import { View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: '40mm',
    paddingLeft: '10mm',
    paddingRight: '10mm',
  },
});

const MapWrapper = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  return <View style={styles.wrapper}>{children}</View>;
};

export { MapWrapper };
