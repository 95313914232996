import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowDown1 } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';

import { useTranslation } from 'react-i18next';
import {
  Direction,
  OrderBy as OrderByEnum,
  OrderByCriterion,
} from '../../../../generated';
import { OutsideClickHandler } from '../../../common/components/outside-click-handler';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { buildOrderBy, parseOptionValue } from '../../utils/build-order-by';
import { device } from '../../../../style/theme';

interface IProps {
  selectedOrderBy?: OrderByCriterion;
  switchOrder: ({
    field,
    direction,
  }: {
    field: OrderByEnum;
    direction: Direction;
  }) => void;
}

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  @media ${device.tablet} {
    flex-direction: column;
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
    align-items: flex-start;
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  @media ${device.tablet} {
    width: 100%;
  }
`;

const Label = styled.label`
  display: inline-block;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-right: 12px;
`;

const SelectWrapper = styled(OutsideClickHandler)<{ $width?: string }>`
  position: relative;
  @media ${device.tablet} {
    width: 100%;
  }
`;

const SelectIcon = styled(ArrowDown1)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 9px;
  height: 9px;
`;

const Select = styled.div<{ isActive: boolean }>`
  height: 40px;
  padding: 12px 30px 12px 12px;
  border-radius: 3px;
  background-color: ${(props) =>
    props.isActive ? props.theme.white : props.theme.greyBackground};
  outline: none;
  border: 1px solid
    ${(props) => (props.isActive ? props.theme.borderFocus : 'transparent')};
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.borderFocus};
    background-color: ${(props) => props.theme.greyBackground};
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }
`;

const SelectDropdown = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  min-width: 100%;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.greyBackground};
  background-color: ${(props) => props.theme.white};
  z-index: 2;
`;

const SelectOption = styled.div<{ isSelected: boolean }>`
  padding: 12px;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  transition: 0.12s ease;
  max-height: 163px;
  cursor: pointer;
  color: ${(props) =>
    props.isSelected ? props.theme.blue : hexToRGB(props.theme.blue, 0.5)};
  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.greyBackground};
  }
  &:hover {
    color: ${(props) => props.theme.blue};
    background: ${(props) => props.theme.greyBackground};
  }
`;

const HiddenInput = styled.input``;

const OrderBy = ({ selectedOrderBy, switchOrder }: IProps): JSX.Element => {
  const orderBy = useMemo(() => buildOrderBy(), []);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    parseOptionValue(selectedOrderBy)
  );

  const { t } = useTranslation();

  const onSelectToggle = useCallback(
    () => setIsSelectOpen(!isSelectOpen),
    [isSelectOpen]
  );

  const onSelectClose = useCallback(() => setIsSelectOpen(false), []);

  const onSelectOptionClick = useCallback(
    (option) => () => {
      setSelectedOption(option);
      switchOrder(option);
      onSelectClose();
    },
    [onSelectClose, switchOrder]
  );

  return (
    <OuterContainer>
      <Label>{t('propert.order-by.label')}</Label>

      <Container>
        <SelectWrapper onOutsideClick={onSelectClose}>
          <Select isActive={isSelectOpen} onClick={onSelectToggle}>
            {t(selectedOption.label)}
            <SelectIcon />
            <HiddenInput type="hidden" defaultValue={selectedOption.label} />
          </Select>
          {isSelectOpen && (
            <SelectDropdown>
              {orderBy?.map((option) => (
                <SelectOption
                  key={option.label}
                  isSelected={selectedOption.label === option.label}
                  onClick={onSelectOptionClick(option)}
                >
                  {t(option.label)}
                </SelectOption>
              ))}
            </SelectDropdown>
          )}
        </SelectWrapper>
      </Container>
    </OuterContainer>
  );
};

export { OrderBy };
