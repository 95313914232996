import { ReactComponent as Letter } from 'assets/streamline-light/interface-essential/paginate/paginate-filter-mail.svg';
import { CSSProperties, FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { LoaderContainer } from 'modules/seller/pages/seller-properties-page/seller-properties-page-styles';
import { ValuationLoader } from 'modules/forms/components/common/loader';
import { Tooltip } from '../../../common/components/tooltip';

import { useAppSelector } from '../../../common/hooks';
import {
  Container,
  ContentBox,
  Form,
  FormContainer,
  FormWrapper,
  Header,
  HeaderTitle,
  LetterIcon,
  Purpose,
  PurposeText,
  Regard,
  RegardText,
  SubmitButton,
  TooltipParagraph,
} from '../appointments/appointments-styles';
import { FormColumn, FormRow } from '../../../common/components/form/form-grid';
import { IAppointmentForm, UpdatedFormValue } from '../../interfaces';
import {
  InputOutsideTopLabel,
  InputOutsideTopLabelWithSelect,
} from '../../../common/components/form';
import { InputType } from '../../../auth/interfaces';
import { phonePrefixes, REGISTER_FORM_KEYS } from '../../../auth/constants';
import { parsePhone } from '../../../auth/utils/parse-phone';
import { SelectOutsideTopLabel } from '../../../common/components/form/select/select-outside-top-label';
import { preferredTimeOptions } from '../../constants';
import { useGetCountry } from '../../../localization/get-country';
import {
  useShopEmailQuery,
  useInitiateRealSellMutation,
  PreferredAppointmentTime,
  useUpdateUserProfileMutation,
  UserStatus,
} from '../../../../generated';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { Alert } from '../alert/alert';
import { Comment } from '../comment/comment';
import { useParseComments } from '../../hooks/useParseComments';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { InputTextArea } from '../../../common/components/form/input/input-text-area';
import Banner from '../banner/banner';
import { ReactComponent as ReadHuman } from '../../../../assets/streamline-light/school-learning/library-reading/read-human.svg';
import BannerImage from '../../../../assets/broker-view-banner.webp';
import { UpdateContactsModal } from '../contact-form-modal/update-contacts-modal';
import { toggleUpdateContactsModalOpen } from '../../redux/sellerPropertiesSlice';
import { setUser } from '../../../auth/redux/authSlice';

interface IProps {
  message?: string;
  messageSentAt?: Date;
  containerStyle?: CSSProperties;
  isModal?: boolean;
  senderFirstName?: string;
  senderLastName?: string;
  userStatus: UserStatus | null;
}

const Appointments: FC<IProps> = ({
  message,
  messageSentAt,
  containerStyle,
  isModal = false,
  senderFirstName,
  senderLastName,
  userStatus,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { propertyId } = useParams<{ propertyId: string }>();
  const [selectedTime, setSelectedTime] = useState<PreferredAppointmentTime>(
    PreferredAppointmentTime.NotSpecified
  );

  const [updatedValues, setUpdatedValues] = useState<UpdatedFormValue[]>([]);

  const user = useAppSelector((state) => state.auth.user);

  const isMobileSize = useIsMobileSize();

  const [isMessageSent, setIsMessageSent] = useState<boolean>(
    !!message && !isModal
  );

  const { phonePrefix } = useGetCountry();

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const isUpdateContactsModalOpen = useAppSelector(
    (state) => state.sellerProperties.isUpdateContactsModalOpen
  );

  const parsedComment = useParseComments([message ?? '']);

  const methods = useForm<IAppointmentForm>({
    mode: 'onSubmit',
  });

  const {
    data,
    isLoading: isShopLoading,
    error: shopErrors,
  } = useShopEmailQuery(
    {
      propertyId,
    },
    { skip: !propertyId }
  );

  const [
    initiateRealSell,
    { isLoading: isInitiateSellLoading, error: initiateSellError },
  ] = useInitiateRealSellMutation();

  const [updateUserProfile] = useUpdateUserProfileMutation();

  const parsedPhone = parsePhone(user?.phone ?? '');

  const selectOptions = phonePrefixes.includes(phonePrefix)
    ? phonePrefixes
    : [phonePrefix, ...phonePrefixes];

  const sender = useMemo(() => {
    if (!senderFirstName || !senderLastName) {
      return {};
    }

    return {
      avatarPlaceholder: `${senderFirstName?.[0] ?? ''}${
        senderLastName?.[0] ?? ''
      }`,
      fullName: `${senderFirstName} ${senderLastName}`,
    };
  }, [senderFirstName, senderLastName]);

  const onSubmit: SubmitHandler<IAppointmentForm> = useCallback(
    async (formData: IAppointmentForm) => {
      const newFormattedPhone = `+${formData.phonePrefix}${formData.phone}`;
      try {
        await initiateRealSell({
          input: {
            propertyId,
            firstName: formData.name,
            lastName: formData.surname,
            phone: newFormattedPhone,
            message: formData.message?.replace(/\n/g, '<br/>'),
            preferredTime:
              formData.preferredTime as unknown as PreferredAppointmentTime,
          },
        }).unwrap();

        setIsMessageSent(true);

        const newValues: UpdatedFormValue[] = [];

        if (formData.name && user?.name && formData.name !== user?.name) {
          newValues.push({
            name: 'name',
            label: t('register.form.input.label.firstname'),
            oldValue: user.name,
            newValue: formData.name,
          });
        }

        if (
          formData.surname &&
          user?.surname &&
          formData.surname !== user?.surname
        ) {
          newValues.push({
            name: 'surname',
            label: t('register.form.input.label.lastname'),
            oldValue: user.surname,
            newValue: formData.surname,
          });
        }

        if (
          formData.phone &&
          user?.phone &&
          user?.phone !== newFormattedPhone
        ) {
          newValues.push({
            name: 'phone',
            label: t('register.form.input.label.phone'),
            oldValue: user.phone,
            newValue: newFormattedPhone,
          });
        }

        if (
          formData.phonePrefix &&
          user?.prefix &&
          String(user?.prefix) !== String(formData.phonePrefix)
        ) {
          newValues.push({
            name: 'phonePrefix',
            label: t(''),
            oldValue: user.prefix,
            newValue: String(formData.phonePrefix),
          });
        }

        if (!isEmpty(newValues)) {
          setUpdatedValues(newValues);
          dispatch(toggleUpdateContactsModalOpen(true));
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      dispatch,
      initiateRealSell,
      propertyId,
      t,
      user?.name,
      user?.phone,
      user?.prefix,
      user?.surname,
    ]
  );

  const onUpdateContactData = useCallback(
    async (values: UpdatedFormValue[]) => {
      try {
        const name = values.find((value) => value.name === 'name');
        const surname = values.find((value) => value.name === 'surname');
        const prefix = values.find((value) => value.name === 'phonePrefix');
        const phone = values.find((value) => value.name === 'phone');

        const updateUserProfileResponse = await updateUserProfile({
          input: {
            ...(name ? { name: name.newValue } : {}),
            ...(surname ? { surname: surname.newValue } : {}),
            ...(prefix ? { prefix: prefix.newValue } : {}),
            ...(phone ? { phone: phone.newValue } : {}),
          },
        }).unwrap();
        dispatch(setUser(updateUserProfileResponse?.updateUserProfile));
        dispatch(toggleUpdateContactsModalOpen(false));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, updateUserProfile]
  );

  const textAreaValue = useMemo(() => {
    const formattedMessage = message?.replace(/<br\/>/g, '\n');

    const defaultFormValue = t(
      'seller.preparation-card.contact-form.message.prefilled-text'
    )?.replace(/\\n/g, '\n');

    const defaultModalValue = t(
      'seller.contact-form-modal.message.prefilled-text'
    )?.replace(/\\n/g, '\n');

    return formattedMessage || (isModal ? defaultModalValue : defaultFormValue);
  }, [isModal, message, t]);

  if (isShopLoading || isInitiateSellLoading) {
    return (
      <LoaderContainer isLoadingMore>
        <ValuationLoader maxWidth="500px" />
      </LoaderContainer>
    );
  }

  return (
    <FormProvider {...methods}>
      {isBrokerView && !isModal && (
        <Banner
          icon={ReadHuman}
          image={BannerImage}
          title={t('seller-portal.broker-view.banner.title')}
          description={t('seller-portal.broker-view.banner.description')}
        />
      )}
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {!isModal && isMessageSent && (
          <Alert
            text={t('seller.preparation-card.contact-form-sent.message')}
          />
        )}
        <Container style={containerStyle}>
          <Header>
            <HeaderTitle>
              {t(
                isMessageSent
                  ? 'seller.preparation-card.contact-form-sent.title'
                  : 'seller.preparation-card.contact-form.title',
                {
                  city: data?.shopEmail?.name ?? '',
                }
              )}
            </HeaderTitle>
          </Header>
          {isModal && isMessageSent && (
            <Alert
              text={t('seller.preparation-card.contact-form-sent.message')}
              style={{ margin: '16px 0' }}
            />
          )}
          <ContentBox>
            {shopErrors && (
              <GlobalError
                title={t(
                  (shopErrors as { message: string })?.message?.split(':')[0]
                )}
              />
            )}
            {initiateSellError && (
              <GlobalError
                title={t(
                  (initiateSellError as { message: string })?.message?.split(
                    ':'
                  )[0]
                )}
              />
            )}
            <FormContainer>
              <LetterIcon
                color={'#00305e'}
                icon={Letter}
                height={isMobileSize ? 30 : 42}
                width={isMobileSize ? 24 : 48}
              />
              <Purpose content={t('seller.preparation-card.header.purpose')} />
              <PurposeText
                content={t('seller.preparation-card.header.purpose-text')}
              />
              <Regard content={t('seller.preparation-card.header.regard')} />
              <RegardText
                content={t('seller.preparation-card.header.regard-text')}
              />
            </FormContainer>
            {isMessageSent && messageSentAt && !isModal && (
              <>
                <Comment
                  userAvatarPlaceholder={
                    sender.avatarPlaceholder ||
                    parsedComment?.[0]?.avatarPlaceholder
                  }
                  title={sender.fullName || parsedComment?.[0]?.userFullName}
                  date={new Date(messageSentAt).toLocaleString().slice(0, -3)}
                  text={message ?? ''}
                  isInnerHTML
                />
                {userStatus !== UserStatus.Spectator ? (
                  <SubmitButton
                    label={t(
                      'seller.preparation-card.contact-form.button.submit'
                    )}
                    onClick={() => setIsMessageSent(false)}
                    isDisabled={isBrokerView}
                  />
                ) : (
                  <Tooltip
                    id={'seller.preparation-card.contact-form.button.tooltip'}
                    content={
                      <SubmitButton
                        label={t(
                          'seller.preparation-card.contact-form.button.submit'
                        )}
                        isDisabled
                      />
                    }
                  >
                    <TooltipParagraph>
                      {t('property.spectator.not-allowed.tooltip')}
                    </TooltipParagraph>
                  </Tooltip>
                )}
              </>
            )}
            {(!isMessageSent || isModal) && (
              <>
                <FormWrapper>
                  <FormRow>
                    <FormColumn flex={'0 0 100%'}>
                      <InputTextArea
                        name={'message'}
                        label={t(
                          'seller.preparation-card.contact-form.message.label',
                          {
                            city: data?.shopEmail?.name ?? '',
                            email: data?.shopEmail?.email ?? '',
                          }
                        )}
                        rules={{
                          required: 'register.input.error.required',
                        }}
                        height={isMobileSize ? 170 : 100}
                        noTranslate
                        defaultValue={textAreaValue}
                        style={{ lineHeight: 2 }}
                        labelStyle={{ whiteSpace: 'unset' }}
                      />
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn>
                      <InputOutsideTopLabel
                        name={'name'}
                        type={InputType.text}
                        label={'register.form.input.label.firstname'}
                        rules={{
                          required: 'register.input.error.required',
                        }}
                        defaultValue={(senderFirstName || user?.name) ?? ''}
                      />
                    </FormColumn>
                    <FormColumn>
                      <InputOutsideTopLabel
                        name={'surname'}
                        type={InputType.text}
                        label={'register.form.input.label.lastname'}
                        rules={{
                          required: 'register.input.error.required',
                        }}
                        defaultValue={(senderLastName || user?.surname) ?? ''}
                      />
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn>
                      <InputOutsideTopLabelWithSelect
                        type={InputType.number}
                        name={'phone'}
                        label="register.form.input.label.phone"
                        placeholder="register.form.input.placeholder.phone"
                        rules={{
                          required: 'register.input.error.required',
                          valueAsNumber: true,
                        }}
                        selectWidth="87px"
                        hasSelect
                        selectOptions={selectOptions}
                        selectValue={
                          parsedPhone.phonePrefix
                            ? parsedPhone.phonePrefix
                            : undefined
                        }
                        selectName={REGISTER_FORM_KEYS.PHONE_PREFIX}
                        defaultValue={parsedPhone.phoneNumber ?? ''}
                        dropdownStyle={{ zIndex: 4 }}
                      />
                    </FormColumn>
                    <FormColumn>
                      <SelectOutsideTopLabel
                        name={'preferredTime'}
                        selectedOption={
                          preferredTimeOptions?.find(
                            (option) => option.value === selectedTime
                          )?.label ?? preferredTimeOptions[0].label
                        }
                        onSelect={(value) => {
                          setSelectedTime(value);
                        }}
                        options={preferredTimeOptions}
                        label={t(
                          'seller.preparation-card.contact-form.preferred-time.label'
                        )}
                        defaultValue={preferredTimeOptions[0].value}
                        style={{ zIndex: 3 }}
                      />
                    </FormColumn>
                  </FormRow>
                </FormWrapper>
                {userStatus !== UserStatus.Spectator ? (
                  <SubmitButton
                    label={t(
                      'seller.preparation-card.contact-form.button.submit'
                    )}
                    isDisabled={isBrokerView}
                    type={'submit'}
                  />
                ) : (
                  <Tooltip
                    id={
                      'seller.preparation-card.contact-form.button.submit.tooltip'
                    }
                    content={
                      <SubmitButton
                        label={t(
                          'seller.preparation-card.contact-form.button.submit'
                        )}
                        isDisabled
                      />
                    }
                  >
                    <TooltipParagraph>
                      {t('property.spectator.not-allowed.tooltip')}
                    </TooltipParagraph>
                  </Tooltip>
                )}
              </>
            )}
          </ContentBox>
        </Container>
        <UpdateContactsModal
          onSubmit={onUpdateContactData}
          isOpen={isUpdateContactsModalOpen}
          updatedValues={updatedValues}
        />
      </Form>
    </FormProvider>
  );
};

export default Appointments;
