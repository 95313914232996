import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;

  @media ${device.tablet} {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const FlexBox = styled.div`
  @media ${device.tablet} {
    display: flex;
    gap: 32px;
  }
`;

export const LoanAmountBox = styled.div`
  padding: 16px 12px;
  border-radius: 3px;
  border: solid 1px #c9d5fc;
  background-color: #f2f5f7;
  margin-right: 16px;

  @media ${device.tablet} {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: 12px;
    justify-content: space-between;
    margin-bottom: 16px;
  }
`;

export const LoanAmount = styled.div`
  font-family: Source Serif Pro, sans-serif;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};

  @media ${device.tablet} {
    min-width: 100px;
    line-height: 24px;
    margin-bottom: 0;
  }
`;

export const LoanAmountText = styled.p`
  opacity: 0.8;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${({ theme }) => theme.blue};

  @media ${device.tablet} {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
`;

export const SectionsContainer = styled.div`
  display: flex;
  gap: 16px;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  @media ${device.tablet} {
    margin-bottom: 16px;

    & > div {
      gap: 16px;
    }
  }
`;
