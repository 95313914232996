import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { batch, useDispatch } from 'react-redux';
import { PhoneVerification } from '../../../../../../auth/components/forms/phone-verification';
import { PhoneVerificationWrapper, Title } from '../../styles';
import {
  useResendConfirmationCodeMutation,
  UserState,
  useUserSelfDeleteMutation,
} from '../../../../../../../generated';
import { useAppSelector } from '../../../../../../common/hooks';
import {
  setUserId,
  setUsername,
  setUserState,
} from '../../../../../../auth/redux/authSlice';
import { StatusType } from '../../../../../../common/components/verification-input/types';

interface IProps {
  verificationStatus: StatusType;
  formattedPhone: string;

  onVerifyPhone(code: string): void;
}

const VerifyPhoneContainer: FC<IProps> = ({
  verificationStatus,
  formattedPhone,
  onVerifyPhone,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const username = useAppSelector((state) => state.auth.userInput?.username);
  const email = useAppSelector((state) => state.auth.userInput?.email);

  const [changePhone] = useUserSelfDeleteMutation();
  const [resendConfirmationCode] = useResendConfirmationCodeMutation();

  const onResendConfirmationCode = useCallback(() => {
    if (email) {
      resendConfirmationCode({
        email,
      });
    }
  }, [resendConfirmationCode, email]);

  const onChangePhone = useCallback(async () => {
    if (username) {
      try {
        await changePhone({ username }).unwrap();

        batch(() => {
          dispatch(setUserId(''));
          dispatch(setUsername(''));
          dispatch(setUserState(UserState.Unauthenticated));
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [dispatch, changePhone, username]);

  return (
    <>
      <Title>{t('phone-verification.title')}</Title>
      <PhoneVerificationWrapper>
        <PhoneVerification
          phoneNumber={formattedPhone}
          onVerifyPhone={onVerifyPhone}
          status={verificationStatus}
          resendCode={onResendConfirmationCode}
          changePhone={onChangePhone}
        />
      </PhoneVerificationWrapper>
    </>
  );
};

export { VerifyPhoneContainer };
