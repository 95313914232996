import { FunctionComponent, SVGProps } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { ClientError } from 'graphql-request';
import { SerializedError } from '@reduxjs/toolkit';
import { IPhonePrefixes } from '../auth/interfaces';

export enum InputType {
  iconBox = 'IconBox',
  radioBox = 'RadioBox',
  input = 'Input',
  slider = 'Slider',
  range = 'Range',
  select = 'Select',
}

export enum BoxType {
  radioBox = 'RadioBox',
  iconBox = 'IconBox',
}

export interface IBox {
  boxType: BoxType;
  value: unknown;
  text?: string;
  icon?: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  iconImage?: string;
  iconColor?: string;
  iconSelectedColor?: string;
  fillIconColor?: string;
  fillSelectedIconColor?: string;
  id?: string;
  title?: string;
  nestedInput?: string;
  nestedInputPlaceholder?: string;
  isDisabled?: boolean;
  isDisabledCustomOnClick?: boolean;
}

export interface IBoxRating {
  value: unknown;
  icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
  text?: string;
  title?: string;
}

export interface IInput {
  name: string;
  label: string;
  type?: string;
  placeholder?: string;
  hasSelect?: boolean;
  selectValue?: string;
  selectWidth?: string;
  selectOptions?: IPhonePrefixes[];
  selectName?: string;
  rules?: RegisterOptions;
}

export type StepStateArray = {
  type: InputType;
  name?: string;
  boxes?: IBox[];
  inputs?: IInput[];
}[];

export interface IInputType {
  name: string;
  label: string;
  type?: string;
  defaultValue?: number | string;
  value?: string | number;
  placeholder?: string;
  rules: RegisterOptions;
  isDisabled?: boolean;
}

export interface IControl {
  name?: string | `${string}` | `${string}.${string}` | `${string}.${number}`;
  type: InputType;
  defaultValue?: number | string;
  boxes?: IBox[];
  inputs?: IInputType[];
}

export interface IAddress {
  postCode: string;
  locality: string;
  neighborhood: string;
  street: string;
  number: string;
  city: string;
  state: string;
  country: string;
  proximity: string;
  types?: string;
  language: string;
  fuzzyMatch: string;
}

export enum AddressPiece {
  postCode = 'postCode',
  number = 'number',
  city = 'city',
  street = 'street',
  state = 'state',
  country = 'country',
}

export enum AddressPlaceTypes {
  address = 'address',
  poi = 'poi',
  postcode = 'postcode',
  region = 'region',
  place = 'place',
  locality = 'locality',
  neighborhood = 'neighborhood',
}

export type FormError =
  | Pick<ClientError, 'message' | 'name' | 'stack'>
  | SerializedError
  | undefined;
