import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Arrow } from 'assets/navigation/mobile-menu-nested.svg';
import Icon from '../../ui/icon';
import { NavigationLinkInterface } from './navigation-types';

const MainBox = styled.div`
  position: relative;
`;

const LinkText = styled.span<{ isActive?: boolean }>`
  ${(props) =>
    props.isActive &&
    `
    border-bottom: 2px solid #00305E;
    padding-bottom: 7px;
  `}
`;

const LinkBtn = styled.button`
  background: transparent;
  outline: none;
  border: none;
  text-align: left;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.05em;
  color: #00305e;
  transition: opacity 0.3s;
  margin-top: 30px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

const SubLink = styled.a`
  font-family: Roboto, sans-serif;
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #00305e;
  text-decoration: none;

  &:first-child {
    margin-top: 20px;
  }

  &:not(:last-child) {
    margin-bottom: 27px;
  }
`;

const CollapseHeaderNested = ({ link }: { link: NavigationLinkInterface }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <MainBox>
      <LinkBtn onClick={() => setIsOpen(!isOpen)}>
        <LinkText isActive={isOpen}>{t(link.label)}</LinkText>
        {/* <Icon color={"#00305E"} icon={Arrow} height={8} width={15} /> */}
      </LinkBtn>
      {isOpen && (
        <div>
          {(link.children || []).map(({ label, path }) => {
            return (
              <SubLink key={`${label}--${path}`} href={path} target={'_blank'}>
                {t(label)}
              </SubLink>
            );
          })}
        </div>
      )}
    </MainBox>
  );
};

export default CollapseHeaderNested;
