import { useTranslation } from 'react-i18next';
import BigCardContainer from '../elements/big-card-container';
import { CardContentHolder } from '../elements/card-content-holder';
import { CardTitle } from '../elements/card-title';
import { CardContent } from '../elements/card-content';

interface IProps {
  title?: string;
  content?: string;
  children?: JSX.Element | JSX.Element[];
}

export const CalculatorCard = ({
  title,
  content,
  children,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <BigCardContainer>
      <CardContentHolder>
        {title && <CardTitle content={t(title)} />}
        {content && <CardContent content={t(content)} />}
      </CardContentHolder>
      <CardContentHolder>{children}</CardContentHolder>
    </BigCardContainer>
  );
};
