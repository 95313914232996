import styled from 'styled-components';
import { device } from '../../../../../../../../style/theme';

export const CertificateContainer = styled.div<{ mt: number }>`
  & > div > div {
    margin: 0;
  }
  margin-top: ${({ mt }) => mt}px;
  margin-bottom: 16px;

  @media ${device.tablet} {
    margin-bottom: 32px;
  }
`;
