import styled from 'styled-components';
import { BoxText, Headline2 } from '../../../common/components/ui/typography';
import Icon from '../../../common/components/ui/icon';

export const Container = styled.div<{ isBlurred?: boolean }>`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.isBlurred && 'filter: blur(6px)'};
  background-color: ${(props) => props.theme.white};
`;

export const Title = styled(Headline2)`
  text-align: center;
  margin-bottom: 20px;
`;

export const TasksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 8px;
  align-self: start;
`;

export const Task = styled.div`
  display: flex;
  align-items: center;
`;

export const TaskIcon = styled(Icon)`
  & > svg {
    stroke: ${(props) => props.theme.green};
  }
`;

export const TaskName = styled(BoxText)`
  line-height: 1.33;
  margin-left: 8px;
`;

export const LoaderContainer = styled.div`
  border: solid 1px ${(props) => props.theme.borderColor};
  width: 100%;
  height: 400px;
  position: relative;
  padding: 56px;
  margin-bottom: 16px;

  &&&& {
    > div {
      position: absolute;
    }
  }
`;
