import { CSSProperties, FC } from 'react';
import {
  Container,
  Content,
  CTAButton,
  Description,
  IconWrapper,
  Image,
  Title,
} from './banner-styles';
import Icon from '../../../common/components/ui/icon';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';

interface IProps {
  title: string;
  description: string;
  image?: string;
  ctaButtonLabel?: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  containerStyle?: CSSProperties;
  className?: string;
  onCTAClick?: () => void;
  isCTADisabled?: boolean;
}

const Banner: FC<IProps> = ({
  title,
  description,
  image,
  ctaButtonLabel,
  icon,
  containerStyle,
  className,
  onCTAClick,
  isCTADisabled,
}) => {
  const isMobileSize = useIsMobileSize();

  return (
    <Container style={containerStyle} className={className}>
      <Content hasCTA={!!ctaButtonLabel}>
        {!isMobileSize && (
          <IconWrapper>
            <Icon
              icon={icon}
              height={isMobileSize ? 24 : 64}
              width={isMobileSize ? 24 : 57}
            />
          </IconWrapper>
        )}
        <Title content={title} />
        <Description content={description} />
        {ctaButtonLabel && (
          <CTAButton
            label={ctaButtonLabel}
            onClick={onCTAClick}
            isDisabled={isCTADisabled}
          />
        )}
      </Content>
      {image && <Image src={image} alt={'banner image'} />}
    </Container>
  );
};

export default Banner;
