import { FC, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { ValuationFormContainer } from '../valuation-form-container/valuation-form-container';
import { IValuationForm } from '../../../../interface';
import { Container } from './valuation-first-step-screen-styles';
import { setIsRegionalPage } from '../../../../redux/valuationWizardV2Slice';
import { useAppSelector } from '../../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../../common/hooks/useIsMobileSize';
import { IIframeType } from '../../../../../../auth/interfaces';

interface IProps {
  mobileReverse?: boolean;
}

const ValuationFirstStepScreen: FC<IProps> = ({ mobileReverse }) => {
  const { reset, ...methods } = useForm<IValuationForm>();
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();
  const { isIframe, iframeType } = useAppSelector((state) => state.auth);
  const { activeStep, iframePropertyType, isHighlight } = useAppSelector(
    (state) => state.valuationWizardV2
  );
  const isCentered = iframeType === IIframeType.WIDGET_OWNER_CENTERED;
  const isNoModal = iframeType === IIframeType.WIDGET_OWNER_NO_MODAL;

  useEffect(() => {
    dispatch(setIsRegionalPage(true));

    return () => {
      dispatch(setIsRegionalPage(false));
    };
  }, []);

  return (
    <Container
      isHighlight={isHighlight}
      isIframe={isIframe}
      isCentered={isCentered}
      mobileReverse={mobileReverse}
    >
      <FormProvider reset={reset} {...methods}>
        <ValuationFormContainer
          openWidget
          activeStepProp={
            isNoModal || iframePropertyType
              ? activeStep
              : isMobileSize
              ? undefined
              : 0
          }
        />
      </FormProvider>
    </Container>
  );
};

export { ValuationFirstStepScreen };
