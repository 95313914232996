import { ISearchProfileForm } from 'modules/forms/form-search-profile/interface';

export enum Proximity {
  GERMANY = '9.993682,53.551086',
  AUSTRIA = '15.4395,47.0707',
  SWITZERLAND = '7.3606,46.2331',
}

export interface IProps {
  regionalFirstStepNext?: (formData?: Partial<ISearchProfileForm>) => void;
}
