import styled from 'styled-components';
import { device } from '../../../../../../style/theme';
import {
  BoxTitle,
  Headline3,
  ParagraphText,
  ParagraphTitle,
} from '../../../../../common/components/ui/typography';
import { hexToRGB } from '../../../../../common/utils/hex-to-rgb';

export const Card = styled.div<{ selected?: boolean }>`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.5s ease;
  --buyer-card-border-color: #e5e9ec;
  --buyer-card-bg-color: #e5e9ec;
  --buyer-card-icon-color: rgb(77, 104, 127, 0.3);
  --buyer-card-hovered-icon-color: #e5e9ec;

  .hovered {
    display: none !important;
  }
  .normal {
    display: flex !important;
  }
`;

export const Wrapper = styled.div`
  border: 1px solid var(--buyer-card-border-color);
  border-radius: 5px;
  //transition: all 0.3s;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px;
`;

export const IconRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-row: 2;
  row-gap: 32px;

  @media ${device.mobile} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 16px;
  }
`;

export const IconItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 4px;
  }
`;

export const IconLabel = styled(ParagraphText)``;

export const Details = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 48, 94, 0.15);
  padding-bottom: 16px;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const LocationTitle = styled(BoxTitle)`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
`;

export const AddressLabel = styled(ParagraphText)``;

export const Address = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const Head = styled.div`
  background: var(--buyer-card-bg-color);
  transition: all 0.3s;

  padding: 12px;
  display: flex;
  gap: 12px;
  align-items: center;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const HeadIcon = styled.div`
  /* width: 48px;
  height: 48px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  /* background: var(--buyer-card-icon-color); */
`;

export const HeadContent = styled.div`
  flex-grow: 2;

  @media ${device.mobile} {
    text-align: center;
  }
`;

export const HeadTitle = styled(Headline3)`
  font-family: 'Roboto';
  font-size: 14px;
`;
export const HeadSubTitle = styled(ParagraphText)`
  font-size: 10px;
`;
export const Handler = styled.div`
  align-self: flex-start;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid var(--buyer-card-hovered-icon-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
`;

export const Tag = styled.div<{ rounded?: boolean }>`
  padding: 2px 8px;
  border-radius: ${(props) => (props.rounded ? '10px' : '3px')};
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.blue};

  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;

  height: 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const TooltipTitle = styled(ParagraphTitle)`
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 24px;
  max-width: 320px;
  overflow-wrap: break-word;
`;
