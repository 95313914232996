import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InformationCircleBold } from 'assets/images/icons/common/tooltip-key-bold.svg';

import { Container, Div } from './separator-styles';
import {
  Info,
  TooltipContainer,
  TooltipParagraph,
} from '../../../../../../components/common/input-v2/input-v2-styles';
import { Tooltip } from '../../../../../../../common/components/tooltip';

interface IProps {
  label?: string;
  value?: string;
  tooltipKey?: string;
  tooltipWidth?: number;
  isBold?: boolean;
  mt?: number;
  mb?: number;
}

const SeparatorBase: FC<IProps> = ({
  label,
  value,
  tooltipKey,
  tooltipWidth,
  isBold,
  mt,
  mb,
}) => {
  const { t } = useTranslation();

  const onClickLabel = () => {
    if (tooltipKey) {
      const tooltip = document.querySelector(
        `#${tooltipKey.replaceAll('.', '-')}-icon`
      ) as HTMLParagraphElement;
      if (tooltip) {
        tooltip.click();
      }
    }
  };

  return (
    <Container isMarginTop={!!label || !!value} mt={mt} mb={mb} isBold={isBold}>
      <div>
        <div>
          <Div className="label" onClick={onClickLabel}>
            {t(label || '')}
          </Div>
          {tooltipKey && (
            <Info tooltipWidth={tooltipWidth}>
              <Tooltip
                icon={InformationCircleBold}
                id={tooltipKey}
                isOnClick
                position="bottom"
                width={16}
                height={16}
              >
                <TooltipContainer>
                  <TooltipParagraph>{t(tooltipKey)}</TooltipParagraph>
                </TooltipContainer>
              </Tooltip>
            </Info>
          )}
        </div>
        <div>{value}</div>
      </div>
      <div />
    </Container>
  );
};

const Separator = memo(SeparatorBase);

export { Separator };
