import { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { ThemeContext } from 'styled-components';
import { device } from '../../../../style/theme';
import Icon from '../ui/icon';

const Container = styled.div<{
  maxWidth?: number | string;
  textAlign?: string;
  noMaxWidth?: boolean;
}>`
  display: flex;
  align-items: center;
  ${(props) =>
    `${
      props.maxWidth
        ? `max-width: ${props.maxWidth}${
            typeof props.maxWidth !== 'string' ? 'px' : ''
          }`
        : ''
    }`};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  ${(props) => props.noMaxWidth && 'max-width: unset;'}
  .__react_component_tooltip.show {
    opacity: 1;
  }
  .extraClass {
    pointer-events: auto !important;
    &:hover {
      visibility: visible !important;
    }
  }

  @media ${device.mobile} {
    .__react_component_tooltip {
      left: 5px !important;
      right: 5px !important;

      &:before,
      &:after {
        display: none;
      }
    }
  }
`;

export interface TooltipHandlers {
  show: () => void;
  hide: () => void;
}

interface IProps {
  children: React.ReactNode;
  id: string;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  position?: 'top' | 'right' | 'bottom' | 'left';
  stayOnHover?: boolean;
  isOnClick?: boolean;
  clickable?: boolean;
  width?: number;
  height?: number;
  color?: string;
  maxWidth?: number | string;
  noMaxWidth?: boolean;
  isDisabled?: boolean;
  content?: React.ReactNode;
  className?: string;
  textAlign?: string;
  isDisabledEvents?: boolean;
}
const Tooltip = forwardRef(
  (
    {
      children,
      id,
      icon: TooltipIcon,
      position = 'top',
      stayOnHover = false,
      isOnClick = false,
      clickable = false,
      width = 20,
      height = 20,
      color,
      maxWidth,
      noMaxWidth,
      isDisabled,
      content,
      className,
      textAlign,
      isDisabledEvents = false,
    }: IProps,
    ref
  ): JSX.Element => {
    const themeContext = useContext(ThemeContext);
    const tipRef = useRef(null);

    useImperativeHandle(ref, () => ({
      show: () => {
        if (tipRef?.current) {
          ReactTooltip.show(tipRef.current!);
        }
      },
      hide: () => {
        if (tipRef?.current) {
          ReactTooltip.hide(tipRef.current!);
        }
      },
    }));

    return (
      <Container
        maxWidth={maxWidth}
        noMaxWidth={noMaxWidth}
        textAlign={textAlign}
        className="tooltip-container"
      >
        <div
          ref={tipRef}
          className="tooltip-inner-container"
          data-tip
          data-for={encodeURIComponent(id)}
          id={`${id.replaceAll('.', '-')}-icon`}
        >
          {TooltipIcon && (
            <Icon
              icon={TooltipIcon}
              width={width}
              height={height}
              color={color ?? themeContext.blue}
            />
          )}
          {content ?? null}
        </div>
        <ReactTooltip
          {...(stayOnHover && { delayHide: 1000 })}
          place={position}
          {...(stayOnHover && { className: 'extraClass' })}
          {...(isOnClick && { event: 'click' })}
          {...(isDisabledEvents && { event: 'none' })}
          id={id}
          effect="solid"
          type="light"
          borderColor="#d9d9d9"
          border
          disable={isDisabled}
          scrollHide
          className={`${className} tooltip`}
          clickable={clickable}
        >
          {children}
        </ReactTooltip>
      </Container>
    );
  }
);

export { Tooltip };
