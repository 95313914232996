export const addCommas = (num?: string | number): string => {
  if (!num) {
    return '';
  }
  const numString = String(num).replace('.', ',');
  const withoutStartZero = Number(numString.split(',')[0]);
  const int = String(withoutStartZero).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const decimal = numString.split(',')[1];
  const isComma = numString.includes(',');

  return `${int}${isComma ? ',' : ''}${decimal ? `${decimal}` : ''}`;
};
export const removeNonNumeric = (num?: string | number): string =>
  String(num).replace(/\D\./g, '');
