import { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LoaderImg from 'assets/marketing-dashboard-loader.webp';
import { Headline2 } from '../../../../common/components/ui/typography';
import { device } from '../../../../../style/theme';

const Container = styled.div`
  padding: 46px;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  background-color: ${(props) => props.theme.white};
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: center;
`;

const Image = styled.img`
  width: 81px;
  height: 120px;
`;

const Text = styled(Headline2)`
  font-size: 20px;
  line-height: 1.6;

  @media ${device.mobile} {
    font-size: 16px;
  }
`;

const FilterLoaderBase = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Image alt={'loader'} src={LoaderImg} />
      <Text content={t('marketing-dashboard.filter.loader')} />
    </Container>
  );
};

const FilterLoader = memo(FilterLoaderBase);

export { FilterLoader };
