import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { ReactComponent as ViewOff } from 'assets/streamline-light/interface-essential/view/view-off.svg';
import { ReactComponent as View } from 'assets/streamline-light/interface-essential/view/view.svg';

import { PASSWORD_REGEX } from '../../../constants';
import Icon from '../../ui/icon';
import {
  IInputOutsideTopLabel,
  InputOutsideTopLabel,
} from './input-outside-top-label';
import * as S from './input-outside-top-label-styled';

export interface IInputPassOutsideTopLabel extends IInputOutsideTopLabel {
  name: string;
  passwordMasterValue?: string;
  skipPattern?: boolean;
  isRedesign?: boolean;
  noLabel?: boolean;
  autoComplete?: 'on' | 'off' | 'nope' | 'false' | 'new-password';
}

const InputPassOutsideTopLabel = ({
  id,
  className,
  name,
  label,
  defaultValue,
  placeholder,
  rules,
  passwordMasterValue,
  skipPattern = false,
  isRedesign,
  noLabel = false,
  autoComplete,
  isDisabled,
  type,
  ...rest
}: IInputPassOutsideTopLabel): JSX.Element => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const [isPasswordVisible, toggleIsPasswordVisible] = useState(false);

  const onPasswordIconClick = useCallback(() => {
    toggleIsPasswordVisible(!isPasswordVisible);
  }, [isPasswordVisible]);

  const validationError = t('passwords do not match');
  const patternError = t('password did not conform with policy');

  return (
    <>
      <InputOutsideTopLabel
        id={id}
        className={className}
        name={name}
        type={isPasswordVisible ? 'text' : 'password'}
        label={label}
        defaultValue={defaultValue}
        placeholder={placeholder}
        isRedesign={isRedesign}
        noLabel={noLabel}
        autoComplete={autoComplete}
        isDisabled={isDisabled}
        rules={{
          ...rules,
          ...(!skipPattern && {
            pattern: {
              value: PASSWORD_REGEX,
              message: patternError,
            },
          }),
          ...(skipPattern && {
            validate: (value: string) =>
              !passwordMasterValue ||
              value === passwordMasterValue ||
              validationError,
          }),
        }}
        infoNode={
          <S.Info onClick={onPasswordIconClick}>
            <Icon
              color={themeContext.blue}
              icon={isPasswordVisible ? ViewOff : View}
              width={16}
              height={16}
            />
          </S.Info>
        }
        {...rest}
      />
    </>
  );
};

export { InputPassOutsideTopLabel };
