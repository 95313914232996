export const calculateParallax = (screenWidth?: number): string => {
  if (!screenWidth) {
    return ``;
  }
  if (screenWidth >= 320 && screenWidth < 340) {
    return `-88%`;
  }
  if (screenWidth >= 340 && screenWidth < 360) {
    return `-89%`;
  }
  if (screenWidth >= 360 && screenWidth < 380) {
    return `-90%`;
  }
  if (screenWidth >= 380 && screenWidth < 440) {
    return `-91%`;
  }
  if (screenWidth >= 440 && screenWidth < 500) {
    return `-92%`;
  }
  if (screenWidth >= 500 && screenWidth < 560) {
    return `-93%`;
  }
  if (screenWidth >= 560 && screenWidth < 620) {
    return `-94%`;
  }
  if (screenWidth >= 620 && screenWidth < 680) {
    return `-94%`;
  }
  if (screenWidth >= 620 && screenWidth < 680) {
    return `-95%`;
  }
  if (screenWidth >= 680 && screenWidth < 740) {
    return `-95%`;
  }
  if (screenWidth >= 740 && screenWidth < 800) {
    return `-95%`;
  }
  if (screenWidth >= 800 && screenWidth < 860) {
    return `-95%`;
  }
  if (screenWidth >= 860 && screenWidth < 920) {
    return `-96%`;
  }
  return `-95%`;
};
