import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserState } from '../../../../../../../../../generated';
import { ConfirmEmail } from '../../../../../../../../auth/components/confirm-email';
import { RegisterForm } from '../../../../../../../../auth/components/forms/register-form/register-form';
import LoginOpen from '../../../../../../../../auth/components/login-open';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../common/hooks';
import { useRegisterSP } from '../../../../../../hooks/useRegisterSP';
import {
  toggleLoginForm,
  toggleRegisterForm,
} from '../../../../../../redux/searchProfileSlice';

import { Container, Row, Title } from './register-styles';

const Register: FC<{ onAuthSuccess: () => void }> = ({ onAuthSuccess }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const userState = useAppSelector((state) => state.auth.userState);

  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const [confirmEmailSent, setConfirmEmailSent] = useState(false);

  const [errors, setErrors] = useState<null | unknown>(null);

  const { onRegisterSubmit } = useRegisterSP({
    setConfirmEmailSent,
    setErrors,
  });

  return (
    <Container>
      <Row>
        {userState === UserState.Unauthenticated && (
          <>
            <Title>{t('register.form.title')}</Title>
            <LoginOpen
              handleClick={(e) => {
                e?.preventDefault();
                dispatch(toggleRegisterForm(false));
                dispatch(toggleLoginForm(true));
              }}
            />
            <RegisterForm
              onSubmit={(formData) => {
                onRegisterSubmit(formData);
                onAuthSuccess();
              }}
              errors={errors}
              isSearchProfile
              isButtonBottom={isIframe}
              isFieldRequirement
              isFullWidthButton={isIframe}
            />
          </>
        )}
        {userState === UserState.VerifyEmail && confirmEmailSent && (
          <ConfirmEmail
            title={t('modal.register.search-profile.confirm-email.title')}
            textContent={t('modal.register.confirm-email.content')}
          />
        )}
      </Row>
    </Container>
  );
};

export default Register;
