export const Europe = {
  latitude: 52,
  longitude: 25,
  zoom: 3,
  bearing: 0,
  pitch: 0,
};

export const Germany = {
  longitude: 10.4515,
  latitude: 51.1657,
  altitude: 1.5,
  zoom: 5.5,
  bearing: 0,
  pitch: 0,
};

export const Austria = {
  longitude: 17.5,
  latitude: 47.210_033,
  altitude: 1.5,
  zoom: 5.6,
  bearing: 0,
  pitch: 0,
};

export const Switzerland = {
  longitude: 11,
  latitude: 46.210_033,
  altitude: 1.5,
  zoom: 6.2,
  bearing: 0,
  pitch: 0,
};
