import { ThemeContext } from 'styled-components';
import { batch, useDispatch } from 'react-redux';
import { FC, useCallback, useContext, useRef } from 'react';

import { CloseButton } from './full-screen-gallery-styles';
import Icon from '../../../../../../common/components/ui/icon';
import { ReactComponent as CloseIcon } from '../../../../../../../assets/streamline-light/interface-essential/form-validation/close.svg';
import { Modal } from '../../../../../../common/components/modal';
import { useAppSelector } from '../../../../../../common/hooks';
import { FullScreenGallery } from './full-screen-gallery';
import { setIsGalleryOpen } from '../../../redux/exposeSlice';
import { SuggestedPropertyAttachment } from '../../../../../../../generated';
import { hexToRGB } from '../../../../../../common/utils/hex-to-rgb';

const wrapperStyles = {
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column' as const,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: hexToRGB('#000000', 0.2),
};

interface IProps {
  images: ({ __typename?: 'SuggestedPropertyAttachment' | undefined } & Pick<
    SuggestedPropertyAttachment,
    'name' | 'alt'
  >)[];
  activeSlide: number;
  setActiveSlide: (index: number) => unknown;
}

const GalleryModal: FC<IProps> = ({ images, activeSlide, setActiveSlide }) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const swiperRef = useRef<unknown>(null);

  const isOpen = useAppSelector((state) => state.exposeDetails.isGalleryOpen);

  const onClose = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      batch(() => {
        dispatch(setIsGalleryOpen(false));
        dispatch(setActiveSlide(0));
      });
    },
    [dispatch, setActiveSlide]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} wrapperStyles={wrapperStyles}>
      <CloseButton onClick={onClose}>
        <Icon
          icon={CloseIcon}
          width={16}
          height={16}
          color={themeContext.blue}
        />
      </CloseButton>
      <FullScreenGallery
        ref={swiperRef}
        activeSlide={activeSlide}
        images={images}
        setActiveSlide={setActiveSlide}
      />
    </Modal>
  );
};

export default GalleryModal;
