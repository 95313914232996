import { CSSProperties, FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { ThemeContext } from 'styled-components';

import { ReactComponent as Pencil } from 'assets/streamline-light/interface-essential/edit/pencil.svg';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import {
  Modal,
  ModalTitle,
} from '../../../../../../../common/components/modal';
import { useIsMobileSize } from '../../../../../../../common/hooks/useIsMobileSize';
import { toggleEditFinancingVariantName } from '../../../../../../../forms/form-financing/redux/financingSlice';
import { FinancingVariantNameForm } from '../../../../../../../forms/form-financing/components/forms/financing-edit-forms/financing-variant-name-form/financing-variant-name-form';
import { useGetFinancing } from '../../../../../../hooks/useGetFinancing';
import { CloseButton } from '../../../../../../../common/components/modal/close-button';

interface IProps {
  onSubmit: () => void;
}

const ModalVariantName: FC<IProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const theme = useContext(ThemeContext);
  const { setValue } = useFormContext();
  const { financingId } = useParams<{ financingId: string }>();

  const isEditFinancingVariantNameOpen = useAppSelector(
    (state) => state.financing.editWidget.isEditFinancingVariantNameOpen
  );

  const { financingVariants } = useGetFinancing({
    financingId,
  });
  const closeModal = () => dispatch(toggleEditFinancingVariantName(false));
  const wrapperStyles = isMobileSize
    ? {
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
        height: '100%',
        overflow: 'overlay',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }
    : { maxWidth: '528px' };

  const onSuggestName = () => {
    setValue('name', `Variante ${Number(financingVariants?.length) + 1}`);
  };

  return (
    <Modal
      isOpen={isEditFinancingVariantNameOpen}
      wrapperStyles={wrapperStyles as CSSProperties}
      onClose={closeModal}
    >
      {!isMobileSize ? (
        <ModalTitle
          icon={Pencil}
          text={t('Finanzierungsvariante benennen')}
          textAlign="center"
          iconPosition="left"
          isCentered
        />
      ) : (
        <CloseButton
          color={theme.blue}
          position="right"
          handleClose={closeModal}
        />
      )}
      <FinancingVariantNameForm
        onSuggestName={onSuggestName}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export { ModalVariantName };
