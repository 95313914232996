import { FC } from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

import { ReactComponent as Pin } from 'assets/streamline-light/maps-navigation/pins/pin.svg';
import { device } from '../../../../../../style/theme';
import Icon from '../../../../../common/components/ui/icon';
import { useAppSelector } from '../../../../../common/hooks';
import { formatPrice } from 'modules/seller/utils/formatPrice';

const Card = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  gap: 12px;

  @media ${device.tablet} {
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    border: none;
    gap: 0;
  }
`;

const CardImage = styled.img`
  border-radius: 3px;
  width: 220px;
  height: 110px;

  @media ${device.tablet} {
    width: 100%;
    height: 144px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const CardImageLoading = styled.div`
  background: #00305e;
  width: 220px;
  height: 110px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardInfoBox = styled.div`
  flex: 1;

  @media ${device.tablet} {
    border: 1px solid #d9d9d9;
    border-top: none;
    padding: 12px;
  }
`;

const CardTitle = styled.h3<{ isLoading?: boolean }>`
  color: #0f5dc4;
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 20px;
  height: 40px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  //width: 320px;
  ${(props) =>
    props.isLoading &&
    `
    background: #00305E;
    height: 20px;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 1px;
  `}
`;

const CardLocation = styled.div<{ isLoading?: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  ${(props) =>
    props.isLoading &&
    `
    margin-top: 10px;
  `}
`;
const CardLocationText = styled.span<{ isLoading?: boolean }>`
  opacity: 0.8;
  color: #00305e;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.6;
  ${(props) =>
    props.isLoading &&
    `
    background: #00305E;
    height: 16px;
    width: 112px;
    border-radius: 3px;
    opacity: 1;
  `}
`;

const CardDescription = styled.p<{ isLoading?: boolean }>`
  margin: 16px 0 10px;
  color: #00305e;
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1;

  ${(props) =>
    props.isLoading &&
    `
    background-color: #00305E;
    height: 16px;
    width: 75%;
    border-radius: 3px;
  `}
`;

interface IProps {
  title: string;
  fallbackImg?: string;
  description: string;
  location: string;
  image?: string;
  isLoading?: boolean;
}

function fixImgSrc(src: string): string {
  if (
    src.startsWith('http://') ||
    src.startsWith('https://') ||
    src.startsWith('/')
  ) {
    return src;
  }

  if (src.includes('http')) {
    return src.split('_')[1];
  }

  return `https://erp.von-poll.com/import/images/${src}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PropertyCard: FC<IProps> = ({
  title,
  fallbackImg,
  image,
  description,
  location,
  isLoading,
}) => {
  const isSPLoading = useAppSelector((state) => state.searchProfile.isLoading);

  return (
    <Card>
      {isLoading || isSPLoading || (!fallbackImg && !image) ? (
        <CardImageLoading>
          <ClipLoader color="#fff" loading size={36} speedMultiplier={0.75} />
        </CardImageLoading>
      ) : (
        <CardImage src={image ? fixImgSrc(image) : fallbackImg} />
      )}
      <CardInfoBox>
        {isLoading || isSPLoading || !title ? (
          <>
            <CardTitle isLoading />
            <CardTitle isLoading />
          </>
        ) : (
          <CardTitle>{title}</CardTitle>
        )}
        <CardLocation isLoading={isLoading}>
          <Icon icon={Pin} width={16} height={16} color={'#00305E'} />
          {isLoading || isSPLoading || !location ? (
            <CardLocationText isLoading={isLoading} />
          ) : (
            <CardLocationText>{location}</CardLocationText>
          )}
        </CardLocation>
        {isLoading || isSPLoading || !description ? (
          <CardDescription isLoading={isLoading} />
        ) : (
          <CardDescription>{description}</CardDescription>
        )}
      </CardInfoBox>
    </Card>
  );
};

export default PropertyCard;
