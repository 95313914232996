interface INormalizeDataInput {
  min: number;
  max: number;
  current: number;
}

const normalizeData = ({ min, max, current }: INormalizeDataInput): number =>
  (current - min) / (max - min);

export { normalizeData };
