import { KeyboardEvent, ReactNode, useEffect, useRef } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InformationCircle } from 'assets/streamline-light/interface-essential/alerts/information-circle.svg';

import { Tooltip } from '../../../../common/components/tooltip';
import {
  Container,
  TooltipContainer,
  ErrorContainer,
  TooltipParagraph,
  Info,
  InputField,
  InputWrapper,
  ErrorMessage,
  Spacer,
  Label,
  InputAdornment,
  LabelContainer,
  SubLabel,
} from './input-styles';
import { InputMode } from '../../../../common/interfaces';

interface IProps {
  name: string;
  label?: string;
  sublabel?: string;
  placeholder?: string;
  rules?: RegisterOptions;
  defaultValue?: unknown;
  type?: string;
  autoComplete?: 'on' | 'off' | 'nope' | 'false';
  onFocus?: () => void;
  onBlur?: () => void;
  inputWrapperChildren?: ReactNode;
  onOutsideClick?: () => void;
  skipIcon?: boolean;
  tooltipKey?: string;
  isDisabled?: boolean;
  isPlaceholderCenter?: boolean;
  isReadOnly?: boolean;
  inputAdornment?: string;
  inputMode?: InputMode;
}

const Input = ({
  label,
  sublabel,
  name,
  placeholder,
  rules,
  defaultValue,
  type,
  autoComplete = 'on',
  onFocus,
  onBlur,
  onOutsideClick,
  inputWrapperChildren,
  skipIcon = false,
  tooltipKey,
  isDisabled,
  isReadOnly,
  inputAdornment,
  inputMode = 'text',
  isPlaceholderCenter,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        onOutsideClick &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onOutsideClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, onOutsideClick]);

  const formatInput = (e: KeyboardEvent) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-';
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  return (
    <Container hasError={errors[name]}>
      <InputWrapper ref={wrapperRef}>
        <InputField
          id={name}
          {...register(`${name}` as const, rules)}
          {...(defaultValue && { defaultValue })}
          placeholder={t(placeholder ?? '')}
          type={type}
          autoComplete={autoComplete}
          onFocus={onFocus}
          onBlur={onBlur}
          hasError={errors[name]}
          {...(type === 'number' && {
            onKeyDown: formatInput,
          })}
          isDisabled={isDisabled}
          readOnly={isReadOnly}
          inputMode={inputMode}
          isPlaceholderCenter={isPlaceholderCenter}
        />
        {inputAdornment && <InputAdornment>{inputAdornment}</InputAdornment>}
        {!skipIcon && tooltipKey && (
          <Info>
            <Tooltip
              icon={InformationCircle}
              id={tooltipKey}
              position="bottom"
              width={16}
              height={16}
            >
              <TooltipContainer>
                <TooltipParagraph>{t(tooltipKey)}</TooltipParagraph>
              </TooltipContainer>
            </Tooltip>
          </Info>
        )}
        {inputWrapperChildren}
      </InputWrapper>
      {label && (
        <LabelContainer>
          <Label>{t(label)}</Label>
          {sublabel && <SubLabel>{t(sublabel)}</SubLabel>}
        </LabelContainer>
      )}

      {errors[name] && (
        <ErrorContainer>
          <Spacer />
          <ErrorMessage>{t(errors[name].message)}</ErrorMessage>
        </ErrorContainer>
      )}
    </Container>
  );
};

export { Input };
