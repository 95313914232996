import { FC } from 'react';
import CSSReset from "../../../../../style/reset";
import { useIsMobileSize } from "../../../../common/hooks/useIsMobileSize";

import {
  CardGroup,
  Container,
  FormCard,
  FormDescription,
  FormHeader,
  LogoCard,
  LogoDescription,
  LogoHeader,
} from './login-dev-styles';
import { LoginDevForm } from './login-dev-form/login-dev-form';
import { Logo } from './logo';

const LoginDev: FC = () => {

  const isMobile = useIsMobileSize();

  return (
    <Container>
      <CSSReset />
      <CardGroup>
        <FormCard>
          <FormHeader>Login</FormHeader>
          <FormDescription>Sign In to the dev account</FormDescription>
          <LoginDevForm />
        </FormCard>
        <LogoCard>
          <LogoHeader>Dev Tool</LogoHeader>
          <LogoDescription>VON POLL Dev Tool</LogoDescription>
          <Logo width={isMobile ? '220' : '370'} />
        </LogoCard>
      </CardGroup>
    </Container>
  );
};

export { LoginDev };
