import { MainButton } from 'modules/common/components/ui/buttons';
import { FC } from 'react';
import {
  BuyerFinderTeasers,
  OwnerLpSection,
  useReadBuyerFinderTeaserQuery,
  ValuationType,
} from '../../../../../generated';
import {
  ButtonWrap,
  Container,
  Content,
  ContentSection,
  Header,
  Headline,
  Step,
  StepContent,
  StepNumber,
  StepNumberContent,
  StepNumberWrap,
  StepTitle,
  Steps,
  StyledImg,
  Text,
} from './buyer-finder-teaser-styles';

interface IStepProps {
  number: number;
  title: string;
  text: string;
}

const BuyerFinderSectionStep = ({ number, title, text }: IStepProps) => {
  return (
    <Step>
      <StepNumberWrap>
        <StepNumberContent>
          <StepNumber content={number.toString()} />
        </StepNumberContent>
      </StepNumberWrap>
      <StepContent>
        <StepTitle content={title} />
        <Text content={text} />
      </StepContent>
    </Step>
  );
};

interface IProps {
  section: OwnerLpSection;
  handleEvaluationWizardOpen: (type: ValuationType) => void;
}

interface BuyerFinderTeaserResponse {
  data: { data: BuyerFinderTeasers };
  isLoading: boolean;
}

export const BuyerFinderTeaser: FC<IProps> = ({
  handleEvaluationWizardOpen,
  section,
}) => {
  const { recordIds } = section;

  const id = recordIds[0] || '';

  const { data, isLoading } =
    useReadBuyerFinderTeaserQuery<BuyerFinderTeaserResponse>(
      {
        id,
      },
      {
        skip: !id,
      }
    );

  if (isLoading) return null;

  const teaser = data?.data;

  return (
    <Container>
      <Header content={teaser?.subtitle || ''} />
      <Headline content={teaser?.title || ''} />
      <Text content={teaser?.description || ''} />
      <Content>
        <ContentSection>
          <Steps>
            <BuyerFinderSectionStep
              title={teaser?.step1Title || ''}
              text={teaser?.step1Text || ''}
              number={1}
            />
            <BuyerFinderSectionStep
              title={teaser?.step2Title || ''}
              text={teaser?.step2Text || ''}
              number={2}
            />
            <BuyerFinderSectionStep
              title={teaser?.step3Title || ''}
              text={teaser?.step3Text || ''}
              number={3}
            />
          </Steps>
          <ButtonWrap>
            <MainButton
              label={teaser.CTALabel || ''}
              onClick={() => {
                handleEvaluationWizardOpen(ValuationType.Online);
              }}
            />
          </ButtonWrap>
        </ContentSection>
        <ContentSection>
          <StyledImg alt={teaser.imageAlt || ''} src={teaser.imageUrl || ''} />
        </ContentSection>
      </Content>
    </Container>
  );
};
