import { useCallback } from 'react';
import { FlyToInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';
import { batch } from 'react-redux';

import {
  Austria,
  Germany,
  Switzerland,
} from '../../../../map/constants/points-of-interest';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import {
  setMapSettings,
  changeMapViewport,
  openFinancingAction,
  setFinancingType,
} from '../../redux/financingSlice';
import { defaultMapSettings } from '../../../../map/constants';
import { FinancingType } from '../../../../../generated';

type IProps = {
  cb?: () => void;
  isLandingPage?: boolean;
};

type TReturn = {
  openFinancing: (financingType: FinancingType) => void;
};

export const useOpenFinancing = ({ cb }: IProps): TReturn => {
  const relatedCountry = useAppSelector(
    (state) => state.financing.relatedCountry
  );
  const dispatch = useAppDispatch();

  const openFinancing = useCallback(
    (financingType: FinancingType) => {
      let countryCoordinates = Germany;
      switch (relatedCountry && relatedCountry.toLowerCase()) {
        case 'germany':
          countryCoordinates = Germany;
          break;
        case 'austria':
          countryCoordinates = Austria;
          break;
        case 'switzerland':
          countryCoordinates = Switzerland;
          break;
        default:
          countryCoordinates = Germany;
      }

      batch(() => {
        dispatch(
          changeMapViewport({
            ...countryCoordinates,
            transitionDuration: 1000,
            transitionInterpolator: new FlyToInterpolator({
              speed: 0.6,
              curve: 1,
            }),
            transitionEasing: easeCubic,
          })
        );
        dispatch(setMapSettings(defaultMapSettings));
        dispatch(setFinancingType(financingType));
        dispatch(openFinancingAction());
      });
      if (cb) {
        cb();
      }
    },
    [cb, dispatch, relatedCountry]
  );

  return {
    openFinancing,
  };
};
