import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import {
  Headline1,
  Headline3,
  TitleSlogan,
} from '../../../../common/components/ui/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  margin-bottom: 88px;
  @media ${device.tablet} {
    padding: 0;
    align-items: center;
    width: auto;
    margin-bottom: 16px;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 32px 32px;
  @media ${device.tablet} {
    padding: 16px;
  }
`;

export const SliderHeader = styled(TitleSlogan)`
  opacity: 0.8;
  margin-bottom: 4px;
`;

export const Headline = styled(Headline1)`
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const CardBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 336px;
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.09);
  padding: 64px 24px 24px;
  border-radius: 3px;
  margin-top: 48px;
  background-color: ${({ theme }) => theme.white};
  @media ${device.laptop} {
    max-width: 286px;
    padding: 56px 12px 12px;
  }
  @media ${device.tablet} {
    margin-top: 56px;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ImageBox = styled.div`
  position: absolute;
  top: -40px;
  margin: 0 auto;
  height: 80px;
  width: 80px;
  background-color: ${({ theme }) => theme.logoBlue};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledImg = styled.img`
  width: 40px;
  height: 40px;
`;

export const InfoBox = styled.div`
  margin-top: 116px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 632px;
  padding: 48px;
  background-color: ${({ theme }) => theme.logoBlue};
  @media ${device.tablet} {
    position: relative;
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 16px;
  }
`;

export const Title = styled(Headline3)`
  margin: 0 0 12px 0;
  @media ${device.tablet} {
    text-align: center;
  }
`;

export const Description = styled.div`
  font-family: Roboto !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-align: center;
  color: #002f5d;
`;
