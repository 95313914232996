import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Container } from '../../common/components/form/input/input-outside-top-label-styled';
import { Modal } from '../../common/components/modal';
import { SecondaryButton } from '../../common/components/ui/buttons';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { useIsMobileSize } from '../../common/hooks/useIsMobileSize';
import { TimifyPersonalisationIds } from '../interfaces';
import {
  successfulRegistrationFlag,
  toggleTimifyModal,
} from '../redux/timifySlice';
import { TimifySuccess } from './success';
import { TimifyWidget } from './widget';
import { useGetCountry } from '../../localization/get-country';

const HeightWrapper = styled.div`
  min-height: 516px;
  flex: 1;
  display: flex;
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  width: 100%;
`;

const TimifyModal = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const { countryCode } = useGetCountry();

  const isOpen = useAppSelector((state) => state.timify.isModalOpen);
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);

  const firstName = useAppSelector((state) => state.auth.user?.name);
  const lastName = useAppSelector((state) => state.auth.user?.surname);
  const email = useAppSelector((state) => state.auth.user?.email);
  const phone = useAppSelector((state) => state.auth.user?.phone);
  const meta = useAppSelector((state) => state.timify?.meta);
  const reschedule = useAppSelector((state) => state.timify?.reschedule);

  const [isReservationSuccessful, setReservationSuccessful] = useState(false);

  const onClose = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      dispatch(toggleTimifyModal(false));
      dispatch(successfulRegistrationFlag(false));
      setReservationSuccessful(false);
    },
    [dispatch]
  );

  const timifyParams = useMemo(() => {
    if (isAnonymousUser) {
      return {
        serviceExternalIds: TimifyPersonalisationIds.Consultation,
        showFooterBackButton: 'true',
        country: countryCode,
      };
    }
    return {
      serviceExternalIds: TimifyPersonalisationIds.Consultation,
      firstName,
      lastName,
      email,
      phone,
      country: countryCode,
      showFooterBackButton: 'true',
      meta,
      reschedule,
    };
  }, [
    countryCode,
    email,
    firstName,
    isAnonymousUser,
    lastName,
    phone,
    meta,
    reschedule,
  ]);

  const reservationSuccessHandler = useCallback(
    (value: boolean) => {
      setReservationSuccessful(value);
      dispatch(successfulRegistrationFlag(value));
    },
    [dispatch]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      wrapperStyles={{ maxWidth: '512px' }}
    >
      <Container>
        <HeightWrapper>
          {isReservationSuccessful ? (
            <TimifySuccess onClose={onClose} />
          ) : (
            <TimifyWidget
              {...timifyParams}
              setReservationSuccess={reservationSuccessHandler}
            />
          )}
        </HeightWrapper>
      </Container>
      {isMobileSize && (
        <ButtonWrapper>
          <SecondaryButton onClick={onClose} label={t('button.back')} fluid />
        </ButtonWrapper>
      )}
    </Modal>
  );
};

export { TimifyModal };
