import { FC } from 'react';
import { AddressBar } from '../../../../../common/components/ui/hero-slider/address-bar';
import { AddressBarButton, MapBoxContainer } from './mapbox-styles';
import {
  useExposeDetailsHeadInfo,
  useExposeDetailsPriceHubble,
} from '../../hooks/useExposeDetailsQueries';
import { MapboxExposeDetails } from './mapbox';
import { useAppSelector } from '../../../../../common/hooks';
import { PropertyLocation } from '../../../../../../generated';

interface IProps {
  location: PropertyLocation;
}

const MapboxContainer: FC<IProps> = ({ location }) => {
  // const { location } = useExposeDetailsPriceHubble();

  const isFullScreenMapOpen = useAppSelector(
    (state) => state.exposeDetails.isFullScreenMapOpen
  );

  const { latitude, longitude } = location?.coordinates || {};
  const onRoute = () => {
    window.open(
      `https://maps.google.com/?q=${latitude},${longitude}`,
      '_blank'
    );
  };
  // const {
  //   headInfo: { fullAddress },
  // } = useExposeDetailsHeadInfo();

  return (
    <MapBoxContainer isFullScreen={isFullScreenMapOpen}>
      <MapboxExposeDetails />
      <div
        style={{
          height: '64px',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          opacity: 0.9,
        }}
      >
        {/* <AddressBar show onRoute={onRoute} address={fullAddress} /> */}
        <AddressBar
          show
          onRoute={onRoute}
          address={`${location?.address?.street} ${location?.address?.houseNumber}, ${location?.address?.postCode} ${location?.address?.city}`}
        />
        {!isFullScreenMapOpen && (
          <AddressBarButton onClick={onRoute} label={'ROUTE'} />
        )}
      </div>
    </MapBoxContainer>
  );
};

export { MapboxContainer };
