import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../common/hooks';
import {
  setHiddenVariants,
  setWillBeHiddenVariant,
} from '../../forms/form-financing/redux/financingSlice';

const useHiddenVariants = () => {
  const dispatch = useDispatch();
  const hiddenVariants = useAppSelector(
    (state) => state.financing.hiddenVariants
  );
  const willBeHiddenVariant = useAppSelector(
    (state) => state.financing.willBeHiddenVariant
  );

  const onSetWillBeHiddenVariant = (i?: number) => {
    dispatch(setWillBeHiddenVariant(i));
  };

  const onHideVariant = (i: number) => {
    if (hiddenVariants.includes(i)) {
      dispatch(setHiddenVariants(hiddenVariants.filter((item) => item !== i)));
    } else {
      dispatch(setHiddenVariants([...hiddenVariants, i]));
    }
  };
  const onShowAllVariants = () => {
    dispatch(setHiddenVariants([]));
  };

  return {
    hiddenVariants,
    willBeHiddenVariant,
    onHideVariant,
    onShowAllVariants,
    onSetWillBeHiddenVariant,
  };
};

export { useHiddenVariants };
