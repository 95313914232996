import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const ContentWithPadding = styled.div`
  padding: 0 48px;
  position: relative;
  @media ${device.tablet} {
    padding: 0;
  }
`;

export const CTAContainer = styled.div<{ iniframe: boolean }>`
  position: ${({ iniframe }) => (iniframe ? 'static' : 'absolute')};
  bottom: -24px;
  right: 48px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media ${device.tablet} {
    position: static;
    padding: 0 16px;
  }
`;

export const IFrameContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.white};
`;

export const HeaderWrapper = styled.div<{
  isTabListNearTop: boolean;
  floatingBar: boolean;
  isContractSigned?: boolean;
  selectedTabIndex?: number;
}>`
  display: flex;
  position: sticky;
  top: ${({ floatingBar }) => (floatingBar ? '80px' : '80px')};
  margin: 0 0 16px;
  border-radius: ${(props) => (props.isTabListNearTop ? '0 0 3px 3px' : '3px')};
  background-color: ${(props) => props.theme.logoBlue};
  z-index: 4;

  &:hover {
    z-index: 2010;
  }

  ul {
    display: flex;
    align-items: center;
    padding: 0 24px;

    @media ${device.mobile} {
      max-height: 68px;
    }

    > li {
      outline: none !important;
      list-style: none;
      padding: 16px 32px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      text-transform: uppercase;

      @media ${device.mobile} {
        min-width: 140px;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        height: 16px;
        width: 16px;
        border: 1px solid white;
        border-radius: 50%;
        box-sizing: border-box;
        background: #fff;
      }

      &:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: ${(props) => props.theme.blue};
        border-radius: 50%;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
      }

      &.react-tabs__tab--selected {
        overflow: visible;
        cursor: default;
      }

      &:not(.react-tabs__tab--selected) {
        opacity: 0.5;

        &:before {
          background: ${(props) => props.theme.blue};
          display: ${({ selectedTabIndex }) =>
            selectedTabIndex === 4 ? 'none' : 'block'};
        }

        &:after {
          background: #fff;
          display: ${({ selectedTabIndex }) =>
            selectedTabIndex === 4 ? 'none' : 'block'};
        }
      }
    }
  }

  @media ${device.tablet} {
    display: flex;
    overflow: auto;
    white-space: nowrap;
    border-radius: unset;
    height: unset;
    max-width: unset;
    position: static;
    -webkit-box-shadow: inset 25px 0 10px -5px rgb(0 48 94 / 100%),
      inset -25px 0 10px -5px rgb(0 48 94 / 100%);
    box-shadow: inset 25px 0 10px -5px rgb(0 48 94 / 100%),
      inset -25px 0 10px -5px rgb(0 48 94 / 100%);

    ul {
      padding: 0 12px;

      li {
        padding: 0;

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }
    }
  }
`;

export const SubHeaderWrapper = styled.div<{
  floatingBar: boolean;
}>`
  display: flex;
  position: sticky;
  top: ${({ floatingBar }) => (floatingBar ? '156px' : '156px')};
  margin: -16px 0 16px;
  border-radius: 0 0 3px 3px;
  background-color: ${(props) => props.theme.logoBlueLight};
  z-index: 3;

  ul {
    flex: 0.6;
    display: flex;
    align-items: center;
    padding: 0 24px;

    @media ${device.mobile} {
      max-height: 68px;
    }

    > li {
      outline: none !important;
      flex: 0.2;
      list-style: none;
      padding: 10px 0;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      text-transform: uppercase;

      @media ${device.mobile} {
        min-width: 140px;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        height: 16px;
        width: 16px;
        border: 1px solid white;
        border-radius: 50%;
        box-sizing: border-box;
        background: #fff;
      }

      &:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: ${(props) => props.theme.blue};
        border-radius: 50%;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
      }

      &:first-child {
        padding-left: 0;
        &::before,
        &::after {
          margin-left: -16px;
        }
      }

      &.react-tabs__tab--selected {
        overflow: visible;
        cursor: default;
      }

      &:not(.react-tabs__tab--selected) {
        opacity: 0.5;

        &:before {
          background: ${(props) => props.theme.blue};
        }

        &:after {
          background: #fff;
        }
      }
    }
  }

  @media ${device.tablet} {
    display: flex;
    overflow: auto;
    white-space: nowrap;
    border-radius: unset;
    height: unset;
    max-width: unset;
    position: static;
    -webkit-box-shadow: inset 25px 0 10px -5px rgb(0 48 94 / 100%),
      inset -25px 0 10px -5px rgb(0 48 94 / 100%);
    box-shadow: inset 25px 0 10px -5px rgb(0 48 94 / 100%),
      inset -25px 0 10px -5px rgb(0 48 94 / 100%);

    ul {
      padding: 0 12px;

      li {
        padding: 0;

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }
    }
  }
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  cursor: pointer;
  width: 12px;
  height: 12px;
`;

export const PanelContentWrapper = styled.div<{ isTabListNearTop: boolean }>`
  display: flex;
  justify-content: ${(props) =>
    props.isTabListNearTop ? 'space-between' : 'flex-end'};
`;

export const PanelContentRightWrapper = styled.div<{
  isTabListNearTop: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isTabListNearTop ? '36.233%' : '100%')};
  margin-left: ${(props) => (props.isTabListNearTop ? 'auto' : '0')};
  z-index: 1;
  background: #fff;
`;

export const MobileMapContainer = styled.div`
  position: relative;
  height: 160px;
  overflow: hidden;
`;

export const MobileHeadContainer = styled.div`
  position: relative;
`;
