import { SyntheticEvent } from 'react';
import styled from 'styled-components';
import Icon from '../icon';

interface IProps {
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  color?: string;
  $forceActive?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  children?: JSX.Element | JSX.Element[];
  type?: 'button' | 'submit' | 'reset';
  style?: React.CSSProperties | undefined;
}

const StyledButton = styled.button<Omit<IProps, 'icon' | 'children'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent;
  outline: none;
  border: none;
  box-sizing: unset;
  margin: 0 0 0 8px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  text-align: right;
  color: ${(props) => props.color ?? '#fff'};
  text-decoration: none;
  opacity: ${(props) => (props.$forceActive ? 1 : 0.5)};
  transition: opacity 225ms ease;
  cursor: pointer;
  &.active,
  &:hover {
    opacity: 1;
  }
  .icon {
    margin-right: 8px;
  }
`;

const TextButton = ({
  children,
  icon,
  type = 'button',
  ...rest
}: IProps): JSX.Element => (
  <StyledButton {...rest} type={type}>
    {icon && <Icon width={16} height={16} icon={icon} />}
    {children}
  </StyledButton>
);

export { TextButton };
