import { useCallback, useEffect } from 'react';
import { batch } from 'react-redux';
import { isNumber } from 'lodash';

import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { IFinancingForm } from '../../interface';
import {
  clearErrors,
  setCalculateDebitRateCommitment,
  setCalculateFinancingPropositions,
  setErrors,
  toggleLoadingFinancingCalculation,
  updateFinancingCalculationStateAction,
} from '../../redux/financingSlice';
import { FormError } from '../../../interface';
import { useGetFinancingPropositions } from '../callback-hooks/useGetFinancingPropositions';
import {
  FinancingReason,
  FinancingType,
  FinancingVariant,
} from '../../../../../generated';
import { getBirthday } from '../../utils/get-birthday';
import { parseFinancingInput } from '../../utils/parse-financing-input';

interface IHookProps {
  financingVariant?: FinancingVariant;
}

const useSubmitGetVariantPropositions = ({ financingVariant }: IHookProps) => {
  const dispatch = useAppDispatch();
  const { selectedAddress } = useAppSelector((state) => state.financing);
  const { financingVariantData } = financingVariant || {};
  const { getFinancingPropositions, data, isLoading, error } =
    useGetFinancingPropositions();

  const onSubmitGetVariantPropositionsForm = useCallback(
    async (formData: IFinancingForm): Promise<void> => {
      // financing mobile edit widget logic
      const editWidgetContainer = document.querySelector(
        '#modal-root'
      ) as HTMLButtonElement;
      editWidgetContainer.style.display = 'none';
      // document.body.style.overflow = 'overlay !important';
      document.body.setAttribute('style', 'overflow: overlay !important');

      batch(() => {
        dispatch(setCalculateFinancingPropositions());
        dispatch(toggleLoadingFinancingCalculation(true));
        dispatch(clearErrors());
        dispatch(setCalculateDebitRateCommitment(formData.debitRateCommitment));
        dispatch(
          updateFinancingCalculationStateAction({
            reason: formData.reason,
            propertyValue: formData.propertyValue,
            landPrice: formData.landPrice,
            equityCapital: formData.equityCapital,
            buildingCosts: formData.buildingCosts,
            payoutDate: formData.payoutDate,
            estimatedRemainingDebt: formData.estimatedRemainingDebt,
            desiredLoanAmount: formData.desiredLoanAmount,
            debitRateCommitment: formData.debitRateCommitment,
            repaymentRate: formData.repaymentRate,
            desiredRate: formData.desiredRate,
            rateType: formData.rateType,
          })
        );
      });
      if (financingVariantData) {
        let birthday = '';
        if (formData.age && isNumber(formData.age)) {
          birthday = getBirthday(formData.age);
        }
        const { financingVariants } = parseFinancingInput(
          formData,
          FinancingType.Financing,
          selectedAddress
        );

        try {
          await getFinancingPropositions({
            input: {
              ...financingVariants[0].financingVariantData,
              mainEarner: financingVariants[0].personalFinancing.mainEarner,
              householdNetIncome:
                financingVariants[0].personalFinancing.householdNetIncome,
              birthday,
              isMaximumPurchasePrice:
                financingVariants[0].financingVariantData.reason ===
                FinancingReason.PurchaseOfExistingProperty,
            },
          });
        } catch (error_: unknown) {
          batch(() => {
            dispatch(toggleLoadingFinancingCalculation(false));
            dispatch(setErrors(error_ as FormError));
          });
        } finally {
          dispatch(toggleLoadingFinancingCalculation(false));
        }
      }
    },
    [financingVariantData, dispatch, selectedAddress, getFinancingPropositions]
  );

  const errorJSON = error?.message?.split('error.[')?.[1]?.split(']:')?.[0];
  const errorMessage = error
    ? errorJSON
      ? JSON.parse(errorJSON).message?.replaceAll('.', ' ')
      : 'Etwas ist schief gelaufen'
    : '';

  useEffect(() => {
    if (data) {
      dispatch(setCalculateFinancingPropositions(data));
    }
  }, [data]);

  return {
    onSubmitGetVariantPropositionsForm,
    data,
    isLoading,
    error,
    errorMessage,
  };
};

export { useSubmitGetVariantPropositions };
