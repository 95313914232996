import { FC } from 'react';
import {
  OverviewApps as OverviewAppsType,
  OverviewLpSection,
  useReadOverviewAppQuery,
} from '../../../../../generated';
import { App } from '../../regional-lp/apps/apps';
import { AppBox } from '../../regional-lp/apps/apps-styles';

interface IProps {
  section: OverviewLpSection;
  overviewLP?: boolean;
}

interface AppOverviewLPProps {
  id: string;
  overviewLP?: boolean;
}

interface AppResponse {
  data: { data: OverviewAppsType };
  isLoading: boolean;
}

const AppOverviewLandingPage: FC<AppOverviewLPProps> = ({ id, overviewLP }) => {
  const { data, isLoading } = useReadOverviewAppQuery<AppResponse>({
    id,
  });

  if (isLoading) return null;

  const app = data?.data;

  return <App app={app} overviewLP={overviewLP} />;
};

const AppsSection: FC<IProps> = ({ section, overviewLP }) => {
  const { recordIds } = section;

  return (
    <AppBox>
      {recordIds.map((id: string) => {
        return (
          <AppOverviewLandingPage key={id} id={id} overviewLP={overviewLP} />
        );
      })}
    </AppBox>
  );
};

export { AppsSection };
