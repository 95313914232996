import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as File } from 'assets/streamline-light/files-folders/common-files/common-file-text.svg';
import { ReactComponent as Picture } from 'assets/streamline-light/images-photography/pictures/picture-polaroid-landscape.svg';
import { ReactComponent as Plan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as VirtualTour } from 'assets/streamline-light/video-movies-tv/modern-tv/modern-tv-3-d-sync.svg';
import { ReactComponent as Drone } from 'assets/streamline-light/technology/drones/drone-camera.svg';
import { ValuationLoader } from 'modules/forms/components/common/loader';
import { LoaderContainer } from 'modules/seller/pages/seller-properties-page/seller-properties-page-styles';

import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionSection } from '../../accordion/accordion';
import {
  SectionType,
  UploadedBy,
  UploadedFilesSection,
} from '../uploaded-files-section/uploaded-files-section';
import { useGetPropertyFilesQuery } from '../../../../../generated';
import { getSizeFromBase64 } from '../../../../common/components/form/input/input-file/utils/getSizeFromBase64';
import { getFileExtension } from '../../../../common/components/form/input/input-file/utils/getFileExtension';
import { GlobalLoader } from '../../../../common/components/ui/loaders/global-loader';
import { device } from '../../../../../style/theme';
import { BoxTitle } from '../../../../common/components/ui/typography';
import { isImage } from '../../../../common/components/form/input/input-file/utils/isImage';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import {
  setIsGalleryOpen,
  setActiveSlide,
} from '../../../pages/expose-details-overlay/redux/exposeSlice';
import { ServiceOverlay as PhotoGallery } from '../../services/service-overlay/service-overlay';
import {
  ExternalFileType,
  ExternalFileTypes,
  ImageTypes,
  PhotoGalleryTypes,
  PlanTypes,
  VideoTypes,
  VirtualTourTypes,
} from '../../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TotalCount = styled(BoxTitle)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 1.6;
  padding: 2px 8px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.lightBlue};
  background-color: ${(props) => props.theme.greyBackground};
  margin-left: 12px;
`;

interface IProps {
  accordionsState?: { id: string; isOpen: boolean }[];
  onCollapseAccordion?: (id: string) => void;
  searchInput?: string;
  isLocked?: boolean;
}

const FilesOverviewBase: FC<IProps> = ({
  accordionsState,
  onCollapseAccordion,
  searchInput,
  isLocked,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { propertyId } = useParams<{ propertyId: string }>();
  const { propertyFiles, isLoading, overviewError } = useGetPropertyFilesQuery(
    {
      propertyId,
    },
    {
      selectFromResult: ({ data, isLoading: loading, error }) => ({
        propertyFiles: data?.getPropertyFiles,
        isLoading: loading,
        overviewError: error,
      }),
    }
  );

  const [isFloorPlanPreviewOpen, setIsFLoorPlanPreviewOpen] =
    useState<boolean>(false);
  const imagesUrls = useRef<string[]>([]);
  const floorPlansUrls = useRef<string[]>([]);

  const handleOpenFile = useCallback(
    async (fileBase64: string, fileName: string, originalName: string) => {
      //   const fileBlob = await fetch(fileBase64).then((res) => res.blob());
      //   const url = URL.createObjectURL(fileBlob);
      //   window.open(url, '_blank');
      const fileBlob = await fetch(fileBase64).then((res) => res.blob());
      const fileNameExtension = getFileExtension(fileName);
      const originalFileNameExtension = getFileExtension(originalName);
      let fileNameWithExtension = fileName;
      if (fileNameExtension !== originalFileNameExtension) {
        fileNameWithExtension = `${fileName}.${originalFileNameExtension}`;
      }
      const a = document.createElement('a');
      document.body.append(a);
      a.style.setProperty('display', 'none');
      // a.style = 'display: none';
      const url = window.URL.createObjectURL(fileBlob);
      a.href = url;
      a.download = fileNameWithExtension;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    []
  );

  const handleOpenLink = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  const handleOpenGallery = useCallback(
    (imageId: number) => {
      if (imagesUrls.current) {
        dispatch(setActiveSlide(imageId));
        dispatch(setIsGalleryOpen(true));
      }
    },
    [dispatch]
  );

  const { documents, photos, virtualTours, plans, droneVideo } = useMemo(() => {
    const images = propertyFiles?.filter((item) =>
      ImageTypes.includes(item.type)
    );
    const floorPlans = propertyFiles?.filter((item) =>
      PlanTypes.includes(item.type)
    );

    const transformedFiles = propertyFiles?.map((file) => {
      let fileType: string;

      switch (file.type) {
        case ExternalFileType.Expose:
        case ExternalFileType.Notice:
        case ExternalFileType.RentalStatement:
        case ExternalFileType.EnergyCertificate:
        case 'Grundriss':
          fileType = 'application';
          break;
        case 'Foto':
          fileType = 'image';
          break;
        default:
          fileType = '';
          break;
      }

      const fileExtension = getFileExtension(file.filename ?? '');
      const openFileHandler = async (fileOriginalName: string) => {
        const isDoc = fileExtension === 'docx' || fileExtension === 'doc';
        const isGalleryType = PhotoGalleryTypes.includes(file.type);
        const isFloorPlan = PlanTypes.includes(file.type);
        const isPhoto = ImageTypes.includes(file.type);

        if (isGalleryType) {
          setIsFLoorPlanPreviewOpen(isFloorPlan);
          const imagesSet = isPhoto ? images : floorPlans;
          const imageIndex = imagesSet?.findIndex(
            (item) => fileOriginalName === item.originalname
          );
          handleOpenGallery(imageIndex ?? 0);
        } else if (file.url) {
          handleOpenLink(file.url);
        } else {
          await handleOpenFile(
            `data:${fileType}/${isDoc ? 'msword' : fileExtension};base64, ${
              file.content
            }`,
            file?.title ?? file?.filename ?? file.originalname,
            file.originalname
          );
        }
      };

      return {
        type: file.type,
        title: file.title,
        fileName: file.filename ?? file.url ?? '',
        size: file.content ? getSizeFromBase64(file.content) : 0,
        onOpenFile: () => openFileHandler(file.originalname),
        uploadedBy: UploadedBy.Broker,
        url: file.url ?? '',
        previewUrl: isImage(file.filename ?? '')
          ? `data:image/${fileExtension};base64, ${file.content}`
          : '',
      };
    });

    const propertyDocuments = transformedFiles?.filter((file) =>
      ExternalFileTypes.includes(file.type)
    );

    const propertyPhotos = transformedFiles?.filter((file) =>
      ImageTypes.includes(file.type)
    );

    const propertyVirtualTours = transformedFiles?.filter((file) =>
      VirtualTourTypes.includes(file.type)
    );

    const propertyPlans = transformedFiles?.filter((file) =>
      PlanTypes.includes(file.type)
    );

    const propertyDroneVideo = transformedFiles?.filter((file) =>
      VideoTypes.includes(file.type)
    );

    imagesUrls.current = propertyPhotos?.map((photo) => photo.previewUrl) ?? [];
    floorPlansUrls.current =
      propertyPlans?.map((photo) => photo.previewUrl) ?? [];

    return {
      documents: propertyDocuments,
      photos: propertyPhotos,
      virtualTours: propertyVirtualTours,
      plans: propertyPlans,
      droneVideo: propertyDroneVideo,
    };
  }, [handleOpenFile, handleOpenGallery, handleOpenLink, propertyFiles]);

  const sections: AccordionSection[] = useMemo(
    () => [
      {
        _id: 'overview_documents',
        title: t('upload-document.header'),
        expandedContent: (
          <UploadedFilesSection
            sectionType={SectionType.Documents}
            uploadedFiles={documents}
            searchInput={searchInput}
            isLocked={isLocked}
          />
        ),
        icon: File,
        Badge: <TotalCount content={documents?.length?.toString() ?? '0'} />,
      },
      {
        _id: 'overview_images',
        title: t('upload-image.header'),
        expandedContent: (
          <UploadedFilesSection
            sectionType={SectionType.Images}
            uploadedFiles={photos}
            searchInput={searchInput}
            isLocked={isLocked}
          />
        ),
        icon: Picture,
        Badge: <TotalCount content={photos?.length?.toString() ?? '0'} />,
      },
      {
        _id: 'overview_3d_tours',
        title: t('upload-virtual-tour.header'),
        expandedContent: (
          <UploadedFilesSection
            sectionType={SectionType.VirtualTours}
            uploadedFiles={virtualTours}
            searchInput={searchInput}
            isLocked={isLocked}
          />
        ),
        icon: VirtualTour,
        Badge: <TotalCount content={virtualTours?.length?.toString() ?? '0'} />,
      },
      {
        _id: 'overview_drone_footage',
        title: t('upload-drone-footage'),
        expandedContent: (
          <UploadedFilesSection
            sectionType={SectionType.DroneFootages}
            uploadedFiles={droneVideo}
            searchInput={searchInput}
            isLocked={isLocked}
          />
        ),
        icon: Drone,
        Badge: <TotalCount content={droneVideo?.length?.toString() ?? '0'} />,
      },
      {
        _id: 'overview_property_plans',
        title: t('upload-property-plan.header'),
        expandedContent: (
          <UploadedFilesSection
            sectionType={SectionType.PropertyPlans}
            uploadedFiles={plans}
            searchInput={searchInput}
            isLocked={isLocked}
          />
        ),
        icon: Plan,
        Badge: <TotalCount content={plans?.length?.toString() ?? '0'} />,
      },
    ],
    [
      isLocked,
      documents,
      droneVideo,
      photos,
      plans,
      searchInput,
      t,
      virtualTours,
    ]
  );

  if (isLoading) {
    return (
      <LoaderContainer isLoadingMore>
        <ValuationLoader maxWidth="500px" />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      {overviewError?.message && (
        <GlobalError
          title={t(overviewError?.message?.split(':')[0].toLowerCase())}
        />
      )}
      {sections.map((section) => {
        const isAccordionOpen = accordionsState?.find(
          (accordion) => accordion.id === section._id
        )?.isOpen;
        return (
          <Accordion
            key={section._id}
            id={section._id}
            accordionSection={section}
            isOpen={isAccordionOpen}
            onChange={onCollapseAccordion}
          />
        );
      })}
      <PhotoGallery
        images={
          isFloorPlanPreviewOpen ? floorPlansUrls.current : imagesUrls.current
        }
        isFloorPlanGallery={isFloorPlanPreviewOpen}
      />
    </Container>
  );
};

const FilesOverview = memo(FilesOverviewBase);

export { FilesOverview };
