import { FC, memo, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { isEmpty } from 'lodash';
import { ReactComponent as DocumentEditOutlined } from 'assets/common-file-text-edit-outlined.svg';
import { ReactComponent as DocumentEditFilled } from 'assets/common-file-text-edit-filled.svg';
import { ReactComponent as DocumentClick } from 'assets/common-file-text-click.svg';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  ConfirmButton,
  Form,
  Row,
  TextArea,
  TextAreaTitle,
  Title,
} from './expose-state-card-styles';
import { BoxSelector } from '../../box-selector/box-selector';
import {
  ExposeBoxSelectorActions,
  IExposeActionForm,
  PropertyExpose,
} from '../../../interfaces';
import { Comment } from '../../comment/comment';
import { ExposeInfoCard } from '../../info-card/info-card';
import {
  PropertyExposeStatus,
  useSetPropertyExposeStatusMutation,
} from '../../../../../generated';
import { useParseComments } from '../../../hooks/useParseComments';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import { GlobalLoader } from '../../../../common/components/ui/loaders/global-loader';
import { device } from '../../../../../style/theme';
import { useAppSelector } from '../../../../common/hooks';

const LoaderContainer = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  padding: 32px;
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  @media ${device.tablet} {
    padding: 16px;
  }

  &&&& {
    > div {
      position: absolute;
    }
  }
`;

interface IProps {
  expose: PropertyExpose;
  onDownloadPdfExpose?: () => void;
  onOpenOnlineExpose?: () => void;
  isLocked?: boolean;
}

const WaitingApprovalCardBase: FC<IProps> = ({
  expose,
  onDownloadPdfExpose,
  onOpenOnlineExpose,
  isLocked,
}) => {
  const { t } = useTranslation();
  const [showTextArea, setShowTextArea] = useState<boolean>(false);
  const theme = useContext(ThemeContext);
  const { propertyId } = useParams<{ propertyId: string }>();
  const parsedComments = useParseComments(expose.exposeChangesHistory ?? []);

  const [setPropertyExposeStatus, { error: setExposeStatusError, isLoading }] =
    useSetPropertyExposeStatusMutation();

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);
  const isReadOnlyAccess = useAppSelector(
    (state) => state.sellerProperties.isReadOnlyAccess
  );

  const methods = useForm<IExposeActionForm>({
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<IExposeActionForm> = useCallback(
    async (formData) => {
      try {
        await setPropertyExposeStatus({
          propertyId,
          status: formData.actionType as PropertyExposeStatus,
          comment: `${formData.comment} Date: ${new Date().toISOString()}`,
        }).unwrap();
      } catch (error) {
        console.error(error);
      }
    },
    [propertyId, setPropertyExposeStatus]
  );

  const onSelect = useCallback(
    (boxId: string | number) => {
      if (isLocked) {
        return;
      }
      if (boxId === ExposeBoxSelectorActions.Approve) {
        setShowTextArea(false);
        methods.setValue('comment', '');
        methods.handleSubmit(onSubmit)();
      }

      if (boxId === ExposeBoxSelectorActions.RequestChanges) {
        setShowTextArea(true);
      }
    },
    [methods, onSubmit, isLocked]
  );

  if (isLoading) {
    return (
      <LoaderContainer>
        <GlobalLoader />
      </LoaderContainer>
    );
  }

  return (
    <FormProvider {...methods}>
      {setExposeStatusError?.message && (
        <GlobalError
          title={t(setExposeStatusError?.message?.split(':')[0].toLowerCase())}
        />
      )}
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {!isEmpty(parsedComments) && (
          <>
            <ExposeInfoCard
              borderLeftColor={theme.red}
              title={t('seller.expose-card.title')}
              createdOn={expose.createdAt}
            />
            {parsedComments?.map((comment) => (
              <Comment
                userAvatarPlaceholder={comment?.avatarPlaceholder}
                title={comment?.userFullName}
                date={comment?.date}
                text={comment?.text}
              />
            ))}
          </>
        )}
        <ExposeInfoCard
          borderLeftColor={theme.ctaBlue}
          title={t('seller.expose-card.waiting-approval-state.title')}
          description={t(
            'seller.expose-card.waiting-approval-state.description'
          )}
          createdOn={expose.updatedAt || expose.createdAt}
          onDownloadPdfExpose={isLocked ? undefined : onDownloadPdfExpose}
          onOpenOnlineExpose={isLocked ? undefined : onOpenOnlineExpose}
        />
        <Row margin={'12px 0'}>
          <Title content={t('seller.expose-card.confirm-expose.title')} />
        </Row>
        <BoxSelector
          onSelect={onSelect}
          name={'actionType'}
          boxes={[
            {
              id: ExposeBoxSelectorActions.RequestChanges,
              title: t(
                'seller.expose-card.box-selector.request-expose-change.title'
              ),
              description: t(
                'seller.expose-card.box-selector.request-expose-change.description'
              ),
              icon: DocumentEditOutlined,
              activeIcon: DocumentEditFilled,
              value: PropertyExposeStatus.ChangesRequested,
            },
            {
              id: ExposeBoxSelectorActions.Approve,
              title: t('seller.expose-card.box-selector.confirm-expose.title'),
              description: t(
                'seller.expose-card.box-selector.confirm-expose.description'
              ),
              icon: DocumentClick,
              activeIcon: DocumentClick,
              value: PropertyExposeStatus.Approved,
              isDark: true,
            },
          ]}
        />
        {showTextArea && !isLocked && (
          <>
            <TextAreaTitle
              content={t(
                'seller.expose-card.text-area.request-expose-change.title'
              )}
            />
            <TextArea
              rows={10}
              placeholder={t(
                'seller.expose-card.text-area.request-expose-change.placeholder'
              )}
              {...methods.register('comment')}
            />
            <ConfirmButton
              fluid
              isDisabled={
                !methods.watch('comment') || isBrokerView || isReadOnlyAccess
              }
              backgroundColor={theme.blue}
              label={t('seller.expose-card.button.request-expose-change.label')}
            />
          </>
        )}
      </Form>
    </FormProvider>
  );
};

const WaitingApprovalCard = memo(WaitingApprovalCardBase);

export { WaitingApprovalCard };
