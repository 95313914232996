import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Comparable,
  OffersFiltersInput,
  OffersSearchInput,
} from '../../../generated';

interface InitialState {
  filters: OffersSearchInput | unknown;
  similarProperties: Comparable[];
  totalItems: number;
  initialLoader: boolean;
}

const initialState: InitialState = {
  filters: {},
  similarProperties: [],
  totalItems: 0,
  initialLoader: true,
};

export const similarPropertiesSlice = createSlice({
  name: 'similarProperties',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<OffersSearchInput>) {
      state.filters = action.payload;
    },
    mergeFilters(state, action: PayloadAction<Partial<OffersSearchInput>>) {
      state.filters = {
        ...(state.filters as OffersSearchInput),
        ...action.payload,
      };
    },
    applyDeepFilters(state, action: PayloadAction<OffersFiltersInput>) {
      (state.filters as OffersSearchInput).filters = {
        ...(state.filters as OffersSearchInput).filters,
        ...action.payload,
      };
    },
    clearPropertiesCache(state) {
      state.similarProperties = [];
    },
    addSimilarProperties(state, action: PayloadAction<Comparable[]>) {
      state.similarProperties = [...state.similarProperties, ...action.payload];
    },
    setTotalItems(state, action: PayloadAction<number>) {
      state.totalItems = action.payload;
    },
    setInitialLoader(state, action: PayloadAction<boolean>) {
      state.initialLoader = action.payload;
    },
  },
});

export const {
  setFilters,
  mergeFilters,
  applyDeepFilters,
  clearPropertiesCache,
  addSimilarProperties,
  setTotalItems,
  setInitialLoader,
} = similarPropertiesSlice.actions;

export default similarPropertiesSlice.reducer;
