import Collapse, { Panel } from 'rc-collapse';
import { CollapsePanelProps } from 'rc-collapse/lib/interface';
import { memo, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { AnyAction } from '@reduxjs/toolkit';

import { ReactComponent as ArrowDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';

import { Poi } from '../../../../../generated';
import motion from '../../ui/faq-accordion/utils';
import Icon from '../../ui/icon';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getInfrastructureMeta } from '../../../utils/infrastructure-meta';
import { IMapPin } from '../../../../map/interfaces';
import { ILocationScore } from '../../../interfaces';
import {
  Container,
  ContentBody,
  ContentHeader,
  ContentWrapper,
  HalfSizedWrapper,
  MainHeader,
} from './infrastructure-components';

interface IProps {
  title: string;
  content: Record<string, unknown>;
  score: ILocationScore;
  isShrinked?: boolean;
  isNewScoreFormat?: boolean;
  currentMapboxMarkers: IMapPin[];
  addMarkers: (pins: IMapPin[]) => AnyAction;
  removeMarkers: (category: string) => AnyAction;
}

const InfrastructureCardBase = ({
  title,
  content,
  score,
  isShrinked,
  isNewScoreFormat,
  currentMapboxMarkers,
  addMarkers,
  removeMarkers,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const isTabListNearTop = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );

  const [subcategories, setSubcategories] = useState(
    Object.keys(content || {})
  );
  const [removedMarkers, setRemovedMarkers] = useState<{
    [key: string]: IMapPin[] | undefined;
  }>({});

  const { title: generatedTitle, icon } = getInfrastructureMeta(title);
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const onSetSubcategories = useCallback(
    (checked: boolean, category: string) => {
      const isRemovingCategory = !checked && subcategories.includes(category);
      const newSubs: string[] = isRemovingCategory
        ? subcategories.filter((cat) => cat !== category)
        : [category, ...subcategories];
      setSubcategories(newSubs);

      if (isRemovingCategory) {
        const markersToRemove = currentMapboxMarkers.filter(
          (m) => m.category === category
        );
        dispatch(removeMarkers(category));
        setRemovedMarkers({ ...removedMarkers, [category]: markersToRemove });
      } else {
        dispatch(addMarkers(removedMarkers[category] as IMapPin[]));
        setRemovedMarkers({
          ...removedMarkers,
          [category]: [],
        });
      }
    },
    [
      subcategories,
      currentMapboxMarkers,
      dispatch,
      removeMarkers,
      removedMarkers,
      addMarkers,
    ]
  );

  return (
    <Container>
      <Collapse
        defaultActiveKey="1"
        openMotion={motion}
        className="outer-accordion"
        expandIcon={({ isActive }: CollapsePanelProps) => (
          <Icon
            width={16}
            height={16}
            icon={ArrowDown}
            color={themeContext.blue}
            style={{
              transition: `transform 500ms ease`,
              transform: isActive ? 'rotate(-180deg)' : 'none',
            }}
          />
        )}
      >
        <Panel
          key="1"
          header={
            <MainHeader
              title={t(generatedTitle)}
              location={
                isNewScoreFormat ? t(score.searchProfileLabel) : t(score.label)
              }
              icon={icon}
              rating={score.score}
              color={score.color}
              isShrinked={isShrinked || false}
            />
          }
          headerClass="outer-header"
          className="outer-content"
        >
          <ContentWrapper isShrinked={isTabListNearTop || isShrinked}>
            {content &&
              Object.keys(content).map((subcategoryKey: string) => {
                const subCatsPois = (content[subcategoryKey] as Poi[]) || [];
                return subCatsPois.length > 0 ? (
                  <HalfSizedWrapper
                    key={subcategoryKey}
                    isShrinked={isTabListNearTop || isShrinked}
                  >
                    <Collapse
                      defaultActiveKey={subcategoryKey}
                      openMotion={motion}
                      className="inner-accordion"
                      expandIcon={({ isActive }: CollapsePanelProps) => (
                        <Icon
                          width={16}
                          height={16}
                          icon={ArrowDown}
                          color={themeContext.blue}
                          style={{
                            transition: `transform 500ms ease`,
                            transform: isActive ? 'rotate(-180deg)' : 'none',
                          }}
                        />
                      )}
                    >
                      <Panel
                        key={subcategoryKey}
                        header={
                          <ContentHeader
                            locationType={subcategoryKey}
                            isChecked={subcategories?.includes(subcategoryKey)}
                            onChange={onSetSubcategories}
                          />
                        }
                        headerClass="inner-header"
                        className="inner-content"
                      >
                        {subCatsPois
                          ?.slice(0, 5)
                          ?.map((point: Poi, innerIndex: number) => (
                            <ContentBody
                              key={`${point.location?.coordinates?.latitude} - ${point.location?.coordinates?.longitude} - ${point.name} - ${point.category} - ${point.subcategory} - ${innerIndex}`}
                              name={
                                point.name ||
                                t(
                                  `infrastructure.titles.${subcategoryKey.toLowerCase()}`
                                )
                              }
                              distance={point.distance as number}
                            />
                          ))}
                      </Panel>
                    </Collapse>
                  </HalfSizedWrapper>
                ) : null;
              })}
          </ContentWrapper>
        </Panel>
      </Collapse>
    </Container>
  );
};

const InfrastructureCard = memo(InfrastructureCardBase);

export { InfrastructureCard };
