import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { PurchaseIntentType } from '../../../../../generated';
import { RootState } from '../../../../../store';
import { RadioInput } from '../../../../common/components/form/input/input-radio';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import {
  businessOptions,
  countryOptions,
  legalFormOptions,
  salutationOptions,
  titleOptions,
} from '../../constants/options';
import { changeType } from '../../redux/purchaseIntendSlice';

const TypeSelectorContainer = styled.div<{ isMobileSize?: boolean }>`
  font-family: Roboto;
  display: flex;
  margin-top: 24px;
  margin-bottom: 12px;
  ${(props) =>
    props.isMobileSize &&
    `
    margin-top: 0;
    flex-direction: column;
  `}
`;

const Divider = styled.div`
  width: 12px;
  height: 12px;
`;

const PurchaseTypeSelector = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();
  const { reset } = useFormContext();

  const { type } = useSelector((state: RootState) => state.purchaseIntend);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value === PurchaseIntentType.Physical) {
      reset({
        salutation: salutationOptions[0].value,
        title: titleOptions[2].value,
        otherPersons: [],
      });
    }

    if (value === PurchaseIntentType.Legal) {
      reset({
        legalForm: legalFormOptions[0].value,
        business: businessOptions[0].value,
        headOfficeCountry: countryOptions[0].value,
        salutation: salutationOptions[0].value,
        title: titleOptions[2].value,
        otherPersons: [],
      });
    }

    dispatch(changeType(value));
  };

  return (
    <TypeSelectorContainer isMobileSize={isMobileSize}>
      <RadioInput
        label={t('purchase-intent.form.physicalPerson')}
        checked={type === PurchaseIntentType.Physical}
        value={PurchaseIntentType.Physical}
        onChange={changeHandler}
      />
      <Divider />
      <RadioInput
        label={t('purchase-intent.form.legalPerson')}
        checked={type === PurchaseIntentType.Legal}
        value={PurchaseIntentType.Legal}
        onChange={changeHandler}
      />
    </TypeSelectorContainer>
  );
};

export default PurchaseTypeSelector;
