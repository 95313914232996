import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableTd } from './table-form-data-styles';
import { BlueLine } from '../item-container/item-container-styles';

interface IProps {
  formData: Record<string, string>;
}

const TableFormData: FC<IProps> = ({ formData }) => {
  const { t } = useTranslation();
  const keys = Object.keys(formData);

  return (
    <Table>
      <tbody>
        {!!keys?.length &&
          keys.map((key) => {
            return (
              <tr key={key}>
                <TableTd isBold>{t(key)}</TableTd>
                <TableTd isValue={!!formData[key]}>
                  {formData[key] ? t(formData[key]) : <BlueLine />}
                </TableTd>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export { TableFormData };
