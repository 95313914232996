import { useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import styled from 'styled-components';

import { useChangePasswordMutation, UserState } from '../../../../generated';
import { device } from '../../../../style/theme';
import { InputPassOutsideTopLabel } from '../../../common/components/form';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { FormColumn, FormRow } from '../../../common/components/form/form-grid';
import { MainButton } from '../../../common/components/ui/buttons';

import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { ICreatePasswordForm, IRegisterForm } from '../../interfaces';
import {
  clearTempPassword,
  setUserState,
  toggleIsCreatePasswordModalOpen,
} from '../../redux/authSlice';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  @media ${device.tablet} {
    height: 100%;
  }
`;

const FormRowWithMargin = styled(FormRow)`
  margin-bottom: 20px;
`;

const SubmitButton = styled(MainButton)`
  align-self: flex-end;
  @media ${device.tablet} {
    margin-top: auto;
  }
`;

const CreatePasswordForm = (): JSX.Element => {
  const methods = useForm<IRegisterForm>({
    mode: 'onTouched',
  });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const [
    createPassword,
    { error: createPasswordErrors, data: createPasswordData, isLoading },
  ] = useChangePasswordMutation();
  const accessToken = useAppSelector((state) => state.auth.accessToken);

  const onSubmit: SubmitHandler<ICreatePasswordForm> = useCallback(
    async (formData) => {
      if (accessToken) {
        const { password } = formData;

        await createPassword({
          input: {
            newPassword: password,
            accessToken,
          },
        });
      }
    },
    [createPassword, accessToken]
  );

  useEffect(() => {
    if (createPasswordData?.changePassword) {
      batch(() => {
        dispatch(setUserState(UserState.Verified));
        dispatch(clearTempPassword());
        dispatch(toggleIsCreatePasswordModalOpen(false));
      });
    }
  }, [dispatch, createPasswordData]);

  return (
    <FormProvider {...methods}>
      {createPasswordErrors && (
        <GlobalError
          title={t(
            (createPasswordErrors as { message: string })?.message
              ?.split(':')?.[0]
              .toLowerCase()
          )}
        />
      )}
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormRowWithMargin>
          <FormColumn flex="0 0 50%">
            <InputPassOutsideTopLabel
              name="password"
              type="password"
              label="register.form.input.label.password"
              placeholder="register.form.input.placeholder.password"
              rules={{
                required: 'register.input.error.required',
                minLength: {
                  value: 10,
                  message: 'error.password.did.not.conform.with.policy',
                },
              }}
              passwordMasterValue={methods.watch('password')}
            />
          </FormColumn>
          <FormColumn flex="0 0 50%">
            <InputPassOutsideTopLabel
              name="confirmPassword"
              type="password"
              label="register.form.input.label.confirm-password"
              placeholder="register.form.input.placeholder.confirm-password"
              rules={{ required: 'register.input.error.required' }}
              passwordMasterValue={methods.watch('password')}
              skipPattern
            />
          </FormColumn>
        </FormRowWithMargin>
        <SubmitButton
          label={t('modal.create-password.submit')}
          loader
          isLoading={isLoading}
          isDisabled={isLoading}
          fluid={isMobileSize}
        />
      </Form>
    </FormProvider>
  );
};

export { CreatePasswordForm };
