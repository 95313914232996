import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useController } from 'react-hook-form';

import { CSSProperties } from 'react';
import Select from '../../../common/components/form/select/select';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';

interface IProps {
  name: string;
  isDisabled?: boolean;
  isRedesign?: boolean;
  selectedOption: string | number;
  options: {
    label: string | number;
    value: string | number | boolean | null;
  }[];
  label?: string;
  defaultValue?: number | string;
  innerLabel?: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  onSelect?: (value: unknown) => void;
  style?: CSSProperties;
}

const Container = styled.div<{ isRedesign?: boolean }>`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
  margin-top: ${({ isRedesign }) => (isRedesign ? 8 : 12)}px;
  position: relative;
`;

const Label = styled.label`
  order: 1;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
`;

const SelectWrapper = styled.div`
  position: relative;
  order: 2;
  flex: 2.125;

  &:hover {
    & + label {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }

  > div {
    > div {
      > div {
        width: 100%;
        > div {
          line-height: 1.2;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
`;

const SelectWithLabel = ({
  name,
  isDisabled = false,
  isRedesign,
  label,
  selectedOption,
  options,
  defaultValue,
  onSelect,
  innerLabel,
  style,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    field: { ref, value, onChange },
  } = useController({
    name,
    defaultValue,
  });

  const onValueChanged = (option: unknown) => {
    if (onSelect) {
      onSelect(option);
    }
    onChange(option);
  };

  return (
    <Container isRedesign={isRedesign} style={style}>
      <SelectWrapper>
        <Select
          isDisabled={isDisabled}
          isRedesign={isRedesign}
          isBigger
          selectedOption={selectedOption}
          options={options}
          onSelect={onValueChanged}
          innerLabel={innerLabel}
        />
      </SelectWrapper>
      {label && <Label>{t(label)}</Label>}
    </Container>
  );
};

export { SelectWithLabel };
