import { FC, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import loginUserIcon from 'assets/images/icons/financing-form/auth/login-user.png';

import {
  UserIcon,
  BlueCircle,
  ImageContainer,
  InfoItemsContainer,
  InfoTitle,
  LoginButton,
  MainButtonContainer,
  OneInfoItem,
  ProvenExpertImage,
  TextItem,
  UserIconContainer,
} from './login-register-screen-styles';
import provenExpert from '../../../../../../assets/images/icons/property-valuation/proven-expert.png';
import { MainButton } from '../../../../../common/components/ui/buttons';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { Title } from '../styles';
import { setArrowPosition } from '../../../../../auth/redux/authSlice';

interface IProps {
  isShowTabletExpertImg: boolean;
  hideInfo?: boolean;
  isHideBlueCircle?: boolean;
  loginButtonLabel: string;
  registerButtonLabel: string;
  infoTitle: string;
  information: string[];
  onOpenLogin: () => void;
  onOpenRegister: () => void;
}

const LoginRegisterScreen: FC<IProps> = ({
  children,
  isShowTabletExpertImg,
  isHideBlueCircle,
  hideInfo,
  loginButtonLabel,
  registerButtonLabel,
  infoTitle,
  information,
  onOpenRegister,
  onOpenLogin,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const offset = ref.current?.getBoundingClientRect();

  useEffect(() => {
    dispatch(
      setArrowPosition({
        top: Number(offset?.top) - 70,
        left: Number(offset?.left) + 30,
      })
    );
  }, [offset?.top, offset?.left]);

  const isMobileSize = useIsMobileSize();

  return (
    <>
      {children}
      {!hideInfo && (
        <>
          <UserIconContainer>
            <UserIcon src={loginUserIcon} alt="user icon" />
          </UserIconContainer>
          <Title>
            {t('property-valuation.form.redesign.tenth-step.title')}
          </Title>
          <InfoTitle>{t(infoTitle)}</InfoTitle>
          <InfoItemsContainer>
            {information.map((item) => (
              <OneInfoItem key={item}>{t(item)}</OneInfoItem>
            ))}
          </InfoItemsContainer>
        </>
      )}
      {isMobileSize && isShowTabletExpertImg && (
        <ImageContainer>
          <ProvenExpertImage src={provenExpert} alt={'proven expert'} />
        </ImageContainer>
      )}
      <MainButtonContainer>
        <MainButton
          centered
          isFullWidth
          onClick={onOpenRegister}
          label={t(registerButtonLabel).toUpperCase()}
        />
        {/* {!isHideBlueCircle && <BlueCircle ref={ref} />} */}
      </MainButtonContainer>
      <TextItem>
        {t('property-valuation.form.redesign.is-registered.label')}
      </TextItem>
      <LoginButton
        onClick={onOpenLogin}
        label={t(loginButtonLabel).toUpperCase()}
      />
    </>
  );
};

export { LoginRegisterScreen };
