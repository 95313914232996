import styled from 'styled-components';
import { device } from '../../../../style/theme';
import {
  Headline2,
  ParagraphText,
  TitleSlogan,
} from '../../../common/components/ui/typography';

const Container = styled.div<{ backgroundColor?: string; color?: string }>`
  &&& {
    width: 100%;
    padding: 32px 80px 32px 32px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    color: ${(props) => props.theme.blue};
    @media ${device.tablet} {
      padding: 16px;
      max-width: unset;
    }
  }
`;

const Header = styled(TitleSlogan)`
  opacity: 0.8;
  text-transform: uppercase;
`;

const Title = styled(Headline2)`
  margin: 4px 0 16px;
  line-height: 1.34;
  @media ${device.tablet} {
    margin-bottom: 12px;
  }
`;

const Content = styled(ParagraphText)`
  margin-bottom: 16px;
  line-height: 1.72;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 39vw !important;
  }
`;

const IconRow = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-row: 2;
  row-gap: 10px;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-rows: 4;
  }
`;

export { Container, Header, Title, Content, IconContainer, IconRow };
