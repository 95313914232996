import BigCardContainer from './elements/big-card-container';
import { CardContent } from './elements/card-content';
import { CardTitle } from './elements/card-title';

interface IProps {
  title: string;
  content: string;
}

const SocialEconomicsDataSourceCard = ({
  title,
  content,
}: IProps): JSX.Element => (
  <BigCardContainer>
    <CardTitle content={title} />
    <CardContent content={content} />
  </BigCardContainer>
);

export { SocialEconomicsDataSourceCard };
