import { FC } from 'react';

import GreenMarkIcon from 'assets/images/icons/financing-form/info-container/greenMark.png';

import {
  Container,
  IconContainer,
  Icon,
  LeftSideContainer,
  Line,
  RightSideContainer,
  Title,
  Headline,
  Content,
  MarkIcon,
  BlueLine,
} from './item-container-styles';

interface IProps {
  title?: string;
  headline: string;
  imageSrc: string;
  imageAlt: string;
  isMark: boolean;
}

const ItemContainer: FC<IProps> = ({
  title,
  headline,
  imageSrc,
  imageAlt,
  isMark,
  children,
}) => {
  return (
    <Container>
      <LeftSideContainer>
        <IconContainer>
          <Icon src={imageSrc} alt={imageAlt} />
          {isMark && <MarkIcon src={GreenMarkIcon} alt="Green Mark Icon" />}
        </IconContainer>
        {children && <Line />}
      </LeftSideContainer>
      <RightSideContainer>
        <Headline>{headline}</Headline>
        <Title>{title || <BlueLine />}</Title>
        {children && <Content>{children}</Content>}
      </RightSideContainer>
    </Container>
  );
};

export { ItemContainer };
