import { memo, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import { ReactComponent as Check } from 'assets/streamline-light/interface-essential/form-validation/check-1.svg';

import {
  MainButton,
  SecondaryButton,
} from '../../../../common/components/ui/buttons';
import {
  Headline3,
  TitleSlogan,
} from '../../../../common/components/ui/typography';
import { VerificationPin } from '../../../../common/components/verification-input';
import { StatusType } from '../../../../common/components/verification-input/types';
import { InputOutsideTopLabelWithSelect } from '../../../../common/components/form';
import { IEditProfile, InputType } from '../../../interfaces';
import { phonePrefixes, REGISTER_FORM_KEYS } from '../../../constants';
import { useGetCountry } from '../../../../localization/get-country';
import { Form } from '../buyer-sign-up-page-styles';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';

interface IProps {
  onVerifyPhone(code: string): void;
  status: StatusType;
  onClose?: () => void;
  onRequestConfirmationCode: (formData: IEditProfile) => void;
}

const PhoneWrapper = styled.div<{ isMobileSize?: boolean }>`
  justify-content: center;
  display: flex;
  position: relative;

  &&& {
    .select div,
    .select input {
      background-color: ${(props) => props.theme.white};
      height: 64px;
      border-color: ${(props) => props.theme.ctaDisabledBackground};
      font-family: source serif pro, sans-serif;
      font-size: ${(props) => (props.isMobileSize ? '24px' : '36px')};
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.5px;
      text-align: center;
      color: ${(props) => props.theme.blue};
      display: flex;
      align-items: center;
    }

    .select input {
      max-width: 320px;
    }
  }
`;

const CodeInformationWrapper = styled.div`
  text-align: center;
  padding: 0 0 24px 0;
`;

const VerificationCodeActionsWrapper = styled.div`
  padding: 16px 0 24px;
`;
const ActionTitlesHolder = styled.div`
  display: flex;
  justify-content: center;
`;

const Action = styled.div`
  transition: opacity 225ms ease;
  min-height: 40px;
`;

const ActionTitle = styled(TitleSlogan)<{ color: string }>`
  text-transform: uppercase;
  padding: 0 12px;
  color: ${(props) => props.color};
  text-align: center;
  opacity: 0.3;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`;

const ChangePhoneFormBase = ({
  onVerifyPhone,
  status,
  onClose,
  onRequestConfirmationCode,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const isMobileSize = useIsMobileSize();

  const [confirmationCode, setConfirmationCode] = useState('');
  const [isDisabled, setDisabled] = useState(false);
  const { phonePrefix } = useGetCountry();
  const methods = useForm<IEditProfile>();

  const handleClickSubmit = () => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 30_000);
  };

  // When user finish entering the code
  const handleOnFinish = useCallback((userConfirmationCode: string) => {
    setConfirmationCode(userConfirmationCode);
  }, []);

  const handleOnVerifyPhone = useCallback(
    () => onVerifyPhone(confirmationCode),
    [onVerifyPhone, confirmationCode]
  );
  const selectOptions = phonePrefixes.includes(phonePrefix)
    ? phonePrefixes
    : [phonePrefix, ...phonePrefixes];

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onRequestConfirmationCode)}>
        <PhoneWrapper isMobileSize={isMobileSize}>
          <InputOutsideTopLabelWithSelect
            type={InputType.number}
            name={REGISTER_FORM_KEYS.PHONE}
            label=""
            rules={{
              required: 'register.input.error.required',
              valueAsNumber: true,
            }}
            selectWidth="120px"
            hasSelect
            selectOptions={selectOptions}
            selectValue={phonePrefix}
            selectName={REGISTER_FORM_KEYS.PHONE_PREFIX}
            className="select"
          />
          <MainButton
            icon={Check}
            iconHeight={16}
            iconWidth={16}
            label=""
            isDisabled={isDisabled}
            onClick={handleClickSubmit}
            style={{
              position: 'absolute',
              top: '30px',
              right: isMobileSize ? '4px' : '96px',
              height: '56px',
              width: '40px',
              padding: '20px 12px',
            }}
            iconStyle={{
              display: 'flex',
              margin: 0,
            }}
          />
        </PhoneWrapper>
        <VerificationCodeActionsWrapper>
          <ActionTitlesHolder>
            <Action>
              <ActionTitle
                content={t('confirmation-code.send-again')}
                color={themeContext.blue}
              />
            </Action>
            <Action>
              <ActionTitle
                content={t('confirmation-code.change-phone')}
                color={themeContext.ctaBlue}
              />
            </Action>
          </ActionTitlesHolder>
        </VerificationCodeActionsWrapper>
        <CodeInformationWrapper>
          <Headline3
            content={t('modal.register.second-step.code.verification.title')}
          />
        </CodeInformationWrapper>
        <VerificationPin
          type="number"
          currentCode={confirmationCode}
          inputsNumber={6}
          status={status}
          onFinish={handleOnFinish}
        />
      </Form>
      <ButtonWrapper>
        {onClose && (
          <SecondaryButton
            label={t('modal.register.second-step.button.skip')}
            onClick={onClose}
            color={themeContext.blue}
            borderColor={themeContext.blue}
          />
        )}
        <MainButton
          isDisabled={confirmationCode.length < 6}
          label={t('buyer-sign-up-page.verify-account')}
          onClick={handleOnVerifyPhone}
        />
      </ButtonWrapper>
    </FormProvider>
  );
};

const ChangePhoneForm = memo(ChangePhoneFormBase);

export { ChangePhoneForm };
