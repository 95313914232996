import { useCallback } from 'react';
import { batch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import {
  clearErrors,
  closeFinancingModal,
  resetFinancing,
  setErrors,
  setVonPollPropertyData,
  toggleLoading,
  toggleLoadingCreating,
  updateFormStateAction,
} from '../../redux/financingSlice';
import { toggleLoading as toggleLoadingAuth } from '../../../../auth/redux/authSlice';
import { siteMap } from '../../../../../routes/site-map';
import { FormError } from '../../../interface';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { useLngHistoryPush } from '../../../../localization/lng-history-push';
import { IFinancingForm } from '../../interface';
import { MODAL_ANIMATION_DURATION } from '../../../../common/components/modal/modal';
import { parseFinancingInput } from '../../utils/parse-financing-input';
import { useCreateFinancing } from '../callback-hooks/useCreateFinancing';
import { FinancingType } from '../../../../../generated';

interface IProps {
  isOnLandingPage?: boolean;
}

interface IReturn {
  onCreateFinancing: (
    formData: IFinancingForm,
    ownerId?: string
  ) => Promise<void>;
  financingData: unknown;
  financingError: unknown;
}

const useSubmitFinancingForm = ({ isOnLandingPage }: IProps): IReturn => {
  const dispatch = useAppDispatch();
  const lngHPush = useLngHistoryPush();
  const { reset } = useFormContext();
  const {
    createFinancing,
    data: financingData,
    error: financingError,
  } = useCreateFinancing();

  const { selectedAddress, financingType, vonPollPropertyData } =
    useAppSelector((state) => state.financing);
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const onCreateFinancing = useCallback(
    async (formData: IFinancingForm, ownerId?: string): Promise<void> => {
      if (selectedAddress && formData) {
        batch(() => {
          dispatch(toggleLoading(true));
          dispatch(toggleLoadingCreating(true));
          dispatch(toggleLoadingAuth(true));
          dispatch(clearErrors());
          dispatch(updateFormStateAction(formData));
        });

        if (ownerId) {
          try {
            const input = parseFinancingInput(
              formData,
              financingType || FinancingType.Financing,
              selectedAddress
            );

            if (vonPollPropertyData) {
              input.vonPollPropertyData = vonPollPropertyData;
            }
            const financing = await createFinancing({
              financingInput: input,
              userId: ownerId,
            });

            if (isIframe) {
              // if (document.fullscreenElement && document.exitFullscreen) {
              //   document.exitFullscreen();
              // }
              window.open(
                `${window.location.origin}${siteMap.FinancingPortalPage.pathWithoutParams}/${financing.createFinancing?._id}`,
                '_blank',
                'noopener,noreferrer'
              );
            } else if (financing.createFinancing?._id && !isOnLandingPage) {
              setTimeout(() => {
                lngHPush(
                  `${siteMap.FinancingPortalPage.pathWithoutParams}/${financing.createFinancing?._id}`
                );
              }, 300);
            }
          } catch (error: unknown) {
            batch(() => {
              dispatch(toggleLoading(false));
              dispatch(toggleLoadingCreating(false));
              dispatch(toggleLoadingAuth(false));
              dispatch(setErrors(error as FormError));
            });
          } finally {
            if (!isIframe) {
              dispatch(closeFinancingModal());
            }

            setTimeout(() => {
              reset({});
              dispatch(
                resetFinancing(
                  isIframe ? { isFinancingOpen: true, financingType } : {}
                )
              );
            }, MODAL_ANIMATION_DURATION);
            dispatch(toggleLoadingCreating(false));
            dispatch(toggleLoadingAuth(false));
            dispatch(setVonPollPropertyData());
          }
        }
      }
    },
    [
      selectedAddress,
      dispatch,
      createFinancing,
      isIframe,
      isOnLandingPage,
      lngHPush,
      reset,
    ]
  );

  return { onCreateFinancing, financingData, financingError };
};

export { useSubmitFinancingForm };
