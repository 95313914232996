import 'core-js/features/string/replace-all';

export const formatNumber = (number?: number | null): string => {
  if (!number) return '0';

  const [whole, decimal] = number.toLocaleString('en-US').split('.');

  const wholeFormated = whole.replaceAll(',', '.');

  if (decimal) {
    return `${wholeFormated},${decimal.slice(0, 2)}`;
  }

  return wholeFormated;
};
