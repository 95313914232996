import { easeCubic } from 'd3-ease';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlyToInterpolator } from 'react-map-gl';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { ReactComponent as ArrowButtonLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { GeneralAppointments } from '../../components/regional-lp/general-appointment/general-appointment';

import {
  ContentWithPadding,
  IconWrapper,
  IFrameContainer,
  ValuationBox,
} from './regional-landing-page-styles';
import {
  CmsRegionalPage,
  RpSection,
  RpSectionType,
  useCmsRegionalPageQuery,
  useGeneralMarketReportByCountryQuery,
  useMarketReportQuery,
  ValuationType,
} from '../../../../generated';
import { usePropertiesQuery } from '../../../../services/graphql/enhanced';
import { Card } from '../../../common/components/ui/card';
import { Hero } from '../../../common/components/ui/hero';
import { HeroSlider } from '../../../common/components/ui/hero-slider/hero-slider';
import Icon from '../../../common/components/ui/icon';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { Mapbox } from '../../../map/components/mapbox';
import {
  Austria,
  Europe,
  Germany,
  Switzerland,
} from '../../../map/constants/points-of-interest';
import {
  changeViewport,
  setMarkers,
  setSettings,
} from '../../../map/redux/mapSlice';
import { toggleIsLoginModalOpen } from '../../../auth/redux/authSlice';
import { OnlineValuationContainer } from '../../../forms/form-property-valuation-wizard/components/containers/online-valuation/online-valuation-container';
import {
  openValuationWizardAction,
  resetValuationWizard,
  setRelatedCountry,
  setValuationTypeAction,
  toggleIsHighlight,
} from '../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { toggleIsHighlight as toggleIsHighlightV2 } from '../../../forms/form-property-valuation-wizard-v2/redux/valuationWizardV2Slice';
import { Appointments } from '../../components/appointment/appointment';
import { toggleTimifyModal } from '../../../timify/redux/timifySlice';
import { Benefits } from '../../components/benefit/benefits';
import { Apps } from '../../components/regional-lp/apps/apps';
import { Banners } from '../../components/regional-lp/banner/banners';
import { Certificates } from '../../components/regional-lp/certificates/certificates';
import { LocalMarkets } from '../../components/regional-lp/local-market/local-markets';
import { Advantages } from '../../components/regional-lp/advantages/advantages';
import { KeyVisual } from '../../components/regional-lp/key-visual/key-visual';
import { Faqs } from '../../components/faqs/faqs';
import { getAttributesFromMeta } from '../../utils/get-attributes-from-meta';
import { LngRedirect } from '../../../localization/lng-redirect';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import { siteMap } from '../../../../routes/site-map';
import useGetLocalization from '../../../localization/get-localization';
import { useGetEvaluationWidgetVersion } from '../../../forms/form-property-valuation-wizard-v2/hooks/useGetWidgetVersion';
import { ValuationFirstStepScreen } from '../../../forms/form-property-valuation-wizard-v2/components/containers/property-valuation-containers/valuation-first-step-screen/valuation-first-step-screen';
import { defaultMapSettings } from '../../../map/constants';

interface IProps {
  openWidget?: string;
  openLoginModal?: boolean;
  openAppointmentModal?: boolean;
}

interface RegionalPageResponse {
  data: { cmsRegionalPage: CmsRegionalPage };
  isLoading: boolean;
}

const RegionalLandingPage: FC<IProps> = ({
  openLoginModal,
  openAppointmentModal,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { slug } = useParams<{ slug: string }>();
  const lang = useGetLocalization();

  const lngHPush = useLngHistoryPush();
  const { isRedesignVersion } = useGetEvaluationWidgetVersion();

  const isValuationWizardOpen = useAppSelector(
    (state) => state.valuationWizard.isValuationWizardOpen
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const selectedAddress = useAppSelector(
    (state) => state.valuationWizard.selectedAddress?.place_name
  );
  const activeStep = useAppSelector(
    (state) => state.valuationWizard.activeStep
  );
  const userId = useAppSelector((state) => state.auth.user?._id);
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);

  const isRedirect = useAppSelector(
    (state) => state.valuationWizard.isRedirect
  );
  const propertyDossier = useAppSelector(
    (state) => state.valuationWizard.propertyDossier
  );
  const relatedCountry = useAppSelector(
    (state) => state.valuationWizard.relatedCountry
  );

  const valuationType = useAppSelector(
    (state) => state.valuationWizard.valuationType
  );

  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  const [mobileCompact, setMobileCompact] = useState(true);

  const {
    data: userProperties,
    isLoading: areUserPropertiesLoading,
    isFetching: areUserPropertiesFetching,
  } = usePropertiesQuery(
    { ownerId: userId },
    { skip: !userId || isAnonymousUser }
  );

  // Fetch landing page details
  const { data: regionalPageData, isLoading: isLandingPageLoading } =
    useCmsRegionalPageQuery<RegionalPageResponse>({ slug: slug ?? '' });

  const { data: dataGeneral, isLoading: isLoadingGeneral } =
    useGeneralMarketReportByCountryQuery({ country: lang });

  const { data: marketReport, isLoading: isMarketReportLoading } =
    useMarketReportQuery({
      filters: {
        slug,
      },
    });

  const closeEvaluationWizard = () => {
    dispatch(
      changeViewport({
        ...Europe,
        transitionDuration: 1000,
      })
    );

    if (propertyDossier?._id) {
      lngHPush(
        `${siteMap.PropertyPage.pathWithoutParams}/${propertyDossier._id}`
      );
    }

    setTimeout(() => {
      batch(() => {
        dispatch(resetValuationWizard());
      });
    }, 500);
  };

  useEffect(() => {
    if (openLoginModal) {
      dispatch(toggleIsLoginModalOpen(true));
    }

    if (openAppointmentModal) {
      dispatch(toggleTimifyModal(true));
    }
  }, []);

  // Redirect logic not in use right now
  // ------------------------------------
  // useEffect(() => {
  //   if (!regionalPageData && !isLandingPageLoading) {
  //     localStorage.removeItem(LAST_LANDING_PAGE_SLUG_KEY);
  //   } else {
  //     localStorage.setItem(LAST_LANDING_PAGE_SLUG_KEY, slug);
  //   }
  // }, [regionalPageData, isLandingPageLoading]);

  const handleEvaluationWizardOpen = useCallback(
    (type: ValuationType) => {
      let countryCoordinates = Germany;
      switch (relatedCountry && relatedCountry.toLowerCase()) {
        case 'germany':
          countryCoordinates = Germany;
          break;
        case 'austria':
          countryCoordinates = Austria;
          break;
        case 'switzerland':
          countryCoordinates = Switzerland;
          break;
        default:
          countryCoordinates = Germany;
      }
      batch(() => {
        dispatch(
          changeViewport({
            ...countryCoordinates,
            transitionDuration: 'auto',
            transitionInterpolator: new FlyToInterpolator({
              speed: 0.6,
              curve: 1,
            }),
            transitionEasing: easeCubic,
          })
        );
        dispatch(setMarkers([]));
        dispatch(setSettings(defaultMapSettings));
        dispatch(openValuationWizardAction());
        dispatch(setValuationTypeAction(type));
      });
    },
    [dispatch, relatedCountry]
  );

  const firstStepNext = () => {
    handleEvaluationWizardOpen(ValuationType.Online);
  };

  const toMobileCompact = () => {
    setMobileCompact(true);
  };

  const ctaHandler = () => {
    if (isMobileSize) {
      setMobileCompact(false);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => {
        if (isRedesignVersion) {
          dispatch(toggleIsHighlightV2(true));
        } else {
          dispatch(toggleIsHighlight(true));
        }
      }, 600);
      setTimeout(() => {
        if (isRedesignVersion) {
          dispatch(toggleIsHighlightV2(true));
        } else {
          dispatch(toggleIsHighlight(false));
        }
      }, 2100);
    }
  };

  // isRedirect logic
  useEffect(() => {
    if (isRedirect && !isValuationWizardOpen) {
      batch(() => {
        dispatch(openValuationWizardAction());
        dispatch(setValuationTypeAction(ValuationType.Online));
      });
    }
  }, [dispatch, isRedirect, isValuationWizardOpen]);

  // set related country
  useEffect(() => {
    dispatch(
      setRelatedCountry(regionalPageData?.cmsRegionalPage.country.toLowerCase())
    );
  }, [isLandingPageLoading]);

  if (
    isLandingPageLoading ||
    areUserPropertiesLoading ||
    areUserPropertiesFetching ||
    isMarketReportLoading ||
    isLoadingGeneral
  ) {
    return <GlobalLoader />;
  }

  // If the user has properties do not let him hit the landing page
  if (
    userProperties?.properties.length &&
    !isValuationWizardOpen &&
    !isIframe
  ) {
    return (
      <LngRedirect
        to={`${siteMap.PropertyPage.pathWithoutParams}/${userProperties?.properties[0]._id}`}
      />
    );
  }

  if (!regionalPageData && !isLandingPageLoading) {
    return <LngRedirect to={siteMap.OwnerLandingPage.path} />;
  }

  const {
    cmsRegionalPage: {
      sections,
      bookingButtonLabel,
      heading,
      imageAlt,
      imageKey,
      imageUrl,
      personName,
      personPosition,
      personText,
      routeButtonLabel,
      routeButtonLink,
      subheading,
      title,
      metaTitle,
      metaDescription,
      metaKeywords,
    },
  } = regionalPageData;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        {imageUrl && <meta property="og:image" content={String(imageUrl)} />}
        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        {imageUrl && (
          <meta property="twitter:image" content={String(imageUrl)} />
        )}
        {metaKeywords.map((item) => {
          const metaObject = getAttributesFromMeta(item);
          return (
            <meta
              key={metaObject.name}
              name={metaObject.name}
              content={metaObject.content}
            />
          );
        })}
        <link
          rel="alternate"
          href={`https://home.von-poll.com/${lang}/${slug}`}
          hrefLang={lang}
        />
        <link
          rel="canonical"
          href={`https://home.von-poll.com/${lang}/${slug}`}
        />
      </Helmet>
      {isIframe && (
        <IFrameContainer>
          <OnlineValuationContainer isOnLandingPage />
        </IFrameContainer>
      )}
      {!isIframe && (
        <>
          <Hero mobileReverse backgroundColor="#f3f4f7">
            <>
              {isMobileSize ? (
                <KeyVisual
                  title={title}
                  heading={heading}
                  subheading={subheading}
                  personName={personName}
                  personPosition={personPosition}
                  personText={personText}
                  imageAlt={imageAlt}
                  imageKey={imageKey}
                  imageUrl={imageUrl}
                  bookingButtonLabel={bookingButtonLabel}
                  routeButtonLabel={routeButtonLabel ?? ''}
                  routeButtonLink={routeButtonLink ?? ''}
                />
              ) : (
                <>
                  <Mapbox isVisible={isValuationWizardOpen} />
                  <HeroSlider
                    showAddressBar={activeStep > 0}
                    address={selectedAddress}
                    showShadow={false}
                  >
                    <KeyVisual
                      title={title}
                      heading={heading}
                      subheading={subheading}
                      personName={personName}
                      personPosition={personPosition}
                      personText={personText}
                      imageAlt={imageAlt}
                      imageKey={imageKey}
                      imageUrl={imageUrl}
                      bookingButtonLabel={bookingButtonLabel}
                      routeButtonLabel={routeButtonLabel ?? ''}
                      routeButtonLink={routeButtonLink ?? ''}
                    />
                    <Card
                      header={t(
                        'landing-page.hero-slider.second-card.back-button'
                      )}
                      titleFirstLine={
                        valuationType === ValuationType.Online
                          ? t(
                              'landing-page.hero-slider.second-card.online.title.first-line'
                            )
                          : t(
                              'landing-page.hero-slider.second-card.personal.title.first-line'
                            )
                      }
                      titleSecondLine={t(
                        'landing-page.hero-slider.second-card.title.second-line'
                      )}
                      content={
                        valuationType === ValuationType.Online
                          ? t(
                              'landing-page.hero-slider.second-card.online.content'
                            )
                          : t(
                              'landing-page.hero-slider.second-card.personal.content'
                            )
                      }
                      showAddressBar={activeStep > 0}
                      cta={(c) => (
                        <IconWrapper onClick={closeEvaluationWizard}>
                          <Icon icon={ArrowButtonLeft} width={12} height={12} />{' '}
                          {c}
                        </IconWrapper>
                      )}
                    />
                    <Card
                      titleType="h2"
                      header={t(
                        'landing-page.hero-slider.third-card.back-button'
                      )}
                      titleFirstLine={
                        valuationType === ValuationType.Online
                          ? t(
                              'landing-page.hero-slider.third-card.online.title.first-line'
                            )
                          : t(
                              'landing-page.hero-slider.third-card.personal.title.first-line'
                            )
                      }
                      titleSecondLine={t(
                        'landing-page.hero-slider.third-card.title.second-line'
                      )}
                      content={
                        valuationType === ValuationType.Online
                          ? t(
                              'landing-page.hero-slider.third-card.online.content'
                            )
                          : t(
                              'landing-page.hero-slider.third-card.personal.content'
                            )
                      }
                      showAddressBar={activeStep > 0}
                      cta={(c) => (
                        <IconWrapper onClick={closeEvaluationWizard}>
                          <Icon icon={ArrowButtonLeft} width={12} height={12} />{' '}
                          {c}
                        </IconWrapper>
                      )}
                    />
                    <Card
                      titleType="h3"
                      header={t(
                        'landing-page.hero-slider.fourth-card.back-button'
                      )}
                      titleFirstLine={
                        valuationType === ValuationType.Online
                          ? t(
                              'landing-page.hero-slider.fourth-card.online.title.first-line'
                            )
                          : t(
                              'landing-page.hero-slider.fourth-card.personal.title.first-line'
                            )
                      }
                      titleSecondLine={t(
                        'landing-page.hero-slider.fourth-card.title.second-line'
                      )}
                      content={
                        valuationType === ValuationType.Online
                          ? t(
                              'landing-page.hero-slider.fourth-card.online.content'
                            )
                          : t(
                              'landing-page.hero-slider.fourth-card.personal.content'
                            )
                      }
                      showAddressBar={activeStep > 0}
                      cta={(c) => (
                        <IconWrapper onClick={closeEvaluationWizard}>
                          <Icon icon={ArrowButtonLeft} width={12} height={12} />{' '}
                          {c}
                        </IconWrapper>
                      )}
                    />
                    <Card
                      titleType="h4"
                      header={t(
                        'landing-page.hero-slider.fifth-card.back-button'
                      )}
                      titleFirstLine={
                        valuationType === ValuationType.Online
                          ? t(
                              'landing-page.hero-slider.fifth-card.online.title.first-line'
                            )
                          : t(
                              'landing-page.hero-slider.fifth-card.personal.title.first-line'
                            )
                      }
                      titleSecondLine={t(
                        'landing-page.hero-slider.fifth-card.title.second-line'
                      )}
                      content={
                        valuationType === ValuationType.Online
                          ? t(
                              'landing-page.hero-slider.fifth-card.online.content'
                            )
                          : t(
                              'landing-page.hero-slider.fifth-card.personal.content'
                            )
                      }
                      showAddressBar={activeStep > 0}
                      cta={(c) => (
                        <IconWrapper onClick={closeEvaluationWizard}>
                          <Icon icon={ArrowButtonLeft} width={12} height={12} />{' '}
                          {c}
                        </IconWrapper>
                      )}
                    />
                  </HeroSlider>
                </>
              )}
            </>
            <ValuationBox>
              {isRedesignVersion ? (
                <ValuationFirstStepScreen />
              ) : (
                <OnlineValuationContainer
                  regionalFirstStepNext={firstStepNext}
                  regionalSecondStepBack={closeEvaluationWizard}
                  activeStep={activeStep}
                  mobileCompact={mobileCompact}
                  toMobileCompact={toMobileCompact}
                  isOnLandingPage
                />
              )}
            </ValuationBox>
          </Hero>
          <ContentWithPadding>
            {sections.map((section: RpSection) => (
              <Fragment key={section.orderIndex}>
                {section.type === RpSectionType.Advantage && (
                  <Advantages section={section} />
                )}

                {section.type === RpSectionType.App && (
                  <Apps section={section} />
                )}

                {section.type === RpSectionType.Banner && (
                  <Banners section={section} ctaHandler={ctaHandler} />
                )}

                {section.type === RpSectionType.Benefit && (
                  <Benefits
                    isStatic
                    section={section}
                    ctaHandler={ctaHandler}
                  />
                )}

                {section.type === RpSectionType.Certificate && (
                  <Certificates section={section} />
                )}

                {section.type === RpSectionType.LocalMarket && (
                  <LocalMarkets
                    section={section}
                    fileUrl={
                      marketReport?.marketReport?.fileUrl ||
                      dataGeneral?.data?.fileUrl
                    }
                  />
                )}

                {section.type === RpSectionType.Faq && (
                  <Faqs section={section} regional />
                )}

                {section.type === RpSectionType.Appointment && (
                  <Appointments rpSection={section} />
                )}

                {section.type === RpSectionType.GeneralAppointment && (
                  <GeneralAppointments section={section} />
                )}
              </Fragment>
            ))}
          </ContentWithPadding>
        </>
      )}
    </>
  );
};

export { RegionalLandingPage };
