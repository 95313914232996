import { FC, useCallback } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Container, Label, Slider, SwitchContainer } from './switch-styles';
import {
  Info,
  TooltipContainer,
  TooltipParagraph,
} from '../input-v2/input-v2-styles';
import { Tooltip } from '../../../../common/components/tooltip';
import { ReactComponent as InformationCircle } from '../../../../../assets/streamline-light/interface-essential/alerts/information-circle.svg';

interface IProps {
  name: string;
  label: string;
  defaultValue?: boolean;
  tooltipKey?: string;
  onChangeProp?: (value: boolean) => void;
}

const Switch: FC<IProps> = ({
  name,
  label,
  defaultValue,
  tooltipKey,
  onChangeProp,
}) => {
  const { t } = useTranslation();

  const {
    field: { ref, value, onChange, ...inputProps },
    // formState: { errors },
  } = useController({
    name,
    defaultValue: defaultValue || false,
  });

  const handleCLick = useCallback(() => {
    onChange(!value);
    if (onChangeProp) {
      onChangeProp(!value);
    }
  }, [onChange, value, onChangeProp]);

  return (
    <Container>
      <SwitchContainer onClick={handleCLick}>
        <input id={name} ref={ref} type="checkbox" {...inputProps} />
        <Slider isChecked={!!value} />
      </SwitchContainer>
      <Label className="label" onClick={handleCLick} isChecked={!!value}>
        {t(label)}
      </Label>
      {tooltipKey && (
        <Info>
          <Tooltip
            icon={InformationCircle}
            id={tooltipKey}
            position="bottom"
            width={16}
            height={16}
          >
            <TooltipContainer>
              <TooltipParagraph>{t(tooltipKey)}</TooltipParagraph>
            </TooltipContainer>
          </Tooltip>
        </Info>
      )}
    </Container>
  );
};

export { Switch };
