import styled from 'styled-components';
import { hexToRGB } from '../../../../../../common/utils/hex-to-rgb';
import { SecondaryButton } from '../../../../../../common/components/ui/buttons';

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  justify-content: center;

  & > div {
    display: flex;
    align-items: center;
    width: 150px;
    justify-content: center;

    &:first-child {
      width: 364px;
      justify-content: space-between;

      & > div {
        display: flex;
        gap: 24px;
      }
    }
  }
`;

export const IconContainer = styled.div<{ isDisabled: boolean }>`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid
    ${({ theme, isDisabled }) =>
      isDisabled ? hexToRGB(theme.black, 0.2) : theme.blue};
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.ctaDisabledBackground : theme.ctaBlue};
  cursor: pointer;
`;

export const VariantBox = styled.div<{ active?: boolean }>`
  font-family: Roboto, sans-serif;
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 96px;
  padding: 8px 4px 4px;
  margin: 0 8px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: ${({ theme }) => hexToRGB(theme.blue, 0.5)};
  border-bottom: 4px solid transparent;
  ${(props) => props.active && 'border-bottom: 4px solid #002849;'}
`;

export const VariantCheckbox = styled.div<{ isDisabled?: boolean }>`
  width: 20px;
  height: 20px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: solid 1px
    ${({ theme, isDisabled }) =>
      isDisabled ? theme.ctaDisabledBackground : theme.logoBlue};
  background-color: #fff;
  cursor: pointer;
`;

export const ShowAllVariantsButton = styled(SecondaryButton)`
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.blue};
  padding: 4px 32px;

  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 16px;
  text-transform: uppercase;
`;

export const VariantContainer = styled.div``;
