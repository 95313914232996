import { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { SubmitHandler } from 'react-hook-form';

import { ILoginForm } from '../../../../auth/interfaces';
import { IFinancingForm } from '../../interface';
import { LoginResponse, useSignInMutation } from '../../../../../generated';
import { useAppSelector } from '../../../../common/hooks';
import {
  togglePersonalFinancingForm,
  toggleForgotPasswordForm,
  toggleLoginForm,
  toggleRegisterForm,
} from '../../redux/financingSlice';
import {
  login,
  setAnonymousUser,
  setUserInput,
  setUserState,
  toggleIsRegisterModalOpen,
  toggleLoading,
  toggleModalOpacity,
} from '../../../../auth/redux/authSlice';
import { client } from '../../../../../services/graphql/graphql';
import { MODAL_ANIMATION_DURATION } from '../../../../common/components/modal/modal';

interface IProps {
  setErrors: (errors: unknown) => void;
  onSuccess: (formData: IFinancingForm, ownerId: string) => Promise<void>;
}

interface IReturn {
  onLoginSubmit: (formData: ILoginForm) => void;
  onOpenForgotPassword: () => void;
}

const useSubmitFinancingLogin = ({ setErrors, onSuccess }: IProps): IReturn => {
  const dispatch = useDispatch();
  const [signIn, { data: signInData }] = useSignInMutation();
  const userInput = useAppSelector((state) => state.financing.userInput);
  const [inputPassword, setInputPassword] = useState('');

  const onOpenForgotPassword = useCallback(() => {
    dispatch(toggleForgotPasswordForm(true));
    dispatch(toggleLoginForm(false));
  }, [dispatch]);

  const onLoginSubmit: SubmitHandler<ILoginForm> = useCallback(
    async (formData) => {
      const { userAuthenticationKey, password } = formData;
      setInputPassword(password);

      if (userAuthenticationKey && password) {
        try {
          dispatch(toggleLoading(true));
          dispatch(toggleModalOpacity(true));

          await signIn({
            input: {
              userAuthenticationKey,
              password,
              requestAccessToken: true,
            },
          }).unwrap();
        } catch (error: unknown) {
          setErrors(error);
        } finally {
          dispatch(toggleLoading(false));
          setTimeout(() => {
            dispatch(toggleModalOpacity(false));
          }, MODAL_ANIMATION_DURATION);
        }
      }
    },
    [dispatch, setErrors, signIn]
  );

  useEffect(() => {
    if (signInData) {
      const loginResponse = signInData.signIn as LoginResponse;
      if (loginResponse?.jwt?.token) {
        client.setHeader(
          'Authorization',
          `Bearer ${loginResponse?.jwt?.token ?? ''}`
        );

        batch(() => {
          dispatch(setAnonymousUser(false));
          dispatch(login(loginResponse));
          dispatch(setUserState(loginResponse.user.userState));
          dispatch(togglePersonalFinancingForm(true));
        });
      } else if (loginResponse.user && !loginResponse.jwt) {
        batch(() => {
          const {
            user: {
              userState,
              name,
              surname,
              phone,
              email,
              termsAndConditions,
            },
          } = loginResponse;
          dispatch(toggleRegisterForm(true));
          dispatch(toggleLoginForm(false));
          dispatch(toggleIsRegisterModalOpen(true));
          dispatch(setUserState(userState));
          dispatch(
            setUserInput({
              name,
              surname,
              phonePrefix: '',
              phone,
              email,
              password: inputPassword,
              confirmPassword: inputPassword,
              termsAndConditions,
            })
          );
        });
      }
    }
  }, [dispatch, inputPassword, onSuccess, signInData, userInput]);

  return { onLoginSubmit, onOpenForgotPassword };
};

export { useSubmitFinancingLogin };
