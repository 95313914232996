import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const LandAreaIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={
          'M22 23a1 1 0 0 0 1-1m-12 1h-1m-2 0H7m7 0h-1m4 0h-1m4 0h-1m4-7v1m0-4v1m0-4v1m0-4v1m0 11v1M5 23V5h18'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={'m18 1 5 4-5 4M1 18l4 5 4-5'}
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { LandAreaIcon };
