import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  EvaluationButton,
  ExampleDashboardButton,
  RealDashboardButton,
  RealDashboardSoldButton,
  SalesSimulationButton,
  StartSaleButton,
} from '../components/seller-property-card/seller-property-card-styles';
import { siteMap } from '../../../routes/site-map';
import { setSelectedTab } from '../../property/redux/dynamicMapUtilsSlice';
import { PropertyStatus, useUpdatePropertyMutation } from '../../../generated';
import { useIsMobileSize } from '../../common/hooks/useIsMobileSize';
import { useAppSelector } from '../../common/hooks';

type TReturn = {
  TopButton: ReactNode | null;
  FirstBottomButton: ReactNode | null;
  SecondBottomButton: ReactNode | null;
};

export const useGetActionPanelButtons = (
  status: PropertyStatus,
  propertyId: string,
  isDigitalServiceActivated?: boolean
): TReturn => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();

  // onOffice marketplace iframe URL params
  const urlParams = new URLSearchParams(window.location.search);
  const brokerId = urlParams.get('userid') || '';
  const propertyOnOfficeId = urlParams.get('EstateId') || '';
  const signature = urlParams.get('signature') || '';

  const [updateProperty] = useUpdatePropertyMutation();

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const onEvaluationButtonClick = useCallback(() => {
    if (status !== PropertyStatus.Selling) {
      window.open(
        `${siteMap.PropertyPage.pathWithoutParams}/${propertyId}`,
        '_blank'
      );
      // push(`${siteMap.PropertyPage.pathWithoutParams}/${propertyId}`);
    }
  }, [propertyId, status]);

  const onStartSaleButtonClick = useCallback(() => {
    dispatch(setSelectedTab(0));
    push(
      `${siteMap.SellingProcessPage.pathWithoutParams}/${propertyId}${
        brokerId && propertyOnOfficeId
          ? `?userid=${brokerId}&EstateId=${propertyOnOfficeId}&signature=${signature}`
          : ''
      }`
    );
  }, [dispatch, push, propertyId, brokerId, propertyOnOfficeId, signature]);

  const onRealDashboardButtonClick = useCallback(() => {
    dispatch(setSelectedTab(isDigitalServiceActivated ? 3 : 4));
    push(
      `${siteMap.SellingProcessPage.pathWithoutParams}/${propertyId}${
        brokerId && propertyOnOfficeId
          ? `?userid=${brokerId}&EstateId=${propertyOnOfficeId}&signature=${signature}`
          : ''
      }`
    );
  }, [
    dispatch,
    isDigitalServiceActivated,
    push,
    propertyId,
    brokerId,
    propertyOnOfficeId,
    signature,
  ]);

  const onSimulationDashboardButtonClick = useCallback(async () => {
    if (status !== PropertyStatus.SellingSimulation && !isBrokerView) {
      try {
        await updateProperty({
          input: {
            _id: propertyId,
            status: PropertyStatus.SellingSimulation,
          },
        }).unwrap();
      } catch (error) {
        console.error(error);
      }
    }
    dispatch(setSelectedTab(4));
    push(
      `${siteMap.SellingProcessPage.pathWithoutParams}/${propertyId}${
        brokerId && propertyOnOfficeId
          ? `?userid=${brokerId}&EstateId=${propertyOnOfficeId}`
          : ''
      }`
    );
  }, [
    status,
    isBrokerView,
    dispatch,
    push,
    propertyId,
    brokerId,
    propertyOnOfficeId,
    updateProperty,
  ]);

  const TopButton =
    isMobileSize &&
    status !== PropertyStatus.Selling &&
    status !== PropertyStatus.Sold ? (
      <StartSaleButton
        // label={
        //   propertyHasAppointments
        //     ? t('seller-properties-list.continue-selling.button')
        //     : t('seller-properties-list.start-selling.button')
        // }
        label={t('seller-properties-list.start-selling.button')}
        onClick={onStartSaleButtonClick}
        fluid
      />
    ) : (
      <EvaluationButton
        label={t('seller-properties-list.evaluation.button')}
        onClick={onEvaluationButtonClick}
        isVisible={status !== PropertyStatus.Selling && !isBrokerView}
      />
    );

  const getFirstBottomButton = () => {
    switch (status) {
      case PropertyStatus.Initial:
        return (
          <SalesSimulationButton
            label={t('seller-properties-list.selling-simulation.button')}
            onClick={onSimulationDashboardButtonClick}
            fluid
          />
        );
      case PropertyStatus.SellingSimulation:
        return (
          <ExampleDashboardButton
            label={t('seller-properties-list.selling-simulation.button')}
            onClick={onSimulationDashboardButtonClick}
            fluid
          />
        );
      case PropertyStatus.Selling:
        return (
          <RealDashboardButton
            label={t('seller-properties-list.real-dashboard.button')}
            onClick={onRealDashboardButtonClick}
            fluid
          />
        );
      case PropertyStatus.Sold:
        return (
          <RealDashboardSoldButton
            label={t('seller-properties-list.real-dashboard.button')}
            onClick={onRealDashboardButtonClick}
            fluid
          />
        );
      default:
        return null;
    }
  };

  const getSecondBottomButton = () => {
    switch (status) {
      case PropertyStatus.Initial:
      case PropertyStatus.SellingSimulation:
        return isMobileSize ? (
          <EvaluationButton
            label={t('seller-properties-list.evaluation.button')}
            onClick={onEvaluationButtonClick}
            isVisible
          />
        ) : (
          <StartSaleButton
            // label={
            //   propertyHasAppointments
            //     ? t('seller-properties-list.continue-selling.button')
            //     : t('seller-properties-list.start-selling.button')
            // }
            label={t('seller-properties-list.start-selling.button')}
            onClick={onStartSaleButtonClick}
            fluid
          />
        );
      case PropertyStatus.Selling:
      case PropertyStatus.Sold:
        return null;
      default:
        return null;
    }
  };

  return {
    TopButton,
    FirstBottomButton: getFirstBottomButton(),
    SecondBottomButton: getSecondBottomButton(),
  };
};
