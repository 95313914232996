import styled from 'styled-components';
import { CSSProperties } from 'react';
import { SelectWithLabel } from '../../../../forms/components/common/select-wth-label';

const Label = styled.label<{ isRedesign?: boolean }>`
  opacity: ${({ isRedesign }) => (isRedesign ? 0.7 : 0.5)};
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  margin-bottom: -4px;
`;

interface IProps {
  name: string;
  isDisabled?: boolean;
  isRedesign?: boolean;
  selectedOption: string | number;
  options: {
    label: string | number;
    value: string | number | boolean | null;
  }[];
  label?: string;
  defaultValue?: number | string;
  innerLabel?: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  onSelect?: (value: unknow) => void;
  style?: CSSProperties;
}

const SelectOutsideTopLabel = ({ label, isRedesign, ...props }: IProps) => {
  return (
    <span>
      {label && <Label isRedesign={isRedesign}>{label}</Label>}
      <SelectWithLabel isRedesign={isRedesign} {...props} />
    </span>
  );
};

export { SelectOutsideTopLabel };
