import { useCallback, useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { SubmitHandler } from 'react-hook-form';
import { useAppSelector } from '../../../../../common/hooks';
import {
  CreatePropertyInput,
  RegistrationBy,
  useRequestVerificationCodeMutation,
  UserSignUpInput,
  UserState,
  UserType,
  useSignUpMutation,
  useVerifyGuestUserMutation,
} from '../../../../../../generated';
import {
  ITimifyParams,
  TimifyPersonalisationIds,
} from '../../../../../timify/interfaces';
import {
  setIsConfirmEmailSent,
  setTimifyParams,
  toggleLoginForm,
  toggleRegisterForm,
} from '../../../redux/valuationWizardV2Slice';
import useGetLocalization from '../../../../../localization/get-localization';
import { useGetCountry } from '../../../../../localization/get-country';
import { useCreateProperty } from '../../../../form-property-valuation-wizard/hooks/useCreateProperty';
import { useCreatePropertyDossierMutation } from '../../../../../../services/graphql/enhanced';
import { IRegisterForm } from '../../../../../auth/interfaces';
import { parseRegisterInput } from '../../../../../auth/utils/parse-register-input';
import {
  setConfirmedGuest,
  setUserId,
  setUserInput,
  setUsername,
  setUserState,
  toggleLoading,
} from '../../../../../auth/redux/authSlice';
import { parseValuationInput } from '../../../../utils/parse-valuation-input';
import { IValuationForm } from '../../../../form-property-valuation-wizard/interface';
import { Feature } from '../../../../../../services/mapbox/interfaces';
import { showServices } from '../../../../form-property-valuation-wizard/constants';
import {
  ITemporaryUserCredentials,
  ITemporaryUserInput,
} from '../../../interface';
import { useActualExperiment } from '../../../../../experiments/hooks/useActualExperiment';

type TProps = {
  setTemporaryUserInput: (temporaryUserInput: ITemporaryUserInput) => void;
  setErrors: (errors: unknown) => void;
  setConfirmGuestAccount: (isConfirm: boolean) => void;
  reservationSuccess: boolean;
  temporaryUserCredentials: ITemporaryUserCredentials;
};

type TReturn = {
  isRegisterFormLoading: boolean;
  isConfirmEmailSent: boolean;
  userState: UserState;
  isIframe?: boolean;
  isValuationLoading: boolean;
  timifyParams: ITimifyParams | null;
  onOpenLogin: () => void;
  onRegisterSubmit: SubmitHandler<IRegisterForm>;
};

const usePersonalRegister = ({
  setErrors,
  setTemporaryUserInput,
  setConfirmGuestAccount,
  reservationSuccess,
  temporaryUserCredentials,
}: TProps): TReturn => {
  const dispatch = useDispatch();

  const isRegisterFormLoading = useAppSelector((state) => state.auth.isLoading);
  const userState = useAppSelector((state) => state.auth.userState);
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const timifyParams = useAppSelector(
    (state) => state.valuationWizardV2.timifyParams
  );
  const isValuationLoading = useAppSelector(
    (state) => state.valuationWizardV2.isLoading
  );
  const isConfirmEmailSent = useAppSelector(
    (state) => state.valuationWizardV2.isConfirmEmailSent
  );
  const isConfirmedGuest = useAppSelector(
    (state) => state.auth.isConfirmedGuest
  );
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const { isRegistrationByEmail, registrationBy } = useActualExperiment();

  const onOpenLogin = useCallback(() => {
    dispatch(toggleLoginForm(true));
    dispatch(toggleRegisterForm(false));
  }, [dispatch]);

  const lang = useGetLocalization();
  const { countryCode } = useGetCountry();

  const userInput = useAppSelector(
    (state) => state.valuationWizardV2.userInput
  );
  const selectedAddress = useAppSelector(
    (state) => state.valuationWizardV2.selectedAddress
  );

  // Mutations
  const [signUp] = useSignUpMutation();
  const [verifyGuestUser] = useVerifyGuestUserMutation();
  const { createProperty } = useCreateProperty();
  const [createPropertyDossier] = useCreatePropertyDossierMutation();
  const [requestConfirmationCode] = useRequestVerificationCodeMutation();

  const createPropertyAndSetTimify = useCallback(
    async (input: UserSignUpInput, userId: string) => {
      // We must create temporary property & dossier
      const valuationInput = parseValuationInput(
        userInput as IValuationForm,
        selectedAddress as Feature
      ) as CreatePropertyInput;

      const createPropertyResponse = await createProperty({
        valuationInput,
        userId,
      });

      let dossierUrl = '';

      if (createPropertyResponse.createProperty._id) {
        const createPropertyDossierResponse = await createPropertyDossier({
          input: {
            propertyId: createPropertyResponse.createProperty._id,
          },
        }).unwrap();
        if (createPropertyDossierResponse.createPropertyDossier?.dossierUrl) {
          dossierUrl =
            createPropertyDossierResponse.createPropertyDossier?.dossierUrl;
        }
      }

      dispatch(
        setTimifyParams({
          serviceExternalIds: TimifyPersonalisationIds.PersonalValuation,
          firstName: input?.name ?? '',
          lastName: input?.surname ?? '',
          email: input?.email ?? '',
          phone: input?.phone ?? '',
          country: countryCode,
          showServices,
          showFooterBackButton: '1',
          dossierUrl: dossierUrl ?? '',
        })
      );
    },
    [
      countryCode,
      createProperty,
      createPropertyDossier,
      dispatch,
      selectedAddress,
      userInput,
    ]
  );

  const onRegisterSubmit: SubmitHandler<IRegisterForm> = useCallback(
    async (formData) => {
      const input = parseRegisterInput({
        formData,
        lang,
        userType: UserType.Owner,
      });

      dispatch(setUserInput({ ...formData, password: input.password }));
      dispatch(toggleLoading(true));
      setTemporaryUserInput(input);

      if (!input.createAccount) {
        try {
          const verifyGuestUserResponse = await verifyGuestUser({
            input: {
              phoneNumber: input.phone,
            },
          }).unwrap();

          if (verifyGuestUserResponse.verifyGuestUser._id) {
            if (verifyGuestUserResponse.verifyGuestUser.verified) {
              dispatch(setConfirmedGuest(input.phone));

              await createPropertyAndSetTimify(
                input,
                verifyGuestUserResponse.verifyGuestUser._id
              );
            } else {
              setConfirmGuestAccount(true);
            }
          }
        } catch (error: unknown) {
          setErrors(error);
        } finally {
          dispatch(toggleLoading(false));
        }
        return;
      }

      // Create the user account
      try {
        const signUpResponse = await signUp({
          input: {
            ...input,
            registrationBy: registrationBy || RegistrationBy.Mobile,
          },
        }).unwrap();

        if (signUpResponse?.signUp?._id) {
          const {
            _id: id,
            username: newUsername,
            userState: newUserState,
          } = signUpResponse.signUp;
          batch(() => {
            dispatch(setUserId(id));
            dispatch(setUsername(newUsername));
            dispatch(setUserState(newUserState));
          });
          if (isRegistrationByEmail) {
            await createPropertyAndSetTimify(input, id);
          }
        }
      } catch (error: unknown) {
        setErrors(error);
      } finally {
        dispatch(toggleLoading(false));
      }
    },
    [
      createPropertyAndSetTimify,
      dispatch,
      isRegistrationByEmail,
      lang,
      registrationBy,
      setConfirmGuestAccount,
      setErrors,
      setTemporaryUserInput,
      signUp,
      verifyGuestUser,
    ]
  );

  useEffect(() => {
    const sendConfirmEmail = async () => {
      if (reservationSuccess && timifyParams) {
        dispatch(setTimifyParams(null));
        if (temporaryUserCredentials?.username) {
          const confirmationCodeResponse = await requestConfirmationCode({
            input: { ...temporaryUserCredentials },
          }).unwrap();

          if (confirmationCodeResponse?.requestVerificationCode) {
            dispatch(setIsConfirmEmailSent(true));
          }
        } else if (
          isConfirmedGuest ||
          !isAnonymousUser ||
          isRegistrationByEmail
        ) {
          dispatch(setIsConfirmEmailSent(true));
        }
      }
    };

    sendConfirmEmail();
  }, [
    reservationSuccess,
    timifyParams,
    temporaryUserCredentials,
    requestConfirmationCode,
    isConfirmedGuest,
    isAnonymousUser,
    dispatch,
    isRegistrationByEmail,
  ]);

  return {
    isRegisterFormLoading,
    isIframe,
    userState,
    isValuationLoading,
    timifyParams,
    onOpenLogin,
    onRegisterSubmit,
    isConfirmEmailSent,
  };
};

export { usePersonalRegister };
