import styled from 'styled-components';

export const SkeletonBox = styled.div<{ width?: string }>`
  width: ${({ width }) => width ?? '100%'};
  flex: 1;
`;

export const SkeletonItem = styled.span<{
  width?: string;
  height?: string;
  mb?: number;
  mt?: number;
  ml?: number;
}>`
  @keyframes barWidth {
    0% {
      width: 50%;
    }
    25% {
      width: 100%;
    }
    50% {
      width: 50%;
    }
    75% {
      width: 10%;
    }
    100% {
      width: 50%;
    }
  }

  display: block;
  width: ${({ width }) => width ?? '160px'};
  height: ${({ height }) => height ?? '6px'};
  background-color: ${({ theme }) => theme.blue};
  border-radius: 3px;
  margin: 0 auto;
  animation: barWidth;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  ${({ mb }) => (mb ? `margin-bottom: ${mb}px;` : '')}
  ${({ mt }) => (mt ? `margin-top: ${mt}px;` : '')}
  ${({ ml }) => (ml ? `margin-left: ${ml}px;` : '')}
`;
