import styled from 'styled-components';
import { hexToRGB } from '../../../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../../../style/theme';

export const Container = styled.div<{ src: string }>`
  flex-basis: 50%;
  min-width: 50%;
  height: 100%;
  background-image: url('${(props) => props.src}');
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Wrapper = styled.div`
  height: fit-content;
  position: relative;
  margin: 32px 64px;
  background-color: ${({ theme }) => theme.white};
  width: 624px;
  //overflow-y: auto;
`;

export const Header = styled.div`
  padding: 8px 32px;
  font-family: Source Serif Pro, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.blue};
`;

export const MapContainer = styled.div`
  height: 184px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const InfoWrapper = styled.div`
  margin: 16px 24px;
  position: relative;
`;

export const SubHeader = styled.div`
  margin: 4px 0 2px;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => hexToRGB(theme.blue, 0.8)};
`;

export const InfoHeader = styled.div`
  margin: 2px 0 8px;
  font-family: Source Serif Pro, sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;

export const Row = styled.div`
  display: flex;
`;

export const Info = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-top: 6px;
  cursor: pointer;
  .icon {
    > svg,
    > svg > path {
      stroke: ${(props) => props.theme.blue};
    }
  }

  @media ${device.tablet} {
    margin-right: 0;
  }
`;

export const MarketHeader = styled.span`
  font-family: Source Serif Pro, sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;

export const MarketImage = styled.img`
  width: 100%;
`;

export const PerformanceHeader = styled.span`
  font-family: Source Serif Pro, sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;

export const PerformanceImage = styled.img`
  width: 100%;
  height: 182px;
`;

export const ProvenExpertContainer = styled.div`
  position: absolute;
  top: 166px;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ProvenExpertImage = styled.img<{ marginLeft?: number }>`
  margin-left: ${({ marginLeft }) => marginLeft || 624}px;
`;
