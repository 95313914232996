import { FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import { LoaderContainer } from 'modules/seller/pages/seller-properties-page/seller-properties-page-styles';
import { ValuationLoader } from 'modules/forms/components/common/loader';

import { device } from '../../../../style/theme';
import { ExposeCard } from '../expose/expose-card/expose-card';
import { ProgressCard } from '../progress-card/progress-card';
import {
  useGetPropertyExposeStatusQuery,
  useGeneratePdfExposeLinkMutation,
  OnOfficeExposeStatus,
  useGetPropertyBrokerQuery,
  Broker,
} from '../../../../generated';
import {
  setActiveTab,
  setIsAppointmentSelectOpen,
  setIsOpenHeadAccordion,
  setSelectedPropertyId,
  toggleExposeOverlay,
} from '../../pages/expose-details-overlay/redux/exposeSlice';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { TeamContactCard } from '../appointment-card/team-contact-card';
import Banner from '../banner/banner';
import { ReactComponent as ReadHuman } from '../../../../assets/streamline-light/school-learning/library-reading/read-human.svg';
import BannerImage from '../../../../assets/broker-view-banner.webp';
import { useAppSelector } from '../../../common/hooks';
import { PropertyLockedMessage } from '../property-locked-message/property-locked-message';
import { dateFromTimestamp } from '../../utils/dateFromTimestamp';

const Container = styled.div`
  position: relative;
  display: flex;
  @media ${device.tablet} {
    flex-direction: column;
    padding: 16px;
  }
`;

const FirstColumn = styled.div<{ isBlurred?: boolean }>`
  flex: 1.72;
  max-width: 688px;
  margin-right: 16px;
  ${(props) => props.isBlurred && 'filter: blur(6px)'};

  @media ${device.tablet} {
    margin-bottom: 16px;
    max-width: 100%;
    width: 100%;
  }
`;

const SecondColumn = styled.div`
  flex: 1;
  max-width: 400px;
  @media ${device.tablet} {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

interface IProps {
  isLocked?: boolean;
}

const ExposeBase: FC<IProps> = ({ isLocked }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { propertyId } = useParams<{ propertyId: string }>();

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const { expose, isDataLoading, errors } = useGetPropertyExposeStatusQuery(
    {
      propertyId,
    },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        expose: {
          status:
            data?.getPropertyExposeStatus?.status ??
            OnOfficeExposeStatus.Preparation,
          comment: data?.getPropertyExposeStatus?.comment ?? '',
          exposeChangesHistory:
            data?.getPropertyExposeStatus?.exposeChangesHistory ?? [],
          pdf: data?.getPropertyExposeStatus?.pdf,
          createdAt: data?.getPropertyExposeStatus?.exposeCreatedAt
            ? dateFromTimestamp(data?.getPropertyExposeStatus?.exposeCreatedAt)
            : '',
          updatedAt: data?.getPropertyExposeStatus?.exposeUpdatedAt
            ? dateFromTimestamp(data?.getPropertyExposeStatus?.exposeUpdatedAt)
            : '',
          approvedAt: data?.getPropertyExposeStatus?.exposeApprovedAt,
          isApprovedViaOnOffice:
            data?.getPropertyExposeStatus?.isExposeApprovedViaOnOffice,
        },
        isDataLoading: isLoading,
        errors: error,
      }),
      pollingInterval: 10_000,
    }
  );

  const [generatePdfExpose] = useGeneratePdfExposeLinkMutation();

  const { propertyBroker, isBrokerLoading, getBrokerError } =
    useGetPropertyBrokerQuery(
      {
        propertyId,
      },
      {
        selectFromResult: ({
          data: result,
          isLoading: brokerLoading,
          error,
        }) => ({
          propertyBroker: result?.getPropertyBroker as Broker,
          isBrokerLoading: brokerLoading,
          getBrokerError: error,
        }),
        skip: !isLocked,
      }
    );

  const onEmailBroker = useCallback(() => {
    if (propertyBroker?.email) {
      window.open(`mailto: ${propertyBroker.email}`, '_self');
    }
  }, [propertyBroker?.email]);

  const onClickDownloadPdf = useCallback(async () => {
    try {
      if (expose?.pdf) {
        const { generatePdfExposeLink: pdfExpose } = await generatePdfExpose({
          id: '',
          fileKey: expose.pdf,
        }).unwrap();
        if (pdfExpose) {
          window.open(pdfExpose, '_self');
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [generatePdfExpose, expose?.pdf]);

  const onClickOpenOverlay = useCallback(() => {
    batch(() => {
      dispatch(setSelectedPropertyId(propertyId));
      // dispatch(setExternalPropertyId(externalPropertyId));
      // dispatch(setSelectedPropertyCode(propertyCode));
      dispatch(toggleExposeOverlay(true));
      dispatch(setIsAppointmentSelectOpen(false));
      dispatch(setIsOpenHeadAccordion(true));
      dispatch(setActiveTab(0));
    });
  }, [dispatch, propertyId]);

  return (
    <>
      {isBrokerView && (
        <Banner
          icon={ReadHuman}
          image={BannerImage}
          title={t('seller-portal.broker-view.banner.title')}
          description={t('seller-portal.broker-view.banner.description')}
        />
      )}
      <Container>
        {isLocked && <PropertyLockedMessage onEmailBroker={onEmailBroker} />}
        <FirstColumn isBlurred={isLocked}>
          {errors?.message && (
            <GlobalError title={t(errors?.message?.split(':')[0])} />
          )}
          {isDataLoading ? (
            <LoaderContainer isLoadingMore>
              <ValuationLoader maxWidth="500px" />
            </LoaderContainer>
          ) : (
            <ExposeCard
              isLocked={isLocked}
              expose={expose}
              onDownloadPdfExpose={onClickDownloadPdf}
              onOpenOnlineExpose={onClickOpenOverlay}
            />
          )}
        </FirstColumn>
        <SecondColumn>
          <ProgressCard isBlurred={isLocked} />
          <TeamContactCard />
        </SecondColumn>
      </Container>
    </>
  );
};

const Expose = memo(ExposeBase);

export { Expose };
