import { useParams } from 'react-router-dom';
import { useUpdateFinancing } from '../../forms/form-financing/hooks/callback-hooks/useUpdateFinancing';
import { useAppSelector } from '../../common/hooks';
import { FinancingVariant, PersonalFinancingInput } from '../../../generated';
import { useSendVonPollFinance } from './useSendVonPollFinance';

interface IProps {
  financingVariants?: FinancingVariant[];
  personalFinancing?: PersonalFinancingInput;
  feasibilityRating: number;
  marketingLink: string;
  propertyId?: string;
}

const useRequestFinancing = ({
  financingVariants,
  personalFinancing,
  feasibilityRating,
  marketingLink,
  propertyId,
}: IProps) => {
  const { financingId } = useParams<{ financingId: string }>();
  const { updateFinancing } = useUpdateFinancing();
  const { variantIndex } = useAppSelector((state) => state.financing);
  const financingVariant = financingVariants && {
    ...financingVariants[variantIndex ?? 0],
    financingPropositions: undefined,
    financingPropositionMaxPurchasePrice: undefined,
  };
  const userButtonLink = `${window.location.origin}${window.location.pathname}?variant-index=${variantIndex}&`;
  const { sendVonPollFinance } = useSendVonPollFinance({
    financingVariant,
    personalFinancing,
    feasibilityRating,
    marketingLink,
    userButtonLink,
    propertyId,
  });

  const requestFinancing = async (isCertificate: boolean) => {
    if (financingVariants) {
      const updatedFinancingVariants = financingVariants.map((item, i) => {
        return i === variantIndex
          ? {
              ...item,
              financingSuggestions: {
                ...item.financingSuggestions,
                isRequestedFinancing: true,
              },
            }
          : item;
      });
      await updateFinancing({
        financingInput: {
          _id: financingId,
          financingVariants: updatedFinancingVariants,
        },
      });
      await sendVonPollFinance(isCertificate);
    }
  };
  return { requestFinancing };
};

export { useRequestFinancing };
