import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ThemeContext } from 'styled-components';
import { FC, useContext } from 'react';
import {
  Container,
  Header,
  Subtitle,
  LoginButton,
  RegisterButton,
} from './buyer-second-landing-page-styles';

export const BuyerSecondLandingPage: FC = () => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const { push } = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const searchProfileId = urlParams.get('searchProfileId') || '';
  const addressId = urlParams.get('addressId') || '';
  const propertyId = urlParams.get('propertyId') || '';
  const onlineExposeId = urlParams.get('onlineExposeId') || '';
  const email = urlParams.get('email') || '';

  return (
    <Container>
      <Header content={t('buyer-second-landing-page.title')} />
      <Subtitle content={t('buyer-second-landing-page.subtitle')} />
      <LoginButton
        label={t('button.login-now')}
        onClick={() =>
          push(
            `/buyer-login-page/?searchProfileId=${searchProfileId}&propertyId=${propertyId}&onlineExposeId=${onlineExposeId}`
          )
        }
        fluid
        color={themeContext.blue}
        borderColor={themeContext.blue}
      />
      <RegisterButton
        label={t('buyer-second-landing-page.button.register')}
        onClick={() =>
          push(
            `/buyer-sign-up-page/?addressId=${addressId}&propertyId=${propertyId}&email=${email}`
          )
        }
        fluid
      />
    </Container>
  );
};
