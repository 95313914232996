import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  activeSlide: number;
}

const initialState: InitialState = {
  activeSlide: 0,
};

export const requestExposeSlice = createSlice({
  name: 'requestExpose',
  initialState,
  reducers: {
    setActiveSlide: (state, action: PayloadAction<number>) => {
      state.activeSlide = action.payload;
    },
  },
});

export const { setActiveSlide } = requestExposeSlice.actions;

export default requestExposeSlice.reducer;
