import { FC } from 'react';
import styled from 'styled-components';
import { AppointmentSection } from '../../../../generated';

interface IProps extends Pick<AppointmentSection, 'altText'> {
  imageSrc: string;
  key?: string | null;
  width?: number;
  height?: number;
}

const Container = styled.div<{ height: number; width: number }>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 3px;
  object-fit: cover;
`;

const Avatar: FC<IProps> = ({
  imageSrc,
  altText,
  key,
  width = 80,
  height = 80,
  ...rest
}) => (
  <Container width={width} height={height} {...rest}>
    <Image src={imageSrc} key={key} alt={altText ?? ''} />
  </Container>
);

export { Avatar };
