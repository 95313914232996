import { FC, memo, useCallback, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { batch, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalFooter,
  ModalText,
  ModalTitle,
} from '../../../common/components/modal';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { toggleUpdateContactsModalOpen } from '../../redux/sellerPropertiesSlice';
import {
  Header,
  SubmitButton,
} from '../../../auth/components/modals/profile-edit-modal/profile-edit-modal-styles';
import { ReactComponent as SingleNeutral } from '../../../../assets/streamline-light/users/natural-close-up-single-user-neutral/single-neutral.svg';
import Icon from '../../../common/components/ui/icon';
import { ReactComponent as Close } from '../../../../assets/streamline-light/interface-essential/form-validation/close.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/long-arrow.svg';
import { UpdatedFormValue } from '../../interfaces';
import { IconContainer } from '../seller-property-card/seller-property-card-styles';
import {
  ParagraphText,
  ParagraphTitle,
} from '../../../common/components/ui/typography';
import { SecondaryButton } from '../../../common/components/ui/buttons';
import { device } from '../../../../style/theme';

const ChangedValueWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 32px;
  }

  & svg > g path {
    stroke: unset;
  }
`;

const Label = styled(ParagraphTitle)`
  font-size: 12px;
  line-height: 1.33;
  opacity: 0.5;
`;

const NewValue = styled(ParagraphText)`
  font-size: 12px;
  line-height: 1.33;
  color: #002849;
`;

const OldValue = styled(NewValue)`
  color: #dc3545;
  text-decoration: line-through;
`;

interface IProps {
  isOpen: boolean;
  onSubmit: (updatedValues: UpdatedFormValue[]) => void;
  updatedValues: UpdatedFormValue[];
}

const UpdateContactsModalBase: FC<IProps> = ({
  onSubmit,
  isOpen,
  updatedValues,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();
  const { t } = useTranslation();

  const onClose = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      batch(() => {
        dispatch(toggleUpdateContactsModalOpen(false));
      });
    },
    [dispatch]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      wrapperStyles={{
        maxWidth: isMobileSize ? '100%' : '622px',
        maxHeight: isMobileSize ? '100%' : 'unset',
        width: '100%',
        height: isMobileSize ? '100%' : 'unset',
        overflow: 'overlay',
        padding: isMobileSize ? 0 : '32px',
        position: 'relative',
        borderRadius: isMobileSize ? 'unset' : '3px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header>
          <ModalTitle
            icon={isMobileSize ? undefined : SingleNeutral}
            text={t(
              'seller.preparation-card.contact-form.update-contacts-modal.title'
            )}
            textColor={isMobileSize ? 'white' : undefined}
            iconPosition={isMobileSize ? undefined : 'left'}
            textAlign={isMobileSize ? 'center' : 'left'}
            margin="0"
          />
          {isMobileSize && (
            <IconContainer onClick={onClose}>
              <Icon
                icon={Close}
                width={16}
                height={16}
                color={themeContext.white}
              />
            </IconContainer>
          )}
        </Header>
        <div style={{ padding: isMobileSize ? '16px' : 0 }}>
          <ModalText
            text={t(
              'seller.preparation-card.contact-form.update-contacts-modal.text'
            )}
          />
          {updatedValues
            ?.filter((value) => value.name !== 'phonePrefix')
            ?.map((value) => (
              <ChangedValueWrapper>
                <Label content={`${value.label}:`} />
                <OldValue content={value.oldValue} />
                <Icon
                  icon={ArrowRight}
                  width={77}
                  height={1}
                  fillColor={'#4E73F5'}
                />
                <NewValue content={value.newValue} />
              </ChangedValueWrapper>
            ))}
          <ModalFooter>
            <SecondaryButton
              label={t(
                'seller.preparation-card.contact-form.update-contacts-modal.button.reject'
              )}
              onClick={onClose}
              style={{ maxWidth: 'fit-content' }}
            />

            <SubmitButton
              label={t(
                'seller.preparation-card.contact-form.update-contacts-modal.button.apply'
              )}
              loader
              onClick={() => onSubmit(updatedValues)}
              style={{ maxWidth: 'fit-content' }}
            />
          </ModalFooter>
        </div>
      </div>
    </Modal>
  );
};

const UpdateContactsModal = memo(UpdateContactsModalBase);

export { UpdateContactsModal };
