import { forwardRef, useCallback, useRef, useState, useEffect } from 'react';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NavigationOptions } from 'swiper/types/components/navigation';
import { useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SwiperCore, { Navigation } from 'swiper';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';

import { isEmpty } from 'lodash';

import { SliderNavButton } from './nav-button';
import { setIsGalleryOpen } from '../../redux/exposeSlice';
import { SecondaryButton } from '../../../../../common/components/ui/buttons';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import {
  BuilderCount,
  ButtonsWrapper,
  SliderContainer,
  SliderImage,
  ZoomButton,
  ZoomIn,
  ZoomOut,
} from './slider-styles';
import { MAX_MOBILE_SIZE } from '../../../../../common/constants';

SwiperCore.use([Navigation]);

interface IProps {
  height?: string;
  isGallery?: boolean;
  images: string[];
  activeSlide: number;
  setActiveSlide: (index: number) => unknown;
  isFloorPlanGallery?: boolean;
  objectFit?: 'cover' | 'contain';
}

export const Slider = forwardRef<unknown, IProps>(
  (
    {
      isGallery,
      images,
      height,
      activeSlide,
      setActiveSlide,
      isFloorPlanGallery = false,
      objectFit,
    },
    swiperRef
  ) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobileSize = useIsMobileSize();
    const [imageZoom, setImageZoom] = useState<number>(1);
    const [isPortrait, setIsPortrait] = useState<boolean>(false);
    const [currentImg, setCurrentImg] = useState<HTMLImageElement>();

    const prevRef = useRef<HTMLDivElement>(null);
    const nextRef = useRef<HTMLDivElement>(null);

    const onOpenFullScreen = () => {
      dispatch(setIsGalleryOpen(true));
    };

    const onSlideChange = (swiper: SwiperCore) => {
      if (imageZoom > 1) {
        setImageZoom(1);
        // reset img position after zoom
        const tween = gsap.set('.drag-wrapper img', {
          x: 0,
          y: 0,
          duration: 0,
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        swiperRef?.current?.swiper.attachEvents();
      }
      dispatch(setActiveSlide(swiper.activeIndex));
    };

    const builderCountLabel = `+ ${images.length} ${t(
      'expose-page.tabs.overview.gallery.pictures'
    )}`;

    const onZoomIn = useCallback(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      swiperRef?.current?.swiper.detachEvents(); // disable swiper dragging for zoomed img
      if (imageZoom < 3) {
        setImageZoom((prevState) => prevState + 0.5);
      }
    }, [imageZoom, swiperRef]);

    const onZoomOut = useCallback(() => {
      if (imageZoom !== 1) {
        if (imageZoom === 1.5) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          swiperRef?.current?.swiper.attachEvents();
        }

        setImageZoom((prevState) => prevState - 0.5);

        // reset img position after zoom
        const tween = gsap.set('.drag-wrapper img', {
          x: 0,
          y: 0,
          duration: 0,
        });

        tween.play();
      }
    }, [imageZoom, swiperRef]);

    useEffect(() => {
      if (isGallery && window.innerWidth > MAX_MOBILE_SIZE) {
        gsap.registerPlugin(Draggable);

        Draggable.create('.drag-wrapper img', {
          type: 'x,y',
          bounds: {
            height: 540,
            left: 0,
            top: 0,
            width: 1000,
          },
        });
        Draggable.zIndex = 1;
      }
    }, [isGallery]);

    useEffect(() => {
      if (
        currentImg &&
        (currentImg?.naturalHeight ?? 0) > (currentImg?.naturalWidth ?? 0)
      ) {
        setIsPortrait(true);
      } else {
        setIsPortrait(false);
      }
    }, [activeSlide, currentImg]);

    const onRefChange = useCallback((node) => {
      setCurrentImg(node);
    }, []);

    return (
      <>
        <SliderContainer height={height} isGallery={isGallery}>
          <SliderNavButton isGallery={isGallery} isPrev buttonRef={prevRef} />
          <SliderNavButton isGallery={isGallery} buttonRef={nextRef} />
          <Swiper
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={swiperRef}
            id="controller"
            allowTouchMove
            tag="section"
            wrapperTag="ul"
            speed={600}
            initialSlide={activeSlide}
            onSlideChange={onSlideChange}
            onInit={(swiper) => {
              // Assign the custom button to the prevEl
              // eslint-disable-next-line no-param-reassign
              (swiper.params.navigation as NavigationOptions).prevEl =
                prevRef.current;
              // Assign the custom button to the nextEl
              // eslint-disable-next-line no-param-reassign
              (swiper.params.navigation as NavigationOptions).nextEl =
                nextRef.current;
              // Force update swiper with new navigation buttons
              swiper.navigation.update();
            }}
            // style={{}}
          >
            {images.map((item, i) => (
              <SwiperSlide key={`${item}${i}`} onClick={onOpenFullScreen}>
                {(item.includes('floor-plan') ||
                  isPortrait ||
                  isFloorPlanGallery) &&
                  !isMobileSize &&
                  isGallery && (
                    <ButtonsWrapper>
                      <ZoomButton onClick={onZoomIn}>
                        <ZoomIn />
                      </ZoomButton>
                      <ZoomButton onClick={onZoomOut}>
                        <ZoomOut />
                      </ZoomButton>
                    </ButtonsWrapper>
                  )}
                <div
                  className={
                    isGallery && !isMobileSize ? 'drag-wrapper' : 'wrapper'
                  }
                  style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <SliderImage
                    ref={(el) => {
                      const node = i === activeSlide ? el : null;
                      onRefChange(node);
                    }}
                    src={item}
                    alt={'property-photo'}
                    zoom={imageZoom}
                    isPortrait={
                      item.includes('floor-plan') ||
                      isPortrait ||
                      isFloorPlanGallery
                    }
                    objectFit={objectFit}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {!isMobileSize && !isGallery && (
            <BuilderCount onClick={onOpenFullScreen}>
              {builderCountLabel}
            </BuilderCount>
          )}
        </SliderContainer>
        {isMobileSize && !isGallery && (
          <SecondaryButton
            label={builderCountLabel}
            borderColor={theme.blue}
            color={theme.blue}
            onClick={onOpenFullScreen}
            style={{
              width: '100%',
              justifyContent: 'center',
              marginTop: '12px',
              marginBottom: '18px',
            }}
          />
        )}
      </>
    );
  }
);
