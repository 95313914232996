import styled from 'styled-components';
import {
  ParagraphText,
  ParagraphTitle,
} from '../../../common/components/ui/typography';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { device } from '../../../../style/theme';
import { SecondaryButton } from '../../../common/components/ui/buttons';

export const Container = styled.div``;

export const UserInfo = styled.div`
  display: grid;
  grid-template-areas:
    'fullName checkbox'
    'email checkbox';
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 2fr 1fr;
  border: ${(props) => `1px solid ${hexToRGB(props.theme.blue, 0.4)}`};
  border-radius: 3px;
  padding: 8px 8px 8px 16px;
`;

export const FullName = styled(ParagraphTitle)`
  grid-area: fullName;
`;

export const Email = styled(ParagraphText)`
  grid-area: email;
`;

export const CheckboxWrapper = styled.div`
  grid-area: checkbox;
  align-self: center;
  justify-self: center;
`;

export const UsersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  height: 400px;
  overflow-y: scroll;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: space-between;

  & button {
    justify-content: center;
  }

  @media ${device.tablet} {
    flex-direction: column;
    margin-top: 36px;
    gap: 8px;
  }
`;

export const LoadMoreButtonContainer = styled.div`
  margin: 12px 0;
`;

export const LoadMoreButton = styled(SecondaryButton)`
  justify-content: center;
`;

export const NotificationInfoMessage = styled(ParagraphText)`
  margin-bottom: 12px;
`;
