import { FlatButton } from 'modules/common/components/ui/buttons';
import {
  Headline3,
  ParagraphText,
  TitleSlogan,
} from 'modules/common/components/ui/typography';
import { device } from 'style/theme';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  inWidget?: boolean;
  isListView?: boolean;
}>`
  background: ${({ inWidget }) =>
    !inWidget ? 'rgba(229, 233, 236, 0.4)' : 'none'};
  padding: ${({ inWidget }) => (inWidget ? '24px 0' : '12px 12px 24px')};

  ${({ isListView }) =>
    isListView
      ? `
    .content {
      display: flex;
      gap: 16px;
      align-items: flex-start;

      .text {
        width: 60%;
      }

      .form-container {
        width: 40%;
        padding-top: 0;
      }

      @media ${device.tablet} {
        flex-direction: column;

        .text, .form-container {
          width: 100%
        }
      }
    }
  `
      : ''};
`;

export const Title = styled(Headline3)`
  font-size: 16px;
  margin-bottom: 12px;
`;

export const Content = styled.div``;
export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding-top: 12px;

  .input-wrapper {
    > div {
      width: 100%;
      max-width: 280px;
    }
    input {
      min-width: 0;
      width: 100%;
    }
  }
`;

export const Text = styled(ParagraphText)`
  &.invalid {
    color: #970f0f;
  }
`;

export const SubmitButton = styled(FlatButton)<{
  isFullWidth?: boolean;
  isLoading?: boolean;
}>`
  position: relative;
  background-color: ${({ theme }) => theme.ctaBlue};
  margin-top: 8px;
  padding: 10px 24px !important;
  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
    `}
  min-width: ${({ isLoading }) => (isLoading ? '230px' : 0)} !important;
  text-align: ${({ isLoading }) => (isLoading ? 'left' : 'center')} !important;
  ${({ isFullWidth }) =>
    isFullWidth
      ? `
    flex-grow: 2;
  `
      : ''};

  &:disabled,
  &:disabled:hover {
    opacity: 1;
    background: ${({ theme }) => theme.ctaDisabledBackground};
  }
`;

export const Success = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 8px;
  justify-content: center;
`;

export const SuccessLabel = styled(TitleSlogan)`
  text-transform: uppercase;
  color: #28560b;
`;

export const IconWrap = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.green};
`;
