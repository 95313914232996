import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { isEqual, pickBy, isString, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  CardHeader,
  Container,
  Description,
  Form,
  InfoBox,
  InfoBoxText,
  ServicesList,
  SubmitButton,
  Subtitle,
  Title,
} from './services-container-styles';
import { BoxSelector } from '../../box-selector/box-selector';

import { ReactComponent as PublicSaleOutlined } from '../../../../../assets/public-sale-outlined.svg';
import { ReactComponent as PublicSaleFilled } from '../../../../../assets/public-sale-filled.svg';
import { ReactComponent as PrivateSaleOutlined } from '../../../../../assets/private-sale-outlined.svg';
import { ReactComponent as PrivateSaleFilled } from '../../../../../assets/private-sale-filled.svg';
import {
  linkServiceExamples,
  overlayServiceExamples,
  SERVICE_FORM_KEYS,
  ServicePackage,
  services,
} from '../../../constants';
import { ServiceCard } from '../service-card/service-card';
import { IServiceForm } from '../../../interfaces';

import {
  OnOfficeServices,
  ServicesInput,
  useSendPropertyServicesMutation,
} from '../../../../../generated';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import { ServiceOverlay } from '../service-overlay/service-overlay';
import { setIsGalleryOpen } from '../../../pages/expose-details-overlay/redux/exposeSlice';
import { toggleServiceExampleOpen } from '../../../redux/sellerPropertiesSlice';
import { useAppSelector } from '../../../../common/hooks';
import { device } from '../../../../../style/theme';
import { GlobalLoader } from '../../../../common/components/ui/loaders/global-loader';

const LoaderContainer = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  padding: 32px;
  border: 1px solid ${({ theme }) => theme.ctaDisabledBackground};
  border-radius: 3px;

  @media ${device.tablet} {
    padding: 16px;
  }

  &&&& {
    > div {
      position: absolute;
    }
  }
`;

interface IProps {
  currentValuation?: number | null;
  preloadedServices?: ServicesInput | null;
  isLocked?: boolean;
  disabledShopServices?: string[] | null;
  isSimulation: boolean;
  disabledPropertyServices: string[] | null;
}

const ServicesContainerBase: FC<IProps> = ({
  currentValuation,
  preloadedServices,
  isLocked,
  disabledShopServices,
  isSimulation,
  disabledPropertyServices,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { propertyId } = useParams<{ propertyId: string }>();
  const [selectedPackage, setSelectedPackage] = useState<ServicePackage>(
    preloadedServices?.SecretSale
      ? ServicePackage.Private
      : ServicePackage.Public
  );
  const [serviceExample, setServiceExample] = useState<string[]>([]);
  const serviceExampleRef = useRef<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const isExampleOpen = useAppSelector(
    (state) => state.sellerProperties.isServiceExampleOpen
  );
  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);
  const isReadOnlyAccess = useAppSelector(
    (state) => state.sellerProperties.isReadOnlyAccess
  );

  const methods = useForm<IServiceForm>({
    mode: 'onSubmit',
    defaultValues: {
      ...preloadedServices,
      package: preloadedServices?.SecretSale
        ? ServicePackage.Private
        : ServicePackage.Public,
    },
  });

  const [sendServices, { isLoading, error: sendServicesError }] =
    useSendPropertyServicesMutation();

  const servicesByPackage = useMemo(() => {
    const filteredServices = services.filter((service) => {
      if (service.availableForPropertyWithPrice && currentValuation) {
        return (
          service.servicePackages.includes(selectedPackage) &&
          service.availableForPropertyWithPrice <= currentValuation
        );
      }
      return service.servicePackages.includes(selectedPackage);
    });
    if (
      !isEmpty(filteredServices) &&
      (!isEmpty(disabledShopServices) || !isEmpty(disabledPropertyServices))
    ) {
      return filteredServices.filter((service) => {
        if (!disabledPropertyServices) {
          return !disabledShopServices?.includes(service.name);
        }

        return !disabledPropertyServices?.includes(service.name);
      });
    }
    return filteredServices;
  }, [
    disabledShopServices,
    disabledPropertyServices,
    currentValuation,
    selectedPackage,
  ]);

  const onSubmit: SubmitHandler<IServiceForm> = useCallback(
    async (formData: IServiceForm) => {
      try {
        // prevent submit on example modal open
        if (isLocked || serviceExampleRef.current) {
          return;
        }
        const { package: servicePackage, ...selectedServices } = formData;
        const preloadedPackage = preloadedServices?.SecretSale
          ? ServicePackage.Private
          : ServicePackage.Public;
        if (
          !isEqual(selectedServices, preloadedServices) ||
          !isEqual(preloadedPackage, servicePackage)
        ) {
          const allServices = {
            ...selectedServices,
            [SERVICE_FORM_KEYS.secretSale]:
              servicePackage === ServicePackage.Private,
            [SERVICE_FORM_KEYS.socialMedia]:
              selectedServices.SocialMedia &&
              servicePackage === ServicePackage.Public,
            [SERVICE_FORM_KEYS.highCirculation]:
              selectedServices.HighCirculation &&
              servicePackage === ServicePackage.Public,
            [SERVICE_FORM_KEYS.magazines]:
              selectedServices.Magazines &&
              servicePackage === ServicePackage.Public,
          };

          const input = pickBy(allServices, (value) => !!value);
          await sendServices({
            propertyId,
            services: Object.keys(input) as OnOfficeServices[],
          }).unwrap();
          setIsChanged(false);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [preloadedServices, propertyId, sendServices, isLocked]
  );

  const onPackageChange = useCallback(
    (boxId: string) => {
      if (!isLocked) {
        setSelectedPackage(boxId as ServicePackage);
        setIsChanged(true);
      }
      // await methods.handleSubmit(onSubmit)();
    },
    [isLocked]
  );

  const onShowExample = useCallback(
    (id: OnOfficeServices) => {
      if (isLocked) {
        return;
      }
      serviceExampleRef.current = true;
      dispatch(toggleServiceExampleOpen(true));
      const service = services.find((item) => item.id === id);
      if (
        service?.example &&
        Array.isArray(service?.example) &&
        overlayServiceExamples.includes(id)
      ) {
        setServiceExample(service.example);
        dispatch(setIsGalleryOpen(true));
      }

      if (
        service?.example &&
        isString(service?.example) &&
        linkServiceExamples.includes(id)
      ) {
        window.open(service.example, '_blank');
      }
    },
    [dispatch, isLocked]
  );

  useEffect(() => {
    serviceExampleRef.current = isExampleOpen;
  }, [isExampleOpen]);

  if (isLoading) {
    return (
      <LoaderContainer>
        <GlobalLoader />
      </LoaderContainer>
    );
  }

  return (
    <FormProvider {...methods}>
      {sendServicesError?.message && (
        <GlobalError
          title={t(sendServicesError?.message?.split(':')[0] ?? '')}
        />
      )}
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container
          key={`services-container-${isExampleOpen ? 'visible' : 'hidden'}`}
        >
          <CardHeader>
            <Title content={t('seller.services-card.title')} />
          </CardHeader>
          <Description content={t('seller.services-card.description')} />
          <Subtitle
            content={t('seller.services-card.service-packages.title')}
          />
          <BoxSelector
            isColumn
            onSelect={onPackageChange}
            initialActiveBox={methods.getValues('package')}
            name={'package'}
            boxes={[
              {
                id: ServicePackage.Public,
                title: t(
                  'seller.services-card.service-packages.public-sale.title'
                ),
                description: t(
                  'seller.services-card.service-packages.public-sale.description'
                ),
                icon: PublicSaleOutlined,
                activeIcon: PublicSaleFilled,
                iconPosition: 'left',
                value: ServicePackage.Public,
              },
              {
                id: ServicePackage.Private,
                title: t(
                  'seller.services-card.service-packages.secret-sale.title'
                ),
                description: t(
                  'seller.services-card.service-packages.secret-sale.description'
                ),
                icon: PrivateSaleOutlined,
                activeIcon: PrivateSaleFilled,
                iconPosition: 'left',
                value: ServicePackage.Private,
              },
            ]}
          />
          <Subtitle
            content={t(
              'seller.services-card.service-packages.add-packages.title'
            )}
          />
          <ServicesList>
            {servicesByPackage.map(
              ({
                id,
                title,
                description,
                imageSrc,
                checkboxLabel,
                buttonLabel,
                isButtonFilled,
                name,
              }) => (
                <ServiceCard
                  key={id}
                  id={id}
                  title={t(title)}
                  description={t(description)}
                  imageSrc={imageSrc}
                  checkboxLabel={t(checkboxLabel)}
                  buttonLabel={t(buttonLabel ?? '')}
                  isButtonFilled={isButtonFilled}
                  // onChange={methods.handleSubmit(onSubmit)}
                  onChange={() => setIsChanged(true)}
                  name={name}
                  isPropertyServiceSelected={methods.getValues(name)}
                  onShowExample={onShowExample}
                  isPropertyServiceDisabled={
                    isBrokerView || isLocked || isReadOnlyAccess
                  }
                  isSimulation={isSimulation}
                />
              )
            )}
          </ServicesList>
          {!isSimulation && (
            <SubmitButton
              label={t('seller.services-card.button.save')}
              isDisabled={
                !isChanged || isBrokerView || isLocked || isReadOnlyAccess
              }
            />
          )}
          <InfoBox>
            <InfoBoxText content={t('seller.services-card.info')} />
          </InfoBox>
        </Container>
      </Form>
      <ServiceOverlay images={serviceExample} />
    </FormProvider>
  );
};

const ServicesContainer = memo(ServicesContainerBase);

export { ServicesContainer };
