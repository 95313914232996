import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Title } from '../../../../../components/fullscreen-modal/auth-container/styles';
import {
  Description,
  Container,
  SubmitButton,
  ContainerFields,
  Text,
} from './financing-personal-form-container-styles';
import { InputType, IRegisterForm } from '../../../../../../auth/interfaces';
import { InputV2 } from '../../../../../components/common/input-v2/input-v2';
import { SelectV2 } from '../../../../../components/common/select-v2/select-v2';
import { useSubmitFinancingPersonal } from '../../../../hooks/submit-hooks/useSubmitFinancingPersonal';
import { useSubmitFinancingForm } from '../../../../hooks/submit-hooks/useSubmitFinancingForm';
import { GlobalError } from '../../../../../../common/components/form/error/global-error';
import { useFinancingFormInfo } from '../../../../hooks/useFinancingFormInfo';
import { FIELDS } from '../../../../constants';

const FinancingPersonalFormContainer: FC = () => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<null | unknown>(null);
  const { isFinancingCertificate } = useFinancingFormInfo();

  const { onCreateFinancing } = useSubmitFinancingForm({});
  const methods = useForm<IRegisterForm>();
  const { onFinancingPersonalSubmit } = useSubmitFinancingPersonal({
    setErrors,
    onSuccess: onCreateFinancing,
  });

  return (
    <Container>
      <Title>{t('financing.personal-form.title')}</Title>
      <Description>
        Geben Sie jetzt Ihr Haushaltsnettoeinkommen & Beschäftigungsverhältnis
        des Hauptverdieners ein, damit wir Ihre Ergebnisse präziser berechenen
        können.
      </Description>
      <FormProvider {...methods}>
        {errors && (
          <GlobalError
            title={t((errors as { message: string })?.message?.split(':')[0])}
          />
        )}
        <form onSubmit={methods.handleSubmit(onFinancingPersonalSubmit)}>
          <ContainerFields>
            <InputV2
              type={InputType.number}
              name={FIELDS.HOUSEHOLD_NET_INCOME.name}
              label={FIELDS.HOUSEHOLD_NET_INCOME.label}
              placeholder={FIELDS.HOUSEHOLD_NET_INCOME.placeholder}
              inputAdornment={FIELDS.HOUSEHOLD_NET_INCOME.inputAdornment}
              isSeparate
              isValidateOnBlur
              rules={FIELDS.HOUSEHOLD_NET_INCOME.rules}
              isColumn
            />
            <SelectV2
              isColumn
              name={FIELDS.MAIN_EARNER.name}
              label={FIELDS.MAIN_EARNER.label}
              placeholder={FIELDS.MAIN_EARNER.placeholder}
              selectOptions={FIELDS.MAIN_EARNER.options}
              rules={FIELDS.MAIN_EARNER.rules}
            />
            <InputV2
              type={InputType.number}
              name={FIELDS.AGE.name}
              label={FIELDS.AGE.label}
              placeholder={FIELDS.AGE.placeholder}
              rules={FIELDS.AGE.rules}
              isColumn
              isValidateOnBlur
            />
            <div />
          </ContainerFields>
          <Text>
            {t(
              'financing-portal-page.tabs.calculator.edit.inputs.checkbox.label'
            )}{' '}
            <a
              href="https://www.vp-finance.de/datenschutz"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(
                'register.form.input.label.terms-and-conditions.financing-2-link'
              )}
            </a>
            .
          </Text>
          <SubmitButton
            isRedesign
            isFullWidth={false}
            label={t(
              isFinancingCertificate
                ? 'financing.personal-form.button-certificate'
                : 'financing.personal-form.button'
            )}
          />
        </form>
      </FormProvider>
    </Container>
  );
};

export { FinancingPersonalFormContainer };
