import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { FC } from 'react';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import useGetLocalization from '../../localization/get-localization';
import { ForgotPasswordForm } from '../components/forms/forgot-password-form';
import { LoginForm } from '../components/forms/login-form';
import { BoxTitle, Headline1 } from '../../common/components/ui/typography';
import { device } from '../../../style/theme';
import { ConfirmForgotPasswordForm } from '../components/forms/confirm-forgot-password-form';
import { isTokenExpired } from '../../common/utils/is-token-expired';
import { setSelectedTab } from '../../property/redux/dynamicMapUtilsSlice';
import { siteMap } from '../../../routes/site-map';

export const Header = styled(Headline1)<{ color?: string }>`
  position: relative;
  color: ${(props) => (props.color ? props.color : props.theme.blue)};
  margin-bottom: 24px;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
    margin-bottom: 12px;
  }
`;

export const Container = styled.div`
  width: 100%;
`;

const SignUpPageLink = styled.div`
  margin: 0;
  font-family: 'Roboto' !important;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  color: ${(props) => props.theme.blue};
  letter-spacing: 0.8px;
  opacity: 0.5;
  cursor: pointer;
  text-align: center;
`;

export const SellerLoginPage: FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const lang = useGetLocalization();
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const sellerPropertyId = urlParams.get('sellerPropertyId') || '';
  const addressId = urlParams.get('addressId') || '';
  const openTab = urlParams.get('openTab') || '';
  const pdfToken = urlParams.get('pdfToken') || '';

  const isForgottenPasswordOpen = useAppSelector(
    (state) => state.auth.isForgottenPasswordOpen
  );

  const isConfirmForgotPasswordModalOpen = useAppSelector(
    (state) => state.auth.isConfirmForgotPasswordModalOpen
  );

  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const exp = useAppSelector((state) => state.auth.exp);

  if (!isAnonymousUser && exp) {
    const isExpired = isTokenExpired(exp);
    if (!isExpired) {
      if (!sellerPropertyId) {
        return (
          <Redirect
            to={`/${lang}${siteMap.SellerPropertiesPage.pathWithoutParams}`}
          />
        );
      }

      if (sellerPropertyId && pdfToken) {
        return (
          <Redirect
            to={`/${lang}${siteMap.DownloadSellerExpose.pathWithoutParams}/${sellerPropertyId}/${pdfToken}`}
          />
        );
      }

      if (Number(openTab)) {
        dispatch(setSelectedTab(Number(openTab)));
      } else {
        dispatch(setSelectedTab(0));
      }

      return (
        <Redirect
          to={`/${lang}${siteMap.SellingProcessPage.pathWithoutParams}/${sellerPropertyId}`}
        />
      );
    }
  }

  return (
    <Container>
      <Helmet>
        <title>{t('buyer-portal.title')}</title>
      </Helmet>
      <Header content={t('header.authentication.login')} />
      {isConfirmForgotPasswordModalOpen ? (
        <ConfirmForgotPasswordForm />
      ) : isForgottenPasswordOpen ? (
        <ForgotPasswordForm />
      ) : (
        <>
          <LoginForm />
          <SignUpPageLink
            dangerouslySetInnerHTML={{
              __html: t('seller-login-page.sign-up-link'),
            }}
            onClick={() =>
              push(
                `/seller-sign-up-page/?addressId=${addressId}&sellerPropertyId=${sellerPropertyId}`
              )
            }
          />
        </>
      )}
    </Container>
  );
};
