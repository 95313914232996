import { FC, useEffect } from 'react';
import useGetLocalization from './get-localization';
import {
  supportedLanguagesCountryBuyerPortal,
  supportedLanguagesCountryFinancingPortal,
  supportedLanguagesCountryOwnerPortal,
  supportedLanguagesCountrySellerPortal,
} from './constants';
import { LngRedirect } from './lng-redirect';
import { useGetPortalType } from '../landing/hooks/useGetPortalType';

interface IProps {
  isError?: boolean;
}

export const LocalizationContainer: FC<IProps> = ({ children, isError }) => {
  // localization logic
  const lang = useGetLocalization();
  const savedLang = localStorage.getItem('localization');
  const { isOwner, isBuyer, isSeller, isFinancing } = useGetPortalType();

  useEffect(() => {
    if (!savedLang || savedLang !== lang) {
      if (supportedLanguagesCountryOwnerPortal.includes(lang) && isOwner) {
        localStorage.setItem('localization', lang);
      } else if (
        supportedLanguagesCountryBuyerPortal.includes(lang) &&
        isBuyer
      ) {
        localStorage.setItem('localization', lang);
      } else if (
        supportedLanguagesCountrySellerPortal.includes(lang) &&
        isSeller
      ) {
        localStorage.setItem('localization', lang);
      } else if (
        supportedLanguagesCountryFinancingPortal.includes(lang) &&
        isFinancing
      ) {
        localStorage.setItem('localization', lang);
      }
    }
  }, [savedLang, lang, isOwner, isBuyer, isSeller, isFinancing]);

  if (isError) {
    return <>{children}</>;
  }

  if (!supportedLanguagesCountryOwnerPortal.includes(lang) && isOwner) {
    if (savedLang && supportedLanguagesCountryOwnerPortal.includes(savedLang)) {
      return (
        <LngRedirect
          langProp={savedLang}
          to={`${window.location.pathname}${window.location.search}`}
        />
      );
    }

    return (
      <LngRedirect
        langProp={'de-de'}
        to={`${window.location.pathname}${window.location.search}`}
      />
    );
  }

  if (!supportedLanguagesCountryBuyerPortal.includes(lang) && isBuyer) {
    const pathname = supportedLanguagesCountryOwnerPortal.includes(lang)
      ? window.location.pathname.split(lang)[1]
      : window.location.pathname;

    if (savedLang && supportedLanguagesCountryBuyerPortal.includes(savedLang)) {
      return (
        <LngRedirect
          langProp={savedLang}
          to={`${pathname}${window.location.search}`}
        />
      );
    }

    return (
      <LngRedirect
        langProp={'de-de'}
        to={`${pathname}${window.location.search}`}
      />
    );
  }

  if (!supportedLanguagesCountrySellerPortal.includes(lang) && isSeller) {
    const pathname = supportedLanguagesCountryOwnerPortal.includes(lang)
      ? window.location.pathname.split(lang)[1]
      : window.location.pathname;

    if (
      savedLang &&
      supportedLanguagesCountrySellerPortal.includes(savedLang)
    ) {
      return (
        <LngRedirect
          langProp={savedLang}
          to={`${pathname}${window.location.search}`}
        />
      );
    }

    return (
      <LngRedirect
        langProp={'de-de'}
        to={`${pathname}${window.location.search}`}
      />
    );
  }

  if (!supportedLanguagesCountryFinancingPortal.includes(lang) && isFinancing) {
    const pathname = supportedLanguagesCountryOwnerPortal.includes(lang)
      ? window.location.pathname.split(lang)[1]
      : window.location.pathname;

    if (
      savedLang &&
      supportedLanguagesCountryFinancingPortal.includes(savedLang)
    ) {
      return (
        <LngRedirect
          langProp={savedLang}
          to={`${pathname}${window.location.search}`}
        />
      );
    }

    return (
      <LngRedirect
        langProp={'de-de'}
        to={`${pathname}${window.location.search}`}
      />
    );
  }

  return <>{children}</>;
};
