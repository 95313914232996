import { useSearchProfileQuery } from '../../../services/graphql/enhanced';
import { implodeAddress } from '../utils/address';

interface IProps {
  searchProfileId: string;
}

const useGetSearchProfile = ({ searchProfileId }: IProps) => {
  const {
    overviewProps,
    address: searchProfileAddress,
    isLoading: isSearchProfileLoading,
    isSuccess: isFetchingDone,
    error: searchProfileErrors,
    coordinates: searchProfileCoordinates,
    countryCodeBase: countryCode,
    newPropertiesCountData: newPropertiesCount,
    isNotifyPriceChange,
    isNotifyNewProperties,
    isNotifyNewPropertiesSummary,
  } = useSearchProfileQuery(
    {
      id: searchProfileId,
    },
    {
      selectFromResult: ({ data, isLoading, error, isSuccess }) => {
        const {
          landAreaMax,
          landAreaMin,
          livingAreaMax,
          livingAreaMin,
          location,
          numberOfBathroomsMax,
          numberOfBathroomsMin,
          numberOfRoomsMax,
          numberOfRoomsMin,
          propertyCode,
          purchasePriceMax,
          purchasePriceMin,
          radius,
        } = data?.searchProfile?.searchProfileData || {};
        const newPropertiesCountData =
          data?.searchProfile?.latestSuggestedProperties?.length;

        const { address, coordinates } = location || {};

        return {
          overviewProps: {
            landAreaMax,
            landAreaMin,
            livingAreaMax,
            livingAreaMin,
            numberOfBathroomsMax,
            numberOfBathroomsMin,
            numberOfRoomsMax,
            numberOfRoomsMin,
            purchasePriceMax,
            purchasePriceMin,
            radius,
            code: propertyCode,
            title: data?.searchProfile?.title,
            isCreatedVia3rdPartyPortal:
              data?.searchProfile?.isCreatedVia3rdPartyPortal,
          },
          newPropertiesCountData,
          error,
          isLoading,
          isSuccess,
          address: implodeAddress(address),
          coordinates,
          countryCodeBase: data?.searchProfile?.countryCode,
          isNotifyPriceChange: data?.searchProfile?.isNotifyPriceChange,
          isNotifyNewProperties: data?.searchProfile?.isNotifyNewProperties,
          isNotifyNewPropertiesSummary:
            data?.searchProfile?.isNotifyNewPropertiesSummary,
        };
      },
    }
  );

  return {
    overviewProps,
    searchProfileAddress,
    isSearchProfileLoading,
    isFetchingDone,
    searchProfileErrors,
    searchProfileCoordinates,
    countryCode,
    newPropertiesCount,
    isNotifyPriceChange,
    isNotifyNewProperties,
    isNotifyNewPropertiesSummary,
  };
};

export { useGetSearchProfile };
