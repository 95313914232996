export const getValuationStepTitle = (
  activeFormStep: number,
  isMultiFamilyHouse: boolean
): string => {
  switch (activeFormStep) {
    case 2: {
      if (isMultiFamilyHouse) {
        return 'valuation.form.second-step.condition.title';
      }
      return 'valuation.form.second-step.title';
    }
    case 3: {
      return 'valuation.form.third-step.title';
    }
    case 4: {
      return 'valuation.form.fourth-step.title';
    }
    case 5: {
      return 'valuation.form.fifth-step.title';
    }
    default:
      return 'valuation.form.first-step.title';
  }
};

export const getSearchStepTitle = (activeFormStep: number): string => {
  switch (activeFormStep) {
    case 2: {
      return 'search-profile.form.second-step.title';
    }
    default:
      return 'search-profile.form.first-step.title';
  }
};
