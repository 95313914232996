import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Container } from './financing-variants-content-styles';
import { FinancingVariantsContentHeader } from './financing-variants-content-header/financing-variants-content-header';
import { FinancingVariantsContentTable } from './financing-variants-content-table/financing-variants-content-table';
import { IVariant } from '../../../../constants';
import { useGetFinancing } from '../../../../hooks/useGetFinancing';
import { getTotalTermOfLoanUntilFullRepayment } from '../../../../utils/getTotalTermOfLoanUntilFullRepayment';
import { numberFormat } from '../../../../utils/numberFormat';
import { useHiddenVariants } from '../../../../hooks/useHiddenVariants';
import { FinancingReason } from '../../../../../../generated';
import { formatPeriodDate } from '../../../../../property/utils/date';

interface IProps {
  period: string;
}

const FinancingVariantsContent: FC<IProps> = ({ period }) => {
  const { financingId } = useParams<{ financingId: string }>();
  const { financingVariants } = useGetFinancing({
    financingId,
  });
  const { hiddenVariants, willBeHiddenVariant } = useHiddenVariants();

  const adaptedVariants = useMemo(() => {
    if (!financingVariants) {
      return {
        names: [],
        indexes: [],
        tableData: [],
      };
    }

    const bgColor = '#f2f5f7';
    const isBold = true;
    const financingVariantsWithoutHided = financingVariants.filter(
      (_, i) => !hiddenVariants.includes(i)
    );
    const adaptedData = financingVariantsWithoutHided.map((variant) => {
      const financingPropositionItem = variant.financingPropositions.find(
        (item) => item.debitRateCommitment === period
      );
      const proposition = financingPropositionItem?.propositions?.[0];
      const buildingBlock = proposition?.financingBuildingBlocks?.[0];

      const totalTermOfLoanUntilFullRepayment =
        getTotalTermOfLoanUntilFullRepayment(
          buildingBlock?.dateOfLastInstallment ?? ''
        );
      let repaidAmount = 0;
      if (
        buildingBlock?.loanAmount &&
        buildingBlock?.residualDebtAfterTheEndOfTheFixedInterestPeriod
      ) {
        repaidAmount = Math.round(
          buildingBlock?.loanAmount -
            buildingBlock?.residualDebtAfterTheEndOfTheFixedInterestPeriod
        );
      }

      let totalInterestPayments = 0;
      if (
        buildingBlock?.annuityDetails?.fixedInterestInYears &&
        buildingBlock?.rateMonthly &&
        buildingBlock?.loanAmount &&
        repaidAmount
      ) {
        totalInterestPayments =
          buildingBlock?.annuityDetails?.fixedInterestInYears *
            12 *
            buildingBlock?.rateMonthly -
          repaidAmount;
      }

      let totalInterestPaymentsEnd = 0;

      if (buildingBlock?.costOverTotalTerm && buildingBlock?.loanAmount) {
        totalInterestPaymentsEnd = Math.round(
          buildingBlock?.costOverTotalTerm - buildingBlock?.loanAmount
        );
      }

      const {
        reason,
        propertyValue,
        purchasePrice,
        landPrice,
        equityCapital,
        buildingCosts,
        payoutDate,
        estimatedRemainingDebt,
        desiredLoanAmount,
      } = variant.financingVariantData;
      let purchasePriceTable = 0;
      let propertyValueTable = 0;
      let desiredLoanAmountTable = 0;
      let landPriceTable = 0;
      let equityCapitalTable = 0;
      let buildingCostsTable = 0;
      let payoutDateTable;
      let estimatedRemainingDebtValue = 0;
      switch (reason) {
        case FinancingReason.PurchaseOfExistingProperty:
        case FinancingReason.PurchaseOfNewBuilding:
          purchasePriceTable = Number(purchasePrice);
          equityCapitalTable = Number(equityCapital);
          break;
        case FinancingReason.FollowUpFinancing:
          propertyValueTable = Number(propertyValue);
          payoutDateTable = payoutDate;
          propertyValueTable = Number(propertyValue);
          estimatedRemainingDebtValue = Number(estimatedRemainingDebt);
          break;
        case FinancingReason.RaisingCapital:
        case FinancingReason.Modernization:
          propertyValueTable = Number(propertyValue);
          desiredLoanAmountTable = Number(desiredLoanAmount);
          break;
        case FinancingReason.OwnConstructionProject:
          landPriceTable = Number(landPrice);
          buildingCostsTable = Number(buildingCosts);
          equityCapitalTable = Number(equityCapital);
          break;
        default:
          break;
      }

      const data = {
        maximalPurchasePrice:
          financingPropositionItem?.maximalPurchasePrice || '',
        purchasePriceTable,
        propertyValueTable,
        desiredLoanAmountTable,
        landPriceTable,
        equityCapitalTable,
        buildingCostsTable,
        payoutDateTable,
        estimatedRemainingDebtValue,
        loanAmount: proposition?.loanAmount || '',
        totalRatePerMonth: proposition?.totalRatePerMonth || '',
        shouldInterest: proposition?.shouldInterest || '',
        repaymentRateInPercent: buildingBlock?.repaymentRateInPercent || '',
        totalTermOfLoanUntilFullRepayment,
        // period: proposition.fixedInterestRateInYearsMinMax || '',
        residualDebt:
          buildingBlock?.residualDebtAfterTheEndOfTheFixedInterestPeriod || '',
        repaidAmount,
        totalInterestPayments,
        totalInterestPaymentsEnd,
        closingRate: buildingBlock?.closingRate || '',
      };

      return {
        maximalPurchasePrice: data.maximalPurchasePrice
          ? `${numberFormat(Number(data.maximalPurchasePrice))} €`
          : '—',
        buildingCosts: data.buildingCostsTable
          ? `${numberFormat(data.buildingCostsTable)} €`
          : '—',
        purchasePrice: data.purchasePriceTable
          ? `${numberFormat(data.purchasePriceTable)} €`
          : '—',
        propertyValue: data.propertyValueTable
          ? `${numberFormat(data.propertyValueTable)} €`
          : '—',
        desiredLoanAmount: data.desiredLoanAmountTable
          ? `${numberFormat(data.desiredLoanAmountTable)} €`
          : '—',
        landPrice: data.landPriceTable
          ? `${numberFormat(data.landPriceTable)} €`
          : '—',
        equityCapital: data.equityCapitalTable
          ? `${numberFormat(data.equityCapitalTable)} €`
          : '—',
        payoutDate: data.payoutDateTable
          ? formatPeriodDate(new Date(data.payoutDateTable), true, true)
          : '—',
        estimatedRemainingDebt: data.estimatedRemainingDebtValue
          ? `${numberFormat(data.estimatedRemainingDebtValue)} €`
          : '—',
        loanAmount: data.loanAmount
          ? `${numberFormat(Number(data.loanAmount))} €`
          : '—',
        totalRatePerMonth: data.totalRatePerMonth
          ? `${numberFormat(Number(data.totalRatePerMonth))} €`
          : '—',
        shouldInterest: data.shouldInterest
          ? `${numberFormat(Number(data.shouldInterest), true)} %`
          : '—',
        repaymentRateInPercent: data.repaymentRateInPercent
          ? `${numberFormat(Number(data.repaymentRateInPercent), true)} %`
          : '—',
        totalTermOfLoanUntilFullRepayment:
          data.totalTermOfLoanUntilFullRepayment,
        // period: `${numberFormat(Number(period))} Jahren`,
        residualDebt: data.residualDebt
          ? `${numberFormat(Number(data.residualDebt))} €`
          : '—',
        repaidAmount: data.repaidAmount
          ? `${numberFormat(Number(data.repaidAmount))} €`
          : '—',
        totalInterestPayments: data.totalInterestPayments
          ? `${numberFormat(Number(data.totalInterestPayments))} €`
          : '—',
        totalInterestPaymentsEnd: data.totalInterestPaymentsEnd
          ? `${numberFormat(Number(data.totalInterestPaymentsEnd))} €`
          : '—',
        closingRate: data.closingRate
          ? `${numberFormat(Number(data.closingRate))} €`
          : '—',
      };
    });

    const names = financingVariants?.map(
      (variant) => variant.financingVariantData.name
    );
    const indexes = financingVariants?.map(
      (variant) => variant.financingVariantData.index
    );

    const tableData: IVariant[][] = [
      // second table
      [
        ...(adaptedData?.some(
          (item) => item.buildingCosts && item.buildingCosts !== '—'
        )
          ? [
              {
                label: 'financing-portal.form.inputs.building-costs.label',
                keyValue: 'buildingCosts',
                values: adaptedData.map((item) => item.buildingCosts),
              },
            ]
          : []),
        ...(adaptedData?.some(
          (item) => item.purchasePrice && item.purchasePrice !== '—'
        )
          ? [
              {
                label: 'financing-portal.form.inputs.purchase-price.label',
                keyValue: 'purchasePrice',
                values: adaptedData.map((item) => item.purchasePrice),
              },
            ]
          : []),
        ...(adaptedData?.some(
          (item) => item.propertyValue && item.propertyValue !== '—'
        )
          ? [
              {
                label: 'financing-portal.form.inputs.property-value.label',
                keyValue: 'propertyValue',
                values: adaptedData.map((item) => item.propertyValue),
              },
            ]
          : []),
        ...(adaptedData?.some(
          (item) => item.desiredLoanAmount && item.desiredLoanAmount !== '—'
        )
          ? [
              {
                label: 'financing-portal.form.inputs.desired-loan-amount.label',
                keyValue: 'desiredLoanAmount',
                values: adaptedData.map((item) => item.desiredLoanAmount),
              },
            ]
          : []),
        ...(adaptedData?.some(
          (item) => item.landPrice && item.landPrice !== '—'
        )
          ? [
              {
                label: 'financing-portal.form.inputs.land-price.label',
                keyValue: 'landPrice',
                values: adaptedData.map((item) => item.landPrice),
              },
            ]
          : []),
        // {
        //   label: adaptedData[0].priceLabel,
        //   keyValue: 'purchasePrice',
        //   values: adaptedData.map((item) => item.priceValue),
        // },
        ...(adaptedData?.some(
          (item) => item.equityCapital && item.equityCapital !== '—'
        )
          ? [
              {
                label: 'financing-portal.form.inputs.equity-capital.label',
                keyValue: 'equityCapital',
                values: adaptedData.map((item) => item.equityCapital),
              },
            ]
          : []),
        ...(adaptedData?.some(
          (item) => item.payoutDate && item.payoutDate !== '—'
        )
          ? [
              {
                label: 'financing-portal.form.inputs.payout-date.label',
                keyValue: 'payoutDate',
                values: adaptedData.map((item) => item.payoutDate),
              },
            ]
          : []),
        ...(adaptedData?.some(
          (item) =>
            item.estimatedRemainingDebt && item.estimatedRemainingDebt !== '—'
        )
          ? [
              {
                label:
                  'financing-portal.form.inputs.estimated-remaining-debt.label',
                keyValue: 'estimatedRemainingDebt',
                values: adaptedData.map((item) => item.estimatedRemainingDebt),
              },
            ]
          : []),
        {
          label: 'financing-portal-page.tabs.calculator.data.table.loan-amount',
          keyValue: 'loanAmount',
          values: adaptedData.map((item) => item.loanAmount),
          isBold,
        },
      ],
      // third table
      [
        {
          label:
            'financing-portal-page.tabs.calculator.data.table.monthly-loan-installment',
          keyValue: 'totalRatePerMonth',
          values: adaptedData.map((item) => item.totalRatePerMonth),
          isBold,
        },
        {
          label:
            'financing-portal-page.tabs.calculator.data.table.debit-interest',
          keyValue: 'shouldInterest',
          values: adaptedData.map((item) => item.shouldInterest),
        },
        {
          label:
            'financing-portal-page.tabs.calculator.data.table.repayment-in-the-first-year',
          keyValue: 'repaymentRateInPercent',
          values: adaptedData.map((item) => item.repaymentRateInPercent),
        },
        {
          label:
            'financing-portal-page.tabs.calculator.data.table.total-term-of-the-loan-until-full-repayment',
          keyValue: 'totalTermOfLoanUntilFullRepayment',
          values: adaptedData.map(
            (item) => item.totalTermOfLoanUntilFullRepayment
          ),
          isBold,
        },
      ],
      // fourth table
      [
        {
          label:
            'financing-portal-page.tabs.calculator.data.table.at-the-end-of-the-fixed-interest-period-of',
          keyValue: '',
          values: adaptedData.map(() => ''),
          subName: `${period} Jahren`,
          isBold,
        },
        {
          label:
            'financing-portal-page.tabs.calculator.data.table.residual-debt',
          keyValue: 'residualDebtAfterTheEndOfTheFixedInterestPeriod',
          values: adaptedData.map((item) => item.residualDebt),
          isBold,
        },
        {
          label:
            'financing-portal-page.tabs.calculator.data.table.repaid-amount',
          keyValue: 'repaidAmount',
          values: adaptedData.map((item) => item.repaidAmount),
        },
        {
          label:
            'financing-portal-page.tabs.calculator.data.table.total-interest-payments',
          keyValue: 'totalInterestPayments',
          values: adaptedData.map((item) => item.totalInterestPayments),
        },
      ],
      // fifth table
      [
        {
          label:
            'financing-portal-page.tabs.calculator.data.table.at-the-end-of-the-loan',
          keyValue: '',
          values: adaptedData.map(() => ''),
          isBold,
          subfields: [
            {
              label:
                'financing-portal-page.tabs.calculator.data.table.repaid-amount',
              keyValue: 'loanAmount',
              values: adaptedData.map((item) => item.loanAmount),
            },
          ],
        },
        {
          label:
            'financing-portal-page.tabs.calculator.data.table.total-interest-payments',
          keyValue: 'totalInterestPaymentsEnd',
          values: adaptedData.map((item) => item.totalInterestPaymentsEnd),
          isBold,
        },
        {
          label:
            'financing-portal-page.tabs.calculator.data.table.last-loan-installment',
          keyValue: 'closingRate',
          values: adaptedData.map((item) => item.closingRate),
        },
      ],
    ];
    return {
      names: names || [],
      indexes: indexes || [],
      tableData: tableData || [],
    };
  }, [financingVariants, period, hiddenVariants]);

  const namesNotHidden = adaptedVariants.names.filter(
    (_, i) => !hiddenVariants.includes(i)
  );
  const indexesNotHidden = adaptedVariants.indexes.filter(
    (item) => !hiddenVariants.includes(item)
  );

  const valuesLength = namesNotHidden.length;
  const [from, setFrom] = useState(0);
  const isRight = from + 4 >= Number(valuesLength);

  const onChangeFrom = (value: number) => {
    setFrom(value);
  };
  const names = namesNotHidden.slice(from, from + 4);

  const filteredVariants = useMemo(
    () =>
      adaptedVariants.tableData.map((table) => {
        return table?.map((section) => {
          return {
            ...section,
            values: section.values?.filter((item, i) => {
              return i >= from && i <= from + 3;
            }),
            deleteId: willBeHiddenVariant,
            subfields: section.subfields?.map((subField) => {
              return {
                ...subField,
                values: subField.values?.filter((item, i) => {
                  return i >= from && i <= from + 3;
                }),
                deleteId: willBeHiddenVariant,
              };
            }),
          };
        });
      }),
    [adaptedVariants.tableData, from, willBeHiddenVariant]
  );

  if (!financingVariants) {
    return null;
  }

  return (
    <Container>
      <FinancingVariantsContentHeader
        from={from}
        isRight={isRight}
        onChangeFrom={onChangeFrom}
        names={names}
        indexes={indexesNotHidden}
      />
      <FinancingVariantsContentTable variants={filteredVariants} />
    </Container>
  );
};

export { FinancingVariantsContent };
