import { FC, memo, useCallback, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  LoginResponse,
  useRequestVerificationCodeMutation,
  UserState,
  useSignInMutation,
} from '../../../../../../generated';
import { client } from '../../../../../../services/graphql/graphql';
import { device } from '../../../../../../style/theme';
import { ConfirmEmail } from '../../../../../auth/components/confirm-email';
import { ForgotPasswordForm } from '../../../../../auth/components/forms/forgot-password-form';
import { LoginFormWizard } from '../../../../../auth/components/forms/login-form-wizard';
import { ILoginForm } from '../../../../../auth/interfaces';
import {
  login,
  setAnonymousUser,
  setUserId,
  setUserInput,
  setUsername,
  setUserState,
  toggleIsForgottenPasswordOpen,
  toggleIsRegisterModalOpen,
} from '../../../../../auth/redux/authSlice';
import { SecondaryButton } from '../../../../../common/components/ui/buttons';
import { GlobalLoader } from '../../../../../common/components/ui/loaders/global-loader';
import { useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { TimifyWidget } from '../../../../../timify/components/widget';
import { ITimifyParams } from '../../../../../timify/interfaces';
import { FORM_STEPS } from '../../../constants';
import { IValuationForm } from '../../../interface';
import {
  toggleLoginForm,
  toggleRegisterForm,
} from '../../../redux/valuationWizardSlice';
import { FormTitle } from '../../../../components/common/form-title';
import { VisibilityWrapper } from '../../../../components/common/visibility-wrapper';
import { MobileBack } from '../common/mobile-back';
import { Progress } from '../../../../components/common/progress';

interface IProps {
  timifyParams: ITimifyParams | null;
  setTimifyParams: React.Dispatch<React.SetStateAction<ITimifyParams | null>>;
  onSuccess: (formData: IValuationForm, ownerId: string) => void;
}

const LoaderContainer = styled.div<{ isiframe?: boolean }>`
  height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
  &&&& {
    > div {
      position: absolute;
    }
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Header = styled.div`
  display: block;
`;

const BackButton = styled(SecondaryButton)`
  margin-top: auto;
  align-self: flex-start;
`;

const FormWrapper = styled.div`
  @media ${device.tablet} {
    margin: 0 16px 0 16px;
    height: 100%;
  }
`;

const PersonalLoginContainerBase: FC<IProps> = ({
  timifyParams,
  setTimifyParams,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  const [confirmEmailSent, setConfirmEmailSent] = useState(false);
  const [errors, setErrors] = useState<null | unknown>(null);
  const [isLoading, setLoading] = useState(false);
  const [reservationSuccess, setReservationSuccess] = useState(false);
  const [inputPassword, setInputPassword] = useState('');
  const [temporaryUserCredentials, setTemporaryUserCredentials] = useState({
    username: '',
    accessToken: '',
    refreshToken: '',
    attributeName: 'email',
    firstName: '',
    lastName: '',
  });

  const userState = useAppSelector((state) => state.auth.userState);
  const isForgottenPasswordOpen = useAppSelector(
    (state) => state.auth.isForgottenPasswordOpen
  );
  const isConfirmedGuest = useAppSelector(
    (state) => state.auth.isConfirmedGuest
  );
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const isValuationFormLoading = useAppSelector(
    (state) => state.valuationWizard.isLoading
  );
  const userInput = useAppSelector((state) => state.valuationWizard.userInput);
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const title = isForgottenPasswordOpen
    ? t('modal.forgotten-password.title')
    : t('modal.login.title');

  // Mutations
  const [signIn, { data: signInData }] = useSignInMutation();
  const [requestConfirmationCode] = useRequestVerificationCodeMutation();

  const backToValuationForm = useCallback(() => {
    dispatch(toggleLoginForm(false));
    dispatch(toggleIsForgottenPasswordOpen(false));
    dispatch(toggleRegisterForm(true));
  }, [dispatch]);

  const onLoginSubmit: SubmitHandler<ILoginForm> = useCallback(
    async (formData) => {
      const { userAuthenticationKey, password } = formData;
      setInputPassword(password);

      if (userAuthenticationKey && password) {
        try {
          // Initiaite login to get the jwt token
          const signInResponse = await signIn({
            input: {
              userAuthenticationKey,
              password,
              requestAccessToken: true,
            },
          }).unwrap();

          if (signInResponse.signIn) {
            const { user, jwt } = signInResponse.signIn as LoginResponse;

            setTemporaryUserCredentials({
              username: user?.username,
              accessToken: jwt?.token ?? '',
              refreshToken: jwt?.refreshToken ?? '',
              attributeName: 'email',
              firstName: user?.name,
              lastName: user?.surname,
            });

            batch(() => {
              dispatch(setUserId(user?._id));
              dispatch(setUsername(user?.username));
              dispatch(setUserState(user?.userState));
            });
          }
        } catch (error: unknown) {
          setErrors(error);
        } finally {
          setLoading(false);
        }
      }
    },
    [dispatch, signIn]
  );

  useEffect(() => {
    if (signInData) {
      const loginResponse = signInData.signIn as LoginResponse;
      if (loginResponse?.jwt?.token) {
        client.setHeader(
          'Authorization',
          `Bearer ${loginResponse?.jwt?.token ?? ''}`
        );

        batch(() => {
          dispatch(setAnonymousUser(false));
          dispatch(login(loginResponse));
          dispatch(setUserState(loginResponse.user.userState));
          onSuccess(userInput as IValuationForm, loginResponse.user._id);
        });
      } else if (loginResponse.user && !loginResponse.jwt) {
        batch(() => {
          const {
            user: { name, surname, phone, email, termsAndConditions },
          } = loginResponse;
          dispatch(toggleRegisterForm(true));
          dispatch(toggleIsRegisterModalOpen(true));
          dispatch(setUserState(userState));
          dispatch(
            setUserInput({
              name,
              surname,
              phonePrefix: '',
              phone,
              email,
              password: inputPassword,
              confirmPassword: inputPassword,
              termsAndConditions,
            })
          );
        });
      }
    }
  }, [dispatch, inputPassword, onSuccess, signInData, userInput, userState]);

  useEffect(() => {
    const sendConfirmEmail = async () => {
      if (reservationSuccess && timifyParams) {
        setTimifyParams(null);
        if (temporaryUserCredentials?.username) {
          const confirmationCodeResponse = await requestConfirmationCode({
            input: { ...temporaryUserCredentials },
          }).unwrap();

          if (confirmationCodeResponse?.requestVerificationCode) {
            setConfirmEmailSent(true);
          }
        } else if (isConfirmedGuest) {
          setConfirmEmailSent(true);
        } else if (!isAnonymousUser) {
          setConfirmEmailSent(true);
        }
      }
    };

    sendConfirmEmail();
  }, [
    reservationSuccess,
    timifyParams,
    setTimifyParams,
    temporaryUserCredentials,
    requestConfirmationCode,
    isConfirmedGuest,
    isAnonymousUser,
    onSuccess,
    userInput,
  ]);

  return (
    <Container>
      {!isIframe && (
        <MobileBack
          title={t('landing-page.primary-button.online-valuation.label')}
        />
      )}
      {isLoading ||
        (isValuationFormLoading && (
          <LoaderContainer isiframe={isIframe}>
            <GlobalLoader />
          </LoaderContainer>
        ))}
      <VisibilityWrapper isVisible={!isLoading && !isValuationFormLoading}>
        {userState === UserState.Unauthenticated &&
          !timifyParams &&
          !confirmEmailSent && (
            <>
              <Header>
                <Progress activeBullet={FORM_STEPS} bullets={FORM_STEPS + 1} />
                <FormTitle text={title} />
              </Header>

              <FormWrapper>
                {isForgottenPasswordOpen ? (
                  <ForgotPasswordForm isWizardForm />
                ) : (
                  <LoginFormWizard onSubmit={onLoginSubmit} errors={errors} />
                )}
              </FormWrapper>
              {(!isMobileSize || isIframe) && (
                <BackButton
                  label={t('button.back')}
                  onClick={backToValuationForm}
                />
              )}
            </>
          )}
        {timifyParams && (
          <TimifyWidget
            {...timifyParams}
            setReservationSuccess={setReservationSuccess}
          />
        )}
        {confirmEmailSent && (
          <ConfirmEmail
            title={t('modal.register.confirm-email.personal-valuation.title')}
            textContent={t(
              'modal.register.confirm-email.personal-valuation.content'
            )}
          />
        )}
      </VisibilityWrapper>
    </Container>
  );
};

const PersonalLoginContainer = memo(PersonalLoginContainerBase);

export { PersonalLoginContainer };
