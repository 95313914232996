import styled from 'styled-components';

export const Container = styled.div`
  .modal-title-wrapper {
    align-items: center;
  }
  & h3 {
    opacity: 1;
    font-family: Source Serif Pro, sans-serif;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 32px;
    margin-left: 8px;
  }

  .pin-container {
    gap: 40px;
    display: flex;
  }

  .pin-input {
    margin: 0;
    cursor: pointer;
    width: 64px;
    height: 64px;
  }
`;

export const Text = styled.p`
  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 16px;
  margin-bottom: 8px;
`;
