import airportPin from 'assets/svg/airport.svg';
import highwayPin from 'assets/svg/autobahn.svg';
import busPin from 'assets/svg/bus.svg';
import similarPropertyPin from 'assets/svg/similar-property.svg';
import subwayPin from 'assets/svg/subway.svg';
import trainPin from 'assets/svg/train.svg';
import tramPin from 'assets/svg/tram.svg';
import { memo, useCallback } from 'react';
import { Marker } from 'react-map-gl';

import styled from 'styled-components';
import pinImg from '../../../assets/map-pin.svg';
import cateringPin from '../../../assets/svg/catering.svg';
import educationPin from '../../../assets/svg/education.svg';
import healthPin from '../../../assets/svg/health.svg';
import leisurePin from '../../../assets/svg/leisure.svg';
import shoppingPin from '../../../assets/svg/shopping.svg';
import { useAppDispatch } from '../../common/hooks';
import { scrollToProperty } from '../../property/redux/dynamicMapUtilsSlice';
import { IMapPin } from '../interfaces';
import { resetPopup, setPopup } from '../redux/mapSlice';

interface IProps {
  pin: IMapPin;
}

const StyledPin = styled.img`
  width: 23px;
  height: 32px;
  cursor: pointer;
  transform: translate(${-11}px, ${-32}px);
`;

const getPinImg = (type: string | undefined) => {
  switch (type) {
    case 'health':
      return healthPin;
    case 'shopping':
      return shoppingPin;
    case 'leisure':
      return leisurePin;
    case 'education':
      return educationPin;
    case 'catering':
      return cateringPin;
    case 'bus':
      return busPin;
    case 'tram':
      return tramPin;
    case 'subway':
      return subwayPin;
    case 'train':
      return trainPin;
    case 'airport':
      return airportPin;
    case 'highway_ramp':
      return highwayPin;
    case 'similarProperty':
      return similarPropertyPin;
    default:
      return pinImg;
  }
};

const PinBase = ({ pin }: IProps) => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    if (
      pin.index !== null &&
      pin.index !== undefined &&
      pin.category === 'property'
    ) {
      dispatch(scrollToProperty(pin.index));
    }
    return false;
  };

  const handleMouseEnter = useCallback(() => {
    dispatch(
      setPopup({
        latitude: pin.latitude,
        longitude: pin.longitude,
        info: pin.info,
      })
    );
  }, [dispatch, pin.latitude, pin.longitude, pin.info]);

  const handleMouseLeave = useCallback(() => {
    dispatch(resetPopup());
  }, [dispatch]);

  return (
    <Marker {...pin}>
      <StyledPin
        onClick={onClick}
        src={getPinImg(pin.marker)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        alt="pin"
      />
    </Marker>
  );
};
const Pin = memo(PinBase);

export { Pin };
