import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExposeInfoCard } from '../../info-card/info-card';

const PreparationCardBase = () => {
  const { t } = useTranslation();

  return (
    <ExposeInfoCard
      title={t('seller.expose-card.preparation-state.title')}
      description={t('seller.expose-card.preparation-state.description')}
    />
  );
};

const PreparationCard = memo(PreparationCardBase);

export { PreparationCard };
