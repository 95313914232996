import styled from 'styled-components';
import { device } from '../../../../style/theme';
import { Headline2 } from '../../../common/components/ui/typography';

export const TitleContainer = styled.div<{ left?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.left ? 'flex-start' : 'center')};
  margin-bottom: 24px;
  > i {
    margin-right: 20px;
    > svg {
      > path:first-of-type {
        stroke: ${(props) => props.theme.ctaBlue};
      }
    }
  }
  @media ${device.tablet} {
    margin-bottom: 16px;
    > i {
      margin-right: 8px;
      width: 32px;
      height: 32px;
    }
  }
`;

export const Title = styled(Headline2)``;

export const Container = styled.div<{ regional?: boolean }>`
  ${(props) => (props.regional ? 'margin-bottom: 88px;' : 'margin-top: 16px;')}
  &&& {
    .rc-collapse {
      border: none !important;
      background: transparent;

      .rc-collapse-item {
        border: none !important;
        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
        .faq-header {
          transition: border-radius 500ms ease, background-color 500ms ease;
          will-change: border-radius, background-color;
          padding: 14px 24px;
          border-radius: 3px;
          cursor: pointer;
          margin: 0;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: 1px;
          color: ${(props) => props.theme.blue};
          background-color: ${(props) => props.theme.faqHeader};

          .icon {
            margin-left: auto;
            order: 2;
            flex: 0 0 auto;
            path {
              stroke-width: 1.5px;
            }
          }
        }
        &.rc-collapse-item-active {
          .faq-header {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background-color: ${(props) => props.theme.faqActiveHeader};
          }
        }
        .rc-collapse-content {
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          background: ${(props) => props.theme.faqContent};
          overflow: hidden;
          color: ${(props) => props.theme.blue};
          padding: 0;
          margin: 0;
          .rc-collapse-content-box {
            margin: 0;
            padding: 24px;
            opacity: 0.6;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: 0.5px;
            color: ${(props) => props.theme.blue};
            @media ${device.tablet} {
              padding: 12px;
              font-size: 12px;
              line-height: 1.67;
            }
          }
        }
      }
    }
  }
`;

export const ContentText = styled.div`
  white-space: pre-wrap;

  * {
    font-family: 'Roboto' !important;
    font-size: 14px !important;
    color: #00305e !important;
    line-height: 24px !important;
    letter-spacing: 0.5px !important;
    background: transparent !important;
    background-color: transparent !important;
  }
`;
