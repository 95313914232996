import { Document, Font, Page, StyleSheet } from '@react-pdf/renderer';

import { MarketingGeneralStatistics } from '../../../generated';
import { Footer } from '../property-document/components';
import { Statistics } from './components/statistics';
import { OffersStatisticsItem, StatisticsItem } from '../../seller/interfaces';
import { HeadInfo } from './components/head-info';
import { Remarks } from './components/remarks';
import { Header } from './components/header';
import { OffersStatistics } from './components/offers-statistics';
import { AdvertisementViews } from './components/advertisement-views';

Font.register({
  family: 'SourceSerifPro',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/sourceserifpro/v11/neIQzD-0qpwxpaWvjeD0X88SAOeaiXM.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasasahSs.ttf',
      fontWeight: 600,
    },
    {
      src: 'https://fonts.gstatic.com/s/sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasc8bhSs.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasfcZhSs.ttf',
      fontWeight: 900,
    },
  ],
});

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Me5Q.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlvAw.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmYUtvAw.ttf',
      fontWeight: 900,
    },
  ],
});

interface IProps {
  isSimulation: boolean;
  onOfficeId?: string | null;
  externalId?: string | null;
  address: string;
  statisticsLastSync?: string | null;

  statistics?: MarketingGeneralStatistics | null;
  priceCurrency: string;
  showRequestedExposes?: boolean;
  showUnlockedExposes?: boolean;
  showViewingAppointments?: boolean;

  images: { [key: string]: string };

  offersStatistics?: OffersStatisticsItem[];
  simulationHighestBid?: string;
}

const styles = StyleSheet.create({
  page: {
    paddingTop: '8mm',
  },
});

// Create Document Component
const PropertyMarketingStatisticsDocument = ({
  isSimulation,

  onOfficeId,
  externalId,
  address,

  statistics,
  statisticsLastSync,
  priceCurrency,
  showRequestedExposes,
  showUnlockedExposes,
  showViewingAppointments,

  images,

  offersStatistics,
  simulationHighestBid,
}: IProps): JSX.Element => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header />
        <HeadInfo
          address={address}
          onOfficeId={onOfficeId}
          externalId={externalId}
          lastSync={statisticsLastSync}
        />
        <Statistics
          statistics={statistics}
          priceCurrency={priceCurrency}
          showRequestedExposes={showRequestedExposes}
          showUnlockedExposes={showUnlockedExposes}
          showViewingAppointments={showViewingAppointments}
          isSimulation={isSimulation}
        />
        {images['Expose-statistics'] && (
          <AdvertisementViews image={images['Expose-statistics']} />
        )}
        <Footer pageNumber={'1/2'} />
      </Page>
      <Page size="A4" style={styles.page}>
        <Header />
        <OffersStatistics
          isSimulation={isSimulation}
          offersStatistics={offersStatistics}
          simulationHighestBid={simulationHighestBid}
        />
        <Remarks />
        <Footer pageNumber={'2/2'} />
      </Page>
    </Document>
  );
};

export { PropertyMarketingStatisticsDocument };
