import { FC, memo, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ReactComponent as WalletIcon } from 'assets/streamline-light/money-payments-finance/money/money-wallet.svg';
import { ReactComponent as PencilIcon } from 'assets/streamline-light/interface-essential/edit/pencil.svg';
import { ReactComponent as CheckCircle } from 'assets/streamline-light/interface-essential/form-validation/check-circle-1.svg';
import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';

import {
  Container,
  Description,
  FinancingRequestButton,
  Header,
  SuccessMessageClose,
  SuccessMessageContainer,
  SuccessMessageTitle,
  Title,
} from './financing-calculation-styles';
import Icon from '../../../../../../common/components/ui/icon';
import { MainButton } from '../../../../../../common/components/ui/buttons';
import { FinancingCalculationContent } from './financing-calculation-content/financing-calculation-content';
import {
  FinancingProposition,
  FinancingVariantData,
  FinancingVariant,
  PersonalFinancingInput,
  RateType,
  VonPollPropertyData,
} from '../../../../../../../generated';
import { FinancingCertificateContainer } from './financing-certificate-container/financing-certificate-container';
import { AdviceContainer } from './advice-container/advice-container';
import { FeasibilityRating } from './feasibility-rating/feasibility-rating';
import { useFinancingCalculation } from '../../../../../hooks/calculation-tab/useFinancingCalculation';
import { useRequestFinancing } from '../../../../../hooks/useRequestFinancing';
import { useAppSelector } from '../../../../../../common/hooks';
import { FormButtons } from './form-buttons/form-buttons';
import { useDirtyForm } from '../../../../../../forms/form-financing/hooks/callback-hooks/calculation-form/useDirtyForm';
import { useIsMobileSize } from '../../../../../../common/hooks/useIsMobileSize';
import { toggleMobileEditWidget } from '../../../../../../forms/form-financing/redux/financingSlice';

interface IProps {
  lastSync: Date;
  isLoadingFinancing?: boolean;
  isCalculated?: boolean;
  isRequestedFinancing?: boolean;
  // isCertificate: boolean;
  isMortgageCertificate?: boolean;
  marketingParameters?: string | null;
  personalFinancing?: PersonalFinancingInput;
  financingVariantData?: FinancingVariantData;
  financingProposition?: FinancingProposition | null;
  financingVariants?: FinancingVariant[];
  vonPollProperty?: VonPollPropertyData | null;
}

const FinancingCalculationBase: FC<IProps> = ({
  lastSync,
  isLoadingFinancing,
  // isCertificate,
  isRequestedFinancing,
  isMortgageCertificate,
  marketingParameters,
  personalFinancing,
  financingVariantData,
  financingProposition,
  financingVariants,
  vonPollProperty,
}) => {
  const themeContext = useTheme();
  const isMobileSize = useIsMobileSize();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState(false);
  const prefix = 'financing-portal-page.tabs.calculator.data.';
  const { isDirty } = useDirtyForm();
  const { editWidget } = useAppSelector((state) => state.financing);
  const { calculateFinancingPropositions } = editWidget;
  const userInput = useAppSelector(
    (state) => state.financing.editWidget.userInputFinancingCalculation
  );
  const { isUpdatingOverdueFinancingPropositions } = useAppSelector(
    (state) => state.financing.editWidget
  );
  const maximalPurchasePrice = financingProposition?.maximalPurchasePrice;
  const financingPropositionNumber =
    Number(financingProposition?.propositions?.length) - 1;
  const { isLoading: isLoadingCalculation } = useFinancingCalculation();

  const isLoading =
    isLoadingFinancing ||
    isLoadingCalculation ||
    isUpdatingOverdueFinancingPropositions;

  const advices: string[] = [];
  financingProposition?.propositions?.[
    financingPropositionNumber
  ]?.optimizationMessages?.feasibility?.forEach((item) => {
    if (item.text) {
      advices.push(item.text);
    }
  });
  const financingBuildingBlock =
    financingProposition?.propositions?.[0]?.financingBuildingBlocks?.[0];
  if (
    (userInput?.rateType || financingVariantData?.rateType) ===
    RateType.RepaymentRate
      ? Number(
          userInput?.repaymentRate || financingVariantData?.repaymentRate
        ) < Number(financingBuildingBlock?.repaymentRateInPercent)
      : Number(userInput?.desiredRate || financingVariantData?.desiredRate) <
          Number(financingBuildingBlock?.rateMonthly) + 10 &&
        Number(userInput?.desiredRate || financingVariantData?.desiredRate) <
          Number(financingBuildingBlock?.rateMonthly) - 10
  ) {
    advices.push(
      'Die monatliche Rate wurde auf den minimal möglichen Wert angepasst.'
    );
  }
  financingProposition?.leadRating?.effortRating?.explanations?.forEach(
    (item) => {
      advices.push(item);
    }
  );

  let feasibilityRating =
    financingProposition?.leadRating?.feasibilityRating || 0;
  if (feasibilityRating < 70) {
    feasibilityRating += Math.round(feasibilityRating * 0.3);
  }
  const { requestFinancing } = useRequestFinancing({
    financingVariants,
    personalFinancing,
    feasibilityRating,
    marketingLink: marketingParameters || '',
    propertyId: vonPollProperty?.propertyId
      ? String(vonPollProperty?.propertyId)
      : undefined,
  });

  const requestFinancing$ = async (isCertificate: boolean) => {
    await requestFinancing(isCertificate);

    setSuccessMessage(true);
  };

  const onOpenMobileEditWidget = () => {
    dispatch(toggleMobileEditWidget(true));
    // financing mobile edit widget logic
    const editWidgetContainer = document.querySelector(
      '#modal-root'
    ) as HTMLButtonElement;
    editWidgetContainer.style.display = '';
    document.body.style.overflow = 'hidden';
  };

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage(false);
      }, 5000);
    }
  }, [successMessage]);

  return (
    <>
      <div>
        {isMortgageCertificate ? (
          <Container>
            <FinancingCertificateContainer
              mt={advices?.length > 0 ? 32 : 0}
              lastSync={lastSync}
              maximalPurchasePrice={maximalPurchasePrice}
            />

            {!isRequestedFinancing &&
              !(!!calculateFinancingPropositions && !isDirty) &&
              !isLoading &&
              (isMobileSize ? (
                <FinancingRequestButton
                  label={t(`${prefix}certificate.title`).toUpperCase()}
                  onClick={() => requestFinancing$(true)}
                  style={{ marginTop: '32px' }}
                  fluid
                  centered
                />
              ) : (
                <MainButton
                  label={t(`${prefix}certificate.title`).toUpperCase()}
                  onClick={() => requestFinancing$(true)}
                  style={{ marginTop: '32px' }}
                  fluid
                  centered
                />
              ))}

            {!!calculateFinancingPropositions && !isDirty ? (
              <FormButtons />
            ) : null}

            {isMobileSize && !isLoading && (
              <MainButton
                label={t(
                  `financing-portal-page.tabs.calculator.edit.title`
                ).toUpperCase()}
                onClick={onOpenMobileEditWidget}
                fluid
                style={{ marginTop: '16px' }}
                iconHeight={16}
                iconWidth={16}
                icon={PencilIcon}
                centered
              />
            )}

            <Description>{t(`${prefix}certificate.description`)}</Description>
          </Container>
        ) : (
          <>
            <Container>
              <Header mb={24}>
                <Icon icon={WalletIcon} color={themeContext.blue} />
                <Title>{t(`${prefix}title`)}</Title>
              </Header>

              <FeasibilityRating
                isLoading={isLoading}
                value={feasibilityRating}
              />
              {!isLoading &&
                advices.length > 0 &&
                advices?.map((item) => (
                  <AdviceContainer key={item} text={item} />
                ))}
              <FinancingCalculationContent
                isLoading={isLoading}
                financingData={financingVariantData}
                financingProposition={financingProposition}
                isDescribed={
                  !isRequestedFinancing &&
                  !(!!calculateFinancingPropositions && !isDirty) &&
                  !isLoading
                }
              />

              {!isRequestedFinancing &&
                !(!!calculateFinancingPropositions && !isDirty) &&
                !isLoading &&
                (isMobileSize ? (
                  <FinancingRequestButton
                    label={t(`${prefix}button`).toUpperCase()}
                    onClick={() => requestFinancing$(false)}
                    style={{ marginTop: '16px' }}
                    fluid
                    centered
                  />
                ) : (
                  <MainButton
                    label={t(`${prefix}button`).toUpperCase()}
                    onClick={() => requestFinancing$(false)}
                    style={{ marginTop: '16px' }}
                    fluid
                    centered
                  />
                ))}

              {!!calculateFinancingPropositions && !isDirty ? (
                <FormButtons />
              ) : null}

              {isMobileSize && !isLoading && (
                <MainButton
                  label={t(
                    `financing-portal-page.tabs.calculator.edit.title`
                  ).toUpperCase()}
                  onClick={onOpenMobileEditWidget}
                  fluid
                  iconHeight={16}
                  iconWidth={16}
                  style={{ marginTop: '16px' }}
                  icon={PencilIcon}
                  centered
                />
              )}
            </Container>
          </>
        )}
      </div>
      {successMessage && (
        <SuccessMessageContainer className="success-message">
          <div>
            <Icon
              icon={CheckCircle}
              color={themeContext.green}
              width={32}
              height={32}
            />
          </div>
          <SuccessMessageTitle
            content={t('financing-portal-page.tabs.calculator.success-message')}
          />
          <SuccessMessageClose
            onClick={() => {
              setSuccessMessage(false);
            }}
          >
            <Icon
              icon={Close}
              width={16}
              height={16}
              color={themeContext.blue}
            />
          </SuccessMessageClose>
        </SuccessMessageContainer>
      )}
    </>
  );
};

const FinancingCalculation = memo(FinancingCalculationBase);

export { FinancingCalculation };
