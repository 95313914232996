import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const Container = styled.div`
  padding-bottom: 88px;
  position: relative;
  @media ${device.tabletSlider} {
    padding-bottom: 32px;
    margin: 0 !important;
  }
`;

export const Title = styled.h3`
  margin-top: 88px;
  font-family: 'Source Serif Pro';
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: #002849;
  margin-bottom: 64px;
  @media ${device.tabletSlider} {
    font-size: 24px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`;

export const BlueTitle = styled.span`
  color: ${({ theme }) => theme.blue};
`;

export const Line = styled.div`
  z-index: 5;
  height: 1px;
  left: 50%;
  right: 50%;
  margin: 0 -50vw;
  position: absolute;
  bottom: 0;
  width: 100vw;
  background-color: ${({ theme }) => theme.borderColor};
`;
