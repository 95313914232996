import { ChangeEventHandler, FC } from 'react';
import styled from 'styled-components';
import { BoxText } from '../../ui/typography';

interface IProps {
  label: string;
  value: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const Label = styled.label<{ isChecked?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  opacity: ${(props) => (props.isChecked ? 1 : 0.6)};
  transition: opacity 100ms ease;
`;

const Radio = styled.span`
  position: relative;
  display: inline-flex;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: solid 1px rgba(0, 48, 94, 0.5);
  border-radius: 50%;
  cursor: pointer;
`;

const Input = styled.input`
  display: none;

  &:checked + ${Radio}::before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    width: 12px;
    height: 12px;
    background-color: ${(props) => props.theme.blue};
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Text = styled(BoxText)``;

export const RadioInput: FC<IProps> = ({
  label,
  value,
  checked,
  onChange,
  ...rest
}) => {
  return (
    <Label isChecked={checked} {...rest}>
      <Input checked={checked} onChange={onChange} value={value} type="radio" />
      <Radio />
      <Text content={label} />
    </Label>
  );
};
