import { FC, memo } from 'react';

import { ReactComponent as Calendar } from 'assets/streamline-light/interface-essential/date-calendar/calendar.svg';
import { ReactComponent as Route } from 'assets/streamline-light/maps-navigation/trip/trip-distance.svg';
import {
  MainButton,
  SecondaryButton,
} from '../../../../common/components/ui/buttons';

import {
  Container,
  HeaderContainer,
  ContentContainer,
  Headline,
  Label,
  TeamImage,
  RegionSlogan,
  TeamLeaderName,
  TeamLeaderPosition,
  TeamLeaderQualification,
  ButtonsContainer,
  Header,
} from './key-visual-styles';
import { toggleTimifyModal } from '../../../../timify/redux/timifySlice';
import { useAppDispatch } from '../../../../common/hooks';

interface IProps {
  bookingButtonLabel: string;
  heading: string;
  imageAlt: string;
  imageKey: string;
  imageUrl: string;
  personName: string;
  personPosition: string;
  personText: string;
  routeButtonLabel: string;
  routeButtonLink: string;
  subheading: string;
  title: string;
}

const KeyVisualBase: FC<IProps> = (props) => {
  const dispatch = useAppDispatch();

  const {
    bookingButtonLabel,
    heading,
    imageAlt,
    imageKey,
    imageUrl,
    personName,
    personPosition,
    personText,
    routeButtonLabel,
    routeButtonLink,
    subheading,
    title,
  } = props;

  const onClickRoute = () => {
    window.open(routeButtonLink, '_blank');
  };

  return (
    <Container>
      <HeaderContainer>
        <Label>{subheading}</Label>
        <Header>
          <Headline>{heading}</Headline>
        </Header>
      </HeaderContainer>
      <TeamImage src={imageUrl} key={imageKey} alt={imageAlt} />
      <ContentContainer>
        <RegionSlogan>{title}</RegionSlogan>
        <TeamLeaderName>{personName}</TeamLeaderName>
        <TeamLeaderPosition>{personPosition}</TeamLeaderPosition>
        <TeamLeaderQualification>{personText}</TeamLeaderQualification>
        <ButtonsContainer>
          <MainButton
            icon={Calendar}
            data-swiper-parallax="0"
            color="#ffffff !important"
            label={bookingButtonLabel}
            onClick={() => dispatch(toggleTimifyModal(true))}
          />
          {/* <SecondaryButton */}
          {/*   icon={Route} */}
          {/*   label={routeButtonLabel} */}
          {/*   onClick={onClickRoute} */}
          {/*   borderColor="#ffffff" */}
          {/*   color="#ffffff !important" */}
          {/*   backgroundColor="transparent" */}
          {/*   highlightColor="#ffffff" */}
          {/* /> */}
        </ButtonsContainer>
      </ContentContainer>
    </Container>
  );
};

const KeyVisual = memo(KeyVisualBase);

export { KeyVisual };
