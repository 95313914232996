import { Fragment, memo } from 'react';
import styled from 'styled-components';
import { FinancingOverviews } from '../../../../../../generated';
import { device } from '../../../../../../style/theme';

import { Token } from './token';

interface IProps {
  slot: string;
  activeIndex: number;
  sections: FinancingOverviews[];
  zIndex?: string;
  margin?: string;
  padding?: string;
  reduceToken?: boolean;
}

const Container = styled.div<{
  slidesCount: number;
  zIndex?: string;
  margin?: string;
  padding?: string;
}>`
  position: absolute;
  left: 0;
  top: 0;
  width: ${(props) => (props.slidesCount - 1) * 602 - 40}px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  height: 460px;
  padding: 48px 0 0 96px;
  @media ${device.tabletSlider} {
    padding: 16px 16px 12px 16px;
    margin: 0;
    height: 108px;
    width: ${(props) => `
      calc(${props.slidesCount - 1} * 100% - 56px);
    `};
  }
  ${(props) =>
    props.zIndex &&
    `
  z-index: ${props.zIndex}
  `}
`;

const TokensRail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  overflow: hidden;
  @media ${device.tabletSlider} {
    margin-bottom: 0;
  }
`;

const TokenSeparator = styled.div`
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
  flex: 1;
  margin: 0 12px;
`;

const ParallaxBase = ({
  activeIndex,
  sections,
  reduceToken,
  ...rest
}: IProps): JSX.Element => {
  return (
    <Container {...rest} slidesCount={sections.length}>
      <TokensRail>
        {sections.map((section, index) => (
          <Fragment key={section._id}>
            <Token
              icon={section.iconUrl ?? ''}
              isActive={activeIndex === index}
              reduceToken={reduceToken}
            />
            {index !== sections.length - 1 && <TokenSeparator />}
          </Fragment>
        ))}
      </TokensRail>
    </Container>
  );
};

const Parallax = memo(ParallaxBase);

export { Parallax };
