import styled from 'styled-components';
import { device } from '../../../../style/theme';
import { MainButton } from "../../../common/components/ui/buttons";
import {
  BoxTitle,
  Headline2,
  PhoneLink,
} from '../../../common/components/ui/typography';
import Icon from '../../../common/components/ui/icon';
import { Avatar } from './avatar';
import { EmailLink } from '../../../common/components/ui/typography/email-link';

export const Wrapper = styled.div<{ darkBlue?: boolean; }>`
  @media ${device.mobile} {
    ${props => props.darkBlue && `
      margin: 0 !important;
    `}
  }
`

export const Container = styled.div<{ marginBottom: number, darkBlue?: boolean }>`
  position: relative;
  padding: 32px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.white};
  display: flex;
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.09);
  margin-bottom: ${(props) => props.marginBottom}px;
  @media ${device.mobile} {
    margin-bottom: 0;
    flex-direction: column;
    padding: 24px 16px 16px;
    box-shadow: none;
    border-top: 1px solid #d9d9d9;
    ${props => props.darkBlue && `
      border: none;
      padding: 32px 0 0;
    `}
  }
`;

export const Content = styled.div<{ darkBlue?: boolean; }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;

  ${props => props.darkBlue && `
    width: 256px;
  `}}

  @media ${device.mobile} {
    margin-left: 0;
    margin-right: 0;
    align-items: center;
  }
`;

export const Title = styled(Headline2)<{ darkBlue?: boolean; }>`
  color: #fff;
  margin-bottom: 4px;

  ${props => props.darkBlue && `
    font-size: 20px;
    line-height: 1.6;
  `}

  @media ${device.mobile} {
    font-size: 20px;
    line-height: 1.4;

    ${props => props.darkBlue && `
      line-height: 1.6;
    `}
  }
`;

export const Phone = styled(BoxTitle)`
  color: #fff;
  letter-spacing: 1px;
  opacity: 0.8;
  @media ${device.mobile} {
    font-size: 10px;
    line-height: 1.6;
  }
`;

export const InfoRow = styled.div`
  width: 31%;
  margin-right: 32px;

  @media ${device.mobile} {
    width: auto;
    margin-right: 0;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const ContentRow = styled.div<{ darkBlue?: boolean }>`
  position: absolute;
  right: 32px;
  width: 63%;
  padding: 24px;
  display: flex;
  background-color: ${(props) => props.darkBlue ? props.theme.logoBlue : props.theme.blue};
  border-radius: 3px;

  @media ${device.mobile} {
    position: static;
    width: auto;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const IconWrapper = styled(Icon)`
  margin-bottom: 16px;

  path {
    stroke: ${(props) => props.theme.blue} !important;
  }

  path:first-of-type {
    stroke: ${(props) => props.theme.ctaBlue} !important;
  }
`;

export const QuestionTitle = styled(Title)`
  color: ${(props) => props.theme.blue};
`;

export const Description = styled.p`
  font-size: 16px;
  font-family: 'Source Serif Pro', serif;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
`;

export const AvatarWrapper = styled(Avatar)<{ darkBlue?: boolean; }>`
  margin-right: 24px;
  img {
    max-height: 100%;
  }

  @media ${device.mobile} {
    margin-right: 0;
    margin-bottom: 16px;
    max-width: 256px;
    height: auto !important;

    ${props => props.darkBlue && `
      width: 256px;
      height: 170px;
    `}
  }
`;

export const Position = styled.p<{ darkBlue?: boolean }>`
  margin-bottom: 4px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.white};
  ${props => props.darkBlue && `
    font-weight: bold;
  `}

  @media ${device.tablet} {
    font-weight: 900;
  }
`;

export const Qualification = styled.p`
  margin-bottom: 12px;
  font-family: Roboto, serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.white};
  opacity: 0.6;
`;

export const LegalModalToggler = styled.button`
  font-family: Roboto;
  opacity: 0.4;
  font-size: 10px;
  line-height: 1.6;
  margin-bottom: 12px;
  background: none;
  text-decoration: underline;
  outline: none;
  border: none;
  cursor: pointer;
  letter-spacing: 1px;
  color: #fff;
`;

export const PhoneLinkWrapper = styled(PhoneLink)`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
`;

export const EmailLinkWrapper = styled(EmailLink)`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
`;

export const ContactIconWrapper = styled(Icon)`
  margin-right: 8px;
`;

export const Contact = styled(BoxTitle)<{ darkBlue?: boolean; }>`
  color: #fff;
  letter-spacing: 1px;

  ${props => props.darkBlue && `
    font-weight: bold;
    letter-spacing: 0.5px !important;
    line-height: 1.33;
  `}

  @media ${device.mobile} {
    font-size: 10px;
    line-height: 1.6;
    ${props => props.darkBlue && `
      font-weight: bold;
      line-height: 1.33;
    `}
  }
`;

export const MainBtn = styled(MainButton)<{ darkBlue?: boolean }>`
  @media ${device.tablet} {
    ${props => props.darkBlue && `
      width: 100%;
      justify-content: center;
    `}
  }
`
