import styled from 'styled-components';
import {
  Headline2,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { device } from '../../../../style/theme';
import { MainButton } from '../../../common/components/ui/buttons';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';

export const Container = styled.div`
  height: 152px;
  position: relative;
  display: flex;
  justify-content: end;
  margin-bottom: 16px;
  border-radius: 3px;
  overflow: hidden;

  @media ${device.tablet} {
    margin: 0 16px;
    height: 100px !important;
  }
`;

export const Content = styled.div<{ hasCTA?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto 1fr;
  column-gap: 32px;
  row-gap: 8px;
  padding: 32px;
  grid-template-areas:
    'icon title ${(props) => (props.hasCTA ? 'cta' : '')}'
    'icon description ${(props) => (props.hasCTA ? 'cta' : '')}';
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: ${(props) =>
    `linear-gradient(to right, ${props.theme.gold} 48%, rgba(162, 133, 87, 0) 85%)`};

  @media ${device.tablet} {
    padding: 12px;
    row-gap: 2px;
    grid-template-areas:
      'title ${(props) => (props.hasCTA ? 'cta' : '')}'
      'description ${(props) => (props.hasCTA ? 'cta' : '')}';
    grid-template-columns: 1fr auto;
    z-index: 2;
    background-image: ${(props) =>
      `linear-gradient(to right, ${props.theme.gold} 55%, rgba(162, 133, 87, 0) 85%)`};
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr min-content;
    background-image: ${(props) =>
      `linear-gradient(to right, ${props.theme.gold} 48%, rgba(162, 133, 87, 0) 85%)`};
  }
`;

export const IconWrapper = styled.div`
  grid-area: icon;
`;

export const Title = styled(Headline2)`
  color: ${(props) => props.theme.white};
  grid-area: title;
  align-self: end;

  @media ${device.tablet} {
    font-size: 16px;
  }

  @media ${device.mobile} {
    font-size: 12px;
  }
`;

export const Description = styled(ParagraphText)`
  color: ${(props) => props.theme.white};
  grid-area: description;
  align-self: start;

  @media ${device.tablet} {
    font-size: 10px;
  }
`;

export const Image = styled.img`
  //width: 50%;
  height: 100%;
  object-fit: contain;

  @media ${device.tablet} {
    transform: translateX(0px);
  }

  @media ${device.mobile} {
    transform: translateX(86px);
  }
`;

export const CTAButton = styled(MainButton)`
  grid-area: cta;
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.blue};
  text-transform: uppercase;

  &:hover {
    background-color: ${(props) => props.theme.white};
    color: ${(props) => hexToRGB(props.theme.blue, 0.85)};
  }

  @media ${device.mobile} {
    font-size: 8px;
    padding: 8px 10px;
  }
`;
