import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { FieldPath } from 'react-hook-form';
import { FlyToInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import {
  changeMapViewport,
  nextStepAction,
  setMapSettings,
  setSelectedAddressAction,
  setSelectedCountryCode,
  setVonPollPropertyData,
  updateFormStateAction,
} from '../../forms/form-financing/redux/financingSlice';
import { useGetFinancing } from './useGetFinancing';
import { IFinancingForm } from '../../forms/form-financing/interface';
import { useGetFinancingVonPollPropertyDetails } from './useGetFinancingVonPollPropertyDetails';
import { defaultDebaunceState } from '../../forms/form-search-profile/components/forms/search-profile-form/steps/first-step/constants';
import { useLazyForwardGeocodingQuery } from '../../../services/mapbox/mapbox-api';
import {
  extractCorrectCityValue,
  getSuggestions,
} from '../../forms/utils/suggestions';
import { FINANCING_FORM_KEYS } from '../../forms/form-financing/constants';
import getCountry from '../../forms/utils/getCountry';
import { FinancingPropertyType, FinancingReason } from '../../../generated';

interface IProps {
  formMethods: UseFormReturn<IFinancingForm>;
}

const useCheckAndCreateFinancingFromSuggestedProperties = ({
  formMethods,
}: IProps) => {
  const { isFinancingOpen } = useAppSelector((state) => state.financing);
  const dispatch = useAppDispatch();
  const { propertyId, purchasePrice } =
    useAppSelector((state) => state.financing.suggestedProperties) || {};
  const { financingId } = useParams<{ financingId: string }>();
  const { financingVariant, financingData } = useGetFinancing({
    financingId,
  });
  const [
    geocodingTrigger,
    { mostAccurateGeocodingResult, isFetching: isMapboxFetching },
  ] = useLazyForwardGeocodingQuery({
    selectFromResult: ({ data, isFetching }) => ({
      mostAccurateGeocodingResult: data,
      isFetching,
    }),
  });
  // search params
  const { data: vonPollPropertyData } = useGetFinancingVonPollPropertyDetails();

  const financingVariantData = financingVariant?.financingVariantData;

  // suggested properties purchase price logic
  useEffect(() => {
    if (isFinancingOpen) {
      let formState = {};
      if (financingVariantData) {
        formState = {
          ...formState,
          reason: financingVariantData.reason,
          searchProgress: financingVariantData.searchProgress,
          propertyUse: financingVariantData.propertyUse,
        };
      }
      if (propertyId && vonPollPropertyData) {
        switch (vonPollPropertyData.propertyType) {
          case 'haus':
            formState = {
              ...formState,
              propertyType: FinancingPropertyType.HouseDetached,
            };
            break;
          case 'wohnung':
            formState = {
              ...formState,
              propertyType: FinancingPropertyType.Condominium,
            };
            break;
          case 'zinshaus_renditeobjekt':
            formState = {
              ...formState,
              propertyType: FinancingPropertyType.MultiFamilyHouse,
            };
            break;
          case 'grundstueck':
            formState = {
              ...formState,
              propertyType: FinancingPropertyType.PlotOnly,
            };
            break;
          default:
            formState = {
              ...formState,
              propertyType: FinancingPropertyType.Condominium,
            };
        }
        const { setValue } = formMethods;
        formState = {
          reason: FinancingReason.PurchaseOfExistingProperty,
          ...formState,
          purchasePrice: vonPollPropertyData.purchasePrice,
          buildingYear: Number(vonPollPropertyData.buildingYear) || undefined,
          livingArea: Number(vonPollPropertyData.livingArea) || undefined,
          placeName: vonPollPropertyData.postCode,
        };

        if (vonPollPropertyData.postCode) {
          geocodingTrigger({
            ...defaultDebaunceState,
            postCode: vonPollPropertyData.postCode,
          });
        }
        Object.keys(formState).forEach((item) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setValue(item as FieldPath<IFinancingForm>, formState[item]);
        });

        dispatch(updateFormStateAction(formState));
        dispatch(
          setVonPollPropertyData({ ...vonPollPropertyData, propertyId })
        );
        dispatch(nextStepAction(1));
      }
    }
  }, [purchasePrice, vonPollPropertyData?.buildingYear, isFinancingOpen]);

  useEffect(() => {
    if (mostAccurateGeocodingResult && isFinancingOpen) {
      const suggestions = getSuggestions(
        FINANCING_FORM_KEYS.POST_CODE,
        mostAccurateGeocodingResult ?? []
      );
      if (suggestions?.[0]) {
        const { setValue } = formMethods;
        const suggestion = suggestions[0];
        const {
          updatedCity,
          updatedPostCode,
          updatedLocality,
          updatedNeighborhood,
          updatedRegion,
        } = extractCorrectCityValue(suggestion);
        dispatch(setSelectedCountryCode(getCountry(suggestion)));

        const setValueType = setValue as (code: string, value: unknown) => void;
        setValueType(FINANCING_FORM_KEYS.POST_CODE, updatedPostCode);
        setValueType(FINANCING_FORM_KEYS.CITY, updatedCity);
        setValueType(
          FINANCING_FORM_KEYS.PLACE_NAME,
          suggestion.place_name_de || suggestion.place_name
        );
        setValueType(FINANCING_FORM_KEYS.LOCALITY, updatedLocality);
        setValueType(FINANCING_FORM_KEYS.NEIGHBORHOOD, updatedNeighborhood);
        setValueType(FINANCING_FORM_KEYS.REGION, updatedRegion);

        const selectedSuggestion = JSON.parse(JSON.stringify([suggestion]))[0];
        const pin = {
          longitude: Number(selectedSuggestion?.center?.[0]),
          latitude: Number(selectedSuggestion?.center?.[1]),
        };
        dispatch(setSelectedAddressAction(selectedSuggestion));
        dispatch(
          changeMapViewport({
            longitude: pin?.longitude ?? 0,
            latitude: pin?.latitude ?? 0,
            zoom: 15,
            transitionDuration: 'auto',
            transitionInterpolator: new FlyToInterpolator({
              speed: 1.8,
            }),
            transitionEasing: easeCubic,
          })
        );
        dispatch(setMapSettings({ isMapMarker: true }));
      }
    }
  }, [mostAccurateGeocodingResult, isFinancingOpen]);
};

export { useCheckAndCreateFinancingFromSuggestedProperties };
