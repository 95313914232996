import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Calendar } from 'assets/streamline-light/interface-essential/date-calendar/calendar.svg';

import {
  Headline,
  Title,
} from '../../../../components/fullscreen-modal/modal-form/modal-form-styles';
import { LoaderContainer, TableContainer } from './auth-container-styles';
import { TablePfExampleItem } from '../financing-containers/financing-info-container/form-details/table-personal-financing-example/table-pf-example-item';
import { WarningItem } from '../financing-containers/financing-info-container/form-details/table-personal-financing-example/warning-item';
import { useGetFinancingPropositionsAuth } from '../../../hooks/callback-hooks/useGetFinancingPropositionsAuth';
import { numberFormat } from '../../../../../financing/utils/numberFormat';
import { FinancingLoader } from '../../../../components/common/loader';

const MobilePersonalFinancingExample: FC = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetFinancingPropositionsAuth();
  const proposition = data?.[0]?.propositions?.[0];

  const fixedBorrowingRate = proposition?.shouldInterest;
  const effectiveInterestRate = proposition?.effectiveInterest;
  const rateMonthly = numberFormat(proposition?.totalRatePerMonth);

  return (
    <>
      <Headline>
        {t('financing-portal.form.info-side.key-data.headline')}
      </Headline>
      <Title>{t('financing-portal.form.info-side.key-data.title-2')}</Title>
      <TableContainer>
        {isLoading ? (
          <LoaderContainer>
            <FinancingLoader mb={0} title="financing.loader-label" />
          </LoaderContainer>
        ) : (
          <TablePfExampleItem
            years={5}
            icon={Calendar}
            fixedBorrowingRate={fixedBorrowingRate}
            effectiveInterestRate={effectiveInterestRate}
            monthlyRate={rateMonthly}
          />
        )}
        <WarningItem />
      </TableContainer>
    </>
  );
};

export { MobilePersonalFinancingExample };
