import styled from 'styled-components';
import { device } from '../../../../../../../style/theme';

export const Container = styled.div<{
  isHighlight?: boolean;
  isIframe?: boolean;
  isCentered?: boolean;
  mobileReverse?: boolean;
}>`
  position: ${({ isCentered }) => (isCentered ? 'static' : 'absolute')};
  top: 48px;
  right: 48px;
  width: 464px;
  height: 616px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ isHighlight, isIframe }) =>
    isIframe
      ? 'none'
      : isHighlight
      ? '0 8px 30px 0 rgba(78, 115, 245, 0.5)'
      : '0 8px 30px 0 rgba(0, 0, 0, 0.09)'};
  z-index: 1;
  border: ${({ isHighlight }) =>
    isHighlight ? '2px solid #4e73f5' : '2px solid #ffffff'};

  & h3 {
    font-size: 20px;
    text-align: center;
  }
  & h4 {
    text-align: center;
  }

  @media ${device.tablet} {
    ${({ mobileReverse }) => (mobileReverse ? `order: 2;` : '')}
    position: static;
    margin: 8px auto 0;
    max-width: 464px;
    width: 100%;
  }
`;
