import styled from 'styled-components';
import { hexToRGB } from '../../../utils/hex-to-rgb';
import Icon from '../../ui/icon';
import { IconButton } from '../../ui/buttons';

const Container = styled.div<{ animateWidth?: { from: string; to: string } }>`
  ${(props) =>
    props.animateWidth &&
    `transition: all 0.5s ease 0s;
    width: ${props.animateWidth.from};
    &:focus-within {
      width: ${props.animateWidth.to};
    }`}
  display: flex;
  align-items: center;
  min-width: 0;
  margin-top: 8px;
  position: relative;
`;

const InputField = styled.input`
  flex: 1;
  width: auto;
  height: 40px;
  padding: 12px 40px 12px 12px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.white};
  outline: none;
  border: 1px solid ${(props) => props.theme.ctaDisabledBackground};
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Edge */
  ::-ms-reveal {
    display: none !important;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
  &::placeholder {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  }
  &:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.borderFocus};
    background-color: ${(props) => props.theme.greyBackground};
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.white};
    border-color: ${(props) => props.theme.borderFocus};
    color: ${(props) => props.theme.blue};
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
    }
  }
  &:disabled {
    opacity: 0.45;
    border: none;
    cursor: default;
    pointer-events: none;
  }
  &:read-only {
    opacity: 0.45;
    border: none;
    cursor: default;
    pointer-events: none;
  }
`;

const SearchIcon = styled(Icon)<{ animate?: boolean }>`
  position: absolute;
  right: 12px;
  transition: opacity 0.3s, transform 0.3s;
  opacity: ${(props) => (props.animate ? 0 : 1)};
  transform: ${(props) =>
    props.animate ? 'rotate(180deg) scale(.5)' : 'unset'};
`;

const ClearSearchIcon = styled(Icon)<{ animate?: boolean }>`
  position: absolute;
  right: 12px;
  cursor: pointer;
  transition: opacity 0.3s, transform 0.3s;
  opacity: ${(props) => (props.animate ? 1 : 0)};
  transform: ${(props) =>
    props.animate ? 'rotate(0deg) scale(1)' : 'rotate(-180deg) scale(0.5)'};
`;

const ConfirmButton = styled(IconButton)<{ animate?: boolean }>`
  position: absolute;
  right: 12px;
  cursor: pointer;
  width: 24px;
  height: 24px;

  transition: opacity 0.2s;
  opacity: ${(props) => (props.animate ? 1 : 0)};

  & > i {
    width: 8px;
    height: 8px;

    & > svg {
      stroke-width: 2px;
    }
  }
`;

export { InputField, Container, SearchIcon, ClearSearchIcon, ConfirmButton };
