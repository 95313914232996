import { FC, memo, useMemo } from 'react';

import { Container, ProgressLine, Divider } from './progress-bar-styles';

interface IProps {
  activeStep: number;
  formSteps: number;
}

const ProgressBar: FC<IProps> = ({ activeStep, formSteps }) => {
  const progressWidth = useMemo(() => {
    if (activeStep === 10) {
      return 0;
    }

    return (activeStep / formSteps) * 100;
  }, [activeStep, formSteps]);

  return (
    <Container>
      <ProgressLine width={`${progressWidth}%`} />
      <Divider />
    </Container>
  );
};

export { ProgressBar };
