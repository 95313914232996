import { FC, memo } from 'react';
import { useParams } from 'react-router-dom';

import EmptyStatePlaceholder from './empty-state-placeholder';
import { Container, FinancingResultsContainer } from './calculation-tab-styles';
import { FinancingCalculation } from './financing-calculation/financing-calculation';
import { FinancingCalculationFormContainer } from './financing-calculation-form-container/financing-calculation-form-container';
import { useGetFinancing } from '../../../../hooks/useGetFinancing';
import { useAppSelector } from '../../../../../common/hooks';
import { InfoBanner } from './info-message/info-banner';
import { InfoMessage } from './info-message/info-message';
import { YourFinanceCertificateSuggestion } from './your-finance-certificate-suggestion/your-finance-certificate-suggestion';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { ModalMobileEditWidget } from './modals/modal-mobile-edit-widget/modal-mobile-edit-widget';

interface IProps {
  onSelectTab: (index: number) => void;
}

const CalculationTabBase: FC<IProps> = ({ onSelectTab }) => {
  const { financingId } = useParams<{ financingId: string }>();
  const isMobileSize = useIsMobileSize();
  const isEmptyState = financingId === undefined;
  const { calculateFinancingPropositions, calculateDebitRateCommitment } =
    useAppSelector((state) => state.financing.editWidget);
  const {
    isFetching,
    proposition: financingProposition,
    financingVariant,
    financingVariants,
    personalFinancing,
    marketingParameters,
    vonPollProperty,
    // isCertificate,
  } = useGetFinancing({
    financingId,
  });

  const financingVariantData = financingVariant?.financingVariantData;
  const isCertificateSuggestion =
    financingVariant?.financingSuggestions.isCertificateSuggestion;

  const calcFinancingProposition = calculateFinancingPropositions?.find(
    (item) => item.debitRateCommitment === calculateDebitRateCommitment
  );
  const proposition = calculateFinancingPropositions
    ? calcFinancingProposition
    : financingProposition;

  const lastSync = calculateFinancingPropositions
    ? new Date()
    : financingVariant?.propositionsLastSync;

  return (
    <Container>
      {
        //   financingData &&
        // proposition &&
        // personalFinancing &&
        // financingVariants &&
        // financingVariantData
        !isEmptyState ? (
          <>
            <InfoBanner />
            <InfoMessage />
            {isCertificateSuggestion && financingVariants && (
              <YourFinanceCertificateSuggestion
                handleSubmit={() => onSelectTab(1)}
                financingVariantsProp={financingVariants}
              />
            )}
            <FinancingResultsContainer>
              <FinancingCalculation
                lastSync={lastSync}
                isLoadingFinancing={isFetching}
                marketingParameters={marketingParameters}
                // isCertificate={isCertificate}
                isRequestedFinancing={
                  financingVariant?.financingSuggestions.isRequestedFinancing
                }
                personalFinancing={personalFinancing}
                financingVariantData={financingVariantData}
                financingProposition={proposition}
                financingVariants={financingVariants}
                vonPollProperty={vonPollProperty}
              />
              {isMobileSize ? (
                <ModalMobileEditWidget
                  personalFin={personalFinancing}
                  financingVariantData={financingVariantData}
                  financingProposition={proposition}
                />
              ) : (
                <FinancingCalculationFormContainer
                  personalFin={personalFinancing}
                  financingVariantData={financingVariantData}
                  financingProposition={proposition}
                />
              )}
            </FinancingResultsContainer>
          </>
        ) : (
          <EmptyStatePlaceholder />
        )
      }
    </Container>
  );
};

const CalculationTab = memo(CalculationTabBase);

export default CalculationTab;
