import { useEffect, useState } from 'react';
import { PropertyValuation } from '../../../generated';
import { useGetCHFRate } from '../../common/utils/convertCHF';
import useGetLocalization from '../../localization/get-localization';

const convertValuation = (
  propertyValuation: PropertyValuation,
  rate: number
) => {
  return {
    ...propertyValuation,
    valuation: {
      ...propertyValuation.valuation,
      salePrice: Math.trunc(
        (propertyValuation?.valuation?.salePrice || 0) * rate
      ),
      value: Math.trunc((propertyValuation?.valuation?.value || 0) * rate),
      salePriceRange: {
        ...propertyValuation.valuation.salePriceRange,
        lower: Math.trunc(
          (propertyValuation?.valuation?.salePriceRange?.lower || 0) * rate
        ),
        upper: Math.trunc(
          (propertyValuation?.valuation?.salePriceRange?.upper || 0) * rate
        ),
      },
      valueRange: {
        ...propertyValuation.valuation.valueRange,
        lower: Math.trunc(
          (propertyValuation?.valuation?.valueRange?.lower || 0) * rate
        ),
        upper: Math.trunc(
          (propertyValuation?.valuation?.valueRange?.upper || 0) * rate
        ),
      },
    },
  };
};

const useOverviewConverted = (
  propertyCurrentValuation?: PropertyValuation,
  propertyCurrentQuarterValuation?: PropertyValuation,
  propertyValuations?: PropertyValuation[]
) => {
  const lang = useGetLocalization();
  const rate = useGetCHFRate();

  const [newPropertyCurrentValuation, setNewPropertyCurrentValuation] =
    useState(propertyCurrentValuation);
  const [
    newPropertyCurrentQuarterValuation,
    setNewPropertyCurrentQuarterValuation,
  ] = useState(propertyCurrentQuarterValuation);
  const [newPropertyValuations, setNewPropertyValuations] =
    useState(propertyValuations);

  useEffect(() => {
    if (
      !propertyCurrentValuation ||
      !propertyCurrentQuarterValuation ||
      !propertyValuations
    ) {
      return;
    }

    (() => {
      if (lang === 'de-ch') {
        setNewPropertyCurrentValuation(
          convertValuation(propertyCurrentValuation, rate)
        );
        setNewPropertyCurrentQuarterValuation(
          convertValuation(propertyCurrentQuarterValuation, rate)
        );
        setNewPropertyValuations(
          propertyValuations.map((valuation) =>
            convertValuation(valuation, rate)
          )
        );
      } else {
        setNewPropertyCurrentValuation(propertyCurrentValuation);
        setNewPropertyCurrentQuarterValuation(propertyCurrentQuarterValuation);
        setNewPropertyValuations(propertyValuations);
      }
    })();
  }, [
    propertyCurrentValuation,
    propertyCurrentQuarterValuation,
    propertyValuations,
    lang,
    rate,
  ]);

  return {
    newPropertyCurrentValuation,
    newPropertyCurrentQuarterValuation,
    newPropertyValuations,
  };
};

export { useOverviewConverted };
