import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  FinancingLpSection,
  FinancingProposition,
  OurRepresentativeExamples,
  useReadOurRepresentativeExampleQuery,
} from '../../../../../generated';
import { Modal, ModalFooter } from '../../../../common/components/modal';
import { BorderButton } from '../../../../common/components/ui/buttons';
import Icon from '../../../../common/components/ui/icon';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { ReactComponent as Close } from '../../../../../assets/streamline-light/interface-essential/form-validation/close.svg';
import {
  Content,
  CTAButton,
  desktopWrapperStyle,
  IconContainer,
  mobileWrapperStyle,
  Row,
  RowValue,
  Subtitle,
  Table,
  TableRow,
  TablesGrid,
  TableTitle,
  Title,
  TitleMobile,
} from './our-representative-example-modal-styles';
import { numberFormat } from '../../../../financing/utils/numberFormat';
import { getTotalTermOfLoanUntilFullRepayment } from '../../../../financing/utils/getTotalTermOfLoanUntilFullRepayment';

interface IProps {
  isOpen: boolean;
  purchasePrice: number;
  equityCapital: number;
  europaceInterestRates?: FinancingProposition;
  onClose: () => void;
  ourRepresentativeSection: FinancingLpSection;
}

interface OurRepresentativeExampleData {
  data: {
    readOurRepresentativeExample: OurRepresentativeExamples;
  };
  isLoading: boolean;
}

const OurRepresentativeExampleModal: FC<IProps> = ({
  isOpen,
  purchasePrice,
  equityCapital,
  onClose,
  europaceInterestRates,
  ourRepresentativeSection: { recordIds },
}) => {
  const isMobileSize = useIsMobileSize();
  const themeContext = useContext(ThemeContext);
  const proposition = europaceInterestRates?.propositions?.[0];

  const { data, isLoading } =
    useReadOurRepresentativeExampleQuery<OurRepresentativeExampleData>({
      id: recordIds?.[0],
    });

  if (isLoading || !data || !data.readOurRepresentativeExample) return null;

  const {
    readOurRepresentativeExample: {
      title,
      expandedText,
      table1Title,
      table1Row1Label,
      table1Row2Label,
      table1Row3Label,
      table1Row4Label,
      table2Title,
      table2Row1Label,
      table2Row2Label,
      table2Row3Label,
      table2Row4Label,
      table2Row5Label,
      table2Row6Label,
      table3Title,
      table3Row1Label,
      table3Row2Label,
      table3Row3Label,
      table4Title,
      table4Row1Label,
      table4Row1Value,
      table4Row2Label,
      table4Row2Value,
      table4Row3Label,
      table4Row3Value,
      table4Row4Label,
      table4Row4Value,
      table4Row5Label,
      table4Row5Value,
      CTALabel,
    },
  } = data;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      wrapperStyles={isMobileSize ? mobileWrapperStyle : desktopWrapperStyle}
    >
      {isMobileSize && (
        <Row>
          <TitleMobile>{title}</TitleMobile>
          <IconContainer onClick={onClose}>
            <Icon
              icon={Close}
              width={16}
              height={16}
              color={themeContext.white}
            />
          </IconContainer>
        </Row>
      )}
      <Content>
        {!isMobileSize && <Title>{title}</Title>}
        <Subtitle>{expandedText}</Subtitle>
        <TablesGrid>
          <Table>
            <TableTitle>{table1Title}</TableTitle>
            <TableRow>
              {table1Row1Label}{' '}
              <RowValue>{numberFormat(purchasePrice)} €</RowValue>
            </TableRow>
            <TableRow>
              {table1Row2Label}{' '}
              <RowValue>{numberFormat(equityCapital)} €</RowValue>
            </TableRow>
            <TableRow>
              {table1Row3Label} <RowValue>100%</RowValue>
            </TableRow>
            <TableRow>
              {table1Row4Label}{' '}
              <RowValue>{numberFormat((purchasePrice / 1000) * 6)} €</RowValue>
            </TableRow>
          </Table>

          <Table>
            <TableTitle>{table2Title}</TableTitle>
            <TableRow>
              {table2Row1Label} <RowValue>10 Jahre</RowValue>
            </TableRow>
            <TableRow>
              {table2Row2Label} <RowValue>2,00% p.a</RowValue>
            </TableRow>
            <TableRow>
              {table2Row3Label}{' '}
              <RowValue>
                {numberFormat(proposition?.shouldInterest, true)}% p.a
              </RowValue>
            </TableRow>
            <TableRow>
              {table2Row4Label}{' '}
              <RowValue>
                {numberFormat(proposition?.effectiveInterest, true)}% p.a
              </RowValue>
            </TableRow>
            <TableRow>
              {table2Row5Label}{' '}
              <RowValue>
                {numberFormat(proposition?.totalRatePerMonth)} €
              </RowValue>
            </TableRow>
            <TableRow>
              {table2Row6Label}{' '}
              <RowValue>
                {numberFormat(
                  proposition?.financingBuildingBlocks?.[0]
                    ?.residualDebtAfterTheEndOfTheFixedInterestPeriod
                )}{' '}
                €
              </RowValue>
            </TableRow>
          </Table>

          <Table>
            <TableTitle>{table3Title}</TableTitle>
            <TableRow>
              {table3Row1Label}{' '}
              <RowValue>
                {getTotalTermOfLoanUntilFullRepayment(
                  String(
                    proposition?.financingBuildingBlocks?.[0]
                      ?.dateOfLastInstallment
                  )
                )}
              </RowValue>
            </TableRow>
            <TableRow>
              {table3Row2Label}{' '}
              <RowValue>
                {
                  proposition?.financingBuildingBlocks?.[0]
                    ?.numberOfInstallments
                }
              </RowValue>
            </TableRow>
            <TableRow>
              {table3Row3Label}{' '}
              <RowValue>
                {numberFormat(
                  proposition?.financingBuildingBlocks?.[0]?.costOverTotalTerm
                )}{' '}
                €
              </RowValue>
            </TableRow>
          </Table>

          <Table>
            <TableTitle>{table4Title}</TableTitle>
            <TableRow>
              {table4Row1Label} <RowValue>{table4Row1Value}</RowValue>
            </TableRow>
            <TableRow>
              {table4Row2Label} <RowValue>{table4Row2Value}</RowValue>
            </TableRow>
            <TableRow>
              {table4Row3Label} <RowValue>{table4Row3Value}</RowValue>
            </TableRow>
            <TableRow>
              {table4Row4Label} <RowValue>{table4Row4Value}</RowValue>
            </TableRow>
            <TableRow>
              {table4Row5Label} <RowValue>{table4Row5Value}</RowValue>
            </TableRow>
          </Table>
        </TablesGrid>
        {isMobileSize && <CTAButton label={CTALabel} onClick={onClose} />}
      </Content>
      {!isMobileSize && (
        <ModalFooter>
          <BorderButton onClick={onClose} label={CTALabel} />
        </ModalFooter>
      )}
    </Modal>
  );
};

export default OurRepresentativeExampleModal;
