import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.lightBlue};
  opacity: 1;
  background-color: ${({ theme }) => theme.greyBackground};
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 24px;

  & > div:first-child {
    height: 64px;
    width: 64px;
  }

  & > div:last-child {
    width: 100%;
    h3 {
      opacity: 1;
      color: ${({ theme }) => theme.blue};
      font-family: Source Serif Pro, sans-serif;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0.33px;
      text-align: left;
      line-height: 24px;
      margin-bottom: 4px;
    }
    p {
      opacity: 1;
      color: ${({ theme }) => theme.blue};
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0.5px;
      text-align: left;
      line-height: 16px;
    }
  }
`;

export const IdeaContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
