import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';
import styled from 'styled-components';
import LoaderGif from '../../../../assets/valuation-loader.gif';
import { Headline3 } from '../../../common/components/ui/typography';

const Container = styled.div<{ mb?: number; maxWidth?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  width: 100%;
  ${({ maxWidth }) =>
    maxWidth &&
    `max-width: ${maxWidth}; margin-left: auto; margin-right: auto;`}
  margin-bottom: ${({ mb }) => (isNumber(mb) ? mb : 56)}px;
`;

const ImageWrapper = styled.div`
  height: 280px;
  margin-bottom: 24px;
`;

const Loader = styled.img``;

const Title = styled(Headline3)`
  text-align: center;
`;

interface IProps {
  title?: string;
  maxWidth?: string;
  mb?: number;
}

const ValuationLoader = ({ title, mb, maxWidth }: IProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container mb={mb} maxWidth={maxWidth}>
      <ImageWrapper>
        <Loader src={LoaderGif} alt="Loading..." />
      </ImageWrapper>
      <Title content={t(title ?? 'valuation-wizard.loader-label')} />
    </Container>
  );
};

export { ValuationLoader, ValuationLoader as FinancingLoader };
