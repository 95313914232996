import styled, { css } from 'styled-components';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../style/theme';

export const Container = styled.div<{
  hasError?: boolean;
  isWrapper?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
  position: relative;
  ${(props) =>
    props.hasError &&
    `

  `}
  ${({ isWrapper, theme }) =>
    isWrapper &&
    `
    border: 1px solid ${theme.borderColor};
    padding: 12px;
  `}
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const TooltipContainer = styled.div`
  max-width: 316px;
  width: 100%;
  padding: 0;
  text-align: center;
`;

export const TooltipParagraph = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

export const LabelContainer = styled.div<{ isTooltipKeyLeftSide?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-right: ${({ isTooltipKeyLeftSide }) =>
    isTooltipKeyLeftSide ? 8 : 16}px;

  @media ${device.tablet} {
    padding-right: ${({ isTooltipKeyLeftSide }) =>
      isTooltipKeyLeftSide ? 4 : 16}px;
  }
`;

export const Label = styled.label<{
  styleGuideErr?: boolean;
  isOldDesign?: boolean;
  isLabelSaturated?: boolean;
}>`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ styleGuideErr, isOldDesign, theme, isLabelSaturated }) =>
    styleGuideErr
      ? hexToRGB(theme.red, 0.5)
      : hexToRGB(theme.blue, isLabelSaturated ? 1 : isOldDesign ? 0.5 : 0.7)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubLabel = styled(Label)`
  font-size: 10px;
`;

export const InputAdornment = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: right;
  position: absolute;
  right: 12px;
  top: 12px;
  opacity: 0.6;
  color: ${({ theme }) => theme.blue};
`;

export const Info = styled.div<{ tooltipWidth?: number }>`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  cursor: pointer;
  .icon {
    > svg,
    > svg > path {
      stroke: ${(props) => props.theme.blue};
    }
  }

  ${({ tooltipWidth }) =>
    tooltipWidth &&
    css`
      .tooltip {
        width: ${tooltipWidth}px;
      }
    `}

  @media ${device.tablet} {
    margin-right: 0;
  }
`;

export const Wrapper = styled.div<{ isColumn?: boolean }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  ${({ isColumn }) =>
    !isColumn &&
    css`
      gap: 8px;
    `}
  align-items: ${({ isColumn }) => (isColumn ? 'flex-start' : 'center')};
  order: 2;
  flex: 2.125;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};

  &:hover {
    & + label {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }

  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
  }
`;

export const InputWrapper = styled.div<{ isColumn?: boolean }>`
  position: relative;
  ${({ isColumn }) =>
    isColumn &&
    css`
      margin-top: 8px;
    `}
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const InputHide = styled.input`
  display: none;
`;

export const InputField = styled.input<{
  hasError?: boolean;
  isDisabled?: boolean;
  isDisabledWhite?: boolean;
  readOnly?: boolean;
  backgroundColor?: string;
  isValue?: boolean;
  isNoBorder?: boolean;
  isOldDesign?: boolean;
  isSaturatedBorder?: boolean;
  isPlaceholderCenter?: boolean;
  styleGuideErr?: boolean;
  isInputAdornment?: boolean;
}>`
  max-width: 280px;
  min-width: 280px;
  height: 40px;
  padding: 12px ${({ isInputAdornment }) => (isInputAdornment ? 40 : 12)}px 12px
    12px;
  border-radius: 3px;
  touch-action: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: ${({ isPlaceholderCenter }) =>
    isPlaceholderCenter ? 'center' : 'left'};

  ${(props) =>
    props.styleGuideErr
      ? `background: ${props.theme.redBackground} !important;`
      : ''}

  background-color: ${(props) =>
    props.hasError
      ? props.theme.redBackground
      : props.isOldDesign
      ? props.theme.greyBackground
      : props.backgroundColor ?? props.theme.white};
  outline: none;
  border: ${({ isNoBorder }) => (isNoBorder ? 0 : 1)}px solid
    ${({ theme, isValue, isOldDesign, isSaturatedBorder }) =>
      isOldDesign
        ? 'transparent'
        : isValue || isSaturatedBorder
        ? theme.grey
        : theme.borderColor};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px
      ${(props) =>
        props.hasError
          ? props.theme.redBackground
          : props.isOldDesign
          ? props.theme.greyBackground
          : props.backgroundColor ?? props.theme.white}
      inset !important;
  }

  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  ${(props) =>
    props.hasError &&
    `
      & + label{
        color: ${hexToRGB(props.theme.red, 0.5)};
      }
  `}
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:not([type='radio']):not([type='checkbox']) {
    -webkit-appearance: none;
    border-radius: 3px;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield !important;
  }

  /* clears the ‘X’ from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &::placeholder {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${(props) => hexToRGB(props.theme.blue, 0.6)};
  }
  &:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.borderFocus};
    & + label {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }
  &:active,
  &:focus {
    cursor: text;
    background-color: ${({ backgroundColor }) => backgroundColor || '#fff'};
    border-color: ${(props) => props.theme.borderFocus};
    color: ${(props) => props.theme.blue};
    & + label {
      color: ${(props) => props.theme.blue};
    }
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
    }
  }
  ${({ isDisabled, isDisabledWhite, theme }) =>
    (isDisabled || isDisabledWhite) &&
    css`
      pointer-events: none;
      opacity: ${isDisabledWhite ? 1 : 0.5};
      ${isDisabledWhite &&
      css`
        background-color: ${theme.white};
      `}
    `}

  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
  }
`;

export const ErrorContainer = styled.div`
  order: 3;
  margin-top: 4px;
  display: flex;
  width: 100%;
`;

export const Spacer = styled.div`
  flex: 1;
  //padding-right: 16px;

  @media ${device.tablet} {
    display: none;
  }
`;

export const ErrorMessage = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.red};
`;

export const InputUsefulLinkBox = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  order: 4;
  margin-top: 8px;
`;

export const InputUsefulLinkText = styled.p`
  a {
    opacity: 0.8;
    color: ${({ theme }) => theme.blue};
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 16px;
    text-decoration: underline;
  }
`;
