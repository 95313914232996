import { FC, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../../../common/components/modal';
import { toggleModalCompareVariants } from '../../../../forms/form-financing/redux/financingSlice';
import { useAppSelector } from '../../../../common/hooks';
import { CloseButton } from '../../../../common/components/modal/close-button';
import { variantsModalStyles } from '../../../constants';
import { Container, Header } from './compare-variants-modal-styles';
import { FinancingVariantsPeriod } from './financing-variants-period/financing-variants-period';
import { FinancingVariantsContent } from './financing-variants-content/financing-variants-content';

const CompareVariantsModal: FC = () => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [period, setPeriod] = useState('10');
  const isOpen = useAppSelector(
    (state) => state.financing.isCompareVariantsModalOpen
  );
  const onCloseClick = () => {
    dispatch(toggleModalCompareVariants(false));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseClick}
      wrapperStyles={variantsModalStyles}
    >
      <Container>
        <CloseButton
          handleClose={onCloseClick}
          position={'right'}
          color={themeContext.blue}
        />
        <Header>
          {t('financing-portal-page.variants-compare.modal.title')}
        </Header>
        <FinancingVariantsPeriod period={period} setPeriod={setPeriod} />
        <FinancingVariantsContent period={period} />
      </Container>
    </Modal>
  );
};

export { CompareVariantsModal };
