import { useCallback } from 'react';

import {
  GetFinancingPropositionsInput,
  useGetFinancingPropositionsMutation,
} from '../../../../../generated';

interface IProps {
  input: GetFinancingPropositionsInput;
}

const useGetFinancingPropositions = () => {
  const [getFinancingPropositionsMutation, { data, isLoading, error }] =
    useGetFinancingPropositionsMutation();

  const getFinancingPropositions = useCallback(
    async ({ input }: IProps) => {
      return getFinancingPropositionsMutation({
        input: {
          ...input,
        },
      }).unwrap();
    },
    [getFinancingPropositionsMutation]
  );

  return {
    getFinancingPropositions,
    data: data?.getFinancingPropositions,
    isLoading,
    error,
  };
};

export { useGetFinancingPropositions };
