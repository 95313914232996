import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import {
  MainButton,
  SecondaryButton,
} from '../../../../../../common/components/ui/buttons';
import {
  Container,
  FormButtonsWrapper,
} from './financing-variant-name-form-styles';
import { InputV2 } from '../../../../../components/common/input-v2/input-v2';

interface IProps {
  onSuggestName: () => void;
  onSubmit: () => void;
}

const FinancingVariantNameForm: FC<IProps> = ({ onSuggestName, onSubmit }) => {
  const { t } = useTranslation();
  const [isName, setName] = useState(false);
  const methods = useFormContext();

  return (
    <Container>
      <InputV2
        name="name"
        isAutoFocus
        label="Name der Variante"
        placeholder="meine bevorzugte Finanzierungsvariante"
        defaultValue=""
        handleChangeProp={(value) => {
          setName(!!value);
        }}
        isOldDesign
      />
      <FormButtonsWrapper>
        <SecondaryButton
          label={t('automatisch benennen')}
          centered
          onClick={() => {
            setName(true);
            onSuggestName();
          }}
        />
        <MainButton
          label={t('speichern')}
          centered
          isDisabled={!isName}
          onClick={methods.handleSubmit(onSubmit)}
        />
      </FormButtonsWrapper>
    </Container>
  );
};

export { FinancingVariantNameForm };
