import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Description,
  NextStepButton,
  Title,
} from './next-step-card-styles';
import { TooltipConfirmation } from '../../../../common/components/tooltip/TooltipConfirmation';

interface IProps {
  onSubmit: () => void;
  isNextStepDisabled?: boolean;
  isBlurred?: boolean;
  tooltipId: string;
}

const NextStepCardBase: FC<IProps> = ({
  onSubmit,
  isNextStepDisabled = false,
  isBlurred = false,
  tooltipId,
}) => {
  const { t } = useTranslation();

  return (
    <Container isBlurred={isBlurred}>
      <Title content={t('seller.next-step-card.title')} />
      <Description content={t('seller.next-step-card.description')} />
      <TooltipConfirmation
        headerText={t('seller.next-step-card.tooltip.title')}
        descriptionText={t('seller.next-step-card.tooltip.description')}
        backText={t('seller.next-step-card.tooltip.cancel')}
        acceptText={t('seller.next-step-card.tooltip.confirm')}
        id={tooltipId}
        onDelete={onSubmit}
        tooltipStyle={{ width: '360px', padding: '12px !important' }}
        tooltipActionsDirection={'row'}
        isDisabled={isNextStepDisabled}
      >
        <NextStepButton
          label={t('seller.next-step-card.button')}
          fluid
          isDisabled={isNextStepDisabled}
        />
      </TooltipConfirmation>
    </Container>
  );
};

const NextStepCard = memo(NextStepCardBase);

export { NextStepCard };
