import { useCallback, useEffect, useState } from 'react';
import { RegisterOptions } from 'react-hook-form';
import styled from 'styled-components';

import { Feature } from '../../../../services/mapbox/interfaces';
import { Input } from './input/input';

interface IProps {
  name: string;
  label: string;
  placeholder?: string;
  rules?: RegisterOptions;
  defaultValue?: unknown;
  type?: string;
  suggestions: Feature[];
  onSuggestionClick: (suggestion: Feature) => void;
  tooltipKey?: string;
  disabled?: boolean;
  readonly?: boolean;
}

const SuggestionsWrapper = styled.div`
  background: ${({ theme }) => theme.white};
  width: 100%;
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.ctaDisabledBackground};
  overflow: hidden;
  z-index: 2;
`;

const Suggestion = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.blue};
  cursor: pointer;
  transition: 0.12s ease;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  &:hover {
    background: ${({ theme }) => theme.greyBackground};
  }
`;

const InputWithSuggestions = ({
  label,
  name,
  placeholder,
  rules,
  defaultValue,
  type,
  suggestions,
  onSuggestionClick,
  tooltipKey,
  disabled,
  readonly,
}: IProps): JSX.Element => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isOnFocus, setIsOnFocus] = useState(false);

  useEffect(() => {
    if (isOnFocus) {
      setShowSuggestions(true);
      return;
    }
    setShowSuggestions(false);
  }, [isOnFocus]);

  const preventMovingWithTab = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.key === 'Tab' &&
        (document.activeElement as HTMLInputElement).name === name
      ) {
        event.preventDefault();
      }
    },
    [name]
  );

  useEffect(() => {
    window.addEventListener('keydown', preventMovingWithTab);

    return () => {
      window.removeEventListener('keydown', preventMovingWithTab);
    };
  }, [preventMovingWithTab]);

  const onSuggestionSelect = (suggestion: Feature) => {
    onSuggestionClick(suggestion);
    setIsOnFocus(false);
  };

  return (
    <Input
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      label={label}
      type={type ?? 'search'}
      rules={rules}
      autoComplete="off"
      isDisabled={disabled}
      isReadOnly={readonly}
      onFocus={() => setIsOnFocus(true)}
      onOutsideClick={() => {
        if ((document?.activeElement as HTMLInputElement)?.name === name)
          setIsOnFocus(false);
      }}
      tooltipKey={tooltipKey}
      inputWrapperChildren={
        suggestions.length > 0 &&
        showSuggestions && (
          // TODO
          <SuggestionsWrapper>
            {suggestions.map((suggestion) => {
              return (
                <Suggestion
                  key={suggestion.id}
                  onClick={() => onSuggestionSelect(suggestion)}
                >
                  {suggestion.place_name}
                </Suggestion>
              );
            })}
          </SuggestionsWrapper>
        )
      }
    />
  );
};

export { InputWithSuggestions };
