import styled from 'styled-components';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../style/theme';

export const Container = styled.div<{ hasError?: boolean; isIframe?: boolean }>`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
  margin-top: 12px;
  position: relative;
  ${(props) =>
    props.hasError &&
    `

  `}
  @media ${({ isIframe }) => (isIframe ? device.mobileIframe : device.mobile)} {
    flex-direction: column;
  }
`;

export const TooltipContainer = styled.div`
  max-width: 316px;
  width: 100%;
  padding: 0;
  text-align: center;
`;

export const TooltipParagraph = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

export const LabelWrapper = styled.div`
  order: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const Label = styled.label`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
  margin-bottom: 2px;
`;

export const SubLabel = styled.label`
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
`;

export const LabelRange = styled.label`
  font-family: 'Roboto';
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 6px;
`;

export const Info = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  .icon {
    > svg,
    > svg > path {
      stroke: ${(props) => props.theme.blue};
    }
  }
`;

export const InputAdornment = styled.span`
  opacity: 0.5;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: right;
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const InputContainer = styled.div<{
  isFullWidth?: boolean;
  isIframe?: boolean;
}>`
  position: relative;

  @media ${({ isIframe }) => (isIframe ? device.mobileIframe : device.mobile)} {
    ${({ isFullWidth }) => isFullWidth && 'width: 100%;'};
  }
`;

export const InputWrapper = styled.div<{ isIframe?: boolean }>`
  position: relative;
  order: 2;
  flex: 2.125;
  display: flex;
  justify-content: flex-end;

  &:hover {
    & + label {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }
  @media ${({ isIframe }) => (isIframe ? device.mobileIframe : device.mobile)} {
    width: 100%;
    justify-content: space-between;
    margin-top: 8px;
  }
`;

export const InputHide = styled.input`
  display: none;
`;

export const InputField = styled.input<{
  hasError?: boolean;
  isDisabled?: boolean;
  isIframe?: boolean;
  readOnly?: boolean;
  isFullWidth?: boolean;
  isPlaceholderCenter?: boolean;
}>`
  width: ${({ isFullWidth }) => (isFullWidth ? 228 : 106)}px;
  height: 40px;
  padding: 12px 40px 12px 12px;
  border-radius: 3px;

  text-align: ${({ isPlaceholderCenter }) =>
    isPlaceholderCenter ? 'center' : 'left'};

  background-color: ${(props) =>
    props.hasError ? props.theme.redBackground : props.theme.greyBackground};

  outline: none;
  border: 1px solid transparent;

  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  ${(props) =>
    props.hasError &&
    `
      & + label{
        color: ${hexToRGB(props.theme.red, 0.5)};
      }
  `}
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:not([type='radio']):not([type='checkbox']) {
    -webkit-appearance: none;
    border-radius: 3px;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield !important;
  }

  /* clears the ‘X’ from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &::placeholder {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  }
  &:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.borderFocus};
    background-color: ${(props) => props.theme.greyBackground};
    & + label {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }
  &:active,
  &:focus {
    cursor: text;
    background-color: #fff;
    border-color: ${(props) => props.theme.borderFocus};
    color: ${(props) => props.theme.blue};
    & + label {
      color: ${(props) => props.theme.blue};
    }
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
    }
  }
  ${({ isDisabled }) =>
    isDisabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}

  @media ${({ isIframe }) => (isIframe ? device.mobileIframe : device.mobile)} {
    width: 100%;
  }
`;

export const ErrorContainer = styled.div`
  order: 3;
  margin-top: 4px;
  display: flex;
  width: 100%;
`;

export const Spacer = styled.div`
  flex: 1;
  padding-right: 16px;
`;

export const ErrorMessage = styled.div`
  flex: 2.125;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.red};
`;
