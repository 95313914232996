import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const Title = styled.h2`
  font-family: 'Source Serif Pro';
  font-size: 36px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #00305e;
  text-align: center;
  margin-bottom: 36px;

  @media ${device.tablet} {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;

  @media ${device.tablet} {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
`;

export const Logo = styled.img<{ isBig?: boolean }>`
  width: 84px;
  object-fit: contain;

  ${({ isBig }) =>
    isBig &&
    `
    width: 160px;
  `}
`;
