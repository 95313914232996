import styled from 'styled-components';
import { device } from '../../../../style/theme';
import {
  Headline2,
  TitleSlogan,
} from '../../../common/components/ui/typography';

export const Container = styled.div`
  &&& {
    width: 100%;
    padding: 32px 80px 32px 32px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    color: ${(props) => props.theme.blue};
    @media ${device.tablet} {
      padding: 16px;
      max-width: unset;
    }
  }
`;

export const Header = styled(TitleSlogan)`
  opacity: 0.8;
  text-transform: uppercase;
`;

export const Title = styled(Headline2)`
  margin: 4px 0 12px;
  line-height: 1.34;
  @media ${device.tablet} {
    margin-bottom: 12px;
  }
`;

export const BulletsBox = styled.div`
  padding: 14px;
  border-radius: 3px;
  border: solid 1px #c9d5fc;
  background-color: #f2f5f7;
  display: inline-block;
`;

export const Bullet = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  position: relative;
  padding-left: 18px;

  &:not(:first-child) {
    margin-top: 4px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.blue};
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
`;

export const Text = styled.div`
  margin: 16px 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;

export const DateContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  @media ${device.tablet} {
    margin-bottom: 16px;
    display: block;
  }
`;

export const DateValue = styled(Text).attrs({ as: 'span' })`
  margin: 0;
  min-width: 67px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  padding-right: 8px;
`;

export const DateText = styled(Text)`
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.33;

  @media ${device.tablet} {
    display: inline;
  }
`;
