import { MouseEvent, CSSProperties, ReactNode } from 'react';
import { ReactComponent as BinSvg } from 'assets/streamline-light/interface-essential/delete/bin.svg';
import styled from 'styled-components';
import { ReactComponent as AddSvg } from '../../../../../assets/streamline-light/interface-essential/remove-add/add.svg';
import { device } from '../../../../../style/theme';

const AddAnother = styled.button`
  font-size: 12px;
  font-weight: 900;
  width: 100%;
  border-radius: 3px;
  border: 2px solid ${({ theme }) => theme.blue};
  color: ${({ theme }) => theme.blue};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  background: transparent;
  letter-spacing: 1px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const DeleteCurrent = styled.button<{
  noTopMargin?: boolean;
  smallMargin?: boolean;
}>`
  font-size: 12px;
  font-weight: 900;
  width: 100%;
  border-radius: 3px;
  border: solid 2px #d9d9d9;
  color: rgba(33, 37, 41, 0.5);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  letter-spacing: 1px;
  background: transparent;
  margin-bottom: 12px;
  ${(props) => !props.noTopMargin && 'margin-top: 24px;'}
  ${(props) =>
    props.smallMargin &&
    `
    margin-top: -12px;
    margin-bottom: 8px;
  `}
`;

const ButtonText = styled.span`
  margin-left: 8px;
  text-transform: uppercase;
`;

interface IProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  style?: CSSProperties;
  children: ReactNode;
  noTopMargin?: boolean;
  disabled?: boolean;
  smallMargin?: boolean;
}

export const AddAnotherButton = ({
  onClick,
  children,
  ...props
}: IProps): JSX.Element => {
  return (
    <AddAnother onClick={onClick} {...props}>
      <AddSvg width={16} height={16} />
      <ButtonText>{children}</ButtonText>
    </AddAnother>
  );
};

export const DeleteCurrentButton = ({
  onClick,
  children,
  ...props
}: IProps): JSX.Element => {
  return (
    <DeleteCurrent onClick={onClick} {...props}>
      <BinSvg width={16} height={16} />
      <ButtonText>{children}</ButtonText>
    </DeleteCurrent>
  );
};

export const Wrapper = styled.div<{ margin?: number }>`
  margin-top: ${(props) => props.margin ?? 24}px;

  @media ${device.tablet} {
    margin-top: 0;
  }
`;

export const Row = styled.div<{
  marginBottom?: number;
  templateColumns?: string;
}>`
  display: grid;
  gap: 12px;
  grid-template-columns: ${(props) => props.templateColumns ?? '1fr 1fr'};
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}px` : '12px'};
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;
