import styled from 'styled-components';
import { device } from '../../../../style/theme';
import { MainButton } from '../../../common/components/ui/buttons';
import { FormRow } from '../../../common/components/form/form-grid';
import {
  Headline1,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  @media ${device.tablet} {
    position: relative;
    height: 100%;
  }
`;

export const SubmitButton = styled(MainButton)`
  justify-content: center;
  @media ${device.tablet} {
    text-transform: uppercase;
  }
`;

export const LastFormRow = styled(FormRow)`
  @media ${device.tablet} {
    margin-top: auto;
  }
`;

export const Header = styled(Headline1)<{ color?: string }>`
  position: relative;
  color: ${(props) => (props.color ? props.color : props.theme.blue)};
  margin-bottom: 24px;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
  }
`;

export const Subtitle = styled(ParagraphText)`
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 16px 0 24px 0;
`;

export const Label = styled.label`
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
  flex: 1;
  max-width: 100%;
  margin-bottom: 24px;

  a {
    color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
  }
`;

export const SelectWrapper = styled.div`
  margin-top: 8px;

  & > div > div > div:first-child {
    display: flex;
    align-items: center;
    height: 40px;
  }

  & > div > div > div:nth-child(2) {
    width: 100%;

    div {
      display: flex;
      align-items: center;
      height: 30px;
      width: 100%;
    }
  }
`;

export const HiddenSubmitButton = styled.button`
  display: none;
`;
