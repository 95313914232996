import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Container } from './auth-container-styles';
import { LoginRegisterScreen } from '../../../../components/fullscreen-modal/auth-container/login-register-screen/login-register-screen';
import { useAppSelector } from '../../../../../common/hooks';
import { ForgotPasswordContainer } from '../../../../components/fullscreen-modal/auth-container/forgot-password-container/forgot-password-container';
import {
  toggleLoginForm,
  toggleRegisterForm,
} from '../../../redux/financingSlice';
import { FinanceRegisterContainer } from './register-container/finance-register-container';
import { FinanceLoginContainer } from './login-container/finance-login-container';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { MobilePersonalFinancingExample } from './mobile-personal-financing-example';
import { FinancingPersonalFormContainer } from './financing-personal-form-container/financing-personal-form-container';
import { useFinancingFormInfo } from '../../../hooks/useFinancingFormInfo';
import { FinancingInfoCertificateContainer } from '../financing-containers/financing-info-certificate-container/financing-info-certificate-container';
import {
  UserIcon,
  UserIconContainer,
} from '../../../../components/fullscreen-modal/auth-container/login-register-screen/login-register-screen-styles';
import loginUserIcon from '../../../../../../assets/images/icons/financing-form/auth/login-user.png';
import { Title } from '../../../../components/fullscreen-modal/auth-container/styles';

const information = [
  'financing-portal.form.auth-screen.information.first',
  'financing-portal.form.auth-screen.information.second',
  'financing-portal.form.auth-screen.information.third',
  'financing-portal.form.auth-screen.information.fourth',
];

const AuthContainer: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const { isFinancingCertificate } = useFinancingFormInfo();

  const {
    isRegisterFormOpen,
    isLoginFormOpen,
    isForgotPassword,
    isPersonalFinancing,
  } = useAppSelector((state) => state.financing);

  const onOpenLogin = useCallback(() => {
    dispatch(toggleLoginForm(true));
  }, [dispatch]);
  const onOpenRegister = useCallback(() => {
    dispatch(toggleRegisterForm(true));
  }, [dispatch]);

  return (
    <Container>
      {!isRegisterFormOpen && !isLoginFormOpen && !isForgotPassword && (
        <LoginRegisterScreen
          hideInfo={isFinancingCertificate && isMobileSize}
          isShowTabletExpertImg={false}
          isHideBlueCircle
          registerButtonLabel="financing-portal.form.auth-screen.register-button"
          loginButtonLabel="financing-portal.form.auth-screen.login-button"
          infoTitle="financing.form.redesign.your-benefits.title"
          information={information}
          onOpenLogin={onOpenLogin}
          onOpenRegister={onOpenRegister}
        >
          {isMobileSize &&
            (isFinancingCertificate ? (
              <>
                <UserIconContainer>
                  <UserIcon src={loginUserIcon} alt="user icon" />
                </UserIconContainer>
                <Title>
                  {t('property-valuation.form.redesign.tenth-step.title')}
                </Title>
                <FinancingInfoCertificateContainer />
              </>
            ) : (
              <MobilePersonalFinancingExample />
            ))}
        </LoginRegisterScreen>
      )}
      {isRegisterFormOpen && (
        <FinanceRegisterContainer
          isFinancingCertificate={isFinancingCertificate}
        />
      )}
      {isPersonalFinancing && <FinancingPersonalFormContainer />}
      {isLoginFormOpen && <FinanceLoginContainer />}
      {isForgotPassword && <ForgotPasswordContainer />}
    </Container>
  );
};

export { AuthContainer };
