import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { getInEur, parseFloatWithoutCurrency } from '../../utils/currency';
import { styles } from './PropertyPDF-styles';
import Logo from './assets/logo.png';
import Area from './assets/details-icons/area.png';
import Bathrooms from './assets/details-icons/bathrooms.png';
import Bedrooms from './assets/details-icons/bedrooms.png';
import ConstructionYear from './assets/details-icons/constructionYear.png';
import Floor from './assets/details-icons/floor.png';
import Garages from './assets/details-icons/garages.png';
import Modrenization from './assets/details-icons/modernization.png';
import Parkings from './assets/details-icons/parkings.png';
import Rooms from './assets/details-icons/rooms.png';
import Type from './assets/details-icons/type.png';
import Phone from './assets/phone.png';
import Pin from './assets/pin.png';
import Envelope from './assets/envelope.png';
import { PropertyPDFProps } from './interfaces';

Font.register({
  family: 'Source Serif Pro',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/sourceserifpro/v11/neIQzD-0qpwxpaWvjeD0X88SAOeaiXM.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasasahSs.ttf',
      fontWeight: 600,
    },
    {
      src: 'https://fonts.gstatic.com/s/sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasc8bhSs.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasfcZhSs.ttf',
      fontWeight: 900,
    },
  ],
});

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Me5Q.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlvAw.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmYUtvAw.ttf',
      fontWeight: 900,
    },
  ],
});

const PropertyPDF = ({
  propertyData,
  purchaseIntentData,
}: PropertyPDFProps) => {
  const { t } = useTranslation();

  return (
    <Document>
      <Page>
        <View style={styles.container}>
          <View style={styles.headerOuterContainer}>
            <View style={styles.header}>
              <View style={styles.logoContainer}>
                <Image style={styles.logo} src={Logo} />
              </View>
              <Text style={styles.headerTitle}>
                {t('purchase-intent.pdf.title')}
              </Text>
            </View>
          </View>

          <View style={styles.innerContainer}>
            <Text style={styles.propertyTitle}>{propertyData.title}</Text>

            <View style={styles.additionalInfoContainer}>
              <View>
                <Text style={styles.street}>{propertyData.streetAddress}</Text>
                <Text style={styles.street}>{propertyData.cityAddress}</Text>
              </View>
              <View style={styles.infoBox}>
                <View style={styles.idBox}>
                  <Text style={styles.idText}>
                    {t('purchase-intent.pdf.id')}
                  </Text>
                  <Text style={styles.hashText}>
                    {' '}
                    # {propertyData.propertyId}
                  </Text>
                </View>
                <View style={styles.feeBox}>
                  {/* <Text style={styles.feeText}>{t('purchase-intent.pdf.provision')}</Text> */}
                  <Text style={styles.feeValue}> {propertyData.provision}</Text>
                </View>
              </View>
            </View>
            <Image style={styles.coverPhoto} src={propertyData.image} />

            <View style={styles.detailsContainer}>
              <View style={styles.details}>
                <View style={styles.detailItemsRow}>
                  <View style={styles.detailItem}>
                    <View style={styles.detailItemBox}>
                      <Image style={styles.itemIcon} src={Type} />
                      <Text style={styles.itemValue}>{propertyData.type}</Text>
                    </View>
                    <Text style={styles.itemKey}>
                      {t('purchase-intent.pdf.type')}
                    </Text>
                  </View>
                  <View style={styles.detailItem}>
                    <View style={styles.detailItemBox}>
                      <Image style={styles.itemIcon} src={ConstructionYear} />
                      <Text style={styles.itemValue}>
                        {propertyData.buildingYear}
                      </Text>
                    </View>
                    <Text style={styles.itemKey}>
                      {t('purchase-intent.pdf.constructionYear')}
                    </Text>
                  </View>
                  <View style={styles.detailItem}>
                    <View style={styles.detailItemBox}>
                      <Image style={styles.itemIcon} src={Modrenization} />
                      <Text style={styles.itemValue}>
                        {propertyData.modernizationYear}
                      </Text>
                    </View>
                    <Text style={styles.itemKey}>
                      {t('purchase-intent.pdf.modernization')}
                    </Text>
                  </View>
                  <View style={styles.lastDetail}>
                    <View style={styles.detailItemBox}>
                      <Image style={styles.itemIcon} src={Area} />
                      <Text style={styles.itemValue}>
                        {t('approx')} {propertyData.area}m²
                      </Text>
                    </View>
                    <Text style={styles.itemKey}>
                      {t('purchase-intent.pdf.area')}
                    </Text>
                  </View>
                </View>

                <View style={styles.detailItemsRow}>
                  <View style={styles.detailItem}>
                    <View style={styles.detailItemBox}>
                      <Image style={styles.itemIcon} src={Floor} />
                      <Text style={styles.itemValue}>{propertyData.floor}</Text>
                    </View>
                    <Text style={styles.itemKey}>
                      {t('purchase-intent.pdf.floor')}
                    </Text>
                  </View>
                  <View style={styles.detailItem}>
                    <View style={styles.detailItemBox}>
                      <Image style={styles.itemIcon} src={Rooms} />
                      <Text style={styles.itemValue}>
                        {propertyData.numberOfRooms}
                      </Text>
                    </View>
                    <Text style={styles.itemKey}>
                      {t('purchase-intent.pdf.rooms')}
                    </Text>
                  </View>
                  <View style={styles.detailItem}>
                    <View style={styles.detailItemBox}>
                      <Image style={styles.itemIcon} src={Bedrooms} />
                      <Text style={styles.itemValue}>
                        {propertyData.numberOfBedrooms}
                      </Text>
                    </View>
                    <Text style={styles.itemKey}>
                      {t('purchase-intent.pdf.bedrooms')}
                    </Text>
                  </View>
                  <View style={styles.lastDetail}>
                    <View style={styles.detailItemBox}>
                      <Image style={styles.itemIcon} src={Bathrooms} />
                      <Text style={styles.itemValue}>
                        {propertyData.numberOfBathrooms}
                      </Text>
                    </View>
                    <Text style={styles.itemKey}>
                      {t('purchase-intent.pdf.bathrooms')}
                    </Text>
                  </View>
                </View>

                <View style={styles.lastDetailItemsRow}>
                  <View style={styles.detailItem}>
                    <View style={styles.detailItemBox}>
                      <Image style={styles.itemIcon} src={Garages} />
                      <Text style={styles.itemValue}>
                        {propertyData.numberOfGarages}
                      </Text>
                    </View>
                    <Text style={styles.itemKey}>
                      {t('purchase-intent.pdf.garages')}
                    </Text>
                  </View>
                  <View style={styles.detailItem}>
                    <View style={styles.detailItemBox}>
                      <Image style={styles.itemIcon} src={Parkings} />
                      <Text style={styles.itemValue}>
                        {propertyData.numberOfParkingSpaces}
                      </Text>
                    </View>
                    <Text style={styles.itemKey}>
                      {t('purchase-intent.pdf.parkings')}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.pricesContainer}>
              <View style={styles.askedPriceBox}>
                <View style={styles.priceBox}>
                  <Text style={styles.fullPrice}>
                    {propertyData.askedPrice &&
                      getInEur(
                        Number.parseInt(`${propertyData.askedPrice}`, 10)
                      )}
                  </Text>
                  <Text style={styles.squarePrice}>
                    {propertyData.askedPrice &&
                      propertyData.area &&
                      parseFloatWithoutCurrency(
                        Number.parseFloat(
                          `${propertyData.askedPrice / propertyData.area}`
                        )
                      )}{' '}
                    €/m²
                  </Text>
                </View>
                <Text style={styles.desc}>
                  {t('purchase-intent.pdf.price')}
                </Text>
              </View>
              <View style={styles.offeredPriceBox}>
                <View style={styles.priceBox}>
                  <Text style={styles.fullPrice}>
                    {purchaseIntentData.price &&
                      getInEur(
                        Number.parseInt(`${purchaseIntentData.price}`, 10)
                      )}
                  </Text>
                  <Text style={styles.squarePrice}>
                    {purchaseIntentData.price &&
                      propertyData.area &&
                      parseFloatWithoutCurrency(
                        Number.parseFloat(
                          `${purchaseIntentData.price / propertyData.area}`
                        )
                      )}{' '}
                    €/m²
                  </Text>
                </View>
                <Text style={styles.desc}>
                  {t('purchase-intent.pdf.yourPrice')}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.footerContainer}>
          <View style={styles.footer}>
            <Text>1</Text>
          </View>
        </View>
      </Page>
      <Page>
        <View style={styles.headerOuterContainer}>
          <View style={styles.header}>
            <View style={styles.logoContainer}>
              <Image style={styles.logo} src={Logo} />
            </View>
            <Text style={styles.headerTitle}>
              {t('purchase-intent.pdf.title')}
            </Text>
          </View>
        </View>

        <View style={styles.innerContainer2}>
          <Text style={styles.subheading}>
            {t('purchase-intent.pdf.buyerDataTitle')}
          </Text>

          <View style={styles.cardContainer}>
            <View style={styles.firstCard}>
              <Text style={styles.cardTitle}>
                {purchaseIntentData.name} {purchaseIntentData.surname}
              </Text>
              <View style={styles.cardRow}>
                <View style={styles.smallIconContainer}>
                  <Image style={styles.pinIcon} src={Pin} />
                </View>
                <Text>
                  {purchaseIntentData.postalCode} {purchaseIntentData.city}
                </Text>
              </View>
              <View style={styles.cardRow}>
                <Image style={styles.cardIcon} src={Envelope} />
                <Text>{purchaseIntentData.email}</Text>
              </View>
              <View style={styles.cardRow}>
                <View style={styles.smallIconContainer}>
                  <Image style={styles.phoneIcon} src={Phone} />
                </View>
                <Text>{purchaseIntentData.phone}</Text>
              </View>
            </View>
            {purchaseIntentData.otherPersons &&
              purchaseIntentData.otherPersons[0] && (
                <View style={styles.secondCard}>
                  <Text style={styles.cardTitle}>
                    {purchaseIntentData.otherPersons[0].name}{' '}
                    {purchaseIntentData.otherPersons[0].surname}
                  </Text>
                  <View style={styles.cardRow}>
                    <View style={styles.smallIconContainer}>
                      <Image style={styles.pinIcon} src={Pin} />
                    </View>
                    <Text>
                      {purchaseIntentData.otherPersons[0].postalCode}{' '}
                      {purchaseIntentData.otherPersons[0].city}
                    </Text>
                  </View>
                  <View style={styles.cardRow}>
                    <Image style={styles.cardIcon} src={Envelope} />
                    <Text>{purchaseIntentData.otherPersons[0].email}</Text>
                  </View>
                  <View style={styles.cardRow}>
                    <View style={styles.smallIconContainer}>
                      <Image style={styles.phoneIcon} src={Phone} />
                    </View>
                    <Text>{purchaseIntentData.otherPersons[0].phone}</Text>
                  </View>
                </View>
              )}
          </View>

          {purchaseIntentData?.notes &&
          purchaseIntentData.notes?.trim()?.length > 0 ? (
            <>
              <Text style={styles.subheading}>
                {t('purchase-intent.pdf.notesTitle')}
              </Text>
              <View style={styles.remarksContainer}>
                {/* <Text style={styles.remarksMargin}> */}
                {/*  Pudding cake cotton candy pudding chocolate shortbread. Cheesecake bonbon jelly cookie cotton candy sugar plum. Sweet roll icing candy pie donut topping biscuit. Muffin muffin gummies cheesecake apple pie cake wafer. Candy canes pie fruitcake cake marshmallow liquorice ice cream lollipop bear claw. */}
                {/* </Text> */}
                <Text style={styles.remarks}>{purchaseIntentData.notes}</Text>
              </View>
            </>
          ) : null}

          {purchaseIntentData?.confirmFunding ? (
            <>
              <Text style={styles.subheading}>
                {t('purchase-intent.pdf.financeTitle')}
              </Text>
              <View style={styles.list}>
                {/* <View style={styles.listItem}> */}
                {/*  <View style={styles.dot} /> */}
                {/*  <Text>{t('purchase-intent.pdf.financeLiableText')}</Text> */}
                {/* </View> */}
                <View style={styles.listItem}>
                  <View style={styles.dot} />
                  <Text>{t('purchase-intent.pdf.financeInterestText')}</Text>
                </View>
              </View>
            </>
          ) : null}

          {/*  <Text style={styles.subheading}>{t('purchase-intent.pdf.notarTerminTitle')}</Text> */}
          {/*  <Text style={styles.plainText}>{t('purchase-intent.pdf.notarTerminDesc')}</Text> */}
          {/*  <View style={styles.cardContainer}> */}
          {/*    <View style={styles.firstCard}> */}
          {/*      <Text style={styles.cardTitle}>{t('purchase-intent.pdf.notarTerminFrom')}</Text> */}
          {/*      <View style={styles.cardRow}> */}
          {/*        <Image style={styles.cardIcon} src={Calendar} /> */}
          {/*        <Text>{fromDate}</Text> */}
          {/*      </View> */}
          {/*    </View> */}
          {/*    <View style={styles.secondCard}> */}
          {/*      <Text style={styles.cardTitle}>{t('purchase-intent.pdf.notarTerminTo')}</Text> */}
          {/*      <View style={styles.cardRow}> */}
          {/*        <Image style={styles.cardIcon} src={Calendar} /> */}
          {/*        <Text>{toDate}</Text> */}
          {/*      </View> */}
          {/*    </View> */}
          {/*  </View> */}

          {/*  <Text style={styles.plainText}>{t('purchase-intent.pdf.notarNameText')}</Text> */}
          {/*  <View style={styles.notarBox}> */}
          {/*    <Text style={styles.notarText}>{notarFullName}</Text> */}
          {/*  </View> */}
        </View>

        <View style={styles.footerContainer2}>
          <View style={styles.footer}>
            <Text>2</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PropertyPDF;
