import { FC } from 'react';
import styled from 'styled-components';

interface IProps {
  value: string | number;
  title: string;
  hasValue: boolean;
}

const Container = styled.div`
  padding: 12px;
  color: ${(props) => props.theme.blue};
  letter-spacing: 0.5px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background-color: ${(props) => props.theme.white};
`;

const Value = styled.p<{ hasValue: boolean }>`
  max-width: 88px;
  margin-bottom: 2px;
  font-family: 'Source Serif Pro', serif;
  font-size: ${(props) => (props.hasValue ? 20 : 16)}px;
  font-weight: 600;
  line-height: 1.2;
  opacity: ${(props) => (props.hasValue ? 1 : 0.5)};
`;

const Title = styled.p`
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  line-height: 1.67;
  opacity: 0.8;
  word-break: break-all;
`;

export const OfferItem: FC<IProps> = ({ value, title, hasValue, ...rest }) => {
  return (
    <Container {...rest}>
      <Value hasValue={hasValue}>{value}</Value>
      <Title>{title}</Title>
    </Container>
  );
};
