import { FC, memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { filter, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import {
  Container,
  UploadedFilesWrapper,
} from './uploaded-files-section-styles';
import { EmptyStatePlaceholder } from './empty-state-placeholder/empty-state-placeholder';
import { UploadedItem } from '../../../../common/components/form/input/input-file/uploaded-item/uploaded-item';
import {
  toggleFileAccessManagerOpen,
  setSelectedFileData,
} from '../../../redux/brokerViewSlice';
import { ReleaseStatus } from '../../../../../generated';
import {
  NotReleasedLabel,
  ReleasedForAllLabel,
  ReleasedForSomeUsersLabel,
} from '../../../../common/components/form/input/input-file/uploaded-item/uploaded-item-styles';
import { ClearFilterButton } from '../filter-button/filter-button-styles';

export enum UploadedBy {
  User = 'user',
  Broker = 'broker',
}

interface UploadedFile {
  id?: number;
  title: string;
  fileName: string;
  size: number;
  onOpenFile: () => void;
  uploadedBy: UploadedBy;
  url?: string;
  previewUrl?: string;
  releaseStatus?: ReleaseStatus | null;
}

export enum SectionType {
  Documents = 'Documents',
  Images = 'Images',
  VirtualTours = 'VirtualTours',
  DroneFootages = 'DroneFootages',
  PropertyPlans = 'PropertyPlans',
}

interface IProps {
  sectionType: SectionType;
  uploadedFiles?: UploadedFile[];
  searchInput?: string;
  isLocked?: boolean;
  isFilterActive?: boolean;
  onResetFilters: () => void;
}

const UploadedFilesSectionBase: FC<IProps> = ({
  sectionType,
  uploadedFiles,
  searchInput,
  isLocked,
  isFilterActive,
  onResetFilters,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const sectionName = useMemo(() => {
    switch (sectionType) {
      case SectionType.Documents:
        return t('upload-document.header');
      case SectionType.Images:
        return t('upload-image.header');
      case SectionType.VirtualTours:
        return t('upload-virtual-tour.header');
      case SectionType.DroneFootages:
        return t('upload-drone-footage');
      case SectionType.PropertyPlans:
        return t('upload-property-plan.header');
      default:
        return '';
    }
  }, [sectionType, t]);

  const filteredFiles = useMemo(() => {
    if (searchInput) {
      return filter(
        uploadedFiles,
        (file) =>
          file.fileName.toLowerCase().includes(searchInput.toLowerCase()) ||
          file.title.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    return uploadedFiles;
  }, [uploadedFiles, searchInput]);

  const getLabel = useCallback(
    (releaseStatus?: ReleaseStatus | null) => {
      switch (releaseStatus) {
        case ReleaseStatus.NotReleased:
          return (
            <NotReleasedLabel>
              {t(
                'buyer-portal.broker-view.expose-documents-page.file.not-released'
              )}
            </NotReleasedLabel>
          );
        case ReleaseStatus.ReleasedForSomeUsers:
          return (
            <ReleasedForSomeUsersLabel>
              {t(
                'buyer-portal.broker-view.expose-documents-page.file.released-for-some-users'
              )}
            </ReleasedForSomeUsersLabel>
          );
        case ReleaseStatus.ReleasedForAll:
          return (
            <ReleasedForAllLabel>
              {t(
                'buyer-portal.broker-view.expose-documents-page.file.released-for-all'
              )}
            </ReleasedForAllLabel>
          );
        default:
          return null;
      }
    },
    [t]
  );

  return (
    <Container
      onClick={(event) => event.stopPropagation()}
      role={'presentation'}
    >
      {!isEmpty(filteredFiles) ? (
        <>
          <UploadedFilesWrapper>
            {filteredFiles?.map(
              ({
                title,
                fileName,
                size,
                onOpenFile,
                url,
                previewUrl,
                id,
                releaseStatus,
              }) => {
                const Label = getLabel(releaseStatus);

                return (
                  <UploadedItem
                    key={fileName}
                    title={title}
                    fileName={fileName}
                    size={size}
                    onOpenFile={isLocked ? undefined : onOpenFile}
                    url={url}
                    previewImgUrl={previewUrl}
                    Label={Label}
                    // checkboxName={String(id)}
                    onOpenAccessManager={() => {
                      dispatch(toggleFileAccessManagerOpen(true));
                      dispatch(
                        setSelectedFileData({
                          name: fileName,
                          title,
                          id: id ?? 0,
                        })
                      );
                    }}
                  />
                );
              }
            )}
          </UploadedFilesWrapper>
        </>
      ) : (
        <EmptyStatePlaceholder
          text={t(
            isFilterActive
              ? 'buyer-portal.broker-view.expose-documents-page.filtered-documents.empty-state'
              : 'buyer-portal.broker-view.expose-documents-page.empty-state',
            {
              sectionName:
                sectionType === SectionType.VirtualTours
                  ? t('virtual-tour')
                  : sectionName,
            }
          )}
          CTA={
            isFilterActive ? (
              <ClearFilterButton
                label={t('marketing-dashboard.filter.clear')}
                onClick={onResetFilters}
                borderColor={themeContext.blue}
                color={themeContext.blue}
                type={'button'}
                style={{ height: '28px', marginLeft: '12px', fontSize: '11px' }}
              />
            ) : null
          }
        />
      )}
    </Container>
  );
};

const UploadedFilesSection = memo(UploadedFilesSectionBase);

export { UploadedFilesSection };
