import { FC, Fragment, memo } from 'react';
import { Redirect } from 'react-router-dom';
import {
  OverviewLpSection,
  OverviewLpSectionType,
  useReadOverviewLandingPageQuery,
} from '../../../../../generated';
import { OverviewLandingPageResponse } from '../../../interface';
import { GlobalLoader } from '../../../../common/components/ui/loaders/global-loader';
import { siteMap } from '../../../../../routes/site-map';
import useGetLocalization from '../../../../localization/get-localization';
import { OverviewSection } from '../overview-section/overview-section';
import { ContentWithPadding } from './sections-styles';
import { FunctionsSection } from '../functions-section/functions-section';
import { Testimonials } from '../testimonials/testimonials';
import { BenefitsSection } from '../benefits-section/benefits-section';
import { AppointmentsContactForms } from '../appointments-contact-form/appointments-contact-form';
import { FinancingOverviewSection } from '../financing-overview-section/financing-overview-section';
import { AppsSection } from '../apps-section/apps-section';

const SectionsBase: FC = () => {
  const lang = useGetLocalization();

  const { data: overviewLPData, isLoading: isLandingPageLoading } =
    useReadOverviewLandingPageQuery<OverviewLandingPageResponse>({
      locale: lang,
    });

  if (isLandingPageLoading) {
    return <GlobalLoader />;
  }

  if (!overviewLPData) {
    return <Redirect to={siteMap.OwnerLandingPage.path} />;
  }

  const {
    readOverviewLandingPage: { sections },
  } = overviewLPData;

  return (
    <ContentWithPadding>
      {sections?.map((section: OverviewLpSection) => (
        <Fragment key={section.orderIndex}>
          {(section.type === OverviewLpSectionType.OwnerBenefit ||
            section.type === OverviewLpSectionType.SellerBenefit ||
            section.type === OverviewLpSectionType.BuyerBenefit) && (
            <BenefitsSection section={section} />
          )}

          {(section.type === OverviewLpSectionType.OwnerOverview ||
            section.type === OverviewLpSectionType.SellerOverview ||
            section.type === OverviewLpSectionType.BuyerOverview) && (
            <OverviewSection section={section} />
          )}

          {/* first fixed */}
          {(section.type === OverviewLpSectionType.OwnerFunction ||
            section.type === OverviewLpSectionType.SellerFunction ||
            section.type === OverviewLpSectionType.BuyerFunction) && (
            <FunctionsSection section={section} />
          )}

          {section.type === OverviewLpSectionType.FinancingOverview && (
            <FinancingOverviewSection section={section} />
          )}

          {section.type === OverviewLpSectionType.Testimonial && (
            <Testimonials section={section} />
          )}

          {section.type === OverviewLpSectionType.App && (
            <AppsSection overviewLP section={section} />
          )}

          {section.type === OverviewLpSectionType.Appointment && (
            <AppointmentsContactForms section={section} />
          )}
        </Fragment>
      ))}
    </ContentWithPadding>
  );
};

const Sections = memo(SectionsBase);

export { Sections };
