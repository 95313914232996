import { ReactComponent as ArrowButtonLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { FC, Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';

import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { BenefitsSection } from '../../components/overview-page/benefits-section/benefits-section';
import { FunctionsSection } from '../../components/overview-page/functions-section/functions-section';
import {
  ContentWithPadding,
  IconWrapper,
  IFrameContainer,
} from './seller-landing-page-styles';

import {
  SellerLandingPage as SellerLP,
  SellerLpSection,
  SellerLpSectionType,
  useReadSellerLandingPageQuery,
  ValuationType,
} from '../../../../generated';
import { usePropertiesQuery } from '../../../../services/graphql/enhanced';
import { Card } from '../../../common/components/ui/card';
import { Hero } from '../../../common/components/ui/hero';
import { HeroSlider } from '../../../common/components/ui/hero-slider/hero-slider';
import Icon from '../../../common/components/ui/icon';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { Mapbox } from '../../../map/components/mapbox';
import { Europe } from '../../../map/constants/points-of-interest';
import { changeViewport } from '../../../map/redux/mapSlice';
import {
  openValuationWizardAction,
  resetValuationWizard,
  setValuationTypeAction,
} from '../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { Appointments } from '../../components/appointment/appointment';
import { LngRedirect } from '../../../localization/lng-redirect';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import { siteMap } from '../../../../routes/site-map';
import useGetLocalization from '../../../localization/get-localization';
import { sortByOrderIndex } from '../../utils/sort-by-order-index';
import { getAttributesFromMeta } from '../../utils/get-attributes-from-meta';
import { Benefits } from '../../components/benefit/benefits';
import { Faqs } from '../../components/faqs/faqs';
import { PropertyValuationWidgetContainer } from '../../../forms/form-property-valuation-wizard/components/containers/property-valuation-widget-container/property-valuation-widget-container';
import { useOpenEvaluationWizard } from '../../../forms/form-property-valuation-wizard/hooks/useOpenEvaluationWizard';
import { useOpenEvaluationWizardV2 } from '../../../forms/form-property-valuation-wizard-v2/hooks/callback-hooks/useOpenEvaluationWizardV2';
import { useGetEvaluationWidgetVersion } from '../../../forms/form-property-valuation-wizard-v2/hooks/useGetWidgetVersion';
import { ValuationFirstStepScreen } from '../../../forms/form-property-valuation-wizard-v2/components/containers/property-valuation-containers/valuation-first-step-screen/valuation-first-step-screen';

interface SellerLandingPageResponse {
  data: { readSellerLandingPage: SellerLP };
  isLoading: boolean;
}

interface IProps {
  openWidget?: string;
}

const SellerLandingPage: FC<IProps> = ({ openWidget }): JSX.Element => {
  const dispatch = useAppDispatch();
  const lngHPush = useLngHistoryPush();
  const lang = useGetLocalization();

  const isValuationWizardOpen = useAppSelector(
    (state) => state.valuationWizard.isValuationWizardOpen
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const selectedAddress = useAppSelector(
    (state) => state.valuationWizard.selectedAddress?.place_name
  );
  const activeStep = useAppSelector(
    (state) => state.valuationWizard.activeStep
  );
  const userId = useAppSelector((state) => state.auth.user?._id);
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const valuationType = useAppSelector(
    (state) => state.valuationWizard.valuationType
  );
  const isRedirect = useAppSelector(
    (state) => state.valuationWizard.isRedirect
  );
  const propertyDossier = useAppSelector(
    (state) => state.valuationWizard.propertyDossier
  );

  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  const { isRedesignVersion } = useGetEvaluationWidgetVersion();
  const { openEvaluationWizard: handleEvaluationWizardOpen } =
    useOpenEvaluationWizard({
      isLandingPage: true,
    });
  const { openEvaluationWizardV2 } = useOpenEvaluationWizardV2({
    isLandingPage: true,
  });

  const openEW = isRedesignVersion
    ? openEvaluationWizardV2
    : handleEvaluationWizardOpen;

  // Fetch landing page details
  const { data: sellerLPData, isLoading: isSellerLandingPageLoading } =
    useReadSellerLandingPageQuery<SellerLandingPageResponse>({
      locale: lang,
    });

  const {
    data: userProperties,
    isLoading: areUserPropertiesLoading,
    isFetching: areUserPropertiesFetching,
  } = usePropertiesQuery(
    { ownerId: userId },
    { skip: !userId || isAnonymousUser }
  );

  const closeEvaluationWizard = () => {
    dispatch(
      changeViewport({
        ...Europe,
        transitionDuration: 1000,
      })
    );
    if (propertyDossier?._id) {
      lngHPush(siteMap.SellerPropertiesPage.pathWithoutParams);
    }
    setTimeout(() => {
      batch(() => {
        dispatch(resetValuationWizard());
      });
    }, 500);
  };

  const handleClickBenefit = useCallback(
    (type: ValuationType) => {
      if (isIframe && document.body.requestFullscreen) {
        document.body.requestFullscreen();
      }
      openEW(type);
    },
    [isIframe, openEW]
  );

  // isRedirect logic
  useEffect(() => {
    if (isRedirect && !isValuationWizardOpen) {
      batch(() => {
        dispatch(openValuationWizardAction());
        dispatch(setValuationTypeAction(ValuationType.Online));
      });
    }
  }, [dispatch, isRedirect, isValuationWizardOpen]);

  // Redirect logic not in use right now
  // ------------------------------------
  // useEffect(() => {
  //   const lastSellerLandingPageSlug = localStorage.getItem(
  //     LAST_LANDING_PAGE_SLUG_KEY
  //   );
  //   if (lastSellerLandingPageSlug) {
  //     lngHPush(lastSellerLandingPageSlug);
  //   }
  // }, []);

  if (
    isSellerLandingPageLoading ||
    areUserPropertiesLoading ||
    areUserPropertiesFetching
  ) {
    return <GlobalLoader />;
  }

  // If the user has properties do not let him hit the landing page
  if (
    userProperties?.properties.length &&
    !isValuationWizardOpen &&
    !isIframe
  ) {
    return <LngRedirect to={siteMap.SellerPropertiesPage.path} />;
  }

  if (!sellerLPData) {
    return <Redirect to={siteMap.OwnerLandingPage.path} />;
  }

  const {
    readSellerLandingPage: {
      imageAlt,
      imageUrl,
      metaDescription,
      metaKeywords,
      sections,
      metaTitle,
      subtitle,
      text,
      title,
    },
  } = sellerLPData;

  const sortedSections = sortByOrderIndex(sections ?? []);

  const benefitSectionIndex = sortedSections.findIndex(
    (section) => section.type === SellerLpSectionType.Benefit
  );

  return (
    <>
      {isIframe && (
        <IFrameContainer>
          {isRedesignVersion && openWidget ? (
            <ValuationFirstStepScreen />
          ) : (
            <PropertyValuationWidgetContainer isOnLandingPage />
          )}
        </IFrameContainer>
      )}
      {!isIframe && (
        <>
          <Helmet>
            <title>{metaTitle || t('buyer-portal.title')}</title>
            <meta name="title" content={metaTitle ?? ''} />
            <meta name="description" content={metaDescription ?? ''} />
            <meta property="og:title" content={metaTitle ?? ''} />
            <meta property="og:description" content={metaDescription ?? ''} />
            {imageUrl && (
              <meta property="og:image" content={String(imageUrl)} />
            )}
            <meta property="twitter:title" content={metaTitle ?? ''} />
            <meta
              property="twitter:description"
              content={metaDescription ?? ''}
            />
            {imageUrl && (
              <meta property="twitter:image" content={String(imageUrl)} />
            )}
            {metaKeywords?.map((item) => {
              const metaObject = getAttributesFromMeta(item);
              return (
                <meta
                  key={metaObject.name}
                  name={metaObject.name}
                  content={metaObject.content}
                />
              );
            })}
            <link
              rel="canonical"
              href={`https://home.von-poll.com/${lang}${siteMap.SellerLandingPage.path}`}
            />
          </Helmet>
          <Hero
            {...(!isValuationWizardOpen && {
              imgSrc: imageUrl ?? '',
              imageAlt: imageAlt ?? '',
            })}
            isWithoutMargin
          >
            <>{!isMobileSize && <Mapbox isVisible={isValuationWizardOpen} />}</>
            <HeroSlider
              showAddressBar={activeStep > 0}
              address={selectedAddress}
              isDarkTheme
              isAuto
            >
              <Card
                header={title ?? ''}
                titleFirstLine={subtitle ?? ''}
                showAddressBar={activeStep > 0}
                content={text ?? ''}
              />
              <Card
                titleType="h2"
                header={t('landing-page.hero-slider.second-card.back-button')}
                titleFirstLine={
                  valuationType === ValuationType.Online
                    ? t(
                        'landing-page.hero-slider.second-card.online.title.first-line'
                      )
                    : t(
                        'landing-page.hero-slider.second-card.personal.title.first-line'
                      )
                }
                titleSecondLine={t(
                  'landing-page.hero-slider.second-card.title.second-line'
                )}
                content={
                  valuationType === ValuationType.Online
                    ? t('landing-page.hero-slider.second-card.online.content')
                    : t('landing-page.hero-slider.second-card.personal.content')
                }
                showAddressBar={activeStep > 0}
                cta={(c) => (
                  <IconWrapper onClick={closeEvaluationWizard}>
                    <Icon icon={ArrowButtonLeft} width={12} height={12} /> {c}
                  </IconWrapper>
                )}
              />
              <Card
                titleType="h3"
                header={t('landing-page.hero-slider.third-card.back-button')}
                titleFirstLine={
                  valuationType === ValuationType.Online
                    ? t(
                        'landing-page.hero-slider.third-card.online.title.first-line'
                      )
                    : t(
                        'landing-page.hero-slider.third-card.personal.title.first-line'
                      )
                }
                titleSecondLine={t(
                  'landing-page.hero-slider.third-card.title.second-line'
                )}
                content={
                  valuationType === ValuationType.Online
                    ? t('landing-page.hero-slider.third-card.online.content')
                    : t('landing-page.hero-slider.third-card.personal.content')
                }
                showAddressBar={activeStep > 0}
                cta={(c) => (
                  <IconWrapper onClick={closeEvaluationWizard}>
                    <Icon icon={ArrowButtonLeft} width={12} height={12} /> {c}
                  </IconWrapper>
                )}
              />
              <Card
                titleType="h4"
                header={t('landing-page.hero-slider.fourth-card.back-button')}
                titleFirstLine={
                  valuationType === ValuationType.Online
                    ? t(
                        'landing-page.hero-slider.fourth-card.online.title.first-line'
                      )
                    : t(
                        'landing-page.hero-slider.fourth-card.personal.title.first-line'
                      )
                }
                titleSecondLine={t(
                  'landing-page.hero-slider.fourth-card.title.second-line'
                )}
                content={
                  valuationType === ValuationType.Online
                    ? t('landing-page.hero-slider.fourth-card.online.content')
                    : t('landing-page.hero-slider.fourth-card.personal.content')
                }
                showAddressBar={activeStep > 0}
                cta={(c) => (
                  <IconWrapper onClick={closeEvaluationWizard}>
                    <Icon icon={ArrowButtonLeft} width={12} height={12} /> {c}
                  </IconWrapper>
                )}
              />
              <Card
                titleType="h5"
                header={t('landing-page.hero-slider.fifth-card.back-button')}
                titleFirstLine={
                  valuationType === ValuationType.Online
                    ? t(
                        'landing-page.hero-slider.fifth-card.online.title.first-line'
                      )
                    : t(
                        'landing-page.hero-slider.fifth-card.personal.title.first-line'
                      )
                }
                titleSecondLine={t(
                  'landing-page.hero-slider.fifth-card.title.second-line'
                )}
                content={
                  valuationType === ValuationType.Online
                    ? t('landing-page.hero-slider.fifth-card.online.content')
                    : t('landing-page.hero-slider.fifth-card.personal.content')
                }
                showAddressBar={activeStep > 0}
                cta={(c) => (
                  <IconWrapper onClick={closeEvaluationWizard}>
                    <Icon icon={ArrowButtonLeft} width={12} height={12} /> {c}
                  </IconWrapper>
                )}
              />
            </HeroSlider>
            {isRedesignVersion ? (
              <ValuationFirstStepScreen />
            ) : (
              <PropertyValuationWidgetContainer isOnLandingPage />
            )}
          </Hero>
          <ContentWithPadding benefitSectionIndex={benefitSectionIndex + 1}>
            {sortedSections?.map((section: SellerLpSection) => (
              <Fragment key={section.orderIndex}>
                {section.type === SellerLpSectionType.Benefit && (
                  <Benefits
                    section={section}
                    isSlider
                    handleEvaluationWizardOpen={handleClickBenefit}
                  />
                )}

                {section.type === SellerLpSectionType.Faq && (
                  <Faqs section={section} />
                )}

                {section.type === SellerLpSectionType.Appointment && (
                  <Appointments rpSection={section} />
                )}

                {section.type === SellerLpSectionType.SellerBenefit && (
                  <BenefitsSection section={section} />
                )}

                {section.type === SellerLpSectionType.SellerFunction && (
                  <FunctionsSection section={section} />
                )}
              </Fragment>
            ))}
          </ContentWithPadding>
        </>
      )}
    </>
  );
};

export { SellerLandingPage };
