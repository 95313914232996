import styled from 'styled-components';

export const VisibilityWrapper = styled.div<{
  isVisible: boolean;
  isiframe?: boolean;
}>`
  flex: 1;
  flex-direction: column;
  height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
  overflow-y: ${({ isiframe }) => (isiframe ? 'auto' : 'visible')};

  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`;
