import { memo, useEffect } from 'react';
import { batch } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { device } from '../../../../style/theme';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import {
  addMarkers,
  changeViewport,
  removeAllMarkersExceptHome,
  setSettings,
} from '../../../map/redux/mapSlice';
import {
  setFilters,
  filtersInitialState,
  clearExposesCache,
} from '../../redux/onlineExposesSlice';
import { useSearchProfileQuery } from '../../../../services/graphql/enhanced';
import { OnlineExposesContainer } from '../online-exposes/container';

const LeftColumn = styled.div`
  margin-right: 16px;
  padding: 36px 32px 32px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #fff;
  flex: 1.56;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    margin-right: 0;
    padding: 36px 16px 16px;
  }
`;

const Container = styled.div<{ isShrinked: boolean }>`
  display: flex;
  margin-top: 16px;
  flex-direction: ${({ isShrinked }) => (isShrinked ? 'column' : 'row')};
  & {
    ${LeftColumn} {
      order: ${({ isShrinked }) => (isShrinked ? 2 : 1)};
      margin: ${({ isShrinked }) => (isShrinked ? '16px 0 0' : '0 0 12px 0')};
      @media ${device.tablet} {
        margin: 0;
      }
    }
  }
  @media ${device.tablet} {
    flex-flow: row wrap;
    ${LeftColumn} {
      flex: 0 0 100%;
      order: 2;
    }
  }
`;

const OnlineExposesBase = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isShrinked = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );

  const { searchProfileId } = useParams<{ searchProfileId: string }>();

  const { searchProfile } = useSearchProfileQuery(
    {
      id: searchProfileId,
    },
    {
      selectFromResult: ({ data, isLoading, error, isSuccess }) => {
        return {
          searchProfile: data?.searchProfile,
          error,
          isLoading,
          isSuccess,
        };
      },
    }
  );

  const filters = useAppSelector((state) => state.onlineExposes.filters);

  useEffect(() => {
    if (searchProfile) {
      const pin = {
        longitude:
          searchProfile.searchProfileData.location?.coordinates.longitude,
        latitude:
          searchProfile.searchProfileData.location?.coordinates.latitude,
      };

      batch(() => {
        dispatch(
          changeViewport({
            ...searchProfile.searchProfileData.location?.coordinates,
            bearing: -80,
            pitch: 65,
            zoom: 14,
            transitionDuration: 0,
          })
        );
        dispatch(
          setSettings({
            dragPan: true,
            dragRotate: true,
            scrollZoom: true,
            touchZoom: true,
            touchRotate: true,
            keyboard: true,
            doubleClickZoom: true,
          })
        );
        dispatch(addMarkers([pin]));
        dispatch(setFilters(filtersInitialState));
      });
    }
    return () => {
      batch(() => {
        dispatch(removeAllMarkersExceptHome());
        dispatch(clearExposesCache());
      });
    };
  }, [dispatch, searchProfile]);

  if (
    (searchProfile && Object.keys(searchProfile).length === 0) ||
    Object.keys(filters).length === 0
  ) {
    return <></>;
  }

  return (
    <Container isShrinked={isShrinked}>
      <LeftColumn>
        <OnlineExposesContainer />
      </LeftColumn>
    </Container>
  );
};

const OnlineExposes = memo(OnlineExposesBase);

export { OnlineExposes };
