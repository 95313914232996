import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SubmitHandler } from 'react-hook-form';

import { IFinancingForm } from '../../interface';
import { useAppSelector } from '../../../../common/hooks';
import { toggleLoading } from '../../../../auth/redux/authSlice';
import { MainEarner } from '../../../../../generated';

interface IFinancingPersonal {
  householdNetIncome: number;
  age: number;
  mainEarner: MainEarner;
  // termsAndConditionsFinancing: boolean;
}

interface IProps {
  setErrors: (errors: unknown) => void;
  onSuccess: (formData: IFinancingForm, ownerId: string) => Promise<void>;
}

interface IReturn {
  onFinancingPersonalSubmit: (formData: IFinancingPersonal) => void;
}

const useSubmitFinancingPersonal = ({
  setErrors,
  onSuccess,
}: IProps): IReturn => {
  const dispatch = useDispatch();
  const ownerId = useAppSelector((state) => state.auth.user?._id);
  const userInput = useAppSelector((state) => state.financing.userInput);

  const onFinancingPersonalSubmit: SubmitHandler<IFinancingPersonal> =
    useCallback(
      async (formData) => {
        const {
          householdNetIncome,
          mainEarner,
          age,
          // termsAndConditionsFinancing,
        } = formData;

        if (householdNetIncome && mainEarner && age && ownerId) {
          try {
            await onSuccess(
              {
                ...userInput,
                age,
                householdNetIncome,
                mainEarner,
                // termsAndConditionsFinancing,
              } as IFinancingForm,
              ownerId
            );
          } catch (error: unknown) {
            setErrors(error);
          } finally {
            dispatch(toggleLoading(false));
          }
        }
      },
      [dispatch, onSuccess, setErrors, ownerId, userInput]
    );

  return { onFinancingPersonalSubmit };
};

export { useSubmitFinancingPersonal };
