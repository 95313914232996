import { SubmitHandler } from 'react-hook-form';
import { useCallback } from 'react';
import { batch } from 'react-redux';

import { IRegisterForm } from '../../../auth/interfaces';
import { parseRegisterInput } from '../../../auth/utils/parse-register-input';
import {
  RegistrationBy,
  UserType,
  useSignUpMutation,
} from '../../../../generated';
import {
  setUserId,
  setUserInput,
  setUsername,
  setUserState,
  toggleLoading,
} from '../../../auth/redux/authSlice';
import { parseSearchProfileInput } from '../../utils/parse-search-profile-input';
import { ISearchProfileForm } from '../interface';
import { Feature } from '../../../../services/mapbox/interfaces';
import { useActualExperiment } from '../../../experiments/hooks/useActualExperiment';
import useGetLocalization from '../../../localization/get-localization';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useCreateSP } from './useCreateSP';

type TProps = {
  setConfirmEmailSent: (state: boolean) => void;
  setErrors: (state: null | unknown) => void;
};

type TReturn = {
  onRegisterSubmit: (formData: IRegisterForm) => Promise<void>;
};

const useRegisterSP = ({ setConfirmEmailSent, setErrors }: TProps): TReturn => {
  const dispatch = useAppDispatch();
  const lang = useGetLocalization();
  const { isRegistrationByEmail, registrationBy } = useActualExperiment();
  const [signUp] = useSignUpMutation();

  const userId = useAppSelector((state) => state.auth.userInput?._id);
  const userInput = useAppSelector((state) => state.searchProfile.userInput);
  const selectedAddress = useAppSelector(
    (state) => state.searchProfile.selectedAddress
  );

  const { onCreateSearchProfile } = useCreateSP();

  const onRegisterSubmit: SubmitHandler<IRegisterForm> = useCallback(
    async (formData) => {
      const input = parseRegisterInput({
        formData,
        lang,
        userType: UserType.Buyer,
      });

      dispatch(setUserInput({ ...formData, password: input.password }));
      dispatch(toggleLoading(true));

      // Create the user account before phone verification
      try {
        const signUpResponse = await signUp({
          input: {
            ...input,
            hasPendingValuations: true,
            registrationBy: registrationBy || RegistrationBy.Mobile,
          },
        }).unwrap();

        if (signUpResponse?.signUp?._id) {
          const {
            _id: id,
            username: newUsername,
            userState: newUserState,
          } = signUpResponse.signUp;
          batch(() => {
            dispatch(setUserId(id));
            dispatch(setUsername(newUsername));
            dispatch(setUserState(newUserState));
          });

          if (isRegistrationByEmail) {
            const searchProfileInput = parseSearchProfileInput(
              userInput as ISearchProfileForm,
              selectedAddress as Feature,
              userId as string
            );

            await onCreateSearchProfile({
              spInput: searchProfileInput,
              ownerId: id,
            });

            setConfirmEmailSent(true);
          }
        }
      } catch (error: unknown) {
        setErrors(error);
      } finally {
        dispatch(toggleLoading(false));
      }
    },
    [
      dispatch,
      isRegistrationByEmail,
      lang,
      onCreateSearchProfile,
      registrationBy,
      selectedAddress,
      setConfirmEmailSent,
      setErrors,
      signUp,
      userId,
      userInput,
    ]
  );
  return { onRegisterSubmit };
};

export { useRegisterSP };
