import {
  InvitationPrivileges,
  PriceFilterRangeInput,
  PropertyPortfolioFieldsFragment,
} from '../../generated';

export interface ICollaborationUser {
  id: number;
  name: string;
  surname: string;
  email: string;
  invitationPrivileges: InvitationPrivileges;
}

export interface IPropertyType {
  label: string;
  value: boolean | null;
}

export interface IRangeObject {
  min: number;
  max: number;
}

export interface ISimilarPropertiesFilters {
  propertyType: boolean | null;
  livingArea: IRangeObject;
  buildingYear: IRangeObject;
  salePrice: PriceFilterRangeInput;
  propertyPeriod: string;
  numberOfRooms: number[];
}

export enum BuyerFinderPageSort {
  HighestPrice = 'highest',
  LowestPrice = 'lowest',
}

export interface IPropertyPortfolioFilterFormFieldOption {
  value: string | number;
  minValue?: number;
  maxValue?: number;
  label: string | ((value: number) => string);
  editing?: boolean;
  checked?: boolean;
}

export interface IPropertyPortfolioFilterFormField {
  prop:
    | 'propertyType'
    | 'buildingYear'
    | 'livingArea'
    | 'landArea'
    | 'numberOfRooms'
    | 'numberOfBathrooms'
    | 'numberOfIndoorParkingSpaces'
    | 'numberOfOutdoorParkingSpaces'
    | 'price'
    | 'energy';
  type: 'input' | 'checkbox';
  label: string;
  options?: IPropertyPortfolioFilterFormFieldOption[];
}

export interface IPropertyPortfolio extends PropertyPortfolioFieldsFragment {
  __typename?: 'Property';
  defaultTitle: string;
  price: number;
  energyLabelData: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
}
