import { ReactElement, useContext } from 'react';
import {
  useController,
  useFormContext,
  RegisterOptions,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as Check1 } from 'assets/streamline-light/interface-essential/form-validation/check-1.svg';

import Icon from '../ui/icon';

import * as S from './input/input-outside-top-label-styled';
import { hexToRGB } from '../../utils/hex-to-rgb';

type ICheckbox = {
  name: string;
  rules?: RegisterOptions;
  hasDisclaimer?: boolean;
  isWithoutErrors?: boolean;
  isRedesign?: boolean;
  isBoldCheckbox?: boolean;
  isWithoutTranslation?: boolean;
  link?: ReactElement;
};

type LabelOrInnerHTML =
  | { label: string; innerHTML?: never }
  | { label?: never; innerHTML?: string };

type CheckboxProps = ICheckbox & LabelOrInnerHTML;

const CheckboxWrapper = styled.label`
  display: inline-flex;
  cursor: pointer;
`;

const CheckboxElement = styled.div<{ isRedesign?: boolean }>`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: solid 1px
    ${(props) =>
      props.isRedesign ? hexToRGB(props.theme.blue, 0.5) : props.theme.blue};
  margin: 0 8px 0 0;
  overflow: hidden;
  line-height: 0;
  transition: 0.12s ease;
  &:hover {
    background-color: ${(props) => props.theme.greyBackground};
  }
`;

const Label = styled.span<{ isRedesign?: boolean; isBoldCheckbox?: boolean }>`
  --label-color: rgb(0, 48, 94);
  font-family: Roboto, sans-serif !important;
  font-size: 12px;
  font-weight: ${({ isBoldCheckbox }) => (isBoldCheckbox ? 'bold' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${(props) =>
    props.isRedesign ? hexToRGB(props.theme.blue, 0.6) : 'var(--label-color)'};

  a {
    color: var(--label-color);
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const ErrorsContainer = styled.div`
  margin-top: 4px;
`;

const Checkbox = ({
  name,
  label,
  rules,
  hasDisclaimer,
  innerHTML,
  isWithoutErrors,
  isRedesign,
  isBoldCheckbox,
  isWithoutTranslation,
  link,
  ...rest
}: CheckboxProps): JSX.Element => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    defaultValue: rules?.value ?? false,
    rules,
  });
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <CheckboxWrapper {...rest}>
        <CheckboxElement isRedesign={isRedesign}>
          {inputProps?.value && (
            <Icon
              width={12}
              height={12}
              icon={Check1}
              color={themeContext.ctaBlue}
            />
          )}
        </CheckboxElement>
        {label && (
          <Label isBoldCheckbox={isBoldCheckbox} isRedesign={isRedesign}>
            {`${isWithoutTranslation ? label : t(label)}${
              hasDisclaimer ? ' *' : ''
            }`}
            {link}
          </Label>
        )}
        {innerHTML && (
          <Label
            isRedesign={isRedesign}
            dangerouslySetInnerHTML={{
              __html: `${isWithoutTranslation ? innerHTML : t(innerHTML)}${
                hasDisclaimer ? ' *' : ''
              }`,
            }}
          />
        )}
        <HiddenCheckbox ref={ref} type="checkbox" {...inputProps} />
      </CheckboxWrapper>
      {!isWithoutErrors && errors[name]?.message && (
        <ErrorsContainer>
          <S.Label $hasError={Boolean(errors[name])}>
            {t(errors[name]?.message)}
          </S.Label>
        </ErrorsContainer>
      )}
    </>
  );
};

export { Checkbox };
