import { api as generatedApi } from '../../generated/index';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [
    'Properties',
    'PropertiesPortfolio',
    'PaginatedPropertiesPortfolio',
    'PropertiesOnOfficeView',
    'Valuations',
    'Invites',
    'SearchProfiles',
    'SellerExposeStatus',
    'MarketingStatistics',
    'SellingProgress',
    'SellerProperties',
    'Financings',
    'PropertyDocuments',
    'FileAccessConfig',
    'BuyerFinder',
  ],
  endpoints: {
    propertyValuation: {
      providesTags: () => [{ type: 'Valuations', id: 'LIST' }],
    },
    properties: {
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result?.properties.map(
                ({ _id: id }) => ({ type: 'Properties', id } as const)
              ),
              { type: 'Properties', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Properties', id: 'LIST' }` is invalidated
            [{ type: 'Properties', id: 'LIST' }],
    },
    propertyPortfolio: {
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result?.properties.map(
                ({ _id: id }) => ({ type: 'PropertiesPortfolio', id } as const)
              ),
              { type: 'PropertiesPortfolio', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Properties', id: 'LIST' }` is invalidated
            [{ type: 'PropertiesPortfolio', id: 'LIST' }],
    },
    paginatedPropertyPortfolio: {
      providesTags: (result) =>
        result
          ? [
              ...result.readProperties.page.map(
                ({ _id: id }) =>
                  ({ type: 'PaginatedPropertiesPortfolio', id } as const)
              ),
              {
                type: 'PaginatedPropertiesPortfolio',
                id: 'LIST',
              },
            ]
          : [{ type: 'PaginatedPropertiesPortfolio', id: 'LIST' }],
    },
    property: {
      providesTags: (_, __, { id }) => [{ type: 'Properties', id }],
    },
    propertyOnOfficeView: {
      providesTags: (_, __, { id }) => [{ type: 'PropertiesOnOfficeView', id }],
    },
    createPropertyDossier: {
      invalidatesTags: (_, __, { input: { propertyId: id } }) => [
        { type: 'Properties', id },
      ],
    },
    createProperty: {
      invalidatesTags: [
        { type: 'Properties', id: 'LIST' },
        { type: 'SellerProperties', id: 'LIST' },
        {
          type: 'PropertiesPortfolio',
          id: 'LIST',
        },
        {
          type: 'PaginatedPropertiesPortfolio',
          id: 'LIST',
        },
      ],
    },
    convertProperty: {
      invalidatesTags: [{ type: 'Properties', id: 'LIST' }],
    },
    updateProperty: {
      invalidatesTags: (_, __, { input: { _id: id } }) => [
        { type: 'Properties', id },
        { type: 'Valuations', id: 'LIST' },
        { type: 'BuyerFinder', id: 'LIST' },
      ],
    },
    updatePropertyPortfolio: {
      invalidatesTags: (_, __, { input: { _id: id } }) => [
        { type: 'PropertiesPortfolio', id },
        { type: 'Valuations', id: 'LIST' },
      ],
    },
    updatePropertyAlerts: {
      invalidatesTags: (_, __, { input: { _id: id } }) => [
        { type: 'Properties', id },
        { type: 'Valuations', id: 'LIST' },
      ],
    },
    notifyBrokerForBuyerFinder: {
      invalidatesTags: (_, __, { propertyId }) => [
        { type: 'Properties', propertyId },
        { type: 'Valuations', id: 'LIST' },
      ],
    },
    initiateRealSell: {
      invalidatesTags: (_, __, { input: { propertyId: id } }) => [
        { type: 'Properties', id },
      ],
    },
    setDisabledShopServices: {
      invalidatesTags: (_, __, { propertyId: id }) => [
        { type: 'Properties', id },
        { type: 'PropertiesOnOfficeView', id },
      ],
    },
    similarProperties: {
      keepUnusedDataFor: 0,
    },
    suggestedProperties: {
      keepUnusedDataFor: 0,
    },
    exposeRequests: {
      keepUnusedDataFor: 0,
    },
    readProperties: {
      providesTags: [{ type: 'SellerProperties', id: 'LIST' }],
    },
    readUserPurchaseIntents: {
      keepUnusedDataFor: 0,
    },
    readUserAppointments: {
      keepUnusedDataFor: 0,
    },
    getPropertyServices: {
      keepUnusedDataFor: 0,
    },
    acceptPurchaseOffer: {
      invalidatesTags: ['MarketingStatistics'],
    },
    getMarketingStatistics: {
      keepUnusedDataFor: 0,
      providesTags: ['MarketingStatistics'],
    },
    getPropertyExposeStatus: {
      keepUnusedDataFor: 0,
      providesTags: ['SellerExposeStatus'],
    },
    sellingProgress: {
      providesTags: ['SellingProgress'],
    },
    setPropertyExposeStatus: {
      invalidatesTags: ['SellerExposeStatus', 'SellingProgress'],
    },
    sendPropertyServices: {
      invalidatesTags: ['SellerExposeStatus', 'SellingProgress'],
    },
    dashboardStatus: {
      keepUnusedDataFor: 0,
    },
    marketingStatus: {
      keepUnusedDataFor: 0,
    },
    disabledShopServices: {
      keepUnusedDataFor: 0,
    },
    removeProperty: {
      invalidatesTags: (_, __, { id }) => [
        { type: 'Properties', id: 'LIST' },
        { type: 'Properties', id },
      ],
    },
    uploadPropertyFiles: {
      invalidatesTags: (_, __, { propertyId: id }) => [
        { type: 'Properties', id },
        { type: 'SellingProgress' },
        { type: 'PropertyDocuments' },
      ],
    },
    getPropertyDocuments: {
      providesTags: ['PropertyDocuments'],
    },
    uploadPropertyDocuments: {
      invalidatesTags: ['PropertyDocuments'],
    },
    getBuyersDocumentAccessStatus: {
      keepUnusedDataFor: 0,
    },
    fileAccessConfig: {
      providesTags: ['FileAccessConfig'],
    },
    saveFileAccessConfig: {
      invalidatesTags: ['FileAccessConfig'],
    },
    getExposeDocuments: {
      keepUnusedDataFor: 0,
    },
    invites: {
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result?.invites.map(
                ({ _id }) => ({ type: 'Invites', id: _id as string } as const)
              ),
              { type: 'Invites', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Properties', id: 'LIST' }` is invalidated
            [{ type: 'Invites', id: 'LIST' }],
    },
    shareProperty: {
      invalidatesTags: [{ type: 'Invites', id: 'LIST' }],
    },
    editInvitation: {
      invalidatesTags: (_, __, { input: { _id } }) => [
        { type: 'Invites', id: _id as string },
      ],
    },
    deleteInvitation: {
      invalidatesTags: (_, __, { inviteId }) => [
        { type: 'Invites', id: 'LIST' },
        { type: 'Invites', id: inviteId },
      ],
    },
    searchProfiles: {
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result?.searchProfiles.map(
                ({ _id: id }) => ({ type: 'SearchProfiles', id } as const)
              ),
              { type: 'SearchProfiles', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Properties', id: 'LIST' }` is invalidated
            [{ type: 'SearchProfiles', id: 'LIST' }],
    },
    searchProfile: {
      providesTags: (_, __, { id }) => [{ type: 'SearchProfiles', id }],
    },
    createSearchProfile: {
      invalidatesTags: [{ type: 'SearchProfiles', id: 'LIST' }],
    },
    updateSearchProfile: {
      invalidatesTags: (_, __, { input: { _id: id } }) => [
        { type: 'SearchProfiles', id },
      ],
    },
    updatePriceOffers: {
      invalidatesTags: (
        _,
        __,
        { updatePriceOffersInput: { searchProfileId } }
      ) => [{ type: 'SearchProfiles', searchProfileId }],
    },
    convertSearchProfile: {
      invalidatesTags: [{ type: 'SearchProfiles', id: 'LIST' }],
    },
    removeSearchProfile: {
      invalidatesTags: (_, __, { id }) => [
        { type: 'SearchProfiles', id: 'LIST' },
        { type: 'SearchProfiles', id },
      ],
    },
    likeProperty: {
      invalidatesTags: (_, __, { searchProfileId }) => [
        { type: 'SearchProfiles', id: searchProfileId },
      ],
    },
    dislikeProperty: {
      invalidatesTags: (_, __, { searchProfileId }) => [
        { type: 'SearchProfiles', id: searchProfileId },
      ],
    },
    markPropertyUnsuitable: {
      invalidatesTags: (_, __, { searchProfileId }) => [
        { type: 'SearchProfiles', id: searchProfileId },
      ],
    },
    financings: {
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result?.financings.map(
                ({ _id: id }) => ({ type: 'Financings', id } as const)
              ),
              { type: 'Financings', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Properties', id: 'LIST' }` is invalidated
            [{ type: 'Financings', id: 'LIST' }],
    },
    financing: {
      providesTags: (_, __, { id }) => [{ type: 'Financings', id }],
    },
    createFinancing: {
      invalidatesTags: [{ type: 'Financings', id: 'LIST' }],
    },
    updateFinancing: {
      invalidatesTags: (_, __, { input: { _id: id } }) => [
        { type: 'Financings', id },
      ],
    },
    removeFinancing: {
      invalidatesTags: (_, __, { id }) => [
        { type: 'Financings', id: 'LIST' },
        { type: 'Financings', id },
      ],
    },
    updateFinancingPropositions: {
      invalidatesTags: (_, __, { input: { _id: id } }) => [
        { type: 'Financings', id },
      ],
    },
    expandFinancingRadius: {
      invalidatesTags: (_, __, { input: { _id: id } }) => [
        { type: 'Financings', id },
      ],
    },
    findRelatedSearchProfiles: {
      providesTags: () => [{ type: 'BuyerFinder', id: 'LIST' }],
    },
  },
});

export const {
  useCreatePropertyDossierMutation,
  useCreatePropertyMutation,
  useConvertPropertyMutation,
  usePropertiesQuery,
  useLazyPropertiesQuery,
  usePropertyQuery,
  useLazyPropertyQuery,
  useUpdatePropertyMutation,
  useUpdatePropertyPortfolioMutation,
  useUpdatePropertyAlertsMutation,
  useLazyPropertyValuationQuery,
  useNotifyBrokerForBuyerFinderMutation,
  useSimilarPropertiesQuery,
  useLazySimilarPropertiesQuery,
  useRemovePropertyMutation,
  useLazyInvitesQuery,
  useInvitesQuery,
  useSharePropertyMutation,
  useEditInvitationMutation,
  useDeleteInvitationMutation,
  useSearchProfilesQuery,
  useCreateSearchProfileMutation,
  useRemoveSearchProfileMutation,
  useUpdateSearchProfileMutation,
  useUpdatePriceOffersMutation,
  useConvertSearchProfileMutation,
  useSearchProfileQuery,
  useLikePropertyMutation,
  useDislikePropertyMutation,
  useMarkPropertyUnsuitableMutation,
  useFinancingsQuery,
  useCreateFinancingMutation,
  useRemoveFinancingMutation,
  useUpdateFinancingMutation,
  useUpdateFinancingPropositionsMutation,
  useExpandFinancingRadiusMutation,
  useFinancingQuery,
} = api;
