import { FC } from 'react';
import { Container } from './financing-variants-content-table-styles';
import { FinancingVariantsContentTableItem } from './financing-variants-content-table-item/financing-variants-content-table-item';
import { IVariant } from '../../../../../constants';

interface IProps {
  variants: IVariant[][];
}

const FinancingVariantsContentTable: FC<IProps> = ({ variants }) => {
  return (
    <Container>
      {variants.map((item) => (
        <FinancingVariantsContentTableItem key={item[0].label} section={item} />
      ))}
    </Container>
  );
};

export { FinancingVariantsContentTable };
