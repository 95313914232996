import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form';

import { useAppSelector } from '../../../../common/hooks';
import {
  prevStepAction,
  toggleAuthFlow,
  toggleLoginForm,
  toggleRegisterForm,
  toggleForgotPasswordForm,
  closeVWModal,
  resetVW,
  resetRegionalVW,
} from '../../redux/valuationWizardV2Slice';
import { PropertyCode } from '../../../../../generated';
import { MODAL_ANIMATION_DURATION } from '../../../../common/components/modal/modal';
import { WidgetPropertyType } from '../../interface';

type TProps = {
  reset?: () => void;
  watch: UseFormWatch<FieldValues>;
};

type TReturn = {
  onBackClick: () => void;
  onCloseClick: () => void;
};

const useModalButtonsHandler = ({ reset, watch }: TProps): TReturn => {
  const dispatch = useDispatch();
  const {
    activeStep,
    isAuthFlow,
    isLoginFormOpen,
    isRegisterFormOpen,
    isForgotPassword,
    isRegionalPage,
    userInput,
    isConfirmEmailSent,
    iframePropertyType,
  } = useAppSelector((state) => state.valuationWizardV2);
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const isMultiFamilyHouse = userInput?.code === PropertyCode.MultiFamilyHouse;

  const isIframePropertyHouseApartment =
    iframePropertyType === WidgetPropertyType.HOUSE ||
    iframePropertyType === WidgetPropertyType.APARTMENT;
  const isIframePropertyMultiFamilyHouse =
    iframePropertyType === WidgetPropertyType.MULTI_FAMILY_HOUSE;

  const onCloseClick = useCallback(() => {
    dispatch(closeVWModal());

    if (isIframe && document.fullscreenElement && document.exitFullscreen) {
      document.exitFullscreen();
    }
    if (isConfirmEmailSent && isIframe) {
      return;
    }
    setTimeout(() => {
      if (!isRegionalPage) {
        dispatch(resetVW());
        if (reset) {
          reset();
        }
      } else {
        dispatch(resetRegionalVW());
      }
    }, MODAL_ANIMATION_DURATION);
  }, [dispatch, isConfirmEmailSent, isIframe, isRegionalPage, reset]);

  const onBackClick = useCallback(() => {
    const values = watch();
    if (activeStep === 0) {
      setTimeout(() => dispatch(resetVW()), MODAL_ANIMATION_DURATION);
      dispatch(closeVWModal());
    } else if (
      activeStep === 2 &&
      (values.code === PropertyCode.MultiFamilyHouse ||
        userInput?.code === PropertyCode.MultiFamilyHouse ||
        isIframePropertyHouseApartment)
    ) {
      if (isRegionalPage) {
        dispatch(closeVWModal());
        setTimeout(() => dispatch(resetRegionalVW()), MODAL_ANIMATION_DURATION);
      } else {
        dispatch(prevStepAction(0));
      }
      if (isIframe && document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else if (activeStep === 3 && isIframePropertyMultiFamilyHouse) {
      if (isRegionalPage) {
        dispatch(closeVWModal());
        setTimeout(() => dispatch(resetRegionalVW()), MODAL_ANIMATION_DURATION);
      } else {
        dispatch(prevStepAction(0));
      }
      if (isIframe && document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else if (isRegionalPage && activeStep === 1) {
      setTimeout(() => dispatch(resetRegionalVW()), MODAL_ANIMATION_DURATION);
      dispatch(closeVWModal());
      if (isIframe && document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else if (isMultiFamilyHouse && activeStep === 5) {
      dispatch(prevStepAction(3));
    } else if (isLoginFormOpen) {
      dispatch(toggleLoginForm(false));
    } else if (isRegisterFormOpen) {
      dispatch(toggleRegisterForm(false));
    } else if (isForgotPassword) {
      dispatch(toggleForgotPasswordForm(false));
    } else if (isAuthFlow) {
      dispatch(toggleAuthFlow(false));
    } else {
      dispatch(prevStepAction());
    }
  }, [
    activeStep,
    dispatch,
    isIframePropertyHouseApartment,
    isAuthFlow,
    isForgotPassword,
    isIframe,
    isLoginFormOpen,
    isRegionalPage,
    isRegisterFormOpen,
    userInput?.code,
    watch,
  ]);

  return {
    onBackClick,
    onCloseClick,
  };
};

export { useModalButtonsHandler };
