import { easeCubic } from 'd3-ease';
import { FC, memo, MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlyToInterpolator } from 'react-map-gl';
import { batch } from 'react-redux';
import { useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { PropertyCode } from '../../../../../../generated';
import { BorderButton } from '../../../../../common/components/ui/buttons';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { Germany } from '../../../../../map/constants/points-of-interest';
import { changeViewport, setMarkers } from '../../../../../map/redux/mapSlice';
import { GlobalError } from '../../../../../common/components/form/error/global-error';
import { VALUATION_FORM_KEYS } from '../../../constants';
import { prevStepAction } from '../../../redux/valuationWizardSlice';
import { device } from '../../../../../../style/theme';
import { NextButton } from '../../../../components/common/next-button';
import { SliderInput } from '../../../../components/common/slider-input';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div<{ isiframe?: boolean }>`
  display: flex;
  flex-direction: column;
  &:last-of-type {
    flex-direction: row;
    margin-top: auto;
    justify-content: flex-start;
    > *:last-of-type {
      margin-left: auto;
    }
  }
  @media ${({ isiframe }) => (isiframe ? '0' : device.tablet)} {
    &:last-of-type {
      justify-content: flex-start;
      > *:last-of-type {
        margin-top: 32px;
      }
    }
  }
`;

interface IProps {
  regionalSecondStepBack?: () => void;
}

const SecondStepBase: FC<IProps> = ({ regionalSecondStepBack }) => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();

  const propertyType = useAppSelector(
    (state) => state.valuationWizard.userInput?.code
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const selectedCountryCode = useAppSelector(
    (state) => state.valuationWizard.selectedCountryCode
  );

  const isApartment = useMemo(
    () => propertyType === PropertyCode.Apartment,
    [propertyType]
  );
  const isHouse = useMemo(
    () => propertyType === PropertyCode.House,
    [propertyType]
  );
  const isMultyFamilyHouse = useMemo(
    () => propertyType === PropertyCode.MultiFamilyHouse,
    [propertyType]
  );

  const [floorNumber, numberOfFloorsInBuilding, landArea, annualRentIncome] =
    useWatch({
      name: [
        VALUATION_FORM_KEYS.FLOOR_NUMBER,
        VALUATION_FORM_KEYS.NUMBER_OF_FLOORS_IN_BUILDING,
        VALUATION_FORM_KEYS.LAND_AREA,
        VALUATION_FORM_KEYS.ANNUAL_RENT_INCOME,
      ],
    });

  const isNumberOfFloorsLower = useMemo(
    () =>
      isApartment &&
      !Number.isNaN(floorNumber) &&
      !Number.isNaN(numberOfFloorsInBuilding) &&
      numberOfFloorsInBuilding < floorNumber,
    [isApartment, floorNumber, numberOfFloorsInBuilding]
  );

  // Clear markers and selected address
  const switchToPrevStep = useCallback(
    (e?: MouseEvent) => {
      e?.preventDefault();

      if (regionalSecondStepBack) {
        regionalSecondStepBack();
        return;
      }

      batch(() => {
        dispatch(
          changeViewport({
            ...Germany,
            transitionDuration: 'auto',
            transitionInterpolator: new FlyToInterpolator({
              speed: 1.8,
            }),
            transitionEasing: easeCubic,
          })
        );
        dispatch(setMarkers([]));
        // dispatch(resetSelectedAddressAction());
        dispatch(prevStepAction());
      });
    },
    [dispatch]
  );

  const minValue = selectedCountryCode === 'CH' ? 20 : 10;
  const minLabelHouse =
    selectedCountryCode === 'CH'
      ? 'valuation.form.second-step.living-area-min-error-switzerland'
      : 'valuation.form.second-step.living-area-min-error';
  const minLabelMultiFamily =
    selectedCountryCode === 'CH'
      ? 'valuation.form.second-step.living-area-min-error-multi-family-switzerland'
      : 'valuation.form.second-step.living-area-min-error-multi-family';

  return (
    <Container>
      <Row isiframe={isIframe}>
        {!isMultyFamilyHouse && (
          <SliderInput
            hasMeasurement
            name={VALUATION_FORM_KEYS.LIVING_AREA}
            label={t('valuation.form.second-step.living-area-label')}
            rules={{
              required: 'register.input.error.required',
              min: {
                value: minValue,
                message: minLabelHouse,
              },
              max: {
                value: 800,
                message: 'valuation.form.second-step.living-area-max-error',
              },
              valueAsNumber: true,
              value: minValue,
              validate: {
                min: (v) => v >= minValue || minLabelHouse,
                max: (v) =>
                  v <= 800 ||
                  'valuation.form.second-step.living-area-max-error',
              },
              setValueAs: (v) => Number.parseInt(v, 10),
            }}
          />
        )}
        {isApartment && (
          <SliderInput
            name={VALUATION_FORM_KEYS.FLOOR_NUMBER}
            label={t('valuation.form.second-step.floor-number-label')}
            rules={{
              required: 'register.input.error.required',
              min: {
                value: 0,
                message: 'valuation.form.second-step.floor-number-min-error',
              },
              max: {
                value: 20,
                message: 'valuation.form.second-step.floor-number-max-error',
              },
              valueAsNumber: true,
              value: 0,
              validate: {
                min: (v) =>
                  v >= 0 || 'valuation.form.second-step.floor-number-min-error',
                max: (v) =>
                  v <= 20 ||
                  'valuation.form.second-step.floor-number-max-error',
              },
              setValueAs: (v) => Number.parseInt(v, 10),
            }}
          />
        )}
        {isMultyFamilyHouse && (
          <SliderInput
            name={VALUATION_FORM_KEYS.NUMBER_OF_UNITS}
            label={t('valuation.form.second-step.number-of-units-label')}
            rules={{
              required: 'register.input.error.required',
              min: {
                value: 3,
                message: 'valuation.form.second-step.number-of-units-min-error',
              },
              max: {
                value: 25,
                message: 'valuation.form.second-step.number-of-units-max-error',
              },
              valueAsNumber: true,
              value: 3,
              validate: {
                min: (v) =>
                  v >= 3 ||
                  'valuation.form.second-step.number-of-units-min-error',
                max: (v) =>
                  v <= 25 ||
                  'valuation.form.second-step.number-of-units-max-error',
              },
              setValueAs: (v) => Number.parseInt(v, 10),
            }}
          />
        )}
        {isMultyFamilyHouse && (
          <SliderInput
            hasMeasurement
            name={VALUATION_FORM_KEYS.LIVING_AREA}
            label={t('valuation.form.second-step.living-area-multi-label')}
            rules={{
              required: 'register.input.error.required',
              min: {
                value: minValue,
                message: minLabelMultiFamily,
              },
              max: {
                value: 5000,
                message:
                  'valuation.form.second-step.living-area-max-error-multi-family',
              },
              valueAsNumber: true,
              value: minValue,
              validate: {
                min: (v) => v >= minValue || minLabelMultiFamily,
                max: (v) =>
                  v <= 5000 ||
                  'valuation.form.second-step.living-area-max-error-multi-family',
              },
              setValueAs: (v) => Number.parseInt(v, 10),
            }}
          />
        )}
        {!isApartment && (
          <SliderInput
            hasMeasurement
            name={VALUATION_FORM_KEYS.LAND_AREA}
            label={t('valuation.form.second-step.land-area-label')}
            rules={{
              required: 'register.input.error.required',
              min: {
                value: propertyType === PropertyCode.MultiFamilyHouse ? 0 : 50,
                message:
                  propertyType === PropertyCode.MultiFamilyHouse
                    ? 'valuation.form.second-step.land-area-multifamilyhouse-min-error'
                    : 'valuation.form.second-step.land-area-min-error',
              },
              max: {
                value: 5000,
                message: 'valuation.form.second-step.land-area-max-error',
              },
              valueAsNumber: true,
              value: propertyType === PropertyCode.MultiFamilyHouse ? 0 : 50,
              validate: {
                min: (v) =>
                  v >=
                    (propertyType === PropertyCode.MultiFamilyHouse ? 0 : 50) ||
                  'valuation.form.second-step.land-area-min-error',
                max: (v) =>
                  v <= 5000 || 'valuation.form.second-step.land-area-max-error',
              },
              setValueAs: (v) => Number.parseInt(v, 10),
            }}
          />
        )}
        {!isMultyFamilyHouse && (
          <>
            <SliderInput
              name={VALUATION_FORM_KEYS.NUMBER_OF_FLOORS_IN_BUILDING}
              label={t(
                'valuation.form.second-step.number-of-floors-in-building-label'
              )}
              rules={{
                required: 'register.input.error.required',
                min: {
                  value: 1,
                  message:
                    'valuation.form.second-step.number-of-floors-in-building-min-error',
                },
                max: {
                  value: 20,
                  message:
                    'valuation.form.second-step.number-of-floors-in-building-max-error',
                },
                valueAsNumber: true,
                value: 1,
                validate: {
                  min: (v) =>
                    v >= 1 ||
                    'valuation.form.second-step.number-of-floors-in-building-min-error',
                  max: (v) =>
                    v <= 20 ||
                    'valuation.form.second-step.number-of-floors-in-building-max-error',
                },
                setValueAs: (v) => Number.parseInt(v, 10),
              }}
            />
            {isNumberOfFloorsLower && !Number.isNaN(isNumberOfFloorsLower) && (
              <GlobalError
                title={t(
                  'valuation.form.second-step.numberoffloors-is-lower-than-floornumber'
                )}
                topMargin
              />
            )}
          </>
        )}
        {isMultyFamilyHouse && (
          <SliderInput
            rentSymbol={'eur'}
            name={VALUATION_FORM_KEYS.ANNUAL_RENT_INCOME}
            label={t('valuation.form.second-step.annual-rent-income-label')}
            rules={{
              required: 'register.input.error.required',
              min: {
                value: 5000,
                message:
                  'valuation.form.second-step.annual-rent-income-min-error',
              },
              max: {
                value: 1_000_000,
                message:
                  'valuation.form.second-step.annual-rent-income-max-error',
              },
              valueAsNumber: true,
              value: 5000,
              validate: {
                min: (v) =>
                  v === 0 ||
                  v >= 5000 ||
                  'valuation.form.second-step.annual-rent-income-min-error',
                max: (v) =>
                  v <= 1_000_000 ||
                  'valuation.form.second-step.annual-rent-income-max-error',
              },
              setValueAs: (v) => Number.parseInt(v, 10),
            }}
          />
        )}
      </Row>
      <Row isiframe={isIframe}>
        {(!isMobileSize || isIframe) && (
          <BorderButton
            id="step-2"
            label={t('button.back')}
            onClick={switchToPrevStep}
          />
        )}
        <NextButton
          id="step-2"
          watchedKeys={[
            ...(isApartment
              ? [
                  VALUATION_FORM_KEYS.LIVING_AREA,
                  ...(typeof floorNumber !== 'undefined' &&
                  !Number.isNaN(floorNumber)
                    ? [VALUATION_FORM_KEYS.FLOOR_NUMBER]
                    : []),
                  ...(typeof numberOfFloorsInBuilding !== 'undefined' &&
                  !Number.isNaN(numberOfFloorsInBuilding)
                    ? [VALUATION_FORM_KEYS.NUMBER_OF_FLOORS_IN_BUILDING]
                    : []),
                ]
              : []),
            ...(isHouse
              ? [
                  VALUATION_FORM_KEYS.LIVING_AREA,
                  VALUATION_FORM_KEYS.LAND_AREA,
                  ...(typeof numberOfFloorsInBuilding !== 'undefined' &&
                  !Number.isNaN(numberOfFloorsInBuilding)
                    ? [VALUATION_FORM_KEYS.NUMBER_OF_FLOORS_IN_BUILDING]
                    : []),
                ]
              : []),
            ...(isMultyFamilyHouse
              ? [
                  VALUATION_FORM_KEYS.NUMBER_OF_UNITS,
                  VALUATION_FORM_KEYS.LIVING_AREA,
                  ...(typeof landArea !== 'undefined' && !Number.isNaN(landArea)
                    ? [VALUATION_FORM_KEYS.LAND_AREA]
                    : []),
                  ...(typeof annualRentIncome !== 'undefined' &&
                  !Number.isNaN(annualRentIncome)
                    ? [VALUATION_FORM_KEYS.ANNUAL_RENT_INCOME]
                    : []),
                ]
              : []),
          ]}
          label={t('button.next-step')}
          outerDisable={isNumberOfFloorsLower}
        />
      </Row>
    </Container>
  );
};

const SecondStep = memo(SecondStepBase);

export { SecondStep };
