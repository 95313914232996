import styled from 'styled-components';
import { device } from '../../../../../../style/theme';

export const Container = styled.div`
  max-width: 1104px;
  margin: 0 auto;
  margin-top: 20px;

  @media ${device.tablet} {
    margin: 0;
    padding: 0;
    border: none;
  }
`;

export const FinancingResultsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  & > div:first-child {
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    & > div {
      border-radius: 3px;
      border: solid 1px #d9d9d9;
      background-color: #fff;
      padding: 32px 41px;
    }
  }
  & > div:last-child {
    border-radius: 3px;
    border: solid 1px #d9d9d9;
    background-color: #fff;
    padding: 32px;
    width: 44%;
  }

  @media ${device.tablet} {
    flex-direction: column;
    padding: 0 12px;
    & > div:first-child {
      & > div {
        padding: 12px;
      }
      width: 100%;
    }
    & > div:last-child {
      width: 100%;
      padding: 12px;
    }
  }
`;
