import { ThemeContext } from 'styled-components';
import { batch, useDispatch } from 'react-redux';
import { CSSProperties, FC, memo, useCallback, useContext } from 'react';

import { ReactComponent as CloseIcon } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { UserStatus } from '../../../../generated';
import { CloseButton } from './contact-form-modal-styles';

import { toggleContactFormOpen } from '../../redux/sellerPropertiesSlice';
import { useAppSelector } from '../../../common/hooks';
import { Modal } from '../../../common/components/modal';
import Appointments from '../tabs/appointments';
import Icon from '../../../common/components/ui/icon';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';

interface IProps {
  message?: string;
  messageSentAt?: Date;
  containerStyle?: CSSProperties;
  userStatus: UserStatus | null;
}

const ContactFormModalBase: FC<IProps> = ({
  message,
  messageSentAt,
  userStatus,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();

  const isOpen = useAppSelector(
    (state) => state.sellerProperties.isContactFormOpen
  );

  const onClose = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      batch(() => {
        dispatch(toggleContactFormOpen(false));
      });
    },
    [dispatch]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      wrapperStyles={{
        maxWidth: isMobileSize ? '100%' : '1104px',
        maxHeight: isMobileSize ? '100%' : 'unset',
        width: '100%',
        height: isMobileSize ? '100%' : 'unset',
        overflow: 'overlay',
        padding: isMobileSize ? '16px 8px' : 0,
        position: 'relative',
        borderRadius: isMobileSize ? 'unset' : '3px',
      }}
    >
      <CloseButton onClick={onClose}>
        <Icon
          icon={CloseIcon}
          width={isMobileSize ? 14 : 16}
          height={isMobileSize ? 14 : 16}
          color={themeContext.blue}
          strokeWidth={'1.5'}
        />
      </CloseButton>
      <Appointments
        userStatus={userStatus}
        containerStyle={{ marginTop: 0 }}
        isModal
        messageSentAt={messageSentAt}
        message={message}
      />
    </Modal>
  );
};

const ContactFormModal = memo(ContactFormModalBase);

export { ContactFormModal };
