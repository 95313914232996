import { batch } from 'react-redux';
import { FlyToInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';
import { useCallback } from 'react';
import { ValuationType } from '../../../../generated';
import {
  changeViewport,
  setMarkers,
  setSettings,
} from '../../../map/redux/mapSlice';
import { Germany } from '../../../map/constants/points-of-interest';
import {
  openValuationWizardAction,
  setValuationTypeAction,
  toggleIsHighlight,
} from '../redux/valuationWizardSlice';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { defaultMapSettings } from '../../../map/constants';

type TProps = {
  isLandingPage: boolean;
};

type TReturn = {
  openEvaluationWizard: (type: ValuationType) => void;
};

const useOpenEvaluationWizard = ({ isLandingPage }: TProps): TReturn => {
  const dispatch = useAppDispatch();
  const mapZoomLevel = useAppSelector(
    (state) => state.map.viewport.zoom
  ) as number;

  const openEvaluationWizard = useCallback(
    (type: ValuationType) =>
      batch(() => {
        dispatch(
          changeViewport({
            ...Germany,
            transitionDuration: 'auto',
            transitionInterpolator: new FlyToInterpolator({
              speed: mapZoomLevel > Germany.zoom ? 2 : 0.6,
              curve: 1,
            }),
            transitionEasing: easeCubic,
          })
        );
        dispatch(setMarkers([]));
        dispatch(setSettings(defaultMapSettings));
        dispatch(openValuationWizardAction());
        dispatch(setValuationTypeAction(type));
        if (isLandingPage) {
          setTimeout(() => {
            dispatch(toggleIsHighlight(true));
          }, 600);
          setTimeout(() => {
            dispatch(toggleIsHighlight(false));
          }, 2100);
        }
      }),
    [dispatch, isLandingPage, mapZoomLevel]
  );

  return {
    openEvaluationWizard,
  };
};

export { useOpenEvaluationWizard };
