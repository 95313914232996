import { memo, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { ReactComponent as InformationCircle } from 'assets/streamline-light/interface-essential/alerts/information-circle.svg';
import { ReactComponent as AlertTriangle } from 'assets/streamline-light/interface-essential/alerts/alert-triangle.svg';
import { ReactComponent as PropertyLike } from 'assets/streamline-light/real-estate/actions-buildings/real-estate-action-building-like.svg';
import { ReactComponent as Area } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  PropertyValuation,
  usePropertyOnOfficeViewQuery,
} from '../../../../generated';
import { CountryCodes } from '../../../localization/constants';
import useGetLocalization from '../../../localization/get-localization';
import { useCurrency } from '../../../property/hooks/useCurrency';
import {
  formatLastValuationDate,
  getLastQuarterDate,
} from '../../../property/utils/date';

import { ActualMarketReport } from '../../../property/components/card/actual-market-report';
import { AppointmentCard } from '../../../property/components/card/appointment-card';
import BigCardContainer from '../../../property/components/card/elements/big-card-container';
import { TeamContactCard } from '../../../property/components/card/team-contact-card';
import { CalculatorCard } from '../../../property/components/card/value-calculator-card/value-calculator-card';
import { CardContentHolder } from '../../../property/components/card/elements/card-content-holder';
import { CardTitle } from '../../../property/components/card/elements/card-title';
import { GeneralAppointmentCard } from '../../../property/components/card/general-appointment-card';
import { LineChart } from '../../../property/components/chart/line-chart';
import { MarketPriceCard } from '../../../property/components/card/market-price-card/market-price-card';
import { ParagraphText } from '../../../common/components/ui/typography';
import { PriceDevelopmentLineChart } from '../../../property/components/card/price-development-card/price-development-line-chart';
import { SalePriceElement } from '../../../property/components/card/market-price-card/price-element';
import { ToolTipBasicContent } from '../../../common/components/tooltip/TooltipBasicContent';
import { Tooltip } from '../../../common/components/tooltip';
import { ValueCalculator } from '../../../property/components/value-calculator/value-calculator';
import { calculateSquareMeterPrice } from '../../../property/utils/calculate-square-meter-price';
import { device } from '../../../../style/theme';
import { formatNumber } from '../../../property/utils/format-number';
import { percentageDifference } from '../../../property/utils/percentage-difference';
import { usePropertyQuery } from '../../../../services/graphql/enhanced';
import { AlertInfo } from '../../../common/components/ui/alerts/info';
import { useOverviewConverted } from '../../../property/hooks/useOverviewConverted';
import { useConfig } from '../../../../config/useConfig';
import Icon from '../../../common/components/ui/icon';
import { OnOfficePriceCard } from '../../../property/components/card/on-office-price-card/on-office-price-card';
import { OnOfficeSalePriceElement } from '../../../property/components/card/on-office-price-card/on-office-price-element';
import { useAppSelector } from '../../../common/hooks';
import { ColumnChart } from '../../../property/components/chart/column-chart';
import { CardContent } from '../../../property/components/card/elements/card-content';
import { TimeOnMarket } from '../../../property/components/card/time-on-market-card/time-on-market';

const Container = styled.div`
  position: relative;
  display: flex;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const ChartContainer = styled.div`
  height: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 24px;
`;

const ChartLabel = styled.div`
  font-size: 10px;
  font-weight: 900;
  font-family: Roboto;
  line-height: 1.6;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.ctaBlue};
  text-align: right;
`;

const FirstColumn = styled.div`
  flex: 1.72;
  //max-width: 688px;
  //margin-right: 16px;
  @media ${device.tablet} {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

const SecondColumn = styled.div`
  flex: 1;
  max-width: 400px;
  @media ${device.tablet} {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

const OnOfficePriceCardsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 0;
  }
`;

const PriceCardsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 12px 0 0 0;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  > div:last-child {
    margin-left: 12px;
    margin-bottom: 8px;
  }
`;

const EmptyMessageContainer = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 1.33;
  border-radius: 3px;
  padding: 12px 12px;
  border: solid 1px #dacebc;
  background-color: rgba(162, 133, 87, 0.2);
`;

const EmptyMessage = styled(ParagraphText)`
  font-size: 12px;
`;

const ChartExcludedItems = styled.div`
  font-family: Roboto;
  font-size: 14px;
  color: rgb(0, 48, 94);
  letter-spacing: 0.5;
  line-height: 1.6;
  margin-top: 16px;
`;

const ChartExcludedLabel = styled.span`
  font-weight: 900;
  font-size: 14px;
  line-height: 1.6;
`;

const MarketPriceBase = (): JSX.Element => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const { isDev } = useConfig();

  const { propertyId } = useParams<{ propertyId: string }>();
  const isTabListNearTop = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );

  const {
    currentValuation: propertyCurrentValuation,
    currentValuationIndex: propertyCurrentValuationIndex,
    currentQuarterValuationIndex: propertyCurrentQuarterValuationIndex,
    currentQuarterValuation: propertyCurrentQuarterValuation,
    livingArea: propertyLivingArea,
    valuations: propertyValuations,
    valuationsLastSync: propertyValuationsLastSync,
    statistics: propertyStatistics,
    city: propertyCity,
    appointmentId: propertyAppointmentId,
    countryCode: countryCodeProperty,
    onOfficePrice: propertyOnOfficePrice,
    propertyWidgetFeedback: propertyWidgetFeedbackData,
  } = usePropertyOnOfficeViewQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data }) => {
        const {
          valuations,
          valuationsLastSync,
          propertyStatistics: statisticsRaw,
          appointmentId,
          countryCode,
          onOfficePrice: price,
          propertyWidgetFeedback,
        } = data?.propertyOnOfficeView ?? {};
        const { livingArea, location } =
          data?.propertyOnOfficeView?.propertyData ?? {};

        const currentValuationIndex =
          valuations?.findIndex(
            (valuation) =>
              new Date(valuation.date).toDateString() ===
              new Date(valuationsLastSync).toDateString()
          ) ?? -1;

        const currentQuarterValuationIndex = currentValuationIndex - 1;

        return {
          currentValuationIndex,
          currentValuation: valuations?.[currentValuationIndex],
          currentQuarterValuationIndex,
          currentQuarterValuation: valuations?.[currentQuarterValuationIndex],
          livingArea,
          valuations,
          valuationsLastSync,
          statistics: statisticsRaw,
          city: location?.address?.city,
          appointmentId: appointmentId ?? '',
          countryCode,
          onOfficePrice: price,
          propertyWidgetFeedback,
        };
      },
    }
  );

  const priceCurrency = useCurrency();
  const convertedValuations = useOverviewConverted(
    propertyCurrentValuation,
    propertyCurrentQuarterValuation,
    propertyValuations as PropertyValuation[]
  );
  const newPropertyCurrentValuation =
    convertedValuations.newPropertyCurrentValuation as PropertyValuation;
  const newPropertyCurrentQuarterValuation =
    convertedValuations.newPropertyCurrentQuarterValuation as PropertyValuation;
  const newPropertyValuations =
    convertedValuations.newPropertyValuations as PropertyValuation[];

  const isGermanSite = useGetLocalization() === 'de-de';
  const isGermanyProperty =
    countryCodeProperty?.toLowerCase() === CountryCodes.GERMANY;

  const [statisticsLabels, statisticsData, excludedLabels] = useMemo(() => {
    const statisticsLabelsArray: string[] = [];
    const statisticsDataArray: number[] = [];
    const excludedLabelsArray: string[] = [];

    propertyStatistics?.forEach(({ statistics, groupId }) => {
      const dataValue = statistics?.find(
        (s) => s?.parameters?.percentileRank === 50
      )?.value;

      if (dataValue) {
        const tokens = (groupId || '-').split('-');
        statisticsLabelsArray.push(`${tokens[1]}/${tokens[0]?.slice(2)}`);
        statisticsDataArray.push(Math.ceil(dataValue));
      } else {
        const tokens = (groupId || '-').split('-');
        excludedLabelsArray.push(`${tokens[1]}/${tokens[0]?.slice(2)}`);
      }
    });

    return [statisticsLabelsArray, statisticsDataArray, excludedLabelsArray];
  }, [propertyStatistics]);

  const propertySalePriceDifference = useMemo(() => {
    let prevValuation = propertyValuations?.[propertyCurrentValuationIndex - 1];

    // Stupid fix
    if (
      propertyValuations &&
      propertyValuations[propertyCurrentValuationIndex - 1] &&
      propertyValuations[propertyCurrentValuationIndex] &&
      propertyValuations[propertyCurrentValuationIndex - 1].date.slice(0, 7) ===
        propertyValuations[propertyCurrentValuationIndex].date.slice(0, 7)
    ) {
      prevValuation = propertyValuations?.[propertyCurrentValuationIndex - 2];
    }

    return percentageDifference(
      prevValuation?.valuation?.salePrice ?? 0,
      propertyValuations?.[propertyCurrentValuationIndex]?.valuation
        .salePrice ?? 0,
      true
    );
  }, [propertyValuations, propertyCurrentValuationIndex]);
  const isShowStatistics =
    statisticsData && statisticsData.filter((item) => item).length > 1;

  return (
    <>
      {/* {propertyOnOfficePrice ? ( */}
      {/*  <AlertInfo */}
      {/*    style={{ */}
      {/*      backgroundColor: '#a2575733', */}
      {/*      border: `1px solid #a2575733`, */}
      {/*    }} */}
      {/*    text={t('valuation.overview.selling-price.alert.info')} */}
      {/*    icon={ */}
      {/*      <Icon */}
      {/*        icon={AlertTriangle} */}
      {/*        width={16} */}
      {/*        height={16} */}
      {/*        color={themeContext.blue} */}
      {/*        style={{ */}
      {/*          alignSelf: 'center', */}
      {/*          marginRight: '12px', */}
      {/*          minWidth: '16px', */}
      {/*        }} */}
      {/*        strokeWidth={'1.5'} */}
      {/*      /> */}
      {/*    } */}
      {/*  /> */}
      {/* ) : ( */}
      {/*  <AlertInfo */}
      {/*    style={{ backgroundColor: themeContext?.paleRed }} */}
      {/*    text={t('valuation.overview.market.price.alert.info')} */}
      {/*  /> */}
      {/* )} */}
      <Container>
        <FirstColumn>
          {propertyOnOfficePrice ? (
            <OnOfficePriceCard
              title={t('valuation.overview.market.on-office-price.title')}
              content={t('valuation.overview.market.on-office-price.content')}
              marketDataMessage={t(
                'valuation.overview.on-office-price.message'
              )}
            >
              <OnOfficePriceCardsWrapper>
                <OnOfficeSalePriceElement
                  salePrice={formatNumber(propertyOnOfficePrice ?? 0)}
                  priceLabel="on-office-price.element.one.label"
                  icon={PropertyLike}
                />
                <OnOfficeSalePriceElement
                  salePrice={calculateSquareMeterPrice(
                    propertyLivingArea ?? 0,
                    propertyOnOfficePrice ?? 0
                  )}
                  priceLabel="price.element.two.label"
                  icon={Area}
                />
              </OnOfficePriceCardsWrapper>
            </OnOfficePriceCard>
          ) : null}
          <MarketPriceCard
            title={t('valuation.overview.market.price.title')}
            content={t(
              'valuation.overview.market.price.content.price-currency',
              {
                price: formatNumber(
                  newPropertyCurrentValuation?.valuation?.salePrice
                ),
                lowerBound: formatNumber(
                  newPropertyCurrentValuation?.valuation?.salePriceRange?.lower
                ),
                upperBound: formatNumber(
                  newPropertyCurrentValuation?.valuation?.salePriceRange?.upper
                ),
                priceCurrency,
              }
            )}
            marketDataDate={formatLastValuationDate(
              propertyValuationsLastSync ?? ''
            )}
            marketDataMessage={t('valuation.overview.market.data.message')}
            elementsContent={t('valuation.overview.market.price.element.title')}
            price={formatNumber(
              newPropertyCurrentValuation?.valuation?.salePrice ?? 0
            )}
            propertyWidgetFeedbackData={propertyWidgetFeedbackData}
          >
            <PriceCardsWrapper>
              <SalePriceElement
                salePrice={formatNumber(
                  newPropertyCurrentValuation?.valuation?.salePrice ?? 0
                )}
                priceLabel="price.element.one.label"
                priceChangePercentage={propertySalePriceDifference}
                date={getLastQuarterDate()}
                priceChangePercentageColor={
                  propertySalePriceDifference > 0
                    ? themeContext.green
                    : themeContext.red
                }
              />
              <SalePriceElement
                salePrice={calculateSquareMeterPrice(
                  propertyLivingArea ?? 0,
                  newPropertyCurrentValuation?.valuation?.salePrice ?? 0
                )}
                priceLabel="price.element.two.label"
                priceChangePercentage={propertySalePriceDifference}
                date={getLastQuarterDate()}
                priceChangePercentageColor={
                  propertySalePriceDifference > 0
                    ? themeContext.green
                    : themeContext.red
                }
              />
              <SalePriceElement
                priceRangeLow={
                  newPropertyCurrentValuation?.valuation?.salePriceRange
                    ?.lower ?? 0
                }
                priceRangeHigh={
                  newPropertyCurrentValuation?.valuation?.salePriceRange
                    ?.upper ?? 0
                }
                priceLabel="price.element.three.label"
                priceChangePercentage={propertySalePriceDifference}
                date={getLastQuarterDate()}
                priceChangePercentageColor={
                  propertySalePriceDifference > 0
                    ? themeContext.green
                    : themeContext.red
                }
              />
            </PriceCardsWrapper>
          </MarketPriceCard>
          <div style={{ height: '16px' }} />
          <CalculatorCard
            title="valuation.overview.price.development.title"
            content="valuation.overview.price.development.content"
          >
            <ChartContainer>
              <ChartLabel>
                {t('valuation.overview.price.development.chart-label')}
              </ChartLabel>
              <PriceDevelopmentLineChart
                propertyValuations={newPropertyValuations ?? []}
                propertyValuationsLastSync={propertyValuationsLastSync}
                propertyCurrentQuarterValuationIndex={
                  propertyCurrentQuarterValuationIndex
                }
              />
            </ChartContainer>
            <PriceCardsWrapper>
              <SalePriceElement
                startPrice={
                  newPropertyValuations?.[
                    propertyCurrentQuarterValuationIndex - 12
                  ]?.valuation?.salePrice ?? 0
                }
                endPrice={
                  newPropertyCurrentQuarterValuation?.valuation?.salePrice ?? 0
                }
                priceLabel="valuation.overview.price.development.past-three-years"
                invertColors
              />
              <SalePriceElement
                startPrice={
                  newPropertyValuations?.[
                    propertyCurrentQuarterValuationIndex - 4
                  ]?.valuation?.salePrice ?? 0
                }
                endPrice={
                  newPropertyCurrentQuarterValuation?.valuation?.salePrice ?? 0
                }
                priceLabel="valuation.overview.price.development.since-last-year"
                invertColors
              />
              <SalePriceElement
                startPrice={
                  newPropertyCurrentQuarterValuation?.valuation?.salePrice ?? 0
                }
                endPrice={
                  newPropertyValuations?.[
                    propertyCurrentQuarterValuationIndex + 5
                  ]?.valuation?.salePrice ?? 0
                }
                priceLabel="valuation.overview.price.development.for-next-year"
                invertColors
              />
            </PriceCardsWrapper>
          </CalculatorCard>

          {/* <CalculatorCard> */}
          {/*  <ValueCalculator /> */}
          {/* </CalculatorCard> */}

          {isShowStatistics && (
            <BigCardContainer>
              <CardContentHolder>
                <Row>
                  <CardTitle
                    content={t('property-locked-page.days-on-the-market.title')}
                  />
                </Row>
                <Row>
                  <CardContent content={t('days-on-market.tooltip.content')} />
                </Row>
              </CardContentHolder>
              <CardContentHolder margin={'16px 0 0'}>
                <ChartLabel>{t('chart.current-market-period')}</ChartLabel>
                <TimeOnMarket
                  id="Vermarktungsdauer"
                  labels={statisticsLabels}
                  data={statisticsData}
                />
                {excludedLabels.length > 0 && (
                  <ChartExcludedItems>
                    <ChartExcludedLabel>
                      {t('chart.excludedLabel')}:&nbsp;
                    </ChartExcludedLabel>
                    {excludedLabels.join('; ')}
                  </ChartExcludedItems>
                )}
                {/* <EmptyMessageContainer> */}
                {/*  <EmptyMessage */}
                {/*    content={t('chart.days-on-market.not_found.message')} */}
                {/*  /> */}
                {/* </EmptyMessageContainer> */}
              </CardContentHolder>
            </BigCardContainer>
          )}
          {isGermanSite && isGermanyProperty && (
            <ActualMarketReport city={propertyCity ?? ''} />
          )}
        </FirstColumn>
        {/* <SecondColumn> */}
        {/* <BigCardContainer> */}
        {/*  <CardContentHolder> */}
        {/*    <Row> */}
        {/*      <CardTitle */}
        {/*        content={t('property-locked-page.days-on-the-market.title')} */}
        {/*      /> */}
        {/*      <Tooltip */}
        {/*        icon={InformationCircle} */}
        {/*        id="days-on-market-tooltip" */}
        {/*        position={isTabListNearTop ? 'bottom' : 'top'} */}
        {/*      > */}
        {/*        <ToolTipBasicContent */}
        {/*          content={t('days-on-market.tooltip.content')} */}
        {/*        /> */}
        {/*      </Tooltip> */}
        {/*    </Row> */}
        {/*  </CardContentHolder> */}

        {/* <CardContentHolder> */}
        {/*  {isShowStatistics ? ( */}
        {/*    <> */}
        {/*      <ColumnChart */}
        {/*        id="Vermarktungsdauer" */}
        {/*        labels={statisticsLabels} */}
        {/*        data={statisticsData} */}
        {/*      /> */}
        {/*      {excludedLabels.length > 0 && ( */}
        {/*        <ChartExcludedItems> */}
        {/*          <ChartExcludedLabel> */}
        {/*            {t('chart.excludedLabel')}:&nbsp; */}
        {/*          </ChartExcludedLabel>{' '} */}
        {/*          {excludedLabels */}
        {/*            .map((l) => { */}
        {/*              const tokens = l.split('-'); */}

        {/*              return `${tokens[1]}/${tokens[0].slice(2)}`; */}
        {/*            }) */}
        {/*            .join('; ')} */}
        {/*        </ChartExcludedItems> */}
        {/*      )} */}
        {/*    </> */}
        {/*  ) : ( */}
        {/*    <EmptyMessageContainer> */}
        {/*      <EmptyMessage */}
        {/*        content={t('chart.days-on-market.not_found.message')} */}
        {/*      /> */}
        {/*    </EmptyMessageContainer> */}
        {/*  )} */}
        {/* </CardContentHolder> */}
        {/* </BigCardContainer> */}
        {/* <TeamContactCard /> */}

        {/* <TeamContactCard /> */}
        {/* <ConsultationCard /> */}
        {/* </SecondColumn> */}
      </Container>
    </>
  );
};

const MarketPrice = memo(MarketPriceBase);

export { MarketPrice };
