import { FC } from 'react';
import styled from 'styled-components';
import { BaseButton } from './base-button';

const DarkBlueGhostButtonBase = styled(BaseButton)`
  border: solid 2px ${({ theme }) => theme.blue};
  background-color: transparent;
  color: ${({ theme }) => theme.blue};
  &:hover {
    background-color: rgba(0, 48, 94, 0.2);
  }
`;
interface IProps {
  label: string;
  fluid?: boolean;
  onClick: () => void;
}
const DarkBlueGhostButton: FC<IProps> = ({
  label,
  fluid,
  onClick,
  ...rest
}) => {
  return (
    <DarkBlueGhostButtonBase fluid={fluid} onClick={onClick} {...rest}>
      {label}
    </DarkBlueGhostButtonBase>
  );
};

export { DarkBlueGhostButton };
