import { useEffect, useState } from 'react';

export interface Offset {
  left: number;
  right: number;
}

export const useKeepElementInWindow = (
  el: HTMLElement | null | undefined,
  bounce = 0
): Offset => {
  const [offset, setOffset] = useState<Offset>({
    left: 0,
    right: 0,
  });

  useEffect(() => {
    const onResize = () => {
      if (!el) return;
      const { x, width } = el.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const marginLeft =
        Number.parseInt(window.getComputedStyle(el).marginLeft, 10) || 0;
      const origX = x - marginLeft;

      if (origX < bounce) {
        setOffset({
          left: Math.ceil(Math.abs(origX - bounce)),
          right: 0,
        });
      } else if (origX + width > windowWidth - bounce) {
        setOffset({
          left: 0,
          right: Math.ceil(Math.abs(origX + width - (windowWidth - bounce))),
        });
      } else {
        setOffset({
          left: 0,
          right: 0,
        });
      }
    };

    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [el]);

  return offset;
};
