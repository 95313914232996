import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowButtonLeft1 } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Close } from '../../../../../assets/streamline-light/interface-essential/form-validation/close.svg';
import { RootState } from '../../../../../store';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import { Progress } from '../../../components/common/progress';
import { stepDescriptions } from '../../constants/steps';
import { ModalHeaderProps } from '../../interface';
import { prevStep, close } from '../../redux/purchaseIntendSlice';
import {
  IconButton,
  MobileToolbarContainer,
  MobileTitle,
  MobileStepDescription,
  MobileTextContainer,
} from './modal-headers-styles';

const ModalMobileHeader = ({
  errors,
  isLoading,
  onClose,
}: ModalHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reset } = useFormContext();
  const { activeStep, structure, type } = useSelector(
    (state: RootState) => state.purchaseIntend
  );

  const stepsCount = structure[type].length;

  const closeHandler = () => {
    if (!isLoading) {
      reset();
      dispatch(close());
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <div>
      <MobileToolbarContainer>
        {activeStep > 0 && (
          <IconButton
            mobile
            onClick={() => {
              if (!isLoading) {
                dispatch(prevStep());
              }
            }}
          >
            <ArrowButtonLeft1 />
          </IconButton>
        )}
        <MobileTextContainer>
          <MobileTitle>{t('purchase-intent.form.formTitle')}</MobileTitle>
          <MobileStepDescription>
            {t(stepDescriptions[structure[type][activeStep]])}
          </MobileStepDescription>
        </MobileTextContainer>
        <IconButton mobile onClick={closeHandler}>
          <Close />
        </IconButton>
      </MobileToolbarContainer>
      {errors?.message && (
        <GlobalError title={t(errors?.message?.split(':')[0].toLowerCase())} />
      )}
      <Progress activeBullet={activeStep} bullets={stepsCount} />
    </div>
  );
};

export default ModalMobileHeader;
