import styled from 'styled-components';

interface IProps {
  content: string;
}

const Container = styled.h4`
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
`;

const Headline4 = ({ content, ...rest }: IProps): JSX.Element => (
  <Container {...rest}>{content}</Container>
);

export { Headline4 };
