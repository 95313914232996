import styled, { ThemeContext } from 'styled-components';
import { CSSProperties, FC, memo, useContext } from 'react';
import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/remove-add/remove-circle.svg';
import { ParagraphText } from '../../../../common/components/ui/typography';
import Icon from '../../../../common/components/ui/icon';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 3px;
  border: solid 1px #dacebc;
  background-color: rgba(162, 133, 87, 0.2);
`;

const Text = styled(ParagraphText)`
  font-size: 12px;
  line-height: 1.33;
`;

interface IProps {
  text: string;
  onClose: () => void;
  containerStyles?: CSSProperties;
}

const WarningMessageBase: FC<IProps> = ({ text, onClose, containerStyles }) => {
  const theme = useContext(ThemeContext);

  return (
    <Container style={containerStyles}>
      <Text content={text} />
      <Icon
        icon={Close}
        width={16}
        height={16}
        onClick={onClose}
        color={theme.blue}
        strokeWidth={'2'}
        style={{ cursor: 'pointer', minWidth: '16px' }}
      />
    </Container>
  );
};

const WarningMessage = memo(WarningMessageBase);

export { WarningMessage };
