import styled from 'styled-components';
import { device } from '../../../../../../../style/theme';
import { MainButton } from '../../../../../../common/components/ui/buttons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  max-width: 562px;
  margin: 0 auto;
  flex-grow: 1;
  //padding: 64px;
`;

export const Description = styled.p`
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;

export const ContainerFields = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 274px);
  gap: 12px;
  margin-bottom: 12px;

  & > div > div:first-child > div:last-child,
  input {
    width: 100%;
    min-width: 200px;
  }

  & > div {
    align-items: flex-start;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const Text = styled.div`
  --label-color: rgb(0, 48, 94);
  font-family: Roboto, sans-serif !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${(props) => 'var(--label-color)'};

  a {
    color: var(--label-color);
  }
`;

export const SubmitButton = styled(MainButton)<{ isRedesign?: boolean }>`
  position: ${({ isRedesign }) => (isRedesign ? 'static' : 'absolute')};
  right: 0;
  bottom: 0;
  align-items: flex-end;
  text-transform: uppercase;
  justify-content: center;
  margin-top: 24px;

  @media ${device.tablet} {
    width: 100%;
    margin-top: 12px;
  }

  @media ${device.mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
