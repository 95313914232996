import { memo, useEffect } from 'react';
import styled from 'styled-components';
import { batch } from 'react-redux';
import { device } from '../../../../style/theme';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { FavouritesContainer } from '../favourites/container';
import {
  clearFavouritePropertiesCache,
  filtersInitialState,
  setFilters,
} from '../../redux/favouritePropertiesSlice';
import { SuggestedPropertiesFilterInput } from '../../../../generated';

const Grid = styled.div`
  padding: 36px 32px 32px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #fff;
  flex: 1.56;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    margin-right: 0;
    padding: 16px;
  }
`;

const Container = styled.div<{ isShrinked: boolean }>`
  display: flex;
  margin-top: 16px;
  flex-direction: ${({ isShrinked }) => (isShrinked ? 'column' : 'row')};
  & {
    ${Grid} {
      order: ${({ isShrinked }) => (isShrinked ? 2 : 1)};
      margin: ${({ isShrinked }) => (isShrinked ? '16px 0 0' : 0)};
      // isShrinked ? '16px 0 0' : '0 16px 12px 0'};
      @media ${device.tablet} {
        margin: 0;
      }
    }
  }
  @media ${device.tablet} {
    flex-flow: row wrap;
    ${Grid} {
      flex: 0 0 100%;
      order: 2;
    }
  }
`;

const FavouritesBase = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const isShrinked = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );

  const filters = useAppSelector(
    (state) => state.favouriteProperties.filters
  ) as SuggestedPropertiesFilterInput;

  useEffect(() => {
    batch(() => {
      dispatch(setFilters(filtersInitialState));
    });
    return () => {
      batch(() => {
        dispatch(clearFavouritePropertiesCache());
      });
    };
  }, [dispatch]);

  if (Object.keys(filters).length === 0) {
    return <></>;
  }

  return (
    <Container isShrinked={isShrinked}>
      <Grid>
        <FavouritesContainer />
      </Grid>
    </Container>
  );
};

const Favourites = memo(FavouritesBase);

export { Favourites };
