import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from '../../../../../../style/theme';
import { InputOutsideTopLabel } from '../../../../../common/components/form';
import { SelectOutsideTopLabel } from '../../../../../common/components/form/select/select-outside-top-label';
import {
  businessOptions,
  legalFormOptions,
  countryOptions,
} from '../../../constants/options';
import { Row } from '../../common/form-utils';

const Title = styled.h3`
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  margin-top: 16px;
  margin-bottom: 12px;

  @media ${device.tablet} {
    margin: 16px 0;
    line-height: 1.43;
    font-size: 14px;
  }
`;

const LegalFirstStep = (): JSX.Element => {
  const { t } = useTranslation();
  const [legalForm, business, headOfficeCountry] = useWatch({
    name: ['legalForm', 'business', 'headOfficeCountry'],
  });

  return (
    <div>
      <Row>
        <InputOutsideTopLabel
          name={'companyName'}
          type={'text'}
          label={t('purchase-intent.form.companyName')}
          placeholder={t('purchase-intent.form.companyName')}
          rules={{}}
        />
        <SelectOutsideTopLabel
          label={t('purchase-intent.form.legalForm')}
          name={'legalForm'}
          selectedOption={
            legalFormOptions.find((o) => o.value === legalForm)?.label || ''
          }
          options={legalFormOptions}
        />
      </Row>
      <Row>
        <InputOutsideTopLabel
          name={'registrationNumber'}
          type={'text'}
          label={t('purchase-intent.form.registrationNumber')}
          placeholder={t('purchase-intent.form.registrationNumber')}
          rules={{}}
        />
        <SelectOutsideTopLabel
          label={t('purchase-intent.form.business')}
          name={'business'}
          selectedOption={
            businessOptions.find((o) => o.value === business)?.label || ''
          }
          options={businessOptions}
        />
      </Row>
      <Title>{t('purchase-intent.form.legal.firstStepDescriptionTitle')}</Title>
      <Row templateColumns={'1fr 64px'}>
        <InputOutsideTopLabel
          name={'headOfficeStreet'}
          type={'text'}
          label={t('purchase-intent.form.street')}
          placeholder={t('purchase-intent.form.street')}
          rules={{}}
        />
        <InputOutsideTopLabel
          name={'headOfficeBuildingNumber'}
          type={'text'}
          label={t('purchase-intent.form.buildingNumber')}
          placeholder={t('purchase-intent.form.buildingNumber')}
          rules={{}}
        />
      </Row>
      <Row>
        <InputOutsideTopLabel
          name={'headOfficePostalCode'}
          type={'text'}
          label={t('purchase-intent.form.postalCode')}
          placeholder={t('purchase-intent.form.postalCode')}
          rules={{}}
        />
        <InputOutsideTopLabel
          name={'headOfficeCity'}
          type={'text'}
          label={t('purchase-intent.form.city')}
          placeholder={t('purchase-intent.form.city')}
          rules={{}}
        />
      </Row>
      <Row>
        <SelectOutsideTopLabel
          label={t('purchase-intent.form.country')}
          name={'headOfficeCountry'}
          selectedOption={
            countryOptions.find((c) => c.value === headOfficeCountry)?.label ||
            ''
          }
          options={countryOptions}
        />
      </Row>
    </div>
  );
};

export default LegalFirstStep;
