import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getClosestElementBySelector } from 'modules/seller/utils/getEventTargetElement';
import Icon from 'modules/common/components/ui/icon';
import { ThemeContext } from 'styled-components';

import {
  Form,
  Container,
  TooltipHeader,
  TooltipBack,
  TooltipAccept,
  TooltipActions,
  TooltipClose,
} from './tooltip-styles';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { OutsideClickHandler } from '../../../../../common/components/outside-click-handler';
import { setSelectedTab } from '../../../../../property/redux/dynamicMapUtilsSlice';
import { AcceptOfferBtn } from '../new-offer-styles';
import { ReactComponent as Check } from '../../../../../../assets/streamline-light/interface-essential/form-validation/check-1.svg';
import { ReactComponent as Close } from '../../../../../../assets/streamline-light/interface-essential/form-validation/close.svg';

const TooltipBase = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [offset, setOffset] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });

  const themeContext = useContext(ThemeContext);
  const isMobileSize = useIsMobileSize();

  const handleOutsideClick = useCallback(() => {
    setIsVisible(false);
  }, []);

  const handleInitialRealSale = useCallback(() => {
    dispatch(setSelectedTab(0));
  }, [dispatch]);

  useEffect(() => {
    const hide = () => setIsVisible(false);
    window.addEventListener('scroll', hide);

    return () => window.removeEventListener('scroll', hide);
  }, []);

  return (
    <Container role={'presentation'} onClick={(event) => {}}>
      {isVisible && (
        <OutsideClickHandler
          display="block"
          onOutsideClick={handleOutsideClick}
        >
          <Form offset={offset}>
            <TooltipHeader>
              {t('selling-simulation.tooltip.text')}
            </TooltipHeader>
            <TooltipActions direction={'column'}>
              <TooltipAccept onClick={handleInitialRealSale}>
                {t('selling-simulation.tooltip.start-marketing')}
              </TooltipAccept>
            </TooltipActions>
            <TooltipClose>
              <Icon
                icon={Close}
                width={16}
                height={16}
                style={{ cursor: 'pointer' }}
                onClick={handleOutsideClick}
                color={isMobileSize ? themeContext.white : themeContext.blue}
              />
            </TooltipClose>
          </Form>
        </OutsideClickHandler>
      )}
      <AcceptOfferBtn
        label={''}
        icon={Check}
        iconWidth={16}
        iconHeight={16}
        iconStyle={{ margin: 0 }}
        onClick={(event) => {
          const btnPosition = getClosestElementBySelector(
            event.target as HTMLElement,
            'button'
          )?.getBoundingClientRect();

          const left =
            btnPosition.left +
            btnPosition.width / 2 -
            (isMobileSize ? 240 : 204);

          const top = btnPosition.top - (isMobileSize ? 144 : 122);

          setOffset({
            top,
            left,
          });

          setIsVisible(true);
        }}
      />
    </Container>
  );
};

const SimulationTooltip = memo(TooltipBase);
export { SimulationTooltip };
