import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import styled, { css } from 'styled-components';

import { ReactComponent as Bin2 } from 'assets/streamline-light/interface-essential/delete/bin-2.svg';
import { ReactComponent as Pencil } from 'assets/streamline-light/interface-essential/edit/pencil.svg';
import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { ReactComponent as Cog1 } from 'assets/streamline-light/interface-essential/settings/cog-1.svg';

import { Invite } from '../../../../../generated';
import { useDeleteInvitationMutation } from '../../../../../services/graphql/enhanced';
import { device } from '../../../../../style/theme';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import {
  Modal,
  ModalFooter,
  ModalTitle,
} from '../../../../common/components/modal';
import { BorderButton } from '../../../../common/components/ui/buttons';
import Icon from '../../../../common/components/ui/icon';
import { useAppDispatch } from '../../../../common/hooks';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import {
  setModifyCollaborator,
  toggleEditModal,
  toggleInvitationModal,
} from '../../../redux/propertyCollaboratorsSlice';

interface ISharePropertyEditCollaborator {
  isOpen: boolean;
  onCloseModal: () => void;
  existingInvitations?: Invite[];
}

const Form = styled.form`
  @media ${device.tablet} {
    padding: 0 16px;
  }
`;

const Collaborators = styled.div`
  height: 400px;
  overflow: auto;
`;

const Collaborator = styled.div`
  display: flex;
  margin: 0 0 8px;
  padding: 12px 16px;
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.ctaDisabledBackground};
  background-color: ${({ theme }) => theme.white};
`;

const CollaboratorDetails = styled.div`
  flex: 1 1 220px;
  padding-right: 12px;
`;

const CollaboratorName = styled.h3`
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  margin: 0 0 4px;
`;

const CollaboratorEmail = styled.h4`
  opacity: 0.8;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${({ theme }) => theme.blue};
`;

const CollaboratorModifications = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 210px;
`;

const Button = styled.button<{ isDisabled?: boolean }>`
  margin: 0 8px;
  cursor: pointer;
  color: ${({ theme, isDisabled }) =>
    isDisabled ? hexToRGB(theme.blue, 0.5) : theme.blue};
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `}

  background: none;
  border: none;
  outline: none;
  &:last-child {
    margin-right: 0;
  }
  & svg {
    width: 16px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  @media ${device.tablet} {
    background-color: ${(props) => props.theme.blue};
    width: 100%;
    justify-content: center;
    padding: 18px 0 18px 0;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  @media ${device.tablet} {
    position: absolute;
    right: 16px;
  }
`;

const desktopWrapperStyle = {
  maxWidth: 512,
};

const mobileWrapperStyle = {
  width: '100%',
  height: '100%',
  overflow: 'overlay',
  padding: 0,
};

const SharePropertyModalEditCollaborators = ({
  isOpen,
  onCloseModal,
  existingInvitations,
}: ISharePropertyEditCollaborator): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  const [deleteInvite, { error }] = useDeleteInvitationMutation();

  const onRemoveCollaborator = (inviteId: string) => () =>
    deleteInvite({ inviteId });

  const onEditCollaborator = (collaboratorId: string) => () => {
    batch(() => {
      dispatch(toggleEditModal(false));
      dispatch(setModifyCollaborator(collaboratorId));
    });
    dispatch(toggleInvitationModal(true));
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    onCloseModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      wrapperStyles={isMobileSize ? mobileWrapperStyle : desktopWrapperStyle}
      onClose={onCloseModal}
    >
      {isMobileSize && (
        <Row>
          <ModalTitle
            text={t('share.property.form.edit-collaborators.title')}
            textColor="white"
            textAlign="center"
            margin="0"
          />
          <IconContainer onClick={onCloseModal}>
            <Icon icon={Close} width={16} height={16} color="white" />
          </IconContainer>
        </Row>
      )}
      {!isMobileSize && (
        <ModalTitle
          text={t('share.property.form.edit-collaborators.title')}
          icon={Cog1}
          iconPosition="left"
          textAlign="left"
        />
      )}
      {error?.message && (
        <GlobalError title={t(error?.message?.split(':')[0])} />
      )}
      <Form onSubmit={onSubmit}>
        <Collaborators>
          {existingInvitations?.map((collaborator) => (
            <Collaborator key={collaborator._id}>
              <CollaboratorDetails>
                <CollaboratorName>
                  {`${collaborator?.name} ${collaborator?.surname}`}
                </CollaboratorName>

                <CollaboratorEmail>{collaborator?.email}</CollaboratorEmail>
              </CollaboratorDetails>

              <CollaboratorModifications>
                {/* <SelectWrapper>
                  <Select
                    selectedOption={`share.property.form.role.option.${collaborator?.invitationPrivileges?.toLowerCase()}`}
                    options={[]}
                    onSelect={() => false}
                    isDisabled
                  />
                </SelectWrapper> */}

                {/* Needs logic for disabling delete button */}
                <Button
                  type="button"
                  isDisabled={false}
                  onClick={onRemoveCollaborator(collaborator._id ?? '')}
                >
                  <Bin2 />
                </Button>

                <Button
                  type="button"
                  onClick={onEditCollaborator(collaborator._id ?? '')}
                >
                  <Pencil />
                </Button>
              </CollaboratorModifications>
            </Collaborator>
          ))}
        </Collaborators>
        {!isMobileSize && (
          <ModalFooter>
            <BorderButton
              label={t('share.property.form.edit-collaborators.cancel')}
              type="button"
              onClick={onCloseModal}
            />
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
};

export { SharePropertyModalEditCollaborators };
