// eslint-disable-next-line import/no-cycle
import {
  PERIOD_15_DAYS,
  PERIOD_1_YEAR,
  PERIOD_2_YEARS,
  PERIOD_30_DAYS,
  PERIOD_5_YEARS,
} from '../constants';

export const formatLastValuationDate = (dateString: string): string => {
  const date = new Date(dateString || '').toLocaleString().split(',')[0];
  return date.replace(/\//g, '.');
};

export const getLastQuarterDate = (): string => {
  const today = new Date();
  const quarter = Math.floor(today.getMonth() / 3);

  const firstDate = new Date(today.getFullYear(), quarter * 3 - 3, 1);
  const endDate = new Date(
    firstDate.getFullYear(),
    firstDate.getMonth() + 3,
    0
  );

  const lastQuarter = Math.round(endDate.getMonth() / 3);
  const quarterYear = endDate.getFullYear().toString().slice(-2);
  return `VS Q${lastQuarter}${'`'}${quarterYear}`;
};

export const formatPeriodDate = (
  date?: Date,
  isDots?: boolean,
  isRevert?: boolean
): string => {
  if (!date) {
    return '';
  }
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;
  const year = date.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  if (isRevert) {
    return [day, month, year].join(isDots ? '.' : '-');
  }

  return [year, month, day].join(isDots ? '.' : '-');
};

export const getPropertyPeriod = (period: string): string => {
  const date = new Date();
  switch (period) {
    case PERIOD_15_DAYS:
      date.setDate(date.getDate() - 15);
      return formatPeriodDate(date);
    case PERIOD_30_DAYS:
      date.setDate(date.getDate() - 30);
      return formatPeriodDate(date);
    case PERIOD_1_YEAR:
      date.setFullYear(date.getFullYear() - 1);
      return formatPeriodDate(date);
    case PERIOD_5_YEARS:
      date.setFullYear(date.getFullYear() - 5);
      return formatPeriodDate(date);
    case PERIOD_2_YEARS:
    default:
      date.setFullYear(date.getFullYear() - 2);
      return formatPeriodDate(date);
  }
};
