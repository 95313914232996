import { batch } from 'react-redux';
import { useCallback, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useGetCountry } from '../../../../../localization/get-country';
import { StatusType } from '../../../../../common/components/verification-input/types';
import { selectFormattedPhone } from '../../../../../auth/redux/selectors/selectFormattedPhone';
import {
  LoginResponse,
  useConfirmGuestUserMutation,
  useConfirmPhoneMutation,
  useResendConfirmationCodeMutation,
  useResendGuestUserCodeMutation,
  UserState,
  useSignInMutation,
  useUserSelfDeleteMutation,
} from '../../../../../../generated';
import {
  setConfirmedGuest,
  setUserId,
  setUsername,
  setUserState,
  toggleLoading,
} from '../../../../../auth/redux/authSlice';
import { IValuationForm } from '../../../../form-property-valuation-wizard/interface';
import { TimifyPersonalisationIds } from '../../../../../timify/interfaces';
import { showServices } from '../../../../form-property-valuation-wizard/constants';
import { setTimifyParams } from '../../../redux/valuationWizardV2Slice';
import {
  ITemporaryUserCredentials,
  ITemporaryUserInput,
} from '../../../interface';

type TProps = {
  temporaryUserInput: ITemporaryUserInput;
  onSuccess: (formData: IValuationForm, ownerId: string) => Promise<void>;
  setErrors: (errors: unknown) => void;
  setTemporaryUserCredentials: (temporaryUC: ITemporaryUserCredentials) => void;
};

type TReturn = {
  formattedPhone: string;
  onVerifyPhone(code: string): void;
  verificationStatus: StatusType;
  onResendConfirmationCode?: () => void;
  onChangePhone?: () => void;
};

const usePersonalPhoneVerification = ({
  temporaryUserInput,
  onSuccess,
  setErrors,
  setTemporaryUserCredentials,
}: TProps): TReturn => {
  const dispatch = useAppDispatch();
  const { countryCode } = useGetCountry();

  const [verificationStatus, setVerificationStatus] = useState(
    StatusType.process
  );

  const formattedPhone = useAppSelector(selectFormattedPhone);
  const username = useAppSelector((state) => state.auth.userInput?.username);
  const email = useAppSelector((state) => state.auth.userInput?.email);
  const password = useAppSelector((state) => state.auth.userInput?.password);
  const userInput = useAppSelector(
    (state) => state.valuationWizardV2.userInput
  );

  const [confirmGuestAccount, setConfirmGuestAccount] = useState(false);

  // Mutations
  const [signIn] = useSignInMutation();
  const [confirmPhone] = useConfirmPhoneMutation();
  const [confirmGuestUser] = useConfirmGuestUserMutation();
  const [resendConfirmationCode] = useResendConfirmationCodeMutation();
  const [changePhone] = useUserSelfDeleteMutation();
  const [resendGuestConfirmationCode] = useResendGuestUserCodeMutation();

  const onVerifyPhone = useCallback(
    async (confirmationCode: string) => {
      setVerificationStatus(StatusType.wait);
      dispatch(toggleLoading(true));
      if (confirmGuestAccount) {
        try {
          const confirmGuestAccountResponse = await confirmGuestUser({
            input: {
              phoneNumber: formattedPhone,
              verificationCode: confirmationCode,
            },
          }).unwrap();

          if (confirmGuestAccountResponse.confirmGuestUser._id) {
            dispatch(setConfirmedGuest(formattedPhone));

            dispatch(
              setTimifyParams({
                serviceExternalIds: TimifyPersonalisationIds.PersonalValuation,
                firstName: temporaryUserInput?.name ?? '',
                lastName: temporaryUserInput?.surname ?? '',
                email: temporaryUserInput?.email ?? '',
                phone: temporaryUserInput?.phone ?? '',
                country: countryCode,
                showServices,
                showFooterBackButton: '1',
              })
            );
            return;
          }
        } catch (error: unknown) {
          setErrors(error);
        } finally {
          dispatch(toggleLoading(false));
        }
      }
      if (confirmationCode && username && password) {
        try {
          const confirmPhoneResult = await confirmPhone({
            input: {
              username,
              confirmationCode,
            },
          }).unwrap();

          if (confirmPhoneResult?.confirmPhone) {
            dispatch(setUserState(confirmPhoneResult.confirmPhone));
          }

          try {
            // Initiaite login to get the jwt token
            const signInResponse = await signIn({
              input: {
                userAuthenticationKey: username,
                password,
                requestAccessToken: true,
              },
            }).unwrap();

            if (signInResponse.signIn) {
              const signInParams = signInResponse.signIn as LoginResponse;
              setTemporaryUserCredentials({
                username: signInParams?.user?.username,
                accessToken: signInParams?.jwt?.token ?? '',
                refreshToken: signInParams?.jwt?.refreshToken ?? '',
                attributeName: 'email',
                firstName: signInParams?.user?.name,
                lastName: signInParams?.user?.surname,
              });
              onSuccess(userInput as IValuationForm, signInParams?.user?._id);
            }
          } catch (error: unknown) {
            setErrors(error);
          }
        } catch (error: unknown) {
          setErrors(error);
          setVerificationStatus(StatusType.error);
        } finally {
          dispatch(toggleLoading(false));
        }
      }
    },
    [
      dispatch,
      confirmGuestAccount,
      username,
      password,
      confirmGuestUser,
      formattedPhone,
      temporaryUserInput?.name,
      temporaryUserInput?.surname,
      temporaryUserInput?.email,
      temporaryUserInput?.phone,
      countryCode,
      setErrors,
      confirmPhone,
      signIn,
      setTemporaryUserCredentials,
      onSuccess,
      userInput,
    ]
  );

  const onResendConfirmationCode = useCallback(() => {
    if (confirmGuestAccount) {
      resendGuestConfirmationCode({ phoneNumber: formattedPhone });
    } else if (email) {
      resendConfirmationCode({
        email,
      });
    }
  }, [
    confirmGuestAccount,
    formattedPhone,
    resendConfirmationCode,
    resendGuestConfirmationCode,
    email,
  ]);

  const onChangePhone = useCallback(async () => {
    if (username) {
      try {
        await changePhone({ username }).unwrap();

        batch(() => {
          dispatch(setUserId(''));
          dispatch(setUsername(''));
          dispatch(setUserState(UserState.Unauthenticated));
          setConfirmGuestAccount(false);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [changePhone, dispatch, username]);

  return {
    onChangePhone,
    formattedPhone,
    onVerifyPhone,
    verificationStatus,
    onResendConfirmationCode,
  };
};

export { usePersonalPhoneVerification };
