import styled from 'styled-components';
import { ParagraphText } from '../../../../common/components/ui/typography';
import { SecondaryButton } from '../../../../common/components/ui/buttons';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../style/theme';

export const Container = styled.div<{ isActive?: boolean }>`
  display: flex;
  padding: 4px 16px 4px 4px;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.borderColor};
  border: ${(props) =>
    props.isActive
      ? `2px solid ${props.theme.blue}`
      : `1px solid ${props.theme.borderColor}`};

  @media ${device.tablet} {
    flex-direction: column;
    padding: 4px;
  }
`;

export const Image = styled.img`
  width: 182px;
  height: 144px;
  margin: 0 16px 0 0;
  object-fit: contain;
  border-radius: 3px;

  @media ${device.tablet} {
    width: 100%;
    margin: 0;
    object-fit: cover;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 12px 0;
  width: 100%;

  @media ${device.tablet} {
    margin: 0;
    padding: 12px;
  }
`;

export const TextWrapper = styled.div``;

export const Title = styled(ParagraphText)<{
  isActive?: boolean;
}>`
  font-size: 12px;
  font-weight: 900;
  line-height: 1.67;
`;

export const Description = styled(ParagraphText)`
  font-size: 12px;
  line-height: 1.67;
  opacity: 0.6;
  margin-top: 8px;
`;

export const ActionsWrapper = styled.div<{
  isSimulation?: boolean;
  isBrokerSettings?: boolean;
}>`
  display: flex;
  justify-content: ${(props) =>
    props.isSimulation
      ? 'end'
      : props.isBrokerSettings
      ? 'start'
      : 'space-between'};

  gap: ${(props) => (props.isBrokerSettings ? '24px' : 0)};

  @media ${device.tablet} {
    margin-top: 8px;
  }
`;

export const SwitchWrapper = styled.div<{ isBlurred?: boolean }>`
  display: flex;
  align-items: center;
  ${(props) => props.isBlurred && 'filter: blur(2px)'};
`;

export const SwitchLabel = styled(ParagraphText)<{ isActive?: boolean }>`
  font-size: 12px;
  line-height: 1.67;
  opacity: ${(props) => (props.isActive ? 1 : 0.6)};
`;

export const ActionButton = styled(SecondaryButton)<{ isFilled?: boolean }>`
  text-transform: uppercase;
  justify-content: center;
  height: 24px;
  padding: 2px 16px;
  width: fit-content;

  &:hover {
    background-color: ${(props) =>
      props.isFilled
        ? hexToRGB(props.theme.blue, 0.9)
        : hexToRGB(props.theme.blue, 0.1)};
  }
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;
