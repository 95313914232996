import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as MobileMenuBack } from 'assets/navigation/mobile-menu-back.svg';
import { useConfig } from '../../../../../config/useConfig';
import useGetLocalization from '../../../../localization/get-localization';
import { useAppSelector } from '../../../hooks';
import Icon from '../../ui/icon';
import CollapseHeaderNested from './collapse-header-nested';
import {
  LinkOption,
  NavigationLinkInterface,
  navigationLinks,
} from './navigation-types';

const BackButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
`;

const LinkBtn = styled.button`
  background: transparent;
  outline: none;
  border: none;
  text-align: left;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.05em;
  color: #00305e;
  transition: opacity 0.3s;
  margin-top: 30px;
  display: block;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

interface IProps {
  activeMenu: LinkOption;
  setActiveMenu: (newActiveMenu: LinkOption) => void;
}

const CollapseHeaderLinks = ({ activeMenu, setActiveMenu }: IProps) => {
  const { t } = useTranslation();

  const { isProd } = useConfig();
  const lang = useGetLocalization();

  const user = useAppSelector((state) => state.auth.user);

  if (activeMenu === '') return null;

  return (
    <>
      <BackButton onClick={() => setActiveMenu('')}>
        <Icon color={'#00305E'} icon={MobileMenuBack} width={13} height={27} />
      </BackButton>
      <div>
        {navigationLinks[activeMenu].map(
          (navigationLink: NavigationLinkInterface) => {
            if (!user && navigationLink.loggedInOnly) return null;

            if (user && navigationLink.notLoggedInOnly) return null;

            if (navigationLink.devOnly && isProd) return null;

            if (navigationLink.path) {
              const target = navigationLink.path.includes('#appUrl')
                ? '_self'
                : '_blank';

              const path = navigationLink.path.replace(
                '#appUrl',
                `${window.location.origin}/${lang}`
              );

              return (
                <LinkBtn onClick={() => window.open(path, target)}>
                  {t(navigationLink.label)}
                </LinkBtn>
              );
            }
            return <CollapseHeaderNested link={navigationLink} />;
          }
        )}
      </div>
    </>
  );
};

export default CollapseHeaderLinks;
