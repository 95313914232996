import { ReactComponent as Pin } from 'assets/streamline-light/maps-navigation/pins/pin.svg';
import { ReactComponent as RealEstateDimensions } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-plan.svg';
import { ReactComponent as RealEstateDimensionsBlock } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as Coin } from 'assets/streamline-light/business-products/donation/begging-hand-coin-2.svg';
import { ReactComponent as Arrow } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { Tooltip } from '../../../common/components/tooltip';
import { useAppDispatch } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import {
  setActiveTab,
  setExternalPropertyId,
  setIsAppointmentSelectOpen,
  setIsOpenHeadAccordion,
  setSelectedPropertyId,
  toggleExposeOverlay,
} from '../../pages/expose-details-overlay/redux/exposeSlice';
import { getInEur, parseFloatWithoutCurrency } from '../../utils/currency';
import { SinglePropertyProps } from './interfaces';
import SingleOffer from './single-offer';
import {
  Content,
  PropertiesContainer,
  Image,
  DetailsBox,
  Title,
  ControlBox,
  LocationBox,
  PricesBox,
  CurrentPriceBox,
  LastPriceBox,
  Emphasized,
  FeaturesBox,
  Feature,
  FeatureText,
  Hash,
  PurchaseOfferButton,
  ToggleButton,
  ToolTipContent,
  OffersContainer,
  OffersTitle,
  FeaturesMobileContainer,
  PurchaseButtonText,
} from './single-property-styles';

const SingleProperty = ({
  property,
  refetch,
  setOpenId,
}: SinglePropertyProps) => {
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    if (property.offers) {
      setIsOpen((prev) => !prev);
    }
  };

  const propertyClickHandler = () => {
    const exposeId = property.offers[0].propertyId;
    batch(() => {
      dispatch(setSelectedPropertyId(exposeId));
      dispatch(setExternalPropertyId(property.id));
      dispatch(toggleExposeOverlay(true));
      dispatch(setIsAppointmentSelectOpen(false));
      dispatch(setIsOpenHeadAccordion(true));
      dispatch(setActiveTab(0));
    });
  };

  return (
    <PropertiesContainer>
      <Content>
        <Image onClick={propertyClickHandler} src={property.img} />
        <DetailsBox isActive={isOpen}>
          <Title>{property.title}</Title>
          <LocationBox>
            <Pin width={17} height={22} />
            {property.address}
            <Hash>{property.externalId}</Hash>
          </LocationBox>
          <PricesBox>
            <CurrentPriceBox>
              <Emphasized>{getInEur(property.price)}</Emphasized>
              <span>
                (
                {property.price &&
                  property.area &&
                  parseFloatWithoutCurrency(
                    Number.parseFloat(`${property.price / property.area}`)
                  )}{' '}
                €/m²)
              </span>
            </CurrentPriceBox>
            {property.offers && property.offers[0] && (
              <LastPriceBox>
                <Emphasized>{getInEur(property.offers[0].price)}</Emphasized>
                <span>
                  {t('purchase-offers.on')}{' '}
                  {new Date(property.offers[0].dateCreated)
                    .toLocaleDateString()
                    .replaceAll('/', '.')}
                </span>
              </LastPriceBox>
            )}
          </PricesBox>
          <FeaturesBox>
            {isMobileSize ? (
              <>
                <Feature>
                  <FeaturesMobileContainer>
                    <RealEstateDimensions height={24} width={24} />
                    <span>{property.rooms}</span>
                  </FeaturesMobileContainer>
                  <FeatureText>{t('purchase-offers.rooms')}</FeatureText>
                </Feature>
                <Feature>
                  <FeaturesMobileContainer marginRight={40}>
                    <RealEstateDimensionsBlock height={24} width={24} />
                    <span>
                      {t('approx')} {property.area} m²
                    </span>
                  </FeaturesMobileContainer>
                  <FeatureText>{t('purchase-offers.area')}</FeatureText>
                </Feature>
              </>
            ) : (
              <>
                <Feature>
                  <RealEstateDimensions height={24} width={24} />
                  <FeatureText>{t('purchase-offers.rooms')}: </FeatureText>
                  <span>{property.rooms}</span>
                </Feature>
                <Feature>
                  <RealEstateDimensionsBlock height={24} width={24} />
                  <FeatureText>{t('purchase-offers.area')}: </FeatureText>
                  <span>
                    {t('approx')} {property.area} m²
                  </span>
                </Feature>
              </>
            )}
          </FeaturesBox>
        </DetailsBox>
        <ControlBox isActive={isOpen}>
          <Tooltip
            maxWidth={32}
            width={32}
            height={32}
            id={'111'}
            icon={() => (
              <PurchaseOfferButton
                onClick={() => setOpenId(property.offers[0].propertyId)}
              >
                <Coin height={16} width={16} />
                {isMobileSize && (
                  <PurchaseButtonText>
                    {t('purchase-offers.newOffer')}
                  </PurchaseButtonText>
                )}
              </PurchaseOfferButton>
            )}
          >
            <ToolTipContent>{t('purchase-offers.newOffer')}</ToolTipContent>
          </Tooltip>
          <ToggleButton onClick={toggleIsOpen} active={isOpen}>
            <Arrow height={16} width={16} />
          </ToggleButton>
        </ControlBox>
      </Content>
      {isOpen && (
        <OffersContainer>
          <OffersTitle>{t('purchase-intent.offers.title')}</OffersTitle>
          {property.offers &&
            property.offers.map((offer) => {
              return (
                <SingleOffer key={offer._id} refetch={refetch} offer={offer} />
              );
            })}
        </OffersContainer>
      )}
    </PropertiesContainer>
  );
};

export default SingleProperty;
