import Collapse, { Panel } from 'rc-collapse';
import { CollapsePanelProps } from 'rc-collapse/lib/interface';
import Icon from 'modules/common/components/ui/icon';
import { ReactComponent as ArrowDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import collapseMotion from 'modules/common/components/ui/faq-accordion/utils';
import { ReactComponent as Logo } from 'assets/buyer-finder/logo.svg';
import {
  Wrapper,
  ItemsWrapper,
  Item,
  ItemNumber,
  ItemContent,
  ItemText,
  ItemTitle,
  HeaderTitle,
  Header,
} from './buyer-finder-guide-styles';

export const BuyerFinderGuide = () => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Collapse
        openMotion={collapseMotion}
        className="outer-accordion"
        defaultActiveKey={1}
        expandIcon={({ isActive }: CollapsePanelProps) => (
          <Icon
            id="close-button"
            width={16}
            height={16}
            icon={ArrowDown}
            color={themeContext.blue}
            style={{
              transition: `transform 500ms ease`,
              transform: isActive ? 'rotate(-180deg)' : 'none',
            }}
          />
        )}
      >
        <Panel
          key="1"
          header={
            <Header>
              <Icon width={32} height={32} icon={Logo} />
              <HeaderTitle content={t('buyer-finder.guide.title')} />
            </Header>
          }
          headerClass="outer-header"
          className="outer-content"
        >
          <ItemsWrapper>
            {[1, 2, 3].map((i) => (
              <Item key={i}>
                <div>
                  <ItemNumber>{i}</ItemNumber>
                </div>
                <ItemContent>
                  <ItemTitle
                    content={t(`buyer-finder.guide.section-${i}.title`)}
                  />
                  <ItemText
                    content={t(`buyer-finder.guide.section-${i}.text`)}
                  />
                </ItemContent>
              </Item>
            ))}
          </ItemsWrapper>
        </Panel>
      </Collapse>
    </Wrapper>
  );
};
