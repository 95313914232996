import styled from 'styled-components';

export const Indicator = styled.div`
  height: 32px;
  width: 100%;
  border-radius: 5px;
  position: relative;
  background-image: linear-gradient(
    to right,
    #02b14f 0%,
    #02b14f 5.4545454545455%,
    #a0e11d 14.545454545455%,
    #c6ed12 22.727272727273%,
    #ffff00 31.818181818182%,
    #f9e700 41.818181818182%,
    #ffa900 52.727272727273%,
    #fe6d00 65.454545454545%,
    #eb5700 81.818181818182%,
    #e70a00 95.454545454545%,
    #e70a00 100%
  );
`;

export const EnergyConsumptionIcon = styled.div<{
  color: string;
  left: string;
  indicatorColor?: string;
}>`
  background-color: ${({ color }) => color};
  width: 36px;
  height: 53px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: 5px solid #fff;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 50%;

  left: ${({ left }) => left};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme, indicatorColor }) => indicatorColor || theme.white};
  transform: translate(-50%, -50%);
`;

interface IInfoPopupProps {
  left: string;
  marginLeft?: number;
}

export const InfoPopup = styled.div<IInfoPopupProps>`
  position: absolute;
  z-index: 500;
  padding: 0 10px;
  box-shadow: 0 4px 10px 0 var(--black-9);
  border-radius: 3px;
  background-color: ${({ theme }) => theme.blue};
  transform: translateX(-50%);
  top: -51px;
  left: ${({ left }) => left};
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.white};
  white-space: nowrap;
  margin-left: ${({ marginLeft = 0 }) => `${marginLeft}px`};
  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: ${({ marginLeft = 0 }) => `${marginLeft * -1 - 5}px`};
    z-index: 502;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid ${({ theme }) => theme.blue};
  }
`;

export const IconContainer = styled.div`
  position: relative;
  width: 100%;
  height: 32px;
`;
