import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CertificateIcon } from 'assets/images/icons/financing-landing-page/mortgage-certificate-info-message-icon.svg';

import styled, { ThemeContext } from 'styled-components';
import { device } from '../../../../../../../style/theme';
import Icon from '../../../../../../common/components/ui/icon';

const Container = styled.div`
  border: 1px solid rgba(213, 227, 204, 1);
  opacity: 1;
  background-color: rgba(242, 245, 247, 1);
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 3px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};

  i {
    min-width: 22px;
  }

  @media ${device.tablet} {
    margin: 0 12px 12px;
  }
`;

const InfoMessage: FC = () => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <Icon
        icon={CertificateIcon}
        width={22}
        height={24}
        color={themeContext.blue}
      />
      {t(
        'Ihre Anfrage zur Prüfung und Ausstellung eines Hypothekenzertifikats wurde erfolgreich an Ihren persönlichen Finanzierungsberater weitergeleitet. Dieser wird sich umgehend mit Ihnen in Verbindung setzen. Das freigegebene Hypothekenzertifikat wird Ihnen anschließend von Ihrem Berater ausgestellt.'
      )}
    </Container>
  );
};

export { InfoMessage };
