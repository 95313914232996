import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormError } from '../../interface';
import { Feature } from '../../../../services/mapbox/interfaces';
import { IMapSettings, IMapPin } from '../../../map/interfaces';
import { IFinancingForm } from '../interface';
import { Europe } from '../../../map/constants/points-of-interest';
import { defaultMapSettings } from '../../../map/constants';
import { FinancingProposition, FinancingType } from '../../../../generated';

interface IVonPollPropertyData {
  propertyId: number;
  purchasePrice?: number | null;
  postCode?: string | null;
  propertyType?: string | null;
  livingArea?: string | null;
  buildingYear?: string | null;
  images: {
    id: number;
    link: string;
    alt: string;
    group: string;
    width: number;
    height: number;
  }[];
}

interface ISuggestedProperty {
  purchasePrice?: number;
  propertyId?: number;
}

interface IState {
  isOpenSourceEmailNotification: boolean;
  isFinancingOpen: boolean;
  isAuthFlow: boolean;
  isRegisterFormOpen: boolean;
  isForgotPassword: boolean;
  isLoginFormOpen: boolean;
  isPersonalFinancing: boolean;
  isLoading: boolean;
  isLoadingCreating: boolean;
  isDataFromTemporary: boolean;
  isConfirmEmailSent: boolean;
  isHighlight: boolean;
  financingType: FinancingType | null;
  activeStep: number;
  errors?: FormError;
  userInput?: Partial<IFinancingForm>;
  isRedirect?: boolean;
  selectedAddress?: Feature;
  selectedCountryCode?: string;
  relatedCountry: string;
  map: {
    viewport: Record<string, unknown>;
    settings: IMapSettings;
    viewportCheckpoint?: Record<string, unknown>;
    markersCheckpoint?: IMapPin[];
    settingsCheckpoint?: IMapSettings;
  };
  // financing portal
  variantIndex?: number;
  isCompareVariantsModalOpen: boolean;
  hiddenVariants: number[];
  willBeHiddenVariant?: number;
  // isPurchasePriceExceeded: boolean;
  // isPurchasePriceExceedsBudgetModal: boolean;
  // isEditBudgetModal: boolean;
  // isAdjustPurchasePriceToBudgetModal: boolean;

  editWidget: {
    userInputFinancingCalculation?: Partial<IFinancingForm>;
    calculateDebitRateCommitment?: string | null;
    calculateFinancingPropositions?: FinancingProposition[];
    isLoadingFinancingCalculation: boolean;
    isLoadingUpdating: boolean;
    isEditFinancingVariantNameOpen: boolean;
    isDirtyForm: boolean;
    // dirty form logic
    renderIndex: number;
    isOpenMobileEditWidget: boolean;
    isUpdatingOverdueFinancingPropositions: boolean;
  };
  isLoadingSuggestedProperty: boolean;
  suggestedProperties: ISuggestedProperty;

  vonPollPropertyData?: IVonPollPropertyData;
  portalGallerySettings: {
    isOpenModal: boolean;
    activeSlide: number;
  };
}

const initialState: IState = {
  isOpenSourceEmailNotification: false,
  isFinancingOpen: false,
  isAuthFlow: false,
  isRegisterFormOpen: false,
  isForgotPassword: false,
  isLoginFormOpen: false,
  isPersonalFinancing: false,
  isDataFromTemporary: false,
  isConfirmEmailSent: false,
  isLoading: false,
  isLoadingCreating: false,
  isHighlight: false,
  relatedCountry: 'germany',
  financingType: null,
  activeStep: 0,
  isRedirect: false,
  map: {
    viewport: { ...Europe },
    settings: defaultMapSettings,
  },
  // financing portal
  variantIndex: undefined,
  isCompareVariantsModalOpen: false,
  hiddenVariants: [],
  willBeHiddenVariant: undefined,
  // isPurchasePriceExceeded: false,
  // isPurchasePriceExceedsBudgetModal: false,
  // isEditBudgetModal: false,
  // isAdjustPurchasePriceToBudgetModal: false,

  editWidget: {
    calculateDebitRateCommitment: undefined,
    calculateFinancingPropositions: undefined,
    isLoadingFinancingCalculation: false,
    isLoadingUpdating: false,
    isEditFinancingVariantNameOpen: false,
    isDirtyForm: false,
    renderIndex: 0,
    isOpenMobileEditWidget: false,
    isUpdatingOverdueFinancingPropositions: false,
  },
  isLoadingSuggestedProperty: false,
  suggestedProperties: {
    purchasePrice: undefined,
    propertyId: undefined,
  },
  vonPollPropertyData: undefined,
  portalGallerySettings: {
    isOpenModal: false,
    activeSlide: 1,
  },
};

export const financingSlice = createSlice({
  name: 'financing',
  initialState,
  reducers: {
    toggleSourceEmailNotification(state, action: PayloadAction<boolean>) {
      state.isOpenSourceEmailNotification = action.payload;
    },
    openFinancingAction(state) {
      state.isFinancingOpen = true;
    },
    setFinancingType(state, action: PayloadAction<FinancingType>) {
      state.financingType = action.payload;
    },
    closeFinancingModal(state) {
      state.isFinancingOpen = false;
    },
    resetFinancing(state, action: PayloadAction<Partial<IState> | undefined>) {
      return { ...initialState, ...action?.payload };
    },
    nextStepAction(state, action: PayloadAction<number | undefined>) {
      if (typeof action.payload === 'number') {
        state.activeStep = action.payload;
      } else {
        state.activeStep += 1;
      }
    },
    prevStepAction(state, action: PayloadAction<number | undefined>) {
      if (typeof action.payload === 'number') {
        state.activeStep = action.payload;
      } else {
        state.activeStep -= 1;
      }
    },
    updateFormStateAction(
      state,
      action: PayloadAction<Partial<IFinancingForm> | undefined>
    ) {
      state.userInput = {
        ...state.userInput,
        ...action.payload,
      };
    },
    setSelectedAddressAction(state, action: PayloadAction<Feature>) {
      state.selectedAddress = action.payload;
    },
    setRedirect(state, action: PayloadAction<boolean>) {
      state.isRedirect = action.payload;
    },
    setIsDataFromTemporary(state, action: PayloadAction<boolean>) {
      state.isDataFromTemporary = action.payload;
    },
    setIsConfirmEmailSent(state, action: PayloadAction<boolean>) {
      state.isConfirmEmailSent = action.payload;
    },
    setSelectedCountryCode(state, action: PayloadAction<string>) {
      state.selectedCountryCode = action.payload;
    },
    toggleAuthFlow(state, action: PayloadAction<boolean>) {
      state.isAuthFlow = action.payload;
    },
    toggleForgotPasswordForm(state, action: PayloadAction<boolean>) {
      state.isForgotPassword = action.payload;
    },
    toggleRegisterForm(state, action: PayloadAction<boolean>) {
      state.isRegisterFormOpen = action.payload;
    },
    toggleLoginForm(state, action: PayloadAction<boolean>) {
      state.isLoginFormOpen = action.payload;
    },
    togglePersonalFinancingForm(state, action: PayloadAction<boolean>) {
      state.isPersonalFinancing = action.payload;
    },
    toggleLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    toggleLoadingCreating(state, action: PayloadAction<boolean>) {
      state.isLoadingCreating = action.payload;
    },
    toggleLoadingFinancingCalculation(state, action: PayloadAction<boolean>) {
      state.editWidget.isLoadingFinancingCalculation = action.payload;
    },
    toggleIsHighlight(state, action: PayloadAction<boolean>) {
      state.isHighlight = action.payload;
    },
    toggleModalCompareVariants(state, action: PayloadAction<boolean>) {
      state.isCompareVariantsModalOpen = action.payload;
    },
    setErrors(state, action: PayloadAction<FormError>) {
      state.errors = action.payload;
    },
    clearErrors(state) {
      delete state.errors;
    },
    changeMapViewport(state, action) {
      state.map.viewportCheckpoint = state.map.viewport;
      state.map.viewport = { ...state.map.viewport, ...action.payload };
    },
    setMapSettings(state, action: PayloadAction<Partial<IMapSettings>>) {
      state.map.settingsCheckpoint = state.map.settings;
      state.map.settings = {
        ...state.map.settings,
        ...action.payload,
      };
    },
    // financing portal
    updateFinancingCalculationStateAction(
      state,
      action: PayloadAction<Partial<IFinancingForm> | undefined>
    ) {
      state.editWidget.userInputFinancingCalculation = {
        ...state.editWidget.userInputFinancingCalculation,
        ...action.payload,
      };
    },
    cleanFinancingCalculationStateAction(state) {
      state.editWidget.userInputFinancingCalculation = {};
    },
    setCalculateDebitRateCommitment(
      state,
      action: PayloadAction<string | undefined | null>
    ) {
      state.editWidget.calculateDebitRateCommitment = action.payload;
    },
    setCalculateFinancingPropositions(
      state,
      action: PayloadAction<FinancingProposition[] | undefined>
    ) {
      state.editWidget.calculateFinancingPropositions = action.payload;
    },
    setVariantIndex(state, action: PayloadAction<number>) {
      state.variantIndex = action.payload;
    },
    toggleLoadingUpdating(state, action: PayloadAction<boolean>) {
      state.editWidget.isLoadingUpdating = action.payload;
    },
    toggleEditFinancingVariantName(state, action: PayloadAction<boolean>) {
      state.editWidget.isEditFinancingVariantNameOpen = action.payload;
    },
    toggleMobileEditWidget(state, action: PayloadAction<boolean>) {
      state.editWidget.isOpenMobileEditWidget = action.payload;
    },
    toggleUpdatingOverdueFinancingPropositions(
      state,
      action: PayloadAction<boolean>
    ) {
      state.editWidget.isUpdatingOverdueFinancingPropositions = action.payload;
    },
    setHiddenVariants(state, action: PayloadAction<number[]>) {
      state.hiddenVariants = action.payload;
    },
    setWillBeHiddenVariant(state, action: PayloadAction<number | undefined>) {
      state.willBeHiddenVariant = action.payload;
    },
    setDirtyEditForm(state, action: PayloadAction<boolean>) {
      state.editWidget.isDirtyForm = action.payload;
    },
    setRenderIndex(state, action: PayloadAction<number>) {
      state.editWidget.renderIndex = action.payload;
    },
    setVonPollPropertyData(
      state,
      action: PayloadAction<IVonPollPropertyData | undefined>
    ) {
      state.vonPollPropertyData = action.payload;
    },
    toggleLoadingSuggestedProperty(state, action: PayloadAction<boolean>) {
      state.isLoadingSuggestedProperty = action.payload;
    },
    setSuggestionProperty(state, action: PayloadAction<ISuggestedProperty>) {
      state.suggestedProperties = action.payload;
    },
    toggleFinancingPortalModalGallery(state, action: PayloadAction<boolean>) {
      state.portalGallerySettings.isOpenModal = action.payload;
    },
    setFinancingPortalGalleryActiveSlide(state, action: PayloadAction<number>) {
      state.portalGallerySettings.activeSlide = action.payload;
    },
    // setPurchasePriceExceeded(state, action: PayloadAction<boolean>) {
    //   state.isPurchasePriceExceeded = action.payload;
    // },
    // togglePurchasePriceExceedsBudgetModal(
    //   state,
    //   action: PayloadAction<boolean>
    // ) {
    //   state.isPurchasePriceExceedsBudgetModal = action.payload;
    // },
    // toggleEditBudgetModal(state, action: PayloadAction<boolean>) {
    //   state.isEditBudgetModal = action.payload;
    // },
    // toggleAdjustPurchasePriceToBudgetModal(
    //   state,
    //   action: PayloadAction<boolean>
    // ) {
    //   state.isAdjustPurchasePriceToBudgetModal = action.payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const {
  openFinancingAction,
  setFinancingType,
  closeFinancingModal,
  nextStepAction,
  prevStepAction,
  updateFormStateAction,
  setRedirect,
  setSelectedAddressAction,
  setIsDataFromTemporary,
  setSelectedCountryCode,
  setIsConfirmEmailSent,
  cleanFinancingCalculationStateAction,
  setCalculateDebitRateCommitment,
  setCalculateFinancingPropositions,
  toggleLoading,
  updateFinancingCalculationStateAction,
  toggleLoadingCreating,
  toggleLoadingFinancingCalculation,
  toggleAuthFlow,
  toggleLoginForm,
  togglePersonalFinancingForm,
  toggleForgotPasswordForm,
  toggleRegisterForm,
  toggleIsHighlight,
  setErrors,
  clearErrors,
  resetFinancing,
  setMapSettings,
  changeMapViewport,
  setVariantIndex,
  toggleLoadingUpdating,
  toggleEditFinancingVariantName,
  toggleModalCompareVariants,
  toggleMobileEditWidget,
  toggleUpdatingOverdueFinancingPropositions,
  setHiddenVariants,
  setWillBeHiddenVariant,
  setVonPollPropertyData,
  setSuggestionProperty,
  toggleLoadingSuggestedProperty,
  // setPurchasePriceExceeded,
  // togglePurchasePriceExceedsBudgetModal,
  // toggleEditBudgetModal,
  // toggleAdjustPurchasePriceToBudgetModal,
  setDirtyEditForm,
  setRenderIndex,
  toggleFinancingPortalModalGallery,
  setFinancingPortalGalleryActiveSlide,
  toggleSourceEmailNotification,
} = financingSlice.actions;

export const { name, reducer } = financingSlice;

export default financingSlice;
