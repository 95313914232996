import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;

  & > div {
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;

export const Header = styled.div`
  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 16px;
`;
