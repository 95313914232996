import { useTranslation } from 'react-i18next';

import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

import { defaultTheme } from '../../../../style/theme';
import { getInfrastructureMeta } from '../../../common/utils/infrastructure-meta';
import { getPercent } from '../../../common/components/infrastructure/infrastructure-card/infrastructure-components';
import { ILocationScore } from '../../../common/interfaces';

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '3mm',
    paddingBottom: '3mm',
    paddingLeft: '4mm',
    paddingRight: '4mm',
    border: '1px',
    borderColor: '#d9d9d9',
    borderRadius: '1mm',
    backgroundColor: '#fff',
    marginBottom: '3mm',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: '4mm',
    maxWidth: '8mm',
    maxHeight: '8mm',
  },
  nameWrapper: {
    display: 'flex',
  },
  nameText: {
    marginBottom: '2mm',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: 1.14,
    letterSpacing: '0.5px',
    color: defaultTheme.blue,
  },
  conditionText: {
    opacity: 0.5,
    fontSize: '8px',
    fontWeight: 'bold',
    lineHeight: 1.5,
    letterSpacing: '0.5px',
    color: defaultTheme.blue,
    textTransform: 'uppercase',
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ratingText: {
    fontSize: '5mm',
    marginBottom: '2mm',
  },
  ratingDotsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  ratingDot: {
    width: '4mm',
    height: '4mm',
    borderRadius: '50%',
    marginLeft: '1mm',
    marginRight: '1mm',
    overflow: 'hidden',
    position: 'relative',
  },
  ratingDotLeft: {
    height: '4mm',
    zIndex: 0,
    borderWidth: '1px',
  },
  ratingDotRight: {
    width: '4mm',
    height: '4mm',
    zIndex: 1000,
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'transparent',
    borderColor: '#d9d9d9',
    borderWidth: '1px',
    borderRadius: '50%',
  },
});

const InfrastructureCard = ({
  name,
  score,
}: {
  name: string;
  score: ILocationScore;
}): JSX.Element => {
  const { title: generatedTitle, icon } = getInfrastructureMeta(name);
  const { t } = useTranslation();
  return (
    <View style={styles.wrapper}>
      <View style={styles.left}>
        <View style={styles.icon}>
          <Image src={icon} />
        </View>
        <View style={styles.nameWrapper}>
          <Text style={styles.nameText}>{t(generatedTitle)}</Text>
          <Text style={styles.conditionText}>{`Lage: ${t(score.label)}`}</Text>
        </View>
      </View>
      <View style={styles.right}>
        <View>
          <Text style={{ ...styles.ratingText, color: score.color }}>
            {score.score}
          </Text>
        </View>
        <View style={styles.ratingDotsWrapper}>
          {[...Array.from({ length: 5 })].map((_, index: number) => {
            const percent = getPercent(score.score, index + 1);
            return (
              <View
                style={styles.ratingDot}
                key={`${score.label}-${score.score}-${index}`}
              >
                <View style={styles.ratingDotRight} />
                {percent > 0 && (
                  <View
                    style={{
                      ...styles.ratingDotLeft,
                      width: `${percent}%`,
                      backgroundColor: score.color,
                      borderColor: score.color,
                    }}
                  />
                )}
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};

export { InfrastructureCard };
