import styled from 'styled-components';
import { device } from '../../../../../../../style/theme';

export const Container = styled.div<{
  isHighlight?: boolean;
  isIframe?: boolean;
  isCentered?: boolean;
}>`
  position: ${({ isCentered }) => (isCentered ? 'static' : 'absolute')};
  top: 48px;
  right: 48px;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ isHighlight, isIframe }) =>
    isIframe
      ? 'none'
      : isHighlight
      ? '0 8px 30px 0 rgba(78, 115, 245, 0.5)'
      : '0 8px 30px 0 rgba(0, 0, 0, 0.09)'};
  z-index: 1;

  .financing-form-container {
    padding: 0;
  }

  & h3 {
    font-size: 20px;
    text-align: center;
  }
  & h4 {
    text-align: center;
  }

  @media ${device.tablet} {
    position: static;
    margin: 8px auto 0;
    //max-width: 464px;
    width: 100%;
  }
`;
