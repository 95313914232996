import { useCallback } from 'react';

import { useAppSelector } from '../../../common/hooks';
import { useCreateSearchProfileMutation } from '../../../../services/graphql/enhanced';
import {
  CreateSearchProfileInput,
  CreateSearchProfileMutation,
} from '../../../../generated';

interface ICreateSP {
  spInput: CreateSearchProfileInput;
  ownerId: string;
}

type TReturn = {
  onCreateSearchProfile: (
    input: ICreateSP
  ) => Promise<CreateSearchProfileMutation>;
};

const useCreateSP = (): TReturn => {
  const marketingParametersBase = useAppSelector(
    (state) => state.auth.marketingParameters
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const marketingParameters = isIframe
    ? `${marketingParametersBase}de/merkliste`
    : marketingParametersBase;

  const [createSearchProfile] = useCreateSearchProfileMutation();

  const onCreateSearchProfile = useCallback(
    async ({ spInput, ownerId }: ICreateSP) => {
      return createSearchProfile({
        input: {
          ...spInput,
          owner: ownerId,
          marketingParameters:
            marketingParameters ??
            `${window?.location?.pathname || ''}${
              window?.location?.search || ''
            }`,
        },
      }).unwrap();
    },
    [createSearchProfile, marketingParameters]
  );
  return { onCreateSearchProfile };
};

export { useCreateSP };
