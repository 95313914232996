import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as Share } from 'assets/streamline-light/interface-essential/share/share.svg';

import { useLazyInvitesQuery } from '../../../../../services/graphql/enhanced';
import { OutsideClickHandler } from '../../../../common/components/outside-click-handler';
import { IconButton } from '../../../../common/components/ui/buttons';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { PropertyInvitation } from '../../../constants';
import {
  setModifyCollaborator,
  toggleEditModal,
  toggleInvitationModal,
} from '../../../redux/propertyCollaboratorsSlice';
import { SharePropertyDropdownMenu } from './share-property-dropdown-menu';
import { SharePropertyModalEditCollaborators } from './share-property-modal-edit-collaborators';
import SharePropertyModalInvitation from './share-property-modal-invitation';

const ShareActionWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const ShareActionBase = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const themeContext = useContext(ThemeContext);
  const { propertyId } = useParams<{ propertyId: string }>();

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [initialOpen, setInitialOpen] = useState(false);

  const [fetchInvites, { data: invites, isLoading, isFetching }] =
    useLazyInvitesQuery();

  const isInvitationModalOpen = useAppSelector(
    (state) => state.propertyCollaborators.isInvitationModalOpen
  );
  const isEditModalOpen = useAppSelector(
    (state) => state.propertyCollaborators.isEditModalOpen
  );

  const onShareButtonClick = () => {
    fetchInvites({ propertyId });
    setInitialOpen(true);

    // if (invites?.invites?.length) {
    //   setIsDropdownOpen(!isDropdownOpen);
    //   return;
    // }

    // dispatch(toggleInvitationModal(!isInvitationModalOpen));
  };

  const closeDropdown = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const onDropdownOptionClick = useCallback(
    (option: Partial<PropertyInvitation>) => () => {
      setIsDropdownOpen(false);
      if (option === PropertyInvitation.Edit) {
        return dispatch(toggleEditModal(true));
      }
      return dispatch(toggleInvitationModal(true));
    },
    [dispatch]
  );

  const closeInvitationModal = useCallback(
    () =>
      batch(() => {
        dispatch(toggleInvitationModal(false));
        dispatch(setModifyCollaborator(null));
      }),
    [dispatch]
  );

  const closeEditModal = useCallback(
    () => dispatch(toggleEditModal(false)),
    [dispatch]
  );

  useEffect(() => {
    if (initialOpen && !isFetching) {
      if (invites?.invites?.length) {
        setIsDropdownOpen(true);
      } else {
        dispatch(toggleInvitationModal(true));
      }
      setInitialOpen(false);
    }
  }, [invites, initialOpen, isFetching, dispatch]);

  return (
    <ShareActionWrapper>
      <OutsideClickHandler onOutsideClick={closeDropdown}>
        <IconButton
          icon={Share}
          {...((isInvitationModalOpen || isDropdownOpen) && {
            buttonColor: themeContext.ctaBlueHover,
          })}
          onClick={onShareButtonClick}
          isLoading={isLoading}
        />

        {isDropdownOpen && (
          <SharePropertyDropdownMenu onOptionClick={onDropdownOptionClick} />
        )}
      </OutsideClickHandler>

      <SharePropertyModalInvitation
        isOpen={isInvitationModalOpen}
        onCloseModal={closeInvitationModal}
        existingInvitations={invites?.invites ?? []}
      />

      <SharePropertyModalEditCollaborators
        isOpen={isEditModalOpen}
        onCloseModal={closeEditModal}
        existingInvitations={invites?.invites ?? []}
      />
    </ShareActionWrapper>
  );
};

const ShareAction = memo(ShareActionBase);

export { ShareAction };
