import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const RoomIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={
          'M19.473 4.5H21.5l2 12H.5l2-12h2.278m18.722 12V21a1.5 1.5 0 0 1-1.5 1.5H2A1.5 1.5 0 0 1 .5 21v-4.5m1.333-8h20.334'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={
          'M10.552 6.01a.5.5 0 0 1-.5.49H5.074a.5.5 0 0 1-.5-.569l.278-2a.5.5 0 0 1 .5-.431h4.742a.5.5 0 0 1 .5.51zm3.148 0a.5.5 0 0 0 .5.49h4.977a.5.5 0 0 0 .5-.569l-.278-2A.5.5 0 0 0 18.9 3.5h-4.743a.5.5 0 0 0-.5.51z'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={
          'M2.5 4.5V2A1.5 1.5 0 0 1 4 .5h16A1.5 1.5 0 0 1 21.5 2v2.5m-18 18v1m17-1v1M10.605 4.614h3.042'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { RoomIcon };
