import { FC } from 'react';
import {
  CmsPage,
  useCmsPagesQuery,
  useGetContentPageQuery,
} from '../../../generated';
import { ContentWithHtml } from '../components/content-with-html';
import { CONTENT_PAGE_NAME } from '../constants';

const ContentPage: FC = () => {
  // Get content page by name
  const { contentPage } = useCmsPagesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      contentPage: data?.cmsPages?.find(
        (page) => page.name === CONTENT_PAGE_NAME
      ),
    }),
  });

  // Get page blocks
  const { blocks } = useGetContentPageQuery(
    {
      id: contentPage?._id ?? '',
    },
    {
      selectFromResult: ({ data }) => data?.cmsPage as CmsPage,
    }
  );

  return <ContentWithHtml blocks={blocks} />;
};

export { ContentPage };
