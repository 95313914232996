import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { siteMap } from '../../../../../routes/site-map';
import { device } from '../../../../../style/theme';
import { useGetPortalType } from '../../../../landing/hooks/useGetPortalType';
import useGetLocalization from '../../../../localization/get-localization';
import LngLink from '../../../../localization/lng-link';
import { useAppSelector } from '../../../hooks';
import NavigationDropdown from './navigation-dropdown';
import { LinkOption } from './navigation-types';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: 121px;

  @media ${device.bigScreen} {
    margin-left: 15px;
  }

  @media ${device.laptop} {
    margin-left: 10px;
  }
`;

interface LinkButtonProps {
  color?: string;
  $isActive?: boolean;
}

const linkStyles = css<LinkButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, sans-serif;
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  letter-spacing: 0.05em;
  text-transform: capitalize !important;
  color: ${(props) => props.color ?? '#fff'};
  margin: 0 15px;

  @media ${device.bigScreen} {
    margin: 0 10px;
  }

  @media ${device.laptop} {
    margin: 0 5px;
    font-size: 12px;
  }

  &:before {
    position: absolute;
    bottom: -7px;
    content: '';
    height: 1.5px;
    width: 0;
    left: 0;
    background: ${(props) => props.color ?? '#fff'};
    transition: width 0.3s;
  }

  text-decoration: none;
  cursor: pointer;
  &.active,
  &:hover {
    &:before {
      width: 100%;
    }
  }
  .icon {
    margin-right: 8px;
  }

  ${(props) =>
    props.$isActive &&
    `
    &:before {
      // transition: none;
      width: 100%;
    }
  `}
`;

const LinkButton = styled(LngLink)<LinkButtonProps>`
  ${linkStyles}
`;

const ExternalLink = styled.a<LinkButtonProps>`
  ${linkStyles}
`;

interface IProps {
  setActiveMenu: (newActiveMenu: LinkOption) => void;
  deactivateAuthMenu: () => void;
  activeMenu: LinkOption;
  isScrolling?: boolean;
}

const Navigation = ({
  setActiveMenu,
  activeMenu,
  isScrolling,
  deactivateAuthMenu,
}: IProps): JSX.Element => {
  const { t } = useTranslation();

  const { isOwner, isBuyer, isSeller, isFinancing } = useGetPortalType();

  const user = useAppSelector((state) => state.auth.user);

  const isCHSite = useGetLocalization() === 'de-ch';
  const isATSite = useGetLocalization() === 'de-at';

  // onOffice marketplace iframe URL params
  const urlParams = new URLSearchParams(window.location.search);
  const brokerId = urlParams.get('userid') || '';
  const propertyOnOfficeId = urlParams.get('EstateId') || '';

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  return (
    <>
      <Container>
        {!isBrokerView && (
          <LinkButton
            absolute
            to={siteMap.OwnerLandingPage.path}
            onMouseOver={() => {
              deactivateAuthMenu();
              setActiveMenu('evaluate');
            }}
            color={isScrolling ? 'white' : '#00305E'}
            $isActive={activeMenu === 'evaluate' || isOwner}
          >
            {t('header.navigation.rating').toLowerCase()}
          </LinkButton>
        )}
        {/* {!isBrokerView && !isCHSite && !isATSite && ( */}
        {!isBrokerView && (
          <LinkButton
            absolute
            onMouseOver={() => {
              deactivateAuthMenu();
              setActiveMenu('sell');
            }}
            to={
              brokerId && propertyOnOfficeId
                ? `${siteMap.SellerPropertiesPage.pathWithoutParams}?userid=${brokerId}&EstateId=${propertyOnOfficeId}`
                : siteMap.SellerLandingPage.path
            }
            color={isScrolling ? 'white' : '#00305E'}
            $isActive={activeMenu === 'sell' || isSeller}
          >
            {t('header.navigation.seller').toLowerCase()}
          </LinkButton>
        )}
        {!isBrokerView && (
          <>
            <LinkButton
              absolute
              $isActive={activeMenu === 'buy' || isBuyer}
              to={`${siteMap.BuyerLandingPage.path}?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu`}
              color={isScrolling ? 'white' : '#00305E'}
              onMouseOver={() => {
                deactivateAuthMenu();
                setActiveMenu('buy');
              }}
            >
              {t('header.navigation.buyer').toLowerCase()}
            </LinkButton>
            {/* {!isCHSite && !isATSite && ( */}
            <LinkButton
              color={isScrolling ? 'white' : '#00305E'}
              absolute
              $isActive={activeMenu === 'finance' || isFinancing}
              to={`${siteMap.FinancingLandingPage.path}`}
              onMouseOver={() => {
                deactivateAuthMenu();
                setActiveMenu('finance');
              }}
            >
              {t('header.navigation.financing').toLowerCase()}
            </LinkButton>
            {/* )} */}
            {/* {!isCHSite && !isATSite && ( */}
            <ExternalLink
              color={isScrolling ? 'white' : '#00305E'}
              $isActive={activeMenu === 'location'}
              href={
                'https://www.von-poll.com/de/unternehmen/standorte?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu'
              }
              onMouseOver={() => {
                deactivateAuthMenu();
                setActiveMenu('location');
              }}
            >
              {t('header.navigation.location').toLowerCase()}
            </ExternalLink>
            {/* )} */}
            {/* {!isCHSite && !isATSite && ( */}
            <ExternalLink
              color={isScrolling ? 'white' : '#00305E'}
              $isActive={activeMenu === 'company'}
              href={'https://www.von-poll.com/de/unternehmen/ueber-uns'}
              onMouseOver={() => {
                deactivateAuthMenu();
                setActiveMenu('company');
              }}
            >
              {t('header.navigation.company').toLowerCase()}
            </ExternalLink>
            {/* )} */}
          </>
        )}
      </Container>
      <NavigationDropdown
        activeMenu={activeMenu}
        setIsOpen={() => setActiveMenu('')}
      />
    </>
  );
};

export { Navigation };
