import { Chart } from 'chart.js';

export const plugin = {
  beforeDraw: (chart: Chart) => {
    const { ctx, scales, config, data } = chart;
    const { y: yAxis } = scales;

    const { backgroundColor, step } = (
      config.options as {
        chartArea: {
          backgroundColor: string;
          step: number;
        };
      }
    )?.chartArea;

    if (step) {
      const oddGridLines = data.datasets[0]?.data.map((dataPoint, index) => {
        // Get the pixel coordinates of the data point
        const meta = chart.getDatasetMeta(0);
        const point = meta.data[index];

        return point.x;
      });

      if (backgroundColor && oddGridLines.length > 1) {
        ctx.save();

        const width = (oddGridLines[1] - oddGridLines[0]) / 2;

        oddGridLines.forEach((tick) => {
          const xCoord = tick - width / 2;
          ctx.fillStyle = backgroundColor;

          ctx.fillRect(xCoord, yAxis.top, width, yAxis.bottom - yAxis.top);
        });

        ctx.restore();
      }
    }
  },
};
