import styled from 'styled-components';
import { Headline3 } from '../../../../common/components/ui/typography';

export const Container = styled.div``;

export const SectionTitle = styled(Headline3)`
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 8px;

  &:nth-of-type(2) {
    margin-top: 12px;
  }
`;

export const UploadedFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
