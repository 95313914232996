import { ReactNode } from 'react';
import styled from 'styled-components';

interface IModalFormColumn {
  className?: string;
  flex?: string;
  children: ReactNode;
}

const Column = styled.div<{ $flex?: string }>`
  flex: ${({ $flex }) => $flex || '0 0 50%'};
  padding: 0 6px;
  margin: 0 0 12px;
  line-height: 0;
  min-width: 0;
`;

const FormColumn = ({
  className,
  flex,
  children,
}: IModalFormColumn): JSX.Element => {
  return (
    <Column $flex={flex} className={className}>
      {children}
    </Column>
  );
};

export { FormColumn };
