import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../common/hooks';
import {
  closeVWModal,
  resetVW,
  setErrors,
  setIsDataFromTemporary,
  setRedirect,
  toggleLoading,
} from '../redux/valuationWizardV2Slice';
import { RegistrationBy } from '../../../../generated';
import { siteMap } from '../../../../routes/site-map';
import { FormError } from '../../interface';
import {
  useConvertPropertyMutation,
  useCreatePropertyDossierMutation,
  usePropertiesQuery,
} from '../../../../services/graphql/enhanced';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import { MODAL_ANIMATION_DURATION } from '../../../common/components/modal/modal';

const useConvertProperty = (): void => {
  const dispatch = useDispatch();
  const lngHPush = useLngHistoryPush();

  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const owner = useAppSelector((state) => state.auth.user?._id);
  const registrationBy = useAppSelector(
    (state) => state.auth.user?.registrationBy
  );
  const isRedirect = useAppSelector(
    (state) => state.valuationWizardV2.isRedirect
  );

  const { data: userProperties, isLoading: areUserPropertiesLoading } =
    usePropertiesQuery({ ownerId: owner }, { skip: !owner || isAnonymousUser });
  const [convertProperty] = useConvertPropertyMutation();
  const [createPropertyDossier] = useCreatePropertyDossierMutation();

  useEffect(() => {
    const convertAsync = async () => {
      if (!areUserPropertiesLoading) {
        try {
          dispatch(toggleLoading(true));
          dispatch(setIsDataFromTemporary(true));
          if (registrationBy === RegistrationBy.Mobile) {
            const propertyResponse = await convertProperty({
              ownerId: owner as string,
            }).unwrap();

            if (propertyResponse?.convertProperty?._id) {
              const createPropertyDossierResponse = await createPropertyDossier(
                {
                  input: {
                    propertyId: propertyResponse?.convertProperty?._id,
                  },
                }
              ).unwrap();

              if (createPropertyDossierResponse.createPropertyDossier?._id) {
                lngHPush(
                  `${siteMap.PropertyPage.pathWithoutParams}/${createPropertyDossierResponse.createPropertyDossier._id}`
                );
              }
            }
          }

          dispatch(closeVWModal());
          dispatch(setRedirect(false));
          setTimeout(() => {
            dispatch(resetVW());
          }, MODAL_ANIMATION_DURATION);
        } catch (error) {
          dispatch(setErrors(error as FormError));
        } finally {
          dispatch(toggleLoading(false));
        }
      }
    };
    if (isRedirect) {
      convertAsync();
    }
  }, [
    dispatch,
    isRedirect,
    convertProperty,
    owner,
    createPropertyDossier,
    lngHPush,
    registrationBy,
    areUserPropertiesLoading,
    userProperties?.properties,
  ]);
};

export { useConvertProperty };
