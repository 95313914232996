import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TimifyWidget } from '../../../../../../timify/components/widget';
import { usePersonalLogin } from '../../../../hooks/callback-hooks/personal-valuation/usePersonalLogin';
import { useSubmitPersonalEVForm } from '../../../../hooks/callback-hooks/personal-valuation/useSubmitPersonalEVForm';
import {
  LoaderContainer,
  Title,
} from '../../../../../components/fullscreen-modal/auth-container/styles';
import { LoginFormWizard } from '../../../../../../auth/components/forms/login-form-wizard';
import { ConfirmEmail } from '../../../../../../auth/components/confirm-email';
import { GlobalLoader } from '../../../../../../common/components/ui/loaders/global-loader';
import { UserState } from '../../../../../../../generated';

const PersonalLoginContainer: FC = () => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<null | unknown>(null);
  const [reservationSuccess, setReservationSuccess] = useState(false);
  const [confirmEmailSent, setConfirmEmailSent] = useState(false);

  const { onSubmit } = useSubmitPersonalEVForm();
  const {
    timifyParams,
    userState,
    onLoginSubmit,
    onOpenForgotPassword,
    isLoginFormLoading,
    isIframe,
  } = usePersonalLogin({
    setErrors,
    onSuccess: onSubmit,
    reservationSuccess,
    setConfirmEmailSent,
  });

  const isLoginForm = useMemo(
    () =>
      !timifyParams &&
      !confirmEmailSent &&
      !isLoginFormLoading &&
      userState === UserState.Unauthenticated,
    [confirmEmailSent, isLoginFormLoading, timifyParams, userState]
  );
  const isConfirmEmail = useMemo(
    () => confirmEmailSent && !isLoginFormLoading,
    [isLoginFormLoading, confirmEmailSent]
  );

  return (
    <>
      {isLoginFormLoading && (
        <LoaderContainer isiframe={isIframe}>
          <GlobalLoader />
        </LoaderContainer>
      )}
      {isLoginForm && (
        <>
          <Title>{t('header.authentication.login')}</Title>
          <LoginFormWizard
            isRedesign
            isFullWidthButton
            onSubmit={onLoginSubmit}
            errors={errors}
            onForgotPasswordClick={onOpenForgotPassword}
          />
        </>
      )}
      {timifyParams && !isLoginFormLoading && (
        <TimifyWidget
          {...timifyParams}
          setReservationSuccess={setReservationSuccess}
        />
      )}
      {isConfirmEmail && (
        <ConfirmEmail
          title={t('modal.register.confirm-email.personal-valuation.title')}
          textContent={t(
            'modal.register.confirm-email.personal-valuation.content'
          )}
        />
      )}
    </>
  );
};

export { PersonalLoginContainer };
