import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {
  Form,
  Container,
  TooltipHeader,
  TooltipBack,
  TooltipAccept,
  TooltipActions,
} from './tooltip-styles';
import { useIsMobileSize } from '../../../../../hooks/useIsMobileSize';
import { OutsideClickHandler } from '../../../../outside-click-handler';
import { DeleteFileButton } from './uploaded-item-styles';
import { ReactComponent as Bin } from '../../../../../../../assets/streamline-light/interface-essential/delete/bin-2.svg';
import { hexToRGB } from '../../../../../utils/hex-to-rgb';

interface IProps {
  fileName: string;
  onDeleteFile: (fileName: string) => void;
}

const TooltipBase: FC<IProps> = ({ fileName, onDeleteFile }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [offset, setOffset] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });
  const themeContext = useContext(ThemeContext);
  const iconStyle = useMemo(
    () => ({
      margin: 0,
    }),
    []
  );

  const isMobileSize = useIsMobileSize();

  const handleOutsideClick = useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    const hide = () => setIsVisible(false);
    window.addEventListener('scroll', hide);

    return () => window.removeEventListener('scroll', hide);
  }, []);

  return (
    <Container
      role={'presentation'}
      onClick={(event) => {
        setOffset({
          top: event.clientY - 180,
          left: isMobileSize ? event.clientX - 240 : event.clientX - 190,
        });
      }}
    >
      {isVisible && (
        <OutsideClickHandler
          display="block"
          onOutsideClick={handleOutsideClick}
        >
          <Form offset={offset}>
            <TooltipHeader>
              {t('seller-preparation.tooltip.text')}
            </TooltipHeader>
            {/* <TooltipDescription */}
            {/*  content={t('selling-simulation.tooltip.close')} */}
            {/* /> */}
            <TooltipActions direction={'column'}>
              <TooltipBack onClick={handleOutsideClick}>
                {t('seller-preparation.tooltip.close')}
              </TooltipBack>
              <TooltipAccept onClick={() => onDeleteFile(fileName)}>
                {t('seller-preparation.tooltip.delete-file')}
              </TooltipAccept>
            </TooltipActions>
          </Form>
        </OutsideClickHandler>
      )}
      <DeleteFileButton
        label={''}
        onClick={() => setIsVisible(true)}
        icon={Bin}
        color={hexToRGB(themeContext.black, 0.5)}
        borderColor={themeContext.borderColor}
        iconStyle={iconStyle}
      />
    </Container>
  );
};

const DeleteFileTooltip = memo(TooltipBase);
export { DeleteFileTooltip };
