import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import styled from 'styled-components';

import sharePropertySignInImageSrc from '../../../assets/share-property-sign-in.png';
import { useAcceptInvitationMutation } from '../../../generated';
import { device } from '../../../style/theme';
import { GlobalError } from '../../common/components/form/error/global-error';
import { useAppSelector } from '../../common/hooks';
import { useIsMobileSize } from '../../common/hooks/useIsMobileSize';
import { NoAccess } from '../components/sign-in/no-acess';
import { SignInForm } from '../components/sign-in/sign-in-form';
import { useLngHistoryPush } from '../../localization/lng-history-push';
import { siteMap } from '../../../routes/site-map';

const Image = styled.img`
  display: block;
  max-height: calc(100vh - 64px - 68px);
  width: 100%;
  max-width: 100%;
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
`;

const Column = styled.div`
  flex: 1 0 50%;
  position: relative;
  @media ${device.tablet} {
    max-height: 100%;
    overflow: overlay;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 12px;
`;

const SharePropertySignInPage = (): JSX.Element => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const lngHPush = useLngHistoryPush();

  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);

  const { invitationId } = useParams<{ invitationId: string }>();

  const [acceptInvitationTrigger, { isLoading, error: invitationErrors }] =
    useAcceptInvitationMutation();

  useEffect(() => {
    const accept = async () => {
      try {
        const propertyIdResponse = await acceptInvitationTrigger({
          invitationId,
        }).unwrap();

        if (propertyIdResponse?.acceptInvitation) {
          lngHPush(
            `${siteMap.PropertyPage.pathWithoutParams}/${propertyIdResponse?.acceptInvitation}`
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (!isAnonymousUser) {
      accept();
    }
  }, [invitationId, isAnonymousUser]);

  return (
    <Container>
      <Column>
        <FormWrapper>
          {isLoading ? (
            <PuffLoader
              color="#002849"
              loading
              size={100}
              speedMultiplier={0.75}
            />
          ) : (
            <>
              {invitationErrors?.message?.split(':')[0] ===
              'Invalid invitation!' ? (
                <NoAccess />
              ) : invitationErrors ? (
                <GlobalError
                  title={t(invitationErrors?.message?.split(':')[0] ?? '')}
                />
              ) : (
                <></>
              )}

              {isAnonymousUser && <SignInForm />}
            </>
          )}
        </FormWrapper>
      </Column>
      {!isMobileSize && (
        <Column>
          <Image src={sharePropertySignInImageSrc} />
        </Column>
      )}
    </Container>
  );
};

export { SharePropertySignInPage };
