import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Property, Valuation, ValuationType } from '../../../../generated';
import { Feature } from '../../../../services/mapbox/interfaces';
import { FormError } from '../../interface';
import { IValuationForm } from '../interface';

interface IState {
  isValuationWizardOpen: boolean;
  isRegisterFormOpen: boolean;
  isLoginFormOpen: boolean;
  isEditValuationModalOpen: boolean;
  isTimifyWidgetOpen: boolean;
  hasUserSwitchedSteps: boolean;
  isLoading: boolean;
  activeStep: number;
  floatingBar: boolean;
  isHighlight: boolean;
  errors?: FormError;
  valuationType?: ValuationType;
  userInput?: Partial<IValuationForm>;
  selectedAddress?: Feature;
  selectedCountryCode?: string;
  temporaryValuations?: { date: string; valuation: Valuation }[];
  temporarySelectedAddress?: Feature;
  temporaryUserInput?: Partial<IValuationForm>;
  isRedirect?: boolean;
  propertyDossier?: Partial<Property>;
  relatedCountry: string;
  regionalBrokerId?: string;
}

const initialState: IState = {
  isValuationWizardOpen: false,
  isRegisterFormOpen: false,
  isLoginFormOpen: false,
  hasUserSwitchedSteps: false,
  isEditValuationModalOpen: false,
  isTimifyWidgetOpen: false,
  isLoading: false,
  floatingBar: false,
  isHighlight: false,
  relatedCountry: 'germany',

  activeStep: 0,
  isRedirect: false,
};

export const valuationWizardSlice = createSlice({
  name: 'valuationWizard',
  initialState,
  reducers: {
    openValuationWizardAction(state) {
      state.isValuationWizardOpen = true;
    },
    closeValuationWizardAction(state) {
      state.isValuationWizardOpen = false;
      state.isRegisterFormOpen = false;
      state.isLoginFormOpen = false;
      state.hasUserSwitchedSteps = false;
      state.activeStep = 0;
      state.selectedCountryCode = '';
    },
    returnToFirstStep(state) {
      state.isValuationWizardOpen = true;
      state.isRegisterFormOpen = false;
      state.isLoginFormOpen = false;
      state.hasUserSwitchedSteps = false;
      state.activeStep = 0;
      state.selectedCountryCode = '';
    },
    nextStepAction(state) {
      state.activeStep += 1;
      state.hasUserSwitchedSteps = true;
    },
    prevStepAction(state) {
      state.activeStep -= 1;
    },
    updateFormStateAction(
      state,
      action: PayloadAction<Partial<IValuationForm> | undefined>
    ) {
      state.userInput = {
        ...state.userInput,
        ...action.payload,
      };
    },
    setSelectedAddressAction(state, action: PayloadAction<Feature>) {
      state.selectedAddress = action.payload;
    },
    resetSelectedAddressAction(state) {
      delete state.selectedAddress;
    },
    setSelectedCountryCode(state, action: PayloadAction<string>) {
      state.selectedCountryCode = action.payload;
    },
    setRelatedCountry(state, action: PayloadAction<string>) {
      state.relatedCountry = action.payload;
    },
    setPropertyDossierAction(state, action: PayloadAction<Partial<Property>>) {
      state.propertyDossier = {
        ...state.propertyDossier,
        ...action.payload,
      };
    },
    resetValuationWizardWithoutValuationsAndAddress(state) {
      return {
        ...initialState,
        temporarySelectedAddress: state.temporarySelectedAddress,
        temporaryValuations: state.temporaryValuations,
        temporaryUserInput: state.temporaryUserInput,
        isLoading: state.isLoading,
      };
    },
    resetValuationWizard() {
      return { ...initialState };
    },
    setRedirect(state, action: PayloadAction<boolean>) {
      state.isRedirect = action.payload;
    },
    toggleRegisterForm(state, action: PayloadAction<boolean>) {
      state.isRegisterFormOpen = action.payload;
    },
    toggleLoginForm(state, action: PayloadAction<boolean>) {
      state.isLoginFormOpen = action.payload;
    },
    toggleIsEditValuationModalOpen(state, action: PayloadAction<boolean>) {
      state.isEditValuationModalOpen = action.payload;
    },
    setTemporaryValuations(
      state,
      action: PayloadAction<{ date: string; valuation: Valuation }[]>
    ) {
      state.temporaryValuations = action.payload;
      state.temporaryUserInput = state.userInput;
      state.temporarySelectedAddress = state.selectedAddress;
    },
    removeTemporaryValuations(state) {
      delete state.temporaryValuations;
    },
    setValuationTypeAction(state, action: PayloadAction<ValuationType>) {
      state.valuationType = action.payload;
    },
    toggleTimifyWidget(state, action: PayloadAction<boolean>) {
      state.isTimifyWidgetOpen = action.payload;
    },
    resetUserInput(state) {
      delete state.userInput;
    },
    toggleLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setErrors(state, action: PayloadAction<FormError>) {
      state.errors = action.payload;
    },
    clearErrors(state) {
      delete state.errors;
    },
    toggleFloatingBar(state, action: PayloadAction<boolean>) {
      state.floatingBar = action.payload;
    },
    toggleIsHighlight(state, action: PayloadAction<boolean>) {
      state.isHighlight = action.payload;
    },
    setRegionalBrokerId(state, action: PayloadAction<string>) {
      state.regionalBrokerId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openValuationWizardAction,
  closeValuationWizardAction,
  returnToFirstStep,
  nextStepAction,
  prevStepAction,
  updateFormStateAction,
  setSelectedAddressAction,
  resetSelectedAddressAction,
  setSelectedCountryCode,
  resetValuationWizardWithoutValuationsAndAddress,
  resetValuationWizard,
  setRedirect,
  toggleRegisterForm,
  toggleLoginForm,
  toggleIsEditValuationModalOpen,
  setTemporaryValuations,
  removeTemporaryValuations,
  setValuationTypeAction,
  toggleTimifyWidget,
  resetUserInput,
  toggleLoading,
  setErrors,
  clearErrors,
  toggleFloatingBar,
  toggleIsHighlight,
  setPropertyDossierAction,
  setRelatedCountry,
  setRegionalBrokerId,
} = valuationWizardSlice.actions;

export default valuationWizardSlice.reducer;
