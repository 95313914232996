import styled from 'styled-components';
import { hexToRGB } from '../../../../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../../../../style/theme';

export const Container = styled.div<{ isCounts?: boolean }>`
  display: flex;
  align-items: center;

  input {
    max-width: 100px !important;
    min-width: 100px !important;
  }

  & > div {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${({ theme, isCounts }) => hexToRGB(theme.blue, isCounts ? 1 : 0.5)};

    &:first-child {
      max-width: 284px;
      @media ${device.tablet} {
        max-width: none;
      }
    }

    &:nth-child(2) {
      margin: 0 auto;

      @media ${device.tablet} {
        margin: 24px 16px 0;
      }
    }

    &:last-child {
      text-align: end;
      min-width: 52px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media ${device.tablet} {
    input {
      max-width: 100% !important;
      min-width: 100% !important;
    }
    & > div:first-child {
      margin-top: 0;
    }
    & > div {
      margin-top: 24px;
    }
  }
`;
