import styled from 'styled-components';

import { device } from '../../../../style/theme';
import Icon from '../../../common/components/ui/icon';
import { BoxTitle } from '../../../common/components/ui/typography';

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    text-align: center;
  }
  i {
    min-width: 16px;
  }
  @media ${device.tablet} {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 32px auto;
    padding: 0 8px;
    &:before {
      content: none;
    }
  }
`;

const Title = styled(BoxTitle)`
  color: #fff;
  margin-top: 8px;
  text-transform: uppercase;
  @media ${device.tablet} {
    margin-top: 0;
    margin: 0 20px 0 12px;
  }
`;

interface IValuationTabProps {
  title: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}
const HeaderTab = ({ title, icon }: IValuationTabProps): JSX.Element => (
  <TabContainer>
    {icon && <Icon icon={icon} width={16} height={16} color="white" />}
    <Title content={title} />
  </TabContainer>
);

export { HeaderTab };
