import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { ReactComponent as EmptyStateIcon } from 'assets/svg/empty-state.svg';
import { device } from '../../../../../../style/theme';
import { MainButton } from '../../../../../common/components/ui/buttons';
import Icon from '../../../../../common/components/ui/icon';
import {
  Headline2,
  ParagraphText,
} from '../../../../../common/components/ui/typography';
import { useOpenFinancing } from '../../../../../forms/form-financing/hooks/callback-hooks/useOpenFinancing';
import { FinancingType } from '../../../../../../generated';

const Container = styled.div`
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 24px;

  @media ${device.tablet} {
    padding: 12px;
  }
`;

const Title = styled(Headline2)`
  font-size: 20px;
  line-height: 1.6;
  margin-top: 12px;
  text-align: center;
  width: 438px;

  @media ${device.tablet} {
    width: auto;
  }
`;

const Description = styled(ParagraphText)`
  text-align: center;
  margin: 8px 0 12px 0;
  width: 671px;

  @media ${device.tablet} {
    width: auto;
  }
`;

const CTAButton = styled(MainButton)`
  text-transform: uppercase;
  margin: 12px 0 8px 0;
`;

const EmptyStatePlaceholder = () => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const { openFinancing } = useOpenFinancing({});

  return (
    <Container>
      <Icon
        icon={EmptyStateIcon}
        color={themeContext.blue}
        style={{ width: '35px', height: '42px' }}
      />
      <Title
        content={t('financing-portal-page.calculation-results.empty-title')}
      />
      <Description
        content={t(
          'financing-portal-page.calculation-results.empty-description'
        )}
      />
      <CTAButton
        label={t('financing-portal-page.calculation-results.empty-button')}
        onClick={() => openFinancing(FinancingType.Financing)}
      />
    </Container>
  );
};

export default EmptyStatePlaceholder;
