import { FC } from 'react';

import { SocialEconomics } from '../../../../../../common/components/social-economics/social-economics';
import { useExposeDetailsPriceHubble } from '../../../hooks/useExposeDetailsQueries';
import { useMapSocialEconomics } from './useMapSocialEconomics';

const SocialEconomicsExposeDetailsWrapper: FC = () => {
  const { socialEconomics, location } = useExposeDetailsPriceHubble();
  useMapSocialEconomics({ location });

  return (
    <SocialEconomics
      socioEconomicsData={socialEconomics}
      isFullWidth
      isOnBuyerPortal
    />
  );
};

export { SocialEconomicsExposeDetailsWrapper };
