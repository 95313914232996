import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { isBoolean } from 'lodash';
import { RadioInput } from '../../common/components/form/input/input-radio';
import { BoxText, BoxTitle } from '../../common/components/ui/typography';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import {
  ReachabilityLegendMode,
  setReachabilityLegendMode,
} from '../../property/redux/dynamicMapUtilsSlice';
import { device } from '../../../style/theme';

interface Config {
  title: string;
  color: string;
}

const Container = styled.div`
  min-width: 170px;
  padding: 10px 12px;
  background: #fff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const DetailContainer = styled(Container)<{ isShowDetails?: boolean }>`
  max-height: ${({ isShowDetails }) => (isShowDetails ? 200 : 38)}px;
  transition: max-height 250ms ease-out;
  //top: 100px;
  @media ${device.tablet} {
    &&&&& {
      top: 56px;
      right: 0;
      height: fit-content !important;
    }
  }
`;

const Color = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${(props) => props.color};
`;

const Title = styled(BoxText)``;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & + & {
    margin-top: 4px;
  }
`;

const HeaderContent = styled.div`
  width: 100%;
  margin-bottom: 12px;
  padding-bottom: 9px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(217, 217, 217, 0.58);
  cursor: pointer;
`;

const Button = styled.button<{ isShowDetails?: boolean }>`
  width: 16px;
  height: 16px;
  border: 1px solid ${(props) => props.theme.blue};
  border-radius: 50%;
  background: transparent;
  font-size: 0;
  cursor: pointer;

  &::before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    border: 1px solid ${(props) => props.theme.blue};
    border-bottom: none;
    border-left: none;
    transform: ${(props) =>
      props.isShowDetails
        ? 'translateY(1px) rotate(-45deg)'
        : 'translateY(-1px) rotate(135deg)'};
    transition: transform 250ms ease-in-out;
  }
`;

const ControlContainer = styled(Container)<{ isTabListNearTop?: boolean }>`
  align-items: flex-start;
  top: 354px;

  ${({ isTabListNearTop }) =>
    isTabListNearTop &&
    css`
      top: auto;
      bottom: 12px;
    `}

  @media ${device.tablet} {
    &&&&& {
      height: fit-content !important;
      top: auto;
      bottom: 0;
      right: 0;
    }
  }
`;

const commuteTimeConfig: Config[] = [
  {
    title: 'reachability.time.15.minutes',
    color: '#6cc139',
  },
  {
    title: 'reachability.time.30.minutes',
    color: '#fdc403',
  },
  {
    title: 'reachability.time.45.minutes',
    color: '#e25326',
  },
];

const localTransitLinesConfig: Config[] = [
  {
    title: 'reachability.connection.bus',
    color: '#ffc403',
  },
  {
    title: 'reachability.connection.train',
    color: '#4086bf',
  },
  {
    title: 'reachability.connection.subway',
    color: '#13b93d',
  },
  {
    title: 'reachability.connection.tram',
    color: '#e25329',
  },
];

interface IProps {
  isTabNearTopProp?: boolean;
}

export const ReachabilityLegend: FC<IProps> = ({ isTabNearTopProp }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // const [selectedOption, setSelectedOption] =
  //   useState<ReachabilityLegendMode>('commuteTime');
  const [isShowDetails, setIsShowDetails] = useState(true);

  const reachabilityLegendMode = useAppSelector(
    (state) => state.dynamicMapUtils.reachabilityLegendMode
  );
  const isTabListNearTopState = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );

  const isTabListNearTop = isBoolean(isTabNearTopProp)
    ? isTabNearTopProp
    : isTabListNearTopState;

  const config =
    reachabilityLegendMode === 'commuteTime'
      ? commuteTimeConfig
      : localTransitLinesConfig;

  const headerText =
    reachabilityLegendMode === 'commuteTime'
      ? 'reachability.commute.time'
      : 'reachability.local.transit.lines';

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;
      // setSelectedOption(value as ReachabilityLegendMode);
      dispatch(setReachabilityLegendMode(value as ReachabilityLegendMode));
    },
    [dispatch]
  );

  const toggleShowDetails = useCallback(() => {
    setIsShowDetails((prev) => !prev);
  }, []);

  return (
    <>
      <DetailContainer
        isShowDetails={isShowDetails}
        onClick={toggleShowDetails}
        className="reachability-legend-detail-container"
      >
        <HeaderContent>
          <BoxTitle content={t(headerText)} />
          <Button isShowDetails={isShowDetails} type="button">
            Toggle details
          </Button>
        </HeaderContent>
        {config.map(({ color, title }) => (
          <Row key={title}>
            <Color color={color} />
            <Title content={t(title)} />
          </Row>
        ))}
      </DetailContainer>
      <ControlContainer
        className="control-container"
        isTabListNearTop={isTabListNearTop}
      >
        <Row>
          <RadioInput
            label={t('reachability.commute.time')}
            checked={reachabilityLegendMode === 'commuteTime'}
            value={'commuteTime'}
            onChange={handleInputChange}
          />
        </Row>
        <Row>
          <RadioInput
            label={t('reachability.local.transit.lines')}
            checked={reachabilityLegendMode === 'localTransitLines'}
            value={'localTransitLines'}
            onChange={handleInputChange}
          />
        </Row>
      </ControlContainer>
    </>
  );
};
