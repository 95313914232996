import styled from 'styled-components';

export const IconWrapper = styled.div``;

export const Back = styled.div`
  height: 56px;
  min-height: 56px;
  width: calc(100% + 2 * 24px);
  background: ${(props) => props.theme.blue};
  position: relative;
  top: -24px;
  margin: 0 -24px;
  display: flex;
  align-items: center;
  padding: 0 16px;

  > div:first-child,
  > div:last-child {
    flex: 0 0 auto;
  }
`;

export const MobileTitle = styled.h2`
  font-family: 'Source Serif Pro', serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
  margin: 0;
  flex: 1;
`;
