import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5mm',
  },
  text: {
    fontSize: '12px',
    fontWeight: 'extrabold',
    lineHeight: 1.33,
    textAlign: 'center',
    color: defaultTheme.blue,
  },
});

const Address = ({ address }: { address: string }): JSX.Element => {
  return (
    <View style={styles.wrapper}>
      <Text style={styles.text}>{address}</Text>
    </View>
  );
};

export { Address };
