import { FC } from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1.429,
  },
  title: {
    opacity: 1,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'SourceSerifPro',
    fontSize: '14px',
    fontWeight: 'semibold',
    textAlign: 'center',
    marginBottom: '8px',
  },
  text: {
    opacity: 1,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 'normal',
    textAlign: 'left',
    marginBottom: '8px',
    width: '498px',
    lineHeight: 1.8,
  },
  bold: {
    fontWeight: 'semibold',
  },
  link: {
    opacity: 0.8,
    color: 'rgba(0, 48, 94, 1)',
    fontSize: '10px',
    fontWeight: 'normal',
    textAlign: 'center',
    marginTop: '16px',
    lineHeight: 2,
  },
});

const Remarks: FC = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Anmerkungen</Text>
      <Text style={styles.text}>
        1. <Text style={styles.bold}>Impressionen</Text> - Dieser Wert gibt an,
        wie oft Ihre Immobilienanzeige präsentiert wurde. Jedes Mal, wenn Ihre
        Anzeige auf einer Suchergebnisseite bei den Immobilienportalen
        ausgeliefert wird, zählt dies als eine Impression.
      </Text>
      <Text style={styles.text}>
        2. <Text style={styles.bold}>Inserataufrufe</Text> - Hier sehen Sie die
        Inseratsaufrufe von Immobilienscout24 und unserer VP-Website. Aktuell
        können weitere Immobilienportale wie Immowelt, Meinestadt.de, eBay
        Kleinanzeigen, etc. aus technischen Gründen noch nicht angezeigt werden.
      </Text>
      <Text style={styles.text}>
        3. <Text style={styles.bold}>Versendete Exposés</Text> - Die Anzahl
        versandter Exposés setzt sich zusammen aus den versendeten Exposés an
        vorgemerkte Suchkunden sowie an Kaufinteressenten, die über unsere
        Website, App und die aktivierten Immobilienportale wie z.B.
        Immobilienscout24, Immowelt, usw. angefragt haben.
      </Text>
      <Text style={styles.link}>Quelle: {window.location.href}</Text>
    </View>
  );
};

export { Remarks };
