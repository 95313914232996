import styled from 'styled-components';
import { ParagraphText, ParagraphTitle } from '../../../ui/typography';

export const Container = styled.div``;

export const InputWrapper = styled.div`
  background-color: ${(props) => props.theme.greyBackground};
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.greyBackground};
  padding: 20px;
  border: 1px dotted ${(props) => props.theme.ctaDisabledBackground};
`;

export const Title = styled(ParagraphTitle)`
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  margin: 8px 0 4px 0;
`;

export const Description = styled(ParagraphText)`
  font-size: 12px;
  line-height: 1.33;
  opacity: 0.5;
  text-align: center;
`;

export const UploadedFilesWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
