import { FC, memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';

import {
  useResendConfirmationCodeMutation,
  UserState,
  useUserSelfDeleteMutation,
} from '../../../../../../generated';
import { ConfirmEmail } from '../../../../../auth/components/confirm-email';
import { PhoneVerification } from '../../../../../auth/components/forms/phone-verification';
import { RegisterForm } from '../../../../../auth/components/forms/register-form/register-form';
import {
  setUserId,
  setUsername,
  setUserState,
} from '../../../../../auth/redux/authSlice';
import { selectFormattedPhone } from '../../../../../auth/redux/selectors/selectFormattedPhone';
import { FormGridWrapperFixHeight } from '../../../../../common/components/form/form-grid';
import { Blurry } from '../../../../../common/components/ui/blurry';
import { GlobalLoader } from '../../../../../common/components/ui/loaders/global-loader';
import { StatusType } from '../../../../../common/components/verification-input/types';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { FORM_STEPS } from '../../../constants';
import { toggleRegisterForm } from '../../../redux/searchProfileSlice';
import { FormTitle } from '../../../../components/common/form-title';
import { VisibilityWrapper } from '../../../../components/common/visibility-wrapper';
import { MobileBack } from '../../common/mobile-back';
import { Progress } from '../../../../components/common/progress';
import LoginOpen from '../../../../../auth/components/login-open';
import { ISearchProfileForm } from '../../../interface';
import {
  BackButton,
  Container,
  Header,
  LoaderContainer,
  PhoneVerificiationWrapper,
} from './search-profile-styles';
import { useGetPortalType } from '../../../../../landing/hooks/useGetPortalType';
import { useRegisterSP } from '../../../hooks/useRegisterSP';
import { useVerifyPhoneSP } from '../../../hooks/useVerifyPhoneSP';

interface IProps {
  onSuccess?: (formData: ISearchProfileForm, ownerId: string) => Promise<void>;
  openLogin?(): void;
}

const OnlineRegisterContainerBase: FC<IProps> = ({ openLogin }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const { isBuyer } = useGetPortalType();

  const [verificationStatus, setVerificationStatus] = useState(
    StatusType.process
  );
  const [confirmEmailSent, setConfirmEmailSent] = useState(false);

  const userState = useAppSelector((state) => state.auth.userState);
  const formattedPhone = useAppSelector(selectFormattedPhone);
  const username = useAppSelector((state) => state.auth.userInput?.username);
  const email = useAppSelector((state) => state.auth.userInput?.email);
  const isRegisterFormLoading = useAppSelector((state) => state.auth.isLoading);
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const formFieldsWrapperRef = useRef<HTMLDivElement>(null);
  const [errors, setErrors] = useState<null | unknown>(null);

  // Mutations
  const [resendConfirmationCode] = useResendConfirmationCodeMutation();
  const [changePhone] = useUserSelfDeleteMutation();

  const backToSearchProfileForm = useCallback(
    () => dispatch(toggleRegisterForm(false)),
    [dispatch]
  );

  const { onRegisterSubmit } = useRegisterSP({
    setConfirmEmailSent,
    setErrors,
  });
  const { onVerifyPhone } = useVerifyPhoneSP({
    setErrors,
    setConfirmEmailSent,
    setVerificationStatus,
  });

  const onResendConfirmationCode = useCallback(() => {
    if (email) {
      resendConfirmationCode({ email });
    }
  }, [resendConfirmationCode, email]);

  const onChangePhone = useCallback(async () => {
    if (username) {
      try {
        await changePhone({ username }).unwrap();

        batch(() => {
          dispatch(setUserId(''));
          dispatch(setUsername(''));
          dispatch(setUserState(UserState.Unauthenticated));
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [dispatch, changePhone, username]);

  const mobileTitle = isBuyer
    ? t('buyer-landing-page.hero-slider.financing-advice.title').toUpperCase()
    : t('landing-page.primary-button.online-valuation.label');

  return (
    <Container isiframe={isIframe}>
      {!isIframe && <MobileBack title={mobileTitle} />}
      {isRegisterFormLoading && (
        <LoaderContainer>
          <GlobalLoader />
        </LoaderContainer>
      )}
      <VisibilityWrapper isVisible={!isRegisterFormLoading}>
        {userState === UserState.Unauthenticated && (
          <>
            <Header>
              <Progress activeBullet={FORM_STEPS} bullets={FORM_STEPS} />
              <FormTitle text={t('register.form.title')} />
            </Header>

            <FormGridWrapperFixHeight wrapperRef={formFieldsWrapperRef}>
              <LoginOpen handleClick={openLogin} />
              <RegisterForm
                // skipPassword
                onSubmit={onRegisterSubmit}
                errors={errors}
                isSearchProfile
                isButtonBottom={isIframe}
                isFieldRequirement
                isFullWidthButton={isIframe}
              />
            </FormGridWrapperFixHeight>
            {!isIframe && (
              <Blurry
                scrolledElementRef={formFieldsWrapperRef}
                position={{
                  left: '0',
                  bottom: !isMobileSize ? '48px' : '0',
                }}
              />
            )}
            {!isMobileSize && !isIframe && (
              <BackButton
                label={t('button.back')}
                onClick={backToSearchProfileForm}
              />
            )}
          </>
        )}
        {userState === UserState.VerifyPhone && (
          <>
            <Header>
              <FormTitle text={t('phone-verification.title')} />
            </Header>
            <PhoneVerificiationWrapper>
              <PhoneVerification
                phoneNumber={formattedPhone ?? ''}
                onVerifyPhone={onVerifyPhone}
                status={verificationStatus}
                // setStatus={setVerificationStatus}
                resendCode={onResendConfirmationCode}
                changePhone={onChangePhone}
              />
            </PhoneVerificiationWrapper>
          </>
        )}
        {userState === UserState.VerifyEmail && confirmEmailSent && (
          <ConfirmEmail
            title={t('modal.register.search-profile.confirm-email.title')}
            textContent={t('modal.register.confirm-email.content')}
          />
        )}
      </VisibilityWrapper>
    </Container>
  );
};

const OnlineRegisterContainer = memo(OnlineRegisterContainerBase);

export { OnlineRegisterContainer };
