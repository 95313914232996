import { FC } from 'react';
import {
  OwnerOverviews as OwnerOverviewsType,
  BuyerOverviews as BuyerOverviewsType,
  SellerOverviews as SellerOverviewsType,
  OverviewLpSection,
  useReadOwnerOverviewQuery,
  useReadBuyerOverviewQuery,
  OverviewLpSectionType,
  useReadSellerOverviewQuery,
} from '../../../../../generated';
import { siteMap } from '../../../../../routes/site-map';
import {
  BgColor,
  Container,
  HeaderContainer,
  Headline,
  BlueTitle,
} from './overview-section-styles';
import { OverviewItem } from './overview-item/overview-item';

interface OwnerOverviewsProps {
  id: string;
}

interface OwnerOverviewsResponse {
  data: { data: OwnerOverviewsType };
  isLoading: boolean;
}

const OwnerOverviews: FC<OwnerOverviewsProps> = ({ id }) => {
  const { data, isLoading } = useReadOwnerOverviewQuery<OwnerOverviewsResponse>(
    {
      id,
    }
  );

  if (isLoading) return null;

  const overviewBase = data?.data;

  const overview = {
    revertContent: true,
    ctaHandler: () =>
      window.open(
        'https://home.von-poll.com/de-de/online-immobilienbewertung',
        '_blank'
      ),
    overview: {
      title: overviewBase.title || '',
      expandedText: overviewBase.expandedText || '',
      buttonLabel: overviewBase.propertyEvaluateLabel || '',
      imageKey: overviewBase.imageKey || '',
      imageUrl: overviewBase.imageUrl || '',
      imageAlt: overviewBase.imageAlt || '',
      link: 'https://www.von-poll.com/downloads/Musteranalyse-VONPOLLIMMOBILIENHOME.pdf',
      linkLabel: overviewBase.patternAnalysisLabel || '',
    },
  };

  return <OverviewItem {...overview} />;
};

interface BuyerOverviewsProps {
  id: string;
}

interface BuyerOverviewsResponse {
  data: { data: BuyerOverviewsType };
  isLoading: boolean;
}

const BuyerOverviews: FC<BuyerOverviewsProps> = ({ id }) => {
  const { data, isLoading } = useReadBuyerOverviewQuery<BuyerOverviewsResponse>(
    {
      id,
    }
  );

  if (isLoading) return null;

  const overviewBase = data?.data;

  const overview = {
    revertContent: false,
    ctaHandler: () =>
      window.open('https://home.von-poll.com/de-de/immobilie-suchen', '_blank'),
    overview: {
      title: overviewBase.title || '',
      expandedText: overviewBase.expandedText || '',
      buttonLabel: overviewBase.createSearchProfileLabel || '',
      imageKey: overviewBase.imageKey || '',
      imageUrl: overviewBase.imageUrl || '',
      imageAlt: overviewBase.imageAlt || '',
    },
  };

  return <OverviewItem {...overview} />;
};

interface SellerOverviewsProps {
  id: string;
}

interface SellerOverviewsResponse {
  data: { data: SellerOverviewsType };
  isLoading: boolean;
}

const SellerOverviews: FC<SellerOverviewsProps> = ({ id }) => {
  const { data, isLoading } =
    useReadSellerOverviewQuery<SellerOverviewsResponse>({
      id,
    });

  if (isLoading) return null;

  const overviewBase = data?.data;

  const overview = {
    revertContent: true,
    ctaHandler: () => window.open(siteMap.SellerLandingPage.path, '_blank'),
    isProportional: true,
    overview: {
      title: overviewBase.title || '',
      expandedText: overviewBase.expandedText || '',
      buttonLabel: overviewBase.CTALabel || '',
      imageKey: overviewBase.imageKey || '',
      imageUrl: overviewBase.imageUrl || '',
      imageAlt: overviewBase.imageAlt || '',
    },
  };

  return <OverviewItem {...overview} />;
};

interface IProps {
  section: OverviewLpSection;
}

const OverviewSection: FC<IProps> = ({ section }) => {
  const { type, recordIds, title } = section;

  const blueTitle = title?.split(' ')?.[0];
  const mainTitle = title?.split(' ')?.slice(1)?.join(' ');

  return (
    <Container isTopPadding={type === OverviewLpSectionType.BuyerOverview}>
      {type === OverviewLpSectionType.BuyerOverview && <BgColor />}
      <HeaderContainer
        noTopPadding={type === OverviewLpSectionType.SellerOverview}
      >
        <Headline>
          <BlueTitle>{blueTitle}</BlueTitle> {mainTitle}
        </Headline>
      </HeaderContainer>
      {type === OverviewLpSectionType.OwnerOverview &&
        recordIds.map((id: string) => {
          return <OwnerOverviews key={id} id={id} />;
        })}
      {type === OverviewLpSectionType.BuyerOverview &&
        recordIds.map((id: string) => {
          return <BuyerOverviews key={id} id={id} />;
        })}
      {type === OverviewLpSectionType.SellerOverview &&
        recordIds.map((id: string) => {
          return <SellerOverviews key={id} id={id} />;
        })}
    </Container>
  );
};

export { OverviewSection };
