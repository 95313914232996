import { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';

import {
  addMarkers,
  changeViewport,
  removeAllMarkersExceptHome,
  setSettings,
} from '../../../../map/redux/mapSlice';
import { clearPropertiesCache } from '../../../redux/similarPropertiesSlice';
import { PropertyLocation } from '../../../../../generated';

interface IProps {
  location?: PropertyLocation;
}

const useMapSocialEconomics = ({ location }: IProps): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (location) {
      const pin = {
        longitude: location?.coordinates.longitude,
        latitude: location?.coordinates.latitude,
      };

      batch(() => {
        dispatch(
          changeViewport({
            ...location?.coordinates,
            bearing: -80,
            pitch: 65,
            zoom: 14,
            transitionDuration: 0,
          })
        );
        dispatch(
          setSettings({
            dragPan: true,
            dragRotate: true,
            scrollZoom: true,
            touchZoom: true,
            touchRotate: true,
            keyboard: true,
            doubleClickZoom: true,
          })
        );
        dispatch(addMarkers([pin]));
      });
    }
    return () => {
      batch(() => {
        dispatch(removeAllMarkersExceptHome());
        dispatch(clearPropertiesCache());
      });
    };
  }, [dispatch, location]);
};

export { useMapSocialEconomics };
