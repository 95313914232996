import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const ContentWithPadding = styled.div`
  padding: 0 48px 0 48px;
  margin-top: -15px;
  @media ${device.tablet} {
    padding: 0;
    margin-top: 0;
  }
`;

export const LoaderContainer = styled.div<{ padding?: string }>`
  padding: 300px 0;
`;

export const MobileMapContainer = styled.div`
  position: relative;
  height: 160px;
  overflow: hidden;
`;

export const MobileHeadContainer = styled.div`
  position: relative;
`;
