import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { ILoginDevForm } from '../../../../interfaces';
import { InputV2 } from '../../../../../forms/components/common/input-v2/input-v2';
import { Error, FormContainer, SubmitButton } from './login-dev-form-styles';
import { setExpDevUser } from '../../../../redux/authSlice';

const envEmail = process.env.REACT_APP_DEV_USER_EMAIL;
const envPassword = process.env.REACT_APP_DEV_USER_PASSWORD;

const LoginDevForm: FC = () => {
  const methods = useForm<ILoginDevForm>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState('');

  const onSubmit = ({ login, password }: ILoginDevForm) => {
    if (login === envEmail && password === envPassword) {
      // will expire in 3 days
      const today = new Date();
      today.setDate(new Date().getDate() + 3);
      dispatch(setExpDevUser(new Date(today).toString()));
    } else {
      setError('error.invalid.username.or.password');
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <FormContainer>
          <InputV2 type="email" name="login" placeholder="Login" rules={{}} />
          <InputV2
            type="password"
            name="password"
            placeholder="Password"
            rules={{}}
          />
          {error && <Error>{t(error)}</Error>}
          <SubmitButton
            label="Login"
            onClick={methods.handleSubmit(onSubmit)}
          />
        </FormContainer>
      </FormProvider>
    </>
  );
};

export { LoginDevForm };
