import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LoaderContainer, Title } from '../styles';
import LoginOpen from '../../../../../auth/components/login-open';
import { RegisterForm } from '../../../../../auth/components/forms/register-form/register-form';
import { UserState, ValuationType } from '../../../../../../generated';
import { ConfirmEmail } from '../../../../../auth/components/confirm-email';
import { GlobalLoader } from '../../../../../common/components/ui/loaders/global-loader';
import { useAppSelector } from '../../../../../common/hooks';
import { IRegisterForm } from '../../../../../auth/interfaces';
import { FinancingLoader } from '../../../common/loader';

interface IProps {
  isConfirmEmailSent: boolean;
  isBoldCheckbox?: boolean;
  isFinancing?: boolean;
  isFinancingCertificate?: boolean;
  buttonPosition?: string;
  onRegisterSubmit(formData: IRegisterForm): void;
  onOpenLogin?: () => void;
  errors: null | unknown;
}

const RegisterContainer: FC<IProps> = ({
  isConfirmEmailSent,
  isBoldCheckbox,
  isFinancing,
  isFinancingCertificate,
  onRegisterSubmit,
  onOpenLogin,
  buttonPosition,
  children,
  errors,
}) => {
  const { t } = useTranslation();
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const isLoginFormLoading = useAppSelector((state) => state.auth.isLoading);
  const userState = useAppSelector((state) => state.auth.userState);

  return (
    <>
      {isLoginFormLoading ? (
        isFinancing ? (
          <FinancingLoader
            title={
              isFinancingCertificate
                ? 'financing.loader-label-certificate'
                : 'financing.loader-label'
            }
          />
        ) : (
          <LoaderContainer isiframe={isIframe}>
            <GlobalLoader />
          </LoaderContainer>
        )
      ) : (
        <>
          {userState === UserState.Unauthenticated && (
            <>
              <Title>{t('register.form.title')}</Title>
              <LoginOpen handleClick={onOpenLogin} />
              <RegisterForm
                // skipPassword
                onSubmit={onRegisterSubmit}
                valuationType={ValuationType.Online}
                errors={errors}
                isRedesign
                isFullWidthButton
                isFieldRequirement
                isFinancing={isFinancing}
                isBoldCheckbox={isBoldCheckbox}
                buttonPosition={buttonPosition}
              />
            </>
          )}
          {children}
          {userState === UserState.VerifyEmail && isConfirmEmailSent && (
            <ConfirmEmail
              title={t(
                isFinancing
                  ? 'modal.register.financier.confirm-email.title'
                  : 'modal.register.confirm-email.title'
              )}
              textContent={t('modal.register.confirm-email.content')}
            />
          )}
        </>
      )}
    </>
  );
};

export { RegisterContainer };
