import Icon from 'modules/common/components/ui/icon';
import { FC, FunctionComponent, SVGProps, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

export type IconType = FunctionComponent<
  SVGProps<SVGSVGElement> & { title?: string | undefined }
>;

interface IProps {
  icon: IconType;
  title: string;
  description: string;
  hasValue: boolean;
  distance?: string | number;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 12px;
  color: ${(props) => props.theme.blue}
  letter-spacing: 0.5px;
  background-color: ${(props) => props.theme.white};
  border: 1px solid #d9d9d9;
  border-radius: 3px;
`;

const TextWrapper = styled.div`
  flex: 1;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  margin-bottom: 3px;
  font-family: 'Source Serif Pro', serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.33;
`;

const Description = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
  line-height: 1.6;
  text-transform: uppercase;
`;

const Distance = styled.p<{ hasValue: boolean }>`
  text-align: right;
  max-width: 130px;
  font-family: 'Source Serif Pro', serif;
  margin-left: auto;
  font-size: ${(props) => (props.hasValue ? 20 : 16)}px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.5px;
  opacity: ${(props) => (props.hasValue ? 1 : 0.5)};
`;

export const ConnectionItem: FC<IProps> = ({
  icon,
  title,
  description,
  hasValue,
  distance,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Container {...rest}>
      <Icon icon={icon} width={32} height={32} color={themeContext.blue} />
      <TextWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextWrapper>
      {distance && <Distance hasValue={hasValue}>{distance}</Distance>}
    </Container>
  );
};
