import { batch } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import {
  changeViewport,
  restoreCheckpoints,
} from '../../../map/redux/mapSlice';
import { Europe } from '../../../map/constants/points-of-interest';
import { closeSPCAction, resetSPC } from '../redux/searchProfileSlice';

const useFormCloseSP = () => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(
    (state) => state.dynamicMapUtils.selectedTab
  );

  const onFormCloseSP = () => {
    // Heat map
    if (selectedTab === 0) {
      dispatch(
        changeViewport({
          ...Europe,
          transitionDuration: 1000,
        })
      );
      setTimeout(() => {
        batch(() => {
          dispatch(closeSPCAction());
          dispatch(resetSPC());
        });
      }, 500);
    } else {
      batch(() => {
        dispatch(closeSPCAction());
        dispatch(resetSPC());
        dispatch(restoreCheckpoints());
      });
    }
  };

  return { onFormCloseSP };
};

export { useFormCloseSP };
