import { useEffect } from 'react';

import { useLazyGetFinancingVonPollPropertyDetailsQuery } from '../../../generated';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { toggleLoadingSuggestedProperty } from '../../forms/form-financing/redux/financingSlice';

const useGetFinancingVonPollPropertyDetails = () => {
  const dispatch = useAppDispatch();
  const marketingParameters = useAppSelector(
    (state) => state.auth.marketingParameters
  );
  const suggestedPropertyId = useAppSelector(
    (state) => state.financing.suggestedProperties?.propertyId
  );

  const propertyIdFromMarkParameters = marketingParameters
    ?.split('property_id=')?.[1]
    ?.split('&')?.[0];

  const propertyId = suggestedPropertyId || propertyIdFromMarkParameters;

  const [getProperty, { data, isLoading }] =
    useLazyGetFinancingVonPollPropertyDetailsQuery();
  useEffect(() => {
    if (propertyId) {
      getProperty({
        id: Number(propertyId),
      });
    }
  }, [propertyId]);

  // suggested properties purchase price logic
  // useEffect(() => {
  //   if (suggestedPropertyId) {
  //     getProperty({ id: Number(suggestedPropertyId) });
  //   }
  // }, [suggestedPropertyId]);

  useEffect(() => {
    dispatch(toggleLoadingSuggestedProperty(isLoading));
  }, [isLoading]);

  return {
    data: data?.getFinancingVonPollPropertyDetails,
    isLoading,
    id: Number(propertyId),
  };
};

export { useGetFinancingVonPollPropertyDetails };
