import { FC, FunctionComponent, SVGProps, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Icon from '../../../../common/components/ui/icon';
import BigCardContainer from '../elements/big-card-container';
import { CardTitle } from '../elements/card-title';

const HeadingWrapper = styled.div`
  text-align: center;
  display: flex;
`;
const TitleWrapper = styled.div`
  padding-left: 6px;
`;
const IconWrapper = styled.div`
  padding-right: 6px;
`;

interface IProps {
  title: string;
  icon?: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  children: JSX.Element | JSX.Element[];
}

const CardWithTitle: FC<IProps> = ({ title, icon, children, ...rest }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <BigCardContainer {...rest}>
      <>
        <HeadingWrapper>
          {icon && (
            <IconWrapper>
              <Icon
                icon={icon}
                width={32}
                height={32}
                color={themeContext.blue}
              />
            </IconWrapper>
          )}
          <TitleWrapper>
            <CardTitle content={title} marginBottom="16px" />
          </TitleWrapper>
        </HeadingWrapper>
        {children}
      </>
    </BigCardContainer>
  );
};

export { CardWithTitle };
