import { FC, memo, useContext } from 'react';
import { ReactComponent as Star } from 'assets/streamline-light/shopping-ecommerce/discount-coupons/discount-star-premium.svg';
import { ThemeContext } from 'styled-components';
import {
  Container,
  Description,
  IconWrapper,
  Title,
} from './motivation-banner-styles';
import Icon from '../../../../common/components/ui/icon';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';

interface IProps {
  title: string;
  description: string;
}

const MotivationBannerBase: FC<IProps> = ({ title, description }) => {
  const isMobileSize = useIsMobileSize();
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      {!isMobileSize && (
        <IconWrapper>
          <Icon icon={Star} width={32} height={32} color={themeContext.blue} />
        </IconWrapper>
      )}
      <Title content={title} />
      <Description content={description} />
    </Container>
  );
};

const MotivationBanner = memo(MotivationBannerBase);

export { MotivationBanner };
