import { FC, Fragment } from 'react';
import styled from 'styled-components';
import { CmsBlock, ContentSection } from '../../../generated';
import { device } from '../../../style/theme';
import { ContentScript } from './content-script';
import './quill.snow.css';
import './cookie-declaration.css';

interface ContentPageWithHtmlProps {
  blocks?: CmsBlock[];
}

const Container = styled.div`
  padding: 20px;
  font-family: Roboto, sans-serif !important;
  color: ${(props) => props.theme.blue} !important;
  letter-spacing: 0.5px;

  h1,
  h2,
  h3 {
    font-family: 'Source Serif Pro', serif;
    font-size: 36px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
  }

  h2 {
    font-size: 24px;
    @media ${device.tablet} {
      font-size: 20px;
      line-height: 1.4;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 1.6;
  }

  li,
  p {
    margin-bottom: 8px;
    font-size: 14px;
  }

  --list-padding: 4px;

  ol {
    counter-reset: ol0;

    & li {
      counter-increment: ol0;
      padding-left: var(--list-padding);
    }

    & li::marker {
      content: counter(ol0, decimal) '.';
    }
  }

  ol ol {
    counter-reset: ol1;
    padding-left: calc(var(--list-padding) * 4);

    & li {
      counter-increment: ol1;
    }

    & li::marker {
      content: counter(ol0, decimal) '.' counter(ol1, decimal);
    }
  }

  ol ol ol {
    counter-reset: ol2;
    padding-left: calc(var(--list-padding) * 6);

    & li {
      counter-increment: ol2;
    }

    & li::marker {
      content: counter(ol0, decimal) '.' counter(ol1, decimal) '.'
        counter(ol2, decimal);
    }
  }
`;

const ContentWithHtml: FC<ContentPageWithHtmlProps> = ({ blocks }) => {
  if (!blocks) {
    return null;
  }

  return (
    <>
      <div className="quill" id="content-page">
        {blocks?.map(({ sections }) =>
          (sections as ContentSection[])?.map(
            ({ contentHTML, _id, scriptSrc, scriptId }) => (
              <Fragment key={_id}>
                <Container dangerouslySetInnerHTML={{ __html: contentHTML }} />
                <ContentScript
                  scriptId={scriptId as string}
                  scriptSrc={scriptSrc as string}
                  containerSelector="#content-page"
                />
              </Fragment>
            )
          )
        )}
      </div>
    </>
  );
};

export { ContentWithHtml };
