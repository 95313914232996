import { FC } from 'react';
import { BoxImage, Container, StyledImage } from './certificates-styles';
import {
  Certificates as CertificatesType,
  RpSection,
  useReadCertificateQuery,
} from '../../../../../generated';

interface IProps {
  section: RpSection;
}

interface CertificateProps {
  id: string;
}

interface CertificateResponse {
  data: { data: CertificatesType };
  isLoading: boolean;
}

const Certificate = ({ id }: CertificateProps) => {
  const { data, isLoading } = useReadCertificateQuery<CertificateResponse>({
    id,
  });

  if (isLoading) return null;

  const certificate = data?.data;

  return (
    <BoxImage>
      <StyledImage
        key={certificate?.fileKey as string}
        src={certificate?.fileUrl as string}
        alt={certificate?.fileAlt as string}
      />
    </BoxImage>
  );
};

const Certificates: FC<IProps> = ({ section }) => {
  const { recordIds } = section;

  return (
    <Container>
      {recordIds.map((id: string) => {
        return <Certificate key={id} id={id} />;
      })}
    </Container>
  );
};

export { Certificates };
