import {
  CSSProperties,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';
// Styles
import styled, { css } from 'styled-components';
// Animation
import { easeQuadInOut } from 'd3-ease';
import { interpolate } from 'd3-interpolate';
import { Animate } from 'react-move';
import { hexToRGB } from '../../utils/hex-to-rgb';
import { device } from '../../../../style/theme';
import { useAppSelector } from '../../hooks';
import { IIframeType } from '../../../auth/interfaces';

interface IModal {
  isOpen: boolean;
  isHiddenScroll?: boolean;
  overlayStyles?: CSSProperties;
  wrapperStyles?: CSSProperties;
  onClose?: (e?: React.SyntheticEvent) => void;
  children: ReactNode;
}

const Overlay = styled.div<{ opacity: string | number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  opacity: ${(props) => props.opacity};
  background-color: ${(props) => hexToRGB(props.theme.logoBlue, 0.9)};
  z-index: 2010;
`;

const ModalWrapper = styled.div<{
  opacity: string | number;
  isHiddenScroll?: boolean;
}>`
  max-width: 688px;
  width: 100%;
  padding: 32px;
  border-radius: 3px;
  opacity: ${(props) => props.opacity};
  background-color: ${(props) => props.theme.white};

  ${({ isHiddenScroll }) =>
    isHiddenScroll &&
    css`
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    `}
  @media ${device.tablet} {
    /* padding: 20px; */
    /* min-height: 100vh; */
  }
`;
const ModalPlacement = document.querySelector('#modal-root') as Element;

export const MODAL_ANIMATION_DURATION = 300;

const Modal = ({
  isOpen,
  isHiddenScroll,
  overlayStyles,
  wrapperStyles,
  onClose,
  children,
}: IModal): JSX.Element => {
  const iframeType = useAppSelector((state) => state.auth.iframeType);
  const isIframeFin = iframeType === IIframeType.WIDGET_FINANCING;
  const isIframeFinCertificate =
    iframeType === IIframeType.WIDGET_FINANCING_CERTIFICATE;
  const isModalOpacity = useAppSelector((state) => state.auth.isModalOpacity);

  const modalAppearance = {
    enter: [
      {
        opacity: isModalOpacity ? 1 : 0,
      },
      {
        opacity: [1],
        timing: {
          delay: 0,
          duration: MODAL_ANIMATION_DURATION,
          ease: easeQuadInOut,
        },
      },
    ],

    leave: [
      {
        opacity: [0],
        timing: {
          duration: MODAL_ANIMATION_DURATION,
          ease: easeQuadInOut,
        },
      },
    ],
  };

  const onEscKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== 'Escape' || isIframeFin || isIframeFinCertificate) {
        return;
      }

      if (onClose) {
        onClose();
      }
    },
    [onClose, isIframeFin, isIframeFinCertificate]
  );

  const onModalWrapperClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    },
    []
  );

  const toggleBodyScroll = (scrollState: boolean) => {
    document.body.style.overflow = scrollState ? 'auto' : 'hidden';
  };

  useEffect(() => {
    if (onClose) {
      window.addEventListener('keydown', onEscKeyDown);
    }

    return () => {
      if (onClose) {
        window.removeEventListener('keydown', onEscKeyDown);
      }
    };
  }, [onClose, onEscKeyDown]);

  useEffect(() => {
    toggleBodyScroll(!isOpen);
  }, [isOpen]);

  return createPortal(
    <Animate
      {...modalAppearance}
      show={isOpen}
      start={{ opacity: 0 }}
      interpolation={(begValue, endValue) => interpolate(begValue, endValue)}
    >
      {({ opacity }) => (
        <Overlay style={overlayStyles} opacity={opacity} onClick={onClose}>
          <ModalWrapper
            className={isOpen ? 'modal-open' : 'modal-close'}
            style={wrapperStyles}
            opacity={opacity}
            onClick={onModalWrapperClick}
            isHiddenScroll={isHiddenScroll}
          >
            {children}
          </ModalWrapper>
        </Overlay>
      )}
    </Animate>,
    ModalPlacement
  );
};

export { Modal };
