import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'auto',
  },
  top: {
    borderTop: `2px solid ${defaultTheme.blue}`,
    height: '2mm',
  },
  bottom: {
    height: '8mm',
    backgroundColor: defaultTheme.blue,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageNumber: {
    color: '#fff',
    fontSize: '4mm',
  },
});

const Footer = ({
  pageNumber,
}: {
  pageNumber?: number | string;
}): JSX.Element => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.top} />
      <View style={styles.bottom}>
        <Text style={styles.pageNumber}>{pageNumber}</Text>
      </View>
    </View>
  );
};

export { Footer };
