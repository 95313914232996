import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ThemeContext } from 'styled-components';
import { isEmpty, map, filter, values, concat, findIndex } from 'lodash';
import { ReactComponent as Upload } from 'assets/streamline-light/internet-networks-servers/upload-download/upload-bottom.svg';
import {
  InputWrapper,
  Content,
  Description,
  Title,
  Container,
  UploadedFilesWrapper,
} from './input-file-styles';
import Icon from '../../../ui/icon';
import { UploadedItem } from './uploaded-item/uploaded-item';
import { isImage } from './utils/isImage';

export type SectionType = 'Documents' | 'Photos' | 'Plans';

export type UploadedFile = File & { title?: string };

interface IProps {
  title: string;
  description?: string;
  accept: string;
  multiple?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>, section?: SectionType) => void;
  onEditFileTitle?: (files: UploadedFile[]) => void;
  onDelete?: (files: UploadedFile[]) => void;
  searchInput?: string;
  section?: SectionType;
  uploadedFiles?: UploadedFile[];
  isDisabled?: boolean;
}

const InputFileBase: FC<IProps> = ({
  title,
  description,
  accept,
  multiple,
  onChange,
  onDelete,
  searchInput,
  section,
  onEditFileTitle,
  uploadedFiles,
  isDisabled,
}) => {
  const [files, setFiles] = useState<UploadedFile[]>(uploadedFiles ?? []);
  const inputRef = useRef<HTMLInputElement>(null);
  const themeContext = useContext(ThemeContext);

  const handleUploadFile = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  }, []);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e?.target?.files) {
        if (files) {
          setFiles(concat(files, values(e.target.files)));
        } else {
          setFiles(values(e.target.files));
        }
      }

      if (onChange) {
        onChange(e, section);
      }
    },
    [files, onChange, section]
  );

  const handleDeleteFile = useCallback(
    (fileName: string) => {
      const filteredFiles = filter(files, (file) => file.name !== fileName);
      setFiles(filteredFiles);

      if (onDelete) {
        onDelete(filteredFiles);
      }
    },
    [files, onDelete]
  );

  const handleOpenFile = useCallback(
    (fileName: string) => {
      const selectedFile = filter(files, (file) => file.name === fileName);
      const url = URL.createObjectURL(selectedFile[0]);
      window.open(url, '_blank');
    },
    [files]
  );

  const handleEditTitle = useCallback(
    (fileName: string, fileTitle: string) => {
      const selectedFile = filter(files, (file) => file.name === fileName);
      if (files && selectedFile[0]) {
        Object.defineProperty(selectedFile[0], 'title', {
          writable: true,
          value: fileTitle,
        });

        if (onEditFileTitle) {
          onEditFileTitle(files);
        }
      }
    },
    [files, onEditFileTitle]
  );

  const filteredFiles = useMemo(() => {
    if (searchInput) {
      return files?.filter(
        (file) =>
          file.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          file?.title?.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    return files;
  }, [files, searchInput]);

  return (
    <Container>
      <InputWrapper onClick={handleUploadFile}>
        <Content>
          <Icon
            icon={Upload}
            color={themeContext.blue}
            width={32}
            height={32}
          />
          <Title content={title} />
          {description && <Description content={description} />}
          <input
            style={{ display: 'none' }}
            type="file"
            ref={inputRef}
            accept={accept}
            multiple={multiple}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Content>
      </InputWrapper>
      {!isEmpty(filteredFiles) && (
        <UploadedFilesWrapper>
          {map(filteredFiles, (file, index) => (
            <UploadedItem
              key={file.name + index.toString()}
              title={file?.title ?? file.name}
              fileName={file.name}
              size={file.size}
              previewImgUrl={
                file.type.includes('image') ? URL.createObjectURL(file) : ''
              }
              onDeleteFile={handleDeleteFile}
              onOpenFile={handleOpenFile}
              onEditTitle={handleEditTitle}
            />
          ))}
        </UploadedFilesWrapper>
      )}
    </Container>
  );
};

const InputFile = memo(InputFileBase);

export { InputFile };
