import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { InputV2 } from 'modules/forms/components/common/input-v2/input-v2';
import { InputType } from 'modules/auth/interfaces';
import { formatNumber } from 'modules/property/utils/format-number';
import { ReactComponent as CheckIcon } from 'assets/streamline-light/interface-essential/form-validation/check-1.svg';
import FormPurchaseIntend from 'modules/forms/form-purchase-intend/components/containers/form-purchase-intend';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Icon from 'modules/common/components/ui/icon';
import { useReadPropertyPurchaseIntentsQuery } from 'generated';
import {
  Container,
  Content,
  FormContainer,
  IconWrap,
  SubmitButton,
  Success,
  SuccessLabel,
  Text,
  Title,
} from './offer-price-styles';
import { useUpdatePriceOffersMutation } from '../../../../services/graphql/enhanced';

type IStatus = 'initial' | 'invalid' | 'valid' | 'success';
interface IOfferPriceForm {
  price: number;
}

interface IProps {
  price: number;
  isListView?: boolean;
  internalId?: string;
  inWidget?: boolean;
  isExposeReleased?: boolean;
  exposeRequestId?: string;
  offeredPrice?: number;
}

const OfferPrice = ({
  price,
  isListView,
  inWidget,
  internalId,
  isExposeReleased,
  exposeRequestId,
  offeredPrice,
}: IProps) => {
  const { searchProfileId } = useParams<{ searchProfileId: string }>();
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const [priceWatch, setPrice] = useState<undefined | number>();

  const methods = useForm<IOfferPriceForm>({
    defaultValues: {
      price: offeredPrice ?? undefined,
    },
  });
  const [updatePriceOffers, { data, isLoading }] =
    useUpdatePriceOffersMutation();

  const [status, setStatus] = useState<IStatus>(
    offeredPrice ? 'success' : 'initial'
  );
  const [isOpen, setIsOpen] = useState<boolean>();

  useEffect(() => {
    if (offeredPrice) {
      setStatus('success');
    } else if (!priceWatch) {
      setStatus('initial');
    } else if (priceWatch < price * 0.85) {
      setStatus('invalid');
    } else {
      setStatus('valid');
    }
  }, [priceWatch]);

  const onSubmit: SubmitHandler<IOfferPriceForm> = useCallback(
    async (formData) => {
      if (internalId) {
        await updatePriceOffers({
          updatePriceOffersInput: {
            searchProfileId,
            priceOffer: {
              propertyInternalId: internalId,
              propertyPrice: price,
              offeredPrice: formData.price,
              createdAt: new Date(),
            },
          },
        });
        setStatus('success');
      }
    },
    []
  );

  const { refetch: refetchPropertyPurchaseIntents } =
    useReadPropertyPurchaseIntentsQuery(
      {
        propertyId: internalId || '',
      },
      { skip: !isExposeReleased }
    );

  return (
    <Container
      isListView={isListView}
      inWidget={inWidget}
      className="offer-price"
    >
      <Title content={t('offer-price.title')} />
      <Content className="content">
        {!isExposeReleased && (
          <>
            {(status === 'initial' || status === 'valid') && (
              <Text content={t('offer-price.initial-text')} className="text" />
            )}
            {status === 'invalid' && (
              <Text
                content={t('offer-price.invalid-text')}
                className="text invalid"
              />
            )}
            {status === 'success' && (
              <Text content={t('offer-price.success-text')} className="text" />
            )}
          </>
        )}
        {isExposeReleased && (
          <Text
            content={t('offer-price.expose-released-text')}
            className="text"
          />
        )}

        {!inWidget ? (
          <FormProvider {...methods}>
            <FormContainer className="form-container">
              {!isExposeReleased && (
                <>
                  <InputV2
                    name="price"
                    type={InputType.number}
                    placeholder={price ? String(formatNumber(price)) : ''}
                    inputAdornment="€"
                    isColumn
                    isValidateOnBlur
                    isDisabledWhite={status === 'success'}
                    isOldDesign={inWidget}
                    isSeparate
                    handleChangeProp={(v) => setPrice(Number(v))}
                    rules={{
                      validate: (value: number) => {
                        return Number(value) >= Number(price) * 0.7 &&
                          Number(value) > 0
                          ? true
                          : '';
                      },
                    }}
                  />
                  {status !== 'success' && (
                    <SubmitButton
                      isDisabled={status !== 'valid'}
                      loader
                      color="white"
                      isLoading={isLoading}
                      label={t('offer-price.submit')}
                      onClick={methods.handleSubmit(onSubmit)}
                    />
                  )}
                  {status === 'success' && (
                    <Success>
                      <IconWrap>
                        <Icon
                          width={8}
                          height={8}
                          icon={CheckIcon}
                          color={themeContext.white}
                        />
                      </IconWrap>
                      <SuccessLabel content={t('offer-price.success-label')} />
                    </Success>
                  )}
                </>
              )}

              {isExposeReleased && (
                <SubmitButton
                  label={t('offer-price.purchase-interest')}
                  isFullWidth
                  onClick={() => setIsOpen(true)}
                />
              )}
            </FormContainer>
          </FormProvider>
        ) : (
          <>
            {!isExposeReleased && (
              <InputV2
                name="price"
                type={InputType.number}
                placeholder={price ? String(formatNumber(price)) : ''}
                inputAdornment="€"
                isColumn
                isSeparate
                isValidateOnBlur
                isDisabledWhite={status === 'success'}
                isOldDesign={inWidget}
                handleChangeProp={(v) => setPrice(Number(v))}
                rules={{
                  validate: (value: number) => {
                    return Number(value) >= Number(price) * 0.7 &&
                      Number(value) > 0
                      ? true
                      : '';
                  },
                }}
              />
            )}
          </>
        )}
      </Content>

      <FormPurchaseIntend
        refetch={refetchPropertyPurchaseIntents}
        isOpen={!!isOpen}
        propertyPrice={price}
        propertyId={exposeRequestId}
        onClose={() => setIsOpen(false)}
      />
    </Container>
  );
};

const OfferPriceSection = memo(OfferPrice);

export { OfferPriceSection };
