import { CSSProperties, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Modal } from '../../../../common/components/modal';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { salutationOptions, titleOptions } from '../../constants/options';
import ModalFooter from '../common/modal-footer';
import ModalHeader from '../common/modal-header';
import ModalMobileHeader from '../common/modal-mobile-header';
import LoaderGif from '../../../../../assets/valuation-loader.gif';

interface FormModalProps {
  children: JSX.Element;
  isOpen: boolean;
  refetch: () => void;
  propertyId?: string;
  propertyPrice?: number;
  onClose?: () => void;
}

const Content = styled.div<{ isMobileSize?: boolean }>`
  flex: 1;
  ${(props) =>
    props.isMobileSize &&
    `
    padding: 16px;
    margin-top: -24px;
  `}
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = styled.img``;

const FormModal = ({
  children,
  isOpen,
  refetch,
  propertyId,
  propertyPrice,
  onClose,
}: FormModalProps): JSX.Element => {
  const methods = useForm({
    defaultValues: {
      salutation: salutationOptions[0].value,
      title: titleOptions[2].value,
      otherPersons: [],
    },
  });
  const [isLoading, setLoading] = useState(false);
  const isMobileSize = useIsMobileSize();

  const wrapperStyles = isMobileSize
    ? {
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
        height: '100%',
        overflow: 'overlay',
        padding: '0',
        borderRadius: '0',
        display: 'flex',
        flexDirection: 'column',
      }
    : {
        width: '620px',
        height: '700px',
        padding: '24px',
        boxShadow: '0 8px 30px 0 rgba(0, 0, 0, 0.09)',
        display: 'flex',
        flexDirection: 'column',
      };

  const onLoading = useCallback((state: boolean) => {
    setLoading(state);
  }, []);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      wrapperStyles={wrapperStyles as CSSProperties}
    >
      <FormProvider {...methods}>
        {isMobileSize ? (
          <ModalMobileHeader isLoading={isLoading} onClose={onClose} />
        ) : (
          <ModalHeader isLoading={isLoading} onClose={onClose} />
        )}
        <Content isMobileSize={isMobileSize}>
          {isLoading ? (
            <LoaderContainer>
              <Loader src={LoaderGif} alt="Loading..." />
            </LoaderContainer>
          ) : (
            children
          )}
        </Content>
        <ModalFooter
          onClose={onClose}
          onLoading={onLoading}
          refetch={refetch}
          propertyId={propertyId}
          propertyPrice={propertyPrice}
        />
      </FormProvider>
    </Modal>
  );
};

export default FormModal;
