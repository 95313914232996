import { FC, useRef } from 'react';
import { Slider } from './slider';
import GalleryModal from './full-screen-gallery/gallery-modal';
import { useAppSelector } from '../../../../../common/hooks';
import { useExposeDetailsImages } from '../../hooks/useExposeDetailsQueries';
import { setActiveSlide } from '../../redux/exposeSlice';

const Gallery: FC = () => {
  const isOpen = useAppSelector((state) => state.exposeDetails.isGalleryOpen);
  const { images } = useExposeDetailsImages();
  const activeSlide = useAppSelector(
    (state) => state.exposeDetails.activeSlide
  );
  const swiperRef = useRef<unknown>(null);

  return (
    <>
      <Slider
        images={images}
        activeSlide={activeSlide}
        setActiveSlide={setActiveSlide}
        ref={swiperRef}
      />
      {isOpen && (
        <GalleryModal
          setActiveSlide={setActiveSlide}
          activeSlide={activeSlide}
          images={images}
        />
      )}
    </>
  );
};

export { Gallery };
