import styled, { css } from 'styled-components';
import { hexToRGB } from '../../../../../common/utils/hex-to-rgb';

export const Container = styled.div<{
  isSelected: boolean;
  isDisabled?: boolean;
  height?: number;
  width?: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: ${({ width }) => width || 40}px;
  height: ${({ height }) => height || 40}px;
  padding: 12px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  background-color: ${({ isDisabled, isSelected }) =>
    isDisabled
      ? 'rgba(217,217,217, 1)'
      : isSelected
      ? 'rgba(242,245,247, 1)'
      : 'white'};
  ${({ isDisabled }) => !isDisabled && 'cursor: pointer;'}
  color: ${({ theme, isSelected, isDisabled }) =>
    hexToRGB(
      isDisabled ? theme.black : theme.blue,
      isDisabled ? 0.3 : isSelected ? 1 : 0.6
    )};
  border: solid ${({ isSelected }) => (isSelected ? 2 : 1)}px
    ${({ theme, isSelected }) =>
      isSelected ? theme.blue : theme.ctaDisabledBackground};

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      &:hover {
        border-color: ${({ theme }) => theme.blue};
        background-color: rgb(242, 245, 247);
        color: ${({ theme }) => theme.blue};
      }
    `}
`;
