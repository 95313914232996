import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CircleContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ContentContainer = styled.div<{
  paddingBottom?: number;
  minHeight?: number;
}>`
  padding-bottom: ${({ paddingBottom }) => paddingBottom || 0}px;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
`;

export const Warning = styled.div`
  padding: 12px 16px 12px 12px;
  border-radius: 3px;
  border: solid 1px #dacebc;
  background-color: rgba(162, 133, 87, 0.2);
  margin: 16px 0;
  display: flex;
  align-items: center;
  gap: 12px;
  i {
    min-width: 20px;
    min-height: 20px;
  }
  p {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.blue};
  }
`;
