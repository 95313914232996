import { SocioEconomicsType } from '../../../../generated';
import { AdvancedChartInterface, SimpleChardDataInterface } from './interface';

export const initialSimpleChartData: SimpleChardDataInterface = {
  data: { data: [], labels: [] },
};

export const initialAdvancedChartData: AdvancedChartInterface = {
  keys: [],
  data: {},
};

export const SimpleChartDataTypes: Set<SocioEconomicsType> = new Set([
  SocioEconomicsType.BirthRate,
  SocioEconomicsType.PopulationSizeTrend,
  SocioEconomicsType.PopulationSize,
  SocioEconomicsType.NetMigrationRate,
  SocioEconomicsType.CrimeRate,
  SocioEconomicsType.EmployedPopulation,
]);

export const AdvancedChartDataType: Set<SocioEconomicsType> = new Set([
  SocioEconomicsType.AgeGroups,
  SocioEconomicsType.EducationLevels,
  SocioEconomicsType.HouseholdStructure,
  SocioEconomicsType.ProfessionalDegrees,
]);

export const initialSimpleChartDataState = {
  population_size: initialSimpleChartData,
  population_size_trend: initialSimpleChartData,
  net_migration_rate: initialSimpleChartData,
  crime_rate: initialSimpleChartData,
  birth_rate: initialSimpleChartData,
  employed_population: initialSimpleChartData,
};

export const initialAdvancedChartDataState = {
  age_groups: initialAdvancedChartData,
  age_groups_subtype: initialAdvancedChartData,
  education_levels: initialAdvancedChartData,
  education_levels_subtype: initialAdvancedChartData,
  household_structure: initialAdvancedChartData,
  household_structure_subtype: initialAdvancedChartData,
  professional_degrees: initialAdvancedChartData,
  professional_degrees_subtype: initialAdvancedChartData,
};
