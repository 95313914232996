import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { Headline3 } from 'modules/common/components/ui/typography';
import { SecondaryButton } from 'modules/common/components/ui/buttons';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Section = styled.div`
  border: 1px solid ${(props) => props.theme.ctaDisabledBackground};
  border-radius: 3px;
  padding: 32px;

  @media ${device.mobile} {
    padding: 16px;
    border: none;
  }
`;

export const SuccessWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 40, 73, 0.9);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SuccessContainer = styled.div`
  width: 95vw;
  max-width: 416px;
  background: ${(props) => props.theme.white};
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
`;

export const SuccessIconContainer = styled.div`
  align-self: center;
`;

export const SuccessText = styled(Headline3)`
  font-size: 16px;
`;

export const SuccessButton = styled(SecondaryButton)`
  width: 100%;
`;
