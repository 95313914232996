import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { filter, isEmpty } from 'lodash';
import {
  Container,
  SectionTitle,
  UploadedFilesWrapper,
} from './uploaded-files-section-styles';
import { EmptyStatePlaceholder } from './empty-state-placeholder/empty-state-placeholder';
import { UploadedItem } from '../../../../common/components/form/input/input-file/uploaded-item/uploaded-item';

export enum UploadedBy {
  User = 'user',
  Broker = 'broker',
}

interface UploadedFile {
  title: string;
  fileName: string;
  size: number;
  onOpenFile: () => void;
  uploadedBy: UploadedBy;
  url?: string;
  previewUrl?: string;
}

export enum SectionType {
  Documents = 'Documents',
  Images = 'Images',
  VirtualTours = 'VirtualTours',
  DroneFootages = 'DroneFootages',
  PropertyPlans = 'PropertyPlans',
}

interface IProps {
  sectionType: SectionType;
  uploadedFiles?: UploadedFile[];
  searchInput?: string;
  isLocked?: boolean;
}

const UploadedFilesSectionBase: FC<IProps> = ({
  sectionType,
  uploadedFiles,
  searchInput,
  isLocked,
}) => {
  const { t } = useTranslation();
  const sectionName = useMemo(() => {
    switch (sectionType) {
      case SectionType.Documents:
        return t('upload-document.header');
      case SectionType.Images:
        return t('upload-image.header');
      case SectionType.VirtualTours:
        return t('upload-virtual-tour.header');
      case SectionType.DroneFootages:
        return t('upload-drone-footage');
      case SectionType.PropertyPlans:
        return t('upload-property-plan.header');
      default:
        return '';
    }
  }, [sectionType, t]);

  const filteredFiles = useMemo(() => {
    if (searchInput) {
      return filter(
        uploadedFiles,
        (file) =>
          file.fileName.toLowerCase().includes(searchInput.toLowerCase()) ||
          file.title.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    return uploadedFiles;
  }, [uploadedFiles, searchInput]);

  return (
    <Container
      onClick={(event) => event.stopPropagation()}
      role={'presentation'}
    >
      {!isEmpty(filteredFiles) ? (
        <>
          <UploadedFilesWrapper>
            {filteredFiles?.map(
              ({ title, fileName, size, onOpenFile, url, previewUrl }) => (
                <UploadedItem
                  key={fileName}
                  title={title}
                  fileName={fileName}
                  size={size}
                  onOpenFile={isLocked ? undefined : onOpenFile}
                  url={url}
                  previewImgUrl={previewUrl}
                />
              )
            )}
          </UploadedFilesWrapper>
        </>
      ) : (
        <EmptyStatePlaceholder
          text={t(
            'seller.overview-card.broker-files.empty-state.placeholder.text',
            {
              sectionName:
                sectionType === SectionType.VirtualTours
                  ? t('virtual-tour')
                  : sectionName,
            }
          )}
        />
      )}
    </Container>
  );
};

const UploadedFilesSection = memo(UploadedFilesSectionBase);

export { UploadedFilesSection };
