import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as CheckCircle } from 'assets/streamline-light/interface-essential/form-validation/check-circle-1.svg';
import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';

import { device } from '../../../../style/theme';
import { Modal, ModalTitle } from '../../../common/components/modal';
import { MainButton } from '../../../common/components/ui/buttons';
import Icon from '../../../common/components/ui/icon';
import {
  Headline2,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  textContent: string;
  acceptButton?: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${device.tablet} {
    padding: 0 16px;
    max-height: 100%;
    height: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  @media ${device.tablet} {
    background-color: ${(props) => props.theme.blue};
    width: 100%;
    justify-content: center;
    padding: 18px 0 18px 0;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  @media ${device.tablet} {
    position: absolute;
    right: 16px;
  }
`;

const Content = styled(ParagraphText)`
  text-align: center;
  margin-bottom: 32px;
`;

const Title = styled(Headline2)`
  margin: 16px 0;
`;

const descktopWrapperStyle = {
  maxWidth: 476,
  padding: '32px 32px 20px 32px',
};
const mobileWrapperStyle = {
  width: '100%',
  height: '100%',
  padding: 0,
  display: 'flex',
  flexDirection: 'column' as const,
};

const StyledBackButton = styled(MainButton)`
  @media ${device.tablet} {
    margin-top: auto;
    margin-bottom: 32px;
  }
`;

const SuccessModal = ({
  isOpen,
  onClose,
  title,
  textContent,
  acceptButton,
}: IProps): JSX.Element => {
  const isMobileSize = useIsMobileSize();
  const themeContext = useContext(ThemeContext);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      wrapperStyles={isMobileSize ? mobileWrapperStyle : descktopWrapperStyle}
    >
      {isMobileSize && (
        <Row>
          <ModalTitle
            text={title}
            textColor="white"
            textAlign="center"
            margin="0"
          />
          <IconContainer onClick={onClose}>
            <Icon
              icon={Close}
              width={16}
              height={16}
              color={themeContext.white}
            />
          </IconContainer>
        </Row>
      )}
      <Container>
        <Icon
          icon={CheckCircle}
          width={40}
          height={40}
          color={themeContext.green}
        />
        <Title content={title} />
        <Content content={textContent} />
        {!isMobileSize && acceptButton && (
          <MainButton label={acceptButton} onClick={onClose} />
        )}
        {isMobileSize && acceptButton && (
          <StyledBackButton label={acceptButton} onClick={onClose} fluid />
        )}
      </Container>
    </Modal>
  );
};

export { SuccessModal };
