import { siteMap } from '../site-map';

const useSearchProfilePageLogic = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const sortBy = urlParams.get('sort-by') || '';
  const searchProfileId = urlParams.get('searchProfileId') || '';
  const propertyId = urlParams.get('propertyId') || '';
  const notifications = urlParams.get('notifications') || '';

  if (
    window.location.href.includes(
      siteMap.SearchProfilePage.pathWithoutParams
    ) &&
    searchProfileId &&
    notifications
  ) {
    return `${siteMap.LoginBuyerPage.path}/?searchProfileId=${searchProfileId}&notifications=${notifications}`;
  }

  if (
    window.location.href.includes(
      siteMap.SearchProfilePage.pathWithoutParams
    ) &&
    sortBy &&
    searchProfileId
  ) {
    return `${
      siteMap.LoginBuyerPage.path
    }/?searchProfileId=${searchProfileId}&sortBy=${sortBy}${
      propertyId ? `&propertyId=${propertyId}` : ''
    }`;
  }
  return null;
};

export { useSearchProfilePageLogic };
