import { FC, memo, useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { ModalContainer } from '../../../components/fullscreen-modal/modal-container/modal-container';
import { useArrowImage } from '../../../hooks/useArrowImage';
import { useModalButtonsHandler } from '../../hooks/useModalButtonsHandler';
import { ISearchProfileForm } from '../../interface';
import { SearchProfileFormContainer } from '../containers/search-profile-form-container/search-profile-form-container';
import { SearchProfileInfoContainer } from '../containers/search-profile-info-container/search-profile-info-container';
import { useSubmitFormSP } from '../../hooks/useSubmitFormSP';
import { updateIsSuggestedOutdated } from '../../redux/searchProfileSlice';
import { PropertyCode } from 'generated';

const SearchProfileModalBase: FC = () => {
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const {
    isSPCOpen,
    isRegisterFormOpen,
    isLoginFormOpen,
    activeStep,
    userInput,
  } = useAppSelector((state) => state.searchProfile);
  const { setValue, ...formMethods } = useForm<ISearchProfileForm>({
    mode: 'all',
    defaultValues: {},
  });

  const [didInit, setDidInit] = useState(false);
  useEffect(() => {
    if (didInit || userInput === undefined) return;

    Object.entries(userInput).forEach(([key, value]) =>
      setValue(key as keyof ISearchProfileForm, value)
    );

    setDidInit(true);
  }, [userInput]);

  const { arrowRef, arrowPosition } = useArrowImage();
  const { onCloseClick, onBackClick } = useModalButtonsHandler({
    reset: formMethods.reset,
    watch: formMethods.watch,
  });
  const { onSubmitSPForm, suggestedPropertiesResponse } = useSubmitFormSP();

  return (
    <ModalContainer
      onCloseModalTitle="modal.widget-on-close.title"
      onCloseModalText="modal.widget-on-close.text"
      isOpen={isSPCOpen}
      isConfirmEmailSent={false}
      isAuthFlow={false}
      isRegisterFormOpen={isRegisterFormOpen}
      isLoginFormOpen={isLoginFormOpen}
      activeStep={activeStep}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formMethods={{ setValue, ...formMethods }}
      arrowPosition={arrowPosition}
      onBackClick={() => {
        dispatch(updateIsSuggestedOutdated(true));
        onBackClick();
      }}
      onCloseClick={onCloseClick}
    >
      <SearchProfileFormContainer
        initialSuggestedPropertiesData={suggestedPropertiesResponse}
        onSubmitSPForm={onSubmitSPForm}
      />
      {!isMobileSize && (
        <SearchProfileInfoContainer
          initialSuggestedPropertiesData={suggestedPropertiesResponse}
          ref={arrowRef}
        />
      )}
    </ModalContainer>
  );
};

export { SearchProfileModalBase as SearchProfileModal };
