import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.greyBackground};
  margin: 2px 0 0;
  min-height: 12px;
  position: relative;
`;

export const ProgressLine = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  height: 12px;
  background-color: ${({ theme }) => theme.blue};
  transition: all 0.5s;
`;

export const Divider = styled.div`
  display: none;

  @media ${device.tablet} {
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 2px;
    background-color: ${({ theme }) => theme.borderColor};
  }
`;
