import { FC, memo } from 'react';
import { useAppSelector } from '../../../../../../../common/hooks';

import DescriptionContainer from './description/description';
import Login from './login/login';
import Register from './register/register';

const AuthFormStep: FC<{
  onAuthSuccess: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialSuggestedPropertiesData: any;
}> = ({ onAuthSuccess, initialSuggestedPropertiesData }) => {
  const isRegisterFormOpen = useAppSelector(
    (state) => state.searchProfile.isRegisterFormOpen
  );
  const isLoginFormOpen = useAppSelector(
    (state) => state.searchProfile.isLoginFormOpen
  );

  if (isRegisterFormOpen) {
    return <Register onAuthSuccess={onAuthSuccess} />;
  }

  if (isLoginFormOpen) {
    return <Login onAuthSuccess={onAuthSuccess} />;
  }

  return <DescriptionContainer initialSuggestedPropertiesData={initialSuggestedPropertiesData} />;
};

export { AuthFormStep };
