import { RefObject, useMemo, useRef } from 'react';

export type TArrowPosition = { top: number; left: number };

type TReturn = {
  arrowPosition: TArrowPosition;
  arrowRef: RefObject<HTMLDivElement>;
};

const useArrowImage = (): TReturn => {
  const arrowRef = useRef<HTMLDivElement>(null);
  const offset = arrowRef.current?.getBoundingClientRect();
  const arrowPosition = useMemo(() => {
    return {
      top: Number(offset?.top) - 80,
      left: Number(offset?.left) - 155,
    };
  }, [offset?.left, offset?.top]);

  return { arrowPosition, arrowRef };
};

export { useArrowImage };
