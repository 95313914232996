import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { stepsComponents } from '../../constants/steps';
import PurchaseTypeSelector from '../common/purchase-type-selector';
import FormModal from '../modals/form-modal';

interface FormPurchaseIntendProps {
  isOpen: boolean;
  refetch: () => void;
  propertyId?: string;
  propertyPrice?: number;
  onClose?: () => void;
}

const FormPurchaseIntend = ({
  isOpen,
  refetch,
  propertyId,
  propertyPrice,
  onClose,
}: FormPurchaseIntendProps): JSX.Element => {
  const { activeStep, structure, type } = useSelector(
    (state: RootState) => state.purchaseIntend
  );

  const Component = stepsComponents[structure[type][activeStep]];

  return (
    <FormModal
      onClose={onClose}
      isOpen={isOpen}
      refetch={refetch}
      propertyId={propertyId}
      propertyPrice={propertyPrice}
    >
      <form>
        {activeStep === 0 && <PurchaseTypeSelector />}
        {Component && (
          <Component
            key={`${structure[type][activeStep]}--${activeStep}`}
            propertyPrice={propertyPrice}
          />
        )}
      </form>
    </FormModal>
  );
};

export default FormPurchaseIntend;
