import { FC } from 'react';
import {
  Container,
  MainContentContainer,
  MainContainer,
  AppleStoreContainer,
  GooglePlayContainer,
  PreviewImageContainer,
  PreviewImage,
  Description,
  Title,
  Name,
  MarketButtons,
} from './apps-styles';
import AppsSectionPicture from '../../../../../assets/apps-section.png';
import GooglePlayStore from '../../../../../assets/google-play-store.png';
import AppStore from '../../../../../assets/app-store.png';
import {
  Apps as AppsType,
  OverviewApps as OverviewAppsType,
  RpSection,
  useReadAppQuery,
} from '../../../../../generated';

interface IProps {
  section: RpSection;
}

interface AppProps {
  app: OverviewAppsType;
  overviewLP?: boolean;
}

const App: FC<AppProps> = ({ app, overviewLP }: AppProps) => {
  return (
    <Container>
      <MainContainer overviewLP={overviewLP}>
        <MainContentContainer>
          <div>
            <Name overviewLP={overviewLP}>{app?.subTitle}</Name>
          </div>
          <div>
            <Title overviewLP={overviewLP}>{app?.title}</Title>
          </div>
          <div>
            <Description overviewLP={overviewLP}>
              <div
                dangerouslySetInnerHTML={{ __html: app?.expandedText ?? '' }}
              />
            </Description>
          </div>
          <MarketButtons overviewLP={overviewLP}>
            <AppleStoreContainer>
              <a
                rel="noreferrer"
                target="_blank"
                href={
                  app?.appStoreLink ??
                  'https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1024px-Google_Play_Store_badge_EN.svg.png'
                }
              >
                <img src={AppStore} alt="Download on the App Store" />
              </a>
            </AppleStoreContainer>
            <GooglePlayContainer>
              <a
                rel="noreferrer"
                target="_blank"
                href={
                  app?.googlePlayLink ??
                  'https://play.google.com/store/apps/details?id=de.fabrik19.vonpoll&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                }
              >
                <img alt="Get it on Google Play" src={GooglePlayStore} />
              </a>
            </GooglePlayContainer>
          </MarketButtons>
        </MainContentContainer>
      </MainContainer>
      <PreviewImageContainer>
        <PreviewImage
          src={app?.imageUrl ?? AppsSectionPicture}
          alt={app?.imageAlt ?? 'preview phone application'}
          key={app?.imageKey ?? ''}
        />
      </PreviewImageContainer>
    </Container>
  );
};

interface AppLandingPageProps {
  id: string;
}

interface AppResponse {
  data: { data: AppsType };
  isLoading: boolean;
}

const AppLandingPage: FC<AppLandingPageProps> = ({ id }) => {
  const { data, isLoading } = useReadAppQuery<AppResponse>({
    id,
  });

  if (isLoading) return null;

  const app = data?.data;

  return (
    <App
      app={
        {
          ...app,
          subTitle: app?.subtitle ?? '',
          expandedText: app?.text ?? '',
        } as OverviewAppsType
      }
    />
  );
};

const Apps: FC<IProps> = ({ section }) => {
  const { recordIds } = section;

  return (
    <div>
      {recordIds.map((id: string) => {
        return <AppLandingPage key={id} id={id} />;
      })}
    </div>
  );
};

export { Apps, App };
