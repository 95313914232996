import { FC, useEffect, useRef, useState } from 'react';
import {
  BoxButtons,
  ContentContainer,
  Description,
  ImageBox,
  InfoBox,
  LinkBox,
  LinkIconWrapper,
  LinkWrapper,
  StyledImg,
  Title,
} from './overview-item-styles';
import { MainButton } from '../../../../../common/components/ui/buttons';
import { ReactComponent as EyeIcon } from '../../../../../../assets/streamline-light/interface-essential/view/view.svg';

interface OverviewItemProps {
  revertContent?: boolean; // false - Buyer; true - Owner
  ctaHandler?: () => void;
  isProportional?: boolean;
  overview: {
    title: string;
    expandedText: string;
    buttonLabel: string;
    imageKey: string;
    imageUrl: string;
    imageAlt: string;
    link?: string;
    linkLabel?: string;
  };
}

const OverviewItem: FC<OverviewItemProps> = ({
  revertContent,
  ctaHandler,
  overview,
  isProportional,
}) => {
  const imageBoxRef = useRef(null);
  const infoBoxRef = useRef(null);

  const [marginBottom, setMarginBottom] = useState(88);

  const marginHandler = () => {
    const { current: currentImageBox } = imageBoxRef;
    const { current: currentInfoBox } = infoBoxRef;

    if (currentImageBox && currentInfoBox) {
      const { clientHeight: imageBoxHeight } =
        currentImageBox || ({} as { clientHeight: number });
      const { clientHeight: infoBoxHeight } =
        currentInfoBox || ({} as { clientHeight: number });

      if (imageBoxHeight && infoBoxHeight) {
        setMarginBottom(infoBoxHeight + 116 - imageBoxHeight + 88);
      }

      if (isProportional) {
        setMarginBottom(51);
      }
    }
  };

  useEffect(() => {
    marginHandler();
  }, []);

  return (
    <ContentContainer marginBottom={!revertContent ? 115 : marginBottom}>
      <ImageBox
        isProportional={isProportional}
        revertContent={revertContent}
        ref={imageBoxRef}
      >
        <StyledImg
          isProportional={isProportional}
          revertContent={revertContent}
          onLoad={marginHandler}
          key={overview?.imageKey}
          src={overview?.imageUrl}
          alt={overview?.imageAlt}
        />
      </ImageBox>
      <InfoBox revertContent={revertContent} ref={infoBoxRef}>
        <Title data-swiper-parallax="0" content={overview?.title} />
        <Description
          data-swiper-parallax="0"
          dangerouslySetInnerHTML={{ __html: overview?.expandedText ?? '' }}
        />
        <BoxButtons>
          <MainButton
            data-swiper-parallax="0"
            label={overview?.buttonLabel}
            onClick={ctaHandler}
            style={{ textTransform: 'uppercase' }}
          />
          {!!overview?.link && !!overview?.linkLabel && (
            <LinkWrapper
              target="_blank"
              rel="noreferrer"
              isWithoutLng
              link={overview?.link}
            >
              <LinkIconWrapper icon={EyeIcon} width={20} height={20} />
              <LinkBox content={overview?.linkLabel} />
            </LinkWrapper>
          )}
        </BoxButtons>
      </InfoBox>
    </ContentContainer>
  );
};

export { OverviewItem };
