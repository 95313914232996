import { memo, useEffect } from 'react';
import styled from 'styled-components';
import UnsubscribeIcon from 'assets/unsubscribe.png';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Headline1,
  ParagraphText,
} from '../../common/components/ui/typography';
import { device } from '../../../style/theme';
import { useUnsubscribeFromNotificationsMutation } from '../../../generated';
import { GlobalLoader } from '../../common/components/ui/loaders/global-loader';
import { GlobalError } from '../../common/components/form/error/global-error';

const Container = styled.div`
  display: flex;
  margin: 110px 0 110px 0;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 520px;
  gap: 24px;

  @media ${device.mobile} {
    padding: 8px;
  }
`;

const Icon = styled.img`
  width: 123px;
  height: 123px;
`;

const Title = styled(Headline1)`
  text-align: center;

  @media ${device.mobile} {
    font-size: 26px;
  }
`;

const Description = styled(ParagraphText)`
  text-align: center;
`;

const UnsubscribePageBase = () => {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();

  const [unsubscribeFromNotifications, { isLoading, error }] =
    useUnsubscribeFromNotificationsMutation();

  useEffect(() => {
    unsubscribeFromNotifications({ userId });
  }, []);

  if (isLoading) {
    return <GlobalLoader />;
  }

  return (
    <Container>
      <Content>
        {error?.message && (
          <GlobalError title={t(error?.message?.split(':')[0].toLowerCase())} />
        )}
        <Icon src={UnsubscribeIcon} alt={'unsubscribe'} />
        <Title content={t('unsubscribe-page.title')} />
        <Description content={t('unsubscribe-page.description')} />
      </Content>
    </Container>
  );
};

const UnsubscribePage = memo(UnsubscribePageBase);

export { UnsubscribePage };
