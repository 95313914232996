import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { ReactComponent as BuildingYear } from 'assets/streamline-light/building-construction/utilities/building-year.svg';
import { ReactComponent as HouseGarage } from 'assets/streamline-light/building-construction/houses/house-garage.svg';
import { ReactComponent as ResizeExpandSides } from 'assets/streamline-light/design/resize/resize-expand-sides.svg';
import { ReactComponent as HotelDoubleBed2 } from 'assets/streamline-light/hotels-spas/hotel-bedrooms/hotel-double-bed-2.svg';
import { ReactComponent as RealEstateDimensionsBlock } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as RealEstateDimensionsPlan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-plan.svg';
import { ReactComponent as RoadSignParking } from 'assets/streamline-light/transportation/signs/road-sign-parking.svg';
import { ReactComponent as CashShield } from 'assets/streamline-light/business-products/business/cash-shield.svg';
import { ReactComponent as Business1 } from 'assets/streamline-light/building-construction/buildings/building-1.svg';
import { ReactComponent as Energy } from 'assets/streamline-light/ecology/renewable-energy/renewable-energy-charging.svg';
import { ReactComponent as AccountingBills } from 'assets/streamline-light/money-payments-finance/money/accounting-bills-1.svg';

import {
  ParkingSpaces,
  PropertyCode,
  PropertyStatus,
} from '../../../../generated';
import { device } from '../../../../style/theme';
import {
  Headline2,
  ParagraphText,
  TitleSlogan,
} from '../../../common/components/ui/typography';
import { extractPropertyTypeLabelAndIcon } from '../../utils/property-type-normalizer';
import PropertyValuationIcon from '../icon/property-valuation-icon';
import { Tooltip } from '../../../common/components/tooltip';
import { formatPrice } from 'modules/seller/utils/formatPrice';
import {
  OutdoorParkingSpaceKeys,
  ParkingSpaceLabelByKey,
} from '../../constants';
import { useGetPortalType } from '../../../landing/hooks/useGetPortalType';

interface IProps {
  buildingYear?: number | null;
  code?: PropertyCode | null;
  livingArea?: number | null;
  landArea?: number | null;
  numberOfRooms?: number | null;
  numberOfBathrooms?: number | null;
  numberOfIndoorParkingSpaces?: number | null;
  numberOfOutdoorParkingSpaces?: number | null;
  numberOfUnits?: number | null;
  annualRentIncome?: number | null;
  annualRentIncomeLabel?: string;
  header: string;
  title: string;
  content: string;
  energyLabel?: string | null;
  priceLabel?: string;
  status?: PropertyStatus | null;
  parkingSpaces?: ParkingSpaces | null;
  isUnderConstruction?: boolean | null;
}

const Container = styled.div<{ backgroundColor?: string; color?: string }>`
  &&& {
    width: 100%;
    padding: 32px 80px 32px 32px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    color: ${(props) => props.theme.blue};
    @media ${device.tablet} {
      padding: 16px;
      max-width: unset;
    }
  }
`;

const Header = styled(TitleSlogan)`
  opacity: 0.8;
  text-transform: uppercase;
`;

const Title = styled(Headline2)`
  margin: 4px 0 16px;
  line-height: 1.34;
  @media ${device.tablet} {
    margin-bottom: 12px;
  }
`;

const Content = styled(ParagraphText)`
  margin-bottom: 16px;
  line-height: 1.72;
`;

const Text = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 39vw !important;
  }
`;

const IconRow = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-row: 2;
  row-gap: 10px;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-rows: 4;
  }
`;

const PropertyValuationOverviewCardBase = ({
  header,
  title,
  content,
  code,
  buildingYear,
  livingArea,
  landArea,
  numberOfRooms,
  numberOfBathrooms,
  numberOfIndoorParkingSpaces,
  numberOfOutdoorParkingSpaces,
  numberOfUnits,
  annualRentIncome,
  annualRentIncomeLabel,
  energyLabel,
  priceLabel,
  status,
  parkingSpaces,
  isUnderConstruction,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { icon, label } = extractPropertyTypeLabelAndIcon(code);
  const { isSeller } = useGetPortalType();

  const parkingSpacesWithLabel = useMemo(() => {
    if (isEmpty(parkingSpaces)) {
      return [];
    }

    return Object.keys(parkingSpaces || {})
      ?.filter(
        (key) =>
          !OutdoorParkingSpaceKeys.includes(key) &&
          parkingSpaces?.[key as keyof typeof parkingSpaces] !== 0
      )
      ?.map((key) => ({
        label: ParkingSpaceLabelByKey.get(key),
        value: parkingSpaces?.[key as keyof typeof parkingSpaces],
      }));
  }, [parkingSpaces]);

  return (
    <Container>
      <Header content={t(header)} />
      <Title content={t(title)} />
      <Content content={t(content)} />
      <IconContainer>
        <IconRow>
          <PropertyValuationIcon
            icon={icon}
            value={`${t(label)}`}
            type={t('property-overview.type')}
          />
          <PropertyValuationIcon
            icon={BuildingYear}
            value={isUnderConstruction || !buildingYear ? '-' : buildingYear}
            type={t('property-overview.building-year')}
          />
          <PropertyValuationIcon
            icon={RealEstateDimensionsBlock}
            value={`${livingArea ?? 0} m²`}
            type={t('property-overview.living-area')}
          />
          {code !== PropertyCode.Apartment && (
            <PropertyValuationIcon
              icon={ResizeExpandSides}
              value={`${landArea ?? 0} m²`}
              type={t('property-overview.land-area')}
            />
          )}
          {code !== PropertyCode.MultiFamilyHouse && (
            <>
              <PropertyValuationIcon
                icon={HotelDoubleBed2}
                value={numberOfRooms ?? 0}
                type={t('property-overview.number-of-rooms')}
              />
              <PropertyValuationIcon
                icon={RealEstateDimensionsPlan}
                value={numberOfBathrooms ?? 0}
                type={t('property-overview.number-of-bathrooms')}
              />
              {isSeller ? (
                <>
                  {parkingSpacesWithLabel?.map((parkingSpace) => (
                    <PropertyValuationIcon
                      icon={HouseGarage}
                      value={parkingSpace.value ?? 0}
                      type={t(parkingSpace.label || '')}
                    />
                  ))}
                </>
              ) : (
                <PropertyValuationIcon
                  icon={HouseGarage}
                  value={numberOfIndoorParkingSpaces ?? 0}
                  type={t('property-overview.number-of-indoor-parking-spaces')}
                />
              )}
              <PropertyValuationIcon
                icon={RoadSignParking}
                value={numberOfOutdoorParkingSpaces ?? 0}
                type={t('property-overview.number-of-outdoor-parking-spaces')}
              />
            </>
          )}
          {code === PropertyCode.MultiFamilyHouse && (
            <>
              <PropertyValuationIcon
                icon={Business1}
                value={numberOfUnits ?? '-'}
                type={t('property-overview.number-of-units')}
              />
              <PropertyValuationIcon
                icon={CashShield}
                value={annualRentIncomeLabel ?? annualRentIncome ?? '-'}
                type={t('property-overview.annual-rent-income')}
              />
            </>
          )}
          {energyLabel && (
            <Tooltip
              id="energy-label"
              textAlign="left"
              maxWidth={150}
              content={
                <PropertyValuationIcon
                  icon={Energy}
                  value={t(energyLabel).toString()}
                  type={t('property-overview.energy-label')}
                />
              }
            >
              <Text>{t(energyLabel)}</Text>
            </Tooltip>
          )}
          {priceLabel && (
            <PropertyValuationIcon
              icon={AccountingBills}
              value={priceLabel}
              type={
                status === PropertyStatus.Selling
                  ? t('property-overview.price')
                  : t('seller-properties-list.evaluation-price')
              }
            />
          )}
        </IconRow>
      </IconContainer>
    </Container>
  );
};

const PropertyValuationOverviewCard = memo(PropertyValuationOverviewCardBase);

export { PropertyValuationOverviewCard };
