import { FC, useMemo } from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer';

import { formatNumber } from '../../../property/utils/format-number';
import { formatPrice } from '../../../seller/utils/formatPrice';
import { MarketingGeneralStatistics } from '../../../../generated';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  content: {
    width: '508px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: '10px',
    borderRadius: '3px',
    border: '1px solid rgba(217, 217, 217, 1)',
    opacity: 1,
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  item: {
    margin: '6px',
    width: '148px',
    height: '72px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '12px',
    borderRadius: '3px',
    border: '1px solid rgba(217, 217, 217, 1)',
    opacity: 1,
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  itemValue: {
    opacity: 1,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'SourceSerifPro',
    fontSize: '20px',
    fontWeight: 'semibold',
    letterSpacing: '0.5px',
    textAlign: 'left',
  },
  itemLabel: {
    opacity: 0.8,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    textAlign: 'left',
  },
});

interface IProps {
  statistics?: MarketingGeneralStatistics | null;
  priceCurrency: string;
  isSimulation?: boolean;
  showRequestedExposes?: boolean;
  showUnlockedExposes?: boolean;
  showViewingAppointments?: boolean;
}

const Statistics: FC<IProps> = ({
  statistics,
  priceCurrency,
  isSimulation,
  showRequestedExposes,
  showUnlockedExposes,
  showViewingAppointments,
}) => {
  const formattedStatistics = useMemo(() => {
    const result = [
      {
        name: 'advertisementViews',
        label: 'Inserataufrufe',
        value: String(formatNumber(statistics?.advertisementViews ?? 0)),
      },
    ];

    if (showRequestedExposes) {
      result.push({
        name: 'requestedExposes',
        label: 'Angeforderte Exposés',
        value: String(formatNumber(statistics?.requestedExposes ?? 0)),
      });
    }

    if (showUnlockedExposes) {
      result.push({
        name: 'unlockedExposes',
        label: 'Versendete Exposés',
        value: String(formatNumber(statistics?.unlockedExposes ?? 0)),
      });
    }

    if (showViewingAppointments) {
      result.push({
        name: 'viewingAppointments',
        label: 'Besichtigungstermine',
        value: String(formatNumber(statistics?.viewingAppointments ?? 0)),
      });
    }

    if (statistics?.impressions) {
      result.unshift({
        name: 'impressions',
        label: 'Impressionen',
        value: String(formatNumber(statistics?.impressions ?? 0)),
      });
    }

    if (statistics?.bookmarks) {
      result.push({
        name: 'bookmarks',
        label: 'Gemerkt',
        value: String(formatNumber(statistics?.bookmarks ?? 0)),
      });
    }

    result.push(
      {
        name: 'bidsNumber',
        label: 'Anzahl Gebote',
        value: String(formatNumber(statistics?.bids ?? 0)),
      },
      {
        name: 'highestBid',
        label: 'Höchstgebot',
        value: formatPrice(
          statistics?.highestBid ?? 0,
          priceCurrency === '€' ? 'EUR' : 'CHF'
        ),
      }
    );

    return result;
  }, [
    isSimulation,
    priceCurrency,
    showRequestedExposes,
    showUnlockedExposes,
    showViewingAppointments,
    statistics?.advertisementViews,
    statistics?.bids,
    statistics?.bookmarks,
    statistics?.highestBid,
    statistics?.impressions,
    statistics?.requestedExposes,
    statistics?.unlockedExposes,
    statistics?.viewingAppointments,
  ]);

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {formattedStatistics.map((item, index) => (
          <View style={styles.item} key={index}>
            <Text style={styles.itemValue}>{item.value}</Text>
            <Text style={styles.itemLabel}>{item.label}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export { Statistics };
