import { useContext, useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { ReactComponent as AddCircle } from 'assets/streamline-light/interface-essential/remove-add/add-circle.svg';

import { InvitationPrivileges, Invite } from '../../../../../generated';
import {
  useEditInvitationMutation,
  useSharePropertyMutation,
} from '../../../../../services/graphql/enhanced';
import { device } from '../../../../../style/theme';
import { InputType } from '../../../../auth/interfaces';
import { InputOutsideTopLabel } from '../../../../common/components/form';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import {
  FormColumn,
  FormGridWrapper,
  FormRow,
} from '../../../../common/components/form/form-grid';
import {
  Modal,
  ModalFooter,
  ModalTitle,
} from '../../../../common/components/modal';
import {
  BorderButton,
  MainButton,
} from '../../../../common/components/ui/buttons';
import Icon from '../../../../common/components/ui/icon';
import { EMAIL_REGEX } from '../../../../common/constants';
import { useAppSelector } from '../../../../common/hooks';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { BoxSelector } from '../../../../forms/components/common/box-selector';
import { BoxType } from '../../../../forms/interface';
import { ICollaborationUser } from '../../../interfaces';

interface ISharePropertyInvitationForm {
  isOpen: boolean;
  onCloseModal: () => void;
  existingInvitations?: Invite[];
}

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  @media ${device.tablet} {
    background-color: ${(props) => props.theme.blue};
    width: 100%;
    justify-content: center;
    padding: 18px 0 18px 0;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  @media ${device.tablet} {
    position: absolute;
    right: 16px;
  }
`;

const Form = styled.form`
  display: block;
  @media ${device.tablet} {
    padding: 0 16px;
  }
`;

const RoleTitle = styled.h3`
  margin: 0 0 16px;
  font-family: 'Source Serif Pro';
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.blue};
`;

const RoleSection = styled.div`
  margin: 4px 0 32px;
`;

const SharePropertyModalInvitation = ({
  isOpen,
  onCloseModal,
  existingInvitations,
}: ISharePropertyInvitationForm): JSX.Element => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const themeContext = useContext(ThemeContext);

  const { propertyId } = useParams<{ propertyId: string }>();
  const isEditMode = useAppSelector(
    (state) => state.propertyCollaborators.modifyCollaborator
  );

  const [
    shareProperty,
    { isLoading: isMutationLoading, error: mutationErrors },
  ] = useSharePropertyMutation();

  const [
    editInvitation,
    { isLoading: isEditMutationLoading, error: editMutationError },
  ] = useEditInvitationMutation();

  const defaultValues = useMemo(
    () => existingInvitations?.find((i) => i._id === isEditMode),
    [existingInvitations, isEditMode]
  );

  const methods = useForm<{
    name: string;
    surname: string;
    email: string;
    invitationPrivileges: InvitationPrivileges;
  }>({
    mode: 'onTouched',
    defaultValues,
  });

  const { reset } = methods;

  const desktopWrapperStyle = {
    maxWidth: 512,
  };
  const mobileWrapperStyle = {
    width: '100%',
    height: '100%',
    padding: 0,
    overflow: 'overlay',
  };

  const onSubmit: SubmitHandler<ICollaborationUser> = async (formData) => {
    if (isEditMode) {
      if (
        formData.invitationPrivileges === defaultValues?.invitationPrivileges
      ) {
        onCloseModal();
        return;
      }

      try {
        const editInvitationResponse = await editInvitation({
          input: {
            _id: isEditMode,
            invitationPrivileges: formData.invitationPrivileges,
          },
        }).unwrap();
        if (editInvitationResponse?.editInvitation) {
          reset();
          onCloseModal();
        }
        return;
      } catch (error) {
        console.error(error);
        return;
      }
    }
    try {
      const invitationResponse = await shareProperty({
        input: { ...formData, propertyId },
      }).unwrap();

      if (invitationResponse?.shareProperty) {
        reset();
        onCloseModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    reset({
      invitationPrivileges:
        defaultValues?.invitationPrivileges ?? InvitationPrivileges.Spectator,
    });
  }, [reset, isOpen, defaultValues?.invitationPrivileges]);

  return (
    <Modal
      isOpen={isOpen}
      wrapperStyles={isMobileSize ? mobileWrapperStyle : desktopWrapperStyle}
      onClose={onCloseModal}
    >
      <Row>
        <ModalTitle
          icon={isMobileSize ? undefined : AddCircle}
          text={t('share.property.form.invitation.title')}
          textColor={isMobileSize ? 'white' : undefined}
          iconPosition={isMobileSize ? undefined : 'left'}
          textAlign={isMobileSize ? 'center' : 'left'}
          margin="0"
        />
        <IconContainer onClick={onCloseModal}>
          <Icon
            icon={Close}
            width={16}
            height={16}
            color={themeContext.white}
          />
        </IconContainer>
      </Row>
      {(mutationErrors || editMutationError) && (
        <GlobalError
          title={t(
            (mutationErrors?.message?.split(':')[0] ||
              editMutationError?.message?.split(':')[0]) ??
              ''
          )}
        />
      )}
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormGridWrapper>
            <FormRow>
              <FormColumn>
                <InputOutsideTopLabel
                  name="name"
                  type={InputType.text}
                  label="share.property.form.input.label.name"
                  placeholder="share.property.form.input.placeholder.name"
                  rules={{
                    required: {
                      value: !defaultValues?.name,
                      message: 'register.input.error.required',
                    },
                  }}
                  defaultValue={defaultValues?.name}
                  isDisabled={!!isEditMode}
                />
              </FormColumn>
              <FormColumn>
                <InputOutsideTopLabel
                  name="surname"
                  type={InputType.text}
                  label="share.property.form.input.label.surname"
                  placeholder="share.property.form.input.placeholder.surname"
                  rules={{
                    required: {
                      value: !defaultValues?.surname,
                      message: 'register.input.error.required',
                    },
                  }}
                  defaultValue={defaultValues?.surname}
                  isDisabled={!!isEditMode}
                />
              </FormColumn>
              <FormColumn flex="0 0 100%">
                <InputOutsideTopLabel
                  name="email"
                  type={InputType.email}
                  label="share.property.form.input.label.email"
                  placeholder="share.property.form.input.placeholder.email"
                  rules={{
                    required: {
                      value: !defaultValues?.email,
                      message: 'register.input.error.required',
                    },
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'share.property.invalid.email.form',
                    },
                    validate: (v) =>
                      !existingInvitations?.some((e) => e.email === v) ||
                      'share.property.email.already.invited',
                  }}
                  defaultValue={defaultValues?.email}
                  isDisabled={!!isEditMode}
                />
              </FormColumn>
            </FormRow>
          </FormGridWrapper>

          <RoleSection>
            <RoleTitle>{t('share.property.form.select.role.title')}</RoleTitle>

            <BoxSelector
              name="invitationPrivileges"
              boxes={[
                {
                  boxType: BoxType.radioBox,
                  title: 'share.property.form.radio.title.role.spectator',
                  text: 'share.property.form.radio.text.role.spectator',
                  value: InvitationPrivileges.Spectator,
                },
                {
                  boxType: BoxType.radioBox,
                  title: 'share.property.form.radio.title.role.editor',
                  text: 'share.property.form.radio.text.role.editor',
                  value: InvitationPrivileges.Editor,
                },
              ]}
              defaultValue={
                defaultValues?.invitationPrivileges ??
                methods.getValues('invitationPrivileges')
              }
              containerStyles={{ flexDirection: 'column' }}
            />
          </RoleSection>

          <ModalFooter>
            {!isMobileSize && (
              <BorderButton
                label={t('share.property.form.invitation.cancel')}
                type="button"
                onClick={onCloseModal}
              />
            )}

            <MainButton
              label={t('share.property.form.invitation.submit')}
              loader
              isLoading={isMutationLoading || isEditMutationLoading}
              fluid={isMobileSize}
              style={{ justifyContent: 'center' }}
            />
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default SharePropertyModalInvitation;
