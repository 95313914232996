import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { ReactComponent as VPLogo } from 'assets/navigation/vp-logo-transparent.svg';
// import dropdownArrowImg from 'assets/navigation/dropdown-arrow.svg'
import facebook from 'assets/navigation/facebook.svg';
import instagram from 'assets/navigation/instagram.svg';
import youtube from 'assets/navigation/youtube.svg';
import linkedin from 'assets/navigation/linkedin.svg';
import xing from 'assets/navigation/xing.svg';
import AppStore from 'assets/images/footer/appstore.png';
import GooglePlay from 'assets/images/footer/google-play.png';
import FooterStack1 from 'assets/images/footer/footer-stack-1.png';
import FooterStack2 from 'assets/images/footer/footer-stack-2.png';
import FooterStack3 from 'assets/images/footer/footer-stack-3.png';
import Footer1 from 'assets/images/footer/footer-1.png';
import Footer2 from 'assets/images/footer/footer-2.png';
import Footer3 from 'assets/images/footer/footer-3.png';
import Footer4 from 'assets/images/footer/footer-4.png';
import Footer5 from 'assets/images/footer/footer-5.png';
import { useGetProvenExpertRatingQuery } from '../../../../generated';
import { device } from '../../../../style/theme';
import LngLink from '../../../localization/lng-link';
import Icon from '../ui/icon';
import CollapseFooter from './collapse-footer';
import { CustomerFeedback } from '../customer-feedback/customer-feedback';
import { useAppSelector } from '../../hooks';
import { ReactComponent as Star1 } from 'assets/svg/star1.svg';
import { ReactComponent as Star12 } from 'assets/svg/star1-2.svg';
import { ReactComponent as Star34 } from 'assets/svg/star3-4.svg';
import { ReactComponent as Star5 } from 'assets/svg/star5.svg';
import { ReactComponent as Star67 } from 'assets/svg/star6-7.svg';
import { ReactComponent as Star89 } from 'assets/svg/star8-9.svg';

const footerLinks = [
  {
    title: 'footer.menu.sell',
    children: [
      {
        label: 'footer.menu.sell.house',
        path: 'https://www.von-poll.com/de/immobilien/immobilie-verkaufen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer&utm_content=haus',
      },
      {
        label: 'footer.menu.sell.flat',
        path: 'https://www.von-poll.com/de/immobilien/immobilie-verkaufen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer&utm_content=wohnung',
      },
      {
        label: 'footer.menu.sell.family-house',
        path: 'https://www.von-poll.com/de/immobilien/immobilie-verkaufen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer&utm_content=mfh',
      },
      {
        label: 'footer.menu.sell.tips',
        path: 'https://www.von-poll.com/de/immobilien/immobilie-verkaufen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer&utm_content=tipps',
      },
    ],
  },
  {
    title: 'footer.menu.buy',
    children: [
      {
        label: 'footer.menu.buy.house',
        path: 'https://www.von-poll.com/de/immobilie-kaufen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.buy.flat',
        path: 'https://www.von-poll.com/de/wohnung-kaufen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.buy.land',
        path: 'https://www.von-poll.com/de/grundstueck-kaufen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.buy.vila',
        path: 'https://www.von-poll.com/de/villa-kaufen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.buy.castle',
        path: 'https://www.von-poll.com/de/burg-oder-schloss-kaufen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.buy.mansion',
        path: 'https://www.von-poll.com/de/immobilien/land-und-herrenhaeuser?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.buy.new-built',
        path: 'https://www.von-poll.com/de/immobilien/neubau?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.buy.rest',
        path: 'https://www.von-poll.com/de/immobilien/ferienimmobilien?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.buy.commercial',
        path: 'https://www.von-poll.com/de/commercial/bueroflaechen-einzelhandelsflaechen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.buy.tips',
        path: 'https://www.von-poll.com/de/immobilien/immobilien-kaufen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer&utm_content=tipps',
      },
    ],
  },
  {
    title: 'footer.menu.services',
    children: [
      {
        label: 'footer.menu.services.portal',
        path: '#appUrl/?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.services.evaluate',
        path: '#appUrl/immobilie-bewerten?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.services.finance',
        path: '#appUrl/baufinanzierung',
      },
      {
        label: 'footer.menu.services.search-profile',
        path: '#appUrl/immobilie-suchen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.services.rent',
        path: 'https://www.von-poll.com/de/immobilien/immobilien-vermieten?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.services.manage',
        path: 'https://www.von-poll-hausverwaltung.com/?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
      {
        label: 'footer.menu.services.energy',
        path: 'https://www.von-poll.com/de/immobilien/energieausweis?utm_source=kundenportal&utm_medium=navigation&utm_campaign=footer',
      },
    ],
  },
  {
    title: 'footer.menu.company',
    children: [
      {
        label: 'footer.menu.company.about-us',
        path: 'https://www.von-poll.com/de/unternehmen/ueber-uns',
      },
      {
        label: 'footer.menu.company.location',
        path: 'https://www.von-poll.com/de/unternehmen/standorte',
      },
      {
        label: 'footer.menu.company.blog',
        path: 'https://www.von-poll.com/de/immobilienblog',
      },
      {
        label: 'footer.menu.company.partner',
        path: 'https://www.von-poll.com/de/immobilien-lizenz/geschaeftsstellenmodell',
      },
      {
        label: 'footer.menu.company.career',
        path: 'https://www.von-poll.com/de/karriere/stellenangebote',
      },
      {
        label: 'footer.menu.company.market-reports',
        path: 'https://www.von-poll.com/de/unternehmen/marktberichte',
      },
      {
        label: 'footer.menu.company.best-location',
        path: 'https://www.von-poll.com/de/unternehmen/beste-lage-magazin',
      },
      {
        label: 'footer.menu.company.cooperation',
        path: 'https://www.von-poll.com/de/unternehmen/kooperation',
      },
    ],
  },
];

const Container = styled.div`
  font-family: Roboto, sans-serif;
  background: linear-gradient(88.91deg, #002447 5.88%, #00366b 86.56%);
  height: 671px;
  display: flex;
  flex-direction: column;
  z-index: 2;

  @media ${device.tablet} {
    height: auto;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }
`;

const FooterBottomContainer = styled.div`
  margin-top: auto;
  height: 103px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //justify-content: space-between;
  background: rgba(0, 0, 0, 0.11);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media ${device.tablet} {
    height: auto;
    justify-content: space-between;
    align-items: flex-start;
    padding: 35px 35px 58px;
  }
`;

const FooterLinkBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 306px;
  margin-right: 282px;
  gap: 64px;

  @media ${device.bigScreen} {
    margin-left: 270px;
  }

  @media ${device.laptop} {
    margin-left: auto;
    margin-right: auto;
  }

  @media ${device.tablet} {
    margin: 10px 0 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const FooterBottomLink = styled(LngLink)`
  font-family: Roboto, sans-serif;
  color: white;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

const Logo = styled(Icon)`
  @media ${device.bigScreen} {
    margin-left: 36px;
  }
  @media ${device.tablet} {
    display: none;
  }
`;

// const LanguageSelect = styled.select`
//   outline: none;
//   border-radius: 5px;
//   height: 32px;
//   padding-left: 14px;
//   padding-right: 27px;
//   appearance: none;
//   font-family: Roboto, sans-serif;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 13px;
//   line-height: 1;
//   letter-spacing: 0.09em;
//   color: #FFFFFF;
//   border: 1px solid rgba(255, 255, 255, 0.14);
//   background: url("${dropdownArrowImg}") 80% / 20% no-repeat transparent;
// `

const FooterContent = styled.div`
  color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 46px 0 31px;
  align-items: center;

  @media ${device.tablet} {
    padding: 0;
  }
`;

const LinksBox = styled.div`
  padding-bottom: 23px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;

  @media ${device.tablet} {
    flex-direction: column;
    padding: 0;
    border: none;
  }
`;

const FooterLinks = styled.div`
  padding-bottom: 26px;
  padding-right: 51px;
  padding-top: 6px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  gap: 64px;

  @media ${device.bigScreen} {
    padding-right: 24px;
    gap: 42px;
  }

  @media ${device.laptop} {
    padding-right: 10px;
    gap: 10px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    border-right: none;
    padding: 32px 0 23px;
    margin: 0;
    gap: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

const MediaBox = styled.div`
  padding: 6px 0 6px 34px;

  @media ${device.bigScreen} {
    padding: 6px 0 6px 24px;
  }

  @media ${device.laptop} {
    padding: 6px 0 6px 10px;
  }

  @media ${device.tablet} {
    padding: 0;
  }
`;

const SocialMediaBox = styled.div`
  width: 313px;
  height: 51px;
  box-sizing: content-box;
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    width: 323px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.14);
    padding: 25px 0;
  }
`;

const SocialMediaLink = styled.a`
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;

  &:hover {
    opacity: 0.6;
  }
`;

const HorizontalBar = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  margin: 31px 0;

  @media ${device.tablet} {
    display: none;
  }
`;

const AppsBox = styled.div`
  @media ${device.tablet} {
    border-bottom: 1px solid rgba(255, 255, 255, 0.14);
    padding: 29px 0;
  }
`;

const AppTitle = styled.h5`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 14px;
`;

const AppLink = styled.a<{
  background: string;
  width: number;
  height: number;
  $withMargin?: boolean;
}>`
  background-image: url('${(props) => props.background}');
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  ${(props) =>
    props.$withMargin &&
    `
    margin-right: 26px;

    @media ${device.laptop} {
      margin-right: 16px;
    }
  `};
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
    transition: opacity 0.3s;
  }
`;

const RatingBox = styled.a`
  text-align: center;
  display: block;
  @media ${device.tablet} {
    border-bottom: 1px solid rgba(255, 255, 255, 0.14);
    padding: 31px 0;
  }
`;

const RatingText = styled.span`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.02em;
  color: #c5b175;
  margin-top: 8px;
  display: block;
  text-decoration: underline;
`;

const PicturesBox = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 40px;
  align-items: center;

  @media ${device.tablet} {
    margin: 40px 34px 61px;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const SmallPictureBox = styled.div`
  width: 71px;
  height: 121px;
`;

const FooterStack = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-right: 18px;

  @media ${device.tablet} {
    margin: 0;
    flex-direction: row;
    align-items: center;
  }
`;

const ImgLink = styled.a`
  display: block;
`;

const StarsRatingBox = styled.div`
  display: flex;
  justify-content: center;
`;

export const StarIcon = styled.i<{ empty?: boolean }>`
  display: block;
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;

    ${(props) =>
      props.empty &&
      `
        path {
          fill: #ccc !important;
        }
      `}
  }
`;

const StarsRating = ({ rating }: { rating: number }) => {
  return (
    <StarsRatingBox>
      {[...Array.from({ length: 5 })].map((_, index: number) => {
        if (index + 1 <= rating) {
          return (
            <StarIcon key={index}>
              <Star1 />
            </StarIcon>
          );
        }

        if (rating - index < 0) {
          return (
            <StarIcon key={index} empty>
              <Star1 />
            </StarIcon>
          );
        }

        if (rating - index < 0.25) {
          return (
            <StarIcon key={index}>
              <Star12 />
            </StarIcon>
          );
        }

        if (rating - index < 0.4) {
          return (
            <StarIcon key={index}>
              <Star34 />
            </StarIcon>
          );
        }

        if (rating - index < 0.55) {
          return (
            <StarIcon key={index}>
              <Star5 />
            </StarIcon>
          );
        }

        if (rating - index < 0.7) {
          return (
            <StarIcon key={index}>
              <Star67 />
            </StarIcon>
          );
        }

        if (rating - index < 1) {
          return (
            <StarIcon key={index}>
              <Star89 />
            </StarIcon>
          );
        }

        return null;
      })}
    </StarsRatingBox>
  );
};

const Footer = (): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);

  const { data } = useGetProvenExpertRatingQuery();

  const provenExpertRating = data?.getProvenExpertRating;

  return (
    <>
      {!isAnonymousUser && <CustomerFeedback />}
      <Container>
        <FooterContent>
          <LinksBox>
            <FooterLinks>
              {footerLinks.map((item) => {
                return <CollapseFooter key={item.title} {...item} />;
              })}
            </FooterLinks>
            <MediaBox>
              <SocialMediaBox>
                <SocialMediaLink
                  href={'https://www.facebook.com/vonPollImmobilien'}
                  target={'_blank'}
                >
                  <img src={facebook} width={25} height={25} alt={'facebook'} />
                </SocialMediaLink>
                <SocialMediaLink
                  href={'https://instagram.com/vonpoll'}
                  target={'_blank'}
                >
                  <img
                    src={instagram}
                    width={25}
                    height={25}
                    alt={'instagram'}
                  />
                </SocialMediaLink>
                <SocialMediaLink
                  href={'https://www.youtube.com/user/vonPoll'}
                  target={'_blank'}
                >
                  <img src={youtube} width={25} height={25} alt={'youtube'} />
                </SocialMediaLink>
                <SocialMediaLink
                  href={
                    'https://de.linkedin.com/company/von-poll-immobilien-gmbh'
                  }
                  target={'_blank'}
                >
                  <img src={linkedin} width={25} height={25} alt={'linkedin'} />
                </SocialMediaLink>
                <SocialMediaLink
                  href={'https://www.xing.com/pages/vonpollimmobiliengmbh'}
                  target={'_blank'}
                >
                  <img src={xing} width={25} height={25} alt={'xing'} />
                </SocialMediaLink>
              </SocialMediaBox>
              <HorizontalBar />
              <AppsBox>
                <AppTitle>{t('footer.apps')}</AppTitle>
                <AppLink
                  href={
                    'https://apps.apple.com/de/app/von-poll-immobilien/id516496863'
                  }
                  target={'_blank'}
                  background={AppStore}
                  width={137}
                  height={46}
                  $withMargin
                />
                <AppLink
                  href={
                    'https://play.google.com/store/apps/details?id=de.fabrik19.vonpoll&hl=de'
                  }
                  target={'_blank'}
                  background={GooglePlay}
                  width={151}
                  height={46}
                />
              </AppsBox>
              <HorizontalBar />
              <RatingBox
                href={'https://www.provenexpert.com/von-poll-immobilien-gmbh/'}
                target={'_blank'}
              >
                <StarsRating rating={provenExpertRating?.ratingValue || 0} />
                <RatingText>
                  {t('footer.rating', {
                    value: provenExpertRating?.reviewCount || '',
                  })}
                </RatingText>
              </RatingBox>
            </MediaBox>
          </LinksBox>
          <PicturesBox>
            <FooterStack>
              <ImgLink href="https://www.leadingre.com/" target="_blank">
                <img
                  src={FooterStack1}
                  width={89}
                  height={41}
                  alt={'leading real estate company of the world'}
                />
              </ImgLink>
              <ImgLink
                href={'https://www.luxuryportfolio.com/'}
                target="_blank"
              >
                <img
                  src={FooterStack2}
                  width={102}
                  height={30}
                  alt={'luxury portfolio international'}
                />
              </ImgLink>
              <ImgLink href={'https://www.dorotheum.com/de/'} target="_blank">
                <img
                  src={FooterStack3}
                  width={100}
                  height={19}
                  alt={'dorotheum'}
                />
              </ImgLink>
            </FooterStack>
            <img
              src={Footer1}
              width={61}
              height={121}
              alt={'top nationaler arbeitgeber'}
            />
            <SmallPictureBox>
              <img
                src={Footer2}
                width={71}
                height={91}
                alt={'deutschlands begehrteste immoobilienmakler'}
              />
            </SmallPictureBox>
            <img
              src={Footer3}
              width={71}
              height={121}
              alt={'höchste kundenzufriedenheit'}
            />
            <ImgLink href={'https://www.ncl-stiftung.de/'} target="_blank">
              <img
                src={Footer4}
                width={98}
                height={38}
                alt={'stiftung kinderherz'}
              />
            </ImgLink>
            <ImgLink
              href={'https://www.stiftung-kinderherz.de/'}
              target={'_blank'}
            >
              <img src={Footer5} width={141} height={30} alt={'ncl stiftung'} />
            </ImgLink>
          </PicturesBox>
        </FooterContent>
        <FooterBottomContainer>
          <Logo icon={VPLogo} width={38} height={38} />
          <FooterLinkBox>
            <FooterBottomLink
              isWithoutLng
              to={{
                pathname: 'https://www.von-poll.com/de/unternehmen/kontakt',
              }}
              color={themeContext.blue}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.contact')}
            </FooterBottomLink>
            <FooterBottomLink
              isWithoutLng
              to={{
                pathname:
                  'https://www.von-poll.com/de/unternehmen/medienbereich',
              }}
              color={themeContext.blue}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.press')}
            </FooterBottomLink>
            <FooterBottomLink
              isWithoutLng
              to={{ pathname: 'https://www.von-poll.com/de/agb' }}
              color={themeContext.blue}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.conditions')}
            </FooterBottomLink>
            <FooterBottomLink
              isWithoutLng
              to={{
                pathname: '/datenschutzinformationenundnutzungsweise',
              }}
              color={themeContext.blue}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.privacy')}
            </FooterBottomLink>
            <FooterBottomLink
              isWithoutLng
              to={{ pathname: 'https://www.von-poll.com/de/impressum' }}
              color={themeContext.blue}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.imprint')}
            </FooterBottomLink>
          </FooterLinkBox>
          {/* <LanguageSelect> */}
          {/* <option>DE</option> */}
          {/* </LanguageSelect> */}
        </FooterBottomContainer>
      </Container>
    </>
  );
};

export { Footer };
