import { ReactComponent as PhoneActionInfo } from 'assets/streamline-light/phones-mobile-devices/phone-actions/phone-actions-info.svg';
import { ReactComponent as PhoneCircle } from 'assets/streamline-light/phones-mobile-devices/phone/phone-circle.svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../common/hooks';

import {
  GeneralAppointment,
  RpSection,
  useReadGeneralAppointmentQuery,
} from '../../../../../generated';
import { MainButton } from '../../../../common/components/ui/buttons';
import { toggleTimifyModal } from '../../../../timify/redux/timifySlice';
import {
  Box,
  Contact,
  ContactIconWrapper,
  Container,
  Content,
  ContentRow,
  Description,
  GeneralText,
  IconWrapper,
  InfoRow,
  LowOpacitySpan,
  PhoneLinkWrapper,
  QuestionTitle,
  Title,
} from './general-appointment-styles';

interface IProps {
  section: RpSection;
}

interface GeneralAppointmentProps {
  id: string;
}

interface GeneralAppointmentResponse {
  data: { data: GeneralAppointment };
  isLoading: boolean;
}

const GeneralAppointmentComponent: FC<GeneralAppointmentProps> = ({ id }) => {
  const { data, isLoading } =
    useReadGeneralAppointmentQuery<GeneralAppointmentResponse>({
      id,
    });

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  if (isLoading) return null;

  return (
    <Box>
      <Container>
        <InfoRow>
          <IconWrapper icon={PhoneActionInfo} width={40} height={40} />
          <QuestionTitle content={t('appointment.title.text')} />
          <Description target={'_blank'} href={`${window.location.href}#FAQ`}>
            {t('appointment.description.text')}
          </Description>
        </InfoRow>
        <ContentRow>
          <Content>
            <Title content={data?.data?.label || ''} />
            <GeneralText
              dangerouslySetInnerHTML={{
                __html: data?.data?.generalText || '',
              }}
            />
            <PhoneLinkWrapper phoneNumber={data?.data?.phone || ''}>
              <ContactIconWrapper icon={PhoneCircle} width={20} height={20} />
              <Contact>
                <LowOpacitySpan>{t('appointment.phone.text')}:</LowOpacitySpan>
                <span>{data?.data?.phone || ''}</span>
              </Contact>
            </PhoneLinkWrapper>
            <MainButton
              label={data?.data?.buttonLabel || ''}
              onClick={() => dispatch(toggleTimifyModal(true))}
            />
          </Content>
        </ContentRow>
      </Container>
    </Box>
  );
};

const GeneralAppointments: FC<IProps> = ({ section }) => {
  const { recordIds } = section;

  return (
    <div>
      {recordIds.map((id: string) => {
        return <GeneralAppointmentComponent key={id} id={id} />;
      })}
    </div>
  );
};

export { GeneralAppointments };
