import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import notFoundImg from '../../../assets/404.svg';

import { device } from '../../../style/theme';
import { DarkBlueGhostLinkButton } from '../../common/components/ui/buttons/dark-blue-ghost-link-button';
import {
  Headline1,
  ParagraphTextWithHTML,
} from '../../common/components/ui/typography';

const PageContainer = styled.div`
  width: 100%;
  max-width: 520px;
  text-align: center;
  padding: 145px 16px;
  margin: 0 auto;
  @media ${device.mobile} {
    padding: 16px;
  }
`;

const NotFoundImage = styled.img`
  margin-bottom: 16px;
`;

const Title = styled(Headline1)`
  margin-bottom: 12px;
  line-height: 48px;

  @media ${device.mobile} {
    font-size: 20px;
    line-height: 28px;
  }
`;

const Text = styled(ParagraphTextWithHTML)`
  margin-bottom: 24px;
`;

const ButtonsContainer = styled.div`
  width: 256px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const LinkButton = styled(DarkBlueGhostLinkButton)`
  text-align: center;
  text-transform: uppercase;

  & + & {
    margin-top: 12px;
  }
`;

const NotFoundPageBase: FC = () => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <NotFoundImage src={notFoundImg} alt="404 number in house style" />
      <Title content={t('page.not_found.title')} />
      <Text content={t('page.not_found.text')} />
      <ButtonsContainer>
        <LinkButton
          label={t('page.not_found.button.estimate.label')}
          href="https://home.von-poll.com/online-immobilienbewertung"
        />
        <LinkButton
          label={t('page.not_found.button.buyer.label')}
          href="https://home.von-poll.com/de-de/immobilie-kaufen"
        />
        <LinkButton
          label={t('page.not_found.button.buy.label')}
          href="https://home.von-poll.com/persoenliche-immobilienbewertung"
        />
        <LinkButton
          label={t('page.not_found.button.search.label')}
          href="https://www.von-poll.com/de/immobilien/immobilien-suchen"
        />
        <LinkButton
          label={t('page.not_found.button.offices.label')}
          href="https://www.von-poll.com/de/unternehmen/standorte"
        />
      </ButtonsContainer>
    </PageContainer>
  );
};

const NotFoundPage = memo(NotFoundPageBase);

export { NotFoundPage };
