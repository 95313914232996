import { interpolateRgb } from 'd3-interpolate';
import { ILocationScore } from '../interfaces';

const darkGreen = '#33a532';
const lightGreen = '#7CFC00';
const yellow = '#FFD700';
const lightRed = '#ff726f';
const darkRed = '#880808';

export const scoreNormalizer = (score: number): ILocationScore => {
  const oldMin = 0;
  const oldMax = 1;
  const newMin = 1;
  const newMax = 5;

  const newValue =
    ((score - oldMin) / (oldMax - oldMin)) * (newMax - newMin) + newMin;

  let label = 'score.4';
  let searchProfileLabel = 'expose-page.tabs.overview.micro-situation.score-1';
  let leftColor = darkGreen;
  let rightColor = darkGreen;

  if (newValue < 5 && newValue >= 4) {
    leftColor = lightGreen;
    rightColor = darkGreen;
  }

  if (newValue < 4 && newValue >= 3) {
    leftColor = yellow;
    rightColor = lightGreen;
  }

  if (newValue < 3 && newValue >= 2) {
    leftColor = lightRed;
    rightColor = yellow;
  }

  if (newValue < 2 && newValue >= 1) {
    leftColor = darkRed;
    rightColor = lightRed;
  }

  if (newValue <= 1) {
    leftColor = darkRed;
    rightColor = darkRed;
  }

  if (newValue < 4 && newValue >= 2) {
    label = 'score.2';
    searchProfileLabel = 'expose-page.tabs.overview.micro-situation.score-2';
  }

  if (newValue < 2) {
    label = 'score.1';
    searchProfileLabel = 'expose-page.tabs.overview.micro-situation.score-3';
  }

  return {
    score: newValue,
    label,
    searchProfileLabel,
    color: interpolateRgb(
      leftColor,
      rightColor
    )(newValue - Math.floor(newValue)),
  };
};
