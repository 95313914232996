import styled from 'styled-components';
import { device } from '../../../../../../../../../style/theme';

export const Title = styled.h1`
  color: #00305e;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin-bottom: 24px;

  @media ${device.tablet} {
    text-align: center !important;
    margin-top: -12px;
    margin-bottom: 16px;
  }

  & + p {
    text-align: left;

    @media ${device.tablet} {
      text-align: center;
    }
  }
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  label {
    color: rgba(0, 48, 94, 0.7);
  }

  & form button {
    position: initial;
    width: 100%;
  }

  & form button[type='button'] {
    margin-bottom: 24px;
  }
`;

export const Row = styled.div<{ isiframe?: boolean; isMarginTop?: boolean }>`
  display: flex;
  flex-direction: column;
`;
