import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const ContentWithPadding = styled.div`
  padding: 48px 48px 0 48px;

  > div:first-child {
    margin-bottom: 64px;
  }

  > div:last-of-type {
    margin-top: 64px;
  }

  @media ${device.tablet} {
    padding: 0;
    > div:first-child {
      margin-bottom: 16px;
    }

    > div {
      margin-left: 16px;
      margin-right: 16px;
    }

    > div:last-of-type {
      margin-top: 16px;
    }
  }
`;

export const LoaderContainer = styled.div`
  padding: 200px 0;
`;
