import { FC } from 'react';
import { ReactComponent as Calendar } from 'assets/streamline-light/interface-essential/date-calendar/calendar.svg';
import { ReactComponent as Lock } from 'assets/streamline-light/interface-essential/lock-unlock/lock-2.svg';

import { Container } from './table-personal-financing-example-styles';
import { TablePfExampleItem } from './table-pf-example-item';
import { WarningItem } from './warning-item';
import { useGetFinancingPropositionsAuth } from '../../../../../../hooks/callback-hooks/useGetFinancingPropositionsAuth';
import { numberFormat } from '../../../../../../../../financing/utils/numberFormat';
import { FinancingLoader } from '../../../../../../../components/common/loader';

const TablePersonalFinancingExample: FC = () => {
  const { data, isLoading } = useGetFinancingPropositionsAuth();

  const proposition = data?.[0]?.propositions?.[0];
  const fixedBorrowingRate = proposition?.shouldInterest;
  const effectiveInterestRate = proposition?.effectiveInterest;
  const rateMonthly = numberFormat(proposition?.totalRatePerMonth);

  return (
    <Container>
      {isLoading ? (
        <FinancingLoader title="financing.loader-label" />
      ) : (
        <>
          <TablePfExampleItem
            years={5}
            icon={Calendar}
            fixedBorrowingRate={fixedBorrowingRate}
            effectiveInterestRate={effectiveInterestRate}
            monthlyRate={rateMonthly}
          />
          <WarningItem />
          <TablePfExampleItem years={10} icon={Lock} />
          <TablePfExampleItem years={15} icon={Lock} />
        </>
      )}
    </Container>
  );
};

export { TablePersonalFinancingExample };
