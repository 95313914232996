import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';

import { StatusType } from '../../../../../common/components/verification-input/types';
import { useAppSelector } from '../../../../../common/hooks';
import { selectFormattedPhone } from '../../../../../auth/redux/selectors/selectFormattedPhone';
import { useCreateTemporaryProperty } from '../../../../form-property-valuation-wizard/hooks/useCreateTemporaryProperty';
import {
  CreatePropertyInput,
  LoginResponse,
  useConfirmPhoneMutation,
  useRequestVerificationCodeMutation,
  useSignInMutation,
} from '../../../../../../generated';
import {
  removeUserInput,
  setUserState,
  toggleLoading,
} from '../../../../../auth/redux/authSlice';
import { parseValuationInput } from '../../../../utils/parse-valuation-input';
import { IValuationForm } from '../../../interface';
import { Feature } from '../../../../../../services/mapbox/interfaces';
import { setIsConfirmEmailSent } from '../../../redux/valuationWizardV2Slice';

type TProps = {
  reduxSliceName: 'valuationWizardV2' | 'valuationWizard';
  setErrors: (errors: unknown) => void;
};

type TReturn = {
  formattedPhone: string;
  onVerifyPhone(code: string): void;
  verificationStatus: StatusType;
  onChangePhone?: () => void;
  isOnBuyerSignUpPage?: boolean;
};

const useOnlinePhoneVerification = ({
  reduxSliceName,
  setErrors,
}: TProps): TReturn => {
  const dispatch = useDispatch();
  const formattedPhone = useAppSelector(selectFormattedPhone);
  const [verificationStatus, setVerificationStatus] = useState(
    StatusType.process
  );

  const userId = useAppSelector((state) => state.auth.userInput?._id);
  const username = useAppSelector((state) => state.auth.userInput?.username);
  const password = useAppSelector((state) => state.auth.userInput?.password);
  const firstName = useAppSelector((state) => state.auth.userInput?.name);
  const lastName = useAppSelector((state) => state.auth.userInput?.surname);
  const userInput = useAppSelector((state) => state[reduxSliceName].userInput);
  const selectedAddress = useAppSelector(
    (state) => state[reduxSliceName].selectedAddress
  );
  const { createTemporaryProperty } = useCreateTemporaryProperty();

  const [confirmPhone] = useConfirmPhoneMutation();
  const [signIn] = useSignInMutation();
  const [requestVerificationCode] = useRequestVerificationCodeMutation();

  const onVerifyPhone = useCallback(
    async (confirmationCode: string) => {
      setVerificationStatus(StatusType.wait);
      if (confirmationCode && username && password && firstName && lastName) {
        try {
          dispatch(toggleLoading(true));
          const confirmPhoneResult = await confirmPhone({
            input: {
              username,
              confirmationCode,
            },
          }).unwrap();

          // set user state VerifyEmail
          if (confirmPhoneResult?.confirmPhone) {
            dispatch(setUserState(confirmPhoneResult.confirmPhone));
          }

          const valuationInput = parseValuationInput(
            userInput as IValuationForm,
            selectedAddress as Feature
          ) as CreatePropertyInput;

          const ownerId = userId as string;

          await createTemporaryProperty({
            valuationInput,
            userId: ownerId,
          });

          try {
            // Initiate login to get the jwt token
            const signInResponse = await signIn({
              input: {
                userAuthenticationKey: username,
                password,
                requestAccessToken: true,
              },
            }).unwrap();

            if (signInResponse.signIn) {
              const signInParams = signInResponse.signIn as LoginResponse;
              dispatch(removeUserInput());

              await requestVerificationCode({
                input: {
                  username,
                  accessToken: signInParams?.jwt?.token ?? '',
                  refreshToken: signInParams?.jwt?.refreshToken ?? '',
                  attributeName: 'email',
                  firstName,
                  lastName,
                },
              });
              dispatch(setIsConfirmEmailSent(true));
            }
          } catch (error: unknown) {
            setErrors(error);
          }
        } catch (error: unknown) {
          setErrors(error);
          setVerificationStatus(StatusType.error);
        } finally {
          dispatch(toggleLoading(false));
        }
      }
    },
    [
      username,
      password,
      firstName,
      lastName,
      dispatch,
      confirmPhone,
      userInput,
      selectedAddress,
      userId,
      createTemporaryProperty,
      signIn,
      requestVerificationCode,
      setErrors,
    ]
  );

  return {
    onVerifyPhone,
    formattedPhone: formattedPhone ?? '',
    verificationStatus,
  };
};

export { useOnlinePhoneVerification };
