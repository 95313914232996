import { ReactComponent as ArrowDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import styled, { css } from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hexToRGB } from '../../../utils/hex-to-rgb';
import { OutsideClickHandler } from '../../outside-click-handler';
import { device } from '../../../../../style/theme';

const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SelectElement = styled.div<{
  isDisabled: boolean;
  hasError: boolean;
  isRedesign?: boolean;
  isBigger: boolean;
}>`
  display: block;
  position: relative;
  padding: 8px 26px 8px 12px;
  ${({ isBigger }) =>
    isBigger &&
    css`
      & {
        min-height: 40px;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    `}
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
  ${({ theme, isDisabled, hasError, isRedesign }) => {
    if (isRedesign) {
      return css`
        & {
          border: 1px solid ${theme.grey};
          background: ${theme.white};
        }
        &:hover {
          border: 1px solid ${theme.borderFocus};
          background-color: ${theme.greyBackground};
        }
      `;
    }
    if (isDisabled) {
      return css`
        & {
          border: 1px solid ${hexToRGB(theme.greyBackground, 0.5)};
          background: ${hexToRGB(theme.greyBackground, 0.5)};
        }
      `;
    }
    if (hasError) {
      return css`
        border: 1px solid ${theme.paleRed};
        background: ${theme.paleRed};
      `;
    }
    return css`
      border: 1px solid ${theme.greyBackground};
      background: ${theme.greyBackground};
    `;
  }};
  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      &:hover {
        border: 1px solid ${({ theme }) => theme.blue};
      }
    `}
`;

const SelectValue = styled.div<{ isDisabled: boolean; hasError: boolean }>`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme, isDisabled, hasError }) => {
    if (isDisabled) {
      return hexToRGB(theme.blue, 0.3);
    }
    if (hasError) {
      return hexToRGB(theme.blue, 0.5);
    }
    return theme.blue;
  }};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SelectIcon = styled(ArrowDown)`
  position: absolute;
  width: 8px;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.blue};
`;

const SelectOptions = styled.div`
  background: ${({ theme }) => theme.white};
  position: absolute;
  top: calc(100% + 3px);
  right: 0;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.ctaDisabledBackground};
  overflow: hidden;
  z-index: 2;
  max-height: 160px;
  overflow-y: auto;
  @media ${device.tablet} {
    max-height: 150px;
    overflow: hidden overlay;
  }
`;

const SelectOption = styled.div<{ isSelected: boolean }>`
  padding: 12px;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.blue : hexToRGB(theme.blue, 0.5)};
  cursor: pointer;
  transition: 0.12s ease;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.greyBackground};
  }
  &:hover {
    color: ${({ theme }) => theme.blue};
    background: ${({ theme }) => theme.greyBackground};
  }
`;

const Error = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.red};
  margin: 4px 0 0;
`;

const InnerLabel = styled.div`
  opacity: 0.5;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;

interface ISelect {
  isDisabled?: boolean;
  isRedesign?: boolean;
  isBigger?: boolean;
  selectedOption: string | number;
  options: {
    label: string | number;
    value: string | number | boolean | null;
  }[];
  error?: string;
  innerLabel?: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  onSelect?: (value: unknow) => void;
}

const Select = ({
  isDisabled = false,
  isRedesign,
  isBigger = false,
  selectedOption,
  options,
  error,
  innerLabel,
  onSelect,
}: ISelect): JSX.Element => {
  const { t } = useTranslation();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const onSelectElementClick = () => {
    if (!isDisabled) {
      setIsDropdownVisible(!isDropdownVisible);
    }
  };

  const closeDropdown = () => {
    setIsDropdownVisible(false);
  };

  const onSelectOptionClick =
    (value: string | number | boolean | null) => () => {
      if (onSelect) onSelect(value);
      closeDropdown();
    };

  return (
    <>
      <SelectWrapper>
        <OutsideClickHandler display="block" onOutsideClick={closeDropdown}>
          <SelectElement
            isBigger={isBigger}
            isDisabled={isDisabled}
            isRedesign={isRedesign}
            hasError={Boolean(error)}
            onClick={onSelectElementClick}
          >
            {innerLabel && <InnerLabel>{innerLabel}</InnerLabel>}
            <SelectValue isDisabled={isDisabled} hasError={Boolean(error)}>
              {t(selectedOption as string)}
            </SelectValue>

            <SelectIcon />
          </SelectElement>

          {isDropdownVisible && (
            <SelectOptions>
              {options.map((option) => (
                <SelectOption
                  key={
                    typeof option?.value === 'boolean' ||
                    typeof option?.value === null
                      ? option.label
                      : option.value
                  }
                  isSelected={selectedOption === option.label}
                  data-value={option.value}
                  onClick={onSelectOptionClick(option?.value)}
                >
                  {t(option.label as string)}
                </SelectOption>
              ))}
            </SelectOptions>
          )}
        </OutsideClickHandler>
      </SelectWrapper>
      {Boolean(error) && <Error>{error}</Error>}
    </>
  );
};

export default Select;
