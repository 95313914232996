import styled from 'styled-components';
import { device } from '../../../../style/theme';
import { Headline3 } from '../ui/typography';

export const ColumnWrapper = styled.div<{ isFullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  gap: 16px;
  @media ${device.tablet} {
    flex-direction: column;
  }
  ${({ isFullWidth }) => isFullWidth && `flex-wrap: wrap`}
`;

export const Column = styled.div<{ margin?: string; isFullWidth?: boolean }>`
  width: 100%;
  margin: ${(props) => (!props.isFullWidth ? props.margin : '0 0 8px 0')};
  @media ${device.tablet} {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

export const ChartWrapper = styled.div`
  padding: 12px;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  &.bar-chart-wrapper {
    margin-bottom: 16px;
  }
  @media ${device.tablet} {
    border: none;
  }
`;

export const ChartContainer = styled.div`
  max-height: 400px;
  width: 100%;
  overflow: hidden;
  @media ${device.tablet} {
    max-height: 800px;
  }
  canvas {
    width: 100% !important;
  }
`;

export const Selector = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SelectorLeft = styled.div`
  display: flex;
  flex: 0 0 33%;
  align-items: center;
`;

export const SelectorRight = styled.div`
  display: flex;
  flex: 0 0 33%;
  align-items: center;
  margin-left: auto;
  max-width: 66%;
  @media ${device.tablet} {
    max-width: 120px;
  }
`;

export const SelectTitle = styled(Headline3)`
  font-size: 16px;
  line-height: 1.25;
`;
