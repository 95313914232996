import styled from 'styled-components';
import { device } from '../../../../../../../../../style/theme';

export const Container = styled.div<{ addPadding?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TdYear = styled.td`
  div {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;

    h5 {
      margin: 12px 0 4px;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: ${({ theme }) => theme.blue};
    }

    p {
      opacity: 0.6;
      font-family: Roboto, sans-serif;
      font-size: 10px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: 1px;
      color: ${({ theme }) => theme.blue};
    }
  }
`;

export const Warning = styled.div`
  padding: 12px 16px 12px 12px;
  border-radius: 3px;
  border: solid 1px #dacebc;
  background-color: rgba(162, 133, 87, 0.2);
  display: flex;
  align-items: center;
  gap: 12px;
  i {
    min-width: 20px;
    min-height: 20px;
  }
  p {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.blue};
  }
`;

export const Table = styled.table<{ isInternal?: boolean }>`
  width: 100%;
  border-radius: 3px;
  border: ${({ isInternal }) => (isInternal ? 'none' : 'solid 1px #a8a8a7')};
  border-collapse: collapse;
  td,
  tr {
    border-radius: 3px;
    border: none;
    border-collapse: collapse;
  }
  & tr > td:last-child {
    border-left: solid 1px #a8a8a7;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
  table td {
    border: 1px solid #a8a8a7;
  }
  table tr:first-child td {
    border-top: 0;
  }
  table tr:last-child td {
    border-bottom: 0;
  }
  table tr td:first-child {
    border-left: 0;
  }
  table tr td:last-child {
    text-align: right;
    border-right: 0;
    img {
      width: 75px;
    }
  }

  @media ${device.tablet} {
    & > tbody > tr:first-child > td {
      border-bottom: solid 1px #a8a8a7;
      background-color: #f2f5f7;
    }
  }
`;
