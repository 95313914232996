import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BoxesContainer,
  DataListContainer,
  Header,
  InfoContainer,
  MarkersListContainer,
  MarkersListItem,
  RangeContainer,
  DataListItem,
} from './energy-efficiency-styles';
import { BoxInfo } from './box-info/box-info';
import { energyEfficiency } from '../../../../constants';
import { useGetEnergyEfficiencyData } from './useGetEnergyEfficiencyData';
import {
  useExposeDetailsEnergyCertificate,
  useExposeDetailsEnergyEquipment,
} from '../../../../hooks/useExposeDetailsQueries';
import { CombinedShape } from './combined-shape/combined-shape';

const EnergyEfficiency: FC = () => {
  const { t } = useTranslation();
  const {
    energyCertificateData: {
      energyCertificate,
      energyCertificateValidUntil,
      energyEfficiencyClass,
      finalEnergyDemand,
      energySource,
    },
  } = useExposeDetailsEnergyCertificate();

  const {
    energyEquipment: { typeOfHeating, typeOfFiring },
  } = useExposeDetailsEnergyEquipment();

  const { colorMarkers, textMarkers } = energyEfficiency;

  const { tooltipLeft, indicatorLeft, letter, color, indicatorColor } =
    useGetEnergyEfficiencyData({
      finalEnergyDemand: Number(finalEnergyDemand),
      energyEfficiencyClass,
    });

  if (
    !letter &&
    !energyCertificate &&
    !finalEnergyDemand &&
    !energyCertificateValidUntil &&
    !energyEfficiencyClass &&
    !typeOfFiring &&
    !energySource &&
    !typeOfHeating
  ) {
    return null;
  }

  return (
    <>
      <Header
        content={t('expose-page.tabs.overview.energy-efficiency.header')}
      />
      <InfoContainer>
        {letter && (
          <RangeContainer isValue={!!finalEnergyDemand}>
            <CombinedShape
              color={color || '#fffa3e'}
              indicatorColor={indicatorColor}
              tooltipLeft={`${tooltipLeft}%`}
              indicatorLeft={`${indicatorLeft}%`}
              letter={letter}
              value={finalEnergyDemand}
            />
            <MarkersListContainer>
              {colorMarkers.map(({ label, range }) => (
                <MarkersListItem key={label} width={`${(range / 275) * 100}%`}>
                  <div>{label}</div>
                </MarkersListItem>
              ))}
            </MarkersListContainer>
            <DataListContainer>
              {textMarkers.map(({ label }, index) => (
                <DataListItem key={label} index={index}>
                  <div>{label}</div>
                </DataListItem>
              ))}
            </DataListContainer>
          </RangeContainer>
        )}
        <BoxesContainer>
          {energyCertificate && (
            <BoxInfo
              type={t(
                'expose-page.tabs.overview.energy-efficiency.energy-certificate'
              )}
              value={t(
                `expose-page.tabs.overview.energy-efficiency.energy-certificate.type.${energyCertificate}`
              )}
            />
          )}
          {finalEnergyDemand && (
            <BoxInfo
              type={t(
                'expose-page.tabs.overview.energy-efficiency.final-energy-consumption'
              )}
              value={`${finalEnergyDemand} kWh/m²a`}
            />
          )}
          {energyCertificateValidUntil && (
            <BoxInfo
              type={t(
                'expose-page.tabs.overview.energy-efficiency.energy-certificate-valid-until'
              )}
              value={energyCertificateValidUntil}
            />
          )}
          {energyEfficiencyClass && (
            <BoxInfo
              type={t(
                'expose-page.tabs.overview.energy-efficiency.energy-efficiency-class'
              )}
              value={energyEfficiencyClass}
            />
          )}
          {typeOfFiring && (
            <BoxInfo
              type={t('expose-page.tabs.overview.energy-efficiency.firing')}
              value={t(
                `expose-page.tabs.overview.energy-efficiency.firing.type.${typeOfFiring}`
              )}
            />
          )}
          {energySource && (
            <BoxInfo
              type={t(
                'expose-page.tabs.overview.energy-efficiency.essential-energy-source'
              )}
              value={t(
                `expose-page.tabs.overview.energy-efficiency.essential-energy-source.type.${energySource}`
              )}
            />
          )}
          {typeOfHeating && (
            <BoxInfo
              type={t(
                'expose-page.tabs.overview.energy-efficiency.type-of-heating'
              )}
              value={t(
                `expose-page.tabs.overview.energy-efficiency.type-of-heating.type.${typeOfHeating}`
              )}
            />
          )}
        </BoxesContainer>
      </InfoContainer>
    </>
  );
};

export { EnergyEfficiency };
