import { FC, memo, useEffect, useState } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { isNumber } from 'lodash';

import { Container } from './input-with-calculate-value-styles';
import { InputV2 } from '../../../../../../components/common/input-v2/input-v2';
import {
  addCommas,
  removeNonNumeric,
} from '../../../../../../utils/number-format';

interface IProps {
  name: string;
  label: string;
  placeholder: string;
  isOwnConstruction?: boolean;
  purchasePrice?: number | null;
  landPrice?: number | null;
  defaultValue?: number;
  onChangePrice: (value: number) => void;
  onChangeProp: (value: number) => void;
  isDisabled?: boolean;
  isCounts?: boolean;
  isPercent?: boolean;
  rules?: RegisterOptions;
}

const InputWithCalculateValueBase: FC<IProps> = ({
  name,
  label,
  placeholder,
  isOwnConstruction,
  purchasePrice,
  landPrice,
  defaultValue,
  onChangePrice,
  onChangeProp,
  isDisabled,
  isCounts,
  isPercent,
  rules,
}) => {
  const [calcValue, setCalcValue] = useState<string>('0');

  useEffect(() => {
    if (isNumber(defaultValue)) {
      setCalcValue(
        addCommas(
          removeNonNumeric(
            Math.round(
              ((isOwnConstruction ? Number(landPrice) : Number(purchasePrice)) *
                defaultValue) /
                100
            )
          )
        )
      );
      onChangePrice(
        Math.round(
          ((isOwnConstruction ? Number(landPrice) : Number(purchasePrice)) *
            defaultValue) /
            100
        )
      );
    }
    // if add onChangePrice then there will be rerender loop
  }, [defaultValue, purchasePrice, isOwnConstruction, landPrice]);

  const onChange = (value: number | string) => {
    const valueNumber = Number(value);
    setCalcValue(
      addCommas(
        removeNonNumeric(
          Math.round(
            ((isOwnConstruction ? Number(landPrice) : Number(purchasePrice)) *
              valueNumber) /
              100
          )
        )
      )
    );
    onChangeProp(
      Math.round(
        ((isOwnConstruction ? Number(landPrice) : Number(purchasePrice)) *
          valueNumber) /
          100
      )
    );
  };

  return (
    <Container isCounts={isCounts}>
      <InputV2
        name={name}
        label={label}
        type="number"
        placeholder={placeholder}
        defaultValue={defaultValue}
        inputAdornment="%"
        isValidateOnBlur
        isOldDesign
        isSeparate
        isDecimalNumber
        isDisabled={isDisabled}
        handleChangeProp={onChange}
        rules={rules}
        isPercent={isPercent}
      />
      <div>=</div>
      <div>{calcValue} €</div>
    </Container>
  );
};

const InputWithCalculateValue = memo(InputWithCalculateValueBase);

export { InputWithCalculateValue };
