import { ReactComponent as BikingHelmetPerson } from 'assets/streamline-light/sports/biking/biking-helmet-person.svg';
import { ReactComponent as Car } from 'assets/streamline-light/transportation/cars/car.svg';
import { ReactComponent as RailroadMetro } from 'assets/streamline-light/transportation/railroad/railroad-metro.svg';
import { ReactComponent as WalkingCrossStreet } from 'assets/streamline-light/wayfinding/walking/walking-cross-street.svg';
import styled from 'styled-components';
import { batch } from 'react-redux';
import { FC, useCallback, useEffect } from 'react';
// import { useExposeDetailsPriceHubble } from '../../../hooks/useExposeDetailsQueries';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import { IsochronesMode, Property } from '../../../../../../../generated';
import {
  ButtonConfig,
  TransportSelector,
} from '../../../../../../property/components/card/elements/transport-selector';
import { changeMapViewport, setMapSettings } from '../../../redux/exposeSlice';
import {
  setIsReachabilityTab,
  setTransportMode,
  toggleReachabilityLegend,
} from '../../../../../../property/redux/dynamicMapUtilsSlice';
import { useTravelTime } from '../../../../../../map/hooks/useTravelTime';
import { ConnectionCard } from '../../../../../../property/components/card/connection-card/connection-card';
import { ReachabilityOfferCard } from '../../../../../../property/components/card/reachability-offer-card/reachability-offer-card';
import { device } from '../../../../../../../style/theme';

const Container = styled.div`
  margin-top: 32px;
  .big-card-container {
    border: none;
    padding: 0;
  }

  @media ${device.tablet} {
    margin-top: 16px;
  }
`;

const CardContainer = styled.div`
  margin-bottom: 32px;

  @media ${device.tablet} {
    margin-bottom: 16px;
  }
`;

const Selector = styled(TransportSelector)`
  margin-bottom: 32px;

  @media ${device.tablet} {
    margin-bottom: 16px;
  }
`;

const modeButtonsConfig: ButtonConfig[] = [
  {
    icon: WalkingCrossStreet,
    mode: IsochronesMode.Walk,
    text: 'reachability.mode.walk',
  },
  {
    icon: BikingHelmetPerson,
    mode: IsochronesMode.Bicycle,
    text: 'reachability.mode.bicycle',
  },
  {
    icon: Car,
    mode: IsochronesMode.Car,
    text: 'reachability.mode.car',
  },
  {
    icon: RailroadMetro,
    mode: IsochronesMode.PublicTransport,
    text: 'reachability.mode.public-transport',
  },
];

interface IProps {
  property: Partial<Property>;
}

const Reachability: FC<IProps> = ({ property }) => {
  const dispatch = useAppDispatch();
  const transportMode = useAppSelector(
    (state) => state.dynamicMapUtils.transportMode
  );
  const reachabilityLegendMode = useAppSelector(
    (state) => state.dynamicMapUtils.reachabilityLegendMode
  );
  const currentMapboxMarkers = useAppSelector(
    (state) => state.exposeDetails.map.markers
  );
  const { poi, propertyData, countryCode } = property || {};
  const transportPOI = poi?.transport;
  const coordinates = propertyData?.location?.coordinates;

  const handleTransportMode = useCallback(
    (mode: IsochronesMode) => {
      dispatch(setTransportMode(mode));
    },
    [dispatch]
  );

  const densities = useTravelTime({
    coordinates,
    transportPOI,
    reachabilityLegendMode,
    currentMapboxMarkers,
    transportMode,
    isExposeRequest: true,
    countryCode: countryCode || 'DE',
  });

  useEffect(() => {
    const pin = { ...coordinates };

    batch(() => {
      dispatch(toggleReachabilityLegend(true));
      dispatch(
        changeMapViewport({
          ...coordinates,
          bearing: -80,
          pitch: 50,
          zoom: 12,
          transitionDuration: 100,
        })
      );
      dispatch(
        setMapSettings({
          dragPan: true,
          dragRotate: true,
          scrollZoom: true,
          touchZoom: true,
          touchRotate: true,
          keyboard: true,
          doubleClickZoom: true,
        })
      );
      dispatch(setMapSettings({ radius: 0 }));
    });
  }, [coordinates, dispatch]);

  useEffect(() => {
    switch (transportMode) {
      case IsochronesMode.Walk:
        dispatch(changeMapViewport({ zoom: 12 }));
        break;
      case IsochronesMode.Bicycle:
        dispatch(changeMapViewport({ zoom: 11 }));
        break;
      case IsochronesMode.PublicTransport:
        dispatch(changeMapViewport({ zoom: 10 }));
        break;
      case IsochronesMode.Car:
        dispatch(changeMapViewport({ zoom: 9 }));
        break;
      default:
        dispatch(changeMapViewport({ zoom: 12 }));
    }
  }, [transportMode]);

  useEffect(() => {
    dispatch(setIsReachabilityTab(true));

    return () => {
      dispatch(setIsReachabilityTab(false));
    };
  }, []);

  return (
    <Container>
      <Selector
        selected={transportMode}
        onSelect={handleTransportMode}
        buttonsConfig={modeButtonsConfig}
      />
      {densities?.densities15 && (
        <CardContainer>
          <ReachabilityOfferCard
            isNoPadding
            isNoBorder
            densities={densities?.densities15}
          />
        </CardContainer>
      )}
      {transportPOI && (
        <ConnectionCard isNoPadding isNoBorder transports={transportPOI} />
      )}
    </Container>
  );
};

export { Reachability };
