import { RefObject, useEffect, useState } from 'react';

interface IProps {
  selectButton?: RefObject<HTMLButtonElement | HTMLDivElement>;
  optionsDiv: RefObject<HTMLDivElement>;
  isSelectOpen?: boolean;
}

interface IReturn {
  leftPX: number;
  topPX: number;
  xCenterPX: number;
}

const useGetOptionsCoordinates = ({
  selectButton,
  optionsDiv,
  isSelectOpen,
}: IProps): IReturn => {
  const [{ leftPX, topPX, xCenterPX }, setOptionsCoordinates] = useState({
    leftPX: 0,
    topPX: 0,
    xCenterPX: 0,
  });

  useEffect(() => {
    const coordinatesButton = selectButton?.current?.getBoundingClientRect();
    const coordinatesDiv = optionsDiv.current?.getBoundingClientRect();
    setOptionsCoordinates({
      topPX: Number(coordinatesButton?.height),
      leftPX:
        (Number(coordinatesButton?.width) - Number(coordinatesDiv?.width)) / 2,
      xCenterPX: Number(coordinatesDiv?.width) / 2,
    });
  }, [isSelectOpen, optionsDiv, selectButton]);

  return { leftPX, topPX, xCenterPX };
};

export { useGetOptionsCoordinates };
