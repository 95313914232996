import { useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useConfirmForgotPasswordMutation } from '../../../../generated';
import { device } from '../../../../style/theme';

import { InputPassOutsideTopLabel } from '../../../common/components/form';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { FormColumn, FormRow } from '../../../common/components/form/form-grid';
import { MainButton } from '../../../common/components/ui/buttons';
import { StatusType } from '../../../common/components/verification-input/types';

import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { ICreatePasswordForm, IRegisterForm } from '../../interfaces';
import {
  toggleIsConfirmForgotPasswordModalOpen,
  toggleIsConfirmForgotPassworSuccessdModalOpen,
} from '../../redux/authSlice';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  @media ${device.tablet} {
    position: relative;
    height: 100%;
  }
`;

const SubmitButton = styled(MainButton)`
  margin: 24px 0 4px 0;
`;

const CodeInformationWrapper = styled.div`
  text-align: left;
`;

const LastFormRow = styled(FormRow)`
  @media ${device.tablet} {
    margin-top: auto;
  }
`;

const ConfirmForgotPasswordForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const confirmationCode = urlParams.get('confirmation_code') || '';
  const username = urlParams.get('username') || '';
  const token = urlParams.get('token') || '';
  const [status, setStatus] = useState<StatusType>(StatusType.process);

  const [missmatchError, setMissmatchError] = useState('');

  const [
    confirmForgotPassword,
    { error: confirmForgotPasswordError, data: confirmForgotPasswordData },
  ] = useConfirmForgotPasswordMutation();

  const methods = useForm<IRegisterForm>({
    mode: 'onTouched',
  });

  useEffect(() => {
    if (confirmForgotPasswordData) {
      dispatch(toggleIsConfirmForgotPasswordModalOpen(false));
    }
    return () => {
      if (confirmForgotPasswordData) {
        dispatch(toggleIsConfirmForgotPassworSuccessdModalOpen(true));
      }
    };
  }, [confirmForgotPasswordData, dispatch]);

  const onSubmit: SubmitHandler<ICreatePasswordForm> = useCallback(
    async (formData) => {
      setStatus(StatusType.process);
      setMissmatchError('');

      let hasErrors = false;
      if (confirmationCode.length === 0 || !token || !username) {
        setStatus(StatusType.error);
        hasErrors = true;
      }

      if (formData.password !== formData.confirmPassword) {
        setMissmatchError(t('Password missmatch.'));
        hasErrors = true;
      }

      if (hasErrors) {
        return;
      }
      await confirmForgotPassword({
        input: {
          password: formData.password,
          username,
          confirmationCode,
          token,
        },
      });
    },
    [token, confirmationCode, username, t, confirmForgotPassword]
  );

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {(confirmForgotPasswordError?.message || missmatchError.length > 0) && (
          <GlobalError
            title={
              t(confirmForgotPasswordError?.message?.split(/[,:]/)[0] ?? '') ||
              missmatchError
            }
          />
        )}
        <FormRow>
          <FormColumn flex="0 0 100%">
            <InputPassOutsideTopLabel
              name="password"
              type="password"
              label="register.form.input.label.password"
              rules={{
                required: 'register.input.error.required',
              }}
              passwordMasterValue={methods.watch('password')}
              placeholder="confirm-forgot-password.form.input.new-password.placeholder"
            />
          </FormColumn>
          <FormColumn flex="0 0 100%">
            <InputPassOutsideTopLabel
              name="confirmPassword"
              type="password"
              label="register.form.input.label.confirm-password"
              placeholder="confirm-forgot-password.form.input.confirm-new-password.placeholder"
              rules={{
                required: 'register.input.error.required',
              }}
              passwordMasterValue={methods.watch('password')}
              skipPattern
            />
          </FormColumn>
        </FormRow>
        <LastFormRow>
          <FormColumn flex="0 0 100%">
            <SubmitButton
              isDisabled={confirmationCode.length < 6}
              fluid
              centered
              label={t('confirm-forgot-password.form.button.submit')}
            />
          </FormColumn>
        </LastFormRow>
      </Form>
    </FormProvider>
  );
};

export { ConfirmForgotPasswordForm };
