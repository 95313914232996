import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const Container = styled.div`
  display: flex;
  gap: 48px;
  margin-bottom: 64px;

  @media ${device.tablet} {
    display: block;
    margin: 0 0 16px 0 !important;
  }
`;

export const BannerBox = styled.a`
  display: flex;
  width: 526px;
  height: 340px;
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.09);

  @media ${device.tablet} {
    width: 100%;
    height: 245px;
  }
`;

export const BannerImage = styled.img`
  flex: 1;
  object-fit: cover;
  @media ${device.tablet} {
    object-fit: contain;
  }
`;

export const TextBox = styled.div`
  padding-top: 32px;

  @media ${device.tablet} {
    padding: 0;
  }
`;

export const Title = styled.h2`
  font-family: 'Source Serif Pro';
  font-size: 36px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #00305e;
  margin: 0 0 16px 0;

  @media ${device.tablet} {
    display: none;
  }
`;

export const TitleMobile = styled.h2`
  font-family: 'Source Serif Pro';
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.5px;
  color: #00305e;
  margin: 0 0 16px 0;
  text-align: center;
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

export const ExpandedText = styled.p`
  margin: 0 2px 0 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: #002849;

  @media ${device.tablet} {
    margin: 16px 16px 24px;
  }
`;
