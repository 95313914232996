import styled from 'styled-components';
import {
  Headline3,
  ParagraphText,
} from '../../../../common/components/ui/typography';
import { PropertyIcon } from '../../icon/icon';
import { MainButton } from '../../../../common/components/ui/buttons';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../style/theme';

export const Container = styled.div<{ isHighlighted?: boolean }>`
  min-height: 72px;
  padding: 0 12px;
  border-radius: 3px;
  border: ${(props) =>
    `${props.isHighlighted ? '2px' : '1px'} solid ${props.theme.lightBlue}`};
  background-color: ${(props) => props.theme.greyBackground};
  display: grid;
  grid-template-areas:
    'id info price acceptBtn'
    'id info price acceptBtn';
  grid-template-columns: 48px 1fr max-content 32px;
  grid-template-rows: 1fr;
  column-gap: 12px;
  align-items: center;

  @media ${device.tablet} {
    grid-template-areas:
      'info price acceptBtn'
      'info price acceptBtn';
    grid-template-columns: 1fr max-content 32px;
    padding: 0 8px;
    min-height: 100px;
  }
`;

export const BidDate = styled.p`
  grid-area: id;
  width: 48px;
  height: 48px;
  padding: 6px 0 0;
  border-radius: 3px;
  background-color: ${(props) => hexToRGB(props.theme.darkGrey, 0.3)};
  color: ${(props) => props.theme.blue};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: source serif pro, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  flex-direction: column;
`;

export const Info = styled.div`
  grid-area: info;
  display: flex;
  //flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 4px;
  border-right: 1px solid ${(props) => props.theme.lightBlue};
  align-self: stretch;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: max-content;

  @media ${device.mobile} {
    align-items: start;
    gap: 4px;
    margin-top: 4px;
  }
`;

export const Title = styled(Headline3)`
  font-size: 16px;
  line-height: 1.25;

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const CityWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FromCity = styled(ParagraphText)`
  display: flex;
  font-size: 10px;
  opacity: 0.5;
  line-height: 1.6;
  text-transform: uppercase;

  @media ${device.mobile} {
    font-size: 8px;
  }
`;

export const CityName = styled(FromCity)`
  font-weight: 900;
  margin-left: 4px;
`;

export const OfferIcon = styled(PropertyIcon)`
  grid-area: price;
  align-self: center;
`;

export const AcceptOfferBtn = styled(MainButton)`
  grid-area: acceptBtn;
  width: 32px;
  height: 48px;
  padding: 16px 8px;
  align-self: center;
`;
