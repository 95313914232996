import { CSSProperties, FC, Fragment, useEffect, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { isBoolean } from 'lodash';

import { ReactComponent as InformationCircle } from 'assets/streamline-light/interface-essential/alerts/information-circle.svg';
import { device } from '../../../../style/theme';

import { IconBox } from '../../../common/components/ui/icon-box';
import { RadioBox } from '../../../common/components/ui/radio-box';
import { Headline3 } from '../../../common/components/ui/typography';
import { BoxType, IBox } from '../../interface';
import { useAppSelector } from '../../../common/hooks';
import { PropertyCode } from '../../../../generated';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { TooltipContainer, TooltipParagraph } from './input-v2/input-v2-styles';
import { Tooltip } from '../../../common/components/tooltip';

const Container = styled.div<{
  column?: boolean;
  isGrid?: boolean;
  isIframe?: boolean;
  isTabletTwoBoxesColumn?: boolean;
  gap?: number;
  tabletGap?: number;
}>`
  display: flex;
  gap: ${({ gap }) => gap || 7}px;
  flex: 1;
  ${(props) =>
    props.column &&
    `
  flex-direction: column;`}
  width: 100%;
  flex-wrap: ${({ isGrid }) => (isGrid ? 'wrap' : 'nowrap')};

  @media ${({ isIframe }) => (isIframe ? device.mobileIframe : device.tablet)} {
    flex-direction: column;
    gap: ${({ tabletGap }) => tabletGap || 4}px;
    ${({ isTabletTwoBoxesColumn }) =>
      isTabletTwoBoxesColumn &&
      css`
        display: grid;
        grid-template-columns: calc(50% - 6px) calc(50% - 6px);
      `}
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;

  @media ${device.tablet} {
    width: 100%;
  }
`;

const Title = styled(Headline3)`
  margin: 18px auto 12px auto;
`;

const Label = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.7)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;

  @media ${device.tablet} {
    margin-right: 4px;
  }
`;

export const Info = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;
  .icon {
    > svg,
    > svg > path {
      stroke: ${(props) => props.theme.blue};
    }
  }

  @media ${device.tablet} {
    margin-right: 0;
  }
`;

interface IProps {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  boxes?: IBox[];
  column?: boolean;
  centered?: boolean;
  isRowBox?: boolean;
  defaultValue?: number | string | boolean;
  containerTitle?: string;
  label?: string;
  containerStyles?: CSSProperties;
  onClick?: () => void;
  handleChangeProp?: (value: boolean) => void;
  boxMaxWidth?: number;
  boxMinHeight?: number;
  boxIconHeight?: number;
  boxGap?: number;
  isWrap?: boolean;
  isBoxTabletColumn?: boolean;
  isTabletTwoBoxesColumn?: boolean;
  isIframe?: boolean;
  isFullWidth?: boolean;
  boxPadding?: string;
  tooltipKey?: string;
  minWidthBox?: number;
  gap?: number;
  tabletGap?: number;
}

const BoxSelector: FC<IProps> = ({
  name,
  boxes,
  column,
  centered,
  isRowBox,
  defaultValue,
  containerTitle,
  label,
  containerStyles,
  onClick,
  boxPadding,
  tooltipKey,
  isWrap,
  boxMaxWidth,
  boxMinHeight,
  boxIconHeight,
  boxGap,
  isBoxTabletColumn,
  isTabletTwoBoxesColumn,
  minWidthBox,
  isIframe,
  isFullWidth,
  gap,
  tabletGap,
  handleChangeProp,
}) => {
  const {
    field: { ref, value, onChange },
  } = useController({
    name,
    defaultValue,
  });
  const selectedCountryCode = useAppSelector(
    (state) => state.valuationWizard.selectedCountryCode
  );

  useEffect(() => {
    if (
      selectedCountryCode === 'AT' &&
      value === PropertyCode.MultiFamilyHouse
    ) {
      onChange('');
    }
  }, [onChange, selectedCountryCode, value]);

  const onSelectBox =
    <T,>(v: T, isDisabledCustomOnClick?: boolean) =>
    () => {
      onChange(v);
      if (onClick && !isDisabledCustomOnClick) {
        onClick();
      }
      if (handleChangeProp) {
        handleChangeProp(Boolean(v));
      }
    };

  const { t } = useTranslation();
  // eslint-disable-next-line consistent-return
  const { minWidth } = useMemo(() => {
    if (minWidthBox) {
      return { minWidth: minWidthBox };
    }
    if (boxes) {
      if (boxes.length === 4) {
        return { minWidth: 202 };
      }
      if (boxes.length === 5 || boxes.length === 6) {
        return { minWidth: 178 };
      }
    }
    return {};
  }, [boxes, minWidthBox]);

  const isGrid = isBoolean(isWrap)
    ? isWrap
    : minWidthBox
    ? minWidthBox >= 200
    : boxes && boxes.length > 3;

  const onClickLabel = () => {
    if (tooltipKey) {
      const tooltip = document.querySelector(
        `#${tooltipKey.replaceAll('.', '-')}-icon`
      ) as HTMLParagraphElement;
      if (tooltip) {
        tooltip.click();
      }
    }
  };

  return (
    <>
      {containerTitle && <Title content={t(containerTitle)} />}
      {label && (
        <InfoContainer>
          <Label onClick={onClickLabel}>{t(label)}</Label>
          {tooltipKey && (
            <Info>
              <Tooltip
                icon={InformationCircle}
                id={tooltipKey}
                position="bottom"
                width={16}
                isOnClick
                height={16}
              >
                <TooltipContainer>
                  <TooltipParagraph>{t(tooltipKey)}</TooltipParagraph>
                </TooltipContainer>
              </Tooltip>
            </Info>
          )}
        </InfoContainer>
      )}
      <Container
        ref={ref}
        column={column}
        style={containerStyles}
        isGrid={isGrid}
        isIframe={isIframe}
        isTabletTwoBoxesColumn={isTabletTwoBoxesColumn}
        gap={gap}
        tabletGap={tabletGap}
      >
        {boxes?.map((box) => (
          <Fragment
            key={`${String(box.value)} - ${box.text ?? ''} - ${box.boxType}`}
          >
            {box.boxType === BoxType.iconBox && (
              <IconBox
                id={box.id}
                key={box.text}
                icon={box.icon}
                iconImage={box.iconImage}
                iconColor={box.iconColor}
                iconSelectedColor={box.iconSelectedColor}
                fillIconColor={box.fillIconColor}
                title={t(box?.title ?? '')}
                text={t(box?.text ?? '')}
                onClick={onSelectBox(box.value, box.isDisabledCustomOnClick)}
                centered={centered}
                isFullWidth={isFullWidth}
                isRowBox={isRowBox}
                isSelected={value === box.value}
                isDisabled={box.isDisabled}
                minWidth={minWidth}
                maxWidth={boxMaxWidth}
                minHeight={boxMinHeight}
                iconHeight={boxIconHeight}
                gap={boxGap}
                padding={boxPadding}
                isBoxTabletColumn={isBoxTabletColumn}
                isIframe={isIframe}
              />
            )}
            {box.boxType === BoxType.radioBox && box.title && (
              <RadioBox
                key={box.text}
                title={t(box.title)}
                text={t(box?.text ?? '')}
                onClick={onSelectBox(box.value)}
                isChecked={value === box.value}
                boxPadding={boxPadding}
                isTwoItems={boxes?.length === 2}
                isFullWidth={isFullWidth}
                {...(value === box.value &&
                  box.nestedInput && { nestedInput: box.nestedInput })}
                {...(value === box.value &&
                  box.nestedInputPlaceholder && {
                    nestedInputPlaceholder: box.nestedInputPlaceholder,
                  })}
              />
            )}
          </Fragment>
        ))}
      </Container>
    </>
  );
};

export { BoxSelector };
