import { ReactComponent as Buildings2 } from 'assets/streamline-light/building-construction/buildings/buildings-2.svg';
import { ReactComponent as HouseApartment } from 'assets/streamline-light/building-construction/houses/house-apartment.svg';
import { ReactComponent as House } from 'assets/streamline-light/building-construction/houses/house-3.svg';
import { easeCubic } from 'd3-ease';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FlyToInterpolator } from 'react-map-gl';
import { batch } from 'react-redux';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce/lib';

import { PropertyCode } from '../../../../../../generated';
import { Feature } from '../../../../../../services/mapbox/interfaces';
import { useLazyForwardGeocodingQuery } from '../../../../../../services/mapbox/mapbox-api';
import { device } from '../../../../../../style/theme';
import { GlobalError } from '../../../../../common/components/form/error/global-error';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { changeViewport, setMarkers } from '../../../../../map/redux/mapSlice';
import {
  VALUATION_FORM_KEYS,
  APARTMENT_SUBTYPES,
  HOUSE_SUBTYPES,
  Proximity,
} from '../../../constants';
import { BoxType, IAddress } from '../../../../interface';
import { BorderButton } from '../../../../../common/components/ui/buttons';

import {
  nextStepAction,
  setSelectedAddressAction,
  setSelectedCountryCode,
  resetValuationWizard,
} from '../../../redux/valuationWizardSlice';
import {
  extractCorrectValue,
  getSuggestions,
} from '../../../../utils/suggestions';
import { BoxSelector } from '../../../../components/common/box-selector';
import { Input } from '../../../../components/common/input/input';
import { InputWithSuggestions } from '../../../../components/common/input-with-suggestions';
import { NextButton } from '../../../../components/common/next-button';
import { Europe } from '../../../../../map/constants/points-of-interest';
import { SelectWithLabel } from '../../../../components/common/select-wth-label';
import getCountry from '../../../../utils/getCountry';
import { useLngHistoryPush } from '../../../../../localization/lng-history-push';
import { useSetProximityFromLocalization } from '../../../../hooks/set-proximity-from-localization';
import { useGetCountry } from '../../../../../localization/get-country';
import { siteMap } from '../../../../../../routes/site-map';

const Container = styled.div<{
  isiframe?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media ${({ isiframe }) => (isiframe ? '0' : device.tablet)} {
    overflow: overlay;
  }
`;

const Row = styled.div<{ direction?: string; isiframe?: boolean }>`
  display: flex;
  flex-direction: ${(props) => props.direction ?? 'row'};

  &:last-of-type {
    margin-top: auto;
    justify-content: flex-start;
    > *:last-of-type {
      margin-left: auto;
    }
  }

  @media ${({ isiframe }) => (isiframe ? '0' : device.tablet)} {
    &:nth-of-type(2) {
      margin-bottom: 32px;
    }

    &:last-of-type {
      margin-top: auto;
    }
  }
`;

const defaultDebaunceState = {
  postCode: '',
  street: '',
  number: '',
  city: '',
  state: '',
  locality: '',
  neighborhood: '',
  proximity: '9.993682,53.551086',
  country: 'de,at,ch',
  language: 'de',
  fuzzyMatch: 'true',
};

const fields = [
  VALUATION_FORM_KEYS.STREET,
  VALUATION_FORM_KEYS.NUMBER,
  VALUATION_FORM_KEYS.CITY,
  VALUATION_FORM_KEYS.CODE,
];

interface IProps {
  regionalFirstStepNext?: () => void;
}

const FirstStepBase: FC<IProps> = ({ regionalFirstStepNext }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const isMobileSize = useIsMobileSize();

  const lngHPush = useLngHistoryPush();
  const { language } = useGetCountry();

  const storedAddress = useAppSelector(
    (state) => state.valuationWizard.selectedAddress
  );
  const propertyDossier = useAppSelector(
    (state) => state.valuationWizard.propertyDossier
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const selectedCountryCode = useAppSelector(
    (state) => state.valuationWizard.selectedCountryCode
  );
  const relatedCountry = useAppSelector(
    (state) => state.valuationWizard.relatedCountry
  );

  const [proceedToNextStep, setProceedToNextStep] = useState(false);
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [debouncedAddress, setDebouncedAddress] =
    useState(defaultDebaunceState);
  const [selectedAddress, setSelectedAddress] = useState(storedAddress || null);
  const [selectedSubcodeTitle, setSelectedSubcodeTitle] = useState('');
  const proximity = useSetProximityFromLocalization();

  // fix autocomplete for MS Edge
  const [isReadonly, setIsReadonly] = useState(true);

  const [street, number, city, code, subcode, renovationYear, buildingYear] =
    useWatch({
      name: [
        ...fields,
        VALUATION_FORM_KEYS.SUBCODE,
        VALUATION_FORM_KEYS.RENOVATION_YEAR,
        VALUATION_FORM_KEYS.BUILDING_YEAR,
      ],
    });

  const isShowRenovationYear = useMemo(
    () => code && PropertyCode.MultiFamilyHouse !== code,
    [code]
  );
  const isRenovationYearLower = useMemo(
    () =>
      isShowRenovationYear &&
      buildingYear &&
      renovationYear &&
      renovationYear <= buildingYear,
    [isShowRenovationYear, buildingYear, renovationYear]
  );

  const [
    geocodingTrigger,
    { mostAccurateGeocodingResult, isFetching: isMapboxFetching },
  ] = useLazyForwardGeocodingQuery({
    selectFromResult: ({ data, isFetching }) => ({
      mostAccurateGeocodingResult: data,
      isFetching,
    }),
  });

  const onDebounceAddressChange = useDebouncedCallback(
    (newDebouncedAddress: IAddress) => setDebouncedAddress(newDebouncedAddress),
    300
  );

  const onSuggestionClick = useCallback(
    (suggestion: Feature) => {
      const { updatedCity, updatedStreet, updatedNumber } =
        extractCorrectValue(suggestion);
      dispatch(setSelectedCountryCode(getCountry(suggestion)));

      setValue(VALUATION_FORM_KEYS.STREET, `${updatedStreet} ${updatedNumber}`);
      setValue(VALUATION_FORM_KEYS.NUMBER, updatedNumber);
      setValue(VALUATION_FORM_KEYS.CITY, updatedCity);

      if (!updatedStreet || !updatedNumber || !updatedCity) {
        setInvalidAddress(true);
      } else {
        setInvalidAddress(false);
      }

      const selectedSuggestion = JSON.parse(JSON.stringify([suggestion]));
      setSelectedAddress(selectedSuggestion[0]);
    },
    [dispatch, setValue]
  );

  const setFinalAddress = useCallback(() => {
    if (regionalFirstStepNext) {
      regionalFirstStepNext();
    }
    geocodingTrigger(debouncedAddress);
    setProceedToNextStep(true);
  }, [regionalFirstStepNext, geocodingTrigger, debouncedAddress]);

  const onPrevButtonClick = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        changeViewport({
          ...Europe,
          transitionDuration: 1000,
        })
      );
      if (propertyDossier?._id) {
        lngHPush(
          `${siteMap.PropertyPage.pathWithoutParams}/${propertyDossier._id}`
        );
      }
      setTimeout(() => {
        batch(() => {
          dispatch(resetValuationWizard());
        });
      }, 500);
    },
    [dispatch, lngHPush, propertyDossier?._id]
  );

  const onSubcodeSelect = useCallback(
    (value: unknown) => {
      setValue(VALUATION_FORM_KEYS.SUBCODE, value);
    },
    [setValue]
  );

  useEffect(() => {
    let selectedOption = null;
    if (PropertyCode.Apartment === code) {
      selectedOption = APARTMENT_SUBTYPES.find(
        (type) => type.value === subcode
      );
    } else if (PropertyCode.House === code) {
      selectedOption = HOUSE_SUBTYPES.find((type) => type.value === subcode);
    }
    if (selectedOption) {
      setSelectedSubcodeTitle(selectedOption.label);
    } else {
      setSelectedSubcodeTitle('');
    }
  }, [code, subcode]);

  useEffect(() => {
    if (
      !isMapboxFetching &&
      proceedToNextStep &&
      mostAccurateGeocodingResult &&
      mostAccurateGeocodingResult?.length > 0
    ) {
      const address = selectedAddress || mostAccurateGeocodingResult?.[0];
      const pin = {
        longitude: address?.center?.[0],
        latitude: address?.center?.[1],
      };

      if (pin?.longitude && pin?.latitude) {
        batch(() => {
          dispatch(setSelectedAddressAction(address));
          dispatch(
            changeViewport({
              longitude: (pin?.longitude ?? 0) + 0.005,
              latitude: (pin?.latitude ?? 0) - 0.001,
              zoom: 15,
              transitionDuration: 'auto',
              transitionInterpolator: new FlyToInterpolator({
                speed: 1.8,
              }),
              transitionEasing: easeCubic,
            })
          );
          dispatch(setMarkers([pin]));
          dispatch(nextStepAction());
        });
      }
    } else if (
      !isMapboxFetching &&
      proceedToNextStep &&
      mostAccurateGeocodingResult?.length === 0
    ) {
      setInvalidAddress(true);
    }
  }, [
    dispatch,
    isMapboxFetching,
    proceedToNextStep,
    mostAccurateGeocodingResult,
    selectedAddress,
  ]);

  useEffect(() => {
    if (
      (debouncedAddress.number !== number && number?.length > 0) ||
      (debouncedAddress.street !== street && street?.length > 2) ||
      (debouncedAddress.city !== city && city?.length > 2)
    ) {
      let defaultProximity;
      switch (relatedCountry && relatedCountry.toLowerCase()) {
        case 'germany':
          defaultProximity = Proximity.GERMANY;
          break;
        case 'austria':
          defaultProximity = Proximity.AUSTRIA;
          break;
        case 'switzerland':
          defaultProximity = Proximity.SWITZERLAND;
          break;
        default:
          defaultProximity = Proximity.GERMANY;
      }

      setProceedToNextStep(false);
      onDebounceAddressChange({
        ...debouncedAddress,
        proximity: proximity || defaultProximity,
        city,
        street,
        number,
        language,
      });
    }
  }, [
    language,
    onDebounceAddressChange,
    city,
    street,
    number,
    debouncedAddress,
    proximity,
    relatedCountry,
  ]);

  useEffect(() => {
    if (
      Object.keys(debouncedAddress)
        .filter((key) => key !== 'country')
        .some(
          (key) => (debouncedAddress as { [key: string]: string })[key] !== ''
        )
    ) {
      geocodingTrigger(debouncedAddress);
    }
  }, [geocodingTrigger, debouncedAddress]);

  // Clear subcode value
  useEffect(() => {
    setValue(VALUATION_FORM_KEYS.SUBCODE, '');
  }, [code, setValue]);

  // Fix autocomplete for MS Edge
  useEffect(() => setIsReadonly(false), []);

  return (
    <Container isiframe={isIframe}>
      <Row isiframe={isIframe}>
        <BoxSelector
          name={VALUATION_FORM_KEYS.CODE}
          boxes={[
            {
              id: 'etw',
              boxType: BoxType.iconBox,
              title: 'valuation.form.first-step.building.apartment',
              value: PropertyCode.Apartment,
              icon: Buildings2,
            },
            {
              id: 'efh',
              boxType: BoxType.iconBox,
              title: 'valuation.form.first-step.building.house',
              value: PropertyCode.House,
              icon: House,
            },
            {
              id: 'mfh',
              boxType: BoxType.iconBox,
              title: 'valuation.form.first-step.building.family-house',
              value: PropertyCode.MultiFamilyHouse,
              icon: HouseApartment,
              isDisabled: selectedCountryCode === 'AT',
            },
          ]}
        />
      </Row>
      {city &&
        !invalidAddress &&
        ![
          PropertyCode.Apartment,
          PropertyCode.House,
          PropertyCode.MultiFamilyHouse,
        ].includes(code) && (
          <GlobalError
            title={t('valuation.form.first-step.code-is-required')}
            topMargin
          />
        )}
      <Row direction="column" isiframe={isIframe}>
        {[PropertyCode.Apartment, PropertyCode.House].includes(code) && (
          <SelectWithLabel
            name={VALUATION_FORM_KEYS.SUBCODE}
            label={'valuation.form.subtype.label'}
            selectedOption={selectedSubcodeTitle}
            innerLabel={
              selectedSubcodeTitle !== ''
                ? ''
                : t('valuation.form.subtype.placeholder')
            }
            options={
              code === PropertyCode.Apartment
                ? APARTMENT_SUBTYPES
                : HOUSE_SUBTYPES
            }
            onSelect={onSubcodeSelect}
          />
        )}

        {invalidAddress && (
          <GlobalError title={t('invalid-address.error')} topMargin />
        )}
        <InputWithSuggestions
          name={VALUATION_FORM_KEYS.STREET}
          label={'valuation.form-first-step.address'}
          placeholder={'valuation.form.first-step.address-placeholder'}
          rules={{ required: 'register.input.error.required' }}
          tooltipKey="valuation.form.first-step.address-tooltip"
          readonly={isReadonly}
          suggestions={getSuggestions(
            VALUATION_FORM_KEYS.STREET,
            mostAccurateGeocodingResult ?? []
          )}
          onSuggestionClick={onSuggestionClick}
        />
        <InputWithSuggestions
          name={VALUATION_FORM_KEYS.CITY}
          label={'valuation.form-first-step.city'}
          placeholder={'valuation.form.first-step.city-placeholder'}
          rules={{ required: 'register.input.error.required' }}
          tooltipKey="valuation.form.first-step.city-tooltip"
          disabled
          suggestions={getSuggestions(
            VALUATION_FORM_KEYS.CITY,
            mostAccurateGeocodingResult ?? []
          )}
          onSuggestionClick={onSuggestionClick}
        />
        <Input
          name={VALUATION_FORM_KEYS.BUILDING_YEAR}
          label="valuation.form.first-step.building-year"
          placeholder="valuation.form.first-step.building-year-placeholder"
          tooltipKey="valuation.form.first-step.building-year-tooltip"
          type="number"
          rules={{
            required: 'register.input.error.required',
            min: {
              value: 1850,
              message: 'valuation.form.first-step.building-year-min-error',
            },
            max: {
              value: new Date().getFullYear() + 3,
              message: 'valuation.form.first-step.building-year-max-error',
            },
            valueAsNumber: true,
            setValueAs: (v) => Number.parseInt(v, 10),
          }}
        />
        {isShowRenovationYear && (
          <>
            <Input
              name={VALUATION_FORM_KEYS.RENOVATION_YEAR}
              label="valuation.form.first-step.renovation-year"
              placeholder="valuation.form.first-step.renovation-year-placeholder"
              tooltipKey="valuation.form.first-step.renovation-year-tooltip"
              type="number"
              rules={{
                required: 'register.input.error.required',
                min: {
                  value: 1950,
                  message:
                    'valuation.form.first-step.renovation-year-min-error',
                },
                max: {
                  value: new Date().getFullYear() + 3,
                  message:
                    'valuation.form.first-step.renovation-year-max-error',
                },
                valueAsNumber: true,
                setValueAs: (v) => Number.parseInt(v, 10),
              }}
            />
            {isRenovationYearLower === true && (
              <GlobalError
                title={t(
                  'valuation.form.first-step.renovation-year-is-lower-than-building-year'
                )}
                topMargin
              />
            )}
          </>
        )}
      </Row>
      <Row isiframe={isIframe}>
        {(!isMobileSize || isIframe) && !regionalFirstStepNext && (
          <BorderButton
            id="step-1"
            label={t('button.back')}
            onClick={onPrevButtonClick}
          />
        )}
        <NextButton
          watchedKeys={[
            ...fields,
            VALUATION_FORM_KEYS.BUILDING_YEAR,
            ...(code &&
            subcode &&
            [PropertyCode.Apartment, PropertyCode.House].includes(code)
              ? [VALUATION_FORM_KEYS.SUBCODE]
              : []),
            ...(isShowRenovationYear &&
            typeof renovationYear !== 'undefined' &&
            !Number.isNaN(renovationYear)
              ? [VALUATION_FORM_KEYS.RENOVATION_YEAR]
              : []),
          ]}
          id="exit"
          label={t('button.next-step')}
          buttonCallback={setFinalAddress}
          outerDisable={invalidAddress || isRenovationYearLower}
        />
      </Row>
    </Container>
  );
};

const FirstStep = memo(FirstStepBase);

export { FirstStep };
