import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const DrawerContainer = styled.div<{ isDrawerOpened: boolean }>`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
  border-bottom: ${({ isDrawerOpened }) => (isDrawerOpened ? 'none' : '4px')}
    solid ${(props) => props.theme.ctaBlue};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media ${device.mobile} {
    bottom: -5px;
  }
`;

export const DrawerHandle = styled.div`
  width: 55px;
  height: 30px;
  background-color: ${(props) => props.theme.ctaBlue};
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  border: 10px solid ${(props) => props.theme.ctaBlue};
  border-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DrawerContentWrapper = styled.div<{
  drawerHeight: number;
  isDrawerOpened: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  //border-bottom-left-radius: 4px;
  //border-bottom-right-radius: 4px;
  width: 100%;
  overflow: hidden;
  // height: ${(props) => props.drawerHeight}px;
  // transition: height 0.25s ease-in;
  max-height: ${(props) => props.drawerHeight}px;
  transition: max-height 0.25s ease-in;
  transform: rotateX(180deg);
`;

export const DrawerContent = styled.div`
  transform: rotateX(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const DrawerContentTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  //height: 207px;
  max-height: 500px;
`;

export const DrawerContentBottom = styled.div<{
  buttonFlex?: string;
  containerPadding?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.blue};
  width: 100%;
  height: 64px;
  padding: 0 12px;

  > div {
    flex: 0 0 25%;
    width: 100%;
    justify-content: space-evenly;
    margin: 0;
    padding: ${(props) => props.containerPadding ?? '0 12px'};

    > button,
    > div:not(.__react_component_tooltip) {
      flex: ${(props) => props.buttonFlex ?? '0 0 25%'};

      > div {
        display: flex;
        flex: 1;

        > button {
          flex: 1;
        }
      }
    }
  }
`;
