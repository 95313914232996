import { useCallback } from 'react';
import { FlyToInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';
import { batch } from 'react-redux';

import {
  Austria,
  Germany,
  Switzerland,
} from '../../../../map/constants/points-of-interest';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import {
  setMapSettings,
  changeMapViewport,
  openVWAction,
  setValuationTypeAction,
} from '../../redux/valuationWizardV2Slice';
import { ValuationType } from '../../../../../generated';
import { defaultMapSettings } from '../../../../map/constants';

type IProps = {
  cb?: () => void;
  isLandingPage?: boolean;
};

type TReturn = {
  openEvaluationWizardV2: (type: ValuationType) => void;
};

export const useOpenEvaluationWizardV2 = ({ cb }: IProps): TReturn => {
  const relatedCountry = useAppSelector(
    (state) => state.valuationWizardV2.relatedCountry
  );
  const dispatch = useAppDispatch();

  const openEvaluationWizardV2 = useCallback(
    (type: ValuationType) => {
      let countryCoordinates = Germany;
      switch (relatedCountry && relatedCountry.toLowerCase()) {
        case 'germany':
          countryCoordinates = Germany;
          break;
        case 'austria':
          countryCoordinates = Austria;
          break;
        case 'switzerland':
          countryCoordinates = Switzerland;
          break;
        default:
          countryCoordinates = Germany;
      }

      batch(() => {
        dispatch(setValuationTypeAction(type));
        dispatch(
          changeMapViewport({
            ...countryCoordinates,
            transitionDuration: 1000,
            transitionInterpolator: new FlyToInterpolator({
              speed: 0.6,
              curve: 1,
            }),
            transitionEasing: easeCubic,
          })
        );
        dispatch(setMapSettings(defaultMapSettings));
        dispatch(openVWAction());
      });
      if (cb) {
        cb();
      }
    },
    [cb, dispatch, relatedCountry]
  );

  return {
    openEvaluationWizardV2,
  };
};
