import { forwardRef } from 'react';
import styled from 'styled-components';

interface IProps {
  content: string;
  className?: string;
}

const Container = styled.p`
  margin: 0;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1px;
  color: ${(props) => props.theme.blue};
`;

const ParagraphTitle = forwardRef<HTMLParagraphElement, IProps>(
  ({ content, ...rest }, ref) => (
    <Container {...rest} ref={ref}>
      {content}
    </Container>
  )
);

export { ParagraphTitle };
