import { Path, Svg } from '@react-pdf/renderer';

import { defaultTheme } from '../../../../style/theme';

const HouseIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={
          'M.5 23.5h23m-22 0v-7m11 0v7m-7 0v-4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v4m15-6.5a3.775 3.775 0 0 1-4 3.5 3.775 3.775 0 0 1-4-3.5c0-1.652 2.921-8.513 3.771-10.474a.249.249 0 0 1 .458 0C20.579 8.487 23.5 15.348 23.5 17Zm-4-3.562V23.5M18 17l1.5 1.5m-19-1 6.146-6.146a.5.5 0 0 1 .708 0L13.5 17.5M6.485 3.2A2 2 0 1 1 8 6.5H3.5a3 3 0 1 1 2.985-3.3'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { HouseIcon };
