import { useCallback, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BorderButton } from '../../../../../common/components/ui/buttons';
import { useAppDispatch } from '../../../../../common/hooks';
import { StepStateArray } from '../../../../interface';
import { IValuationForm } from '../../../interface';
import {
  nextStepAction,
  prevStepAction,
  updateFormStateAction,
} from '../../../redux/valuationWizardSlice';
import { NextButton } from '../../../../components/common/next-button';

interface IProps {
  children: JSX.Element | JSX.Element[];
  stepState?: StepStateArray;
  buttonLabel: string;
  hasBackButton?: boolean;
  backButtonLabel?: string;
  nextButtonCallback?: () => void;
  submitForm?: boolean;
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div<{ direction?: string }>`
  display: flex;
  flex-direction: ${(props) => props.direction ?? 'row'};
  &:last-of-type {
    margin-top: auto;
    justify-content: flex-start;
    > *:last-of-type {
      margin-left: auto;
    }
  }
`;

const Step = ({
  children,
  stepState,
  buttonLabel,
  hasBackButton,
  backButtonLabel,
  submitForm,
  nextButtonCallback,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const switchToNextStep = useCallback(
    (e?: MouseEvent, formState?: Partial<IValuationForm>) => {
      if (submitForm) {
        return;
      }
      e?.preventDefault();

      dispatch(updateFormStateAction(formState));
      if (nextButtonCallback) {
        nextButtonCallback();
        return;
      }
      dispatch(nextStepAction());
    },
    [dispatch, submitForm, nextButtonCallback]
  );

  const switchToPrevStep = useCallback(
    (e?: MouseEvent) => {
      e?.preventDefault();
      dispatch(prevStepAction());
    },
    [dispatch]
  );

  return (
    <Container>
      {children}
      <Row>
        {hasBackButton && backButtonLabel && (
          <BorderButton label={t(backButtonLabel)} onClick={switchToPrevStep} />
        )}
        <NextButton watchedKeys={[]} label={t(buttonLabel)} />
      </Row>
    </Container>
  );
};

export { Step };
