import styled, { css } from 'styled-components';
import { device } from '../../../../../../../style/theme';
import { IVerificationPin, StatusType } from '../../../types';

export const inputError = css`
  border-color: ${({ theme }) => theme.red};
  background-color: ${({ theme }) => theme.paleRed};
  color: ${({ theme }) => theme.blue};
  box-shadow: none;
`;

export const inputHover = css`
  background-color: ${({ theme }) => theme.greyBackground};
  box-shadow: none;
`;

export const inputFocus = css`
  background-color: ${({ theme }) => theme.white};
  box-shadow: none;
  border-color: ${({ theme }) => theme.borderFocus};
`;

export const StyledInput = styled.input<IVerificationPin>`
  background-color: ${({ theme }) => theme.greyBackground};
  color: ${({ theme }) => theme.blue};
  -webkit-appearance: none; /* box-shadow Iphone */
  box-shadow: none;
  text-transform: uppercase;
  padding: 12px 10px;
  text-align: center;
  border: 1px solid transparent;
  outline: 0;
  font-weight: bold;
  transition: all 0.3s ease;
  border-radius: 3px;
  width: 48px;
  height: 64px;
  margin: 0 6px;
  font-size: 24px;

  @media ${device.tablet} {
    width: 36px;
    height: 52px;
  }

  &:hover {
    ${({ status }) => status === StatusType.process && inputHover}
  }

  &:focus {
    ${({ status }) => status === StatusType.process && inputFocus}
  }

  ${({ status }) => status === StatusType.error && inputError}
`;
