import { MouseEvent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  InputOutsideTopLabel,
  InputOutsideTopLabelWithSelect,
} from '../../../../../common/components/form';
import { SelectOutsideTopLabel } from '../../../../../common/components/form/select/select-outside-top-label';
import {
  phoneNumberPrefixesOptions,
  salutationOptions,
  titleOptions,
} from '../../../constants/options';
import { useCanContinue } from '../../../hooks/useCanContinue';
import { useValidate } from '../../../hooks/useValidate';
import { addAnother } from '../../../redux/purchaseIntendSlice';
import { AddAnotherButton, Row } from '../../common/form-utils';

const PhysicalFirstStep = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setValue, getValues } = useFormContext();
  const canContinue = useCanContinue();
  const validate = useValidate();

  const [salutation, title] = useWatch({ name: ['salutation', 'title'] });

  const addAnotherHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!validate()) return;

    setValue('otherPersons', [
      ...getValues('otherPersons'),
      {
        salutation: salutationOptions[0].value,
        title: titleOptions[2].value,
      },
    ]);
    dispatch(addAnother());
  };

  return (
    <div>
      <Row>
        <SelectOutsideTopLabel
          label={t('purchase-intent.form.salutation')}
          name={'salutation'}
          selectedOption={
            salutationOptions.find((s) => s.value === salutation)?.label || ''
          }
          options={salutationOptions}
          defaultValue={salutationOptions[0].value}
        />
        <SelectOutsideTopLabel
          label={t('purchase-intent.form.title')}
          name={'title'}
          selectedOption={
            titleOptions.find((o) => o.value === title)?.label || ''
          }
          options={titleOptions}
          defaultValue={titleOptions[2].value}
        />
      </Row>
      <Row>
        <InputOutsideTopLabel
          name={'name'}
          type={'text'}
          label={t('purchase-intent.form.name')}
          placeholder={t('purchase-intent.form.name')}
          rules={{}}
        />
        <InputOutsideTopLabel
          name={'surname'}
          type={'text'}
          label={t('purchase-intent.form.surname')}
          placeholder={t('purchase-intent.form.surname')}
          rules={{}}
        />
      </Row>
      <Row>
        <InputOutsideTopLabel
          name={'postalCode'}
          type={'text'}
          label={t('purchase-intent.form.postalCode')}
          placeholder={t('purchase-intent.form.postalCode')}
          rules={{}}
        />
        <InputOutsideTopLabel
          name={'city'}
          type={'text'}
          label={t('purchase-intent.form.city')}
          placeholder={t('purchase-intent.form.city')}
          rules={{}}
        />
      </Row>
      <Row marginBottom={16}>
        <InputOutsideTopLabel
          type={'text'}
          name={'email'}
          label={t('purchase-intent.form.email')}
          placeholder={t('purchase-intent.form.email')}
          rules={{}}
        />
        <InputOutsideTopLabelWithSelect
          type={'number'}
          name={'phone'}
          label={t('purchase-intent.form.phone')}
          placeholder={t('purchase-intent.form.phone')}
          rules={{
            valueAsNumber: true,
          }}
          selectWidth="87px"
          hasSelect
          selectOptions={phoneNumberPrefixesOptions}
          selectValue={phoneNumberPrefixesOptions[0]}
          selectName={'phonePrefix'}
        />
      </Row>
      <AddAnotherButton disabled={!canContinue} onClick={addAnotherHandler}>
        {t('purchase-intent.form.addAnotherPerson')}
      </AddAnotherButton>
    </div>
  );
};

export default PhysicalFirstStep;
