import { ValuationType } from '../../../../../../../generated';
import { MainButton } from '../../../../../../common/components/ui/buttons';
import { useGetEvaluationWidgetVersion } from '../../../../../../forms/form-property-valuation-wizard-v2/hooks/useGetWidgetVersion';
import {
  Container,
  Title,
  Description,
  ContentBox,
  CTAButtonWrapper,
  SlideIconBox,
  SlideIcon,
} from './primary-slide-styles';

interface IProps {
  title: string;
  description: string;
  buttonLabel: string;
  handleEvaluationWizardOpen?: (type: ValuationType) => void;
  ctaHandler?: () => void;
  icon: string;
}

const PrimarySlide = ({
  title,
  description,
  buttonLabel,
  handleEvaluationWizardOpen,
  ctaHandler,
  icon,
}: IProps): JSX.Element => {
  const { isRedesignVersion } = useGetEvaluationWidgetVersion();

  const onClick = () => {
    if (handleEvaluationWizardOpen) {
      if (!isRedesignVersion) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      handleEvaluationWizardOpen(ValuationType.Online);
    } else if (ctaHandler) {
      ctaHandler();
    }
  };

  return (
    <Container>
      <SlideIconBox>
        <SlideIcon src={icon} />
      </SlideIconBox>
      <ContentBox>
        <Title data-swiper-parallax="0" content={title} />
        <Description data-swiper-parallax="0">{description}</Description>
        <CTAButtonWrapper>
          <MainButton
            style={{ textTransform: 'uppercase' }}
            data-swiper-parallax="0"
            label={buttonLabel}
            onClick={onClick}
          />
        </CTAButtonWrapper>
      </ContentBox>
    </Container>
  );
};

export { PrimarySlide };
