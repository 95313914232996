import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const Container = styled.div`
  position: relative;
  padding: 0 32px;
  margin: 0 0 88px 0 !important;
  background-color: ${(props) => props.theme.white};
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media ${device.tablet} {
    margin: 0 !important;
  }
  @media ${device.mobile} {
    flex-direction: column;
    padding: 24px 16px 16px;
    button {
      min-width: 256px;
    }
  }
`;

export const BoxImage = styled.div`
  padding: 0 8px;
`;

export const StyledImage = styled.img`
  max-width: 120px;
  max-height: 156px;
`;
