import { FC, memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { ReactComponent as Filter } from 'assets/streamline-light/interface-essential/filter/filter-1.svg';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FormProvider, useForm } from 'react-hook-form';
import {
  ApplyFilterButton,
  Button,
  ClearFilterButton,
  Form,
  Container,
  CheckboxWrapper,
  Title,
} from './filter-button-styles';
import { ReleaseStatusFilter } from '../../../interfaces';
import { OutsideClickHandler } from '../../../../common/components/outside-click-handler';
import {
  toggleFilterOpen,
  toggleShouldResetFilter,
} from '../../../redux/brokerViewSlice';
import { useAppSelector } from '../../../../common/hooks';
import { Checkbox } from '../../../../common/components/form';
import { ReleaseStatus } from '../../../../../generated';

interface IProps {
  onSubmit: (formData: ReleaseStatusFilter) => void;
  onReset: () => void;
  isActive?: boolean;
  isSimulation?: boolean;
}

const FilterButtonBase: FC<IProps> = ({
  onSubmit,
  onReset,
  isActive = false,
  isSimulation,
}) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();

  const isFilterOpen = useAppSelector((state) => state.brokerView.isFilterOpen);

  const shouldResetFilter = useAppSelector(
    (state) => state.brokerView.shouldResetFilter
  );

  const methods = useForm<ReleaseStatusFilter>({
    mode: 'onSubmit',
    defaultValues: {
      [ReleaseStatus.NotReleased]: false,
      [ReleaseStatus.ReleasedForSomeUsers]: false,
      [ReleaseStatus.ReleasedForAll]: false,
    },
  });

  const handleOutsideClick = useCallback(() => {
    dispatch(toggleFilterOpen(false));
  }, [dispatch]);

  const resetFilters = useCallback(() => {
    onReset();
    methods.reset();
    dispatch(toggleFilterOpen(false));
  }, [dispatch, methods, onReset]);

  const isApplyFilterBtnDisabled = useMemo(
    () => !methods.formState.isDirty,
    [methods.formState.isDirty]
  );

  useEffect(() => {
    if (shouldResetFilter) {
      resetFilters();
      dispatch(toggleShouldResetFilter(false));
    }
  }, [dispatch, resetFilters, shouldResetFilter]);

  return (
    <Container>
      <Button
        label={''}
        onClick={() => dispatch(toggleFilterOpen(true))}
        color={theme.blue}
        icon={Filter}
        iconStyle={{
          width: '18px',
          height: '18px',
          margin: 0,
          strokeWidth: '1.5px',
        }}
        borderColor={theme.blue}
        isActive={isActive}
      />
      <OutsideClickHandler
        display="block"
        onOutsideClick={handleOutsideClick}
        style={{ display: isFilterOpen ? 'unset' : 'none' }}
      >
        <FormProvider {...methods}>
          <Form
            isSimulation={isSimulation}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Title
              content={t(
                'buyer-portal.broker-view.expose-documents-page.access-manager.filter-by'
              )}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                margin: '12px 0',
              }}
            >
              <CheckboxWrapper>
                <Checkbox
                  name={ReleaseStatus.ReleasedForAll}
                  label={t(
                    'buyer-portal.broker-view.expose-documents-page.file.released-for-all'
                  )}
                  isWithoutErrors
                />
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox
                  name={ReleaseStatus.ReleasedForSomeUsers}
                  label={t(
                    'buyer-portal.broker-view.expose-documents-page.file.released-for-some-users'
                  )}
                  isWithoutErrors
                />
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox
                  name={ReleaseStatus.NotReleased}
                  label={t(
                    'buyer-portal.broker-view.expose-documents-page.file.not-released'
                  )}
                  isWithoutErrors
                />
              </CheckboxWrapper>
            </div>

            <ClearFilterButton
              label={t('marketing-dashboard.filter.clear')}
              onClick={resetFilters}
              borderColor={theme.blue}
              color={theme.blue}
              type={'button'}
              style={{ width: '100%' }}
            />
            <ApplyFilterButton
              label={t('marketing-dashboard.filter.apply')}
              type={'submit'}
              isDisabled={isApplyFilterBtnDisabled}
              style={{ width: '100%' }}
            />
          </Form>
        </FormProvider>
      </OutsideClickHandler>
    </Container>
  );
};

const FilterButton = memo(FilterButtonBase);
export { FilterButton };
