import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { Headline2 } from '../../../../common/components/ui/typography';
import Icon from '../../../../common/components/ui/icon';

export const Container = styled.div<{ isMobileRedesign?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 152px 96px 48px 152px;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  @media ${device.tabletSlider} {
    padding: 108px 16px 16px 16px;
    align-items: center;

    ${(props) => props.isMobileRedesign && 'padding: 16px;'}
  }
`;

export const SlideIconBox = styled.div<{ isMobileRedesign?: boolean }>`
  display: none;

  @media ${device.tabletSlider} {
    ${(props) =>
      props.isMobileRedesign &&
      `
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      background: #fff;
      border-radius: 100%;
      margin-bottom: 16px;
    `}
  }
`;

export const SlideIcon = styled.img<{
  isMobileRedesign?: boolean;
  reduceToken?: boolean;
}>`
  display: none;

  @media ${device.tabletSlider} {
    ${(props) =>
      props.isMobileRedesign &&
      `
      display: block;
      width: ${props.reduceToken ? '50%' : '100%'};
      height: auto;
    `}
  }
`;

export const Title = styled(Headline2)<{ isMobileRedesign?: boolean }>`
  margin: 0 0 16px 0;
  color: #ffffff;

  @media ${device.tabletSlider} {
    ${(props) =>
      props.isMobileRedesign &&
      `
      text-align: center;
    `}
  }
`;

export const Description = styled.p`
  margin: 0 0 16px 0;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: #ffffff;
`;

export const LinkIconWrapper = styled(Icon)`
  margin-right: 8px;
`;
