import styled, { css } from 'styled-components';

export const Container = styled.div`
  .expandable-content {
    padding: 12px;
  }
  .expandable-content-title {
    opacity: 1;
    color: ${({ theme }) => theme.blue};
    font-family: Source Serif Pro, sans-serif;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 22px;
  }

  .expandable-content-wrapper {
    padding: 0;
  }
`;

export const Title = styled.h4`
  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Source Serif Pro, sans-serif;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 22px;
  margin: 24px 0 16px;
`;

export const ContainerValue = styled.div<{ isSingleValue?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.greyBackground};
  }
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.white};
  }

  & > div:first-child {
    opacity: 1;
    color: ${({ theme }) => theme.blue};
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 16px;
    ${({ isSingleValue }) =>
      isSingleValue
        ? ''
        : css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}

    margin-right: 8px;
  }

  & > div:last-child {
    display: flex;
    align-items: center;
    opacity: 1;
    color: ${({ theme }) => theme.blue};
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 16px;
    white-space: nowrap;
  }
`;
