import { CSSProperties } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { hexToRGB } from '../../../utils/hex-to-rgb';

export interface IInputTextArea {
  className?: string;
  id?: string;
  defaultValue?: string;
  name: string;
  label: string;
  height?: number;
  placeholder?: string;
  rules: RegisterOptions;
  isDisabled?: boolean;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  noTranslate?: boolean;
}

const Label = styled.label<{ $hasError: boolean }>`
  display: inline-block;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) =>
    props.$hasError
      ? hexToRGB(props.theme.red, 0.5)
      : hexToRGB(props.theme.blue, 0.5)};
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 0;
  margin-top: 8px;
  position: relative;
`;

const Error = styled.div`
  font-family: Roboto;
  font-size: 12px;
  //font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  //color: ${(props) => props.theme.red};
  margin: 4px 0 0;
  font-weight: bold;
  color: rgba(162, 87, 87, 0.5);
`;

const TextArea = styled.textarea<{ $hasError: boolean; height?: number }>`
  flex: 1;
  height: ${({ height }) => height ?? 40}px;
  padding: 12px;
  border-radius: 3px;
  background-color: ${(props) =>
    props.$hasError ? props.theme.paleRed : props.theme.greyBackground};
  outline: none;
  border: 1px solid transparent;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  resize: none;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Edge */
  ::-ms-reveal {
    display: none !important;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
  &::placeholder {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  }
  &:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.borderFocus};
    background-color: ${(props) => props.theme.greyBackground};
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.white};
    border-color: ${(props) => props.theme.borderFocus};
    color: ${(props) => props.theme.blue};
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
    }
  }
  &:disabled {
    opacity: 0.45;
    border: none;
    cursor: default;
    pointer-events: none;
  }
`;

const InputTextArea = ({
  id,
  className,
  name,
  label,
  defaultValue,
  placeholder,
  height,
  rules,
  isDisabled,
  style,
  labelStyle,
  noTranslate,
}: IInputTextArea): JSX.Element => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <Label htmlFor={id} $hasError={Boolean(errors[name])} style={labelStyle}>
        {noTranslate ? label : t(label)}
      </Label>

      <Container className={className}>
        <TextArea
          style={style}
          id={id}
          height={height}
          {...register(`${name}` as const, rules)}
          {...(defaultValue && { defaultValue })}
          placeholder={t(placeholder ?? '')}
          $hasError={Boolean(errors[name])}
          disabled={isDisabled}
        />
      </Container>
      {Boolean(errors[name]) && <Error>{t(errors[name].message)}</Error>}
    </div>
  );
};

export { InputTextArea };
