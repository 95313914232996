import { memo } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { changeViewport } from '../redux/mapSlice';

const Container = styled.div`
  > div {
    > button {
      svg {
        margin-top: 4px;
      }
    }
  }
`;

const GeoLocateControllerBase = () => {
  const dispatch = useAppDispatch();
  const property = useAppSelector((state) =>
    state.map.markers.find((marker) => !marker.category)
  );

  const onClick = () =>
    dispatch(
      changeViewport({
        longitute: property?.longitude,
        latitude: property?.latitude,
      })
    );

  return (
    <Container onClick={onClick} style={{ position: 'absolute' }}>
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
        <button
          className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
          >
            <path
              fill="#4A4A4A"
              fillRule="evenodd"
              d="M8.75 6.375c.86 0 1.595.306 2.207.918a3.01 3.01 0 01.918 2.207c0 .86-.306 1.595-.918 2.207a3.01 3.01 0 01-2.207.918c-.86 0-1.595-.306-2.207-.918A3.01 3.01 0 015.625 9.5c0-.86.306-1.595.918-2.207a3.01 3.01 0 012.207-.918zm7.46 2.305h1.29v1.64h-1.29c-.18 1.745-.897 3.242-2.148 4.492-1.25 1.25-2.747 1.967-4.492 2.15v1.288H7.93v-1.29c-1.745-.18-3.242-.897-4.492-2.148-1.25-1.25-1.967-2.747-2.15-4.492H0V8.68h1.29c.18-1.745.897-3.242 2.147-4.492 1.25-1.25 2.748-1.967 4.493-2.15V.75h1.64v1.29c1.745.18 3.242.897 4.492 2.147 1.25 1.25 1.967 2.748 2.15 4.493zm-7.46 6.64c1.615 0 2.988-.566 4.12-1.7 1.134-1.132 1.7-2.505 1.7-4.12 0-1.615-.566-2.988-1.7-4.12-1.132-1.134-2.505-1.7-4.12-1.7-1.615 0-2.988.566-4.12 1.7-1.134 1.132-1.7 2.505-1.7 4.12 0 1.615.566 2.988 1.7 4.12 1.132 1.134 2.505 1.7 4.12 1.7z"
            />
          </svg>
        </button>
      </div>
    </Container>
  );
};

const GeoLocateController = memo(GeoLocateControllerBase);

export { GeoLocateController };
