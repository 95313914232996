import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { PropsWithChildren, memo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Icon from '../ui/icon';
import { Modal } from './modal';
import { CloseIconContainer, MessageContainer } from './warning-modal-styles';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  text: string;
}

const WarningModalBase: React.FC<PropsWithChildren<IProps>> = ({
  isOpen,
  onClose,
  text,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        wrapperStyles={{ position: 'relative' }}
      >
        <MessageContainer>{text}</MessageContainer>

        <CloseIconContainer onClick={onClose}>
          <Icon
            icon={Close}
            width={16}
            height={16}
            color={themeContext.black}
          />
        </CloseIconContainer>
      </Modal>
    </>
  );
};

const WarningModal = memo(WarningModalBase);

export { WarningModal };
