import styled from 'styled-components';
import { Checkbox } from '../../../../common/components/form';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;

  & i svg {
    max-width: 62px;
    max-height: 42px;
  }
`;

export const CheckboxItem = styled(Checkbox)`
  margin-right: 12px;
`;
