import { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Lock } from 'assets/streamline-light/interface-essential/lock-unlock/lock-2.svg';
import { ThemeContext } from 'styled-components';
import {
  ActionsWrapper,
  BookAppointmentBtn,
  Container,
  PreviewBtn,
  SendEmailBtn,
  Text,
  Title,
} from './dashboard-locked-message-styles';
import Icon from '../../../../common/components/ui/icon';

interface IProps {
  onBookAppointment?: () => void;
  onEmailBroker?: () => void;
  onShowPreview?: () => void;
}

const DashboardLockedMessageBase: FC<IProps> = ({
  onBookAppointment,
  onEmailBroker,
  onShowPreview,
}) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <Icon icon={Lock} width={40} height={40} />
      <Title content={t('marketing-dashboard-locked.message.title')} />
      <Text content={t('marketing-dashboard-locked.message.text')} />
      <ActionsWrapper>
        {onEmailBroker && (
          <SendEmailBtn
            label={t('marketing-dashboard-locked.message.email-broker.button')}
            onClick={onEmailBroker}
            backgroundColor={themeContext.logoBlue}
            borderColor={themeContext.white}
            color={themeContext.white}
          />
        )}
        {onBookAppointment && (
          <BookAppointmentBtn
            label={t(
              'marketing-dashboard-locked.message.book-appointment.button'
            )}
            onClick={onBookAppointment}
          />
        )}
        {onShowPreview && (
          <PreviewBtn
            label={t(
              'marketing-dashboard-locked.message.dashboard-preview.button'
            )}
            onClick={onShowPreview}
          />
        )}
      </ActionsWrapper>
    </Container>
  );
};

const DashboardLockedMessage = memo(DashboardLockedMessageBase);

export { DashboardLockedMessage };
