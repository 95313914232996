import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { SuggestedPropertyAttachment } from '../../../../generated';
import {
  CarouselItem,
  PictureCarousel,
} from '../expose-details-overlay/components/slider/full-screen-gallery/full-screen-gallery-styles';
import { Slider } from '../expose-details-overlay/components/slider/slider';
import { defaultTheme } from '../../../../style/theme';

interface IProps {
  activeSlide: number;
  images: ({ __typename?: 'SuggestedPropertyAttachment' | undefined } & Pick<
    SuggestedPropertyAttachment,
    'name' | 'alt'
  >)[];
  setActiveSlide: (index: number) => unknown;
}

const PropertyGallery = forwardRef<unknown, IProps>(
  ({ activeSlide, images, setActiveSlide }, ref) => {
    const dispatch = useDispatch();

    const toSlide = (index: number) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref.current?.swiper.slideTo(index);
      dispatch(setActiveSlide(index));
    };

    return (
      <>
        <Slider
          ref={ref}
          activeSlide={activeSlide}
          images={images}
          setActiveSlide={setActiveSlide}
          height={'272px'}
        />
        {images?.length > 0 && (
          <PictureCarousel>
            {images.map((item, i) => (
              <CarouselItem
                onClick={() => toSlide(i)}
                key={item.name}
                height="auto"
                src={`https://erp.von-poll.com/import/images/${item.name}`}
                alt={item.alt}
                isActive={i === activeSlide}
                activeColor={defaultTheme.blue}
              />
            ))}
          </PictureCarousel>
        )}
      </>
    );
  }
);

export { PropertyGallery };
