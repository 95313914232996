import { FC, useEffect, useRef } from 'react';
import FallBackGalleryImg from '../../../../../../assets/images/property-not-online-gallery.png';
import {
  CarouselItem,
  PictureCarousel,
} from './financing-full-screen-gallery-styles';
import { VonPollPropertyImage } from '../../../../../../generated';
import { FinancingGallerySlider } from '../../financing-gallery-slider/financing-gallery-slider';

interface IProps {
  activeSlide: number;
  images: VonPollPropertyImage[];
  setActiveSlide: (index: number) => unknown;
}

const unshiftedMapSlide = (index: number) => {
  return Math.max(0, index - 1);
};

const FinancingFullScreenGallery: FC<IProps> = ({
  activeSlide,
  images,
  setActiveSlide,
}) => {
  const gallerySliderRef = useRef<HTMLImageElement | null>(null);
  const pictureCarouselRef = useRef<HTMLImageElement | null>(null);

  const changeSwiperSlide = (index: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gallerySliderRef.current?.swiper?.slideTo(index);
  };

  const toSlide = (index: number) => {
    changeSwiperSlide(unshiftedMapSlide(index));
    setActiveSlide(index);
  };

  const onClickNavButton = (isPrev: boolean) => () => {
    if (isPrev) {
      if (activeSlide > 0) {
        setActiveSlide(activeSlide - 1);
      }
    } else if (images.length > activeSlide) {
      setActiveSlide(Math.max(activeSlide + 1, 2));
    }
  };

  useEffect(() => {
    changeSwiperSlide(unshiftedMapSlide(activeSlide));
    pictureCarouselRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [activeSlide]);

  return (
    <>
      <FinancingGallerySlider
        isWithMap={false}
        activeSlide={unshiftedMapSlide(activeSlide)}
        ref={gallerySliderRef}
        images={images}
        onClickNavButton={onClickNavButton}
        isGallery
        isPortrait
      />
      {images?.length > 0 && (
        <PictureCarousel>
          {images.map((item, i) => (
            <CarouselItem
              onClick={() => toSlide(i + 1)}
              key={item.id}
              onError={({ currentTarget }) => {
                // eslint-disable-next-line unicorn/prefer-add-event-listener
                currentTarget.onerror = null;
                currentTarget.src = FallBackGalleryImg;
              }}
              src={`https://erp.von-poll.com/import/images/${item.link}`}
              alt={item.link}
              isActive={i === unshiftedMapSlide(activeSlide)}
              ref={
                i === unshiftedMapSlide(activeSlide) ? pictureCarouselRef : null
              }
            />
          ))}
        </PictureCarousel>
      )}
    </>
  );
};

export { FinancingFullScreenGallery };
