import { ReactComponent as Building } from 'assets/streamline-light/building-construction/buildings/buildings-2.svg';
import { ReactComponent as MultiFamilyHouse } from 'assets/streamline-light/building-construction/houses/house-apartment.svg';
import { ReactComponent as House } from 'assets/streamline-light/building-construction/houses/house-3.svg';

import { PropertyCode } from '../../../generated';

type PropertyFields = {
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
};

export const extractPropertyTypeLabelAndIcon = (
  code?: PropertyCode | null
): PropertyFields => {
  if (code === PropertyCode.Apartment) {
    return { label: 'property-type.apartment', icon: Building };
  }
  if (code === PropertyCode.House) {
    return {
      label: 'property-type.house',
      icon: House,
    };
  }
  if (code === PropertyCode.Plot) {
    return {
      label: 'property-type.plot',
      icon: House,
    };
  }
  if (code === PropertyCode.MultiFamilyHouse) {
    return {
      label: 'property-type.multi-family-house',
      icon: MultiFamilyHouse,
    };
  }
  return {
    label: '',
    icon: House,
  };
};

export const getPropertyCodeFromType = (propertyType: string): PropertyCode => {
  switch (propertyType) {
    case 'wohnung':
      return PropertyCode.Apartment;
    case 'haus':
      return PropertyCode.House;
    case 'zinshaus_renditeobjekt':
      return PropertyCode.MultiFamilyHouse;
    case 'grundstueck':
      return PropertyCode.Plot;
    default:
      return PropertyCode.Apartment;
  }
};
