import { FC } from 'react';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    marginTop: '24px',
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  contentContainer: {
    width: '508px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: '16px',
    borderRadius: '3px',
    border: '1px solid rgba(217, 217, 217, 1)',
    opacity: 1,
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  title: {
    marginBottom: '12px',
    opacity: 1,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'SourceSerifPro',
    fontSize: '20px',
    fontWeight: 'semibold',
    textAlign: 'left',
    lineHeight: 1.2,
  },
  chartContainer: {
    width: '450px',
    height: '141px',
  },
  chartImage: {
    width: '100%',
    height: 'auto',
    maxHeight: '100%',
  },
});

interface IProps {
  image: string;
}

const AdvertisementViews: FC<IProps> = ({ image }) => {
  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <Text style={styles.title}>Inserataufrufe</Text>
        <View style={styles.chartContainer}>
          <Image style={styles.chartImage} src={image} />
        </View>
      </View>
    </View>
  );
};

export { AdvertisementViews };
