import { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { device } from 'style/theme';

import { ReactComponent as Calendar } from 'assets/streamline-light/interface-essential/date-calendar/calendar.svg';
import { ReactComponent as Hyperlink3 } from 'assets/streamline-light/interface-essential/link-unlink/hyperlink-3.svg';
import { ReactComponent as RealEstateDimensionsBlock } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as RealEstateDimensionsPlan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-plan.svg';

import Icon from '../../../common/components/ui/icon';
import { useEURtoCHF } from '../../../common/utils/convertCHF';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import useGetLocalization from '../../../localization/get-localization';

interface IProps {
  title: string;
  isActive: boolean;
  daysOnTheMarket: number;
  price: number;
  numberOfRooms: number;
  livingArea: number;
  currency: string;
  imageSrc: string;
  startDate: string;
  url: string;
}

const Container = styled.div`
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  @media ${device.tablet} {
    max-width: none;
  }
`;

const ImageWrapper = styled.div`
  height: 0;
  padding-top: calc(152 / 306 * 100%);
  position: relative;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  flex: 1;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
`;

const Title = styled.h4`
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

const Row = styled.div<{
  justifyContent?: string;
  margin?: string;
  alignItems?: string;
}>`
  display: flex;
  align-self: stretch;
  .icon {
    min-width: 24px;
    min-height: 24px;
  }
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};

  ${({ margin }) => margin && `margin: ${margin}`};

  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
`;

const Tag = styled.div<{ daysOnTheMarket?: boolean; isActive?: boolean }>`
  padding: 2px 8px;
  margin-right: 4px;
  border-radius: 3px;
  border: solid 1px
    ${(props) =>
      props.daysOnTheMarket
        ? props.theme.ctaDisabledBackground
        : props.isActive
        ? props.theme.tagGreenBorder
        : props.theme.lightBlue};
  background-color: ${(props) =>
    props.daysOnTheMarket
      ? '#fff'
      : props.isActive
      ? props.theme.paleGreen
      : props.theme.greyBackground};
  color: ${(props) =>
    props.daysOnTheMarket
      ? hexToRGB(props.theme.blue, 0.5)
      : props.isActive
      ? props.theme.green
      : props.theme.blue};

  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
`;

const PriceContainer = styled.div`
  padding: 4px 8px;
  border-radius: 3px;
  background-color: ${(props) => hexToRGB(props.theme.blue, 0.1)};
  margin: 12px 0;
  white-space: nowrap;
`;

const Price = styled.span`
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  margin-right: 8px;
`;

const SquareMeterPrice = styled(Price)`
  font-weight: normal;
  sup {
    font-size: 8px;
  }
`;

const IconContainer = styled.div<{ alignAtEnd?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
  ${({ alignAtEnd }) =>
    alignAtEnd &&
    `
    > div {
      justify-content: flex-end
    }
  `}
`;

const IconDigit = styled.div`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${(props) => props.theme.blue};
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  sup {
    font-size: 8px;
  }
`;

const IconLabel = styled.div`
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 1px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.6)};
`;

const StyledLink = styled.a`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const SimilarPropertyBase = ({
  title,
  isActive,
  daysOnTheMarket,
  price,
  numberOfRooms,
  livingArea,
  currency,
  imageSrc,
  startDate,
  url,
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const [priceState, setPriceState] = useState({
    price,
    currency,
    pricePerSqm: 0,
  });
  const lang = useGetLocalization();
  const chfPrice = useEURtoCHF(price || 0);

  useEffect(() => {
    (() => {
      setPriceState({
        price,
        currency,
        pricePerSqm: Number.parseFloat(
          (price / livingArea).toFixed(2).toString()
        ),
      });

      if (lang === 'de-ch' && price) {
        setPriceState({
          price: Math.trunc(chfPrice),
          currency: ' CHF',
          pricePerSqm: Number.parseFloat(
            (chfPrice / livingArea).toFixed(2).toString()
          ),
        });
      }
    })();
  }, [price, currency, lang, livingArea, chfPrice]);

  return (
    <Container>
      <ImageWrapper>
        <Image src={imageSrc} />
      </ImageWrapper>
      <Content>
        <Title>{title}</Title>
        <Row>
          <Tag isActive={isActive}>{isActive ? 'AKTIV' : 'HISTORISCH'}</Tag>
          <Tag daysOnTheMarket>
            {daysOnTheMarket}{' '}
            {daysOnTheMarket > 1
              ? t('chart.tooltip.days').toUpperCase()
              : t('chart.tooltip.day').toUpperCase()}{' '}
            AUF DEM MARKT
          </Tag>
        </Row>
        <PriceContainer>
          <Price>
            {priceState.price.toLocaleString('de-DE')} {priceState.currency}
          </Price>
          <SquareMeterPrice>
            ({priceState.pricePerSqm.toLocaleString('de-DE')}{' '}
            {priceState.currency}/m<sup>2</sup>)
          </SquareMeterPrice>
        </PriceContainer>
        <Row justifyContent="space-between" margin={'0 0 12px 0'}>
          <IconContainer>
            <Row margin="0 0 4px 0" alignItems="center">
              <Icon icon={RealEstateDimensionsPlan} color={themeContext.blue} />
              <IconDigit>{numberOfRooms}</IconDigit>
            </Row>
            <Row>
              <IconLabel>{t('property.offers.card.room.label')}</IconLabel>
            </Row>
          </IconContainer>
          <IconContainer alignAtEnd>
            <Row margin="0 0 4px 0" alignItems="center">
              <Icon
                icon={RealEstateDimensionsBlock}
                color={themeContext.blue}
              />
              <IconDigit>
                {livingArea} m<sup>2</sup>
              </IconDigit>
            </Row>
            <Row>
              <IconLabel>
                {t('property.offers.card.net-living-space.label')}
              </IconLabel>
            </Row>
          </IconContainer>
        </Row>
        <Row justifyContent="space-between">
          <IconContainer>
            <Row margin="0 0 4px 0" alignItems="center">
              <Icon icon={Calendar} color={themeContext.blue} />
              <IconDigit>{new Date(startDate).toLocaleDateString()}</IconDigit>
            </Row>
            <Row>
              <IconLabel>{t('property.offers.card.date.label')}</IconLabel>
            </Row>
          </IconContainer>
          <IconContainer alignAtEnd>
            <Row margin="0 0 4px 0" alignItems="center">
              <Icon icon={Hyperlink3} color={themeContext.blue} />
              <StyledLink href={url} target="_blank">
                <IconDigit>{t('property.offers.card.source.label')}</IconDigit>
              </StyledLink>
            </Row>
          </IconContainer>
        </Row>
      </Content>
    </Container>
  );
};

const SimilarProperty = memo(SimilarPropertyBase);

export { SimilarProperty };
