import { SEARCH_PROFILE_FORM_KEYS } from '../../../../../constants';

export const fields = [
  // SEARCH_PROFILE_FORM_KEYS.PURCHASE_PRICE_MIN,
  SEARCH_PROFILE_FORM_KEYS.PURCHASE_PRICE_MAX,
  SEARCH_PROFILE_FORM_KEYS.LIVING_AREA_MIN,
  // SEARCH_PROFILE_FORM_KEYS.LIVING_AREA_MAX,
  SEARCH_PROFILE_FORM_KEYS.LAND_AREA_MIN,
  // SEARCH_PROFILE_FORM_KEYS.LAND_AREA_MAX,
  SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_ROOMS_MIN,
  // SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_ROOMS_MAX,
  SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_BATHROOMS_MIN,
  // SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_BATHROOMS_MAX,
];
