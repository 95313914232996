import { FC, useState } from 'react';

import { UserState } from '../../../../../../../generated';
import { OnlineVerifyPhoneContainer } from './verify-phone-container/online-verify-phone-container';
import { useOnlineRegister } from '../../../../hooks/callback-hooks/online-valuation/useOnlineRegister';
import { RegisterContainer } from '../../../../../components/fullscreen-modal/auth-container/register-container/register-container';

const OnlineRegisterContainer: FC = () => {
  const [errors, setErrors] = useState<null | unknown>(null);

  const { isConfirmEmailSent, userState, onRegisterSubmit, onOpenLogin } =
    useOnlineRegister({
      reduxSliceName: 'valuationWizardV2',
      setErrors,
    });

  return (
    <RegisterContainer
      onRegisterSubmit={onRegisterSubmit}
      errors={errors}
      isConfirmEmailSent={isConfirmEmailSent}
      onOpenLogin={onOpenLogin}
      buttonPosition={'relative'}
    >
      {userState === UserState.VerifyPhone && (
        <OnlineVerifyPhoneContainer setErrors={setErrors} />
      )}
    </RegisterContainer>
  );
};

export { OnlineRegisterContainer };
