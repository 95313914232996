import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowDown1 } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import styled from 'styled-components';
import { useDidUpdateEffect } from '../../hooks/useDidUpdateEffect';

const ANIMATION_DURATION = 300;

interface IExpandableContent {
  className?: string;
  style?: CSSProperties;
  icon?: ReactNode;
  title: string;
  initHeight?: number;
  expanded?: boolean;
  setExpanded?: () => void;
  children: ReactNode;
}

const Wrapper = styled.div<{ height: string; isHidden: boolean }>`
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.ctaDisabledBackground};
  transition: ${ANIMATION_DURATION}ms ease;
  position: relative;
  height: ${({ height }) => height};
  overflow: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`;

export const InnerWrapper = styled.div<{ isHidden: boolean }>`
  position: relative;
  overflow: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
  background: ${({ theme }) => theme.white};
  position: relative;
  z-index: 2;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

export const Title = styled.h3`
  font-family: Source Serif Pro, sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  margin-right: 20px;
`;

export const ContentWrapper = styled.div<{
  isOpen: boolean;
  $position: 'absolute' | 'relative';
}>`
  padding: 0 16px 16px;
  display: block;
  transition: 0.3s ease;
  transform: translateY(${({ isOpen }) => (isOpen ? '0%' : '-100%')});
  position: ${({ $position }) => $position};
  margin: 0;

  &:has(.select-opened) {
    z-index: 3;
  }
`;

const ArrowDownIcon = styled(ArrowDown1)<{ $isOpen: boolean }>`
  color: ${({ theme }) => theme.blue};
  width: 16px;
  height: auto;
  transition: 0.12s ease;
  transform: rotate(${({ $isOpen }) => ($isOpen ? '180deg' : '0deg')});
`;

let timeoutId: NodeJS.Timeout | undefined;

const ExpandableContent = ({
  className,
  style,
  icon,
  title,
  initHeight,
  expanded = false,
  setExpanded,
  children,
}: IExpandableContent): JSX.Element => {
  const [isOpen, setIsOpen] = useState(expanded);
  const [isHidden, setHidden] = useState(!expanded);
  const contentRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const toggleTimeout = useRef<NodeJS.Timeout>(0);
  const [height, setHeight] = useState(0);
  const toggleOpen = (isHide?: boolean) => {
    const event = new CustomEvent('toggleOpenExpandableContent');
    clearTimeout(toggleTimeout.current);
    if (!isOpen && !isHide) {
      toggleTimeout.current = setTimeout(() => {
        if (setExpanded) {
          setExpanded();
        }
        setHidden(false);
      }, ANIMATION_DURATION);
    } else {
      setHidden(true);
    }
    // fields with errors logic
    setHeight(
      contentRef.current?.offsetHeight
        ? contentRef.current?.offsetHeight + (initHeight || 64)
        : initHeight || 64
    );
    setTimeout(() => {
      setHeight(0);
      document.dispatchEvent(event);
    }, ANIMATION_DURATION);
    setTimeout(() => {
      setIsOpen(!isHide ? !isOpen : false);
    }, 0);
  };
  const [contentPosition, setContentPosition] = useState<
    'absolute' | 'relative'
  >(expanded === true ? 'relative' : 'absolute');

  useDidUpdateEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (isOpen) {
      setContentPosition('relative');
    } else {
      timeoutId = setTimeout(() => {
        setContentPosition('absolute');
      }, ANIMATION_DURATION);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!expanded) {
      toggleOpen(true);
    }
  }, [expanded]);

  return (
    <Wrapper
      isHidden={isHidden}
      height={
        isOpen
          ? height
            ? `${height}px`
            : 'auto'
          : initHeight
          ? `${initHeight}px`
          : '64px'
      }
      className={className}
      style={style}
    >
      <InnerWrapper isHidden={isHidden}>
        <TitleWrapper
          className="expandable-content"
          onClick={() => toggleOpen()}
        >
          {icon && icon}
          <Title className="expandable-content-title">{title}</Title>

          <ArrowDownIcon $isOpen={isOpen} />
        </TitleWrapper>

        <ContentWrapper
          className={`expandable-content-wrapper${
            isOpen ? ' expandable-content-opened' : ''
          }`}
          isOpen={isOpen}
          $position={contentPosition}
          ref={contentRef}
        >
          {children}
        </ContentWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

export { ExpandableContent };
