import styled from 'styled-components';
import {
  Headline2,
  ParagraphText,
  ParagraphTitle,
} from '../../../../common/components/ui/typography';
import { device } from '../../../../../style/theme';
import { SecondaryButton } from '../../../../common/components/ui/buttons';

export const Container = styled.div`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  background-color: ${(props) => props.theme.white};

  @media ${device.tablet} {
    padding: 16px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const FilterWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: end;
  gap: 8px;
  width: 100%;
`;

export const Title = styled(Headline2)`
  margin-right: 16px;
`;

export const Description = styled(ParagraphText)`
  margin-bottom: 16px;
`;

export const BoldText = styled(Description)`
  font-weight: bold;
`;

export const CollapseButton = styled(SecondaryButton)`
  width: 40px;
  height: 40px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Info = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;

  .tooltip {
    opacity: 1 !important;
    padding: 0;

    @media ${device.mobile} {
      left: 24px !important;
    }

    div {
      background-color: ${(props) => props.theme.white};
      border: none;
    }
  }

  .icon {
    > svg,
    > svg > path {
      stroke: ${(props) => props.theme.blue};
    }
  }
`;

export const TooltipContainer = styled.div`
  max-width: 500px;
  width: 100%;
  padding: 0;
  text-align: center;

  @media ${device.mobile} {
    max-width: 320px;
  }
`;

export const TooltipTitle = styled(ParagraphTitle)`
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
