import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { defaultTheme, device } from '../../../../style/theme';
import { hexToRGB } from '../../utils/hex-to-rgb';
import Icon from './icon';
import { BoxTitle } from './typography';
import { useAppSelector } from '../../hooks';

interface IProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  title: string;
  text: string;
  iconsCount: number;
  iconsFill: number;
  onClick: () => void;
  isSelected?: boolean;
}

const Container = styled.div<{ isSelected?: boolean; isiframe?: boolean }>`
  & {
    display: inline-flex;
    flex-direction: column;
    padding: 8px 12px;
    border-radius: 3px;
    border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
    background-color: #ffffff;
    min-width: 128px;
    min-height: 36px;

    @media ${({ isiframe }) => (isiframe ? '' : device.tablet)} {
      min-width: 100%;
      min-height: auto;
      margin: 4px 0;
    }

    flex: 1;
    box-shadow: inset 0px 0px 0px 1px transparent;
    transition: border-color 225ms ease, background-color 225ms ease,
      box-shadow 225ms ease, height 225ms ease;

    p {
      color: ${(props) => hexToRGB(props.theme.blue, 0.6)};
      transition: color 225ms ease;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
    }

    &:hover {
      border-color: ${(props) => props.theme.blue};
      background-color: ${(props) => props.theme.greyBackground};
      cursor: pointer;

      p {
        color: ${(props) => props.theme.blue};
      }
    }

    ${(props) =>
      props.isSelected &&
      `
      background-color: #fff;
      box-shadow: inset 0px 0px 0px 1px ${props.theme.blue};
      border-color: ${props.theme.blue};
       p {
        color: ${props.theme.blue};
      }
    `}

    & + & {
      margin-top: 8px;
    }
  }
`;

const IconsContainer = styled.div`
  display: flex;
  margin-right: 4px;

  i {
    margin-right: 4px;
  }

  svg,
  svg > path {
    stroke: ${(props) => props.theme.blue};
  }
`;

const Text = styled.p`
  text-align: left !important;
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.regularText}
  opacity: 0.6;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconRatingBox: FC<IProps> = ({
  icon,
  title,
  text,
  onClick,
  isSelected,
  iconsCount,
  iconsFill,
}) => {
  const icons = useMemo(() => {
    return Array.from({ length: iconsCount }, (_, idx) => (
      <Icon
        icon={icon}
        width={12}
        height={12}
        color={defaultTheme.blue}
        fillColor={idx < iconsFill ? defaultTheme.blue : ''}
        key={idx}
      />
    ));
  }, [icon, iconsCount, iconsFill]);

  const isIframe = useAppSelector((state) => state.auth.isIframe);

  return (
    <Container onClick={onClick} isSelected={isSelected} isiframe={isIframe}>
      <Wrapper>
        <IconsContainer>{icons}</IconsContainer>
        <BoxTitle content={title} />
      </Wrapper>
      {isSelected && <Text>{text}</Text>}
    </Container>
  );
};

export { IconRatingBox };
