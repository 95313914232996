import { useAppSelector } from '../../common/hooks';

type TReturn = {
  text: string;
  date: string;
  userFullName: string;
  avatarPlaceholder: string;
};

export const useParseComments = (comments: string[]): TReturn[] => {
  const user = useAppSelector((state) => state.auth.user);
  const avatarPlaceholder = `${user?.name?.[0] ?? ''}${
    user?.surname?.[0] ?? ''
  }`;

  const userFullName = `${user?.name ?? 'Kundenkommentar:'} ${
    user?.surname ?? ''
  }`;

  return comments.map((comment) => {
    const date = new Date(comment.split('Date: ')[1])
      ?.toLocaleString('en-GB')
      ?.slice(0, -3);
    const text = comment.split('Date: ')[0];

    return {
      text,
      date,
      userFullName,
      avatarPlaceholder,
    };
  });
};
