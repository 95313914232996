import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import {
  Headline2,
  PhoneLink,
} from '../../../../common/components/ui/typography';
import Icon from '../../../../common/components/ui/icon';

export const Box = styled.div`
  min-height: 300px;
`;

export const Container = styled.div`
  position: relative;
  padding: 32px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.white};
  display: flex;
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.09);
  @media ${device.mobile} {
    flex-direction: column;
    padding: 24px 16px 16px;
    box-shadow: none;
    border-top: 1px solid #d9d9d9;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;

  @media ${device.mobile} {
    margin-left: 0;
    margin-right: 0;
    align-items: center;
  }
`;

export const Title = styled(Headline2)`
  color: #fff;
  margin-bottom: 4px;
  @media ${device.mobile} {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const GeneralText = styled.div`
  margin: 4px 0 16px;
  opacity: 0.6;
  font-family: Roboto !important;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.5px;
  color: #fff;
`;

export const InfoRow = styled.div`
  width: 31%;
  margin-right: 32px;

  @media ${device.mobile} {
    width: auto;
    margin-right: 0;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const ContentRow = styled.div`
  position: absolute;
  right: 32px;
  width: 63%;
  padding: 24px;
  display: flex;
  background-color: ${(props) => props.theme.blue};
  border-radius: 3px;

  @media ${device.mobile} {
    position: static;
    width: auto;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const IconWrapper = styled(Icon)`
  margin-bottom: 16px;

  path {
    stroke: ${(props) => props.theme.blue} !important;
  }

  path:first-of-type {
    stroke: ${(props) => props.theme.ctaBlue} !important;
  }
`;

export const QuestionTitle = styled(Title)`
  color: ${(props) => props.theme.blue};
`;

export const Description = styled.a`
  font-size: 16px;
  font-family: Source Serif Pro, serif;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  cursor: pointer;
`;

export const PhoneLinkWrapper = styled(PhoneLink)`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
`;

export const ContactIconWrapper = styled(Icon)`
  margin-right: 8px;
`;

export const Contact = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #fff;
  @media ${device.mobile} {
    font-size: 10px;
    line-height: 1.6;
  }
`;

export const LowOpacitySpan = styled.span`
  opacity: 0.6;
  margin-right: 4px;
`;
