import { easeQuadOut } from 'd3-ease';
import { interpolate } from 'd3-interpolate';
import { memo } from 'react';
import { Animate } from 'react-move';
import styled from 'styled-components';
import { device } from '../../../../style/theme';
import { MAX_MOBILE_SIZE } from 'modules/common/constants';

interface IProps {
  imgSrc?: string;
  imgAlt?: string;
  children?: JSX.Element | JSX.Element[];
  backgroundColor?: string;
  mobileReverse?: boolean;
  isWithoutMargin?: boolean;
  isFlipHorizontal?: boolean;
}

const Container = styled.div<{
  backgroundColor?: string;
  mobileReverse?: boolean;
  isWithoutMargin?: boolean;
}>`
  position: relative;
  min-height: 640px;
  z-index: 3;
  margin-bottom: 88px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  @media screen and (max-width: ${MAX_MOBILE_SIZE}px) {
    min-height: unset;
    margin-bottom: 60px !important;
  }
  ${({ mobileReverse }) =>
    mobileReverse
      ? `
    @media ${device.tablet} {
      display: flex;
      flex-direction: column-reverse;
      padding: 24px 0;
      margin-bottom: 24px;
    }
    @media ${device.mobile} {
      padding: 0;
    }
  `
      : ''}
  ${({ isWithoutMargin }) =>
    isWithoutMargin &&
    `
    margin-bottom: 48px;

    @media ${device.tablet} {
        margin-bottom: 24px;
    }
  `}
`;

const StyledImage = styled.img<{
  isFlipHorizontal?: boolean;
  mobileReverse?: boolean;
}>`
  width: 100%;
  height: auto;
  display: block;
  ${({ isFlipHorizontal }) =>
    isFlipHorizontal && '-webkit-transform: scaleX(-1); transform: scaleX(-1);'}
  ${({ mobileReverse }) => mobileReverse && 'transform: scaleX(-1); order: 1;'}
`;

const HeroBase = ({
  imgSrc,
  imgAlt,
  children,
  backgroundColor,
  mobileReverse,
  isWithoutMargin,
  isFlipHorizontal,
}: IProps): JSX.Element => (
  <Container
    backgroundColor={backgroundColor}
    mobileReverse={mobileReverse}
    isWithoutMargin={isWithoutMargin}
  >
    <Animate
      show={!!imgSrc}
      start={{
        opacity: 0,
      }}
      enter={{
        opacity: [1],
        timing: { duration: 665, ease: easeQuadOut },
      }}
      update={{
        opacity: [1],
        timing: { duration: 665, ease: easeQuadOut },
      }}
      leave={{
        opacity: [0],
        timing: { duration: 665, ease: easeQuadOut },
      }}
      interpolation={(begValue, endValue) => interpolate(begValue, endValue)}
    >
      {({ opacity }) => (
        <StyledImage
          src={imgSrc}
          isFlipHorizontal={isFlipHorizontal}
          style={{ opacity }}
          alt={imgAlt}
          mobileReverse={mobileReverse}
        />
      )}
    </Animate>
    {children && children}
  </Container>
);

const Hero = memo(HeroBase);

export { Hero };
