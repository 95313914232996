import { FC, memo, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import useGetLocalization from '../../localization/get-localization';
import { ITimifyParams, TimifyPersonalisationIds } from '../interfaces';
import { useAppSelector } from '../../common/hooks';

type IProps = ITimifyParams & {
  setReservationSuccess: (value: boolean) => void;
};

enum Actions {
  Success = 'timify:widget:page:success',
}

const IframeContainer = styled.div<{ isiframe?: boolean }>`
  flex: 1;
  margin-left: ${({ isiframe }) => (isiframe ? '0' : '-24px')};
  margin-right: ${({ isiframe }) => (isiframe ? '0' : '-24px')};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
`;

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const TimifyWidgetBase: FC<IProps> = ({
  serviceExternalIds,
  firstName,
  lastName,
  email,
  phone,
  country,
  dossierUrl,
  setReservationSuccess,
  hideServices,
  showServices,
  showFooterBackButton,
  meta,
  reschedule,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const lang = useGetLocalization();
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const iframeUrlsParams = new URLSearchParams({
    accountId:
      process.env.REACT_APP_TIMIFY_ACCOUNT_ID || '610276c410d241118433bb87',
    fullscreen: 'true',
    hideCloseButton: 'true',
    enterpriseId:
      process.env.REACT_APP_TIMIFY_ENTERPRISE_ID || '610276173a099f1179611922',
    customisationId:
      process.env.REACT_APP_TIMIFY_WIDGET_CUSTOMISATION_ID ||
      '61027b53482b13117ddc26cd',
    ...(lang === 'de-ch'
      ? { templatesCustomisationId: '62fb4c523a929fc489af37f6' }
      : {}),
    horizontalCalendarLayout: 'true',
    displayWeeklyView: 'false',
    horizontalMonthSelector: 'true',
    serviceExternalIds,
    ...(firstName && { guestFirstName: firstName }),
    ...(lastName && { guestLastName: lastName }),
    ...(email && { guestEmail: email }),
    ...(phone && { guestPhone: phone }),
    ...(country && { guestPhoneCountry: country }),
    ...(dossierUrl && {
      'customerField[6102da632b696111509b996a]': dossierUrl,
      skipCustomerFieldsSection: 'true',
    }),
    ...(hideServices ? { hideServices: hideServices.join(',') } : {}),
    ...(showServices ? { showServices: showServices.join(',') } : {}),
    ...(showFooterBackButton ? { showFooterBackButton: 'true' } : {}),
    ...(meta
      ? {
          'meta[vonpollUserId]': meta.vonpollUserId,
          ...(meta.vonpollPropertyId
            ? { 'meta[vonpollPropertyId]': meta.vonpollPropertyId }
            : {}),
          ...(meta.vonpollSellingPropertyId
            ? {
                'meta[vonpollSellingPropertyId]': meta.vonpollSellingPropertyId,
              }
            : {}),
        }
      : {}),
    ...(reschedule
      ? { eventId: reschedule.id, secret: reschedule.secret }
      : {}),
    hideServicesExternalIds: 'KS1',
  });

  const url =
    serviceExternalIds === TimifyPersonalisationIds.PersonalValuation
      ? `${
          process.env.REACT_APP_TIMIFY_WIDGET_URL
        }services?${iframeUrlsParams.toString()}`
      : `${
          process.env.REACT_APP_TIMIFY_WIDGET_URL
        }customer-fields?${iframeUrlsParams.toString()}`;

  const listenForIframeMessages = useCallback(
    ({ data }: MessageEvent) => {
      if (data?.action === Actions.Success) {
        setReservationSuccess(true);
      }
    },
    [setReservationSuccess]
  );

  useEffect(() => {
    window.addEventListener('message', listenForIframeMessages);
    return () => window.removeEventListener('message', listenForIframeMessages);
  }, [listenForIframeMessages]);

  return (
    <IframeContainer isiframe={isIframe}>
      <Iframe ref={iframeRef} title="timity widget" src={url} />
    </IframeContainer>
  );
};

const TimifyWidget = memo(TimifyWidgetBase);

export { TimifyWidget };
