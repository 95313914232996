import { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';

import {
  CreatePropertyInput,
  LoginResponse,
  useSignInMutation,
  useSetPropertyPortalStatusMutation,
  PropertyPortalStatusValues,
} from '../../../../generated';
import { client } from '../../../../services/graphql/graphql';
import { device } from '../../../../style/theme';
import {
  InputOutsideTopLabel,
  InputPassOutsideTopLabel,
} from '../../../common/components/form';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { FormColumn, FormRow } from '../../../common/components/form/form-grid';
import { MainButton, TextButton } from '../../../common/components/ui/buttons';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { IValuationForm } from '../../../forms/form-property-valuation-wizard/interface';
import {
  resetValuationWizard,
  toggleRegisterForm,
} from '../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { parseValuationInput } from '../../../forms/utils/parse-valuation-input';
import { ILoginForm } from '../../interfaces';
import {
  login,
  setAnonymousUser,
  setConfirmedEmail,
  setUserInput,
  setUsername,
  setUserState,
  toggleIsForgottenPasswordOpen,
  toggleIsLoginModalOpen,
  toggleIsRegisterModalOpen,
} from '../../redux/authSlice';
import { resetSPC } from '../../../forms/form-search-profile/redux/searchProfileSlice';
import { useCreateTemporaryProperty } from '../../../forms/form-property-valuation-wizard/hooks/useCreateTemporaryProperty';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  @media ${device.tablet} {
    position: relative;
    height: 100%;
  }
`;

const ForgottenPasswordContainer = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
`;

const SubmitButton = styled(MainButton)`
  margin: 24px 0 4px 0;
  justify-content: center;
  @media ${device.tablet} {
    margin: 24px 0 4px 0;
    text-transform: uppercase;
  }
`;

const UppercaseTextButton = styled(TextButton)`
  opacity: 1;
  @media ${device.tablet} {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
    color: ${(props) => props.theme.blue};
  }
`;

const LastFormRow = styled(FormRow)`
  @media ${device.tablet} {
    margin-top: auto;
  }
`;

const ConfirmedEmailMessage = styled.div`
  opacity: 1;
  color: #437027;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  margin-top: 4px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1px;
  text-align: left;
  line-height: 16px;
`;

const LoginForm = (): JSX.Element => {
  const email = useAppSelector((state) => state.auth.confirmedEmail);
  const methods = useForm<ILoginForm>({
    mode: 'onTouched',
    defaultValues: {
      userAuthenticationKey: email,
    },
  });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { slug } = useParams<{ slug?: string }>();
  const themeContext = useContext(ThemeContext);
  const urlParams = new URLSearchParams(window.location.search);
  const sellerPropertyId = urlParams.get('sellerPropertyId') || '';

  const [inputPassword, setInputPassword] = useState('');
  const [validationError, setValidationError] = useState('');

  const userInput = useAppSelector((state) => state.valuationWizard.userInput);
  const selectedAddress = useAppSelector(
    (state) => state.valuationWizard.selectedAddress
  );
  const regionalBrokerId = useAppSelector(
    (state) => state.valuationWizard.regionalBrokerId
  );

  const [signIn, { data: signInData, error, isLoading }] = useSignInMutation();
  const [setPropertyPortalStatus] = useSetPropertyPortalStatusMutation();
  const { createTemporaryProperty } = useCreateTemporaryProperty();

  const onSubmit: SubmitHandler<ILoginForm> = useCallback(
    async (formData) => {
      const { userAuthenticationKey, password } = formData;

      setValidationError('');
      setInputPassword(password);

      // if (sellerPropertyId) {
      //   await setPropertyPortalStatus({
      //     propertyId: sellerPropertyId,
      //     status: PropertyPortalStatusValues.Active,
      //   });
      // }
      await signIn({
        input: {
          userAuthenticationKey,
          password,
          requestAccessToken: true,
          regionalBrokerId,
          slug,
        },
      });
    },
    [regionalBrokerId, signIn, slug]
  );

  const onForgottenPassword = () => {
    dispatch(toggleIsForgottenPasswordOpen(true));
  };

  useEffect(() => {
    if (signInData) {
      const loginResponse = signInData.signIn as LoginResponse;
      if (loginResponse?.jwt?.token) {
        client.setHeader(
          'Authorization',
          `Bearer ${loginResponse?.jwt?.token ?? ''}`
        );
        batch(() => {
          dispatch(login(loginResponse));
          dispatch(setUserState(loginResponse.user.userState));
          dispatch(setAnonymousUser(false));
          dispatch(resetValuationWizard());
          dispatch(resetSPC());
          dispatch(setConfirmedEmail());
        });
      } else if (loginResponse.user && !loginResponse.jwt) {
        batch(() => {
          dispatch(toggleRegisterForm(true));
          dispatch(toggleIsRegisterModalOpen(true));
          dispatch(setUserState(loginResponse.user.userState));
          dispatch(
            setUserInput({
              name: loginResponse.user.name,
              surname: loginResponse.user.surname,
              phonePrefix: '',
              phone: loginResponse.user.phone,
              email: loginResponse.user.email,
              password: inputPassword,
              confirmPassword: inputPassword,
              termsAndConditions: loginResponse.user.termsAndConditions,
            })
          );
          dispatch(setUsername(loginResponse.user.username));
        });
      }
      dispatch(toggleIsLoginModalOpen(false));
    }
  }, [dispatch, signInData, inputPassword]);

  // TODO: move this logic to another component
  useEffect(() => {
    if (signInData && userInput && selectedAddress) {
      const loginResponse = signInData.signIn as LoginResponse;
      try {
        const valuationInput = parseValuationInput(
          userInput as IValuationForm,
          selectedAddress
        ) as CreatePropertyInput;

        createTemporaryProperty({
          valuationInput,
          userId: loginResponse.user._id,
        }).catch((error_) => console.error(error_));
      } catch (temporaryError) {
        console.error(temporaryError);
      }
    }
  }, [createTemporaryProperty, selectedAddress, signInData, userInput]);

  useEffect(() => {
    if (email) {
      methods.setValue('userAuthenticationKey', email);
    }
  }, [email]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {validationError && <GlobalError title={t(validationError)} />}
        {!validationError && error?.message && (
          <GlobalError title={t(error?.message?.split(':')[0])} />
        )}
        <FormRow>
          <FormColumn flex="0 0 100%">
            <InputOutsideTopLabel
              name="userAuthenticationKey"
              type="text"
              label="email-phone.label"
              placeholder={t('email-phone.placeholder')}
              defaultValue={email || ''}
              rules={{
                required: true,
              }}
            />
            {!!email && (
              <ConfirmedEmailMessage>
                {t(
                  'login.form.input.label.userAuthenticationKey.email-success-confirmed'
                )}
              </ConfirmedEmailMessage>
            )}
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn flex="0 0 100%">
            <InputPassOutsideTopLabel
              name="password"
              type="password"
              label="register.form.input.label.password"
              placeholder="register.form.input.placeholder.password"
              rules={{ required: true }}
              passwordMasterValue={methods.watch('password')}
            />
          </FormColumn>
        </FormRow>
        <LastFormRow>
          <FormColumn flex="0 0 100%">
            <ForgottenPasswordContainer>
              <UppercaseTextButton
                color={themeContext.blue}
                $forceActive
                type="button"
                onClick={onForgottenPassword}
              >
                {t('modal.login.forgotten-password')}
              </UppercaseTextButton>
            </ForgottenPasswordContainer>
            <SubmitButton
              fluid
              loader
              label={t('modal.login.submit')}
              isLoading={isLoading}
            />
          </FormColumn>
        </LastFormRow>
      </Form>
    </FormProvider>
  );
};

export { LoginForm };
