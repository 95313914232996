import { Image, StyleSheet, View } from '@react-pdf/renderer';

import { defaultTheme } from '../../../../style/theme';

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  side: {
    width: '34%',
  },
  sideMiddle: {
    height: '12mm',
    backgroundColor: defaultTheme.blue,
    width: '100%',
  },
  sideTop: {
    borderTop: `2px solid ${defaultTheme.blue}`,
    height: '2mm',
  },
  sideBottom: {
    borderBottom: `2px solid ${defaultTheme.blue}`,
    height: '2mm',
  },
  logo: {
    display: 'flex',
    width: '28%',
    height: '14mm',
  },
});

const Header = (): JSX.Element => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.side}>
        <View style={styles.sideTop} />
        <View style={styles.sideMiddle} />
        <View style={styles.sideBottom} />
      </View>
      <View style={styles.logo}>
        <Image source="/templates/logo.png" />
      </View>
      <View style={styles.side}>
        <View style={styles.sideTop} />
        <View style={styles.sideMiddle} />
        <View style={styles.sideBottom} />
      </View>
    </View>
  );
};

export { Header };
