import { ReactComponent as PhoneCircle } from 'assets/streamline-light/phones-mobile-devices/phone/phone-circle.svg';
import { FC, memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';

import {
  AppointmentSection,
  BlockType,
  useCmsPageQuery,
  useCmsPagesQuery,
  useReadAppointmentsQuery,
} from '../../../../generated';
import { device } from '../../../../style/theme';
import { MainButton } from '../../../common/components/ui/buttons';
import Icon from '../../../common/components/ui/icon';
import {
  Headline2,
  Headline3,
  ParagraphTitle,
  PhoneLink,
} from '../../../common/components/ui/typography';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { Avatar } from '../../../landing/components/appointment/avatar';
import LegalModal from '../../../landing/components/appointment/legal-modal';
import { toggleTimifyModal } from '../../../timify/redux/timifySlice';

interface IProps {
  pageName: string;
  appointmentId?: string;
}

const Container = styled.div`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  @media ${device.tablet} {
    /* display: none; */
    padding: 16px;
  }
`;

const Title = styled(Headline2)`
  margin-top: 16px;
  margin-bottom: 6px;
`;

const PhoneWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`;

const PhoneText = styled(ParagraphTitle)`
  opacity: 0.6;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  margin: 0 4px 0 8px;
`;

const PhoneNumber = styled(ParagraphTitle)`
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
`;

const Description = styled.p`
  margin-bottom: 16px;
  font-size: 16px;
  font-family: 'Source Serif Pro', serif;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  text-align: center;
`;

const Photo = styled(Avatar)`
  overflow: hidden;
  margin-bottom: 16px;
  object-fit: cover;
  border-radius: 4px;

  @media ${device.mobile} {
    max-width: 100%;
    height: auto;
  }
`;

const BrokerName = styled(Headline3)``;

const Position = styled.p`
  margin-bottom: 4px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
`;

const Qualification = styled.p`
  margin-bottom: 12px;
  font-family: Roboto, serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  opacity: 0.6;
`;

export const LegalModalToggler = styled.button`
  font-family: Roboto;
  opacity: 0.4;
  font-size: 10px;
  line-height: 1.6;
  background: none;
  text-decoration: underline;
  outline: none;
  border: none;
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #002849;
`;

const AppointmentCardBase: FC<IProps> = ({ pageName, appointmentId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const themeContext = useContext(ThemeContext);

  const [isLegalOpen, setLegalOpen] = useState(false);

  const regionalBrokerId = useAppSelector(
    (state) => state.auth.user?.regionalBrokerId
  );

  // select property page from cache
  const { propertyPage } = useCmsPagesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      propertyPage: data?.cmsPages?.find((page) => page.name === pageName),
    }),
  });

  // Fetch property page details
  const appointmentSectionFromPage = useCmsPageQuery(
    {
      id: propertyPage?._id ?? '',
    },
    {
      selectFromResult: ({ data }) =>
        data?.cmsPage.blocks.find(
          (block) => block.type === BlockType.Appointment
        )?.sections[0] as AppointmentSection,
    }
  );

  const appointmentSection = useReadAppointmentsQuery(
    {
      filter: {
        _ids: [
          appointmentId ||
            regionalBrokerId ||
            (appointmentSectionFromPage._id ?? ''),
        ],
      },
    },
    {
      selectFromResult: ({ data }) => data?.data[0] as AppointmentSection,
    }
  );

  return (
    <Container>
      <Title content={t('appointment.title.text')} />
      <Description>{t('appointment.description.text')}</Description>
      {appointmentSection?.avatarUrl && (
        <Photo
          imageSrc={appointmentSection?.avatarUrl}
          altText={appointmentSection?.altText}
          width={336}
          height={124}
        />
      )}
      <BrokerName content={appointmentSection?.title} />
      <Position>{appointmentSection?.brokerPosition}</Position>
      <Qualification>{appointmentSection?.brokerQualification}</Qualification>
      <LegalModalToggler onClick={() => setLegalOpen(true)}>
        {appointmentSection?.legalPopUpLabel}
      </LegalModalToggler>
      <LegalModal
        mobileTitle={appointmentSection?.legalPopUpLabel ?? ''}
        innerHTML={appointmentSection?.legalPopUpText ?? ''}
        isOpen={isLegalOpen}
        onClose={() => setLegalOpen(false)}
      />
      <PhoneWrapper>
        <Icon
          width={20}
          height={20}
          icon={PhoneCircle}
          color={themeContext.blue}
        />
        <PhoneText content={`${t('appointment.phone.text')}:`} />
        <PhoneLink phoneNumber={appointmentSection?.phone}>
          <PhoneNumber content={appointmentSection?.phone} />
        </PhoneLink>
      </PhoneWrapper>
      <MainButton
        label={t('appointment.button.text')}
        onClick={() => dispatch(toggleTimifyModal(true))}
        fluid
        centered
      />
    </Container>
  );
};

const AppointmentCard = memo(AppointmentCardBase);

export { AppointmentCard };
