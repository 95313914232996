import { MouseEvent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import {
  InputOutsideTopLabel,
  InputOutsideTopLabelWithSelect,
} from '../../../../../common/components/form';
import { SelectOutsideTopLabel } from '../../../../../common/components/form/select/select-outside-top-label';
import {
  phoneNumberPrefixesOptions,
  salutationOptions,
  titleOptions,
} from '../../../constants/options';
import { useCanContinue } from '../../../hooks/useCanContinue';
import { useValidate } from '../../../hooks/useValidate';
import { addAnother, deleteCurrent } from '../../../redux/purchaseIntendSlice';
import {
  AddAnotherButton,
  DeleteCurrentButton,
  Row,
  Wrapper,
} from '../../common/form-utils';

const PhysicalAdditionalStep = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setValue, getValues, reset } = useFormContext();
  const { activeStep } = useSelector(
    (state: RootState) => state.purchaseIntend
  );
  const canContinue = useCanContinue();
  const validate = useValidate();

  const index = activeStep - 1;

  const [otherPersons] = useWatch({ name: ['otherPersons'] });

  const addAnotherHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!validate()) return;

    setValue('otherPersons', [
      ...getValues('otherPersons'),
      {
        salutation: salutationOptions[0].value,
        title: titleOptions[2].value,
      },
    ]);
    dispatch(addAnother());
  };

  const deleteCurrentHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const value = getValues();
    value.otherPersons.splice(index, 1);
    reset(value);
    dispatch(deleteCurrent());
  };

  return (
    <Wrapper>
      <Row>
        <SelectOutsideTopLabel
          label={t('purchase-intent.form.salutation')}
          name={`otherPersons.${index}.salutation`}
          selectedOption={
            salutationOptions.find(
              (s) => s.value === otherPersons[index]?.salutation
            )?.label || ''
          }
          defaultValue={salutationOptions[0].value}
          options={salutationOptions}
        />
        <SelectOutsideTopLabel
          label={t('purchase-intent.form.title')}
          name={`otherPersons.${index}.title`}
          selectedOption={
            titleOptions.find((o) => o.value === otherPersons[index]?.title)
              ?.label || ''
          }
          defaultValue={titleOptions[2].value}
          options={titleOptions}
        />
      </Row>
      <Row>
        <InputOutsideTopLabel
          name={`otherPersons.${index}.name`}
          type={'text'}
          label={t('purchase-intent.form.name')}
          placeholder={t('purchase-intent.form.name')}
          rules={{}}
        />
        <InputOutsideTopLabel
          name={`otherPersons.${index}.surname`}
          type={'text'}
          label={t('purchase-intent.form.surname')}
          placeholder={t('purchase-intent.form.surname')}
          rules={{}}
        />
      </Row>
      <Row>
        <InputOutsideTopLabel
          name={`otherPersons.${index}.postalCode`}
          type={'text'}
          label={t('purchase-intent.form.postalCode')}
          placeholder={t('purchase-intent.form.postalCode')}
          rules={{}}
        />
        <InputOutsideTopLabel
          name={`otherPersons.${index}.city`}
          type={'text'}
          label={t('purchase-intent.form.city')}
          placeholder={t('purchase-intent.form.city')}
          rules={{}}
        />
      </Row>
      <Row marginBottom={16}>
        <InputOutsideTopLabel
          type={'text'}
          name={`otherPersons.${index}.email`}
          errorKey={`otherPersons:${index}:email`}
          label={t('purchase-intent.form.email')}
          placeholder={t('purchase-intent.form.email')}
          rules={{}}
        />
        <InputOutsideTopLabelWithSelect
          type={'number'}
          name={`otherPersons.${index}.phone`}
          label={t('purchase-intent.form.phone')}
          placeholder={t('purchase-intent.form.phone')}
          rules={{
            valueAsNumber: true,
          }}
          selectWidth="87px"
          hasSelect
          selectOptions={phoneNumberPrefixesOptions}
          selectValue={phoneNumberPrefixesOptions[0]}
          selectName={`otherPersons.${index}.phonePrefix`}
        />
      </Row>
      <DeleteCurrentButton onClick={deleteCurrentHandler}>
        {t('purchase-intent.form.deleteCurrent')}
      </DeleteCurrentButton>
      <AddAnotherButton disabled={!canContinue} onClick={addAnotherHandler}>
        {t('purchase-intent.form.addAnotherPerson')}
      </AddAnotherButton>
    </Wrapper>
  );
};

export default PhysicalAdditionalStep;
