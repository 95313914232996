import { FC, memo, useCallback } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { device } from '../../../../style/theme';
import { ProgressCard } from '../progress-card/progress-card';
import { OverviewCard } from '../overview/overview-card/overview-card';
import { TeamContactCard } from '../appointment-card/team-contact-card';
import Banner from '../banner/banner';
import { ReactComponent as ReadHuman } from '../../../../assets/streamline-light/school-learning/library-reading/read-human.svg';
import BannerImage from '../../../../assets/broker-view-banner.webp';
import { useAppSelector } from '../../../common/hooks';
import { Broker, useGetPropertyBrokerQuery } from '../../../../generated';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { PropertyLockedMessage } from '../property-locked-message/property-locked-message';

const Container = styled.div`
  position: relative;
  display: flex;
  @media ${device.tablet} {
    flex-direction: column;
    padding: 16px;
  }
`;

const FirstColumn = styled.div<{ isBlurred?: boolean }>`
  flex: 1.72;
  max-width: 688px;
  margin-right: 16px;
  ${(props) => props.isBlurred && 'filter: blur(6px)'};

  @media ${device.tablet} {
    margin-bottom: 16px;
    max-width: 100%;
    width: 100%;
  }
`;

const SecondColumn = styled.div`
  flex: 1;
  max-width: 400px;
  @media ${device.tablet} {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

interface IProps {
  isLocked?: boolean;
}

const OverviewBase: FC<IProps> = ({ isLocked }): JSX.Element => {
  const { t } = useTranslation();
  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);
  const { propertyId } = useParams<{ propertyId: string }>();

  const { propertyBroker, isBrokerLoading, getBrokerError } =
    useGetPropertyBrokerQuery(
      {
        propertyId,
      },
      {
        selectFromResult: ({ data, isLoading: brokerLoading, error }) => ({
          propertyBroker: data?.getPropertyBroker as Broker,
          isBrokerLoading: brokerLoading,
          getBrokerError: error,
        }),
        skip: !isLocked,
      }
    );

  const onEmailBroker = useCallback(() => {
    if (propertyBroker?.email) {
      window.open(`mailto: ${propertyBroker.email}`, '_self');
    }
  }, [propertyBroker?.email]);

  return (
    <>
      {isBrokerView && (
        <Banner
          icon={ReadHuman}
          image={BannerImage}
          title={t('seller-portal.broker-view.banner.title')}
          description={t('seller-portal.broker-view.banner.description')}
        />
      )}
      <Container>
        {isLocked && <PropertyLockedMessage onEmailBroker={onEmailBroker} />}
        <FirstColumn isBlurred={isLocked}>
          {getBrokerError?.message && (
            <GlobalError
              title={t(getBrokerError?.message?.split(':')[0].toLowerCase())}
            />
          )}
          <OverviewCard isLocked={isLocked} />
        </FirstColumn>
        <SecondColumn>
          <ProgressCard pollingInterval={5000} isBlurred={isLocked} />
          <TeamContactCard />
        </SecondColumn>
      </Container>
    </>
  );
};

const Overview = memo(OverviewBase);

export { Overview };
