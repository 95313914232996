import { easeCubic } from 'd3-ease';
import { memo, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FlyToInterpolator } from 'react-map-gl';
import { batch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as ArrowButtonLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as AddCircle } from 'assets/streamline-light/interface-essential/remove-add/add-circle.svg';
import { ReactComponent as LocationMarker } from 'assets/streamline-light/maps-navigation/pins/pin.svg';

import { useParams } from 'react-router-dom';
import {
  usePropertyQuery,
  ValuationType,
  usePropertyOnOfficeViewQuery,
} from '../../../../../generated';
import { device } from '../../../../../style/theme';
import { Germany } from '../../../../map/constants/points-of-interest';
import {
  changeViewport,
  setMarkers,
  setSettings,
} from '../../../../map/redux/mapSlice';
import {
  openValuationWizardAction,
  setValuationTypeAction,
  toggleFloatingBar,
} from '../../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import {
  setShowMap,
  setTabListNearTop,
} from '../../../../property/redux/dynamicMapUtilsSlice';
import { implodeAddress } from '../../../../property/utils/address';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useIsMobileSize } from '../../../hooks/useIsMobileSize';
import Icon from '../../ui/icon';
import { BoxTitle } from '../../ui/typography';
import LngLink from '../../../../localization/lng-link';
import { siteMap } from '../../../../../routes/site-map';
import { useGetEvaluationWidgetVersion } from '../../../../forms/form-property-valuation-wizard-v2/hooks/useGetWidgetVersion';
import { useOpenEvaluationWizardV2 } from '../../../../forms/form-property-valuation-wizard-v2/hooks/callback-hooks/useOpenEvaluationWizardV2';
import { defaultMapSettings } from '../../../../map/constants';

interface IProps {
  shouldShrink?: boolean;
  onGoBack?: () => void;
}

const OuterContainer = styled.div<{ shouldShrink?: boolean }>`
  width: ${(props) => (props.shouldShrink ? 'calc(100% - 96px)' : '100%')};
  margin: auto;
  position: sticky;
  top: 80px;
  z-index: 2000;
  max-height: ${(props) => (props.shouldShrink ? '62px' : 0)};
  opacity: ${(props) => (props.shouldShrink ? 1 : 0)};
  background-color: #e5e9ec;
  transition: width 500ms ease, opacity 500ms, max-height 200ms;
  overflow: hidden;

  @media ${device.tablet} {
    position: relative;
    margin: 0;
    width: 100%;
    top: 0;
  }
`;

const Container = styled.div`
  transform: rotateX(180deg);
  padding: 8px 24px;
  height: 62px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  align-items: center;
  width: 100%;
  a {
    display: block;
    transform: rotateX(180deg);
    color: unset;
    text-decoration: none;
    &.active {
      border-bottom: 4px solid ${(props) => props.theme.logoBlue};
      cursor: default;
    }
  }
  &::-webkit-scrollbar-track {
    background: #e7ebee;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.logoBlue};
  }
  @media ${device.tablet} {
    position: static;
    padding: 8px 12px;
  }
`;

const Label = styled(BoxTitle)`
  color: ${(props) => props.theme.blue};
  opacity: 0.5;
  margin-bottom: 4px;
  font-size: 10px;
  line-height: 1.6;
`;

const AddressText = styled.p`
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.5px;
  &&&& {
    color: ${(props) => props.theme.blue};
  }
`;

const PropertyContainer = styled.div`
  min-width: 320px;
`;

const ArrowContainer = styled.div`
  background-image: radial-gradient(
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0)
  );
  width: 40px;
  z-index: 10;
  border-radius: 50%;
`;

const ArrowButton = styled.div`
  width: 40px;
  height: 40px;
  border: solid 2px #fff;
  background-color: ${(props) => props.theme.logoBlue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    path {
      stroke-width: 2px;
    }
  }
`;

const ValuationButtonContainer = styled.div`
  padding: 4px 0 0 0;
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
`;

const ValuationButtonLabel = styled.p`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 900;
  line-height: 1.71;
  letter-spacing: 1px;
  color: ${(props) => props.theme.blue};
  margin-left: 16px;
  transform: scale(-1, 1) rotate(180deg);
`;

const IconWrapper = styled.div`
  transform: rotateX(180deg);
  align-self: center;
  margin: 0 24px;

  @media ${device.tablet} {
    margin: 0 12px;
  }
`;

const SellerPropertiesTabBase = ({
  shouldShrink,
  onGoBack,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const themeContext = useContext(ThemeContext);
  const { propertyId } = useParams<{ propertyId: string }>();
  const { openEvaluationWizardV2 } = useOpenEvaluationWizardV2({});

  const containerRef = useRef<HTMLDivElement | null>(null);
  const floatingBar = useAppSelector(
    (state) => state.valuationWizard.floatingBar
  );
  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const { isRedesignVersion } = useGetEvaluationWidgetVersion();

  const onNewValuationClick = () => {
    if (isRedesignVersion) {
      openEvaluationWizardV2(ValuationType.Online);
      return;
    }
    batch(() => {
      dispatch(
        changeViewport({
          ...Germany,
          transitionDuration: 'auto',
          transitionInterpolator: new FlyToInterpolator({
            speed: 0.6,
            curve: 1,
          }),
          transitionEasing: easeCubic,
        })
      );
      dispatch(setShowMap(false));
      dispatch(setTabListNearTop(false));
      dispatch(setMarkers([]));
      dispatch(setSettings(defaultMapSettings));
      dispatch(openValuationWizardAction());
      dispatch(setValuationTypeAction(ValuationType.Online));
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Fetch all properties
  const {
    isLoading: isPropertyDataLoading,
    propertyData,
    id,
  } = usePropertyQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, isLoading }) => {
        return {
          id: data?.property?._id,
          propertyData: data?.property?.propertyData,
          isLoading,
        };
      },
      skip: isBrokerView,
    }
  );

  const {
    isLoading: isBrokerPropertyDataLoading,
    propertyData: brokerPropertyData,
    id: brokerPropertyId,
  } = usePropertyOnOfficeViewQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, isLoading }) => {
        return {
          id: data?.propertyOnOfficeView?._id,
          propertyData: data?.propertyOnOfficeView?.propertyData,
          isLoading,
        };
      },
      skip: !isBrokerView,
    }
  );

  useEffect(() => {
    if (propertyData && !floatingBar) {
      dispatch(toggleFloatingBar(true));
    }
  }, [dispatch, propertyData, floatingBar]);

  return (
    <OuterContainer shouldShrink={shouldShrink}>
      <Container ref={containerRef}>
        <ArrowContainer>
          <ArrowButton onClick={onGoBack}>
            <Icon icon={ArrowButtonLeft} width={10} height={10} />
          </ArrowButton>
        </ArrowContainer>
        <IconWrapper>
          <Icon
            icon={LocationMarker}
            width={24}
            height={24}
            color={themeContext.blue}
          />
        </IconWrapper>
        <LngLink
          key={id}
          to={`${siteMap.PropertyPage.pathWithoutParams}/${
            isBrokerView ? brokerPropertyId : id
          }`}
          exact
          absolute
        >
          <PropertyContainer>
            <Label content={`${t('seller-properties-tab.property-label')}`} />
            <AddressText>
              {isBrokerView
                ? brokerPropertyData?.location?.address &&
                  implodeAddress(brokerPropertyData?.location.address)
                : propertyData?.location?.address &&
                  implodeAddress(propertyData?.location.address)}
            </AddressText>
          </PropertyContainer>
        </LngLink>
        {!isMobileSize && (
          <ValuationButtonContainer
            onClick={isBrokerView ? undefined : onNewValuationClick}
          >
            <Icon
              icon={AddCircle}
              width={32}
              height={32}
              color={themeContext.blue}
            />
            <ValuationButtonLabel>
              {t('seller-properties-tab.new-valuation-button.label')}
            </ValuationButtonLabel>
          </ValuationButtonContainer>
        )}
      </Container>
    </OuterContainer>
  );
};

const SellerPropertiesTab = memo(SellerPropertiesTabBase);

export { SellerPropertiesTab };
