import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const ContentWithPadding = styled.div`
  padding: 48px 48px 0 48px;

  > div:first-child {
    margin-bottom: 28px;
  }

  > div:last-of-type {
    margin-top: 64px;
  }

  @media ${device.tablet} {
    padding: 0;
    > div:first-child {
      margin-bottom: 0;
    }

    > div:not(:first-child) {
      margin-left: 16px;
      margin-right: 16px;
    }

    > div:last-of-type {
      margin-top: 16px;
    }
  }
`;
