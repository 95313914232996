import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { ReactComponent as EmptyStateIcon } from 'assets/svg/empty-state.svg';
import certificateImage from 'assets/images/icons/financing-landing-page/financing-certificate.png';
import { device } from '../../../../../../style/theme';
import { MainButton } from '../../../../../common/components/ui/buttons';
import Icon from '../../../../../common/components/ui/icon';
import {
  Headline2,
  ParagraphText,
} from '../../../../../common/components/ui/typography';
import { useOpenFinancing } from '../../../../../forms/form-financing/hooks/callback-hooks/useOpenFinancing';
import { FinancingType } from '../../../../../../generated';

const Content = styled.div`
  padding: 32px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  border-radius: 3px;
  background-color: #fff;

  @media ${device.tablet} {
    padding: 10px;
  }
`;

const Header = styled.h1`
  color: #00305e;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 32px;
  margin-bottom: 24px;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: column-reverse;
  }
`;

const Certificate = styled.img`
  width: 174px;
  height: 250px;
  margin: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const ContentBox = styled.div`
  border-radius: 3px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 24px;

  @media ${device.tablet} {
    padding: 12px;
  }
`;

const Title = styled(Headline2)`
  font-size: 20px;
  line-height: 1.6;
  margin-top: 12px;
  text-align: center;

  @media ${device.tablet} {
    width: auto;
  }
`;

const Description = styled(ParagraphText)`
  text-align: center;
  margin: 8px 0 12px 0;
  width: 671px;

  @media ${device.tablet} {
    width: auto;
  }
`;

const CTAButton = styled(MainButton)`
  text-transform: uppercase;
  margin: 12px 0 8px 0;
`;

const EmptyStatePlaceholder = () => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const { openFinancing } = useOpenFinancing({});

  return (
    <Content>
      <FlexBox>
        <Certificate src={certificateImage} />
        <ContentBox>
          <Icon
            icon={EmptyStateIcon}
            color={themeContext.blue}
            style={{ width: '35px', height: '42px' }}
          />
          <Title
            content={t(
              'financing-portal-page.mortgage-certificate.empty-title'
            )}
          />
          <Description
            content={t(
              'financing-portal-page.mortgage-certificate.empty-description'
            )}
          />
          <CTAButton
            label={t('financing-portal-page.mortgage-certificate.empty-button')}
            onClick={() => openFinancing(FinancingType.FinancingCertificate)}
          />
        </ContentBox>
      </FlexBox>
    </Content>
  );
};

export default EmptyStatePlaceholder;
