import { IMapSettings } from '../interfaces';

export const defaultMapSettings: IMapSettings = {
  dragPan: false,
  dragRotate: false,
  scrollZoom: false,
  touchZoom: false,
  touchRotate: false,
  keyboard: false,
  doubleClickZoom: false,
  minZoom: 1,
  maxZoom: 18,
  minPitch: 0,
  maxPitch: 85,
  radius: 0,
  isMapMarker: false,
};
