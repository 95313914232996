import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const GarageIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={
          'M.5 21.477h23m-21-12v12m19 0v-12m2-1.672a.5.5 0 0 0-.3-.458l-11-4.783a.5.5 0 0 0-.4 0L.8 7.347a.5.5 0 0 0-.3.458v1.172a.5.5 0 0 0 .5.5h22a.5.5 0 0 0 .5-.5zM5 21.477a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h14a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5m-12.5-7h11m-11 2h11m-11 2h11'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { GarageIcon };
