import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 14px;
  margin-bottom: 4px;
`;

export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const IconContainer = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 3px;
  background-color: #f2f5f7;
  gap: 4px;
`;

export const Icon = styled.img`
  max-width: 24px;
  max-height: 24px;
`;

export const MarkIcon = styled.img`
  position: absolute;
  width: 16px;
  height: 16px;
  top: -5px;
  right: -5px;
`;

export const Line = styled.div`
  height: 100%;
  opacity: 0.9;
  width: 1px;
  border-left: 1px dashed ${({ theme }) => theme.blue};
`;

export const RightSideContainer = styled(LeftSideContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
`;

export const Headline = styled.h5`
  opacity: 0.5;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.blue};
`;

export const Title = styled.h4`
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  min-height: 32px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  display: flex;
  align-items: center;
`;

export const BlueLine = styled.div`
  width: 160px;
  height: 6px;
  background-color: ${({ theme }) => theme.blue};
  border-radius: 3px;
`;

export const Content = styled.div`
  padding: 24px 0 0;
  width: 100%;
`;
