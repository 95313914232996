import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const CTAContainer = styled.div<{ iniframe: boolean }>`
  position: ${({ iniframe }) => (iniframe ? 'static' : 'absolute')};
  bottom: -24px;
  right: 48px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media ${device.tablet} {
    position: static;
    padding: 0 16px;
  }
`;

export const ContentWithPadding = styled.div`
  padding: 48px 48px 0 48px;

  > div:first-child,
  > div:nth-child(2) {
    margin-bottom: 64px;
  }

  > div:last-of-type {
    margin-top: 64px;
  }

  @media ${device.tablet} {
    overflow: hidden;
    padding: 0;
    > div:first-child {
      margin-bottom: 16px;
    }

    > div:not(:first-child) {
      margin-left: 16px;
      margin-right: 16px;
    }

    > div:last-of-type {
      margin-top: 16px;
    }
  }
`;

export const IFrameContainer = styled.div<{
  isOverflowVisible?: boolean;
}>`
  display: flex;
  justify-content: center;
  overflow-y: ${({ isOverflowVisible }) =>
    isOverflowVisible ? 'visible' : 'auto'};
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  cursor: pointer;
  width: 12px;
  height: 12px;
`;

export const IFrameSectionContainer = styled.div`
  padding: 0 30px;

  @media ${device.tabletSlider} {
    padding: 0;
  }
`;
