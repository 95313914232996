import styled from 'styled-components';
import { device } from '../../../../../../style/theme';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

export const IconRow = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-row: 2;
  row-gap: 10px;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-rows: 3;
  }
`;
