import styled from 'styled-components';
import { FormGridWrapperFixHeight } from '../../../../../common/components/form/form-grid';
import { Checkbox } from '../../../../../common/components/form';
import { device } from '../../../../../../style/theme';

export const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Row = styled.div<{ direction?: string }>`
  display: flex;
  flex-direction: ${(props) => props.direction ?? 'row'};
`;

export const ExpandleContentSplitter = styled.div`
  margin: 8px 0 0;
`;

export const StyledFormGridWrapperFixHeight = styled(FormGridWrapperFixHeight)`
  min-height: 480px;
  display: block;
`;

export const FormGridOuterWrapper = styled.div`
  position: relative;
`;

export const CheckboxRow = styled.div`
  padding: 12px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media ${device.tablet} {
    padding-top: 18px;
  }
`;

export const CheckboxItem = styled(Checkbox)`
  margin-right: 12px;
`;
