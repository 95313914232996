import { FC } from 'react';
import { useVonPollIFrameAuth } from '../hooks/useVonPollIFrameAuth';

const VonPollIframeAuthPage: FC = () => {
  useVonPollIFrameAuth();

  return <></>;
};

export { VonPollIframeAuthPage };
