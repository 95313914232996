import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const BathroomIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={
          'M6 10.5H4V1a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v9.5H9.5m2.5 0v3h0a3 3 0 0 1 3 3v.5h-3v6a.5.5 0 0 1-.5.5H1a.5.5 0 0 1-.5-.5V11a.5.5 0 0 1 .5-.5h3'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={
          'M12 19h11a.5.5 0 0 0 .5-.5V12h0A3.5 3.5 0 0 1 20 8.5h3a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5h-8M.5 17.5h5v6h0H1a.5.5 0 0 1-.5-.5v-5.5h0zm0 2h5m-5 2h5'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { BathroomIcon };
