export const getQuarterAndYear = (
  date: string
): { quarter: number; year: number } => {
  const fullDate = new Date(date);

  return {
    quarter: Math.floor((fullDate.getMonth() + 3) / 3),
    year: fullDate.getFullYear(),
  };
};
