import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Hero } from '../../../common/components/ui/hero';
import { AddressBar } from '../../../common/components/ui/hero-slider/address-bar';
import { HeroSlider } from '../../../common/components/ui/hero-slider/hero-slider';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { Mapbox } from '../../../map/components/mapbox';
import { Drawer } from '../../../property/components/drawer/drawer';
import { MobileMapContainer } from '../../../search-profile/pages/search-profile-page/search-profile-page-styles';
import FinancingOverviewCard from '../../components/financing-overview-card/financing-overview-card';
import FinancingWidgets from '../../components/financing-widgets/financing-widgets';
import {
  ContentWithPadding,
  LoaderContainer,
  MobileHeadContainer,
} from './financing-portal-page-styles';
import { TabsFinancingPortal } from '../../components/tabs/tabs-financing-portal';
import { useFinancingPortalMap } from '../../hooks/useFinancingPortalMap';
import { useGetFinancings } from '../../hooks/useGetFinancings';
import { useAppSelector } from '../../../common/hooks';
import { useGetFinancing } from '../../hooks/useGetFinancing';
import { LngRedirect } from '../../../localization/lng-redirect';
import { siteMap } from '../../../../routes/site-map';
import { MultipleFinancings } from '../../components/multiple-financings/multiple-financings';
import { MultipleVariants } from '../../components/multiple-financings/multiple-variants';
import { useCleanVariantPropositions } from '../../hooks/useCleanVariantPropositions';
import { useGetReasonAndType } from '../../hooks/useGetReasonAndType';
import { CompareVariantsModal } from '../../components/multiple-financings/compare-variants-modal/compare-variants-modal';
import { useFinancingCalculation } from '../../hooks/calculation-tab/useFinancingCalculation';
import {
  setVariantIndex,
  toggleSourceEmailNotification,
} from '../../../forms/form-financing/redux/financingSlice';
import { VariationHeaderActions } from '../../components/tabs/variation-header-actions/variation-header-actions';
import { CompareVariantsMobileModal } from '../../components/multiple-financings/compare-variants-mobile-modal/compare-variants-mobile-modal';
import { FinancingGalleryContainer } from '../../components/financing-gallery-container/financing-gallery-container';
import { useCheckAndUpdateOverduePropositions } from '../../../forms/form-financing/hooks/useCheckAndUpdateOverduePropositions';
import { useCheckFinancingURLParams } from '../../hooks/useCheckFinancingURLParams';
import { FinancingLoader } from '../../../forms/components/common/loader';
import { SourceEmailNotification } from '../../components/source-email-notification/source-email-notification';
import { toggleisEditProfileModalOpen } from '../../../auth/redux/authSlice';

const FinancingPortalPage: FC = () => {
  const isMobileSize = useIsMobileSize();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { financingId } = useParams<{ financingId: string }>();
  const isEmptyState = financingId === undefined;
  const locationReactDom = useLocation();
  const history = useHistory();

  const ownerId = useAppSelector((state) => state.auth.user?._id);
  const { variantIndex, isLoadingCreating } = useAppSelector(
    (state) => state.financing
  );
  const { isUpdatingOverdueFinancingPropositions } = useAppSelector(
    (state) => state.financing.editWidget
  );

  const { variantIndexParam, source, financingNotification } =
    useCheckFinancingURLParams();

  const {
    financingVariants,
    proposition,
    financingVariant,
    vonPollProperty,
    isFetching: isFinancingFetching,
    isLoading: isFinancingLoading,
    error: errorFinancing,
    location,
    refetch,
    searchProfileId,
    onlineExposeId,
  } = useGetFinancing({
    financingId,
  });
  const updatedAt = financingVariant?.propositionsLastSync;
  const {
    financings,
    isLoading: isFinancingsLoading,
    error: errorFinancings,
    isFetching: isFinancingsFetching,
  } = useGetFinancings({ ownerId });
  const { isLoading } = useFinancingCalculation();

  useFinancingPortalMap({ coordinates: location?.coordinates });
  useCleanVariantPropositions();
  useCheckAndUpdateOverduePropositions();
  useEffect(() => {
    if (
      (variantIndex !== 0 && variantIndex !== undefined) ||
      !variantIndexParam
    ) {
      dispatch(setVariantIndex(0));
    }
  }, [financingId]);

  useEffect(() => {
    if (source === 'email') {
      dispatch(toggleSourceEmailNotification(true));
    }
    dispatch(setVariantIndex(Number(variantIndexParam) || 0));
  }, []);

  useEffect(() => {
    if (financingVariants && financingVariants?.length > 0) {
      const queryParams = new URLSearchParams(locationReactDom.search);
      if (queryParams.has('financing-id')) {
        queryParams.delete('financing-id');
        history.replace({
          search: queryParams.toString(),
        });
      }
      if (financingNotification === '-1') {
        dispatch(toggleisEditProfileModalOpen(true));
        queryParams.delete('financing-notification');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
  }, [financingVariants?.length]);

  const { propertyType, reason } = useGetReasonAndType({
    financingVariantData: financingVariant?.financingVariantData,
  });

  const isRedirect404 =
    (!isFinancingLoading && errorFinancing) ||
    (!isFinancingsLoading && errorFinancings);

  const onlineExposeLink = useMemo(() => {
    if (vonPollProperty && searchProfileId && onlineExposeId) {
      return `${siteMap.SearchProfilePage.pathWithoutParams}/${searchProfileId}/?online-expose=${onlineExposeId}&property-id=${vonPollProperty.propertyId}`;
    }
    return '';
  }, [onlineExposeId, searchProfileId, vonPollProperty]);

  if (isRedirect404 && !isEmptyState) {
    return <LngRedirect to={siteMap.FinancingLandingPage.path} />;
  }

  if (
    isFinancingLoading ||
    !financings ||
    isLoadingCreating ||
    isFinancingsFetching
  ) {
    return (
      <LoaderContainer>
        <FinancingLoader mb={0} title="financing.loader-label" />
      </LoaderContainer>
    );
  }

  const isMultipleVariants =
    !!financingVariants && financingVariants?.length > 1;

  const isMultipleFinancings = financings?.length > 1 || isMultipleVariants;

  return (
    <>
      <SourceEmailNotification />
      {isMultipleFinancings && (
        <MultipleFinancings
          financingsData={financings}
          activeId={financingId}
        />
      )}
      {isMultipleVariants && (
        <>
          <MultipleVariants
            refetch={refetch}
            financingVariants={financingVariants || []}
            activeId={variantIndex}
          />
          {isMobileSize ? (
            <CompareVariantsMobileModal />
          ) : (
            <CompareVariantsModal />
          )}
        </>
      )}
      {!isMobileSize ? (
        <>
          <Hero>
            <FinancingGalleryContainer images={vonPollProperty?.images} />
            <HeroSlider
              showEditFinancing={!isEmptyState}
              showAddressBar
              address={
                isEmptyState
                  ? t('financing-portal-page.slider.address')
                  : location?.address?.placeName?.toString()
              }
              addressBarTitle={
                isEmptyState
                  ? t('financing-portal-page.slider.addressBarTitle')
                  : t(propertyType || '')
              }
            >
              <FinancingOverviewCard
                isLoading={
                  isLoading ||
                  isFinancingFetching ||
                  isUpdatingOverdueFinancingPropositions
                }
                isEmptyState={isEmptyState}
                financingReason={reason || ''}
                proposition={proposition?.propositions?.[0]}
                updatedAt={updatedAt}
              />
            </HeroSlider>
            <FinancingWidgets isEmptyState={isEmptyState} />
          </Hero>
        </>
      ) : (
        <MobileHeadContainer>
          <FinancingOverviewCard
            isLoading={
              isLoading ||
              isFinancingFetching ||
              isUpdatingOverdueFinancingPropositions
            }
            isEmptyState={isEmptyState}
            financingReason={reason || ''}
            proposition={proposition?.propositions?.[0]}
            updatedAt={updatedAt}
            onlineExposeLink={onlineExposeLink}
          />
          <AddressBar
            show
            address={
              isEmptyState
                ? t('financing-portal-page.slider.address')
                : location?.address?.placeName?.toString()
            }
            title={
              isEmptyState
                ? t('financing-portal-page.slider.addressBarTitle')
                : t(propertyType || '')
            }
          />

          <MobileMapContainer>
            <Mapbox isVisible />
          </MobileMapContainer>

          <Drawer
            drawerTopContent={<FinancingWidgets isEmptyState={isEmptyState} />}
            drawerBottomButtonFlex=""
            drawerBottomContainerPadding=""
            drawerBottomContent={
              isEmptyState ? undefined : <VariationHeaderActions isMobile />
            }
          />
        </MobileHeadContainer>
      )}
      <ContentWithPadding>
        <TabsFinancingPortal
          isMultipleFinancings={isMultipleFinancings}
          isMultipleVariants={isMultipleVariants}
        />
      </ContentWithPadding>
    </>
  );
};

export { FinancingPortalPage };
