import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const TitleContainer = styled.div`
  h3 {
    font-size: 24px;
  }
  > div {
    margin-bottom: 16px;
  }
`;

export const Text = styled.p`
  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 24px;
  margin-bottom: 24px;
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 8px;
  }
`;
