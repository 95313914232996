import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { ICommuteTimeCoordinates } from '../../property/redux/dynamicMapUtilsSlice';

const styles = {
  m15: {
    paint: {
      'fill-color': '#6CC139',
      'fill-opacity': 0.1,
    },
  },
  m15Border: {
    paint: {
      'line-color': '#6CC139',
      'line-width': 4,
    },
  },
  m30: {
    paint: {
      'fill-color': '#FDC403',
      'fill-opacity': 0.05,
    },
  },
  m30Border: {
    paint: {
      'line-color': '#FDC403',
      'line-width': 4,
    },
  },
  m45: {
    paint: {
      'fill-color': '#E25326',
      'fill-opacity': 0.1,
    },
  },
  m45Border: {
    paint: {
      'line-color': '#E25326',
      'line-width': 4,
    },
  },
};

interface IProps {
  commuteTimeCoordinates: ICommuteTimeCoordinates;
}

const CommuteTime: FC<IProps> = ({ commuteTimeCoordinates }) => {
  return (
    <>
      <Source
        id="commuteTime45"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                name: 'm45',
              },
              geometry: {
                type: 'MultiPolygon',
                coordinates: [[commuteTimeCoordinates.minutes45]],
              },
            },
          ],
        }}
      >
        <Layer id="layer45" {...styles.m45} type="fill" />
        <Layer id="layer45Border" {...styles.m45Border} type="line" />
      </Source>
      <Source
        id="commuteTime30"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                name: 'm30',
              },
              geometry: {
                type: 'MultiPolygon',
                coordinates: [[commuteTimeCoordinates.minutes30]],
              },
            },
          ],
        }}
      >
        <Layer id="layer30" {...styles.m30} type="fill" />
        <Layer id="layer30Border" {...styles.m30Border} type="line" />
      </Source>

      <Source
        id="commuteTime15"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                name: 'm15',
              },
              geometry: {
                type: 'MultiPolygon',
                coordinates: [[commuteTimeCoordinates.minutes15]],
              },
            },
          ],
        }}
      >
        <Layer id="layer15" {...styles.m15} type="fill" />
        <Layer id="layer15Border" {...styles.m15Border} type="line" />
      </Source>
    </>
  );
};

export { CommuteTime };
