import { forwardRef, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { CarouselItem, PictureCarousel } from './full-screen-gallery-styles';
import { Slider } from '../slider';
import { SuggestedPropertyAttachment } from '../../../../../../../generated';

interface IProps {
  activeSlide: number;
  images: ({ __typename?: 'SuggestedPropertyAttachment' | undefined } & Pick<
    SuggestedPropertyAttachment,
    'name' | 'alt'
  >)[];
  setActiveSlide: (index: number) => unknown;
}

const FullScreenGallery = forwardRef<unknown, IProps>(
  ({ activeSlide, images, setActiveSlide }, ref) => {
    const dispatch = useDispatch();
    const pictureCarouselRef = useRef<HTMLImageElement | null>(null);

    const toSlide = (index: number) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref.current?.swiper.slideTo(index);
      dispatch(setActiveSlide(index));
    };
    useEffect(() => {
      pictureCarouselRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [activeSlide]);

    return (
      <>
        <Slider
          ref={ref}
          images={images}
          activeSlide={activeSlide}
          setActiveSlide={setActiveSlide}
          isGallery
        />
        {images?.length > 0 && (
          <PictureCarousel>
            {images.map((item, i) => (
              <CarouselItem
                onClick={() => toSlide(i)}
                key={item.name}
                src={`https://erp.von-poll.com/import/images/${item.name}`}
                alt={item.alt}
                isActive={i === activeSlide}
                ref={i === activeSlide ? pictureCarouselRef : null}
              />
            ))}
          </PictureCarousel>
        )}
      </>
    );
  }
);

export { FullScreenGallery };
