import styled from 'styled-components';
import { device } from '../../../../../../../style/theme';
import { MainButton, SecondaryButton } from '../../../../ui/buttons';
import { hexToRGB } from '../../../../../utils/hex-to-rgb';
import { ParagraphText } from '../../../../ui/typography';

export const Container = styled.div`
  position: relative;
`;

export const Button = styled(SecondaryButton)<{ isActive?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  background-color: ${(props) =>
    props.isActive ? hexToRGB(props.theme.blue, 0.2) : props.theme.white};
  position: relative;

  ${(props) =>
    props.isActive &&
    `
    &::before {
    content: ' ';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    background-color: ${props.theme.green};
    border-radius: 50%;
    border: 2px solid ${props.theme.white};
    top: -4px;
    right: -4px;
  }
  `}
`;

export const ClearFilterButton = styled(SecondaryButton)`
  justify-content: center;
  height: 48px;
  text-transform: uppercase;
`;

export const ApplyFilterButton = styled(MainButton)`
  justify-content: center;
  height: 48px;
  margin-top: 12px;
  text-transform: uppercase;
`;

export const Form = styled.form<{ offset: { top: number; left: number } }>`
  display: flex;
  flex-direction: column;
  //gap: 12px;
  padding: 12px;
  position: fixed;
  background-color: ${(props) => props.theme.white};
  border: ${(props) => `1px solid ${props.theme.borderColor}`};
  border-radius: 3px;
  width: 360px;
  left: ${(props) => `${props.offset.left}px`};
  margin-top: 10px;
  top: ${(props) => `${props.offset.top}px`};
  z-index: 10;

  @media ${device.tablet} {
    width: 300px;
    //right: -40%;
  }

  &::before {
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid ${(props) => props.theme.borderColor};
    right: 161px;
    bottom: -7px;
    position: absolute;
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
    background-color: white;

    @media ${device.tablet} {
      //right: 9%;
      right: 50px;
    }
  }
`;

export const TooltipHeader = styled.h4`
  font-family: 'Source Serif Pro', serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${(props) => props.theme.blue};
  margin-bottom: 12px;
`;

export const TooltipDescription = styled(ParagraphText)`
  margin-bottom: 12px;
  text-align: center;
`;

export const TooltipActions = styled.div<{ direction: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) =>
    props.direction === 'row' ? 'center' : 'unset'};
  gap: ${(props) => (props.direction === 'row' ? '24px' : 0)};
`;

export const TooltipBack = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.blue};
  margin-bottom: 12px;
  cursor: pointer;
`;

export const TooltipAccept = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.ctaBlue};
  cursor: pointer;
`;
