import { FC, memo } from 'react';

import { Pin } from './pin';
import { IMapPin } from '../interfaces';

interface IProps {
  markers: IMapPin[];
}

const PinsBase: FC<IProps> = ({ markers }) => {
  return (
    <>
      {markers.map((pin, index) => (
        <Pin
          key={`marker-${pin.latitude}-${pin.longitude}-${index}-${pin?.category}-${pin?.marker}`}
          pin={pin}
        />
      ))}
    </>
  );
};

const Pins = memo(PinsBase);

export { Pins };
