import { memo, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  OurDestinations,
  useOurDestinationsQuery,
} from '../../../../generated';
import { device } from '../../../../style/theme';
import Icon from '../../../common/components/ui/icon';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { sortByOrderIndex } from '../../../landing/utils/sort-by-order-index';
import { ReactComponent as Arrow } from 'assets/svg/carousel-arrow-right.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

const BOX_WIDTH = 336;

const Container = styled.div`
  padding: 32px;
  border-radius: 3px;
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: rgba(255, 255, 255, 1);

  @media ${device.tablet} {
    padding: 16px;
    margin-bottom: 16px;
  }
`;

const Content = styled.div`
  width: ${BOX_WIDTH}px;
  margin: 0 auto;
`;

const Title = styled.h3`
  margin-bottom: 16px;
  color: #00305e;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 32px;
`;

const Description = styled.p`
  margin-bottom: 16px;
  opacity: 0.6;
  color: #002f5d;
  font-family: 'Roboto';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
`;

const CarouselBox = styled.div`
  overflow: hidden;
  border-radius: 3px;
  width: ${BOX_WIDTH}px;
  height: 224px;
`;

const CarouselItems = styled.div<{ $totalWidth: number }>`
  display: flex;
`;

const CarouselItem = styled.a<{ $firstTapDone: boolean }>`
  display: block;
  height: 100%;
  cursor: pointer;
  position: relative;
  ${(props) => !props.$firstTapDone && `pointer-events: none;`}
`;

const CarouselItemLayover = styled.div<{ isHovering?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: background-color 0.3s ease-in-out;
  background-color: transparent;

  ${(props) =>
    props.isHovering &&
    `
      background-color: rgba(0, 48, 94, 0.3);
    `}
`;

const CarouselItemsCount = styled.span`
  display: block;
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 8px;
  color: white;
  font-family: 'Roboto';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
`;

const CarouselItemName = styled.h5<{ isHovering?: boolean }>`
  color: white;
  font-family: 'Roboto';
  z-index: 2;
  bottom: 16px;
  left: 16px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 20px;
  position: absolute;
  transition: bottom 0.3s ease-in-out;
  ${(props) =>
    props.isHovering &&
    `
      bottom: 37px;
    `}
`;

const CarouselItemDescription = styled.p<{ isHovering?: boolean }>`
  color: white;
  font-family: 'Roboto';
  z-index: 2;
  bottom: 16px;
  left: 16px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) =>
    props.isHovering &&
    `
      opacity: 1;
    `}
`;

const CarouselImage = styled.img`
  width: ${BOX_WIDTH}px;
  height: 224px;
  object-fit: cover;
  display: block;
`;

const RangeInputBox = styled.div`
  margin: 8px 32px 0;
`;

const RangeInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: rgba(0, 48, 94, 0.05);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 8px;
    background: rgba(0, 48, 94, 0.3);
    cursor: pointer;
    border-radius: 6px;
  }

  &::-moz-range-thumb {
    width: 24px;
    height: 8px;
    background: rgba(0, 48, 94, 0.3);
    cursor: pointer;
    border-radius: 6px;
  }
`;

const OurDestinationsCardBase = () => {
  const intervalRef = useRef<number>();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [firstTapDone, setFirstTapDone] = useState<boolean>(false);

  const swiperRef = useRef<Swiper | null>(null);

  const isMobile = useIsMobileSize();

  const { data } = useOurDestinationsQuery();

  const ourDestinations: OurDestinations = useMemo(() => {
    const currentOurDestinations =
      data?.ourDestinations?.[0] || ({} as OurDestinations);
    return {
      ...currentOurDestinations,
      destinations: sortByOrderIndex(
        currentOurDestinations?.destinations || []
      ),
    };
  }, [data]);

  const startInterval = () => {
    clearInterval(intervalRef.current);

    intervalRef.current = window.setInterval(() => {
      if (swiperRef.current) {
        const value =
          activeIndex + 1 < ourDestinations.destinations.length
            ? activeIndex + 1
            : 0;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        swiperRef.current?.swiper?.slideTo(value);
      }
    }, 5000);
  };

  const onMouseEnter = () => {
    clearInterval(intervalRef.current);
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    startInterval();
    setIsHovering(false);
    setFirstTapDone(false);
  };

  const handleTap = () => {
    setFirstTapDone(true);
  };

  useEffect(() => {
    startInterval();

    return () => clearInterval(intervalRef.current);
  }, [activeIndex, ourDestinations.destinations.length]);

  const activeItem = ourDestinations.destinations[activeIndex];

  if (!activeItem) return null;

  return (
    <Container>
      <Content>
        <Title>{ourDestinations.title}</Title>
        <Description>{ourDestinations.description}</Description>
        <CarouselBox
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={handleTap}
        >
          <CarouselItems
            $totalWidth={BOX_WIDTH * ourDestinations.destinations.length}
          >
            <Swiper
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={swiperRef}
              onSlideChange={(e) => {
                setActiveIndex(e.activeIndex);
              }}
            >
              {ourDestinations.destinations.map(
                ({ imageAlt, imageUrl, name, url, description }, index) => {
                  return (
                    <SwiperSlide key={imageUrl}>
                      <CarouselItem
                        href={url || ''}
                        target={'_blank'}
                        $firstTapDone={isMobile ? firstTapDone : true}
                      >
                        <CarouselImage
                          key={`${imageUrl}--${name}`}
                          alt={imageAlt || ''}
                          src={imageUrl || ''}
                        />
                        <CarouselItemsCount>
                          {index + 1}/{ourDestinations.destinations.length}
                        </CarouselItemsCount>
                        <CarouselItemName isHovering={isHovering}>
                          {name}
                        </CarouselItemName>
                        <CarouselItemDescription isHovering={isHovering}>
                          {description}
                          <Icon width={16} height={11} icon={Arrow} />
                        </CarouselItemDescription>
                        <CarouselItemLayover isHovering={isHovering} />
                      </CarouselItem>
                    </SwiperSlide>
                  );
                }
              )}
            </Swiper>
          </CarouselItems>
        </CarouselBox>
        <RangeInputBox>
          <RangeInput
            onChange={(e) => {
              if (swiperRef.current) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                swiperRef.current?.swiper?.slideTo(+e.target.value);
              }
            }}
            type="range"
            min="0"
            max={ourDestinations.destinations.length - 1}
            value={activeIndex}
          />
        </RangeInputBox>
      </Content>
    </Container>
  );
};

const OurDestinationsCard = memo(OurDestinationsCardBase);

export { OurDestinationsCard };
