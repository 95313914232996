import {
  Condition,
  PropertyCode,
  PropertySubcode,
  Quality,
  RatingReason,
  TimeHorizont,
} from '../../../generated';

export interface IValuationForm {
  code: PropertyCode;
  subcode?: PropertySubcode;
  address: string;
  postCode: string;
  street: string;
  number: string;
  buildingYear: number;
  renovationYear?: number;
  floorNumber?: number;
  livingArea: number;
  landArea: number;
  numberOfUnits: number;
  condition: Condition;
  quality: Quality;
  numberOfRooms: number;
  numberOfBathrooms: number;
  numberOfIndoorParkingSpaces: number;
  numberOfOutdoorParkingSpaces: number;
  numberOfFloorsInBuilding?: number;
  balconyArea: number;
  ratingReason: RatingReason;
  otherRatingReason: string;
  energyLabel: string;
  timeHorizont: TimeHorizont;
  isNew?: boolean;
  hasPool?: boolean;
  hasSauna?: boolean;
  annualRentIncome?: number;
}

export enum WidgetPropertyType {
  APARTMENT = 'APARTMENT',
  HOUSE = 'HOUSE',
  MULTI_FAMILY_HOUSE = 'MULTI_FAMILY_HOUSE',
}

export interface ITemporaryUserInput {
  name: string;
  surname: string;
  email: string;
  phone: string;
}

export interface ITemporaryUserCredentials {
  username: string;
  accessToken: string;
  refreshToken: string;
  attributeName: string;
  firstName: string;
  lastName: string;
}

export enum EnergyEffiencyHubspot {
  a_plus_plus = 'A++',
  a_plus = 'A+',
  a = 'A',
  b = 'B',
  c = 'C',
  d = 'D',
  e = 'E',
  f = 'F',
  g = 'G',
  h = 'H',
}

export enum EnergyEffiencyHubspotSwitzerland {
  minergie = 'property-valuation.form.redesign.energy-label.options.swtz.minergy',
  minergie_p = 'property-valuation.form.redesign.energy-label.options.swtz.minergy-p',
  minergie_a = 'property-valuation.form.redesign.energy-label.options.swtz.minergy-a',
  minergie_eco = 'property-valuation.form.redesign.energy-label.options.swtz.minergy-eco',
  minergie_p_eco = 'property-valuation.form.redesign.energy-label.options.swtz.minergy-p-eco',
  minergie__a_eco = 'property-valuation.form.redesign.energy-label.options.swtz.minergy-a-eco',
}
