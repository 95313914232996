export const BUYER_INFO_FORM_KEYS = {
  SALUTATION: 'SALUTATION',
  NAME: 'name',
  SURNAME: 'surname',
  CITY: 'city',
  STREET: 'street',
  NUMBER: 'number',
  PHONE: 'phone',
  PHONE_PREFIX: 'phonePrefix',
  EMAIL: 'email',
  FINANCING_CONSULTATION: 'needFinancingConsultation',
  IS_PROPERTY_OWNER: 'isPropertyOwner',
  INTEND_TO_SELL_PROPERTY: 'intendToSellProperty',
};
