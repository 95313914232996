import { FC, memo } from 'react';
import styled from 'styled-components';
import { ReactComponent as AlertTriangle } from 'assets/streamline-light/interface-essential/alerts/alert-triangle.svg';

interface IProps {
  message: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 3px;
  border: 1px solid #dacebc;
  background-color: rgba(162, 133, 87, 0.2);
`;

const Icon = styled(AlertTriangle)`
  width: 16px;
  height: 16px;
  margin-right: 12px;
`;

const AlertWarningBase: FC<IProps> = ({ message }) => {
  return (
    <Container>
      <Icon />
      {message}
    </Container>
  );
};

const AlertWarning = memo(AlertWarningBase);

export { AlertWarning };
