import { RegisterOptions } from 'react-hook-form';
import { IBox } from '../../interface';
import { IOption } from '../common/select-v2/interface';

export enum ICustomFieldType {
  Number = 'number',
  Checkbox = 'checkbox',
  BoxRating = 'box_rating',
  Select = 'select',
  Switch = 'switch',
  Date = 'date',
  BooleanBoxes = 'boolean_boxes',
  FinancingAddress = 'financing_address',
}

export type TField = {
  name: string;
  containerTitle?: string;
  type?: string;
  customType?: ICustomFieldType;
  tooltipKey?: string;
  isTooltipKeyLeftSide?: boolean;
  label?: string;
  placeholder?: string;
  inputAdornment?: string;
  isDisabled?: boolean;
  isPercent?: boolean;
  isSeparate?: boolean;
  isWrapper?: boolean;
  defaultValue?: number | boolean;
  isWrap?: boolean;
  boxMaxWidth?: number;
  minWidthBox?: number;
  isRequired?: boolean;
  isCollapsible?: boolean;
  initHeight?: number;
  isHidden?: boolean;

  isValidateOnBlur?: boolean;
  validateNames?: string[];
  isDecimalNumber?: boolean;
  isBoxTabletColumn?: boolean;
  isTabletTwoBoxesColumn?: boolean;
  boxGap?: number;
  boxTabletGap?: number;
  rules?: RegisterOptions;
  boxes?: IBox[];
  usefulLink?: { label: string; link: string };
  options?: IOption[];
  onClick?: () => void;
  handleChangeProp?: (value: number | string) => void;
};
