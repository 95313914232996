import { FC, memo, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PropertyCode, ValuationType } from '../../../../../generated';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import { Headline3 } from '../../../../common/components/ui/typography';
import { useAppSelector } from '../../../../common/hooks';
import { FormError } from '../../../interface';
import { IValuationForm } from '../../interface';
import { getValuationStepTitle } from '../../../utils/getStepTitle';
import { Progress } from '../../../components/common/progress';
import { MobileBack } from '../containers/common/mobile-back';
import {
  FifthStep,
  FirstStep,
  FourthStep,
  MultiFamilyHouseThirdStep,
  SecondStep,
  ThirdStep,
} from './steps';

const Title = styled(Headline3)`
  margin: 0 auto 12px auto;
  text-align: center;
`;

const Form = styled.form<{ isiframe?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  //height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
`;

interface IProps {
  activeFormStep: number;
  stepsCount: number;
  submitForm?: boolean;
  errors?: FormError;
  mobileCompact?: boolean;

  regionalFirstStepNext?: () => void;
  regionalSecondStepBack?: () => void;
  onSubmit?: (formData: IValuationForm, ownerId?: string) => Promise<void>;
  lastStepCallback?(): void;
  toMobileCompact?: () => void;
}

const ValuationFormBase: FC<IProps> = ({
  activeFormStep,
  stepsCount,
  submitForm,
  onSubmit,
  lastStepCallback,
  regionalFirstStepNext,
  regionalSecondStepBack,
  errors,
  mobileCompact,
  toMobileCompact,
}) => {
  const methods = useForm<IValuationForm>();
  const propertyType = useAppSelector(
    (state) => state.valuationWizard.userInput?.code
  );
  const isMultiFamilyHouse = useMemo(
    () => propertyType === PropertyCode.MultiFamilyHouse,
    [propertyType]
  );
  const valuationType = useAppSelector(
    (state) => state.valuationWizard.valuationType
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const { t } = useTranslation();

  const mobileTitle =
    valuationType === ValuationType.Online
      ? t('landing-page.primary-button.online-valuation.label')
      : t('landing-page.primary-button.personal-valuation.label');

  return (
    <>
      {!isIframe && (
        <MobileBack
          toMobileCompact={toMobileCompact}
          mobileCompact={mobileCompact}
          title={mobileTitle}
        />
      )}
      <FormProvider {...methods}>
        <Form
          isiframe={isIframe}
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.keyCode === 13) e.preventDefault();
          }}
        >
          <Progress activeBullet={activeFormStep} bullets={stepsCount} />
          {errors?.message && (
            <GlobalError
              title={t(errors?.message?.split(':')[0].toLowerCase())}
            />
          )}
          <Title
            content={t(
              getValuationStepTitle(activeFormStep, isMultiFamilyHouse)
            )}
          />
          {activeFormStep === 0 && (
            <FirstStep regionalFirstStepNext={regionalFirstStepNext} />
          )}
          {activeFormStep === 1 && (
            <SecondStep regionalSecondStepBack={regionalSecondStepBack} />
          )}
          {activeFormStep === 2 && isMultiFamilyHouse && (
            <MultiFamilyHouseThirdStep />
          )}
          {activeFormStep === 2 && !isMultiFamilyHouse && <ThirdStep />}
          {activeFormStep === 3 && <FourthStep />}
          {activeFormStep === 4 && (
            <FifthStep
              submitForm={submitForm}
              onSubmit={onSubmit}
              lastStepCallback={lastStepCallback}
            />
          )}
        </Form>
      </FormProvider>
    </>
  );
};

const ValuationForm = memo(ValuationFormBase);

export { ValuationForm };
