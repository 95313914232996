import {
  LocationSearchProfileInput,
  PropertyCode,
  SearchProfileInput,
} from '../../../generated';

export const defaultSPInput = (
  propertyCode: PropertyCode,
  purchasePriceMax: number,
  location: LocationSearchProfileInput
): SearchProfileInput => {
  return {
    location: {
      address: {
        city: location.address.city,
        locality: location.address.locality,
        neighborhood: location.address.neighborhood,
        placeName: location.address.placeName,
        postCode: location.address.postCode,
      },
      coordinates: {
        ...location.coordinates,
      },
    },
    ...(propertyCode !== PropertyCode.Plot
      ? {
          livingAreaMax: 800,
          livingAreaMin: 10,
          numberOfBathroomsMax: 40,
          numberOfBathroomsMin: 1,
          numberOfRoomsMax: 40,
          numberOfRoomsMin: 1,
        }
      : {}),
    propertyCode,
    purchasePriceMax,
    purchasePriceMin: 1,
    radius: 50,
  };
};
