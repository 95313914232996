import styled from 'styled-components';
import {
  Headline2,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { device } from '../../../../style/theme';
import {
  MainButton,
  SecondaryButton,
} from '../../../common/components/ui/buttons';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';

export const Container = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.logoBlue};
  padding: 32px;
  z-index: 1;
  border-radius: 3px;
  box-shadow: 0 8px 30px 0 ${(props) => hexToRGB(props.theme.black, 0.09)};
  width: 688px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media ${device.tablet} {
    //width: calc(100% - 32px);
    width: 100%;
    padding: 16px;
    gap: 12px;
    margin-top: 48px;
  }
`;

export const Title = styled(Headline2)`
  text-align: center;
  color: ${(props) => props.theme.white};

  @media ${device.tablet} {
    font-size: 18px;
  }
`;

export const Text = styled(ParagraphText)`
  text-align: center;
  color: ${(props) => props.theme.white};

  @media ${device.tablet} {
    font-size: 12px;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  margin-top: 8px;
  justify-content: center;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const SendEmailBtn = styled(SecondaryButton)`
  width: 50%;
  justify-content: center;
  text-transform: uppercase;

  @media ${device.tablet} {
    padding: 10px 12px;
    width: 100%;
  }
`;

export const BookAppointmentBtn = styled(MainButton)`
  width: 50%;
  justify-content: center;

  @media ${device.tablet} {
    padding: 10px 12px;
    width: 100%;
  }
`;
