import { TFunctionResult } from 'i18next';
import { Container, Label, Value } from './box-info-styles';

interface IProps {
  type: string;
  value: string | number | TFunctionResult;
}

const BoxInfo = ({ type, value }: IProps): JSX.Element => {
  return (
    <Container>
      <Label content={type} />
      <Value content={`${value}`} />
    </Container>
  );
};

export { BoxInfo };
