import { ReactNode } from 'react';
import styled from 'styled-components';
import { device } from '../../../../../style/theme';

interface IFormGridWrapper {
  className?: string;
  children: ReactNode;
  wrapperRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
    | undefined;
}

const Wrapper = styled.div`
  display: block;
  width: 100%;
`;

const FormGridWrapper = ({
  wrapperRef,
  className,
  children,
}: IFormGridWrapper): JSX.Element => {
  return (
    <Wrapper className={className} ref={wrapperRef}>
      {children}
    </Wrapper>
  );
};

export { FormGridWrapper };
