import { FC, Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import {
  FinancingLandingPage as FinancingLP,
  FinancingLpSection,
  FinancingLpSectionType,
  FinancingType,
  useReadFinancingLandingPageQuery,
} from '../../../../generated';
import { siteMap } from '../../../../routes/site-map';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { locales } from '../../../localization/constants';
import useGetLocalization from '../../../localization/get-localization';
import { Appointments } from '../../components/appointment/appointment';
import { CTAButtons } from '../../components/financing-lp/cta-buttons/cta-buttons';
import CustomerAppraisal from '../../components/financing-lp/customer-appraisal/customer-appraisal';
import { Faqs } from '../../components/faqs/faqs';
import FinancingBenefit from '../../components/financing-lp/financing-benefit/financing-benefit';
import FinancingCalculator from '../../components/financing-lp/financing-calculator/financing-calculator';
import FinancingCertificate from '../../components/financing-lp/financing-certificate/financing-certificate';
import FinancingPartners from '../../components/financing-lp/financing-partners/financing-partners';
import InterestRatesTable from '../../components/financing-lp/interest-rates-table/interest-rates-table';
import { getAttributesFromMeta } from '../../utils/get-attributes-from-meta';
import { orderMobile, sortByOrderIndex } from '../../utils/sort-by-order-index';

import {
  ContentWithPadding,
  LoaderContainer,
} from './financing-landing-page-styles';
import { Card } from '../../../common/components/ui/card';
import { Hero } from '../../../common/components/ui/hero';
import { HeroSlider } from '../../../common/components/ui/hero-slider/hero-slider';
import { useGetFinancings } from '../../../financing/hooks/useGetFinancings';
import { LngRedirect } from '../../../localization/lng-redirect';
import { useOpenFinancing } from '../../../forms/form-financing/hooks/callback-hooks/useOpenFinancing';
import { IIframeType } from '../../../auth/interfaces';
import { FinancingNoModal } from '../../../forms/form-financing/components/containers/financing-containers/financing-no-modal/financing-no-modal';
import {
  setFinancingType,
  setSuggestionProperty,
  toggleSourceEmailNotification,
} from '../../../forms/form-financing/redux/financingSlice';
import { useCheckFinancingURLParams } from '../../../financing/hooks/useCheckFinancingURLParams';
import { toggleIsLoginModalOpen } from '../../../auth/redux/authSlice';

interface FinancingLandingPageResponse {
  data: { readFinancingLandingPage: FinancingLP };
  isLoading: boolean;
}

interface IProps {
  isFinancingWidget?: boolean;
  isFinancingCertificateWidget?: boolean;
}

const FinancingLandingPage: FC<IProps> = ({
  isFinancingWidget,
  isFinancingCertificateWidget,
}) => {
  const dispatch = useAppDispatch();
  const lang = useGetLocalization();
  const isMobile = useIsMobileSize();
  const {
    propertyId,
    source,
    financingIdParam,
    variantIndexParam,
    financingNotification,
  } = useCheckFinancingURLParams();

  const ownerId = useAppSelector((state) => state.auth.user?._id);

  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const iframeType = useAppSelector((state) => state.auth.iframeType);
  const isIframeFin =
    iframeType === IIframeType.WIDGET_FINANCING ||
    iframeType === IIframeType.WIDGET_FINANCING_NO_MODAL;
  const isIframeFinCertificate =
    iframeType === IIframeType.WIDGET_FINANCING_CERTIFICATE ||
    iframeType === IIframeType.WIDGET_FINANCING_CERTIFICATE_NO_MODAL;
  const isNoModal =
    iframeType === IIframeType.WIDGET_FINANCING_NO_MODAL ||
    iframeType === IIframeType.WIDGET_FINANCING_CERTIFICATE_NO_MODAL;

  const {
    financings,
    isLoading: financingsLoading,
    isFetching,
    isSuccess,
  } = useGetFinancings({
    ownerId,
  });
  const { data: lpData, isLoading } =
    useReadFinancingLandingPageQuery<FinancingLandingPageResponse>({
      locale: lang,
    });

  const { openFinancing } = useOpenFinancing({});

  useEffect(() => {
    if (propertyId) {
      openFinancing(FinancingType.Financing);
      dispatch(setSuggestionProperty({ propertyId: Number(propertyId) }));
    }
    if (isFinancingWidget || isIframeFin) {
      if (isNoModal) {
        dispatch(setFinancingType(FinancingType.Financing));
      } else {
        openFinancing(FinancingType.Financing);
      }
    }
    if (isFinancingCertificateWidget || isIframeFinCertificate) {
      if (isNoModal) {
        dispatch(setFinancingType(FinancingType.FinancingCertificate));
      } else {
        openFinancing(FinancingType.FinancingCertificate);
      }
    }
    if (source === 'email') {
      dispatch(toggleSourceEmailNotification(true));
    }
    if (financingIdParam || financingNotification) {
      dispatch(toggleIsLoginModalOpen(true));
    }
  }, []);

  if (isLoading || financingsLoading || isFetching) {
    return (
      <LoaderContainer>
        <GlobalLoader isAbsolute />
      </LoaderContainer>
    );
  }

  if (isSuccess && financings && financings?.length > 0 && !isIframe) {
    const id = financings[0]._id;
    const variantIndexParamString = variantIndexParam
      ? `variant-index=${variantIndexParam}&`
      : '';
    const financingNotificationString = financingNotification
      ? `financing-notification=${financingNotification}&`
      : '';

    return (
      <LngRedirect
        to={`${siteMap.FinancingPortalPage.pathWithoutParams}/${
          financingIdParam || id
        }?${variantIndexParamString}${financingNotificationString}`}
      />
    );
  }

  if (ownerId && isSuccess && financings?.length === 0 && !isIframe) {
    return (
      <LngRedirect to={`${siteMap.FinancingPortalPage.pathWithoutParams}`} />
    );
  }

  if (!lpData) {
    return <Redirect to={siteMap.OverviewLandingPage.path} />;
  }

  const { path } = siteMap.FinancingLandingPage;

  const {
    readFinancingLandingPage: {
      imageAlt,
      imageUrl,
      metaDescription,
      metaKeywords,
      sections,
      metaTitle,
      subtitle,
      text,
      title,
    },
  } = lpData;

  const sortedSections = isMobile
    ? sortByOrderIndex(orderMobile(sections ?? []))
    : sortByOrderIndex(sections ?? []);

  let metaTitle$ = metaTitle || '';
  let metaDescription$ = metaDescription || '';

  switch (true) {
    case isFinancingWidget:
      metaTitle$ = 'Immobilien Finanzierungsrechner - VON POLL FINANCE';
      metaDescription$ =
        'Nutzen Sie unseren Finanzierungsrechner udn Sie Ihr persönliches Sofortergebnis zu Ihrem maximalen Kaufbudget sowie einen ersten Überblick zu möglichen Finanzierungsvarianten.';
      break;
    case isFinancingCertificateWidget:
      metaTitle$ = 'Hypothekenzertifikat anfragen - VON POLL FINANCE';
      metaDescription$ =
        'Unter Einbeziehung des besten Zinses bescheinigen wir Ihnen Ihren Finanzierungsrahmen mit einem Zertifikat, das Eigentümern und Maklern Ihre Kaufkraft bestätigt.';
      break;
    default:
  }

  return (
    <>
      <Helmet>
        <title>{metaTitle$}</title>
        <meta name="title" content={metaTitle$} />
        <meta name="description" content={metaDescription$} />
        <meta property="og:title" content={metaTitle$} />
        <meta property="og:description" content={metaDescription$} />
        {imageUrl && <meta property="og:image" content={String(imageUrl)} />}
        <meta property="twitter:title" content={metaTitle$} />
        <meta property="twitter:description" content={metaDescription$} />
        {imageUrl && (
          <meta property="twitter:image" content={String(imageUrl)} />
        )}
        {metaKeywords?.map((item) => {
          const metaObject = getAttributesFromMeta(item);
          return (
            <meta
              key={metaObject.name}
              name={metaObject.name}
              content={metaObject.content}
            />
          );
        })}
        {locales.map(({ hrefLang, locale }) => (
          <link
            key={path}
            rel="alternate"
            href={`https://home.von-poll.com/${locale}${path}`}
            hrefLang={hrefLang}
          />
        ))}
        <link
          rel="canonical"
          href={`https://home.von-poll.com/${lang}${path}`}
        />
      </Helmet>
      {isIframe && isNoModal && <FinancingNoModal />}
      {!isIframe && (
        <>
          <Hero imgSrc={imageUrl ?? ''} imgAlt={imageAlt ?? ''} isWithoutMargin>
            <HeroSlider showAddressBar={false}>
              <Card
                mobileCentered
                header={subtitle ?? ''}
                titleFirstLine={title ?? ''}
                content={text ?? ''}
              />
            </HeroSlider>
            <CTAButtons />
          </Hero>
          <ContentWithPadding>
            {sortedSections?.map((section) => (
              <Fragment key={section.orderIndex}>
                {section.type === FinancingLpSectionType.FinancingPartner && (
                  <FinancingPartners section={section} />
                )}
                {section.type ===
                  FinancingLpSectionType.FinancingCalculator && (
                  <FinancingCalculator
                    section={section}
                    ourRepresentativeSection={sortedSections.find(
                      (sec: FinancingLpSection) =>
                        sec.type ===
                        FinancingLpSectionType.OurRepresentativeExample
                    )}
                  />
                )}
                {section.type === FinancingLpSectionType.InterestRatesTable && (
                  <InterestRatesTable
                    section={section}
                    ourRepresentativeSection={sortedSections.find(
                      (sec: FinancingLpSection) =>
                        sec.type ===
                        FinancingLpSectionType.OurRepresentativeExample
                    )}
                  />
                )}
                {section.type === FinancingLpSectionType.StepByStepBenefit && (
                  <FinancingBenefit section={section} />
                )}
                {section.type ===
                  FinancingLpSectionType.FinancingCertificate && (
                  <FinancingCertificate section={section} />
                )}
                {section.type === FinancingLpSectionType.CustomerAppraisal && (
                  <CustomerAppraisal section={section} />
                )}
                {section.type === FinancingLpSectionType.Faq && (
                  <Faqs left={!isMobile} section={section} />
                )}
                {section.type === FinancingLpSectionType.Appointment && (
                  <Appointments darkBlue rpSection={section} />
                )}
              </Fragment>
            ))}
          </ContentWithPadding>
        </>
      )}
    </>
  );
};

export { FinancingLandingPage };
