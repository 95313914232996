import * as S from './styles';
import { IShakeXProps } from './types/i-shake-props';

export const ShakeX = ({ shake, children }: IShakeXProps): JSX.Element => {
  return (
    <S.AnimatedShakeX className="pin-container" shake={shake}>
      {children}
    </S.AnimatedShakeX>
  );
};
