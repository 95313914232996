import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  View,
} from '@react-pdf/renderer';

import {
  Comparable,
  Coordinates,
  LocationScores,
  PropertyData,
  PropertyPoi,
  PropertyValuation,
} from '../../../generated';
import { abbrNum } from '../../common/utils/number-scientific-notation';
import { calculateSquareMeterPrice } from '../../property/utils/calculate-square-meter-price';
import {
  formatLastValuationDate,
  getLastQuarterDate,
} from '../../property/utils/date';
import { formatNumber } from '../../property/utils/format-number';
import { percentageDifference } from '../../property/utils/percentage-difference';
import { scoreNormalizer } from '../../common/utils/score-normalizer';
import {
  Address,
  Calculator,
  Footer,
  Header,
  IconBox,
  InfrastructureCard,
  MapWrapper,
  ParagraphText,
  ParagraphTextBlurred,
  PercentCard,
  PriceCard,
  SectionSubtitle,
  SectionTitle,
} from './components';
import { SimilarPropertyCard } from './components/similar-property-card';

Font.register({
  family: 'SourceSerifPro',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/sourceserifpro/v11/neIQzD-0qpwxpaWvjeD0X88SAOeaiXM.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasasahSs.ttf',
      fontWeight: 600,
    },
    {
      src: 'https://fonts.gstatic.com/s/sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasc8bhSs.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasfcZhSs.ttf',
      fontWeight: 900,
    },
  ],
});

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Me5Q.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlvAw.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmYUtvAw.ttf',
      fontWeight: 900,
    },
  ],
});

interface IProps {
  ValueCalculator: React.ElementType;
  images: { [key: string]: string };
  address: string;
  coordinates?: Coordinates;
  propertyData?: PropertyData;
  valuations: PropertyValuation[];
  currentValuationIndex: number;
  currentQuarterValuationIndex: number;
  valuationsLastSync: string;
  poi?: PropertyPoi;
  locationScores?: LocationScores | null;
  similarProperties?: Comparable[];
}

const styles = StyleSheet.create({
  page: {
    paddingTop: '8mm',
    // fontFamily: 'SourceSerifPro',
  },
  contentContainer: {
    paddingLeft: '15mm',
    paddingRight: '15mm',
    display: 'flex',
    alignItems: 'center',
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10mm',
    width: '100%',
    flexWrap: 'wrap',
  },
  chartContainer: {},
  chartImage: {
    width: '100%',
    height: 'auto',
    maxHeight: '100%',
  },
  threeChartsContainer: {
    paddingLeft: '25mm',
    paddingRight: '25mm',
    display: 'flex',
    alignItems: 'center',
  },
});

interface IStaticMapImage {
  lng: number;
  lat: number;
  pinUrl: string;
  zoom: string;
  width: string;
  height: string;
}

const token = process.env.REACT_APP_MAPBOX_KEY;

const getStaticMapImageSrc = ({
  lng,
  lat,
  pinUrl,
  zoom,
  width,
  height,
}: IStaticMapImage) => {
  return `https://api.mapbox.com/styles/v1/mapbox/light-v9/static/url-${encodeURIComponent(
    pinUrl
  )}(${lng},${lat})/${lng},${lat},${zoom},0/${width}x${height}@2x?access_token=${token}`;
};

// Create Document Component
const MyPropertyDocument = ({
  ValueCalculator,
  images,
  address,
  coordinates,
  propertyData,
  valuations,
  currentQuarterValuationIndex,
  currentValuationIndex,
  valuationsLastSync,
  poi,
  locationScores,
  similarProperties,
}: IProps): JSX.Element => {
  const percentDifference = percentageDifference(
    valuations?.[currentValuationIndex - 1]?.valuation?.salePrice ?? 0,
    valuations?.[currentValuationIndex]?.valuation.salePrice ?? 0,
    true
  );
  const calculateDifference = (startPrice: number, endPrice: number) => {
    const isIncreasing = startPrice < endPrice;
    const difference = percentageDifference(startPrice, endPrice, true);
    const value = Math.abs(startPrice - endPrice);

    return {
      isIncreasing,
      difference,
      value,
    };
  };

  const past3Years = calculateDifference(
    valuations?.[currentQuarterValuationIndex - 12].valuation.salePrice ?? 0,
    valuations?.[currentQuarterValuationIndex].valuation?.salePrice ?? 0
  );

  const pastYear = calculateDifference(
    valuations?.[currentQuarterValuationIndex - 4].valuation.salePrice ?? 0,
    valuations?.[currentQuarterValuationIndex].valuation?.salePrice ?? 0
  );

  const nextYear = calculateDifference(
    valuations?.[currentQuarterValuationIndex].valuation.salePrice ?? 0,
    valuations?.[currentQuarterValuationIndex + 5].valuation?.salePrice ?? 0
  );

  const isSimilarProperties =
    !!similarProperties && similarProperties?.length > 0;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header />
        <Address address={address} />
        <MapWrapper>
          <Image
            source={getStaticMapImageSrc({
              lng: coordinates?.longitude ?? 0,
              lat: coordinates?.latitude ?? 0,
              pinUrl: 'https://home.von-poll.com/templates/map-pin.png',
              zoom: '15',
              width: '960',
              height: '540',
            })}
          />
          <IconBox
            type={propertyData?.propertyType.code}
            yearOfConstruction={propertyData?.buildingYear ?? 0}
            bathroom={propertyData?.numberOfBathrooms ?? 0}
            livingSpace={propertyData?.livingArea ?? 0}
            landArea={propertyData?.landArea ?? 0}
            parking={propertyData?.numberOfOutdoorParkingSpaces ?? 0}
            garage={propertyData?.numberOfIndoorParkingSpaces ?? 0}
            roomsNumber={propertyData?.numberOfRooms ?? 0}
          />
        </MapWrapper>
        <View style={styles.contentContainer}>
          <SectionTitle text={'Immobilienwertrechner'} />
          <Calculator
            condition={propertyData?.condition ?? {}}
            quality={propertyData?.quality ?? {}}
          />
        </View>
        <Footer pageNumber={1} />
      </Page>
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={styles.contentContainer}>
          <SectionTitle text={'Marktpreis'} />
          <ParagraphText
            text={`Der Preis für diese Immobilie wird basierend auf unserer Analyse ähnlicher Immobilien auf ${formatNumber(
              valuations[currentValuationIndex].valuation.salePrice
            )} € geschätzt. Der Preis wird wahrscheinlich zwischen ${formatNumber(
              valuations[currentValuationIndex]?.valuation?.salePriceRange
                ?.lower
            )} € und ${formatNumber(
              valuations[currentValuationIndex]?.valuation?.salePriceRange
                ?.upper
            )} € liegen.`}
          />
          <ParagraphText text={'Konfidenzwert für die Bewertung: niedrig'} />
          <View style={styles.cardsContainer}>
            <PriceCard
              price={formatNumber(
                valuations[currentValuationIndex].valuation.salePrice
              )}
              percent={percentDifference}
              clarification={'Marketpreis'}
            />
            <PriceCard
              price={calculateSquareMeterPrice(
                propertyData?.livingArea ?? 0,
                valuations[currentValuationIndex]?.valuation?.salePrice ?? 0
              )}
              percent={percentDifference}
              clarification={'Preis / m²'}
            />
            <PriceCard
              lowerBound={
                valuations[currentValuationIndex]?.valuation?.salePriceRange
                  ?.lower ?? 0
              }
              upperBound={
                valuations[currentValuationIndex]?.valuation?.salePriceRange
                  ?.upper ?? 0
              }
              percent={percentDifference}
              clarification={'Preisspanne'}
            />
          </View>
          <ParagraphTextBlurred
            text={`Die Marktdaten wurden am ${formatLastValuationDate(
              valuationsLastSync
            )} aktualisiert. Alle Vergleiche sind mit ${getLastQuarterDate()}.`}
          />
          <SectionTitle text={'Wertentwicklung'} />
          <ParagraphText
            text={
              'Hier sehen Sie die historische und eine mögliche zukünftige Preisentwicklung (basierend auf Big Data Analytics).'
            }
          />

          {images.Wertenwicklung && (
            <View style={styles.chartContainer} wrap={false}>
              <Image style={styles.chartImage} src={images.Wertentwicklung} />
            </View>
          )}

          <View style={styles.cardsContainer}>
            <PercentCard
              price={abbrNum(past3Years.value, 2)}
              percent={past3Years.difference}
              clarification={'In den letzten 3 Jahren'}
            />
            <PercentCard
              price={abbrNum(pastYear.value, 2)}
              percent={pastYear.difference}
              clarification={'Seit\nletztemJahr'}
            />
            <PercentCard
              price={abbrNum(nextYear.value, 2)}
              percent={nextYear.difference}
              clarification={'Prognose für das nächste Jahr'}
            />
          </View>
          {images.Vermarktungsdauer && (
            <>
              <SectionTitle text={'Vermarktungsdauer'} />
              <View
                style={{ ...styles.chartContainer, maxWidth: '50%' }}
                wrap={false}
              >
                <Image
                  style={styles.chartImage}
                  src={images.Vermarktungsdauer}
                />
              </View>
            </>
          )}
        </View>
        <Footer pageNumber={2} />
      </Page>
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={styles.contentContainer} wrap={false}>
          <SectionTitle text={'Infrastruktur'} />
          {Object.keys(poi ?? {}).map((categoryKey) => (
            <InfrastructureCard
              key={`poi-${categoryKey}`}
              name={categoryKey}
              score={scoreNormalizer(
                (locationScores as LocationScores & { [key: string]: number })[
                  categoryKey
                ] ?? locationScores?.family
              )}
            />
          ))}
        </View>
        <Footer pageNumber={3} />
      </Page>
      {isSimilarProperties && (
        <Page size="A4" style={styles.page}>
          <Header />
          <View style={styles.contentContainer}>
            <SectionTitle text={'Angebote'} />
            <View style={styles.cardsContainer}>
              {similarProperties?.slice(0, 4).map((prop) => (
                <SimilarPropertyCard
                  similarProperty={prop}
                  key={prop.offerId}
                />
              ))}
            </View>
          </View>
          <Footer pageNumber={4} />
        </Page>
      )}
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={styles.contentContainer}>
          <SectionTitle text={'Sozioökonomie'} />
          <SectionSubtitle text={'Datenquelle: infas360'} />
          <ParagraphText
            text={
              'infas 360 ist eine Tochtergesellschaft der infas Holding AG. Ziel des Unternehmens ist die Erfassung, Analyse und Aufbereitung von Daten aus verschiedenen Quellen. Dazu gehören Geodaten, frei verfügbare Marktdaten, Kundendaten von Auftraggebern und Daten aus eigenen Marktforschungsprojekten.'
            }
          />

          {images.Einwohnerzahl && (
            <>
              <SectionTitle text={'Einwohnerzahl'} />
              <View style={styles.contentContainer} wrap={false}>
                <View style={styles.chartContainer}>
                  <Image style={styles.chartImage} src={images.Einwohnerzahl} />
                </View>
              </View>
            </>
          )}

          {images['Prognose der Einwohnerzahl'] && (
            <>
              <SectionTitle text={'Prognose der Einwohnerzahl'} />
              <View style={styles.contentContainer} wrap={false}>
                <View style={styles.chartContainer} wrap={false}>
                  <Image
                    style={styles.chartImage}
                    src={images['Prognose der Einwohnerzahl']}
                  />
                </View>
              </View>
            </>
          )}
        </View>
        <Footer pageNumber={isSimilarProperties ? 5 : 4} />
      </Page>

      <Page size="A4" style={styles.page}>
        <Header />
        <View style={styles.contentContainer}>
          {images.Alter && (
            <>
              <SectionTitle text={'Alter'} />
              <ParagraphTextBlurred text={'Jahr: 2018'} />
              <View style={styles.contentContainer}>
                <View style={styles.chartContainer}>
                  <Image style={styles.chartImage} src={images.Alter} />
                </View>
              </View>
            </>
          )}

          {images.Bildungsniveau && (
            <>
              <SectionTitle text={'Bildungsniveau'} />
              <ParagraphTextBlurred text={'Jahr: 2018'} />
              <View style={styles.contentContainer}>
                <View style={styles.chartContainer}>
                  <Image
                    style={styles.chartImage}
                    src={images.Bildungsniveau}
                  />
                </View>
              </View>
            </>
          )}
        </View>
        <Footer pageNumber={isSimilarProperties ? 6 : 5} />
      </Page>
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={styles.contentContainer}>
          {images.Haushaltsgrößenklassen && (
            <>
              <SectionTitle text={'Haushaltsgröße'} />
              <ParagraphTextBlurred text={'Jahr: 2018'} />
              <View style={styles.threeChartsContainer}>
                <View style={styles.chartContainer}>
                  <Image
                    style={styles.chartImage}
                    src={images.Haushaltsgrößenklassen}
                  />
                </View>
              </View>
            </>
          )}

          {images.Geburtenrate && (
            <>
              <SectionTitle text={'Geburtenrate'} />
              <View style={styles.threeChartsContainer}>
                <View style={styles.chartContainer}>
                  <Image style={styles.chartImage} src={images.Geburtenrate} />
                </View>
              </View>
            </>
          )}

          {images.Kriminalitätsrate && (
            <>
              <SectionTitle text={'Kriminalitätsrate'} />
              <View style={styles.threeChartsContainer}>
                <View style={styles.chartContainer}>
                  <Image
                    style={styles.chartImage}
                    src={images.Kriminalitätsrate}
                  />
                </View>
              </View>
            </>
          )}
        </View>
        <Footer pageNumber={isSimilarProperties ? 7 : 6} />
      </Page>
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={styles.contentContainer}>
          {images.Wanderungssaldo && (
            <>
              <SectionTitle text={'Wanderungssaldo'} />
              <View style={styles.threeChartsContainer}>
                <View style={styles.chartContainer}>
                  <Image
                    style={styles.chartImage}
                    src={images.Wanderungssaldo}
                  />
                </View>
              </View>
            </>
          )}

          {images['Anzahl Erwerbstätige'] && (
            <>
              <SectionTitle text={'Anzahl Erwerbstätige'} />
              <View style={styles.threeChartsContainer}>
                <View style={styles.chartContainer}>
                  <Image
                    style={styles.chartImage}
                    src={images['Anzahl Erwerbstätige']}
                  />
                </View>
              </View>
            </>
          )}

          {images.Verteilung && (
            <>
              <SectionTitle text={'Verteilung'} />
              <ParagraphTextBlurred text={'Jahr: 2018'} />
              <View style={styles.threeChartsContainer}>
                <View style={styles.chartContainer}>
                  <Image style={styles.chartImage} src={images.Verteilung} />
                </View>
              </View>
            </>
          )}
        </View>
        <Footer pageNumber={isSimilarProperties ? 8 : 7} />
      </Page>
    </Document>
  );
};

export { MyPropertyDocument };
