import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.logoBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  position: relative;
`;

export const Title = styled.h3`
  max-width: 238px;
  margin: 8px 0 8px 8px;
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ theme }) => theme.white};
`;
