export enum ENV {
  Production = 'Production',
  Development = 'Development',
}

export interface Config {
  NODE_ENV?: string;
  REACT_APP_URL?: string;
}

export const devConfig: Config = {
  NODE_ENV: ENV.Development,
  REACT_APP_URL: process.env.REACT_APP_URL_DEV,
};

export const prodConfig: Config = {
  NODE_ENV: ENV.Production,
  REACT_APP_URL: process.env.REACT_APP_URL_PROD,
};
