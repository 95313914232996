import {
  Condition,
  PropertyCode,
  PropertySubcode,
  Quality,
  RatingReason,
  TimeHorizont,
} from '../../../generated';

export interface IValuationForm {
  code: PropertyCode;
  subcode?: PropertySubcode;
  address: string;
  postCode: string;
  street: string;
  number: string;
  buildingYear: number;
  renovationYear?: number;
  floorNumber?: number;
  livingArea: number;
  landArea: number;
  numberOfUnits: number;
  condition: Condition;
  quality: Quality;
  numberOfRooms: number;
  numberOfBathrooms: number;
  numberOfIndoorParkingSpaces: number;
  numberOfOutdoorParkingSpaces: number;
  numberOfFloorsInBuilding?: number;
  balconyArea: number;
  ratingReason: RatingReason;
  otherRatingReason: string;
  energyLabel: string;
  timeHorizont: TimeHorizont;
  isNew?: boolean;
  hasPool?: boolean;
  hasSauna?: boolean;
  annualRentIncome?: number;
}

export enum PageType {
  property = 'property',
  landingPage = 'landing-page',
  sellerLandingPage = 'seller-landing-page',
  sellerProperties = 'seller-properties',
  propertyPortfolio = 'property-portfolio',
}
