import { useFinancingQuery } from '../../../services/graphql/enhanced';
import { useAppSelector } from '../../common/hooks';
import { FinancingType } from '../../../generated';

interface IProps {
  financingId: string;
  isSkip?: boolean;
}

const useGetFinancing = ({ financingId, isSkip }: IProps) => {
  const { variantIndex: variantIndexState } = useAppSelector(
    (state) => state.financing
  );
  const isEmptyState = financingId === undefined;

  const {
    financingVariants,
    financingData,
    vonPollProperty,
    marketingParametersData: marketingParameters,
    error,
    isLoading,
    isFetching,
    isSuccess,
    updatedAt,
    refetch,
    searchProfileId,
    onlineExposeId,
  } = useFinancingQuery(
    {
      id: financingId,
    },
    {
      skip: !!isSkip || isEmptyState,
      selectFromResult: ({
        data,
        isLoading: isLoadingData,
        isSuccess: isSuccessData,
        isFetching: isFetchingData,
        error: errorData,
      }) => {
        // const financingVariantsData =
        //   data?.financing?.financingVariants?.[0]?.financingVariantData;
        const updatedAtData = data?.financing?.updatedAt;

        const financing = data?.financing?.financingData;
        const vonPollPropertyData = data?.financing?.vonPollPropertyData;
        const marketingParametersData = data?.financing?.marketingParameters;

        return {
          financingVariants: data?.financing?.financingVariants,
          financingData: financing,
          vonPollProperty: vonPollPropertyData,
          marketingParametersData,
          error: errorData,
          isLoading: isLoadingData,
          isFetching: isFetchingData,
          isSuccess: isSuccessData,
          updatedAt: updatedAtData,
          searchProfileId: data?.financing?.searchProfileId,
          onlineExposeId: data?.financing?.onlineExposeId,
        };
      },
    }
  );
  const isCertificate =
    financingData?.financingType === FinancingType.FinancingCertificate;
  const variantIndex =
    variantIndexState && variantIndexState >= Number(financingVariants?.length)
      ? 0
      : variantIndexState;
  const financingVariant = financingVariants?.[variantIndex ?? 0];
  const propositions = financingVariant?.financingPropositions;
  const proposition = propositions?.find(
    (item) =>
      item.debitRateCommitment ===
      financingVariant?.financingVariantData?.debitRateCommitment
  );
  const propositionMaxPurchasePrice =
    financingVariant?.financingPropositionMaxPurchasePrice;
  const { personalFinancing, financingVariantData } = financingVariant || {};
  const { location } = financingVariantData || {};

  return {
    financingVariant,
    financingVariants,
    financingData,
    vonPollProperty,
    marketingParameters,
    personalFinancing,
    propositions,
    proposition,
    propositionMaxPurchasePrice,
    location,
    error,
    isLoading,
    isFetching,
    isSuccess,
    updatedAt,
    isCertificate,
    refetch,
    searchProfileId,
    onlineExposeId,
  };
};

export { useGetFinancing };
