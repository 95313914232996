export const getDateOfISOWeek = (week: number, year: number) => {
  const simple = new Date(year, 0, 1 + (week - 1) * 7);
  const dow = simple.getDay();
  const ISOWeekStart = simple;
  if (dow <= 4) {
    ISOWeekStart.setDate(simple.getDate() - simple.getDay() + 1);
  } else {
    ISOWeekStart.setDate(simple.getDate() + 8 - simple.getDay());
  }
  return ISOWeekStart;
};

export const getWeek = (current?: Date) => {
  const currentDate = current ?? new Date();
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor(
    (currentDate.valueOf() - startDate.valueOf()) / (24 * 60 * 60 * 1000)
  );

  const weekNumber = Math.ceil(days / 7);

  const startWeekDate = getDateOfISOWeek(weekNumber, currentDate.getFullYear());
  const date = new Date(startWeekDate);
  const endWeekDate = date.setDate(date.getDate() + 6);

  return {
    weekNumber,
    startWeekDate: startWeekDate.toISOString().split('T')[0],
    endWeekDate: new Date(endWeekDate).toISOString().split('T')[0],
  };
};
