import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ForgotPasswordForm } from '../../../../../../../../auth/components/forms/forgot-password-form';
import { LoginFormWizard } from '../../../../../../../../auth/components/forms/login-form-wizard';
import { GlobalLoader } from '../../../../../../../../common/components/ui/loaders/global-loader';
import { useAppSelector } from '../../../../../../../../common/hooks';
import { useLoginSP } from '../../../../../../hooks/useLoginSP';
import { useSubmitFormSP } from '../../../../../../hooks/useSubmitFormSP';
import { LoaderContainer } from '../../../../../containers/search-profile/search-profile-styles';

import { Container, Row, Title } from './login-styles';

const Login: FC<{ onAuthSuccess: () => void }> = ({ onAuthSuccess }) => {
  const { t } = useTranslation();
  const { onSubmitSPForm } = useSubmitFormSP();

  const isForgottenPasswordOpen = useAppSelector(
    (state) => state.auth.isForgottenPasswordOpen
  );
  const isLoginFormLoading = useAppSelector((state) => state.auth.isLoading);
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const [errors, setErrors] = useState<null | unknown>(null);

  const { onLoginSubmit } = useLoginSP({
    setErrors,
    onSuccess: onSubmitSPForm,
  });

  const title = isForgottenPasswordOpen
    ? t('modal.forgot-password.title')
    : t('modal.login.title');

  return (
    <Container>
      <Row>
        {isLoginFormLoading && (
          <LoaderContainer>
            <GlobalLoader />
          </LoaderContainer>
        )}
        <Title>{title}</Title>
        {isForgottenPasswordOpen ? (
          <ForgotPasswordForm isWizardForm />
        ) : (
          <LoginFormWizard
            onSubmit={(formData) => {
              onLoginSubmit(formData);
              onAuthSuccess();
            }}
            errors={errors}
            isButtonBottom={isIframe}
            isFullWidthButton={isIframe}
          />
        )}
      </Row>
    </Container>
  );
};

export default Login;
