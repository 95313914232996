import { PriceFilterRangeInput } from '../../../generated';

export const getSalePriceFilter = (
  salePrice: PriceFilterRangeInput
): PriceFilterRangeInput | undefined => {
  const { currency, min, max } = salePrice;
  const hasMinValue = typeof min === 'number' && min >= 100_000;
  const hasMaxValue = typeof max === 'number' && max <= 5_000_000;
  if (hasMinValue || hasMaxValue) {
    return {
      currency,
      ...(hasMinValue && { min }),
      ...(hasMaxValue && { max }),
    };
  }
  return undefined;
};

export const getFilterRangeArray = (
  min: number,
  max: number,
  numbers: number[]
): { min?: number; max?: number } | undefined => {
  const hasMin = numbers[0] > 1;
  const hasMax = numbers[1] < 9;

  if (hasMin || hasMax) {
    return {
      ...(hasMin && { min: numbers[0] }),
      ...(hasMax && { max: numbers[1] }),
    };
  }

  return undefined;
};

export const getFilterRangeObject = (
  minValue: number,
  maxValue: number,
  object: {
    min: number;
    max: number;
  }
): { min?: number; max?: number } | undefined => {
  const { min, max } = object;
  const hasMin = min > minValue;
  const hasMax = max < maxValue;

  if (hasMin || hasMax) {
    return {
      ...(hasMin && max !== 0 && { min }),
      ...(hasMax && max !== 0 && { max }),
    };
  }

  return undefined;
};
