import styled from 'styled-components';
import { device } from '../../../../../../style/theme';
import {
  Headline1,
  TitleSlogan,
} from '../../../../../common/components/ui/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 688px;
  margin-left: auto;
  @media ${device.tablet} {
    max-width: 100%;
  }
`;

export const SliderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 464px;
  &&& {
    .swiper-container {
      width: 100%;
      height: 100%;
      padding-bottom: 35px;

      &#main {
        background-color: ${(props) => props.theme.logoBlue};
      }

      &#controller {
        //position: absolute;
        height: 600px;
        left: -416px;
        bottom: 52px;
        max-width: 100% !important;
      }

      .swiper-slide {
        width: 100% !important;
        height: auto;
      }
    }

    .swiper-pagination {
      //bottom: 0;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      position: absolute;
      position: absolute;
      bottom: 5px;
    }

    .swiper-wrapper {
      padding-inline-start: 0;
    }

    .swiper-pagination-bullet {
      position: relative;
      width: 16px;
      height: 16px;
      padding: 3px;
      border: solid 1px rgba(0, 48, 94, 0.5);
      background: transparent;
      background-clip: content-box;
      margin: 0 20px;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background-color: ${(props) => props.theme.blue};
      }
      &:not(:last-of-type):after {
        content: '';
        border-bottom: 1px dashed rgba(0, 48, 94, 0.5);
        position: absolute;
        width: calc(300% - 8px);
        top: 50%;
        left: calc(100% + 4px);
        transform: translateY(-50%);
      }
    }
    .parallax-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 130%;
      height: 100%;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
    }
  }
  @media ${device.tablet} {
    height: auto;
    &&& {
      .swiper-container {
        overflow: hidden;
        &#main {
          order: 2;
        }

        &#controller {
          order: 1;
          position: relative;
          width: 100%;
          height: 100%;
          left: unset;
          bottom: unset;
          max-width: 100% !important;
        }
      }

      .swiper-wrapper {
      }

      .swiper-pagination-bullet {
        margin: 0 12px;
        &.swiper-pagination-bullet-active {
        }
        &:not(:last-of-type):after {
          width: calc(200% - 8px);
          left: calc(100% + 3px);
        }
      }
      .parallax-bg {
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  //padding: 32px 32px 32px 152px;
  @media ${device.tablet} {
    padding-bottom: 16px;
  }
`;

export const SliderHeader = styled(TitleSlogan)`
  opacity: 0.8;
  margin-bottom: 4px;
  position: relative;
`;

export const Headline = styled(Headline1)`
  position: relative;
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
  }
`;
