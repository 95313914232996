import styled, { css } from 'styled-components';
import { isNumber, isString } from 'lodash';
import {
  MainButton,
  SecondaryButton,
} from '../../../../../../common/components/ui/buttons';
import { hexToRGB } from '../../../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../../../style/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .input-wrapper {
    gap: 0;
  }

  .select,
  input {
    max-width: 192px;
    min-width: 192px;
  }

  @media ${device.tablet} {
    .select,
    input {
      max-width: 100%;
    }

    .input-wrapper {
      gap: 8px;
    }
  }
`;

export const EditVariantButton = styled(MainButton)`
  display: none;
  background-color: transparent;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.blue};
  border: 2px solid ${({ theme }) => theme.blue};
  margin-top: 24px !important;
  &:hover {
    background-color: ${(props) => hexToRGB(props.theme.ctaBlue, 0.2)};
    border-color: ${({ theme }) => theme.blue};
  }
`;
export const AddNewVariantButton = styled(MainButton)`
  display: none;
  background-color: transparent;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.ctaBlue};
  border: 2px solid transparent;
  margin-top: 12px !important;
  &:hover {
    background-color: transparent;
    border-color: ${({ theme }) => theme.ctaBlue};
  }
`;

export const EditBudgetBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const EditBudgetButton = styled(SecondaryButton)`
  padding: 2px;
  border-color: ${({ theme }) => theme.blue};
  color: ${({ theme }) => theme.blue};
  max-width: 193px;
  margin-top: 0 !important;
  text-align: center;
  justify-content: center;
`;

export const PaddingBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 16px;
`;

export const SwitchBox = styled.div`
  .label {
    color: ${({ theme }) => hexToRGB(theme.blue, 0.5)} !important;
  }
`;

export const InputWrapper = styled.div<{
  content: string;
  minHeight?: string;
  mt?: number;
  mb?: number;
}>`
  ${({ minHeight }) =>
    isString(minHeight) &&
    css`
      display: flex;
      align-items: center;
      min-height: ${minHeight};
    `}
  ${({ mt }) =>
    isNumber(mt) &&
    css`
      margin-top: ${mt}px;
    `}
  ${({ mb }) =>
    isNumber(mb) &&
    css`
      margin-bottom: ${mb}px;
    `}
  .label {
    &:before {
      display: flex;
      width: fit-content;
      content: '${({ content }) => content}';
      opacity: 1;
      color: ${({ theme }) => theme.blue};
      font-family: Roboto, sans-serif;
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: 1px;
      text-align: left;
      line-height: 14px;
      margin-right: 8px;
    }
    display: flex;
  }
`;
