import { CSSProperties, FC, ReactNode } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';

import { InputDate } from '../../../../common/components/form/input/input-date/input-date';

interface IProps {
  name: string;
  label?: string;
  labelWidth?: string;
  labelMR?: string;
  labelMobileMR?: string;
  isModalDesign?: boolean;
  customInput?: ReactNode;
  placeholder?: string;
  isPlaceholderCentered?: boolean;
  isBoldTooltipKeyIcon?: boolean;
  isTooltipKeyLeftSide?: boolean;
  isOnClickTooltip?: boolean;
  isValidateOnBlur?: boolean;
  withPortal?: boolean;
  adjustDateOnChange?: boolean;
  tooltipKey?: string;
  tooltipWidth?: number;
  locale?: string;
  calendarStartDay?: number;
  dateFormat?: string;
  defaultValue?: string | null | undefined;
  rules?: RegisterOptions;
  labelStyle?: CSSProperties;
  handleChangeProp?: (value: Date | null) => void;
}

const DatePicker: FC<IProps> = ({
  name,
  label,
  labelWidth,
  labelMR,
  labelMobileMR,
  customInput,
  isModalDesign,
  placeholder,
  tooltipKey,
  tooltipWidth,
  isPlaceholderCentered,
  isBoldTooltipKeyIcon,
  isTooltipKeyLeftSide,
  isOnClickTooltip,
  isValidateOnBlur,
  withPortal,
  adjustDateOnChange = true,
  locale = 'de',
  calendarStartDay = 1,
  dateFormat = 'dd/MM/yyyy',
  rules,
  defaultValue,
  labelStyle,
  handleChangeProp,
}) => {
  const {
    field: { value, onChange },
    formState: { errors },
  } = useController({
    name,
    rules,
    defaultValue: rules?.value ?? defaultValue,
  });

  return (
    <InputDate
      name={name}
      onChange={(date, event) => {
        if (handleChangeProp) {
          handleChangeProp(date);
        }
        onChange(date, event);
      }}
      selected={value}
      label={label}
      labelWidth={labelWidth}
      labelMR={labelMR}
      labelMobileMR={labelMobileMR}
      placeholder={placeholder}
      isPlaceholderCentered={isPlaceholderCentered}
      isBoldTooltipKeyIcon={isBoldTooltipKeyIcon}
      isTooltipKeyLeftSide={isTooltipKeyLeftSide}
      isOnClickTooltip={isOnClickTooltip}
      isValidateOnBlur={isValidateOnBlur}
      labelStyle={labelStyle}
      isModalDesign={isModalDesign}
      tooltipKey={tooltipKey}
      tooltipWidth={tooltipWidth}
      customInput={customInput}
      withPortal={withPortal}
      adjustDateOnChange={adjustDateOnChange}
      locale={locale}
      calendarStartDay={calendarStartDay}
      dateFormat={dateFormat}
      errors={errors}
    />
  );
};

export { DatePicker };
