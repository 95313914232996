import {
  FC,
  memo,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ThemeContext } from 'styled-components';
import { ReactComponent as CheckCircle } from 'assets/streamline-light/interface-essential/form-validation/check-circle-1.svg';
import {
  Container,
  Label,
  Title,
  Form,
  SubmitButton,
  RadioButtonsWrapper,
  CheckboxesWrapper,
  DeclinePropertyMessage,
  SuccessMessage,
  SuccessMessageTitle,
  SuccessMessageText,
} from './contact-form-styles';
import { RadioInput } from '../../../../../common/components/form/input/input-radio';
import { Checkbox } from '../../../../../common/components/form';
import { InputTextArea } from '../../../../../common/components/form/input/input-text-area';
import { hexToRGB } from '../../../../../common/utils/hex-to-rgb';

import { useSendExposeContactFormMutation } from '../../../../../../generated';
import { useAppSelector } from '../../../../../common/hooks';
import { GlobalError } from '../../../../../common/components/form/error/global-error';
import {
  FormColumn,
  FormRow,
} from '../../../../../common/components/form/form-grid';
import Icon from '../../../../../common/components/ui/icon';

interface IContactForm {
  needMoreInfo: boolean;
  needViewingAppointment: boolean;
  needCallback: boolean;
  message: string;
}

interface IProps {
  contactFormRef?: RefObject<HTMLDivElement>;
}

const ContactFormBase: FC<IProps> = ({ contactFormRef }) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const methods = useForm<IContactForm>({ mode: 'onSubmit' });

  const [isShowInterestFormOpen, setShowInterestFormOpen] =
    useState<boolean>(true);

  const [sendContactForm, { error: errors, isLoading }] =
    useSendExposeContactFormMutation();
  const propertyId = useAppSelector(
    (state) => state.exposeDetails.externalPropertyId
  );

  const successMessage = useMemo(() => {
    if (isShowInterestFormOpen) {
      return {
        title:
          'expose-overlay.contact-form.show-interest.success-message.title',
        text: 'expose-overlay.contact-form.show-interest.success-message.text',
      };
    }

    return {
      title:
        'expose-overlay.contact-form.decline-property.success-message.title',
      text: 'expose-overlay.contact-form.decline-property.success-message.text',
    };
  }, [isShowInterestFormOpen]);

  const values = methods.watch();

  useEffect(() => {
    if (
      methods.formState.errors.needMoreInfo?.message &&
      (values.needCallback ||
        values.needViewingAppointment ||
        values.needMoreInfo)
    ) {
      methods.clearErrors('needMoreInfo');
    }
  }, [
    methods,
    values.needCallback,
    values.needMoreInfo,
    values.needViewingAppointment,
  ]);

  const onSubmit: SubmitHandler<IContactForm> = useCallback(
    async (formData) => {
      try {
        const { needMoreInfo, needViewingAppointment, needCallback, message } =
          formData;
        const checkboxesNotSelected =
          !needMoreInfo && !needViewingAppointment && !needCallback;
        if (isShowInterestFormOpen && (checkboxesNotSelected || !message)) {
          if (checkboxesNotSelected) {
            methods.setError('needMoreInfo', {
              message: 'register.input.error.required',
            });
          }

          if (!message) {
            methods.setError('message', {
              message: 'register.input.error.required',
            });
          }

          return;
        }

        await sendContactForm({
          propertyId,
          contactForm: {
            isCustomerInterested: isShowInterestFormOpen,
            needMoreInfo,
            needViewingAppointment,
            needCallback,
            message,
          },
        }).unwrap();
        setShowSuccessMessage(true);
      } catch (error) {
        console.error(error);
      }
    },
    [sendContactForm, propertyId, isShowInterestFormOpen, methods]
  );

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {showSuccessMessage && (
          <SuccessMessage>
            <Icon
              icon={CheckCircle}
              color={theme.green}
              width={40}
              height={40}
              style={{ marginBottom: '12px' }}
            />
            <SuccessMessageTitle content={t(successMessage.title)} />
            <SuccessMessageText content={t(successMessage.text)} />
          </SuccessMessage>
        )}
        {!showSuccessMessage && (
          <Container ref={contactFormRef}>
            {errors?.message && (
              <GlobalError
                title={t(errors?.message?.split(':')[0].toLowerCase())}
              />
            )}
            <Title content={t('expose-overlay.contact-form.title')} />
            <RadioButtonsWrapper>
              <RadioInput
                label={t('expose-overlay.contact-form.show-interest.label')}
                value={'value'}
                checked={isShowInterestFormOpen}
                onChange={() => {
                  methods.reset();
                  setShowInterestFormOpen(true);
                }}
              />
              <RadioInput
                label={t('expose-overlay.contact-form.decline.label')}
                value={'value'}
                checked={!isShowInterestFormOpen}
                onChange={() => {
                  methods.reset();
                  setShowInterestFormOpen(false);
                }}
              />
            </RadioButtonsWrapper>
            <Label
              content={t(
                isShowInterestFormOpen
                  ? 'expose-overlay.contact-form.show-interest.options.label'
                  : 'expose-overlay.contact-form.decline-property.info-message.title'
              )}
            />
            {isShowInterestFormOpen ? (
              <CheckboxesWrapper>
                <FormRow>
                  <FormColumn flex="0 0 33%">
                    <Checkbox
                      name={'needMoreInfo'}
                      label={t(
                        'expose-overlay.contact-form.show-interest.options.more-info'
                      )}
                    />
                  </FormColumn>
                  <FormColumn flex="0 0 33%">
                    <Checkbox
                      name={'needViewingAppointment'}
                      label={t(
                        'expose-overlay.contact-form.show-interest.options.viewing-appointment'
                      )}
                      isWithoutErrors
                    />
                  </FormColumn>
                  <FormColumn flex="0 0 33%">
                    <Checkbox
                      name={'needCallback'}
                      label={t(
                        'expose-overlay.contact-form.show-interest.options.callback'
                      )}
                    />
                  </FormColumn>
                </FormRow>
              </CheckboxesWrapper>
            ) : (
              <DeclinePropertyMessage
                content={t(
                  'expose-overlay.contact-form.decline-property.info-message.text'
                )}
              />
            )}

            <InputTextArea
              name={'message'}
              label={t(
                'expose-overlay.contact-form.show-interest.message.label'
              )}
              rules={{
                required: false,
              }}
              height={170}
              noTranslate
              style={{ lineHeight: 2 }}
              labelStyle={{
                whiteSpace: 'unset',
                color: hexToRGB(theme.blue, 0.8),
              }}
              placeholder={t(
                'expose-overlay.contact-form.show-interest.message.label'
              )}
            />
            <SubmitButton
              loader
              isLoading={isLoading}
              label={t(
                isShowInterestFormOpen
                  ? 'expose-overlay.contact-form.show-interest.submit'
                  : 'expose-overlay.contact-form.decline-property.submit'
              )}
              type={'submit'}
            />
          </Container>
        )}
      </Form>
    </FormProvider>
  );
};

const ContactForm = memo(ContactFormBase);

export { ContactForm };
