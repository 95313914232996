import { SellingSteps } from '../../../generated';
import { SellingProgress } from '../interfaces';
import { sellingSteps } from '../constants';

export const buildSellingProgress = (
  completedSellingSteps?: SellingSteps[] | null
): SellingProgress => {
  let steps = [{ title: '', isCompleted: false }];
  let progress = 0;

  if (completedSellingSteps && Array.isArray(completedSellingSteps)) {
    steps = sellingSteps.map((currentStep) => ({
      title: currentStep.title,
      isCompleted: completedSellingSteps.includes(currentStep.name),
    }));

    const progressPerStep = 100 / sellingSteps.length;

    progress = completedSellingSteps.length * progressPerStep;
  }

  return {
    steps,
    progress,
  };
};
