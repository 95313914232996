import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const Container = styled.div`
  max-width: 1104px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 32px;
  border-radius: 3px;
  border: solid 1px #d9d9d9;

  @media ${device.tablet} {
    margin: 16px;
    padding: 0;
    border: none;
  }
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
`;

export const HeaderTitle = styled.h3<{ count: string | number }>`
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
  }

  :after {
    font-family: Roboto;
    display: flex;
    content: ${(props) => `'${props.count}'`};
    width: 23px;
    height: 20px;
    border-radius: 10px;
    background-color: #4e73f5;
    font-size: 10px;
    font-weight: 900;
    line-height: 1.6;
    letter-spacing: 0.5px;
    color: #fff;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
  }
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SortLabel = styled.span`
  font-family: Roboto;
  margin-right: 12px;
  opacity: 0.5;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  white-space: nowrap;
`;
