import styled from 'styled-components';
import React from 'react';

import { hexToRGB } from '../../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../../style/theme';

export const Container = styled(React.Fragment)<{ isHeightBigger: boolean }>`
  .slider-image {
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
  height: 48px;
  opacity: 0.9;
  border-radius: 3px;
  background-color: ${hexToRGB('#e5e9ec', 0.9)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
`;

export const PictureCarousel = styled.div`
  display: flex;
  gap: 12px;
  overflow-x: auto;
  max-height: 72px;
  width: 1000px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const CarouselItem = styled.img<{
  isActive: boolean;
  activeColor?: string;
  height?: string;
}>`
  cursor: pointer;
  height: ${({ height }) => height || '100%'};
  object-fit: cover;
  border: solid 4px
    ${({ isActive, activeColor }) =>
      isActive ? activeColor || '#fff' : 'transparent'};
  width: 108px;
`;
