import { FC } from 'react';
import {
  SkeletonBox,
  SkeletonItem,
} from '../../../common/components/ui/skeleton/skeleton-styles';

interface IProps {
  isLoading?: boolean;
  width: string;
  mb?: number;
  mt?: number;
  ml?: number;
}

const FinancingSkeleton: FC<IProps> = ({
  isLoading,
  width,
  mt,
  mb,
  ml,
  children,
}) => {
  return (
    <>
      {isLoading ? (
        <SkeletonBox width={width}>
          <SkeletonItem width={width} mt={mt} mb={mb} ml={ml} />
        </SkeletonBox>
      ) : (
        children
      )}
    </>
  );
};

export { FinancingSkeleton };
