import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { device } from '../../../../../style/theme';
import { Switch } from '../../ui/switch';
import { Headline3, ParagraphTitle } from '../../ui/typography';

interface IMainHeader {
  title: string;
  location: string;
  icon: unknown;
  rating: number;
  color: string;
  isShrinked: boolean;
}

interface IContentHeader {
  locationType: string;
  isChecked: boolean;
  onChange: (checked: boolean, category: string) => void;
}

interface IContentBody {
  name: string;
  distance: number;
}

interface ISimpleRatingDots {
  rating: number;
  color: string;
}

export const Container = styled.div`
  &&& {
    padding: 32px;
    border-radius: 3px;
    border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
    background-color: #fff;
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    .outer-accordion {
      border: none !important;
      background: transparent;

      .rc-collapse-item {
        border: none !important;
        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
        .outer-header {
          cursor: pointer;
          padding: 0;
          margin: 0;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: 1px;
          color: ${(props) => props.theme.blue};

          .icon {
            margin-left: auto;
            order: 2;
            path {
              stroke-width: 1.5px;
            }
          }
        }
        &.rc-collapse-item-active {
          .outer-header {
            // Active
          }
        }
        .rc-collapse-content {
          padding: 0;
          margin: 16px 0 0 0;
          .rc-collapse-content-box {
            margin: 0;
            @media ${device.tablet} {
            }

            .inner-accordion {
              border-radius: 3px;
              border: solid 1px ${(props) => props.theme.lightBlue};
              background-color: ${(props) => props.theme.greyBackground};
              margin-bottom: 12px;
              .rc-collapse-item {
                .inner-header {
                  padding: 12px;
                  .icon {
                    margin-left: auto;
                    order: 2;
                  }
                }
                .rc-collapse-content {
                  padding: 0 12px 12px 12px;
                  margin: 0;
                  border-radius: 0;
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const FlexRowWrapper = styled.div`
  display: flex;
`;

const FlexRowHalfLeftWrapper = styled.div`
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  overflow: hidden;
  justify-content: flex-start;
`;

const FlexRowHalfRightWrapper = styled.div`
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  overflow: hidden;
  justify-content: flex-end;
`;

const FlexRowWrapperCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexColumnWrapperCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const PaddingWrapper = styled.div<{ padding: string }>`
  padding: ${(props) => props.padding || 0};
`;

const MarginWrapper = styled.div<{ margin: string }>`
  margin: ${(props) => props.margin || 0};
`;

export const HalfSizedWrapper = styled.div<{ isShrinked?: boolean }>`
  min-width: 0;
  margin: ${(props) => (props.isShrinked ? '0' : '0 6px')};
  flex: ${(props) => (props.isShrinked ? '1 0 100%' : '0 0 calc(50% - 12px)')};
  @media ${device.tablet} {
    flex: 1 0 50%;
  }
`;

const MainTitle = styled(Headline3)<{ isShrinked?: boolean }>`
  line-height: 1.33;
  font-size: 24px;
  ${(props) =>
    props.isShrinked &&
    `
    max-width: 10rem;
    overflow: hidden;
  `}
`;

const MainLocation = styled(ParagraphTitle)<{
  fontWeight?: number | string;
  margin?: string;
}>`
  font-size: 10px;
  opacity: 0.5;
  font-weight: ${(props) => props.fontWeight || 'normal'};
  text-transform: uppercase;
  ${({ margin }) => margin && `margin: ${margin};`};
`;

const MainRating = styled(Headline3)<{ color?: string }>`
  color: ${(props) => props.color};
`;

const ContentTitle = styled(ParagraphTitle)`
  font-size: 12px;
  font-weight: bold;
  opacity: 0.8;
  line-height: 1.67;
`;

const ContentText = styled(ParagraphTitle)`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ContentWrapper = styled.div<{ isShrinked?: boolean }>`
  width: ${(props) => (props.isShrinked ? '100%' : 'calc(100% + 12px)')};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: ${(props) =>
    props.isShrinked ? '0 0 -12px 0' : '0 -6px -12px -6px'};
  @media ${device.tablet} {
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
    align-items: stretch;
  }
`;

const RatingDotsWrapper = styled.div`
  border-radius: 50%;
  display: flex;
  width: 10px;
  height: 10px;
  overflow: hidden;
`;

const RatingDotsWrapperBorder = styled.div<{
  percentage: number;
  backgroundColor: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  overflow: hidden;
  background: #d9d9d9;
  background: ${(props) =>
    `linear-gradient(90deg, ${props.backgroundColor} calc(${props.percentage}%), #d9d9d9 calc(${props.percentage}%))`};
  padding: 0.5px;
  margin: 0 1px;
`;

const LeftDotPart = styled.div<{ percentage: number; backgroundColor: string }>`
  height: 100%;
  flex: 1 1 ${(props) => props.percentage}%;
  background-color: ${(props) => props.backgroundColor || '#fff'};
`;

const RightDotPart = styled.div<{
  percentage: number;
  backgroundColor: string;
}>`
  height: 100%;
  flex: 0 0 ${(props) => props.percentage}%;
  background-color: ${(props) => props.backgroundColor || '#fff'};
`;

export const getPercent = (value: number, index: number): number => {
  switch (true) {
    case value >= index:
      return 100;
    case value > index - 1 && value < index:
      return Math.round((value % 1) * 100);
    default:
      return 0;
  }
};

const SimpleRatingDots = ({
  rating,
  color,
}: ISimpleRatingDots): JSX.Element => {
  return (
    <>
      {[...Array.from({ length: 5 })].map((_, index: number) => {
        return (
          <RatingDotsWrapperBorder
            key={`rd-${index}`}
            percentage={getPercent(rating, index + 1)}
            backgroundColor={color}
          >
            <RatingDotsWrapper>
              <LeftDotPart
                percentage={getPercent(rating, index + 1)}
                backgroundColor={color}
              />
              <RightDotPart
                percentage={100 - getPercent(rating, index + 1)}
                backgroundColor={'#fff'}
              />
            </RatingDotsWrapper>
          </RatingDotsWrapperBorder>
        );
      })}
    </>
  );
};

export const MainHeader = ({
  title,
  location,
  icon,
  rating,
  color,
  isShrinked,
}: IMainHeader): JSX.Element => (
  <SpaceBetweenWrapper>
    <FlexRowWrapper>
      <FlexRowWrapperCentered>
        <MarginWrapper margin={'0 12px 0 0'}>
          <img src={icon as string} alt={title} width={28} height={32} />
        </MarginWrapper>
      </FlexRowWrapperCentered>
      <FlexColumnWrapper>
        <MainTitle isShrinked={isShrinked} content={title} />
        <FlexRowWrapper>
          <MainLocation content={'Lage: '} margin={'0 4px 0 0'} />
          <MainLocation fontWeight={900} content={`  ${location}`} />
        </FlexRowWrapper>
      </FlexColumnWrapper>
    </FlexRowWrapper>
    <PaddingWrapper padding={'0 12px 0 0'}>
      <FlexColumnWrapperCentered>
        <MainRating color={color} content={rating?.toFixed(1)} />
        <FlexRowWrapper>
          <SimpleRatingDots rating={rating} color={color} />
        </FlexRowWrapper>
      </FlexColumnWrapperCentered>
    </PaddingWrapper>
  </SpaceBetweenWrapper>
);

export const ContentHeader = ({
  locationType,
  isChecked,
  onChange,
}: IContentHeader): JSX.Element => {
  const { t } = useTranslation();
  const onSwitchChange = (checked: boolean) => {
    onChange(checked, locationType);
  };
  return (
    <FlexRowWrapperCentered onClick={(e) => e.stopPropagation()}>
      <Switch isChecked={isChecked} onChange={onSwitchChange} />
      <ContentTitle
        content={t(`infrastructure.titles.${locationType.toLowerCase()}`)}
      />
    </FlexRowWrapperCentered>
  );
};

export const ContentBody = ({ name, distance }: IContentBody): JSX.Element => {
  return (
    <SpaceBetweenWrapper>
      <FlexRowHalfLeftWrapper>
        <ContentText content={name} />
      </FlexRowHalfLeftWrapper>
      <FlexRowHalfRightWrapper>
        <ContentTitle content={`${distance} m`} />
      </FlexRowHalfRightWrapper>
    </SpaceBetweenWrapper>
  );
};
