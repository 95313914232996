import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IExperiment } from '../interfaces';

interface InitialState {
  savedExperiments: IExperiment[];
}

const initialState: InitialState = {
  savedExperiments: [],
};

const experimentsSlice = createSlice({
  name: 'experiments',
  initialState,
  reducers: {
    setExperiments(state, action: PayloadAction<IExperiment[]>) {
      state.savedExperiments = action.payload;
    },
  },
});

export const { setExperiments } = experimentsSlice.actions;

const persistConfig = {
  key: `vonpoll.${experimentsSlice.name}`,
  version: 1,
  storage,
};

const experiments = persistReducer(persistConfig, experimentsSlice.reducer);

export default experiments;
