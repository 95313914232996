import { CSSProperties, FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as MailQuestionMark } from 'assets/svg/mail-question-mark.svg';

import { Modal } from '../../../../common/components/modal';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import Icon from '../../../../common/components/ui/icon';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { CloseButton } from '../../../../common/components/modal/close-button';
import {
  setResendVerificationAccountEmail,
  toggleIsResendVerificationAccountModalOpen,
} from '../../../redux/authSlice';
import { useResendConfirmationCodeMutation } from '../../../../../generated';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  position: relative;
  height: 100%;
`;

const Title = styled.h3`
  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Source Serif Pro, sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 32px;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.blue};
  opacity: 1;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 16px;
  max-width: 480px;
  margin-bottom: 8px;
`;

const Suggestion = styled.span`
  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 16px;
`;

const SendButton = styled.span`
  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 1px;
  text-align: left;
  line-height: 20px;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
`;

const desktopWrapperStyle = {
  maxWidth: 585,
  padding: 0,
};
const mobileWrapperStyle = {
  width: '100%',
  height: '100%',
  padding: 0,
  overflow: 'overlay',
};

const ResendVerificationAccountModal: FC = () => {
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const [resendConfirmationCode] = useResendConfirmationCodeMutation();
  const isOpen = useAppSelector(
    (state) => state.auth.isResendVerificationAccountModalOpen
  );
  const resendVerificationAccountEmail = useAppSelector(
    (state) => state.auth.resendVerificationAccountEmail
  );
  const handleClose = () =>
    dispatch(toggleIsResendVerificationAccountModalOpen(false));

  const onSubmit = () => {
    if (resendVerificationAccountEmail) {
      resendConfirmationCode({
        email: resendVerificationAccountEmail,
      });
      dispatch(setResendVerificationAccountEmail());
    }
    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      wrapperStyles={isMobileSize ? mobileWrapperStyle : desktopWrapperStyle}
    >
      <Container>
        <CloseButton
          bgColor="transparent"
          color={theme.blue}
          position="right"
          handleClose={handleClose}
        />
        <Icon
          icon={MailQuestionMark}
          style={{ marginBottom: 8 }}
          color=""
          width={48}
          height={46}
        />
        <Title>{t('resend-verification-account.modal.title')}</Title>
        <Text>{t('resend-verification-account.modal.text')}</Text>
        <p>
          <Suggestion>
            {t('resend-verification-account.modal.suggestion')}
          </Suggestion>{' '}
          <SendButton onClick={onSubmit}>
            {t('resend-verification-account.modal.link')}
          </SendButton>
        </p>
      </Container>
    </Modal>
  );
};

export { ResendVerificationAccountModal };
