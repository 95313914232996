import styled from 'styled-components';
import { hexToRGB } from '../../../../utils/hex-to-rgb';
import { device } from '../../../../../../style/theme';

export const InputWrapper = styled.div<{ isModalDesign?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: ${({ isModalDesign }) => (isModalDesign ? 0 : 12)}px;
  position: relative;

  @media ${device.tablet} {
    flex-direction: ${({ isModalDesign }) =>
      isModalDesign ? 'column' : 'row'};
    align-items: ${({ isModalDesign }) =>
      isModalDesign ? 'flex-start' : 'center'};
  }
`;

export const InfoWrapper = styled.div<{ isTooltipKeyLeftSide?: boolean }>`
  display: flex;
  flex-grow: 1;
  justify-content: ${({ isTooltipKeyLeftSide }) =>
    isTooltipKeyLeftSide ? 'flex-start' : 'space-between'};
  align-items: center;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const CalendarIcon = styled.img`
  cursor: pointer;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 11px;
  right: 12px;

  @media ${device.tablet} {
    top: 12px;
  }
`;

export const Label = styled.p<{
  isModalDesign?: boolean;
  labelWidth?: string;
  labelMR?: string;
  labelMobileMR?: string;
}>`
  margin: 0;
  font-family: 'Roboto';
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  opacity: ${({ isModalDesign }) => (isModalDesign ? 0.7 : 0.5)};
  display: inline-flex;
  width: ${({ labelWidth }) => labelWidth ?? '200px'};
  ${({ labelMR }) => labelMR && `margin-right: ${labelMR};`}
  justify-content: start;
  ${({ isModalDesign, theme }) => isModalDesign && `color: ${theme.blue};`}

  @media ${device.tablet} {
    width: ${({ labelWidth }) => labelWidth ?? '85px'};
    ${({ labelMobileMR }) => labelMobileMR && `margin-right: ${labelMobileMR};`}
  }
`;

export const DatePickerContainer = styled.div<{ isModalDesign?: boolean }>`
  width: 100%;
  position: relative;
  max-width: ${({ isModalDesign }) => (isModalDesign ? '280px' : '100%')};

  @media ${device.tablet} {
    max-width: 100%;
  }

  &&& {
    .react-datepicker {
      border: none;
    }
    .react-datepicker__triangle::before {
      display: none;
    }
    .react-datepicker__triangle::after {
      display: none;
    }
    .react-datepicker__month-container {
      background-color: ${() => hexToRGB('#4e73f5', 0.05)};

      .react-datepicker__day {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .react-datepicker__day--keyboard-selected {
        background-color: ${(props) => props.theme.white};
      }

      .react-datepicker__month-text--keyboard-selected {
        background-color: ${(props) => props.theme.white};
      }
      .react-datepicker__quarter-text--keyboard-selected {
        background-color: ${(props) => props.theme.white};
      }
      .react-datepicker__year-text--keyboard-selected {
        background-color: ${(props) => props.theme.white};
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        background-color: ${(props) => props.theme.white};
      }

      .day {
        display: flex;
        flex-direction: column;
        opacity: 1;

        .day-number {
          width: 32px;
          height: 24px;
          font-family: 'Source Serif Pro';
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: 0.35px;
          color: ${(props) => props.theme.blue};
        }
        .week-name {
          width: 32px;
          height: 16px;
          opacity: 0.6;
          font-family: 'Roboto';
          font-size: 10px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 0.5px;
          text-align: center;
          color: ${(props) => props.theme.blue};
        }
      }

      .react-datepicker__month {
        margin: 0;
        text-align: unset;
        padding: 16px;
      }

      .react-datepicker__header {
        height: 40px;
        padding: 0;
      }

      .react-datepicker__week {
        display: flex;
        gap: 8px;
      }

      .picker-header {
        height: 40px;
        display: flex;
        align-items: center;
        background-color: ${() => hexToRGB('#e5e9ec', 0.9)};
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        justify-content: space-between;
        padding: 0 40px;

        .month-name {
          font-family: 'Roboto';
          font-size: 12px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: 0.5px;
          color: ${(props) => props.theme.blue};
        }
      }
      .react-datepicker__day-names {
        display: none;
      }
    }
`;

export const Input = styled.input<{
  isModalDesign?: boolean;
  isPlaceholderCentered?: boolean;
  $hasError?: boolean;
}>`
  flex: 1;
  width: 100%;
  height: 40px;
  padding: 12px;
  border-radius: 3px;
  background-color: ${({ theme, isModalDesign, $hasError }) =>
    isModalDesign
      ? theme.white
      : $hasError
      ? theme.paleRed
      : theme.greyBackground};
  outline: none;
  border: ${({ isModalDesign, theme }) =>
    isModalDesign ? `1px solid ${theme.borderColor}` : '1px solid transparent'};
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  text-align: ${({ isPlaceholderCentered }) =>
    isPlaceholderCentered ? 'center' : 'left'};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Edge */
  ::-ms-reveal {
    display: none !important;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
  &::placeholder {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  }
  &:hover {
    cursor: pointer;
    border-color: ${({ theme, isModalDesign }) =>
      isModalDesign ? theme.grey : theme.borderFocus};
    background-color: ${({ theme, isModalDesign }) =>
      isModalDesign ? theme.white : theme.greyBackground};
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.white};
    border-color: ${(props) => props.theme.borderFocus};
    color: ${(props) => props.theme.blue};
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
    }
  }
  &:disabled {
    opacity: 0.45;
    border: none;
    cursor: default;
    pointer-events: none;
  }
  &:read-only {
    opacity: 0.45;
    border: none;
    cursor: default;
    pointer-events: none;
  }
`;
