import { useMemo } from 'react';
import { ISearchProfileForm } from '../interface';
import { removeNonNumeric } from '../../utils/number-format';

const useValidateSecondStepFields = ({
  livingAreaMin,
  livingAreaMax,
  landAreaMin,
  landAreaMax,
  numberOfRoomsMin,
  numberOfRoomsMax,
  numberOfBathroomsMin,
  numberOfBathroomsMax,
  purchasePriceMin,
  purchasePriceMax,
}: Partial<ISearchProfileForm>): boolean => {
  const purchasePriceMinInt = Number(removeNonNumeric(purchasePriceMin));
  const purchasePriceMaxInt = Number(removeNonNumeric(purchasePriceMax));
  const isNotZero = useMemo(
    () =>
      livingAreaMin !== 0 ||
      livingAreaMax !== 0 ||
      landAreaMin !== 0 ||
      landAreaMax !== 0 ||
      numberOfRoomsMin !== 0 ||
      numberOfRoomsMax !== 0 ||
      numberOfBathroomsMin !== 0 ||
      numberOfBathroomsMax !== 0 ||
      purchasePriceMinInt !== 0 ||
      purchasePriceMaxInt !== 0,
    [
      livingAreaMin,
      livingAreaMax,
      landAreaMin,
      landAreaMax,
      numberOfRoomsMin,
      numberOfRoomsMax,
      numberOfBathroomsMin,
      numberOfBathroomsMax,
      purchasePriceMinInt,
      purchasePriceMaxInt,
    ]
  );

  const isLivingAreaMaxLower = useMemo(
    () => livingAreaMin && livingAreaMax && livingAreaMax <= livingAreaMin,
    [livingAreaMin, livingAreaMax]
  );

  const isLandAreaMaxLower = useMemo(
    () => landAreaMin && landAreaMax && landAreaMax <= landAreaMin,
    [landAreaMin, landAreaMax]
  );

  const isNumberOfRoomsMaxLower = useMemo(
    () =>
      numberOfRoomsMin &&
      numberOfRoomsMax &&
      numberOfRoomsMax <= numberOfRoomsMin,
    [numberOfRoomsMin, numberOfRoomsMax]
  );

  const isNumberOfBathroomsMaxLower = useMemo(
    () =>
      numberOfBathroomsMin &&
      numberOfBathroomsMax &&
      numberOfBathroomsMax <= numberOfBathroomsMin,
    [numberOfBathroomsMin, numberOfBathroomsMax]
  );

  const isPurchasePriceMaxLower = useMemo(
    () =>
      purchasePriceMaxInt &&
      purchasePriceMinInt &&
      purchasePriceMaxInt <= purchasePriceMinInt,
    [purchasePriceMaxInt, purchasePriceMinInt]
  );

  return (
    isNotZero &&
    !isLivingAreaMaxLower &&
    !isLandAreaMaxLower &&
    !isNumberOfRoomsMaxLower &&
    !isNumberOfBathroomsMaxLower &&
    !isPurchasePriceMaxLower
  );
};

export { useValidateSecondStepFields };
