import styled, { css } from 'styled-components';
import { device } from '../../../../../../../../../style/theme';

export const Container = styled.div`
  border-radius: 3px;
  background-color: #fff;
  overflow: hidden;
  border: solid 1px #a8a8a7;
`;

export const SectionContainer = styled.div<{
  isHideMarker?: boolean;
}>`
  padding: 12px;
  display: flex;
  flex-direction: column;
  &:nth-child(odd) {
    background-color: white;
  }
  &:nth-child(even) {
    background-color: #f2f5f7;
  }
  & div {
    display: flex;
    justify-content: space-between;
  }
  & > ul {
    margin-top: 16px;
    margin-left: ${({ isHideMarker }) => (isHideMarker ? 0 : 17)}px;
    ${({ isHideMarker }) => isHideMarker && 'list-style-type: none;'}
    & > li:first-child {
      margin-bottom: 8px;
    }
  }

  .info {
    margin-left: 8px;
  }
`;

export const Text = styled.div<{
  isBold?: boolean;
  isLoading?: boolean;
  isNoWrap?: boolean;
  isLabel?: boolean;
}>`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  align-items: center;
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
  font-stretch: normal;
  ${(props) => props.isLoading && 'flex: 1;'}
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  &:last-child {
    white-space: nowrap;
    margin-left: 8px;
  }
  color: ${(props) => props.theme.blue};
  ${(props) =>
    props.isLabel &&
    `
    justify-content: flex-start !important;
  `}
`;

export const Label = styled.span`
  flex-basis: 0;
`;

export const Info = styled.div<{ tooltipWidth?: number }>`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  cursor: pointer;
  .icon {
    > svg,
    > svg > path {
      stroke: ${(props) => props.theme.blue};
    }
  }

  ${({ tooltipWidth }) =>
    tooltipWidth &&
    css`
      .tooltip {
        width: ${tooltipWidth}px;
      }
    `}

  @media ${device.tablet} {
    & .tooltip {
      left: 5px !important;
    }
  }
`;

export const TooltipContainer = styled.div`
  max-width: 316px;
  width: 100%;
  padding: 0;
  text-align: center;
`;

export const TooltipParagraph = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;
