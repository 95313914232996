import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FC, useContext } from 'react';

import Icon from '../../../common/components/ui/icon';
import { ReactComponent as EmptyStateIcon } from '../../../../assets/svg/empty-state.svg';
import {
  Headline2,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { device } from '../../../../style/theme';
import { MainButton } from '../../../common/components/ui/buttons';

const Container = styled.div`
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 24px;

  @media ${device.tablet} {
    padding: 12px;
  }
`;

const Title = styled(Headline2)`
  font-size: 20px;
  line-height: 1.6;
  margin-top: 12px;
  text-align: center;
`;

const Description = styled(ParagraphText)`
  text-align: center;
  margin: 8px 0 12px 0;
`;

export enum Variations {
  OFFER_EXTEND_RADIUS,
  OFFER_UPDATE_SP,
}

interface IProps {
  onClick: () => void;
  variation?: Variations;
}

const EmptyStatePlaceholder: FC<IProps> = ({
  onClick,
  variation = Variations.OFFER_EXTEND_RADIUS,
}) => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <Container>
      <Icon
        icon={EmptyStateIcon}
        color={themeContext.blue}
        style={{ width: '40px', height: '40px' }}
      />
      {variation === Variations.OFFER_EXTEND_RADIUS && (
        <>
          <Title
            content={t(
              'search-profile.suggested-properties-tab.empty-list.header'
            )}
          />
          <Description
            content={t(
              'search-profile.suggested-properties-tab.empty-list.text'
            )}
          />
          <MainButton
            label={t(
              'search-profile.suggested-properties-tab.button.expand-search-radius'
            )}
            onClick={onClick}
          />
        </>
      )}

      {variation === Variations.OFFER_UPDATE_SP && (
        <>
          <Description
            content={t(
              'search-profile.suggested-properties-tab.text.update-profile-details'
            )}
          />

          <MainButton
            label={t(
              'search-profile.suggested-properties-tab.button.update-profile-details'
            )}
            onClick={onClick}
          />
        </>
      )}
    </Container>
  );
};

export default EmptyStatePlaceholder;
