import styled, { css } from 'styled-components';
import { isNumber } from 'lodash';
import { hexToRGB } from '../../../../../../../common/utils/hex-to-rgb';

export const Container = styled.div`
  width: fit-content;
  border-radius: 3px;
  background-color: #fff;
  //border-top: solid 1px #a8a8a7;
  border-left: solid 1px #a8a8a7;
  //border-bottom: solid 1px #a8a8a7;
`;

export const SectionContainer = styled.div<{
  isHideMarker?: boolean;
}>`
  display: flex;
  flex-direction: column;
  &:nth-child(odd) {
    background-color: white;
  }
  &:nth-child(even) {
    background-color: #f2f5f7;
  }
  & div {
    display: flex;
  }
`;

export const Text = styled.div<{
  isBold?: boolean;
  isWillBeDeleted?: boolean;
  deleteId?: number;
  isFirstSection?: boolean;
  isLastSection?: boolean;
}>`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  align-items: center;
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme, isWillBeDeleted }) =>
    isWillBeDeleted ? hexToRGB(theme.blue, 0.5) : theme.blue};
  border-right: 1px solid ${({ theme }) => theme.grey};

  width: 150px;
  max-width: 150px;
  justify-content: flex-end !important;
  padding: 12px;

  &:first-child {
    max-width: 364px;
    width: 364px;
    min-width: 364px;
    justify-content: flex-start !important;
  }
  &:last-child {
    border-right: solid 1px #a8a8a7;
  }

  ${({ deleteId }) =>
    isNumber(deleteId) &&
    css`
      &:nth-child(${deleteId + 2}),
      &:nth-child(${deleteId + 1}) {
        border-right: 1px dashed ${({ theme }) => theme.grey};
      }
    `}

  ${({ isLastSection, isWillBeDeleted }) =>
    isLastSection &&
    css`
      border-bottom: ${isWillBeDeleted ? 'dashed' : 'solid'} 1px #a8a8a7;
    `}

  ${({ isFirstSection, isWillBeDeleted }) =>
    isFirstSection &&
    css`
      border-top: ${isWillBeDeleted ? 'dashed' : 'solid'} 1px #a8a8a7;
    `}
`;
