export enum FileTypes {
  DOC = '.doc',
  DOCX = '.docx',
  PDF = '.pdf',
  TXT = '.txt',
  JPG = '.jpg',
  PNG = '.png',
  GIF = '.gif',
  CAD = '.cad',
  BMP = '.bmp',
}

export const documentTypes = [
  FileTypes.DOC,
  FileTypes.DOCX,
  FileTypes.PDF,
  FileTypes.TXT,
  FileTypes.JPG,
  FileTypes.PNG,
];

export const imageTypes = [FileTypes.JPG, FileTypes.PNG, FileTypes.GIF];

export const propertyPlanTypes = [
  FileTypes.JPG,
  FileTypes.PNG,
  FileTypes.BMP,
  FileTypes.GIF,
];
