import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isEqual } from 'lodash';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Container,
  FormsContainer,
  // ShadowBottom,
  // ShadowTop,
} from './financing-edit-forms-styles';

import { FinancingDataEditForm } from './financing-data-edit-form/financing-data-edit-form';
import {
  FinancingReason,
  FinancingVariantData,
  PersonalFinancingEntity,
  Propositions,
} from '../../../../../../generated';
import { IFinancingForm } from '../../../interface';
import { FinancingCalculationFormButtons } from './financing-calculation-form-buttons';
import { ModalVariantName } from '../../../../../financing/components/tabs/tabs-content/calculator-tab/modals/modal-variant-name/modal-variant-name';
import { toggleEditFinancingVariantName } from '../../../redux/financingSlice';
import { useAppSelector } from '../../../../../common/hooks';
import { useDirtyForm } from '../../../hooks/callback-hooks/calculation-form/useDirtyForm';
import { ExpandableContent } from '../../../../../common/components/ui/expandable-content';
import { FinancingPropertyEditForm } from './financing-property-edit-form/financing-property-edit-form';
import { FinancingPersonalEditForm } from './financing-personal-edit-form/financing-personal-edit-form';
// import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';

interface IProps {
  finVarData: FinancingVariantData;
  personalFin: PersonalFinancingEntity;
  proposition?: Propositions;
  transferTax?: number | null;
  isLoading: boolean;
  isMortgageCertificate?: boolean;

  onFindPropositions: SubmitHandler<IFinancingForm>;
  onSubmitFinVarForm: (formData: IFinancingForm, variantIndex?: number) => void;
}

type FinancingEditSections =
  | 'financing'
  | 'property'
  | 'personal'
  | 'reason'
  | '';

const FinancingEditForms: FC<IProps> = ({
  proposition,
  transferTax,
  finVarData,
  personalFin,
  isLoading,
  isMortgageCertificate,
  onSubmitFinVarForm,
  onFindPropositions,
}) => {
  // const isMobileSize = useIsMobileSize();
  // const containerRef = useRef<HTMLDivElement>(null);
  // const containerFormsRef = useRef<HTMLDivElement>(null);
  // const shadowTopRef = useRef<HTMLDivElement>(null);
  // const shadowBottomRef = useRef<HTMLDivElement>(null);

  const dataFormRef = useRef<HTMLDivElement>(null);
  const propertyFormRef = useRef<HTMLDivElement>(null);
  const personalFormRef = useRef<HTMLDivElement>(null);
  const reasonFormRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { variantIndex, editWidget } = useAppSelector(
    (state) => state.financing
  );
  const { calculateFinancingPropositions, isLoadingUpdating } = editWidget;
  const isOwnConstruction =
    finVarData.reason === FinancingReason.OwnConstructionProject;

  const landTax =
    (isOwnConstruction ? finVarData.landPrice : finVarData.purchasePrice) &&
    transferTax
      ? Math.round(
          (Number(transferTax) /
            Number(
              isOwnConstruction
                ? finVarData.landPrice
                : finVarData.purchasePrice
            )) *
            1000
        ) / 10
      : 0;

  const defaultValues = {
    ...finVarData,
    ...finVarData.location.address,
    ...finVarData.location.coordinates,
    ...personalFin,
    ...(finVarData.payoutDate
      ? { payoutDate: new Date(finVarData.payoutDate) }
      : {}),
    region:
      finVarData.location.address.region || finVarData.location.address.city,
    name: '',
    landTax,
  };

  const [prevFinVarData, setPrevFinVarData] = useState(defaultValues);

  const methods = useForm<IFinancingForm>({
    defaultValues,
  });

  // set new data when change a financing variant
  useEffect(() => {
    if (!isEqual(defaultValues, prevFinVarData)) {
      setPrevFinVarData(defaultValues);
      methods.reset(defaultValues);
    }
  }, [finVarData]);

  const { handleChangeDirty } = useDirtyForm();

  // buttons callbacks

  // user can submit form and add new variant
  const handleAddVariant = useCallback(
    (formData: IFinancingForm) => {
      onSubmitFinVarForm(formData);
      methods.setValue('name', '');
      dispatch(toggleEditFinancingVariantName(false));
    },
    [dispatch, methods, onSubmitFinVarForm]
  );

  // user can submit form and update variant
  const handleUpdateVariant = useCallback(
    (formData: IFinancingForm) => {
      onSubmitFinVarForm({ ...formData, name: finVarData.name }, variantIndex);
    },
    [finVarData.name, onSubmitFinVarForm, variantIndex]
  );

  // add logic to hide "save variant button" after changing values
  const handleFindPropositions = (formData: IFinancingForm) => {
    handleChangeDirty({ state: false });
    onFindPropositions(formData);
  };

  const onFindErrors = (e: MouseEvent) => {
    const element = e.target as HTMLElement;
    if (element.id === 'find-propositions-button') {
      const dataFormErrors =
        dataFormRef.current?.getElementsByClassName('field-error');
      const propertyFormErrors =
        propertyFormRef.current?.getElementsByClassName('field-error');
      const personalFormErrors =
        personalFormRef.current?.getElementsByClassName('field-error');
      const isErrors =
        !!dataFormErrors?.length ||
        !!propertyFormErrors?.length ||
        !!personalFormErrors?.length;

      if (isErrors) {
        const isOpenedDataForm = dataFormRef.current?.getElementsByClassName(
          'expandable-content-opened'
        )?.length;
        const isOpenedPropertyForm =
          propertyFormRef.current?.getElementsByClassName(
            'expandable-content-opened'
          )?.length;
        const isOpenedPersonalForm =
          personalFormRef.current?.getElementsByClassName(
            'expandable-content-opened'
          )?.length;
        const isOpenedReasonForm =
          reasonFormRef.current?.getElementsByClassName(
            'expandable-content-opened'
          )?.length;

        const dataFormExpandableContent =
          dataFormRef.current?.getElementsByClassName(
            'expandable-content'
          )[0] as HTMLElement;
        if (
          (dataFormErrors?.length && !isOpenedDataForm) ||
          (!dataFormErrors?.length && isOpenedDataForm)
        ) {
          dataFormExpandableContent.click();
        }

        const propertyFormExpandableContent =
          propertyFormRef.current?.getElementsByClassName(
            'expandable-content'
          )[0] as HTMLElement;
        if (
          (propertyFormErrors?.length && !isOpenedPropertyForm) ||
          (!propertyFormErrors?.length && isOpenedPropertyForm)
        ) {
          propertyFormExpandableContent.click();
        }

        const personalFormExpandableContent =
          personalFormRef.current?.getElementsByClassName(
            'expandable-content'
          )[0] as HTMLElement;
        if (
          (personalFormErrors?.length && !isOpenedPersonalForm) ||
          (!personalFormErrors?.length && isOpenedPersonalForm)
        ) {
          personalFormExpandableContent.click();
        }

        if (isOpenedReasonForm) {
          const expandableContent =
            reasonFormRef.current?.getElementsByClassName(
              'expandable-content'
            )[0] as HTMLElement;
          expandableContent.click();
        }
        window.scrollTo({ top: 800, behavior: 'smooth' });
        // containerFormsRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  // after submit form, user can set a variant name
  const handleOpenName = useCallback(() => {
    dispatch(toggleEditFinancingVariantName(true));

    // financing mobile edit widget logic
    const editWidgetContainer = document.querySelector(
      '#modal-root'
    ) as HTMLButtonElement;
    editWidgetContainer.style.display = '';
    document.body.style.overflow = 'hidden';
  }, [dispatch]);

  // shadow logic
  // const onScroll = () => {
  //   const c = containerRef.current;
  //   const cForms = containerFormsRef.current;
  //   // const shadowTop = shadowTopRef.current;
  //   // const shadowBottom = shadowBottomRef.current;
  //   if (c && cForms && shadowTop && shadowBottom) {
  //     const scrollHeight = cForms.scrollHeight - c.offsetHeight;
  //     const currentScroll = cForms.scrollTop / scrollHeight;
  //     if (scrollHeight > 0 && currentScroll > 0) {
  //       shadowTop.style.opacity = '1';
  //       shadowTop.style.display = 'block';
  //     } else {
  //       shadowTop.style.opacity = '0';
  //       shadowTop.style.display = 'none';
  //     }
  //     if (
  //       scrollHeight > 0 &&
  //       currentScroll < 0.99 &&
  //       cForms.scrollHeight > 1180
  //     ) {
  //       shadowBottom.style.opacity = '1';
  //       shadowBottom.style.display = 'block';
  //     } else {
  //       shadowBottom.style.opacity = '0';
  //       shadowBottom.style.display = 'none';
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const c = containerRef.current;
  //   const cForms = containerFormsRef.current;
  //   const shadowBottom = shadowBottomRef.current;
  //   if (c && cForms && shadowBottom) {
  //     const setShadow = () => {
  //       const scrollHeight = cForms.scrollHeight - c.offsetHeight;
  //       const currentScroll = cForms.scrollTop / scrollHeight;
  //       if (cForms.scrollHeight > 1180 && currentScroll < 0.99) {
  //         shadowBottom.style.opacity = '1';
  //         shadowBottom.style.display = 'block';
  //       } else {
  //         shadowBottom.style.opacity = '0';
  //         shadowBottom.style.display = 'none';
  //       }
  //     };
  //     document.addEventListener('toggleOpenExpandableContent', setShadow);
  //     return () => {
  //       document.removeEventListener('toggleOpenExpandableContent', setShadow);
  //     };
  //   }
  //   return () => {};
  // }, []);

  const [activeSection, setActiveSection] =
    useState<FinancingEditSections>('financing');

  return (
    <div onClickCapture={(e) => setTimeout(() => onFindErrors(e), 0)}>
      <FormProvider {...methods}>
        <Container>
          {/* {!isMobileSize && ( */}
          {/*   <> */}
          {/*     <ShadowTop ref={shadowTopRef} /> */}
          {/*     <ShadowBottom ref={shadowBottomRef} /> */}
          {/*   </> */}
          {/* )} */}
          <FormsContainer
          // ref={containerFormsRef}
          // onScroll={onScroll}
          >
            <div ref={dataFormRef}>
              <ExpandableContent
                expanded={activeSection === 'financing'}
                setExpanded={() => setActiveSection('financing')}
                style={{ marginBottom: 24 }}
                title={t('financing.edit-widget.financing-data.title')}
              >
                <FinancingDataEditForm
                  methods={methods}
                  finData={{
                    ...finVarData,
                    ...personalFin,
                    ...finVarData.location.address,
                    ...finVarData.location.coordinates,
                  }}
                  proposition={proposition}
                  landTax={landTax}
                  isMortgageCertificate={!!isMortgageCertificate}
                />
              </ExpandableContent>
            </div>
            <div ref={propertyFormRef}>
              <ExpandableContent
                expanded={activeSection === 'property'}
                setExpanded={() => setActiveSection('property')}
                style={{ marginBottom: 24 }}
                title={t('financing.edit-widget.property-data.title')}
              >
                <FinancingPropertyEditForm />
              </ExpandableContent>
            </div>
            <div ref={personalFormRef}>
              <ExpandableContent
                expanded={activeSection === 'personal'}
                setExpanded={() => setActiveSection('personal')}
                title={t('financing.edit-widget.personal-data.title')}
              >
                <FinancingPersonalEditForm />
              </ExpandableContent>
            </div>
            {/* {!isMortgageCertificate && ( */}
            {/*  <div ref={reasonFormRef}> */}
            {/*    <ExpandableContent */}
            {/*      expanded={activeSection === 'reason'} */}
            {/*      setExpanded={() => setActiveSection('reason')} */}
            {/*      title={t('financing.edit-widget.reason-data.title')} */}
            {/*    > */}
            {/*      <FinancingReasonEditForm /> */}
            {/*    </ExpandableContent> */}
            {/*  </div> */}
            {/* )} */}
          </FormsContainer>
        </Container>

        <FinancingCalculationFormButtons
          isCalculatedPropositions={!!calculateFinancingPropositions}
          isLoading={isLoading}
          isLoadingUpdating={isLoadingUpdating}
          isCertificate={!!isMortgageCertificate}
          onAddNewVariantSubmit={handleOpenName}
          onEditVariantSubmit={methods.handleSubmit(handleUpdateVariant)}
          onFindPropositionsSubmit={methods.handleSubmit(
            handleFindPropositions
          )}
        />
        <ModalVariantName onSubmit={methods.handleSubmit(handleAddVariant)} />
      </FormProvider>
    </div>
  );
};

export { FinancingEditForms };
