import { FC } from 'react';
import { isString } from 'lodash';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useTranslation } from 'react-i18next';

import {
  BackgroundImage,
  BlueBox,
  BlueBoxContainer,
  BrownText,
  Container,
  ErrorBox,
  ErrorText,
  ImageVonPoll,
  ImageVP,
  Text,
  Wrapper,
} from './financing-certificate-styles';
import vpImage from '../../../../../assets/images/icons/financing-form/info-container/vp.png';
import vonPollImage from '../../../../../assets/images/icons/financing-form/info-container/vonpoll.png';
import certificateImage from '../../../../../assets/images/icons/financing-form/info-container/certificate.png';
import { GlobalLoader } from '../../../../common/components/ui/loaders/global-loader';
import { addCommas } from '../../../../forms/utils/number-format';
import musterImage from '../../../../../assets/images/icons/financing-form/info-container/muster.png';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';

interface IProps {
  isLoading: boolean;
  isCalculationPage?: boolean;
  name?: string;
  loanAmount?: Maybe<number> | undefined;
  dateProp?: Date;
}

const FinancingCertificate: FC<IProps> = ({
  isLoading,
  isCalculationPage,
  name,
  loanAmount,
  dateProp,
}) => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  const mainTextSize = isMobileSize ? 6.7 : 14;

  const dateObj = dateProp ? new Date(dateProp) : new Date();
  const month = dateObj.getUTCMonth() + 1;
  const textMonth = month < 10 ? `0${month}` : month;

  const day = dateObj.getUTCDate();
  const dayText = day < 10 ? `0${day}` : day;

  const year = dateObj.getUTCFullYear();

  const date = `${dayText}.${textMonth}.${year}`;

  return (
    <Container>
      <ImageVP
        isCalculationPage={isCalculationPage}
        src={vpImage}
        alt="vp image"
      />
      <ImageVonPoll
        isCalculationPage={isCalculationPage}
        src={vonPollImage}
        alt="von poll image"
      />
      <Wrapper src={certificateImage}>
        <Text
          mt={isMobileSize ? 34.8 : 57}
          mb={isMobileSize ? 18.7 : 38}
          fontSize={mainTextSize}
        >
          {t('financing.financing-certificate.header')}
        </Text>
        <Text
          mb={isMobileSize ? 22.9 : 49.8}
          fontSize={isMobileSize ? 20.4 : 43}
        >
          {t('financing.financing-certificate.title')}
        </Text>
        <Text
          maxWidth={500}
          mb={isMobileSize ? 12.7 : 25.6}
          fontSize={mainTextSize}
        >
          {t('financing.financing-certificate.text-1', { date })}{' '}
          {t('financing.financing-certificate.text-2')}
        </Text>
        <Text mb={isMobileSize ? 12.4 : 28} fontSize={mainTextSize}>
          {t('financing.financing-certificate.text-3')}
        </Text>
        {isString(name) ? (
          <Text isBold fontSize={24.9} mb={isMobileSize ? 15.9 : 25.9}>
            {name}
          </Text>
        ) : (
          <BlueBox width={215.8} mb={33.5} />
        )}
        <Text mb={isMobileSize ? 18.3 : 39.7} fontSize={mainTextSize}>
          {t('financing.financing-certificate.text-4')}
        </Text>
        <BlueBoxContainer mb={isMobileSize ? 4.3 : 11.7}>
          {isLoading ? (
            <GlobalLoader isAbsolute />
          ) : loanAmount ? (
            <>{addCommas(loanAmount)} €</>
          ) : isCalculationPage ? (
            <ErrorBox>
              <ErrorText>
                {t('financing.financing-certificate.error')}
              </ErrorText>
            </ErrorBox>
          ) : (
            <BlueBox width={215.8} mb={33.5} />
          )}
        </BlueBoxContainer>
        {(isLoading || loanAmount || !isCalculationPage) && (
          <Text mb={isMobileSize ? 18.3 : 37.3} fontSize={mainTextSize}>
            {t('financing.financing-certificate.text-5')}
          </Text>
        )}
        <Text
          maxWidth={390}
          mb={isMobileSize ? 24.2 : 49.8}
          fontSize={mainTextSize}
        >
          {t('financing.financing-certificate.text-6')}
        </Text>
        <Text mb={isMobileSize ? 1 : 2} fontSize={isMobileSize ? 7.4 : 15.6}>
          {t('financing.financing-certificate.text-7')}
        </Text>
        <Text mb={isMobileSize ? 6 : 84} fontSize={isMobileSize ? 7.4 : 15.6}>
          {t('financing.financing-certificate.text-8')}
        </Text>
        <BrownText>
          <p>{t('financing.financing-certificate.text-9')}</p>
        </BrownText>
        <BackgroundImage src={musterImage} alt="muster image" />
      </Wrapper>
    </Container>
  );
};

export { FinancingCertificate };
