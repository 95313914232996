import { FC, memo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  Container,
  CreatedOn,
  Date,
  Description,
  Row,
  Title,
} from './info-card-styles';
import Icon from '../../../common/components/ui/icon';
import { ReactComponent as Calendar } from '../../../../assets/streamline-light/interface-essential/date-calendar/calendar.svg';
import { ReactComponent as DocumentSearch } from '../../../../assets/streamline-light/files-folders/common-files/common-file-text-search.svg';
import { ReactComponent as DocumentPDF } from '../../../../assets/streamline-light/work-office-companies/office-files/office-file-pdf-1.svg';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { ExposeDetailsOverlay } from '../../pages/expose-details-overlay/expose-details-overlay';

interface IProps {
  borderLeftColor?: string;
  title: string;
  description?: string;
  createdOn?: string;
  onDownloadPdfExpose?: () => void;
  onOpenOnlineExpose?: () => void;
}

const ExposeInfoCardBase: FC<IProps> = ({
  borderLeftColor,
  title,
  description,
  createdOn,
  onDownloadPdfExpose,
  onOpenOnlineExpose,
}) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  return (
    <Container borderLeftColor={borderLeftColor}>
      <Title content={title} />
      {createdOn && (
        <Row alignItems={'center'}>
          <Icon icon={Calendar} color={theme.blue} width={20} height={20} />
          <CreatedOn content={t('seller.expose-card.created-on')} />
          <Date content={createdOn} />
        </Row>
      )}
      {description && <Description content={description} />}
      {(onOpenOnlineExpose || onDownloadPdfExpose) && (
        <Row gap={12} isColumn={isMobileSize}>
          {onDownloadPdfExpose && (
            <ActionButton
              label={t('seller.expose-card.download-expose')}
              onClick={onDownloadPdfExpose}
              icon={DocumentPDF}
              borderColor={theme.blue}
              color={theme.blue}
              type={'button'}
              iconStyle={{ width: '16px', height: '16px', margin: '0 8px 0 0' }}
            />
          )}

          {onOpenOnlineExpose && (
            <ActionButton
              label={t('seller.expose-card.show-expose')}
              onClick={onOpenOnlineExpose}
              icon={DocumentSearch}
              borderColor={theme.blue}
              color={theme.blue}
              type={'button'}
              iconStyle={{ width: '16px', height: '16px', margin: '0 8px 0 0' }}
            />
          )}
        </Row>
      )}
      <ExposeDetailsOverlay />
    </Container>
  );
};

const ExposeInfoCard = memo(ExposeInfoCardBase);

export { ExposeInfoCard };
