import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { Headline1 } from '../../../../common/components/ui/typography';

export const VirtualToursContainer = styled.div`
  position: relative;
  padding: 64px 0;
  margin: 64px 0;

  @media ${device.mobile} {
    padding: 16px 0;
    margin: 16px 0;
  }
`;

export const Background = styled.div`
  background-color: ${(props) => props.theme.faqHeader};
  height: 100%;
  position: absolute;
  top: 0;
  // TODO: hotfix width
  width: calc(100vw - 10px);
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

export const VirtualToursGrid = styled.div`
  position: relative;
  display: grid;
  //grid-template-columns: repeat(3, 1fr);
  //grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 24px;
  margin-top: 32px;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
  }
`;

export const Header = styled(Headline1)`
  text-align: center;
  position: relative;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
  }
`;
