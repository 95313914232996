import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  isCollapsibleHeaderOpen: boolean;
}

const initialState: InitialState = {
  isCollapsibleHeaderOpen: false,
};

const mobileSlice = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    toggleCollapsibleHeader(state, action: PayloadAction<boolean>) {
      state.isCollapsibleHeaderOpen = action.payload;
    },
  },
});

export const { toggleCollapsibleHeader } = mobileSlice.actions;

export default mobileSlice.reducer;
