import { FC, memo } from 'react';
import {
  CommentDate,
  CommentText,
  Wrapper,
  Row,
  CommentTitle,
  UserAvatarPlaceholder,
  CommentContainer,
} from './comment-styles';

interface IProps {
  userAvatarPlaceholder: string;
  title: string;
  date: string;
  text: string;
  isInnerHTML?: boolean;
}

const CommentBase: FC<IProps> = ({
  userAvatarPlaceholder,
  title,
  date,
  text,
  isInnerHTML,
}) => {
  return (
    <Wrapper>
      <UserAvatarPlaceholder>{userAvatarPlaceholder}</UserAvatarPlaceholder>
      <CommentContainer>
        <Row
          alignItems={'center'}
          justifyContent={'space-between'}
          margin={'0 0 12px 0'}
        >
          <CommentTitle content={title} />
          <CommentDate content={date} />
        </Row>
        {isInnerHTML ? (
          <CommentText dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          <CommentText>{text}</CommentText>
        )}
      </CommentContainer>
    </Wrapper>
  );
};

const Comment = memo(CommentBase);

export { Comment };
