import { PortalType } from '../../routes/site-map';

export const locales = [
  { locale: 'de-de', hrefLang: 'de-DE' },
  { locale: 'de-ch', hrefLang: 'de-CH' },
  { locale: 'de-at', hrefLang: 'de-AT' },
  { locale: 'de-de', hrefLang: 'x-default' },
];

export enum CountryCodes {
  SWITZERLAND = 'ch',
  AUSTRIA = 'at',
  GERMANY = 'de',
  LUXEMBOURG = 'lu',
  SPAIN = 'es',
  FRANCE = 'fr',
  GREECE = 'gr',
  HUNGARY = 'hu',
  ITALY = 'it',
  NETHERLANDS = 'nl',
  PORTUGAL = 'pt',
  ENGLAND = 'en',
  SWEDEN = 'se',
}

enum Countries {
  germany = 'germany',
  austria = 'austria',
  switzerland = 'switzerland',
  spain = 'spain',
  france = 'france',
  greece = 'greece',
  hungary = 'hungary',
  italy = 'italy',
  luxembourg = 'luxembourg',
  netherlands = 'netherlands',
  portugal = 'portugal',
  unitedStates = 'unitedStates',
  sweden = 'sweden',
}

type Country = {
  phonePrefix: string;
  languagesCountry: string[];
  languages: string[];
  defaultLanguage: string;
  countryCode: string;
  supportedPortals: PortalType[];
};

export const localizations: Record<Countries, Country> = {
  germany: {
    phonePrefix: '+49',
    languagesCountry: ['de-de'],
    languages: ['de'],
    defaultLanguage: 'de',
    countryCode: 'de',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  austria: {
    phonePrefix: '+43',
    languagesCountry: ['de-at'],
    languages: ['de'],
    defaultLanguage: 'de',
    countryCode: 'at',
    supportedPortals: [PortalType.Owner, PortalType.Buyer, PortalType.Seller],
  },
  switzerland: {
    phonePrefix: '+41',
    languagesCountry: ['de-ch', 'fr-ch', 'it-ch'],
    languages: ['de', 'fr', 'it'],
    defaultLanguage: 'de',
    countryCode: 'ch',
    supportedPortals: [PortalType.Owner, PortalType.Buyer, PortalType.Seller],
  },
  spain: {
    phonePrefix: '+34',
    languagesCountry: ['es-es'],
    languages: ['es'],
    defaultLanguage: 'es',
    countryCode: 'es',
    supportedPortals: [PortalType.Owner, PortalType.Buyer],
  },
  france: {
    phonePrefix: '+33',
    languagesCountry: ['fr-fr'],
    languages: ['fr'],
    defaultLanguage: 'fr',
    countryCode: 'fr',
    supportedPortals: [PortalType.Owner, PortalType.Buyer],
  },
  greece: {
    phonePrefix: '+30',
    languagesCountry: ['gr-gr'],
    languages: ['gr'],
    defaultLanguage: 'gr',
    countryCode: 'gr',
    supportedPortals: [PortalType.Owner, PortalType.Buyer],
  },
  hungary: {
    phonePrefix: '+36',
    languagesCountry: ['hu-hu'],
    languages: ['hu'],
    defaultLanguage: 'hu',
    countryCode: 'hu',
    supportedPortals: [PortalType.Owner, PortalType.Buyer],
  },
  italy: {
    phonePrefix: '+39',
    languagesCountry: ['it-it'],
    languages: ['it'],
    defaultLanguage: 'it',
    countryCode: 'it',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Financing,
      PortalType.Seller,
    ],
  },
  luxembourg: {
    phonePrefix: '+352',
    languagesCountry: ['lu-lu', 'de-lu', 'fr-lu'],
    languages: ['lu', 'de', 'fr'],
    defaultLanguage: 'fr',
    countryCode: 'lu',
    supportedPortals: [PortalType.Owner, PortalType.Buyer],
  },
  netherlands: {
    phonePrefix: '+31',
    languagesCountry: ['nl-nl'],
    languages: ['nl'],
    defaultLanguage: 'nl',
    countryCode: 'nl',
    supportedPortals: [PortalType.Owner, PortalType.Buyer],
  },
  portugal: {
    phonePrefix: '+351',
    languagesCountry: ['pt-pt'],
    languages: ['pt'],
    defaultLanguage: 'pt',
    countryCode: 'pt',
    supportedPortals: [PortalType.Owner, PortalType.Buyer],
  },
  unitedStates: {
    phonePrefix: '+49',
    languagesCountry: ['en-us'],
    languages: ['en'],
    defaultLanguage: 'en',
    countryCode: 'us',
    supportedPortals: [PortalType.Owner, PortalType.Buyer],
  },
  sweden: {
    phonePrefix: '+46',
    languagesCountry: ['se-se'],
    languages: ['se'],
    defaultLanguage: 'se',
    countryCode: 'se',
    supportedPortals: [PortalType.Owner, PortalType.Buyer],
  },
};

export enum PhonePrefixes {
  GERMANY = '+49',
  SWITZERLAND = '+41',
  AUSTRIA = '+43',
  SPAIN = '+34',
  FRANCE = '+33',
  GREECE = '+30',
  HUNGARY = '+36',
  ITALY = '+39',
  LUXEMBOURG = '+352',
  NETHERLANDS = '+31',
  PORTUGAL = '+351',
  DENMARK = '+45',
  POLAND = '+48',
  CZECH = '+420',
  LIECHTENSTEIN = '+423',
  BELGIUM = '+32',
  SWEDEN = '+46',
}

//  ISO 639-1
// country with languages
export const allSupportedLanguages = Object.values(localizations)
  .reduce((acc: string[], country) => [...country.languagesCountry, ...acc], [])
  .map((item) => item.split('-')[0]);

export const supportedLanguagesCountryOwnerPortal = Object.values(localizations)
  .filter((item) => item.supportedPortals.includes(PortalType.Owner))
  .reduce((acc: string[], item) => [...item.languagesCountry, ...acc], []);

export const supportedLanguagesCountryBuyerPortal = Object.values(localizations)
  .filter((item) => item.supportedPortals.includes(PortalType.Buyer))
  .reduce((acc: string[], item) => [...item.languagesCountry, ...acc], []);

export const supportedLanguagesCountrySellerPortal = Object.values(
  localizations
)
  .filter((item) => item.supportedPortals.includes(PortalType.Seller))
  .reduce((acc: string[], item) => [...item.languagesCountry, ...acc], []);

export const supportedLanguagesCountryFinancingPortal = Object.values(
  localizations
)
  .filter((item) => item.supportedPortals.includes(PortalType.Financing))
  .reduce((acc: string[], item) => [...item.languagesCountry, ...acc], []);

const languagesSet = new Set(
  Object.values(localizations).reduce(
    (acc: string[], { languages: langs }) => [...langs, ...acc],
    []
  )
);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const languages = [...languagesSet];

export const getCurrencyByLocale = (locale: string): string => {
  switch (locale) {
    case 'de-de': // Germany
    case 'de-at': // Austria
      return 'EUR';
    case 'de-ch': // Switzerland
      return 'CHF';
    default:
      return 'EUR'; // Default currency
  }
};
