import styled from 'styled-components';
import { Headline1 } from '../../../../../../../common/components/ui/typography';

export const Header = styled(Headline1)`
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
`;

export const InfoContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 12px;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
`;

export const RangeContainer = styled.div<{ isValue: boolean }>`
  position: relative;
  ${({ isValue }) => isValue && 'margin-top: 52px'};
`;

export const MarkersListContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  height: 20px;
`;

export const DataListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
`;

export const Mark = styled.div<{ width?: number; isLast?: boolean }>`
  border-right: 1px solid ${({ isLast }) => (isLast ? 'transparent' : 'white')};
  width: ${({ width }) => (width ? `${width}%` : 'fit-content')}; ;
`;

export const MarkNaming = styled.span`
  font-family: Roboto;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: ${({ theme }) => theme.blue};
`;

export const MarkMetric = styled.div`
  opacity: 0.3;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;

export const BoxesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
