import { FC } from 'react';
import {
  FinancingCertificates,
  FinancingLpSection,
  FinancingType,
  useReadFinancingCertificateQuery,
} from '../../../../../generated';
import {
  Container,
  Title,
  Text,
  Img,
  CTAButton,
  TitleMobile,
} from './financing-certificate-styles';
import { useOpenFinancing } from '../../../../forms/form-financing/hooks/callback-hooks/useOpenFinancing';

interface IProps {
  section: FinancingLpSection;
}

interface FinancingCertificateData {
  data: {
    readFinancingCertificate: FinancingCertificates;
  };
  isLoading: boolean;
}

const FinancingCertificate: FC<IProps> = ({ section: { recordIds } }) => {
  const { data, isLoading } =
    useReadFinancingCertificateQuery<FinancingCertificateData>({
      id: recordIds?.[0],
    });
  const { openFinancing } = useOpenFinancing({});

  if (isLoading || !data || !data.readFinancingCertificate) return null;

  const {
    readFinancingCertificate: {
      title,
      expandedText,
      mainCTALabel,
      imageAlt,
      imageUrl,
    },
  } = data;

  const splitter = 'VON POLL FINANCE';

  const titleArr = (title || '').split(splitter);

  return (
    <Container>
      <div>
        <Title>
          <div>{titleArr?.[0] || ''}</div>
          <div>{`${splitter} ${titleArr?.[1] || ''}`}</div>
        </Title>
        <Text>{expandedText || ''}</Text>
        <CTAButton
          label={mainCTALabel || ''}
          onClick={() => openFinancing(FinancingType.FinancingCertificate)}
        />
      </div>
      <Img src={imageUrl || ''} alt={imageAlt || ''} />
      <TitleMobile>{title || ''}</TitleMobile>
    </Container>
  );
};

export default FinancingCertificate;
