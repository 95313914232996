import { FC, memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Container, TooltipHeader } from './tooltip-styles';
import { OutsideClickHandler } from '../../../../common/components/outside-click-handler';

interface IProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  offset: { top: number; left: number };
}

const TooltipBase: FC<IProps> = ({ isVisible, setIsVisible, offset }) => {
  const { t } = useTranslation();

  const handleOutsideClick = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  useEffect(() => {
    const hide = () => setIsVisible(false);
    window.addEventListener('scroll', hide);

    return () => window.removeEventListener('scroll', hide);
  }, [setIsVisible]);

  return (
    <Container role={'presentation'}>
      {isVisible && (
        <OutsideClickHandler
          display="block"
          onOutsideClick={handleOutsideClick}
        >
          <Form offset={offset}>
            <TooltipHeader>
              {t('selling-process.locked-tab.tooltip.text')}
            </TooltipHeader>
          </Form>
        </OutsideClickHandler>
      )}
    </Container>
  );
};

const LockedTabTooltip = memo(TooltipBase);
export { LockedTabTooltip };
