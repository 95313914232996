import { memo, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PropertyCode } from '../../../../../../generated';
import { device } from '../../../../../../style/theme';
import { Checkbox } from '../../../../../common/components/form';

import { BorderButton } from '../../../../../common/components/ui/buttons';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { VALUATION_FORM_KEYS } from '../../../constants';
import { prevStepAction } from '../../../redux/valuationWizardSlice';
import { NextButton } from '../../../../components/common/next-button';
import { SliderInput } from '../../../../components/common/slider-input';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div<{ isiframe?: boolean }>`
  display: flex;
  flex-direction: column;

  &:last-of-type {
    margin-top: auto;
    justify-content: flex-start;
    flex-direction: row;

    > *:last-of-type {
      margin-left: auto;
      @media ${({ isiframe }) => (isiframe ? '0' : device.tablet)} {
        margin-top: 32px;
      }
    }
  }
`;

const CheckboxRow = styled.div`
  padding: 12px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media ${device.tablet} {
    padding-top: 18px;
  }
`;

const CheckboxItem = styled(Checkbox)`
  margin-right: 12px;
`;

const ThirdStepBase = (): JSX.Element => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();

  const isHouse = useAppSelector(
    (state) => state.valuationWizard.userInput?.code === PropertyCode.House
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const switchToPrevStep = useCallback(
    (e?: MouseEvent) => {
      e?.preventDefault();
      dispatch(prevStepAction());
    },
    [dispatch]
  );

  return (
    <Container>
      <Row>
        <SliderInput
          hasInfo
          name={VALUATION_FORM_KEYS.NUMBER_OF_ROOMS}
          label={t('valuation.form.fourth-step.rooms')}
          tooltipKey="valuation.form.first-step.rooms-tooltip"
          rules={{
            required: 'register.input.error.required',
            min: {
              value: 1,
              message: 'valuation.form.fourth-step.number-of-rooms-min-error',
            },
            max: {
              value: 20,
              message: 'valuation.form.fourth-step.number-of-rooms-max-error',
            },
            valueAsNumber: true,
            value: 1,
            validate: {
              min: (v) =>
                v >= 1 ||
                'valuation.form.fourth-step.number-of-rooms-min-error',
              max: (v) =>
                v <= 20 ||
                'valuation.form.fourth-step.number-of-rooms-max-error',
            },
            setValueAs: (v) => Number.parseInt(v, 10),
          }}
        />
        <SliderInput
          hasInfo
          name={VALUATION_FORM_KEYS.NUMBER_OF_BATHROOMS}
          label={t('valuation.form.fourth-step.bathrooms')}
          tooltipKey="valuation.form.first-step.bathrooms-tooltip"
          rules={{
            required: 'register.input.error.required',
            min: {
              value: 1,
              message:
                'valuation.form.fourth-step.number-of-bathrooms-min-error',
            },
            max: {
              value: 5,
              message:
                'valuation.form.fourth-step.number-of-bathrooms-max-error',
            },
            valueAsNumber: true,
            value: 1,
            validate: {
              min: (v) =>
                v >= 1 ||
                'valuation.form.fourth-step.number-of-bathrooms-min-error',
              max: (v) =>
                v <= 5 ||
                'valuation.form.fourth-step.number-of-bathrooms-max-error',
            },
            setValueAs: (v) => Number.parseInt(v, 10),
          }}
        />
        <SliderInput
          hasMeasurement
          name={VALUATION_FORM_KEYS.BALCONY_AREA}
          label={t('valuation.form.fourth-step.balcony-area')}
          tooltipKey="valuation.form.first-step.balcony-area-tooltip"
          rules={{
            required: 'register.input.error.required',
            min: {
              value: 0,
              message: 'valuation.form.fourth-step.balcony-area-min-error',
            },
            max: {
              value: 200,
              message: 'valuation.form.fourth-step.balcony-area-max-error',
            },
            valueAsNumber: true,
            value: 0,
            validate: {
              min: (v) =>
                v >= 0 || 'valuation.form.fourth-step.balcony-area-min-error',
              max: (v) =>
                v <= 200 || 'valuation.form.fourth-step.balcony-area-max-error',
            },
            setValueAs: (v) => Number.parseInt(v, 10),
          }}
        />
        <SliderInput
          hasInfo
          name={VALUATION_FORM_KEYS.NUMBER_OF_INDOOR_PARKING_SPACES}
          label={t('valuation.form.fourth-step.garage-parking-spaces')}
          tooltipKey="valuation.form.first-step.garade-parking-spaces-tooltip"
          rules={{
            required: 'register.input.error.required',
            min: {
              value: 0,
              message:
                'valuation.form.fourth-step.number-of-indoor-parking-spaces-min-error',
            },
            max: {
              value: 6,
              message:
                'valuation.form.fourth-step.number-of-indoor-parking-spaces-max-error',
            },
            valueAsNumber: true,
            value: 0,
            validate: {
              min: (v) =>
                v >= 0 ||
                'valuation.form.fourth-step.number-of-indoor-parking-spaces-min-error',
              max: (v) =>
                v <= 6 ||
                'valuation.form.fourth-step.number-of-indoor-parking-spaces-max-error',
            },
            setValueAs: (v) => Number.parseInt(v, 10),
          }}
        />
        <SliderInput
          hasInfo
          name={VALUATION_FORM_KEYS.NUMBER_OF_OUTDOOR_PARKING_SPACES}
          label={t('valuation.form.fourth-step.parking-spaces')}
          tooltipKey="valuation.form.first-step.parking-spaces-tooltip"
          rules={{
            required: 'register.input.error.required',
            min: {
              value: 0,
              message:
                'valuation.form.fourth-step.number-of-outdoor-parking-spaces-min-error',
            },
            max: {
              value: 6,
              message:
                'valuation.form.fourth-step.number-of-outdoor-parking-spaces-max-error',
            },
            valueAsNumber: true,
            value: 0,
            validate: {
              min: (v) =>
                v >= 0 ||
                'valuation.form.fourth-step.number-of-outdoor-parking-spaces-min-error',
              max: (v) =>
                v <= 6 ||
                'valuation.form.fourth-step.number-of-outdoor-parking-spaces-max-error',
            },
            setValueAs: (v) => Number.parseInt(v, 10),
          }}
        />
      </Row>
      {isHouse && (
        <CheckboxRow>
          <CheckboxItem
            name={VALUATION_FORM_KEYS.IS_NEW}
            label={'valuation.form.fourth-step.is-new'}
          />
          <CheckboxItem
            name={VALUATION_FORM_KEYS.HAS_POOL}
            label={'valuation.form.fourth-step.has-pool'}
          />
          <CheckboxItem
            name={VALUATION_FORM_KEYS.HAS_SAUNA}
            label={'valuation.form.fourth-step.has-sauna'}
          />
        </CheckboxRow>
      )}
      <Row isiframe={isIframe}>
        {(!isMobileSize || isIframe) && (
          <BorderButton
            id="step-3"
            label={t('button.back')}
            onClick={switchToPrevStep}
          />
        )}
        <NextButton
          id="step-3"
          watchedKeys={[
            VALUATION_FORM_KEYS.NUMBER_OF_ROOMS,
            VALUATION_FORM_KEYS.NUMBER_OF_BATHROOMS,
            VALUATION_FORM_KEYS.BALCONY_AREA,
            VALUATION_FORM_KEYS.NUMBER_OF_INDOOR_PARKING_SPACES,
            VALUATION_FORM_KEYS.NUMBER_OF_OUTDOOR_PARKING_SPACES,
            ...(isHouse
              ? [
                  VALUATION_FORM_KEYS.IS_NEW,
                  VALUATION_FORM_KEYS.HAS_POOL,
                  VALUATION_FORM_KEYS.HAS_SAUNA,
                ]
              : []),
          ]}
          label={t('button.next-step')}
        />
      </Row>
    </Container>
  );
};

const ThirdStep = memo(ThirdStepBase);

export { ThirdStep };
