import styled from 'styled-components';
import {
  Headline2,
  ParagraphText,
} from '../../../../common/components/ui/typography';
import { device } from '../../../../../style/theme';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';

export const Container = styled.div`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  background-color: ${(props) => props.theme.white};

  @media ${device.tablet} {
    padding: 16px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const Title = styled(Headline2)`
  margin-right: 12px;
`;

export const Description = styled(ParagraphText)`
  margin-bottom: 16px;
`;

const BaseStateTag = styled.div`
  min-height: 20px;
  padding: 2px 8px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.borderColor};
  background-color: ${(props) => props.theme.white};
  font-family: Roboto;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

export const PreparationTag = styled(BaseStateTag)``;

export const WaitingApprovalTag = styled(BaseStateTag)`
  border: 1px solid ${(props) => props.theme.lightBlue};
  background-color: ${(props) => props.theme.greyBackground};
  color: ${(props) => props.theme.blue};
`;

export const ChangesRequestedTag = styled(BaseStateTag)`
  border: 1px solid ${(props) => props.theme.tagGoldBorder};
  background-color: ${(props) => props.theme.tagGoldBackground};
  color: ${(props) => props.theme.gold};
`;

export const ApprovedTag = styled(BaseStateTag)`
  border: 1px solid ${(props) => props.theme.tagGreenBorder};
  background-color: ${(props) => props.theme.paleGreen};
  color: ${(props) => props.theme.green};
`;
