import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { ReactComponent as PasswordLock } from 'assets/streamline-light/interface-essential/password/password-lock.svg';

import { Modal, ModalTitle } from '../../../common/components/modal';
import Icon from '../../../common/components/ui/icon';
import {
  removeUserInput,
  toggleIsConfirmForgotPasswordModalOpen,
} from '../../redux/authSlice';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { device } from '../../../../style/theme';
import { ConfirmForgotPasswordForm } from '../forms/confirm-forgot-password-form';

interface IProps {
  isOpen: boolean;
}

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  @media ${device.tablet} {
    background-color: ${(props) => props.theme.blue};
    width: 100%;
    justify-content: center;
    padding: 18px 0 18px 0;
  }
`;
const FormWrapper = styled.div`
  @media ${device.tablet} {
    margin: 0 16px 0 16px;
    height: 100%;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  @media ${device.tablet} {
    position: absolute;
    right: 16px;
  }
`;

const descktopWrapperStyle = {
  maxWidth: 476,
  padding: '32px 32px 20px 32px',
};
const mobileWrapperStyle = {
  width: '100%',
  height: '100%',
  padding: 0,
  overflow: 'overlay',
};

const ConfirmForgotPasswordModal = ({ isOpen }: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const isMobileSize = useIsMobileSize();

  const onClose = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    batch(() => {
      dispatch(removeUserInput());
      dispatch(toggleIsConfirmForgotPasswordModalOpen(false));
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      wrapperStyles={isMobileSize ? mobileWrapperStyle : descktopWrapperStyle}
    >
      <Container>
        <Row>
          <ModalTitle
            icon={isMobileSize ? undefined : PasswordLock}
            text={t('confirm password')}
            textColor={isMobileSize ? 'white' : undefined}
            iconPosition={isMobileSize ? undefined : 'left'}
            textAlign={isMobileSize ? 'center' : 'left'}
            margin="0"
          />

          <IconContainer onClick={onClose}>
            <Icon
              icon={Close}
              width={16}
              height={16}
              color={themeContext.white}
            />
          </IconContainer>
        </Row>
        <FormWrapper>
          <ConfirmForgotPasswordForm />
        </FormWrapper>
      </Container>
    </Modal>
  );
};

export { ConfirmForgotPasswordModal };
