import { FC, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { SerializedError } from '@reduxjs/toolkit';
import { Accordion } from '../../accordion/accordion';
import { ReactComponent as FilterIcon } from '../../../../../assets/streamline-light/interface-essential/filter/filter-1.svg';
import { FilterLoader } from './loader';
import { WarningMessage } from './warning-message';
import {
  EditFiltersButton,
  NewOfferBadge,
  ScrollWrapper,
  Statistics,
} from '../offers-statistics-card-styles';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import { Offer } from '../new-offer/new-offer';
import { buildGeneralStatistics } from '../../../utils/buildGeneralStatistics';
import {
  Item,
  ItemLabel,
  ItemValue,
} from '../../general-statistics-card/general-statistics-card-styles';
import {
  toggleFilterOpen,
  toggleShouldResetFilter,
} from '../../../redux/sellerPropertiesSlice';
import { useAppSelector } from '../../../../common/hooks';
import { NewPurchaseOffer, OffersStatisticsItem } from '../../../interfaces';
import {
  MarketingGeneralStatistics,
  UserStatus,
} from '../../../../../generated';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { formatNumber } from '../../../utils/formatNumber';

interface IProps {
  statistics?: OffersStatisticsItem[];
  accordionsState:
    | { id: string; isOpen: boolean; isScrolledToBottom: boolean }[]
    | undefined;
  onCollapseSingleAccordion: (id: string) => void;
  isShowLoader: boolean;
  isSimulation: boolean;
  statisticsError?: SerializedError;
  filteredOffers: NewPurchaseOffer[];
  filteredGeneralStatistics?: MarketingGeneralStatistics | null;
  warningMessage?: string;
  onCloseWarningMessage: () => void;
  hasHighestBid: boolean;
  accordionId: string;
  setLastItemVisible: (accordionId: string, isVisible: boolean) => void;
  isFilterActive?: boolean;
  isDisabled?: boolean;
  simulationHighestBid?: string;
  userStatus?: UserStatus | null;
}

const FilterAccordionBase: FC<IProps> = ({
  statistics,
  accordionsState,
  onCollapseSingleAccordion,
  isShowLoader,
  isSimulation,
  statisticsError,
  filteredOffers,
  filteredGeneralStatistics,
  warningMessage,
  onCloseWarningMessage,
  hasHighestBid,
  accordionId,
  setLastItemVisible,
  isFilterActive,
  isDisabled,
  simulationHighestBid,
  userStatus,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();

  const theme = useContext(ThemeContext);

  const [showSecondWarningMessage, setShowSecondWarningMessage] =
    useState<boolean>(true);

  const filters = useAppSelector(
    (state) => state.sellerProperties.marketingStatisticsFilter
  );

  const formattedDateFrom = useMemo(() => {
    if (filters?.dateFrom) {
      return new Date(filters.dateFrom).toLocaleDateString('en-GB');
    }
    return new Date(statistics?.[statistics?.length - 1]?.dateFrom ?? '')
      .toLocaleString('en-GB')
      .split(',')[0];
  }, [filters.dateFrom, statistics]);

  const formattedDateTo = useMemo(() => {
    if (filters?.dateTo) {
      return new Date(filters.dateTo).toLocaleDateString('en-GB');
    }
    return t('marketing-dashboard.filter.until-today');
  }, [filters.dateTo, t]);

  const onEditFilters = useCallback(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 1200,
        behavior: 'smooth',
      });
    }, 100);
    dispatch(toggleFilterOpen(true));
  }, [dispatch]);

  const onResetFilters = useCallback(() => {
    dispatch(toggleShouldResetFilter(true));
  }, [dispatch]);

  return (
    <Accordion
      id={accordionId}
      isOpen={
        accordionsState?.find((accordion) => accordion.id === 'filter')?.isOpen
      }
      onChange={onCollapseSingleAccordion}
      accordionSection={{
        _id: 'filter',
        icon: FilterIcon,
        iconStyles: { width: '14px', height: '16px', marginRight: '8px' },
        title: t('marketing-dashboard.filter'),
        subtitle: `(${formattedDateFrom} - ${formattedDateTo})`,
        expandedContent: (
          <>
            {isShowLoader ? (
              <FilterLoader />
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                }}
                role={'presentation'}
                onClick={(event) => event.stopPropagation()}
              >
                {/* {!isSimulation && showSecondWarningMessage && ( */}
                {/*  <WarningMessage */}
                {/*    text={t( */}
                {/*      'marketing-dashboard.filter-accordion.warning-message' */}
                {/*    )} */}
                {/*    onClose={() => setShowSecondWarningMessage(false)} */}
                {/*  /> */}
                {/* )} */}

                {warningMessage && (
                  <WarningMessage
                    text={warningMessage}
                    onClose={onCloseWarningMessage}
                  />
                )}
                <ScrollWrapper>
                  {statisticsError && (
                    <GlobalError
                      title={t(
                        (statisticsError as { message: string })?.message
                          ?.split(':')?.[0]
                          .toLowerCase()
                      )}
                    />
                  )}
                  {filteredOffers?.map((offer, i) => (
                    <Offer
                      userStatus={userStatus}
                      isLast={i === filteredOffers.length - 1}
                      accordionId={accordionId}
                      setLastItemVisible={setLastItemVisible}
                      bidOnOfficeId={offer.bidOnOfficeId}
                      key={offer.bidOnOfficeId}
                      customerId={offer.customerId}
                      customerOnOfficeId={offer.customerOnOfficeId ?? ''}
                      title={`${t(
                        'selling-simulation.offers-statistics-card.customer'
                      )} ${offer.customerId}`}
                      fromCity={offer.fromCity}
                      offeredPrice={offer.price}
                      newOfferedPrice={offer.newPrice}
                      isHighestBid={
                        isSimulation
                          ? offer.price === simulationHighestBid
                          : offer.isHighestBid
                      }
                      isNew={offer.isNew}
                      isSimulation={isSimulation}
                      isAccepted={offer.isAccepted}
                      date={offer.date}
                      isDisabled={isDisabled}
                    />
                  ))}
                </ScrollWrapper>
                {/* <Statistics */}
                {/*  isGrid={buildGeneralStatistics( */}
                {/*    filteredGeneralStatistics */}
                {/*  )?.some( */}
                {/*    (item) => */}
                {/*      item.name === 'impressions' || item.name === 'bookmarks' */}
                {/*  )} */}
                {/*  isShowGradient={ */}
                {/*    Boolean(filteredOffers?.length) && */}
                {/*    !accordionsState?.find( */}
                {/*      (accordion) => accordion.id === accordionId */}
                {/*    )?.isScrolledToBottom */}
                {/*  } */}
                {/* > */}
                {/*  {buildGeneralStatistics(filteredGeneralStatistics) */}
                {/*    ?.slice(1) // hide advertisementViews */}
                {/*    ?.map((item) => ( */}
                {/*      <Item key={item.label}> */}
                {/*        <ItemValue content={item.value} /> */}
                {/*        <ItemLabel content={t(item.label)} /> */}
                {/*      </Item> */}
                {/*    ))} */}
                {/* </Statistics> */}
                <EditFiltersButton
                  label={t(
                    isFilterActive
                      ? 'marketing-dashboard.filter.reset.button'
                      : 'marketing-dashboard.filter.edit.button'
                  )}
                  onClick={
                    isFilterActive && onResetFilters
                      ? onResetFilters
                      : onEditFilters
                  }
                  borderColor={theme.blue}
                  color={theme.blue}
                />
              </div>
            )}
          </>
        ),
        Badge: filteredOffers?.length ? (
          <>
            <NewOfferBadge>
              {filteredOffers.length === 1
                ? t(
                    'selling-simulation.offers-statistics-card.single-new-offer.badge'
                  )
                : t(
                    'selling-simulation.offers-statistics-card.new-offers-number.badge',
                    { offersNumber: filteredOffers?.length }
                  )}
            </NewOfferBadge>
            {hasHighestBid && (
              <NewOfferBadge isFilled>
                {t(
                  'selling-simulation.offers-statistics-card.highest-bid.badge'
                )}
              </NewOfferBadge>
            )}
          </>
        ) : undefined,
      }}
      isBadgeAtBottom={isMobileSize}
    />
  );
};

const FilterAccordion = memo(FilterAccordionBase);

export { FilterAccordion };
