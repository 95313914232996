import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import styled from 'styled-components';

import { CreatePropertyInput } from '../../../../../../generated';
import { useCreatePropertyDossierMutation } from '../../../../../../services/graphql/enhanced';
import { ConfirmEmail } from '../../../../../auth/components/confirm-email';
import { setErrors } from '../../../../../auth/redux/authSlice';
import { GlobalLoader } from '../../../../../common/components/ui/loaders/global-loader';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { disableBodyOverflow } from '../../../../../common/utils/disable-body-overflow';
import { TimifyWidget } from '../../../../../timify/components/widget';
import {
  ITimifyParams,
  TimifyPersonalisationIds,
} from '../../../../../timify/interfaces';
import { FORM_STEPS, showServices } from '../../../constants';
import { FormError } from '../../../../interface';
import { IValuationForm } from '../../../interface';
import {
  clearErrors,
  setPropertyDossierAction,
  toggleLoading,
  toggleLoginForm,
  toggleRegisterForm,
} from '../../../redux/valuationWizardSlice';
import { parseValuationInput } from '../../../../utils/parse-valuation-input';
import { FormContainer } from '../../../../components/common/form-container';
import { VisibilityWrapper } from '../../../../components/common/visibility-wrapper';
import { ValuationForm } from '../../forms/valuation-form';
import { PersonalLoginContainer } from './personal-login-container';
import { PersonalRegisterContainer } from './personal-register-container';
import { useCreateProperty } from '../../../hooks/useCreateProperty';
import { useGetCountry } from '../../../../../localization/get-country';

const LoaderContainer = styled.div`
  &&&& {
    > div {
      position: absolute;
    }
  }
`;

const PersonalValuationContainerBase: FC = () => {
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const { t } = useTranslation();
  const { countryCode } = useGetCountry();

  const [timifyParams, setTimifyParams] = useState<ITimifyParams | null>(null);
  const [reservationSuccess, setReservationSuccess] = useState(false);

  const selectedAddress = useAppSelector(
    (state) => state.valuationWizard.selectedAddress
  );
  const activeFormStep = useAppSelector(
    (state) => state.valuationWizard.activeStep
  );
  const isRegisterFormOpen = useAppSelector(
    (state) => state.valuationWizard.isRegisterFormOpen
  );
  const isLoginFormOpen = useAppSelector(
    (state) => state.valuationWizard.isLoginFormOpen
  );
  const isLoading = useAppSelector((state) => state.valuationWizard.isLoading);
  const valuationErrors = useAppSelector(
    (state) => state.valuationWizard.errors
  );
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const userId = useAppSelector((state) => state.auth.user?._id);
  const firstName = useAppSelector((state) => state.auth.user?.name);
  const lastName = useAppSelector((state) => state.auth.user?.surname);
  const email = useAppSelector((state) => state.auth.user?.email);
  const phone = useAppSelector((state) => state.auth.user?.phone);
  const userAuthInput = useAppSelector((state) => state.auth.userInput);
  const propertyDossier = useAppSelector(
    (state) => state.valuationWizard.propertyDossier
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  // Mutations
  const { createProperty } = useCreateProperty();
  const [createPropertyDossier] = useCreatePropertyDossierMutation();

  const onSubmit = useCallback(
    async (formData: IValuationForm, owner?: string) => {
      if (selectedAddress && owner) {
        batch(() => {
          dispatch(toggleLoading(true));
          dispatch(clearErrors());
        });

        const valuationInput = parseValuationInput(
          formData,
          selectedAddress
        ) as CreatePropertyInput;

        try {
          const createPropertyResponse = await createProperty({
            valuationInput,
            userId: owner,
          });
          if (createPropertyResponse.createProperty._id) {
            const createPropertyDossierResponse = await createPropertyDossier({
              input: {
                propertyId: createPropertyResponse.createProperty._id,
              },
            }).unwrap();
            if (
              createPropertyDossierResponse.createPropertyDossier?.dossierUrl
            ) {
              dispatch(
                setPropertyDossierAction(
                  createPropertyDossierResponse.createPropertyDossier
                )
              );
            }
          }
        } catch (error: unknown) {
          dispatch(setErrors(error as FormError));
        } finally {
          dispatch(toggleLoading(false));
        }
      }
    },
    [selectedAddress, dispatch, createProperty, createPropertyDossier]
  );

  const switchToRegisterForm = useCallback(
    () => dispatch(toggleRegisterForm(true)),
    [dispatch]
  );

  const switchToLoginForm = useCallback(() => {
    dispatch(toggleRegisterForm(false));
    dispatch(toggleLoginForm(true));
  }, [dispatch]);

  useEffect(() => {
    if (propertyDossier) {
      setTimifyParams({
        serviceExternalIds: TimifyPersonalisationIds.PersonalValuation,
        firstName: firstName ?? userAuthInput?.name,
        lastName: lastName ?? userAuthInput?.surname,
        email: email ?? userAuthInput?.email,
        phone: phone ?? `+${userAuthInput?.phonePrefix}${userAuthInput?.phone}`,
        country: countryCode,
        dossierUrl: propertyDossier?.dossierUrl ?? '',
        showServices,
        showFooterBackButton: '1',
      });
    }
  }, [
    countryCode,
    email,
    firstName,
    lastName,
    phone,
    propertyDossier,
    userAuthInput?.email,
    userAuthInput?.name,
    userAuthInput?.phone,
    userAuthInput?.phonePrefix,
    userAuthInput?.surname,
  ]);

  useEffect(() => {
    if (isMobileSize) {
      disableBodyOverflow();
    }
    return () => {
      if (isMobileSize) {
        disableBodyOverflow();
      }
    };
  }, [isMobileSize]);

  // Clean timify params on success reservation
  useEffect(() => {
    if (reservationSuccess) {
      setTimifyParams(null);
    }
  }, [reservationSuccess]);

  if (userId) {
    return (
      <FormContainer>
        {isLoading && (
          <LoaderContainer>
            <GlobalLoader />
          </LoaderContainer>
        )}
        {!isLoading && !reservationSuccess && !timifyParams && (
          <ValuationForm
            errors={valuationErrors}
            stepsCount={FORM_STEPS}
            activeFormStep={activeFormStep}
            onSubmit={onSubmit}
            submitForm={!isAnonymousUser}
          />
        )}
        {timifyParams && (
          <TimifyWidget
            {...timifyParams}
            setReservationSuccess={setReservationSuccess}
          />
        )}
        {reservationSuccess && !timifyParams && (
          <ConfirmEmail
            title={t('modal.register.confirm-email.personal-valuation.title')}
            textContent={t(
              'modal.register.confirm-email.personal-valuation.content'
            )}
          />
        )}
      </FormContainer>
    );
  }

  return (
    <FormContainer hasCleanInterface={isIframe}>
      <VisibilityWrapper
        isVisible={!isRegisterFormOpen && !isLoginFormOpen}
        isiframe={isIframe}
      >
        {isLoading ? (
          <LoaderContainer>
            <GlobalLoader />
          </LoaderContainer>
        ) : (
          <ValuationForm
            errors={valuationErrors}
            stepsCount={FORM_STEPS + (isAnonymousUser ? 1 : 0)}
            activeFormStep={activeFormStep}
            submitForm={!isAnonymousUser}
            lastStepCallback={switchToRegisterForm}
          />
        )}
      </VisibilityWrapper>
      {isRegisterFormOpen && (
        <PersonalRegisterContainer
          timifyParams={timifyParams}
          setTimifyParams={setTimifyParams}
          onSuccess={onSubmit}
          openLogin={switchToLoginForm}
        />
      )}
      {isLoginFormOpen && (
        <PersonalLoginContainer
          timifyParams={timifyParams}
          setTimifyParams={setTimifyParams}
          onSuccess={onSubmit}
        />
      )}
    </FormContainer>
  );
};

const PersonalValuationContainer = memo(PersonalValuationContainerBase);

export { PersonalValuationContainer };
