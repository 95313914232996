import { FC, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ValidationValueMessage } from 'react-hook-form/dist/types/validator';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WarningIcon } from 'assets/streamline-light/transportation/signs/road-sign-warning.svg';

import {
  EuropaceDataType,
  FinancingCalculators,
  FinancingLpSection,
  FinancingReason,
  FinancingType,
  MainEarner,
  PropertyUse,
  useReadEuropaceInterestRatesQuery,
  useReadFinancingCalculatorQuery,
} from '../../../../../generated';
import { InputV2 } from '../../../../forms/components/common/input-v2/input-v2';
import { SelectV2 } from '../../../../forms/components/common/select-v2/select-v2';
import OurRepresentativeExampleModal from '../our-representative-example-modal/our-representative-example-modal';
import {
  AmountPlaceholder,
  AmountPlaceholderBox,
  Box,
  BoxAmount,
  BoxLabel,
  ButtonBox,
  CalculateButton,
  Container,
  Content,
  ErrorBox,
  ErrorContainer,
  ErrorText,
  ErrorTitle,
  ExpandedText,
  GhostBtn,
  Icon,
  IconBox,
  IconBoxMobile,
  InputBox,
  InputContainer,
  InputItemBox,
  InputLabel,
  MainBtn,
  Title,
} from './financing-calculator-styles';
import { useOpenFinancing } from '../../../../forms/form-financing/hooks/callback-hooks/useOpenFinancing';
import { useGetFinancingPropositions } from '../../../../forms/form-financing/hooks/callback-hooks/useGetFinancingPropositions';
import { regionsOfGermany } from '../../../../forms/form-financing/components/forms/financing-edit-forms/financing-data-edit-form/fields-containers/kauf-fields/purchase-price-and-additional-costs/purchase-price-and-additional-costs';
import { numberFormat } from '../../../../financing/utils/numberFormat';
import { FIELDS } from '../../../../forms/form-financing/constants';
import IconCommon from '../../../../common/components/ui/icon';

interface IProps {
  section: FinancingLpSection;
  ourRepresentativeSection?: FinancingLpSection;
}

interface FinancingCalculatorData {
  data: {
    readFinancingCalculator: FinancingCalculators;
  };
  isLoading: boolean;
}

const FinancingCalculator: FC<IProps> = ({
  section: { recordIds },
  ourRepresentativeSection,
}) => {
  const { t } = useTranslation();
  const methods = useForm();
  const defValues = {
    federalState: regionsOfGermany[6].label,
    equityCapital: 180_000,
    householdNetIncome: 5000,
  };
  const theme = useContext(ThemeContext);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { openFinancing } = useOpenFinancing({});
  const {
    getFinancingPropositions,
    data: propositionsData,
    isLoading: isLoadingPropositions,
    // error,
  } = useGetFinancingPropositions();
  const formValues = methods.getValues();

  const { data: tableData } = useReadEuropaceInterestRatesQuery();

  const europaceInterestRates = tableData?.readEuropaceInterestRates;

  const { data, isLoading } =
    useReadFinancingCalculatorQuery<FinancingCalculatorData>({
      id: recordIds?.[0],
    });

  const requestValues = {
    isMaximumPurchasePrice: true,
    debitRateCommitment: '10',
    repaymentRate: 2,
    mainEarner: MainEarner.Employee,
    propertyUse: PropertyUse.SelfUsed,
  };

  useEffect(() => {
    const state = regionsOfGermany.find(
      (item) => item.value === defValues.federalState
    );
    getFinancingPropositions({
      input: {
        dataType: EuropaceDataType.FinancingCalculatorLandingPage,
        location: {
          coordinates: { latitude: 0, longitude: 0 },
          address: {
            postCode: state?.index || '',
            city: state?.value || '',
            region: state?.code || '',
          },
        },
        brokerageFee: true,
        brokerFeePercent: 0,
        reason: FinancingReason.PurchaseOfExistingProperty,
        equityCapital: defValues.equityCapital,
        householdNetIncome: defValues.householdNetIncome,
        purchasePrice: defValues.equityCapital + 500_000,
        ...requestValues,
      },
    });
  }, []);

  if (isLoading || !data || !data.readFinancingCalculator) return null;

  const {
    readFinancingCalculator: {
      expandedText,
      federalStateDropdownLabel,
      capitalContributionLabel,
      monthlyNetIncomeLabel,
      calculateCTALabel,
      maximumPurchasePriceLabel,
      maximumLoanAmountLabel,
      ghostButtonLabel,
      mainCTALabel,
      iconUrl,
      iconAlt,
      title,
    },
  } = data;

  const onCalculate = async () => {
    const values = methods.getValues();
    const state = regionsOfGermany.find(
      (item) => item.value === values.federalState
    );
    await getFinancingPropositions({
      input: {
        dataType: EuropaceDataType.FinancingCalculatorLandingPage,
        location: {
          coordinates: { latitude: 0, longitude: 0 },
          address: {
            postCode: state?.index || '',
            city: state?.value || '',
            region: state?.code || '',
          },
        },
        brokerageFee: true,
        brokerFeePercent: 0,
        reason: FinancingReason.PurchaseOfExistingProperty,
        equityCapital: values.equityCapital,
        householdNetIncome: values.householdNetIncome,
        purchasePrice: Number(values.equityCapital) + 500_000,
        ...requestValues,
      },
    });
  };

  const proposition = propositionsData?.[propositionsData?.length - 1];

  const equityCapitalMin = FIELDS.EQUITY_CAPITAL.rules
    ?.min as ValidationValueMessage;
  const equityCapitalMax = FIELDS.EQUITY_CAPITAL.rules
    ?.max as ValidationValueMessage;
  const isError = !isLoadingPropositions && !proposition?.maximalPurchasePrice;

  return (
    <Container>
      <Content>
        <IconBoxMobile>
          <Icon alt={iconAlt || ''} src={iconUrl || ''} />
        </IconBoxMobile>
        <Title>{title}</Title>
        <ExpandedText>{expandedText}</ExpandedText>
        <FormProvider {...methods}>
          <InputContainer>
            <InputBox>
              <InputLabel>{federalStateDropdownLabel}</InputLabel>
              <InputItemBox>
                <SelectV2
                  backgroundColor={'#f2f5f7'}
                  isNoBorder
                  name={'federalState'}
                  defaultValue={defValues.federalState}
                  selectOptions={regionsOfGermany}
                />
              </InputItemBox>
            </InputBox>
            <InputBox>
              <InputLabel>{capitalContributionLabel}</InputLabel>
              <InputItemBox>
                <InputV2
                  type={'number'}
                  inputAdornment="€"
                  backgroundColor={'#f2f5f7'}
                  isNoBorder
                  isSeparate
                  isValidateOnBlur
                  name={FIELDS.EQUITY_CAPITAL.name}
                  rules={{
                    ...FIELDS.EQUITY_CAPITAL.rules,
                    min: { ...equityCapitalMin, value: 28_000 },
                    max: { ...equityCapitalMax, value: 280_000 },
                  }}
                  defaultValue={defValues.equityCapital}
                />
              </InputItemBox>
            </InputBox>
            <InputBox buttonMargin>
              <InputLabel>{monthlyNetIncomeLabel}</InputLabel>
              <InputItemBox>
                <InputV2
                  type={'number'}
                  inputAdornment="€"
                  backgroundColor={'#f2f5f7'}
                  isNoBorder
                  isSeparate
                  isValidateOnBlur
                  name={FIELDS.HOUSEHOLD_NET_INCOME.name}
                  rules={FIELDS.HOUSEHOLD_NET_INCOME.rules}
                  defaultValue={defValues.householdNetIncome}
                />
              </InputItemBox>
            </InputBox>
            <CalculateButton
              label={calculateCTALabel}
              loader
              isLoading={isLoadingPropositions}
              onClick={methods.handleSubmit(onCalculate)}
            />
          </InputContainer>
        </FormProvider>
      </Content>
      <Box>
        {isError && (
          <ErrorContainer>
            <ErrorBox>
              <ErrorTitle>
                <IconCommon
                  icon={WarningIcon}
                  color={theme.blue}
                  height={16}
                  width={16}
                />{' '}
                {t('financing-landing-page.financing-calculator.error.label')}
              </ErrorTitle>
              <ErrorText>
                {t('financing-landing-page.financing-calculator.error.text')}
              </ErrorText>
            </ErrorBox>
          </ErrorContainer>
        )}
        <IconBox>
          <Icon alt={iconAlt || ''} src={iconUrl || ''} />
        </IconBox>
        <BoxLabel isError={isError} topMargin>
          {maximumPurchasePriceLabel}
        </BoxLabel>
        <BoxAmount isError={isError}>
          {isLoadingPropositions ? (
            <AmountPlaceholderBox>
              <AmountPlaceholder />
            </AmountPlaceholderBox>
          ) : (
            `${numberFormat(proposition?.maximalPurchasePrice) || 0} €`
          )}
        </BoxAmount>
        <BoxLabel isError={isError} gapMargin>
          {maximumLoanAmountLabel}
        </BoxLabel>
        <BoxAmount isError={isError}>
          {isLoadingPropositions ? (
            <AmountPlaceholderBox>
              <AmountPlaceholder delay />
            </AmountPlaceholderBox>
          ) : (
            `${numberFormat(proposition?.propositions?.[0]?.loanAmount) || 0} €`
          )}
        </BoxAmount>
        <ButtonBox isError={isError}>
          <GhostBtn onClick={() => setIsModalOpen(true)}>
            {ghostButtonLabel}
          </GhostBtn>
          <MainBtn
            label={mainCTALabel}
            onClick={() =>
              isError ? () => {} : openFinancing(FinancingType.Financing)
            }
          />
        </ButtonBox>
      </Box>
      {ourRepresentativeSection && europaceInterestRates && (
        <OurRepresentativeExampleModal
          purchasePrice={
            formValues.equityCapital
              ? Number(formValues.equityCapital) + 500_000
              : 0
          }
          equityCapital={formValues.equityCapital || 0}
          ourRepresentativeSection={ourRepresentativeSection}
          isOpen={isModalOpen}
          europaceInterestRates={europaceInterestRates[1]}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </Container>
  );
};

export default FinancingCalculator;
