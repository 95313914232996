import { FC, memo } from 'react';

import { AnyAction } from '@reduxjs/toolkit';
import { LocationScores, Poi, PropertyPoi } from '../../../../generated';
import { scoreNormalizer } from '../../utils/score-normalizer';
import { InfrastructureCard } from './infrastructure-card/infrastructure-card';
import { IMapPin } from '../../../map/interfaces';

export type PropertyPoiWithIndex = PropertyPoi & {
  [key: string]: Record<string, Poi[]>;
};

interface IProps {
  propertyPois?: PropertyPoiWithIndex;
  locationScores?: LocationScores | null;
  isShrinked?: boolean;
  isNewScoreFormat?: boolean;
  currentMapboxMarkers: IMapPin[];
  addMarkers: (pins: IMapPin[]) => AnyAction;
  removeMarkers: (category: string) => AnyAction;
}

const InfrastructureBase: FC<IProps> = ({
  propertyPois,
  locationScores,
  currentMapboxMarkers,
  addMarkers,
  removeMarkers,
  isNewScoreFormat,
}) => {
  const { transport, ...pois } = propertyPois || {};

  return (
    <>
      {Object.keys(pois ?? {}).map((categoryKey) => (
        <InfrastructureCard
          key={`infra-card-${categoryKey}`}
          isShrinked
          isNewScoreFormat={isNewScoreFormat}
          title={categoryKey}
          currentMapboxMarkers={currentMapboxMarkers}
          addMarkers={addMarkers}
          removeMarkers={removeMarkers}
          score={scoreNormalizer(
            (locationScores as LocationScores & { [key: string]: number })[
              categoryKey
            ] ?? locationScores?.family
          )}
          content={pois[categoryKey] as unknown as Record<string, unknown>}
        />
      ))}
    </>
  );
};

const Infrastructure = memo(InfrastructureBase);

export { Infrastructure };
