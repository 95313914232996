import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, List, ListItem, Title } from './simple-list-styles';
import { SimpleListItem } from '../../../../seller/constants';

interface IProps {
  title: string;
  items: SimpleListItem[];
}

const SimpleListBase: FC<IProps> = ({ title, items }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title content={t(title)} />
      <List>
        {items?.map((item) => (
          <ListItem>
            {item.link ? (
              <a href={item.link} target={'_blank'} rel="noreferrer">
                {t(item.label)}
              </a>
            ) : (
              <>{t(item.label)}</>
            )}
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

const SimpleList = memo(SimpleListBase);
export { SimpleList };
