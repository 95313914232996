import { SerializedError } from '@reduxjs/toolkit';
import { ClientError } from 'graphql-request';

import {
  CreateFinancingMutation,
  FinancingDataInput,
  FinancingVariantInput,
} from '../../../../../generated';
import { useCreateFinancingMutation } from '../../../../../services/graphql/enhanced';
import { useAppSelector } from '../../../../common/hooks';

type ICreateFinancing = {
  financingInput: {
    financingData: FinancingDataInput;
    financingVariants: FinancingVariantInput[];
  };
  userId: string;
};

type IReturn = {
  data: CreateFinancingMutation | undefined;
  error:
    | Pick<ClientError, 'name' | 'message' | 'stack'>
    | SerializedError
    | undefined;
  createFinancing: (
    props: ICreateFinancing
  ) => Promise<CreateFinancingMutation>;
};

export const useCreateFinancing = (): IReturn => {
  const [createFinancingMutation, { data, error }] =
    useCreateFinancingMutation();
  const marketingParameters = useAppSelector(
    (state) => state.auth.marketingParameters
  );

  const createFinancing = async ({
    financingInput,
    userId,
  }: ICreateFinancing) => {
    const financing = await createFinancingMutation({
      input: {
        ...financingInput,
        owner: userId,
        marketingParameters:
          marketingParameters ??
          `${window?.location?.pathname || ''}${
            window?.location?.search || ''
          }`,
      },
    }).unwrap();
    return financing;
  };

  return {
    data,
    error,
    createFinancing,
  };
};
