import { FC, useCallback, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as CommonFileText } from 'assets/streamline-light/files-folders/common-files/common-file-text.svg';
import { ReactComponent as View } from 'assets/streamline-light/interface-essential/view/view.svg';
import { ReactComponent as Finance } from 'assets/streamline-light/money-payments-finance/cash-payments/cash-payment-coins.svg';

import {
  ActionButton,
  IconLabel,
  RequestExposeButton,
  Row,
} from '../properties-tab-styles';
import { ButtonWithTooltip } from '../../../../../../common/components/ui/buttons/button-with-tooltip';
import { ToolTipBasicContent } from '../../../../../../common/components/tooltip/TooltipBasicContent';
import { hexToRGB } from '../../../../../../common/utils/hex-to-rgb';
import { useIsMobileSize } from '../../../../../../common/hooks/useIsMobileSize';
import { setSelectedTab } from '../../../../../../property/redux/dynamicMapUtilsSlice';
import { siteMap } from '../../../../../../../routes/site-map';
import { useGetCountry } from '../../../../../../localization/get-country';
import { setSuggestionProperty } from '../../../../../../forms/form-financing/redux/financingSlice';
import {
  CountryCodeFromVonPollDbEnum,
  FinancingType,
} from '../../../../../../../generated';
import { useOpenFinancing } from '../../../../../../forms/form-financing/hooks/callback-hooks/useOpenFinancing';
import { setMarketingParameters } from '../../../../../../auth/redux/authSlice';

interface IProps {
  propertyId: string;
  propertyPrice: number;
  isListView?: boolean;
  isRequestedForExpose?: boolean;
  detailsUrl: string;
  isLiked: boolean;
  isUnsuitable: boolean;
  isFavourite?: boolean;
  countryCode?: CountryCodeFromVonPollDbEnum | null;
  isUnlocked?: boolean;
  id: number;
  onClickOpenOverlay?: () => void;
}

const PropertyActionPanel: FC<IProps> = ({
  propertyId,
  isListView = false,
  isRequestedForExpose = false,
  detailsUrl,
  propertyPrice,
  id,
  isUnlocked = false,
  countryCode,
  onClickOpenOverlay,
}) => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { financingId } = useParams<{ financingId: string }>();
  const { language, getCountry } = useGetCountry();
  const { openFinancing } = useOpenFinancing({});

  const isGermany = countryCode === CountryCodeFromVonPollDbEnum.Deu;
  // const { isDeAtCh } = getCountry(countryCode || 'DE');
  // const userId = useAppSelector((state) => state.auth.user?._id);

  const isMobileSize = useIsMobileSize();

  const onClickRequestExpose = useCallback(() => {
    if (isUnlocked && onClickOpenOverlay) {
      dispatch(setSelectedTab(2));
      onClickOpenOverlay();
    } else {
      window.open(
        isGermany
          ? `${siteMap.RequestExposePage.pathWithoutParams}/${propertyId}?financingId=${financingId}&propertyId=${id}`
          : `https://www.von-poll.com/${language}/expose/immobilie/${id}`,
        '_blank'
      );
    }
  }, [
    dispatch,
    id,
    isGermany,
    // isDeAtCh,
    isUnlocked,
    language,
    onClickOpenOverlay,
    propertyId,
    financingId,
  ]);

  const onClickPropertyDetails = useCallback(() => {
    if (detailsUrl) {
      window.open(detailsUrl, '_blank');
    }
  }, [detailsUrl]);

  // suggested properties purchase price logic
  const onClickRecalculate = () => {
    openFinancing(FinancingType.Financing);
    dispatch(
      setSuggestionProperty({ purchasePrice: propertyPrice, propertyId: id })
    );
    const utmParams = `utm_source=kundenportal&utm_medium=finanzieren&utm_campaign=immoanfrage-alle&property_id=${id}`;
    const href = window?.location?.href;
    const markParams = href.includes('?')
      ? `${href}&${utmParams}`
      : `${href}?${utmParams}`;
    dispatch(setMarketingParameters(markParams));
  };

  const CalculateButton = !isListView ? (
    <ActionButton
      label={
        !isListView
          ? t('financing-portal-page.tabs.properties.property.calculate-btn')
          : ''
      }
      icon={Finance}
      color={themeContext.blue}
      borderColor={themeContext.blue}
      padding="12px 6px"
      width={isMobileSize ? '100%' : undefined}
      onClick={onClickRecalculate}
      iconStyle={{
        margin: isListView ? '0' : '0 8px 0 0',
        padding: '3px',
      }}
    />
  ) : (
    <ButtonWithTooltip
      id={`calculations-tooltip-${propertyId}`}
      Button={
        <ActionButton
          label=""
          icon={Finance}
          color={themeContext.blue}
          borderColor={themeContext.blue}
          padding="12px 6px"
          width={isMobileSize ? '100%' : undefined}
          onClick={onClickRecalculate}
          iconStyle={{
            margin: '0',
            padding: '3px',
          }}
        />
      }
      Tooltip={
        <ToolTipBasicContent
          content={
            <IconLabel>
              {t(
                'financing-portal-page.tabs.properties.property.calculate-btn'
              )}
            </IconLabel>
          }
        />
      }
    />
  );

  const DetailsButton = (
    <ButtonWithTooltip
      id={`details-tooltip-${propertyId}`}
      Button={
        <ActionButton
          label={
            isMobileSize
              ? t('financing-portal-page.tabs.properties.property.details-btn')
              : ''
          }
          onClick={onClickPropertyDetails}
          icon={View}
          color={hexToRGB(themeContext.black, 0.5)}
          borderColor={themeContext.ctaDisabledBackground}
          margin={isMobileSize ? '0' : '0 0 0 12px'}
          width={isMobileSize ? '100%' : 40}
          iconStyle={{
            margin: isMobileSize ? '0 8px 0 0' : '0',
            padding: '2px',
          }}
        />
      }
      Tooltip={
        <ToolTipBasicContent
          content={
            <IconLabel>
              {t('financing-portal-page.tabs.properties.property.details-btn')}
            </IconLabel>
          }
        />
      }
    />
  );

  return (
    <>
      {!isListView && !isMobileSize && (
        <Row alignItems={'end'} justifyContent={'space-between'}>
          <Row alignItems={'baseline'}>
            {isRequestedForExpose && !isUnlocked ? (
              <ButtonWithTooltip
                id={`expose-tooltip-${propertyId}`}
                Button={
                  <RequestExposeButton
                    label={t(
                      'search-profile.suggested-properties.request-expose.button'
                    )}
                    icon={CommonFileText}
                    iconStyle={{ padding: '3px', margin: '0 8px 0 0' }}
                    onClick={onClickRequestExpose}
                    disabled
                  />
                }
                Tooltip={
                  <ToolTipBasicContent
                    content={t(
                      'search-profile.suggested-properties.expose-requested.tooltip'
                    )}
                  />
                }
              />
            ) : (
              <RequestExposeButton
                label={t(
                  isUnlocked
                    ? 'search-profile.suggested-properties.expose-details'
                    : 'search-profile.suggested-properties.request-expose.button'
                )}
                icon={CommonFileText}
                iconStyle={{
                  padding: '3px',
                  margin: '0 8px 0 0',
                }}
                onClick={onClickRequestExpose}
                isUnlocked={isUnlocked}
                color={isUnlocked ? 'rgb(78, 115, 245)' : themeContext.white}
              />
            )}

            {CalculateButton}
          </Row>
          {DetailsButton}
        </Row>
      )}
      {isMobileSize && (
        <>
          {isRequestedForExpose && !isUnlocked ? (
            <ButtonWithTooltip
              id={`expose-tooltip-${propertyId}`}
              Button={
                <RequestExposeButton
                  label={t(
                    'search-profile.suggested-properties.request-expose.button'
                  )}
                  icon={CommonFileText}
                  iconStyle={{ padding: '3px', margin: '0 8px 0 0' }}
                  onClick={onClickRequestExpose}
                  isMobileSize
                  disabled
                />
              }
              Tooltip={
                <ToolTipBasicContent
                  content={t(
                    'search-profile.suggested-properties.expose-requested.tooltip'
                  )}
                />
              }
            />
          ) : (
            <RequestExposeButton
              label={t(
                isUnlocked
                  ? 'search-profile.suggested-properties.expose-details'
                  : 'search-profile.suggested-properties.request-expose.button'
              )}
              icon={CommonFileText}
              iconStyle={{
                padding: '3px',
                margin: '0 8px 0 0',
              }}
              onClick={onClickRequestExpose}
              isMobileSize
              isUnlocked={isUnlocked}
              color={isUnlocked ? 'rgb(78, 115, 245)' : themeContext.white}
            />
          )}
          <Row margin="0 0 12px 0">{CalculateButton}</Row>
          <Row>{DetailsButton}</Row>
        </>
      )}
      {isListView && (
        <Row alignItems={'baseline'} padding={'0 12px'} margin={'auto'}>
          {CalculateButton}

          <ButtonWithTooltip
            id={`expose-tooltip-${propertyId}`}
            stayOnHover
            Button={
              <>
                {isRequestedForExpose && !isUnlocked ? (
                  <RequestExposeButton
                    label={''}
                    isListView
                    icon={CommonFileText}
                    iconStyle={{ padding: '3px', margin: 0 }}
                    onClick={onClickRequestExpose}
                    disabled
                  />
                ) : (
                  <RequestExposeButton
                    label={''}
                    isListView
                    icon={CommonFileText}
                    iconStyle={{ padding: '3px', margin: 0 }}
                    onClick={onClickRequestExpose}
                  />
                )}
              </>
            }
            Tooltip={
              <ToolTipBasicContent
                content={
                  <IconLabel>
                    {t(
                      isRequestedForExpose
                        ? 'search-profile.suggested-properties.expose-requested.tooltip'
                        : 'search-profile.suggested-properties.request-expose.button'
                    )}
                  </IconLabel>
                }
              />
            }
          />
          {DetailsButton}
        </Row>
      )}
    </>
  );
};

export default PropertyActionPanel;
