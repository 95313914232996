import styled from 'styled-components';
import { device } from '../../../../../../style/theme';
import { BorderButton } from '../../../../../common/components/ui/buttons';

export const UserIconContainer = styled.div`
  display: flex;

  @media ${device.tablet} {
    justify-content: center;
  }
`;

export const UserIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
`;

export const InfoTitle = styled.div`
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};

  @media ${device.tablet} {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
  }
`;

export const InfoItemsContainer = styled.ul`
  list-style: none;
  margin: 16px 0 24px;

  @media ${device.tablet} {
    margin-bottom: 16px;
  }
`;

export const OneInfoItem = styled.li`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  margin-left: 12px;
  ::before {
    content: '\\2022';
    color: ${({ theme }) => theme.blue};
    font-weight: 900;
    display: inline-block;
    position: relative;
    width: 0;
    left: -12px;
  }
`;

export const TextItem = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 16px 0;
  opacity: 0.5;
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ theme }) => theme.blue};
  ::before,
  ::after {
    opacity: 0.5;
    display: inline-block;
    height: 1px;
    content: '';
    background-color: ${({ theme }) => theme.blue};
    max-width: 80px;
    flex-grow: 1;
    margin: 0 16px 4px;
    //transform: translateY(-1rem);
  }
`;

export const LoginButton = styled(BorderButton)`
  border-color: ${({ theme }) => theme.blue} !important;
  color: ${({ theme }) => theme.blue} !important;
`;

export const ImageContainer = styled.div`
  max-width: 180px;
  margin: 0 auto 16px;
`;

export const ProvenExpertImage = styled.img`
  width: 100%;
`;

export const MainButtonContainer = styled.div`
  position: relative;
  & > button {
    z-index: 2;
  }
`;

export const BlueCircle = styled.div`
  position: absolute;
  right: -16px;
  top: 8px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  background-color: #4e73f5;
  border-radius: 25px;
  z-index: 0;

  @media ${device.tablet} {
    display: none;
  }
`;

export const ArrowImage = styled.img<{
  left: number;
  top: number;
  isArrowUp?: boolean;
}>`
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  width: 140px;
  z-index: 5;
  ${({ isArrowUp }) => isArrowUp && `transform: scaleY(-1);`}

  @media ${device.tablet} {
    display: none;
  }
`;
