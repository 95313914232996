import { RegisterOptions, useController } from 'react-hook-form';
import styled from 'styled-components';

const SwitchBox = styled.label`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.blue};
`;

const Switch = styled.span`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  margin: 0 8px;
`;

const Text = styled.span`
  font-family: Roboto;
  opacity: 0.6;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: var(--dark-blue);
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:focus + span {
    box-shadow: 0 0 1px rgb(0, 48, 94);
  }

  &:checked + span:before {
    transform: translateX(10px);
    background: rgb(0, 48, 94);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border: 1px solid rgba(0, 48, 94, 0.5);
  transition: 0.4s;
  border-radius: 10px;

  &:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 3px;
    background-color: rgba(0, 48, 94, 0.5);
    transition: 0.4s;
    border-radius: 50%;
  }
`;

interface InputSwitchProps {
  name: string;
  yesLabel: string;
  noLabel: string;
  rules?: RegisterOptions;
}

const InputSwitch = ({
  name,
  rules,
  yesLabel,
  noLabel,
}: InputSwitchProps): JSX.Element => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    defaultValue: rules?.value ?? false,
    rules,
  });

  return (
    <SwitchBox>
      <Text>{noLabel}</Text>
      <Switch>
        <Input ref={ref} type="checkbox" {...inputProps} />
        <Slider />
      </Switch>
      <Text>{yesLabel}</Text>
    </SwitchBox>
  );
};

export default InputSwitch;
