import { FC, memo } from 'react';
import { TimeOnMarketLineChart } from './time-on-market-line-chart';

interface IProps {
  id: string;
  labels: string[];
  data: unknown;
}

const TimeOnMarketBase: FC<IProps> = (props) => {
  return <TimeOnMarketLineChart {...props} />;
};

const TimeOnMarket = memo(TimeOnMarketBase);

export { TimeOnMarket };
