import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { RadioButton, RadioContainer, Text } from './radio-select-input-styles';
import { InputV2 } from '../../../../../../components/common/input-v2/input-v2';
import { SelectV2 } from '../../../../../../components/common/select-v2/select-v2';
import { RateType } from '../../../../../../../../generated';
import { FIELDS } from '../../../../../constants';
import { IFinancingForm } from '../../../../../interface';

interface IProps {
  rate: RateType;
  setRate: (state: RateType) => void;
  finData: IFinancingForm;
  handleChangeProp: () => void;
}

const RadioSelectInputBase: FC<IProps> = ({
  rate,
  setRate,
  finData,
  handleChangeProp,
}) => {
  const { t } = useTranslation();
  const isDesiredRate = rate === RateType.DesiredRate;
  const methods = useFormContext();

  return (
    <>
      <RadioContainer>
        <RadioButton
          isChecked={!isDesiredRate}
          type="radio"
          onClick={(e) => {
            setRate(RateType.RepaymentRate);
            e.preventDefault();
            methods.setValue('rateType', RateType.RepaymentRate);
          }}
        />
        <SelectV2
          name={FIELDS.REPAYMENT_RATE.name}
          label={FIELDS.REPAYMENT_RATE.label}
          placeholder={FIELDS.REPAYMENT_RATE.placeholder}
          tooltipKey={FIELDS.REPAYMENT_RATE.tooltipKey}
          tooltipPosition="top"
          isTooltipKeyLeftSide
          isBoldTooltipKeyIcon
          isDisabled={isDesiredRate}
          defaultValue={finData.repaymentRate ?? 2}
          isOldDesign
          handleChangeProp={handleChangeProp}
          selectOptions={FIELDS.REPAYMENT_RATE.options}
          rules={FIELDS.REPAYMENT_RATE.rules}
        />
      </RadioContainer>
      <Text>
        {t('financing-portal-page.tabs.calculator.edit.inputs.or.label')}
      </Text>
      <RadioContainer>
        <RadioButton
          isChecked={isDesiredRate}
          type="radio"
          onClick={(e) => {
            setRate(RateType.DesiredRate);
            e.preventDefault();
            methods.setValue('rateType', RateType.DesiredRate);
          }}
        />
        <InputV2
          type="number"
          name={FIELDS.DESIRED_RATE.name}
          label={FIELDS.DESIRED_RATE.label}
          placeholder={FIELDS.DESIRED_RATE.placeholder}
          tooltipKey={FIELDS.DESIRED_RATE.tooltipKey}
          isTooltipKeyLeftSide
          isBoldTooltipKeyIcon
          tooltipPosition="top"
          tooltipWidth={280}
          inputAdornment="€"
          defaultValue={finData.desiredRate}
          isDisabled={!isDesiredRate}
          handleChangeProp={handleChangeProp}
          isOldDesign
          isValidateOnBlur
          isSeparate
          rules={FIELDS.DESIRED_RATE.rules}
        />
      </RadioContainer>
    </>
  );
};

const RadioSelectInput = memo(RadioSelectInputBase);

export { RadioSelectInput };
