import { AuthContainer } from './modules/auth/components/auth-container';
import { Routes } from './routes/routes';

const App = (): JSX.Element => (
  <AuthContainer>
    <Routes />
  </AuthContainer>
);

export default App;
