const abbrNum = (
  number: number | string,
  decPlaces = 0,
  addAbbrev = true
): number | string => {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = 10 ** decPlaces;
  const isNegative = Number(number) < 0;

  if (isNegative) {
    number = Number(number) * -1;
  }

  // Enumerate number abbreviations
  const abbrev = ['k', 'm', 'B', 'T'];

  // Go through the array backwards, so we do the largest first
  // eslint-disable-next-line no-plusplus
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = 10 ** ((i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((+number * decPlaces) / size) / decPlaces;
      if (addAbbrev) {
        // Add the letter for the abbreviation
        number = number.toString() + abbrev[i];
      }
      // We are done... stop
      break;
    }
  }

  if (isNegative) {
    return `-${number}`;
  }

  return number;
};

export { abbrNum };
