import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const OfferContainer = styled.div`
  border-radius: 3px;
  border: solid 1px #d9d9d9;
  display: flex;
  margin-top: 12px;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const SentOfferDataContainer = styled.div`
  border-right: solid 1px #d9d9d9;
  flex: 1;
  padding: 12px;

  @media ${device.tablet} {
    border: none;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};

  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 8px;
  }
`;

export const Recipient = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.67;
  margin-bottom: 4px;
`;

export const Price = styled.span`
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
`;

export const OfferInfosContainer = styled.div`
  display: flex;
  gap: 12px;
  @media ${device.tablet} {
    flex-direction: column;
    gap: 8px;
  }
`;

export const OfferInfoDetailsBox = styled.div`
  display: flex;
  gap: 12px;
`;

export const OfferInfoBox = styled.div`
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #002f5d;
  display: flex;
  align-items: flex-end;
`;

export const OfferInfoIcon = styled.div<{ iconColor?: string }>`
  height: 16px;
  width: 16px;
  margin-right: 8px;
  ${(props) => props.iconColor && `color: ${props.iconColor};`}
`;

export const ControlsContainer = styled.div`
  margin: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    margin: 0;
    padding: 12px;
    border-top: solid 1px #d9d9d9;
  }
`;

export const PdfButton = styled.button<{ disabled?: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 3px;
  color: ${({ theme }) => theme.blue};
  border: solid 2px currentColor;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  background: #fff;
  margin-right: 8px;
  ${(props) => props.disabled && 'opacity: 0.3;'}

  @media ${device.tablet} {
    margin-right: 12px;
    height: 40px;
    flex: 1;
  }
`;

export const DeleteButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 3px;
  border: solid 1px #d9d9d9;
  background-color: #fff;
  color: rgba(33, 37, 41, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media ${device.tablet} {
    height: 40px;
    flex: 1;
  }
`;
