import styled from 'styled-components';
import { MainButton } from '../../../../../../../common/components/ui/buttons';
import { hexToRGB } from '../../../../../../../common/utils/hex-to-rgb';

export const EditVariantButton = styled(MainButton)`
  background-color: transparent;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.blue};
  border: 2px solid ${({ theme }) => theme.blue};
  margin-top: 24px !important;
  &:hover {
    background-color: ${(props) => hexToRGB(props.theme.ctaBlue, 0.2)};
    border-color: ${({ theme }) => theme.blue};
  }
`;
export const AddNewVariantButton = styled(MainButton)`
  background-color: transparent;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.ctaBlue};
  border: 2px solid transparent;
  margin-top: 12px !important;
  &:hover {
    background-color: transparent;
    border-color: ${({ theme }) => theme.ctaBlue};
  }
`;
