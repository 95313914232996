import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../store';

const selectExperiments = (state: RootState) =>
  state.experiments._persist?.rehydrated;
// const selectValuationWizard = (state: RootState) =>
//   state.valuationWizard._persist?.rehydrated;
const selectAuth = (state: RootState) => state.auth._persist?.rehydrated;

export const selectIsStoreLoaded = createDraftSafeSelector(
  selectExperiments,
  selectAuth,
  (areExperimentsLoaded, isAuthLoaded) => areExperimentsLoaded && isAuthLoaded
);
