import { CSSProperties, FC } from 'react';

import { Modal } from '../../../../../../../common/components/modal';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import { toggleMobileEditWidget } from '../../../../../../../forms/form-financing/redux/financingSlice';
import { FinancingCalculationFormContainer } from '../../financing-calculation-form-container/financing-calculation-form-container';
import {
  FinancingProposition,
  FinancingVariantData,
  PersonalFinancingEntity,
} from '../../../../../../../../generated';
import { ModalHeader } from '../../../../../multiple-financings/compare-variants-mobile-modal/modal-header/modal-header';

interface IProps {
  isMortgageCertificate?: boolean;
  financingVariantData?: FinancingVariantData;
  personalFin?: PersonalFinancingEntity;
  financingProposition?: FinancingProposition;
}

const ModalMobileEditWidget: FC<IProps> = ({
  isMortgageCertificate,
  personalFin,
  financingVariantData,
  financingProposition,
}) => {
  const dispatch = useAppDispatch();
  const isOpenMobileEditWidget = useAppSelector(
    (state) => state.financing.editWidget.isOpenMobileEditWidget
  );
  const closeModal = () => dispatch(toggleMobileEditWidget(false));
  const wrapperStyles = {
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    padding: '0',
  };

  return (
    <Modal
      isOpen={isOpenMobileEditWidget}
      wrapperStyles={wrapperStyles as CSSProperties}
      onClose={closeModal}
      isHiddenScroll
    >
      <ModalHeader
        title="financing-portal-page.tabs.calculator.edit.title"
        onClose={closeModal}
      />
      <FinancingCalculationFormContainer
        personalFin={personalFin}
        financingVariantData={financingVariantData}
        financingProposition={financingProposition}
        isMortgageCertificate={isMortgageCertificate}
      />
    </Modal>
  );
};

export { ModalMobileEditWidget };
