import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const MapGalleryButton = styled.div`
  position: absolute;
  top: 26px;
  left: 24px;
  border-radius: 6.666667px;
  border: 1px solid ${({ theme }) => theme.blue};
  opacity: 1;
  background-color: ${({ theme }) => theme.white};
  cursor: pointer;
  z-index: 3;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .tooltip-text {
    font-family: Roboto, sans-serif;
    color: ${({ theme }) => theme.blue};
  }

  &:hover {
    background-color: ${({ theme }) => theme.greyBackground};
  }
  &:active {
    background-color: ${({ theme }) => theme.ctaDisabledBackground};
  }
`;

export const GalleryModalButton = styled.div`
  position: absolute;
  cursor: pointer;
  padding: 10px 16px;
  top: 26px;
  right: 24px;
  z-index: 3;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.blue};
  opacity: 1;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 1px;
  text-align: left;
  line-height: 20px;
  text-transform: uppercase;

  &:hover {
    background-color: ${({ theme }) => theme.greyBackground};
  }
  &:active {
    background-color: ${({ theme }) => theme.ctaDisabledBackground};
  }
`;
