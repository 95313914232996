import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  LoginResponse,
  RegistrationBy,
  useConfirmEmailMutation,
  useRequestVerificationCodeMutation,
  UserState,
  UserType,
  useSendSellerRegisteredNotificationMutation,
  useSetUserStateMutation,
  useSignInPropertiesMutation,
} from '../../../generated';
import { client } from '../../../services/graphql/graphql';
import { MainButton } from '../../common/components/ui/buttons';
import { Headline1 } from '../../common/components/ui/typography';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { parseGqlErrors } from '../../common/utils/parse-gql-errors';
import { LoginModal } from '../components/modals/login-modal';
import {
  login,
  setAnonymousUser,
  setUserState,
  toggleIsConfirmEmailSuccessdModalOpen,
  toggleIsLoginModalOpen,
} from '../redux/authSlice';
import { useLngHistoryPush } from '../../localization/lng-history-push';
import { PhoneVerificationForm } from './seller-sign-up-page/forms/phone-verification-form';
import { siteMap } from '../../../routes/site-map';

const ErrorContainer = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Headline = styled(Headline1)`
  max-width: 620px;
  margin-bottom: 20px;
  text-align: center;
`;

const ConfirmSellerEmailPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { search } = useLocation();

  const lngHPush = useLngHistoryPush();

  // Get confirmation code from url
  const { confirmation_code: confirmationCode, sub } =
    queryString.parse(search);

  const [didMount, setMount] = useState(false);
  const [shouldLogin, setShouldLogin] = useState(false);
  const [errors, setErrors] = useState<null | string>(null);

  const [confirmEmail] = useConfirmEmailMutation();
  const [signIn] = useSignInPropertiesMutation();
  const [requestVerificationCode] = useRequestVerificationCodeMutation();
  const [setUserStateRequest] = useSetUserStateMutation();
  const [sendSellerRegisteredNotification] =
    useSendSellerRegisteredNotificationMutation();

  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const userState = useAppSelector((state) => state.auth.userState);

  const onLogin = useCallback(() => {
    dispatch(toggleIsLoginModalOpen(true));
  }, [dispatch]);

  const confirmEmailAsync = useCallback(
    async (passedAccessToken?: string) => {
      if (confirmationCode) {
        try {
          // Execute confirmation mutation
          const confirmEmailResponse = await confirmEmail({
            input: {
              _id: sub as string,
              confirmationCode: confirmationCode as string,
              ...(passedAccessToken ? { accessToken: passedAccessToken } : {}),
            },
          }).unwrap();

          await sendSellerRegisteredNotification({
            userId: sub as string,
          });

          const confirmEmailData = confirmEmailResponse?.confirmEmail;
          if (confirmEmailData?.temporaryPassword) {
            const { temporaryPassword, username } = confirmEmailData;

            try {
              const loginResponse = await signIn({
                input: {
                  userAuthenticationKey: username,
                  password: temporaryPassword,
                  requestAccessToken: true,
                },
              }).unwrap();

              client.setHeader(
                'Authorization',
                `Bearer ${
                  (loginResponse?.signIn as LoginResponse)?.jwt?.token ?? ''
                }`
              );

              const signInParams = loginResponse.signIn as LoginResponse;

              if (signInParams.user.registrationBy === 'email') {
                await requestVerificationCode({
                  input: {
                    username: signInParams.user.username,
                    accessToken: signInParams?.jwt?.token ?? '',
                    refreshToken: signInParams?.jwt?.refreshToken ?? '',
                    attributeName: 'phone_number',
                    firstName: signInParams.user.name,
                    lastName: signInParams.user.surname,
                  },
                }).unwrap();

                await setUserStateRequest({
                  _id: signInParams.user._id,
                  state: UserState.VerifyPhone,
                });

                batch(() => {
                  dispatch(login(loginResponse.signIn as LoginResponse));
                  dispatch(setUserState(UserState.VerifyPhone));
                });
              } else if (
                signInParams.user.registrationBy === RegistrationBy.EmailOnly
              ) {
                // Selected address ?
                batch(() => {
                  dispatch(login(loginResponse.signIn as LoginResponse));
                  dispatch(setUserState(UserState.Verified));
                });
                return;
              } else {
                // Selected address ?
                batch(() => {
                  dispatch(login(loginResponse.signIn as LoginResponse));
                  dispatch(setUserState(UserState.VerifyPhone));
                });
                return;
              }
            } catch (error) {
              console.error(error);
            }
          }

          // If user doesn't have valuations or is in case B
          if (!confirmEmailData?.temporaryPassword) {
            dispatch(toggleIsConfirmEmailSuccessdModalOpen(true));
            if (
              confirmEmailData?.userType &&
              confirmEmailData?.userType[0] === UserType.Buyer
            ) {
              lngHPush(siteMap.BuyerLandingPage.path);
            } else if (
              confirmEmailData?.userType &&
              confirmEmailData?.userType[0] === UserType.Seller
            ) {
              lngHPush(siteMap.SellerLandingPage.path);
            } else if (
              confirmEmailData?.userType &&
              confirmEmailData?.userType[0] === UserType.Financier
            ) {
              lngHPush(siteMap.FinancingLandingPage.path);
            } else {
              lngHPush(siteMap.OwnerLandingPage.path);
            }
          }

          // if system has errors with token
          if (confirmEmailData && shouldLogin) {
            setShouldLogin(false);
            lngHPush('/');
          }
        } catch (error) {
          const parsedErrorResponse = parseGqlErrors(error?.message ?? '[]');
          const parsedErrors = parsedErrorResponse
            ?.map((e: Error) => e?.message)
            ?.join(', ');
          if (
            parsedErrors === 'error.user.does.not.exist' ||
            parsedErrors === 'error.user.account.already.confirmed'
          ) {
            lngHPush(siteMap.LoginSellerPage.pathWithoutParams);
            if (!accessToken) {
              // if not logged show login modal
              onLogin();
            }
          } else if (parsedErrors === 'error.access.token.expired') {
            setShouldLogin(true);
          } else {
            setErrors(parsedErrors);
          }
        }
      }
    },
    [
      confirmationCode,
      confirmEmail,
      sub,
      shouldLogin,
      signIn,
      requestVerificationCode,
      dispatch,
      lngHPush,
      accessToken,
      onLogin,
    ]
  );

  // Execute email confirmation
  useEffect(() => {
    if (!didMount) {
      dispatch(setAnonymousUser(true));
      confirmEmailAsync();
    }
    setMount(true);
  }, [
    didMount,
    confirmEmail,
    confirmationCode,
    dispatch,
    signIn,
    sub,
    confirmEmailAsync,
  ]);

  useEffect(() => {
    if (shouldLogin && accessToken) {
      confirmEmailAsync(accessToken);
    }
  }, [shouldLogin, accessToken, confirmEmailAsync]);

  if ((!confirmationCode || errors) && userState === UserState.Verified) {
    return (
      <ErrorContainer>
        <Headline content={t('error.confirm-page.email.already.verified')} />
        <MainButton onClick={onLogin} label={t('modal.login.submit')} />
      </ErrorContainer>
    );
  }

  return (
    <>
      <LoginModal isOpen={shouldLogin} />
      <PhoneVerificationForm />
    </>
  );
};

export { ConfirmSellerEmailPage };
