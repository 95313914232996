import { FC, useState } from 'react';
import styled from 'styled-components';
import ForgotPasswordForm from './forgot-password-form';
import LoginForm from './login-form';
import { ReactComponent as IconClose } from 'assets/streamline-light/interface-essential/form-validation/close.svg';

const Container = styled.div<{ isMobile?: boolean }>`
  // width: 342px;
  flex: 1 1 0;
  padding: 28px 32px;
  background: #fff;
  box-shadow: 0px 12px 27px rgba(0, 0, 0, 0.32);
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ isMobile }) =>
    isMobile &&
    `
    max-width: unset;
    width: 100vw;
    height: 100vh;
  `}
`;

const CloseButton = styled.button`
  width: 18px;
  height: 18px;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  margin-left: auto;
  margin-bottom: 32px;
`;

interface IProps {
  setIsAuthFormInProgress: (newValue: boolean) => void;
  isAuthFormInProgress: boolean;
  close: () => void;
  isMobile?: boolean;
}

const FormBox: FC<IProps> = ({
  setIsAuthFormInProgress,
  isAuthFormInProgress,
  close,
  isMobile,
}) => {
  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);

  return (
    <Container isMobile={isMobile}>
      <CloseButton onClick={close}>
        <IconClose />
      </CloseButton>
      {isForgotPassword ? (
        <ForgotPasswordForm
          isAuthFormInProgress={isAuthFormInProgress}
          setIsAuthFormInProgress={setIsAuthFormInProgress}
        />
      ) : (
        <LoginForm
          isAuthFormInProgress={isAuthFormInProgress}
          setIsAuthFormInProgress={setIsAuthFormInProgress}
          onForgotPassword={() => setIsForgotPassword(true)}
          close={close}
        />
      )}
    </Container>
  );
};

export default FormBox;
