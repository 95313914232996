export const variantsModalStyles = {
  width: '1040px',
  maxHeight: 'calc(100vh - 60px)',
  maxWidth: '1040px',
  padding: 0,
};

export const variantsModalMobileStyles = {
  maxWidth: '100%',
  maxHeight: '100%',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  padding: '0',
  borderRadius: '0',
  display: 'flex',
  flexDirection: 'column',
};

export interface IVariant {
  label: string;
  keyValue: string;
  subName?: string;
  values?: string[];
  isBold?: boolean;
  variantsLength?: number;
  deleteId?: number;
  subfields?: {
    label: string;
    keyValue: string;
    subName?: string;
    values?: string[];
  }[];
}

export interface IMobileVariant {
  categoryName?: string;
  dataTypes: {
    label: string;
    keyValue: string;
    subName?: string;
    initHeight?: number;
    values?: { name: string; value: string }[];
    value?: string;
    deleteId?: number;
  }[];
}

// first array is an array of table
// second arrays is an array of the values

const isBold = true;
const period = '15';

interface IExample {
  names: string[];
  variantsValues: IVariant[][];
}

export const example: IExample = {
  names: ['Variante 1', 'Variante 2', 'Variante 3', 'Variante 4', 'Variante 5'],
  variantsValues: [
    // first table
    [
      {
        label:
          'financing-portal-page.tabs.calculator.data.table.maximum-purchase-budget',
        keyValue: 'maximalPurchasePrice',
        values: [
          '900.000,00 €',
          '900.111,00 €',
          '900.000,00 €',
          '900.444,00 €',
          '900.555,00 €',
        ],
        isBold,
      },
    ],
    // second table
    [
      {
        label: 'purchase-intent.form.price',
        keyValue: 'purchasePrice',
        values: [
          '900.000,00 €',
          '900.111,00 €',
          '900.000,00 €',
          '900.444,00 €',
          '900.555,00 €',
        ],
      },
      {
        label: 'financing-portal-page.tabs.calculator.data.table.loan-amount',
        keyValue: 'loanAmount',
        values: [
          '900.000,00 €',
          '900.111,00 €',
          '900.000,00 €',
          '900.444,00 €',
          '900.555,00 €',
        ],
        isBold,
      },
    ],
    // third table
    [
      {
        label:
          'financing-portal-page.tabs.calculator.data.table.monthly-loan-installment',
        keyValue: 'totalRatePerMonth',
        values: [
          '900.000,00 €',
          '900.111,00 €',
          '900.000,00 €',
          '900.444,00 €',
          '900.555,00 €',
        ],
        // value: `${numberFormat(proposition.totalRatePerMonth)} €`,
        isBold,
      },
      {
        label:
          'financing-portal-page.tabs.calculator.data.table.debit-interest',
        keyValue: 'shouldInterest',
        values: [
          '900.000,00 €',
          '900.111,00 €',
          '900.000,00 €',
          '900.444,00 €',
          '900.555,00 €',
        ],
        // value: `${numberFormat(proposition.shouldInterest)} %`,
      },
      {
        label:
          'financing-portal-page.tabs.calculator.data.table.repayment-in-the-first-year',
        keyValue: 'repaymentRateInPercent',
        values: [
          '900.000,00 €',
          '900.111,00 €',
          '900.000,00 €',
          '900.444,00 €',
          '900.555,00 €',
        ],
        // value: `${numberFormat(proposition.financingBuildingBlocks[0].repaymentRateInPercent )} %`,
      },
      {
        label:
          'financing-portal-page.tabs.calculator.data.table.total-term-of-the-loan-until-full-repayment',
        keyValue: 'totalTermOfLoanUntilFullRepayment',
        values: [
          '900.000,00 €',
          '900.111,00 €',
          '900.000,00 €',
          '900.444,00 €',
          '900.555,00 €',
        ],
        // value: totalTermOfLoanUntilFullRepayment,
        isBold,
      },
    ],
    // fourth table
    [
      {
        label:
          'financing-portal-page.tabs.calculator.data.table.at-the-end-of-the-fixed-interest-period-of',
        keyValue: '',
        values: ['', '', '', '', ''],
        subName: `${period} Jahren`,
        isBold,
      },
      {
        label: 'financing-portal-page.tabs.calculator.data.table.residual-debt',
        keyValue: 'residualDebtAfterTheEndOfTheFixedInterestPeriod',
        values: [
          '900.000,00 €',
          '900.111,00 €',
          '900.000,00 €',
          '900.444,00 €',
          '900.555,00 €',
        ],
        // value: `${numberFormat(buildingBlock?.residualDebtAfterTheEndOfTheFixedInterestPeriod)} €`,
        isBold,
      },
      {
        label: 'financing-portal-page.tabs.calculator.data.table.repaid-amount',
        keyValue: 'repaidAmount',
        values: [
          '900.000,00 €',
          '900.111,00 €',
          '900.000,00 €',
          '900.444,00 €',
          '900.555,00 €',
        ],
        // value: `${numberFormat(repaidAmount)} €`,
      },
      {
        label:
          'financing-portal-page.tabs.calculator.data.table.total-interest-payments',
        keyValue: 'totalInterestPayments',
        values: [
          '900.000,00 €',
          '900.111,00 €',
          '900.000,00 €',
          '900.444,00 €',
          '900.555,00 €',
        ],
        // value: `${numberFormat(totalInterestPayments)} €`,
      },
    ],
    // fifth table
    [
      {
        label:
          'financing-portal-page.tabs.calculator.data.table.at-the-end-of-the-loan',
        keyValue: '',
        values: ['', '', '', '', ''],
        // value: '',
        isBold,
        subfields: [
          {
            label:
              'financing-portal-page.tabs.calculator.data.table.repaid-amount',
            keyValue: 'loanAmount',
            values: [
              '900.000,00 €',
              '900.111,00 €',
              '900.000,00 €',
              '900.444,00 €',
              '900.555,00 €',
            ],
            // value: `${numberFormat(proposition.loanAmount)} €`,
          },
        ],
      },
      {
        label:
          'financing-portal-page.tabs.calculator.data.table.total-interest-payments',
        keyValue: 'totalInterestPaymentsEnd',
        values: [
          '900.000,00 €',
          '900.111,00 €',
          '900.000,00 €',
          '900.444,00 €',
          '900.555,00 €',
        ],
        // value: `${numberFormat(totalInterestPaymentsEnd)} €`,
        isBold,
      },
      {
        label:
          'financing-portal-page.tabs.calculator.data.table.last-loan-installment',
        keyValue: 'closingRate',
        values: [
          '900.000,00 €',
          '900.111,00 €',
          '900.000,00 €',
          '900.444,00 €',
          '900.555,00 €',
        ],
        // value: `${numberFormat(buildingBlock.closingRate)} €`,
      },
    ],
  ],
};
