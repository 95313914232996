import { FC, useCallback, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/streamline-light/interface-essential/form-validation/close.svg';

import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { toggleSellerPortalModalGallery } from '../../../redux/sellerPropertiesSlice';
import { Modal } from '../../../../common/components/modal';
import { CloseButton } from '../../../../search-profile/pages/expose-details-overlay/components/slider/full-screen-gallery/full-screen-gallery-styles';
import Icon from '../../../../common/components/ui/icon';
import { SellerPropertyFullScreenGallery } from './seller-property-full-screen-gallery/seller-property-full-screen-gallery';
import { VonPollPropertyImage } from '../../../../../generated';
import { defaultTheme } from '../../../../../style/theme';

const wrapperStyles = {
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column' as const,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: hexToRGB(defaultTheme.blue, 1),
};

interface IProps {
  images: VonPollPropertyImage[];
  setActiveSlide: (index: number) => unknown;
  activeSlide: number;
}

const SellerPropertyGalleryModal: FC<IProps> = ({
  images,
  setActiveSlide,
  activeSlide,
}) => {
  const dispatch = useAppDispatch();
  const themeContext = useContext(ThemeContext);

  const { isOpenModal } = useAppSelector(
    (state) => state.sellerProperties.portalGallerySettings
  );

  const onClose = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      dispatch(toggleSellerPortalModalGallery(false));
    },
    [dispatch]
  );

  return (
    <Modal isOpen={isOpenModal} onClose={onClose} wrapperStyles={wrapperStyles}>
      <CloseButton onClick={onClose}>
        <Icon
          icon={CloseIcon}
          width={16}
          height={16}
          color={themeContext.blue}
        />
      </CloseButton>
      <SellerPropertyFullScreenGallery
        activeSlide={activeSlide}
        images={images}
        setActiveSlide={setActiveSlide}
      />
    </Modal>
  );
};

export { SellerPropertyGalleryModal };
