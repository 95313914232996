import { FC, memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Check } from 'assets/streamline-light/interface-essential/form-validation/check-1.svg';
import { ReactComponent as CashPaymentBag } from 'assets/streamline-light/money-payments-finance/cash-payments/cash-payment-bag.svg';
import { ReactComponent as Calendar } from 'assets/streamline-light/interface-essential/date-calendar/calendar.svg';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { UserStatus } from '../../../../../generated';
import {
  FromCity,
  Container,
  BidDate,
  Title,
  TitleWrapper,
  CityWrapper,
  CityName,
  OfferIcon,
  AcceptOfferBtn,
  Info,
} from './new-offer-styles';
import {
  EditedOfferBadge,
  NewOfferBadge,
} from '../offers-statistics-card-styles';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import {
  toggleAcceptPurchaseOfferModalOpen,
  setSelectedPurchaseOffer,
} from '../../../redux/sellerPropertiesSlice';
import { ButtonWithTooltip } from '../../../../common/components/ui/buttons/button-with-tooltip';
import { ToolTipBasicContent } from '../../../../common/components/tooltip/TooltipBasicContent';
import Icon from '../../../../common/components/ui/icon';
import { SimulationTooltip } from './tooltip/tooltip';

interface IProps {
  bidOnOfficeId: string;
  customerId: number;
  customerOnOfficeId: string;
  title: string;
  fromCity: string;
  offeredPrice: string;
  newOfferedPrice?: string | null;
  isHighestBid?: boolean;
  isNew?: boolean;
  isSimulation?: boolean;
  isAccepted?: boolean;
  isLast?: boolean;
  date: string;
  accordionId: string;
  setLastItemVisible: (accordionId: string, isVisible: boolean) => void;
  isDisabled?: boolean;
  userStatus?: UserStatus | null;
}

const OfferBase: FC<IProps> = ({
  bidOnOfficeId,
  customerId,
  customerOnOfficeId,
  title,
  fromCity,
  offeredPrice,
  newOfferedPrice,
  isHighestBid,
  isNew,
  isSimulation,
  isAccepted,
  date,
  isLast,
  accordionId,
  setLastItemVisible,
  isDisabled,
  userStatus,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();
  const theme = useContext(ThemeContext);
  const { ref, inView } = useInView({
    threshold: 1,
    fallbackInView: true,
  });

  const onAcceptOffer = useCallback(() => {
    if (!isSimulation && !isDisabled) {
      dispatch(
        setSelectedPurchaseOffer({
          bidOnOfficeId,
          customerId,
          price: offeredPrice,
          newPrice: newOfferedPrice,
          fromCity,
          customerOnOfficeId,
          date,
        })
      );
      dispatch(toggleAcceptPurchaseOfferModalOpen(true));
    }
  }, [
    isSimulation,
    isDisabled,
    dispatch,
    bidOnOfficeId,
    customerId,
    offeredPrice,
    newOfferedPrice,
    fromCity,
    customerOnOfficeId,
    date,
  ]);

  const formattedDate = useMemo(
    () => new Date(date).toLocaleDateString('en-GB').slice(0, -5),
    [date]
  );

  useEffect(() => {
    setLastItemVisible(accordionId, inView);
  }, [setLastItemVisible, accordionId, inView]);

  return (
    <Container
      onClick={(event) => event.stopPropagation()}
      isHighlighted={isHighestBid}
      ref={isLast ? ref : null}
    >
      {!isMobileSize && (
        <BidDate>
          <Icon
            icon={Calendar}
            width={16}
            height={16}
            color={theme.blue}
            style={{ display: 'block' }}
          />
          <div>{formattedDate}</div>
        </BidDate>
      )}
      <Info>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleWrapper>
            <Title content={title} />
          </TitleWrapper>
          <CityWrapper>
            <FromCity
              content={t('selling-simulation.offers-statistics-card.from-city')}
            />{' '}
            <CityName content={fromCity} />
          </CityWrapper>
          {isMobileSize && (
            <>
              {isNew && (
                <NewOfferBadge style={{ margin: '4px 0 0 0' }}>
                  {t(
                    'selling-simulation.offers-statistics-card.new-offer.badge'
                  )}
                </NewOfferBadge>
              )}
              {isHighestBid && (
                <NewOfferBadge isFilled style={{ margin: '4px 0 0 0' }}>
                  {t(
                    'selling-simulation.offers-statistics-card.highest-bid.badge'
                  )}
                </NewOfferBadge>
              )}
              {newOfferedPrice && (
                <EditedOfferBadge style={{ margin: '4px 0 0 0' }}>
                  {t(
                    'marketing-dashboard.offers-statistics-card.edited-offer.badge'
                  )}
                </EditedOfferBadge>
              )}
            </>
          )}
        </div>
        {!isMobileSize && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              marginLeft: '12px',
              marginBottom:
                newOfferedPrice && (isNew || isHighestBid) ? 0 : '16px',
            }}
          >
            {isNew && (
              <NewOfferBadge style={{ margin: 0 }}>
                {t('selling-simulation.offers-statistics-card.new-offer.badge')}
              </NewOfferBadge>
            )}
            {isHighestBid && (
              <NewOfferBadge isFilled style={{ margin: 0 }}>
                {t(
                  'selling-simulation.offers-statistics-card.highest-bid.badge'
                )}
              </NewOfferBadge>
            )}
            {newOfferedPrice && (
              <EditedOfferBadge style={{ margin: 0 }}>
                {t(
                  'marketing-dashboard.offers-statistics-card.edited-offer.badge'
                )}
              </EditedOfferBadge>
            )}
          </div>
        )}
      </Info>

      <OfferIcon
        iconStyle={{
          width: isMobileSize ? '20px' : '24px',
          height: isMobileSize ? '20px' : '24px',
        }}
        digitStyle={{
          fontSize: newOfferedPrice
            ? isMobileSize
              ? '8px'
              : '10px'
            : isMobileSize
            ? '12px'
            : '14px',
          textDecoration: newOfferedPrice ? 'line-through' : 'none',
          opacity: newOfferedPrice ? 0.5 : 1,
        }}
        newDigitStyle={{
          color: theme.green2,
          fontSize: isMobileSize ? '12px' : '14px',
        }}
        labelStyle={{ fontSize: isMobileSize ? '9px' : '10px' }}
        icon={CashPaymentBag}
        value={offeredPrice}
        newValue={newOfferedPrice}
        label={t('selling-simulation.offers-statistics-card.price-offer')}
      />
      {userStatus === UserStatus.Spectator ? null : isAccepted ||
        isSimulation ? (
        isSimulation ? (
          <SimulationTooltip />
        ) : (
          <ButtonWithTooltip
            id={`offer-accepted-tooltip-${bidOnOfficeId}`}
            Button={
              <AcceptOfferBtn
                label={''}
                icon={Check}
                iconWidth={16}
                iconHeight={16}
                iconStyle={{ margin: 0 }}
                onClick={undefined}
                backgroundColor={theme.green3}
                backgroundHoverColor={theme.ctaGreenHover}
              />
            }
            Tooltip={
              <ToolTipBasicContent
                content={t('accept-purchase-offer.tooltip.offer-accepted')}
              />
            }
          />
        )
      ) : (
        <AcceptOfferBtn
          label={''}
          icon={Check}
          iconWidth={16}
          iconHeight={16}
          iconStyle={{ margin: 0 }}
          onClick={onAcceptOffer}
        />
      )}
    </Container>
  );
};

const Offer = memo(OfferBase);

export { Offer };
