import { isNil } from 'lodash';

import {
  CreatePropertyInput,
  DealType,
  PropertyCode,
  PropertyCondition,
  PropertyData,
  PropertyQuality,
} from '../../../generated';
import { Feature } from '../../../services/mapbox/interfaces';
import { isoCountries } from '../constants';
import { IValuationForm } from '../form-property-valuation-wizard/interface';

export const parseValuationInput = (
  formData: IValuationForm,
  geoCodingResult: Feature
): Partial<CreatePropertyInput> => {
  const { center, address, context, text } = geoCodingResult;
  const mapboxStreet = text;
  const mapboxNumber = address;

  let mapboxPostCode = '';
  let mapboxCity = '';
  let mapboxCountryCode = '';

  context?.forEach((c) => {
    if (c?.id.includes('postcode')) {
      mapboxPostCode = c.text;
    }
    if (c?.id.includes('place')) {
      mapboxCity = c.text;
    }
    if (c?.id.includes('country')) {
      // mapboxCountry = c.text;
      mapboxCountryCode = c.short_code ?? '';
    }
  });

  const countryCode =
    Object.keys(isoCountries).find(
      // (key: string) => isoCountries[key].toLowerCase() === mapboxCountry.toLowerCase()
      (key: string) => key.toLowerCase() === mapboxCountryCode.toLowerCase()
    ) ?? 'DE';

  const location = {
    coordinates: {
      longitude: center[0],
      latitude: center[1],
    },
    address: {
      houseNumber: mapboxNumber,
      postCode: mapboxPostCode,
      street: mapboxStreet,
      city: mapboxCity,
    },
  };

  const {
    code,
    subcode,
    address: propertyAddress,
    buildingYear: buildingYearRaw,
    renovationYear: renovationYearRaw,
    postCode,
    street,
    number,
    condition: conditionInput,
    quality: qualityInput,
    ratingReason,
    otherRatingReason,
    timeHorizont,
    numberOfRooms: numberOfRoomsRaw,
    numberOfBathrooms: numberOfBathroomsRaw,
    numberOfIndoorParkingSpaces: numberOfIndoorParkingSpacesRaw,
    numberOfOutdoorParkingSpaces: numberOfOutdoorParkingSpacesRaw,
    livingArea: livingAreaRaw,
    landArea: landAreaRaw,
    balconyArea: balconyAreaRaw,
    energyLabel,
    ...rest
  } = formData;

  const condition: PropertyCondition = {
    overall: conditionInput,
  };

  const quality: PropertyQuality = {
    overall: qualityInput,
  };

  if (code !== PropertyCode.MultiFamilyHouse) {
    delete condition?.overall;
    delete quality?.overall;

    condition.bathrooms = conditionInput;
    condition.flooring = conditionInput;
    condition.kitchen = conditionInput;
    condition.windows = conditionInput;

    quality.bathrooms = qualityInput;
    quality.flooring = qualityInput;
    quality.kitchen = qualityInput;
    quality.windows = qualityInput;

    if (code === PropertyCode.House) {
      condition.masonry = conditionInput;
      quality.masonry = qualityInput;
    }
  }

  const buildingYear = +buildingYearRaw;
  const renovationYear = Number(renovationYearRaw);
  const numberOfRooms = +numberOfRoomsRaw;
  const numberOfBathrooms = +numberOfBathroomsRaw;
  const numberOfIndoorParkingSpaces = +numberOfIndoorParkingSpacesRaw;
  const numberOfOutdoorParkingSpaces = +numberOfOutdoorParkingSpacesRaw;
  const balconyArea = +balconyAreaRaw;

  const livingArea = +livingAreaRaw;
  const landArea = +landAreaRaw;

  let propertyData: PropertyData = {
    ...rest,
    ...(!isNil(energyLabel) ? { energyLabel } : {}),
    livingArea,
    buildingYear,
    ...(!isNil(renovationYear) && !Number.isNaN(renovationYear)
      ? { renovationYear }
      : {}),
    ...(!isNil(landAreaRaw) && !Number.isNaN(landAreaRaw) ? { landArea } : {}),
    ...(!isNil(balconyArea) && !Number.isNaN(balconyArea)
      ? { balconyArea }
      : {}),
    propertyType: {
      code,
    },
    location,
    quality,
    condition,
  };

  if (code !== PropertyCode.MultiFamilyHouse) {
    propertyData = {
      ...propertyData,
      numberOfRooms,
      ...(!isNil(numberOfBathrooms) && !Number.isNaN(numberOfBathrooms)
        ? { numberOfBathrooms }
        : {}),
      ...(!isNil(numberOfIndoorParkingSpaces) &&
      !Number.isNaN(numberOfIndoorParkingSpaces)
        ? { numberOfIndoorParkingSpaces }
        : {}),
      ...(!isNil(numberOfOutdoorParkingSpaces) &&
      !Number.isNaN(numberOfOutdoorParkingSpaces)
        ? { numberOfOutdoorParkingSpaces }
        : {}),
      propertyType: {
        ...propertyData.propertyType,
        subcode,
      },
    };
  }

  return {
    propertyData,
    countryCode,
    timeHorizont,
    ratingReason,
    otherRatingReason,
    dealType: DealType.Sale,
  };
};

// const { center, place_name: placeName } = mostAccurateGeocodingResult;

// const [
//   mapboxStreet,
//   mapboxNumber,
//   mapboxPostCode,
//   mapboxCity,
//   mapboxCountry,
// ] = placeName.split(/[ ,]+/);

// const countryCode =
//   Object.keys(isoCountries).find(
//     (key: string) =>
//       isoCountries[key].toLowerCase() === mapboxCountry.toLowerCase()
//   ) ?? 'DE';

// const {
//   code,
//   buildingYear,
//   livingArea,
//   landArea,
//   condition: conditionInput,
//   quality: qualityInput,
//   numberOfRooms,
//   numberOfBathrooms,
//   numberOfIndoorParkingSpaces,
//   numberOfOutdoorParkingSpaces,
//   ratingReason,
//   numberOfUnits,
// } = formData;

// const location = {
//   coordinates: {
//     longitude: center[0],
//     latitude: center[1],
//   },
//   address: {
//     houseNumber: mapboxNumber,
//     postCode: mapboxPostCode,
//     street: mapboxStreet,
//     city: mapboxCity,
//   },
// };

// const condition: PropertyCondition = {
//   overall: conditionInput,
// };

// const quality: PropertyQuality = {
//   overall: qualityInput,
// };

// if (code !== PropertyCode.MultiFamilyHouse) {
//   delete condition?.overall;
//   delete quality?.overall;

//   condition.bathrooms = conditionInput;
//   condition.flooring = conditionInput;
//   condition.kitchen = conditionInput;
//   condition.windows = conditionInput;

//   quality.bathrooms = qualityInput;
//   quality.flooring = qualityInput;
//   quality.kitchen = qualityInput;
//   quality.windows = qualityInput;

//   if (code === PropertyCode.House) {
//     condition.masonry = conditionInput;
//     quality.masonry = qualityInput;
//   }
// }

// // TODO Adjust accordingly
// const propertyData = {
//   buildingYear: buildingYear ? +buildingYear : 0,
//   condition,
//   quality,
//   // TODO
//   // landArea,
//   livingArea: livingArea ? +livingArea : 0,
//   location,
//   propertyType: {
//     code,
//   },
//   numberOfRooms: numberOfRooms ? +numberOfRooms : 0,
//   numberOfBathrooms: numberOfBathrooms ? +numberOfBathrooms : 0,
//   numberOfIndoorParkingSpaces: numberOfIndoorParkingSpaces
//     ? +numberOfIndoorParkingSpaces
//     : 0,
//   numberOfOutdoorParkingSpaces: numberOfOutdoorParkingSpaces
//     ? +numberOfOutdoorParkingSpaces
//     : 0,
//   numberOfUnits: 1,
// };
