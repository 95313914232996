import styled from 'styled-components';

export const ControlsContainer = styled.div`
  position: absolute;
  bottom: 106px;
  left: 4px;
  z-index: 0;
  color: #dfdfdf;
  display: flex;
  flex-direction: column;

  > div {
    position: static !important;
    border: none;
    border-radius: 2px;

    > div {
      margin: 0 0 10px 10px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3) !important;
      border-radius: 2px;

      > button {
        width: 34px;
        height: 34px;
        border: none;
        margin: 0;
        border-radius: 2px;

        &:nth-child(2) {
          border-radius: 0;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
`;

export const MarkerAddress = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  width: fit-content;
  max-width: 250px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  margin-left: calc(50% - 100%);
  margin-top: -8px;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MarkerImg = styled.img``;

export const Container = styled.div<{
  width?: string;
  hasTopRadius?: boolean;
}>`
  width: 100%;
  height: 184px;
  position: absolute;
  pointer-events: all;
  transform: translateY(0);
  left: 0;
  z-index: 0;
  transition: transform 665ms ease-in-out, opacity 665ms ease;
  will-change: transform, opacity;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: hidden;

  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl,
  .mapboxgl-ctrl-attrib-inner {
    display: none !important;
  }
`;
