import { capitalize } from 'lodash';
import { PropertyCode } from '../../../generated';

export const buildPropertyTitle = (
  type: PropertyCode,
  numberOfRooms: number,
  city: string,
  livingArea: number
): string => {
  let typeName;

  switch (type) {
    case PropertyCode.Apartment:
      typeName = 'Wohnung';
      break;
    case PropertyCode.House:
      typeName = 'Haus';
      break;
    case PropertyCode.MultiFamilyHouse:
      typeName = 'Mehrfamilienhaus';
      break;
    case PropertyCode.Plot:
      typeName = 'Grundstück';
      break;
    default:
      typeName = '';
      break;
  }

  switch (type) {
    case PropertyCode.Apartment:
    case PropertyCode.House:
      return `${numberOfRooms}-Zimmer-${capitalize(
        typeName
      )} in ${city}, ca. ${livingArea}m²`;
    case PropertyCode.MultiFamilyHouse:
      return `Mehrfamilienhaus in ${city}, ca. ${livingArea}m²`;
    default:
      return `${typeName} in ${city}`;
  }
};
