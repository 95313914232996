import apartmentNormalIcon from 'assets/images/icons/property-valuation/apartment.png';
import apartmentPenthouseIcon from 'assets/images/icons/property-valuation/penthouse.png';
import apartmentMaisonetteIcon from 'assets/images/icons/property-valuation/maisonette.png';
import apartmentAtticIcon from 'assets/images/icons/property-valuation/attikawohnung.png';
import apartmentTerracedIcon from 'assets/images/icons/property-valuation/terrassenwohnung.png';
import apartmentStudioIcon from 'assets/images/icons/property-valuation/einzimmerwohnung.png';

import houseDetachedIcon from 'assets/images/icons/property-valuation/einfamillienhaus.png';
import houseFarmIcon from 'assets/images/icons/property-valuation/bauernhof.png';
import houseRowCornerIcon from 'assets/images/icons/property-valuation/reiheneckhaus.png';
import houseRowMiddleIcon from 'assets/images/icons/property-valuation/reihenmittelhaus.png';
import houseSemiDetachedIcon from 'assets/images/icons/property-valuation/doppelhaushäfte.png';

import {
  Condition,
  PropertySubcode,
  Quality,
  RatingReason,
  TimeHorizont,
} from '../../../../generated';
import { BoxType } from '../../interface';
import { ReactComponent as OneStar } from '../../../../assets/images/icons/stars/1-star.svg';
import { ReactComponent as TwoStar } from '../../../../assets/images/icons/stars/2-star.svg';
import { ReactComponent as ThreeStar } from '../../../../assets/images/icons/stars/3-star.svg';
import { ReactComponent as FourStar } from '../../../../assets/images/icons/stars/4-star.svg';
import { ReactComponent as Calendar } from '../../../../assets/streamline-light/interface-essential/date-calendar/calendar.svg';
import {
  ICustomFieldType,
  TField,
} from '../../components/fullscreen-modal/interface';
import {
  EnergyEffiencyHubspot,
  EnergyEffiencyHubspotSwitzerland,
} from '../interface';

export const usefulInfoKeyPhrases = [
  'property-valuation.form.redesign.useful-info.first',
  'property-valuation.form.redesign.useful-info.second',
  'property-valuation.form.redesign.useful-info.third',
];

export const FORM_STEPS = 8;

export enum Proximity {
  GERMANY = '9.993682,53.551086',
  AUSTRIA = '15.4395,47.0707',
  SWITZERLAND = '7.3606,46.2331',
}

export const energyLabelOptions = Object.entries(EnergyEffiencyHubspot).map(
  (entry) => ({
    value: entry[0],
    label: entry[1],
  })
);

export const energyLabelOptionsSwitzerland = Object.entries(
  EnergyEffiencyHubspotSwitzerland
).map((entry) => ({
  value: entry[0],
  label: entry[1],
}));

export const VALUATION_FORM_KEYS = {
  CODE: 'code',
  SUBCODE: 'subcode',
  POST_CODE: 'postCode',
  STREET: 'street',
  NUMBER: 'number',
  BUILDING_YEAR: 'buildingYear',
  LIVING_AREA: 'livingArea',
  LAND_AREA: 'landArea',
  NUMBER_OF_UNITS: 'numberOfUnits',
  CONDITION: 'condition',
  QUALITY: 'quality',
  NUMBER_OF_ROOMS: 'numberOfRooms',
  NUMBER_OF_BATHROOMS: 'numberOfBathrooms',
  NUMBER_OF_INDOOR_PARKING_SPACES: 'numberOfIndoorParkingSpaces',
  NUMBER_OF_OUTDOOR_PARKING_SPACES: 'numberOfOutdoorParkingSpaces',
  RATING_REASON: 'ratingReason',
  OTHER_RATING_REASON: 'otherRatingReason',
  TIME_HORIZONT: 'timeHorizont',
  CITY: 'city',
  RENOVATION_YEAR: 'renovationYear',
  FLOOR_NUMBER: 'floorNumber',
  NUMBER_OF_FLOORS_IN_BUILDING: 'numberOfFloorsInBuilding',
  ANNUAL_RENT_INCOME: 'annualRentIncome',
  BALCONY_AREA: 'balconyArea',
  ENERGY_LABEL: 'energyLabel',
  IS_NEW: 'isNew',
  HAS_POOL: 'hasPool',
  HAS_SAUNA: 'hasSauna',
  ADDRESS: 'address',
};

export const apartmentBoxes = [
  {
    boxType: BoxType.iconBox,
    title: 'property-valuation.form.redesign.subtypes.apartment.apartment',
    value: PropertySubcode.ApartmentNormal,
    iconImage: apartmentNormalIcon,
  },
  {
    boxType: BoxType.iconBox,
    title: 'property-valuation.form.redesign.subtypes.apartment.penthouse',
    value: PropertySubcode.ApartmentPenthouse,
    iconImage: apartmentPenthouseIcon,
  },
  {
    boxType: BoxType.iconBox,
    title: 'property-valuation.form.redesign.subtypes.apartment.maisonette',
    value: PropertySubcode.ApartmentMaisonette,
    iconImage: apartmentMaisonetteIcon,
  },
  {
    boxType: BoxType.iconBox,
    title:
      'property-valuation.form.redesign.subtypes.apartment.attic-apartment',
    value: PropertySubcode.ApartmentAttic,
    iconImage: apartmentAtticIcon,
  },
  {
    boxType: BoxType.iconBox,
    title:
      'property-valuation.form.redesign.subtypes.apartment.terrace-apartment',
    value: PropertySubcode.ApartmentTerraced,
    iconImage: apartmentTerracedIcon,
  },
  {
    boxType: BoxType.iconBox,
    title:
      'property-valuation.form.redesign.subtypes.apartment.one-room-apartment',
    value: PropertySubcode.ApartmentStudio,
    iconImage: apartmentStudioIcon,
  },
];

export const houseBoxes = [
  {
    boxType: BoxType.iconBox,
    title: 'property-valuation.form.redesign.subtypes.house.detached-house',
    value: PropertySubcode.HouseDetached,
    iconImage: houseDetachedIcon,
  },
  {
    boxType: BoxType.iconBox,
    title:
      'property-valuation.form.redesign.subtypes.house.semi-detached-house',
    value: PropertySubcode.HouseSemiDetached,
    iconImage: houseSemiDetachedIcon,
  },
  {
    boxType: BoxType.iconBox,
    title: 'property-valuation.form.redesign.subtypes.house.row-corner-house',
    value: PropertySubcode.HouseRowCorner,
    iconImage: houseRowCornerIcon,
  },
  {
    boxType: BoxType.iconBox,
    title: 'property-valuation.form.redesign.subtypes.house.mid-terrace-house',
    value: PropertySubcode.HouseRowMiddle,
    iconImage: houseRowMiddleIcon,
  },
  {
    boxType: BoxType.iconBox,
    title: 'property-valuation.form.redesign.subtypes.house.farm-yard',
    value: PropertySubcode.HouseFarm,
    iconImage: houseFarmIcon,
  },
];

export const FIELDS: Record<string, TField> = {
  CITY: {
    name: VALUATION_FORM_KEYS.CITY,
    // tooltipKey: 'property-valuation.form.redesign.city.tooltip',
    label: 'property-valuation.form.redesign.city.label',
    placeholder: 'property-valuation.form.redesign.city.placeholder',
    isDisabled: true,
  },
  BUILDING_YEAR: {
    name: VALUATION_FORM_KEYS.BUILDING_YEAR,
    type: 'number',
    tooltipKey: 'property-valuation.form.redesign.building-year.tooltip',
    label: 'property-valuation.form.redesign.building-year.label',
    placeholder: 'property-valuation.form.redesign.building-year.placeholder',
    isRequired: true,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1850,
        message: 'property-valuation.form.redesign.building-year.error-min',
      },
      max: {
        value: new Date().getFullYear() + 3,
        message: 'property-valuation.form.redesign.building-year.error-max',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  RENOVATION_YEAR: {
    name: VALUATION_FORM_KEYS.RENOVATION_YEAR,
    type: 'number',
    tooltipKey: 'property-valuation.form.redesign.renovation-year.tooltip',
    label: 'property-valuation.form.redesign.renovation-year.label',
    placeholder: 'property-valuation.form.redesign.renovation-year.placeholder',
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1950,
        message: 'property-valuation.form.redesign.renovation-year.error-min',
      },
      max: {
        value: new Date().getFullYear() + 3,
        message: 'property-valuation.form.redesign.renovation-year.error-max',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  LIVING_AREA: {
    name: VALUATION_FORM_KEYS.LIVING_AREA,
    type: 'number',
    tooltipKey: '',
    inputAdornment: 'm²',
    label: 'property-valuation.form.redesign.living-area.label',
    placeholder: 'property-valuation.form.redesign.living-area.placeholder',
    isRequired: true,
    // defaultValue: 10,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 10,
        message: 'property-valuation.form.redesign.living-area.error-min',
      },
      max: {
        value: 800,
        message: 'property-valuation.form.redesign.living-area.error-max',
      },
      // value: 10,
      valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 10 || 'property-valuation.form.redesign.living-area.error-min',
        max: (v: number): boolean | string =>
          v <= 800 || 'valuation.form.second-step.living-area-max-error',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  LIVING_AREA_SWITZERLAND: {
    name: VALUATION_FORM_KEYS.LIVING_AREA,
    type: 'number',
    tooltipKey: '',
    label: 'property-valuation.form.redesign.living-area.label',
    placeholder: 'property-valuation.form.redesign.living-area.placeholder',
    isRequired: true,
    inputAdornment: 'm²',
    // defaultValue: 20,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 20,
        message:
          'property-valuation.form.redesign.living-area.error-min-switzerland',
      },
      max: {
        value: 800,
        message: 'property-valuation.form.redesign.living-area.error-max',
      },
      // value: 20,
      valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 20 ||
          'property-valuation.form.redesign.living-area.error-min-switzerland',
        max: (v: number): boolean | string =>
          v <= 800 || 'valuation.form.second-step.living-area-max-error',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  LIVING_AREA_MULTI_FAMILY_HOUSE: {
    name: VALUATION_FORM_KEYS.LIVING_AREA,
    type: 'number',
    tooltipKey: '',
    label: 'property-valuation.form.redesign.living-area.label',
    placeholder: 'property-valuation.form.redesign.living-area.placeholder',
    isRequired: true,
    inputAdornment: 'm²',
    // defaultValue: 10,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 10,
        message: 'property-valuation.form.redesign.living-area.error-min',
      },
      max: {
        value: 5000,
        message:
          'property-valuation.form.redesign.living-area.error-max-multi-family-house',
      },
      // value: 10,
      valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 10 || 'property-valuation.form.redesign.living-area.error-min',
        max: (v: number): boolean | string =>
          v <= 5000 ||
          'property-valuation.form.redesign.living-area.error-max-multi-family-house',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  LIVING_AREA_MULTI_FAMILY_HOUSE_SWITZERLAND: {
    name: VALUATION_FORM_KEYS.LIVING_AREA,
    type: 'number',
    tooltipKey: '',
    label: 'property-valuation.form.redesign.living-area.label',
    placeholder: 'property-valuation.form.redesign.living-area.placeholder',
    isRequired: true,
    inputAdornment: 'm²',
    // defaultValue: 20,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 20,
        message:
          'property-valuation.form.redesign.living-area.error-min-switzerland',
      },
      max: {
        value: 5000,
        message:
          'property-valuation.form.redesign.living-area.error-max-multi-family-house',
      },
      // value: 20,
      valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 10 || 'property-valuation.form.redesign.living-area.error-min',
        max: (v: number): boolean | string =>
          v <= 5000 ||
          'property-valuation.form.redesign.living-area.error-max-multi-family-house',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  FLOOR_NUMBER: {
    name: VALUATION_FORM_KEYS.FLOOR_NUMBER,
    type: 'number',
    customType: ICustomFieldType.Number,
    tooltipKey: '',
    inputAdornment: 'von',
    // TODO: rename 'property-valuation.form.redesign.floor-number.label' to "Etage der Wohnung"
    label: 'Etage der Wohnung',
    placeholder: 'property-valuation.form.redesign.floor-number.placeholder',
    // isRequired: true,
    // defaultValue: 0,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 0,
        message: 'property-valuation.form.redesign.floor-number.error-min',
      },
      max: {
        value: 20,
        message: 'property-valuation.form.redesign.floor-number.error-max',
      },
      // value: 0,
      valueAsNumber: false,
      validate: {
        min: (v: number): boolean | string =>
          v >= 0 || 'property-valuation.form.redesign.floor-number.error-min',
        max: (v: number): boolean | string =>
          v <= 20 || 'property-valuation.form.redesign.floor-number.error-max',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  NUMBER_OF_FLOORS_IN_BUILDING: {
    name: VALUATION_FORM_KEYS.NUMBER_OF_FLOORS_IN_BUILDING,
    type: 'number',
    customType: ICustomFieldType.Number,
    tooltipKey: '',
    label:
      'property-valuation.form.redesign.number-of-floors-in-building.label',
    placeholder:
      'property-valuation.form.redesign.number-of-floors-in-building.placeholder',
    // isRequired: true,
    // defaultValue: 1,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1,
        message:
          'property-valuation.form.redesign.number-of-floors-in-building.error-min',
      },
      max: {
        value: 20,
        message:
          'property-valuation.form.redesign.number-of-floors-in-building.error-max',
      },
      // value: 1,
      valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 1 ||
          'property-valuation.form.redesign.number-of-floors-in-building.error-min',
        max: (v: number): boolean | string =>
          v <= 20 ||
          'property-valuation.form.redesign.number-of-floors-in-building.error-max',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  NUMBER_OF_UNITS: {
    name: VALUATION_FORM_KEYS.NUMBER_OF_UNITS,
    type: 'number',
    customType: ICustomFieldType.Number,
    tooltipKey: '',
    label: 'property-valuation.form.redesign.number-of-units.label',
    placeholder: 'property-valuation.form.redesign.number-of-units.placeholder',
    isRequired: true,
    // defaultValue: 3,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 3,
        message: 'property-valuation.form.redesign.number-of-units.error-min',
      },
      max: {
        value: 25,
        message: 'property-valuation.form.redesign.number-of-units.error-max',
      },
      // value: 3,
      valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 3 ||
          'property-valuation.form.redesign.number-of-units.error-min',
        max: (v: number): boolean | string =>
          v <= 25 ||
          'property-valuation.form.redesign.number-of-units.error-max',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  LAND_AREA: {
    name: VALUATION_FORM_KEYS.LAND_AREA,
    type: 'number',
    tooltipKey: '',
    label: 'property-valuation.form.redesign.land-area.label',
    placeholder: 'property-valuation.form.redesign.land-area.placeholder',
    isRequired: true,
    inputAdornment: 'm²',
    // defaultValue: 50,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 50,
        message: 'property-valuation.form.redesign.land-area.error-min',
      },
      max: {
        value: 5000,
        message: 'property-valuation.form.redesign.land-area.error-max',
      },
      // value: 50,
      valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 50 || 'property-valuation.form.redesign.land-area.error-min',
        max: (v: number): boolean | string =>
          v <= 5000 || 'property-valuation.form.redesign.land-area.error-max',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  LAND_AREA_MULTI_FAMILY_HOUSE: {
    name: VALUATION_FORM_KEYS.LAND_AREA,
    type: 'number',
    tooltipKey: '',
    label: 'property-valuation.form.redesign.land-area.label',
    placeholder: 'property-valuation.form.redesign.land-area.placeholder',
    isRequired: true,
    inputAdornment: 'm²',
    // defaultValue: 0,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 0,
        message:
          'property-valuation.form.redesign.land-area.error-min-multi-family-house',
      },
      max: {
        value: 5000,
        message: 'property-valuation.form.redesign.land-area.error-max',
      },
      // value: 0,
      valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 0 ||
          'property-valuation.form.redesign.land-area.error-min-multi-family-house',
        max: (v: number): boolean | string =>
          v <= 5000 || 'property-valuation.form.redesign.land-area.error-max',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  ANNUAL_RENT_INCOME: {
    name: VALUATION_FORM_KEYS.ANNUAL_RENT_INCOME,
    type: 'number',
    tooltipKey: '',
    label: 'property-valuation.form.redesign.annual-rent-income.label',
    placeholder:
      'property-valuation.form.redesign.annual-rent-income.placeholder',
    isRequired: true,
    isSeparate: true,
    // defaultValue: 5000,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 5000,
        message:
          'property-valuation.form.redesign.annual-rent-income.error-min',
      },
      max: {
        value: 1_000_000,
        message:
          'property-valuation.form.redesign.annual-rent-income.error-max',
      },
      // value: 5000,
      valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 5000 ||
          'property-valuation.form.redesign.annual-rent-income.error-min',
        max: (v: number): boolean | string =>
          v <= 1_000_000 ||
          'property-valuation.form.redesign.annual-rent-income.error-max',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  NUMBER_OF_ROOMS: {
    name: VALUATION_FORM_KEYS.NUMBER_OF_ROOMS,
    type: 'number',
    tooltipKey: 'property-valuation.form.redesign.rooms.tooltip',
    customType: ICustomFieldType.Number,
    label: 'property-valuation.form.redesign.rooms.label',
    placeholder: 'property-valuation.form.redesign.rooms.placeholder',
    isRequired: true,
    // defaultValue: 1,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1,
        message: 'property-valuation.form.redesign.rooms.error-min',
      },
      max: {
        value: 20,
        message: 'property-valuation.form.redesign.rooms.error-max',
      },
      // value: 1,
      valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 1 || 'property-valuation.form.redesign.rooms.error-min',
        max: (v: number): boolean | string =>
          v <= 20 || 'property-valuation.form.redesign.rooms.error-max',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  NUMBER_OF_BATHROOMS: {
    name: VALUATION_FORM_KEYS.NUMBER_OF_BATHROOMS,
    type: 'number',
    tooltipKey: 'property-valuation.form.redesign.bathrooms.tooltip',
    customType: ICustomFieldType.Number,
    label: 'property-valuation.form.redesign.bathrooms.label',
    placeholder: 'property-valuation.form.redesign.bathrooms.placeholder',
    // isRequired: true,
    defaultValue: 1,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1,
        message: 'property-valuation.form.redesign.bathrooms.error-min',
      },
      max: {
        value: 5,
        message: 'property-valuation.form.redesign.bathrooms.error-max',
      },
      // value: 1,
      valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 1 || 'property-valuation.form.redesign.bathrooms.error-min',
        max: (v: number): boolean | string =>
          v <= 5 || 'property-valuation.form.redesign.bathrooms.error-max',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  BALCONY_AREA: {
    name: VALUATION_FORM_KEYS.BALCONY_AREA,
    type: 'number',
    tooltipKey: 'property-valuation.form.redesign.balcony-area.tooltip',
    label: 'property-valuation.form.redesign.balcony-area.label',
    placeholder: 'property-valuation.form.redesign.balcony-area.placeholder',
    inputAdornment: 'm²',
    // defaultValue: 0,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 0,
        message: 'property-valuation.form.redesign.balcony-area.error-min',
      },
      max: {
        value: 200,
        message: 'property-valuation.form.redesign.balcony-area.error-max',
      },
      // value: 0,
      valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 0 || 'property-valuation.form.redesign.balcony-area.error-min',
        max: (v: number): boolean | string =>
          v <= 200 || 'property-valuation.form.redesign.balcony-area.error-max',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  NUMBER_OF_INDOOR_PARKING_SPACES: {
    name: VALUATION_FORM_KEYS.NUMBER_OF_INDOOR_PARKING_SPACES,
    type: 'number',
    tooltipKey: 'property-valuation.form.redesign.garage.tooltip',
    customType: ICustomFieldType.Number,
    label: 'property-valuation.form.redesign.garage.label',
    placeholder: 'property-valuation.form.redesign.garage.placeholder',
    // defaultValue: 0,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 0,
        message: 'property-valuation.form.redesign.garage.error-min',
      },
      max: {
        value: 6,
        message: 'property-valuation.form.redesign.garage.error-max',
      },
      // value: 0,
      // valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 0 || 'property-valuation.form.redesign.garage.error-min',
        max: (v: number): boolean | string =>
          v <= 6 || 'property-valuation.form.redesign.garage.error-max',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  NUMBER_OF_OUTDOOR_PARKING_SPACES: {
    name: VALUATION_FORM_KEYS.NUMBER_OF_OUTDOOR_PARKING_SPACES,
    type: 'number',
    tooltipKey: 'property-valuation.form.redesign.parking.tooltip',
    customType: ICustomFieldType.Number,
    label: 'property-valuation.form.redesign.parking.label',
    placeholder: 'property-valuation.form.redesign.parking.placeholder',
    // defaultValue: 0,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 0,
        message: 'property-valuation.form.redesign.parking.error-min',
      },
      max: {
        value: 6,
        message: 'property-valuation.form.redesign.parking.error-max',
      },
      // value: 0,
      // valueAsNumber: true,
      validate: {
        min: (v: number): boolean | string =>
          v >= 0 || 'property-valuation.form.redesign.parking.error-min',
        max: (v: number): boolean | string =>
          v <= 6 || 'property-valuation.form.redesign.parking.error-max',
      },
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  ENERGY_LABEL: {
    name: VALUATION_FORM_KEYS.ENERGY_LABEL,
    label: 'property-valuation.form.redesign.energy-label.label',
    placeholder: 'property-valuation.form.redesign.energy-label.placeholder',
    customType: ICustomFieldType.Select,
    options: energyLabelOptions,
  },
  ENERGY_LABEL_SWITZERLAND: {
    name: VALUATION_FORM_KEYS.ENERGY_LABEL,
    label: 'property-valuation.form.redesign.energy-label.label',
    placeholder: 'property-valuation.form.redesign.energy-label.placeholder',
    customType: ICustomFieldType.Select,
    options: energyLabelOptionsSwitzerland,
  },
  IS_NEW: {
    name: VALUATION_FORM_KEYS.IS_NEW,
    customType: ICustomFieldType.Checkbox,
    label: 'property-valuation.form.redesign.is-new.label',
  },
  HAS_POOL: {
    name: VALUATION_FORM_KEYS.HAS_POOL,
    customType: ICustomFieldType.Checkbox,
    label: 'property-valuation.form.redesign.has-pool.label',
  },
  HAS_SAUNA: {
    name: VALUATION_FORM_KEYS.HAS_SAUNA,
    customType: ICustomFieldType.Checkbox,
    label: 'property-valuation.form.redesign.has-sauna.label',
  },
  QUALITY: {
    name: VALUATION_FORM_KEYS.QUALITY,
    customType: ICustomFieldType.BoxRating,
    isBoxTabletColumn: true,
    boxes: [
      {
        title: 'property-valuation.form.redesign.quality.simple.title',
        text: 'property-valuation.form.redesign.quality.simple.content',
        value: Quality.Simple,
        icon: OneStar,
        iconColor: 'rgb(102, 131, 158)',
        iconSelectedColor: '#00305E',
        fillIconColor: '#7f97ae',
        boxType: BoxType.iconBox,
      },
      {
        title: 'property-valuation.form.redesign.quality.normal.title',
        text: 'property-valuation.form.redesign.quality.normal.content',
        value: Quality.Normal,
        icon: TwoStar,
        iconColor: 'rgb(102, 131, 158)',
        iconSelectedColor: '#00305E',
        fillIconColor: '#7f97ae',
        boxType: BoxType.iconBox,
      },
      {
        title: 'property-valuation.form.redesign.quality.high_quality.title',
        text: 'property-valuation.form.redesign.quality.high_quality.content',
        value: Quality.HighQuality,
        icon: ThreeStar,
        iconColor: 'rgb(102, 131, 158)',
        iconSelectedColor: '#00305E',
        fillIconColor: '#7f97ae',
        boxType: BoxType.iconBox,
      },
      {
        title: 'property-valuation.form.redesign.quality.luxury.title',
        text: 'property-valuation.form.redesign.quality.luxury.content',
        value: Quality.Luxury,
        icon: FourStar,
        iconColor: 'rgb(102, 131, 158)',
        iconSelectedColor: '#00305E',
        fillIconColor: '#7f97ae',
        boxType: BoxType.iconBox,
      },
    ],
  },
  CONDITION: {
    name: VALUATION_FORM_KEYS.CONDITION,
    customType: ICustomFieldType.BoxRating,
    isBoxTabletColumn: true,
    boxes: [
      {
        title:
          'property-valuation.form.redesign.condition.renovation-needed.title',
        text: 'property-valuation.form.redesign.condition.renovation-needed.content',
        value: Condition.RenovationNeeded,
        icon: OneStar,
        iconColor: 'rgb(102, 131, 158)',
        iconSelectedColor: '#00305E',
        fillIconColor: '#7f97ae',
        boxType: BoxType.iconBox,
      },
      {
        title:
          'property-valuation.form.redesign.condition.well-maintained.title',
        text: 'property-valuation.form.redesign.condition.well-maintained.content',
        value: Condition.WellMaintained,
        icon: TwoStar,
        iconColor: 'rgb(102, 131, 158)',
        iconSelectedColor: '#00305E',
        fillIconColor: '#7f97ae',
        boxType: BoxType.iconBox,
      },
      {
        title:
          'property-valuation.form.redesign.condition.new/recently-renovated.title',
        text: 'property-valuation.form.redesign.condition.new/recently-renovated.content',
        value: Condition.NewOrRecentlyRenovated,
        icon: ThreeStar,
        iconColor: 'rgb(102, 131, 158)',
        iconSelectedColor: '#00305E',
        fillIconColor: '#7f97ae',
        boxType: BoxType.iconBox,
      },
    ],
  },
  RATING_REASON: {
    name: VALUATION_FORM_KEYS.RATING_REASON,
    customType: ICustomFieldType.BoxRating,
    isBoxTabletColumn: true,
    boxes: [
      {
        title: 'property-valuation.form.redesign.rating-reason.sell.label',
        value: RatingReason.Sell,
        boxType: BoxType.iconBox,
        isDisabledCustomOnClick: true,
      },
      {
        title: 'property-valuation.form.redesign.rating-reason.buy.label',
        value: RatingReason.Buy,
        boxType: BoxType.iconBox,
        isDisabledCustomOnClick: true,
      },
      {
        title:
          'property-valuation.form.redesign.rating-reason.estimated-result.label',
        value: RatingReason.EstimatedResult,
        boxType: BoxType.iconBox,
      },
      {
        title: 'property-valuation.form.redesign.rating-reason.other.label',
        value: RatingReason.Other,
        boxType: BoxType.iconBox,
        isDisabledCustomOnClick: true,
      },
    ],
  },
  OTHER_RATING_REASON: {
    name: VALUATION_FORM_KEYS.OTHER_RATING_REASON,
    type: 'text',
    tooltipKey: '',
    label: 'property-valuation.form.redesign.rating-reason.other.input.label',
    placeholder:
      'property-valuation.form.redesign.rating-reason.other.input.placeholder',
    isWrapper: true,
  },
  TIME_HORIZONT: {
    name: VALUATION_FORM_KEYS.TIME_HORIZONT,
    customType: ICustomFieldType.BoxRating,
    isBoxTabletColumn: true,
    containerTitle: 'valuation.form.fifth-step.time-horizont.title',
    boxes: [
      {
        title: 'property-valuation.form.redesign.time-horizont.1-3.label',
        value: TimeHorizont.UpTo3Months,
        boxType: BoxType.iconBox,
        icon: Calendar,
      },
      {
        title: 'property-valuation.form.redesign.time-horizont.4-12.label',
        value: TimeHorizont.UpTo12Months,
        boxType: BoxType.iconBox,
        icon: Calendar,
      },
      {
        title: 'property-valuation.form.redesign.time-horizont.>12.label',
        value: TimeHorizont.MoreThan12Months,
        boxType: BoxType.iconBox,
        icon: Calendar,
      },
    ],
  },
};

export const showServices: string[] = [
  '61448cf4aa12c7116c8a9779',
  '61488c30d6c6e21177ef69eb',
];

export const APARTMENT_SUBTYPES = [
  {
    label: 'valuation.form.subtypes.apartment.apartment',
    value: PropertySubcode.ApartmentNormal,
  },
  {
    label: 'valuation.form.subtypes.apartment.penthouse',
    value: PropertySubcode.ApartmentPenthouse,
  },
  {
    label: 'valuation.form.subtypes.apartment.maisonette',
    value: PropertySubcode.ApartmentMaisonette,
  },
  {
    label: 'valuation.form.subtypes.apartment.attic-apartment',
    value: PropertySubcode.ApartmentAttic,
  },
  {
    label: 'valuation.form.subtypes.apartment.terrace-apartment',
    value: PropertySubcode.ApartmentTerraced,
  },
  {
    label: 'valuation.form.subtypes.apartment.one-room-apartment',
    value: PropertySubcode.ApartmentStudio,
  },
];

export const HOUSE_SUBTYPES = [
  {
    label: 'valuation.form.subtypes.house.detached-house',
    value: PropertySubcode.HouseDetached,
  },
  {
    label: 'valuation.form.subtypes.house.semi-detached-house',
    value: PropertySubcode.HouseSemiDetached,
  },
  {
    label: 'valuation.form.subtypes.house.row-corner-house',
    value: PropertySubcode.HouseRowCorner,
  },
  {
    label: 'valuation.form.subtypes.house.mid-terrace-house',
    value: PropertySubcode.HouseRowMiddle,
  },
  {
    label: 'valuation.form.subtypes.house.farm-yard',
    value: PropertySubcode.HouseFarm,
  },
];
