import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Content,
  Title,
  Item,
  ItemLabel,
  ItemValue,
  SyncDateContainer,
  SyncDateLabel,
  SyncDateValue,
  Info,
  TooltipContainer,
  TooltipParagraph,
} from './general-statistics-card-styles';
import { simulationHighestBidPrice } from '../selling-simulation/dummy-data';
import { MarketingGeneralStatistics } from '../../../../generated';
import { useCurrency } from '../../../property/hooks/useCurrency';
import { formatPrice } from '../../utils/formatPrice';
import { Tooltip } from '../../../common/components/tooltip';
import { ReactComponent as InformationCircle } from '../../../../assets/streamline-light/interface-essential/alerts/information-circle.svg';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { formatNumber } from '../../utils/formatNumber';

export const formatSyncDate = (date: Date) => {
  let day = String(date.getDate());
  let month = String(date.getMonth() + 1);
  const year = String(date.getFullYear());
  let hours = String(date.getHours());
  let minutes = String(date.getMinutes());

  if (day?.toString()?.length < 2) {
    day = `0${day}`;
  }
  if (month?.toString()?.length < 2) {
    month = `0${month}`;
  }
  if (hours?.toString()?.length < 2) {
    hours = `0${hours}`;
  }
  if (minutes?.toString()?.length < 2) {
    minutes = `0${minutes}`;
  }

  return `${day}.${month}.${year} - ${hours}:${minutes} Uhr`;
};

interface IProps {
  statistics?: MarketingGeneralStatistics | null;
  statisticsLastSync?: Date | null;
  isBlurred?: boolean;
  isSimulation?: boolean;
  currentValuation?: number | null;
  showRequestedExposes?: boolean;
  showUnlockedExposes?: boolean;
  showViewingAppointments?: boolean;
}

const GeneralStatisticsCardBase: FC<IProps> = ({
  statistics,
  isBlurred,
  isSimulation,
  currentValuation,
  statisticsLastSync,
  showRequestedExposes,
  showUnlockedExposes,
  showViewingAppointments,
}) => {
  const { t } = useTranslation();
  const priceCurrency = useCurrency();
  const isMobileSize = useIsMobileSize();

  const formattedStatistics = useMemo(() => {
    const result = [
      {
        name: 'advertisementViews',
        label: 'selling-simulation.general-statistics-card.views-number',
        value: String(formatNumber(statistics?.advertisementViews ?? 0)),
        tooltipText:
          'marketing-dashboard.advertisement-views-statistics.tooltip',
      },
    ];

    if (showRequestedExposes) {
      result.push({
        name: 'requestedExposes',
        label:
          'selling-simulation.general-statistics-card.requested-exposes-number',
        value: String(formatNumber(statistics?.requestedExposes ?? 0)),
        tooltipText: '',
      });
    }

    if (showUnlockedExposes) {
      result.push({
        name: 'unlockedExposes',
        label:
          'selling-simulation.general-statistics-card.unlocked-exposes-number',
        value: String(formatNumber(statistics?.unlockedExposes ?? 0)),
        tooltipText: 'marketing-dashboard.unlocked-exposes-statistics.tooltip',
      });
    }

    if (showViewingAppointments) {
      result.push({
        name: 'viewingAppointments',
        label: 'selling-simulation.general-statistics-card.appointments-number',
        value: String(formatNumber(statistics?.viewingAppointments ?? 0)),
        tooltipText: '',
      });
    }

    if (statistics?.impressions) {
      result.unshift({
        name: 'impressions',
        label: 'selling-simulation.general-statistics-card.impressions',
        value: String(formatNumber(statistics?.impressions ?? 0)),
        tooltipText: 'marketing-dashboard.impressions-statistics.tooltip',
      });
    }

    if (statistics?.bookmarks) {
      result.push({
        name: 'bookmarks',
        label: 'selling-simulation.general-statistics-card.bookmarks',
        value: String(formatNumber(statistics?.bookmarks ?? 0)),
        tooltipText: '',
      });
    }

    result.push(
      {
        name: 'bidsNumber',
        label: 'selling-simulation.general-statistics-card.bids-number',
        value: String(formatNumber(statistics?.bids ?? 0)),
        tooltipText: '',
      },
      {
        name: 'highestBid',
        label: 'selling-simulation.general-statistics-card.highest-bid',
        value: formatPrice(
          isSimulation && currentValuation
            ? simulationHighestBidPrice(currentValuation)
            : statistics?.highestBid ?? 0,
          priceCurrency === '€' ? 'EUR' : 'CHF'
        ),
        tooltipText: '',
      }
    );

    return result;
  }, [
    currentValuation,
    isSimulation,
    priceCurrency,
    showRequestedExposes,
    showUnlockedExposes,
    showViewingAppointments,
    statistics?.advertisementViews,
    statistics?.bids,
    statistics?.bookmarks,
    statistics?.highestBid,
    statistics?.impressions,
    statistics?.requestedExposes,
    statistics?.unlockedExposes,
    statistics?.viewingAppointments,
  ]);

  if (!statistics) {
    return (
      <Container>
        <Title
          content={t('selling-simulation.general-statistics-card.title')}
        />
        <Content isBlurred={isBlurred}>
          {formattedStatistics.map((item, index) => (
            <Item key={index}>
              <ItemValue content={item.name === 'highestBid' ? '€-,—' : '-'} />
              <ItemLabel content={t(item.label)} />
            </Item>
          ))}
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Title content={t('selling-simulation.general-statistics-card.title')} />
      <Content isBlurred={isBlurred}>
        {formattedStatistics.map((item, index) => (
          <Item key={index}>
            {item.tooltipText && (
              <Info>
                <Tooltip
                  icon={InformationCircle}
                  id={item.tooltipText}
                  position="top"
                  width={16}
                  height={16}
                  className={'tooltip'}
                >
                  <TooltipContainer>
                    <TooltipParagraph>{t(item.tooltipText)}</TooltipParagraph>
                  </TooltipContainer>
                </Tooltip>
              </Info>
            )}

            <ItemValue content={item.value} />
            <ItemLabel content={t(item.label)} />
          </Item>
        ))}
      </Content>
      {statisticsLastSync && (
        <SyncDateContainer>
          <SyncDateLabel
            content={t('marketing-dashboard.statistics-last-sync')}
          />
          <SyncDateValue
            content={formatSyncDate(new Date(statisticsLastSync))}
          />
        </SyncDateContainer>
      )}
    </Container>
  );
};

const GeneralStatisticsCard = memo(GeneralStatisticsCardBase);

export { GeneralStatisticsCard };
