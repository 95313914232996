import { FC, useCallback, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useDispatch } from 'react-redux';

import { Modal } from '../../../../../common/components/modal';
import { useAppSelector } from '../../../../../common/hooks';
import { MapboxContainer } from './mapbox-container';
import Icon from '../../../../../common/components/ui/icon';
import { ReactComponent as CloseIcon } from '../../../../../../assets/streamline-light/interface-essential/form-validation/close.svg';
import { hexToRGB } from '../../../../../common/utils/hex-to-rgb';
import { setIsFullScreenMapOpen } from '../../redux/exposeSlice';

const Header = styled.div`
  background-color: ${hexToRGB('#e5e9ec', 0.9)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
`;

const IconContainer = styled.div`
  padding: 20px;
  cursor: pointer;
`;

const FullScreenMap: FC = () => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const isFullScreenMapOpen = useAppSelector(
    (state) => state.exposeDetails.isFullScreenMapOpen
  );

  const onClose = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      dispatch(setIsFullScreenMapOpen(false));
    },
    [dispatch]
  );

  return (
    <Modal
      isOpen={isFullScreenMapOpen}
      wrapperStyles={{
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        padding: 0,
      }}
    >
      <Header>
        <IconContainer onClick={onClose}>
          <Icon
            icon={CloseIcon}
            width={16}
            height={16}
            color={themeContext.blue}
          />
        </IconContainer>
      </Header>
      <MapboxContainer />
    </Modal>
  );
};

export { FullScreenMap };
