import { useCallback, useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { setSelectedTab } from '../../property/redux/dynamicMapUtilsSlice';
import {
  toggleIsEditSearchProfileModalOpen,
  toggleIsNotificationModalOpen,
} from '../../forms/form-search-profile/redux/searchProfileSlice';
import {
  setActiveTab,
  setExternalPropertyId,
  setIsAppointmentSelectOpen,
  setIsOpenHeadAccordion,
  setSelectedPropertyId,
  toggleExposeOverlay,
  setSelectedPropertyCode,
} from '../pages/expose-details-overlay/redux/exposeSlice';
import { useOrderBy } from './useOrderBy';
import {
  Direction,
  OrderSuggestedPropertiesBy as OrderByEnum,
  useExposeDetailsQuery,
  useGeneratePdfExposeLinkMutation,
} from '../../../generated';
import { getPropertyCodeFromType } from '../../property/utils/property-type-normalizer';
import { useFormOpenSP } from '../../forms/form-search-profile/hooks/useFormOpenSP';

const useCheckSearchParams = (): void => {
  const dispatch = useDispatch();
  const { switchOrder } = useOrderBy();
  const { onFormOpenSP } = useFormOpenSP({});
  const urlParams = new URLSearchParams(window.location.search);

  const [generatePdfExpose] = useGeneratePdfExposeLinkMutation();

  const activeTab = urlParams.get('active-tab');
  const openWidget = urlParams.get('openWidget') === 'true';
  const isEditSearchProfile = urlParams.get('edit-search-profile');
  const onlineExpose = urlParams.get('online-expose');
  const sortBy = urlParams.get('sort-by');
  const pdfKey = urlParams.get('pdf-key');
  const propertyId = urlParams.get('property-id') || '';
  const notifications = urlParams.get('notifications') || '';

  const { propertyType } = useExposeDetailsQuery(
    {
      id: onlineExpose ?? '',
    },
    {
      selectFromResult: ({ data }) => {
        const { type } = data?.exposeDetails?.propertyDetails || {};

        return {
          propertyType: type,
        };
      },
      skip: isEmpty(onlineExpose),
    }
  );

  const openPdfLink = useCallback(
    async (pdf) => {
      if (pdf) {
        const { generatePdfExposeLink: pdfExpose } = await generatePdfExpose({
          id: onlineExpose ?? '',
          fileKey: pdf,
        }).unwrap();
        if (pdfExpose) {
          window.open(pdfExpose, '_blank');
        }
      }
    },
    [generatePdfExpose, onlineExpose]
  );

  useEffect(() => {
    if (activeTab === 'favourites') {
      setTimeout(() => {
        dispatch(setSelectedTab(1));
      }, 1000);
    }
    if (isEditSearchProfile === 'true') {
      setTimeout(() => {
        dispatch(toggleIsEditSearchProfileModalOpen(true));
      }, 1000);
    }
    if (openWidget) {
      onFormOpenSP();
    }
    if (pdfKey) {
      setTimeout(() => {
        openPdfLink(pdfKey);
      }, 1000);
    }
    if (onlineExpose) {
      const propertyCode = getPropertyCodeFromType(propertyType ?? '');
      setTimeout(() => {
        batch(() => {
          dispatch(setExternalPropertyId(propertyId));
          dispatch(setSelectedPropertyCode(propertyCode));
          dispatch(setIsAppointmentSelectOpen(false));
          dispatch(setIsOpenHeadAccordion(true));
          dispatch(setActiveTab(0));
          dispatch(setSelectedTab(2));
          dispatch(setSelectedPropertyId(onlineExpose));
          dispatch(toggleExposeOverlay(true));
        });
      }, 1000);
    }
    if (sortBy) {
      setTimeout(() => {
        const [field, direction] = sortBy.split('-');
        const isField = Object.values(OrderByEnum).includes(
          field as OrderByEnum
        );
        const isDirection = Object.values(Direction).includes(
          direction as Direction
        );
        if (isField && isDirection) {
          switchOrder({
            field: field as OrderByEnum,
            direction: direction as Direction,
          });
        }
      }, 1000);
    }
    if (notifications === '1') {
      setTimeout(() => {
        dispatch(toggleIsNotificationModalOpen(true));
      }, 1000);
    }
    if (notifications === '-1') {
      setTimeout(() => {
        const deleteSearchProfileBtn = document.querySelector(
          '#delete-search-profile-btn'
        ) as HTMLButtonElement;
        if (deleteSearchProfileBtn) {
          window.scrollTo({
            behavior: 'smooth',
            top: deleteSearchProfileBtn.getBoundingClientRect().top / 3,
          });
          setTimeout(() => {
            deleteSearchProfileBtn.click();
          }, 1500);
        }
      }, 2000);
    }
  }, [propertyType]);
};

export { useCheckSearchParams };
