import styled from 'styled-components';
import { Headline1 } from '../../../../../../../common/components/ui/typography';

export const Header = styled(Headline1)`
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  padding-top: 44px;
`;

export const InfoContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 12px;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
`;

export const RangeContainer = styled.div<{ isValue: boolean }>`
  position: relative;
  ${({ isValue }) => isValue && 'margin-top: 52px'};
`;

export const MarkersListContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  height: 32px;
`;

export const MarkersListItem = styled.div<{
  width?: string;
}>`
  font-family: Roboto;
  border-right: 1px solid rgba(0, 48, 94, 0.2);
  width: ${({ width }) => width || 'auto'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.blue};

  &:last-child {
    border-right: none;
  }
`;

export const DataListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  position: relative;
  height: 28px;
`;

export const DataListItem = styled.div<{
  index: number;
}>`
  font-family: Roboto;
  position: absolute;
  left: ${({ index }) => index * (25 / 275) * 100}%;
  transform: translate(-50%, -50%);
  top: 50%;
  font-size: 10px;
  font-weight: normal;
  color: ${({ theme }) => theme.blue};
  opacity: 0.35;

  &:last-child {
    transform: translate(-100%, -50%);

    @media (max-width: 430px) {
      &:before {
        content: '>';
      }
      > div {
        display: none;
      }
    }
  }
`;

export const BoxesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
`;
