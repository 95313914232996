import styled from 'styled-components';

import { Headline3 } from '../../../../../common/components/ui/typography';
import { Checkbox } from '../../../../../common/components/form';
import { device } from '../../../../../../style/theme';
import {
  MainButton,
  SecondaryButton,
} from '../../../../../common/components/ui/buttons';

export const Title = styled(Headline3)`
  margin-bottom: 12px;
`;

export const CheckboxItem = styled(Checkbox)`
  margin-right: 12px;
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  @media ${device.tablet} {
    margin-top: auto;
  }
`;

export const NotificationSecondaryButton = styled(SecondaryButton)`
  text-transform: uppercase;
  @media ${device.tablet} {
    display: none;
  }
`;

export const NotificationMainButton = styled(MainButton)`
  text-transform: uppercase;
  @media ${device.tablet} {
    width: 100%;
    justify-content: center;
  }
`;
