import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';

import { InputV2 } from '../../../../../../../components/common/input-v2/input-v2';
import { FIELDS, FINANCING_FORM_KEYS } from '../../../../../../constants';
import { IFinancingForm } from '../../../../../../interface';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .info {
    margin-right: 0;
  }
  & > div:last-child {
    .label-container {
      padding-right: 3px;
    }
  }
`;

interface IProps {
  finData: IFinancingForm;
  setDirtyTrue(): void;
}

const ModernizationFields: FC<IProps> = ({ finData, setDirtyTrue }) => {
  const { t } = useTranslation();
  const [propertyValue, desiredLoanAmount] = useWatch({
    name: [
      FINANCING_FORM_KEYS.PROPERTY_VALUE,
      FINANCING_FORM_KEYS.DESIRED_LOAN_AMOUNT,
    ],
  });

  return (
    <Container>
      <InputV2
        {...FIELDS.PROPERTY_VALUE}
        defaultValue={finData.propertyValue}
        inputAdornment="€"
        tooltipWidth={230}
        isSeparate
        isRequired={false}
        isLabelSaturated
        isOldDesign
        isValidateOnBlur
        isTooltipKeyLeftSide
        isBoldTooltipKeyIcon
        validateNames={
          desiredLoanAmount
            ? [
                FINANCING_FORM_KEYS.PROPERTY_VALUE,
                FINANCING_FORM_KEYS.DESIRED_LOAN_AMOUNT,
              ]
            : undefined
        }
        rules={{
          ...FIELDS.PROPERTY_VALUE.rules,
          validate: (value: number) => {
            return Number(value) * 1.5 < Number(desiredLoanAmount)
              ? t(
                  'financing-portal.form.inputs.property-value.errors.desired-loan-amount-value'
                ).toString()
              : true;
          },
        }}
        handleChangeProp={() => {
          setDirtyTrue();
        }}
      />
      <InputV2
        {...FIELDS.DESIRED_LOAN_AMOUNT}
        defaultValue={finData.desiredLoanAmount}
        inputAdornment="€"
        isSeparate
        isValidateOnBlur
        isRequired={false}
        isLabelSaturated
        isOldDesign
        isTooltipKeyLeftSide
        isBoldTooltipKeyIcon
        validateNames={
          propertyValue
            ? [
                FINANCING_FORM_KEYS.PROPERTY_VALUE,
                FINANCING_FORM_KEYS.DESIRED_LOAN_AMOUNT,
              ]
            : undefined
        }
        rules={{
          ...FIELDS.DESIRED_LOAN_AMOUNT.rules,
          validate: (value: number) => {
            return Number(value) > Number(propertyValue * 1.5)
              ? t(
                  'financing-portal.form.inputs.desired-loan-amount.errors.property-value'
                ).toString()
              : true;
          },
        }}
        handleChangeProp={() => {
          setDirtyTrue();
        }}
      />
    </Container>
  );
};

export { ModernizationFields };
