import { Chart } from 'chart.js';
import { memo, useContext, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { DefaultTheme, ThemeContext } from 'styled-components';

import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { abbrNum } from '../../../common/utils/number-scientific-notation';
import { plugin } from './background-plugin';

// Chart.register([annotationPlugin]);

interface IProps {
  labels: string[];
  data: unknown;
  height?: number;
  labelsWithQuarters?: boolean;
  yValueSuffix?: string;
  hideStripes?: boolean;
  labelsWithShortYear?: boolean;
  labelFormatText?: string;
  footer?: string;
  id?: string;
  aspectRatio?: number;
}

interface ITooltipData {
  chart: typeof Chart;
  label: string;
  parsed: unknown;
  raw: unknown;
  formattedValue: string;
  dataset: unknown;
  datasetIndex: number;
  dataIndex: number;
  element: Element;
}

const buildDatasets = (
  themeContext: DefaultTheme,
  labels: string[],
  data: unknown
) => ({
  labels,
  datasets: [
    {
      labels,
      data,
      fill: false,
      borderColor: themeContext.blue,
      backgroundColor: themeContext.blue,
      pointerStyle: 'circle',
      pointRadius: 3,
      lineTension: 0,
      borderCapStyle: 'butt',
      borderWidth: 1,
      borderJoinStyle: 'miter',
      pointBorderColor: themeContext.blue,
      pointBackgroundColor: themeContext.blue,
      pointBorderWidth: 1,
      pointHoverRadius: 3,
      pointHoverBackgroundColor: themeContext.blue,
      pointHoverBorderColor: themeContext.blue,
      pointHoverBorderWidth: 6,
      pointHitRadius: 3,
    },
  ],
});

const LineChartBase = ({
  labels,
  labelFormatText,
  data,
  height,
  labelsWithQuarters,
  yValueSuffix,
  hideStripes,
  labelsWithShortYear,
  footer,
  id,
  aspectRatio,
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  const chartData = useMemo(
    () => buildDatasets(themeContext, labels, data),
    [data, labels, themeContext]
  );

  const options = useMemo(
    () => ({
      maintainAspectRatio: true,
      ...(aspectRatio && { aspectRatio }),
      interaction: {
        intersect: false,
        mode: 'point',
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          displayColors: false,
          backgroundColor: '#fff',
          titleColor: hexToRGB(themeContext.blue, 0.8),
          titleFont: {
            family: 'Roboto',
            size: 12,
            weight: '900',
            lineHeight: 1.67,
          },
          titleAlign: 'center',
          titleMarginBottom: 4,
          bodyColor: themeContext.blue,
          bodyFont: {
            family: 'Source Serif Pro',
            size: 16,
            weight: '600',
            lineHeight: 1.25,
          },
          bodyAlign: 'center',
          footerColor: hexToRGB(themeContext.blue, 0.5),
          footerFont: {
            family: 'Roboto',
            size: 10,
            weight: '900',
            lineHeight: 1.6,
          },
          footerAlign: 'center',
          footerMarginTop: 2,
          padding: {
            left: 20,
            right: 20,
            top: 8,
            bottom: 8,
          },
          cornerRadius: 3,
          borderColor: 'rgb(217, 217, 217)',
          borderWidth: 1,
          caretSize: 10,
          caretPadding: 8,
          usePointStyle: true,
          callbacks: {
            labelPointStyle: () => {
              return {
                pointStyle: 'triangle',
                rotation: 1,
              };
            },
            title: (context: [ITooltipData]) => {
              const { label } = context[0];
              if (!label) {
                return '';
              }

              const tokens = label.split('-');
              return labelsWithQuarters
                ? `${tokens[1]}-${tokens[0].slice(2)}`
                : label;
            },
            label: (context: ITooltipData) => {
              const { formattedValue } = context;
              if (labelFormatText && labelFormatText?.length > 0) {
                return `${formattedValue || ''} ${t(
                  formattedValue === '1'
                    ? labelFormatText
                    : `${labelFormatText}s`
                )}`;
              }
              return `${formattedValue || ''}`;
            },
            footer: () => {
              if (footer) {
                return t(footer);
              }
              return '';
            },
          },
        },
      },
      chartArea: {
        backgroundColor: hexToRGB('#4e73f5', 0.05),
        step: 1,
      },
      scales: {
        x: {
          beginAtZero: false,
          offset: true,
          grid: {
            display: true,
            drawTicks: false,
            drawOnChartArea: false,
          },
          ticks: {
            major: {
              enabled: true,
            },
            maxRotation: 0,
            align: 'center',
            crossAlign: 'near',
            includeBounds: false,
            labelOffset: 0,
            padding: 8,
            color: hexToRGB(themeContext.blue, 0.3),
            font: {
              family: 'Roboto',
              size: 10,
              weight: '900',
              lineHeight: 1.6,
            },
            callback(value: number) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line react/no-this-in-sfc
              let label: string = this.getLabelForValue(value);
              if (!label) {
                return '';
              }
              const tokens = label.split('-');

              if (labelsWithShortYear) {
                label = `'${label.slice(2)}`;
              }

              return labelsWithQuarters
                ? `${tokens[1]}-${tokens[0].slice(2)}`
                : label;
            },
          },
        },
        y: {
          beginAtZero: false,
          offset: true,
          grid: {
            drawBorder: true,
            borderDash: [2, 1],
            zeroLineColor: 'red',
            drawTicks: false,
          },
          ticks: {
            padding: 10,
            maxTicksLimit: 5,
            color: themeContext.blue,
            font: {
              family: 'Roboto',
              size: 12,
              weight: 'bold',
              lineHeight: 1.33,
            },
            callback(value: number) {
              if (value > 999 || value < -999) {
                return `${abbrNum(value, 2)} ${yValueSuffix || ''}`;
              }
              return `${value.toFixed(2).replace(/[,.]00$/, '')}`;
            },
          },
          // afterFit(scale: unknown) {
          //   console.log(scale);
          //   scale.left = 0;
          // },
        },
      },
      layout: {
        padding: {
          left: 0,
        },
      },
    }),
    [
      footer,
      labelFormatText,
      labelsWithQuarters,
      labelsWithShortYear,
      t,
      themeContext.blue,
      yValueSuffix,
    ]
  );

  const plugins = hideStripes ? [] : [plugin];

  return (
    <Line
      {...(height && { height })}
      data={chartData}
      options={options}
      plugins={plugins}
      id={id}
    />
  );
};

const LineChart = memo(LineChartBase);

export { LineChart };
