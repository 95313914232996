import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useExposeDetailsHeadInfo } from 'modules/search-profile/pages/expose-details-overlay/hooks/useExposeDetailsQueries';
import { OfferPriceSection } from 'modules/search-profile/components/offer-price/offer-price';
import { Checkbox } from '../../../../../common/components/form';
import InputSwitch from '../../../../../common/components/form/input/input-switch';
import { InputTextArea } from '../../../../../common/components/form/input/input-text-area';
import { Wrapper } from '../../common/form-utils';

const EurSymbol = styled.span`
  color: ${({ theme }) => theme.blue};
  opacity: 0.5;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  right: 12px;
`;

const TextAreaBox = styled.div`
  margin-top: 12px;
`;

const ConfirmationText = styled.div`
  font-family: Roboto;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.5px;
  margin: 12px 0;
  color: #002f5d;
`;

interface IProps {
  propertyPrice?: number;
}

const ConfirmInterest = ({ propertyPrice }: IProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    headInfo: { priceValue },
  } = useExposeDetailsHeadInfo();

  return (
    <Wrapper>
      <Checkbox
        name="confirmPrice"
        label={t('purchase-intent.form.confirmPrice')}
      />
      <OfferPriceSection price={propertyPrice ?? priceValue} inWidget />
      <TextAreaBox>
        <InputTextArea
          style={{ height: 120 }}
          name={'notes'}
          label={t('purchase-intent.form.notes')}
          rules={{}}
        />
      </TextAreaBox>
      <ConfirmationText>
        {t('purchase-intent.form.confirmFunding')}
      </ConfirmationText>
      <InputSwitch
        noLabel={t('purchase-intent.form.no')}
        yesLabel={t('purchase-intent.form.yes')}
        name={'confirmFunding'}
      />
    </Wrapper>
  );
};

export default ConfirmInterest;
