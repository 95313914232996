import {
  forwardRef,
  useState,
  FocusEvent,
  ChangeEvent,
  KeyboardEvent,
} from 'react';
import * as S from './styles/styled-input';
import { IVerificationPin } from '../../types';

export interface IInputProps {
  id: string;
  type?: 'text' | 'number';
  width?: string;
  pattern?: RegExp;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Input = forwardRef<
  HTMLInputElement,
  IInputProps & IVerificationPin
>(
  (
    { type, id, width, status, pattern, onFocus, onChange, onKeyDown, onBlur },
    ref
  ) => {
    const [value, setValue] = useState('');
    const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(e);
      }
    };

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        const inputValue = event.target.value;
        if (pattern) {
          const regex = new RegExp(pattern);
          if (regex.test(inputValue) || inputValue.length === 0) {
            setValue(inputValue);
            onChange(event);
          }
        } else {
          setValue(inputValue);
          onChange(event);
        }
      }
    };

    return (
      <S.StyledInput
        ref={ref}
        value={value}
        status={status}
        autoComplete="off" // Disable autocomplete hack
        type={type}
        name={id}
        id={id}
        width={width}
        maxLength={1}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        className="pin-input"
        onFocus={handleOnFocus}
        onChange={handleOnChange}
      />
    );
  }
);
