import { Feature } from '../../../services/mapbox/interfaces';
import { isoCountries } from '../constants';

const getCountry = (geoCodingResult: Feature): string => {
  const { context } = geoCodingResult;
  let mapboxCountryCode = '';

  context?.forEach((c) => {
    if (c?.id.includes('country')) {
      // mapboxCountry = c.text;
      mapboxCountryCode = c.short_code ?? '';
    }
  });

  return (
    Object.keys(isoCountries).find(
      // (key: string) => isoCountries[key].toLowerCase() === mapboxCountry.toLowerCase()
      (key: string) => key.toLowerCase() === mapboxCountryCode.toLowerCase()
    ) ?? 'DE'
  );
};

export default getCountry;
