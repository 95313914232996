import styled from 'styled-components';

interface IProps {
  content: string;
  paleText?: string;
  fontWeight?: number;
  className?: string;
}

const Container = styled.p<{ fontWeight?: number }>`
  margin: 0;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: ${({ fontWeight }) => fontWeight || 900};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
`;

const Pale = styled.span`
  opacity: 0.6;
`;

const BoxTitle = ({ content, paleText, ...rest }: IProps): JSX.Element => {
  const textArr = (content || '').split(paleText || '');

  return (
    <Container {...rest}>
      {paleText ? (
        <>
          <Pale>{paleText}</Pale>
          {textArr?.[1] || ''}
        </>
      ) : (
        content
      )}
    </Container>
  );
};

export { BoxTitle };
