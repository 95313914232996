import { memo } from 'react';
import styled from 'styled-components';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { device } from '../../../../style/theme';
import { useAppSelector } from '../../../common/hooks';

interface IProps {
  activeBullet: number;
  bullets: number;
}

const Container = styled.div`
  display: flex;
  margin: 0 auto 24px auto;
  justify-content: center;
`;

const Bullet = styled.div<{ filled?: boolean; isiframe?: boolean }>`
  position: relative;
  width: 16px;
  height: 16px;
  padding: 3px;
  border-radius: 50%;
  margin: 0 20px;
  border: solid 1px ${(props) => hexToRGB(props.theme.blue, 0.5)};
  background-color: ${(props) =>
    props.filled ? props.theme.blue : hexToRGB('#ffffff', 0)};
  background-clip: content-box;
  &:not(:first-child):before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% + 5px);
    width: calc(200% + 4px);
    border-bottom: 1px
      ${(props) =>
        `${props.filled ? 'solid' : 'dashed'} ${hexToRGB(
          props.theme.blue,
          0.5
        )}`};
  }
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  @media ${({ isiframe }) => (isiframe ? '0' : device.tablet)} {
    margin: 0 12px;
    &:not(:first-child):before {
      right: calc(100% + 4px);
      width: calc(100% + 2px);
    }
  }
`;

const ProgressBase: React.FC<IProps> = ({ activeBullet, bullets }) => {
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  return (
    <Container>
      {[...Array.from({ length: bullets })].map((_, index) => (
        <Bullet
          key={index}
          filled={activeBullet >= index}
          isiframe={isIframe}
        />
      ))}
    </Container>
  );
};

const Progress = memo(ProgressBase);

export { Progress };
