import { ReactComponent as Bin2 } from 'assets/streamline-light/interface-essential/delete/bin-2.svg';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { TooltipConfirmation } from '../../../../common/components/tooltip/TooltipConfirmation';
import { IconButton } from '../../../../common/components/ui/buttons';
import { useLngHistoryPush } from '../../../../localization/lng-history-push';
import { useRemoveFinancingMutation } from '../../../../../services/graphql/enhanced';
import { siteMap } from '../../../../../routes/site-map';
import { device } from '../../../../../style/theme';
import { Tooltip } from 'modules/common/components/tooltip';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 24px;
  flex: 1;

  > *:not(:last-child) {
    margin-right: 8px;
  }
  > div:nth-child(2) {
    > div {
      display: block;
    }
  }

  @media ${device.tablet} {
    justify-content: center !important;
  }
`;

const TooltipHeader = styled.h4`
  font-family: 'Source Serif Pro', serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

interface IProps {
  isMobile?: boolean;
}

const VariationHeaderActions: FC<IProps> = ({ isMobile }) => {
  const { t } = useTranslation();
  const lngHPush = useLngHistoryPush();
  const { financingId } = useParams<{ financingId: string }>();

  const [removeFinancing, { isLoading }] = useRemoveFinancingMutation();

  const handleDeleteFinancing = async () => {
    if (financingId) {
      await removeFinancing({ id: financingId }).unwrap();
    }
  };

  return (
    <Container>
      <TooltipConfirmation
        headerText={t('financing-delete.tooltip.title')}
        backText={t('financing-delete.tooltip.back')}
        acceptText={t('financing-delete.tooltip.accept')}
        id={isMobile ? 'financing-delete-mobile' : 'financing-delete'}
        maxWidth="400px"
        onDelete={handleDeleteFinancing}
      >
        <Tooltip
          content={
            <IconButton
              // tabletButtonWidth="100%"
              // tabletButtonHeight="40px"
              iconColor={'#4E73F5'}
              iconColorHover={'#ffffff'}
              buttonColorHover={'#4E73F5'}
              border={'2px solid #4E73F5'}
              buttonColor={'transparent'}
              id={'delete-search-profile-btn'}
              icon={Bin2}
              isLoading={isLoading}
            />
          }
          id={'financing-portal-page.delete-property.tooltip'}
          maxWidth={32}
        >
          <TooltipHeader>
            {t('financing-portal-page.delete-property.tooltip')}
          </TooltipHeader>
        </Tooltip>
      </TooltipConfirmation>
    </Container>
  );
};

export { VariationHeaderActions };
