import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { MainButton } from '../../../../common/components/ui/buttons';

export const Container = styled.div`
  padding: 48px 0;
  margin-bottom: 64px;
  position: relative;
  background: #f3f4f7;
  color: #00305e;
  letter-spacing: 0.5px;

  &:before {
    content: '';
    width: 100vw;
    background: #f3f4f7;
    position: absolute;
    left: 50%;
    right: 50%;
    margin: 0 -50vw;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  @media (max-width: 1193px) {
    padding: 24px 0;
  }

  @media ${device.tablet} {
    margin: 0;
  }
`;

export const Title = styled.h1`
  font-family: 'Source Serif Pro';
  margin: 0;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.33;

  @media (max-width: 1193px) {
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: 0.5px;
    text-align: center;
    margin-bottom: 8px;
  }
`;

export const Subtitle = styled.p`
  font-family: Roboto;
  margin: 0 0 16px 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;

  @media (max-width: 1193px) {
    text-align: center;
    margin: 0 0 24px 0;
  }
`;

export const Table = styled.div`
  width: 1088px;
  height: 256px;
  border: 2px solid #00305e;
  background: white;
  border-radius: 3px;

  @media (max-width: 1193px) {
    display: flex;
    width: 100%;
    height: unset;
  }
`;

export const TableWrapper = styled.div`
  padding-bottom: 8px;
`;

export const TableRow = styled.div`
  height: 84px;
  display: flex;

  @media (max-width: 1193px) {
    flex-direction: column;
    height: auto;
    flex: 1;
  }
`;

export const TableCell = styled.div<{
  direction?: string;
  dark?: boolean;
  shaded?: boolean;
  bold?: boolean;
}>`
  font-family: Roboto;
  border-right: 1px solid #d9d9d9;
  font-size: 14px;
  ${(props) => (props.bold ? 'font-weight: bold;' : 'font-weight: 500;')}
  line-height: 1.14;
  letter-spacing: 1.5px;
  color: #00305e;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 165px;
  padding: 8px;
  &:first-child {
    flex-basis: 118px;
  }

  flex-direction: ${(props) => props.direction ?? 'row'};

  ${(props) => props.dark && 'background: #00305e; color: white;'}
  ${(props) => props.shaded && 'background: #f2f5f7; color: #002849;'}

  &:last-child {
    border: none;
    flex-basis: 141px;
  }

  @media (max-width: 1193px) {
    flex-basis: 75px;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    font-size: 12px;

    &:first-child {
      flex-basis: 48px;
    }

    &:last-child {
      border: none;
      flex-basis: 75px;
    }
  }
`;

export const DateBox = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: white;
  margin-top: 12px;

  @media (max-width: 1193px) {
    font-size: 12px;
  }
`;

export const RowLabel = styled.div`
  margin-left: 18px;
  width: 100%;

  &:first-child {
    margin-left: 12px;
  }
`;

export const TableDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: #00305e;
  width: 1084px;
  margin: 0 2px 0;

  @media (max-width: 1193px) {
    flex-direction: column;
    margin: 8px 0 0;
    align-items: center;
    font-size: 10px;
    line-height: 1.6;
    width: 100%;
  }
`;

export const HintText = styled.p`
  margin: 0;
`;

export const TimeStampBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const TimeStampLabel = styled.span`
  font-weight: bold;
`;

export const GhostBtnBox = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 1084px;
  margin: 0 2px 0;

  @media (max-width: 1193px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 16px 0 24px;
  }
`;

export const GhostBtn = styled.button`
  font-family: Roboto;
  margin: 8px 0 0 0;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.67;
  letter-spacing: 1px;
  color: #00305e;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  border: 0;
  outline: none;
  text-transform: uppercase;
  background: transparent;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 1193px) {
    font-size: 10px;
    line-height: 1.6;
    letter-spacing: 0.83px;
  }
`;

export const CTAButton = styled(MainButton)`
  text-transform: uppercase;

  @media (max-width: 1193px) {
    margin: 0 auto;
  }
`;

export const SkeletonItem = styled.span<{
  width?: string;
  height?: string;
  mb?: number;
  mt?: number;
  ml?: number;
}>`
  @keyframes barWidth {
    0% {
      width: 10%;
    }
    25% {
      width: 50%;
    }
    50% {
      width: 100%;
    }
    75% {
      width: 50%;
    }
    100% {
      width: 10%;
    }
  }

  display: block;
  width: ${({ width }) => width ?? '160px'};
  height: ${({ height }) => height ?? '6px'};
  background-color: ${({ theme }) => theme.blue};
  border-radius: 3px;
  margin: 0 auto;
  animation: barWidth;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  ${({ mb }) => (mb ? `margin-bottom: ${mb}px;` : '')}
  ${({ mt }) => (mt ? `margin-top: ${mt}px;` : '')}
  ${({ ml }) => (ml ? `margin-left: ${ml}px;` : '')}
`;
