import { SEARCH_PROFILE_FORM_KEYS } from '../../../../../constants';

export const defaultDebaunceState = {
  postCode: '',
  street: '',
  number: '',
  city: '',
  placeName: '',
  locality: '',
  neighborhood: '',
  state: '',
  proximity: '9.993682,53.551086',
  country: 'de,at,ch',
  language: 'de',
};

export const fields = [
  SEARCH_PROFILE_FORM_KEYS.CITY,
  SEARCH_PROFILE_FORM_KEYS.POST_CODE,
  SEARCH_PROFILE_FORM_KEYS.LOCALITY,
  SEARCH_PROFILE_FORM_KEYS.NEIGHBORHOOD,
  SEARCH_PROFILE_FORM_KEYS.CODE,
  SEARCH_PROFILE_FORM_KEYS.PLACE_NAME,
];
