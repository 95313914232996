import {
  MainEarner,
  RegistrationBy,
  UserSignUpInput,
  UserType,
} from '../../../generated';
import { IRegisterForm } from '../interfaces';
import { passwordGenerator } from './random-password-generator';
import {
  supportedLanguagesCountryBuyerPortal,
  supportedLanguagesCountryFinancingPortal,
  supportedLanguagesCountryOwnerPortal,
  supportedLanguagesCountrySellerPortal,
} from '../../localization/constants';

interface IProps {
  formData: IRegisterForm;
  lang: string;
  userType: UserType;
}

export const parseRegisterInput = ({
  formData,
  lang,
  userType,
}: IProps): UserSignUpInput => {
  const {
    phone,
    phonePrefix,
    password: userInputPassword,
    confirmPassword,
    householdNetIncome,
    mainEarner,
    ...rest
  } = formData;

  const newPassword = userInputPassword ?? passwordGenerator(12);
  const temporaryPassword = userInputPassword ? null : newPassword;

  let lngList;
  switch (userType) {
    case UserType.Buyer:
      lngList = supportedLanguagesCountryBuyerPortal;
      break;
    case UserType.Financier:
      lngList = supportedLanguagesCountryFinancingPortal;
      break;
    case UserType.Seller:
      lngList = supportedLanguagesCountrySellerPortal;
      break;
    default:
      lngList = supportedLanguagesCountryOwnerPortal;
      break;
  }
  const checkLang = lngList.includes(lang);

  return {
    ...rest,
    householdNetIncome: Number(householdNetIncome),
    mainEarner: mainEarner as MainEarner,
    phone: `+${phonePrefix}${phone}`,
    prefix: `${phonePrefix}`,
    password: newPassword,
    temporaryPassword,
    registrationBy: rest.registrationBy ?? RegistrationBy.Mobile,
    lang: checkLang ? lang : 'de-de',
    userType: [userType],
  };
};
