export const getClosestElementBySelector = (
  el: HTMLElement,
  selector: string
): HTMLElement => {
  if (el.matches(selector)) {
    return el;
  }

  return el.closest(selector) || el;
};
