import { FC, useMemo } from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { CalendarIcon } from '../icons/calendar';
import { MoneyIcon } from '../icons/money';
import { OffersStatisticsItem } from '../../../seller/interfaces';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1.429,
    marginBottom: '8px',
  },
  title: {
    opacity: 1,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'SourceSerifPro',
    fontSize: '20px',
    fontWeight: 'semibold',
    textAlign: 'left',
    lineHeight: 1.2,
    marginBottom: '8px',
  },
  description: {
    opacity: 1,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'SourceSerifPro',
    fontSize: '12px',
    fontWeight: 'normal',
    textAlign: 'center',
    lineHeight: 1.33,
    marginBottom: '8px',
  },
  cardContainer: {
    width: '498px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '3px',
    overflow: 'hidden',
    border: '1px solid rgba(201, 213, 252, 1)',
    opacity: 1,
    backgroundColor: 'rgba(242, 245, 247, 1)',
    marginBottom: '8px',
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  dateContainer: {
    width: '48px',
    height: '48px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    margin: '12px',
    borderRadius: '3px',
    opacity: 0.3,
    backgroundColor: 'rgba(77, 104, 127, 1)',
  },
  dateText: {
    marginTop: '4px',
    opacity: 1,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'SourceSerifPro',
    fontSize: '12px',
    fontWeight: 'semibold',
    textAlign: 'center',
    lineHeight: 2,
  },
  cardTitleContainer: {
    marginRight: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  cardTitle: {
    marginTop: '16px',
    marginBottom: '4px',
    opacity: 1,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'SourceSerifPro',
    fontSize: '16px',
    fontWeight: 'semibold',
    textAlign: 'left',
    lineHeight: 1.25,
  },
  bold: {
    fontWeight: 'bold',
  },
  cardCity: {
    opacity: 0.5,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 'normal',
    textAlign: 'left',
    lineHeight: 1.6,
    textTransform: 'uppercase',
  },
  cardLabelsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '14px',
  },
  cardLabelContainerTypeNew: {
    padding: '4px 8px 0 8px',
    marginBottom: '8px',
    borderRadius: '10px',
    opacity: 1,
    backgroundColor: '#f1f5ee',
    border: '1px solid #d5e3cc',
  },
  cardLabelTextTypeNew: {
    opacity: 1,
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 'semibold',
    textAlign: 'left',
    lineHeight: 1.6,
    textTransform: 'uppercase',
    color: '#74a257',
  },
  cardLabelContainerTypeNewPrice: {
    border: '1px solid rgba(223, 214, 200, 1)',
    padding: '4px 8px 0 8px',
    marginBottom: '8px',
    borderRadius: '10px',
    opacity: 1,
    // backgroundColor: 'rgba(116, 162, 87, 1)',
    backgroundColor: 'rgba(240, 237, 233, 1)',
  },
  cardLabelTextTypeNewPrice: {
    opacity: 1,
    color: 'rgba(162, 133, 87, 1)',
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 'semibold',
    textAlign: 'left',
    lineHeight: 1.6,
    textTransform: 'uppercase',
  },
  cardLabelContainerTypeHighestBid: {
    padding: '4px 8px 0 8px',
    marginBottom: '8px',
    borderRadius: '10px',
    opacity: 1,
    backgroundColor: 'rgba(116, 162, 87, 1)',
  },
  cardLabelTextTypeHighestBid: {
    opacity: 1,
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 'semibold',
    textAlign: 'left',
    lineHeight: 1.6,
    textTransform: 'uppercase',
  },
  cardPriceContainer: {
    minWidth: '158px',
    display: 'flex',
    padding: '15px 4px 15px 10px',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // borderRadius: '0px 3px 3px 0px',
    borderLeft: '1px solid rgba(201, 213, 252, 1)',
    opacity: 1,
    backgroundColor: 'rgba(242, 245, 247, 1)',
  },
  cardPriceWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  cardPriceText: {
    marginTop: '5px',
    marginLeft: '8px',
    opacity: 1,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'left',
    lineHeight: 1.429,
  },
  cardPriceDescription: {
    marginTop: '4px',
    opacity: 0.6,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 'bold',
    textAlign: 'left',
    lineHeight: 1.4,
  },
});

interface IProps {
  offersStatistics?: OffersStatisticsItem[];
  isSimulation: boolean;
  simulationHighestBid?: string;
}

const OffersStatistics: FC<IProps> = ({
  offersStatistics,
  isSimulation,
  simulationHighestBid,
}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Ihre Interessenten</Text>
      <Text style={styles.description}>
        Die Interessenten sind nach dem höchsten bis zum niedrigsten
        Preisangebot gelistet.
      </Text>
      {offersStatistics?.map((offer) => {
        return offer.offers?.map((item) => {
          const isHighestBid = isSimulation
            ? item.price === simulationHighestBid
            : item.isHighestBid;
          return (
            <View style={styles.cardContainer} key={item.price}>
              <View style={styles.cardWrapper}>
                <View style={styles.dateContainer}>
                  <CalendarIcon />
                  <Text style={styles.dateText}>
                    {new Date(item.date)
                      .toLocaleDateString('en-GB')
                      .slice(0, -5)}
                  </Text>
                </View>
                <View style={styles.cardTitleContainer}>
                  <Text style={styles.cardTitle}>
                    Interessent {item.customerId}
                  </Text>
                  <Text style={styles.cardCity}>
                    AUS <Text style={styles.bold}>{item.fromCity}</Text>
                  </Text>
                </View>
                <View style={styles.cardLabelsContainer}>
                  {item.isNew ? (
                    <View
                      style={styles.cardLabelContainerTypeNew}
                      key={'NEUES ANGEBOT'}
                    >
                      <Text style={styles.cardLabelTextTypeNew}>
                        NEUES ANGEBOT
                      </Text>
                    </View>
                  ) : null}
                  {isHighestBid ? (
                    <View
                      style={styles.cardLabelContainerTypeHighestBid}
                      key={'HÖCHSTGEBOT'}
                    >
                      <Text style={styles.cardLabelTextTypeHighestBid}>
                        HÖCHSTGEBOT
                      </Text>
                    </View>
                  ) : null}
                  {item.newPrice ? (
                    <View
                      style={styles.cardLabelContainerTypeNewPrice}
                      key={'ANGEPASSTES ANGEBOT'}
                    >
                      <Text style={styles.cardLabelTextTypeNewPrice}>
                        ANGEPASSTES ANGEBOT
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>
              <View style={styles.cardPriceContainer}>
                <View style={styles.cardPriceWrapper}>
                  <MoneyIcon />
                  <Text style={styles.cardPriceText}>{item.price}</Text>
                </View>
                <Text style={styles.cardPriceDescription}>
                  Kaufpreisangebot
                </Text>
              </View>
            </View>
          );
        });
      })}
    </View>
  );
};

export { OffersStatistics };
