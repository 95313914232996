import styled from 'styled-components';
import { device } from '../../../../../../../style/theme';

export const Container = styled.div`
  border-radius: 3px;
  display: flex;
  padding: 32px;
  position: relative;
  gap: 24px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.lightBlue};
  opacity: 1;
  background-color: ${({ theme }) => theme.greyBackground};
  margin-bottom: 16px;

  h3 {
    opacity: 1;
    color: ${({ theme }) => theme.blue};
    font-family: Source Serif Pro, sans-serif;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 32px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    margin: 0 12px 12px;
    padding: 12px;
    gap: 12px;
  }
`;

export const CertificateIconBox = styled.div`
  gap: 12px;
  min-width: 72px;
  max-width: 72px;
  display: flex;

  img {
    width: 100%;
    height: 74px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    img {
      min-width: 48px;
      max-width: 48px;
      max-height: 48px;
    }

    h3 {
      font-size: 16px;
      letter-spacing: .33px;
      line-height: 1;
    }
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > p {
    opacity: 1;
    color: ${({ theme }) => theme.blue};
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 16px;
  }
`;

export const Button = styled.div`
  margin-top: 8px;
  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 1px;
  text-align: left;
  line-height: 20px;
  text-transform: uppercase;
  cursor: pointer;
  width: fit-content;

  &:hover {
    color: ${({ theme }) => theme.ctaBlueHover};
  }

  @media ${device.tablet} {
    width: 100%;
    text-align: center;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;

  @media ${device.tablet} {
    position: absolute;
    right: 16px;
    top: 16px;
  }
`;
