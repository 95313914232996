import { FC, memo, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoStatistics } from 'assets/streamline-light/business-products/data-files/data-file-bars-remove.svg';
import { ThemeContext } from 'styled-components';
import { isEmpty } from 'lodash';
import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import {
  AlertContainer,
  AlertTextWrapper,
  AlertText,
  AlertTitle,
  Container,
  Content,
  Title,
  AlertContentWrapper,
  ActionButton,
  AlertActions,
  Info,
  TooltipContainer,
  TooltipParagraph,
  TitleWrapper,
  AlertClose,
} from './expose-statistics-card-styles';
import { LineChart } from '../../../property/components/chart/line-chart';
import { StatisticsItem } from '../../interfaces';
import EmptyStatePlaceholder from '../../../common/components/empty-state-placeholder';
import Icon from '../../../common/components/ui/icon';
import { ReactComponent as AlertTriangle } from '../../../../assets/streamline-light/interface-essential/alerts/alert-triangle.svg';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { Broker } from '../../../../generated';
import { ExposeStatisticsLineChart } from './expose-statistics-line-chart';
import { Tooltip } from '../../../common/components/tooltip';
import { ReactComponent as InformationCircle } from '../../../../assets/streamline-light/interface-essential/alerts/information-circle.svg';
import { useUpdatePropertyAlertsMutation } from '../../../../services/graphql/enhanced';

interface IProps {
  statistics?: StatisticsItem[];
  propertyBroker?: Broker;
  isBlurred?: boolean;
  onBookAppointment?: () => void;
  onEmailBroker?: () => void;
  showAlert?: boolean;
  isSimulation?: boolean;
  isHiddenSendEmailToBrokerAlert?: boolean | null;
}

const ExposeStatisticsCardBase: FC<IProps> = ({
  statistics,
  propertyBroker,
  isBlurred,
  onBookAppointment,
  onEmailBroker,
  showAlert,
  isSimulation = false,
  isHiddenSendEmailToBrokerAlert,
}) => {
  const { t } = useTranslation();
  const { propertyId } = useParams<{ propertyId: string }>();

  const themeContext = useContext(ThemeContext);

  const showChart = useMemo(
    () => !isEmpty(statistics) && statistics?.some((item) => item.value !== 0),
    [statistics]
  );

  const chartLabels = statistics?.map((item) => item.label);
  const chartValues = statistics?.map((item) => item.value);

  const [updateProperty] = useUpdatePropertyAlertsMutation();
  const onSetAlertHidden = () => {
    updateProperty({
      input: { _id: propertyId, isHiddenSendEmailToBrokerAlert: true },
    });
  };

  const percentDifferenceBetweenHighestAndLatest = useMemo(() => {
    const highestChartValue = Math.max(...(chartValues ?? [0]));
    const reversedStatistics = chartValues ? [...chartValues]?.reverse() : [];
    if (!isEmpty(reversedStatistics)) {
      const currentWeekValue = reversedStatistics[0];
      return Math.round(100 - (currentWeekValue / highestChartValue) * 100);
    }
    return 0;
  }, [chartValues]);

  // const alertText = useMemo(() => {
  //   if (!showChart) {
  //     return {
  //       title: 'marketing-dashboard.no-expose-requests.warning.title',
  //       description:
  //         'marketing-dashboard.no-expose-requests.warning.description',
  //     };
  //   }

  // const reversedStatistics = chartValues ? [...chartValues]?.reverse() : [];
  //
  // if (
  //   reversedStatistics &&
  //   Array.isArray(reversedStatistics) &&
  //   reversedStatistics.length >= 2 &&
  //   reversedStatistics[0] < 100
  // ) {
  //   if (reversedStatistics[0] > reversedStatistics[1]) {
  //     return {
  //       title: 'marketing-dashboard.expose-requests.questions.title',
  //       description:
  //         'marketing-dashboard.expose-requests.questions.description',
  //     };
  //   }
  //   return {
  //     title: 'marketing-dashboard.low-expose-requests-number.warning.title',
  //     description:
  //       'marketing-dashboard.low-expose-requests-number.warning.description',
  //   };
  // }

  //   return {
  //     title:
  //       'marketing-dashboard.lower-advertisement-views-number.warning.title',
  //     description:
  //       'marketing-dashboard.lower-advertisement-views-number.warning.description',
  //   };
  // }, [showChart]);

  return (
    <Container>
      <TitleWrapper>
        <Title
          content={t('selling-simulation.general-statistics-card.views-number')}
        />
        <Info>
          <Tooltip
            icon={InformationCircle}
            id={'chart-statistics-tooltip'}
            position="top"
            width={16}
            height={16}
            className={'tooltip'}
          >
            <TooltipContainer>
              <TooltipParagraph>
                {t(
                  'marketing-dashboard.advertisement-views-statistics.tooltip'
                )}
              </TooltipParagraph>
            </TooltipContainer>
          </Tooltip>
        </Info>
      </TitleWrapper>
      <Content isBlurred={isBlurred}>
        {showChart ? (
          <>
            {chartLabels && chartValues ? (
              <ExposeStatisticsLineChart
                id="Expose-statistics"
                // labelFormatText="chart.tooltip.day"
                labels={chartLabels}
                data={chartValues}
                isSimulation={isSimulation}
                // footer="chart.days-on-market.footer"
              />
            ) : (
              <EmptyStatePlaceholder
                containerStyle={{ marginTop: '16px' }}
                icon={NoStatistics}
                title={t(
                  'marketing-dashboard.no-expose-statistics.placeholder.title'
                )}
              />
            )}
          </>
        ) : (
          <></>
        )}

        {showAlert && (!showChart || isEmpty(statistics)) && (
          <AlertContainer style={{ marginTop: showChart ? 0 : '16px' }}>
            <AlertContentWrapper>
              <AlertTextWrapper>
                <AlertTitle
                  content={t(
                    'marketing-dashboard.no-expose-requests.warning.title'
                  )}
                />

                <AlertText
                  content={t(
                    'marketing-dashboard.no-expose-requests.warning.description'
                  )}
                />
                <AlertActions>
                  {/* {onBookAppointment && ( */}
                  {/*  <ActionButton */}
                  {/*    label={t( */}
                  {/*      'marketing-dashboard.low-expose-requests-number.warning.book-appointment.button' */}
                  {/*    )} */}
                  {/*    onClick={onBookAppointment} */}
                  {/*  /> */}
                  {/* )} */}
                  {onEmailBroker && (
                    <ActionButton
                      label={t(
                        'marketing-dashboard.low-expose-requests-number.warning.email-broker.button'
                      )}
                      onClick={onEmailBroker}
                    />
                  )}
                </AlertActions>
              </AlertTextWrapper>
            </AlertContentWrapper>
          </AlertContainer>
        )}
        {showAlert &&
          showChart &&
          percentDifferenceBetweenHighestAndLatest >= 80 && (
            <AlertContainer
              style={{ marginTop: showChart ? 0 : '16px' }}
              className={isHiddenSendEmailToBrokerAlert ? 'hidden' : ''}
            >
              <AlertClose onClick={onSetAlertHidden}>
                <Icon
                  icon={Close}
                  width={16}
                  height={16}
                  color={themeContext.red}
                />
              </AlertClose>
              <AlertContentWrapper>
                <AlertTextWrapper>
                  <AlertTitle
                    content={t(
                      'marketing-dashboard.lower-advertisement-views-number.warning.title'
                    )}
                  />
                  <AlertText
                    content={t(
                      'marketing-dashboard.lower-advertisement-views-number.warning.description'
                    )}
                  />
                  <AlertActions>
                    {/* {onBookAppointment && ( */}
                    {/*  <ActionButton */}
                    {/*    label={t( */}
                    {/*      'marketing-dashboard.low-expose-requests-number.warning.book-appointment.button' */}
                    {/*    )} */}
                    {/*    onClick={onBookAppointment} */}
                    {/*  /> */}
                    {/* )} */}
                    {onEmailBroker && (
                      <ActionButton
                        label={t(
                          'marketing-dashboard.low-expose-requests-number.warning.email-broker.button'
                        )}
                        onClick={onEmailBroker}
                      />
                    )}
                  </AlertActions>
                </AlertTextWrapper>
              </AlertContentWrapper>
            </AlertContainer>
          )}
      </Content>
    </Container>
  );
};

const ExposeStatisticsCard = memo(ExposeStatisticsCardBase);

export { ExposeStatisticsCard };
