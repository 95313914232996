import { ReactNode } from 'react';
import styled from 'styled-components';
import { isNumber, isString } from 'lodash';
import { device } from '../../../../../style/theme';

interface IModalFormRow {
  children: ReactNode;
  className?: string;
  position?: string;
  bottom?: string;
  isIframe?: boolean;
  flexGrow?: number;
  alignItems?: string;
}

const Row = styled.div<{
  $position?: string;
  $bottom?: string;
  $isiframe?: boolean;
  $flexGrow?: number;
  $alignItems?: string;
}>`
  display: flex;
  flex-flow: row wrap;
  min-width: 100%;
  margin: 0 ${({ $isiframe }) => ($isiframe ? '0' : '-6')}px;
  ${({ $flexGrow }) => isNumber($flexGrow) && `flex-grow: ${$flexGrow};`}
  ${({ $alignItems }) =>
    isString($alignItems) && `align-items: ${$alignItems};`}
  ${({ $position }) =>
    $position &&
    `
    position:${$position};
  `}
  ${({ $bottom }) =>
    $bottom &&
    `
  bottom:${$bottom};
`}
@media ${({ $isiframe }) => ($isiframe ? '0' : device.tablet)} {
    > div {
      flex: 0 0 100%;
    }
  }
`;

const FormRow = ({
  className,
  children,
  position,
  bottom,
  isIframe,
  flexGrow,
  alignItems,
}: IModalFormRow): JSX.Element => {
  return (
    <Row
      className={className}
      $position={position}
      $bottom={bottom}
      $isiframe={isIframe}
      $flexGrow={flexGrow}
      $alignItems={alignItems}
    >
      {children}
    </Row>
  );
};

export { FormRow };
