import { useLocation } from 'react-router-dom';
import { siteMap, PortalType } from '../../../routes/site-map';

type TReturn = {
  isBuyer: boolean;
  isOwner: boolean;
  isSeller: boolean;
  isOverview: boolean;
  isFinancing: boolean;
  isBroker: boolean;
};

export const useGetPortalType = (): TReturn => {
  const { pathname } = useLocation();
  const isBuyer = Object.values(siteMap).some((item) => {
    return (
      pathname.includes(item.pathWithoutParams) &&
      item.portalType === PortalType.Buyer
    );
  });
  const isOwner = Object.values(siteMap).some((item) => {
    return (
      pathname.includes(item.pathWithoutParams) &&
      item.portalType === PortalType.Owner
    );
  });
  const isSeller = Object.values(siteMap).some((item) => {
    return (
      pathname.includes(item.pathWithoutParams) &&
      item.portalType === PortalType.Seller
    );
  });
  const isOverview = Object.values(siteMap).some((item) => {
    return (
      pathname === item.pathWithLang.replace(':lang', 'de-de') &&
      item.portalType === PortalType.Overview
    );
  });
  const isFinancing = Object.values(siteMap).some((item) => {
    return (
      pathname.includes(item.pathWithoutParams) &&
      item.portalType === PortalType.Financing
    );
  });
  const isBroker = Object.values(siteMap).some((item) => {
    return (
      pathname.includes(item.pathWithoutParams) &&
      item.portalType === PortalType.Broker
    );
  });
  return {
    isBuyer,
    isOwner,
    isSeller,
    isOverview,
    isFinancing,
    isBroker,
  };
};
