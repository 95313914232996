import annotationPlugin from 'chartjs-plugin-annotation';
import { memo, useContext, useMemo } from 'react';
import { Chart as ChartInstance, Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { PropertyValuation } from '../../../../../generated';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { abbrNum } from '../../../../common/utils/number-scientific-notation';
import { useCurrency } from '../../../hooks/useCurrency';
import { plugin } from '../../chart/background-plugin';

ChartInstance.register([annotationPlugin]);

interface IProps {
  propertyValuations: PropertyValuation[];
  propertyValuationsLastSync: string;
  propertyCurrentQuarterValuationIndex: number;
}

interface ITooltipData {
  chart: typeof ChartInstance;
  label: string;
  parsed: unknown;
  raw: unknown;
  formattedValue: string;
  dataset: {
    labels: string[];
    data: number[];
  };
  datasetIndex: number;
  dataIndex: number;
  element: Element;
}

const PriceDevelopmentLineChartBase = ({
  propertyValuations,
  propertyValuationsLastSync,
  propertyCurrentQuarterValuationIndex,
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const priceCurrency = useCurrency();

  const chartData = useMemo(() => {
    const labels: string[] = [];
    const data: number[] = [];
    propertyValuations
      ?.filter(
        (v) =>
          new Date(v.date).toDateString() !==
          new Date(propertyValuationsLastSync ?? '').toDateString()
      )
      .forEach(({ date, valuation }) => {
        labels.push(date);
        data.push(valuation?.salePrice ?? 0);
      });

    return {
      labels,
      datasets: [
        {
          labels,
          data,
          fill: false,
          borderColor: themeContext.blue,
          backgroundColor: themeContext.blue,
          pointerStyle: 'circle',
          pointRadius: 3,
          lineTension: 0,
          borderCapStyle: 'butt',
          borderWidth: 1,
          borderJoinStyle: 'miter',
          lineBorderDash: (context: ITooltipData) => {
            const index = context.datasetIndex;
            const date = context.dataset.labels[index];
            return new Date() > new Date(date) ? [] : [4, 2];
          },
          pointBorderColor: (context: ITooltipData) => {
            const index = context.dataIndex;
            const date = context.dataset.labels[index];
            return new Date() > new Date(date)
              ? themeContext.blue
              : themeContext.ctaBlue;
          },
          pointBackgroundColor: (context: ITooltipData) => {
            const index = context.dataIndex;
            const date = context.dataset.labels[index];
            return new Date() > new Date(date)
              ? themeContext.blue
              : themeContext.ctaBlue;
          },
          // pointBackgroundColor: themeContext.blue,
          pointBorderWidth: 1,
          pointHoverRadius: 3,
          pointHoverBackgroundColor: themeContext.blue,
          pointHoverBorderColor: themeContext.blue,
          pointHoverBorderWidth: 6,
          pointHitRadius: 3,
        },
      ],
    };
  }, [
    propertyValuations,
    propertyValuationsLastSync,
    themeContext.blue,
    themeContext.ctaBlue,
  ]);

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: 'point',
      },
      plugins: {
        legend: {
          display: false,
        },
        annotation: {
          annotations: {
            box1: {
              drawTime: 'afterDatasetsDraw',
              type: 'box',
              xMin: propertyCurrentQuarterValuationIndex,
              xMax: propertyValuations?.length - 2,
              backgroundColor: `${hexToRGB('#4e73f5', 0.2)}`,
            },
            line1: {
              type: 'line',
              xMin: propertyCurrentQuarterValuationIndex,
              xMax: propertyCurrentQuarterValuationIndex,
              borderColor: '#00305e',
              borderWidth: 2,
            },
          },
        },
        tooltip: {
          enabled: true,
          displayColors: false,
          backgroundColor: '#fff',
          titleColor: 'rgba(0, 48, 94, 0.8)',
          titleFont: {
            family: 'Roboto',
            size: 12,
            weight: '900',
            lineHeight: 1.67,
          },
          titleAlign: 'center',
          titleMarginBottom: 4,
          bodyColor: '#00305e',
          bodyFont: {
            family: 'Source Serif Pro',
            size: 16,
            weight: '600',
            lineHeight: 1.25,
          },
          bodyAlign: 'center',
          footerColor: 'rgba(0, 48, 94, 0.5)',
          footerFont: {
            family: 'Roboto',
            size: 10,
            weight: '900',
            lineHeight: 1.6,
          },
          footerAlign: 'center',
          footerMarginTop: 2,
          padding: {
            left: 20,
            right: 20,
            top: 8,
            bottom: 8,
          },
          cornerRadius: 3,
          borderColor: 'rgb(217, 217, 217)',
          borderWidth: 1,
          // xAlign: 'center',
          // yAlign: 'bottom',
          caretSize: 10,
          caretPadding: 8,
          usePointStyle: true,
          callbacks: {
            labelPointStyle: () => {
              return {
                pointStyle: 'triangle',
                rotation: 1,
              };
            },
            title: (context: [ITooltipData]) => {
              const { label } = context[0];

              if (!label) {
                return '';
              }

              const today: Date = new Date(label);
              const currentQuarter: number = Math.floor(today.getMonth() / 3);
              const year: string = label.split('-')[0];
              return `${t('Wert im Q')}${currentQuarter + 1}\`${year}`;
            },
            label: (context: ITooltipData) => {
              const { formattedValue, dataIndex, dataset } = context;
              const rawValue = dataset.data[dataIndex];

              if (!formattedValue) {
                return '';
              }

              return `${abbrNum(rawValue, 2) || ''} ${priceCurrency}`;
            },
            footer: (context: [ITooltipData]) => {
              const { label, dataIndex, dataset } = context[0];
              if (!label) {
                return '';
              }
              const currentValue = dataset?.data[dataIndex];
              const prevValue = dataset?.data[dataIndex - 1];

              const percentDifference =
                (100 *
                  // Math.abs(
                  (currentValue - prevValue)) /
                ((currentValue + prevValue) / 2);
              const today: Date = new Date(label);
              const currentQuarter: number = Math.floor(today.getMonth() / 3);
              const year: string = label.split('-')[0];

              if (!prevValue) {
                return '';
              }

              return `${percentDifference.toFixed(2)}% VS Q${
                currentQuarter + 1
              }\`${year}`;
            },
          },
        },
      },
      chartArea: {
        backgroundColor: hexToRGB('#4e73f5', 0.05),
        step: 4,
      },
      scales: {
        x: {
          // type: 'timeseries',
          beginAtZero: false,
          offset: true,
          grid: {
            display: true,
            drawTicks: false,
            drawOnChartArea: false,
          },
          ticks: {
            major: {
              enabled: true,
            },
            maxRotation: 0,
            align: 'center',
            crossAlign: 'near',
            includeBounds: false,
            labelOffset: 0,
            padding: 8,
            color: 'rgba(0, 48, 94, 0.3)',
            font: {
              family: 'Roboto',
              size: 10,
              weight: '900',
              lineHeight: 1.6,
            },
            callback(value: number) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const label = this.getLabelForValue(value);
              if (!label) return '';
              const today = new Date(label);

              const currentQuarter = Math.floor(today.getMonth() / 3);

              if (currentQuarter === 0) {
                const year: string = label.split('-')[0];
                return year;
              }

              return null;
            },
          },
        },
        y: {
          beginAtZero: false,
          offset: true,
          grid: {
            drawBorder: true,
            borderDash: [2, 1],
            zeroLineColor: 'red',
            drawTicks: false,
          },
          ticks: {
            maxTicksLimit: 5,
            color: '#00305e',
            font: {
              family: 'Roboto',
              size: 12,
              weight: 'bold',
              lineHeight: 1.33,
            },
            callback: (value: number) => {
              return `${abbrNum(value, 2)} ${priceCurrency}     `;
            },
          },
        },
      },
    }),
    [
      priceCurrency,
      propertyCurrentQuarterValuationIndex,
      propertyValuations?.length,
      t,
    ]
  );

  return (
    <Line
      data={chartData}
      options={options}
      plugins={[plugin]}
      id={t('valuation.overview.price.development.title')}
    />
  );
};

const PriceDevelopmentLineChart = memo(PriceDevelopmentLineChartBase);

export { PriceDevelopmentLineChart };
