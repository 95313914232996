import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  CSSProperties,
  FC,
  memo,
  ReactNode,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { ThemeContext } from 'styled-components';
import {
  DeepMap,
  FieldError,
  FieldValues,
  useFormContext,
} from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { ReactComponent as InformationCircle } from 'assets/streamline-light/interface-essential/alerts/information-circle.svg';
import { ReactComponent as InformationCircleBold } from 'assets/images/icons/common/tooltip-key-bold.svg';
import {
  CalendarIcon,
  DatePickerContainer,
  InfoWrapper,
  Input,
  InputWrapper,
  Label,
} from './input-date-styles';
import Icon from '../../../ui/icon';
import { ReactComponent as ArrowLeft } from '../../../../../../assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as ArrowRight } from '../../../../../../assets/streamline-light/arrows-diagrams/arrows/arrow-button-right-1.svg';
import { datePickerLocales } from './locales';
import Calendar from '../../../../../../assets/calendar.webp';
import {
  ErrorContainer,
  ErrorMessage,
  Info,
  Spacer,
  TooltipContainer,
  TooltipParagraph,
} from '../../../../../forms/components/common/input-v2/input-v2-styles';
import { useIsMobileSize } from '../../../../hooks/useIsMobileSize';
import { Tooltip } from '../../../tooltip';

interface IProps {
  name: string;
  value?: string;
  inputValue?: string;
  label?: string;
  labelWidth?: string;
  labelMR?: string;
  labelMobileMR?: string;
  isModalDesign?: boolean;
  selected: Date | null | undefined;
  onChange: (
    date: Date | null,
    event: React.SyntheticEvent<unknown, Event> | undefined
  ) => void;
  customInput?: ReactNode;
  placeholder?: string;
  tooltipKey?: string;
  tooltipWidth?: number;
  isPlaceholderCentered?: boolean;
  isBoldTooltipKeyIcon?: boolean;
  isTooltipKeyLeftSide?: boolean;
  isValidateOnBlur?: boolean;
  withPortal?: boolean;
  isOnClickTooltip?: boolean;
  adjustDateOnChange?: boolean;
  locale?: string;
  calendarStartDay?: number;
  dateFormat?: string;
  errors?: DeepMap<FieldValues, FieldError>;
  noTranslate?: boolean;
  iconStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  wrapperStyle?: CSSProperties;
}

const InputDateBase: FC<IProps> = ({
  name,
  value,
  inputValue,
  label,
  labelWidth,
  labelMR,
  labelMobileMR,
  selected,
  onChange,
  customInput,
  isModalDesign,
  placeholder,
  tooltipKey,
  tooltipWidth,
  isPlaceholderCentered,
  isBoldTooltipKeyIcon,
  isTooltipKeyLeftSide,
  isValidateOnBlur,
  withPortal,
  isOnClickTooltip,
  adjustDateOnChange = true,
  locale = 'de',
  calendarStartDay = 1,
  dateFormat = 'dd/MM/yyyy',
  errors,
  noTranslate,
  iconStyle,
  inputStyle,
  labelStyle,
  wrapperStyle,
}) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const inputRef = useRef<DatePicker | null>(null);
  const isMobileSize = useIsMobileSize();
  const { trigger } = useFormContext();

  const selectedLocale = useMemo(
    () => datePickerLocales.get(locale) ?? datePickerLocales.get('de'),
    [locale]
  );

  const handleChange = (
    date: Date | null,
    event: React.SyntheticEvent<unknown, Event> | undefined
  ) => {
    onChange(date, event);
    if (isValidateOnBlur) {
      trigger(name);
    }
  };

  const onClickLabel = () => {
    if (tooltipKey) {
      const tooltip = document.querySelector(
        `#${tooltipKey.replaceAll('.', '-')}-icon`
      ) as HTMLParagraphElement;
      if (tooltip) {
        tooltip.click();
      }
    }
  };

  return (
    <div>
      <InputWrapper className="input-wrapper" isModalDesign={isModalDesign}>
        <InfoWrapper
          style={wrapperStyle}
          isTooltipKeyLeftSide={isTooltipKeyLeftSide}
        >
          {label && (
            <Label
              className="label"
              isModalDesign={isModalDesign}
              style={labelStyle}
              labelWidth={labelWidth}
              labelMR={labelMR}
              labelMobileMR={labelMobileMR}
              onClick={onClickLabel}
            >
              {noTranslate ? label : t(label)}
            </Label>
          )}
          {tooltipKey && (
            <Info className="info" tooltipWidth={tooltipWidth}>
              <Tooltip
                icon={
                  isBoldTooltipKeyIcon
                    ? InformationCircleBold
                    : InformationCircle
                }
                isOnClick={isOnClickTooltip}
                id={tooltipKey}
                position="bottom"
                width={16}
                height={16}
              >
                <TooltipContainer>
                  <TooltipParagraph>{t(tooltipKey)}</TooltipParagraph>
                </TooltipContainer>
              </Tooltip>
            </Info>
          )}
        </InfoWrapper>
        <DatePickerContainer
          className="date-picker-container"
          isModalDesign={isModalDesign}
        >
          <DatePicker
            ref={inputRef}
            onFocus={() => {
              // disable browser default cursor for input
              if (isMobileSize) {
                inputRef?.current?.setBlur();
              }
            }}
            name={name}
            calendarStartDay={calendarStartDay}
            adjustDateOnChange={adjustDateOnChange}
            withPortal={withPortal}
            className={'date-picker'}
            selected={selected}
            // value={value}
            onChange={handleChange}
            dateFormat={dateFormat}
            placeholderText={t(placeholder || '')}
            renderDayContents={(day, date) => {
              const isPrevMonth = selected?.getMonth() !== date?.getMonth();
              return (
                <div
                  className={'day'}
                  style={{ opacity: isPrevMonth ? 0.5 : 1 }}
                >
                  <div className={'day-number'}>{day}</div>
                  <div className={'week-name'}>
                    {selectedLocale?.days[date?.getDay() ?? 0]}
                  </div>{' '}
                </div>
              );
            }}
            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
              <div className={'picker-header'}>
                <Icon
                  icon={ArrowLeft}
                  onClick={decreaseMonth}
                  color={theme.blue}
                  width={14}
                  height={14}
                  style={{ cursor: 'pointer' }}
                />

                <div className={'month-name'}>
                  {`${
                    selectedLocale?.months[date.getMonth()]
                  } ${date.getFullYear()}`}
                </div>

                <Icon
                  icon={ArrowRight}
                  onClick={increaseMonth}
                  color={theme.blue}
                  width={14}
                  height={14}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            )}
            customInput={
              customInput ?? (
                <Input
                  isModalDesign={isModalDesign}
                  isPlaceholderCentered={isPlaceholderCentered}
                  inputMode={'none'}
                  $hasError={Boolean(errors?.[name])}
                  style={inputStyle}
                />
              )
            }
          />
          <CalendarIcon alt={'calendar'} src={Calendar} style={iconStyle} />
        </DatePickerContainer>
      </InputWrapper>
      {errors?.[name] && (
        <ErrorContainer>
          <Spacer />
          <ErrorMessage>
            {noTranslate ? errors[name].message : t(errors[name].message)}
          </ErrorMessage>
        </ErrorContainer>
      )}
    </div>
  );
};

const InputDate = memo(InputDateBase);

export { InputDate };
