import { StatisticsItem, OffersStatisticsItem } from '../../interfaces';
import { MarketingGeneralStatistics } from '../../../../generated';
import { getWeek } from '../../utils/getWeek';
import { formatPrice } from '../../utils/formatPrice';
import { adjustPriceByPercent } from '../../utils/calculatePercent';
import { formatNumber } from '../../utils/formatNumber';

export const generalStatistics: MarketingGeneralStatistics = {
  highestBid: 650_000,
  bids: 2,
  viewingAppointments: 5,
  advertisementViews: 241,
  requestedExposes: 13,
  unlockedExposes: 51,
  impressions: 4901,
  bookmarks: 34,
};

const currentWeek = getWeek(new Date()).weekNumber;

export const advertisementViewsStatistics: StatisticsItem[] = [
  // {
  //   label: `KW${currentWeek - 8}`,
  //   value: 607,
  // },
  // {
  //   label: `KW${currentWeek - 7}`,
  //   value: 604,
  // },
  // {
  //   label: `KW${currentWeek - 6}`,
  //   value: 616,
  // },
  // {
  //   label: `KW${currentWeek - 5}`,
  //   value: 624,
  // },
  // {
  //   label: `KW${currentWeek - 4}`,
  //   value: 621,
  // },
  // {
  //   label: `KW${currentWeek - 3}`,
  //   value: 630,
  // },
  {
    label: `KW${currentWeek - 2}`,
    value: 115,
  },
  {
    label: `KW${currentWeek - 1}`,
    value: 80,
  },
  {
    label: `KW${currentWeek}`,
    value: 46,
  },
];

export const exposeStatistics: StatisticsItem[] = [
  {
    label: `KW${currentWeek - 8}`,
    value: 15,
  },
  {
    label: `KW${currentWeek - 7}`,
    value: 20,
  },
  {
    label: `KW${currentWeek - 6}`,
    value: 21,
  },
  {
    label: `KW${currentWeek - 5}`,
    value: 23,
  },
  {
    label: `KW${currentWeek - 4}`,
    value: 18,
  },
  {
    label: `KW${currentWeek - 3}`,
    value: 30,
  },
  {
    label: `KW${currentWeek - 2}`,
    value: 24,
  },
  {
    label: `KW${currentWeek - 1}`,
    value: 23,
  },
  {
    label: `KW${currentWeek}`,
    value: 34,
  },
];

export const simulationHighestBidPrice = (
  currentValuation?: number | null
): number => adjustPriceByPercent(currentValuation ?? 0, 10, 'increase');

export const offersStatisticsPerWeek = (
  priceCurrency: string,
  currentValuation?: number | null
): OffersStatisticsItem[] => {
  const bid7price = adjustPriceByPercent(currentValuation ?? 0, 10, 'increase');
  const bid6price = currentValuation ?? 0;
  const bid5price = adjustPriceByPercent(currentValuation ?? 0, 8, 'decrease');
  const bid4price = adjustPriceByPercent(currentValuation ?? 0, 6, 'increase');
  const bid3price = adjustPriceByPercent(currentValuation ?? 0, 2, 'decrease');

  return [
    {
      _id: String(getWeek(new Date()).weekNumber),
      weekNumber: getWeek(new Date()).weekNumber,
      dateFrom: getWeek(new Date()).startWeekDate,
      dateTo: getWeek(new Date()).endWeekDate,
      highestBidId: 7,
      offers: [
        {
          bidOnOfficeId: '7',
          // customerId: 7,
          customerId: 2,
          price: currentValuation
            ? `${formatPrice(bid7price, priceCurrency)}`
            : '€520.500',
          fromCity: 'München',
          keywords: ['7', '520500', '520.500', 'München', 'NEUES ANGEBOT'].map(
            (keyword) => keyword?.toLowerCase()
          ),
          isNew: true,
          date: getWeek(new Date()).startWeekDate,
        },
        // {
        //   bidOnOfficeId: '6',
        //   customerId: 6,
        //   price: currentValuation
        //     ? `${formatPrice(bid6price, priceCurrency)}`
        //     : '€585.500',
        //   fromCity: 'Berlin',
        //   keywords: ['6', '585500', '585.500', 'Berlin', 'NEUES ANGEBOT'].map(
        //     (keyword) => keyword?.toLowerCase()
        //   ),
        //   isNew: true,
        //   date: getWeek(new Date()).startWeekDate,
        // },
      ],
      generalStatistics: {
        advertisementViews: 46,
        requestedExposes: 1,
        unlockedExposes: 1,
        viewingAppointments: 1,
        bookmarks: 4,
        impressions: 1441,
      },
    },
    {
      _id: String(
        getWeek(new Date(new Date().setDate(new Date().getDate() - 7)))
          .weekNumber
      ),
      weekNumber: getWeek(
        new Date(new Date().setDate(new Date().getDate() - 7))
      ).weekNumber,
      dateFrom: getWeek(new Date(new Date().setDate(new Date().getDate() - 7)))
        .startWeekDate,
      dateTo: getWeek(new Date(new Date().setDate(new Date().getDate() - 7)))
        .endWeekDate,
      offers: [
        {
          bidOnOfficeId: '7',
          // customerId: 7,
          customerId: 1,
          price: currentValuation
            ? `${formatPrice(bid5price, priceCurrency)}`
            : '€520.500',
          fromCity: 'München',
          keywords: ['7', '520500', '520.500', 'München', 'NEUES ANGEBOT'].map(
            (keyword) => keyword?.toLowerCase()
          ),
          isNew: true,
          date: getWeek(new Date()).startWeekDate,
        },
      ],
      generalStatistics: {
        advertisementViews: 80,
        requestedExposes: 4,
        unlockedExposes: 4,
        viewingAppointments: 1,
        bookmarks: 8,
        impressions: 1650,
      },
    },
    {
      _id: String(
        getWeek(new Date(new Date().setDate(new Date().getDate() - 14)))
          .weekNumber
      ),
      weekNumber: getWeek(
        new Date(new Date().setDate(new Date().getDate() - 14))
      ).weekNumber,
      dateFrom: getWeek(new Date(new Date().setDate(new Date().getDate() - 14)))
        .startWeekDate,
      dateTo: getWeek(new Date(new Date().setDate(new Date().getDate() - 14)))
        .endWeekDate,
      offers: [
        // {
        //   bidOnOfficeId: '5',
        //   // customerId: 5,
        //   customerId: 1,
        //   price: currentValuation
        //     ? `${formatPrice(bid5price, priceCurrency)}`
        //     : '€550.000',
        //   fromCity: 'München',
        //   keywords: ['5', '550000', '550.000', 'München'].map((keyword) =>
        //     keyword?.toLowerCase()
        //   ),
        //   date: getWeek(new Date(new Date().setDate(new Date().getDate() - 14)))
        //     .endWeekDate,
        // },
        // {
        //   bidOnOfficeId: '4',
        //   customerId: 4,
        //   price: currentValuation
        //     ? `${formatPrice(bid4price, priceCurrency)}`
        //     : '€650.000',
        //   fromCity: 'Berlin',
        //   keywords: ['4', '650000', '650.000', 'Berlin', 'HÖCHSTGEBOT'].map(
        //     (keyword) => keyword?.toLowerCase()
        //   ),
        //   date: getWeek(new Date(new Date().setDate(new Date().getDate() - 14)))
        //     .startWeekDate,
        // },
        // {
        //   bidOnOfficeId: '3',
        //   customerId: 3,
        //   price: currentValuation
        //     ? `${formatPrice(bid3price, priceCurrency)}`
        //     : '€600.000',
        //   fromCity: 'Frankfurt',
        //   keywords: ['3', '600000', '600.000', 'Frankfurt'].map((keyword) =>
        //     keyword?.toLowerCase()
        //   ),
        //   date: getWeek(new Date(new Date().setDate(new Date().getDate() - 14)))
        //     .startWeekDate,
        // },
      ],
      generalStatistics: {
        advertisementViews: 115,
        requestedExposes: 8,
        unlockedExposes: 46,
        viewingAppointments: 3,
        bookmarks: 22,
        impressions: 1810,
      },
    },
  ];
};
