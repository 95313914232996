import { SubmitHandler } from 'react-hook-form';
import { batch, useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { ILoginForm } from '../../../auth/interfaces';
import {
  login,
  setAnonymousUser,
  setUserInput,
  setUserState,
  toggleIsRegisterModalOpen,
  toggleLoading,
} from '../../../auth/redux/authSlice';
import { LoginResponse, useSignInMutation } from '../../../../generated';
import { client } from '../../../../services/graphql/graphql';
import { ISearchProfileForm } from '../interface';
import {
  toggleLoginForm,
  toggleRegisterForm,
} from '../redux/searchProfileSlice';
import { useAppSelector } from '../../../common/hooks';

type TProps = {
  setErrors: (state: null | unknown) => void;
  onSuccess?: (formData: ISearchProfileForm, ownerId: string) => Promise<void>;
};

type TReturn = {
  onLoginSubmit: (formData: ILoginForm) => void;
};

const useLoginSP = ({ setErrors, onSuccess }: TProps): TReturn => {
  const dispatch = useDispatch();

  const [inputPassword, setInputPassword] = useState('');

  const userInput = useAppSelector((state) => state.searchProfile.userInput);
  const regionalBrokerId = useAppSelector(
    (state) => state.searchProfile.regionalBrokerId
  );

  // Mutations
  const [signIn, { data: signInData }] = useSignInMutation();

  const onLoginSubmit: SubmitHandler<ILoginForm> = useCallback(
    async (formData) => {
      const { userAuthenticationKey, password } = formData;
      setInputPassword(password);

      if (userAuthenticationKey && password) {
        try {
          dispatch(toggleLoading(true));

          await signIn({
            input: {
              userAuthenticationKey,
              password,
              requestAccessToken: true,
              regionalBrokerId,
            },
          }).unwrap();
        } catch (error: unknown) {
          setErrors(error);
        } finally {
          dispatch(toggleLoading(false));
        }
      }
    },
    [dispatch, regionalBrokerId, setErrors, signIn]
  );

  useEffect(() => {
    if (signInData) {
      const loginResponse = signInData.signIn as LoginResponse;
      if (loginResponse?.jwt?.token) {
        client.setHeader(
          'Authorization',
          `Bearer ${loginResponse?.jwt?.token ?? ''}`
        );

        batch(() => {
          dispatch(setAnonymousUser(false));
          dispatch(login(loginResponse));
          dispatch(setUserState(loginResponse.user.userState));
          if (onSuccess) {
            onSuccess(userInput as ISearchProfileForm, loginResponse.user._id);
          }
        });
      } else if (loginResponse.user && !loginResponse.jwt) {
        batch(() => {
          const {
            user: {
              userState,
              name,
              surname,
              phone,
              email,
              termsAndConditions,
            },
          } = loginResponse;
          dispatch(toggleRegisterForm(true));
          dispatch(toggleLoginForm(false));
          dispatch(toggleIsRegisterModalOpen(true));
          dispatch(setUserState(userState));
          dispatch(
            setUserInput({
              name,
              surname,
              phonePrefix: '',
              phone,
              email,
              password: inputPassword,
              confirmPassword: inputPassword,
              termsAndConditions,
            })
          );
        });
      }
    }
  }, [dispatch, inputPassword, onSuccess, signInData, userInput]);

  return { onLoginSubmit };
};

export { useLoginSP };
