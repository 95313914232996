import { useEffect } from 'react';
import { isNumber } from 'lodash';

import { useAppSelector } from '../../../../common/hooks';
import {
  FinancingReason,
  FinancingType,
  useGetFinancingPropositionsMutation,
  UserState,
} from '../../../../../generated';
import { parseFinancingInput } from '../../utils/parse-financing-input';
import { IFinancingForm } from '../../interface';
import { getBirthday } from '../../utils/get-birthday';

const useGetFinancingPropositionsAuth = () => {
  const {
    userInput,
    selectedAddress,
    isAuthFlow,
    financingType,
    isConfirmEmailSent,
  } = useAppSelector((state) => state.financing);
  const householdNetIncome = useAppSelector(
    (state) => state.auth.userInput?.householdNetIncome
  );
  const age = useAppSelector((state) => state.auth.userInput?.age);
  const mainEarner = useAppSelector(
    (state) => state.auth.userInput?.mainEarner
  );
  const userState = useAppSelector((state) => state.auth.userState);

  const [getFinancingPropositions, { data, isLoading }] =
    useGetFinancingPropositionsMutation();

  useEffect(() => {
    if (
      isAuthFlow &&
      userState === UserState.VerifyEmail &&
      isConfirmEmailSent
    ) {
      (async () => {
        if (userInput && selectedAddress) {
          const { financingData, financingVariants } = parseFinancingInput(
            userInput as IFinancingForm,
            financingType || FinancingType.Financing,
            selectedAddress
          );
          let birthday = '';
          if (age && isNumber(age)) {
            birthday = getBirthday(age);
          }
          const input = {
            ...financingVariants[0].financingVariantData,
            ...financingData,
            title: undefined,
            countryCode: undefined,
            householdNetIncome,
            birthday,
            mainEarner,
            isMaximumPurchasePrice:
              financingVariants[0].financingVariantData.reason ===
              FinancingReason.PurchaseOfExistingProperty,
            // for financing certificate
            debitRateCommitment: '10',
          };
          await getFinancingPropositions({ input });
        }
      })();
    }
  }, [isAuthFlow, isConfirmEmailSent, userState]);

  return { data: data?.getFinancingPropositions, isLoading };
};

export { useGetFinancingPropositionsAuth };
