import { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';

import Icon from '../ui/icon';
import { Headline2 } from '../ui/typography';

interface IModalTitle {
  className?: string;
  text: string;
  textColor?: string;
  textAlign: 'center' | 'left' | 'right';
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  iconWidth?: number;
  iconHeight?: number;
  iconPosition?: 'top' | 'left';
  iconColor?: string;
  margin?: string;
  isCentered?: boolean;
}

const TitleWrapper = styled.div<{
  iconPosition: IModalTitle['iconPosition'];
  margin?: string;
  isCentered?: boolean;
}>`
  display: flex;
  ${({ margin }) =>
    css`
      margin: ${margin || '0 0 24px'};
    `}
  ${({ iconPosition, isCentered }) =>
    isCentered
      ? `flex-flow: row unset; justify-content: center;`
      : iconPosition === 'left'
      ? `flex-flow: row unset; align-items: flex-start;`
      : `flex-flow: column wrap; justify-content: center`}
`;

const IconWrapper = styled.div<{
  iconPosition: IModalTitle['iconPosition'];
  align: IModalTitle['textAlign'];
}>`
  display: flex;
  ${({ iconPosition }) =>
    iconPosition === 'left' ? 'margin: 0 16px 0 0;' : 'margin: 0 0 16px;'}
  justify-content: ${({ align }) =>
    align === 'left'
      ? 'flex-start'
      : align === 'center'
      ? 'center'
      : 'flex-end'};
  line-height: 0;
`;

const Title = styled(Headline2)<{
  textAlign: IModalTitle['textAlign'];
  iconPosition?: IModalTitle['iconPosition'];
  color?: IModalTitle['textColor'];
}>`
  font-size: 20px;
  color: ${(props) => props.color || props.theme.blue};
   {
    text-align: ${({ textAlign }) => textAlign};
  }
  ${({ iconPosition }) =>
    iconPosition === 'left' &&
    css`
      align-self: center;
      text-transform: uppercase;
    `}
`;

const ModalTitle = ({
  className,
  text,
  textColor,
  textAlign = 'left',
  icon,
  iconWidth,
  iconHeight,
  iconPosition,
  iconColor,
  margin,
  isCentered,
}: IModalTitle): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  return (
    <TitleWrapper
      className={`${className} modal-title-wrapper`}
      iconPosition={iconPosition}
      margin={margin}
      isCentered={isCentered}
    >
      {icon && (
        <IconWrapper iconPosition={iconPosition} align={textAlign}>
          <Icon
            width={iconWidth}
            height={iconHeight}
            icon={icon}
            color={iconColor || themeContext.blue}
          />
        </IconWrapper>
      )}
      <Title textAlign={textAlign} content={text} color={textColor} />
    </TitleWrapper>
  );
};

export { ModalTitle };
