import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const WindowIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={
          'M3 10.5h18m-18 6h18m-12-6v13m6-13v13m-3-23v10L5.671 3.101M12 10.5l6.329-7.399'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={'M21 22.5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-13a9 9 0 0 1 18 0z'}
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { WindowIcon };
