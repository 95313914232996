import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import {
  MainButton,
  SecondaryButton,
} from '../../../../common/components/ui/buttons';

export const Container = styled.div`
  min-height: 497px;
  box-sizing: border-box;
  width: 1088px;
  margin: 64px auto 90px;
  padding: 48px;
  border-radius: 3px;
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  display: flex;

  @media ${device.tablet} {
    width: 100%;
    height: auto;
    margin: 0 !important;
    padding: 0;
    box-shadow: none;
    flex-direction: column;
  }
`;

export const Content = styled.div`
  color: #00305e;
  letter-spacing: 0.5px;
  margin-right: 60px;
  width: 556px;

  label {
    color: #00305e;
  }

  @media ${device.tablet} {
    margin: 16px;
    padding: 0;
    width: initial;
  }
`;

export const Title = styled.h2`
  font-family: 'Source Serif Pro';
  margin: 0 0 8px 0;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.33;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
    margin: 6px 0 9px 0;
    letter-spacing: 0.5px;
    text-align: center;
  }
`;

export const ExpandedText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  margin: 0 0 24px 0;

  @media ${device.tablet} {
    margin: 0 0 12px 0;
  }
`;

export const InputContainer = styled.div`
  max-width: 482px;

  @media ${device.tablet} {
    max-width: initial;
  }
`;

export const InputBox = styled.div<{ buttonMargin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  ${(props) => props.buttonMargin && 'margin-bottom: 24px;'}

  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    gap: 8px;
  }
`;

export const InputLabel = styled.label`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #00305e;
`;

export const InputItemBox = styled.div`
  width: 212px;

  #federalState,
  & input {
    min-width: 212px;
    max-width: 212px;
  }

  & > div > div {
    gap: 0;
  }

  @media ${device.tablet} {
    width: 100%;

    #federalState,
    & input {
      min-width: 100%;
      max-width: 100%;
    }
  }
`;

export const CalculateButton = styled(SecondaryButton)`
  background-color: transparent;
  border-color: #00305e;
  color: #00305e;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const Box = styled.div`
  position: relative;
  top: 90px;
  background: #002849;
  color: white;
  padding: 69px 48px 48px;
  width: 375px;
  height: 381px;

  @media ${device.tablet} {
    position: relative;
    top: 0;
    height: auto;
    width: 100%;
    padding: 0;
  }
`;

export const IconBox = styled.div`
  top: -40px;
  left: 48px;
  height: 80px;
  width: 80px;
  background: white;
  border-radius: 100%;
  border: solid 1px #002849;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    display: none;
  }
`;

export const IconBoxMobile = styled.div`
  margin: 0 auto 6px auto;
  height: 48px;
  width: 48px;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 100%;
  display: none;
  border: solid 0.6px #002849;

  @media ${device.tablet} {
    display: flex;
  }
`;

export const Icon = styled.img`
  height: 40px;
  width: 40px;
  object-fit: contain;

  @media ${device.tablet} {
    height: 24px;
    width: 24px;
  }
`;

export const BoxLabel = styled.div<{
  topMargin?: boolean;
  gapMargin?: boolean;
  isError?: boolean;
}>`
  font-family: Source Serif Pro, sans-serif;
  font-weight: 600;
  margin-bottom: 16px;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 1;

  ${({ isError }) => isError && 'filter: blur(4px);'}

  ${(props) => props.gapMargin && 'margin-top: 30px;'}

  @media ${device.tablet} {
    margin-left: 40px;

    ${(props) => props.gapMargin && 'margin-top: 20px;'}
    ${(props) => props.topMargin && 'margin-top: 24px;'}
  }
`;

export const BoxAmount = styled.div<{
  isError?: boolean;
}>`
  font-family: Source Serif Pro, sans-serif;
  opacity: 0.88;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1;
  ${({ isError }) => isError && 'filter: blur(4px);'}

  @media ${device.tablet} {
    margin: 16px 0 0 40px;
  }
`;

export const AmountPlaceholderBox = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
`;

export const AmountPlaceholder = styled.div<{ delay?: boolean }>`
  @keyframes barWidth {
    0% {
      width: 10%;
    }
    25% {
      width: 50%;
    }
    50% {
      width: 100%;
    }
    75% {
      width: 50%;
    }
    100% {
      width: 10%;
    }
  }

  position: relative;
  width: 0;
  height: 5px;
  border-radius: 3px;
  animation: barWidth;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  ${(props) => (props.delay ? 'animation-delay: .5s;' : 'animation-delay: 0s;')}
  background: rgba(255,255,255, 0.88);
`;

export const ButtonBox = styled.div<{
  isError?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ isError }) => isError && 'filter: blur(4px);'}

  @media ${device.tablet} {
    margin-top: 8px;
    gap: 24px;
  }
`;

export const GhostBtn = styled.button`
  margin: 8px 0 24px 0;
  background: transparent;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }

  @media ${device.tablet} {
    margin: 0 0 0 40px;
  }
`;

export const MainBtn = styled(MainButton)`
  padding: 14px 24px;
  border-radius: 3px;
  text-transform: uppercase;
  white-space: nowrap;

  @media ${device.tablet} {
    width: calc(100% - 32px);
    justify-content: center;
    margin: 0 16px 24px;
  }
`;

export const ErrorContainer = styled.div`
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  padding: 0 40px;

  @media ${device.tablet} {
    padding: 0 24px 0;
  }
`;

export const ErrorBox = styled.div`
  padding: 16px 12px 12px;
  border-radius: 3px;
  border: 2px solid rgba(162, 87, 87, 0.2);
  box-shadow: 0px 8px 30px rgba(0, 40, 73, 0.5);
  opacity: 1;
  background-color: rgba(240, 233, 233, 1);
`;

export const ErrorTitle = styled.h3`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${({ theme }) => theme.blue};
  font-family: Source Serif Pro, sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.75px;
  text-align: left;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 22px;
`;
