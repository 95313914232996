import { FC, memo } from 'react';
import { isNumber } from 'lodash';
import { useUsefulValuationContainerData } from '../../../../../hooks/useGetValuationFormInfo';
// import { useIsOnLandingPage } from '../../../../../hooks/useIsOnLandingPage';
import { useConvertProperty } from '../../../../../hooks/useConvertProperty';
import { useSubmitOnlineEVForm } from '../../../../../hooks/callback-hooks/online-valuation/useSubmitOnlineEVForm';
import { ValuationLoader } from '../../../../../../components/common/loader';
import { ValuationForm } from '../../../../forms/valuation-form/valuation-form';
import { AuthContainer } from '../../../auth-containers/auth-container';
import { useIsMobileSize } from '../../../../../../../common/hooks/useIsMobileSize';

interface IProps {
  activeStepProp?: number;
  minStepProp?: number;
}

const OnlineValuationBase: FC<IProps> = ({ activeStepProp }) => {
  const {
    activeStep,
    owner,
    onSwitchToAuth,
    valuationWizardErrors,
    isAuthFlow,
    isLoading,
  } = useUsefulValuationContainerData();
  // const { isOnLandingPage } = useIsOnLandingPage();
  const isMobileSize = useIsMobileSize();
  useConvertProperty();

  const { onCreateProperty } = useSubmitOnlineEVForm();
  if (owner) {
    return (
      <>
        {isLoading ? (
          <ValuationLoader />
        ) : (
          <ValuationForm
            isHeadline={!isMobileSize}
            activeFormStep={
              isNumber(activeStepProp) ? activeStepProp : activeStep
            }
            errors={valuationWizardErrors}
            onSubmit={onCreateProperty}
          />
        )}
      </>
    );
  }

  return (
    <>
      {!isAuthFlow ? (
        <>
          {isLoading ? (
            <ValuationLoader />
          ) : (
            <ValuationForm
              isHeadline={!isMobileSize}
              activeFormStep={
                isNumber(activeStepProp) ? activeStepProp : activeStep
              }
              errors={valuationWizardErrors}
              lastStepCallback={onSwitchToAuth}
            />
          )}
        </>
      ) : (
        <AuthContainer />
      )}
    </>
  );
};

const OnlineValuation = memo(OnlineValuationBase);

export { OnlineValuation };
