import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Direction,
  OrderSuggestedPropertiesBy,
  PaginatedSuggestedProperties,
  SuggestedProperty as SuggestedPropertyType,
  useSuggestedPropertiesQuery,
} from '../../../../../../generated';
import { SuggestedPropertyCard } from '../../../../../search-profile/components/suggested-properties/suggested-property-card';

import {
  PropertiesContainer,
  Column,
  ShowMoreButton,
  Header,
  LoaderContainer,
} from './suggested-properties-styles';
import { GlobalLoader } from '../../../../../common/components/ui/loaders/global-loader';
import { useGetCountry } from '../../../../../localization/get-country';
import { useGeolocation } from '../../../../../common/hooks/useGeolocation';
import { FrankfurtLocation } from '../../../../constants';

export interface SuggestedPropertiesProps {
  title?: string;
}

export interface SuggestedPropertyProps {
  section: SuggestedPropertyType;
}

export interface SuggestedPropertiesResponse {
  data: { suggestedProperties: PaginatedSuggestedProperties };
  isLoading: boolean;
  isFetching: boolean;
  isRefetching: boolean;
}

const SuggestedProperties: FC<SuggestedPropertiesProps> = ({ title }) => {
  const { t } = useTranslation();
  const { language } = useGetCountry();
  const geolocation = useGeolocation();
  const [isRefetched, setIsRefetched] = useState<boolean>(false);

  const { data, isLoading, refetch, isFetching, isRefetching } =
    useSuggestedPropertiesQuery<SuggestedPropertiesResponse>({
      filter: {
        offset: 0,
        limit: 6,
        orderBy: {
          field: OrderSuggestedPropertiesBy.Proximity,
          direction: Direction.Desc,
          proximityParams: {
            latitude: geolocation?.latitude ?? FrankfurtLocation.latitude,
            longitude: geolocation?.longitude ?? FrankfurtLocation.longitude,
          },
        },
      },
    });

  useEffect(() => {
    if (!isRefetched && geolocation) {
      refetch();
      setIsRefetched(true);
    }
  }, [geolocation, refetch, isRefetched]);

  const onClickShowMore = useCallback(
    () =>
      window.open(
        'https://www.von-poll.com/de/immobilien/immobilien-suchen',
        '_blank'
      ),
    []
  );

  const items = useMemo(
    () => data?.suggestedProperties.page,
    [data?.suggestedProperties.page]
  );

  return (
    <Column>
      {title && <Header content={title} />}
      {isLoading || isFetching ? (
        <LoaderContainer>
          <GlobalLoader />
        </LoaderContainer>
      ) : (
        <PropertiesContainer>
          {items?.map((section: SuggestedPropertyType) => {
            return (
              <SuggestedPropertyCard
                key={section.propertyId}
                propertyId={section.propertyId}
                id={section.externalId ?? ''}
                internalId={section.internalId ?? ''}
                title={section.title ?? ''}
                price={section.price ?? 0}
                currency={section.currency ?? ''}
                numberOfRooms={section.numberOfRooms ?? 0}
                livingArea={section.livingArea ?? 0}
                landArea={section.landArea ?? 0}
                imageSrc={`https://erp.von-poll.com/import/images/${section.image[0].name}`}
                url={`https://www.von-poll.com/${language}/expose/immobilie/${section.propertyId}`}
                location={section.city ?? ''}
                regionalAddition={section.regionalAddition ?? ''}
                postCode={section.postalCode ?? ''}
                isListView={false}
              />
            );
          })}
        </PropertiesContainer>
      )}
      <ShowMoreButton
        onClick={onClickShowMore}
        label={t(
          'buyer-landing-page.suggested-properties.show-more-button.label'
        )}
      />
    </Column>
  );
};

export { SuggestedProperties };
