import styled from 'styled-components';

export const InfoContainer = styled.div<{ src: string }>`
  flex-basis: 50%;
  min-width: 50%;
  height: 100%;
  overflow: auto;
  background-image: url('${(props) => props.src}');
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
