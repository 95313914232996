import styled from 'styled-components';

export const IconContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
`;

export const IconBox = styled.div`
  position: absolute;
  border-radius: 100%;
  background: white;
  bottom: 4px;
  right: 0;
  border: 2px solid white;
`;

export const Title = styled.h1`
  color: #00305e;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin: 8px 0 16px 0;
`;

export const Description = styled.p`
  color: #00305e;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-bottom: 16px;
`;

export const ThesisBox = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
`;

export const Thesis = styled.p`
  color: #00305e;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;

  & b {
    font-weight: 700;
  }
`;

export const Dot = styled.div`
  min-width: 8px;
  max-width: 8px;
  min-height: 8px;
  max-height: 8px;
  margin-top: 7px;
  border-radius: 100%;
  background: #00305e;
`;

export const SeparatorBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  color: #00305e;
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 20px;
  gap: 16px;
  margin: 16px 0;
`;

export const Separator = styled.div`
  width: 80px;
  background: #00305e;
  height: 1px;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div<{ isiframe?: boolean; isMarginTop?: boolean }>`
  display: flex;
  flex-direction: column;
`;

export const MobileTitle = styled.h1`
  margin-bottom: 16px;
  color: #00305e;
  font-family: 'Source Serif Pro';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 28px;
`;

export const DescriptionBox = styled.div`
  border-radius: 3px;
  background: #f1f5ee;
  padding: 12px;
  margin-bottom: 16px;
  color: #122e01;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 16px;

  b {
    font-weight: bold;
  }
`;

export const PropertiesBox = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
