import { PropertyPortfolioFieldsFragment } from 'generated';
import { IPropertyPortfolio } from '../interfaces';
import {
  calculatePropertyPrice,
  getCoordinates,
  getEnergyLabel,
} from './property.utils';

export const generatePropertyPortfolioList = (
  list: PropertyPortfolioFieldsFragment[],
  sortOrder: 'asc' | 'desc',
  defaultTitleTranslation: string,
  allProperties: IPropertyPortfolio[] = []
): IPropertyPortfolio[] => {
  return list.map((property, index) => {
    const energyLabelData: string = property.propertyData.energyLabel
      ? getEnergyLabel(property.propertyData.energyLabel, property.countryCode)
      : property.energyCertificate?.energyEfficiencyClass || '';

    const coordinates = getCoordinates(property.propertyData?.location);

    const price = calculatePropertyPrice(property);

    const foundProperty = allProperties.find(
      ({ _id: id }) => id === property._id
    );

    const defaultTitle = foundProperty
      ? foundProperty.defaultTitle
      : `${defaultTitleTranslation} ${
          sortOrder === 'asc' ? index + 1 : list.length - index
        }`;

    return {
      ...property,
      energyLabelData,
      coordinates,
      price,
      defaultTitle,
    };
  });
};
