import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../../../common/hooks';
import {
  prevStepAction,
  toggleAuthFlow,
  toggleLoginForm,
  toggleRegisterForm,
  toggleForgotPasswordForm,
  closeFinancingModal,
  resetFinancing,
  togglePersonalFinancingForm,
} from '../../redux/financingSlice';
import { MODAL_ANIMATION_DURATION } from '../../../../common/components/modal/modal';
import { toggleIframe } from '../../../../auth/redux/authSlice';

type TProps = {
  reset?: (values: { [key: string]: unknown }) => void;
};

type TReturn = {
  onBackClick: () => void;
  onCloseClick: () => void;
};

const useNavigationButtonsHandler = ({ reset }: TProps): TReturn => {
  const dispatch = useDispatch();
  const activeStep = useAppSelector((state) => state.financing.activeStep);
  const isAuthFlow = useAppSelector((state) => state.financing.isAuthFlow);
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const isLoginFormOpen = useAppSelector(
    (state) => state.financing.isLoginFormOpen
  );
  const isPersonalFinancing = useAppSelector(
    (state) => state.financing.isPersonalFinancing
  );
  const isRegisterFormOpen = useAppSelector(
    (state) => state.financing.isRegisterFormOpen
  );
  const isForgotPassword = useAppSelector(
    (state) => state.financing.isForgotPassword
  );
  const isConfirmEmailSent = useAppSelector(
    (state) => state.financing.isConfirmEmailSent
  );

  const onCloseClick = useCallback(() => {
    dispatch(closeFinancingModal());
    dispatch(toggleIframe(null));

    if (isIframe && document.fullscreenElement && document.exitFullscreen) {
      document.exitFullscreen();
    }
    if (isConfirmEmailSent && isIframe) {
      return;
    }
    setTimeout(() => {
      dispatch(resetFinancing());
      if (reset) {
        reset({});
      }
    }, MODAL_ANIMATION_DURATION);
  }, [dispatch, isConfirmEmailSent, isIframe, reset]);

  const onBackClick = useCallback(() => {
    // let values: { [x: string]: unknown } = { reason: '' };
    // if (watch) {
    //   values = watch();
    // }
    if (activeStep === 0) {
      setTimeout(() => dispatch(resetFinancing()), MODAL_ANIMATION_DURATION);
      dispatch(closeFinancingModal());
      // } else if (
      //   activeStep === 2 &&
      //   (userInput?.reason === FinancingReason.InstallmentLoan ||
      //     values?.reason === FinancingReason.InstallmentLoan)
      // ) {
      //   dispatch(prevStepAction(0));
    } else if (isLoginFormOpen) {
      dispatch(toggleLoginForm(false));
    } else if (isRegisterFormOpen) {
      dispatch(toggleRegisterForm(false));
    } else if (isPersonalFinancing) {
      dispatch(togglePersonalFinancingForm(false));
    } else if (isForgotPassword) {
      dispatch(toggleForgotPasswordForm(false));
    } else if (isAuthFlow) {
      dispatch(toggleAuthFlow(false));
    } else {
      dispatch(prevStepAction());
    }
  }, [
    activeStep,
    dispatch,
    isAuthFlow,
    isForgotPassword,
    isLoginFormOpen,
    isPersonalFinancing,
    isRegisterFormOpen,
  ]);

  return {
    onBackClick,
    onCloseClick,
  };
};

export { useNavigationButtonsHandler };
