import { useContext, useEffect, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { device } from '../../../../style/theme';
import { Tooltip } from '../../../common/components/tooltip';
import Icon from '../../../common/components/ui/icon';
import {
  ParagraphTitle,
  TitleSlogan,
} from '../../../common/components/ui/typography';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-width: 0;
  &:not(:last-of-type) {
    margin-right: 8px;
  }
  @media ${device.tablet} {
    flex: 0 0 45%;
  }
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
`;

const Value = styled(ParagraphTitle)`
  && {
    color: ${(props) => props.theme.blue};
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const Label = styled(TitleSlogan)`
  && {
    font-size: 10px;
    opacity: 0.6;
    line-height: 1.4;
    color: ${(props) => props.theme.blue};
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const Skeleton = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  height: 6px;
  border-radius: 3px;
  background: #00305e;
`;

interface IProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  type: string;
  value: string | number;
  skeletonWidth: number;
}

const SearchProfileIcon = ({
  icon,
  type,
  value,
  skeletonWidth,
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const labelRef = useRef<HTMLParagraphElement>(null);
  const [isTooltip, setIsTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (
      labelRef.current &&
      labelRef.current.scrollWidth > labelRef.current.offsetWidth
    ) {
      setIsTooltip(true);
    }
  }, []);

  return (
    <Container>
      <Column>
        <Icon icon={icon} width={24} height={24} color={themeContext.blue} />
        {value ? (
          <Value content={`${value}`} />
        ) : (
          <Skeleton width={skeletonWidth} />
        )}
      </Column>
      <Tooltip
        isDisabled={!isTooltip}
        noMaxWidth
        textAlign={'left'}
        id={`${type}--${value}`}
        maxWidth={0}
        content={<Label ref={labelRef} content={type} />}
      >
        <Label content={type} />
      </Tooltip>
    </Container>
  );
};

export default SearchProfileIcon;
