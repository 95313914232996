import { useEffect, useState } from 'react';
import { batch } from 'react-redux';

import {
  LoginResponse,
  useForgotPasswordMutation,
  useSignInMutation,
} from '../../../generated';
import { client } from '../../../services/graphql/graphql';
import {
  login,
  logout,
  setAnonymousUser,
  setUsername,
  setUserState,
} from '../redux/authSlice';
import { togglePersonalFinancingForm } from '../../forms/form-financing/redux/financingSlice';
import { useAppDispatch, useAppSelector } from '../../common/hooks';

const useVonPollIFrameAuth = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<null | Record<string, unknown>>(null);
  const user = useAppSelector((state) => state.auth.user);

  const [signIn] = useSignInMutation();
  const [forgotPassword] = useForgotPasswordMutation();

  const sendMessage = (type: string, message = {}) => {
    window.parent.postMessage(JSON.stringify({ ...message, type }), '*');
  };

  const handleLogin = async (username: string, password: string) => {
    // imitate some sort of processing on the request
    if (username && password) {
      try {
        setLoading(true);
        const signInData = await signIn({
          input: {
            userAuthenticationKey: username,
            password,
            requestAccessToken: true,
          },
        }).unwrap();
        const loginResponse = signInData.signIn as LoginResponse;

        if (loginResponse?.jwt?.token) {
          client.setHeader(
            'Authorization',
            `Bearer ${loginResponse?.jwt?.token ?? ''}`
          );

          const { user: userResponse } = loginResponse;
          batch(() => {
            dispatch(setAnonymousUser(false));
            dispatch(login(loginResponse));
            dispatch(setUserState(userResponse.userState));
            dispatch(togglePersonalFinancingForm(true));
          });

          sendMessage('loginCompleted', {
            name: `${userResponse.name} ${userResponse.surname}`,
            email: userResponse.email,
          });
        }
      } catch (error) {
        setErrors(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleForgotPassword = async (username: string) => {
    // imitate some sort of processing on the request
    if (username) {
      try {
        dispatch(setUsername(username));
        await forgotPassword({
          input: {
            username,
          },
        }).unwrap();

        sendMessage('forgotPasswordCompleted', {
          isSuccess: true,
        });
      } catch (error) {
        setErrors(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    sendMessage('currentStatus');
  };

  const handleFetchStatus = () => {
    if (user) {
      sendMessage('currentStatus', {
        name: `${user.name} ${user.surname}`,
        email: user.email,
      });
    } else {
      sendMessage('currentStatus');
    }
  };

  const onMessage = (e: MessageEvent) => {
    try {
      if (e.origin === 'null') {
        return;
      }
      const message = JSON.parse(e.data);

      switch (message.type) {
        case 'login':
          handleLogin(message.username, message.password);
          break;
        case 'logout':
          handleLogout();
          break;
        case 'forgotPassword':
          handleForgotPassword(message.username);
          break;
        case 'fetchStatus':
          handleFetchStatus();
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);
    handleFetchStatus();

    return () => window.removeEventListener('message', onMessage);
  }, []);

  useEffect(() => {
    if (errors) {
      sendMessage('errors', {
        errors,
      });
      setErrors(null);
    }
  }, [errors]);
};

export { useVonPollIFrameAuth };
