import {
  PurchaseIntentBusiness,
  PurchaseIntentCountry,
  PurchaseIntentLegalForm,
  PurchaseIntentSalutation,
  PurchaseIntentTitle,
} from '../../../../generated';

export const salutationOptions = [
  {
    value: PurchaseIntentSalutation.Mr,
    label: 'Herr',
  },
  {
    value: PurchaseIntentSalutation.Ms,
    label: 'Frau',
  },
];

export const titleOptions = [
  {
    value: PurchaseIntentTitle.Dr,
    label: 'Dr.',
  },
  {
    value: PurchaseIntentTitle.ProfDr,
    label: 'Prof. Dr.',
  },
  {
    value: PurchaseIntentTitle.NoTitle,
    label: 'ohne Titel',
  },
];

export const legalFormOptions = [
  {
    value: PurchaseIntentLegalForm.SoleTrade,
    label: 'Einzelunternehmer',
  },
  {
    value: PurchaseIntentLegalForm.CompanyUnderCivilLaw,
    label: 'Gesellschaft bürgerlichen Rechts (GbR)',
  },
  {
    value: PurchaseIntentLegalForm.OpenTradingCompany,
    label: 'Offene Handelsgesellschaft (OHG)',
  },
  {
    value: PurchaseIntentLegalForm.LimitedPartnership,
    label: 'Kommanditgesellschaft (KG)',
  },
  {
    value: PurchaseIntentLegalForm.PublicCompany,
    label: 'Aktiengesellschaft (AG)',
  },
  {
    value: PurchaseIntentLegalForm.CompanyWithLimitedLiability,
    label: 'Gesellschaft mit beschränkter Haftung (GmbH)',
  },
  {
    value: PurchaseIntentLegalForm.EntrepreneurialCompany,
    label: 'UG (haftungsbeschränkt)',
  },
];

export const businessOptions = [
  {
    value: PurchaseIntentBusiness.Trade,
    label: 'Handel',
  },
  {
    value: PurchaseIntentBusiness.Industry,
    label: 'Industrie',
  },
  {
    value: PurchaseIntentBusiness.Media,
    label: 'Medien',
  },
  {
    value: PurchaseIntentBusiness.Tourism,
    label: 'Tourismus',
  },
  {
    value: PurchaseIntentBusiness.RealEstate,
    label: 'Immobilien',
  },
  {
    value: PurchaseIntentBusiness.Health,
    label: 'Gesundheit',
  },
  {
    value: PurchaseIntentBusiness.Service,
    label: 'Dienstleistung',
  },
  {
    value: PurchaseIntentBusiness.FinancialEconomy,
    label: 'Finanzwirtschaft',
  },
];

export const countryOptions = [
  {
    value: PurchaseIntentCountry.Germany,
    label: 'Germany',
  },
  {
    value: PurchaseIntentCountry.Austria,
    label: 'Austria',
  },
  {
    value: PurchaseIntentCountry.Switzerland,
    label: 'Switzerland',
  },
  {
    value: PurchaseIntentCountry.Spain,
    label: 'Spain',
  },
  {
    value: PurchaseIntentCountry.France,
    label: 'France',
  },
  {
    value: PurchaseIntentCountry.Greece,
    label: 'Greece',
  },
  {
    value: PurchaseIntentCountry.Hungary,
    label: 'Hungary',
  },
  {
    value: PurchaseIntentCountry.Italy,
    label: 'Italy',
  },
  {
    value: PurchaseIntentCountry.Luxembourg,
    label: 'Luxembourg',
  },
  {
    value: PurchaseIntentCountry.Netherlands,
    label: 'Netherlands',
  },
  {
    value: PurchaseIntentCountry.Portugal,
    label: 'Portugal',
  },
];

export const phoneNumberPrefixesOptions = [
  '+49', // germany
  '+43', // austria
  '+41', // switzerland
  '+34', // spain
  '+33', // france
  '+30', // greece
  '+36', // hungary
  '+39', // italy
  '+352', // luxembourg
  '+31', // netherlands
  '+351', // portugal
  '+46', // sweden
];
