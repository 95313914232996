import { useMemo } from 'react';
import { isArray, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { SerializedError } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { NewPurchaseOffer, OffersStatisticsItem } from '../interfaces';
import { useAppSelector } from '../../common/hooks';
import {
  MarketingGeneralStatistics,
  useLazyGetGeneralStatisticsQuery,
} from '../../../generated';

type TReturn = {
  filteredGeneralStatistics?: MarketingGeneralStatistics | null;
  isFilteredStatisticsEmpty: boolean;
  filteredOffers: NewPurchaseOffer[];
  isStatisticsLoading: boolean;
  isStatisticsFetching: boolean;
  statisticsError?: SerializedError;
  hasHighestBid: boolean;
};

export const useStatisticsFilter = (
  statistics?: OffersStatisticsItem[],
  searchInput?: string
): TReturn => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const { t } = useTranslation();

  const [
    fetchStatistics,
    {
      filteredGeneralStatistics,
      isStatisticsLoading,
      isStatisticsFetching,
      statisticsError,
    },
  ] = useLazyGetGeneralStatisticsQuery({
    selectFromResult: ({ data, isLoading, isFetching, error }) => {
      return {
        filteredGeneralStatistics: data?.getGeneralStatistics,
        isStatisticsLoading: isLoading,
        isStatisticsFetching: isFetching,
        statisticsError: error,
      };
    },
  });

  const filters = useAppSelector(
    (state) => state.sellerProperties.marketingStatisticsFilter
  );

  const filteredOffers = useMemo(() => {
    let searchedStatistics = statistics;
    if (searchInput) {
      const searchKeywords = searchInput
        ?.split(' ')
        .map((keyword) => keyword.toLowerCase());

      searchedStatistics = statistics?.flatMap((item) => {
        const { offers } = item;

        const suitableOffers = offers?.flatMap((offer) => {
          const hasCommonKeywords = searchKeywords.every((keyword) => {
            if (
              offer &&
              !isEmpty(offer?.keywords) &&
              isArray(offer?.keywords)
            ) {
              if (
                searchKeywords[0] ===
                  t('selling-simulation.offers-statistics-card.customer') &&
                searchKeywords[1]
              ) {
                return offer.keywords.includes(
                  `${t('selling-simulation.offers-statistics-card.customer')} ${
                    searchKeywords[1]
                  }`
                );
              }
              return new RegExp(`${keyword}`).test(offer.keywords.join(' '));
              // return offer.keywords.some((key) => key.startsWith(keyword));
            }
            return false;
          });

          if (hasCommonKeywords) {
            return offer;
          }
          return [];
        });

        if (!isEmpty(suitableOffers)) {
          return { ...item, offers: suitableOffers } ?? [];
        }

        return [];
      });
    }

    let result = searchedStatistics;
    const { dateFrom, dateTo } = filters;

    const firstDate =
      dateFrom || statistics?.[statistics?.length - 1]?.dateFrom;
    const lastDate = dateTo || new Date().toISOString();

    if (firstDate && lastDate) {
      result = result?.map((item: OffersStatisticsItem) => {
        const { offers } = item;
        if (offers?.length) {
          return {
            ...item,
            offers: offers.filter(
              (offer) =>
                new Date(offer.date) >= new Date(firstDate) &&
                new Date(offer.date) <= new Date(lastDate)
            ),
          };
        }
        return item;
      });

      fetchStatistics({
        propertyId,
        dateFrom: firstDate,
        dateTo: lastDate,
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    result = result?.reduce(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (prevValue, currentValue) => {
        return [...prevValue, ...(currentValue?.offers ?? [])]?.sort(
          (offerA, offerB) => {
            if (offerA.price > offerB.price) {
              return -1;
            }
            if (offerA.price < offerB.price) {
              return 1;
            }
            return 0;
          }
        );
      },
      []
    );

    return result as unknown as NewPurchaseOffer[];
  }, [fetchStatistics, filters, propertyId, searchInput, statistics, t]);

  const isFilteredStatisticsEmpty = useMemo(
    () =>
      ((filteredGeneralStatistics?.viewingAppointments === 0 &&
        filteredGeneralStatistics?.requestedExposes === 0 &&
        filteredGeneralStatistics?.unlockedExposes === 0) ||
        isEmpty(filteredGeneralStatistics)) &&
      isEmpty(filteredOffers) &&
      !isStatisticsLoading &&
      !isStatisticsFetching,
    [
      filteredGeneralStatistics,
      filteredOffers,
      isStatisticsFetching,
      isStatisticsLoading,
    ]
  );

  const hasHighestBid = useMemo(
    () => !!filteredOffers?.some((offer) => !!offer.isHighestBid),
    [filteredOffers]
  );

  return {
    filteredGeneralStatistics,
    isFilteredStatisticsEmpty,
    filteredOffers,
    isStatisticsLoading,
    isStatisticsFetching,
    statisticsError,
    hasHighestBid,
  };
};
