import { batch, useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { useAppSelector } from '../../common/hooks';
import {
  Direction,
  OrderSuggestedPropertiesBy as OrderByEnum,
} from '../../../generated';
import {
  clearSuggestedPropertiesCache,
  mergeFilters,
  setInitialLoader,
} from '../redux/suggestedPropertiesSlice';

type IProps = { field: OrderByEnum; direction: Direction };

type IReturn = {
  switchOrder: (props: IProps) => void;
};

const useOrderBy = (): IReturn => {
  const dispatch = useDispatch();

  const searchProfileLocation = useAppSelector((state) => state.map.markers[0]);
  const suggestedProperties = useAppSelector(
    (state) => state.suggestedProperties.suggestedProperties
  );

  const switchOrder = useCallback(
    ({ field, direction }: IProps) => {
      const currentLoadedCount = suggestedProperties.length;
      dispatch(setInitialLoader(true));
      batch(() => {
        dispatch(clearSuggestedPropertiesCache());
        dispatch(
          mergeFilters({
            limit: currentLoadedCount,
            offset: 0,
            orderBy: {
              field,
              direction: direction.toUpperCase(),
              proximityParams: {
                latitude: searchProfileLocation?.latitude,
                longitude: searchProfileLocation?.longitude,
              },
            },
          })
        );
      });
    },
    [
      dispatch,
      searchProfileLocation?.latitude,
      searchProfileLocation?.longitude,
      suggestedProperties.length,
    ]
  );

  return { switchOrder };
};

export { useOrderBy };
