import { memo, useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { siteMap } from 'routes/site-map';

import { ReactComponent as RealEstateDimensionsBlock } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as RealEstateDimensionsPlan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-plan.svg';
import { ReactComponent as LocationMarker } from 'assets/streamline-light/maps-navigation/pins/pin.svg';
import { ReactComponent as ColorRollingBrush } from 'assets/streamline-light/design/colors-painting/color-rolling-brush.svg';
import { ReactComponent as LiftTwoPeople } from 'assets/streamline-light/wayfinding/lifts/lift-two-people.svg';
import { ReactComponent as Bathroom } from 'assets/streamline-light/hotels-spas/bathroom/bathroom-tub-towel.svg';
import { ReactComponent as ResizeExpandSides } from 'assets/streamline-light/design/resize/resize-expand-sides.svg';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';

import {
  ButtonsWrapper,
  CardContainer,
  CardContent,
  CardImage,
  CardTitle,
  CommissionContainer,
  CommissionLabel,
  CommissionValue,
  DownloadPdfButton,
  FinancingButton,
  ImageWrapper,
  Location,
  LocationIcon,
  MapWrapper,
  LockedRequestMessage,
  Price,
  PriceContainer,
  PropertiesContainerWrapper,
  PropertyDetailsContainer,
  Row,
  ShowExposeButton,
  SquareMeterPrice,
  Tag,
} from './online-expose-card-styles';
import { ReactComponent as BuildingYear } from '../../../../assets/streamline-light/building-construction/utilities/building-year.svg';
import {
  Coordinates,
  ExposeStatus,
  FinancingType,
  PropertyCode,
  useGeneratePdfExposeLinkMutation,
} from '../../../../generated';
import { ReactComponent as HotelDoubleBed2 } from '../../../../assets/streamline-light/hotels-spas/hotel-bedrooms/hotel-double-bed-2.svg';
import { ReactComponent as HouseGarage } from '../../../../assets/streamline-light/building-construction/houses/house-garage.svg';
import { ReactComponent as RoadSignParking } from '../../../../assets/streamline-light/transportation/signs/road-sign-parking.svg';
import { extractPropertyTypeLabelAndIcon } from '../../../property/utils/property-type-normalizer';
import { Mapbox } from './map';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { OnlineExposeIcon } from './online-expose-icon';
import { useOpenFinancing } from '../../../forms/form-financing/hooks/callback-hooks/useOpenFinancing';
import { setSuggestionProperty } from '../../../forms/form-financing/redux/financingSlice';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import { setMarketingParameters } from '../../../auth/redux/authSlice';
import { Tooltip } from '../../../common/components/tooltip';
import { useGetCountry } from '../../../localization/get-country';
import { ParkingSpaceLabelByKey } from '../../../property/constants';

interface IProps {
  id: string | number;
  exposeId: string;
  propertyId: number;
  title: string;
  price: number;
  numberOfRooms: number;
  livingArea: number;
  landArea?: number;
  currency: string;
  imageSrc: string;
  city: string;
  address?: string;
  regionalAddition?: string;
  postCode?: string | number;
  isListView?: boolean;
  code?: PropertyCode | null;
  status?: ExposeStatus;
  coordinates?: Coordinates;
  type?: string;
  buildingYear?: string;
  modernizationYear?: string;
  floor?: number;
  numberOfFloors?: number;
  numberOfBedrooms?: number;
  numberOfBathrooms?: number;
  numberOfGarages?: number;
  numberOfDuplexParkingSpaces?: number;
  numberOfUndergroundGarages?: number;
  numberOfOtherParkingSpaces?: number;
  numberOfParkingSpace?: number;
  provision?: string;
  numberOfParkingSpaces?: number;
  onClickOpenOverlay: () => void;
  isPlot?: boolean;
  pdf?: string;
  financingId?: string;
}

const OnlineExpose = ({
  id,
  exposeId,
  propertyId,
  title,
  price,
  numberOfRooms,
  livingArea,
  landArea,
  currency,
  imageSrc,
  city,
  address,
  regionalAddition,
  isListView = false,
  postCode = '',
  code,
  status = ExposeStatus.Pending,
  coordinates,
  type,
  buildingYear,
  modernizationYear,
  floor,
  numberOfFloors,
  numberOfBedrooms,
  numberOfBathrooms,
  numberOfGarages,
  provision,
  numberOfParkingSpaces,
  numberOfDuplexParkingSpaces,
  numberOfUndergroundGarages,
  numberOfOtherParkingSpaces,
  numberOfParkingSpace,
  onClickOpenOverlay,
  isPlot,
  pdf,
  financingId,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const lngHPush = useLngHistoryPush();
  const { language } = useGetCountry();

  const { openFinancing } = useOpenFinancing({});
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const pricePerSqm = Number.parseFloat(
    (price / (isPlot ? landArea ?? 0 : livingArea)).toFixed(2).toString()
  );
  const isMobileSize = useIsMobileSize();

  const isShrinked = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );

  const { icon, label } = extractPropertyTypeLabelAndIcon(code);

  const [generatePdfExpose] = useGeneratePdfExposeLinkMutation();

  const navigate = useHistory();

  const onClickDownloadPdf = useCallback(async () => {
    if (pdf) {
      const { generatePdfExposeLink: pdfExpose } = await generatePdfExpose({
        id: exposeId,
        fileKey: pdf,
      }).unwrap();
      if (pdfExpose) {
        window.open(pdfExpose, '_blank');
      }
    }
  }, [generatePdfExpose, pdf, exposeId]);

  // get provision value
  let provisionValue = '';
  provision?.split(' ')?.forEach((item, i, arr) => {
    if (arr[i + 1]?.includes('%')) {
      provisionValue = arr[i] + arr[i + 1];
    }
  });

  // suggested properties purchase price logic
  const onClickFinancing = () => {
    if (financingId) {
      lngHPush(
        `${siteMap.FinancingPortalPage.pathWithoutParams}/${financingId}`
      );
      return;
    }
    openFinancing(FinancingType.Financing);
    dispatch(setSuggestionProperty({ purchasePrice: price, propertyId }));
    const utmParams = `utm_source=kundenportal&utm_medium=suchprofil&utm_campaign=online-expose-freigabe&property_id=${propertyId}`;
    const href = window?.location?.href;
    const markParams = href.includes('?')
      ? `${href}&${utmParams}`
      : `${href}?${utmParams}`;
    dispatch(setMarketingParameters(markParams));
  };

  const regionalAdditionString = regionalAddition
    ? `– ${regionalAddition}`
    : '';

  const addressText = String(address).trim() ? `${address}, ` : '';

  const locationLabel = useMemo(() => {
    return `${
      address && status === ExposeStatus.Unlocked ? `${addressText}` : ''
    }${postCode} ${city} ${regionalAdditionString}`;
  }, [address, postCode, city, regionalAdditionString, status]);

  const parkingSpacesWithLabel = useMemo(() => {
    const parkingSpacesObj = {
      duplex: numberOfDuplexParkingSpaces,
      garage: numberOfGarages,
      multiStoryGarage: numberOfParkingSpace,
      undergroundGarage: numberOfUndergroundGarages,
      otherParkingLot: numberOfOtherParkingSpaces,
    };

    return Object.keys(parkingSpacesObj)
      ?.filter(
        (key) => parkingSpacesObj?.[key as keyof typeof parkingSpacesObj] !== 0
      )
      ?.map((key) => ({
        label: ParkingSpaceLabelByKey.get(key),
        value: parkingSpacesObj?.[key as keyof typeof parkingSpacesObj],
      }));
  }, [
    numberOfDuplexParkingSpaces,
    numberOfGarages,
    numberOfParkingSpace,
    numberOfUndergroundGarages,
    numberOfOtherParkingSpaces,
  ]);

  const onClickTag = () => {
    window.open(
      `https://www.von-poll.com/${language}/expose/immobilie/${propertyId}`,
      '_blank'
    );
  };

  return (
    <CardContainer isListView={isListView} isShrinked={isShrinked}>
      {(isListView || isMobileSize) && (
        <>
          {status === ExposeStatus.Unlocked && coordinates ? (
            <MapWrapper isMobileSize={isMobileSize}>
              <Mapbox isVisible coordinates={coordinates} />
            </MapWrapper>
          ) : (
            <ImageWrapper isListView>
              <CardImage src={imageSrc} isListView />
            </ImageWrapper>
          )}
        </>
      )}
      <CardContent isListView={isListView}>
        <Row>
          <CardTitle>{title}</CardTitle>
        </Row>
        <Row justifyContent={'space-between'}>
          <Row>
            <LocationIcon icon={LocationMarker} color={themeContext.blue} />
            <Tooltip
              maxWidth={280}
              id={locationLabel}
              position="top"
              textAlign="left"
              content={
                <Location isShrinked={isShrinked}>{locationLabel}</Location>
              }
            >
              <Location isShrinked={isShrinked} maxWidth={400}>
                {locationLabel}
              </Location>
            </Tooltip>
          </Row>
          <Tag onClick={onClickTag}>{`# ${id}`}</Tag>
        </Row>

        <Row isWrap alignItems={'center'}>
          <PriceContainer>
            <Price>
              {price === 0
                ? t('property.offers.card.price-on-request.label')
                : `${price.toLocaleString('de-DE')} ${currency}`}
            </Price>
            {price !== 0 && (
              <SquareMeterPrice>
                ({pricePerSqm.toLocaleString('de-DE')} {currency}/m<sup>2</sup>)
              </SquareMeterPrice>
            )}
          </PriceContainer>

          {price !== 0 && (
            <CommissionContainer>
              <CommissionLabel>
                {t('expose-card.provision-tag')}
              </CommissionLabel>
              <CommissionValue>{provisionValue}</CommissionValue>
            </CommissionContainer>
          )}
        </Row>
        <PropertiesContainerWrapper>
          <PropertyDetailsContainer isShrinked={isShrinked}>
            <OnlineExposeIcon
              icon={icon}
              label={t('property-overview.type')}
              value={String(t(label))}
            />
            {!isPlot && (
              <>
                <OnlineExposeIcon
                  icon={BuildingYear}
                  label={t('property-overview.building-year')}
                  value={buildingYear ?? 0}
                />
                {modernizationYear && (
                  <OnlineExposeIcon
                    icon={ColorRollingBrush}
                    label={t('property-overview.modernization-year')}
                    value={modernizationYear}
                  />
                )}
              </>
            )}
            <OnlineExposeIcon
              icon={isPlot ? ResizeExpandSides : RealEstateDimensionsBlock}
              label={t(
                isPlot
                  ? 'property-overview.land-area'
                  : 'property-overview.living-area'
              )}
              value={`${t('approx')} ${isPlot ? landArea : livingArea ?? 0} m²`}
            />
            {!isPlot && (
              <>
                <OnlineExposeIcon
                  icon={LiftTwoPeople}
                  label={t(
                    code === PropertyCode.Apartment
                      ? 'valuation.form.second-step.floor-number-label'
                      : 'property-valuation.form.redesign.number-of-floors-in-building.label'
                  )}
                  value={
                    code === PropertyCode.Apartment
                      ? floor ?? 0
                      : numberOfFloors ?? 0
                  }
                />
                <OnlineExposeIcon
                  icon={RealEstateDimensionsPlan}
                  label={t('property-overview.number-of-rooms')}
                  value={numberOfRooms ?? 0}
                />
                <OnlineExposeIcon
                  icon={HotelDoubleBed2}
                  label={t('property-overview.number-of-bedrooms')}
                  value={numberOfBedrooms ?? 0}
                />
                <OnlineExposeIcon
                  icon={Bathroom}
                  label={t('property-overview.number-of-bathrooms')}
                  value={numberOfBathrooms ?? 0}
                />
                {parkingSpacesWithLabel?.map((parkingSpaceWithLabel) => (
                  <OnlineExposeIcon
                    icon={HouseGarage}
                    value={parkingSpaceWithLabel.value ?? 0}
                    label={t(parkingSpaceWithLabel.label || '')}
                  />
                ))}
                <OnlineExposeIcon
                  icon={RoadSignParking}
                  label={t(
                    'property-overview.number-of-outdoor-parking-spaces'
                  )}
                  value={numberOfParkingSpaces ?? 0}
                />
              </>
            )}
          </PropertyDetailsContainer>
        </PropertiesContainerWrapper>
        {status !== ExposeStatus.Unlocked ? (
          <LockedRequestMessage>
            {t(
              status === ExposeStatus.Pending
                ? 'search-profile.suggested-properties.pending-request-expose'
                : 'search-profile.suggested-properties.sold-property-expose'
            )}
          </LockedRequestMessage>
        ) : (
          <>
            <ButtonsWrapper isColumn={!isListView}>
              <ShowExposeButton
                onClick={onClickOpenOverlay}
                fluid
                isFullSize={!pdf || isMobileSize}
                label={t('search-profile.online-exposes.button.show-expose')}
              />
              {pdf && (
                <DownloadPdfButton
                  label={t('search-profile.online-exposes.button.download-pdf')}
                  onClick={onClickDownloadPdf}
                  color={themeContext.blue}
                  borderColor={themeContext.blue}
                  isShrinked={isShrinked}
                  isMobileSize={isMobileSize}
                />
              )}
            </ButtonsWrapper>

            <FinancingButton
              label={t(
                financingId
                  ? 'search-profile.online-exposes.button.open-financing'
                  : 'search-profile.online-exposes.button.financing'
              )}
              onClick={onClickFinancing}
              color={themeContext.ctaBlue}
              borderColor={themeContext.ctaBlue}
              isShrinked={isShrinked}
              isMobileSize={isMobileSize}
            />
          </>
        )}
      </CardContent>
    </CardContainer>
  );
};

const OnlineExposeCard = memo(OnlineExpose);

export { OnlineExposeCard };
