import 'core-js/features/array/flat-map';
import {
  Direction,
  OrderSuggestedPropertiesBy,
  OrderSuggestedPropertiesByCriterion,
} from '../../../../../generated';

export const buildOrderBy = () => {
  const options = (
    Object.keys(OrderSuggestedPropertiesBy) as Array<
      keyof typeof OrderSuggestedPropertiesBy
    >
  ).flatMap((field) => {
    switch (OrderSuggestedPropertiesBy[field]) {
      case 'proximity':
        return [
          {
            field: OrderSuggestedPropertiesBy[field],
            label: `${field.toLowerCase()} - ${Direction.Asc.toLowerCase()}`,
            direction: Direction.Asc,
          },
        ];
      case 'date':
        return [
          {
            field: OrderSuggestedPropertiesBy[field],
            label: `${field.toLowerCase()} - ${Direction.Asc.toLowerCase()}`,
            direction: Direction.Asc,
          },
          {
            field: OrderSuggestedPropertiesBy[field],
            label: `${field.toLowerCase()} - ${Direction.Desc.toLowerCase()}`,
            direction: Direction.Desc,
          },
        ];
      default:
        return [
          {
            field: OrderSuggestedPropertiesBy[field],
            label: `${field.toLowerCase()} - ${Direction.Asc.toLowerCase()}`,
            direction: Direction.Asc,
          },
          {
            field: OrderSuggestedPropertiesBy[field],
            label: `${field.toLowerCase()} - ${Direction.Desc.toLowerCase()}`,
            direction: Direction.Desc,
          },
        ];
    }
  });

  return options;
};

export const parseOptionValue = (
  orderByCriterion?: OrderSuggestedPropertiesByCriterion
) => {
  const { field, direction } = orderByCriterion ?? {
    field: OrderSuggestedPropertiesBy.Proximity,
    direction: Direction.Asc,
  };
  return {
    field,
    label: `${field?.toLowerCase()} - ${direction?.toLowerCase()}`,
    direction,
  };
};
