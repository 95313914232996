import { ReactComponent as ArrowButtonLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as Attachment } from 'assets/streamline-light/interface-essential/link-unlink/attachment.svg';
import { ReactComponent as Lock } from 'assets/streamline-light/interface-essential/lock-unlock/lock-2.svg';
import { ReactComponent as Document } from 'assets/streamline-light/files-folders/common-files/common-file-text.svg';
import { ReactComponent as MarketPriceCalculator } from 'assets/real-estate-market-calculator-house.svg';
import { ReactComponent as Puzzle } from 'assets/streamline-light/programing-apps-websites/plugins-modules/module-hand-puzzle.svg';
import { ReactComponent as House } from 'assets/streamline-light/real-estate/real-estate/real-estate-house.svg';
import { ReactComponent as Money } from 'assets/streamline-light/users/natural-close-up-single-user-actions-neutral/single-neutral-actions-money.svg';
import { ReactComponent as Settings } from 'assets/streamline-light/real-estate/settings/real-estate-settings-building-1.svg';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { FlyToInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';
import { ValuationLoader } from 'modules/forms/components/common/loader';
import { formatPrice } from 'modules/seller/utils/formatPrice';
import {
  // PropertyStatus,
  usePropertyQuery,
  ValuationType,
  usePropertyOnOfficeViewQuery,
  useDigitalServiceStatusQuery,
  VonPollPropertyImage,
  Currency,
  PropertyStatus,
  UserStatus,
  // useCheckBrokerSettingsPermissionQuery,
} from '../../../../generated';
import { Card } from '../../../common/components/ui/card';
import { Hero } from '../../../common/components/ui/hero';
import { HeroSlider } from '../../../common/components/ui/hero-slider/hero-slider';
import Icon from '../../../common/components/ui/icon';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { useToLocalWithCurrency } from '../../../common/utils/convertCHF';
import { IframeMap } from '../../../map/components/iframe-map';
import { Mapbox } from '../../../map/components/mapbox';
import { Europe } from '../../../map/constants/points-of-interest';
import {
  changeViewport,
  restoreCheckpoints,
  setMarkers,
  setSettings,
} from '../../../map/redux/mapSlice';
import { OnlineValuationContainer } from '../../../forms/form-property-valuation-wizard/components/containers/online-valuation/online-valuation-container';
import { PersonalValuationContainer } from '../../../forms/form-property-valuation-wizard/components/containers/personal-valuation/personal-valuation-container';
import { EditValuationModal } from '../../../forms/form-property-valuation-wizard/components/modals/edit-valuation-modal';
import {
  closeValuationWizardAction,
  resetValuationWizard,
} from '../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { PropertyValuationOverviewCard } from '../../../property/components/card/property-valuation-overview-card';
import { Drawer } from '../../../property/components/drawer/drawer';
import { HeaderTab } from '../../../property/components/header/valuation-header-tab';
import { setSelectedTab } from '../../../property/redux/dynamicMapUtilsSlice';
import { implodeAddress } from '../../../property/utils/address';
import { LngRedirect } from '../../../localization/lng-redirect';
import Appointments from '../../components/tabs/appointments';
import {
  ContentWithPadding,
  HeaderWrapper,
  SubHeaderWrapper,
  IconWrapper,
  IFrameContainer,
  MobileHeadContainer,
  MobileMapContainer,
} from './selling-process-page-styles';
import { CTAButtons } from '../../../forms/form-property-valuation-wizard/components/containers/property-valuation-widget-container/cta-buttons/cta-buttons';
import { PropertyValuationWidgetContainer } from '../../../forms/form-property-valuation-wizard/components/containers/property-valuation-widget-container/property-valuation-widget-container';
import { siteMap } from '../../../../routes/site-map';
import { SellerPropertiesTab } from '../../../common/components/layout/header/seller-properties-tab';
import { ScrollDetector } from '../../../common/components/scroll-detector';
import { MarketPrice } from '../../components/tabs/market-price';
import { Overview } from '../../components/tabs/overview';
import { Expose } from '../../components/tabs/expose';
import { Services } from '../../components/tabs/services';
import { PageType } from '../../../forms/form-property-valuation-wizard/interface';
// import { buildSellingProgress } from '../../utils/buildSellingProgress';
import { Marketing } from '../../components/tabs/marketing';
// import { MarketingStatus } from '../../constants';
import { Preparation } from '../../components/tabs/preparation';
import { SellingSimulation } from '../../components/tabs/selling-simulation';
import { VariationHeaderActions } from '../../components/header/variation-header-actions';
import { defaultMapSettings } from '../../../map/constants';
import { ContactFormModal } from '../../components/contact-form-modal/contact-form-modal';
import { LockedTabTooltip } from './tooltip/tooltip';
import { BrokerSettings } from '../../components/tabs/broker-settings';
import { Activities } from '../../components/tabs/activities';
import { SellerPropertyGalleryContainer } from '../../components/seller-property-gallery-container/seller-property-gallery-container';
import {
  energyLabelOptions,
  energyLabelOptionsSwitzerland,
} from '../../../forms/form-property-valuation-wizard-v2/constants';
import { setIsReadOnlyAccess } from '../../redux/sellerPropertiesSlice';

const SellingProcessPageBase = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const tabListRef = useRef<HTMLDivElement>(null);
  const isMobileSize = useIsMobileSize();
  const { propertyId } = useParams<{ propertyId: string }>();
  const { t } = useTranslation();

  // onOffice marketplace iframe URL params
  const urlParams = new URLSearchParams(window.location.search);
  const brokerId = urlParams.get('userid') || '';
  const propertyOnOfficeId = urlParams.get('EstateId') || '';
  const openTab = urlParams.get('openTab') || '';

  const [isLockedTooltipVisible, setIsLockedTooltipVisible] =
    useState<boolean>(false);
  const [tabHoveredIndex, setTabHoveredIndex] = useState<number>(0);

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const selectedTab = useAppSelector(
    (state) => state.dynamicMapUtils.selectedTab
  );
  const isTabListNearTop = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );

  const isValuationWizardOpen = useAppSelector(
    (state) => state.valuationWizard.isValuationWizardOpen
  );
  const selectedAdddress = useAppSelector(
    (state) => state.valuationWizard.selectedAddress?.place_name
  );
  const activeStep = useAppSelector(
    (state) => state.valuationWizard.activeStep
  );
  const valuationType = useAppSelector(
    (state) => state.valuationWizard.valuationType
  );
  const floatingBar = useAppSelector(
    (state) => state.valuationWizard.floatingBar
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const onGoBack = useCallback(
    () =>
      push(
        `${siteMap.SellerPropertiesPage.path}${
          brokerId && propertyOnOfficeId
            ? `?userid=${brokerId}&EstateId=${propertyOnOfficeId}`
            : ''
        }`
      ),
    [brokerId, propertyOnOfficeId, push]
  );

  const { isDigitalServiceLocked } = useDigitalServiceStatusQuery(
    { propertyId },
    {
      selectFromResult: ({ data }) => {
        return {
          isDigitalServiceLocked: data?.digitalServiceStatus,
        };
      },
      pollingInterval: 10_000,
    }
  );

  const propertyData = usePropertyQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, isLoading, error, isSuccess }) => {
        const {
          buildingYear,
          balconyArea,
          floorNumber,
          numberOfFloorsInBuilding,
          renovationYear,
          propertyType,
          livingArea,
          landArea,
          numberOfRooms,
          numberOfBathrooms,
          numberOfIndoorParkingSpaces,
          numberOfOutdoorParkingSpaces,
          numberOfUnits,
          annualRentIncome,
          location,
          energyLabel,
          parkingSpaces,
        } = data?.property?.propertyData || {};

        const {
          dossierUrl,
          userStatus: userStatusRaw,
          poi,
          valuations,
          valuationsLastSync,
          isDigitalServiceActivated: digitalServiceActivated,
          isActivitiesTabUnlocked: activitiesTabUnlocked,
          isContractSigned: contractSigned,
          isMarketingDashboardUnlocked: dashboardUnlocked,
          status: sellingStatus,
          isRealSellingProcessStarted: realMarketingStarted,
          isHiddenSendEmailToBrokerAlert: hiddenSendEmailToBrokerAlert,
          relatedShop: propertyShop,
          disabledServices: disabledPropertyServices,
          servicesDisabledAt,
          photos,
          countryCode,
          priceCurrency,
          onOfficePrice,
          isReadOnlyAccess,
          description,
          isUnderConstruction,
        } = data?.property || {};
        const { address, coordinates } = location || {};

        const currentValuationIndex =
          valuations?.findIndex(
            (valuation) =>
              new Date(valuation.date).toDateString() ===
              new Date(valuationsLastSync).toDateString()
          ) ?? -1;

        const imagesWithMetaData: VonPollPropertyImage[] =
          photos?.map((photo, index) => ({
            alt: `Seller property photo ${index}`,
            group: 'BILD',
            height: 750,
            id: index,
            link: photo,
            width: 1000,
          })) ?? [];

        const isSwitzerland = countryCode === 'CH';
        const labelOfEnergyLabelField = isSwitzerland
          ? energyLabelOptionsSwitzerland.find(
              (item) => item.value === energyLabel
            )?.label
          : energyLabelOptions.find((item) => item.value === energyLabel)
              ?.label;

        return {
          overviewProps: {
            buildingYear,
            code: propertyType?.code,
            livingArea,
            landArea,
            numberOfRooms,
            numberOfBathrooms,
            numberOfIndoorParkingSpaces,
            numberOfOutdoorParkingSpaces,
            balconyArea,
            floorNumber,
            numberOfFloorsInBuilding,
            renovationYear,
            annualRentIncome,
            numberOfUnits,
            energyLabel: labelOfEnergyLabelField,
            status: sellingStatus,
            parkingSpaces,
            isUnderConstruction,
          },
          error,
          isLoading,
          isSuccess,
          dossierUrl,
          address: implodeAddress(address),
          coordinates,
          userStatus: userStatusRaw ?? null,
          poi,
          currentValuation:
            valuations?.[currentValuationIndex]?.valuation?.value,
          priceCurrency:
            priceCurrency === Currency.Chf ? Currency.Chf : Currency.Eur,
          onOfficePrice,
          isDigitalServiceActivated: digitalServiceActivated,
          isActivitiesTabUnlocked: activitiesTabUnlocked,
          isContractSigned: Boolean(contractSigned),
          isMarketingDashboardUnlocked: !!dashboardUnlocked,
          isRealSellingProcessStarted: !!realMarketingStarted,
          isHiddenSendEmailToBrokerAlert: !!hiddenSendEmailToBrokerAlert,
          status: sellingStatus,
          relatedShop: propertyShop,
          disabledServices: servicesDisabledAt
            ? disabledPropertyServices ?? []
            : null,
          photos: imagesWithMetaData,
          isReadOnlyAccess,
          description,
        };
      },
      skip: isBrokerView,
    }
  );

  const brokerPropertyData = usePropertyOnOfficeViewQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, isLoading, error, isSuccess }) => {
        const {
          buildingYear,
          balconyArea,
          floorNumber,
          numberOfFloorsInBuilding,
          renovationYear,
          propertyType,
          livingArea,
          landArea,
          numberOfRooms,
          numberOfBathrooms,
          numberOfIndoorParkingSpaces,
          numberOfOutdoorParkingSpaces,
          numberOfUnits,
          annualRentIncome,
          location,
          energyLabel,
          parkingSpaces,
        } = data?.propertyOnOfficeView?.propertyData || {};

        const {
          dossierUrl,
          userStatus: userStatusRaw,
          poi,
          valuations,
          valuationsLastSync,
          isDigitalServiceActivated: digitalServiceActivated,
          isActivitiesTabUnlocked: activitiesTabUnlocked,
          isContractSigned: contractSigned,
          isMarketingDashboardUnlocked: dashboardUnlocked,
          status: sellingStatus,
          isRealSellingProcessStarted: realMarketingStarted,
          relatedShop: propertyShop,
          actualValuations,
          disabledServices: disabledPropertyServices,
          servicesDisabledAt,
          photos,
          energyCertificate,
          countryCode,
          priceCurrency,
          onOfficePrice,
          isHiddenSendEmailToBrokerAlert: hiddenSendEmailToBrokerAlert,
          isReadOnlyAccess,
          description,
          isUnderConstruction,
        } = data?.propertyOnOfficeView || {};
        const { address, coordinates } = location || {};
        const currentValuationIndex =
          actualValuations?.findIndex(
            (valuation) =>
              new Date(valuation.date).toDateString() ===
              new Date(valuationsLastSync).toDateString()
          ) ?? -1;

        const imagesWithMetaData: VonPollPropertyImage[] =
          photos?.map((photo, index) => ({
            alt: `Seller property photo ${index}`,
            group: 'BILD',
            height: 750,
            id: index,
            link: photo,
            width: 1000,
          })) ?? [];

        const isSwitzerland = countryCode === 'CH';
        const labelOfEnergyLabelField = isSwitzerland
          ? energyLabelOptionsSwitzerland.find(
              (item) => item.value === energyLabel
            )?.label
          : energyLabelOptions.find((item) => item.value === energyLabel)
              ?.label;

        const annualRentIncomeLabel = Number.isFinite(annualRentIncome)
          ? formatPrice(annualRentIncome!, priceCurrency || 'EUR')
          : '-';

        return {
          overviewProps: {
            buildingYear,
            code: propertyType?.code,
            livingArea,
            landArea,
            numberOfRooms,
            numberOfBathrooms,
            numberOfIndoorParkingSpaces,
            numberOfOutdoorParkingSpaces,
            balconyArea,
            floorNumber,
            numberOfFloorsInBuilding,
            renovationYear,
            annualRentIncome,
            annualRentIncomeLabel,
            numberOfUnits,
            energyLabel: energyLabel
              ? labelOfEnergyLabelField
              : energyCertificate?.energyEfficiencyClass || null,
            status: sellingStatus,
            parkingSpaces,
            isUnderConstruction,
          },
          error,
          isLoading,
          isSuccess,
          dossierUrl,
          address: implodeAddress(address),
          coordinates,
          userStatus: userStatusRaw ?? null,
          poi,
          onOfficePrice,
          currentValuation:
            actualValuations?.[currentValuationIndex]?.valuation?.value ??
            valuations?.[currentValuationIndex]?.valuation?.value,
          priceCurrency:
            priceCurrency === Currency.Chf ? Currency.Chf : Currency.Eur,
          isDigitalServiceActivated: digitalServiceActivated,
          isActivitiesTabUnlocked: activitiesTabUnlocked,
          isContractSigned: Boolean(contractSigned),
          isMarketingDashboardUnlocked: !!dashboardUnlocked,
          isHiddenSendEmailToBrokerAlert: !!hiddenSendEmailToBrokerAlert,
          isRealSellingProcessStarted: !!realMarketingStarted,
          status: sellingStatus,
          relatedShop: propertyShop,
          disabledServices: servicesDisabledAt
            ? disabledPropertyServices ?? []
            : null,
          photos: imagesWithMetaData,
          isReadOnlyAccess,
          description,
        };
      },
      skip: !isBrokerView,
    }
  );

  const currentPropertyData = useMemo(() => {
    return isBrokerView ? brokerPropertyData : propertyData;
  }, [brokerPropertyData, isBrokerView, propertyData]);

  const currentValuationLocal = useToLocalWithCurrency(
    currentPropertyData.currentValuation ?? 0,
    currentPropertyData.priceCurrency
  );
  const onOfficePriceLocal = useToLocalWithCurrency(
    currentPropertyData.onOfficePrice ?? 0,
    currentPropertyData.priceCurrency
  );

  const priceLabel = useMemo(() => {
    if (
      currentPropertyData.status === PropertyStatus.Initial ||
      currentPropertyData.status === PropertyStatus.SellingSimulation
    ) {
      return currentValuationLocal as string;
    }
    if (currentPropertyData.status === PropertyStatus.Selling) {
      return currentPropertyData.onOfficePrice
        ? (onOfficePriceLocal as string)
        : t(
            'seller-properties-list.evaluation-price.in-consultation-with-broker'
          );
    }
    return '';
  }, [t, currentPropertyData, currentValuationLocal, onOfficePriceLocal]);

  const closeEvaluationWizard = () => {
    // Heat map
    if (selectedTab === 0) {
      dispatch(
        changeViewport({
          ...Europe,
          transitionDuration: 1000,
        })
      );
      setTimeout(() => {
        batch(() => {
          dispatch(closeValuationWizardAction());
          dispatch(resetValuationWizard());
        });
      }, 500);
    } else {
      batch(() => {
        dispatch(closeValuationWizardAction());
        dispatch(resetValuationWizard());
        dispatch(restoreCheckpoints());
      });
    }
  };

  useEffect(() => {
    if (Number(openTab)) {
      dispatch(setSelectedTab(Number(openTab)));
    }
    dispatch(
      changeViewport({
        ...Europe,
        transitionDuration: 1000,
      })
    );

    return () => {
      dispatch(setSelectedTab(0));
      dispatch(setIsReadOnlyAccess(false));
    };
  }, []);

  // map
  useEffect(() => {
    setTimeout(() => {
      if (currentPropertyData.coordinates && !isValuationWizardOpen) {
        batch(() => {
          dispatch(setSettings({ ...defaultMapSettings }));
          dispatch(
            changeViewport({
              longitude:
                (Number(currentPropertyData.coordinates?.longitude) ?? 0) +
                0.005,
              latitude:
                (Number(currentPropertyData.coordinates?.latitude) ?? 0) -
                0.001,
              zoom: 12,
              transitionDuration: 'auto',
              transitionInterpolator: new FlyToInterpolator({
                speed: 1.8,
              }),
              transitionEasing: easeCubic,
            })
          );
          dispatch(
            setMarkers([
              currentPropertyData.coordinates ?? { latitude: 0, longitude: 0 },
            ])
          );
        });
      }
    }, 0);
  }, [
    selectedTab,
    currentPropertyData.coordinates,
    isValuationWizardOpen,
    dispatch,
  ]);

  if (currentPropertyData.isLoading) {
    return <ValuationLoader maxWidth="500px" />;
  }

  if (
    !currentPropertyData.isLoading &&
    (currentPropertyData.error ||
      (currentPropertyData.isSuccess && !currentPropertyData.address))
  ) {
    return <LngRedirect to="/404" />;
  }

  if (currentPropertyData.isReadOnlyAccess) {
    dispatch(setIsReadOnlyAccess(true));
  }

  return (
    <>
      {isIframe && (
        <IFrameContainer>
          <PropertyValuationWidgetContainer />
        </IFrameContainer>
      )}
      {!isIframe && (
        <>
          <>
            {!isMobileSize && (
              <>
                <Hero>
                  <SellerPropertyGalleryContainer
                    images={currentPropertyData.photos}
                  />

                  <HeroSlider
                    showAddressBar={
                      isValuationWizardOpen ? activeStep > 0 : true
                    }
                    topOffset={-56}
                    address={
                      isValuationWizardOpen
                        ? selectedAdddress
                        : currentPropertyData.address
                    }
                    onGoBack={onGoBack}
                  >
                    <PropertyValuationOverviewCard
                      header="property-valuation-overview-card.header"
                      title="property-valuation-overview-card.title"
                      content="property-valuation-overview-card.content"
                      priceLabel={priceLabel}
                      {...currentPropertyData.overviewProps}
                    />
                    <Card
                      header={t('property-page.hero-slider.first-card.header')}
                      titleFirstLine={
                        valuationType === ValuationType.Online
                          ? t(
                              'property-page.hero-slider.first-card.online.title.first-line'
                            )
                          : t(
                              'property-page.hero-slider.first-card.personal.title.first-line'
                            )
                      }
                      titleSecondLine={t(
                        'property-page.hero-slider.first-card.title.second-line'
                      )}
                      content={
                        valuationType === ValuationType.Online
                          ? t(
                              'property-page.hero-slider.first-card.online.content'
                            )
                          : t(
                              'property-page.hero-slider.first-card.personal.content'
                            )
                      }
                      showAddressBar={
                        isValuationWizardOpen ? activeStep > 0 : true
                      }
                      cta={(c) => (
                        <IconWrapper onClick={closeEvaluationWizard}>
                          <Icon icon={ArrowButtonLeft} width={12} height={12} />{' '}
                          {c}
                        </IconWrapper>
                      )}
                    />
                    <Card
                      titleType="h2"
                      header={t('property-page.hero-slider.second-card.header')}
                      titleFirstLine={
                        valuationType === ValuationType.Online
                          ? t(
                              'property-page.hero-slider.second-card.online.title.first-line'
                            )
                          : t(
                              'property-page.hero-slider.second-card.personal.title.first-line'
                            )
                      }
                      titleSecondLine={t(
                        'property-page.hero-slider.second-card.title.second-line'
                      )}
                      content={
                        valuationType === ValuationType.Online
                          ? t(
                              'property-page.hero-slider.second-card.online.content'
                            )
                          : t(
                              'property-page.hero-slider.second-card.personal.content'
                            )
                      }
                      showAddressBar={
                        isValuationWizardOpen ? activeStep > 0 : true
                      }
                      cta={(c) => (
                        <IconWrapper onClick={closeEvaluationWizard}>
                          <Icon icon={ArrowButtonLeft} width={12} height={12} />{' '}
                          {c}
                        </IconWrapper>
                      )}
                    />
                  </HeroSlider>
                  <PropertyValuationWidgetContainer
                    pageType={PageType.sellerLandingPage}
                  />
                </Hero>
              </>
            )}
          </>
          <>
            {isMobileSize && (
              <>
                <SellerPropertiesTab shouldShrink onGoBack={onGoBack} />
                <MobileHeadContainer>
                  <PropertyValuationOverviewCard
                    header="property-valuation-overview-card.header"
                    title="property-valuation-overview-card.title"
                    content="property-valuation-overview-card.content"
                    {...currentPropertyData.overviewProps}
                  />

                  {/* <AddressBar */}
                  {/*  show={isValuationWizardOpen ? activeStep > 0 : true} */}
                  {/*  address={ */}
                  {/*    isValuationWizardOpen ? selectedAdddress : propertyAddress */}
                  {/*  } */}
                  {/* /> */}
                  <MobileMapContainer>
                    <>
                      {currentPropertyData.dossierUrl && (
                        <IframeMap
                          isVisible={!selectedTab}
                          dossierUrl={currentPropertyData.dossierUrl ?? ''}
                        />
                      )}
                    </>
                    <>
                      <Mapbox
                        isVisible={isValuationWizardOpen || selectedTab > 0}
                      />
                    </>
                  </MobileMapContainer>

                  <Drawer
                    drawerTopContent={
                      <CTAButtons
                        skipBorderRadius
                        pageType={PageType.sellerLandingPage}
                      />
                    }
                    drawerBottomContent={
                      currentPropertyData.isContractSigned ||
                      currentPropertyData.userStatus ===
                        UserStatus.Spectator ? undefined : (
                        <VariationHeaderActions
                          propertyId={propertyId}
                          isDeleteDisabled={
                            currentPropertyData.isContractSigned || isBrokerView
                          }
                          isEditDisabled={
                            currentPropertyData.isContractSigned || isBrokerView
                          }
                        />
                      )
                    }
                    drawerBottomButtonFlex={'0 0 50%'}
                  />

                  {isValuationWizardOpen &&
                    valuationType === ValuationType.Online && (
                      <OnlineValuationContainer />
                    )}
                  {isValuationWizardOpen &&
                    valuationType === ValuationType.Personal && (
                      <PersonalValuationContainer />
                    )}
                </MobileHeadContainer>
              </>
            )}
          </>
          <ContentWithPadding>
            <LockedTabTooltip
              isVisible={isLockedTooltipVisible}
              setIsVisible={setIsLockedTooltipVisible}
              offset={{
                top: isMobileSize ? -220 : -190,
                left: isMobileSize
                  ? 20 + 12 * (tabHoveredIndex - 1)
                  : 20 + 120 * (tabHoveredIndex - 1),
              }}
            />
            <Tabs
              selectedIndex={selectedTab}
              onSelect={(index) => {
                dispatch(setSelectedTab(index));
              }}
            >
              <HeaderWrapper
                isTabListNearTop={isTabListNearTop}
                ref={tabListRef}
                floatingBar={floatingBar}
                isContractSigned={currentPropertyData.isContractSigned}
                selectedTabIndex={selectedTab}
                style={{
                  overflowY: isMobileSize ? 'hidden' : 'visible',
                  overflowX: isMobileSize ? 'auto' : 'visible',
                }}
              >
                <TabList style={{ height: '100%', position: 'relative' }}>
                  {isBrokerView && (
                    <Tab tabIndex="0">
                      <HeaderTab
                        title={t(
                          'seller-property-navigation.broker-view.market-price-tab'
                        )}
                        icon={MarketPriceCalculator}
                      />
                    </Tab>
                  )}
                  {!currentPropertyData.isDigitalServiceActivated && (
                    <Tab tabIndex="0">
                      <HeaderTab
                        title={t('seller-property-navigation.first-tab')}
                        icon={Attachment}
                      />
                    </Tab>
                  )}
                  <Tab
                    tabIndex="0"
                    disabled={!currentPropertyData.isDigitalServiceActivated}
                    onClick={() => {
                      if (currentPropertyData.isDigitalServiceActivated) {
                        return;
                      }
                      setTabHoveredIndex(1);
                      setIsLockedTooltipVisible(true);
                    }}
                    onMouseEnter={() => {
                      if (currentPropertyData.isDigitalServiceActivated) {
                        return;
                      }
                      setTabHoveredIndex(1);
                      setIsLockedTooltipVisible(true);
                    }}
                    onMouseLeave={() => {
                      setTabHoveredIndex(0);
                      setIsLockedTooltipVisible(false);
                    }}
                  >
                    <HeaderTab
                      title={t('seller-property-navigation.second-tab')}
                      icon={
                        currentPropertyData.isDigitalServiceActivated
                          ? Document
                          : Lock
                      }
                    />
                  </Tab>
                  <Tab tabIndex="0">
                    <HeaderTab
                      title={t('seller-property-navigation.third-tab')}
                      icon={Puzzle}
                    />
                  </Tab>
                  <Tab
                    tabIndex="0"
                    disabled={!currentPropertyData.isDigitalServiceActivated}
                    onClick={() => {
                      if (currentPropertyData.isDigitalServiceActivated) {
                        return;
                      }
                      setTabHoveredIndex(3);
                      setIsLockedTooltipVisible(true);
                    }}
                    onMouseEnter={() => {
                      if (currentPropertyData.isDigitalServiceActivated) {
                        return;
                      }
                      setTabHoveredIndex(3);
                      setIsLockedTooltipVisible(true);
                    }}
                    onMouseLeave={() => {
                      setTabHoveredIndex(0);
                      setIsLockedTooltipVisible(false);
                    }}
                  >
                    <HeaderTab
                      title={t('seller-property-navigation.fourth-tab')}
                      icon={
                        currentPropertyData.isDigitalServiceActivated
                          ? House
                          : Lock
                      }
                    />
                  </Tab>
                  <Tab tabIndex="0">
                    <HeaderTab
                      title={t('seller-property-navigation.fifth-tab')}
                      icon={Money ?? Lock}
                    />
                  </Tab>
                  {isBrokerView && (
                    <Tab tabIndex="0">
                      <HeaderTab
                        // title={t('seller-property-navigation.sixth-tab')}
                        title={'EINSTELLUNGEN'}
                        icon={Settings}
                      />
                    </Tab>
                  )}
                </TabList>

                <>
                  {currentPropertyData.userStatus !== UserStatus.Spectator &&
                    !isMobileSize &&
                    !currentPropertyData.isContractSigned && (
                      <VariationHeaderActions
                        propertyId={propertyId}
                        isDeleteDisabled={
                          currentPropertyData.isContractSigned || isBrokerView
                        }
                        isEditDisabled={
                          currentPropertyData.isContractSigned || isBrokerView
                        }
                      />
                    )}
                </>
              </HeaderWrapper>
              {isBrokerView && (
                <TabPanel>
                  <MarketPrice />
                </TabPanel>
              )}
              {!currentPropertyData.isDigitalServiceActivated && (
                <TabPanel>
                  {currentPropertyData.isContractSigned ? (
                    <Preparation isLocked={isDigitalServiceLocked} />
                  ) : (
                    <Appointments
                      userStatus={currentPropertyData.userStatus}
                      message={currentPropertyData.relatedShop?.message ?? ''}
                      messageSentAt={
                        currentPropertyData.relatedShop?.messageSentAt
                      }
                      senderFirstName={
                        currentPropertyData.relatedShop?.senderFirstName ?? ''
                      }
                      senderLastName={
                        currentPropertyData.relatedShop?.senderLastName ?? ''
                      }
                    />
                  )}
                </TabPanel>
              )}
              <TabPanel>
                <Overview isLocked={isDigitalServiceLocked} />
              </TabPanel>
              <TabPanel>
                <Services
                  isSimulation={!currentPropertyData.isDigitalServiceActivated}
                  isLocked={isDigitalServiceLocked}
                  currentValuation={currentPropertyData.currentValuation}
                  disabledPropertyServices={
                    currentPropertyData.disabledServices
                  }
                />
              </TabPanel>
              <TabPanel>
                <Expose isLocked={isDigitalServiceLocked} />
              </TabPanel>
              <TabPanel>
                <Tabs>
                  {currentPropertyData.isActivitiesTabUnlocked && (
                    <SubHeaderWrapper floatingBar={floatingBar}>
                      <TabList>
                        <Tab tabIndex="0">
                          <HeaderTab
                            title={t('seller-marketing.tabs.first-tab')}
                          />
                        </Tab>

                        <Tab tabIndex="0">
                          <HeaderTab
                            title={t('seller-marketing.tabs.second-tab')}
                          />
                        </Tab>
                      </TabList>
                    </SubHeaderWrapper>
                  )}

                  <TabPanel>
                    {currentPropertyData.isContractSigned ? (
                      <Marketing
                        isLocked={isDigitalServiceLocked}
                        isHiddenSendEmailToBrokerAlert={
                          currentPropertyData.isHiddenSendEmailToBrokerAlert
                        }
                      />
                    ) : (
                      <SellingSimulation
                        userStatus={currentPropertyData.userStatus}
                        currentValuation={currentPropertyData.currentValuation}
                        isHiddenSendEmailToBrokerAlert={
                          currentPropertyData.isHiddenSendEmailToBrokerAlert
                        }
                      />
                    )}
                  </TabPanel>

                  {currentPropertyData.isActivitiesTabUnlocked && (
                    <TabPanel>
                      <Activities
                        propertyId={propertyId}
                        address={
                          isValuationWizardOpen
                            ? selectedAdddress
                            : currentPropertyData.address
                        }
                        code={currentPropertyData.overviewProps.code}
                      />
                    </TabPanel>
                  )}
                </Tabs>
              </TabPanel>
              {isBrokerView && (
                <TabPanel>
                  <BrokerSettings
                    disabledPropertyServices={
                      currentPropertyData.disabledServices
                    }
                  />
                </TabPanel>
              )}
            </Tabs>
          </ContentWithPadding>
          <EditValuationModal />
          <ContactFormModal
            userStatus={currentPropertyData.userStatus}
            message={currentPropertyData.relatedShop?.message ?? ''}
            messageSentAt={currentPropertyData.relatedShop?.messageSentAt}
          />
          <ScrollDetector
            containerReference={tabListRef}
            propertyFlyingTabs={new Set([])}
          />
        </>
      )}
    </>
  );
};

const SellingProcessPage = memo(SellingProcessPageBase);

export { SellingProcessPage };
