import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const LivingSpaceIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={
          'M2.5 23.5v-18m-2 16 2 2 2-2m0-14-2-2-2 2m5-5h18m-16-2-2 2 2 2m14 0 2-2-2-2m-14 7h16v16h-16z'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={
          'M19.5 23.5V19a.5.5 0 0 1 .5-.5h1m2.5-3h-4m4-3h-4m-8-5V12a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5V7.5m-6 12V16a.5.5 0 0 0-.5-.5H7.5m6 8v-4m3 4V17a.5.5 0 0 0-.5-.5h-2.5'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { LivingSpaceIcon };
