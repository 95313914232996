import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputV2 } from '../../../../../../../components/common/input-v2/input-v2';
import { FIELDS, FINANCING_FORM_KEYS } from '../../../../../../constants';
import { SwitchBox } from '../../financing-data-edit-form-styles';
import { Switch } from '../../../../../../../components/common/switch/switch';
import { Collapsible } from '../../../../../../../../common/components/ui/collapsible';
import { InputWithCalculateValue } from '../../input-with-calculate-value/input-with-calculate-value';
import { IFinancingForm } from '../../../../../../interface';
import { numberFormat } from '../../../../../../../../financing/utils/numberFormat';

interface IProps {
  finData: IFinancingForm;
  isWithBroker: boolean;

  setWithBroker: (state: boolean) => void;
  onChangeTax: (name: string, cb?: () => void) => (value: number) => void;
  setDirtyTrue(): void;
  onChangePurchasePrice(value: number | string): void;
}

const MortgageCertificateFields: FC<IProps> = ({
  finData,
  isWithBroker,
  setWithBroker,
  setDirtyTrue,
  onChangeTax,
  onChangePurchasePrice,
}) => {
  const { t } = useTranslation();
  const [purchasePrice, equityCapital] = useWatch({
    name: [
      FINANCING_FORM_KEYS.PURCHASE_PRICE,
      FINANCING_FORM_KEYS.EQUITY_CAPITAL,
    ],
  });

  return (
    <>
      <InputV2
        name="purchasePrice"
        type="number"
        label="financing-portal-page.tabs.calculator.edit.inputs.purchase-price.label"
        placeholder="financing-portal-page.tabs.calculator.edit.inputs.purchase-price.placeholder"
        inputAdornment="€"
        defaultValue={finData?.purchasePrice}
        handleChangeProp={(value) => {
          if (onChangePurchasePrice) {
            onChangePurchasePrice(Number(value));
          }
          setDirtyTrue();
        }}
        isSeparate
        isLabelSaturated
        isValidateOnBlur
        isOldDesign
        validateNames={
          equityCapital
            ? [
                FINANCING_FORM_KEYS.PURCHASE_PRICE,
                FINANCING_FORM_KEYS.EQUITY_CAPITAL,
              ]
            : undefined
        }
        rules={{
          ...FIELDS.PURCHASE_PRICE.rules,
          validate: (value: number) => {
            return Number(value) >= Number(equityCapital)
              ? true
              : t(
                  'financing-portal.form.inputs.purchase-price.errors.equity-capital-value'
                ).toString();
          },
        }}
      />
      <InputV2
        name="equityCapital"
        type="number"
        label="financing-portal-page.tabs.calculator.edit.inputs.equity-capital.label"
        placeholder="financing-portal-page.tabs.calculator.edit.inputs.equity-capital.placeholder"
        inputAdornment="€"
        defaultValue={finData?.equityCapital}
        handleChangeProp={() => {
          setDirtyTrue();
        }}
        isSeparate
        isLabelSaturated
        isValidateOnBlur
        isOldDesign
        tooltipKey="financing-portal-page.tabs.calculator.edit.inputs.equity-capital.tooltip"
        isTooltipKeyLeftSide
        isBoldTooltipKeyIcon
        validateNames={
          purchasePrice
            ? [
                FINANCING_FORM_KEYS.PURCHASE_PRICE,
                FINANCING_FORM_KEYS.EQUITY_CAPITAL,
              ]
            : undefined
        }
        rules={{
          ...FIELDS.EQUITY_CAPITAL.rules,
          min: {
            value: purchasePrice * 0.1,
            message: t(
              'financing-portal.form.inputs.equity-capital.errors.min-value',
              { value: numberFormat(purchasePrice * 0.1) }
            ),
          },
          validate: (value: number) => {
            return Number(value) <= Number(purchasePrice)
              ? true
              : t(
                  'financing-portal.form.inputs.equity-capital.errors.purchase-price-value'
                ).toString();
          },
        }}
      />
      <SwitchBox>
        <Switch
          name="brokerageFee"
          label="financing-portal-page.tabs.calculator.edit.inputs.with-broker.label"
          defaultValue={!!finData.brokerageFee}
          onChangeProp={(value) => {
            setWithBroker(value);
            setDirtyTrue();
          }}
        />
      </SwitchBox>
      <Collapsible
        isOpen={isWithBroker}
        initHeight={isWithBroker ? 40 : 0}
        mt={!isWithBroker ? -12 : 0}
      >
        <InputWithCalculateValue
          name="brokerFeePercent"
          label="financing-portal-page.tabs.calculator.edit.inputs.brokerage-fee.label"
          placeholder="financing-portal-page.tabs.calculator.edit.inputs.brokerage-fee.placeholder"
          purchasePrice={purchasePrice ?? 0}
          isDisabled={!isWithBroker}
          isPercent
          isCounts={isWithBroker}
          defaultValue={finData.brokerFeePercent || 3.57}
          onChangePrice={onChangeTax('brokerFeePercent')}
          onChangeProp={onChangeTax('brokerFeePercent', setDirtyTrue)}
          rules={FIELDS.BROKER_FEE_PERCENT.rules}
        />
      </Collapsible>
    </>
  );
};

export { MortgageCertificateFields };
