import {
  ChangeEvent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import {
  BoldText,
  CardHeader,
  Container,
  Description,
  Title,
  TitleWrapper,
  FilterWrapper,
} from './overview-card-styles';
import { InputSearch } from '../../../../common/components/form/input/input-search';
import { FilesOverview } from '../files-overview/files-overview';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { FilterButton } from '../filter-button/filter-button';
import {
  setReleaseStatusFilter,
  toggleFilterOpen,
  toggleShouldResetFilter,
} from '../../../redux/brokerViewSlice';
import { ReleaseStatus } from '../../../../../generated';
import { AlertInfo } from '../../../../common/components/ui/alerts/info';
import Icon from '../../../../common/components/ui/icon';
import { ReactComponent as AlertTriangle } from '../../../../../assets/streamline-light/interface-essential/alerts/alert-triangle.svg';
import { ClearFilterButton } from '../filter-button/filter-button-styles';

const OverviewCardBase = () => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchInputDebounced, setSearchInputDebounced] = useState<string>('');
  const [accordionState, setAccordionState] = useState<boolean>(true);
  const isMobileSize = useIsMobileSize();
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);

  const onSearchInputChangeDebounced = useDebouncedCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchInputDebounced(event.target.value);
    },
    1000
  );

  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value);
      onSearchInputChangeDebounced(event);
    },
    [onSearchInputChangeDebounced]
  );

  const onClearSearch = useCallback(() => {
    setSearchInput('');
    setSearchInputDebounced('');
  }, []);

  const onCollapseAllAccordions = useCallback(() => {
    setAccordionState((prevState) => !prevState);
  }, []);

  const onApplyFilters = useCallback(
    (formData) => {
      setIsFilterActive(true);
      const filter: ReleaseStatus[] = Object.keys(formData)
        .filter((key) => !!formData[key])
        .map((key) => key as ReleaseStatus);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(setReleaseStatusFilter(filter));
      dispatch(toggleFilterOpen(false));
    },
    [dispatch]
  );

  const onResetFilters = useCallback(() => {
    dispatch(setReleaseStatusFilter([]));
    dispatch(toggleShouldResetFilter(true));
    setIsFilterActive(false);
  }, [dispatch]);

  // useEffect(() => {
  //   return () => {
  //     onResetFilters();
  //   };
  // }, []);

  return (
    <Container>
      <CardHeader>
        <TitleWrapper>
          <Title
            content={t('buyer-portal.broker-view.expose-documents-page.title')}
          />
        </TitleWrapper>
      </CardHeader>
      <FilterWrapper>
        <InputSearch
          placeholder={t('seller-properties-list.search-input.placeholder')}
          containerStyle={{ width: '100%' }}
          onChange={onSearchInputChange}
          value={searchInput}
          onClearSearch={onClearSearch}
        />
        <AlertInfo
          style={{
            backgroundColor: themeContext.tagGoldBackground,
            border: `1px solid ${themeContext.tagGoldBorder}`,
            margin: 0,
            padding: '6px 10px',
            alignItems: 'center',
            display: isFilterActive ? 'flex' : 'none',
            minWidth: 'max-content',
            height: '40px',
          }}
          text={t(
            'buyer-portal.broker-view.expose-documents-page.access-manager.filter-set-message'
          )}
          icon={
            <Icon
              icon={AlertTriangle}
              width={16}
              height={16}
              color={themeContext.blue}
              style={{
                alignSelf: 'center',
                marginRight: '12px',
                minWidth: '16px',
              }}
              strokeWidth={'1.5'}
            />
          }
          CTA={
            <ClearFilterButton
              label={t('marketing-dashboard.filter.clear')}
              onClick={onResetFilters}
              borderColor={themeContext.blue}
              color={themeContext.blue}
              type={'button'}
              style={{ height: '28px', marginLeft: '12px', fontSize: '11px' }}
            />
          }
        />
        <FilterButton
          onSubmit={onApplyFilters}
          onReset={onResetFilters}
          isActive={isFilterActive}
        />
      </FilterWrapper>
      <Description
        content={t(
          'buyer-portal.broker-view.expose-documents-page.description'
        )}
      />
      <BoldText
        content={t('buyer-portal.broker-view.expose-documents-page.bold-text')}
      />
      <FilesOverview
        searchInput={searchInputDebounced}
        accordionState={accordionState}
        onCollapseAccordion={onCollapseAllAccordions}
        isFilterActive={isFilterActive}
        onResetFilters={onResetFilters}
      />
    </Container>
  );
};

const OverviewCard = memo(OverviewCardBase);

export { OverviewCard };
