import { FC, memo, useEffect } from 'react';
import { isNumber } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { FORM_STEPS, usefulInfoKeyPhrases } from '../../../../constants';
import {
  Container,
  FormContainer,
} from '../../../../../components/fullscreen-modal/styles';
import { AuthContainer } from '../../auth-containers/auth-container';
import { FinancingLoader } from '../../../../../components/common/loader';
import { UsefulInfo } from '../../../../../components/fullscreen-modal/useful-info/useful-info';
import { ProgressBar } from '../../../../../components/fullscreen-modal/progress-bar/progress-bar';
import { ModalHeader } from '../../../../../components/fullscreen-modal/modal-header/modal-header';
import { FinancingForm } from '../../../forms/financing-form/financing-form';

import { useIsMobileSize } from '../../../../../../common/hooks/useIsMobileSize';
import { useFinancingFormInfo } from '../../../../hooks/useFinancingFormInfo';
import { useNavigationButtonsHandler } from '../../../../hooks/callback-hooks/useNavigationButtonsHandler';
import { FinancingPersonalFormContainer } from '../../auth-containers/financing-personal-form-container/financing-personal-form-container';

interface IProps {
  activeStepProp?: number;
  minStepProp?: number;
}

const FinancingFormContainerBase: FC<IProps> = ({ activeStepProp }) => {
  const isMobileSize = useIsMobileSize();
  const { reset } = useFormContext();

  const {
    activeStep,
    isAuthFlow,
    isPersonalFinancing,
    owner,
    header,
    isIframe,
    isFinancingOpen,
    isLoading,
    onSwitchToAuth,
    onSwitchToPersonalFinancing,
    financingErrors,
    isFinancingCertificate,
    isLoadingSuggestedProperty,
  } = useFinancingFormInfo();
  // useConvertProperty();
  // const { onCreateFinancing } = useSubmitFinancingForm({});
  const { onBackClick, onCloseClick } = useNavigationButtonsHandler({
    reset,
  });

  function onBeforeUnload(event: Event) {
    event.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-return-assign
    return (event.returnValue = '');
  }

  useEffect(() => {
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  const isShowUsefulInfo =
    (!isMobileSize || activeStep === 0 || activeStepProp === 0) && !isAuthFlow;

  const isShowHeader = !isIframe && activeStepProp !== 0 && isMobileSize;

  if (owner) {
    return (
      <Container isIframe={isIframe}>
        {isShowHeader && (
          <ModalHeader
            activeStep={activeStep}
            isUpperCase={!isMobileSize}
            onClose={onCloseClick}
            onBackClick={onBackClick}
            title={isMobileSize ? 'Ihre Finanzierungsanfrage' : header}
          />
        )}
        <FormContainer
          className="financing-form-container"
          tabletPadding={activeStep === 0 ? '16px 16px 8px' : undefined}
        >
          {isLoading || isLoadingSuggestedProperty ? (
            <FinancingLoader
              title={
                isFinancingCertificate
                  ? 'financing.loader-label-certificate'
                  : 'financing.loader-label'
              }
            />
          ) : isPersonalFinancing ? (
            <FinancingPersonalFormContainer />
          ) : (
            <FinancingForm
              isHeadline
              activeFormStep={
                isNumber(activeStepProp) ? activeStepProp : activeStep
              }
              errors={financingErrors}
              // onSubmit={onCreateFinancing}
              lastStepCallback={onSwitchToPersonalFinancing}
            />
          )}
        </FormContainer>
      </Container>
    );
  }

  return (
    <Container isIframe={isIframe}>
      {isShowHeader && (
        <ModalHeader
          activeStep={activeStep}
          isUpperCase={!isMobileSize}
          onClose={onCloseClick}
          onBackClick={onBackClick}
          title={isMobileSize ? 'Ihre Finanzierungsanfrage' : header}
        />
      )}
      <FormContainer
        className="financing-form-container"
        tabletPadding={activeStep === 0 ? '16px 16px 8px' : undefined}
      >
        {!isAuthFlow ? (
          <>
            {isLoading ? (
              <FinancingLoader
                title={
                  isFinancingCertificate
                    ? 'financing.loader-label-certificate'
                    : 'financing.loader-label'
                }
              />
            ) : (
              <FinancingForm
                isHeadline
                activeFormStep={
                  isNumber(activeStepProp) ? activeStepProp : activeStep
                }
                errors={financingErrors}
                lastStepCallback={onSwitchToAuth}
              />
            )}
          </>
        ) : (
          <AuthContainer />
        )}
      </FormContainer>
      {!isAuthFlow && (
        <ProgressBar activeStep={activeStep} formSteps={FORM_STEPS} />
      )}
      {isShowUsefulInfo && (
        <UsefulInfo
          keyPhrases={usefulInfoKeyPhrases}
          isShort={!isFinancingOpen}
          isIframe={isIframe}
        />
      )}
    </Container>
  );
};

const FinancingFormContainer = memo(FinancingFormContainerBase);

export { FinancingFormContainer };
