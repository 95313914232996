import Collapse, { Panel } from 'rc-collapse';
import { CollapsePanelProps } from 'rc-collapse/lib/interface';
import { useParams } from 'react-router-dom';
import Icon from 'modules/common/components/ui/icon';
import { ReactComponent as ArrowDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import { ReactComponent as Help } from 'assets/streamline-light/interface-essential/help/question-help-circle.svg';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import collapseMotion from 'modules/common/components/ui/faq-accordion/utils';
import useGetLocalization from 'modules/localization/get-localization';
import {
  BuyerFinderSettings,
  useLazyReadFaqsQuery,
  useReadBuyerFinderSettingsQuery,
} from '../../../../../../generated';
import {
  Wrapper,
  Header,
  HeaderTitle,
  Container,
  ContentText,
} from './buyer-finder-faq-styles';

interface BuyerFinderSettingsResponse {
  data: { readBuyerFinderSettings: BuyerFinderSettings };
  isLoading: boolean;
}

export const BuyerFinderFaq = () => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const lang = useGetLocalization();
  const { data: buyerFinderSettings } =
    useReadBuyerFinderSettingsQuery<BuyerFinderSettingsResponse>({
      locale: lang,
    });
  const [getFAQs, { data: FAQsList }] = useLazyReadFaqsQuery();

  useEffect(() => {
    if (buyerFinderSettings) {
      const FAQSection =
        buyerFinderSettings.readBuyerFinderSettings?.sections?.find(
          (item) => item.type === 'FAQ'
        );
      getFAQs({
        filter: {
          _ids: FAQSection?.recordIds || [],
        },
      });
    }
  }, [propertyId, buyerFinderSettings]);

  return (
    <Wrapper>
      <Header>
        <Icon width={32} height={32} icon={Help} color={themeContext.blue} />
        <HeaderTitle content={t('buyer-finder.faq.title')} />
      </Header>
      <Container>
        <Collapse
          openMotion={collapseMotion}
          className="faq-accordion"
          defaultActiveKey={1}
          expandIcon={({ isActive }: CollapsePanelProps) => {
            return (
              <Icon
                width={16}
                height={16}
                icon={ArrowDown}
                color={themeContext.blue}
                style={{
                  transition: `transform 500ms ease`,
                  transform: isActive ? 'rotate(-180deg)' : 'none',
                }}
              />
            );
          }}
        >
          {FAQsList?.data?.map((item, i) => (
            <Panel
              key={i + 1}
              header={item.title}
              headerClass="faq-header"
              className="faq-content"
            >
              <ContentText
                dangerouslySetInnerHTML={{
                  __html: item.expandedText,
                }}
              />
            </Panel>
          ))}
        </Collapse>
      </Container>
    </Wrapper>
  );
};
