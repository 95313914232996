import { FC, useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Container,
  Header,
  PropertyPlan,
  PropertyPlanContainer,
  Text,
} from './description-styles';
import { useTranslation } from 'react-i18next';
import {
  setIsGalleryOpen,
  setActiveSlide,
  setIsPropertyPlanOpen,
} from '../../../../redux/exposeSlice';
import { useDispatch } from 'react-redux';
import { PropertyPlanModal } from '../property-plan-modal/property-plan-modal';
import { Property } from '../../../../../../../../generated';

const parseEquipmentDescription = (equipmentDescription: string): string => {
  if (equipmentDescription.includes('\n')) return equipmentDescription;

  return equipmentDescription.split('-').join('\n-').slice(1);
};

const parseDescription = (description: string): string => {
  if (description.includes('\n')) return description;

  return description.split('. ').join('.\n\n');
};

interface IProps {
  property: Partial<Property>;
}

const Description: FC<IProps> = ({ property }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedPropertyPlan, setSelectedPropertyPlan] = useState('');

  const {
    objectDescription,
    locationDescription,
    equipmentDescription,
    otherInformation,
    planUrls,
  } = property?.description || {};

  const { numberOfIndoorParkingSpaces, numberOfOutdoorParkingSpaces } =
    property?.propertyData || {};

  const onOpenFullScreen = useCallback((src: string) => {
    // const propertyPlanSlide = images?.findIndex(
    //   (img) => img.group === 'GRUNDRISS'
    // );
    // dispatch(setActiveSlide(propertyPlanSlide + index ?? 0));
    // dispatch(setIsGalleryOpen(true));
    setSelectedPropertyPlan(src);
    dispatch(setIsPropertyPlanOpen(true));
  }, []);

  return (
    <Container>
      {!isEmpty(objectDescription) && (
        <>
          {' '}
          <Header
            content={t(
              'search-profile.suggested-properties.expose-details.description'
            )}
          />
          <Text
            content={parseDescription(objectDescription || '')}
            allowHtml
            preLine
          />
        </>
      )}

      {!isEmpty(locationDescription) && (
        <>
          <Header
            content={t(
              'search-profile.suggested-properties.expose-details.location-description'
            )}
          />
          <Text
            content={parseDescription(locationDescription || '')}
            allowHtml
            preLine
          />
        </>
      )}

      {!isEmpty(equipmentDescription) && (
        <>
          {' '}
          <Header
            content={t(
              'search-profile.suggested-properties.expose-details.equipment-description'
            )}
          />
          <Text
            content={parseEquipmentDescription(equipmentDescription || '')}
            allowHtml
            preLine
          />
        </>
      )}

      {numberOfIndoorParkingSpaces || numberOfOutdoorParkingSpaces ? (
        <>
          <Header
            content={t(
              'search-profile.suggested-properties.expose-details.parking-spaces'
            )}
          />
          {numberOfIndoorParkingSpaces !== 0 && (
            <Text
              content={`${numberOfIndoorParkingSpaces} x ${t(
                'expose-page.tabs.overview.property-details.garage'
              )}`}
              preLine
            />
          )}
          {numberOfOutdoorParkingSpaces !== 0 && (
            <Text
              content={`${numberOfOutdoorParkingSpaces} x ${t(
                'expose-page.tabs.overview.property-details.parking-space'
              )}`}
              preLine
            />
          )}
        </>
      ) : (
        <></>
      )}

      {!isEmpty(otherInformation) && (
        <>
          {' '}
          <Header
            content={t(
              'search-profile.suggested-properties.expose-details.other-info'
            )}
          />
          <Text
            content={parseDescription(otherInformation || '')}
            allowHtml
            preLine
          />
        </>
      )}

      {!isEmpty(planUrls) && (
        <>
          {' '}
          <Header
            content={t(
              'search-profile.suggested-properties.expose-details.property-plan'
            )}
          />
          <PropertyPlanContainer>
            {planUrls?.map((planUrl, index) => (
              <div
                style={{
                  border: '1px solid #ddd',
                  borderRadius: '.25rem',
                  height: '100%',
                  display: 'flex',
                }}
              >
                <PropertyPlan
                  key={`Plan-${index}`}
                  onClick={() => onOpenFullScreen(planUrl)}
                  alt={`Plan-${index}`}
                  src={planUrl}
                />
              </div>
            ))}
          </PropertyPlanContainer>
          <PropertyPlanModal imgSrc={selectedPropertyPlan} />
        </>
      )}
    </Container>
  );
};

export { Description };
