import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Container } from './financing-no-modal-styles';
import { useAppSelector } from '../../../../../../common/hooks';
import { IFinancingForm } from '../../../../interface';
import { FinancingFormContainer } from '../financing-form-container/financing-form-container';

const FinancingNoModal: FC = () => {
  const { reset, ...methods } = useForm<IFinancingForm>();
  const { isIframe } = useAppSelector((state) => state.auth);
  const { isHighlight } = useAppSelector((state) => state.valuationWizardV2);

  return (
    <Container isIframe={isIframe} isCentered isHighlight={isHighlight}>
      <FormProvider reset={reset} {...methods}>
        <FinancingFormContainer />
      </FormProvider>
    </Container>
  );
};

export { FinancingNoModal };
