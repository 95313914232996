import styled from 'styled-components';
import { device } from '../../../../../../style/theme';
import {
  BoxTitle,
  Headline2,
} from '../../../../../common/components/ui/typography';
import { LngLinkContainer } from '../../../../../common/components/ui/typography/link';
import Icon from '../../../../../common/components/ui/icon';

export const ContentContainer = styled.div<{ marginBottom: number }>`
  position: relative;
  width: 100%;
  margin-bottom: ${(props) => props.marginBottom}px;
  @media ${device.tablet} {
    margin: 0;
    position: initial;
  }
`;

export const ImageBox = styled.div<{
  revertContent?: boolean;
  isProportional?: boolean;
}>`
  max-width: 743px;
  max-height: 530px;
  position: relative;
  z-index: 1;
  margin-left: ${({ revertContent }) => (revertContent ? 'auto' : 0)};
  ${({ revertContent }) =>
    revertContent
      ? `
    padding-right: 55px;
    @media ${device.tablet} {
      padding-right: 35px !important;
    }
  `
      : `
    padding-left: 55px;
    @media ${device.mobile} {
      padding-left: 0 !important;
    }
  `};
  ${(props) =>
    !props.revertContent
      ? `
    max-width: 804px;
    max-height: 506px;
    position: relative;
    z-index: 1;
    left: -170px;
    top: 20px;
  `
      : `
    right: -115px;
  `}

  ${({ isProportional }) =>
    isProportional &&
    `
    max-width: initial;
    max-height: initial;
    right: -210px;
    top: 41px;
  `}

  @media ${device.tablet} {
    margin: 0 auto;
    position: initial;
    max-width: 100%;
    padding: 0;
  }
`;

export const InfoBox = styled.div<{
  revertContent?: boolean;
}>`
  margin-top: 65px;
  position: absolute;
  ${({ revertContent }) => (revertContent ? 'left' : 'right')}: 0;
  top: 0;
  max-width: 688px;
  width: 688px;
  padding: 48px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.logoBlue};
  ${(props) =>
    !props.revertContent &&
    `
    margin-top: 65px;
    max-width: 688px;
    max-height: 372px;
    padding: 48px 48px 48px 146px;
  `}
  @media ${device.tablet} {
    position: initial;
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 16px 24px;
  }

  @media ${device.mobile} {
    max-height: unset;
    border-radius: 0;
  }
`;

export const Title = styled(Headline2)`
  margin: 0 0 24px 0;
  color: #ffffff;
  width: 494px;
  line-height: 1.42;
  letter-spacing: 0.33px;
  @media ${device.tablet} {
    text-align: center;
    width: 100%;
    font-size: 18px;
    margin-bottom: 16px;
  }
`;

export const Description = styled.div`
  margin: 0 0 16px 0;
  font-family: 'Roboto' !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: #ffffff !important;
  background-color: transparent !important;
  opacity: 0.9;
  width: 488px;
  li {
    margin-left: 1.5em !important;
  }
  p,
  span {
    background-color: transparent !important;
    color: #ffffff !important;
  }

  @media ${device.tablet} {
    width: 100%;
    opacity: 1;
  }
`;

export const BoxButtons = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    justify-content: center;
  }
  @media ${device.mobile} {
    button,
    a {
      width: 100%;
      justify-content: center;
    }

    a * {
      font-size: 12px;
      opacity: 1;
    }

    flex-direction: column-reverse;
  }
`;

export const LinkWrapper = styled(LngLinkContainer)`
  margin-left: 24px;
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    margin-left: 0;
    margin-bottom: 16px;
  }
  :hover {
    p {
      color: #cccccc;
    }
  }
`;

export const LinkIconWrapper = styled(Icon)`
  margin-right: 8px;
`;

export const LinkBox = styled(BoxTitle)`
  color: #fff;
  letter-spacing: 1px;
  opacity: 0.8;
  @media ${device.mobile} {
    font-size: 10px;
    line-height: 1.6;
  }
`;

export const StyledImg = styled.img<{
  revertContent?: boolean;
  isProportional?: boolean;
}>`
  width: 100%;
  height: 100%;
  max-height: 530px;
  object-fit: contain;
  ${(props) =>
    !props.revertContent &&
    `
    object-fit: contain;
    max-height: 506px;
  `}
  ${(props) =>
    props.isProportional &&
    `
      max-height: 560px;
  `}
`;
