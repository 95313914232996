import {
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { Virtuoso, VirtuosoGrid, VirtuosoHandle } from 'react-virtuoso';
import styled, { ThemeContext } from 'styled-components';
import { useParams } from 'react-router-dom';
import { LoaderContainer } from 'modules/search-profile/pages/search-profile-page/search-profile-page-styles';
import { ValuationLoader } from 'modules/forms/components/common/loader';

import { addMarkers } from '../../../map/redux/mapSlice';
import {
  ExposeStatus,
  PaginatedExposeRequests,
  PropertyCode,
  useExposeRequestsQuery,
} from '../../../../generated';
import { device } from '../../../../style/theme';
import { SecondaryButton } from '../../../common/components/ui/buttons';
import { Headline2 } from '../../../common/components/ui/typography';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { scrollToProperty } from '../../../property/redux/dynamicMapUtilsSlice';
import {
  addOnlineExposes,
  mergeFilters,
  setInitialLoader,
  setTotalItems,
} from '../../redux/onlineExposesSlice';
import { OnlineExposeCard } from './online-expose-card';
import { EmptyStatePlaceholder } from './empty-state-placeholder';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { ExposeDetailsOverlay } from '../../pages/expose-details-overlay/expose-details-overlay';
import {
  toggleExposeOverlay,
  setSelectedPropertyId,
  setExternalPropertyId,
  setSelectedPropertyCode,
  setIsAppointmentSelectOpen,
  setIsOpenHeadAccordion,
  setActiveTab,
  setFinancingId,
} from '../../pages/expose-details-overlay/redux/exposeSlice';
import { getPropertyCodeFromType } from '../../../property/utils/property-type-normalizer';

const ItemContainer = styled.div<{
  isShrinked: boolean;
  isMobileSize: boolean;
}>`
  padding-bottom: 12px;
  width: 100%;
  overflow: hidden;
  /* max-height: ${({ isMobileSize, isShrinked }) =>
    isMobileSize ? '710px' : isShrinked ? '530px' : '486px'};
  height: ${({ isMobileSize, isShrinked }) =>
    isMobileSize ? '710px' : isShrinked ? '530px' : '486px'}; */
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ListContainer = styled.div`
  /* display: flex;
  flex-wrap: wrap;
  //margin: 0 -6px -12px -6px;
  width: 100%;
  height: 100%; */
  width: 100%;
  height: 100%;
  display: block;
`;

const LoadMoreButtonContainer = styled.div`
  margin-top: 40px;

  & > button {
    justify-content: center;
  }
`;

const Header = styled.div<{ isShrinked: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  ${({ isShrinked }) =>
    isShrinked &&
    `
    >div:last-child {
      margin-left: 0;
      flex: 1;
      justify-content: space-between;
    }
  `} @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled(Headline2)``;

const Count = styled.div`
  margin-left: 8px;
  padding: 2px 8px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.ctaBlue};
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: #fff;
`;

export interface OnlineExposesResponse {
  data: { exposeRequests: PaginatedExposeRequests };
  isLoading: boolean;
}

const OnlineExposesContainerBase = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const themeContext = useContext(ThemeContext);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const isMobileSize = useIsMobileSize();
  const { searchProfileId } = useParams<{ searchProfileId: string }>();

  const isShrinked = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );

  const filters = useAppSelector((state) => state.onlineExposes.filters);

  const onlineExposes = useAppSelector(
    (state) => state.onlineExposes.onlineExposes
  );
  const totalItems = useAppSelector((state) => state.onlineExposes.totalItems);

  const initialLoader = useAppSelector(
    (state) => state.onlineExposes.initialLoader
  );
  const propertyIndexForScrolling = useAppSelector(
    (state) => state.dynamicMapUtils.propertyIndexForScrolling
  );

  const { data, isLoading } = useExposeRequestsQuery<OnlineExposesResponse>({
    searchProfileId,
    pagination: {
      ...filters,
    },
  });

  const loadMore = useCallback(() => {
    if (totalItems > (filters?.offset ?? 0)) {
      dispatch(
        mergeFilters({
          limit: 8,
          offset: +(filters.offset ?? 0) + 8,
        })
      );
    }
  }, [dispatch, filters?.offset, totalItems]);

  useEffect(() => {
    if (data?.exposeRequests?.page) {
      batch(() => {
        dispatch(addOnlineExposes(data?.exposeRequests?.page));
        dispatch(setTotalItems(data?.exposeRequests?.pageData?.count));
        dispatch(setInitialLoader(false));
        dispatch(
          addMarkers(
            data?.exposeRequests?.page
              ?.filter((item) => item.status === ExposeStatus.Unlocked)
              ?.map((item, index) => ({
                longitude: item?.propertyDetails?.longitude ?? 0,
                latitude: item?.propertyDetails?.latitude ?? 0,
                category: 'property',
                marker: 'similarProperty',
                index,
              }))
          )
        );
      });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (
      propertyIndexForScrolling !== null &&
      propertyIndexForScrolling !== undefined &&
      virtuosoRef?.current
    ) {
      dispatch(scrollToProperty(null));
      try {
        setTimeout(() => {
          virtuosoRef?.current?.scrollToIndex({
            index: propertyIndexForScrolling,
            align: 'center',
            behavior: 'smooth',
          });
        }, 0);
      } catch (error) {
        console.error('Error ->', error);
      }
    }
  }, [dispatch, propertyIndexForScrolling]);

  const ItemWithShrinkProps = useMemo(() => {
    return ({ children, ...props }: { children?: ReactNode }) => {
      return (
        <ItemContainer
          isShrinked={isShrinked}
          isMobileSize={isMobileSize}
          {...props}
        >
          {children}
        </ItemContainer>
      );
    };
  }, [isMobileSize, isShrinked]);

  const onClickOpenOverlay = useCallback(
    (
      propertyId: string,
      externalPropertyId: string,
      propertyCode: PropertyCode,
      financingId?: string
    ) => {
      batch(() => {
        if (financingId) {
          dispatch(setFinancingId(financingId));
        }
        dispatch(setSelectedPropertyId(propertyId));
        dispatch(setExternalPropertyId(externalPropertyId));
        dispatch(setSelectedPropertyCode(propertyCode));
        dispatch(toggleExposeOverlay(true));
        dispatch(setIsAppointmentSelectOpen(false));
        dispatch(setIsOpenHeadAccordion(true));
        dispatch(setActiveTab(0));
      });
    },
    [dispatch]
  );

  const itemContent = useCallback(
    (index: number) => (
      <OnlineExposeCard
        onClickOpenOverlay={() =>
          onClickOpenOverlay(
            String(onlineExposes?.[index]?._id) ?? '',
            String(onlineExposes?.[index]?.propertyId) ?? '',
            getPropertyCodeFromType(
              onlineExposes?.[index]?.propertyDetails?.type ?? ''
            ),
            onlineExposes?.[index]?.financingId ?? ''
          )
        }
        id={onlineExposes?.[index]?.propertyDetails?.externalId ?? ''}
        exposeId={onlineExposes?.[index]?._id ?? ''}
        propertyId={onlineExposes?.[index]?.propertyDetails?.propertyId ?? 0}
        title={onlineExposes?.[index]?.propertyDetails?.title ?? ''}
        price={onlineExposes?.[index]?.propertyDetails?.price ?? 0}
        currency={onlineExposes?.[index]?.propertyDetails?.currency ?? ''}
        numberOfRooms={
          onlineExposes?.[index]?.propertyDetails?.numberOfRooms ?? 0
        }
        livingArea={onlineExposes?.[index]?.propertyDetails?.livingArea ?? 0}
        landArea={onlineExposes?.[index]?.propertyDetails?.landArea ?? 0}
        imageSrc={
          `https://erp.von-poll.com/import/images/${onlineExposes?.[index]?.propertyDetails?.image[0].name}` ??
          ''
        }
        city={onlineExposes?.[index]?.propertyDetails?.city ?? ''}
        address={onlineExposes?.[index]?.fullAddress ?? ''}
        postCode={onlineExposes?.[index]?.propertyDetails?.postalCode ?? ''}
        regionalAddition={
          onlineExposes?.[index]?.propertyDetails?.regionalAddition ?? ''
        }
        isListView={!isMobileSize && !isShrinked}
        code={getPropertyCodeFromType(
          onlineExposes?.[index]?.propertyDetails?.type ?? ''
        )}
        status={onlineExposes?.[index]?.status}
        coordinates={{
          latitude: onlineExposes?.[index]?.propertyDetails?.latitude ?? 0,
          longitude: onlineExposes?.[index]?.propertyDetails?.longitude ?? 0,
        }}
        type={onlineExposes?.[index]?.propertyDetails?.type}
        buildingYear={
          onlineExposes?.[index]?.propertyDetails?.condition?.buildingYear ?? ''
        }
        modernizationYear={
          onlineExposes?.[index]?.propertyDetails?.condition
            ?.modernizationYear ?? ''
        }
        floor={onlineExposes?.[index]?.propertyDetails?.address?.floor}
        numberOfFloors={
          onlineExposes?.[index]?.propertyDetails?.address?.numberOfFloors ?? 0
        }
        numberOfBedrooms={
          onlineExposes?.[index]?.propertyDetails?.area?.numberOfBedrooms
        }
        numberOfBathrooms={
          onlineExposes?.[index]?.propertyDetails?.area?.numberOfBathrooms
        }
        numberOfGarages={
          onlineExposes?.[index]?.propertyDetails?.equipment?.numberOfGarages
        }
        provision={
          onlineExposes?.[index]?.propertyDetails?.equipment?.provision
        }
        numberOfParkingSpaces={
          onlineExposes?.[index]?.propertyDetails?.area?.numberOfParkingSpaces
        }
        numberOfDuplexParkingSpaces={
          onlineExposes?.[index]?.propertyDetails?.equipment
            ?.numberOfDuplexParkingSpaces || 0
        }
        numberOfUndergroundGarages={
          onlineExposes?.[index]?.propertyDetails?.equipment
            ?.numberOfUndergroundGarages || 0
        }
        numberOfOtherParkingSpaces={
          onlineExposes?.[index]?.propertyDetails?.equipment
            ?.numberOfOtherParkingSpaces || 0
        }
        numberOfParkingSpace={
          onlineExposes?.[index]?.propertyDetails?.equipment
            ?.numberOfParkingSpace || 0
        }
        isPlot={onlineExposes?.[index]?.propertyDetails?.type === 'grundstueck'}
        pdf={onlineExposes?.[index]?.pdf ?? ''}
        financingId={onlineExposes?.[index]?.financingId ?? ''}
      />
    ),
    [isMobileSize, isShrinked, onClickOpenOverlay, onlineExposes]
  );

  if (initialLoader || isLoading) {
    return (
      <LoaderContainer isLoadingMore>
        <ValuationLoader maxWidth="500px" />
      </LoaderContainer>
    );
  }

  return (
    <>
      <Header isShrinked={isShrinked}>
        <FlexContainer>
          <Title content={t('search-profile.online-exposes.title')} />
          <Count>{onlineExposes?.length}</Count>
        </FlexContainer>
      </Header>

      {data?.exposeRequests?.pageData.count === 0 ||
      onlineExposes.length === 0 ? (
        <EmptyStatePlaceholder />
      ) : (
        <>
          <Virtuoso
            useWindowScroll
            totalCount={onlineExposes?.length}
            overscan={1000}
            ref={virtuosoRef}
            components={{
              Item: ItemWithShrinkProps,
              List: ListContainer,
            }}
            itemContent={itemContent}
          />
          {totalItems > onlineExposes?.length && (
            <LoadMoreButtonContainer>
              <SecondaryButton
                fluid
                onClick={loadMore}
                label={t('button.load-more')}
                color={themeContext.blue}
                borderColor={themeContext.blue}
              />
            </LoadMoreButtonContainer>
          )}
        </>
      )}

      <ExposeDetailsOverlay />
    </>
  );
};

const OnlineExposesContainer = memo(OnlineExposesContainerBase);

export { OnlineExposesContainer };
