import { useTranslation } from 'react-i18next';

import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { PropertyCondition, PropertyQuality } from '../../../../generated';
import { defaultTheme } from '../../../../style/theme';
import {
  Bathroom2Icon,
  FloorIcon,
  KitchenIcon,
  StarIcon,
  WallIcon,
  WindowIcon,
  OverallIcon,
} from '../icons';

interface IProps {
  condition?: PropertyCondition;
  quality?: PropertyQuality;
}

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '12px 0',
    height: '70mm',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '10mm',
  },
  left: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    flexDirection: 'row',
    borderWidth: '1px',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderColor: '#d9d9d9',
    borderStyle: 'solid',
    height: '10mm',
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    flex: '1 0 38%',
    maxWidth: '38%',
  },
  rightTopBorder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    flex: '1 0 38%',
    maxWidth: '38%',
  },
  starWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 0 50%',
    maxWidth: '50%',
    overflow: 'hidden',
    borderWidth: '0.5mm',
    borderColor: '#d9d9d9',
    borderStyle: 'solid',
    borderBottomWidth: 0,
    // padding: '8px',
    fontSize: '10px',
    color: defaultTheme.blue,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    fontWeight: 'bold',
    height: '10mm',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '2px',
    width: '100%',
    overflow: 'hidden',
    height: '6mm',
  },
  text: {
    color: '#00305e',
    fontSize: '10px',
    lineHeight: 1.4,
    height: '8mm',
    marginLeft: '2mm',
    marginTop: '4mm',
  },
  heading: {
    color: '#00305e',
    opacity: 0.5,
    fontSize: '8px',
    fontWeight: 'heavy',
    lineHeight: 1.5,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },
});

const Stars = ({ input }: { input: string }) => {
  if (input === 'new_or_recently_renovated') {
    return (
      <>
        <StarIcon filled />
        <StarIcon filled />
        <StarIcon filled />
      </>
    );
  }
  if (input === 'well_maintained') {
    return (
      <>
        <StarIcon filled />
        <StarIcon filled />
        <StarIcon />
      </>
    );
  }
  if (input === 'renovation_needed') {
    return (
      <>
        <StarIcon filled />
        <StarIcon />
        <StarIcon />
      </>
    );
  }
  if (input === 'luxury') {
    return (
      <>
        <StarIcon filled />
        <StarIcon filled />
        <StarIcon filled />
        <StarIcon filled />
      </>
    );
  }
  if (input === 'high_quality') {
    return (
      <>
        <StarIcon filled />
        <StarIcon filled />
        <StarIcon filled />
        <StarIcon />
      </>
    );
  }
  if (input === 'normal') {
    return (
      <>
        <StarIcon filled />
        <StarIcon filled />
        <StarIcon />
        <StarIcon />
      </>
    );
  }
  return (
    <>
      <StarIcon filled />
      <StarIcon />
      <StarIcon />
      <StarIcon />
    </>
  );
};

const Calculator = ({ condition, quality }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const qk = Object.keys(quality as { [key: string]: string | null }).filter(
    (qualityKey) =>
      (quality as { [key: string]: string | null })[qualityKey] !== null
  );
  return (
    <View style={styles.wrapper}>
      <View style={styles.row}>
        <View style={styles.rightTopBorder}>
          <View style={[styles.starWrapper, { borderTopLeftRadius: '1mm' }]}>
            <Text style={styles.heading}>Qualität</Text>
          </View>
          <View
            style={[
              styles.starWrapper,
              { borderLeftWidth: 0, borderTopRightRadius: '1mm' },
            ]}
          >
            <Text style={styles.heading}>Bauzustand</Text>
          </View>
        </View>
      </View>
      {qk.map((qualityKey, index) => (
        <View style={styles.row} key={index}>
          <View
            style={[
              styles.left,
              {
                borderBottomWidth: index === qk.length - 1 ? '1px' : 0,
                borderTopLeftRadius: index === 0 ? '1mm' : 0,
                borderBottomLeftRadius: index === qk.length - 1 ? '1mm' : 0,
              },
            ]}
          >
            {qualityKey === 'bathrooms' && <Bathroom2Icon />}
            {qualityKey === 'flooring' && <FloorIcon />}
            {qualityKey === 'kitchen' && <KitchenIcon />}
            {qualityKey === 'windows' && <WindowIcon />}
            {qualityKey === 'masonry' && <WallIcon />}
            {qualityKey === 'overall' && <OverallIcon />}
            <Text style={styles.text}>{t(qualityKey)}</Text>
          </View>
          <View style={styles.right}>
            <View
              style={[
                styles.starWrapper,
                { borderBottomWidth: index === qk.length - 1 ? '1px' : 0 },
              ]}
            >
              <View style={styles.row}>
                <View style={styles.iconWrapper}>
                  <Stars
                    input={(quality as { [key: string]: string })[qualityKey]}
                  />
                </View>
              </View>
            </View>
            <View
              style={[
                styles.starWrapper,
                {
                  borderLeftWidth: 0,
                  borderBottomWidth: index === qk.length - 1 ? '1px' : 0,
                  borderBottomRightRadius: index === qk.length - 1 ? '1mm' : 0,
                },
              ]}
            >
              <View style={styles.row}>
                <View style={styles.iconWrapper}>
                  <Stars
                    input={(condition as { [key: string]: string })[qualityKey]}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      ))}
    </View>
  );
};

export { Calculator };
