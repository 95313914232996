import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';
import { DEBUG_MODE } from '../modules/common/constants';
import { allSupportedLanguages } from '../modules/localization/constants';

const urlParams = new URLSearchParams(window.location.search);
const phraseEnabled = urlParams.get(DEBUG_MODE) === 'true';
// Disable check until env's sorted
// && process.env.NODE_ENV !== 'production';

export const fallbackLng = 'de';

const backend = {
  loadPath: `/locales/{{lng}}.json?version=${Date.now()}`,
  crossDomain: true,
};

i18next
  .use(initReactI18next)
  .use(HttpApi) // Registering the back-end plugin
  .use(LanguageDetector) // Registering the detection plugin
  .use(
    new PhraseInContextEditorPostProcessor({
      phraseEnabled,
      projectId: process.env.REACT_APP_PHRASE_PROJECT_ID ?? '',
    })
  )
  .init({
    backend,
    nonExplicitSupportedLngs: true,
    fallbackLng,
    supportedLngs: allSupportedLanguages,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    debug: phraseEnabled,
    postProcess: ['phraseInContextEditor'],
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
    },
  })
  .catch((error: Error) => {
    console.error(error);
  });

export default i18next;
