import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { ReactComponent as ArrowButtonLeftIcon } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as ArrowButtonRightIcon } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-right-1.svg';
import { ReactComponent as CloseIcon } from 'assets/streamline-light/interface-essential/form-validation/close.svg';

import { useTranslation } from 'react-i18next';
import {
  Container,
  IconContainer,
  ShowAllVariantsButton,
  VariantBox,
  VariantCheckbox,
  VariantContainer,
} from './financing-variants-content-header-styles';
import { Tooltip } from '../../../multiple-variants-item';
import Icon from '../../../../../../common/components/ui/icon';
import { useHiddenVariants } from '../../../../../hooks/useHiddenVariants';
import { TooltipConfirmation } from '../../../../../../common/components/tooltip/TooltipConfirmation';

interface IProps {
  from: number;
  isRight: boolean;
  onChangeFrom: (value: number) => void;
  names: string[];
  indexes: number[];
}

interface ItemProps {
  label: string;
  index: number;
  indexEntity: number;
  isTwoVariants: boolean;
  onHideVariant(i: number): void;
  onBeforeHideVariant(i?: number): void;
}

const VariantItem: FC<ItemProps> = ({
  label,
  index,
  indexEntity,
  isTwoVariants,
  onHideVariant,
  onBeforeHideVariant,
}) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <>
      <Tooltip text={label}>
        <VariantBox>{label}</VariantBox>
      </Tooltip>
      {isTwoVariants ? (
        <Tooltip
          text={t(
            'financing-portal-page.variants-compare.modal.checkbox.disabled.hover'
          )}
        >
          <VariantCheckbox isDisabled>
            <Icon
              width={12}
              height={12}
              icon={CloseIcon}
              color={theme.ctaDisabledBackground}
            />
          </VariantCheckbox>
        </Tooltip>
      ) : (
        <TooltipConfirmation
          tabletWidth="100%"
          maxWidth="348px"
          headerStyle={{ fontWeight: 400, fontSize: '14px' }}
          headerText={
            'Sind Sie sicher, dass Sie diese Variante aus dem Variantenvergleich entfernen möchten?'
          }
          backText={t('financing-delete.tooltip.back')}
          acceptText="Ja, bitte die Variante entfernen"
          id={`variant ${indexEntity} delete`}
          onBeforeDelete={() => onBeforeHideVariant(index)}
          onDelete={() => {
            onHideVariant(indexEntity);
            onBeforeHideVariant();
          }}
          onClickOutside={() => onBeforeHideVariant()}
        >
          <VariantCheckbox>
            <Icon width={12} height={12} icon={CloseIcon} color={theme.blue} />
          </VariantCheckbox>
        </TooltipConfirmation>
      )}
    </>
  );
};

const FinancingVariantsContentHeader: FC<IProps> = ({
  from,
  isRight,
  onChangeFrom,
  names,
  indexes,
}) => {
  const {
    hiddenVariants,
    onHideVariant,
    onShowAllVariants,
    onSetWillBeHiddenVariant,
  } = useHiddenVariants();
  const isTwoVariants = names.length < 3;

  return (
    <Container>
      <div>
        <div>
          {hiddenVariants.length > 0 && (
            <ShowAllVariantsButton
              label="Alle Varianten zeigen"
              onClick={onShowAllVariants}
            />
          )}
        </div>
        {indexes.length > 4 && (
          <div>
            <IconContainer
              isDisabled={from === 0}
              onClick={() => (from === 0 ? false : onChangeFrom(from - 1))}
            >
              <Icon icon={ArrowButtonLeftIcon} width={8} height={8} />
            </IconContainer>
            <IconContainer
              isDisabled={isRight}
              onClick={() => (!isRight ? onChangeFrom(from + 1) : false)}
            >
              <Icon icon={ArrowButtonRightIcon} width={8} height={8} />
            </IconContainer>
          </div>
        )}
      </div>
      {names.map((item, i) => (
        <VariantContainer key={item}>
          <VariantItem
            index={i}
            indexEntity={indexes[i]}
            onHideVariant={onHideVariant}
            onBeforeHideVariant={onSetWillBeHiddenVariant}
            isTwoVariants={isTwoVariants}
            label={item}
          />
        </VariantContainer>
      ))}
    </Container>
  );
};

export { FinancingVariantsContentHeader };
