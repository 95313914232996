import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Europe } from '../constants/points-of-interest';
import { IMapPin, IMapSettings } from '../interfaces';
import { defaultMapSettings } from '../constants';

interface Popup {
  latitude: number;
  longitude: number;
  info?: string;
}

type InitialState = {
  viewport: Record<string, unknown>;
  markers: IMapPin[];
  settings: IMapSettings;
  popup?: Popup;

  viewportCheckpoint?: Record<string, unknown>;
  markersCheckpoint?: IMapPin[];
  settingsCheckpoint?: IMapSettings;
};

const initialState: InitialState = {
  viewport: { ...Europe },
  settings: { ...defaultMapSettings },
  markers: [],
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    changeViewport(state, action) {
      state.viewportCheckpoint = state.viewport;
      state.viewport = { ...state.viewport, ...action.payload };
    },
    addMarkers(state, action: PayloadAction<IMapPin[]>) {
      state.markers = [...state.markers, ...action.payload];
    },
    removeMarkers(state, action) {
      state.markers = state.markers.filter(
        (m) => m.category !== action.payload
      );
    },
    removeAllMarkers(state) {
      state.markers = [];
    },
    setMarkers(state, action: PayloadAction<IMapPin[]>) {
      state.markersCheckpoint = state.markers;
      state.markers = action.payload;
    },
    setPopup(state, action: PayloadAction<Popup>) {
      state.popup = action.payload;
    },
    resetPopup(state) {
      state.popup = initialState.popup;
    },
    setSettings(state, action: PayloadAction<Partial<IMapSettings>>) {
      state.settingsCheckpoint = state.settings;
      state.settings = {
        ...state.settings,
        ...action.payload,
      };
    },
    removeAllMarkersExceptHome(state) {
      state.markers = state.markers.filter((marker) => !marker.category);
    },
    restoreCheckpoints(state) {
      if (state.viewportCheckpoint) {
        state.viewport = state.viewportCheckpoint;
      }
      if (state.markersCheckpoint) {
        state.markers = state.markersCheckpoint;
      }
      if (state.settingsCheckpoint) {
        state.settings = state.settingsCheckpoint;
      }
    },
  },
});

export const {
  changeViewport,
  addMarkers,
  removeMarkers,
  removeAllMarkers,
  setMarkers,
  setSettings,
  removeAllMarkersExceptHome,
  restoreCheckpoints,
  setPopup,
  resetPopup,
} = mapSlice.actions;

export const { name, reducer } = mapSlice;

export default reducer;
