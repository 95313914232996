import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const YearOfConstructionIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={
          'M13.5 23.36H.5m8 0h-3m-2.82 0v-5a1.277 1.277 0 0 1 .374-.832L5.5 15.36h3l2.315 2.159a1.32 1.32 0 0 1 .365.841v5M8 5.36c.275 0 .724.022 1 .05l14.5 1.45v1a.5.5 0 0 1-.5.5H1a.5.5 0 0 1-.5-.5v-1L5.021 5.5A4.1 4.1 0 0 1 6 5.36z'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={
          'm.5 6.86 6.267-5.819a1.714 1.714 0 0 1 1.659-.3L23.5 6.86m-3 1.5v6.708a1 1 0 0 0 .6.917A1.5 1.5 0 1 1 19 17.36m-13.5-7 3 3m0 5-3 3m7-15.6v2.6M7.5.682V5.36m1 .015V23.36m-3 0V5.403'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { YearOfConstructionIcon };
