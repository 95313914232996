import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ValuationType,
  RpSection,
  Benefits as BenefitsType,
  useReadBenefitQuery,
  BuyerLpSection,
  useLazyBenefitsByIdsQuery,
  BenefitSection,
  SellerLpSection,
  OverviewLpSection,
  FinancingLpSection,
  OwnerLpSection,
} from '../../../../generated';
import { MainButton } from '../../../common/components/ui/buttons';
import { ReactComponent as EyeIcon } from '../../../../assets/streamline-light/interface-essential/view/view.svg';
import {
  Container,
  ContentContainer,
  ImageBox,
  InfoBox,
  Title,
  Description,
  BoxButtons,
  LinkWrapper,
  LinkBox,
  LinkIconWrapper,
  StyledImg,
  HeaderContainer,
  Headline,
  SliderContainer,
  Content,
} from './benefit-styles';
import { Slider } from '../slider';
import regionalBenefitImg from './regional-benefit.png';

interface IProps {
  section:
    | RpSection
    | BuyerLpSection
    | SellerLpSection
    | OverviewLpSection
    | FinancingLpSection
    | OwnerLpSection;
  ctaHandler?: () => void;
  handleEvaluationWizardOpen?: (type: ValuationType) => void;
  isSlider?: boolean;
  revertContent?: boolean;
  isStatic?: boolean;
  reduceToken?: boolean;
}

interface BenefitProps {
  id: string;
  revertContent?: boolean;
  ctaHandler?: () => void;
  handleEvaluationWizardOpen?: (type: ValuationType) => void;
  isStatic?: boolean;
}

interface BenefitResponse {
  data: { data: BenefitsType };
  isLoading: boolean;
}

const BenefitItem = ({
  id,
  revertContent,
  ctaHandler,
  handleEvaluationWizardOpen,
  isStatic,
}: BenefitProps) => {
  const { data, isLoading } = useReadBenefitQuery<BenefitResponse>({
    id,
  });

  const imageBoxRef = useRef(null);
  const infoBoxRef = useRef(null);

  const [marginBottom, setMarginBottom] = useState(88);

  const marginHandler = () => {
    const { current: currentImageBox } = imageBoxRef;
    const { current: currentInfoBox } = infoBoxRef;

    if (currentImageBox && currentInfoBox) {
      const { clientHeight: imageBoxHeight } =
        currentImageBox || ({} as { clientHeight: number });
      const { clientHeight: infoBoxHeight } =
        currentInfoBox || ({} as { clientHeight: number });

      if (imageBoxHeight && infoBoxHeight) {
        setMarginBottom(infoBoxHeight + 116 - imageBoxHeight + 88);
      }
    }
  };

  useEffect(() => {
    marginHandler();
  }, [isLoading]);

  if (isLoading) return null;

  const benefit = data?.data;

  const text = benefit?.text?.replace(/(<br>)*(&nbsp;)*/g, '') || '';

  return (
    <ContentContainer
      isStatic={isStatic}
      marginBottom={isStatic ? 0 : marginBottom}
    >
      <ImageBox
        isStatic={isStatic}
        revertContent={revertContent}
        ref={imageBoxRef}
      >
        <StyledImg
          isStatic={isStatic}
          onLoad={marginHandler}
          key={benefit?.imageKey as string}
          src={isStatic ? regionalBenefitImg : (benefit?.imageUrl as string)}
          alt={benefit?.imageAlt as string}
        />
      </ImageBox>
      <InfoBox
        isStatic={isStatic}
        revertContent={revertContent}
        ref={infoBoxRef}
      >
        <Title
          isStatic={isStatic}
          data-swiper-parallax="0"
          content={benefit?.title as string}
        />
        <Description
          isStatic={isStatic}
          data-swiper-parallax="0"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <BoxButtons>
          <MainButton
            data-swiper-parallax="0"
            label={benefit?.widgetButtonLabel as string}
            onClick={
              ctaHandler ||
              (() =>
                handleEvaluationWizardOpen &&
                handleEvaluationWizardOpen(ValuationType.Online))
            }
          />
          <LinkWrapper
            target="_blank"
            rel="noreferrer"
            isWithoutLng
            link={benefit?.secondaryButtonLink as string}
          >
            <LinkIconWrapper
              isStatic={isStatic}
              icon={EyeIcon}
              width={20}
              height={20}
            />
            <LinkBox
              isStatic={isStatic}
              content={benefit?.secondaryButtonLabel as string}
            />
          </LinkWrapper>
        </BoxButtons>
      </InfoBox>
    </ContentContainer>
  );
};

const Benefits: FC<IProps> = ({
  section,
  ctaHandler,
  handleEvaluationWizardOpen,
  isSlider = false,
  revertContent = false,
  reduceToken,
  isStatic,
}) => {
  const { recordIds, title } = section;

  const [getBenefitsByIds, { data, isLoading }] = useLazyBenefitsByIdsQuery();
  const { t } = useTranslation();

  useEffect(() => {
    if (isSlider && !isLoading && !data) {
      getBenefitsByIds({ _ids: recordIds.length > 0 ? recordIds : [] });
    }
  }, [data, getBenefitsByIds, isLoading, isSlider, recordIds]);

  if (isLoading) return null;

  const sections = data?.data as unknown as BenefitSection[];

  return (
    <Content isSlider={isSlider} isStatic={isStatic}>
      {isSlider ? (
        sections &&
        !isLoading && (
          <SliderContainer>
            <Slider
              handleEvaluationWizardOpen={handleEvaluationWizardOpen}
              sections={sections}
              ctaHandler={ctaHandler}
              sliderHeader={t('buyer-landing-page.benefits-slider.title')}
              headline={title}
              reduceToken={reduceToken}
              isMobileRedesign
            />
          </SliderContainer>
        )
      ) : (
        <Container>
          <HeaderContainer isStatic={isStatic}>
            <Headline content={title} />
          </HeaderContainer>
          {recordIds.map((id: string) => {
            return (
              <BenefitItem
                key={id}
                id={id}
                isStatic={isStatic}
                revertContent={revertContent}
                ctaHandler={ctaHandler}
                handleEvaluationWizardOpen={handleEvaluationWizardOpen}
              />
            );
          })}
        </Container>
      )}
    </Content>
  );
};

export { Benefits };
