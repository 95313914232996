import {
  PropertyCode,
  PropertyFilterInput,
  PropertyPortfolioFieldsFragment,
  PropertyStatus,
  UserStatus,
} from 'generated';
import {
  energyLabelOptions,
  energyLabelOptionsSwitzerland,
} from 'modules/forms/form-property-valuation-wizard-v2/constants';
import { IPropertyPortfolioFilterFormField } from '../interfaces';
import { buildPropertyTitle } from 'modules/seller/utils/buildPropertyTitle';

type EnergyLabelOption = {
  label: string;
  value: string;
};

export const getEnergyLabel = (
  energyLabel: string | undefined,
  countryCode: string
): string => {
  const options =
    countryCode === 'CH'
      ? <EnergyLabelOption[]>energyLabelOptionsSwitzerland
      : <EnergyLabelOption[]>energyLabelOptions;
  const option = options.find((item) => item.value === energyLabel);

  if (!option) {
    return '';
  }

  return option.label;
};

export const getCoordinates = (location?: {
  coordinates?: {
    latitude?: number;
    longitude?: number;
  };
}): {
  latitude: number;
  longitude: number;
} => {
  return {
    latitude: location?.coordinates?.latitude ?? 0,
    longitude: location?.coordinates?.longitude ?? 0,
  };
};

export const getPropertyTitle = (
  property: PropertyPortfolioFieldsFragment
): string => {
  if (property.status === PropertyStatus.Selling) {
    return (
      property.title ||
      buildPropertyTitle(
        property.propertyData.propertyType.code,
        property.propertyData.numberOfRooms ?? 0,
        property.propertyData.location.address.city,
        property.propertyData.livingArea
      )
    );
  }

  const ownerTitle =
    property.propertyPortfolioData?.title || property.title || '';
  const shareTitle =
    property.propertyPortfolioData?.shareTitle ||
    property.propertyPortfolioData?.title ||
    property.title ||
    '';

  return property.userStatus === UserStatus.Owner ? ownerTitle : shareTitle;
};

export const calculatePropertyPrice = (
  property: PropertyPortfolioFieldsFragment
): number => {
  const { status, valuations, valuationsLastSync, onOfficePrice } = property;

  let price = 0;

  const currentValuationIndex =
    valuations?.findIndex(
      (valuation) =>
        new Date(valuation.date).toDateString() ===
        new Date(valuationsLastSync).toDateString()
    ) ?? -1;

  const currentValuation = valuations?.[currentValuationIndex];

  if (
    status === PropertyStatus.Initial ||
    status === PropertyStatus.SellingSimulation
  ) {
    price = currentValuation?.valuation?.salePrice || 0;
  }

  if (status === PropertyStatus.Selling && onOfficePrice) {
    price = onOfficePrice;
  }

  return price;
};

export const toPropertyFilterInput = (
  filters: IPropertyPortfolioFilterFormField[]
): PropertyFilterInput => {
  return filters.reduce((acc, filter) => {
    const min = +(filter.type === 'input'
      ? filter.options?.[0]?.value || 0
      : 0);
    const max = +(filter.type === 'input'
      ? filter.options?.[1]?.value || 0
      : 0);

    const checkedOptions = filter.options?.filter((opt) => opt.checked) || [];

    switch (filter.prop) {
      case 'propertyType':
        acc.propertyCodes = checkedOptions.map(
          (option) => option.value as PropertyCode
        );
        break;

      case 'buildingYear':
        acc.minBuildingYear = min;
        acc.maxBuildingYear = max;
        break;

      case 'livingArea':
        acc.minLivingArea = min;
        acc.maxLivingArea = max;
        break;

      case 'landArea':
        acc.minLandArea = min;
        acc.maxLandArea = max;
        break;

      case 'numberOfRooms':
        acc.minNumberOfRooms = min;
        acc.maxNumberOfRooms = max;
        break;

      case 'numberOfBathrooms':
        acc.minNumberOfBathrooms = min;
        acc.maxNumberOfBathrooms = max;
        break;

      case 'numberOfIndoorParkingSpaces':
        acc.minNumberOfIndoorParkingSpaces = min;
        acc.maxNumberOfIndoorParkingSpaces = max;
        break;

      case 'numberOfOutdoorParkingSpaces':
        acc.minNumberOfOutdoorParkingSpaces = min;
        acc.maxNumberOfOutdoorParkingSpaces = max;
        break;

      case 'price':
        acc.minPrice = min;
        acc.maxPrice = max;
        break;

      case 'energy':
        acc.energyLabels = checkedOptions.map(
          (option) => option.value as string
        );
        break;

      default:
        break;
    }

    return acc;
  }, {} as PropertyFilterInput);
};
