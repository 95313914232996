import styled from 'styled-components';
import { device } from '../../../../../../../../style/theme';

export const Container = styled.div`
  display: flex;
  width: 100%;

  @media ${device.tablet} {
    justify-content: space-between;

    & > div:first-child {
      width: auto;
      flex-grow: 0;
    }
  }
`;

export const ValueWrapper = styled.div<{ isError?: boolean }>`
  opacity: 1;
  color: ${({ theme, isError }) => (isError ? theme.red : theme.blue)};
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 16px;
`;
