import styled from 'styled-components';

export const Indicator = styled.div`
  height: 20px;
  width: 100%;
  border-radius: 3px;
  position: relative;
  background-image: linear-gradient(
    to right,
    #45a34d 0%,
    #fffa3e 31%,
    #eb6f23 65%,
    #cf381a 100%
  );
`;

export const EnergyConsumptionIcon = styled.div<{
  color: string;
  left: string;
}>`
  background-color: ${({ color }) => color};
  width: 32px;
  height: 32px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 2px #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: -6px;
  left: ${({ left }) => left};
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.blue};
}
`;

interface IInfoPopupProps {
  left: string;
  width: string;
  leftArrowPX: number;
}

export const InfoPopup = styled.div<IInfoPopupProps>`
  position: absolute;
  z-index: 500;
  padding: 8px;
  box-shadow: 0 4px 10px 0 var(--black-9);
  border: solid 1px #d9d9d9;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.white};
  top: -51px;
  left: ${({ left, width }) => `calc(${left} - ${width}px)`};
  font-family: 'Source Serif Pro';
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  &:before {
    content: '';
    position: absolute;
    top: 32px;
    left: ${({ leftArrowPX }) => `${leftArrowPX - 8}px`};
    z-index: 502;
    border: solid 8px transparent;
    border-top-color: #fff;
  }
  &:after {
    z-index: 501;
    content: '';
    position: absolute;
    top: 32px;
    left: ${({ leftArrowPX }) => `${leftArrowPX - 10}px`};
    border: solid 10px transparent;
    border-top-color: #d9d9d9;
  }
`;

export const IconContainer = styled.div`
  position: relative;
  width: 94%;
  height: 20px;
`;
