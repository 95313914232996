const size = {
  mobileIframe: '460px',
  mobile: '768px',
  tablet: '992px',
  tabletSlider: '1150px',
  laptop: '1200px',
  bigScreen: '1450px',
};

export const device = {
  mobileIframe: `(max-width: ${size.mobileIframe})`,
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletSlider: `(max-width: ${size.tabletSlider})`,
  laptop: `(max-width: ${size.laptop})`,
  bigScreen: `(max-width: ${size.bigScreen})`,
};

const defaultTheme = {
  black: '#212529',
  logoBlue: '#002849',
  logoBlueLight: '#1A3E5C',
  subheaderBlue: '#193d5b',
  blue: '#00305E',
  gold: '#A28557',
  grey: '#A8A8A7',
  grey2: '#909294',
  white: '#ffffff',
  ctaBlue: '#4E73F5',
  ctaBlueHover: '#0F5DC4',
  red: '#A25757',
  lightRed: '#DFC8C8',
  paleRed: '#f5eeee',
  redBackground: '#F5EEEE',
  darkGreen: '#638A4A',
  green: '#74A257',
  green2: '#108E2D',
  green3: '#28A745',
  ctaGreenHover: '#28a745cc',
  lightBlue: '#c9d5fc',
  paleGreen: '#f1f5ee',
  lightGreen: '#d6e3cd',

  fileUploaderActiveHeader: '#F6F6F6',
  faqActiveHeader: '#D9E0E7',
  faqHeader: '#F2F4F7',
  faqContent: '#F7F9FA',
  regularText: '#002F5D',

  greyBackground: '#f2f5f7',
  ctaDisabledBackground: '#d9d9d9',
  borderFocus: '#7f97ae',
  borderColor: '#d9d9d9',
  transparent: 'transparent',

  tagGreenBorder: '#d5e3cc',
  tagGoldBorder: '#dfd6c8',
  tagGoldBackground: '#f0ede9',
  darkGrey: '#4d687f',

  priceStatusBest: '#D5E3CC',
  priceStatusMedium: '#F2DC92',
  priceStatusLow: '#D9B0B0',
};

export type Theme = typeof defaultTheme;
export { defaultTheme };
