import { useCallback } from 'react';
import { StatusType } from '../../../common/components/verification-input/types';
import {
  removeUserInput,
  setUserState,
  toggleLoading,
} from '../../../auth/redux/authSlice';
import { parseSearchProfileInput } from '../../utils/parse-search-profile-input';
import { ISearchProfileForm } from '../interface';
import { Feature } from '../../../../services/mapbox/interfaces';
import {
  LoginResponse,
  useConfirmPhoneMutation,
  useRequestVerificationCodeMutation,
  useSignInMutation,
} from '../../../../generated';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useCreateTemporarySP } from './useCreateTemporarySP';

type TProps = {
  setVerificationStatus: (state: StatusType) => void;
  setConfirmEmailSent: (state: boolean) => void;
  setErrors: (state: null | unknown) => void;
};

type TReturn = { onVerifyPhone: (confirmationCode: string) => Promise<void> };

const useVerifyPhoneSP = ({
  setVerificationStatus,
  setConfirmEmailSent,
  setErrors,
}: TProps): TReturn => {
  const dispatch = useAppDispatch();

  const userInput = useAppSelector((state) => state.auth.userInput);
  const {
    _id: userId,
    password,
    name: firstName,
    surname: lastName,
    username,
  } = userInput || {};
  const selectedAddress = useAppSelector(
    (state) => state.searchProfile.selectedAddress
  );

  const [signIn] = useSignInMutation();
  const [confirmPhone] = useConfirmPhoneMutation();
  const [requestVerificationCode] = useRequestVerificationCodeMutation();
  const { onCreateTemporarySP } = useCreateTemporarySP();

  const onVerifyPhone = useCallback(
    async (confirmationCode: string) => {
      setVerificationStatus(StatusType.wait);
      if (confirmationCode && username && password && firstName && lastName) {
        try {
          dispatch(toggleLoading(true));
          const confirmPhoneResult = await confirmPhone({
            input: {
              username,
              confirmationCode,
            },
          }).unwrap();

          // set user state VerifyEmail
          if (confirmPhoneResult?.confirmPhone) {
            dispatch(setUserState(confirmPhoneResult.confirmPhone));
          }

          const searchProfileInput = parseSearchProfileInput(
            userInput as ISearchProfileForm,
            selectedAddress as Feature,
            userId as string
          );

          const createTemporarySearchProfileResponse =
            await onCreateTemporarySP({
              spInput: searchProfileInput,
              ownerId: userId as string,
            });
          const createTemporarySearchProfileResp = JSON.parse(
            JSON.stringify(createTemporarySearchProfileResponse)
          );

          if (createTemporarySearchProfileResp?.error?.message) {
            throw new Error(createTemporarySearchProfileResp.error.message);
          }

          try {
            // Initiate login to get the jwt token
            const signInResponse = await signIn({
              input: {
                userAuthenticationKey: username,
                password,
                requestAccessToken: true,
              },
            }).unwrap();

            if (signInResponse.signIn) {
              const signInParams = signInResponse.signIn as LoginResponse;
              dispatch(removeUserInput());

              await requestVerificationCode({
                input: {
                  username,
                  accessToken: signInParams?.jwt?.token ?? '',
                  refreshToken: signInParams?.jwt?.refreshToken ?? '',
                  attributeName: 'email',
                  firstName,
                  lastName,
                },
              });
              setConfirmEmailSent(true);
            }
          } catch (error: unknown) {
            setErrors(error);
          }
        } catch (error: unknown) {
          setErrors(error);
          setVerificationStatus(StatusType.error);
        } finally {
          dispatch(toggleLoading(false));
        }
      }
    },
    [
      setVerificationStatus,
      username,
      password,
      firstName,
      lastName,
      dispatch,
      confirmPhone,
      userInput,
      selectedAddress,
      userId,
      onCreateTemporarySP,
      signIn,
      requestVerificationCode,
      setConfirmEmailSent,
      setErrors,
    ]
  );
  return { onVerifyPhone };
};

export { useVerifyPhoneSP };
