import { markLetters } from '../../../../constants';
import { getColorEnergyEfficiency } from '../../../../utils/get-color-energy-efficiency';

type Props = {
  finalEnergyDemand: number;
  energyEfficiencyClass?: string | null;
};

type TReturn = {
  widthPercent: number;
  letter?: string;
  color?: string;
};

const useGetEnergyEfficiencyData = ({
  finalEnergyDemand,
  energyEfficiencyClass,
}: Props): TReturn => {
  let widthPercent = 0;
  let letter: string | undefined = '';

  if (finalEnergyDemand) {
    widthPercent = (finalEnergyDemand / 265) * 100;
    letter = markLetters.find((item) => {
      if (finalEnergyDemand > 250 && !item.max) {
        return item;
      }
      if (
        item.min <= finalEnergyDemand &&
        item.max !== null &&
        item.max > finalEnergyDemand
      ) {
        return item;
      }
      return false;
    })?.letter;
  } else if (energyEfficiencyClass) {
    letter = energyEfficiencyClass;
    const letterObject = markLetters.find((item) => {
      if (item.letter === letter) {
        return item;
      }
      return false;
    });
    if (letterObject?.max && letterObject?.min) {
      widthPercent =
        (((letterObject?.max + 5 - letterObject?.min) / 2 + letterObject?.min) /
          265) *
        100;
    }
  }

  const { color } = getColorEnergyEfficiency(widthPercent);
  return {
    widthPercent,
    letter,
    color,
  };
};

export { useGetEnergyEfficiencyData };
