import 'mapbox-gl/dist/mapbox-gl.css';

import mapPin from 'assets/svg/map-pin.svg';
import { FC, memo, useEffect, useRef, useState } from 'react';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';

import { useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { CommuteTime } from '../../../../../map/components/commute-time';
import { GeoLocateController } from '../../../../../map/components/geolocate-controller';
import { Legend } from '../../../../../map/components/legend';
import { Pins } from '../../../../../map/components/pins';
import { ReachabilityLegend } from '../../../../../map/components/reachability-legend';
import { StyledPopup } from '../../../../../search-profile/pages/expose-details-overlay/components/mapbox/mapbox-styles';
import { Container, ControlsContainer, MarkerImg } from './mapbox-styles';

const MapboxExposeDetailsBase: FC = () => {
  const reachabilityMarkers = useAppSelector(
    (state) => state.exposeDetails.map.markers
  );
  const { viewport, settings, markers } = useAppSelector(
    (state) => state.sellerExposeDetails.map
  );
  const { popup } = useAppSelector((state) => state.map);
  const {
    showLegend,
    showReachabilityLegend,
    commuteTimeCoordinates,
    isReachabilityTab,
  } = useAppSelector((state) => state.dynamicMapUtils);

  const isMobileSize = useIsMobileSize();

  const { longitude, latitude } = viewport;

  const [innerViewport, setInnerViewport] =
    useState<Record<string, unknown>>(viewport);

  const onViewportChange = (newViewport: Record<string, unknown>) => {
    setInnerViewport(newViewport);
  };

  useEffect(() => {
    setInnerViewport(viewport);
  }, [viewport]);

  const mapStyle = `mapbox://styles/mapbox/${
    isReachabilityTab && !commuteTimeCoordinates
      ? 'navigation-day-v1'
      : 'light-v9'
  }?optimize=true`;

  const mapboxContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapboxContainerRef.current) {
      return () => {};
    }

    const mapContainer = mapboxContainerRef.current;

    const wheelHandler = (e: Event) => {
      const wheelEvent = e as WheelEvent;

      if (wheelEvent.ctrlKey || wheelEvent.metaKey || wheelEvent.altKey) {
        return;
      }

      e.stopPropagation();
    };

    mapContainer.addEventListener('wheel', wheelHandler, true);

    return () => {
      mapContainer.removeEventListener('wheel', wheelHandler);
    };
  });

  return (
    <Container ref={mapboxContainerRef}>
      {!isMobileSize && showLegend && <Legend />}
      {showReachabilityLegend && <ReachabilityLegend />}
      <ReactMapGL
        width="100%"
        height={isMobileSize ? '160px' : '640px'}
        {...innerViewport}
        {...settings}
        getCursor={() => 'default'}
        onViewportChange={onViewportChange}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY ?? ''}
        mapStyle={mapStyle}
        asyncRender
        style={{ color: 'transparent' }}
      >
        <Pins
          markers={[
            ...markers,
            ...(isReachabilityTab ? reachabilityMarkers : []),
          ]}
        />
        <ControlsContainer>
          <GeoLocateController />
          <NavigationControl />
        </ControlsContainer>
        <Marker
          longitude={(longitude as number) || 0}
          latitude={(latitude as number) || 0}
        >
          <MarkerImg src={mapPin} />
        </Marker>
        {commuteTimeCoordinates && (
          <CommuteTime commuteTimeCoordinates={commuteTimeCoordinates} />
        )}
        {popup?.info && (
          <StyledPopup
            latitude={popup.latitude}
            longitude={popup.longitude}
            anchor="bottom"
            closeButton={false}
            offsetTop={-32}
          >
            {popup.info}
          </StyledPopup>
        )}
      </ReactMapGL>
    </Container>
  );
};

const MapboxExposeDetails = memo(MapboxExposeDetailsBase);

export { MapboxExposeDetails };
