import { device } from 'style/theme';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RealEstateDimensionsBlock } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as AccountingBills } from 'assets/streamline-light/money-payments-finance/money/accounting-bills-1.svg';
import { ReactComponent as LocationMarker } from 'assets/streamline-light/maps-navigation/pins/pin.svg';
import { ReactComponent as RealEstateDimensionsPlan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-plan.svg';
import { ReactComponent as User } from 'assets/streamline-light/users/natural-close-up-single-user-neutral/single-neutral-home.svg';
import { ReactComponent as Check } from 'assets/streamline-light/interface-essential/form-validation/check-1.svg';
import { ReactComponent as Plus } from 'assets/streamline-light/interface-essential/remove-add/add.svg';
import { ReactComponent as Minus } from 'assets/streamline-light/interface-essential/remove-add/subtract.svg';

import { extractPropertyTypeLabelAndIcon } from 'modules/property/utils/property-type-normalizer';

import { PropertyCode } from 'generated';
import Icon from 'modules/common/components/ui/icon';
import { useCallback, useContext, useRef } from 'react';
import { useToLocalWithCurrency } from '../../../../../common/utils/convertCHF';
import PropertyValuationIcon from '../../../icon/property-valuation-icon';
import {
  Card,
  Tag,
  Wrapper,
  Handler,
  Head,
  HeadIcon,
  HeadContent,
  IconContainer,
  IconRow,
  Address,
  Details,
  HeadTitle,
  HeadSubTitle,
  Location,
  LocationTitle,
  AddressLabel,
  TooltipTitle,
  IconItem,
  IconLabel,
} from './buyer-finder-card-styles';
import { Tooltip, TooltipHandlers } from 'modules/common/components/tooltip';

interface IProps {
  i: number;
  isFavorite?: boolean;
  isViewRightsOnly?: boolean;
  _id: string;
  searchProfileData: {
    location?: string | null;
    radius?: number;
    purchasePriceMax?: number | null;
    numberOfRoomsMin?: number | null;
    livingAreaMin?: number | null;
    landAreaMin?: number | null;
    propertyCode?: PropertyCode;
  };
  onChangeFavoriteSP: (searchProfileId: string) => void;
}

export const BuyerFinderCard = ({
  i,
  isFavorite,
  isViewRightsOnly,
  _id,
  searchProfileData,
  onChangeFavoriteSP,
}: IProps) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const { icon, label } = extractPropertyTypeLabelAndIcon(
    searchProfileData.propertyCode
  );

  const isShowNumberOfRooms =
    searchProfileData.propertyCode !== PropertyCode.MultiFamilyHouse &&
    searchProfileData.propertyCode !== PropertyCode.Plot;
  const isShowLivingSpace =
    searchProfileData.propertyCode !== PropertyCode.Plot;
  const isShowLandArea = searchProfileData.propertyCode === PropertyCode.Plot;

  const tooltipRef = useRef<TooltipHandlers>(null);

  const handleClick = useCallback(() => {
    if (isViewRightsOnly) {
      if (tooltipRef.current) {
        tooltipRef.current.show();
        setTimeout(() => {
          tooltipRef.current!.hide();
        }, 2000);
      }
    } else {
      onChangeFavoriteSP(_id);
    }
  }, [tooltipRef, onChangeFavoriteSP, isViewRightsOnly]);

  const localPurchasePriceMax = useToLocalWithCurrency(
    searchProfileData.purchasePriceMax || 0
  );

  return (
    <Card selected={isFavorite} onClick={handleClick}>
      <Wrapper>
        <Head>
          <div>
            <HeadIcon>
              <Icon
                icon={User}
                color={themeContext.blue}
                style={{
                  minHeight: '24px',
                }}
              />
            </HeadIcon>
          </div>
          <HeadContent>
            <HeadTitle content={`${t('buyer-finder.card.title')} ${i + 1}`} />
            <HeadSubTitle content={t('buyer-finder.card.subtitle')} />
          </HeadContent>
          {/* <div> */}
          {/*  <Tooltip */}
          {/*    ref={tooltipRef} */}
          {/*    className="title-tooltip" */}
          {/*    id={`title-tt-${_id}`} */}
          {/*    textAlign="left" */}
          {/*    isDisabledEvents */}
          {/*    content={ */}
          {/*      <> */}
          {/*        <Handler className="normal"> */}
          {/*          {isFavorite && ( */}
          {/*            <Icon */}
          {/*              icon={Check} */}
          {/*              color={'var(--buyer-card-hovered-icon-color)'} */}
          {/*              style={{ */}
          {/*                width: '10px', */}
          {/*                height: '10px', */}
          {/*              }} */}
          {/*            /> */}
          {/*          )} */}
          {/*        </Handler> */}
          {/*        <Handler className="hovered"> */}
          {/*          {isFavorite && ( */}
          {/*            <Icon */}
          {/*              icon={Minus} */}
          {/*              color={'var(--buyer-card-hovered-icon-color)'} */}
          {/*              style={{ */}
          {/*                width: '14px', */}
          {/*                height: '14px', */}
          {/*              }} */}
          {/*            /> */}
          {/*          )} */}
          {/*          {!isFavorite && ( */}
          {/*            <Icon */}
          {/*              icon={Plus} */}
          {/*              color={'var(--buyer-card-hovered-icon-color)'} */}
          {/*              style={{ */}
          {/*                width: '14px', */}
          {/*                height: '14px', */}
          {/*              }} */}
          {/*            /> */}
          {/*          )} */}
          {/*        </Handler> */}
          {/*      </> */}
          {/*    } */}
          {/*  > */}
          {/*    <TooltipTitle */}
          {/*      content={t('buyer-finder.card.unauthorized-tootlip')} */}
          {/*    /> */}
          {/*  </Tooltip> */}
          {/* </div> */}
        </Head>
        <Details>
          <Location>
            <LocationTitle
              content={`${t('buyer-finder.card.search-profile')}:`}
            />
            <Address>
              <AddressLabel content={String(searchProfileData.location)} />
              <Tag rounded>+{searchProfileData.radius} KM</Tag>
            </Address>
          </Location>
          {/* <Tag># 21391006-1</Tag> */}
        </Details>

        <IconContainer>
          <IconRow>
            <IconItem>
              <Icon
                icon={icon}
                width={16}
                height={16}
                color={themeContext.blue}
              />
              <IconLabel
                allowHtml
                content={`${t('buyer-finder.card.looking-for')} <b>${t(
                  label
                )}</b>`}
              />
            </IconItem>

            {isShowNumberOfRooms && (
              <IconItem>
                <Icon
                  icon={RealEstateDimensionsPlan}
                  width={16}
                  height={16}
                  color={themeContext.blue}
                />
                <IconLabel
                  allowHtml
                  content={t('buyer-finder.card.rooms', {
                    value: `<b>${searchProfileData.numberOfRoomsMin}</b>`,
                  })}
                />
              </IconItem>
            )}

            {isShowLivingSpace && (
              <IconItem>
                <Icon
                  icon={RealEstateDimensionsBlock}
                  width={16}
                  height={16}
                  color={themeContext.blue}
                />
                <IconLabel
                  allowHtml
                  content={t('buyer-finder.card.living-space', {
                    value: `<b>${searchProfileData.livingAreaMin} m²</b>`,
                  })}
                />
              </IconItem>
            )}

            {isShowLandArea && (
              <IconItem>
                <Icon
                  icon={RealEstateDimensionsBlock}
                  width={16}
                  height={16}
                  color={themeContext.blue}
                />
                <IconLabel
                  allowHtml
                  content={t('buyer-finder.card.land-area', {
                    value: `<b>${searchProfileData.landAreaMin} m²</b>`,
                  })}
                />
              </IconItem>
            )}

            <IconItem>
              <Icon
                icon={AccountingBills}
                width={16}
                height={16}
                color={themeContext.blue}
              />
              <IconLabel
                allowHtml
                content={`${t(
                  'buyer-finder.card.price'
                )} <b>${localPurchasePriceMax}</b>`}
              />
            </IconItem>
          </IconRow>
        </IconContainer>
      </Wrapper>
    </Card>
  );
};
