import styled from 'styled-components';
import {
  Headline2,
  ParagraphText,
} from '../../../../common/components/ui/typography';
import { device } from '../../../../../style/theme';
import { SecondaryButton } from '../../../../common/components/ui/buttons';

export const Container = styled.div`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  background-color: ${(props) => props.theme.white};

  @media ${device.tablet} {
    padding: 16px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 16px;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const Title = styled(Headline2)`
  margin-right: 16px;
`;

export const Description = styled(ParagraphText)`
  margin-bottom: 16px;
`;

export const CollapseButton = styled(SecondaryButton)`
  width: 40px;
  height: 40px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
