import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import {
  Headline1,
  TitleSlogan,
} from '../../../../common/components/ui/typography';

export const VideoContainer = styled.div`
  position: relative;
  padding: 48px 0 48px 48px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 3px;
    background: #f7f9fa;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.09);
    top: 0;
    left: 0;
    right: 80px;
    bottom: 0;
  }

  @media ${device.tablet} {
    padding: 16px 16px 40px;
    margin: 0 !important;
    border-radius: 0;

    &::before {
      right: 0;
      box-shadow: none;
    }
  }
`;

export const VideoContainerBody = styled.div`
  position: relative;
`;

export const VideoHeader = styled(TitleSlogan)`
  opacity: 0.8;
  margin-bottom: 4px;
`;

export const Headline = styled(Headline1)`
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const VideoContent = styled.div`
  display: flex;
  margin-top: 32px;

  @media ${device.tablet} {
    margin-top: 12px;
    display: block;
  }
`;

export const VideoContentLeft = styled.div`
  width: 45%;
  p {
    padding-bottom: 16px;
  }

  @media ${device.tablet} {
    width: 100%;
    p {
      padding-bottom: 4px;
    }
  }
`;

export const VideoContentRight = styled.div`
  width: 50%;
  position: relative;

  @media ${device.tablet} {
    width: 100%;
    padding-top: 12px;
  }
`;

export const VideoPlayer = styled.div`
  position: absolute;
  top: 0;
  left: 26px;
  height: 100%;
  aspect-ratio: 16/9;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none !important;
  }

  @media ${device.tablet} {
    position: relative;
    height: auto;
    width: 100%;
    left: 0;
  }
`;

export const ButtonWrap = styled.div`
  @media ${device.tablet} {
    display: none;
  }
`;

export const ButtonWrapMobile = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    justify-content: center;
  }
  @media ${device.mobile} {
    button {
      width: 100%;
    }
  }
`;
