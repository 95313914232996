import styled from 'styled-components';
import { device } from '../../../../../../../style/theme';

export const Container = styled.div`
  & button {
    margin-top: 24px;
  }

  @media ${device.tablet} {
    margin-bottom: 24px;
    padding: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
`;

export const Title = styled.h3`
  font-family: Source Serif Pro, sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;

export const ErrorText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.red};
  font-family: Roboto, sans-serif;
  margin-bottom: 12px;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const LoaderContainer = styled.div`
  position: relative;
  padding: 100px 0;
`;
