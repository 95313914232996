import styled from 'styled-components';
import { MainButton } from '../../../../../common/components/ui/buttons';

export const ShadowmapContainer = styled.div<{ isShadowmapExpanded?: boolean }>`
  width: 50%;
  position: relative;
  transition: 0.3s all;
  ${({ isShadowmapExpanded }) =>
    isShadowmapExpanded &&
    `
        width: 100%;
    `}
`;

export const ShadowmapExpandButton = styled.button`
  width: 48px;
  height: 48px;
  outline: 2px solid white;
  border: 2px solid #002849;
  background: #4e73f5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: -24px;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const ShadowmapIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

export const AddressBar = styled.div`
  height: 64px;
  position: absolute;
  bottom: 0;
  width: 100%;
  opacity: 0.9;
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 24px;
  background-color: #e5e9ec;
`;

export const AddressBarButton = styled(MainButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  height: 40px;
`;

export const AddressBarItemsBox = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const AddressBarTitle = styled.h5`
  font-family: Roboto;
  font-weight: 900;
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: rgba(0, 48, 94, 0.5);
  margin-bottom: 4px;
`;

export const AddressBarText = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: 0.5px;
  color: #00305e;
  max-width: 205px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
