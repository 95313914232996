import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import {
  Headline1,
  Headline2,
  TitleSlogan,
} from '../../../../common/components/ui/typography';
import Icon from '../../../../common/components/ui/icon';

export const Container = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 88px;
  justify-content: flex-end;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  @media ${device.tablet} {
    padding: 0;
    align-items: center;
    width: auto;
    margin-bottom: 16px;
  }
`;

export const HeaderBox = styled.div`
  max-width: 552px;
  text-align: left;
  padding: 32px 0;
  @media ${device.tablet} {
    padding: 16px;
  }
`;

export const SliderHeader = styled(TitleSlogan)`
  opacity: 0.8;
  margin-bottom: 4px;
`;

export const Headline = styled(Headline1)`
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @media ${device.tablet} {
    position: static;
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
`;

export const ImageBox = styled.div`
  width: 377px;
  height: 500px;
  z-index: 1;
  @media ${device.tablet} {
    display: none;
    margin: 0 auto;
  }
`;

export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const InfoBox = styled.div`
  max-width: 839px;
  padding: 48px;
  background-color: ${({ theme }) => theme.logoBlue};
  @media ${device.tablet} {
    position: relative;
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 16px;
  }
`;

export const Title = styled(Headline2)`
  margin: 0 0 16px 0;
  @media ${device.tablet} {
    text-align: center;
  }
`;

export const Description = styled.div`
  margin: 0 0 16px 0;
  font-family: 'Roboto' !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: #ffffff !important;
  p,
  span {
    color: #ffffff !important;
  }
`;

export const BoxButtons = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    justify-content: center;
  }
  @media ${device.mobile} {
    flex-direction: column-reverse;
  }
`;

export const LinkIconWrapper = styled(Icon)`
  margin-right: 8px;
`;
