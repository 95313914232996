import styled from 'styled-components';

export const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconButton = styled.button<{ mobile?: boolean }>`
  padding: 0;
  margin: 0;
  outline: none;
  width: 16px;
  height: 16px;
  color: ${(props) => (props.mobile ? props.theme.white : props.theme.blue)};
  background: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: ${(props) => (!props.mobile ? '24px' : '0')};
`;

export const TextContainer = styled.div`
  text-align: center;
  margin-top: -8px;
`;

export const Title = styled.h2`
  font-family: 'Source Serif Pro';
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  margin: 0;
`;

export const StepDescription = styled.p`
  margin: 4px 0 0 0;
  opacity: 0.8;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.blue};
  text-transform: uppercase;
`;

export const MobileToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  background: #002849;
  padding: 0 16px;
  margin-bottom: 16px;
`;

export const MobileTextContainer = styled.div`
  text-align: center;
  margin: 0 auto;
`;

export const MobileTitle = styled.h2`
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
`;

export const MobileStepDescription = styled.p`
  opacity: 0.8;
  font-family: Roboto;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: #fff;
`;
