import { FC, memo, useMemo } from 'react';
import { FirstStep } from './steps/first-step/first-step';
import { SecondStep } from './steps/second-step/second-step';
import { useGetValuationTitle } from '../../../hooks/useGetValuationTitle';
import { FormError } from '../../../../interface';
import { ThirdStep } from './steps/third-step/third-step';
import { FourthStep } from './steps/fouth-step/fouth-step';
import { FifthStep } from './steps/fifth-step/fifth-step';
import { SixthStep } from './steps/sixth-step/sixth-step';
import { SeventhStep } from './steps/seventh-step/seventh-step';
import { EighthStep } from './steps/eighth-step/eighth-step';
import { IValuationForm } from '../../../../form-property-valuation-wizard/interface';
import { useAppSelector } from '../../../../../common/hooks';
import { ModalForm } from '../../../../components/fullscreen-modal/modal-form/modal-form';
import { useGetPortalType } from '../../../../../landing/hooks/useGetPortalType';

interface IProps {
  activeFormStep: number;
  errors?: FormError;
  onSubmit?: (formData: IValuationForm, ownerId?: string) => Promise<void>;
  lastStepCallback?: () => void;

  isHeadline?: boolean;
}

const ValuationFormBase: FC<IProps> = ({
  activeFormStep,
  errors,
  onSubmit,
  lastStepCallback,
  isHeadline,
}) => {
  const { isSeller } = useGetPortalType();
  const title = useGetValuationTitle({ activeStepProp: activeFormStep });
  const isVWOpen = useAppSelector((state) => state.valuationWizardV2.isVWOpen);

  const formStep = useMemo(() => {
    switch (activeFormStep) {
      case 0:
        return <FirstStep />;
      case 1:
        return <SecondStep />;
      case 2:
        return <ThirdStep />;
      case 3:
        return <FourthStep />;
      case 4:
        return <FifthStep />;
      case 5:
        return <SixthStep />;
      case 6:
        return <SeventhStep />;
      case 7:
        return (
          <EighthStep lastStepCallback={lastStepCallback} onSubmit={onSubmit} />
        );
      default:
        return <FirstStep />;
    }
  }, [activeFormStep, lastStepCallback, onSubmit]);

  return (
    <ModalForm
      isModalOpen={isVWOpen}
      formStep={formStep}
      title={title}
      header={
        isSeller
          ? 'property-valuation.seller.form.redesign.headline'
          : 'property-valuation.form.redesign.headline'
      }
      errors={errors}
      isHeadline={isHeadline}
    />
  );
};

const ValuationForm = memo(ValuationFormBase);

export { ValuationForm };
