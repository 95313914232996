import { RefObject } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as ChevronRight } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-right-1.svg';
import { device } from '../../../../style/theme';
import Icon from '../../../common/components/ui/icon';

interface IProps {
  buttonRef: RefObject<HTMLDivElement> | null;
  isPrev?: boolean;
  isMobileRedesign?: boolean;
  onClick?: () => void;
}

const Container = styled.div<{ isPrev?: boolean; isMobileRedesign?: boolean }>`
  position: absolute;
  top: 51%;
  ${(props) =>
    props.isPrev
      ? `
    left: 72px;
  `
      : `right: -32px`};
  z-index: 2;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border: 2px solid #fff;
  border-radius: 50%;
  padding: 12px;
  cursor: pointer;
  background-color: ${(props) => props.theme.logoBlue};
  svg {
    stroke-width: 1.5px;
  }
  &.swiper-button-disabled {
    visibility: hidden;
    opacity: 0;
  }
  @media ${device.tabletSlider} {
    display: none;
  }

  ${(props) =>
    props.isMobileRedesign &&
    `
    @media ${device.tabletSlider} {
      display: flex;
      position: static;
      width: 48px;
      height: 48px;
    }
  `}
`;

const IconWrapper = styled.div<{ isMobileRedesign?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 14px;
  background-color: ${(props) => props.theme.ctaBlue};
  transition: background-color 225ms ease;
  &:hover {
    background-color: ${(props) => props.theme.ctaBlueHover};
  }

  ${(props) =>
    props.isMobileRedesign &&
    `
    @media ${device.tabletSlider} {
      padding: 10px;

      i {
        width: 9px !important;
        height: 9px !important;
      }
    }
  `}
`;

const SliderNavButton = ({
  buttonRef,
  isPrev,
  isMobileRedesign,
  onClick,
}: IProps): JSX.Element => (
  <Container
    ref={buttonRef}
    isPrev={isPrev}
    isMobileRedesign={isMobileRedesign}
    onClick={onClick}
  >
    <IconWrapper isMobileRedesign={isMobileRedesign}>
      <Icon icon={isPrev ? ChevronLeft : ChevronRight} width={12} height={12} />
    </IconWrapper>
  </Container>
);

export { SliderNavButton };
