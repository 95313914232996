import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import {
  Headline1,
  Headline3,
  ParagraphText,
} from '../../../../common/components/ui/typography';
import { PropertyIcon } from '../../icon/icon';

export const SelectedOfferContainer = styled.div`
  height: 80px;
  padding: 12px;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.borderColor};
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const Id = styled.p`
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.greyBackground};
  color: ${(props) => props.theme.blue};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: source serif pro, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  align-self: stretch;
  flex: 1;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(Headline3)`
  font-size: 16px;
  line-height: 1.25;

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const CityWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FromCity = styled(ParagraphText)`
  display: flex;
  font-size: 10px;
  opacity: 0.5;
  line-height: 1.6;
  text-transform: uppercase;

  @media ${device.mobile} {
    font-size: 8px;
  }
`;

export const CityName = styled(FromCity)`
  font-weight: 900;
  margin-left: 4px;
`;

export const OfferIcon = styled(PropertyIcon)`
  align-self: center;
`;

export const FormWrapper = styled.div`
  padding: 16px;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.borderColor};
  margin-top: 12px;
`;

export const FormTitle = styled(Headline3)`
  margin-bottom: 16px;
`;

export const FormText = styled(ParagraphText)`
  font-size: 12px;
  line-height: 1.67;
  margin-bottom: 12px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  margin-top: 78px;
  justify-content: space-between;

  & button {
    justify-content: center;
  }

  @media ${device.tablet} {
    flex-direction: column;
    margin-top: 36px;
    gap: 8px;
  }
`;

export const SuccessMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SuccessMessageTitle = styled(Headline1)`
  position: relative;
  text-align: center;
  font-size: 20px;
`;

export const SuccessMessageText = styled(ParagraphText)`
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 16px 0 24px 0;
  text-align: center;
`;
