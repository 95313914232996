import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const Title = styled.div`
  width: 100%;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: left;
  color: ${({ theme }) => theme.blue};
  margin-bottom: 24px;

  @media ${device.tablet} {
    text-align: center;
    margin-bottom: 12px;
    font-size: 20px;
  }
`;

export const LoaderContainer = styled.div<{ isiframe?: boolean }>`
  height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
  &&&& {
    > div {
      position: absolute;
    }
  }
`;

export const PhoneVerificationWrapper = styled.div`
  flex: 1;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;
