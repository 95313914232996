import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { ReactComponent as Close } from '../../../../assets/streamline-light/interface-essential/form-validation/close.svg';
import { device } from '../../../../style/theme';
import { Modal, ModalFooter } from '../../../common/components/modal';
import { BorderButton } from '../../../common/components/ui/buttons';
import Icon from '../../../common/components/ui/icon';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';

interface LegalModalProps {
  isOpen: boolean;
  onClose: () => void;
  mobileTitle: string;
  innerHTML: string;
}

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  background-color: ${(props) => props.theme.blue};
  width: 100%;
  justify-content: center;
  padding: 18px 0 18px 0;
`;

const Title = styled.h3`
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
`;

const ContentBox = styled.div`
  padding-bottom: 20px;
  position: relative;
  @media ${device.tablet} {
    height: calc(100vh - 56px);
    padding-bottom: 0;
  }
`;

const Content = styled.div`
  font-family: 'Roboto' !important;
  height: 400px;
  overflow-x: scroll;
  @media ${device.tablet} {
    height: 100%;
    padding: 16px 16px 0 16px;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 16px;
`;

const Fade = styled.div`
  height: 64px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
`;

const descktopWrapperStyle: React.CSSProperties = {
  maxWidth: 512,
  padding: '32px',
};

const mobileWrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100vh',
  padding: 0,
  overflow: 'overlay',
};

const LegalModal = ({
  isOpen,
  onClose,
  mobileTitle,
  innerHTML,
}: LegalModalProps) => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const themeContext = useContext(ThemeContext);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      wrapperStyles={isMobileSize ? mobileWrapperStyle : descktopWrapperStyle}
    >
      {isMobileSize && (
        <Row>
          <Title>{mobileTitle}</Title>
          <IconContainer onClick={onClose}>
            <Icon
              icon={Close}
              width={16}
              height={16}
              color={themeContext.white}
            />
          </IconContainer>
        </Row>
      )}
      <ContentBox>
        <Content dangerouslySetInnerHTML={{ __html: innerHTML }} />
        <Fade />
      </ContentBox>
      {!isMobileSize && (
        <ModalFooter>
          <BorderButton onClick={onClose} label={t('button.close')} />
        </ModalFooter>
      )}
    </Modal>
  );
};

export default LegalModal;
