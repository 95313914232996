import { ReactComponent as Calendar } from 'assets/streamline-light/interface-essential/date-calendar/calendar.svg';
import { FC, memo, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { RatingReason, TimeHorizont } from '../../../../../../generated';
import { device } from '../../../../../../style/theme';
import { BorderButton } from '../../../../../common/components/ui/buttons';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { VALUATION_FORM_KEYS } from '../../../constants';
import { BoxType } from '../../../../interface';
import { IValuationForm } from '../../../interface';
import { prevStepAction } from '../../../redux/valuationWizardSlice';
import { BoxSelector } from '../../../../components/common/box-selector';
import { NextButton } from '../../../../components/common/next-button';
import { useGetPortalType } from '../../../../../landing/hooks/useGetPortalType';

const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div<{ isiframe?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;

  &:nth-of-type(2) {
    flex: 0;
    margin-bottom: 28px;
  }

  &:last-of-type {
    flex: 0;
    margin-top: auto;
    justify-content: flex-start;
    flex-direction: row;

    > *:last-of-type {
      margin-left: auto;
      @media ${({ isiframe }) => (isiframe ? '0' : device.tablet)} {
        margin-top: 32px;
      }
    }
  }
`;

interface IProps {
  submitForm?: boolean;
  onSubmit?: (formData: IValuationForm, ownerId?: string) => Promise<void>;

  lastStepCallback?(): void;
}

const RegisterButton = styled(NextButton)`
  text-transform: uppercase;
`;

const FifthStepBase: FC<IProps> = ({
  submitForm,
  onSubmit,
  lastStepCallback,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const { isSeller } = useGetPortalType();

  const [isSubmit, setIsSubmit] = useState(false);

  const userInput = useAppSelector((state) => state.valuationWizard.userInput);
  const owner = useAppSelector((state) => state.auth.user?._id);
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const ratingReason = useWatch({ name: 'ratingReason' });

  const switchToPrevStep = useCallback(
    (e?: MouseEvent) => {
      e?.preventDefault();
      dispatch(prevStepAction());
    },
    [dispatch]
  );

  const handleSubmit = useCallback(() => {
    setIsSubmit(true);
  }, []);

  // get latest values from userInput
  useEffect(() => {
    if (onSubmit && isSubmit) {
      onSubmit(userInput as IValuationForm, owner);
    }
  }, [isSubmit, onSubmit, owner, userInput]);

  const displayTimeHorizont =
    ratingReason && ratingReason !== RatingReason.EstimatedResult;

  const ratingReasonBoxes = [
    {
      boxType: BoxType.radioBox,
      title: 'valuation.form.fifth-step.sell-label',
      value: RatingReason.Sell,
    },
    {
      boxType: BoxType.radioBox,
      title: 'valuation.form.fifth-step.buy-label',
      value: RatingReason.Buy,
    },
    {
      boxType: BoxType.radioBox,
      title: 'valuation.form.fifth-step.estimated-result-label',
      value: RatingReason.EstimatedResult,
    },
    {
      boxType: BoxType.radioBox,
      title: 'valuation.form.fifth-step.other-label',
      value: RatingReason.Other,
      nestedInput: VALUATION_FORM_KEYS.OTHER_RATING_REASON,
      nestedInputPlaceholder: t('ihre grund'),
    },
  ];

  return (
    <Container>
      <Row>
        <BoxSelector
          name={VALUATION_FORM_KEYS.RATING_REASON}
          boxes={
            isSeller
              ? ratingReasonBoxes.filter(
                  (ratingReasonBox) =>
                    ratingReasonBox.value !== RatingReason.Buy
                )
              : ratingReasonBoxes
          }
          column
        />
      </Row>
      <Row>
        {displayTimeHorizont && (
          <BoxSelector
            containerTitle={t('valuation.form.fifth-step.time-horizont.title')}
            name={VALUATION_FORM_KEYS.TIME_HORIZONT}
            boxes={[
              {
                boxType: BoxType.iconBox,
                title: 'valuation.form.fifth-step.time-horizont.1-3',
                value: TimeHorizont.UpTo3Months,
                icon: Calendar,
              },
              {
                boxType: BoxType.iconBox,
                title: 'valuation.form.fifth-step.time-horizont.4-12',
                value: TimeHorizont.UpTo12Months,
                icon: Calendar,
              },
              {
                boxType: BoxType.iconBox,
                title: 'valuation.form.fifth-step.time-horizont.>12',
                value: TimeHorizont.MoreThan12Months,
                icon: Calendar,
              },
            ]}
          />
        )}
      </Row>
      <Row isiframe={isIframe}>
        {(!isMobileSize || isIframe) && (
          <BorderButton
            id="step-5"
            label={t('button.back')}
            onClick={switchToPrevStep}
          />
        )}
        <RegisterButton
          key={ratingReason}
          id="register-wizard-button"
          watchedKeys={[
            VALUATION_FORM_KEYS.RATING_REASON,
            ...(ratingReason !== RatingReason.EstimatedResult
              ? [VALUATION_FORM_KEYS.TIME_HORIZONT]
              : []),
            ...(ratingReason === RatingReason.Other
              ? [VALUATION_FORM_KEYS.OTHER_RATING_REASON]
              : []),
          ]}
          label={t('button.next-step')}
          buttonCallback={
            submitForm ? onSubmit && handleSubmit : lastStepCallback
          }
        />
      </Row>
    </Container>
  );
};

const FifthStep = memo(FifthStepBase);

export { FifthStep };
