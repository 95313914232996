import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useThirdStepLogic } from '../../../../../hooks/useStepsLogic';
import { NextButton } from '../../../../../../components/common/next-button';
import { InputAddress } from '../../../../common/input-address/input-address';
import { InputsContainer } from '../../../../../../components/fullscreen-modal/inputs-container/inputs-container';
import { GlobalError } from '../../../../../../../common/components/form/error/global-error';
import { BackButtonContainer } from '../../../../common/back-button-container';

const Container = styled.div<{ inputWidth: number }>`
  & input {
    min-width: ${({ inputWidth }) => inputWidth}px;
  }
`;

const ThirdStep: FC = () => {
  const { t } = useTranslation();

  const {
    inputs,
    watchedKeys,
    outerDisable,
    isRenovationYearLower,
    isIframePropertyMultiFamilyHouse,
    onSubmit,
  } = useThirdStepLogic();

  return (
    <Container inputWidth={isIframePropertyMultiFamilyHouse ? 240 : 280}>
      <InputAddress />
      <InputsContainer inputs={inputs} />
      {isRenovationYearLower && (
        <GlobalError
          title={t(
            'valuation.form.first-step.renovation-year-is-lower-than-building-year'
          )}
          topMargin
        />
      )}
      {!isIframePropertyMultiFamilyHouse && (
        <BackButtonContainer marginTop={0} />
      )}
      <NextButton
        buttonCallback={onSubmit}
        watchedKeys={watchedKeys}
        isEvaluationV2
        outerDisable={outerDisable}
        label={'property-valuation.form.redesign.next-button'}
      />
    </Container>
  );
};

export { ThirdStep };
