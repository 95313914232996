import { FC, memo } from 'react';

import {
  BackButton,
  Space,
} from '../../../components/fullscreen-modal/modal-form/modal-form-styles';
import { useAppSelector } from '../../../../common/hooks';
import { useNavigationButtonsHandler } from '../../hooks/callback-hooks/useNavigationButtonsHandler';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';

interface IProps {
  marginTop?: number;
  marginRight?: number;
  isOnlyIframe?: boolean;
}

const BackButtonContainerBase: FC<IProps> = ({
  marginTop,
  marginRight,
  isOnlyIframe,
}) => {
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const isMobileSize = useIsMobileSize();

  const { onBackClick } = useNavigationButtonsHandler({});

  return (
    <>
      {(isIframe || (!isMobileSize && !isOnlyIframe)) && (
        <>
          <Space marginBottom={marginTop} />
          <BackButton
            id="step-1"
            isIframe={isIframe}
            label={'button.back'}
            onClick={(e) => {
              e?.preventDefault();
              onBackClick();
            }}
            marginRight={marginRight}
            isUpperCase
          />
        </>
      )}
    </>
  );
};

const BackButtonContainer = memo(BackButtonContainerBase);

export { BackButtonContainer };
