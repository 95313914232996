import { memo, useMemo } from 'react';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  Parallax,
} from 'swiper';
import {
  Container,
  HeaderContainer,
  SliderHeader,
  SliderContainer,
  Headline,
} from './virtual-tour-slider-styles';
import { VirtualTourSection } from '../../../../../../generated';
import { VirtualTourCard } from '../virtual-tour-card';

interface IProps {
  sections: VirtualTourSection[];
  sliderHeader: string;
  headline: string;
}

SwiperCore.use([Parallax, Navigation, Pagination, Controller]);

const SliderBase = ({
  sections,
  sliderHeader,
  headline,
}: IProps): JSX.Element => {
  const { slides } = useMemo(() => {
    const list = sections?.map((section) => {
      return (
        <SwiperSlide
          key={`slide-${section._id}`}
          tag="li"
          style={{ listStyle: 'none' }}
        >
          <VirtualTourCard section={section} />
        </SwiperSlide>
      );
    });
    return {
      slides: list,
    };
  }, [sections]);

  return (
    <Container>
      <HeaderContainer>
        <SliderHeader content={sliderHeader} />
        <Headline content={headline} />
      </HeaderContainer>
      <SliderContainer>
        <Swiper
          observer
          observeParents
          id="controller"
          allowTouchMove
          tag="section"
          wrapperTag="ul"
          speed={600}
          pagination={{
            clickable: true,
          }}
        >
          {slides}
        </Swiper>
      </SliderContainer>
    </Container>
  );
};

const VirtualTourSlider = memo(SliderBase);

export { VirtualTourSlider };
