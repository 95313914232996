import styled from 'styled-components';
import { ParagraphText } from '../../../common/components/ui/typography';
import { device } from '../../../../style/theme';

export const Container = styled.div`
  width: 100%;
  margin: 16px 0;
  padding: 12px;
  border-radius: 3px;
  border: solid 1px #d5e3cc;
  background-color: rgba(213, 227, 204, 0.2);
  display: flex;
  align-items: center;
  gap: 12px;

  @media ${device.tablet} {
    margin: 0 16px;
    width: unset;
  }
`;

export const Text = styled(ParagraphText)`
  font-size: 12px;
  line-height: 1.33;
`;
