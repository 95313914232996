import styled from 'styled-components';

import { MainButton } from '../../../../../common/components/ui/buttons';
import { device } from '../../../../../../style/theme';

export const MapBoxContainer = styled.div<{ isFullScreen: boolean }>`
  width: ${({ isFullScreen }) => (isFullScreen ? '100%' : '50%')};
  position: relative;
  overflow: hidden;
  height: ${({ isFullScreen }) =>
    isFullScreen ? '100%' : 'calc(100vh - 64px)'};

  &&&& {
    > div {
      //height: 100%;
      height: calc(100vh - 64px);

      > div {
        height: 100% !important;
      }
    }
  }
`;

export const AddressBarButton = styled(MainButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  height: 40px;
`;

export const ControlsContainer = styled.div`
  position: absolute;
  bottom: 106px;
  left: 4px;
  z-index: 0;
  color: #dfdfdf;
  display: flex;
  flex-direction: column;

  > div {
    position: static !important;
    border: none;
    border-radius: 2px;

    > div {
      margin: 0 0 10px 10px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3) !important;
      border-radius: 2px;

      > button {
        width: 34px;
        height: 34px;
        border: none;
        margin: 0;
        border-radius: 2px;

        &:nth-child(2) {
          border-radius: 0;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
`;

export const MarkerImg = styled.img``;

export const Container = styled.div<{
  width?: string;
  hasTopRadius?: boolean;
}>`
  width: 100%;
  position: absolute;
  pointer-events: all;
  transform: translateY(0);
  left: 0;
  z-index: 0;
  transition: transform 665ms ease-in-out, opacity 665ms ease;
  will-change: transform, opacity;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: hidden;

  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib-inner {
    display: none !important;
  }
  &&&&&&& > .reachability-legend-detail-container {
    height: fit-content !important;
    top: 68px;
    @media ${device.tablet} {
      top: 56px;
    }
  }
  &&&&&&& > .control-container {
    height: fit-content !important;
    top: calc(100% - 142px);
    @media ${device.tablet} {
      top: calc(100% - 64px);
    }
  }
`;
