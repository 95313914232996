import { FC } from 'react';
import styled from 'styled-components';

import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../style/theme';
import { VirtualTourSection } from '../../../../../generated';

export const VirtualToursContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: 24px;
  background-color: ${(props) => props.theme.faqHeader};
  margin-top: 32px;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
  }
`;

const Container = styled.div`
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  @media ${device.mobile} {
    max-width: 100%;
    height: 100%;
  }
`;

const ImageWrapper = styled.div`
  height: 0;
  padding-top: calc(152 / 306 * 100%);
  position: relative;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  flex: 1;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
`;

const Title = styled.h4`
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

const Text = styled.div`
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  font-family: Roboto, sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  text-align: center;
  opacity: 0.6;
`;

interface VirtualTourProps {
  section: VirtualTourSection;
}

export const VirtualTourCard: FC<VirtualTourProps> = ({ section }) => {
  return (
    <Container>
      {section.imageUrl && (
        <ImageWrapper>
          <Image src={section.imageUrl} />
        </ImageWrapper>
      )}
      <Content>
        <Title>{section.title}</Title>
        <Text dangerouslySetInnerHTML={{ __html: section.text ?? '' }} />
      </Content>
    </Container>
  );
};
