import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Densities } from '../../../../../generated';
import { CardWithTitle } from '../social-economics/card-with-title';
import { OfferItem } from './offer-item';

interface IProps {
  densities?: Densities | null;
  isNoPadding?: boolean;
  isNoBorder?: boolean;
}

const Card = styled(CardWithTitle)<{
  isNoPadding?: boolean;
  isNoBorder?: boolean;
}>`
  margin-bottom: 0;
  ${({ isNoPadding }) => isNoPadding && 'padding: 0;'}
  ${({ isNoBorder }) => isNoBorder && 'border: none;'}

  h3 {
    text-align: left;
  }
`;

const OffersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  word-break: break-all;
`;

export const ReachabilityOfferCard: FC<IProps> = ({
  densities,
  isNoPadding,
  isNoBorder,
}) => {
  const { t } = useTranslation();
  const noDataAvailable = t('reachability.no.data.available');

  return (
    <Card
      isNoBorder={isNoBorder}
      isNoPadding={isNoPadding}
      title={t('reachability.number.of.offers')}
    >
      <OffersContainer>
        {densities &&
          Object.entries(densities).map(([key, value]) => (
            <OfferItem
              value={value || noDataAvailable}
              title={t(`reachability.offer.${key}`)}
              hasValue={!!value}
              key={key}
            />
          ))}
      </OffersContainer>
    </Card>
  );
};
