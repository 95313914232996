import { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, ThemeContext } from 'styled-components';
import { IsochronesMode } from '../../../../../generated';
import { device } from '../../../../../style/theme';
import Icon from '../../../../common/components/ui/icon';
import { IconType } from '../connection-card/connection-item';

export interface ButtonConfig {
  icon: IconType;
  mode: IsochronesMode;
  text: string;
}

interface IProps {
  buttonsConfig: ButtonConfig[];
  selected: IsochronesMode;
  onSelect?: (mode: IsochronesMode) => void;
}

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  @media ${device.tablet} {
    padding: 0 12px;
  }
`;

const Button = styled.button.attrs({
  type: 'button',
})<{ isSelected: boolean }>`
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  background-color: ${(props) => props.theme.white};
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  color: ${(props) => props.theme.blue};
  cursor: pointer;
  transition: all 150ms ease;

  svg,
  span {
    opacity: 0.5;
    transition: all 150ms ease;
  }

  &:hover {
    svg,
    span {
      opacity: 0.8;
    }
  }

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${props.theme.blue};
      border-color: ${props.theme.blue};
      color: ${props.theme.white};

      span,
      svg {
        opacity: 1;
      }

      svg,
      path {
        stroke: ${props.theme.white} !important;
      }
    `}
`;

const ButtonText = styled.span`
  margin-left: 8px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.67;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const TransportSelector: FC<IProps> = ({
  buttonsConfig,
  selected,
  onSelect = () => {},
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  const handleSelect = useCallback(
    (mode: IsochronesMode) => {
      onSelect(mode);
    },
    [onSelect]
  );

  return (
    <ButtonGroup {...rest}>
      {buttonsConfig.map((button) => (
        <Button
          isSelected={button.mode === selected}
          onClick={() => handleSelect(button.mode)}
          key={button.mode}
        >
          <Icon
            icon={button.icon}
            width={20}
            height={20}
            color={themeContext.blue}
          />
          <ButtonText>{t(button.text)}</ButtonText>
        </Button>
      ))}
    </ButtonGroup>
  );
};
