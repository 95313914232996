import { useEffect, useRef, useState } from 'react';

import { Input, ShakeX } from './components';
import * as S from './styles';
import { IVerificationPin, StatusType } from './types';

const KEY_CODE = {
  backspace: 8,
  left: 37,
  right: 39,
};

type IInputType = 'number' | 'text' | 'numberAndText';

const pattern = {
  number: /^\d+$/,
  text: /^[A-Za-z]+$/,
  numberAndText: /^[\dA-Za-z]+$/,
};

interface IVerificationPinProps extends IVerificationPin {
  type: IInputType;
  currentCode?: string | number;
  inputsNumber: number;
  autoFocus?: boolean;
  onChange?: (letterOrNumber: string) => void;
  onFinish?: (completeCode: string) => void;
}

export const VerificationPin: React.FC<IVerificationPinProps> = ({
  type,
  currentCode,
  inputsNumber,
  autoFocus = true,
  status,
  onChange,
  onFinish,
}) => {
  const inputsRef = useRef<HTMLInputElement[]>([]);
  const [internalStatus, setInternalStatus] = useState<StatusType>(
    StatusType.process
  );

  useEffect(() => {
    setInternalStatus(status);
  }, [status]);

  useEffect(() => {
    if (autoFocus) {
      inputsRef.current[0].focus();
    }
  }, [autoFocus]);

  const next = (inputIndex: number) => {
    if (inputIndex < inputsNumber - 1) {
      inputsRef.current[inputIndex + 1].focus();
    }
  };

  const previous = (inputIndex: number) => {
    if (inputIndex > 0) {
      inputsRef.current[inputIndex - 1].focus();
    }
  };

  const getIndexFromId = (id: string): number =>
    Number.parseInt(id.split('-')[1], 10);

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { id, value } = e.target as HTMLInputElement;
    const index = getIndexFromId(id);
    if (
      (e.keyCode === KEY_CODE.backspace && value.length === 0) ||
      e.keyCode === KEY_CODE.left
    ) {
      previous(index);
    } else if (e.keyCode === KEY_CODE.right) {
      next(index);
    }
  };

  const getCompleteCode = () => {
    const values: string[] = [];
    inputsRef.current.forEach(({ value }: HTMLInputElement) => {
      values.push(value);
    });
    return values.join('');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const index = getIndexFromId(id);
    e.preventDefault();

    setInternalStatus(StatusType.process);

    if (onChange) {
      onChange(value);
    }

    if (value.length > 0) {
      const code = getCompleteCode();
      if (onFinish && code.length === inputsNumber) {
        onFinish(code);
      } else {
        next(index);
      }
    } else if (onFinish && currentCode && currentCode !== '') {
      onFinish('');
    }
  };

  return (
    <S.Middle>
      <ShakeX shake={status === StatusType.error}>
        {Array.from({ length: inputsNumber }, (_, k) => k).map((n) => {
          return (
            <Input
              key={`inputVC-${n}`}
              status={internalStatus}
              id={`inputVC-${n}`}
              ref={(el) => {
                inputsRef.current[n] = el as HTMLInputElement;
              }}
              pattern={pattern[type]}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
            />
          );
        })}
      </ShakeX>
    </S.Middle>
  );
};
