import { ReactComponent as Bin2 } from 'assets/streamline-light/interface-essential/delete/bin-2.svg';
import { ReactComponent as Pencil1 } from 'assets/streamline-light/interface-essential/edit/pencil-1.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import { device } from 'style/theme';

import { useContext } from 'react';
import { useRemovePropertyMutation } from '../../../../services/graphql/enhanced';
import { Tooltip } from '../../../common/components/tooltip';
import { TooltipConfirmation } from '../../../common/components/tooltip/TooltipConfirmation';
import { IconButton } from '../../../common/components/ui/buttons';
import {
  removeTemporaryValuations,
  toggleIsEditValuationModalOpen,
} from '../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import { siteMap } from '../../../../routes/site-map';

interface IProps {
  propertyId?: string;
  isDeleteDisabled?: boolean;
  isEditDisabled?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 24px;
  flex: 1;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  @media ${device.tablet} {
    gap: 8px;
    > *:not(.__react_component_tooltip) {
      width: 50%;
      margin: 0 !important;
      button {
        width: 100%;
        height: 40px;
      }
      [data-tip] {
        width: 100%;
      }
    }
    *:not(.__react_component_tooltip) {
      max-width: none !important;
    }

    .tootlip-confirmation {
      left: 16px !important;
      ::before,
      ::after {
        left: 32px !important;
      }
    }
  }
`;

const TooltipHeader = styled.h4`
  font-family: 'Source Serif Pro', serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

const VariationHeaderActions = ({
  propertyId,
  isDeleteDisabled = false,
  isEditDisabled = false,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lngHPush = useLngHistoryPush();
  const theme = useContext(ThemeContext);

  const openModal = () => {
    dispatch(toggleIsEditValuationModalOpen(true));
  };

  const [removeProperty, { isLoading }] = useRemovePropertyMutation();

  const handleDeleteProperty = async () => {
    if (propertyId) {
      await removeProperty({ id: propertyId }).unwrap();
      lngHPush(siteMap.SellerPropertiesPage.path);
    } else {
      dispatch(removeTemporaryValuations());
    }
  };

  return (
    <Container>
      <TooltipConfirmation
        headerText={
          isDeleteDisabled
            ? t('property-delete-disabled.tooltip.title')
            : t('property-delete.tooltip.title')
        }
        backText={
          isDeleteDisabled
            ? t('button.close')
            : t('property-delete.tooltip.back')
        }
        acceptText={isDeleteDisabled ? '' : t('property-delete.tooltip.accept')}
        id={'property-delete'}
        onDelete={isDeleteDisabled ? undefined : handleDeleteProperty}
      >
        <Tooltip
          content={
            <IconButton
              icon={Bin2}
              isLoading={isLoading}
              tabletButtonWidth="100%"
              tabletButtonHeight="40px"
              iconColor={isDeleteDisabled ? theme.ctaBlue : theme.white}
              buttonColor={isDeleteDisabled ? theme.blue : theme.ctaBlue}
              border={isDeleteDisabled ? `1px solid ${theme.ctaBlue}` : 'none'}
              iconColorHover={isDeleteDisabled ? theme.ctaBlue : theme.white}
              buttonColorHover={
                isDeleteDisabled ? theme.blue : theme.ctaBlueHover
              }
            />
          }
          id={'selling-process-navigation.delete.tooltip'}
          maxWidth={32}
        >
          <TooltipHeader>
            {t('seller-property-navigation.delete.tooltip')}
          </TooltipHeader>
        </Tooltip>
      </TooltipConfirmation>
      {isEditDisabled && (
        <TooltipConfirmation
          headerText={t('property-edit-disabled.tooltip.title')}
          backText={t('button.close')}
          acceptText={''}
          id={'property-edit'}
          onDelete={undefined}
          tabletWidth="100%"
        >
          <Tooltip
            content={
              <IconButton
                tabletButtonWidth="100%"
                icon={Pencil1}
                onClick={isEditDisabled ? undefined : openModal}
                iconColor={isEditDisabled ? theme.ctaBlue : theme.white}
                buttonColor={isEditDisabled ? theme.blue : theme.ctaBlue}
                border={isEditDisabled ? `1px solid ${theme.ctaBlue}` : 'none'}
                iconColorHover={isEditDisabled ? theme.ctaBlue : theme.white}
                buttonColorHover={
                  isEditDisabled ? theme.blue : theme.ctaBlueHover
                }
              />
            }
            id={'selling-process-navigation.edit-disabled.tooltip'}
            maxWidth={32}
          >
            <TooltipHeader>
              {t('seller-property-navigation.edit.tooltip')}
            </TooltipHeader>
          </Tooltip>
        </TooltipConfirmation>
      )}
      {!isEditDisabled && (
        <Tooltip
          content={
            <IconButton
              icon={Pencil1}
              onClick={openModal}
              tabletButtonHeight="40px"
              tabletButtonWidth="100%"
            />
          }
          id={'selling-process-navigation.edit.tooltip'}
          maxWidth={32}
        >
          <TooltipHeader>
            {t('seller-property-navigation.edit.tooltip')}
          </TooltipHeader>
        </Tooltip>
      )}
    </Container>
  );
};

export { VariationHeaderActions };
