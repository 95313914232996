import { FC, useCallback, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
  Form,
  Header,
  Label,
  LastFormRow,
  SubmitButton,
  HiddenSubmitButton,
  Subtitle,
} from '../buyer-sign-up-page-styles';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import {
  FormColumn,
  FormRow,
} from '../../../../common/components/form/form-grid';
import {
  Checkbox,
  InputOutsideTopLabel,
  InputOutsideTopLabelWithSelect,
  InputPassOutsideTopLabel,
} from '../../../../common/components/form';
import { REGISTER_FORM_KEYS, phonePrefixes } from '../../../constants';
import { InputType, IRegisterForm } from '../../../interfaces';
import { useGetCountry } from '../../../../localization/get-country';
import {
  OnOfficeUser,
  useGetOnOfficeUserQuery,
} from '../../../../../generated';
import { GlobalLoader } from '../../../../common/components/ui/loaders/global-loader';
import { parsePhone } from '../../../utils/parse-phone';

interface IProps {
  errors?: unknown;
  onSubmit(formData: IRegisterForm): void;
  isSubmitDisabled?: boolean;
}

interface OnOfficeUserResponse {
  data: { getOnOfficeUser: OnOfficeUser };
  isLoading: boolean;
  error: unknown;
}

export const BuyerRegisterForm: FC<IProps> = ({
  errors,
  onSubmit,
  isSubmitDisabled,
}) => {
  const { t } = useTranslation();
  const submitButton = useRef<HTMLButtonElement>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const userEmail = urlParams.get('email') || '';
  const addressId = urlParams.get('addressId') || '';

  const {
    data: onOfficeUser,
    isLoading: isOnOfficeUserLoading,
    error: onOfficeErrors,
  } = useGetOnOfficeUserQuery<OnOfficeUserResponse>(
    {
      addressId: Number(addressId),
    },
    {
      skip: isEmpty(addressId),
    }
  );

  const methods = useForm<IRegisterForm>();
  const { phonePrefix } = useGetCountry();

  const clickSubmitButton = useCallback(() => {
    submitButton?.current?.click();
  }, [submitButton]);

  if (isOnOfficeUserLoading) {
    return <GlobalLoader />;
  }

  const parsedPhone = parsePhone(onOfficeUser?.getOnOfficeUser?.phone ?? '');

  const selectOptions = phonePrefixes.includes(phonePrefix)
    ? phonePrefixes
    : [phonePrefix, ...phonePrefixes];

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {errors && (
          <GlobalError
            title={t((errors as { message: string })?.message?.split(':')[0])}
          />
        )}
        {onOfficeErrors && (
          <GlobalError
            title={t((errors as { message: string })?.message?.split(':')[0])}
          />
        )}
        <Header content={t('buyer-sign-up-page.title')} />
        <Subtitle content={t('buyer-sign-up-page.subtitle')} />
        <FormRow>
          <FormColumn flex="0 0 50%">
            <InputOutsideTopLabel
              name={REGISTER_FORM_KEYS.NAME}
              type="text"
              label="profile-edit.input.label.name"
              placeholder={t('profile-edit.input.placeholder.name')}
              rules={{
                required: 'register.input.error.required',
              }}
              defaultValue={onOfficeUser?.getOnOfficeUser?.name ?? ''}
            />
          </FormColumn>
          <FormColumn flex="0 0 50%">
            <InputOutsideTopLabel
              name={REGISTER_FORM_KEYS.SURNAME}
              type="text"
              label="profile-edit.input.label.surname"
              placeholder="profile-edit.input.placeholder.surname"
              rules={{ required: 'register.input.error.required' }}
              defaultValue={onOfficeUser?.getOnOfficeUser?.surname ?? ''}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <InputOutsideTopLabelWithSelect
              type={InputType.number}
              name={REGISTER_FORM_KEYS.PHONE}
              label="register.form.input.label.phone"
              placeholder="register.form.input.placeholder.phone"
              rules={{
                required: 'register.input.error.required',
                valueAsNumber: true,
              }}
              selectWidth="87px"
              hasSelect
              selectOptions={selectOptions}
              selectValue={parsedPhone.phonePrefix ?? phonePrefix}
              selectName={REGISTER_FORM_KEYS.PHONE_PREFIX}
              defaultValue={parsedPhone.phoneNumber ?? ''}
            />
          </FormColumn>
          <FormColumn>
            <InputOutsideTopLabel
              type={InputType.text}
              name={REGISTER_FORM_KEYS.EMAIL}
              label="register.form.input.label.email"
              placeholder="register.form.input.placeholder.email"
              rules={{
                required: 'register.input.error.required',
              }}
              defaultValue={onOfficeUser?.getOnOfficeUser?.email || userEmail}
              isReadonly
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn flex="0 0 50%">
            <InputPassOutsideTopLabel
              name="password"
              type="password"
              label="register.form.input.label.password"
              placeholder="register.form.input.placeholder.password"
              rules={{
                required: 'register.input.error.required',
                minLength: {
                  value: 10,
                  message: 'error.password.did.not.conform.with.policy',
                },
              }}
              passwordMasterValue={methods.watch('password')}
            />
          </FormColumn>
          <FormColumn flex="0 0 50%">
            <InputPassOutsideTopLabel
              name="confirmPassword"
              type="password"
              label="register.form.input.label.confirm-password"
              placeholder="register.form.input.placeholder.confirm-password"
              rules={{ required: 'register.input.error.required' }}
              passwordMasterValue={methods.watch('password')}
              skipPattern
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn flex="0 0 100%">
            <Checkbox
              name={REGISTER_FORM_KEYS.TERMS_AND_CONDITIONS}
              rules={{ required: 'register.input.error.required' }}
              hasDisclaimer
              innerHTML={
                'buyer-sign-up-page.confirm-registration-data-processing'
              }
            />
          </FormColumn>
          <FormColumn flex="0 0 100%">
            <Checkbox
              name={REGISTER_FORM_KEYS.COMMUNICATION_CONSENT}
              label={'buyer-sign-up-page.enable-email-notifications'}
              rules={{ required: false }}
            />
          </FormColumn>
        </FormRow>
        <Label
          dangerouslySetInnerHTML={{
            __html: t('register.form.required.fields'),
          }}
        />
        <HiddenSubmitButton
          id="register-form-hidden-submit-button"
          type="submit"
          ref={submitButton}
        />
        <LastFormRow>
          <FormColumn flex="0 0 100%">
            <SubmitButton
              fluid
              loader
              isDisabled={isSubmitDisabled}
              label={t('buyer-second-landing-page.button.sign-up')}
              onClick={methods.handleSubmit(clickSubmitButton)}
              type="button"
              id="register-form-submit-button"
            />
          </FormColumn>
        </LastFormRow>
      </Form>
    </FormProvider>
  );
};
