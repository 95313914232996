import { PuffLoader } from 'react-spinners';
import styled from 'styled-components';

const Container = styled.div<{ isAbsolute?: boolean }>`
  &&& {
    position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : 'fixed')};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface IProps {
  size?: number;
  isAbsolute?: boolean;
}

const GlobalLoader = ({ size, isAbsolute }: IProps): JSX.Element => (
  <Container isAbsolute={isAbsolute}>
    <PuffLoader
      color="#002849"
      loading
      size={size || 100}
      speedMultiplier={0.75}
    />
  </Container>
);

export { GlobalLoader };
