import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import { ThemeContext } from 'styled-components';
import { FC, useContext } from 'react';
import {
  Container,
  Header,
  Subtitle,
  LoginButton,
  RegisterButton,
  Divider,
  DividerText,
} from './seller-second-landing-page-styles';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { isTokenExpired } from '../../../common/utils/is-token-expired';
import { siteMap } from '../../../../routes/site-map';
import { setSelectedTab } from '../../../property/redux/dynamicMapUtilsSlice';
import useGetLocalization from '../../../localization/get-localization';

export const SellerSecondLandingPage: FC = () => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const { push } = useHistory();
  const lang = useGetLocalization();
  const dispatch = useAppDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const addressId = urlParams.get('addressId') || '';
  const sellerPropertyId = urlParams.get('sellerPropertyId') || '';
  const openTab = urlParams.get('openTab') || '';

  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const exp = useAppSelector((state) => state.auth.exp);

  if (!isAnonymousUser && exp) {
    const isExpired = isTokenExpired(exp);
    if (!isExpired) {
      if (Number(openTab)) {
        dispatch(setSelectedTab(Number(openTab)));
      } else {
        dispatch(setSelectedTab(0));
      }

      return (
        <Redirect
          to={`/${lang}${siteMap.SellingProcessPage.pathWithoutParams}/${sellerPropertyId}`}
        />
      );
    }
  }

  return (
    <Container>
      <Header content={t('seller-second-landing-page.title')} />
      <Subtitle content={t('seller-second-landing-page.subtitle')} />
      <LoginButton
        label={t('button.login-now')}
        onClick={() =>
          push(
            `/seller-login-page/?addressId=${addressId}&sellerPropertyId=${sellerPropertyId}&openTab=${openTab}`
          )
        }
        fluid
        color={themeContext.blue}
        borderColor={themeContext.blue}
      />
      <Divider>
        <DividerText
          content={t('seller-second-landing-page.divider.first-line')}
        />
        <DividerText
          content={t('seller-second-landing-page.divider.second-line')}
        />
      </Divider>
      <RegisterButton
        label={t('seller-second-landing-page.button.register')}
        onClick={() =>
          push(
            `/seller-sign-up-page/?addressId=${addressId}&sellerPropertyId=${sellerPropertyId}`
          )
        }
        fluid
      />
    </Container>
  );
};
