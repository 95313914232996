import { useTranslation } from 'react-i18next';

import { useFinancingsQuery } from '../../../services/graphql/enhanced';
import { useAppSelector } from '../../common/hooks';
import {
  propertyTypeBoxes,
  reasonBoxes,
} from '../../forms/form-financing/constants';

interface IProps {
  ownerId?: string;
  isSkip?: boolean;
}

const useGetFinancings = ({ ownerId, isSkip }: IProps) => {
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const { t } = useTranslation();

  const { financings, isLoading, error, isFetching, isSuccess } =
    useFinancingsQuery(
      { ownerId },
      {
        skip: !ownerId || isAnonymousUser || !!isSkip,
        selectFromResult: ({
          data,
          isFetching: isFetchingData,
          isLoading: isLoadingData,
          error: errorData,
          isSuccess: isSuccessData,
        }) => {
          const financingsData = data?.financings
            .map((item) => {
              const financingData =
                item?.financingVariants?.[0].financingVariantData;
              const { address } = financingData.location;
              const addressTitle = `${
                address.postCode ? `${address.postCode}, ` : ''
              }${address.locality ? `${address.locality}, ` : ''}${
                address.neighborhood ? `${address.neighborhood}, ` : ''
              }${address.city}`;
              const propertyType = propertyTypeBoxes.find(
                (type) => type.value === financingData?.propertyType
              )?.title;
              const reason = reasonBoxes.find(
                (reasonItem) => reasonItem.value === financingData?.reason
              )?.title;

              const title = `${t(reason || '')}, ${t(propertyType || '')}`;

              return {
                _id: item._id,
                address: addressTitle,
                title: title.toUpperCase(),
              };
            })
            .reverse();

          return {
            financings: financingsData,
            isLoading: isLoadingData,
            isFetching: isFetchingData,
            error: errorData,
            isSuccess: isSuccessData,
          };
        },
      }
    );

  return { financings, isLoading, isFetching, error, isSuccess };
};

export { useGetFinancings };
