import { FC } from 'react';
import { ReactComponent as Pencil } from 'assets/streamline-light/interface-essential/edit/pencil.svg';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { Modal, ModalTitle } from '../../../../common/components/modal';
import { toggleIsEditSearchProfileModalOpen } from '../../redux/searchProfileSlice';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { EditSearchProfileForm } from '../forms/edit-search-profile-form/edit-search-profile-form';

const EditSearchProfileModal: FC = () => {
  const isEditSearchProfileModalOpen = useAppSelector(
    (state) => state.searchProfile.isEditSearchProfileModalOpen
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const closeModal = () => dispatch(toggleIsEditSearchProfileModalOpen(false));
  const wrapperStyles = isMobileSize
    ? {
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
        height: '100%',
        overflow: 'overlay',
        padding: '24px',
      }
    : { maxWidth: '528px' };

  return (
    <Modal
      isOpen={isEditSearchProfileModalOpen}
      wrapperStyles={wrapperStyles}
      onClose={closeModal}
    >
      {!isMobileSize && (
        <ModalTitle
          icon={Pencil}
          text={t('edit.search-profile.modal.title')}
          iconPosition="left"
          textAlign="left"
        />
      )}
      <EditSearchProfileForm />
    </Modal>
  );
};

export { EditSearchProfileModal };
