import { FC } from 'react';
import styled from 'styled-components';

interface IProps {
  content: string;
}

const Container = styled.p`
  margin: 0;
  font-family: 'Roboto' !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};

  a {
    color: ${(props) => props.theme.blue};
  }
`;

const ParagraphTextWithHTML: FC<IProps> = ({ content, ...rest }) => (
  <Container dangerouslySetInnerHTML={{ __html: content }} {...rest} />
);

export { ParagraphTextWithHTML };
