import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Container, ContainerValue, Title } from './data-container-styles';
import { ExpandableContent } from '../../../../../../common/components/ui/expandable-content';
import { getTotalTermOfLoanUntilFullRepayment } from '../../../../../utils/getTotalTermOfLoanUntilFullRepayment';
import { numberFormat } from '../../../../../utils/numberFormat';
import { IMobileVariant } from '../../../../../constants';
import { useGetFinancing } from '../../../../../hooks/useGetFinancing';
import { useHiddenVariants } from '../../../../../hooks/useHiddenVariants';
import { FinancingReason } from '../../../../../../../generated';
import { formatPeriodDate } from '../../../../../../property/utils/date';

interface IProps {
  period: string;
}

const DataContainer: FC<IProps> = ({ period }) => {
  const { t } = useTranslation();
  const { financingId } = useParams<{ financingId: string }>();
  const { financingVariants } = useGetFinancing({
    financingId,
  });
  const { hiddenVariants } = useHiddenVariants();

  const adaptedVariants = useMemo(() => {
    if (!financingVariants) {
      return {
        tableData: [],
      };
    }

    const financingVariantsNotHidden = financingVariants.filter(
      (_, i) => !hiddenVariants.includes(i)
    );
    const adaptedData = financingVariantsNotHidden.map((variant) => {
      const financingPropositionItem = variant.financingPropositions.find(
        (item) => item.debitRateCommitment === period
      );
      const proposition = financingPropositionItem?.propositions?.[0];
      const buildingBlock = proposition?.financingBuildingBlocks?.[0];

      const totalTermOfLoanUntilFullRepayment =
        getTotalTermOfLoanUntilFullRepayment(
          buildingBlock?.dateOfLastInstallment ?? ''
        );
      let repaidAmount = 0;
      if (
        buildingBlock?.loanAmount &&
        buildingBlock?.residualDebtAfterTheEndOfTheFixedInterestPeriod
      ) {
        repaidAmount = Math.round(
          buildingBlock?.loanAmount -
            buildingBlock?.residualDebtAfterTheEndOfTheFixedInterestPeriod
        );
      }

      let totalInterestPayments = 0;
      if (
        buildingBlock?.annuityDetails?.fixedInterestInYears &&
        buildingBlock?.rateMonthly &&
        buildingBlock?.loanAmount &&
        repaidAmount
      ) {
        totalInterestPayments =
          buildingBlock?.annuityDetails?.fixedInterestInYears *
            12 *
            buildingBlock?.rateMonthly -
          repaidAmount;
      }

      let totalInterestPaymentsEnd = 0;

      if (buildingBlock?.costOverTotalTerm && buildingBlock?.loanAmount) {
        totalInterestPaymentsEnd = Math.round(
          buildingBlock?.costOverTotalTerm - buildingBlock?.loanAmount
        );
      }
      const {
        name,
        reason,
        propertyValue,
        desiredLoanAmount,
        purchasePrice,
        landPrice,
        equityCapital,
        buildingCosts,
        payoutDate,
        estimatedRemainingDebt,
      } = variant.financingVariantData;

      let purchasePriceTable = 0;
      let propertyValueTable = 0;
      let desiredLoanAmountTable = 0;
      let landPriceTable = 0;
      let equityCapitalTable = 0;
      let buildingCostsTable = 0;
      let payoutDateTable;
      let estimatedRemainingDebtValue = 0;
      switch (reason) {
        case FinancingReason.PurchaseOfExistingProperty:
        case FinancingReason.PurchaseOfNewBuilding:
          purchasePriceTable = Number(purchasePrice);
          equityCapitalTable = Number(equityCapital);
          break;
        case FinancingReason.FollowUpFinancing:
          propertyValueTable = Number(propertyValue);
          payoutDateTable = payoutDate;
          propertyValueTable = Number(propertyValue);
          estimatedRemainingDebtValue = Number(estimatedRemainingDebt);
          break;
        case FinancingReason.RaisingCapital:
        case FinancingReason.Modernization:
          propertyValueTable = Number(propertyValue);
          desiredLoanAmountTable = Number(desiredLoanAmount);
          break;
        case FinancingReason.OwnConstructionProject:
          landPriceTable = Number(landPrice);
          buildingCostsTable = Number(buildingCosts);
          equityCapitalTable = Number(equityCapital);
          break;
        default:
          break;
      }

      const data = {
        maximalPurchasePrice:
          financingPropositionItem?.maximalPurchasePrice || '',
        purchasePriceTable,
        propertyValueTable,
        desiredLoanAmountTable,
        landPriceTable,
        equityCapitalTable,
        buildingCostsTable,
        payoutDateTable,
        estimatedRemainingDebtValue,
        loanAmount: proposition?.loanAmount || '',
        totalRatePerMonth: proposition?.totalRatePerMonth || '',
        shouldInterest: proposition?.shouldInterest || '',
        repaymentRateInPercent: buildingBlock?.repaymentRateInPercent || '',
        totalTermOfLoanUntilFullRepayment,
        // period: proposition.fixedInterestRateInYearsMinMax || '',
        residualDebt:
          buildingBlock?.residualDebtAfterTheEndOfTheFixedInterestPeriod || '',
        repaidAmount,
        totalInterestPayments,
        totalInterestPaymentsEnd,
        closingRate: buildingBlock?.closingRate || '',
      };

      return {
        name,
        maximalPurchasePrice: data.maximalPurchasePrice
          ? `${numberFormat(Number(data.maximalPurchasePrice))} €`
          : '—',
        buildingCosts: data.buildingCostsTable
          ? `${numberFormat(data.buildingCostsTable)} €`
          : '—',
        purchasePrice: data.purchasePriceTable
          ? `${numberFormat(data.purchasePriceTable)} €`
          : '—',
        propertyValue: data.propertyValueTable
          ? `${numberFormat(data.propertyValueTable)} €`
          : '—',
        desiredLoanAmount: data.desiredLoanAmountTable
          ? `${numberFormat(data.desiredLoanAmountTable)} €`
          : '—',
        landPrice: data.landPriceTable
          ? `${numberFormat(data.landPriceTable)} €`
          : '—',
        equityCapital: data.equityCapitalTable
          ? `${numberFormat(data.equityCapitalTable)} €`
          : '—',
        payoutDate: data.payoutDateTable
          ? formatPeriodDate(new Date(data.payoutDateTable), true, true)
          : '—',
        estimatedRemainingDebt: data.estimatedRemainingDebtValue
          ? `${numberFormat(data.estimatedRemainingDebtValue)} €`
          : '—',
        loanAmount: data.loanAmount
          ? `${numberFormat(Number(data.loanAmount))} €`
          : '—',
        totalRatePerMonth: data.totalRatePerMonth
          ? `${numberFormat(Number(data.totalRatePerMonth))} €`
          : '—',
        shouldInterest: data.shouldInterest
          ? `${numberFormat(Number(data.shouldInterest), true)} %`
          : '—',
        annuity: data.repaymentRateInPercent
          ? `${numberFormat(
              Number(proposition?.shouldInterest) +
                Number(
                  proposition?.financingBuildingBlocks[0].repaymentRateInPercent
                ),
              true
            )} %`
          : '—',
        repaymentRateInPercent: data.repaymentRateInPercent
          ? `${numberFormat(Number(data.repaymentRateInPercent), true)} %`
          : '—',
        totalTermOfLoanUntilFullRepayment:
          data.totalTermOfLoanUntilFullRepayment,
        // period: `${numberFormat(Number(period))} Jahren`,
        residualDebt: data.residualDebt
          ? `${numberFormat(Number(data.residualDebt))} €`
          : '—',
        repaidAmount: data.repaidAmount
          ? `${numberFormat(Number(data.repaidAmount))} €`
          : '—',
        totalInterestPayments: data.totalInterestPayments
          ? `${numberFormat(Number(data.totalInterestPayments))} €`
          : '—',
        totalInterestPaymentsEnd: data.totalInterestPaymentsEnd
          ? `${numberFormat(Number(data.totalInterestPaymentsEnd))} €`
          : '—',
        closingRate: data.closingRate
          ? `${numberFormat(Number(data.closingRate))} €`
          : '—',
      };
    });

    const checkSameData = (values: { name: string; value: string }[]) => {
      return new Set(values.map((item) => item.value)).size !== 1
        ? {
            values: values.map((item) => ({
              name: item.name,
              value: item.value,
            })),
          }
        : {
            value: values[0].value,
          };
    };

    const tableData: IMobileVariant[] = [
      // second table
      {
        categoryName: '',
        dataTypes: [
          // {
          //   label: adaptedData[0].priceLabel,
          //   keyValue: 'purchasePrice',
          //   ...checkSameData(
          //     adaptedData.map((item) => ({
          //       name: item.name,
          //       value: item.priceValue,
          //     }))
          //   ),
          // },

          ...(adaptedData?.some(
            (item) => item.buildingCosts && item.buildingCosts !== '—'
          )
            ? [
                {
                  label: 'financing-portal.form.inputs.building-costs.label',
                  keyValue: 'buildingCosts',
                  ...checkSameData(
                    adaptedData.map((item) => ({
                      name: item.name,
                      value: item.buildingCosts,
                    }))
                  ),
                },
              ]
            : []),

          ...(adaptedData?.some(
            (item) => item.purchasePrice && item.purchasePrice !== '—'
          )
            ? [
                {
                  label: 'financing-portal.form.inputs.purchase-price.label',
                  keyValue: 'purchasePrice',
                  ...checkSameData(
                    adaptedData.map((item) => ({
                      name: item.name,
                      value: item.purchasePrice,
                    }))
                  ),
                },
              ]
            : []),

          ...(adaptedData?.some(
            (item) => item.propertyValue && item.propertyValue !== '—'
          )
            ? [
                {
                  label: 'financing-portal.form.inputs.property-value.label',
                  keyValue: 'propertyValue',
                  ...checkSameData(
                    adaptedData.map((item) => ({
                      name: item.name,
                      value: item.propertyValue,
                    }))
                  ),
                },
              ]
            : []),

          ...(adaptedData?.some(
            (item) => item.desiredLoanAmount && item.desiredLoanAmount !== '—'
          )
            ? [
                {
                  label:
                    'financing-portal.form.inputs.desired-loan-amount.label',
                  keyValue: 'desiredLoanAmount',
                  ...checkSameData(
                    adaptedData.map((item) => ({
                      name: item.name,
                      value: item.desiredLoanAmount,
                    }))
                  ),
                },
              ]
            : []),

          ...(adaptedData?.some(
            (item) => item.landPrice && item.landPrice !== '—'
          )
            ? [
                {
                  label: 'financing-portal.form.inputs.land-price.label',
                  keyValue: 'landPrice',
                  ...checkSameData(
                    adaptedData.map((item) => ({
                      name: item.name,
                      value: item.landPrice,
                    }))
                  ),
                },
              ]
            : []),

          ...(adaptedData?.some(
            (item) => item.equityCapital && item.equityCapital !== '—'
          )
            ? [
                {
                  label: 'financing-portal.form.inputs.equity-capital.label',
                  keyValue: 'equityCapital',
                  ...checkSameData(
                    adaptedData.map((item) => ({
                      name: item.name,
                      value: item.equityCapital,
                    }))
                  ),
                },
              ]
            : []),

          ...(adaptedData?.some(
            (item) => item.payoutDate && item.payoutDate !== '—'
          )
            ? [
                {
                  label: 'financing-portal.form.inputs.payout-date.label',
                  keyValue: 'payoutDate',
                  ...checkSameData(
                    adaptedData.map((item) => ({
                      name: item.name,
                      value: item.payoutDate,
                    }))
                  ),
                },
              ]
            : []),

          ...(adaptedData?.some(
            (item) =>
              item.estimatedRemainingDebt && item.estimatedRemainingDebt !== '—'
          )
            ? [
                {
                  label:
                    'financing-portal.form.inputs.estimated-remaining-debt.label',
                  keyValue: 'estimatedRemainingDebt',
                  ...checkSameData(
                    adaptedData.map((item) => ({
                      name: item.name,
                      value: item.estimatedRemainingDebt,
                    }))
                  ),
                },
              ]
            : []),
          {
            label:
              'financing-portal-page.tabs.calculator.data.table.loan-amount',
            keyValue: 'loanAmount',
            ...checkSameData(
              adaptedData.map((item) => ({
                name: item.name,
                value: item.loanAmount,
              }))
            ),
          },
        ],
      },
      // third table
      {
        categoryName:
          'financing-portal-page.tabs.calculator.data.table.monthly-loan-installment',
        dataTypes: [
          {
            label:
              'financing-portal-page.tabs.calculator.data.table.debit-interest',
            keyValue: 'shouldInterest',
            ...checkSameData(
              adaptedData.map((item) => ({
                name: item.name,
                value: item.shouldInterest,
              }))
            ),
          },
          {
            label:
              'financing-portal-page.tabs.calculator.data.table.repayment-in-the-first-year',
            keyValue: 'repaymentRateInPercent',
            ...checkSameData(
              adaptedData.map((item) => ({
                name: item.name,
                value: item.repaymentRateInPercent,
              }))
            ),
          },
          {
            label: 'financing-portal-page.tabs.calculator.data.table.annuity',
            keyValue: 'annuity',
            ...checkSameData(
              adaptedData.map((item) => ({
                name: item.name,
                value: item.annuity,
              }))
            ),
          },
          {
            label:
              'financing-portal-page.tabs.calculator.data.table.total-term-of-the-loan-until-full-repayment',
            keyValue: 'totalTermOfLoanUntilFullRepayment',
            initHeight: 70,
            ...checkSameData(
              adaptedData.map((item) => ({
                name: item.name,
                value: item.totalTermOfLoanUntilFullRepayment,
              }))
            ),
          },
        ],
      },
      // fourth table
      {
        categoryName: `Am Ende der Zinsbindung von ${period} Jahren`,
        dataTypes: [
          {
            label:
              'financing-portal-page.tabs.calculator.data.table.residual-debt',
            keyValue: 'residualDebtAfterTheEndOfTheFixedInterestPeriod',
            ...checkSameData(
              adaptedData.map((item) => ({
                name: item.name,
                value: item.residualDebt,
              }))
            ),
          },
          {
            label:
              'financing-portal-page.tabs.calculator.data.table.repaid-amount',
            keyValue: 'repaidAmount',
            ...checkSameData(
              adaptedData.map((item) => ({
                name: item.name,
                value: item.repaidAmount,
              }))
            ),
          },
          {
            label:
              'financing-portal-page.tabs.calculator.data.table.total-interest-payments',
            keyValue: 'totalInterestPayments',
            ...checkSameData(
              adaptedData.map((item) => ({
                name: item.name,
                value: item.totalInterestPayments,
              }))
            ),
          },
        ],
      },
      // fifth table
      {
        categoryName:
          'financing-portal-page.tabs.calculator.data.table.at-the-end-of-the-loan',
        dataTypes: [
          {
            label:
              'financing-portal-page.tabs.calculator.data.table.repaid-amount',
            keyValue: 'loanAmount',
            ...checkSameData(
              adaptedData.map((item) => ({
                name: item.name,
                value: item.loanAmount,
              }))
            ),
          },
          {
            label:
              'financing-portal-page.tabs.calculator.data.table.total-interest-payments',
            keyValue: 'totalInterestPaymentsEnd',
            ...checkSameData(
              adaptedData.map((item) => ({
                name: item.name,
                value: item.totalInterestPaymentsEnd,
              }))
            ),
          },
          {
            label:
              'financing-portal-page.tabs.calculator.data.table.last-loan-installment',
            keyValue: 'closingRate',
            ...checkSameData(
              adaptedData.map((item) => ({
                name: item.name,
                value: item.closingRate,
              }))
            ),
          },
        ],
      },
    ];
    return {
      tableData: tableData || [],
    };
  }, [financingVariants, period, hiddenVariants]);

  return (
    <Container>
      {adaptedVariants.tableData.map((item) => (
        <>
          {item.categoryName && <Title>{t(item.categoryName)}</Title>}
          {item.dataTypes.map((data) => {
            return data.values ? (
              <ExpandableContent
                expanded
                style={{ marginBottom: 16 }}
                initHeight={data.initHeight || 46}
                title={t(data.label)}
              >
                {data.values.map((value) => (
                  <ContainerValue>
                    <div>{value.name}</div>
                    <div>{value.value}</div>
                  </ContainerValue>
                ))}
              </ExpandableContent>
            ) : (
              <ExpandableContent
                expanded
                style={{ marginBottom: 16 }}
                initHeight={data.initHeight || 46}
                title={t(data.label)}
              >
                <ContainerValue isSingleValue>
                  <div>
                    {t(
                      'financing-portal.variants-compare.mobile.same-for-all-selected-variants'
                    )}
                  </div>
                  <div>{data?.value}</div>
                </ContainerValue>
              </ExpandableContent>
            );
          })}
        </>
      ))}
    </Container>
  );
};

export { DataContainer };
