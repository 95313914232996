import styled from 'styled-components';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { device } from '../../../../style/theme';

export const Container = styled.div``;

export const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 3px;
  background-color: ${hexToRGB('#e5e9ec', 0.9)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media ${device.mobile} {
    width: 40px;
    height: 40px;
  }
}
`;
