import { FC, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LoaderContainer } from 'modules/seller/pages/seller-properties-page/seller-properties-page-styles';
import { ValuationLoader } from 'modules/forms/components/common/loader';

import { useParams } from 'react-router-dom';
import { device } from '../../../../style/theme';
import {
  useDisabledShopServicesQuery,
  ServicesInput,
  BrokerPermissions,
  useCheckBrokerSettingsPermissionQuery,
} from '../../../../generated';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { BrokerServicesContainer } from '../broker-settings/services/services-container';
import { brokerSettingsServicesList, servicesList } from '../../constants';
import { IBrokerSettingsForm } from '../../interfaces';

const Container = styled.div`
  position: relative;
  display: flex;
  @media ${device.tablet} {
    flex-direction: column;
    padding: 16px;
  }
`;

const FirstColumn = styled.div<{ isBlurred?: boolean }>`
  flex: 1.72;
  //max-width: 688px;
  margin-right: 16px;
  ${(props) => props.isBlurred && 'filter: blur(6px)'};

  @media ${device.tablet} {
    margin-bottom: 16px;
    max-width: 100%;
    width: 100%;
  }
`;

const SecondColumn = styled.div`
  flex: 1;
  max-width: 400px;
  @media ${device.tablet} {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

interface IProps {
  disabledPropertyServices: string[] | null;
}

const BrokerSettingsBase: FC<IProps> = ({ disabledPropertyServices }) => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const brokerId = urlParams.get('userid') || '';
  const { propertyId } = useParams<{ propertyId: string }>();

  const { brokerPermissions, isBrokerPermissionsLoading } =
    useCheckBrokerSettingsPermissionQuery(
      { propertyId, brokerId },
      {
        selectFromResult: ({ data, isLoading }) => {
          return {
            brokerPermissions: data?.checkBrokerSettingsPermission,
            isBrokerPermissionsLoading: isLoading,
          };
        },
        skip: !brokerId,
      }
    );

  const { preloadedShopServices, isServicesLoading, errors } =
    useDisabledShopServicesQuery(
      {
        propertyId,
      },
      {
        selectFromResult: ({ data, isLoading, error }) => {
          const preloadedServices = brokerSettingsServicesList.reduce(
            (accumulator, currentValue) => ({
              ...accumulator,
              [`${currentValue}Shop`]:
                !data?.disabledShopServices?.includes(currentValue),
              [`${currentValue}Property`]: disabledPropertyServices
                ? !disabledPropertyServices?.includes(currentValue)
                : !data?.disabledShopServices?.includes(currentValue),
            }),
            {}
          );

          return {
            preloadedShopServices: preloadedServices as IBrokerSettingsForm,
            errors: error,
            isServicesLoading: isLoading,
          };
        },
      }
    );

  return (
    <>
      {/* {isBrokerView && ( */}
      {/*  <Banner */}
      {/*    icon={ReadHuman} */}
      {/*    image={BannerImage} */}
      {/*    title={t('seller-portal.broker-view.banner.title')} */}
      {/*    description={t('seller-portal.broker-view.banner.description')} */}
      {/*  /> */}
      {/* )} */}

      <Container>
        <FirstColumn>
          {errors?.message && (
            <GlobalError
              title={t(errors?.message?.split(':')[0].toLowerCase())}
            />
          )}
          {isServicesLoading || isBrokerPermissionsLoading ? (
            <LoaderContainer isLoadingMore>
              <ValuationLoader maxWidth="500px" />
            </LoaderContainer>
          ) : (
            <BrokerServicesContainer
              preloadedServices={preloadedShopServices}
              brokerPermissions={brokerPermissions}
            />
          )}
        </FirstColumn>

        {/* <SecondColumn> */}
        {/*  <ProgressCard isBlurred={isLocked} /> */}
        {/*  <TeamContactCard /> */}
        {/* </SecondColumn> */}
      </Container>
    </>
  );
};

const BrokerSettings = memo(BrokerSettingsBase);

export { BrokerSettings };
