import { memo, useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';

import { ReactComponent as LocationMarker } from 'assets/streamline-light/maps-navigation/pins/pin.svg';
import { ReactComponent as CashPaymentBag } from 'assets/streamline-light/money-payments-finance/cash-payments/cash-payment-bag.svg';
import { ReactComponent as AccountingBills } from 'assets/streamline-light/money-payments-finance/money/accounting-bills-1.svg';
import { ReactComponent as MoneyWallet } from 'assets/streamline-light/money-payments-finance/money/money-wallet-1.svg';
import { useTranslation } from 'react-i18next';
import { useToLocalWithCurrency } from '../../../common/utils/convertCHF';
import {
  CardContainer,
  CardContent,
  CardImage,
  CardTitle,
  ImageWrapper,
  LabelsContainer,
  Location,
  LocationIcon,
  MapWrapper,
  MarketingLabel,
  NewLabel,
  PropertyDetailsContainer,
  Row,
  SalesSimulationLabel,
  SoldLabel,
  Tag,
  CardWrapper,
  DeactivatedLabel,
} from './seller-property-card-styles';
import { SellerPropertyCardActionPanel } from './seller-property-card-action-panel';
import { PropertyIcon } from '../icon/icon';
import { Coordinates, Currency, PropertyStatus } from '../../../../generated';
import { Mapbox } from '../map/map';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { useGetActionPanelButtons } from '../../hooks/useGetActionPanelButtons';

interface IProps {
  id: string;
  title: string;
  currency: Currency;
  imageSrc: string;
  propertyId: string;
  address: string;
  highestBid: number;
  evaluation: number;
  sellingPrice: number;
  status?: PropertyStatus;
  coordinates?: Coordinates;
  newBidsNumber?: number;
  soldPrice?: number;
  isDigitalServiceActivated?: boolean;
  isDigitalServiceLockedByBroker?: boolean;
}

const SellerPropertyCardBase = ({
  id,
  title,
  currency,
  imageSrc,
  propertyId,
  address,
  highestBid,
  evaluation,
  sellingPrice,
  status = PropertyStatus.Initial,
  coordinates,
  newBidsNumber,
  soldPrice,
  isDigitalServiceActivated,
  isDigitalServiceLockedByBroker,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const iconStyle = useMemo(
    () => ({
      minWidth: '16px',
      minHeight: '16px',
      maxWidth: '16px',
      maxHeight: '16px',
    }),
    []
  );

  const isMobileSize = useIsMobileSize();
  const { TopButton, FirstBottomButton, SecondBottomButton } =
    useGetActionPanelButtons(status, id, isDigitalServiceActivated);

  const localEvaluation = useToLocalWithCurrency(evaluation ?? 0, currency);
  const localHighestBid = useToLocalWithCurrency(highestBid ?? 0, currency);
  const localSellingPrice = useToLocalWithCurrency(sellingPrice ?? 0, currency);

  return (
    <CardWrapper>
      <LabelsContainer>
        {isDigitalServiceLockedByBroker ? (
          <DeactivatedLabel>
            {t('seller-properties-list.deactivated-by-broker.label')}
          </DeactivatedLabel>
        ) : (
          <>
            {status === PropertyStatus.SellingSimulation && (
              <SalesSimulationLabel>
                {t('seller-properties-list.selling-simulation.label')}
              </SalesSimulationLabel>
            )}
            {status === PropertyStatus.Selling && (
              <>
                <MarketingLabel>
                  {t('seller-properties-list.marketing.label')}
                </MarketingLabel>
                {!!newBidsNumber && (
                  <NewLabel>
                    {newBidsNumber === 1
                      ? t('seller-properties-list.single-bid.label')
                      : t('seller-properties-list.bids.label', {
                          bidsCount: newBidsNumber,
                        })}
                  </NewLabel>
                )}
              </>
            )}
            {status === PropertyStatus.Sold && (
              <SoldLabel>{t('seller-properties-list.sold.label')}</SoldLabel>
            )}
          </>
        )}
      </LabelsContainer>
      <CardContainer isDisabled={status === PropertyStatus.Sold}>
        {!imageSrc && coordinates ? (
          <MapWrapper isMobileSize={isMobileSize}>
            <Mapbox isVisible coordinates={coordinates} />
          </MapWrapper>
        ) : (
          <ImageWrapper>
            <CardImage src={imageSrc} />
          </ImageWrapper>
        )}
        <CardContent>
          <Row>
            <CardTitle>{title}</CardTitle>
          </Row>
          <Row justifyContent={'space-between'}>
            <Row>
              <LocationIcon
                icon={LocationMarker}
                color={themeContext.blue}
                style={iconStyle}
              />
              <Location>{address}</Location>
            </Row>
            {/* {propertyId && <Tag>{`# ${propertyId}`}</Tag>} */}
          </Row>
          <PropertyDetailsContainer>
            {(status === PropertyStatus.Initial ||
              status === PropertyStatus.SellingSimulation) && (
              <PropertyIcon
                icon={AccountingBills}
                value={localEvaluation as string}
                label={t('seller-properties-list.evaluation-price')}
                iconStyle={iconStyle}
              />
            )}
            {status === PropertyStatus.Selling && (
              <>
                {highestBid ? (
                  <PropertyIcon
                    icon={CashPaymentBag}
                    value={localHighestBid as string}
                    label={t('seller-properties-list.highest-bid.label')}
                    iconStyle={iconStyle}
                  />
                ) : null}

                <PropertyIcon
                  icon={AccountingBills}
                  value={
                    sellingPrice
                      ? (localSellingPrice as string)
                      : t(
                          'seller-properties-list.evaluation-price.in-consultation-with-broker'
                        )
                  }
                  label={t('seller-properties-list.price.label')}
                  iconStyle={iconStyle}
                />
              </>
            )}
            {status === PropertyStatus.Sold && (
              <>
                {/* <PropertyIcon */}
                {/*  icon={MoneyWallet} */}
                {/*  value={new Intl.NumberFormat('en-GB', { */}
                {/*    style: 'currency', */}
                {/*    currency, */}
                {/*  }).format(soldPrice ?? 0)} */}
                {/*  label={t('seller-properties-list.price.label')} */}
                {/*  iconStyle={iconStyle} */}
                {/* /> */}
                <PropertyIcon
                  icon={AccountingBills}
                  value={t('seller-properties-list.sold.label')}
                  label={t('seller-properties-list.evaluation-price')}
                  iconStyle={iconStyle}
                />
              </>
            )}
          </PropertyDetailsContainer>
          <SellerPropertyCardActionPanel
            TopButton={TopButton}
            FirstBottomButton={FirstBottomButton}
            SecondBottomButton={SecondBottomButton}
          />
        </CardContent>
      </CardContainer>
    </CardWrapper>
  );
};

const SellerPropertyCard = memo(SellerPropertyCardBase);

export { SellerPropertyCard };
