import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InformationCircleBold } from 'assets/images/icons/common/tooltip-key-bold.svg';

import { Container, ValueWrapper } from './calculate-value-styles';
import {
  Info,
  InfoWrapper,
  Label,
  LabelContainer,
  TooltipContainer,
  TooltipParagraph,
} from '../../../../../../components/common/input-v2/input-v2-styles';
import { Tooltip } from '../../../../../../../common/components/tooltip';

interface IProps {
  tooltipWidth?: number;
  label: string;
  value: string;
  tooltipKey: string;
  isError?: boolean;
}

const CalculateValue: FC<IProps> = ({
  tooltipWidth,
  label,
  value,
  tooltipKey,
  isError,
}) => {
  const { t } = useTranslation();

  const onClickLabel = () => {
    if (tooltipKey) {
      const tooltip = document.querySelector(
        `#${tooltipKey.replaceAll('.', '-')}-icon`
      ) as HTMLParagraphElement;
      if (tooltip) {
        tooltip.click();
      }
    }
  };

  return (
    <Container>
      <InfoWrapper>
        <LabelContainer isTooltipKeyLeftSide onClick={onClickLabel}>
          <Label className="label" isOldDesign isLabelSaturated>
            {t(label)}
          </Label>
        </LabelContainer>
        <Info tooltipWidth={tooltipWidth}>
          <Tooltip
            icon={InformationCircleBold}
            id={tooltipKey}
            position="bottom"
            isOnClick
            width={16}
            height={16}
          >
            <TooltipContainer>
              <TooltipParagraph>{t(tooltipKey)}</TooltipParagraph>
            </TooltipContainer>
          </Tooltip>
        </Info>
      </InfoWrapper>
      <ValueWrapper isError={isError}>{value}</ValueWrapper>
    </Container>
  );
};

export { CalculateValue };
