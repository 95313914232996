import queryString from 'query-string';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import styled from 'styled-components';

import {
  LoginResponse,
  useCheckInvitationHealthQuery,
  useConfirmPhoneMutation,
  useResendConfirmationCodeMutation,
  UserState,
  UserType,
  useSignInMutation,
  useSignUpMutation,
  useUserSelfDeleteMutation,
} from '../../../../generated';
import { client } from '../../../../services/graphql/graphql';
import { ConfirmEmail } from '../../../auth/components/confirm-email';
import { PhoneVerification } from '../../../auth/components/forms/phone-verification';
import { phonePrefixes } from '../../../auth/constants';
import {
  InputType,
  IRegisterForm,
  SharePropertyCreateAccountForm,
} from '../../../auth/interfaces';
import {
  login,
  logout,
  setAnonymousUser,
  setUserId,
  setUserInput,
  setUsername,
  setUserState,
} from '../../../auth/redux/authSlice';
import { selectFormattedPhone } from '../../../auth/redux/selectors/selectFormattedPhone';
import { parseRegisterInput } from '../../../auth/utils/parse-register-input';
import {
  Checkbox,
  InputOutsideTopLabelWithSelect,
  InputPassOutsideTopLabel,
} from '../../../common/components/form';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { FormColumn, FormRow } from '../../../common/components/form/form-grid';
import { MainButton } from '../../../common/components/ui/buttons';
import { Headline1 } from '../../../common/components/ui/typography';
import { StatusType } from '../../../common/components/verification-input/types';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useActualExperiment } from '../../../experiments/hooks/useActualExperiment';
import { VisibilityWrapper } from '../../../forms/components/common/visibility-wrapper';
import { NoAccess } from '../sign-in/no-acess';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import useGetLocalization from '../../../localization/get-localization';
import { useGetCountry } from '../../../localization/get-country';
import { siteMap } from '../../../../routes/site-map';

const Container = styled.div`
  flex: 1;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div:last-of-type {
    justify-content: center;
    align-items: center;

    > div:last-of-type {
      margin-top: 12px;
      align-self: stretch;

      button {
        width: 100%;
      }
    }
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 32px;
`;

const SubmitButton = styled(MainButton)`
  justify-content: center;
`;

const Disclaimer = styled.span`
  opacity: 0.8;
  font-family: Roboto, sans-serif !important;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};

  a {
    color: ${(props) => props.theme.blue};
  }
`;
const Title = styled(Headline1)`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 32px;
`;

const ExtendErrors = styled(GlobalError)`
  &&& {
    margin-top: 24px;
    margin-bottom: -24px;
  }
`;

const PasswordChangeForm = (): JSX.Element => {
  const methods = useForm<IRegisterForm>({
    mode: 'onTouched',
  });

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { t } = useTranslation();
  const { invitationId } = useParams<{ invitationId: string }>();
  // const lngHPush = useLngHistoryPush();
  const lang = useGetLocalization();
  const { phonePrefix } = useGetCountry();

  const { registrationBy } = useActualExperiment();

  const userState = useAppSelector((state) => state.auth.userState);
  // const formattedPhone = useAppSelector(selectFormattedPhone);
  // const username = useAppSelector((state) => state.auth.userInput?.username);
  // const emailStore = useAppSelector((state) => state.auth.userInput?.email);
  // const password = useAppSelector((state) => state.auth.userInput?.password);
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);

  const { firstName, lastName, email } = queryString.parse(search);

  const [healthInput] = useState({
    email: email as string,
    inviteId: invitationId,
  });

  const { error: invitationError, isLoading: isInvitationHealthCheckLoading } =
    useCheckInvitationHealthQuery({
      input: healthInput,
    });

  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState<unknown | null>(null);
  const [invalidInvitation, setInvalidInvitation] = useState(false);
  // const [verificationStatus, setVerificationStatus] = useState(
  //   StatusType.process
  // );

  const [signUp] = useSignUpMutation();
  // const [signIn] = useSignInMutation();
  // const [confirmPhone] = useConfirmPhoneMutation();
  // const [resendConfirmationCode] = useResendConfirmationCodeMutation();
  // const [changePhone] = useUserSelfDeleteMutation();

  const onSubmit: SubmitHandler<SharePropertyCreateAccountForm> = useCallback(
    async (formData) => {
      if (firstName && lastName && email) {
        setLoading(true);
        const input = parseRegisterInput({
          formData: {
            ...formData,
            name: firstName as string,
            surname: lastName as string,
            email: email as string,
            registrationBy,
          },
          lang,
          userType: UserType.Owner,
        });

        dispatch(
          setUserInput({
            ...formData,
            name: firstName as string,
            surname: lastName as string,
            email: email as string,
          })
        );

        try {
          const signUpResponse = await signUp({
            input: { ...input, lang, userType: [UserType.Owner] },
          }).unwrap();
          if (signUpResponse?.signUp?._id) {
            const {
              _id: id,
              username: newUsername,
              userState: newUserState,
            } = signUpResponse.signUp;
            batch(() => {
              dispatch(setUserId(id));
              dispatch(setUsername(newUsername));
              dispatch(setUserState(newUserState));
            });
          }
        } catch (error: unknown) {
          setErrors(error);
        } finally {
          setLoading(false);
        }
      } else {
        setInvalidInvitation(true);
      }
    },
    [dispatch, email, firstName, lang, lastName, signUp]
  );

  // const onVerifyPhone = useCallback(
  //   async (confirmationCode: string) => {
  //     setVerificationStatus(StatusType.wait);
  //     if (confirmationCode && username && password) {
  //       setLoading(true);
  //       try {
  //         const confirmPhoneResult = await confirmPhone({
  //           input: {
  //             username,
  //             confirmationCode,
  //           },
  //         }).unwrap();
  //
  //         if (confirmPhoneResult?.confirmPhone) {
  //           dispatch(setUserState(confirmPhoneResult.confirmPhone));
  //         }
  //
  //         try {
  //           // Initiaite login to get the jwt token
  //           const signInResponse = await signIn({
  //             input: {
  //               userAuthenticationKey: username,
  //               password,
  //               requestAccessToken: true,
  //             },
  //           }).unwrap();
  //
  //           if (signInResponse.signIn) {
  //             const signInParams = signInResponse.signIn as LoginResponse;
  //
  //             client.setHeader(
  //               'Authorization',
  //               `Bearer ${signInParams?.jwt?.token ?? ''}`
  //             );
  //
  //             batch(() => {
  //               dispatch(login(signInParams));
  //               dispatch(setUserState(signInParams.user.userState));
  //               dispatch(setAnonymousUser(false));
  //             });
  //
  //             lngHPush(
  //               `${siteMap.PropertyInvitationPage.pathWithoutParams}/${invitationId}`
  //             );
  //           }
  //         } catch (error: unknown) {
  //           setErrors(error);
  //         }
  //       } catch (error: unknown) {
  //         setErrors(error);
  //         setVerificationStatus(StatusType.error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   },
  //   [username, password, confirmPhone, dispatch, signIn, lngHPush, invitationId]
  // );
  //
  // const onResendConfirmationCode = useCallback(() => {
  //   if (emailStore) {
  //     resendConfirmationCode({
  //       email: emailStore,
  //     });
  //   }
  // }, [resendConfirmationCode, emailStore]);
  //
  // const onChangePhone = useCallback(async () => {
  //   if (username) {
  //     try {
  //       await changePhone({ username }).unwrap();
  //
  //       batch(() => {
  //         dispatch(setUserId(''));
  //         dispatch(setUsername(''));
  //         dispatch(setUserState(UserState.Unauthenticated));
  //       });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // }, [dispatch, changePhone, username]);

  const onLogout = () => {
    dispatch(logout());
  };

  const selectOptions = useMemo(() => {
    if (phonePrefixes.includes(phonePrefix)) {
      return [...phonePrefixes];
    }

    return [phonePrefix, ...phonePrefixes];
  }, [phonePrefix]);

  if (invitationError) {
    return (
      <Container>
        <NoAccess />
      </Container>
    );
  }

  return (
    <Container>
      {(invalidInvitation || errors) && (
        <ExtendErrors
          title={
            t((errors as { message?: string })?.message?.split(':')[0] ?? '') ||
            t('property-share.invalid-invitation')
          }
        />
      )}
      {(isLoading || isInvitationHealthCheckLoading) && (
        <PuffLoader color="#002849" loading size={100} speedMultiplier={0.75} />
      )}
      <VisibilityWrapper
        isVisible={!isLoading && !isInvitationHealthCheckLoading}
      >
        {userState === UserState.Unauthenticated && (
          <>
            <Title content={t('property-share.create-account.title')} />
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <FormRow>
                  <FormColumn flex="0 0 100%">
                    <InputOutsideTopLabelWithSelect
                      type={InputType.number}
                      name={'phone'}
                      label="register.form.input.label.phone"
                      placeholder="register.form.input.placeholder.phone"
                      rules={{
                        required: 'register.input.error.required',
                        valueAsNumber: true,
                      }}
                      selectWidth="87px"
                      hasSelect
                      selectOptions={selectOptions}
                      selectValue={phonePrefix}
                      selectName={'phonePrefix'}
                    />
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn flex="0 0 100%">
                    <InputPassOutsideTopLabel
                      name="password"
                      type="password"
                      label="register.form.input.label.password"
                      rules={{
                        required: 'register.input.error.required',
                        minLength: {
                          value: 10,
                          message: 'error.password.did.not.conform.with.policy',
                        },
                      }}
                      placeholder="register.form.input.placeholder.password"
                      passwordMasterValue={methods.watch('password')}
                    />
                  </FormColumn>
                </FormRow>

                <FormRow>
                  <FormColumn flex="0 0 100%">
                    <InputPassOutsideTopLabel
                      name="confirmPassword"
                      type="password"
                      label={'register.form.input.label.confirm-password'}
                      rules={{ required: 'register.input.error.required' }}
                      passwordMasterValue={methods.watch('password')}
                      placeholder={
                        'register.form.input.placeholder.confirm-password'
                      }
                      skipPattern
                    />
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn flex="0 0 100%">
                    <Checkbox
                      name={'termsAndConditions'}
                      label={
                        'property-share.create-account.terms-and-conditions'
                      }
                      rules={{ required: 'register.input.error.required' }}
                      hasDisclaimer
                    />
                  </FormColumn>
                  <FormColumn flex="0 0 100%">
                    <Checkbox
                      name={'communicationConsent'}
                      label={
                        'property-share.create-account.communication-consent'
                      }
                    />
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn flex="0 0 100%">
                    <Disclaimer
                      dangerouslySetInnerHTML={{
                        __html: t('register.form.input.disclaimer'),
                      }}
                    />
                  </FormColumn>
                </FormRow>

                <SubmitButton
                  label={t('property-share.create-account.submit')}
                  fluid
                />
              </Form>
            </FormProvider>
          </>
        )}
        {userState === UserState.VerifyEmail && (
          <ConfirmEmail
            title={t('modal.register.confirm-email.title')}
            textContent={t('modal.register.confirm-email.content')}
          />
        )}
        {/* {userState === UserState.VerifyPhone && ( */}
        {/*  <> */}
        {/*    <Title content={t('modal.register.second-step.title')} /> */}
        {/*    <PhoneVerification */}
        {/*      phoneNumber={formattedPhone ?? ''} */}
        {/*      onVerifyPhone={onVerifyPhone} */}
        {/*      status={verificationStatus} */}
        {/*      // setStatus={setVerificationStatus} */}
        {/*      resendCode={onResendConfirmationCode} */}
        {/*      changePhone={onChangePhone} */}
        {/*    /> */}
        {/*  </> */}
        {/* )} */}
        {!isAnonymousUser && (
          <>
            <Title content={t('property-share.log-out.title')} />
            <SubmitButton
              label={t('property-share.log-out.button')}
              fluid
              onClick={onLogout}
            />
          </>
        )}
      </VisibilityWrapper>
    </Container>
  );
};

export default PasswordChangeForm;
