import { ReactComponent as ArrowButtonLeft1 } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { memo, useCallback } from 'react';

import Icon from '../../../../common/components/ui/icon';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import {
  prevStepAction,
  toggleLoginForm,
  toggleRegisterForm,
} from '../../redux/searchProfileSlice';
import { PropertyCode } from '../../../../../generated';
import { Back, IconWrapper, MobileTitle } from './mobile-back-styles';
import { useFormCloseSP } from '../../hooks/useFormCloseSP';

interface IProps {
  title?: string;
  isRegional?: boolean;
}

const MobileBackBase = ({ title, isRegional }: IProps) => {
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const activeStep = useAppSelector((state) => state.searchProfile.activeStep);
  const isRegisterFormOpen = useAppSelector(
    (state) => state.searchProfile.isRegisterFormOpen
  );
  const isLoginFormOpen = useAppSelector(
    (state) => state.searchProfile.isLoginFormOpen
  );
  const isMultiFamilyHouse = useAppSelector(
    (state) =>
      state.searchProfile.userInput?.code === PropertyCode.MultiFamilyHouse
  );
  const { onFormCloseSP } = useFormCloseSP();

  // Clear markers and selected address
  const switchToPrevStep = useCallback(() => {
    if (isRegisterFormOpen) {
      dispatch(toggleRegisterForm(false));
      return;
    }
    if (isLoginFormOpen) {
      dispatch(toggleLoginForm(false));
      return;
    }
    if (isMultiFamilyHouse && activeStep === 3) {
      dispatch(prevStepAction());
      dispatch(prevStepAction());
    } else {
      dispatch(prevStepAction());
    }
  }, [
    dispatch,
    isLoginFormOpen,
    isRegisterFormOpen,
    isMultiFamilyHouse,
    activeStep,
  ]);

  if (isMobileSize) {
    return (
      <Back>
        {(isRegional
          ? activeStep > 1 && !isRegisterFormOpen
          : activeStep > 0 && !isRegisterFormOpen) && (
          <IconWrapper onClick={switchToPrevStep}>
            <Icon icon={ArrowButtonLeft1} width={16} height={16} />
          </IconWrapper>
        )}
        <MobileTitle>{title}</MobileTitle>
        {(isRegional ? activeStep > 0 : true) && (
          <IconWrapper onClick={onFormCloseSP}>
            <Icon icon={Close} width={16} height={16} />
          </IconWrapper>
        )}
      </Back>
    );
  }

  return <></>;
};

const MobileBack = memo(MobileBackBase);

export { MobileBack };
