import {
  Headline2,
  ParagraphText,
} from 'modules/common/components/ui/typography';
import { device } from 'style/theme';
import styled from 'styled-components';

export const ContentWithPadding = styled.div`
  padding: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;

  @media ${device.mobile} {
    padding: 16px;
    border: none;
  }
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  cursor: pointer;
  width: 12px;
  height: 12px;
`;

export const MobileHeadContainer = styled.div`
  position: relative;
`;

export const ItemContainer = styled.div`
  padding: 0;
  min-width: 0;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    align-items: stretch;
  }
`;

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const LoadMoreButtonContainer = styled.div`
  margin-top: 40px;
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FilterContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 12px;

  @media ${device.tablet} {
    flex-direction: column-reverse;
    width: 100%;
    gap: 20px;
  }
`;

export const ActionsContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 12px;

  @media ${device.tablet} {
    margin-left: 0;
    width: 100%;
  }
`;

export const SearchField = styled.div`
  flex: 1;
  min-width: 0;
`;

export const Title = styled(Headline2)``;

export const Count = styled.div`
  margin-left: 8px;
  padding: 2px 8px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.ctaBlue};
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: #fff;
`;

export const PropertiesContent = styled.div`
  min-height: 188px;

  @media ${device.mobile} {
    min-height: 0;
  }
`;

export const PropertiesEmptyState = styled.div`
  border: 1px solid ${(props) => props.theme.blue};
  border-radius: 5px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

export const PropertiesEmptyStateTitle = styled(Headline2)`
  font-size: 20px;

  @media ${device.mobile} {
    font-size: 18px;
    text-align: center;
  }
`;

export const PropertiesEmptyStateText = styled(ParagraphText)`
  margin: 0 auto;
  max-width: 570px;
`;

export const FilterResetBox = styled.div`
  width: 100%;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 16px;
  background: #ece7dd;
  border-radius: 3px;
  border: 1px solid #dacebc;

  @media ${device.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const FilterResetBoxContent = styled.div`
  flex: 1;
  display: flex;
  gap: 16px;
  color: ${(props) => props.theme.blue};
`;

export const FilterResetBoxText = styled(ParagraphText)`
  font-size: 12px;
`;

export const LoaderContainer = styled.div<{ isLoadingMore?: boolean }>`
  width: 100%;
  height: ${({ isLoadingMore }) => (isLoadingMore ? 'auto' : '200px')};
  position: relative;
  padding: ${({ isLoadingMore }) => (isLoadingMore ? '36px' : '56px')};
  ${({ isLoadingMore }) => isLoadingMore && 'margin-top: 24px;'};
  ${({ isLoadingMore, theme }) =>
    !isLoadingMore &&
    `border-radius: 3px;border: solid 1px ${theme.ctaDisabledBackground};`};

  @media ${device.tablet} {
    padding: 16px;
  }
`;
