import { phonePrefixes } from '../constants';

interface ParsedPhone {
  phonePrefix: string | null;
  phoneNumber: string | null;
}

export const parsePhone = (phone: string): ParsedPhone => {
  const phonePrefix = phonePrefixes.find((prefix) => phone.includes(prefix));

  if (!phonePrefix) {
    return {
      phonePrefix: null,
      phoneNumber: null,
    };
  }

  const phoneNumber = phone.replace(phonePrefix, '');

  return {
    phonePrefix,
    phoneNumber,
  };
};
