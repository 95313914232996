import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AddNewVariantButton, EditVariantButton } from './form-buttons-styles';
import { useAppSelector } from '../../../../../../../common/hooks';

const FormButtons: FC = () => {
  const { t } = useTranslation();
  const { editWidget } = useAppSelector((state) => state.financing);
  const { isLoadingUpdating } = editWidget;

  return (
    <>
      <EditVariantButton
        fluid
        centered
        loader={isLoadingUpdating}
        isDisabled={isLoadingUpdating}
        label={t(
          'financing-portal-page.tabs.calculator.edit.button-edit-variant'
        )}
        onClick={() => {
          const button = document.querySelector(
            '#edit-variant-button'
          ) as HTMLButtonElement;
          button.click();
        }}
      />
      <AddNewVariantButton
        fluid
        centered
        isDisabled={isLoadingUpdating}
        label={t(
          'financing-portal-page.tabs.calculator.edit.button-add-new-variant'
        )}
        onClick={() => {
          const button = document.querySelector(
            '#add-variant-button'
          ) as HTMLButtonElement;
          button.click();
        }}
      />
    </>
  );
};

export { FormButtons };
