import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { isNumber } from 'lodash';

import { useGetFinancing } from '../../../financing/hooks/useGetFinancing';
import { useAppSelector } from '../../../common/hooks';
import { useUpdateFinancingPropositions } from './callback-hooks/useUpdateFinancingPropositions';

const useCheckAndUpdateOverduePropositions = (): boolean => {
  const { financingId } = useParams<{ financingId: string }>();
  const { variantIndex } = useAppSelector((state) => state.financing);
  const { financingVariant } = useGetFinancing({
    financingId,
  });
  const updatedAt = financingVariant?.propositionsLastSync;

  const { updateFinancingPropositions, isLoading, error } =
    useUpdateFinancingPropositions();

  useEffect(() => {
    if (updatedAt && isNumber(variantIndex)) {
      const today = new Date();
      const updatedDay = new Date(updatedAt);
      const isToday = today.toDateString() === updatedDay.toDateString();

      if (!isToday) {
        updateFinancingPropositions({
          _id: financingId,
          variantIndex,
        });
      }
    }
  }, [updatedAt, variantIndex]);

  return isLoading;
};

export { useCheckAndUpdateOverduePropositions };
