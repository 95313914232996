import { ReactElement } from 'react';
import styled from 'styled-components';

interface IProps {
  content: string | ReactElement;
}

const Container = styled.h2`
  margin: 0;
  font-family: 'Source Serif Pro';
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
`;

const Headline1 = ({ content, ...rest }: IProps): JSX.Element => (
  <Container {...rest}>{content}</Container>
);

export { Headline1 };
