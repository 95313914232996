import { FC, memo, ReactNode } from 'react';
import {
  ActionPanelContainer,
  BottomButtonsWrapper,
} from './seller-property-card-styles';

interface IProps {
  TopButton: ReactNode;
  FirstBottomButton?: ReactNode;
  SecondBottomButton?: ReactNode;
}

const PropertyCardActionPanelBase: FC<IProps> = ({
  TopButton,
  FirstBottomButton,
  SecondBottomButton,
}) => {
  const hasBottomButtons = FirstBottomButton || SecondBottomButton;
  return (
    <ActionPanelContainer>
      {TopButton}
      {hasBottomButtons && (
        <BottomButtonsWrapper>
          {FirstBottomButton}
          {SecondBottomButton}
        </BottomButtonsWrapper>
      )}
    </ActionPanelContainer>
  );
};

const SellerPropertyCardActionPanel = memo(PropertyCardActionPanelBase);

export { SellerPropertyCardActionPanel };
