import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const Bathroom2Icon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={
          'M13.5 14.274h9.486a.5.5 0 0 1 .5.53 9 9 0 0 1-8.985 8.47h-5A9 9 0 0 1 .515 14.8a.5.5 0 0 1 .5-.53H6.5'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={'m17.27 2.136.9-.9a2 2 0 0 1 3.329 1.5v11.538'}
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={
          'M17.27 5.488a2.37 2.37 0 1 0-3.351-3.352l-.205.205a.5.5 0 0 0 0 .707l2.644 2.645a.5.5 0 0 0 .708 0zm-5.862-2.132-1.816.837m6.301 3.662-.786 1.839m-1.896-3.623-1.422 1.407m1.711 5.796a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1v5.5a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5z'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { Bathroom2Icon };
