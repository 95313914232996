import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

import {
  BoxesContainer,
  DataListContainer,
  DataListItem,
  Header,
  InfoContainer,
  MarkersListContainer,
  MarkersListItem,
  RangeContainer,
} from './energy-efficiency-styles';
import { BoxInfo } from './box-info/box-info';
import { energyEfficiency } from '../../../../constants';
import { useGetEnergyEfficiencyData } from './useGetEnergyEfficiencyData';
import { CombinedShape } from './combined-shape/combined-shape';
import { OnOfficeEnergyCertificate } from '../../../../../../../../generated';

interface IProps {
  certificate: OnOfficeEnergyCertificate;
}

const EnergyEfficiency: FC<IProps> = ({ certificate }) => {
  const { t } = useTranslation();
  const {
    finalEnergyDemand,
    energyEfficiencyClass,
    energySource,
    energyCertificateValidUntil,
    energyCertificate,
    typeOfHeating,
  } = certificate ?? {};

  // const finalEnergyDemand = '10.00';

  const { colorMarkers, textMarkers } = energyEfficiency;

  const { tooltipLeft, indicatorLeft, letter, color, indicatorColor } =
    useGetEnergyEfficiencyData({
      finalEnergyDemand: Number(finalEnergyDemand),
      energyEfficiencyClass,
    });

  if (!Number(finalEnergyDemand)) {
    return null;
  }

  if (
    !letter &&
    !energyCertificate &&
    !finalEnergyDemand &&
    !energyCertificateValidUntil &&
    !energyEfficiencyClass &&
    // !typeOfFiring &&
    !energySource &&
    !typeOfHeating
  ) {
    return null;
  }

  return (
    <>
      <Header
        content={t('expose-page.tabs.overview.energy-efficiency.header')}
      />
      <InfoContainer>
        {letter && (
          <RangeContainer isValue={!!finalEnergyDemand}>
            <CombinedShape
              color={color || '#fffa3e'}
              indicatorColor={indicatorColor}
              tooltipLeft={`${tooltipLeft}%`}
              indicatorLeft={`${indicatorLeft}%`}
              letter={letter}
              value={finalEnergyDemand}
            />
            <MarkersListContainer>
              {colorMarkers.map(({ label, range }) => (
                <MarkersListItem key={label} width={`${(range / 275) * 100}%`}>
                  <div>{label}</div>
                </MarkersListItem>
              ))}
            </MarkersListContainer>
            <DataListContainer>
              {textMarkers.map(({ label }, index) => (
                <DataListItem key={label} index={index}>
                  <div>{label}</div>
                </DataListItem>
              ))}
            </DataListContainer>
          </RangeContainer>
        )}
        <BoxesContainer>
          {energyCertificate && (
            <BoxInfo
              type={t(
                'expose-page.tabs.overview.energy-efficiency.energy-certificate'
              )}
              value={energyCertificate}
              // value={t(
              //   `expose-page.tabs.overview.energy-efficiency.energy-certificate.type.${energyCertificate}`
              // )}
            />
          )}
          {finalEnergyDemand && (
            <BoxInfo
              type={t(
                'expose-page.tabs.overview.energy-efficiency.final-energy-consumption'
              )}
              value={`${finalEnergyDemand} kWh/m²a`}
            />
          )}
          {energyCertificateValidUntil && (
            <BoxInfo
              type={t(
                'expose-page.tabs.overview.energy-efficiency.energy-certificate-valid-until'
              )}
              value={energyCertificateValidUntil}
            />
          )}
          {energyEfficiencyClass && (
            <BoxInfo
              type={t(
                'expose-page.tabs.overview.energy-efficiency.energy-efficiency-class'
              )}
              value={energyEfficiencyClass}
            />
          )}
          {/* {typeOfFiring && ( */}
          {/*  <BoxInfo */}
          {/*    type={t('expose-page.tabs.overview.energy-efficiency.firing')} */}
          {/*    value={t( */}
          {/*      `expose-page.tabs.overview.energy-efficiency.firing.type.${typeOfFiring}` */}
          {/*    )} */}
          {/*  /> */}
          {/* )} */}
          {energySource && (
            <BoxInfo
              type={t(
                'expose-page.tabs.overview.energy-efficiency.essential-energy-source'
              )}
              value={energySource}
              // value={t(
              //   `expose-page.tabs.overview.energy-efficiency.essential-energy-source.type.${energySource}`
              // )}
            />
          )}
          {typeOfHeating && (
            <BoxInfo
              type={t(
                'expose-page.tabs.overview.energy-efficiency.type-of-heating'
              )}
              value={capitalize(typeOfHeating)}
              // value={t(
              //   `expose-page.tabs.overview.energy-efficiency.type-of-heating.type.${typeOfHeating}`
              // )}
            />
          )}
        </BoxesContainer>
      </InfoContainer>
    </>
  );
};

export { EnergyEfficiency };
