import {
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { VirtuosoGrid, VirtuosoHandle } from 'react-virtuoso';
import styled, { ThemeContext } from 'styled-components';

import {
  Direction,
  OffersSearchInput,
  OrderBy as OrderByEnum,
} from '../../../../generated';
import { useSimilarPropertiesQuery } from '../../../../services/graphql/enhanced';
import { device } from '../../../../style/theme';
import { SecondaryButton } from '../../../common/components/ui/buttons';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { Headline2 } from '../../../common/components/ui/typography';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import {
  addMarkers,
  removeAllMarkersExceptHome,
} from '../../../map/redux/mapSlice';
import { scrollToProperty } from '../../redux/dynamicMapUtilsSlice';
import {
  addSimilarProperties,
  clearPropertiesCache,
  mergeFilters,
  setInitialLoader,
  setTotalItems,
} from '../../redux/similarPropertiesSlice';
import { SimilarProperty } from '../card/similar-property';
import { OrderBy } from './order-by';

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 56px;

  &&&& {
    > div {
      position: absolute;
    }
  }
`;

const ItemContainer = styled.div<{ isShrinked: boolean }>`
  padding: 0;
  /* margin: 0 6px 12px 6px; */
  width: ${({ isShrinked }) => (isShrinked ? '100%' : 'calc(50% - 6px)')};

  @media ${device.mobile} {
    width: 100%;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    align-items: stretch;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  gap: 6px;
`;

const LoadMoreButtonContainer = styled.div`
  margin-top: 40px;
`;

const Header = styled.div<{ isShrinked: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  ${({ isShrinked }) =>
    isShrinked &&
    `
    >div:last-child {
      margin-left: 0;
      flex: 1;
      justify-content: space-between;
    }
  `} @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled(Headline2)``;

const Count = styled.div`
  margin-left: 8px;
  padding: 2px 8px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.ctaBlue};
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: #fff;
`;

const SimilarPropertiesContainerBase = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const themeContext = useContext(ThemeContext);
  const virtuosoRef = useRef<VirtuosoHandle>(null);

  // const isShrinked = useAppSelector(
  //   (state) => state.dynamicMapUtils.isTabListNearTop
  // );

  const isShrinked = false;

  const filters = useAppSelector(
    (state) => state.similarProperties.filters
  ) as OffersSearchInput;
  const similarProperties = useAppSelector(
    (state) => state.similarProperties.similarProperties
  );
  const totalItems = useAppSelector(
    (state) => state.similarProperties.totalItems
  );
  const initialLoader = useAppSelector(
    (state) => state.similarProperties.initialLoader
  );
  const propertyIndexForScrolling = useAppSelector(
    (state) => state.dynamicMapUtils.propertyIndexForScrolling
  );

  const { data } = useSimilarPropertiesQuery(
    {
      input: filters,
    },
    { skip: Object.keys(filters).length === 0 }
  );

  const loadMore = useCallback(() => {
    if (totalItems > (filters?.offset ?? 0)) {
      dispatch(
        mergeFilters({
          limit: 6,
          offset: +(filters.offset ?? 0) + 6,
        })
      );
    }
  }, [dispatch, filters?.offset, totalItems]);

  const switchOrder = useCallback(
    ({ field, direction }: { field: OrderByEnum; direction: Direction }) => {
      const currentLoadedCount = similarProperties.length;
      batch(() => {
        dispatch(removeAllMarkersExceptHome());
        dispatch(clearPropertiesCache());
        dispatch(
          mergeFilters({
            limit: currentLoadedCount,
            offset: 0,
            orderBy: [{ field, direction }],
          })
        );
      });
    },
    [dispatch, similarProperties.length]
  );

  useEffect(() => {
    if (data?.getOffers?.items) {
      batch(() => {
        dispatch(addSimilarProperties(data?.getOffers?.items));
        dispatch(setTotalItems(data?.getOffers?.totalItems));
        dispatch(setInitialLoader(false));
        dispatch(
          addMarkers(
            data?.getOffers?.items.map((item, index) => ({
              longitude: item.coordinates?.longitude ?? 0,
              latitude: item.coordinates?.latitude ?? 0,
              category: 'property',
              marker: 'similarProperty',
              index,
            }))
          )
        );
      });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (
      propertyIndexForScrolling !== null &&
      propertyIndexForScrolling !== undefined &&
      virtuosoRef?.current
    ) {
      dispatch(scrollToProperty(null));
      try {
        setTimeout(() => {
          virtuosoRef?.current?.scrollToIndex({
            index: propertyIndexForScrolling,
            align: 'center',
            behavior: 'smooth',
          });
        }, 0);
      } catch (error) {
        console.error('Error ->', error);
      }
    }
  }, [dispatch, propertyIndexForScrolling]);

  const ItemWithShrinkProps = useMemo(() => {
    return ({ children, ...props }: { children?: ReactNode }) => {
      return (
        <ItemContainer isShrinked={isShrinked} {...props}>
          {children}
        </ItemContainer>
      );
    };
  }, [isShrinked]);

  const itemContent = useCallback(
    (index: number) => (
      <SimilarProperty
        title={similarProperties?.[index]?.title ?? ''}
        isActive={similarProperties?.[index]?.isActive ?? false}
        daysOnTheMarket={similarProperties?.[index]?.daysOnMarket ?? 0}
        price={similarProperties?.[index]?.salePrice ?? 0}
        currency={similarProperties?.[index]?.currency ?? ''}
        numberOfRooms={similarProperties?.[index]?.numberOfRooms ?? 0}
        livingArea={similarProperties?.[index]?.livingArea ?? 0}
        imageSrc={similarProperties?.[index]?.images?.[0]?.url ?? ''}
        startDate={similarProperties?.[index]?.startDate ?? ''}
        url={similarProperties?.[index]?.url ?? ''}
      />
    ),
    [similarProperties]
  );

  if (initialLoader) {
    return (
      <LoaderContainer>
        <GlobalLoader />
      </LoaderContainer>
    );
  }

  return (
    <>
      <Header isShrinked={false}>
        <FlexContainer>
          <Title content={t('property.offers.label')} />
          <Count>{similarProperties?.length}</Count>
        </FlexContainer>
        <OrderBy
          selectedOrderBy={filters.orderBy?.[0]}
          switchOrder={switchOrder}
        />
      </Header>
      <VirtuosoGrid
        useWindowScroll
        totalCount={similarProperties?.length}
        overscan={200}
        ref={virtuosoRef}
        components={{
          Item: ItemWithShrinkProps,
          List: ListContainer,
        }}
        itemContent={itemContent}
      />
      {totalItems > similarProperties?.length && (
        <LoadMoreButtonContainer>
          <SecondaryButton
            fluid
            onClick={loadMore}
            label={t('button.load-more')}
            color={themeContext.blue}
            borderColor={themeContext.blue}
          />
        </LoadMoreButtonContainer>
      )}
    </>
  );
};

const SimilarPropertiesContainer = memo(SimilarPropertiesContainerBase);

export { SimilarPropertiesContainer };
