import { FC, UIEvent, useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useTranslation } from 'react-i18next';

import {
  setActiveTab,
  setIsAppointmentSelectOpen,
  setIsOpenHeadAccordion,
} from '../redux/exposeSlice';

import { HeadInfo } from './head-info/head-info';
import {
  Property,
  PropertyCode,
  PropertyData,
  PropertyPurchaseIntent,
} from '../../../../../generated';
import { useAppSelector } from '../../../../common/hooks';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { Documents } from './tabs/documents/documents';
import { Overview } from './tabs/overview/overview';
import { InfrastructurePropertyWrapper } from './tabs/local-supply/infrastructure-espose-details-wrapper';
import { SocialEconomicsExposeDetailsWrapper } from './tabs/social-economics/social-economics-expose-details-wrapper';
import { Content, TabsContainer, Container, InfoOverlay } from './info-styles';
import { Reachability } from './tabs/reachability/reachability';

interface IProps {
  purchaseIntents: PropertyPurchaseIntent[];
  refetch: () => void;
  property: Partial<Property>;
}

const Info: FC<IProps> = ({ purchaseIntents, refetch, property }) => {
  const dispatch = useDispatch();
  const isOpenHeadAccordion = useAppSelector(
    (state) => state.sellerExposeDetails.isOpenHeadAccordion
  );
  const isMobileSize = useIsMobileSize();
  const { t } = useTranslation();
  const activeTab = useAppSelector(
    (state) => state.sellerExposeDetails.activeTab
  );
  const propertyCode = useAppSelector(
    (state) => state.sellerExposeDetails.selectedPropertyCode
  );

  const onScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const { scrollTop } = e.currentTarget;
      if (!isMobileSize) {
        if (isOpenHeadAccordion && scrollTop > 180) {
          batch(() => {
            dispatch(setIsAppointmentSelectOpen(false));
            dispatch(setIsOpenHeadAccordion(false));
          });
        } else if (!isOpenHeadAccordion && scrollTop === 0) {
          batch(() => {
            dispatch(setIsAppointmentSelectOpen(false));
            dispatch(setIsOpenHeadAccordion(true));
          });
        }
      }
    },
    [dispatch, isMobileSize, isOpenHeadAccordion]
  );

  return (
    <Content onScroll={onScroll}>
      <Tabs
        selectedIndex={activeTab}
        onSelect={(index) => {
          dispatch(setActiveTab(index));
        }}
      >
        <Container>
          <HeadInfo
            purchaseIntents={purchaseIntents}
            refetch={refetch}
            property={property}
          />
          <TabsContainer>
            <TabList>
              <Tab>{t('expose-page.tabs.overview.label')}</Tab>
              <Tab hidden={propertyCode === PropertyCode.Plot}>
                {t('expose-page.tabs.local-supply.label')}
              </Tab>
              <Tab hidden={propertyCode === PropertyCode.Plot}>
                {t('expose-page.tabs.socioeconomics.label')}
              </Tab>
              <Tab>{t('expose-page.tabs.reachability.label')}</Tab>
              <Tab hidden={propertyCode === PropertyCode.Plot}>
                {t('expose-page.tabs.documents.label')}
              </Tab>
            </TabList>
          </TabsContainer>
          {!isOpenHeadAccordion && <InfoOverlay />}
        </Container>
        <TabPanel>
          <Overview property={property} />
        </TabPanel>
        <TabPanel>
          <InfrastructurePropertyWrapper property={property} />
        </TabPanel>
        <TabPanel>
          <SocialEconomicsExposeDetailsWrapper property={property} />
        </TabPanel>
        <TabPanel>
          <Reachability property={property} />
        </TabPanel>
        <TabPanel>
          <Documents />
        </TabPanel>
      </Tabs>
    </Content>
  );
};

export { Info };
