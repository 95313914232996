import {
  FC,
  JSXElementConstructor,
  KeyboardEventHandler,
  memo,
  ReactElement,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNumber } from 'lodash';

import {
  CircleContainer,
  Container,
  Form,
  Headline,
  HeadlineBox,
  StepContainer,
  Title,
} from './modal-form-styles';
import { FormError } from '../../../interface';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import { useAppSelector, useDeviceDetector } from '../../../../common/hooks';
import { CircleProgress } from '../../../form-financing/components/containers/financing-containers/financing-info-container/circle-progress/circle-progress';
import { AddressBar } from 'modules/common/components/ui/hero-slider/address-bar';
import { useFormContext } from 'react-hook-form';
import { useIsMobileSize } from 'modules/common/hooks/useIsMobileSize';

interface IProps {
  title: string;
  header: string;
  isModalOpen: boolean;
  percent?: number;
  errors?: FormError;
  activeStep?: number;

  isHeadline?: boolean;
  formStep: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
}

const ModalFormBase: FC<IProps> = ({
  isModalOpen,
  title,
  header,
  percent,
  errors,
  activeStep,
  isHeadline,
  formStep,
}) => {
  const { t } = useTranslation();
  const { isIphone } = useDeviceDetector();
  const isMobileSize = useIsMobileSize();

  const userInput = useAppSelector((state) => {
    return state.searchProfile;
  });

  const { watch, reset } = useFormContext();
  const values = watch();

  const formData = isEmpty(values) ? userInput : values;

  const { radius } = formData || {};

  const storedAddress = useAppSelector(
    (state) => state.searchProfile.selectedAddress
  );

  const handleKeyDown: KeyboardEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      if (e.keyCode === 13) e.preventDefault();
    },
    []
  );

  return (
    <Container isFixTop={isIphone()}>
      {isHeadline && (
        <HeadlineBox>
          <Headline>{t(header)}</Headline>
        </HeadlineBox>
      )}
      <Title isShort={!isModalOpen}>{t(title)}</Title>

      {isNumber(percent) && (
        <CircleContainer>
          <CircleProgress percent={percent} />
        </CircleContainer>
      )}

      {isMobileSize && activeStep !== undefined && activeStep <= 1 ? (
        <AddressBar
          title={t('search-profile.info-container.plz')}
          show
          isSkeleton
          isStarsImage
          radiusInfo={
            radius && storedAddress?.place_name ? `+${radius} KM` : undefined
          }
          address={storedAddress?.place_name}
          isSPMobile={isMobileSize}
        />
      ) : null}

      <Form autoComplete="off" onKeyDown={handleKeyDown}>
        {errors?.message && (
          <GlobalError
            title={t(errors?.message?.split(':')[0].toLowerCase())}
          />
        )}
        <StepContainer>{formStep}</StepContainer>
      </Form>
    </Container>
  );
};

const ModalForm = memo(ModalFormBase);

export { ModalForm };
