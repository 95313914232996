import styled, { ThemeContext } from 'styled-components';
import { useContext } from 'react';
import { ReactComponent as AlertTriangle } from 'assets/streamline-light/interface-essential/alerts/alert-triangle.svg';
import { ParagraphText } from '../../ui/typography';
import Icon from '../../ui/icon';

const GlobalErrorContainer = styled.div<{ topMargin?: boolean }>`
  width: 100%;
  color: ${(props) => props.theme.red};
  background-color: #f0e9e9;
  border: solid 1px ${(props) => props.theme.lightRed};
  border-radius: 3px;
  margin-bottom: 16px;
  ${({ topMargin }) => topMargin && `margin-top: 16px;`}
`;

const GlobalErrorContentWrapper = styled.div`
  align-items: center;
  display: inline-flex;
`;

const IconWrapper = styled.div`
  margin: 12px;
`;

const ErrorText = styled(ParagraphText)`
  color: ${(props) => props.theme.red};
  font-size: 12px;
  line-height: 1.33;
  margin: 12px 12px 12px 0px;
`;

interface IProps {
  title?: string;
  topMargin?: boolean;
  className?: string;
}
export const GlobalError = ({
  title,
  topMargin = false,
  className,
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);

  return (
    <GlobalErrorContainer topMargin={topMargin} className={className}>
      <GlobalErrorContentWrapper>
        <IconWrapper>
          <Icon
            icon={AlertTriangle}
            width={16}
            height={16}
            color={themeContext.red}
          />
        </IconWrapper>
        <ErrorText content={title || ''} />
      </GlobalErrorContentWrapper>
    </GlobalErrorContainer>
  );
};
