import { ReactComponent as Bus } from 'assets/streamline-light/transportation/bus/bus.svg';
import { ReactComponent as RailroadMetro } from 'assets/streamline-light/transportation/railroad/railroad-metro.svg';
import { ReactComponent as RailroadTrain1 } from 'assets/streamline-light/transportation/railroad/railroad-train-1.svg';
import { ReactComponent as RailroadTrain } from 'assets/streamline-light/transportation/railroad/railroad-train.svg';
import { ReactComponent as RoadStraight } from 'assets/streamline-light/transportation/roads-lights/road-straight.svg';
import { ReactComponent as Plane } from 'assets/streamline-light/travel/planes-trip/plane.svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Transport } from '../../../../../generated';
import { AlertWarning } from '../../../../common/components/ui/alerts/warning';
import { CardWithTitle } from '../social-economics/card-with-title';
import { ConnectionItem, IconType } from './connection-item';

interface IProps {
  transports: Transport;
  isNoPadding?: boolean;
  isNoBorder?: boolean;
}

const Card = styled(CardWithTitle)<{
  isNoPadding?: boolean;
  isNoBorder?: boolean;
}>`
  margin-bottom: 0;
  ${({ isNoPadding }) => isNoPadding && 'padding: 0;'}
  ${({ isNoBorder }) => isNoBorder && 'border: none;'}
`;

type Icons = { [key in keyof Transport | string]: IconType };

const icons: Icons = {
  airport: Plane,
  bus: Bus,
  highway_ramp: RoadStraight,
  subway: RailroadMetro,
  train: RailroadTrain1,
  tram: RailroadTrain,
};

const Connection = styled(ConnectionItem)`
  & + & {
    margin-top: 12px;
  }
`;

export const formatDistance = (distance?: number | null): string => {
  if (!distance) {
    return '';
  }

  if (distance > 1000) {
    return `${(distance / 1000).toFixed(1)} km`;
  }

  return `${distance} m`;
};

export const ConnectionCard: FC<IProps> = ({
  transports,
  isNoPadding,
  isNoBorder,
}) => {
  const { t } = useTranslation();
  const noDataAvailable = t('reachability.currently.no.data.available');

  return (
    <Card
      isNoBorder={isNoBorder}
      isNoPadding={isNoPadding}
      title={t('reachability.closest.connections')}
    >
      {transports ? (
        Object.entries(transports).map(([transportName, values]) => (
          <Connection
            icon={icons[transportName]}
            title={t(`reachability.connection.${transportName}`)}
            description={(Array.isArray(values) && values[0].name) || ''}
            distance={
              Array.isArray(values)
                ? formatDistance(values[0].distance)
                : noDataAvailable
            }
            hasValue={Array.isArray(values)}
            key={transportName}
          />
        ))
      ) : (
        <AlertWarning message={t('reachability.connection.empty')} />
      )}
    </Card>
  );
};
