import styled from 'styled-components';
import {
  BoxText,
  Headline2,
  Headline3,
  ParagraphText,
  ParagraphTitle,
} from '../../../../../common/components/ui/typography';
import { device } from '../../../../../../style/theme';
import { MainButton } from '../../../../../common/components/ui/buttons';

export const Container = styled.div`
  border-radius: 3px;
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: rgba(255, 255, 255, 1);
  padding: 16px;
  margin-top: 24px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  @media ${device.tablet} {
    position: relative;
    height: 100%;
  }
`;

export const Title = styled(Headline3)``;

export const Label = styled(ParagraphTitle)`
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 1.33;
  opacity: 0.8;
`;

export const SubmitButton = styled(MainButton)`
  text-transform: uppercase;
  margin-top: 24px;
  justify-content: center;
  width: 100%;

  @media ${device.mobile} {
    margin-top: 18px;
    height: 40px;
  }
`;

export const RadioButtonsWrapper = styled.div`
  display: flex;
  gap: 48px;
  margin: 24px 0;

  @media ${device.mobile} {
    gap: 24px;
  }
`;

export const CheckboxesWrapper = styled.div`
  display: flex;
  gap: 48px;
  margin: 12px 0 24px 0;
  flex-wrap: wrap;

  @media ${device.mobile} {
    gap: 24px;
  }
`;

export const DeclinePropertyMessage = styled(BoxText)`
  line-height: 1.33;
  margin: 8px 0 24px 0;
  opacity: 0.8;
`;

export const SuccessMessage = styled(Container)`
  padding: 128px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.mobile} {
    padding: 32px 16px;
  }
`;

export const SuccessMessageTitle = styled(Headline2)`
  margin-bottom: 24px;
  text-align: center;

  @media ${device.mobile} {
    font-size: 16px;
  }
`;

export const SuccessMessageText = styled(ParagraphText)`
  font-size: 16px;
  text-align: center;

  @media ${device.mobile} {
    font-size: 14px;
  }
`;
