import { Redirect, RedirectProps } from 'react-router-dom';
import { FC } from 'react';
import useGetLocalization from './get-localization';

interface ILngRedirect extends RedirectProps {
  langProp?: string;
}

// Redirect component with localization logic

const LngRedirect: FC<ILngRedirect> = ({ to, langProp }) => {
  const lang = useGetLocalization();
  const langTo = `/${langProp || lang || 'de-de'}${to}`;

  return <Redirect to={langTo} />;
};

export { LngRedirect };
