import styled from 'styled-components';
import { CSSProperties, memo, ReactNode } from 'react';
import { device } from '../../../../../style/theme';
import { ParagraphText } from '../typography';
import Icon from '../icon';

const Container = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 1.33;
  border-radius: 3px;
  padding: 12px 12px;
  border: solid 1px ${(props) => props.theme.lightRed};
  background-color: ${(props) => props.theme.greyBackground};
  margin: 0 0 16px 0;

  @media ${device.tablet} {
    margin: 0 16px 0 16px;
  }
`;

const Text = styled(ParagraphText)`
  font-size: 12px;
`;

const InnerHtmlContainer = styled.div`
  font-size: 12px;
  margin: 0;
  font-family: 'Roboto';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
`;

interface IProps {
  text: string;
  style?: CSSProperties;
  icon?: ReactNode;
  CTA?: ReactNode;
  isInnerHtml?: boolean;
}

const AlertInfoBase = ({
  text,
  style,
  icon,
  CTA,
  isInnerHtml = false,
}: IProps): JSX.Element => {
  return (
    <Container style={{ ...style }}>
      {icon}
      {isInnerHtml ? (
        <InnerHtmlContainer dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        <Text content={text} />
      )}

      {CTA}
    </Container>
  );
};

const AlertInfo = memo(AlertInfoBase);

export { AlertInfo };
