import { FC, memo, useCallback, useContext, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { ValuationLoader } from 'modules/forms/components/common/loader';

import { device } from '../../../../style/theme';
import {
  formatSyncDate,
  GeneralStatisticsCard,
} from '../general-statistics-card/general-statistics-card';
import { ExposeStatisticsCard } from '../expose-statistics-card/expose-statistics-card';
import { OffersStatisticsCard } from '../offers-statistics-card/offers-statistics-card';
import { MarketingStatus } from '../../constants';
import { Placeholder } from '../marketing/placeholder';
import { ProgressCard } from '../progress-card/progress-card';
import {
  StatisticsItem,
  OffersStatisticsItem,
  SellingProgress,
} from '../../interfaces';
import {
  Broker,
  useGetPropertyBrokerQuery,
  useGetMarketingStatisticsQuery,
  MarketingStatistics,
  useDashboardStatusQuery,
  useMarketingStatusQuery,
} from '../../../../generated';
import { DashboardLockedMessage } from '../marketing/dashboard-locked-message/dashboard-locked-message';
import {
  setPropertyMeta,
  toggleTimifyModal,
} from '../../../timify/redux/timifySlice';
import { LoaderContainer } from '../../pages/seller-properties-page/seller-properties-page-styles';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { TeamContactCard } from '../appointment-card/team-contact-card';
import { useAppSelector } from '../../../common/hooks';
import { formatPrice } from '../../utils/formatPrice';
import { useCurrency } from '../../../property/hooks/useCurrency';
import Banner from '../banner/banner';
import { ReactComponent as ReadHuman } from '../../../../assets/streamline-light/school-learning/library-reading/read-human.svg';
import BannerImage from '../../../../assets/broker-view-banner.webp';
import { AlertInfo } from '../../../common/components/ui/alerts/info';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import Icon from '../../../common/components/ui/icon';
import { ReactComponent as AlertTriangle } from '../../../../assets/streamline-light/interface-essential/alerts/alert-triangle.svg';
import { SellerPdfDocument } from '../seller-pdf-document/seller-pdf-document';

const Container = styled.div`
  position: relative;
  display: flex;
  @media ${device.tablet} {
    flex-direction: column;
    padding: 16px;
  }
`;

const FirstColumn = styled.div`
  flex: 1.72;
  max-width: 688px;
  margin-right: 16px;
  @media ${device.tablet} {
    margin-bottom: 16px;
    max-width: 100%;
    width: 100%;
  }
`;

const SecondColumn = styled.div`
  flex: 1;
  max-width: 400px;
  @media ${device.tablet} {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

interface IProps {
  isLocked?: boolean;
  isHiddenSendEmailToBrokerAlert?: boolean | null;
}

const MarketingBase: FC<IProps> = ({
  isLocked,
  isHiddenSendEmailToBrokerAlert,
}): JSX.Element => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const { propertyId } = useParams<{ propertyId: string }>();
  const priceCurrency = useCurrency();
  const isMobileSize = useIsMobileSize();

  const userId = useAppSelector((state) => state.auth.user?._id);
  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const [showPreview, setShowPreview] = useState<boolean>(false);

  const { propertyBroker, isBrokerLoading, getBrokerError } =
    useGetPropertyBrokerQuery(
      {
        propertyId,
      },
      {
        selectFromResult: ({ data, isLoading, error }) => ({
          propertyBroker: data?.getPropertyBroker as Broker,
          isBrokerLoading: isLoading,
          getBrokerError: error,
        }),
      }
    );

  const {
    isRealSellingProcessStarted,
    isMarketingStatusLoading,
    getMarketingStatusError,
  } = useMarketingStatusQuery(
    {
      propertyId,
    },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        isRealSellingProcessStarted: data?.marketingStatus,
        isMarketingStatusLoading: isLoading,
        getMarketingStatusError: error,
      }),
      pollingInterval: 10_000,
    }
  );

  const {
    isDashboardUnlocked,
    isMarketingPaused,
    isDashboardLoading,
    getDashboardError,
  } = useDashboardStatusQuery(
    {
      propertyId,
    },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        isDashboardUnlocked: data?.dashboardStatus?.isDashboardUnlocked,
        isMarketingPaused: data?.dashboardStatus?.isMarketingPaused,
        isDashboardLoading: isLoading,
        getDashboardError: error,
      }),
      pollingInterval: 10_000,
    }
  );

  const { statistics, isStatisticsLoading, getStatisticsError } =
    useGetMarketingStatisticsQuery(
      {
        propertyId,
      },
      {
        selectFromResult: ({ data, isLoading, error }) => ({
          statistics: data?.getMarketingStatistics as MarketingStatistics,
          isStatisticsLoading: isLoading,
          getStatisticsError: error,
        }),
        // skip: !isDashboardUnlocked,
      }
    );

  const status = useMemo(
    () =>
      isRealSellingProcessStarted
        ? MarketingStatus.DashboardDataCollected
        : MarketingStatus.NotStarted,
    [isRealSellingProcessStarted]
  );

  const offersStatisticsPerWeek = useMemo(() => {
    const highestBidValue = statistics?.generalStatistics?.highestBid ?? 0;
    const result = statistics?.statisticsPerWeek?.map((item, index) => {
      const highestBid = item.bids?.find(
        (bid) => highestBidValue === bid.offeredPrice
      );
      const dateFrom = new Date(item.dateFrom).toISOString();
      const dateTo = new Date(item.dateTo).toISOString();
      const isNewOffer = index === 0;

      return {
        _id: String(item.weekNumber),
        weekNumber: item.weekNumber,
        dateFrom,
        dateTo,
        highestBidId: highestBid?.customerId,
        offers: item.bids?.map((bid) => {
          const formattedPrice = formatPrice(
            bid.offeredPrice ?? 0,
            priceCurrency === '€' ? 'EUR' : 'CHF'
          );

          const newFormattedPrice = bid.newOfferedPrice
            ? formatPrice(
                bid.newOfferedPrice ?? 0,
                priceCurrency === '€' ? 'EUR' : 'CHF'
              )
            : '';

          return {
            bidOnOfficeId: bid.bidOnOfficeId,
            customerId: bid.customerId,
            customerOnOfficeId: bid.customerOnOfficeId ?? '',
            price: formattedPrice,
            newPrice: newFormattedPrice,
            fromCity: bid.customerCity,
            isNew: isNewOffer,
            isAccepted: bid.isAccepted,
            isHighestBid: bid.newOfferedPrice
              ? bid.newOfferedPrice === highestBidValue
              : bid.offeredPrice === highestBidValue,
            keywords: [
              `${t('selling-simulation.offers-statistics-card.customer')} ${
                bid.customerId
              }`,
              // String(bid.customerId),
              String(bid.offeredPrice),
              formattedPrice,
              newFormattedPrice,
              bid.customerCity,
              ...(isNewOffer
                ? [
                    t(
                      'selling-simulation.offers-statistics-card.new-offer.badge'
                    ),
                  ]
                : []),
              ...(highestBid?.customerId === bid.customerId
                ? [
                    t(
                      'selling-simulation.offers-statistics-card.highest-bid.badge'
                    ),
                  ]
                : []),
            ].map((keyword) => keyword?.toLowerCase()),
            date: bid.date,
          };
        }),

        generalStatistics: item.statistics,
      } as OffersStatisticsItem;
    });

    return result ?? [];
  }, [
    priceCurrency,
    statistics?.generalStatistics?.highestBid,
    statistics?.statisticsPerWeek,
    t,
  ]);

  const exposeStatistics = useMemo(() => {
    return statistics?.statisticsPerWeek
      ?.slice()
      ?.reverse()
      ?.map(
        (item) =>
          ({
            label: `KW${item.weekNumber}`,
            value: item.statistics?.requestedExposes,
          } as StatisticsItem)
      );
  }, [statistics?.statisticsPerWeek]);

  const advertisementViewsStatistics = useMemo(() => {
    return statistics?.statisticsPerWeek
      ?.slice()
      ?.reverse()
      ?.map(
        (item) =>
          ({
            label: `KW${item.weekNumber}`,
            value: item.statistics?.advertisementViews,
          } as StatisticsItem)
      );
  }, [statistics?.statisticsPerWeek]);

  const onBookAppointment = useCallback(() => {
    dispatch(
      setPropertyMeta({
        vonpollUserId: userId || '',
        vonpollSellingPropertyId: propertyId.toString(),
      })
    );
    dispatch(toggleTimifyModal(true));
  }, [dispatch, propertyId, userId]);

  const onShowPreview = useCallback(() => {
    setShowPreview(true);
  }, []);

  const onEmailBroker = useCallback(() => {
    if (propertyBroker?.email) {
      window.open(`mailto: ${propertyBroker.email}`, '_self');
    }
  }, [propertyBroker?.email]);

  const showChartAlert = useMemo(
    () =>
      !(
        Number(
          advertisementViewsStatistics?.[
            advertisementViewsStatistics?.length - 1
          ]?.value ?? 0
        ) >
        Number(
          advertisementViewsStatistics?.[
            advertisementViewsStatistics?.length - 2
          ]?.value ?? 0
        )
      ),
    [advertisementViewsStatistics]
  );

  const isStatisticsBlurred = useMemo(() => {
    return isBrokerView
      ? (!isDashboardUnlocked || isLocked) && !showPreview
      : !isDashboardUnlocked || isLocked;
  }, [isBrokerView, isDashboardUnlocked, isLocked, showPreview]);

  const showRequestedExposes = useMemo(
    () => !!statistics?.generalStatistics?.requestedExposes,
    [statistics?.generalStatistics?.requestedExposes]
  );

  const showUnlockedExposes = useMemo(
    () => !!statistics?.generalStatistics?.unlockedExposes,
    [statistics?.generalStatistics?.unlockedExposes]
  );

  const showViewingAppointments = useMemo(
    () => !!statistics?.generalStatistics?.viewingAppointments,
    [statistics?.generalStatistics?.viewingAppointments]
  );

  if (
    isBrokerLoading ||
    isStatisticsLoading ||
    isDashboardLoading ||
    isMarketingStatusLoading
  ) {
    return (
      <LoaderContainer isLoadingMore>
        <ValuationLoader maxWidth="500px" />
      </LoaderContainer>
    );
  }

  const isPDFOption = !isStatisticsBlurred && !isLocked;

  return (
    <>
      {isPDFOption && (
        <SellerPdfDocument
          isSimulation={false}
          lastSync={statistics?.statisticsLastSync}
          isHiddenButton={!statistics?.generalStatistics?.bids}
        />
      )}
      {isBrokerView && (
        <Banner
          icon={ReadHuman}
          image={BannerImage}
          title={t('seller-portal.broker-view.banner.title')}
          description={t('seller-portal.broker-view.banner.description')}
        />
      )}
      {isMarketingPaused && (
        <AlertInfo
          style={{ backgroundColor: themeContext?.paleRed }}
          text={t('marketing-dashboard.marketing-paused.message')}
        />
      )}
      {/* <AlertInfo */}
      {/*  style={{ backgroundColor: themeContext?.paleRed }} */}
      {/*  text={t('marketing-dashboard.weekly-statistics-update.message', { */}
      {/*    date: formatSyncDate(new Date(statistics?.statisticsLastSync)), */}
      {/*  })} */}
      {/*  isInnerHtml */}
      {/*  icon={ */}
      {/*    <Icon */}
      {/*      icon={AlertTriangle} */}
      {/*      width={16} */}
      {/*      height={16} */}
      {/*      color={themeContext.blue} */}
      {/*      style={{ */}
      {/*        alignSelf: 'center', */}
      {/*        marginRight: '12px', */}
      {/*        minWidth: '16px', */}
      {/*      }} */}
      {/*      strokeWidth={'1.5'} */}
      {/*    /> */}
      {/*  } */}
      {/* /> */}
      <Container>
        {isStatisticsBlurred &&
          status === MarketingStatus.DashboardDataCollected && (
            <DashboardLockedMessage
              // onBookAppointment={onBookAppointment}
              onEmailBroker={onEmailBroker}
              onShowPreview={isBrokerView ? onShowPreview : undefined}
            />
          )}
        <FirstColumn>
          {getBrokerError?.message && (
            <GlobalError
              title={t(getBrokerError?.message?.split(':')[0].toLowerCase())}
            />
          )}
          {getStatisticsError?.message && (
            <GlobalError
              title={t(
                getStatisticsError?.message?.split(':')[0].toLowerCase()
              )}
            />
          )}
          {getDashboardError?.message && (
            <GlobalError
              title={t(getDashboardError?.message?.split(':')[0].toLowerCase())}
            />
          )}
          {status === MarketingStatus.NotStarted ? (
            <Placeholder />
          ) : (
            <>
              <ExposeStatisticsCard
                statistics={advertisementViewsStatistics}
                isHiddenSendEmailToBrokerAlert={isHiddenSendEmailToBrokerAlert}
                propertyBroker={propertyBroker}
                isBlurred={isStatisticsBlurred}
                onBookAppointment={onBookAppointment}
                onEmailBroker={onEmailBroker}
                showAlert={Boolean(propertyBroker && showChartAlert)}
              />
              {isMobileSize && (
                <GeneralStatisticsCard
                  statistics={statistics?.generalStatistics}
                  isBlurred={isStatisticsBlurred}
                  statisticsLastSync={statistics?.statisticsLastSync}
                  showRequestedExposes={showRequestedExposes}
                  showUnlockedExposes={showUnlockedExposes}
                  showViewingAppointments={showViewingAppointments}
                />
              )}

              {!isMobileSize && (
                <>
                  {!isEmpty(offersStatisticsPerWeek) && (
                    <OffersStatisticsCard
                      statistics={offersStatisticsPerWeek}
                      isBlurred={isStatisticsBlurred}
                      showFilter={!!statistics?.generalStatistics?.bids}
                      showRequestedExposes={showRequestedExposes}
                      showUnlockedExposes={showUnlockedExposes}
                      showViewingAppointments={showViewingAppointments}
                    />
                  )}
                </>
              )}
            </>
          )}
        </FirstColumn>
        <SecondColumn>
          {isMobileSize && (
            <>
              {!isEmpty(offersStatisticsPerWeek) && (
                <OffersStatisticsCard
                  statistics={offersStatisticsPerWeek}
                  isBlurred={isStatisticsBlurred}
                  showFilter={!!statistics?.generalStatistics?.bids}
                  showRequestedExposes={showRequestedExposes}
                  showUnlockedExposes={showUnlockedExposes}
                  showViewingAppointments={showViewingAppointments}
                />
              )}
            </>
          )}
          {!isMobileSize && (
            <>
              {status === MarketingStatus.NotStarted ? (
                <ProgressCard />
              ) : (
                <GeneralStatisticsCard
                  statistics={statistics?.generalStatistics}
                  isBlurred={isStatisticsBlurred}
                  statisticsLastSync={statistics?.statisticsLastSync}
                  showRequestedExposes={showRequestedExposes}
                  showUnlockedExposes={showUnlockedExposes}
                  showViewingAppointments={showViewingAppointments}
                />
              )}{' '}
            </>
          )}

          <TeamContactCard />
        </SecondColumn>
      </Container>
    </>
  );
};

const Marketing = memo(MarketingBase);

export { Marketing };
