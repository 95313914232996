import { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  PropertyPurchaseIntent,
  useExposeDetailsQuery,
  useReadPropertyPurchaseIntentsQuery,
} from '../../../../generated';

import FormPurchaseIntend from '../../../forms/form-purchase-intend/components/containers/form-purchase-intend';
import { FullScreenMap } from './components/mapbox/full-screen-map';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { Info } from './components/info';
import { MainContainer } from './components/containers/main-container';
import { MapboxContainer } from './components/mapbox/mapbox-container';
import { Modal } from '../../../common/components/modal';
import { RootState } from '../../../../store';
import { useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';

const LoaderContainer = styled.div`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExposeDetailsOverlay: FC = () => {
  const isMobileSize = useIsMobileSize();
  const isExposeOverlayOpen = useAppSelector(
    (state) => state.exposeDetails.isExposeOverlayOpen
  );
  const propertyId = useAppSelector(
    (state) => state.exposeDetails.selectedPropertyId
  );
  const { isLoading, error } = useExposeDetailsQuery({
    id: propertyId,
  });

  const { isOpen } = useSelector((state: RootState) => state.purchaseIntend);

  const {
    data: propertyPurchaseIntentsData,
    isLoading: propertyPurchaseIntentsLoading,
    refetch: refetchPropertyPurchaseIntents,
  } = useReadPropertyPurchaseIntentsQuery({
    propertyId,
  });

  return (
    <Modal
      isOpen={isExposeOverlayOpen}
      wrapperStyles={{
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        padding: 0,
      }}
    >
      <MainContainer>
        {isLoading || error || propertyPurchaseIntentsLoading ? (
          <LoaderContainer>
            <GlobalLoader />
          </LoaderContainer>
        ) : (
          <>
            <Info
              refetch={refetchPropertyPurchaseIntents}
              purchaseIntents={
                (propertyPurchaseIntentsData?.readPropertyPurchaseIntents ||
                  []) as PropertyPurchaseIntent[]
              }
            />
            {isOpen && (
              <FormPurchaseIntend
                refetch={refetchPropertyPurchaseIntents}
                isOpen={isOpen}
              />
            )}
            {!isMobileSize && <MapboxContainer />}
            <FullScreenMap />
          </>
        )}
      </MainContainer>
    </Modal>
  );
};

export { ExposeDetailsOverlay };
