import styled from 'styled-components';
import { device } from '../../../../../../style/theme';

export const Background = styled.div`
  height: 100vh;
  background-color: ${(props) => props.theme.logoBlue};
  padding: 32px;
  @media ${device.tablet} {
    padding: 56px 0 0;
  }
`;

export const Container = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.white};
  border-radius: 3px;
  position: relative;
  height: 100%;
`;
