import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const Container = styled.div<{ isTopPadding?: boolean }>`
  display: flex;
  ${({ isTopPadding }) => isTopPadding && 'padding-top: 88px;'}
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 3px;
  width: 100%;
  height: 100%;

  @media ${device.tablet} {
    padding: 0 0 32px;
    align-items: center;
    width: auto;
  }

  @media ${device.mobile} {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

export const BgColor = styled.div`
  position: absolute;
  width: 100vw;
  top: 0;
  left: 50%;
  right: 50%;
  margin: 0 -50vw;
  height: 100%;
  background-color: #f2f4f7;
  @media ${device.tablet} {
    position: initial;
  }
`;

export const HeaderContainer = styled.div<{ noTopPadding?: boolean }>`
  width: 100%;
  z-index: 1;
  text-align: center;
  padding: 0 32px 64px;
  ${({ noTopPadding }) => noTopPadding && `padding: 0 32px;`}
  @media ${device.tablet} {
    padding: 16px;
  }
`;

export const Headline = styled.h2`
  margin: 0;
  font-family: 'Source Serif Pro';
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const BlueTitle = styled.span`
  color: ${({ theme }) => theme.blue};
`;
