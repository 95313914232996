export function getDate(date: string, showYear = false): string {
  const dateObj = new Date(date);
  const day = toDoubleFixed(dateObj.getDate());
  const month = toDoubleFixed(dateObj.getMonth() + 1);
  const year = toDoubleFixed(dateObj.getFullYear());
  return `${day}.${month}${showYear ? `.${year}` : ''}`;
}

export function getTime(date: string): string {
  const dateObj = new Date(date);
  const hours = toDoubleFixed(dateObj.getHours());
  const minutes = toDoubleFixed(dateObj.getMinutes());
  return `${hours}:${minutes}`;
}

export function getDay(date: string): string {
  const dateObj = new Date(date);
  const day = dateObj.getDay();
  return ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'][day];
}

function toDoubleFixed(val: number) {
  if (val < 10) return `0${val.toString()}`;
  return val.toString();
}
