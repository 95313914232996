import { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';

import {
  changeMapViewport,
  removeAllMapMarkersExceptHome,
  setMapSettings,
} from '../../../redux/exposeSlice';
import { PropertyLocation } from '../../../../../../../generated';

interface IProps {
  location?: PropertyLocation;
}

const useMapSocialEconomics = ({ location }: IProps): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (location) {
      batch(() => {
        dispatch(
          changeMapViewport({
            ...location?.coordinates,
            bearing: -80,
            pitch: 65,
            zoom: 14,
            transitionDuration: 0,
          })
        );
        dispatch(
          setMapSettings({
            dragPan: true,
            dragRotate: true,
            scrollZoom: true,
            touchZoom: true,
            touchRotate: true,
            keyboard: true,
            doubleClickZoom: true,
          })
        );
      });
    }
    return () => {
      batch(() => {
        dispatch(removeAllMapMarkersExceptHome());
      });
    };
  }, [dispatch, location]);
};

export { useMapSocialEconomics };
