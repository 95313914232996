import styled from 'styled-components';
import { RefObject, useCallback, useEffect, useState } from 'react';

interface IBlurry {
  isInitiallyVisible?: boolean;
  scrolledElementRef: RefObject<HTMLDivElement>;
  isReversed?: boolean;
  position?: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  };
}

const BlurryElement = styled.div<{
  isVisible: boolean;
  isReversed?: boolean;
  position: IBlurry['position'];
}>`
  height: 64px;
  width: 100%;
  position: absolute;
  transform: rotate(${({ isReversed }) => (isReversed ? '180deg' : '0deg')});
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
  transition: 0.12s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  z-index: ${({ isVisible }) => (isVisible ? 1 : -1)};
  pointer-events: none;
  ${({ position, isReversed }) =>
    position
      ? `left: ${position?.left}; right: ${position?.right}; top:${position?.top}; bottom: ${position?.bottom};`
      : `${isReversed ? 'top: 0' : 'bottom: 0'}; left:0;`}
`;

const Blurry = ({
  isInitiallyVisible = true,
  scrolledElementRef,
  position,
  isReversed,
}: IBlurry): JSX.Element => {
  const [isElementScrolled, setIsElementScrolled] =
    useState(isInitiallyVisible);
  const onElementScroll = useCallback(
    (element: HTMLDivElement) => () => {
      if (!isReversed) {
        if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
          setIsElementScrolled(false);
          return;
        }
        setIsElementScrolled(true);
        return;
      }

      if (element.scrollTop > 0) {
        setIsElementScrolled(true);
        return;
      }
      setIsElementScrolled(false);
    },
    [isReversed]
  );
  useEffect(() => {
    const element = scrolledElementRef.current;
    if (element) {
      element.addEventListener('scroll', onElementScroll(element));
    }

    return () => {
      if (element)
        element.removeEventListener('scroll', onElementScroll(element));
    };
  }, [onElementScroll, scrolledElementRef]);

  return (
    <BlurryElement
      isVisible={isElementScrolled}
      position={position}
      isReversed={isReversed}
    />
  );
};

export { Blurry };
