export const parseWithoutCurrency = (amount: number) => {
  const strArr = amount.toString().split('').reverse();
  const newArr: string[] = [];

  strArr.forEach((ch, index) => {
    newArr.push(ch);
    if ((index + 1) % 3 === 0) {
      newArr.push('.');
    }
  });

  const final = newArr.reverse().join('');

  return `${final[0] === '.' ? final.slice(1) : final}`;
};

export const getInEur = (amount: number): string => {
  return `${parseWithoutCurrency(amount)} €`;
};

export const parseFloatWithoutCurrency = (amount: number): string => {
  if (!amount) return '';

  const arr = amount.toString().split('.');
  const beforeDecimal = arr[0];
  let afterDecimal = arr[1] || '00';

  afterDecimal = afterDecimal.slice(0, 2);

  return `${parseWithoutCurrency(Number.parseInt(beforeDecimal, 10))}${
    afterDecimal === '00' || afterDecimal === '0' ? '' : `,${afterDecimal}`
  }`;
};
