import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';
import { KitchenIcon } from '../icons';

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '3mm',
    paddingBottom: '3mm',
    paddingLeft: '4mm',
    paddingRight: '4mm',
    border: '1px',
    borderColor: '#d9d9d9',
    borderRadius: '1mm',
    backgroundColor: '#fff',
    marginBottom: '3mm',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: '4mm',
  },
  nameWrapper: {
    display: 'flex',
  },
  nameText: {
    marginBottom: '2mm',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 1.14,
    letterSpacing: '0.5px',
    color: defaultTheme.blue,
  },
  addressText: {
    opacity: 0.5,
    fontSize: '8px',
    fontWeight: 'bold',
    lineHeight: 1.5,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  distanceText: {
    color: 'green',
    fontSize: '5mm',
    marginBottom: '2mm',
  },
});

const TransportCard = ({
  name,
  distance,
  address,
}: {
  name: string;
  distance: number;
  address?: string;
}): JSX.Element => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.left}>
        <View style={styles.icon}>
          <KitchenIcon />
        </View>
        <View style={styles.nameWrapper}>
          <Text style={styles.nameText}>{name}</Text>
          <Text
            style={[
              styles.addressText,
              { color: address ? defaultTheme.blue : 'rgba(0, 0, 0, 0)' },
            ]}
          >
            {address || ' '}
          </Text>
        </View>
      </View>
      <View style={styles.right}>
        <View>
          <Text style={styles.distanceText}>{distance}m</Text>
        </View>
      </View>
    </View>
  );
};

export { TransportCard };
