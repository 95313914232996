import { FC, memo } from 'react';
import styled from 'styled-components';

const RatingContainer = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 12px;
`;

interface IProps {
  rating: 0 | 1 | 2 | 3 | 4 | 5;
}

const RatingIconsBase: FC<IProps> = ({ rating }) => {
  const arrayRating = Array.from({ length: 5 });
  return (
    <RatingContainer>
      {arrayRating.map((item, i) => (
        <svg
          key={i}
          width={20}
          height={20}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            d="M23.5 19.5a4 4 0 0 1-4 4h-15a4 4 0 0 1-4-4v-15a4 4 0 0 1 4-4h15a4 4 0 0 1 4 4Z"
            fill={i < rating ? '#4e73f5' : '#ffffff'}
            stroke={i < rating ? '#4e73f5' : '#d9d9d9'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m12.706 5.93 1.539 3.57h2.995a.735.735 0 0 1 .516 1.283l-2.6 2.316 1.44 3.313a.785.785 0 0 1-1.117.982L12 15.433l-3.48 1.961a.785.785 0 0 1-1.12-.982L8.843 13.1l-2.6-2.315A.735.735 0 0 1 6.758 9.5h3l1.541-3.57a.8.8 0 0 1 1.407 0Z"
            fill="white"
            stroke={i < rating ? '#ffffff' : '#d9d9d9'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ))}
    </RatingContainer>
  );
};

const RatingsIcon = memo(RatingIconsBase);

export { RatingsIcon };
