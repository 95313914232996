import styled from 'styled-components';
import {
  Headline2,
  ParagraphText,
} from '../../../../common/components/ui/typography';
import { device } from '../../../../../style/theme';

export const ContainerItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

export const Container = styled.div`
  position: relative;
  z-index: 1;
`;

export const BgColor = styled.div`
  position: absolute;
  width: 100vw;
  margin: 0 -50vw;
  top: 0;
  left: 50%;
  right: 50%;
  height: 100%;
  background-color: #f2f4f7;
`;

export const ImageContentContainer = styled.div<{ isRevert?: boolean }>`
  display: flex;
  position: relative;

  ${({ isRevert }) =>
    isRevert &&
    `
    > div:first-child {
      order: 2
    }
    > div:last-child {
      order: 1
    }
  `}
`;

export const ImageContainer = styled.div<{
  isProportional?: boolean;
  isFit?: boolean;
  isRevert?: boolean;
}>`
  max-width: 520px;
  max-height: 600px;
  margin-right: 48px;
  position: relative;
  top: -64px;
  z-index: 10;
  margin-top: 64px;

  ${({ isProportional }) =>
    isProportional &&
    `
    top: -9px;
    min-width: 450px;
    margin-top: 0;
  `}

  ${({ isFit }) =>
    isFit &&
    `
    flex: 1;
    position: static;
    max-width: unset;
    max-height: unset;
  `}

  ${({ isRevert }) =>
    isRevert &&
    `
    margin-right: 0;
    margin-left: 48px;
  `}

  @media ${device.tablet} {
    display: none;
  }
`;

export const Image = styled.img<{ isProportional?: boolean; isFit?: boolean }>`
  height: auto;
  width: 100%;

  ${({ isProportional }) =>
    isProportional &&
    `
    position: absolute;
    width: 652px;
    left: -125px;
  `}

  ${({ isFit }) =>
    isFit &&
    `
    position: absolute;
    width: 620px;
    left: -30px;
    top: 0;

    @media ${device.tabletSlider} {
      width: 540px;
    }
  `}
`;

export const Content = styled.div<{
  isProportional?: boolean;
  isFit?: boolean;
}>`
  max-width: 552px;
  color: ${({ theme }) => theme.blue};
  margin-top: 48px;
  margin-bottom: 96px;

  ${({ isProportional }) =>
    isProportional &&
    `
    max-width: 600px;
    position: relative;
    right: -48px;
    margin-top: 16px;

    @media ${device.tablet} {
      max-width: initial;
      position: initial;
      right: initial;
    }
  `}

  ${({ isFit }) =>
    isFit &&
    `
      flex-basis: 45%;
      position: static;

      @media ${device.tablet} {
        flex: 1;
      }
    `}

  @media ${device.tablet} {
    margin-top: 16px;
    margin-bottom: 40px;
  }
`;

export const Line = styled.div<{ isTop: boolean }>`
  z-index: 5;
  height: 1px;
  left: 50%;
  right: 50%;
  margin: 0 -50vw;
  position: absolute;
  ${({ isTop }) => (isTop ? 'top: 0;' : 'bottom: 32px;')}
  width: 100vw;
  background-color: ${({ theme }) => theme.borderColor};
`;

export const Title = styled(Headline2)`
  margin-bottom: 8px;

  @media ${device.mobile} {
    margin-bottom: 16px;
    text-align: center;
  }
`;

export const Description = styled(ParagraphText)`
  margin-bottom: 24px;
  color: ${({ theme }) => theme.blue};

  @media ${device.mobile} {
    margin-bottom: 16px;
    text-align: left;
  }
`;

export const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 64px;
  @media ${device.tablet} {
    margin-bottom: 24px;
  }
`;

export const VerticalLine = styled.div`
  border-left: 1px dashed rgba(0, 40, 73, 0.5);
  height: calc(100% - 60px);
  position: absolute;
  z-index: 5;
  left: 40px;
  top: 30px;

  @media ${device.tablet} {
    left: 24px;
  }
`;

export const CTAButtonWrapper = styled.div`
  @media ${device.mobile} {
    button {
      width: 100%;
    }
  }
`;
