import { isEmpty } from 'lodash';
import { forwardRef, memo, useEffect, useState } from 'react';

import BackgroundImg from 'assets/expose-request-page.png';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import provenExpert from 'assets/images/icons/financing-form/info-container/icon.png';

import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { ModalMap } from '../../../../components/fullscreen-modal/modal-map/modal-map';
import MessageBox from '../../common/message-box';
import { PropertyDetails } from '../../common/property-details/property-details';
import SuggestedProperties from '../../common/suggested-properties/suggested-properties';
import {
  Container,
  Header,
  MapContainer,
  Wrapper,
  InfoHeader,
  InfoWrapper,
  ProvenExpertContainer,
  ProvenExpertImage,
  Row,
  SubHeader,
} from './search-profile-info-container-styles';
import {
  OrderSuggestedPropertiesBy,
  SuggestedPropertiesFilterInput,
  useLazySuggestedPropertiesQuery,
  useUpdateTemporarySearchProfileMutation,
} from '../../../../../../generated';
import { useModalButtonsHandler } from '../../../hooks/useModalButtonsHandler';
import { AddressBar } from '../../../../../common/components/ui/hero-slider/address-bar';
import {
  updateIsSuggestedOutdated,
  updateTemporarySearchProfileToClaim,
} from '../../../redux/searchProfileSlice';
import EmptyStatePlaceholder, {
  Variations,
} from '../../../../../search-profile/components/suggested-properties/empty-state-placeholder';

enum ViewStates {
  PLACEHOLDER,
  DISPLAY_QUERIED_PROPERTIES,
  OFFER_EXTEND_RADIUS,
  OFFER_UPDATE_SP,
}

const sleepMs = (timeout = 100) =>
  new Promise((resolve) => setTimeout(resolve, timeout));

const SearchProfileInfoContainer = forwardRef<
  HTMLDivElement,
  {
    // second value of useLazySuggestedPropertiesQuery()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialSuggestedPropertiesData: any;
  }
>(({ initialSuggestedPropertiesData }, ref) => {
  const { t } = useTranslation();

  const { watch } = useFormContext();

  const [suggestedPropertiesData, setSuggestedPropertiesData] = useState(
    initialSuggestedPropertiesData
  );

  const storedAddress = useAppSelector(
    (state) => state.searchProfile.selectedAddress
  );
  const viewport = useAppSelector((state) => state.searchProfile.map.viewport);
  const settings = useAppSelector((state) => state.searchProfile.map.settings);
  const userInput = useAppSelector((state) => {
    return state.searchProfile;
  });
  const [viewState, setViewState] = useState<ViewStates>(
    ViewStates.PLACEHOLDER
  );
  useEffect(() => {
    if (
      initialSuggestedPropertiesData.isSuccess &&
      initialSuggestedPropertiesData?.data?.suggestedProperties?.page !==
        undefined
    ) {
      setSuggestedPropertiesData(initialSuggestedPropertiesData);

      const nextViewState =
        initialSuggestedPropertiesData?.data?.suggestedProperties?.page.length >
        0
          ? ViewStates.DISPLAY_QUERIED_PROPERTIES
          : ViewStates.OFFER_EXTEND_RADIUS;
      setViewState(nextViewState);
    }
  }, [initialSuggestedPropertiesData]);

  const values = watch();

  const formData = isEmpty(values) ? userInput : values;

  const { radius } = formData || {};

  const dispatch = useAppDispatch();
  const filters = useAppSelector(
    (state) => state.suggestedProperties.filters
  ) as SuggestedPropertiesFilterInput;

  const { reset } = useFormContext();
  const { onBackClick } = useModalButtonsHandler({
    reset,
    watch,
  });

  const temporarySearchProfileToClaim = useAppSelector(
    (state) => state.searchProfile.temporarySearchProfileToClaim
  );
  const suggestedPropertiesQuery = useLazySuggestedPropertiesQuery();
  const triggerSuggestedPropertiesQuery = suggestedPropertiesQuery[0];
  const suggestedPropertiesResponse = suggestedPropertiesQuery[1];

  const isSuggestedOutdated = useAppSelector(
    (state) => state.searchProfile.isSuggestedOutdated
  );

  const [updateTemporarySearchProfile, updateSearchProfileResponse] =
    useUpdateTemporarySearchProfileMutation();

  const [isExtendingRadius, setIsExtendingRadius] = useState(false);
  const [radiusExtendedBy, setRadiusExtendedBy] = useState(0);

  const handleExtendRadius = async (
    currentSP: NonNullable<typeof temporarySearchProfileToClaim>,
    delta = 35
  ) => {
    const nextRadiusExtendedBy = radiusExtendedBy + delta;

    await updateTemporarySearchProfile({
      input: {
        _id: currentSP._id,
        searchProfileData: {
          radius: currentSP.searchProfileData.radius + nextRadiusExtendedBy,
        },
      },
    });
    triggerSuggestedPropertiesQuery({
      filter: {
        offset: filters.offset ?? 0,
        limit: filters.limit ?? 8,
        orderBy: {
          field: OrderSuggestedPropertiesBy.Proximity,
          direction: 'asc',
          proximityParams: {
            latitude:
              currentSP.searchProfileData.location.coordinates.latitude ?? 0,
            longitude:
              currentSP.searchProfileData.location.coordinates.longitude ?? 0,
          },
        },
        unsuitablePropertiesIds: [],
        searchProfileData: {
          ...currentSP.searchProfileData,
          radius: currentSP.searchProfileData.radius + nextRadiusExtendedBy,
        },
      },
      searchProfileId: currentSP._id,
    });

    setRadiusExtendedBy(nextRadiusExtendedBy);
    dispatch(updateIsSuggestedOutdated(false));

    await sleepMs(200);
  };

  useEffect(() => {
    const cleanup = () => {
      setRadiusExtendedBy(0);
      setIsExtendingRadius(false);
    };

    const cb = async () => {
      if (
        !(isExtendingRadius && temporarySearchProfileToClaim) ||
        suggestedPropertiesResponse.isLoading
      ) {
        return;
      }

      if (
        !(suggestedPropertiesResponse.isUninitialized || isSuggestedOutdated)
      ) {
        const propertiesData =
          suggestedPropertiesResponse.data?.suggestedProperties;
        if (propertiesData?.page && propertiesData.page.length > 0) {
          setSuggestedPropertiesData(suggestedPropertiesResponse);
          setViewState(ViewStates.DISPLAY_QUERIED_PROPERTIES);
          cleanup();
          return;
        }

        if (radiusExtendedBy > 200) {
          setViewState(ViewStates.OFFER_UPDATE_SP);
          cleanup();
          return;
        }
      }

      await handleExtendRadius(temporarySearchProfileToClaim);
    };

    cb();
  }, [isExtendingRadius, suggestedPropertiesResponse]);

  return (
    <Container src={BackgroundImg}>
      <Wrapper>
        <Header>{t('search-profile.info-container.title')}</Header>
        <MapContainer>
          <ModalMap viewport={viewport} settings={settings} />
        </MapContainer>
        <AddressBar
          title={t('search-profile.info-container.plz')}
          show
          isSkeleton
          isStarsImage
          radiusInfo={
            radius && storedAddress?.place_name ? `+${radius} KM` : undefined
          }
          address={storedAddress?.place_name}
        />
        <InfoWrapper>
          <SubHeader>
            {t('search-profile.info-container.details-subtitle').toUpperCase()}
          </SubHeader>
          <InfoHeader>
            {t('search-profile.info-container.details-title')}
          </InfoHeader>
          <PropertyDetails />
          <Row>
            <MessageBox
              propertiesCount={
                suggestedPropertiesData?.data?.suggestedProperties?.pageData
                  ?.count || 0
              }
            />
          </Row>
          <Row>
            {[
              ViewStates.PLACEHOLDER,
              ViewStates.DISPLAY_QUERIED_PROPERTIES,
            ].includes(viewState) && (
              <SuggestedProperties
                isLoading={suggestedPropertiesResponse.isLoading}
                properties={
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  suggestedPropertiesData?.data?.suggestedProperties?.page
                }
                isLighter={
                  suggestedPropertiesData?.data?.suggestedProperties?.page
                    ?.length > 0
                }
                address={storedAddress?.place_name
                  ?.split(',')
                  ?.slice(0, 2)
                  ?.join(',')}
              />
            )}
            {viewState === ViewStates.OFFER_EXTEND_RADIUS && (
              <SuggestedProperties
                isLoading={suggestedPropertiesResponse.isLoading}
                isLighter={
                  suggestedPropertiesData?.data?.suggestedProperties?.page
                    ?.length > 0
                }
                address={storedAddress?.place_name
                  ?.split(',')
                  ?.slice(0, 2)
                  ?.join(',')}
              />
            )}
            {viewState === ViewStates.OFFER_UPDATE_SP && (
              <EmptyStatePlaceholder
                variation={Variations.OFFER_UPDATE_SP}
                onClick={() => {
                  dispatch(updateTemporarySearchProfileToClaim(null));
                  setViewState(ViewStates.PLACEHOLDER);
                  suggestedPropertiesResponse.isUninitialized = true;
                  dispatch(updateIsSuggestedOutdated(true));
                  onBackClick();
                }}
              />
            )}
          </Row>
        </InfoWrapper>
        <ProvenExpertContainer>
          <ProvenExpertImage
            src={provenExpert}
            width={88}
            height={83}
            alt="proven expert"
          />
        </ProvenExpertContainer>
      </Wrapper>
    </Container>
  );
});

export { SearchProfileInfoContainer };
