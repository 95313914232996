import { memo } from 'react';
import styled from 'styled-components';

interface IProps {
  isVisible: boolean;
  dossierUrl: string;
}

const Container = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  will-change: opacity;
  transition: opacity 665ms ease;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
`;

const IframeMapBase = ({ isVisible, dossierUrl }: IProps): JSX.Element => (
  <Container isVisible={isVisible}>
    <Iframe src={`${dossierUrl}?map_only=true`} />
  </Container>
);

const IframeMap = memo(IframeMapBase);

export { IframeMap };
