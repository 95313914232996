import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { useFormContext, useWatch } from 'react-hook-form';
import { FlyToInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';

import { GlobalError } from '../../../../../common/components/form/error/global-error';
import { FINANCING_FORM_KEYS, Proximity } from '../../../constants';
import {
  extractCorrectCityValue,
  getSuggestions,
} from '../../../../utils/suggestions';
import { defaultDebaunceState } from '../../../../form-search-profile/components/forms/search-profile-form/steps/first-step/constants';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useLazyForwardGeocodingQuery } from '../../../../../../services/mapbox/mapbox-api';
import { Feature } from '../../../../../../services/mapbox/interfaces';
import {
  setSelectedAddressAction,
  setSelectedCountryCode,
  changeMapViewport,
  setMapSettings,
} from '../../../redux/financingSlice';
import getCountry from '../../../../utils/getCountry';
import { useSetProximityFromLocalization } from '../../../../hooks/set-proximity-from-localization';
import { useGetCountry } from '../../../../../localization/get-country';
import { InputWithSuggestionsV2 } from '../../../../components/common/input-with-suggestions-v2';

interface IProps {
  defaultValue?: string;
  proceedToNextStep?: boolean;
  setProceedToNextStep?: (condition: boolean) => void;
  // invalidAddress?: boolean;
  // setInvalidAddress: (condition: boolean) => void;
  label?: string;
  placeholder?: string;
  notRequired?: boolean;
  tooltipKey?: string;

  isOldDesign?: boolean;
}

const fields = [
  FINANCING_FORM_KEYS.CITY,
  FINANCING_FORM_KEYS.PLACE_NAME,
  FINANCING_FORM_KEYS.POST_CODE,
  FINANCING_FORM_KEYS.LOCALITY,
  FINANCING_FORM_KEYS.NEIGHBORHOOD,
  FINANCING_FORM_KEYS.REGION,
];

export interface IAddress {
  postCode: string;
  locality: string;
  neighborhood: string;
  region: string;
  street: string;
  number: string;
  city: string;
  placeName: string;
  state: string;
  country: string;
  proximity: string;
  types?: string;
  language: string;
}

const InputFinancingAddress: FC<IProps> = ({
  defaultValue,
  proceedToNextStep,
  setProceedToNextStep,
  // invalidAddress,
  // setInvalidAddress,
  label,
  placeholder,
  notRequired,
  tooltipKey,

  isOldDesign,
}) => {
  const [city, placeName, postCode, locality, neighborhood, region] = useWatch({
    name: fields,
  });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const [invalidAddress, setInvalidAddress] = useState(false);

  const relatedCountry = useAppSelector(
    (state) => state.financing.relatedCountry
  );

  const [debouncedAddress, setDebouncedAddress] =
    useState(defaultDebaunceState);
  const proximity = useSetProximityFromLocalization();
  const { language } = useGetCountry();

  // fix autocomplete for MS Edge
  const [isReadonly, setIsReadonly] = useState(true);

  const [
    geocodingTrigger,
    { mostAccurateGeocodingResult, isFetching: isMapboxFetching },
  ] = useLazyForwardGeocodingQuery({
    selectFromResult: ({ data, isFetching }) => ({
      mostAccurateGeocodingResult: data,
      isFetching,
    }),
  });

  // hook
  let defaultProximity = Proximity.GERMANY;
  switch (relatedCountry && relatedCountry.toLowerCase()) {
    case 'germany':
      defaultProximity = Proximity.GERMANY;
      break;
    case 'austria':
      defaultProximity = Proximity.AUSTRIA;
      break;
    case 'switzerland':
      defaultProximity = Proximity.SWITZERLAND;
      break;
    default:
      defaultProximity = Proximity.GERMANY;
  }

  // hook
  const onDebounceAddressChange = useDebouncedCallback(
    (newDebouncedAddress: IAddress) => {
      setDebouncedAddress(newDebouncedAddress);
    },
    300
  );
  const handleChangeProp = () => {
    setValue(FINANCING_FORM_KEYS.POST_CODE, '');
    setValue(FINANCING_FORM_KEYS.CITY, '');
    setValue(FINANCING_FORM_KEYS.LOCALITY, '');
    setValue(FINANCING_FORM_KEYS.NEIGHBORHOOD, '');
    setValue(FINANCING_FORM_KEYS.REGION, '');
  };

  const onSuggestionClick = useCallback(
    (suggestion: Feature) => {
      const {
        updatedCity,
        updatedPostCode,
        updatedLocality,
        updatedNeighborhood,
        updatedRegion,
      } = extractCorrectCityValue(suggestion);
      dispatch(setSelectedCountryCode(getCountry(suggestion)));

      setValue(FINANCING_FORM_KEYS.POST_CODE, updatedPostCode);
      setValue(FINANCING_FORM_KEYS.CITY, updatedCity);
      setValue(
        FINANCING_FORM_KEYS.PLACE_NAME,
        suggestion.place_name_de || suggestion.place_name
      );
      setValue(FINANCING_FORM_KEYS.LOCALITY, updatedLocality);
      setValue(FINANCING_FORM_KEYS.NEIGHBORHOOD, updatedNeighborhood);
      setValue(FINANCING_FORM_KEYS.REGION, updatedRegion);

      if (!updatedCity) {
        setInvalidAddress(true);
      } else {
        setInvalidAddress(false);
      }

      const selectedSuggestion = JSON.parse(JSON.stringify([suggestion]))[0];
      const pin = {
        longitude: Number(selectedSuggestion?.center?.[0]),
        latitude: Number(selectedSuggestion?.center?.[1]),
      };
      dispatch(setSelectedAddressAction(selectedSuggestion));
      dispatch(
        changeMapViewport({
          longitude: pin?.longitude ?? 0,
          latitude: pin?.latitude ?? 0,
          zoom: 15,
          transitionDuration: 'auto',
          transitionInterpolator: new FlyToInterpolator({
            speed: 1.8,
          }),
          transitionEasing: easeCubic,
        })
      );
      dispatch(setMapSettings({ isMapMarker: true }));
      if (
        !isMapboxFetching &&
        proceedToNextStep &&
        mostAccurateGeocodingResult?.length === 0
      ) {
        setInvalidAddress(true);
      }
    },
    [
      dispatch,
      isMapboxFetching,
      mostAccurateGeocodingResult,
      proceedToNextStep,
      setInvalidAddress,
      setValue,
    ]
  );

  // find suggestions from the input value
  useEffect(() => {
    if (
      placeName &&
      debouncedAddress.placeName !== placeName &&
      placeName?.length > 2
    ) {
      if (setProceedToNextStep) {
        setProceedToNextStep(false);
      }

      onDebounceAddressChange({
        ...debouncedAddress,
        proximity: proximity || defaultProximity,
        city: city || '',
        placeName: placeName || '',
        postCode: postCode || '',
        locality: locality || '',
        neighborhood: neighborhood || '',
        region: region || '',
        language,
        country: 'de',
        types: 'address,postcode',
      });
    }
  }, [
    language,
    onDebounceAddressChange,
    city,
    placeName,
    postCode,
    debouncedAddress,
    proximity,
    region,
    defaultProximity,
    setProceedToNextStep,
    locality,
    neighborhood,
  ]);

  // hook
  useEffect(() => {
    if (
      Object.keys(debouncedAddress)
        .filter((key) => key !== 'country')
        .some(
          (key) => (debouncedAddress as { [key: string]: string })[key] !== ''
        )
    ) {
      geocodingTrigger(debouncedAddress);
    }
  }, [geocodingTrigger, debouncedAddress]);

  // Fix autocomplete for MS Edge
  useEffect(() => setIsReadonly(false), []);

  return (
    <>
      {invalidAddress && (
        <GlobalError title={t('invalid-address.error')} topMargin />
      )}
      <InputWithSuggestionsV2
        tooltipKey={tooltipKey}
        defaultValue={defaultValue}
        name={FINANCING_FORM_KEYS.PLACE_NAME}
        label={label ?? 'search-profile.form.first-step.address'}
        placeholder={
          placeholder ?? 'search-profile.form.first-step.address-placeholder'
        }
        rules={notRequired ? {} : { required: 'register.input.error.required' }}
        readonly={isReadonly}
        isOldDesign={isOldDesign}
        handleChangeProp={handleChangeProp}
        suggestions={getSuggestions(
          FINANCING_FORM_KEYS.ADDRESS,
          mostAccurateGeocodingResult ?? []
        )}
        onSuggestionClick={onSuggestionClick}
      />
    </>
  );
};

export { InputFinancingAddress };
