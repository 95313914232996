import styled from 'styled-components';
import {
  MainButton,
  SecondaryButton,
} from '../../../../common/components/ui/buttons';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../style/theme';

export const Container = styled.div`
  position: relative;
`;

export const Button = styled(SecondaryButton)<{ isActive?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  background-color: ${(props) =>
    props.isActive ? hexToRGB(props.theme.blue, 0.2) : props.theme.white};
  position: relative;

  ${(props) =>
    props.isActive &&
    `
    &::before {
    content: ' ';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    background-color: ${props.theme.green};
    border-radius: 50%;
    border: 2px solid ${props.theme.white};
    top: -4px;
    right: -4px;
  }
  `}
`;

export const ClearFilterButton = styled(SecondaryButton)`
  justify-content: center;
  height: 48px;
  text-transform: uppercase;
`;

export const ApplyFilterButton = styled(MainButton)`
  justify-content: center;
  height: 48px;
  margin-top: 12px;
  text-transform: uppercase;
`;

export const Form = styled.form<{ isSimulation?: boolean }>`
  display: flex;
  flex-direction: column;
  //gap: 12px;
  padding: 12px;
  position: absolute;
  z-index: 1;
  background-color: ${(props) => props.theme.white};
  border: ${(props) => `1px solid ${props.theme.borderColor}`};
  border-radius: 3px;
  width: 360px;
  right: -150px;
  margin-top: 10px;

  @media ${device.tablet} {
    width: 300px;
    right: -40%;
  }

  @media ${device.mobile} {
    right: ${(props) =>
      props.isSimulation ? 'calc(100% - 300px)' : 'calc(100% - 96px)'};
  }

  &::before {
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid ${(props) => props.theme.borderColor};
    right: 161px;
    top: -7px;
    position: absolute;
    transform: rotate(45deg);
    border-bottom-color: transparent;
    border-right-color: transparent;
    background-color: white;

    @media ${device.tablet} {
      right: 9%;
    }

    @media ${device.mobile} {
      right: ${(props) =>
        props.isSimulation ? 'calc(100% - 26px)' : 'calc(100% - 230px)'};
    }
  }
`;
