import { useCallback } from 'react';
import { batch } from 'react-redux';

import { ISearchProfileForm } from '../interface';
import {
  clearErrors,
  closeSPCAction,
  resetSPC,
  setErrors,
  toggleLoading,
  updateFormStateAction,
  updateTemporarySearchProfileToClaim,
} from '../redux/searchProfileSlice';
import { toggleLoading as toggleLoadingAuth } from '../../../auth/redux/authSlice';
import { parseSearchProfileInput } from '../../utils/parse-search-profile-input';
import { siteMap } from '../../../../routes/site-map';
import { FormError } from '../../interface';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import { useCreateSP } from './useCreateSP';
import {
  OrderSuggestedPropertiesBy,
  SuggestedPropertiesFilterInput,
  useLazySuggestedPropertiesQuery,
} from 'generated';
import { useCreateTemporarySP } from './useCreateTemporarySP';

const useSubmitFormSP = () => {
  const dispatch = useAppDispatch();
  const lngHPush = useLngHistoryPush();

  const selectedAddress = useAppSelector(
    (state) => state.searchProfile.selectedAddress
  );
  const { userInput, temporarySearchProfileToClaim } = useAppSelector(
    (state) => ({
      userInput: state.searchProfile.userInput,
      temporarySearchProfileToClaim:
        state.searchProfile.temporarySearchProfileToClaim,
    })
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const { onCreateSearchProfile } = useCreateSP();
  const { onCreateTemporarySP } = useCreateTemporarySP();
  const [triggerSuggestedPropertiesQuery, suggestedPropertiesResponse] =
    useLazySuggestedPropertiesQuery();

  const filters = useAppSelector(
    (state) => state.suggestedProperties.filters
  ) as SuggestedPropertiesFilterInput;

  const onSubmitSPForm = async (
    formData: ISearchProfileForm,
    ownerId?: string,
    shouldRedirect = true,
    onSuccessAuthed?: (args?: unknown[]) => void
  ) => {
    if (!(selectedAddress && formData)) return;

    batch(() => {
      dispatch(toggleLoading(true));
      dispatch(toggleLoadingAuth(true));
      dispatch(clearErrors());
      dispatch(updateFormStateAction(formData));
    });

    try {
      const parsedInput = parseSearchProfileInput(
        userInput as ISearchProfileForm,
        selectedAddress
      );

      if (!ownerId) {
        const createTemporarySearchProfileResponse = await onCreateTemporarySP({
          spInput: parsedInput,
          ownerId: '',
        });
        dispatch(
          updateTemporarySearchProfileToClaim(
            createTemporarySearchProfileResponse.createTemporarySearchProfile
          )
        );

        triggerSuggestedPropertiesQuery({
          filter: {
            offset: filters.offset ?? 0,
            limit: filters.limit ?? 8,
            orderBy: {
              field: OrderSuggestedPropertiesBy.Proximity,
              direction: 'asc',
              proximityParams: {
                latitude:
                  createTemporarySearchProfileResponse
                    .createTemporarySearchProfile?.searchProfileData.location
                    .coordinates.latitude ?? 0,
                longitude:
                  createTemporarySearchProfileResponse
                    .createTemporarySearchProfile?.searchProfileData.location
                    .coordinates.longitude ?? 0,
              },
            },
            unsuitablePropertiesIds: [],
            searchProfileData:
              createTemporarySearchProfileResponse.createTemporarySearchProfile
                ?.searchProfileData,
          },
          searchProfileId:
            createTemporarySearchProfileResponse.createTemporarySearchProfile
              ?._id,
        });

        return;
      }

      if (temporarySearchProfileToClaim) {
        // useConvertSP() hook picks up execution flow from here
        return;
      }

      const searchProfile = await onCreateSearchProfile({
        spInput: parsedInput,
        ownerId,
      });
      dispatch(resetSPC());
      if (onSuccessAuthed) onSuccessAuthed();

      if (isIframe) {
        window.open(
          `${window.location.origin}${siteMap.SearchProfilePage.pathWithoutParams}/${searchProfile.createSearchProfile?._id}`,
          '_blank',
          'noopener,noreferrer'
        );
      } else if (ownerId && searchProfile.createSearchProfile?._id) {
        lngHPush(
          `${siteMap.SearchProfilePage.pathWithoutParams}/${searchProfile.createSearchProfile?._id}`
        );
      }
    } catch (error: unknown) {
      batch(() => {
        dispatch(toggleLoading(false));
        dispatch(toggleLoadingAuth(false));
        dispatch(setErrors(error as FormError));
      });
    } finally {
      dispatch(toggleLoading(false));
      dispatch(toggleLoadingAuth(false));

      if (shouldRedirect) dispatch(closeSPCAction());
    }
  };

  return { onSubmitSPForm, suggestedPropertiesResponse };
};

export { useSubmitFormSP };
