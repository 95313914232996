import { FC } from 'react';

import {
  BuyerLpSection,
  BuyerLpSectionType,
  OverviewLpSection,
  OverviewLpSectionType,
  OwnerLpSection,
  SellerLpSection,
  SellerLpSectionType,
} from '../../../../../generated';
import {
  FunctionsContainer,
  FunctionsGrid,
  Header,
} from './functions-section-styles';
import {
  FunctionsCardsBuyer,
  FunctionsCardsSeller,
  FunctionsCardsOwner,
} from './function-card-item';
import { BgColor } from '../overview-section/overview-section-styles';

interface IProps {
  section:
    | OverviewLpSection
    | BuyerLpSection
    | OwnerLpSection
    | SellerLpSection;
  bgWhite?: boolean;
}

const FunctionsSection: FC<IProps> = ({ section, bgWhite }) => {
  const { recordIds, type, title } = section;
  return (
    <FunctionsContainer>
      {type === OverviewLpSectionType.BuyerFunction && !bgWhite && <BgColor />}
      <Header content={title} />
      <FunctionsGrid isSeller={type === OverviewLpSectionType.SellerFunction}>
        {type === OverviewLpSectionType.OwnerFunction && (
          <FunctionsCardsOwner recordIds={recordIds} />
        )}
        {(type === OverviewLpSectionType.BuyerFunction ||
          type === BuyerLpSectionType.BuyerFunction) && (
          <FunctionsCardsBuyer recordIds={recordIds} />
        )}
        {(type === OverviewLpSectionType.SellerFunction ||
          type === SellerLpSectionType.SellerFunction) && (
          <FunctionsCardsSeller recordIds={recordIds} />
        )}
      </FunctionsGrid>
    </FunctionsContainer>
  );
};

export { FunctionsSection };
