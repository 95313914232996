import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from '../../../../style/theme';
import { Headline2 } from '../../../common/components/ui/typography';

const Container = styled.div`
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #ffffff;
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  @media ${device.tablet} {
    padding: 16px;
    margin: unset;
    max-width: unset;
  }
`;

const TitleContainer = styled.div`
  padding: 32px 32px 0;
`;

const Title = styled(Headline2)`
  text-align: center;
  @media ${device.tablet} {
    max-width: unset;
    width: unset;
  }
`;

const ImageContainer = styled.div`
  display: flex;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

interface IProps {
  title: string;
  blurredImageSrc?: string;
}
const SmallBlurredCard = ({ title, blurredImageSrc }: IProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <TitleContainer>
        <Title content={t(title)} />
      </TitleContainer>
      <ImageContainer>
        <Image src={blurredImageSrc} />
      </ImageContainer>
    </Container>
  );
};

export { SmallBlurredCard };
