import { CSSProperties, ReactNode } from "react";
import styled, { css } from 'styled-components';
import { device } from '../../../../style/theme';

interface IModalFooter {
  className?: string;
  children: ReactNode;
  margin?: string;
  justifyContent?: string;
  backgroundColor?: string;
  style?: CSSProperties;
}

const Wrapper = styled.div<{ margin?: string; justifyContent?: string; backgroundColor?: string; }>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || `space-between`};
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`}
  @media ${device.tablet} {
    margin-bottom: 12px;
  }
`;

const ModalFooter = ({
  className,
  margin,
  children,
  justifyContent,
  backgroundColor,
  style
}: IModalFooter): JSX.Element => {
  return (
    <Wrapper
      className={className}
      margin={margin}
      style={style}
      justifyContent={justifyContent}
      backgroundColor={backgroundColor}
    >
      {children}
    </Wrapper>
  );
};

export { ModalFooter };
