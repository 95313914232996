import styled from 'styled-components';

export const CircleBackground = styled.circle`
  fill: none;
  stroke: rgba(0, 48, 94, 0.5);
`;

export const CircleProgress = styled.circle`
  fill: rgba(78, 115, 245, 0.05);
  stroke: ${(props) => props.theme.blue};
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const Percent = styled.text`
  font-family: Source Serif Pro, serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: center;
  fill: ${(props) => props.theme.blue};
`;
