import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { toggleAuthFlow } from '../redux/valuationWizardV2Slice';
import { useAppSelector } from '../../../common/hooks';
import { FormError } from '../../interface';
import { ValuationType } from '../../../../generated';
import { ITimifyParams } from '../../../timify/interfaces';
import { IIframeType } from '../../../auth/interfaces';

type TReturn = {
  activeStep: number;
  owner?: string;
  valuationWizardErrors: FormError;
  timifyParams: ITimifyParams | null;
  isAuthFlow: boolean;
  isLoading: boolean;
  isPersonal: boolean;
  isRegionalPage: boolean;
  isIframe?: boolean;
  isVWOpen?: boolean;
  isNoModal?: boolean;
  onSwitchToAuth: () => void;
};

const useUsefulValuationContainerData = (): TReturn => {
  const dispatch = useDispatch();

  const isVWOpen = useAppSelector((state) => state.valuationWizardV2.isVWOpen);
  const activeStep = useAppSelector(
    (state) => state.valuationWizardV2.activeStep
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const valuationType = useAppSelector(
    (state) => state.valuationWizardV2.valuationType
  );
  const owner = useAppSelector((state) => state.auth.user?._id);
  const valuationWizardErrors = useAppSelector(
    (state) => state.valuationWizardV2.errors
  );
  const isAuthFlow = useAppSelector(
    (state) => state.valuationWizardV2.isAuthFlow
  );
  const isLoading = useAppSelector(
    (state) => state.valuationWizardV2.isLoading
  );
  const timifyParams = useAppSelector(
    (state) => state.valuationWizardV2.timifyParams
  );
  const isRegionalPage = useAppSelector(
    (state) => state.valuationWizardV2.isRegionalPage
  );
  const iframeType = useAppSelector((state) => state.auth.iframeType);
  const isNoModal = iframeType === IIframeType.WIDGET_OWNER_NO_MODAL;

  const isPersonal = valuationType === ValuationType.Personal;

  const onSwitchToAuth = useCallback(() => {
    dispatch(toggleAuthFlow(true));
  }, [dispatch]);

  return {
    activeStep,
    owner,
    valuationWizardErrors,
    isAuthFlow,
    isLoading,
    onSwitchToAuth,
    isPersonal,
    timifyParams,
    isRegionalPage,
    isIframe,
    isVWOpen,
    isNoModal,
  };
};

export { useUsefulValuationContainerData };
