import { ReactNode, useEffect, useRef } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InformationCircle } from 'assets/streamline-light/interface-essential/alerts/information-circle.svg';

import { Tooltip } from '../../../../common/components/tooltip';
import {
  Container,
  TooltipContainer,
  ErrorContainer,
  TooltipParagraph,
  Info,
  InputWrapper,
  ErrorMessage,
  Spacer,
  Label,
  LabelRange,
  LabelWrapper,
  SubLabel,
} from './input-range-styles';
import { InputMinMax } from './input-min-max';
import { InputMode } from '../../../../common/interfaces';
import { useAppSelector } from '../../../../common/hooks';

interface IProps {
  nameMin?: string;
  nameMax?: string;
  label?: string;
  subLabel?: string;
  placeholderMin?: string;
  placeholderMax?: string;
  rules?: RegisterOptions;
  defaultValues?: {
    min?: number | string;
    max?: number | string;
  };
  type?: string;
  onFocus?: () => void;
  inputWrapperChildren?: ReactNode;
  onOutsideClick?: () => void;
  skipIcon?: boolean;
  tooltipKey?: string;
  isDisabled?: boolean;
  inputAdornment?: string;
  isSeparate?: boolean;
  inputMode?: InputMode;
}

const InputRange = ({
  label,
  subLabel,
  nameMin,
  nameMax,
  placeholderMin,
  placeholderMax,
  rules,
  defaultValues,
  type,
  onFocus,
  onOutsideClick,
  inputWrapperChildren,
  skipIcon = false,
  tooltipKey,
  isDisabled,
  inputAdornment,
  isSeparate,
  inputMode = 'text',
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        onOutsideClick &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onOutsideClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, onOutsideClick]);

  return (
    <Container
      hasError={(nameMin && errors[nameMin]) || (nameMax && errors[nameMax])}
      isIframe={isIframe}
    >
      <InputWrapper ref={wrapperRef} isIframe={isIframe}>
        {nameMin && (
          <InputMinMax
            name={nameMin}
            rules={rules}
            type={type}
            defaultValue={defaultValues?.min}
            isDisabled={isDisabled}
            inputAdornment={inputAdornment}
            placeholder={placeholderMin}
            onFocus={onFocus}
            isSeparate={isSeparate}
            inputMode={inputMode}
            isFullWidth={!nameMax}
            isPlaceholderCenter={false}
          />
        )}
        {nameMin && nameMax && <LabelRange>-</LabelRange>}
        {nameMax && (
          <InputMinMax
            name={nameMax}
            rules={rules}
            type={type}
            defaultValue={defaultValues?.max}
            isDisabled={isDisabled}
            inputAdornment={inputAdornment}
            placeholder={placeholderMax}
            onFocus={onFocus}
            isSeparate={isSeparate}
            inputMode={inputMode}
            isFullWidth={!nameMin}
            isPlaceholderCenter={false}
          />
        )}
        {!skipIcon && tooltipKey && (
          <Info>
            <Tooltip
              icon={InformationCircle}
              id={tooltipKey}
              position="bottom"
              width={16}
              height={16}
            >
              <TooltipContainer>
                <TooltipParagraph>{t(tooltipKey)}</TooltipParagraph>
              </TooltipContainer>
            </Tooltip>
          </Info>
        )}
        {inputWrapperChildren}
      </InputWrapper>
      <LabelWrapper>
        {label && <Label>{t(label)}</Label>}
        {subLabel && <SubLabel>{subLabel}</SubLabel>}
      </LabelWrapper>

      {((nameMin && errors[nameMin]) || (nameMax && errors[nameMax])) && (
        <ErrorContainer>
          <Spacer />
          <div>
            {nameMin && errors[nameMin] && (
              <ErrorMessage>{t(errors[nameMin]?.message)}</ErrorMessage>
            )}
            {nameMax && errors[nameMax] && (
              <ErrorMessage>{t(errors[nameMax]?.message)}</ErrorMessage>
            )}
          </div>
        </ErrorContainer>
      )}
    </Container>
  );
};

export { InputRange };
