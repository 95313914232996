import { FC } from 'react';
import {
  RpSection,
  Banner as BannerType,
  useReadBannerQuery,
} from '../../../../../generated';
import { MainButton } from '../../../../common/components/ui/buttons';
import {
  Container,
  ContentContainer,
  InfoContainer,
  ImageBox,
  InfoBox,
  Description,
  BoxButtons,
  Headline,
  HeaderBox,
  StyledImg,
} from './banner-styles';

interface IProps {
  section: RpSection;
  ctaHandler?: () => void;
}

interface BannerProps {
  id: string;
  title: string;
  ctaHandler?: () => void;
}

interface BannerResponse {
  data: { data: BannerType };
  isLoading: boolean;
}

const Banner = ({ id, title, ctaHandler }: BannerProps) => {
  const { data, isLoading } = useReadBannerQuery<BannerResponse>({
    id,
  });

  if (isLoading) return null;

  const banner = data?.data;

  return (
    <Container>
      <ContentContainer>
        <HeaderBox>
          <Headline content={title} />
        </HeaderBox>
        <InfoContainer>
          <InfoBox>
            <Description
              data-swiper-parallax="0"
              dangerouslySetInnerHTML={{ __html: banner?.text ?? '' }}
            />
            <BoxButtons>
              <MainButton
                data-swiper-parallax="0"
                label={banner?.buttonLabel as string}
                onClick={ctaHandler}
              />
            </BoxButtons>
          </InfoBox>
        </InfoContainer>
      </ContentContainer>
      <ImageBox>
        <StyledImg
          key={banner?.imageKey as string}
          src={banner?.imageUrl as string}
          alt={banner?.imageAlt as string}
        />
      </ImageBox>
    </Container>
  );
};

const Banners: FC<IProps> = ({ ctaHandler, section }) => {
  const { recordIds, title } = section;

  return (
    <div>
      {recordIds.map((id: string) => {
        return (
          <Banner id={id} key={id} title={title} ctaHandler={ctaHandler} />
        );
      })}
    </div>
  );
};

export { Banners };
