import styled from 'styled-components';
import {
  Headline1,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { device } from '../../../../style/theme';
import {
  MainButton,
  SecondaryButton,
} from '../../../common/components/ui/buttons';

export const Header = styled(Headline1)<{ color?: string }>`
  position: relative;
  color: ${(props) => (props.color ? props.color : props.theme.blue)};
  margin-bottom: 24px;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
    margin-bottom: 12px;
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const Subtitle = styled(ParagraphText)`
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 16px 0 24px 0;
`;

export const LoginButton = styled(SecondaryButton)`
  justify-content: center;
`;

export const RegisterButton = styled(MainButton)`
  justify-content: center;
  margin-top: 12px;
`;
