import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddCircle } from 'assets/streamline-light/interface-essential/remove-add/add-circle.svg';
import { ReactComponent as TeamChat } from 'assets/streamline-light/work-office-companies/meetings-collaboration/team-chat.svg';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  useGetProvenExpertRatingQuery,
  ValuationType,
} from '../../../../../../../generated';

import { PrimaryCTAButton } from '../../../../../../common/components/ui/buttons';
import { numberFormat } from '../../../../../../financing/utils/numberFormat';
import { useOpenEvaluationWizard } from '../../../../hooks/useOpenEvaluationWizard';
import { useOpenEvaluationWizardV2 } from '../../../../../form-property-valuation-wizard-v2/hooks/callback-hooks/useOpenEvaluationWizardV2';
import { useGetEvaluationWidgetVersion } from '../../../../../form-property-valuation-wizard-v2/hooks/useGetWidgetVersion';
import { useGetPortalType } from '../../../../../../landing/hooks/useGetPortalType';
import { PageType } from '../../../../interface';
import { useIsMobileSize } from '../../../../../../common/hooks/useIsMobileSize';
import { useAppSelector } from '../../../../../../common/hooks';
import { setSelectedTab } from '../../../../../../property/redux/dynamicMapUtilsSlice';
import { siteMap } from '../../../../../../../routes/site-map';

interface IProps {
  pageType: PageType;
  skipBorderRadius?: boolean;
}

const CTAButtons: FC<IProps> = ({ skipBorderRadius, pageType }) => {
  const { t } = useTranslation();
  const { isSeller } = useGetPortalType();
  const isMobileSize = useIsMobileSize();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { propertyId } = useParams<{ propertyId?: string }>();

  const isLandingPage = pageType === 'landing-page';
  const { isRedesignVersion } = useGetEvaluationWidgetVersion();

  const { openEvaluationWizard } = useOpenEvaluationWizard({ isLandingPage });
  const { openEvaluationWizardV2 } = useOpenEvaluationWizardV2({
    isLandingPage,
  });

  const { data } = useGetProvenExpertRatingQuery();

  const provenExpertRating = data?.getProvenExpertRating;

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const hideSellerButton = useMemo(() => {
    return isSeller || pageType === 'property-portfolio';
  }, [isSeller, pageType]);

  const {
    titleOV,
    idOV,
    descriptionOV,
    disclaimerOV,
    idPV,
    titlePV,
    descriptionPV,
    disclaimerPV,
  } = useMemo(() => {
    switch (pageType) {
      case 'property':
      case 'property-portfolio':
        return {
          idOV: '',
          titleOV: 'property-page.primary-button.online-valuation.label',
          descriptionOV:
            'property-page.primary-button.online-valuation.description',
          idPV: '',
          titlePV: 'landing-page.primary-button.personal-valuation.label',
          descriptionPV:
            'landing-page.primary-button.personal-valuation.description',
          disclaimerPV: '',
        };
      case 'seller-landing-page':
        return {
          idOV: '',
          titleOV:
            'seller-landing-page.primary-button.property-valuation.label',
          descriptionOV:
            'seller-landing-page.primary-button.property-valuation.description',
          disclaimerOV:
            'seller-landing-page.primary-button.property-valuation.disclaimer',
          idPV: '',
          titlePV:
            'seller-landing-page.primary-button.property-valuation.label',
          descriptionPV:
            'seller-landing-page.primary-button.property-valuation.description',
          disclaimerPV: '',
        };
      case 'seller-properties':
        return {
          idOV: '',
          titleOV:
            'seller-landing-page.primary-button.property-valuation.label',
          descriptionOV:
            'seller-landing-page.primary-button.property-valuation.description',
          disclaimerOV:
            'seller-landing-page.primary-button.property-valuation.disclaimer',
          idPV: '',
          titlePV:
            'seller-landing-page.primary-button.property-valuation.label',
          descriptionPV:
            'seller-landing-page.primary-button.property-valuation.description',
          disclaimerPV: '',
        };
      case 'landing-page':
      default:
        return {
          idOV: 'online-real-estate-valuation',
          titleOV: 'landing-page.primary-button.online-valuation.label',
          descriptionOV:
            'landing-page.primary-button.online-valuation.description',
          idPV: 'personal-property-evaluation',
          titlePV: 'landing-page.primary-button.personal-valuation.label',
          descriptionPV:
            'landing-page.primary-button.personal-valuation.description',
          disclaimerPV:
            'landing-page.primary-button.personal-valuation.disclaimer',
        };
    }
  }, [pageType]);

  return (
    <>
      <PrimaryCTAButton
        id={idOV}
        isFirst={
          hideSellerButton ? (isLandingPage ? true : !isMobileSize) : true
        }
        isLast={hideSellerButton}
        icon={AddCircle}
        title={t(titleOV)}
        description={t(descriptionOV)}
        onClick={() =>
          isRedesignVersion
            ? openEvaluationWizardV2(ValuationType.Online)
            : openEvaluationWizard(ValuationType.Online)
        }
        disclaimer={
          disclaimerOV &&
          t(disclaimerOV, {
            reviewsCount: numberFormat(provenExpertRating?.reviewCount || 0),
            totalRate: (provenExpertRating?.ratingValue || 0)
              .toFixed(2)
              .replace('.', ','),
          })
        }
        skipBorder={isSeller}
        rating={isSeller ? 5 : undefined}
        isDisabled={isBrokerView}
      />
      {!hideSellerButton && (
        <PrimaryCTAButton
          id={idPV}
          isLast
          skipBorder
          skipBorderRadius={skipBorderRadius}
          icon={TeamChat}
          title={t(titlePV)}
          description={t(descriptionPV)}
          onClick={
            () =>
              window.open(
                `${siteMap.SellingProcessPage.pathWithoutParams}/${propertyId}`,
                '_blank'
              )
            // isRedesignVersion
            //   ? openEvaluationWizardV2(ValuationType.Personal)
            //   : openEvaluationWizard(ValuationType.Personal)
          }
          disclaimer={t(disclaimerPV)}
        />
      )}
    </>
  );
};

export { CTAButtons };
