import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { ReactComponent as AdviceIcon } from 'assets/images/icons/financing-landing-page/advice-icon.svg';

import { Container } from './advice-container-styles';
import Icon from '../../../../../../../common/components/ui/icon';

interface IProps {
  text: string;
}

const AdviceContainer: FC<IProps> = ({ text }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <Container>
      <Icon
        icon={AdviceIcon}
        width={31}
        height={32}
        color={themeContext.blue}
      />
      <p>{text}</p>
    </Container>
  );
};

export { AdviceContainer };
