import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const KitchenIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'20px'} height={'20px'}>
      <Path
        d={
          'M.5 12.5h10m3-3h10m-7 0 1-2m2 2 1-2m-16 5h-1a1 1 0 0 1-1-1v-2h3v2a1 1 0 0 1-1 1zm4-12v4m7 0v-4m-14 0a1 1 0 0 0-1 1v3h23v-3a1 1 0 0 0-1-1zm14 23v-6m-7 0v6m14 0a1 1 0 0 0 1-1v-5H.5v5a1 1 0 0 0 1 1zm-21-8h21a1 1 0 0 1 1 1v1h0-23 0v-1a1 1 0 0 1 1-1z'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { KitchenIcon };
