import { Feature } from '../../../../services/mapbox/interfaces';
import { parseLocation } from '../../utils/parse-location';

const parseFinancingLocation = (location?: Feature) => {
  let mapboxLocality = '';
  let mapboxNeighborhood = '';
  let mapboxPostCode = '';
  let mapboxCity = '';
  let mapboxCountry = '';
  let mapboxRegion = '';
  let mapboxPlaceName = '';
  let longitude = 0;
  let latitude = 0;
  if (location) {
    const parsedLocation = parseLocation(location);
    mapboxLocality = parsedLocation.mapboxLocality;
    mapboxNeighborhood = parsedLocation.mapboxNeighborhood;
    mapboxPostCode = parsedLocation.mapboxPostCode;
    mapboxCity = parsedLocation.mapboxCity;
    mapboxRegion = parsedLocation.mapboxRegion;
    mapboxCountry = parsedLocation.mapboxCountry;
    mapboxPlaceName = parsedLocation.mapboxPlaceName;
    longitude = parsedLocation.longitude;
    latitude = parsedLocation.latitude;
  }
  return {
    mapboxLocality,
    mapboxNeighborhood,
    mapboxPostCode,
    mapboxCity,
    mapboxRegion,
    mapboxCountry,
    mapboxPlaceName,
    longitude,
    latitude,
  };
};

export { parseFinancingLocation };
