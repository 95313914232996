import { roundToHundreds } from './roundToHundreds';

type Action = 'increase' | 'decrease';

export const adjustPriceByPercent = (
  price: number,
  percent: number,
  action: Action
): number => {
  switch (action) {
    case 'increase':
      return roundToHundreds(Math.round(price + (price * percent) / 100));
    case 'decrease':
      return roundToHundreds(Math.round(price - (price * percent) / 100));
    default:
      return roundToHundreds(Math.round(price + (price * percent) / 100));
  }
};
