export const tabs = [
  'overview',
  'local_supply',
  'social_economics',
  'reachability',
  'documents',
];

export const energyEfficiency = {
  colorMarkers: [
    { label: 'A', width: 12 },
    { label: 'A', width: 10 },
    { label: 'B', width: 11 },
    { label: 'C', width: 11 },
    { label: 'D', width: 12 },
    { label: 'E', width: 12 },
    { label: 'F', width: 9 },
    { label: 'G', width: 12 },
    { label: 'H', width: 11, isLast: true },
  ],
  textMarkers: [
    { label: '0', width: 8 },
    { label: '25', width: 7 },
    { label: '50', width: 6 },
    { label: '75', width: 6 },
    { label: '100', width: 6 },
    { label: '125' },
    { label: '150' },
    { label: '175' },
    { label: '200' },
    { label: '225' },
    { label: '250' },
    { label: '>250' },
  ],
};

export const markLetters = [
  {
    letter: 'A',
    min: 0,
    max: 50,
  },
  {
    letter: 'B',
    min: 50,
    max: 75,
  },
  {
    letter: 'C',
    min: 75,
    max: 100,
  },
  {
    letter: 'D',
    min: 100,
    max: 130,
  },
  {
    letter: 'E',
    min: 130,
    max: 160,
  },
  {
    letter: 'F',
    min: 160,
    max: 200,
  },
  {
    letter: 'G',
    min: 200,
    max: 250,
  },
  {
    letter: 'H',
    min: 250,
    max: null,
  },
];

export const markColors = [
  { color: '#45a34d', min: 0, max: 16 },
  { color: '#fffa3e', min: 16, max: 32 },
  { color: '#eb6f23', min: 32, max: 50 },
  { color: '#cf381a', min: 50, max: 100 },
];

export const options = [
  {
    label:
      'expose-page.head-info.button.make-an-appointment.head-consultation-appointment',
    value: 'Beratungstermin',
    isHeader: true,
  },
  {
    label:
      'expose-page.head-info.button.make-an-appointment.option-in-the-branch',
    value: 'in der Filiale',
    isHeader: false,
  },
  {
    label:
      'expose-page.head-info.button.make-an-appointment.option-via-video-conference',
    value: 'über Videokonferenz',
    isHeader: false,
  },
  {
    label: 'expose-page.head-info.button.make-an-appointment.option-via-phone',
    value: 'über Telefon',
    isHeader: false,
  },
  {
    label:
      'expose-page.head-info.button.make-an-appointment.head-viewing-appointment',
    value: 'Besichtigungstermin',
    isHeader: true,
  },
  {
    label:
      'expose-page.head-info.button.make-an-appointment.option-personally-on-site',
    value: 'Persönlich vor Ort',
    isHeader: false,
  },
  {
    label:
      'expose-page.head-info.button.make-an-appointment.option-with-inspection-robot',
    value: 'Mit Besichtigungsroboter',
    isHeader: false,
  },
  {
    label:
      'expose-page.head-info.button.make-an-appointment.option-with-viewing-glasses',
    value: 'Mit Besichtungsbrille',
    isHeader: false,
  },
];
