import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const Text = styled.div`
  --label-color: rgb(0, 48, 94);
  font-family: Roboto, sans-serif !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${(props) => 'var(--label-color)'};

  a {
    color: var(--label-color);
  }
`;
