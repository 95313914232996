import styled from 'styled-components';
import { Headline3 } from '../../../../../common/components/ui/typography';

export const Title = styled(Headline3)`
  margin: 0 auto 12px auto;
  text-align: center;
`;

export const Form = styled.form<{ isiframe?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: ${({ isiframe }) => (isiframe ? 16 : 0)}px;
`;
