import { useCallback } from 'react';
import { isNumber } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  setDirtyEditForm,
  setRenderIndex,
} from '../../../redux/financingSlice';
import { useAppSelector } from '../../../../../common/hooks';

interface IProps {
  state: boolean;
  value?: number | string;
  cb?: (value: number) => void;
}

const useDirtyForm = () => {
  const isDirty = useAppSelector(
    (state) => state.financing.editWidget.isDirtyForm
  );
  const renderIndex = useAppSelector(
    (state) => state.financing.editWidget.renderIndex
  );
  const dispatch = useDispatch();
  // force rerender to check isEqual form values
  // const [renderIndex, setRenderIndex] = useState(0);

  const handleChangeDirty = useCallback(
    ({ state, value, cb }: IProps) => {
      dispatch(setDirtyEditForm(state));
      if (cb && isNumber(value)) {
        cb(value);
      }
    },
    [dispatch]
  );

  const setDirtyTrue = useCallback(() => {
    const newIndex = renderIndex + 1;
    dispatch(setRenderIndex(newIndex));
    handleChangeDirty({
      state: true,
    });
  }, [handleChangeDirty, renderIndex]);

  return { isDirty, handleChangeDirty, setDirtyTrue };
};

export { useDirtyForm };
