import { ReactNode } from 'react';
import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { useAppSelector } from '../../../hooks';

interface IFormGridWrapperFixHeight {
  className?: string;
  maxHeight?: string;
  children: ReactNode;
  wrapperRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
    | undefined;
}

const Wrapper = styled.div<{ maxHeight?: string; isiframe?: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;
  max-height: ${({ maxHeight, isiframe }) =>
    isiframe ? 'auto' : maxHeight || '436px'};
  padding: ${({ isiframe }) => (isiframe ? '0' : '0 0 24px 0')};
  overflow: ${({ isiframe }) => (isiframe ? 'auto' : 'hidden')};
  overflow-y: auto;

  &:hover {
    overflow-y: auto;
  }

  @media ${({ isiframe }) => (isiframe ? '0' : device.tablet)} {
    max-height: 200%;
  }
`;

const FormGridWrapperFixHeight = ({
  wrapperRef,
  className,
  maxHeight,
  children,
}: IFormGridWrapperFixHeight): JSX.Element => {
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  return (
    <Wrapper
      className={className}
      maxHeight={maxHeight}
      ref={wrapperRef}
      isiframe={isIframe}
    >
      {children}
    </Wrapper>
  );
};

export { FormGridWrapperFixHeight };
