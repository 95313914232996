import styled from 'styled-components';
import {
  Headline2,
  Headline3,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { device } from '../../../../style/theme';
import { MainButton } from '../../../common/components/ui/buttons';

export const Container = styled.div`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  margin-bottom: 16px;
  background-color: ${(props) => props.theme.white};

  @media ${device.tablet} {
    padding: 16px;
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Title = styled(Headline2)``;

export const Content = styled.div<{ isBlurred?: boolean }>`
  ${(props) => props.isBlurred && 'filter: blur(6px)'};
`;

export const AlertContainer = styled.div`
  width: 100%;
  color: ${(props) => props.theme.red};
  background-color: #f0e9e9;
  border: solid 1px ${(props) => props.theme.lightRed};
  border-radius: 3px;
  padding: 16px;
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
  max-height: 320px;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    margin: 0 !important;
  }
`;

export const AlertContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const AlertTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AlertTitle = styled(Headline3)`
  @media ${device.mobile} {
    font-size: 16px;
  }
`;

export const AlertText = styled(ParagraphText)`
  @media ${device.mobile} {
    font-size: 12px;
  }
`;

export const AlertActions = styled.div`
  display: flex;
  gap: 12px;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 8px;
  }
`;

export const ActionButton = styled(MainButton)`
  height: 40px;
  justify-content: center;

  @media ${device.mobile} {
    font-size: 11px;
  }
`;

export const AlertClose = styled.a`
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  height: 32px;
`;

export const Info = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: 8px;

  .tooltip {
    background-color: ${(props) => props.theme.white};
    opacity: 1 !important;
    padding: 8px;
  }

  .icon {
    > svg,
    > svg > path {
      stroke: ${(props) => props.theme.blue};
    }
  }
`;

export const TooltipContainer = styled.div`
  max-width: 316px;
  width: 100%;
  padding: 0;
  text-align: center;
`;

export const TooltipParagraph = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;
