import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const StarIcon = ({ filled }: { filled?: boolean }) => {
  return (
    <Svg
      viewBox={'0 0 24 24'}
      style={{ maxHeight: '12mm', marginLeft: '1mm', marginRight: '1mm' }}
      width={'12mm'}
      height={'12mm'}
    >
      <Path
        d={
          'M11.533 1.571a.5.5 0 0 1 .936 0L15 8.747h7.148a.5.5 0 0 1 .325.88L16.5 14.579l2.5 7.51a.5.5 0 0 1-.77.561L12 18.079 5.767 22.65A.5.5 0 0 1 5 22.089l2.5-7.51-5.974-4.952a.5.5 0 0 1 .325-.88H9z'
        }
        fill={filled ? defaultTheme.blue : 'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { StarIcon };
