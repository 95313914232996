import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';

import { useContext } from 'react';
import { device } from '../../../../../style/theme';
import {
  Headline3,
  ParagraphTitle,
} from '../../../../common/components/ui/typography';
import { useCurrency } from '../../../hooks/useCurrency';
import Icon from '../../../../common/components/ui/icon';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';

const PriceContainer = styled.div<{
  backgroundColor?: string;
  borderColor?: string;
}>`
  display: flex;
  flex-direction: column;
  padding: 11.5px 12px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.borderColor || props.theme.lightBlue};
  overflow: hidden;
  //flex: 0 1 170px;
  width: 100%;
  align-items: center;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.greyBackground};
  @media ${device.tablet} {
    padding: 20px;
    margin: 4px 0;
  }
  @media ${device.mobile} {
    flex: 1;
    padding: 16px;
  }
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

const Price = styled(Headline3)`
  font-weight: 600;
  line-height: 1.2;
  @media ${device.mobile} {
    font-size: 18px;
  }
`;

const AdditionalInfo = styled(ParagraphTitle)`
  font-size: 12px;
  opacity: 0.8;
  line-height: 1.67;
  font-weight: bold;
  letter-spacing: normal;
`;

interface ISalePriceElementProps {
  salePrice?: string;
  priceLabel: string;
  backgroundColor?: string;
  borderColor?: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
}

const OnOfficeSalePriceElement = ({
  salePrice,
  priceLabel,
  backgroundColor,
  borderColor,
  icon,
}: ISalePriceElementProps): JSX.Element => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const priceCurrency = useCurrency();
  const isMobileSize = useIsMobileSize();

  return (
    <PriceContainer backgroundColor={backgroundColor} borderColor={borderColor}>
      <Row>
        <Icon
          icon={icon}
          width={isMobileSize ? 24 : 32}
          height={isMobileSize ? 24 : 32}
          color={themeContext.blue}
          style={{
            alignSelf: 'center',
            marginRight: '12px',
            minWidth: isMobileSize ? '24px' : '32px',
          }}
        />
        {salePrice && <Price content={`${salePrice}${priceCurrency}`} />}
      </Row>
      <Row>
        <AdditionalInfo content={t(priceLabel ?? '')} />
      </Row>
    </PriceContainer>
  );
};

export { OnOfficeSalePriceElement };
