import { ReactNode } from 'react'
import styled from "styled-components";

const ErrorMessage = styled.p`
  margin: -8px 0 0;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${props => props.theme.red};
`

interface IProps {
  children: ReactNode
}

export const StyleGuideError = ({ children }: IProps) => {
  return <ErrorMessage>{children}</ErrorMessage>
}
