import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const ContentWithPadding = styled.div`
  padding: 0 48px 0 48px;
  @media ${device.tablet} {
    padding: 0;
  }
`;

export const CTAContainer = styled.div<{ iniframe: boolean }>`
  position: ${({ iniframe }) => (iniframe ? 'static' : 'absolute')};
  bottom: -24px;
  right: 48px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media ${device.tablet} {
    position: static;
    padding: 0 16px;
  }
`;

export const IFrameContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.white};
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  cursor: pointer;
  width: 12px;
  height: 12px;
`;

export const MobileMapContainer = styled.div`
  position: relative;
  height: 160px;
  overflow: hidden;
`;

export const MobileHeadContainer = styled.div`
  position: relative;
`;

export const LoaderContainer = styled.div<{ isLoadingMore?: boolean }>`
  width: 100%;
  height: ${({ isLoadingMore }) => (isLoadingMore ? 'auto' : '200px')};
  position: relative;
  padding: ${({ isLoadingMore }) => (isLoadingMore ? '36px' : '56px')};
  ${({ isLoadingMore }) => isLoadingMore && 'margin-top: 24px;'};
  ${({ isLoadingMore, theme }) =>
    !isLoadingMore &&
    `border-radius: 3px;border: solid 1px ${theme.ctaDisabledBackground};`};

  @media ${device.tablet} {
    padding: 16px;
  }
`;
