import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { device } from '../../../../style/theme';
import { useAppSelector } from '../../../common/hooks';

const Container = styled.div<{
  isHighlight?: boolean;
  activeStep?: number;
  mobileCompact?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 464px;
  min-height: 616px;
  position: absolute;
  top: 48px;
  right: 48px;
  padding: 24px;
  border-radius: 3px;
  box-shadow: ${({ isHighlight }) =>
    isHighlight
      ? '0 8px 30px 0 rgba(78, 115, 245, 0.5)'
      : '0 8px 30px 0 rgba(0, 0, 0, 0.09)'};
  background-color: #ffffff;
  z-index: 3;
  transition: 1s;
  border: ${({ isHighlight }) =>
    isHighlight ? '2px solid #4e73f5' : '2px solid #ffffff'};
  @media ${device.tablet} {
    ${({ activeStep, mobileCompact }) =>
      (mobileCompact ? activeStep && activeStep > 0 : true)
        ? `
        width: 100%;
        height: 100%;
        min-height: 100%;
        top: 0 !important;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        overflow: overlay;
        z-index: 2010;
      `
        : `
        position: static;
        margin: 0 auto;
        max-width: 464px;
        width: 100%;
      `}
  }
`;

interface IFormContainer {
  hasCleanInterface?: boolean;
  children: ReactNode;
  activeStep?: number;
  mobileCompact?: boolean;
  styles?: CSSProperties;
}

const FormContainer = ({
  hasCleanInterface,
  children,
  activeStep,
  mobileCompact,
  styles,
}: IFormContainer): JSX.Element => {
  const isHighlight = useAppSelector(
    (state) => state.valuationWizard.isHighlight
  );
  if (hasCleanInterface) return <>{children}</>;
  return (
    <Container
      activeStep={activeStep}
      mobileCompact={mobileCompact}
      isHighlight={isHighlight}
      style={styles}
    >
      {children}
    </Container>
  );
};

export { FormContainer };
