import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 3px;
  border: 1px solid rgba(223, 200, 200, 1);
  opacity: 1;
  background-color: rgba(240, 233, 233, 1);

  p {
    opacity: 1;
    color: ${({ theme }) => theme.blue};
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.43px;
    text-align: left;
    line-height: 16px;
  }

  i {
    min-width: 31px;
  }
`;
