import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { isEmpty, isNil } from 'lodash';

import { ReactComponent as Building } from 'assets/streamline-light/building-construction/buildings/buildings-2.svg';
import { ReactComponent as House } from 'assets/streamline-light/building-construction/houses/house-3.svg';
import { ReactComponent as MultiFamilyHouse } from 'assets/streamline-light/building-construction/houses/house-apartment.svg';
import { ReactComponent as Plot } from 'assets/streamline-light/nature-farming/outdoors/outdoors-tree-gate.svg';
import { ReactComponent as Rectangle } from 'assets/svg/rectangle.svg';

import { ReactComponent as Money } from 'assets/streamline-light/money-payments-finance/money/accounting-bills-1.svg';
import { ReactComponent as Area } from 'assets/svg/area.svg';

import { ReactComponent as Bathroom } from 'assets/streamline-light/hotels-spas/bathroom/bathroom-tub-towel.svg';
import { ReactComponent as RealEstateDimensionsPlan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-plan.svg';
import { numberFormat } from '../../../../../financing/utils/numberFormat';

import SearchProfileIcon from '../../../../../property/components/icon/search-profile-icon';
import { InfoContainer, IconRow } from './property-details-styles';
import { extractPropertyTypeLabelAndIcon } from '../../../../../property/utils/property-type-normalizer';
import { useAppSelector } from '../../../../../common/hooks';
import { PropertyCode } from '../../../../../../generated';

const PropertyDetails: FC = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const userInput = useAppSelector((state) => state.searchProfile.userInput);
  const values = watch();

  const formData = isEmpty(values) ? userInput : values;

  const {
    code,
    propertyType,
    purchasePriceMax,
    livingAreaMin,
    landAreaMin,
    numberOfBathroomsMin,
    numberOfRoomsMin,
  } = formData || {};

  const { label } = extractPropertyTypeLabelAndIcon(
    code || propertyType?.code || userInput?.code
  );

  const propertyTypeIcon = useMemo(() => {
    if (code === PropertyCode.Apartment) {
      return Building;
    }
    if (code === PropertyCode.House) {
      return House;
    }
    if (code === PropertyCode.MultiFamilyHouse) {
      return MultiFamilyHouse;
    }
    if (code === PropertyCode.Plot) {
      return Plot;
    }
    return Rectangle;
  }, [code]);

  return (
    <InfoContainer>
      <IconRow>
        <SearchProfileIcon
          icon={propertyTypeIcon}
          value={t(label).toString()}
          skeletonWidth={32}
          type={t('search-profile-overview.type')}
        />
        <SearchProfileIcon
          icon={Money}
          value={
            !isNil(purchasePriceMax) && !Number.isNaN(purchasePriceMax)
              ? numberFormat(purchasePriceMax)
              : ''
          }
          skeletonWidth={30}
          type={t('search-profile.form.second-step.purchase-price')}
        />
        <SearchProfileIcon
          icon={Area}
          value={
            code === PropertyCode.Plot
              ? !isNil(landAreaMin) && !Number.isNaN(landAreaMin)
                ? landAreaMin
                : ''
              : !isNil(livingAreaMin) && !Number.isNaN(livingAreaMin)
              ? livingAreaMin
              : ''
          }
          skeletonWidth={45}
          type={t(
            `search-profile.form.second-step.${
              code === PropertyCode.Plot ? 'land-area' : 'living-space'
            }`
          )}
        />
        {code !== PropertyCode.MultiFamilyHouse && code !== PropertyCode.Plot && (
          <>
            <SearchProfileIcon
              icon={RealEstateDimensionsPlan}
              value={
                !isNil(numberOfRoomsMin) && !Number.isNaN(numberOfRoomsMin)
                  ? numberOfRoomsMin
                  : ''
              }
              skeletonWidth={10}
              type={t('search-profile.form.second-step.room')}
            />
            <SearchProfileIcon
              icon={Bathroom}
              value={
                !isNil(numberOfBathroomsMin) &&
                !Number.isNaN(numberOfBathroomsMin)
                  ? numberOfBathroomsMin
                  : ''
              }
              skeletonWidth={10}
              type={t('search-profile.form.second-step.bathroom')}
            />
          </>
        )}
      </IconRow>
    </InfoContainer>
  );
};

export { PropertyDetails };
