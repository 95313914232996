import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SuggestedProperty,
  SuggestedPropertiesFilterInput,
  OrderSuggestedPropertiesBy,
  Direction,
} from '../../../generated';

interface InitialState {
  filters: SuggestedPropertiesFilterInput | unknown;
  suggestedProperties: SuggestedProperty[];
  totalItems: number;
  initialLoader: boolean;
}

export const filtersInitialState: SuggestedPropertiesFilterInput = {
  offset: 0,
  limit: 8,
  orderBy: {
    field: OrderSuggestedPropertiesBy.Proximity,
    direction: Direction.Asc,
    proximityParams: { latitude: 0, longitude: 0 },
  },
};

const initialState: InitialState = {
  filters: {},
  suggestedProperties: [],
  totalItems: 0,
  initialLoader: true,
};

export const suggestedPropertiesSlice = createSlice({
  name: 'suggestedProperties',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<SuggestedPropertiesFilterInput>) {
      state.filters = action.payload;
    },
    mergeFilters(
      state,
      action: PayloadAction<Partial<SuggestedPropertiesFilterInput>>
    ) {
      state.filters = {
        ...(state.filters as SuggestedPropertiesFilterInput),
        ...action.payload,
      };
    },
    clearSuggestedPropertiesCache(state) {
      state.suggestedProperties = [];
      state.totalItems = 0;
    },
    addSuggestedProperties(state, action: PayloadAction<SuggestedProperty[]>) {
      state.suggestedProperties = [
        ...state.suggestedProperties,
        ...action.payload,
      ];
    },
    setSuggestedProperties(state, action: PayloadAction<SuggestedProperty[]>) {
      state.suggestedProperties = action.payload;
    },
    setTotalItems(state, action: PayloadAction<number>) {
      state.totalItems = action.payload;
    },
    setInitialLoader(state, action: PayloadAction<boolean>) {
      state.initialLoader = action.payload;
    },
    removeFromSuggestedProperties(state, action: PayloadAction<string>) {
      state.suggestedProperties = state.suggestedProperties.filter(
        (property) => property.externalId !== action.payload
      );
      state.totalItems -= 1;
    },
  },
});

export const {
  setFilters,
  mergeFilters,
  clearSuggestedPropertiesCache,
  addSuggestedProperties,
  setSuggestedProperties,
  setTotalItems,
  setInitialLoader,
  removeFromSuggestedProperties,
} = suggestedPropertiesSlice.actions;

export default suggestedPropertiesSlice.reducer;
