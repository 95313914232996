import { FC, memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import BackgroundImg from 'assets/expose-request-page.png';

import { IFinancingForm } from '../../../../interface';
import { ModalContainer } from '../../../../../components/fullscreen-modal/modal-container/modal-container';
import { FinancingFormContainer } from '../financing-form-container/financing-form-container';
import { FinancingInfoContainer } from '../financing-info-container/financing-info-container';

import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../../common/hooks/useIsMobileSize';
import { useNavigationButtonsHandler } from '../../../../hooks/callback-hooks/useNavigationButtonsHandler';
import { InfoContainer } from './financing-modal-styles';
import { useFinancingFormInfo } from '../../../../hooks/useFinancingFormInfo';
import { FinancingInfoCertificateContainer } from '../financing-info-certificate-container/financing-info-certificate-container';
import { useGetFinancingVonPollPropertyDetails } from '../../../../../../financing/hooks/useGetFinancingVonPollPropertyDetails';
import { FinancingPropertyType } from '../../../../../../../generated';
import { setVonPollPropertyData } from '../../../../redux/financingSlice';
import { useCheckAndCreateFinancingFromSuggestedProperties } from '../../../../../../financing/hooks/useCheckAndCreateFinancingFromSuggestedProperties';

const FinancingModalBase: FC = () => {
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();
  const formMethods = useForm<IFinancingForm>();

  // search params
  const { data: vonPollPropertyData, id } =
    useGetFinancingVonPollPropertyDetails();
  // suggested properties purchase price logic
  useCheckAndCreateFinancingFromSuggestedProperties({ formMethods });

  useEffect(() => {
    if (vonPollPropertyData && id) {
      const { setValue } = formMethods;
      setValue(
        'purchasePrice',
        Math.round(vonPollPropertyData.purchasePrice || 0)
      );
      setValue(
        'buildingYear',
        Number(vonPollPropertyData.buildingYear) || undefined
      );
      setValue(
        'livingArea',
        Math.round(Number(vonPollPropertyData.livingArea)) || undefined
      );
      switch (vonPollPropertyData.propertyType) {
        case 'haus':
          setValue('propertyType', FinancingPropertyType.HouseDetached);
          break;
        case 'wohnung':
          setValue('propertyType', FinancingPropertyType.Condominium);
          break;
        case 'zinshaus_renditeobjekt':
          setValue('propertyType', FinancingPropertyType.MultiFamilyHouse);
          setValue(
            'rentalIncomePerMonth',
            Math.round(
              Number(vonPollPropertyData.equipment?.rentalIncomeIs) || 0
            ) ||
              Math.round(
                Number(vonPollPropertyData.equipment?.rentalIncomeShould) || 0
              )
          );
          break;
        case 'grundstueck':
          setValue('propertyType', FinancingPropertyType.PlotOnly);
          break;
        default:
          setValue('propertyType', FinancingPropertyType.Condominium);
      }
      dispatch(
        setVonPollPropertyData({ ...vonPollPropertyData, propertyId: id })
      );
    }
  }, [vonPollPropertyData?.purchasePrice]);

  const {
    isFinancingOpen,
    isAuthFlow,
    isRegisterFormOpen,
    activeStep,
    isLoginFormOpen,
    isConfirmEmailSent,
  } = useAppSelector((state) => state.financing);

  const { isFinancingCertificate } = useFinancingFormInfo();
  // const { arrowPosition } = useFinancingArrowImage();
  const { onCloseClick, onBackClick } = useNavigationButtonsHandler({
    reset: formMethods.reset,
  });

  return (
    <ModalContainer
      onCloseModalTitle="modal.widget-on-close.title-finance"
      onCloseModalText="modal.widget-on-close.text-finance"
      activeStep={activeStep}
      isOpen={isFinancingOpen}
      isConfirmEmailSent={isConfirmEmailSent}
      isAuthFlow={isAuthFlow}
      isRegisterFormOpen={isRegisterFormOpen}
      isLoginFormOpen={isLoginFormOpen}
      isArrowUp
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formMethods={formMethods}
      arrowPosition={{ left: 0, top: 0 }}
      onBackClick={onBackClick}
      onCloseClick={onCloseClick}
    >
      <FinancingFormContainer />
      {!isMobileSize && (
        <InfoContainer src={BackgroundImg}>
          {isFinancingCertificate ? (
            <FinancingInfoCertificateContainer />
          ) : (
            <FinancingInfoContainer />
          )}
        </InfoContainer>
      )}
    </ModalContainer>
  );
};

const FinancingModal = memo(FinancingModalBase);

export { FinancingModal };
