import {
  Currency,
  useConvertChfToEurQuery,
  useConvertEurToChfQuery,
  useGetEurToChfRateQuery,
} from '../../../generated';
import useGetLocalization from '../../localization/get-localization';
import { formatNumber } from '../../property/utils/format-number';

export const useGetCHFRate = (): number => {
  const { data } = useGetEurToChfRateQuery();

  return data?.rate || 1;
};

export const useEURtoCHF = (amount: number, skip?: boolean): number => {
  const lang = useGetLocalization();
  const rate = useGetCHFRate();

  const { data } = useConvertEurToChfQuery(
    { amount },
    { skip: !amount || (skip ?? lang === 'de-de') }
  );

  return data?.value || amount * rate;
};

export const useCHFtoEUR = (amount: number, skip?: boolean): number => {
  const lang = useGetLocalization();
  const rate = useGetCHFRate();

  const { data } = useConvertChfToEurQuery(
    { amount },
    { skip: !amount || (skip ?? lang === 'de-ch') }
  );

  return data?.value || amount / rate;
};

export const useToLocalWithCurrency = (
  amount: number,
  currency: Currency = Currency.Eur,
  priceCurrencySeparate = false
): string | [string, string] => {
  const lang = useGetLocalization();

  const eurAmountInChf = useEURtoCHF(
    amount,
    !(currency === Currency.Eur && lang === 'de-ch')
  );
  const chfAmountInEur = useCHFtoEUR(
    amount,
    !(currency === Currency.Chf && lang !== 'de-ch')
  );

  if (currency === Currency.Eur && lang !== 'de-ch') {
    if (priceCurrencySeparate) return [formatNumber(amount), '€'];
    return `${formatNumber(amount)} €`;
  }
  if (currency === Currency.Eur && lang === 'de-ch') {
    if (priceCurrencySeparate) return [formatNumber(eurAmountInChf), 'CHF'];
    return `CHF ${formatNumber(eurAmountInChf)}`;
  }

  if (currency === Currency.Chf && lang === 'de-ch') {
    if (priceCurrencySeparate) return [formatNumber(amount), 'CHF'];
    return `CHF ${formatNumber(amount)}`;
  }
  if (currency === Currency.Chf && lang !== 'de-ch') {
    if (priceCurrencySeparate) return [formatNumber(chfAmountInEur), '€'];
    return `${formatNumber(chfAmountInEur)} €`;
  }

  return 'n/a';
};
