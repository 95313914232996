import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as Phone } from 'assets/streamline-light/phones-mobile-devices/phone/phone.svg';
import { ReactComponent as Star } from 'assets/navigation/star.svg';
import { ReactComponent as User } from 'assets/navigation/user.svg';

import { device } from '../../../../../style/theme';
import AuthModal from '../../../../auth/components/modals/auth-modal/auth-modal';
import { ProfileSettings } from '../../../../auth/components/profile-settings';
import {
  logout,
  toggleHeaderAuthOpen,
  toggleHeaderMobileAuthOpen,
  toggleIsLoginModalOpen,
} from '../../../../auth/redux/authSlice';
import { resetValuationWizard } from '../../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { resetSPC } from '../../../../forms/form-search-profile/redux/searchProfileSlice';
import useGetLocalization from '../../../../localization/get-localization';
import { resetMapState } from '../../../../property/redux/dynamicMapUtilsSlice';
import { useAppSelector } from '../../../hooks';
import { toggleCollapsibleHeader } from '../../../redux/mobileSlice';
import { OutsideClickHandler } from '../../outside-click-handler';
import { HamburgerButton } from '../../ui/buttons';
import { Collapsible } from '../../ui/collapsible';
import Icon from '../../ui/icon';
import CollapseHeaderLinks from './collapse-header-links';
import { Logo } from './logo';

const Container = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 27px 14px 34px;
  background: linear-gradient(90.57deg, #002447 4.48%, #003364 48.29%);
`;

const MenuBox = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const MenuButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  //opacity: .75;
`;

const MenuButtonLink = styled.a`
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  //opacity: .75;
  text-decoration: none;
`;

const UserBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const UserActiveIndicator = styled.div`
  width: 13px;
  height: 13px;
  background: #5aae2e;
  position: absolute;
  bottom: -8px;
  right: -4px;
  border-radius: 100%;
  border: 3px solid #00366a;
`;

const HeaderBox = styled.div`
  padding: 33px;
`;

const NavLinksBox = styled.div``;

const MainNavLink = styled.button<{ thinner?: boolean }>`
  background: transparent;
  outline: none;
  border: none;
  font-family: ${(props) => (props.thinner ? 'Inter' : 'Roboto')}, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => (props.thinner ? 16 : 24)}px;
  line-height: 1;
  letter-spacing: 0 ${(props) => (props.thinner ? 2 : 5)}em;
  color: #00305e;
  transition: opacity 0.3s;
  margin-bottom: 30px;
  display: block;
  text-transform: capitalize;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const ControlBox = styled.div`
  padding: 30px 0;
  border-top: 1px solid rgba(0, 48, 94, 0.2);
`;

type LinkOption =
  | 'evaluate'
  | 'sell'
  | 'buy'
  | 'finance'
  | 'location'
  | 'company'
  | '';
type AuthOpen = 'user' | 'star' | '';

const CollapseHeader = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { headerMobileAuthOpen: authOpen, user } = useAppSelector(
    (state) => state.auth
  );
  const setAuthOpen = useCallback((state: AuthOpen) => {
    dispatch(toggleHeaderMobileAuthOpen(state));
  }, []);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const isCHSite = useGetLocalization() === 'de-ch';
  const isATSite = useGetLocalization() === 'de-at';

  const [activeMenu, setActiveMenu] = useState<LinkOption>('');

  const isOpen = useAppSelector(
    (state) => state.mobile.isCollapsibleHeaderOpen
  );
  const toggleHeader = useCallback(() => {
    dispatch(toggleCollapsibleHeader(!isOpen));
  }, [dispatch, isOpen]);

  const openLoginModal = () => {
    batch(() => {
      dispatch(toggleIsLoginModalOpen(true));
      dispatch(toggleCollapsibleHeader(false));
    });
  };

  const onLogout = () => {
    toggleHeader();
    batch(() => {
      dispatch(logout());
      dispatch(resetValuationWizard());
      dispatch(resetSPC());
      dispatch(resetMapState());
    });
  };

  const handlePhoneClick = () => {
    window.open(`tel:${t('subheader.phone.text')}`);
  };

  return (
    <>
      <Container>
        <Collapsible
          isOpen={isOpen}
          isShadow
          isFullScreen
          header={
            <Header>
              <Logo />
              <MenuBox>
                <MenuButtonLink href={'https://www.von-poll.com/de/merkliste'}>
                  <Icon width={20} height={20} icon={Star} />
                </MenuButtonLink>
                {!user && (
                  <MenuButton onClick={() => setAuthOpen('user')}>
                    <Icon width={20} height={20} icon={User} />
                  </MenuButton>
                )}
                {user && (
                  <MenuButton
                    onClick={() => {
                      setAuthOpen('');
                      setIsDropdownOpen(true);
                    }}
                  >
                    <UserBox>
                      <Icon width={20} height={20} icon={User} />
                      <UserActiveIndicator />
                    </UserBox>
                    <OutsideClickHandler
                      onOutsideClick={() => setIsDropdownOpen(false)}
                    >
                      {isDropdownOpen && (
                        <ProfileSettings
                          isMobile
                          closeDropdown={() => setIsDropdownOpen(false)}
                        />
                      )}
                    </OutsideClickHandler>
                  </MenuButton>
                )}
                <MenuButton onClick={handlePhoneClick}>
                  <Icon width={20} height={20} icon={Phone} />
                </MenuButton>
                <HamburgerButton isOpen={isOpen} toggleHeader={toggleHeader} />
              </MenuBox>
            </Header>
          }
        >
          <HeaderBox>
            {activeMenu === '' ? (
              <>
                <NavLinksBox>
                  <MainNavLink onClick={() => setActiveMenu('evaluate')}>
                    {t('header.navigation.rating').toLowerCase()}
                  </MainNavLink>
                  {/* {!isCHSite && !isATSite && <MainNavLink onClick={() => setActiveMenu('sell')}>{t('header.navigation.seller').toLowerCase()}</MainNavLink>} */}
                  <MainNavLink onClick={() => setActiveMenu('sell')}>
                    {t('header.navigation.seller').toLowerCase()}
                  </MainNavLink>
                  <MainNavLink onClick={() => setActiveMenu('buy')}>
                    {t('header.navigation.buyer').toLowerCase()}
                  </MainNavLink>
                  <MainNavLink onClick={() => setActiveMenu('finance')}>
                    {t('header.navigation.financing').toLowerCase()}
                  </MainNavLink>
                  <MainNavLink onClick={() => setActiveMenu('location')}>
                    {t('header.navigation.location').toLowerCase()}
                  </MainNavLink>
                  <MainNavLink onClick={() => setActiveMenu('company')}>
                    {t('header.navigation.company').toLowerCase()}
                  </MainNavLink>
                </NavLinksBox>
                <ControlBox>
                  <MainNavLink
                    thinner
                    onClick={user ? onLogout : openLoginModal}
                  >
                    {user
                      ? t('header.authentication.logout')
                      : t('header.navigation.login')}
                  </MainNavLink>
                  <MainNavLink
                    thinner
                    onClick={() => {
                      window.open(
                        'https://www.von-poll.com/de/unternehmen/kontakt',
                        '__blank'
                      );
                    }}
                  >
                    {t('header.navigation.contact')}
                  </MainNavLink>
                </ControlBox>
              </>
            ) : (
              <CollapseHeaderLinks
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
              />
            )}
          </HeaderBox>
        </Collapsible>
      </Container>
      <AuthModal
        isMobile
        close={() => setAuthOpen('')}
        isOpen={authOpen !== ''}
        showForm={authOpen === 'user'}
      />
    </>
  );
};

export { CollapseHeader };
