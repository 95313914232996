import { ReactComponent as Calendar } from 'assets/streamline-light/interface-essential/date-calendar/calendar.svg';
import { ReactComponent as Check } from 'assets/streamline-light/interface-essential/form-validation/check-rounded.svg';
import { ReactComponent as Remove } from 'assets/streamline-light/interface-essential/form-validation/remove-circle.svg';
import { ReactComponent as PDF } from 'assets/streamline-light/work-office-companies/office-files/office-file-pdf.svg';
import { ReactComponent as Bin } from 'assets/streamline-light/interface-essential/delete/bin.svg';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { useDeletePropertyPurchaseIntentMutation } from '../../../../generated';
import { TooltipConfirmation } from '../../../common/components/tooltip/TooltipConfirmation';
import { getInEur } from '../../utils/currency';
import PropertyPDF from '../PDF/PropertyPDF';
import usePropertyData from '../PDF/usePropertyData';
import usePurchaseIntentData from '../PDF/usePurchaseIntentData';
import {
  DetailsContainer,
  Price,
  Recipient,
  OfferContainer,
  SentOfferDataContainer,
  OfferInfosContainer,
  OfferInfoBox,
  OfferInfoIcon,
  ControlsContainer,
  PdfButton,
  DeleteButton,
  OfferInfoDetailsBox,
} from './single-offer-styles';

interface SingleOfferInterface {
  _id: string;
  recipient: string;
  price: number;
  dateCreated: Date;
  confirmFunding: boolean;
  propertyId: string;
}

interface SingleOfferProps {
  offer: SingleOfferInterface;
  refetch: () => void;
}

interface OfferInfoProps {
  text: string;
  renderIcon: () => JSX.Element;
  iconColor?: string;
}

const OfferInfo = ({ text, renderIcon, iconColor }: OfferInfoProps) => {
  return (
    <OfferInfoBox>
      <OfferInfoIcon iconColor={iconColor}>{renderIcon()}</OfferInfoIcon>
      {text}
    </OfferInfoBox>
  );
};

const SingleOffer = ({ offer, refetch }: SingleOfferProps) => {
  const [deletePropertyPurchaseIntent, res] =
    useDeletePropertyPurchaseIntentMutation();

  const { t } = useTranslation();

  const { isLoading: isLoadingPropertyData, propertyData } = usePropertyData(
    offer.propertyId
  );
  const { isLoading: isLoadingPurchaseIntentData, purchaseIntentData } =
    usePurchaseIntentData(offer._id);

  const deleteHandler = async () => {
    await deletePropertyPurchaseIntent({ id: offer._id });
    refetch();
  };

  return (
    <OfferContainer>
      <SentOfferDataContainer>
        <DetailsContainer>
          <Recipient>{offer.recipient}</Recipient>
          <Price>{getInEur(offer.price)}</Price>
        </DetailsContainer>
        <OfferInfosContainer>
          <OfferInfo
            text={new Date(offer.dateCreated)
              .toLocaleDateString()
              .replaceAll('/', '.')}
            renderIcon={() => <Calendar width={16} height={16} />}
          />
          <OfferInfoDetailsBox>
            <OfferInfo
              text={t(
                'expose-page.head-info.button.interest-in-buying.info.financing'
              )}
              renderIcon={() =>
                offer.confirmFunding ? (
                  <Check width={16} height={16} />
                ) : (
                  <Remove width={16} height={16} />
                )
              }
              iconColor={!offer.confirmFunding ? 'rgba(0, 48, 94, 0.5)' : ''}
            />
            {/* <OfferInfo */}
            {/*  text={t('expose-page.head-info.button.interest-in-buying.info.notary-appointment')} */}
            {/*  renderIcon={() => */}
            {/*    false ? ( */}
            {/*      <Check width={16} height={16} /> */}
            {/*    ) : ( */}
            {/*      <Remove width={16} height={16} /> */}
            {/*    ) */}
            {/*  } */}
            {/*  iconColor={'rgba(0, 48, 94, 0.5)'} */}
            {/* /> */}
          </OfferInfoDetailsBox>
        </OfferInfosContainer>
      </SentOfferDataContainer>
      <ControlsContainer>
        {!(isLoadingPropertyData || isLoadingPurchaseIntentData) && (
          <PDFDownloadLink
            document={
              <PropertyPDF
                propertyData={propertyData}
                purchaseIntentData={purchaseIntentData}
              />
            }
            fileName={'VP_Kaufangebot'}
          >
            {({ loading }) =>
              loading ? (
                <PdfButton disabled>
                  <PDF width={16} height={16} />
                </PdfButton>
              ) : (
                <PdfButton>
                  <PDF width={16} height={16} />
                </PdfButton>
              )
            }
          </PDFDownloadLink>
        )}
        <TooltipConfirmation
          tabletWidth="100%"
          headerText={t('purchase-offers.delete.tooltip.title')}
          backText={t('purchase-offers.delete.tooltip.back')}
          acceptText={t('purchase-offers.delete.tooltip.accept')}
          id={'purchase-offer-delete'}
          onDelete={deleteHandler}
        >
          <DeleteButton>
            <Bin width={16} height={16} />
          </DeleteButton>
        </TooltipConfirmation>
      </ControlsContainer>
    </OfferContainer>
  );
};

export default SingleOffer;
