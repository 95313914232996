import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReleaseStatus } from '../../../generated';

interface FileData {
  name: string;
  title: string;
  id: number;
}

interface InitialState {
  isFileAccessManagerOpen: boolean;
  selectedFileData: FileData;
  isFilterOpen: boolean;
  shouldResetFilter: boolean;
  releaseStatusFilter: ReleaseStatus[];
}

const initialState: InitialState = {
  isFileAccessManagerOpen: false,
  selectedFileData: {
    name: '',
    title: '',
    id: 0,
  },
  isFilterOpen: false,
  shouldResetFilter: false,
  releaseStatusFilter: [],
};

export const brokerViewSlice = createSlice({
  name: 'brokerView',
  initialState,
  reducers: {
    toggleFileAccessManagerOpen(state, action: PayloadAction<boolean>) {
      state.isFileAccessManagerOpen = action.payload;
    },
    setSelectedFileData(state, action: PayloadAction<FileData>) {
      state.selectedFileData = action.payload;
    },
    toggleFilterOpen(state, action: PayloadAction<boolean>) {
      state.isFilterOpen = action.payload;
    },
    toggleShouldResetFilter(state, action: PayloadAction<boolean>) {
      state.shouldResetFilter = action.payload;
    },
    setReleaseStatusFilter(state, action: PayloadAction<ReleaseStatus[]>) {
      state.releaseStatusFilter = action.payload;
    },
  },
});

export const {
  toggleFileAccessManagerOpen,
  setSelectedFileData,
  toggleFilterOpen,
  toggleShouldResetFilter,
  setReleaseStatusFilter,
} = brokerViewSlice.actions;

export default brokerViewSlice.reducer;
