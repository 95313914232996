import useGetLocalization from '../../localization/get-localization';

const useCurrency = () => {
  const lang = useGetLocalization();

  if (lang === 'de-ch') return ' CHF';

  return '€';
};

export { useCurrency };
