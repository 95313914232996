import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ReactComponent as Search } from 'assets/streamline-light/interface-essential/search/search.svg';
import { ReactComponent as Clear } from 'assets/streamline-light/interface-essential/form-validation/remove-circle.svg';
import { ReactComponent as Check } from 'assets/streamline-light/interface-essential/form-validation/check-1.svg';
import { ChangeEvent, CSSProperties, FC, useCallback, useEffect } from 'react';
import {
  ClearSearchIcon,
  Container,
  InputField,
  SearchIcon,
  ConfirmButton,
} from './input-search-styled';
import { IconButton } from '../../ui/buttons';

export interface IInputSearch {
  id?: string;
  defaultValue?: string;
  placeholder?: string;
  iconStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  animateWidth?: {
    from: string;
    to: string;
  };
  onClearSearch?: () => void;
  onConfirmSearch?: () => void;
}

const InputSearch: FC<IInputSearch> = ({
  id,
  defaultValue,
  placeholder,
  iconStyle,
  containerStyle,
  onChange,
  value,
  animateWidth,
  onClearSearch,
  onConfirmSearch,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const onEnterKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== 'Enter') return;

      if (onConfirmSearch) {
        onConfirmSearch();
      }
    },
    [onConfirmSearch]
  );
  useEffect(() => {
    window.addEventListener('keydown', onEnterKeyDown);

    return () => {
      window.removeEventListener('keydown', onEnterKeyDown);
    };
  }, [onEnterKeyDown]);

  return (
    <Container style={{ ...containerStyle }} animateWidth={animateWidth}>
      <InputField
        id={id}
        {...(defaultValue && { defaultValue })}
        placeholder={t(placeholder ?? '')}
        onChange={onChange}
        value={value}
      />
      <SearchIcon
        icon={Search}
        color={theme.blue}
        width={16}
        height={16}
        animate={!!value}
        style={{ ...iconStyle }}
      />
      {onClearSearch && (
        <ClearSearchIcon
          icon={Clear}
          color={theme.red}
          width={16}
          height={16}
          animate={!!value}
          style={{ ...iconStyle }}
          onClick={onClearSearch}
        />
      )}
      {onConfirmSearch && (
        <ConfirmButton
          icon={Check}
          animate={!!value}
          onClick={onConfirmSearch}
        />
      )}
    </Container>
  );
};

export { InputSearch };
