import { Feature } from '../../../../services/mapbox/interfaces';
import { IFinancingForm } from '../interface';
import { isoCountriesGermany } from '../../constants';
import {
  FinancingDataInput,
  FinancingPropertyType,
  FinancingReason,
  FinancingType,
  FinancingVariantInput,
  RateType,
  VonPollPropertyData,
} from '../../../../generated';
import { parseFinancingLocation } from './parse-financing-location';
import { reasonBoxes } from '../constants';

interface IReturn {
  financingData: FinancingDataInput;
  // termsAndConditionsFinancing: boolean;
  financingVariants: FinancingVariantInput[];
  vonPollPropertyData?: VonPollPropertyData;
}

export const parseFinancingInput = (
  formData: IFinancingForm,
  financingType: FinancingType,
  geoCodingResult?: Feature
): IReturn => {
  const {
    mapboxLocality,
    mapboxNeighborhood,
    mapboxPostCode,
    mapboxCity,
    mapboxCountry,
    mapboxPlaceName,
    mapboxRegion,
    longitude,
    latitude,
  } = parseFinancingLocation(geoCodingResult);

  const countryCode =
    Object.keys(isoCountriesGermany).find(
      (key: string) =>
        isoCountriesGermany[key].toLowerCase() === mapboxCountry.toLowerCase()
    ) ?? 'DE';

  const location = {
    coordinates: {
      longitude: longitude || formData.longitude,
      latitude: latitude || formData.latitude,
    },
    address: {
      postCode: mapboxPostCode || formData.postCode,
      city: mapboxCity || formData.city,
      locality: mapboxLocality || formData.locality,
      neighborhood: mapboxNeighborhood || formData.neighborhood,
      placeName: mapboxPlaceName || formData.placeName,
      region: mapboxRegion || formData.region || mapboxCity || formData.city,
    },
  };

  const {
    name,
    reason,

    livingArea,
    buildingYear,
    rentalIncomePerMonth,
    propertyType,
    propertyUse,
    // searchProgress,
    //
    // purchasePrice,
    // equityCapital,
    brokerageFee,
    brokerFeePercent,
    repaymentRate,
    rateType,
    // buildingCosts,
    // landPrice,
    // propertyValue,
    // monthlyRate,
    // estimatedRemainingDebt,
    // desiredLoanAmount,
    // payoutDate,
    debitRateCommitment,
    desiredRate,
    radius,

    age,
    mainEarner,
    householdNetIncome,
    // termsAndConditionsFinancing,
  } = formData;
  const reasonBox = reasonBoxes.find((item) => item.value === reason);

  // const reasonName = reasonBoxes.find((item) => item.value === reason)?.title;
  const financingData = {
    title: `${mapboxCity}`,
    financingType,
    countryCode,
  };

  const personalFinancing = {
    age,
    mainEarner,
    householdNetIncome,
  };

  const financingVariantData = {
    name: name || 'Variante 1',
    index: 0,
    reason,

    propertyType,
    radius: radius || 25,
    livingArea:
      propertyType !== FinancingPropertyType.PlotOnly ? livingArea : undefined,
    buildingYear:
      propertyType !== FinancingPropertyType.PlotOnly
        ? buildingYear
        : undefined,
    rentalIncomePerMonth:
      propertyType === FinancingPropertyType.MultiFamilyHouse
        ? rentalIncomePerMonth
        : undefined,
    propertyUse,

    location,
    brokerageFee,
    brokerFeePercent: brokerageFee ? brokerFeePercent : 0,
    rateType: rateType || RateType.RepaymentRate,
    landRegisterEntry: 0.6,
    notary: 1.2,
    repaymentRate: repaymentRate || 2,
    desiredRate: desiredRate || Math.round(householdNetIncome * 0.3),
    debitRateCommitment: debitRateCommitment || '10',
  };
  if (reasonBox) {
    reasonBox.fields.secondStep.forEach((item) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (formData[item] && item !== 'postCode') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        financingVariantData[item] = formData[item];
      }
    });
    reasonBox.fields.thirdStep.forEach((item) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (formData[item]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        financingVariantData[item] = formData[item];
      }
    });
  }
  // searchProgress,
  // purchasePrice,
  // equityCapital,
  // buildingCosts,
  // landPrice,
  // propertyValue,
  // monthlyRate,
  // estimatedRemainingDebt,
  // desiredLoanAmount,
  // payoutDate,

  return {
    financingData,
    // termsAndConditionsFinancing,
    financingVariants: [
      {
        financingVariantData,
        personalFinancing,
        financingSuggestions: {
          isRequestedFinancing: true,
          isCertificateSuggestion:
            reason === FinancingReason.PurchaseOfExistingProperty,
        },
        propositionsLastSync: new Date(),
      },
    ],
  };
};
