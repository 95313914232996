import { FC, memo, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  ConfirmButton,
  Form,
  TextArea,
  TextAreaTitle,
} from './expose-state-card-styles';
import { Comment } from '../../comment/comment';
import { ExposeInfoCard } from '../../info-card/info-card';
import { useParseComments } from '../../../hooks/useParseComments';
import { IExposeActionForm, PropertyExpose } from '../../../interfaces';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import {
  PropertyExposeStatus,
  useSetPropertyExposeStatusMutation,
} from '../../../../../generated';
import { GlobalLoader } from '../../../../common/components/ui/loaders/global-loader';
import { device } from '../../../../../style/theme';
import { useAppSelector } from '../../../../common/hooks';

const LoaderContainer = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  padding: 32px;
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  @media ${device.tablet} {
    padding: 16px;
  }

  &&&& {
    > div {
      position: absolute;
    }
  }
`;

interface IProps {
  expose: PropertyExpose;
  onDownloadPdfExpose?: () => void;
  onOpenOnlineExpose?: () => void;
  isLocked?: boolean;
}

const ApprovedCardBase: FC<IProps> = ({
  expose,
  onDownloadPdfExpose,
  onOpenOnlineExpose,
  isLocked,
}) => {
  const { t } = useTranslation();
  const { propertyId } = useParams<{ propertyId: string }>();
  const [showTextArea, setShowTextArea] = useState<boolean>(false);
  const theme = useContext(ThemeContext);
  const parsedComments = useParseComments(expose.exposeChangesHistory ?? []);

  const [setPropertyExposeStatus, { error: setExposeStatusError, isLoading }] =
    useSetPropertyExposeStatusMutation();

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);
  const isReadOnlyAccess = useAppSelector(
    (state) => state.sellerProperties.isReadOnlyAccess
  );

  const methods = useForm<IExposeActionForm>({
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<IExposeActionForm> = useCallback(
    async (formData) => {
      try {
        await setPropertyExposeStatus({
          propertyId,
          status: PropertyExposeStatus.ChangesRequested,
          comment: `${formData.comment} Date: ${new Date().toISOString()}`,
        }).unwrap();
      } catch (error) {
        console.error(error);
      }
    },
    [propertyId, setPropertyExposeStatus]
  );

  if (isLoading) {
    return (
      <LoaderContainer>
        <GlobalLoader />
      </LoaderContainer>
    );
  }

  return (
    <FormProvider {...methods}>
      {setExposeStatusError?.message && (
        <GlobalError
          title={t(setExposeStatusError?.message?.split(':')[0].toLowerCase())}
        />
      )}
      <Form
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{ gap: expose.comment ? 0 : '12px' }}
      >
        {!isEmpty(parsedComments) && (
          <>
            <ExposeInfoCard
              borderLeftColor={theme.red}
              title={t('seller.expose-card.title')}
              createdOn={expose.createdAt}
            />
            {parsedComments?.map((comment) => (
              <Comment
                userAvatarPlaceholder={comment?.avatarPlaceholder}
                title={comment?.userFullName}
                date={comment?.date}
                text={comment?.text}
              />
            ))}
          </>
        )}
        <ExposeInfoCard
          borderLeftColor={theme.green}
          title={t('seller.expose-card.title')}
          createdOn={expose.updatedAt || expose.createdAt}
          onDownloadPdfExpose={onDownloadPdfExpose}
          onOpenOnlineExpose={onOpenOnlineExpose}
        />
        <ConfirmButton
          fluid
          type={'button'}
          backgroundColor={theme.blue}
          style={{ marginTop: expose.comment ? '12px' : 0 }}
          label={t(
            'seller.expose-card.button.request-additional-expose-changes.label'
          )}
          onClick={() => setShowTextArea((prevState) => !prevState)}
        />
        {showTextArea && !isLocked && (
          <>
            <TextAreaTitle
              content={t(
                'seller.expose-card.text-area.request-expose-change.title'
              )}
            />
            <TextArea
              rows={10}
              placeholder={t(
                'seller.expose-card.text-area.request-expose-change.placeholder'
              )}
              {...methods.register('comment')}
            />
            <ConfirmButton
              fluid
              isDisabled={
                !methods.watch('comment') || isBrokerView || isReadOnlyAccess
              }
              backgroundColor={theme.blue}
              label={t('seller.expose-card.button.request-expose-change.label')}
            />
          </>
        )}
      </Form>
    </FormProvider>
  );
};

const ApprovedCard = memo(ApprovedCardBase);

export { ApprovedCard };
