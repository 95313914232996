import styled from 'styled-components';
import { SecondaryButton } from '../../../../../common/components/ui/buttons';
import { device } from '../../../../../../style/theme';
import { ParagraphTextWithHTML } from '../../../../../common/components/ui/typography';

export const LoaderContainer = styled.div<{ isiframe?: boolean }>`
  height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
  &&&& {
    > div {
      position: absolute;
    }
  }
`;

export const Container = styled.div<{ isiframe?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: ${({ isiframe }) => (isiframe ? 'auto' : 'visible')};
  height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
  padding: ${({ isiframe }) => (isiframe ? 16 : 0)}px;
`;

export const Header = styled.div`
  display: block;
`;

export const BackButton = styled(SecondaryButton)`
  margin-top: auto;
  align-self: flex-start;
`;

export const FormWrapper = styled.div<{ isIframe?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ isIframe }) => isIframe && 'flex: 1;'}

  @media ${device.tablet} {
    margin: ${({ isIframe }) => (isIframe ? 0 : '0 16px 0 16px')};
    height: 100%;
  }
`;

export const PhoneVerificiationWrapper = styled.div`
  flex: 1;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;

export const FieldRequirement = styled(ParagraphTextWithHTML)`
  font-weight: bold;
  font-size: 10px;
  line-height: 1.6;
  opacity: 0.8;
  letter-spacing: 0.5px;
  @media ${device.tablet} {
    padding-bottom: 56px;
  }
`;

export const IFrameContainer = styled.div<{
  isiframe?: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${({ isiframe }) => (isiframe ? '100vh' : '100%')};

  ${({ isiframe }) => isiframe && 'overflow-y: auto;'}
`;
