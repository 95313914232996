import styled from 'styled-components';
import { device } from '../../../../../../../../style/theme';
import { NextButton } from '../../../../../../components/common/next-button';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div<{ isiframe?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  &:last-of-type {
    flex-direction: row;
    justify-content: flex-start;
    > *:last-of-type:not(:first-of-type) {
      margin-left: 24px;
    }
  }
  @media ${({ isiframe }) => (isiframe ? 0 : device.mobile)} {
    &:last-of-type {
      justify-content: flex-start;
      > *:last-of-type {
        margin-top: 32px;
      }
    }
  }

  @media ${({ isiframe }) => (isiframe ? '0' : device.tablet)} {
    &:last-of-type {
        & > *:last-of-type {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
`;

export const RegisterButton = styled(NextButton)`
  text-transform: uppercase;
`;
