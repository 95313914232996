import 'react-toastify/dist/ReactToastify.css';

import styled, { ThemeProvider } from 'styled-components';

import CSSReset from '../../../../style/reset';
import { defaultTheme, device } from '../../../../style/theme';
import { Footer } from "./footer";

import { SimpleHeader } from './header/simple-header';

interface IProps {
  children: JSX.Element;
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  height: calc(100vh - 64px - 68px);
  max-height: calc(100vh - 64px - 68px);
  @media ${device.tablet} {
    max-width: 100%;
    padding-bottom: 0;
  }
`;

const SimpleLayout = ({ children }: IProps): JSX.Element => (
  <ThemeProvider theme={defaultTheme}>
    <CSSReset />
    <Container>
      <SimpleHeader />
      <Content>{children}</Content>
      <Footer />
    </Container>
  </ThemeProvider>
);

export { SimpleLayout };
