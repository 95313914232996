import { useCallback, useRef, useMemo, useEffect, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  FieldPath,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as Buildings2 } from 'assets/streamline-light/building-construction/buildings/buildings-2.svg';
import { ReactComponent as HouseApartment } from 'assets/streamline-light/building-construction/houses/house-apartment.svg';
import { ReactComponent as House } from 'assets/streamline-light/building-construction/houses/house-3.svg';

import { SelectV2 } from '../../../components/common/select-v2/select-v2';

import {
  PropertyCode,
  usePropertyQuery,
  useUpdatePropertyMutation,
} from '../../../../../generated';
import { FormGridWrapperFixHeight } from '../../../../common/components/form/form-grid';
import { Blurry } from '../../../../common/components/ui/blurry';
import {
  MainButton,
  SecondaryButton,
} from '../../../../common/components/ui/buttons';
import { device } from '../../../../../style/theme';
import { Checkbox } from '../../../../common/components/form';
import { ExpandableContent } from '../../../../common/components/ui/expandable-content';
import { GlobalLoader } from '../../../../common/components/ui/loaders/global-loader';
import { useAppDispatch } from '../../../../common/hooks';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import {
  VALUATION_FORM_KEYS,
  APARTMENT_SUBTYPES,
  HOUSE_SUBTYPES,
} from '../../constants';
import { BoxType } from '../../../interface';
import { IValuationForm } from '../../interface';
import {
  toggleIsEditValuationModalOpen,
  updateFormStateAction,
} from '../../redux/valuationWizardSlice';
import { BoxSelector } from '../../../components/common/box-selector';
import { Input } from '../../../components/common/input/input';
import { SliderInput } from '../../../components/common/slider-input';
import { MobileBack } from '../containers/common/mobile-back';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import { SelectWithLabel } from '../../../components/common/select-wth-label';
import {
  energyLabelOptions,
  energyLabelOptionsSwitzerland,
} from '../../../form-property-valuation-wizard-v2/constants';

const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const FormButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Row = styled.div<{ direction?: string; marginBottom?: number }>`
  display: flex;
  flex-direction: ${(props) => props.direction ?? 'row'};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`}
`;

const ExpandleContentSplitter = styled.div`
  margin: 8px 0 0;
`;

const StyledFormGridWrapperFixHeight = styled(FormGridWrapperFixHeight)`
  min-height: 480px;
  display: block;
`;

const FormGridOuterWrapper = styled.div`
  position: relative;
`;

const CheckboxRow = styled.div`
  padding: 12px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media ${device.tablet} {
    padding-top: 18px;
  }
`;

const CheckboxItem = styled(Checkbox)`
  margin-right: 12px;
`;

const EditValuationForm = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();

  const methods = useForm<IValuationForm>();
  const { watch } = methods;

  const { propertyId } = useParams<{ propertyId: string }>();
  const formGridWrapperRef = useRef<HTMLDivElement>(null);

  const closeModal = useCallback(
    () => dispatch(toggleIsEditValuationModalOpen(false)),
    [dispatch]
  );

  const {
    isLoading: isPropertyDataLoading,
    propertyData,
    countryCode,
  } = usePropertyQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, isLoading }) => {
        return {
          propertyData: data?.property?.propertyData,
          countryCode: data?.property?.countryCode,
          isLoading,
        };
      },
    }
  );
  const isAustria = countryCode === 'AT';
  const isSwitzerland = countryCode === 'CH';

  const watchedCode = watch('code', propertyData?.propertyType.code);

  const [watchedSubcode, setWatchedSubcode] = useState(
    propertyData?.propertyType.subcode
  );
  const [watchedBuildingYear, setWatchedBuildingYear] = useState(
    propertyData?.buildingYear
  );
  const [watchedRenovationYear, setWatchedRenovationYear] = useState(
    propertyData?.renovationYear
  );
  const [watchedFloorNumber, setWatchedFloorNumber] = useState(
    propertyData?.floorNumber || 0
  );
  const [watchedNumberOfFloorsInBuilding, setWatchedNumberOfFloorsInBuilding] =
    useState(propertyData?.numberOfFloorsInBuilding || 0);

  useEffect(() => {
    watch((value) => {
      setWatchedSubcode(value.subcode);
      setWatchedBuildingYear(value.buildingYear);
      setWatchedRenovationYear(value.renovationYear);
      setWatchedFloorNumber(value.floorNumber || 0);
      setWatchedNumberOfFloorsInBuilding(value.numberOfFloorsInBuilding || 1);
    });
  }, [watch]);

  // const watchedSubcode = methods.watch('subcode');
  // const watchedBuildingYear = methods.watch(
  //   'buildingYear',
  //   propertyData?.buildingYear
  // );
  // const watchedRenovationYear = methods.watch('renovationYear', propertyData?.renovationYear);
  // const watchedFloorNumber = methods.watch('floorNumber');
  // const watchedNumberOfFloorsInBuilding = methods.watch(
  //   'numberOfFloorsInBuilding'
  // );

  const [updateProperty, { isLoading: isUpdatePropertyLoading }] =
    useUpdatePropertyMutation();

  const [lastEditedCode, setLastEditedCode] = useState(
    propertyData?.propertyType.code
  );
  const [selectedSubcodeTitle, setSelectedSubcodeTitle] = useState('');

  useEffect(() => {
    if (watchedCode !== lastEditedCode) {
      setLastEditedCode(watchedCode);
      methods.setValue(
        'subcode',
        watchedCode === PropertyCode.Apartment
          ? APARTMENT_SUBTYPES[0].value
          : HOUSE_SUBTYPES[0].value
      );
    }
  }, [lastEditedCode, methods, watchedCode]);

  const isRenovationYearLower = useMemo(
    () =>
      watchedBuildingYear &&
      watchedRenovationYear &&
      watchedRenovationYear <= watchedBuildingYear,
    [watchedBuildingYear, watchedRenovationYear]
  );

  const isNumberOfFloorsLower = useMemo(
    () =>
      watchedCode === propertyData?.propertyType.code &&
      typeof watchedNumberOfFloorsInBuilding !== 'undefined' &&
      typeof watchedFloorNumber !== 'undefined' &&
      !Number.isNaN(watchedFloorNumber) &&
      !Number.isNaN(watchedNumberOfFloorsInBuilding) &&
      watchedNumberOfFloorsInBuilding < watchedFloorNumber,
    [
      propertyData?.propertyType.code,
      watchedCode,
      watchedFloorNumber,
      watchedNumberOfFloorsInBuilding,
    ]
  );

  useEffect(() => {
    let selectedOption = null;
    if (PropertyCode.Apartment === watchedCode) {
      selectedOption = APARTMENT_SUBTYPES.find(
        (type) => type.value === watchedSubcode
      );
    } else if (PropertyCode.House === watchedCode) {
      selectedOption = HOUSE_SUBTYPES.find(
        (type) => type.value === watchedSubcode
      );
    }
    if (selectedOption) {
      setSelectedSubcodeTitle(selectedOption.label);
    } else {
      setSelectedSubcodeTitle('');
    }
  }, [watchedCode, watchedSubcode]);

  const onSubmit: SubmitHandler<IValuationForm> = useCallback(
    async (formData) => {
      const isApartment = watchedCode === PropertyCode.Apartment;
      const isHouse = watchedCode === PropertyCode.House;
      const isMultiFamilyHouse = watchedCode === PropertyCode.MultiFamilyHouse;

      if (!isMultiFamilyHouse && isRenovationYearLower) {
        return;
      }

      if (isApartment && isNumberOfFloorsLower) {
        return;
      }

      // Add validation of the fields
      let validationFields = [VALUATION_FORM_KEYS.BUILDING_YEAR];
      switch (watchedCode) {
        case PropertyCode.Apartment:
          validationFields = [
            ...validationFields,
            VALUATION_FORM_KEYS.LIVING_AREA,
            VALUATION_FORM_KEYS.NUMBER_OF_ROOMS,
            VALUATION_FORM_KEYS.NUMBER_OF_BATHROOMS,
            VALUATION_FORM_KEYS.BALCONY_AREA,
            VALUATION_FORM_KEYS.NUMBER_OF_INDOOR_PARKING_SPACES,
            VALUATION_FORM_KEYS.NUMBER_OF_OUTDOOR_PARKING_SPACES,
            ...(typeof formData.renovationYear !== 'undefined' &&
            !Number.isNaN(formData.renovationYear)
              ? [VALUATION_FORM_KEYS.RENOVATION_YEAR]
              : []),
            ...(typeof formData.floorNumber !== 'undefined' &&
            !Number.isNaN(formData.floorNumber)
              ? [VALUATION_FORM_KEYS.FLOOR_NUMBER]
              : []),
            ...(typeof formData.numberOfFloorsInBuilding !== 'undefined' &&
            !Number.isNaN(formData.numberOfFloorsInBuilding)
              ? [VALUATION_FORM_KEYS.NUMBER_OF_FLOORS_IN_BUILDING]
              : []),
          ];
          break;
        case PropertyCode.House:
          validationFields = [
            ...validationFields,
            VALUATION_FORM_KEYS.LIVING_AREA,
            VALUATION_FORM_KEYS.LAND_AREA,
            VALUATION_FORM_KEYS.NUMBER_OF_ROOMS,
            VALUATION_FORM_KEYS.NUMBER_OF_BATHROOMS,
            VALUATION_FORM_KEYS.BALCONY_AREA,
            VALUATION_FORM_KEYS.NUMBER_OF_INDOOR_PARKING_SPACES,
            VALUATION_FORM_KEYS.NUMBER_OF_OUTDOOR_PARKING_SPACES,
            ...(typeof formData.renovationYear !== 'undefined' &&
            !Number.isNaN(formData.renovationYear)
              ? [VALUATION_FORM_KEYS.RENOVATION_YEAR]
              : []),
            ...(typeof formData.numberOfFloorsInBuilding !== 'undefined' &&
            !Number.isNaN(formData.numberOfFloorsInBuilding)
              ? [VALUATION_FORM_KEYS.NUMBER_OF_FLOORS_IN_BUILDING]
              : []),
          ];
          break;
        case PropertyCode.MultiFamilyHouse:
          validationFields = [
            ...validationFields,
            VALUATION_FORM_KEYS.NUMBER_OF_UNITS,
            VALUATION_FORM_KEYS.LIVING_AREA,
            ...(typeof formData.landArea !== 'undefined' &&
            !Number.isNaN(formData.landArea)
              ? [VALUATION_FORM_KEYS.LAND_AREA]
              : []),
          ];
          break;
        default:
      }

      if (validationFields.length > 0) {
        const isFormValid = await methods.trigger(
          validationFields as FieldPath<IValuationForm>[]
        );
        if (!isFormValid) {
          // TODO: If need show an error message
          return;
        }
      }

      if (propertyData) {
        const {
          propertyType: { code },
          buildingYear,
          landArea,
          livingArea,
          numberOfUnits,
          location: {
            address: { postCode, street, city, houseNumber: number },
            coordinates,
          },
          numberOfBathrooms,
          numberOfRooms,
        } = propertyData;

        const input = {
          propertyData: {
            propertyType: {
              code: formData.code || code || PropertyCode.Apartment,
            },
            buildingYear: formData.buildingYear || Number(buildingYear),
            landArea: isApartment ? undefined : formData.landArea || landArea,
            numberOfUnits: !isMultiFamilyHouse
              ? undefined
              : formData.numberOfUnits || numberOfUnits,
            livingArea: formData.livingArea || livingArea,
            numberOfBathrooms: formData.numberOfBathrooms || numberOfBathrooms,
            energyLabel: formData.energyLabel,
            numberOfRooms: formData.numberOfRooms || numberOfRooms,
            balconyArea: formData.balconyArea || 0,
            floorNumber: formData.floorNumber || undefined,
            numberOfFloorsInBuilding:
              formData.numberOfFloorsInBuilding || undefined,
            renovationYear: formData.renovationYear || null,
            location: {
              address: {
                postCode: postCode || '',
                street: street || '',
                houseNumber: number || '',
                city: city || '',
              },
              coordinates: {
                latitude: coordinates.latitude || 0,
                longitude: coordinates.longitude || 0,
              },
            },
          },
        };

        if (isApartment) {
          input.propertyData = Object.assign(input.propertyData, {
            floorNumber: formData.floorNumber || 0,
            numberOfIndoorParkingSpaces:
              formData.numberOfIndoorParkingSpaces || 0,
            numberOfOutdoorParkingSpaces:
              formData.numberOfOutdoorParkingSpaces || 0,
          });
          if (formData.subcode) {
            input.propertyData.propertyType = Object.assign(
              input.propertyData.propertyType,
              {
                subcode: formData.subcode,
              }
            );
          }
        }

        if (isHouse) {
          input.propertyData = Object.assign(input.propertyData, {
            numberOfIndoorParkingSpaces:
              formData.numberOfIndoorParkingSpaces || 0,
            numberOfOutdoorParkingSpaces:
              formData.numberOfOutdoorParkingSpaces || 0,
            isNew: formData.isNew || false,
            hasPool: formData.hasPool || false,
            hasSauna: formData.hasSauna || false,
          });
          if (formData.subcode) {
            input.propertyData.propertyType = Object.assign(
              input.propertyData.propertyType,
              {
                subcode: formData.subcode,
              }
            );
          }
        }

        if (isMultiFamilyHouse) {
          input.propertyData = Object.assign(input.propertyData, {
            annualRentIncome: formData.annualRentIncome || 0,
          });
        }

        await updateProperty({
          input: {
            _id: propertyId,
            ...input,
          },
        });
        dispatch(updateFormStateAction(formData));
        closeModal();
      }
    },
    [
      watchedCode,
      isRenovationYearLower,
      isNumberOfFloorsLower,
      propertyData,
      methods,
      updateProperty,
      propertyId,
      dispatch,
      closeModal,
    ]
  );

  if (isPropertyDataLoading || isUpdatePropertyLoading) {
    return (
      <StyledFormGridWrapperFixHeight>
        <GlobalLoader />
      </StyledFormGridWrapperFixHeight>
    );
  }

  return (
    <>
      <MobileBack title={t('edit.valuation.modal.title')} />
      <FormWrapper>
        <FormProvider {...methods}>
          <FormGridOuterWrapper>
            <StyledFormGridWrapperFixHeight
              maxHeight="480px"
              wrapperRef={formGridWrapperRef}
            >
              {!isMobileSize && (
                <Blurry
                  scrolledElementRef={formGridWrapperRef}
                  isReversed
                  isInitiallyVisible={false}
                />
              )}

              <ExpandableContent
                expanded
                title={t('edit.valuation.section.title.details')}
              >
                <Row>
                  <BoxSelector
                    name={VALUATION_FORM_KEYS.CODE}
                    boxes={[
                      {
                        boxType: BoxType.iconBox,
                        title: 'valuation.form.first-step.building.apartment',
                        value: PropertyCode.Apartment,
                        icon: Buildings2,
                      },
                      {
                        boxType: BoxType.iconBox,
                        title: 'valuation.form.first-step.building.house',
                        value: PropertyCode.House,
                        icon: House,
                      },
                      {
                        boxType: BoxType.iconBox,
                        title:
                          'valuation.form.first-step.building.family-house',
                        value: PropertyCode.MultiFamilyHouse,
                        icon: HouseApartment,
                      },
                    ]}
                    defaultValue={propertyData?.propertyType?.code}
                  />
                </Row>
                <Row direction="column">
                  {[PropertyCode.Apartment, PropertyCode.House].includes(
                    watchedCode
                  ) && (
                    <SelectWithLabel
                      name={VALUATION_FORM_KEYS.SUBCODE}
                      label={'valuation.form.subtype.label'}
                      defaultValue={propertyData?.propertyType?.subcode || ''}
                      selectedOption={selectedSubcodeTitle}
                      innerLabel={
                        selectedSubcodeTitle !== ''
                          ? ''
                          : t('valuation.form.subtype.placeholder')
                      }
                      options={
                        watchedCode === PropertyCode.Apartment
                          ? APARTMENT_SUBTYPES
                          : HOUSE_SUBTYPES
                      }
                    />
                  )}

                  <Input
                    name={VALUATION_FORM_KEYS.ADDRESS}
                    defaultValue={`${propertyData?.location?.address?.street} ${propertyData?.location?.address?.houseNumber}`}
                    placeholder="valuation.form.first-step.address-placeholder"
                    label="valuation.form.first-step.address"
                    rules={{
                      required: 'register.input.error.required',
                    }}
                    isDisabled
                  />
                  <Input
                    name={VALUATION_FORM_KEYS.CITY}
                    defaultValue={propertyData?.location?.address?.city}
                    placeholder="valuation.form.first-step.city-placeholder"
                    label="valuation.form.first-step.city"
                    rules={{
                      required: 'register.input.error.required',
                    }}
                    isDisabled
                  />
                  <Input
                    name={VALUATION_FORM_KEYS.BUILDING_YEAR}
                    defaultValue={propertyData?.buildingYear}
                    placeholder="valuation.form.first-step.building-year-placeholder"
                    label="valuation.form.first-step.building-year"
                    type="number"
                    rules={{
                      required: 'register.input.error.required',
                      min: {
                        value: 1850,
                        message:
                          'valuation.form.first-step.building-year-min-error',
                      },
                      max: {
                        value: new Date().getFullYear() + 3,
                        message:
                          'valuation.form.first-step.building-year-max-error',
                      },
                      valueAsNumber: true,
                      setValueAs: (v) => Number.parseInt(v, 10),
                    }}
                  />
                  {watchedCode !== PropertyCode.MultiFamilyHouse && (
                    <>
                      <Input
                        name={VALUATION_FORM_KEYS.RENOVATION_YEAR}
                        defaultValue={propertyData?.renovationYear ?? ''}
                        placeholder="valuation.form.first-step.renovation-year-placeholder"
                        label="valuation.form.first-step.renovation-year"
                        type="number"
                        rules={{
                          // required: 'register.input.error.required',
                          min: {
                            value: 1950,
                            message:
                              'valuation.form.first-step.renovation-year-min-error',
                          },
                          max: {
                            value: new Date().getFullYear() + 3,
                            message:
                              'valuation.form.first-step.renovation-year-max-error',
                          },
                          // value: propertyData?.renovationYear,
                          valueAsNumber: true,
                          setValueAs: (v) => Number.parseInt(v, 10),
                        }}
                      />
                      {isRenovationYearLower === true && (
                        <GlobalError
                          title={t(
                            'valuation.form.first-step.renovation-year-is-lower-than-building-year'
                          )}
                          topMargin
                        />
                      )}
                    </>
                  )}
                </Row>
                <Row direction="column" marginBottom={16}>
                  {watchedCode === PropertyCode.MultiFamilyHouse && (
                    <SliderInput
                      hasMarginTop
                      name={VALUATION_FORM_KEYS.NUMBER_OF_UNITS}
                      defaultValue={propertyData?.numberOfUnits ?? 3}
                      label={t(
                        'valuation.form.second-step.number-of-units-label'
                      )}
                      rules={{
                        required: 'register.input.error.required',
                        min: {
                          value: 3,
                          message:
                            'valuation.form.second-step.number-of-units-min-error',
                        },
                        max: {
                          value: 25,
                          message:
                            'valuation.form.second-step.number-of-units-max-error',
                        },
                        valueAsNumber: true,
                        value: propertyData?.numberOfUnits,
                        validate: {
                          min: (v) =>
                            v >= 3 ||
                            'valuation.form.second-step.number-of-units-min-error',
                          max: (v) =>
                            v <= 25 ||
                            'valuation.form.second-step.number-of-units-max-error',
                        },
                        setValueAs: (v) => Number.parseInt(v, 10),
                      }}
                    />
                  )}
                  <SliderInput
                    hasMarginTop
                    hasMeasurement
                    name={VALUATION_FORM_KEYS.LIVING_AREA}
                    defaultValue={propertyData?.livingArea}
                    label={t('valuation.form.second-step.living-area-label')}
                    rules={{
                      required: 'register.input.error.required',
                      min: {
                        value: 10,
                        message:
                          'valuation.form.second-step.living-area-min-error',
                      },
                      max: {
                        value:
                          watchedCode === PropertyCode.MultiFamilyHouse
                            ? 5000
                            : 800,
                        message:
                          watchedCode === PropertyCode.MultiFamilyHouse
                            ? 'valuation.form.second-step.living-area-max-error-multi-family'
                            : 'valuation.form.second-step.living-area-max-error',
                      },
                      valueAsNumber: true,
                      value: propertyData?.livingArea,
                      validate: {
                        min: (v) =>
                          v >= 10 ||
                          'valuation.form.second-step.living-area-min-error',
                        max: (v) =>
                          v <=
                            (watchedCode === PropertyCode.MultiFamilyHouse
                              ? 5000
                              : 800) ||
                          'valuation.form.second-step.living-area-max-error',
                      },
                      setValueAs: (v) => Number.parseInt(v, 10),
                    }}
                  />
                  {watchedCode === PropertyCode.Apartment && (
                    <>
                      <SliderInput
                        name={VALUATION_FORM_KEYS.FLOOR_NUMBER}
                        defaultValue={propertyData?.floorNumber ?? 1}
                        label={t(
                          'valuation.form.second-step.floor-number-label'
                        )}
                        rules={{
                          required: 'register.input.error.required',
                          min: {
                            value: 0,
                            message:
                              'valuation.form.second-step.floor-number-min-error',
                          },
                          max: {
                            value: 20,
                            message:
                              'valuation.form.second-step.floor-number-max-error',
                          },
                          valueAsNumber: true,
                          value: propertyData?.floorNumber ?? 0,
                          validate: {
                            min: (v) =>
                              v >= 0 ||
                              'valuation.form.second-step.floor-number-min-error',
                            max: (v) =>
                              v <= 20 ||
                              'valuation.form.second-step.floor-number-max-error',
                          },
                          setValueAs: (v) => Number.parseInt(v, 10),
                        }}
                      />
                      {isNumberOfFloorsLower &&
                        !Number.isNaN(isNumberOfFloorsLower) && (
                          <GlobalError
                            title={t(
                              'valuation.form.second-step.numberoffloors-is-lower-than-floornumber'
                            )}
                            topMargin
                          />
                        )}
                    </>
                  )}
                  {watchedCode !== PropertyCode.Apartment && (
                    <SliderInput
                      hasMeasurement
                      name={VALUATION_FORM_KEYS.LAND_AREA}
                      defaultValue={propertyData?.landArea ?? 0}
                      label={t('valuation.form.second-step.land-area-label')}
                      rules={{
                        required: 'register.input.error.required',
                        min: {
                          value:
                            watchedCode === PropertyCode.MultiFamilyHouse
                              ? 0
                              : 50,
                          message:
                            watchedCode === PropertyCode.MultiFamilyHouse
                              ? 'valuation.form.second-step.land-area-multifamilyhouse-min-error'
                              : 'valuation.form.second-step.land-area-min-error',
                        },
                        max: {
                          value: 5000,
                          message:
                            'valuation.form.second-step.land-area-max-error',
                        },
                        valueAsNumber: true,
                        value: propertyData?.landArea,
                        validate: {
                          min: (v) =>
                            v >=
                              (watchedCode === PropertyCode.MultiFamilyHouse
                                ? 0
                                : 50) ||
                            'valuation.form.second-step.land-area-min-error',
                          max: (v) =>
                            v <= 5000 ||
                            'valuation.form.second-step.land-area-max-error',
                        },
                        setValueAs: (v) => Number.parseInt(v, 10),
                      }}
                    />
                  )}
                  {watchedCode !== PropertyCode.MultiFamilyHouse && (
                    <SliderInput
                      name={VALUATION_FORM_KEYS.NUMBER_OF_FLOORS_IN_BUILDING}
                      defaultValue={propertyData?.numberOfFloorsInBuilding ?? 1}
                      label={t(
                        'valuation.form.second-step.number-of-floors-in-building-label'
                      )}
                      rules={{
                        required: 'register.input.error.required',
                        min: {
                          value: 1,
                          message:
                            'valuation.form.second-step.number-of-floors-in-building-min-error',
                        },
                        max: {
                          value: 20,
                          message:
                            'valuation.form.second-step.number-of-floors-in-building-max-error',
                        },
                        valueAsNumber: true,
                        // value: propertyData?.numberOfFloorsInBuilding ?? 1,
                        validate: {
                          min: (v) =>
                            v >= 1 ||
                            'valuation.form.second-step.number-of-floors-in-building-min-error',
                          max: (v) =>
                            v <= 20 ||
                            'valuation.form.second-step.number-of-floors-in-building-max-error',
                        },
                        setValueAs: (v) => Number.parseInt(v, 10),
                      }}
                    />
                  )}
                  {watchedCode === PropertyCode.MultiFamilyHouse && (
                    <SliderInput
                      rentSymbol={'eur'}
                      name={VALUATION_FORM_KEYS.ANNUAL_RENT_INCOME}
                      label={t(
                        'valuation.form.second-step.annual-rent-income-label'
                      )}
                      rules={{
                        required: 'register.input.error.required',
                        min: {
                          value: 5000,
                          message:
                            'valuation.form.second-step.annual-rent-income-min-error',
                        },
                        max: {
                          value: 1_000_000,
                          message:
                            'valuation.form.second-step.annual-rent-income-max-error',
                        },
                        valueAsNumber: true,
                        value: propertyData?.annualRentIncome || 0,
                        validate: {
                          min: (v) =>
                            v === 0 ||
                            v >= 5000 ||
                            'valuation.form.second-step.annual-rent-income-min-error',
                          max: (v) =>
                            v <= 1_000_000 ||
                            'valuation.form.second-step.annual-rent-income-max-error',
                        },
                        setValueAs: (v) => Number.parseInt(v, 10),
                      }}
                    />
                  )}
                </Row>
                {!isAustria && (
                  <SelectV2
                    name={VALUATION_FORM_KEYS.ENERGY_LABEL}
                    label={t(
                      'property-valuation.form.redesign.energy-label.label'
                    )}
                    placeholder={t(
                      'property-valuation.form.redesign.energy-label.placeholder'
                    )}
                    isPlaceholderCenter
                    isOptionsAtTop
                    defaultValue={propertyData?.energyLabel ?? ''}
                    selectOptions={
                      isSwitzerland
                        ? energyLabelOptionsSwitzerland
                        : energyLabelOptions
                    }
                  />
                )}
              </ExpandableContent>

              <ExpandleContentSplitter />

              {watchedCode !== PropertyCode.MultiFamilyHouse && (
                <ExpandableContent
                  title={t('edit.valuation.section.title.rooms_and_parking')}
                  expanded
                >
                  <Row direction="column">
                    <SliderInput
                      name={VALUATION_FORM_KEYS.NUMBER_OF_ROOMS}
                      label={t('valuation.form.fourth-step.rooms')}
                      rules={{
                        required: 'register.input.error.required',
                        min: {
                          value: 1,
                          message:
                            'valuation.form.fourth-step.number-of-rooms-min-error',
                        },
                        max: {
                          value: 20,
                          message:
                            'valuation.form.fourth-step.number-of-rooms-max-error',
                        },
                        valueAsNumber: true,
                        value: propertyData?.numberOfRooms,
                        validate: {
                          min: (v) =>
                            v >= 1 ||
                            'valuation.form.fourth-step.number-of-rooms-min-error',
                          max: (v) =>
                            v <= 20 ||
                            'valuation.form.fourth-step.number-of-rooms-max-error',
                        },
                        setValueAs: (v) => Number.parseInt(v, 10),
                      }}
                    />
                    <SliderInput
                      name={VALUATION_FORM_KEYS.NUMBER_OF_BATHROOMS}
                      label={t('valuation.form.fourth-step.bathrooms')}
                      rules={{
                        required: 'register.input.error.required',
                        min: {
                          value: 1,
                          message:
                            'valuation.form.fourth-step.number-of-bathrooms-min-error',
                        },
                        max: {
                          value: 5,
                          message:
                            'valuation.form.fourth-step.number-of-bathrooms-max-error',
                        },
                        valueAsNumber: true,
                        value: propertyData?.numberOfBathrooms,
                        validate: {
                          min: (v) =>
                            v >= 1 ||
                            'valuation.form.fourth-step.number-of-bathrooms-min-error',
                          max: (v) =>
                            v <= 5 ||
                            'valuation.form.fourth-step.number-of-bathrooms-max-error',
                        },
                        setValueAs: (v) => Number.parseInt(v, 10),
                      }}
                    />
                    <SliderInput
                      hasMeasurement
                      name={VALUATION_FORM_KEYS.BALCONY_AREA}
                      label={t('valuation.form.fourth-step.balcony-area')}
                      tooltipKey="valuation.form.first-step.balcony-area-tooltip"
                      rules={{
                        required: 'register.input.error.required',
                        min: {
                          value: 0,
                          message:
                            'valuation.form.fourth-step.balcony-area-min-error',
                        },
                        max: {
                          value: 200,
                          message:
                            'valuation.form.fourth-step.balcony-area-max-error',
                        },
                        valueAsNumber: true,
                        value: propertyData?.balconyArea,
                        validate: {
                          min: (v) =>
                            v >= 0 ||
                            'valuation.form.fourth-step.balcony-area-min-error',
                          max: (v) =>
                            v <= 200 ||
                            'valuation.form.fourth-step.balcony-area-max-error',
                        },
                        setValueAs: (v) => Number.parseInt(v, 10),
                      }}
                    />
                    <SliderInput
                      name={VALUATION_FORM_KEYS.NUMBER_OF_INDOOR_PARKING_SPACES}
                      label={t(
                        'valuation.form.fourth-step.garage-parking-spaces'
                      )}
                      rules={{
                        required: 'register.input.error.required',
                        min: {
                          value: 0,
                          message:
                            'valuation.form.fourth-step.number-of-indoor-parking-spaces-min-error',
                        },
                        max: {
                          value: 6,
                          message:
                            'valuation.form.fourth-step.number-of-indoor-parking-spaces-max-error',
                        },
                        valueAsNumber: true,
                        value: propertyData?.numberOfIndoorParkingSpaces || 0,
                        validate: {
                          min: (v) =>
                            v >= 0 ||
                            'valuation.form.fourth-step.number-of-indoor-parking-spaces-min-error',
                          max: (v) =>
                            v <= 6 ||
                            'valuation.form.fourth-step.number-of-indoor-parking-spaces-max-error',
                        },
                        setValueAs: (v) => Number.parseInt(v, 10),
                      }}
                    />
                    <SliderInput
                      name={
                        VALUATION_FORM_KEYS.NUMBER_OF_OUTDOOR_PARKING_SPACES
                      }
                      label={t('valuation.form.fourth-step.parking-spaces')}
                      rules={{
                        required: 'register.input.error.required',
                        min: {
                          value: 0,
                          message:
                            'valuation.form.fourth-step.number-of-outdoor-parking-spaces-min-error',
                        },
                        max: {
                          value: 6,
                          message:
                            'valuation.form.fourth-step.number-of-outdoor-parking-spaces-max-error',
                        },
                        valueAsNumber: true,
                        value: propertyData?.numberOfOutdoorParkingSpaces || 0,
                        validate: {
                          min: (v) =>
                            v >= 0 ||
                            'valuation.form.fourth-step.number-of-outdoor-parking-spaces-min-error',
                          max: (v) =>
                            v <= 6 ||
                            'valuation.form.fourth-step.number-of-outdoor-parking-spaces-max-error',
                        },
                        setValueAs: (v) => Number.parseInt(v, 10),
                      }}
                    />
                  </Row>
                  {watchedCode === PropertyCode.House && (
                    <CheckboxRow>
                      <CheckboxItem
                        name={VALUATION_FORM_KEYS.IS_NEW}
                        label={'valuation.form.fourth-step.is-new'}
                        rules={{
                          value: propertyData?.isNew,
                        }}
                      />
                      <CheckboxItem
                        name={VALUATION_FORM_KEYS.HAS_POOL}
                        label={'valuation.form.fourth-step.has-pool'}
                        rules={{
                          value: propertyData?.hasPool,
                        }}
                      />
                      <CheckboxItem
                        name={VALUATION_FORM_KEYS.HAS_SAUNA}
                        label={'valuation.form.fourth-step.has-sauna'}
                        rules={{
                          value: propertyData?.hasSauna,
                        }}
                      />
                    </CheckboxRow>
                  )}
                </ExpandableContent>
              )}
              {!isMobileSize && (
                <Blurry
                  scrolledElementRef={formGridWrapperRef}
                  position={{ bottom: '0' }}
                />
              )}
            </StyledFormGridWrapperFixHeight>
          </FormGridOuterWrapper>

          <FormButtonsWrapper>
            <SecondaryButton
              label={t('modal.edit-valuation-modal.button-close')}
              onClick={closeModal}
            />
            <MainButton
              label={t('modal.edit-valuation-modal.button-save')}
              onClick={methods.handleSubmit(onSubmit)}
            />
          </FormButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </>
  );
};

export { EditValuationForm };
