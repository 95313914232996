import styled from 'styled-components';
import { device } from '../../../../../../../style/theme';
import Icon from '../../../../../../common/components/ui/icon';
import { Headline2 } from '../../../../../../common/components/ui/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 152px 96px 76px 96px;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  @media ${device.tabletSlider} {
    padding: 16px;
    align-items: center;
  }
`;

export const SlideIconBox = styled.div`
  display: none;

  @media ${device.tabletSlider} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background: #fff;
    border-radius: 100%;
    margin-bottom: 16px;
  }
`;

export const SlideIcon = styled.img`
  display: none;

  @media ${device.tabletSlider} {
    display: block;
    width: 50%;
    height: 50%;
  }
`;

export const Title = styled(Headline2)`
  margin: 0 0 16px 0;
  color: #ffffff;

  @media ${device.tabletSlider} {
    text-align: center;
  }
`;

export const Description = styled.p`
  margin: 0 0 16px 0;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: #ffffff;
`;

export const LinkIconWrapper = styled(Icon)`
  margin-right: 8px;
`;

export const ContentBox = styled.div`
  height: 232px;
  overflow: auto;

  @media ${device.tabletSlider} {
    height: auto;
  }
`;

export const CTAButtonWrapper = styled.div`
  @media ${device.tabletSlider} {
    button {
      width: 100%;
      justify-content: center;
    }
  }
`;
