import styled from 'styled-components';
import { device } from '../../../../../../../style/theme';

export const InfoWrapper = styled.div`
  padding: 36px;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

export const Wrapper = styled.div`
  margin: 32px 64px;
  background-color: ${({ theme }) => theme.white};
  width: 624px;
  overflow-y: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
  max-height: 1050px;
`;

export const Header = styled.div`
  padding: 8px 32px;
  font-family: Source Serif Pro, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.blue};
`;

export const MapContainer = styled.div`
  height: 184px;
  min-height: 184px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const Row = styled.div`
  display: flex;
`;

export const Info = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-top: 6px;
  cursor: pointer;
  .icon {
    > svg,
    > svg > path {
      stroke: ${(props) => props.theme.blue};
    }
  }

  @media ${device.tablet} {
    margin-right: 0;
  }
`;

export const ProvenExpertContainer = styled.div`
  position: absolute;
  top: 155px;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ProvenExpertImage = styled.img<{ marginLeft?: number }>`
  margin-left: ${({ marginLeft }) => marginLeft || 624}px;
`;
