import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SuggestedProperty,
  SuggestedPropertiesFilterInput,
  OrderSuggestedPropertiesBy,
  Direction,
} from '../../../generated';

interface InitialState {
  filters: SuggestedPropertiesFilterInput | unknown;
  favouriteProperties: SuggestedProperty[];
  totalItems: number;
  initialLoader: boolean;
}

export const filtersInitialState: SuggestedPropertiesFilterInput = {
  offset: 0,
  limit: 8,
  orderBy: {
    field: OrderSuggestedPropertiesBy.Proximity,
    direction: Direction.Asc,
    proximityParams: { latitude: 0, longitude: 0 },
  },
};

const initialState: InitialState = {
  filters: {},
  favouriteProperties: [],
  totalItems: 0,
  initialLoader: true,
};

export const favouritePropertiesSlice = createSlice({
  name: 'favouriteProperties',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<SuggestedPropertiesFilterInput>) {
      state.filters = action.payload;
    },
    mergeFilters(
      state,
      action: PayloadAction<Partial<SuggestedPropertiesFilterInput>>
    ) {
      state.filters = {
        ...(state.filters as SuggestedPropertiesFilterInput),
        ...action.payload,
      };
    },
    clearFavouritePropertiesCache(state) {
      state.favouriteProperties = [];
    },
    addFavouriteProperties(state, action: PayloadAction<SuggestedProperty[]>) {
      state.favouriteProperties = [
        ...state.favouriteProperties,
        ...action.payload,
      ];
    },
    setTotalItems(state, action: PayloadAction<number>) {
      state.totalItems = action.payload;
    },
    setInitialLoader(state, action: PayloadAction<boolean>) {
      state.initialLoader = action.payload;
    },
    removeFromFavourites(state, action: PayloadAction<string>) {
      state.favouriteProperties = state.favouriteProperties.filter(
        (property) => property.externalId !== action.payload
      );
      state.totalItems -= 1;
    },
  },
});

export const {
  setFilters,
  mergeFilters,
  clearFavouritePropertiesCache,
  addFavouriteProperties,
  setTotalItems,
  setInitialLoader,
  removeFromFavourites,
} = favouritePropertiesSlice.actions;

export default favouritePropertiesSlice.reducer;
