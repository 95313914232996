import { FC } from 'react';

interface IProps {
  width: string;
}

export const Logo: FC<IProps> = ({ width }) => {
  return (
    <>
      <svg
        width={width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 462.8 132.9"
      >
        <path d="M.6.6h461.6v131.9H.6z" fill="#fff" />
        <path d="M11.5 11.5h109.8v109.8H11.5z" fill="#002849" />
        <defs>
          <path id="A" d="M0 0h462.8v132.8H0z" />
        </defs>
        <clipPath id="B">
          <use xlinkHref="#A" />
        </clipPath>
        <path
          d="M131.5 10.9v111M.3.3h462.2v132.2H.3V.3z"
          clipPath="url(#B)"
          fill="none"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="3.864"
        />
        <path
          d="M192.9 87.3h2.7v18.3h-2.7zm32.1 18.3V90.7l-5.9 14.9h-2.4l-5.8-14.9v14.9h-2.5V87.3h3.7l5.9 15 5.9-15h3.7v18.3zm32 0V90.7l-5.9 14.9h-2.4l-5.8-14.9v14.9h-2.6V87.3h3.8l5.9 15 5.8-15h3.8v18.3z"
          fill="#a8a8a7"
        />
        <defs>
          <path id="C" d="M0 0h462.8v132.8H0z" />
        </defs>
        <clipPath id="D">
          <use xlinkHref="#C" />
        </clipPath>
        <path
          d="M277.8 89.3c-3.8 0-5.5 3.6-5.5 7.1s1.7 7.1 5.5 7.1c3.9 0 5.5-3.6 5.5-7.1s-1.6-7.1-5.5-7.1m0 16.6c-5.7 0-8.3-4.3-8.3-9.5 0-5 2.7-9.4 8.3-9.4 5.7 0 8.3 4.3 8.3 9.4s-2.7 9.5-8.3 9.5"
          clipPath="url(#D)"
          fill="#a8a8a7"
        />
        <defs>
          <path id="E" d="M0 0h462.8v132.8H0z" />
        </defs>
        <clipPath id="F">
          <use xlinkHref="#E" />
        </clipPath>
        <g fill="#a8a8a7">
          <path
            d="M299.8 97.2h-1.3v6.3h1.6c2.3 0 4.5-.6 4.5-3.1 0-2.7-2.4-3.2-4.8-3.2m.8-7.7h-2.2v5.6h1.7c2.1 0 3.9-.7 3.9-3 .1-2-1.5-2.6-3.4-2.6m-.5 16.1h-4.3V87.3h5c2.9 0 6 1.1 6 4.7 0 2-1 3.4-2.7 4 1.9.6 3.2 2.1 3.2 4.3 0 4.3-3.7 5.3-7.2 5.3"
            clipPath="url(#F)"
          />
          <path d="M318.4 87.3h2.7v18.3h-2.7zm14.5 18.3V87.3h2.6v16h7.2v2.3zm17.8-18.3h2.7v18.3h-2.7zm14 18.3V87.3h9.7v2.3h-7.1v5.5h6.8v2.2h-6.8v6.1h7.4v2.2zm30 0l-8.1-14.8v14.8h-2.5V87.3h3.4l8.1 14.8V87.3h2.5v18.3z" />
        </g>
        <defs>
          <path id="G" d="M0 0h462.8v132.8H0z" />
        </defs>
        <clipPath id="H">
          <use xlinkHref="#G" />
        </clipPath>
        <path
          d="M69.2 90.6v-1.1c2.9-.6 3.2-5.1 3.4-7.3.1-1.1.1-2.3.1-3.7V52.3c0-1.2 0-2.2-.3-3.5h7.4v17.3h6.9c6.9 0 10.1-5.7 10.1-11.6 0-5.8-3.4-11.3-10.2-11.7 0 0-9.9-.5-14.9.1-2.8.4-3.9 2-5 4.3-.4.9-.9 1.9-1.4 3.1L46.9 92.6 30 55.1c-.6-1.4-2.1-4.2-3.1-4.8-.6-.4-1.3-.6-1.9-.6v-.9h13.7v1c-.9.2-1.6.6-1.8 1.6-.3 1.5.8 4 1.5 5.4l10.3 23.1 13.7-31.8c.3-.6.6-1.4.9-2.3.4-1.2.7-2.5.6-3.2-.2-.9-.9-1.4-1.8-1.6v-1h24.7c11.5 0 17.9 6.7 17.9 14.6 0 7.8-6.4 14.1-17.9 14.1h-7v9.9c0 1.4 0 2.6.1 3.6.1 1.6.3 4 1.2 5.7l.3.5c.5.6 1.1 1.1 1.9 1.3v1.1H69.2v-.2z"
          clipPath="url(#H)"
          fill="#fff"
        />
        <defs>
          <path id="I" d="M0 0h462.8v132.8H0z" />
        </defs>
        <clipPath id="J">
          <use xlinkHref="#I" />
        </clipPath>
        <path
          d="M212.1 35c-9 0-11.6 8.1-11.6 14.6s2.6 14.6 11.6 14.6c9.1 0 11.7-8 11.7-14.6 0-6.5-2.6-14.6-11.7-14.6m17.8 14.6c0 9.2-8 16.8-17.7 16.8s-17.7-7.6-17.7-16.8 8-16.8 17.7-16.8 17.7 7.6 17.7 16.8"
          clipPath="url(#J)"
          fill="#002849"
        />
        <defs>
          <path id="K" d="M0 0h462.8v132.8H0z" />
        </defs>
        <clipPath id="L">
          <use xlinkHref="#K" />
        </clipPath>
        <path
          d="M191.2 34.9c-2.2 0-3.4.2-4.2.9-.4.3-.7.8-1 1.4l-1.1 2.3-11.7 26.9h-.7l-11.7-26.9c-.4-.9-.7-1.7-1.1-2.3-.3-.6-.6-1.1-1-1.4-.8-.7-2-.9-4.2-.9v-1.2h15.7v1.2c-4.4 0-5.3 1.1-4.1 4l8.3 18.9 8.2-18.9c1.2-2.8.3-4-4.1-4v-1.2h12.7v1.2z"
          clipPath="url(#L)"
          fill="#002849"
        />
        <defs>
          <path id="M" d="M0 0h462.8v132.8H0z" />
        </defs>
        <clipPath id="N">
          <use xlinkHref="#M" />
        </clipPath>
        <path
          d="M267 57.2V41c0-5.3-.6-6.1-4.9-6.1v-1.2h12.5v1.2c-4.3 0-5 .8-5 6.1v25.4H268l-21.4-26.5v18.2c0 5.3.7 6.2 5 6.2v1.2h-12.5v-1.2c4.3 0 5-.9 5-6.2V41c0-5.3-.6-6.1-5-6.1v-1.2h9L267 57.2z"
          clipPath="url(#N)"
          fill="#002849"
        />
        <defs>
          <path id="O" d="M0 0h462.8v132.8H0z" />
        </defs>
        <clipPath id="P">
          <use xlinkHref="#O" />
        </clipPath>
        <path
          d="M310.8 49.6c4.6 0 6.7-3.1 6.7-6.9s-2.1-6.9-6.7-6.9h-3.7v13.8c0 0 3.7 0 3.7 0zm.9-15.9c7.4 0 11.4 4 11.4 9s-4.1 9-11.4 9h-4.6v6.5c0 5.3.7 6.2 5 6.2v1.2h-15.2v-1.2c4.3 0 5-.9 5-6.2V41c0-5.3-.6-6.1-5-6.1v-1.2h14.8z"
          clipPath="url(#P)"
          fill="#002849"
        />
        <defs>
          <path id="Q" d="M0 0h462.8v132.8H0z" />
        </defs>
        <clipPath id="R">
          <use xlinkHref="#Q" />
        </clipPath>
        <path
          d="M348.2 35c-9 0-11.6 8.1-11.6 14.6s2.6 14.6 11.6 14.6c9.1 0 11.7-8 11.7-14.6 0-6.5-2.6-14.6-11.7-14.6M366 49.6c0 9.2-8 16.8-17.7 16.8s-17.7-7.6-17.7-16.8 8-16.8 17.7-16.8S366 40.4 366 49.6"
          clipPath="url(#R)"
          fill="#002849"
        />
        <defs>
          <path id="S" d="M0 0h462.8v132.8H0z" />
        </defs>
        <clipPath id="T">
          <use xlinkHref="#S" />
        </clipPath>
        <path
          d="M408.5 65.5v-1.2c4.3 0 5-.9 5-6.2V41c0-5.3-.6-6.1-5-6.1v-1.2h15.3v1.2c-4.3 0-5 .8-5 6.1v16.4c0 5.3.6 6.1 5 6.1h5.3c4.8 0 5.3-1.2 6-4l.3-1.1h1.2l-.5 7.1h-27.6z"
          clipPath="url(#T)"
          fill="#002849"
        />
        <defs>
          <path id="U" d="M0 0h462.8v132.8H0z" />
        </defs>
        <clipPath id="V">
          <use xlinkHref="#U" />
        </clipPath>
        <path
          d="M374.7 65.5v-1.2c4.3 0 5-.9 5-6.2V41c0-5.3-.6-6.1-5-6.1v-1.2H390v1.2c-4.3 0-5 .8-5 6.1v16.4c0 5.3.6 6.1 5 6.1h5.3c4.8 0 5.3-1.2 6-4l.3-1.1h1.2l-.5 7.1h-27.6z"
          clipPath="url(#V)"
          fill="#002849"
        />
      </svg>
    </>
  );
};
