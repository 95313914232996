import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-radius: 3px;
  border: solid 1px #a8a8a7;
  border-collapse: collapse;

  td,
  tr {
    border-radius: 3px;
    border: solid 1px #a8a8a7;
    border-collapse: collapse;
  }
`;

export const TableTd = styled.td<{ isBold?: boolean; isValue?: boolean }>`
  width: 50%;
  padding: 13px 12px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  text-align: ${({ isValue }) => (isValue ? 'right' : 'left')};
`;
