import { FC } from 'react';
import {
  OverviewLpSection,
  Testimonials as TestimonialsType,
  useReadTestimonialsListQuery,
} from '../../../../../generated';
import {
  TestimonialsContainer,
  TestimonialsGrid,
  Header,
} from './testimonials-styles';
import { TestimonialCard } from './testimonial-card';

interface IProps {
  section: OverviewLpSection;
}

interface TestimonialsListResponse {
  data: { data: TestimonialsType[] };
  isLoading: boolean;
}

const Testimonials: FC<IProps> = ({ section }) => {
  const { recordIds, title } = section;

  const { data, isLoading } =
    useReadTestimonialsListQuery<TestimonialsListResponse>({
      ids: recordIds,
    });

  const sections = data?.data || [];

  return (
    <TestimonialsContainer>
      <Header content={title} />
      <TestimonialsGrid>
        {isLoading
          ? null
          : sections.map((sectionData, index) => (
              <TestimonialCard
                key={sectionData._id}
                section={sectionData}
                rating={index === 0 ? 4 : 5}
              />
            ))}
      </TestimonialsGrid>
    </TestimonialsContainer>
  );
};

export { Testimonials };
