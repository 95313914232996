import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { isEqual, pickBy, isString } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  CardHeader,
  Container,
  Description,
  Form,
  ServicesList,
  Subtitle,
  Title,
} from './services-container-styles';

import {
  BrokerSettingsServices,
  linkServiceExamples,
  overlayServiceExamples,
  SERVICE_FORM_KEYS,
  ServicePackage,
  services,
} from '../../../constants';
import { IBrokerSettingsForm, IServiceForm } from '../../../interfaces';

import {
  BrokerPermissions,
  OnOfficeServices,
  ServicesInput,
  useSetDisabledShopServicesMutation,
} from '../../../../../generated';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import { setIsGalleryOpen } from '../../../pages/expose-details-overlay/redux/exposeSlice';
import { toggleServiceExampleOpen } from '../../../redux/sellerPropertiesSlice';
import { useAppSelector } from '../../../../common/hooks';
import { ServiceOverlay } from '../../services/service-overlay/service-overlay';
import { ServiceCard } from '../../services/service-card/service-card';
import { SubmitButton } from '../../services/services-container/services-container-styles';

interface IProps {
  preloadedServices?: IBrokerSettingsForm | null;
  brokerPermissions?: BrokerPermissions | null;
}

const BrokerServicesContainerBase: FC<IProps> = ({
  preloadedServices,
  brokerPermissions,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { propertyId } = useParams<{ propertyId: string }>();
  const [serviceExample, setServiceExample] = useState<string[]>([]);
  const serviceExampleRef = useRef<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const urlParams = new URLSearchParams(window.location.search);
  const brokerId = urlParams.get('userid') || '';

  const isExampleOpen = useAppSelector(
    (state) => state.sellerProperties.isServiceExampleOpen
  );

  const methods = useForm<IBrokerSettingsForm>({
    mode: 'onSubmit',
    defaultValues: {
      ...preloadedServices,
    },
  });

  const [setShopAvailableServices, { error: setShopAvailableServicesError }] =
    useSetDisabledShopServicesMutation();

  const onSubmit: SubmitHandler<IBrokerSettingsForm> = useCallback(
    async (formData: IBrokerSettingsForm) => {
      try {
        const { ...selectedServices } = formData;
        if (!isEqual(selectedServices, preloadedServices)) {
          const shopServices = Object.entries(selectedServices)
            .filter(([key, value]) => key.includes('Shop') && !value)
            .map(([key, value]) => key.replace('Shop', ''));

          const propertyServices = Object.entries(selectedServices)
            .filter(([key, value]) => key.includes('Property') && !value)
            .map(([key, value]) => key.replace('Property', ''));

          await setShopAvailableServices({
            leaderId: brokerId,
            disabledServices: shopServices,
            propertyId,
            disabledPropertyServices: propertyServices,
          }).unwrap();
          setIsChanged(false);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [preloadedServices, setShopAvailableServices, brokerId, propertyId]
  );

  const onShowExample = useCallback(
    (id: OnOfficeServices) => {
      serviceExampleRef.current = true;
      dispatch(toggleServiceExampleOpen(true));
      const service = services.find((item) => item.id === id);
      if (
        service?.example &&
        Array.isArray(service?.example) &&
        overlayServiceExamples.includes(id)
      ) {
        setServiceExample(service.example);
        dispatch(setIsGalleryOpen(true));
      }

      if (
        service?.example &&
        isString(service?.example) &&
        linkServiceExamples.includes(id)
      ) {
        window.open(service.example, '_blank');
      }
    },
    [dispatch]
  );

  useEffect(() => {
    serviceExampleRef.current = isExampleOpen;
  }, [isExampleOpen]);

  return (
    <FormProvider {...methods}>
      {setShopAvailableServicesError?.message && (
        <GlobalError
          title={t(setShopAvailableServicesError?.message?.split(':')[0] ?? '')}
        />
      )}
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container>
          <CardHeader>
            <Title content={t('seller-portal.broker-view.settings.title')} />
          </CardHeader>
          <Description
            content={t('seller-portal.broker-view.settings.description')}
          />

          <ServicesList>
            {services
              .filter((service) => service.canBeDisabledByShopLeader)
              .map(
                ({
                  id,
                  title,
                  description,
                  imageSrc,
                  checkboxLabel,
                  buttonLabel,
                  isButtonFilled,
                  name,
                  brokerSettingsPropertyCheckboxName,
                  brokerSettingsShopCheckboxName,
                }) => (
                  <ServiceCard
                    key={id}
                    id={id}
                    title={t(title)}
                    description={t(description)}
                    imageSrc={imageSrc}
                    checkboxLabel={
                      methods.getValues(
                        brokerSettingsPropertyCheckboxName as BrokerSettingsServices
                      )
                        ? t(
                            'seller-portal.broker-view.settings.deactivate-service'
                          )
                        : t(
                            'seller-portal.broker-view.settings.activate-service'
                          )
                    }
                    settingsLabel={
                      methods.getValues(
                        brokerSettingsShopCheckboxName as BrokerSettingsServices
                      )
                        ? t(
                            'seller-portal.broker-view.settings.deactivate-shop-service'
                          )
                        : t(
                            'seller-portal.broker-view.settings.activate-shop-service'
                          )
                    }
                    buttonLabel={t(buttonLabel ?? '')}
                    isButtonFilled={isButtonFilled}
                    onChange={() => setIsChanged(true)}
                    name={brokerSettingsPropertyCheckboxName ?? name}
                    settingsName={brokerSettingsShopCheckboxName}
                    isPropertyServiceSelected={methods.getValues(
                      (brokerSettingsPropertyCheckboxName as BrokerSettingsServices) ??
                        'PremiumPlacementProperty'
                    )}
                    isShopServiceSelected={methods.getValues(
                      (brokerSettingsShopCheckboxName as BrokerSettingsServices) ??
                        'PremiumPlacementShop'
                    )}
                    onShowExample={onShowExample}
                    isPropertyServiceDisabled={
                      !brokerPermissions?.canChangePropertySettings
                    }
                    isShopServiceDisabled={
                      !brokerPermissions?.canChangeShopSettings
                    }
                    isBrokerSettings
                  />
                )
              )}
          </ServicesList>
          <SubmitButton
            label={t('seller-portal.broker-view.settings.button.save')}
            isDisabled={!isChanged}
          />
        </Container>
      </Form>
      <ServiceOverlay images={serviceExample} />
    </FormProvider>
  );
};

const BrokerServicesContainer = memo(BrokerServicesContainerBase);

export { BrokerServicesContainer };
