import styled, { css } from 'styled-components';
import { device } from '../../../../style/theme';
import { hexToRGB } from '../../utils/hex-to-rgb';
import Icon from './icon';
import { BoxText, BoxTitle } from './typography';
import { useIsMobileSize } from '../../hooks/useIsMobileSize';

const Container = styled.div<{
  isSelected?: boolean;
  isDisabled?: boolean;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  gap?: number;
  padding?: string;
  isBoxTabletColumn?: boolean;
  centered?: boolean;
  isFullWidth?: boolean;
  isRowBox?: boolean;
  isIframe?: boolean;
}>`
  &&& {
    display: inline-flex;
    flex-direction: ${({ isRowBox }) => (isRowBox ? 'row' : 'column')};
    justify-content: center;
    align-items: center;
    padding: ${({ padding }) => padding || '16px 8px'};
    ${({ isFullWidth }) =>
      isFullWidth &&
      css`
        width: 100%;
      `}
    border-radius: 3px;
    border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
    background-color: ${({ theme }) => theme.white};
    min-width: ${({ minWidth }) =>
      typeof minWidth === 'number' ? minWidth : '128'}px;
    ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
    min-height: ${({ minHeight }) => minHeight ?? 104}px;

    @media ${({ isIframe }) =>
        isIframe ? device.mobileIframe : device.tablet} {
      flex-direction: ${({ isBoxTabletColumn }) =>
        isBoxTabletColumn ? 'column' : 'row'};
      gap: ${({ isBoxTabletColumn, gap }) =>
        gap ?? (isBoxTabletColumn ? '0' : '16px')};
      justify-content: ${({ isBoxTabletColumn, centered }) =>
        isBoxTabletColumn || centered ? 'center' : 'flex-start'};
      padding: 12px;
      min-width: 100%;
      min-height: auto;
    }

    flex: 1;
    box-shadow: inset 0 0 0 1px transparent;
    transition: border-color 225ms ease, background-color 225ms ease,
      box-shadow 225ms ease;
    p {
      color: ${(props) =>
        props.isDisabled
          ? props.theme.ctaDisabledBackground
          : hexToRGB(props.theme.blue, 0.6)};
      transition: color 225ms ease;
      margin-top: ${({ isBoxTabletColumn }) =>
        isBoxTabletColumn ? '12px' : '0'};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
    }
    > .icon {
      > svg,
      > svg > path {
        stroke: ${(props) =>
          props.isDisabled
            ? props.theme.ctaDisabledBackground
            : props.theme.blue};
      }
    }
    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
    &:hover {
      border-color: ${(props) => props.theme.blue};
      background-color: ${(props) => props.theme.greyBackground};
      cursor: pointer;
      p {
        color: ${(props) => props.theme.blue};
      }
    }
    ${(props) =>
      props.isSelected &&
      `
      background-color: #fff;
      box-shadow: inset 0px 0px 0px 1px ${props.theme.blue};
      border-color: ${props.theme.blue};
       p {
        color: ${props.theme.blue};
      }
    `}
  }
`;

const TitleWrapper = styled.div<{ isIframe?: boolean; isRowBox?: boolean }>`
  display: flex;
  justify-content: center;
  width: ${({ isRowBox }) => (isRowBox ? 'auto' : '100%')};
  margin-left: ${({ isRowBox }) => (isRowBox ? 16 : 0)}px;
  min-width: 0;
  @media ${({ isIframe }) => (isIframe ? device.mobileIframe : device.tablet)} {
    width: auto;
  }
`;

const TextWrapper = styled.div`
  & > p {
    white-space: normal !important;
    overflow: auto !important;
    margin-top: 4px !important;
  }
`;

const IconImage = styled.img``;

interface IProps {
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  iconImage?: string;
  iconColor?: string;
  iconSelectedColor?: string;
  fillIconColor?: string;
  fillSelectedIconColor?: string;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  iconHeight?: number;
  gap?: number;
  padding?: string;
  id?: string;
  title: string;
  text?: string;
  onClick: () => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  centered?: boolean;
  isFullWidth?: boolean;
  isRowBox?: boolean;
  isBoxTabletColumn?: boolean;
  isIframe?: boolean;
}

const IconBox = ({
  icon,
  iconImage,
  iconColor,
  iconSelectedColor,
  fillIconColor,
  fillSelectedIconColor,
  minWidth,
  maxWidth,
  minHeight,
  iconHeight,
  padding,
  gap,
  title,
  text,
  onClick,
  isSelected,
  id,
  isDisabled,
  centered,
  isFullWidth,
  isRowBox,
  isBoxTabletColumn,
  isIframe,
}: IProps): JSX.Element => {
  const isMobile = useIsMobileSize();
  return (
    <Container
      id={id}
      onClick={isDisabled ? undefined : onClick}
      isSelected={isSelected}
      isDisabled={isDisabled}
      minWidth={minWidth}
      maxWidth={maxWidth}
      minHeight={minHeight}
      padding={padding}
      gap={gap}
      centered={centered}
      isFullWidth={isFullWidth}
      isRowBox={isRowBox}
      isBoxTabletColumn={isBoxTabletColumn}
      isIframe={isIframe}
    >
      {!!icon && (
        <Icon
          icon={icon}
          color={
            iconSelectedColor && isSelected ? iconSelectedColor : iconColor
          }
          fillColor={isSelected ? fillIconColor : undefined}
          width={iconHeight ?? 40}
          height={40}
        />
      )}
      {!!iconImage && (
        <IconImage
          src={iconImage}
          height={iconHeight ?? (isMobile ? 34 : 40)}
        />
      )}
      <TitleWrapper isIframe={isIframe} isRowBox={isRowBox}>
        <BoxTitle fontWeight={500} content={title} />
      </TitleWrapper>
      <TextWrapper>
        <BoxText content={text} />
      </TextWrapper>
    </Container>
  );
};

export { IconBox };
