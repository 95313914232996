import { useEffect } from 'react';

const useScript = (
  src?: string | null,
  scriptId?: string | null,
  containerSelector?: string
): void => {
  useEffect(() => {
    if (!src) {
      return () => {};
    }

    const script = document.createElement('script');

    script.src = src;
    script.async = true;
    script.id = scriptId ?? '';

    const $containerElement = containerSelector
      ? document.querySelector(containerSelector)
      : document.body;

    $containerElement?.append(script);

    return () => {
      script.remove();
    };
  }, [containerSelector, scriptId, src]);
};

export default useScript;
