import { FinancingReason, FinancingVariant } from '../../../generated';

export const purchasePriceByFinancingVariant = (
  financingVariant: FinancingVariant
): number => {
  const { financingPropositionMaxPurchasePrice, financingVariantData } =
    financingVariant;

  let maxPurchasePrice =
    financingPropositionMaxPurchasePrice?.maximalPurchasePrice;

  if (maxPurchasePrice) {
    return Math.round(maxPurchasePrice + maxPurchasePrice * 0.4);
  }

  const { reason, propertyValue, purchasePrice, buildingCosts, landPrice } =
    financingVariantData;

  switch (reason) {
    case FinancingReason.FollowUpFinancing:
    case FinancingReason.RaisingCapital:
    case FinancingReason.Modernization:
      maxPurchasePrice = propertyValue ?? 0;
      break;
    case FinancingReason.PurchaseOfNewBuilding:
    case FinancingReason.PurchaseOfExistingProperty:
      maxPurchasePrice = purchasePrice ?? 0;
      break;

    case FinancingReason.OwnConstructionProject:
      maxPurchasePrice = (buildingCosts ?? 0) + (landPrice ?? 0);
      break;

    default:
      maxPurchasePrice = 0;
      break;
  }

  return Math.round(maxPurchasePrice + maxPurchasePrice * 0.4);
};
