import { FC } from 'react';
import {
  FinancingLpSection,
  useReadFinancingPartnerQuery,
} from '../../../../../generated';
import { Title, LogosContainer, Logo } from './financing-partners-styles';

interface IProps {
  section: FinancingLpSection;
}

interface PartnerLogoProps {
  id: string;
}

interface FinancingPartnerData {
  data: {
    readFinancingPartner: {
      _id: string;
      fileAlt: string;
      fileUrl: string;
    };
  };
  isLoading: boolean;
}

const PartnerLogo: FC<PartnerLogoProps> = ({ id }) => {
  const { data, isLoading } =
    useReadFinancingPartnerQuery<FinancingPartnerData>({ id });

  if (isLoading || !data || !data.readFinancingPartner) return null;

  const {
    readFinancingPartner: { fileUrl, fileAlt },
  } = data;

  return <Logo isBig={fileAlt === 'Commerzbank'} src={fileUrl} alt={fileAlt} />;
};

const FinancingPartners: FC<IProps> = ({ section: { title, recordIds } }) => {
  return (
    <div>
      <Title>{title}</Title>
      <LogosContainer>
        {(recordIds || []).map((id) => {
          return <PartnerLogo key={id} id={id} />;
        })}
      </LogosContainer>
    </div>
  );
};

export default FinancingPartners;
