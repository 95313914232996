import { ReactComponent as CoinsIcon } from 'assets/streamline-light/money-payments-finance/accounting-billing/accounting-coins.svg';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Close } from '../../../../../../assets/streamline-light/interface-essential/form-validation/close.svg';
import { PropertyPurchaseIntent } from '../../../../../../generated';
import SingleOffer from '../../../../components/purchase-offers/single-offer';

const Container = styled.div`
  z-index: 2;
`;

const Nav = styled.div`
  display: none;
  position: fixed;
  z-index: 3334;
  top: 0;
  right: 0;
  color: white;
  height: 56px;
  background-color: #002849;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-transform: capitalize;

  @media (max-width: 1440px) {
    display: flex;
  }
`;

const CloseBtn = styled.button`
  position: fixed;
  right: 16px;
  top: 16px;
  padding: 0;
  margin: 0;
  outline: none;
  width: 16px;
  height: 16px;
  color: #fff;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const Heading = styled.div`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: solid 1px #d9d9d9;
  border-bottom: none;
  padding: 10px 16px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.blue};
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.67;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  background: white;
`;

const HeadingHidden = styled.div`
  padding: 10px 16px;
  color: white;
  background: white;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.67;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  background: white;
  position: absolute;
  top: -1px;
  right: 0;
  height: 1px;
  overflow: hidden;
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const ContentBox = styled.div<{ isOpenAccordion: boolean }>`
  position: absolute;
  padding: 16px;
  border-radius: 3px;
  border-top-right-radius: 0;
  box-shadow: 0 4px 10px 0 var(--black-9);
  border: solid 1px #d9d9d9;
  background-color: #fff;
  z-index: 1;
  top: 40px;
  right: 0;
  ${(props) => (!props.isOpenAccordion ? 'left: auto;' : 'left: 0;')}
  min-width: 640px;

  @media (max-width: 1440px) {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3333;
    position: fixed;

    a {
      display: flex;
      flex: 1;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 12px;
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};

  @media (max-width: 1440px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    margin-top: 54px;
  }
`;

const PurchaseIntentButton = styled.button`
  height: 40px;
  margin-top: 12px;
  padding: 10px 24px;
  border-radius: 3px;
  background-color: #4e73f5;
  color: white;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 1px;
  outline: none;
  border: none;
  cursor: pointer;

  @media (max-width: 1440px) {
    margin-top: 40px;
    width: 100%;
  }
`;

const Overlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
`;

interface IProps {
  openPurchaseIntent: () => void;
  purchaseIntents: PropertyPurchaseIntent[];
  refetch: () => void;
  closePurchaseIntentDropdown: () => void;
  isOpenAccordion: boolean;
}

const PurchaseIntentDropdown = ({
  openPurchaseIntent,
  purchaseIntents,
  refetch,
  closePurchaseIntentDropdown,
  isOpenAccordion,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Overlay onClick={closePurchaseIntentDropdown} />
      <Container>
        <Nav>
          {t('expose-page.head-info.button.interest-in-buying').toLowerCase()}
          <CloseBtn onClick={closePurchaseIntentDropdown}>
            <Close />
          </CloseBtn>
        </Nav>
        <Heading>
          <IconContainer>
            <CoinsIcon />
          </IconContainer>
          {t('expose-page.head-info.button.interest-in-buying')}
        </Heading>
        <ContentBox isOpenAccordion={isOpenAccordion}>
          <Title>{t('purchase-intent.offers.title')}</Title>
          <HeadingHidden>
            <IconContainer>
              <CoinsIcon />
            </IconContainer>
            {t('expose-page.head-info.button.interest-in-buying')}
          </HeadingHidden>
          {purchaseIntents.map((offer) => {
            return (
              <SingleOffer key={offer._id} offer={offer} refetch={refetch} />
            );
          })}
          <PurchaseIntentButton onClick={openPurchaseIntent}>
            {t(
              'expose-page.head-info.button.interest-in-buying.info.button.submit-purchase-offer'
            )}
          </PurchaseIntentButton>
        </ContentBox>
      </Container>
    </>
  );
};

export default PurchaseIntentDropdown;
