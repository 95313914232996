import { memo, useEffect } from 'react';
import { batch } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';

import {
  OffersSearchInput,
  Property,
  usePropertyQuery,
} from '../../../../generated';
import { device } from '../../../../style/theme';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import {
  addMarkers,
  changeViewport,
  removeAllMarkersExceptHome,
  setSettings,
} from '../../../map/redux/mapSlice';
import {
  DEFAULT_SIMILAR_PROPERTIES_FILTERS,
  PERIOD_2_YEARS,
} from '../../constants';
import {
  clearPropertiesCache,
  setFilters,
} from '../../redux/similarPropertiesSlice';
import { getPropertyPeriod } from '../../utils/date';
import { SimilarPropertiesContainer } from '../similar-properties/container';
import { Filters } from '../similar-properties/filters';

const LeftColumn = styled.div`
  margin-right: 16px;
  padding: 36px 32px 32px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #fff;
  flex: 1.56;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    margin-right: 0;
    padding: 36px 16px 16px;
  }
`;

const RightColumn = styled.div`
  flex: 1;
`;

const Container = styled.div<{ isShrinked: boolean }>`
  display: flex;
  flex-direction: ${({ isShrinked }) => (isShrinked ? 'column' : 'row')};
  & {
    ${LeftColumn} {
      order: ${({ isShrinked }) => (isShrinked ? 2 : 1)};
      margin: ${({ isShrinked }) =>
        isShrinked ? '16px 0 0' : '0 16px 12px 0'};
      @media ${device.tablet} {
        margin: 0;
      }
    }
    ${RightColumn} {
      order: ${({ isShrinked }) => (isShrinked ? 1 : 2)};
    }
  }
  @media ${device.tablet} {
    flex-flow: row wrap;
    ${LeftColumn} {
      flex: 0 0 100%;
      order: 2;
    }
    ${RightColumn} {
      flex: 0 0 100%;
      order: 1;
    }
  }
`;

const SimilarPropertiesBase = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { propertyId } = useParams<{ propertyId: string }>();
  // const isShrinked = useAppSelector(
  //   (state) => state.dynamicMapUtils.isTabListNearTop
  // );
  const isShrinked = false;

  const { property } = usePropertyQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, isLoading, error, isSuccess }) => {
        return {
          property: data?.property,
          error,
          isLoading,
          isSuccess,
        };
      },
    }
  );

  const filters = useAppSelector(
    (state) => state.similarProperties.filters
  ) as OffersSearchInput;

  useEffect(() => {
    if (property) {
      const pin = {
        longitude: property.propertyData.location?.coordinates.longitude,
        latitude: property.propertyData.location?.coordinates.latitude,
      };

      batch(() => {
        dispatch(
          changeViewport({
            ...property.propertyData.location?.coordinates,
            bearing: -80,
            pitch: 65,
            zoom: 14,
            transitionDuration: 0,
          })
        );
        dispatch(
          setSettings({
            dragPan: true,
            dragRotate: true,
            scrollZoom: true,
            touchZoom: true,
            touchRotate: true,
            keyboard: true,
            doubleClickZoom: true,
          })
        );
        dispatch(addMarkers([pin]));
        dispatch(
          setFilters(
            DEFAULT_SIMILAR_PROPERTIES_FILTERS(
              property as Property,
              getPropertyPeriod(PERIOD_2_YEARS)
            )
          )
        );
      });
    }
    return () => {
      batch(() => {
        dispatch(removeAllMarkersExceptHome());
        dispatch(clearPropertiesCache());
      });
    };
  }, [dispatch, property]);

  if (
    (property && Object.keys(property).length === 0) ||
    Object.keys(filters).length === 0
  ) {
    return <></>;
  }

  return (
    <Container isShrinked={isShrinked}>
      <LeftColumn>
        <SimilarPropertiesContainer />
      </LeftColumn>

      <RightColumn>
        <Filters property={property as Property} />
      </RightColumn>
    </Container>
  );
};

const SimilarProperties = memo(SimilarPropertiesBase);

export { SimilarProperties };
