import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Container } from './mortgage-certificate-styles';
import { useAppSelector } from '../../../../../common/hooks';
import { useGetFinancing } from '../../../../hooks/useGetFinancing';
import { InfoMessage } from './info-message/info-message';
import { FinancingResultsContainer } from '../calculator-tab/calculation-tab-styles';
import { FinancingCalculation } from '../calculator-tab/financing-calculation/financing-calculation';
import { FinancingCalculationFormContainer } from '../calculator-tab/financing-calculation-form-container/financing-calculation-form-container';
import EmptyStatePlaceholder from './empty-state-placeholder';
import { YourFinanceCertificateSuggestion } from '../calculator-tab/your-finance-certificate-suggestion/your-finance-certificate-suggestion';
import { ModalMobileEditWidget } from '../calculator-tab/modals/modal-mobile-edit-widget/modal-mobile-edit-widget';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';

const MortgageCertificate: FC = () => {
  const { financingId } = useParams<{ financingId: string }>();
  const isMobileSize = useIsMobileSize();
  const { calculateFinancingPropositions, calculateDebitRateCommitment } =
    useAppSelector((state) => state.financing.editWidget);
  const {
    proposition: financingProposition,
    propositionMaxPurchasePrice,
    financingVariant,
    personalFinancing,
    financingVariants,
    marketingParameters,
    vonPollProperty,
    // isCertificate,
  } = useGetFinancing({
    financingId,
  });

  // max price
  const clacMaximalProposition = calculateFinancingPropositions?.find(
    (item) => item.maximalPurchasePrice
  );
  const maximalProposition = calculateFinancingPropositions
    ? clacMaximalProposition
    : propositionMaxPurchasePrice;

  // general proposition
  const calcFinancingProposition = calculateFinancingPropositions?.find(
    (item) => item.debitRateCommitment === calculateDebitRateCommitment
  );
  const proposition = calculateFinancingPropositions
    ? calcFinancingProposition
    : financingProposition;

  const financingVariantData = financingVariant?.financingVariantData;
  const isCertificateSuggestion =
    financingVariant?.financingSuggestions.isCertificateSuggestion;
  const lastSync = calculateFinancingPropositions
    ? new Date()
    : financingVariant?.propositionsLastSync;

  return (
    <Container>
      {proposition &&
      personalFinancing &&
      financingVariant &&
      financingVariants &&
      financingVariantData ? (
        <>
          <InfoMessage />
          {isCertificateSuggestion && (
            <YourFinanceCertificateSuggestion
              financingVariantsProp={financingVariants}
            />
          )}
          <FinancingResultsContainer>
            <FinancingCalculation
              lastSync={lastSync}
              isMortgageCertificate
              // isCertificate={isCertificate}
              isCalculated={!!calculateFinancingPropositions}
              marketingParameters={marketingParameters}
              personalFinancing={personalFinancing}
              financingVariantData={financingVariantData}
              financingProposition={maximalProposition}
              isRequestedFinancing={
                financingVariant.financingSuggestions.isRequestedFinancing
              }
              financingVariants={financingVariants}
              vonPollProperty={vonPollProperty}
            />
            {isMobileSize ? (
              <ModalMobileEditWidget
                isMortgageCertificate
                financingVariantData={financingVariantData}
                personalFin={personalFinancing}
              />
            ) : (
              <FinancingCalculationFormContainer
                isMortgageCertificate
                financingVariantData={financingVariantData}
                personalFin={personalFinancing}
              />
            )}
          </FinancingResultsContainer>
        </>
      ) : (
        <EmptyStatePlaceholder />
      )}
    </Container>
  );
};

export { MortgageCertificate };
