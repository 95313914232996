import { batch, useDispatch } from 'react-redux';
import { FlyToInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';
import { useCallback } from 'react';
import {
  changeViewport,
  setMarkers,
  setSettings,
} from '../../../map/redux/mapSlice';
import { Germany } from '../../../map/constants/points-of-interest';
import {
  openValuationWizardAction,
  setValuationTypeAction,
  toggleIsHighlight,
} from '../redux/valuationWizardSlice';
import { ValuationType } from '../../../../generated';
import { defaultMapSettings } from '../../../map/constants';

type IProps = {
  type: ValuationType;
};

type THandleVWOpen = {
  handleVWOpen: () => void;
};

export const useHandleVWOpen = ({ type }: IProps): THandleVWOpen => {
  const dispatch = useDispatch();

  const handleEvaluationWizardOpen = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    batch(() => {
      dispatch(
        changeViewport({
          ...Germany,
          transitionDuration: 'auto',
          transitionInterpolator: new FlyToInterpolator({
            speed: 0.8,
            curve: 1,
          }),
          transitionEasing: easeCubic,
        })
      );
      dispatch(setMarkers([]));
      dispatch(setSettings(defaultMapSettings));
      dispatch(openValuationWizardAction());
      dispatch(setValuationTypeAction(type));
      setTimeout(() => {
        dispatch(toggleIsHighlight(true));
      }, 600);
      setTimeout(() => {
        dispatch(toggleIsHighlight(false));
      }, 2100);
    });
  }, [dispatch]);

  return {
    handleVWOpen: handleEvaluationWizardOpen,
  };
};
