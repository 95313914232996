import { CSSProperties, FC } from 'react';

import { ReactComponent as CheckIcon } from 'assets/streamline-light/interface-essential/form-validation/check-button.svg';

import { Modal, ModalTitle } from '../../../../common/components/modal';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { toggleIsConfirmAccountModalOpen } from '../../../redux/authSlice';
import { Container, Text } from './confirm-account-modal-styles';
import { VerificationPin } from '../../../../common/components/verification-input';
import { StatusType } from '../../../../common/components/verification-input/types';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { MainButton } from '../../../../common/components/ui/buttons';

const ConfirmAccountModal: FC = () => {
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();
  const { isConfirmAccountModalOpen } = useAppSelector((state) => state.auth);
  const wrapperStyles = isMobileSize
    ? {
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
        height: '100%',
        overflow: 'overlay',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }
    : { maxWidth: '544px' };

  const onClose = () => {
    dispatch(toggleIsConfirmAccountModalOpen(false));
  };

  const handleOnFinish = () => {};

  return (
    <Modal
      isOpen={isConfirmAccountModalOpen}
      onClose={onClose}
      wrapperStyles={wrapperStyles as CSSProperties}
    >
      <Container>
        <ModalTitle
          iconWidth={22}
          iconHeight={16}
          icon={CheckIcon}
          text="E-Mail-Adresse bestätigen"
          textAlign="center"
          isCentered
          margin="0 0 16px"
        />
        <Text>
          Geben Sie den Bestätigungscode ein, den wir Ihnen per E-Mail geschickt
          haben.
        </Text>
        <VerificationPin
          type="number"
          inputsNumber={5}
          status={StatusType.process}
          onFinish={handleOnFinish}
        />
        <MainButton label="Konto bestätigen" />
      </Container>
    </Modal>
  );
};

export { ConfirmAccountModal };
