import { useCallback } from 'react';
import { batch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { IValuationForm } from '../../../../form-property-valuation-wizard/interface';
import {
  clearErrors,
  closeVWModal,
  resetRegionalVW,
  resetVW,
  setErrors,
  toggleLoading,
  updateFormStateAction,
} from '../../../redux/valuationWizardV2Slice';
import { toggleLoading as toggleLoadingAuth } from '../../../../../auth/redux/authSlice';
import { parseValuationInput } from '../../../../utils/parse-valuation-input';
import {
  CreatePropertyInput,
  useReadPropertiesQuery,
} from '../../../../../../generated';
import { buildTimeSeries } from '../../../../utils/build-time-series';
import { siteMap } from '../../../../../../routes/site-map';
import { FormError } from '../../../../interface';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import {
  useCreatePropertyDossierMutation,
  useLazyPropertyValuationQuery,
} from '../../../../../../services/graphql/enhanced';
import { useCreateProperty } from '../../../../form-property-valuation-wizard/hooks/useCreateProperty';
import { useLngHistoryPush } from '../../../../../localization/lng-history-push';
import { MODAL_ANIMATION_DURATION } from '../../../../../common/components/modal/modal';
import { useActualExperiment } from '../../../../../experiments/hooks/useActualExperiment';
import { useGetPortalType } from '../../../../../landing/hooks/useGetPortalType';
import {
  clearSellerPropertiesCache,
  resetFilters,
} from '../../../../../seller/redux/sellerPropertiesSlice';

// type TProps = {
//   isOnLandingPage?: boolean;
// };

type TReturn = {
  onCreateProperty: (
    formData: IValuationForm,
    ownerId?: string
  ) => Promise<void>;
  valuationData: unknown;
  valuationError: unknown;
};

const useSubmitOnlineEVForm = (): TReturn => {
  const dispatch = useAppDispatch();
  const lngHPush = useLngHistoryPush();
  const { isSeller } = useGetPortalType();
  const { reset } = useFormContext();

  const selectedAddress = useAppSelector(
    (state) => state.valuationWizardV2.selectedAddress
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const user = useAppSelector((state) => state.auth.user);
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);

  const [valuateProperty, { data: valuationData, error: valuationError }] =
    useLazyPropertyValuationQuery();
  const [createPropertyDossier] = useCreatePropertyDossierMutation();
  const { createProperty } = useCreateProperty();
  const { isBCase } = useActualExperiment();

  // seller logic
  const { refetch } = useReadPropertiesQuery(
    {
      filter: {
        ownerId: user?._id,
      },
      pagination: {
        offset: 0,
        limit: 8,
      },
      calculateTotalInfo: true,
    },
    {
      skip: !user?._id || isAnonymousUser,
    }
  );

  const onCreateProperty = useCallback(
    async (formData: IValuationForm, ownerId?: string): Promise<void> => {
      const userId = ownerId;
      if (selectedAddress && formData) {
        batch(() => {
          dispatch(toggleLoading(true));
          dispatch(toggleLoadingAuth(true));
          dispatch(clearErrors());
          dispatch(updateFormStateAction(formData));
        });

        const valuationInput = parseValuationInput(
          formData,
          selectedAddress
        ) as CreatePropertyInput;

        const { dealType, countryCode, propertyData } = valuationInput;

        // Case B valuation
        if (!ownerId && isBCase) {
          valuateProperty({
            input: {
              dealType,
              countryCode,
              propertyData,
              valuationDates: buildTimeSeries(1, 0),
            },
          });
          return;
        }

        // Create property
        if (userId) {
          try {
            const createPropertyResponse = await createProperty({
              valuationInput,
              userId,
            });
            if (createPropertyResponse?.createProperty?._id) {
              try {
                const createPropertyDossierResponse =
                  await createPropertyDossier({
                    input: {
                      propertyId: createPropertyResponse.createProperty._id,
                    },
                  }).unwrap();
                if (isIframe) {
                  if (document.fullscreenElement && document.exitFullscreen) {
                    document.exitFullscreen();
                  }
                  window.open(
                    `${window.location.origin}/property/${createPropertyDossierResponse?.createPropertyDossier?._id}`,
                    '_blank',
                    'noopener,noreferrer'
                  );
                  dispatch(closeVWModal());
                  reset();
                } else if (
                  createPropertyDossierResponse.createPropertyDossier?._id &&
                  // !isOnLandingPage &&
                  !isSeller
                ) {
                  lngHPush(
                    `${siteMap.PropertyPage.pathWithoutParams}/${createPropertyDossierResponse.createPropertyDossier._id}`
                  );
                } else if (isSeller) {
                  dispatch(resetFilters());
                  dispatch(clearSellerPropertiesCache());
                  refetch();
                  lngHPush(siteMap.SellerPropertiesPage.pathWithoutParams);
                }

                if (!isIframe) {
                  dispatch(closeVWModal());
                }
              } catch (error: unknown) {
                batch(() => {
                  dispatch(toggleLoading(false));
                  dispatch(toggleLoadingAuth(false));
                  dispatch(setErrors(error as FormError));
                });
              }
            }
          } catch (error: unknown) {
            batch(() => {
              dispatch(toggleLoading(false));
              dispatch(toggleLoadingAuth(false));
              dispatch(setErrors(error as FormError));
            });
          } finally {
            setTimeout(() => {
              if (!isIframe) {
                dispatch(resetVW());
              } else {
                dispatch(resetRegionalVW());
              }
            }, MODAL_ANIMATION_DURATION);
            dispatch(toggleLoadingAuth(false));
          }
        }
      }
    },
    [
      selectedAddress,
      isBCase,
      dispatch,
      valuateProperty,
      createProperty,
      createPropertyDossier,
      isIframe,
      isSeller,
      reset,
      lngHPush,
      refetch,
    ]
  );

  return {
    onCreateProperty,
    valuationData,
    valuationError,
  };
};

export { useSubmitOnlineEVForm };
