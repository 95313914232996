import {
  FC,
  memo,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { ContentEditableEvent } from 'react-contenteditable';
import { ReactComponent as File } from 'assets/streamline-light/files-folders/common-files/common-file-text.svg';
import { ReactComponent as Bin } from 'assets/streamline-light/interface-essential/delete/bin-2.svg';
import { ReactComponent as Pencil } from 'assets/streamline-light/interface-essential/edit/pencil-1.svg';
import { ReactComponent as View } from 'assets/streamline-light/interface-essential/view/view-1.svg';
import { ReactComponent as Play } from 'assets/streamline-light/video-movies-tv/controls/controls-play.svg';
import { ReactComponent as ManageAccess } from 'assets/streamline-light/programing-apps-websites/digital-policies/ccpa-lock-protection-opt-out.svg';

import { ThemeContext } from 'styled-components';
import {
  ActionsContainer,
  Container,
  DeleteFileButton,
  FileName,
  FileSize,
  OpenFileButton,
  Preview,
  PreviewImage,
  TitleWrapper,
  EditFileName,
  Title,
  OpenAccessManagerButton,
} from './uploaded-item-styles';
import { convertSizeToMB } from '../utils/convertSizeToMB';
import Icon from '../../../../ui/icon';
import { hexToRGB } from '../../../../../utils/hex-to-rgb';
import { useIsMobileSize } from '../../../../../hooks/useIsMobileSize';
import { DeleteFileTooltip } from './tooltip';

interface IProps {
  title: string;
  fileName: string;
  size: number;
  onDeleteFile?: (fileName: string) => void;
  onOpenFile?: (fileName: string) => void;
  onEditTitle?: (fileName: string, fileTitle: string) => void;
  onOpenAccessManager?: (fileName: string) => void;
  previewImgUrl?: string;
  url?: string;
  Label?: ReactNode;
}

const UploadedItemBase: FC<IProps> = ({
  title,
  fileName,
  size,
  onDeleteFile,
  onOpenFile,
  onEditTitle,
  onOpenAccessManager,
  previewImgUrl,
  url,
  Label,
}) => {
  // useRef instead of useState for react-contenteditable in functional component
  // https://www.npmjs.com/package/react-contenteditable
  const titleRef = useRef(title);
  const contentEditableRef = useRef<HTMLDivElement | null>(null);
  const isMobileSize = useIsMobileSize();
  const themeContext = useContext(ThemeContext);
  const iconStyle = useMemo(
    () => ({
      margin: 0,
    }),
    []
  );

  const handleFileNameChange = useCallback((e: ContentEditableEvent) => {
    titleRef.current = e.target.value;
  }, []);

  const handleFileNameBlur = useCallback(() => {
    if (onEditTitle && title !== titleRef.current) {
      onEditTitle(fileName, titleRef.current);
    }
  }, [fileName, onEditTitle, title]);

  const showPreview = (!onDeleteFile && isMobileSize) || !isMobileSize;

  const isLink = useMemo(() => !!url, [url]);

  return (
    <Container
      showPreview={showPreview}
      onClick={(event) => event.stopPropagation()}
    >
      {showPreview && !isLink && (
        <Preview isImage={!!previewImgUrl}>
          {previewImgUrl ? (
            <PreviewImage src={previewImgUrl} alt={'preview image'} />
          ) : (
            <Icon icon={File} color={hexToRGB(themeContext.blue, 0.5)} />
          )}
        </Preview>
      )}
      {showPreview && isLink && (
        <Preview isImage>
          <iframe
            width={isMobileSize ? '52' : '82'}
            height="54"
            src={url}
            title="Video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Preview>
      )}
      <TitleWrapper>
        {onEditTitle ? (
          <>
            <EditFileName
              innerRef={contentEditableRef}
              html={titleRef.current}
              onBlur={handleFileNameBlur}
              onChange={handleFileNameChange}
              onClick={(event) => event.stopPropagation()}
            />
            <Icon
              icon={Pencil}
              color={themeContext.blue}
              width={12}
              height={12}
              style={{ marginLeft: '8px', cursor: 'pointer' }}
              onClick={() => contentEditableRef?.current?.focus()}
            />
          </>
        ) : (
          <Title content={title} />
        )}
      </TitleWrapper>
      <FileName content={fileName} />
      {Label}
      {size !== 0 && !isMobileSize && (
        <FileSize content={`${convertSizeToMB(size)} MB`} />
      )}
      <ActionsContainer>
        {onDeleteFile && (
          <DeleteFileTooltip fileName={fileName} onDeleteFile={onDeleteFile} />
        )}
        <OpenFileButton
          label={''}
          onClick={onOpenFile ? () => onOpenFile(fileName) : () => null}
          icon={isLink ? Play : View}
          borderColor={themeContext.blue}
          color={themeContext.blue}
          iconStyle={iconStyle}
        />
        {onOpenAccessManager && (
          <OpenAccessManagerButton
            label={''}
            onClick={() => onOpenAccessManager(fileName)}
            icon={ManageAccess}
            borderColor={themeContext.blue}
            color={themeContext.blue}
            iconStyle={iconStyle}
          />
        )}
      </ActionsContainer>
    </Container>
  );
};

const UploadedItem = memo(UploadedItemBase);

export { UploadedItem };
