import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as CheckCircle } from 'assets/streamline-light/interface-essential/form-validation/check-circle-1.svg';

import Icon from '../../common/components/ui/icon';
import { Headline2 } from '../../common/components/ui/typography';
import { MainButton } from '../../common/components/ui/buttons';

interface IProps {
  onClose: () => void;
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &:last-of-type {
    margin-top: auto;
  }
`;

const Title = styled(Headline2)`
  margin: 16px 0 24px 0;
`;

const Content = styled.p`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

const TimifySuccess = ({ onClose }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <Row>
        <Icon
          icon={CheckCircle}
          width={40}
          height={40}
          color={themeContext.green}
        />
      </Row>
      <Row>
        <Title content={t('timify-modal.reservation.success.title')} />
      </Row>
      <Row>
        <Content>{t('timify-modal.reservation.success.body')}</Content>
      </Row>
      <Row>
        <MainButton
          label={t('timify-modal.reservation.success.button')}
          onClick={onClose}
          centered
          fluid
        />
      </Row>
    </Container>
  );
};

export { TimifySuccess };
