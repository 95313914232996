import styled from 'styled-components';

import Icon from '../../../common/components/ui/icon';

interface IProps {
  icon:
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & { title?: string }
      >
    | string;
  isActive: boolean;
  reduceToken?: boolean;
}

const Container = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-color: #ffffff;
  opacity: ${(props) => (props.isActive ? 1 : 0.4)};
  transition: opacity 600ms ease;
`;

const StyledImage = styled.img<{ reduceToken?: boolean }>`
  width: ${({ reduceToken }) => (reduceToken ? '50%' : '100%')};
  height: auto;
`;

const Token = ({ icon, isActive, reduceToken }: IProps): JSX.Element => (
  <Container isActive={isActive}>
    {typeof icon === 'string' ? (
      <StyledImage src={icon} reduceToken={reduceToken} />
    ) : (
      <Icon width={40} height={40} icon={icon} color="#000" />
    )}
  </Container>
);

export { Token };
