import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddressBarContainer,
  CommissionContainer,
  CommissionLabel,
  CommissionValue,
  FullPrice,
  Header,
  PriceContainer,
  Row,
  SquareMeterPrice,
  Tag,
} from './head-info-styles';

import { AddressBar } from '../../../../../common/components/ui/hero-slider/address-bar';
import { Buttons } from './buttons/buttons';
import { Property, PropertyPurchaseIntent } from '../../../../../../generated';
import { useAppSelector } from '../../../../../common/hooks';
import { useExposeDetailsHeadInfo } from '../../hooks/useExposeDetailsQueries';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { formatPrice } from '../../../../utils/formatPrice';
import { buildPropertyTitle } from '../../../../utils/buildPropertyTitle';

interface IProps {
  purchaseIntents: PropertyPurchaseIntent[];
  refetch: () => void;
  property: Partial<Property>;
}

const HeadInfo: FC<IProps> = ({ purchaseIntents, refetch, property }) => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const isOpenAccordion = useAppSelector(
    (state) => state.exposeDetails.isOpenHeadAccordion
  );
  // const {
  //   headInfo: {
  //     externalId,
  //     title,
  //     price,
  //     pricePerSqm,
  //     currency,
  //     provision,
  //     fullAddress,
  //   },
  // } = useExposeDetailsHeadInfo();

  const {
    propertyData,
    title,
    onOfficePrice,
    priceCurrency,
    externalId,
    provision,
  } = property;

  const { propertyType, numberOfRooms, location, livingArea } =
    propertyData ?? {};

  const pricePerSqm = useMemo(() => {
    if (onOfficePrice && livingArea) {
      return Number.parseFloat(
        (onOfficePrice / livingArea).toFixed(2).toString()
      );
    }

    return 0;
  }, [onOfficePrice, livingArea]);

  return (
    <>
      {isMobileSize && (
        <AddressBarContainer>
          <AddressBar
            show
            address={`${location?.address?.street} ${location?.address?.houseNumber}, ${location?.address?.postCode} ${location?.address?.city}`}
          />
        </AddressBarContainer>
      )}
      <Header
        isColumn={isOpenAccordion}
        content={
          title ||
          (propertyType?.code && numberOfRooms && location && livingArea
            ? buildPropertyTitle(
                propertyType.code,
                numberOfRooms,
                location?.address?.city ?? '',
                livingArea
              )
            : '')
        }
      />

      <Row
        isWrap
        isColumn={isOpenAccordion}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Row
          isWrap
          alignItems={'center'}
          justifyContent={'space-between'}
          style={{ flexGrow: 1 }}
        >
          <Row
            alignItems={'center'}
            style={{ flexGrow: 1 }}
            justifyContent={'space-between'}
          >
            <PriceContainer>
              <FullPrice>
                {onOfficePrice
                  ? formatPrice(onOfficePrice ?? 0, priceCurrency ?? 'EUR')
                  : t(
                      'seller-properties-list.evaluation-price.in-consultation-with-broker'
                    )}
              </FullPrice>
              {pricePerSqm ? (
                <SquareMeterPrice>
                  {pricePerSqm} {priceCurrency}/m²
                </SquareMeterPrice>
              ) : null}
            </PriceContainer>
            {externalId ? <Tag># {externalId}</Tag> : null}
          </Row>
          {provision?.value && (
            <CommissionContainer>
              <CommissionLabel isBold={!!provision?.isAgreedWithSeller}>
                {t('expose-page.head-info.provision', {
                  provision: provision.value,
                })}
              </CommissionLabel>
              <br />
              {provision?.isAgreedWithSeller && (
                <CommissionLabel>
                  {t('expose-page.head-info.provision.agreed-with-seller')}
                </CommissionLabel>
              )}
            </CommissionContainer>
          )}
        </Row>
        {/* <Buttons purchaseIntents={purchaseIntents} refetch={refetch} /> */}
      </Row>
    </>
  );
};

export { HeadInfo };
