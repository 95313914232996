import { FC } from 'react';
import { InputsContainer } from '../../../../../../components/fullscreen-modal/inputs-container/inputs-container';
import { useSixthStepLogic } from '../../../../../hooks/useStepsLogic';
import { Container } from './sixth-step-styles';
import { BackButtonContainer } from '../../../../common/back-button-container';

const SixthStep: FC = () => {
  const { inputs } = useSixthStepLogic();
  return (
    <Container>
      <InputsContainer inputs={inputs} />
      <BackButtonContainer marginTop={0} />
    </Container>
  );
};

export { SixthStep };
