import { CSSProperties, FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { Modal, ModalTitle } from '../../../../common/components/modal';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { CloseButton } from '../../../../common/components/modal/close-button';
import { toggleIsWidgetOnCloseModalOpen } from '../../../../auth/redux/authSlice';
import {
  MainButton,
  SecondaryButton,
} from '../../../../common/components/ui/buttons';
import {
  TitleContainer,
  Text,
  FormButtonsWrapper,
} from './modal-on-close-styles';

interface IProps {
  title: string;
  text: string;
  onSubmit: () => void;
}

const ModalOnClose: FC<IProps> = ({ title, text, onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const theme = useContext(ThemeContext);

  const isOpen = useAppSelector((state) => state.auth.isWidgetOnCloseModalOpen);

  const closeModal = () => dispatch(toggleIsWidgetOnCloseModalOpen(false));
  const wrapperStyles = isMobileSize
    ? {
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
        height: '100%',
        overflow: 'overlay',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }
    : { maxWidth: '780px', position: 'relative' };

  return (
    <Modal
      isOpen={isOpen}
      wrapperStyles={wrapperStyles as CSSProperties}
      onClose={closeModal}
    >
      {!isMobileSize && (
        <TitleContainer>
          <ModalTitle
            text={t(title)}
            textAlign="center"
            iconPosition="left"
            isCentered
          />
        </TitleContainer>
      )}
      <CloseButton
        color={theme.blue}
        bgColor={theme.white}
        position="right"
        handleClose={closeModal}
      />
      <Text>{t(text)}</Text>
      <FormButtonsWrapper>
        <SecondaryButton
          label={t('modal.widget-on-close.button-submit')}
          centered
          onClick={closeModal}
        />
        <MainButton
          label={t('modal.widget-on-close.button-close')}
          centered
          onClick={() => {
            onSubmit();
            closeModal();
          }}
        />
      </FormButtonsWrapper>
    </Modal>
  );
};

export { ModalOnClose };
