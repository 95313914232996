import { memo, RefObject } from 'react';
import { batch } from 'react-redux';
import { useIsMobileSize } from '../hooks/useIsMobileSize';

import {
  setShowMap,
  setTabListNearTop,
} from '../../property/redux/dynamicMapUtilsSlice';
import { useAppDispatch, useAppSelector, useScrollPosition } from '../hooks';
import { useGetPortalType } from '../../landing/hooks/useGetPortalType';

// const propertyFlyingTabs = new Set([1, 2, 4]);

interface IProps {
  containerReference: RefObject<HTMLDivElement>;
  propertyFlyingTabs: Set<number>;
  isDisabled?: boolean;
}

const ScrollDetectorBase = ({
  containerReference,
  propertyFlyingTabs,
  isDisabled,
}: IProps) => {
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const isTabListNearTop = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );
  const selectedTab = useAppSelector(
    (state) => state.dynamicMapUtils.selectedTab
  );
  const showMap = useAppSelector((state) => state.dynamicMapUtils.showMap);
  const { isSeller } = useGetPortalType();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (
        (propertyFlyingTabs.has(selectedTab) || isSeller) &&
        !isDisabled &&
        !isMobileSize
      ) {
        const tabListRect =
          containerReference &&
          (containerReference.current?.getBoundingClientRect() as DOMRect);

        if (currPos.y > prevPos.y) {
          const isBorderCrossed = tabListRect?.top > 177;

          if (isBorderCrossed && isTabListNearTop) {
            batch(() => {
              dispatch(setShowMap(false));
              dispatch(setTabListNearTop(false));
            });
          }
        } else if (currPos.y < prevPos.y) {
          const isBorderCrossed = tabListRect?.top < 144;
          if (isBorderCrossed && !isTabListNearTop) {
            batch(() => {
              dispatch(setTabListNearTop(true));
              if (!isSeller) {
                dispatch(setShowMap(true));
              }
            });
          }
        }
      } else {
        batch(() => {
          dispatch(setShowMap(false));
          dispatch(setTabListNearTop(false));
        });
      }
    },
    [selectedTab, isTabListNearTop, dispatch, isSeller]
  );

  // useEffect(() => {
  //   if (propertyFlyingTabs.has(selectedTab) && !isMobileSize) {
  //     if (isTabListNearTop !== showMap && isTabListNearTop) {
  //       setTimeout(() => {
  //         dispatch(setShowMap(true));
  //       }, 100);
  //     } else if (isTabListNearTop !== showMap && !isTabListNearTop) {
  //       dispatch(setShowMap(false));
  //     }
  //   }
  // }, [
  //   isTabListNearTop,
  //   showMap,
  //   selectedTab,
  //   dispatch,
  //   isMobileSize,
  //   propertyFlyingTabs,
  // ]);
  //
  // useEffect(() => {
  //   if (
  //     propertyFlyingTabs.has(selectedTab) &&
  //     window.scrollY > 769 &&
  //     !isMobileSize
  //   ) {
  //     batch(() => {
  //       dispatch(setTabListNearTop(true));
  //       dispatch(setShowMap(true));
  //     });
  //   } else if (!propertyFlyingTabs.has(selectedTab) && !isMobileSize) {
  //     batch(() => {
  //       dispatch(setTabListNearTop(false));
  //       dispatch(setShowMap(false));
  //     });
  //   }
  // }, [dispatch, selectedTab, isMobileSize, propertyFlyingTabs]);

  return <></>;
};

const ScrollDetector = memo(ScrollDetectorBase);

export { ScrollDetector };
