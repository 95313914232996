import { CSSProperties, FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import BackgroundImg from 'assets/expose-request-page.png';
import SideImage from 'assets/side-image.png';

import {
  Container,
  Divider,
  GalleryContainer,
  PlaceHolderImg,
  PropertyDetailsContainer,
  PropertyDetailsTitle,
  PropertyInfoContainer,
  PropertyPrice,
  PropertyPriceContainer,
  PropertyPricePerMetr,
  Title,
} from './property-info-style';
import { PropertyDetails } from '../expose-details-overlay/components/tabs/overview/property-details/property-details';
import { setActiveSlide } from '../../redux/requestExposeSlice';
import { useAppSelector } from '../../../common/hooks';
import { PropertyGallery } from './property-gallery';
import { useSuggestedProperty } from '../expose-details-overlay/hooks/useExposeDetailsQueries';
import { Price } from '../../../landing/components/buyer-lp/suggested-properties/components/suggested-properties-styles';

interface IProps {
  propertyId: string;
  SideImageSrc?: string;
  sideImageStyle?: CSSProperties;
}

const PropertyInfo: FC<IProps> = ({
  propertyId,
  SideImageSrc,
  sideImageStyle,
}) => {
  const { t } = useTranslation();
  const swiperRef = useRef<unknown>(null);
  const activeSlide = useAppSelector(
    (state) => state.requestExpose.activeSlide
  );

  const { propertyData } = useSuggestedProperty({ propertyId });
  const { images, title, price, pricePerSqm, currency } = propertyData || {};

  return (
    <Container src={SideImageSrc ? undefined : BackgroundImg}>
      {propertyId ? (
        <PropertyInfoContainer>
          <Title>{title}</Title>
          <GalleryContainer>
            <PropertyGallery
              images={images || []}
              ref={swiperRef}
              setActiveSlide={setActiveSlide}
              activeSlide={activeSlide}
            />
          </GalleryContainer>
          <Divider />
          <PropertyDetailsContainer>
            <PropertyDetailsTitle>
              {t('property-info.property-details.title')}
            </PropertyDetailsTitle>
            <PropertyDetails propsData={propertyData} />
          </PropertyDetailsContainer>
          <PropertyPriceContainer>
            <PropertyPrice>
              {price === 0
                ? t('property.offers.card.price-on-request.label')
                : `${price?.toLocaleString('de-DE')} ${currency}`}
            </PropertyPrice>
            {price !== 0 && (
              <PropertyPricePerMetr>
                ({t('approx')} {pricePerSqm?.toLocaleString('de-DE')} {currency}
                /m²)
              </PropertyPricePerMetr>
            )}
          </PropertyPriceContainer>
        </PropertyInfoContainer>
      ) : (
        <PlaceHolderImg
          alt={'placeholder'}
          src={SideImageSrc || SideImage}
          style={sideImageStyle}
        />
      )}
    </Container>
  );
};

export default PropertyInfo;
