import { useReadPurchaseIntentQuery } from '../../../../../generated';

const usePurchaseIntentData = (purchaseIntentId: string) => {
  const { isLoading, purchaseIntentData } = useReadPurchaseIntentQuery(
    {
      id: purchaseIntentId,
    },
    {
      selectFromResult: ({ data, isLoading: loading }) => {
        const readPurchaseIntent = data?.readPurchaseIntent;

        return {
          isLoading: loading,
          purchaseIntentData: {
            name: readPurchaseIntent?.name,
            surname: readPurchaseIntent?.surname,
            email: readPurchaseIntent?.email,
            phone: readPurchaseIntent?.phone,
            postalCode: readPurchaseIntent?.postalCode,
            city: readPurchaseIntent?.city,
            confirmFunding: readPurchaseIntent?.confirmFunding,
            otherPersons: (readPurchaseIntent?.otherPersons || []).map(
              (person) => ({
                name: person.name,
                surname: person.surname,
                email: person.email,
                phone: person.phone,
                postalCode: person.postalCode,
                city: person.city,
              })
            ),
            notes: readPurchaseIntent?.notes || '',
            price: readPurchaseIntent?.price,
          },
        };
      },
    }
  );

  return {
    isLoading,
    purchaseIntentData,
  };
};

export default usePurchaseIntentData;
