import { isNumber } from 'lodash';

import { useAppSelector } from '../../../common/hooks';
import { useGetPortalType } from '../../../landing/hooks/useGetPortalType';

interface IProps {
  activeStepProp?: number;
}

const useGetValuationTitle = ({ activeStepProp }: IProps): string => {
  const { isSeller } = useGetPortalType();
  const activeStep = useAppSelector(
    (state) => state.valuationWizardV2.activeStep
  );
  switch (isNumber(activeStepProp) ? activeStepProp : activeStep) {
    case 0:
      return isSeller
        ? 'property-valuation.seller.form.redesign.first-step.title'
        : 'property-valuation.form.redesign.first-step.title';
    case 1:
      return 'property-valuation.form.redesign.second-step.title';
    case 2:
      return 'property-valuation.form.redesign.third-fourth-fifth-step.title';
    case 3:
      return 'property-valuation.form.redesign.third-fourth-fifth-step.title';
    case 4:
      return 'property-valuation.form.redesign.third-fourth-fifth-step.title';
    case 5:
      return 'property-valuation.form.redesign.sixth-step.title';
    case 6:
      return 'property-valuation.form.redesign.seventh-step.title';
    case 7:
      return 'property-valuation.form.redesign.ninth-step.title';
    default:
      return '';
  }
};

export { useGetValuationTitle };
