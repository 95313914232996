import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LoaderContainer, Title } from '../styles';
import { LoginFormWizard } from '../../../../../auth/components/forms/login-form-wizard';
import { GlobalLoader } from '../../../../../common/components/ui/loaders/global-loader';
import { useAppSelector } from '../../../../../common/hooks';
import { ILoginForm } from '../../../../../auth/interfaces';

interface IProps {
  errors: null | unknown;

  onLoginSubmit: (formData: ILoginForm) => void;
  onOpenForgotPassword?: () => void;
}

const LoginContainer: FC<IProps> = ({
  errors,
  onLoginSubmit,
  onOpenForgotPassword,
}) => {
  const { t } = useTranslation();
  const isLoginFormLoading = useAppSelector((state) => state.auth.isLoading);
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  return (
    <>
      {isLoginFormLoading ? (
        <LoaderContainer isiframe={isIframe}>
          <GlobalLoader />
        </LoaderContainer>
      ) : (
        <>
          <Title>{t('header.authentication.login')}</Title>
          <LoginFormWizard
            isRedesign
            isFullWidthButton
            onSubmit={onLoginSubmit}
            errors={errors}
            onForgotPasswordClick={onOpenForgotPassword}
          />
        </>
      )}
    </>
  );
};

export { LoginContainer };
