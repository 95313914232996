import styled from 'styled-components';
import { Headline2 } from '../../../../../common/components/ui/typography';
import { device } from '../../../../../../style/theme';

export const Container = styled.div`
  display: flex;
  position: relative;
  z-index: 10;
  align-items: center;
  margin-bottom: 40px;

  @media ${device.tablet} {
    margin-bottom: 24px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ImageContainer = styled.div<{ isGray?: boolean }>`
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
  min-height: 80px;
  min-width: 80px;
  border-radius: 40px;
  background-color: ${(props) => (props.isGray ? 'white' : '#f2f4f7')};
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    width: 48px;
    height: 48px;
    max-width: 48px;
    max-height: 48px;
    min-height: 48px;
    min-width: 48px;
    background-color: #f2f4f7;
    margin-right: 8px;
  }
`;

export const Icon = styled.img`
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media ${device.tablet} {
    max-width: 24px;
    max-height: 24px;
  }
`;

export const BenefitTitle = styled(Headline2)<{ isProportional?: boolean }>`
  max-width: 412px;
  ${({ isProportional }) => isProportional && `max-width: initial;`}
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 16px;

  @media ${device.tablet} {
    display: block;
    font-size: 14px;
  }
`;
