import { memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { ReactComponent as RealEstateDimensionsBlock } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as RealEstateDimensionsPlan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-plan.svg';
import { ReactComponent as LocationMarker } from 'assets/streamline-light/maps-navigation/pins/pin.svg';
import { ReactComponent as ResizeExpandSides } from 'assets/streamline-light/design/resize/resize-expand-sides.svg';
import { ReactComponent as InformationCircleBold } from 'assets/images/icons/common/tooltip-key-bold.svg';
import { Tooltip } from 'modules/common/components/tooltip';
import { BudgetMatchingType } from 'modules/financing/interfaces';

import Icon from '../../../../../../common/components/ui/icon';

import {
  CardContainer,
  ImageWrapper,
  CardImage,
  CardContent,
  CardTitle,
  Row,
  Tag,
  PriceContainer,
  Price,
  Location,
  IconContainer,
  IconDigit,
  IconLabel,
  SquareMeterPrice,
  LocationIcon,
  NewLabel,
  Info,
  PriceContainerHead,
} from '../properties-tab-styles';

import PropertyActionPanel from './property-action-panel';
import { CountryCodeFromVonPollDbEnum } from '../../../../../../../generated';

export enum CardTypes {
  SUGGESTED_PROPERTY = 'SuggestedProperty',
  FAVOURITE = 'Favourite',
}

interface IProps {
  id: string;
  title: string;
  price: number;
  numberOfRooms: number;
  livingArea: number;
  landArea: number;
  currency: string;
  imageSrc: string;
  url?: string;
  location: string;
  postCode?: string | number;
  isListView?: boolean;
  hasRequestExpose?: boolean;
  isDisabled?: boolean;
  type?: CardTypes;
  isLiked?: boolean;
  isRequestedForExpose?: boolean;
  isPlot?: boolean;
  isNew?: boolean;
  isUnlocked?: boolean;
  countryCode?: CountryCodeFromVonPollDbEnum | null;
  propertyId: number;
  userBudget?: number;
  onClickOpenOverlay?: () => void;
}

const PropertyCard = ({
  id,
  title,
  price,
  numberOfRooms,
  livingArea,
  landArea,
  currency,
  imageSrc,
  url,
  location,
  isListView = false,
  hasRequestExpose = false,
  isDisabled = false,
  postCode = '',
  type = CardTypes.SUGGESTED_PROPERTY,
  isLiked = false,
  isRequestedForExpose = false,
  isPlot,
  isNew,
  propertyId,
  isUnlocked = false,
  countryCode,
  userBudget = 0,
  onClickOpenOverlay,
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const pricePerSqm = Number.parseFloat(
    (price / (livingArea || landArea)).toFixed(2).toString()
  );

  const onClickPropertyDetails = useCallback(() => {
    if (url) {
      window.open(url, '_blank');
    }
  }, [url]);

  const tooltipId = `price-info-${id}`;

  let matchingType: BudgetMatchingType = 'best';

  if (userBudget > 0 && userBudget < price) {
    const exceedsWith = ((price - userBudget) / userBudget) * 100;

    matchingType =
      exceedsWith > 5 ? (exceedsWith <= 20 ? 'medium' : 'low') : 'best';
  }

  return (
    <CardContainer
      isListView={isListView}
      onClick={!hasRequestExpose ? onClickPropertyDetails : undefined}
      style={{ cursor: url && !hasRequestExpose ? 'pointer' : 'default' }}
      isDisabled={isDisabled}
    >
      {isNew && <NewLabel>NEU</NewLabel>}
      <ImageWrapper isListView={isListView}>
        <CardImage src={imageSrc} isListView={isListView} />
      </ImageWrapper>
      <CardContent isListView={isListView}>
        <Row>
          <CardTitle>{title}</CardTitle>
        </Row>
        <Row justifyContent={'space-between'}>
          <Row>
            <LocationIcon icon={LocationMarker} color={themeContext.blue} />
            <Location>{`${postCode} ${location}`}</Location>
          </Row>
          <Tag onClick={onClickPropertyDetails}>{`# ${id}`}</Tag>
        </Row>

        <PriceContainer status={matchingType}>
          <PriceContainerHead>
            <Price>
              {price.toLocaleString('de-DE')} {currency}
            </Price>
            <SquareMeterPrice>
              ({pricePerSqm.toLocaleString('de-DE')} {currency}/m<sup>2</sup>)
            </SquareMeterPrice>

            {matchingType !== 'best' && (
              <Info className="info" tooltipWidth={268}>
                <Tooltip
                  icon={InformationCircleBold}
                  id={tooltipId}
                  position="top"
                  width={16}
                  height={16}
                >
                  {t(
                    'financing-portal-page.tabs.properties.property.exceeded-tooltip'
                  )}
                </Tooltip>
              </Info>
            )}
          </PriceContainerHead>
          <SquareMeterPrice>
            {t(
              `financing-portal-page.tabs.properties.property.price-status-${matchingType}`
            )}
          </SquareMeterPrice>
        </PriceContainer>
        <Row justifyContent="space-between">
          {!isPlot && (
            <IconContainer>
              <Row margin="0 0 4px 0" alignItems="center">
                <Icon
                  icon={RealEstateDimensionsPlan}
                  color={themeContext.blue}
                />
                <IconDigit>{numberOfRooms}</IconDigit>
              </Row>
              <Row>
                <IconLabel>{t('property.offers.card.room.label')}</IconLabel>
              </Row>
            </IconContainer>
          )}
          <IconContainer>
            <Row margin="0 0 4px 0" alignItems="center">
              <Icon
                icon={isPlot ? ResizeExpandSides : RealEstateDimensionsBlock}
                color={themeContext.blue}
              />
              <IconDigit>
                {t('approx')} {isPlot ? landArea : livingArea} m<sup>2</sup>
              </IconDigit>
            </Row>
            <Row>
              <IconLabel>
                {t(
                  isPlot
                    ? 'property-overview.land-area'
                    : 'property-overview.living-area'
                )}
              </IconLabel>
            </Row>
          </IconContainer>
        </Row>
        {hasRequestExpose && !isListView && (
          <PropertyActionPanel
            propertyId={id}
            id={propertyId}
            propertyPrice={price}
            detailsUrl={url ?? ''}
            isRequestedForExpose={isRequestedForExpose}
            isLiked={isLiked}
            isUnsuitable={isDisabled}
            isFavourite={type === CardTypes.FAVOURITE}
            isUnlocked={isUnlocked}
            countryCode={countryCode}
            onClickOpenOverlay={onClickOpenOverlay}
          />
        )}
      </CardContent>
      {hasRequestExpose && isListView && (
        <PropertyActionPanel
          propertyId={id}
          id={propertyId}
          propertyPrice={price}
          detailsUrl={url ?? ''}
          isListView
          isRequestedForExpose={isRequestedForExpose}
          isLiked={isLiked}
          isUnsuitable={isDisabled}
          isFavourite={type === CardTypes.FAVOURITE}
          isUnlocked={isUnlocked}
          countryCode={countryCode}
          onClickOpenOverlay={onClickOpenOverlay}
        />
      )}
    </CardContainer>
  );
};

const SuggestedPropertyCard = memo(PropertyCard);

export { SuggestedPropertyCard };
