import { SocioEconomicsEntity, YearEntity } from '../../../../generated';
import { AdvancedChartInterface, SimpleChartInterface } from './interface';

export const parseSimpleChartData = (
  entity: SocioEconomicsEntity
): SimpleChartInterface => {
  const labels: string[] = [];
  const data: string[] = [];

  entity?.years?.forEach((yearData) => {
    labels.push(yearData.year.toString());
    data.push(yearData.values[0].absoluteValue?.toFixed(2) || '');
  });

  return {
    labels,
    data,
  };
};

export const filterAdvancedDataByYear = (years: YearEntity[]) => {
  const data: AdvancedChartInterface = {
    keys: [],
    data: {},
  };

  years.forEach((year) => {
    const key = year.year.toString();
    data.keys.push(key);
    const yearData: SimpleChartInterface = { data: [], labels: [] };
    year.values.forEach((yearValue) => {
      const { relativeValue, subtype } = yearValue;
      yearData.data.push(
        relativeValue ? (relativeValue * 100)?.toFixed(2) : '0'
      );
      yearData.labels.push(subtype || '0');
    });
    data.data = { ...data.data, [key]: yearData };
  });

  return data;
};

export const filterAdvancedDataBySubtype = (years: YearEntity[]) => {
  const data: AdvancedChartInterface = {
    keys: years[0]?.values.map((value) => value.subtype || ''),
    data: {},
  };

  data?.keys?.forEach((key) => {
    const keyData: SimpleChartInterface = { data: [], labels: [] };
    years.forEach((year) => {
      keyData.labels.push(year.year.toString());
      year.values.forEach((yearValue) => {
        if (yearValue.subtype === key) {
          keyData.data.push(yearValue.absoluteValue?.toFixed(2) || '');
        }
      });
    });
    data.data = { ...data.data, [key]: keyData };
  });

  return data;
};

export const parseAdvancedChartData = (
  entity: SocioEconomicsEntity,
  filterBy: string
): AdvancedChartInterface => {
  if (filterBy === 'year') {
    return filterAdvancedDataByYear(entity.years ?? []);
  }

  return filterAdvancedDataBySubtype(entity.years ?? []);
};
