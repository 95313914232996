import { isNumber } from 'lodash';
import { Maybe } from '../../../generated';

// separate logic start
const addZeros = (num: string) => {
  if (num.length > 0) {
    if (!num.includes(',')) {
      return `${num},00`;
    }
    if (num.split(',')[1].length === 0) {
      return `${num}00`;
    }
    if (num.split(',')[1].length === 1) {
      return `${num}0`;
    }
    return num;
  }
  return num;
};

const numberFormat = (
  value: Maybe<number> | undefined,
  isDecimalNumber?: boolean
): string => {
  if (!isNumber(value)) {
    return '';
  }
  const number = Number(value);
  const round = isDecimalNumber
    ? Math.round(number * 100) / 100
    : Math.round(number);

  const withSeparator = round.toLocaleString('de-DE');

  if (isDecimalNumber) {
    return addZeros(withSeparator);
  }

  return withSeparator;
};

export { numberFormat };
