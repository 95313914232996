import styled from 'styled-components';

interface IProps {
  isOpen: boolean;
  toggleHeader: () => void;
}

const Container = styled.div<{ isOpen: boolean }>`
  && {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 14px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    flex: 0;
    opacity: .75;
    ${(props) =>
      props.isOpen &&
      ` > div {
          transform: translateX(-20px);
          background: transparent;
          box-shadow: none;
          &:before {
            transform: rotate(45deg) translate(11.5px, -11.5px);
          }
          &:after {
            transform: rotate(-45deg) translate(11.5px, 11.5px);
          }
        }`}
  }
`;

const Hamburger = styled.div`
  width: 20px;
  height: 1px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
  transition: all 0.5s ease-in-out;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 1px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    transition: all 0.5s ease-in-out;
  }
  &:before {
    transform: translateY(-7px);
  }
  &:after {
    transform: translateY(7px);
  }
`;

const HamburgerButton = ({ isOpen, toggleHeader }: IProps): JSX.Element => (
  <Container onClick={toggleHeader} isOpen={isOpen}>
    <Hamburger />
  </Container>
);

export { HamburgerButton };
