import { FC, useState } from 'react';

import { CTAContainer } from './search-profile-widget-container-styles';
import { CTAButtons } from './cta-buttons/cta-buttons';
import { useIsMobileSize } from 'modules/common/hooks/useIsMobileSize';
import { SearchProfileContainer } from '../search-profile/search-profile-container';
import { useFormOpenSP } from 'modules/forms/form-search-profile/hooks/useFormOpenSP';
import { ISearchProfileForm } from 'modules/forms/form-search-profile/interface';

const SearchProfileWidgetContainer: FC<{ mode?: 'cta' | 'widget' }> = ({
  mode = 'cta',
}) => {
  const [forceCta, setForceCta] = useState(false);
  const isMobile = useIsMobileSize();

  const { onFormOpenSP } = useFormOpenSP({});

  const transitionToModal = (formData?: Partial<ISearchProfileForm>) => {
    onFormOpenSP(formData);

    setForceCta(true);
  };

  if (mode === 'cta' || forceCta) {
    return (
      <CTAContainer>
        <CTAButtons />
      </CTAContainer>
    );
  }

  // todo: always show widget
  if (mode === 'widget') {
    return (
      <SearchProfileContainer
        mobileCompact
        regionalFirstStepNext={transitionToModal}
      />
    );
  }

  return <></>;
};

export { SearchProfileWidgetContainer };
