const getBirthday = (age: number): string => {
  const today = new Date();
  const year = today.getFullYear() - age;
  let month = `${today.getMonth() + 1}`;
  let day = `${today.getDate()}`;
  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }
  return [year, month, day].join('-');
};

export { getBirthday };
