import { FC, useCallback, useContext, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

import { useHistory } from 'react-router-dom';
import {
  LoginResponse,
  RegistrationBy,
  useConfirmPhoneMutation,
  useRequestVerificationCodeMutation,
  useResendConfirmationCodeMutation,
  UserState,
  UserType,
  useSignInMutation,
  useSignUpMutation,
  useUserSelfDeleteMutation,
  useSetUserIdForExposesMutation,
} from '../../../../generated';
import { PhoneVerification } from '../../components/forms/phone-verification';
import { IRegisterForm } from '../../interfaces';
import {
  removeUserInput,
  setUserId,
  setUserInput,
  setUsername,
  setUserState,
  toggleLoading,
} from '../../redux/authSlice';
import { selectFormattedPhone } from '../../redux/selectors/selectFormattedPhone';
import { parseRegisterInput } from '../../utils/parse-register-input';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import {
  Headline1,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { StatusType } from '../../../common/components/verification-input/types';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import useGetLocalization from '../../../localization/get-localization';
import { BuyerRegisterForm } from './forms/register-form';
import Icon from '../../../common/components/ui/icon';
import { ReactComponent as CheckCircle } from '../../../../assets/streamline-light/interface-essential/form-validation/check-circle-1.svg';
import { device } from '../../../../style/theme';
import { Header } from './buyer-sign-up-page-styles';
import { isTokenExpired } from '../../../common/utils/is-token-expired';
import { siteMap } from '../../../../routes/site-map';
import { useActualExperiment } from '../../../experiments/hooks/useActualExperiment';

const LoaderContainer = styled.div<{ isiframe?: boolean }>`
  height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
  &&&& {
    > div {
      position: absolute;
    }
  }
`;

const PhoneVerificiationWrapper = styled.div`
  flex: 1;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;

export const SuccessMessageHeader = styled(Headline1)<{ color?: string }>`
  position: relative;
  color: ${(props) => (props.color ? props.color : props.theme.blue)};

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
  }
`;

export const SuccessMessageSubtitle = styled(ParagraphText)`
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 16px 0 24px 0;
`;

const BuyerSignUpPage: FC = () => {
  const isSubmitDisabled = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const lang = useGetLocalization();
  const themeContext = useContext(ThemeContext);
  const urlParams = new URLSearchParams(window.location.search);
  const addressId = urlParams.get('addressId') || '';
  const { push } = useHistory();

  const [verificationStatus, setVerificationStatus] = useState(
    StatusType.process
  );

  const userState = useAppSelector((state) => state.auth.userState);
  const formattedPhone = useAppSelector(selectFormattedPhone);
  const username = useAppSelector((state) => state.auth.userInput?.username);
  const email = useAppSelector((state) => state.auth.userInput?.email);
  const password = useAppSelector((state) => state.auth.userInput?.password);
  const firstName = useAppSelector((state) => state.auth.userInput?.name);
  const lastName = useAppSelector((state) => state.auth.userInput?.surname);
  const isRegisterFormLoading = useAppSelector((state) => state.auth.isLoading);
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const { registrationBy } = useActualExperiment();
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const exp = useAppSelector((state) => state.auth.exp);

  const [errors, setErrors] = useState<null | unknown>(null);

  // Mutations
  const [signUp] = useSignUpMutation();
  const [signIn] = useSignInMutation();
  const [confirmPhone] = useConfirmPhoneMutation();
  const [requestVerificationCode] = useRequestVerificationCodeMutation();
  const [resendConfirmationCode] = useResendConfirmationCodeMutation();
  const [changePhone] = useUserSelfDeleteMutation();
  const [setUserIdForExposes] = useSetUserIdForExposesMutation();

  const onRegisterSubmit: SubmitHandler<IRegisterForm> = useCallback(
    async (formData) => {
      isSubmitDisabled.current = true;

      const input = parseRegisterInput({
        formData,
        lang,
        userType: UserType.Buyer,
      });

      dispatch(setUserInput({ ...formData, password: input.password }));
      dispatch(toggleLoading(true));

      // Create the user account before phone verification
      try {
        const signUpResponse = await signUp({
          input: {
            ...input,
            hasPendingValuations: true,
            registrationBy: registrationBy || RegistrationBy.Mobile,
            isUserFromAnotherPortal: true,
          },
        }).unwrap();

        if (signUpResponse?.signUp?._id) {
          const {
            _id: id,
            username: newUsername,
            userState: newUserState,
          } = signUpResponse.signUp;

          await setUserIdForExposes({
            userEmail: formData.email,
            userId: id,
            addressId: Number(addressId),
          });

          batch(() => {
            dispatch(setUserId(id));
            dispatch(setUsername(newUsername));
            dispatch(setUserState(UserState.VerifyEmail));
          });
        }
      } catch (error: unknown) {
        setErrors(error);
      } finally {
        dispatch(toggleLoading(false));
        isSubmitDisabled.current = false;
      }
    },
    [addressId, dispatch, lang, registrationBy, setUserIdForExposes, signUp]
  );

  const onVerifyPhone = useCallback(
    async (confirmationCode: string) => {
      setVerificationStatus(StatusType.wait);
      if (confirmationCode && username && password && firstName && lastName) {
        try {
          dispatch(toggleLoading(true));
          const confirmPhoneResult = await confirmPhone({
            input: {
              username,
              confirmationCode,
            },
          }).unwrap();

          // set user state VerifyEmail
          if (confirmPhoneResult?.confirmPhone) {
            dispatch(setUserState(confirmPhoneResult.confirmPhone));
          }

          try {
            // Initiate login to get the jwt token
            const signInResponse = await signIn({
              input: {
                userAuthenticationKey: username,
                password,
                requestAccessToken: true,
              },
            }).unwrap();

            if (signInResponse.signIn) {
              const signInParams = signInResponse.signIn as LoginResponse;
              dispatch(removeUserInput());

              await requestVerificationCode({
                input: {
                  username,
                  accessToken: signInParams?.jwt?.token ?? '',
                  refreshToken: signInParams?.jwt?.refreshToken ?? '',
                  attributeName: 'email',
                  firstName,
                  lastName,
                },
              });
            }
          } catch (error: unknown) {
            setErrors(error);
          }
        } catch (error: unknown) {
          setErrors(error);
          setVerificationStatus(StatusType.error);
        } finally {
          dispatch(toggleLoading(false));
        }
      }
    },
    [
      username,
      password,
      firstName,
      lastName,
      dispatch,
      confirmPhone,
      signIn,
      requestVerificationCode,
    ]
  );

  const onResendConfirmationCode = useCallback(() => {
    if (email) {
      resendConfirmationCode({
        email,
      });
    }
  }, [resendConfirmationCode, email]);

  const onChangePhone = useCallback(async () => {
    if (username) {
      try {
        await changePhone({ username }).unwrap();

        batch(() => {
          dispatch(setUserId(''));
          dispatch(setUsername(''));
          dispatch(setUserState(UserState.Unauthenticated));
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [dispatch, changePhone, username]);

  if (!isAnonymousUser && exp) {
    const isExpired = isTokenExpired(exp);
    if (!isExpired) {
      push(siteMap.BuyerLandingPage.pathWithoutParams);
    }
  }

  return (
    <>
      {isRegisterFormLoading && (
        <LoaderContainer isiframe={isIframe}>
          <GlobalLoader />
        </LoaderContainer>
      )}
      {userState === UserState.Unauthenticated && (
        <BuyerRegisterForm
          onSubmit={onRegisterSubmit}
          errors={errors}
          isSubmitDisabled={isSubmitDisabled.current}
        />
      )}
      {userState === UserState.VerifyPhone && (
        <>
          <Header content={t('phone-verification.title')} />
          <PhoneVerificiationWrapper>
            <PhoneVerification
              phoneNumber={formattedPhone ?? ''}
              onVerifyPhone={onVerifyPhone}
              status={verificationStatus}
              resendCode={onResendConfirmationCode}
              changePhone={onChangePhone}
            />
          </PhoneVerificiationWrapper>
        </>
      )}
      {userState === UserState.VerifyEmail && (
        <div>
          <Icon
            icon={CheckCircle}
            color={themeContext.green}
            width={40}
            height={40}
            style={{ marginBottom: '16px' }}
          />
          <SuccessMessageHeader
            content={t('search-profile.personal-details.success-message.title')}
            color={themeContext.green}
          />
          <SuccessMessageSubtitle
            content={t(
              'search-profile.personal-details.success-message.subtitle'
            )}
          />
        </div>
      )}
    </>
  );
};

export { BuyerSignUpPage };
