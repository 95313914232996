import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { MainButton } from '../../../../common/components/ui/buttons';
import Icon from '../../../../common/components/ui/icon';
import { Avatar } from '../../appointment/avatar';
import { EmailLink } from '../../../../common/components/ui/typography/email-link';
import {
  BoxTitle,
  Headline2,
  PhoneLink,
} from '../../../../common/components/ui/typography';

export const AppointmentsContainer = styled.div`
  display: flex;

  align-items: center;

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.mobile} {
    margin: 0 !important;
  }
`;

export const Container = styled.div<{ marginBottom: number }>`
  position: relative;
  padding: 32px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.white};
  display: flex;
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.09);
  margin-bottom: ${(props) => props.marginBottom}px;
  @media ${device.mobile} {
    margin-bottom: 0;
    flex-direction: column;
    padding: 24px 16px 16px;
    box-shadow: none;
    border-top: 1px solid #d9d9d9;
  }
`;

export const AppointmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding: 32px;
  background: #002849;
  //background-color: ${(props) => props.theme.blue};
  max-width: 416px;
  height: fit-content;

  @media ${device.mobile} {
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    padding: 16px;
    background: #f2f4f7;
  }
`;

export const Name = styled(Headline2)`
  color: #fff;
  margin-bottom: 6px;
  @media ${device.mobile} {
    font-size: 20px;
    line-height: 1.4;
    color: #00305e;
  }
`;

export const Title = styled(Name)`
  text-align: center;
  color: ${(props) => props.theme.blue};
  margin-bottom: 24px;

  @media ${device.mobile} {
    margin-bottom: 16px;
  }
`;

export const IconWrapper = styled(Icon)`
  margin-bottom: 16px;

  path {
    stroke: ${(props) => props.theme.blue} !important;
  }

  path:first-of-type {
    stroke: ${(props) => props.theme.ctaBlue} !important;
  }
`;

export const Description = styled.p`
  font-size: 16px;
  font-family: 'Source Serif Pro', serif;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
`;

export const Information = styled.div`
  max-width: 352px;
  opacity: 0.6;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
  margin-bottom: 24px;

  @media ${device.mobile} {
    color: #00305e;
    opacity: 0.8;
    margin-bottom: 16px;
  }
`;

export const CTAButton = styled(MainButton)`
  text-transform: uppercase;

  @media ${device.mobile} {
    width: 100%;
    justify-content: center;
  }
`;

export const AvatarWrapper = styled(Avatar)`
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  img {
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  @media ${device.mobile} {
    margin-right: 0;
    margin-bottom: 16px;
    height: auto !important;
  }
`;

export const Position = styled.p`
  margin-bottom: 4px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.white};
`;

export const Qualification = styled.p`
  margin-bottom: 12px;
  font-family: Roboto, serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.white};
  opacity: 0.6;
`;

export const LegalModalToggler = styled.button`
  font-family: Roboto;
  opacity: 0.4;
  font-size: 10px;
  line-height: 1.6;
  margin-bottom: 12px;
  background: none;
  text-decoration: underline;
  outline: none;
  border: none;
  cursor: pointer;
  letter-spacing: 1px;
  color: #fff;
`;

export const PhoneLinkWrapper = styled(PhoneLink)`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
`;

export const EmailLinkWrapper = styled(EmailLink)`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
`;

export const ContactIconWrapper = styled(Icon)`
  margin-right: 8px;

  @media ${device.mobile} {
    & > svg > path {
      stroke: #00305e;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 4px;
    margin-bottom: 4px;

    & label {
      color: rgba(0, 48, 94, 0.5) !important;
    }

    & .input-wrapper div:last-child {
      margin-top: 0;
    }
  }
`;

export const ContactLabel = styled(BoxTitle)`
  color: white;
  font-weight: bold;
  opacity: 0.6;

  @media ${device.mobile} {
    color: #00305e;
    font-size: 12px;
  }
`;

export const Contact = styled(BoxTitle)`
  color: #fff;
  font-weight: bold;
  margin-left: 3px;

  @media ${device.mobile} {
    font-size: 12px;
    color: #00305e;
    line-height: 1.6;
  }
`;

export const SubmitButtonContainer = styled.div`
  margin-top: 24px;

  @media ${device.mobile} {
    margin-top: 16px;
    button {
      width: 100%;
    }
  }
`;

export const ContactFormContainer = styled.div`
  max-width: 636px;
  height: fit-content;
  padding: 32px;
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.09);

  input {
    margin-bottom: 12px;
    margin-top: 8px;
  }

  @media ${device.mobile} {
    padding: 16px;
  }
`;

export const InputV2WithSelect = styled.div`
  flex: 1;

  .input-v2-label {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: rgba(0, 48, 94, 0.7);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .input-v2-container {
    margin: 12px 0 12px 0;

    @media ${device.mobile} {
      margin: 4px 0 12px 0;
    }
  }

  .input-v2-input {
    margin: 0;
    border: none;

    &:focus {
      background-color: #f2f5f7;
    }
  }

  .input-v2-select {
    border: none;
    border-right: 1px solid rgba(0, 19, 37, 0.1);
    background-color: #f2f5f7;
  }
`;

export const InputCityAddressWrapper = styled.div`
  & > div {
    align-items: flex-start;
    margin: 0;
    flex-direction: column;

    input {
      max-width: 280px;
      min-width: 280px;
      background-color: #f2f5f7 !important;
      border: none;
    }

    @media ${device.mobile} {
      & > div {
        width: 100%;

        input {
          max-width: initial;
        }
      }
    }

    label {
      color: rgba(0, 48, 94, 0.7);
    }
  }
`;

export const InputAddressWrapper = styled.div`
  & > div {
    margin: 0;
  }

  & > div > div > div {
    flex-direction: column;
    align-items: flex-start;
  }

  & > div > div > div > div:first-child > div:last-child {
    display: none;
  }

  & > div > div > div input {
    border: none;
    text-align: left;
    background: #f2f5f7;
    margin-top: 0;

    &:focus {
      background-color: #f2f5f7;
    }
  }
`;

export const InputMessageWrapper = styled.div`
  label {
    color: rgba(0, 48, 94, 0.7);

    @media ${device.mobile} {
      color: rgba(0, 48, 94, 0.5);
    }
  }

  textarea {
    border: none;
    background-color: #f2f5f7 !important;
  }
`;

export const CheckboxWrapper = styled.div`
  margin-top: 24px;

  @media ${device.mobile} {
    margin-top: 16px;
  }
`;

export const RemarkBox = styled.div`
  margin-top: 24px;
  font-weight: bold;
  color: #00305e;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 1.67;

  @media ${device.mobile} {
    margin-top: 16px;
  }
`;
