import { Svg, Path, Circle, Rect } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const MoneyIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={
          'M16.941 4.155h-1.668a1.1 1.1 0 0 0-.409 2.123l1.693.677a1.1 1.1 0 0 1-.409 2.122H14.48m1.231-4.922V2.963m0 7.178V9.077'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Circle
        cx={'15.711'}
        cy={'6.616'}
        r={'5.64'}
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Rect
        x=".5"
        y="14.024"
        width="4"
        height="8"
        rx=".5"
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d="M4.5 20.586c4.5 1.5 6.425 2.438 8.1 2.438 2.4 0 4.035-1.507 10.9-4.938a2.762 2.762 0 0 0-3-1l-4.316 1.367"
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d="M4.5 15.024h3c2.353 0 4 2 4.5 2h3.5c1 0 1 2 0 2H10"
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { MoneyIcon };
