import { FC, memo, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { useParams } from 'react-router-dom';

import { Container, PaddingBox } from './financing-data-edit-form-styles';
import { BoxSelectorLine } from '../../../../../components/common/box-selector-line/box-selector-line';
import { Separator } from './separator/separator';
import { RadioSelectInput } from './radio-select-input/radio-select-input';
import {
  FinancingReason,
  Propositions,
  RateType,
} from '../../../../../../../generated';
import { useDirtyForm } from '../../../../hooks/callback-hooks/calculation-form/useDirtyForm';
import { useOnChangeValues } from '../../../../hooks/callback-hooks/calculation-form/useOnChangeValues';
import { useTotalTax } from '../../../../hooks/useTotalTax';
import { IFinancingForm } from '../../../../interface';
import { KaufFields } from './fields-containers/kauf-fields/kauf-fields';
import { FollowUpFinancingFields } from './fields-containers/follow-up-financing-fields/follow-up-financing-fields';
import { OwnConstructionFields } from './fields-containers/own-construction-fields/own-construction-fields';
import { ModernizationFields } from './fields-containers/modernization-fields/modernization-fields';
import { MortgageCertificateFields } from './fields-containers/mortgage-certificate-fields/mortgage-certificate-fields';

const boxes = [
  { value: '5', text: '5' },
  { value: '10', text: '10' },
  { value: '15', text: '15' },
  { value: '20', text: '20' },
  { value: '25', text: '25' },
  { value: '30', text: '30' },
];

interface IProps {
  methods: UseFormReturn<IFinancingForm>;
  finData: IFinancingForm;
  proposition?: Propositions;
  landTax: number;
  isMortgageCertificate: boolean;
}

const FinancingCalculationFormBase: FC<IProps> = ({
  methods,
  finData,
  proposition,
  landTax,
  isMortgageCertificate,
}) => {
  const { financingId } = useParams<{ financingId: string }>();
  const values = methods.getValues();
  const [rate, setRate] = useState<RateType>(finData.rateType);
  const [isWithBroker, setWithBroker] = useState(!!finData.brokerageFee);
  const { reason } = values;

  const isKauf =
    (reason === FinancingReason.PurchaseOfExistingProperty ||
      reason === FinancingReason.PurchaseOfNewBuilding) &&
    !isMortgageCertificate;
  const isFollowUpFinancing = reason === FinancingReason.FollowUpFinancing;
  const isOwnConstruction = reason === FinancingReason.OwnConstructionProject;
  const isModernization =
    reason === FinancingReason.Modernization ||
    reason === FinancingReason.RaisingCapital;

  const { totalTax, onChangeTax } = useTotalTax({ isWithBroker });

  // set new data when change a financing variant
  useEffect(() => {
    setRate(finData.rateType);
    setWithBroker(!!finData.brokerageFee);
  }, [finData.name, financingId]);

  // our dirty function for form
  const { handleChangeDirty, setDirtyTrue } = useDirtyForm();

  // check if form values are equal
  const getDisabledFields = (
    data: Partial<IFinancingForm>,
    isDesiredRate: boolean
  ) => {
    return {
      purchasePrice: data.purchasePrice ?? 0,
      equityCapital: data.equityCapital ?? 0,
      desiredLoanAmount: data.desiredLoanAmount ?? 0,
      brokerFeePercent: isWithBroker ? data.brokerFeePercent : null,
      desiredRate: isDesiredRate ? data.desiredRate : null,
      repaymentRate: isDesiredRate ? null : data.repaymentRate,
      ...(data.payoutDate ? { payoutDate: new Date(data.payoutDate) } : {}),
      landTax: null,
      name: '',
    };
  };

  const financingDataWithoutDisabledFields = {
    ...finData,
    ...getDisabledFields(finData, finData.rateType === RateType.DesiredRate),
  };
  const valuesWithoutDisabledFields = {
    ...values,
    ...getDisabledFields(values, rate === RateType.DesiredRate),
  };
  const isEqualForm = isEqual(
    financingDataWithoutDisabledFields,
    valuesWithoutDisabledFields
  );
  useEffect(() => {
    // if is equal => form isn't dirty
    if (isEqualForm) {
      handleChangeDirty({ state: false });
    } else {
      handleChangeDirty({ state: true });
    }
  }, [isEqualForm, rate]);

  // observe the purchase price
  const { onChangePurchasePrice } = useOnChangeValues({
    finData,
  });

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Container>
        {isMortgageCertificate && (
          <MortgageCertificateFields
            finData={finData}
            isWithBroker={isWithBroker}
            setWithBroker={setWithBroker}
            onChangeTax={onChangeTax}
            setDirtyTrue={setDirtyTrue}
            onChangePurchasePrice={onChangePurchasePrice}
          />
        )}
        {isKauf && (
          <KaufFields
            finData={finData}
            landTax={landTax}
            isWithBroker={isWithBroker}
            setWithBroker={setWithBroker}
            totalTax={totalTax}
            onChangeTax={onChangeTax}
            setDirtyTrue={setDirtyTrue}
            onChangePurchasePrice={onChangePurchasePrice}
            // isPurchaseOfExistingProperty={
            //   reason === FinancingReason.PurchaseOfExistingProperty
            // }
          />
        )}
        {isFollowUpFinancing && (
          <FollowUpFinancingFields
            finData={finData}
            setDirtyTrue={setDirtyTrue}
          />
        )}
        {isOwnConstruction && (
          <OwnConstructionFields
            finData={finData}
            landTax={landTax}
            isWithBroker={isWithBroker}
            setWithBroker={setWithBroker}
            totalTax={totalTax}
            onChangeTax={onChangeTax}
            setDirtyTrue={setDirtyTrue}
          />
        )}
        {isModernization && (
          <ModernizationFields finData={finData} setDirtyTrue={setDirtyTrue} />
        )}
        {!isMortgageCertificate && (
          <>
            <Separator isBold mt={12} mb={12} />
            <BoxSelectorLine
              name="debitRateCommitment"
              label="financing-portal-page.tabs.calculator.edit.inputs.debit-rate-commitment.label"
              isOldDesign
              isLabelSaturated
              defaultValue={proposition?.fixedInterestRateInYearsMinMax}
              handleChangeProp={setDirtyTrue}
              boxes={boxes}
            />

            <Separator
              mt={12}
              label="financing-portal-page.tabs.calculator.edit.inputs.calculation-method.label"
            />
            <PaddingBox>
              <RadioSelectInput
                rate={rate}
                setRate={setRate}
                finData={finData}
                handleChangeProp={setDirtyTrue}
              />
            </PaddingBox>
          </>
        )}
      </Container>
    </form>
  );
};

const FinancingDataEditForm = memo(FinancingCalculationFormBase);

export { FinancingDataEditForm };
