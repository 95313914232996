import { ReactComponent as Pencil } from 'assets/streamline-light/interface-essential/edit/pencil.svg';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { Modal, ModalTitle } from '../../../../common/components/modal';
import { toggleIsEditValuationModalOpen } from '../../redux/valuationWizardSlice';
import { EditValuationForm } from '../forms/edit-valuation-form';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';

const EditValuationModal = (): JSX.Element => {
  const isEditValuationModalOpen = useAppSelector(
    (state) => state.valuationWizard.isEditValuationModalOpen
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const closeModal = () => dispatch(toggleIsEditValuationModalOpen(false));
  const wrapperStyles = isMobileSize
    ? {
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
        height: '100%',
        overflow: 'overlay',
        padding: '24px',
      }
    : { maxWidth: '512px' };

  return (
    <Modal
      isOpen={isEditValuationModalOpen}
      wrapperStyles={wrapperStyles}
      onClose={closeModal}
    >
      {!isMobileSize && (
        <ModalTitle
          icon={Pencil}
          text={t('edit.valuation.modal.title')}
          iconPosition="left"
          textAlign="left"
        />
      )}
      <EditValuationForm />
    </Modal>
  );
};

export { EditValuationModal };
