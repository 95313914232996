import { ReactComponent as Star } from 'assets/streamline-light/social-medias-rewards-rating/rating/rating-star.svg';
import { FC, memo, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Condition, PropertyCode, Quality } from '../../../../../../generated';
import { device } from '../../../../../../style/theme';
import { BorderButton } from '../../../../../common/components/ui/buttons';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { VALUATION_FORM_KEYS } from '../../../constants';
import { prevStepAction } from '../../../redux/valuationWizardSlice';
import { BoxRatingSelector } from '../../../../components/common/box-rating-selector';
import { NextButton } from '../../../../components/common/next-button';

const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div<{ isiframe?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  @medit ${device.tablet} {
    flex-grow: 0;
  }

  &:last-of-type {
    flex: 0;
    margin-top: 12px;
    justify-content: flex-start;
    flex-direction: row;

    > *:last-of-type {
      margin-left: auto;
      @media ${({ isiframe }) => (isiframe ? '0' : device.tablet)} {
        margin-top: 32px;
      }
    }
  }
`;

const FourthStepBase: FC = () => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();

  const isMultiFamilyHouse = useAppSelector(
    (state) =>
      state.valuationWizard.userInput?.code === PropertyCode.MultiFamilyHouse
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const switchToPrevStep = useCallback(
    (e?: MouseEvent) => {
      e?.preventDefault();

      if (isMultiFamilyHouse) {
        dispatch(prevStepAction());
        dispatch(prevStepAction());
      } else {
        dispatch(prevStepAction());
      }
    },
    [dispatch, isMultiFamilyHouse]
  );

  return (
    <Container>
      <Row>
        <BoxRatingSelector
          name={VALUATION_FORM_KEYS.QUALITY}
          boxes={[
            {
              title: 'valuation.form.third-step.quality.simple.title',
              text: 'valuation.form.third-step.quality.simple.content',
              value: Quality.Simple,
              icon: Star,
            },
            {
              title: 'valuation.form.third-step.quality.normal.title',
              text: 'valuation.form.third-step.quality.normal.content',
              value: Quality.Normal,
              icon: Star,
            },
            {
              title: 'valuation.form.third-step.quality.high_quality.title',
              text: 'valuation.form.third-step.quality.high_quality.content',
              value: Quality.HighQuality,
              icon: Star,
            },
            {
              title: 'valuation.form.third-step.quality.luxury.title',
              text: 'valuation.form.third-step.quality.luxury.content',
              value: Quality.Luxury,
              icon: Star,
            },
          ]}
          column
        />
      </Row>
      <Row>
        <BoxRatingSelector
          name={VALUATION_FORM_KEYS.CONDITION}
          boxes={[
            {
              title:
                'valuation.form.second-step.condition.new/recently-renovated.title',
              text: 'valuation.form.second-step.condition.new/recently-renovated.content',
              value: Condition.NewOrRecentlyRenovated,
              icon: Star,
            },
            {
              title:
                'valuation.form.second-step.condition.well-maintained.title',
              text: 'valuation.form.second-step.condition.well-maintained.content',
              value: Condition.WellMaintained,
              icon: Star,
            },
            {
              title:
                'valuation.form.second-step.condition.renovation-needed.title',
              text: 'valuation.form.second-step.condition.renovation-needed.content',
              value: Condition.RenovationNeeded,
              icon: Star,
            },
          ]}
          column
          containerTitle={t('valuation.form.second-step.condition.title')}
          reverseCounting
        />
      </Row>
      <Row isiframe={isIframe}>
        {(!isMobileSize || isIframe) && (
          <BorderButton
            id="step-4"
            label={t('button.back')}
            onClick={switchToPrevStep}
          />
        )}
        <NextButton
          id="step-4"
          watchedKeys={[
            VALUATION_FORM_KEYS.QUALITY,
            VALUATION_FORM_KEYS.CONDITION,
          ]}
          label={t('button.next-step')}
        />
      </Row>
    </Container>
  );
};

const FourthStep = memo(FourthStepBase);

export { FourthStep };
