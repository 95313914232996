import { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../../../common/hooks/useIsMobileSize';
import {
  Modal,
  ModalTitle,
} from '../../../../../../../common/components/modal';
import { MainButton } from '../../../../../../../common/components/ui/buttons';
import { setIsPropertyPlanOpen } from '../../../../redux/exposeSlice';

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
`;

const Title = styled(ModalTitle)`
  font-size: 20px !important;
  padding-top: 1px !important;
  line-height: 23px !important;
  font-weight: 500 !important;
  margin: 24px 0 0 0;
`;

const CTAContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef;
`;

const CTA = styled(MainButton)`
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
  background-color: rgba(0, 48, 94, 0.1);
  color: #00305e;
  padding: 10px 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  border-radius: 3px;
  border: 0;
  height: 48px;

  &:hover {
    background-color: rgba(0, 48, 94, 0.2);
  }
`;

const CloseButton = styled.button`
  background: 0 0;
  border: 0;
  padding: 15px;
  margin: -15px -15px -15px auto;
  cursor: pointer;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  font-family: 'Source Serif Pro, sans-serif';
`;

const ImgContainer = styled.div`
  flex: 1 1 auto;
  padding: 15px;
`;

const StyledImg = styled.img`
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto;
`;

interface IProps {
  imgSrc: string;
}

const PropertyPlanModalBase: FC<IProps> = ({ imgSrc }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();

  const closeModal = useCallback(() => {
    dispatch(setIsPropertyPlanOpen(false));
  }, [dispatch]);

  const wrapperStyles = useMemo(
    () =>
      !isMobileSize
        ? {
            maxWidth: '800px',
            maxHeight: '100%',
            width: '100%',
            height: '100%',
            overflow: 'overlay',
            padding: 0,

            // display: 'flex',
            // flexDirection: 'column',
            // pointerEvents: 'auto',
            backgroundColor: '#fff',
            backgroundClip: 'padding-box',
            border: '1px solid rgba(0, 0, 0, .2)',
            borderRadius: '.3rem',
            outline: 0,
          }
        : {
            overflow: 'overlay',
            maxWidth: '500px',
            padding: 0,
            height: '100%',
          },
    [isMobileSize]
  );

  const isModalOpen = useAppSelector(
    (state) => state.exposeDetails.isPropertyPlanOpen
  );

  return (
    <Modal
      isOpen={isModalOpen}
      wrapperStyles={wrapperStyles}
      onClose={closeModal}
    >
      <TitleContainer>
        <Title
          text={t(
            'search-profile.suggested-properties.expose-details.property-plan'
          )}
          textAlign="left"
        />
        <CloseButton type={'button'} onClick={closeModal}>
          ×
        </CloseButton>
      </TitleContainer>

      <ImgContainer>
        <StyledImg
          width={1000}
          height={762}
          src={imgSrc}
          alt={'property-plan'}
        />
      </ImgContainer>

      <CTAContainer>
        <CTA
          label={t('accept-purchase-offer.modal.button.close')}
          onClick={closeModal}
        />
      </CTAContainer>
    </Modal>
  );
};

const PropertyPlanModal = memo(PropertyPlanModalBase);

export { PropertyPlanModal };
