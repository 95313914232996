import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as MyProperties } from 'assets/navigation/my-properties.svg';
import { ReactComponent as SendEmail } from 'assets/navigation/send-email.svg';
import { ReactComponent as Megaphone } from 'assets/navigation/megaphone.svg';
import { ReactComponent as BuildingSearch } from 'assets/navigation/building-search.svg';
import { ReactComponent as Bank } from 'assets/navigation/bank.svg';
import { ReactComponent as Config } from 'assets/navigation/config.svg';
import { ReactComponent as Logout } from 'assets/navigation/logout.svg';
import { siteMap } from '../../../routes/site-map';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { resetValuationWizard } from '../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import LngLink from '../../localization/lng-link';
import { resetMapState } from '../../property/redux/dynamicMapUtilsSlice';
import { logout, toggleisEditProfileModalOpen } from '../redux/authSlice';
import { resetSPC } from '../../forms/form-search-profile/redux/searchProfileSlice';

interface IProps {
  closeDropdown: () => void;
  isMobile?: boolean;
}

const DropdownWrapper = styled.div<{ isMobile?: boolean }>`
  position: absolute;
  top: 100%;
  z-index: 2004;
  right: 0;
  transform: translateX(50%);
  padding-top: 20px;

  ${(props) =>
    props.isMobile &&
    `
    transform: none;
    right: 30px;
    top: 55px;
  `}
`;

const Dropdown = styled.div`
  background: ${({ theme }) => theme.white};
  border: 1px solid #d9e0e7;
  box-shadow: 0px 8px 32px -6px rgba(20, 23, 26, 0.12),
    0px 12px 42px -4px rgba(20, 23, 26, 0.12);
  border-radius: 5px;
  width: 273px;
`;

const DropdownOption = styled(LngLink)<{
  $marginTop?: boolean;
  $marginBottom?: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 5px;
  margin: 5px;

  ${(props) => props.$marginTop && 'margin-top: 10px;'}
  ${(props) => props.$marginBottom && 'margin-bottom: 10px;'}

  &:hover {
    transition: all 0.3s;
    border: 1px solid #d9e0e7;
    background: rgba(0, 48, 94, 0.05);
  }
`;

const DropdownOptionBtn = styled.a<{
  $marginTop?: boolean;
  $marginBottom?: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 5px;
  margin: 5px;
  ${(props) => props.$marginTop && 'margin-top: 10px;'}
  ${(props) => props.$marginBottom && 'margin-bottom: 10px;'}

  &:hover {
    transition: all 0.3s;
    border: 1px solid #d9e0e7;
    background: rgba(0, 48, 94, 0.05);
  }
`;

const OptionIconWrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  margin-right: 12px;
  line-height: 0;
  & svg {
    width: 100%;
    height: auto;
  }
`;

const HorizontalBar = styled.div`
  width: 100%;
  height: 1px;
  background: #d9e0e7;
  margin: 7px 0;
`;

const OptionText = styled.span`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.25px;
  color: #00305e;
`;

const DropdownHeader = styled.div`
  background: rgba(0, 48, 94, 0.05);
  padding: 12px 16px;
  border-bottom: 1px solid #d9e0e7;
  display: flex;
  gap: 12px;
  align-items: center;
`;

const DropdownHeaderLogo = styled.div`
  background: #00305e;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 1;
  border-radius: 100%;
  text-transform: uppercase;
`;

const NameBox = styled.p`
  margin-bottom: 4px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.25px;
  color: #00305e;
  text-align: left;
`;

const EmailBox = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.25px;
  color: rgba(0, 48, 94, 0.6);
  text-align: left;
`;

const ProfileSettings = ({ closeDropdown, isMobile }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);

  // onOffice marketplace iframe URL params
  const urlParams = new URLSearchParams(window.location.search);
  const brokerId = urlParams.get('userid') || '';
  const propertyOnOfficeId = urlParams.get('EstateId') || '';

  const onLogout = () => {
    closeDropdown();
    batch(() => {
      dispatch(logout());
      dispatch(resetValuationWizard());
      dispatch(resetSPC());
      dispatch(resetMapState());
    });
  };

  const onOpenProfileSettings = () => {
    closeDropdown();
    dispatch(toggleisEditProfileModalOpen(true));
  };

  const initials = `${user?.name[0]}${user?.surname[0]}`;
  const fullName = `${user?.name} ${user?.surname}`;

  return (
    <DropdownWrapper onMouseLeave={closeDropdown} isMobile={isMobile}>
      <Dropdown>
        <DropdownHeader>
          <DropdownHeaderLogo>{initials}</DropdownHeaderLogo>
          <div>
            <NameBox>{fullName}</NameBox>
            <EmailBox>{user?.email}</EmailBox>
          </div>
        </DropdownHeader>

        <DropdownOption absolute to={siteMap.BuyerLandingPage.path} $marginTop>
          <OptionIconWrapper>
            <BuildingSearch />
          </OptionIconWrapper>

          <OptionText>{t('header.authentication.searchprofile')}</OptionText>
        </DropdownOption>

        <DropdownOption absolute to={siteMap.BuyerLandingPage.path}>
          <OptionIconWrapper>
            <SendEmail />
          </OptionIconWrapper>

          <OptionText>
            {t('header.authentication.my-properties-requests')}
          </OptionText>
        </DropdownOption>

        <DropdownOption absolute to={siteMap.FinancingLandingPage.path}>
          <OptionIconWrapper>
            <Bank />
          </OptionIconWrapper>

          <OptionText>{t('header.authentication.my-finance')}</OptionText>
        </DropdownOption>

        <DropdownOption absolute to={siteMap.OwnerLandingPage.path}>
          <OptionIconWrapper>
            <MyProperties />
          </OptionIconWrapper>

          <OptionText>{t('header.authentication.my-properties')}</OptionText>
        </DropdownOption>

        <DropdownOption
          absolute
          to={
            brokerId && propertyOnOfficeId
              ? `${siteMap.SellerPropertiesPage.pathWithoutParams}?userid=${brokerId}&EstateId=${propertyOnOfficeId}`
              : siteMap.SellerLandingPage.path
          }
        >
          <OptionIconWrapper>
            <Megaphone />
          </OptionIconWrapper>

          <OptionText>{t('header.authentication.my-sale')}</OptionText>
        </DropdownOption>

        <HorizontalBar />

        <DropdownOptionBtn onClick={onOpenProfileSettings}>
          <OptionIconWrapper>
            <Config />
          </OptionIconWrapper>

          <OptionText>{t('header.authentication.profile-settings')}</OptionText>
        </DropdownOptionBtn>

        <DropdownOptionBtn onClick={onLogout} $marginBottom>
          <OptionIconWrapper>
            <Logout />
          </OptionIconWrapper>

          <OptionText>{t('header.authentication.signout')}</OptionText>
        </DropdownOptionBtn>
      </Dropdown>
    </DropdownWrapper>
  );
};

export { ProfileSettings };
