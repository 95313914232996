import { FC } from 'react';
import { CertificateContainer } from './financing-certificate-container-styles';
import { FinancingCertificate } from '../../../../../common/financing-certificate/financing-certificate';
import { useAppSelector } from '../../../../../../../common/hooks';
import { useFinancingCalculation } from '../../../../../../hooks/calculation-tab/useFinancingCalculation';

interface IProps {
  mt: number;
  lastSync: Date;
  maximalPurchasePrice?: number | null;
}

const FinancingCertificateContainer: FC<IProps> = ({
  mt,
  lastSync,
  maximalPurchasePrice,
}) => {
  const user = useAppSelector((state) => state.auth.user);
  const name = `${user?.name} ${user?.surname}`;
  const { isLoading } = useFinancingCalculation();
  const { isUpdatingOverdueFinancingPropositions } = useAppSelector(
    (state) => state.financing.editWidget
  );

  return (
    <CertificateContainer mt={mt}>
      <FinancingCertificate
        isLoading={isLoading || isUpdatingOverdueFinancingPropositions}
        isCalculationPage
        name={name}
        loanAmount={maximalPurchasePrice}
        dateProp={lastSync}
      />
    </CertificateContainer>
  );
};

export { FinancingCertificateContainer };
