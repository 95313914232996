import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import Collapse, { Panel } from 'rc-collapse';
import { CollapsePanelProps } from 'rc-collapse/lib/interface';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { InputTextArea } from 'modules/common/components/form/input/input-text-area';
import { InputV2 } from 'modules/forms/components/common/input-v2/input-v2';
import { Switch } from 'modules/common/components/ui/switch';
import { useParams } from 'react-router-dom';

import { ReactComponent as InformationCircle } from 'assets/streamline-light/interface-essential/alerts/information-circle.svg';
import { ReactComponent as ArrowDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import { ReactComponent as Star } from 'assets/streamline-light/social-medias-rewards-rating/rating/rating-star.svg';
import { ReactComponent as AlertTriangle } from 'assets/streamline-light/interface-essential/alerts/alert-triangle.svg';

import { Tooltip } from '../../../../common/components/tooltip';
import { ParagraphText } from '../../../../common/components/ui/typography';
import BigCardContainer from '../elements/big-card-container';
import { CardContent } from '../elements/card-content';
import { CardContentHolder } from '../elements/card-content-holder';
import { CardTitle } from '../elements/card-title';
import motion from '../../../../common/components/ui/faq-accordion/utils';
import Icon from '../../../../common/components/ui/icon';
import {
  RatingWrap,
  TextareaWrap,
  RatingLegend,
  RatingItem,
  ButtonsWrap,
  InputWrapper,
  FeedbackWrapper,
  SwitchWrapper,
  SubmitButton,
  TooltipParagraph,
  TooltipContainer,
  TooltipMail,
  Form,
  FeedbackHeader,
  Row,
  SwitchLabel,
  MarketDataUpdateDate,
  MarketDataUpdateMessage,
  MarketDataUpdateMessageContainer,
  MarketPriceCardElementsHolder,
  Delimiter,
} from './market-price-card-styles';
import {
  PropertyWidgetFeedback,
  useSendFeedbackOfOwnerPortalMutation,
} from '../../../../../generated';
import { useAppSelector } from '../../../../common/hooks';
import { AlertInfo } from '../../../../common/components/ui/alerts/info';

interface IProps {
  title: string;
  content: string;
  elementsContent: string;
  priceHubbleId?: string | null;
  marketDataMessage: string;
  marketDataDate: string;
  children?: JSX.Element | JSX.Element[];
  price?: string;
  propertyWidgetFeedbackData?: PropertyWidgetFeedback | null;
  isChangeNotAllowed?: boolean;
}

interface RatingProps {
  rating: string;
  onChange?: (value: string) => void;
}

const Rating = ({ rating, onChange }: RatingProps) => {
  const ratings = ['1', '2', '3', '4', '5', '6'];
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <>
      <RatingWrap>
        {ratings.map((r, i) => (
          <RatingItem
            key={i}
            className={Number(rating) >= Number(r) ? 'active' : ''}
            onClick={() => {
              if (onChange) {
                onChange(r);
              }
            }}
          >
            <Icon
              icon={Star}
              width={32}
              height={32}
              color={themeContext.grey}
            />
          </RatingItem>
        ))}
      </RatingWrap>
      <RatingLegend>
        <ParagraphText
          content={t('valuation.overview.market.price.feedback.low')}
        />
        <ParagraphText
          content={t('valuation.overview.market.price.feedback.high')}
        />
      </RatingLegend>
    </>
  );
};

interface IRatingForm {
  price: number;
  text: string;
}

const MarketPriceCard = ({
  title,
  content,
  elementsContent,
  marketDataMessage,
  marketDataDate,
  children,
  price,
  priceHubbleId,
  propertyWidgetFeedbackData,
  isChangeNotAllowed,
}: IProps): JSX.Element => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const methods = useForm<IRatingForm>({ mode: 'onSubmit' });
  const values = useWatch<IRatingForm>({ control: methods.control });
  const [rating, setRating] = useState<string>('0');
  const [shownMessage, setShownMessage] = useState(false);
  const [sendFeedback, { data, isLoading }] =
    useSendFeedbackOfOwnerPortalMutation();
  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);
  const isTabListNearTop = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );

  useEffect(() => {
    if (propertyWidgetFeedbackData) {
      setRating(propertyWidgetFeedbackData.rating);
      if (propertyWidgetFeedbackData.text) {
        setShownMessage(true);
      } else {
        setShownMessage(false);
      }
      methods.reset({
        price: Number(propertyWidgetFeedbackData.numericValue) || 0,
        text: propertyWidgetFeedbackData.text || '',
      });
    }
  }, [propertyWidgetFeedbackData]);

  const onSubmit: SubmitHandler<IRatingForm> = useCallback(
    async (formData) => {
      if (isBrokerView) {
        return;
      }

      const closeButton = document.querySelector(
        '#close-button'
      ) as HTMLButtonElement;
      closeButton?.click();

      await sendFeedback({
        input: {
          _id: propertyId,
          rating: rating || '',
          numericValue: formData.price ? String(formData.price) : '',
          text: shownMessage ? formData.text : '',
          priceHubbleId: priceHubbleId ?? '',
        },
      });
    },
    [rating, shownMessage, priceHubbleId, isBrokerView]
  );

  const onRatingChange = useCallback(
    (value: string) => {
      if (isBrokerView) {
        return;
      }
      setRating(value);
    },
    [isBrokerView]
  );

  const isDisabledFeedbackSubmit =
    (Number(rating) <= 0 && !values.text && !values.price) || isBrokerView;
  const FeedbackComponent = (
    <FeedbackWrapper>
      <Collapse
        openMotion={motion}
        className="outer-accordion"
        expandIcon={({ isActive }: CollapsePanelProps) => (
          <Icon
            id="close-button"
            width={16}
            height={16}
            icon={ArrowDown}
            color={themeContext.blue}
            style={{
              transition: `transform 500ms ease`,
              transform: isActive ? 'rotate(-180deg)' : 'none',
            }}
          />
        )}
      >
        <Panel
          key="1"
          header={
            <FeedbackHeader
              content={t('valuation.overview.market.price.feedback.title')}
            />
          }
          headerClass="outer-header"
          className="outer-content"
        >
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              {isBrokerView && (
                <AlertInfo
                  style={{
                    backgroundColor: '#a2575733',
                    border: `1px solid #a2575733`,
                    margin: '0 0 12px 0',
                  }}
                  text={t('valuation.overview.market.price.feedback.tooltip')}
                  icon={
                    <Icon
                      icon={AlertTriangle}
                      width={16}
                      height={16}
                      color={themeContext.blue}
                      style={{
                        alignSelf: 'center',
                        marginRight: '12px',
                        minWidth: '16px',
                      }}
                      strokeWidth={'1.5'}
                    />
                  }
                />
              )}
              <Rating rating={rating} onChange={onRatingChange} />

              <Delimiter />

              <InputWrapper>
                <InputV2
                  name="price"
                  label="valuation.overview.market.price.feedback.value-label"
                  placeholder={price}
                  inputAdornment="€"
                  isSeparate
                  isNoBorder
                  isDisabled={isBrokerView}
                />
              </InputWrapper>

              <SwitchWrapper>
                <Switch
                  className="switch"
                  isChecked={shownMessage}
                  onChange={() => {
                    setShownMessage(!shownMessage);
                  }}
                />
                <SwitchLabel
                  content={t(
                    'valuation.overview.market.price.feedback.add-comment'
                  )}
                />
              </SwitchWrapper>

              {shownMessage && (
                <TextareaWrap>
                  <InputTextArea
                    name={'text'}
                    label=""
                    rules={{
                      required: false,
                    }}
                    height={120}
                    noTranslate
                    style={{ lineHeight: 2, background: themeContext.white }}
                    labelStyle={{
                      display: 'none',
                    }}
                    placeholder={t(
                      'valuation.overview.market.price.feedback.message-placeholder'
                    )}
                    isDisabled={isBrokerView}
                  />
                </TextareaWrap>
              )}
              <ButtonsWrap>
                {isChangeNotAllowed ? (
                  <Tooltip
                    id="market-price-submit.tooltip"
                    content={
                      <SubmitButton
                        label={t(
                          'valuation.overview.market.price.feedback.submit'
                        )}
                        isDisabled
                      />
                    }
                  >
                    <TooltipParagraph>
                      {t('property.spectator.not-allowed.tooltip')}
                    </TooltipParagraph>
                  </Tooltip>
                ) : (
                  <SubmitButton
                    label={t('valuation.overview.market.price.feedback.submit')}
                    isDisabled={isDisabledFeedbackSubmit}
                  />
                )}
              </ButtonsWrap>
            </Form>
          </FormProvider>
        </Panel>
      </Collapse>
    </FeedbackWrapper>
  );

  return (
    <BigCardContainer>
      <CardContentHolder>
        <Row>
          <CardTitle content={title} />
          <Tooltip
            stayOnHover
            icon={InformationCircle}
            id="market-price.tooltip"
            position="bottom"
          >
            <TooltipContainer>
              <TooltipParagraph>
                {t('market-price.tooltip.first-paragraph')}
              </TooltipParagraph>
              <TooltipParagraph>
                {t('market-price.tooltip.second-paragraph')}
              </TooltipParagraph>
              <TooltipMail href={`mailto:${t('market-price.tooltip.mail')}`}>
                {t('market-price.tooltip.mail')}
              </TooltipMail>
            </TooltipContainer>
          </Tooltip>
        </Row>
        <CardContent content={content} />
      </CardContentHolder>
      <CardContentHolder margin="8px 0 0 0">
        <CardContent content={elementsContent} />
        <MarketPriceCardElementsHolder>
          {children}
        </MarketPriceCardElementsHolder>
      </CardContentHolder>
      <CardContentHolder>
        <MarketDataUpdateMessageContainer>
          <MarketDataUpdateDate content={marketDataDate} />
          <MarketDataUpdateMessage content={marketDataMessage} />
        </MarketDataUpdateMessageContainer>
      </CardContentHolder>
      {FeedbackComponent}
    </BigCardContainer>
  );
};

export { MarketPriceCard };
