import { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BackgroundImg from 'assets/expose-request-page.png';
import marketPriceBlur from 'assets/images/icons/property-valuation/market-price-blur.png';
import performanceBlur from 'assets/images/icons/property-valuation/performance-blur.png';
import provenExpert from 'assets/images/icons/property-valuation/proven-expert.png';

import {
  Container,
  Header,
  Info,
  InfoHeader,
  InfoWrapper,
  MapContainer,
  MarketHeader,
  MarketImage,
  PerformanceHeader,
  PerformanceImage,
  ProvenExpertContainer,
  ProvenExpertImage,
  Row,
  SubHeader,
  Wrapper,
} from './valuation-info-container-styles';
import { PropertyDetails } from '../../../common/property-details/property-details';
import { AddressBar } from '../../../../../../common/components/ui/hero-slider/address-bar';
import { useAppSelector } from '../../../../../../common/hooks';
import { Tooltip } from '../../../../../../common/components/tooltip';
import { ReactComponent as InformationCircle } from '../../../../../../../assets/streamline-light/interface-essential/alerts/information-circle.svg';
import {
  TooltipContainer,
  TooltipParagraph,
} from '../../../../../components/common/input-v2/input-v2-styles';
import { ModalMap } from '../../../../../components/fullscreen-modal/modal-map/modal-map';

const ValuationInfoContainerBase = forwardRef<HTMLDivElement>((empty, ref) => {
  const { t } = useTranslation();
  const [marginLeft, setMarginLeft] = useState(0);
  const storedAddress = useAppSelector(
    (state) => state.valuationWizardV2.selectedAddress
  );
  const viewport = useAppSelector(
    (state) => state.valuationWizardV2.map.viewport
  );
  const settings = useAppSelector(
    (state) => state.valuationWizardV2.map.settings
  );
  const wrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMarginLeft(wrapper?.current?.clientWidth || 0);
  }, [wrapper?.current?.clientWidth]);

  return (
    <Container src={BackgroundImg}>
      <Wrapper ref={wrapper}>
        <Header>
          {t('property-valuation.form.redesign.info-screen.title')}
        </Header>
        <MapContainer>
          <ModalMap viewport={viewport} settings={settings} />
        </MapContainer>
        <AddressBar show address={storedAddress?.place_name} />
        <InfoWrapper>
          <SubHeader>
            {t(
              'property-valuation.form.redesign.info-screen.details-subtitle'
            ).toUpperCase()}
          </SubHeader>
          <InfoHeader>
            {t('property-valuation.form.redesign.info-screen.details-title')}
          </InfoHeader>
          <PropertyDetails />
          <Row>
            <MarketHeader ref={ref}>
              {t(
                'property-valuation.form.redesign.info-screen.market-price-title'
              )}
            </MarketHeader>
            <Info>
              <Tooltip
                icon={InformationCircle}
                id={
                  'property-valuation.form.redesign.info-screen.market-price-info'
                }
                position="bottom"
                width={16}
                height={16}
              >
                <TooltipContainer>
                  <TooltipParagraph>
                    {t(
                      'property-valuation.form.redesign.info-screen.market-price-info'
                    )}
                  </TooltipParagraph>
                </TooltipContainer>
              </Tooltip>
            </Info>
          </Row>
          <MarketImage src={marketPriceBlur} alt="market price" />
          <Row>
            <PerformanceHeader>
              {t(
                'property-valuation.form.redesign.info-screen.performance-title'
              )}
            </PerformanceHeader>
            <Info>
              <Tooltip
                icon={InformationCircle}
                id={
                  'property-valuation.form.redesign.info-screen.performance-info'
                }
                position="bottom"
                width={16}
                height={16}
              >
                <TooltipContainer>
                  <TooltipParagraph>
                    {t(
                      'property-valuation.form.redesign.info-screen.performance-info'
                    )}
                  </TooltipParagraph>
                </TooltipContainer>
              </Tooltip>
            </Info>
          </Row>
          <PerformanceImage src={performanceBlur} alt="performance" />
        </InfoWrapper>
        <ProvenExpertContainer>
          <ProvenExpertImage
            marginLeft={marginLeft}
            src={provenExpert}
            alt="proven expert"
          />
        </ProvenExpertContainer>
      </Wrapper>
    </Container>
  );
});

const ValuationInfoContainer = memo(ValuationInfoContainerBase);

export { ValuationInfoContainer };
