import { FC } from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { formatSyncDate } from '../../../seller/components/general-statistics-card/general-statistics-card';

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    // marginBottom: '5mm',
    marginBottom: '24px',
  },
  title: {
    opacity: 1,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'SourceSerifPro',
    fontSize: '24px',
    fontWeight: 'semibold',
    textAlign: 'center',
    marginBottom: '8px',
  },
  address: {
    opacity: 1,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'SourceSerifPro',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '8px',
  },
  textBold: {
    opacity: 1,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'SourceSerifPro',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '8px',
  },
  text: {
    opacity: 1,
    color: 'rgba(0, 48, 94, 1)',
    fontFamily: 'SourceSerifPro',
    fontSize: '14px',
    fontWeight: 'normal',
    textAlign: 'center',
    marginBottom: '8px',
  },
});

interface IProps {
  address: string;
  onOfficeId?: string | null;
  externalId?: string | null;
  lastSync?: string | null;
}

const HeadInfo: FC<IProps> = ({
  address,
  onOfficeId,
  externalId,
  lastSync,
}) => {
  const lastSyncString = lastSync ? new Date(lastSync) : new Date();

  return (
    <View style={styles.wrapper}>
      <Text style={styles.title}>Vermarktungsstatistik</Text>
      <Text style={styles.text}>Ihrer Immobilie</Text>
      <Text style={styles.address}>{address}</Text>
      <Text style={styles.text}>Ihrer Immobilie</Text>
      {!!onOfficeId && !!externalId && (
        <View>
          <Text style={styles.text}>
            Datensatznummer: <Text style={styles.textBold}>{onOfficeId}</Text> |
            Objektnummer: <Text style={styles.textBold}>{externalId}</Text>
          </Text>
        </View>
      )}
      <Text style={styles.text}>
        Stand per:{' '}
        <Text style={styles.textBold}>{formatSyncDate(lastSyncString)}</Text>
      </Text>
    </View>
  );
};

export { HeadInfo };
