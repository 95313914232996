import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const Container = styled.div`
  display: flex;
  gap: 10px;
  & > div {
    display: flex;
    flex-direction: column;
  }

  @media ${device.tablet} {
    gap: 16px;
  }
`;

export const Value = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${({ theme }) => theme.blue};
`;

export const Text = styled.p`
  opacity: 0.6;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;
