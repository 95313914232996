import { SuggestedPropertyEnergyEquipmentEntity } from '../../../generated';

type TReturn = {
  typeOfFiring?: string;
  typeOfHeating?: string;
};

const typesOfFiring = new Set([
  'additionalDecentralizedHotWater',
  'gas',
  'oil',
  'geothermalEnergy',
  'electro',
  'airWaterHeatPump',
  'alternative',
  'combinedHeatAndPowerPlant',
  'pellet',
  'solar',
]);

const typesOfHeating = new Set([
  'districtHeating',
  'centralHeating',
  'floorHeating',
  'furnaceHeating',
  'underfloorHeating',
]);

const filterEnergyEquipment = (
  energyEquipment?: Partial<SuggestedPropertyEnergyEquipmentEntity> | null
): TReturn => {
  let typeOfFiring;
  let typeOfHeating;

  if (energyEquipment) {
    const typesOfEnergyEquipment = Object.entries(energyEquipment);
    typesOfEnergyEquipment.forEach((item) => {
      const [type, value] = item;
      if (value === 1) {
        if (typesOfHeating.has(item[0])) {
          typeOfHeating = type;
        }

        if (typesOfFiring.has(item[0])) {
          typeOfFiring = String(type);
        }
      }
    });
  }

  return {
    typeOfFiring,
    typeOfHeating,
  };
};

export { filterEnergyEquipment };
