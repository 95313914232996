import Collapse, { Panel } from 'rc-collapse';
import { CollapsePanelProps } from 'rc-collapse/lib/interface';
import Icon from 'modules/common/components/ui/icon';
import { ReactComponent as ArrowDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import { ReactComponent as Stats } from 'assets/streamline-light/business-products/data-files/data-file-graph.svg';
import { ReactComponent as StatAsc } from 'assets/streamline-light/interface-essential/stats/graph-stats-ascend.svg';
import { ReactComponent as StatDesc } from 'assets/streamline-light/interface-essential/stats/graph-stats-descend.svg';

import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import collapseMotion from 'modules/common/components/ui/faq-accordion/utils';
import { LineChart } from '../../../chart/line-chart';
import { SearchProfile } from '../../../../../../generated';
import {
  Wrapper,
  Header,
  Table,
  TableContent,
  ChartContent,
  GraphCard,
  GraphCardTitle,
  GraphCardText,
  HeaderTitle,
  LowResultsBox,
  SubmitButton,
  LineChartContainer,
  TooltipTitle,
} from './buyer-finder-graph-styles';
import { buyerFinderGraphFakeData } from './buyer-finder-graph-fake-data';
import { Tooltip } from 'modules/common/components/tooltip';

const monthLabels = [
  'Jan',
  'Feb',
  'Mrz',
  'Apr',
  'Mai',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dez',
];

const monthFullLabels = [
  'Januar',
  'Februar',
  'Marz',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

interface IProps {
  searchProfiles: Partial<SearchProfile>[];
  onNotifyBroker: () => void;
  setIsBlurredGraphic: (isBlurredGraphic: boolean) => void;
  isViewRightsOnly?: boolean;
}

export const BuyerFinderGraph: FC<IProps> = ({
  searchProfiles,
  onNotifyBroker,
  setIsBlurredGraphic,
  isViewRightsOnly = false,
}) => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const [fakeLineChartData, setFakeLineChartData] = useState<{
    data: number[];
    labels: string[];
  } | null>(null);

  const lineChartData = useMemo(() => {
    // const months = searchProfiles.reduce(
    //   (
    //     acc: { label: string; labelFull: string; count: number }[],
    //     searchProfile
    //   ) => {
    //     if (!searchProfile.createdAt) {
    //       return acc;
    //     }
    //
    //     const month = new Date(searchProfile.createdAt).getMonth();
    //     const year = new Date(searchProfile.createdAt).getFullYear();
    //     const isHasMonth = acc.find(
    //       (item) => item.label === `${monthLabels[month]} ${year}`
    //     );
    //
    //     if (isHasMonth) {
    //       return acc.map((item) => {
    //         if (item.label === `${monthLabels[month]} ${year}`) {
    //           return {
    //             label: item.label,
    //             labelFull: item.labelFull,
    //             count: item.count + 1,
    //           };
    //         }
    //         return item;
    //       });
    //     }
    //
    //     return [
    //       ...acc,
    //       {
    //         label: `${monthLabels[month]} ${year}`,
    //         labelFull: `${monthFullLabels[month]} ${year}`,
    //         count: 1,
    //       },
    //     ];
    //   },
    //   []
    // );
    // count: 1
    // label: "Nov 2023"
    // labelFull: "November 2023"

    const months = monthLabels.map((item, i) => {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 12 + i);
      const month = currentDate.getMonth();
      const year = currentDate.getFullYear();

      return {
        label: `${monthLabels[month]} ${year}`,
        labelFull: `${monthFullLabels[month]} ${year}`,
        date: currentDate,
        count: 0,
      };
    });

    searchProfiles.forEach((item) => {
      const spDate = new Date(item.createdAt);
      months.find((month, i) => {
        if (
          spDate.getMonth() === month.date.getMonth() &&
          spDate.getFullYear() === month.date.getFullYear()
        ) {
          months[i].count += 1;
          return true;
        }
        return false;
      });
    });

    const chartData = months.reduce(
      (
        acc: {
          labels: string[];
          labelsFull: string[];
          data: number[];
          trend: number[];
          prevValue: number;
        },
        lineChartDataItem
      ) => {
        return {
          labels: [...acc.labels, lineChartDataItem.label],
          labelsFull: [...acc.labelsFull, lineChartDataItem.labelFull],
          data: [...acc.data, lineChartDataItem.count],
          trend: [
            ...acc.trend,
            acc.prevValue < lineChartDataItem.count ? 1 : -1,
          ],
          prevValue: lineChartDataItem.count,
        };
      },
      {
        labels: [],
        labelsFull: [],
        data: [],
        trend: [],
        prevValue: 0,
      }
    );

    return {
      labels: chartData.labels,
      labelsFull: chartData.labelsFull,
      data: chartData.data,
      trend: chartData.trend,
    };
  }, [searchProfiles.length]);

  useEffect(() => {
    if (lineChartData.data.filter((item) => item < 5).length > 1) {
      const currentMonth = `${
        new Date().getMonth() + 1
      }.${new Date().getFullYear()}`;

      const endIndex = buyerFinderGraphFakeData.months.indexOf(currentMonth);

      if (endIndex > -1) {
        const startIndex = Math.max(0, endIndex - 12);
        const data = buyerFinderGraphFakeData.values.slice(
          startIndex,
          endIndex + 1
        );
        const labels = buyerFinderGraphFakeData.labels.slice(
          startIndex,
          endIndex + 1
        );

        setFakeLineChartData({ data, labels });
        setIsBlurredGraphic(true);
      } else {
        setFakeLineChartData(null);
        setIsBlurredGraphic(false);
      }
    } else {
      setIsBlurredGraphic(false);
      setFakeLineChartData(null);
    }
  }, [lineChartData]);

  return (
    <Wrapper>
      <Collapse
        openMotion={collapseMotion}
        className="outer-accordion"
        defaultActiveKey={1}
        expandIcon={({ isActive }: CollapsePanelProps) => (
          <Icon
            id="close-button"
            width={16}
            height={16}
            icon={ArrowDown}
            color={themeContext.blue}
            style={{
              transition: `transform 500ms ease`,
              transform: isActive ? 'rotate(-180deg)' : 'none',
            }}
          />
        )}
      >
        <Panel
          key="1"
          header={
            <Header>
              <Icon
                width={32}
                height={32}
                icon={Stats}
                color={themeContext.blue}
              />
              <HeaderTitle content={t('buyer-finder.graph.title')} />
            </Header>
          }
          headerClass="outer-header"
          className="outer-content"
        >
          <GraphCard>
            <GraphCardTitle content={t('buyer-finder.graph-card.title')} />
            <GraphCardText content={t('buyer-finder.graph-card.text')} />

            {fakeLineChartData && (
              <>
                <ChartContent className="low-results">
                  <LineChartContainer className="line-chart-container">
                    <LineChart
                      labels={fakeLineChartData.labels}
                      data={fakeLineChartData.data}
                      aspectRatio={3}
                    />
                  </LineChartContainer>
                  <LowResultsBox>
                    <GraphCardText
                      content={t('buyer-finder.graph-card.low-results.text')}
                    />
                    <Tooltip
                      id="evaluation-button-tooltip"
                      isDisabled={!isViewRightsOnly}
                      content={
                        <SubmitButton
                          isDisabled={isViewRightsOnly}
                          onClick={onNotifyBroker}
                          label={t(
                            'buyer-finder.graph-card.low-results.button'
                          )}
                        />
                      }
                    >
                      <TooltipTitle
                        content={t(
                          'buyer-finder.graph-card.low-results.unauthorized-tootlip'
                        )}
                      />
                    </Tooltip>
                  </LowResultsBox>
                </ChartContent>
              </>
            )}
            {!fakeLineChartData && (
              <>
                <ChartContent>
                  <LineChart
                    labels={lineChartData.labels}
                    data={lineChartData.data}
                  />
                </ChartContent>
                <TableContent>
                  <Table>
                    {lineChartData.labelsFull.map((item, i) => (
                      <tr key={i}>
                        <td className="label">{item}</td>
                        <td className="value">{lineChartData.data[i]}</td>
                        <td className="trend">
                          {lineChartData.trend[i] > 0 && (
                            <Icon
                              width={24}
                              height={24}
                              icon={StatAsc}
                              color={themeContext.blue}
                            />
                          )}
                          {lineChartData.trend[i] < 0 && (
                            <Icon
                              width={24}
                              height={24}
                              icon={StatDesc}
                              color={themeContext.blue}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </Table>
                </TableContent>
              </>
            )}
          </GraphCard>
        </Panel>
      </Collapse>
    </Wrapper>
  );
};
