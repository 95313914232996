import { FC, useEffect, useRef, useState } from 'react';
import { useKeepElementInWindow } from 'modules/common/hooks/useKeepElementInWindow';
import {
  EnergyConsumptionIcon,
  IconContainer,
  Indicator,
  InfoPopup,
} from './combined-shape-styles';

interface IProps {
  color: string;
  tooltipLeft: string;
  indicatorLeft: string;
  letter?: string;
  value?: string;
  indicatorColor?: string;
}

const CombinedShape: FC<IProps> = ({
  color,
  letter,
  tooltipLeft,
  indicatorLeft,
  indicatorColor,
  value,
}) => {
  const infoDiv = useRef<HTMLDivElement>(null);

  const [marginLeft, setMarginLeft] = useState(0);

  const { left, right } = useKeepElementInWindow(infoDiv.current, 10);

  useEffect(() => {
    setMarginLeft(left !== 0 ? left : right !== 0 ? right * -1 : marginLeft);
  }, [left, right, tooltipLeft]);

  return (
    <>
      <Indicator>
        <IconContainer>
          {value && (
            <InfoPopup ref={infoDiv} left={tooltipLeft} marginLeft={marginLeft}>
              {`${value} kWh/m²a`}
            </InfoPopup>
          )}
          <EnergyConsumptionIcon
            color={color}
            indicatorColor={indicatorColor || '#ffffff'}
            left={indicatorLeft}
          >
            {letter}
          </EnergyConsumptionIcon>
        </IconContainer>
      </Indicator>
    </>
  );
};

export { CombinedShape };
