import { RegisterOptions } from 'react-hook-form';
import { MainEarner, RegistrationBy } from '../../generated';

export enum InputType {
  text = 'text',
  email = 'email',
  number = 'number',
  checkbox = 'checkbox',
  password = 'password',
}

export interface IRegisterForm {
  name: string;
  surname: string;
  phone: string;
  phonePrefix: string;
  householdNetIncome?: number;
  mainEarner?: MainEarner;
  age?: number;
  email: string;
  password: string;
  confirmPassword: string;
  termsAndConditions: boolean;
  termsAndConditionsFinancing?: boolean;
  termsAndConditionsFinancing2?: boolean;
  communicationConsent?: boolean;
  createAccount?: boolean;
  registrationBy?: RegistrationBy;
}

export interface IEditProfile {
  name: string;
  surname: string;
  phone: string;
  phonePrefix: string;
  email: string;
  password: string;
  confirmPassword: string;
  isNotifyPriceChange: boolean;
  isNotifyNewPropertiesSummary: boolean;
  isNotifyNewProperties: boolean;
  isNotifyPaymentOfInterestMonthly: boolean;
  isNotifyGeneralNewsletter: boolean;
  isNotifyMarketPrice: boolean;
  isNotifyFinancingInterestRates: boolean;
  isNotifyEvaluationUpdates: boolean;
}

export interface ILoginForm {
  userAuthenticationKey: string;
  password: string;
}

export interface IForgotPasswordForm {
  username: string;
}

export interface ICreatePasswordForm {
  password: string;
  confirmPassword: string;
}

export interface SharePropertyCreateAccountForm {
  phone: string;
  phonePrefix: string;
  password: string;
  confirmPassword: string;
  termsAndConditions: boolean;
  communicationConsent?: boolean;
}

export interface IRegisterFormState {
  name: string;
  label?: string;
  checkboxLabel?: {
    online: string;
    personal: string;
  };
  type: InputType;
  placeholder?: string;
  rules?: RegisterOptions;
  selectWidth?: string;
  hasSelect?: boolean;
  selectValue?: string;
  selectOptions?: string[];
  selectName?: string;
}

export interface IFirstStepErrors {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface IPhonePrefixes {
  label: string;
  value: string;
}

export interface IUserInput extends Partial<IRegisterForm> {
  _id?: string;
  username?: string;
}

export enum IIframeType {
  WIDGET = 'WIDGET',
  WIDGET_OWNER_CENTERED = 'WIDGET_OWNER_CENTERED',
  WIDGET_OWNER_NO_MODAL = 'WIDGET_OWNER_NO_MODAL',
  BENEFIT = 'BENEFIT',
  WIDGET_BUYER = 'WIDGET_BUYER',
  WIDGET_FINANCING = 'WIDGET_FINANCING',
  WIDGET_FINANCING_NO_MODAL = 'WIDGET_FINANCING_NO_MODAL',
  WIDGET_FINANCING_CERTIFICATE = 'WIDGET_FINANCING_CERTIFICATE',
  WIDGET_FINANCING_CERTIFICATE_NO_MODAL = 'WIDGET_FINANCING_CERTIFICATE_NO_MODAL',
}

export interface ILoginDevForm {
  login: string;
  password: string;
}
