import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { MainButton } from '../../../../common/components/ui/buttons';

export const Container = styled.div`
  height: 344px;
  background-color: #f3f4f7;
  margin-bottom: 64px;
  display: flex;
  gap: 32px;
  position: relative;

  &:before {
    content: '';
    width: 100vw;
    background: #f3f4f7;
    position: absolute;
    left: 50%;
    right: 50%;
    margin: 0 -50vw;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  @media ${device.tablet} {
    height: auto;
    margin: 0 0 16px 0 !important;
    flex-direction: column-reverse;
    gap: 0;
  }
`;

export const Title = styled.h2`
  margin: 32px 0 16px 0;
  font-family: 'Source Serif Pro';
  font-size: 36px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #002849;

  @media ${device.tablet} {
    display: none;
  }
`;

export const TitleMobile = styled.h2`
  margin: 16px 0;
  font-family: 'Source Serif Pro';
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.5px;
  color: #00305e;
  text-align: center;
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

export const Text = styled.p`
  margin: 0 0 24px 0;
  width: 499px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: #00305e;

  @media ${device.tablet} {
    margin: 16px 16px 24px;
    width: auto;
  }
`;

export const CTAButton = styled(MainButton)`
  padding: 14px 24px;
  border-radius: 3px;
  text-transform: uppercase;

  @media ${device.tablet} {
    margin: 0 auto 16px auto;
  }
`;

export const Img = styled.img`
  object-fit: cover;
  width: 616px;
  height: 344px;
  position: relative;

  @media ${device.tablet} {
    flex: 1;
    position: initial;
    object-fit: contain;
    max-height: 200px;
    width: initial;
  }
`;
