import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as ArrowRight } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-right.svg';

import { Link } from 'react-router-dom';
import { Address, Coordinates, Property } from '../../../../generated';
import Icon from '../../../common/components/ui/icon';
import { ParagraphTitle } from '../../../common/components/ui/typography';
import { siteMap } from '../../../../routes/site-map';

interface IProps {
  property: Pick<Property, '_id'> & {
    propertyData: { __typename?: 'PropertyData' } & {
      location: { __typename?: 'PropertyLocation' } & {
        coordinates: { __typename?: 'Coordinates' } & Pick<
          Coordinates,
          'longitude' | 'latitude'
        >;
        address: { __typename?: 'Address' } & Pick<
          Address,
          'city' | 'houseNumber' | 'postCode' | 'street'
        >;
      };
    };
  };
}

const StyledLink = styled(Link)`
  display: block;
  margin-bottom: 16px;
  text-decoration: none !important;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  justify-content: space-between;
  padding: 12px 16px;
`;

const AddressContainer = styled.div``;
const AddresAndImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  max-width: 160px;
  width: 100%;
  max-height: 80px;
  height: 100%;
`;

const ImageContainer = styled.div`
  margin-right: 16px;
  min-width: 160px;
  min-height: 80px;
`;

const token = process.env.REACT_APP_MAPBOX_KEY;

const getStaticMapImageSrc = ({
  lng,
  lat,
  zoom,
  width,
  height,
}: {
  lng: number;
  lat: number;
  width: number;
  height: number;
  zoom: number;
}) => {
  return `https://api.mapbox.com/styles/v1/mapbox/light-v9/static/url-${encodeURIComponent(
    'https://home.von-poll.com/templates/map-pin-small.png'
  )}(${lng},${lat})/${lng},${lat},${zoom},0/${width}x${height}@2x?access_token=${token}`;
};

const OtherPropertyCard = ({ property }: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const { coordinates, address } = property.propertyData.location;

  const image = getStaticMapImageSrc({
    lng: coordinates.longitude,
    lat: coordinates.latitude,
    width: 160,
    height: 80,
    zoom: 13,
  });

  return (
    <StyledLink
      to={`${siteMap.PropertyPage.pathWithoutParams}/${property._id}`}
    >
      <Container>
        <AddresAndImageContainer>
          <ImageContainer>
            <Image src={image} />
          </ImageContainer>
          <AddressContainer>
            <ParagraphTitle
              content={`${address.street} ${address.houseNumber},`}
            />
            <ParagraphTitle content={`${address.postCode} ${address.city}`} />
          </AddressContainer>
        </AddresAndImageContainer>

        <Icon
          icon={ArrowRight}
          height={16}
          width={16}
          color={themeContext.blue}
        />
      </Container>
    </StyledLink>
  );
};
export { OtherPropertyCard };
