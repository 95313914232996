import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlyToInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';
import { batch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { GlobalError } from '../../../../../common/components/form/error/global-error';
import { StyleGuideError } from '../../../../../common/components/form/error/style-guide-error';
import { InputWithSuggestionsV2 } from '../../../../components/common/input-with-suggestions-v2';
import { VALUATION_FORM_KEYS, Proximity } from '../../../constants';
import {
  extractCorrectValue,
  getSuggestions,
} from '../../../../utils/suggestions';
import { useLazyForwardGeocodingQuery } from '../../../../../../services/mapbox/mapbox-api';
import { Feature } from '../../../../../../services/mapbox/interfaces';
import {
  setSelectedAddressAction,
  setSelectedCountryCode,
  changeMapViewport,
  setMapSettings,
} from '../../../redux/valuationWizardV2Slice';
import getCountry from '../../../../utils/getCountry';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { IAddress } from '../../../../interface';
import { useGetCountry } from '../../../../../localization/get-country';
import { useSetProximityFromLocalization } from '../../../../hooks/set-proximity-from-localization';

const defaultDebounceState = {
  postCode: '',
  street: '',
  number: '',
  city: '',
  state: '',
  locality: '',
  neighborhood: '',
  proximity: '9.993682,53.551086',
  country: 'de,at,ch',
  language: 'de',
  fuzzyMatch: 'true',
};

const fields = [
  VALUATION_FORM_KEYS.STREET,
  VALUATION_FORM_KEYS.NUMBER,
  VALUATION_FORM_KEYS.CITY,
];

const Container = styled.div`
  margin-bottom: 12px;
`;

interface IProps {
  label?: string;
  placeholder?: string;
  notRequired?: boolean;
  styleGuideErr?: boolean;
  controllerValue?: string;
  disabled?: boolean;
  defaultAddressState?: Partial<typeof defaultDebounceState>;
  suggestionsFilter?: (feature: Feature[]) => Feature[];
}

const InputAddress: FC<IProps> = ({
  label,
  placeholder,
  notRequired,
  styleGuideErr,
  controllerValue,
  disabled,
  defaultAddressState,
  suggestionsFilter,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setValue } = useFormContext();
  const { language } = useGetCountry();

  const relatedCountry = useAppSelector(
    (state) => state.valuationWizardV2.relatedCountry
  );

  const [invalidAddress, setInvalidAddress] = useState(false);
  const [debouncedAddress, setDebouncedAddress] =
    useState(defaultDebounceState);
  const proximity = useSetProximityFromLocalization();

  const [street, number, city] = useWatch({
    name: fields,
  });

  const [geocodingTrigger, { mostAccurateGeocodingResult }] =
    useLazyForwardGeocodingQuery({
      selectFromResult: ({ data, isFetching }) => ({
        mostAccurateGeocodingResult: data,
        isFetching,
      }),
    });

  const onDebounceAddressChange = useDebouncedCallback(
    (newDebouncedAddress: IAddress) => setDebouncedAddress(newDebouncedAddress),
    300
  );

  useEffect(() => {
    if (
      (debouncedAddress.number !== number && number?.length > 0) ||
      (debouncedAddress.street !== street && street?.length > 2)
      // (debouncedAddress.city !== city && city?.length > 2)
    ) {
      let defaultProximity;
      switch (relatedCountry && relatedCountry.toLowerCase()) {
        case 'germany':
          defaultProximity = Proximity.GERMANY;
          break;
        case 'austria':
          defaultProximity = Proximity.AUSTRIA;
          break;
        case 'switzerland':
          defaultProximity = Proximity.SWITZERLAND;
          break;
        default:
          defaultProximity = Proximity.GERMANY;
      }

      onDebounceAddressChange({
        ...debouncedAddress,
        proximity: proximity || defaultProximity,
        ...(defaultAddressState?.city
          ? { city: defaultAddressState.city }
          : {}),
        ...(defaultAddressState?.country
          ? { country: defaultAddressState.country }
          : {}),
        ...(defaultAddressState?.fuzzyMatch
          ? { fuzzyMatch: defaultAddressState.fuzzyMatch }
          : {}),
        street,
        number,
        language,
        types: 'address',
      });
    }
  }, [
    language,
    onDebounceAddressChange,
    // city,
    street,
    number,
    debouncedAddress,
    proximity,
    relatedCountry,
  ]);

  useEffect(() => {
    if (
      Object.keys(debouncedAddress)
        .filter((key) => key !== 'country')
        .some(
          (key) => (debouncedAddress as { [key: string]: string })[key] !== ''
        )
    ) {
      geocodingTrigger(debouncedAddress);
    }
  }, [geocodingTrigger, debouncedAddress]);

  // fix autocomplete for MS Edge
  const [isReadonly, setIsReadonly] = useState(true);
  useEffect(() => setIsReadonly(false), []);

  const onSuggestionClick = useCallback(
    (suggestion: Feature) => {
      const { updatedCity, updatedStreet, updatedNumber } =
        extractCorrectValue(suggestion);

      dispatch(setSelectedCountryCode(getCountry(suggestion)));

      setValue(VALUATION_FORM_KEYS.STREET, `${updatedStreet} ${updatedNumber}`);
      setValue(VALUATION_FORM_KEYS.NUMBER, updatedNumber);
      setValue(VALUATION_FORM_KEYS.CITY, updatedCity);

      if (!updatedStreet || !updatedNumber || !updatedCity) {
        setInvalidAddress(true);
      } else {
        setInvalidAddress(false);
      }

      const selectedSuggestion = JSON.parse(JSON.stringify([suggestion]))[0];
      const pin = {
        longitude: Number(selectedSuggestion?.center?.[0]),
        latitude: Number(selectedSuggestion?.center?.[1]),
      };

      batch(() => {
        dispatch(setSelectedAddressAction(selectedSuggestion));
        dispatch(
          changeMapViewport({
            longitude: pin?.longitude ?? 0,
            latitude: pin?.latitude ?? 0,
            zoom: 15,
            transitionDuration: 'auto',
            transitionInterpolator: new FlyToInterpolator({
              speed: 1.8,
            }),
            transitionEasing: easeCubic,
          })
        );
        dispatch(setMapSettings({ isMapMarker: true }));
      });
    },
    [dispatch, setValue]
  );

  const styleGuideCheckForInvalid = () => {
    if (street && !number) {
      setInvalidAddress(true);
    } else {
      setInvalidAddress(false);
    }
  };

  return (
    <Container>
      {invalidAddress && !styleGuideErr && (
        <GlobalError
          title={t('property-valuation.form.redesign.address.error')}
          topMargin
        />
      )}
      <InputWithSuggestionsV2
        styleGuideErr={invalidAddress && styleGuideErr}
        name={VALUATION_FORM_KEYS.STREET}
        controllerValue={controllerValue}
        disabled={disabled}
        label={label ?? 'property-valuation.form.redesign.address.label'}
        placeholder={
          placeholder ?? 'property-valuation.form.redesign.address.placeholder'
        }
        rules={notRequired ? {} : { required: 'register.input.error.required' }}
        tooltipKey="property-valuation.form.redesign.address.tooltip"
        readonly={isReadonly}
        suggestions={getSuggestions(
          VALUATION_FORM_KEYS.STREET,
          mostAccurateGeocodingResult ?? [],
          suggestionsFilter
        )}
        onSuggestionClick={onSuggestionClick}
        handleSetError={styleGuideErr ? styleGuideCheckForInvalid : undefined}
      />
      {invalidAddress && styleGuideErr && (
        <StyleGuideError>
          {t('property-valuation.form.redesign.address.error')}
        </StyleGuideError>
      )}
    </Container>
  );
};

export { InputAddress };
