import { FC } from 'react';
import {
  BenefitTitle,
  Container,
  Icon,
  ImageContainer,
} from './benefit-card-styles';

interface IProps {
  imageSrc: string;
  imageAlt: string;
  title: string;
  isProportional?: boolean;
  isGray?: boolean;
}

const BenefitCard: FC<IProps> = ({
  imageSrc,
  imageAlt,
  title,
  isProportional,
  isGray,
}) => {
  return (
    <Container>
      <ImageContainer isGray={isGray}>
        <Icon src={imageSrc} alt={imageAlt} />
      </ImageContainer>
      <BenefitTitle isProportional={isProportional} content={title} />
    </Container>
  );
};

export { BenefitCard };
