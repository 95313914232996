import { FC, memo } from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowLeftIcon } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-left.svg';
import { ReactComponent as MobileArrowLeftIcon } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';

import Icon from '../ui/icon';
import { hexToRGB } from '../../utils/hex-to-rgb';
import { device } from '../../../../style/theme';
import { useIsMobileSize } from '../../hooks/useIsMobileSize';

const Container = styled.div<{ position: 'left' | 'right' }>`
  padding: 16px;
  opacity: 0.9;
  border-radius: 3px;
  background-color: ${hexToRGB('#e5e9ec', 0.9)};
  position: absolute;
  ${({ position }) => (position === 'left' ? `left: 0;` : 'right: 0;')}
  top: 0;
  cursor: pointer;
  z-index: 1;
  @media ${device.tablet} {
    padding: 20px 16px;
    background-color: transparent;
  }
`;

interface IProps {
  handleBack: () => void;
  position: 'left' | 'right';
  color: string;
}

const BackButtonBase: FC<IProps> = ({ handleBack, position, color }) => {
  const isMobile = useIsMobileSize();

  return (
    <Container position={position} onClick={handleBack}>
      <Icon
        icon={isMobile ? MobileArrowLeftIcon : ArrowLeftIcon}
        width={isMobile ? 16 : 24}
        height={isMobile ? 16 : 24}
        color={color}
      />
    </Container>
  );
};

export { BackButtonBase as BackButton };
