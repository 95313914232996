import { FC, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import arrow from 'assets/images/icons/property-valuation/arrow.png';

import { TArrowPosition } from '../../../hooks/useArrowImage';
import { CloseButton } from '../../../../common/components/modal/close-button';
import { BackButton } from '../../../../common/components/modal/back-button';
import { ArrowImage } from '../auth-container/login-register-screen/login-register-screen-styles';

import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { useFinancingFormInfo } from '../../../form-financing/hooks/useFinancingFormInfo';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

interface IProps {
  activeStep: number;
  isAuthFlow: boolean;
  isRegisterFormOpen: boolean;
  isLoginFormOpen: boolean;
  isArrowUp?: boolean;

  onClose: () => void;
  onBackClick: () => void;
  arrowPosition: TArrowPosition;
}

const Container: FC<IProps> = ({
  children,
  activeStep,
  isAuthFlow,
  isLoginFormOpen,
  isRegisterFormOpen,
  isArrowUp,
  onClose,
  onBackClick,
  arrowPosition,
}) => {
  const isMobileSize = useIsMobileSize();
  const themeContext = useContext(ThemeContext);
  const { isFinancingCertificate } = useFinancingFormInfo();

  const isFirstStep = activeStep === 0;
  const isCertificate = activeStep === 1 && isFinancingCertificate;

  // const isShowArrow =
  //   !!arrowPosition.top &&
  //   !!arrowPosition.left &&
  //   !isRegisterFormOpen &&
  //   !isLoginFormOpen &&
  //   isAuthFlow;

  return (
    <Wrapper>
      {!isMobileSize && (
        <CloseButton
          handleClose={onClose}
          color={isMobileSize ? themeContext.white : themeContext.blue}
          position="right"
        />
      )}
      {!isFirstStep && !isMobileSize && !isCertificate && (
        <BackButton
          handleBack={onBackClick}
          color={isMobileSize ? themeContext.white : themeContext.blue}
          position="left"
        />
      )}
      {/* {isShowArrow && ( */}
      {/*  <ArrowImage */}
      {/*    src={arrow} */}
      {/*    isArrowUp={isArrowUp} */}
      {/*    top={arrowPosition.top} */}
      {/*    left={arrowPosition.left} */}
      {/*    alt={'arrow'} */}
      {/*  /> */}
      {/* )} */}
      {children}
    </Wrapper>
  );
};

export { Container };
