import { PropertySubcode } from '../../../../generated';

export const VALUATION_FORM_KEYS = {
  CODE: 'code',
  SUBCODE: 'subcode',
  POST_CODE: 'postCode',
  STREET: 'street',
  NUMBER: 'number',
  BUILDING_YEAR: 'buildingYear',
  LIVING_AREA: 'livingArea',
  LAND_AREA: 'landArea',
  NUMBER_OF_UNITS: 'numberOfUnits',
  CONDITION: 'condition',
  QUALITY: 'quality',
  NUMBER_OF_ROOMS: 'numberOfRooms',
  NUMBER_OF_BATHROOMS: 'numberOfBathrooms',
  NUMBER_OF_INDOOR_PARKING_SPACES: 'numberOfIndoorParkingSpaces',
  NUMBER_OF_OUTDOOR_PARKING_SPACES: 'numberOfOutdoorParkingSpaces',
  RATING_REASON: 'ratingReason',
  OTHER_RATING_REASON: 'otherRatingReason',
  TIME_HORIZONT: 'timeHorizont',
  CITY: 'city',
  RENOVATION_YEAR: 'renovationYear',
  FLOOR_NUMBER: 'floorNumber',
  NUMBER_OF_FLOORS_IN_BUILDING: 'numberOfFloorsInBuilding',
  ANNUAL_RENT_INCOME: 'annualRentIncome',
  BALCONY_AREA: 'balconyArea',
  ENERGY_LABEL: 'energyLabel',
  IS_NEW: 'isNew',
  HAS_POOL: 'hasPool',
  HAS_SAUNA: 'hasSauna',
  ADDRESS: 'address',
};

export const FORM_STEPS = 6;

export const hideServices: string[] = [
  '6144e4dfff7ead11b34492da',
  '6144e47e03cc7c119f93b833',
  '6144e4ebff7ead11b34492db',
  '6144e4a203cc7c119f93b834',
  '6144e4f6d8d871117ab66642',
  '6144e4a9aa12c7116c8a9781',
  '61484f4818e34311864b6fbf',
  '61488d7251363e1174883def',
  '61488ad7ee93b811823901bf',
];

export const showServices: string[] = [
  '61448cf4aa12c7116c8a9779',
  '61488c30d6c6e21177ef69eb',
];

export const APARTMENT_SUBTYPES = [
  {
    label: 'valuation.form.subtypes.apartment.apartment',
    value: PropertySubcode.ApartmentNormal,
  },
  {
    label: 'valuation.form.subtypes.apartment.penthouse',
    value: PropertySubcode.ApartmentPenthouse,
  },
  {
    label: 'valuation.form.subtypes.apartment.maisonette',
    value: PropertySubcode.ApartmentMaisonette,
  },
  {
    label: 'valuation.form.subtypes.apartment.attic-apartment',
    value: PropertySubcode.ApartmentAttic,
  },
  {
    label: 'valuation.form.subtypes.apartment.terrace-apartment',
    value: PropertySubcode.ApartmentTerraced,
  },
  {
    label: 'valuation.form.subtypes.apartment.one-room-apartment',
    value: PropertySubcode.ApartmentStudio,
  },
];

export const HOUSE_SUBTYPES = [
  {
    label: 'valuation.form.subtypes.house.detached-house',
    value: PropertySubcode.HouseDetached,
  },
  {
    label: 'valuation.form.subtypes.house.semi-detached-house',
    value: PropertySubcode.HouseSemiDetached,
  },
  {
    label: 'valuation.form.subtypes.house.row-corner-house',
    value: PropertySubcode.HouseRowCorner,
  },
  {
    label: 'valuation.form.subtypes.house.mid-terrace-house',
    value: PropertySubcode.HouseRowMiddle,
  },
  {
    label: 'valuation.form.subtypes.house.farm-yard',
    value: PropertySubcode.HouseFarm,
  },
];

export enum Proximity {
  GERMANY = '9.993682,53.551086',
  AUSTRIA = '15.4395,47.0707',
  SWITZERLAND = '7.3606,46.2331',
}
