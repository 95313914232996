import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardHeader,
  Container,
  Description,
  Title,
} from './expose-card-styles';
import { useGetExposeStateTag } from '../../../hooks/useGetExposeStateTag';
import { PreparationCard } from '../expose-state-card/preparation-card';
import { WaitingApprovalCard } from '../expose-state-card/waiting-approval-card';
import { ChangesRequestedCard } from '../expose-state-card/changes-requested-card';
import { ApprovedCard } from '../expose-state-card/approved-card';
import { OnOfficeExposeStatus } from '../../../../../generated';
import { PropertyExpose } from '../../../interfaces';

interface IProps {
  expose: PropertyExpose;
  onDownloadPdfExpose: () => void;
  onOpenOnlineExpose: () => void;
  isLocked?: boolean;
}

const ExposeCardBase: FC<IProps> = ({
  expose,
  onDownloadPdfExpose,
  onOpenOnlineExpose,
  isLocked,
}) => {
  const { t } = useTranslation();
  const ExposeStateTag = useGetExposeStateTag(
    expose.status,
    expose.isApprovedViaOnOffice,
    expose.approvedAt
  );

  return (
    <Container>
      <CardHeader>
        <Title content={t('seller.expose-card.title')} />
        {ExposeStateTag}
      </CardHeader>
      <Description content={t('seller.expose-card.description')} />

      {expose.status === OnOfficeExposeStatus.Preparation && (
        <PreparationCard />
      )}
      {expose.status === OnOfficeExposeStatus.WaitingApproval && (
        <WaitingApprovalCard
          expose={expose}
          onDownloadPdfExpose={onDownloadPdfExpose}
          onOpenOnlineExpose={onOpenOnlineExpose}
          isLocked={isLocked}
        />
      )}
      {expose.status === OnOfficeExposeStatus.ChangesRequested && (
        <ChangesRequestedCard
          expose={expose}
          onDownloadPdfExpose={onDownloadPdfExpose}
          onOpenOnlineExpose={onOpenOnlineExpose}
        />
      )}
      {expose.status === OnOfficeExposeStatus.Approved && (
        <ApprovedCard
          expose={expose}
          onDownloadPdfExpose={onDownloadPdfExpose}
          onOpenOnlineExpose={onOpenOnlineExpose}
          isLocked={isLocked}
        />
      )}
    </Container>
  );
};

const ExposeCard = memo(ExposeCardBase);

export { ExposeCard };
