import HouseEvaluate from 'assets/navigation/house-evaluate.png';
import HouseSell from 'assets/navigation/house-sell.png';
import HouseBuy from 'assets/navigation/house-buy.png';
import HouseFinance from 'assets/navigation/house-finance.png';
import Location from 'assets/streamline-light/shopping-ecommerce/shops/shop.svg';
import Company from 'assets/streamline-light/work-office-companies/ideas-creativity/bulb.svg';
import { siteMap } from '../../../../../routes/site-map';

export interface NavigationLinkInterface {
  label: string;
  path?: string;
  children?: { label: string; path?: string }[];
  devOnly?: boolean;
  loggedInOnly?: boolean;
  notLoggedInOnly?: boolean;
}

export const navigationLinks = {
  evaluate: [
    {
      label: 'header.navigation.links.property',
      path: '#appUrl/immobilie-bewerten?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu&online-modal=true',
    },
    {
      label: 'header.navigation.links.house',
      path: '#appUrl/immobilie-bewerten?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu&utm_content=haus&haus=true',
    },
    {
      label: 'header.navigation.links.flat',
      path: '#appUrl/immobilie-bewerten?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu&utm_content=wohnung&wohnung=true',
    },
    {
      label: 'header.navigation.links.family-house',
      path: '#appUrl/immobilie-bewerten?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu&utm_content=mfh&mehrfamilienhaus=true',
    },
    {
      label: 'header.navigation.links.my-owned-properties',
      path: siteMap.OwnerLandingPage.path,
      loggedInOnly: true,
    },
  ],
  sell: [
    {
      label: 'header.navigation.links.property-sell',
      path: '#appUrl/immobilie-verkaufen',
    },
    {
      label: 'header.navigation.links.marketing',
      path: 'https://www.von-poll.com/de/die-vermarktung-ihrer-immobilie',
    },
    {
      label: 'header.navigation.links.start-sell-simulation',
      path: '#appUrl/immobilie-verkaufen',
    },
    {
      label: 'header.navigation.links.tips',
      path: 'https://www.von-poll.com/de/immobilien/immobilie-verkaufen',
    },
    {
      label: 'header.navigation.links.energy',
      path: 'https://www.von-poll.com/de/immobilien/energieausweis',
    },
    {
      label: 'header.navigation.links.my-selling',
      path: siteMap.SellerLandingPage.path,
      loggedInOnly: true,
    },
  ],
  buy: [
    {
      label: 'header.navigation.links.search',
      path: 'https://www.von-poll.com/de/immobilien-kaufen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu',
    },
    {
      label: 'header.navigation.links.search-profile',
      path: '#appUrl/immobilie-suchen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu',
    },
    {
      label: 'header.navigation.links.buy-home',
      path: 'https://www.von-poll.com/de/haus-kaufen',
    },
    {
      label: 'header.navigation.links.buy-flat',
      path: 'https://www.von-poll.com/de/wohnung-kaufen',
    },
    {
      label: 'header.navigation.links.buy-villa',
      path: 'https://www.von-poll.com/de/villen',
    },
    {
      label: 'header.navigation.links.buy-land-house',
      path: 'https://www.von-poll.com/de/landhaus-oder-herrenhaus-kaufen',
    },
    {
      label: 'header.navigation.links.buy-cottage',
      path: 'https://www.von-poll.com/de/ferienimmobilien',
    },
    {
      label: 'header.navigation.links.buy-land',
      path: 'https://www.von-poll.com/de/grundstueck-kaufen',
    },
    {
      label: 'header.navigation.links.my-search-profiles',
      path: siteMap.BuyerLandingPage.path,
      loggedInOnly: true,
    },
    {
      label: 'header.navigation.links.my-property-requests',
      path: siteMap.BuyerLandingPage.path,
      loggedInOnly: true,
    },
  ],
  finance: [
    {
      label: 'header.navigation.links.finance.everything',
      path: '#appUrl/baufinanzierung',
      notLoggedInOnly: true,
    },
    {
      label: 'header.navigation.links.finance.calculate',
      path: '#appUrl/finanzierung-berechnen',
    },
    {
      label: 'header.navigation.links.finance.mortgage',
      path: '#appUrl/hypothekenzertifikat-anfragen',
    },
    {
      label: 'header.navigation.links.finance.my',
      path: '#appUrl/baufinanzierung',
    },
  ],
  location: [
    {
      label: 'header.navigation.links.our-locations',
      path: 'https://www.von-poll.com/de/unternehmen/standorte?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu',
    },
    {
      label: 'header.navigation.links.our-virtual-shops',
      path: 'https://www.von-poll.com/de/virtueller-shop?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu',
    },
  ],
  company: [
    {
      label: 'header.navigation.links.about-us',
      path: 'https://www.von-poll.com/de/unternehmen/ueber-uns',
    },
    {
      label: 'header.navigation.links.companies',
      children: [
        {
          label: 'header.navigation.links.companies.properties',
          path: 'https://www.von-poll.com/de/unternehmen/ueber-uns',
        },
        {
          label: 'header.navigation.links.companies.finance',
          path: 'https://www.von-poll.com/de/unternehmen/von-poll-finance',
        },
        {
          label: 'header.navigation.links.companies.commercial',
          path: 'https://www.von-poll.com/de/unternehmen/ueber-uns',
        },
        {
          label: 'header.navigation.links.companies.house',
          path: 'https://www.von-poll.com/de/unternehmen/von-poll-hausverwaltung',
        },
        {
          label: 'header.navigation.links.companies.premium',
          path: 'https://www.von-poll.com/de/unternehmen/von-poll-premium',
        },
      ],
    },
    {
      label: 'header.navigation.links.partner',
      children: [
        {
          label: 'header.navigation.links.partner.business',
          path: 'https://www.von-poll.com/de/immobilien-lizenz/geschaeftsstellenmodell',
        },
        {
          label: 'header.navigation.links.partner.franchise',
          path: 'https://www.von-poll.com/de/immobilien-lizenz/immobilien-franchise',
        },
        {
          label: 'header.navigation.links.partner.licence',
          path: 'https://www.von-poll.com/de/immobilien-lizenz/immobilien-franchise',
        },
      ],
    },
    {
      label: 'header.navigation.links.career',
      children: [
        {
          label: 'header.navigation.links.career.broker',
          path: 'https://www.von-poll.com/de/karriere/immobilienmakler-werden',
        },
        {
          label: 'header.navigation.links.career.offer',
          path: 'https://www.von-poll.com/de/karriere/stellenangebote',
        },
        {
          label: 'header.navigation.links.career.academy',
          path: 'https://www.von-poll.com/de/unternehmen/akademie',
        },
      ],
    },
    {
      label: 'header.navigation.links.market-reports',
      path: 'https://www.von-poll.com/de/unternehmen/marktberichte',
    },
    {
      label: 'header.navigation.links.best-locations',
      path: 'https://www.von-poll.com/de/unternehmen/beste-lage-magazin',
    },
    {
      label: 'header.navigation.links.cooperation',
      path: 'https://www.von-poll.com/de/unternehmen/kooperation',
    },
    {
      label: 'header.navigation.links.faq',
      children: [
        {
          label: 'header.navigation.links.faq.mentor',
          path: 'https://www.von-poll.com/de/immobilien-ratgeber',
        },
        {
          label: 'header.navigation.links.faq.lifestyle',
          path: 'https://www.von-poll.com/de/immobilienblog',
        },
        {
          label: 'header.navigation.links.faq.tech',
          path: 'https://www.von-poll.com/de/tech-blog',
        },
      ],
    },
  ],
};

export const navigationDetails = {
  evaluate: {
    icon: HouseEvaluate,
    title: 'header.navigation.details.evaluate.title',
    description: 'header.navigation.details.evaluate.description',
    link: '#appUrl/immobilie-bewerten?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu-feature',
  },
  sell: {
    icon: HouseSell,
    title: 'header.navigation.details.sell.title',
    description: 'header.navigation.details.sell.description',
    link: '#appUrl/immobilie-verkaufen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu-feature',
  },
  buy: {
    icon: HouseBuy,
    title: 'header.navigation.details.buy.title',
    description: 'header.navigation.details.buy.description',
    link: '#appUrl/immobilie-suchen?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu-feature',
  },
  finance: {
    icon: HouseFinance,
    title: 'header.navigation.details.finance.title',
    description: 'header.navigation.details.finance.description',
    link: '#appUrl/baufinanzierung',
  },
  location: {
    icon: Location,
    title: 'header.navigation.details.location.title',
    description: 'header.navigation.details.location.description',
    link: 'https://www.von-poll.com/de/unternehmen/standorte?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu-feature',
  },
  company: {
    icon: Company,
    title: 'header.navigation.details.company.title',
    description: 'header.navigation.details.company.description',
    link: '#appUrl/immobilie-bewerten?utm_source=kundenportal&utm_medium=navigation&utm_campaign=menu-feature',
  },
};

export type LinkOption =
  | 'evaluate'
  | 'sell'
  | 'buy'
  | 'finance'
  | 'location'
  | 'company'
  | '';
