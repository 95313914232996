import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as Building } from 'assets/streamline-light/real-estate/real-estate/real-estate-building-house.svg';
import { ReactComponent as AccountingBills } from 'assets/streamline-light/money-payments-finance/money/accounting-bills-1.svg';

import { device } from '../../../../style/theme';
import {
  Headline2,
  ParagraphText,
  TitleSlogan,
} from '../../../common/components/ui/typography';
import PropertyValuationIcon from '../icon/property-valuation-icon';

interface IProps {
  header: string;
  title: string;
  content: string;
  properties: number;
  priceLabel: string;
}

const Container = styled.div<{ backgroundColor?: string; color?: string }>`
  &&& {
    width: 100%;
    padding: 32px 80px 32px 32px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    color: ${(props) => props.theme.blue};
    @media ${device.tablet} {
      padding: 16px;
      max-width: unset;
    }
  }
`;

const Header = styled(TitleSlogan)`
  opacity: 0.8;
  text-transform: uppercase;
`;

const Title = styled(Headline2)`
  margin: 4px 0 16px;
  line-height: 1.34;
  @media ${device.tablet} {
    margin-bottom: 12px;
  }
`;

const Content = styled(ParagraphText)`
  margin-bottom: 16px;
  line-height: 1.72;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 39vw !important;
  }
`;

const IconRow = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-row: 1;
  row-gap: 10px;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-rows: 4;
  }
`;

const PropertyPortfolioOverviewCardBase = ({
  header,
  title,
  content,
  priceLabel,
  properties,
}: IProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header content={t(header)} />
      <Title content={t(title)} />
      <Content content={t(content)} />
      <IconContainer>
        <IconRow>
          <PropertyValuationIcon
            icon={Building}
            value={properties ?? 0}
            type={t('property-portfolio-overview-card.properties')}
          />
          <PropertyValuationIcon
            icon={AccountingBills}
            value={priceLabel ?? ''}
            type={t('property-portfolio-overview-card.price')}
          />
        </IconRow>
      </IconContainer>
    </Container>
  );
};

const PropertyPortfolioOverviewCard = memo(PropertyPortfolioOverviewCardBase);

export { PropertyPortfolioOverviewCard };
