import { FC, memo, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Container,
  FormContainer,
} from '../../../../../components/fullscreen-modal/styles';
import { UsefulInfo } from '../../../../../components/fullscreen-modal/useful-info/useful-info';
import { ProgressBar } from '../../../../../components/fullscreen-modal/progress-bar/progress-bar';
import { ModalHeader } from '../../../../../components/fullscreen-modal/modal-header/modal-header';
import { useIsMobileSize } from '../../../../../../common/hooks/useIsMobileSize';
import { useUsefulValuationContainerData } from '../../../../hooks/useGetValuationFormInfo';
import { OnlineValuation } from './online-valuation/online-valuation';
import { PersonalValuation } from './personal-valuation/personal-valuation';
import { FORM_STEPS, usefulInfoKeyPhrases } from '../../../../constants';
import { useModalButtonsHandler } from '../../../../hooks/callback-hooks/useModalButtonsHandler';

interface IProps {
  openWidget?: boolean;
  activeStepProp?: number;
  minStepProp?: number;
}

const ValuationFormContainerBase: FC<IProps> = ({
  openWidget,
  activeStepProp,
}) => {
  const isMobileSize = useIsMobileSize();
  const {
    isPersonal,
    activeStep,
    isAuthFlow,
    owner,
    isRegionalPage,
    isIframe,
    isVWOpen,
    isNoModal,
  } = useUsefulValuationContainerData();
  const { reset, watch } = useFormContext();
  const { onBackClick, onCloseClick } = useModalButtonsHandler({
    reset,
    watch,
  });
  function onBeforeUnload(event: Event) {
    event.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-return-assign
    return (event.returnValue = '');
  }

  useEffect(() => {
    if (isVWOpen) {
      // eslint-disable-next-line unicorn/prefer-add-event-listener
      window.addEventListener('beforeunload', onBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isVWOpen]);

  const isShowUsefulInfo =
    (!isMobileSize || activeStep === 0 || activeStepProp === 0) && !isAuthFlow;

  const valuationForm = useMemo(
    () =>
      isPersonal ? (
        <PersonalValuation />
      ) : (
        <OnlineValuation activeStepProp={activeStepProp} />
      ),
    [activeStepProp, isPersonal]
  );
  const isShowHeader = !isIframe && activeStepProp !== 0 && isMobileSize;

  if (owner) {
    return (
      <Container isIframe={isIframe} isOverflowVisible={isNoModal}>
        {isShowHeader && (
          <ModalHeader
            openWidget={openWidget}
            activeStep={activeStep}
            isUpperCase
            onClose={onCloseClick}
            onBackClick={onBackClick}
            title="Online Immobilienbewertung"
          />
        )}
        <FormContainer isRegionalPage={isRegionalPage}>
          {valuationForm}
        </FormContainer>
      </Container>
    );
  }

  return (
    <Container isIframe={isIframe} isOverflowVisible={isNoModal}>
      {isShowHeader && (
        <ModalHeader
          activeStep={activeStep}
          openWidget={openWidget}
          isUpperCase
          onClose={onCloseClick}
          onBackClick={onBackClick}
          title="Online Immobilienbewertung"
        />
      )}
      <FormContainer isRegionalPage={isRegionalPage}>
        {valuationForm}
      </FormContainer>
      {!isAuthFlow && (
        <ProgressBar activeStep={activeStep} formSteps={FORM_STEPS} />
      )}
      {isShowUsefulInfo && (
        <UsefulInfo
          keyPhrases={usefulInfoKeyPhrases}
          isShort={!isVWOpen}
          isIframe={isIframe}
          isRegionalPage={isRegionalPage}
        />
      )}
    </Container>
  );
};

const ValuationFormContainer = memo(ValuationFormContainerBase);

export { ValuationFormContainer };
