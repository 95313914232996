import { ReactComponent as PhoneCircle } from 'assets/streamline-light/phones-mobile-devices/phone/phone-circle.svg';
import { FC, memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { useReadGeneralAppointmentByCountryQuery } from '../../../../generated';

import { device } from '../../../../style/theme';
import { MainButton } from '../../../common/components/ui/buttons';
import Icon from '../../../common/components/ui/icon';
import {
  Headline2,
  ParagraphTitle,
  PhoneLink,
} from '../../../common/components/ui/typography';
import { useAppDispatch } from '../../../common/hooks';
import useGetLocalization from '../../../localization/get-localization';
import { toggleTimifyModal } from '../../../timify/redux/timifySlice';
import FaqModal from './faq-modal';

const Container = styled.div`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-bottom: 16px;
  @media ${device.tablet} {
    /* display: none; */
    padding: 16px;
  }
`;

const Title = styled(Headline2)`
  margin-top: 0;
  margin-bottom: 6px;
`;

const PhoneWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`;

const PhoneText = styled(ParagraphTitle)`
  opacity: 0.6;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  margin: 0 4px 0 8px;
`;

const PhoneNumber = styled(ParagraphTitle)`
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
`;

const FaqModalTrigger = styled.button`
  margin-bottom: 16px;
  font-size: 16px;
  text-decoration: underline;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  font-family: Source Serif Pro, serif;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  text-align: start;
`;

const Label = styled.h5`
  font-family: Source Serif Pro;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  margin-bottom: 4px;
`;

const GeneralText = styled.div`
  font-family: Roboto !important;
  font-size: 11px;
  font-weight: 500;
  opacity: 0.6;
  color: #002f5d;
  margin-bottom: 16px;
  line-height: 1.45;
  letter-spacing: 0.5px;

  * {
    text-align: left !important;
  }
`;

interface IProps {
  handleOpenTimifyModal?: () => void;
}

const GeneralAppointmentCardBase: FC<IProps> = ({ handleOpenTimifyModal }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const themeContext = useContext(ThemeContext);
  const lang = useGetLocalization();

  const [isFaqOpen, setFaqOpen] = useState(false);

  const {
    isLoading: isLoadingGeneralAppointment,
    data: generalAppointmentData,
  } = useReadGeneralAppointmentByCountryQuery({ country: lang });

  const openTimifyModal = () => {
    if (handleOpenTimifyModal) {
      handleOpenTimifyModal();
    } else {
      dispatch(toggleTimifyModal(true));
    }
  };

  if (isLoadingGeneralAppointment) return null;

  return (
    <Container>
      <Title content={t('appointment.title.text')} />
      <FaqModalTrigger onClick={() => setFaqOpen(true)}>
        {t('appointment.description.text')}
      </FaqModalTrigger>
      <FaqModal isOpen={isFaqOpen} onClose={() => setFaqOpen(false)} />
      <Label>{generalAppointmentData?.data?.label}</Label>
      <GeneralText
        dangerouslySetInnerHTML={{
          __html: generalAppointmentData?.data?.generalText ?? '',
        }}
      />
      <PhoneWrapper>
        <Icon
          width={20}
          height={20}
          icon={PhoneCircle}
          color={themeContext.blue}
        />
        <PhoneText content={`${t('appointment.phone.text')}:`} />
        <PhoneLink phoneNumber={generalAppointmentData?.data?.phone || ''}>
          <PhoneNumber content={generalAppointmentData?.data?.phone || ''} />
        </PhoneLink>
      </PhoneWrapper>
      <MainButton
        label={generalAppointmentData?.data?.buttonLabel || ''}
        onClick={openTimifyModal}
        fluid
        centered
      />
    </Container>
  );
};

const GeneralAppointmentCard = memo(GeneralAppointmentCardBase);

export { GeneralAppointmentCard };
