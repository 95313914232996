import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 24px;
  margin-bottom: 16px;
`;

export const VariantItem = styled.div<{ isHidden?: boolean }>`
  opacity: ${({ isHidden }) => (isHidden ? 0.5 : 1)};
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 16px;
  padding: 2px 8px;
  text-transform: uppercase;
  border-radius: 10px;
  border: 1px solid
    ${({ theme, isHidden }) =>
      isHidden ? theme.ctaDisabledBackground : theme.lightBlue};
  background-color: ${({ theme, isHidden }) =>
    isHidden ? theme.white : theme.greyBackground};

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
