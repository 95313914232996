import { useCallback } from 'react';
import { batch } from 'react-redux';
import { isNumber } from 'lodash';

import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { IFinancingForm } from '../../interface';
import {
  cleanFinancingCalculationStateAction,
  clearErrors,
  setCalculateFinancingPropositions,
  setErrors,
  setVariantIndex,
  toggleLoadingUpdating,
  toggleMobileEditWidget,
} from '../../redux/financingSlice';
import { FormError } from '../../../interface';
import { useUpdateFinancing } from '../callback-hooks/useUpdateFinancing';
import {
  FinancingProposition,
  FinancingReason,
  FinancingType,
  FinancingVariant,
} from '../../../../../generated';
import { parseFinancingInput } from '../../utils/parse-financing-input';

interface IHookProps {
  // isCertificate: boolean;
  financingId: string;
  financingVariantsProp: FinancingVariant[];
}

const useSubmitFinancingVariantForm = ({
  // isCertificate,
  financingId,
  financingVariantsProp,
}: IHookProps) => {
  const dispatch = useAppDispatch();
  const { calculateFinancingPropositions } = useAppSelector(
    (state) => state.financing.editWidget
  );
  const { selectedAddress } = useAppSelector((state) => state.financing);
  const {
    updateFinancing,
    data,
    error: financingVariantError,
  } = useUpdateFinancing();

  const onSubmitFinancingVariantForm = useCallback(
    async (formData: IFinancingForm, variantIndex?: number): Promise<void> => {
      if (formData) {
        batch(() => {
          dispatch(toggleLoadingUpdating(true));
          dispatch(clearErrors());
        });

        // financing mobile edit widget logic
        dispatch(toggleMobileEditWidget(false));
        const editWidgetContainer = document.querySelector(
          '#modal-root'
        ) as HTMLButtonElement;
        editWidgetContainer.style.display = '';
        document.body.style.overflow = 'overlay';

        try {
          // eslint-disable-next-line promise/param-names
          if (calculateFinancingPropositions) {
            // create variant data
            const financingVariantData = {
              // ...financingVariantsProp[0].financingVariantData,
              ...formData,
            };

            const financingVariantsWithIndex = financingVariantsProp.map(
              (item, i) => ({
                ...item,
                financingVariantData: {
                  ...item.financingVariantData,
                  index: i,
                },
              })
            );
            const input = parseFinancingInput(
              financingVariantData,
              FinancingType.Financing,
              selectedAddress
            );
            const financingVariant = input.financingVariants[0];

            const isPurchOfExProperty =
              financingVariant.financingVariantData.reason ===
              FinancingReason.PurchaseOfExistingProperty;

            let financingPropositions: FinancingProposition[];
            let financingPropositionMaxPurchasePrice:
              | FinancingProposition
              | null
              | undefined;
            if (
              isPurchOfExProperty &&
              calculateFinancingPropositions.length > 6
            ) {
              financingPropositions = calculateFinancingPropositions.filter(
                (item) => !item.maximalPurchasePrice
              );
              financingPropositionMaxPurchasePrice =
                calculateFinancingPropositions.find(
                  (item) => !!item.maximalPurchasePrice
                );
            } else {
              financingPropositions = calculateFinancingPropositions;
              financingPropositionMaxPurchasePrice = null;
            }

            // if variant index => update variant by index
            const financingVariants = isNumber(variantIndex)
              ? financingVariantsWithIndex.map((item, i) =>
                  i === variantIndex
                    ? {
                        ...financingVariant,
                        financingPropositions,
                        financingPropositionMaxPurchasePrice,
                        financingSuggestions: {
                          isCertificateSuggestion:
                            item.financingSuggestions.isCertificateSuggestion,
                          isRequestedFinancing: false,
                        },
                      }
                    : item
                )
              : [
                  ...financingVariantsWithIndex,
                  {
                    ...financingVariant,
                    financingVariantData: {
                      ...financingVariant.financingVariantData,
                      index: financingVariantsWithIndex.length,
                    },
                    financingPropositions,
                    financingPropositionMaxPurchasePrice,
                    financingSuggestions: {
                      isRequestedFinancing: false,
                      isCertificateSuggestion:
                        formData.reason ===
                        FinancingReason.PurchaseOfExistingProperty,
                    },
                  },
                ];

            await updateFinancing({
              financingInput: {
                _id: financingId,
                financingVariants,
              },
            });
            if (!isNumber(variantIndex)) {
              dispatch(setVariantIndex(financingVariants.length - 1));
            }
          }
        } catch (error: unknown) {
          batch(() => {
            dispatch(toggleLoadingUpdating(false));
            dispatch(setErrors(error as FormError));
          });
        } finally {
          dispatch(toggleLoadingUpdating(false));
          dispatch(setCalculateFinancingPropositions());
          dispatch(cleanFinancingCalculationStateAction());
        }
      }
    },
    [
      dispatch,
      financingId,
      calculateFinancingPropositions,
      financingVariantsProp,
      // isCertificate,
      selectedAddress,
      updateFinancing,
    ]
  );

  return {
    onSubmitFinancingVariantForm,
    data,
    financingVariantError,
  };
};

export { useSubmitFinancingVariantForm };
