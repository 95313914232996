import { useParams } from 'react-router-dom';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { BuyerFinderGuide } from './buyer-finder-guide/buyer-finder-guide';
import { BuyerFinderFaq } from './buyer-finder-faq/buyer-finder-faq';
import { BuyerFinderList } from './buyer-finder-list/buyer-finder-list';
import { BuyerFinderSelectedList } from './buyer-finder-selected-list/buyer-finder-selected-list';
import { BuyerFinderGraph } from './buyer-finder-graph/buyer-finder-graph';
import {
  SearchProfile,
  useGetPropertyFavoriteSearchProfilesListQuery,
  useLazyFindRelatedSearchProfilesQuery,
  useFindRelatedSearchProfilesQuery,
  UserStatus,
  useUpdatePropertyFavoriteSearchProfilesListMutation,
} from '../../../../../generated';
import { BuyerFinderPageSort } from '../../../interfaces';
import { ValuationLoader } from '../../../../forms/components/common/loader';
import {
  Section,
  SuccessButton,
  SuccessContainer,
  SuccessIconContainer,
  SuccessText,
  SuccessWrapper,
  Wrapper,
} from './buyer-finder-page-styles';
import {
  useNotifyBrokerForBuyerFinderMutation,
  usePropertyQuery,
} from '../../../../../services/graphql/enhanced';
import { ReactComponent as Bird } from 'assets/buyer-finder/bird-mail.svg';
import { useTranslation } from 'react-i18next';

const BuyerFinderPageMemo: FC = () => {
  const { t } = useTranslation();
  const [isBlurredGraphic, setIsBlurredGraphic] = useState(false);
  // const [
  //   updateFavoriteSPList,
  //   { isLoading: isLoadingUpdatePropertyFavoriteSP },
  // ] = useUpdatePropertyFavoriteSearchProfilesListMutation();
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState(BuyerFinderPageSort.HighestPrice);
  const [isSuccessShown, setIsSuccessShown] = useState(false);
  const { propertyId } = useParams<{ propertyId: string }>();
  const [
    notifyBroker,
    {
      isLoading: isLoadingNotifyBroker,
      isSuccess: isSuccessNotifyBroker,
      isError: isErrorNotifyBroker,
    },
  ] = useNotifyBrokerForBuyerFinderMutation();

  const { data: propertyData } = usePropertyQuery({
    id: propertyId,
  });
  const userStatus = propertyData?.property?.userStatus;
  const isBrokerNotified =
    !!propertyData?.property?.isBrokerNotifiedOfBuyerFinder;

  const { data: dataRelatedSP, isLoading: isLoadingRelatedSP } =
    useFindRelatedSearchProfilesQuery(
      {
        id: propertyId,
      },
      { skip: !propertyId }
    );
  const searchProfiles =
    dataRelatedSP?.findRelatedSearchProfiles as SearchProfile[];

  // const {
  //   favoriteSearchProfiles: favoriteSPList,
  //   refetch,
  //   isLoading: isLoadingFavoriteSP,
  //   isFetching: isFetchingFavoriteSP,
  // } = useGetPropertyFavoriteSearchProfilesListQuery(
  //   {
  //     id: propertyId,
  //   },
  //   {
  //     selectFromResult: ({ data, isLoading, isFetching }) => {
  //       const favoriteSearchProfiles =
  //         data?.getPropertyFavoriteSearchProfilesList || [];
  //       return {
  //         favoriteSearchProfiles,
  //         isLoading,
  //         isFetching,
  //       };
  //     },
  //   }
  // );

  // useEffect(() => {
  //   query({
  //     id: propertyId,
  //   });
  // }, []);
  //
  // useEffect(() => {
  //   query({
  //     id: propertyId,
  //   });
  // }, [propertyId]);

  useEffect(() => {
    if (isErrorNotifyBroker || isSuccessNotifyBroker) {
      setIsSuccessShown(true);
    }
  }, [isErrorNotifyBroker, isSuccessNotifyBroker]);

  const onLoadMore = useCallback(() => {
    setLimit((s) => s + 10);
  }, []);

  const onChangeSort = useCallback((value: BuyerFinderPageSort) => {
    setSort(value);
  }, []);

  // const onChangeFavoriteSP = async (searchProfileId: string) => {
  //   if (
  //     favoriteSPList &&
  //     !isLoadingUpdatePropertyFavoriteSP &&
  //     !isFetchingFavoriteSP
  //   ) {
  //     const favoriteSearchProfilesIds = favoriteSPList.map((item) => item._id);
  //     const isExist = favoriteSearchProfilesIds.includes(searchProfileId);
  //
  //     const input = isExist
  //       ? {
  //           _id: propertyId,
  //           favoriteSearchProfiles: favoriteSearchProfilesIds.filter(
  //             (item) => item !== searchProfileId
  //           ),
  //         }
  //       : {
  //           _id: propertyId,
  //           favoriteSearchProfiles: [
  //             ...favoriteSearchProfilesIds,
  //             searchProfileId,
  //           ],
  //         };
  //
  //     await updateFavoriteSPList({ input });
  //     refetch();
  //   }
  // };

  const sortedSearchProfiles = useMemo(() => {
    if (!searchProfiles?.length) {
      return [];
    }
    const copySearchProfiles = [...searchProfiles];
    return copySearchProfiles.sort((a, b) =>
      sort === BuyerFinderPageSort.HighestPrice
        ? b.searchProfileData?.purchasePriceMax -
          a.searchProfileData?.purchasePriceMax
        : a.searchProfileData?.purchasePriceMax -
          b.searchProfileData?.purchasePriceMax
    );
  }, [sort, searchProfiles?.length]);

  const onNotifyBroker = () => {
    if (!isLoadingNotifyBroker) {
      notifyBroker({
        propertyId,
        countSearchProfiles: String(sortedSearchProfiles.length),
        isBlurredGraphic,
      });
    }
  };

  const isNotifyButton =
    userStatus === UserStatus.Owner || userStatus === UserStatus.Editor;

  const isViewRightsOnly = !(
    userStatus === UserStatus.Owner || userStatus === UserStatus.Editor
  );

  return (
    <Wrapper>
      {isSuccessShown && (
        <SuccessWrapper>
          <SuccessContainer>
            <SuccessIconContainer>
              <Bird />
            </SuccessIconContainer>
            <SuccessText content={t('buyer-finder.success-text')} />
            <SuccessButton
              label={t('buyer-finder.success-button')}
              onClick={() => {
                setIsSuccessShown(false);
              }}
            />
          </SuccessContainer>
        </SuccessWrapper>
      )}

      <BuyerFinderGuide />

      {isLoadingRelatedSP ? (
        <ValuationLoader maxWidth="500px" mb={96} />
      ) : (
        <>
          {searchProfiles?.length > 0 && (
            <BuyerFinderGraph
              // searchProfiles={searchProfiles || []}
              searchProfiles={[]}
              onNotifyBroker={onNotifyBroker}
              setIsBlurredGraphic={setIsBlurredGraphic}
              isViewRightsOnly={isViewRightsOnly}
            />
          )}
          <Section>
            <BuyerFinderList
              count={searchProfiles?.length || 0}
              searchProfiles={sortedSearchProfiles || []}
              // favoriteSP={favoriteSPList.map((item) => item._id) || []}
              favoriteSP={[]}
              onLoadMore={onLoadMore}
              onChangeSort={onChangeSort}
              // onChangeFavoriteSP={onChangeFavoriteSP}
              onChangeFavoriteSP={() => null}
              isNotifyButton={isNotifyButton}
              isViewRightsOnly={isViewRightsOnly}
              onNotifyBroker={onNotifyBroker}
            />
          </Section>
          {/* {favoriteSPList?.length > 0 && ( */}
          {/*  <Section> */}
          {/*    <BuyerFinderSelectedList */}
          {/*      count={favoriteSPList.length} */}
          {/*      searchProfiles={favoriteSPList as SearchProfile[]} */}
          {/*      onChangeFavoriteSP={onChangeFavoriteSP} */}
          {/*      isNotifyButton={isNotifyButton} */}
          {/*      onNotifyBroker={onNotifyBroker} */}
          {/*    /> */}
          {/*  </Section> */}
          {/* )} */}
        </>
      )}
      <Section>
        <BuyerFinderFaq />
      </Section>
    </Wrapper>
  );
};

export const BuyerFinderPage = memo(BuyerFinderPageMemo);
