import { useEffect, useState } from 'react';
import { Proximity } from '../form-property-valuation-wizard/constants';
import { useGetCountry } from '../../localization/get-country';

export const useSetProximityFromLocalization = (): string => {
  const [proximity, setProximity] = useState('');
  const { isAustria, isSwitzerland } = useGetCountry();
  useEffect(() => {
    if (isSwitzerland) {
      setProximity(Proximity.SWITZERLAND);
    } else if (isAustria) {
      setProximity(Proximity.AUSTRIA);
    } else {
      setProximity(Proximity.GERMANY);
    }
  }, [isAustria, isSwitzerland, setProximity]);

  return proximity;
};
