import styled from 'styled-components';
import {
  BoxTitle,
  Headline2,
  Headline3,
  ParagraphText,
  ParagraphTitle,
} from '../../../common/components/ui/typography';
import { device } from '../../../../style/theme';

export const Container = styled.div`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  margin-bottom: 16px;
  background-color: ${(props) => props.theme.white};

  @media ${device.tablet} {
    padding: 16px;
    margin-bottom: 0;
  }
`;

export const Title = styled(Headline2)`
  text-align: center;
`;

export const Content = styled.div<{ isBlurred?: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, minmax(162px, 1fr));
  grid-template-rows: repeat(1, minmax(72px, 1fr));
  gap: 12px;
  margin-top: 16px;
  ${(props) => props.isBlurred && 'filter: blur(6px)'};

  @media ${device.tablet} {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.borderColor};
  position: relative;

  &:first-of-type {
    background-color: ${(props) => props.theme.greyBackground};
  }
`;

export const ItemLabel = styled(BoxTitle)`
  opacity: 0.8;
  font-weight: bold;

  @media ${device.mobile} {
    display: block;
    width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const ItemValue = styled(Headline3)`
  line-height: 1.2;

  // @media ${device.mobile} {
  //   text-align: center;
  // }
`;

export const SyncDateContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 16px;
`;

export const SyncDateLabel = styled(ParagraphTitle)`
  letter-spacing: 0.5px;
  line-height: 1.7;
`;

export const SyncDateValue = styled(ParagraphText)`
  letter-spacing: 0.5px;
  line-height: 1.7;
  margin-left: 4px;
`;

export const Info = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  .tooltip {
    background-color: ${(props) => props.theme.white};
    opacity: 1 !important;
    padding: 8px;
  }

  .icon {
    > svg,
    > svg > path {
      stroke: ${(props) => props.theme.blue};
    }
  }
`;

export const TooltipContainer = styled.div`
  max-width: 316px;
  width: 100%;
  padding: 0;
  text-align: center;
`;

export const TooltipParagraph = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;
