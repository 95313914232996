import styled from "styled-components";
import { device } from "../../../../../style/theme";
import { MainButton } from "../../../../common/components/ui/buttons";

export const desktopWrapperStyle: React.CSSProperties = {
  maxWidth: 851,
  padding: '32px',
};

export const mobileWrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  padding: 0,
  overflow: 'auto',
  position: "fixed",
  top: 0,
  bottom: 0
};

export const Row = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  background-color: ${(props) => props.theme.blue};
  width: 100%;
  justify-content: center;
  padding: 18px 0 18px 0;
  position: relative;
`;

export const TitleMobile = styled.h3`
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 16px;
`;

export const Content = styled.div`
  padding-bottom: 32px;
  @media ${device.tablet} {
    background: white;
    padding: 16px;
  }
`;

export const Title = styled.h1`
  font-family: 'Source Serif Pro';
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 1px;
  color: #00305e;
`

export const Subtitle = styled.h1`
  font-family: Roboto;
  padding-bottom: 32px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.14;
  letter-spacing: 1px;
  color: #00305e;
  background: white;

  @media ${device.tablet} {
    padding: 0 12px 16px;
  }
`

export const TablesGrid = styled.div`
  display: grid;
  grid-template-columns: 338px 421px;
  grid-column-gap: 26px;
  grid-row-gap: 24px;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    background: white;
  }
`

export const Table = styled.div`
  background: white;
`

export const TableTitle = styled.div`
  padding: 12px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background: #fff;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #00305e;
  border: solid 1px rgba(168, 168, 167, 0.15);
  border-bottom: none;
`

export const TableRow = styled.div`
  border-left: solid 1px rgba(168, 168, 167, 0.15);
  border-right: solid 1px rgba(168, 168, 167, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  color: #00305e;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  background: white;

  &:nth-child(even){
    background-color: #f2f5f7;
  }

  &:last-child{
    border-bottom: solid 1px rgba(168, 168, 167, 0.15);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`

export const RowValue = styled.span`
  white-space: nowrap;
`

export const CTAButton = styled(MainButton)`
  text-transform: uppercase;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
`;
