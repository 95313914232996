import styled from 'styled-components';

interface IProps {
  fluid?: boolean;
}

const BaseLinkButton = styled.a<IProps>`
  border-radius: 3px;
  padding: 14px 24px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  width: ${(props) => (props.fluid ? '100%' : 'auto')};
  text-decoration: none;
`;

export { BaseLinkButton };
