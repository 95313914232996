import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import LngLink from '../../../../localization/lng-link';

export const CTAContainer = styled.div<{ iniframe?: boolean }>`
  position: ${({ iniframe }) => (iniframe ? 'static' : 'absolute')};
  bottom: -24px;
  right: 48px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media ${device.tablet} {
    position: static;
    padding: 0 16px;
  }
`;

export const Link = styled(LngLink)`
  text-decoration: none;
`;
