import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PaginationArgs,
  ExposeRequest,
  SuggestedPropertiesFilterInput,
} from '../../../generated';

interface InitialState {
  filters: PaginationArgs;
  onlineExposes: Array<Partial<ExposeRequest>>;
  totalItems: number;
  initialLoader: boolean;
}

export const filtersInitialState: SuggestedPropertiesFilterInput = {
  offset: 0,
  limit: 8,
};

const initialState: InitialState = {
  filters: {},
  onlineExposes: [],
  totalItems: 0,
  initialLoader: true,
};

export const onlineExposesSlice = createSlice({
  name: 'onlineExposes',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<PaginationArgs>) {
      state.filters = action.payload;
    },
    mergeFilters(state, action: PayloadAction<Partial<PaginationArgs>>) {
      state.filters = {
        ...(state.filters as PaginationArgs),
        ...action.payload,
      };
    },
    clearExposesCache(state) {
      state.onlineExposes = [];
    },
    addOnlineExposes(state, action: PayloadAction<ExposeRequest[]>) {
      state.onlineExposes = [...state.onlineExposes, ...action.payload];
    },
    setTotalItems(state, action: PayloadAction<number>) {
      state.totalItems = action.payload;
    },
    setInitialLoader(state, action: PayloadAction<boolean>) {
      state.initialLoader = action.payload;
    },
  },
});

export const {
  setFilters,
  mergeFilters,
  clearExposesCache,
  addOnlineExposes,
  setTotalItems,
  setInitialLoader,
} = onlineExposesSlice.actions;

export default onlineExposesSlice.reducer;
