import { IMapViewport, IMapPoint } from './interfaces';

// Helper function to convert degrees to radians and vice versa
const toRadians = (degrees: number) => (degrees * Math.PI) / 180;
const toDegrees = (radians: number) => (radians * 180) / Math.PI;

// Calculate geographical center of a set of points
const calculateGeographicCenter = (
  points: IMapPoint[]
): { latitude: number; longitude: number } => {
  let x = 0;
  let y = 0;
  let z = 0;

  points.forEach((point) => {
    const lat = toRadians(point.latitude);
    const lng = toRadians(point.longitude);
    x += Math.cos(lat) * Math.cos(lng);
    y += Math.cos(lat) * Math.sin(lng);
    z += Math.sin(lat);
  });

  x /= points.length;
  y /= points.length;
  z /= points.length;

  const centralLng = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLat = Math.atan2(z, centralSquareRoot);

  return { latitude: toDegrees(centralLat), longitude: toDegrees(centralLng) };
};

// Helper function to calculate the map's zoom level to fit all points within the viewport dimensions
const calculateZoom = (
  minLng: number,
  maxLng: number,
  minLat: number,
  maxLat: number,
  width: number,
  height: number
): number => {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  const latRad = toRadians((minLat + maxLat) / 2);
  const lngDelta = toRadians(maxLng - minLng);
  const latDelta = toRadians(maxLat - minLat);
  const lngDimension = Math.cos(latRad) * lngDelta;

  const scaleX = width / WORLD_DIM.width / lngDimension;
  const scaleY = height / WORLD_DIM.height / latDelta;

  const scale = Math.min(scaleX, scaleY);
  const zoom = Math.log2(scale);

  return Math.min(Math.floor(zoom), ZOOM_MAX);
};

export const calculateBestMapView = (
  points: IMapPoint[],
  viewportWidth: number,
  viewportHeight: number
): IMapViewport => {
  if (points.length === 0) {
    return { latitude: 0, longitude: 0, zoom: 1, bearing: 0, pitch: 0 };
  }

  let minLat = Number.POSITIVE_INFINITY;
  let maxLat = Number.NEGATIVE_INFINITY;
  let minLng = Number.POSITIVE_INFINITY;
  let maxLng = Number.NEGATIVE_INFINITY;

  // Calculate the bounding box
  points.forEach((point) => {
    minLat = Math.min(minLat, point.latitude);
    maxLat = Math.max(maxLat, point.latitude);
    minLng = Math.min(minLng, point.longitude);
    maxLng = Math.max(maxLng, point.longitude);
  });

  // Calculate the centroid using the geographic center function
  const center = calculateGeographicCenter(points);

  // Calculate the optimal zoom level
  const zoom = calculateZoom(
    minLng,
    maxLng,
    minLat,
    maxLat,
    viewportWidth,
    viewportHeight
  );

  return {
    latitude: center.latitude,
    longitude: center.longitude,
    zoom,
    bearing: 0,
    pitch: 0,
  };
};
