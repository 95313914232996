import { usePDF } from '@react-pdf/renderer';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { saveAs } from 'file-saver';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { MyPropertyDocument } from '..';
import { OffersSearchInput, Property } from '../../../../generated';
import {
  usePropertyQuery,
  useSimilarPropertiesQuery,
} from '../../../../services/graphql/enhanced';
import { ValueCalculator } from '../../../property/components/value-calculator/value-calculator';
import {
  DEFAULT_SIMILAR_PROPERTIES_FILTERS,
  PERIOD_2_YEARS,
} from '../../../property/constants';
import { implodeAddress } from '../../../property/utils/address';
import { getPropertyPeriod } from '../../../property/utils/date';
import { SocialEconomicsPropertyWrapper } from '../../../property/components/tabs/social-economics/social-economics-property-wrapper';

interface IProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Container = styled.div`
  position: absolute;
  z-index: -9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
`;

const PDFContainer = ({ setLoading }: IProps): JSX.Element => {
  const containerRef = useRef<null | HTMLDivElement>(null);
  const [images, setImages] = useState<{ [key: string]: string }>({});
  const [filters, setFilters] = useState<OffersSearchInput | null>(null);
  const [downloadReady, setDownloadReady] = useState(false);
  const { propertyId } = useParams<{ propertyId: string }>();

  const { data: propertyCache } = usePropertyQuery({ id: propertyId });
  const {
    data: similarProperties,
    isUninitialized,
    isLoading,
  } = useSimilarPropertiesQuery(
    filters
      ? {
          input: filters,
        }
      : skipToken
  );
  const { propertyData, valuations, valuationsLastSync, poi, locationScores } =
    propertyCache?.property ?? {};

  // locationScores doesn't have transport score
  const { transport, ...restPOI } = poi ?? {};

  const address = implodeAddress(propertyData?.location.address);
  const { coordinates } = propertyData?.location ?? {};

  const currentValuationIndex =
    valuations?.findIndex(
      (valuation) =>
        new Date(valuation.date).toDateString() ===
        new Date(valuationsLastSync).toDateString()
    ) ?? -1;

  const currentQuarterValuationIndex = currentValuationIndex - 1;

  const [instance, updateInstance] = usePDF({
    document: (
      <MyPropertyDocument
        ValueCalculator={ValueCalculator as React.ElementType}
        address={address}
        images={images}
        coordinates={coordinates}
        propertyData={propertyData}
        valuations={valuations ?? []}
        currentValuationIndex={currentValuationIndex}
        currentQuarterValuationIndex={currentQuarterValuationIndex}
        valuationsLastSync={valuationsLastSync}
        poi={restPOI ?? {}}
        locationScores={locationScores}
        similarProperties={similarProperties?.getOffers.items}
      />
    ),
  });

  useEffect(() => {
    setFilters(
      DEFAULT_SIMILAR_PROPERTIES_FILTERS(
        propertyCache?.property as Property,
        getPropertyPeriod(PERIOD_2_YEARS)
      )
    );
  }, [propertyCache?.property]);

  useEffect(() => {
    setTimeout(() => {
      const canvases = containerRef.current?.querySelectorAll('canvas');
      const imagesObject: {
        [key: string]: string;
      } = {};
      canvases?.forEach((c) => {
        if (c.id) {
          imagesObject[c.id] = c.toDataURL('image/png', 1);
        }
      });
      const root = document.querySelector('#root');
      const overviewCanvases = root?.querySelectorAll('canvas');
      overviewCanvases?.forEach((c) => {
        if (c.id) {
          imagesObject[c.id] = c.toDataURL('image/png', 1);
        }
      });
      setImages(imagesObject);
    }, 3000);
  }, []);

  useEffect(() => {
    if (
      Object.keys(images).length > 0 &&
      !isUninitialized &&
      !isLoading &&
      similarProperties !== null
    ) {
      updateInstance();
      setDownloadReady(true);
    }
  }, [
    images,
    setLoading,
    isUninitialized,
    similarProperties,
    isLoading,
    updateInstance,
  ]);

  useEffect(() => {
    if (
      !instance?.loading &&
      !instance?.error &&
      instance?.blob &&
      downloadReady
    ) {
      saveAs(instance?.blob, 'property');
      setLoading(false);
    }
  }, [downloadReady, instance, setLoading]);

  return createPortal(
    <Container ref={containerRef}>
      <SocialEconomicsPropertyWrapper />
    </Container>,
    document.body as Element
  );
};

export { PDFContainer };
