import Collapse, { Panel } from 'rc-collapse';
import { CollapsePanelProps } from 'rc-collapse/lib/interface';
import { memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as ArrowDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import { ReactComponent as Filter1 } from 'assets/streamline-light/interface-essential/filter/filter-1.svg';
import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';

import { Property } from '../../../../generated';
import { device } from '../../../../style/theme';
import { Modal } from '../../../common/components/modal';
import { Title } from '../../../common/components/ui/expandable-content';
import motion from '../../../common/components/ui/faq-accordion/utils';
import Icon from '../../../common/components/ui/icon';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { DEFAULT_PROPERTY_FILTERS_STATE } from '../../constants';
import { ISimilarPropertiesFilters } from '../../interfaces';
import { FiltersForm } from './filters-form';

interface IProps {
  property: Property;
}

const CollapseContainer = styled.div`
  &&& {
    border-radius: 3px;
    background-color: #fff;
    border: solid 1px ${({ theme }) => theme.ctaDisabledBackground};
    padding: 32px;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    .filter-accordion {
      border: none !important;
      background: transparent;

      .rc-collapse-item {
        border: none !important;
        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
        .filter-header {
          cursor: pointer;
          padding: 0;
          margin: 0;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: 1px;
          color: ${(props) => props.theme.blue};

          .icon {
            margin-left: auto;
            order: 2;
            path {
              stroke-width: 1.5px;
            }
          }
        }
        &.rc-collapse-item-active {
          .outer-header {
            // Active
          }
        }
        .rc-collapse-content {
          margin: 16px 0 0 0;
          overflow: visible;
          padding: 0 7px;
          .rc-collapse-content-box {
            margin: 0;
            @media ${device.tablet} {
            }

            .inner-accordion {
              border-radius: 3px;
              border: solid 1px ${(props) => props.theme.lightBlue};
              background-color: ${(props) => props.theme.greyBackground};
              margin-bottom: 12px;
              .rc-collapse-item {
                .inner-header {
                  padding: 12px;
                  .icon {
                    margin-left: auto;
                    order: 2;
                  }
                }
                .rc-collapse-content {
                  padding: 0 12px 12px 12px;
                  margin: 0;
                  border-radius: 0;
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
  @media ${device.tablet} {
    &&& {
      padding: 18px 16px;
      border: none;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 12px;
  flex: 0 0 auto;
  & > svg {
    height: auto;
    width: 100%;
    color: ${({ theme }) => theme.blue};
  }
  @media ${device.tablet} {
    width: 24px;
    height: auto;
  }
`;

const HeaderWrapper = styled.div`
  padding: 18px 16px;
  margin-top: -16px;
`;

const ArrowWrapper = styled.div`
  transform: rotate(-90deg);
  margin: 0 0 0 auto;
`;

const ModalHeader = styled.div`
  height: 56px;
  min-height: 56px;
  width: calc(100% + 32px);
  background: ${(props) => props.theme.blue};
  position: relative;
  margin: -16px 0 16px -16px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  > div:first-child,
  > div:last-child {
    flex: 0 0 auto;
  }
`;

const ModalTitle = styled.h2`
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ theme }) => theme.white};
  flex: 1;
  width: calc(100% - 56px);
  margin: auto;
`;

const ModalClose = styled.div`
  position: absolute;
  right: 16px;
  & > svg {
    width: 16px;
    color: ${({ theme }) => theme.white};
  }
`;

const mobileWrapperStyle = {
  width: '100%',
  height: '100%',
  padding: 16,
  maxHeight: '100%',
  maxWidth: '100%',
  overflow: 'overlay',
};

const FiltersBase = ({ property }: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [numberOfRooms, setNumberOfRooms] = useState(
    DEFAULT_PROPERTY_FILTERS_STATE.numberOfRooms
  );

  const [filterState, setFilterState] = useState<ISimilarPropertiesFilters>(
    DEFAULT_PROPERTY_FILTERS_STATE
  );

  const toggleFiltersModal = () => {
    setIsFiltersModalOpen(!isFiltersModalOpen);
  };

  if (isMobileSize) {
    return (
      <>
        <Modal isOpen={isFiltersModalOpen} wrapperStyles={mobileWrapperStyle}>
          <ModalHeader>
            <ModalTitle>
              {t('similar-properties.filter.section.title')}
            </ModalTitle>

            <ModalClose onClick={toggleFiltersModal}>
              <Close />
            </ModalClose>
          </ModalHeader>

          <FiltersForm
            property={property}
            filterState={filterState}
            numberOfRooms={numberOfRooms}
            setFilterState={setFilterState}
            setNumberOfRooms={setNumberOfRooms}
          />
        </Modal>

        <HeaderWrapper onClick={toggleFiltersModal}>
          <Header>
            <IconWrapper>
              <Filter1 />
            </IconWrapper>
            <Title>{t('similar-properties.filter.section.title')}</Title>
            <ArrowWrapper>
              <Icon
                width={16}
                height={16}
                icon={ArrowDown}
                color={themeContext.blue}
              />
            </ArrowWrapper>
          </Header>
        </HeaderWrapper>
      </>
    );
  }

  return (
    <CollapseContainer>
      <Collapse
        openMotion={motion}
        defaultActiveKey="1"
        className="filter-accordion"
        expandIcon={({ isActive }: CollapsePanelProps) => (
          <Icon
            width={16}
            height={16}
            icon={ArrowDown}
            color={themeContext.blue}
            style={{
              transition: `transform 500ms ease`,
              transform: isActive ? 'rotate(-180deg)' : 'none',
            }}
          />
        )}
      >
        <Panel
          key="1"
          header={
            <Header>
              <IconWrapper>
                <Filter1 />
              </IconWrapper>
              <Title>{t('similar-properties.filter.section.title')}</Title>
            </Header>
          }
          headerClass="filter-header"
          className="filter-content"
        >
          <FiltersForm
            property={property}
            filterState={filterState}
            numberOfRooms={numberOfRooms}
            setFilterState={setFilterState}
            setNumberOfRooms={setNumberOfRooms}
          />
        </Panel>
      </Collapse>
    </CollapseContainer>
  );
};

const Filters = memo(FiltersBase);

export { Filters };
