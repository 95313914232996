import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { isBoolean, isEmpty, isNumber } from 'lodash';

import { useAppSelector } from '../../../common/hooks';
import { useGetTemporaryPropertyQuery } from '../../../../generated';
import { useGetFinancingReason, useGetPropertyBoxes } from './useStepsLogic';

const useGetProgressPercent = (): number => {
  const { watch } = useFormContext();
  const activeStep = useAppSelector((state) => state.financing.activeStep);
  const userInput = useAppSelector((state) => state.financing.userInput);
  const isRedirect = useAppSelector((state) => state.financing.isRedirect);
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);

  const { data } = useGetTemporaryPropertyQuery({}, { skip: isAnonymousUser });

  let values = watch();
  useEffect(() => {
    values = watch();
  }, [activeStep]);
  const formData = isEmpty(values) ? userInput : values;
  const { isSearchProgressField } = useGetPropertyBoxes();

  const {
    reason,
    propertyType,
    propertyUse,
    searchProgress,
    postCode,
    purchasePrice,
    equityCapital,
    brokerageFee,
    brokerFeePercent,
    buildingCosts,
    landPrice,
    propertyValue,
    estimatedRemainingDebt,
    desiredLoanAmount,
    payoutDate,
    // monthlyRate,
  } =
    (isRedirect ? data?.getTemporaryProperty?.propertyData : formData) ||
    formData ||
    {};

  let percent = 0;

  // first step 15%
  if (reason) {
    percent += 15;
  }

  // second step 40%
  if (propertyType) {
    percent += 10;
  }
  if (propertyUse) {
    percent += isSearchProgressField ? 10 : 15;
  }
  if (searchProgress) {
    percent += isSearchProgressField ? 10 : 0;
  }
  if (postCode) {
    percent += isSearchProgressField ? 10 : 15;
  }

  // third step 40%

  const {
    isPurchaseOfExistingProperty,
    isFollowUpFinancing,
    isModernization,
    isOwnConstructionProject,
    isPurchaseOfNewBuilding,
    isRaisingCapital,
  } = useGetFinancingReason();

  // type of the financing
  if (isPurchaseOfExistingProperty || isPurchaseOfNewBuilding) {
    if (isNumber(purchasePrice)) {
      percent += brokerageFee ? 10 : 20;
    }
    if (isNumber(equityCapital)) {
      percent += brokerageFee ? 10 : 10;
    }
    if (isBoolean(brokerageFee)) {
      percent += brokerageFee ? 10 : 0;
    }
    if (isNumber(brokerFeePercent)) {
      percent += brokerageFee ? 10 : 0;
    }
  }

  if (isFollowUpFinancing) {
    if (isNumber(propertyValue)) {
      percent += 14;
    }
    if (payoutDate) {
      percent += 14;
    }
    if (isNumber(estimatedRemainingDebt)) {
      percent += 12;
    }
  }

  if (isOwnConstructionProject) {
    if (isNumber(buildingCosts)) {
      percent += brokerageFee ? 10 : 20;
    }
    if (isNumber(landPrice)) {
      percent += brokerageFee ? 10 : 20;
    }
    if (isBoolean(brokerageFee)) {
      percent += brokerageFee ? 10 : 0;
    }
    if (isNumber(brokerFeePercent)) {
      percent += brokerageFee ? 10 : 0;
    }
  }

  if (isModernization || isRaisingCapital) {
    if (isNumber(propertyValue)) {
      percent += 20;
    }
    if (isNumber(desiredLoanAmount)) {
      percent += 20;
    }
  }

  // third step

  return percent;
};

export { useGetProgressPercent };
