import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Wrapper = styled.div<{ src: string }>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  margin: 36px;
  border: solid 1.6px #9c7b51;
  padding: 16px;
  background-image: url('${({ src }) => src}');

  @media ${device.tablet} {
    margin: 0;
  }
`;

export const Text = styled.p<{
  mb?: number;
  mt?: number;
  maxWidth?: number;
  fontSize: number;
  isBold?: boolean;
}>`
  margin-bottom: ${({ mb }) => mb}px;
  ${({ mt }) => !!mt && `margin-top: ${mt}px;`}
  font-family: source serif pro, sans-serif;
  font-size: ${({ fontSize }) => fontSize}px;
  ${({ maxWidth }) => !!maxWidth && `max-width: ${maxWidth}px;`};
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.blue};
`;

export const BlueBoxContainer = styled.div<{ mb: number }>`
  position: relative;
  margin-bottom: ${({ mb }) => mb}px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: source serif pro, sans-serif;
  font-size: 19.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #031c38;
  min-height: 25px;

  & > div {
    left: -15px !important;
    & span {
      width: 30px;
      height: 30px;
    }
  }

  @media ${device.tablet} {
    font-size: 9.3px;
    min-height: 20px;
  }
`;

export const BlueBox = styled.div<{ mb: number; width: number }>`
  margin-bottom: ${({ mb }) => mb}px;
  width: ${({ width }) => width}px;
  height: 20.3px;
  border-radius: 2.3px;
  background-color: ${({ theme }) => theme.blue};
`;

export const BrownText = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  & > p {
    max-width: 450.3px;
    font-family: source serif pro, sans-serif;
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9d9fa2;

    @media ${device.tablet} {
      font-size: 5.9px;
    }
  }
`;

export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: -80px;

  @media ${device.tablet} {
    width: 410px;
    top: 70px;
    left: -40px;
  }
`;
export const ImageVonPoll = styled.img<{ isCalculationPage?: boolean }>`
  position: absolute;
  right: ${({ isCalculationPage }) => (isCalculationPage ? 24 : 50)}px;
  bottom: ${({ isCalculationPage }) => (isCalculationPage ? -18 : 13)}px;
  z-index: 1;

  @media ${device.tablet} {
    bottom: -8px;
    width: 71.5px;
  }
`;

export const ImageVP = styled.img<{ isCalculationPage?: boolean }>`
  position: absolute;
  right: calc(100% - 50% - 29px);
  top: ${({ isCalculationPage }) => (isCalculationPage ? -17 : 20)}px;
  z-index: 1;

  @media ${device.tablet} {
    right: calc(100% - 50% - 13px);
    top: -8px;
    width: 26px;
  }
`;

export const ErrorBox = styled.div`
  border-radius: 3px;
  border: 1px solid rgba(162, 87, 87, 0.202645);
  padding: 8px;
  background-color: rgba(240, 233, 233, 1);
  max-width: 506px;
  margin-bottom: 21px;

  @media ${device.tablet} {
    padding: 4px;
    margin-bottom: 11px;
  }
`;

export const ErrorText = styled.p`
  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 1.5;

  @media ${device.tablet} {
    font-size: 6.7px;
  }
`;
