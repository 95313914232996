import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isNumber, isUndefined, zipObject } from 'lodash';
import { useAppSelector } from '../../../common/hooks';
import {
  FIELDS,
  FINANCING_FORM_KEYS,
  propertyTypeBoxes,
  reasonBoxes,
} from '../constants';
import { useNextHandler } from './callback-hooks/useNextHandler';
// import { IFinancingForm } from '../interface';
import { FinancingPropertyType, FinancingReason } from '../../../../generated';
import { useFinancingFormInfo } from './useFinancingFormInfo';
import { numberFormat } from '../../../financing/utils/numberFormat';
import { IIframeType } from '../../../auth/interfaces';

interface IGFRReturn {
  isPurchaseOfExistingProperty: boolean;
  isFollowUpFinancing: boolean;
  isOwnConstructionProject: boolean;
  isPurchaseOfNewBuilding: boolean;
  isModernization: boolean;
  isRaisingCapital: boolean;
  // isInstallmentLoan: boolean;
  fieldsInStep: {
    firstStep: string[];
    secondStep: string[];
    thirdStep: string[];
    allFields: string[];
  };
}

export const useGetPropertyBoxes = () => {
  const reasonInput = useAppSelector(
    (state) => state.financing.userInput?.reason
  );

  const { filteredPropertyBoxes, isSearchProgressField } = useMemo(() => {
    const reasonBox = reasonBoxes.find((item) => item.value === reasonInput);
    return {
      filteredPropertyBoxes: propertyTypeBoxes.filter((item) =>
        reasonBox?.propertyTypes?.includes(item.value)
      ),
      isSearchProgressField: !reasonInput || reasonBox?.isSearchProgressField,
    };
  }, [reasonInput]);

  return { filteredPropertyBoxes, isSearchProgressField };
};

export const useGetFinancingReason = (): IGFRReturn => {
  const reasonInput = useAppSelector(
    (state) => state.financing.userInput?.reason
  );
  const propertyTypeInput = useAppSelector(
    (state) => state.financing.userInput?.propertyType
  );
  const methods = useFormContext();
  const [reasonWatch, propertyTypeWatch, livingArea, buildingYear] = useWatch({
    name: [
      FINANCING_FORM_KEYS.REASON,
      FINANCING_FORM_KEYS.PROPERTY_TYPE,
      FINANCING_FORM_KEYS.LIVING_AREA,
      FINANCING_FORM_KEYS.BUILDING_YEAR,
    ],
  });
  const isBrokerageFee = methods.getValues(FINANCING_FORM_KEYS.BROKERAGE_FEE);

  const reason = reasonWatch ?? reasonInput;
  const propertyType = propertyTypeWatch ?? propertyTypeInput;

  const isPurchaseOfExistingProperty = useMemo(
    () => reason === FinancingReason.PurchaseOfExistingProperty,
    [reason]
  );
  const isFollowUpFinancing = useMemo(
    () => reason === FinancingReason.FollowUpFinancing,
    [reason]
  );
  const isOwnConstructionProject = useMemo(
    () => reason === FinancingReason.OwnConstructionProject,
    [reason]
  );
  const isPurchaseOfNewBuilding = useMemo(
    () => reason === FinancingReason.PurchaseOfNewBuilding,
    [reason]
  );
  const isModernization = useMemo(
    () => reason === FinancingReason.Modernization,
    [reason]
  );
  const isRaisingCapital = useMemo(
    () => reason === FinancingReason.RaisingCapital,
    [reason]
  );
  // const isInstallmentLoan = useMemo(
  //   () => reason === FinancingReason.InstallmentLoan,
  //   [reason]
  // );

  const fieldsInStep = useMemo(() => {
    const reasonObject =
      reasonBoxes.find((item) => item.value === reason) || reasonBoxes[0];
    const firstStep = [FINANCING_FORM_KEYS.REASON];
    let secondStep = [
      ...reasonObject.fields.secondStep,
      ...(propertyType === FinancingPropertyType.MultiFamilyHouse
        ? [FINANCING_FORM_KEYS.RENTAL_INCOME_PER_MONTH]
        : []),
    ];
    if (propertyType === FinancingPropertyType.PlotOnly) {
      secondStep = secondStep.filter(
        (item) =>
          item !== FINANCING_FORM_KEYS.LIVING_AREA &&
          item !== FINANCING_FORM_KEYS.BUILDING_YEAR
      );
    }
    if (!isNumber(livingArea) || Number.isNaN(livingArea)) {
      secondStep = secondStep.filter(
        (item) => item !== FINANCING_FORM_KEYS.LIVING_AREA
      );
    }
    if (
      !isNumber(buildingYear) ||
      Number.isNaN(buildingYear) ||
      isOwnConstructionProject
    ) {
      secondStep = secondStep.filter(
        (item) => item !== FINANCING_FORM_KEYS.BUILDING_YEAR
      );
    }

    const thirdStep = [
      ...reasonObject.fields.thirdStep,
      ...(isBrokerageFee ? [FINANCING_FORM_KEYS.BROKER_FEE_PERCENT] : []),
    ];
    const allFields = [...firstStep, ...secondStep, ...thirdStep];

    return { firstStep, secondStep, thirdStep, allFields };
  }, [reason, isBrokerageFee, propertyType, livingArea, buildingYear]);

  return {
    isPurchaseOfExistingProperty,
    isFollowUpFinancing,
    // isInstallmentLoan,
    isModernization,
    isOwnConstructionProject,
    isPurchaseOfNewBuilding,
    isRaisingCapital,
    fieldsInStep,
  };
};

export const useFirstStepLogic = () => {
  const { isIframe, iframeType } = useAppSelector((state) => state.auth);
  const isNoModal =
    iframeType === IIframeType.WIDGET_FINANCING_NO_MODAL ||
    iframeType === IIframeType.WIDGET_FINANCING_CERTIFICATE_NO_MODAL;
  const {
    fieldsInStep: { firstStep },
  } = useGetFinancingReason();

  const { onClickNext } = useNextHandler({
    watchedKeys: firstStep,
  });

  // certificate logic
  const methods = useFormContext();
  const { isFinancingCertificate } = useFinancingFormInfo();
  useEffect(() => {
    if (isFinancingCertificate) {
      methods.setValue(
        FINANCING_FORM_KEYS.REASON,
        FinancingReason.PurchaseOfExistingProperty
      );
      onClickNext();
    }
  }, []);

  const onClick = () => {
    if (isIframe && document.body.requestFullscreen && !isNoModal) {
      document.body.requestFullscreen();
    }
    onClickNext();
  };

  const inputs = [{ ...FIELDS.REASON, onClick }];

  return { inputs };
};

export const useSecondStepLogic = () => {
  const methods = useFormContext();
  const { isFinancingCertificate } = useFinancingFormInfo();
  const propertyType = methods.getValues(FINANCING_FORM_KEYS.PROPERTY_TYPE);
  const { filteredPropertyBoxes, isSearchProgressField } =
    useGetPropertyBoxes();
  const {
    isOwnConstructionProject,
    fieldsInStep: { secondStep },
  } = useGetFinancingReason();

  const watchedKeys = secondStep;

  const inputs = useMemo(
    () => [
      { ...FIELDS.PROPERTY_TYPE, boxes: filteredPropertyBoxes },
      {
        ...FIELDS.LIVING_AREA,
        isHidden: propertyType === FinancingPropertyType.PlotOnly,
      },
      {
        ...FIELDS.BUILDING_YEAR,
        isHidden:
          propertyType === FinancingPropertyType.PlotOnly ||
          isOwnConstructionProject,
      },
      {
        ...FIELDS.RENTAL_INCOME_PER_MONTH,
        isHidden: propertyType !== FinancingPropertyType.MultiFamilyHouse,
      },
      FIELDS.PROPERTY_USE,
      ...(isSearchProgressField ? [FIELDS.SEARCH_PROGRESS] : []),
      FIELDS.PLACE_NAME,
    ],
    [
      filteredPropertyBoxes,
      isSearchProgressField,
      propertyType,
      isOwnConstructionProject,
    ]
  );

  const { onClickNext } = useNextHandler({
    watchedKeys,
  });

  // logic filter property type boxes

  return {
    inputs,
    onClickNext,
    watchedKeys,
    isBackButton: !isFinancingCertificate,
  };
};

export const checkPayoutDate = (value: string): boolean => {
  const dateValue = new Date(value);
  const today = new Date();
  today.setFullYear(today.getFullYear() + 5);
  return dateValue < today;
};

interface IThirdStepProps {
  // onSubmit?: (formData: IFinancingForm, ownerId?: string) => Promise<void>;
  lastStepCallback?(): void;
}

export const useThirdStepLogic = ({ lastStepCallback }: IThirdStepProps) => {
  const methods = useFormContext();
  const { t } = useTranslation();
  const {
    isPurchaseOfExistingProperty,
    isFollowUpFinancing,
    // isInstallmentLoan,
    isModernization,
    isOwnConstructionProject,
    isPurchaseOfNewBuilding,
    isRaisingCapital,
    fieldsInStep: { thirdStep, allFields },
  } = useGetFinancingReason();
  const userInput = useAppSelector((state) => state.financing.userInput);
  const [
    isBrokerageFee,
    purchasePrice,
    landPrice,
    equityCapital,
    estimatedRemainingDebt,
    propertyValue,
    buildingCosts,
    desiredLoanAmount,
  ] = methods.getValues([
    FINANCING_FORM_KEYS.BROKERAGE_FEE,
    FINANCING_FORM_KEYS.PURCHASE_PRICE,
    FINANCING_FORM_KEYS.LAND_PRICE,
    FINANCING_FORM_KEYS.EQUITY_CAPITAL,
    FINANCING_FORM_KEYS.ESTIMATED_REMAINING_DEBT,
    FINANCING_FORM_KEYS.PROPERTY_VALUE,
    FINANCING_FORM_KEYS.BUILDING_COSTS,
    FINANCING_FORM_KEYS.DESIRED_LOAN_AMOUNT,
  ]);

  const values = methods.getValues(allFields);
  const formValues = zipObject(allFields, values);
  if (!formValues.reason && userInput && userInput.reason) {
    methods.reset(userInput);
  }

  const inputs = useMemo(() => {
    switch (true) {
      case isPurchaseOfExistingProperty || isPurchaseOfNewBuilding:
        return [
          {
            ...FIELDS.PURCHASE_PRICE,
            usefulLink: isPurchaseOfExistingProperty
              ? undefined
              : FIELDS.PURCHASE_PRICE.usefulLink,
            validateNames: equityCapital
              ? [
                  FINANCING_FORM_KEYS.PURCHASE_PRICE,
                  FINANCING_FORM_KEYS.EQUITY_CAPITAL,
                ]
              : undefined,
            rules: {
              ...FIELDS.PURCHASE_PRICE.rules,
              validate: (value: number) => {
                return Number(value) >= Number(equityCapital ?? 0)
                  ? true
                  : t(
                      'financing-portal.form.inputs.purchase-price.errors.equity-capital-value'
                    ).toString();
              },
            },
          },
          {
            ...FIELDS.EQUITY_CAPITAL,
            validateNames: purchasePrice
              ? [
                  FINANCING_FORM_KEYS.PURCHASE_PRICE,
                  FINANCING_FORM_KEYS.EQUITY_CAPITAL,
                ]
              : undefined,
            rules: {
              ...FIELDS.EQUITY_CAPITAL.rules,
              min: {
                value: purchasePrice * 0.1,
                message: t(
                  'financing-portal.form.inputs.equity-capital.errors.min-value',
                  { value: numberFormat((purchasePrice ?? 0) * 0.1) }
                ),
              },
              validate: (value: number) => {
                return Number(value) <= Number(purchasePrice ?? 0)
                  ? true
                  : t(
                      'financing-portal.form.inputs.equity-capital.errors.purchase-price-value'
                    ).toString();
              },
            },
          },
          FIELDS.BROKERAGE_FEE,
          {
            ...FIELDS.BROKER_FEE_PERCENT,
            isHidden: !isBrokerageFee && !isUndefined(isBrokerageFee),
          },
        ];
      case isFollowUpFinancing:
        return [
          {
            ...FIELDS.PROPERTY_VALUE,
            validateNames: estimatedRemainingDebt
              ? [
                  FINANCING_FORM_KEYS.PROPERTY_VALUE,
                  FINANCING_FORM_KEYS.ESTIMATED_REMAINING_DEBT,
                ]
              : undefined,
            rules: {
              ...FIELDS.PROPERTY_VALUE.rules,
              validate: (value: number) => {
                return Number(value) * 1.2 < Number(estimatedRemainingDebt ?? 0)
                  ? t(
                      'financing-portal.form.inputs.property-value.errors.estimated-remaining-debt-value'
                    ).toString()
                  : true;
              },
            },
          },
          {
            ...FIELDS.PAYOUT_DATE,
            rules: {
              ...FIELDS.PAYOUT_DATE.rules,
              validate: (value: string) => {
                return checkPayoutDate(value)
                  ? true
                  : t(
                      'financing-portal.form.inputs.payout-date.errors.max-date'
                    ).toString();
              },
            },
          },
          {
            ...FIELDS.ESTIMATED_REMAINING_DEBT,
            validateNames: propertyValue
              ? [
                  FINANCING_FORM_KEYS.PROPERTY_VALUE,
                  FINANCING_FORM_KEYS.ESTIMATED_REMAINING_DEBT,
                ]
              : undefined,
            rules: {
              ...FIELDS.ESTIMATED_REMAINING_DEBT.rules,
              validate: (value: number) => {
                return Number(value) > Number((propertyValue ?? 0) * 1.2)
                  ? t(
                      'financing-portal.form.inputs.estimated-remaining-debt.errors.property-value'
                    ).toString()
                  : true;
              },
            },
          },
        ];
      case isOwnConstructionProject:
        return [
          {
            ...FIELDS.BUILDING_COSTS,
            validateNames:
              landPrice && equityCapital
                ? [
                    FINANCING_FORM_KEYS.BUILDING_COSTS,
                    FINANCING_FORM_KEYS.LAND_PRICE,
                    FINANCING_FORM_KEYS.EQUITY_CAPITAL,
                  ]
                : undefined,
            rules: {
              ...FIELDS.BUILDING_COSTS.rules,
              validate: (value: number) => {
                return Number(value) + Number(landPrice ?? 0) <
                  +Number(equityCapital ?? 0)
                  ? t(
                      'financing-portal.form.inputs.building-costs.errors.equity-capital-value'
                    ).toString()
                  : true;
              },
            },
          },
          {
            ...FIELDS.LAND_PRICE,
            validateNames:
              buildingCosts && equityCapital
                ? [
                    FINANCING_FORM_KEYS.BUILDING_COSTS,
                    FINANCING_FORM_KEYS.LAND_PRICE,
                    FINANCING_FORM_KEYS.EQUITY_CAPITAL,
                  ]
                : undefined,
            rules: {
              ...FIELDS.LAND_PRICE.rules,
              validate: (value: number) => {
                return Number(value) + Number(buildingCosts ?? 0) <
                  Number(equityCapital ?? 0)
                  ? t(
                      'financing-portal.form.inputs.land-price.errors.equity-capital-value'
                    ).toString()
                  : true;
              },
            },
          },
          {
            ...FIELDS.EQUITY_CAPITAL,
            validateNames:
              landPrice && buildingCosts
                ? [
                    FINANCING_FORM_KEYS.BUILDING_COSTS,
                    FINANCING_FORM_KEYS.LAND_PRICE,
                    FINANCING_FORM_KEYS.EQUITY_CAPITAL,
                  ]
                : undefined,
            rules: {
              ...FIELDS.EQUITY_CAPITAL.rules,
              min: {
                value: landPrice * 0.1,
                message: t(
                  'financing-portal.form.inputs.equity-capital.errors.min-value',
                  { value: numberFormat((landPrice ?? 0) * 0.1) }
                ),
              },
              validate: (value: number) => {
                return Number(value) >
                  Number(landPrice ?? 0) + Number(buildingCosts ?? 0)
                  ? t(
                      'financing-portal.form.inputs.equity-capital.errors.building-land-price-value'
                    ).toString()
                  : true;
              },
            },
          },
          FIELDS.BROKERAGE_FEE,
          {
            ...FIELDS.BROKER_FEE_PERCENT,
            isHidden: !isBrokerageFee && !isUndefined(isBrokerageFee),
          },
        ];
      case isModernization || isRaisingCapital:
        return [
          {
            ...FIELDS.PROPERTY_VALUE,
            validateNames: desiredLoanAmount
              ? [
                  FINANCING_FORM_KEYS.PROPERTY_VALUE,
                  FINANCING_FORM_KEYS.DESIRED_LOAN_AMOUNT,
                ]
              : undefined,
            rules: {
              ...FIELDS.PROPERTY_VALUE.rules,
              validate: (value: number) => {
                return Number(value) * 1.5 < Number(desiredLoanAmount ?? 0)
                  ? t(
                      'financing-portal.form.inputs.property-value.errors.desired-loan-amount-value'
                    ).toString()
                  : true;
              },
            },
          },
          {
            ...FIELDS.DESIRED_LOAN_AMOUNT,
            validateNames: propertyValue
              ? [
                  FINANCING_FORM_KEYS.PROPERTY_VALUE,
                  FINANCING_FORM_KEYS.DESIRED_LOAN_AMOUNT,
                ]
              : undefined,
            rules: {
              ...FIELDS.DESIRED_LOAN_AMOUNT.rules,
              validate: (value: number) => {
                return Number(value) > Number((propertyValue ?? 0) * 1.5)
                  ? t(
                      'financing-portal.form.inputs.desired-loan-amount.errors.property-value'
                    ).toString()
                  : true;
              },
            },
          },
        ];
      default:
        return [];
    }
  }, [
    isPurchaseOfExistingProperty,
    isPurchaseOfNewBuilding,
    purchasePrice,
    isBrokerageFee,
    isFollowUpFinancing,
    isOwnConstructionProject,
    landPrice,
    isModernization,
    isRaisingCapital,
    equityCapital,
    estimatedRemainingDebt,
    propertyValue,
    buildingCosts,
    desiredLoanAmount,
  ]);

  const watchedKeys = thirdStep;

  const onNextClick = () => {
    if (lastStepCallback) {
      lastStepCallback();
    }
  };

  return { watchedKeys, inputs, onNextClick };
};
