import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InformationCircleBold } from 'assets/images/icons/common/tooltip-key-bold.svg';
import { useIsMobileSize } from '../../../../../../../../common/hooks/useIsMobileSize';

import {
  Container,
  SectionContainer,
  Text,
  Label,
  Info,
  TooltipContainer,
  TooltipParagraph,
} from './calculation-table-styles';
import { FinancingSkeleton as Skeleton } from '../../../../../../financing-skeleton/financing-skeleton';
import { Tooltip } from '../../../../../../../../common/components/tooltip';

interface IContent {
  isLoading?: boolean;
  name: string;
  tooltipKey?: string;
  subName?: string;
  skeletonWidth: string;
  value?: string;
  isBold?: boolean;
  isSpan?: boolean;
  subfields?: {
    name: string;
    tooltipKey?: string;
    subName?: string;
    value: string;
    skeletonWidth: string;
  }[];
}

interface IProps {
  isLoading?: boolean;
  content: IContent[];
}

const SectionItem: FC<IContent> = ({
  name,
  subName,
  isBold,
  subfields,
  value,
  tooltipKey,
  skeletonWidth,
  isLoading,
  isSpan,
}) => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  const onClickLabel = () => {
    if (tooltipKey) {
      const tooltip = document.querySelector(
        `#${tooltipKey.replaceAll('.', '-')}-icon`
      ) as HTMLParagraphElement;
      if (tooltip) {
        tooltip.click();
      }
    }
  };

  const labelContent = (
    <>
      {t(name)}
      {subName ? (
        <Skeleton isLoading={isLoading} ml={4} width="40px">
          <>{subName}</>
        </Skeleton>
      ) : (
        ''
      )}
      :
    </>
  );

  return (
    <SectionContainer isHideMarker>
      <div>
        <Text isLabel isBold={isBold} onClick={onClickLabel}>
          {isSpan && isMobileSize ? (
            <Label>{labelContent}</Label>
          ) : (
            labelContent
          )}
          {tooltipKey && (
            <Info className="info">
              <Tooltip
                icon={InformationCircleBold}
                id={tooltipKey}
                position={'bottom'}
                width={16}
                height={16}
                isOnClick
              >
                <TooltipContainer>
                  <TooltipParagraph>{t(tooltipKey)}</TooltipParagraph>
                </TooltipContainer>
              </Tooltip>
            </Info>
          )}
        </Text>
        <Text isLoading={isLoading} isBold={isBold}>
          <Skeleton isLoading={isLoading} width={skeletonWidth}>
            <>{value}</>
          </Skeleton>
        </Text>
      </div>
      {!!subfields && (
        <ul>
          {subfields.map((item) => {
            const onClickSubLabel = () => {
              if (item.tooltipKey) {
                const tooltip = document.querySelector(
                  `#${item.tooltipKey.replaceAll('.', '-')}-icon`
                ) as HTMLParagraphElement;
                if (tooltip) {
                  tooltip.click();
                }
              }
            };
            return (
              <li key={item.name}>
                <div>
                  <Text onClick={onClickSubLabel}>
                    <Skeleton
                      isLoading={isLoading}
                      width="194px"
                      mt={4.5}
                      mb={8.5}
                    >
                      <>
                        {t(item.name)}
                        {item.subName ? ` (${item.subName})` : ''}:
                        {item.tooltipKey && (
                          <Info className="info">
                            <Tooltip
                              icon={InformationCircleBold}
                              id={item.tooltipKey}
                              position={'bottom'}
                              width={16}
                              height={16}
                              isOnClick
                            >
                              <TooltipContainer>
                                <TooltipParagraph>
                                  {t(item.tooltipKey)}
                                </TooltipParagraph>
                              </TooltipContainer>
                            </Tooltip>
                          </Info>
                        )}
                      </>
                    </Skeleton>
                  </Text>
                  <Text isLoading={isLoading}>
                    <Skeleton isLoading={isLoading} width={item.skeletonWidth}>
                      <>{item.value}</>
                    </Skeleton>
                  </Text>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </SectionContainer>
  );
};

const CalculationTableBase: FC<IProps> = ({ content, isLoading }) => {
  return (
    <Container>
      {content.map((item) => (
        <SectionItem key={item.name} isLoading={isLoading} {...item} />
      ))}
    </Container>
  );
};

const CalculationTable = memo(CalculationTableBase);

export { CalculationTable };
