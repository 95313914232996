import { PropertyMeta, Reschedule } from './redux/timifySlice';

export enum TimifyPersonalisationIds {
  Consultation = 'owner-service-1',
  PersonalValuation = 'owner-service-2',
}

export interface ITimifyParams {
  serviceExternalIds: TimifyPersonalisationIds;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  country?: string;
  dossierUrl?: string;
  hideServices?: string[];
  showServices?: string[];
  showFooterBackButton?: string;
  meta?: PropertyMeta;
  reschedule?: Reschedule;
}
