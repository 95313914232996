import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import overviewIcon1 from 'assets/images/icons/overview-landing-page/overview-icon-1.png';
import overviewIcon2 from 'assets/images/icons/overview-landing-page/overview-icon-2.png';
import overviewIcon3 from 'assets/images/icons/overview-landing-page/overview-icon-3.png';

import { PrimaryCTAButton } from '../../../../common/components/ui/buttons';
import { siteMap } from '../../../../../routes/site-map';
import { CTAContainer, Link } from './cta-buttons-styles';

const CTAButtons: FC = () => {
  const { t } = useTranslation();

  return (
    <CTAContainer>
      <Link absolute isOpenNewPage to={siteMap.OwnerLandingPage.path}>
        <PrimaryCTAButton
          id="link-to-overview-landing-page"
          isFirst
          image={overviewIcon1}
          title={t('overview-landing-page.owner-button.label').toUpperCase()}
          description={t('overview-landing-page.owner-button.description')}
          onClick={() => {}}
        />
      </Link>
      <Link absolute isOpenNewPage to={siteMap.BuyerLandingPage.path}>
        <PrimaryCTAButton
          id="link-to-buyer-landing-page"
          image={overviewIcon2}
          title={t('overview-landing-page.buyer-button.label').toUpperCase()}
          description={t('overview-landing-page.buyer-button.description')}
          onClick={() => {}}
        />
      </Link>
      <Link
        absolute
        isOpenNewPage
        to={siteMap.FinancingLandingPage.path}
      >
        <PrimaryCTAButton
          id="link-to-financing-service-page"
          isLast
          skipBorder
          image={overviewIcon3}
          title={t(
            'overview-landing-page.financing-service-button.label'
          ).toUpperCase()}
          description={t(
            'overview-landing-page.financing-service-button.description'
          )}
          onClick={() => {}}
        />
      </Link>
    </CTAContainer>
  );
};

export { CTAButtons };
