import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from '../../../../style/theme';
import {
  Headline2,
  Headline3,
  ParagraphText,
  ParagraphTitle,
  TitleSlogan,
} from '../../../common/components/ui/typography';
import { abbrNum } from '../../../common/utils/number-scientific-notation';

const Container = styled.div`
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #ffffff;
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media ${device.tablet} {
    padding: 16px;
    margin: unset;
    max-width: unset;
  }
`;

const ContentWrapper = styled.div`
  padding: 32px 32px 0;
`;

const Title = styled(Headline2)`
  margin-bottom: 8px;
`;

const Content = styled(ParagraphText)``;

const BlurredWrapper = styled.div`
  display: flex;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 32px;
  bottom: 84px;
  padding: 11px;
  border-radius: 3px;
  min-width: 200px;
  border: solid 1px #c9d5fc;
  background-color: ${(props) => props.theme.greyBackground};
  @media ${device.tablet} {
    padding: 20px;
    right: unset;
    max-width: unset;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

const Price = styled(Headline3)`
  margin-right: 22px;
`;

const PriceAdditionalDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const PricePercentage = styled(TitleSlogan)<{ isPositive: boolean }>`
  line-height: 1.33;
  color: ${({ theme, isPositive }) => (isPositive ? theme.green : theme.red)};
`;

const Date = styled(TitleSlogan)`
  opacity: 0.5;
  font-size: 10px;
  line-height: 1.6;
`;

const AdditionalInfo = styled(ParagraphTitle)`
  font-size: 12px;
  line-height: 1.67;
  margin: 0;
`;

interface IProps {
  title?: string;
  content?: string;
  blurredImageSrc?: string;
  priceRangeLow?: number;
  priceRangeHigh?: number;
  priceLabel?: string;
  priceChangePercentage?: string;
  date?: string;
}

const BigBlurredCard = ({
  title,
  content,
  priceLabel,
  priceRangeLow,
  blurredImageSrc,
  priceRangeHigh,
  priceChangePercentage,
  date,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container>
      {(title || content) && (
        <ContentWrapper>
          {title && <Title content={t(title)} />}
          {content && <Content content={t(content)} />}
        </ContentWrapper>
      )}
      <BlurredWrapper>
        <Image src={blurredImageSrc} />
        {priceRangeLow && priceRangeHigh && priceChangePercentage && date && (
          <PriceContainer>
            <Row>
              <Price
                content={`${abbrNum(priceRangeLow, 2)}-${abbrNum(
                  priceRangeHigh,
                  2
                )}€`}
              />
              <PriceAdditionalDetails>
                <PricePercentage
                  isPositive={
                    Math.sign(Number.parseFloat(priceChangePercentage)) === 1
                  }
                  content={priceChangePercentage}
                />
                <Date content={date} />
              </PriceAdditionalDetails>
            </Row>
            <Row>
              <AdditionalInfo content={t(priceLabel ?? '')} />
            </Row>
          </PriceContainer>
        )}
      </BlurredWrapper>
    </Container>
  );
};

export { BigBlurredCard };
