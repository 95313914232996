import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px;
`;

export const Header = styled.h3`
  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Source Serif Pro, sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 32px;
  margin-bottom: 24px;
`;
