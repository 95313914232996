import styled from 'styled-components';

export const Container = styled.div`
  max-height: calc(100vh - 60px - 264px);
  overflow-y: auto;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
`;
