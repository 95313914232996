import { MouseEvent, ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { device } from '../../../../../style/theme';
import { hexToRGB } from '../../../utils/hex-to-rgb';
import { ButtonLoader } from './button-loader';

interface IProps {
  onClick?: (e?: MouseEvent) => void;
  label: string | ReactElement;
  isDisabled?: boolean;
  isIframe?: boolean;
  loader?: boolean;
  color?: string;
  isLoading?: boolean;
  isError?: boolean;
}

const FlatButtonBase = styled.button<{
  isDisabled?: boolean;
  isiframe?: boolean;
  isError?: boolean;
}>`
  padding: 14px 24px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.ctaBlue};

  border: none;
  outline: none;

  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 225ms ease !important;
  &:hover {
    background-color: ${(props) => props.theme.ctaBlueHover};
  }
  ${({ isError }) =>
    isError
      ? css`
          background-color: #b72a2a;

          &:hover {
            background-color: #772828;
          }
        `
      : ''}
  ${(props) =>
    props.isDisabled &&
    `
    background-color: ${props.theme.ctaDisabledBackground};
    pointer-events: none;
    color: ${hexToRGB('#000000', 0.3)};
  `}
`;

const FlatButton = ({
  onClick,
  label,
  isIframe,
  loader,
  ...rest
}: IProps): JSX.Element => (
  <FlatButtonBase
    disabled={rest.isDisabled}
    onClick={onClick}
    isiframe={!!isIframe}
    {...rest}
  >
    {loader && (
      <ButtonLoader
        isLoading={rest.isLoading ?? false}
        isDisabled={rest.isDisabled ?? false}
        color={rest.color}
      />
    )}
    {label}
  </FlatButtonBase>
);

export { FlatButton };
