import { CSSProperties, ReactNode } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as S from './input-outside-top-label-styled';
import { InputMode } from '../../../interfaces';

export interface IInputOutsideTopLabel {
  className?: string;
  id?: string;
  defaultValue?: string;
  name: string;
  errorKey?: string;
  type: 'text' | 'password' | 'email' | 'number' | 'date';
  label: string;
  noTranslate?: boolean;
  placeholder?: string;
  hasSelect?: boolean;
  selectWidth?: string;
  rules: RegisterOptions;
  infoNode?: ReactNode;
  selectNode?: ReactNode;
  isDisabled?: boolean;
  isReadonly?: boolean;
  isRedesign?: boolean;
  autoComplete?: 'on' | 'off' | 'nope' | 'false' | 'new-password';
  hasDisclaimer?: boolean;
  inputMode?: InputMode;
  labelClassName?: string;
  inputClassName?: string;
  handleChange?: (value: string) => void;
  noLabel?: boolean;
}

const InputOutsideTopLabel = ({
  id,
  className,
  name,
  errorKey,
  label,
  noTranslate,
  type,
  defaultValue,
  placeholder,
  hasSelect = false,
  selectWidth,
  rules,
  infoNode,
  selectNode,
  isDisabled,
  isReadonly,
  isRedesign,
  autoComplete,
  hasDisclaimer,
  inputMode = 'text',
  labelClassName,
  inputClassName,
  handleChange,
  noLabel = false,
}: IInputOutsideTopLabel): JSX.Element => {
  const { t } = useTranslation();
  const {
    field: { ref, value, onChange, ...inputProps },
    formState: { errors },
  } = useController({
    name,
    rules,
    defaultValue,
  });
  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value) {
      event.currentTarget.value = event?.currentTarget.value.trim();
    }

    if (handleChange) {
      handleChange(event?.currentTarget.value);
    }

    onChange(event);
  };

  return (
    <S.OuterContainer>
      {!noLabel && (
        <S.Label
          className={labelClassName}
          htmlFor={id}
          $hasError={Boolean(errorKey ? errors[errorKey] : errors[name])}
          $isRedesign={isRedesign}
        >
          {noTranslate ? label : t(label)}
          {hasDisclaimer && '*'}
        </S.Label>
      )}

      <S.Container className={className}>
        {selectNode}
        <S.InputField
          id={id}
          ref={ref}
          value={value}
          $isRedesign={isRedesign}
          type={type}
          placeholder={t(placeholder ?? '')}
          $hasSelect={hasSelect}
          $selectWidth={selectWidth}
          $hasInfoNode={Boolean(infoNode)}
          $hasError={Boolean(errorKey ? errors[errorKey] : errors[name])}
          disabled={isDisabled}
          readOnly={isReadonly}
          inputMode={inputMode}
          autoComplete={autoComplete}
          className={inputClassName}
          onChange={onInputChange}
          {...inputProps}
        />
        {infoNode}
      </S.Container>
      {Boolean(errorKey ? errors[errorKey] : errors[name]) && (
        <S.Error className="error-message">
          {t(errorKey ? errors[errorKey].message : errors[name].message)}
        </S.Error>
      )}
    </S.OuterContainer>
  );
};

export { InputOutsideTopLabel };
