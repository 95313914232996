import {
  FinancingVariantInput,
  PersonalFinancingInput,
  useSendVonPollFinanceMutation,
} from '../../../generated';

interface IProps {
  financingVariant?: FinancingVariantInput;
  personalFinancing?: PersonalFinancingInput;
  feasibilityRating: number;
  marketingLink: string;
  userButtonLink: string;
  propertyId?: string;
}

const useSendVonPollFinance = ({
  financingVariant,
  personalFinancing,
  feasibilityRating,
  marketingLink,
  userButtonLink,
  propertyId,
}: IProps) => {
  const [sendVonPollFinanceMutation] = useSendVonPollFinanceMutation();

  const sendVonPollFinance = async (isCertificate: boolean) => {
    if (personalFinancing && financingVariant) {
      await sendVonPollFinanceMutation({
        input: {
          financingVariant,
          personalFinancing,
          feasibilityRating,
          isCertificate,
          marketingLink,
          userButtonLink: `${userButtonLink}is-certificate=${isCertificate}`,
          propertyId,
        },
      });
    }
  };
  return { sendVonPollFinance };
};

export { useSendVonPollFinance };
