import 'mapbox-gl/dist/mapbox-gl.css';

import mapPin from 'assets/svg/map-pin.svg';
import { FC, memo, useEffect, useRef, useState } from 'react';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';

import { useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { GeoLocateController } from '../../../../../map/components/geolocate-controller';
import { Pins } from '../../../../../map/components/pins';
import { Container, ControlsContainer, MarkerImg } from './mapbox-styles';

const MapboxExposeDetailsBase: FC = () => {
  const viewport = useAppSelector((state) => state.exposeDetails.map.viewport);
  const settings = useAppSelector((state) => state.exposeDetails.map.settings);
  const markers = useAppSelector((state) => state.exposeDetails.map.markers);

  const isMobileSize = useIsMobileSize();

  const { longitude, latitude } = viewport;

  const [innerViewport, setInnerViewport] =
    useState<Record<string, unknown>>(viewport);

  const onViewportChange = (newViewport: Record<string, unknown>) => {
    setInnerViewport(newViewport);
  };

  useEffect(() => {
    setInnerViewport(viewport);
  }, [viewport]);

  const mapboxContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapboxContainerRef.current) {
      return () => {};
    }

    const mapContainer = mapboxContainerRef.current;

    const wheelHandler = (e: Event) => {
      const wheelEvent = e as WheelEvent;

      if (wheelEvent.ctrlKey || wheelEvent.metaKey || wheelEvent.altKey) {
        return;
      }

      e.stopPropagation();
    };

    mapContainer.addEventListener('wheel', wheelHandler, true);

    return () => {
      mapContainer.removeEventListener('wheel', wheelHandler);
    };
  });

  return (
    <Container ref={mapboxContainerRef}>
      <ReactMapGL
        width="100%"
        height={isMobileSize ? '160px' : '640px'}
        {...innerViewport}
        {...settings}
        getCursor={() => 'default'}
        onViewportChange={onViewportChange}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY ?? ''}
        mapStyle="mapbox://styles/mapbox/light-v9?optimize=true"
        asyncRender
        style={{ color: 'transparent' }}
      >
        <Pins markers={markers} />
        <ControlsContainer>
          <GeoLocateController />
          <NavigationControl />
        </ControlsContainer>
        <Marker
          longitude={(longitude as number) || 0}
          latitude={(latitude as number) || 0}
        >
          <MarkerImg src={mapPin} />
        </Marker>
      </ReactMapGL>
    </Container>
  );
};

const MapboxExposeDetails = memo(MapboxExposeDetailsBase);

export { MapboxExposeDetails };
