import { FC, ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { Container, Value, Text } from './icon-section-styles';
import Icon from '../../../../common/components/ui/icon';

interface IProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  text: string;
  value: string | ReactNode;
}

const IconSection: FC<IProps> = ({ icon, text, value }) => {
  const themeContext = useTheme();

  return (
    <Container>
      <Icon color={themeContext.blue} icon={icon} />
      <div>
        <Value>{value}</Value>
        <Text>{text}</Text>
      </div>
    </Container>
  );
};

export { IconSection };
