import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import catering from '../../../assets/svg/catering.svg';
import education from '../../../assets/svg/education.svg';
import health from '../../../assets/svg/health.svg';
import leisure from '../../../assets/svg/leisure.svg';
import shopping from '../../../assets/svg/shopping.svg';
import { BoxText, BoxTitle } from '../../common/components/ui/typography';

const Container = styled.div`
  background: #fff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  padding: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

const Image = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 8px;
`;

const Title = styled(BoxText)``;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Header = styled(BoxTitle)`
  text-align: center;
`;

const LegendBase = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Header content={t('legend.header')} />
      <Row>
        <Image src={shopping} />
        <Title content={t('infrastructure.shopping')} />
      </Row>
      <Row>
        <Image src={education} />
        <Title content={t('infrastructure.education')} />
      </Row>
      <Row>
        <Image src={leisure} />
        <Title content={t('infrastructure.leisure')} />
      </Row>
      <Row>
        <Image src={catering} />
        <Title content={t('infrastructure.catering')} />
      </Row>
      <Row>
        <Image src={health} />
        <Title content={t('infrastructure.health')} />
      </Row>
    </Container>
  );
};

const Legend = memo(LegendBase);

export { Legend };
