import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { Feature, IAddress, IForwardGeocoding } from './interfaces';

const token = process.env.REACT_APP_MAPBOX_KEY;

export const mapboxApi = createApi({
  reducerPath: 'mapbox-api',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.mapbox.com' }),
  endpoints: (builder) => ({
    forwardGeocoding: builder.query<Feature[], IAddress>({
      query: ({
        number,
        street,
        city,
        state,
        postCode,
        placeName,
        locality,
        neighborhood,
        autocomplete,
        proximity,
        types,
        language = 'de',
        country = 'de',
        fuzzyMatch = 'true',
      }) => {
        const address = `${street?.length > 0 ? `${street} ` : ''}${
          number?.length > 0 ? `${number} ` : ''
        }${postCode?.length > 0 ? `${postCode} ` : ''}${
          (placeName as string)?.length > 0 ? `${placeName} ` : ''
        }${(locality as string)?.length > 0 ? `${locality} ` : ''}${
          (neighborhood as string)?.length > 0 ? `${neighborhood} ` : ''
        }${(city as string)?.length > 0 ? `${city} ` : ''}${
          (state as string)?.length > 0 ? `${state}` : ''
        }`.trim();

        return `/geocoding/v5/mapbox.places/${address}.json?country=${country}&${
          autocomplete ? 'autocomplete=true' : ''
        }&proximity=${proximity}&${
          types ? `types=${types}` : ''
        }&language=${language}&access_token=${token}&limit=10&fuzzyMatch=${fuzzyMatch}`;
      },
      transformResponse: (response: IForwardGeocoding) => response.features,
    }),
  }),
});

export const { useForwardGeocodingQuery, useLazyForwardGeocodingQuery } =
  mapboxApi;
