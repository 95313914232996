import { SubmitHandler } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';

import { ILoginForm } from '../../../../../auth/interfaces';
import {
  login,
  setAnonymousUser,
  setUserInput,
  setUserState,
  toggleIsRegisterModalOpen,
  toggleLoading,
  toggleModalOpacity,
} from '../../../../../auth/redux/authSlice';
import { LoginResponse, useSignInMutation } from '../../../../../../generated';
import { client } from '../../../../../../services/graphql/graphql';
import { IValuationForm } from '../../../interface';
import {
  toggleAuthFlow,
  toggleForgotPasswordForm,
  toggleLoginForm,
  toggleRegisterForm,
} from '../../../redux/valuationWizardV2Slice';
import { useAppSelector } from '../../../../../common/hooks';
import { MODAL_ANIMATION_DURATION } from '../../../../../common/components/modal/modal';

type TReturn = {
  onLoginSubmit: (formData: ILoginForm) => void;
  onOpenForgotPassword: () => void;
};

type TProps = {
  setErrors: (errors: unknown) => void;
  onSuccess: (formData: IValuationForm, ownerId: string) => Promise<void>;
};

const useOnlineLogin = ({ setErrors, onSuccess }: TProps): TReturn => {
  const dispatch = useDispatch();
  const [signIn, { data: signInData }] = useSignInMutation();
  const regionalBrokerId = useAppSelector(
    (state) => state.valuationWizardV2.regionalBrokerId
  );
  const userInput = useAppSelector(
    (state) => state.valuationWizardV2.userInput
  );
  const [inputPassword, setInputPassword] = useState('');

  const onOpenForgotPassword = useCallback(() => {
    dispatch(toggleForgotPasswordForm(true));
    dispatch(toggleLoginForm(false));
  }, [dispatch]);

  const onLoginSubmit: SubmitHandler<ILoginForm> = useCallback(
    async (formData) => {
      const { userAuthenticationKey, password } = formData;
      setInputPassword(password);

      if (userAuthenticationKey && password) {
        try {
          dispatch(toggleLoading(true));
          dispatch(toggleModalOpacity(true));

          await signIn({
            input: {
              userAuthenticationKey,
              password,
              requestAccessToken: true,
              regionalBrokerId,
            },
          }).unwrap();
        } catch (error: unknown) {
          setErrors(error);
        } finally {
          dispatch(toggleLoading(false));
          setTimeout(() => {
            dispatch(toggleModalOpacity(false));
          }, MODAL_ANIMATION_DURATION);
        }
      }
    },
    [dispatch, regionalBrokerId, setErrors, signIn]
  );

  useEffect(() => {
    if (signInData) {
      const loginResponse = signInData.signIn as LoginResponse;
      if (loginResponse?.jwt?.token) {
        client.setHeader(
          'Authorization',
          `Bearer ${loginResponse?.jwt?.token ?? ''}`
        );

        batch(() => {
          dispatch(setAnonymousUser(false));
          dispatch(login(loginResponse));
          dispatch(setUserState(loginResponse.user.userState));
          dispatch(toggleAuthFlow(false));
          dispatch(toggleLoginForm(false));
        });
        onSuccess(userInput as IValuationForm, loginResponse.user._id);
      } else if (loginResponse.user && !loginResponse.jwt) {
        batch(() => {
          const {
            user: {
              userState,
              name,
              surname,
              phone,
              email,
              termsAndConditions,
            },
          } = loginResponse;
          dispatch(toggleRegisterForm(true));
          dispatch(toggleLoginForm(false));
          dispatch(toggleIsRegisterModalOpen(true));
          dispatch(setUserState(userState));
          dispatch(
            setUserInput({
              name,
              surname,
              phonePrefix: '',
              phone,
              email,
              password: inputPassword,
              confirmPassword: inputPassword,
              termsAndConditions,
            })
          );
        });
      }
    }
  }, [dispatch, inputPassword, onSuccess, signInData, userInput]);

  return { onLoginSubmit, onOpenForgotPassword };
};

export { useOnlineLogin };
