import { ReactComponent as Arrow } from 'assets/navigation/arrow.svg';
import { ReactComponent as Checkmark } from 'assets/navigation/checkmark.svg';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { useConfig } from '../../../../../config/useConfig';
import { siteMap } from '../../../../../routes/site-map';
import Icon from '../../../../common/components/ui/icon';
import { useAppSelector } from '../../../../common/hooks';
import useGetLocalization from '../../../../localization/get-localization';
import LngLink from '../../../../localization/lng-link';

const Container = styled.div`
  flex: 1 1 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    linear-gradient(90.57deg, #002447 4.48%, #00366b 48.29%);
  box-shadow: 0px 12px 27px rgba(0, 0, 0, 0.32);
  padding: 28px 32px;
`;

const ItemContainer = styled.div`
  & > div ~ div {
    margin-top: 18px;
  }
`;

const Title = styled.h2`
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.5px;
  color: #fff;
  margin-bottom: 18px;
`;

const Item = styled.div`
  display: block;
`;

const CTAButton = styled.button`
  margin-top: 24px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  width: 100%;
  color: white;
  background: #4e73f4;
  border-radius: 5px;
`;

const CTAButtonLink = styled(LngLink)`
  text-decoration: none;
  margin-top: 24px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  width: 100%;
  color: white;
  background: #4e73f4;
  border-radius: 5px;
`;

const ItemTitle = styled.h2`
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.02em;
  color: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

const ItemDescription = styled.p`
  font-weight: 400;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: #fff;
  opacity: 0.75;
`;

interface InfoBoxProps {
  onCTAClick: () => void;
  isFormVisible: boolean;
  onClose: () => void;
}

const InfoBox = ({ onCTAClick, isFormVisible, onClose }: InfoBoxProps) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);

  const isCHSite = useGetLocalization() === 'de-ch';
  const isATSite = useGetLocalization() === 'de-at';

  // onOffice marketplace iframe URL params
  const urlParams = new URLSearchParams(window.location.search);
  const brokerId = urlParams.get('userid') || '';
  const propertyOnOfficeId = urlParams.get('EstateId') || '';

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  return (
    <Container>
      <Title>{t('header.authentication.info.title')}</Title>
      <ItemContainer>
        {!isBrokerView && (
          <Item>
            <ItemTitle>
              <Icon icon={Checkmark} width={24} height={24} color={'#fff'} />
              {t('header.authentication.info.evaluate.title')}
            </ItemTitle>
            <ItemDescription>
              {t('header.authentication.info.evaluate.description')}
            </ItemDescription>
          </Item>
        )}
        {/* {!isBrokerView && !isCHSite && !isATSite && ( */}
        {!isBrokerView && (
          <Item>
            <ItemTitle>
              <Icon icon={Checkmark} width={24} height={24} color={'#fff'} />
              {t('header.authentication.info.sell.title')}
            </ItemTitle>
            <ItemDescription>
              {t('header.authentication.info.sell.description')}
            </ItemDescription>
          </Item>
        )}
        {!isBrokerView && (
          <Item>
            <ItemTitle>
              <Icon icon={Checkmark} width={24} height={24} color={'#fff'} />
              {t('header.authentication.info.buy.title')}
            </ItemTitle>
            <ItemDescription>
              {t('header.authentication.info.buy.description')}
            </ItemDescription>
          </Item>
        )}
        {/* {!isBrokerView && !isCHSite && !isATSite && ( */}
        {!isBrokerView && (
          <Item>
            <ItemTitle>
              <Icon icon={Checkmark} width={24} height={24} color={'#fff'} />
              {t('header.authentication.info.finance.title')}
            </ItemTitle>
            <ItemDescription>
              {t('header.authentication.info.finance.description')}
            </ItemDescription>
          </Item>
        )}
      </ItemContainer>
      {!user && !isFormVisible && (
        <CTAButton onClick={onCTAClick}>
          {t('header.authentication.info.cta-login')}
        </CTAButton>
      )}
      {!user && isFormVisible && (
        <CTAButtonLink absolute onClick={onClose} to={'/'}>
          {t('header.authentication.info.cta-more')}
        </CTAButtonLink>
      )}
    </Container>
  );
};

export default InfoBox;
