const formatDate = (date: Date): string => {
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;
  const year = date.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [year, month, day].join('-');
};

export const buildTimeSeries = (
  pastQuarters = 12,
  futureQuarters = 7
): string[] => {
  let today = new Date();

  const dates = [formatDate(today)];

  for (let i = 0; i < pastQuarters; i += 1) {
    const currentQuarter = Math.floor(today.getMonth() / 3);
    today = new Date(today.getFullYear(), currentQuarter * 3 - 3, 1);
    dates.unshift(formatDate(today));
  }

  today = new Date(dates[dates.length - 1]);

  for (let i = 0; i < futureQuarters; i += 1) {
    const currentQuarter = Math.floor(today.getMonth() / 3);
    today = new Date(today.getFullYear(), currentQuarter * 3 + 3, 1);
    dates.push(formatDate(today));
  }

  return dates;
};
