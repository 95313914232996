import { FC, memo, useContext } from 'react';
import { ReactComponent as FileRemove } from 'assets/streamline-light/files-folders/common-files/common-file-text-remove.svg';
import { ThemeContext } from 'styled-components';
import { Container, Text, Title } from './empty-state-placeholder-styles';
import Icon from '../../../../../common/components/ui/icon';

interface IProps {
  title?: string;
  text: string;
}

const EmptyStatePlaceholderBase: FC<IProps> = ({ title, text }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <Container>
      <Icon
        icon={FileRemove}
        width={32}
        height={32}
        color={themeContext.blue}
      />
      {title && <Title content={title} />} <Text content={text} />
    </Container>
  );
};

const EmptyStatePlaceholder = memo(EmptyStatePlaceholderBase);

export { EmptyStatePlaceholder };
