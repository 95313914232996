import { isEmpty } from 'lodash';
import { MarketingGeneralStatistics } from '../../../generated';
import { formatNumber } from './formatNumber';

type TReturn = {
  name: string;
  label: string;
  value: string;
};

interface Params {
  statistics?: MarketingGeneralStatistics | null;
  showRequestedExposes?: boolean;
  showUnlockedExposes?: boolean;
  showViewingAppointments?: boolean;
}

export const buildGeneralStatistics = ({
  statistics,
  showRequestedExposes,
  showUnlockedExposes,
  showViewingAppointments,
}: Params): TReturn[] => {
  if (isEmpty(statistics)) {
    return [];
  }

  const generalStatistics = [
    {
      name: 'advertisementViews',
      label: 'selling-simulation.general-statistics-card.views-number',
      value: String(formatNumber(statistics?.advertisementViews ?? 0)),
    },
  ];

  if (showRequestedExposes) {
    generalStatistics.push({
      name: 'requestedExposes',
      label:
        'selling-simulation.general-statistics-card.requested-exposes-number',
      value: String(formatNumber(statistics?.requestedExposes ?? 0)),
    });
  }

  if (showUnlockedExposes) {
    generalStatistics.push({
      name: 'unlockedExposes',
      label:
        'selling-simulation.general-statistics-card.unlocked-exposes-number',
      value: String(formatNumber(statistics?.unlockedExposes ?? 0)),
    });
  }

  if (showViewingAppointments) {
    generalStatistics.push({
      name: 'viewingAppointments',
      label: 'selling-simulation.general-statistics-card.appointments-number',
      value: String(formatNumber(statistics?.viewingAppointments ?? 0)),
    });
  }

  if (statistics?.impressions) {
    generalStatistics.unshift({
      name: 'impressions',
      label: 'selling-simulation.general-statistics-card.impressions',
      value: String(formatNumber(statistics?.impressions ?? 0)),
    });
  }

  if (statistics?.bookmarks) {
    generalStatistics.push({
      name: 'bookmarks',
      label: 'selling-simulation.general-statistics-card.bookmarks',
      value: String(formatNumber(statistics?.bookmarks ?? 0)),
    });
  }

  return generalStatistics;
};
