import styled from 'styled-components';
import { device } from '../../../../../../style/theme';
import {
  Headline2,
  ParagraphText,
  ParagraphTitle,
} from '../../../../../common/components/ui/typography';
import { hexToRGB } from '../../../../../common/utils/hex-to-rgb';
import { MainButton } from 'modules/common/components/ui/buttons';

export const Wrapper = styled.div`
  .outer-accordion {
    border-color: ${(props) => props.theme.lightBlue};

    .outer-header {
      background: ${(props) => props.theme.faqActiveHeader};
      transition: all 0.3s;
      /* border-radius: 0; */
      padding: 24px 32px;

      &:hover {
        background: rgb(229, 233, 236, 1);
      }

      #close-button {
        order: 2;
        margin-left: auto;
      }
    }

    .rc-collapse-content {
      background: ${(props) => props.theme.greyBackground};
      color: ${(props) => props.theme.blue};
      padding: 32px 32px 32px;

      .rc-collapse-content-box {
        margin: 0;
      }
    }

    @media ${device.tablet} {
      margin: 16px -16px -16px -16px;

      .outer-header {
        padding-left: 16px;
      }

      .rc-collapse-content {
        padding: 8px 16px 16px;
      }
    }

    @media ${device.mobile} {
      border: none;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  @media ${device.mobile} {
    padding-left: 16px;
  }
`;
export const HeaderTitle = styled(Headline2)`
  font-size: 24px;

  @media ${device.mobile} {
    font-size: 20px;
  }
`;

export const GraphCard = styled.div`
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 3px;
  padding: 32px;

  @media ${device.mobile} {
    margin: -8px 0 -16px;
    border-width: 0 0 1px 0;
    padding: 16px 16px 0;
  }
`;

export const GraphCardTitle = styled(Headline2)`
  font-size: 20px;

  @media ${device.mobile} {
    font-size: 18px;
    text-align: center;
  }
`;

export const GraphCardText = styled(ParagraphText)`
  margin: 8px 0 24px 0;
`;

export const ChartContent = styled.div`
  position: relative;

  &.low-results {
    display: flex;
    align-items: center;
  }

  @media ${device.mobile} {
    &:not(.low-results) {
      display: none;
    }

    &.low-results {
      min-height: 260px;
      padding-bottom: 24px;
    }
  }

  @media ${device.mobileIframe} {
    min-height: 0;

    .line-chart-container {
      display: none;
    }
  }
`;

export const LineChartContainer = styled.div`
  display: block;
  width: 100%;
`;

export const TableContent = styled.div`
  display: none;

  @media ${device.mobile} {
    display: block;
    margin: 0 -16px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr:nth-child(even) {
    td {
      background: ${(props) => hexToRGB(props.theme.ctaBlue, 0.05)};
    }
  }

  .label {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0.5;
    font-weight: bold;
    color: ${(props) => hexToRGB(props.theme.blue, 0.65)};
    padding: 10px 8px 10px 32px;
    width: 100%;
  }
  .value {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    letter-spacing: 0.5;
    font-weight: bold;
    color: ${(props) => props.theme.blue};
    padding: 10px 4px;
    text-align: right;
  }
  .trend {
    padding: 10px 24px 10px 0;
  }
`;

export const LowResultsBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  top: 50%;
  left: 50%;
  width: 726px;
  max-width: 80%;
  transform: translate(-50%, -50%);
  border: 1px solid ${(props) => props.theme.lightBlue};
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  padding: 24px 32px;

  @media ${device.mobile} {
    padding: 16px;
  }

  @media ${device.mobileIframe} {
    max-width: 100%;
    position: relative;
    transform: translate(0, 0);
    top: auto;
    left: auto;
  }
`;

export const SubmitButton = styled(MainButton)`
  /* white-space: nowrap; */
`;

export const TooltipTitle = styled(ParagraphTitle)`
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 24px;
  max-width: 320px;
  overflow-wrap: break-word;
`;
