import styled from 'styled-components';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../style/theme';

export const Container = styled.div<{ hasError?: boolean }>`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
  position: relative;
  ${(props) =>
    props.hasError &&
    `

  `}
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;

  @media ${device.tablet} {
    width: 100%;
    margin-bottom: 8px;
  }
`;

export const TooltipContainer = styled.div`
  max-width: 316px;
  width: 100%;
  padding: 0;
  text-align: center;
`;

export const TooltipParagraph = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`;

export const Label = styled.label`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.7)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubLabel = styled(Label)`
  font-size: 10px;
`;

export const InputAdornment = styled.span`
  opacity: 0.5;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: right;
  position: absolute;
  left: 48%;
  bottom: 12px;
`;

export const Info = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  cursor: pointer;
  .icon {
    > svg,
    > svg > path {
      stroke: ${(props) => props.theme.blue};
    }
  }

  @media ${device.tablet} {
    margin-right: 0;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  order: 2;
  flex: 2.125;

  &:hover {
    & + label {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }

  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
  }
`;

export const InputWrapper = styled.div`
  position: relative;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const InputField = styled.input<{
  hasError?: boolean;
  isDisabled?: boolean;
  readOnly?: boolean;
  isValue?: boolean;
  inputAdornment?: string;
}>`
  max-width: 280px;
  min-width: 280px;
  height: 40px;
  padding: ${({ inputAdornment }) =>
    inputAdornment ? '12px 53% 12px 40px' : '12px 40px'};
  border-radius: 3px;
  text-align: ${({ inputAdornment }) => (inputAdornment ? 'right' : 'center')};
  touch-action: none;

  background-color: ${(props) =>
    props.hasError ? props.theme.redBackground : props.theme.white};

  outline: none;
  border: 1px solid ${({ theme }) => theme.grey};
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  ${(props) =>
    props.hasError &&
    `
      & + label{
        color: ${hexToRGB(props.theme.red, 0.5)};
      }
  `}
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:not([type='radio']):not([type='checkbox']) {
    -webkit-appearance: none;
    border-radius: 3px;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield !important;
  }

  /* clears the ‘X’ from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &::placeholder {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${(props) => hexToRGB(props.theme.blue, 0.6)};
  }
  // &:hover {
  //   cursor: pointer;
  //   border-color: ${(props) => props.theme.borderFocus};
  //   & + label {
  //     color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
  //   }
  //   &::placeholder {
  //     color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
  //   }
  // }
  &:active,
  &:focus {
    cursor: text;
    background-color: #fff;
    border-color: ${(props) => props.theme.borderFocus};
    color: ${(props) => props.theme.blue};
    & + label {
      color: ${(props) => props.theme.blue};
    }
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
    }
  }
  ${({ isDisabled }) =>
    isDisabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}

  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
  }
`;

export const Button = styled.button<{ isLeft?: boolean }>`
  position: absolute;
  border: none;
  cursor: pointer;
  background-color: transparent;
  ${({ isLeft, theme }) =>
    isLeft
      ? `left: 0; border-right: 1px solid ${theme.grey}; border-radius: 3px 0 0 3px;`
      : `right: 0; border-left: 1px solid ${theme.grey}; border-radius: 0 3px 3px 0;`}
  top: 1px;
  height: 38px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => hexToRGB(theme.borderColor, 0.3)};
    & path {
      stroke: ${({ theme }) => theme.blue};
    }
  }
`;

export const ErrorContainer = styled.div`
  order: 3;
  margin-top: 4px;
  display: flex;
  width: 100%;
`;

export const Spacer = styled.div`
  flex: 1;
  padding-right: 16px;

  @media ${device.tablet} {
    display: none;
  }
`;

export const ErrorMessage = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.red};
`;
