import { FC, useState } from 'react';

import { useOnlineLogin } from '../../../../hooks/callback-hooks/online-valuation/useOnlineLogin';
import { useSubmitOnlineEVForm } from '../../../../hooks/callback-hooks/online-valuation/useSubmitOnlineEVForm';
import { LoginContainer } from '../../../../../components/fullscreen-modal/auth-container/login-container/login-container';

const OnlineLoginContainer: FC = () => {
  const [errors, setErrors] = useState<null | unknown>(null);

  const { onCreateProperty } = useSubmitOnlineEVForm();

  const { onLoginSubmit, onOpenForgotPassword } = useOnlineLogin({
    setErrors,
    onSuccess: onCreateProperty,
  });

  return (
    <LoginContainer
      onLoginSubmit={onLoginSubmit}
      onOpenForgotPassword={onOpenForgotPassword}
      errors={errors}
    />
  );
};

export { OnlineLoginContainer };
