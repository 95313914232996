import Icon from 'modules/common/components/ui/icon';
import { ReactComponent as Users } from 'assets/streamline-light/users/natural-close-up-single-user-actions-neutral/single-neutral-actions-text.svg';
import { ReactComponent as Phone } from 'assets/streamline-light/phones-mobile-devices/phone-actions/phone-actions-clock.svg';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { ReactComponent as EmptyState } from 'assets/buyer-finder/empty-state.svg';
import { IOption } from 'modules/forms/components/common/select-v2/interface';
import { SearchProfile } from 'generated';
import { BuyerFinderCard } from '../buyer-finder-card/buyer-finder-card';
import { BuyerFinderPageSort } from '../../../../interfaces';
import {
  Wrapper,
  Header,
  List,
  EmptyStateContent,
  EmptyStateIcon,
  InfoBox,
  InfoBoxText,
  LoadMoreButtonContainer,
  SubmitButton,
  MobileBtnContainer,
  HeaderTitle,
  InfoBoxButton,
} from './buyer-finder-list-styles';

interface IProps {
  count: number;
  searchProfiles: Partial<SearchProfile>[];
  favoriteSP: string[];
  onLoadMore: () => void;
  onChangeSort: (sortType: BuyerFinderPageSort) => void;
  onChangeFavoriteSP: (searchProfileId: string) => void;
  isNotifyButton?: boolean;
  isViewRightsOnly?: boolean;
  onNotifyBroker: () => void;
}

export const BuyerFinderList: FC<IProps> = ({
  count,
  searchProfiles,
  favoriteSP,
  onLoadMore,
  onChangeSort,
  onChangeFavoriteSP,
  isNotifyButton,
  isViewRightsOnly,
  onNotifyBroker,
}) => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  const [emptyState, setEmptyState] = useState(false);

  const options: IOption[] = [
    {
      value: BuyerFinderPageSort.HighestPrice,
      label: t('buyer-finder.list.sort.options.highest'),
    },
    {
      value: BuyerFinderPageSort.LowestPrice,
      label: t('buyer-finder.list.sort.options.lowest'),
    },
  ];

  const handleChangeSort = (value: string | number | boolean) => {
    onChangeSort(value as BuyerFinderPageSort);
  };

  const isShowLoadMoreButton = searchProfiles.length < count;

  useEffect(() => {
    setEmptyState(searchProfiles.length === 0);
  }, [searchProfiles.length]);

  return (
    <Wrapper>
      <Header>
        <Icon width={32} height={32} icon={Users} color={themeContext.blue} />
        <HeaderTitle content={t('buyer-finder.list.title')} />
        {/* {!emptyState && ( */}
        {/*  <Filter> */}
        {/*    <FormProvider {...methods}> */}
        {/*      <SelectV2 */}
        {/*        isOldDesign */}
        {/*        name={'search'} */}
        {/*        label={t('buyer-finder.list.sort.label')} */}
        {/*        selectOptions={options} */}
        {/*        handleChangeProp={handleChangeSort} */}
        {/*      /> */}
        {/*    </FormProvider> */}
        {/*  </Filter> */}
        {/* )} */}
      </Header>

      <InfoBox>
        {emptyState ? (
          <InfoBoxText
            content={t('buyer-finder.list.info-box.empty-state.text')}
          />
        ) : (
          <InfoBoxText
            content={t('buyer-finder.list.info-box.text', {
              value: `<span style='font-size: 24px'>${count}</span>`,
            })}
            allowHtml
          />
        )}

        {isNotifyButton && (
          <>
            <InfoBoxButton
              onClick={onNotifyBroker}
              icon={Phone}
              label={t('buyer-finder.list.info-box.button')}
            />
            <MobileBtnContainer>
              <InfoBoxButton
                onClick={onNotifyBroker}
                icon={Phone}
                style={{ width: '100%' }}
                label={t('buyer-finder.list.info-box.button')}
              />
            </MobileBtnContainer>
          </>
        )}
      </InfoBox>

      {!emptyState && (
        <>
          <List>
            {searchProfiles.map((item, i) => {
              const address = item.searchProfileData?.location.address;
              const searchProfileData = {
                location: `${address?.postCode} ${address?.city}`,
                radius: item.searchProfileData?.radius,
                purchasePriceMax: item.searchProfileData?.purchasePriceMax,
                numberOfRoomsMin: item.searchProfileData?.numberOfRoomsMin,
                livingAreaMin: item.searchProfileData?.livingAreaMin,
                landAreaMin: item.searchProfileData?.landAreaMin,
                propertyCode: item.searchProfileData?.propertyCode,
              };
              const selected = favoriteSP.includes(String(item._id));
              return (
                <BuyerFinderCard
                  _id={String(item._id)}
                  isFavorite={selected}
                  isViewRightsOnly={isViewRightsOnly}
                  i={i}
                  searchProfileData={searchProfileData}
                  onChangeFavoriteSP={onChangeFavoriteSP}
                />
              );
            })}
          </List>
          {/* {isShowLoadMoreButton && ( */}
          {/*  <LoadMoreButtonContainer> */}
          {/*    <SecondaryButton */}
          {/*      fluid */}
          {/*      onClick={onLoadMore} */}
          {/*      label={t('button.load-more')} */}
          {/*      color={themeContext.blue} */}
          {/*      borderColor={themeContext.blue} */}
          {/*    /> */}
          {/*  </LoadMoreButtonContainer> */}
          {/* )} */}
        </>
      )}

      {emptyState && (
        <EmptyStateContent>
          <EmptyStateIcon>
            <EmptyState />
          </EmptyStateIcon>
        </EmptyStateContent>
      )}
      {isNotifyButton && (
        <LoadMoreButtonContainer>
          <SubmitButton
            onClick={onNotifyBroker}
            style={{ width: '100%' }}
            label={t('buyer-finder.list.load-more.button')}
          />
        </LoadMoreButtonContainer>
      )}
    </Wrapper>
  );
};
