import { memo } from 'react';
import styled from 'styled-components';

import { device } from '../../../../style/theme';
import { ParagraphText, TitleSlogan } from './typography';

interface IProps {
  header?: string;
  titleFirstLine: string;
  titleSecondLine?: string;
  content: string;
  cta?: (children?: JSX.Element) => JSX.Element;
  interpolatedColor?: string;
  interpolatedBackgroundColor?: string;
  showAddressBar?: boolean;
  titleType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  mobileCentered?: boolean;
}

const Container = styled.div<{ showAddressBar?: boolean }>`
  & {
    border-radius: 3px;
    padding: 32px 96px 32px 32px;
    ${({ showAddressBar }) =>
      showAddressBar && `border-top-left-radius: 0; border-top-right-radius: 0`}
    > * {
      color: ${(props) => props.color};
    }
    @media ${device.tablet} {
      padding: 16px;
      max-width: 100%;
      box-shadow: none;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    flex: 1;
    > i {
      margin-right: 8px;
    }
  }
`;

const Header = styled(TitleSlogan)<{ mobileCentered?: boolean; }>`
  opacity: 0.8;

  ${props => props.mobileCentered && `
    @media ${device.tablet} {
      flex: 1;
      text-align: center;
    }
  `}
`;

const Title = styled.h1<{ mobileCentered?: boolean }>`
  margin: 4px 0 16px;
  @media ${device.tablet} {
    margin-bottom: 12px;
  }
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
    ${props => props.mobileCentered && `
      text-align: center;
    `}
  }
`;

const Content = styled(ParagraphText)``;

const CardBase = ({
  header,
  titleFirstLine,
  titleSecondLine,
  content,
  cta,
  interpolatedColor: color,
  interpolatedBackgroundColor: backgroundColor,
  showAddressBar,
  titleType = 'h1',
  mobileCentered,
}: IProps): JSX.Element => (
  <Container style={{ color, backgroundColor }} showAddressBar={showAddressBar}>
    {header && (
      <HeaderContainer>
        {cta ? cta(<Header content={header} />) : <Header mobileCentered={mobileCentered} content={header} />}
      </HeaderContainer>
    )}
    <Title as={titleType} mobileCentered={mobileCentered}>
      {titleFirstLine}
      <br />
      {titleSecondLine}
    </Title>
    <Content content={content} />
  </Container>
);

const Card = memo(CardBase);

export { Card };
