import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { FC } from 'react';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import useGetLocalization from '../../localization/get-localization';
import { ForgotPasswordForm } from '../components/forms/forgot-password-form';
import { LoginForm } from '../components/forms/login-form';
import { Headline1 } from '../../common/components/ui/typography';
import { device } from '../../../style/theme';
import { ConfirmForgotPasswordForm } from '../components/forms/confirm-forgot-password-form';
import { isTokenExpired } from '../../common/utils/is-token-expired';
import { setSelectedTab } from '../../property/redux/dynamicMapUtilsSlice';
import { setActiveTab } from '../../search-profile/pages/expose-details-overlay/redux/exposeSlice';
import { siteMap } from '../../../routes/site-map';

export const Header = styled(Headline1)<{ color?: string }>`
  position: relative;
  color: ${(props) => (props.color ? props.color : props.theme.blue)};
  margin-bottom: 24px;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
    margin-bottom: 12px;
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const BuyerLoginPage: FC = () => {
  const { t } = useTranslation();
  const lang = useGetLocalization();
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const searchProfileId = urlParams.get('searchProfileId') || '';
  const notifications = urlParams.get('notifications') || '';
  const sortBy = urlParams.get('sortBy') || '';
  const propertyId = urlParams.get('propertyId') || '';
  const onlineExposeId = urlParams.get('onlineExposeId') || '';
  const exposeRequestId = urlParams.get('exposeRequestId') || '';
  const pdfToken = urlParams.get('pdfToken') || '';
  const openDocumentsTab = urlParams.get('openDocumentsTab') === 'true';

  const isForgottenPasswordOpen = useAppSelector(
    (state) => state.auth.isForgottenPasswordOpen
  );

  const isConfirmForgotPasswordModalOpen = useAppSelector(
    (state) => state.auth.isConfirmForgotPasswordModalOpen
  );

  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const exp = useAppSelector((state) => state.auth.exp);

  if (!isAnonymousUser && exp) {
    const isExpired = isTokenExpired(exp);
    if (!isExpired) {
      if (searchProfileId && notifications) {
        return (
          <Redirect
            to={`/${lang}${siteMap.SearchProfilePage.pathWithoutParams}/${searchProfileId}/?notifications=${notifications}`}
          />
        );
      }
      if (searchProfileId && onlineExposeId && propertyId) {
        dispatch(setSelectedTab(2));
        if (openDocumentsTab) {
          setTimeout(() => {
            dispatch(setActiveTab(4));
          }, 2000);
        }
        return (
          <Redirect
            to={`/${lang}${siteMap.SearchProfilePage.pathWithoutParams}/${searchProfileId}/?online-expose=${onlineExposeId}&property-id=${propertyId}`}
          />
        );
      }
      if (exposeRequestId && pdfToken) {
        return (
          <Redirect
            to={`/${lang}${siteMap.DownloadExpose.pathWithoutParams}/${exposeRequestId}/${pdfToken}`}
          />
        );
      }
      if (searchProfileId && sortBy) {
        return (
          <Redirect
            to={`/${lang}${siteMap.SearchProfilePage.pathWithoutParams}/${searchProfileId}/?sort-by=${sortBy}`}
          />
        );
      }
      return (
        <Redirect
          to={`/${lang}${siteMap.BuyerLandingPage.pathWithoutParams}`}
        />
      );
    }
  }

  return (
    <Container>
      <Helmet>
        <title>{t('buyer-portal.title')}</title>
      </Helmet>
      <Header content={t('buyer-login-page.header')} />
      {isConfirmForgotPasswordModalOpen ? (
        <ConfirmForgotPasswordForm />
      ) : isForgottenPasswordOpen ? (
        <ForgotPasswordForm />
      ) : (
        <LoginForm />
      )}
    </Container>
  );
};
