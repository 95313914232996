import { useAppSelector } from '../../../common/hooks';
import { IIframeType } from '../../../auth/interfaces';
import { FormError } from '../../interface';

type TReturn = {
  searchProfileErrors: FormError;
  activeStep: number;
  isLoading: boolean;
  isIframe?: boolean;
  isSPCOpen: boolean;
  isNoModal?: boolean;
};

const useUsefulSearchProfileContainerData = (): TReturn => {
  const isSPCOpen = useAppSelector((state) => state.searchProfile.isSPCOpen);
  const activeStep = useAppSelector((state) => state.searchProfile.activeStep);
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const searchProfileErrors = useAppSelector(
    (state) => state.searchProfile.errors
  );
  const isLoading = useAppSelector((state) => state.searchProfile.isLoading);
  const iframeType = useAppSelector((state) => state.auth.iframeType);
  const isNoModal = iframeType === IIframeType.WIDGET_OWNER_NO_MODAL;

  return {
    activeStep,
    isLoading,
    isIframe,
    isSPCOpen,
    isNoModal,
    searchProfileErrors,
  };
};

export { useUsefulSearchProfileContainerData };
