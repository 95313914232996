import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PropertyMeta {
  vonpollUserId: string;
  vonpollPropertyId?: string;
  vonpollSellingPropertyId?: string;
}

export interface Reschedule {
  id: string;
  secret: string;
}

interface InitialState {
  isModalOpen: boolean;
  successRegistration?: boolean;
  meta?: PropertyMeta;
  reschedule?: Reschedule;
}

const initialState: InitialState = {
  isModalOpen: false,
};

const timifySlice = createSlice({
  name: 'timify',
  initialState,
  reducers: {
    toggleTimifyModal(state, action: PayloadAction<boolean>) {
      state.isModalOpen = action.payload;
      if (!action.payload) {
        state.meta = undefined;
        state.reschedule = undefined;
        state.successRegistration = false;
      }
    },
    successfulRegistrationFlag(state, action: PayloadAction<boolean>) {
      state.successRegistration = action.payload;
    },
    setPropertyMeta(state, action: PayloadAction<PropertyMeta>) {
      state.meta = action.payload;
    },
    openTimifyReschedule(state, action: PayloadAction<Reschedule>) {
      state.isModalOpen = true;
      state.reschedule = action.payload;
    },
  },
});

export const {
  toggleTimifyModal,
  setPropertyMeta,
  openTimifyReschedule,
  successfulRegistrationFlag,
} = timifySlice.actions;

export default timifySlice.reducer;
