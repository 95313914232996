import { ServicePackage } from './constants';
import {
  Direction,
  MarketingGeneralStatistics,
  OnOfficeExposeStatus,
  PropertyExposeStatus,
} from '../../generated';

export enum ExposeBoxSelectorActions {
  RequestChanges = 'RequestChanges',
  Approve = 'Approve',
}

export interface IServiceForm {
  package: ServicePackage;
  SecretSale: boolean;
  RealEstatePortals: boolean;
  PremiumPlacement: boolean;
  OfficialInformation: boolean;
  Photos: boolean;
  DronePhotos: boolean;
  VirtualTours: boolean;
  FloorPlans: boolean;
  DigitalStaging: boolean;
  EnergyCertificate: boolean;
  Magazines: boolean;
  HighCirculation: boolean;
  SocialMedia: boolean;
}

export interface IBrokerSettingsForm {
  PremiumPlacementProperty: boolean;
  DronePhotosProperty: boolean;
  VirtualToursProperty: boolean;
  DigitalStagingProperty: boolean;

  PremiumPlacementShop: boolean;
  DronePhotosShop: boolean;
  VirtualToursShop: boolean;
  DigitalStagingShop: boolean;
}

export interface IExposeActionForm {
  actionType: string;
  comment?: string;
}

export interface PropertyExpose {
  status: OnOfficeExposeStatus;
  exposeChangesHistory: string[];
  comment?: string;
  createdAt: string;
  updatedAt?: string;
  approvedAt?: string;
  isApprovedViaOnOffice?: boolean | null;
}

export interface SellingStep {
  title: string;
  isCompleted: boolean;
}

export interface SellingProgress {
  steps: SellingStep[];
  progress: number;
}

export interface GeneralStatisticsItem {
  label: string;
  value: string;
}

export interface StatisticsItem {
  label: string;
  value: number;
}

export interface NewPurchaseOffer {
  bidOnOfficeId: string;
  customerId: number;
  price: string;
  newPrice?: string | null;
  fromCity: string;
  keywords?: string[];
  isNew?: boolean;
  customerOnOfficeId?: string;
  isAccepted?: boolean;
  isHighestBid?: boolean;
  date: string;
}

export interface OffersStatisticsItem {
  _id: string;
  weekNumber: number;
  dateFrom: string;
  dateTo: string;
  highestBidId?: number;
  offers?: NewPurchaseOffer[];
  generalStatistics: MarketingGeneralStatistics;
}

export interface IAcceptOfferForm {
  notaryAppointmentFromDate: string;
  notaryAppointmentToDate: string;
  comment: string;
  isOfferNotary: boolean;
}

export enum OrderStatisticsBy {
  Date = 'Date',
  OffersNumber = 'OffersNumber',
}

export type OrderStatisticsByCriterion = {
  direction?: Direction;
  field?: OrderStatisticsBy;
};

export interface StatisticsFilterForm {
  dateFrom?: string;
  dateTo?: string;
  // orderBy: OrderStatisticsByCriterion;
}

export interface IAppointmentForm {
  message: string;
  name: string;
  surname: string;
  phone: string;
  phonePrefix: string;
  preferredTime: string;
}

export interface UpdatedFormValue {
  name: string;
  label: string;
  oldValue: string;
  newValue: string;
}
