import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LoaderContainer } from 'modules/search-profile/pages/search-profile-page/search-profile-page-styles';
import { ValuationLoader } from 'modules/forms/components/common/loader';
import {
  DetailedPropertyPurchaseIntent,
  useReadUserPurchaseIntentsQuery,
} from '../../../../generated';
import Select from '../../../common/components/form/select/select';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import FormPurchaseIntend from '../../../forms/form-purchase-intend/components/containers/form-purchase-intend';
import { ExposeDetailsOverlay } from '../../pages/expose-details-overlay/expose-details-overlay';
import {
  Container,
  Header,
  HeaderTitle,
  SortContainer,
  SortLabel,
} from '../purchase-offers/purchase-offers-styles';
import SingleProperty from '../purchase-offers/single-property';
import EmptyStatePlaceholder from '../purchase-offers/empty-state-placeholder';

const options = [{ label: 'Das neuste zuerst', value: 'Das neuste zuerst' }];

const PurchaseOffers = () => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const { searchProfileId } = useParams<{ searchProfileId: string }>();

  const [openId, setOpenId] = useState('');
  const { data, isLoading, refetch } = useReadUserPurchaseIntentsQuery({
    searchProfileId,
  });

  const userPurchaseIntents = data?.readUserPurchaseIntents || [];

  const onPurchaseIntentClose = () => {
    setOpenId('');
    document.body.style.overflow = 'auto';
  };

  if (isLoading) {
    return (
      <LoaderContainer isLoadingMore>
        <ValuationLoader maxWidth="500px" />
      </LoaderContainer>
    );
  }

  return (
    <>
      <Container>
        <Header>
          <HeaderTitle count={userPurchaseIntents.length}>
            {t('purchase-offers.title')}
          </HeaderTitle>
          {!isMobileSize && userPurchaseIntents?.length !== 0 && (
            <SortContainer>
              <SortLabel>{t('purchase-offers.sortLabel')}</SortLabel>
              <Select selectedOption={'Das neuste zuerst'} options={options} />
            </SortContainer>
          )}
        </Header>
        {userPurchaseIntents.length === 0 ? (
          <EmptyStatePlaceholder />
        ) : (
          <>
            {userPurchaseIntents.map(
              (property: DetailedPropertyPurchaseIntent) => {
                return (
                  <SingleProperty
                    setOpenId={setOpenId}
                    refetch={refetch}
                    key={property.id}
                    property={property}
                  />
                );
              }
            )}
          </>
        )}
        {openId && (
          <FormPurchaseIntend
            refetch={refetch}
            isOpen={!!openId}
            onClose={onPurchaseIntentClose}
            propertyId={openId}
          />
        )}
      </Container>
      <ExposeDetailsOverlay />
    </>
  );
};

export default PurchaseOffers;
