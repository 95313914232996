import { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ForgotPasswordForm } from '../../../../../auth/components/forms/forgot-password-form';
import { LoginFormWizard } from '../../../../../auth/components/forms/login-form-wizard';
import { toggleIsForgottenPasswordOpen } from '../../../../../auth/redux/authSlice';
import { GlobalLoader } from '../../../../../common/components/ui/loaders/global-loader';
import { useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { FORM_STEPS } from '../../../constants';
import {
  toggleLoginForm,
  toggleRegisterForm,
} from '../../../redux/searchProfileSlice';
import { FormTitle } from '../../../../components/common/form-title';
import { VisibilityWrapper } from '../../../../components/common/visibility-wrapper';
import { MobileBack } from '../../common/mobile-back';
import { Progress } from '../../../../components/common/progress';
import { ISearchProfileForm } from '../../../interface';
import {
  BackButton,
  Container,
  FormWrapper,
  Header,
  LoaderContainer,
} from './search-profile-styles';
import { useGetPortalType } from '../../../../../landing/hooks/useGetPortalType';
import { useLoginSP } from '../../../hooks/useLoginSP';

interface IProps {
  onSuccess?: (formData: ISearchProfileForm, ownerId: string) => Promise<void>;
}

const OnlineLoginContainerBase: FC<IProps> = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const { isBuyer } = useGetPortalType();

  const [errors, setErrors] = useState<null | unknown>(null);

  const isForgottenPasswordOpen = useAppSelector(
    (state) => state.auth.isForgottenPasswordOpen
  );
  const isLoginFormLoading = useAppSelector((state) => state.auth.isLoading);
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const title = isForgottenPasswordOpen
    ? t('modal.forgotten-password.title')
    : t('modal.login.title');

  const { onLoginSubmit } = useLoginSP({ setErrors, onSuccess });

  const backToSearchProfileForm = useCallback(() => {
    dispatch(toggleLoginForm(false));
    dispatch(toggleIsForgottenPasswordOpen(false));
    dispatch(toggleRegisterForm(true));
  }, [dispatch]);

  const mobileTitle = isBuyer
    ? t('buyer-landing-page.hero-slider.financing-advice.title').toUpperCase()
    : t('landing-page.primary-button.online-valuation.label');

  return (
    <Container isiframe={isIframe}>
      {!isIframe && <MobileBack title={mobileTitle} />}
      {isLoginFormLoading && (
        <LoaderContainer>
          <GlobalLoader />
        </LoaderContainer>
      )}
      <VisibilityWrapper isVisible={!isLoginFormLoading}>
        <Header>
          <Progress activeBullet={FORM_STEPS} bullets={FORM_STEPS} />
          <FormTitle text={title} />
        </Header>

        <FormWrapper isIframe={isIframe}>
          {isForgottenPasswordOpen ? (
            <ForgotPasswordForm isWizardForm />
          ) : (
            <LoginFormWizard
              onSubmit={onLoginSubmit}
              errors={errors}
              isButtonBottom={isIframe}
              isFullWidthButton={isIframe}
            />
          )}
        </FormWrapper>
        {!isMobileSize && !isIframe && (
          <BackButton
            label={t('button.back')}
            onClick={backToSearchProfileForm}
          />
        )}
      </VisibilityWrapper>
    </Container>
  );
};

const OnlineLoginContainer = memo(OnlineLoginContainerBase);

export { OnlineLoginContainer };
