import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { ReactComponent as Close } from '../../../../assets/streamline-light/interface-essential/form-validation/close.svg';
import {
  BlockType,
  FaqSection,
  useCmsPageQuery,
  useCmsPagesQuery,
} from '../../../../generated';
import { device } from '../../../../style/theme';
import { Modal, ModalFooter } from '../../../common/components/modal';
import { BorderButton } from '../../../common/components/ui/buttons';
import { FaqAccordion } from '../../../common/components/ui/faq-accordion/faq-accordion';
import Icon from '../../../common/components/ui/icon';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  background-color: ${(props) => props.theme.blue};
  width: 100%;
  justify-content: center;
  padding: 18px 0 18px 0;
`;

const Title = styled.h3`
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
`;

const Content = styled.div`
  padding-bottom: 32px;
  @media ${device.tablet} {
    padding: 16px 16px 0 16px;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 16px;
`;

const descktopWrapperStyle: React.CSSProperties = {
  maxWidth: 1104,
  padding: '32px',
};

const mobileWrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100vh',
  padding: 0,
  overflow: 'overlay',
};

interface FaqModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FaqModal = ({ isOpen, onClose }: FaqModalProps) => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const themeContext = useContext(ThemeContext);

  const { landingPage } = useCmsPagesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      landingPage: data?.cmsPages?.find((page) => page.name === 'Landing Page'),
    }),
  });

  const { data: landingPageData, isLoading: isLandingPageLoading } =
    useCmsPageQuery({ id: landingPage?._id ?? '' });

  const faqBlock = useMemo(() => {
    return landingPageData?.cmsPage.blocks.find(
      (block) => block.type === BlockType.Faq
    );
  }, [landingPageData]);

  if (isLandingPageLoading) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      wrapperStyles={isMobileSize ? mobileWrapperStyle : descktopWrapperStyle}
    >
      {isMobileSize && (
        <Row>
          <Title>{faqBlock?.title}</Title>
          <IconContainer onClick={onClose}>
            <Icon
              icon={Close}
              width={16}
              height={16}
              color={themeContext.white}
            />
          </IconContainer>
        </Row>
      )}
      <Content>
        {faqBlock && (
          <FaqAccordion
            accordionTitle={isMobileSize ? '' : faqBlock.title}
            accordionSections={(faqBlock.sections as FaqSection[]) ?? []}
          />
        )}
      </Content>
      {!isMobileSize && (
        <ModalFooter>
          <BorderButton onClick={onClose} label={t('button.close')} />
        </ModalFooter>
      )}
    </Modal>
  );
};

export default FaqModal;
