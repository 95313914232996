// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { isNumber } from 'lodash';

interface IProps {
  isOpen: boolean;
  initHeight?: number;
  isShadow?: boolean;
  isNotCollapsible?: boolean;
  mt?: number;
  header?: JSX.Element;
  children?: JSX.Element | JSX.Element[] | string;
  isFullScreen?: boolean
}

const Container = styled.div<{ isShadow?: boolean; mt?: number }>`
  transition: 0.3s;
  ${({ mt }) =>
    isNumber(mt) &&
    css`
      margin-top: ${mt}px;
      transition: margin-top 0.2s ease-in-out;
    `}

  ${({ isShadow }) =>
    isShadow &&
    css`
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      }
    `}
`;

const ContentContainer = styled.div`
  overflow: hidden;
  transition: height 0.2s ease-in-out;
`;

const Content = styled.div``;

const Collapsible = ({
  isOpen,
  initHeight,
  children,
  mt,
  header,
  isNotCollapsible,
  isShadow,
  isFullScreen
}: IProps): JSX.Element => {
  const [height, setHeight] = useState<number | undefined>(initHeight || 0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!height || !isOpen || !ref.current) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      return undefined;
    }

    // @ts-ignore
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setHeight(ref.current?.getBoundingClientRect().height);
      return;
    }
    setHeight(0);
  }, [isOpen]);

  if (isNotCollapsible) {
    return <>{children}</>;
  }

  const fullScreenStyles = {
    height: isOpen ? 'auto' : 0,
    minHeight: isOpen ? '100vh' : 'auto'
  }

  return (
    <Container isShadow={isShadow} mt={mt}>
      {header}
      <ContentContainer style={isFullScreen ? fullScreenStyles : { height }}>
        <Content ref={ref}>{children}</Content>
      </ContentContainer>
    </Container>
  );
};

export { Collapsible };
