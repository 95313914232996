import styled from 'styled-components';

const Text = styled.p<{ margin: string }>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  margin: ${(props) => props.margin};
`;

interface IModalText {
  text: string;
  className?: string;
  margin?: string;
}

const ModalText = ({
  className,
  text,
  margin = '0 0 24px',
}: IModalText): JSX.Element => {
  return (
    <Text className={className} margin={margin}>
      {text}
    </Text>
  );
};

export { ModalText };
