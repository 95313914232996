import styled from 'styled-components';
import { px2vw } from '../../../utils/px2vw';
import { Logo } from './logo';
import { SimpleNavigation } from './simple-navigation';

const Container = styled.div`
  display: flex;
  align-items: center;
  z-index: 3;
  position: sticky;
  top: -1px;
  background: ${(props) => props.theme.logoBlue};
  height: 65px;
  margin-top: -1px;
  padding: 12px ${px2vw(120)};
  > * {
    flex: 0 0 auto;
  }
`;

const NavigationContainer = styled.div``;

const SimpleHeader = (): JSX.Element => (
  <Container>
    <Logo />
    <NavigationContainer>
      <SimpleNavigation />
    </NavigationContainer>
  </Container>
);

export { SimpleHeader };
