import {
  CreatePropertyInput,
  useCreateTemporaryPropertyMutation,
  ValuationType,
} from '../../../../generated';
import { useAppSelector } from '../../../common/hooks';

type TCreateTemporaryProperty = {
  valuationInput: CreatePropertyInput;
  userId: string;
};

type TReturn = {
  createTemporaryProperty: (props: TCreateTemporaryProperty) => Promise<void>;
};

export const useCreateTemporaryProperty = (): TReturn => {
  const [createTemporaryPropertyMutation] =
    useCreateTemporaryPropertyMutation();
  const marketingParameters = useAppSelector(
    (state) => state.auth.marketingParameters
  );

  const createTemporaryProperty = async ({
    valuationInput,
    userId,
  }: TCreateTemporaryProperty) => {
    const {
      dealType,
      countryCode,
      ratingReason,
      otherRatingReason,
      timeHorizont,
      propertyData,
    } = valuationInput;

    const createTemporaryPropertyResponse =
      await createTemporaryPropertyMutation({
        input: {
          dealType,
          countryCode,
          ratingReason,
          otherRatingReason,
          timeHorizont,
          propertyData,
          owner: userId,
          valuationType: ValuationType.Online,
          marketingParameters:
            marketingParameters ??
            `${window?.location?.pathname || ''}${
              window?.location?.search || ''
            }`,
        },
      });
    const createTemporaryPropertyResp = JSON.parse(
      JSON.stringify(createTemporaryPropertyResponse)
    );

    if (
      createTemporaryPropertyResp &&
      createTemporaryPropertyResp.error &&
      createTemporaryPropertyResp.error.message
    ) {
      throw new Error(createTemporaryPropertyResp.error.message);
    }
  };

  return {
    createTemporaryProperty,
  };
};
