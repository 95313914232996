import { CSSProperties, FC, memo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Icon from '../../../common/components/ui/icon';
import {
  IconContainer,
  IconDigit,
  IconLabel,
  Row,
} from '../seller-property-card/seller-property-card-styles';

interface IProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  iconStyle?: CSSProperties;
  digitStyle?: CSSProperties;
  newDigitStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  value: string;
  newValue?: string | null;
  label: string;
}

const PropertyIconBase: FC<IProps> = ({
  icon,
  value,
  newValue,
  label,
  iconStyle,
  digitStyle,
  newDigitStyle,
  labelStyle,
  containerStyle,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <IconContainer style={containerStyle}>
      <Row alignItems="center">
        <Icon icon={icon} color={themeContext.blue} style={{ ...iconStyle }} />
        <Row alignItems="center">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {newValue && (
              <IconDigit style={newDigitStyle}>{newValue}</IconDigit>
            )}
            <IconDigit style={digitStyle}>{value}</IconDigit>
          </div>
        </Row>
      </Row>
      <IconLabel style={labelStyle}>{label}</IconLabel>
    </IconContainer>
  );
};

const PropertyIcon = memo(PropertyIconBase);

export { PropertyIcon };
