import styled from 'styled-components';
import { FC } from 'react';
import useGetLocalization from '../../../../localization/get-localization';

interface ILink {
  link: string;
  rel?: string;
  target?: string;
  langProp?: string;
  isWithoutLng?: boolean;
}

const LinkContainer = styled.a`
  margin: 0;
  display: inline-flex;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: ${(props) => props.theme.blue};
`;

const LngLinkContainer: FC<ILink> = ({
  link = '',
  rel,
  target,
  langProp,
  isWithoutLng,
  children,
  ...rest
}) => {
  const lang = useGetLocalization();
  const lngLink = isWithoutLng ? link : `${langProp || lang || 'de-de'}${link}`;

  return (
    <LinkContainer href={lngLink} rel={rel} target={target} {...rest}>
      {children}
    </LinkContainer>
  );
};

export { LngLinkContainer };
