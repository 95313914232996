import { NavLink, NavLinkProps } from 'react-router-dom';
import { FC } from 'react';
import useGetLocalization from './get-localization';

interface IProps extends NavLinkProps {
  isWithoutLng?: boolean;
  absolute?: boolean;
  isOpenNewPage?: boolean;
}

const LngLink: FC<IProps> = ({
  to,
  isWithoutLng,
  absolute,
  isOpenNewPage,
  ...rest
}) => {
  const lang = useGetLocalization();
  const langTo = isWithoutLng
    ? to
    : `${absolute ? '/' : ''}${lang || 'de-de'}${to}`;

  return (
    <NavLink
      target={isOpenNewPage ? '_blank' : '_self'}
      to={langTo}
      {...rest}
    />
  );
};

export default LngLink;
