import { FC, memo } from 'react';
import { useForm } from 'react-hook-form';

import { useIsMobileSize } from '../../../../../../common/hooks/useIsMobileSize';
import { useAppSelector } from '../../../../../../common/hooks';
import { ValuationFormContainer } from '../valuation-form-container/valuation-form-container';
import { ValuationInfoContainer } from '../valuation-info-container/valuation-info-container';
import { IValuationForm } from '../../../../interface';
import { useArrowImage } from '../../../../../hooks/useArrowImage';
import { useModalButtonsHandler } from '../../../../hooks/callback-hooks/useModalButtonsHandler';
import { ModalContainer } from '../../../../../components/fullscreen-modal/modal-container/modal-container';

const ValuationModalV2Base: FC = () => {
  const isMobileSize = useIsMobileSize();
  const {
    isVWOpen,
    isAuthFlow,
    isRegisterFormOpen,
    isLoginFormOpen,
    activeStep,
    isConfirmEmailSent,
  } = useAppSelector((state) => state.valuationWizardV2);
  const formMethods = useForm<IValuationForm>();
  const { arrowRef, arrowPosition } = useArrowImage();
  const { onCloseClick, onBackClick } = useModalButtonsHandler({
    reset: formMethods.reset,
    watch: formMethods.watch,
  });

  return (
    <ModalContainer
      onCloseModalTitle="modal.widget-on-close.title"
      onCloseModalText="modal.widget-on-close.text"
      isOpen={isVWOpen}
      isConfirmEmailSent={isConfirmEmailSent}
      isAuthFlow={isAuthFlow}
      isRegisterFormOpen={isRegisterFormOpen}
      isLoginFormOpen={isLoginFormOpen}
      activeStep={activeStep}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formMethods={formMethods}
      arrowPosition={arrowPosition}
      onBackClick={onBackClick}
      onCloseClick={onCloseClick}
    >
      <ValuationFormContainer />
      {!isMobileSize && <ValuationInfoContainer ref={arrowRef} />}
    </ModalContainer>
  );
};

const ValuationModalV2 = memo(ValuationModalV2Base);

export { ValuationModalV2 };
