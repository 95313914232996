import { FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from 'assets/streamline-light/transportation/signs/road-sign-warning.svg';

import Icon from '../../../../../../common/components/ui/icon';
import { device } from '../../../../../../../style/theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 3px;
  border: solid 1px rgba(162, 87, 87, 0.2);
  background-color: rgba(162, 87, 87, 0.2);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};

  i {
    min-width: 20px;
  }

  @media ${device.tablet} {
    margin: 0 12px 12px;
  }
`;

const InfoMessage: FC = () => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <Icon
        icon={WarningIcon}
        width={20}
        height={20}
        color={themeContext.blue}
      />
      {t('financing-portal-page.tabs.calculator.info-message')}
    </Container>
  );
};

export { InfoMessage };
