import styled from 'styled-components';
import { Headline1 } from '../../../../../common/components/ui/typography';
import { hexToRGB } from '../../../../../common/utils/hex-to-rgb';
import { MainButton } from '../../../../../common/components/ui/buttons';
import { device } from '../../../../../../style/theme';

export const AddressBarContainer = styled.div`
  margin: 0 -16px 16px;
`;

export const Header = styled(Headline1)<{ isColumn: boolean }>`
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  ${({ isColumn }) =>
    !isColumn &&
    `text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;`}
`;

export const PriceContainer = styled.div`
  white-space: nowrap;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: ${(props) => hexToRGB(props.theme.blue, 0.1)};
  margin: 12px 8px 12px 0;
`;

export const FullPrice = styled.span`
  margin-right: 8px;
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;

export const SquareMeterPrice = styled(FullPrice)`
  font-weight: normal;
  sup {
    font-size: 8px;
  }
`;

export const CommissionContainer = styled.div`
  width: 100%;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: ${() => hexToRGB('#a28557', 0.1)};
  margin-bottom: 16px;
`;

export const CommissionLabel = styled.span<{ isBold?: boolean }>`
  margin-right: 8px;
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.5px;
  color: #a28557;
`;

export const CommissionValue = styled(CommissionLabel)`
  font-weight: normal;
  sup {
    font-size: 8px;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const Tag = styled.div`
  padding: 2px 8px;
  margin-right: 4px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: ${(props) => props.theme.white};
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};

  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  @media ${device.mobile} {
    order: -1;
  }
`;

export const Row = styled.div<{
  justifyContent?: string;
  margin?: string;
  alignItems?: string;
  padding?: string;
  isListView?: boolean;
  isColumn?: boolean;
  isWrap?: boolean;
}>`
  ${({ isColumn }) => isColumn && 'flex-direction: column'};
  display: flex;
  align-self: stretch;
  position: relative;
  flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'no-wrap')};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};

  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};

  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};

  border-right: 1px solid
    ${({ theme, isListView }) =>
      isListView ? theme.ctaDisabledBackground : 'transparent'};
  transition: 500ms;
`;

export const PurchaseOfferButton = styled(MainButton)<{ isShortcut?: boolean }>`
  max-height: 40px;
  width: fit-content;
  padding: 12px;
  text-transform: uppercase;
  justify-content: center;
  margin-bottom: 12px;

  ${({ isShortcut }) =>
    !isShortcut &&
    `@media ${device.mobile} {
    width: 100%;
  }`}
`;
