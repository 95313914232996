import styled from 'styled-components';
import { device } from '../../../../../../../style/theme';

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 656px;
  height: 966px;
  margin: 16px;
  background-color: ${({ theme }) => theme.white};

  @media ${device.tablet} {
    width: 100%;
    margin: 0 0 34px;
    height: auto;
  }
`;
