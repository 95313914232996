import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  toggleAuthFlow,
  togglePersonalFinancingForm,
} from '../redux/financingSlice';
import { useAppSelector } from '../../../common/hooks';
import { FormError } from '../../interface';
import { useGetFinancingTitle } from './useGetFinancingTitle';
import { FinancingType } from '../../../../generated';

type TReturn = {
  activeStep: number;
  owner?: string;
  header: string;
  financingErrors: FormError;
  isAuthFlow: boolean;
  isLoading: boolean;
  isIframe?: boolean;
  isFinancingOpen?: boolean;
  isPersonalFinancing?: boolean;
  isLoadingSuggestedProperty: boolean;
  isFinancingCertificate?: boolean;
  onSwitchToAuth: () => void;
  onSwitchToPersonalFinancing: () => void;
};

const useFinancingFormInfo = (): TReturn => {
  const dispatch = useDispatch();

  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const owner = useAppSelector((state) => state.auth.user?._id);
  const {
    errors,
    isAuthFlow,
    isLoading,
    isLoadingSuggestedProperty,
    isPersonalFinancing,
    financingType,
    activeStep,
    isFinancingOpen,
  } = useAppSelector((state) => state.financing);
  const { header } = useGetFinancingTitle({ activeStepProp: activeStep });
  const isFinancingCertificate = useMemo(
    () => financingType === FinancingType.FinancingCertificate,
    [financingType]
  );

  const onSwitchToAuth = useCallback(() => {
    dispatch(toggleAuthFlow(true));
  }, [dispatch]);

  const onSwitchToPersonalFinancing = useCallback(() => {
    dispatch(togglePersonalFinancingForm(true));
  }, [dispatch]);

  return {
    activeStep,
    owner,
    header,
    financingErrors: errors,
    isAuthFlow,
    isLoading,
    onSwitchToAuth,
    onSwitchToPersonalFinancing,
    isIframe,
    isFinancingOpen,
    isPersonalFinancing,
    isFinancingCertificate,
    isLoadingSuggestedProperty,
  };
};

export { useFinancingFormInfo };
