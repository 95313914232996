import styled from 'styled-components';

export const CloseIconContainer = styled.button`
  all: unset;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const MessageContainer = styled.div`
  font-family: 'Source Serif Pro';
  padding-right: 2.5rem;
  text-align: center;
`;
