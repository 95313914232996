import { useFormContext } from 'react-hook-form';
import { MouseEvent } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { IFinancingForm } from '../../interface';
import {
  nextStepAction,
  updateFormStateAction,
} from '../../redux/financingSlice';
import { FinancingReason } from '../../../../../generated';

type TProps = {
  watchedKeys: string[];
  isSubmitForm?: boolean;
  buttonCallback?: (input: Record<string, unknown>) => Promise<void>;
};

type TReturn = {
  onClickNext: (e?: MouseEvent) => void;
};

const useNextHandler = ({
  watchedKeys,
  isSubmitForm,
  buttonCallback,
}: TProps): TReturn => {
  const dispatch = useAppDispatch();
  const { trigger, watch } = useFormContext();
  const activeStep = useAppSelector((state) => state.financing.activeStep);

  const onClickNext = async (e?: MouseEvent) => {
    e?.preventDefault();

    const isFormValid = await trigger(watchedKeys);
    let watchedStepState = watch(watchedKeys);

    if (watchedKeys && watchedKeys.length > 0) {
      watchedStepState = watchedStepState.slice(0, watchedKeys.length);
    }

    if (isFormValid) {
      if (isSubmitForm) {
        return;
      }

      const formState = watchedKeys.reduce(
        (acc, _, i) => ({
          ...acc,
          [watchedKeys[i]]: watchedStepState[i],
        }),
        {}
      ) as Partial<IFinancingForm>;

      dispatch(updateFormStateAction(formState));

      if (buttonCallback) {
        await buttonCallback(formState);
        return;
      }

      // if (
      //   activeStep === 0 &&
      //   formState.reason === FinancingReason.InstallmentLoan
      // ) {
      //   dispatch(nextStepAction(2));
      // } else {
      dispatch(nextStepAction());
      // }
    }
  };

  return { onClickNext };
};

export { useNextHandler };
