import { SubmitHandler } from 'react-hook-form';
import { useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';

import { IRegisterForm } from '../../../../../auth/interfaces';
import { parseRegisterInput } from '../../../../../auth/utils/parse-register-input';
import {
  CreatePropertyInput,
  RegistrationBy,
  UserState,
  UserType,
  useSignUpMutation,
} from '../../../../../../generated';
import {
  setUserId,
  setUserInput,
  setUsername,
  setUserState,
  toggleLoading,
} from '../../../../../auth/redux/authSlice';
import { parseValuationInput } from '../../../../utils/parse-valuation-input';
import { IValuationForm } from '../../../../form-property-valuation-wizard/interface';
import { Feature } from '../../../../../../services/mapbox/interfaces';
import useGetLocalization from '../../../../../localization/get-localization';
import { useAppSelector } from '../../../../../common/hooks';
import { useCreateProperty } from '../../../../form-property-valuation-wizard/hooks/useCreateProperty';
import {
  closeVWModal,
  setIsConfirmEmailSent,
  toggleLoginForm,
  toggleRegisterForm,
} from '../../../redux/valuationWizardV2Slice';
import { useActualExperiment } from '../../../../../experiments/hooks/useActualExperiment';
import { useGetPortalType } from '../../../../../landing/hooks/useGetPortalType';

type TProps = {
  reduxSliceName: 'valuationWizardV2' | 'valuationWizard';
  setErrors: (errors: unknown) => void;
};

type TReturn = {
  isConfirmEmailSent: boolean;
  userState: UserState;
  onRegisterSubmit(formData: IRegisterForm): void;
  onOpenLogin(): void;
};

const useOnlineRegister = ({ reduxSliceName, setErrors }: TProps): TReturn => {
  const dispatch = useDispatch();
  const lang = useGetLocalization();
  const [signUp] = useSignUpMutation();
  const { isSeller } = useGetPortalType();
  const { isRegistrationByEmail, registrationBy } = useActualExperiment();
  const userInput = useAppSelector((state) => state[reduxSliceName].userInput);
  const selectedAddress = useAppSelector(
    (state) => state[reduxSliceName].selectedAddress
  );
  const isConfirmEmailSent = useAppSelector(
    (state) => state.valuationWizardV2.isConfirmEmailSent
  );
  const userState = useAppSelector((state) => state.auth.userState);
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const onOpenLogin = useCallback(() => {
    dispatch(toggleLoginForm(true));
    dispatch(toggleRegisterForm(false));
  }, [dispatch]);

  const { createProperty } = useCreateProperty();

  const onRegisterSubmit: SubmitHandler<IRegisterForm> = useCallback(
    async (formData) => {
      const input = parseRegisterInput({
        formData,
        lang,
        userType: isSeller ? UserType.Seller : UserType.Owner,
      });

      dispatch(setUserInput({ ...formData, password: input.password }));
      dispatch(toggleLoading(true));

      // Create the user account before phone verification
      try {
        const signUpResponse = await signUp({
          input: {
            ...input,
            hasPendingValuations: true,
            registrationBy: registrationBy || RegistrationBy.Mobile,
          },
        }).unwrap();

        if (signUpResponse?.signUp?._id) {
          const {
            _id: id,
            username: newUsername,
            userState: newUserState,
          } = signUpResponse.signUp;
          batch(() => {
            dispatch(setUserId(id));
            dispatch(setUsername(newUsername));
            dispatch(setUserState(newUserState));
          });

          // registration case
          if (isRegistrationByEmail) {
            const valuationInput = parseValuationInput(
              userInput as IValuationForm,
              selectedAddress as Feature
            ) as CreatePropertyInput;

            await createProperty({ valuationInput, userId: id });

            dispatch(setIsConfirmEmailSent(true));
            if (isIframe) {
              setTimeout(() => {
                if (document.fullscreenElement && document.exitFullscreen) {
                  document.exitFullscreen();
                }
                dispatch(closeVWModal());
              }, 5000);
            }
          }
        }
      } catch (error: unknown) {
        setErrors(error);
      } finally {
        dispatch(toggleLoading(false));
      }
    },
    [
      isSeller,
      createProperty,
      dispatch,
      isIframe,
      isRegistrationByEmail,
      lang,
      registrationBy,
      selectedAddress,
      setErrors,
      signUp,
      userInput,
    ]
  );

  return {
    onRegisterSubmit,
    onOpenLogin,
    isConfirmEmailSent,
    userState,
  };
};

export { useOnlineRegister };
