import styled from 'styled-components';
import { BoxTitle } from 'modules/common/components/ui/typography';
import { device } from '../../../../../../../style/theme';
import { SecondaryButton } from '../../../../../../common/components/ui/buttons';

export const Container = styled.div`
  & button {
    margin-top: 24px;
  }
  & > p {
    margin-bottom: 24px;
  }
`;

export const Description = styled.p`
  color: #7a7c7f;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  padding: 24px 0 0;
  margin: 0 !important;
`;

export const Header = styled.div<{ mb: number }>`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: ${({ mb }) => mb}px;
`;

export const Title = styled.h3`
  font-family: Source Serif Pro, sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};

  @media ${device.tablet} {
    font-size: 20px;
  }
`;

export const Text = styled.p`
  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 24px;
`;

export const FinancingRequestButton = styled(SecondaryButton)`
  color: ${({ theme }) => theme.blue};
  border-color: ${({ theme }) => theme.blue};
`;

export const SuccessMessageContainer = styled.div`
  position: fixed;
  padding: 32px;
  z-index: 100;
  background: ${({ theme }) => theme.white};
  border: 2px solid ${({ theme }) => theme.green};
  border-radius: 3px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobile} {
    left: 16px;
    right: 16px;
    transform: translate(0, -50%);
    padding: 16px;
  }
`;

export const SuccessMessageTitle = styled(BoxTitle)`
  position: relative;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: normal;
  padding: 0 40px 0 16px;
  white-space: nowrap;

  @media ${device.tablet} {
    white-space: normal;
    font-size: 16px;
    wite-space: 24px;
    font-weight: bold;
    line-height: 24px;
  }
`;

export const SuccessMessageClose = styled.a`
  cursor: pointer;
`;
