import { Config, devConfig, prodConfig } from './config';

type TReturn = {
  isProd: boolean;
  isDev: boolean;
  config: Config;
};

export const useConfig = (): TReturn => {
  const isProd = window.location.href.includes(
    process.env.REACT_APP_URL_PROD ?? 'https://home.von-poll.com/'
  );

  const isDev = !isProd;

  const configByEnv = isProd ? prodConfig : devConfig;

  return {
    isProd,
    isDev,
    config: configByEnv,
  };
};
