import styled from 'styled-components';
import { device } from '../../../../../../../../style/theme';
import { hexToRGB } from '../../../../../../../common/utils/hex-to-rgb';

export const Svg = styled.svg<{ size?: number }>`
  display: inline-flex;
  vertical-align: bottom;
  width: ${({ size }) => size || 132}px;
  height: ${({ size }) => size || 132}px;

  @media ${device.tablet} {
    width: 64px;
    height: 64px;
  }
`;

export const Circle = styled.circle<{ color?: string; isLoading?: boolean }>`
  stroke: ${({ theme, color }) => color ?? theme.blue};
  stroke-width: 1px;
  stroke-dasharray: 0;
  fill: ${({ theme, color }) => hexToRGB(color ?? theme.blue, 0.05)};
  @keyframes circleColor {
    0% {
      stroke: #dc3545;
    }
    25% {
      stroke: #edb304;
    }
    50% {
      stroke: #28a745;
    }
    75% {
      stroke: #edb304;
    }
    100% {
      stroke: #dc3545;
    }
  }
  ${({ isLoading }) => isLoading && `animation: meterWidth;`}
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const Meter = styled.path<{ color?: string; isLoading?: boolean }>`
  @keyframes meterWidth {
    0% {
      stroke-dashoffset: 280;
      stroke: #dc3545;
    }
    25% {
      stroke-dashoffset: 140;
      stroke: #edb304;
    }
    50% {
      stroke-dashoffset: 0;
      stroke: #28a745;
    }
    75% {
      stroke-dashoffset: 140;
      stroke: #edb304;
    }
    100% {
      stroke-dashoffset: 280;
      stroke: #dc3545;
    }
  }

  stroke-width: 7px;
  ${({ isLoading }) => isLoading && `animation: meterWidth;`}
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  stroke: ${({ theme, color }) => color ?? theme.blue};
  fill: none;
  transition: stroke-dashoffset 1s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  transform-origin: center center;
  transform: rotate(-90deg) scaleX(-1);
  @media ${device.tablet} {
    stroke-width: 6px;
  }
`;

export const Text = styled.text`
  font-family: Source Serif Pro, sans-serif;
  font-size: 24px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: center;
  color: ${({ theme }) => theme.blue};

  @media ${device.tablet} {
    font-size: 20px;
  }
`;
