import { FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import BannerImage from 'assets/seller-welcome-banner.webp';
import BrokerBannerImage from 'assets/broker-view-banner.webp';
import { ReactComponent as SignDocument } from 'assets/streamline-light/work-office-companies/office/office-sign-document.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LoaderContainer } from 'modules/seller/pages/seller-properties-page/seller-properties-page-styles';
import { ValuationLoader } from 'modules/forms/components/common/loader';

import { device } from '../../../../style/theme';
import Banner from '../banner/banner';
import { PreparationCard } from '../preparation/preparation-card/preparation-card';
import { NextStepCard } from '../preparation/next-step-card/next-step-card';
import { useAppSelector } from '../../../common/hooks';
import { fileToBase64 } from '../../../common/components/form/input/input-file/utils/fileToBase64';
import {
  Broker,
  PropertyFileInput,
  PropertyFileType,
  useGetPropertyBrokerQuery,
  useUploadPropertyFilesMutation,
} from '../../../../generated';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { clearFiles } from '../../redux/sellerPropertiesSlice';
import { ReactComponent as ReadHuman } from '../../../../assets/streamline-light/school-learning/library-reading/read-human.svg';
import { TeamContactCard } from '../appointment-card/team-contact-card';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { PropertyLockedMessage } from '../property-locked-message/property-locked-message';

const Container = styled.div`
  position: relative;
  display: flex;

  @media ${device.tablet} {
    flex-direction: column;
    padding: 16px;
  }
`;

const BannerWrapper = styled.div`
  & .preparation-banner {
    & > div {
      grid-template-columns: 57px 55%;

      @media ${device.tablet} {
        grid-template-columns: 1fr;
      }

      @media ${device.mobile} {
        & > h3 {
          text-align: center;
        }
        & > p {
          text-align: center;
        }
        background-image: 'none';
        background-color: ${(props) => props.theme.gold};
      }
    }
  }
`;

const FirstColumn = styled.div<{ isBlurred?: boolean }>`
  flex: 1.72;
  max-width: 688px;
  margin-right: 16px;
  ${(props) => props.isBlurred && 'filter: blur(6px)'};
  @media ${device.tablet} {
    margin-bottom: 16px;
    max-width: 100%;
    width: 100%;
  }
`;

const SecondColumn = styled.div`
  flex: 1;
  max-width: 400px;
  @media ${device.tablet} {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

interface IProps {
  isLocked?: boolean;
}

const PreparationBase: FC<IProps> = ({ isLocked }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();
  const { propertyId } = useParams<{ propertyId: string }>();

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const [uploadFiles, { data: uploadedFiles, error: uploadError, isLoading }] =
    useUploadPropertyFilesMutation();

  const propertyDocuments = useAppSelector(
    (state) => state.sellerProperties.propertyDocuments
  );

  const propertyPhotos = useAppSelector(
    (state) => state.sellerProperties.propertyPhotos
  );

  const propertyPlans = useAppSelector(
    (state) => state.sellerProperties.propertyPlans
  );

  const { propertyBroker, isBrokerLoading, getBrokerError } =
    useGetPropertyBrokerQuery(
      {
        propertyId,
      },
      {
        selectFromResult: ({ data, isLoading: brokerLoading, error }) => ({
          propertyBroker: data?.getPropertyBroker as Broker,
          isBrokerLoading: brokerLoading,
          getBrokerError: error,
        }),
        skip: !isLocked,
      }
    );

  const onEmailBroker = useCallback(() => {
    if (propertyBroker?.email) {
      window.open(`mailto: ${propertyBroker.email}`, '_self');
    }
  }, [propertyBroker?.email]);

  const handleSubmit = useCallback(async () => {
    try {
      if (isLocked) {
        return;
      }
      const documentsPromises = propertyDocuments.map(async (document) => {
        const base64Content = await fileToBase64(document);
        return {
          content: String(base64Content).split(',')[1],
          text: '',
          fileType: PropertyFileType.Document,
          fileName: document.name,
          fileTitle: document.title ?? document.name,
        };
      });

      const photosPromises = propertyPhotos.map(async (document) => {
        const base64Content = await fileToBase64(document);
        return {
          content: String(base64Content).split(',')[1],
          text: '',
          fileType: PropertyFileType.Photo,
          fileName: document.name,
          fileTitle: document.title ?? document.name,
        };
      });

      const plansPromises = propertyPlans.map(async (document) => {
        const base64Content = await fileToBase64(document);
        return {
          content: String(base64Content).split(',')[1],
          text: '',
          fileType: PropertyFileType.Plan,
          fileName: document.name,
          fileTitle: document.title ?? document.name,
        };
      });

      const transformedFiles = await Promise.all([
        ...documentsPromises,
        ...photosPromises,
        ...plansPromises,
      ]);

      await uploadFiles({
        propertyFiles: transformedFiles as PropertyFileInput[],
        propertyId,
      }).unwrap();

      dispatch(clearFiles());
    } catch (error) {
      console.error(error);
    }
  }, [
    dispatch,
    propertyDocuments,
    propertyId,
    propertyPhotos,
    propertyPlans,
    uploadFiles,
    isLocked,
  ]);

  if (isLoading || isBrokerLoading) {
    return (
      <LoaderContainer isLoadingMore>
        <ValuationLoader maxWidth="500px" />
      </LoaderContainer>
    );
  }

  return (
    <>
      {isBrokerView ? (
        <Banner
          icon={ReadHuman}
          image={BrokerBannerImage}
          title={t('seller-portal.broker-view.banner.title')}
          description={t('seller-portal.broker-view.banner.description')}
        />
      ) : (
        <BannerWrapper>
          <Banner
            icon={SignDocument}
            image={isMobileSize ? '' : BannerImage}
            title={t('seller-welcome-banner-title')}
            description={t('seller-welcome-banner-description')}
            className={'preparation-banner'}
          />
        </BannerWrapper>
      )}

      <Container>
        {isLocked && <PropertyLockedMessage onEmailBroker={onEmailBroker} />}
        <FirstColumn isBlurred={isLocked}>
          {uploadError?.message && (
            <GlobalError
              title={t(uploadError?.message?.split(':')[0].toLowerCase())}
            />
          )}
          {getBrokerError?.message && (
            <GlobalError
              title={t(getBrokerError?.message?.split(':')[0].toLowerCase())}
            />
          )}
          <PreparationCard onSubmit={handleSubmit} isLocked={isLocked} />
        </FirstColumn>
        <SecondColumn>
          <NextStepCard
            onSubmit={handleSubmit}
            isNextStepDisabled={isBrokerView}
            isBlurred={isLocked}
            tooltipId={'seller-next-step'}
          />
          <TeamContactCard />
        </SecondColumn>
      </Container>
    </>
  );
};

const Preparation = memo(PreparationBase);

export { Preparation };
