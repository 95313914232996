import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ReactComponent as Filter } from 'assets/streamline-light/interface-essential/filter/filter-1.svg';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FormProvider, useForm } from 'react-hook-form';
import {
  ApplyFilterButton,
  Button,
  ClearFilterButton,
  Form,
  Container,
} from './filter-button-styles';
import { StatisticsFilterForm } from '../../../interfaces';
import { OutsideClickHandler } from '../../../../common/components/outside-click-handler';
import {
  FormColumn,
  FormRow,
} from '../../../../common/components/form/form-grid';
import {
  toggleFilterOpen,
  mergeMarketingStatisticsFilter,
  toggleShouldResetFilter,
} from '../../../redux/sellerPropertiesSlice';
import { useAppSelector } from '../../../../common/hooks';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { InputDate } from '../../../../common/components/form/input/input-date/input-date';

interface IProps {
  onSubmit: (formData: StatisticsFilterForm) => void;
  onReset: () => void;
  isActive?: boolean;
  startDate?: string;
  endDate?: string;
  isSimulation?: boolean;
}

interface IFilterForm {
  dateFrom: Date;
  dateTo: Date;
}

const FilterButtonBase: FC<IProps> = ({
  onSubmit,
  onReset,
  isActive = false,
  startDate,
  endDate,
  isSimulation,
}) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();

  const [dateFrom, setDateFrom] = useState<Date | undefined | null>(
    startDate ? new Date(startDate) : undefined
  );
  const [dateTo, setDateTo] = useState<Date | undefined | null>(
    endDate ? new Date(endDate) : undefined
  );
  const [dateFromInput, setDateFromInput] = useState<string>('');
  const isMobileSize = useIsMobileSize();

  const isFilterOpen = useAppSelector(
    (state) => state.sellerProperties.isFilterOpen
  );

  const shouldResetFilter = useAppSelector(
    (state) => state.sellerProperties.shouldResetFilter
  );

  const methods = useForm<IFilterForm>({
    mode: 'onSubmit',
  });

  const handleSubmit = useCallback(() => {
    dispatch(toggleFilterOpen(false));
    dispatch(
      mergeMarketingStatisticsFilter({
        dateFrom: dateFrom?.toISOString(),
        dateTo: dateTo?.toISOString(),
      })
    );
    onSubmit({
      dateFrom: dateFrom?.toISOString(),
      dateTo: dateTo?.toISOString(),
    });
  }, [dateFrom, dateTo, dispatch, onSubmit]);

  const handleOutsideClick = useCallback(() => {
    dispatch(toggleFilterOpen(false));
  }, [dispatch]);

  const resetFilters = useCallback(() => {
    onReset();
    setDateFrom(startDate ? new Date(startDate) : undefined);
    setDateTo(endDate ? new Date(endDate) : undefined);
    dispatch(toggleFilterOpen(false));
  }, [dispatch, endDate, onReset, startDate]);

  const isApplyFilterBtnDisabled = useMemo(
    () =>
      Boolean(
        (!dateFrom && !dateTo) ||
          (dateFrom && dateTo && new Date(dateFrom) > new Date(dateTo))
      ),
    [dateFrom, dateTo]
  );

  useEffect(() => {
    if (shouldResetFilter) {
      resetFilters();
      dispatch(toggleShouldResetFilter(false));
    }
  }, [dispatch, resetFilters, shouldResetFilter]);

  return (
    <Container>
      <Button
        label={''}
        onClick={() => dispatch(toggleFilterOpen(true))}
        color={theme.blue}
        icon={Filter}
        iconStyle={{
          width: '18px',
          height: '18px',
          margin: 0,
          strokeWidth: '1.5px',
        }}
        borderColor={theme.blue}
        isActive={isActive}
      />
      {isFilterOpen && (
        <OutsideClickHandler
          display="block"
          onOutsideClick={handleOutsideClick}
        >
          <FormProvider {...methods}>
            <Form isSimulation={isSimulation}>
              <FormRow>
                <FormColumn flex={'0 0 100%'}>
                  <InputDate
                    name="dateFrom"
                    label={t('marketing-dashboard.filter.date-from')}
                    placeholder={t('marketing-dashboard.filter.until-today')}
                    withPortal={isMobileSize}
                    value={dateFrom?.toLocaleDateString('en-GB')}
                    selected={dateFrom}
                    onChange={(
                      date: Date | null,
                      event: React.SyntheticEvent<unknown, Event> | undefined
                    ) => {
                      // TODO find mask
                      setDateFrom(date);
                    }}
                    labelStyle={{ width: '100px' }}
                    iconStyle={{ top: '11px' }}
                    wrapperStyle={{ width: 'auto' }}
                  />
                  <InputDate
                    name="dateTo"
                    label={t('marketing-dashboard.filter.date-to')}
                    placeholder={t('marketing-dashboard.filter.until-today')}
                    withPortal={isMobileSize}
                    value={dateTo?.toLocaleDateString('en-GB')}
                    selected={dateTo}
                    onChange={(
                      date: Date | null,
                      event: React.SyntheticEvent<unknown, Event> | undefined
                    ) => {
                      setDateTo(date);
                    }}
                    errors={
                      dateFrom && isApplyFilterBtnDisabled
                        ? {
                            dateTo: {
                              message: t(
                                'marketing-dashboard.filter.date-to.error',
                                {
                                  date: new Date(dateFrom).toLocaleDateString(),
                                }
                              ),
                            },
                          }
                        : {}
                    }
                    noTranslate
                    labelStyle={{ width: '100px' }}
                    iconStyle={{ top: '11px' }}
                    wrapperStyle={{ width: 'auto' }}
                  />
                </FormColumn>
              </FormRow>

              <ClearFilterButton
                label={t('marketing-dashboard.filter.clear')}
                onClick={resetFilters}
                borderColor={theme.blue}
                color={theme.blue}
                type={'button'}
              />
              <ApplyFilterButton
                label={t('marketing-dashboard.filter.apply')}
                type={'submit'}
                onClick={handleSubmit}
                isDisabled={isApplyFilterBtnDisabled}
              />
            </Form>
          </FormProvider>
        </OutsideClickHandler>
      )}
    </Container>
  );
};

const FilterButton = memo(FilterButtonBase);
export { FilterButton };
