import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { defaultTheme } from '../../../../style/theme';

const styles = StyleSheet.create({
  wrapper: {
    width: '32%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '3mm',
    paddingBottom: '3mm',
    paddingLeft: '8mm',
    paddingRight: '8mm',
    border: '1px',
    borderColor: '#c9d5fc',
    borderRadius: '1mm',
    backgroundColor: '#f2f5f7',
  },
  priceText: {
    fontSize: '10px',
    fontWeight: 'extrabold',
    lineHeight: 1.33,
    textAlign: 'center',
    color: defaultTheme.blue,
    marginBottom: '3mm',
  },
  percentText: {
    fontSize: '20px',
    fontWeight: 'extrabold',
    lineHeight: 1.33,
    textAlign: 'center',
    marginBottom: '3mm',
  },
  clarificationText: {
    fontSize: '12px',
    fontWeight: 'extrabold',
    lineHeight: 1.33,
    textAlign: 'center',
    color: defaultTheme.blue,
    opacity: 0.8,
  },
});

const PercentCard = ({
  price,
  percent,
  clarification,
}: {
  price: number | string;
  percent: number;
  clarification: string;
}): JSX.Element => {
  return (
    <View style={styles.wrapper}>
      <Text
        style={[
          styles.percentText,
          {
            color:
              percent > 1
                ? defaultTheme.green
                : percent < 0
                ? defaultTheme.red
                : defaultTheme.gold,
          },
        ]}
      >
        {percent} %
      </Text>
      <Text style={styles.priceText}>
        {`${percent > 0 ? '+' : '-'}${price}`} €
      </Text>
      <Text style={styles.clarificationText}>{clarification}</Text>
    </View>
  );
};

export { PercentCard };
