import { FC } from 'react';
import { InputsContainer } from '../../../../../../components/fullscreen-modal/inputs-container/inputs-container';
import { useSeventhStepLogic } from '../../../../../hooks/useStepsLogic';
import { Container } from './seventh-step-styles';
import { BackButtonContainer } from '../../../../common/back-button-container';

const SeventhStep: FC = () => {
  const { inputs } = useSeventhStepLogic();
  return (
    <Container>
      <InputsContainer inputs={inputs} />
      <BackButtonContainer marginTop={0} />
    </Container>
  );
};

export { SeventhStep };
