import { ChangeEvent, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { values, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../common/hooks';
import {
  Container,
  LoaderContainer,
  UploadFileBtn,
} from './buyer-expose-files-styles';
import {
  InputFile,
  SectionType,
} from '../../common/components/form/input/input-file/input-file';
import { buildAcceptTypes } from '../../common/components/form/input/input-file/utils/buildAcceptTypes';
import { documentTypes } from '../../common/components/form/input/input-file/constants';
import {
  clearFiles,
  setPropertyDocuments,
} from '../../seller/redux/sellerPropertiesSlice';
import { OverviewCard } from '../components/overview/overview-card/overview-card';
import {
  PropertyFileInput,
  PropertyFileType,
  useUploadPropertyDocumentsMutation,
} from '../../../generated';
import { fileToBase64 } from '../../common/components/form/input/input-file/utils/fileToBase64';
import { GlobalError } from '../../common/components/form/error/global-error';
import { GlobalLoader } from '../../common/components/ui/loaders/global-loader';
import { FilterLoader } from '../../seller/components/offers-statistics-card/filter/loader';

const BuyerExposeFilesBase = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const propertyOnOfficeId = urlParams.get('EstateId') || '';

  const [
    uploadDocuments,
    { data: uploadedFiles, error: uploadDocumentsError, isLoading },
  ] = useUploadPropertyDocumentsMutation();

  const propertyDocuments = useAppSelector(
    (state) => state.sellerProperties.propertyDocuments
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, section?: SectionType) => {
      dispatch(
        setPropertyDocuments([...propertyDocuments, ...values(e.target.files)])
      );
    },
    [dispatch, propertyDocuments]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const documentsPromises = propertyDocuments.map(async (document) => {
        const base64Content = await fileToBase64(document);
        return {
          content: String(base64Content).split(',')[1],
          text: '',
          fileType: PropertyFileType.Document,
          fileName: document.name,
          fileTitle: document.title ?? document.name,
        };
      });

      const transformedFiles = await Promise.all([...documentsPromises]);

      await uploadDocuments({
        propertyFiles: transformedFiles as PropertyFileInput[],
        propertyId: propertyOnOfficeId,
      }).unwrap();

      dispatch(clearFiles());
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, propertyDocuments, uploadDocuments]);

  if (isLoading) {
    return <FilterLoader />;
  }

  return (
    <Container>
      {/* {uploadDocumentsError && ( */}
      {/*  <GlobalError */}
      {/*    title={t( */}
      {/*      (uploadDocumentsError as { message: string })?.message?.split( */}
      {/*        ':' */}
      {/*      )[0] */}
      {/*    )} */}
      {/*  /> */}
      {/* )} */}
      {/* <InputFile */}
      {/*  title={t('upload-document.title')} */}
      {/*  description={t('upload-document.description')} */}
      {/*  accept={buildAcceptTypes(documentTypes)} */}
      {/*  multiple */}
      {/*  section={'Documents'} */}
      {/*  onChange={handleChange} */}
      {/*  searchInput={''} */}
      {/*  uploadedFiles={[]} */}
      {/* /> */}
      {/* {!isEmpty(propertyDocuments) && ( */}
      {/*  <div style={{ display: 'flex', justifyContent: 'center' }}> */}
      {/*    <UploadFileBtn */}
      {/*      label={t( */}
      {/*        'buyer-portal.broker-view.expose-documents-page.access-manager.save' */}
      {/*      )} */}
      {/*      onClick={handleSubmit} */}
      {/*    /> */}
      {/*  </div> */}
      {/* )} */}
      <OverviewCard />
    </Container>
  );
};

const BuyerExposeFilesPage = memo(BuyerExposeFilesBase);

export { BuyerExposeFilesPage };
