import { FC } from 'react';
import { ReactComponent as AlarmBellIcon } from 'assets/streamline-light/interface-essential/alert/alarm-bell.svg';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { Modal, ModalTitle } from '../../../../common/components/modal';
import { toggleIsNotificationModalOpen } from '../../redux/searchProfileSlice';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { NotificationsSearchProfileForm } from '../forms/notifications-search-profile-form/notifications-search-profile-form';

const NotificationsSearchProfileModal: FC = () => {
  const isNotificationModalOpen = useAppSelector(
    (state) => state.searchProfile.isNotificationModalOpen
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const closeModal = () => dispatch(toggleIsNotificationModalOpen(false));
  const wrapperStyles = isMobileSize
    ? {
        display: 'flex',
        flexDirection: 'column' as const,
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
        height: '100%',
        overflow: 'overlay',
        padding: '24px',
      }
    : { maxWidth: '512px' };

  return (
    <Modal
      isOpen={isNotificationModalOpen}
      wrapperStyles={wrapperStyles}
      onClose={closeModal}
    >
      {!isMobileSize && (
        <ModalTitle
          icon={AlarmBellIcon}
          text={t('edit.search-profile.modal.notification.title')}
          iconPosition="left"
          textAlign="left"
        />
      )}
      <NotificationsSearchProfileForm />
    </Modal>
  );
};

export { NotificationsSearchProfileModal };
