import { useCallback, MouseEvent, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Condition, PropertyCode } from '../../../../../../generated';
import { device } from '../../../../../../style/theme';
import { BorderButton } from '../../../../../common/components/ui/buttons';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { VALUATION_FORM_KEYS } from '../../../constants';
import { BoxType } from '../../../../interface';
import {
  nextStepAction,
  prevStepAction,
} from '../../../redux/valuationWizardSlice';
import { BoxSelector } from '../../../../components/common/box-selector';
import { NextButton } from '../../../../components/common/next-button';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  &:last-of-type {
    flex-direction: row;
    margin-top: auto;
    justify-content: flex-start;
    > *:last-of-type {
      margin-left: auto;
      @media ${device.tablet} {
        margin-top: 32px;
      }
    }
  }
`;

const MultiFamilyHouseThirdStepBase = (): JSX.Element => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(nextStepAction());
  }, []);

  const propertyType = useAppSelector(
    (state) => state.valuationWizard.userInput?.code
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const switchToPrevStep = useCallback(
    (e?: MouseEvent) => {
      e?.preventDefault();
      dispatch(prevStepAction());
    },
    [dispatch]
  );

  return (
    <Container>
      <Row>
        <BoxSelector
          name={VALUATION_FORM_KEYS.CONDITION}
          boxes={[
            {
              boxType: BoxType.radioBox,
              title:
                'valuation.form.second-step.condition.new/recently-renovated.title',
              text: 'valuation.form.second-step.condition.new/recently-renovated.content',
              value: Condition.NewOrRecentlyRenovated,
            },
            {
              boxType: BoxType.radioBox,
              title:
                'valuation.form.second-step.condition.well-maintained.title',
              text: 'valuation.form.second-step.condition.well-maintained.content',
              value: Condition.WellMaintained,
            },
            {
              boxType: BoxType.radioBox,
              title:
                'valuation.form.second-step.condition.renovation-needed.title',
              text: 'valuation.form.second-step.condition.renovation-needed.content',
              value: Condition.RenovationNeeded,
            },
          ]}
          column
        />
      </Row>
      <Row>
        {(!isMobileSize || isIframe) && (
          <BorderButton label={t('button.back')} onClick={switchToPrevStep} />
        )}
        <NextButton
          watchedKeys={[
            VALUATION_FORM_KEYS.LIVING_AREA,
            ...(propertyType !== PropertyCode.Apartment
              ? [VALUATION_FORM_KEYS.LAND_AREA]
              : []),
            ...(propertyType === PropertyCode.MultiFamilyHouse
              ? [VALUATION_FORM_KEYS.NUMBER_OF_UNITS]
              : []),
            VALUATION_FORM_KEYS.CONDITION,
          ]}
          label={t('button.next-step')}
        />
      </Row>
    </Container>
  );
};

const MultiFamilyHouseThirdStep = memo(MultiFamilyHouseThirdStepBase);

export { MultiFamilyHouseThirdStep };
