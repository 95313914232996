import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import certificateIcon from 'assets/images/icons/financing-landing-page/certificate-icon.png';

import {
  Button,
  CertificateIconBox,
  Container,
  ContentContainer,
  IconContainer
} from "./your-finance-certificate-suggestion-styles";
import { FinancingVariant } from '../../../../../../../generated';
import { useRequestCertificate } from '../../../../../hooks/useRequestCertificate';
import { useIsMobileSize } from '../../../../../../common/hooks/useIsMobileSize';
import Icon from '../../../../../../common/components/ui/icon';

interface IProps {
  financingVariantsProp: FinancingVariant[];
  handleSubmit?: () => void;
  handleClose?: () => void;
}

const YourFinanceCertificateSuggestion: FC<IProps> = ({
  financingVariantsProp,
  handleSubmit,
  handleClose,
}) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const { requestCertificate } = useRequestCertificate({
    financingVariantsProp,
  });
  const isMobileSize = useIsMobileSize();

  const onSubmit = () => {
    requestCertificate();
    if (handleSubmit) {
      handleSubmit();
    }
  };

  const onClose = () => {
    requestCertificate();
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <Container>
      <CertificateIconBox>
        <img src={certificateIcon} alt="certificate icon" />
        {isMobileSize && <h3>{t('finance-certificate-suggestion.title')}</h3>}
      </CertificateIconBox>
      <ContentContainer>
        {!isMobileSize && <h3>{t('finance-certificate-suggestion.title')}</h3>}
        <p>{t('finance-certificate-suggestion.description')}</p>
        {handleSubmit && (
          <Button onClick={onSubmit}>
            {t('finance-certificate-suggestion.button')}
          </Button>
        )}
      </ContentContainer>
      <IconContainer onClick={onClose}>
        <Icon icon={CloseIcon} width={16} height={16} color={theme.blue} />
      </IconContainer>
    </Container>
  );
};

export { YourFinanceCertificateSuggestion };
