import queryString from 'query-string';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { isString } from 'lodash';

import { ReactComponent as WindowLinkIcon } from 'assets/streamline-light/programing-apps-websites/apps-window/app-window-link.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/streamline-light/interface-essential/form-validation/check-circle-1.svg';

import { Headline1 } from '../../common/components/ui/typography';
import Icon from '../../common/components/ui/icon';
import { SecondaryButton } from '../../common/components/ui/buttons';
import { useResendConfirmationCodeMutation } from '../../../generated';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 12px;
  margin: 250px auto calc(250px - 88px);
`;

const Headline = styled(Headline1)`
  margin-top: 4px;
  text-align: center;
`;

const Text = styled.span`
  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 24px;
  margin-bottom: 12px;
  max-width: 520px;
`;

const ExpiredConfirmEmailPage: FC = () => {
  const [isSent, setIsSent] = useState(false);
  const { t } = useTranslation();
  const { search } = useLocation();
  const theme = useContext(ThemeContext);
  const { email } = queryString.parse(search);
  const [resendConfirmationCode] = useResendConfirmationCodeMutation();

  const onSubmit = () => {
    if (isString(email)) {
      resendConfirmationCode({
        email,
      });
      setIsSent(true);
    }
  };

  return (
    <Container>
      {isSent ? (
        <>
          <Icon
            icon={CheckCircleIcon}
            width={88}
            height={88}
            color={theme.green}
          />
          <Headline
            content={t('expired-confirm-email-page.is-sent-state.header')}
          />
          <Text>{t('expired-confirm-email-page.is-sent-state.text')}</Text>
        </>
      ) : (
        <>
          <Icon
            icon={WindowLinkIcon}
            width={101}
            height={94}
            color={theme.blue}
          />
          <Headline
            content={t('expired-confirm-email-page.no-send-state.header')}
          />
          <Text>{t('expired-confirm-email-page.no-send-state.text')}</Text>
          <SecondaryButton
            color={theme.blue}
            borderColor={theme.blue}
            label={t('expired-confirm-email-page.no-send-state.button')}
            onClick={onSubmit}
          />
        </>
      )}
    </Container>
  );
};

export { ExpiredConfirmEmailPage };
