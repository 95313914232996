import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { ReactComponent as WarningIcon } from 'assets/streamline-light/transportation/signs/road-sign-warning.svg';

import { Warning } from './table-personal-financing-example-styles';
import Icon from '../../../../../../../../common/components/ui/icon';

const WarningItem: FC = () => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  return (
    <Warning>
      <Icon
        icon={WarningIcon}
        width={20}
        height={20}
        color={themeContext.blue}
      />
      <p>
        {t(
          `financing-portal.form.info-side.financing-example.detailed-information`
        )}
      </p>
    </Warning>
  );
};

export { WarningItem };
