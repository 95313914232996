import styled from 'styled-components';
import LngLink from 'modules/localization/lng-link';
import { device } from '../../../../style/theme';
import { Headline1 } from '../../../common/components/ui/typography';
import {
  FlatButton,
  MainButton,
  SecondaryButton,
} from '../../../common/components/ui/buttons';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import Icon from '../../../common/components/ui/icon';

export const PropertiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: 12px;
  margin-top: 32px;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    margin-top: 12px;
  }
`;

export const Header = styled(Headline1)`
  text-align: center;
  position: relative;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
  }
`;

export const ShowMoreButton = styled(FlatButton)`
  text-transform: uppercase;
  display: flex;
  max-width: 300px;
  align-self: center;
  margin-top: 24px;
  justify-content: center;

  @media ${device.tablet} {
    margin: 16px 0;
    min-width: 250px;
  }

  @media ${device.mobile} {
    min-width: 100%;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContainer = styled.div<{
  isListView?: boolean;
  isShrinked?: boolean;
}>`
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #fff;
  display: flex;
  flex-direction: ${(props) => (props.isListView ? 'row' : 'column')};
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* max-height: ${(props) => (props.isListView ? '486px' : 'unset')}; */
  min-width: ${(props) => (props.isListView ? '100%' : 'unset')};
`;

export const ImageWrapper = styled.div<{ isListView?: boolean }>`
  min-height: 100%;
  /* //padding-top: calc(152 / 306 * 100%);
  padding-top: calc(147 / 306 * 100%); */
  position: relative;
  width: ${(props) => (props.isListView ? '520px' : 'unset')};
  overflow: hidden;
  @media ${device.tablet} {
    height: 200px;
    min-height: 0;
    width: 100%;
  }
`;

export const CardImage = styled.img<{ isListView?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  /* height: ${(props) => (props.isListView ? '486px' : '100%')}; */
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const CardContent = styled.div<{ isListView?: boolean }>`
  flex: 1;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
`;

export const CardTitle = styled.h4`
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

export const Row = styled.div<{
  justifyContent?: string;
  margin?: string;
  alignItems?: string;
  padding?: string;
  isListView?: boolean;
  isWrap?: boolean;
}>`
  display: flex;
  align-self: stretch;
  .icon {
    min-width: 24px;
    min-height: 24px;
  }
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};
  flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'no-wrap')};

  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};

  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
`;

export const CommissionContainer = styled.div`
  margin-right: 8px;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: ${() => hexToRGB('#a28557', 0.1)};
`;

export const CommissionLabel = styled.span`
  margin-right: 8px;
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.5px;
  color: #a28557;
`;

export const CommissionValue = styled(CommissionLabel)`
  font-weight: normal;
  sup {
    font-size: 8px;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const Tag = styled.div`
  cursor: pointer;
  padding: 2px 8px;
  margin-right: 4px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: ${(props) => props.theme.white};
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};

  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
`;

export const PriceContainer = styled.div`
  padding: 4px 8px;
  border-radius: 3px;
  background-color: ${(props) => hexToRGB(props.theme.blue, 0.1)};
  margin: 12px 0;
  white-space: nowrap;
  margin-right: 8px;
`;

export const Price = styled.span`
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  margin-right: 8px;
`;

export const Location = styled.div<{
  isShrinked?: boolean;
  maxWidth?: number;
}>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${({ isShrinked, maxWidth }) =>
    maxWidth ? `${maxWidth}px` : isShrinked ? '165px' : '280px'};
  height: 20px;
  opacity: 0.8;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  text-transform: uppercase;

  @media ${device.tablet} {
    white-space: normal;
    height: auto;
  }
`;

export const SquareMeterPrice = styled(Price)`
  font-weight: normal;
  sup {
    font-size: 8px;
  }
`;

export const IconDigit = styled.div`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${(props) => props.theme.blue};
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  sup {
    font-size: 8px;
  }
`;

export const IconLabel = styled.div`
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 1px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.6)};
`;

export const RequestExposeButton = styled(MainButton)<{
  isListView?: boolean;
  isMobileSize?: boolean;
}>`
  height: 40px;
  margin-top: ${(props) => (props.isListView ? 0 : '12px')};
  padding: 12px;
  text-transform: uppercase;
  justify-content: center;
  width: ${(props) =>
    props.isMobileSize ? '100%' : props.isListView ? '40px' : 'fit-content'};
  margin-bottom: ${(props) => (props.isMobileSize ? '12px' : 0)};
`;

export const ActionButton = styled(SecondaryButton)<{
  width?: number;
  margin?: string;
}>`
  padding: 12px;
  height: 40px;
  justify-content: center;
  width: ${(props) => (props.width ? `${props.width}px` : 'fit-content')};
  margin: ${(props) => (props.margin ? props.margin : 0)};
`;

export const PropertiesContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const PropertyDetailsContainer = styled.div<{ isShrinked?: boolean }>`
  flex: 1;
  display: grid;
  grid-template-columns: ${(props) =>
    props.isShrinked
      ? 'repeat(2, minmax(0, 1fr))'
      : 'repeat(3, minmax(0, 140px))'};
  grid-row: 2;
  gap: 24px 12px;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, minmax(0, 140px));
    grid-rows: 4;
  }
`;

export const LockedRequestMessage = styled.div`
  padding: 12px;
  width: 100%;
  text-align: center;
  //margin-top: 12px;
  margin-top: 16px;
  border-radius: 3px;
  border: solid 1px #dacebc;
  background-color: rgba(162, 133, 87, 0.2);
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
`;

export const ButtonsWrapper = styled.div<{ isColumn?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.isColumn ? 'unset' : 'center')};
  margin-top: 12px;
  width: 100%;
  flex-direction: ${(props) => (props.isColumn ? 'column' : 'row')};
`;

export const ShowExposeButton = styled(MainButton)<{ isFullSize?: boolean }>`
  max-height: 40px;
  height: 40px;
  justify-content: center;
  max-width: 242px;
  width: 242px;
  ${(props) => props.isFullSize && 'width: 100%; max-width: unset;'}
  white-space: nowrap;
`;

export const DownloadPdfButton = styled(SecondaryButton)<{
  isShrinked?: boolean;
  isMobileSize?: boolean;
}>`
  max-height: 40px;
  height: 40px;
  justify-content: center;
  margin: ${(props) =>
    props.isShrinked || props.isMobileSize ? '12px 0 0 0' : '0 0 0 12px'};
  max-width: 242px;
  width: 242px;
  ${(props) => props.isMobileSize && 'width: 100%; max-width: unset;'}
  white-space: nowrap;
`;

export const FinancingButton = styled(SecondaryButton)<{
  isShrinked?: boolean;
  isMobileSize?: boolean;
}>`
  max-height: 40px;
  height: 40px;
  justify-content: center;
  margin: 12px 0 0 0;
  width: 100%;
  white-space: nowrap;
`;

export const LocationIcon = styled(Icon)`
  &&& > svg {
    max-height: 16px;
  }
`;
export const MapWrapper = styled.div<{ isMobileSize?: boolean }>`
  min-height: 100%;
  position: relative;
  width: ${(props) => (props.isMobileSize ? '100%' : '50%')};
  overflow: hidden;
  @media ${device.tablet} {
    height: 200px;
    min-height: 0;
    width: 100%;
  }
`;

export const IconContainer = styled.div<{
  alignAtEnd?: boolean;
  disabled?: boolean;
}>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
  ${({ alignAtEnd }) =>
    alignAtEnd &&
    `
    > div {
      justify-content: flex-end
    }
  `}
`;
