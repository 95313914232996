import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import styled from 'styled-components';

import {
  CreatePropertyInput,
  LoginResponse,
  RegistrationBy,
  useConfirmGuestUserMutation,
  useConfirmPhoneMutation,
  useRequestVerificationCodeMutation,
  useResendConfirmationCodeMutation,
  useResendGuestUserCodeMutation,
  UserSignUpInput,
  UserState,
  UserType,
  useSignInMutation,
  useSignUpMutation,
  useUserSelfDeleteMutation,
  useVerifyGuestUserMutation,
  ValuationType,
} from '../../../../../../generated';
import { useCreatePropertyDossierMutation } from '../../../../../../services/graphql/enhanced';
import { ConfirmEmail } from '../../../../../auth/components/confirm-email';
import { PhoneVerification } from '../../../../../auth/components/forms/phone-verification';
import { RegisterForm } from '../../../../../auth/components/forms/register-form/register-form';
import { IRegisterForm } from '../../../../../auth/interfaces';
import {
  setConfirmedGuest,
  setUserId,
  setUserInput,
  setUsername,
  setUserState,
  toggleLoading,
} from '../../../../../auth/redux/authSlice';
import { selectFormattedPhone } from '../../../../../auth/redux/selectors/selectFormattedPhone';
import { parseRegisterInput } from '../../../../../auth/utils/parse-register-input';
import { FormGridWrapperFixHeight } from '../../../../../common/components/form/form-grid';
import { Blurry } from '../../../../../common/components/ui/blurry';
import { SecondaryButton } from '../../../../../common/components/ui/buttons';
import { GlobalLoader } from '../../../../../common/components/ui/loaders/global-loader';
import { ParagraphTextWithHTML } from '../../../../../common/components/ui/typography';
import { StatusType } from '../../../../../common/components/verification-input/types';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { TimifyWidget } from '../../../../../timify/components/widget';
import {
  ITimifyParams,
  TimifyPersonalisationIds,
} from '../../../../../timify/interfaces';
import { FORM_STEPS, showServices } from '../../../constants';
import { IValuationForm } from '../../../interface';
import { toggleRegisterForm } from '../../../redux/valuationWizardSlice';
import { FormTitle } from '../../../../components/common/form-title';
import { VisibilityWrapper } from '../../../../components/common/visibility-wrapper';
import { MobileBack } from '../common/mobile-back';
import { Progress } from '../../../../components/common/progress';
import { parseValuationInput } from '../../../../utils/parse-valuation-input';
import { Feature } from '../../../../../../services/mapbox/interfaces';
import LoginOpen from '../../../../../auth/components/login-open';
import useGetLocalization from '../../../../../localization/get-localization';
import { useCreateProperty } from '../../../hooks/useCreateProperty';
import { useGetCountry } from '../../../../../localization/get-country';
import { device } from '../../../../../../style/theme';
import { useActualExperiment } from '../../../../../experiments/hooks/useActualExperiment';

interface IProps {
  timifyParams: ITimifyParams | null;
  setTimifyParams: React.Dispatch<React.SetStateAction<ITimifyParams | null>>;
  onSuccess: (formData: IValuationForm, ownerId: string) => void;

  openLogin?(): void;
}

const LoaderContainer = styled.div<{ isiframe?: boolean }>`
  height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
  &&&& {
    > div {
      position: absolute;
    }
  }
`;

const Container = styled.div<{ isiframe?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
`;

const Header = styled.div`
  display: block;
`;

const BackButton = styled(SecondaryButton)`
  align-self: flex-start;
`;

const PhoneVerificiationWrapper = styled.div`
  flex: 1;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;

const FieldRequirement = styled(ParagraphTextWithHTML)`
  font-weight: bold;
  font-size: 10px;
  line-height: 1.6;
  opacity: 0.8;
  letter-spacing: 0.5px;
  @media ${device.tablet} {
    padding-bottom: 56px;
  }
`;

const PersonalRegisterContainerBase = ({
  timifyParams,
  setTimifyParams,
  onSuccess,
  openLogin,
}: IProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const lang = useGetLocalization();
  const { countryCode } = useGetCountry();
  const { isRegistrationByEmail, registrationBy } = useActualExperiment();

  const formFieldsWrapperRef = useRef<HTMLDivElement>(null);
  const [verificationStatus, setVerificationStatus] = useState(
    StatusType.process
  );

  const userState = useAppSelector((state) => state.auth.userState);
  const formattedPhone = useAppSelector(selectFormattedPhone);
  const email = useAppSelector((state) => state.auth.userInput?.email);
  const username = useAppSelector((state) => state.auth.userInput?.username);
  const password = useAppSelector((state) => state.auth.userInput?.password);
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const isValuationLoading = useAppSelector(
    (state) => state.valuationWizard.isLoading
  );
  const isConfirmedGuest = useAppSelector(
    (state) => state.auth.isConfirmedGuest
  );
  const userInput = useAppSelector((state) => state.valuationWizard.userInput);
  const selectedAddress = useAppSelector(
    (state) => state.valuationWizard.selectedAddress
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const isRegisterFormLoading = useAppSelector((state) => state.auth.isLoading);

  const [errors, setErrors] = useState<null | unknown>(null);
  const [confirmGuestAccount, setConfirmGuestAccount] = useState(false);
  const [confirmEmailSent, setConfirmEmailSent] = useState(false);
  const [reservationSuccess, setReservationSuccess] = useState(false);
  const [temporaryUserCredentials, setTemporaryUserCredentials] = useState({
    username: '',
    accessToken: '',
    refreshToken: '',
    attributeName: 'email',
    firstName: '',
    lastName: '',
  });
  const [temporaryUserInput, setTemporaryUserInput] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
  });

  // Mutations
  const [signUp] = useSignUpMutation();
  const [signIn] = useSignInMutation();
  const [confirmPhone] = useConfirmPhoneMutation();
  const [verifyGuestUser] = useVerifyGuestUserMutation();
  const [confirmGuestUser] = useConfirmGuestUserMutation();
  const [requestConfirmationCode] = useRequestVerificationCodeMutation();
  const [resendConfirmationCode] = useResendConfirmationCodeMutation();
  const [changePhone] = useUserSelfDeleteMutation();
  const [resendGuestConfirmationCode] = useResendGuestUserCodeMutation();
  const { createProperty } = useCreateProperty();
  const [createPropertyDossier] = useCreatePropertyDossierMutation();

  const backToValuationForm = useCallback(
    () => dispatch(toggleRegisterForm(false)),
    [dispatch]
  );
  const createPropertyAndSetTimify = useCallback(
    async (input: UserSignUpInput, userId: string) => {
      // We must create temporary property & dossier
      const valuationInput = parseValuationInput(
        userInput as IValuationForm,
        selectedAddress as Feature
      ) as CreatePropertyInput;

      const createPropertyResponse = await createProperty({
        valuationInput,
        userId,
      });

      let dossierUrl = '';

      if (createPropertyResponse.createProperty._id) {
        const createPropertyDossierResponse = await createPropertyDossier({
          input: {
            propertyId: createPropertyResponse.createProperty._id,
          },
        }).unwrap();
        if (createPropertyDossierResponse.createPropertyDossier?.dossierUrl) {
          dossierUrl =
            createPropertyDossierResponse.createPropertyDossier?.dossierUrl;
        }
      }

      setTimifyParams({
        serviceExternalIds: TimifyPersonalisationIds.PersonalValuation,
        firstName: input?.name ?? '',
        lastName: input?.surname ?? '',
        email: input?.email ?? '',
        phone: input?.phone ?? '',
        country: countryCode,
        showServices,
        showFooterBackButton: '1',
        dossierUrl: dossierUrl ?? '',
      });
    },
    [
      countryCode,
      createProperty,
      createPropertyDossier,
      selectedAddress,
      setTimifyParams,
      userInput,
    ]
  );

  const onRegisterSubmit: SubmitHandler<IRegisterForm> = useCallback(
    async (formData) => {
      const input = parseRegisterInput({
        formData,
        lang,
        userType: UserType.Owner,
      });

      dispatch(setUserInput({ ...formData, password: input.password }));
      dispatch(toggleLoading(true));
      setTemporaryUserInput(input);

      if (!input.createAccount) {
        try {
          const verifyGuestUserResponse = await verifyGuestUser({
            input: {
              phoneNumber: input.phone,
            },
          }).unwrap();

          if (verifyGuestUserResponse.verifyGuestUser._id) {
            if (verifyGuestUserResponse.verifyGuestUser.verified) {
              dispatch(setConfirmedGuest(input.phone));
              await createPropertyAndSetTimify(
                input,
                verifyGuestUserResponse.verifyGuestUser._id
              );
            } else {
              setConfirmGuestAccount(true);
            }
          }
        } catch (error: unknown) {
          setErrors(error);
        } finally {
          dispatch(toggleLoading(false));
        }
        return;
      }

      // Create the user account
      try {
        const signUpResponse = await signUp({
          input: {
            ...input,
            registrationBy: registrationBy || RegistrationBy.Mobile,
          },
        }).unwrap();

        if (signUpResponse?.signUp?._id) {
          const {
            _id: id,
            username: newUsername,
            userState: newUserState,
          } = signUpResponse.signUp;
          batch(() => {
            dispatch(setUserId(id));
            dispatch(setUsername(newUsername));
            dispatch(setUserState(newUserState));
          });
          if (isRegistrationByEmail) {
            await createPropertyAndSetTimify(input, id);
          }
        }
      } catch (error: unknown) {
        setErrors(error);
      } finally {
        dispatch(toggleLoading(false));
      }
    },
    [
      createPropertyAndSetTimify,
      dispatch,
      isRegistrationByEmail,
      lang,
      registrationBy,
      signUp,
      verifyGuestUser,
    ]
  );

  useEffect(() => {
    const sendConfirmEmail = async () => {
      if (reservationSuccess && timifyParams) {
        setTimifyParams(null);
        if (temporaryUserCredentials?.username) {
          const confirmationCodeResponse = await requestConfirmationCode({
            input: { ...temporaryUserCredentials },
          }).unwrap();

          if (confirmationCodeResponse?.requestVerificationCode) {
            setConfirmEmailSent(true);
          }
        } else if (
          isConfirmedGuest ||
          !isAnonymousUser ||
          isRegistrationByEmail
        ) {
          setConfirmEmailSent(true);
        }
      }
    };

    sendConfirmEmail();
  }, [
    reservationSuccess,
    timifyParams,
    setTimifyParams,
    temporaryUserCredentials,
    requestConfirmationCode,
    isConfirmedGuest,
    isAnonymousUser,
    isRegistrationByEmail,
  ]);

  const onVerifyPhone = useCallback(
    async (confirmationCode: string) => {
      setVerificationStatus(StatusType.wait);
      dispatch(toggleLoading(true));
      if (confirmGuestAccount) {
        try {
          const confirmGuestAccountResponse = await confirmGuestUser({
            input: {
              phoneNumber: formattedPhone,
              verificationCode: confirmationCode,
            },
          }).unwrap();

          if (confirmGuestAccountResponse.confirmGuestUser._id) {
            dispatch(setConfirmedGuest(formattedPhone));

            setTimifyParams({
              serviceExternalIds: TimifyPersonalisationIds.PersonalValuation,
              firstName: temporaryUserInput?.name ?? '',
              lastName: temporaryUserInput?.surname ?? '',
              email: temporaryUserInput?.email ?? '',
              phone: temporaryUserInput?.phone ?? '',
              country: countryCode,
              showServices,
              showFooterBackButton: '1',
            });
            return;
          }
        } catch (error: unknown) {
          setErrors(error);
        } finally {
          dispatch(toggleLoading(false));
        }
      }
      if (confirmationCode && username && password) {
        try {
          const confirmPhoneResult = await confirmPhone({
            input: {
              username,
              confirmationCode,
            },
          }).unwrap();

          if (confirmPhoneResult?.confirmPhone) {
            dispatch(setUserState(confirmPhoneResult.confirmPhone));
          }

          try {
            // Initiaite login to get the jwt token
            const signInResponse = await signIn({
              input: {
                userAuthenticationKey: username,
                password,
                requestAccessToken: true,
              },
            }).unwrap();

            if (signInResponse.signIn) {
              const signInParams = signInResponse.signIn as LoginResponse;
              setTemporaryUserCredentials({
                username: signInParams?.user?.username,
                accessToken: signInParams?.jwt?.token ?? '',
                refreshToken: signInParams?.jwt?.refreshToken ?? '',
                attributeName: 'email',
                firstName: signInParams?.user?.name,
                lastName: signInParams?.user?.surname,
              });
              onSuccess(userInput as IValuationForm, signInParams?.user?._id);
            }
          } catch (error: unknown) {
            setErrors(error);
          }
        } catch (error: unknown) {
          setErrors(error);
          setVerificationStatus(StatusType.error);
        } finally {
          dispatch(toggleLoading(false));
        }
      }
    },
    [
      confirmGuestAccount,
      username,
      password,
      confirmGuestUser,
      formattedPhone,
      dispatch,
      setTimifyParams,
      temporaryUserInput?.name,
      temporaryUserInput?.surname,
      temporaryUserInput?.email,
      temporaryUserInput?.phone,
      countryCode,
      confirmPhone,
      signIn,
      onSuccess,
      userInput,
    ]
  );

  const onResendConfirmationCode = useCallback(() => {
    if (confirmGuestAccount) {
      resendGuestConfirmationCode({ phoneNumber: formattedPhone });
    } else if (email) {
      resendConfirmationCode({
        email,
      });
    }
  }, [
    confirmGuestAccount,
    formattedPhone,
    resendConfirmationCode,
    resendGuestConfirmationCode,
    email,
  ]);

  const onChangePhone = useCallback(async () => {
    if (username) {
      try {
        await changePhone({ username }).unwrap();

        batch(() => {
          dispatch(setUserId(''));
          dispatch(setUsername(''));
          dispatch(setUserState(UserState.Unauthenticated));
          setConfirmGuestAccount(false);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [changePhone, dispatch, username]);

  return (
    <>
      {!isIframe && (
        <MobileBack
          title={t('landing-page.primary-button.personal-valuation.label')}
        />
      )}
      <Container isiframe={isIframe}>
        {(isRegisterFormLoading || isValuationLoading) && (
          <LoaderContainer isiframe={isIframe}>
            <GlobalLoader />
          </LoaderContainer>
        )}
        <VisibilityWrapper
          isVisible={!isRegisterFormLoading && !isValuationLoading}
        >
          {userState === UserState.Unauthenticated &&
            !confirmGuestAccount &&
            !timifyParams &&
            !confirmEmailSent && (
              <>
                <Header>
                  <Progress
                    activeBullet={FORM_STEPS - 1}
                    bullets={FORM_STEPS + 1}
                  />
                  <FormTitle text={t('register.form.title')} />
                </Header>

                <FormGridWrapperFixHeight wrapperRef={formFieldsWrapperRef}>
                  <LoginOpen handleClick={openLogin} />
                  <RegisterForm
                    skipPassword
                    onSubmit={onRegisterSubmit}
                    valuationType={ValuationType.Personal}
                    errors={errors}
                  />
                  <FieldRequirement
                    content={t('register.form.required.fields.message')}
                  />
                </FormGridWrapperFixHeight>
                <Blurry
                  scrolledElementRef={formFieldsWrapperRef}
                  position={{
                    left: '0',
                    bottom: !isMobileSize || isIframe ? '48px' : '0',
                  }}
                />
                {(!isMobileSize || isIframe) && (
                  <BackButton
                    label={t('button.back')}
                    onClick={backToValuationForm}
                  />
                )}
              </>
            )}
          {(userState === UserState.VerifyPhone ||
            (confirmGuestAccount && !timifyParams && !confirmEmailSent)) && (
            <>
              <Header>
                <FormTitle text={t('phone-verification.title')} />
              </Header>
              <PhoneVerificiationWrapper>
                <PhoneVerification
                  phoneNumber={formattedPhone ?? ''}
                  onVerifyPhone={onVerifyPhone}
                  status={verificationStatus}
                  resendCode={onResendConfirmationCode}
                  changePhone={onChangePhone}
                />
              </PhoneVerificiationWrapper>
            </>
          )}
          {timifyParams && (
            <TimifyWidget
              {...timifyParams}
              setReservationSuccess={setReservationSuccess}
            />
          )}
          {confirmEmailSent && (
            <ConfirmEmail
              title={t(
                confirmGuestAccount
                  ? 'modal.register.confirm-email.personal-valuation.guest.title'
                  : 'modal.register.confirm-email.personal-valuation.title'
              )}
              textContent={t(
                confirmGuestAccount
                  ? 'modal.register.confirm-email.personal-valuation.guest.content'
                  : 'modal.register.confirm-email.personal-valuation.content'
              )}
            />
          )}
        </VisibilityWrapper>
      </Container>
    </>
  );
};

const PersonalRegisterContainer = memo(PersonalRegisterContainerBase);

export { PersonalRegisterContainer };
