import 'rc-collapse/assets/index.css';

import Collapse, { Panel } from 'rc-collapse';
import { CollapsePanelProps } from 'rc-collapse/lib/interface';
import { FC, useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';

import { ReactComponent as ArrowDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import { ReactComponent as QuestionHelpCircle } from 'assets/streamline-light/interface-essential/help/question-help-circle.svg';

import {
  BuyerLpSection,
  FinancingLpSection,
  OwnerLpSection,
  RpSection,
  SellerLpSection,
  useLazyReadFaqsQuery,
} from '../../../../generated';
import Icon from '../../../common/components/ui/icon';
import motion from './utils';
import { Container, TitleContainer, Title, ContentText } from './faqs-styles';

interface IProps {
  section:
    | RpSection
    | BuyerLpSection
    | SellerLpSection
    | FinancingLpSection
    | OwnerLpSection;
  regional?: boolean;
  left?: boolean;
}

const Faqs: FC<IProps> = ({ section, regional, left }) => {
  const themeContext = useContext(ThemeContext);

  const [readFaqs, { data, isLoading }] = useLazyReadFaqsQuery();

  useEffect(() => {
    if (!readFaqs || !section || !section.recordIds) return;

    const ids = section.recordIds.length > 0 ? section.recordIds : [''];

    readFaqs({ filter: { _ids: ids } });
  }, [section, readFaqs]);

  useEffect(() => {
    const faq = document.querySelector('#FAQ');
    if (faq && window.location.hash === '#FAQ') {
      setTimeout(() => {
        faq.scrollIntoView({ behavior: 'smooth' });
      }, 600);
    }
  }, []);

  const { recordIds, title } = section;

  return (
    <Container id={'FAQ'} regional={regional}>
      {title && (
        <TitleContainer left={left}>
          <Icon color="#000" width={40} height={40} icon={QuestionHelpCircle} />
          <Title content={title} />
        </TitleContainer>
      )}
      <Collapse
        accordion
        openMotion={motion}
        className="faq-accordion"
        defaultActiveKey={recordIds[0]}
        expandIcon={({ isActive }: CollapsePanelProps) => {
          return (
            <Icon
              width={16}
              height={16}
              icon={ArrowDown}
              color={themeContext.blue}
              style={{
                transition: `transform 500ms ease`,
                transform: isActive ? 'rotate(-180deg)' : 'none',
              }}
            />
          );
        }}
      >
        {!data || isLoading
          ? null
          : data.data.map((item) => (
              <Panel
                key={item?._id}
                header={item?.title}
                headerClass="faq-header"
                className="faq-content"
              >
                <ContentText
                  dangerouslySetInnerHTML={{ __html: item?.expandedText ?? '' }}
                />
              </Panel>
            ))}
      </Collapse>
    </Container>
  );
};

export { Faqs };
