import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { Headline2 } from '../../../../common/components/ui/typography';

export const TestimonialsContainer = styled.div`
  position: relative;
  margin: 88px 0;

  @media ${device.mobile} {
    margin: 16px 0;
  }
`;

export const Header = styled(Headline2)`
  text-align: center;
  position: relative;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
  }

  @media ${device.mobile} {
    text-align: center;
  }
`;

export const TestimonialsGrid = styled.div`
  position: relative;
  display: grid;
  //grid-template-columns: repeat(3, 1fr);
  //grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  margin-top: 32px;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 16px;
    margin-top: 16px;
  }
`;
