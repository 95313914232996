import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useHiddenVariants } from '../../../../../hooks/useHiddenVariants';
import { useGetFinancing } from '../../../../../hooks/useGetFinancing';
import { Container, VariantItem } from './variants-container-styles';

const VariantsContainer: FC = () => {
  const { financingId } = useParams<{ financingId: string }>();
  const { financingVariants } = useGetFinancing({
    financingId,
  });
  const { hiddenVariants, onHideVariant } = useHiddenVariants();
  const isTwoVariants =
    Number(financingVariants?.length) - Number(hiddenVariants?.length) < 3;

  return (
    <Container>
      {financingVariants?.map((item, i) => {
        return (
          <VariantItem
            key={`${i}`}
            isHidden={hiddenVariants.includes(i)}
            onClick={() => {
              if (hiddenVariants.includes(i) || !isTwoVariants) {
                onHideVariant(i);
              }
            }}
          >
            {item.financingVariantData.name}
          </VariantItem>
        );
      })}
    </Container>
  );
};

export { VariantsContainer };
