import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  cleanFinancingCalculationStateAction,
  setCalculateFinancingPropositions,
} from '../../forms/form-financing/redux/financingSlice';
import { useAppSelector } from '../../common/hooks';

const useCleanVariantPropositions = (): void => {
  const dispatch = useDispatch();
  const { financingId } = useParams<{ financingId: string }>();
  const { variantIndex } = useAppSelector((state) => state.financing);
  useEffect(() => {
    dispatch(setCalculateFinancingPropositions());
    dispatch(cleanFinancingCalculationStateAction());
  }, [variantIndex, financingId]);
};

export { useCleanVariantPropositions };
