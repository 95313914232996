import { FC, UIEvent, useCallback, useRef } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useTranslation } from 'react-i18next';

import {
  setActiveTab,
  setIsAppointmentSelectOpen,
  setIsOpenHeadAccordion,
} from '../redux/exposeSlice';

import { HeadInfo } from './head-info/head-info';
import { PropertyCode, PropertyPurchaseIntent } from '../../../../../generated';
import { useAppSelector } from '../../../../common/hooks';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { Overview } from './tabs/overview/overview';
import { InfrastructurePropertyWrapper } from './tabs/local-supply/infrastructure-espose-details-wrapper';
import { SocialEconomicsExposeDetailsWrapper } from './tabs/social-economics/social-economics-expose-details-wrapper';
import { Content, TabsContainer, Container, InfoOverlay } from './info-styles';

interface IProps {
  purchaseIntents: PropertyPurchaseIntent[];
  refetch: () => void;
}

const Info: FC<IProps> = ({ purchaseIntents, refetch }) => {
  const dispatch = useDispatch();
  const contactFormRef = useRef<HTMLDivElement>(null);
  const isOpenHeadAccordion = useAppSelector(
    (state) => state.exposeDetails.isOpenHeadAccordion
  );
  const isMobileSize = useIsMobileSize();
  const { t } = useTranslation();
  const activeTab = useAppSelector((state) => state.exposeDetails.activeTab);
  const propertyCode = useAppSelector(
    (state) => state.exposeDetails.selectedPropertyCode
  );

  const onScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const { scrollTop } = e.currentTarget;
      if (!isMobileSize) {
        if (isOpenHeadAccordion && scrollTop > 180) {
          batch(() => {
            dispatch(setIsAppointmentSelectOpen(false));
            dispatch(setIsOpenHeadAccordion(false));
          });
        } else if (!isOpenHeadAccordion && scrollTop === 0) {
          batch(() => {
            dispatch(setIsAppointmentSelectOpen(false));
            dispatch(setIsOpenHeadAccordion(true));
          });
        }
      }
    },
    [dispatch, isMobileSize, isOpenHeadAccordion]
  );

  return (
    <Content onScroll={onScroll}>
      <Tabs
        selectedIndex={activeTab}
        onSelect={(index) => {
          dispatch(setActiveTab(index));
        }}
      >
        <Container>
          <HeadInfo
            purchaseIntents={purchaseIntents}
            refetch={refetch}
            contactFormRef={contactFormRef}
          />
          <TabsContainer>
            <TabList>
              <Tab>{t('expose-page.tabs.overview.label')}</Tab>
              <Tab hidden={propertyCode === PropertyCode.Plot}>
                {t('expose-page.tabs.local-supply.label')}
              </Tab>
              <Tab hidden={propertyCode === PropertyCode.Plot}>
                {t('expose-page.tabs.socioeconomics.label')}
              </Tab>
            </TabList>
          </TabsContainer>
          {!isOpenHeadAccordion && <InfoOverlay />}
        </Container>
        <TabPanel>
          <Overview contactFormRef={contactFormRef} />
        </TabPanel>
        <TabPanel>
          <InfrastructurePropertyWrapper />
        </TabPanel>
        <TabPanel>
          <SocialEconomicsExposeDetailsWrapper />
        </TabPanel>
      </Tabs>
    </Content>
  );
};

export { Info };
