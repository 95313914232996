import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { device } from '../../../../style/theme';

import { MainButton } from '../../../common/components/ui/buttons';
import {
  Headline1,
  Headline3,
  TitleSlogan,
} from '../../../common/components/ui/typography';
import { VerificationPin } from '../../../common/components/verification-input';
import { StatusType } from '../../../common/components/verification-input/types';
import { useAppSelector } from '../../../common/hooks';
import { UserType } from '../../../../generated';

interface IProps {
  phoneNumber: string; // yes
  onVerifyPhone(code: string): void; // no
  status: StatusType; //
  resendCode?: () => void;
  changePhone?: () => void;
  isOnBuyerSignUpPage?: boolean;
}

const PhoneWrapper = styled.div<{ isOnBuyerSignUpPage?: boolean }>`
  margin: 0 auto;
  padding: 8px 20px;
  text-align: center;
  border-radius: 3px;
  border: solid 1px #d9d9d9;
  width: 100%;
  max-width: ${(props) => (props.isOnBuyerSignUpPage ? 'unset' : '500px')};
  h2 {
    white-space: nowrap;
  }
`;

const Phone = styled(Headline1)`
  @media ${device.tablet} {
    font-size: 24px;
  }
`;

const CodeInformationWrapper = styled.div`
  text-align: center;
  padding: 0 0 24px 0;
`;

const VerificationCodeActionsWrapper = styled.div`
  padding: 16px 0 24px;
`;
const ActionTitlesHolder = styled.div`
  display: flex;
  justify-content: center;
`;

const Action = styled.div<{ isDisabled?: boolean }>`
  cursor: pointer;
  transition: opacity 225ms ease;
  min-height: 40px;
  ${({ isDisabled }) =>
    isDisabled &&
    `
    >:first-child {
    opacity: 0.45;
    cursor: default;
    pointer-events: none;
    }
  `}
`;

const ActionTitle = styled(TitleSlogan)<{ color: string }>`
  text-transform: uppercase;
  padding: 0 12px;
  color: ${(props) => props.color};
  text-align: center;
`;

const ButtonWrapper = styled.div<{ isOnBuyerSignUpPage?: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin: auto 0 0 0;
  ${(props) =>
    props.isOnBuyerSignUpPage &&
    `
    width: 100%;

    & button {
  width: 100%;
  justify-content: center;
  }`}

  @media ${device.tablet} {
    flex: 1;
    align-items: flex-end;
    margin-bottom: 16px;
    button {
      width: 100%;
    }
  }
`;

const PhoneVerificationBase = ({
  phoneNumber,
  onVerifyPhone,
  status,
  resendCode,
  changePhone,
  isOnBuyerSignUpPage,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const [counter, setCounter] = useState(60);
  const [isResendActive, setResendActive] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const userType = useAppSelector((state) => state.auth.user?.userType);

  // When user finish entering the code
  const handleOnFinish = useCallback((userConfirmationCode: string) => {
    setConfirmationCode(userConfirmationCode);
  }, []);

  const handleOnVerifyPhone = useCallback(
    () => onVerifyPhone(confirmationCode),
    [onVerifyPhone, confirmationCode]
  );

  const handleResendCode = () => {
    if (resendCode && !isResendActive) {
      setResendActive(true);
      resendCode();
    }
  };

  const handleChangePhone = () => {
    if (changePhone) {
      changePhone();
    }
  };

  const isBuyer = useMemo(() => {
    if (userType) {
      return userType[0] === UserType.Buyer;
    }
    return false;
  }, [userType]);

  useEffect(() => {
    const timer =
      counter > 0 &&
      isResendActive &&
      setInterval(() => setCounter(counter - 1), 1000);

    if (counter === 0 && isResendActive) {
      setResendActive(false);
      setCounter(60);
    }

    return () => clearInterval(timer as unknown as number);
  }, [counter, isResendActive]);

  return (
    <>
      <PhoneWrapper isOnBuyerSignUpPage={isOnBuyerSignUpPage}>
        <Phone content={phoneNumber ?? ''} />
      </PhoneWrapper>
      <VerificationCodeActionsWrapper>
        <ActionTitlesHolder>
          <Action isDisabled={isResendActive} onClick={handleResendCode}>
            <ActionTitle
              content={t('confirmation-code.send-again')}
              color={themeContext.blue}
            />
            {isResendActive && (
              <ActionTitle content={`${counter}`} color={themeContext.blue} />
            )}
          </Action>
          <Action onClick={handleChangePhone}>
            <ActionTitle
              content={t('confirmation-code.change-phone')}
              color={themeContext.ctaBlue}
            />
          </Action>
        </ActionTitlesHolder>
      </VerificationCodeActionsWrapper>
      <CodeInformationWrapper>
        <Headline3
          content={t('modal.register.second-step.code.verification.title')}
        />
      </CodeInformationWrapper>
      <VerificationPin
        type="number"
        currentCode={confirmationCode}
        inputsNumber={6}
        status={status}
        onFinish={handleOnFinish}
      />
      <ButtonWrapper isOnBuyerSignUpPage={isOnBuyerSignUpPage}>
        <MainButton
          isDisabled={confirmationCode.length < 6}
          label={t(
            isOnBuyerSignUpPage
              ? 'buyer-sign-up-page.verify-account'
              : isBuyer
              ? 'buyer.verify-phone.button'
              : 'modal.register.second-step.button.register'
          )}
          onClick={handleOnVerifyPhone}
        />
      </ButtonWrapper>
    </>
  );
};

const PhoneVerification = memo(PhoneVerificationBase);

export { PhoneVerification };
