import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { Headline2 } from '../../../../common/components/ui/typography';

export const FunctionsContainer = styled.div`
  position: relative;
  padding: 32px 0 88px;

  @media ${device.mobile} {
    padding: 0 0 8px;
  }
`;

export const BgColor = styled.div`
  position: absolute;
  width: 100vw;
  top: 0;
  left: 50%;
  right: 50%;
  margin: 0 -50vw;
  height: 100%;
  background-color: #f2f4f7;
`;

export const Header = styled(Headline2)`
  text-align: center;
  position: relative;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
  }

  @media ${device.mobile} {
    text-align: center;
  }
`;

export const FunctionsGrid = styled.div<{ isSeller?: boolean }>`
  position: relative;
  display: grid;
  //grid-template-columns: repeat(3, 1fr);
  //grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  grid-column-gap: 37px;
  grid-row-gap: 67px;
  margin-top: 32px;

  ${({ isSeller }) =>
    isSeller &&
    `
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 48px;
    grid-row-gap: 48px;
  `}

  @media ${device.tablet} {
    grid-column-gap: 16px;
    grid-row-gap: 24px;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    margin-top: 24px;
  }
`;
