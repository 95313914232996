import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useUsefulValuationContainerData } from '../../../../../hooks/useGetValuationFormInfo';
import { GlobalLoader } from '../../../../../../../common/components/ui/loaders/global-loader';
import { ValuationForm } from '../../../../forms/valuation-form/valuation-form';
import { TimifyWidget } from '../../../../../../../timify/components/widget';
import { ConfirmEmail } from '../../../../../../../auth/components/confirm-email';
import { AuthContainer } from '../../../auth-containers/auth-container';
import { useSubmitPersonalEVForm } from '../../../../../hooks/callback-hooks/personal-valuation/useSubmitPersonalEVForm';
import {
  WidgetContainer,
  Title,
} from '../../../../../../components/fullscreen-modal/styles';
import { useIsMobileSize } from '../../../../../../../common/hooks/useIsMobileSize';

const LoaderContainer = styled.div`
  &&&& {
    > div {
      position: absolute;
    }
  }
`;

const PersonalValuation: FC = () => {
  const { t } = useTranslation();
  const [reservationSuccess, setReservationSuccess] = useState(false);
  const {
    activeStep,
    owner,
    onSwitchToAuth,
    valuationWizardErrors,
    isAuthFlow,
    isLoading,
    timifyParams,
  } = useUsefulValuationContainerData();
  const { onSubmit } = useSubmitPersonalEVForm();
  const isMobileSize = useIsMobileSize();

  const isValuationForm = useMemo(
    () => !isLoading && !reservationSuccess && !timifyParams,
    [isLoading, reservationSuccess, timifyParams]
  );
  const isConfirmEmail = useMemo(
    () => reservationSuccess && !timifyParams,
    [reservationSuccess, timifyParams]
  );

  if (owner) {
    return (
      <>
        {isLoading && (
          <LoaderContainer>
            <GlobalLoader />
          </LoaderContainer>
        )}
        {isValuationForm && (
          <ValuationForm
            isHeadline={!isMobileSize}
            activeFormStep={activeStep}
            errors={valuationWizardErrors}
            onSubmit={onSubmit}
          />
        )}
        {timifyParams && (
          <WidgetContainer>
            <Title>{'Verfügbare Termine'}</Title>
            <TimifyWidget
              {...timifyParams}
              setReservationSuccess={setReservationSuccess}
            />
          </WidgetContainer>
        )}
        {isConfirmEmail && (
          <ConfirmEmail
            title={t('modal.register.confirm-email.personal-valuation.title')}
            textContent={t(
              'modal.register.confirm-email.personal-valuation.content'
            )}
          />
        )}
      </>
    );
  }

  return (
    <>
      {!isAuthFlow ? (
        <>
          {isLoading ? (
            <LoaderContainer>
              <GlobalLoader />
            </LoaderContainer>
          ) : (
            <ValuationForm
              isHeadline={!isMobileSize}
              activeFormStep={activeStep}
              errors={valuationWizardErrors}
              lastStepCallback={onSwitchToAuth}
            />
          )}
        </>
      ) : (
        <AuthContainer />
      )}
    </>
  );
};

export { PersonalValuation };
