import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import {
  Headline1,
  TitleSlogan,
} from '../../../../common/components/ui/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 688px;
  margin-left: auto;
  @media ${device.tabletSlider} {
    max-width: 100%;
  }
`;

export const SliderContainer = styled.div<{ isMobileRedesign?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 464px;
  &&& {
    .swiper-container {
      width: 100%;
      height: 100%;

      &#main {
        background-color: ${(props) => props.theme.logoBlue};
      }

      &#controller {
        position: absolute;
        height: 600px;
        width: 520px;
        left: -416px;
        bottom: 52px;
      }
    }

    .swiper-pagination {
      bottom: 0;
      padding-bottom: 24px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .swiper-wrapper {
      padding-inline-start: 0;
    }

    .swiper-pagination-bullet {
      position: relative;
      width: 16px;
      height: 16px;
      padding: 3px;
      border: solid 1px rgba(0, 48, 94, 0.5);
      background: transparent;
      background-clip: content-box;
      margin: 0 20px;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background-color: ${(props) => props.theme.blue};
      }
      &:not(:last-of-type):after {
        content: '';
        border-bottom: 1px dashed rgba(0, 48, 94, 0.5);
        position: absolute;
        width: calc(300% - 8px);
        top: 50%;
        left: calc(100% + 4px);
        transform: translateY(-50%);
      }
    }
    .parallax-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 130%;
      height: 100%;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
    }
  }
  @media ${device.tabletSlider} {
    height: auto;
    &&& {
      .swiper-container {
        &#main {
          order: 2;
        }

        &#controller {
          order: 1;
          position: relative;
          width: 100%;
          max-width: 520px;
          height: 100%;
          left: unset;
          bottom: unset;
          margin-bottom: 16px;
          ${(props) => props.isMobileRedesign && 'margin-bottom: 0px;'}
        }

        &:first-child {
          .swiper-wrapper {
            ${(props) => props.isMobileRedesign && 'padding-bottom: 48px;'}
          }
        }
      }

      .swiper-pagination {
        padding-bottom: 12px;
        ${(props) => props.isMobileRedesign && 'padding-bottom: 16px;'}
      }

      .swiper-pagination-bullet {
        margin: 0 12px;
        ${(props) => props.isMobileRedesign && 'margin: 0 16px;'}
        ${(props) => props.isMobileRedesign && 'border: solid 1px #fff;'}
        &.swiper-pagination-bullet-active {
          ${(props) => props.isMobileRedesign && 'background-color: #fff'}
        }
        &:not(:last-of-type):after {
          width: calc(200% - 8px);
          left: calc(100% + 3px);
          ${(props) =>
            props.isMobileRedesign && 'border-bottom: 1px dashed #fff;'}
          ${(props) => props.isMobileRedesign && 'left: calc(100% + 7px);'}
        }
      }
      .parallax-bg {
      }
    }
  }
`;

export const HeaderContainer = styled.div<{ isMobileRedesign?: boolean }>`
  padding: 32px 32px 32px 152px;
  @media ${device.tabletSlider} {
    padding: 16px;
  }

  ${(props) =>
    props.isMobileRedesign &&
    `
    @media ${device.tabletSlider} {
      display: flex;
      align-items: center;
      gap: 8px;
      padding-top: 0;
    }
  `}
`;

export const SliderHeader = styled(TitleSlogan)`
  opacity: 0.8;
  margin-bottom: 4px;
`;

export const HeaderBox = styled.div<{ isMobileRedesign?: boolean }>`
  ${(props) =>
    props.isMobileRedesign &&
    `
    flex: 1;
  `}
`;

export const Headline = styled(Headline1)`
  @media ${device.tabletSlider} {
    font-size: 20px;
    line-height: 1.4;
  }
`;
