import health from '../../../assets/images/icons/infrastructure/infrastructure.health.png';
import education from '../../../assets/images/icons/infrastructure/infrastructure.education.png';
import catering from '../../../assets/images/icons/infrastructure/infrastructure.catering.png';
import leisure from '../../../assets/images/icons/infrastructure/infrastructure.leisure.png';
import shopping from '../../../assets/images/icons/infrastructure/infrastructure.shopping.png';

interface ICategoryMeta {
  title: string;
  icon: string;
}

const getTitle = (category: string): string => {
  switch (category) {
    case 'health':
      return 'infrastructure.health';
    case 'shopping':
      return 'infrastructure.shopping';
    case 'leisure':
      return 'infrastructure.leisure';
    case 'education':
      return 'infrastructure.education';
    case 'catering':
      return 'infrastructure.catering';
    default:
      return '';
  }
};

const getIcon = (category: string): string => {
  switch (category) {
    case 'health':
      return health;
    case 'shopping':
      return shopping;
    case 'leisure':
      return leisure;
    case 'education':
      return education;
    case 'catering':
      return catering;
    default:
      return '';
  }
};

const getInfrastructureMeta = (category: string): ICategoryMeta => {
  return {
    title: getTitle(category),
    icon: getIcon(category),
  };
};

export { getInfrastructureMeta };
