import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as SingleNeutral } from 'assets/streamline-light/users/natural-close-up-single-user-neutral/single-neutral.svg';

import { Modal, ModalText, ModalTitle } from '../../../common/components/modal';
import { CreatePasswordForm } from '../forms/create-password-form';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { device } from '../../../../style/theme';

interface ICreatePasswordModal {
  isOpen: boolean;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    height: 100%;
  }
`;

const Row = styled.div<{ margin?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${(props) => props.margin ?? '0 0 24px'};
`;

const CreatePasswordModal = ({ isOpen }: ICreatePasswordModal): JSX.Element => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  const mobileWrapperStyles = {
    width: '100%',
    height: '100%',
  };
  return (
    <Modal
      isOpen={isOpen}
      wrapperStyles={isMobileSize ? mobileWrapperStyles : {}}
    >
      <Container>
        <Row>
          <ModalTitle
            icon={SingleNeutral}
            text={t('modal.create-password.title')}
            iconPosition="left"
            textAlign="left"
            margin="0"
          />
        </Row>
        <Row margin="0 0 16px">
          <ModalText margin="0" text={t('modal.create-password.content')} />
        </Row>
        <CreatePasswordForm />
      </Container>
    </Modal>
  );
};

export { CreatePasswordModal };
