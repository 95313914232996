import { useTranslation } from 'react-i18next';
import { PuffLoader } from 'react-spinners';
import styled from 'styled-components';

import { ReactComponent as StopSign } from 'assets/streamline-light/interface-essential/alerts/stop-sign.svg';

import { usePropertiesQuery } from '../../../../services/graphql/enhanced';
import { device } from '../../../../style/theme';
import Icon from '../../../common/components/ui/icon';
import {
  Headline1,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { useAppSelector } from '../../../common/hooks';
import { OtherPropertyCard } from '../other-property-card';

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 0 calc((100% - 480px) / 2);
  overflow: overlay;
  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    width: 2px;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    width: 2px;
    border-radius: 2px;
  }
`;

const Title = styled(Headline1)`
  color: #a25757;
  @media ${device.tablet} {
    word-break: break-word;
  }
`;

const Paragraph = styled(ParagraphText)`
  margin: 16px 0 8px 0;
`;

const TitleIconContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  padding-bottom: 16px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 25px auto;
`;

const NoAccess = (): JSX.Element => {
  const { t } = useTranslation();
  const ownerId = useAppSelector((state) => state.auth.user?._id);
  const { data: userPropertiesData, isLoading } = usePropertiesQuery({
    ownerId,
  });

  return (
    <Container>
      <TitleIconContainer>
        <IconContainer>
          <Icon icon={StopSign} height={40} width={40} color="#a25757" />
        </IconContainer>
        <Title content={t('share-property.no-access.title')} />
      </TitleIconContainer>
      {isLoading && (
        <LoaderContainer>
          <PuffLoader
            color="#002849"
            loading
            size={75}
            speedMultiplier={0.75}
          />
        </LoaderContainer>
      )}
      {!isLoading && (userPropertiesData?.properties.length ?? 0) > 0 && (
        <>
          <Paragraph content={t('share-property.no-access.other-properties')} />
          {userPropertiesData?.properties?.map((property) => (
            <OtherPropertyCard key={property._id} property={property} />
          ))}
        </>
      )}
    </Container>
  );
};

export { NoAccess };
