import './services/i18n';

import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import { GlobalLoader } from './modules/common/components/ui/loaders/global-loader';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './store';
import ErrorView from './modules/common/components/error/error-view';

const onError = (error: Error) => console.error(error);
const onReset = () => window.location.reload();

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<GlobalLoader />}>
      <ErrorBoundary
        FallbackComponent={ErrorView}
        onError={onError}
        onReset={onReset}
      >
        {/* i18n needs the suspense to load up */}
        <Provider store={store}>
          <PersistGate loading={<GlobalLoader />} persistor={persistor}>
            <HelmetProvider>
              <Router>
                <App />
              </Router>
            </HelmetProvider>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </Suspense>
  </StrictMode>,
  document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
