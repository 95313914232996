import { memo } from 'react';
import styled from 'styled-components';

import { hexToRGB } from '../../utils/hex-to-rgb';

const CheckBoxWrapper = styled.div`
  position: relative;
  margin: 0 12px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
  transition: border-color 225ms ease;
  border: 1px solid ${(props) => hexToRGB(props.theme.blue, 0.5)};
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 2px;
    background-color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
    transition: background-color 225ms ease, margin 225ms ease;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 10px;
  width: 24px;
  height: 16px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    cursor: pointer;
    background: #ffffff;
    border-color: ${(props) => props.theme.blue};
    transition: border-color 225ms ease;
    &::after {
      margin-left: 10px;
      background-color: ${(props) => props.theme.blue};
      transition: background-color 225ms ease, margin 225ms ease;
    }
  }
`;

interface ISwitch {
  id?: string;
  onChange: (checked: boolean) => void;
  isChecked: boolean;
  className?: string;
}

const SwitchBase = ({
  onChange,
  isChecked,
  id,
  className,
}: ISwitch): JSX.Element => (
  <CheckBoxWrapper className={className}>
    <CheckBox
      checked={isChecked}
      onChange={(e) => onChange(e.target.checked)}
      id={id ?? 'checkbox'}
      type="checkbox"
    />
    <CheckBoxLabel htmlFor={id ?? 'checkbox'} />
  </CheckBoxWrapper>
);

const Switch = memo(SwitchBase);

export { Switch };
