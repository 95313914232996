import { isNumber } from 'lodash';

import { useAppSelector } from '../../../common/hooks';

interface IProps {
  activeStepProp?: number;
}

interface IReturn {
  title: string;
  header: string;
}

const useGetFinancingTitle = ({ activeStepProp }: IProps): IReturn => {
  const activeStep = useAppSelector((state) => state.financing.activeStep);
  switch (isNumber(activeStepProp) ? activeStepProp : activeStep) {
    case 0:
      return {
        title: 'financing-portal.form.first-step.title',
        header: 'financing-portal.form.first-step.header',
      };
    case 1:
      return {
        title: 'financing-portal.form.second-step.title',
        header: 'financing-portal.form.second-step.header',
      };
    case 2:
      return {
        title: 'financing-portal.form.third-step.title',
        header: 'financing-portal.form.third-step.header',
      };
    default:
      return { title: '', header: '' };
  }
};

export { useGetFinancingTitle };
