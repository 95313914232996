import { useTranslation } from 'react-i18next';

import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { PropertyCode } from '../../../../generated';
import { defaultTheme } from '../../../../style/theme';
import { extractPropertyTypeLabelAndIcon } from '../../../property/utils/property-type-normalizer';
import {
  BathroomIcon,
  GarageIcon,
  LandAreaIcon,
  LivingSpaceIcon,
  ParkingIcon,
  ApartmentIcon,
  HouseIcon,
  MultiFamilyHouseIcon,
  YearOfConstructionIcon,
} from '../icons';
import { RoomIcon } from '../icons/room';

const styles = StyleSheet.create({
  wrapper: {
    width: '94%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'absolute',
    flexWrap: 'wrap',
    borderRadius: '3px',
    backgroundColor: '#fff',
    bottom: '-30mm',
    left: '16mm',
    overflow: 'hidden',
    border: '1px solid #d9d9d9',
    padding: '12px',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 23%',
    maxWidth: '23%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: '12px',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  valueText: {
    fontSize: '9px',
    fontWeight: 'semibold',
    lineHeight: 1.4,
    color: defaultTheme.blue,
    marginLeft: '12px',
  },
  clarificationText: {
    opacity: 0.6,
    fontSize: '8px',
    fontWeight: 'bold',
    lineHeight: 1.5,
    letterSpacing: '1px',
    color: defaultTheme.blue,
    marginTop: '6px',
  },
  superscriptText: {
    fontSize: '6px',
    marginBottom: '10px',
  },
});

const IconBox = ({
  type,
  yearOfConstruction,
  bathroom,
  garage,
  landArea,
  livingSpace,
  parking,
  roomsNumber,
}: {
  type?: PropertyCode;
  yearOfConstruction: number;
  bathroom: number;
  garage: number;
  landArea: number;
  livingSpace: number;
  parking: number;
  roomsNumber: number;
}): JSX.Element => {
  const { t } = useTranslation();
  const { label } = extractPropertyTypeLabelAndIcon(type);
  return (
    <View style={styles.wrapper}>
      <View style={styles.infoWrapper}>
        <View style={styles.iconWrapper}>
          {type === PropertyCode.Apartment && <ApartmentIcon />}
          {type === PropertyCode.House && <HouseIcon />}
          {type === PropertyCode.MultiFamilyHouse && <MultiFamilyHouseIcon />}
          <Text style={styles.valueText}>{t(label)}</Text>
        </View>
        <View>
          <Text style={styles.clarificationText}>Typ</Text>
        </View>
      </View>
      <View style={styles.infoWrapper}>
        <View style={styles.iconWrapper}>
          <YearOfConstructionIcon />
          <Text style={styles.valueText}>{yearOfConstruction}</Text>
        </View>
        <View>
          <Text style={styles.clarificationText}>Baujahr</Text>
        </View>
      </View>
      <View style={styles.infoWrapper}>
        <View style={styles.iconWrapper}>
          <LivingSpaceIcon />
          <Text style={styles.valueText}>{livingSpace} m</Text>
          <Text style={styles.superscriptText}>2</Text>
        </View>
        <View>
          <Text style={styles.clarificationText}>Wohnfläche</Text>
        </View>
      </View>
      <View style={styles.infoWrapper}>
        <View style={styles.iconWrapper}>
          <LandAreaIcon />
          <Text style={styles.valueText}>{landArea} m</Text>
          <Text style={styles.superscriptText}>2</Text>
        </View>
        <View>
          <Text style={styles.clarificationText}>Grundstücksfläche</Text>
        </View>
      </View>
      <View style={styles.infoWrapper}>
        <View style={styles.iconWrapper}>
          <RoomIcon />
          <Text style={styles.valueText}>{roomsNumber}</Text>
        </View>
        <View>
          <Text style={styles.clarificationText}>Zimmer</Text>
        </View>
      </View>
      <View style={styles.infoWrapper}>
        <View style={styles.iconWrapper}>
          <BathroomIcon />
          <Text style={styles.valueText}>{bathroom}</Text>
        </View>
        <View>
          <Text style={styles.clarificationText}>Badezimmer</Text>
        </View>
      </View>
      <View style={styles.infoWrapper}>
        <View style={styles.iconWrapper}>
          <GarageIcon />
          <Text style={styles.valueText}>{garage}</Text>
        </View>
        <View>
          <Text style={styles.clarificationText}>Garage</Text>
        </View>
      </View>
      <View style={styles.infoWrapper}>
        <View style={styles.iconWrapper}>
          <ParkingIcon />
          <Text style={styles.valueText}>{parking}</Text>
        </View>
        <View>
          <Text style={styles.clarificationText}>Stellplatz</Text>
        </View>
      </View>
    </View>
  );
};

export { IconBox };
