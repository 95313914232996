import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { usePropertyQuery } from '../../../../../generated';
import { SocialEconomics } from '../../../../common/components/social-economics/social-economics';
import { useMapSocialEconomics } from './useMapSociatlEconomics';

const SocialEconomicsPropertyWrapper: FC = () => {
  const { propertyId } = useParams<{ propertyId: string }>();

  const { data: socioEconomicsData, location } = usePropertyQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data }) => {
        const socioEconomics = data?.property?.socioEconomics;
        return {
          data: socioEconomics,
          location: data?.property?.propertyData?.location,
        };
      },
    }
  );
  useMapSocialEconomics({ location });

  return <SocialEconomics socioEconomicsData={socioEconomicsData} />;
};

export { SocialEconomicsPropertyWrapper };
