import { ReactComponent as Bin2 } from 'assets/streamline-light/interface-essential/delete/bin-2.svg';
import { ReactComponent as Pencil1 } from 'assets/streamline-light/interface-essential/edit/pencil-1.svg';
import { ReactComponent as Notifications } from 'assets/streamline-light/interface-essential/alert/alarm-bell.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { device } from 'style/theme';
import { Tooltip } from '../../../common/components/tooltip';

import { TooltipConfirmation } from '../../../common/components/tooltip/TooltipConfirmation';
import { IconButton } from '../../../common/components/ui/buttons';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import {
  toggleIsEditSearchProfileModalOpen,
  toggleIsNotificationModalOpen,
} from '../../../forms/form-search-profile/redux/searchProfileSlice';
import { useRemoveSearchProfileMutation } from '../../../../services/graphql/enhanced';
import { siteMap } from '../../../../routes/site-map';

interface IProps {
  searchProfileId: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 24px;
  flex: 1;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  @media ${device.tablet} {
    gap: 8px;
    > *:not(.__react_component_tooltip) {
      width: 33%;
      margin: 0 !important;
      button {
        width: 100%;
        height: 40px;
      }
      [data-tip] {
        width: 100%;
      }
    }
    *:not(.__react_component_tooltip) {
      max-width: none !important;
    }

    .tootlip-confirmation {
      left: 16px !important;
      ::before,
      ::after {
        left: 32px !important;
      }
    }
  }
`;

const TooltipHeader = styled.h4`
  font-family: 'Source Serif Pro', serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

const VariationHeaderActions = ({ searchProfileId }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lngHPush = useLngHistoryPush();

  const openEditModal = () => {
    dispatch(toggleIsEditSearchProfileModalOpen(true));
  };

  const openNotificationModal = () => {
    dispatch(toggleIsNotificationModalOpen(true));
  };

  const [removeSearchProfile, { isLoading }] = useRemoveSearchProfileMutation();

  const handleDeleteSearchProfile = async () => {
    if (searchProfileId) {
      await removeSearchProfile({ id: searchProfileId }).unwrap();
      lngHPush(siteMap.BuyerLandingPage.path);
    }
  };

  return (
    <Container>
      <TooltipConfirmation
        headerText={t('search-profile-delete.tooltip.title')}
        backText={t('search-profile-delete.tooltip.back')}
        acceptText={t('search-profile-delete.tooltip.accept')}
        id={'search-profile-delete'}
        onDelete={handleDeleteSearchProfile}
      >
        <Tooltip
          content={
            <IconButton
              iconColor={'#4E73F5'}
              iconColorHover={'#ffffff'}
              buttonColorHover={'#4E73F5'}
              border={'2px solid #4E73F5'}
              buttonColor={'transparent'}
              id={'delete-search-profile-btn'}
              icon={Bin2}
              isLoading={isLoading}
            />
          }
          id={'search-profile-navigation.delete.tooltip'}
          maxWidth={32}
        >
          <TooltipHeader>
            {t('search-profile-navigation.delete.tooltip')}
          </TooltipHeader>
        </Tooltip>
      </TooltipConfirmation>
      <Tooltip
        content={<IconButton icon={Pencil1} onClick={openEditModal} />}
        id={'search-profile-navigation.edit.tooltip'}
        maxWidth={32}
      >
        <TooltipHeader>
          {t('search-profile-navigation.edit.tooltip')}
        </TooltipHeader>
      </Tooltip>
      <Tooltip
        content={
          <IconButton icon={Notifications} onClick={openNotificationModal} />
        }
        id={'search-profile-navigation.notifications.tooltip'}
        maxWidth={32}
      >
        <TooltipHeader>
          {t('search-profile-navigation.notifications.tooltip')}
        </TooltipHeader>
      </Tooltip>
    </Container>
  );
};

export { VariationHeaderActions };
