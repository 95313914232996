import { FC } from 'react';
import {
  FinancingLpSection,
  FinancingType,
  StepByStepBenefits,
  useReadStepByStepBenefitQuery,
} from '../../../../../generated';
import {
  Container,
  Img,
  ContentBox,
  Subtitle,
  Title,
  SubtitleMobile,
  TitleMobile,
  BlueBox,
  InfoBox,
  IconsContainer,
  IconBox,
  IconBoxMobile,
  Icon,
  Poll,
  TextContainer,
  TextTitle,
  ExpandedText,
  CTAButton,
} from './financing-benefit-styles';
import { useOpenFinancing } from '../../../../forms/form-financing/hooks/callback-hooks/useOpenFinancing';

interface IProps {
  section: FinancingLpSection;
}

interface FinancingBenefitData {
  data: {
    readStepByStepBenefit: StepByStepBenefits;
  };
  isLoading: boolean;
}

const FinancingBenefit: FC<IProps> = ({ section: { recordIds } }) => {
  const { data, isLoading } =
    useReadStepByStepBenefitQuery<FinancingBenefitData>({ id: recordIds?.[0] });
  const { openFinancing } = useOpenFinancing({});

  if (isLoading || !data || !data.readStepByStepBenefit) return null;

  const {
    readStepByStepBenefit: {
      subtitle,
      title,
      step1IconUrl,
      step1IconAlt,
      step1Title,
      step1Text,
      step2IconUrl,
      step2IconAlt,
      step2Title,
      step2Text,
      step3IconUrl,
      step3IconAlt,
      step3Title,
      step3Text,
      CTALabel,
      imageUrl,
      imageAlt,
    },
  } = data;

  return (
    <Container>
      <SubtitleMobile>{subtitle}</SubtitleMobile>
      <TitleMobile>{title}</TitleMobile>
      <Img src={imageUrl || ''} alt={imageAlt || ''} />
      <ContentBox>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
        <BlueBox>
          <InfoBox>
            <IconsContainer>
              <IconBox>
                <Icon alt={step1IconAlt || ''} src={step1IconUrl || ''} />
              </IconBox>
              <Poll />
              <IconBox>
                <Icon alt={step2IconAlt || ''} src={step2IconUrl || ''} />
              </IconBox>
              <Poll />
              <IconBox>
                <Icon alt={step3IconAlt || ''} src={step3IconUrl || ''} />
              </IconBox>
            </IconsContainer>
            <TextContainer>
              <IconBoxMobile>
                <Icon alt={step1IconAlt || ''} src={step1IconUrl || ''} />
              </IconBoxMobile>
              <TextTitle>{step1Title}</TextTitle>
              <ExpandedText>{step1Text}</ExpandedText>
              <IconBoxMobile>
                <Icon alt={step2IconAlt || ''} src={step2IconUrl || ''} />
              </IconBoxMobile>
              <TextTitle>{step2Title}</TextTitle>
              <ExpandedText>{step2Text}</ExpandedText>
              <IconBoxMobile>
                <Icon alt={step3IconAlt || ''} src={step3IconUrl || ''} />
              </IconBoxMobile>
              <TextTitle>{step3Title}</TextTitle>
              <ExpandedText>{step3Text}</ExpandedText>
            </TextContainer>
          </InfoBox>
          <CTAButton
            label={CTALabel || ''}
            onClick={() => openFinancing(FinancingType.Financing)}
          />
        </BlueBox>
      </ContentBox>
    </Container>
  );
};

export default FinancingBenefit;
