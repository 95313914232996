import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { AddressBar } from '../../../../common/components/ui/hero-slider/address-bar';
import { MapBoxContainer } from './mapbox-styles';
import { Mapbox } from '../../../../map/components/mapbox';
import { useAppSelector } from '../../../../common/hooks';
import { usePropertyQuery } from '../../../../../generated';
import { implodeAddress } from '../../../utils/address';

const MapboxContainer: FC = () => {
  const { propertyId } = useParams<{ propertyId: string }>();

  const isFullScreenMapOpen = useAppSelector(
    (state) => state.dynamicMapUtils.isFullScreenMapOpen
  );
  const { address: propertyAddress } = usePropertyQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data }) => {
        const { location } = data?.property?.propertyData || {};
        const { address } = location || {};

        return {
          address: implodeAddress(address),
        };
      },
    }
  );

  return (
    <MapBoxContainer isFullScreen={isFullScreenMapOpen}>
      <Mapbox isVisible />
      <div
        style={{
          height: '64px',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          opacity: 0.9,
        }}
      >
        <AddressBar show address={propertyAddress} />
        {/* {!isFullScreenMapOpen && ( */}
        {/*   <AddressBarButton onClick={onRoute} label={'ROUTE'} /> */}
        {/* )} */}
      </div>
    </MapBoxContainer>
  );
};

export { MapboxContainer };
