import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const ParkingIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={'M.5 12a11.5 11.5 0 1 0 23 0 11.5 11.5 0 1 0-23 0z'}
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={'M14.5 10.5a2 2 0 0 1-2 2h-2v-4h2a2 2 0 0 1 2 2zm-4 6v-4'}
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { ParkingIcon };
