import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const ContentWithPadding = styled.div`
  padding: 48px 48px 0 48px;

  > div:first-child {
    margin-bottom: 28px;
  }

  > div:last-of-type {
    margin-top: 64px;
  }

  @media ${device.tablet} {
    padding: 0;
    > div:first-child {
      margin-bottom: 16px;
    }

    > div:not(:first-child) {
      margin-left: 16px;
      margin-right: 16px;
    }

    > div:last-of-type {
      margin-top: 16px;
    }
  }
`;

export const IFrameContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  cursor: pointer;
  width: 12px;
  height: 12px;
`;

export const ValuationBox = styled.div`
  position: absolute;
  top: 24px;
  right: 0;

  @media ${device.tablet} {
    position: static;
    padding-bottom: 42px;
  }
`;
