import { CSSProperties, FunctionComponent, useContext, MouseEvent } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { hexToRGB } from '../../../utils/hex-to-rgb';
import { BaseButton } from './base-button';
import { ButtonLoader } from './button-loader';
import Icon from '../icon';

interface IProps {
  label: string;
  onClick: (ev: MouseEvent<HTMLButtonElement>, ...args: any[]) => void;
  color?: string;
  borderColor?: string;
  backgroundColor?: string;
  highlightColor?: string;
  fluid?: boolean;
  loader?: boolean;
  centered?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  icon?: FunctionComponent;
  iconStyle?: CSSProperties;
  style?: CSSProperties;
  type?: 'submit' | 'button';
}

const SecondaryButtonBase = styled(BaseButton)<Omit<IProps, 'label'>>`
  display: flex;
  align-items: center;
  position: relative;
  color: ${(props) => props.color ?? props.theme.ctaBlue};
  background: ${(props) => props.backgroundColor ?? '#fff'};
  border: solid 2px ${(props) => props.borderColor ?? props.theme.ctaBlue};
  transition: background-color 225ms ease, padding 225ms ease !important;
  padding: 12px 24px;
  justify-content: center;
  text-transform: uppercase;
  &:hover {
    background-color: ${(props) =>
      hexToRGB(
        props.highlightColor ?? props.borderColor ?? props.theme.ctaBlue,
        0.2
      )};
  }
  ${(props) =>
    props.isDisabled &&
    `
    pointer-events: none;
    cursor: default;
    opacity: 0.45;
  `}
  ${(props) =>
    props.isLoading &&
    `padding-right: 3.6rem !important; pointer-events: none !important;`}
`;

const SecondaryButton = ({
  label,
  loader,
  icon,
  iconStyle,
  ...rest
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);

  return (
    <SecondaryButtonBase {...rest}>
      {loader && (
        <ButtonLoader
          isLoading={rest.isLoading ?? false}
          isDisabled={rest.isDisabled ?? false}
          color={rest.color ?? themeContext.ctaBlue}
          disabledColor={themeContext.ctaBlue}
        />
      )}
      {icon && (
        <Icon
          style={{
            margin: '0 12px 0 0',
            ...iconStyle,
          }}
          icon={icon}
          height={24}
          width={24}
          color={rest.color}
        />
      )}
      {label}
    </SecondaryButtonBase>
  );
};

export { SecondaryButton };
