import { FC, memo } from 'react';
import GalleryModal from '../../../pages/expose-details-overlay/components/slider/full-screen-gallery/gallery-modal';
import { useAppSelector } from '../../../../common/hooks';
import { setActiveSlide } from '../../../pages/expose-details-overlay/redux/exposeSlice';

interface IProps {
  images: string[];
  isFloorPlanGallery?: boolean;
}

const ServiceOverlayBase: FC<IProps> = ({
  images,
  isFloorPlanGallery = false,
}) => {
  const activeSlide = useAppSelector(
    (state) => state.sellerExposeDetails.activeSlide
  );

  return (
    <GalleryModal
      images={images}
      activeSlide={activeSlide}
      setActiveSlide={setActiveSlide}
      isFloorPlanGallery={isFloorPlanGallery}
    />
  );
};

const ServiceOverlay = memo(ServiceOverlayBase);

export { ServiceOverlay };
