import styled from 'styled-components';
import { device } from '../../../../style/theme';
import {
  BoxTitle,
  Headline1,
  Headline2,
  TitleSlogan,
} from '../../../common/components/ui/typography';
import { LngLinkContainer } from '../../../common/components/ui/typography/link';
import Icon from '../../../common/components/ui/icon';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  @media ${device.tablet} {
    padding: 0;
    align-items: center;
    width: auto;
    margin-bottom: 16px;
  }
`;

export const HeaderContainer = styled.div<{ isStatic?: boolean }>`
  width: 100%;
  text-align: center;
  padding: 0 32px 32px;
  @media ${device.tablet} {
    padding: 16px;
  }

  ${(props) =>
    props.isStatic &&
    `
    padding: 0 0 48px;
  `}
`;

export const SliderHeader = styled(TitleSlogan)`
  opacity: 0.8;
  margin-bottom: 4px;
`;

export const Headline = styled(Headline1)`
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const ContentContainer = styled.div<{
  marginBottom: number;
  isStatic?: boolean;
}>`
  position: relative;
  width: 100%;
  margin-bottom: ${(props) => props.marginBottom}px;
  @media ${device.tablet} {
    margin: 0;
  }

  ${(props) =>
    props.isStatic &&
    `
    position: static;
    display: flex;
    gap: 48px;

    @media ${device.tablet} {
      flex-direction: column;
      gap: 0;
    }
  `}
`;

export const ImageBox = styled.div<{
  revertContent?: boolean;
  isStatic?: boolean;
}>`
  max-width: 632px;
  margin-left: ${({ revertContent }) => (revertContent ? 'auto' : 0)};
  @media ${device.tablet} {
    margin: 0 auto;
  }

  ${(props) =>
    props.isStatic &&
    `
    max-width: 535px;
  `}
`;

export const InfoBox = styled.div<{
  revertContent?: boolean;
  isStatic?: boolean;
}>`
  margin-top: 116px;
  position: absolute;
  ${({ revertContent }) => (revertContent ? 'left' : 'right')}: 0;
  z-index: ${({ revertContent }) => (revertContent ? -1 : 0)};
  top: 0;
  max-width: 632px;
  padding: 48px;
  background-color: ${({ theme }) => theme.logoBlue};
  @media ${device.tablet} {
    position: relative;
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 16px 24px;
  }

  ${(props) =>
    props.isStatic &&
    `
    position: static;
    max-width: 536px;
    margin-top: 0;
    padding: 0;
    background: transparent;
  `}
`;

export const Title = styled(Headline2)<{ isStatic?: boolean }>`
  margin: 0 0 16px 0;
  color: #ffffff;
  @media ${device.tablet} {
    text-align: center;
  }

  ${(props) =>
    props.isStatic &&
    `
    color: #00305E;
  `}
`;

export const Description = styled.div<{ isStatic?: boolean }>`
  margin: 0 0 16px 0;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: #ffffff !important;
  background-color: transparent !important;
  li {
    margin-left: 1.5em !important;
  }
  ul {
    margin-top: 24px;
  }
  p,
  span {
    background-color: transparent !important;
    color: #ffffff !important;
  }

  * {
    font-family: Roboto !important;
  }

  ${(props) =>
    props.isStatic &&
    `
    color: #00305E !important;
    margin: 0 0 24px 0;

    p,
    span {
      color: #00305E !important;
    }
  `}
`;

export const BoxButtons = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    justify-content: center;
  }
  @media ${device.mobile} {
    flex-direction: column-reverse;
  }
`;

export const LinkWrapper = styled(LngLinkContainer)`
  margin-left: 24px;
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    margin-left: 0;
    margin-bottom: 16px;
  }
  :hover {
    opacity: 0.6;
  }
`;

export const LinkIconWrapper = styled(Icon)<{ isStatic?: boolean }>`
  margin-right: 8px;

  ${(props) =>
    props.isStatic &&
    `
    color: #00305E !important;

    & svg, & path {
      stroke: #00305E !important;
    }
  `}
`;

export const LinkBox = styled(BoxTitle)<{ isStatic?: boolean }>`
  color: #fff;
  letter-spacing: 1px;
  opacity: 0.8;
  @media ${device.mobile} {
    font-size: 10px;
    line-height: 1.6;
  }

  ${(props) =>
    props.isStatic &&
    `
    color: #00305E !important;
  `}
`;

export const StyledImg = styled.img<{ isStatic?: boolean }>`
  width: 100%;
  height: 100%;
  max-height: 472px;
  object-fit: cover;

  ${(props) =>
    props.isStatic &&
    `
    max-height: 335px;
    object-fit: contain;

    @media ${device.mobile} {
      margin-left: -30px;
    }
  `}
`;

export const SliderContainer = styled.div`
  @media ${device.tablet} {
    margin-top: 12px;
  }
`;

export const Content = styled.div<{ isSlider?: boolean; isStatic?: boolean }>`
  ${(props) =>
    props.isSlider &&
    `
    @media ${device.tablet} {
      margin: 0 0 24px 0 !important;
    }
  `}

  ${(props) =>
    props.isStatic &&
    `
    background: #F3F4F7;
    position: relative;
    padding: 32px 0;
    margin-bottom: 28px;

    &:before {
      content: '';
      width: 100%;
      background: #F3F4F7;
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
    }

    &:after {
      content: '';
      width: 100%;
      background: #F3F4F7;
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
    }
  `}
`;
