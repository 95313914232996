import { FC, memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { ModalHeader } from '../../../../components/fullscreen-modal/modal-header/modal-header';
import { ProgressBar } from '../../../../components/fullscreen-modal/progress-bar/progress-bar';
import {
  Container,
  FormContainer,
} from '../../../../components/fullscreen-modal/styles';
import { useSubmitFormSP } from '../../../hooks/useSubmitFormSP';
import { UsefulInfo } from '../../../../components/fullscreen-modal/useful-info/useful-info';
import { FORM_STEPS, usefulInfoKeyPhrases } from '../../../constants';
import { useUsefulSearchProfileContainerData } from '../../../hooks/useGetSearchProfileFormInfo';
import { useModalButtonsHandler } from '../../../hooks/useModalButtonsHandler';
import { SearchProfileForm } from '../search-profile-form/search-profile-form';
import { useConvertSP } from '../../../hooks/useConvertSP';

const SearchProfileFormContainer: FC<{
  onSubmitSPForm: ReturnType<typeof useSubmitFormSP>['onSubmitSPForm'];
  // second value of useLazySuggestedPropertiesQuery()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialSuggestedPropertiesData: any;
}> = ({ onSubmitSPForm, initialSuggestedPropertiesData }) => {
  const isMobileSize = useIsMobileSize();
  const { activeStep, isIframe, isSPCOpen, isNoModal } =
    useUsefulSearchProfileContainerData();
  const { reset, watch } = useFormContext();
  const { onBackClick, onCloseClick } = useModalButtonsHandler({
    reset,
    watch,
  });

  const temporarySearchProfileToClaim = useAppSelector(
    (state) => state.searchProfile.temporarySearchProfileToClaim
  );

  function onBeforeUnload(event: Event) {
    event.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-return-assign
    return (event.returnValue = '');
  }

  useEffect(() => {
    if (isSPCOpen) {
      // eslint-disable-next-line unicorn/prefer-add-event-listener
      window.addEventListener('beforeunload', onBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isSPCOpen]);

  useConvertSP();

  const isShowHeader = !isIframe && isMobileSize;

  return (
    <Container
      noProgressBar={activeStep > 1}
      isIframe={isIframe}
      isOverflowVisible={isNoModal}
      isOverflowXHidden={isMobileSize}
    >
      {isShowHeader && (
        <ModalHeader
          activeStep={activeStep}
          isUpperCase={!isMobileSize}
          onClose={onCloseClick}
          onBackClick={onBackClick}
          title={'search-profile.info-container.title'}
        />
      )}
      <FormContainer>
        <SearchProfileForm initialSuggestedPropertiesData={initialSuggestedPropertiesData} onSubmitSPForm={onSubmitSPForm} />
      </FormContainer>
      {activeStep < 2 && (
        <ProgressBar activeStep={activeStep} formSteps={FORM_STEPS} />
      )}
      <UsefulInfo
        keyPhrases={usefulInfoKeyPhrases}
        isShort={!isSPCOpen}
        isIframe={isIframe}
      />
    </Container>
  );
};

export { SearchProfileFormContainer };
