import { FC } from 'react';
import {
  OwnerLpSection,
  useReadVideoQuery,
  ValuationType,
  VideoSection,
} from '../../../../../generated';
import { MainButton } from '../../../../common/components/ui/buttons';
import { ParagraphText } from '../../../../common/components/ui/typography';
import {
  ButtonWrap,
  ButtonWrapMobile,
  Headline,
  VideoContainer,
  VideoContainerBody,
  VideoContent,
  VideoContentLeft,
  VideoContentRight,
  VideoHeader,
  VideoPlayer,
} from './video-styles';

interface IProps {
  section: OwnerLpSection;
  handleEvaluationWizardOpen: (type: ValuationType) => void;
}

interface VideoResponse {
  data: { data: VideoSection };
  isLoading: boolean;
}

function getEmbeddedUrl(url: string): string {
  return url
    .replace('youtu.be', 'youtube.com')
    .replace('youtube.com/', 'youtube.com/embed/')
    .replace('.be/', '.be/embed/');
}

const Video: FC<IProps> = ({ handleEvaluationWizardOpen, section }) => {
  const { recordIds } = section;

  const { data, isLoading } = useReadVideoQuery<VideoResponse>({
    id: recordIds[0] || '',
  });

  if (isLoading) return null;

  const video = data?.data;

  return (
    <VideoContainer>
      <VideoContainerBody>
        <VideoHeader content={(video?.subtitle || '').toUpperCase()} />
        {(video?.title || '').split('\n').map((item, index) => {
          if (item) {
            return <Headline key={index} content={item} />;
          }

          return null;
        })}
        <VideoContent>
          <VideoContentLeft>
            {(video?.text || '').split('\n').map((item, index) => {
              if (item) {
                return <ParagraphText key={index} content={item} />;
              }

              return null;
            })}
            <ButtonWrap>
              <MainButton
                style={{ textTransform: 'uppercase', marginTop: '8px' }}
                label={video?.ctaLabel || ''}
                onClick={() => {
                  handleEvaluationWizardOpen(ValuationType.Online);
                }}
              />
            </ButtonWrap>
          </VideoContentLeft>
          <VideoContentRight>
            <VideoPlayer>
              <iframe
                src={getEmbeddedUrl(video?.videoURL || '')}
                title="Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </VideoPlayer>
            <ButtonWrapMobile>
              <MainButton
                style={{
                  textTransform: 'uppercase',
                  marginTop: '16px',
                  justifyContent: 'center',
                }}
                label={video?.ctaLabel || ''}
                onClick={() => {
                  handleEvaluationWizardOpen(ValuationType.Online);
                }}
              />
            </ButtonWrapMobile>
          </VideoContentRight>
        </VideoContent>
      </VideoContainerBody>
    </VideoContainer>
  );
};

export { Video };
