import 'rc-collapse/assets/index.css';

import Collapse, { Panel } from 'rc-collapse';
import { CollapsePanelProps } from 'rc-collapse/lib/interface';
import { memo, useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as ArrowDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';

import { FaqSection } from '../../../../../generated';
import { device } from '../../../../../style/theme';
import Icon from '../icon';
import { Headline2 } from '../typography';
import motion from './utils';

interface IProps {
  accordionTitle?: string;
  accordionIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  accordionSections: FaqSection[];
}

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  > i {
    margin-right: 20px;
    > svg {
      > path:first-of-type {
        stroke: ${(props) => props.theme.ctaBlue};
      }
    }
  }
  @media ${device.tablet} {
    margin-bottom: 16px;
    > i {
      margin-right: 8px;
      width: 32px;
      height: 32px;
    }
  }
`;

const Title = styled(Headline2)``;

const Container = styled.div`
  &&& {
    .rc-collapse {
      border: none !important;
      background: transparent;

      .rc-collapse-item {
        border: none !important;
        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
        .faq-header {
          transition: border-radius 500ms ease, background-color 500ms ease;
          will-change: border-radius, background-color;
          padding: 14px 24px;
          border-radius: 3px;
          cursor: pointer;
          margin: 0;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: 1px;
          color: ${(props) => props.theme.blue};
          background-color: ${(props) => props.theme.faqHeader};

          .icon {
            margin-left: auto;
            order: 2;
            flex: 0 0 auto;
            path {
              stroke-width: 1.5px;
            }
          }
        }
        &.rc-collapse-item-active {
          .faq-header {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background-color: ${(props) => props.theme.faqActiveHeader};
          }
        }
        .rc-collapse-content {
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          background: ${(props) => props.theme.faqContent};
          overflow: hidden;
          color: ${(props) => props.theme.blue};
          padding: 0;
          margin: 0;
          .rc-collapse-content-box {
            margin: 0;
            padding: 24px;
            opacity: 0.6;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: 0.5px;
            color: ${(props) => props.theme.blue};
            @media ${device.tablet} {
              padding: 12px;
              font-size: 12px;
              line-height: 1.67;
            }
          }
        }
      }
    }
  }
`;

const ContentText = styled.div`
  white-space: pre-wrap;

  *{
    font-family: 'Roboto' !important;
    font-size: 14px !important;
    color: #00305e !important;
    line-height: 24px !important;
    letter-spacing: 0.5px !important;
    background: transparent !important;
    background-color: transparent !important;
  }
`;

const FaqAccordionBase = ({
  accordionTitle,
  accordionIcon,
  accordionSections,
}: IProps) => {
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    const faq = document.querySelector('#FAQ');
    if (faq && window.location.hash === '#FAQ') {
      setTimeout(() => {
        faq.scrollIntoView({ behavior: 'smooth' });
      }, 600);
    }
  }, []);

  return (
    <Container id={'FAQ'}>
      {accordionTitle && (
        <TitleContainer>
          {accordionIcon && (
            <Icon color="#000" width={40} height={40} icon={accordionIcon} />
          )}
          <Title content={accordionTitle} />
        </TitleContainer>
      )}
      <Collapse
        accordion
        openMotion={motion}
        className="faq-accordion"
        defaultActiveKey={accordionSections[0]._id}
        expandIcon={({ isActive }: CollapsePanelProps) => {
          return (
            <Icon
              width={16}
              height={16}
              icon={ArrowDown}
              color={themeContext.blue}
              style={{
                transition: `transform 500ms ease`,
                transform: isActive ? 'rotate(-180deg)' : 'none',
              }}
            />
          );
        }}
      >
        {accordionSections?.map((aSection) => (
          <Panel
            key={aSection._id}
            header={aSection.title}
            headerClass="faq-header"
            className="faq-content"
          >
            <ContentText dangerouslySetInnerHTML={{ __html: aSection.expandedText }} />
          </Panel>
        ))}
      </Collapse>
    </Container>
  );
};

const FaqAccordion = memo(FaqAccordionBase);

export { FaqAccordion };
