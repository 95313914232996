import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { locales } from '../../../localization/constants';

interface IProps {
  path: string;
  lang: string;
}

const HelmetContainer: FC<IProps> = ({ path, lang }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('landing-page.meta.title')}</title>
        <meta name="title" content={t('landing-page.meta.title') ?? ''} />
        <meta
          name="description"
          content={t('landing-page.meta.description') ?? ''}
        />
        <meta
          property="og:title"
          content={t('landing-page.meta.title') ?? ''}
        />
        <meta
          property="og:description"
          content={t('landing-page.meta.description') ?? ''}
        />
        {locales.map(({ hrefLang, locale }) => (
          <link
            key={path}
            rel="alternate"
            href={`https://home.von-poll.com/${locale}${path}`}
            hrefLang={hrefLang}
          />
        ))}
        <link
          rel="canonical"
          href={`https://home.von-poll.com/${lang}${path}`}
        />
      </Helmet>
    </>
  );
};

export { HelmetContainer };
