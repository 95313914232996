import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -4px;
  > a {
    min-width: 120px;
    margin: 0 4px;
  }
`;

const SimpleNavigation = (): JSX.Element => {
  return (
    <Container>
      {/* <LinkButton to="/" $hasActiveIndicator={false}>
        {t('simple-header.navigation.property')}
      </LinkButton>
      <LinkButton to="/" $hasActiveIndicator={false}>
        {t('simple-header.navigation.online-propery-valuation')}
      </LinkButton>
      <LinkButton to="/" $hasActiveIndicator={false}>
        {t('simple-header.navigation.companies')}
      </LinkButton> */}
    </Container>
  );
};

export { SimpleNavigation };
