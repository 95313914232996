import { useCallback, useContext, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CheckCircle } from 'assets/streamline-light/interface-essential/form-validation/check-circle-1.svg';
import {
  Checkbox,
  InputOutsideTopLabel,
  InputOutsideTopLabelWithSelect,
} from '../../../common/components/form';
import { FormColumn, FormRow } from '../../../common/components/form/form-grid';
import { InputType } from '../../../auth/interfaces';
import { phonePrefixes } from '../../../auth/constants';
import Select from '../../../common/components/form/select/select';
import * as S from '../../../common/components/form/input/input-outside-top-label-styled';
import { IPersonalDetails } from '../../interfaces';
import { BUYER_INFO_FORM_KEYS } from '../../constants';
import Icon from '../../../common/components/ui/icon';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import {
  useUpdateUserProfileMutation,
  useCreateExposeRequestMutation,
  ExposeStatus,
} from '../../../../generated';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { setUser } from '../../../auth/redux/authSlice';
import {
  Form,
  SubmitButton,
  LastFormRow,
  Header,
  Subtitle,
  Label,
  SelectWrapper,
} from './request-expose-page-styles';
import { MainButton } from '../../../common/components/ui/buttons';

const RequestExposePage = (): JSX.Element => {
  const methods = useForm<IPersonalDetails>({
    mode: 'onChange',
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const searchProfileId = urlParams.get('searchProfileId') || '';
  const financingId = urlParams.get('financingId') || '';

  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const themeContext = useContext(ThemeContext);
  const { propertyId } = useParams<{ propertyId: string }>();

  const userInfo = useAppSelector((state) => state.auth.user);
  const [selectedSalutation, setSelectedSalutation] = useState<'Frau' | 'Herr'>(
    (userInfo?.salutation as 'Frau' | 'Herr') ?? 'Herr'
  );

  const [
    updateUserProfile,
    { isLoading: isUserProfileUpdateLoading, error: updateUserProfileError },
  ] = useUpdateUserProfileMutation();

  const [
    createExposeRequest,
    { error: createExposeRequestError, isLoading: isExposeRequestLoading },
  ] = useCreateExposeRequestMutation();

  const onSubmit: SubmitHandler<IPersonalDetails> = useCallback(
    async (formData) => {
      const { phone, phonePrefix, email, number, ...rest } = formData;
      const input = {
        ...rest,
        salutation: selectedSalutation,
        number: +number,
      };
      try {
        const updateUserProfileResponse = await updateUserProfile({
          input,
        }).unwrap();
        dispatch(setUser(updateUserProfileResponse?.updateUserProfile));
        await createExposeRequest({
          input: {
            externalPropertyId: propertyId,
            ownerId: userInfo?._id ?? '',
            // propertyId: +propertyId,
            status: ExposeStatus.Pending,
            ...(searchProfileId ? { searchProfileId } : {}),
            ...(financingId ? { financingId } : {}),
          },
          emailTemplate: {
            propertyLink: `https://smart.onoffice.de/smart/smart.php?forceLanguage=DEU&modul_name=daten&inhalt=grunddaten&datensatz=`,
            salutation: selectedSalutation,
            firstName: formData.name ?? '',
            lastName: formData.surname ?? '',
            city: formData.city,
            street: formData.street,
            number: +number,
            phone: userInfo?.phone ?? '',
            email: userInfo?.email ?? '',
            propertyId,
            buyerPortalId: userInfo?._id ?? '',
            needFinancingConsultation: formData?.needFinancingConsultation,
            isPropertyOwner: formData?.isPropertyOwner,
            intendToSellProperty: formData?.intendToSellProperty,
          },
        }).unwrap();
        setShowSuccessMessage(true);
      } catch (error) {
        console.error(error);
      }
    },
    [
      createExposeRequest,
      dispatch,
      propertyId,
      searchProfileId,
      financingId,
      selectedSalutation,
      updateUserProfile,
      userInfo?._id,
      userInfo?.email,
      userInfo?.name,
      userInfo?.phone,
      userInfo?.surname,
    ]
  );

  const onSelect = useCallback((value) => setSelectedSalutation(value), []);

  return (
    <>
      <Helmet>
        <title>{t('buyer-portal.title')}</title>
      </Helmet>
      {showSuccessMessage ? (
        <div>
          <Icon
            icon={CheckCircle}
            color={themeContext.green}
            width={40}
            height={40}
            style={{ marginBottom: '16px' }}
          />
          <Header
            content={t('request-expose-page.success.title')}
            color={themeContext.green}
          />
          <Subtitle content={t('request-expose-page.success.subtitle')} />
          <MainButton
            label={t('request-expose-page.button.go-back')}
            onClick={() =>
              push(
                searchProfileId
                  ? `/search-profile/${searchProfileId}`
                  : `/finanzieren/${financingId}`
              )
            }
          />
        </div>
      ) : (
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            {updateUserProfileError?.message && (
              <GlobalError
                title={t(updateUserProfileError?.message?.split(':')[0])}
              />
            )}
            {createExposeRequestError?.message && (
              <GlobalError
                title={t(createExposeRequestError?.message?.split(':')[0])}
              />
            )}
            <Header content={t('search-profile.personal-details.form.title')} />
            <Subtitle
              content={t('search-profile.personal-details.form.subtitle')}
            />
            <FormRow>
              <FormColumn flex="0 0 50%">
                <S.Label htmlFor={'id'} $hasError={false}>
                  {`${t('search-profile.personal-details.form.salutation')}*`}
                </S.Label>
                <SelectWrapper>
                  <Select
                    selectedOption={selectedSalutation}
                    options={[
                      { label: 'Herr', value: 'Herr' },
                      { label: 'Frau', value: 'Frau' },
                    ]}
                    onSelect={onSelect}
                  />
                </SelectWrapper>
              </FormColumn>
              <FormColumn flex="0 0 50%">
                <InputOutsideTopLabel
                  name={BUYER_INFO_FORM_KEYS.NAME}
                  type="text"
                  label="profile-edit.input.label.name"
                  placeholder={t('profile-edit.input.placeholder.name')}
                  rules={{
                    required: 'register.input.error.required',
                  }}
                  defaultValue={userInfo?.name ?? ''}
                  hasDisclaimer
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn flex="0 0 50%">
                <InputOutsideTopLabel
                  name={BUYER_INFO_FORM_KEYS.SURNAME}
                  type="text"
                  label="profile-edit.input.label.surname"
                  placeholder="profile-edit.input.placeholder.surname"
                  rules={{ required: 'register.input.error.required' }}
                  defaultValue={userInfo?.surname ?? ''}
                  hasDisclaimer
                />
              </FormColumn>
              <FormColumn flex="0 0 50%">
                <InputOutsideTopLabel
                  name={BUYER_INFO_FORM_KEYS.CITY}
                  type="text"
                  label="ort"
                  placeholder="ort"
                  rules={{ required: 'register.input.error.required' }}
                  defaultValue={userInfo?.city ?? ''}
                  hasDisclaimer
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn flex="0 0 80%">
                <InputOutsideTopLabel
                  name={BUYER_INFO_FORM_KEYS.STREET}
                  type="text"
                  label="valuation.form.first-step.street"
                  placeholder="valuation.form.first-step.street-placeholder"
                  rules={{ required: 'register.input.error.required' }}
                  defaultValue={userInfo?.street ?? ''}
                  hasDisclaimer
                />
              </FormColumn>
              <FormColumn flex="0 0 20%">
                <InputOutsideTopLabel
                  name={BUYER_INFO_FORM_KEYS.NUMBER}
                  type="number"
                  label="valuation.form.first-step.number-placeholder"
                  placeholder="valuation.form.first-step.number-placeholder"
                  rules={{
                    required: 'register.input.error.required',
                    valueAsNumber: true,
                  }}
                  defaultValue={userInfo?.number?.toString() ?? ''}
                  hasDisclaimer
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <InputOutsideTopLabelWithSelect
                  type={InputType.number}
                  name={BUYER_INFO_FORM_KEYS.PHONE}
                  label="register.form.input.label.phone"
                  placeholder="register.form.input.placeholder.phone"
                  rules={{
                    required: false,
                  }}
                  selectWidth="87px"
                  hasSelect
                  selectOptions={phonePrefixes}
                  selectValue={
                    phonePrefixes.find(
                      (prefix) => prefix === `+${userInfo?.prefix}`
                    ) ?? phonePrefixes[0]
                  }
                  selectName={BUYER_INFO_FORM_KEYS.PHONE_PREFIX}
                  defaultValue={
                    userInfo?.phone.replace(`+${userInfo?.prefix}`, '') ?? ''
                  }
                  isDisabled
                  hasDisclaimer
                />
              </FormColumn>
              <FormColumn>
                <InputOutsideTopLabel
                  type={InputType.text}
                  name={BUYER_INFO_FORM_KEYS.EMAIL}
                  label="register.form.input.label.email"
                  placeholder="register.form.input.placeholder.email"
                  rules={{
                    required: false,
                  }}
                  defaultValue={userInfo?.email ?? ''}
                  isDisabled
                  hasDisclaimer
                />
              </FormColumn>
            </FormRow>
            <Label>
              {t('search-profile.personal-details.form.general-info')}
            </Label>
            <FormRow>
              <FormColumn flex="0 0 100%">
                <Checkbox
                  name={BUYER_INFO_FORM_KEYS.FINANCING_CONSULTATION}
                  label={
                    'search-profile.personal-details.form.financing-consultation'
                  }
                />
              </FormColumn>
              <FormColumn flex="0 0 100%">
                <Checkbox
                  name={BUYER_INFO_FORM_KEYS.IS_PROPERTY_OWNER}
                  label={
                    'search-profile.personal-details.form.is-property-owner'
                  }
                />
              </FormColumn>
              <FormColumn flex="0 0 100%">
                <Checkbox
                  name={BUYER_INFO_FORM_KEYS.INTEND_TO_SELL_PROPERTY}
                  label={
                    'search-profile.personal-details.form.intend-to-sell-property'
                  }
                />
              </FormColumn>
            </FormRow>
            <LastFormRow>
              <FormColumn flex="0 0 100%">
                <SubmitButton
                  fluid
                  loader
                  isDisabled={!methods.formState.isValid}
                  label={t(
                    'search-profile.personal-details.form.submit-button'
                  )}
                  isLoading={
                    isUserProfileUpdateLoading || isExposeRequestLoading
                  }
                />
              </FormColumn>
            </LastFormRow>
          </Form>
        </FormProvider>
      )}
    </>
  );
};

export { RequestExposePage };
