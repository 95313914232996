import { FC, memo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputV2 } from '../../../../../../../../components/common/input-v2/input-v2';
import { Separator } from '../../../separator/separator';
import { numberFormat } from '../../../../../../../../../financing/utils/numberFormat';
import { InputWithCalculateValue } from '../../../input-with-calculate-value/input-with-calculate-value';
import { Switch } from '../../../../../../../../components/common/switch/switch';
import { FIELDS, FINANCING_FORM_KEYS } from '../../../../../../../constants';
import {
  InputWrapper,
  PaddingBox,
  SwitchBox,
} from '../../../financing-data-edit-form-styles';
import { Collapsible } from '../../../../../../../../../common/components/ui/collapsible';
import { IFinancingForm } from '../../../../../../../interface';

export const regionsOfGermany = [
  {
    label: 'Baden-Württemberg',
    value: 'Baden-Württemberg',
    index: '70173',
    code: '08',
  },
  { label: 'Bayern', value: 'Bayern', index: '80331', code: '09' },
  { label: 'Berlin', value: 'Berlin', index: '10117', code: '11' },
  { label: 'Brandenburg', value: 'Brandenburg', index: '14467', code: '12' },
  { label: 'Bremen', value: 'Bremen', index: '28195', code: '04' },
  { label: 'Hamburg', value: 'Hamburg', index: '20095', code: '02' },
  { label: 'Hessen', value: 'Hessen', index: '34117', code: '06' },
  {
    label: 'Niedersachsen',
    value: 'Niedersachsen',
    index: '30159',
    code: '03',
  },
  {
    label: 'Mecklenburg-Vorpommern',
    value: 'Mecklenburg-Vorpommern',
    index: '18055',
    code: '13',
  },
  {
    label: 'Nordrhein-Westfalen',
    value: 'Nordrhein-Westfalen',
    index: '40213',
    code: '05',
  },
  {
    label: 'Rheinland-Pfalz',
    value: 'Rheinland-Pfalz',
    index: '55116',
    code: '07',
  },
  { label: 'Saarland', value: 'Saarland', index: '66111', code: '10' },
  { label: 'Sachsen', value: 'Sachsen', index: '04109', code: '14' },
  {
    label: 'Sachsen-Anhalt',
    value: 'Sachsen-Anhalt',
    index: '06108',
    code: '15',
  },
  {
    label: 'Schleswig-Holstein',
    value: 'Schleswig-Holstein',
    index: '24103',
    code: '01',
  },
  { label: 'Thüringen', value: 'Thüringen', index: '07743', code: '16' },
];

interface IProps {
  finData: IFinancingForm;
  landTax: number;
  isOwnConstruction?: boolean;
  isWithBroker: boolean;
  setWithBroker: (state: boolean) => void;
  totalTax: number;
  onChangeTax: (name: string, cb?: () => void) => (value: number) => void;
  setDirtyTrue(): void;
  onChangePurchasePrice?(value: number | string): void;
}

const PurchasePriceAndAdditionalCostsBase: FC<IProps> = ({
  landTax,
  isOwnConstruction,
  isWithBroker,
  setWithBroker,
  finData,
  totalTax,
  onChangeTax,
  setDirtyTrue,
  onChangePurchasePrice,
}) => {
  const { t } = useTranslation();
  const [landPrice, purchasePrice, equityCapital, buildingCosts] = useWatch({
    name: [
      FINANCING_FORM_KEYS.LAND_PRICE,
      FINANCING_FORM_KEYS.PURCHASE_PRICE,
      FINANCING_FORM_KEYS.EQUITY_CAPITAL,
      FINANCING_FORM_KEYS.BUILDING_COSTS,
    ],
  });

  return (
    <>
      {!isOwnConstruction ? (
        <InputV2
          name="purchasePrice"
          type="number"
          label="financing-portal-page.tabs.calculator.edit.inputs.purchase-price.label"
          placeholder="financing-portal-page.tabs.calculator.edit.inputs.purchase-price.placeholder"
          inputAdornment="€"
          defaultValue={finData?.purchasePrice}
          isSeparate
          isValidateOnBlur
          isLabelSaturated
          isOldDesign
          validateNames={
            equityCapital
              ? [
                  FINANCING_FORM_KEYS.PURCHASE_PRICE,
                  FINANCING_FORM_KEYS.EQUITY_CAPITAL,
                ]
              : undefined
          }
          rules={{
            ...FIELDS.PURCHASE_PRICE.rules,
            validate: (value: number) => {
              return Number(value) >= Number(equityCapital)
                ? true
                : t(
                    'financing-portal.form.inputs.purchase-price.errors.equity-capital-value'
                  ).toString();
            },
          }}
          handleChangeProp={(value: number | string) => {
            if (onChangePurchasePrice) {
              onChangePurchasePrice(Number(value));
            }
            setDirtyTrue();
          }}
        />
      ) : (
        <>
          <InputV2
            {...FIELDS.BUILDING_COSTS}
            // defaultValue={finData?.buildingCosts}
            handleChangeProp={() => {
              setDirtyTrue();
            }}
            tooltipWidth={180}
            isRequired={false}
            isSeparate
            isValidateOnBlur
            isLabelSaturated
            isOldDesign
            isTooltipKeyLeftSide
            isBoldTooltipKeyIcon
            validateNames={
              landPrice && equityCapital
                ? [
                    FINANCING_FORM_KEYS.BUILDING_COSTS,
                    FINANCING_FORM_KEYS.LAND_PRICE,
                    FINANCING_FORM_KEYS.EQUITY_CAPITAL,
                  ]
                : undefined
            }
            rules={{
              ...FIELDS.BUILDING_COSTS.rules,
              validate: (value: number) => {
                return Number(value) + Number(landPrice) <
                  +Number(equityCapital)
                  ? t(
                      'financing-portal.form.inputs.building-costs.errors.equity-capital-value'
                    ).toString()
                  : true;
              },
            }}
          />
          <InputWrapper content="+" mb={12}>
            <InputV2
              {...FIELDS.LAND_PRICE}
              // defaultValue={finData?.landPrice}
              handleChangeProp={() => {
                setDirtyTrue();
              }}
              tooltipWidth={300}
              isValidateOnBlur
              isRequired={false}
              isSeparate
              isLabelSaturated
              isOldDesign
              isTooltipKeyLeftSide
              isBoldTooltipKeyIcon
              validateNames={
                buildingCosts && equityCapital
                  ? [
                      FINANCING_FORM_KEYS.BUILDING_COSTS,
                      FINANCING_FORM_KEYS.LAND_PRICE,
                      FINANCING_FORM_KEYS.EQUITY_CAPITAL,
                    ]
                  : undefined
              }
              rules={{
                ...FIELDS.LAND_PRICE.rules,
                validate: (value: number) => {
                  return Number(value) + Number(buildingCosts) <
                    Number(equityCapital)
                    ? t(
                        'financing-portal.form.inputs.land-price.errors.equity-capital-value'
                      ).toString()
                    : true;
                },
              }}
            />
          </InputWrapper>
        </>
      )}

      <InputWrapper content="+">
        <Separator
          label="financing-portal-page.tabs.calculator.edit.inputs.total-additional-costs.label"
          tooltipKey="financing-portal-page.tabs.calculator.edit.inputs.total-additional-costs.tooltip"
          value={`${numberFormat(totalTax)} €`}
          tooltipWidth={320}
        />
      </InputWrapper>
      <PaddingBox>
        <InputV2
          name="region"
          label="financing-portal-page.tabs.calculator.edit.inputs.federal-state.label"
          placeholder="financing-portal-page.tabs.calculator.edit.inputs.federal-state.placeholder"
          isOldDesign
          isDisabled
          handleChangeProp={setDirtyTrue}
        />
        <InputWithCalculateValue
          name="landTax"
          isDisabled
          isPercent
          isCounts
          label="financing-portal-page.tabs.calculator.edit.inputs.property-tax.label"
          placeholder="financing-portal-page.tabs.calculator.edit.inputs.property-tax.placeholder"
          isOwnConstruction={isOwnConstruction}
          purchasePrice={purchasePrice ?? 0}
          landPrice={landPrice ?? 0}
          defaultValue={landTax}
          onChangePrice={onChangeTax('landTax')}
          onChangeProp={onChangeTax('landTax', setDirtyTrue)}
        />
        <SwitchBox>
          <Switch
            name="brokerageFee"
            label="financing-portal-page.tabs.calculator.edit.inputs.with-broker.label"
            defaultValue={!!finData.brokerageFee}
            onChangeProp={(value) => {
              setWithBroker(value);
              setDirtyTrue();
            }}
          />
        </SwitchBox>
        <Collapsible
          isOpen={isWithBroker}
          initHeight={isWithBroker ? 40 : 0}
          mt={!isWithBroker ? -12 : 0}
        >
          <InputWithCalculateValue
            name="brokerFeePercent"
            label="financing-portal-page.tabs.calculator.edit.inputs.brokerage-fee.label"
            placeholder="financing-portal-page.tabs.calculator.edit.inputs.brokerage-fee.placeholder"
            isOwnConstruction={isOwnConstruction}
            purchasePrice={purchasePrice ?? 0}
            landPrice={landPrice ?? 0}
            isDisabled={!isWithBroker}
            isPercent
            isCounts={isWithBroker}
            defaultValue={finData.brokerFeePercent || 3.57}
            onChangePrice={onChangeTax('brokerFeePercent')}
            onChangeProp={onChangeTax('brokerFeePercent', setDirtyTrue)}
            rules={FIELDS.BROKER_FEE_PERCENT.rules}
          />
        </Collapsible>
        <InputWithCalculateValue
          name="notary"
          label="financing-portal-page.tabs.calculator.edit.inputs.notary.label"
          placeholder="financing-portal-page.tabs.calculator.edit.inputs.notary.placeholder"
          isOwnConstruction={isOwnConstruction}
          purchasePrice={purchasePrice ?? 0}
          landPrice={landPrice ?? 0}
          isDisabled
          isCounts
          isPercent
          defaultValue={1.2}
          onChangePrice={onChangeTax('notary')}
          onChangeProp={onChangeTax('notary', setDirtyTrue)}
        />
        <InputWithCalculateValue
          name="landRegisterEntry"
          label="financing-portal-page.tabs.calculator.edit.inputs.land-register-entry.label"
          placeholder="financing-portal-page.tabs.calculator.edit.inputs.land-register-entry.placeholder"
          isOwnConstruction={isOwnConstruction}
          purchasePrice={purchasePrice ?? 0}
          landPrice={landPrice ?? 0}
          isCounts
          isPercent
          isDisabled
          defaultValue={0.6}
          onChangePrice={onChangeTax('landRegisterEntry')}
          onChangeProp={onChangeTax('landRegisterEntry', setDirtyTrue)}
          rules={FIELDS.BROKER_FEE_PERCENT.rules}
        />
      </PaddingBox>
    </>
  );
};

const PurchasePriceAndAdditionalCosts = memo(
  PurchasePriceAndAdditionalCostsBase
);

export { PurchasePriceAndAdditionalCosts };
