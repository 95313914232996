import styled from 'styled-components';
import { ReactNode } from 'react';

interface IProps {
  content: string | ReactNode;
}

const Text = styled.p`
  margin: 0;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.regularText};
`;

const Container = styled.div`
  margin: 0;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.regularText};
`;

const BoxText = ({ content, ...rest }: IProps): JSX.Element => (
  <>
    {typeof content === 'string' ? (
      <Text {...rest}>{content}</Text>
    ) : (
      <Container {...rest}>{content}</Container>
    )}
  </>
);

export { BoxText };
