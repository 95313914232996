import styled from 'styled-components';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import {
  Headline3,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { device } from '../../../../style/theme';

export const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  margin: 12px 0;

  @media ${device.tablet} {
    gap: 8px;
  }
`;

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 3px;
  position: relative;
  width: 100%;

  &::before {
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid ${(props) => props.theme.borderColor};
    left: -7px;
    top: 14px;
    position: absolute;
    transform: rotate(45deg);
    border-right-color: transparent;
    border-top-color: transparent;
    background-color: white;
  }
`;

export const UserAvatarPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  margin: 1px 12px 0 0;
  padding: 12px 0;
  border-radius: 3px;
  background-color: ${(props) => hexToRGB(props.theme.darkGrey, 0.3)};

  font-family: 'Source Serif Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
  color: ${(props) => props.theme.blue};

  @media ${device.tablet} {
    min-width: 36px;
    min-height: 36px;
    max-width: 36px;
    max-height: 36px;
    font-size: 14px;
  }
`;

export const CommentText = styled.p`
  margin: 0;
  font-family: 'Roboto' !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  font-size: 12px;
  line-height: 1.33;
`;

export const CommentDate = styled(ParagraphText)`
  font-size: 10px;
  font-weight: 900;
  line-height: 1.6;
  opacity: 0.5;

  @media ${device.mobile} {
    font-size: 9px;
  }
`;

export const CommentTitle = styled(Headline3)`
  font-size: 16px;
  line-height: 1.25;

  @media ${device.mobile} {
    font-size: 14px;
    display: inline-block;
    overflow: hidden;
    width: 110px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Row = styled.div<{
  justifyContent?: string;
  margin?: string;
  alignItems?: string;
  padding?: string;
  isColumn?: boolean;
  gap?: number;
}>`
  ${({ isColumn }) => isColumn && 'flex-direction: column'};
  display: flex;
  align-self: stretch;
  position: relative;
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};

  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};

  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ gap }) => gap && `gap: ${gap}px`};
`;
