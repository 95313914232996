import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatNumber } from 'modules/property/utils/format-number';

import { device } from '../../../../../style/theme';
import {
  Headline3,
  ParagraphTitle,
  TitleSlogan,
} from '../../../../common/components/ui/typography';
import { abbrNum } from '../../../../common/utils/number-scientific-notation';
import { useCurrency } from '../../../hooks/useCurrency';
import { percentageDifference } from '../../../utils/percentage-difference';

const PriceContainer = styled.div<{
  backgroundColor?: string;
  borderColor?: string;
}>`
  display: block;
  padding: 12px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.borderColor || props.theme.lightBlue};
  overflow: hidden;
  flex: 0 1 32%;
  text-align: start;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.greyBackground};
  @media ${device.tablet} {
    padding: 20px;
    margin: 4px 0;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const Price = styled(Headline3)<{
  invertColors?: boolean;
  color?: 'green' | 'yellow' | 'red';
}>`
  font-weight: 600;
  line-height: 1.2;
  ${({ invertColors, color, theme }) =>
    invertColors &&
    `
    color: ${
      color === 'green'
        ? theme.green
        : color === 'yellow'
        ? theme.gold
        : theme.red
    };
  `}

  span {
    font-size: 14px;
  }
`;

const PriceDifference = styled(TitleSlogan)<{ invertColors?: boolean }>`
  line-height: 1.33;
  color: ${(props) => props.theme.red};
  ${({ invertColors, theme }) => invertColors && `color: ${theme.blue};`}
`;

const OldPrice = styled(Headline3)`
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.5px;
  text-decoration: line-through;
  font-weight: 600;
  opacity: 0.6;
`;

const PriceAdditionalDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const PricePercentage = styled(TitleSlogan)<{
  priceChangePercentageColor?: string;
}>`
  line-height: 1.33;
  color: ${(props) => props.priceChangePercentageColor || props.theme.red};
`;

const Date = styled(TitleSlogan)`
  white-space: nowrap;
  opacity: 0.5;
  font-size: 10px;
  line-height: 1.6;
`;

const AdditionalInfo = styled(ParagraphTitle)`
  font-size: 12px;
  opacity: 0.8;
  line-height: 1.67;
  font-weight: bold;
  letter-spacing: normal;
`;

interface IPriceRangeElementProps {
  priceRangeLow: number;
  priceRangeHigh: number;
  priceLabel: string;
  priceChangePercentage: string;
}
const PriceRangeElement = ({
  priceRangeLow,
  priceRangeHigh,
  priceLabel,
  priceChangePercentage,
}: IPriceRangeElementProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PriceContainer>
      <Row>
        <Price
          content={`${abbrNum(priceRangeLow, 1)}-${abbrNum(
            priceRangeHigh,
            1
          )} €`}
        />
        <PriceAdditionalDetails>
          <PricePercentage content={priceChangePercentage} />
          <Date content="VS Q4`20" />
        </PriceAdditionalDetails>
      </Row>
      <Row>
        <AdditionalInfo content={t(priceLabel ?? '')} />
      </Row>
    </PriceContainer>
  );
};

interface ISalePriceElementProps {
  salePrice?: string;
  priceLabel: string;
  priceChangePercentage?: number;
  oldPrice?: number | string;
  oldPriceLow?: number;
  oldPriceHigh?: number;
  date?: string;
  priceRangeLow?: number;
  priceRangeHigh?: number;
  startPrice?: number;
  endPrice?: number;
  backgroundColor?: string;
  borderColor?: string;
  priceChangePercentageColor?: string;
  invertColors?: boolean;
}

const SalePriceElement = ({
  salePrice,
  priceLabel,
  priceChangePercentage,
  oldPrice,
  oldPriceLow,
  oldPriceHigh,
  date,
  priceRangeLow = 0,
  priceRangeHigh = 0,
  startPrice = 0,
  endPrice = 0,
  backgroundColor,
  borderColor,
  priceChangePercentageColor,
  invertColors,
}: ISalePriceElementProps): JSX.Element => {
  const { t } = useTranslation();
  const isIncreasing = startPrice < endPrice;
  const difference = percentageDifference(startPrice, endPrice);
  const priceCurrency = useCurrency();

  return (
    <PriceContainer backgroundColor={backgroundColor} borderColor={borderColor}>
      <Row>
        <AdditionalInfo content={t(priceLabel ?? '')} />
      </Row>
      <Row>
        {salePrice && <Price content={`${salePrice} ${priceCurrency}`} />}
        {!!startPrice && !!endPrice && (
          <Price
            content={`${isIncreasing ? '+' : '-'}${difference}%`}
            invertColors={invertColors}
            color={
              !isIncreasing
                ? 'red'
                : difference > 1
                ? 'green'
                : difference > 0 && difference < 1
                ? 'yellow'
                : 'red'
            }
          />
        )}
        {!!priceRangeLow && !!priceRangeHigh && (
          <Price
            content={
              <>
                {`${formatNumber(priceRangeLow)} ${priceCurrency}`}
                {' - '}
                {`${formatNumber(priceRangeHigh)} ${priceCurrency}`}
              </>
            }
          />
        )}
        <PriceAdditionalDetails>
          {(!!priceChangePercentage || priceChangePercentage === 0) && (
            <PricePercentage
              priceChangePercentageColor={priceChangePercentageColor}
              content={
                priceChangePercentage > 0
                  ? `+${priceChangePercentage}%`
                  : `${priceChangePercentage}%`
              }
            />
          )}
          {!!date && <Date content={date} />}
          {!!startPrice && !!endPrice && (
            <PriceDifference
              invertColors={invertColors}
              content={`${isIncreasing ? '+' : '-'}${abbrNum(
                Math.abs(startPrice - endPrice)
              )}${priceCurrency}`}
            />
          )}
        </PriceAdditionalDetails>
      </Row>
      {!!oldPrice && (
        <Row>
          <OldPrice content={`${oldPrice}${priceCurrency}`} />
        </Row>
      )}
      {!!oldPriceLow && !!oldPriceHigh && (
        <OldPrice
          content={`${abbrNum(oldPriceLow)}-${abbrNum(
            oldPriceHigh
          )}${priceCurrency}`}
        />
      )}
    </PriceContainer>
  );
};

interface ISquareMeterPriceElementProps {
  squareMeterPrice: string;
  priceLabel: string;
  priceChangePercentage: string;
}
const SquareMeterPriceElement = ({
  squareMeterPrice,
  priceLabel,
  priceChangePercentage,
}: ISquareMeterPriceElementProps): JSX.Element => {
  const { t } = useTranslation();
  const priceCurrency = useCurrency();

  return (
    <PriceContainer>
      <Row>
        <Price content={`${squareMeterPrice}${priceCurrency}`} />
        <PriceAdditionalDetails>
          <PricePercentage content={priceChangePercentage} />
          <Date content="VS Q4`20" />
        </PriceAdditionalDetails>
      </Row>
      <Row>
        <AdditionalInfo content={t(priceLabel ?? '')} />
      </Row>
    </PriceContainer>
  );
};

export { PriceRangeElement, SalePriceElement, SquareMeterPriceElement };
