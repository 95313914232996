import { useCallback, useRef, useEffect, useState, FC, useMemo } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  FieldPath,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  PropertyCode,
  SearchProfileData,
  useSearchProfileQuery,
} from '../../../../../../generated';
import { useUpdateSearchProfileMutation } from '../../../../../../services/graphql/enhanced';
import { Blurry } from '../../../../../common/components/ui/blurry';
import {
  MainButton,
  SecondaryButton,
} from '../../../../../common/components/ui/buttons';
import { ExpandableContent } from '../../../../../common/components/ui/expandable-content';
import { GlobalLoader } from '../../../../../common/components/ui/loaders/global-loader';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import {
  toggleIsEditSearchProfileModalOpen,
  updateFormStateAction,
} from '../../../redux/searchProfileSlice';
import {
  setInitialLoader,
  setSuggestedProperties,
} from '../../../../../search-profile/redux/suggestedPropertiesSlice';
import { Input } from '../../../../components/common/input/input';
import { MobileBack } from '../../common/mobile-back';
import {
  FormWrapper,
  StyledFormGridWrapperFixHeight,
  FormGridOuterWrapper,
  FormButtonsWrapper,
  Row,
} from './edit-search-profile-form-styles';
import {
  REDESIGNED_SEARCH_CRITERIA,
  // SEARCH_CRITERIA,
  SEARCH_PROFILE_FORM_KEYS,
} from '../../../constants';
import { ISearchProfileForm, TSearchProfileKey } from '../../../interface';
import { ContainerInputRadius } from '../search-profile-form/steps/first-step/first-step-styles';
import { InputRange } from '../../../../components/common/input-range/input-range';
import { SelectPropertyCode } from '../../common/select-property-code';
import { InputCityAddress } from '../../common/input-city-address';
import { useValidateSecondStepFields } from '../../../hooks/useValidateSecondStepFields';
import { Feature } from '../../../../../../services/mapbox/interfaces';
import { parseLocation } from '../../../../utils/parse-location';
import { removeNonNumeric } from '../../../../utils/number-format';
import { LngRedirect } from '../../../../../localization/lng-redirect';
import { siteMap } from '../../../../../../routes/site-map';

const EditSearchProfileForm: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();

  const methods = useForm<ISearchProfileForm>();
  const { watch } = methods;

  const { searchProfileId } = useParams<{ searchProfileId: string }>();
  const formGridWrapperRef = useRef<HTMLDivElement>(null);

  const [invalidAddress, setInvalidAddress] = useState(false);

  const selectedAddress = useAppSelector(
    (state) => state.searchProfile.selectedAddress
  );

  const closeModal = useCallback(
    () => dispatch(toggleIsEditSearchProfileModalOpen(false)),
    [dispatch]
  );

  const {
    isLoading: isSearchProfileDataLoading,
    searchProfileData,
    title,
  } = useSearchProfileQuery(
    {
      id: searchProfileId,
    },
    {
      selectFromResult: ({ data, isLoading }) => {
        return {
          searchProfileData: data?.searchProfile
            ?.searchProfileData as SearchProfileData,
          title: data?.searchProfile?.title,
          isLoading,
        };
      },
    }
  );

  const watchedCode = methods.watch('code', searchProfileData?.propertyCode);

  const [watchedPlaceName, setWatchedPlaceName] = useState(
    searchProfileData?.location.address.placeName
  );
  // const [watchedPurchasePriceMin, setWatchedPurchasePriceMin] =
  //   useState<string>(String(searchProfileData?.purchasePriceMin));
  const [watchedPurchasePriceMax, setWatchedPurchasePriceMax] =
    useState<string>(String(searchProfileData?.purchasePriceMax));
  const [watchedLivingAreaMin, setWatchedLivingAreaMin] = useState(
    searchProfileData?.livingAreaMin || 0
  );
  // const [watchedLivingAreaMax, setWatchedLivingAreaMax] = useState(
  //   searchProfileData?.livingAreaMax || 1
  // );
  const [watchedLandAreaMin, setWatchedLandAreaMin] = useState(
    searchProfileData?.landAreaMin || 0
  );
  // const [watchedLandAreaMax, setWatchedLandAreaMax] = useState(
  //   searchProfileData?.landAreaMax || 1
  // );
  const [watchedNumberOfRoomsMin, setWatchedNumberOfRoomsMin] = useState(
    searchProfileData?.numberOfRoomsMin || 0
  );
  // const [watchedNumberOfRoomsMax, setWatchedNumberOfRoomsMax] = useState(
  //   searchProfileData?.numberOfRoomsMax || 1
  // );
  const [watchedNumberOfBathroomsMin, setWatchedNumberOfBathroomsMin] =
    useState(searchProfileData?.numberOfBathroomsMin || 0);
  // const [watchedNumberOfBathroomsMax, setWatchedNumberOfBathroomsMax] =
  //   useState(searchProfileData?.numberOfBathroomsMax || 1);

  useEffect(() => {
    watch((value) => {
      setWatchedPlaceName(value.placeName);
      // setWatchedPurchasePriceMin(value.purchasePriceMin);
      setWatchedPurchasePriceMax(value.purchasePriceMax);
      setWatchedLivingAreaMin(value.livingAreaMin || 0);
      // setWatchedLivingAreaMax(value.livingAreaMax || 1);
      setWatchedLandAreaMin(value.landAreaMin || 0);
      // setWatchedLandAreaMax(value.landAreaMax || 1);
      setWatchedNumberOfRoomsMin(value.numberOfRoomsMin || 0);
      // setWatchedNumberOfRoomsMax(value.numberOfRoomsMax || 1);
      setWatchedNumberOfBathroomsMin(value.numberOfBathroomsMin || 0);
      // setWatchedNumberOfBathroomsMax(value.numberOfBathroomsMax || 1);
    });
  }, [watch]);

  const [
    updateSearchProfile,
    { isLoading: isUpdateSearchProfileLoading, data: updateSearchProfileData },
  ] = useUpdateSearchProfileMutation();

  const availableOptions = useMemo(() => {
    if (searchProfileData) {
      return REDESIGNED_SEARCH_CRITERIA.filter((item) =>
        item.availableFor.includes(
          watchedCode || searchProfileData?.propertyCode || ''
        )
      ).map((item) => {
        return {
          ...item,
          defaultValues: {
            min:
              searchProfileData[item.nameMin as TSearchProfileKey] ??
              item.rules.min.value,
            max:
              searchProfileData[item.nameMax as TSearchProfileKey] ??
              item.rules.max.value,
          },
        };
      });
    }
    return [];
  }, [searchProfileData, watchedCode]);

  const isValid = useValidateSecondStepFields({
    // purchasePriceMin: watchedPurchasePriceMin,
    purchasePriceMax: watchedPurchasePriceMax,
    livingAreaMin: watchedLivingAreaMin,
    // livingAreaMax: watchedLivingAreaMax,
    landAreaMin: watchedLandAreaMin,
    // landAreaMax: watchedLandAreaMax,
    numberOfRoomsMin: watchedNumberOfRoomsMin,
    // numberOfRoomsMax: watchedNumberOfRoomsMax,
    numberOfBathroomsMin: watchedNumberOfBathroomsMin,
    // numberOfBathroomsMax: watchedNumberOfBathroomsMax,
  });

  const onSubmit: SubmitHandler<ISearchProfileForm> = useCallback(
    async (formData) => {
      const isApartment = watchedCode === PropertyCode.Apartment;
      const isHouse = watchedCode === PropertyCode.House;
      const isMultiFamilyHouse = watchedCode === PropertyCode.MultiFamilyHouse;
      const isPlot = watchedCode === PropertyCode.Plot;

      if (!isValid) {
        return;
      }

      // Add validation of the fields
      let validationFields = [
        SEARCH_PROFILE_FORM_KEYS.RADIUS,
        SEARCH_PROFILE_FORM_KEYS.PURCHASE_PRICE_MIN,
        SEARCH_PROFILE_FORM_KEYS.PURCHASE_PRICE_MAX,
      ];
      switch (watchedCode) {
        case PropertyCode.Apartment:
        case PropertyCode.House:
          validationFields = [
            ...validationFields,
            SEARCH_PROFILE_FORM_KEYS.LIVING_AREA_MIN,
            SEARCH_PROFILE_FORM_KEYS.LIVING_AREA_MAX,
            SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_ROOMS_MIN,
            SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_ROOMS_MAX,
            SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_BATHROOMS_MIN,
            SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_BATHROOMS_MAX,
          ];
          break;
        case PropertyCode.MultiFamilyHouse:
          validationFields = [
            ...validationFields,
            SEARCH_PROFILE_FORM_KEYS.LIVING_AREA_MIN,
            SEARCH_PROFILE_FORM_KEYS.LIVING_AREA_MAX,
          ];
          break;
        case PropertyCode.Plot:
          validationFields = [
            ...validationFields,
            SEARCH_PROFILE_FORM_KEYS.LAND_AREA_MIN,
            SEARCH_PROFILE_FORM_KEYS.LAND_AREA_MAX,
          ];
          break;
        default:
      }

      if (validationFields.length > 0) {
        const isFormValid = await methods.trigger(
          validationFields as FieldPath<ISearchProfileForm>[]
        );
        if (!isFormValid) {
          // TODO: If need show an error message
          return;
        }
      }

      if (searchProfileData) {
        const {
          propertyCode: code,
          radius,
          purchasePriceMin,
          purchasePriceMax,
          livingAreaMin,
          livingAreaMax,
          landAreaMin,
          landAreaMax,
          location: {
            address: { postCode, city, locality, neighborhood, placeName },
            coordinates,
          },
          numberOfBathroomsMin,
          numberOfBathroomsMax,
          numberOfRoomsMax,
          numberOfRoomsMin,
        } = searchProfileData;

        const {
          mapboxPostCode,
          mapboxCity,
          mapboxLocality,
          mapboxNeighborhood,
          mapboxPlaceName,
          latitude,
          longitude,
        } = parseLocation(selectedAddress as Feature);

        const purchasePriceMinInt = Number(
          removeNonNumeric(formData.purchasePriceMin)
        );
        const purchasePriceMaxInt = Number(
          removeNonNumeric(formData.purchasePriceMax)
        );
        const isTitleIncludesCity =
          !!selectedAddress && city !== mapboxCity && title?.includes(city);
        const newTitle =
          formData.title !== title
            ? formData.title
            : isTitleIncludesCity
            ? title.replace(city, mapboxCity)
            : title;

        const input = {
          title: newTitle,
          searchProfileData: {
            propertyCode: formData.code || code || PropertyCode.Apartment,
            radius: Number(formData.radius) || Number(radius),

            purchasePriceMin:
              Number(purchasePriceMinInt) || Number(purchasePriceMin),
            purchasePriceMax:
              Number(purchasePriceMaxInt) || Number(purchasePriceMax),

            livingAreaMin: isPlot
              ? undefined
              : Number(formData.livingAreaMin) || Number(livingAreaMin),
            livingAreaMax: isPlot
              ? undefined
              : Number(formData.livingAreaMax) || Number(livingAreaMax),

            landAreaMin:
              isApartment || isHouse || isMultiFamilyHouse
                ? undefined
                : Number(formData.landAreaMin) || Number(landAreaMin),
            landAreaMax:
              isApartment || isHouse || isMultiFamilyHouse
                ? undefined
                : Number(formData.landAreaMax) || Number(landAreaMax),

            numberOfRoomsMin:
              isMultiFamilyHouse || isPlot
                ? undefined
                : Number(formData.numberOfRoomsMin) || Number(numberOfRoomsMin),
            numberOfRoomsMax:
              isMultiFamilyHouse || isPlot
                ? undefined
                : Number(formData.numberOfRoomsMax) || Number(numberOfRoomsMax),

            numberOfBathroomsMin:
              isMultiFamilyHouse || isPlot
                ? undefined
                : Number(formData.numberOfBathroomsMin) ||
                  Number(numberOfBathroomsMin),
            numberOfBathroomsMax:
              isMultiFamilyHouse || isPlot
                ? undefined
                : Number(formData.numberOfBathroomsMax) ||
                  Number(numberOfBathroomsMax),
            location: {
              address: {
                neighborhood: selectedAddress
                  ? mapboxNeighborhood
                  : neighborhood || '',
                locality: selectedAddress ? mapboxLocality : locality || '',
                postCode: selectedAddress ? mapboxPostCode : postCode || '',
                city: selectedAddress ? mapboxCity : city || '',
                placeName: selectedAddress ? mapboxPlaceName : placeName || '',
              },
              coordinates: {
                latitude: latitude || coordinates.latitude || 0,
                longitude: longitude || coordinates.longitude || 0,
              },
            },
          },
        };

        await updateSearchProfile({
          input: {
            _id: searchProfileId,
            ...input,
          },
        });
        dispatch(setSuggestedProperties([]));
        dispatch(setInitialLoader(true));
        dispatch(updateFormStateAction(formData));
        closeModal();
      }
    },
    [
      watchedCode,
      isValid,
      searchProfileData,
      methods,
      selectedAddress,
      title,
      updateSearchProfile,
      searchProfileId,
      dispatch,
      closeModal,
    ]
  );

  if (isSearchProfileDataLoading || isUpdateSearchProfileLoading) {
    return (
      <StyledFormGridWrapperFixHeight>
        <GlobalLoader />
      </StyledFormGridWrapperFixHeight>
    );
  }

  // if user changed property type, redirect him to a new search profile created for this type
  if (
    updateSearchProfileData?.updateSearchProfile &&
    updateSearchProfileData.updateSearchProfile._id !== searchProfileId
  ) {
    return (
      <LngRedirect
        to={`${siteMap.SearchProfilePage.pathWithoutParams}/${updateSearchProfileData.updateSearchProfile._id}`}
      />
    );
  }

  const defaultAddress =
    searchProfileData?.location?.address?.placeName ||
    `${searchProfileData?.location?.address?.city} ${searchProfileData?.location?.address?.postCode}`;

  return (
    <>
      <MobileBack title={t('edit.search-profile.modal.title')} />
      <FormWrapper>
        <FormProvider {...methods}>
          <FormGridOuterWrapper>
            <StyledFormGridWrapperFixHeight
              maxHeight="480px"
              wrapperRef={formGridWrapperRef}
            >
              {!isMobileSize && (
                <Blurry
                  scrolledElementRef={formGridWrapperRef}
                  isReversed
                  isInitiallyVisible={false}
                />
              )}

              <ExpandableContent
                style={{ marginBottom: '8px' }}
                expanded
                title={t('edit.search-profile.section.title.title')}
              >
                <Row>
                  <Input
                    name={SEARCH_PROFILE_FORM_KEYS.TITLE}
                    defaultValue={title}
                    placeholder="edit.search-profile.section.title.title.placeholder"
                    rules={{
                      required: 'register.input.error.required',
                    }}
                  />
                </Row>
              </ExpandableContent>

              <ExpandableContent
                expanded
                title={t('edit.search-profile.section.title.details')}
              >
                <Row>
                  <SelectPropertyCode
                    defaultValue={searchProfileData?.propertyCode}
                  />
                </Row>
                <Row direction="column">
                  <InputCityAddress
                    placeName={watchedPlaceName || ''}
                    defaultValue={defaultAddress}
                    postCode={
                      searchProfileData?.location.address.postCode ?? ''
                    }
                    invalidAddress={invalidAddress}
                    setInvalidAddress={setInvalidAddress}
                  />
                  <ContainerInputRadius>
                    <Input
                      name={SEARCH_PROFILE_FORM_KEYS.RADIUS}
                      defaultValue={searchProfileData?.radius}
                      label="search-profile.form.first-step.radius"
                      placeholder="search-profile.form.first-step.radius"
                      type="number"
                      rules={{
                        required: 'register.input.error.required',
                        min: {
                          value: 1,
                          message:
                            'search-profile.form.first-step.radius-min-error',
                        },
                        max: {
                          value:
                            searchProfileData?.radius > 200
                              ? searchProfileData?.radius
                              : 200,
                          message:
                            'search-profile.form.first-step.radius-max-error',
                        },
                        valueAsNumber: true,
                        setValueAs: (v) => Number.parseInt(v, 10),
                      }}
                      inputAdornment="km"
                    />
                  </ContainerInputRadius>
                </Row>
                <Row direction="column">
                  {availableOptions.map((item) => (
                    <InputRange
                      key={item.typeName}
                      nameMin={item.nameMin}
                      nameMax={item.nameMax}
                      label={item.label}
                      subLabel={item.subLabel}
                      placeholderMin={item.placeholderMin}
                      placeholderMax={item.placeholderMax}
                      type="number"
                      defaultValues={item.defaultValues}
                      rules={item.rules}
                      inputAdornment={item.inputAdornment}
                      isSeparate={item.typeName === 'Kaufpreis'}
                      inputMode={'numeric'}
                    />
                  ))}
                </Row>
              </ExpandableContent>
              {!isMobileSize && (
                <Blurry
                  scrolledElementRef={formGridWrapperRef}
                  position={{ bottom: '0' }}
                />
              )}
            </StyledFormGridWrapperFixHeight>
          </FormGridOuterWrapper>

          <FormButtonsWrapper>
            <SecondaryButton
              label={t('modal.edit-valuation-modal.button-close')}
              onClick={closeModal}
            />
            <MainButton
              label={t('modal.edit-valuation-modal.button-save')}
              onClick={methods.handleSubmit(onSubmit)}
            />
          </FormButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </>
  );
};

export { EditSearchProfileForm };
