import { ReactComponent as BikingHelmetPerson } from 'assets/streamline-light/sports/biking/biking-helmet-person.svg';
import { ReactComponent as Car } from 'assets/streamline-light/transportation/cars/car.svg';
import { ReactComponent as RailroadMetro } from 'assets/streamline-light/transportation/railroad/railroad-metro.svg';
import { ReactComponent as WalkingCrossStreet } from 'assets/streamline-light/wayfinding/walking/walking-cross-street.svg';
import { FC, memo, useCallback, useEffect } from 'react';
import { batch } from 'react-redux';
import styled from 'styled-components';
import { Coordinates, IsochronesMode, Transport } from '../../../../generated';
import { device } from '../../../../style/theme';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import {
  changeViewport,
  setMarkers,
  setSettings,
} from '../../../map/redux/mapSlice';
import {
  setCommuteTimeCoordinates,
  setIsReachabilityTab,
  setTransportMode,
  toggleReachabilityLegend,
} from '../../redux/dynamicMapUtilsSlice';
import { ConnectionCard } from '../card/connection-card/connection-card';
import {
  ButtonConfig,
  TransportSelector,
} from '../card/elements/transport-selector';
import { useTravelTime } from '../../../map/hooks/useTravelTime';
import { ReachabilityOfferCard } from '../card/reachability-offer-card/reachability-offer-card';

interface IProps {
  transportPOI: Transport;
  coordinates: Coordinates;
  countryCode: string;
  isTabListNearTop: boolean;
}

const Container = styled.div``;

const ContentWrapper = styled.div<{ isColumnShow?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isColumnShow ? 'column-reverse' : 'row')};
  gap: 16px;

  @media ${device.tablet} {
    flex-direction: column-reverse;
  }
`;

const FirstColumn = styled.div`
  flex: 1;
`;

const SecondColumn = styled.div`
  max-width: 400px;
  flex: 1;

  @media ${device.tablet} {
    max-width: none;
  }
`;

const Selector = styled(TransportSelector)`
  margin-bottom: 16px;
`;

const modeButtonsConfig: ButtonConfig[] = [
  {
    icon: WalkingCrossStreet,
    mode: IsochronesMode.Walk,
    text: 'reachability.mode.walk',
  },
  {
    icon: BikingHelmetPerson,
    mode: IsochronesMode.Bicycle,
    text: 'reachability.mode.bicycle',
  },
  {
    icon: Car,
    mode: IsochronesMode.Car,
    text: 'reachability.mode.car',
  },
  {
    icon: RailroadMetro,
    mode: IsochronesMode.PublicTransport,
    text: 'reachability.mode.public-transport',
  },
];

const ReachabilityBase: FC<IProps> = ({
  transportPOI,
  coordinates,
  countryCode,
  isTabListNearTop,
}) => {
  const dispatch = useAppDispatch();

  const transportMode = useAppSelector(
    (state) => state.dynamicMapUtils.transportMode
  );
  const currentMapboxMarkers = useAppSelector((state) => state.map.markers);
  const reachabilityLegendMode = useAppSelector(
    (state) => state.dynamicMapUtils.reachabilityLegendMode
  );

  const handleTransportMode = useCallback(
    (mode: IsochronesMode) => {
      dispatch(setTransportMode(mode));
    },
    [dispatch]
  );

  const densities = useTravelTime({
    coordinates,
    transportPOI,
    reachabilityLegendMode,
    currentMapboxMarkers,
    transportMode,
    countryCode,
  });

  useEffect(() => {
    const pin = { ...coordinates };

    batch(() => {
      dispatch(toggleReachabilityLegend(true));
      dispatch(
        changeViewport({
          ...coordinates,
          longitude: coordinates.longitude + 0.05,
          bearing: -80,
          pitch: 50,
          zoom: 12,
          transitionDuration: 100,
        })
      );
      dispatch(
        setSettings({
          dragPan: true,
          dragRotate: true,
          scrollZoom: true,
          touchZoom: true,
          touchRotate: true,
          keyboard: true,
          doubleClickZoom: true,
        })
      );
      dispatch(setMarkers([pin]));
      dispatch(setSettings({ radius: 0 }));
    });

    return () => {
      batch(() => {
        dispatch(setMarkers([]));
        dispatch(toggleReachabilityLegend(false));
        dispatch(setCommuteTimeCoordinates());
      });
    };
  }, [coordinates, dispatch]);

  useEffect(() => {
    switch (transportMode) {
      case IsochronesMode.Walk:
        dispatch(
          changeViewport({ zoom: 12, longitude: coordinates.longitude + 0.05 })
        );
        break;
      case IsochronesMode.Bicycle:
        dispatch(
          changeViewport({ zoom: 10, longitude: coordinates.longitude + 0.2 })
        );
        break;
      case IsochronesMode.PublicTransport:
        dispatch(
          changeViewport({ zoom: 7, longitude: coordinates.longitude + 0.4 })
        );
        break;
      case IsochronesMode.Car:
        dispatch(
          changeViewport({ zoom: 8, longitude: coordinates.longitude + 0.7 })
        );
        break;
      default:
        dispatch(
          changeViewport({ zoom: 12, longitude: coordinates.longitude + 0.045 })
        );
    }
  }, [transportMode]);

  useEffect(() => {
    dispatch(setIsReachabilityTab(true));

    return () => {
      dispatch(setIsReachabilityTab(false));
    };
  }, []);

  return (
    <Container>
      <Selector
        selected={transportMode}
        onSelect={handleTransportMode}
        buttonsConfig={modeButtonsConfig}
      />
      <ContentWrapper isColumnShow={isTabListNearTop}>
        <FirstColumn>
          <ConnectionCard transports={transportPOI} />
        </FirstColumn>
        <SecondColumn>
          <ReachabilityOfferCard densities={densities?.densities15} />
        </SecondColumn>
      </ContentWrapper>
    </Container>
  );
};

const Reachability = memo(ReachabilityBase);

export { Reachability };
