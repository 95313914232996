import styled from 'styled-components';
import { device } from '../../../../../../style/theme';

export const Container = styled.div`
  position: relative;
`;

export const FormsContainer = styled.div`
  max-height: 1240px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    max-height: none;
  }
`;

export const ShadowBase = styled.div`
  position: absolute;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 50px;
`;

export const ShadowTop = styled(ShadowBase)`
  top: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1), transparent);
  opacity: 0;
  display: none;
`;

export const ShadowBottom = styled(ShadowBase)`
  bottom: 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1), transparent);
  opacity: 0;
  display: none;
`;
