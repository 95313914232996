import { FC, memo, useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { disableBodyOverflow } from '../../../../../common/utils/disable-body-overflow';
import { FORM_STEPS } from '../../../constants';
import { FormContainer } from '../../../../components/common/form-container';
import { VisibilityWrapper } from '../../../../components/common/visibility-wrapper';
import { ValuationLoader } from '../../../../components/common/loader';
import { SearchProfileForm } from '../../forms/search-profile-form/search-profile-form';
import {
  toggleLoginForm,
  toggleRegisterForm,
} from '../../../redux/searchProfileSlice';
import { OnlineRegisterContainer } from './search-profile-register-container';
import { OnlineLoginContainer } from './search-profile-login-container';
import { IFrameContainer } from './search-profile-styles';
import { useConvertSP } from '../../../hooks/useConvertSP';
import { useSubmitFormSP } from '../../../hooks/useSubmitFormSP';

interface IProps {
  regionalFirstStepNext?: () => void;
  regionalSecondStepBack?: () => void;
  mobileCompact?: boolean;
}

const SearchProfileContainerBase: FC<IProps> = ({
  regionalFirstStepNext,
  regionalSecondStepBack,
  mobileCompact,
}) => {
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const activeFormStep = useAppSelector(
    (state) => state.searchProfile.activeStep
  );
  const isRegisterFormOpen = useAppSelector(
    (state) => state.searchProfile.isRegisterFormOpen
  );
  const isLoginFormOpen = useAppSelector(
    (state) => state.searchProfile.isLoginFormOpen
  );
  const isLoading = useAppSelector((state) => state.searchProfile.isLoading);
  const searchProfileErrors = useAppSelector(
    (state) => state.searchProfile.errors
  );
  const owner = useAppSelector((state) => state.auth.user?._id);
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  // Mutations

  const { onSubmitSPForm } = useSubmitFormSP();

  const switchToRegisterForm = useCallback(() => {
    dispatch(toggleRegisterForm(true));
  }, [dispatch]);

  const switchToLoginForm = useCallback(() => {
    dispatch(toggleRegisterForm(false));
    dispatch(toggleLoginForm(true));
  }, [dispatch]);

  useEffect(() => {
    if (isMobileSize) {
      disableBodyOverflow();
    }
    return () => {
      if (isMobileSize) {
        disableBodyOverflow();
      }
    };
  }, [isMobileSize]);
  useConvertSP();

  if (owner) {
    return (
      <FormContainer
        mobileCompact={mobileCompact}
        hasCleanInterface={isIframe}
        styles={{ top: '72px' }}
      >
        <IFrameContainer isiframe={isIframe}>
          {isLoading ? (
            <ValuationLoader />
          ) : (
            <SearchProfileForm
              errors={searchProfileErrors}
              stepsCount={FORM_STEPS}
              activeFormStep={activeFormStep}
              onSubmit={onSubmitSPForm}
              submitForm
            />
          )}
        </IFrameContainer>
      </FormContainer>
    );
  }

  return (
    <FormContainer mobileCompact={mobileCompact} hasCleanInterface={isIframe}>
      <VisibilityWrapper
        isVisible={!isRegisterFormOpen && !isLoginFormOpen}
        isiframe={isIframe}
      >
        {isLoading ? (
          <ValuationLoader />
        ) : (
          <SearchProfileForm
            regionalFirstStepNext={regionalFirstStepNext}
            regionalSecondStepBack={regionalSecondStepBack}
            errors={searchProfileErrors}
            stepsCount={FORM_STEPS}
            activeFormStep={activeFormStep}
            submitForm={false}
            lastStepCallback={switchToRegisterForm}
            lastStepLoginCallback={switchToLoginForm}
            mobileCompact={mobileCompact}
          />
        )}
      </VisibilityWrapper>
      {isRegisterFormOpen && !isLoginFormOpen && (
        <OnlineRegisterContainer
          // onSuccess={onCreateSearchProfile}
          openLogin={switchToLoginForm}
        />
      )}
      {isLoginFormOpen && <OnlineLoginContainer onSuccess={onSubmitSPForm} />}
    </FormContainer>
  );
};

const SearchProfileContainer = memo(SearchProfileContainerBase);

export { SearchProfileContainer };
