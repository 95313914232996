import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as CheckCircle } from 'assets/streamline-light/interface-essential/form-validation/check-circle-1.svg';

import Icon from '../../common/components/ui/icon';
import {
  Headline2,
  ParagraphText,
} from '../../common/components/ui/typography';

interface IProps {
  title: string;
  textContent: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Content = styled(ParagraphText)`
  text-align: center;
  margin-bottom: 32px;
`;

const Title = styled(Headline2)`
  margin: 16px 0;
  text-align: center;
`;

const ConfirmEmail = ({ title, textContent }: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <Icon
        icon={CheckCircle}
        width={40}
        height={40}
        color={themeContext.green}
      />
      <Title content={title} />
      <Content content={textContent} />
    </Container>
  );
};

export { ConfirmEmail };
