import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../store';

const selectExperiments = (state: RootState) =>
  state.experiments.savedExperiments;

export const selectExperimentsForQuery = createDraftSafeSelector(
  selectExperiments,
  (experiments) =>
    experiments.map(({ _id, selectedCase }) => ({ _id, selectedCase }))
);
