import styled from 'styled-components';
import { Headline3 } from '../../../common/components/ui/typography';

const Title = styled(Headline3)`
  margin: 0 auto 12px auto;
  text-align: center;
`;

interface IFormTitle {
  text: string;
}

const FormTitle = ({ text }: IFormTitle): JSX.Element => {
  return <Title content={text} />;
};

export { FormTitle };
