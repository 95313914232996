import { useHistory } from 'react-router-dom';
import { LocationState, Path } from 'history';
import useGetLocalization from './get-localization';

// history push function with localization logic

export const useLngHistoryPush = () => {
  const { push } = useHistory();
  const lang = useGetLocalization();

  return (path: Path, langProp?: string, state?: LocationState) => {
    const langPath = `/${langProp || lang || 'de-de'}${path}`;
    push(langPath, state);
  };
};
