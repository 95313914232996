const getCollapsedHeight = (): { height: number; opacity: number } => ({
  height: 0,
  opacity: 0,
});
const getRealHeight = (node: {
  scrollHeight: number;
}): { height: number; opacity: number } => ({
  height: node.scrollHeight,
  opacity: 1,
});
const getCurrentHeight = (node: {
  offsetHeight: number;
}): { height: number } => ({
  height: node.offsetHeight,
});
const skipOpacityTransition = (_: unknown, event: Event): boolean =>
  (event as TransitionEvent).propertyName === 'height';

const collapseMotion = {
  motionName: 'rc-collapse-motion',
  onEnterStart: getCollapsedHeight,
  onEnterActive: getRealHeight,
  onLeaveStart: getCurrentHeight,
  onLeaveActive: getCollapsedHeight,
  onEnterEnd: skipOpacityTransition,
  onLeaveEnd: skipOpacityTransition,
  motionDeadline: 500,
  leavedClassName: 'rc-collapse-content-hidden',
};

export default collapseMotion;
