import { Redirect, Route, Switch } from 'react-router-dom';
import SellerSideImage from 'assets/seller-side-img.png';
import SellerLPSideImage from 'assets/seller-second-lp-side-img.png';

import { siteMap } from './site-map';

import { ConfirmEmailPage } from '../modules/auth/pages/confirm-email-page';
import { SimpleLayout } from '../modules/common/components/layout/simple-layout';
import { Layout } from '../modules/common/components/layout/layout';
import { ContentPage } from '../modules/content-page/pages/content-page';
import { OwnerLandingPage } from '../modules/landing/pages/owner-landing-page/owner-landing-page';
import PropertyInvitationPage from '../modules/property-share/pages/property-invitation-page';
import { PropertyLockedPage } from '../modules/property/pages/property-locked-page/property-locked-page';
import { PropertyPage } from '../modules/property/pages/property-page/property-page';
import { SharePropertySignInPage } from '../modules/property-share/pages/share-property-sign-in-page';
import { NotFoundPage } from '../modules/system/pages/notfound';
import { ProtectedRoute } from './protected-route';
import { RegionalLandingPage } from '../modules/landing/pages/regional-landing-page/regional-landing-page';
import { BuyerLandingPage } from '../modules/landing/pages/buyer-landing-page/buyer-landing-page';
import { SearchProfilePage } from '../modules/search-profile/pages/search-profile-page/search-profile-page';
import { LayoutWithImage } from '../modules/common/components/layout/layout-with-side-img';
import { BuyerLoginPage } from '../modules/auth/pages/buyer-login-page';
import { RequestExposePage } from '../modules/search-profile/pages/request-expose-page/request-expose-page';
import { BuyerSecondLandingPage } from '../modules/landing/pages/buyer-second-landing-page/buyer-second-landing-page';
import { BuyerSignUpPage } from '../modules/auth/pages/buyer-sign-up-page/buyer-sign-up-page';
import { ConfirmBuyerEmailPage } from '../modules/auth/pages/confirm-buyer-email-page';
import { DownloadExposePage } from '../modules/search-profile/pages/download-expose-page/download-expose-page';
import { SellerPropertiesPage } from '../modules/seller/pages/seller-properties-page/seller-properties-page';
import { SellerLandingPage } from '../modules/landing/pages/seller-landing-page/seller-landing-page';
import { SellingProcessPage } from '../modules/seller/pages/selling-process-page/selling-process-page';
import { OverviewLandingPage } from '../modules/landing/pages/overview-landing-page/overview-landing-page';
import { SellerLoginPage } from '../modules/auth/pages/seller-login-page';
import { SellerSignUpPage } from '../modules/auth/pages/seller-sign-up-page/seller-sign-up-page';
import { ConfirmSellerEmailPage } from '../modules/auth/pages/confirm-seller-email-page';
import { SellerSecondLandingPage } from '../modules/landing/pages/seller-second-landing-page/seller-second-landing-page';
import { DownloadSellerExposePage } from '../modules/seller/pages/download-seller-expose-page/download-seller-expose-page';
import { FinancingLandingPage } from '../modules/landing/pages/financing-landing-page/financing-landing-page';
import { FinancingPortalPage } from '../modules/financing/pages/financing-portal-page/financing-portal-page';
import { VonPollIframeAuthPage } from '../modules/auth/pages/von-poll-iframe-auth-page';
import { BuyerExposeFilesPage } from '../modules/broker-view/pages/buyer-expose-files';
import { UnsubscribePage } from '../modules/auth/pages/unsubscribe-page';
import { ExpiredConfirmEmailPage } from '../modules/auth/pages/expired-confirm-email-page';
import { PropertyPortfolioPage } from '../modules/property/pages/property-portfolio-page/property-portfolio-page';

const Routes = (): JSX.Element => (
  <Switch>
    <Route exact strict path={siteMap.OverviewLandingPage.pathWithLang}>
      <Layout clip>
        <OverviewLandingPage />
      </Layout>
    </Route>
    <Route exact strict path={siteMap.OwnerLandingPage.pathWithLang}>
      <Layout clip>
        <OwnerLandingPage />
      </Layout>
    </Route>
    <Route exact path={siteMap.OwnerLandingPageOnlineWidget.pathWithLang}>
      <Layout>
        <OwnerLandingPage openWidget="ONLINE" />
      </Layout>
    </Route>
    <Route exact path={siteMap.OwnerLandingPagePersonalWidget.pathWithLang}>
      <Layout>
        <OwnerLandingPage openWidget="PERSONAL" />
      </Layout>
    </Route>
    <Route exact path={siteMap.LoginPage.pathWithLang}>
      <Layout>
        <OwnerLandingPage openLoginModal />
      </Layout>
    </Route>
    <Route exact path={siteMap.OwnerLandingPageAppointmentModal.pathWithLang}>
      <Layout>
        <OwnerLandingPage openAppointmentModal />
      </Layout>
    </Route>
    <Route exact path={siteMap.SellerLandingPage.pathWithLang}>
      <Layout clip>
        <SellerLandingPage />
      </Layout>
    </Route>
    <Route exact path={siteMap.BuyerLandingPage.pathWithLang}>
      <Layout clip>
        <BuyerLandingPage />
      </Layout>
    </Route>
    <Route exact path={siteMap.BuyerLandingPageWidget.pathWithLang}>
      <Layout clip>
        <BuyerLandingPage isOpenWidget />
      </Layout>
    </Route>
    <Route exact path={siteMap.BuyerSecondLandingPage.pathWithLang}>
      <LayoutWithImage isSubheader>
        <BuyerSecondLandingPage />
      </LayoutWithImage>
    </Route>
    <Route exact path={siteMap.BuyerSignUpPage.pathWithLang}>
      <LayoutWithImage isSubheader>
        <BuyerSignUpPage />
      </LayoutWithImage>
    </Route>
    <Route exact path={siteMap.FinancingLandingPage.pathWithLang}>
      <Layout clip>
        <FinancingLandingPage />
      </Layout>
    </Route>
    <Route exact path={siteMap.FinancingLandingPageWidget.pathWithLang}>
      <Layout clip>
        <FinancingLandingPage isFinancingWidget />
      </Layout>
    </Route>
    <Route
      exact
      path={siteMap.FinancingLandingPageCertificateWidget.pathWithLang}
    >
      <Layout clip>
        <FinancingLandingPage isFinancingCertificateWidget />
      </Layout>
    </Route>
    <ProtectedRoute exact path={siteMap.FinancingPortalPage.pathEmptyState}>
      <Layout>
        <FinancingPortalPage />
      </Layout>
    </ProtectedRoute>
    <ProtectedRoute exact path={siteMap.FinancingPortalPage.pathWithLang}>
      <Layout>
        <FinancingPortalPage />
      </Layout>
    </ProtectedRoute>
    <Route exact path={siteMap.LoginBuyerPage.pathWithLang}>
      <LayoutWithImage isSubheader isBuyerLogin>
        <BuyerLoginPage />
      </LayoutWithImage>
    </Route>
    <Route exact path={siteMap.SellerSignUpPage.pathWithLang}>
      <LayoutWithImage
        SideImageSrc={SellerSideImage}
        sideImageStyle={{ width: '34.9vw', height: '100%' }}
        childrenContainerStyle={{ maxWidth: '520px' }}
      >
        <SellerSignUpPage />
      </LayoutWithImage>
    </Route>
    <Route exact path={siteMap.LoginSellerPage.pathWithLang}>
      <LayoutWithImage
        SideImageSrc={SellerSideImage}
        sideImageStyle={{ width: '34.9vw', height: '100%' }}
      >
        <SellerLoginPage />
      </LayoutWithImage>
    </Route>
    <Route exact path={siteMap.SellerSecondLandingPage.pathWithLang}>
      <LayoutWithImage
        SideImageSrc={SellerLPSideImage}
        sideImageStyle={{ width: '34.9vw', height: '100%' }}
      >
        <SellerSecondLandingPage />
      </LayoutWithImage>
    </Route>
    <ProtectedRoute exact path={siteMap.BuyerExposeFiles.pathWithLang}>
      <Layout>
        <BuyerExposeFilesPage />
      </Layout>
    </ProtectedRoute>
    <ProtectedRoute exact path={siteMap.RequestExposePage.pathWithLang}>
      <LayoutWithImage isSubheader>
        <RequestExposePage />
      </LayoutWithImage>
    </ProtectedRoute>
    <ProtectedRoute path={siteMap.SearchProfilePage.pathWithLang}>
      <Layout clip>
        <SearchProfilePage />
      </Layout>
    </ProtectedRoute>
    <ProtectedRoute path={siteMap.DownloadExpose.pathWithLang}>
      <Layout>
        <DownloadExposePage />
      </Layout>
    </ProtectedRoute>
    <ProtectedRoute path={siteMap.DownloadSellerExpose.pathWithLang}>
      <Layout>
        <DownloadSellerExposePage />
      </Layout>
    </ProtectedRoute>
    <ProtectedRoute path={siteMap.SellerPropertiesPage.pathWithLang}>
      <Layout>
        <SellerPropertiesPage />
      </Layout>
    </ProtectedRoute>
    <ProtectedRoute path={siteMap.SellingProcessPage.pathWithLang}>
      <Layout>
        <SellingProcessPage />
      </Layout>
    </ProtectedRoute>
    {/* <ProtectedRoute
      path="/property/:propertyId"
      render={(routeProps) => (
        <PropertyPage key={routeProps.location.pathname} />
      )}
    /> */}
    <ProtectedRoute path={siteMap.PropertyPage.pathWithLang}>
      <Layout>
        <PropertyPage />
      </Layout>
    </ProtectedRoute>
    <Route path={siteMap.PropertyLockedPage.pathWithLang}>
      <Layout>
        <PropertyLockedPage />
      </Layout>
    </Route>
    <ProtectedRoute path={siteMap.PropertyPortfolioPage.pathWithLang}>
      <Layout>
        <PropertyPortfolioPage />
      </Layout>
    </ProtectedRoute>
    <Route path={siteMap.PropertyInvitationPage.pathWithLang}>
      <SimpleLayout>
        <PropertyInvitationPage />
      </SimpleLayout>
    </Route>
    <Route path={siteMap.SharePropertySignInPage.pathWithLang}>
      <SimpleLayout>
        <SharePropertySignInPage />
      </SimpleLayout>
    </Route>
    <Route path={siteMap.ConfirmBuyerEmailPage.pathWithLang}>
      <LayoutWithImage>
        <ConfirmBuyerEmailPage />
      </LayoutWithImage>
    </Route>
    <Route path={siteMap.ConfirmSellerEmailPage.pathWithLang}>
      <LayoutWithImage
        SideImageSrc={SellerSideImage}
        sideImageStyle={{ width: '35vw', height: '100%' }}
      >
        <ConfirmSellerEmailPage />
      </LayoutWithImage>
    </Route>
    <Route path={siteMap.ConfirmEmailPage.pathWithLang}>
      <Layout>
        <ConfirmEmailPage />
      </Layout>
    </Route>
    <Route path={siteMap.ExpiredConfirmEmailPage.pathWithLang}>
      <Layout>
        <ExpiredConfirmEmailPage />
      </Layout>
    </Route>
    <Route path={siteMap.ResetPasswordPage.pathWithLang}>
      <Layout>
        <OwnerLandingPage openConfirmForgotPasswordModal />
      </Layout>
    </Route>
    <Route path={siteMap.ContentPage.pathWithLang} exact>
      <Layout>
        <ContentPage />
      </Layout>
    </Route>
    <Route exact path={siteMap.VonPollIFrameAuthPage.pathWithLang}>
      <VonPollIframeAuthPage />
    </Route>
    <Route exact path={siteMap.RegionalLandingPage.pathWithLang}>
      <Layout clip contentNoMargin>
        <RegionalLandingPage />
      </Layout>
    </Route>
    <Route path={siteMap.UnsubscribePage.pathWithLang}>
      <Layout>
        <UnsubscribePage />
      </Layout>
    </Route>
    <Route path={siteMap.NotFoundPage.pathWithLang}>
      <Layout>
        <NotFoundPage />
      </Layout>
    </Route>
    <Route strict path={siteMap.RedirectPage.pathWithLang}>
      <Layout>
        <Redirect to={`/de-de/`} />
      </Layout>
    </Route>
  </Switch>
);

export { Routes };
