import 'core-js/features/array/flat-map';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import {
  useCmsPagesQuery,
  useExperimentsQuery,
  useLazyCurrentUserQuery,
  User,
  UserState,
} from '../../../generated';
import { client } from '../../../services/graphql/graphql';

import { Layout } from '../../common/components/layout/layout';
import { GlobalLoader } from '../../common/components/ui/loaders/global-loader';
import {
  useAppDispatch,
  useAppSelector,
  useDeviceDetector,
} from '../../common/hooks';
import { isTokenExpired } from '../../common/utils/is-token-expired';
import { setExperiments } from '../../experiments/redux/experimentsSlice';
import { selectExperimentsForQuery } from '../../experiments/redux/selectors/selectExperimentsForQuery';
import { NotFoundPage } from '../../system/pages/notfound';
import {
  logout,
  setAnonymousUser,
  setUser,
  setUserState,
  toggleIsCreatePasswordModalOpen,
} from '../redux/authSlice';
import { selectIsStoreLoaded } from '../redux/selectors/isStoreLoaded';
import { setRegionalBrokerId } from '../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { useConfig } from '../../../config/useConfig';
import { LoginDev } from './forms/login-dev/login-dev';
import { defaultTheme } from '../../../style/theme';
import { siteMap } from '../../../routes/site-map';

interface IProps {
  children: JSX.Element;
}

const AuthContainerBase = ({ children }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isMobile, isIos } = useDeviceDetector();
  const { isDev } = useConfig();
  const isVonPollAuth =
    window.location.pathname === `/de-de${siteMap.VonPollIFrameAuthPage.path}`;

  const changePasswordTimeout = useRef<NodeJS.Timeout | null>(null);
  const [isGlobalLoaderActive, setGlobalLoaderActive] = useState(true);

  // Make sure that all persisted reducers are rehydrated
  const isStoreLoaded = useAppSelector(selectIsStoreLoaded);

  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const exp = useAppSelector((state) => state.auth.exp);
  const expDevUser = useAppSelector((state) => state.auth.expDevUser);
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);

  const userState = useAppSelector((state) => state.auth.userState);
  const userId = useAppSelector((state) => state.auth.user?._id);
  const savedExperiments = useAppSelector(selectExperimentsForQuery);
  const changePasswordTimeoutDuration = useAppSelector(
    (state) => state.auth.changePasswordTimeout
  );

  // Fetch all cms pages
  const { isLoading: areCmsPagesLoading, error: cmsPagesError } =
    useCmsPagesQuery();

  // Fetch all experiments
  const { data: experimentsData, isLoading: areExperimentsLoading } =
    useExperimentsQuery(
      {
        savedExperiments,
      },
      { skip: !isStoreLoaded || !savedExperiments }
    );

  // Fetch current user
  const [fetchUser, { data: userData, error: userError }] =
    useLazyCurrentUserQuery();

  const preventPinch = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (isMobile() && isIos() && e?.scale !== 1) {
        e?.preventDefault();
      }
    },
    [isIos, isMobile]
  );

  // If we have token and exp persisted and the user is anonymous, try to login and set the header
  // if the token is expired clear the persisted data.
  // TODO - try to extend the confirm logic here
  useEffect(() => {
    if (isStoreLoaded && accessToken && exp && isAnonymousUser && !userId) {
      const isExpired = isTokenExpired(exp);
      if (!isExpired) {
        client.setHeader('Authorization', `Bearer ${accessToken}`);
        fetchUser();
        return;
      }
      dispatch(logout());
      setGlobalLoaderActive(false);
    }
  }, [
    dispatch,
    fetchUser,
    isStoreLoaded,
    accessToken,
    exp,
    isAnonymousUser,
    userId,
  ]);

  // Remove loader for anonymous users
  useEffect(() => {
    if (isStoreLoaded && (!accessToken || !exp)) {
      setGlobalLoaderActive(false);
    }
  }, [dispatch, fetchUser, isStoreLoaded, accessToken, exp, isAnonymousUser]);

  useEffect(() => {
    if (userData?.currentUser && userData?.currentUser?.userState) {
      batch(() => {
        dispatch(setUser(userData?.currentUser as User));
        dispatch(setAnonymousUser(false));
        dispatch(
          setUserState(
            userData?.currentUser?.userState ?? UserState.Unauthenticated
          )
        );
        dispatch(
          setRegionalBrokerId(userData?.currentUser?.regionalBrokerId || '')
        );
      });
      setGlobalLoaderActive(false);
      return;
    }

    if (userData?.currentUser === null) {
      dispatch(logout());
      setGlobalLoaderActive(false);
    }

    if (userError) {
      // Maybe we don't need to logout here?
      // dispatch(logout());
      setGlobalLoaderActive(false);
    }
  }, [dispatch, userData, userError]);

  useEffect(() => {
    if (
      !isAnonymousUser &&
      userState === UserState.ChangePassword &&
      changePasswordTimeout.current === null
    ) {
      const duration = changePasswordTimeoutDuration ?? 10_000;
      // Do not show the modal immediately
      changePasswordTimeout.current = setTimeout(
        () => dispatch(toggleIsCreatePasswordModalOpen(true)),
        duration
      );
    } else if (isAnonymousUser && changePasswordTimeout.current !== null) {
      clearTimeout(changePasswordTimeout.current);
    }
  }, [
    dispatch,
    userState,
    isAnonymousUser,
    changePasswordTimeout,
    changePasswordTimeoutDuration,
  ]);

  useEffect(() => {
    if (experimentsData && experimentsData?.experiments?.length > 0) {
      dispatch(
        setExperiments(
          experimentsData?.experiments.flatMap(
            ({
              _id,
              type,
              selectedCase,
              registrationBy,
              evaluationWidgetVersion,
            }) =>
              selectedCase
                ? [
                    {
                      _id,
                      type,
                      selectedCase,
                      registrationBy,
                      evaluationWidgetVersion,
                    },
                  ]
                : []
          )
        )
      );
    }
  }, [dispatch, experimentsData]);

  useEffect(() => {
    document.addEventListener('touchmove', preventPinch, { passive: false });
    return () => {
      document.removeEventListener('touchmove', preventPinch);
    };
  }, [preventPinch]);

  if (isDev && !isVonPollAuth) {
    const isDevExpired = new Date(expDevUser || '') < new Date();

    if (!expDevUser || isDevExpired) {
      return (
        <ThemeProvider theme={defaultTheme}>
          <LoginDev />
        </ThemeProvider>
      );
    }
  }

  if (cmsPagesError) {
    return (
      <Layout isError={!!cmsPagesError}>
        <NotFoundPage />
      </Layout>
    );
  }

  if (isGlobalLoaderActive || areExperimentsLoading || areCmsPagesLoading) {
    return <GlobalLoader />;
  }

  return children;
};

const AuthContainer = memo(AuthContainerBase);

export { AuthContainer };
