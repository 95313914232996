import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateFinancingCalculationStateAction } from '../../../redux/financingSlice';
import { IFinancingForm } from '../../../interface';

interface IProps {
  finData: IFinancingForm;
}

const useOnChangeValues = ({ finData }: IProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateFinancingCalculationStateAction({
        ...(finData?.purchasePrice
          ? { purchasePrice: finData?.purchasePrice }
          : {}),
      })
    );
  }, [finData?.purchasePrice]);

  const onChangePurchasePrice = useCallback(
    (value) =>
      dispatch(updateFinancingCalculationStateAction({ purchasePrice: value })),
    [dispatch]
  );
  return {
    onChangePurchasePrice,
  };
};

export { useOnChangeValues };
