import styled from 'styled-components';
import { device } from '../../../../style/theme';
import { BoxTitle, Headline2 } from '../ui/typography';
import { MainButton, SecondaryButton } from '../ui/buttons';

export const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  padding: 0 12px;
  z-index: 2000;
  bottom: 0;
  right: 0;

  @media ${device.tablet} {
    padding: 0 16px;
  }

  @media ${device.mobile} {
    &:not(.collapsed) {
      min-height: 100vh;
      min-height: -webkit-fill-available;
      width: 100vw;
      backdrop-filter: blur(5px);
      background-color: rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: flex-end;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  /* right: 24px;
  bottom: 8px; */
  width: 512px;
  padding-bottom: 12px;

  @media ${device.mobile} {
    width: 100%;
    max-height: 100vh;
    overflow: auto;
  }
`;

export const HandlerButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.a`
  display: block;
  width: 48px;
  height: 48px;
  border: 2px solid #f2f5f7;
  border-radius: 0 0 8px 8px;
  border-top-color: #ffffff;
  background: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .icon {
    transform-origin: center center;
  }

  /* .collapsed & {
    border-radius: 3px !important;

    .icon {
      rotate: 180deg;
    }
  } */
`;

export const StarButton = styled.a`
  display: block;
  width: 48px;
  height: 48px;
  border: 2px solid ${(props) => props.theme.blue};
  border-radius: 8px;
  background: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: #a08558;
  }
`;

export const Body = styled.div`
  border: 2px solid #f2f5f7;
  border-radius: 3px 0 3px 3px;
  background: #ffffff;
  /* height: 480px; */
  width: 100%;
  max-width: 512px;
  margin-bottom: -2px;
  transition: all 0.3s;
  overflow: hidden;
  opacity: 1;
  visibility: visible;
  padding-top: 32px;
  margin-left: auto;

  .collapsed & {
    opacity: 0;
    visibility: hidden;
    height: 0;
    border-color: transparent;
    box-shadow: none;
  }

  @media ${device.mobile} {
    padding-top: 12px;
    /* height: auto; */
  }
`;

export const BodyTitle = styled(Headline2)`
  color: ${(props) => props.theme.blue};
  text-align: center;
  padding: 0 16px;
`;

export const Form = styled.form`
  padding: 32px;

  @media ${device.mobile} {
    padding: 32px 12px;
  }
`;

export const RatingWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RatingItem = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 2px solid ${(props) => props.theme.blue};
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;

  &.active {
    background: #a08558;
  }

  @media ${device.mobileIframe} {
    width: 36px;
    height: 36px;
    .icon {
      width: 32px;
      height: 32px;
    }
  }
`;

export const RatingLegend = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;

  @media ${device.mobileIframe} {
    padding-top: 8px;
    p {
      font-size: 10px;
    }
  }
`;

export const TextareaWrap = styled.div`
  padding-top: 32px;
`;

export const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 32px;

  @media ${device.mobile} {
    padding-top: 24px;
  }

  @media ${device.mobileIframe} {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 24px;
    button {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const CancelButton = styled(SecondaryButton)`
  color: ${(props) => props.theme.ctaBlue};
  border-color: ${(props) => props.theme.ctaBlue};
  text-transform: uppercase;
`;

export const SubmitButton = styled(MainButton)``;

export const TooltipHeader = styled(BoxTitle)`
  text-align: center;
  color: ${(props) => props.theme.blue};
`;
