import { memo, useMemo, useRef, useState } from 'react';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  Parallax,
} from 'swiper';
import {
  Container,
  HeaderContainer,
  SliderHeader,
  SliderContainer,
  Headline,
  HeaderBox,
} from 'modules/landing/components/slider/slider/slider-styles';
import { NavigationOptions } from 'swiper/types/components/navigation';
import { device } from '../../../../../style/theme';
import { SliderNavButton } from '../slider-nav-button';
import { PrimarySlide } from '../primary-slide/primary-slide';

import { Parallax as ParallaxComponent } from '../parallax';
import { SecondarySlide } from '../secondary-slide';
import { useWindowSize } from '../../../../common/hooks';
import { calculateParallax } from '../../../../common/utils/calculate-parallax';
import { BenefitSection, ValuationType } from '../../../../../generated';

interface IProps {
  sections: BenefitSection[];
  handleEvaluationWizardOpen?: (type: ValuationType) => void;
  ctaHandler?: () => void;
  sliderHeader: string;
  headline: string;
  isMobileRedesign?: boolean;
  reduceToken?: boolean;
}

SwiperCore.use([Parallax, Navigation, Pagination, Controller]);

const SliderBase = ({
  sections,
  handleEvaluationWizardOpen,
  ctaHandler,
  sliderHeader,
  headline,
  isMobileRedesign,
  reduceToken,
}: IProps): JSX.Element => {
  const [mainSlider, setMainSlider] = useState<SwiperCore>();
  const [secondarySlider, setSecondarySlider] = useState<SwiperCore>();
  const [activeIndex, setActiveIndex] = useState(0);

  const windowSize = useWindowSize();
  const isMobileSize =
    (windowSize.width || 0) <=
    Number.parseInt(device.tabletSlider.replace('(max-width: ', ''), 10);

  const prevRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);

  const onSlideChange = (swiper: SwiperCore) =>
    setActiveIndex(swiper.activeIndex);

  const size = useWindowSize();

  const sliderParallax = useMemo(
    () => calculateParallax(size.width),
    [size.width]
  );

  const { primarySlides, secondarySlides } = useMemo(() => {
    const firstList: JSX.Element[] = [];
    const secondList: JSX.Element[] = [];
    sections.forEach((section) => {
      secondList.push(
        <SwiperSlide
          key={`slide-${section._id}`}
          tag="li"
          style={{ listStyle: 'none' }}
        >
          <SecondarySlide
            image={section.imageUrl}
            disclaimer={section.imageHint ?? ''}
          />
        </SwiperSlide>
      );
      firstList.push(
        <SwiperSlide
          key={`slide-${section._id}`}
          tag="li"
          style={{ listStyle: 'none' }}
        >
          <PrimarySlide
            reduceToken={reduceToken}
            isMobileRedesign={isMobileRedesign}
            icon={section.iconUrl || ''}
            title={section.title}
            description={section.expandedText}
            buttonLabel={section.ctaLabel}
            buttonCta={section.cta}
            handleEvaluationWizardOpen={handleEvaluationWizardOpen}
            ctaHandler={ctaHandler}
          />
        </SwiperSlide>
      );
    });
    return {
      primarySlides: firstList,
      secondarySlides: secondList,
    };
  }, [ctaHandler, handleEvaluationWizardOpen, sections]);

  return (
    <Container>
      <HeaderContainer isMobileRedesign={isMobileRedesign}>
        <HeaderBox isMobileRedesign={isMobileRedesign}>
          <SliderHeader content={sliderHeader} />
          <Headline content={headline} />
        </HeaderBox>
        {isMobileRedesign && isMobileSize && (
          <SliderNavButton
            isMobileRedesign={isMobileRedesign}
            buttonRef={null}
            onClick={() => nextRef.current?.click()}
          />
        )}
      </HeaderContainer>
      <SliderContainer isMobileRedesign={isMobileRedesign}>
        <Swiper
          observer
          observeParents
          id="main"
          controller={{ control: secondarySlider }}
          tag="section"
          wrapperTag="ul"
          parallax
          speed={600}
          navigation={{
            prevEl: prevRef.current ? prevRef.current : undefined,
            nextEl: nextRef.current ? nextRef.current : undefined,
          }}
          spaceBetween={0}
          slidesPerView={1}
          allowTouchMove
          pagination={
            isMobileSize && isMobileRedesign
              ? {
                  clickable: true,
                }
              : false
          }
          breakpoints={{
            920: {
              allowTouchMove: false,
            },
          }}
          onInit={(swiper) => {
            // Assign the custom button to the prevEl
            // eslint-disable-next-line no-param-reassign
            (swiper.params.navigation as NavigationOptions).prevEl =
              prevRef.current;
            // Assign the custom button to the nextEl
            // eslint-disable-next-line no-param-reassign
            (swiper.params.navigation as NavigationOptions).nextEl =
              nextRef.current;
            // Force update swiper with new navigation buttons
            swiper.navigation.update();
            setMainSlider(swiper);
          }}
          onSlideChange={onSlideChange}
        >
          <ParallaxComponent
            isMobileRedesign={isMobileRedesign}
            slot="container-start"
            data-swiper-parallax={sliderParallax}
            activeIndex={activeIndex}
            sections={sections}
            reduceToken={reduceToken}
          />
          {primarySlides}
        </Swiper>
        <SliderNavButton isPrev buttonRef={prevRef} />
        <SliderNavButton buttonRef={nextRef} />
        <Swiper
          observer
          observeParents
          id="controller"
          onSwiper={setSecondarySlider}
          controller={{ control: mainSlider }}
          allowTouchMove
          tag="section"
          wrapperTag="ul"
          speed={600}
          pagination={
            isMobileSize && isMobileRedesign
              ? false
              : {
                  clickable: true,
                }
          }
        >
          {secondarySlides}
        </Swiper>
      </SliderContainer>
    </Container>
  );
};

const Slider = memo(SliderBase);

export { Slider };
