import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';

import {
  Action,
  configureStore,
  isRejectedWithValue,
  Middleware,
  ThunkAction,
} from '@reduxjs/toolkit';

import { parseGqlErrors } from './modules/common/utils/parse-gql-errors';
import { api } from './generated';
import { mapboxApi } from './services/mapbox/mapbox-api';
import auth from './modules/auth/redux/authSlice';
import valuationWizard from './modules/forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import searchProfile from './modules/forms/form-search-profile/redux/searchProfileSlice';
import {
  reducer as valuationWizardV2,
  changeMapViewport,
  name as valuationWizardV2Name,
} from './modules/forms/form-property-valuation-wizard-v2/redux/valuationWizardV2Slice';
import {
  reducer as financing,
  changeMapViewport as changeMapViewportFinancing,
  name as financingName,
} from './modules/forms/form-financing/redux/financingSlice';
import {
  reducer as exposeDetails,
  name as exposeDetailsName,
  changeMapViewport as changeMapViewportED,
} from './modules/search-profile/pages/expose-details-overlay/redux/exposeSlice';
import {
  reducer as sellerExposeDetails,
  name as sellerExposeDetailsName,
  changeMapViewport as changeMapViewportSED,
} from './modules/seller/pages/expose-details-overlay/redux/exposeSlice';
import experiments from './modules/experiments/redux/experimentsSlice';
import map, {
  changeViewport,
  name as mapSliceName,
} from './modules/map/redux/mapSlice';
import propertyCollaborators from './modules/property/redux/propertyCollaboratorsSlice';
import timify from './modules/timify/redux/timifySlice';
import similarProperties from './modules/property/redux/similarPropertiesSlice';
import dynamicMapUtils from './modules/property/redux/dynamicMapUtilsSlice';
import suggestedProperties from './modules/search-profile/redux/suggestedPropertiesSlice';
import mobile from './modules/common/redux/mobileSlice';
import onlineExposes from './modules/search-profile/redux/onlineExposesSlice';
import favouriteProperties from './modules/search-profile/redux/favouritePropertiesSlice';
import requestExpose from './modules/search-profile/redux/requestExposeSlice';
import purchaseIntend from './modules/forms/form-purchase-intend/redux/purchaseIntendSlice';
import brokerView from './modules/broker-view/redux/brokerViewSlice';
import {
  reducer as sellerProperties,
  name as sellerPropertiesName,
  setPropertyDocuments,
  setPropertyPhotos,
  setPropertyPlans,
} from './modules/seller/redux/sellerPropertiesSlice';

/**
 * Log a warning and show a toast!
 */
const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action)) {
    const parsedErrorResponse = parseGqlErrors(
      action?.payload?.message ?? '[]'
    );
    const errors = parsedErrorResponse
      ?.map((e: Error) => e?.message)
      ?.join(', ');

    console.warn('We got a rejected action!');
    console.error({
      title: 'Async error!',
      message: errors,
    });
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [api.reducerPath]: api.reducer,
    [mapboxApi.reducerPath]: mapboxApi.reducer,
    valuationWizard,
    valuationWizardV2,
    financing,
    searchProfile,
    exposeDetails,
    brokerView,
    sellerExposeDetails,
    auth,
    experiments,
    map,
    propertyCollaborators,
    timify,
    similarProperties,
    suggestedProperties,
    dynamicMapUtils,
    mobile,
    onlineExposes,
    favouriteProperties,
    requestExpose,
    purchaseIntend,
    sellerProperties,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          changeViewport.type,
          changeMapViewport.type,
          changeMapViewportFinancing.type,
          changeMapViewportED.type,
          changeMapViewportSED.type,
          setPropertyDocuments.type,
          setPropertyPhotos.type,
          setPropertyPlans.type,
        ],
        ignoredPaths: [
          mapSliceName,
          valuationWizardV2Name,
          financingName,
          exposeDetailsName,
          sellerExposeDetailsName,
          sellerPropertiesName,
        ],
      },
    })
      // eslint-disable-next-line unicorn/prefer-spread
      .concat(api.middleware)
      // eslint-disable-next-line unicorn/prefer-spread
      .concat(mapboxApi.middleware)
      // eslint-disable-next-line unicorn/prefer-spread
      .concat(rtkQueryErrorLogger),
  devTools:
    process.env.NODE_ENV !== 'production'
      ? {
          trace: true,
          traceLimit: 50,
          maxAge: 20,
          // actionsBlacklist: [],
          // actionsWhitelist: [],
        }
      : false,
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization

// We might not need the refetch on focus or the other listeners.
// setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
