import styled from 'styled-components';
import {
  Headline3,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { SecondaryButton } from '../../../common/components/ui/buttons';

export const Container = styled.div<{ borderLeftColor?: string }>`
  padding: 12px;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.borderColor};
  border-left-width: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-left-color: ${(props) =>
    props.borderLeftColor ?? props.theme.borderColor};
`;

export const Title = styled(Headline3)`
  font-size: 16px;
  line-height: 1.25;
`;

export const Description = styled(ParagraphText)``;

export const CreatedOn = styled(ParagraphText)`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  opacity: 0.6;
  margin-left: 10px;
`;

export const Date = styled(CreatedOn)`
  opacity: 1;
  margin-left: 4px;
`;

export const ActionButton = styled(SecondaryButton)`
  height: 40px;
  padding: 10px 12px;
  justify-content: center;
`;

export const Row = styled.div<{
  justifyContent?: string;
  margin?: string;
  alignItems?: string;
  padding?: string;
  isColumn?: boolean;
  gap?: number;
}>`
  ${({ isColumn }) => isColumn && 'flex-direction: column'};
  display: flex;
  align-self: stretch;
  position: relative;
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};

  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};

  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ gap }) => gap && `gap: ${gap}px`};
`;
