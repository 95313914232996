import 'rc-collapse/assets/index.css';

import Collapse, { Panel } from 'rc-collapse';
import { CollapsePanelProps } from 'rc-collapse/lib/interface';
import { CSSProperties, memo, ReactNode, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as ArrowDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import { device } from '../../../../style/theme';
import Icon from '../../../common/components/ui/icon';
import { BoxText, Headline2 } from '../../../common/components/ui/typography';
import motion from './utils';

export interface AccordionSection {
  _id: string;
  title: string;
  subtitle?: string;
  expandedContent: ReactNode;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  Badge?: ReactNode;
  iconStyles?: CSSProperties;
}

interface IProps {
  id: string;
  accordionTitle?: string;
  accordionIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  accordionSection: AccordionSection;
  isOpen?: boolean;
  onChange?: (id: string) => void;
  isBadgeAtBottom?: boolean;
}

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  > i {
    margin-right: 20px;
    > svg {
      > path:first-of-type {
        stroke: ${(props) => props.theme.ctaBlue};
      }
    }
  }
  @media ${device.tablet} {
    margin-bottom: 16px;
    > i {
      margin-right: 8px;
      width: 32px;
      height: 32px;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled(Headline2)`
  font-size: 20px;
  line-height: 1.2;

  @media ${device.mobile} {
    font-size: 16px;
  }
`;

export const Subtitle = styled(BoxText)`
  font-weight: 900;
  letter-spacing: 1px;
  opacity: 0.6;

  @media ${device.mobile} {
    font-size: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  &&& {
    .icon {
      order: unset !important;
      margin-right: 12px;
    }
  }
`;

const BadgeWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 4px;

  & > div {
    margin-left: unset;
  }
`;

const Container = styled.div`
  &&& {
    .rc-collapse {
      border: none !important;
      background: transparent;

      .rc-collapse-item {
        border: none !important;
        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
        .faq-header {
          transition: border-radius 500ms ease, background-color 500ms ease;
          will-change: border-radius, background-color;
          padding: 12px;
          border-radius: 3px;
          cursor: pointer;
          margin: 0;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: 1px;
          color: ${(props) => props.theme.blue};
          background-color: ${(props) => props.theme.fileUploaderActiveHeader};
          border: 1px solid ${(props) => props.theme.grey};
          //height: 48px;

          .icon {
            margin-left: auto;
            order: 2;
            flex: 0 0 auto;
            path {
              stroke-width: 1.5px;
            }
          }
        }
        &.rc-collapse-item-active {
          .faq-header {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background-color: ${(props) =>
              props.theme.fileUploaderActiveHeader};
          }
          border: 1px solid ${(props) => props.theme.grey};
        }
        .rc-collapse-content {
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          background: ${(props) => props.theme.white};
          border: 1px solid ${(props) => props.theme.grey};
          border-top: none;
          overflow: hidden;
          color: ${(props) => props.theme.blue};
          padding: 0;
          margin: 0;
          .rc-collapse-content-box {
            margin: 0;
            padding: 12px;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: 0.5px;
            color: ${(props) => props.theme.blue};
            @media ${device.tablet} {
              padding: 12px;
              font-size: 12px;
              line-height: 1.67;
            }
          }
        }
      }
    }
  }
`;

const AccordionBase = ({
  id,
  accordionTitle,
  accordionIcon,
  accordionSection,
  isOpen,
  onChange,
  isBadgeAtBottom,
}: IProps) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Container
      id={id}
      onClick={(event) => {
        event.stopPropagation();
        onChange?.(id);
      }}
    >
      {accordionTitle && (
        <TitleContainer>
          {accordionIcon && (
            <Icon color="#000" width={40} height={40} icon={accordionIcon} />
          )}
          <Title content={accordionTitle} />
        </TitleContainer>
      )}
      <Collapse
        accordion
        openMotion={motion}
        className="faq-accordion"
        activeKey={isOpen ? accordionSection._id : undefined}
        expandIcon={({ isActive }: CollapsePanelProps) => {
          return (
            <Icon
              width={16}
              height={16}
              icon={ArrowDown}
              color={themeContext.blue}
              style={{
                transition: `transform 500ms ease`,
                transform: isActive ? 'rotate(-180deg)' : 'none',
              }}
            />
          );
        }}
      >
        <Panel
          key={accordionSection._id}
          header={
            <Header>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <TitleWrapper>
                  {accordionSection.icon && (
                    <Icon
                      color={themeContext.blue}
                      icon={accordionSection.icon}
                      style={accordionSection.iconStyles}
                    />
                  )}
                  <Title content={accordionSection.title} />
                  {!isBadgeAtBottom && accordionSection.Badge && (
                    <>{accordionSection.Badge}</>
                  )}
                </TitleWrapper>
                {accordionSection.subtitle && (
                  <Subtitle content={accordionSection.subtitle} />
                )}
                {isBadgeAtBottom && accordionSection.Badge && (
                  <BadgeWrapper style={{ display: 'flex' }}>
                    {accordionSection.Badge}
                  </BadgeWrapper>
                )}
              </div>
            </Header>
          }
          headerClass="faq-header"
          className="faq-content"
        >
          {accordionSection.expandedContent}
        </Panel>
      </Collapse>
    </Container>
  );
};

const Accordion = memo(AccordionBase);

export { Accordion };
