const getMobileDetect = (userAgent: string) => {
  const isAndroid = (): boolean => Boolean(/android/i.test(userAgent));
  const isIos = (): boolean => Boolean(/iphone|ipad|ipod/i.test(userAgent));
  const isIphone = (): boolean => Boolean(/iphone/i.test(userAgent));
  const isOpera = (): boolean => Boolean(/opera mini/i.test(userAgent));
  const isWindows = (): boolean => Boolean(/iemobile/i.test(userAgent));
  const isSSR = (): boolean => Boolean(/ssr/i.test(userAgent));

  const isMobile = (): boolean =>
    Boolean(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = (): boolean => Boolean(!isMobile() && !isSSR());

  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIphone,
    isIos,
    isSSR,
  };
};

const useDeviceDetector = (): ReturnType<typeof getMobileDetect> => {
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  return getMobileDetect(userAgent);
};

export { useDeviceDetector };
