export const disableBodyScroll = (disabled: boolean) => {
  if (disabled) {
    const { scrollY } = window;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = `100vw`;
  } else {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = 'auto';
    window.scrollTo(0, Number.parseInt(scrollY || '0', 10) * -1);
  }
};
