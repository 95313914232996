import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PercentageIcon } from 'assets/streamline-light/money-payments-finance/money/percentage.svg';
import { ReactComponent as CashPaymentBillIcon } from 'assets/streamline-light/money-payments-finance/cash-payments/cash-payment-bill-5.svg';
import { ReactComponent as AccountingBillsIcon } from 'assets/streamline-light/money-payments-finance/money/accounting-bills-1.svg';
import { ReactComponent as CalendarIcon } from 'assets/streamline-light/interface-essential/date-calendar/calendar.svg';

import {
  Container,
  FlexBox,
  LoanAmount,
  LoanAmountBox,
  LoanAmountText,
  SectionsContainer,
} from './financing-information-styles';
import { IconSection } from '../icon-section/icon-section';
import { Propositions } from '../../../../../generated';
import { FinancingSkeleton as Skeleton } from '../../financing-skeleton/financing-skeleton';
import { getTotalTermOfLoanUntilFullRepayment } from '../../../utils/getTotalTermOfLoanUntilFullRepayment';
import { numberFormat } from '../../../utils/numberFormat';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';

interface IProps {
  isLoading: boolean;
  proposition?: Propositions;
}

const FinancingInformation: FC<IProps> = ({ isLoading, proposition }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobileSize();
  const buildingBlock = proposition?.financingBuildingBlocks?.[0];
  const totalTermOfLoanUntilFullRepayment =
    getTotalTermOfLoanUntilFullRepayment(
      buildingBlock?.dateOfLastInstallment ?? ''
    );

  return (
    <Container>
      <LoanAmountBox>
        <LoanAmount>
          <Skeleton isLoading={isLoading} width="100%" mb={12} mt={10}>
            <>{`${numberFormat(proposition?.loanAmount)} €`}</>
          </Skeleton>
        </LoanAmount>
        <LoanAmountText>
          {t('financing-portal-page.overview-card.info.loan-amount')}
        </LoanAmountText>
      </LoanAmountBox>
      <SectionsContainer>
        <div>
          <FlexBox>
            <IconSection
              icon={CashPaymentBillIcon}
              value={
                <Skeleton isLoading={isLoading} width="69px " mb={7} mt={7}>
                  <>{`${numberFormat(proposition?.totalRatePerMonth)} €`}</>
                </Skeleton>
              }
              text={t(
                'financing-portal-page.overview-card.info.monthly-loan-installment'
              )}
            />
            {isMobile && (
              <IconSection
                icon={AccountingBillsIcon}
                value={
                  <Skeleton isLoading={isLoading} width="43px " mb={7} mt={7}>
                    <>{`${numberFormat(
                      proposition?.shouldInterest,
                      true
                    )} %`}</>
                  </Skeleton>
                }
                text={t(
                  'financing-portal-page.overview-card.info.debit-interest'
                )}
              />
            )}
          </FlexBox>
          <IconSection
            icon={PercentageIcon}
            value={
              <Skeleton isLoading={isLoading} width="38px " mb={7} mt={7}>
                <>{`${numberFormat(
                  proposition?.financingBuildingBlocks?.[0]
                    ?.repaymentRateInPercent,
                  true
                )} %`}</>
              </Skeleton>
            }
            text={t('financing-portal-page.overview-card.info.repayment-rate')}
          />

          {isMobile && (
            <IconSection
              icon={CalendarIcon}
              value={
                <Skeleton isLoading={isLoading} width="122px " mb={7} mt={7}>
                  <>{totalTermOfLoanUntilFullRepayment}</>
                </Skeleton>
              }
              text={t(
                'financing-portal-page.overview-card.info.duration-of-the-loan-until-full-repayment'
              )}
            />
          )}
        </div>
        {!isMobile && (
          <div>
            <IconSection
              icon={AccountingBillsIcon}
              value={
                <Skeleton isLoading={isLoading} width="43px " mb={7} mt={7}>
                  <>{`${numberFormat(proposition?.shouldInterest, true)} %`}</>
                </Skeleton>
              }
              text={t(
                'financing-portal-page.overview-card.info.debit-interest'
              )}
            />

            <IconSection
              icon={CalendarIcon}
              value={
                <Skeleton isLoading={isLoading} width="122px " mb={7} mt={7}>
                  <>{totalTermOfLoanUntilFullRepayment}</>
                </Skeleton>
              }
              text={t(
                'financing-portal-page.overview-card.info.duration-of-the-loan-until-full-repayment'
              )}
            />
          </div>
        )}
      </SectionsContainer>
    </Container>
  );
};

export { FinancingInformation };
