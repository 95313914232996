import { FC, useCallback, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InformationCircle } from 'assets/streamline-light/interface-essential/alerts/information-circle.svg';
import { ReactComponent as InformationCircleBold } from 'assets/images/icons/common/tooltip-key-bold.svg';

import {
  Container,
  ErrorContainer,
  ErrorMessage,
  Info,
  InfoWrapper,
  InputAdornment,
  InputWrapper,
  Label,
  LabelContainer,
  SelectDropdown,
  SelectField,
  SelectOption,
  Spacer,
  SubLabel,
  TooltipContainer,
  TooltipParagraph,
  Wrapper,
  SelectIcon,
  Placeholder,
} from './select-v2-styles';
import { Tooltip } from '../../../../common/components/tooltip';
import { OutsideClickHandler } from '../../../../common/components/outside-click-handler';
import { IOption, IProps } from './interface';

const SelectV2: FC<IProps> = ({
  label,
  sublabel,
  name,
  placeholder,
  backgroundColor,
  rules,
  defaultValue,
  inputWrapperChildren,
  skipIcon = false,
  tooltipKey,
  tooltipPosition,
  isBoldTooltipKeyIcon,
  isTooltipKeyLeftSide,
  selectOptions,
  isOptionsAtTop,
  isDisabled,
  isReadOnly,
  isRequired,
  isWrapper,
  isPlaceholderCenter,
  isColumn,
  isOldDesign,
  isSaturatedBorder,
  isNoBorder,
  inputAdornment,
  handleChangeProp,
}) => {
  const {
    field: { ref, value, onChange },
    formState: { errors },
  } = useController({
    name,
    rules,
    defaultValue: rules?.value ?? defaultValue,
  });

  const valueLabel = useMemo(() => {
    return selectOptions?.find((item) => item.value === value)?.label;
  }, [selectOptions, value]);

  const { t } = useTranslation();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: valueLabel,
    value,
  });

  const onSelectClose = useCallback(() => setIsSelectOpen(false), []);
  const onSelectToggle = useCallback(
    () => setIsSelectOpen(!isSelectOpen),
    [isSelectOpen]
  );

  const onSelectOptionClick = useCallback(
    (option: IOption) => () => {
      setSelectedOption(option);
      onChange(option.value);
      if (handleChangeProp) {
        handleChangeProp(option.value);
      }

      onSelectClose();
    },
    [onChange, handleChangeProp, onSelectClose]
  );

  const isValue =
    value !== '' &&
    value !== undefined &&
    value !== null &&
    !Number.isNaN(value);

  const onClickLabel = () => {
    if (tooltipKey) {
      const tooltip = document.querySelector(
        `#${tooltipKey.replaceAll('.', '-')}-icon`
      ) as HTMLParagraphElement;
      if (tooltip) {
        tooltip.click();
      }
    }
  };

  return (
    <Container hasError={errors[name]} isWrapper={isWrapper}>
      <Wrapper isColumn={isColumn}>
        <InfoWrapper isTooltipKeyLeftSide={isTooltipKeyLeftSide}>
          {label && (
            <LabelContainer
              onClick={onClickLabel}
              isTooltipKeyLeftSide={isTooltipKeyLeftSide}
            >
              <Label isOldDesign={isOldDesign}>
                {t(label)}
                {isRequired && '*'}
              </Label>
              {sublabel && <SubLabel>{t(sublabel)}</SubLabel>}
            </LabelContainer>
          )}
          {!skipIcon && tooltipKey && (
            <Info>
              <Tooltip
                icon={
                  isBoldTooltipKeyIcon
                    ? InformationCircleBold
                    : InformationCircle
                }
                isOnClick
                id={tooltipKey}
                position={tooltipPosition || 'bottom'}
                width={16}
                height={16}
              >
                <TooltipContainer>
                  <TooltipParagraph>{t(tooltipKey)}</TooltipParagraph>
                </TooltipContainer>
              </Tooltip>
            </Info>
          )}
        </InfoWrapper>
        <InputWrapper isColumn={isColumn}>
          <OutsideClickHandler display="block" onOutsideClick={onSelectClose}>
            <SelectField
              id={name}
              className="select"
              ref={ref}
              isFocus={isSelectOpen}
              placeholder={t(placeholder ?? '')}
              onClick={onSelectToggle}
              hasError={errors[name]}
              backgroundColor={backgroundColor}
              isNoBorder={isNoBorder}
              isValue={isValue}
              isDisabled={isDisabled}
              isOldDesign={isOldDesign}
              isSaturatedBorder={isSaturatedBorder}
              isPlaceholderCenter={isPlaceholderCenter}
              readOnly={isReadOnly}
            >
              <SelectIcon isopen={String(isSelectOpen)} />
              {isValue ? (
                t(valueLabel || '')
              ) : (
                <Placeholder>{t(placeholder ?? '')}</Placeholder>
              )}
            </SelectField>
            {isSelectOpen && (
              <SelectDropdown
                className="select-opened"
                isOptionsAtTop={isOptionsAtTop}
              >
                {selectOptions?.map((option) => (
                  <SelectOption
                    key={option.label}
                    isSelected={selectedOption.value === option.value}
                    onClick={onSelectOptionClick(option)}
                  >
                    {t(`${option.label}`)}
                  </SelectOption>
                ))}
              </SelectDropdown>
            )}
          </OutsideClickHandler>
          {inputAdornment && <InputAdornment>{inputAdornment}</InputAdornment>}
        </InputWrapper>
        {inputWrapperChildren}
      </Wrapper>

      {errors[name] && (
        <ErrorContainer className="field-error">
          <Spacer />
          <ErrorMessage>{t(errors[name].message)}</ErrorMessage>
        </ErrorContainer>
      )}
    </Container>
  );
};

export { SelectV2 };
