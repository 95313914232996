import styled from 'styled-components';
import { device } from '../../../../../../style/theme';
import { px2vw } from '../../../../../common/utils/px2vw';

interface IProps {
  image: string;
  disclaimer: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  @media ${device.tabletSlider} {
    height: ${px2vw(370, 320)};
    max-height: 600px;
    max-width: 520px;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

const Disclaimer = styled.p`
  position: absolute;
  white-space: nowrap;
  bottom: 56px;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${(props) => props.theme.blue};
  @media ${device.tabletSlider} {
    bottom: 36px;
    padding: 0 16px;
    font-size: 12px;
    line-height: 1.67;
  }
`;

const SecondarySlide = ({ image, disclaimer }: IProps): JSX.Element => (
  <Container>
    <StyledImg src={image} />
    <Disclaimer>{disclaimer}</Disclaimer>
  </Container>
);

export { SecondarySlide };
