import { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import {
  Container,
  Header,
  Title,
  // BulletsBox,
  // Bullet,
  Text,
  DateContainer,
  DateText,
  DateValue,
  BulletsBox,
  Bullet,
} from './financing-overview-card-styles';
import { FinancingInformation } from './financing-information/financing-information';
import { FinancingSkeleton as Skeleton } from '../financing-skeleton/financing-skeleton';
import { Propositions } from '../../../../generated';
import { formatPeriodDate } from '../../../property/utils/date';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { SecondaryButton } from '../../../common/components/ui/buttons';
import { siteMap } from '../../../../routes/site-map';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { ReactComponent as CommonFileText } from '../../../../assets/streamline-light/files-folders/common-files/common-file-text.svg';

const bullets = [
  'financing-portal-page.slider.overview-card.bullet1',
  'financing-portal-page.slider.overview-card.bullet2',
  'financing-portal-page.slider.overview-card.bullet3',
  'financing-portal-page.slider.overview-card.bullet4',
];

interface IProps {
  isLoading: boolean;
  isEmptyState: boolean;
  financingReason: string;
  proposition?: Propositions;
  updatedAt?: string;
  onlineExposeLink?: string;
}

const FinancingOverviewCardBase: FC<IProps> = ({
  isLoading,
  isEmptyState,
  financingReason,
  proposition,
  updatedAt,
  onlineExposeLink,
}) => {
  const isMobileSize = useIsMobileSize();
  const theme = useContext(ThemeContext);
  const lngHPush = useLngHistoryPush();
  const { t } = useTranslation();

  const date = formatPeriodDate(
    updatedAt ? new Date(updatedAt) : new Date(),
    true,
    true
  );

  return (
    <Container>
      <Header
        content={t(
          isEmptyState
            ? 'financing-portal-page.slider.overview-card.header'
            : 'financing-portal-page.overview-card.header'
        )}
      />
      <Skeleton isLoading={isLoading} width="320px" mt={17} mb={29}>
        <Title
          content={t(
            financingReason ||
              'financing-portal-page.slider.overview-card.title'
          )}
        />
      </Skeleton>

      {isEmptyState ? (
        <BulletsBox>
          {bullets.map((bullet) => {
            return <Bullet key={bullet}>{t(bullet)}</Bullet>;
          })}
        </BulletsBox>
      ) : (
        <>
          <Text>{t('financing-portal-page.overview-card.description')}</Text>
          <FinancingInformation
            proposition={proposition}
            isLoading={isLoading}
          />
          <DateContainer>
            <DateValue>
              <Skeleton isLoading={isLoading} width="67px">
                <>{date}</>
              </Skeleton>
            </DateValue>
            <DateText>
              {t(
                'financing-portal-page.overview-card.info.the-calculation-results-are-updated-at-regular-intervals'
              )}
            </DateText>
          </DateContainer>
          {isMobileSize && (
            <>
              <SecondaryButton
                label={t(
                  'financing-portal-page.address-bar.button.open-edit-modal'
                ).toUpperCase()}
                onClick={() =>
                  lngHPush(
                    `${siteMap.BuyerLandingPage.pathWithoutParams}?${siteMap.BuyerLandingPage.qp?.openWidget}`
                  )
                }
                color={theme.blue}
                borderColor={theme.blue}
                backgroundColor={theme.white}
                style={{
                  padding: '10px',
                  minWidth: '100%',
                  height: '40px',
                  whiteSpace: 'nowrap',
                }}
              />
              {onlineExposeLink && (
                <SecondaryButton
                  label={t(
                    'search-profile.suggested-properties.expose-details'
                  )}
                  onClick={() => {
                    lngHPush(onlineExposeLink);
                  }}
                  color={'rgb(78, 115, 245)'}
                  borderColor={'rgb(78, 115, 245)'}
                  backgroundColor={theme.white}
                  icon={CommonFileText}
                  iconStyle={{
                    padding: '3px',
                    margin: '0 8px 0 0',
                  }}
                  style={{
                    padding: '10px',
                    minWidth: '100%',
                    height: '40px',
                    whiteSpace: 'nowrap',
                    marginTop: '8px',
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

const FinancingOverviewCard = memo(FinancingOverviewCardBase);

export default FinancingOverviewCard;
