import { FC } from 'react';
import styled from 'styled-components';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputWrapper } from '../../financing-data-edit-form-styles';
import { CalculateValue } from '../../calculate-value/calculate-value';
import { Separator } from '../../separator/separator';
import { InputV2 } from '../../../../../../../components/common/input-v2/input-v2';
import { FIELDS, FINANCING_FORM_KEYS } from '../../../../../../constants';
import { IFinancingForm } from '../../../../../../interface';
import { DatePicker } from '../../../../../../../components/common/datepicker/datepicker';
import { numberFormat } from '../../../../../../../../financing/utils/numberFormat';
import { checkPayoutDate } from '../../../../../../hooks/useStepsLogic';
import { device } from '../../../../../../../../../style/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .info {
    margin-right: 0;
  }
  .label {
    width: auto;
    opacity: 1;
  }
  .date-picker-container {
    width: auto;
    max-width: auto;
  }
  .input-wrapper {
    margin-bottom: 0;
    flex-wrap: wrap;
  }

  @media ${device.tablet} {
    .date-picker-container {
      width: 100%;
      max-width: 100%;
    }
  }
`;

interface IProps {
  finData: IFinancingForm;
  setDirtyTrue(): void;
}

const FollowUpFinancingFields: FC<IProps> = ({ finData, setDirtyTrue }) => {
  const { t } = useTranslation();
  const [estimatedRemainingDebt, propertyValue] = useWatch({
    name: [
      FINANCING_FORM_KEYS.ESTIMATED_REMAINING_DEBT,
      FINANCING_FORM_KEYS.PROPERTY_VALUE,
    ],
  });

  return (
    <Container>
      <InputV2
        {...FIELDS.PROPERTY_VALUE}
        defaultValue={finData.propertyValue}
        inputAdornment="€"
        isValidateOnBlur
        isSeparate
        isRequired={false}
        tooltipWidth={230}
        isLabelSaturated
        isOldDesign
        isTooltipKeyLeftSide
        isBoldTooltipKeyIcon
        validateNames={
          estimatedRemainingDebt
            ? [
                FINANCING_FORM_KEYS.PROPERTY_VALUE,
                FINANCING_FORM_KEYS.ESTIMATED_REMAINING_DEBT,
              ]
            : undefined
        }
        rules={{
          ...FIELDS.PROPERTY_VALUE.rules,
          validate: (value: number) => {
            return Number(value) * 1.2 < Number(estimatedRemainingDebt)
              ? t(
                  'financing-portal.form.inputs.property-value.errors.estimated-remaining-debt-value'
                ).toString()
              : true;
          },
        }}
        handleChangeProp={() => {
          setDirtyTrue();
        }}
      />
      <DatePicker
        {...FIELDS.PAYOUT_DATE}
        isOnClickTooltip
        tooltipWidth={260}
        isBoldTooltipKeyIcon
        isTooltipKeyLeftSide
        defaultValue={undefined}
        labelStyle={{ paddingRight: 8 }}
        dateFormat={'dd.MM.yyyy'}
        isValidateOnBlur
        rules={{
          ...FIELDS.PAYOUT_DATE.rules,
          validate: (value: string) => {
            return checkPayoutDate(value)
              ? true
              : t(
                  'financing-portal.form.inputs.payout-date.errors.max-date'
                ).toString();
          },
        }}
        handleChangeProp={() => {
          setDirtyTrue();
        }}
      />
      <InputV2
        {...FIELDS.ESTIMATED_REMAINING_DEBT}
        defaultValue={finData.estimatedRemainingDebt}
        inputAdornment="€"
        isValidateOnBlur
        isSeparate
        isRequired={false}
        isLabelSaturated
        isOldDesign
        isTooltipKeyLeftSide
        isBoldTooltipKeyIcon
        validateNames={
          propertyValue
            ? [
                FINANCING_FORM_KEYS.PROPERTY_VALUE,
                FINANCING_FORM_KEYS.ESTIMATED_REMAINING_DEBT,
              ]
            : undefined
        }
        rules={{
          ...FIELDS.DESIRED_LOAN_AMOUNT.rules,
          validate: (value: number) => {
            return Number(value) > Number(propertyValue * 1.2)
              ? t(
                  'financing-portal.form.inputs.estimated-remaining-debt.errors.property-value'
                ).toString()
              : true;
          },
        }}
        handleChangeProp={() => {
          setDirtyTrue();
        }}
      />
      <Separator />
      <InputWrapper content="=" minHeight="40px">
        <CalculateValue
          tooltipWidth={270}
          label="financing-portal-page.tabs.calculator.edit.inputs.loan-amount.label"
          value={`${numberFormat(estimatedRemainingDebt)} €`}
          tooltipKey="financing-portal-page.tabs.calculator.edit.inputs.loan-amount.tooltip"
          isError={estimatedRemainingDebt < 0}
        />
      </InputWrapper>
    </Container>
  );
};

export { FollowUpFinancingFields };
