import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const ContentWithPadding = styled.div`
  padding: 0 48px;
  @media ${device.tablet} {
    padding: 0;
  }
`;

export const CTAContainer = styled.div`
  position: absolute;
  bottom: -24px;
  right: 48px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media ${device.tablet} {
    position: static;
    padding: 0 16px;
  }
`;

export const HeaderWrapper = styled.div<{
  isTabListNearTop?: boolean;
  floatingBar?: boolean;
}>`
  display: flex;
  position: sticky;
  top: ${({ floatingBar }) => (floatingBar ? '142px' : '80px')};
  margin: 0 0 16px;
  border-radius: ${(props) => (props.isTabListNearTop ? '0 0 3px 3px' : '3px')};
  background-color: ${(props) => props.theme.logoBlue};
  z-index: 2;

  ul {
    display: flex;
    align-items: center;
    padding: 0 24px;

    > li {
      outline: none !important;
      list-style: none;
      padding: 16px 32px;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        height: 16px;
        width: 16px;
        border: 1px solid white;
        border-radius: 50%;
        box-sizing: border-box;
        background: #fff;
      }

      &:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: ${(props) => props.theme.blue};
        border-radius: 50%;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
      }

      &:first-child {
        padding-left: 0;
        &::before,
        &::after {
          margin-left: -16px;
        }
      }

      &.react-tabs__tab--selected {
        overflow: visible;
        cursor: default;
      }

      &:not(.react-tabs__tab--selected) {
        opacity: 0.5;

        &:before {
          background: ${(props) => props.theme.blue};
        }

        &:after {
          background: #fff;
        }
      }
    }
  }

  @media ${device.tablet} {
    display: flex;
    overflow: auto;
    white-space: nowrap;
    border-radius: unset;
    height: unset;
    max-width: unset;
    position: static;
    -webkit-box-shadow: inset 25px 0 10px -5px rgb(0 48 94 / 100%),
      inset -25px 0 10px -5px rgb(0 48 94 / 100%);
    box-shadow: inset 25px 0 10px -5px rgb(0 48 94 / 100%),
      inset -25px 0 10px -5px rgb(0 48 94 / 100%);

    ul {
      padding: 0 12px;

      li {
        padding: 0;

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
  }
`;

export const FirstColumn = styled.div`
  flex: 1.72;
  margin-right: 16px;
`;

export const SecondColumn = styled.div`
  flex: 1;
`;

export const UnlockCardContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 668px;
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  cursor: pointer;
  width: 12px;
  height: 12px;
`;

export const MobileMapContainer = styled.div`
  position: relative;
  height: 160px;
  overflow: hidden;
`;

export const MobileHeadContainer = styled.div`
  position: relative;
`;
