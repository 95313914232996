import styled from 'styled-components';
import {
  Headline3,
  ParagraphText,
} from '../../../../../common/components/ui/typography';

export const Container = styled.div`
  padding: 12px;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.borderColor};
  display: flex;
  align-items: center;
`;

export const Title = styled(Headline3)``;

export const Text = styled(ParagraphText)`
  margin-left: 12px;
`;
