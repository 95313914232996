import { FC } from 'react';
import { Title } from '../styles';
import { ForgotPasswordForm } from '../../../../../auth/components/forms/forgot-password-form';

const ForgotPasswordContainer: FC = () => {
  return (
    <>
      <Title>{'Passwort vergessen'}</Title>
      <ForgotPasswordForm isRedesign isWizardForm />
    </>
  );
};

export { ForgotPasswordContainer };
