import { useEffect } from 'react';
import { batch } from 'react-redux';

import {
  Coordinates,
  Isochrones,
  IsochronesMode,
  Poi,
  PoiCategory,
  PoiSubCategory,
  Transport,
  useLazyIsochronesQuery,
} from '../../../generated';
import {
  ReachabilityLegendMode,
  setCommuteTimeCoordinates,
  setReachabilityLegendMode,
  toggleReachabilityLegend,
} from '../../property/redux/dynamicMapUtilsSlice';
import { IMapPin } from '../interfaces';
import { formatDistance } from '../../property/components/card/connection-card/connection-card';
import { addMarkers, removeAllMarkersExceptHome } from '../redux/mapSlice';
import {
  addMapMarkers,
  removeAllMapMarkersExceptHome,
  setMapMarkers,
} from '../../search-profile/pages/expose-details-overlay/redux/exposeSlice';
import { useAppDispatch } from '../../common/hooks';

interface IProps {
  coordinates?: Coordinates;
  transportMode: IsochronesMode;
  transportPOI?: Transport | null;
  reachabilityLegendMode: ReachabilityLegendMode;
  currentMapboxMarkers: IMapPin[];
  isExposeRequest?: boolean;
  countryCode: string;
}

const useTravelTime = ({
  coordinates,
  transportMode,
  transportPOI,
  reachabilityLegendMode,
  currentMapboxMarkers,
  isExposeRequest,
  countryCode,
}: IProps) => {
  const dispatch = useAppDispatch();
  const input = {
    countryCode,
    cutoff: 15,
    returnDensities: true,
    mode: transportMode,
    coordinates: coordinates || { latitude: 0, longitude: 0 },
  };

  const [request15m, isochrones15] = useLazyIsochronesQuery({
    selectFromResult: ({ data }) => data?.isochrones as Isochrones,
  });

  const [request30m, isochrones30] = useLazyIsochronesQuery({
    selectFromResult: ({ data }) => data?.isochrones as Isochrones,
  });

  const [request45m, isochrones45] = useLazyIsochronesQuery({
    selectFromResult: ({ data }) => data?.isochrones as Isochrones,
  });
  const coordinates15 = isochrones15?.isochrone?.geometry?.coordinates?.[0];
  const coordinates30 = isochrones30?.isochrone?.geometry?.coordinates?.[0];
  const coordinates45 = isochrones45?.isochrone?.geometry?.coordinates?.[0];

  const densities15 = isochrones15?.densities;
  const densities30 = isochrones30?.densities;
  const densities45 = isochrones45?.densities;

  useEffect(() => {
    request15m({ input });
    request30m({ input: { ...input, cutoff: 30 } });
    request45m({ input: { ...input, cutoff: 45 } });
  }, [coordinates, transportMode]);

  useEffect(() => {
    if (reachabilityLegendMode !== 'localTransitLines') {
      if (coordinates15 && coordinates30 && coordinates45) {
        dispatch(
          setCommuteTimeCoordinates({
            minutes15: coordinates15,
            minutes30: coordinates30,
            minutes45: coordinates45,
          })
        );
        dispatch(removeAllMarkersExceptHome());
        dispatch(removeAllMapMarkersExceptHome());
      }
      return;
    }

    dispatch(setCommuteTimeCoordinates());

    const markers: IMapPin[] = [];

    if (transportPOI) {
      Object.values(transportPOI).forEach((value) => {
        if (!value) return;

        (value as Poi[]).slice(0, 5).forEach((poi) => {
          if (poi?.location?.coordinates) {
            const newMarker = { ...poi.location.coordinates } as IMapPin & {
              marker: PoiSubCategory;
              category: PoiCategory;
            };

            newMarker.marker = poi.subcategory as PoiSubCategory;
            newMarker.category = poi.category as PoiCategory;
            newMarker.info = `${poi.name || ''} ${formatDistance(
              poi.distance
            )}`;

            if (newMarker.latitude && newMarker.longitude) {
              markers.push(newMarker);
            }
          }
        });
      });

      if (isExposeRequest) {
        dispatch(
          addMapMarkers([
            ...currentMapboxMarkers.filter(
              (current) => current.marker === 'transport'
            ),
            ...markers,
          ])
        );
      } else {
        dispatch(
          addMarkers([
            ...currentMapboxMarkers.filter(
              (current) => current.marker === 'transport'
            ),
            ...markers,
          ])
        );
      }
    }
  }, [
    dispatch,
    reachabilityLegendMode,
    transportPOI,
    coordinates15,
    coordinates30,
    coordinates45,
  ]);

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(toggleReachabilityLegend(false));
        dispatch(setCommuteTimeCoordinates());
        dispatch(setMapMarkers([]));
        dispatch(setReachabilityLegendMode('commuteTime'));
      });
    };
  }, []);

  return { densities15, densities30, densities45 };
};

export { useTravelTime };
