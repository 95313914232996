import { memo, useEffect, useState } from 'react';
import Loader from 'react-spinners/BarLoader';
import styled from 'styled-components';

import MunchenImage from '../../../../assets/aktuelle-marktnachfrage.jpg';
import {
  useGeneralMarketReportByCountryQuery,
  useMarketReportQuery,
} from '../../../../generated';
import { device } from '../../../../style/theme';
import { SecondaryButton } from '../../../common/components/ui/buttons';
import {
  Headline2,
  TitleSlogan,
} from '../../../common/components/ui/typography';
import useGetLocalization from '../../../localization/get-localization';

const getCityImage = () => MunchenImage;

const ContentBox = styled.div`
  @media ${device.tablet} {
    padding: 0 16px 16px;
    border-bottom: 1px solid #d9d9d9;
  }
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  padding: 50px 32px;

  @media ${device.tablet} {
    padding: 16px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Headline2)`
  color: #fff;
  margin-bottom: 8px;

  @media ${device.tablet} {
    margin-bottom: 4px;
  }
`;

const SubTitle = styled(TitleSlogan)`
  color: #fff;

  @media ${device.tablet} {
    text-align: center;
    margin-bottom: 32px;
  }
`;

const Link = styled.a`
  text-decoration: none;
`;

const Image = styled.img`
  position: absolute;
  display: block;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
  border-radius: 3px;

  @media ${device.tablet} {
    max-width: initial;
  }
`;

interface IProps {
  city: string;
}

const ActualMarketReportBase = ({ city }: IProps): JSX.Element => {
  const lang = useGetLocalization();
  const [input, setInput] = useState({
    filters: {
      city: city.toLowerCase(),
    },
  });

  const {
    data: dataGeneral,
    isLoading: isLoadingGeneral,
    error: errorGeneral,
  } = useGeneralMarketReportByCountryQuery({ country: lang });

  const { data, isLoading, error } = useMarketReportQuery(input, {
    skip: !city,
  });

  useEffect(() => {
    setInput({
      filters: {
        city: city.toLowerCase(),
      },
    });
  }, [city]);

  // Temporary hide by https://gitlab.com/vpi-customer-portal/current-sprint/-/issues/719
  return <></>;

  if (isLoading || isLoadingGeneral) {
    return <Loader />;
  }

  if (error || errorGeneral) {
    return <></>;
  }

  return (
    <ContentBox>
      <Container>
        <Image src={getCityImage()} />
        <Overlay />
        <Row>
          <TextColumn>
            <Title content="Aktueller Marktbericht" />
            <SubTitle content={city.toLocaleUpperCase()} />
          </TextColumn>
          <Link
            target="_blank"
            href={data?.marketReport?.fileUrl || dataGeneral?.data?.fileUrl}
            rel="noreferrer"
          >
            <SecondaryButton
              label="BERICHT HERUNTERLADEN"
              onClick={() => false}
              borderColor="#ffffff"
              color="#ffffff"
              backgroundColor="transparent"
              highlightColor="#ffffff"
            />
          </Link>
        </Row>
      </Container>
    </ContentBox>
  );
};

const ActualMarketReport = memo(ActualMarketReportBase);

export { ActualMarketReport };
