import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PurchaseIntentType } from '../../../../generated';
import { RootState } from '../../../../store';
import { EMAIL_REGEX } from '../../../common/constants';

export const useValidate = (propertyPrice?: number): (() => boolean) => {
  const { getValues, setError, clearErrors } = useFormContext();
  const { t } = useTranslation();
  const { activeStep, structure, type } = useSelector(
    (state: RootState) => state.purchaseIntend
  );

  return () => {
    clearErrors();
    const stepsCount = structure[type].length;
    const index = activeStep - 1;

    const formState = getValues();

    if (type === PurchaseIntentType.Physical) {
      if (activeStep === 0) {
        const validEmail = EMAIL_REGEX.test(formState.email);

        if (!validEmail) {
          setError('email', {
            type: 'custom',
            message: t('purchase-intent.form.error.email'),
          });
          return false;
        }
      }

      if (activeStep > 0 && activeStep < stepsCount - 1) {
        const additionalPerson = formState.otherPersons[index];
        const validEmail = EMAIL_REGEX.test(additionalPerson.email);

        if (!validEmail) {
          setError(`otherPersons:${index}:email`, {
            type: 'custom',
            message: t('purchase-intent.form.error.email'),
          });
          return false;
        }
      }

      if (activeStep === stepsCount - 1) {
        const validPrice =
          formState.price > (propertyPrice ? propertyPrice * 0.7 : 0);

        if (!validPrice) {
          setError('price', {
            type: 'custom',
            message: t('purchase-intent.form.error.price'),
          });
          return false;
        }
      }
    }

    if (type === PurchaseIntentType.Legal) {
      if (activeStep === 1) {
        const validEmail = EMAIL_REGEX.test(formState.email);

        if (!validEmail) {
          setError('email', {
            type: 'custom',
            message: t('purchase-intent.form.error.email'),
          });
          return false;
        }
      }

      if (activeStep > 1 && activeStep < stepsCount - 1) {
        const additionalPerson = formState.otherPersons[index - 1];
        const validEmail = EMAIL_REGEX.test(additionalPerson.email);

        if (!validEmail) {
          setError(`otherPersons:${index - 1}:email`, {
            type: 'custom',
            message: t('purchase-intent.form.error.email'),
          });
          return false;
        }
      }

      if (activeStep === stepsCount - 1) {
        const validPrice = formState.price > 0;

        if (!validPrice) {
          setError('price', {
            type: 'custom',
            message: t('purchase-intent.form.error.price'),
          });
          return false;
        }
      }
    }

    return true;
  };
};
