import { MouseEvent } from 'react';
import styled from 'styled-components';

interface IProps {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  fluid?: boolean;
  margin?: string;
}
const BaseButton = styled.button<IProps>`
  border-radius: 3px;
  padding: 14px 24px;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  width: ${(props) => (props.fluid ? '100%' : 'auto')};
  ${(props) => props.margin && `margin: ${props.margin};`}
`;

export { BaseButton };
