import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { isEmpty, isNil } from 'lodash';

import { ReactComponent as Building } from 'assets/streamline-light/building-construction/buildings/buildings-2.svg';
import { ReactComponent as BuildingYear } from 'assets/streamline-light/building-construction/utilities/building-year.svg';
import { ReactComponent as RollerIcon } from 'assets/streamline-light/design/colors-painting/color-rolling-brush.svg';
import { ReactComponent as RealEstateDimensionsBlock } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as ResizeExpandSides } from 'assets/streamline-light/design/resize/resize-expand-sides.svg';
import { ReactComponent as FloorsIcon } from 'assets/streamline-light/wayfinding/lifts/lift-two-people.svg';
import { ReactComponent as Bathroom } from 'assets/streamline-light/hotels-spas/bathroom/bathroom-tub-towel.svg';
import { ReactComponent as BalconyIcon } from 'assets/streamline-light/building-construction/buildings/building-daylight.svg';
import { ReactComponent as HouseGarage } from 'assets/streamline-light/building-construction/houses/house-garage.svg';
import { ReactComponent as RealEstateDimensionsPlan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-plan.svg';
import { ReactComponent as MoneyBagDollarIcon } from 'assets/streamline-light/money-payments-finance/money/money-bag-dollar.svg';
// import { ReactComponent as ParkingIcon } from 'assets/streamline-light/shopping-ecommerce/discount-coupons/discount-parking-2.svg';

import PropertyValuationIcon from '../../../../../property/components/icon/property-valuation-icon';
import { InfoContainer, IconRow } from './property-details-styles';
import { extractPropertyTypeLabelAndIcon } from '../../../../../property/utils/property-type-normalizer';
import { useGetPropertyCode } from '../../../hooks/useStepsLogic';
import { propertySubtypeNormalizer } from '../../../utils/property-subtype-normalizer';
import { useAppSelector } from '../../../../../common/hooks';
import {
  PropertyCode,
  useGetTemporaryPropertyQuery,
} from '../../../../../../generated';

interface IProps {
  propsData?: {
    type?: string | null;
    buildingYear?: string | null;
    modernizationYear?: string | null;
    livingArea?: number | null;
    landArea?: number | null;
    floor?: number | null;
    numberOfRooms?: number | null;
    numberOfBedrooms?: number | null;
    numberOfBathrooms?: number | null;
    numberOfParkingSpace?: number | null;
    numberOfGarages?: number | null;
  };
}

const PropertyDetails: FC<IProps> = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const activeStep = useAppSelector(
    (state) => state.valuationWizardV2.activeStep
  );
  const userInput = useAppSelector(
    (state) => state.valuationWizardV2.userInput
  );
  const isRedirect = useAppSelector(
    (state) => state.valuationWizardV2.isRedirect
  );
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const { data } = useGetTemporaryPropertyQuery({}, { skip: isAnonymousUser });

  let values = watch();
  useEffect(() => {
    values = watch();
  }, [activeStep]);
  const { isApartment, isHouse, isMultiFamilyHouse } = useGetPropertyCode();
  const formData = isEmpty(values) ? userInput : values;

  const {
    code,
    subcode,
    propertyType,
    buildingYear,
    renovationYear,
    livingArea,
    landArea,
    floorNumber,
    numberOfRooms,
    numberOfFloorsInBuilding,
    numberOfUnits,
    balconyArea,
    numberOfBathrooms,
    // numberOfOutdoorParkingSpaces,
    numberOfIndoorParkingSpaces,
    annualRentIncome,
  } =
    (isRedirect ? data?.getTemporaryProperty?.propertyData : formData) ||
    formData ||
    {};

  const { label } = extractPropertyTypeLabelAndIcon(
    code || propertyType?.code || userInput?.code
  );
  const { label: labelSubType } = propertySubtypeNormalizer(
    subcode || propertyType?.subcode
  );

  const propertyTypeLabel =
    code === PropertyCode.MultiFamilyHouse
      ? t(label).toString()
      : t(labelSubType).toString() || t(label).toString();

  return (
    <InfoContainer>
      <IconRow>
        <PropertyValuationIcon
          sloganBigger
          icon={Building}
          value={propertyTypeLabel || '-'}
          type={t('property-valuation.form.redesign.info-screen.type')}
        />
        <PropertyValuationIcon
          sloganBigger
          icon={BuildingYear}
          value={buildingYear || '-'}
          type={t(
            'property-valuation.form.redesign.info-screen.construction-year'
          )}
        />
        {!isMultiFamilyHouse && (
          <PropertyValuationIcon
            sloganBigger
            icon={RollerIcon}
            value={renovationYear || '-'}
            type={t(
              'property-valuation.form.redesign.info-screen.modernization'
            )}
          />
        )}
        <PropertyValuationIcon
          sloganBigger
          icon={RealEstateDimensionsBlock}
          value={
            !isNil(livingArea) && !Number.isNaN(livingArea)
              ? `${t('approx')} ${livingArea} m²`
              : '-'
          }
          type={t('property-valuation.form.redesign.info-screen.living-space')}
        />
        {!isApartment && (
          <PropertyValuationIcon
            sloganBigger
            icon={ResizeExpandSides}
            value={
              !isNil(landArea) && !Number.isNaN(landArea)
                ? `${t('approx')} ${landArea} m²`
                : '-'
            }
            type={t('property-valuation.form.redesign.info-screen.land-area')}
          />
        )}
        {isApartment && (
          <PropertyValuationIcon
            sloganBigger
            icon={FloorsIcon}
            value={
              !isNil(floorNumber) && !Number.isNaN(floorNumber)
                ? floorNumber
                : '-'
            }
            type={t('property-valuation.form.redesign.info-screen.floor')}
          />
        )}
        {isHouse && (
          <PropertyValuationIcon
            sloganBigger
            icon={FloorsIcon}
            value={
              !isNil(numberOfFloorsInBuilding) &&
              !Number.isNaN(numberOfFloorsInBuilding)
                ? numberOfFloorsInBuilding
                : '-'
            }
            type={t(
              'property-valuation.form.redesign.number-of-floors-in-building.label'
            )}
          />
        )}
        {!isMultiFamilyHouse && (
          <>
            <PropertyValuationIcon
              sloganBigger
              icon={RealEstateDimensionsPlan}
              value={
                !isNil(numberOfRooms) && !Number.isNaN(numberOfRooms)
                  ? numberOfRooms
                  : '-'
              }
              type={t(
                'property-valuation.form.redesign.info-screen.number-of-rooms'
              )}
            />
            <PropertyValuationIcon
              sloganBigger
              icon={Bathroom}
              value={
                !isNil(numberOfBathrooms) && !Number.isNaN(numberOfBathrooms)
                  ? numberOfBathrooms
                  : '-'
              }
              type={t(
                'property-valuation.form.redesign.info-screen.number-of-bathroom'
              )}
            />
            <PropertyValuationIcon
              sloganBigger
              icon={BalconyIcon}
              value={
                !isNil(balconyArea) && !Number.isNaN(balconyArea)
                  ? balconyArea
                  : '-'
              }
              type={t(
                'property-valuation.form.redesign.info-screen.number-of-balconies'
              )}
            />
            <PropertyValuationIcon
              sloganBigger
              icon={HouseGarage}
              value={
                !isNil(numberOfIndoorParkingSpaces) &&
                !Number.isNaN(numberOfIndoorParkingSpaces)
                  ? numberOfIndoorParkingSpaces
                  : '-'
              }
              type={t('property-valuation.form.redesign.info-screen.garage')}
            />
            {/* <PropertyValuationIcon */}
            {/*  sloganBigger */}
            {/*  icon={ParkingIcon} */}
            {/*  value={ */}
            {/*    isNumber(numberOfOutdoorParkingSpaces) */}
            {/*      ? numberOfOutdoorParkingSpaces */}
            {/*      : '-' */}
            {/*  } */}
            {/*  type={t( */}
            {/*    'property-valuation.form.redesign.info-screen.parking-space' */}
            {/*  )} */}
            {/* /> */}
          </>
        )}
        {isMultiFamilyHouse && (
          <>
            <PropertyValuationIcon
              sloganBigger
              icon={RealEstateDimensionsPlan}
              value={
                !isNil(numberOfUnits) && !Number.isNaN(numberOfUnits)
                  ? numberOfUnits
                  : '-'
              }
              type={t('property-valuation.form.redesign.number-of-units.label')}
            />
            <PropertyValuationIcon
              sloganBigger
              icon={MoneyBagDollarIcon}
              value={
                !isNil(annualRentIncome) && !Number.isNaN(annualRentIncome)
                  ? annualRentIncome
                  : '-'
              }
              type={t(
                'property-valuation.form.redesign.info-screen.annual-net-rental-income'
              )}
            />
          </>
        )}
      </IconRow>
    </InfoContainer>
  );
};

export { PropertyDetails };
