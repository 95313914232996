import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { useFormContext } from 'react-hook-form';

import { GlobalError } from '../../../../common/components/form/error/global-error';
import { InputWithSuggestions } from '../../../components/common/input-with-suggestions';
import { SEARCH_PROFILE_FORM_KEYS } from '../../constants';
import {
  extractCorrectCityValue,
  getSuggestions,
} from '../../../utils/suggestions';
import { defaultDebaunceState } from '../forms/search-profile-form/steps/first-step/constants';
import { Proximity } from '../forms/search-profile-form/steps/first-step/interface';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { useLazyForwardGeocodingQuery } from '../../../../../services/mapbox/mapbox-api';
import { Feature } from '../../../../../services/mapbox/interfaces';
import {
  setSelectedAddressAction,
  setSelectedCountryCode,
} from '../../redux/searchProfileSlice';
import getCountry from '../../../utils/getCountry';
import { useSetProximityFromLocalization } from '../../../hooks/set-proximity-from-localization';
import { useGetCountry } from '../../../../localization/get-country';

interface IProps {
  defaultValue?: string;
  city?: string;
  placeName?: string;
  postCode?: string;
  locality?: string;
  country?: string;
  neighborhood?: string;
  proceedToNextStep?: boolean;
  setProceedToNextStep?: (condition: boolean) => void;
  invalidAddress?: boolean;
  setInvalidAddress: (condition: boolean) => void;
  label?: string;
  placeholder?: string;
  notRequired?: boolean;
}

export interface IAddress {
  postCode: string;
  locality: string;
  neighborhood: string;
  street: string;
  number: string;
  city: string;
  placeName: string;
  state: string;
  country: string;
  proximity: string;
  types?: string;
  language: string;
}

const InputCityAddress: FC<IProps> = ({
  defaultValue,
  city,
  placeName,
  postCode,
  locality,
  neighborhood,
  proceedToNextStep,
  setProceedToNextStep,
  invalidAddress,
  setInvalidAddress,
  label,
  country,
  placeholder,
  notRequired,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const relatedCountry = useAppSelector(
    (state) => state.searchProfile.relatedCountry
  );

  const [debouncedAddress, setDebouncedAddress] =
    useState(defaultDebaunceState);
  const proximity = useSetProximityFromLocalization();
  const { language } = useGetCountry();

  // fix autocomplete for MS Edge
  const [isReadonly, setIsReadonly] = useState(true);

  const [
    geocodingTrigger,
    { mostAccurateGeocodingResult, isFetching: isMapboxFetching },
  ] = useLazyForwardGeocodingQuery({
    selectFromResult: ({ data, isFetching }) => ({
      mostAccurateGeocodingResult: data,
      isFetching,
    }),
  });

  // hook
  let defaultProximity = Proximity.GERMANY;
  switch (relatedCountry && relatedCountry.toLowerCase()) {
    case 'germany':
      defaultProximity = Proximity.GERMANY;
      break;
    case 'austria':
      defaultProximity = Proximity.AUSTRIA;
      break;
    case 'switzerland':
      defaultProximity = Proximity.SWITZERLAND;
      break;
    default:
      defaultProximity = Proximity.GERMANY;
  }

  // hook
  const onDebounceAddressChange = useDebouncedCallback(
    (newDebouncedAddress: IAddress) => {
      setDebouncedAddress(newDebouncedAddress);
    },
    300
  );

  const onSuggestionClick = useCallback(
    (suggestion: Feature) => {
      const {
        updatedCity,
        updatedPostCode,
        updatedLocality,
        updatedNeighborhood,
        updatedRegion,
      } = extractCorrectCityValue(suggestion);
      dispatch(setSelectedCountryCode(getCountry(suggestion)));

      setValue(SEARCH_PROFILE_FORM_KEYS.POST_CODE, updatedPostCode);
      setValue(SEARCH_PROFILE_FORM_KEYS.REGION, updatedRegion);
      setValue(SEARCH_PROFILE_FORM_KEYS.CITY, updatedCity);
      setValue(
        SEARCH_PROFILE_FORM_KEYS.PLACE_NAME,
        suggestion.place_name_de || suggestion.place_name
      );
      setValue(SEARCH_PROFILE_FORM_KEYS.LOCALITY, updatedLocality);
      setValue(SEARCH_PROFILE_FORM_KEYS.NEIGHBORHOOD, updatedNeighborhood);

      if (!updatedCity) {
        setInvalidAddress(true);
      } else {
        setInvalidAddress(false);
      }

      const selectedSuggestion = JSON.parse(JSON.stringify([suggestion]));
      dispatch(setSelectedAddressAction(selectedSuggestion[0]));
      if (
        !isMapboxFetching &&
        proceedToNextStep &&
        mostAccurateGeocodingResult?.length === 0
      ) {
        setInvalidAddress(true);
      }
    },
    [
      dispatch,
      isMapboxFetching,
      mostAccurateGeocodingResult,
      proceedToNextStep,
      setInvalidAddress,
      setValue,
    ]
  );

  // find suggestions from the input value
  useEffect(() => {
    if (
      placeName &&
      debouncedAddress.placeName !== placeName &&
      placeName?.length > 2
    ) {
      if (setProceedToNextStep) {
        setProceedToNextStep(false);
      }

      onDebounceAddressChange({
        ...debouncedAddress,
        proximity: proximity || defaultProximity,
        city: city || '',
        placeName: placeName || '',
        postCode: postCode || '',
        locality: locality || '',
        neighborhood: neighborhood || '',
        language,
        country: country || 'de,nl,hu,es,fr,it,pt,at,ch,lu',
        types: 'postcode,place,neighborhood,locality',
      });
    }
  }, [
    language,
    onDebounceAddressChange,
    city,
    placeName,
    postCode,
    debouncedAddress,
    proximity,
    defaultProximity,
    setProceedToNextStep,
    locality,
    neighborhood,
  ]);

  // hook
  useEffect(() => {
    if (
      Object.keys(debouncedAddress)
        .filter((key) => key !== 'country')
        .some(
          (key) => (debouncedAddress as { [key: string]: string })[key] !== ''
        )
    ) {
      geocodingTrigger(debouncedAddress);
    }
  }, [geocodingTrigger, debouncedAddress]);

  // Fix autocomplete for MS Edge
  useEffect(() => setIsReadonly(false), []);

  return (
    <>
      {invalidAddress && (
        <GlobalError title={t('invalid-address.error')} topMargin />
      )}
      <InputWithSuggestions
        defaultValue={defaultValue}
        name={SEARCH_PROFILE_FORM_KEYS.PLACE_NAME}
        label={label ?? 'search-profile.form.first-step.address'}
        placeholder={
          placeholder ?? 'search-profile.form.first-step.address-placeholder'
        }
        rules={notRequired ? {} : { required: 'register.input.error.required' }}
        readonly={isReadonly}
        suggestions={getSuggestions(
          SEARCH_PROFILE_FORM_KEYS.CITY,
          mostAccurateGeocodingResult ?? []
        )}
        onSuggestionClick={onSuggestionClick}
      />
    </>
  );
};

export { InputCityAddress };
