import { Feature } from '../../../services/mapbox/interfaces';
import { AddressPiece, AddressPlaceTypes } from '../interface';

interface InputExtractInterface {
  updatedCity: string;
  updatedStreet: string;
  updatedNumber: string;
}

interface InputExtractCityInterface {
  updatedCity: string;
  updatedPostCode?: string;
  updatedLocality?: string;
  updatedRegion?: string;
  updatedNeighborhood?: string;
}

const defaultExtractedInputData: InputExtractInterface = {
  updatedStreet: '',
  updatedNumber: '',
  updatedCity: '',
};

const parsePostCodeSuggestions = (results: Feature[]) => {
  return results?.filter(
    (result) => result.place_type[0] === AddressPlaceTypes.postcode
  );
};

const parseStreetNameSuggestions = (results: Feature[]) => {
  return results?.filter(
    (result) => result.place_type[0] === AddressPlaceTypes.address
  );
};

const parseCityNameSuggestions = (results: Feature[]) => {
  return results?.filter(
    (result) =>
      result.place_type[0] === AddressPlaceTypes.poi ||
      result.place_type.includes(AddressPlaceTypes.place) ||
      result.place_type[0] === AddressPlaceTypes.postcode ||
      result.place_type[0] === AddressPlaceTypes.locality ||
      result.place_type[0] === AddressPlaceTypes.neighborhood
  );
};

const getSuggestions = (
  name: string,
  results: Feature[],
  filter?: (results: Feature[]) => Feature[]
): Feature[] => {
  if (filter) {
    results = filter(results);
  }
  switch (name) {
    case AddressPiece.postCode:
      return parsePostCodeSuggestions(results);
    case AddressPiece.number:
    case AddressPiece.street:
      return parseStreetNameSuggestions(results);
    case AddressPiece.city:
      return parseCityNameSuggestions(results);
    default:
      return results;
  }
};

const extractCorrectValue = (feature: Feature): InputExtractInterface => {
  if (feature?.place_type?.[0] === 'address') {
    const updatedStreet = feature?.text ?? '';
    const updatedCity = feature?.context?.find((c) =>
      c.id.includes('place')
    )?.text;
    if (updatedCity) {
      if (feature?.address) {
        return {
          updatedStreet,
          updatedCity,
          updatedNumber: feature.address,
        };
      }
      return {
        ...defaultExtractedInputData,
        updatedStreet,
        updatedCity,
      };
    }
    return {
      ...defaultExtractedInputData,
      updatedStreet,
    };
  }

  return defaultExtractedInputData;
};

const extractCorrectCityValue = (
  feature: Feature
): InputExtractCityInterface => {
  switch (feature?.place_type?.[0]) {
    case AddressPlaceTypes.postcode: {
      const updatedCity = feature?.context?.find((c) =>
        c.id.includes('place')
      )?.text;
      const updatedRegion = feature?.context?.find((c) =>
        c.id.includes('region')
      )?.text;

      const updatedPostCode = feature?.text;
      const updatedNeighborhood = 'null';
      const updatedLocality = 'null';

      if (updatedCity) {
        return {
          ...defaultExtractedInputData,
          updatedCity,
          updatedPostCode,
          updatedNeighborhood,
          updatedLocality,
          updatedRegion,
        };
      }
      break;
    }
    case AddressPlaceTypes.address: {
      const updatedCity = feature?.context?.find((c) =>
        c.id.includes('place')
      )?.text;
      const updatedRegion = feature?.context?.find((c) =>
        c.id.includes('region')
      )?.text;
      const updatedPostCode = feature?.context?.find((c) =>
        c.id.includes('postcode')
      )?.text;

      const updatedNeighborhood = 'null';
      const updatedLocality = 'null';

      if (updatedCity) {
        return {
          ...defaultExtractedInputData,
          updatedCity,
          updatedPostCode,
          updatedNeighborhood,
          updatedLocality,
          updatedRegion,
        };
      }
      break;
    }
    case AddressPlaceTypes.locality: {
      const updatedCity = feature?.context?.find((c) =>
        c.id.includes('place')
      )?.text;

      const updatedLocality = feature?.text;
      const updatedNeighborhood = 'null';
      const updatedPostCode = 'null';

      if (updatedCity) {
        return {
          ...defaultExtractedInputData,
          updatedCity,
          updatedLocality,
          updatedNeighborhood,
          updatedPostCode,
        };
      }
      break;
    }
    case AddressPlaceTypes.neighborhood: {
      const updatedCity = feature?.context?.find((c) =>
        c.id.includes('place')
      )?.text;

      const updatedNeighborhood = feature?.text;
      const updatedPostCode = 'null';
      const updatedLocality = 'null';

      if (updatedCity) {
        return {
          ...defaultExtractedInputData,
          updatedCity,
          updatedNeighborhood,
          updatedPostCode,
          updatedLocality,
        };
      }
      break;
    }
    default:
      if (feature?.place_type?.includes(AddressPlaceTypes.place)) {
        const updatedCity = feature?.text;
        const updatedPostCode = 'null';
        const updatedNeighborhood = 'null';
        const updatedLocality = 'null';
        const updatedRegion = feature?.context?.find((c) =>
          c.id.includes('region')
        )?.text;

        if (updatedCity) {
          return {
            ...defaultExtractedInputData,
            updatedCity,
            updatedPostCode,
            updatedNeighborhood,
            updatedLocality,
            updatedRegion,
          };
        }
      }
  }

  return defaultExtractedInputData;
};

export { getSuggestions, extractCorrectValue, extractCorrectCityValue };
