import styled from 'styled-components';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';

export const Container = styled.div<{ src?: string }>`
  height: 100%;
  // background-image: url('${(props) => props.src}');
  // background-position: center;
  // background-size: cover;
  display: flex;
  //justify-content: center;
  align-items: center;

  ${(props) =>
    props.src
      ? `background-image: url('${props.src}');
  background-position: center;
  background-size: cover;
  justify-content: center;`
      : `justify-content: end;`}
`;

export const PlaceHolderImg = styled.img`
  width: 41vw;
  height: 74vh;
  object-fit: cover;
`;

export const PropertyInfoContainer = styled.div`
  width: 592px;
  margin: 64px;
  background-color: ${({ theme }) => theme.white};
  z-index: 2;
  position: relative;
`;

export const Title = styled.div`
  padding: 24px 24px 0;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;

export const GalleryContainer = styled.div`
  overflow: hidden;
  margin: 0 24px 16px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.ctaDisabledBackground};
`;

export const PropertyDetailsContainer = styled.div`
  padding: 16px 24px 0;
`;

export const PropertyDetailsTitle = styled.div`
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  padding-bottom: 14px;
`;

export const PropertyPriceContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => hexToRGB(theme.blue, 0.1)};
  padding: 12px 12px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PropertyPrice = styled.div`
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  padding-right: 12px;
`;

export const PropertyPricePerMetr = styled(PropertyPrice)`
  font-weight: normal;
`;
