import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 3px;
  border: 1px solid rgba(201, 213, 252, 1);
  opacity: 1;
  background-color: rgba(242, 245, 247, 1);
  align-items: center;
  margin-bottom: 16px;
  gap: 12px;

  button {
    max-width: 232px;
    padding: 10px 12px;
  }

  i {
    margin: -4px 12px 0 0 !important;
  }

  @media ${device.tablet} {
    margin: 0 16px 16px;
    padding: 12px 28px 24px;
    flex-direction: column;

    button {
      max-width: 100%;
    }
  }
`;

export const Text = styled.span`
  opacity: 1;
  color: rgba(0, 48, 94, 1);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 24px;
`;
