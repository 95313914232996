import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { batch } from 'react-redux';

import { ReactComponent as Calendar } from 'assets/streamline-light/interface-essential/date-calendar/calendar.svg';
import { ReactComponent as User } from 'assets/navigation/user.svg';
import { ReactComponent as Star } from 'assets/navigation/star.svg';
import { ReactComponent as Phone } from 'assets/streamline-light/phones-mobile-devices/phone/phone.svg';
import { ReactComponent as Email } from 'assets/streamline-light/emails/email-actions/email-action-read.svg';

import {
  CmsRegionalPage,
  useLazyCmsRegionalPageQuery,
  useReadCentralPhoneByCountryQuery,
} from '../../../generated';
import { device } from '../../../style/theme';

import Icon from '../../common/components/ui/icon';
import {
  useAppDispatch,
  useAppSelector,
  useScrollPosition,
} from '../../common/hooks';
import { normalizeData } from '../../common/utils/normalize-data';
import useGetLocalization from '../../localization/get-localization';
import { toggleTimifyModal } from '../../timify/redux/timifySlice';
import {
  toggleIsConfirmEmailSuccessdModalOpen,
  toggleIsConfirmForgotPassworSuccessdModalOpen,
  toggleIsLoginModalOpen,
  toggleIsForgotPasswordSuccessModalOpen,
  toggleisEditProfileModalOpen,
} from '../redux/authSlice';
import AuthModal from './modals/auth-modal/auth-modal';
import { ConfirmForgotPasswordModal } from './modals/confirm-forgot-password-modal';
import { CreatePasswordModal } from './modals/create-password-modal';
import { LoginModal } from './modals/login-modal';
import { ProfileEditModal } from './modals/profile-edit-modal/profile-edit-modal';
import { SuccessModal } from './modals/success-modal';
import { ProfileSettings } from './profile-settings';
import { ConfirmAccountModal } from './forms/confirm-account-modal/confirm-account-modal';
import { ResendVerificationAccountModal } from './modals/auth-modal/resend-verification-account-modal';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 86px;

  @media ${device.bigScreen} {
    padding-left: 15px;
    gap: 15px;
  }

  @media ${device.laptop} {
    padding-left: 10px;
    gap: 10px;
  }
`;

const Menu = styled.div`
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 1px #002849;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .icon * {
    stroke-width: 2.5px;
  }
`;

const MenuIconButton = styled.button<{ $phoneHover?: boolean }>`
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  position: relative;
  transition: all 0.5s;

  ${(props) =>
    props.$phoneHover &&
    `
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    overflow: hidden;
  `}
`;

const MenuIconButtonLink = styled.a<{ $phoneHover?: boolean }>`
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  position: relative;
  text-decoration: none;
  transition: all 0.5s;

  ${(props) =>
    props.$phoneHover &&
    `
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    overflow: hidden;
  `}
`;

const MenuIconLink = styled.a<{
  $phoneHover?: boolean;
  $calendarHover?: boolean;
}>`
  text-decoration: none;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  ${(props) =>
    props.$calendarHover &&
    `
    display: none;
  `}
`;

const ContactBox = styled.div<{ $isNotFixed?: boolean }>`
  display: flex;
  padding: 6px 12px;
  background: #4e73f4;
  border-radius: 5px;
  position: relative;
  height: 35px;
  ${(props) => (props.$isNotFixed ? 'width: auto;' : 'width: 230px;')}
  justify-content: center;
  align-items: center;
`;

const MenuIconText = styled.span`
  font-family: Roboto, sans-serif;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  white-space: nowrap;
  margin-left: 10px;
`;

const Pipe = styled.div<{ $phoneHover?: boolean; $calendarHover?: boolean }>`
  height: 23px;
  width: 1px;
  background: white;
  opacity: 0.5;
  margin: 0 11px;
  transition: all 0.3s;

  ${(props) =>
    props.$phoneHover &&
    `
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
  `}

  ${(props) =>
    props.$calendarHover &&
    `
    opacity: 0;
    margin: 0;
  `}
`;

const UserBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const UserActiveIndicator = styled.div<{ isScrolling?: boolean }>`
  width: 13px;
  height: 13px;
  background: #5aae2e;
  position: absolute;
  bottom: -8px;
  right: -4px;
  border-radius: 100%;
  border: 3px solid ${(props) => (props.isScrolling ? '#00366a' : 'white')};
`;

const UserText = styled.div`
  position: absolute;
  bottom: -20px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-top: 6px;
  color: white;
`;

interface RegionalPageResponse {
  data: { cmsRegionalPage: CmsRegionalPage };
  isLoading: boolean;
}

interface IProps {
  isScrolling?: boolean;
  deactivateMenu: () => void;
  authOpen: 'user' | 'star' | '';
  setAuthOpen: (newVal: 'user' | 'star' | '') => void;
  isDropdownOpen: boolean;
  setIsDropdownOpen: (newVal: boolean) => void;
}

const AuthHeaderBase = ({
  isScrolling,
  deactivateMenu,
  authOpen,
  setAuthOpen,
  isDropdownOpen,
  setIsDropdownOpen,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const lang = useGetLocalization();
  const { slug } = useParams<{ slug: string }>();
  const urlParams = new URLSearchParams(window.location.search);
  const openProfileEditModal = urlParams.get('profile-edit-modal') || '';

  const [visiblePercentage, setVisiblePercentage] = useState(0);

  const [phoneHover, setPhoneHover] = useState(false);
  const [calendarHover, setCalendarHover] = useState(false);

  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);

  const { data: centralPhoneData, isLoading: isCentralPhoneLoading } =
    useReadCentralPhoneByCountryQuery({ country: lang ?? 'de-de' });

  // Fetch landing page details
  const [getContact, { data: regionalPageData, isLoading: isRegionalLoading }] =
    useLazyCmsRegionalPageQuery<RegionalPageResponse>();

  useEffect(() => {
    if (slug) {
      getContact({ slug });
    }
  }, []);

  const centralPhoneNumber =
    centralPhoneData?.readCentralPhoneByCountry?.phoneNumber ||
    t('subheader.phone.text') ||
    '';

  const isEditProfileModalOpen = useAppSelector(
    (state) => state.auth.isEditProfileModalOpen
  );
  const isLoginModalOpen = useAppSelector(
    (state) => state.auth.isLoginModalOpen
  );
  const isCreatePasswordModalOpen = useAppSelector(
    (state) => state.auth.isCreatePasswordModalOpen
  );
  const isConfirmForgotPasswordModalOpen = useAppSelector(
    (state) => state.auth.isConfirmForgotPasswordModalOpen
  );
  const isConfirmForgotPasswordSuccesModalOpen = useAppSelector(
    (state) => state.auth.isConfirmForgotPasswordSuccesModalOpen
  );
  const isConfirmEmailSuccessModalOpen = useAppSelector(
    (state) => state.auth.isConfirmEmailSuccessModalOpen
  );
  const isForgotPasswordSuccessModalOpen = useAppSelector(
    (state) => state.auth.isForgotPasswordSuccessModalOpen
  );

  useEffect(() => {
    if (openProfileEditModal) {
      dispatch(toggleisEditProfileModalOpen(true));
    }
  }, []);

  const onCloseConfirmForgotPasswordSuccessModal = (
    e?: React.SyntheticEvent
  ) => {
    e?.preventDefault();
    dispatch(toggleIsConfirmForgotPassworSuccessdModalOpen(false));
  };

  const onCloseConfirmEmailSuccessModal = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    batch(() => {
      dispatch(toggleIsConfirmEmailSuccessdModalOpen(false));
      dispatch(toggleIsLoginModalOpen(true));
    });
  };

  const onCloseForgotPasswordSuccessModal = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    batch(() => {
      dispatch(toggleIsLoginModalOpen(false));
      dispatch(toggleIsForgotPasswordSuccessModalOpen(false));
    });
  };

  const closeDropdown = useCallback(() => setIsDropdownOpen(false), []);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  useScrollPosition(
    ({ currPos }) => {
      const min = 0;
      const max = 64;
      const current = Math.abs(currPos.y) - 1;
      const normalizedCurrentPosition = Math.abs(
        normalizeData({
          min,
          max,
          current,
        })
      );

      if (normalizedCurrentPosition >= 0 && normalizedCurrentPosition <= 1) {
        setVisiblePercentage(normalizedCurrentPosition);
      } else if (visiblePercentage < 1 && normalizedCurrentPosition > 0) {
        setVisiblePercentage(1);
      }
    },
    [visiblePercentage]
  );

  const handleStarHover = () => {
    deactivateMenu();
    setAuthOpen('');
    // setIsDropdownOpen(false);
  };

  const handleUserClick = () => {
    deactivateMenu();
    setAuthOpen(authOpen === '' ? 'user' : '');
  };

  const handleUserActiveHover = () => {
    deactivateMenu();
    setAuthOpen('');
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <Container>
        <>
          <MenuIconButtonLink
            onMouseOver={() => handleStarHover()}
            href={'https://www.von-poll.com/de/merkliste'}
          >
            <Icon
              color={isScrolling ? 'white' : '#00305E'}
              icon={Star}
              width={20}
              height={20}
            />
          </MenuIconButtonLink>
          {isAnonymousUser ? (
            <MenuIconButton onClick={() => handleUserClick()}>
              <UserBox>
                <Icon
                  color={isScrolling ? 'white' : '#00305E'}
                  icon={User}
                  width={20}
                  height={20}
                />
                {authOpen === 'user' && (
                  <UserText>{t('header.login')}</UserText>
                )}
              </UserBox>
            </MenuIconButton>
          ) : (
            <MenuIconButton onClick={() => handleUserActiveHover()}>
              <UserBox>
                <Icon
                  color={isScrolling ? 'white' : '#00305E'}
                  icon={User}
                  width={20}
                  height={20}
                />
                <UserActiveIndicator isScrolling={isScrolling} />
              </UserBox>
              {isDropdownOpen && (
                <ProfileSettings closeDropdown={closeDropdown} />
              )}
            </MenuIconButton>
          )}
          <ContactBox
            $isNotFixed={!!regionalPageData}
            onMouseLeave={() => {
              setPhoneHover(false);
              setCalendarHover(false);
            }}
          >
            <MenuIconLink
              $calendarHover={calendarHover}
              $phoneHover={phoneHover}
              onMouseEnter={() => setPhoneHover(true)}
              href={`tel:${
                regionalPageData
                  ? regionalPageData.cmsRegionalPage.phone
                  : centralPhoneNumber
              }`}
            >
              <Icon icon={Phone} width={16} height={16} />
              <MenuIconText>
                {regionalPageData
                  ? regionalPageData.cmsRegionalPage.phone
                  : centralPhoneNumber}
              </MenuIconText>
            </MenuIconLink>
            {!regionalPageData && !isRegionalLoading && (
              <>
                <Pipe $phoneHover={phoneHover} $calendarHover={calendarHover} />
                <MenuIconButton
                  onMouseEnter={() => setCalendarHover(true)}
                  $phoneHover={phoneHover}
                  onClick={() => dispatch(toggleTimifyModal(true))}
                >
                  {calendarHover && (
                    <MenuIconText
                      style={{ marginRight: 10, textTransform: 'uppercase' }}
                    >
                      {t('header.authentication.online-termin')}
                    </MenuIconText>
                  )}
                  <Icon icon={Calendar} width={16} height={16} />
                </MenuIconButton>
              </>
            )}
            {regionalPageData && (
              <>
                <Pipe />
                <MenuIconLink
                  href={`mailto:${regionalPageData.cmsRegionalPage.email}`}
                >
                  <Icon icon={Email} width={16} height={16} />
                  <MenuIconText>
                    {regionalPageData.cmsRegionalPage.email}
                  </MenuIconText>
                </MenuIconLink>
              </>
            )}
          </ContactBox>
        </>
        <ConfirmAccountModal />
        <LoginModal isOpen={isLoginModalOpen} />
        <ProfileEditModal isOpen={isEditProfileModalOpen} />
        <CreatePasswordModal isOpen={isCreatePasswordModalOpen} />
        <ConfirmForgotPasswordModal isOpen={isConfirmForgotPasswordModalOpen} />
        <SuccessModal
          isOpen={isConfirmForgotPasswordSuccesModalOpen}
          onClose={onCloseConfirmForgotPasswordSuccessModal}
          title={t('confirm-forgor-password.success-modal.title')}
          textContent={t('confirm-forgor-password.success-modal.text-content')}
        />
        <ResendVerificationAccountModal />
        <SuccessModal
          isOpen={isConfirmEmailSuccessModalOpen}
          onClose={onCloseConfirmEmailSuccessModal}
          title={t('confirm-email.success-modal.title')}
          textContent={t('confirm-email.success-modal.text-content')}
          acceptButton={t('confirm-email.success-modal.button')}
        />
        <SuccessModal
          isOpen={isForgotPasswordSuccessModalOpen}
          onClose={onCloseForgotPasswordSuccessModal}
          title={t('forgot-password.form.success-message.title')}
          textContent={t('forgot-password.form.success-message.subtitle')}
          acceptButton={t(
            'forgot-password.form.success-message.button.continue'
          )}
        />
      </Container>
      <AuthModal
        close={() => setAuthOpen('')}
        isOpen={authOpen !== ''}
        showForm={authOpen === 'user'}
      />
    </>
  );
};

const AuthHeader = memo(AuthHeaderBase);

export { AuthHeader };
