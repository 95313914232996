import { energyEfficiency } from '../../../../constants';

type Props = {
  finalEnergyDemand: number;
  energyEfficiencyClass?: string | null;
};

type TReturn = {
  indicatorLeft: number;
  tooltipLeft: number;
  letter?: string;
  color?: string;
  indicatorColor?: string;
};

const useGetEnergyEfficiencyData = ({
  finalEnergyDemand,
  energyEfficiencyClass,
}: Props): TReturn => {
  let tooltipLeft = 0;
  let indicatorLeft = 0;
  let letter = '';
  let marker: typeof energyEfficiency.colorMarkers[0] | undefined;
  let color = '';
  let indicatorColor = '#ffffff';

  if (finalEnergyDemand) {
    marker = energyEfficiency.colorMarkers.find((item) => {
      return finalEnergyDemand >= item.min && finalEnergyDemand < item.max;
    });
  } else if (energyEfficiencyClass) {
    letter = energyEfficiencyClass;
    marker = energyEfficiency.colorMarkers.find((item) => {
      return item.label === letter;
    });
  }

  if (finalEnergyDemand) {
    tooltipLeft = Math.min(95, (finalEnergyDemand / 275) * 100);
  }

  if (marker) {
    letter = marker.label;
    // Calculated based on the marker range center
    indicatorLeft = ((marker.min + marker.range / 2) / 275) * 100;
    color = marker.color;

    if (['C', 'D'].includes(letter)) {
      indicatorColor = 'rgba(0, 0, 0, 0.5)';
    }
  }

  return {
    indicatorLeft,
    tooltipLeft,
    letter,
    color,
    indicatorColor,
  };
};

export { useGetEnergyEfficiencyData };
