import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-left.svg';
import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import { Progress } from '../../../components/common/progress';
import { stepDescriptions } from '../../constants/steps';
import { ModalHeaderProps } from '../../interface';
import { prevStep, close } from '../../redux/purchaseIntendSlice';
import {
  IconButton,
  StepDescription,
  TextContainer,
  Title,
  ToolbarContainer,
} from './modal-headers-styles';

const ModalHeader = ({
  errors,
  onClose,
  isLoading,
}: ModalHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reset } = useFormContext();
  const { activeStep, structure, type } = useSelector(
    (state: RootState) => state.purchaseIntend
  );

  const stepsCount = structure[type].length;

  const closeHandler = () => {
    if (!isLoading) {
      reset();
      dispatch(close());
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <div>
      <ToolbarContainer>
        {activeStep > 0 && (
          <IconButton
            onClick={() => {
              if (!isLoading) {
                dispatch(prevStep());
              }
            }}
          >
            <ArrowLeft />
          </IconButton>
        )}
        <Progress activeBullet={activeStep} bullets={stepsCount} />
        <IconButton onClick={closeHandler}>
          <Close />
        </IconButton>
      </ToolbarContainer>
      {errors?.message && (
        <GlobalError title={t(errors?.message?.split(':')[0].toLowerCase())} />
      )}
      <TextContainer>
        <Title>{t('purchase-intent.form.formTitle')}</Title>
        <StepDescription>
          {t(stepDescriptions[structure[type][activeStep]])}
        </StepDescription>
      </TextContainer>
    </div>
  );
};

export default ModalHeader;
