import { FC, memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowButtonLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as ArrowButtonRight } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-right-1.svg';
import styled from 'styled-components';

import { FinancingVariant } from '../../../../generated';
import { device } from '../../../../style/theme';
import Icon from '../../../common/components/ui/icon';
import {
  setVariantIndex,
  toggleModalCompareVariants,
} from '../../../forms/form-financing/redux/financingSlice';
import EditVariantModal from './edit-variant-modal/edit-variant-modal';
import {
  VariantsButtonBox,
  VariantsContainer,
  VariantsMainButton,
  VariantsOuterContainer,
} from './multiple-financings-styles';
import { MultipleVariantsItem } from './multiple-variants-item';
import { useHiddenVariants } from '../../hooks/useHiddenVariants';
import { useCheckFinancingURLParams } from '../../hooks/useCheckFinancingURLParams';

const ButtonContainer = styled.div`
  height: 56px;
  width: 56px;
  position: absolute;
  z-index: 1;

  @media ${device.tablet} {
    height: 72px;
  }
`;

export const LeftButtonContainer = styled(ButtonContainer)`
  top: 0;
  padding: 0 0 0 12px;
  width: 28px;
  background: white;

  &::after {
    content: '';
    top: 12px;
    display: block;
    width: 45px;
    height: 32px;
    position: absolute;
    opacity: 1;
    left: 28px;
    background: linear-gradient(
      270deg,
      rgba(231, 235, 237, 0) 0%,
      rgba(231, 235, 237, 1) 100%
    );
  }

  @media ${device.tablet} {
    background-image: linear-gradient(
      to left,
      rgba(231, 235, 237, 0) 9%,
      #e7ebed 62%
    );
    display: none;
  }
`;

export const RightButtonContainer = styled(ButtonContainer)`
  top: 0;
  right: 169px;

  &::after {
    content: '';
    top: 12px;
    display: block;
    width: 45px;
    height: 32px;
    position: absolute;
    opacity: 1;
    right: 38px;
    background: linear-gradient(
      90deg,
      rgba(231, 235, 237, 0) 0%,
      rgba(231, 235, 237, 1) 100%
    );
  }

  @media ${device.tablet} {
    background-image: linear-gradient(
      to right,
      rgba(231, 235, 237, 0) 9%,
      #e7ebed 62%
    );
    display: none;
  }
`;

const ArrowButton = styled.div`
  width: 32px;
  height: 32px;
  outline: solid 1px #fff;
  border: solid 1px #4e73f5;
  background-color: ${(props) => props.theme.logoBlue};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;

  i {
    path {
      stroke-width: 2px;
    }
  }
`;

export const LeftButton = styled(ArrowButton)``;

export const RightButton = styled(ArrowButton)`
  z-index: 2;
`;

interface IProps {
  financingVariants: FinancingVariant[];
  activeId?: number;
  refetch: () => void;
}

const MultipleVariantsBase: FC<IProps> = ({
  financingVariants,
  activeId,
  refetch,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { variantIndexParam, setVariantIndexParam } =
    useCheckFinancingURLParams();

  useEffect(() => {
    if (isNumber(activeId) && activeId !== Number(variantIndexParam)) {
      setVariantIndexParam(activeId);
    }
  }, [activeId]);

  useEffect(() => {
    const variantIndexParamNumber = Number(variantIndexParam);
    if (
      variantIndexParam &&
      !Number.isNaN(variantIndexParamNumber) &&
      variantIndexParamNumber < financingVariants.length &&
      variantIndexParamNumber >= 0 &&
      Number(variantIndexParam) !== activeId
    ) {
      dispatch(setVariantIndex(Number(variantIndexParam)));
    }
  }, []);
  const onOpenClick = () => {
    dispatch(toggleModalCompareVariants(true));
  };
  const { hiddenVariants, onHideVariant } = useHiddenVariants();
  const isTwoVariants =
    Number(financingVariants?.length) - Number(hiddenVariants?.length) < 3;

  const scrollContainer = useRef(null);
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);

  const [editing, setEditing] = useState<{
    index: number;
    name: string;
  } | null>(null);

  const checkForScroll = () => {
    if (!scrollContainer || !scrollContainer.current) return;

    const current = scrollContainer.current! as Element;
    const { clientWidth, scrollWidth, scrollLeft } = current;

    if (scrollLeft > 0) {
      setLeft(true);
    } else {
      setLeft(false);
    }

    if (clientWidth + scrollLeft < scrollWidth - 2) {
      setRight(true);
    } else {
      setRight(false);
    }
  };

  useLayoutEffect(() => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    if (!scrollContainer || !scrollContainer.current) return undefined;

    const current = scrollContainer.current! as Element;
    current.addEventListener('scroll', checkForScroll);
    window.addEventListener('resize', checkForScroll);
    checkForScroll();

    return () => {
      current.removeEventListener('scroll', checkForScroll);
      window.removeEventListener('resize', checkForScroll);
    };
  }, []);

  const scrollHandler = (direction: 'left' | 'right') => {
    if (!scrollContainer || !scrollContainer.current) return;

    const current = scrollContainer.current! as Element;

    current.scrollTo({
      top: 0,
      left:
        direction === 'right'
          ? current.scrollLeft + 320
          : current.scrollLeft - 320,
      behavior: 'smooth',
    });

    checkForScroll();
  };

  useEffect(() => {
    checkForScroll();
  }, [financingVariants]);

  return (
    <>
      <VariantsOuterContainer>
        {left && (
          <LeftButtonContainer>
            <LeftButton onClick={() => scrollHandler('left')}>
              <Icon icon={ArrowButtonLeft} width={10} height={10} />
            </LeftButton>
          </LeftButtonContainer>
        )}

        <VariantsContainer ref={scrollContainer}>
          {financingVariants.map((item, i) => {
            return (
              <MultipleVariantsItem
                active={activeId === i}
                index={i}
                setEditing={setEditing}
                variantIndex={item.financingVariantData.index}
                value={item.financingVariantData.name}
                isTwoVariants={isTwoVariants}
                key={`${i}`}
                isHide={hiddenVariants.includes(i)}
                onHideVariant={onHideVariant}
              />
            );
          })}
        </VariantsContainer>

        {right && (
          <RightButtonContainer>
            <RightButton onClick={() => scrollHandler('right')}>
              <Icon icon={ArrowButtonRight} width={10} height={10} />
            </RightButton>
          </RightButtonContainer>
        )}
        <VariantsButtonBox>
          <VariantsMainButton
            label={t('financing-portal-page.variants-compare.button.label')}
            onClick={onOpenClick}
            fluid
            centered
          />
        </VariantsButtonBox>
      </VariantsOuterContainer>
      <EditVariantModal
        refetch={refetch}
        onClose={() => setEditing(null)}
        active={editing}
      />
    </>
  );
};

const MultipleVariants = memo(MultipleVariantsBase);

export { MultipleVariants };
