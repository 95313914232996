import { FinancingPropertyType, PropertyCode } from '../../../generated';

export const financingPropertyTypeToPropertyCode = (
  propertyType?: FinancingPropertyType
): PropertyCode => {
  switch (propertyType) {
    case FinancingPropertyType.HouseDetached:
    case FinancingPropertyType.HouseSemiDetached:
    case FinancingPropertyType.HouseRowMiddle:
    case FinancingPropertyType.HouseRowCorner:
      return PropertyCode.House;
    case FinancingPropertyType.Condominium:
      return PropertyCode.Apartment;
    case FinancingPropertyType.MultiFamilyHouse:
    case FinancingPropertyType.TwoFamilyHouse:
      return PropertyCode.MultiFamilyHouse;
    case FinancingPropertyType.PlotOnly:
      return PropertyCode.Plot;
    default:
      return PropertyCode.Apartment;
  }
};
