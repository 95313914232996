import { CSSProperties, FC, memo, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useController } from 'react-hook-form';

import {
  Box,
  Description,
  RadioButton,
  TextContainer,
  Title,
} from './box-selector-styles';
import Icon from '../../../common/components/ui/icon';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { Row } from '../expose/expose-state-card/expose-state-card-styles';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';

interface IBox {
  id: string;
  title: string;
  description?: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  activeIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  iconPosition?: 'top' | 'left';
  value: string;
  isDark?: boolean;
}

interface IProps {
  boxes: IBox[];
  isColumn?: boolean;
  onSelect?: (boxId: string) => void;
  boxStyle?: CSSProperties;
  initialActiveBox?: string;
  name: string;
}

const BoxSelectorBase: FC<IProps> = ({
  boxes,
  onSelect,
  isColumn,
  boxStyle,
  initialActiveBox,
  name,
}) => {
  const theme = useContext(ThemeContext);
  const isMobileSize = useIsMobileSize();
  const [activeBox, setActiveBox] = useState<string | number | null>(
    initialActiveBox ?? null
  );

  const {
    field: { ref, onChange },
  } = useController({
    name,
    defaultValue: boxes[0].value,
  });

  const onSelectBox =
    <T,>(v: T) =>
    () => {
      onChange(v);
      const selectedBox = boxes.find((box) => box.value === String(v));
      if (selectedBox) {
        setActiveBox(selectedBox.id);

        if (onSelect) {
          onSelect(selectedBox.id);
        }
      }
    };
  return (
    <Row gap={12} isColumn={isColumn || isMobileSize}>
      {boxes.map((box) => (
        <Box
          key={box.id}
          isActive={activeBox === box.id}
          isDark={box.isDark}
          iconPosition={box.iconPosition ?? 'top'}
          style={{ ...boxStyle }}
          ref={ref}
          onClick={onSelectBox(box.value)}
        >
          <Icon
            icon={
              activeBox === box.id && box.activeIcon ? box.activeIcon : box.icon
            }
            width={box.isDark ? 48 : 40}
            height={box.isDark ? 51 : 40}
            color={hexToRGB(theme.blue, 1)}
            style={{
              marginBottom: box.isDark ? '0' : '12px',
              minWidth: box.isDark ? '48px' : '40px',
              minHeight: box.isDark ? '51px' : '40px',
            }}
          />
          <TextContainer>
            <Title
              content={box.title}
              isActive={activeBox === box.id}
              textAlign={box.iconPosition === 'left' ? 'start' : 'center'}
            />
            {box.description && (
              <Description
                content={box.description}
                textAlign={box.iconPosition === 'left' ? 'start' : 'center'}
              />
            )}
          </TextContainer>
          <RadioButton
            type={'radio'}
            name={name}
            checked={activeBox === box.id}
            value={box.value}
            readOnly
          />
        </Box>
      ))}
    </Row>
  );
};

const BoxSelector = memo(BoxSelectorBase);

export { BoxSelector };
