import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IPropertyState {
  isInvitationModalOpen: boolean;
  isEditModalOpen: boolean;

  modifyCollaborator: string | null;
}

const initialState: IPropertyState = {
  isInvitationModalOpen: false,
  isEditModalOpen: false,
  modifyCollaborator: null,
};

export const propertyCollaboratorsSlice = createSlice({
  name: 'propertyCollaborators',
  initialState,
  reducers: {
    toggleInvitationModal(state, action: PayloadAction<boolean>) {
      state.isInvitationModalOpen = action.payload;
    },
    toggleEditModal(state, action: PayloadAction<boolean>) {
      state.isEditModalOpen = action.payload;
    },
    setModifyCollaborator(state, action: PayloadAction<string | null>) {
      state.modifyCollaborator = action.payload;
    },
  },
});

export const { toggleInvitationModal, toggleEditModal, setModifyCollaborator } =
  propertyCollaboratorsSlice.actions;

export default propertyCollaboratorsSlice.reducer;
