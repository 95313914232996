import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect } from 'react';
import {
  PurchaseIntentInput,
  useCreatePurchaseIntentMutation,
} from '../../../../../generated';
import { RootState } from '../../../../../store';
import { FlatButton } from '../../../../common/components/ui/buttons';
import { useAppSelector } from '../../../../common/hooks';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { useCanContinue } from '../../hooks/useCanContinue';
import { useValidate } from '../../hooks/useValidate';
import { FormStateInterface } from '../../interface';
import { nextStep, close } from '../../redux/purchaseIntendSlice';

const Container = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: flex-end;
  ${(props) =>
    props.isMobile &&
    `
    padding: 16px;
    border-top: 1px solid #d9d9d9;
  `}
`;

interface IProps {
  refetch: () => void;
  propertyId?: string;
  propertyPrice?: number;
  onClose?: () => void;
  onLoading: (state: boolean) => void;
}

const ModalFooter = ({
  refetch,
  propertyId,
  propertyPrice,
  onClose,
  onLoading,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { getValues, reset } = useFormContext();
  const isMobileSize = useIsMobileSize();
  const dispatch = useDispatch();
  const { activeStep, structure, type } = useSelector(
    (state: RootState) => state.purchaseIntend
  );
  const selectedPropertyId = useAppSelector(
    (state) => state.exposeDetails.selectedPropertyId
  );
  const onlineExposes = useAppSelector(
    (state) => state.onlineExposes.onlineExposes
  );
  const propertyPriceExpose = onlineExposes?.filter(
    (expose) => expose._id === (selectedPropertyId || propertyId)
  )?.[0]?.propertyDetails?.price;
  // const userId = useAppSelector((state) => state.auth.user?._id);

  const [createPurchaseIntent, { isLoading }] =
    useCreatePurchaseIntentMutation();
  const canContinue = useCanContinue();
  const validate = useValidate(propertyPrice ?? propertyPriceExpose);
  const { searchProfileId } = useParams<{ searchProfileId: string }>();

  const stepsCount = structure[type].length;
  const lastStep = activeStep + 1 === stepsCount;

  useEffect(() => {
    onLoading(isLoading);
  }, [isLoading]);

  const nextStepHandler = async () => {
    if (canContinue) {
      if (!validate()) return;

      if (lastStep) {
        const formState = getValues() as FormStateInterface;
        const newOtherPersons = (formState?.otherPersons || []).map(
          (person) => {
            const newPerson = { ...person };
            newPerson.phone = `+${newPerson.phonePrefix}${newPerson.phone}`;
            delete newPerson.phonePrefix;
            return newPerson;
          }
        );

        const input = {
          ...formState,
          phone: `+${formState.phonePrefix}${formState.phone}`,
          otherPersons: newOtherPersons,
          type,
          propertyId: selectedPropertyId || propertyId,
          price: Number.parseFloat(String(formState.price)),
          searchProfileId,
        };
        delete input.phonePrefix;

        await createPurchaseIntent({ input: input as PurchaseIntentInput });

        refetch();
        reset();
        dispatch(close());
        if (onClose) {
          onClose();
        }
      } else {
        dispatch(nextStep());
      }
    }
  };

  return (
    <Container isMobile={isMobileSize}>
      <FlatButton
        isDisabled={isLoading || !canContinue}
        onClick={nextStepHandler}
        label={
          lastStep
            ? t('purchase-intent.form.send')
            : t('purchase-intent.form.nextStep')
        }
      />
    </Container>
  );
};

export default ModalFooter;
