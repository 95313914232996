import { FC } from 'react';

import {
  FinancingOverviews,
  OverviewLpSection,
  useReadFinancingOverviewsQuery,
} from '../../../../../generated';
import {
  Container,
  Title,
  Line,
  BlueTitle,
} from './financing-overview-section-styles';
import { Slider } from './slider';

interface IProps {
  section: OverviewLpSection;
}

interface FinancingOverviewsResponse {
  data: {
    data: {
      page: FinancingOverviews[];
    };
  };
  isLoading: boolean;
}

const FinancingOverviewSection: FC<IProps> = ({ section }) => {
  const { recordIds, title } = section;

  const { data, isLoading } =
    useReadFinancingOverviewsQuery<FinancingOverviewsResponse>({
      filter: { _ids: recordIds },
    });

  if (isLoading) return null;

  const pages = data?.data?.page;

  const sections = pages?.map((page) => ({
    ...page,
    ctaLabel: page.fundingInquiriesLabel,
  }));
  const blueTitle = title?.split(' ')?.[0];
  const mainTitle = title?.split(' ')?.slice(1)?.join(' ');

  return (
    <Container>
      <Title>
        <BlueTitle>{blueTitle}</BlueTitle> {mainTitle}
      </Title>
      <Slider
        sections={sections as FinancingOverviews[]}
        ctaHandler={() => {
          window.open(
            `${window.location.origin}/de-de/baufinanzierung`,
            '_blank'
          );
        }}
      />
      <Line />
    </Container>
  );
};

export { FinancingOverviewSection };
