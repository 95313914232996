import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { StyledFormGridWrapperFixHeight } from '../edit-search-profile-form/edit-search-profile-form-styles';
import { GlobalLoader } from '../../../../../common/components/ui/loaders/global-loader';
import { useUpdateSearchProfileMutation } from '../../../../../../services/graphql/enhanced';
import { ISearchProfileNotificationsForm } from '../../../interface';
import { toggleIsNotificationModalOpen } from '../../../redux/searchProfileSlice';
import { MobileBack } from '../../common/mobile-back';
import { SEARCH_PROFILE_NOTIFICATIONS_FORM_KEYS } from '../../../constants';
import {
  Title,
  NotificationMainButton,
  NotificationSecondaryButton,
  FormButtonsWrapper,
  CheckboxItem,
} from './notifications-search-profile-form-styles';
import { useGetSearchProfile } from '../../../../../search-profile/hooks/useGetSearchProfile';

const NotificationsSearchProfileForm: FC = () => {
  const { searchProfileId } = useParams<{ searchProfileId: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const methods = useForm<ISearchProfileNotificationsForm>();
  const closeModal = useCallback(
    () => dispatch(toggleIsNotificationModalOpen(false)),
    [dispatch]
  );

  const {
    isSearchProfileLoading,
    isNotifyPriceChange,
    isNotifyNewProperties,
    isNotifyNewPropertiesSummary,
  } = useGetSearchProfile({ searchProfileId });

  const [updateSearchProfile, { isLoading: isUpdateSearchProfileLoading }] =
    useUpdateSearchProfileMutation();

  const onSubmit: SubmitHandler<ISearchProfileNotificationsForm> = useCallback(
    async (formData) => {
      await updateSearchProfile({
        input: {
          _id: searchProfileId,
          isNotifyPriceChange: formData.isNotifyPriceChange,
          isNotifyNewProperties: formData.isNotifyNewProperties,
          isNotifyNewPropertiesSummary: formData.isNotifyNewPropertiesSummary,
        },
      });
      closeModal();
    },
    [closeModal, searchProfileId, updateSearchProfile]
  );

  if (isSearchProfileLoading || isUpdateSearchProfileLoading) {
    return (
      <StyledFormGridWrapperFixHeight>
        <GlobalLoader />
      </StyledFormGridWrapperFixHeight>
    );
  }

  return (
    <>
      <MobileBack title={t('edit.search-profile.modal.notification.title')} />
      <FormProvider {...methods}>
        <Title
          content={t(
            'edit.search-profile.section.notification.new-property.title'
          )}
        />
        <CheckboxItem
          name={
            SEARCH_PROFILE_NOTIFICATIONS_FORM_KEYS.NEW_PROPERTY_NOTIFICATION
          }
          label={'edit.search-profile.section.notification.new-property.label'}
          rules={{
            value: isNotifyNewProperties,
          }}
        />
        <CheckboxItem
          name={
            SEARCH_PROFILE_NOTIFICATIONS_FORM_KEYS.NEW_PROPERTY_SUMMARY_NOTIFICATION
          }
          label={
            'edit.search-profile.section.notification.new-property-summary.label'
          }
          rules={{
            value: isNotifyNewPropertiesSummary,
          }}
        />

        <Title
          content={t(
            'edit.search-profile.section.notification.price-changes.title'
          )}
        />
        <CheckboxItem
          name={
            SEARCH_PROFILE_NOTIFICATIONS_FORM_KEYS.PRICE_CHANGE_NOTIFICATION
          }
          label={'edit.search-profile.section.notification.price-changes.label'}
          rules={{
            value: isNotifyPriceChange,
          }}
        />
        <FormButtonsWrapper>
          <NotificationSecondaryButton
            label={t('button.close')}
            onClick={closeModal}
          />
          <NotificationMainButton
            label={t('edit.search-profile.modal.button-save')}
            onClick={methods.handleSubmit(onSubmit)}
          />
        </FormButtonsWrapper>
      </FormProvider>
    </>
  );
};

export { NotificationsSearchProfileForm };
