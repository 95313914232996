import styled from 'styled-components';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  margin-right: 8px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:focus {
      box-shadow: 0 0 1px #2196f3;
    }
  }
`;

export const Slider = styled.span<{ isChecked?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.white};
  -webkit-transition: 0.4s;
  border: 1px solid ${({ theme }) => hexToRGB(theme.blue, 0.5)};
  transition: 0.4s;
  border-radius: 34px;
  ${({ isChecked, theme }) =>
    isChecked &&
    `
  border: 1px solid ${theme.blue};
  `}}

  &:before {
    border-radius: 34px;
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    left: 3px;
    bottom: 3px;
    background-color: ${({ theme }) => hexToRGB(theme.blue, 0.5)};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    ${({ isChecked, theme }) =>
      isChecked &&
      `
      background-color: ${theme.blue};
      -webkit-transform: translateX(12px);
      -ms-transform: translateX(12px);
      transform: translateX(12px);
  `}}
`;

export const Label = styled.label<{ isChecked?: boolean }>`
  font-family: Roboto, sans-serif;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  margin-right: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${({ theme, isChecked }) =>
    hexToRGB(theme.blue, isChecked ? 0.7 : 0.5)};
`;
