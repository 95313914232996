import { useParams } from 'react-router';
import { useCallback } from 'react';

import { languages, localizations } from './constants';
import useGetLocalization from './get-localization';

type TGetCountry = {
  countryCode: string;
  phonePrefix: string;
  language: string;
  isSwitzerland: boolean;
  isAustria: boolean;
  isGermany: boolean;
  isDeAtCh: boolean;
  getCountry: (country?: string) => { isDeAtCh: boolean };
};

const useGetCountry = (): TGetCountry => {
  const lang = useGetLocalization();

  // country & phone prefix
  const isGermany = localizations.germany.languagesCountry.includes(lang);
  const isSwitzerland =
    localizations.switzerland.languagesCountry.includes(lang);
  const isAustria = localizations.austria.languagesCountry.includes(lang);
  // const isLuxembourg = localizations.luxembourg.languagesCountry.includes(lang);
  // const isUnitedStates =
  //   localizations.unitedStates.languagesCountry.includes(lang);
  // const isSpain = localizations.spain.languagesCountry.includes(lang);
  // const isFrance = localizations.france.languagesCountry.includes(lang);
  // const isHungary = localizations.hungary.languagesCountry.includes(lang);
  // const isGreece = localizations.greece.languagesCountry.includes(lang);
  // const isItaly = localizations.italy.languagesCountry.includes(lang);
  // const isNetherlands =
  //   localizations.netherlands.languagesCountry.includes(lang);
  // const isPortugal = localizations.portugal.languagesCountry.includes(lang);
  const isDeAtCh = isGermany || isSwitzerland || isAustria;

  const countryObject =
    Object.entries(localizations).find((item) =>
      item[1].languagesCountry.includes(lang)
    )?.[1] ?? localizations.germany;

  // language
  let language = lang?.split('-')[0];
  if (!language || !languages.includes(language)) {
    language = localizations.germany.defaultLanguage;
  }

  const getCountry = useCallback((countryCode?: string) => {
    const isDeAtChBase =
      countryCode === localizations.germany.countryCode.toUpperCase() ||
      countryCode === localizations.austria.countryCode.toUpperCase() ||
      countryCode === localizations.switzerland.countryCode.toUpperCase();

    return { isDeAtCh: isDeAtChBase };
  }, []);

  return {
    countryCode: countryObject.countryCode,
    language,
    phonePrefix: countryObject.phonePrefix,
    isGermany,
    isAustria,
    isSwitzerland,
    isDeAtCh,
    getCountry,
  };
};

export { useGetCountry };
