import { FC } from 'react';
import { MainButton } from '../../../../common/components/ui/buttons';
import {
  Container,
  ContentContainer,
  ContentContainer2,
  ImageBox,
  InfoBox,
  Description,
  BoxButtons,
  StyledImg,
  Title,
  Wrapper,
} from './local-market-styles';
import {
  LocalMarket as LocalMarketType,
  RpSection,
  useReadLocalMarketQuery,
} from '../../../../../generated';

interface IProps {
  section: RpSection;
  fileUrl?: string;
}

interface LocalMarketProps {
  id: string;
  title: string;
  fileUrl?: string;
}

interface LocalMarketResponse {
  data: { data: LocalMarketType };
  isLoading: boolean;
}

const LocalMarketItem = ({ id, title, fileUrl }: LocalMarketProps) => {
  const { data, isLoading } = useReadLocalMarketQuery<LocalMarketResponse>({
    id,
  });

  const onClick = () => {
    window.open(fileUrl, '_blank');
  };

  if (isLoading) return null;

  const localMarket = data?.data;

  return (
    <Wrapper>
      <Container>
        <ContentContainer>
          <ContentContainer2>
            <InfoBox>
              <Title content={title} />
              <Description
                data-swiper-parallax="0"
                dangerouslySetInnerHTML={{ __html: localMarket?.text ?? '' }}
              />
              <BoxButtons>
                <MainButton
                  data-swiper-parallax="0"
                  label={localMarket?.buttonLabel as string}
                  onClick={onClick}
                />
              </BoxButtons>
            </InfoBox>
            <ImageBox>
              <StyledImg
                key={localMarket?.imageKey as string}
                src={localMarket?.imageUrl as string}
                alt={localMarket?.imageAlt as string}
              />
            </ImageBox>
          </ContentContainer2>
        </ContentContainer>
      </Container>
    </Wrapper>
  );
};

const LocalMarkets: FC<IProps> = ({ section, fileUrl }) => {
  const { recordIds, title } = section;

  return (
    <>
      {recordIds.map((id: string) => {
        return (
          <LocalMarketItem key={id} id={id} title={title} fileUrl={fileUrl} />
        );
      })}
    </>
  );
};

export { LocalMarkets };
