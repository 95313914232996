interface IMetaAttributes {
  name: string;
  content: string;
}

export const getAttributesFromMeta = (tag: string): IMetaAttributes => {
  if (
    !tag.includes('<meta ') &&
    !tag.includes(' />') &&
    !tag.includes('" ') &&
    !tag.includes('="') &&
    !tag.includes('=“')
  ) {
    return {
      name: '',
      content: '',
    };
  }

  const attributes = tag
    .split('<meta ')[1]
    .split('" />')[0]
    .split('" ')
    .map((item) => item.split('="'))
    .map((item) => (item.length === 1 ? item[0].split('=“') : item));
  return Object.fromEntries(attributes);
};
