import { FC, useEffect, useRef, useState } from 'react';

import { Circle, Meter, Svg, Text } from './circle-progress-styles';

interface IProps {
  percent: number;
  circleValue?: number;
  size?: number;
  color?: string;
  isLoading?: boolean;
  isPortalPage?: boolean;
  isHideZero?: boolean;
}

const CircleProgress: FC<IProps> = ({
  percent,
  circleValue,
  size,
  color,
  isLoading,
  isPortalPage,
  isHideZero,
}) => {
  // Get all the Meters
  const [lastPercent, setLastPercent] = useState(percent);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const intervalIndex = useRef<Timeout>(0);
  const containerClassName = isPortalPage
    ? '.feasibility-rating-container'
    : '.container-svg';
  const meterClassName = isPortalPage ? '.feasibility-rating-meter' : '.meter';

  useEffect(() => {
    const meters = document.querySelectorAll(
      `${containerClassName} ${meterClassName}`
    );
    meters.forEach((elem) => {
      const path = elem as SVGPathElement;
      // Get the length of the path
      const length = path.getTotalLength();

      const to = length * ((100 - (circleValue ?? percent)) / 100);
      path.getBoundingClientRect();
      // Set the Offset
      path.style.strokeDashoffset = `${Math.max(0, to)}`;
    });
  }, [circleValue, percent, lastPercent, isPortalPage]);

  useEffect(() => {
    if (lastPercent !== percent) {
      intervalIndex.current = setTimeout(() => {
        if (lastPercent < percent) {
          setLastPercent(lastPercent + 1);
        } else if (lastPercent > percent) {
          setLastPercent(lastPercent - 1);
        }
      }, 20);
    }
    return () => {
      clearTimeout(intervalIndex.current);
    };
  }, [lastPercent, percent, circleValue, isPortalPage]);

  return (
    <>
      <Svg
        className={containerClassName.replace('.', '')}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        size={size}
        // data-value={`${lastPercent}`}
      >
        <Circle r="45" cx="50" cy="50" color={color} isLoading={isLoading} />
        <Meter
          color={color}
          isLoading={isLoading}
          className={meterClassName.replace('.', '')}
          d="M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDashoffset="282.78302001953125"
          strokeDasharray="282.78302001953125"
        />
        <Text
          x="50"
          y="50"
          textAnchor="middle"
          dominantBaseline="central"
          fontSize="20"
        >
          {isHideZero && !isLoading && percent === 0 ? `` : `${lastPercent}%`}
        </Text>
      </Svg>
    </>
  );
};

export { CircleProgress };
