import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Arrow } from 'assets/streamline-light/arrows-diagrams/arrows/icon-arrow-down-blue.svg';

import { useConfig } from 'config/useConfig';
import { device } from '../../../../../style/theme';
import useGetLocalization from '../../../../localization/get-localization';
import { useAppSelector } from '../../../hooks';

import {
  navigationDetails,
  NavigationLinkInterface,
  navigationLinks,
} from './navigation-types';

const ModalPlacement = document.querySelector('#modal-root') as Element;

const Backdrop = styled.div`
  background: rgba(0, 0, 0, 0.15);
  position: fixed;
  backdrop-filter: blur(4.5px);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  z-index: 2000;
`;

interface BackdropComponentProps {
  setIsOpen: (newIsOpen: boolean) => void;
}

const BackdropComponent = ({ setIsOpen }: BackdropComponentProps) => {
  return createPortal(
    <Backdrop onMouseOver={() => setIsOpen(false)} />,
    ModalPlacement
  );
};

const Content = styled.div`
  position: absolute;
  top: 61px;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.98);
  display: flex;

  &:before {
    content: '';
    width: 100vw;
    background: rgba(255, 255, 255, 0.98);
    position: absolute;
    left: 50%;
    right: 50%;
    margin: 0 -50vw;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
`;

const MenuBox = styled.div<{ activeMenu?: string }>`
  flex: 1;
  padding-top: 42px;
  padding-bottom: 42px;
  padding-left: 278px;

  @media ${device.laptop} {
    padding-left: 100px;
  }

  & > a:last-child {
    margin: 0;
  }
`;

const DetailsBox = styled.div`
  flex: 1;
  background: rgba(0, 48, 94, 0.05);
  padding: 51px 255px 65px 57px;
  &:before {
    content: '';
    width: 100%;
    background: rgba(0, 48, 94, 0.05);
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
  }

  @media ${device.bigScreen} {
    padding: 51px 100px 65px 57px;
  }

  @media ${device.laptop} {
    padding: 51px 100px 65px 57px;
  }
`;

const Title = styled.h2`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.15px;
  color: #00305e;
  margin-top: 12px;
  margin-bottom: 21px;
`;

const Description = styled.p`
  max-width: 313px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: rgba(0, 48, 94, 0.75);
  margin-bottom: 23px;
`;

const CTAButton = styled.a`
  text-decoration: none;
  padding: 11px 45.5px 10px;
  background: #4e73f4;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px rgba(0, 48, 94, 0.08);
  border-radius: 5px;
  outline: none;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1;
  color: white;
  cursor: pointer;

  &:hover {
    transition: opacity 0.3s;
    opacity: 0.6;
  }
`;

const MenuLink = styled.a<{ isActive?: boolean }>`
  margin-bottom: 30px;
  margin-right: 30px;
  color: rgba(0, 48, 94);
  transition: opacity 0.3s;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;

  ${(props) =>
    props.isActive &&
    `
    padding-bottom: 6px;
    border-bottom: 2px solid rgba(0, 48, 94);
    margin-bottom: 14px;
    // display: inline-block;
  `}

  &:hover {
    opacity: 0.6;
  }
`;

const SubLink = styled.a`
  margin-bottom: 17px;
  transition: opacity 0.3s;
  font-family: Roboto, sans-serif;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.02em;
  display: block;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 48, 94, 0.8);
  text-decoration: none;

  &:hover {
    opacity: 1;
  }

  &:last-child {
    margin-bottom: 32px;
  }
`;

type LinkOption =
  | 'evaluate'
  | 'sell'
  | 'buy'
  | 'finance'
  | 'location'
  | 'company'
  | '';

interface NavigationDropdownProps {
  activeMenu: LinkOption;
  setIsOpen: (newIsOpen: boolean) => void;
}

const MainNavCollapse = ({ link }: { link: NavigationLinkInterface }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <MenuLink isActive={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {t(link.label)}
        <Arrow />
      </MenuLink>
      {isOpen && (
        <div>
          {(link.children || []).map(({ label, path }) => {
            return (
              <SubLink key={`${label}--${path}`} href={path}>
                {t(label)}
              </SubLink>
            );
          })}
        </div>
      )}
    </div>
  );
};

const NavigationDropdown = ({
  activeMenu,
  setIsOpen,
}: NavigationDropdownProps) => {
  const { t } = useTranslation();

  const { isProd } = useConfig();
  const lang = useGetLocalization();

  const user = useAppSelector((state) => state.auth.user);

  const isOpen = activeMenu !== '';

  const toggleBodyScroll = (scrollState: boolean) => {
    document.body.style.overflow = scrollState ? 'auto' : 'hidden';
  };

  useEffect(() => {
    toggleBodyScroll(!isOpen);
  }, [isOpen]);

  if (!isOpen) return null;

  const itemLinks =
    (activeMenu === '' ? [] : navigationLinks?.[activeMenu]) || [];
  const details = activeMenu === '' ? null : navigationDetails?.[activeMenu];

  return (
    <>
      <Content>
        <MenuBox activeMenu={activeMenu}>
          {itemLinks.map((link: NavigationLinkInterface) => {
            if (!user && link.loggedInOnly) return null;

            if (user && link.notLoggedInOnly) return null;

            if (link.devOnly && isProd) return null;

            if (link.path) {
              return (
                <MenuLink
                  href={link.path.replace(
                    '#appUrl',
                    `${window.location.origin}/${lang}`
                  )}
                  key={link.label}
                >
                  {t(link.label)}
                </MenuLink>
              );
            }

            return <MainNavCollapse key={link.label} link={link} />;
          })}
        </MenuBox>
        {details && (
          <DetailsBox>
            <img
              alt={details.title}
              src={details.icon}
              width={30}
              height={30}
            />
            <Title>{t(details.title)}</Title>
            <Description>{t(details.description)}</Description>
            <CTAButton
              href={details.link.replace(
                '#appUrl',
                `${window.location.origin}/${lang}`
              )}
            >
              {t('navigation.more-info')}
            </CTAButton>
          </DetailsBox>
        )}
      </Content>
      <BackdropComponent setIsOpen={setIsOpen} />
    </>
  );
};

export default NavigationDropdown;
