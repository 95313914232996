import { api } from '../services/graphql/graphql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcceptPurchaseOfferInput = {
  bidOnOfficeId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  customerOnOfficeId: Scalars['String'];
  isOfferNotary?: Maybe<Scalars['Boolean']>;
  notaryAppointmentFromDate?: Maybe<Scalars['String']>;
  notaryAppointmentToDate?: Maybe<Scalars['String']>;
  price: Scalars['String'];
  propertyId: Scalars['String'];
};

export type Activity = {
  __typename?: 'Activity';
  _id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  dateTime: Scalars['DateTime'];
  title: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  street: Scalars['String'];
};

export type AddressFinancing = {
  __typename?: 'AddressFinancing';
  city: Scalars['String'];
  locality?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  placeName?: Maybe<Scalars['String']>;
  postCode: Scalars['String'];
  region?: Maybe<Scalars['String']>;
};

export type AddressFinancingInput = {
  city: Scalars['String'];
  locality?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  placeName?: Maybe<Scalars['String']>;
  postCode: Scalars['String'];
  region?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city: Scalars['String'];
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  street: Scalars['String'];
};

export type AddressSearchProfileEntity = {
  __typename?: 'AddressSearchProfileEntity';
  city: Scalars['String'];
  locality?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  placeName?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
};

export type AddressSearchProfileInput = {
  city: Scalars['String'];
  locality?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  placeName?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
};

export type AdministrativeDivision = {
  __typename?: 'AdministrativeDivision';
  divisionLevel4?: Maybe<Division>;
  divisionLevel6?: Maybe<Division>;
  divisionLevel8?: Maybe<Division>;
  divisionLevel9?: Maybe<Division>;
  divisionLevel11?: Maybe<Division>;
  divisionLevel100?: Maybe<Division>;
};

export type AdvantageFilterInput = {
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AdvantageInput = {
  _id?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconFilename?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Advantages = {
  __typename?: 'Advantages';
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdvantagesInput = {
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnuityDetails = {
  __typename?: 'AnnuityDetails';
  /** zinsbindungInJahren */
  fixedInterestInYears?: Maybe<Scalars['Float']>;
  /** tilgung */
  repayment?: Maybe<Repayment>;
  /** sondertilgungJaehrlich */
  specialRepaymentAnnually?: Maybe<Scalars['Float']>;
};

export type AnnuityDetailsInput = {
  /** zinsbindungInJahren */
  fixedInterestInYears?: Maybe<Scalars['Float']>;
  /** tilgung */
  repayment?: Maybe<RepaymentInput>;
  /** sondertilgungJaehrlich */
  specialRepaymentAnnually?: Maybe<Scalars['Float']>;
};

export type AppFilterInput = {
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AppInput = {
  _id?: Maybe<Scalars['String']>;
  appStoreLink?: Maybe<Scalars['String']>;
  googlePlayLink?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type AppointmentContactFormFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type AppointmentContactFormInput = {
  _id?: Maybe<Scalars['String']>;
  addressBuildingNumberLabel?: Maybe<Scalars['String']>;
  addressBuildingNumberPlaceholder?: Maybe<Scalars['String']>;
  bookAppointmentLabel?: Maybe<Scalars['String']>;
  checkbox1?: Maybe<Scalars['String']>;
  cityPostalLabel?: Maybe<Scalars['String']>;
  cityPostalPlaceholder?: Maybe<Scalars['String']>;
  emailLabel?: Maybe<Scalars['String']>;
  emailPlaceholder?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  formTitle?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  messageLabel?: Maybe<Scalars['String']>;
  messagePlaceholder?: Maybe<Scalars['String']>;
  nameLabel?: Maybe<Scalars['String']>;
  namePlaceholder?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberLabel?: Maybe<Scalars['String']>;
  phoneNumberPlaceholder?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  sendLabel?: Maybe<Scalars['String']>;
  surnameLabel?: Maybe<Scalars['String']>;
  surnamePlaceholder?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AppointmentContactForms = {
  __typename?: 'AppointmentContactForms';
  _id?: Maybe<Scalars['String']>;
  addressBuildingNumberLabel?: Maybe<Scalars['String']>;
  addressBuildingNumberPlaceholder?: Maybe<Scalars['String']>;
  bookAppointmentLabel?: Maybe<Scalars['String']>;
  checkbox1?: Maybe<Scalars['String']>;
  cityPostalLabel?: Maybe<Scalars['String']>;
  cityPostalPlaceholder?: Maybe<Scalars['String']>;
  emailLabel?: Maybe<Scalars['String']>;
  emailPlaceholder?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  formTitle?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  messageLabel?: Maybe<Scalars['String']>;
  messagePlaceholder?: Maybe<Scalars['String']>;
  nameLabel?: Maybe<Scalars['String']>;
  namePlaceholder?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberLabel?: Maybe<Scalars['String']>;
  phoneNumberPlaceholder?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  sendLabel?: Maybe<Scalars['String']>;
  surnameLabel?: Maybe<Scalars['String']>;
  surnamePlaceholder?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AppointmentContactFormsInput = {
  _id?: Maybe<Scalars['String']>;
  addressBuildingNumberLabel?: Maybe<Scalars['String']>;
  addressBuildingNumberPlaceholder?: Maybe<Scalars['String']>;
  bookAppointmentLabel?: Maybe<Scalars['String']>;
  checkbox1?: Maybe<Scalars['String']>;
  cityPostalLabel?: Maybe<Scalars['String']>;
  cityPostalPlaceholder?: Maybe<Scalars['String']>;
  emailLabel?: Maybe<Scalars['String']>;
  emailPlaceholder?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  formTitle?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  messageLabel?: Maybe<Scalars['String']>;
  messagePlaceholder?: Maybe<Scalars['String']>;
  nameLabel?: Maybe<Scalars['String']>;
  namePlaceholder?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberLabel?: Maybe<Scalars['String']>;
  phoneNumberPlaceholder?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  sendLabel?: Maybe<Scalars['String']>;
  surnameLabel?: Maybe<Scalars['String']>;
  surnamePlaceholder?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AppointmentSection = {
  __typename?: 'AppointmentSection';
  _id: Scalars['String'];
  altText?: Maybe<Scalars['String']>;
  avatarKey: Scalars['String'];
  avatarUrl: Scalars['String'];
  awsFolderKey: Scalars['String'];
  brokerPosition?: Maybe<Scalars['String']>;
  brokerQualification?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  legalPopUpLabel?: Maybe<Scalars['String']>;
  legalPopUpText?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  timifyId: Scalars['String'];
  title: Scalars['String'];
  type: SectionType;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Apps = {
  __typename?: 'Apps';
  _id?: Maybe<Scalars['String']>;
  appStoreLink?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  googlePlayLink?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppsInput = {
  _id?: Maybe<Scalars['String']>;
  appStoreLink?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  googlePlayLink?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AuthChallenge = {
  __typename?: 'AuthChallenge';
  challengeName: Scalars['String'];
  challengeParameters: Scalars['JSON'];
  session: Scalars['String'];
};

export type AuthChallengeInput = {
  challengeName: Scalars['String'];
  challengeParameters: Scalars['JSON'];
  session: Scalars['String'];
};

export type Banner = {
  __typename?: 'Banner';
  _id?: Maybe<Scalars['String']>;
  buttonLabel?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BannerFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type BannerInput = {
  _id?: Maybe<Scalars['String']>;
  buttonLabel?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BannersInput = {
  _id?: Maybe<Scalars['String']>;
  buttonLabel?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

export type BenefitFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BenefitInput = {
  _id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  secondaryButtonLabel?: Maybe<Scalars['String']>;
  secondaryButtonLink?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  widgetButtonLabel?: Maybe<Scalars['String']>;
};

export type BenefitSection = {
  __typename?: 'BenefitSection';
  _id: Scalars['String'];
  altText?: Maybe<Scalars['String']>;
  awsFolderKey: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  cta: Scalars['String'];
  ctaLabel: Scalars['String'];
  expandedText: Scalars['String'];
  iconKey: Scalars['String'];
  iconUrl: Scalars['String'];
  imageHint?: Maybe<Scalars['String']>;
  imageKey: Scalars['String'];
  imageUrl: Scalars['String'];
  title: Scalars['String'];
  type: SectionType;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BenefitSectionInput = {
  _id?: Maybe<Scalars['String']>;
  altText?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  ctaLabel?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
  iconFilename?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageHint?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Benefits = {
  __typename?: 'Benefits';
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  secondaryButtonLabel?: Maybe<Scalars['String']>;
  secondaryButtonLink?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
  widgetButtonLabel?: Maybe<Scalars['String']>;
};

export type BenefitsInput = {
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  secondaryButtonLabel?: Maybe<Scalars['String']>;
  secondaryButtonLink?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
  widgetButtonLabel?: Maybe<Scalars['String']>;
};

export type Bid = {
  __typename?: 'Bid';
  bidOnOfficeId: Scalars['String'];
  customerCity: Scalars['String'];
  customerId: Scalars['Int'];
  customerOnOfficeId: Scalars['String'];
  date: Scalars['DateTime'];
  isAccepted?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  newOfferedPrice?: Maybe<Scalars['Float']>;
  offeredPrice: Scalars['Float'];
};

export type BidInput = {
  bidOnOfficeId: Scalars['String'];
  customerCity: Scalars['String'];
  customerId: Scalars['Int'];
  customerOnOfficeId: Scalars['String'];
  date: Scalars['DateTime'];
  isAccepted?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  newOfferedPrice?: Maybe<Scalars['Float']>;
  offeredPrice: Scalars['Float'];
};

export enum BlockType {
  Advantages = 'ADVANTAGES',
  Appointment = 'APPOINTMENT',
  Apps = 'APPS',
  Banner = 'BANNER',
  Benefit = 'BENEFIT',
  Certificates = 'CERTIFICATES',
  Content = 'CONTENT',
  Faq = 'FAQ',
  LocalMarket = 'LOCAL_MARKET',
  OwnerSettings = 'OWNER_SETTINGS',
  PropertyTab = 'PROPERTY_TAB',
}

export type Broker = {
  __typename?: 'Broker';
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  position: Scalars['String'];
  propertyOnOfficeId?: Maybe<Scalars['Int']>;
  qualification?: Maybe<Scalars['String']>;
  relatedShop?: Maybe<Shop>;
  surname: Scalars['String'];
};

export type BrokerPermissions = {
  __typename?: 'BrokerPermissions';
  canChangePropertySettings: Scalars['Boolean'];
  canChangeShopSettings: Scalars['Boolean'];
};

export type BrokerShop = {
  __typename?: 'BrokerShop';
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  disabledServices?: Maybe<Array<Scalars['String']>>;
  shopData?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BuildingProjectsEntity = {
  __typename?: 'BuildingProjectsEntity';
  items: Array<BuildingProjectsItemEntity>;
  totalItems: Scalars['String'];
};

export type BuildingProjectsInput = {
  items: Array<BuildingProjectsItemInput>;
  totalItems: Scalars['String'];
};

export type BuildingProjectsItemEntity = {
  __typename?: 'BuildingProjectsItemEntity';
  constructionStartDate?: Maybe<Scalars['String']>;
  distance: Scalars['Float'];
  fullDescription?: Maybe<Scalars['String']>;
  isPreciselyLocated: Scalars['Boolean'];
  location: PropertyLocation;
  numberOfApartments?: Maybe<Scalars['Float']>;
  numberOfBuildings?: Maybe<Scalars['Float']>;
  numberOfFloorsInBuilding?: Maybe<Scalars['Float']>;
  provider: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type BuildingProjectsItemInput = {
  constructionStartDate?: Maybe<Scalars['String']>;
  distance: Scalars['Float'];
  fullDescription?: Maybe<Scalars['String']>;
  isPreciselyLocated: Scalars['Boolean'];
  location: PropertyLocationInput;
  numberOfApartments?: Maybe<Scalars['Float']>;
  numberOfBuildings?: Maybe<Scalars['Float']>;
  numberOfFloorsInBuilding?: Maybe<Scalars['Float']>;
  provider: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type BuyerBenefitFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerBenefitInput = {
  _id?: Maybe<Scalars['String']>;
  createSearchProfileLabel?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  icon1?: Maybe<Scalars['Upload']>;
  icon1Alt?: Maybe<Scalars['String']>;
  icon1Filename?: Maybe<Scalars['String']>;
  icon1Key?: Maybe<Scalars['String']>;
  icon1Url?: Maybe<Scalars['String']>;
  icon2?: Maybe<Scalars['Upload']>;
  icon2Alt?: Maybe<Scalars['String']>;
  icon2Filename?: Maybe<Scalars['String']>;
  icon2Key?: Maybe<Scalars['String']>;
  icon2Url?: Maybe<Scalars['String']>;
  icon3?: Maybe<Scalars['Upload']>;
  icon3Alt?: Maybe<Scalars['String']>;
  icon3Filename?: Maybe<Scalars['String']>;
  icon3Key?: Maybe<Scalars['String']>;
  icon3Url?: Maybe<Scalars['String']>;
  icon4?: Maybe<Scalars['Upload']>;
  icon4Alt?: Maybe<Scalars['String']>;
  icon4Filename?: Maybe<Scalars['String']>;
  icon4Key?: Maybe<Scalars['String']>;
  icon4Url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  text1?: Maybe<Scalars['String']>;
  text2?: Maybe<Scalars['String']>;
  text3?: Maybe<Scalars['String']>;
  text4?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerBenefits = {
  __typename?: 'BuyerBenefits';
  _id?: Maybe<Scalars['String']>;
  createSearchProfileLabel?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  icon1Alt?: Maybe<Scalars['String']>;
  icon1Key?: Maybe<Scalars['String']>;
  icon1Url?: Maybe<Scalars['String']>;
  icon2Alt?: Maybe<Scalars['String']>;
  icon2Key?: Maybe<Scalars['String']>;
  icon2Url?: Maybe<Scalars['String']>;
  icon3Alt?: Maybe<Scalars['String']>;
  icon3Key?: Maybe<Scalars['String']>;
  icon3Url?: Maybe<Scalars['String']>;
  icon4Alt?: Maybe<Scalars['String']>;
  icon4Key?: Maybe<Scalars['String']>;
  icon4Url?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  text1?: Maybe<Scalars['String']>;
  text2?: Maybe<Scalars['String']>;
  text3?: Maybe<Scalars['String']>;
  text4?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerBenefitsInput = {
  _id?: Maybe<Scalars['String']>;
  createSearchProfileLabel?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  icon1Alt?: Maybe<Scalars['String']>;
  icon1Key?: Maybe<Scalars['String']>;
  icon1Url?: Maybe<Scalars['String']>;
  icon2Alt?: Maybe<Scalars['String']>;
  icon2Key?: Maybe<Scalars['String']>;
  icon2Url?: Maybe<Scalars['String']>;
  icon3Alt?: Maybe<Scalars['String']>;
  icon3Key?: Maybe<Scalars['String']>;
  icon3Url?: Maybe<Scalars['String']>;
  icon4Alt?: Maybe<Scalars['String']>;
  icon4Key?: Maybe<Scalars['String']>;
  icon4Url?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  text1?: Maybe<Scalars['String']>;
  text2?: Maybe<Scalars['String']>;
  text3?: Maybe<Scalars['String']>;
  text4?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerFinderSettings = {
  __typename?: 'BuyerFinderSettings';
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  locale: Scalars['String'];
  name: Scalars['String'];
  sections?: Maybe<Array<BuyerFinderSettingsSection>>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BuyerFinderSettingsFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type BuyerFinderSettingsInput = {
  _id?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  name: Scalars['String'];
  sections?: Maybe<Array<BuyerFinderSettingsSectionInput>>;
};

export type BuyerFinderSettingsSection = {
  __typename?: 'BuyerFinderSettingsSection';
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: BuyerFinderSettingsSectionType;
};

export type BuyerFinderSettingsSectionInput = {
  _id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: BuyerFinderSettingsSectionType;
};

export enum BuyerFinderSettingsSectionType {
  Faq = 'FAQ',
}

export type BuyerFinderTeaserFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerFinderTeaserInput = {
  CTALabel?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  step1Text?: Maybe<Scalars['String']>;
  step1Title?: Maybe<Scalars['String']>;
  step2Text?: Maybe<Scalars['String']>;
  step2Title?: Maybe<Scalars['String']>;
  step3Text?: Maybe<Scalars['String']>;
  step3Title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerFinderTeasers = {
  __typename?: 'BuyerFinderTeasers';
  CTALabel?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  step1Text?: Maybe<Scalars['String']>;
  step1Title?: Maybe<Scalars['String']>;
  step2Text?: Maybe<Scalars['String']>;
  step2Title?: Maybe<Scalars['String']>;
  step3Text?: Maybe<Scalars['String']>;
  step3Title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerFinderTeasersInput = {
  CTALabel?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  step1Text?: Maybe<Scalars['String']>;
  step1Title?: Maybe<Scalars['String']>;
  step2Text?: Maybe<Scalars['String']>;
  step2Title?: Maybe<Scalars['String']>;
  step3Text?: Maybe<Scalars['String']>;
  step3Title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerFunctionFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerFunctionInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconFilename?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerFunctions = {
  __typename?: 'BuyerFunctions';
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerFunctionsInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerLpSection = {
  __typename?: 'BuyerLPSection';
  _id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: BuyerLpSectionType;
};

export type BuyerLpSectionInput = {
  _id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: BuyerLpSectionType;
};

export enum BuyerLpSectionType {
  Appointment = 'APPOINTMENT',
  Benefit = 'BENEFIT',
  BuyerBenefit = 'BUYER_BENEFIT',
  BuyerFunction = 'BUYER_FUNCTION',
  Faq = 'FAQ',
  PropertiesOnFocus = 'PROPERTIES_ON_FOCUS',
  VirtualTour = 'VIRTUAL_TOUR',
}

export type BuyerLandingPage = {
  __typename?: 'BuyerLandingPage';
  _id?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Array<Scalars['String']>>;
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sections?: Maybe<Array<BuyerLpSection>>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BuyerLandingPageFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type BuyerLandingPageInput = {
  _id?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Array<Scalars['String']>>;
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sections?: Maybe<Array<BuyerLpSectionInput>>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerOverviewFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerOverviewInput = {
  _id?: Maybe<Scalars['String']>;
  createSearchProfileLabel?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerOverviews = {
  __typename?: 'BuyerOverviews';
  _id?: Maybe<Scalars['String']>;
  createSearchProfileLabel?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BuyerOverviewsInput = {
  _id?: Maybe<Scalars['String']>;
  createSearchProfileLabel?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Catering = {
  __typename?: 'Catering';
  bar?: Maybe<Array<Poi>>;
  cafe?: Maybe<Array<Poi>>;
  restaurant?: Maybe<Array<Poi>>;
};

export type CentralPhone = {
  __typename?: 'CentralPhone';
  _id?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  phoneNumber: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CentralPhoneFilterInput = {
  phoneNumber?: Maybe<Scalars['String']>;
};

export type CentralPhoneInput = {
  _id?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CentralPhones = {
  _id?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  phoneNumber: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CertificateFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type CertificateInput = {
  _id?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  fileAlt?: Maybe<Scalars['String']>;
  fileKey?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Certificates = {
  __typename?: 'Certificates';
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  fileAlt?: Maybe<Scalars['String']>;
  fileKey?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CertificatesInput = {
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  fileAlt?: Maybe<Scalars['String']>;
  fileKey?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChangePasswordInput = {
  accessToken: Scalars['String'];
  newPassword: Scalars['String'];
  oldPassword?: Maybe<Scalars['String']>;
};

export type CheckInviteHealth = {
  email: Scalars['String'];
  inviteId: Scalars['String'];
};

export type CircleInput = {
  center?: Maybe<LocationInput>;
  radius?: Maybe<Scalars['Int']>;
};

export type CmsBlock = {
  __typename?: 'CmsBlock';
  _id: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  isActive: Scalars['Boolean'];
  orderIndex: Scalars['Float'];
  sections: Array<CmsSectionUnion>;
  title: Scalars['String'];
  type: BlockType;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CmsBlockSectionsArgs = {
  randomiseSection?: Maybe<Scalars['Boolean']>;
};

export type CmsPage = {
  __typename?: 'CmsPage';
  _id: Scalars['String'];
  blocks: Array<CmsBlock>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  representativeName: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CmsRegionalPage = {
  __typename?: 'CmsRegionalPage';
  _id: Scalars['String'];
  active: Scalars['Boolean'];
  awsFolderKey: Scalars['String'];
  bookingButtonLabel: Scalars['String'];
  country: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  heading: Scalars['String'];
  imageAlt: Scalars['String'];
  imageKey: Scalars['String'];
  imageUrl: Scalars['String'];
  metaDescription: Scalars['String'];
  metaKeywords: Array<Scalars['String']>;
  metaTitle: Scalars['String'];
  name: Scalars['String'];
  personName: Scalars['String'];
  personPosition: Scalars['String'];
  personText: Scalars['String'];
  phone: Scalars['String'];
  routeButtonLabel?: Maybe<Scalars['String']>;
  routeButtonLink?: Maybe<Scalars['String']>;
  sections: Array<RpSection>;
  slug: Scalars['String'];
  subheading: Scalars['String'];
  title: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CmsRegionalPageFilterInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type CmsSection = {
  __typename?: 'CmsSection';
  _id: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  type: SectionType;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CmsSectionFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type CmsSectionUnion =
  | AppointmentSection
  | BenefitSection
  | ContentSection
  | FaqSection
  | OwnerSettingsSection
  | VideoSection
  | VirtualTourSection;

export type Comparable = {
  __typename?: 'Comparable';
  address?: Maybe<ComparableAddress>;
  buildingYear?: Maybe<Scalars['Int']>;
  contactInfo?: Maybe<ContactInfoEntity>;
  coordinates?: Maybe<Coordinates>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  daysOnMarket?: Maybe<Scalars['Int']>;
  dealType?: Maybe<DealType>;
  description?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Int']>;
  distanceGroceryStore?: Maybe<Scalars['Int']>;
  distanceHospital?: Maybe<Scalars['Int']>;
  distancePublicTransport?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['DateTime']>;
  floorNumber?: Maybe<Scalars['Int']>;
  hasLift?: Maybe<Scalars['Boolean']>;
  hasParkingSpaces?: Maybe<Scalars['Boolean']>;
  images: Array<ImageEntity>;
  isActive: Scalars['Boolean'];
  isExclusive?: Maybe<Scalars['Boolean']>;
  landArea?: Maybe<Scalars['Int']>;
  livingArea?: Maybe<Scalars['Int']>;
  noise?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Int']>;
  numberOfIndoorParkingSpaces?: Maybe<Scalars['Int']>;
  numberOfOutdoorParkingSpaces?: Maybe<Scalars['Int']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  offerId: Scalars['String'];
  propertyType: PropertyType;
  rentGross?: Maybe<Scalars['Int']>;
  rentNet?: Maybe<Scalars['Int']>;
  salePrice?: Maybe<Scalars['Int']>;
  shareableLink?: Maybe<Scalars['String']>;
  slope?: Maybe<Scalars['Float']>;
  startDate: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Int']>;
};

export type ComparableAddress = {
  __typename?: 'ComparableAddress';
  city?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type Comparables = {
  __typename?: 'Comparables';
  items: Array<Comparable>;
  totalItems: Scalars['Int'];
};

export enum Condition {
  NewOrRecentlyRenovated = 'new_or_recently_renovated',
  RenovationNeeded = 'renovation_needed',
  WellMaintained = 'well_maintained',
}

export enum Confidence {
  Good = 'good',
  Medium = 'medium',
  Poor = 'poor',
}

export type ConfirmEmailInput = {
  _id: Scalars['String'];
  accessToken?: Maybe<Scalars['String']>;
  confirmationCode: Scalars['String'];
};

export type ConfirmForgotPasswordInput = {
  confirmationCode: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
  username: Scalars['String'];
};

export type ConfirmGuestUserInput = {
  phoneNumber: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type ConfirmPhoneInput = {
  confirmationCode: Scalars['String'];
  username: Scalars['String'];
};

export type ContactInfoEntity = {
  __typename?: 'ContactInfoEntity';
  inquiryPerson?: Maybe<Raw>;
  organization?: Maybe<Raw>;
  raw?: Maybe<Scalars['String']>;
  viewPerson?: Maybe<Raw>;
};

export type ContactPerson = {
  __typename?: 'ContactPerson';
  adressfreigabe?: Maybe<Scalars['String']>;
  anrede?: Maybe<Scalars['String']>;
  anrede_brief?: Maybe<Scalars['String']>;
  email_direkt?: Maybe<Scalars['String']>;
  email_feedback?: Maybe<Scalars['String']>;
  email_privat?: Maybe<Scalars['String']>;
  email_sonstige?: Maybe<Scalars['String']>;
  email_zentrale: Scalars['String'];
  firma?: Maybe<Scalars['String']>;
  foto?: Maybe<Scalars['String']>;
  freitextfeld?: Maybe<Scalars['String']>;
  hausnummer?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  immoID: Scalars['Float'];
  kontaktperson?: Maybe<Scalars['String']>;
  land?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ort?: Maybe<Scalars['String']>;
  personennummer?: Maybe<Scalars['String']>;
  plz?: Maybe<Scalars['String']>;
  postf_ort?: Maybe<Scalars['String']>;
  postf_plz?: Maybe<Scalars['String']>;
  postfach?: Maybe<Scalars['String']>;
  strasse?: Maybe<Scalars['String']>;
  tel_durchw: Scalars['String'];
  tel_fax?: Maybe<Scalars['String']>;
  tel_handy?: Maybe<Scalars['String']>;
  tel_privat?: Maybe<Scalars['String']>;
  tel_sonstige?: Maybe<Scalars['String']>;
  tel_zentrale: Scalars['String'];
  titel?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  vorname?: Maybe<Scalars['String']>;
  zusatzfeld?: Maybe<Scalars['String']>;
};

export type ContentSection = {
  __typename?: 'ContentSection';
  _id: Scalars['String'];
  contentHTML: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  scriptId?: Maybe<Scalars['String']>;
  scriptSrc?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: SectionType;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Country = {
  __typename?: 'Country';
  _id: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
};

export enum CountryCodeFromVonPollDbEnum {
  Aut = 'AUT',
  Bel = 'BEL',
  Che = 'CHE',
  Deu = 'DEU',
  Esp = 'ESP',
  Fra = 'FRA',
  Grc = 'GRC',
  Hun = 'HUN',
  Ita = 'ITA',
  Lux = 'LUX',
  Nld = 'NLD',
  Prt = 'PRT',
  Srb = 'SRB',
}

export type CountryFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type CreateAdminInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  prefix: Scalars['String'];
  surname: Scalars['String'];
};

export type CreateAppointmentSectionInput = {
  altText: Scalars['String'];
  brokerPosition?: Maybe<Scalars['String']>;
  brokerQualification?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
  filename?: Maybe<Scalars['String']>;
  legalPopUpLabel?: Maybe<Scalars['String']>;
  legalPopUpText?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  timifyId: Scalars['String'];
  title: Scalars['String'];
};

export type CreateBrokerShopInput = {
  disabledServices?: Maybe<Array<Scalars['String']>>;
  shopData?: Maybe<Scalars['String']>;
};

export type CreateCmsBlockInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  sections?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  type: BlockType;
};

export type CreateCmsPageInput = {
  blocks: Array<Scalars['String']>;
  name: Scalars['String'];
  representativeName: Scalars['String'];
};

export type CreateCmsRegionalPageInput = {
  active: Scalars['Boolean'];
  bookingButtonLabel: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  heading: Scalars['String'];
  image: Scalars['Upload'];
  imageAlt: Scalars['String'];
  metaDescription: Scalars['String'];
  metaKeywords: Array<Scalars['String']>;
  metaTitle: Scalars['String'];
  name: Scalars['String'];
  personName: Scalars['String'];
  personPosition: Scalars['String'];
  personText: Scalars['String'];
  phone: Scalars['String'];
  routeButtonLabel?: Maybe<Scalars['String']>;
  routeButtonLink?: Maybe<Scalars['String']>;
  sections: Array<RpSectionInput>;
  slug: Scalars['String'];
  subheading: Scalars['String'];
  title: Scalars['String'];
};

export type CreateContentSectionInput = {
  contentHTML: Scalars['String'];
  scriptId?: Maybe<Scalars['String']>;
  scriptSrc?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateCountryInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CreateExperimentInput = {
  caseAWeight: Scalars['Float'];
  caseBWeight: Scalars['Float'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  type: ExperimentType;
};

export type CreateFaqSectionInput = {
  expandedText: Scalars['String'];
  order: Scalars['Int'];
  title: Scalars['String'];
};

export type CreateFinancingInput = {
  financingData: FinancingDataInput;
  financingVariants: Array<FinancingVariantInput>;
  marketingParameters?: Maybe<Scalars['String']>;
  onlineExposeId?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  searchProfileId?: Maybe<Scalars['String']>;
  vonPollOnOfficeData?: Maybe<VonPollOnOfficeDataInput>;
  vonPollPropertyData?: Maybe<VonPollPropertyDataInput>;
};

export type CreateGeneralMarketReportInput = {
  country: Scalars['String'];
  file: Scalars['Upload'];
  fileKey?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
};

export type CreateInviteInput = {
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  invitationPrivileges: InvitationPrivileges;
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  status?: Maybe<InviteStatus>;
  surname: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateMarketReportInput = {
  cities: Array<Scalars['String']>;
  country: Scalars['String'];
  file: Scalars['Upload'];
  fileKey?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type CreateOwnerSettingsSectionInput = {
  file: Scalars['Upload'];
  filename?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type CreatePropertyInput = {
  countryCode: Scalars['String'];
  dealType: DealType;
  exposeStatus?: Maybe<OnOfficeExposeStatus>;
  externalId?: Maybe<Scalars['String']>;
  includeInitialValuations?: Maybe<Scalars['Boolean']>;
  isContractSigned?: Maybe<Scalars['Boolean']>;
  isCreatedVia3rdPartyPortal?: Maybe<Scalars['Boolean']>;
  isDigitalServiceActivated?: Maybe<Scalars['Boolean']>;
  isPreview?: Maybe<Scalars['Boolean']>;
  isRealSellingProcessStarted?: Maybe<Scalars['Boolean']>;
  marketingParameters?: Maybe<Scalars['String']>;
  onOfficeId?: Maybe<Scalars['String']>;
  onOfficePrice?: Maybe<Scalars['Float']>;
  onOfficeUUID?: Maybe<Scalars['String']>;
  otherRatingReason?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  ownerOnOfficeId?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Scalars['String']>>;
  priceCurrency?: Maybe<Scalars['String']>;
  propertyData: PropertyInput;
  propertyPortfolioData?: Maybe<PropertyPortfolioDataInput>;
  ratingReason?: Maybe<RatingReason>;
  status?: Maybe<PropertyStatus>;
  timeHorizont?: Maybe<TimeHorizont>;
  title?: Maybe<Scalars['String']>;
  tour360Link?: Maybe<Scalars['String']>;
  uploadedFilesCount?: Maybe<Scalars['Int']>;
  valuationType: ValuationType;
};

export type CreateSearchProfileInput = {
  countryCode: Scalars['String'];
  isCreatedVia3rdPartyPortal?: Maybe<Scalars['Boolean']>;
  isNotifyNewProperties?: Maybe<Scalars['Boolean']>;
  isNotifyPriceChange?: Maybe<Scalars['Boolean']>;
  marketingParameters?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  relatedShop?: Maybe<ShopEmailInput>;
  searchProfileData: SearchProfileInput;
  title: Scalars['String'];
};

export type CreateTemporarySearchProfileInput = {
  countryCode: Scalars['String'];
  isCreatedVia3rdPartyPortal?: Maybe<Scalars['Boolean']>;
  isNotifyNewProperties?: Maybe<Scalars['Boolean']>;
  isNotifyPriceChange?: Maybe<Scalars['Boolean']>;
  marketingParameters?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  relatedShop?: Maybe<ShopEmailInput>;
  searchProfileData: SearchProfileInput;
  title: Scalars['String'];
};

export type CreateVideoSectionInput = {
  ctaLabel: Scalars['String'];
  subtitle: Scalars['String'];
  text: Scalars['String'];
  title: Scalars['String'];
  videoURL: Scalars['String'];
};

export type CreateVirtualTourSectionInput = {
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export enum Currency {
  Chf = 'CHF',
  Eur = 'EUR',
}

export type CustomerAppraisalFilterInput = {
  title?: Maybe<Scalars['String']>;
};

export type CustomerAppraisalInput = {
  _id?: Maybe<Scalars['String']>;
  bannerLink?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CustomerAppraisals = {
  __typename?: 'CustomerAppraisals';
  _id?: Maybe<Scalars['String']>;
  bannerLink?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  expandedText?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerAppraisalsInput = {
  _id?: Maybe<Scalars['String']>;
  bannerLink?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  expandedText?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DashboardStatus = {
  __typename?: 'DashboardStatus';
  isDashboardUnlocked?: Maybe<Scalars['Boolean']>;
  isMarketingPaused?: Maybe<Scalars['Boolean']>;
};

export type DateRangeInput = {
  max: Scalars['String'];
  min: Scalars['String'];
};

export enum DealType {
  Rent = 'rent',
  Sale = 'sale',
}

export type Densities = {
  __typename?: 'Densities';
  catering: Scalars['Int'];
  education: Scalars['Int'];
  health: Scalars['Int'];
  leisure: Scalars['Int'];
  shopping: Scalars['Int'];
};

export type Destination = {
  __typename?: 'Destination';
  description: Scalars['String'];
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderIndex: Scalars['Int'];
  url: Scalars['String'];
};

export type DestinationInput = {
  description: Scalars['String'];
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderIndex: Scalars['Int'];
  url: Scalars['String'];
};

export type DetailedPropertyPurchaseIntent = {
  __typename?: 'DetailedPropertyPurchaseIntent';
  address: Scalars['String'];
  area: Scalars['Float'];
  externalId: Scalars['String'];
  id: Scalars['String'];
  img: Scalars['String'];
  offers: Array<PropertyPurchaseIntent>;
  price: Scalars['Float'];
  rooms: Scalars['Float'];
  title: Scalars['String'];
};

export enum Direction {
  Asc = 'asc',
  Desc = 'desc',
}

export type Division = {
  __typename?: 'Division';
  levelDesignation: Scalars['String'];
  name: Scalars['String'];
  officialId: Scalars['String'];
};

export type Dossier = {
  __typename?: 'Dossier';
  askingSalePrice?: Maybe<Scalars['Int']>;
  countryCode: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  dealType: DealType;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  images?: Maybe<Array<DossierImage>>;
  isValuationStale?: Maybe<Scalars['Boolean']>;
  isValuationStored?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['String']>;
  priceStrategyDescription?: Maybe<Scalars['String']>;
  property: Property;
  selectedOffers?: Maybe<Scalars['String']>;
  selectedTransactions?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userDefinedFields?: Maybe<Array<UserDefinedFields>>;
  valuationConfidenceRent?: Maybe<Confidence>;
  valuationConfidenceSale?: Maybe<Confidence>;
  valuationDate?: Maybe<Scalars['String']>;
  valuationOverrideRentGross?: Maybe<Scalars['Int']>;
  valuationOverrideRentGrossRange?: Maybe<ValueRange>;
  valuationOverrideRentNet?: Maybe<Scalars['Int']>;
  valuationOverrideRentNetRange?: Maybe<ValueRange>;
  valuationOverrideSalePrice?: Maybe<Scalars['Int']>;
  valuationOverrideSalePriceRange?: Maybe<ValueRange>;
  valuationRentGross?: Maybe<Scalars['Int']>;
  valuationRentGrossRange?: Maybe<ValueRange>;
  valuationRentNet?: Maybe<Scalars['Int']>;
  valuationRentNetRange?: Maybe<ValueRange>;
  valuationSalePrice?: Maybe<Scalars['Int']>;
  valuationSalePriceRange?: Maybe<ValueRange>;
};

export type DossierImage = {
  __typename?: 'DossierImage';
  caption?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
};

export type DossierImageInput = {
  caption?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
};

export type Education = {
  __typename?: 'Education';
  kindergarten?: Maybe<Array<Poi>>;
  primary_school?: Maybe<Array<Poi>>;
  secondary_school?: Maybe<Array<Poi>>;
  university?: Maybe<Array<Poi>>;
};

export type EffortRating = {
  __typename?: 'EffortRating';
  /** Explanations why the process is complex */
  explanations?: Maybe<Array<Scalars['String']>>;
  /** Indicator whether the operation is associated with a high workload */
  rating?: Maybe<Scalars['Boolean']>;
};

export type EffortRatingInput = {
  /** Explanations why the process is complex */
  explanations?: Maybe<Array<Scalars['String']>>;
  /** Indicator whether the operation is associated with a high workload */
  rating?: Maybe<Scalars['Boolean']>;
};

export enum EuropaceDataType {
  FinancingCalculatorLandingPage = 'FINANCING_CALCULATOR_LANDING_PAGE',
  InterestRatesTable = 'INTEREST_RATES_TABLE',
}

export type EvaluationCategory = {
  __typename?: 'EvaluationCategory';
  _id?: Maybe<Scalars['String']>;
  cities: Array<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  postCodes: Array<Scalars['String']>;
  priceAlterations: Array<PriceAlteration>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EvaluationCategoryFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type EvaluationCategoryInput = {
  _id?: Maybe<Scalars['String']>;
  cities?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  postCodes?: Maybe<Array<Scalars['String']>>;
  priceAlterations: Array<PriceAlterationInput>;
};

export type EvaluationCity = {
  __typename?: 'EvaluationCity';
  name: Scalars['String'];
  value: Scalars['Float'];
};

export type EvaluationCityInput = {
  name: Scalars['String'];
  value: Scalars['Float'];
};

export enum EvaluationWidgetVersion {
  NewRedesignedVersion = 'NEW_REDESIGNED_VERSION',
  OldVersion = 'OLD_VERSION',
}

export type Experiment = {
  __typename?: 'Experiment';
  _id: Scalars['String'];
  caseAWeight: Scalars['Float'];
  caseBWeight: Scalars['Float'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  evaluationWidgetVersion: EvaluationWidgetVersion;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  registrationBy: RegistrationBy;
  selectedCase?: Maybe<SelectedCase>;
  type: ExperimentType;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum ExperimentType {
  Register = 'Register',
}

export type ExposeContactForm = {
  isCustomerInterested: Scalars['Boolean'];
  message: Scalars['String'];
  needCallback?: Maybe<Scalars['Boolean']>;
  needMoreInfo?: Maybe<Scalars['Boolean']>;
  needViewingAppointment?: Maybe<Scalars['Boolean']>;
};

export type ExposeEmailBatches = {
  __typename?: 'ExposeEmailBatches';
  newFileUnlocked?: Maybe<Scalars['String']>;
};

export type ExposeEmailBatchesInput = {
  newFileUnlocked?: Maybe<Scalars['String']>;
};

export type ExposeRequest = {
  __typename?: 'ExposeRequest';
  _id?: Maybe<Scalars['String']>;
  buildingProjects?: Maybe<BuildingProjectsEntity>;
  countryCode: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  emailBatches?: Maybe<ExposeEmailBatches>;
  financingId?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  isFinancingMarketingMailSent?: Maybe<Scalars['Boolean']>;
  location: PropertyLocation;
  locationScores?: Maybe<LocationScores>;
  ownerEmail?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  ownerOnOfficeId?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  pdfToken?: Maybe<Scalars['String']>;
  poi?: Maybe<PropertyPoi>;
  poiLastSync?: Maybe<Scalars['DateTime']>;
  propertyDetails?: Maybe<SuggestedProperty>;
  propertyId: Scalars['Float'];
  propertyStatistics?: Maybe<Array<Item>>;
  propertyStatisticsLastSync?: Maybe<Scalars['DateTime']>;
  provision?: Maybe<Provision>;
  searchProfileId: Scalars['String'];
  socioEconomics?: Maybe<Array<SocioEconomicsEntity>>;
  status: ExposeStatus;
  unlockedFiles?: Maybe<Array<Scalars['String']>>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
  valuations?: Maybe<Array<PropertyValuation>>;
  valuationsLastSync?: Maybe<Scalars['DateTime']>;
};

export type ExposeRequestEmailTemplate = {
  buyerPortalId: Scalars['String'];
  city: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  intendToSellProperty?: Maybe<Scalars['Boolean']>;
  isPropertyOwner?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  needFinancingConsultation?: Maybe<Scalars['Boolean']>;
  number: Scalars['Int'];
  phone: Scalars['String'];
  propertyId: Scalars['String'];
  propertyLink: Scalars['String'];
  salutation: Scalars['String'];
  street: Scalars['String'];
};

export type ExposeRequestInput = {
  _id?: Maybe<Scalars['String']>;
  emailBatches?: Maybe<ExposeEmailBatchesInput>;
  externalPropertyId: Scalars['String'];
  financingId?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  isFinancingMarketingMailSent?: Maybe<Scalars['Boolean']>;
  ownerEmail?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  ownerOnOfficeId?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  pdfToken?: Maybe<Scalars['String']>;
  propertyDetails?: Maybe<SuggestedPropertyInput>;
  propertyId?: Maybe<Scalars['Float']>;
  provision?: Maybe<ProvisionInput>;
  searchProfileId?: Maybe<Scalars['String']>;
  status: ExposeStatus;
  unlockedFiles?: Maybe<Array<Scalars['String']>>;
};

export type ExposeRequestsPaginationArgs = {
  /** Limit */
  limit?: Maybe<Scalars['Float']>;
  /** Offset */
  offset?: Maybe<Scalars['Float']>;
};

export enum ExposeStatus {
  Inactive = 'inactive',
  Pending = 'pending',
  Unlocked = 'unlocked',
}

export type ExtraCosts = {
  __typename?: 'ExtraCosts';
  /** maklergebuehr */
  brokerageFee?: Maybe<Scalars['Float']>;
  /** notargebuehr */
  notaryFee?: Maybe<Scalars['Float']>;
  /** grunderwerbsteuer */
  realEstateTransferTax?: Maybe<Scalars['Float']>;
};

export type ExtraCostsInput = {
  /** maklergebuehr */
  brokerageFee?: Maybe<Scalars['Float']>;
  /** notargebuehr */
  notaryFee?: Maybe<Scalars['Float']>;
  /** grunderwerbsteuer */
  realEstateTransferTax?: Maybe<Scalars['Float']>;
};

export type FaqSection = {
  __typename?: 'FaqSection';
  _id: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  expandedText: Scalars['String'];
  order: Scalars['Int'];
  title: Scalars['String'];
  type: SectionType;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Feasibility = {
  __typename?: 'Feasibility';
  /** code */
  code?: Maybe<Scalars['String']>;
  /** text */
  text?: Maybe<Scalars['String']>;
};

export type FeasibilityInput = {
  /** code */
  code?: Maybe<Scalars['String']>;
  /** text */
  text?: Maybe<Scalars['String']>;
};

export type FileAccessConfig = {
  __typename?: 'FileAccessConfig';
  _id: Scalars['String'];
  accessExpiresAt?: Maybe<Scalars['DateTime']>;
  accessLimit?: Maybe<FileAccessLimit>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  propertyId: Scalars['String'];
  releaseStatus?: Maybe<ReleaseStatus>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
  usersWithForbiddenAccess: Array<Scalars['String']>;
  usersWithGrantedAccess: Array<Scalars['String']>;
};

export type FileAccessConfigInput = {
  _id?: Maybe<Scalars['String']>;
  accessExpiresAt?: Maybe<Scalars['DateTime']>;
  accessLimit?: Maybe<FileAccessLimit>;
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  propertyId: Scalars['String'];
  releaseStatus?: Maybe<ReleaseStatus>;
  usersWithForbiddenAccess: Array<Scalars['String']>;
  usersWithGrantedAccess: Array<Scalars['String']>;
};

export enum FileAccessLimit {
  FourWeeks = 'FourWeeks',
  OneWeek = 'OneWeek',
  ThreeWeeks = 'ThreeWeeks',
  TwoMonths = 'TwoMonths',
  TwoWeeks = 'TwoWeeks',
  Unlimited = 'Unlimited',
}

export type FilterRangeInput = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type FilterUpperRangeInput = {
  max: Scalars['Int'];
};

export type FiltersInput = {
  buildingYear?: Maybe<FilterRangeInput>;
  coordinates?: Maybe<CoordinatesInput>;
  daysOnMarket?: Maybe<FilterRangeInput>;
  dealType: DealType;
  divisionLevel6?: Maybe<Scalars['String']>;
  divisionLevel8?: Maybe<Scalars['String']>;
  divisionLevel100?: Maybe<Scalars['String']>;
  endDate: DateRangeInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  livingArea?: Maybe<FilterRangeInput>;
  numberOfRooms?: Maybe<FilterRangeInput>;
  propertyType?: Maybe<PropertyCode>;
  radius?: Maybe<Scalars['Int']>;
  rentGross?: Maybe<PriceFilterRangeInput>;
  salePrice?: Maybe<PriceFilterRangeInput>;
};

export enum FinancialProposalsDataContextEnum {
  RealBusiness = 'REAL_BUSINESS',
  Test = 'TEST',
}

export type Financing = {
  __typename?: 'Financing';
  _id: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  financingData: FinancingData;
  financingVariants: Array<FinancingVariant>;
  marketingParameters?: Maybe<Scalars['String']>;
  onlineExposeId?: Maybe<Scalars['String']>;
  owner: User;
  searchProfileId?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
  vonPollOnOfficeData?: Maybe<VonPollOnOfficeData>;
  vonPollPropertyData?: Maybe<VonPollPropertyData>;
};

export type FinancingBuildingBlocks = {
  __typename?: 'FinancingBuildingBlocks';
  /** annuitaetendetails */
  annuityDetails?: Maybe<AnnuityDetails>;
  /** schlussrate */
  closingRate?: Maybe<Scalars['Float']>;
  /** bereitstellungszinsfreieZeitInMonaten */
  commitmentInterestFreePeriodInMonths?: Maybe<Scalars['Float']>;
  /** kostenUeberGesamtlaufzeit */
  costOverTotalTerm?: Maybe<Scalars['Float']>;
  /** datumLetzteRate */
  dateOfLastInstallment?: Maybe<Scalars['String']>;
  /** effektivZins */
  effectiveInterest?: Maybe<Scalars['Float']>;
  /** darlehensbetrag */
  loanAmount?: Maybe<Scalars['Float']>;
  /** anzahlRaten */
  numberOfInstallments?: Maybe<Scalars['Float']>;
  /** produktAnbieter */
  productProvider?: Maybe<Scalars['String']>;
  /** rateMonatlich */
  rateMonthly?: Maybe<Scalars['Float']>;
  /** tilgungssatzInProzent */
  repaymentRateInPercent?: Maybe<Scalars['Float']>;
  /** restschuldNachZinsbindungsEnde */
  residualDebtAfterTheEndOfTheFixedInterestPeriod?: Maybe<Scalars['Float']>;
  /** sollZins */
  shouldInterest?: Maybe<Scalars['Float']>;
  /** @type */
  type?: Maybe<Scalars['String']>;
};

export type FinancingBuildingBlocksInput = {
  /** annuitaetendetails */
  annuityDetails?: Maybe<AnnuityDetailsInput>;
  /** schlussrate */
  closingRate?: Maybe<Scalars['Float']>;
  /** bereitstellungszinsfreieZeitInMonaten */
  commitmentInterestFreePeriodInMonths?: Maybe<Scalars['Float']>;
  /** kostenUeberGesamtlaufzeit */
  costOverTotalTerm?: Maybe<Scalars['Float']>;
  /** datumLetzteRate */
  dateOfLastInstallment?: Maybe<Scalars['String']>;
  /** effektivZins */
  effectiveInterest?: Maybe<Scalars['Float']>;
  /** darlehensbetrag */
  loanAmount?: Maybe<Scalars['Float']>;
  /** anzahlRaten */
  numberOfInstallments?: Maybe<Scalars['Float']>;
  /** produktAnbieter */
  productProvider?: Maybe<Scalars['String']>;
  /** rateMonatlich */
  rateMonthly?: Maybe<Scalars['Float']>;
  /** tilgungssatzInProzent */
  repaymentRateInPercent?: Maybe<Scalars['Float']>;
  /** restschuldNachZinsbindungsEnde */
  residualDebtAfterTheEndOfTheFixedInterestPeriod?: Maybe<Scalars['Float']>;
  /** sollZins */
  shouldInterest?: Maybe<Scalars['Float']>;
  /** @type */
  type?: Maybe<Scalars['String']>;
};

export type FinancingCalculatorFilterInput = {
  title?: Maybe<Scalars['String']>;
};

export type FinancingCalculatorInput = {
  _id?: Maybe<Scalars['String']>;
  calculateCTALabel: Scalars['String'];
  capitalContributionLabel: Scalars['String'];
  expandedText: Scalars['String'];
  federalStateDropdownLabel: Scalars['String'];
  ghostButtonLabel: Scalars['String'];
  icon?: Maybe<Scalars['Upload']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconFilename?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  mainCTALabel: Scalars['String'];
  maximumLoanAmountLabel: Scalars['String'];
  maximumPurchasePriceLabel: Scalars['String'];
  monthlyNetIncomeLabel: Scalars['String'];
  title: Scalars['String'];
};

export type FinancingCalculators = {
  __typename?: 'FinancingCalculators';
  _id?: Maybe<Scalars['String']>;
  calculateCTALabel: Scalars['String'];
  capitalContributionLabel: Scalars['String'];
  expandedText: Scalars['String'];
  federalStateDropdownLabel: Scalars['String'];
  ghostButtonLabel: Scalars['String'];
  iconAlt?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  mainCTALabel: Scalars['String'];
  maximumLoanAmountLabel: Scalars['String'];
  maximumPurchasePriceLabel: Scalars['String'];
  monthlyNetIncomeLabel: Scalars['String'];
  title: Scalars['String'];
};

export type FinancingCalculatorsInput = {
  _id?: Maybe<Scalars['String']>;
  calculateCTALabel: Scalars['String'];
  capitalContributionLabel: Scalars['String'];
  expandedText: Scalars['String'];
  federalStateDropdownLabel: Scalars['String'];
  ghostButtonLabel: Scalars['String'];
  iconAlt?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  mainCTALabel: Scalars['String'];
  maximumLoanAmountLabel: Scalars['String'];
  maximumPurchasePriceLabel: Scalars['String'];
  monthlyNetIncomeLabel: Scalars['String'];
  title: Scalars['String'];
};

export type FinancingCertificateFilterInput = {
  title?: Maybe<Scalars['String']>;
};

export type FinancingCertificateInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  mainCTALabel?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type FinancingCertificates = {
  __typename?: 'FinancingCertificates';
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  expandedText?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  mainCTALabel?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancingCertificatesInput = {
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  expandedText?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  mainCTALabel?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancingData = {
  __typename?: 'FinancingData';
  countryCode: Scalars['String'];
  financingType: FinancingType;
  title: Scalars['String'];
};

export type FinancingDataInput = {
  countryCode: Scalars['String'];
  financingType: FinancingType;
  title: Scalars['String'];
};

export type FinancingLpSection = {
  __typename?: 'FinancingLPSection';
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: FinancingLpSectionType;
};

export enum FinancingLpSectionType {
  Appointment = 'APPOINTMENT',
  CustomerAppraisal = 'CUSTOMER_APPRAISAL',
  Faq = 'FAQ',
  FinancingCalculator = 'FINANCING_CALCULATOR',
  FinancingCertificate = 'FINANCING_CERTIFICATE',
  FinancingPartner = 'FINANCING_PARTNER',
  InterestRatesTable = 'INTEREST_RATES_TABLE',
  OurRepresentativeExample = 'OUR_REPRESENTATIVE_EXAMPLE',
  StepByStepBenefit = 'STEP_BY_STEP_BENEFIT',
}

export type FinancingLandingPage = {
  __typename?: 'FinancingLandingPage';
  _id?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Array<Scalars['String']>>;
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sections?: Maybe<Array<FinancingLpSection>>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancingLandingPageFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type FinancingLandingPageInput = {
  _id?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Array<Scalars['String']>>;
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sections?: Maybe<Array<FinancingLpSectionInput>>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FinancingLpSectionInput = {
  _id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: FinancingLpSectionType;
};

export type FinancingOverviewFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type FinancingOverviewInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  fundingInquiriesLabel?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconFilename?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  textTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FinancingOverviews = {
  __typename?: 'FinancingOverviews';
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  fundingInquiriesLabel?: Maybe<Scalars['String']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  textTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FinancingOverviewsInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  fundingInquiriesLabel?: Maybe<Scalars['String']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  textTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FinancingPartnerFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type FinancingPartnerInput = {
  _id?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  fileAlt?: Maybe<Scalars['String']>;
  fileKey?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type FinancingPartners = {
  __typename?: 'FinancingPartners';
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  fileAlt?: Maybe<Scalars['String']>;
  fileKey?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancingPartnersInput = {
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  fileAlt?: Maybe<Scalars['String']>;
  fileKey?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum FinancingPropertyType {
  Condominium = 'CONDOMINIUM',
  HouseDetached = 'HOUSE_DETACHED',
  HouseRowCorner = 'HOUSE_ROW_CORNER',
  HouseRowMiddle = 'HOUSE_ROW_MIDDLE',
  HouseSemiDetached = 'HOUSE_SEMI_DETACHED',
  MultiFamilyHouse = 'MULTI_FAMILY_HOUSE',
  PlotOnly = 'PLOT_ONLY',
  TwoFamilyHouse = 'TWO_FAMILY_HOUSE',
}

export type FinancingProposition = {
  __typename?: 'FinancingProposition';
  debitRateCommitment: Scalars['String'];
  /** nebenkosten */
  extraCosts?: Maybe<ExtraCosts>;
  leadRating?: Maybe<LeadRating>;
  /** Calculated maximum purchase price in euros */
  maximalPurchasePrice?: Maybe<Scalars['Float']>;
  propositions: Array<Propositions>;
};

export type FinancingPropositionInput = {
  debitRateCommitment: Scalars['String'];
  /** nebenkosten */
  extraCosts?: Maybe<ExtraCostsInput>;
  leadRating?: Maybe<LeadRatingInput>;
  /** Calculated maximum purchase price in euros */
  maximalPurchasePrice?: Maybe<Scalars['Float']>;
  propositions: Array<PropositionsInput>;
};

export enum FinancingReason {
  FollowUpFinancing = 'FOLLOW_UP_FINANCING',
  Modernization = 'MODERNIZATION',
  OwnConstructionProject = 'OWN_CONSTRUCTION_PROJECT',
  PurchaseOfExistingProperty = 'PURCHASE_OF_EXISTING_PROPERTY',
  PurchaseOfNewBuilding = 'PURCHASE_OF_NEW_BUILDING',
  RaisingCapital = 'RAISING_CAPITAL',
}

export type FinancingSuggestionsEntity = {
  __typename?: 'FinancingSuggestionsEntity';
  isCertificateSuggestion: Scalars['Boolean'];
  isRequestedFinancing: Scalars['Boolean'];
};

export type FinancingSuggestionsInput = {
  isCertificateSuggestion: Scalars['Boolean'];
  isRequestedFinancing: Scalars['Boolean'];
};

export enum FinancingType {
  Financing = 'FINANCING',
  FinancingCertificate = 'FINANCING_CERTIFICATE',
}

export type FinancingVariant = {
  __typename?: 'FinancingVariant';
  financingPropositionEmail?: Maybe<FinancingProposition>;
  financingPropositionMaxPurchasePrice?: Maybe<FinancingProposition>;
  financingPropositions: Array<FinancingProposition>;
  financingSuggestions: FinancingSuggestionsEntity;
  financingVariantData: FinancingVariantData;
  lastDateOfInterestRatesNotification?: Maybe<Scalars['DateTime']>;
  personalFinancing: PersonalFinancingEntity;
  propositionsLastSync: Scalars['DateTime'];
};

export type FinancingVariantData = {
  __typename?: 'FinancingVariantData';
  /** Maklergebühr Prozent */
  brokerFeePercent?: Maybe<Scalars['Float']>;
  brokerageFee?: Maybe<Scalars['Boolean']>;
  buildingCosts?: Maybe<Scalars['Float']>;
  /** Baujahr */
  buildingYear?: Maybe<Scalars['Int']>;
  /** Sollzinsbindung */
  debitRateCommitment: Scalars['String'];
  /** Darlehensbetrag */
  desiredLoanAmount?: Maybe<Scalars['Float']>;
  /** Wunschrate */
  desiredRate: Scalars['Float'];
  /** Eigenkapital */
  equityCapital?: Maybe<Scalars['Float']>;
  estimatedRemainingDebt?: Maybe<Scalars['Float']>;
  /** Bundesland */
  federalState?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  landPrice?: Maybe<Scalars['Float']>;
  /** Grundbucheintrag */
  landRegisterEntry: Scalars['Float'];
  /** Grundsteuer */
  landTax?: Maybe<Scalars['Float']>;
  /** Wohnfläche */
  livingArea?: Maybe<Scalars['Float']>;
  location: LocationFinancing;
  monthlyRate?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  /** Notar */
  notary: Scalars['Float'];
  payoutDate?: Maybe<Scalars['DateTime']>;
  propertyType: FinancingPropertyType;
  propertyUse: PropertyUse;
  propertyValue?: Maybe<Scalars['Float']>;
  /** Kaufpreis */
  purchasePrice?: Maybe<Scalars['Float']>;
  /** Properties radius */
  radius?: Maybe<Scalars['Float']>;
  /** Tilgungssatz | Wunschrate */
  rateType: RateType;
  reason: FinancingReason;
  /** Mieteinnahmen pro Monat */
  rentalIncomePerMonth?: Maybe<Scalars['Float']>;
  /** Tilgungssatz */
  repaymentRate?: Maybe<Scalars['Float']>;
  searchProgress?: Maybe<SearchProgress>;
};

export type FinancingVariantDataInput = {
  brokerFeePercent?: Maybe<Scalars['Float']>;
  brokerageFee?: Maybe<Scalars['Boolean']>;
  buildingCosts?: Maybe<Scalars['Float']>;
  buildingYear?: Maybe<Scalars['Float']>;
  debitRateCommitment: Scalars['String'];
  desiredLoanAmount?: Maybe<Scalars['Float']>;
  desiredRate: Scalars['Float'];
  equityCapital?: Maybe<Scalars['Float']>;
  estimatedRemainingDebt?: Maybe<Scalars['Float']>;
  federalState?: Maybe<Scalars['String']>;
  index: Scalars['Float'];
  landPrice?: Maybe<Scalars['Float']>;
  landRegisterEntry: Scalars['Float'];
  landTax?: Maybe<Scalars['Float']>;
  livingArea?: Maybe<Scalars['Float']>;
  location: LocationFinancingInput;
  monthlyRate?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  notary: Scalars['Float'];
  payoutDate?: Maybe<Scalars['DateTime']>;
  propertyType: FinancingPropertyType;
  propertyUse: PropertyUse;
  propertyValue?: Maybe<Scalars['Float']>;
  purchasePrice?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
  rateType: RateType;
  reason: FinancingReason;
  rentalIncomePerMonth?: Maybe<Scalars['Float']>;
  repaymentRate?: Maybe<Scalars['Float']>;
  searchProgress?: Maybe<SearchProgress>;
};

export type FinancingVariantInput = {
  financingSuggestions: FinancingSuggestionsInput;
  financingVariantData: FinancingVariantDataInput;
  lastDateOfInterestRatesNotification?: Maybe<Scalars['DateTime']>;
  personalFinancing: PersonalFinancingInput;
  propositionsLastSync: Scalars['DateTime'];
};

export type ForgotPasswordInput = {
  username: Scalars['String'];
};

export type GeneralAppointment = {
  __typename?: 'GeneralAppointment';
  _id?: Maybe<Scalars['String']>;
  buttonLabel: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  generalText: Scalars['String'];
  label: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GeneralAppointmentFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type GeneralAppointmentInput = {
  _id?: Maybe<Scalars['String']>;
  buttonLabel: Scalars['String'];
  country: Scalars['String'];
  generalText: Scalars['String'];
  label: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type GeneralMarketReport = {
  __typename?: 'GeneralMarketReport';
  _id: Scalars['String'];
  awsFolderKey: Scalars['String'];
  country: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  fileKey: Scalars['String'];
  fileUrl: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GeoJsonInput = {
  coordinates: Array<Scalars['Float']>;
  type: Scalars['String'];
};

export type GeoJsonSearchProfile = {
  __typename?: 'GeoJsonSearchProfile';
  coordinates: Array<Scalars['Float']>;
  type: Scalars['String'];
};

export type GeoJsonSearchProfileInput = {
  coordinates: Array<Scalars['Float']>;
  type: Scalars['String'];
};

export type Geometry = {
  __typename?: 'Geometry';
  coordinates: Array<Array<Array<Scalars['Float']>>>;
  type: Scalars['String'];
};

export type GetFinancingPropositionsInput = {
  birthday?: Maybe<Scalars['String']>;
  brokerFeePercent?: Maybe<Scalars['Float']>;
  brokerageFee?: Maybe<Scalars['Boolean']>;
  buildingCosts?: Maybe<Scalars['Int']>;
  buildingYear?: Maybe<Scalars['Int']>;
  dataContext?: Maybe<FinancialProposalsDataContextEnum>;
  dataType?: Maybe<EuropaceDataType>;
  debitRateCommitment?: Maybe<Scalars['String']>;
  desiredLoanAmount?: Maybe<Scalars['Int']>;
  desiredRate?: Maybe<Scalars['Int']>;
  equityCapital?: Maybe<Scalars['Int']>;
  estimatedRemainingDebt?: Maybe<Scalars['Int']>;
  federalState?: Maybe<Scalars['String']>;
  financingType?: Maybe<FinancingType>;
  householdNetIncome?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Int']>;
  isMaximumPurchasePrice?: Maybe<Scalars['Boolean']>;
  landPrice?: Maybe<Scalars['Int']>;
  landRegisterEntry?: Maybe<Scalars['Float']>;
  landTax?: Maybe<Scalars['Float']>;
  livingArea?: Maybe<Scalars['Float']>;
  loanAmount?: Maybe<Scalars['Float']>;
  location: LocationFinancingInput;
  mainEarner?: Maybe<MainEarner>;
  mode?: Maybe<Scalars['String']>;
  monthlyRate?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  notary?: Maybe<Scalars['Float']>;
  numberOfPropositions?: Maybe<Scalars['Int']>;
  payoutDate?: Maybe<Scalars['DateTime']>;
  propertyType?: Maybe<FinancingPropertyType>;
  propertyUse?: Maybe<PropertyUse>;
  propertyValue?: Maybe<Scalars['Int']>;
  purchasePrice?: Maybe<Scalars['Int']>;
  radius?: Maybe<Scalars['Int']>;
  rateType?: Maybe<RateType>;
  reason: FinancingReason;
  rentalIncomePerMonth?: Maybe<Scalars['Float']>;
  repaymentRate?: Maybe<Scalars['Float']>;
  searchProgress?: Maybe<SearchProgress>;
};

export type GetFinancingVonPollPropertyDetails = {
  __typename?: 'GetFinancingVonPollPropertyDetails';
  buildingYear?: Maybe<Scalars['String']>;
  equipment: VonPollPropertyEquipment;
  images: Array<VonPollPropertyImage>;
  livingArea?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  propertyType?: Maybe<Scalars['String']>;
  purchasePrice?: Maybe<Scalars['Float']>;
};

export enum GroupByField {
  DaysOnMarket = 'days_on_market',
  EndDate = 'end_date',
  LivingArea = 'living_area',
  NumberOfRooms = 'number_of_rooms',
  RentGross = 'rent_gross',
  RentGrossPerSquareMeter = 'rent_gross_per_square_meter',
  RentNet = 'rent_net',
  RentNetPerSquareMeter = 'rent_net_per_square_meter',
  SalePrice = 'sale_price',
  SalePricePerSquareMeter = 'sale_price_per_square_meter',
  StartDate = 'start_date',
}

export type GroupByInput = {
  field?: Maybe<GroupByField>;
  groups?: Maybe<Array<GroupInput>>;
  period?: Maybe<Period>;
};

export type GroupInput = {
  lowerBound?: Maybe<Scalars['Int']>;
  upperBound?: Maybe<Scalars['Int']>;
};

export type GuestUser = {
  __typename?: 'GuestUser';
  _id: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  phoneNumber: Scalars['String'];
  timesCodeSent: Scalars['Int'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
  verificationCode?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};

export type Health = {
  __typename?: 'Health';
  clinic?: Maybe<Array<Poi>>;
  dentist?: Maybe<Array<Poi>>;
  doctor?: Maybe<Array<Poi>>;
  hospital?: Maybe<Array<Poi>>;
  nursing_home?: Maybe<Array<Poi>>;
  pharmacy?: Maybe<Array<Poi>>;
  veterinary?: Maybe<Array<Poi>>;
};

export type ImageEntity = {
  __typename?: 'ImageEntity';
  url: Scalars['String'];
};

export type InitiateRealSellInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  message: Scalars['String'];
  phone: Scalars['String'];
  preferredTime: PreferredAppointmentTime;
  propertyId: Scalars['String'];
};

export type InterestRatesTableFilterInput = {
  title?: Maybe<Scalars['String']>;
};

export type InterestRatesTableInput = {
  _id?: Maybe<Scalars['String']>;
  ghostButtonLabel: Scalars['String'];
  mainCTALabel: Scalars['String'];
  row1Label: Scalars['String'];
  row2Label: Scalars['String'];
  row3Label: Scalars['String'];
  subtitle: Scalars['String'];
  tableHintText: Scalars['String'];
  timestampLabel: Scalars['String'];
  title: Scalars['String'];
};

export type InterestRatesTables = {
  __typename?: 'InterestRatesTables';
  _id?: Maybe<Scalars['String']>;
  ghostButtonLabel: Scalars['String'];
  mainCTALabel: Scalars['String'];
  row1Label: Scalars['String'];
  row2Label: Scalars['String'];
  row3Label: Scalars['String'];
  subtitle: Scalars['String'];
  tableHintText: Scalars['String'];
  timestampLabel: Scalars['String'];
  title: Scalars['String'];
};

export type InterestRatesTablesInput = {
  _id?: Maybe<Scalars['String']>;
  ghostButtonLabel: Scalars['String'];
  mainCTALabel: Scalars['String'];
  row1Label: Scalars['String'];
  row2Label: Scalars['String'];
  row3Label: Scalars['String'];
  subtitle: Scalars['String'];
  tableHintText: Scalars['String'];
  timestampLabel: Scalars['String'];
  title: Scalars['String'];
};

export enum InvitationPrivileges {
  Editor = 'EDITOR',
  Spectator = 'SPECTATOR',
}

export type Invite = {
  __typename?: 'Invite';
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  invitationPrivileges: InvitationPrivileges;
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  status?: Maybe<InviteStatus>;
  surname: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InviteInput = {
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  invitationPrivileges: InvitationPrivileges;
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  status?: Maybe<InviteStatus>;
  surname: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum InviteStatus {
  Accepted = 'ACCEPTED',
  Denied = 'DENIED',
  Pending = 'PENDING',
}

export type Isochrone = {
  __typename?: 'Isochrone';
  geometry: Geometry;
};

export type Isochrones = {
  __typename?: 'Isochrones';
  densities?: Maybe<Densities>;
  isochrone: Isochrone;
};

export type IsochronesInput = {
  coordinates: CoordinatesInput;
  countryCode: Scalars['String'];
  cutoff: Scalars['Int'];
  mode: IsochronesMode;
  returnDensities?: Maybe<Scalars['Boolean']>;
};

export enum IsochronesMode {
  Bicycle = 'BICYCLE',
  Car = 'CAR',
  PublicTransport = 'PUBLIC_TRANSPORT',
  Walk = 'WALK',
}

export type Item = {
  __typename?: 'Item';
  groupId?: Maybe<Scalars['String']>;
  groupLowerBound?: Maybe<Scalars['Int']>;
  groupUpperBound?: Maybe<Scalars['Int']>;
  statistics?: Maybe<Array<Statistic>>;
};

export type JwtToken = {
  __typename?: 'JwtToken';
  exp: Scalars['Int'];
  iat: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type LatestSuggestedProperty = {
  __typename?: 'LatestSuggestedProperty';
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
};

export type LatestSuggestedPropertyInput = {
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
};

export type LatestSuggestedSp = {
  __typename?: 'LatestSuggestedSP';
  lastSync?: Maybe<Scalars['DateTime']>;
  suggestedSP?: Maybe<Array<Scalars['String']>>;
};

export type LatestSuggestedSpInput = {
  lastSync?: Maybe<Scalars['DateTime']>;
  suggestedSP?: Maybe<Array<Scalars['String']>>;
};

export type LeadRating = {
  __typename?: 'LeadRating';
  effortRating?: Maybe<EffortRating>;
  /** Displays the feasibility value */
  feasibilityRating?: Maybe<Scalars['Float']>;
  /** Rating indicates the probability of a transaction being completed. Categories A, B, C and D indicate the probability that a transaction will be successfully completed. A is the best, i.e. most promising, category. */
  successRating?: Maybe<Scalars['String']>;
};

export type LeadRatingInput = {
  effortRating?: Maybe<EffortRatingInput>;
  /** Displays the feasibility value */
  feasibilityRating?: Maybe<Scalars['Float']>;
  /** Rating indicates the probability of a transaction being completed. Categories A, B, C and D indicate the probability that a transaction will be successfully completed. A is the best, i.e. most promising, category. */
  successRating?: Maybe<Scalars['String']>;
};

export type Leisure = {
  __typename?: 'Leisure';
  entertainment?: Maybe<Array<Poi>>;
  park?: Maybe<Array<Poi>>;
  playground?: Maybe<Array<Poi>>;
  sport?: Maybe<Array<Poi>>;
};

export type LikedProperty = {
  __typename?: 'LikedProperty';
  id: Scalars['String'];
  price: Scalars['Float'];
};

export type LocalMarket = {
  __typename?: 'LocalMarket';
  _id?: Maybe<Scalars['String']>;
  buttonLabel?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LocalMarketFilterInput = {
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type LocalMarketInput = {
  _id?: Maybe<Scalars['String']>;
  buttonLabel?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LocalMarketsInput = {
  _id?: Maybe<Scalars['String']>;
  buttonLabel?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Locale = {
  __typename?: 'Locale';
  _id: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LocationFilter = {
  circle?: Maybe<CircleInput>;
  divisionLevel6?: Maybe<Scalars['String']>;
  divisionLevel8?: Maybe<Scalars['String']>;
  divisionLevel100?: Maybe<Scalars['String']>;
};

export type LocationFinancing = {
  __typename?: 'LocationFinancing';
  address: AddressFinancing;
  coordinates: Coordinates;
};

export type LocationFinancingInput = {
  address: AddressFinancingInput;
  coordinates: CoordinatesInput;
};

export type LocationInput = {
  address: AddressInput;
  coordinates: CoordinatesInput;
  geoJson?: Maybe<GeoJsonInput>;
};

export type LocationScores = {
  __typename?: 'LocationScores';
  catering: Scalars['Float'];
  family: Scalars['Float'];
  health: Scalars['Float'];
  leisure: Scalars['Float'];
  noise?: Maybe<Scalars['Float']>;
  shopping: Scalars['Float'];
};

export type LocationSearchProfile = {
  __typename?: 'LocationSearchProfile';
  address: AddressSearchProfileEntity;
  coordinates: Coordinates;
  geoJson?: Maybe<GeoJsonSearchProfile>;
};

export type LocationSearchProfileInput = {
  address: AddressSearchProfileInput;
  coordinates: CoordinatesInput;
  geoJson?: Maybe<GeoJsonSearchProfileInput>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  jwt?: Maybe<JwtToken>;
  user: User;
};

export type LoginUnion = AuthChallenge | LoginResponse;

export enum MainEarner {
  Employee = 'EMPLOYEE',
  Freelancer = 'FREELANCER',
  HouseWife = 'HOUSE_WIFE',
  Official = 'OFFICIAL',
  Pensioner = 'PENSIONER',
  SelfEmployed = 'SELF_EMPLOYED',
  Unemployed = 'UNEMPLOYED',
  Worker = 'WORKER',
}

export type MarketReport = {
  __typename?: 'MarketReport';
  _id: Scalars['String'];
  awsFolderKey: Scalars['String'];
  cities: Array<Scalars['String']>;
  country: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  fileKey: Scalars['String'];
  fileUrl: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MarketReportInput = {
  _id?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type MarketReportsFilterInput = {
  city?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type MarketingGeneralStatistics = {
  __typename?: 'MarketingGeneralStatistics';
  advertisementViews?: Maybe<Scalars['Int']>;
  bids?: Maybe<Scalars['Int']>;
  bookmarks?: Maybe<Scalars['Int']>;
  highestBid?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Int']>;
  requestedExposes?: Maybe<Scalars['Int']>;
  unlockedExposes?: Maybe<Scalars['Int']>;
  viewingAppointments?: Maybe<Scalars['Int']>;
};

export type MarketingGeneralStatisticsInput = {
  advertisementViews?: Maybe<Scalars['Int']>;
  bids?: Maybe<Scalars['Int']>;
  bookmarks?: Maybe<Scalars['Int']>;
  highestBid?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Int']>;
  requestedExposes?: Maybe<Scalars['Int']>;
  unlockedExposes?: Maybe<Scalars['Int']>;
  viewingAppointments?: Maybe<Scalars['Int']>;
};

export type MarketingStatistics = {
  __typename?: 'MarketingStatistics';
  generalStatistics?: Maybe<MarketingGeneralStatistics>;
  statisticsLastSync?: Maybe<Scalars['DateTime']>;
  statisticsPerWeek?: Maybe<Array<MarketingStatisticsPerWeek>>;
};

export type MarketingStatisticsInput = {
  generalStatistics?: Maybe<MarketingGeneralStatisticsInput>;
  statisticsLastSync?: Maybe<Scalars['DateTime']>;
  statisticsPerWeek?: Maybe<Array<MarketingStatisticsPerWeekInput>>;
};

export type MarketingStatisticsPerWeek = {
  __typename?: 'MarketingStatisticsPerWeek';
  bids?: Maybe<Array<Bid>>;
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  statistics?: Maybe<MarketingGeneralStatistics>;
  weekNumber: Scalars['Int'];
};

export type MarketingStatisticsPerWeekInput = {
  bids?: Maybe<Array<BidInput>>;
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  statistics?: Maybe<MarketingGeneralStatisticsInput>;
  weekNumber: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation: Scalars['String'];
  acceptPurchaseOffer?: Maybe<Scalars['String']>;
  approvedExposeRequests: Array<Task>;
  /** Regular user password change */
  changePassword: Scalars['Boolean'];
  /** Regular users email confirm */
  confirmEmail: User;
  /** Regular user confirm forgotten password change */
  confirmForgotPassword: Scalars['Boolean'];
  confirmGuestUser: GuestUser;
  /** Confirm regular user phone */
  confirmPhone: UserState;
  /** Confirm phone number change */
  confirmPhoneChange: User;
  /** Convert temporary property to permanent */
  convertProperty: Property;
  /** Convert temporary search profile to permanent */
  convertSearchProfile: Property;
  /** Create admin user. *Requires admin access */
  createAdmin: Scalars['Boolean'];
  /** Create Appointment section. *Requires admin access */
  createAppointmentSection: AppointmentSection;
  createBrokerShop: BrokerShop;
  /** Create cms block. *Requires admin access */
  createCmsBlock: CmsBlock;
  /** Create new cms page. *Requires admin access */
  createCmsPage: CmsPage;
  /** Create new cms regional page. *Requires admin access */
  createCmsRegionalPage: CmsRegionalPage;
  /** Create Content section. *Requires admin access */
  createContentSection: ContentSection;
  createCountry: Country;
  createDownloadedFileActivity: Scalars['String'];
  /** Create experiment (A/B Test). *Requires admin access */
  createExperiment: Experiment;
  /** Create expose request */
  createExposeRequest: Scalars['Boolean'];
  /** Create FAQ section. *Requires admin access */
  createFaqSection: FaqSection;
  /** Simply create financing */
  createFinancing: Financing;
  /** Create General Market Report. *Requires admin access */
  createGeneralMarketReport: GeneralMarketReport;
  /** Create market report. *Requires admin access */
  createMarketReport: MarketReport;
  createOverviewLPSection: OverviewLpSection;
  /** Create Owner settings section. *Requires admin access */
  createOwnerSettingsSection: OwnerSettingsSection;
  /** Simply create property or create property with initial valuations */
  createProperty: Property;
  /** Create dossier for existing property */
  createPropertyDossier?: Maybe<Property>;
  /** Use this mutation to create Purchase Intent */
  createPurchaseIntent: PurchaseIntent;
  /** Simply create property or create property with initial valuations */
  createSearchProfile: SearchProfile;
  createTemporaryProperty: TemporaryProperty;
  createTemporarySearchProfile: TemporarySearchProfile;
  /** Create Video section. *Requires admin access */
  createVideoSection: VideoSection;
  createVirtualTour: VirtualTourSection;
  /** Delete admin user. *Requires admin access */
  deleteAdmin: Scalars['Boolean'];
  deleteAdvantage: Scalars['Boolean'];
  deleteApp: Scalars['Boolean'];
  deleteAppointmentContactForm: Scalars['Boolean'];
  deleteBanner: Scalars['Boolean'];
  deleteBenefit: Scalars['Boolean'];
  deleteBuyerBenefit: Scalars['Boolean'];
  deleteBuyerFinderSettings: Scalars['Boolean'];
  deleteBuyerFinderTeaser: Scalars['Boolean'];
  deleteBuyerFunction: Scalars['Boolean'];
  deleteBuyerLandingPage: Scalars['Boolean'];
  deleteBuyerOverview: Scalars['Boolean'];
  deleteCentralPhone: Scalars['Boolean'];
  deleteCertificate: Scalars['Boolean'];
  /** Delete cms page. *Requires admin access */
  deleteCmsPage: Scalars['Boolean'];
  /** Delete cms page. *Requires admin access */
  deleteCmsRegionalPage: Scalars['Boolean'];
  deleteCustomerAppraisal: Scalars['Boolean'];
  deleteEvaluationCategory: Scalars['Boolean'];
  deleteFinancingCalculator: Scalars['Boolean'];
  deleteFinancingCertificate: Scalars['Boolean'];
  deleteFinancingLandingPage: Scalars['Boolean'];
  deleteFinancingOverview: Scalars['Boolean'];
  deleteFinancingPartner: Scalars['Boolean'];
  deleteGeneralAppointment: Scalars['Boolean'];
  deleteInterestRatesTable: Scalars['Boolean'];
  deleteInvitation: Invite;
  deleteLocalMarket: Scalars['Boolean'];
  deleteOurRepresentativeExample: Scalars['Boolean'];
  deleteOverviewApp: Scalars['Boolean'];
  deleteOverviewLandingPage: Scalars['Boolean'];
  deleteOwnerBenefit: Scalars['Boolean'];
  deleteOwnerFunction: Scalars['Boolean'];
  deleteOwnerLandingPage: Scalars['Boolean'];
  deleteOwnerOverview: Scalars['Boolean'];
  deletePriceAlteration: Scalars['Boolean'];
  deletePropertyPurchaseIntent: Scalars['Boolean'];
  deleteSellerBenefit: Scalars['Boolean'];
  deleteSellerFunction: Scalars['Boolean'];
  deleteSellerLandingPage: Scalars['Boolean'];
  deleteSellerOverview: Scalars['Boolean'];
  deleteStepByStepBenefit: Scalars['Boolean'];
  deleteTestimonial: Scalars['Boolean'];
  /** Delete user. *Requires admin access */
  deleteUser?: Maybe<User>;
  dislikeProperty: SearchProfile;
  editInvitation: Invite;
  /** Expand financing radius */
  expandFinancingRadius: Scalars['Boolean'];
  /** Simply update property portfolio without valuations */
  exportPdf: Scalars['Boolean'];
  /** Regular user password change initiate */
  forgotPassword: Scalars['Boolean'];
  generatePdfExposeLink: Scalars['String'];
  /** Get financing propositions */
  getFinancingPropositions: Array<FinancingProposition>;
  getPdfLink: Scalars['String'];
  getSellerExposePdfLink: Scalars['String'];
  initiateRealSell?: Maybe<Scalars['Boolean']>;
  /** Invite Seller from preview */
  inviteSeller?: Maybe<Scalars['String']>;
  likeProperty: SearchProfile;
  markPropertyUnsuitable: SearchProfile;
  notifyBrokerForBuyerFinder: Scalars['Boolean'];
  overviewLandingPageContact: Scalars['Boolean'];
  /** Delete cms block. *Requires admin access */
  removeCmsBlock: CmsBlock;
  /** Delete cms section. *Requires admin access */
  removeCmsSection: CmsSection;
  removeCountry: Country;
  /** Delete experiment (A/B Test). *Requires admin access */
  removeExperiment: Experiment;
  removeFileAccessConfig: FileAccessConfig;
  /** Delete financing by _id. */
  removeFinancing?: Maybe<Financing>;
  removeGeneralMarketReport: GeneralMarketReport;
  removeMarketReport: MarketReport;
  removeOurDestinations: Scalars['Boolean'];
  removeOverviewLPSection: OverviewLpSection;
  /** Delete property by _id. */
  removeProperty?: Maybe<Property>;
  /** Delete search profile by _id. */
  removeSearchProfile?: Maybe<SearchProfile>;
  /** Remove user account */
  removeUserAccount: Scalars['Boolean'];
  /** Request a attribute verification code. (Usually for email) */
  requestVerificationCode: Scalars['Boolean'];
  /** Re-send confirmation code */
  resendConfirmationCode: Scalars['Boolean'];
  resendGuestUserCode: Scalars['Boolean'];
  /** Resend phone code after changing phone number */
  resendPhoneCodeAfterChange: Scalars['Boolean'];
  /** Respond to AWS authentication challenge. (Force change password, etc...) */
  respondToAuthChallenge: LoginUnion;
  /** Use this mutation to create or update an advantage section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveAdvantage: Advantages;
  /** Use this mutation to create or update an app section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveApp: Apps;
  /** Use this mutation to create or update a appointment contact forms section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveAppointmentContactForm: AppointmentContactForms;
  /** Use this mutation to create or update a banner section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveBanner: Banner;
  /** Use this mutation to create or update a benefits section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveBenefit: Benefits;
  /** Save Benefit section. *Requires admin access */
  saveBenefitSection: BenefitSection;
  /** Use this mutation to create or update a buyer benefits section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveBuyerBenefit: BuyerBenefits;
  /** Use this mutation to create or update a buyer finder settings section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveBuyerFinderSettings: BuyerFinderSettings;
  /** Use this mutation to create or update a Buyer Finder Teasers section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveBuyerFinderTeaser: BuyerFinderTeasers;
  /** Use this mutation to create or update a buyer functions section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveBuyerFunction: BuyerFunctions;
  /** Use this mutation to create or update a buyer landing page section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveBuyerLandingPage: BuyerLandingPage;
  /** Use this mutation to create or update a buyer overviews section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveBuyerOverview: BuyerOverviews;
  /** Use this mutation to create or update an CentralPhone section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveCentralPhone: CentralPhone;
  /** Use this mutation to create or update a certificate section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveCertificate: Certificates;
  /** Use this mutation to create or update a customer appraisals section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveCustomerAppraisal: CustomerAppraisals;
  /** Use this mutation to create or update a evaluationCategories section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveEvaluationCategory: EvaluationCategory;
  saveFileAccessConfig: Scalars['String'];
  /** Use this mutation to create or update a financing calculators section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveFinancingCalculator: FinancingCalculators;
  /** Use this mutation to create or update a financing certificates section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveFinancingCertificate: FinancingCertificates;
  /** Use this mutation to create or update a financing landing page section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveFinancingLandingPage: FinancingLandingPage;
  /** Use this mutation to create or update a financing overviews section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveFinancingOverview: FinancingOverviews;
  /** Use this mutation to create or update a financing partners section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveFinancingPartner: FinancingPartners;
  /** Use this mutation to create or update a General Appointment. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveGeneralAppointment: GeneralAppointment;
  /** Use this mutation to create or update Interest rates tables section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveInterestRatesTable: InterestRatesTables;
  /** Use this mutation to create or update a local market section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveLocalMarket: LocalMarket;
  saveOurDestinations: OurDestinations;
  /** Use this mutation to create or update Our Representative Example section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveOurRepresentativeExample: OurRepresentativeExamples;
  /** Use this mutation to create or update a overview apps section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveOverviewApp: OverviewApps;
  /** Use this mutation to create or update a overview landing page section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveOverviewLandingPage: OverviewLandingPage;
  /** Use this mutation to create or update a owner benefits section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveOwnerBenefit: OwnerBenefits;
  /** Use this mutation to create or update a owner functions section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveOwnerFunction: OwnerFunctions;
  /** Use this mutation to create or update a owner landing page section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveOwnerLandingPage: OwnerLandingPage;
  /** Use this mutation to create or update a owner overviews section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveOwnerOverview: OwnerOverviews;
  /** Use this mutation to create or update a priceAlterations section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  savePriceAlteration: PriceAlterationEntity;
  /** Use this mutation to create or update a seller benefits section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveSellerBenefit: SellerBenefits;
  /** Use this mutation to create or update a seller functions section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveSellerFunction: SellerFunctions;
  /** Use this mutation to create or update a seller landing page section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveSellerLandingPage: SellerLandingPage;
  /** Use this mutation to create or update a seller overviews section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveSellerOverview: SellerOverviews;
  /** Use this mutation to create or update a step by step benefits section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveStepByStepBenefit: StepByStepBenefits;
  /** Use this mutation to create or update a testimonials section. If you send "_id" in the payload then the API will try to update the existing record. If the "_id" is missing then we will create new record. */
  saveTestimonial: Testimonials;
  sendExposeContactForm: Scalars['String'];
  sendFeedback: Scalars['String'];
  sendFeedbackOfOwnerPortal: Scalars['String'];
  /** Send selected services to onOffice */
  sendPropertyServices?: Maybe<Scalars['String']>;
  sendSellerRegisteredNotification?: Maybe<Scalars['String']>;
  /** Send von poll finance email */
  sendVonPollFinance: Scalars['Boolean'];
  setDisabledShopServices: Scalars['Boolean'];
  /** Get property expose status */
  setPropertyExposeStatus?: Maybe<PropertyExpose>;
  /** Set property owner id */
  setPropertyOwnerId?: Maybe<Scalars['String']>;
  /** Set property portal status */
  setPropertyPortalStatus?: Maybe<Scalars['String']>;
  setUserIdForExposes: Scalars['String'];
  /** Set user state */
  setUserState: User;
  shareProperty: Scalars['Boolean'];
  /** Regular users sign in */
  signIn: LoginUnion;
  /** Admin users sign in */
  signInAdmin: LoginUnion;
  /** Regular users sign up */
  signUp: User;
  /** Tie appointment to Property */
  tieAppointment: Property;
  unsubscribeFromNotifications: Scalars['String'];
  /** Update admin user. *Requires admin access */
  updateAdmin: Scalars['Boolean'];
  /** Update Appointment section. *Requires admin access */
  updateAppointmentSection?: Maybe<AppointmentSection>;
  /** Update cms block. *Requires admin access */
  updateCmsBlock: CmsBlock;
  /** Update cms page. *Requires admin access */
  updateCmsPage: CmsPage;
  /** Update cms regional page. *Requires admin access */
  updateCmsRegionalPage?: Maybe<CmsRegionalPage>;
  /** Update Content section. *Requires admin access */
  updateContentSection?: Maybe<ContentSection>;
  updateCountry: Country;
  /** Update experiment (A/B Test). *Requires admin access */
  updateExperiment: Experiment;
  /** Update FAQ section. *Requires admin access */
  updateFaqSection?: Maybe<FaqSection>;
  /** Update financing */
  updateFinancing: Financing;
  /** Update financing propositions */
  updateFinancingPropositions: Scalars['Boolean'];
  /** Update financing variant name */
  updateFinancingVariantName: Financing;
  updateGeneralMarketReport: GeneralMarketReport;
  updateMarketReport: MarketReport;
  updateOverviewLPSection: OverviewLpSection;
  /** Update Owner settings section. *Requires admin access */
  updateOwnerSettingsSection?: Maybe<OwnerSettingsSection>;
  updatePriceOffers: SearchProfile;
  /** Simply update property with or without valuations */
  updateProperty: Property;
  /** Simply update property with or without valuations */
  updatePropertyAlerts: Property;
  /** Update dossier for existing property */
  updatePropertyDossier?: Maybe<Property>;
  updatePropertyFavoriteSearchProfilesList: Scalars['Boolean'];
  /** Simply update property portfolio without valuations */
  updatePropertyPortfolio: Property;
  /** Update search profile */
  updateSearchProfile: SearchProfile;
  /** Update temporary search profile */
  updateTemporarySearchProfile: TemporarySearchProfile;
  /** Update user profile by Admin */
  updateUserByAdmin: User;
  /** Update user profile */
  updateUserProfile: User;
  /** Update Video section. *Requires admin access */
  updateVideoSection?: Maybe<VideoSection>;
  updateVirtualTour: VirtualTourSection;
  /** Upload property private documents to onOffice */
  uploadPropertyDocuments?: Maybe<Array<Scalars['String']>>;
  /** Upload property files to onOffice */
  uploadPropertyFiles?: Maybe<Array<Scalars['String']>>;
  /** User self delete unverified account */
  userSelfDelete: Scalars['Boolean'];
  verifyGuestUser: GuestUser;
};

export type MutationAcceptInvitationArgs = {
  invitationId: Scalars['String'];
};

export type MutationAcceptPurchaseOfferArgs = {
  input: AcceptPurchaseOfferInput;
};

export type MutationApprovedExposeRequestsArgs = {
  userId: Scalars['String'];
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};

export type MutationConfirmForgotPasswordArgs = {
  input: ConfirmForgotPasswordInput;
};

export type MutationConfirmGuestUserArgs = {
  input: ConfirmGuestUserInput;
};

export type MutationConfirmPhoneArgs = {
  input: ConfirmPhoneInput;
};

export type MutationConfirmPhoneChangeArgs = {
  input: ConfirmEmailInput;
};

export type MutationConvertPropertyArgs = {
  ownerId: Scalars['String'];
};

export type MutationConvertSearchProfileArgs = {
  ownerId: Scalars['String'];
  searchProfileId?: Maybe<Scalars['String']>;
};

export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};

export type MutationCreateAppointmentSectionArgs = {
  input: CreateAppointmentSectionInput;
};

export type MutationCreateBrokerShopArgs = {
  createBrokerShopInput: CreateBrokerShopInput;
};

export type MutationCreateCmsBlockArgs = {
  input: CreateCmsBlockInput;
};

export type MutationCreateCmsPageArgs = {
  input: CreateCmsPageInput;
};

export type MutationCreateCmsRegionalPageArgs = {
  input: CreateCmsRegionalPageInput;
};

export type MutationCreateContentSectionArgs = {
  input: CreateContentSectionInput;
};

export type MutationCreateCountryArgs = {
  createCountryInput: CreateCountryInput;
};

export type MutationCreateDownloadedFileActivityArgs = {
  exposeId: Scalars['String'];
  fileName: Scalars['String'];
};

export type MutationCreateExperimentArgs = {
  input: CreateExperimentInput;
};

export type MutationCreateExposeRequestArgs = {
  emailTemplate: ExposeRequestEmailTemplate;
  input: ExposeRequestInput;
};

export type MutationCreateFaqSectionArgs = {
  input: CreateFaqSectionInput;
};

export type MutationCreateFinancingArgs = {
  input: CreateFinancingInput;
};

export type MutationCreateGeneralMarketReportArgs = {
  input: CreateGeneralMarketReportInput;
};

export type MutationCreateMarketReportArgs = {
  input: CreateMarketReportInput;
};

export type MutationCreateOverviewLpSectionArgs = {
  overviewLPSectionInput: OverviewLpSectionInput;
};

export type MutationCreateOwnerSettingsSectionArgs = {
  input: CreateOwnerSettingsSectionInput;
};

export type MutationCreatePropertyArgs = {
  input: CreatePropertyInput;
};

export type MutationCreatePropertyDossierArgs = {
  input: PropertyDossierInput;
};

export type MutationCreatePurchaseIntentArgs = {
  input: PurchaseIntentInput;
};

export type MutationCreateSearchProfileArgs = {
  input: CreateSearchProfileInput;
};

export type MutationCreateTemporaryPropertyArgs = {
  input: CreatePropertyInput;
};

export type MutationCreateTemporarySearchProfileArgs = {
  input: CreateTemporarySearchProfileInput;
};

export type MutationCreateVideoSectionArgs = {
  input: CreateVideoSectionInput;
};

export type MutationCreateVirtualTourArgs = {
  input: CreateVirtualTourSectionInput;
};

export type MutationDeleteAdminArgs = {
  id: Scalars['String'];
};

export type MutationDeleteAdvantageArgs = {
  id: Scalars['String'];
};

export type MutationDeleteAppArgs = {
  id: Scalars['String'];
};

export type MutationDeleteAppointmentContactFormArgs = {
  id: Scalars['String'];
};

export type MutationDeleteBannerArgs = {
  id: Scalars['String'];
};

export type MutationDeleteBenefitArgs = {
  id: Scalars['String'];
};

export type MutationDeleteBuyerBenefitArgs = {
  id: Scalars['String'];
};

export type MutationDeleteBuyerFinderSettingsArgs = {
  id: Scalars['String'];
};

export type MutationDeleteBuyerFinderTeaserArgs = {
  id: Scalars['String'];
};

export type MutationDeleteBuyerFunctionArgs = {
  id: Scalars['String'];
};

export type MutationDeleteBuyerLandingPageArgs = {
  id: Scalars['String'];
};

export type MutationDeleteBuyerOverviewArgs = {
  id: Scalars['String'];
};

export type MutationDeleteCentralPhoneArgs = {
  id: Scalars['String'];
};

export type MutationDeleteCertificateArgs = {
  id: Scalars['String'];
};

export type MutationDeleteCmsPageArgs = {
  _id: Scalars['String'];
};

export type MutationDeleteCmsRegionalPageArgs = {
  _id: Scalars['String'];
};

export type MutationDeleteCustomerAppraisalArgs = {
  id: Scalars['String'];
};

export type MutationDeleteEvaluationCategoryArgs = {
  id: Scalars['String'];
};

export type MutationDeleteFinancingCalculatorArgs = {
  id: Scalars['String'];
};

export type MutationDeleteFinancingCertificateArgs = {
  id: Scalars['String'];
};

export type MutationDeleteFinancingLandingPageArgs = {
  id: Scalars['String'];
};

export type MutationDeleteFinancingOverviewArgs = {
  id: Scalars['String'];
};

export type MutationDeleteFinancingPartnerArgs = {
  id: Scalars['String'];
};

export type MutationDeleteGeneralAppointmentArgs = {
  id: Scalars['String'];
};

export type MutationDeleteInterestRatesTableArgs = {
  id: Scalars['String'];
};

export type MutationDeleteInvitationArgs = {
  inviteId: Scalars['String'];
};

export type MutationDeleteLocalMarketArgs = {
  id: Scalars['String'];
};

export type MutationDeleteOurRepresentativeExampleArgs = {
  id: Scalars['String'];
};

export type MutationDeleteOverviewAppArgs = {
  id: Scalars['String'];
};

export type MutationDeleteOverviewLandingPageArgs = {
  id: Scalars['String'];
};

export type MutationDeleteOwnerBenefitArgs = {
  id: Scalars['String'];
};

export type MutationDeleteOwnerFunctionArgs = {
  id: Scalars['String'];
};

export type MutationDeleteOwnerLandingPageArgs = {
  id: Scalars['String'];
};

export type MutationDeleteOwnerOverviewArgs = {
  id: Scalars['String'];
};

export type MutationDeletePriceAlterationArgs = {
  id: Scalars['String'];
};

export type MutationDeletePropertyPurchaseIntentArgs = {
  id: Scalars['String'];
};

export type MutationDeleteSellerBenefitArgs = {
  id: Scalars['String'];
};

export type MutationDeleteSellerFunctionArgs = {
  id: Scalars['String'];
};

export type MutationDeleteSellerLandingPageArgs = {
  id: Scalars['String'];
};

export type MutationDeleteSellerOverviewArgs = {
  id: Scalars['String'];
};

export type MutationDeleteStepByStepBenefitArgs = {
  id: Scalars['String'];
};

export type MutationDeleteTestimonialArgs = {
  id: Scalars['String'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};

export type MutationDislikePropertyArgs = {
  propertyId: Scalars['String'];
  searchProfileId: Scalars['String'];
};

export type MutationEditInvitationArgs = {
  input: UpdateInviteInput;
};

export type MutationExpandFinancingRadiusArgs = {
  input: UpdateFinancingPropositionsInput;
};

export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};

export type MutationGeneratePdfExposeLinkArgs = {
  fileKey: Scalars['String'];
  id: Scalars['String'];
};

export type MutationGetFinancingPropositionsArgs = {
  input: GetFinancingPropositionsInput;
};

export type MutationGetPdfLinkArgs = {
  id: Scalars['String'];
  token: Scalars['String'];
};

export type MutationGetSellerExposePdfLinkArgs = {
  id: Scalars['String'];
  token: Scalars['String'];
};

export type MutationInitiateRealSellArgs = {
  input: InitiateRealSellInput;
};

export type MutationInviteSellerArgs = {
  estateId: Scalars['String'];
};

export type MutationLikePropertyArgs = {
  propertyId: Scalars['String'];
  propertyPrice: Scalars['Float'];
  searchProfileId: Scalars['String'];
};

export type MutationMarkPropertyUnsuitableArgs = {
  propertyId: Scalars['String'];
  searchProfileId: Scalars['String'];
};

export type MutationNotifyBrokerForBuyerFinderArgs = {
  countSearchProfiles: Scalars['String'];
  isBlurredGraphic?: Maybe<Scalars['Boolean']>;
  propertyId: Scalars['String'];
};

export type MutationOverviewLandingPageContactArgs = {
  contactData: OverviewLandingPageContactDataInput;
};

export type MutationRemoveCmsBlockArgs = {
  _id: Scalars['String'];
};

export type MutationRemoveCmsSectionArgs = {
  input: RemoveCmsSectionInput;
};

export type MutationRemoveCountryArgs = {
  id: Scalars['String'];
};

export type MutationRemoveExperimentArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveFileAccessConfigArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveFinancingArgs = {
  _id: Scalars['String'];
};

export type MutationRemoveGeneralMarketReportArgs = {
  id: Scalars['String'];
};

export type MutationRemoveMarketReportArgs = {
  input: RemoveMarketReportInput;
};

export type MutationRemoveOurDestinationsArgs = {
  id: Scalars['String'];
};

export type MutationRemoveOverviewLpSectionArgs = {
  id: Scalars['String'];
};

export type MutationRemovePropertyArgs = {
  _id: Scalars['String'];
};

export type MutationRemoveSearchProfileArgs = {
  _id: Scalars['String'];
};

export type MutationRemoveUserAccountArgs = {
  username: Scalars['String'];
};

export type MutationRequestVerificationCodeArgs = {
  input: RequestVerificationCodeInput;
};

export type MutationResendConfirmationCodeArgs = {
  email: Scalars['String'];
};

export type MutationResendGuestUserCodeArgs = {
  phoneNumber: Scalars['String'];
};

export type MutationResendPhoneCodeAfterChangeArgs = {
  phone: Scalars['String'];
};

export type MutationRespondToAuthChallengeArgs = {
  input: AuthChallengeInput;
};

export type MutationSaveAdvantageArgs = {
  input: AdvantageInput;
};

export type MutationSaveAppArgs = {
  input: AppInput;
};

export type MutationSaveAppointmentContactFormArgs = {
  input: AppointmentContactFormInput;
};

export type MutationSaveBannerArgs = {
  input: BannersInput;
};

export type MutationSaveBenefitArgs = {
  input: BenefitInput;
};

export type MutationSaveBenefitSectionArgs = {
  input: BenefitSectionInput;
};

export type MutationSaveBuyerBenefitArgs = {
  input: BuyerBenefitInput;
};

export type MutationSaveBuyerFinderSettingsArgs = {
  input: BuyerFinderSettingsInput;
};

export type MutationSaveBuyerFinderTeaserArgs = {
  input: BuyerFinderTeaserInput;
};

export type MutationSaveBuyerFunctionArgs = {
  input: BuyerFunctionInput;
};

export type MutationSaveBuyerLandingPageArgs = {
  input: BuyerLandingPageInput;
};

export type MutationSaveBuyerOverviewArgs = {
  input: BuyerOverviewInput;
};

export type MutationSaveCentralPhoneArgs = {
  input: CentralPhoneInput;
};

export type MutationSaveCertificateArgs = {
  input: CertificateInput;
};

export type MutationSaveCustomerAppraisalArgs = {
  input: CustomerAppraisalInput;
};

export type MutationSaveEvaluationCategoryArgs = {
  input: EvaluationCategoryInput;
};

export type MutationSaveFileAccessConfigArgs = {
  input: FileAccessConfigInput;
};

export type MutationSaveFinancingCalculatorArgs = {
  input: FinancingCalculatorInput;
};

export type MutationSaveFinancingCertificateArgs = {
  input: FinancingCertificateInput;
};

export type MutationSaveFinancingLandingPageArgs = {
  input: FinancingLandingPageInput;
};

export type MutationSaveFinancingOverviewArgs = {
  input: FinancingOverviewInput;
};

export type MutationSaveFinancingPartnerArgs = {
  input: FinancingPartnerInput;
};

export type MutationSaveGeneralAppointmentArgs = {
  input: GeneralAppointmentInput;
};

export type MutationSaveInterestRatesTableArgs = {
  input: InterestRatesTableInput;
};

export type MutationSaveLocalMarketArgs = {
  input: LocalMarketsInput;
};

export type MutationSaveOurDestinationsArgs = {
  saveOurDestinationsInput: SaveOurDestinationsInput;
};

export type MutationSaveOurRepresentativeExampleArgs = {
  input: OurRepresentativeExampleInput;
};

export type MutationSaveOverviewAppArgs = {
  input: OverviewAppInput;
};

export type MutationSaveOverviewLandingPageArgs = {
  input: OverviewLandingPageInput;
};

export type MutationSaveOwnerBenefitArgs = {
  input: OwnerBenefitInput;
};

export type MutationSaveOwnerFunctionArgs = {
  input: OwnerFunctionInput;
};

export type MutationSaveOwnerLandingPageArgs = {
  input: OwnerLandingPageInput;
};

export type MutationSaveOwnerOverviewArgs = {
  input: OwnerOverviewInput;
};

export type MutationSavePriceAlterationArgs = {
  input: PriceAlterationInput2;
};

export type MutationSaveSellerBenefitArgs = {
  input: SellerBenefitInput;
};

export type MutationSaveSellerFunctionArgs = {
  input: SellerFunctionInput;
};

export type MutationSaveSellerLandingPageArgs = {
  input: SellerLandingPageInput;
};

export type MutationSaveSellerOverviewArgs = {
  input: SellerOverviewInput;
};

export type MutationSaveStepByStepBenefitArgs = {
  input: StepByStepBenefitInput;
};

export type MutationSaveTestimonialArgs = {
  input: TestimonialInput;
};

export type MutationSendExposeContactFormArgs = {
  contactForm: ExposeContactForm;
  propertyId: Scalars['String'];
};

export type MutationSendFeedbackArgs = {
  input: SendFeedbackInput;
};

export type MutationSendFeedbackOfOwnerPortalArgs = {
  input: SendFeedbackOfOwnerPortalInput;
};

export type MutationSendPropertyServicesArgs = {
  propertyId: Scalars['String'];
  services: Array<OnOfficeServices>;
};

export type MutationSendSellerRegisteredNotificationArgs = {
  userId: Scalars['String'];
};

export type MutationSendVonPollFinanceArgs = {
  input: SendVonPollFinanceInput;
};

export type MutationSetDisabledShopServicesArgs = {
  disabledPropertyServices: Array<Scalars['String']>;
  disabledServices: Array<Scalars['String']>;
  leaderId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type MutationSetPropertyExposeStatusArgs = {
  comment?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  status: PropertyExposeStatus;
};

export type MutationSetPropertyOwnerIdArgs = {
  addressId: Scalars['String'];
  ownerId: Scalars['String'];
};

export type MutationSetPropertyPortalStatusArgs = {
  propertyId: Scalars['String'];
  status: PropertyPortalStatusValues;
};

export type MutationSetUserIdForExposesArgs = {
  addressId: Scalars['Int'];
  userEmail: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationSetUserStateArgs = {
  _id: Scalars['String'];
  state: Scalars['String'];
};

export type MutationSharePropertyArgs = {
  input: CreateInviteInput;
};

export type MutationSignInArgs = {
  input: UserSignInInput;
};

export type MutationSignInAdminArgs = {
  input: UserSignInInput;
};

export type MutationSignUpArgs = {
  input: UserSignUpInput;
};

export type MutationTieAppointmentArgs = {
  appointmentId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type MutationUnsubscribeFromNotificationsArgs = {
  userId: Scalars['String'];
};

export type MutationUpdateAdminArgs = {
  input: UpdateAdminArgs;
};

export type MutationUpdateAppointmentSectionArgs = {
  input: UpdateAppointmentSectionInput;
};

export type MutationUpdateCmsBlockArgs = {
  input: UpdateCmsBlockInput;
};

export type MutationUpdateCmsPageArgs = {
  input: UpdateCmsPageInput;
};

export type MutationUpdateCmsRegionalPageArgs = {
  input: UpdateCmsRegionalPageInput;
};

export type MutationUpdateContentSectionArgs = {
  input: UpdateContentSectionInput;
};

export type MutationUpdateCountryArgs = {
  updateCountryInput: UpdateCountryInput;
};

export type MutationUpdateExperimentArgs = {
  input: UpdateExperimentInput;
};

export type MutationUpdateFaqSectionArgs = {
  input: UpdateFaqSectionInput;
};

export type MutationUpdateFinancingArgs = {
  input: UpdateFinancingInput;
};

export type MutationUpdateFinancingPropositionsArgs = {
  input: UpdateFinancingPropositionsInput;
};

export type MutationUpdateFinancingVariantNameArgs = {
  input: UpdateFinancingVariantNameInput;
};

export type MutationUpdateGeneralMarketReportArgs = {
  input: UpdateGeneralMarketReportInput;
};

export type MutationUpdateMarketReportArgs = {
  input: UpdateMarketReportInput;
};

export type MutationUpdateOverviewLpSectionArgs = {
  overviewLPSectionInput: OverviewLpSectionInput;
};

export type MutationUpdateOwnerSettingsSectionArgs = {
  input: UpdateOwnerSettingsSectionInput;
};

export type MutationUpdatePriceOffersArgs = {
  input: UpdatePriceOffersInput;
};

export type MutationUpdatePropertyArgs = {
  input: UpdatePropertyInput;
};

export type MutationUpdatePropertyAlertsArgs = {
  input: UpdatePropertyInput;
};

export type MutationUpdatePropertyDossierArgs = {
  input: PropertyDossierInput;
};

export type MutationUpdatePropertyFavoriteSearchProfilesListArgs = {
  input: UpdatePropertyFavoriteSpListInput;
};

export type MutationUpdatePropertyPortfolioArgs = {
  input: UpdatePropertyPortfolioInput;
};

export type MutationUpdateSearchProfileArgs = {
  input: UpdateSearchProfileInput;
};

export type MutationUpdateTemporarySearchProfileArgs = {
  input: UpdateSearchProfileInput;
};

export type MutationUpdateUserByAdminArgs = {
  input: UpdateUserByIdInput;
};

export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

export type MutationUpdateVideoSectionArgs = {
  input: UpdateVideoSectionInput;
};

export type MutationUpdateVirtualTourArgs = {
  input: UpdateVirtualTourSectionInput;
};

export type MutationUploadPropertyDocumentsArgs = {
  propertyFiles: Array<PropertyFileInput>;
  propertyId: Scalars['String'];
};

export type MutationUploadPropertyFilesArgs = {
  propertyFiles: Array<PropertyFileInput>;
  propertyId: Scalars['String'];
};

export type MutationUserSelfDeleteArgs = {
  username: Scalars['String'];
};

export type MutationVerifyGuestUserArgs = {
  input: VerifyGuestUserInput;
};

export type NotificationsEntity = {
  __typename?: 'NotificationsEntity';
  isNotifyEvaluationUpdates?: Maybe<Scalars['Boolean']>;
  isNotifyFinancingInterestRates?: Maybe<Scalars['Boolean']>;
  isNotifyFinancingMarketing?: Maybe<Scalars['Boolean']>;
  isNotifyGeneralNewsletter?: Maybe<Scalars['Boolean']>;
  isNotifyMarketPrice?: Maybe<Scalars['Boolean']>;
  isNotifyNewProperties?: Maybe<Scalars['Boolean']>;
  isNotifyNewPropertiesSummary?: Maybe<Scalars['Boolean']>;
  isNotifyPaymentOfInterestMonthly?: Maybe<Scalars['Boolean']>;
  isNotifyPriceChange?: Maybe<Scalars['Boolean']>;
};

export type NotificationsInput = {
  isNotifyEvaluationUpdates?: Maybe<Scalars['Boolean']>;
  isNotifyFinancingInterestRates?: Maybe<Scalars['Boolean']>;
  isNotifyFinancingMarketing?: Maybe<Scalars['Boolean']>;
  isNotifyGeneralNewsletter?: Maybe<Scalars['Boolean']>;
  isNotifyMarketPrice?: Maybe<Scalars['Boolean']>;
  isNotifyNewProperties?: Maybe<Scalars['Boolean']>;
  isNotifyNewPropertiesSummary?: Maybe<Scalars['Boolean']>;
  isNotifyPaymentOfInterestMonthly?: Maybe<Scalars['Boolean']>;
  isNotifyPriceChange?: Maybe<Scalars['Boolean']>;
};

export type OfferDateRangeInput = {
  max?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['String']>;
};

export type OfferFilterRangeInput = {
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type OffersFiltersInput = {
  buildingYear?: Maybe<OfferFilterRangeInput>;
  daysOnMarket?: Maybe<OfferFilterRangeInput>;
  dealType?: Maybe<DealType>;
  distanceGroceryStore?: Maybe<FilterUpperRangeInput>;
  distanceHospital?: Maybe<FilterUpperRangeInput>;
  distancePublicTransport?: Maybe<FilterUpperRangeInput>;
  hasLift?: Maybe<Scalars['Boolean']>;
  hasParkingSpaces?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  isWheelchairAccessible?: Maybe<Scalars['Boolean']>;
  landArea?: Maybe<OfferFilterRangeInput>;
  livingArea?: Maybe<OfferFilterRangeInput>;
  location?: Maybe<Array<LocationFilter>>;
  noise?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<OfferFilterRangeInput>;
  numberOfRooms?: Maybe<OfferFilterRangeInput>;
  offerIds?: Maybe<Array<Scalars['String']>>;
  propertyType?: Maybe<Array<PropertyTypeInput>>;
  rentGross?: Maybe<PriceFilterRangeInput>;
  salePrice?: Maybe<PriceFilterRangeInput>;
  slope?: Maybe<OfferFilterRangeInput>;
  startDate?: Maybe<OfferDateRangeInput>;
};

export type OffersSearchInput = {
  countryCode: Scalars['String'];
  filters: OffersFiltersInput;
  includeShareableLinks?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrderByCriterion>>;
  referenceLocation?: Maybe<ReferenceLocationInput>;
};

export type OnOfficeEnergyCertificate = {
  __typename?: 'OnOfficeEnergyCertificate';
  energyCertificate: Scalars['String'];
  energyCertificateValidUntil: Scalars['String'];
  energyEfficiencyClass: Scalars['String'];
  energySource: Scalars['String'];
  finalEnergyDemand: Scalars['String'];
  typeOfHeating: Scalars['String'];
};

export type OnOfficeEnergyCertificateInput = {
  energyCertificate: Scalars['String'];
  energyCertificateValidUntil: Scalars['String'];
  energyEfficiencyClass: Scalars['String'];
  energySource: Scalars['String'];
  finalEnergyDemand: Scalars['String'];
  typeOfHeating: Scalars['String'];
};

export enum OnOfficeExposeStatus {
  Approved = 'Approved',
  ChangesRequested = 'ChangesRequested',
  Preparation = 'Preparation',
  WaitingApproval = 'WaitingApproval',
}

export enum OnOfficeServices {
  DigitalStaging = 'DigitalStaging',
  DronePhotos = 'DronePhotos',
  EnergyCertificate = 'EnergyCertificate',
  FloorPlans = 'FloorPlans',
  HighCirculation = 'HighCirculation',
  Magazines = 'Magazines',
  OfficialInformation = 'OfficialInformation',
  Photos = 'Photos',
  PremiumPlacement = 'PremiumPlacement',
  RealEstatePortals = 'RealEstatePortals',
  SecretSale = 'SecretSale',
  SocialMedia = 'SocialMedia',
  VirtualTours = 'VirtualTours',
}

export type OnOfficeUser = {
  __typename?: 'OnOfficeUser';
  DSGVOStatus?: Maybe<Scalars['String']>;
  buyerPortalId?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  email: Scalars['String'];
  hasAccessToDocument?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  salutation: Scalars['String'];
  street: Scalars['String'];
  surname: Scalars['String'];
};

export type OptimizationMessages = {
  __typename?: 'OptimizationMessages';
  /** machbarkeit */
  feasibility?: Maybe<Array<Feasibility>>;
};

export type OptimizationMessagesInput = {
  /** machbarkeit */
  feasibility?: Maybe<Array<FeasibilityInput>>;
};

export type OptionalAddress = {
  __typename?: 'OptionalAddress';
  city?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type OptionalCoordinates = {
  __typename?: 'OptionalCoordinates';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type OptionalLocation = {
  __typename?: 'OptionalLocation';
  address?: Maybe<OptionalAddress>;
  coordinates?: Maybe<OptionalCoordinates>;
};

export enum OrderBy {
  DaysOnMarket = 'daysOnMarket',
  Distance = 'distance',
  SalePrice = 'salePrice',
  StartDate = 'startDate',
}

export type OrderByCriterion = {
  direction?: Maybe<Direction>;
  field?: Maybe<OrderBy>;
};

export enum OrderSuggestedPropertiesBy {
  Date = 'date',
  LivingArea = 'livingArea',
  Price = 'price',
  Proximity = 'proximity',
}

export type OrderSuggestedPropertiesByCriterion = {
  direction?: Maybe<Scalars['String']>;
  field?: Maybe<OrderSuggestedPropertiesBy>;
  proximityParams?: Maybe<CoordinatesInput>;
};

export type OurDestinations = {
  __typename?: 'OurDestinations';
  _id: Scalars['String'];
  description: Scalars['String'];
  destinations: Array<Destination>;
  title: Scalars['String'];
};

export type OurRepresentativeExampleFilterInput = {
  title?: Maybe<Scalars['String']>;
};

export type OurRepresentativeExampleInput = {
  CTALabel: Scalars['String'];
  _id?: Maybe<Scalars['String']>;
  expandedText: Scalars['String'];
  table1Row1Label: Scalars['String'];
  table1Row2Label: Scalars['String'];
  table1Row3Label: Scalars['String'];
  table1Row4Label: Scalars['String'];
  table1Title: Scalars['String'];
  table2Row1Label: Scalars['String'];
  table2Row2Label: Scalars['String'];
  table2Row3Label: Scalars['String'];
  table2Row4Label: Scalars['String'];
  table2Row5Label: Scalars['String'];
  table2Row6Label: Scalars['String'];
  table2Title: Scalars['String'];
  table3Row1Label: Scalars['String'];
  table3Row2Label: Scalars['String'];
  table3Row3Label: Scalars['String'];
  table3Title: Scalars['String'];
  table4Row1Label: Scalars['String'];
  table4Row1Value: Scalars['String'];
  table4Row2Label: Scalars['String'];
  table4Row2Value: Scalars['String'];
  table4Row3Label: Scalars['String'];
  table4Row3Value: Scalars['String'];
  table4Row4Label: Scalars['String'];
  table4Row4Value: Scalars['String'];
  table4Row5Label: Scalars['String'];
  table4Row5Value: Scalars['String'];
  table4Title: Scalars['String'];
  title: Scalars['String'];
};

export type OurRepresentativeExamples = {
  __typename?: 'OurRepresentativeExamples';
  CTALabel: Scalars['String'];
  _id?: Maybe<Scalars['String']>;
  expandedText: Scalars['String'];
  table1Row1Label: Scalars['String'];
  table1Row2Label: Scalars['String'];
  table1Row3Label: Scalars['String'];
  table1Row4Label: Scalars['String'];
  table1Title: Scalars['String'];
  table2Row1Label: Scalars['String'];
  table2Row2Label: Scalars['String'];
  table2Row3Label: Scalars['String'];
  table2Row4Label: Scalars['String'];
  table2Row5Label: Scalars['String'];
  table2Row6Label: Scalars['String'];
  table2Title: Scalars['String'];
  table3Row1Label: Scalars['String'];
  table3Row2Label: Scalars['String'];
  table3Row3Label: Scalars['String'];
  table3Title: Scalars['String'];
  table4Row1Label: Scalars['String'];
  table4Row1Value: Scalars['String'];
  table4Row2Label: Scalars['String'];
  table4Row2Value: Scalars['String'];
  table4Row3Label: Scalars['String'];
  table4Row3Value: Scalars['String'];
  table4Row4Label: Scalars['String'];
  table4Row4Value: Scalars['String'];
  table4Row5Label: Scalars['String'];
  table4Row5Value: Scalars['String'];
  table4Title: Scalars['String'];
  title: Scalars['String'];
};

export type OurRepresentativeExamplesInput = {
  CTALabel: Scalars['String'];
  _id?: Maybe<Scalars['String']>;
  expandedText: Scalars['String'];
  table1Row1Label: Scalars['String'];
  table1Row2Label: Scalars['String'];
  table1Row3Label: Scalars['String'];
  table1Row4Label: Scalars['String'];
  table1Title: Scalars['String'];
  table2Row1Label: Scalars['String'];
  table2Row2Label: Scalars['String'];
  table2Row3Label: Scalars['String'];
  table2Row4Label: Scalars['String'];
  table2Row5Label: Scalars['String'];
  table2Row6Label: Scalars['String'];
  table2Title: Scalars['String'];
  table3Row1Label: Scalars['String'];
  table3Row2Label: Scalars['String'];
  table3Row3Label: Scalars['String'];
  table3Title: Scalars['String'];
  table4Row1Label: Scalars['String'];
  table4Row1Value: Scalars['String'];
  table4Row2Label: Scalars['String'];
  table4Row2Value: Scalars['String'];
  table4Row3Label: Scalars['String'];
  table4Row3Value: Scalars['String'];
  table4Row4Label: Scalars['String'];
  table4Row4Value: Scalars['String'];
  table4Row5Label: Scalars['String'];
  table4Row5Value: Scalars['String'];
  table4Title: Scalars['String'];
  title: Scalars['String'];
};

export type OverviewAppFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type OverviewAppInput = {
  _id?: Maybe<Scalars['String']>;
  appStoreLink?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  googlePlayLink?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OverviewApps = {
  __typename?: 'OverviewApps';
  _id?: Maybe<Scalars['String']>;
  appStoreLink?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  googlePlayLink?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OverviewAppsInput = {
  _id?: Maybe<Scalars['String']>;
  appStoreLink?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  googlePlayLink?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OverviewLpSection = {
  __typename?: 'OverviewLPSection';
  _id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: OverviewLpSectionType;
};

export enum OverviewLpSectionType {
  App = 'APP',
  Appointment = 'APPOINTMENT',
  BuyerBenefit = 'BUYER_BENEFIT',
  BuyerFunction = 'BUYER_FUNCTION',
  BuyerOverview = 'BUYER_OVERVIEW',
  FinancingOverview = 'FINANCING_OVERVIEW',
  OwnerBenefit = 'OWNER_BENEFIT',
  OwnerFunction = 'OWNER_FUNCTION',
  OwnerOverview = 'OWNER_OVERVIEW',
  SellerBenefit = 'SELLER_BENEFIT',
  SellerFunction = 'SELLER_FUNCTION',
  SellerOverview = 'SELLER_OVERVIEW',
  Testimonial = 'TESTIMONIAL',
}

export type OverviewLandingPage = {
  __typename?: 'OverviewLandingPage';
  _id?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Array<Scalars['String']>>;
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sections?: Maybe<Array<OverviewLpSection>>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OverviewLandingPageContactDataInput = {
  addressBuildingNumber?: Maybe<Scalars['String']>;
  checkbox1: Scalars['Boolean'];
  cityPostal?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export type OverviewLandingPageFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type OverviewLandingPageInput = {
  _id?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Array<Scalars['String']>>;
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sections?: Maybe<Array<OverviewLpSectionInput>>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OverviewLpSectionInput = {
  _id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: OverviewLpSectionType;
};

export type OwnerBenefitFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerBenefitInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  icon1?: Maybe<Scalars['Upload']>;
  icon1Alt?: Maybe<Scalars['String']>;
  icon1Filename?: Maybe<Scalars['String']>;
  icon1Key?: Maybe<Scalars['String']>;
  icon1Url?: Maybe<Scalars['String']>;
  icon2?: Maybe<Scalars['Upload']>;
  icon2Alt?: Maybe<Scalars['String']>;
  icon2Filename?: Maybe<Scalars['String']>;
  icon2Key?: Maybe<Scalars['String']>;
  icon2Url?: Maybe<Scalars['String']>;
  icon3?: Maybe<Scalars['Upload']>;
  icon3Alt?: Maybe<Scalars['String']>;
  icon3Filename?: Maybe<Scalars['String']>;
  icon3Key?: Maybe<Scalars['String']>;
  icon3Url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  propertyEvaluateLabel?: Maybe<Scalars['String']>;
  text1?: Maybe<Scalars['String']>;
  text2?: Maybe<Scalars['String']>;
  text3?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerBenefits = {
  __typename?: 'OwnerBenefits';
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  icon1Alt?: Maybe<Scalars['String']>;
  icon1Key?: Maybe<Scalars['String']>;
  icon1Url?: Maybe<Scalars['String']>;
  icon2Alt?: Maybe<Scalars['String']>;
  icon2Key?: Maybe<Scalars['String']>;
  icon2Url?: Maybe<Scalars['String']>;
  icon3Alt?: Maybe<Scalars['String']>;
  icon3Key?: Maybe<Scalars['String']>;
  icon3Url?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  propertyEvaluateLabel?: Maybe<Scalars['String']>;
  text1?: Maybe<Scalars['String']>;
  text2?: Maybe<Scalars['String']>;
  text3?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerBenefitsInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  icon1Alt?: Maybe<Scalars['String']>;
  icon1Key?: Maybe<Scalars['String']>;
  icon1Url?: Maybe<Scalars['String']>;
  icon2Alt?: Maybe<Scalars['String']>;
  icon2Key?: Maybe<Scalars['String']>;
  icon2Url?: Maybe<Scalars['String']>;
  icon3Alt?: Maybe<Scalars['String']>;
  icon3Key?: Maybe<Scalars['String']>;
  icon3Url?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  propertyEvaluateLabel?: Maybe<Scalars['String']>;
  text1?: Maybe<Scalars['String']>;
  text2?: Maybe<Scalars['String']>;
  text3?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerFunctionFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerFunctionInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconFilename?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerFunctions = {
  __typename?: 'OwnerFunctions';
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerFunctionsInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerLpSection = {
  __typename?: 'OwnerLPSection';
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: OwnerLpSectionType;
};

export enum OwnerLpSectionType {
  Appointment = 'APPOINTMENT',
  Benefit = 'BENEFIT',
  BuyerFinderTeaser = 'BUYER_FINDER_TEASER',
  Faq = 'FAQ',
  OwnerBenefit = 'OWNER_BENEFIT',
  OwnerFunction = 'OWNER_FUNCTION',
  Video = 'VIDEO',
}

export type OwnerLandingPage = {
  __typename?: 'OwnerLandingPage';
  _id?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Array<Scalars['String']>>;
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sections?: Maybe<Array<OwnerLpSection>>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OwnerLandingPageFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type OwnerLandingPageInput = {
  _id?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Array<Scalars['String']>>;
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sections?: Maybe<Array<OwnerLpSectionInput>>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerLpSectionInput = {
  _id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: OwnerLpSectionType;
};

export type OwnerOverviewFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerOverviewInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  patternAnalysisLabel?: Maybe<Scalars['String']>;
  propertyEvaluateLabel?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerOverviews = {
  __typename?: 'OwnerOverviews';
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  patternAnalysisLabel?: Maybe<Scalars['String']>;
  propertyEvaluateLabel?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerOverviewsInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  patternAnalysisLabel?: Maybe<Scalars['String']>;
  propertyEvaluateLabel?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerSettingsSection = {
  __typename?: 'OwnerSettingsSection';
  _id: Scalars['String'];
  awsFolderKey: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  isActive: Scalars['Boolean'];
  ownerSettingsKey: Scalars['String'];
  ownerSettingsUrl: Scalars['String'];
  title: Scalars['String'];
  type: SectionType;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Poi = {
  __typename?: 'POI';
  category?: Maybe<PoiCategory>;
  distance?: Maybe<Scalars['Int']>;
  location?: Maybe<OptionalLocation>;
  name?: Maybe<Scalars['String']>;
  subcategory?: Maybe<PoiSubCategory>;
};

export enum PoiCategory {
  Catering = 'catering',
  Education = 'education',
  Health = 'health',
  Leisure = 'leisure',
  Shopping = 'shopping',
  Transport = 'transport',
}

export type PoiInput = {
  category?: Maybe<Array<PoiCategory>>;
  coordinates: CoordinatesInput;
  countryCode: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  radius?: Maybe<Scalars['Int']>;
  subcategory?: Maybe<Array<PoiSubCategory>>;
};

export enum PoiSubCategory {
  Airport = 'airport',
  Bar = 'bar',
  Bus = 'bus',
  Cafe = 'cafe',
  Clinic = 'clinic',
  ConvenienceStore = 'convenience_store',
  Dentist = 'dentist',
  Doctor = 'doctor',
  Entertainment = 'entertainment',
  HighwayRamp = 'highway_ramp',
  Hospital = 'hospital',
  Kindergarten = 'kindergarten',
  NursingHome = 'nursing_home',
  Park = 'park',
  Pharmacy = 'pharmacy',
  Playground = 'playground',
  PrimarySchool = 'primary_school',
  Restaurant = 'restaurant',
  SecondarySchool = 'secondary_school',
  Sport = 'sport',
  Subway = 'subway',
  Supermarket = 'supermarket',
  Train = 'train',
  Tram = 'tram',
  University = 'university',
  Veterinary = 'veterinary',
}

export type PageData = {
  __typename?: 'PageData';
  count: Scalars['Float'];
  limit: Scalars['Float'];
  offset: Scalars['Float'];
};

export type PaginatedAdvantages = {
  __typename?: 'PaginatedAdvantages';
  page: Array<Advantages>;
  pageData: PageData;
};

export type PaginatedAppointmentContactForms = {
  __typename?: 'PaginatedAppointmentContactForms';
  page: Array<AppointmentContactForms>;
  pageData: PageData;
};

export type PaginatedApps = {
  __typename?: 'PaginatedApps';
  page: Array<Apps>;
  pageData: PageData;
};

export type PaginatedBanners = {
  __typename?: 'PaginatedBanners';
  page: Array<Banner>;
  pageData: PageData;
};

export type PaginatedBenefits = {
  __typename?: 'PaginatedBenefits';
  page: Array<Benefits>;
  pageData: PageData;
};

export type PaginatedBuyerBenefits = {
  __typename?: 'PaginatedBuyerBenefits';
  page: Array<BuyerBenefits>;
  pageData: PageData;
};

export type PaginatedBuyerFinderSettings = {
  __typename?: 'PaginatedBuyerFinderSettings';
  page: Array<BuyerFinderSettings>;
  pageData: PageData;
};

export type PaginatedBuyerFinderTeasers = {
  __typename?: 'PaginatedBuyerFinderTeasers';
  page: Array<BuyerFinderTeasers>;
  pageData: PageData;
};

export type PaginatedBuyerFunctions = {
  __typename?: 'PaginatedBuyerFunctions';
  page: Array<BuyerFunctions>;
  pageData: PageData;
};

export type PaginatedBuyerLandingPages = {
  __typename?: 'PaginatedBuyerLandingPages';
  page: Array<BuyerLandingPage>;
  pageData: PageData;
};

export type PaginatedBuyerOverviews = {
  __typename?: 'PaginatedBuyerOverviews';
  page: Array<BuyerOverviews>;
  pageData: PageData;
};

export type PaginatedCentralPhone = {
  __typename?: 'PaginatedCentralPhone';
  page: Array<CentralPhone>;
  pageData: PageData;
};

export type PaginatedCertificates = {
  __typename?: 'PaginatedCertificates';
  page: Array<Certificates>;
  pageData: PageData;
};

export type PaginatedCmsRegionalPage = {
  __typename?: 'PaginatedCmsRegionalPage';
  page: Array<CmsRegionalPage>;
  pageData: PageData;
};

export type PaginatedCmsSections = {
  __typename?: 'PaginatedCmsSections';
  page: Array<CmsSectionUnion>;
  pageData: PageData;
};

export type PaginatedCustomerAppraisals = {
  __typename?: 'PaginatedCustomerAppraisals';
  page: Array<CustomerAppraisals>;
  pageData: PageData;
};

export type PaginatedEvaluationCategories = {
  __typename?: 'PaginatedEvaluationCategories';
  page: Array<EvaluationCategory>;
  pageData: PageData;
  usedCities: Array<Scalars['String']>;
};

export type PaginatedExposeRequests = {
  __typename?: 'PaginatedExposeRequests';
  page: Array<ExposeRequest>;
  pageData: PageData;
};

export type PaginatedFinancingCalculators = {
  __typename?: 'PaginatedFinancingCalculators';
  page: Array<FinancingCalculators>;
  pageData: PageData;
};

export type PaginatedFinancingCertificates = {
  __typename?: 'PaginatedFinancingCertificates';
  page: Array<FinancingCertificates>;
  pageData: PageData;
};

export type PaginatedFinancingLandingPages = {
  __typename?: 'PaginatedFinancingLandingPages';
  page: Array<FinancingLandingPage>;
  pageData: PageData;
};

export type PaginatedFinancingOverviews = {
  __typename?: 'PaginatedFinancingOverviews';
  page: Array<FinancingOverviews>;
  pageData: PageData;
};

export type PaginatedFinancingPartners = {
  __typename?: 'PaginatedFinancingPartners';
  page: Array<FinancingPartners>;
  pageData: PageData;
};

export type PaginatedGeneralAppointment = {
  __typename?: 'PaginatedGeneralAppointment';
  page: Array<GeneralAppointment>;
  pageData: PageData;
};

export type PaginatedInterestRatesTables = {
  __typename?: 'PaginatedInterestRatesTables';
  page: Array<InterestRatesTables>;
  pageData: PageData;
};

export type PaginatedLocalMarket = {
  __typename?: 'PaginatedLocalMarket';
  page: Array<LocalMarket>;
  pageData: PageData;
};

export type PaginatedMarketReports = {
  __typename?: 'PaginatedMarketReports';
  page: Array<MarketReport>;
  pageData: PageData;
};

export type PaginatedOurRepresentativeExamples = {
  __typename?: 'PaginatedOurRepresentativeExamples';
  page: Array<OurRepresentativeExamples>;
  pageData: PageData;
};

export type PaginatedOverviewApps = {
  __typename?: 'PaginatedOverviewApps';
  page: Array<OverviewApps>;
  pageData: PageData;
};

export type PaginatedOverviewLandingPagesEntity = {
  __typename?: 'PaginatedOverviewLandingPagesEntity';
  page: Array<OverviewLandingPage>;
  pageData: PageData;
};

export type PaginatedOwnerBenefits = {
  __typename?: 'PaginatedOwnerBenefits';
  page: Array<OwnerBenefits>;
  pageData: PageData;
};

export type PaginatedOwnerFunctions = {
  __typename?: 'PaginatedOwnerFunctions';
  page: Array<OwnerFunctions>;
  pageData: PageData;
};

export type PaginatedOwnerLandingPages = {
  __typename?: 'PaginatedOwnerLandingPages';
  page: Array<OwnerLandingPage>;
  pageData: PageData;
};

export type PaginatedOwnerOverviews = {
  __typename?: 'PaginatedOwnerOverviews';
  page: Array<OwnerOverviews>;
  pageData: PageData;
};

export type PaginatedPriceAlterationsEntity = {
  __typename?: 'PaginatedPriceAlterationsEntity';
  page: Array<PriceAlterationEntity>;
  pageData: PageData;
};

export type PaginatedProperties = {
  __typename?: 'PaginatedProperties';
  page: Array<Property>;
  pageData: PageData;
  totalValue?: Maybe<Scalars['Float']>;
};

export type PaginatedSellerBenefits = {
  __typename?: 'PaginatedSellerBenefits';
  page: Array<SellerBenefits>;
  pageData: PageData;
};

export type PaginatedSellerFunctions = {
  __typename?: 'PaginatedSellerFunctions';
  page: Array<SellerFunctions>;
  pageData: PageData;
};

export type PaginatedSellerLandingPages = {
  __typename?: 'PaginatedSellerLandingPages';
  page: Array<SellerLandingPage>;
  pageData: PageData;
};

export type PaginatedSellerOverviews = {
  __typename?: 'PaginatedSellerOverviews';
  page: Array<SellerOverviews>;
  pageData: PageData;
};

export type PaginatedStepByStepBenefits = {
  __typename?: 'PaginatedStepByStepBenefits';
  page: Array<StepByStepBenefits>;
  pageData: PageData;
};

export type PaginatedSuggestedProperties = {
  __typename?: 'PaginatedSuggestedProperties';
  page: Array<SuggestedProperty>;
  pageData: PageData;
};

export type PaginatedTestimonials = {
  __typename?: 'PaginatedTestimonials';
  page: Array<Testimonials>;
  pageData: PageData;
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  page: Array<User>;
  pageData: PageData;
};

export type PaginationArgs = {
  /** Limit */
  limit?: Maybe<Scalars['Float']>;
  /** Offset */
  offset?: Maybe<Scalars['Float']>;
};

export type Parameters = {
  __typename?: 'Parameters';
  percentileRank?: Maybe<Scalars['Int']>;
  percentileValue?: Maybe<Scalars['Float']>;
};

export type ParkingSpaces = {
  __typename?: 'ParkingSpaces';
  carport?: Maybe<Scalars['Int']>;
  duplex?: Maybe<Scalars['Int']>;
  garage?: Maybe<Scalars['Int']>;
  multiStoryGarage?: Maybe<Scalars['Int']>;
  otherParkingLot?: Maybe<Scalars['Int']>;
  parkingSpace?: Maybe<Scalars['Int']>;
  undergroundGarage?: Maybe<Scalars['Int']>;
};

export type ParkingSpacesInput = {
  carport?: Maybe<Scalars['Int']>;
  duplex?: Maybe<Scalars['Int']>;
  garage?: Maybe<Scalars['Int']>;
  multiStoryGarage?: Maybe<Scalars['Int']>;
  otherParkingLot?: Maybe<Scalars['Int']>;
  parkingSpace?: Maybe<Scalars['Int']>;
  undergroundGarage?: Maybe<Scalars['Int']>;
};

export enum Period {
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

export type PersonalFinancingEntity = {
  __typename?: 'PersonalFinancingEntity';
  age: Scalars['Float'];
  householdNetIncome: Scalars['Float'];
  mainEarner: MainEarner;
};

export type PersonalFinancingInput = {
  age: Scalars['Float'];
  householdNetIncome: Scalars['Float'];
  mainEarner: MainEarner;
};

export enum PreferredAppointmentTime {
  Afternoon = 'Afternoon',
  Evening = 'Evening',
  Morning = 'Morning',
  Noon = 'Noon',
  NotSpecified = 'NotSpecified',
}

export type PriceAlteration = {
  __typename?: 'PriceAlteration';
  cities: Array<EvaluationCity>;
  description: Scalars['String'];
  name: Scalars['String'];
  postCodes: Array<EvaluationCity>;
  startDate: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type PriceAlterationEntity = {
  __typename?: 'PriceAlterationEntity';
  _id?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type PriceAlterationFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type PriceAlterationInput = {
  cities: Array<EvaluationCityInput>;
  description: Scalars['String'];
  name: Scalars['String'];
  postCodes: Array<EvaluationCityInput>;
  startDate: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type PriceAlterationInput2 = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PriceFilterRangeInput = {
  currency: Currency;
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type PriceOffer = {
  __typename?: 'PriceOffer';
  createdAt: Scalars['DateTime'];
  offeredPrice: Scalars['Float'];
  propertyInternalId: Scalars['String'];
  propertyPrice: Scalars['Float'];
};

export type PriceOfferInput = {
  createdAt: Scalars['DateTime'];
  offeredPrice: Scalars['Float'];
  propertyInternalId: Scalars['String'];
  propertyPrice: Scalars['Float'];
};

export type Property = {
  __typename?: 'Property';
  _id: Scalars['String'];
  actualValuations?: Maybe<Array<PropertyValuation>>;
  administrativeDivision?: Maybe<AdministrativeDivision>;
  appointmentId?: Maybe<Scalars['String']>;
  buildingProjects?: Maybe<BuildingProjectsEntity>;
  completedSellingSteps?: Maybe<Array<SellingSteps>>;
  countryCode: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  dealType: DealType;
  description?: Maybe<PropertyDescription>;
  disabledServices?: Maybe<Array<Scalars['String']>>;
  dossierUrl?: Maybe<Scalars['String']>;
  dossierUrlLastSync?: Maybe<Scalars['DateTime']>;
  editors?: Maybe<Array<User>>;
  emailBatches?: Maybe<PropertyEmailBatches>;
  energyCertificate?: Maybe<OnOfficeEnergyCertificate>;
  /** Expose approved At */
  exposeApprovedAt?: Maybe<Scalars['DateTime']>;
  exposeChangesHistory?: Maybe<Array<Scalars['String']>>;
  /** Expose created At */
  exposeCreatedAt?: Maybe<Scalars['DateTime']>;
  exposeStatus?: Maybe<OnOfficeExposeStatus>;
  /** Expose updated At */
  exposeUpdatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  favoriteSearchProfiles?: Maybe<Array<Scalars['String']>>;
  /** Seller first registration reminder */
  firstReminderSentAt?: Maybe<Scalars['DateTime']>;
  isActivitiesTabUnlocked?: Maybe<Scalars['Boolean']>;
  isBrokerNotifiedOfBuyerFinder?: Maybe<Scalars['Boolean']>;
  isContractSigned?: Maybe<Scalars['Boolean']>;
  isCreatedVia3rdPartyPortal?: Maybe<Scalars['Boolean']>;
  isDigitalServiceActivated?: Maybe<Scalars['Boolean']>;
  isDigitalServiceLockedByBroker?: Maybe<Scalars['Boolean']>;
  isExposeApprovedViaOnOffice?: Maybe<Scalars['Boolean']>;
  isHiddenSendEmailToBrokerAlert?: Maybe<Scalars['Boolean']>;
  isMarketingDashboardNotificationSent?: Maybe<Scalars['Boolean']>;
  isMarketingDashboardUnlocked?: Maybe<Scalars['Boolean']>;
  isMarketingStartedNotificationSent?: Maybe<Scalars['Boolean']>;
  isNotifyEvaluationUpdates?: Maybe<Scalars['Boolean']>;
  isPreview?: Maybe<Scalars['Boolean']>;
  isReadOnlyAccess?: Maybe<Scalars['Boolean']>;
  isRealSellingProcessStarted?: Maybe<Scalars['Boolean']>;
  isUnderConstruction?: Maybe<Scalars['Boolean']>;
  latestSuggestedSP?: Maybe<LatestSuggestedSp>;
  locationScores?: Maybe<LocationScores>;
  mapboxImageUrl?: Maybe<Scalars['String']>;
  marketingStatistics?: Maybe<MarketingStatistics>;
  onOfficeId?: Maybe<Scalars['String']>;
  onOfficePrice?: Maybe<Scalars['Float']>;
  /** Seller second registration reminder */
  onOfficeReminderSentAt?: Maybe<Scalars['DateTime']>;
  onOfficeUUID?: Maybe<Scalars['String']>;
  otherRatingReason?: Maybe<Scalars['String']>;
  owner: User;
  ownerOnOfficeId?: Maybe<Scalars['String']>;
  pdfExpose?: Maybe<Scalars['String']>;
  pdfExposeToken?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Scalars['String']>>;
  poi?: Maybe<PropertyPoi>;
  poiLastSync?: Maybe<Scalars['DateTime']>;
  priceCurrency?: Maybe<Scalars['String']>;
  priceHubbleDossierId?: Maybe<Scalars['String']>;
  propertyData: PropertyData;
  propertyPortfolioData?: Maybe<PropertyPortfolioData>;
  propertyStatistics?: Maybe<Array<Item>>;
  propertyStatisticsLastSync?: Maybe<Scalars['DateTime']>;
  propertyWidgetFeedback?: Maybe<PropertyWidgetFeedback>;
  provision?: Maybe<Provision>;
  ratingReason?: Maybe<RatingReason>;
  relatedShop?: Maybe<RelatedShop>;
  /** Seller second registration reminder */
  secondReminderSentAt?: Maybe<Scalars['DateTime']>;
  servicesDisabledAt?: Maybe<Scalars['DateTime']>;
  socioEconomics?: Maybe<Array<SocioEconomicsEntity>>;
  spectators?: Maybe<Array<User>>;
  status?: Maybe<PropertyStatus>;
  timeHorizont?: Maybe<TimeHorizont>;
  /** Property title in onOffice */
  title?: Maybe<Scalars['String']>;
  tour360Link?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFilesCount?: Maybe<Scalars['Int']>;
  userStatus?: Maybe<UserStatus>;
  valuationType: ValuationType;
  valuations?: Maybe<Array<PropertyValuation>>;
  valuationsLastSync?: Maybe<Scalars['DateTime']>;
  valuationsUpdateEmailSentAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyAddress = {
  __typename?: 'PropertyAddress';
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownerEmail?: Maybe<Scalars['String']>;
};

export type PropertyAddressInput = {
  city: Scalars['String'];
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  street: Scalars['String'];
};

export enum PropertyCode {
  Apartment = 'apartment',
  House = 'house',
  MultiFamilyHouse = 'multi_family_house',
  Plot = 'plot',
}

export type PropertyCondition = {
  __typename?: 'PropertyCondition';
  bathrooms?: Maybe<Condition>;
  flooring?: Maybe<Condition>;
  kitchen?: Maybe<Condition>;
  masonry?: Maybe<Condition>;
  overall?: Maybe<Condition>;
  windows?: Maybe<Condition>;
};

export type PropertyConditionInput = {
  bathrooms?: Maybe<Condition>;
  flooring?: Maybe<Condition>;
  kitchen?: Maybe<Condition>;
  masonry?: Maybe<Condition>;
  overall?: Maybe<Condition>;
  windows?: Maybe<Condition>;
};

export type PropertyCoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type PropertyData = {
  __typename?: 'PropertyData';
  annualRentIncome?: Maybe<Scalars['Int']>;
  balconyArea?: Maybe<Scalars['Int']>;
  buildingYear: Scalars['Int'];
  city?: Maybe<Scalars['String']>;
  condition?: Maybe<PropertyCondition>;
  energyLabel?: Maybe<Scalars['String']>;
  floorNumber?: Maybe<Scalars['Int']>;
  hasPool?: Maybe<Scalars['Boolean']>;
  hasSauna?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  landArea?: Maybe<Scalars['Int']>;
  livingArea: Scalars['Float'];
  location: PropertyLocation;
  marketingParameters?: Maybe<Scalars['String']>;
  numberOfBathrooms?: Maybe<Scalars['Int']>;
  numberOfBedrooms?: Maybe<Scalars['Int']>;
  numberOfFloorsInBuilding?: Maybe<Scalars['Int']>;
  numberOfIndoorParkingSpaces?: Maybe<Scalars['Int']>;
  numberOfOutdoorParkingSpaces?: Maybe<Scalars['Int']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  numberOfUnits?: Maybe<Scalars['Int']>;
  parkingSpaces?: Maybe<ParkingSpaces>;
  propertyType: PropertyType;
  quality?: Maybe<PropertyQuality>;
  renovationYear?: Maybe<Scalars['Int']>;
};

export type PropertyDefaultFilters = {
  __typename?: 'PropertyDefaultFilters';
  energyLabels: Array<Scalars['String']>;
  maxBuildingYear: Scalars['Int'];
  maxLandArea: Scalars['Float'];
  maxLivingArea: Scalars['Float'];
  maxNumberOfBathrooms: Scalars['Int'];
  maxNumberOfIndoorParkingSpaces: Scalars['Int'];
  maxNumberOfOutdoorParkingSpaces: Scalars['Int'];
  maxNumberOfRooms: Scalars['Int'];
  maxPrice: Scalars['Float'];
  minBuildingYear: Scalars['Int'];
  minLandArea: Scalars['Float'];
  minLivingArea: Scalars['Float'];
  minNumberOfBathrooms: Scalars['Int'];
  minNumberOfIndoorParkingSpaces: Scalars['Int'];
  minNumberOfOutdoorParkingSpaces: Scalars['Int'];
  minNumberOfRooms: Scalars['Int'];
  minPrice: Scalars['Float'];
  propertyCodes: Array<PropertyCode>;
};

export type PropertyDefaultFiltersFilterInput = {
  ownerId?: Maybe<Scalars['String']>;
  relatedProperties?: Maybe<Scalars['Boolean']>;
};

export type PropertyDescription = {
  __typename?: 'PropertyDescription';
  equipmentDescription?: Maybe<Scalars['String']>;
  locationDescription?: Maybe<Scalars['String']>;
  objectDescription?: Maybe<Scalars['String']>;
  otherInformation?: Maybe<Scalars['String']>;
  planUrls?: Maybe<Array<Scalars['String']>>;
};

export type PropertyDescriptionInput = {
  equipmentDescription?: Maybe<Scalars['String']>;
  locationDescription?: Maybe<Scalars['String']>;
  objectDescription?: Maybe<Scalars['String']>;
  otherInformation?: Maybe<Scalars['String']>;
  planUrls?: Maybe<Array<Scalars['String']>>;
};

export type PropertyDossierInput = {
  askingSalePrice?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<DossierImageInput>>;
  logo?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  userDefinedFields?: Maybe<Array<UserDefinedFieldInput>>;
  valuationOverrideReasonFreetext?: Maybe<Scalars['String']>;
  valuationOverrideRentGross?: Maybe<Scalars['Int']>;
  valuationOverrideRentNet?: Maybe<Scalars['Int']>;
  valuationOverrideSalePrice?: Maybe<Scalars['Int']>;
};

export type PropertyEmailBatches = {
  __typename?: 'PropertyEmailBatches';
  exposeWaitingApprovalReminder?: Maybe<Scalars['String']>;
  sellerDeclinedInvitation?: Maybe<Scalars['String']>;
};

export type PropertyEmailBatchesInput = {
  exposeWaitingApprovalReminder?: Maybe<Scalars['String']>;
  sellerDeclinedInvitation?: Maybe<Scalars['String']>;
};

export type PropertyExpose = {
  __typename?: 'PropertyExpose';
  comment?: Maybe<Scalars['String']>;
  /** Expose updated At */
  exposeApprovedAt?: Maybe<Scalars['DateTime']>;
  exposeChangesHistory?: Maybe<Array<Scalars['String']>>;
  /** Expose created At */
  exposeCreatedAt?: Maybe<Scalars['DateTime']>;
  /** Expose updated At */
  exposeUpdatedAt?: Maybe<Scalars['DateTime']>;
  isExposeApprovedViaOnOffice?: Maybe<Scalars['Boolean']>;
  pdf?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  status?: Maybe<OnOfficeExposeStatus>;
};

export type PropertyExposeInput = {
  comment?: Maybe<Scalars['String']>;
  /** Expose updated At */
  exposeApprovedAt?: Maybe<Scalars['DateTime']>;
  exposeChangesHistory?: Maybe<Array<Scalars['String']>>;
  /** Expose created At */
  exposeCreatedAt?: Maybe<Scalars['DateTime']>;
  /** Expose updated At */
  exposeUpdatedAt?: Maybe<Scalars['DateTime']>;
  isExposeApprovedViaOnOffice?: Maybe<Scalars['Boolean']>;
  pdf?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  status?: Maybe<OnOfficeExposeStatus>;
};

export enum PropertyExposeStatus {
  Approved = 'Approved',
  ChangesRequested = 'ChangesRequested',
  NotificationSent = 'NotificationSent',
  Preparation = 'Preparation',
  WaitingApproval = 'WaitingApproval',
}

export type PropertyFile = {
  __typename?: 'PropertyFile';
  content?: Maybe<Scalars['String']>;
  documentAttribute?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isPublished?: Maybe<Scalars['Boolean']>;
  originalname: Scalars['String'];
  releaseStatus?: Maybe<ReleaseStatus>;
  title: Scalars['String'];
  type: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type PropertyFileInput = {
  attribute?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  fileName: Scalars['String'];
  fileTitle: Scalars['String'];
  fileType: PropertyFileType;
  text: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export enum PropertyFileType {
  Document = 'Document',
  EnergyCertificate = 'EnergyCertificate',
  Expose = 'Expose',
  Link = 'Link',
  Notice = 'Notice',
  Photo = 'Photo',
  Plan = 'Plan',
  RentalStatement = 'RentalStatement',
}

export type PropertyFilterInput = {
  energyLabels?: Maybe<Array<Scalars['String']>>;
  maxBuildingYear?: Maybe<Scalars['Int']>;
  maxLandArea?: Maybe<Scalars['Float']>;
  maxLivingArea?: Maybe<Scalars['Float']>;
  maxNumberOfBathrooms?: Maybe<Scalars['Int']>;
  maxNumberOfIndoorParkingSpaces?: Maybe<Scalars['Int']>;
  maxNumberOfOutdoorParkingSpaces?: Maybe<Scalars['Int']>;
  maxNumberOfRooms?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Float']>;
  minBuildingYear?: Maybe<Scalars['Int']>;
  minLandArea?: Maybe<Scalars['Float']>;
  minLivingArea?: Maybe<Scalars['Float']>;
  minNumberOfBathrooms?: Maybe<Scalars['Int']>;
  minNumberOfIndoorParkingSpaces?: Maybe<Scalars['Int']>;
  minNumberOfOutdoorParkingSpaces?: Maybe<Scalars['Int']>;
  minNumberOfRooms?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  propertyCodes?: Maybe<Array<PropertyCode>>;
  propertyOnOfficeId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type PropertyGeoJson = {
  __typename?: 'PropertyGeoJson';
  coordinates: Array<Scalars['Float']>;
  type: Scalars['String'];
};

export type PropertyGeoJsonInput = {
  coordinates: Array<Scalars['Float']>;
  type: Scalars['String'];
};

export type PropertyInput = {
  annualRentIncome?: Maybe<Scalars['Int']>;
  balconyArea?: Maybe<Scalars['Int']>;
  buildingYear: Scalars['Int'];
  city?: Maybe<Scalars['String']>;
  condition?: Maybe<PropertyConditionInput>;
  energyLabel?: Maybe<Scalars['String']>;
  favoriteSearchProfiles?: Maybe<Array<Scalars['String']>>;
  floorNumber?: Maybe<Scalars['Int']>;
  hasPool?: Maybe<Scalars['Boolean']>;
  hasSauna?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  landArea?: Maybe<Scalars['Int']>;
  livingArea: Scalars['Float'];
  location: LocationInput;
  numberOfBathrooms?: Maybe<Scalars['Int']>;
  numberOfBedrooms?: Maybe<Scalars['Int']>;
  numberOfFloorsInBuilding?: Maybe<Scalars['Int']>;
  numberOfIndoorParkingSpaces?: Maybe<Scalars['Int']>;
  numberOfOutdoorParkingSpaces?: Maybe<Scalars['Int']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  numberOfUnits?: Maybe<Scalars['Int']>;
  parkingSpaces?: Maybe<ParkingSpacesInput>;
  propertyType: PropertyTypeInput;
  quality?: Maybe<PropertyQualityInput>;
  renovationYear?: Maybe<Scalars['Int']>;
};

export type PropertyLocation = {
  __typename?: 'PropertyLocation';
  address: Address;
  coordinates: Coordinates;
  geoJson?: Maybe<PropertyGeoJson>;
};

export type PropertyLocationInput = {
  address: PropertyAddressInput;
  coordinates: PropertyCoordinatesInput;
  geoJson?: Maybe<PropertyGeoJsonInput>;
};

export type PropertyPoi = {
  __typename?: 'PropertyPOI';
  catering?: Maybe<Catering>;
  education?: Maybe<Education>;
  health?: Maybe<Health>;
  leisure?: Maybe<Leisure>;
  shopping?: Maybe<Shopping>;
  transport?: Maybe<Transport>;
};

export enum PropertyPortalStatusValues {
  Active = 'Active',
  DeletedUser = 'DeletedUser',
  Inactive = 'Inactive',
  InvitationSent = 'InvitationSent',
  NotSet = 'NotSet',
}

export type PropertyPortfolioData = {
  __typename?: 'PropertyPortfolioData';
  coverImageFilename?: Maybe<Scalars['String']>;
  coverImageKey?: Maybe<Scalars['String']>;
  coverImageUrl?: Maybe<Scalars['String']>;
  shareTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PropertyPortfolioDataInput = {
  coverImage?: Maybe<Scalars['Upload']>;
  coverImageFilename?: Maybe<Scalars['String']>;
  coverImageKey?: Maybe<Scalars['String']>;
  coverImageUrl?: Maybe<Scalars['String']>;
  shareTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PropertyPurchaseIntent = {
  __typename?: 'PropertyPurchaseIntent';
  _id: Scalars['String'];
  confirmFunding: Scalars['Boolean'];
  dateCreated: Scalars['DateTime'];
  price: Scalars['Float'];
  propertyId: Scalars['String'];
  recipient: Scalars['String'];
};

export type PropertyQuality = {
  __typename?: 'PropertyQuality';
  bathrooms?: Maybe<Quality>;
  flooring?: Maybe<Quality>;
  kitchen?: Maybe<Quality>;
  masonry?: Maybe<Quality>;
  overall?: Maybe<Quality>;
  windows?: Maybe<Quality>;
};

export type PropertyQualityInput = {
  bathrooms?: Maybe<Quality>;
  flooring?: Maybe<Quality>;
  kitchen?: Maybe<Quality>;
  masonry?: Maybe<Quality>;
  overall?: Maybe<Quality>;
  windows?: Maybe<Quality>;
};

export type PropertyStatisticsInput = {
  countryCode: Scalars['String'];
  filters: FiltersInput;
  groupBy?: Maybe<GroupByInput>;
  statistics: Array<StatisticsInput>;
};

export enum PropertyStatus {
  Initial = 'Initial',
  Selling = 'Selling',
  SellingSimulation = 'SellingSimulation',
  Sold = 'Sold',
}

export enum PropertySubcode {
  ApartmentAttic = 'apartment_attic',
  ApartmentMaisonette = 'apartment_maisonette',
  ApartmentNormal = 'apartment_normal',
  ApartmentPenthouse = 'apartment_penthouse',
  ApartmentStudio = 'apartment_studio',
  ApartmentTerraced = 'apartment_terraced',
  HouseDetached = 'house_detached',
  HouseFarm = 'house_farm',
  HouseRowCorner = 'house_row_corner',
  HouseRowMiddle = 'house_row_middle',
  HouseSemiDetached = 'house_semi_detached',
}

export type PropertyType = {
  __typename?: 'PropertyType';
  code: PropertyCode;
  subcode?: Maybe<PropertySubcode>;
};

export type PropertyTypeInput = {
  code: PropertyCode;
  subcode?: Maybe<PropertySubcode>;
};

export enum PropertyUse {
  PartlyRented = 'PARTLY_RENTED',
  Rented = 'RENTED',
  SelfUsed = 'SELF_USED',
}

export type PropertyValuation = {
  __typename?: 'PropertyValuation';
  date: Scalars['DateTime'];
  valuation: Valuation;
};

export type PropertyWidgetFeedback = {
  __typename?: 'PropertyWidgetFeedback';
  numericValue: Scalars['String'];
  priceHubbleId?: Maybe<Scalars['String']>;
  rating: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

export type PropertyWidgetFeedbackInput = {
  numericValue?: Maybe<Scalars['String']>;
  priceHubbleId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Propositions = {
  __typename?: 'Propositions';
  /** annahmeFrist */
  acceptanceDeadline?: Maybe<Scalars['String']>;
  /** beschreibung */
  description?: Maybe<Scalars['String']>;
  /** effektivZins */
  effectiveInterest?: Maybe<Scalars['Float']>;
  /** machbarkeit */
  feasibility?: Maybe<Scalars['Float']>;
  /** finanzierungsbausteine */
  financingBuildingBlocks: Array<FinancingBuildingBlocks>;
  /** finanzierungsVorschlagId */
  financingPropositionId?: Maybe<Scalars['String']>;
  /** zinsbindungInJahrenMinMax */
  fixedInterestRateInYearsMinMax?: Maybe<Scalars['String']>;
  /** kennung */
  identifier?: Maybe<Scalars['String']>;
  /** darlehensSumme */
  loanAmount?: Maybe<Scalars['Float']>;
  /** optimierungsMeldungen */
  optimizationMessages?: Maybe<OptimizationMessages>;
  /** rank */
  rank?: Maybe<Scalars['Float']>;
  rateMonthly?: Maybe<Scalars['Float']>;
  /** sollZins */
  shouldInterest?: Maybe<Scalars['Float']>;
  /** vorschlagsOption */
  suggestionOption?: Maybe<Scalars['String']>;
  /** gesamtRateProMonat */
  totalRatePerMonth?: Maybe<Scalars['Float']>;
};

export type PropositionsInput = {
  /** annahmeFrist */
  acceptanceDeadline?: Maybe<Scalars['String']>;
  /** beschreibung */
  description?: Maybe<Scalars['String']>;
  /** effektivZins */
  effectiveInterest?: Maybe<Scalars['Float']>;
  /** machbarkeit */
  feasibility?: Maybe<Scalars['Float']>;
  /** finanzierungsbausteine */
  financingBuildingBlocks: Array<FinancingBuildingBlocksInput>;
  /** finanzierungsVorschlagId */
  financingPropositionId?: Maybe<Scalars['String']>;
  /** zinsbindungInJahrenMinMax */
  fixedInterestRateInYearsMinMax?: Maybe<Scalars['String']>;
  /** kennung */
  identifier?: Maybe<Scalars['String']>;
  /** darlehensSumme */
  loanAmount?: Maybe<Scalars['Float']>;
  /** optimierungsMeldungen */
  optimizationMessages?: Maybe<OptimizationMessagesInput>;
  /** rank */
  rank?: Maybe<Scalars['Float']>;
  rateMonthly?: Maybe<Scalars['Float']>;
  /** sollZins */
  shouldInterest?: Maybe<Scalars['Float']>;
  /** vorschlagsOption */
  suggestionOption?: Maybe<Scalars['String']>;
  /** gesamtRateProMonat */
  totalRatePerMonth?: Maybe<Scalars['Float']>;
};

export type Provision = {
  __typename?: 'Provision';
  isAgreedWithSeller?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type ProvisionInput = {
  isAgreedWithSeller?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type PurchaseIntent = {
  __typename?: 'PurchaseIntent';
  _id: Scalars['String'];
  buildingNumber?: Maybe<Scalars['String']>;
  business?: Maybe<PurchaseIntentBusiness>;
  city: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  confirmFunding: Scalars['Boolean'];
  confirmPrice: Scalars['Boolean'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  creatorId: Scalars['String'];
  dateCreated: Scalars['DateTime'];
  email: Scalars['String'];
  externalPropertyId: Scalars['Float'];
  headOfficeBuildingNumber?: Maybe<Scalars['String']>;
  headOfficeCity?: Maybe<Scalars['String']>;
  headOfficeCountry?: Maybe<PurchaseIntentCountry>;
  headOfficePostalCode?: Maybe<Scalars['String']>;
  headOfficeStreet?: Maybe<Scalars['String']>;
  legalForm?: Maybe<PurchaseIntentLegalForm>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  otherPersons?: Maybe<Array<PurchaseIntentPerson>>;
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  price: Scalars['Float'];
  propertyId: Scalars['String'];
  registrationNumber?: Maybe<Scalars['String']>;
  salutation: PurchaseIntentSalutation;
  searchProfileId: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  title: PurchaseIntentTitle;
  type: PurchaseIntentType;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum PurchaseIntentBusiness {
  FinancialEconomy = 'FINANCIAL_ECONOMY',
  Health = 'HEALTH',
  Industry = 'INDUSTRY',
  Media = 'MEDIA',
  RealEstate = 'REAL_ESTATE',
  Service = 'SERVICE',
  Tourism = 'TOURISM',
  Trade = 'TRADE',
}

export enum PurchaseIntentCountry {
  Austria = 'AUSTRIA',
  France = 'FRANCE',
  Germany = 'GERMANY',
  Greece = 'GREECE',
  Hungary = 'HUNGARY',
  Italy = 'ITALY',
  Luxembourg = 'LUXEMBOURG',
  Netherlands = 'NETHERLANDS',
  Portugal = 'PORTUGAL',
  Spain = 'SPAIN',
  Switzerland = 'SWITZERLAND',
}

export type PurchaseIntentInput = {
  buildingNumber?: Maybe<Scalars['String']>;
  business?: Maybe<PurchaseIntentBusiness>;
  city: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  confirmFunding: Scalars['Boolean'];
  confirmPrice: Scalars['Boolean'];
  email: Scalars['String'];
  headOfficeBuildingNumber?: Maybe<Scalars['String']>;
  headOfficeCity?: Maybe<Scalars['String']>;
  headOfficeCountry?: Maybe<PurchaseIntentCountry>;
  headOfficePostalCode?: Maybe<Scalars['String']>;
  headOfficeStreet?: Maybe<Scalars['String']>;
  legalForm?: Maybe<PurchaseIntentLegalForm>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  otherPersons?: Maybe<Array<PurchaseIntentPersonInput>>;
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  price: Scalars['Float'];
  propertyId: Scalars['String'];
  registrationNumber?: Maybe<Scalars['String']>;
  salutation: PurchaseIntentSalutation;
  searchProfileId: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  title: PurchaseIntentTitle;
  type: PurchaseIntentType;
};

export enum PurchaseIntentLegalForm {
  CompanyUnderCivilLaw = 'COMPANY_UNDER_CIVIL_LAW',
  CompanyWithLimitedLiability = 'COMPANY_WITH_LIMITED_LIABILITY',
  EntrepreneurialCompany = 'ENTREPRENEURIAL_COMPANY',
  LimitedPartnership = 'LIMITED_PARTNERSHIP',
  OpenTradingCompany = 'OPEN_TRADING_COMPANY',
  PublicCompany = 'PUBLIC_COMPANY',
  SoleTrade = 'SOLE_TRADE',
}

export type PurchaseIntentPerson = {
  __typename?: 'PurchaseIntentPerson';
  buildingNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  salutation: PurchaseIntentSalutation;
  street?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  title: PurchaseIntentTitle;
};

export type PurchaseIntentPersonInput = {
  buildingNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  salutation: PurchaseIntentSalutation;
  street?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  title: PurchaseIntentTitle;
};

export enum PurchaseIntentSalutation {
  Mr = 'MR',
  Ms = 'MS',
}

export enum PurchaseIntentTitle {
  Dr = 'DR',
  NoTitle = 'NO_TITLE',
  ProfDr = 'PROF_DR',
}

export enum PurchaseIntentType {
  Legal = 'LEGAL',
  Physical = 'PHYSICAL',
}

export enum Quality {
  HighQuality = 'high_quality',
  Luxury = 'luxury',
  Normal = 'normal',
  Simple = 'simple',
}

export type Query = {
  __typename?: 'Query';
  appointmentActivities?: Maybe<Array<Activity>>;
  benefitsByIds: Array<BenefitSection>;
  brokerShops: Array<BrokerShop>;
  checkBrokerSettingsPermission?: Maybe<BrokerPermissions>;
  checkInvitationHealth: Scalars['Boolean'];
  /** Fetch single cms block */
  cmsBlock?: Maybe<CmsBlock>;
  /** Fetch all cms blocks */
  cmsBlocks: Array<CmsBlock>;
  /** Fetch single cms page */
  cmsPage: CmsPage;
  /** Fetch all cms pages */
  cmsPages: Array<CmsPage>;
  /** Fetch single cms regional page by slug */
  cmsRegionalPage: CmsRegionalPage;
  cmsRegionalPages: PaginatedCmsRegionalPage;
  /** Fetch single cms section. */
  cmsSection?: Maybe<CmsSectionUnion>;
  /** Fetch all cms sections. (Type filtering is appliable here) */
  cmsSections: Array<CmsSectionUnion>;
  contactPerson: ContactPerson;
  convertChfToEur: Scalars['Float'];
  convertEurToChf: Scalars['Float'];
  countries: Array<Country>;
  country: Country;
  /** Get currently logged in user data */
  currentUser?: Maybe<User>;
  dashboardStatus: DashboardStatus;
  digitalServiceStatus: Scalars['Boolean'];
  disabledShopServices?: Maybe<Array<Scalars['String']>>;
  documentsActivities?: Maybe<Array<Activity>>;
  downloadedExposeActivities?: Maybe<Array<Activity>>;
  /** Find single experiment (A/B Test) */
  experiment: Experiment;
  /** Find all experiments (A/B Tests) */
  experiments: Array<Experiment>;
  exposeActivities?: Maybe<Array<Activity>>;
  exposeDetails: ExposeRequest;
  exposeRequests: PaginatedExposeRequests;
  fileAccessConfig?: Maybe<FileAccessConfig>;
  fileAccessConfigs: Array<FileAccessConfig>;
  /** Fetch single financing */
  financing?: Maybe<Financing>;
  /** Fetch all finances */
  financings: Array<Financing>;
  /** Find related search profiles */
  findRelatedSearchProfiles: Array<SearchProfile>;
  /** Find user by id. *Requires admin access */
  findUserById?: Maybe<User>;
  /** Get General Market Report by country */
  generalMarketReportByCountry?: Maybe<GeneralMarketReport>;
  /** Get General Market Report by _id. */
  generalMarketReportById?: Maybe<GeneralMarketReport>;
  generalMarketReports: Array<GeneralMarketReport>;
  /** Get all users. *Requires admin access */
  getAllUsers: PaginatedUser;
  /** Get list of Buyers with access status to file */
  getBuyersDocumentAccessStatus?: Maybe<Array<OnOfficeUser>>;
  getChfToEurRate: Scalars['Float'];
  getEurToChfRate: Scalars['Float'];
  getExposeDocuments: Array<PropertyFile>;
  /** Get von poll property details */
  getFinancingVonPollPropertyDetails: GetFinancingVonPollPropertyDetails;
  /** Get general statistics */
  getGeneralStatistics?: Maybe<MarketingGeneralStatistics>;
  getMarketingStatistics?: Maybe<MarketingStatistics>;
  getOffers: Comparables;
  /** Get user info from onOffice */
  getOnOfficeUser?: Maybe<OnOfficeUser>;
  /** Get user info from onOffice */
  getPropertyBroker?: Maybe<Broker>;
  /** Get property private documents from onOffice */
  getPropertyDocuments?: Maybe<Array<PropertyFile>>;
  /** Get property expose status */
  getPropertyExposeStatus?: Maybe<PropertyExpose>;
  getPropertyFavoriteSearchProfilesList: Array<SearchProfile>;
  /** Get property external files from onOffice */
  getPropertyFiles?: Maybe<Array<PropertyFile>>;
  /** Get selected services from onOffice */
  getPropertyServices?: Maybe<Services>;
  getProvenExpertRating: Rating;
  /** Get shop contacts from onOffice */
  getShopContacts?: Maybe<Shop>;
  getTemporaryProperty?: Maybe<TemporaryProperty>;
  handOverActivities?: Maybe<Array<Activity>>;
  invites: Array<Invite>;
  /** Get isochrones for the specified location, mode of transport and cutoff minutes, e.g. */
  isochrones: Isochrones;
  latestActivities?: Maybe<Array<Activity>>;
  locales: Array<Locale>;
  /** Get market report by _id. */
  marketReport?: Maybe<MarketReport>;
  marketReports: PaginatedMarketReports;
  marketingActivities?: Maybe<Array<Activity>>;
  marketingStatus?: Maybe<Scalars['Boolean']>;
  notaryActivities?: Maybe<Array<Activity>>;
  ourDestination: OurDestinations;
  ourDestinations: Array<OurDestinations>;
  overviewLpSection: OverviewLpSection;
  /** Fetch all paginated cms sections. (Type filtering is applicable here) */
  paginatedCmsSections: PaginatedCmsSections;
  phoneCallsActivities?: Maybe<Array<Activity>>;
  /** Get points of interest based on coordinates */
  pois: Array<Poi>;
  portalsActivities?: Maybe<Array<Activity>>;
  priceOfferActivities?: Maybe<Array<Activity>>;
  /** Fetch all properties */
  properties: Array<Property>;
  /** Fetch single property */
  property?: Maybe<Property>;
  propertyAddress?: Maybe<PropertyAddress>;
  /** Used to compute default filters based on existing properties */
  propertyDefaultFilters: PropertyDefaultFilters;
  /** Fetch property for onOffice marketplace */
  propertyOnOfficeView?: Maybe<Property>;
  /** Basic statistics */
  propertyStatistics: Array<Item>;
  /** Run price hubble property valuation */
  propertyValuation: Array<PropertyValuation>;
  readAdvantage: Advantages;
  readAdvantages: PaginatedAdvantages;
  readApp: Apps;
  readAppointmentContactForm: AppointmentContactForms;
  readAppointmentContactForms: PaginatedAppointmentContactForms;
  readAppointments: Array<AppointmentSection>;
  readApps: PaginatedApps;
  readBanner: Banner;
  readBanners: PaginatedBanners;
  readBenefit: Benefits;
  readBenefits: PaginatedBenefits;
  readBuyerBenefit: BuyerBenefits;
  readBuyerBenefits: PaginatedBuyerBenefits;
  readBuyerFinderSettings?: Maybe<BuyerFinderSettings>;
  readBuyerFinderSettingsList: PaginatedBuyerFinderSettings;
  readBuyerFinderTeaser: BuyerFinderTeasers;
  readBuyerFinderTeasers: PaginatedBuyerFinderTeasers;
  readBuyerFunction: BuyerFunctions;
  readBuyerFunctions: PaginatedBuyerFunctions;
  readBuyerFunctionsList: Array<BuyerFunctions>;
  readBuyerLandingPage: BuyerLandingPage;
  readBuyerLandingPages: PaginatedBuyerLandingPages;
  readBuyerOverview: BuyerOverviews;
  readBuyerOverviews: PaginatedBuyerOverviews;
  readCentralPhone: CentralPhone;
  readCentralPhoneByCountry: CentralPhone;
  readCentralPhones: PaginatedCentralPhone;
  readCertificate: Certificates;
  readCertificates: PaginatedCertificates;
  readCustomerAppraisal: CustomerAppraisals;
  readCustomerAppraisals: PaginatedCustomerAppraisals;
  /** Read price hubble dossier */
  readDossier: Dossier;
  /** Interest rates table */
  readEuropaceInterestRates: Array<FinancingProposition>;
  readEvaluationCategories: PaginatedEvaluationCategories;
  readEvaluationCategory: EvaluationCategory;
  readFaqs: Array<FaqSection>;
  readFinancingCalculator: FinancingCalculators;
  readFinancingCalculators: PaginatedFinancingCalculators;
  readFinancingCertificate: FinancingCertificates;
  readFinancingCertificates: PaginatedFinancingCertificates;
  readFinancingLandingPage: FinancingLandingPage;
  readFinancingLandingPages: PaginatedFinancingLandingPages;
  readFinancingOverview: FinancingOverviews;
  readFinancingOverviews: PaginatedFinancingOverviews;
  readFinancingPartner: FinancingPartners;
  readFinancingPartners: PaginatedFinancingPartners;
  readGeneralAppointment: GeneralAppointment;
  readGeneralAppointmentByCountry: GeneralAppointment;
  readGeneralAppointments: PaginatedGeneralAppointment;
  readInterestRatesTable: InterestRatesTables;
  readInterestRatesTables: PaginatedInterestRatesTables;
  readLocalMarket: LocalMarket;
  readLocalMarkets: PaginatedLocalMarket;
  readOurRepresentativeExample: OurRepresentativeExamples;
  readOurRepresentativeExamples: PaginatedOurRepresentativeExamples;
  readOverviewApp: OverviewApps;
  readOverviewApps: PaginatedOverviewApps;
  readOverviewLandingPage: OverviewLandingPage;
  readOverviewLandingPages: PaginatedOverviewLandingPagesEntity;
  readOwnerBenefit: OwnerBenefits;
  readOwnerBenefits: PaginatedOwnerBenefits;
  readOwnerFunction: OwnerFunctions;
  readOwnerFunctions: PaginatedOwnerFunctions;
  readOwnerFunctionsList: Array<OwnerFunctions>;
  readOwnerLandingPage: OwnerLandingPage;
  readOwnerLandingPages: PaginatedOwnerLandingPages;
  readOwnerOverview: OwnerOverviews;
  readOwnerOverviews: PaginatedOwnerOverviews;
  readPriceAlteration: PriceAlterationEntity;
  readPriceAlterations: PaginatedPriceAlterationsEntity;
  /** Fetch properties */
  readProperties: PaginatedProperties;
  readPropertyPurchaseIntents: Array<PropertyPurchaseIntent>;
  readPurchaseIntent: PurchaseIntent;
  readSellerBenefit: SellerBenefits;
  readSellerBenefits: PaginatedSellerBenefits;
  readSellerFunction: SellerFunctions;
  readSellerFunctions: PaginatedSellerFunctions;
  readSellerFunctionsList: Array<SellerFunctions>;
  readSellerLandingPage: SellerLandingPage;
  readSellerLandingPages: PaginatedSellerLandingPages;
  readSellerOverview: SellerOverviews;
  readSellerOverviews: PaginatedSellerOverviews;
  readStepByStepBenefit: StepByStepBenefits;
  readStepByStepBenefits: PaginatedStepByStepBenefits;
  readTestimonial: Testimonials;
  readTestimonials: PaginatedTestimonials;
  readTestimonialsList: Array<Testimonials>;
  readUserAppointments: Array<TabAppointment>;
  readUserContractAppointments: Array<TabAppointment>;
  readUserPurchaseIntents: Array<DetailedPropertyPurchaseIntent>;
  readVirtualTours: Array<VirtualTourSection>;
  requestedExposeActivities?: Maybe<Array<Activity>>;
  /** Fetch single search profile */
  searchProfile?: Maybe<SearchProfile>;
  /** Fetch all properties */
  searchProfiles: Array<SearchProfile>;
  sellingProgress?: Maybe<Array<SellingSteps>>;
  sentExposeActivities?: Maybe<Array<Activity>>;
  shopContacts?: Maybe<ShopContact>;
  shopEmail?: Maybe<ShopEmail>;
  simulateContractSigned?: Maybe<Scalars['String']>;
  suggestedProperties: PaginatedSuggestedProperties;
  suggestedProperty: SuggestedProperty;
  temporaryProperty: Scalars['Boolean'];
  temporarySearchProfile: Scalars['Boolean'];
  virtualTourActivities?: Maybe<Array<Activity>>;
};

export type QueryAppointmentActivitiesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type QueryBenefitsByIdsArgs = {
  _ids?: Maybe<Array<Scalars['String']>>;
};

export type QueryCheckBrokerSettingsPermissionArgs = {
  brokerId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type QueryCheckInvitationHealthArgs = {
  input: CheckInviteHealth;
};

export type QueryCmsBlockArgs = {
  _id: Scalars['String'];
};

export type QueryCmsPageArgs = {
  _id: Scalars['String'];
};

export type QueryCmsRegionalPageArgs = {
  slug: Scalars['String'];
};

export type QueryCmsRegionalPagesArgs = {
  filter?: Maybe<CmsRegionalPageFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryCmsSectionArgs = {
  _id: Scalars['String'];
};

export type QueryCmsSectionsArgs = {
  filter?: Maybe<CmsSectionFilterInput>;
  type?: Maybe<SectionType>;
};

export type QueryContactPersonArgs = {
  propertyId: Scalars['Float'];
};

export type QueryConvertChfToEurArgs = {
  amount: Scalars['Float'];
};

export type QueryConvertEurToChfArgs = {
  amount: Scalars['Float'];
};

export type QueryCountriesArgs = {
  filter?: Maybe<CountryFilterInput>;
};

export type QueryCountryArgs = {
  id: Scalars['String'];
};

export type QueryDashboardStatusArgs = {
  propertyId: Scalars['String'];
};

export type QueryDigitalServiceStatusArgs = {
  propertyId: Scalars['String'];
};

export type QueryDisabledShopServicesArgs = {
  propertyId: Scalars['String'];
};

export type QueryDocumentsActivitiesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type QueryDownloadedExposeActivitiesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type QueryExperimentArgs = {
  id: Scalars['Int'];
};

export type QueryExperimentsArgs = {
  savedExperiments?: Maybe<Array<SavedExperimentsInput>>;
};

export type QueryExposeActivitiesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type QueryExposeDetailsArgs = {
  _id: Scalars['String'];
};

export type QueryExposeRequestsArgs = {
  financingId?: Maybe<Scalars['String']>;
  pagination?: Maybe<ExposeRequestsPaginationArgs>;
  searchProfileId?: Maybe<Scalars['String']>;
};

export type QueryFileAccessConfigArgs = {
  fileId: Scalars['String'];
};

export type QueryFinancingArgs = {
  _id: Scalars['String'];
};

export type QueryFinancingsArgs = {
  ownerId?: Maybe<Scalars['String']>;
};

export type QueryFindRelatedSearchProfilesArgs = {
  _id: Scalars['String'];
};

export type QueryFindUserByIdArgs = {
  id: Scalars['String'];
};

export type QueryGeneralMarketReportByCountryArgs = {
  country: Scalars['String'];
};

export type QueryGeneralMarketReportByIdArgs = {
  id: Scalars['String'];
};

export type QueryGetAllUsersArgs = {
  filter?: Maybe<UserFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryGetBuyersDocumentAccessStatusArgs = {
  fileId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type QueryGetExposeDocumentsArgs = {
  exposeId: Scalars['String'];
};

export type QueryGetFinancingVonPollPropertyDetailsArgs = {
  _id: Scalars['Float'];
};

export type QueryGetGeneralStatisticsArgs = {
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  propertyId: Scalars['String'];
};

export type QueryGetMarketingStatisticsArgs = {
  propertyId: Scalars['String'];
};

export type QueryGetOffersArgs = {
  input: OffersSearchInput;
};

export type QueryGetOnOfficeUserArgs = {
  addressId: Scalars['Int'];
};

export type QueryGetPropertyBrokerArgs = {
  propertyId: Scalars['String'];
};

export type QueryGetPropertyDocumentsArgs = {
  propertyId: Scalars['String'];
};

export type QueryGetPropertyExposeStatusArgs = {
  propertyId: Scalars['String'];
};

export type QueryGetPropertyFavoriteSearchProfilesListArgs = {
  _id: Scalars['String'];
};

export type QueryGetPropertyFilesArgs = {
  propertyId: Scalars['String'];
};

export type QueryGetPropertyServicesArgs = {
  propertyId: Scalars['String'];
};

export type QueryGetShopContactsArgs = {
  propertyId: Scalars['String'];
};

export type QueryHandOverActivitiesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type QueryInvitesArgs = {
  propertyId: Scalars['String'];
};

export type QueryIsochronesArgs = {
  input: IsochronesInput;
};

export type QueryLatestActivitiesArgs = {
  limit: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type QueryMarketReportArgs = {
  filters: MarketReportInput;
};

export type QueryMarketReportsArgs = {
  filter?: Maybe<MarketReportsFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryMarketingActivitiesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type QueryMarketingStatusArgs = {
  propertyId: Scalars['String'];
};

export type QueryNotaryActivitiesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type QueryOurDestinationArgs = {
  id: Scalars['String'];
};

export type QueryOverviewLpSectionArgs = {
  id: Scalars['String'];
};

export type QueryPaginatedCmsSectionsArgs = {
  filter?: Maybe<CmsSectionFilterInput>;
  pagination?: Maybe<PaginationArgs>;
  type?: Maybe<SectionType>;
};

export type QueryPhoneCallsActivitiesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type QueryPoisArgs = {
  input: PoiInput;
};

export type QueryPortalsActivitiesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type QueryPriceOfferActivitiesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type QueryPropertiesArgs = {
  excludeExternalProperties?: Maybe<Scalars['Boolean']>;
  ownerId?: Maybe<Scalars['String']>;
};

export type QueryPropertyArgs = {
  _id: Scalars['String'];
};

export type QueryPropertyAddressArgs = {
  propertyOnOfficeId: Scalars['String'];
};

export type QueryPropertyDefaultFiltersArgs = {
  filter?: Maybe<PropertyDefaultFiltersFilterInput>;
};

export type QueryPropertyOnOfficeViewArgs = {
  _id: Scalars['String'];
};

export type QueryPropertyStatisticsArgs = {
  input: PropertyStatisticsInput;
};

export type QueryPropertyValuationArgs = {
  input: ValuationInput;
};

export type QueryReadAdvantageArgs = {
  id: Scalars['String'];
};

export type QueryReadAdvantagesArgs = {
  filter?: Maybe<AdvantageFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadAppArgs = {
  id: Scalars['String'];
};

export type QueryReadAppointmentContactFormArgs = {
  id: Scalars['String'];
};

export type QueryReadAppointmentContactFormsArgs = {
  filter?: Maybe<AppointmentContactFormFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadAppointmentsArgs = {
  filter?: Maybe<CmsSectionFilterInput>;
};

export type QueryReadAppsArgs = {
  filter?: Maybe<AppFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadBannerArgs = {
  id: Scalars['String'];
};

export type QueryReadBannersArgs = {
  filter?: Maybe<BannerFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadBenefitArgs = {
  id: Scalars['String'];
};

export type QueryReadBenefitsArgs = {
  filter?: Maybe<BenefitFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadBuyerBenefitArgs = {
  id: Scalars['String'];
};

export type QueryReadBuyerBenefitsArgs = {
  filter?: Maybe<BuyerBenefitFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadBuyerFinderSettingsArgs = {
  locale: Scalars['String'];
};

export type QueryReadBuyerFinderSettingsListArgs = {
  filter?: Maybe<BuyerFinderSettingsFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadBuyerFinderTeaserArgs = {
  id: Scalars['String'];
};

export type QueryReadBuyerFinderTeasersArgs = {
  filter?: Maybe<BuyerFinderTeaserFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadBuyerFunctionArgs = {
  id: Scalars['String'];
};

export type QueryReadBuyerFunctionsArgs = {
  filter?: Maybe<BuyerFunctionFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadBuyerFunctionsListArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryReadBuyerLandingPageArgs = {
  locale: Scalars['String'];
};

export type QueryReadBuyerLandingPagesArgs = {
  filter?: Maybe<BuyerLandingPageFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadBuyerOverviewArgs = {
  id: Scalars['String'];
};

export type QueryReadBuyerOverviewsArgs = {
  filter?: Maybe<BuyerOverviewFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadCentralPhoneArgs = {
  id: Scalars['String'];
};

export type QueryReadCentralPhoneByCountryArgs = {
  country: Scalars['String'];
};

export type QueryReadCentralPhonesArgs = {
  filter?: Maybe<CentralPhoneFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadCertificateArgs = {
  id: Scalars['String'];
};

export type QueryReadCertificatesArgs = {
  filter?: Maybe<CertificateFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadCustomerAppraisalArgs = {
  id: Scalars['String'];
};

export type QueryReadCustomerAppraisalsArgs = {
  filter?: Maybe<CustomerAppraisalFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadDossierArgs = {
  _id: Scalars['String'];
};

export type QueryReadEvaluationCategoriesArgs = {
  filter?: Maybe<EvaluationCategoryFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadEvaluationCategoryArgs = {
  id: Scalars['String'];
};

export type QueryReadFaqsArgs = {
  filter?: Maybe<CmsSectionFilterInput>;
};

export type QueryReadFinancingCalculatorArgs = {
  id: Scalars['String'];
};

export type QueryReadFinancingCalculatorsArgs = {
  filter?: Maybe<FinancingCalculatorFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadFinancingCertificateArgs = {
  id: Scalars['String'];
};

export type QueryReadFinancingCertificatesArgs = {
  filter?: Maybe<FinancingCertificateFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadFinancingLandingPageArgs = {
  locale: Scalars['String'];
};

export type QueryReadFinancingLandingPagesArgs = {
  filter?: Maybe<FinancingLandingPageFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadFinancingOverviewArgs = {
  id: Scalars['String'];
};

export type QueryReadFinancingOverviewsArgs = {
  filter?: Maybe<FinancingOverviewFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadFinancingPartnerArgs = {
  id: Scalars['String'];
};

export type QueryReadFinancingPartnersArgs = {
  filter?: Maybe<FinancingPartnerFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadGeneralAppointmentArgs = {
  id: Scalars['String'];
};

export type QueryReadGeneralAppointmentByCountryArgs = {
  country: Scalars['String'];
};

export type QueryReadGeneralAppointmentsArgs = {
  filter?: Maybe<GeneralAppointmentFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadInterestRatesTableArgs = {
  id: Scalars['String'];
};

export type QueryReadInterestRatesTablesArgs = {
  filter?: Maybe<InterestRatesTableFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadLocalMarketArgs = {
  id: Scalars['String'];
};

export type QueryReadLocalMarketsArgs = {
  filter?: Maybe<LocalMarketFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadOurRepresentativeExampleArgs = {
  id: Scalars['String'];
};

export type QueryReadOurRepresentativeExamplesArgs = {
  filter?: Maybe<OurRepresentativeExampleFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadOverviewAppArgs = {
  id: Scalars['String'];
};

export type QueryReadOverviewAppsArgs = {
  filter?: Maybe<OverviewAppFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadOverviewLandingPageArgs = {
  locale: Scalars['String'];
};

export type QueryReadOverviewLandingPagesArgs = {
  filter?: Maybe<OverviewLandingPageFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadOwnerBenefitArgs = {
  id: Scalars['String'];
};

export type QueryReadOwnerBenefitsArgs = {
  filter?: Maybe<OwnerBenefitFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadOwnerFunctionArgs = {
  id: Scalars['String'];
};

export type QueryReadOwnerFunctionsArgs = {
  filter?: Maybe<OwnerFunctionFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadOwnerFunctionsListArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryReadOwnerLandingPageArgs = {
  locale: Scalars['String'];
};

export type QueryReadOwnerLandingPagesArgs = {
  filter?: Maybe<OwnerLandingPageFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadOwnerOverviewArgs = {
  id: Scalars['String'];
};

export type QueryReadOwnerOverviewsArgs = {
  filter?: Maybe<OwnerOverviewFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadPriceAlterationArgs = {
  id: Scalars['String'];
};

export type QueryReadPriceAlterationsArgs = {
  filter?: Maybe<PriceAlterationFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadPropertiesArgs = {
  calculateTotalInfo?: Maybe<Scalars['Boolean']>;
  excludeExternalProperties?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<PropertyFilterInput>;
  includeRelatedProperties?: Maybe<Scalars['Boolean']>;
  isEvaluationChanges?: Maybe<Scalars['Boolean']>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadPropertyPurchaseIntentsArgs = {
  propertyId: Scalars['String'];
};

export type QueryReadPurchaseIntentArgs = {
  id: Scalars['String'];
};

export type QueryReadSellerBenefitArgs = {
  id: Scalars['String'];
};

export type QueryReadSellerBenefitsArgs = {
  filter?: Maybe<SellerBenefitFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadSellerFunctionArgs = {
  id: Scalars['String'];
};

export type QueryReadSellerFunctionsArgs = {
  filter?: Maybe<SellerFunctionFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadSellerFunctionsListArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryReadSellerLandingPageArgs = {
  locale: Scalars['String'];
};

export type QueryReadSellerLandingPagesArgs = {
  filter?: Maybe<SellerLandingPageFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadSellerOverviewArgs = {
  id: Scalars['String'];
};

export type QueryReadSellerOverviewsArgs = {
  filter?: Maybe<SellerOverviewFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadStepByStepBenefitArgs = {
  id: Scalars['String'];
};

export type QueryReadStepByStepBenefitsArgs = {
  filter?: Maybe<StepByStepBenefitFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadTestimonialArgs = {
  id: Scalars['String'];
};

export type QueryReadTestimonialsArgs = {
  filter?: Maybe<TestimonialFilterInput>;
  pagination?: Maybe<PaginationArgs>;
};

export type QueryReadTestimonialsListArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryReadUserContractAppointmentsArgs = {
  propertyId: Scalars['String'];
};

export type QueryReadUserPurchaseIntentsArgs = {
  searchProfileId: Scalars['String'];
};

export type QueryReadVirtualToursArgs = {
  filter?: Maybe<CmsSectionFilterInput>;
};

export type QueryRequestedExposeActivitiesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type QuerySearchProfileArgs = {
  _id: Scalars['String'];
};

export type QuerySearchProfilesArgs = {
  ownerId?: Maybe<Scalars['String']>;
};

export type QuerySellingProgressArgs = {
  propertyId: Scalars['String'];
};

export type QuerySentExposeActivitiesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type QueryShopContactsArgs = {
  priceHubbleId: Scalars['String'];
};

export type QueryShopEmailArgs = {
  propertyId: Scalars['String'];
};

export type QuerySuggestedPropertiesArgs = {
  filter?: Maybe<SuggestedPropertiesFilterInput>;
  searchProfileId?: Maybe<Scalars['String']>;
};

export type QuerySuggestedPropertyArgs = {
  id: Scalars['Float'];
};

export type QueryVirtualTourActivitiesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type RpSection = {
  __typename?: 'RPSection';
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: RpSectionType;
};

export type RpSectionInput = {
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: RpSectionType;
};

export enum RpSectionType {
  Advantage = 'ADVANTAGE',
  App = 'APP',
  Appointment = 'APPOINTMENT',
  Banner = 'BANNER',
  Benefit = 'BENEFIT',
  Certificate = 'CERTIFICATE',
  Faq = 'FAQ',
  GeneralAppointment = 'GENERAL_APPOINTMENT',
  LocalMarket = 'LOCAL_MARKET',
}

export enum RateType {
  DesiredRate = 'DESIRED_RATE',
  RepaymentRate = 'REPAYMENT_RATE',
}

export type Rating = {
  __typename?: 'Rating';
  ratingValue: Scalars['Float'];
  reviewCount: Scalars['Int'];
};

export enum RatingReason {
  Buy = 'Buy',
  EstimatedResult = 'EstimatedResult',
  Other = 'Other',
  Sell = 'Sell',
}

export type Raw = {
  __typename?: 'Raw';
  raw?: Maybe<Scalars['String']>;
};

export type ReferenceLocationInput = {
  address?: Maybe<AddressInput>;
  coordinates?: Maybe<CoordinatesInput>;
};

export enum RegistrationBy {
  Email = 'email',
  EmailOnly = 'emailOnly',
  Mobile = 'mobile',
}

export type RelatedShop = {
  __typename?: 'RelatedShop';
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  messageSentAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  senderFirstName?: Maybe<Scalars['String']>;
  senderLastName?: Maybe<Scalars['String']>;
};

export type RelatedShopInput = {
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  messageSentAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  senderFirstName?: Maybe<Scalars['String']>;
  senderLastName?: Maybe<Scalars['String']>;
};

export enum ReleaseStatus {
  NotReleased = 'NotReleased',
  ReleasedForAll = 'ReleasedForAll',
  ReleasedForSomeUsers = 'ReleasedForSomeUsers',
}

export type RemoveCmsSectionInput = {
  _id: Scalars['String'];
  keys?: Maybe<Array<Scalars['String']>>;
};

export type RemoveMarketReportInput = {
  _id: Scalars['String'];
};

export type Repayment = {
  __typename?: 'Repayment';
  /** tilgungssatzInProzent */
  amortizationRateInPercent?: Maybe<Scalars['Float']>;
  /** tilgungsbeginn */
  startOfRepayment?: Maybe<Scalars['String']>;
  /** @type */
  type?: Maybe<Scalars['String']>;
};

export type RepaymentInput = {
  /** tilgungssatzInProzent */
  amortizationRateInPercent?: Maybe<Scalars['Float']>;
  /** tilgungsbeginn */
  startOfRepayment?: Maybe<Scalars['String']>;
  /** @type */
  type?: Maybe<Scalars['String']>;
};

export type RequestVerificationCodeInput = {
  accessToken: Scalars['String'];
  attributeName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  refreshToken: Scalars['String'];
  username: Scalars['String'];
};

export enum Role {
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
  User = 'User',
}

export type SaveOurDestinationsInput = {
  _id?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  destinations: Array<DestinationInput>;
  title: Scalars['String'];
};

export type SavedExperimentsInput = {
  _id: Scalars['String'];
  evaluationWidgetVersion?: Maybe<EvaluationWidgetVersion>;
  registrationBy?: Maybe<RegistrationBy>;
  selectedCase: SelectedCase;
};

export type ScheduledEmail = {
  __typename?: 'ScheduledEmail';
  emailType: ScheduledEmailType;
  plannedEmailSendingDate: Scalars['DateTime'];
};

export type ScheduledEmailInput = {
  emailType: ScheduledEmailType;
  plannedEmailSendingDate: Scalars['DateTime'];
};

export enum ScheduledEmailType {
  ForgotPasswordConfirmation = 'FORGOT_PASSWORD_CONFIRMATION',
  SignUpConfirmation = 'SIGN_UP_CONFIRMATION',
}

export type Score = {
  __typename?: 'Score';
  location?: Maybe<Scalars['Float']>;
};

export type SearchProfile = {
  __typename?: 'SearchProfile';
  _id: Scalars['String'];
  countryCode: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  disliked: Array<Scalars['String']>;
  isCreatedVia3rdPartyPortal?: Maybe<Scalars['Boolean']>;
  isNotifyNewProperties: Scalars['Boolean'];
  isNotifyNewPropertiesSummary: Scalars['Boolean'];
  isNotifyPriceChange: Scalars['Boolean'];
  latestSuggestedProperties?: Maybe<Array<LatestSuggestedProperty>>;
  liked: Array<LikedProperty>;
  marketingParameters?: Maybe<Scalars['String']>;
  /** Total number of suggested properties  */
  offersCount?: Maybe<Scalars['Int']>;
  owner: User;
  priceOffers?: Maybe<Array<PriceOffer>>;
  relatedShop?: Maybe<ShopEmail>;
  searchProfileData: SearchProfileData;
  suggestedPropertiesSummaryTemplate?: Maybe<SuggestedPropertiesSummaryTemplateEntity>;
  title: Scalars['String'];
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SearchProfileData = {
  __typename?: 'SearchProfileData';
  landAreaMax?: Maybe<Scalars['Int']>;
  landAreaMin?: Maybe<Scalars['Int']>;
  livingAreaMax?: Maybe<Scalars['Int']>;
  livingAreaMin?: Maybe<Scalars['Int']>;
  location: LocationSearchProfile;
  numberOfBathroomsMax?: Maybe<Scalars['Int']>;
  numberOfBathroomsMin?: Maybe<Scalars['Int']>;
  numberOfRoomsMax?: Maybe<Scalars['Int']>;
  numberOfRoomsMin?: Maybe<Scalars['Int']>;
  propertyCode: PropertyCode;
  purchasePriceMax: Scalars['Float'];
  purchasePriceMin: Scalars['Float'];
  radius: Scalars['Int'];
};

export type SearchProfileInput = {
  landAreaMax?: Maybe<Scalars['Int']>;
  landAreaMin?: Maybe<Scalars['Int']>;
  livingAreaMax?: Maybe<Scalars['Int']>;
  livingAreaMin?: Maybe<Scalars['Int']>;
  location: LocationSearchProfileInput;
  numberOfBathroomsMax?: Maybe<Scalars['Int']>;
  numberOfBathroomsMin?: Maybe<Scalars['Int']>;
  numberOfRoomsMax?: Maybe<Scalars['Int']>;
  numberOfRoomsMin?: Maybe<Scalars['Int']>;
  propertyCode: PropertyCode;
  purchasePriceMax: Scalars['Float'];
  purchasePriceMin: Scalars['Float'];
  radius: Scalars['Int'];
};

export enum SearchProgress {
  BudgetCheckOnly = 'BUDGET_CHECK_ONLY',
  InPurchaseNegotiations = 'IN_PURCHASE_NEGOTIATIONS',
  PropertyInspected = 'PROPERTY_INSPECTED',
  PropertyNotVisited = 'PROPERTY_NOT_VISITED',
}

export enum SectionType {
  Appointment = 'APPOINTMENT',
  Benefit = 'BENEFIT',
  Content = 'CONTENT',
  Faq = 'FAQ',
  OwnerSettings = 'OWNER_SETTINGS',
  Video = 'VIDEO',
  VirtualTour = 'VIRTUAL_TOUR',
}

export enum SelectedCase {
  A = 'A',
  B = 'B',
}

export type SellerBenefitFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type SellerBenefitInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  icon1?: Maybe<Scalars['Upload']>;
  icon1Alt?: Maybe<Scalars['String']>;
  icon1Filename?: Maybe<Scalars['String']>;
  icon1Key?: Maybe<Scalars['String']>;
  icon1Url?: Maybe<Scalars['String']>;
  icon2?: Maybe<Scalars['Upload']>;
  icon2Alt?: Maybe<Scalars['String']>;
  icon2Filename?: Maybe<Scalars['String']>;
  icon2Key?: Maybe<Scalars['String']>;
  icon2Url?: Maybe<Scalars['String']>;
  icon3?: Maybe<Scalars['Upload']>;
  icon3Alt?: Maybe<Scalars['String']>;
  icon3Filename?: Maybe<Scalars['String']>;
  icon3Key?: Maybe<Scalars['String']>;
  icon3Url?: Maybe<Scalars['String']>;
  icon4?: Maybe<Scalars['Upload']>;
  icon4Alt?: Maybe<Scalars['String']>;
  icon4Filename?: Maybe<Scalars['String']>;
  icon4Key?: Maybe<Scalars['String']>;
  icon4Url?: Maybe<Scalars['String']>;
  icon5?: Maybe<Scalars['Upload']>;
  icon5Alt?: Maybe<Scalars['String']>;
  icon5Filename?: Maybe<Scalars['String']>;
  icon5Key?: Maybe<Scalars['String']>;
  icon5Url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  propertyEvaluateLabel?: Maybe<Scalars['String']>;
  text1?: Maybe<Scalars['String']>;
  text2?: Maybe<Scalars['String']>;
  text3?: Maybe<Scalars['String']>;
  text4?: Maybe<Scalars['String']>;
  text5?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SellerBenefits = {
  __typename?: 'SellerBenefits';
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  icon1Alt?: Maybe<Scalars['String']>;
  icon1Key?: Maybe<Scalars['String']>;
  icon1Url?: Maybe<Scalars['String']>;
  icon2Alt?: Maybe<Scalars['String']>;
  icon2Key?: Maybe<Scalars['String']>;
  icon2Url?: Maybe<Scalars['String']>;
  icon3Alt?: Maybe<Scalars['String']>;
  icon3Key?: Maybe<Scalars['String']>;
  icon3Url?: Maybe<Scalars['String']>;
  icon4Alt?: Maybe<Scalars['String']>;
  icon4Key?: Maybe<Scalars['String']>;
  icon4Url?: Maybe<Scalars['String']>;
  icon5Alt?: Maybe<Scalars['String']>;
  icon5Key?: Maybe<Scalars['String']>;
  icon5Url?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  propertyEvaluateLabel?: Maybe<Scalars['String']>;
  text1?: Maybe<Scalars['String']>;
  text2?: Maybe<Scalars['String']>;
  text3?: Maybe<Scalars['String']>;
  text4?: Maybe<Scalars['String']>;
  text5?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SellerBenefitsInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  icon1Alt?: Maybe<Scalars['String']>;
  icon1Key?: Maybe<Scalars['String']>;
  icon1Url?: Maybe<Scalars['String']>;
  icon2Alt?: Maybe<Scalars['String']>;
  icon2Key?: Maybe<Scalars['String']>;
  icon2Url?: Maybe<Scalars['String']>;
  icon3Alt?: Maybe<Scalars['String']>;
  icon3Key?: Maybe<Scalars['String']>;
  icon3Url?: Maybe<Scalars['String']>;
  icon4Alt?: Maybe<Scalars['String']>;
  icon4Key?: Maybe<Scalars['String']>;
  icon4Url?: Maybe<Scalars['String']>;
  icon5Alt?: Maybe<Scalars['String']>;
  icon5Key?: Maybe<Scalars['String']>;
  icon5Url?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  propertyEvaluateLabel?: Maybe<Scalars['String']>;
  text1?: Maybe<Scalars['String']>;
  text2?: Maybe<Scalars['String']>;
  text3?: Maybe<Scalars['String']>;
  text4?: Maybe<Scalars['String']>;
  text5?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SellerFunctionFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type SellerFunctionInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconFilename?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SellerFunctions = {
  __typename?: 'SellerFunctions';
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SellerFunctionsInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  iconAlt?: Maybe<Scalars['String']>;
  iconKey?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SellerLpSection = {
  __typename?: 'SellerLPSection';
  _id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: SellerLpSectionType;
};

export enum SellerLpSectionType {
  Appointment = 'APPOINTMENT',
  Benefit = 'BENEFIT',
  Faq = 'FAQ',
  SellerBenefit = 'SELLER_BENEFIT',
  SellerFunction = 'SELLER_FUNCTION',
}

export type SellerLandingPage = {
  __typename?: 'SellerLandingPage';
  _id?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Array<Scalars['String']>>;
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sections?: Maybe<Array<SellerLpSection>>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SellerLandingPageFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type SellerLandingPageInput = {
  _id?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Array<Scalars['String']>>;
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sections?: Maybe<Array<SellerLpSectionInput>>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SellerLpSectionInput = {
  _id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Int'];
  recordIds: Array<Scalars['String']>;
  title: Scalars['String'];
  type: SellerLpSectionType;
};

export type SellerOverviewFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type SellerOverviewInput = {
  CTALabel?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SellerOverviews = {
  __typename?: 'SellerOverviews';
  CTALabel?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SellerOverviewsInput = {
  CTALabel?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum SellingSteps {
  DocumentsUploaded = 'DocumentsUploaded',
  ExposeApproved = 'ExposeApproved',
  ImagesUploaded = 'ImagesUploaded',
  MarketingServicesSelected = 'MarketingServicesSelected',
  PropertyPlanUploaded = 'PropertyPlanUploaded',
}

export type SendFeedbackInput = {
  rating: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

export type SendFeedbackOfOwnerPortalInput = {
  _id: Scalars['String'];
  numericValue?: Maybe<Scalars['String']>;
  priceHubbleId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type SendVonPollFinanceInput = {
  feasibilityRating: Scalars['Float'];
  financingVariant: FinancingVariantInput;
  isCertificate: Scalars['Boolean'];
  marketingLink: Scalars['String'];
  personalFinancing: PersonalFinancingInput;
  propertyId?: Maybe<Scalars['String']>;
  userButtonLink: Scalars['String'];
};

export type Services = {
  __typename?: 'Services';
  DigitalStaging: Scalars['Boolean'];
  DronePhotos: Scalars['Boolean'];
  EnergyCertificate: Scalars['Boolean'];
  FloorPlans: Scalars['Boolean'];
  HighCirculation: Scalars['Boolean'];
  Magazines: Scalars['Boolean'];
  OfficialInformation: Scalars['Boolean'];
  Photos: Scalars['Boolean'];
  PremiumPlacement: Scalars['Boolean'];
  RealEstatePortals: Scalars['Boolean'];
  SecretSale: Scalars['Boolean'];
  SocialMedia: Scalars['Boolean'];
  VirtualTours: Scalars['Boolean'];
};

export type ServicesInput = {
  DigitalStaging: Scalars['Boolean'];
  DronePhotos: Scalars['Boolean'];
  EnergyCertificate: Scalars['Boolean'];
  FloorPlans: Scalars['Boolean'];
  HighCirculation: Scalars['Boolean'];
  Magazines: Scalars['Boolean'];
  OfficialInformation: Scalars['Boolean'];
  Photos: Scalars['Boolean'];
  PremiumPlacement: Scalars['Boolean'];
  RealEstatePortals: Scalars['Boolean'];
  SecretSale: Scalars['Boolean'];
  SocialMedia: Scalars['Boolean'];
  VirtualTours: Scalars['Boolean'];
};

export type Shop = {
  __typename?: 'Shop';
  city: Scalars['String'];
  email: Scalars['String'];
  firma: Scalars['String'];
  homepage: Scalars['String'];
  housenumber: Scalars['String'];
  phone: Scalars['String'];
  postcode: Scalars['String'];
  street: Scalars['String'];
};

export type ShopContact = {
  __typename?: 'ShopContact';
  address: Scalars['String'];
  alias: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  telephone: Scalars['String'];
  zip: Scalars['String'];
};

export type ShopEmail = {
  __typename?: 'ShopEmail';
  email: Scalars['String'];
  name: Scalars['String'];
};

export type ShopEmailInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type Shopping = {
  __typename?: 'Shopping';
  convenience_store?: Maybe<Array<Poi>>;
  supermarket?: Maybe<Array<Poi>>;
};

export type SocioEconomicsEntity = {
  __typename?: 'SocioEconomicsEntity';
  officialId?: Maybe<Scalars['String']>;
  officialName: Scalars['String'];
  type: SocioEconomicsType;
  years?: Maybe<Array<YearEntity>>;
};

export enum SocioEconomicsType {
  AgeGroups = 'age_groups',
  BirthRate = 'birth_rate',
  CrimeRate = 'crime_rate',
  EducationLevels = 'education_levels',
  EmployedPopulation = 'employed_population',
  HouseholdStructure = 'household_structure',
  NetMigrationRate = 'net_migration_rate',
  PopulationSize = 'population_size',
  PopulationSizeTrend = 'population_size_trend',
  ProfessionalDegrees = 'professional_degrees',
}

export type SocioEconomicsValueEntity = {
  __typename?: 'SocioEconomicsValueEntity';
  absoluteValue?: Maybe<Scalars['Float']>;
  relativeValue?: Maybe<Scalars['Float']>;
  subtype?: Maybe<Scalars['String']>;
};

export type Statistic = {
  __typename?: 'Statistic';
  metric: StatisticMetric;
  parameters?: Maybe<Parameters>;
  type: StatisticType;
  value?: Maybe<Scalars['Float']>;
};

export enum StatisticMetric {
  DaysOnMarket = 'days_on_market',
  LivingArea = 'living_area',
  NumberOfRooms = 'number_of_rooms',
  RentGross = 'rent_gross',
  RentGrossPerSquareMeter = 'rent_gross_per_square_meter',
  RentNet = 'rent_net',
  RentNetPerSquareMeter = 'rent_net_per_square_meter',
  SalePrice = 'sale_price',
  SalePricePerSquareMeter = 'sale_price_per_square_meter',
}

export enum StatisticType {
  Count = 'count',
  Mean = 'mean',
  Percentile = 'percentile',
  PercentileRank = 'percentile_rank',
}

export type StatisticsInput = {
  metric: StatisticMetric;
  parameters?: Maybe<StatisticsParameteresInput>;
  type: StatisticType;
};

export type StatisticsParameteresInput = {
  percentileRank?: Maybe<Scalars['Int']>;
  percentileValue?: Maybe<Scalars['Int']>;
};

export type Status = {
  __typename?: 'Status';
  code?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

export type StepByStepBenefitFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type StepByStepBenefitInput = {
  CTALabel?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  step1Icon?: Maybe<Scalars['Upload']>;
  step1IconAlt?: Maybe<Scalars['String']>;
  step1IconFilename?: Maybe<Scalars['String']>;
  step1IconKey?: Maybe<Scalars['String']>;
  step1IconUrl?: Maybe<Scalars['String']>;
  step1Text?: Maybe<Scalars['String']>;
  step1Title?: Maybe<Scalars['String']>;
  step2Icon?: Maybe<Scalars['Upload']>;
  step2IconAlt?: Maybe<Scalars['String']>;
  step2IconFilename?: Maybe<Scalars['String']>;
  step2IconKey?: Maybe<Scalars['String']>;
  step2IconUrl?: Maybe<Scalars['String']>;
  step2Text?: Maybe<Scalars['String']>;
  step2Title?: Maybe<Scalars['String']>;
  step3Icon?: Maybe<Scalars['Upload']>;
  step3IconAlt?: Maybe<Scalars['String']>;
  step3IconFilename?: Maybe<Scalars['String']>;
  step3IconKey?: Maybe<Scalars['String']>;
  step3IconUrl?: Maybe<Scalars['String']>;
  step3Text?: Maybe<Scalars['String']>;
  step3Title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type StepByStepBenefits = {
  __typename?: 'StepByStepBenefits';
  CTALabel?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  step1IconAlt?: Maybe<Scalars['String']>;
  step1IconKey?: Maybe<Scalars['String']>;
  step1IconUrl?: Maybe<Scalars['String']>;
  step1Text?: Maybe<Scalars['String']>;
  step1Title?: Maybe<Scalars['String']>;
  step2IconAlt?: Maybe<Scalars['String']>;
  step2IconKey?: Maybe<Scalars['String']>;
  step2IconUrl?: Maybe<Scalars['String']>;
  step2Text?: Maybe<Scalars['String']>;
  step2Title?: Maybe<Scalars['String']>;
  step3IconAlt?: Maybe<Scalars['String']>;
  step3IconKey?: Maybe<Scalars['String']>;
  step3IconUrl?: Maybe<Scalars['String']>;
  step3Text?: Maybe<Scalars['String']>;
  step3Title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type StepByStepBenefitsInput = {
  CTALabel?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  step1IconAlt?: Maybe<Scalars['String']>;
  step1IconKey?: Maybe<Scalars['String']>;
  step1IconUrl?: Maybe<Scalars['String']>;
  step1Text?: Maybe<Scalars['String']>;
  step1Title?: Maybe<Scalars['String']>;
  step2IconAlt?: Maybe<Scalars['String']>;
  step2IconKey?: Maybe<Scalars['String']>;
  step2IconUrl?: Maybe<Scalars['String']>;
  step2Text?: Maybe<Scalars['String']>;
  step2Title?: Maybe<Scalars['String']>;
  step3IconAlt?: Maybe<Scalars['String']>;
  step3IconKey?: Maybe<Scalars['String']>;
  step3IconUrl?: Maybe<Scalars['String']>;
  step3Text?: Maybe<Scalars['String']>;
  step3Title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SuggestedPropertiesFilterInput = {
  countryCode?: Maybe<Scalars['String']>;
  favouritePropertiesIds?: Maybe<Array<Scalars['String']>>;
  financingData?: Maybe<SuggestedPropertiesFinancingFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderSuggestedPropertiesByCriterion>;
  searchProfileData?: Maybe<SearchProfileInput>;
  unsuitablePropertiesIds?: Maybe<Array<Scalars['String']>>;
};

export type SuggestedPropertiesFinancingFilterInput = {
  address: AddressFinancingInput;
  coordinates: CoordinatesInput;
  /** Calculated maximum purchase price in euros */
  maximalPurchasePrice?: Maybe<Scalars['Float']>;
  propertyType: FinancingPropertyType;
  /** Radius */
  radius?: Maybe<Scalars['Float']>;
};

export type SuggestedPropertiesSummaryInput = {
  firstName: Scalars['String'];
  isCH: Scalars['Boolean'];
  lastName: Scalars['String'];
  link: Scalars['String'];
  linkNotifications: Scalars['String'];
  linkNotificationsDelete: Scalars['String'];
  properties: Array<SummaryPropertyInput>;
  searchProfileAddress: Scalars['String'];
  searchProfileFullTitle: Scalars['String'];
  searchProfileRadius: Scalars['String'];
  searchProfileTitle: Scalars['String'];
  userEmail: Scalars['String'];
};

export type SuggestedPropertiesSummaryTemplateEntity = {
  __typename?: 'SuggestedPropertiesSummaryTemplateEntity';
  firstName: Scalars['String'];
  isCH: Scalars['Boolean'];
  lastName: Scalars['String'];
  link: Scalars['String'];
  linkNotifications: Scalars['String'];
  linkNotificationsDelete: Scalars['String'];
  properties: Array<SummaryProperty>;
  searchProfileAddress: Scalars['String'];
  searchProfileFullTitle: Scalars['String'];
  searchProfileRadius: Scalars['String'];
  searchProfileTitle: Scalars['String'];
  userEmail: Scalars['String'];
};

export type SuggestedProperty = {
  __typename?: 'SuggestedProperty';
  address?: Maybe<SuggestedPropertyAddress>;
  area?: Maybe<SuggestedPropertyArea>;
  city: Scalars['String'];
  condition?: Maybe<SuggestedPropertyCondition>;
  country?: Maybe<CountryCodeFromVonPollDbEnum>;
  currency: Scalars['String'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
  energyCertificate?: Maybe<SuggestedPropertyEnergyCertificateEntity>;
  energyEquipment?: Maybe<SuggestedPropertyEnergyEquipmentEntity>;
  equipment?: Maybe<SuggestedPropertyEquipment>;
  equipmentDescription?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  image: Array<SuggestedPropertyAttachment>;
  internalId: Scalars['String'];
  landArea: Scalars['Float'];
  language: Scalars['String'];
  latitude: Scalars['Float'];
  livingArea: Scalars['Float'];
  locationDescription?: Maybe<Scalars['String']>;
  longitude: Scalars['Float'];
  numberOfRooms: Scalars['Float'];
  otherInformation?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  price: Scalars['Float'];
  propertyId: Scalars['Float'];
  regionalAddition?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title: Scalars['String'];
  tour360Link?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type SuggestedPropertyAddress = {
  __typename?: 'SuggestedPropertyAddress';
  city: Scalars['String'];
  floor: Scalars['Int'];
  houseNumber: Scalars['String'];
  id: Scalars['Float'];
  numberOfFloors?: Maybe<Scalars['Int']>;
  postCode: Scalars['String'];
  street: Scalars['String'];
};

export type SuggestedPropertyAddressInput = {
  city: Scalars['String'];
  floor: Scalars['Int'];
  houseNumber: Scalars['String'];
  id: Scalars['Float'];
  numberOfFloors?: Maybe<Scalars['Int']>;
  postCode: Scalars['String'];
  street: Scalars['String'];
};

export type SuggestedPropertyArea = {
  __typename?: 'SuggestedPropertyArea';
  balconyArea: Scalars['Float'];
  id: Scalars['Float'];
  numberOfBathrooms: Scalars['Float'];
  numberOfBedrooms: Scalars['Float'];
  numberOfParkingSpaces: Scalars['Float'];
  numberOfRooms: Scalars['Float'];
};

export type SuggestedPropertyAreaInput = {
  balconyArea: Scalars['Float'];
  id: Scalars['Float'];
  numberOfBathrooms: Scalars['Float'];
  numberOfBedrooms: Scalars['Float'];
  numberOfParkingSpaces: Scalars['Float'];
  numberOfRooms: Scalars['Float'];
};

export type SuggestedPropertyAttachment = {
  __typename?: 'SuggestedPropertyAttachment';
  alt: Scalars['String'];
  group?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  width?: Maybe<Scalars['Float']>;
};

export type SuggestedPropertyAttachmentInput = {
  alt: Scalars['String'];
  group?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  width?: Maybe<Scalars['Float']>;
};

export type SuggestedPropertyCondition = {
  __typename?: 'SuggestedPropertyCondition';
  buildingYear?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  modernizationYear?: Maybe<Scalars['String']>;
};

export type SuggestedPropertyConditionInput = {
  buildingYear?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  modernizationYear?: Maybe<Scalars['String']>;
};

export type SuggestedPropertyEnergyCertificateEntity = {
  __typename?: 'SuggestedPropertyEnergyCertificateEntity';
  energyCertificate: Scalars['String'];
  energyCertificateValidUntil: Scalars['String'];
  energyEfficiencyClass?: Maybe<Scalars['String']>;
  energySource: Scalars['String'];
  finalEnergyDemand: Scalars['String'];
  id: Scalars['Float'];
};

export type SuggestedPropertyEnergyCertificateInput = {
  energyCertificate: Scalars['String'];
  energyCertificateValidUntil: Scalars['String'];
  energyEfficiencyClass?: Maybe<Scalars['String']>;
  energySource: Scalars['String'];
  finalEnergyDemand: Scalars['String'];
  id: Scalars['Float'];
};

export type SuggestedPropertyEnergyEquipmentEntity = {
  __typename?: 'SuggestedPropertyEnergyEquipmentEntity';
  additionalDecentralizedHotWater: Scalars['Int'];
  airWaterHeatPump: Scalars['Int'];
  alternative: Scalars['Int'];
  centralHeating: Scalars['Int'];
  combinedHeatAndPowerPlant: Scalars['Int'];
  districtHeating: Scalars['Int'];
  electro: Scalars['Int'];
  floorHeating: Scalars['Int'];
  furnaceHeating: Scalars['Int'];
  gas: Scalars['Int'];
  geothermalEnergy: Scalars['Int'];
  id: Scalars['Float'];
  oil: Scalars['Int'];
  pellet: Scalars['Int'];
  solar: Scalars['Int'];
  underfloorHeating: Scalars['Int'];
};

export type SuggestedPropertyEnergyEquipmentInput = {
  additionalDecentralizedHotWater: Scalars['Int'];
  airWaterHeatPump: Scalars['Int'];
  alternative: Scalars['Int'];
  centralHeating: Scalars['Int'];
  combinedHeatAndPowerPlant: Scalars['Int'];
  districtHeating: Scalars['Int'];
  electro: Scalars['Int'];
  floorHeating: Scalars['Int'];
  furnaceHeating: Scalars['Int'];
  gas: Scalars['Int'];
  geothermalEnergy: Scalars['Int'];
  id: Scalars['Float'];
  oil: Scalars['Int'];
  pellet: Scalars['Int'];
  solar: Scalars['Int'];
  underfloorHeating: Scalars['Int'];
};

export type SuggestedPropertyEquipment = {
  __typename?: 'SuggestedPropertyEquipment';
  id: Scalars['Float'];
  numberOfDuplexParkingSpaces?: Maybe<Scalars['Int']>;
  numberOfGarages: Scalars['Int'];
  numberOfOtherParkingSpaces?: Maybe<Scalars['Int']>;
  numberOfParkingSpace: Scalars['Int'];
  numberOfUndergroundGarages?: Maybe<Scalars['Int']>;
  provision: Scalars['String'];
  rentalIncomeIs: Scalars['String'];
  rentalIncomeShould: Scalars['String'];
};

export type SuggestedPropertyEquipmentInput = {
  id: Scalars['Float'];
  numberOfDuplexParkingSpaces?: Maybe<Scalars['Int']>;
  numberOfGarages: Scalars['Int'];
  numberOfOtherParkingSpaces?: Maybe<Scalars['Int']>;
  numberOfParkingSpace: Scalars['Int'];
  numberOfUndergroundGarages?: Maybe<Scalars['Int']>;
  provision: Scalars['String'];
  rentalIncomeIs: Scalars['String'];
  rentalIncomeShould: Scalars['String'];
};

export type SuggestedPropertyInput = {
  address?: Maybe<SuggestedPropertyAddressInput>;
  area?: Maybe<SuggestedPropertyAreaInput>;
  city: Scalars['String'];
  condition?: Maybe<SuggestedPropertyConditionInput>;
  country?: Maybe<CountryCodeFromVonPollDbEnum>;
  currency: Scalars['String'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
  energyCertificate?: Maybe<SuggestedPropertyEnergyCertificateInput>;
  energyEquipment?: Maybe<SuggestedPropertyEnergyEquipmentInput>;
  equipment?: Maybe<SuggestedPropertyEquipmentInput>;
  equipmentDescription?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  image: Array<SuggestedPropertyAttachmentInput>;
  internalId: Scalars['String'];
  landArea: Scalars['Float'];
  language: Scalars['String'];
  latitude: Scalars['Float'];
  livingArea: Scalars['Float'];
  locationDescription?: Maybe<Scalars['String']>;
  longitude: Scalars['Float'];
  numberOfRooms: Scalars['Float'];
  otherInformation?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  price: Scalars['Float'];
  propertyId: Scalars['Float'];
  regionalAddition?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title: Scalars['String'];
  tour360Link?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type SummaryProperty = {
  __typename?: 'SummaryProperty';
  address: Scalars['String'];
  id: Scalars['String'];
  landArea?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  linkVonPoll: Scalars['String'];
  livingArea?: Maybe<Scalars['String']>;
  numberOfRooms?: Maybe<Scalars['String']>;
  photoSrc: Scalars['String'];
  price: Scalars['String'];
  pricePerMeter: Scalars['String'];
  title: Scalars['String'];
};

export type SummaryPropertyInput = {
  address: Scalars['String'];
  id: Scalars['String'];
  landArea?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  linkVonPoll: Scalars['String'];
  livingArea?: Maybe<Scalars['String']>;
  numberOfRooms?: Maybe<Scalars['String']>;
  photoSrc: Scalars['String'];
  price: Scalars['String'];
  pricePerMeter: Scalars['String'];
  title: Scalars['String'];
};

export type TabAppointment = {
  __typename?: 'TabAppointment';
  id: Scalars['String'];
  property?: Maybe<SuggestedProperty>;
  rescheduleUntil?: Maybe<Scalars['String']>;
  reschedulingSecret?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  title: Scalars['String'];
  userId: Scalars['String'];
  zoomVideoLink?: Maybe<Scalars['String']>;
};

export type Task = {
  __typename?: 'Task';
  elements: TaskElements;
  id: Scalars['Int'];
  type: Scalars['String'];
};

export type TaskElements = {
  __typename?: 'TaskElements';
  Status: Scalars['String'];
  Verantwortung: Scalars['String'];
  relatedAddressId: Array<Scalars['String']>;
  relatedEstateId: Array<Scalars['String']>;
};

export type TemporaryProperty = {
  __typename?: 'TemporaryProperty';
  _id: Scalars['String'];
  countryCode: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  dealType: DealType;
  marketingParameters?: Maybe<Scalars['String']>;
  otherRatingReason?: Maybe<Scalars['String']>;
  owner: User;
  propertyData: PropertyData;
  ratingReason?: Maybe<RatingReason>;
  timeHorizont?: Maybe<TimeHorizont>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
  valuationType: ValuationType;
};

export type TemporarySearchProfile = {
  __typename?: 'TemporarySearchProfile';
  _id: Scalars['String'];
  countryCode: Scalars['String'];
  marketingParameters?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  searchProfileData: SearchProfileData;
  title: Scalars['String'];
};

export type TestimonialFilterInput = {
  _ids?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type TestimonialInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Testimonials = {
  __typename?: 'Testimonials';
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type TestimonialsInput = {
  _id?: Maybe<Scalars['String']>;
  expandedText?: Maybe<Scalars['String']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum TimeHorizont {
  MoreThan12Months = 'MoreThan12Months',
  UpTo3Months = 'UpTo3Months',
  UpTo12Months = 'UpTo12Months',
}

export type Transport = {
  __typename?: 'Transport';
  airport?: Maybe<Array<Poi>>;
  bus?: Maybe<Array<Poi>>;
  highway_ramp?: Maybe<Array<Poi>>;
  subway?: Maybe<Array<Poi>>;
  train?: Maybe<Array<Poi>>;
  tram?: Maybe<Array<Poi>>;
};

export type UpdateAddressInput = {
  city?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type UpdateAddressSearchProfileInput = {
  city?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  placeName?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
};

export type UpdateAdminArgs = {
  _id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export type UpdateAppointmentSectionInput = {
  _id: Scalars['String'];
  altText?: Maybe<Scalars['String']>;
  brokerPosition?: Maybe<Scalars['String']>;
  brokerQualification?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  legalPopUpLabel?: Maybe<Scalars['String']>;
  legalPopUpText?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  timifyId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UpdateCmsBlockInput = {
  _id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  sections?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<BlockType>;
};

export type UpdateCmsPageInput = {
  _id: Scalars['String'];
  blocks?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  representativeName?: Maybe<Scalars['String']>;
};

export type UpdateCmsRegionalPageInput = {
  _id: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  bookingButtonLabel?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Array<Scalars['String']>>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  personName?: Maybe<Scalars['String']>;
  personPosition?: Maybe<Scalars['String']>;
  personText?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  routeButtonLabel?: Maybe<Scalars['String']>;
  routeButtonLink?: Maybe<Scalars['String']>;
  sections?: Maybe<Array<RpSectionInput>>;
  slug?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UpdateContentSectionInput = {
  _id: Scalars['String'];
  contentHTML: Scalars['String'];
  scriptId?: Maybe<Scalars['String']>;
  scriptSrc?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type UpdateCoordinatesInput = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type UpdateCountryInput = {
  _id: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateExperimentInput = {
  _id: Scalars['String'];
  caseAWeight: Scalars['Float'];
  caseBWeight: Scalars['Float'];
  evaluationWidgetVersion: Scalars['String'];
  isActive: Scalars['Boolean'];
  registrationBy: Scalars['String'];
};

export type UpdateFaqSectionInput = {
  _id: Scalars['String'];
  expandedText: Scalars['String'];
  order: Scalars['Int'];
  title: Scalars['String'];
};

export type UpdateFinancingDataInput = {
  countryCode: Scalars['String'];
  financingType: FinancingType;
  title: Scalars['String'];
};

export type UpdateFinancingInput = {
  _id: Scalars['String'];
  financingData?: Maybe<UpdateFinancingDataInput>;
  financingVariants?: Maybe<Array<UpdateFinancingVariantInput>>;
  marketingParameters?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  searchProfileId?: Maybe<Scalars['String']>;
  vonPollOnOfficeData?: Maybe<VonPollOnOfficeDataInput>;
  vonPollPropertyData?: Maybe<UpdateVonPollPropertyDataInput>;
};

export type UpdateFinancingPropositionsInput = {
  _id: Scalars['String'];
  variantIndex: Scalars['Float'];
};

export type UpdateFinancingVariantDataInput = {
  brokerFeePercent?: Maybe<Scalars['Float']>;
  brokerageFee?: Maybe<Scalars['Boolean']>;
  buildingCosts?: Maybe<Scalars['Float']>;
  buildingYear?: Maybe<Scalars['Float']>;
  debitRateCommitment: Scalars['String'];
  desiredLoanAmount?: Maybe<Scalars['Float']>;
  desiredRate: Scalars['Float'];
  equityCapital?: Maybe<Scalars['Float']>;
  estimatedRemainingDebt?: Maybe<Scalars['Float']>;
  federalState?: Maybe<Scalars['String']>;
  index: Scalars['Float'];
  landPrice?: Maybe<Scalars['Float']>;
  landRegisterEntry: Scalars['Float'];
  landTax?: Maybe<Scalars['Float']>;
  livingArea?: Maybe<Scalars['Float']>;
  location: LocationFinancingInput;
  monthlyRate?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  notary: Scalars['Float'];
  payoutDate?: Maybe<Scalars['DateTime']>;
  propertyType: FinancingPropertyType;
  propertyUse: PropertyUse;
  propertyValue?: Maybe<Scalars['Float']>;
  purchasePrice?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
  rateType: RateType;
  reason: FinancingReason;
  rentalIncomePerMonth?: Maybe<Scalars['Float']>;
  repaymentRate?: Maybe<Scalars['Float']>;
  searchProgress?: Maybe<SearchProgress>;
};

export type UpdateFinancingVariantInput = {
  financingPropositionEmail?: Maybe<FinancingPropositionInput>;
  financingPropositionMaxPurchasePrice?: Maybe<FinancingPropositionInput>;
  financingPropositions: Array<FinancingPropositionInput>;
  financingSuggestions: FinancingSuggestionsInput;
  financingVariantData: UpdateFinancingVariantDataInput;
  lastDateOfInterestRatesNotification?: Maybe<Scalars['DateTime']>;
  personalFinancing: PersonalFinancingInput;
  propositionsLastSync: Scalars['DateTime'];
};

export type UpdateFinancingVariantNameInput = {
  financingId: Scalars['String'];
  index: Scalars['Float'];
  name: Scalars['String'];
};

export type UpdateGeneralMarketReportInput = {
  _id: Scalars['String'];
  country: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
  filename?: Maybe<Scalars['String']>;
};

export type UpdateInviteInput = {
  _id?: Maybe<Scalars['String']>;
  invitationPrivileges: InvitationPrivileges;
};

export type UpdateLocationInput = {
  address?: Maybe<UpdateAddressInput>;
  coordinates?: Maybe<UpdateCoordinatesInput>;
  geoJson?: Maybe<GeoJsonInput>;
};

export type UpdateLocationSearchProfileInput = {
  address?: Maybe<UpdateAddressSearchProfileInput>;
  coordinates?: Maybe<UpdateCoordinatesInput>;
  geoJson?: Maybe<GeoJsonSearchProfileInput>;
};

export type UpdateMarketReportInput = {
  _id: Scalars['String'];
  cities?: Maybe<Array<Scalars['String']>>;
  country: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
  filename?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type UpdateOwnerSettingsSectionInput = {
  _id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type UpdatePriceOffersInput = {
  priceOffer: PriceOfferInput;
  searchProfileId: Scalars['String'];
};

export type UpdatePropertyDataInput = {
  annualRentIncome?: Maybe<Scalars['Int']>;
  balconyArea?: Maybe<Scalars['Int']>;
  buildingYear?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  condition?: Maybe<PropertyConditionInput>;
  energyLabel?: Maybe<Scalars['String']>;
  floorNumber?: Maybe<Scalars['Int']>;
  hasPool?: Maybe<Scalars['Boolean']>;
  hasSauna?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  landArea?: Maybe<Scalars['Int']>;
  livingArea?: Maybe<Scalars['Float']>;
  location?: Maybe<UpdateLocationInput>;
  numberOfBathrooms?: Maybe<Scalars['Int']>;
  numberOfFloorsInBuilding?: Maybe<Scalars['Int']>;
  numberOfIndoorParkingSpaces?: Maybe<Scalars['Int']>;
  numberOfOutdoorParkingSpaces?: Maybe<Scalars['Int']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  numberOfUnits?: Maybe<Scalars['Int']>;
  parkingSpaces?: Maybe<ParkingSpacesInput>;
  propertyType?: Maybe<PropertyTypeInput>;
  quality?: Maybe<PropertyQualityInput>;
  renovationYear?: Maybe<Scalars['Int']>;
};

export type UpdatePropertyFavoriteSpListInput = {
  _id: Scalars['String'];
  favoriteSearchProfiles: Array<Scalars['String']>;
};

export type UpdatePropertyInput = {
  _id: Scalars['String'];
  buildingProjects?: Maybe<BuildingProjectsInput>;
  completedSellingSteps?: Maybe<Array<SellingSteps>>;
  countryCode?: Maybe<Scalars['String']>;
  dealType?: Maybe<DealType>;
  description?: Maybe<PropertyDescriptionInput>;
  disabledServices?: Maybe<Array<Scalars['String']>>;
  emailBatches?: Maybe<PropertyEmailBatchesInput>;
  energyCertificate?: Maybe<OnOfficeEnergyCertificateInput>;
  /** Expose approved At */
  exposeApprovedAt?: Maybe<Scalars['DateTime']>;
  exposeChangesHistory?: Maybe<Array<Scalars['String']>>;
  /** Expose created At */
  exposeCreatedAt?: Maybe<Scalars['DateTime']>;
  exposeStatus?: Maybe<OnOfficeExposeStatus>;
  /** Expose updated At */
  exposeUpdatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  favoriteSearchProfiles?: Maybe<Array<Scalars['String']>>;
  /** Seller first registration reminder */
  firstReminderSentAt?: Maybe<Scalars['DateTime']>;
  isActivitiesTabUnlocked?: Maybe<Scalars['Boolean']>;
  /** Broker notification of the buyer finder service */
  isBrokerNotifiedOfBuyerFinder?: Maybe<Scalars['Boolean']>;
  isContractSigned?: Maybe<Scalars['Boolean']>;
  isCreatedVia3rdPartyPortal?: Maybe<Scalars['Boolean']>;
  isDigitalServiceActivated?: Maybe<Scalars['Boolean']>;
  isDigitalServiceLockedByBroker?: Maybe<Scalars['Boolean']>;
  isExposeApprovedViaOnOffice?: Maybe<Scalars['Boolean']>;
  isHiddenSendEmailToBrokerAlert?: Maybe<Scalars['Boolean']>;
  isMarketingDashboardNotificationSent?: Maybe<Scalars['Boolean']>;
  isMarketingDashboardUnlocked?: Maybe<Scalars['Boolean']>;
  isMarketingStartedNotificationSent?: Maybe<Scalars['Boolean']>;
  isNotifyEvaluationUpdates?: Maybe<Scalars['Boolean']>;
  isPreview?: Maybe<Scalars['Boolean']>;
  isReadOnlyAccess?: Maybe<Scalars['Boolean']>;
  isRealSellingProcessStarted?: Maybe<Scalars['Boolean']>;
  isUnderConstruction?: Maybe<Scalars['Boolean']>;
  latestSuggestedSP?: Maybe<LatestSuggestedSpInput>;
  mapboxImageUrl?: Maybe<Scalars['String']>;
  marketingStatistics?: Maybe<MarketingStatisticsInput>;
  onOfficeId?: Maybe<Scalars['String']>;
  onOfficePrice?: Maybe<Scalars['Float']>;
  /** Seller second registration reminder */
  onOfficeReminderSentAt?: Maybe<Scalars['DateTime']>;
  onOfficeUUID?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  ownerOnOfficeId?: Maybe<Scalars['String']>;
  pdfExpose?: Maybe<Scalars['String']>;
  pdfExposeToken?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Scalars['String']>>;
  priceCurrency?: Maybe<Scalars['String']>;
  propertyData?: Maybe<UpdatePropertyDataInput>;
  propertyPortfolioData?: Maybe<PropertyPortfolioDataInput>;
  propertyWidgetFeedback?: Maybe<PropertyWidgetFeedbackInput>;
  provision?: Maybe<ProvisionInput>;
  ratingReason?: Maybe<RatingReason>;
  relatedShop?: Maybe<RelatedShopInput>;
  /** Seller second registration reminder */
  secondReminderSentAt?: Maybe<Scalars['DateTime']>;
  servicesDisabledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PropertyStatus>;
  timeHorizont?: Maybe<TimeHorizont>;
  title?: Maybe<Scalars['String']>;
  tour360Link?: Maybe<Scalars['String']>;
  uploadedFilesCount?: Maybe<Scalars['Int']>;
  valuationType?: Maybe<ValuationType>;
  valuationsUpdateEmailSentAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePropertyPortfolioInput = {
  _id: Scalars['String'];
  propertyPortfolioData: PropertyPortfolioDataInput;
};

export type UpdateSearchProfileDataInput = {
  landAreaMax?: Maybe<Scalars['Int']>;
  landAreaMin?: Maybe<Scalars['Int']>;
  livingAreaMax?: Maybe<Scalars['Int']>;
  livingAreaMin?: Maybe<Scalars['Int']>;
  location?: Maybe<UpdateLocationSearchProfileInput>;
  numberOfBathroomsMax?: Maybe<Scalars['Int']>;
  numberOfBathroomsMin?: Maybe<Scalars['Int']>;
  numberOfRoomsMax?: Maybe<Scalars['Int']>;
  numberOfRoomsMin?: Maybe<Scalars['Int']>;
  propertyCode?: Maybe<PropertyCode>;
  purchasePriceMax?: Maybe<Scalars['Float']>;
  purchasePriceMin?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Int']>;
};

export type UpdateSearchProfileInput = {
  _id: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  isNotifyNewProperties?: Maybe<Scalars['Boolean']>;
  isNotifyNewPropertiesSummary?: Maybe<Scalars['Boolean']>;
  isNotifyPriceChange?: Maybe<Scalars['Boolean']>;
  latestSuggestedProperties?: Maybe<Array<LatestSuggestedPropertyInput>>;
  marketingParameters?: Maybe<Scalars['String']>;
  /** Total number of suggested properties  */
  offersCount?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  /** Total number of suggested properties  */
  priceOffers?: Maybe<Array<PriceOfferInput>>;
  relatedShop?: Maybe<ShopEmailInput>;
  searchProfileData?: Maybe<UpdateSearchProfileDataInput>;
  suggestedPropertiesSummaryTemplate?: Maybe<SuggestedPropertiesSummaryInput>;
  title?: Maybe<Scalars['String']>;
};

export type UpdateUserByIdInput = {
  _id: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  intendToSellProperty?: Maybe<Scalars['Boolean']>;
  isPropertyOwner?: Maybe<Scalars['Boolean']>;
  isSentFeedback?: Maybe<Scalars['Boolean']>;
  lastConfirmationEmailSentByAdmin?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  needFinancingConsultation?: Maybe<Scalars['Boolean']>;
  newPassword?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationsInput>;
  number?: Maybe<Scalars['Int']>;
  oldPassword?: Maybe<Scalars['String']>;
  onOfficeId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  salutation?: Maybe<Scalars['String']>;
  scheduledEmails?: Maybe<Array<ScheduledEmailInput>>;
  street?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export type UpdateUserProfileInput = {
  city?: Maybe<Scalars['String']>;
  hubspotContactId?: Maybe<Scalars['String']>;
  intendToSellProperty?: Maybe<Scalars['Boolean']>;
  isPropertyOwner?: Maybe<Scalars['Boolean']>;
  isSentFeedback?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  needFinancingConsultation?: Maybe<Scalars['Boolean']>;
  newPassword?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationsInput>;
  number?: Maybe<Scalars['Int']>;
  oldPassword?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export type UpdateVideoSectionInput = {
  _id: Scalars['String'];
  ctaLabel?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
};

export type UpdateVirtualTourSectionInput = {
  _id: Scalars['String'];
  image?: Maybe<Scalars['Upload']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageFilename?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type UpdateVonPollPropertyDataInput = {
  buildingYear?: Maybe<Scalars['String']>;
  equipment?: Maybe<VonPollPropertyEquipmentInput>;
  images: Array<VonPollPropertyImageInput>;
  livingArea?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  propertyId: Scalars['Float'];
  propertyType?: Maybe<Scalars['String']>;
  purchasePrice?: Maybe<Scalars['Float']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  communicationConsent?: Maybe<Scalars['Boolean']>;
  createAccount?: Maybe<Scalars['Boolean']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  duplicateContent?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailRefreshToken?: Maybe<Scalars['String']>;
  financings?: Maybe<Array<Financing>>;
  hasPendingValuations?: Maybe<Scalars['Boolean']>;
  hubspotContactId?: Maybe<Scalars['String']>;
  intendToSellProperty?: Maybe<Scalars['Boolean']>;
  inviteExpire?: Maybe<Scalars['Float']>;
  isPropertyOwner?: Maybe<Scalars['Boolean']>;
  isSentFeedback?: Maybe<Scalars['Boolean']>;
  lastConfirmationEmailSentByAdmin?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  needFinancingConsultation?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<NotificationsEntity>;
  number?: Maybe<Scalars['Int']>;
  onOfficeId?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  prefix: Scalars['String'];
  properties?: Maybe<Array<Property>>;
  regionalBrokerId?: Maybe<Scalars['String']>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  registrationBy?: Maybe<RegistrationBy>;
  roles: Array<Role>;
  salutation?: Maybe<Scalars['String']>;
  scheduledEmails?: Maybe<Array<ScheduledEmail>>;
  searchProfiles?: Maybe<Array<SearchProfile>>;
  slug?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  temporaryPassword?: Maybe<Scalars['String']>;
  termsAndConditions: Scalars['Boolean'];
  termsAndConditionsFinancing?: Maybe<Scalars['Boolean']>;
  termsAndConditionsFinancing2?: Maybe<Scalars['Boolean']>;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
  userState: UserState;
  userType?: Maybe<Array<UserType>>;
  username: Scalars['String'];
};

export type UserDefinedFieldInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type UserDefinedFields = {
  __typename?: 'UserDefinedFields';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type UserFilterInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export type UserSignInInput = {
  password: Scalars['String'];
  regionalBrokerId?: Maybe<Scalars['String']>;
  requestAccessToken?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  userAuthenticationKey: Scalars['String'];
};

export type UserSignUpInput = {
  age?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  communicationConsent?: Maybe<Scalars['Boolean']>;
  createAccount?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  hasPendingValuations?: Maybe<Scalars['Boolean']>;
  householdNetIncome?: Maybe<Scalars['Float']>;
  isUserFromAnotherPortal?: Maybe<Scalars['Boolean']>;
  lang: Scalars['String'];
  mainEarner?: Maybe<MainEarner>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationsInput>;
  number?: Maybe<Scalars['Int']>;
  password: Scalars['String'];
  phone: Scalars['String'];
  prefix: Scalars['String'];
  registrationBy?: Maybe<RegistrationBy>;
  salutation?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  temporaryPassword?: Maybe<Scalars['String']>;
  termsAndConditions: Scalars['Boolean'];
  termsAndConditionsFinancing?: Maybe<Scalars['Boolean']>;
  termsAndConditionsFinancing2?: Maybe<Scalars['Boolean']>;
  userType: Array<UserType>;
};

export enum UserState {
  ChangePassword = 'CHANGE_PASSWORD',
  PersonalEvaluation = 'PERSONAL_EVALUATION',
  Unauthenticated = 'UNAUTHENTICATED',
  Verified = 'VERIFIED',
  VerifyEmail = 'VERIFY_EMAIL',
  VerifyPhone = 'VERIFY_PHONE',
}

export enum UserStatus {
  Editor = 'EDITOR',
  Owner = 'OWNER',
  Spectator = 'SPECTATOR',
}

export enum UserType {
  Buyer = 'Buyer',
  Financier = 'Financier',
  Owner = 'Owner',
  Seller = 'Seller',
}

export type Valuation = {
  __typename?: 'Valuation';
  confidence?: Maybe<Confidence>;
  coordinates?: Maybe<Coordinates>;
  currency?: Maybe<Currency>;
  rentGross?: Maybe<Scalars['Int']>;
  rentGrossRange?: Maybe<ValueRange>;
  rentNet?: Maybe<Scalars['Int']>;
  rentNetRange?: Maybe<ValueRange>;
  salePrice?: Maybe<Scalars['Int']>;
  salePriceRange?: Maybe<ValueRange>;
  scores?: Maybe<Score>;
  status?: Maybe<Status>;
  /** @deprecated Deprecated since 2019-05-20. Replaced by salePrice (if dealType is sale) and rentGross (if dealType is rent) */
  value?: Maybe<Scalars['Int']>;
  /** @deprecated Deprecated since 2019-05-20. Replaced by salePriceRange.upper (if dealType is sale) and rentGrossRange.upper (if dealType is rent) */
  valueRange?: Maybe<ValueRange>;
};

export type ValuationInput = {
  countryCode: Scalars['String'];
  dealType: DealType;
  ownerId?: Maybe<Scalars['String']>;
  propertyData: PropertyInput;
  valuationDates?: Maybe<Array<Scalars['DateTime']>>;
};

export enum ValuationType {
  Online = 'Online',
  Personal = 'Personal',
}

export type ValueRange = {
  __typename?: 'ValueRange';
  lower: Scalars['Int'];
  upper: Scalars['Int'];
};

export type VerifyGuestUserInput = {
  phoneNumber: Scalars['String'];
};

export type VideoSection = {
  __typename?: 'VideoSection';
  _id: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  ctaLabel: Scalars['String'];
  subtitle: Scalars['String'];
  text: Scalars['String'];
  title: Scalars['String'];
  type: SectionType;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoURL: Scalars['String'];
};

export type VirtualTourSection = {
  __typename?: 'VirtualTourSection';
  _id: Scalars['String'];
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  imageAlt?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: SectionType;
  /** Updated At */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VonPollOnOfficeData = {
  __typename?: 'VonPollOnOfficeData';
  /** email of address broker */
  addressBrokerEmail?: Maybe<Scalars['String']>;
  /** check if user exists in onOffice */
  duplicate?: Maybe<Scalars['Boolean']>;
  /** (onOffice id) - objektnr_intern in VP DB */
  internalId?: Maybe<Scalars['Float']>;
  /** email of property broker  */
  propertyBrokerEmail?: Maybe<Scalars['String']>;
};

export type VonPollOnOfficeDataInput = {
  addressBrokerEmail?: Maybe<Scalars['String']>;
  duplicate?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['Float']>;
  propertyBrokerEmail?: Maybe<Scalars['String']>;
};

export type VonPollPropertyData = {
  __typename?: 'VonPollPropertyData';
  buildingYear?: Maybe<Scalars['String']>;
  equipment?: Maybe<VonPollPropertyEquipment>;
  images: Array<VonPollPropertyImage>;
  livingArea?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  propertyId: Scalars['Float'];
  propertyType?: Maybe<Scalars['String']>;
  purchasePrice?: Maybe<Scalars['Float']>;
};

export type VonPollPropertyDataInput = {
  buildingYear?: Maybe<Scalars['String']>;
  equipment?: Maybe<VonPollPropertyEquipmentInput>;
  images?: Maybe<Array<VonPollPropertyImageInput>>;
  livingArea?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  propertyId: Scalars['Float'];
  propertyType?: Maybe<Scalars['String']>;
  purchasePrice?: Maybe<Scalars['Float']>;
};

export type VonPollPropertyEquipment = {
  __typename?: 'VonPollPropertyEquipment';
  rentalIncomeIs?: Maybe<Scalars['String']>;
  rentalIncomeShould?: Maybe<Scalars['String']>;
};

export type VonPollPropertyEquipmentInput = {
  rentalIncomeIs: Scalars['String'];
  rentalIncomeShould: Scalars['String'];
};

export type VonPollPropertyImage = {
  __typename?: 'VonPollPropertyImage';
  alt: Scalars['String'];
  group: Scalars['String'];
  height: Scalars['Float'];
  id: Scalars['Float'];
  link: Scalars['String'];
  width: Scalars['Float'];
};

export type VonPollPropertyImageInput = {
  alt: Scalars['String'];
  group: Scalars['String'];
  height: Scalars['Float'];
  id: Scalars['Float'];
  link: Scalars['String'];
  width: Scalars['Float'];
};

export type YearEntity = {
  __typename?: 'YearEntity';
  values: Array<SocioEconomicsValueEntity>;
  year: Scalars['Int'];
};

export type AuthChallengeFieldsFragment = {
  __typename?: 'AuthChallenge';
} & Pick<AuthChallenge, 'challengeName' | 'challengeParameters' | 'session'>;

export type JwtTokenFieldsFragment = { __typename?: 'JwtToken' } & Pick<
  JwtToken,
  'token' | 'exp' | 'iat' | 'refreshToken'
>;

export type UserFieldsFragment = { __typename?: 'User' } & Pick<
  User,
  | '_id'
  | 'username'
  | 'email'
  | 'phone'
  | 'prefix'
  | 'roles'
  | 'name'
  | 'surname'
  | 'userState'
  | 'userType'
  | 'termsAndConditions'
  | 'createAccount'
  | 'communicationConsent'
  | 'temporaryPassword'
  | 'hasPendingValuations'
  | 'registrationBy'
  | 'regionalBrokerId'
  | 'slug'
  | 'salutation'
  | 'city'
  | 'street'
  | 'number'
  | 'needFinancingConsultation'
  | 'isPropertyOwner'
  | 'intendToSellProperty'
  | 'isSentFeedback'
> & {
    notifications?: Maybe<
      { __typename?: 'NotificationsEntity' } & Pick<
        NotificationsEntity,
        | 'isNotifyPriceChange'
        | 'isNotifyNewProperties'
        | 'isNotifyPaymentOfInterestMonthly'
        | 'isNotifyGeneralNewsletter'
        | 'isNotifyMarketPrice'
        | 'isNotifyNewPropertiesSummary'
        | 'isNotifyFinancingInterestRates'
        | 'isNotifyEvaluationUpdates'
      >
    >;
  };

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'changePassword'
>;

export type ConfirmEmailMutationVariables = Exact<{
  input: ConfirmEmailInput;
}>;

export type ConfirmEmailMutation = { __typename?: 'Mutation' } & {
  confirmEmail: { __typename?: 'User' } & UserFieldsFragment;
};

export type ConfirmForgotPasswordMutationVariables = Exact<{
  input: ConfirmForgotPasswordInput;
}>;

export type ConfirmForgotPasswordMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'confirmForgotPassword'
>;

export type ConfirmPhoneChangeMutationVariables = Exact<{
  input: ConfirmEmailInput;
}>;

export type ConfirmPhoneChangeMutation = { __typename?: 'Mutation' } & {
  confirmPhoneChange: { __typename?: 'User' } & UserFieldsFragment;
};

export type ConfirmPhoneMutationVariables = Exact<{
  input: ConfirmPhoneInput;
}>;

export type ConfirmPhoneMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'confirmPhone'
>;

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;

export type ForgotPasswordMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'forgotPassword'
>;

export type RemoveUserAccountMutationVariables = Exact<{
  username: Scalars['String'];
}>;

export type RemoveUserAccountMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'removeUserAccount'
>;

export type RequestVerificationCodeMutationVariables = Exact<{
  input: RequestVerificationCodeInput;
}>;

export type RequestVerificationCodeMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'requestVerificationCode'>;

export type ResendConfirmationCodeMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ResendConfirmationCodeMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'resendConfirmationCode'
>;

export type ResendPhoneCodeAfterChangeMutationVariables = Exact<{
  phone: Scalars['String'];
}>;

export type ResendPhoneCodeAfterChangeMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'resendPhoneCodeAfterChange'>;

export type UserSelfDeleteMutationVariables = Exact<{
  username: Scalars['String'];
}>;

export type UserSelfDeleteMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'userSelfDelete'
>;

export type SetUserStateMutationVariables = Exact<{
  _id: Scalars['String'];
  state: Scalars['String'];
}>;

export type SetUserStateMutation = { __typename?: 'Mutation' } & {
  setUserState: { __typename?: 'User' } & Pick<User, 'userState'>;
};

export type SignInPropertiesMutationVariables = Exact<{
  input: UserSignInInput;
}>;

export type SignInPropertiesMutation = { __typename?: 'Mutation' } & {
  signIn:
    | ({ __typename?: 'AuthChallenge' } & AuthChallengeFieldsFragment)
    | ({ __typename?: 'LoginResponse' } & {
        jwt?: Maybe<{ __typename?: 'JwtToken' } & JwtTokenFieldsFragment>;
        user: { __typename?: 'User' } & {
          properties?: Maybe<
            Array<{ __typename?: 'Property' } & Pick<Property, '_id'>>
          >;
        } & UserFieldsFragment;
      });
};

export type SignInMutationVariables = Exact<{
  input: UserSignInInput;
}>;

export type SignInMutation = { __typename?: 'Mutation' } & {
  signIn:
    | ({ __typename?: 'AuthChallenge' } & AuthChallengeFieldsFragment)
    | ({ __typename?: 'LoginResponse' } & {
        jwt?: Maybe<{ __typename?: 'JwtToken' } & JwtTokenFieldsFragment>;
        user: { __typename?: 'User' } & UserFieldsFragment;
      });
};

export type SignUpMutationVariables = Exact<{
  input: UserSignUpInput;
}>;

export type SignUpMutation = { __typename?: 'Mutation' } & {
  signUp: { __typename?: 'User' } & Pick<
    User,
    '_id' | 'username' | 'userState'
  >;
};

export type UnsubscribeFromNotificationsMutationVariables = Exact<{
  userId: Scalars['String'];
}>;

export type UnsubscribeFromNotificationsMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'unsubscribeFromNotifications'>;

export type UpdateUserProfileMutationVariables = Exact<{
  input: UpdateUserProfileInput;
}>;

export type UpdateUserProfileMutation = { __typename?: 'Mutation' } & {
  updateUserProfile: { __typename?: 'User' } & UserFieldsFragment;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<{ __typename?: 'User' } & UserFieldsFragment>;
};

export type GetOnOfficeUserQueryVariables = Exact<{
  addressId: Scalars['Int'];
}>;

export type GetOnOfficeUserQuery = { __typename?: 'Query' } & {
  getOnOfficeUser?: Maybe<
    { __typename?: 'OnOfficeUser' } & Pick<
      OnOfficeUser,
      | 'id'
      | 'buyerPortalId'
      | 'salutation'
      | 'name'
      | 'surname'
      | 'phone'
      | 'email'
      | 'city'
      | 'street'
    >
  >;
};

export type SaveFileAccessConfigMutationVariables = Exact<{
  input: FileAccessConfigInput;
}>;

export type SaveFileAccessConfigMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'saveFileAccessConfig'
>;

export type UploadPropertyDocumentsMutationVariables = Exact<{
  propertyFiles: Array<PropertyFileInput> | PropertyFileInput;
  propertyId: Scalars['String'];
}>;

export type UploadPropertyDocumentsMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'uploadPropertyDocuments'>;

export type FileAccessConfigQueryVariables = Exact<{
  fileId: Scalars['String'];
}>;

export type FileAccessConfigQuery = { __typename?: 'Query' } & {
  fileAccessConfig?: Maybe<
    { __typename?: 'FileAccessConfig' } & Pick<
      FileAccessConfig,
      'fileId' | 'propertyId' | 'fileName' | 'accessLimit' | 'accessExpiresAt'
    >
  >;
};

export type GetBuyersDocumentAccessStatusQueryVariables = Exact<{
  propertyId: Scalars['String'];
  fileId: Scalars['String'];
}>;

export type GetBuyersDocumentAccessStatusQuery = { __typename?: 'Query' } & {
  getBuyersDocumentAccessStatus?: Maybe<
    Array<
      { __typename?: 'OnOfficeUser' } & Pick<
        OnOfficeUser,
        'id' | 'name' | 'surname' | 'email' | 'hasAccessToDocument'
      >
    >
  >;
};

export type GetPropertyDocumentsQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type GetPropertyDocumentsQuery = { __typename?: 'Query' } & {
  getPropertyDocuments?: Maybe<
    Array<
      { __typename?: 'PropertyFile' } & Pick<
        PropertyFile,
        | 'id'
        | 'type'
        | 'originalname'
        | 'filename'
        | 'title'
        | 'content'
        | 'documentAttribute'
        | 'url'
        | 'isPublished'
        | 'releaseStatus'
      >
    >
  >;
};

export type PropertyAddressQueryVariables = Exact<{
  propertyOnOfficeId: Scalars['String'];
}>;

export type PropertyAddressQuery = { __typename?: 'Query' } & {
  propertyAddress?: Maybe<
    { __typename?: 'PropertyAddress' } & Pick<
      PropertyAddress,
      'id' | 'ownerEmail' | 'address'
    >
  >;
};

export type SendFeedbackMutationVariables = Exact<{
  input: SendFeedbackInput;
}>;

export type SendFeedbackMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'sendFeedback'
>;

export type ReadCentralPhoneByCountryQueryVariables = Exact<{
  country: Scalars['String'];
}>;

export type ReadCentralPhoneByCountryQuery = { __typename?: 'Query' } & {
  readCentralPhoneByCountry: { __typename?: 'CentralPhone' } & Pick<
    CentralPhone,
    'phoneNumber'
  >;
};

export type ReadBuyerLandingPageQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type ReadBuyerLandingPageQuery = { __typename?: 'Query' } & {
  readBuyerLandingPage: { __typename?: 'BuyerLandingPage' } & Pick<
    BuyerLandingPage,
    | '_id'
    | 'imageUrl'
    | 'imageKey'
    | 'imageAlt'
    | 'country'
    | 'locale'
    | 'metaDescription'
    | 'metaKeywords'
    | 'metaTitle'
    | 'name'
    | 'subtitle'
    | 'title'
    | 'text'
  > & {
      sections?: Maybe<
        Array<
          { __typename?: 'BuyerLPSection' } & Pick<
            BuyerLpSection,
            'type' | 'title' | 'orderIndex' | 'recordIds'
          >
        >
      >;
    };
};

export type ReadFinancingLandingPageQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type ReadFinancingLandingPageQuery = { __typename?: 'Query' } & {
  readFinancingLandingPage: { __typename?: 'FinancingLandingPage' } & Pick<
    FinancingLandingPage,
    | '_id'
    | 'imageUrl'
    | 'imageKey'
    | 'imageAlt'
    | 'country'
    | 'locale'
    | 'metaDescription'
    | 'metaKeywords'
    | 'metaTitle'
    | 'name'
    | 'subtitle'
    | 'title'
    | 'text'
  > & {
      sections?: Maybe<
        Array<
          { __typename?: 'FinancingLPSection' } & Pick<
            FinancingLpSection,
            'type' | 'title' | 'orderIndex' | 'recordIds'
          >
        >
      >;
    };
};

export type ReadOverviewLandingPageQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type ReadOverviewLandingPageQuery = { __typename?: 'Query' } & {
  readOverviewLandingPage: { __typename?: 'OverviewLandingPage' } & Pick<
    OverviewLandingPage,
    | '_id'
    | 'imageUrl'
    | 'imageKey'
    | 'imageAlt'
    | 'country'
    | 'locale'
    | 'metaDescription'
    | 'metaKeywords'
    | 'metaTitle'
    | 'name'
    | 'subtitle'
    | 'title'
    | 'text'
  > & {
      sections?: Maybe<
        Array<
          { __typename?: 'OverviewLPSection' } & Pick<
            OverviewLpSection,
            'type' | 'title' | 'orderIndex' | 'recordIds'
          >
        >
      >;
    };
};

export type ReadAppointmentContactFormQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadAppointmentContactFormQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'AppointmentContactForms' } & Pick<
    AppointmentContactForms,
    | '_id'
    | 'imageAlt'
    | 'imageUrl'
    | 'imageKey'
    | 'title'
    | 'phoneNumber'
    | 'expandedText'
    | 'bookAppointmentLabel'
    | 'formTitle'
    | 'nameLabel'
    | 'namePlaceholder'
    | 'surnameLabel'
    | 'surnamePlaceholder'
    | 'emailLabel'
    | 'emailPlaceholder'
    | 'phoneNumberLabel'
    | 'phoneNumberPlaceholder'
    | 'cityPostalLabel'
    | 'cityPostalPlaceholder'
    | 'addressBuildingNumberLabel'
    | 'addressBuildingNumberPlaceholder'
    | 'messageLabel'
    | 'messagePlaceholder'
    | 'checkbox1'
    | 'remark'
    | 'sendLabel'
  >;
};

export type ReadBuyerBenefitQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadBuyerBenefitQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'BuyerBenefits' } & Pick<
    BuyerBenefits,
    | '_id'
    | 'title'
    | 'expandedText'
    | 'icon1Alt'
    | 'icon1Url'
    | 'icon1Key'
    | 'text1'
    | 'icon2Alt'
    | 'icon2Url'
    | 'icon2Key'
    | 'text2'
    | 'icon3Alt'
    | 'icon3Url'
    | 'icon3Key'
    | 'text3'
    | 'icon4Alt'
    | 'icon4Url'
    | 'icon4Key'
    | 'text4'
    | 'createSearchProfileLabel'
    | 'imageAlt'
    | 'imageUrl'
    | 'imageKey'
  >;
};

export type ReadBuyerFunctionsListQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type ReadBuyerFunctionsListQuery = { __typename?: 'Query' } & {
  data: Array<
    { __typename?: 'BuyerFunctions' } & Pick<
      BuyerFunctions,
      '_id' | 'title' | 'expandedText' | 'iconAlt' | 'iconUrl' | 'iconKey'
    >
  >;
};

export type ReadBuyerOverviewQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadBuyerOverviewQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'BuyerOverviews' } & Pick<
    BuyerOverviews,
    | '_id'
    | 'title'
    | 'expandedText'
    | 'createSearchProfileLabel'
    | 'imageAlt'
    | 'imageUrl'
    | 'imageKey'
  >;
};

export type ReadFinancingOverviewsQueryVariables = Exact<{
  filter: FinancingOverviewFilterInput;
}>;

export type ReadFinancingOverviewsQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'PaginatedFinancingOverviews' } & {
    page: Array<
      { __typename?: 'FinancingOverviews' } & Pick<
        FinancingOverviews,
        | '_id'
        | 'subTitle'
        | 'title'
        | 'iconAlt'
        | 'iconUrl'
        | 'iconKey'
        | 'textTitle'
        | 'expandedText'
        | 'fundingInquiriesLabel'
        | 'imageAlt'
        | 'imageUrl'
        | 'imageKey'
      >
    >;
  };
};

export type ReadOverviewAppQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadOverviewAppQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'OverviewApps' } & Pick<
    OverviewApps,
    | '_id'
    | 'subTitle'
    | 'title'
    | 'expandedText'
    | 'imageAlt'
    | 'imageUrl'
    | 'imageKey'
    | 'appStoreLink'
    | 'googlePlayLink'
  >;
};

export type OverviewLandingPageContactMutationVariables = Exact<{
  contactData: OverviewLandingPageContactDataInput;
}>;

export type OverviewLandingPageContactMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'overviewLandingPageContact'>;

export type ReadOwnerBenefitQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadOwnerBenefitQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'OwnerBenefits' } & Pick<
    OwnerBenefits,
    | '_id'
    | 'title'
    | 'expandedText'
    | 'icon1Alt'
    | 'icon1Url'
    | 'icon1Key'
    | 'text1'
    | 'icon2Alt'
    | 'icon2Url'
    | 'icon2Key'
    | 'text2'
    | 'icon3Alt'
    | 'icon3Url'
    | 'icon3Key'
    | 'text3'
    | 'propertyEvaluateLabel'
    | 'imageAlt'
    | 'imageUrl'
    | 'imageKey'
  >;
};

export type ReadOwnerFunctionsListQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type ReadOwnerFunctionsListQuery = { __typename?: 'Query' } & {
  data: Array<
    { __typename?: 'OwnerFunctions' } & Pick<
      OwnerFunctions,
      '_id' | 'title' | 'expandedText' | 'iconAlt' | 'iconUrl' | 'iconKey'
    >
  >;
};

export type ReadOwnerOverviewQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadOwnerOverviewQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'OwnerOverviews' } & Pick<
    OwnerOverviews,
    | '_id'
    | 'title'
    | 'expandedText'
    | 'propertyEvaluateLabel'
    | 'patternAnalysisLabel'
    | 'imageAlt'
    | 'imageUrl'
    | 'imageKey'
  >;
};

export type ReadSellerBenefitQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadSellerBenefitQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'SellerBenefits' } & Pick<
    SellerBenefits,
    | '_id'
    | 'title'
    | 'expandedText'
    | 'icon1Alt'
    | 'icon1Url'
    | 'icon1Key'
    | 'text1'
    | 'icon2Alt'
    | 'icon2Url'
    | 'icon2Key'
    | 'text2'
    | 'icon3Alt'
    | 'icon3Url'
    | 'icon3Key'
    | 'text3'
    | 'icon4Alt'
    | 'icon4Url'
    | 'icon4Key'
    | 'text4'
    | 'icon5Alt'
    | 'icon5Url'
    | 'icon5Key'
    | 'text5'
    | 'propertyEvaluateLabel'
    | 'imageAlt'
    | 'imageUrl'
    | 'imageKey'
  >;
};

export type ReadSellerFunctionsListQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type ReadSellerFunctionsListQuery = { __typename?: 'Query' } & {
  data: Array<
    { __typename?: 'SellerFunctions' } & Pick<
      SellerFunctions,
      '_id' | 'title' | 'expandedText' | 'iconAlt' | 'iconUrl' | 'iconKey'
    >
  >;
};

export type ReadSellerOverviewQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadSellerOverviewQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'SellerOverviews' } & Pick<
    SellerOverviews,
    | '_id'
    | 'title'
    | 'expandedText'
    | 'CTALabel'
    | 'imageAlt'
    | 'imageUrl'
    | 'imageKey'
  >;
};

export type ReadTestimonialsListQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type ReadTestimonialsListQuery = { __typename?: 'Query' } & {
  data: Array<
    { __typename?: 'Testimonials' } & Pick<
      Testimonials,
      | '_id'
      | 'imageAlt'
      | 'imageUrl'
      | 'imageKey'
      | 'title'
      | 'expandedText'
      | 'name'
    >
  >;
};

export type ReadOwnerLandingPageQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type ReadOwnerLandingPageQuery = { __typename?: 'Query' } & {
  readOwnerLandingPage: { __typename?: 'OwnerLandingPage' } & Pick<
    OwnerLandingPage,
    | '_id'
    | 'imageUrl'
    | 'imageKey'
    | 'imageAlt'
    | 'country'
    | 'locale'
    | 'metaDescription'
    | 'metaKeywords'
    | 'metaTitle'
    | 'name'
    | 'subtitle'
    | 'title'
    | 'text'
  > & {
      sections?: Maybe<
        Array<
          { __typename?: 'OwnerLPSection' } & Pick<
            OwnerLpSection,
            'type' | 'title' | 'orderIndex' | 'recordIds'
          >
        >
      >;
    };
};

export type ReadBuyerFinderTeaserQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadBuyerFinderTeaserQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'BuyerFinderTeasers' } & Pick<
    BuyerFinderTeasers,
    | '_id'
    | 'subtitle'
    | 'title'
    | 'description'
    | 'step1Title'
    | 'step1Text'
    | 'step2Title'
    | 'step2Text'
    | 'step3Title'
    | 'step3Text'
    | 'imageAlt'
    | 'imageUrl'
    | 'CTALabel'
  >;
};

export type BlockFieldsFragment = { __typename?: 'CmsBlock' } & Pick<
  CmsBlock,
  '_id' | 'title' | 'type' | 'isActive'
> & {
    sections: Array<
      | ({
          __typename?: 'AppointmentSection';
        } & SectionFields_AppointmentSection_Fragment)
      | ({
          __typename?: 'BenefitSection';
        } & SectionFields_BenefitSection_Fragment)
      | ({
          __typename?: 'ContentSection';
        } & SectionFields_ContentSection_Fragment)
      | ({ __typename?: 'FaqSection' } & SectionFields_FaqSection_Fragment)
      | ({
          __typename?: 'OwnerSettingsSection';
        } & SectionFields_OwnerSettingsSection_Fragment)
      | ({ __typename?: 'VideoSection' } & SectionFields_VideoSection_Fragment)
      | ({
          __typename?: 'VirtualTourSection';
        } & SectionFields_VirtualTourSection_Fragment)
    >;
  };

export type CmsPageQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CmsPageQuery = { __typename?: 'Query' } & {
  cmsPage: { __typename?: 'CmsPage' } & Pick<
    CmsPage,
    '_id' | 'name' | 'representativeName'
  > & { blocks: Array<{ __typename?: 'CmsBlock' } & BlockFieldsFragment> };
};

export type CmsPagesQueryVariables = Exact<{ [key: string]: never }>;

export type CmsPagesQuery = { __typename?: 'Query' } & {
  cmsPages: Array<
    { __typename?: 'CmsPage' } & Pick<
      CmsPage,
      '_id' | 'name' | 'representativeName'
    >
  >;
};

export type FaqSectionFieldsFragment = { __typename?: 'FaqSection' } & Pick<
  FaqSection,
  '_id' | 'title' | 'type' | 'expandedText' | 'order'
>;

export type OwnerSettingsSectionFieldsFragment = {
  __typename?: 'OwnerSettingsSection';
} & Pick<
  OwnerSettingsSection,
  | '_id'
  | 'title'
  | 'type'
  | 'ownerSettingsUrl'
  | 'ownerSettingsKey'
  | 'isActive'
  | 'awsFolderKey'
>;

export type BenefitSectionFieldsFragment = {
  __typename?: 'BenefitSection';
} & Pick<
  BenefitSection,
  | '_id'
  | 'title'
  | 'type'
  | 'expandedText'
  | 'imageUrl'
  | 'imageKey'
  | 'iconUrl'
  | 'iconKey'
  | 'awsFolderKey'
  | 'imageHint'
  | 'altText'
  | 'cta'
  | 'ctaLabel'
>;

export type AppointmentSectionFieldsFragment = {
  __typename?: 'AppointmentSection';
} & Pick<
  AppointmentSection,
  | '_id'
  | 'title'
  | 'type'
  | 'avatarUrl'
  | 'avatarKey'
  | 'phone'
  | 'awsFolderKey'
  | 'timifyId'
  | 'brokerPosition'
  | 'brokerQualification'
  | 'altText'
  | 'email'
  | 'legalPopUpLabel'
  | 'legalPopUpText'
>;

export type VideoSectionFieldsFragment = { __typename?: 'VideoSection' } & Pick<
  VideoSection,
  '_id' | 'title' | 'subtitle' | 'text' | 'ctaLabel' | 'videoURL'
>;

type SectionFields_AppointmentSection_Fragment = {
  __typename?: 'AppointmentSection';
} & AppointmentSectionFieldsFragment;

type SectionFields_BenefitSection_Fragment = {
  __typename?: 'BenefitSection';
} & BenefitSectionFieldsFragment;

type SectionFields_ContentSection_Fragment = { __typename?: 'ContentSection' };

type SectionFields_FaqSection_Fragment = {
  __typename?: 'FaqSection';
} & FaqSectionFieldsFragment;

type SectionFields_OwnerSettingsSection_Fragment = {
  __typename?: 'OwnerSettingsSection';
} & OwnerSettingsSectionFieldsFragment;

type SectionFields_VideoSection_Fragment = {
  __typename?: 'VideoSection';
} & VideoSectionFieldsFragment;

type SectionFields_VirtualTourSection_Fragment = {
  __typename?: 'VirtualTourSection';
};

export type SectionFieldsFragment =
  | SectionFields_AppointmentSection_Fragment
  | SectionFields_BenefitSection_Fragment
  | SectionFields_ContentSection_Fragment
  | SectionFields_FaqSection_Fragment
  | SectionFields_OwnerSettingsSection_Fragment
  | SectionFields_VideoSection_Fragment
  | SectionFields_VirtualTourSection_Fragment;

export type CmsRegionalPageQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type CmsRegionalPageQuery = { __typename?: 'Query' } & {
  cmsRegionalPage: { __typename?: 'CmsRegionalPage' } & Pick<
    CmsRegionalPage,
    | '_id'
    | 'active'
    | 'awsFolderKey'
    | 'imageUrl'
    | 'imageKey'
    | 'imageAlt'
    | 'bookingButtonLabel'
    | 'country'
    | 'email'
    | 'heading'
    | 'metaDescription'
    | 'metaKeywords'
    | 'metaTitle'
    | 'name'
    | 'personName'
    | 'personPosition'
    | 'personText'
    | 'phone'
    | 'routeButtonLabel'
    | 'routeButtonLink'
    | 'slug'
    | 'subheading'
    | 'title'
  > & {
      sections: Array<
        { __typename?: 'RPSection' } & Pick<
          RpSection,
          'type' | 'title' | 'orderIndex' | 'recordIds'
        >
      >;
    };
};

export type ReadGeneralAppointmentQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadGeneralAppointmentQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'GeneralAppointment' } & Pick<
    GeneralAppointment,
    '_id' | 'buttonLabel' | 'generalText' | 'label' | 'name' | 'phone'
  >;
};

export type ReadAdvantageQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadAdvantageQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'Advantages' } & Pick<
    Advantages,
    '_id' | 'name' | 'title' | 'text' | 'iconAlt' | 'iconUrl' | 'iconKey'
  >;
};

export type ReadAppQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadAppQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'Apps' } & Pick<
    Apps,
    | '_id'
    | 'name'
    | 'title'
    | 'subtitle'
    | 'text'
    | 'appStoreLink'
    | 'googlePlayLink'
  >;
};

export type ReadAppointmentsQueryVariables = Exact<{
  filter: CmsSectionFilterInput;
}>;

export type ReadAppointmentsQuery = { __typename?: 'Query' } & {
  data: Array<
    { __typename?: 'AppointmentSection' } & Pick<
      AppointmentSection,
      | '_id'
      | 'avatarUrl'
      | 'avatarKey'
      | 'altText'
      | 'phone'
      | 'email'
      | 'title'
      | 'awsFolderKey'
      | 'timifyId'
      | 'brokerPosition'
      | 'brokerQualification'
      | 'legalPopUpLabel'
      | 'legalPopUpText'
    >
  >;
};

export type ReadBannerQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadBannerQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'Banner' } & Pick<
    Banner,
    | '_id'
    | 'name'
    | 'text'
    | 'buttonLabel'
    | 'imageAlt'
    | 'imageUrl'
    | 'imageKey'
  >;
};

export type ReadBenefitQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadBenefitQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'Benefits' } & Pick<
    Benefits,
    | '_id'
    | 'name'
    | 'title'
    | 'text'
    | 'widgetButtonLabel'
    | 'secondaryButtonLabel'
    | 'secondaryButtonLink'
    | 'imageAlt'
    | 'imageKey'
    | 'imageUrl'
  >;
};

export type BenefitsByIdsQueryVariables = Exact<{
  _ids?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type BenefitsByIdsQuery = { __typename?: 'Query' } & {
  data: Array<
    { __typename?: 'BenefitSection' } & Pick<
      BenefitSection,
      | '_id'
      | 'altText'
      | 'awsFolderKey'
      | 'cta'
      | 'ctaLabel'
      | 'expandedText'
      | 'iconKey'
      | 'iconUrl'
      | 'imageHint'
      | 'imageKey'
      | 'imageUrl'
      | 'title'
      | 'type'
    >
  >;
};

export type ReadCertificateQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadCertificateQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'Certificates' } & Pick<
    Certificates,
    '_id' | 'fileAlt' | 'fileKey' | 'fileUrl' | 'name'
  >;
};

export type CmsSectionQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CmsSectionQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    | ({ __typename?: 'AppointmentSection' } & AppointmentSectionFieldsFragment)
    | { __typename?: 'BenefitSection' }
    | { __typename?: 'ContentSection' }
    | ({ __typename?: 'FaqSection' } & FaqSectionFieldsFragment)
    | { __typename?: 'OwnerSettingsSection' }
    | { __typename?: 'VideoSection' }
    | { __typename?: 'VirtualTourSection' }
  >;
};

export type CountryFieldsFragment = { __typename?: 'Country' } & Pick<
  Country,
  '_id' | 'code' | 'name'
>;

export type ReadCustomerAppraisalQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadCustomerAppraisalQuery = { __typename?: 'Query' } & {
  readCustomerAppraisal: { __typename?: 'CustomerAppraisals' } & Pick<
    CustomerAppraisals,
    '_id' | 'bannerLink' | 'expandedText' | 'imageAlt' | 'imageUrl' | 'title'
  >;
};

export type ReadFaqsQueryVariables = Exact<{
  filter: CmsSectionFilterInput;
}>;

export type ReadFaqsQuery = { __typename?: 'Query' } & {
  data: Array<
    { __typename?: 'FaqSection' } & Pick<
      FaqSection,
      '_id' | 'expandedText' | 'order' | 'title' | 'type'
    >
  >;
};

export type ReadStepByStepBenefitQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadStepByStepBenefitQuery = { __typename?: 'Query' } & {
  readStepByStepBenefit: { __typename?: 'StepByStepBenefits' } & Pick<
    StepByStepBenefits,
    | '_id'
    | 'subtitle'
    | 'title'
    | 'step1IconUrl'
    | 'step1IconAlt'
    | 'step1Title'
    | 'step1Text'
    | 'step2IconUrl'
    | 'step2IconAlt'
    | 'step2Title'
    | 'step2Text'
    | 'step3IconUrl'
    | 'step3IconAlt'
    | 'step3Title'
    | 'step3Text'
    | 'CTALabel'
    | 'imageUrl'
    | 'imageAlt'
  >;
};

export type ReadFinancingCalculatorQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadFinancingCalculatorQuery = { __typename?: 'Query' } & {
  readFinancingCalculator: { __typename?: 'FinancingCalculators' } & Pick<
    FinancingCalculators,
    | '_id'
    | 'title'
    | 'expandedText'
    | 'federalStateDropdownLabel'
    | 'capitalContributionLabel'
    | 'monthlyNetIncomeLabel'
    | 'calculateCTALabel'
    | 'maximumPurchasePriceLabel'
    | 'maximumLoanAmountLabel'
    | 'ghostButtonLabel'
    | 'iconUrl'
    | 'iconAlt'
    | 'mainCTALabel'
  >;
};

export type ReadFinancingCertificateQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadFinancingCertificateQuery = { __typename?: 'Query' } & {
  readFinancingCertificate: { __typename?: 'FinancingCertificates' } & Pick<
    FinancingCertificates,
    '_id' | 'title' | 'expandedText' | 'mainCTALabel' | 'imageAlt' | 'imageUrl'
  >;
};

export type ReadFinancingPartnerQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadFinancingPartnerQuery = { __typename?: 'Query' } & {
  readFinancingPartner: { __typename?: 'FinancingPartners' } & Pick<
    FinancingPartners,
    '_id' | 'fileAlt' | 'fileUrl'
  >;
};

export type ReadGeneralAppointmentByCountryQueryVariables = Exact<{
  country: Scalars['String'];
}>;

export type ReadGeneralAppointmentByCountryQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'GeneralAppointment' } & Pick<
    GeneralAppointment,
    | '_id'
    | 'buttonLabel'
    | 'generalText'
    | 'label'
    | 'name'
    | 'phone'
    | 'country'
  >;
};

export type ReadInterestRatesTableQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadInterestRatesTableQuery = { __typename?: 'Query' } & {
  readInterestRatesTable: { __typename?: 'InterestRatesTables' } & Pick<
    InterestRatesTables,
    | '_id'
    | 'ghostButtonLabel'
    | 'mainCTALabel'
    | 'row1Label'
    | 'row2Label'
    | 'row3Label'
    | 'subtitle'
    | 'tableHintText'
    | 'timestampLabel'
    | 'title'
  >;
};

export type ReadLocalMarketQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadLocalMarketQuery = { __typename?: 'Query' } & {
  data: { __typename?: 'LocalMarket' } & Pick<
    LocalMarket,
    | '_id'
    | 'name'
    | 'title'
    | 'text'
    | 'buttonLabel'
    | 'imageAlt'
    | 'imageUrl'
    | 'imageKey'
  >;
};

export type ReadOurRepresentativeExampleQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadOurRepresentativeExampleQuery = { __typename?: 'Query' } & {
  readOurRepresentativeExample: {
    __typename?: 'OurRepresentativeExamples';
  } & Pick<
    OurRepresentativeExamples,
    | '_id'
    | 'title'
    | 'CTALabel'
    | 'expandedText'
    | 'table1Row1Label'
    | 'table1Row2Label'
    | 'table1Row3Label'
    | 'table1Row4Label'
    | 'table1Title'
    | 'table2Row1Label'
    | 'table2Row2Label'
    | 'table2Row3Label'
    | 'table2Row4Label'
    | 'table2Row5Label'
    | 'table2Row6Label'
    | 'table2Title'
    | 'table3Row1Label'
    | 'table3Row2Label'
    | 'table3Row3Label'
    | 'table3Title'
    | 'table4Row1Label'
    | 'table4Row1Value'
    | 'table4Row2Label'
    | 'table4Row2Value'
    | 'table4Row3Label'
    | 'table4Row3Value'
    | 'table4Row4Label'
    | 'table4Row4Value'
    | 'table4Row5Label'
    | 'table4Row5Value'
    | 'table4Title'
  >;
};

export type ReadVideoQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadVideoQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    | { __typename?: 'AppointmentSection' }
    | { __typename?: 'BenefitSection' }
    | { __typename?: 'ContentSection' }
    | { __typename?: 'FaqSection' }
    | { __typename?: 'OwnerSettingsSection' }
    | ({ __typename?: 'VideoSection' } & Pick<
        VideoSection,
        '_id' | 'title' | 'subtitle' | 'text' | 'ctaLabel' | 'videoURL'
      >)
    | { __typename?: 'VirtualTourSection' }
  >;
};

export type ReadVirtualToursQueryVariables = Exact<{
  filter: CmsSectionFilterInput;
}>;

export type ReadVirtualToursQuery = { __typename?: 'Query' } & {
  data: Array<
    { __typename?: 'VirtualTourSection' } & Pick<
      VirtualTourSection,
      '_id' | 'imageUrl' | 'imageKey' | 'imageAlt' | 'text' | 'title'
    >
  >;
};

export type ReadSellerLandingPageQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type ReadSellerLandingPageQuery = { __typename?: 'Query' } & {
  readSellerLandingPage: { __typename?: 'SellerLandingPage' } & Pick<
    SellerLandingPage,
    | '_id'
    | 'imageUrl'
    | 'imageKey'
    | 'imageAlt'
    | 'country'
    | 'locale'
    | 'metaDescription'
    | 'metaKeywords'
    | 'metaTitle'
    | 'name'
    | 'subtitle'
    | 'title'
    | 'text'
  > & {
      sections?: Maybe<
        Array<
          { __typename?: 'SellerLPSection' } & Pick<
            SellerLpSection,
            'type' | 'title' | 'orderIndex' | 'recordIds'
          >
        >
      >;
    };
};

export type ConvertChfToEurQueryVariables = Exact<{
  amount: Scalars['Float'];
}>;

export type ConvertChfToEurQuery = { __typename?: 'Query' } & {
  value: Query['convertChfToEur'];
};

export type ConvertEurToChfQueryVariables = Exact<{
  amount: Scalars['Float'];
}>;

export type ConvertEurToChfQuery = { __typename?: 'Query' } & {
  value: Query['convertEurToChf'];
};

export type ExperimentFieldsFragment = { __typename?: 'Experiment' } & Pick<
  Experiment,
  | '_id'
  | 'type'
  | 'caseAWeight'
  | 'caseBWeight'
  | 'isActive'
  | 'selectedCase'
  | 'name'
  | 'registrationBy'
  | 'evaluationWidgetVersion'
>;

export type ExperimentsQueryVariables = Exact<{
  savedExperiments?: Maybe<
    Array<SavedExperimentsInput> | SavedExperimentsInput
  >;
}>;

export type ExperimentsQuery = { __typename?: 'Query' } & {
  experiments: Array<{ __typename?: 'Experiment' } & ExperimentFieldsFragment>;
};

export type GetEurToChfRateQueryVariables = Exact<{ [key: string]: never }>;

export type GetEurToChfRateQuery = { __typename?: 'Query' } & {
  rate: Query['getEurToChfRate'];
};

export type GetProvenExpertRatingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProvenExpertRatingQuery = { __typename?: 'Query' } & {
  getProvenExpertRating: { __typename?: 'Rating' } & Pick<
    Rating,
    'ratingValue' | 'reviewCount'
  >;
};

export type ContentFragment = { __typename?: 'ContentSection' } & Pick<
  ContentSection,
  '_id' | 'title' | 'contentHTML' | 'scriptId' | 'scriptSrc'
>;

export type GetContentPageQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetContentPageQuery = { __typename?: 'Query' } & {
  cmsPage: { __typename?: 'CmsPage' } & Pick<CmsPage, 'name'> & {
      blocks: Array<
        { __typename?: 'CmsBlock' } & Pick<CmsBlock, 'isActive' | 'title'> & {
            sections: Array<
              | { __typename?: 'AppointmentSection' }
              | { __typename?: 'BenefitSection' }
              | ({ __typename?: 'ContentSection' } & ContentFragment)
              | { __typename?: 'FaqSection' }
              | { __typename?: 'OwnerSettingsSection' }
              | { __typename?: 'VideoSection' }
              | { __typename?: 'VirtualTourSection' }
            >;
          }
      >;
    };
};

export type SuggestedPropertyQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type SuggestedPropertyQuery = { __typename?: 'Query' } & {
  suggestedProperty: { __typename?: 'SuggestedProperty' } & Pick<
    SuggestedProperty,
    | 'title'
    | 'propertyId'
    | 'type'
    | 'livingArea'
    | 'landArea'
    | 'price'
    | 'currency'
  > & {
      address?: Maybe<
        { __typename?: 'SuggestedPropertyAddress' } & Pick<
          SuggestedPropertyAddress,
          | 'floor'
          | 'street'
          | 'houseNumber'
          | 'postCode'
          | 'city'
          | 'numberOfFloors'
        >
      >;
      image: Array<
        { __typename?: 'SuggestedPropertyAttachment' } & Pick<
          SuggestedPropertyAttachment,
          'id' | 'alt' | 'name'
        >
      >;
      equipment?: Maybe<
        { __typename?: 'SuggestedPropertyEquipment' } & Pick<
          SuggestedPropertyEquipment,
          'numberOfGarages' | 'numberOfParkingSpace' | 'provision'
        >
      >;
      condition?: Maybe<
        { __typename?: 'SuggestedPropertyCondition' } & Pick<
          SuggestedPropertyCondition,
          'modernizationYear' | 'buildingYear'
        >
      >;
      area?: Maybe<
        { __typename?: 'SuggestedPropertyArea' } & Pick<
          SuggestedPropertyArea,
          | 'numberOfRooms'
          | 'numberOfBedrooms'
          | 'numberOfBathrooms'
          | 'numberOfParkingSpaces'
        >
      >;
    };
};

export type CreateExposeRequestMutationVariables = Exact<{
  emailTemplate: ExposeRequestEmailTemplate;
  input: ExposeRequestInput;
}>;

export type CreateExposeRequestMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'createExposeRequest'
>;

export type GetPdfLinkMutationVariables = Exact<{
  id: Scalars['String'];
  token: Scalars['String'];
}>;

export type GetPdfLinkMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'getPdfLink'
>;

export type SetUserIdForExposesMutationVariables = Exact<{
  addressId: Scalars['Int'];
  userEmail: Scalars['String'];
  userId: Scalars['String'];
}>;

export type SetUserIdForExposesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'setUserIdForExposes'
>;

export type ApprovedExposeRequestsMutationVariables = Exact<{
  userId: Scalars['String'];
}>;

export type ApprovedExposeRequestsMutation = { __typename?: 'Mutation' } & {
  approvedExposeRequests: Array<
    { __typename?: 'Task' } & Pick<Task, 'id'> & {
        elements: { __typename?: 'TaskElements' } & Pick<
          TaskElements,
          'Verantwortung' | 'relatedEstateId' | 'relatedAddressId'
        >;
      }
  >;
};

export type ReadUserPurchaseIntentsQueryVariables = Exact<{
  searchProfileId: Scalars['String'];
}>;

export type ReadUserPurchaseIntentsQuery = { __typename?: 'Query' } & {
  readUserPurchaseIntents: Array<
    { __typename?: 'DetailedPropertyPurchaseIntent' } & Pick<
      DetailedPropertyPurchaseIntent,
      | 'id'
      | 'title'
      | 'address'
      | 'price'
      | 'rooms'
      | 'area'
      | 'img'
      | 'externalId'
    > & {
        offers: Array<
          { __typename?: 'PropertyPurchaseIntent' } & Pick<
            PropertyPurchaseIntent,
            | '_id'
            | 'recipient'
            | 'price'
            | 'confirmFunding'
            | 'dateCreated'
            | 'propertyId'
          >
        >;
      }
  >;
};

export type FinancingDataFieldsFragment = {
  __typename?: 'FinancingData';
} & Pick<FinancingData, 'financingType' | 'countryCode' | 'title'>;

export type FinancingLocationFragment = { __typename?: 'LocationFinancing' } & {
  address: { __typename?: 'AddressFinancing' } & Pick<
    AddressFinancing,
    'city' | 'postCode' | 'locality' | 'neighborhood' | 'placeName' | 'region'
  >;
  coordinates: { __typename?: 'Coordinates' } & Pick<
    Coordinates,
    'latitude' | 'longitude'
  >;
};

export type FinancingPropositionsFieldsFragment = {
  __typename?: 'FinancingProposition';
} & Pick<
  FinancingProposition,
  'debitRateCommitment' | 'maximalPurchasePrice'
> & {
    propositions: Array<
      { __typename?: 'Propositions' } & Pick<
        Propositions,
        | 'financingPropositionId'
        | 'acceptanceDeadline'
        | 'loanAmount'
        | 'shouldInterest'
        | 'effectiveInterest'
        | 'rateMonthly'
        | 'description'
        | 'identifier'
        | 'feasibility'
        | 'rank'
        | 'totalRatePerMonth'
        | 'fixedInterestRateInYearsMinMax'
        | 'suggestionOption'
      > & {
          financingBuildingBlocks: Array<
            { __typename?: 'FinancingBuildingBlocks' } & Pick<
              FinancingBuildingBlocks,
              | 'type'
              | 'residualDebtAfterTheEndOfTheFixedInterestPeriod'
              | 'closingRate'
              | 'dateOfLastInstallment'
              | 'numberOfInstallments'
              | 'repaymentRateInPercent'
              | 'costOverTotalTerm'
              | 'loanAmount'
              | 'commitmentInterestFreePeriodInMonths'
              | 'shouldInterest'
              | 'effectiveInterest'
              | 'rateMonthly'
              | 'productProvider'
            > & {
                annuityDetails?: Maybe<
                  { __typename?: 'AnnuityDetails' } & Pick<
                    AnnuityDetails,
                    'fixedInterestInYears' | 'specialRepaymentAnnually'
                  > & {
                      repayment?: Maybe<
                        { __typename?: 'Repayment' } & Pick<
                          Repayment,
                          | 'type'
                          | 'amortizationRateInPercent'
                          | 'startOfRepayment'
                        >
                      >;
                    }
                >;
              }
          >;
          optimizationMessages?: Maybe<
            { __typename?: 'OptimizationMessages' } & {
              feasibility?: Maybe<
                Array<
                  { __typename?: 'Feasibility' } & Pick<
                    Feasibility,
                    'code' | 'text'
                  >
                >
              >;
            }
          >;
        }
    >;
    leadRating?: Maybe<
      { __typename?: 'LeadRating' } & Pick<
        LeadRating,
        'successRating' | 'feasibilityRating'
      > & {
          effortRating?: Maybe<
            { __typename?: 'EffortRating' } & Pick<
              EffortRating,
              'rating' | 'explanations'
            >
          >;
        }
    >;
    extraCosts?: Maybe<
      { __typename?: 'ExtraCosts' } & Pick<
        ExtraCosts,
        'brokerageFee' | 'notaryFee' | 'realEstateTransferTax'
      >
    >;
  };

export type FinancingVariantDataFieldsFragment = {
  __typename?: 'FinancingVariantData';
} & Pick<
  FinancingVariantData,
  | 'name'
  | 'index'
  | 'reason'
  | 'propertyType'
  | 'radius'
  | 'livingArea'
  | 'buildingYear'
  | 'rentalIncomePerMonth'
  | 'propertyUse'
  | 'searchProgress'
  | 'brokerFeePercent'
  | 'brokerageFee'
  | 'buildingCosts'
  | 'desiredLoanAmount'
  | 'equityCapital'
  | 'estimatedRemainingDebt'
  | 'landPrice'
  | 'landRegisterEntry'
  | 'monthlyRate'
  | 'payoutDate'
  | 'propertyValue'
  | 'purchasePrice'
  | 'desiredRate'
  | 'federalState'
  | 'landTax'
  | 'notary'
  | 'debitRateCommitment'
  | 'rateType'
  | 'repaymentRate'
> & {
    location: { __typename?: 'LocationFinancing' } & FinancingLocationFragment;
  };

export type FinancingFieldsFragment = { __typename?: 'Financing' } & Pick<
  Financing,
  | '_id'
  | 'marketingParameters'
  | 'searchProfileId'
  | 'onlineExposeId'
  | 'updatedAt'
> & {
    financingData: {
      __typename?: 'FinancingData';
    } & FinancingDataFieldsFragment;
    financingVariants: Array<
      { __typename?: 'FinancingVariant' } & Pick<
        FinancingVariant,
        'lastDateOfInterestRatesNotification' | 'propositionsLastSync'
      > & {
          financingVariantData: {
            __typename?: 'FinancingVariantData';
          } & FinancingVariantDataFieldsFragment;
          financingPropositionMaxPurchasePrice?: Maybe<
            {
              __typename?: 'FinancingProposition';
            } & FinancingPropositionsFieldsFragment
          >;
          financingPropositions: Array<
            {
              __typename?: 'FinancingProposition';
            } & FinancingPropositionsFieldsFragment
          >;
          personalFinancing: { __typename?: 'PersonalFinancingEntity' } & Pick<
            PersonalFinancingEntity,
            'mainEarner' | 'householdNetIncome' | 'age'
          >;
          financingSuggestions: {
            __typename?: 'FinancingSuggestionsEntity';
          } & Pick<
            FinancingSuggestionsEntity,
            'isCertificateSuggestion' | 'isRequestedFinancing'
          >;
        }
    >;
    vonPollPropertyData?: Maybe<
      { __typename?: 'VonPollPropertyData' } & Pick<
        VonPollPropertyData,
        | 'propertyId'
        | 'purchasePrice'
        | 'postCode'
        | 'propertyType'
        | 'livingArea'
        | 'buildingYear'
      > & {
          images: Array<
            { __typename?: 'VonPollPropertyImage' } & Pick<
              VonPollPropertyImage,
              'id' | 'alt' | 'group' | 'link' | 'width' | 'height'
            >
          >;
        }
    >;
  };

export type ExpandFinancingRadiusMutationVariables = Exact<{
  input: UpdateFinancingPropositionsInput;
}>;

export type ExpandFinancingRadiusMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'expandFinancingRadius'
>;

export type RemoveFinancingMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type RemoveFinancingMutation = { __typename?: 'Mutation' } & {
  removeFinancing?: Maybe<
    { __typename?: 'Financing' } & Pick<Financing, '_id'>
  >;
};

export type SendVonPollFinanceMutationVariables = Exact<{
  input: SendVonPollFinanceInput;
}>;

export type SendVonPollFinanceMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'sendVonPollFinance'
>;

export type UpdateFinancingVariantNameMutationVariables = Exact<{
  input: UpdateFinancingVariantNameInput;
}>;

export type UpdateFinancingVariantNameMutation = { __typename?: 'Mutation' } & {
  updateFinancingVariantName: { __typename?: 'Financing' } & Pick<
    Financing,
    '_id'
  >;
};

export type FinancingQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type FinancingQuery = { __typename?: 'Query' } & {
  financing?: Maybe<{ __typename?: 'Financing' } & FinancingFieldsFragment>;
};

export type FinancingsQueryVariables = Exact<{
  ownerId?: Maybe<Scalars['String']>;
}>;

export type FinancingsQuery = { __typename?: 'Query' } & {
  financings: Array<
    { __typename?: 'Financing' } & Pick<Financing, '_id'> & {
        financingVariants: Array<
          { __typename?: 'FinancingVariant' } & {
            financingVariantData: {
              __typename?: 'FinancingVariantData';
            } & Pick<FinancingVariantData, 'reason' | 'propertyType'> & {
                location: {
                  __typename?: 'LocationFinancing';
                } & FinancingLocationFragment;
              };
          }
        >;
      }
  >;
};

export type GetFinancingVonPollPropertyDetailsQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type GetFinancingVonPollPropertyDetailsQuery = {
  __typename?: 'Query';
} & {
  getFinancingVonPollPropertyDetails: {
    __typename?: 'GetFinancingVonPollPropertyDetails';
  } & Pick<
    GetFinancingVonPollPropertyDetails,
    | 'purchasePrice'
    | 'postCode'
    | 'propertyType'
    | 'livingArea'
    | 'buildingYear'
  > & {
      images: Array<
        { __typename?: 'VonPollPropertyImage' } & Pick<
          VonPollPropertyImage,
          'id' | 'alt' | 'group' | 'link' | 'width' | 'height'
        >
      >;
      equipment: { __typename?: 'VonPollPropertyEquipment' } & Pick<
        VonPollPropertyEquipment,
        'rentalIncomeIs' | 'rentalIncomeShould'
      >;
    };
};

export type DeletePropertyPurchaseIntentMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeletePropertyPurchaseIntentMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'deletePropertyPurchaseIntent'>;

export type ReadPropertyPurchaseIntentsQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type ReadPropertyPurchaseIntentsQuery = { __typename?: 'Query' } & {
  readPropertyPurchaseIntents: Array<
    { __typename?: 'PropertyPurchaseIntent' } & Pick<
      PropertyPurchaseIntent,
      | '_id'
      | 'recipient'
      | 'confirmFunding'
      | 'price'
      | 'dateCreated'
      | 'propertyId'
    >
  >;
};

export type ReadPurchaseIntentQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ReadPurchaseIntentQuery = { __typename?: 'Query' } & {
  readPurchaseIntent: { __typename?: 'PurchaseIntent' } & Pick<
    PurchaseIntent,
    | 'name'
    | 'surname'
    | 'email'
    | 'phone'
    | 'postalCode'
    | 'city'
    | 'confirmFunding'
    | '_id'
    | 'notes'
    | 'price'
  > & {
      otherPersons?: Maybe<
        Array<
          { __typename?: 'PurchaseIntentPerson' } & Pick<
            PurchaseIntentPerson,
            'name' | 'surname' | 'email' | 'phone' | 'postalCode' | 'city'
          >
        >
      >;
    };
};

export type CreateFinancingMutationVariables = Exact<{
  input: CreateFinancingInput;
}>;

export type CreateFinancingMutation = { __typename?: 'Mutation' } & {
  createFinancing: { __typename?: 'Financing' } & Pick<Financing, '_id'>;
};

export type UpdateFinancingMutationVariables = Exact<{
  input: UpdateFinancingInput;
}>;

export type UpdateFinancingMutation = { __typename?: 'Mutation' } & {
  updateFinancing: { __typename?: 'Financing' } & Pick<Financing, '_id'>;
};

export type UpdateFinancingPropositionsMutationVariables = Exact<{
  input: UpdateFinancingPropositionsInput;
}>;

export type UpdateFinancingPropositionsMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'updateFinancingPropositions'>;

export type GetFinancingPropositionsMutationVariables = Exact<{
  input: GetFinancingPropositionsInput;
}>;

export type GetFinancingPropositionsMutation = { __typename?: 'Mutation' } & {
  getFinancingPropositions: Array<
    {
      __typename?: 'FinancingProposition';
    } & FinancingPropositionsFieldsFragment
  >;
};

export type ReadEuropaceInterestRatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ReadEuropaceInterestRatesQuery = { __typename?: 'Query' } & {
  readEuropaceInterestRates: Array<
    {
      __typename?: 'FinancingProposition';
    } & FinancingPropositionsFieldsFragment
  >;
};

export type ValuationFieldsFragment = { __typename?: 'Valuation' } & Pick<
  Valuation,
  'confidence' | 'currency' | 'rentGross' | 'rentNet' | 'salePrice' | 'value'
> & {
    coordinates?: Maybe<
      { __typename?: 'Coordinates' } & Pick<
        Coordinates,
        'longitude' | 'latitude'
      >
    >;
    rentGrossRange?: Maybe<
      { __typename?: 'ValueRange' } & Pick<ValueRange, 'lower' | 'upper'>
    >;
    rentNetRange?: Maybe<
      { __typename?: 'ValueRange' } & Pick<ValueRange, 'lower' | 'upper'>
    >;
    salePriceRange?: Maybe<
      { __typename?: 'ValueRange' } & Pick<ValueRange, 'lower' | 'upper'>
    >;
    scores?: Maybe<{ __typename?: 'Score' } & Pick<Score, 'location'>>;
    status?: Maybe<
      { __typename?: 'Status' } & Pick<Status, 'code' | 'message'>
    >;
    valueRange?: Maybe<
      { __typename?: 'ValueRange' } & Pick<ValueRange, 'lower' | 'upper'>
    >;
  };

export type ConfirmGuestUserMutationVariables = Exact<{
  input: ConfirmGuestUserInput;
}>;

export type ConfirmGuestUserMutation = { __typename?: 'Mutation' } & {
  confirmGuestUser: { __typename?: 'GuestUser' } & Pick<GuestUser, '_id'>;
};

export type ConvertPropertyMutationVariables = Exact<{
  ownerId: Scalars['String'];
}>;

export type ConvertPropertyMutation = { __typename?: 'Mutation' } & {
  convertProperty: { __typename?: 'Property' } & PropertyFieldsFragment;
};

export type CreatePropertyDossierMutationVariables = Exact<{
  input: PropertyDossierInput;
}>;

export type CreatePropertyDossierMutation = { __typename?: 'Mutation' } & {
  createPropertyDossier?: Maybe<
    { __typename?: 'Property' } & PropertyFieldsFragment
  >;
};

export type CreatePropertyMutationVariables = Exact<{
  input: CreatePropertyInput;
}>;

export type CreatePropertyMutation = { __typename?: 'Mutation' } & {
  createProperty: { __typename?: 'Property' } & Pick<Property, '_id'>;
};

export type CreateTemporaryPropertyMutationVariables = Exact<{
  input: CreatePropertyInput;
}>;

export type CreateTemporaryPropertyMutation = { __typename?: 'Mutation' } & {
  createTemporaryProperty: { __typename?: 'TemporaryProperty' } & Pick<
    TemporaryProperty,
    '_id'
  >;
};

export type UpdatePropertyAlertsMutationVariables = Exact<{
  input: UpdatePropertyInput;
}>;

export type UpdatePropertyAlertsMutation = { __typename?: 'Mutation' } & {
  updatePropertyAlerts: { __typename?: 'Property' } & Pick<Property, '_id'>;
};

export type UpdatePropertyPortfolioMutationVariables = Exact<{
  input: UpdatePropertyPortfolioInput;
}>;

export type UpdatePropertyPortfolioMutation = { __typename?: 'Mutation' } & {
  updatePropertyPortfolio: { __typename?: 'Property' } & Pick<
    Property,
    '_id'
  > & {
      propertyPortfolioData?: Maybe<
        { __typename?: 'PropertyPortfolioData' } & Pick<
          PropertyPortfolioData,
          'title'
        >
      >;
    };
};

export type UpdatePropertyMutationVariables = Exact<{
  input: UpdatePropertyInput;
}>;

export type UpdatePropertyMutation = { __typename?: 'Mutation' } & {
  updateProperty: { __typename?: 'Property' } & Pick<Property, '_id'>;
};

export type RemovePropertyMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type RemovePropertyMutation = { __typename?: 'Mutation' } & {
  removeProperty?: Maybe<{ __typename?: 'Property' } & Pick<Property, '_id'>>;
};

export type ResendGuestUserCodeMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;

export type ResendGuestUserCodeMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'resendGuestUserCode'
>;

export type UpdatePropertyFavoriteSearchProfilesListMutationVariables = Exact<{
  input: UpdatePropertyFavoriteSpListInput;
}>;

export type UpdatePropertyFavoriteSearchProfilesListMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'updatePropertyFavoriteSearchProfilesList'>;

export type VerifyGuestUserMutationVariables = Exact<{
  input: VerifyGuestUserInput;
}>;

export type VerifyGuestUserMutation = { __typename?: 'Mutation' } & {
  verifyGuestUser: { __typename?: 'GuestUser' } & Pick<
    GuestUser,
    '_id' | 'verified'
  >;
};

export type GetPropertyFavoriteSearchProfilesListQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetPropertyFavoriteSearchProfilesListQuery = {
  __typename?: 'Query';
} & {
  getPropertyFavoriteSearchProfilesList: Array<
    { __typename?: 'SearchProfile' } & Pick<
      SearchProfile,
      '_id' | 'title' | 'createdAt'
    > & {
        searchProfileData: { __typename?: 'SearchProfileData' } & Pick<
          SearchProfileData,
          | 'radius'
          | 'purchasePriceMax'
          | 'numberOfRoomsMin'
          | 'livingAreaMin'
          | 'landAreaMin'
          | 'propertyCode'
        > & {
            location: { __typename?: 'LocationSearchProfile' } & {
              address: { __typename?: 'AddressSearchProfileEntity' } & Pick<
                AddressSearchProfileEntity,
                'city' | 'postCode' | 'neighborhood' | 'locality'
              >;
              coordinates: { __typename?: 'Coordinates' } & Pick<
                Coordinates,
                'latitude' | 'longitude'
              >;
            };
          };
      }
  >;
};

export type PropertyValuationQueryVariables = Exact<{
  input: ValuationInput;
}>;

export type PropertyValuationQuery = { __typename?: 'Query' } & {
  propertyValuation: Array<
    { __typename?: 'PropertyValuation' } & Pick<PropertyValuation, 'date'> & {
        valuation: { __typename?: 'Valuation' } & Pick<
          Valuation,
          | 'currency'
          | 'salePrice'
          | 'rentGross'
          | 'rentNet'
          | 'value'
          | 'confidence'
        > & {
            coordinates?: Maybe<
              { __typename?: 'Coordinates' } & Pick<
                Coordinates,
                'longitude' | 'latitude'
              >
            >;
            salePriceRange?: Maybe<
              { __typename?: 'ValueRange' } & Pick<
                ValueRange,
                'lower' | 'upper'
              >
            >;
            rentGrossRange?: Maybe<
              { __typename?: 'ValueRange' } & Pick<
                ValueRange,
                'lower' | 'upper'
              >
            >;
            rentNetRange?: Maybe<
              { __typename?: 'ValueRange' } & Pick<
                ValueRange,
                'lower' | 'upper'
              >
            >;
            valueRange?: Maybe<
              { __typename?: 'ValueRange' } & Pick<
                ValueRange,
                'lower' | 'upper'
              >
            >;
            scores?: Maybe<{ __typename?: 'Score' } & Pick<Score, 'location'>>;
            status?: Maybe<
              { __typename?: 'Status' } & Pick<Status, 'code' | 'message'>
            >;
          };
      }
  >;
};

export type CreatePurchaseIntentMutationVariables = Exact<{
  input: PurchaseIntentInput;
}>;

export type CreatePurchaseIntentMutation = { __typename?: 'Mutation' } & {
  createPurchaseIntent: { __typename?: 'PurchaseIntent' } & Pick<
    PurchaseIntent,
    '_id'
  >;
};

export type ConvertSearchProfileMutationVariables = Exact<{
  ownerId: Scalars['String'];
  searchProfileId?: Maybe<Scalars['String']>;
}>;

export type ConvertSearchProfileMutation = { __typename?: 'Mutation' } & {
  convertSearchProfile: { __typename?: 'Property' } & Pick<Property, '_id'>;
};

export type CreateSearchProfileMutationVariables = Exact<{
  input: CreateSearchProfileInput;
}>;

export type CreateSearchProfileMutation = { __typename?: 'Mutation' } & {
  createSearchProfile: { __typename?: 'SearchProfile' } & Pick<
    SearchProfile,
    '_id'
  >;
};

export type CreateTemporarySearchProfileMutationVariables = Exact<{
  input: CreateTemporarySearchProfileInput;
}>;

export type CreateTemporarySearchProfileMutation = {
  __typename?: 'Mutation';
} & {
  createTemporarySearchProfile: {
    __typename?: 'TemporarySearchProfile';
  } & Pick<TemporarySearchProfile, '_id' | 'countryCode' | 'title'> & {
      searchProfileData: {
        __typename?: 'SearchProfileData';
      } & SearchProfileDataFieldsFragment;
    };
};

export type RemoveSearchProfileMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type RemoveSearchProfileMutation = { __typename?: 'Mutation' } & {
  removeSearchProfile?: Maybe<
    { __typename?: 'SearchProfile' } & Pick<SearchProfile, '_id'>
  >;
};

export type UpdateSearchProfileMutationVariables = Exact<{
  input: UpdateSearchProfileInput;
}>;

export type UpdateSearchProfileMutation = { __typename?: 'Mutation' } & {
  updateSearchProfile: { __typename?: 'SearchProfile' } & Pick<
    SearchProfile,
    '_id'
  >;
};

export type UpdateTemporarySearchProfileMutationVariables = Exact<{
  input: UpdateSearchProfileInput;
}>;

export type UpdateTemporarySearchProfileMutation = {
  __typename?: 'Mutation';
} & {
  updateTemporarySearchProfile: {
    __typename?: 'TemporarySearchProfile';
  } & Pick<TemporarySearchProfile, '_id'>;
};

export type SuggestedPropertiesQueryVariables = Exact<{
  filter?: Maybe<SuggestedPropertiesFilterInput>;
  searchProfileId?: Maybe<Scalars['String']>;
}>;

export type SuggestedPropertiesQuery = { __typename?: 'Query' } & {
  suggestedProperties: { __typename?: 'PaginatedSuggestedProperties' } & {
    page: Array<
      { __typename?: 'SuggestedProperty' } & Pick<
        SuggestedProperty,
        | 'propertyId'
        | 'externalId'
        | 'internalId'
        | 'title'
        | 'regionalAddition'
        | 'postalCode'
        | 'city'
        | 'price'
        | 'currency'
        | 'livingArea'
        | 'landArea'
        | 'numberOfRooms'
        | 'type'
        | 'country'
      > & {
          image: Array<
            { __typename?: 'SuggestedPropertyAttachment' } & Pick<
              SuggestedPropertyAttachment,
              'name'
            >
          >;
        }
    >;
    pageData: { __typename?: 'PageData' } & Pick<
      PageData,
      'count' | 'limit' | 'offset'
    >;
  };
};

export type AcceptInvitationMutationVariables = Exact<{
  invitationId: Scalars['String'];
}>;

export type AcceptInvitationMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'acceptInvitation'
>;

export type CheckInvitationHealthQueryVariables = Exact<{
  input: CheckInviteHealth;
}>;

export type CheckInvitationHealthQuery = { __typename?: 'Query' } & Pick<
  Query,
  'checkInvitationHealth'
>;

export type AdministrativeDivisionFieldsFragment = {
  __typename?: 'AdministrativeDivision';
} & {
  divisionLevel100?: Maybe<
    { __typename?: 'Division' } & Pick<Division, 'officialId'>
  >;
};

export type PoiFieldsFragment = { __typename?: 'POI' } & Pick<
  Poi,
  'category' | 'distance' | 'name' | 'subcategory'
> & {
    location?: Maybe<
      { __typename?: 'OptionalLocation' } & {
        address?: Maybe<
          { __typename?: 'OptionalAddress' } & Pick<
            OptionalAddress,
            'city' | 'houseNumber' | 'postCode' | 'street'
          >
        >;
        coordinates?: Maybe<
          { __typename?: 'OptionalCoordinates' } & Pick<
            OptionalCoordinates,
            'latitude' | 'longitude'
          >
        >;
      }
    >;
  };

export type PropertyDataFieldsFragment = { __typename?: 'PropertyData' } & Pick<
  PropertyData,
  | 'buildingYear'
  | 'landArea'
  | 'livingArea'
  | 'numberOfBathrooms'
  | 'numberOfBedrooms'
  | 'numberOfIndoorParkingSpaces'
  | 'numberOfOutdoorParkingSpaces'
  | 'numberOfRooms'
  | 'numberOfUnits'
  | 'energyLabel'
  | 'balconyArea'
  | 'floorNumber'
  | 'numberOfFloorsInBuilding'
  | 'renovationYear'
  | 'annualRentIncome'
  | 'isNew'
  | 'hasPool'
  | 'hasSauna'
> & {
    condition?: Maybe<
      { __typename?: 'PropertyCondition' } & Pick<
        PropertyCondition,
        'bathrooms' | 'flooring' | 'kitchen' | 'masonry' | 'overall' | 'windows'
      >
    >;
    location: { __typename?: 'PropertyLocation' } & {
      address: { __typename?: 'Address' } & Pick<
        Address,
        'city' | 'houseNumber' | 'postCode' | 'street'
      >;
      coordinates: { __typename?: 'Coordinates' } & Pick<
        Coordinates,
        'latitude' | 'longitude'
      >;
    };
    parkingSpaces?: Maybe<
      { __typename?: 'ParkingSpaces' } & Pick<
        ParkingSpaces,
        | 'duplex'
        | 'garage'
        | 'multiStoryGarage'
        | 'undergroundGarage'
        | 'otherParkingLot'
        | 'carport'
        | 'parkingSpace'
      >
    >;
    propertyType: { __typename?: 'PropertyType' } & Pick<
      PropertyType,
      'code' | 'subcode'
    >;
    quality?: Maybe<
      { __typename?: 'PropertyQuality' } & Pick<
        PropertyQuality,
        'bathrooms' | 'flooring' | 'kitchen' | 'masonry' | 'overall' | 'windows'
      >
    >;
  };

export type PropertyPortfolioFieldsFragment = {
  __typename?: 'Property';
} & Pick<
  Property,
  | '_id'
  | 'title'
  | 'countryCode'
  | 'priceCurrency'
  | 'status'
  | 'valuationsLastSync'
  | 'onOfficePrice'
  | 'userStatus'
  | 'mapboxImageUrl'
  | 'createdAt'
  | 'isCreatedVia3rdPartyPortal'
  | 'isRealSellingProcessStarted'
  | 'isDigitalServiceLockedByBroker'
  | 'isUnderConstruction'
  | 'photos'
> & {
    propertyData: { __typename?: 'PropertyData' } & Pick<
      PropertyData,
      | 'buildingYear'
      | 'livingArea'
      | 'landArea'
      | 'numberOfRooms'
      | 'numberOfBathrooms'
      | 'numberOfIndoorParkingSpaces'
      | 'numberOfOutdoorParkingSpaces'
      | 'numberOfUnits'
      | 'annualRentIncome'
      | 'energyLabel'
    > & {
        location: { __typename?: 'PropertyLocation' } & {
          coordinates: { __typename?: 'Coordinates' } & Pick<
            Coordinates,
            'latitude' | 'longitude'
          >;
          address: { __typename?: 'Address' } & Pick<
            Address,
            'city' | 'postCode' | 'street' | 'houseNumber'
          >;
        };
        propertyType: { __typename?: 'PropertyType' } & Pick<
          PropertyType,
          'code'
        >;
      };
    energyCertificate?: Maybe<
      { __typename?: 'OnOfficeEnergyCertificate' } & Pick<
        OnOfficeEnergyCertificate,
        'energyEfficiencyClass'
      >
    >;
    valuations?: Maybe<
      Array<
        { __typename?: 'PropertyValuation' } & Pick<
          PropertyValuation,
          'date'
        > & {
            valuation: { __typename?: 'Valuation' } & Pick<
              Valuation,
              'salePrice'
            >;
          }
      >
    >;
    propertyPortfolioData?: Maybe<
      { __typename?: 'PropertyPortfolioData' } & Pick<
        PropertyPortfolioData,
        'title' | 'shareTitle' | 'coverImageUrl'
      >
    >;
    marketingStatistics?: Maybe<
      { __typename?: 'MarketingStatistics' } & {
        generalStatistics?: Maybe<
          { __typename?: 'MarketingGeneralStatistics' } & Pick<
            MarketingGeneralStatistics,
            'highestBid'
          >
        >;
        statisticsPerWeek?: Maybe<
          Array<
            { __typename?: 'MarketingStatisticsPerWeek' } & {
              bids?: Maybe<
                Array<{ __typename?: 'Bid' } & Pick<Bid, 'customerId'>>
              >;
            }
          >
        >;
      }
    >;
  };

export type SocioEconomicsFieldsFragment = {
  __typename?: 'SocioEconomicsEntity';
} & Pick<SocioEconomicsEntity, 'officialId' | 'officialName' | 'type'> & {
    years?: Maybe<
      Array<
        { __typename?: 'YearEntity' } & Pick<YearEntity, 'year'> & {
            values: Array<
              { __typename?: 'SocioEconomicsValueEntity' } & Pick<
                SocioEconomicsValueEntity,
                'absoluteValue' | 'relativeValue' | 'subtype'
              >
            >;
          }
      >
    >;
  };

export type PropertyStatisticsFieldsFragment = { __typename?: 'Item' } & Pick<
  Item,
  'groupId' | 'groupLowerBound' | 'groupUpperBound'
> & {
    statistics?: Maybe<
      Array<
        { __typename?: 'Statistic' } & Pick<
          Statistic,
          'metric' | 'type' | 'value'
        > & {
            parameters?: Maybe<
              { __typename?: 'Parameters' } & Pick<Parameters, 'percentileRank'>
            >;
          }
      >
    >;
  };

export type PropertyFieldsFragment = { __typename?: 'Property' } & Pick<
  Property,
  | '_id'
  | 'appointmentId'
  | 'countryCode'
  | 'dealType'
  | 'dossierUrl'
  | 'dossierUrlLastSync'
  | 'priceHubbleDossierId'
  | 'ratingReason'
  | 'timeHorizont'
  | 'valuationsLastSync'
  | 'valuationType'
  | 'poiLastSync'
  | 'propertyStatisticsLastSync'
  | 'userStatus'
  | 'tour360Link'
  | 'photos'
  | 'title'
  | 'completedSellingSteps'
  | 'onOfficePrice'
  | 'priceCurrency'
  | 'isDigitalServiceActivated'
  | 'isActivitiesTabUnlocked'
  | 'isContractSigned'
  | 'isMarketingDashboardUnlocked'
  | 'isHiddenSendEmailToBrokerAlert'
  | 'isRealSellingProcessStarted'
  | 'isBrokerNotifiedOfBuyerFinder'
  | 'isUnderConstruction'
  | 'status'
  | 'externalId'
  | 'onOfficeId'
  | 'favoriteSearchProfiles'
  | 'disabledServices'
  | 'servicesDisabledAt'
  | 'createdAt'
  | 'mapboxImageUrl'
  | 'isReadOnlyAccess'
> & {
    socioEconomics?: Maybe<
      Array<
        { __typename?: 'SocioEconomicsEntity' } & SocioEconomicsFieldsFragment
      >
    >;
    propertyData: { __typename?: 'PropertyData' } & PropertyDataFieldsFragment;
    propertyPortfolioData?: Maybe<
      { __typename?: 'PropertyPortfolioData' } & Pick<
        PropertyPortfolioData,
        | 'title'
        | 'shareTitle'
        | 'coverImageFilename'
        | 'coverImageUrl'
        | 'coverImageKey'
      >
    >;
    valuations?: Maybe<
      Array<
        { __typename?: 'PropertyValuation' } & Pick<
          PropertyValuation,
          'date'
        > & {
            valuation: { __typename?: 'Valuation' } & ValuationFieldsFragment;
          }
      >
    >;
    actualValuations?: Maybe<
      Array<
        { __typename?: 'PropertyValuation' } & Pick<
          PropertyValuation,
          'date'
        > & {
            valuation: { __typename?: 'Valuation' } & ValuationFieldsFragment;
          }
      >
    >;
    administrativeDivision?: Maybe<
      {
        __typename?: 'AdministrativeDivision';
      } & AdministrativeDivisionFieldsFragment
    >;
    poi?: Maybe<
      { __typename?: 'PropertyPOI' } & {
        catering?: Maybe<
          { __typename?: 'Catering' } & {
            bar?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            cafe?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            restaurant?: Maybe<
              Array<{ __typename?: 'POI' } & PoiFieldsFragment>
            >;
          }
        >;
        education?: Maybe<
          { __typename?: 'Education' } & {
            kindergarten?: Maybe<
              Array<{ __typename?: 'POI' } & PoiFieldsFragment>
            >;
            primary_school?: Maybe<
              Array<{ __typename?: 'POI' } & PoiFieldsFragment>
            >;
            secondary_school?: Maybe<
              Array<{ __typename?: 'POI' } & PoiFieldsFragment>
            >;
            university?: Maybe<
              Array<{ __typename?: 'POI' } & PoiFieldsFragment>
            >;
          }
        >;
        health?: Maybe<
          { __typename?: 'Health' } & {
            clinic?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            dentist?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            doctor?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            hospital?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            nursing_home?: Maybe<
              Array<{ __typename?: 'POI' } & PoiFieldsFragment>
            >;
            pharmacy?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            veterinary?: Maybe<
              Array<{ __typename?: 'POI' } & PoiFieldsFragment>
            >;
          }
        >;
        leisure?: Maybe<
          { __typename?: 'Leisure' } & {
            entertainment?: Maybe<
              Array<{ __typename?: 'POI' } & PoiFieldsFragment>
            >;
            park?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            playground?: Maybe<
              Array<{ __typename?: 'POI' } & PoiFieldsFragment>
            >;
            sport?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
          }
        >;
        shopping?: Maybe<
          { __typename?: 'Shopping' } & {
            convenience_store?: Maybe<
              Array<{ __typename?: 'POI' } & PoiFieldsFragment>
            >;
            supermarket?: Maybe<
              Array<{ __typename?: 'POI' } & PoiFieldsFragment>
            >;
          }
        >;
        transport?: Maybe<
          { __typename?: 'Transport' } & {
            bus?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            tram?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            subway?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            train?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            airport?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            highway_ramp?: Maybe<
              Array<{ __typename?: 'POI' } & PoiFieldsFragment>
            >;
          }
        >;
      }
    >;
    locationScores?: Maybe<
      { __typename?: 'LocationScores' } & Pick<
        LocationScores,
        'catering' | 'family' | 'health' | 'leisure' | 'shopping' | 'noise'
      >
    >;
    propertyStatistics?: Maybe<
      Array<{ __typename?: 'Item' } & PropertyStatisticsFieldsFragment>
    >;
    buildingProjects?: Maybe<
      { __typename?: 'BuildingProjectsEntity' } & Pick<
        BuildingProjectsEntity,
        'totalItems'
      > & {
          items: Array<
            { __typename?: 'BuildingProjectsItemEntity' } & Pick<
              BuildingProjectsItemEntity,
              | 'title'
              | 'distance'
              | 'isPreciselyLocated'
              | 'provider'
              | 'status'
            > & {
                location: { __typename?: 'PropertyLocation' } & {
                  coordinates: { __typename?: 'Coordinates' } & Pick<
                    Coordinates,
                    'latitude' | 'longitude'
                  >;
                  address: { __typename?: 'Address' } & Pick<
                    Address,
                    'postCode' | 'city' | 'street' | 'houseNumber'
                  >;
                };
              }
          >;
        }
    >;
    energyCertificate?: Maybe<
      { __typename?: 'OnOfficeEnergyCertificate' } & Pick<
        OnOfficeEnergyCertificate,
        | 'energyCertificate'
        | 'finalEnergyDemand'
        | 'energyCertificateValidUntil'
        | 'energyEfficiencyClass'
        | 'energySource'
        | 'typeOfHeating'
      >
    >;
    relatedShop?: Maybe<
      { __typename?: 'RelatedShop' } & Pick<
        RelatedShop,
        'message' | 'messageSentAt' | 'senderFirstName' | 'senderLastName'
      >
    >;
    propertyWidgetFeedback?: Maybe<
      { __typename?: 'PropertyWidgetFeedback' } & Pick<
        PropertyWidgetFeedback,
        'rating' | 'numericValue' | 'priceHubbleId' | 'text'
      >
    >;
    description?: Maybe<
      { __typename?: 'PropertyDescription' } & Pick<
        PropertyDescription,
        | 'objectDescription'
        | 'equipmentDescription'
        | 'locationDescription'
        | 'otherInformation'
        | 'planUrls'
      >
    >;
    provision?: Maybe<
      { __typename?: 'Provision' } & Pick<
        Provision,
        'value' | 'isAgreedWithSeller'
      >
    >;
  };

export type SimilarPropertyFieldsFragment = {
  __typename?: 'Comparable';
} & Pick<
  Comparable,
  | 'buildingYear'
  | 'currency'
  | 'daysOnMarket'
  | 'dealType'
  | 'description'
  | 'distance'
  | 'distanceGroceryStore'
  | 'distanceHospital'
  | 'distancePublicTransport'
  | 'endDate'
  | 'floorNumber'
  | 'hasLift'
  | 'hasParkingSpaces'
  | 'isActive'
  | 'isExclusive'
  | 'landArea'
  | 'livingArea'
  | 'noise'
  | 'numberOfBedrooms'
  | 'numberOfIndoorParkingSpaces'
  | 'numberOfOutdoorParkingSpaces'
  | 'numberOfRooms'
  | 'offerId'
  | 'rentGross'
  | 'rentNet'
  | 'salePrice'
  | 'shareableLink'
  | 'slope'
  | 'startDate'
  | 'title'
  | 'url'
  | 'volume'
> & {
    address?: Maybe<
      { __typename?: 'ComparableAddress' } & Pick<
        ComparableAddress,
        'city' | 'houseNumber' | 'postCode' | 'street'
      >
    >;
    contactInfo?: Maybe<
      { __typename?: 'ContactInfoEntity' } & Pick<ContactInfoEntity, 'raw'> & {
          inquiryPerson?: Maybe<{ __typename?: 'Raw' } & Pick<Raw, 'raw'>>;
          organization?: Maybe<{ __typename?: 'Raw' } & Pick<Raw, 'raw'>>;
          viewPerson?: Maybe<{ __typename?: 'Raw' } & Pick<Raw, 'raw'>>;
        }
    >;
    coordinates?: Maybe<
      { __typename?: 'Coordinates' } & Pick<
        Coordinates,
        'latitude' | 'longitude'
      >
    >;
    images: Array<{ __typename?: 'ImageEntity' } & Pick<ImageEntity, 'url'>>;
    propertyType: { __typename?: 'PropertyType' } & Pick<
      PropertyType,
      'code' | 'subcode'
    >;
  };

export type DeleteInvitationMutationVariables = Exact<{
  inviteId: Scalars['String'];
}>;

export type DeleteInvitationMutation = { __typename?: 'Mutation' } & {
  deleteInvitation: { __typename?: 'Invite' } & Pick<Invite, '_id'>;
};

export type EditInvitationMutationVariables = Exact<{
  input: UpdateInviteInput;
}>;

export type EditInvitationMutation = { __typename?: 'Mutation' } & {
  editInvitation: { __typename?: 'Invite' } & Pick<Invite, '_id'>;
};

export type ExportPdfMutationVariables = Exact<{ [key: string]: never }>;

export type ExportPdfMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'exportPdf'
>;

export type NotifyBrokerForBuyerFinderMutationVariables = Exact<{
  propertyId: Scalars['String'];
  countSearchProfiles: Scalars['String'];
  isBlurredGraphic?: Maybe<Scalars['Boolean']>;
}>;

export type NotifyBrokerForBuyerFinderMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'notifyBrokerForBuyerFinder'>;

export type SendFeedbackOfOwnerPortalMutationVariables = Exact<{
  input: SendFeedbackOfOwnerPortalInput;
}>;

export type SendFeedbackOfOwnerPortalMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'sendFeedbackOfOwnerPortal'>;

export type SharePropertyMutationVariables = Exact<{
  input: CreateInviteInput;
}>;

export type SharePropertyMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'shareProperty'
>;

export type FindRelatedSearchProfilesQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type FindRelatedSearchProfilesQuery = { __typename?: 'Query' } & {
  findRelatedSearchProfiles: Array<
    { __typename?: 'SearchProfile' } & Pick<
      SearchProfile,
      '_id' | 'title' | 'createdAt'
    > & {
        searchProfileData: { __typename?: 'SearchProfileData' } & Pick<
          SearchProfileData,
          | 'radius'
          | 'purchasePriceMax'
          | 'numberOfRoomsMin'
          | 'livingAreaMin'
          | 'landAreaMin'
          | 'propertyCode'
        > & {
            location: { __typename?: 'LocationSearchProfile' } & {
              address: { __typename?: 'AddressSearchProfileEntity' } & Pick<
                AddressSearchProfileEntity,
                'city' | 'postCode' | 'neighborhood' | 'locality'
              >;
              coordinates: { __typename?: 'Coordinates' } & Pick<
                Coordinates,
                'latitude' | 'longitude'
              >;
            };
          };
      }
  >;
};

export type GeneralMarketReportByCountryQueryVariables = Exact<{
  country: Scalars['String'];
}>;

export type GeneralMarketReportByCountryQuery = { __typename?: 'Query' } & {
  data?: Maybe<
    { __typename?: 'GeneralMarketReport' } & Pick<
      GeneralMarketReport,
      '_id' | 'country' | 'fileUrl' | 'awsFolderKey'
    >
  >;
};

export type GetTemporaryPropertyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetTemporaryPropertyQuery = { __typename?: 'Query' } & {
  getTemporaryProperty?: Maybe<
    { __typename?: 'TemporaryProperty' } & {
      propertyData: {
        __typename?: 'PropertyData';
      } & PropertyDataFieldsFragment;
    }
  >;
};

export type InvitesQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type InvitesQuery = { __typename?: 'Query' } & {
  invites: Array<
    { __typename?: 'Invite' } & Pick<
      Invite,
      | '_id'
      | 'email'
      | 'invitationPrivileges'
      | 'name'
      | 'ownerId'
      | 'propertyId'
      | 'status'
      | 'surname'
    >
  >;
};

export type IsochronesQueryVariables = Exact<{
  input: IsochronesInput;
}>;

export type IsochronesQuery = { __typename?: 'Query' } & {
  isochrones: { __typename?: 'Isochrones' } & {
    densities?: Maybe<
      { __typename?: 'Densities' } & Pick<
        Densities,
        'catering' | 'education' | 'health' | 'leisure' | 'shopping'
      >
    >;
    isochrone: { __typename?: 'Isochrone' } & {
      geometry: { __typename?: 'Geometry' } & Pick<
        Geometry,
        'type' | 'coordinates'
      >;
    };
  };
};

export type MarketReportQueryVariables = Exact<{
  filters: MarketReportInput;
}>;

export type MarketReportQuery = { __typename?: 'Query' } & {
  marketReport?: Maybe<
    { __typename?: 'MarketReport' } & Pick<
      MarketReport,
      '_id' | 'cities' | 'fileUrl' | 'awsFolderKey'
    >
  >;
};

export type NavigationPropertiesQueryVariables = Exact<{
  pagination?: Maybe<PaginationArgs>;
  filter?: Maybe<PropertyFilterInput>;
}>;

export type NavigationPropertiesQuery = { __typename?: 'Query' } & {
  readProperties: { __typename?: 'PaginatedProperties' } & {
    page: Array<
      { __typename?: 'Property' } & Pick<Property, '_id' | 'title'> & {
          propertyData: { __typename?: 'PropertyData' } & {
            location: { __typename?: 'PropertyLocation' } & {
              coordinates: { __typename?: 'Coordinates' } & Pick<
                Coordinates,
                'longitude' | 'latitude'
              >;
              address: { __typename?: 'Address' } & Pick<
                Address,
                'street' | 'houseNumber' | 'city' | 'postCode'
              >;
            };
          };
          propertyPortfolioData?: Maybe<
            { __typename?: 'PropertyPortfolioData' } & Pick<
              PropertyPortfolioData,
              'title'
            >
          >;
        }
    >;
    pageData: { __typename?: 'PageData' } & Pick<
      PageData,
      'count' | 'limit' | 'offset'
    >;
  };
};

export type OurDestinationsQueryVariables = Exact<{ [key: string]: never }>;

export type OurDestinationsQuery = { __typename?: 'Query' } & {
  ourDestinations: Array<
    { __typename?: 'OurDestinations' } & Pick<
      OurDestinations,
      '_id' | 'title' | 'description'
    > & {
        destinations: Array<
          { __typename?: 'Destination' } & Pick<
            Destination,
            | 'orderIndex'
            | 'name'
            | 'description'
            | 'url'
            | 'imageUrl'
            | 'imageAlt'
          >
        >;
      }
  >;
};

export type PaginatedPropertyPortfolioQueryVariables = Exact<{
  pagination?: Maybe<PaginationArgs>;
  filter?: Maybe<PropertyFilterInput>;
}>;

export type PaginatedPropertyPortfolioQuery = { __typename?: 'Query' } & {
  readProperties: { __typename?: 'PaginatedProperties' } & {
    page: Array<{ __typename?: 'Property' } & PropertyPortfolioFieldsFragment>;
    pageData: { __typename?: 'PageData' } & Pick<PageData, 'count'>;
  };
};

export type PropertiesQueryVariables = Exact<{
  ownerId?: Maybe<Scalars['String']>;
  excludeExternalProperties?: Maybe<Scalars['Boolean']>;
}>;

export type PropertiesQuery = { __typename?: 'Query' } & {
  properties: Array<
    { __typename?: 'Property' } & Pick<
      Property,
      '_id' | 'isCreatedVia3rdPartyPortal'
    > & {
        propertyData: { __typename?: 'PropertyData' } & {
          location: { __typename?: 'PropertyLocation' } & {
            coordinates: { __typename?: 'Coordinates' } & Pick<
              Coordinates,
              'longitude' | 'latitude'
            >;
            address: { __typename?: 'Address' } & Pick<
              Address,
              'city' | 'houseNumber' | 'postCode' | 'street'
            >;
          };
        };
      }
  >;
};

export type PropertyDefaultFiltersQueryVariables = Exact<{
  filter?: Maybe<PropertyDefaultFiltersFilterInput>;
}>;

export type PropertyDefaultFiltersQuery = { __typename?: 'Query' } & {
  propertyDefaultFilters: { __typename?: 'PropertyDefaultFilters' } & Pick<
    PropertyDefaultFilters,
    | 'propertyCodes'
    | 'minBuildingYear'
    | 'maxBuildingYear'
    | 'minLivingArea'
    | 'maxLivingArea'
    | 'minLandArea'
    | 'maxLandArea'
    | 'minNumberOfRooms'
    | 'maxNumberOfRooms'
    | 'minNumberOfBathrooms'
    | 'maxNumberOfBathrooms'
    | 'minNumberOfIndoorParkingSpaces'
    | 'maxNumberOfIndoorParkingSpaces'
    | 'minNumberOfOutdoorParkingSpaces'
    | 'maxNumberOfOutdoorParkingSpaces'
    | 'minPrice'
    | 'maxPrice'
    | 'energyLabels'
  >;
};

export type PropertyPortfolioQueryVariables = Exact<{
  ownerId?: Maybe<Scalars['String']>;
  excludeExternalProperties?: Maybe<Scalars['Boolean']>;
}>;

export type PropertyPortfolioQuery = { __typename?: 'Query' } & {
  properties: Array<
    { __typename?: 'Property' } & PropertyPortfolioFieldsFragment
  >;
};

export type PropertyQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type PropertyQuery = { __typename?: 'Query' } & {
  property?: Maybe<{ __typename?: 'Property' } & PropertyFieldsFragment>;
};

export type ReadBuyerFinderSettingsQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type ReadBuyerFinderSettingsQuery = { __typename?: 'Query' } & {
  readBuyerFinderSettings?: Maybe<
    { __typename?: 'BuyerFinderSettings' } & Pick<
      BuyerFinderSettings,
      '_id' | 'locale' | 'name'
    > & {
        sections?: Maybe<
          Array<
            { __typename?: 'BuyerFinderSettingsSection' } & Pick<
              BuyerFinderSettingsSection,
              'type' | 'title' | 'orderIndex' | 'recordIds'
            >
          >
        >;
      }
  >;
};

export type ShopContactsQueryVariables = Exact<{
  priceHubbleId: Scalars['String'];
}>;

export type ShopContactsQuery = { __typename?: 'Query' } & {
  shopContacts?: Maybe<
    { __typename?: 'ShopContact' } & Pick<
      ShopContact,
      'address' | 'alias' | 'city' | 'email' | 'telephone' | 'zip'
    >
  >;
};

export type SimilarPropertiesQueryVariables = Exact<{
  input: OffersSearchInput;
}>;

export type SimilarPropertiesQuery = { __typename?: 'Query' } & {
  getOffers: { __typename?: 'Comparables' } & Pick<
    Comparables,
    'totalItems'
  > & {
      items: Array<
        { __typename?: 'Comparable' } & SimilarPropertyFieldsFragment
      >;
    };
};

export type TemporaryPropertyQueryVariables = Exact<{ [key: string]: never }>;

export type TemporaryPropertyQuery = { __typename?: 'Query' } & Pick<
  Query,
  'temporaryProperty'
>;

export type ReadUserAppointmentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ReadUserAppointmentsQuery = { __typename?: 'Query' } & {
  readUserAppointments: Array<
    { __typename?: 'TabAppointment' } & Pick<
      TabAppointment,
      | 'id'
      | 'rescheduleUntil'
      | 'reschedulingSecret'
      | 'startDate'
      | 'title'
      | 'userId'
      | 'zoomVideoLink'
    > & {
        property?: Maybe<
          { __typename?: 'SuggestedProperty' } & Pick<
            SuggestedProperty,
            'title' | 'postalCode' | 'city' | 'externalId' | 'propertyId'
          > & {
              image: Array<
                { __typename?: 'SuggestedPropertyAttachment' } & Pick<
                  SuggestedPropertyAttachment,
                  'id' | 'alt' | 'name'
                >
              >;
            }
        >;
      }
  >;
};

export type SuggestPropertyFieldsFragment = {
  __typename?: 'SuggestedProperty';
} & Pick<
  SuggestedProperty,
  | 'propertyId'
  | 'externalId'
  | 'title'
  | 'description'
  | 'equipmentDescription'
  | 'locationDescription'
  | 'otherInformation'
  | 'livingArea'
  | 'landArea'
  | 'numberOfRooms'
  | 'price'
  | 'currency'
  | 'latitude'
  | 'longitude'
  | 'city'
  | 'postalCode'
  | 'language'
  | 'status'
  | 'type'
  | 'tour360Link'
> & {
    image: Array<
      { __typename?: 'SuggestedPropertyAttachment' } & Pick<
        SuggestedPropertyAttachment,
        'name' | 'alt' | 'group' | 'width' | 'height'
      >
    >;
    condition?: Maybe<
      { __typename?: 'SuggestedPropertyCondition' } & Pick<
        SuggestedPropertyCondition,
        'buildingYear' | 'modernizationYear'
      >
    >;
    address?: Maybe<
      { __typename?: 'SuggestedPropertyAddress' } & Pick<
        SuggestedPropertyAddress,
        | 'floor'
        | 'numberOfFloors'
        | 'houseNumber'
        | 'city'
        | 'postCode'
        | 'street'
      >
    >;
    area?: Maybe<
      { __typename?: 'SuggestedPropertyArea' } & Pick<
        SuggestedPropertyArea,
        | 'numberOfRooms'
        | 'numberOfBathrooms'
        | 'numberOfBedrooms'
        | 'numberOfParkingSpaces'
        | 'balconyArea'
      >
    >;
    equipment?: Maybe<
      { __typename?: 'SuggestedPropertyEquipment' } & Pick<
        SuggestedPropertyEquipment,
        | 'numberOfGarages'
        | 'numberOfParkingSpace'
        | 'numberOfDuplexParkingSpaces'
        | 'numberOfUndergroundGarages'
        | 'numberOfOtherParkingSpaces'
        | 'provision'
      >
    >;
    energyCertificate?: Maybe<
      { __typename?: 'SuggestedPropertyEnergyCertificateEntity' } & Pick<
        SuggestedPropertyEnergyCertificateEntity,
        | 'finalEnergyDemand'
        | 'energyCertificateValidUntil'
        | 'energyEfficiencyClass'
        | 'energySource'
        | 'energyCertificate'
      >
    >;
    energyEquipment?: Maybe<
      { __typename?: 'SuggestedPropertyEnergyEquipmentEntity' } & Pick<
        SuggestedPropertyEnergyEquipmentEntity,
        | 'floorHeating'
        | 'districtHeating'
        | 'underfloorHeating'
        | 'furnaceHeating'
        | 'centralHeating'
        | 'alternative'
        | 'combinedHeatAndPowerPlant'
        | 'electro'
        | 'geothermalEnergy'
        | 'gas'
        | 'airWaterHeatPump'
        | 'oil'
        | 'pellet'
        | 'solar'
        | 'additionalDecentralizedHotWater'
      >
    >;
  };

export type GeneratePdfExposeLinkMutationVariables = Exact<{
  id: Scalars['String'];
  fileKey: Scalars['String'];
}>;

export type GeneratePdfExposeLinkMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'generatePdfExposeLink'
>;

export type ExposeRequestsQueryVariables = Exact<{
  searchProfileId?: Maybe<Scalars['String']>;
  financingId?: Maybe<Scalars['String']>;
  pagination?: Maybe<ExposeRequestsPaginationArgs>;
}>;

export type ExposeRequestsQuery = { __typename?: 'Query' } & {
  exposeRequests: { __typename?: 'PaginatedExposeRequests' } & {
    page: Array<
      { __typename?: 'ExposeRequest' } & Pick<
        ExposeRequest,
        '_id' | 'propertyId' | 'status' | 'fullAddress' | 'pdf' | 'financingId'
      > & {
          propertyDetails?: Maybe<
            { __typename?: 'SuggestedProperty' } & Pick<
              SuggestedProperty,
              | 'propertyId'
              | 'externalId'
              | 'title'
              | 'postalCode'
              | 'city'
              | 'price'
              | 'currency'
              | 'type'
              | 'livingArea'
              | 'landArea'
              | 'numberOfRooms'
              | 'latitude'
              | 'longitude'
              | 'regionalAddition'
            > & {
                image: Array<
                  { __typename?: 'SuggestedPropertyAttachment' } & Pick<
                    SuggestedPropertyAttachment,
                    'name'
                  >
                >;
                condition?: Maybe<
                  { __typename?: 'SuggestedPropertyCondition' } & Pick<
                    SuggestedPropertyCondition,
                    'buildingYear' | 'modernizationYear'
                  >
                >;
                address?: Maybe<
                  { __typename?: 'SuggestedPropertyAddress' } & Pick<
                    SuggestedPropertyAddress,
                    'floor' | 'numberOfFloors'
                  >
                >;
                area?: Maybe<
                  { __typename?: 'SuggestedPropertyArea' } & Pick<
                    SuggestedPropertyArea,
                    | 'numberOfBathrooms'
                    | 'numberOfBedrooms'
                    | 'numberOfParkingSpaces'
                  >
                >;
                equipment?: Maybe<
                  { __typename?: 'SuggestedPropertyEquipment' } & Pick<
                    SuggestedPropertyEquipment,
                    | 'numberOfGarages'
                    | 'provision'
                    | 'numberOfDuplexParkingSpaces'
                    | 'numberOfUndergroundGarages'
                    | 'numberOfOtherParkingSpaces'
                    | 'numberOfParkingSpace'
                  >
                >;
              }
          >;
        }
    >;
    pageData: { __typename?: 'PageData' } & Pick<
      PageData,
      'count' | 'limit' | 'offset'
    >;
  };
};

export type DislikePropertyMutationVariables = Exact<{
  propertyId: Scalars['String'];
  searchProfileId: Scalars['String'];
}>;

export type DislikePropertyMutation = { __typename?: 'Mutation' } & {
  dislikeProperty: { __typename?: 'SearchProfile' } & Pick<
    SearchProfile,
    'disliked'
  > & {
      liked: Array<
        { __typename?: 'LikedProperty' } & Pick<LikedProperty, 'id' | 'price'>
      >;
    };
};

export type LikePropertyMutationVariables = Exact<{
  propertyId: Scalars['String'];
  propertyPrice: Scalars['Float'];
  searchProfileId: Scalars['String'];
}>;

export type LikePropertyMutation = { __typename?: 'Mutation' } & {
  likeProperty: { __typename?: 'SearchProfile' } & Pick<
    SearchProfile,
    'disliked'
  > & {
      liked: Array<
        { __typename?: 'LikedProperty' } & Pick<LikedProperty, 'id' | 'price'>
      >;
    };
};

export type MarkPropertyUnsuitableMutationVariables = Exact<{
  propertyId: Scalars['String'];
  searchProfileId: Scalars['String'];
}>;

export type MarkPropertyUnsuitableMutation = { __typename?: 'Mutation' } & {
  markPropertyUnsuitable: { __typename?: 'SearchProfile' } & Pick<
    SearchProfile,
    'disliked'
  > & {
      liked: Array<
        { __typename?: 'LikedProperty' } & Pick<LikedProperty, 'id' | 'price'>
      >;
    };
};

export type SearchProfileDataFieldsFragment = {
  __typename?: 'SearchProfileData';
} & Pick<
  SearchProfileData,
  | 'landAreaMax'
  | 'landAreaMin'
  | 'livingAreaMax'
  | 'livingAreaMin'
  | 'numberOfBathroomsMax'
  | 'numberOfBathroomsMin'
  | 'numberOfRoomsMax'
  | 'numberOfRoomsMin'
  | 'propertyCode'
  | 'purchasePriceMax'
  | 'purchasePriceMin'
  | 'radius'
> & {
    location: { __typename?: 'LocationSearchProfile' } & {
      address: { __typename?: 'AddressSearchProfileEntity' } & Pick<
        AddressSearchProfileEntity,
        'city' | 'postCode' | 'locality' | 'neighborhood' | 'placeName'
      >;
      coordinates: { __typename?: 'Coordinates' } & Pick<
        Coordinates,
        'latitude' | 'longitude'
      >;
    };
  };

export type SearchProfileFieldsFragment = {
  __typename?: 'SearchProfile';
} & Pick<
  SearchProfile,
  | '_id'
  | 'countryCode'
  | 'title'
  | 'disliked'
  | 'isNotifyNewProperties'
  | 'isNotifyPriceChange'
  | 'isNotifyNewPropertiesSummary'
  | 'marketingParameters'
  | 'isCreatedVia3rdPartyPortal'
> & {
    searchProfileData: {
      __typename?: 'SearchProfileData';
    } & SearchProfileDataFieldsFragment;
    liked: Array<
      { __typename?: 'LikedProperty' } & Pick<LikedProperty, 'id' | 'price'>
    >;
    latestSuggestedProperties?: Maybe<
      Array<
        { __typename?: 'LatestSuggestedProperty' } & Pick<
          LatestSuggestedProperty,
          'id'
        >
      >
    >;
    priceOffers?: Maybe<
      Array<
        { __typename?: 'PriceOffer' } & Pick<
          PriceOffer,
          'propertyInternalId' | 'propertyPrice' | 'offeredPrice' | 'createdAt'
        >
      >
    >;
  };

export type SendExposeContactFormMutationVariables = Exact<{
  contactForm: ExposeContactForm;
  propertyId: Scalars['String'];
}>;

export type SendExposeContactFormMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'sendExposeContactForm'
>;

export type UpdatePriceOffersMutationVariables = Exact<{
  updatePriceOffersInput: UpdatePriceOffersInput;
}>;

export type UpdatePriceOffersMutation = { __typename?: 'Mutation' } & {
  updatePriceOffers: { __typename?: 'SearchProfile' } & Pick<
    SearchProfile,
    '_id'
  >;
};

export type SearchProfileQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type SearchProfileQuery = { __typename?: 'Query' } & {
  searchProfile?: Maybe<
    { __typename?: 'SearchProfile' } & SearchProfileFieldsFragment
  >;
};

export type SearchProfilesQueryVariables = Exact<{
  ownerId?: Maybe<Scalars['String']>;
}>;

export type SearchProfilesQuery = { __typename?: 'Query' } & {
  searchProfiles: Array<
    { __typename?: 'SearchProfile' } & Pick<SearchProfile, '_id' | 'title'> & {
        searchProfileData: { __typename?: 'SearchProfileData' } & Pick<
          SearchProfileData,
          'radius' | 'purchasePriceMax'
        > & {
            location: { __typename?: 'LocationSearchProfile' } & {
              address: { __typename?: 'AddressSearchProfileEntity' } & Pick<
                AddressSearchProfileEntity,
                'city' | 'postCode' | 'neighborhood' | 'locality'
              >;
              coordinates: { __typename?: 'Coordinates' } & Pick<
                Coordinates,
                'latitude' | 'longitude'
              >;
            };
          };
      }
  >;
};

export type TemporarySearchProfileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TemporarySearchProfileQuery = { __typename?: 'Query' } & Pick<
  Query,
  'temporarySearchProfile'
>;

export type CreateDownloadedFileActivityMutationVariables = Exact<{
  exposeId: Scalars['String'];
  fileName: Scalars['String'];
}>;

export type CreateDownloadedFileActivityMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'createDownloadedFileActivity'>;

export type ExposeDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ExposeDetailsQuery = { __typename?: 'Query' } & {
  exposeDetails: { __typename?: 'ExposeRequest' } & Pick<
    ExposeRequest,
    | '_id'
    | 'propertyId'
    | 'ownerId'
    | 'status'
    | 'pdf'
    | 'fullAddress'
    | 'countryCode'
    | 'valuationsLastSync'
    | 'propertyStatisticsLastSync'
    | 'poiLastSync'
  > & {
      propertyDetails?: Maybe<
        { __typename?: 'SuggestedProperty' } & SuggestPropertyFieldsFragment
      >;
      location: { __typename?: 'PropertyLocation' } & {
        address: { __typename?: 'Address' } & Pick<
          Address,
          'city' | 'houseNumber' | 'postCode' | 'street'
        >;
        coordinates: { __typename?: 'Coordinates' } & Pick<
          Coordinates,
          'latitude' | 'longitude'
        >;
      };
      socioEconomics?: Maybe<
        Array<
          { __typename?: 'SocioEconomicsEntity' } & SocioEconomicsFieldsFragment
        >
      >;
      valuations?: Maybe<
        Array<
          { __typename?: 'PropertyValuation' } & Pick<
            PropertyValuation,
            'date'
          > & {
              valuation: { __typename?: 'Valuation' } & ValuationFieldsFragment;
            }
        >
      >;
      locationScores?: Maybe<
        { __typename?: 'LocationScores' } & Pick<
          LocationScores,
          'catering' | 'family' | 'health' | 'leisure' | 'shopping' | 'noise'
        >
      >;
      propertyStatistics?: Maybe<
        Array<{ __typename?: 'Item' } & PropertyStatisticsFieldsFragment>
      >;
      buildingProjects?: Maybe<
        { __typename?: 'BuildingProjectsEntity' } & Pick<
          BuildingProjectsEntity,
          'totalItems'
        > & {
            items: Array<
              { __typename?: 'BuildingProjectsItemEntity' } & Pick<
                BuildingProjectsItemEntity,
                'title' | 'distance'
              >
            >;
          }
      >;
      poi?: Maybe<
        { __typename?: 'PropertyPOI' } & {
          catering?: Maybe<
            { __typename?: 'Catering' } & {
              bar?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
              cafe?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
              restaurant?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
            }
          >;
          education?: Maybe<
            { __typename?: 'Education' } & {
              kindergarten?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
              primary_school?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
              secondary_school?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
              university?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
            }
          >;
          health?: Maybe<
            { __typename?: 'Health' } & {
              clinic?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
              dentist?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
              doctor?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
              hospital?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
              nursing_home?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
              pharmacy?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
              veterinary?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
            }
          >;
          leisure?: Maybe<
            { __typename?: 'Leisure' } & {
              entertainment?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
              park?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
              playground?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
              sport?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
            }
          >;
          shopping?: Maybe<
            { __typename?: 'Shopping' } & {
              convenience_store?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
              supermarket?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
            }
          >;
          transport?: Maybe<
            { __typename?: 'Transport' } & {
              bus?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
              tram?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
              subway?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
              train?: Maybe<Array<{ __typename?: 'POI' } & PoiFieldsFragment>>;
              airport?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
              highway_ramp?: Maybe<
                Array<{ __typename?: 'POI' } & PoiFieldsFragment>
              >;
            }
          >;
        }
      >;
      provision?: Maybe<
        { __typename?: 'Provision' } & Pick<
          Provision,
          'value' | 'isAgreedWithSeller'
        >
      >;
    };
};

export type GetExposeDocumentsQueryVariables = Exact<{
  exposeId: Scalars['String'];
}>;

export type GetExposeDocumentsQuery = { __typename?: 'Query' } & {
  getExposeDocuments: Array<
    { __typename?: 'PropertyFile' } & Pick<
      PropertyFile,
      | 'type'
      | 'originalname'
      | 'filename'
      | 'title'
      | 'content'
      | 'documentAttribute'
      | 'url'
      | 'isPublished'
    >
  >;
};

export type ReadUserContractAppointmentsQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type ReadUserContractAppointmentsQuery = { __typename?: 'Query' } & {
  readUserContractAppointments: Array<
    { __typename?: 'TabAppointment' } & Pick<
      TabAppointment,
      | 'id'
      | 'rescheduleUntil'
      | 'reschedulingSecret'
      | 'startDate'
      | 'title'
      | 'userId'
      | 'zoomVideoLink'
    >
  >;
};

export type AcceptPurchaseOfferMutationVariables = Exact<{
  input: AcceptPurchaseOfferInput;
}>;

export type AcceptPurchaseOfferMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'acceptPurchaseOffer'
>;

export type GetSellerExposePdfLinkMutationVariables = Exact<{
  id: Scalars['String'];
  token: Scalars['String'];
}>;

export type GetSellerExposePdfLinkMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'getSellerExposePdfLink'
>;

export type InitiateRealSellMutationVariables = Exact<{
  input: InitiateRealSellInput;
}>;

export type InitiateRealSellMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'initiateRealSell'
>;

export type InviteSellerMutationVariables = Exact<{
  estateId: Scalars['String'];
}>;

export type InviteSellerMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'inviteSeller'
>;

export type SendPropertyServicesMutationVariables = Exact<{
  propertyId: Scalars['String'];
  services: Array<OnOfficeServices> | OnOfficeServices;
}>;

export type SendPropertyServicesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'sendPropertyServices'
>;

export type SendSellerRegisteredNotificationMutationVariables = Exact<{
  userId: Scalars['String'];
}>;

export type SendSellerRegisteredNotificationMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'sendSellerRegisteredNotification'>;

export type SetDisabledShopServicesMutationVariables = Exact<{
  leaderId: Scalars['String'];
  disabledServices: Array<Scalars['String']> | Scalars['String'];
  propertyId: Scalars['String'];
  disabledPropertyServices: Array<Scalars['String']> | Scalars['String'];
}>;

export type SetDisabledShopServicesMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'setDisabledShopServices'>;

export type SetPropertyExposeStatusMutationVariables = Exact<{
  comment?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  status: PropertyExposeStatus;
}>;

export type SetPropertyExposeStatusMutation = { __typename?: 'Mutation' } & {
  setPropertyExposeStatus?: Maybe<
    { __typename?: 'PropertyExpose' } & Pick<
      PropertyExpose,
      'status' | 'comment'
    >
  >;
};

export type SetPropertyOwnerIdMutationVariables = Exact<{
  ownerId: Scalars['String'];
  addressId: Scalars['String'];
}>;

export type SetPropertyOwnerIdMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'setPropertyOwnerId'
>;

export type SetPropertyPortalStatusMutationVariables = Exact<{
  status: PropertyPortalStatusValues;
  propertyId: Scalars['String'];
}>;

export type SetPropertyPortalStatusMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'setPropertyPortalStatus'>;

export type UploadPropertyFilesMutationVariables = Exact<{
  propertyFiles: Array<PropertyFileInput> | PropertyFileInput;
  propertyId: Scalars['String'];
}>;

export type UploadPropertyFilesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'uploadPropertyFiles'
>;

export type AppointmentActivitiesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
}>;

export type AppointmentActivitiesQuery = { __typename?: 'Query' } & {
  appointmentActivities?: Maybe<
    Array<
      { __typename?: 'Activity' } & Pick<
        Activity,
        '_id' | 'dateTime' | 'title' | 'createdAt'
      >
    >
  >;
};

export type CheckBrokerSettingsPermissionQueryVariables = Exact<{
  brokerId: Scalars['String'];
  propertyId: Scalars['String'];
}>;

export type CheckBrokerSettingsPermissionQuery = { __typename?: 'Query' } & {
  checkBrokerSettingsPermission?: Maybe<
    { __typename?: 'BrokerPermissions' } & Pick<
      BrokerPermissions,
      'canChangeShopSettings' | 'canChangePropertySettings'
    >
  >;
};

export type DashboardStatusQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type DashboardStatusQuery = { __typename?: 'Query' } & {
  dashboardStatus: { __typename?: 'DashboardStatus' } & Pick<
    DashboardStatus,
    'isDashboardUnlocked' | 'isMarketingPaused'
  >;
};

export type DigitalServiceStatusQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type DigitalServiceStatusQuery = { __typename?: 'Query' } & Pick<
  Query,
  'digitalServiceStatus'
>;

export type DisabledShopServicesQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type DisabledShopServicesQuery = { __typename?: 'Query' } & Pick<
  Query,
  'disabledShopServices'
>;

export type DocumentsActivitiesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
}>;

export type DocumentsActivitiesQuery = { __typename?: 'Query' } & {
  documentsActivities?: Maybe<
    Array<
      { __typename?: 'Activity' } & Pick<
        Activity,
        '_id' | 'dateTime' | 'title' | 'createdAt'
      >
    >
  >;
};

export type DownloadedExposeActivitiesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
}>;

export type DownloadedExposeActivitiesQuery = { __typename?: 'Query' } & {
  downloadedExposeActivities?: Maybe<
    Array<
      { __typename?: 'Activity' } & Pick<
        Activity,
        '_id' | 'dateTime' | 'title' | 'createdAt'
      >
    >
  >;
};

export type ExposeActivitiesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
}>;

export type ExposeActivitiesQuery = { __typename?: 'Query' } & {
  exposeActivities?: Maybe<
    Array<
      { __typename?: 'Activity' } & Pick<
        Activity,
        '_id' | 'dateTime' | 'title' | 'createdAt'
      >
    >
  >;
};

export type GetGeneralStatisticsQueryVariables = Exact<{
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  propertyId: Scalars['String'];
}>;

export type GetGeneralStatisticsQuery = { __typename?: 'Query' } & {
  getGeneralStatistics?: Maybe<
    { __typename?: 'MarketingGeneralStatistics' } & Pick<
      MarketingGeneralStatistics,
      'viewingAppointments' | 'requestedExposes' | 'unlockedExposes'
    >
  >;
};

export type GetMarketingStatisticsQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type GetMarketingStatisticsQuery = { __typename?: 'Query' } & {
  getMarketingStatistics?: Maybe<
    { __typename?: 'MarketingStatistics' } & Pick<
      MarketingStatistics,
      'statisticsLastSync'
    > & {
        generalStatistics?: Maybe<
          { __typename?: 'MarketingGeneralStatistics' } & Pick<
            MarketingGeneralStatistics,
            | 'highestBid'
            | 'bids'
            | 'viewingAppointments'
            | 'advertisementViews'
            | 'requestedExposes'
            | 'unlockedExposes'
            | 'bookmarks'
            | 'impressions'
          >
        >;
        statisticsPerWeek?: Maybe<
          Array<
            { __typename?: 'MarketingStatisticsPerWeek' } & Pick<
              MarketingStatisticsPerWeek,
              'weekNumber' | 'dateFrom' | 'dateTo'
            > & {
                bids?: Maybe<
                  Array<
                    { __typename?: 'Bid' } & Pick<
                      Bid,
                      | 'customerId'
                      | 'offeredPrice'
                      | 'newOfferedPrice'
                      | 'customerOnOfficeId'
                      | 'bidOnOfficeId'
                      | 'customerCity'
                      | 'isAccepted'
                      | 'date'
                    >
                  >
                >;
                statistics?: Maybe<
                  { __typename?: 'MarketingGeneralStatistics' } & Pick<
                    MarketingGeneralStatistics,
                    | 'highestBid'
                    | 'bids'
                    | 'viewingAppointments'
                    | 'advertisementViews'
                    | 'requestedExposes'
                    | 'unlockedExposes'
                    | 'bookmarks'
                    | 'impressions'
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type GetPropertyExposeStatusQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type GetPropertyExposeStatusQuery = { __typename?: 'Query' } & {
  getPropertyExposeStatus?: Maybe<
    { __typename?: 'PropertyExpose' } & Pick<
      PropertyExpose,
      | 'pdf'
      | 'status'
      | 'comment'
      | 'exposeChangesHistory'
      | 'exposeCreatedAt'
      | 'exposeUpdatedAt'
      | 'exposeApprovedAt'
      | 'isExposeApprovedViaOnOffice'
    >
  >;
};

export type GetPropertyFilesQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type GetPropertyFilesQuery = { __typename?: 'Query' } & {
  getPropertyFiles?: Maybe<
    Array<
      { __typename?: 'PropertyFile' } & Pick<
        PropertyFile,
        | 'type'
        | 'originalname'
        | 'filename'
        | 'title'
        | 'content'
        | 'documentAttribute'
        | 'url'
        | 'isPublished'
      >
    >
  >;
};

export type GetPropertyServicesQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type GetPropertyServicesQuery = { __typename?: 'Query' } & {
  getPropertyServices?: Maybe<
    { __typename?: 'Services' } & Pick<
      Services,
      | 'SecretSale'
      | 'RealEstatePortals'
      | 'PremiumPlacement'
      | 'OfficialInformation'
      | 'Photos'
      | 'DronePhotos'
      | 'VirtualTours'
      | 'FloorPlans'
      | 'DigitalStaging'
      | 'EnergyCertificate'
      | 'Magazines'
      | 'HighCirculation'
      | 'SocialMedia'
    >
  >;
};

export type GetShopContactsQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type GetShopContactsQuery = { __typename?: 'Query' } & {
  getShopContacts?: Maybe<
    { __typename?: 'Shop' } & Pick<
      Shop,
      | 'city'
      | 'email'
      | 'firma'
      | 'homepage'
      | 'housenumber'
      | 'phone'
      | 'postcode'
      | 'street'
    >
  >;
};

export type HandOverActivitiesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
}>;

export type HandOverActivitiesQuery = { __typename?: 'Query' } & {
  handOverActivities?: Maybe<
    Array<
      { __typename?: 'Activity' } & Pick<
        Activity,
        '_id' | 'dateTime' | 'title' | 'createdAt'
      >
    >
  >;
};

export type LatestActivitiesQueryVariables = Exact<{
  limit: Scalars['Int'];
  propertyId: Scalars['String'];
}>;

export type LatestActivitiesQuery = { __typename?: 'Query' } & {
  latestActivities?: Maybe<
    Array<
      { __typename?: 'Activity' } & Pick<
        Activity,
        '_id' | 'dateTime' | 'title' | 'createdAt'
      >
    >
  >;
};

export type MarketingActivitiesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
}>;

export type MarketingActivitiesQuery = { __typename?: 'Query' } & {
  marketingActivities?: Maybe<
    Array<
      { __typename?: 'Activity' } & Pick<
        Activity,
        '_id' | 'dateTime' | 'title' | 'createdAt'
      >
    >
  >;
};

export type MarketingStatusQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type MarketingStatusQuery = { __typename?: 'Query' } & Pick<
  Query,
  'marketingStatus'
>;

export type NotaryActivitiesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
}>;

export type NotaryActivitiesQuery = { __typename?: 'Query' } & {
  notaryActivities?: Maybe<
    Array<
      { __typename?: 'Activity' } & Pick<
        Activity,
        '_id' | 'dateTime' | 'title' | 'createdAt'
      >
    >
  >;
};

export type ReadPropertiesQueryVariables = Exact<{
  pagination?: Maybe<PaginationArgs>;
  filter?: Maybe<PropertyFilterInput>;
  calculateTotalInfo?: Maybe<Scalars['Boolean']>;
  isEvaluationChanges?: Maybe<Scalars['Boolean']>;
  includeRelatedProperties?: Maybe<Scalars['Boolean']>;
}>;

export type ReadPropertiesQuery = { __typename?: 'Query' } & {
  readProperties: { __typename?: 'PaginatedProperties' } & Pick<
    PaginatedProperties,
    'totalValue'
  > & {
      page: Array<
        { __typename?: 'Property' } & Pick<
          Property,
          | '_id'
          | 'countryCode'
          | 'status'
          | 'valuationsLastSync'
          | 'appointmentId'
          | 'photos'
          | 'onOfficePrice'
          | 'priceCurrency'
          | 'externalId'
          | 'isDigitalServiceActivated'
          | 'isDigitalServiceLockedByBroker'
          | 'mapboxImageUrl'
          | 'isPreview'
          | 'ownerOnOfficeId'
          | 'title'
        > & {
            actualValuations?: Maybe<
              Array<
                { __typename?: 'PropertyValuation' } & Pick<
                  PropertyValuation,
                  'date'
                > & {
                    valuation: {
                      __typename?: 'Valuation';
                    } & ValuationFieldsFragment;
                  }
              >
            >;
            valuations?: Maybe<
              Array<
                { __typename?: 'PropertyValuation' } & Pick<
                  PropertyValuation,
                  'date'
                > & {
                    valuation: {
                      __typename?: 'Valuation';
                    } & ValuationFieldsFragment;
                  }
              >
            >;
            propertyData: { __typename?: 'PropertyData' } & Pick<
              PropertyData,
              'livingArea' | 'numberOfRooms'
            > & {
                propertyType: { __typename?: 'PropertyType' } & Pick<
                  PropertyType,
                  'code'
                >;
                location: { __typename?: 'PropertyLocation' } & {
                  coordinates: { __typename?: 'Coordinates' } & Pick<
                    Coordinates,
                    'longitude' | 'latitude'
                  >;
                  address: { __typename?: 'Address' } & Pick<
                    Address,
                    'street' | 'houseNumber' | 'city' | 'postCode'
                  >;
                };
              };
            marketingStatistics?: Maybe<
              { __typename?: 'MarketingStatistics' } & {
                generalStatistics?: Maybe<
                  { __typename?: 'MarketingGeneralStatistics' } & Pick<
                    MarketingGeneralStatistics,
                    'highestBid'
                  >
                >;
                statisticsPerWeek?: Maybe<
                  Array<
                    { __typename?: 'MarketingStatisticsPerWeek' } & {
                      bids?: Maybe<
                        Array<{ __typename?: 'Bid' } & Pick<Bid, 'customerId'>>
                      >;
                    }
                  >
                >;
              }
            >;
          }
      >;
      pageData: { __typename?: 'PageData' } & Pick<
        PageData,
        'count' | 'limit' | 'offset'
      >;
    };
};

export type PhoneCallsActivitiesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
}>;

export type PhoneCallsActivitiesQuery = { __typename?: 'Query' } & {
  phoneCallsActivities?: Maybe<
    Array<
      { __typename?: 'Activity' } & Pick<
        Activity,
        '_id' | 'dateTime' | 'title' | 'createdAt'
      >
    >
  >;
};

export type PriceOfferActivitiesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
}>;

export type PriceOfferActivitiesQuery = { __typename?: 'Query' } & {
  priceOfferActivities?: Maybe<
    Array<
      { __typename?: 'Activity' } & Pick<
        Activity,
        '_id' | 'dateTime' | 'title' | 'createdAt'
      >
    >
  >;
};

export type GetPropertyBrokerQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type GetPropertyBrokerQuery = { __typename?: 'Query' } & {
  getPropertyBroker?: Maybe<
    { __typename?: 'Broker' } & Pick<
      Broker,
      | 'id'
      | 'name'
      | 'surname'
      | 'phone'
      | 'email'
      | 'position'
      | 'qualification'
      | 'photo'
    >
  >;
};

export type PropertyOnOfficeViewQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type PropertyOnOfficeViewQuery = { __typename?: 'Query' } & {
  propertyOnOfficeView?: Maybe<
    { __typename?: 'Property' } & PropertyFieldsFragment
  >;
};

export type RequestedExposeActivitiesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
}>;

export type RequestedExposeActivitiesQuery = { __typename?: 'Query' } & {
  requestedExposeActivities?: Maybe<
    Array<
      { __typename?: 'Activity' } & Pick<
        Activity,
        '_id' | 'dateTime' | 'title' | 'createdAt'
      >
    >
  >;
};

export type SellingProgressQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type SellingProgressQuery = { __typename?: 'Query' } & Pick<
  Query,
  'sellingProgress'
>;

export type SentExposeActivitiesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
}>;

export type SentExposeActivitiesQuery = { __typename?: 'Query' } & {
  sentExposeActivities?: Maybe<
    Array<
      { __typename?: 'Activity' } & Pick<
        Activity,
        '_id' | 'dateTime' | 'title' | 'createdAt'
      >
    >
  >;
};

export type ShopEmailQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;

export type ShopEmailQuery = { __typename?: 'Query' } & {
  shopEmail?: Maybe<
    { __typename?: 'ShopEmail' } & Pick<ShopEmail, 'email' | 'name'>
  >;
};

export type VirtualTourActivitiesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  propertyId: Scalars['String'];
}>;

export type VirtualTourActivitiesQuery = { __typename?: 'Query' } & {
  virtualTourActivities?: Maybe<
    Array<
      { __typename?: 'Activity' } & Pick<
        Activity,
        '_id' | 'dateTime' | 'title' | 'createdAt'
      >
    >
  >;
};

export const AuthChallengeFieldsFragmentDoc = `
    fragment authChallengeFields on AuthChallenge {
  challengeName
  challengeParameters
  session
}
    `;
export const JwtTokenFieldsFragmentDoc = `
    fragment jwtTokenFields on JwtToken {
  token
  exp
  iat
  refreshToken
}
    `;
export const UserFieldsFragmentDoc = `
    fragment userFields on User {
  _id
  username
  email
  phone
  prefix
  roles
  name
  surname
  userState
  userType
  termsAndConditions
  createAccount
  communicationConsent
  temporaryPassword
  hasPendingValuations
  registrationBy
  regionalBrokerId
  slug
  salutation
  city
  street
  number
  needFinancingConsultation
  isPropertyOwner
  intendToSellProperty
  notifications {
    isNotifyPriceChange
    isNotifyNewProperties
    isNotifyPaymentOfInterestMonthly
    isNotifyGeneralNewsletter
    isNotifyMarketPrice
    isNotifyNewPropertiesSummary
    isNotifyFinancingInterestRates
    isNotifyEvaluationUpdates
  }
  isSentFeedback
}
    `;
export const FaqSectionFieldsFragmentDoc = `
    fragment faqSectionFields on FaqSection {
  _id
  title
  type
  expandedText
  order
}
    `;
export const OwnerSettingsSectionFieldsFragmentDoc = `
    fragment ownerSettingsSectionFields on OwnerSettingsSection {
  _id
  title
  type
  ownerSettingsUrl
  ownerSettingsKey
  isActive
  awsFolderKey
}
    `;
export const BenefitSectionFieldsFragmentDoc = `
    fragment benefitSectionFields on BenefitSection {
  _id
  title
  type
  expandedText
  imageUrl
  imageKey
  iconUrl
  iconKey
  awsFolderKey
  imageHint
  altText
  cta
  ctaLabel
}
    `;
export const AppointmentSectionFieldsFragmentDoc = `
    fragment appointmentSectionFields on AppointmentSection {
  _id
  title
  type
  avatarUrl
  avatarKey
  phone
  awsFolderKey
  timifyId
  brokerPosition
  brokerQualification
  altText
  email
  legalPopUpLabel
  legalPopUpText
}
    `;
export const VideoSectionFieldsFragmentDoc = `
    fragment videoSectionFields on VideoSection {
  _id
  title
  subtitle
  text
  ctaLabel
  videoURL
}
    `;
export const SectionFieldsFragmentDoc = `
    fragment sectionFields on CmsSectionUnion {
  ... on FaqSection {
    ...faqSectionFields
  }
  ... on OwnerSettingsSection {
    ...ownerSettingsSectionFields
  }
  ... on BenefitSection {
    ...benefitSectionFields
  }
  ... on AppointmentSection {
    ...appointmentSectionFields
  }
  ... on VideoSection {
    ...videoSectionFields
  }
}
    ${FaqSectionFieldsFragmentDoc}
${OwnerSettingsSectionFieldsFragmentDoc}
${BenefitSectionFieldsFragmentDoc}
${AppointmentSectionFieldsFragmentDoc}
${VideoSectionFieldsFragmentDoc}`;
export const BlockFieldsFragmentDoc = `
    fragment blockFields on CmsBlock {
  _id
  title
  type
  isActive
  sections(randomiseSection: true) {
    ...sectionFields
  }
}
    ${SectionFieldsFragmentDoc}`;
export const CountryFieldsFragmentDoc = `
    fragment countryFields on Country {
  _id
  code
  name
}
    `;
export const ExperimentFieldsFragmentDoc = `
    fragment experimentFields on Experiment {
  _id
  type
  caseAWeight
  caseBWeight
  isActive
  selectedCase
  name
  registrationBy
  evaluationWidgetVersion
}
    `;
export const ContentFragmentDoc = `
    fragment Content on ContentSection {
  _id
  title
  contentHTML
  scriptId
  scriptSrc
}
    `;
export const FinancingDataFieldsFragmentDoc = `
    fragment financingDataFields on FinancingData {
  financingType
  countryCode
  title
}
    `;
export const FinancingLocationFragmentDoc = `
    fragment financingLocation on LocationFinancing {
  address {
    city
    postCode
    locality
    neighborhood
    placeName
    region
  }
  coordinates {
    latitude
    longitude
  }
}
    `;
export const FinancingVariantDataFieldsFragmentDoc = `
    fragment financingVariantDataFields on FinancingVariantData {
  name
  index
  reason
  propertyType
  radius
  livingArea
  buildingYear
  rentalIncomePerMonth
  propertyUse
  searchProgress
  location {
    ...financingLocation
  }
  brokerFeePercent
  brokerageFee
  buildingCosts
  desiredLoanAmount
  equityCapital
  estimatedRemainingDebt
  landPrice
  landRegisterEntry
  monthlyRate
  payoutDate
  propertyValue
  purchasePrice
  desiredRate
  federalState
  landTax
  notary
  debitRateCommitment
  rateType
  repaymentRate
}
    ${FinancingLocationFragmentDoc}`;
export const FinancingPropositionsFieldsFragmentDoc = `
    fragment financingPropositionsFields on FinancingProposition {
  debitRateCommitment
  propositions {
    financingPropositionId
    acceptanceDeadline
    financingBuildingBlocks {
      type
      residualDebtAfterTheEndOfTheFixedInterestPeriod
      closingRate
      dateOfLastInstallment
      numberOfInstallments
      repaymentRateInPercent
      costOverTotalTerm
      loanAmount
      annuityDetails {
        fixedInterestInYears
        repayment {
          type
          amortizationRateInPercent
          startOfRepayment
        }
        specialRepaymentAnnually
      }
      commitmentInterestFreePeriodInMonths
      shouldInterest
      effectiveInterest
      rateMonthly
      productProvider
    }
    loanAmount
    shouldInterest
    effectiveInterest
    rateMonthly
    description
    identifier
    feasibility
    rank
    totalRatePerMonth
    fixedInterestRateInYearsMinMax
    suggestionOption
    optimizationMessages {
      feasibility {
        code
        text
      }
    }
  }
  leadRating {
    successRating
    effortRating {
      rating
      explanations
    }
    feasibilityRating
  }
  extraCosts {
    brokerageFee
    notaryFee
    realEstateTransferTax
  }
  maximalPurchasePrice
}
    `;
export const FinancingFieldsFragmentDoc = `
    fragment financingFields on Financing {
  _id
  financingData {
    ...financingDataFields
  }
  financingVariants {
    financingVariantData {
      ...financingVariantDataFields
    }
    financingPropositionMaxPurchasePrice {
      ...financingPropositionsFields
    }
    financingPropositions {
      ...financingPropositionsFields
    }
    personalFinancing {
      mainEarner
      householdNetIncome
      age
    }
    financingSuggestions {
      isCertificateSuggestion
      isRequestedFinancing
    }
    lastDateOfInterestRatesNotification
    propositionsLastSync
  }
  vonPollPropertyData {
    propertyId
    images {
      id
      alt
      group
      link
      width
      height
    }
    purchasePrice
    postCode
    propertyType
    livingArea
    buildingYear
  }
  marketingParameters
  searchProfileId
  onlineExposeId
  updatedAt
}
    ${FinancingDataFieldsFragmentDoc}
${FinancingVariantDataFieldsFragmentDoc}
${FinancingPropositionsFieldsFragmentDoc}`;
export const PropertyPortfolioFieldsFragmentDoc = `
    fragment propertyPortfolioFields on Property {
  _id
  title
  countryCode
  priceCurrency
  propertyData {
    location {
      coordinates {
        latitude
        longitude
      }
      address {
        city
        postCode
        street
        houseNumber
      }
    }
    buildingYear
    propertyType {
      code
    }
    livingArea
    landArea
    numberOfRooms
    numberOfBathrooms
    numberOfIndoorParkingSpaces
    numberOfOutdoorParkingSpaces
    numberOfUnits
    annualRentIncome
    energyLabel
  }
  energyCertificate {
    energyEfficiencyClass
  }
  status
  valuations {
    date
    valuation {
      salePrice
    }
  }
  valuationsLastSync
  onOfficePrice
  userStatus
  propertyPortfolioData {
    title
    shareTitle
    coverImageUrl
  }
  mapboxImageUrl
  createdAt
  isCreatedVia3rdPartyPortal
  isRealSellingProcessStarted
  isDigitalServiceLockedByBroker
  isUnderConstruction
  photos
  marketingStatistics {
    generalStatistics {
      highestBid
    }
    statisticsPerWeek {
      bids {
        customerId
      }
    }
  }
}
    `;
export const SocioEconomicsFieldsFragmentDoc = `
    fragment socioEconomicsFields on SocioEconomicsEntity {
  officialId
  officialName
  type
  years {
    year
    values {
      absoluteValue
      relativeValue
      subtype
    }
  }
}
    `;
export const PropertyDataFieldsFragmentDoc = `
    fragment propertyDataFields on PropertyData {
  buildingYear
  condition {
    bathrooms
    flooring
    kitchen
    masonry
    overall
    windows
  }
  landArea
  livingArea
  location {
    address {
      city
      houseNumber
      postCode
      street
    }
    coordinates {
      latitude
      longitude
    }
  }
  numberOfBathrooms
  numberOfBedrooms
  numberOfIndoorParkingSpaces
  numberOfOutdoorParkingSpaces
  parkingSpaces {
    duplex
    garage
    multiStoryGarage
    undergroundGarage
    otherParkingLot
    carport
    parkingSpace
  }
  numberOfRooms
  numberOfUnits
  energyLabel
  propertyType {
    code
    subcode
  }
  quality {
    bathrooms
    flooring
    kitchen
    masonry
    overall
    windows
  }
  balconyArea
  floorNumber
  numberOfFloorsInBuilding
  renovationYear
  annualRentIncome
  isNew
  hasPool
  hasSauna
}
    `;
export const ValuationFieldsFragmentDoc = `
    fragment valuationFields on Valuation {
  confidence
  coordinates {
    longitude
    latitude
  }
  currency
  rentGross
  rentGrossRange {
    lower
    upper
  }
  rentNet
  rentNetRange {
    lower
    upper
  }
  salePrice
  salePriceRange {
    lower
    upper
  }
  scores {
    location
  }
  status {
    code
    message
  }
  value
  valueRange {
    lower
    upper
  }
}
    `;
export const AdministrativeDivisionFieldsFragmentDoc = `
    fragment administrativeDivisionFields on AdministrativeDivision {
  divisionLevel100 {
    officialId
  }
}
    `;
export const PoiFieldsFragmentDoc = `
    fragment poiFields on POI {
  category
  distance
  location {
    address {
      city
      houseNumber
      postCode
      street
    }
    coordinates {
      latitude
      longitude
    }
  }
  name
  subcategory
}
    `;
export const PropertyStatisticsFieldsFragmentDoc = `
    fragment propertyStatisticsFields on Item {
  groupId
  groupLowerBound
  groupUpperBound
  statistics {
    metric
    type
    value
    parameters {
      percentileRank
    }
  }
}
    `;
export const PropertyFieldsFragmentDoc = `
    fragment propertyFields on Property {
  _id
  appointmentId
  countryCode
  dealType
  dossierUrl
  dossierUrlLastSync
  priceHubbleDossierId
  socioEconomics {
    ...socioEconomicsFields
  }
  propertyData {
    ...propertyDataFields
  }
  propertyPortfolioData {
    title
    shareTitle
    coverImageFilename
    coverImageUrl
    coverImageKey
  }
  valuations {
    date
    valuation {
      ...valuationFields
    }
  }
  actualValuations {
    date
    valuation {
      ...valuationFields
    }
  }
  ratingReason
  timeHorizont
  valuationsLastSync
  administrativeDivision {
    ...administrativeDivisionFields
  }
  valuationType
  poi {
    catering {
      bar {
        ...poiFields
      }
      cafe {
        ...poiFields
      }
      restaurant {
        ...poiFields
      }
    }
    education {
      kindergarten {
        ...poiFields
      }
      primary_school {
        ...poiFields
      }
      secondary_school {
        ...poiFields
      }
      university {
        ...poiFields
      }
    }
    health {
      clinic {
        ...poiFields
      }
      dentist {
        ...poiFields
      }
      doctor {
        ...poiFields
      }
      hospital {
        ...poiFields
      }
      nursing_home {
        ...poiFields
      }
      pharmacy {
        ...poiFields
      }
      veterinary {
        ...poiFields
      }
    }
    leisure {
      entertainment {
        ...poiFields
      }
      park {
        ...poiFields
      }
      playground {
        ...poiFields
      }
      sport {
        ...poiFields
      }
    }
    shopping {
      convenience_store {
        ...poiFields
      }
      supermarket {
        ...poiFields
      }
    }
    transport {
      bus {
        ...poiFields
      }
      tram {
        ...poiFields
      }
      subway {
        ...poiFields
      }
      train {
        ...poiFields
      }
      airport {
        ...poiFields
      }
      highway_ramp {
        ...poiFields
      }
    }
  }
  locationScores {
    catering
    family
    health
    leisure
    shopping
    noise
  }
  poiLastSync
  propertyStatistics {
    ...propertyStatisticsFields
  }
  buildingProjects {
    totalItems
    items {
      location {
        coordinates {
          latitude
          longitude
        }
        address {
          postCode
          city
          street
          houseNumber
        }
      }
      title
      distance
      isPreciselyLocated
      provider
      status
    }
  }
  propertyStatisticsLastSync
  userStatus
  tour360Link
  photos
  title
  completedSellingSteps
  onOfficePrice
  priceCurrency
  isDigitalServiceActivated
  isActivitiesTabUnlocked
  isContractSigned
  isMarketingDashboardUnlocked
  isHiddenSendEmailToBrokerAlert
  isRealSellingProcessStarted
  isBrokerNotifiedOfBuyerFinder
  isUnderConstruction
  status
  externalId
  onOfficeId
  energyCertificate {
    energyCertificate
    finalEnergyDemand
    energyCertificateValidUntil
    energyEfficiencyClass
    energySource
    typeOfHeating
  }
  relatedShop {
    message
    messageSentAt
    senderFirstName
    senderLastName
  }
  favoriteSearchProfiles
  disabledServices
  servicesDisabledAt
  propertyWidgetFeedback {
    rating
    numericValue
    priceHubbleId
    text
  }
  createdAt
  mapboxImageUrl
  isReadOnlyAccess
  description {
    objectDescription
    equipmentDescription
    locationDescription
    otherInformation
    planUrls
  }
  provision {
    value
    isAgreedWithSeller
  }
}
    ${SocioEconomicsFieldsFragmentDoc}
${PropertyDataFieldsFragmentDoc}
${ValuationFieldsFragmentDoc}
${AdministrativeDivisionFieldsFragmentDoc}
${PoiFieldsFragmentDoc}
${PropertyStatisticsFieldsFragmentDoc}`;
export const SimilarPropertyFieldsFragmentDoc = `
    fragment similarPropertyFields on Comparable {
  address {
    city
    houseNumber
    postCode
    street
  }
  buildingYear
  contactInfo {
    inquiryPerson {
      raw
    }
    organization {
      raw
    }
    raw
    viewPerson {
      raw
    }
  }
  coordinates {
    latitude
    longitude
  }
  currency
  daysOnMarket
  dealType
  description
  distance
  distanceGroceryStore
  distanceHospital
  distancePublicTransport
  endDate
  floorNumber
  hasLift
  hasParkingSpaces
  images {
    url
  }
  isActive
  isExclusive
  landArea
  livingArea
  noise
  numberOfBedrooms
  numberOfIndoorParkingSpaces
  numberOfOutdoorParkingSpaces
  numberOfRooms
  offerId
  propertyType {
    code
    subcode
  }
  rentGross
  rentNet
  salePrice
  shareableLink
  slope
  startDate
  title
  url
  volume
}
    `;
export const SuggestPropertyFieldsFragmentDoc = `
    fragment suggestPropertyFields on SuggestedProperty {
  propertyId
  externalId
  title
  description
  equipmentDescription
  locationDescription
  otherInformation
  livingArea
  landArea
  numberOfRooms
  price
  currency
  latitude
  longitude
  city
  postalCode
  language
  status
  type
  image {
    name
    alt
    group
    width
    height
  }
  condition {
    buildingYear
    modernizationYear
  }
  address {
    floor
    numberOfFloors
    houseNumber
    city
    postCode
    street
  }
  area {
    numberOfRooms
    numberOfBathrooms
    numberOfBedrooms
    numberOfParkingSpaces
    balconyArea
  }
  equipment {
    numberOfGarages
    numberOfParkingSpace
    numberOfDuplexParkingSpaces
    numberOfUndergroundGarages
    numberOfOtherParkingSpaces
    provision
  }
  energyCertificate {
    finalEnergyDemand
    energyCertificateValidUntil
    energyEfficiencyClass
    energySource
    energyCertificate
  }
  energyEquipment {
    floorHeating
    districtHeating
    underfloorHeating
    furnaceHeating
    centralHeating
    alternative
    combinedHeatAndPowerPlant
    electro
    geothermalEnergy
    gas
    airWaterHeatPump
    oil
    pellet
    solar
    additionalDecentralizedHotWater
  }
  tour360Link
}
    `;
export const SearchProfileDataFieldsFragmentDoc = `
    fragment searchProfileDataFields on SearchProfileData {
  landAreaMax
  landAreaMin
  livingAreaMax
  livingAreaMin
  location {
    address {
      city
      postCode
      locality
      neighborhood
      placeName
    }
    coordinates {
      latitude
      longitude
    }
  }
  numberOfBathroomsMax
  numberOfBathroomsMin
  numberOfRoomsMax
  numberOfRoomsMin
  propertyCode
  purchasePriceMax
  purchasePriceMin
  radius
}
    `;
export const SearchProfileFieldsFragmentDoc = `
    fragment searchProfileFields on SearchProfile {
  _id
  countryCode
  title
  searchProfileData {
    ...searchProfileDataFields
  }
  liked {
    id
    price
  }
  latestSuggestedProperties {
    id
  }
  disliked
  priceOffers {
    propertyInternalId
    propertyPrice
    offeredPrice
    createdAt
  }
  isNotifyNewProperties
  isNotifyPriceChange
  isNotifyNewPropertiesSummary
  marketingParameters
  isCreatedVia3rdPartyPortal
}
    ${SearchProfileDataFieldsFragmentDoc}`;
export const ChangePasswordDocument = `
    mutation changePassword($input: ChangePasswordInput!) {
  changePassword(input: $input)
}
    `;
export const ConfirmEmailDocument = `
    mutation confirmEmail($input: ConfirmEmailInput!) {
  confirmEmail(input: $input) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const ConfirmForgotPasswordDocument = `
    mutation confirmForgotPassword($input: ConfirmForgotPasswordInput!) {
  confirmForgotPassword(input: $input)
}
    `;
export const ConfirmPhoneChangeDocument = `
    mutation confirmPhoneChange($input: ConfirmEmailInput!) {
  confirmPhoneChange(input: $input) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const ConfirmPhoneDocument = `
    mutation confirmPhone($input: ConfirmPhoneInput!) {
  confirmPhone(input: $input)
}
    `;
export const ForgotPasswordDocument = `
    mutation forgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input)
}
    `;
export const RemoveUserAccountDocument = `
    mutation removeUserAccount($username: String!) {
  removeUserAccount(username: $username)
}
    `;
export const RequestVerificationCodeDocument = `
    mutation requestVerificationCode($input: RequestVerificationCodeInput!) {
  requestVerificationCode(input: $input)
}
    `;
export const ResendConfirmationCodeDocument = `
    mutation resendConfirmationCode($email: String!) {
  resendConfirmationCode(email: $email)
}
    `;
export const ResendPhoneCodeAfterChangeDocument = `
    mutation resendPhoneCodeAfterChange($phone: String!) {
  resendPhoneCodeAfterChange(phone: $phone)
}
    `;
export const UserSelfDeleteDocument = `
    mutation userSelfDelete($username: String!) {
  userSelfDelete(username: $username)
}
    `;
export const SetUserStateDocument = `
    mutation setUserState($_id: String!, $state: String!) {
  setUserState(_id: $_id, state: $state) {
    userState
  }
}
    `;
export const SignInPropertiesDocument = `
    mutation signInProperties($input: UserSignInInput!) {
  signIn(input: $input) {
    ... on LoginResponse {
      jwt {
        ...jwtTokenFields
      }
      user {
        ...userFields
        properties {
          _id
        }
      }
    }
    ... on AuthChallenge {
      ...authChallengeFields
    }
  }
}
    ${JwtTokenFieldsFragmentDoc}
${UserFieldsFragmentDoc}
${AuthChallengeFieldsFragmentDoc}`;
export const SignInDocument = `
    mutation signIn($input: UserSignInInput!) {
  signIn(input: $input) {
    ... on LoginResponse {
      jwt {
        ...jwtTokenFields
      }
      user {
        ...userFields
      }
    }
    ... on AuthChallenge {
      ...authChallengeFields
    }
  }
}
    ${JwtTokenFieldsFragmentDoc}
${UserFieldsFragmentDoc}
${AuthChallengeFieldsFragmentDoc}`;
export const SignUpDocument = `
    mutation signUp($input: UserSignUpInput!) {
  signUp(input: $input) {
    _id
    username
    userState
  }
}
    `;
export const UnsubscribeFromNotificationsDocument = `
    mutation unsubscribeFromNotifications($userId: String!) {
  unsubscribeFromNotifications(userId: $userId)
}
    `;
export const UpdateUserProfileDocument = `
    mutation updateUserProfile($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const CurrentUserDocument = `
    query currentUser {
  currentUser {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const GetOnOfficeUserDocument = `
    query getOnOfficeUser($addressId: Int!) {
  getOnOfficeUser(addressId: $addressId) {
    id
    buyerPortalId
    salutation
    name
    surname
    phone
    email
    city
    street
  }
}
    `;
export const SaveFileAccessConfigDocument = `
    mutation saveFileAccessConfig($input: FileAccessConfigInput!) {
  saveFileAccessConfig(input: $input)
}
    `;
export const UploadPropertyDocumentsDocument = `
    mutation uploadPropertyDocuments($propertyFiles: [PropertyFileInput!]!, $propertyId: String!) {
  uploadPropertyDocuments(propertyFiles: $propertyFiles, propertyId: $propertyId)
}
    `;
export const FileAccessConfigDocument = `
    query fileAccessConfig($fileId: String!) {
  fileAccessConfig(fileId: $fileId) {
    fileId
    propertyId
    fileName
    accessLimit
    accessExpiresAt
  }
}
    `;
export const GetBuyersDocumentAccessStatusDocument = `
    query getBuyersDocumentAccessStatus($propertyId: String!, $fileId: String!) {
  getBuyersDocumentAccessStatus(propertyId: $propertyId, fileId: $fileId) {
    id
    name
    surname
    email
    hasAccessToDocument
  }
}
    `;
export const GetPropertyDocumentsDocument = `
    query getPropertyDocuments($propertyId: String!) {
  getPropertyDocuments(propertyId: $propertyId) {
    id
    type
    originalname
    filename
    title
    content
    documentAttribute
    url
    isPublished
    releaseStatus
  }
}
    `;
export const PropertyAddressDocument = `
    query propertyAddress($propertyOnOfficeId: String!) {
  propertyAddress(propertyOnOfficeId: $propertyOnOfficeId) {
    id
    ownerEmail
    address
  }
}
    `;
export const SendFeedbackDocument = `
    mutation sendFeedback($input: SendFeedbackInput!) {
  sendFeedback(input: $input)
}
    `;
export const ReadCentralPhoneByCountryDocument = `
    query readCentralPhoneByCountry($country: String!) {
  readCentralPhoneByCountry(country: $country) {
    phoneNumber
  }
}
    `;
export const ReadBuyerLandingPageDocument = `
    query readBuyerLandingPage($locale: String!) {
  readBuyerLandingPage(locale: $locale) {
    _id
    sections {
      type
      title
      orderIndex
      recordIds
    }
    imageUrl
    imageKey
    imageAlt
    country
    locale
    metaDescription
    metaKeywords
    metaTitle
    name
    subtitle
    title
    text
  }
}
    `;
export const ReadFinancingLandingPageDocument = `
    query readFinancingLandingPage($locale: String!) {
  readFinancingLandingPage(locale: $locale) {
    _id
    sections {
      type
      title
      orderIndex
      recordIds
    }
    imageUrl
    imageKey
    imageAlt
    country
    locale
    metaDescription
    metaKeywords
    metaTitle
    name
    subtitle
    title
    text
  }
}
    `;
export const ReadOverviewLandingPageDocument = `
    query readOverviewLandingPage($locale: String!) {
  readOverviewLandingPage(locale: $locale) {
    _id
    sections {
      type
      title
      orderIndex
      recordIds
    }
    imageUrl
    imageKey
    imageAlt
    country
    locale
    metaDescription
    metaKeywords
    metaTitle
    name
    subtitle
    title
    text
  }
}
    `;
export const ReadAppointmentContactFormDocument = `
    query readAppointmentContactForm($id: String!) {
  data: readAppointmentContactForm(id: $id) {
    _id
    imageAlt
    imageUrl
    imageKey
    title
    phoneNumber
    expandedText
    bookAppointmentLabel
    formTitle
    nameLabel
    namePlaceholder
    surnameLabel
    surnamePlaceholder
    emailLabel
    emailPlaceholder
    phoneNumberLabel
    phoneNumberPlaceholder
    cityPostalLabel
    cityPostalPlaceholder
    addressBuildingNumberLabel
    addressBuildingNumberPlaceholder
    messageLabel
    messagePlaceholder
    checkbox1
    remark
    sendLabel
  }
}
    `;
export const ReadBuyerBenefitDocument = `
    query readBuyerBenefit($id: String!) {
  data: readBuyerBenefit(id: $id) {
    _id
    title
    expandedText
    icon1Alt
    icon1Url
    icon1Key
    text1
    icon2Alt
    icon2Url
    icon2Key
    text2
    icon3Alt
    icon3Url
    icon3Key
    text3
    icon4Alt
    icon4Url
    icon4Key
    text4
    createSearchProfileLabel
    imageAlt
    imageUrl
    imageKey
  }
}
    `;
export const ReadBuyerFunctionsListDocument = `
    query readBuyerFunctionsList($ids: [String!]!) {
  data: readBuyerFunctionsList(ids: $ids) {
    _id
    title
    expandedText
    iconAlt
    iconUrl
    iconKey
  }
}
    `;
export const ReadBuyerOverviewDocument = `
    query readBuyerOverview($id: String!) {
  data: readBuyerOverview(id: $id) {
    _id
    title
    expandedText
    createSearchProfileLabel
    imageAlt
    imageUrl
    imageKey
  }
}
    `;
export const ReadFinancingOverviewsDocument = `
    query readFinancingOverviews($filter: FinancingOverviewFilterInput!) {
  data: readFinancingOverviews(filter: $filter) {
    page {
      _id
      subTitle
      title
      iconAlt
      iconUrl
      iconKey
      textTitle
      expandedText
      fundingInquiriesLabel
      imageAlt
      imageUrl
      imageKey
    }
  }
}
    `;
export const ReadOverviewAppDocument = `
    query readOverviewApp($id: String!) {
  data: readOverviewApp(id: $id) {
    _id
    subTitle
    title
    expandedText
    imageAlt
    imageUrl
    imageKey
    appStoreLink
    googlePlayLink
  }
}
    `;
export const OverviewLandingPageContactDocument = `
    mutation overviewLandingPageContact($contactData: OverviewLandingPageContactDataInput!) {
  overviewLandingPageContact(contactData: $contactData)
}
    `;
export const ReadOwnerBenefitDocument = `
    query readOwnerBenefit($id: String!) {
  data: readOwnerBenefit(id: $id) {
    _id
    title
    expandedText
    icon1Alt
    icon1Url
    icon1Key
    text1
    icon2Alt
    icon2Url
    icon2Key
    text2
    icon3Alt
    icon3Url
    icon3Key
    text3
    propertyEvaluateLabel
    imageAlt
    imageUrl
    imageKey
  }
}
    `;
export const ReadOwnerFunctionsListDocument = `
    query readOwnerFunctionsList($ids: [String!]!) {
  data: readOwnerFunctionsList(ids: $ids) {
    _id
    title
    expandedText
    iconAlt
    iconUrl
    iconKey
  }
}
    `;
export const ReadOwnerOverviewDocument = `
    query readOwnerOverview($id: String!) {
  data: readOwnerOverview(id: $id) {
    _id
    title
    expandedText
    propertyEvaluateLabel
    patternAnalysisLabel
    imageAlt
    imageUrl
    imageKey
  }
}
    `;
export const ReadSellerBenefitDocument = `
    query readSellerBenefit($id: String!) {
  data: readSellerBenefit(id: $id) {
    _id
    title
    expandedText
    icon1Alt
    icon1Url
    icon1Key
    text1
    icon2Alt
    icon2Url
    icon2Key
    text2
    icon3Alt
    icon3Url
    icon3Key
    text3
    icon4Alt
    icon4Url
    icon4Key
    text4
    icon5Alt
    icon5Url
    icon5Key
    text5
    propertyEvaluateLabel
    imageAlt
    imageUrl
    imageKey
  }
}
    `;
export const ReadSellerFunctionsListDocument = `
    query readSellerFunctionsList($ids: [String!]!) {
  data: readSellerFunctionsList(ids: $ids) {
    _id
    title
    expandedText
    iconAlt
    iconUrl
    iconKey
  }
}
    `;
export const ReadSellerOverviewDocument = `
    query readSellerOverview($id: String!) {
  data: readSellerOverview(id: $id) {
    _id
    title
    expandedText
    CTALabel
    imageAlt
    imageUrl
    imageKey
  }
}
    `;
export const ReadTestimonialsListDocument = `
    query readTestimonialsList($ids: [String!]!) {
  data: readTestimonialsList(ids: $ids) {
    _id
    imageAlt
    imageUrl
    imageKey
    title
    expandedText
    name
  }
}
    `;
export const ReadOwnerLandingPageDocument = `
    query readOwnerLandingPage($locale: String!) {
  readOwnerLandingPage(locale: $locale) {
    _id
    sections {
      type
      title
      orderIndex
      recordIds
    }
    imageUrl
    imageKey
    imageAlt
    country
    locale
    metaDescription
    metaKeywords
    metaTitle
    name
    subtitle
    title
    text
  }
}
    `;
export const ReadBuyerFinderTeaserDocument = `
    query readBuyerFinderTeaser($id: String!) {
  data: readBuyerFinderTeaser(id: $id) {
    _id
    subtitle
    title
    description
    step1Title
    step1Text
    step2Title
    step2Text
    step3Title
    step3Text
    imageAlt
    imageUrl
    CTALabel
  }
}
    `;
export const CmsPageDocument = `
    query cmsPage($id: String!) {
  cmsPage(_id: $id) {
    _id
    name
    representativeName
    blocks {
      ...blockFields
    }
  }
}
    ${BlockFieldsFragmentDoc}`;
export const CmsPagesDocument = `
    query cmsPages {
  cmsPages {
    _id
    name
    representativeName
  }
}
    `;
export const CmsRegionalPageDocument = `
    query cmsRegionalPage($slug: String!) {
  cmsRegionalPage(slug: $slug) {
    _id
    active
    awsFolderKey
    sections {
      type
      title
      orderIndex
      recordIds
    }
    imageUrl
    imageKey
    imageAlt
    bookingButtonLabel
    country
    email
    heading
    imageAlt
    imageUrl
    metaDescription
    metaKeywords
    metaTitle
    name
    personName
    personPosition
    personText
    phone
    routeButtonLabel
    routeButtonLink
    slug
    subheading
    title
  }
}
    `;
export const ReadGeneralAppointmentDocument = `
    query readGeneralAppointment($id: String!) {
  data: readGeneralAppointment(id: $id) {
    ... on GeneralAppointment {
      _id
      buttonLabel
      generalText
      label
      name
      phone
    }
  }
}
    `;
export const ReadAdvantageDocument = `
    query readAdvantage($id: String!) {
  data: readAdvantage(id: $id) {
    _id
    name
    title
    text
    iconAlt
    iconUrl
    iconKey
  }
}
    `;
export const ReadAppDocument = `
    query readApp($id: String!) {
  data: readApp(id: $id) {
    _id
    name
    title
    subtitle
    text
    appStoreLink
    googlePlayLink
  }
}
    `;
export const ReadAppointmentsDocument = `
    query readAppointments($filter: CmsSectionFilterInput!) {
  data: readAppointments(filter: $filter) {
    _id
    avatarUrl
    avatarKey
    altText
    phone
    email
    title
    awsFolderKey
    timifyId
    brokerPosition
    brokerQualification
    legalPopUpLabel
    legalPopUpText
  }
}
    `;
export const ReadBannerDocument = `
    query readBanner($id: String!) {
  data: readBanner(id: $id) {
    _id
    name
    text
    buttonLabel
    imageAlt
    imageUrl
    imageKey
  }
}
    `;
export const ReadBenefitDocument = `
    query readBenefit($id: String!) {
  data: readBenefit(id: $id) {
    _id
    name
    title
    text
    widgetButtonLabel
    secondaryButtonLabel
    secondaryButtonLink
    imageAlt
    imageKey
    imageUrl
  }
}
    `;
export const BenefitsByIdsDocument = `
    query benefitsByIds($_ids: [String!]) {
  data: benefitsByIds(_ids: $_ids) {
    _id
    altText
    awsFolderKey
    cta
    ctaLabel
    expandedText
    iconKey
    iconUrl
    imageHint
    imageKey
    imageUrl
    title
    type
  }
}
    `;
export const ReadCertificateDocument = `
    query readCertificate($id: String!) {
  data: readCertificate(id: $id) {
    _id
    fileAlt
    fileKey
    fileUrl
    name
  }
}
    `;
export const CmsSectionDocument = `
    query cmsSection($id: String!) {
  data: cmsSection(_id: $id) {
    ... on FaqSection {
      ...faqSectionFields
    }
    ... on AppointmentSection {
      ...appointmentSectionFields
    }
  }
}
    ${FaqSectionFieldsFragmentDoc}
${AppointmentSectionFieldsFragmentDoc}`;
export const ReadCustomerAppraisalDocument = `
    query readCustomerAppraisal($id: String!) {
  readCustomerAppraisal(id: $id) {
    _id
    bannerLink
    expandedText
    imageAlt
    imageUrl
    title
  }
}
    `;
export const ReadFaqsDocument = `
    query readFaqs($filter: CmsSectionFilterInput!) {
  data: readFaqs(filter: $filter) {
    _id
    expandedText
    order
    title
    type
  }
}
    `;
export const ReadStepByStepBenefitDocument = `
    query readStepByStepBenefit($id: String!) {
  readStepByStepBenefit(id: $id) {
    _id
    subtitle
    title
    step1IconUrl
    step1IconAlt
    step1Title
    step1Text
    step2IconUrl
    step2IconAlt
    step2Title
    step2Text
    step3IconUrl
    step3IconAlt
    step3Title
    step3Text
    CTALabel
    imageUrl
    imageAlt
  }
}
    `;
export const ReadFinancingCalculatorDocument = `
    query readFinancingCalculator($id: String!) {
  readFinancingCalculator(id: $id) {
    _id
    title
    expandedText
    federalStateDropdownLabel
    capitalContributionLabel
    monthlyNetIncomeLabel
    calculateCTALabel
    maximumPurchasePriceLabel
    maximumLoanAmountLabel
    ghostButtonLabel
    iconUrl
    iconAlt
    mainCTALabel
  }
}
    `;
export const ReadFinancingCertificateDocument = `
    query readFinancingCertificate($id: String!) {
  readFinancingCertificate(id: $id) {
    _id
    title
    expandedText
    mainCTALabel
    imageAlt
    imageUrl
  }
}
    `;
export const ReadFinancingPartnerDocument = `
    query readFinancingPartner($id: String!) {
  readFinancingPartner(id: $id) {
    _id
    fileAlt
    fileUrl
  }
}
    `;
export const ReadGeneralAppointmentByCountryDocument = `
    query readGeneralAppointmentByCountry($country: String!) {
  data: readGeneralAppointmentByCountry(country: $country) {
    ... on GeneralAppointment {
      _id
      buttonLabel
      generalText
      label
      name
      phone
      country
    }
  }
}
    `;
export const ReadInterestRatesTableDocument = `
    query readInterestRatesTable($id: String!) {
  readInterestRatesTable(id: $id) {
    _id
    ghostButtonLabel
    mainCTALabel
    row1Label
    row2Label
    row3Label
    subtitle
    tableHintText
    timestampLabel
    title
  }
}
    `;
export const ReadLocalMarketDocument = `
    query readLocalMarket($id: String!) {
  data: readLocalMarket(id: $id) {
    _id
    name
    title
    text
    buttonLabel
    imageAlt
    imageUrl
    imageKey
  }
}
    `;
export const ReadOurRepresentativeExampleDocument = `
    query readOurRepresentativeExample($id: String!) {
  readOurRepresentativeExample(id: $id) {
    _id
    title
    CTALabel
    expandedText
    table1Row1Label
    table1Row2Label
    table1Row3Label
    table1Row4Label
    table1Title
    table2Row1Label
    table2Row2Label
    table2Row3Label
    table2Row4Label
    table2Row5Label
    table2Row6Label
    table2Title
    table3Row1Label
    table3Row2Label
    table3Row3Label
    table3Title
    table4Row1Label
    table4Row1Value
    table4Row2Label
    table4Row2Value
    table4Row3Label
    table4Row3Value
    table4Row4Label
    table4Row4Value
    table4Row5Label
    table4Row5Value
    table4Title
  }
}
    `;
export const ReadVideoDocument = `
    query readVideo($id: String!) {
  data: cmsSection(_id: $id) {
    ... on VideoSection {
      _id
      title
      subtitle
      text
      ctaLabel
      videoURL
    }
  }
}
    `;
export const ReadVirtualToursDocument = `
    query readVirtualTours($filter: CmsSectionFilterInput!) {
  data: readVirtualTours(filter: $filter) {
    _id
    imageUrl
    imageKey
    imageAlt
    text
    title
  }
}
    `;
export const ReadSellerLandingPageDocument = `
    query readSellerLandingPage($locale: String!) {
  readSellerLandingPage(locale: $locale) {
    _id
    sections {
      type
      title
      orderIndex
      recordIds
    }
    imageUrl
    imageKey
    imageAlt
    country
    locale
    metaDescription
    metaKeywords
    metaTitle
    name
    subtitle
    title
    text
  }
}
    `;
export const ConvertChfToEurDocument = `
    query convertChfToEur($amount: Float!) {
  value: convertChfToEur(amount: $amount)
}
    `;
export const ConvertEurToChfDocument = `
    query convertEurToChf($amount: Float!) {
  value: convertEurToChf(amount: $amount)
}
    `;
export const ExperimentsDocument = `
    query experiments($savedExperiments: [SavedExperimentsInput!]) {
  experiments(savedExperiments: $savedExperiments) {
    ...experimentFields
  }
}
    ${ExperimentFieldsFragmentDoc}`;
export const GetEurToChfRateDocument = `
    query getEurToChfRate {
  rate: getEurToChfRate
}
    `;
export const GetProvenExpertRatingDocument = `
    query getProvenExpertRating {
  getProvenExpertRating {
    ratingValue
    reviewCount
  }
}
    `;
export const GetContentPageDocument = `
    query getContentPage($id: String!) {
  cmsPage(_id: $id) {
    name
    blocks {
      isActive
      title
      sections {
        ...Content
      }
    }
  }
}
    ${ContentFragmentDoc}`;
export const SuggestedPropertyDocument = `
    query suggestedProperty($id: Float!) {
  suggestedProperty(id: $id) {
    title
    address {
      floor
      street
      houseNumber
      postCode
      city
      numberOfFloors
    }
    image {
      id
      alt
      name
    }
    propertyId
    equipment {
      numberOfGarages
      numberOfParkingSpace
      provision
    }
    type
    condition {
      modernizationYear
      buildingYear
    }
    livingArea
    landArea
    area {
      numberOfRooms
      numberOfBedrooms
      numberOfBathrooms
      numberOfParkingSpaces
    }
    price
    currency
  }
}
    `;
export const CreateExposeRequestDocument = `
    mutation createExposeRequest($emailTemplate: ExposeRequestEmailTemplate!, $input: ExposeRequestInput!) {
  createExposeRequest(emailTemplate: $emailTemplate, input: $input)
}
    `;
export const GetPdfLinkDocument = `
    mutation getPdfLink($id: String!, $token: String!) {
  getPdfLink(id: $id, token: $token)
}
    `;
export const SetUserIdForExposesDocument = `
    mutation setUserIdForExposes($addressId: Int!, $userEmail: String!, $userId: String!) {
  setUserIdForExposes(
    addressId: $addressId
    userEmail: $userEmail
    userId: $userId
  )
}
    `;
export const ApprovedExposeRequestsDocument = `
    mutation approvedExposeRequests($userId: String!) {
  approvedExposeRequests(userId: $userId) {
    id
    elements {
      Verantwortung
      relatedEstateId
      relatedAddressId
    }
  }
}
    `;
export const ReadUserPurchaseIntentsDocument = `
    query readUserPurchaseIntents($searchProfileId: String!) {
  readUserPurchaseIntents(searchProfileId: $searchProfileId) {
    id
    title
    address
    price
    rooms
    area
    img
    externalId
    offers {
      _id
      recipient
      price
      confirmFunding
      dateCreated
      propertyId
    }
  }
}
    `;
export const ExpandFinancingRadiusDocument = `
    mutation expandFinancingRadius($input: UpdateFinancingPropositionsInput!) {
  expandFinancingRadius(input: $input)
}
    `;
export const RemoveFinancingDocument = `
    mutation removeFinancing($id: String!) {
  removeFinancing(_id: $id) {
    _id
  }
}
    `;
export const SendVonPollFinanceDocument = `
    mutation sendVonPollFinance($input: SendVonPollFinanceInput!) {
  sendVonPollFinance(input: $input)
}
    `;
export const UpdateFinancingVariantNameDocument = `
    mutation updateFinancingVariantName($input: UpdateFinancingVariantNameInput!) {
  updateFinancingVariantName(input: $input) {
    _id
  }
}
    `;
export const FinancingDocument = `
    query financing($id: String!) {
  financing(_id: $id) {
    ...financingFields
  }
}
    ${FinancingFieldsFragmentDoc}`;
export const FinancingsDocument = `
    query financings($ownerId: String) {
  financings(ownerId: $ownerId) {
    _id
    financingVariants {
      financingVariantData {
        reason
        propertyType
        location {
          ...financingLocation
        }
      }
    }
  }
}
    ${FinancingLocationFragmentDoc}`;
export const GetFinancingVonPollPropertyDetailsDocument = `
    query getFinancingVonPollPropertyDetails($id: Float!) {
  getFinancingVonPollPropertyDetails(_id: $id) {
    purchasePrice
    images {
      id
      alt
      group
      link
      width
      height
    }
    equipment {
      rentalIncomeIs
      rentalIncomeShould
    }
    postCode
    propertyType
    livingArea
    buildingYear
  }
}
    `;
export const DeletePropertyPurchaseIntentDocument = `
    mutation deletePropertyPurchaseIntent($id: String!) {
  deletePropertyPurchaseIntent(id: $id)
}
    `;
export const ReadPropertyPurchaseIntentsDocument = `
    query readPropertyPurchaseIntents($propertyId: String!) {
  readPropertyPurchaseIntents(propertyId: $propertyId) {
    _id
    recipient
    confirmFunding
    price
    dateCreated
    propertyId
  }
}
    `;
export const ReadPurchaseIntentDocument = `
    query readPurchaseIntent($id: String!) {
  readPurchaseIntent(id: $id) {
    name
    surname
    email
    phone
    postalCode
    city
    confirmFunding
    otherPersons {
      name
      surname
      email
      phone
      postalCode
      city
    }
    _id
    notes
    price
  }
}
    `;
export const CreateFinancingDocument = `
    mutation createFinancing($input: CreateFinancingInput!) {
  createFinancing(input: $input) {
    _id
  }
}
    `;
export const UpdateFinancingDocument = `
    mutation updateFinancing($input: UpdateFinancingInput!) {
  updateFinancing(input: $input) {
    _id
  }
}
    `;
export const UpdateFinancingPropositionsDocument = `
    mutation updateFinancingPropositions($input: UpdateFinancingPropositionsInput!) {
  updateFinancingPropositions(input: $input)
}
    `;
export const GetFinancingPropositionsDocument = `
    mutation getFinancingPropositions($input: GetFinancingPropositionsInput!) {
  getFinancingPropositions(input: $input) {
    ...financingPropositionsFields
  }
}
    ${FinancingPropositionsFieldsFragmentDoc}`;
export const ReadEuropaceInterestRatesDocument = `
    query readEuropaceInterestRates {
  readEuropaceInterestRates {
    ...financingPropositionsFields
  }
}
    ${FinancingPropositionsFieldsFragmentDoc}`;
export const ConfirmGuestUserDocument = `
    mutation confirmGuestUser($input: ConfirmGuestUserInput!) {
  confirmGuestUser(input: $input) {
    _id
  }
}
    `;
export const ConvertPropertyDocument = `
    mutation convertProperty($ownerId: String!) {
  convertProperty(ownerId: $ownerId) {
    ...propertyFields
  }
}
    ${PropertyFieldsFragmentDoc}`;
export const CreatePropertyDossierDocument = `
    mutation createPropertyDossier($input: PropertyDossierInput!) {
  createPropertyDossier(input: $input) {
    ...propertyFields
  }
}
    ${PropertyFieldsFragmentDoc}`;
export const CreatePropertyDocument = `
    mutation createProperty($input: CreatePropertyInput!) {
  createProperty(input: $input) {
    _id
  }
}
    `;
export const CreateTemporaryPropertyDocument = `
    mutation createTemporaryProperty($input: CreatePropertyInput!) {
  createTemporaryProperty(input: $input) {
    _id
  }
}
    `;
export const UpdatePropertyAlertsDocument = `
    mutation updatePropertyAlerts($input: UpdatePropertyInput!) {
  updatePropertyAlerts(input: $input) {
    _id
  }
}
    `;
export const UpdatePropertyPortfolioDocument = `
    mutation updatePropertyPortfolio($input: UpdatePropertyPortfolioInput!) {
  updatePropertyPortfolio(input: $input) {
    _id
    propertyPortfolioData {
      title
    }
  }
}
    `;
export const UpdatePropertyDocument = `
    mutation updateProperty($input: UpdatePropertyInput!) {
  updateProperty(input: $input) {
    _id
  }
}
    `;
export const RemovePropertyDocument = `
    mutation removeProperty($id: String!) {
  removeProperty(_id: $id) {
    _id
  }
}
    `;
export const ResendGuestUserCodeDocument = `
    mutation resendGuestUserCode($phoneNumber: String!) {
  resendGuestUserCode(phoneNumber: $phoneNumber)
}
    `;
export const UpdatePropertyFavoriteSearchProfilesListDocument = `
    mutation updatePropertyFavoriteSearchProfilesList($input: UpdatePropertyFavoriteSpListInput!) {
  updatePropertyFavoriteSearchProfilesList(input: $input)
}
    `;
export const VerifyGuestUserDocument = `
    mutation verifyGuestUser($input: VerifyGuestUserInput!) {
  verifyGuestUser(input: $input) {
    _id
    verified
  }
}
    `;
export const GetPropertyFavoriteSearchProfilesListDocument = `
    query getPropertyFavoriteSearchProfilesList($id: String!) {
  getPropertyFavoriteSearchProfilesList(_id: $id) {
    _id
    title
    createdAt
    searchProfileData {
      radius
      purchasePriceMax
      numberOfRoomsMin
      livingAreaMin
      landAreaMin
      propertyCode
      location {
        address {
          city
          postCode
          neighborhood
          locality
        }
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
}
    `;
export const PropertyValuationDocument = `
    query propertyValuation($input: ValuationInput!) {
  propertyValuation(input: $input) {
    date
    valuation {
      currency
      coordinates {
        longitude
        latitude
      }
      salePrice
      salePriceRange {
        lower
        upper
      }
      rentGross
      rentGrossRange {
        lower
        upper
      }
      rentNet
      rentNetRange {
        lower
        upper
      }
      value
      valueRange {
        lower
        upper
      }
      confidence
      scores {
        location
      }
      status {
        code
        message
      }
    }
  }
}
    `;
export const CreatePurchaseIntentDocument = `
    mutation createPurchaseIntent($input: PurchaseIntentInput!) {
  createPurchaseIntent(input: $input) {
    _id
  }
}
    `;
export const ConvertSearchProfileDocument = `
    mutation convertSearchProfile($ownerId: String!, $searchProfileId: String) {
  convertSearchProfile(ownerId: $ownerId, searchProfileId: $searchProfileId) {
    _id
  }
}
    `;
export const CreateSearchProfileDocument = `
    mutation createSearchProfile($input: CreateSearchProfileInput!) {
  createSearchProfile(input: $input) {
    _id
  }
}
    `;
export const CreateTemporarySearchProfileDocument = `
    mutation createTemporarySearchProfile($input: CreateTemporarySearchProfileInput!) {
  createTemporarySearchProfile(input: $input) {
    _id
    countryCode
    title
    searchProfileData {
      ...searchProfileDataFields
    }
  }
}
    ${SearchProfileDataFieldsFragmentDoc}`;
export const RemoveSearchProfileDocument = `
    mutation removeSearchProfile($id: String!) {
  removeSearchProfile(_id: $id) {
    _id
  }
}
    `;
export const UpdateSearchProfileDocument = `
    mutation updateSearchProfile($input: UpdateSearchProfileInput!) {
  updateSearchProfile(input: $input) {
    _id
  }
}
    `;
export const UpdateTemporarySearchProfileDocument = `
    mutation updateTemporarySearchProfile($input: UpdateSearchProfileInput!) {
  updateTemporarySearchProfile(input: $input) {
    _id
  }
}
    `;
export const SuggestedPropertiesDocument = `
    query suggestedProperties($filter: SuggestedPropertiesFilterInput, $searchProfileId: String) {
  suggestedProperties(filter: $filter, searchProfileId: $searchProfileId) {
    page {
      propertyId
      externalId
      internalId
      title
      regionalAddition
      postalCode
      city
      price
      currency
      livingArea
      landArea
      numberOfRooms
      type
      country
      image {
        name
      }
    }
    pageData {
      count
      limit
      offset
    }
  }
}
    `;
export const AcceptInvitationDocument = `
    mutation acceptInvitation($invitationId: String!) {
  acceptInvitation(invitationId: $invitationId)
}
    `;
export const CheckInvitationHealthDocument = `
    query checkInvitationHealth($input: CheckInviteHealth!) {
  checkInvitationHealth(input: $input)
}
    `;
export const DeleteInvitationDocument = `
    mutation deleteInvitation($inviteId: String!) {
  deleteInvitation(inviteId: $inviteId) {
    _id
  }
}
    `;
export const EditInvitationDocument = `
    mutation editInvitation($input: UpdateInviteInput!) {
  editInvitation(input: $input) {
    _id
  }
}
    `;
export const ExportPdfDocument = `
    mutation exportPdf {
  exportPdf
}
    `;
export const NotifyBrokerForBuyerFinderDocument = `
    mutation notifyBrokerForBuyerFinder($propertyId: String!, $countSearchProfiles: String!, $isBlurredGraphic: Boolean) {
  notifyBrokerForBuyerFinder(
    propertyId: $propertyId
    countSearchProfiles: $countSearchProfiles
    isBlurredGraphic: $isBlurredGraphic
  )
}
    `;
export const SendFeedbackOfOwnerPortalDocument = `
    mutation sendFeedbackOfOwnerPortal($input: SendFeedbackOfOwnerPortalInput!) {
  sendFeedbackOfOwnerPortal(input: $input)
}
    `;
export const SharePropertyDocument = `
    mutation shareProperty($input: CreateInviteInput!) {
  shareProperty(input: $input)
}
    `;
export const FindRelatedSearchProfilesDocument = `
    query findRelatedSearchProfiles($id: String!) {
  findRelatedSearchProfiles(_id: $id) {
    _id
    title
    createdAt
    searchProfileData {
      radius
      purchasePriceMax
      numberOfRoomsMin
      livingAreaMin
      landAreaMin
      propertyCode
      location {
        address {
          city
          postCode
          neighborhood
          locality
        }
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
}
    `;
export const GeneralMarketReportByCountryDocument = `
    query generalMarketReportByCountry($country: String!) {
  data: generalMarketReportByCountry(country: $country) {
    _id
    country
    fileUrl
    awsFolderKey
  }
}
    `;
export const GetTemporaryPropertyDocument = `
    query getTemporaryProperty {
  getTemporaryProperty {
    propertyData {
      ...propertyDataFields
    }
  }
}
    ${PropertyDataFieldsFragmentDoc}`;
export const InvitesDocument = `
    query invites($propertyId: String!) {
  invites(propertyId: $propertyId) {
    _id
    email
    invitationPrivileges
    name
    ownerId
    propertyId
    status
    surname
  }
}
    `;
export const IsochronesDocument = `
    query Isochrones($input: IsochronesInput!) {
  isochrones(input: $input) {
    densities {
      catering
      education
      health
      leisure
      shopping
    }
    isochrone {
      geometry {
        type
        coordinates
      }
    }
  }
}
    `;
export const MarketReportDocument = `
    query marketReport($filters: MarketReportInput!) {
  marketReport(filters: $filters) {
    _id
    cities
    fileUrl
    awsFolderKey
  }
}
    `;
export const NavigationPropertiesDocument = `
    query navigationProperties($pagination: PaginationArgs, $filter: PropertyFilterInput) {
  readProperties(pagination: $pagination, filter: $filter) {
    page {
      _id
      propertyData {
        location {
          coordinates {
            longitude
            latitude
          }
          address {
            street
            houseNumber
            city
            postCode
          }
        }
      }
      propertyPortfolioData {
        title
      }
      title
    }
    pageData {
      count
      limit
      offset
    }
  }
}
    `;
export const OurDestinationsDocument = `
    query ourDestinations {
  ourDestinations {
    _id
    title
    description
    destinations {
      orderIndex
      name
      description
      url
      imageUrl
      imageAlt
    }
  }
}
    `;
export const PaginatedPropertyPortfolioDocument = `
    query paginatedPropertyPortfolio($pagination: PaginationArgs, $filter: PropertyFilterInput) {
  readProperties(
    pagination: $pagination
    filter: $filter
    isEvaluationChanges: true
    includeRelatedProperties: true
  ) {
    page {
      ...propertyPortfolioFields
    }
    pageData {
      count
    }
  }
}
    ${PropertyPortfolioFieldsFragmentDoc}`;
export const PropertiesDocument = `
    query properties($ownerId: String, $excludeExternalProperties: Boolean) {
  properties(
    ownerId: $ownerId
    excludeExternalProperties: $excludeExternalProperties
  ) {
    _id
    isCreatedVia3rdPartyPortal
    propertyData {
      location {
        coordinates {
          longitude
          latitude
        }
        address {
          city
          houseNumber
          postCode
          street
        }
      }
    }
  }
}
    `;
export const PropertyDefaultFiltersDocument = `
    query propertyDefaultFilters($filter: PropertyDefaultFiltersFilterInput) {
  propertyDefaultFilters(filter: $filter) {
    propertyCodes
    minBuildingYear
    maxBuildingYear
    minLivingArea
    maxLivingArea
    minLandArea
    maxLandArea
    minNumberOfRooms
    maxNumberOfRooms
    minNumberOfBathrooms
    maxNumberOfBathrooms
    minNumberOfIndoorParkingSpaces
    maxNumberOfIndoorParkingSpaces
    minNumberOfOutdoorParkingSpaces
    maxNumberOfOutdoorParkingSpaces
    minPrice
    maxPrice
    energyLabels
  }
}
    `;
export const PropertyPortfolioDocument = `
    query propertyPortfolio($ownerId: String, $excludeExternalProperties: Boolean) {
  properties(
    ownerId: $ownerId
    excludeExternalProperties: $excludeExternalProperties
  ) {
    ...propertyPortfolioFields
  }
}
    ${PropertyPortfolioFieldsFragmentDoc}`;
export const PropertyDocument = `
    query property($id: String!) {
  property(_id: $id) {
    ...propertyFields
  }
}
    ${PropertyFieldsFragmentDoc}`;
export const ReadBuyerFinderSettingsDocument = `
    query readBuyerFinderSettings($locale: String!) {
  readBuyerFinderSettings(locale: $locale) {
    _id
    sections {
      type
      title
      orderIndex
      recordIds
    }
    locale
    name
  }
}
    `;
export const ShopContactsDocument = `
    query shopContacts($priceHubbleId: String!) {
  shopContacts(priceHubbleId: $priceHubbleId) {
    address
    alias
    city
    email
    telephone
    zip
  }
}
    `;
export const SimilarPropertiesDocument = `
    query similarProperties($input: OffersSearchInput!) {
  getOffers(input: $input) {
    totalItems
    items {
      ...similarPropertyFields
    }
  }
}
    ${SimilarPropertyFieldsFragmentDoc}`;
export const TemporaryPropertyDocument = `
    query temporaryProperty {
  temporaryProperty
}
    `;
export const ReadUserAppointmentsDocument = `
    query readUserAppointments {
  readUserAppointments {
    id
    rescheduleUntil
    reschedulingSecret
    startDate
    title
    userId
    zoomVideoLink
    property {
      title
      postalCode
      city
      externalId
      propertyId
      image {
        id
        alt
        name
      }
    }
  }
}
    `;
export const GeneratePdfExposeLinkDocument = `
    mutation generatePdfExposeLink($id: String!, $fileKey: String!) {
  generatePdfExposeLink(id: $id, fileKey: $fileKey)
}
    `;
export const ExposeRequestsDocument = `
    query exposeRequests($searchProfileId: String, $financingId: String, $pagination: ExposeRequestsPaginationArgs) {
  exposeRequests(
    searchProfileId: $searchProfileId
    financingId: $financingId
    pagination: $pagination
  ) {
    page {
      _id
      propertyId
      status
      fullAddress
      pdf
      propertyDetails {
        propertyId
        externalId
        title
        postalCode
        city
        price
        currency
        type
        livingArea
        landArea
        numberOfRooms
        latitude
        longitude
        regionalAddition
        image {
          name
        }
        condition {
          buildingYear
          modernizationYear
        }
        address {
          floor
          numberOfFloors
        }
        area {
          numberOfBathrooms
          numberOfBedrooms
          numberOfParkingSpaces
        }
        equipment {
          numberOfGarages
          provision
          numberOfDuplexParkingSpaces
          numberOfUndergroundGarages
          numberOfOtherParkingSpaces
          numberOfParkingSpace
        }
      }
      financingId
    }
    pageData {
      count
      limit
      offset
    }
  }
}
    `;
export const DislikePropertyDocument = `
    mutation dislikeProperty($propertyId: String!, $searchProfileId: String!) {
  dislikeProperty(propertyId: $propertyId, searchProfileId: $searchProfileId) {
    liked {
      id
      price
    }
    disliked
  }
}
    `;
export const LikePropertyDocument = `
    mutation likeProperty($propertyId: String!, $propertyPrice: Float!, $searchProfileId: String!) {
  likeProperty(
    propertyId: $propertyId
    propertyPrice: $propertyPrice
    searchProfileId: $searchProfileId
  ) {
    liked {
      id
      price
    }
    disliked
  }
}
    `;
export const MarkPropertyUnsuitableDocument = `
    mutation markPropertyUnsuitable($propertyId: String!, $searchProfileId: String!) {
  markPropertyUnsuitable(
    propertyId: $propertyId
    searchProfileId: $searchProfileId
  ) {
    liked {
      id
      price
    }
    disliked
  }
}
    `;
export const SendExposeContactFormDocument = `
    mutation sendExposeContactForm($contactForm: ExposeContactForm!, $propertyId: String!) {
  sendExposeContactForm(contactForm: $contactForm, propertyId: $propertyId)
}
    `;
export const UpdatePriceOffersDocument = `
    mutation updatePriceOffers($updatePriceOffersInput: UpdatePriceOffersInput!) {
  updatePriceOffers(input: $updatePriceOffersInput) {
    _id
  }
}
    `;
export const SearchProfileDocument = `
    query searchProfile($id: String!) {
  searchProfile(_id: $id) {
    ...searchProfileFields
  }
}
    ${SearchProfileFieldsFragmentDoc}`;
export const SearchProfilesDocument = `
    query searchProfiles($ownerId: String) {
  searchProfiles(ownerId: $ownerId) {
    _id
    title
    searchProfileData {
      radius
      purchasePriceMax
      location {
        address {
          city
          postCode
          neighborhood
          locality
        }
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
}
    `;
export const TemporarySearchProfileDocument = `
    query temporarySearchProfile {
  temporarySearchProfile
}
    `;
export const CreateDownloadedFileActivityDocument = `
    mutation createDownloadedFileActivity($exposeId: String!, $fileName: String!) {
  createDownloadedFileActivity(exposeId: $exposeId, fileName: $fileName)
}
    `;
export const ExposeDetailsDocument = `
    query exposeDetails($id: String!) {
  exposeDetails(_id: $id) {
    _id
    propertyId
    ownerId
    status
    propertyDetails {
      ...suggestPropertyFields
    }
    location {
      address {
        city
        houseNumber
        postCode
        street
      }
      coordinates {
        latitude
        longitude
      }
    }
    pdf
    fullAddress
    countryCode
    socioEconomics {
      ...socioEconomicsFields
    }
    valuations {
      date
      valuation {
        ...valuationFields
      }
    }
    valuationsLastSync
    locationScores {
      catering
      family
      health
      leisure
      shopping
      noise
    }
    propertyStatistics {
      ...propertyStatisticsFields
    }
    propertyStatisticsLastSync
    poiLastSync
    buildingProjects {
      totalItems
      items {
        title
        distance
      }
    }
    poi {
      catering {
        bar {
          ...poiFields
        }
        cafe {
          ...poiFields
        }
        restaurant {
          ...poiFields
        }
      }
      education {
        kindergarten {
          ...poiFields
        }
        primary_school {
          ...poiFields
        }
        secondary_school {
          ...poiFields
        }
        university {
          ...poiFields
        }
      }
      health {
        clinic {
          ...poiFields
        }
        dentist {
          ...poiFields
        }
        doctor {
          ...poiFields
        }
        hospital {
          ...poiFields
        }
        nursing_home {
          ...poiFields
        }
        pharmacy {
          ...poiFields
        }
        veterinary {
          ...poiFields
        }
      }
      leisure {
        entertainment {
          ...poiFields
        }
        park {
          ...poiFields
        }
        playground {
          ...poiFields
        }
        sport {
          ...poiFields
        }
      }
      shopping {
        convenience_store {
          ...poiFields
        }
        supermarket {
          ...poiFields
        }
      }
      transport {
        bus {
          ...poiFields
        }
        tram {
          ...poiFields
        }
        subway {
          ...poiFields
        }
        train {
          ...poiFields
        }
        airport {
          ...poiFields
        }
        highway_ramp {
          ...poiFields
        }
      }
    }
    provision {
      value
      isAgreedWithSeller
    }
  }
}
    ${SuggestPropertyFieldsFragmentDoc}
${SocioEconomicsFieldsFragmentDoc}
${ValuationFieldsFragmentDoc}
${PropertyStatisticsFieldsFragmentDoc}
${PoiFieldsFragmentDoc}`;
export const GetExposeDocumentsDocument = `
    query getExposeDocuments($exposeId: String!) {
  getExposeDocuments(exposeId: $exposeId) {
    type
    originalname
    filename
    title
    content
    documentAttribute
    url
    isPublished
  }
}
    `;
export const ReadUserContractAppointmentsDocument = `
    query readUserContractAppointments($propertyId: String!) {
  readUserContractAppointments(propertyId: $propertyId) {
    id
    rescheduleUntil
    reschedulingSecret
    startDate
    title
    userId
    zoomVideoLink
  }
}
    `;
export const AcceptPurchaseOfferDocument = `
    mutation acceptPurchaseOffer($input: AcceptPurchaseOfferInput!) {
  acceptPurchaseOffer(input: $input)
}
    `;
export const GetSellerExposePdfLinkDocument = `
    mutation getSellerExposePdfLink($id: String!, $token: String!) {
  getSellerExposePdfLink(id: $id, token: $token)
}
    `;
export const InitiateRealSellDocument = `
    mutation initiateRealSell($input: InitiateRealSellInput!) {
  initiateRealSell(input: $input)
}
    `;
export const InviteSellerDocument = `
    mutation inviteSeller($estateId: String!) {
  inviteSeller(estateId: $estateId)
}
    `;
export const SendPropertyServicesDocument = `
    mutation sendPropertyServices($propertyId: String!, $services: [OnOfficeServices!]!) {
  sendPropertyServices(propertyId: $propertyId, services: $services)
}
    `;
export const SendSellerRegisteredNotificationDocument = `
    mutation sendSellerRegisteredNotification($userId: String!) {
  sendSellerRegisteredNotification(userId: $userId)
}
    `;
export const SetDisabledShopServicesDocument = `
    mutation setDisabledShopServices($leaderId: String!, $disabledServices: [String!]!, $propertyId: String!, $disabledPropertyServices: [String!]!) {
  setDisabledShopServices(
    leaderId: $leaderId
    disabledServices: $disabledServices
    propertyId: $propertyId
    disabledPropertyServices: $disabledPropertyServices
  )
}
    `;
export const SetPropertyExposeStatusDocument = `
    mutation setPropertyExposeStatus($comment: String, $propertyId: String!, $status: PropertyExposeStatus!) {
  setPropertyExposeStatus(
    comment: $comment
    propertyId: $propertyId
    status: $status
  ) {
    status
    comment
  }
}
    `;
export const SetPropertyOwnerIdDocument = `
    mutation setPropertyOwnerId($ownerId: String!, $addressId: String!) {
  setPropertyOwnerId(ownerId: $ownerId, addressId: $addressId)
}
    `;
export const SetPropertyPortalStatusDocument = `
    mutation setPropertyPortalStatus($status: PropertyPortalStatusValues!, $propertyId: String!) {
  setPropertyPortalStatus(status: $status, propertyId: $propertyId)
}
    `;
export const UploadPropertyFilesDocument = `
    mutation uploadPropertyFiles($propertyFiles: [PropertyFileInput!]!, $propertyId: String!) {
  uploadPropertyFiles(propertyFiles: $propertyFiles, propertyId: $propertyId)
}
    `;
export const AppointmentActivitiesDocument = `
    query appointmentActivities($limit: Int!, $offset: Int!, $propertyId: String!) {
  appointmentActivities(limit: $limit, offset: $offset, propertyId: $propertyId) {
    _id
    dateTime
    title
    createdAt
  }
}
    `;
export const CheckBrokerSettingsPermissionDocument = `
    query checkBrokerSettingsPermission($brokerId: String!, $propertyId: String!) {
  checkBrokerSettingsPermission(brokerId: $brokerId, propertyId: $propertyId) {
    canChangeShopSettings
    canChangePropertySettings
  }
}
    `;
export const DashboardStatusDocument = `
    query dashboardStatus($propertyId: String!) {
  dashboardStatus(propertyId: $propertyId) {
    isDashboardUnlocked
    isMarketingPaused
  }
}
    `;
export const DigitalServiceStatusDocument = `
    query digitalServiceStatus($propertyId: String!) {
  digitalServiceStatus(propertyId: $propertyId)
}
    `;
export const DisabledShopServicesDocument = `
    query disabledShopServices($propertyId: String!) {
  disabledShopServices(propertyId: $propertyId)
}
    `;
export const DocumentsActivitiesDocument = `
    query documentsActivities($limit: Int!, $offset: Int!, $propertyId: String!) {
  documentsActivities(limit: $limit, offset: $offset, propertyId: $propertyId) {
    _id
    dateTime
    title
    createdAt
  }
}
    `;
export const DownloadedExposeActivitiesDocument = `
    query downloadedExposeActivities($limit: Int!, $offset: Int!, $propertyId: String!) {
  downloadedExposeActivities(
    limit: $limit
    offset: $offset
    propertyId: $propertyId
  ) {
    _id
    dateTime
    title
    createdAt
  }
}
    `;
export const ExposeActivitiesDocument = `
    query exposeActivities($limit: Int!, $offset: Int!, $propertyId: String!) {
  exposeActivities(limit: $limit, offset: $offset, propertyId: $propertyId) {
    _id
    dateTime
    title
    createdAt
  }
}
    `;
export const GetGeneralStatisticsDocument = `
    query getGeneralStatistics($dateFrom: String!, $dateTo: String!, $propertyId: String!) {
  getGeneralStatistics(
    dateFrom: $dateFrom
    dateTo: $dateTo
    propertyId: $propertyId
  ) {
    viewingAppointments
    requestedExposes
    unlockedExposes
  }
}
    `;
export const GetMarketingStatisticsDocument = `
    query getMarketingStatistics($propertyId: String!) {
  getMarketingStatistics(propertyId: $propertyId) {
    generalStatistics {
      highestBid
      bids
      viewingAppointments
      advertisementViews
      requestedExposes
      unlockedExposes
      bookmarks
      impressions
    }
    statisticsPerWeek {
      weekNumber
      dateFrom
      dateTo
      bids {
        customerId
        offeredPrice
        newOfferedPrice
        customerOnOfficeId
        bidOnOfficeId
        customerCity
        isAccepted
        date
      }
      statistics {
        highestBid
        bids
        viewingAppointments
        advertisementViews
        requestedExposes
        unlockedExposes
        bookmarks
        impressions
      }
    }
    statisticsLastSync
  }
}
    `;
export const GetPropertyExposeStatusDocument = `
    query getPropertyExposeStatus($propertyId: String!) {
  getPropertyExposeStatus(propertyId: $propertyId) {
    pdf
    status
    comment
    exposeChangesHistory
    exposeCreatedAt
    exposeUpdatedAt
    exposeApprovedAt
    isExposeApprovedViaOnOffice
  }
}
    `;
export const GetPropertyFilesDocument = `
    query getPropertyFiles($propertyId: String!) {
  getPropertyFiles(propertyId: $propertyId) {
    type
    originalname
    filename
    title
    content
    documentAttribute
    url
    isPublished
  }
}
    `;
export const GetPropertyServicesDocument = `
    query getPropertyServices($propertyId: String!) {
  getPropertyServices(propertyId: $propertyId) {
    SecretSale
    RealEstatePortals
    PremiumPlacement
    OfficialInformation
    Photos
    DronePhotos
    VirtualTours
    FloorPlans
    DigitalStaging
    EnergyCertificate
    Magazines
    HighCirculation
    SocialMedia
  }
}
    `;
export const GetShopContactsDocument = `
    query getShopContacts($propertyId: String!) {
  getShopContacts(propertyId: $propertyId) {
    city
    email
    firma
    homepage
    housenumber
    phone
    postcode
    street
  }
}
    `;
export const HandOverActivitiesDocument = `
    query handOverActivities($limit: Int!, $offset: Int!, $propertyId: String!) {
  handOverActivities(limit: $limit, offset: $offset, propertyId: $propertyId) {
    _id
    dateTime
    title
    createdAt
  }
}
    `;
export const LatestActivitiesDocument = `
    query latestActivities($limit: Int!, $propertyId: String!) {
  latestActivities(limit: $limit, propertyId: $propertyId) {
    _id
    dateTime
    title
    createdAt
  }
}
    `;
export const MarketingActivitiesDocument = `
    query marketingActivities($limit: Int!, $offset: Int!, $propertyId: String!) {
  marketingActivities(limit: $limit, offset: $offset, propertyId: $propertyId) {
    _id
    dateTime
    title
    createdAt
  }
}
    `;
export const MarketingStatusDocument = `
    query marketingStatus($propertyId: String!) {
  marketingStatus(propertyId: $propertyId)
}
    `;
export const NotaryActivitiesDocument = `
    query notaryActivities($limit: Int!, $offset: Int!, $propertyId: String!) {
  notaryActivities(limit: $limit, offset: $offset, propertyId: $propertyId) {
    _id
    dateTime
    title
    createdAt
  }
}
    `;
export const ReadPropertiesDocument = `
    query readProperties($pagination: PaginationArgs, $filter: PropertyFilterInput, $calculateTotalInfo: Boolean, $isEvaluationChanges: Boolean, $includeRelatedProperties: Boolean) {
  readProperties(
    pagination: $pagination
    filter: $filter
    calculateTotalInfo: $calculateTotalInfo
    isEvaluationChanges: $isEvaluationChanges
    includeRelatedProperties: $includeRelatedProperties
  ) {
    totalValue
    page {
      _id
      countryCode
      status
      actualValuations {
        date
        valuation {
          ...valuationFields
        }
      }
      valuations {
        date
        valuation {
          ...valuationFields
        }
      }
      valuationsLastSync
      propertyData {
        livingArea
        numberOfRooms
        propertyType {
          code
        }
        location {
          coordinates {
            longitude
            latitude
          }
          address {
            street
            houseNumber
            city
            postCode
          }
        }
      }
      appointmentId
      photos
      marketingStatistics {
        generalStatistics {
          highestBid
        }
        statisticsPerWeek {
          bids {
            customerId
          }
        }
      }
      onOfficePrice
      priceCurrency
      externalId
      isDigitalServiceActivated
      isDigitalServiceLockedByBroker
      mapboxImageUrl
      isPreview
      ownerOnOfficeId
      title
    }
    pageData {
      count
      limit
      offset
    }
  }
}
    ${ValuationFieldsFragmentDoc}`;
export const PhoneCallsActivitiesDocument = `
    query phoneCallsActivities($limit: Int!, $offset: Int!, $propertyId: String!) {
  phoneCallsActivities(limit: $limit, offset: $offset, propertyId: $propertyId) {
    _id
    dateTime
    title
    createdAt
  }
}
    `;
export const PriceOfferActivitiesDocument = `
    query priceOfferActivities($limit: Int!, $offset: Int!, $propertyId: String!) {
  priceOfferActivities(limit: $limit, offset: $offset, propertyId: $propertyId) {
    _id
    dateTime
    title
    createdAt
  }
}
    `;
export const GetPropertyBrokerDocument = `
    query getPropertyBroker($propertyId: String!) {
  getPropertyBroker(propertyId: $propertyId) {
    id
    name
    surname
    phone
    email
    position
    qualification
    photo
  }
}
    `;
export const PropertyOnOfficeViewDocument = `
    query propertyOnOfficeView($id: String!) {
  propertyOnOfficeView(_id: $id) {
    ...propertyFields
  }
}
    ${PropertyFieldsFragmentDoc}`;
export const RequestedExposeActivitiesDocument = `
    query requestedExposeActivities($limit: Int!, $offset: Int!, $propertyId: String!) {
  requestedExposeActivities(
    limit: $limit
    offset: $offset
    propertyId: $propertyId
  ) {
    _id
    dateTime
    title
    createdAt
  }
}
    `;
export const SellingProgressDocument = `
    query sellingProgress($propertyId: String!) {
  sellingProgress(propertyId: $propertyId)
}
    `;
export const SentExposeActivitiesDocument = `
    query sentExposeActivities($limit: Int!, $offset: Int!, $propertyId: String!) {
  sentExposeActivities(limit: $limit, offset: $offset, propertyId: $propertyId) {
    _id
    dateTime
    title
    createdAt
  }
}
    `;
export const ShopEmailDocument = `
    query shopEmail($propertyId: String!) {
  shopEmail(propertyId: $propertyId) {
    email
    name
  }
}
    `;
export const VirtualTourActivitiesDocument = `
    query virtualTourActivities($limit: Int!, $offset: Int!, $propertyId: String!) {
  virtualTourActivities(limit: $limit, offset: $offset, propertyId: $propertyId) {
    _id
    dateTime
    title
    createdAt
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    changePassword: build.mutation<
      ChangePasswordMutation,
      ChangePasswordMutationVariables
    >({
      query: (variables) => ({ document: ChangePasswordDocument, variables }),
    }),
    confirmEmail: build.mutation<
      ConfirmEmailMutation,
      ConfirmEmailMutationVariables
    >({
      query: (variables) => ({ document: ConfirmEmailDocument, variables }),
    }),
    confirmForgotPassword: build.mutation<
      ConfirmForgotPasswordMutation,
      ConfirmForgotPasswordMutationVariables
    >({
      query: (variables) => ({
        document: ConfirmForgotPasswordDocument,
        variables,
      }),
    }),
    confirmPhoneChange: build.mutation<
      ConfirmPhoneChangeMutation,
      ConfirmPhoneChangeMutationVariables
    >({
      query: (variables) => ({
        document: ConfirmPhoneChangeDocument,
        variables,
      }),
    }),
    confirmPhone: build.mutation<
      ConfirmPhoneMutation,
      ConfirmPhoneMutationVariables
    >({
      query: (variables) => ({ document: ConfirmPhoneDocument, variables }),
    }),
    forgotPassword: build.mutation<
      ForgotPasswordMutation,
      ForgotPasswordMutationVariables
    >({
      query: (variables) => ({ document: ForgotPasswordDocument, variables }),
    }),
    removeUserAccount: build.mutation<
      RemoveUserAccountMutation,
      RemoveUserAccountMutationVariables
    >({
      query: (variables) => ({
        document: RemoveUserAccountDocument,
        variables,
      }),
    }),
    requestVerificationCode: build.mutation<
      RequestVerificationCodeMutation,
      RequestVerificationCodeMutationVariables
    >({
      query: (variables) => ({
        document: RequestVerificationCodeDocument,
        variables,
      }),
    }),
    resendConfirmationCode: build.mutation<
      ResendConfirmationCodeMutation,
      ResendConfirmationCodeMutationVariables
    >({
      query: (variables) => ({
        document: ResendConfirmationCodeDocument,
        variables,
      }),
    }),
    resendPhoneCodeAfterChange: build.mutation<
      ResendPhoneCodeAfterChangeMutation,
      ResendPhoneCodeAfterChangeMutationVariables
    >({
      query: (variables) => ({
        document: ResendPhoneCodeAfterChangeDocument,
        variables,
      }),
    }),
    userSelfDelete: build.mutation<
      UserSelfDeleteMutation,
      UserSelfDeleteMutationVariables
    >({
      query: (variables) => ({ document: UserSelfDeleteDocument, variables }),
    }),
    setUserState: build.mutation<
      SetUserStateMutation,
      SetUserStateMutationVariables
    >({
      query: (variables) => ({ document: SetUserStateDocument, variables }),
    }),
    signInProperties: build.mutation<
      SignInPropertiesMutation,
      SignInPropertiesMutationVariables
    >({
      query: (variables) => ({ document: SignInPropertiesDocument, variables }),
    }),
    signIn: build.mutation<SignInMutation, SignInMutationVariables>({
      query: (variables) => ({ document: SignInDocument, variables }),
    }),
    signUp: build.mutation<SignUpMutation, SignUpMutationVariables>({
      query: (variables) => ({ document: SignUpDocument, variables }),
    }),
    unsubscribeFromNotifications: build.mutation<
      UnsubscribeFromNotificationsMutation,
      UnsubscribeFromNotificationsMutationVariables
    >({
      query: (variables) => ({
        document: UnsubscribeFromNotificationsDocument,
        variables,
      }),
    }),
    updateUserProfile: build.mutation<
      UpdateUserProfileMutation,
      UpdateUserProfileMutationVariables
    >({
      query: (variables) => ({
        document: UpdateUserProfileDocument,
        variables,
      }),
    }),
    currentUser: build.query<
      CurrentUserQuery,
      CurrentUserQueryVariables | void
    >({
      query: (variables) => ({ document: CurrentUserDocument, variables }),
    }),
    getOnOfficeUser: build.query<
      GetOnOfficeUserQuery,
      GetOnOfficeUserQueryVariables
    >({
      query: (variables) => ({ document: GetOnOfficeUserDocument, variables }),
    }),
    saveFileAccessConfig: build.mutation<
      SaveFileAccessConfigMutation,
      SaveFileAccessConfigMutationVariables
    >({
      query: (variables) => ({
        document: SaveFileAccessConfigDocument,
        variables,
      }),
    }),
    uploadPropertyDocuments: build.mutation<
      UploadPropertyDocumentsMutation,
      UploadPropertyDocumentsMutationVariables
    >({
      query: (variables) => ({
        document: UploadPropertyDocumentsDocument,
        variables,
      }),
    }),
    fileAccessConfig: build.query<
      FileAccessConfigQuery,
      FileAccessConfigQueryVariables
    >({
      query: (variables) => ({ document: FileAccessConfigDocument, variables }),
    }),
    getBuyersDocumentAccessStatus: build.query<
      GetBuyersDocumentAccessStatusQuery,
      GetBuyersDocumentAccessStatusQueryVariables
    >({
      query: (variables) => ({
        document: GetBuyersDocumentAccessStatusDocument,
        variables,
      }),
    }),
    getPropertyDocuments: build.query<
      GetPropertyDocumentsQuery,
      GetPropertyDocumentsQueryVariables
    >({
      query: (variables) => ({
        document: GetPropertyDocumentsDocument,
        variables,
      }),
    }),
    propertyAddress: build.query<
      PropertyAddressQuery,
      PropertyAddressQueryVariables
    >({
      query: (variables) => ({ document: PropertyAddressDocument, variables }),
    }),
    sendFeedback: build.mutation<
      SendFeedbackMutation,
      SendFeedbackMutationVariables
    >({
      query: (variables) => ({ document: SendFeedbackDocument, variables }),
    }),
    readCentralPhoneByCountry: build.query<
      ReadCentralPhoneByCountryQuery,
      ReadCentralPhoneByCountryQueryVariables
    >({
      query: (variables) => ({
        document: ReadCentralPhoneByCountryDocument,
        variables,
      }),
    }),
    readBuyerLandingPage: build.query<
      ReadBuyerLandingPageQuery,
      ReadBuyerLandingPageQueryVariables
    >({
      query: (variables) => ({
        document: ReadBuyerLandingPageDocument,
        variables,
      }),
    }),
    readFinancingLandingPage: build.query<
      ReadFinancingLandingPageQuery,
      ReadFinancingLandingPageQueryVariables
    >({
      query: (variables) => ({
        document: ReadFinancingLandingPageDocument,
        variables,
      }),
    }),
    readOverviewLandingPage: build.query<
      ReadOverviewLandingPageQuery,
      ReadOverviewLandingPageQueryVariables
    >({
      query: (variables) => ({
        document: ReadOverviewLandingPageDocument,
        variables,
      }),
    }),
    readAppointmentContactForm: build.query<
      ReadAppointmentContactFormQuery,
      ReadAppointmentContactFormQueryVariables
    >({
      query: (variables) => ({
        document: ReadAppointmentContactFormDocument,
        variables,
      }),
    }),
    readBuyerBenefit: build.query<
      ReadBuyerBenefitQuery,
      ReadBuyerBenefitQueryVariables
    >({
      query: (variables) => ({ document: ReadBuyerBenefitDocument, variables }),
    }),
    readBuyerFunctionsList: build.query<
      ReadBuyerFunctionsListQuery,
      ReadBuyerFunctionsListQueryVariables
    >({
      query: (variables) => ({
        document: ReadBuyerFunctionsListDocument,
        variables,
      }),
    }),
    readBuyerOverview: build.query<
      ReadBuyerOverviewQuery,
      ReadBuyerOverviewQueryVariables
    >({
      query: (variables) => ({
        document: ReadBuyerOverviewDocument,
        variables,
      }),
    }),
    readFinancingOverviews: build.query<
      ReadFinancingOverviewsQuery,
      ReadFinancingOverviewsQueryVariables
    >({
      query: (variables) => ({
        document: ReadFinancingOverviewsDocument,
        variables,
      }),
    }),
    readOverviewApp: build.query<
      ReadOverviewAppQuery,
      ReadOverviewAppQueryVariables
    >({
      query: (variables) => ({ document: ReadOverviewAppDocument, variables }),
    }),
    overviewLandingPageContact: build.mutation<
      OverviewLandingPageContactMutation,
      OverviewLandingPageContactMutationVariables
    >({
      query: (variables) => ({
        document: OverviewLandingPageContactDocument,
        variables,
      }),
    }),
    readOwnerBenefit: build.query<
      ReadOwnerBenefitQuery,
      ReadOwnerBenefitQueryVariables
    >({
      query: (variables) => ({ document: ReadOwnerBenefitDocument, variables }),
    }),
    readOwnerFunctionsList: build.query<
      ReadOwnerFunctionsListQuery,
      ReadOwnerFunctionsListQueryVariables
    >({
      query: (variables) => ({
        document: ReadOwnerFunctionsListDocument,
        variables,
      }),
    }),
    readOwnerOverview: build.query<
      ReadOwnerOverviewQuery,
      ReadOwnerOverviewQueryVariables
    >({
      query: (variables) => ({
        document: ReadOwnerOverviewDocument,
        variables,
      }),
    }),
    readSellerBenefit: build.query<
      ReadSellerBenefitQuery,
      ReadSellerBenefitQueryVariables
    >({
      query: (variables) => ({
        document: ReadSellerBenefitDocument,
        variables,
      }),
    }),
    readSellerFunctionsList: build.query<
      ReadSellerFunctionsListQuery,
      ReadSellerFunctionsListQueryVariables
    >({
      query: (variables) => ({
        document: ReadSellerFunctionsListDocument,
        variables,
      }),
    }),
    readSellerOverview: build.query<
      ReadSellerOverviewQuery,
      ReadSellerOverviewQueryVariables
    >({
      query: (variables) => ({
        document: ReadSellerOverviewDocument,
        variables,
      }),
    }),
    readTestimonialsList: build.query<
      ReadTestimonialsListQuery,
      ReadTestimonialsListQueryVariables
    >({
      query: (variables) => ({
        document: ReadTestimonialsListDocument,
        variables,
      }),
    }),
    readOwnerLandingPage: build.query<
      ReadOwnerLandingPageQuery,
      ReadOwnerLandingPageQueryVariables
    >({
      query: (variables) => ({
        document: ReadOwnerLandingPageDocument,
        variables,
      }),
    }),
    readBuyerFinderTeaser: build.query<
      ReadBuyerFinderTeaserQuery,
      ReadBuyerFinderTeaserQueryVariables
    >({
      query: (variables) => ({
        document: ReadBuyerFinderTeaserDocument,
        variables,
      }),
    }),
    cmsPage: build.query<CmsPageQuery, CmsPageQueryVariables>({
      query: (variables) => ({ document: CmsPageDocument, variables }),
    }),
    cmsPages: build.query<CmsPagesQuery, CmsPagesQueryVariables | void>({
      query: (variables) => ({ document: CmsPagesDocument, variables }),
    }),
    cmsRegionalPage: build.query<
      CmsRegionalPageQuery,
      CmsRegionalPageQueryVariables
    >({
      query: (variables) => ({ document: CmsRegionalPageDocument, variables }),
    }),
    readGeneralAppointment: build.query<
      ReadGeneralAppointmentQuery,
      ReadGeneralAppointmentQueryVariables
    >({
      query: (variables) => ({
        document: ReadGeneralAppointmentDocument,
        variables,
      }),
    }),
    readAdvantage: build.query<ReadAdvantageQuery, ReadAdvantageQueryVariables>(
      {
        query: (variables) => ({ document: ReadAdvantageDocument, variables }),
      }
    ),
    readApp: build.query<ReadAppQuery, ReadAppQueryVariables>({
      query: (variables) => ({ document: ReadAppDocument, variables }),
    }),
    readAppointments: build.query<
      ReadAppointmentsQuery,
      ReadAppointmentsQueryVariables
    >({
      query: (variables) => ({ document: ReadAppointmentsDocument, variables }),
    }),
    readBanner: build.query<ReadBannerQuery, ReadBannerQueryVariables>({
      query: (variables) => ({ document: ReadBannerDocument, variables }),
    }),
    readBenefit: build.query<ReadBenefitQuery, ReadBenefitQueryVariables>({
      query: (variables) => ({ document: ReadBenefitDocument, variables }),
    }),
    benefitsByIds: build.query<
      BenefitsByIdsQuery,
      BenefitsByIdsQueryVariables | void
    >({
      query: (variables) => ({ document: BenefitsByIdsDocument, variables }),
    }),
    readCertificate: build.query<
      ReadCertificateQuery,
      ReadCertificateQueryVariables
    >({
      query: (variables) => ({ document: ReadCertificateDocument, variables }),
    }),
    cmsSection: build.query<CmsSectionQuery, CmsSectionQueryVariables>({
      query: (variables) => ({ document: CmsSectionDocument, variables }),
    }),
    readCustomerAppraisal: build.query<
      ReadCustomerAppraisalQuery,
      ReadCustomerAppraisalQueryVariables
    >({
      query: (variables) => ({
        document: ReadCustomerAppraisalDocument,
        variables,
      }),
    }),
    readFaqs: build.query<ReadFaqsQuery, ReadFaqsQueryVariables>({
      query: (variables) => ({ document: ReadFaqsDocument, variables }),
    }),
    readStepByStepBenefit: build.query<
      ReadStepByStepBenefitQuery,
      ReadStepByStepBenefitQueryVariables
    >({
      query: (variables) => ({
        document: ReadStepByStepBenefitDocument,
        variables,
      }),
    }),
    readFinancingCalculator: build.query<
      ReadFinancingCalculatorQuery,
      ReadFinancingCalculatorQueryVariables
    >({
      query: (variables) => ({
        document: ReadFinancingCalculatorDocument,
        variables,
      }),
    }),
    readFinancingCertificate: build.query<
      ReadFinancingCertificateQuery,
      ReadFinancingCertificateQueryVariables
    >({
      query: (variables) => ({
        document: ReadFinancingCertificateDocument,
        variables,
      }),
    }),
    readFinancingPartner: build.query<
      ReadFinancingPartnerQuery,
      ReadFinancingPartnerQueryVariables
    >({
      query: (variables) => ({
        document: ReadFinancingPartnerDocument,
        variables,
      }),
    }),
    readGeneralAppointmentByCountry: build.query<
      ReadGeneralAppointmentByCountryQuery,
      ReadGeneralAppointmentByCountryQueryVariables
    >({
      query: (variables) => ({
        document: ReadGeneralAppointmentByCountryDocument,
        variables,
      }),
    }),
    readInterestRatesTable: build.query<
      ReadInterestRatesTableQuery,
      ReadInterestRatesTableQueryVariables
    >({
      query: (variables) => ({
        document: ReadInterestRatesTableDocument,
        variables,
      }),
    }),
    readLocalMarket: build.query<
      ReadLocalMarketQuery,
      ReadLocalMarketQueryVariables
    >({
      query: (variables) => ({ document: ReadLocalMarketDocument, variables }),
    }),
    readOurRepresentativeExample: build.query<
      ReadOurRepresentativeExampleQuery,
      ReadOurRepresentativeExampleQueryVariables
    >({
      query: (variables) => ({
        document: ReadOurRepresentativeExampleDocument,
        variables,
      }),
    }),
    readVideo: build.query<ReadVideoQuery, ReadVideoQueryVariables>({
      query: (variables) => ({ document: ReadVideoDocument, variables }),
    }),
    readVirtualTours: build.query<
      ReadVirtualToursQuery,
      ReadVirtualToursQueryVariables
    >({
      query: (variables) => ({ document: ReadVirtualToursDocument, variables }),
    }),
    readSellerLandingPage: build.query<
      ReadSellerLandingPageQuery,
      ReadSellerLandingPageQueryVariables
    >({
      query: (variables) => ({
        document: ReadSellerLandingPageDocument,
        variables,
      }),
    }),
    convertChfToEur: build.query<
      ConvertChfToEurQuery,
      ConvertChfToEurQueryVariables
    >({
      query: (variables) => ({ document: ConvertChfToEurDocument, variables }),
    }),
    convertEurToChf: build.query<
      ConvertEurToChfQuery,
      ConvertEurToChfQueryVariables
    >({
      query: (variables) => ({ document: ConvertEurToChfDocument, variables }),
    }),
    experiments: build.query<
      ExperimentsQuery,
      ExperimentsQueryVariables | void
    >({
      query: (variables) => ({ document: ExperimentsDocument, variables }),
    }),
    getEurToChfRate: build.query<
      GetEurToChfRateQuery,
      GetEurToChfRateQueryVariables | void
    >({
      query: (variables) => ({ document: GetEurToChfRateDocument, variables }),
    }),
    getProvenExpertRating: build.query<
      GetProvenExpertRatingQuery,
      GetProvenExpertRatingQueryVariables | void
    >({
      query: (variables) => ({
        document: GetProvenExpertRatingDocument,
        variables,
      }),
    }),
    getContentPage: build.query<
      GetContentPageQuery,
      GetContentPageQueryVariables
    >({
      query: (variables) => ({ document: GetContentPageDocument, variables }),
    }),
    suggestedProperty: build.query<
      SuggestedPropertyQuery,
      SuggestedPropertyQueryVariables
    >({
      query: (variables) => ({
        document: SuggestedPropertyDocument,
        variables,
      }),
    }),
    createExposeRequest: build.mutation<
      CreateExposeRequestMutation,
      CreateExposeRequestMutationVariables
    >({
      query: (variables) => ({
        document: CreateExposeRequestDocument,
        variables,
      }),
    }),
    getPdfLink: build.mutation<GetPdfLinkMutation, GetPdfLinkMutationVariables>(
      {
        query: (variables) => ({ document: GetPdfLinkDocument, variables }),
      }
    ),
    setUserIdForExposes: build.mutation<
      SetUserIdForExposesMutation,
      SetUserIdForExposesMutationVariables
    >({
      query: (variables) => ({
        document: SetUserIdForExposesDocument,
        variables,
      }),
    }),
    approvedExposeRequests: build.mutation<
      ApprovedExposeRequestsMutation,
      ApprovedExposeRequestsMutationVariables
    >({
      query: (variables) => ({
        document: ApprovedExposeRequestsDocument,
        variables,
      }),
    }),
    readUserPurchaseIntents: build.query<
      ReadUserPurchaseIntentsQuery,
      ReadUserPurchaseIntentsQueryVariables
    >({
      query: (variables) => ({
        document: ReadUserPurchaseIntentsDocument,
        variables,
      }),
    }),
    expandFinancingRadius: build.mutation<
      ExpandFinancingRadiusMutation,
      ExpandFinancingRadiusMutationVariables
    >({
      query: (variables) => ({
        document: ExpandFinancingRadiusDocument,
        variables,
      }),
    }),
    removeFinancing: build.mutation<
      RemoveFinancingMutation,
      RemoveFinancingMutationVariables
    >({
      query: (variables) => ({ document: RemoveFinancingDocument, variables }),
    }),
    sendVonPollFinance: build.mutation<
      SendVonPollFinanceMutation,
      SendVonPollFinanceMutationVariables
    >({
      query: (variables) => ({
        document: SendVonPollFinanceDocument,
        variables,
      }),
    }),
    updateFinancingVariantName: build.mutation<
      UpdateFinancingVariantNameMutation,
      UpdateFinancingVariantNameMutationVariables
    >({
      query: (variables) => ({
        document: UpdateFinancingVariantNameDocument,
        variables,
      }),
    }),
    financing: build.query<FinancingQuery, FinancingQueryVariables>({
      query: (variables) => ({ document: FinancingDocument, variables }),
    }),
    financings: build.query<FinancingsQuery, FinancingsQueryVariables | void>({
      query: (variables) => ({ document: FinancingsDocument, variables }),
    }),
    getFinancingVonPollPropertyDetails: build.query<
      GetFinancingVonPollPropertyDetailsQuery,
      GetFinancingVonPollPropertyDetailsQueryVariables
    >({
      query: (variables) => ({
        document: GetFinancingVonPollPropertyDetailsDocument,
        variables,
      }),
    }),
    deletePropertyPurchaseIntent: build.mutation<
      DeletePropertyPurchaseIntentMutation,
      DeletePropertyPurchaseIntentMutationVariables
    >({
      query: (variables) => ({
        document: DeletePropertyPurchaseIntentDocument,
        variables,
      }),
    }),
    readPropertyPurchaseIntents: build.query<
      ReadPropertyPurchaseIntentsQuery,
      ReadPropertyPurchaseIntentsQueryVariables
    >({
      query: (variables) => ({
        document: ReadPropertyPurchaseIntentsDocument,
        variables,
      }),
    }),
    readPurchaseIntent: build.query<
      ReadPurchaseIntentQuery,
      ReadPurchaseIntentQueryVariables
    >({
      query: (variables) => ({
        document: ReadPurchaseIntentDocument,
        variables,
      }),
    }),
    createFinancing: build.mutation<
      CreateFinancingMutation,
      CreateFinancingMutationVariables
    >({
      query: (variables) => ({ document: CreateFinancingDocument, variables }),
    }),
    updateFinancing: build.mutation<
      UpdateFinancingMutation,
      UpdateFinancingMutationVariables
    >({
      query: (variables) => ({ document: UpdateFinancingDocument, variables }),
    }),
    updateFinancingPropositions: build.mutation<
      UpdateFinancingPropositionsMutation,
      UpdateFinancingPropositionsMutationVariables
    >({
      query: (variables) => ({
        document: UpdateFinancingPropositionsDocument,
        variables,
      }),
    }),
    getFinancingPropositions: build.mutation<
      GetFinancingPropositionsMutation,
      GetFinancingPropositionsMutationVariables
    >({
      query: (variables) => ({
        document: GetFinancingPropositionsDocument,
        variables,
      }),
    }),
    readEuropaceInterestRates: build.query<
      ReadEuropaceInterestRatesQuery,
      ReadEuropaceInterestRatesQueryVariables | void
    >({
      query: (variables) => ({
        document: ReadEuropaceInterestRatesDocument,
        variables,
      }),
    }),
    confirmGuestUser: build.mutation<
      ConfirmGuestUserMutation,
      ConfirmGuestUserMutationVariables
    >({
      query: (variables) => ({ document: ConfirmGuestUserDocument, variables }),
    }),
    convertProperty: build.mutation<
      ConvertPropertyMutation,
      ConvertPropertyMutationVariables
    >({
      query: (variables) => ({ document: ConvertPropertyDocument, variables }),
    }),
    createPropertyDossier: build.mutation<
      CreatePropertyDossierMutation,
      CreatePropertyDossierMutationVariables
    >({
      query: (variables) => ({
        document: CreatePropertyDossierDocument,
        variables,
      }),
    }),
    createProperty: build.mutation<
      CreatePropertyMutation,
      CreatePropertyMutationVariables
    >({
      query: (variables) => ({ document: CreatePropertyDocument, variables }),
    }),
    createTemporaryProperty: build.mutation<
      CreateTemporaryPropertyMutation,
      CreateTemporaryPropertyMutationVariables
    >({
      query: (variables) => ({
        document: CreateTemporaryPropertyDocument,
        variables,
      }),
    }),
    updatePropertyAlerts: build.mutation<
      UpdatePropertyAlertsMutation,
      UpdatePropertyAlertsMutationVariables
    >({
      query: (variables) => ({
        document: UpdatePropertyAlertsDocument,
        variables,
      }),
    }),
    updatePropertyPortfolio: build.mutation<
      UpdatePropertyPortfolioMutation,
      UpdatePropertyPortfolioMutationVariables
    >({
      query: (variables) => ({
        document: UpdatePropertyPortfolioDocument,
        variables,
      }),
    }),
    updateProperty: build.mutation<
      UpdatePropertyMutation,
      UpdatePropertyMutationVariables
    >({
      query: (variables) => ({ document: UpdatePropertyDocument, variables }),
    }),
    removeProperty: build.mutation<
      RemovePropertyMutation,
      RemovePropertyMutationVariables
    >({
      query: (variables) => ({ document: RemovePropertyDocument, variables }),
    }),
    resendGuestUserCode: build.mutation<
      ResendGuestUserCodeMutation,
      ResendGuestUserCodeMutationVariables
    >({
      query: (variables) => ({
        document: ResendGuestUserCodeDocument,
        variables,
      }),
    }),
    updatePropertyFavoriteSearchProfilesList: build.mutation<
      UpdatePropertyFavoriteSearchProfilesListMutation,
      UpdatePropertyFavoriteSearchProfilesListMutationVariables
    >({
      query: (variables) => ({
        document: UpdatePropertyFavoriteSearchProfilesListDocument,
        variables,
      }),
    }),
    verifyGuestUser: build.mutation<
      VerifyGuestUserMutation,
      VerifyGuestUserMutationVariables
    >({
      query: (variables) => ({ document: VerifyGuestUserDocument, variables }),
    }),
    getPropertyFavoriteSearchProfilesList: build.query<
      GetPropertyFavoriteSearchProfilesListQuery,
      GetPropertyFavoriteSearchProfilesListQueryVariables
    >({
      query: (variables) => ({
        document: GetPropertyFavoriteSearchProfilesListDocument,
        variables,
      }),
    }),
    propertyValuation: build.query<
      PropertyValuationQuery,
      PropertyValuationQueryVariables
    >({
      query: (variables) => ({
        document: PropertyValuationDocument,
        variables,
      }),
    }),
    createPurchaseIntent: build.mutation<
      CreatePurchaseIntentMutation,
      CreatePurchaseIntentMutationVariables
    >({
      query: (variables) => ({
        document: CreatePurchaseIntentDocument,
        variables,
      }),
    }),
    convertSearchProfile: build.mutation<
      ConvertSearchProfileMutation,
      ConvertSearchProfileMutationVariables
    >({
      query: (variables) => ({
        document: ConvertSearchProfileDocument,
        variables,
      }),
    }),
    createSearchProfile: build.mutation<
      CreateSearchProfileMutation,
      CreateSearchProfileMutationVariables
    >({
      query: (variables) => ({
        document: CreateSearchProfileDocument,
        variables,
      }),
    }),
    createTemporarySearchProfile: build.mutation<
      CreateTemporarySearchProfileMutation,
      CreateTemporarySearchProfileMutationVariables
    >({
      query: (variables) => ({
        document: CreateTemporarySearchProfileDocument,
        variables,
      }),
    }),
    removeSearchProfile: build.mutation<
      RemoveSearchProfileMutation,
      RemoveSearchProfileMutationVariables
    >({
      query: (variables) => ({
        document: RemoveSearchProfileDocument,
        variables,
      }),
    }),
    updateSearchProfile: build.mutation<
      UpdateSearchProfileMutation,
      UpdateSearchProfileMutationVariables
    >({
      query: (variables) => ({
        document: UpdateSearchProfileDocument,
        variables,
      }),
    }),
    updateTemporarySearchProfile: build.mutation<
      UpdateTemporarySearchProfileMutation,
      UpdateTemporarySearchProfileMutationVariables
    >({
      query: (variables) => ({
        document: UpdateTemporarySearchProfileDocument,
        variables,
      }),
    }),
    suggestedProperties: build.query<
      SuggestedPropertiesQuery,
      SuggestedPropertiesQueryVariables | void
    >({
      query: (variables) => ({
        document: SuggestedPropertiesDocument,
        variables,
      }),
    }),
    acceptInvitation: build.mutation<
      AcceptInvitationMutation,
      AcceptInvitationMutationVariables
    >({
      query: (variables) => ({ document: AcceptInvitationDocument, variables }),
    }),
    checkInvitationHealth: build.query<
      CheckInvitationHealthQuery,
      CheckInvitationHealthQueryVariables
    >({
      query: (variables) => ({
        document: CheckInvitationHealthDocument,
        variables,
      }),
    }),
    deleteInvitation: build.mutation<
      DeleteInvitationMutation,
      DeleteInvitationMutationVariables
    >({
      query: (variables) => ({ document: DeleteInvitationDocument, variables }),
    }),
    editInvitation: build.mutation<
      EditInvitationMutation,
      EditInvitationMutationVariables
    >({
      query: (variables) => ({ document: EditInvitationDocument, variables }),
    }),
    exportPdf: build.mutation<
      ExportPdfMutation,
      ExportPdfMutationVariables | void
    >({
      query: (variables) => ({ document: ExportPdfDocument, variables }),
    }),
    notifyBrokerForBuyerFinder: build.mutation<
      NotifyBrokerForBuyerFinderMutation,
      NotifyBrokerForBuyerFinderMutationVariables
    >({
      query: (variables) => ({
        document: NotifyBrokerForBuyerFinderDocument,
        variables,
      }),
    }),
    sendFeedbackOfOwnerPortal: build.mutation<
      SendFeedbackOfOwnerPortalMutation,
      SendFeedbackOfOwnerPortalMutationVariables
    >({
      query: (variables) => ({
        document: SendFeedbackOfOwnerPortalDocument,
        variables,
      }),
    }),
    shareProperty: build.mutation<
      SharePropertyMutation,
      SharePropertyMutationVariables
    >({
      query: (variables) => ({ document: SharePropertyDocument, variables }),
    }),
    findRelatedSearchProfiles: build.query<
      FindRelatedSearchProfilesQuery,
      FindRelatedSearchProfilesQueryVariables
    >({
      query: (variables) => ({
        document: FindRelatedSearchProfilesDocument,
        variables,
      }),
    }),
    generalMarketReportByCountry: build.query<
      GeneralMarketReportByCountryQuery,
      GeneralMarketReportByCountryQueryVariables
    >({
      query: (variables) => ({
        document: GeneralMarketReportByCountryDocument,
        variables,
      }),
    }),
    getTemporaryProperty: build.query<
      GetTemporaryPropertyQuery,
      GetTemporaryPropertyQueryVariables | void
    >({
      query: (variables) => ({
        document: GetTemporaryPropertyDocument,
        variables,
      }),
    }),
    invites: build.query<InvitesQuery, InvitesQueryVariables>({
      query: (variables) => ({ document: InvitesDocument, variables }),
    }),
    Isochrones: build.query<IsochronesQuery, IsochronesQueryVariables>({
      query: (variables) => ({ document: IsochronesDocument, variables }),
    }),
    marketReport: build.query<MarketReportQuery, MarketReportQueryVariables>({
      query: (variables) => ({ document: MarketReportDocument, variables }),
    }),
    navigationProperties: build.query<
      NavigationPropertiesQuery,
      NavigationPropertiesQueryVariables | void
    >({
      query: (variables) => ({
        document: NavigationPropertiesDocument,
        variables,
      }),
    }),
    ourDestinations: build.query<
      OurDestinationsQuery,
      OurDestinationsQueryVariables | void
    >({
      query: (variables) => ({ document: OurDestinationsDocument, variables }),
    }),
    paginatedPropertyPortfolio: build.query<
      PaginatedPropertyPortfolioQuery,
      PaginatedPropertyPortfolioQueryVariables | void
    >({
      query: (variables) => ({
        document: PaginatedPropertyPortfolioDocument,
        variables,
      }),
    }),
    properties: build.query<PropertiesQuery, PropertiesQueryVariables | void>({
      query: (variables) => ({ document: PropertiesDocument, variables }),
    }),
    propertyDefaultFilters: build.query<
      PropertyDefaultFiltersQuery,
      PropertyDefaultFiltersQueryVariables | void
    >({
      query: (variables) => ({
        document: PropertyDefaultFiltersDocument,
        variables,
      }),
    }),
    propertyPortfolio: build.query<
      PropertyPortfolioQuery,
      PropertyPortfolioQueryVariables | void
    >({
      query: (variables) => ({
        document: PropertyPortfolioDocument,
        variables,
      }),
    }),
    property: build.query<PropertyQuery, PropertyQueryVariables>({
      query: (variables) => ({ document: PropertyDocument, variables }),
    }),
    readBuyerFinderSettings: build.query<
      ReadBuyerFinderSettingsQuery,
      ReadBuyerFinderSettingsQueryVariables
    >({
      query: (variables) => ({
        document: ReadBuyerFinderSettingsDocument,
        variables,
      }),
    }),
    shopContacts: build.query<ShopContactsQuery, ShopContactsQueryVariables>({
      query: (variables) => ({ document: ShopContactsDocument, variables }),
    }),
    similarProperties: build.query<
      SimilarPropertiesQuery,
      SimilarPropertiesQueryVariables
    >({
      query: (variables) => ({
        document: SimilarPropertiesDocument,
        variables,
      }),
    }),
    temporaryProperty: build.query<
      TemporaryPropertyQuery,
      TemporaryPropertyQueryVariables | void
    >({
      query: (variables) => ({
        document: TemporaryPropertyDocument,
        variables,
      }),
    }),
    readUserAppointments: build.query<
      ReadUserAppointmentsQuery,
      ReadUserAppointmentsQueryVariables | void
    >({
      query: (variables) => ({
        document: ReadUserAppointmentsDocument,
        variables,
      }),
    }),
    generatePdfExposeLink: build.mutation<
      GeneratePdfExposeLinkMutation,
      GeneratePdfExposeLinkMutationVariables
    >({
      query: (variables) => ({
        document: GeneratePdfExposeLinkDocument,
        variables,
      }),
    }),
    exposeRequests: build.query<
      ExposeRequestsQuery,
      ExposeRequestsQueryVariables | void
    >({
      query: (variables) => ({ document: ExposeRequestsDocument, variables }),
    }),
    dislikeProperty: build.mutation<
      DislikePropertyMutation,
      DislikePropertyMutationVariables
    >({
      query: (variables) => ({ document: DislikePropertyDocument, variables }),
    }),
    likeProperty: build.mutation<
      LikePropertyMutation,
      LikePropertyMutationVariables
    >({
      query: (variables) => ({ document: LikePropertyDocument, variables }),
    }),
    markPropertyUnsuitable: build.mutation<
      MarkPropertyUnsuitableMutation,
      MarkPropertyUnsuitableMutationVariables
    >({
      query: (variables) => ({
        document: MarkPropertyUnsuitableDocument,
        variables,
      }),
    }),
    sendExposeContactForm: build.mutation<
      SendExposeContactFormMutation,
      SendExposeContactFormMutationVariables
    >({
      query: (variables) => ({
        document: SendExposeContactFormDocument,
        variables,
      }),
    }),
    updatePriceOffers: build.mutation<
      UpdatePriceOffersMutation,
      UpdatePriceOffersMutationVariables
    >({
      query: (variables) => ({
        document: UpdatePriceOffersDocument,
        variables,
      }),
    }),
    searchProfile: build.query<SearchProfileQuery, SearchProfileQueryVariables>(
      {
        query: (variables) => ({ document: SearchProfileDocument, variables }),
      }
    ),
    searchProfiles: build.query<
      SearchProfilesQuery,
      SearchProfilesQueryVariables | void
    >({
      query: (variables) => ({ document: SearchProfilesDocument, variables }),
    }),
    temporarySearchProfile: build.query<
      TemporarySearchProfileQuery,
      TemporarySearchProfileQueryVariables | void
    >({
      query: (variables) => ({
        document: TemporarySearchProfileDocument,
        variables,
      }),
    }),
    createDownloadedFileActivity: build.mutation<
      CreateDownloadedFileActivityMutation,
      CreateDownloadedFileActivityMutationVariables
    >({
      query: (variables) => ({
        document: CreateDownloadedFileActivityDocument,
        variables,
      }),
    }),
    exposeDetails: build.query<ExposeDetailsQuery, ExposeDetailsQueryVariables>(
      {
        query: (variables) => ({ document: ExposeDetailsDocument, variables }),
      }
    ),
    getExposeDocuments: build.query<
      GetExposeDocumentsQuery,
      GetExposeDocumentsQueryVariables
    >({
      query: (variables) => ({
        document: GetExposeDocumentsDocument,
        variables,
      }),
    }),
    readUserContractAppointments: build.query<
      ReadUserContractAppointmentsQuery,
      ReadUserContractAppointmentsQueryVariables
    >({
      query: (variables) => ({
        document: ReadUserContractAppointmentsDocument,
        variables,
      }),
    }),
    acceptPurchaseOffer: build.mutation<
      AcceptPurchaseOfferMutation,
      AcceptPurchaseOfferMutationVariables
    >({
      query: (variables) => ({
        document: AcceptPurchaseOfferDocument,
        variables,
      }),
    }),
    getSellerExposePdfLink: build.mutation<
      GetSellerExposePdfLinkMutation,
      GetSellerExposePdfLinkMutationVariables
    >({
      query: (variables) => ({
        document: GetSellerExposePdfLinkDocument,
        variables,
      }),
    }),
    initiateRealSell: build.mutation<
      InitiateRealSellMutation,
      InitiateRealSellMutationVariables
    >({
      query: (variables) => ({ document: InitiateRealSellDocument, variables }),
    }),
    inviteSeller: build.mutation<
      InviteSellerMutation,
      InviteSellerMutationVariables
    >({
      query: (variables) => ({ document: InviteSellerDocument, variables }),
    }),
    sendPropertyServices: build.mutation<
      SendPropertyServicesMutation,
      SendPropertyServicesMutationVariables
    >({
      query: (variables) => ({
        document: SendPropertyServicesDocument,
        variables,
      }),
    }),
    sendSellerRegisteredNotification: build.mutation<
      SendSellerRegisteredNotificationMutation,
      SendSellerRegisteredNotificationMutationVariables
    >({
      query: (variables) => ({
        document: SendSellerRegisteredNotificationDocument,
        variables,
      }),
    }),
    setDisabledShopServices: build.mutation<
      SetDisabledShopServicesMutation,
      SetDisabledShopServicesMutationVariables
    >({
      query: (variables) => ({
        document: SetDisabledShopServicesDocument,
        variables,
      }),
    }),
    setPropertyExposeStatus: build.mutation<
      SetPropertyExposeStatusMutation,
      SetPropertyExposeStatusMutationVariables
    >({
      query: (variables) => ({
        document: SetPropertyExposeStatusDocument,
        variables,
      }),
    }),
    setPropertyOwnerId: build.mutation<
      SetPropertyOwnerIdMutation,
      SetPropertyOwnerIdMutationVariables
    >({
      query: (variables) => ({
        document: SetPropertyOwnerIdDocument,
        variables,
      }),
    }),
    setPropertyPortalStatus: build.mutation<
      SetPropertyPortalStatusMutation,
      SetPropertyPortalStatusMutationVariables
    >({
      query: (variables) => ({
        document: SetPropertyPortalStatusDocument,
        variables,
      }),
    }),
    uploadPropertyFiles: build.mutation<
      UploadPropertyFilesMutation,
      UploadPropertyFilesMutationVariables
    >({
      query: (variables) => ({
        document: UploadPropertyFilesDocument,
        variables,
      }),
    }),
    appointmentActivities: build.query<
      AppointmentActivitiesQuery,
      AppointmentActivitiesQueryVariables
    >({
      query: (variables) => ({
        document: AppointmentActivitiesDocument,
        variables,
      }),
    }),
    checkBrokerSettingsPermission: build.query<
      CheckBrokerSettingsPermissionQuery,
      CheckBrokerSettingsPermissionQueryVariables
    >({
      query: (variables) => ({
        document: CheckBrokerSettingsPermissionDocument,
        variables,
      }),
    }),
    dashboardStatus: build.query<
      DashboardStatusQuery,
      DashboardStatusQueryVariables
    >({
      query: (variables) => ({ document: DashboardStatusDocument, variables }),
    }),
    digitalServiceStatus: build.query<
      DigitalServiceStatusQuery,
      DigitalServiceStatusQueryVariables
    >({
      query: (variables) => ({
        document: DigitalServiceStatusDocument,
        variables,
      }),
    }),
    disabledShopServices: build.query<
      DisabledShopServicesQuery,
      DisabledShopServicesQueryVariables
    >({
      query: (variables) => ({
        document: DisabledShopServicesDocument,
        variables,
      }),
    }),
    documentsActivities: build.query<
      DocumentsActivitiesQuery,
      DocumentsActivitiesQueryVariables
    >({
      query: (variables) => ({
        document: DocumentsActivitiesDocument,
        variables,
      }),
    }),
    downloadedExposeActivities: build.query<
      DownloadedExposeActivitiesQuery,
      DownloadedExposeActivitiesQueryVariables
    >({
      query: (variables) => ({
        document: DownloadedExposeActivitiesDocument,
        variables,
      }),
    }),
    exposeActivities: build.query<
      ExposeActivitiesQuery,
      ExposeActivitiesQueryVariables
    >({
      query: (variables) => ({ document: ExposeActivitiesDocument, variables }),
    }),
    getGeneralStatistics: build.query<
      GetGeneralStatisticsQuery,
      GetGeneralStatisticsQueryVariables
    >({
      query: (variables) => ({
        document: GetGeneralStatisticsDocument,
        variables,
      }),
    }),
    getMarketingStatistics: build.query<
      GetMarketingStatisticsQuery,
      GetMarketingStatisticsQueryVariables
    >({
      query: (variables) => ({
        document: GetMarketingStatisticsDocument,
        variables,
      }),
    }),
    getPropertyExposeStatus: build.query<
      GetPropertyExposeStatusQuery,
      GetPropertyExposeStatusQueryVariables
    >({
      query: (variables) => ({
        document: GetPropertyExposeStatusDocument,
        variables,
      }),
    }),
    getPropertyFiles: build.query<
      GetPropertyFilesQuery,
      GetPropertyFilesQueryVariables
    >({
      query: (variables) => ({ document: GetPropertyFilesDocument, variables }),
    }),
    getPropertyServices: build.query<
      GetPropertyServicesQuery,
      GetPropertyServicesQueryVariables
    >({
      query: (variables) => ({
        document: GetPropertyServicesDocument,
        variables,
      }),
    }),
    getShopContacts: build.query<
      GetShopContactsQuery,
      GetShopContactsQueryVariables
    >({
      query: (variables) => ({ document: GetShopContactsDocument, variables }),
    }),
    handOverActivities: build.query<
      HandOverActivitiesQuery,
      HandOverActivitiesQueryVariables
    >({
      query: (variables) => ({
        document: HandOverActivitiesDocument,
        variables,
      }),
    }),
    latestActivities: build.query<
      LatestActivitiesQuery,
      LatestActivitiesQueryVariables
    >({
      query: (variables) => ({ document: LatestActivitiesDocument, variables }),
    }),
    marketingActivities: build.query<
      MarketingActivitiesQuery,
      MarketingActivitiesQueryVariables
    >({
      query: (variables) => ({
        document: MarketingActivitiesDocument,
        variables,
      }),
    }),
    marketingStatus: build.query<
      MarketingStatusQuery,
      MarketingStatusQueryVariables
    >({
      query: (variables) => ({ document: MarketingStatusDocument, variables }),
    }),
    notaryActivities: build.query<
      NotaryActivitiesQuery,
      NotaryActivitiesQueryVariables
    >({
      query: (variables) => ({ document: NotaryActivitiesDocument, variables }),
    }),
    readProperties: build.query<
      ReadPropertiesQuery,
      ReadPropertiesQueryVariables | void
    >({
      query: (variables) => ({ document: ReadPropertiesDocument, variables }),
    }),
    phoneCallsActivities: build.query<
      PhoneCallsActivitiesQuery,
      PhoneCallsActivitiesQueryVariables
    >({
      query: (variables) => ({
        document: PhoneCallsActivitiesDocument,
        variables,
      }),
    }),
    priceOfferActivities: build.query<
      PriceOfferActivitiesQuery,
      PriceOfferActivitiesQueryVariables
    >({
      query: (variables) => ({
        document: PriceOfferActivitiesDocument,
        variables,
      }),
    }),
    getPropertyBroker: build.query<
      GetPropertyBrokerQuery,
      GetPropertyBrokerQueryVariables
    >({
      query: (variables) => ({
        document: GetPropertyBrokerDocument,
        variables,
      }),
    }),
    propertyOnOfficeView: build.query<
      PropertyOnOfficeViewQuery,
      PropertyOnOfficeViewQueryVariables
    >({
      query: (variables) => ({
        document: PropertyOnOfficeViewDocument,
        variables,
      }),
    }),
    requestedExposeActivities: build.query<
      RequestedExposeActivitiesQuery,
      RequestedExposeActivitiesQueryVariables
    >({
      query: (variables) => ({
        document: RequestedExposeActivitiesDocument,
        variables,
      }),
    }),
    sellingProgress: build.query<
      SellingProgressQuery,
      SellingProgressQueryVariables
    >({
      query: (variables) => ({ document: SellingProgressDocument, variables }),
    }),
    sentExposeActivities: build.query<
      SentExposeActivitiesQuery,
      SentExposeActivitiesQueryVariables
    >({
      query: (variables) => ({
        document: SentExposeActivitiesDocument,
        variables,
      }),
    }),
    shopEmail: build.query<ShopEmailQuery, ShopEmailQueryVariables>({
      query: (variables) => ({ document: ShopEmailDocument, variables }),
    }),
    virtualTourActivities: build.query<
      VirtualTourActivitiesQuery,
      VirtualTourActivitiesQueryVariables
    >({
      query: (variables) => ({
        document: VirtualTourActivitiesDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useChangePasswordMutation,
  useConfirmEmailMutation,
  useConfirmForgotPasswordMutation,
  useConfirmPhoneChangeMutation,
  useConfirmPhoneMutation,
  useForgotPasswordMutation,
  useRemoveUserAccountMutation,
  useRequestVerificationCodeMutation,
  useResendConfirmationCodeMutation,
  useResendPhoneCodeAfterChangeMutation,
  useUserSelfDeleteMutation,
  useSetUserStateMutation,
  useSignInPropertiesMutation,
  useSignInMutation,
  useSignUpMutation,
  useUnsubscribeFromNotificationsMutation,
  useUpdateUserProfileMutation,
  useCurrentUserQuery,
  useLazyCurrentUserQuery,
  useGetOnOfficeUserQuery,
  useLazyGetOnOfficeUserQuery,
  useSaveFileAccessConfigMutation,
  useUploadPropertyDocumentsMutation,
  useFileAccessConfigQuery,
  useLazyFileAccessConfigQuery,
  useGetBuyersDocumentAccessStatusQuery,
  useLazyGetBuyersDocumentAccessStatusQuery,
  useGetPropertyDocumentsQuery,
  useLazyGetPropertyDocumentsQuery,
  usePropertyAddressQuery,
  useLazyPropertyAddressQuery,
  useSendFeedbackMutation,
  useReadCentralPhoneByCountryQuery,
  useLazyReadCentralPhoneByCountryQuery,
  useReadBuyerLandingPageQuery,
  useLazyReadBuyerLandingPageQuery,
  useReadFinancingLandingPageQuery,
  useLazyReadFinancingLandingPageQuery,
  useReadOverviewLandingPageQuery,
  useLazyReadOverviewLandingPageQuery,
  useReadAppointmentContactFormQuery,
  useLazyReadAppointmentContactFormQuery,
  useReadBuyerBenefitQuery,
  useLazyReadBuyerBenefitQuery,
  useReadBuyerFunctionsListQuery,
  useLazyReadBuyerFunctionsListQuery,
  useReadBuyerOverviewQuery,
  useLazyReadBuyerOverviewQuery,
  useReadFinancingOverviewsQuery,
  useLazyReadFinancingOverviewsQuery,
  useReadOverviewAppQuery,
  useLazyReadOverviewAppQuery,
  useOverviewLandingPageContactMutation,
  useReadOwnerBenefitQuery,
  useLazyReadOwnerBenefitQuery,
  useReadOwnerFunctionsListQuery,
  useLazyReadOwnerFunctionsListQuery,
  useReadOwnerOverviewQuery,
  useLazyReadOwnerOverviewQuery,
  useReadSellerBenefitQuery,
  useLazyReadSellerBenefitQuery,
  useReadSellerFunctionsListQuery,
  useLazyReadSellerFunctionsListQuery,
  useReadSellerOverviewQuery,
  useLazyReadSellerOverviewQuery,
  useReadTestimonialsListQuery,
  useLazyReadTestimonialsListQuery,
  useReadOwnerLandingPageQuery,
  useLazyReadOwnerLandingPageQuery,
  useReadBuyerFinderTeaserQuery,
  useLazyReadBuyerFinderTeaserQuery,
  useCmsPageQuery,
  useLazyCmsPageQuery,
  useCmsPagesQuery,
  useLazyCmsPagesQuery,
  useCmsRegionalPageQuery,
  useLazyCmsRegionalPageQuery,
  useReadGeneralAppointmentQuery,
  useLazyReadGeneralAppointmentQuery,
  useReadAdvantageQuery,
  useLazyReadAdvantageQuery,
  useReadAppQuery,
  useLazyReadAppQuery,
  useReadAppointmentsQuery,
  useLazyReadAppointmentsQuery,
  useReadBannerQuery,
  useLazyReadBannerQuery,
  useReadBenefitQuery,
  useLazyReadBenefitQuery,
  useBenefitsByIdsQuery,
  useLazyBenefitsByIdsQuery,
  useReadCertificateQuery,
  useLazyReadCertificateQuery,
  useCmsSectionQuery,
  useLazyCmsSectionQuery,
  useReadCustomerAppraisalQuery,
  useLazyReadCustomerAppraisalQuery,
  useReadFaqsQuery,
  useLazyReadFaqsQuery,
  useReadStepByStepBenefitQuery,
  useLazyReadStepByStepBenefitQuery,
  useReadFinancingCalculatorQuery,
  useLazyReadFinancingCalculatorQuery,
  useReadFinancingCertificateQuery,
  useLazyReadFinancingCertificateQuery,
  useReadFinancingPartnerQuery,
  useLazyReadFinancingPartnerQuery,
  useReadGeneralAppointmentByCountryQuery,
  useLazyReadGeneralAppointmentByCountryQuery,
  useReadInterestRatesTableQuery,
  useLazyReadInterestRatesTableQuery,
  useReadLocalMarketQuery,
  useLazyReadLocalMarketQuery,
  useReadOurRepresentativeExampleQuery,
  useLazyReadOurRepresentativeExampleQuery,
  useReadVideoQuery,
  useLazyReadVideoQuery,
  useReadVirtualToursQuery,
  useLazyReadVirtualToursQuery,
  useReadSellerLandingPageQuery,
  useLazyReadSellerLandingPageQuery,
  useConvertChfToEurQuery,
  useLazyConvertChfToEurQuery,
  useConvertEurToChfQuery,
  useLazyConvertEurToChfQuery,
  useExperimentsQuery,
  useLazyExperimentsQuery,
  useGetEurToChfRateQuery,
  useLazyGetEurToChfRateQuery,
  useGetProvenExpertRatingQuery,
  useLazyGetProvenExpertRatingQuery,
  useGetContentPageQuery,
  useLazyGetContentPageQuery,
  useSuggestedPropertyQuery,
  useLazySuggestedPropertyQuery,
  useCreateExposeRequestMutation,
  useGetPdfLinkMutation,
  useSetUserIdForExposesMutation,
  useApprovedExposeRequestsMutation,
  useReadUserPurchaseIntentsQuery,
  useLazyReadUserPurchaseIntentsQuery,
  useExpandFinancingRadiusMutation,
  useRemoveFinancingMutation,
  useSendVonPollFinanceMutation,
  useUpdateFinancingVariantNameMutation,
  useFinancingQuery,
  useLazyFinancingQuery,
  useFinancingsQuery,
  useLazyFinancingsQuery,
  useGetFinancingVonPollPropertyDetailsQuery,
  useLazyGetFinancingVonPollPropertyDetailsQuery,
  useDeletePropertyPurchaseIntentMutation,
  useReadPropertyPurchaseIntentsQuery,
  useLazyReadPropertyPurchaseIntentsQuery,
  useReadPurchaseIntentQuery,
  useLazyReadPurchaseIntentQuery,
  useCreateFinancingMutation,
  useUpdateFinancingMutation,
  useUpdateFinancingPropositionsMutation,
  useGetFinancingPropositionsMutation,
  useReadEuropaceInterestRatesQuery,
  useLazyReadEuropaceInterestRatesQuery,
  useConfirmGuestUserMutation,
  useConvertPropertyMutation,
  useCreatePropertyDossierMutation,
  useCreatePropertyMutation,
  useCreateTemporaryPropertyMutation,
  useUpdatePropertyAlertsMutation,
  useUpdatePropertyPortfolioMutation,
  useUpdatePropertyMutation,
  useRemovePropertyMutation,
  useResendGuestUserCodeMutation,
  useUpdatePropertyFavoriteSearchProfilesListMutation,
  useVerifyGuestUserMutation,
  useGetPropertyFavoriteSearchProfilesListQuery,
  useLazyGetPropertyFavoriteSearchProfilesListQuery,
  usePropertyValuationQuery,
  useLazyPropertyValuationQuery,
  useCreatePurchaseIntentMutation,
  useConvertSearchProfileMutation,
  useCreateSearchProfileMutation,
  useCreateTemporarySearchProfileMutation,
  useRemoveSearchProfileMutation,
  useUpdateSearchProfileMutation,
  useUpdateTemporarySearchProfileMutation,
  useSuggestedPropertiesQuery,
  useLazySuggestedPropertiesQuery,
  useAcceptInvitationMutation,
  useCheckInvitationHealthQuery,
  useLazyCheckInvitationHealthQuery,
  useDeleteInvitationMutation,
  useEditInvitationMutation,
  useExportPdfMutation,
  useNotifyBrokerForBuyerFinderMutation,
  useSendFeedbackOfOwnerPortalMutation,
  useSharePropertyMutation,
  useFindRelatedSearchProfilesQuery,
  useLazyFindRelatedSearchProfilesQuery,
  useGeneralMarketReportByCountryQuery,
  useLazyGeneralMarketReportByCountryQuery,
  useGetTemporaryPropertyQuery,
  useLazyGetTemporaryPropertyQuery,
  useInvitesQuery,
  useLazyInvitesQuery,
  useIsochronesQuery,
  useLazyIsochronesQuery,
  useMarketReportQuery,
  useLazyMarketReportQuery,
  useNavigationPropertiesQuery,
  useLazyNavigationPropertiesQuery,
  useOurDestinationsQuery,
  useLazyOurDestinationsQuery,
  usePaginatedPropertyPortfolioQuery,
  useLazyPaginatedPropertyPortfolioQuery,
  usePropertiesQuery,
  useLazyPropertiesQuery,
  usePropertyDefaultFiltersQuery,
  useLazyPropertyDefaultFiltersQuery,
  usePropertyPortfolioQuery,
  useLazyPropertyPortfolioQuery,
  usePropertyQuery,
  useLazyPropertyQuery,
  useReadBuyerFinderSettingsQuery,
  useLazyReadBuyerFinderSettingsQuery,
  useShopContactsQuery,
  useLazyShopContactsQuery,
  useSimilarPropertiesQuery,
  useLazySimilarPropertiesQuery,
  useTemporaryPropertyQuery,
  useLazyTemporaryPropertyQuery,
  useReadUserAppointmentsQuery,
  useLazyReadUserAppointmentsQuery,
  useGeneratePdfExposeLinkMutation,
  useExposeRequestsQuery,
  useLazyExposeRequestsQuery,
  useDislikePropertyMutation,
  useLikePropertyMutation,
  useMarkPropertyUnsuitableMutation,
  useSendExposeContactFormMutation,
  useUpdatePriceOffersMutation,
  useSearchProfileQuery,
  useLazySearchProfileQuery,
  useSearchProfilesQuery,
  useLazySearchProfilesQuery,
  useTemporarySearchProfileQuery,
  useLazyTemporarySearchProfileQuery,
  useCreateDownloadedFileActivityMutation,
  useExposeDetailsQuery,
  useLazyExposeDetailsQuery,
  useGetExposeDocumentsQuery,
  useLazyGetExposeDocumentsQuery,
  useReadUserContractAppointmentsQuery,
  useLazyReadUserContractAppointmentsQuery,
  useAcceptPurchaseOfferMutation,
  useGetSellerExposePdfLinkMutation,
  useInitiateRealSellMutation,
  useInviteSellerMutation,
  useSendPropertyServicesMutation,
  useSendSellerRegisteredNotificationMutation,
  useSetDisabledShopServicesMutation,
  useSetPropertyExposeStatusMutation,
  useSetPropertyOwnerIdMutation,
  useSetPropertyPortalStatusMutation,
  useUploadPropertyFilesMutation,
  useAppointmentActivitiesQuery,
  useLazyAppointmentActivitiesQuery,
  useCheckBrokerSettingsPermissionQuery,
  useLazyCheckBrokerSettingsPermissionQuery,
  useDashboardStatusQuery,
  useLazyDashboardStatusQuery,
  useDigitalServiceStatusQuery,
  useLazyDigitalServiceStatusQuery,
  useDisabledShopServicesQuery,
  useLazyDisabledShopServicesQuery,
  useDocumentsActivitiesQuery,
  useLazyDocumentsActivitiesQuery,
  useDownloadedExposeActivitiesQuery,
  useLazyDownloadedExposeActivitiesQuery,
  useExposeActivitiesQuery,
  useLazyExposeActivitiesQuery,
  useGetGeneralStatisticsQuery,
  useLazyGetGeneralStatisticsQuery,
  useGetMarketingStatisticsQuery,
  useLazyGetMarketingStatisticsQuery,
  useGetPropertyExposeStatusQuery,
  useLazyGetPropertyExposeStatusQuery,
  useGetPropertyFilesQuery,
  useLazyGetPropertyFilesQuery,
  useGetPropertyServicesQuery,
  useLazyGetPropertyServicesQuery,
  useGetShopContactsQuery,
  useLazyGetShopContactsQuery,
  useHandOverActivitiesQuery,
  useLazyHandOverActivitiesQuery,
  useLatestActivitiesQuery,
  useLazyLatestActivitiesQuery,
  useMarketingActivitiesQuery,
  useLazyMarketingActivitiesQuery,
  useMarketingStatusQuery,
  useLazyMarketingStatusQuery,
  useNotaryActivitiesQuery,
  useLazyNotaryActivitiesQuery,
  useReadPropertiesQuery,
  useLazyReadPropertiesQuery,
  usePhoneCallsActivitiesQuery,
  useLazyPhoneCallsActivitiesQuery,
  usePriceOfferActivitiesQuery,
  useLazyPriceOfferActivitiesQuery,
  useGetPropertyBrokerQuery,
  useLazyGetPropertyBrokerQuery,
  usePropertyOnOfficeViewQuery,
  useLazyPropertyOnOfficeViewQuery,
  useRequestedExposeActivitiesQuery,
  useLazyRequestedExposeActivitiesQuery,
  useSellingProgressQuery,
  useLazySellingProgressQuery,
  useSentExposeActivitiesQuery,
  useLazySentExposeActivitiesQuery,
  useShopEmailQuery,
  useLazyShopEmailQuery,
  useVirtualTourActivitiesQuery,
  useLazyVirtualTourActivitiesQuery,
} = injectedRtkApi;
