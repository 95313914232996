import { FC, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ReactComponent as PencilIcon } from 'assets/streamline-light/interface-essential/edit/pencil.svg';
import {
  Container,
  ErrorText,
  Header,
  LoaderContainer,
  Title,
} from './financing-calculation-form-container-styles';
import Icon from '../../../../../../common/components/ui/icon';
import {
  FinancingProposition,
  FinancingVariantData,
  PersonalFinancingEntity,
} from '../../../../../../../generated';
import { useSubmitGetVariantPropositions } from '../../../../../../forms/form-financing/hooks/submit-hooks/useSubmitGetVariantPropositions';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import { useSubmitFinancingVariantForm } from '../../../../../../forms/form-financing/hooks/submit-hooks/useSubmitFinancingVariantForm';
import { useGetFinancing } from '../../../../../hooks/useGetFinancing';
import { FinancingEditForms } from '../../../../../../forms/form-financing/components/forms/financing-edit-forms/financing-edit-forms';
import { GlobalLoader } from '../../../../../../common/components/ui/loaders/global-loader';
import { useIsMobileSize } from '../../../../../../common/hooks/useIsMobileSize';
import { toggleMobileEditWidget } from '../../../../../../forms/form-financing/redux/financingSlice';

interface IProps {
  // isCertificate: boolean;
  isMortgageCertificate?: boolean;
  financingVariantData?: FinancingVariantData;
  personalFin?: PersonalFinancingEntity;
  financingProposition?: FinancingProposition;
}

const FinancingCalculationFormContainer: FC<IProps> = ({
  isMortgageCertificate,
  financingVariantData,
  personalFin,
  financingProposition,
}) => {
  const themeContext = useTheme();
  const isMobileSize = useIsMobileSize();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { financingId } = useParams<{ financingId: string }>();
  const isLoading = useAppSelector(
    (state) => state.financing.editWidget.isLoadingFinancingCalculation
  );

  const { financingVariants, financingVariant, isFetching } = useGetFinancing({
    financingId,
  });

  const { onSubmitGetVariantPropositionsForm, errorMessage } =
    useSubmitGetVariantPropositions({ financingVariant });
  const { onSubmitFinancingVariantForm } = useSubmitFinancingVariantForm({
    financingId,
    financingVariantsProp: financingVariants || [],
  });

  useEffect(() => {
    return () => {
      // financing mobile edit widget logic
      dispatch(toggleMobileEditWidget(false));
      const editWidgetContainer = document.querySelector(
        '#modal-root'
      ) as HTMLButtonElement;
      editWidgetContainer.style.display = '';
      document.body.style.overflow = 'overlay';
    };
  }, []);

  const proposition = financingProposition?.propositions?.[0];
  const transferTax = financingProposition?.extraCosts?.realEstateTransferTax;

  return (
    <Container>
      {!isMobileSize && (
        <Header>
          <Icon icon={PencilIcon} color={themeContext.blue} />
          <Title>{t('financing-portal-page.tabs.calculator.edit.title')}</Title>
        </Header>
      )}
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      {financingVariantData && personalFin && !isFetching ? (
        <FinancingEditForms
          isMortgageCertificate={isMortgageCertificate}
          finVarData={financingVariantData}
          personalFin={personalFin}
          proposition={proposition}
          transferTax={transferTax}
          isLoading={isLoading}
          onFindPropositions={onSubmitGetVariantPropositionsForm}
          onSubmitFinVarForm={onSubmitFinancingVariantForm}
        />
      ) : (
        <LoaderContainer>
          <GlobalLoader isAbsolute />
        </LoaderContainer>
      )}
    </Container>
  );
};

export { FinancingCalculationFormContainer };
