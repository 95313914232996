export const tabs = [
  'overview',
  'local_supply',
  'social_economics',
  'reachability',
  'documents',
];

export const energyEfficiency = {
  colorMarkersOld: [
    { label: 'A', width: 12 },
    { label: 'A', width: 10 },
    { label: 'B', width: 11 },
    { label: 'C', width: 11 },
    { label: 'D', width: 12 },
    { label: 'E', width: 12 },
    { label: 'F', width: 9 },
    { label: 'G', width: 12 },
    { label: 'H', width: 11, isLast: true },
  ],
  colorMarkers: [
    {
      label: 'A+',
      min: 0,
      max: 30,
      range: 30,
      color: '#02b14f',
    },
    {
      label: 'A',
      min: 30,
      max: 50,
      range: 20,
      color: '#a0e11d',
    },
    {
      label: 'B',
      min: 50,
      max: 75,
      range: 25,
      color: '#c6ed12',
    },
    {
      label: 'C',
      min: 75,
      max: 100,
      range: 25,
      color: '#ffff00',
    },
    {
      label: 'D',
      min: 100,
      max: 130,
      range: 30,
      color: '#f9e700',
    },
    {
      label: 'E',
      min: 130,
      max: 160,
      range: 30,
      color: '#ffa900',
    },
    {
      label: 'F',
      min: 160,
      max: 200,
      range: 40,
      color: '#fe6d00',
    },
    {
      label: 'G',
      min: 200,
      max: 250,
      range: 50,
      color: '#eb5700',
    },
    {
      label: 'H',
      min: 250,
      max: 2000000,
      range: 25,
      color: '#e70a00',
    },
  ],
  textMarkers: [
    { label: '0' },
    { label: '25' },
    { label: '50' },
    { label: '75' },
    { label: '100' },
    { label: '125' },
    { label: '150' },
    { label: '175' },
    { label: '200' },
    { label: '225' },
    { label: '250' },
    { label: '>250' },
  ],
};

export const options = [
  {
    label:
      'expose-page.head-info.button.make-an-appointment.head-consultation-appointment',
    value: 'Beratungstermin',
    isHeader: true,
  },
  {
    label:
      'expose-page.head-info.button.make-an-appointment.option-in-the-branch',
    value: 'in der Filiale',
    isHeader: false,
  },
  {
    label:
      'expose-page.head-info.button.make-an-appointment.option-via-video-conference',
    value: 'über Videokonferenz',
    isHeader: false,
  },
  {
    label: 'expose-page.head-info.button.make-an-appointment.option-via-phone',
    value: 'über Telefon',
    isHeader: false,
  },
  {
    label:
      'expose-page.head-info.button.make-an-appointment.head-viewing-appointment',
    value: 'Besichtigungstermin',
    isHeader: true,
  },
  {
    label:
      'expose-page.head-info.button.make-an-appointment.option-personally-on-site',
    value: 'Persönlich vor Ort',
    isHeader: false,
  },
  {
    label:
      'expose-page.head-info.button.make-an-appointment.option-with-inspection-robot',
    value: 'Mit Besichtigungsroboter',
    isHeader: false,
  },
  {
    label:
      'expose-page.head-info.button.make-an-appointment.option-with-viewing-glasses',
    value: 'Mit Besichtungsbrille',
    isHeader: false,
  },
];
