import styled from 'styled-components';
import { device } from '../../../../style/theme';
import {
  ParagraphTitle,
  TitleSlogan,
} from '../../../common/components/ui/typography';

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 0;
  &:not(:last-of-type) {
    margin-right: 8px;
  }
  @media ${device.tablet} {
    flex: 0 0 45%;
  }
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &:not(:last-of-type) {
    margin-right: 8px;
    flex: 0 0 auto;
  }
`;

export const Value = styled(ParagraphTitle)`
  && {
    color: ${(props) => props.theme.blue};
    margin-bottom: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Label = styled(TitleSlogan)`
  && {
    font-size: 10px;
    line-height: 1.6;
    opacity: 0.6;
    color: ${(props) => props.theme.blue};
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
