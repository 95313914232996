import { FC } from 'react';
import ShadowmapMobile from '../../../../../map/shadowmap/shadowmap-mobile';
import {
  useExposeDetailsHeadInfo,
  useExposeDetailsPriceHubble,
} from '../../hooks/useExposeDetailsQueries';

const ExposeShadowmapMobile: FC = () => {
  const { location } = useExposeDetailsPriceHubble();

  const {
    headInfo: { fullAddress },
  } = useExposeDetailsHeadInfo();

  return (
    <ShadowmapMobile
      coordinates={{
        latitude: location?.coordinates?.latitude || 0,
        longitude: location?.coordinates?.longitude || 0,
      }}
      address={fullAddress}
      urlType={'exposeoverlay'}
    />
  );
};

export default ExposeShadowmapMobile;
