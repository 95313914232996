import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IsochronesMode } from '../../../generated';

export type ReachabilityLegendMode = 'commuteTime' | 'localTransitLines';

export interface ICommuteTimeCoordinates {
  minutes15: number[][];
  minutes30: number[][];
  minutes45: number[][];
}

interface InitialState {
  isTabListNearTop: boolean;
  showMap: boolean;
  selectedTab: number;
  showLegend: boolean;
  showReachabilityLegend: boolean;
  reachabilityLegendMode: ReachabilityLegendMode;
  commuteTimeCoordinates?: ICommuteTimeCoordinates;
  propertyIndexForScrolling?: null | number;
  transportMode: IsochronesMode;
  isReachabilityTab: boolean;
  isFullScreenMapOpen: boolean;
}

const initialState: InitialState = {
  isTabListNearTop: false,
  showMap: false,
  showLegend: false,
  isFullScreenMapOpen: false,
  selectedTab: 0,
  showReachabilityLegend: false,
  reachabilityLegendMode: 'commuteTime',
  commuteTimeCoordinates: undefined,
  transportMode: IsochronesMode.Walk,
  isReachabilityTab: false,
};

export const dynamicMapUtilsSlice = createSlice({
  name: 'dynamicMapUtils',
  initialState,
  reducers: {
    setTabListNearTop: (state, action: PayloadAction<boolean>) => {
      state.isTabListNearTop = action.payload;
    },
    setShowMap: (state, action: PayloadAction<boolean>) => {
      state.showMap = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<number>) => {
      state.selectedTab = action.payload;
    },
    toggleLegend: (state, action: PayloadAction<boolean>) => {
      state.showLegend = action.payload;
    },
    toggleReachabilityLegend: (state, action: PayloadAction<boolean>) => {
      state.showReachabilityLegend = action.payload;
    },
    toggleFullScreenMap: (state, action: PayloadAction<boolean>) => {
      state.isFullScreenMapOpen = action.payload;
    },
    setReachabilityLegendMode: (
      state,
      action: PayloadAction<ReachabilityLegendMode>
    ) => {
      state.reachabilityLegendMode = action.payload;
    },
    setCommuteTimeCoordinates: (
      state,
      action: PayloadAction<ICommuteTimeCoordinates | undefined>
    ) => {
      state.commuteTimeCoordinates = action.payload;
    },
    resetMapState: () => {
      return initialState;
    },
    scrollToProperty: (state, action: PayloadAction<number | null>) => {
      state.propertyIndexForScrolling = action.payload;
    },
    setTransportMode: (state, action: PayloadAction<IsochronesMode>) => {
      state.transportMode = action.payload;
    },
    setIsReachabilityTab: (state, action: PayloadAction<boolean>) => {
      state.isReachabilityTab = action.payload;
    },
  },
});

export const {
  setTabListNearTop,
  setShowMap,
  setSelectedTab,
  toggleLegend,
  resetMapState,
  scrollToProperty,
  toggleReachabilityLegend,
  setReachabilityLegendMode,
  setCommuteTimeCoordinates,
  setTransportMode,
  setIsReachabilityTab,
  toggleFullScreenMap,
} = dynamicMapUtilsSlice.actions;

export default dynamicMapUtilsSlice.reducer;
