import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  PropertyPurchaseIntent,
  useExposeDetailsQuery,
  useReadPropertyPurchaseIntentsQuery,
  usePropertyQuery,
  usePropertyOnOfficeViewQuery,
} from '../../../../generated';

import FormPurchaseIntend from '../../../forms/form-purchase-intend/components/containers/form-purchase-intend';
import { FullScreenMap } from './components/mapbox/full-screen-map';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { Info } from './components/info';
import { MainContainer } from './components/containers/main-container';
import { MapboxContainer } from './components/mapbox/mapbox-container';
import { Modal } from '../../../common/components/modal';
import { RootState } from '../../../../store';
import { useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';

const LoaderContainer = styled.div`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExposeDetailsOverlay: FC = () => {
  const isMobileSize = useIsMobileSize();
  const { propertyId } = useParams<{ propertyId: string }>();
  const isExposeOverlayOpen = useAppSelector(
    (state) => state.sellerExposeDetails.isExposeOverlayOpen
  );

  const { isOpen } = useSelector((state: RootState) => state.purchaseIntend);

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const {
    data: propertyPurchaseIntentsData,
    isLoading: propertyPurchaseIntentsLoading,
    refetch: refetchPropertyPurchaseIntents,
  } = useReadPropertyPurchaseIntentsQuery({
    propertyId,
  });

  const {
    data,
    isLoading: propertyLoading,
    error: propertyError,
    refetch: refetchProperty,
  } = usePropertyQuery(
    {
      id: propertyId,
    },
    {
      ...(isExposeOverlayOpen ? { pollingInterval: 2000 } : {}),
      skip: isBrokerView,
    }
  );

  const { isLoading: isBrokerPropertyDataLoading, data: brokerPropertyData } =
    usePropertyOnOfficeViewQuery(
      {
        id: propertyId,
      },
      {
        ...(isExposeOverlayOpen ? { pollingInterval: 2000 } : {}),
        skip: !isBrokerView,
      }
    );

  const currentPropertyData = useMemo(() => {
    return isBrokerView
      ? brokerPropertyData?.propertyOnOfficeView
      : data?.property;
  }, [brokerPropertyData, isBrokerView, data]);

  if (propertyLoading || isBrokerPropertyDataLoading) {
    return <GlobalLoader />;
  }

  return (
    <Modal
      isOpen={isExposeOverlayOpen}
      wrapperStyles={{
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        padding: 0,
      }}
    >
      <MainContainer>
        {propertyLoading || propertyError || propertyPurchaseIntentsLoading ? (
          <LoaderContainer>
            <GlobalLoader />
          </LoaderContainer>
        ) : (
          <>
            {currentPropertyData && (
              <Info
                property={currentPropertyData}
                refetch={refetchPropertyPurchaseIntents}
                purchaseIntents={
                  (propertyPurchaseIntentsData?.readPropertyPurchaseIntents ||
                    []) as PropertyPurchaseIntent[]
                }
              />
            )}
            {isOpen && (
              <FormPurchaseIntend
                refetch={refetchPropertyPurchaseIntents}
                isOpen={isOpen}
              />
            )}
            {!isMobileSize && currentPropertyData?.propertyData?.location && (
              <MapboxContainer
                location={currentPropertyData.propertyData.location}
              />
            )}
            {currentPropertyData?.propertyData?.location && (
              <FullScreenMap
                location={currentPropertyData.propertyData.location}
              />
            )}
          </>
        )}
      </MainContainer>
    </Modal>
  );
};

export { ExposeDetailsOverlay };
