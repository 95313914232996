import { ReactComponent as ArrowButtonLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as CommonFileText } from 'assets/streamline-light/files-folders/common-files/common-file-text.svg';
import { ReactComponent as Lock2 } from 'assets/streamline-light/interface-essential/lock-unlock/lock-2.svg';
import { ReactComponent as AddCircle } from 'assets/streamline-light/interface-essential/remove-add/add-circle.svg';
import { easeCubic } from 'd3-ease';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlyToInterpolator } from 'react-map-gl';
import { batch } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { ThemeContext } from 'styled-components';

import AktuelleMarktnachfrageBlurImg from '../../../../assets/aktuelle-marktnachfrage-blue.png';
import AktuelleMarktnachfrageMobileBlurImg from '../../../../assets/aktuelle-marktnachfrage-blur-mobile.png';
import AktuellerMarktberichtBlurImg from '../../../../assets/aktueller-marktbericht-blue-web.png';
import AktuellerMarktberichtMobileBlurImg from '../../../../assets/aktueller-marktbericht-blur-mobile.png';
import ImmobilienwertrechnerBlurImg from '../../../../assets/immobilienwertrechner-blue.png';
import ImmobilienwertrechnerMobileBlurImg from '../../../../assets/immobilienwertrechner-blur-mobile.png';
import MarktpreisBlurImg from '../../../../assets/marketpreis-blur.png';
import MarktpreisMobileBlurImg from '../../../../assets/marketprice-blur-mobile.png';
import VermarktungsdauerBlurImg from '../../../../assets/vermarktungsdauer-blue.png';
import VermarktungsdauerMobileBlurImg from '../../../../assets/vermarktungsdauer-blur-mobile.png';
import WertentwicklungMonileBlurImg from '../../../../assets/wertentwicklung-blur-mobile.png';
import WertentwicklungBlurImg from '../../../../assets/wertentwicklung-blur.png';
import {
  useCreatePropertyDossierMutation,
  ValuationType,
} from '../../../../generated';
import { useConvertPropertyMutation } from '../../../../services/graphql/enhanced';
import { toggleIsRegisterModalOpen } from '../../../auth/redux/authSlice';
import { PrimaryCTAButton } from '../../../common/components/ui/buttons';
import { Card } from '../../../common/components/ui/card';
import { Hero } from '../../../common/components/ui/hero';
import { AddressBar } from '../../../common/components/ui/hero-slider/address-bar';
import { HeroSlider } from '../../../common/components/ui/hero-slider/hero-slider';
import Icon from '../../../common/components/ui/icon';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { calculateDifferencePercentage } from '../../../common/utils/calculateDifferencePercentage';
import { getQuarterAndYear } from '../../../common/utils/getQuarterAndYear';
import { Mapbox } from '../../../map/components/mapbox';
import { Germany } from '../../../map/constants/points-of-interest';
import {
  changeViewport,
  restoreCheckpoints,
  setMarkers,
  setSettings,
} from '../../../map/redux/mapSlice';
import { OnlineValuationContainer } from '../../../forms/form-property-valuation-wizard/components/containers/online-valuation/online-valuation-container';
import { FormError } from '../../../forms/interface';
import {
  closeValuationWizardAction,
  openValuationWizardAction,
  resetValuationWizard,
  resetValuationWizardWithoutValuationsAndAddress,
  setErrors,
  setValuationTypeAction,
} from '../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { AppointmentCard } from '../../components/card/appointment-card';
import { PropertyValuationOverviewCard } from '../../components/card/property-valuation-overview-card';
import { Drawer } from '../../components/drawer/drawer';
import { HeaderTab } from '../../components/header/valuation-header-tab';
import { VariationHeaderActions } from '../../components/header/variation-header-actions';
import { BigBlurredCard } from '../../components/locked/big-blurred-card';
import { SmallBlurredCard } from '../../components/locked/small-blurred-card';
import { UnlockBlurredContentCard } from '../../components/locked/unlock-blurred-content-card';
import { formatNumber } from '../../utils/format-number';
import { LngRedirect } from '../../../localization/lng-redirect';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import { siteMap } from '../../../../routes/site-map';
import {
  IconWrapper,
  ContentWithPadding,
  HeaderWrapper,
  MobileMapContainer,
  MobileHeadContainer,
  CTAContainer,
  Container,
  UnlockCardContainer,
  SecondColumn,
  FirstColumn,
} from './property-locked-page-styles';
import { defaultMapSettings } from '../../../map/constants';

const PropertyLockedPageBase: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const themeContext = useContext(ThemeContext);
  const isMobileSize = useIsMobileSize();
  const lngHPush = useLngHistoryPush();

  const [isLoading, setLoading] = useState(false);

  const temporaryValuations = useAppSelector(
    (state) => state.valuationWizard.temporaryValuations
  );
  const temporaryUserInput = useAppSelector(
    (state) => state.valuationWizard.temporaryUserInput
  );
  const temporarySelectedAddress = useAppSelector(
    (state) => state.valuationWizard.temporarySelectedAddress
  );
  const isValuationWizardOpen = useAppSelector(
    (state) => state.valuationWizard.isValuationWizardOpen
  );
  const valuationType = useAppSelector(
    (state) => state.valuationWizard.valuationType
  );
  const activeStep = useAppSelector(
    (state) => state.valuationWizard.activeStep
  );

  const userId = useAppSelector((state) => state.auth.user?._id);

  const [convertProperty] = useConvertPropertyMutation();
  const [createPropertyDossier] = useCreatePropertyDossierMutation();

  const toggleRegisterModal = () => dispatch(toggleIsRegisterModalOpen(true));

  const openEvaluationWizard = () =>
    batch(() => {
      dispatch(
        changeViewport({
          ...Germany,
          transitionDuration: 'auto',
          transitionInterpolator: new FlyToInterpolator({
            speed: 2,
            curve: 1,
          }),
          transitionEasing: easeCubic,
        })
      );
      dispatch(setMarkers([]));
      dispatch(setSettings(defaultMapSettings));
      dispatch(openValuationWizardAction());
      dispatch(setValuationTypeAction(ValuationType.Online));
    });

  const closeEvaluationWizard = () => {
    batch(() => {
      dispatch(closeValuationWizardAction());
      dispatch(resetValuationWizardWithoutValuationsAndAddress());
      dispatch(restoreCheckpoints());
    });
  };

  useEffect(() => {
    const convertPropertyAsync = async () => {
      try {
        setLoading(true);

        const propertyResponse = await convertProperty({
          ownerId: userId as string,
        }).unwrap();

        if (propertyResponse?.convertProperty?._id) {
          const createPropertyDossierResponse = await createPropertyDossier({
            input: {
              propertyId: propertyResponse?.convertProperty?._id,
            },
          }).unwrap();

          if (createPropertyDossierResponse.createPropertyDossier?._id) {
            dispatch(resetValuationWizard());
            lngHPush(
              `${siteMap.PropertyPage.pathWithoutParams}/${createPropertyDossierResponse.createPropertyDossier._id}`
            );
          }
        }
      } catch (error) {
        dispatch(setErrors(error as FormError));
      } finally {
        setLoading(false);
      }
    };
    if (userId) {
      convertPropertyAsync();
    }
  }, [dispatch, createPropertyDossier, userId, convertProperty, lngHPush]);

  useEffect(() => {
    dispatch(closeValuationWizardAction());
  }, [dispatch]);

  if (!temporaryValuations?.length) {
    return <LngRedirect to="/" />;
  }

  const [prevQuarterValuation, currentQuarterValuation] = temporaryValuations;

  const { year, quarter } = getQuarterAndYear(prevQuarterValuation.date);

  const quarterDifferencePercent =
    currentQuarterValuation?.valuation?.salePrice &&
    prevQuarterValuation?.valuation?.salePrice
      ? Number(
          calculateDifferencePercentage(
            currentQuarterValuation.valuation.salePrice,
            prevQuarterValuation.valuation.salePrice
          ).toFixed(2)
        )
      : 0;

  if (isLoading) {
    return <GlobalLoader />;
  }

  return (
    <>
      {!isMobileSize && (
        <Hero>
          <Mapbox isVisible />
          <HeroSlider
            showAddressBar={!(isValuationWizardOpen && !activeStep)}
            topOffset={-56}
            address={temporarySelectedAddress?.place_name}
          >
            <PropertyValuationOverviewCard
              header="property-valuation-overview-card.header"
              title="property-valuation-overview-card.title"
              content="property-valuation-overview-card.content"
              {...temporaryUserInput}
            />
            <Card
              header={t('property-page.hero-slider.first-card.header')}
              titleFirstLine={
                valuationType === ValuationType.Online
                  ? t(
                      'property-page.hero-slider.first-card.online.title.first-line'
                    )
                  : t(
                      'property-page.hero-slider.first-card.personal.title.first-line'
                    )
              }
              titleSecondLine={t(
                'property-page.hero-slider.first-card.title.second-line'
              )}
              content={
                valuationType === ValuationType.Online
                  ? t('property-page.hero-slider.first-card.online.content')
                  : t('property-page.hero-slider.first-card.personal.content')
              }
              showAddressBar={!(isValuationWizardOpen && !activeStep)}
              cta={(c) => (
                <IconWrapper onClick={closeEvaluationWizard}>
                  <Icon icon={ArrowButtonLeft} width={12} height={12} /> {c}
                </IconWrapper>
              )}
            />
            <Card
              titleType="h2"
              header={t('property-page.hero-slider.second-card.header')}
              titleFirstLine={
                valuationType === ValuationType.Online
                  ? t(
                      'property-page.hero-slider.second-card.online.title.first-line'
                    )
                  : t(
                      'property-page.hero-slider.second-card.personal.title.first-line'
                    )
              }
              titleSecondLine={t(
                'property-page.hero-slider.second-card.title.second-line'
              )}
              content={
                valuationType === ValuationType.Online
                  ? t('property-page.hero-slider.second-card.online.content')
                  : t('property-page.hero-slider.second-card.personal.content')
              }
              showAddressBar={!(isValuationWizardOpen && !activeStep)}
              cta={(c) => (
                <IconWrapper onClick={closeEvaluationWizard}>
                  <Icon icon={ArrowButtonLeft} width={12} height={12} /> {c}
                </IconWrapper>
              )}
            />
          </HeroSlider>
          {isValuationWizardOpen ? (
            <OnlineValuationContainer />
          ) : (
            <CTAContainer>
              <PrimaryCTAButton
                id="unlock-cta-button"
                isFirst
                skipBorder
                icon={Lock2}
                title={t('property-locked-page.primary-button.unlock.label')}
                description={t(
                  'property-locked-page.primary-button.unlock.description'
                )}
                onClick={toggleRegisterModal}
              />
              <PrimaryCTAButton
                isLast
                skipBorder
                icon={AddCircle}
                title={t(
                  'property-locked-page.primary-button.new-valuation.label'
                )}
                description={t(
                  'property-locked-page.primary-button.new-valuation.description'
                )}
                backgroundColor={themeContext.blue}
                onClick={openEvaluationWizard}
              />
            </CTAContainer>
          )}
        </Hero>
      )}
      {isMobileSize && (
        <MobileHeadContainer>
          <PropertyValuationOverviewCard
            header="property-valuation-overview-card.header"
            title="property-valuation-overview-card.title"
            content="property-valuation-overview-card.content"
            {...temporaryUserInput}
          />
          <AddressBar
            show={!(isValuationWizardOpen && !activeStep)}
            address={temporarySelectedAddress?.place_name}
          />
          <MobileMapContainer>
            <>
              <Mapbox isVisible />
            </>
          </MobileMapContainer>
          {isValuationWizardOpen && valuationType === ValuationType.Online && (
            <OnlineValuationContainer />
          )}
          <Drawer
            drawerTopContent={
              <>
                <PrimaryCTAButton
                  isFirst
                  skipBorder
                  icon={Lock2}
                  title={t('property-locked-page.primary-button.unlock.label')}
                  description={t(
                    'property-locked-page.primary-button.unlock.description'
                  )}
                  onClick={toggleRegisterModal}
                />
                <PrimaryCTAButton
                  isLast
                  skipBorder
                  skipBorderRadius
                  icon={AddCircle}
                  title={t(
                    'property-locked-page.primary-button.new-valuation.label'
                  )}
                  description={t(
                    'property-locked-page.primary-button.new-valuation.description'
                  )}
                  backgroundColor={themeContext.blue}
                  onClick={openEvaluationWizard}
                />
              </>
            }
            drawerBottomContent={<VariationHeaderActions isLockedPage />}
          />
        </MobileHeadContainer>
      )}
      <ContentWithPadding>
        <Tabs>
          <HeaderWrapper>
            <TabList style={{ height: '100%', position: 'relative' }}>
              <Tab tabIndex="0">
                <HeaderTab
                  title={t('property-navigation.first-tab')}
                  icon={CommonFileText}
                />
              </Tab>
              <Tab tabIndex="0" disabled>
                <HeaderTab
                  title={t('property-navigation.second-tab')}
                  icon={Lock2}
                />
              </Tab>
              <Tab tabIndex="0" disabled>
                <HeaderTab
                  title={t('property-navigation.third-tab')}
                  icon={Lock2}
                />
              </Tab>
              <Tab tabIndex="0" disabled>
                <HeaderTab
                  title={t('property-navigation.fourth-tab')}
                  icon={Lock2}
                />
              </Tab>
            </TabList>
            {!isMobileSize && <VariationHeaderActions isLockedPage />}
          </HeaderWrapper>
          <TabPanel>
            <Container>
              <FirstColumn>
                <BigBlurredCard
                  title={t('property-locked-page.market-price.title')}
                  content={t('property-locked-page.market-price.content', {
                    lowerBound: formatNumber(
                      currentQuarterValuation?.valuation?.salePriceRange?.lower
                    ),
                    upperBound: formatNumber(
                      currentQuarterValuation?.valuation?.salePriceRange?.upper
                    ),
                  })}
                  priceLabel={t(
                    'property-locked-page.market-price.price-label'
                  )}
                  priceRangeLow={
                    currentQuarterValuation?.valuation?.salePriceRange?.lower
                  }
                  blurredImageSrc={
                    isMobileSize ? MarktpreisMobileBlurImg : MarktpreisBlurImg
                  }
                  priceRangeHigh={
                    currentQuarterValuation?.valuation?.salePriceRange?.upper
                  }
                  priceChangePercentage={`${quarterDifferencePercent}%`}
                  date={`VS ${quarter}\`${year.toString().slice(-2)}`}
                />
                <BigBlurredCard
                  title={t('property-locked-page.price-development.title')}
                  content={t('property-locked-page.price-development.content')}
                  priceLabel={t(
                    'property-locked-page.price-development.price-label'
                  )}
                  blurredImageSrc={
                    isMobileSize
                      ? WertentwicklungMonileBlurImg
                      : WertentwicklungBlurImg
                  }
                />
                <BigBlurredCard
                  title={t('property-locked-page.price-calculator.title')}
                  content={t('property-locked-page.price-calculator.content')}
                  priceLabel={t(
                    'property-locked-page.price-calculator.price-label'
                  )}
                  blurredImageSrc={
                    isMobileSize
                      ? ImmobilienwertrechnerMobileBlurImg
                      : ImmobilienwertrechnerBlurImg
                  }
                />
                <BigBlurredCard
                  blurredImageSrc={
                    isMobileSize
                      ? AktuellerMarktberichtMobileBlurImg
                      : AktuellerMarktberichtBlurImg
                  }
                />
              </FirstColumn>
              <SecondColumn>
                <SmallBlurredCard
                  title={t('property-locked-page.actual-market-report.title')}
                  blurredImageSrc={
                    isMobileSize
                      ? AktuelleMarktnachfrageMobileBlurImg
                      : AktuelleMarktnachfrageBlurImg
                  }
                />
                <SmallBlurredCard
                  title={t('property-locked-page.days-on-the-market.title')}
                  blurredImageSrc={
                    isMobileSize
                      ? VermarktungsdauerMobileBlurImg
                      : VermarktungsdauerBlurImg
                  }
                />
                <AppointmentCard pageName="Property Page" />
                {/* <ConsultationCard /> */}
              </SecondColumn>
              <UnlockCardContainer>
                <UnlockBlurredContentCard />
              </UnlockCardContainer>
            </Container>
          </TabPanel>
          <TabPanel />
          <TabPanel />
          <TabPanel />
        </Tabs>
      </ContentWithPadding>
    </>
  );
};

const PropertyLockedPage = memo(PropertyLockedPageBase);

export { PropertyLockedPage };
