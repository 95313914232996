import { ReactComponent as ArrowButtonDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-down-1.svg';
import { ReactComponent as ArrowButtonTop } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-up-1.svg';
import { memo, useState } from 'react';

import Icon from '../../../common/components/ui/icon';
import {
  DrawerContainer,
  DrawerContentBottom,
  DrawerContentTop,
  DrawerContentWrapper,
  DrawerHandle,
  DrawerContent,
} from './drawer-styles';

interface IProps {
  drawerTopContent?: JSX.Element | JSX.Element[];
  drawerBottomContent?: JSX.Element | JSX.Element[];
  drawerBottomButtonFlex?: string;
  drawerBottomContainerPadding?: string;
}

enum DrawerHeight {
  min = 0,
  max = 271,
}

const DrawerBase = ({
  drawerTopContent,
  drawerBottomContent,
  drawerBottomButtonFlex,
  drawerBottomContainerPadding,
}: IProps) => {
  const [drawerHeight, setDrawerHeight] = useState(DrawerHeight.max);
  const [isDrawerOpened, setIsDrawerOpened] = useState(true);

  const handleDrawer = () => {
    if (isDrawerOpened) {
      setDrawerHeight(DrawerHeight.min);
      setIsDrawerOpened(false);
    } else {
      setDrawerHeight(DrawerHeight.max);
      setIsDrawerOpened(true);
    }
  };

  return (
    <DrawerContainer isDrawerOpened={isDrawerOpened}>
      <DrawerHandle onClick={handleDrawer}>
        <Icon
          icon={isDrawerOpened ? ArrowButtonDown : ArrowButtonTop}
          width={12}
          height={12}
        />
      </DrawerHandle>
      <DrawerContentWrapper
        isDrawerOpened={isDrawerOpened}
        drawerHeight={drawerHeight}
      >
        <DrawerContent>
          <DrawerContentTop>{drawerTopContent || <></>}</DrawerContentTop>
          {drawerBottomContent && (
            <DrawerContentBottom
              buttonFlex={drawerBottomButtonFlex}
              containerPadding={drawerBottomContainerPadding}
            >
              {drawerBottomContent}
            </DrawerContentBottom>
          )}
        </DrawerContent>
      </DrawerContentWrapper>
    </DrawerContainer>
  );
};

const Drawer = memo(DrawerBase);

export { Drawer };
