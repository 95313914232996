import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { RegisterOptions, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusSvg } from 'assets/streamline-light/interface-essential/remove-add/add.svg';
import { ReactComponent as MinusSvg } from 'assets/streamline-light/interface-essential/remove-add/subtract.svg';
import { ReactComponent as InformationCircle } from 'assets/streamline-light/interface-essential/alerts/information-circle.svg';
import { ThemeContext } from 'styled-components';

import {
  Button,
  Container,
  ErrorContainer,
  ErrorMessage,
  Info,
  InfoWrapper,
  InputAdornment,
  InputField,
  InputWrapper,
  Label,
  LabelContainer,
  Spacer,
  SubLabel,
  TooltipContainer,
  TooltipParagraph,
  Wrapper,
} from './input-number-styles';
import { Tooltip } from '../../../../common/components/tooltip';
import Icon from '../../../../common/components/ui/icon';

interface IProps {
  name: string;
  label?: string;
  sublabel?: string;
  placeholder?: string;
  rules?: RegisterOptions;
  defaultValue?: unknown;
  type?: string;
  autoComplete?: 'on' | 'off' | 'nope' | 'false';
  onFocus?: () => void;
  inputWrapperChildren?: ReactNode;
  onOutsideClick?: () => void;
  skipIcon?: boolean;
  tooltipKey?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  inputAdornment?: string;
  dynamicAdornment?: string;
}

const InputNumber: FC<IProps> = ({
  label,
  sublabel,
  name,
  placeholder,
  rules,
  defaultValue,
  type,
  autoComplete = 'on',
  onFocus,
  onOutsideClick,
  inputWrapperChildren,
  skipIcon = false,
  tooltipKey,
  isDisabled,
  isReadOnly,
  isRequired,
  inputAdornment,
  dynamicAdornment,
}) => {
  const { t } = useTranslation();
  const {
    field: { ref, onChange, value, ...inputProps },
    formState: { errors },
  } = useController({
    name,
    rules,
    defaultValue: rules?.value ?? defaultValue,
  });
  const themeContext = useContext(ThemeContext);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        onOutsideClick &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onOutsideClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, onOutsideClick]);

  const formatInput = (e: KeyboardEvent) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-';
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(
        Number.isNaN(e.target.value) || Number.isNaN(e.target.valueAsNumber)
          ? e.target.value
          : e.target.valueAsNumber
      );
    },
    [onChange]
  );

  const onClickButton = useCallback(
    (isPlus: boolean) => (e: MouseEvent) => {
      e.preventDefault();
      if (isPlus) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const min = rules?.min?.value;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const max = rules?.max?.value;
        const vInt = Number(value) || 0;
        if (vInt + 1 < min) {
          onChange(min);
          return;
        }
        onChange(vInt + 1 <= max ? vInt + 1 : value);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const min = rules?.min?.value;
        const vInt = Number(value);
        onChange(vInt - 1 >= min ? vInt - 1 : value);
      }
    },
    [onChange, value]
  );

  const isValue =
    value !== '' &&
    value !== undefined &&
    value !== null &&
    !Number.isNaN(value);

  return (
    <Container hasError={errors[name]}>
      <Wrapper ref={wrapperRef}>
        <InfoWrapper>
          {label && (
            <LabelContainer>
              <Label>
                {t(label)}
                {isRequired && '*'}
              </Label>
              {sublabel && <SubLabel>{t(sublabel)}</SubLabel>}
            </LabelContainer>
          )}
          {!skipIcon && tooltipKey && (
            <Info>
              <Tooltip
                icon={InformationCircle}
                isOnClick
                id={tooltipKey}
                position="bottom"
                width={16}
                height={16}
              >
                <TooltipContainer>
                  <TooltipParagraph>{t(tooltipKey)}</TooltipParagraph>
                </TooltipContainer>
              </Tooltip>
            </Info>
          )}
        </InfoWrapper>
        <InputWrapper>
          <Button
            onClick={
              onClickButton(
                false
              ) as unknown as MouseEventHandler<HTMLButtonElement>
            }
            isLeft
          >
            <Icon
              icon={MinusSvg}
              width={16}
              height={16}
              color={themeContext.grey}
            />
          </Button>
          <InputField
            id={name}
            placeholder={t(placeholder ?? '')}
            ref={ref}
            type={type}
            autoComplete={autoComplete}
            onFocus={onFocus}
            // onBlur={onBlur}
            hasError={errors[name]}
            onChange={handleChange}
            inputAdornment={inputAdornment}
            {...(type === 'number' && {
              onKeyDown: formatInput,
            })}
            isValue={isValue}
            isDisabled={isDisabled}
            readOnly={isReadOnly}
            value={isValue ? value : ''}
            {...inputProps}
          />
          <Button
            onClick={
              onClickButton(
                true
              ) as unknown as MouseEventHandler<HTMLButtonElement>
            }
          >
            <Icon
              icon={PlusSvg}
              width={16}
              height={16}
              color={themeContext.grey}
            />
          </Button>
          {inputAdornment && (
            <InputAdornment>
              {inputAdornment} {dynamicAdornment}
            </InputAdornment>
          )}
        </InputWrapper>
        {inputWrapperChildren}
      </Wrapper>

      {errors[name] && (
        <ErrorContainer>
          <Spacer />
          <ErrorMessage>{t(errors[name].message)}</ErrorMessage>
        </ErrorContainer>
      )}
    </Container>
  );
};

export { InputNumber };
