import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const styles = StyleSheet.create({
  wrapper: {
    width: '32%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '5mm',
    paddingBottom: '5mm',
    paddingLeft: '8mm',
    paddingRight: '8mm',
    border: '1px',
    borderColor: '#d9d9d9',
    borderRadius: '1mm',
    marginBottom: '3mm',
  },
  numberText: {
    fontSize: '16px',
    fontWeight: 'extrabold',
    lineHeight: 1.25,
    textAlign: 'center',
    color: defaultTheme.blue,
    marginBottom: '5mm',
  },
  clarificationText: {
    fontSize: '12px',
    fontWeight: 'extrabold',
    lineHeight: 1.33,
    textAlign: 'center',
    color: defaultTheme.blue,
    opacity: 0.8,
  },
});

const OfferCard = ({
  number,
  clarification,
}: {
  number: number;
  clarification: string;
}): JSX.Element => {
  return (
    <View style={styles.wrapper}>
      <Text style={styles.numberText}>{number}</Text>
      <Text style={styles.clarificationText}>{clarification}</Text>
    </View>
  );
};

export { OfferCard };
