import { FC } from 'react';

import { Container } from './financing-info-certificate-container-styles';
import { useGetFinancingPropositionsAuth } from '../../../../hooks/callback-hooks/useGetFinancingPropositionsAuth';
import { useAppSelector } from '../../../../../../common/hooks';
import { FinancingCertificate } from '../../../../../../financing/components/common/financing-certificate/financing-certificate';

const FinancingInfoCertificateContainer: FC = () => {
  const { data, isLoading } = useGetFinancingPropositionsAuth();
  const loanAmount = data?.[Number(data?.length - 1)].maximalPurchasePrice;
  const firstNameInput = useAppSelector((state) => state.auth.userInput?.name);
  const firstNameUser = useAppSelector((state) => state.auth.user?.name);
  const lastNameInput = useAppSelector(
    (state) => state.auth.userInput?.surname
  );
  const lastNameUser = useAppSelector((state) => state.auth.user?.surname);
  const isName =
    !!firstNameInput || !!firstNameUser || !!lastNameInput || !!lastNameUser;
  const name = !isName
    ? undefined
    : firstNameInput
    ? `${firstNameInput} ${lastNameInput}`
    : `${firstNameUser} ${lastNameUser}`;

  return (
    <Container>
      <FinancingCertificate
        isLoading={isLoading}
        loanAmount={loanAmount}
        name={name}
      />
    </Container>
  );
};

export { FinancingInfoCertificateContainer };
