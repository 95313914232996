import { FC } from 'react';

import { useOnlinePhoneVerification } from '../../../../../hooks/callback-hooks/online-valuation/useOnlinePhoneVerification';
import { VerifyPhoneContainer } from '../../../../../../components/fullscreen-modal/auth-container/register-container/verify-phone-container/verify-phone-container';

interface IProps {
  setErrors: (errors: unknown) => void;
}

const OnlineVerifyPhoneContainer: FC<IProps> = ({ setErrors }) => {
  const { formattedPhone, onVerifyPhone, verificationStatus } =
    useOnlinePhoneVerification({
      reduxSliceName: 'valuationWizardV2',
      setErrors,
    });

  return (
    <VerifyPhoneContainer
      verificationStatus={verificationStatus}
      formattedPhone={formattedPhone}
      onVerifyPhone={onVerifyPhone}
    />
  );
};

export { OnlineVerifyPhoneContainer };
