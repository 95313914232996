import {
  CreatePropertyInput,
  CreatePropertyMutation,
  ValuationType,
} from '../../../../generated';
import { useCreatePropertyMutation } from '../../../../services/graphql/enhanced';
import { useAppSelector } from '../../../common/hooks';

type TCreateProperty = {
  valuationInput: CreatePropertyInput;
  userId: string;
};

type TReturn = {
  createProperty: (props: TCreateProperty) => Promise<CreatePropertyMutation>;
};

export const useCreateProperty = (): TReturn => {
  const [createPropertyMutation] = useCreatePropertyMutation();
  const marketingParameters = useAppSelector(
    (state) => state.auth.marketingParameters
  );

  const createProperty = async ({
    valuationInput,
    userId,
  }: TCreateProperty) => {
    const {
      dealType,
      countryCode,
      ratingReason,
      otherRatingReason,
      timeHorizont,
      propertyData,
    } = valuationInput;

    return createPropertyMutation({
      input: {
        dealType,
        countryCode,
        ratingReason,
        otherRatingReason,
        timeHorizont,
        includeInitialValuations: true,
        propertyData,
        owner: userId,
        valuationType: ValuationType.Online,
        marketingParameters:
          marketingParameters ??
          `${window?.location?.pathname || ''}${
            window?.location?.search || ''
          }`,
      },
    }).unwrap();
  };

  return {
    createProperty,
  };
};
