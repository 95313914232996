import { ValuationType } from '../../../../../generated';
import { MainButton } from '../../../../common/components/ui/buttons';
import {
  Container,
  Title,
  Description,
  SlideIcon,
  SlideIconBox,
} from './primary-slide-styles';
import { useLngHistoryPush } from '../../../../localization/lng-history-push';
import { useGetEvaluationWidgetVersion } from '../../../../forms/form-property-valuation-wizard-v2/hooks/useGetWidgetVersion';

interface IProps {
  title: string;
  description: string;
  buttonLabel: string;
  buttonCta: string;
  handleEvaluationWizardOpen?: (type: ValuationType) => void;
  ctaHandler?: () => void;
  isOverviewLP?: boolean;
  isMobileRedesign?: boolean;
  reduceToken?: boolean;
  icon: string;
}

const PrimarySlide = ({
  title,
  description,
  buttonLabel,
  buttonCta,
  handleEvaluationWizardOpen,
  ctaHandler,
  isOverviewLP,
  isMobileRedesign,
  icon,
  reduceToken,
}: IProps): JSX.Element => {
  const lngHPush = useLngHistoryPush();
  const { isRedesignVersion } = useGetEvaluationWidgetVersion();

  const onClick = () => {
    if (handleEvaluationWizardOpen) {
      if (!isRedesignVersion) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      handleEvaluationWizardOpen(ValuationType.Online);
    } else if (buttonCta) {
      lngHPush(buttonCta);
    } else if (ctaHandler) {
      ctaHandler();
    }
  };

  return (
    <Container isMobileRedesign={isMobileRedesign}>
      <SlideIconBox isMobileRedesign={isMobileRedesign}>
        <SlideIcon
          reduceToken={reduceToken}
          isMobileRedesign={isMobileRedesign}
          src={icon}
        />
      </SlideIconBox>
      <Title
        data-swiper-parallax="0"
        content={title}
        isMobileRedesign={isMobileRedesign}
      />
      <Description data-swiper-parallax="0">{description}</Description>
      <MainButton
        style={isOverviewLP ? { textTransform: 'uppercase' } : {}}
        data-swiper-parallax="0"
        label={buttonLabel}
        onClick={onClick}
      />
    </Container>
  );
};

export { PrimarySlide };
