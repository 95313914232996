import { ReactComponent as CoinsIcon } from 'assets/streamline-light/money-payments-finance/accounting-billing/accounting-coins.svg';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Close } from '../../../../../../assets/streamline-light/interface-essential/form-validation/close.svg';
import { PropertyPurchaseIntent } from '../../../../../../generated';
import SingleOffer from '../../../../components/purchase-offers/single-offer';
import { OutsideClickHandler } from 'modules/common/components/outside-click-handler';
import { useEffect, useRef, useState } from 'react';

const respBreakPoint = 1440;

const Container = styled.div<{ isOpenAccordion: boolean }>`
  z-index: 2;
`;

const Nav = styled.div<{ isOpenAccordion: boolean }>`
  display: none;
  position: fixed;
  z-index: 3334;
  top: 0;
  right: 0;
  left: 0;
  color: white;
  height: 56px;
  background-color: #002849;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-transform: capitalize;

  @media (max-width: ${respBreakPoint}px) {
    display: flex;
  }
`;

const CloseBtn = styled.button`
  position: fixed;
  right: 16px;
  top: 16px;
  padding: 0;
  margin: 0;
  outline: none;
  width: 16px;
  height: 16px;
  color: #fff;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const Heading = styled.div<{ isOpenAccordion: boolean }>`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: solid 1px #d9d9d9;
  border-bottom: 1px solid #fff;
  padding: 10px 16px 22px;
  color: ${({ theme }) => theme.blue};
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.67;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  background: white;
  position: relative;
  z-index: 2;
`;

const IconContainer = styled.div<{ isOpenAccordion: boolean }>`
  width: 20px;
  height: 20px;
  ${(props) => (props.isOpenAccordion ? 'margin-right: 8px;' : '')}
`;

const ContentBox = styled.div<{ isOpenAccordion: boolean }>`
  position: absolute;
  padding: 16px;
  border-radius: 3px;
  border-top-right-radius: 0;
  box-shadow: 0 4px 10px 0 var(--black-9);
  border: solid 1px #d9d9d9;
  border-top: none;
  background-color: #fff;
  z-index: 4;
  top: 54px;
  right: 0;
  /* ${(props) => (!props.isOpenAccordion ? 'left: auto;' : 'left: 0;')} */
  /* min-width: 640px; */

  @media (max-width: ${respBreakPoint}px) {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3333;
    position: fixed;

    a {
      display: flex;
      flex: 1;
    }
  }
`;

const ContentBoxLine = styled.div`
  position: absolute;
  top: 53px;
  right: 0;
  height: 1px;
  background: #d9d9d9;
  z-index: 1;
`;

const Title = styled.h2`
  margin-bottom: 12px;
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};

  @media (max-width: ${respBreakPoint}px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    margin-top: 54px;
  }
`;

const PurchaseIntentButton = styled.button`
  height: 40px;
  margin-top: 12px;
  padding: 10px 24px;
  border-radius: 3px;
  background-color: #4e73f5;
  color: white;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 1px;
  outline: none;
  border: none;
  cursor: pointer;

  @media (max-width: ${respBreakPoint}px) {
    margin-top: 40px;
    width: 100%;
  }
`;

interface IProps {
  openPurchaseIntent: () => void;
  purchaseIntents: PropertyPurchaseIntent[];
  refetch: () => void;
  closePurchaseIntentDropdown: () => void;
  isOpenAccordion: boolean;
}

const PurchaseIntentDropdown = ({
  openPurchaseIntent,
  purchaseIntents,
  refetch,
  closePurchaseIntentDropdown,
  isOpenAccordion,
}: IProps) => {
  const { t } = useTranslation();
  const contentBoxRef = useRef<HTMLDivElement>(null);
  const [contentBoxWidth, setContentBoxWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (contentBoxRef.current) {
        const windowWidth = window.innerWidth;
        if (windowWidth > respBreakPoint) {
          const rect = contentBoxRef.current.getBoundingClientRect();
          const contentLeft = 32 + 32; // Sum the overlay and container paddings
          const calculatedWidth = rect.right - contentLeft;
          setContentBoxWidth(calculatedWidth);
        } else {
          setContentBoxWidth(0);
        }
      }
    };

    // Calculate width on mount and when the window is resized
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <OutsideClickHandler
        display="block"
        onOutsideClick={closePurchaseIntentDropdown}
      >
        <Container isOpenAccordion={isOpenAccordion}>
          <Nav isOpenAccordion={isOpenAccordion}>
            {t('expose-page.head-info.button.interest-in-buying').toLowerCase()}
            <CloseBtn onClick={closePurchaseIntentDropdown}>
              <Close />
            </CloseBtn>
          </Nav>
          <Heading isOpenAccordion={isOpenAccordion}>
            <IconContainer isOpenAccordion={isOpenAccordion}>
              <CoinsIcon />
            </IconContainer>
            <>{t('expose-page.head-info.button.interest-in-buying')}</>
          </Heading>
          <ContentBoxLine
            style={{
              width: contentBoxWidth > 0 ? `${contentBoxWidth - 2}px` : '100vw',
            }}
          />
          <ContentBox
            ref={contentBoxRef}
            style={{
              width: contentBoxWidth > 0 ? `${contentBoxWidth}px` : '100vw',
            }}
            isOpenAccordion={isOpenAccordion}
          >
            <Title>{t('purchase-intent.offers.title')}</Title>
            {purchaseIntents.map((offer) => {
              return (
                <SingleOffer key={offer._id} offer={offer} refetch={refetch} />
              );
            })}
            <PurchaseIntentButton onClick={openPurchaseIntent}>
              {t(
                'expose-page.head-info.button.interest-in-buying.info.button.submit-purchase-offer'
              )}
            </PurchaseIntentButton>
          </ContentBox>
        </Container>
      </OutsideClickHandler>
    </>
  );
};

export default PurchaseIntentDropdown;
