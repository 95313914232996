import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import {
  setMarketingParameters,
  toggleIframe,
} from '../../auth/redux/authSlice';
import { IIframeType } from '../../auth/interfaces';

type TReturn = {
  isIframe: boolean;
};

const useIframeLogic = (): TReturn => {
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const { marketingParameters } = useAppSelector((state) => state.auth);
  const isIframeWidget =
    urlParams.get('iniframe') === 'true' ? IIframeType.WIDGET : null;
  const isIframeWidgetCentered =
    urlParams.get('iniframe-centered') === 'true'
      ? IIframeType.WIDGET_OWNER_CENTERED
      : null;
  const isIframeWidgetNoModal =
    urlParams.get('iniframe-no-modal') === 'true'
      ? IIframeType.WIDGET_OWNER_NO_MODAL
      : null;
  const isIframeBenefit =
    urlParams.get('iniframe-benefit') === 'true' ? IIframeType.BENEFIT : null;
  const isIframeWidgetBuyer =
    urlParams.get('iniframe-buyer') === 'true'
      ? IIframeType.WIDGET_BUYER
      : null;
  const isIframeWidgetFinancing =
    urlParams.get('iniframe-financing') === 'true'
      ? IIframeType.WIDGET_FINANCING
      : null;
  const isIframeWidgetFinancingNoModal =
    urlParams.get('iniframe-financing-no-modal') === 'true'
      ? IIframeType.WIDGET_FINANCING_NO_MODAL
      : null;
  const isIframeWidgetFinancingCertificate =
    urlParams.get('iniframe-financing-certificate') === 'true'
      ? IIframeType.WIDGET_FINANCING_CERTIFICATE
      : null;
  const isIframeWidgetFinancingCertificateNoModal =
    urlParams.get('iniframe-financing-certificate-no-modal') === 'true'
      ? IIframeType.WIDGET_FINANCING_CERTIFICATE_NO_MODAL
      : null;

  const iframeType =
    isIframeWidget ||
    isIframeWidgetBuyer ||
    isIframeBenefit ||
    isIframeWidgetCentered ||
    isIframeWidgetFinancing ||
    isIframeWidgetFinancingNoModal ||
    isIframeWidgetFinancingCertificate ||
    isIframeWidgetFinancingCertificateNoModal ||
    isIframeWidgetNoModal;

  useEffect(() => {
    // Get the URL parameters and if iniframe is present we must show only the form.
    dispatch(toggleIframe(iframeType));

    // marketing parameters logic
    if (!marketingParameters) {
      if (iframeType) {
        dispatch(setMarketingParameters(`${window?.document?.referrer || ''}`));
      } else {
        dispatch(setMarketingParameters(`${window?.location?.href}`));
      }
    }
  }, []);

  return { isIframe: !!iframeType };
};

export { useIframeLogic };
