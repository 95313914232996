import { usePDF } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import {
  Broker,
  MarketingStatistics,
  useGetMarketingStatisticsQuery,
  useGetPropertyBrokerQuery,
  usePropertyOnOfficeViewQuery,
} from '../../../../generated';

import { PropertyMarketingStatisticsDocument } from '..';
import { usePropertyQuery } from '../../../../services/graphql/enhanced';
import { implodeAddress } from '../../../property/utils/address';
import {
  OffersStatisticsItem,
  StatisticsItem,
} from '../../../seller/interfaces';
import { useCurrency } from '../../../property/hooks/useCurrency';
import {
  advertisementViewsStatistics as advertisementViewsStatisticsSimulation,
  generalStatistics,
  offersStatisticsPerWeek as offersStatisticsPerWeekSimulation,
  simulationHighestBidPrice,
} from '../../../seller/components/selling-simulation/dummy-data';
import { ExposeStatisticsCard } from '../../../seller/components/expose-statistics-card/expose-statistics-card';
import { useAppSelector } from '../../../common/hooks';
import { formatPrice } from '../../../seller/utils/formatPrice';

interface IProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLocked?: boolean;
  isSimulation: boolean;
}

const Container = styled.div`
  position: absolute;
  z-index: -9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
`;

const PDFPropertyMarketingStatisticsContainer = ({
  setLoading,
  isSimulation,
}: IProps): JSX.Element => {
  const containerRef = useRef<null | HTMLDivElement>(null);
  const [images, setImages] = useState<{ [key: string]: string }>({});
  const [downloadReady, setDownloadReady] = useState(false);
  const { propertyId } = useParams<{ propertyId: string }>();
  const priceCurrency = useCurrency();
  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const { statistics, isStatisticsLoading } = useGetMarketingStatisticsQuery(
    {
      propertyId,
    },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        statistics: data?.getMarketingStatistics as MarketingStatistics,
        isStatisticsLoading: isLoading,
        getStatisticsError: error,
      }),
    }
  );

  const showRequestedExposes = useMemo(
    () => !!statistics?.generalStatistics?.requestedExposes,
    [statistics?.generalStatistics?.requestedExposes]
  );

  const showUnlockedExposes = useMemo(
    () => !!statistics?.generalStatistics?.unlockedExposes,
    [statistics?.generalStatistics?.unlockedExposes]
  );

  const showViewingAppointments = useMemo(
    () => !!statistics?.generalStatistics?.viewingAppointments,
    [statistics?.generalStatistics?.viewingAppointments]
  );

  const { advertisementViewsStatistics, showChartAlert } = useMemo(() => {
    const stat = statistics?.statisticsPerWeek
      ?.slice()
      ?.reverse()
      ?.map(
        (item) =>
          ({
            label: `KW${item.weekNumber}`,
            value: item.statistics?.advertisementViews,
          } as StatisticsItem)
      );

    const isShowChartAlert = !(
      Number(stat?.[stat?.length - 1]?.value ?? 0) >
      Number(stat?.[stat?.length - 2]?.value ?? 0)
    );

    return {
      advertisementViewsStatistics: stat,
      showChartAlert: isShowChartAlert,
    };
  }, [statistics?.statisticsPerWeek]);

  const statisticsChart = isSimulation
    ? advertisementViewsStatisticsSimulation
    : advertisementViewsStatistics;

  const showChart = useMemo(
    () =>
      !isEmpty(statisticsChart) &&
      statisticsChart?.some((item) => item.value !== 0),
    [statisticsChart]
  );

  const property = usePropertyQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, isLoading }) => {
        const { location } = data?.property?.propertyData || {};

        const { valuations, valuationsLastSync, onOfficeId, externalId } =
          data?.property || {};
        const { address } = location || {};

        const currentValuationIndex =
          valuations?.findIndex(
            (valuation) =>
              new Date(valuation.date).toDateString() ===
              new Date(valuationsLastSync).toDateString()
          ) ?? -1;

        return {
          isLoading,
          address: implodeAddress(address),
          currentValuation:
            valuations?.[currentValuationIndex]?.valuation?.value,
          onOfficeId,
          externalId,
        };
      },
      skip: isBrokerView,
    }
  );

  const brokerPropertyData = usePropertyOnOfficeViewQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, isLoading: isLoadingBrokerPropertyData }) => {
        const { location } = data?.propertyOnOfficeView?.propertyData || {};

        const {
          valuations,
          valuationsLastSync,
          actualValuations,
          onOfficeId,
          externalId,
        } = data?.propertyOnOfficeView || {};
        const { address } = location || {};

        const currentValuationIndex =
          actualValuations?.findIndex(
            (valuation) =>
              new Date(valuation.date).toDateString() ===
              new Date(valuationsLastSync).toDateString()
          ) ?? -1;

        return {
          isLoading: isLoadingBrokerPropertyData,
          address: implodeAddress(address),
          onOfficeId,
          externalId,
          currentValuation:
            actualValuations?.[currentValuationIndex]?.valuation?.value ??
            valuations?.[currentValuationIndex]?.valuation?.value,
        };
      },
      skip: !isBrokerView,
    }
  );

  const currentPropertyData = useMemo(() => {
    return isBrokerView ? brokerPropertyData : property;
  }, [brokerPropertyData, isBrokerView, property]);

  const simulationHighestBid = useMemo(
    () =>
      currentPropertyData?.currentValuation
        ? formatPrice(
            simulationHighestBidPrice(currentPropertyData?.currentValuation),
            priceCurrency === '€' ? 'EUR' : 'CHF'
          )
        : '€650.000',
    [currentPropertyData?.currentValuation, priceCurrency]
  );

  const offersStatisticsPerWeek = useMemo(() => {
    const highestBidValue = statistics?.generalStatistics?.highestBid ?? 0;
    const result = statistics?.statisticsPerWeek?.map((item, index) => {
      const highestBid = item.bids?.find(
        (bid) => highestBidValue === bid.offeredPrice
      );
      // const dateFrom = new Date(item.dateFrom).toISOString();
      // const dateTo = new Date(item.dateTo).toISOString();
      const isNewOffer = index === 0;

      return {
        _id: String(item.weekNumber),
        // weekNumber: item.weekNumber,
        // dateFrom,
        // dateTo,
        // highestBidId: highestBid?.customerId,
        offers: item.bids?.map((bid) => {
          const formattedPrice = formatPrice(
            bid.offeredPrice ?? 0,
            priceCurrency === '€' ? 'EUR' : 'CHF'
          );

          const newFormattedPrice = bid.newOfferedPrice
            ? formatPrice(
                bid.newOfferedPrice ?? 0,
                priceCurrency === '€' ? 'EUR' : 'CHF'
              )
            : '';

          return {
            bidOnOfficeId: bid.bidOnOfficeId,
            customerId: bid.customerId,
            customerOnOfficeId: bid.customerOnOfficeId ?? '',
            price: formattedPrice,
            newPrice: newFormattedPrice,
            fromCity: bid.customerCity,
            isNew: isNewOffer,
            isAccepted: bid.isAccepted,
            isHighestBid: bid.newOfferedPrice
              ? bid.newOfferedPrice === highestBidValue
              : bid.offeredPrice === highestBidValue,
            keywords: [
              `Interessent ${bid.customerId}`,
              // String(bid.customerId),
              String(bid.offeredPrice),
              formattedPrice,
              newFormattedPrice,
              bid.customerCity,
              ...(isNewOffer ? ['NEUES ANGEBOT'] : []),
              ...(highestBid?.customerId === bid.customerId
                ? ['HÖCHSTGEBOT']
                : []),
            ].map((keyword) => keyword?.toLowerCase()),
            date: bid.date,
          };
        }),

        generalStatistics: item.statistics,
      } as OffersStatisticsItem;
    });

    return result ?? [];
  }, [
    priceCurrency,
    statistics?.generalStatistics?.highestBid,
    statistics?.statisticsPerWeek,
  ]);
  const statisticsProp = isSimulation
    ? generalStatistics
    : statistics?.generalStatistics;

  const offersStatistics = isSimulation
    ? offersStatisticsPerWeekSimulation(
        priceCurrency === '€' ? 'EUR' : 'CHF',
        currentPropertyData?.currentValuation
      )
    : offersStatisticsPerWeek;

  const [instance, updateInstance] = usePDF({
    document: (
      <PropertyMarketingStatisticsDocument
        isSimulation={isSimulation}
        onOfficeId={currentPropertyData.onOfficeId}
        externalId={currentPropertyData.externalId}
        address={currentPropertyData?.address}
        statistics={statisticsProp}
        priceCurrency={priceCurrency}
        statisticsLastSync={statistics?.statisticsLastSync}
        showViewingAppointments={showViewingAppointments}
        showUnlockedExposes={showUnlockedExposes}
        showRequestedExposes={showRequestedExposes}
        images={images}
        offersStatistics={offersStatistics}
        simulationHighestBid={simulationHighestBid}
      />
    ),
  });

  useEffect(() => {
    setTimeout(() => {
      const canvases = containerRef.current?.querySelectorAll('canvas');
      const imagesObject: {
        [key: string]: string;
      } = {};
      canvases?.forEach((c) => {
        if (c.id) {
          imagesObject[c.id] = c.toDataURL('image/png', 1);
        }
      });
      const root = document.querySelector('#root');
      const overviewCanvases = root?.querySelectorAll('canvas');
      overviewCanvases?.forEach((c) => {
        if (c.id) {
          imagesObject[c.id] = c.toDataURL('image/png', 1);
        }
      });
      setImages(imagesObject);
    }, 3000);
  }, []);

  const { propertyBroker, isBrokerLoading, getBrokerError } =
    useGetPropertyBrokerQuery(
      {
        propertyId,
      },
      {
        selectFromResult: ({ data, isLoading: isLoading2, error }) => ({
          propertyBroker: data?.getPropertyBroker as Broker,
          isBrokerLoading: isLoading2,
          getBrokerError: error,
        }),
      }
    );
  const showAlert = Boolean(propertyBroker && showChartAlert);

  useEffect(() => {
    if (
      (showChart ? Object.keys(images).length > 0 : true) &&
      !property.isLoading &&
      !brokerPropertyData.isLoading &&
      !isStatisticsLoading &&
      !isBrokerLoading
    ) {
      updateInstance();
      setDownloadReady(true);
    }
  }, [
    showChart,
    images,
    setLoading,
    isStatisticsLoading,
    property.isLoading,
    brokerPropertyData.isLoading,
    updateInstance,
  ]);

  useEffect(() => {
    if (
      !instance?.loading &&
      !instance?.error &&
      instance?.blob &&
      downloadReady
    ) {
      saveAs(instance?.blob, 'property');
      setLoading(false);
    }
  }, [downloadReady, instance, setLoading]);

  return createPortal(
    <Container ref={containerRef}>
      <ExposeStatisticsCard
        statistics={statisticsChart}
        isSimulation={isSimulation}
        showAlert={showAlert}
      />
    </Container>,
    document.body as Element
  );
};

export { PDFPropertyMarketingStatisticsContainer };
