import { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { isEmpty } from 'lodash';
import { Comment } from '../../comment/comment';
import { ExposeInfoCard } from '../../info-card/info-card';
import { useParseComments } from '../../../hooks/useParseComments';
import { PropertyExpose } from '../../../interfaces';

interface IProps {
  expose: PropertyExpose;
  onDownloadPdfExpose?: () => void;
  onOpenOnlineExpose?: () => void;
}

const ChangesRequestedCardBase: FC<IProps> = ({
  expose,
  onDownloadPdfExpose,
  onOpenOnlineExpose,
}) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const parsedComments = useParseComments(expose.exposeChangesHistory ?? []);

  return (
    <>
      <ExposeInfoCard
        borderLeftColor={theme.red}
        title={t('seller.expose-card.title')}
        createdOn={expose.createdAt}
        onDownloadPdfExpose={onDownloadPdfExpose}
        onOpenOnlineExpose={onOpenOnlineExpose}
      />
      {!isEmpty(parsedComments) && (
        <>
          {parsedComments?.map((comment) => (
            <Comment
              userAvatarPlaceholder={comment?.avatarPlaceholder}
              title={comment?.userFullName}
              date={comment?.date}
              text={comment?.text}
            />
          ))}
        </>
      )}
      <ExposeInfoCard
        borderLeftColor={theme.gold}
        title={t('seller.expose-card.changes-requested-state.title')}
        description={t(
          'seller.expose-card.changes-requested-state.description'
        )}
      />
    </>
  );
};

const ChangesRequestedCard = memo(ChangesRequestedCardBase);

export { ChangesRequestedCard };
