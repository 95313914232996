import styled from 'styled-components';
import { device } from '../../../../../../../../../style/theme';

export const Title = styled.h1`
  color: #00305e;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin-bottom: 24px;

  @media ${device.tablet} {
    text-align: center;
    margin-bottom: 16px;
    font-size: 20px;

    & + div {
      margin-bottom: 12px;
    }
  }
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  label {
    color: rgba(0, 48, 94, 0.7);
  }

  p {
    padding: 0;
  }

  #register-form-submit-button {
    position: initial;
    width: 100%;

    @media ${device.tablet} {
      transform: initial;
      margin-bottom: 8px;
    }
  }
`;

export const Row = styled.div<{ isiframe?: boolean; isMarginTop?: boolean }>`
  display: flex;
  flex-direction: column;
`;
