import { CSSProperties, FC, memo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ReactComponent as CheckCircle } from 'assets/streamline-light/interface-essential/form-validation/check-circle-1.svg';
import { Container, Text } from './alert-styles';
import Icon from '../../../common/components/ui/icon';

interface IProps {
  text: string;
  style?: CSSProperties;
}

const AlertBase: FC<IProps> = ({ text, style }) => {
  const theme = useContext(ThemeContext);

  return (
    <Container style={style}>
      <Icon
        icon={CheckCircle}
        width={16}
        height={16}
        color={theme.green}
        style={{
          minWidth: '16px',
          minHeight: '16px',
        }}
      />
      <Text content={text} />
    </Container>
  );
};

const Alert = memo(AlertBase);

export { Alert };
