import { identity, omit, pickBy } from 'lodash';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as Lock } from 'assets/streamline-light/interface-essential/password/password-lock.svg';

import {
  useConfirmPhoneChangeMutation,
  UserState,
  useUpdateUserProfileMutation,
  useSetUserStateMutation,
  useRequestVerificationCodeMutation,
} from '../../../../generated';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { Modal } from '../../../common/components/modal';
import Icon from '../../../common/components/ui/icon';
import { StatusType } from '../../../common/components/verification-input/types';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { IEditProfile } from '../../interfaces';
import { setUser, setUserState } from '../../redux/authSlice';
import { ChangePhoneForm } from './forms/change-phone-form';

interface IChangePhoneModal {
  isOpen: boolean;
  onSuccessSubmit?: () => void;
}

const Header = styled.div`
  margin: 16px 0;
  font-family: source serif pro, sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ChangePhoneModal = ({
  isOpen,
  onSuccessSubmit,
}: IChangePhoneModal): JSX.Element => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();
  const themeContext = useContext(ThemeContext);

  const user = useAppSelector((state) => state.auth.user);

  const [verificationStatus] = useState(StatusType.process);

  const [updateUserProfile] = useUpdateUserProfileMutation();
  const [confirmPhoneChange, { error: confirmPhoneChangeError }] =
    useConfirmPhoneChangeMutation();
  const [setUserStateRequest] = useSetUserStateMutation();
  const [requestVerificationCode] = useRequestVerificationCodeMutation();
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const refreshToken = useAppSelector((state) => state.auth.refreshToken);

  const onClose = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      if (onSuccessSubmit) {
        onSuccessSubmit();
      }
    },
    [onSuccessSubmit]
  );

  const onVerifyPhone = useCallback(
    async (confirmationCode: string) => {
      if (user?._id) {
        const verifyPhoneResponse = await confirmPhoneChange({
          input: {
            confirmationCode,
            _id: user?._id,
          },
        }).unwrap();

        await setUserStateRequest({
          _id: user._id,
          state: UserState.ChangePassword,
        });

        if (verifyPhoneResponse?.confirmPhoneChange) {
          batch(() => {
            dispatch(setUser(verifyPhoneResponse?.confirmPhoneChange));
            dispatch(setUserState(UserState.ChangePassword));
            onClose();
          });
        }
      }
    },
    [confirmPhoneChange, dispatch, onClose, setUserStateRequest, user?._id]
  );

  const onRequestConfirmationCode = useCallback(
    async (formData: IEditProfile) => {
      const input = {
        ...pickBy(omit(formData, ['phonePrefix', 'phone']), identity),
        prefix: `${formData.phonePrefix}`,
        phone: `+${formData.phonePrefix}${formData.phone}`,
      };

      try {
        const updateUserProfileResponse = await updateUserProfile({
          input,
        }).unwrap();

        dispatch(setUser(updateUserProfileResponse?.updateUserProfile));

        await requestVerificationCode({
          input: {
            username:
              updateUserProfileResponse?.updateUserProfile?.username ?? '',
            accessToken: accessToken ?? '',
            refreshToken: refreshToken ?? '',
            attributeName: 'phone_number',
            firstName: updateUserProfileResponse?.updateUserProfile?.name ?? '',
            lastName:
              updateUserProfileResponse?.updateUserProfile?.surname ?? '',
          },
        }).unwrap();
      } catch (error) {
        console.error(error);
      }
    },
    [
      accessToken,
      dispatch,
      refreshToken,
      requestVerificationCode,
      updateUserProfile,
    ]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      {...(isMobileSize && {
        wrapperStyles: {
          width: '100%',
          height: '100%',
          padding: '12px',
          maxWidth: '100%',
          overflowY: 'auto',
        },
      })}
    >
      <IconContainer>
        <Icon icon={Lock} color={themeContext.blue} width={40} height={40} />
      </IconContainer>
      <Header>{t('phone-verification.title')}</Header>
      {confirmPhoneChangeError && (
        <GlobalError
          title={t(
            confirmPhoneChangeError?.message?.split(':')?.[0].toLowerCase() ??
              ''
          )}
        />
      )}
      <ChangePhoneForm
        onVerifyPhone={onVerifyPhone}
        status={verificationStatus}
        onClose={onClose}
        onRequestConfirmationCode={onRequestConfirmationCode}
      />
    </Modal>
  );
};

export { ChangePhoneModal };
