import { createSlice } from '@reduxjs/toolkit';
import { PurchaseIntentType } from '../../../../generated';
import { InitialStateProps, Steps } from '../interface';

const initialState: InitialStateProps = {
  isOpen: false,
  type: PurchaseIntentType.Physical,
  activeStep: 0,
  structure: {
    PHYSICAL: ['physical-first-step', 'physical-second-step'],
    LEGAL: ['legal-first-step', 'legal-second-step', 'legal-third-step'],
  },
};

export const purchaseIntendSlice = createSlice({
  name: 'purchaseIntend',
  initialState,
  reducers: {
    nextStep: (state) => {
      state.activeStep += 1;
    },
    prevStep: (state) => {
      state.activeStep -= 1;
    },
    open: (state) => {
      state.isOpen = true;
    },
    close: (state) => {
      state.isOpen = initialState.isOpen;
      state.type = initialState.type;
      state.activeStep = initialState.activeStep;
      state.structure = initialState.structure;
    },
    changeType: (state, { payload: newType }) => {
      state.type = newType;
    },
    addAnother: (state) => {
      state.structure[state.type].splice(
        state.activeStep + 1,
        0,
        `${state.type.toLowerCase()}-additional-step` as Steps
      );
      state.activeStep += 1;
    },
    deleteCurrent: (state) => {
      state.structure[state.type].splice(state.activeStep, 1);
      state.activeStep -= 1;
    },
  },
});

export const {
  nextStep,
  prevStep,
  open,
  close,
  changeType,
  addAnother,
  deleteCurrent,
} = purchaseIntendSlice.actions;

export default purchaseIntendSlice.reducer;
