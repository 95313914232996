export const buyerFinderGraphFakeData = {
  labels: [
    'Mai 23',
    'Jun 23',
    'Jul 23',
    'Aug 23',
    'Sep 23',
    'Okt 23',
    'Nov 23',
    'Dez 23',
    'Jan 24',
    'Feb 24',
    'Mrz 24',
    'Apr 24',
    'Mai 24',
    'Jun 24',
    'Jul 24',
    'Aug 24',
    'Sep 24',
    'Okt 24',
    'Nov 24',
    'Dez 24',
    'Jan 25',
    'Feb 25',
    'Mrz 25',
    'Apr 25',
    'Mai 25',
    'Jun 25',
    'Jul 25',
    'Aug 25',
    'Sep 25',
    'Okt 25',
    'Nov 25',
    'Dez 25',
    'Jan 26',
    'Feb 26',
    'Mrz 26',
    'Apr 26',
    'Mai 26',
    'Jun 26',
  ],
  values: [
    25, 31, 28, 33, 39, 38, 45, 46, 55, 40, 58, 65, 57, 62, 55, 64, 71, 68, 70,
    66, 70, 72, 80, 95, 87, 85, 93, 101, 105, 85, 80, 81, 84, 90, 100, 125, 115,
    131,
  ],
  months: [
    '5.2023',
    '6.2023',
    '7.2023',
    '8.2023',
    '9.2023',
    '10.2023',
    '11.2023',
    '12.2023',
    '1.2024',
    '2.2024',
    '3.2024',
    '4.2024',
    '5.2024',
    '6.2024',
    '7.2024',
    '8.2024',
    '9.2024',
    '10.2024',
    '11.2024',
    '12.2024',
    '1.2025',
    '2.2025',
    '3.2025',
    '4.2025',
    '5.2025',
    '6.2025',
    '7.2025',
    '8.2025',
    '9.2025',
    '10.2025',
    '11.2025',
    '12.2025',
    '1.2026',
    '2.2026',
    '3.2026',
    '4.2026',
    '5.2026',
    '6.2026',
  ],
};
