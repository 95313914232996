import { useTranslation } from 'react-i18next';
import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';

import { ReactComponent as Star } from 'assets/streamline-light/social-medias-rewards-rating/rating/rating-star-1.svg';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useIsMobileSize } from 'modules/common/hooks/useIsMobileSize';
import { disableBodyScroll } from 'modules/common/utils/disable-body-scroll';

import {
  CloseButton,
  HandlerButtonWrap,
  Body,
  BodyTitle,
  ButtonsWrap,
  CancelButton,
  Container,
  Form,
  RatingItem,
  RatingLegend,
  RatingWrap,
  StarButton,
  SubmitButton,
  TextareaWrap,
  Wrapper,
  TooltipHeader,
} from './customer-feedback-styles';
import Icon from '../ui/icon';
import { ParagraphText } from '../ui/typography';
import { InputTextArea } from '../form/input/input-text-area';
import { useSendFeedbackMutation } from '../../../../generated';
import { useAppSelector } from '../../hooks';
import { Tooltip } from '../tooltip';

interface RatingProps {
  rating: string;
  onChange?: (value: string) => void;
}

const Rating = ({ rating, onChange }: RatingProps) => {
  const ratings = ['1', '2', '3', '4', '5', '6'];
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <>
      <RatingWrap>
        {ratings.map((r, i) => (
          <RatingItem
            key={i}
            className={Number(rating) >= Number(r) ? 'active' : ''}
            onClick={() => {
              if (onChange) {
                onChange(r);
              }
            }}
          >
            <Icon
              icon={Star}
              width={42}
              height={42}
              color={themeContext.blue}
            />
          </RatingItem>
        ))}
      </RatingWrap>
      <RatingLegend>
        <ParagraphText content={t('customer_feedback.low')} />
        <ParagraphText content={t('customer_feedback.high')} />
      </RatingLegend>
    </>
  );
};

interface IRatingForm {
  rating: number;
  text: string;
}

export const CustomerFeedback = () => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [rating, setRating] = useState<string>('0');
  const [sendFeedback, { data, isLoading }] = useSendFeedbackMutation();
  const user = useAppSelector((state) => state.auth.user);
  const methods = useForm<IRatingForm>({ mode: 'onSubmit' });
  const isMobileSize = useIsMobileSize();

  const onSwitch = useCallback(
    (collapsedState: boolean) => {
      setCollapsed(collapsedState);

      if (!isMobileSize) return;

      // Disable body scroll on mobile
      disableBodyScroll(!collapsedState);
    },
    [isMobileSize]
  );

  useEffect(() => {
    if (user?.isSentFeedback) {
      onSwitch(true);
    } else {
      // setCollapsed(false);
    }
  }, [user?.isSentFeedback]);

  const onSubmit: SubmitHandler<IRatingForm> = useCallback(
    async (formData) => {
      onSwitch(true);
      await sendFeedback({ input: { rating, text: formData.text } });
    },
    [rating]
  );

  return (
    <Container className={collapsed ? 'collapsed' : ''}>
      <Wrapper className="customer-feedback-wrapper">
        <Body>
          <BodyTitle content={t('customer_feedback.title')} />
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Rating
                rating={rating}
                onChange={(value: string) => setRating(value)}
              />
              <TextareaWrap>
                <InputTextArea
                  name={'text'}
                  label={t('customer_feedback.textarea.label')}
                  rules={{
                    required: false,
                  }}
                  height={120}
                  noTranslate
                  style={{ lineHeight: 2 }}
                  labelStyle={{
                    whiteSpace: 'unset',
                  }}
                  placeholder={t('customer_feedback.textarea.placeholder')}
                />
              </TextareaWrap>
              <ButtonsWrap>
                <CancelButton
                  label={t('customer_feedback.cancel')}
                  onClick={() => {
                    onSwitch(true);
                  }}
                />
                <SubmitButton
                  label={t('customer_feedback.submit')}
                  isDisabled={Number(rating) <= 0}
                />
              </ButtonsWrap>
            </Form>
          </FormProvider>
        </Body>
        <HandlerButtonWrap>
          {collapsed && (
            <Tooltip
              content={
                <StarButton
                  onClick={() => {
                    onSwitch(!collapsed);
                  }}
                >
                  <Icon
                    icon={Star}
                    width={42}
                    height={42}
                    color={themeContext.blue}
                  />
                </StarButton>
              }
              id={'customer_feedback_tooltip'}
              maxWidth={48}
            >
              <TooltipHeader content={t('customer_feedback.title')} />
            </Tooltip>
          )}
          {!collapsed && (
            <CloseButton
              onClick={() => {
                onSwitch(!collapsed);
              }}
            >
              <Icon
                icon={Close}
                width={16}
                height={16}
                color={themeContext.blue}
              />
            </CloseButton>
          )}
        </HandlerButtonWrap>
      </Wrapper>
    </Container>
  );
};
