import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    width: '596px',
    backgroundColor: '#fff',
  },
  headerOuterContainer: {
    marginTop: '24px',
    borderTop: '1px solid #002849',
    borderBottom: '1px solid #002849',
    position: 'relative',
  },
  header: {
    height: '40px',
    padding: '0 42px',
    borderTop: '3px solid #fff',
    borderBottom: '3px solid #fff',
    backgroundColor: '#002849',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  logoContainer: {
    borderRight: '4px solid #fff',
    borderLeft: '4px solid #fff',
    borderTop: '2px solid #fff',
    borderBottom: '2px solid #fff',
    position: 'absolute',
    left: '38px',
    top: '-6px',
    height: '46px',
    width: '140px',
  },
  logo: {
    height: '42px',
    border: '1px solid #000',
  },
  headerTitle: {
    fontSize: '12px',
    letterSpacing: '0.5px',
    color: '#fff',
    fontFamily: 'Source Serif Pro',
    fontWeight: 'semibold',
  },
  innerContainer: {
    width: '511px',
    margin: '24px 42px 0 42px',
    textAlign: 'center',
    letterSpacing: '0.5px',
    color: '#00305e',
  },
  propertyTitle: {
    fontSize: '18px',
    marginBottom: '24px',
    fontFamily: 'Source Serif Pro',
    fontWeight: 'semibold',
  },
  additionalInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  street: {
    fontSize: '12px',
    letterSpacing: '0.5px',
    textAlign: 'left',
    fontFamily: 'Source Serif Pro',
    fontWeight: 'semibold',
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  idBox: {
    display: 'flex',
    flexDirection: 'row',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    border: '1px solid #d9d9d9',
    borderRadius: '3px',
    padding: '3px 7px',
    marginRight: '8px',
  },
  idText: {
    opacity: 0.5,
    fontFamily: 'Source Serif Pro',
    fontWeight: 'bold',
  },
  hashText: {
    opacity: 0.5,
    fontFamily: 'Source Serif Pro',
    fontWeight: 'normal',
  },
  feeBox: {
    backgroundColor: 'rgba(162, 133, 87, 0.1)',
    padding: '4px 8px',
    fontSize: '12px',
    letterSpacing: '0.5px',
    color: '#a28557',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    // alignItems: 'center',
    borderRadius: '3px',
    maxWidth: '200px',
  },
  feeText: {
    fontFamily: 'Source Serif Pro',
    fontWeight: 'bold',
  },
  feeValue: {
    fontFamily: 'Source Serif Pro',
    fontWeight: 'normal',
  },
  coverPhoto: {
    width: '511px',
    height: '273px',
    borderRadius: '3px',
  },
  detailsContainer: {
    width: '487px',
    margin: '-40px 12px 16px 12px',
  },
  details: {
    padding: '12px',
    borderRadius: '3px',
    border: '1px solid #d9d9d9',
    backgroundColor: '#fff',
  },
  detailItemsRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '9px',
  },
  lastDetailItemsRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  detailItem: {
    width: '109px',
    height: '40px',
    marginRight: '9px',
  },
  detailItemBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemIcon: {
    height: '24px',
    width: '24px',
    marginRight: '8px',
  },
  itemValue: {
    fontSize: '10px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  itemKey: {
    marginTop: '8px',
    opacity: 0.6,
    fontSize: '8px',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 'black',
  },
  lastDetail: {
    width: '109px',
    height: '40px',
  },
  pricesContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '68px',
    width: '487px',
    margin: '0 12px',
  },
  askedPriceBox: {
    padding: '12px',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    border: '1px solid #c9d5fc',
    backgroundColor: '#f2f5f7',
    color: '#002849',
    width: '244px',
  },
  priceBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  fullPrice: {
    fontSize: '16px',
    fontFamily: 'Source Serif Pro',
    fontWeight: 'semibold',
  },
  squarePrice: {
    fontSize: '10px',
    fontWeight: 'black',
    fontFamily: 'Roboto',
  },
  desc: {
    opacity: 0.8,
    fontSize: '12px',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  offeredPriceBox: {
    padding: '12px',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
    backgroundColor: '#00305e',
    color: 'white',
    width: '244px',
  },
  footerContainer: {
    position: 'absolute',
    bottom: 0,
    width: '596px',
    borderTop: '1px solid #002849',
  },
  footer: {
    borderTop: '3px solid #fff',
    height: '24px',
    backgroundColor: '#002849',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '12px',
    fontFamily: 'Source Serif Pro',
    fontWeight: 'semibold',
  },
  innerContainer2: {
    width: '487px',
    margin: '24px 54px 122px 54px',
    textAlign: 'left',
    letterSpacing: '0.5px',
    color: '#00305e',
  },
  subheading: {
    marginBottom: '12px',
    fontSize: '16px',
    letterSpacing: '0.5px',
    textAlign: 'left',
    color: '#00305e',
    fontFamily: 'Source Serif Pro',
    fontWeight: 'semibold',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '16px',
  },
  firstCard: {
    padding: '12px',
    borderRadius: '3px',
    border: '1px solid #d9d9d9',
    fontSize: '12px',
    fontWeight: 'normal',
    color: '#002f5d',
    textAlign: 'left',
    width: '237px',
    marginRight: '12px',
  },
  secondCard: {
    padding: '12px',
    borderRadius: '3px',
    border: '1px solid #d9d9d9',
    fontSize: '12px',
    fontWeight: 'normal',
    color: '#002f5d',
    textAlign: 'left',
    width: '237px',
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: '4px',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    letterSpacing: '0.5px',
  },
  cardTitle: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  cardIcon: {
    width: '16px',
    height: '16px',
    marginRight: '4px',
  },
  smallIconContainer: {
    width: '16px',
    height: '16px',
    marginRight: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pinIcon: {
    width: '10px',
    height: '14px',
  },
  phoneIcon: {
    width: '14px',
    height: '14px',
  },
  remarksContainer: {
    marginBottom: '16px',
  },
  remarks: {
    fontSize: '10px',
    fontFamily: 'Roboto',
    lineHeight: 1.6,
    letterSpacing: '0.5px',
  },
  remarksMargin: {
    fontSize: '10px',
    marginBottom: '8px',
    fontFamily: 'Roboto',
    lineHeight: 1.6,
    letterSpacing: '0.5px',
  },
  list: {
    fontSize: '10px',
    marginBottom: '16px',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '10px',
    position: 'relative',
    marginTop: '4px',
    lineHeight: 1.6,
    fontFamily: 'Roboto',
    letterSpacing: '0.5px',
  },
  dot: {
    width: '6px',
    height: '6px',
    backgroundColor: '#00305e',
    borderRadius: '100%',
    position: 'absolute',
    top: '3px',
  },
  plainText: {
    fontSize: '10px',
    lineHeight: '1.6',
    letterSpacing: '0.5px',
    color: '#002f5d',
    marginBottom: '12px',
    fontFamily: 'Roboto',
  },
  notarBox: {
    fontSize: '12px',
    lineHeight: '1.33',
    padding: '11px',
    borderRadius: '3px',
    border: '1px solid #d9d9d9',
  },
  notarText: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  footerContainer2: {
    position: 'absolute',
    bottom: 0,
    width: '596px',
    borderTop: '1px solid #002849',
  },
});

// Cheesecake cake gummi bears tiramisu soufflé chocolate bar gummies pudding muffin.
