import styled from 'styled-components';
import { device } from '../../../../../../style/theme';

export const CTAContainer = styled.div`
  position: absolute;
  bottom: -24px;
  right: 48px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media ${device.tablet} {
    position: static;
    padding: 0 16px;
  }
`;
