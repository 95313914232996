import { useState } from 'react';

interface Geolocation {
  latitude?: number;
  longitude?: number;
}

const useGeolocation = () => {
  const [geolocation, setGeolocation] = useState<Geolocation | null>(null);

  if (!navigator.geolocation) {
    console.error('Geolocation is not supported by your browser');
  }

  const success = (position: {
    coords: { latitude?: number; longitude?: number };
  }) => {
    const { latitude } = position?.coords ?? 0;
    const { longitude } = position?.coords ?? 0;

    setGeolocation({ latitude, longitude });
  };

  const error = () => {
    console.error('Unable to retrieve your location');
  };

  navigator.geolocation.getCurrentPosition(success, error);

  return geolocation;
};

export { useGeolocation };
