import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const Content = styled.div<{ isShadowmapExpanded?: boolean }>`
  position: relative;
  width: 50%;
  padding: 0 32px 32px;
  height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s width;

  ${({ isShadowmapExpanded }) =>
    isShadowmapExpanded &&
    `
      width: 0;
      overflow: hidden;
      padding: 0;
      opacity: 0.1;
  `}

  @media ${device.tablet} {
    width: 100%;
    overflow: auto;
    padding: 0 16px 32px;
    opacity: 1;
    height: calc(100vh - 56px);
  }
`;

export const Container = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.white};
  z-index: 501;
  padding-top: 32px;
  @media ${device.tablet} {
    position: relative;
    padding-top: 0;
  }
`;

export const Row = styled.div<{
  justifyContent?: string;
  margin?: string;
  alignItems?: string;
  padding?: string;
  isListView?: boolean;
  isColumn?: boolean;
  isWrap?: boolean;
}>`
  ${({ isColumn }) => isColumn && 'flex-direction: column'};
  display: flex;
  align-self: stretch;
  position: relative;
  flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'no-wrap')};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};

  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};

  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};

  border-right: 1px solid
    ${({ theme, isListView }) =>
      isListView ? theme.ctaDisabledBackground : 'transparent'};
  transition: 500ms;
`;

export const TabsContainer = styled(Row)`
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  overflow-x: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ul {
    display: flex;
    list-style-type: none;
  }
  li {
    cursor: pointer;
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    padding-right: 24px;
    font-family: Roboto;
    color: ${({ theme }) => theme.blue};
    text-transform: uppercase;
    &:last-child {
      padding-right: 0;
    }
    &.react-tabs__tab--selected {
      text-decoration: underline;
      opacity: 1 !important;
    }

    &:not(.react-tabs__tab--selected) {
      opacity: 0.5 !important;
    }
  }
  @media ${device.mobile} {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const InfoOverlay = styled.div`
  left: 0;
  right: 0;
  height: 64px;
  position: absolute;
  border-radius: 3px;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0), #fff);
`;
