import {
  Headline1,
  Headline2,
  ParagraphText,
  TitleSlogan,
} from 'modules/common/components/ui/typography';
import { device } from 'style/theme';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 48px;
  border-radius: 3px;
  background: #f7f9fa;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.09);
  margin-bottom: 64px;

  @media ${device.tablet} {
    padding: 16px;
    margin: 0 !important;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  padding-top: 24px;
  gap: 24px;

  @media ${device.tablet} {
    flex-direction: column-reverse;
  }
`;

export const ContentSection = styled.div`
  width: 50%;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const Header = styled(TitleSlogan)`
  opacity: 0.8;
  margin-bottom: 4px;
`;

export const StyledImg = styled.img`
  width: 100%;
  height: 278px;
  border-radius: 5px;
  object-fit: cover;
`;

export const Text = styled(ParagraphText)``;

export const Headline = styled(Headline1)`
  margin-bottom: 24px;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const ButtonWrap = styled.div`
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
  }

  @media ${device.mobile} {
    button {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const Steps = styled.div``;

export const Step = styled.div`
  padding-bottom: 24px;
  display: flex;
  gap: 16px;
  position: relative;

  &:before {
    content: '';
    display: block;
    border-left: 1px dashed ${(props) => props.theme.blue};
    position: absolute;
    top: 32px;
    left: 16px;
    bottom: 0;
  }

  &:last-child {
    &:before {
      display: none;
    }
  }

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;

    &:before {
      display: none;
    }
  }
`;

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media ${device.tablet} {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const StepTitle = styled(Headline2)`
  @media ${device.mobile} {
    font-size: 16px;
  }
`;

export const StepNumberContent = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid ${(props) => props.theme.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${(props) => props.theme.greyBackground};
`;

export const StepNumber = styled(Headline2)``;

export const StepNumberWrap = styled.div`
  width: 32px;
`;
