import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ResizeExpandSides } from 'assets/streamline-light/design/resize/resize-expand-sides.svg';
import { ReactComponent as BathroomIcon } from 'assets/streamline-light/hotels-spas/bathroom/bathroom-tub-towel.svg';
import { ReactComponent as MoneyIcon } from 'assets/streamline-light/money-payments-finance/money/accounting-bills-1.svg';
import { ReactComponent as RealEstateDimensionsBlock } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as RealEstateDimensionsPlan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-plan.svg';

import { PropertyCode } from '../../../../../generated';
import { useToLocalWithCurrency } from '../../../../common/utils/convertCHF';
import { extractPropertyTypeLabelAndIcon } from '../../../../property/utils/property-type-normalizer';
import PropertyValuationIcon from '../../icon/property-valuation-icon';
import {
  Container,
  IconContainer,
  IconRow,
  Content,
  Title,
  Header,
} from './search-profile-overview-card-styles';
import { IProps } from './interface';

const SearchProfileOverviewCardBase = ({
  header,
  title,
  content,
  code,
  // purchasePriceMin,
  purchasePriceMax,
  livingAreaMin,
  // livingAreaMax,
  landAreaMin,
  // landAreaMax,
  numberOfRoomsMin,
  // numberOfRoomsMax,
  numberOfBathroomsMin,
}: // numberOfBathroomsMax,
IProps): JSX.Element => {
  const { t } = useTranslation();
  const { icon, label } = extractPropertyTypeLabelAndIcon(code);
  const localPurchasePriceMax = useToLocalWithCurrency(
    purchasePriceMax || 0
  ) as string;

  return (
    <Container>
      <Header content={t(header)} />
      <Title content={t(title ?? '')} />
      <Content content={t(content)} />
      <IconContainer>
        <IconRow>
          <PropertyValuationIcon
            icon={icon}
            value={`${t(label)}`}
            type={t('property-overview.type')}
          />
          <PropertyValuationIcon
            icon={MoneyIcon}
            value={localPurchasePriceMax}
            type={t('search-profile-overview.purchase-price')}
          />
          {code !== PropertyCode.Plot && (
            <PropertyValuationIcon
              icon={RealEstateDimensionsBlock}
              value={`${t('approx')} ${livingAreaMin ?? 0} m²`}
              type={t('search-profile-overview.living-space')}
            />
          )}
          {code === PropertyCode.Plot && (
            <PropertyValuationIcon
              icon={ResizeExpandSides}
              value={`${t('approx')} ${landAreaMin ?? 0} m²`}
              type={t('search-profile-overview.land-area')}
            />
          )}
          {(code === PropertyCode.Apartment || code === PropertyCode.House) && (
            <>
              <PropertyValuationIcon
                icon={RealEstateDimensionsPlan}
                value={numberOfRoomsMin ?? 0}
                type={t('search-profile-overview.room')}
              />
              <PropertyValuationIcon
                icon={BathroomIcon}
                value={numberOfBathroomsMin ?? 0}
                type={t('search-profile-overview.bathroom')}
              />
            </>
          )}
        </IconRow>
      </IconContainer>
    </Container>
  );
};

const SearchProfileOverviewCard = memo(SearchProfileOverviewCardBase);

export { SearchProfileOverviewCard };
