import { useExposeDetailsQuery } from '../../../../generated';

const usePropertyData = (propertyId: string) => {
  const { isLoading, propertyData } = useExposeDetailsQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, isLoading: loading }) => {
        const propertyDetails = data?.exposeDetails?.propertyDetails;

        return {
          isLoading: loading,
          propertyData: {
            title: propertyDetails?.title,
            streetAddress: `${propertyDetails?.address?.street} ${propertyDetails?.address?.houseNumber}`,
            cityAddress: `${propertyDetails?.address?.postCode} ${propertyDetails?.address?.city}`,
            propertyId: propertyDetails?.propertyId,
            provision: propertyDetails?.equipment?.provision,
            type: propertyDetails?.type || '-',
            buildingYear: propertyDetails?.condition?.buildingYear || '-',
            modernizationYear:
              propertyDetails?.condition?.modernizationYear || '-',
            area: propertyDetails?.livingArea,
            floor: propertyDetails?.address?.floor,
            numberOfFloors: propertyDetails?.address?.numberOfFloors,
            numberOfRooms: propertyDetails?.area?.numberOfRooms,
            numberOfBedrooms: propertyDetails?.area?.numberOfBedrooms,
            numberOfBathrooms: propertyDetails?.area?.numberOfBathrooms,
            numberOfGarages: propertyDetails?.equipment?.numberOfGarages,
            numberOfParkingSpaces: propertyDetails?.area?.numberOfParkingSpaces,
            askedPrice: propertyDetails?.price,
            image: `https://erp.von-poll.com/import/images/${propertyDetails?.image[0].name}`,
          },
        };
      },
    }
  );

  return {
    isLoading,
    propertyData,
  };
};

export default usePropertyData;
