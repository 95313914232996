import 'core-js/features/array/flat-map';
import { Direction, OrderBy, OrderByCriterion } from '../../../generated';

export const buildOrderBy = () => {
  const options = (Object.keys(OrderBy) as Array<keyof typeof OrderBy>).flatMap(
    (field) => [
      {
        field: OrderBy[field],
        label: `${field.toLowerCase()} - ${Direction.Asc.toLowerCase()}`,
        direction: Direction.Asc,
      },
      {
        field: OrderBy[field],
        label: `${field.toLowerCase()} - ${Direction.Desc.toLowerCase()}`,
        direction: Direction.Desc,
      },
    ]
  );

  return options;
};

export const parseOptionValue = (orderByCriterion?: OrderByCriterion) => {
  const { field, direction } = orderByCriterion ?? {
    field: OrderBy.Distance,
    direction: Direction.Asc,
  };
  return {
    field,
    label: `${field?.toLowerCase()} - ${direction?.toLowerCase()}`,
    direction,
  };
};
