import { useContext, useEffect, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { device } from '../../../../style/theme';
import { Tooltip } from '../../../common/components/tooltip';
import Icon from '../../../common/components/ui/icon';
import {
  ParagraphTitle,
  TitleSlogan,
} from '../../../common/components/ui/typography';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 0;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
  @media ${device.tablet} {
    flex: 0 0 45%;
  }
`;

const Column = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  overflow: hidden;

  .tooltip-container {
    display: flex;
    flex-grow: 1;
  }

  .tooltip-inner-container {
    display: flex;
    max-width: 100%;
  }

  &:not(:last-of-type) {
    margin-right: 8px;
    flex: 0 0 auto;
  }
`;

const Value = styled(ParagraphTitle)`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    min-width: 0;

    color: ${(props) => props.theme.blue};
    margin-bottom: 3px;
  }
`;

const Label = styled(TitleSlogan)<{ sloganBigger?: boolean }>`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    min-width: 0;

    font-size: 10px;
    ${(props) => props.sloganBigger && 'font-size: 12px;'}
    line-height: 1.6;
    opacity: 0.6;
    color: ${(props) => props.theme.blue};
  }
`;

interface IProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  type: string;
  value: string | number;
  sloganBigger?: boolean;
}

const PropertyValuationIcon = ({
  icon,
  type,
  value,
  sloganBigger,
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const labelRef = useRef<HTMLParagraphElement>(null);
  const valueRef = useRef<HTMLParagraphElement>(null);
  const [labelTooltip, setLabelTooltip] = useState<boolean>(false);
  const [valueTooltip, setValueTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (labelRef.current) {
      setLabelTooltip(
        labelRef.current.scrollWidth > labelRef.current.offsetWidth
      );
    }
  }, [labelRef.current?.scrollWidth, labelRef.current?.offsetWidth]);

  useEffect(() => {
    if (valueRef.current) {
      setValueTooltip(
        valueRef.current.scrollWidth > valueRef.current.offsetWidth
      );
    }
  }, [valueRef.current?.scrollWidth, valueRef.current?.offsetWidth]);

  return (
    <Container className="property-valuation-icon">
      <Column>
        <Icon icon={icon} width={24} height={24} color={themeContext.blue} />
      </Column>
      <Column>
        <Tooltip
          isDisabled={!valueTooltip}
          noMaxWidth
          textAlign={'left'}
          id={`${type}--${value}-value`}
          maxWidth={0}
          content={
            <Value
              ref={valueRef}
              className="property-valuation-icon-value"
              content={`${value}`}
            />
          }
        >
          <Value
            className="property-valuation-icon-value"
            content={`${value}`}
          />
        </Tooltip>

        <Tooltip
          isDisabled={!labelTooltip}
          noMaxWidth
          textAlign={'left'}
          id={`${type}--${value}`}
          maxWidth={0}
          content={
            <Label ref={labelRef} content={type} sloganBigger={sloganBigger} />
          }
        >
          <Label content={type} sloganBigger={sloganBigger} />
        </Tooltip>
      </Column>
    </Container>
  );
};

export default PropertyValuationIcon;
