import { FC } from 'react';
import { useFourthStepLogic } from '../../../../../hooks/useStepsLogic';
import { NextButton } from '../../../../../../components/common/next-button';
import { InputsContainer } from '../../../../../../components/fullscreen-modal/inputs-container/inputs-container';
import { BackButtonContainer } from '../../../../common/back-button-container';

const FourthStep: FC = () => {
  const { onSubmit, inputs, watchedKeys, dynamicAdornment, outerDisable } =
    useFourthStepLogic();
  return (
    <>
      <InputsContainer inputs={inputs} dynamicAdornment={dynamicAdornment} />

      <BackButtonContainer marginTop={0} />
      <NextButton
        buttonCallback={onSubmit}
        watchedKeys={watchedKeys}
        isEvaluationV2
        outerDisable={outerDisable}
        label={'property-valuation.form.redesign.next-button'}
      />
    </>
  );
};

export { FourthStep };
