import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const PropertiesContainer = styled.div`
  border-radius: 3px;
  margin-top: 12px;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 290px;
  height: 144px;
  object-fit: cover;
  cursor: pointer;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const DetailsBox = styled.div<{ isActive?: boolean }>`
  border: 1px solid ${(props) => (props.isActive ? '#a8a8a7' : '#d9d9d9')};
  border-left: none;
  transition: 0.3s;
  padding: 12px;
  flex: 1;

  @media ${device.tablet} {
    border: 1px solid ${(props) => (props.isActive ? '#a8a8a7' : '#d9d9d9')};
    border-top: none;
  }
`;

export const Title = styled.h2`
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  margin-bottom: 6px;
  overflow: hidden;
  max-width: 539px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ControlBox = styled.div<{ isActive?: boolean }>`
  padding: 0 12px;
  transition: 0.3s;
  ${(props) =>
    props.isActive
      ? `
    border: 1px solid #a8a8a7;
    border-left: none;
    border-top-right-radius: 3px;
  `
      : `
    border: 1px solid #d9d9d9;
    border-left: none;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  `}
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    border: 1px solid ${(props) => (props.isActive ? '#a8a8a7' : '#d9d9d9')};
    border-top: none;
    border-radius: 0;
    justify-content: space-between;
    padding: 12px;
    ${(props) =>
      !props.isActive &&
      `
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    `}
  }
`;

export const LocationBox = styled.div`
  font-family: Roboto;
  color: ${({ theme }) => theme.blue};
  opacity: 0.8;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
`;

export const Hash = styled.span`
  margin-left: auto;
  padding: 1px 7px;
  border-radius: 3px;
  border: solid 1px #d9d9d9;
  font-family: Roboto;
  color: rgba(0, 48, 94, 0.5);
  font-size: 10px;
  font-weight: 900;
  line-height: 1.6;
  letter-spacing: 0.5px;
`;

export const PricesBox = styled.div`
  font-family: 'Source Serif Pro';
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.5px;
  display: flex;
  gap: 8px;
  margin-bottom: 12px;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CurrentPriceBox = styled.div`
  border-radius: 3px;
  background: rgba(0, 48, 94, 0.1);
  padding: 4px 8px;
  color: ${({ theme }) => theme.blue};
`;

export const Emphasized = styled.span`
  margin-right: 8px;
  font-weight: bold;
`;

export const LastPriceBox = styled.div`
  border-radius: 3px;
  padding: 4px 8px;
  background: rgba(78, 115, 245, 0.1);
  color: #4e73f5;
  display: inline-block;
`;

export const FeaturesBox = styled.div`
  display: flex;
  gap: 16px;

  @media ${device.tablet} {
    justify-content: space-between;
  }
`;

export const Feature = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FeaturesMobileContainer = styled.div<{ marginRight?: number }>`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 2px;
  ${(props) => `margin-right: ${props.marginRight}px`}
`;

export const FeatureText = styled.span`
  opacity: 0.6;
  margin-left: 8px;
  margin-right: 4px;

  @media ${device.tablet} {
    margin: 0;
  }
`;

export const PurchaseOfferButton = styled.button`
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  border-radius: 3px;
  background-color: #4e73f5;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    .a {
      stroke: #fff !important;
    }
  }

  &:hover {
    background-color: #0f5dc4;
    transition: 0.3s;
  }

  @media ${device.tablet} {
    height: 40px;
    width: auto;
    padding: 0 12px;
  }
`;

export const PurchaseButtonText = styled.span`
  font-family: Roboto;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.67;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const ToggleButton = styled.button<{ active?: boolean }>`
  cursor: pointer;
  padding: 0;
  margin: 0;
  margin-left: 8px;
  border: none;
  outline: none;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  border: solid 2px #d9d9d9;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(33, 37, 41, 0.5);

  svg {
    transition: 0.3s;
    ${(props) => props.active && 'transform: rotateX(180deg);'}
  }

  @media ${device.tablet} {
    height: 40px;
    width: 40px;
  }
`;

export const ToolTipContent = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto;
  text-transform: capitalize;
`;

export const OffersContainer = styled.div`
  padding: 12px;
  border: solid 1px #a8a8a7;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;

export const OffersTitle = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.blue};
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.5px;
`;
