import { FC, RefObject, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ReactComponent as Calendar } from 'assets/streamline-light/interface-essential/date-calendar/calendar.svg';
import { ReactComponent as CoinsIcon } from 'assets/streamline-light/money-payments-finance/accounting-billing/accounting-coins.svg';
import { open } from '../../../../../../forms/form-purchase-intend/redux/purchaseIntendSlice';

import {
  ExposeFeedbackButton,
  PurchaseOfferButton,
  Row,
} from '../head-info-styles';
import SelectWithArrow from '../../../../../../common/components/form/select/select-with-arrow/select-with-arrow';
import {
  setPropertyMeta,
  toggleTimifyModal,
} from '../../../../../../timify/redux/timifySlice';
import { setIsAppointmentSelectOpen } from '../../../redux/exposeSlice';
import { useAppSelector } from '../../../../../../common/hooks';
import PurchaseIntentDropdown from '../purchase-intent-dropdown';
import { PropertyPurchaseIntent } from '../../../../../../../generated';

interface IProps {
  purchaseIntents: PropertyPurchaseIntent[];
  refetch: () => void;
  contactFormRef?: RefObject<HTMLDivElement>;
}

const Buttons: FC<IProps> = ({ purchaseIntents, refetch, contactFormRef }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [purchaseIntentDropdownIsOpen, openPurchaseIntentDropdown] =
    useState(false);
  const { t } = useTranslation();

  const isSelectOpen = useAppSelector(
    (state) => state.exposeDetails.isAppointmentSelectOpen
  );
  const isOpenAccordion = useAppSelector(
    (state) => state.exposeDetails.isOpenHeadAccordion
  );

  const userId = useAppSelector((state) => state.auth.user?._id);

  const propertyId = useAppSelector(
    (state) => state.exposeDetails.externalPropertyId
  );

  const setIsSelectOpen = useCallback(() => {
    dispatch(setIsAppointmentSelectOpen(!isSelectOpen));
  }, [dispatch, isSelectOpen]);

  const closeDropdown = useCallback(() => {
    dispatch(setIsAppointmentSelectOpen(false));
  }, [dispatch]);

  const scrollToContactForm = useCallback(() => {
    if (contactFormRef?.current) {
      contactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [contactFormRef]);

  const openPurchaseIntent = () => {
    if (purchaseIntents.length > 0 && !purchaseIntentDropdownIsOpen) {
      openPurchaseIntentDropdown(true);
    } else {
      dispatch(open());
    }
  };

  const handleOpenTimifyModal = () => {
    dispatch(toggleTimifyModal(true));
    dispatch(
      setPropertyMeta({
        vonpollPropertyId: propertyId,
        vonpollUserId: userId || '',
      })
    );
  };

  const closePurchaseIntentDropdown = () => {
    openPurchaseIntentDropdown(false);
  };

  useEffect(() => {
    if (purchaseIntents.length === 0) {
      openPurchaseIntentDropdown(false);
    }
  }, [purchaseIntents]);

  return (
    <Row
      isWrap
      alignItems={'center'}
      margin={`12px 0 ${isOpenAccordion ? '0 0' : '12px 12px'}`}
      justifyContent={'space-between'}
    >
      {/* <SelectWithArrow */}
      {/*  isShortcut={!isOpenAccordion} */}
      {/*  isOpen={isSelectOpen} */}
      {/*  setIsOpen={setIsSelectOpen} */}
      {/*  closeDropdown={closeDropdown} */}
      {/*  selectButtonStyles={ */}
      {/*    isOpenAccordion ? { padding: '12px 14px' } : { padding: '8px' } */}
      {/*  } */}
      {/*  onClick={handleOpenTimifyModal} */}
      {/*  innerLabel={t('expose-page.head-info.button.make-an-appointment')} */}
      {/*  selectStyles={{ */}
      {/*    marginRight: '8px', */}
      {/*    marginBottom: isOpenAccordion ? '12px' : '0', */}
      {/*  }} */}
      {/*  iconStyles={isOpenAccordion ? { margin: '0 6px 0 0' } : { margin: '0' }} */}
      {/*  arrowIconStyles={ */}
      {/*    isOpenAccordion ? { margin: '0 0 0 6px' } : { display: 'none' } */}
      {/*  } */}
      {/* /> */}
      <ExposeFeedbackButton
        label={isOpenAccordion ? t('expose-overlay.contact-form.button') : ''}
        onClick={scrollToContactForm}
        color={theme.blue}
        borderColor={theme.blue}
        icon={Calendar}
        iconStyle={
          isOpenAccordion
            ? undefined
            : { margin: '0', width: '16px', height: '16px' }
        }
        isShortcut={!isOpenAccordion}
        style={{
          padding: isOpenAccordion ? '12px' : '8px',
          marginBottom: isOpenAccordion ? '12px' : '0',
          marginRight: isOpenAccordion ? 0 : '8px',
        }}
      />
      {purchaseIntentDropdownIsOpen ? (
        <PurchaseIntentDropdown
          isOpenAccordion={isOpenAccordion}
          refetch={refetch}
          purchaseIntents={purchaseIntents}
          openPurchaseIntent={openPurchaseIntent}
          closePurchaseIntentDropdown={closePurchaseIntentDropdown}
        />
      ) : (
        <PurchaseOfferButton
          isShortcut={!isOpenAccordion}
          style={{
            padding: isOpenAccordion ? '12px' : '8px',
            marginBottom: isOpenAccordion ? '12px' : '0',
          }}
          label={
            isOpenAccordion
              ? t('expose-page.head-info.button.interest-in-buying')
              : ''
          }
          icon={CoinsIcon}
          onClick={openPurchaseIntent}
          iconStyle={isOpenAccordion ? undefined : { margin: '0' }}
          iconWidth={20}
          iconHeight={20}
        />
      )}
    </Row>
  );
};

export { Buttons };
