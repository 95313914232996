import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Container } from './auth-container-styles';
import { LoginRegisterScreen } from '../../../../components/fullscreen-modal/auth-container/login-register-screen/login-register-screen';
import { useAppSelector } from '../../../../../common/hooks';
import { OnlineRegisterContainer } from './register-container/online-register-container';
import { OnlineLoginContainer } from './login-container/online-login-container';
import { ForgotPasswordContainer } from '../../../../components/fullscreen-modal/auth-container/forgot-password-container/forgot-password-container';
import { ValuationType } from '../../../../../../generated';
import { PersonalLoginContainer } from './login-container/personal-login-container';
import { PersonalRegisterContainer } from './register-container/personal-register-container';
import {
  toggleLoginForm,
  toggleRegisterForm,
} from '../../../redux/valuationWizardV2Slice';

const information = [
  'property-valuation.form.redesign.your-benefits.first',
  'property-valuation.form.redesign.your-benefits.second',
  'property-valuation.form.redesign.your-benefits.third',
  'property-valuation.form.redesign.your-benefits.fourth',
];

const AuthContainer: FC = () => {
  const dispatch = useDispatch();

  const isRegisterFormOpen = useAppSelector(
    (state) => state.valuationWizardV2.isRegisterFormOpen
  );
  const isLoginFormOpen = useAppSelector(
    (state) => state.valuationWizardV2.isLoginFormOpen
  );
  const isForgotPassword = useAppSelector(
    (state) => state.valuationWizardV2.isForgotPassword
  );
  const valuationType = useAppSelector(
    (state) => state.valuationWizardV2.valuationType
  );

  const onOpenLogin = useCallback(() => {
    dispatch(toggleLoginForm(true));
  }, [dispatch]);
  const onOpenRegister = useCallback(() => {
    dispatch(toggleRegisterForm(true));
  }, [dispatch]);

  const isPersonal = useMemo(
    () => valuationType === ValuationType.Personal,
    [valuationType]
  );
  const loginContainer = useMemo(
    () => (isPersonal ? <PersonalLoginContainer /> : <OnlineLoginContainer />),
    [isPersonal]
  );
  const registerContainer = useMemo(
    () =>
      isPersonal ? <PersonalRegisterContainer /> : <OnlineRegisterContainer />,
    [isPersonal]
  );

  return (
    <Container>
      {!isRegisterFormOpen && !isLoginFormOpen && !isForgotPassword && (
        <LoginRegisterScreen
          registerButtonLabel="property-valuation.form.redesign.to-register-button.label"
          loginButtonLabel="property-valuation.form.redesign.to-login-button.label"
          infoTitle="property-valuation.form.redesign.your-benefits.title"
          information={information}
          onOpenLogin={onOpenLogin}
          onOpenRegister={onOpenRegister}
          isShowTabletExpertImg
        />
      )}
      {isRegisterFormOpen && registerContainer}
      {isLoginFormOpen && loginContainer}
      {isForgotPassword && <ForgotPasswordContainer />}
    </Container>
  );
};

export { AuthContainer };
