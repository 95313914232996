import { AnchorHTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import { BaseLinkButton } from './base-link-button';

const DarkBlueGhostButtonBase = styled(BaseLinkButton)`
  border: solid 2px ${({ theme }) => theme.blue};
  background-color: transparent;
  color: ${({ theme }) => theme.blue};

  &:hover {
    background-color: rgba(0, 48, 94, 0.2);
  }
`;

interface IProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  label: string;
  fluid?: boolean;
}

const DarkBlueGhostLinkButton: FC<IProps> = ({
  label,
  fluid,
  onClick = () => {},
  target = '_blank',
  ...rest
}) => {
  return (
    <DarkBlueGhostButtonBase
      fluid={fluid}
      onClick={onClick}
      target={target}
      {...rest}
    >
      {label}
    </DarkBlueGhostButtonBase>
  );
};

export { DarkBlueGhostLinkButton };
