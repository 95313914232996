import styled, { ThemeContext } from 'styled-components';
import { CSSProperties, FC, useContext } from 'react';

import Icon from './ui/icon';
import { Headline2, ParagraphText } from './ui/typography';
import { device } from '../../../style/theme';
import { MainButton } from './ui/buttons';
import { useAppSelector } from '../hooks';

const Container = styled.div`
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 24px;

  @media ${device.tablet} {
    padding: 12px;
  }
`;

const Title = styled(Headline2)`
  font-size: 20px;
  line-height: 1.6;
  margin-top: 12px;
  text-align: center;

  @media ${device.mobile} {
    font-size: 16px;
  }
`;

const Description = styled(ParagraphText)`
  text-align: center;
  margin: 8px 0 12px 0;

  @media ${device.mobile} {
    font-size: 12px;
  }
`;

interface IProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  title: string;
  description?: string;
  ctaLabel?: string;
  onCtaClick?: () => void;
  containerStyle?: CSSProperties;
}

const EmptyStatePlaceholder: FC<IProps> = ({
  icon,
  title,
  description,
  ctaLabel,
  onCtaClick,
  containerStyle,
}) => {
  const themeContext = useContext(ThemeContext);
  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  return (
    <Container style={containerStyle}>
      <Icon
        icon={icon}
        color={themeContext.blue}
        style={{ width: '40px', height: '40px' }}
      />
      <Title content={title} />
      {description && <Description content={description} />}
      {onCtaClick && (
        <MainButton
          label={ctaLabel ?? ''}
          onClick={onCtaClick}
          isDisabled={isBrokerView}
        />
      )}
    </Container>
  );
};

export default EmptyStatePlaceholder;
