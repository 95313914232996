import { ReactComponent as Lock2 } from 'assets/streamline-light/interface-essential/lock-unlock/lock-2.svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from '../../../../style/theme';
import {
  toggleIsLoginModalOpen,
  toggleIsRegisterModalOpen,
} from '../../../auth/redux/authSlice';
import {
  MainButton,
  WhiteGhostButton,
} from '../../../common/components/ui/buttons';
import Icon from '../../../common/components/ui/icon';
import {
  Headline2,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { useAppDispatch } from '../../../common/hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  border-radius: 3px;
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.09);
  background-color: ${(props) => props.theme.logoBlue};
  @media ${device.tablet} {
    display: none;
  }
`;

const Title = styled(Headline2)`
  color: #fff;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
  white-space: nowrap;
`;

const Content = styled(ParagraphText)`
  color: #fff;
  text-align: center;
  margin-bottom: 24px;
`;

const ButtonRow = styled.div``;

const LoginButton = styled(WhiteGhostButton)`
  margin-right: 12px;
  padding: 12px 22px;
  text-transform: uppercase;
`;

const UnlockBlurredContentCard: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const toggleRegisterModal = () => dispatch(toggleIsRegisterModalOpen(true));
  const toggleLoginModal = () => dispatch(toggleIsLoginModalOpen(true));

  return (
    <Container>
      <Icon icon={Lock2} width={40} height={40} />
      <Title content={t('property-locked-page.unlock-modal.title')} />
      <Content content={t('property-locked-page.unlock-modal.content')} />
      <ButtonRow>
        <LoginButton
          id="login-property-locked-submit-button"
          label={t('modal.login.submit')}
          onClick={toggleLoginModal}
        />
        <MainButton
          id="register-property-locked-submit-butto"
          label={t('property-locked-page.unlock-modal.button')}
          onClick={toggleRegisterModal}
        />
      </ButtonRow>
    </Container>
  );
};

export { UnlockBlurredContentCard };
