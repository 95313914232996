import { FC } from 'react';
import { NextButton } from '../../../../../../components/common/next-button';
import { InputsContainer } from '../../../../../../components/fullscreen-modal/inputs-container/inputs-container';
import { BackButtonContainer } from '../../../../common/back-button-container';
import { useThirdStepLogic } from '../../../../../hooks/useStepsLogic';
// import { IFinancingForm } from '../../../../../interface';

interface IProps {
  // onSubmit?: (formData: IFinancingForm, ownerId?: string) => Promise<void>;
  lastStepCallback?(): void;
}

const ThirdStep: FC<IProps> = ({
  // onSubmit,
  lastStepCallback,
}) => {
  const { inputs, watchedKeys, onNextClick } = useThirdStepLogic({
    // onSubmit,
    lastStepCallback,
  });
  return (
    <>
      <InputsContainer inputs={inputs} />

      <BackButtonContainer marginTop={0} />
      <NextButton
        buttonCallback={onNextClick}
        watchedKeys={watchedKeys}
        isFinancing
        label={'property-valuation.form.redesign.next-button'}
      />
    </>
  );
};

export { ThirdStep };
