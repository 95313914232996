import { FC, memo, useCallback, useEffect } from 'react';
import { batch } from 'react-redux';
import { useParams } from 'react-router';

import {
  CreatePropertyInput,
  RegistrationBy,
  useReadPropertiesQuery,
} from '../../../../../../generated';
import {
  useConvertPropertyMutation,
  useCreatePropertyDossierMutation,
  useLazyPropertyValuationQuery,
  usePropertiesQuery,
  useUpdatePropertyMutation,
} from '../../../../../../services/graphql/enhanced';
import { toggleLoading as toggleLoadingAuth } from '../../../../../auth/redux/authSlice';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { disableBodyOverflow } from '../../../../../common/utils/disable-body-overflow';
import { FORM_STEPS } from '../../../constants';
import { FormError } from '../../../../interface';
import { IValuationForm } from '../../../interface';
import {
  clearErrors,
  closeValuationWizardAction,
  resetValuationWizard,
  returnToFirstStep,
  setErrors,
  setRedirect,
  setTemporaryValuations,
  toggleLoading,
  toggleLoginForm,
  toggleRegisterForm,
  updateFormStateAction,
} from '../../../redux/valuationWizardSlice';
import {
  resetFilters,
  clearSellerPropertiesCache,
} from '../../../../../seller/redux/sellerPropertiesSlice';
import { removeAllMarkersExceptHome } from '../../../../../map/redux/mapSlice';
import { buildTimeSeries } from '../../../../utils/build-time-series';
import { parseValuationInput } from '../../../../utils/parse-valuation-input';
import { FormContainer } from '../../../../components/common/form-container';
import { VisibilityWrapper } from '../../../../components/common/visibility-wrapper';
import { ValuationForm } from '../../forms/valuation-form';
import { ValuationLoader } from '../../../../components/common/loader';
import { OnlineLoginContainer } from './online-login-container';
import { OnlineRegisterContainer } from './online-register-container';
import { useLngHistoryPush } from '../../../../../localization/lng-history-push';
import { useCreateProperty } from '../../../hooks/useCreateProperty';
import { siteMap } from '../../../../../../routes/site-map';
import { useGetPortalType } from '../../../../../landing/hooks/useGetPortalType';
import { useActualExperiment } from '../../../../../experiments/hooks/useActualExperiment';

interface IProps {
  isOnLandingPage?: boolean;
  regionalFirstStepNext?: () => void;
  regionalSecondStepBack?: () => void;
  activeStep?: number;
  mobileCompact?: boolean;
  toMobileCompact?: () => void;
}

const OnlineValuationContainerBase: FC<IProps> = ({
  isOnLandingPage,
  regionalFirstStepNext,
  regionalSecondStepBack,
  activeStep,
  mobileCompact,
  toMobileCompact,
}) => {
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const lngHPush = useLngHistoryPush();
  const { propertyId } = useParams<{ propertyId: string }>();
  const { isSeller } = useGetPortalType();

  const userId = useAppSelector((state) => state.auth.user?._id);
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const owner = useAppSelector((state) => state.auth.user?._id);
  const userRegistrationBy = useAppSelector(
    (state) => state.auth.user?.registrationBy
  );

  const selectedAddress = useAppSelector(
    (state) => state.valuationWizard.selectedAddress
  );
  const activeFormStep = useAppSelector(
    (state) => state.valuationWizard.activeStep
  );
  const isRegisterFormOpen = useAppSelector(
    (state) => state.valuationWizard.isRegisterFormOpen
  );
  const isLoginFormOpen = useAppSelector(
    (state) => state.valuationWizard.isLoginFormOpen
  );
  const isLoading = useAppSelector((state) => state.valuationWizard.isLoading);
  const valuationWizardErrors = useAppSelector(
    (state) => state.valuationWizard.errors
  );
  const userInput = useAppSelector((state) => state.valuationWizard.userInput);
  const isRedirect = useAppSelector(
    (state) => state.valuationWizard.isRedirect
  );
  const { isBCase } = useActualExperiment();
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const { data: userProperties, isLoading: areUserPropertiesLoading } =
    usePropertiesQuery({ ownerId: owner }, { skip: !owner || isAnonymousUser });

  const { refetch } = useReadPropertiesQuery(
    {
      filter: {
        ownerId: userId,
      },
      pagination: {
        offset: 0,
        limit: 8,
      },
      calculateTotalInfo: true,
    },
    {
      skip: !userId || isAnonymousUser,
    }
  );

  // Mutations
  const [convertProperty] = useConvertPropertyMutation();
  const [updateProperty] = useUpdatePropertyMutation();
  const [createPropertyDossier] = useCreatePropertyDossierMutation();
  const [valuateProperty, { data: valuationData, error: valuationError }] =
    useLazyPropertyValuationQuery();
  const { createProperty } = useCreateProperty();

  const onCreateProperty = useCallback(
    async (formData: IValuationForm, ownerId?: string): Promise<void> => {
      if (selectedAddress && formData) {
        batch(() => {
          dispatch(toggleLoading(true));
          dispatch(toggleLoadingAuth(true));
          dispatch(clearErrors());
          dispatch(updateFormStateAction(formData));
        });

        const valuationInput = parseValuationInput(
          valuationWizardErrors ? (userInput as IValuationForm) : formData,
          selectedAddress
        ) as CreatePropertyInput;

        const { dealType, countryCode, propertyData } = valuationInput;

        // Case B valuation
        if (!ownerId && isBCase) {
          valuateProperty({
            input: {
              dealType,
              countryCode,
              propertyData,
              valuationDates: buildTimeSeries(1, 0),
            },
          });
          return;
        }

        // Create property
        if (ownerId) {
          try {
            const createPropertyResponse = await createProperty({
              valuationInput,
              userId: ownerId,
            });
            if (createPropertyResponse?.createProperty?._id) {
              try {
                const createPropertyDossierResponse =
                  await createPropertyDossier({
                    input: {
                      propertyId: createPropertyResponse.createProperty._id,
                    },
                  }).unwrap();
                if (isIframe) {
                  window.open(
                    `${window.location.origin}/property/${createPropertyDossierResponse?.createPropertyDossier?._id}`,
                    '_blank',
                    'noopener,noreferrer'
                  );
                  dispatch(returnToFirstStep());
                } else if (
                  createPropertyDossierResponse.createPropertyDossier?._id &&
                  !isOnLandingPage &&
                  !isSeller
                ) {
                  lngHPush(
                    `${siteMap.PropertyPage.pathWithoutParams}/${createPropertyDossierResponse.createPropertyDossier._id}`
                  );
                } else if (isSeller) {
                  dispatch(resetFilters());
                  dispatch(clearSellerPropertiesCache());
                  dispatch(removeAllMarkersExceptHome());
                  refetch();
                  lngHPush(siteMap.SellerPropertiesPage.pathWithoutParams);
                }

                if (!isIframe) {
                  dispatch(resetValuationWizard());
                }
              } catch (error: unknown) {
                batch(() => {
                  dispatch(toggleLoading(false));
                  dispatch(toggleLoadingAuth(false));
                  dispatch(setErrors(error as FormError));
                });
              }
            }
          } catch (error: unknown) {
            batch(() => {
              dispatch(toggleLoading(false));
              dispatch(toggleLoadingAuth(false));
              dispatch(setErrors(error as FormError));
            });
          } finally {
            dispatch(toggleLoading(false));
            dispatch(toggleLoadingAuth(false));
          }
        }
      }
    },
    [
      selectedAddress,
      valuationWizardErrors,
      userInput,
      isBCase,
      dispatch,
      valuateProperty,
      createProperty,
      createPropertyDossier,
      isIframe,
      isOnLandingPage,
      isSeller,
      lngHPush,
      refetch,
    ]
  );

  const switchToRegisterForm = useCallback(() => {
    dispatch(toggleRegisterForm(true));
  }, [dispatch]);

  const switchToLoginForm = useCallback(() => {
    dispatch(toggleRegisterForm(false));
    dispatch(toggleLoginForm(true));
  }, [dispatch]);

  // Anonymous user valuation which leads to locked page
  useEffect(() => {
    if (isAnonymousUser && valuationData) {
      batch(() => {
        dispatch(setTemporaryValuations(valuationData.propertyValuation));
        dispatch(closeValuationWizardAction());
        dispatch(toggleLoading(false));
      });
      lngHPush(siteMap.PropertyLockedPage.path);
    } else if (valuationError) {
      batch(() => {
        dispatch(setErrors(valuationError));
        dispatch(toggleLoading(false));
      });
    }
  }, [dispatch, valuationData, valuationError, isAnonymousUser, lngHPush]);

  useEffect(() => {
    const convertAsync = async () => {
      if (!areUserPropertiesLoading) {
        try {
          dispatch(toggleLoading(true));
          if (userRegistrationBy === RegistrationBy.Mobile) {
            const propertyResponse = await convertProperty({
              ownerId: owner as string,
            }).unwrap();

            if (propertyResponse?.convertProperty?._id) {
              const createPropertyDossierResponse = await createPropertyDossier(
                {
                  input: {
                    propertyId: propertyResponse?.convertProperty?._id,
                  },
                }
              ).unwrap();

              if (createPropertyDossierResponse.createPropertyDossier?._id) {
                lngHPush(
                  `${siteMap.PropertyPage.pathWithoutParams}/${createPropertyDossierResponse.createPropertyDossier._id}`
                );
              }
            }
          }
          dispatch(resetValuationWizard());
        } catch (error) {
          dispatch(setErrors(error as FormError));
        } finally {
          dispatch(toggleLoading(false));
        }
      }
    };
    if (isRedirect) {
      dispatch(setRedirect(false));
      convertAsync();
    }
  }, [
    dispatch,
    isRedirect,
    userInput,
    selectedAddress,
    convertProperty,
    owner,
    createPropertyDossier,
    lngHPush,
    userRegistrationBy,
    updateProperty,
    propertyId,
    areUserPropertiesLoading,
    userProperties?.properties,
  ]);

  useEffect(() => {
    if (isMobileSize) {
      if (mobileCompact) {
        if (activeStep && activeStep > 0) {
          disableBodyOverflow();
        }
      } else {
        disableBodyOverflow();
      }
    }
    return () => {
      if (isMobileSize) {
        if (mobileCompact) {
          if (activeStep && activeStep > 0) {
            disableBodyOverflow();
          }
        } else {
          disableBodyOverflow();
        }
      }
    };
  }, [isMobileSize, activeStep, mobileCompact]);

  if (isBCase || owner) {
    return (
      <FormContainer>
        {isLoading ? (
          <ValuationLoader />
        ) : (
          <ValuationForm
            errors={valuationWizardErrors}
            stepsCount={FORM_STEPS}
            activeFormStep={activeFormStep}
            onSubmit={onCreateProperty}
            submitForm
          />
        )}
      </FormContainer>
    );
  }

  return (
    <FormContainer
      mobileCompact={mobileCompact}
      activeStep={activeStep}
      hasCleanInterface={isIframe}
    >
      <VisibilityWrapper
        isVisible={!isRegisterFormOpen && !isLoginFormOpen}
        isiframe={isIframe}
      >
        {isLoading ? (
          <ValuationLoader />
        ) : (
          <ValuationForm
            mobileCompact={mobileCompact}
            toMobileCompact={toMobileCompact}
            regionalFirstStepNext={regionalFirstStepNext}
            regionalSecondStepBack={regionalSecondStepBack}
            errors={valuationWizardErrors}
            stepsCount={FORM_STEPS}
            activeFormStep={activeFormStep}
            submitForm={false}
            lastStepCallback={switchToRegisterForm}
          />
        )}
      </VisibilityWrapper>
      {isRegisterFormOpen && (
        <OnlineRegisterContainer openLogin={switchToLoginForm} />
      )}
      {isLoginFormOpen && <OnlineLoginContainer onSuccess={onCreateProperty} />}
    </FormContainer>
  );
};

const OnlineValuationContainer = memo(OnlineValuationContainerBase);

export { OnlineValuationContainer };
