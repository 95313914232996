import styled from 'styled-components';
import {
  Headline2,
  Headline3,
} from '../../../../common/components/ui/typography';
import { MainButton } from '../../../../common/components/ui/buttons';

export const Container = styled.div<{ isBlurred?: boolean }>`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  margin-bottom: 16px;
  background-color: ${(props) => props.theme.white};
  ${(props) => props.isBlurred && 'filter: blur(6px)'};
`;

export const Title = styled(Headline2)`
  text-align: center;
`;

export const Description = styled(Headline3)`
  text-align: center;
  margin: 8px 0 16px 0;
  font-size: 16px;
  line-height: 1.25;
`;

export const NextStepButton = styled(MainButton)`
  display: flex;
  justify-content: center;
`;
