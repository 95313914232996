import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Person } from 'assets/streamline-light/users/natural-close-up-single-user-neutral/single-neutral.svg';
import { ReactComponent as Key } from 'assets/streamline-light/interface-essential/login-logout/login-key-1.svg';
import Property1 from '../../../../../../../../../assets/images/icons/search-profile-form/property-1.png';
import Property2 from '../../../../../../../../../assets/images/icons/search-profile-form/property-2.png';
import {
  MainButton,
  SecondaryButton,
} from '../../../../../../../../common/components/ui/buttons';
import Icon from '../../../../../../../../common/components/ui/icon';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../../../../common/hooks/useIsMobileSize';
import {
  toggleLoginForm,
  toggleRegisterForm,
} from '../../../../../../redux/searchProfileSlice';
import PropertyCard from '../../../../../common/suggested-properties/property-card';

import {
  Container,
  Row,
  IconContainer,
  IconBox,
  Title,
  Description,
  ThesisBox,
  Thesis,
  Dot,
  SeparatorBox,
  Separator,
  MobileTitle,
  DescriptionBox,
  PropertiesBox,
} from './description-styles';

const thesis = [
  'search-profile.form.auth.thesis1',
  'search-profile.form.auth.thesis2',
  'search-profile.form.auth.thesis3',
  'search-profile.form.auth.thesis4',
];

const DEFAULT_PROPERTIES = [
  {
    title: 'EIGENNUTZER & KAPITALANLEGER AUFGEPASST: Apartment mit kleiner...',
    src: Property1,
    description: '445.000 €, 5 Zimmer, ca. 119.96 m²',
  },
  {
    title: 'TRÈS CHIC - WOHNEN IM FRANZÖSISCHEN VIERTEL IN HAID...',
    src: Property2,
    description: '195.000 €, 3 Zimmer, ca. 95 m²',
  },
  {
    title: 'EIGENNUTZER & KAPITALANLEGER AUFGEPASST: Apartment mit kleiner...',
    src: Property1,
    description: '445.000 €, 5 Zimmer, ca. 119.96 m²',
  },
];

const DescriptionContainer: FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialSuggestedPropertiesData: any;
}> = ({ initialSuggestedPropertiesData }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const storedAddress = useAppSelector(
    (state) => state.searchProfile.selectedAddress
  );

  const isMobileSize = useIsMobileSize();

  return (
    <Container>
      <Row>
        {isMobileSize ? (
          <>
            <MobileTitle>
              {t('search-profile.info-container.message.title')}:
            </MobileTitle>
            <DescriptionBox
              dangerouslySetInnerHTML={{
                __html: t('search-profile.info-container.message.text-loaded', {
                  propertiesCount:
                    initialSuggestedPropertiesData?.data?.suggestedProperties
                      ?.pageData?.count || 0,
                }),
              }}
            />
            <PropertiesBox>
              {(Array.isArray(
                initialSuggestedPropertiesData?.data?.suggestedProperties?.page
              ) &&
              initialSuggestedPropertiesData?.data?.suggestedProperties?.page
                .length > 0
                ? initialSuggestedPropertiesData?.data?.suggestedProperties
                    ?.page
                : DEFAULT_PROPERTIES
              )
                .slice(0, 3)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .map((property: any, idx: number) => {
                  return (
                    <PropertyCard
                      {...property}
                      image={
                        Array.isArray(property.image)
                          ? property.image[0]?.name
                          : property.image
                      }
                      fallbackImg={
                        DEFAULT_PROPERTIES[
                          Math.min(
                            idx,
                            Math.max(DEFAULT_PROPERTIES.length - idx, 0)
                          )
                        ].src
                      }
                      location={
                        storedAddress?.place_name
                          ?.split(',')
                          ?.slice(0, 2)
                          ?.join(',') || ''
                      }
                    />
                  );
                })}
            </PropertiesBox>
          </>
        ) : (
          <>
            <IconContainer>
              <Icon icon={Person} color={'#00305E'} width={26} height={26} />
              <IconBox>
                <Icon icon={Key} color={'#00305E'} width={20} height={20} />
              </IconBox>
            </IconContainer>
            <Title>{t('search-profile.form.auth.title')}</Title>
            <Description>
              {t('search-profile.form.auth.description')}
            </Description>
            {thesis.map((item) => (
              <ThesisBox key={item}>
                <Dot />
                <Thesis dangerouslySetInnerHTML={{ __html: t(item) }} />
              </ThesisBox>
            ))}
          </>
        )}
        <MainButton
          onClick={(e) => {
            e?.preventDefault();
            dispatch(toggleRegisterForm(true));
          }}
          style={{
            justifyContent: 'center',
            marginTop: isMobileSize ? 24 : 12,
          }}
          label={t('search-profile.form.auth.check-property-offer')}
        />
        <SeparatorBox>
          <Separator />
          <span>{t('search-profile.form.auth.already-active')}</span>
          <Separator />
        </SeparatorBox>
        <SecondaryButton
          borderColor={'#00305E'}
          highlightColor={'#00305E'}
          color={'#00305E'}
          style={{ marginBottom: isMobileSize ? 8 : 0 }}
          label={t('search-profile.form.auth.login-and-continue')}
          onClick={() => {
            dispatch(toggleLoginForm(true));
          }}
        />
      </Row>
    </Container>
  );
};

export default DescriptionContainer;
