import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const ApartmentIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={
          'M.5 23.5h23m-16 0v-18a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v18m6 0V14a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v9.5m-13 0V14a.5.5 0 0 0-.5-.5H2a.5.5 0 0 0-.5.5v9.5m3-10v-1m15 1v-1m-6-8V3a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5v1.5m2-2v-2m-3 7h5m-5 3h5m-5 3h5m-5 3h5m-5 3h5'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { ApartmentIcon };
