import ConfirmInterest from '../components/forms/common/ConfirmInterest';
import LegalAdditionalStep from '../components/forms/legal/LegalAdditionalStep';
import LegalFirstStep from '../components/forms/legal/LegalFirstStep';
import LegalSecondStep from '../components/forms/legal/LegalSecondStep';
import PhysicalAdditionalStep from '../components/forms/physical/PhysicalAdditionalStep';
import PhysicalFirstStep from '../components/forms/physical/PhysicalFirstStep';

export const stepDescriptions = {
  'physical-first-step': 'purchase-intent.form.physical.firstStepTitle',
  'physical-additional-step':
    'purchase-intent.form.physical.additionalStepTitle',
  'physical-second-step': 'purchase-intent.form.common.confirmInterestTitle',

  'legal-first-step': 'purchase-intent.form.legal.firstStepTitle',
  'legal-second-step': 'purchase-intent.form.legal.secondStepTitle',
  'legal-additional-step': 'purchase-intent.form.legal.secondStepTitle',
  'legal-third-step': 'purchase-intent.form.common.confirmInterestTitle',
};

export const stepsComponents = {
  'physical-first-step': PhysicalFirstStep,
  'physical-additional-step': PhysicalAdditionalStep,
  'physical-second-step': ConfirmInterest,

  'legal-first-step': LegalFirstStep,
  'legal-second-step': LegalSecondStep,
  'legal-additional-step': LegalAdditionalStep,
  'legal-third-step': ConfirmInterest,
};
