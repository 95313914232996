import { FC, memo } from 'react';

import { VALUATION_FORM_KEYS } from '../../../../../constants';
import { BoxSelector } from '../../../../../../components/common/box-selector';
import { useSecondStepLogic } from '../../../../../hooks/useStepsLogic';
import { BackButtonContainer } from '../../../../common/back-button-container';

const SecondStepBase: FC = () => {
  const { boxes, iframePropertyType, onClickNext, isBackButton } =
    useSecondStepLogic();

  return (
    <>
      <BoxSelector
        name={VALUATION_FORM_KEYS.SUBCODE}
        onClick={onClickNext}
        boxes={boxes}
        isWrap
        minWidthBox={iframePropertyType ? 130 : 178}
        boxMaxWidth={iframePropertyType ? 133 : 178}
        isBoxTabletColumn
      />
      {isBackButton && <BackButtonContainer marginTop={12} />}
    </>
  );
};

const SecondStep = memo(SecondStepBase);

export { SecondStep };
