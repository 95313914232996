import { easeCubic } from 'd3-ease';
import { FC, memo, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlyToInterpolator } from 'react-map-gl';
import { batch } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';

import { BorderButton } from '../../../../../../../common/components/ui/buttons';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../../../common/hooks/useIsMobileSize';
import { Germany } from '../../../../../../../map/constants/points-of-interest';
import {
  changeViewport,
  setMarkers,
  setSettings,
} from '../../../../../../../map/redux/mapSlice';
import {
  nextStepAction,
  prevStepAction,
  toggleLoading,
} from '../../../../../redux/searchProfileSlice';
import { ISearchProfileForm } from '../../../../../interface';
import {
  REDESIGNED_SEARCH_CRITERIA,
  // SEARCH_CRITERIA,
  SEARCH_PROFILE_FORM_KEYS,
} from '../../../../../constants';
import { InputRange } from '../../../../../../components/common/input-range/input-range';
import { Container, Row, RegisterButton } from './second-step-styles';
import { fields } from './constants';
import { useValidateSecondStepFields } from '../../../../../hooks/useValidateSecondStepFields';
import { defaultMapSettings } from '../../../../../../../map/constants';

interface IProps {
  regionalSecondStepBack?: () => void;
  submitForm?: boolean;
  onSubmit?: (formData: ISearchProfileForm, ownerId?: string) => void;

  lastStepCallback?(): void;
  isRedesign?: boolean;
}

const SecondStep: FC<IProps> = ({
  regionalSecondStepBack,
  submitForm,
  onSubmit,
  lastStepCallback,
  isRedesign,
}) => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const [isSubmit, setIsSubmit] = useState(false);

  const userInput = useAppSelector((state) => state.searchProfile.userInput);

  const owner = useAppSelector((state) => state.auth.user?._id);
  const [
    // purchasePriceMin,
    purchasePriceMax,
    livingAreaMin,
    // livingAreaMax,
    landAreaMin,
    // landAreaMax,
    numberOfRoomsMin,
    // numberOfRoomsMax,
    numberOfBathroomsMin,
    // numberOfBathroomsMax,
    code,
  ] = useWatch({
    name: [...fields, SEARCH_PROFILE_FORM_KEYS.CODE],
  });

  const {
    formState: { errors: formErrors },
  } = useFormContext();

  const isValid = useValidateSecondStepFields({
    purchasePriceMax,
    livingAreaMin,
    // livingAreaMax,
    landAreaMin,
    // landAreaMax,
    numberOfRoomsMin,
    // numberOfRoomsMax,
    numberOfBathroomsMin,
    // numberOfBathroomsMax,
    code,
  });

  // const availableOptions = SEARCH_CRITERIA.filter((item) =>
  //   item.availableFor.includes(code)
  // );
  const availableOptions = REDESIGNED_SEARCH_CRITERIA.filter((item) =>
    item.availableFor.includes(code)
  );

  const availableFields: string[] = [];
  availableOptions.forEach(({ nameMax, nameMin }) => {
    if (nameMax) {
      availableFields.push(nameMax);
    } else if (nameMin) {
      availableFields.push(nameMin);
    }
  });

  const toggleFormStep = () => {
    dispatch(toggleLoading(true));

    // TODO: GET PROPERTIES HERE

    setTimeout(() => {
      dispatch(nextStepAction());
      dispatch(toggleLoading(false));
    }, 3000);
  };

  // Clear markers and selected address
  const switchToPrevStep = useCallback(
    (e?: MouseEvent) => {
      e?.preventDefault();

      if (regionalSecondStepBack) {
        regionalSecondStepBack();
        return;
      }

      batch(() => {
        dispatch(
          changeViewport({
            ...Germany,
            transitionDuration: 'auto',
            transitionInterpolator: new FlyToInterpolator({
              speed: 1.8,
            }),
            transitionEasing: easeCubic,
          })
        );
        dispatch(setSettings(defaultMapSettings));
        dispatch(setMarkers([]));
        dispatch(prevStepAction());
      });
    },
    [dispatch, regionalSecondStepBack]
  );

  const handleSubmit = useCallback(() => {
    setIsSubmit(true);
  }, []);

  // get latest values from userInput
  useEffect(() => {
    if (onSubmit && isSubmit) {
      onSubmit(userInput as ISearchProfileForm, owner);
      setIsSubmit(false);
    }
  }, [isSubmit, onSubmit, owner, userInput]);

  return (
    <Container>
      <Row>
        {availableOptions.map((item) => {
          return (
            <InputRange
              key={item.typeName}
              nameMin={item.nameMin}
              nameMax={item.nameMax}
              label={item.label}
              subLabel={item.subLabel}
              placeholderMin={item.placeholderMin}
              placeholderMax={item.placeholderMax}
              type="number"
              defaultValues={item.defaultValues}
              rules={item.rules}
              inputAdornment={item.inputAdornment}
              isSeparate={item.typeName === 'Kaufpreis'}
              inputMode={'numeric'}
            />
          );
        })}
      </Row>
      <Row isiframe={isIframe}>
        {(!isMobileSize || isIframe) && (
          <BorderButton
            id="step-2"
            label={t('button.back')}
            onClick={switchToPrevStep}
          />
        )}
        <RegisterButton
          // key={ratingReason}
          id="register-wizard-button"
          watchedKeys={availableFields}
          label={t('button.next')}
          buttonCallback={
            submitForm
              ? onSubmit && handleSubmit
              : isRedesign
              ? toggleFormStep
              : lastStepCallback
          }
          outerDisable={formErrors && Object.keys(formErrors).length > 0}
          isSearchProfile
        />
      </Row>
    </Container>
  );
};

export { SecondStep };
