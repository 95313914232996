import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import financingIcon1 from 'assets/images/icons/financing-landing-page/financing-icon-1.png';
import financingIcon2 from 'assets/images/icons/financing-landing-page/financing-icon-2.png';
import { ReactComponent as ShieldIcon } from 'assets/streamline-light/programing-apps-websites/bug-security/shield-check.svg';

import { PrimaryCTAButton } from '../../../../common/components/ui/buttons';
import Icon from '../../../../common/components/ui/icon';
import {
  CTAContainer,
  RatingBox,
  RatingText,
  Star,
} from './cta-buttons-styles';
import { useOpenFinancing } from '../../../../forms/form-financing/hooks/callback-hooks/useOpenFinancing';
import { FinancingType } from '../../../../../generated';

const CTAButtons: FC = () => {
  const { t } = useTranslation();
  const { openFinancing } = useOpenFinancing({});

  return (
    <CTAContainer>
      <PrimaryCTAButton
        isFirst
        image={financingIcon1}
        title={t(
          'financing-landing-page.request-financing.label'
        ).toUpperCase()}
        description={t('financing-landing-page.request-financing.description')}
        onClick={() => openFinancing(FinancingType.Financing)}
      />
      <PrimaryCTAButton
        skipBorder
        image={financingIcon2}
        title={t(
          'financing-landing-page.receive-financing-certificate.label'
        ).toUpperCase()}
        description={t(
          'financing-landing-page.receive-financing-certificate.description'
        )}
        onClick={() => openFinancing(FinancingType.FinancingCertificate)}
      />
      <RatingBox>
        <Icon icon={ShieldIcon} width={12} height={14} color={'#fff'} />
        <RatingText>{t('financing-landing-page.description')}</RatingText>
        <Star />
        <Star />
        <Star />
        <Star />
        <Star />
      </RatingBox>
    </CTAContainer>
  );
};

export { CTAButtons };
