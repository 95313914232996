import styled from 'styled-components';
import { FC } from 'react';

interface IPhoneLink {
  phoneNumber: string;
}

const PhoneContainer = styled.a`
  margin: 0;
  display: inline-flex;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: ${(props) => props.theme.blue};
`;

const PhoneLink: FC<IPhoneLink> = ({ phoneNumber = '', children, ...rest }) => (
  <PhoneContainer href={`tel:${phoneNumber}`} {...rest}>
    {children}
  </PhoneContainer>
);

export { PhoneLink };
