import { FC } from 'react';
import {
  Container,
  ContentContainer,
  ImageBox,
  Title,
  Description,
  StyledImg,
  HeaderContainer,
  Headline,
  CardBox,
} from './advantages-styles';
import {
  Advantages as AdvantagesType,
  RpSection,
  useReadAdvantageQuery,
} from '../../../../../generated';

interface IProps {
  section: RpSection;
}

interface AdvantageProps {
  id: string;
}

interface AdvantageResponse {
  data: { data: AdvantagesType };
  isLoading: boolean;
}

const AdvantageItem = ({ id }: AdvantageProps) => {
  const { data, isLoading } = useReadAdvantageQuery<AdvantageResponse>({
    id,
  });

  if (isLoading) return null;

  const advantage = data?.data;

  return (
    <CardBox>
      <ImageBox>
        <StyledImg
          key={advantage?.iconKey as string}
          src={advantage?.iconUrl as string}
          alt={advantage?.iconAlt as string}
        />
      </ImageBox>
      <Title data-swiper-parallax="0" content={advantage?.name} />
      <Description
        data-swiper-parallax="0"
        dangerouslySetInnerHTML={{ __html: advantage?.text ?? '' }}
      />
    </CardBox>
  );
};

const Advantages: FC<IProps> = ({ section }) => {
  const { recordIds, title } = section;

  return (
    <Container>
      <HeaderContainer>
        <Headline content={title} />
      </HeaderContainer>
      <ContentContainer>
        {recordIds.map((id: string) => {
          return <AdvantageItem key={id} id={id} />;
        })}
      </ContentContainer>
    </Container>
  );
};

export { Advantages };
