import { FC } from 'react';

import { VerifyPhoneContainer } from '../../../../../../components/fullscreen-modal/auth-container/register-container/verify-phone-container/verify-phone-container';
import { useFinancingPhoneVerification } from '../../../../../hooks/callback-hooks/useFinancingPhoneVerification';

interface IProps {
  setErrors: (errors: unknown) => void;
}

const FinanceVerifyPhoneContainer: FC<IProps> = ({ setErrors }) => {
  const { formattedPhone, onVerifyPhone, verificationStatus } =
    useFinancingPhoneVerification({
      setErrors,
    });

  return (
    <VerifyPhoneContainer
      verificationStatus={verificationStatus}
      formattedPhone={formattedPhone}
      onVerifyPhone={onVerifyPhone}
    />
  );
};

export { FinanceVerifyPhoneContainer };
