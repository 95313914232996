import styled from 'styled-components';
import { device } from '../../../../style/theme';
import { Headline1, Headline2 } from '../../../common/components/ui/typography';
import {
  MainButton,
  SecondaryButton,
} from '../../../common/components/ui/buttons';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import Icon from '../../../common/components/ui/icon';

const BaseLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.blue};
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  &:first-of-type {
    border-top-left-radius: 3px;
  }

  &:last-of-type {
    border-bottom-right-radius: 3px;
  }
`;

const BaseMainButton = styled(MainButton)`
  text-transform: uppercase;
  height: 40px;
  padding: 10px;
  justify-content: center;

  @media ${device.mobile} {
    font-size: 11px;
  }
`;

const BaseSecondaryButton = styled(SecondaryButton)`
  text-transform: uppercase;
  height: 40px;
  padding: 10px;
  justify-content: center;

  &:hover {
    background-color: ${(props) =>
      hexToRGB(props.theme.ctaDisabledBackground, 0.2)};
  }

  @media ${device.mobile} {
    font-size: 11px;
  }
`;

export const Header = styled(Headline1)<{ isShrinked: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  ${({ isShrinked }) =>
    isShrinked &&
    `
    >div:last-child {
      margin-left: 0;
      flex: 1;
      justify-content: space-between;
    }
  `} @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Container = styled.div`
  padding: 36px 32px 32px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #fff;
  flex: 1.56;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  @media ${device.tablet} {
    margin-right: 0;
    padding: 16px;
  }
`;

export const LabelsContainer = styled.div`
  display: flex;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 1;
  height: 24px;
`;

export const MarketingLabel = styled(BaseLabel)``;

export const NewLabel = styled(BaseLabel)`
  background-color: ${(props) => props.theme.ctaBlue};
`;

export const SalesSimulationLabel = styled(BaseLabel)`
  background-color: ${(props) => props.theme.gold};
`;

export const SoldLabel = styled(BaseLabel)`
  background-color: ${(props) => props.theme.green};
`;

export const DeactivatedLabel = styled(BaseLabel)`
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  background-color: ${(props) => props.theme.white};
  border: ${(props) => `1px solid ${props.theme.ctaDisabledBackground}`};
`;

export const EvaluationButton = styled(BaseSecondaryButton)<{
  isVisible?: boolean;
}>`
  border-color: ${(props) => props.theme.ctaDisabledBackground};
  color: ${(props) => hexToRGB(props.theme.black, 0.5)};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  cursor: ${(props) => (props.isVisible ? 'pointer' : 'default')};
`;

export const RealDashboardButton = styled(BaseMainButton)``;

export const RealDashboardSoldButton = styled(BaseSecondaryButton)`
  border-color: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.blue};
`;

export const ExampleDashboardButton = styled(BaseSecondaryButton)`
  border-color: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.blue};
`;

export const StartSaleButton = styled(BaseMainButton)``;

export const SalesSimulationButton = styled(BaseMainButton)`
  background-color: ${(props) => props.theme.blue};

  &:hover {
    background-color: ${(props) => hexToRGB(props.theme.blue, 0.9)};
  }
`;

export const ActionPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
`;

export const PropertyDetailsContainer = styled.div<{ isShrinked?: boolean }>`
  flex: 1;
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) =>
    props.isShrinked
      ? 'repeat(2, minmax(0, 1fr))'
      : 'repeat(3, minmax(0, 140px))'};
  grid-row: 2;
  row-gap: 10px;
  margin-top: 14px;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-rows: 4;
  }
`;

export const ItemContainer = styled.div`
  padding: 0;
  margin: 0 6px 12px 6px;
  width: calc(50% - 12px);
  min-width: unset;
  position: relative;

  @media ${device.tablet} {
    width: 100%;
    margin: 0 0 12px 0;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ListContainer = styled.div`
  //display: flex;
  //flex-wrap: wrap;
  //width: 100%;
  //height: 100%;

  display: grid;
  //grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  grid-template-columns: minmax(calc(50% - 12px), 1fr) minmax(
      calc(50% - 12px),
      1fr
    );
  gap: 12px;

  @media ${device.tablet} {
    grid-template-columns: 100%;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContainer = styled.div<{
  isDisabled?: boolean;
}>`
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #fff;
  display: flex;
  flex-direction: column; // list
  width: 100%;
  overflow: hidden;
  max-height: unset;
  min-width: unset;
  padding: 12px;
`;

export const ImageWrapper = styled.div`
  height: 256px;
  padding-top: calc(152 / 306 * 100%);
  position: relative;
  width: unset;
  overflow: unset;
  border-radius: 3px;
  overflow: hidden;

  @media ${device.tablet} {
    height: 160px;
  }
`;

export const CardImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: unset;
`;

export const CardContent = styled.div`
  flex: 1;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
`;

export const Title = styled(Headline2)`
  margin-left: 6px;

  @media ${device.tablet} {
    margin-left: unset;
  }
`;

export const CardTitle = styled.h4`
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

export const Row = styled.div<{
  justifyContent?: string;
  margin?: string;
  alignItems?: string;
  padding?: string;
  isListView?: boolean;
  gap?: number;
}>`
  display: flex;
  align-self: stretch;
  ${({ gap }) => gap && `gap: ${gap}px`};

  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};

  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};

  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};

  border-right: 1px solid
    ${({ theme, isListView }) =>
      isListView ? theme.ctaDisabledBackground : 'transparent'};
`;

export const BottomButtonsWrapper = styled.div`
  display: flex;
  margin: 12px 0 0 0;
  gap: 12px;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const Tag = styled.div`
  padding: 2px 8px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: ${(props) => props.theme.white};
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};

  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;

  height: 20px;
  display: flex;
  align-items: center;
`;

export const Location = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;
  height: 20px;
  opacity: 0.8;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  margin-left: 6px;
`;

export const IconContainer = styled.div<{
  alignAtEnd?: boolean;
  disabled?: boolean;
}>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
  ${({ alignAtEnd }) =>
    alignAtEnd &&
    `
    > div {
      justify-content: flex-end
    }
  `}
`;

export const IconDigit = styled.div`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${(props) => props.theme.blue};
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  sup {
    font-size: 8px;
  }
`;

export const IconLabel = styled.div`
  margin-top: 4px;
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 1px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.6)} !important;
`;

export const LocationIcon = styled(Icon)`
  &&& > svg {
    max-height: 16px;
  }
`;

export const MapWrapper = styled.div<{ isMobileSize?: boolean }>`
  position: relative;
  height: ${(props) => (props.isMobileSize ? '160px' : '256px')};
  width: 100%;
  overflow: hidden;
  border-radius: 3px;
`;
