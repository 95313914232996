import { FC } from 'react';

import { SocialEconomics } from '../../../../../../common/components/social-economics/social-economics';
import { useExposeDetailsPriceHubble } from '../../../hooks/useExposeDetailsQueries';
import { useMapSocialEconomics } from './useMapSocialEconomics';
import { Property } from '../../../../../../../generated';

interface IProps {
  property: Partial<Property>;
}

const SocialEconomicsExposeDetailsWrapper: FC<IProps> = ({ property }) => {
  // const { socialEconomics, location } = useExposeDetailsPriceHubble();
  const { socioEconomics, propertyData } = property;
  useMapSocialEconomics({ location: propertyData?.location });

  return (
    <SocialEconomics
      socioEconomicsData={socioEconomics}
      isFullWidth
      isOnBuyerPortal
    />
  );
};

export { SocialEconomicsExposeDetailsWrapper };
