import styled from 'styled-components';
import { ReactComponent as ChevronRight } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-right-1.svg';
import { ReactComponent as Shield } from 'assets/streamline-light/programing-apps-websites/bug-security/shield-check.svg';
import Icon from '../icon';
import { device } from '../../../../../style/theme';
import { Star } from '../../../../landing/components/financing-lp/cta-buttons/cta-buttons-styles';

interface IProps {
  title: string;
  onClick?: () => void;
  skipBorder?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  disclaimer?: string;
  image?: string;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  description?: string;
  backgroundColor?: string;
  textColor?: string;
  skipBorderRadius?: boolean;
  id?: string;
  rating?: 0 | 1 | 2 | 3 | 4 | 5;
  titleNoWrap?: boolean;
  isDisabled?: boolean;
}

const Container = styled.div<
  Pick<
    IProps,
    'isFirst' | 'isLast' | 'disclaimer' | 'backgroundColor' | 'skipBorderRadius'
  >
>`
  display: inline-flex;
  padding: 24px 24px 0 24px;
  border-radius: 0;
  ${(props) =>
    props.isFirst &&
    `
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  `}
  ${(props) =>
    props.isLast &&
    !props.disclaimer &&
    `
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  `}
  background-color: ${(props) => props.backgroundColor ?? props.theme.ctaBlue};
  &:hover {
    background-color: ${(props) =>
      props.backgroundColor ?? props.theme.ctaBlueHover};
  }
  max-width: 464px;
  transition: background-color 225ms ease;
  cursor: pointer;
  ${(props) => props.skipBorderRadius && `border-radius: 0;`}
  @media ${device.tablet} {
    max-width: 100%;
    width: 100%;
    padding: 12px 12px 0 12px;
    height: 100%;
  }
`;

const BorderBox = styled.div<{ skipBorder: boolean }>`
  flex: 1;
  display: inline-flex;
  align-items: center;
  border-bottom: ${(props) =>
    props.skipBorder ? 'none' : ' 1px solid rgba(255,255,255,0.2)'};
  padding-bottom: 24px;
  min-width: 0;
  > i {
    flex: 0 0 32px;
  }
  @media ${device.tablet} {
    padding-bottom: 12px;
    flex-direction: column;
  }
`;

const ContentColumn = styled.div`
  margin: 0 8px 0 16px;
  min-width: 0;
  @media ${device.tablet} {
    margin: 8px 0 0 0;
  }
`;

const Title = styled.h3<{ textColor?: string; titleNoWrap?: boolean }>`
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1px;
  color: ${(props) => props.textColor ?? '#fff'};
  margin-bottom: 4px;
  ${(props) => props.titleNoWrap && `white-space: nowrap;`}
  @media ${device.tablet} {
    text-align: center;
    font-size: 12px;
    line-height: 1.33;
  }
`;

const Description = styled.p<{ textColor?: string }>`
  opacity: 0.6;
  margin: 0;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0.5px;
  color: ${(props) => props.textColor ?? '#fff'};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  @media ${device.tablet} {
    text-align: center;
    font-size: 12px;
    line-height: 1.2;
  }
`;

const CTA = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border: 2px solid #fff;
  border-radius: 50%;
  padding: 16px;
  position: relative;
  svg {
    stroke-width: 1.5px;
  }
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #000;
    border-radius: 50%;
  }
  @media ${device.tablet} {
    display: none;
  }
`;

const DisclaimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
  padding: 4px 34px;
  //border-bottom-left-radius: 3px;
  //border-bottom-right-radius: 3px;
  background-color: ${(props) => props.theme.logoBlue};
  max-width: 464px;
  > i {
    flex: 0 0 auto;
  }
  &:before {
    position: absolute;
    top: -7px;
    left: 33px;
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${(props) => props.theme.logoBlue};
  }
  @media ${device.tablet} {
    padding: 4px 22px;
    max-width: 100%;
    width: 100%;
    justify-content: center;

    &:before {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const DisclaimerLabel = styled.div`
  margin-left: 6px;
  min-width: 0;
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.white};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  margin-right: 6px;
`;

const PrimaryCTAButton = ({
  title,
  onClick,
  skipBorder = false,
  isFirst,
  isLast,
  disclaimer,
  image,
  icon,
  description,
  backgroundColor,
  textColor,
  skipBorderRadius,
  id,
  rating,
  titleNoWrap,
  isDisabled,
}: IProps): JSX.Element => {
  const arrayRating = Array.from({ length: rating ?? 0 });

  return (
    <>
      <Container
        isFirst={isFirst}
        isLast={isLast}
        disclaimer={disclaimer}
        backgroundColor={backgroundColor}
        skipBorderRadius={skipBorderRadius}
        onClick={isDisabled ? undefined : onClick}
        id={id}
      >
        <BorderBox skipBorder={skipBorder}>
          {icon && <Icon width={32} height={32} icon={icon} />}
          {image && <img width={32} height={32} src={image} alt={image} />}
          <ContentColumn>
            <Title titleNoWrap={titleNoWrap} textColor={textColor}>
              {title}
            </Title>
            <Description textColor={textColor}>{description}</Description>
          </ContentColumn>
          <CTA>
            <Icon icon={ChevronRight} width={12} height={12} />
          </CTA>
        </BorderBox>
      </Container>
      {disclaimer && (
        <DisclaimerContainer>
          <Icon icon={Shield} width={14} height={14} />
          <DisclaimerLabel>{disclaimer}</DisclaimerLabel>
          {arrayRating.map((item, index) => (
            <Star key={index} />
          ))}
        </DisclaimerContainer>
      )}
    </>
  );
};

export { PrimaryCTAButton };
