import styled from 'styled-components';

export const Container = styled.div`
  & > div > div:nth-child(1) {
    & div {
      min-height: auto !important;
    }
    & p {
      margin-top: 0 !important;
    }
  }

  h4 {
    margin: 12px 0 !important;
    font-size: 24px !important;
  }
`;
