import { FC, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LaptopIdea } from 'assets/streamline-light/work-office-companies/ideas-creativity/laptop-idea.svg';
import BannerImage from 'assets/selling-simulation-banner.png';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { UserStatus } from '../../../../generated';
import { device } from '../../../../style/theme';
import Banner from '../banner/banner';
import { GeneralStatisticsCard } from '../general-statistics-card/general-statistics-card';
import { ExposeStatisticsCard } from '../expose-statistics-card/expose-statistics-card';
import { OffersStatisticsCard } from '../offers-statistics-card/offers-statistics-card';
import {
  offersStatisticsPerWeek,
  generalStatistics,
  advertisementViewsStatistics,
} from '../selling-simulation/dummy-data';
import { setSelectedTab } from '../../../property/redux/dynamicMapUtilsSlice';
import { TeamContactCard } from '../appointment-card/team-contact-card';
import { ReactComponent as ReadHuman } from '../../../../assets/streamline-light/school-learning/library-reading/read-human.svg';
import { useAppSelector } from '../../../common/hooks';
import { useCurrency } from '../../../property/hooks/useCurrency';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { SellerPdfDocument } from '../seller-pdf-document/seller-pdf-document';

const Container = styled.div`
  position: relative;
  display: flex;
  @media ${device.tablet} {
    flex-direction: column;
    padding: 16px;
  }
`;

const FirstColumn = styled.div`
  flex: 1.72;
  max-width: 688px;
  margin-right: 16px;
  @media ${device.tablet} {
    margin-bottom: 16px;
    max-width: 100%;
    width: 100%;
  }
`;

const SecondColumn = styled.div`
  flex: 1;
  max-width: 400px;
  @media ${device.tablet} {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

interface IProps {
  currentValuation?: number | null;
  isHiddenSendEmailToBrokerAlert?: boolean | null;
  userStatus?: UserStatus | null;
}

const SellingSimulationBase: FC<IProps> = ({
  currentValuation,
  isHiddenSendEmailToBrokerAlert,
  userStatus,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const priceCurrency = useCurrency();
  const isMobileSize = useIsMobileSize();

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const onStartSaleButtonClick = () => {
    dispatch(setSelectedTab(0));
  };

  return (
    <>
      <SellerPdfDocument isSimulation />
      {isBrokerView ? (
        <Banner
          icon={ReadHuman}
          image={BannerImage}
          title={t('seller-portal.broker-view.banner.title')}
          description={t('seller-portal.broker-view.banner.description')}
        />
      ) : (
        <Banner
          icon={LaptopIdea}
          image={BannerImage}
          title={t('selling-simulation-banner.title')}
          description={t('selling-simulation-banner.description')}
          ctaButtonLabel={
            userStatus === UserStatus.Spectator
              ? ''
              : t('selling-simulation-banner.button')
          }
          containerStyle={{ height: '128px', backgroundImage: 'none' }}
          onCTAClick={onStartSaleButtonClick}
        />
      )}

      <Container>
        <FirstColumn>
          <ExposeStatisticsCard
            statistics={advertisementViewsStatistics}
            isSimulation
            isHiddenSendEmailToBrokerAlert={isHiddenSendEmailToBrokerAlert}
          />
          {isMobileSize && (
            <GeneralStatisticsCard
              isSimulation
              statistics={generalStatistics}
              currentValuation={currentValuation}
              showViewingAppointments
              showUnlockedExposes
              showRequestedExposes
            />
          )}

          {!isMobileSize && (
            <OffersStatisticsCard
              userStatus={userStatus}
              statistics={offersStatisticsPerWeek(
                priceCurrency === '€' ? 'EUR' : 'CHF',
                currentValuation
              )}
              currentValuation={currentValuation}
              isSimulation
              showFilter
              showViewingAppointments
              showUnlockedExposes
              showRequestedExposes
            />
          )}
        </FirstColumn>
        <SecondColumn>
          {isMobileSize && (
            <OffersStatisticsCard
              userStatus={userStatus}
              statistics={offersStatisticsPerWeek(
                priceCurrency === '€' ? 'EUR' : 'CHF',
                currentValuation
              )}
              currentValuation={currentValuation}
              isSimulation
              showViewingAppointments
              showUnlockedExposes
              showRequestedExposes
            />
          )}
          {!isMobileSize && (
            <GeneralStatisticsCard
              isSimulation
              statistics={generalStatistics}
              currentValuation={currentValuation}
              showViewingAppointments
              showUnlockedExposes
              showRequestedExposes
            />
          )}

          <TeamContactCard />
        </SecondColumn>
      </Container>
    </>
  );
};

const SellingSimulation = memo(SellingSimulationBase);

export { SellingSimulation };
