export const formModalStyles = {
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  padding: 0,
};

export const isoCountries: { [key: string]: string } = {
  AF: 'Afghanistan',
  AX: 'Aland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua And Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia And Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo, Democratic Republic',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Cote D'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island & Mcdonald Islands',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran, Islamic Republic Of',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle Of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KR: 'Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libyan Arab Jamahiriya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States Of',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory, Occupied',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  BL: 'Saint Barthelemy',
  SH: 'Saint Helena',
  KN: 'Saint Kitts And Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin',
  PM: 'Saint Pierre And Miquelon',
  VC: 'Saint Vincent And Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome And Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia And Sandwich Isl.',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard And Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad And Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks And Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UM: 'United States Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Viet Nam',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  WF: 'Wallis And Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

export const isoCountriesGermany: { [key: string]: string } = {
  AF: 'Afghanistan',
  EG: 'Ägypten',
  AX: 'Ålandinseln',
  AL: 'Albanien',
  DZ: 'Algerien',
  AS: 'Amerikanisch Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarktis',
  AG: 'Antigua und Barbuda',
  GQ: 'Äquatorialguinea',
  AR: 'Argentinien',
  AM: 'Armenien',
  AW: 'Aruba',
  AZ: 'Aserbaidschan',
  ET: 'Äthiopien',
  AU: 'Australien',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesch',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgien',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivien',
  BQ: 'Bonaire, Sint Eustatius und Saba',
  BA: 'Bosnien und Herzegowina',
  BW: 'Botswana',
  BV: 'Bouvetinsel',
  BR: 'Brasilien',
  IO: 'Britisches Territorium im Indischen Ozean',
  BN: 'Brunei',
  BG: 'Bulgarien',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CL: 'Chile',
  CN: 'China',
  CK: 'Cookinseln',
  CR: 'Costa Rica',
  CW: 'Curaçao',
  DK: 'Dänemark',
  CD: 'Demokratische Republik Kongo',
  DE: 'Deutschland',
  DM: 'Dominica',
  DO: 'Dominikanische Republik',
  DJ: 'Dschibuti',
  EC: 'Ecuador',
  SV: 'El Salvador',
  CI: 'Elfenbeinküste',
  ER: 'Eritrea',
  EE: 'Estland',
  SZ: 'Eswatini',
  FK: 'Falklandinseln',
  FO: 'Färöer-Inseln',
  FJ: 'Fidschi',
  FI: 'Finnland',
  FM: 'Föderierte Staaten von Mikronesien',
  FR: 'Frankreich',
  GF: 'Französisch-Guayana',
  PF: 'Französisch-Polynesien',
  TF: 'Französische Süd- und Antarktisgebiete',
  MC: 'Fürstentum Monaco',
  GA: 'Gabun',
  GM: 'Gambia',
  GE: 'Georgien',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GD: 'Grenada',
  GR: 'Griechenland',
  GL: 'Grönland',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard und McDonald Inseln',
  HN: 'Honduras',
  HK: 'Hongkong',
  IN: 'Indien',
  ID: 'Indonesien',
  IM: 'Insel Man',
  IQ: 'Irak',
  IR: 'Iran',
  IE: 'Irland',
  IS: 'Island',
  IL: 'Israel',
  IT: 'Italien',
  JM: 'Jamaika',
  JP: 'Japan',
  YE: 'Jemen',
  JE: 'Jersey',
  JO: 'Jordanien',
  VG: 'Jungferninseln (UK)',
  VI: 'Jungferninseln (US)',
  KY: 'Kaimaninseln',
  KH: 'Kambodscha',
  CM: 'Kamerun',
  CA: 'Kanada',
  CV: 'Kap Verde',
  KZ: 'Kasachstan',
  QA: 'Katar',
  KE: 'Kenia',
  KG: 'Kirgisistan',
  KI: 'Kiribati',
  CC: 'Kokosinseln',
  CO: 'Kolumbien',
  KM: 'Komoren',
  XK: 'Kosovo',
  HR: 'Kroatien',
  CU: 'Kuba',
  KW: 'Kuwait',
  LA: 'Laos',
  LS: 'Lesotho',
  LV: 'Lettland',
  LB: 'Libanon',
  LR: 'Liberia',
  LY: 'Libyen',
  LI: 'Liechtenstein',
  LT: 'Litauen',
  LU: 'Luxemburg',
  MO: 'Macau',
  MG: 'Madagaskar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Malediven',
  ML: 'Mali',
  MT: 'Malta',
  MA: 'Marokko',
  MH: 'Marshallinseln',
  MQ: 'Martinique',
  MR: 'Mauretanien',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexiko',
  MD: 'Moldawien',
  MN: 'Mongolei',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MZ: 'Mosambik',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NC: 'Neukaledonien',
  NZ: 'Neuseeland',
  NI: 'Nicaragua',
  NL: 'Niederlande',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  KP: 'Nordkorea',
  MP: 'Nördliche Marianen',
  MK: 'Nordmazedonien',
  NF: 'Norfolkinsel',
  NO: 'Norwegen',
  OM: 'Oman',
  AT: 'Österreich',
  TL: 'Osttimor',
  PK: 'Pakistan',
  PS: 'Palästina',
  PW: 'Palau',
  PA: 'Panama',
  PG: 'Papua-Neuguinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippinen',
  PN: 'Pitcairninseln',
  PL: 'Polen',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  CG: 'Republik Kongo',
  RE: 'Réunion',
  RW: 'Ruanda',
  RO: 'Rumänien',
  RU: 'Russland',
  MF: 'Saint Martin',
  SB: 'Salomonen',
  ZM: 'Sambia',
  WS: 'Samoa',
  SM: 'San Marino',
  BL: 'Sankt Bartholomäus',
  ST: 'São Tomé und Príncipe',
  SA: 'Saudi-Arabien',
  SE: 'Schweden',
  CH: 'Schweiz',
  SN: 'Senegal',
  RS: 'Serbien',
  SC: 'Seychellen',
  SL: 'Sierra Leone',
  ZW: 'Simbabwe',
  SG: 'Singapur',
  SX: 'Sint Maarten',
  SK: 'Slowakei',
  SI: 'Slowenien',
  SO: 'Somalia',
  ES: 'Spanien',
  LK: 'Sri Lanka',
  SH: 'St. Helena, Ascension und Tristan da Cunha',
  KN: 'St. Kitts und Nevis',
  LC: 'St. Lucia',
  PM: 'St. Pierre und Miquelon',
  VC: 'St. Vincent und die Grenadinen',
  ZA: 'Südafrika',
  SD: 'Sudan',
  GS: 'Südgeorgien und die Südlichen Sandwichinseln',
  KR: 'Südkorea',
  SS: 'Südsudan',
  SR: 'Suriname',
  SJ: 'Svalbard und Jan Mayen',
  SY: 'Syrien',
  TJ: 'Tadschikistan',
  TW: 'Taiwan',
  TZ: 'Tansania',
  TH: 'Thailand',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad und Tobago',
  TD: 'Tschad',
  CZ: 'Tschechien',
  TN: 'Tunesien',
  TR: 'Türkei',
  TM: 'Turkmenistan',
  TC: 'Turks und Caicosinseln',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  HU: 'Ungarn',
  UY: 'Uruguay',
  UZ: 'Usbekistan',
  VU: 'Vanuatu',
  VA: 'Vatikanstadt',
  VE: 'Venezuela',
  AE: 'Vereinigte Arabische Emirate',
  US: 'Vereinigte Staaten von Amerika',
  GB: 'Vereinigtes Königreich',
  VN: 'Vietnam',
  WF: 'Wallis und Futuna',
  CX: 'Weihnachtsinsel',
  EH: 'Westsahara',
  CF: 'Zentralafrikanische Republik',
  CY: 'Zypern',
};
