import { FC, Fragment, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { ReactComponent as Calendar } from 'assets/streamline-light/interface-essential/date-calendar/calendar.svg';

import { useTranslation } from 'react-i18next';
import { Container, Header } from './financing-variants-period-styles';
import Icon from '../../../../../common/components/ui/icon';
import { BoxItem } from '../../../../../forms/components/common/box-selector-line/box-item/box-item';

const boxes = [
  { value: '5', text: '5 Jahre' },
  { value: '10', text: '10 Jahre' },
  { value: '15', text: '15 Jahre' },
  { value: '20', text: '20 Jahre' },
  { value: '25', text: '25 Jahre' },
  { value: '30', text: '30 Jahre' },
];

interface IProps {
  period: string;
  setPeriod: (value: string) => void;
}

const FinancingVariantsPeriod: FC<IProps> = ({ period, setPeriod }) => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <Container>
      <div>
        <Icon
          icon={Calendar}
          width={20}
          height={20}
          color={themeContext.logoBlue}
        />
        <Header>
          {t('financing-portal-page.variants-compare.modal.period.title')}
        </Header>
      </div>
      <div>
        {boxes.map((box) => (
          <Fragment key={box.value}>
            <BoxItem
              text={box.text}
              isSelected={box.value === period}
              isDisabled={box.value === '30'}
              onClick={() => setPeriod(box.value)}
              width={80}
              height={44}
            />
          </Fragment>
        ))}
      </div>
    </Container>
  );
};

export { FinancingVariantsPeriod };
