import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { BorderButton } from '../../../../common/components/ui/buttons';

export const Container = styled.div<{ isFixTop?: boolean }>`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: ${({ isFixTop }) => (isFixTop ? 'flex-start' : 'center')};
  align-items: center;
  max-width: 561px;
  margin: 0 auto;
  & h2 {
    margin-bottom: 108px;
  }
`;

export const HeadlineBox = styled.div`
  margin: 0 0 8px;
  width: 100%;

  @media ${device.tablet} {
    margin: 0;
  }
`;

export const Headline = styled.h4`
  opacity: 0.5;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  text-align: left;
  text-transform: uppercase;
  width: 100%;

  @media ${device.tablet} {
    font-weight: 700;
    font-size: 10px;
    text-align: center;
  }
`;

export const Title = styled.h3<{ isShort?: boolean }>`
  width: ${({ isShort }) => (isShort ? 63 : 100)}%;
  font-family: Source Serif Pro, sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: left;
  color: ${({ theme }) => theme.blue};
  margin-bottom: 24px;

  @media ${device.tablet} {
    font-size: 20px;
    text-align: center;
    margin-bottom: 12px;
  }
`;

export const CircleContainer = styled.div`
  display: none;

  @media ${device.tablet} {
    display: block;
    margin: 4px 0 16px;
  }
`;

export const StepContainer = styled.div``;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    div:has(> label) {
      // flex: 0;
    }

    div:has(> div > label) {
      justify-content: flex-start;
    }
  }
`;

export const Space = styled.div<{ marginBottom?: number }>`
  margin-bottom: ${({ marginBottom }) =>
    typeof marginBottom === 'number' ? marginBottom : 8}px;
`;

export const BackButton = styled(BorderButton)<{
  marginRight?: number;
  isIframe?: boolean;
}>`
  margin-right: ${({ marginRight }) =>
    typeof marginRight === 'number' ? marginRight : 8}px;
`;
