import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { useGetFinancingReason, useGetPropertyBoxes } from './useStepsLogic';
import { useAppSelector } from '../../../common/hooks';
import { useGetTemporaryPropertyQuery } from '../../../../generated';
import {
  FIELDS,
  propertySearchProgressOptions,
  propertyTypeBoxes,
  propertyUseOptions,
  reasonBoxes,
} from '../constants';
import { numberFormat } from '../../../financing/utils/numberFormat';

const useGetInfoValues = () => {
  const { watch } = useFormContext();

  const activeStep = useAppSelector((state) => state.financing.activeStep);
  const userInput = useAppSelector((state) => state.financing.userInput);
  const isRedirect = useAppSelector((state) => state.financing.isRedirect);
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);

  const { data } = useGetTemporaryPropertyQuery({}, { skip: isAnonymousUser });

  let values = watch();
  useEffect(() => {
    values = watch();
  }, [activeStep]);
  const formData = isEmpty(values) ? userInput : values;
  const { isSearchProgressField } = useGetPropertyBoxes();

  const {
    reason,
    propertyType,
    livingArea,
    buildingYear,
    propertyUse,
    searchProgress,
    purchasePrice,
    equityCapital,
    brokerFeePercent,
    buildingCosts,
    landPrice,
    propertyValue,
    estimatedRemainingDebt,
    desiredLoanAmount,
    payoutDate,
    // monthlyRate,
  } =
    (isRedirect ? data?.getTemporaryProperty?.propertyData : formData) ||
    formData ||
    {};

  // first step

  const reasonName = reasonBoxes.find((item) => item.value === reason)?.title;

  // propertyTable

  const propertyTypeName = propertyTypeBoxes.find(
    (item) => item.value === propertyType
  )?.title;
  const propertyUseName = propertyUseOptions.find(
    (item) => item.value === propertyUse
  )?.label;
  const searchProgressName = propertySearchProgressOptions.find(
    (item) => item.value === searchProgress
  )?.label;

  const propertyTable = {
    [FIELDS.LIVING_AREA.label]: livingArea ? `${livingArea} m²` : '',
    [FIELDS.BUILDING_YEAR.label]: buildingYear || '',
    [FIELDS.PROPERTY_USE.label]: propertyUseName || '',
    ...(isSearchProgressField
      ? { [FIELDS.SEARCH_PROGRESS.label]: searchProgressName || '' }
      : {}),
  };

  // fundingRequirementTable

  const {
    isPurchaseOfExistingProperty,
    isFollowUpFinancing,
    // isInstallmentLoan,
    isModernization,
    isOwnConstructionProject,
    isPurchaseOfNewBuilding,
    isRaisingCapital,
  } = useGetFinancingReason();

  // type of the financing
  const fundingTable: { [key: string]: string } = {};

  if (isPurchaseOfExistingProperty || isPurchaseOfNewBuilding) {
    fundingTable[FIELDS.PURCHASE_PRICE.label] = purchasePrice
      ? `${numberFormat(purchasePrice)} ${FIELDS.PURCHASE_PRICE.inputAdornment}`
      : '';
    fundingTable[FIELDS.EQUITY_CAPITAL.label] = equityCapital
      ? `${numberFormat(equityCapital)} ${FIELDS.EQUITY_CAPITAL.inputAdornment}`
      : '';
    if (formData?.brokerageFee) {
      fundingTable[FIELDS.BROKER_FEE_PERCENT.label] = brokerFeePercent
        ? `${numberFormat(brokerFeePercent, true)} ${
            FIELDS.BROKER_FEE_PERCENT.inputAdornment
          }`
        : '';
    }
  }

  if (isFollowUpFinancing) {
    fundingTable[FIELDS.PROPERTY_VALUE.label] = propertyValue
      ? `${numberFormat(propertyValue)} ${FIELDS.PROPERTY_VALUE.inputAdornment}`
      : '';
    fundingTable[FIELDS.PAYOUT_DATE.label] = payoutDate
      ? `${payoutDate.toLocaleDateString('de')}`
      : '';
    fundingTable[FIELDS.ESTIMATED_REMAINING_DEBT.label] = estimatedRemainingDebt
      ? `${numberFormat(estimatedRemainingDebt)} ${
          FIELDS.ESTIMATED_REMAINING_DEBT.inputAdornment
        }`
      : '';
  }

  if (isOwnConstructionProject) {
    fundingTable[FIELDS.BUILDING_COSTS.label] = buildingCosts
      ? `${numberFormat(buildingCosts)} ${FIELDS.BUILDING_COSTS.inputAdornment}`
      : '';
    fundingTable[FIELDS.LAND_PRICE.label] = landPrice
      ? `${numberFormat(landPrice)} ${FIELDS.LAND_PRICE.inputAdornment}`
      : '';
    // fundingTable[FIELDS.EQUITY_CAPITAL.label] = equityCapital
    //   ? `${numberFormat(equityCapital)} ${FIELDS.EQUITY_CAPITAL.inputAdornment}`
    //   : '';
    if (formData?.brokerageFee) {
      fundingTable[FIELDS.BROKER_FEE_PERCENT.label] = brokerFeePercent
        ? `${numberFormat(brokerFeePercent, true)} ${
            FIELDS.BROKER_FEE_PERCENT.inputAdornment
          }`
        : '';
    }
  }

  if (isModernization || isRaisingCapital) {
    fundingTable[FIELDS.PROPERTY_VALUE.label] = propertyValue
      ? `${numberFormat(propertyValue)} ${FIELDS.PROPERTY_VALUE.inputAdornment}`
      : '';
    fundingTable[FIELDS.DESIRED_LOAN_AMOUNT.label] = desiredLoanAmount
      ? `${numberFormat(desiredLoanAmount)} ${
          FIELDS.DESIRED_LOAN_AMOUNT.inputAdornment
        }`
      : '';
  }

  // if (isInstallmentLoan) {
  //   fundingTable[FIELDS.DESIRED_LOAN_AMOUNT.label] = desiredLoanAmount
  //     ? `${numberFormat(desiredLoanAmount)} ${
  //         FIELDS.DESIRED_LOAN_AMOUNT.inputAdornment
  //       }`
  //     : '';
  //   fundingTable[FIELDS.MONTHLY_RATE.label] = monthlyRate
  //     ? `${numberFormat(monthlyRate)} ${FIELDS.MONTHLY_RATE.inputAdornment}`
  //     : '';
  // }

  if (isEmpty(fundingTable)) {
    fundingTable[FIELDS.PURCHASE_PRICE.label] = '';
    fundingTable[FIELDS.EQUITY_CAPITAL.label] = '';
    fundingTable[FIELDS.BROKERAGE_FEE.label] = '';
  }

  return {
    reasonName,
    propertyTypeName,
    propertyTable,
    fundingTable,
  };
};

export { useGetInfoValues };
