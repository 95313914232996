import { useParams } from 'react-router-dom';
import { useUpdateFinancing } from '../../forms/form-financing/hooks/callback-hooks/useUpdateFinancing';
import { useAppSelector } from '../../common/hooks';
import { FinancingVariant } from '../../../generated';

interface IProps {
  financingVariantsProp: FinancingVariant[];
}

const useRequestCertificate = ({ financingVariantsProp }: IProps) => {
  const { financingId } = useParams<{ financingId: string }>();
  const { updateFinancing } = useUpdateFinancing();
  const { variantIndex } = useAppSelector((state) => state.financing);

  const requestCertificate = async () => {
    const financingVariants = financingVariantsProp.map((item, i) => {
      return i === variantIndex
        ? {
            ...item,
            financingSuggestions: {
              ...item.financingSuggestions,
              isCertificateSuggestion: false,
            },
          }
        : item;
    });
    await updateFinancing({
      financingInput: {
        _id: financingId,
        financingVariants,
      },
    });
  };
  return { requestCertificate };
};

export { useRequestCertificate };
