import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const AppBox = styled.div`
  @media ${device.mobile} {
    margin: 0 !important;
  }
`;

export const Container = styled.div`
  position: relative;
  border-radius: 3px;
  margin-top: -40px;
  margin-bottom: 58px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media ${device.tablet} {
    margin-top: 32px;
  }

  @media ${device.mobile} {
    box-shadow: none;
    border-top: 1px solid #d9d9d9;
    button {
      min-width: 256px;
    }
    margin: 0;
  }
`;

export const MainContainer = styled.div<{ overviewLP?: boolean }>`
  position: absolute;
  width: 100%;
  background-color: ${(props) =>
    props.overviewLP ? '#002849' : props.theme.faqActiveHeader};
  @media ${device.mobile} {
    position: relative;
  }
`;

export const MainContentContainer = styled.div`
  width: 65%;
  padding: 32px;
  @media ${device.mobile} {
    width: 100%;
    padding: 16px;
  }
`;

export const Name = styled.span<{ overviewLP?: boolean }>`
  opacity: 0.8;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  ${(props) => props.overviewLP && 'text-transform: uppercase;'}
  color: ${(props) => (props.overviewLP ? '#fff' : props.theme.logoBlue)};

  @media ${device.mobile} {
    ${(props) =>
      props.overviewLP &&
      `
      display: block;
      text-align: center;
    `}
  }
`;

export const Title = styled.span<{ overviewLP?: boolean }>`
  margin-top: 8px;
  display: block;
  font-family: ${(props) =>
    props.overviewLP ? 'Source Serif Pro' : 'Roboto, sans-serif'};
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => (props.overviewLP ? '#fff' : props.theme.logoBlue)};
  ${(props) =>
    props.overviewLP &&
    `
    font-family: SourceSerifPro-SemiBold;
    line-height: 32px;
  `}

  @media ${device.mobile} {
    font-size: 20px;
    margin-top: 4px;
    ${(props) =>
      props.overviewLP &&
      `
      text-align: center;
    `}
  }
`;

export const Description = styled.div<{ overviewLP?: boolean }>`
  margin-top: 8px;
  font-family: Roboto, sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  ${(props) => props.overviewLP && 'max-width: 520px; opacity: 0.9;'}
  color: ${(props) => (props.overviewLP ? '#fff' : props.theme.logoBlue)};

  @media ${device.mobile} {
    margin-top: 16px;
    ${(props) =>
      props.overviewLP &&
      `
      text-align: center;
    `}
  }
`;

export const MarketButtons = styled.div<{ overviewLP?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-top: 16px;
  }
  img {
    width: 100%;
  }

  @media ${device.mobile} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const GooglePlayContainer = styled.div`
  width: 161px;
  height: 46px;
`;

export const AppleStoreContainer = styled.div`
  width: 142px;
  height: 46px;
  margin-right: 12px;
  a {
    width: 100%;
    display: inline-block;
    border-radius: 13px;
  }
`;

export const PreviewImageContainer = styled.div`
  width: 35%;
  z-index: 2;
  margin-right: 24px;
  @media ${device.mobile} {
    display: none;
  }
`;

export const PreviewImage = styled.img`
  width: 100%;
  max-height: 410px;
  object-fit: cover;
`;
