import { ReactComponent as ArrowButtonLeft1 } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { memo, useCallback } from 'react';
import { batch } from 'react-redux';
import styled from 'styled-components';

import Icon from '../../../../../common/components/ui/icon';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { Europe } from '../../../../../map/constants/points-of-interest';
import {
  changeViewport,
  restoreCheckpoints,
} from '../../../../../map/redux/mapSlice';
import {
  closeValuationWizardAction,
  prevStepAction,
  resetValuationWizard,
  toggleLoginForm,
  toggleRegisterForm,
} from '../../../redux/valuationWizardSlice';
import { PropertyCode } from '../../../../../../generated';

interface IProps {
  title?: string;
  mobileCompact?: boolean;
  toMobileCompact?: () => void;
}

const IconWrapper = styled.div``;

const Back = styled.div`
  height: 56px;
  min-height: 56px;
  width: calc(100% + 2 * 24px);
  background: ${(props) => props.theme.blue};
  position: relative;
  top: -24px;
  margin: 0 -24px;
  display: flex;
  align-items: center;
  padding: 0 16px;

  > div:first-child,
  > div:last-child {
    flex: 0 0 auto;
  }
`;

const MobileTitle = styled.h2`
  font-family: 'Source Serif Pro', serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
  margin: 0;
  flex: 1;
`;

const MobileBackBase = ({ title, mobileCompact, toMobileCompact }: IProps) => {
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const activeStep = useAppSelector(
    (state) => state.valuationWizard.activeStep
  );
  const selectedTab = useAppSelector(
    (state) => state.dynamicMapUtils.selectedTab
  );
  const isRegisterFormOpen = useAppSelector(
    (state) => state.valuationWizard.isRegisterFormOpen
  );
  const isLoginFormOpen = useAppSelector(
    (state) => state.valuationWizard.isLoginFormOpen
  );
  const isMultiFamilyHouse = useAppSelector(
    (state) =>
      state.valuationWizard.userInput?.code === PropertyCode.MultiFamilyHouse
  );

  const closeEvaluationWizard = () => {
    // Heat map
    if (selectedTab === 0) {
      dispatch(
        changeViewport({
          ...Europe,
          transitionDuration: 1000,
        })
      );
      setTimeout(() => {
        batch(() => {
          dispatch(closeValuationWizardAction());
          dispatch(resetValuationWizard());
        });
      }, 500);
    } else {
      batch(() => {
        dispatch(closeValuationWizardAction());
        dispatch(resetValuationWizard());
        dispatch(restoreCheckpoints());
      });
    }

    if (!mobileCompact && toMobileCompact) {
      toMobileCompact();
    }
  };

  // Clear markers and selected address
  const switchToPrevStep = useCallback(() => {
    if (isRegisterFormOpen) {
      dispatch(toggleRegisterForm(false));
      return;
    }
    if (isLoginFormOpen) {
      dispatch(toggleLoginForm(false));
      return;
    }
    if (isMultiFamilyHouse && activeStep === 3) {
      dispatch(prevStepAction());
      dispatch(prevStepAction());
    } else {
      dispatch(prevStepAction());
    }
  }, [
    dispatch,
    isLoginFormOpen,
    isRegisterFormOpen,
    isMultiFamilyHouse,
    activeStep,
  ]);

  if (isMobileSize) {
    return (
      <Back>
        {(mobileCompact
          ? activeStep > 1 && !isRegisterFormOpen
          : activeStep > 0 && !isRegisterFormOpen) && (
          <IconWrapper onClick={switchToPrevStep}>
            <Icon icon={ArrowButtonLeft1} width={16} height={16} />
          </IconWrapper>
        )}
        <MobileTitle>{title}</MobileTitle>
        {(mobileCompact ? activeStep > 0 : true) && (
          <IconWrapper onClick={closeEvaluationWizard}>
            <Icon icon={Close} width={16} height={16} />
          </IconWrapper>
        )}
      </Back>
    );
  }

  return <></>;
};

const MobileBack = memo(MobileBackBase);

export { MobileBack };
