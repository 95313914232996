import { forwardRef } from 'react';
import styled from 'styled-components';
import { device } from '../../../../../style/theme';

interface IProps {
  content: string;
}

const Container = styled.p`
  margin: 0;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: ${(props) => props.theme.blue};
  @media ${device.tablet} {
    font-size: 10px;
    line-height: 1.6;
  }
`;

const TitleSlogan = forwardRef<HTMLParagraphElement, IProps>(
  ({ content, ...rest }, ref) => (
    <Container {...rest} ref={ref}>
      {content}
    </Container>
  )
);

export { TitleSlogan };
