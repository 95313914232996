import { FC, useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ReactComponent as MapIcon } from 'assets/streamline-light/maps-navigation/maps/maps.svg';
import { ReactComponent as GalleryIcon } from 'assets/streamline-light/images-photography/pictures/picture-polaroid-landscape.svg';

import { Mapbox } from '../../../map/components/mapbox';
import {
  Container,
  GalleryModalButton,
  MapGalleryButton,
} from './seller-property-gallery-container-styles';
import Icon from '../../../common/components/ui/icon';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import {
  toggleSellerPortalModalGallery,
  setSellerPortalGalleryActiveSlide,
} from '../../redux/sellerPropertiesSlice';
import { SellerPropertyGalleryModal } from './seller-property-gallery-modal/seller-property-gallery-modal';
import { VonPollPropertyImage } from '../../../../generated';
import { SellerPropertyGallerySlider } from './seller-property-gallery-slider/seller-property-gallery-slider';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { Tooltip } from '../../../common/components/tooltip';

interface IProps {
  images?: VonPollPropertyImage[];
}

const SellerPropertyGalleryContainer: FC<IProps> = ({ images }) => {
  const theme = useContext(ThemeContext);
  const isMobileSize = useIsMobileSize();
  const { propertyId } = useParams<{ propertyId: string }>();
  const { activeSlide, isOpenModal } = useAppSelector(
    (state) => state.sellerProperties.portalGallerySettings
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isMap, setIsMap] = useState(true);
  const [modalActiveSlide, setModalActiveSlide] = useState<number>(0);
  const gallerySliderRef = useRef<HTMLImageElement | null>(null);

  const setActiveSlide = (index: number) => {
    dispatch(setSellerPortalGalleryActiveSlide(index));
  };

  const setGalleryModalActiveSlide = (index: number) => {
    setModalActiveSlide(index);
  };

  useEffect(() => {
    setActiveSlide(1);
  }, [propertyId]);

  const changeSwiperSlide = (index: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gallerySliderRef.current?.swiper.slideTo(index);
  };

  useEffect(() => {
    if (isOpenModal) {
      changeSwiperSlide(activeSlide + 1);
    } else {
      changeSwiperSlide(activeSlide);
    }
    setIsMap(activeSlide === 0);
  }, [activeSlide]);

  const onClickNavButton = (isPrev: boolean) => () => {
    if (images) {
      if (isPrev && activeSlide > 0) {
        setActiveSlide(activeSlide - 1);
      } else if (images.length > activeSlide) {
        setActiveSlide(activeSlide + 1);
      }
    }
  };

  const toSlide = (index: number) => {
    setActiveSlide(index);
  };

  const onClickGalleryModal = () => {
    dispatch(toggleSellerPortalModalGallery(true));
  };

  const isImages = Number(images?.length) > 0;

  return (
    <Container>
      {isImages && images && !isMobileSize ? (
        <>
          <MapGalleryButton>
            <Tooltip
              id="tooltip"
              position="right"
              content={
                <Icon
                  icon={isMap ? GalleryIcon : MapIcon}
                  height={20}
                  width={20}
                  color={theme.blue}
                  onClick={() => toSlide(isMap ? 1 : 0)}
                />
              }
            >
              <p className="tooltip-text">
                {isMap
                  ? t('financing.gallery-container.tooltip.gallery-icon')
                  : t('financing.gallery-container.tooltip.map-icon')}
              </p>
            </Tooltip>
          </MapGalleryButton>
          <GalleryModalButton onClick={onClickGalleryModal}>
            + {images.length} {t('expose-page.tabs.overview.gallery.pictures')}
          </GalleryModalButton>
          <SellerPropertyGallerySlider
            isWithMap
            activeSlide={activeSlide}
            ref={gallerySliderRef}
            images={images}
            hideFirstImageBanners
            onClickNavButton={onClickNavButton}
          />
          <SellerPropertyGalleryModal
            images={images}
            setActiveSlide={setGalleryModalActiveSlide}
            activeSlide={modalActiveSlide}
          />
        </>
      ) : (
        <Mapbox isVisible />
      )}
    </Container>
  );
};

export { SellerPropertyGalleryContainer };
