import { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import provenExpert from 'assets/images/icons/financing-form/info-container/icon.png';

import { AddressBar } from '../../../../../../common/components/ui/hero-slider/address-bar';
import { useAppSelector } from '../../../../../../common/hooks';
import { ModalMap } from '../../../../../components/fullscreen-modal/modal-map/modal-map';
import {
  Header,
  MapContainer,
  ProvenExpertContainer,
  ProvenExpertImage,
  Wrapper,
} from './financing-info-container-styles';
import { FormDetails } from './form-details/form-details';

const FinancingInfoContainerBase: FC = () => {
  const { t } = useTranslation();
  const [marginLeft, setMarginLeft] = useState(0);

  const storedAddress = useAppSelector(
    (state) => state.financing.selectedAddress
  );
  const viewport = useAppSelector((state) => state.financing.map.viewport);
  const settings = useAppSelector((state) => state.financing.map.settings);
  const wrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMarginLeft(
      wrapper?.current?.clientWidth ? wrapper?.current?.clientWidth - 200 : 0
    );
  }, [wrapper?.current?.clientWidth]);

  return (
    <Wrapper ref={wrapper}>
      <Header>{t('financing-portal.form.info-side.header')}</Header>
      <MapContainer>
        <ModalMap
          addressMarker={storedAddress?.place_name}
          viewport={viewport}
          settings={settings}
        />
      </MapContainer>
      <AddressBar
        title={t('financing-portal.form.info-side.address-title')}
        show
        isSkeleton
        isStarsImage
        address={storedAddress?.place_name}
      />
      <FormDetails />
      <ProvenExpertContainer>
        <ProvenExpertImage
          marginLeft={marginLeft}
          src={provenExpert}
          alt="proven expert"
        />
      </ProvenExpertContainer>
    </Wrapper>
  );
};

const FinancingInfoContainer = memo(FinancingInfoContainerBase);

export { FinancingInfoContainer };
