import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AddNewVariantButton,
  EditVariantButton,
} from './financing-data-edit-form/financing-data-edit-form-styles';
import loadingIcon from '../../../../../../assets/images/icons/financing-landing-page/loading-button.png';
import { MainButton } from '../../../../../common/components/ui/buttons';
import { useDirtyForm } from '../../../hooks/callback-hooks/calculation-form/useDirtyForm';

interface IProps {
  isCalculatedPropositions: boolean;
  isLoading: boolean;
  isLoadingUpdating: boolean;
  isCertificate: boolean;

  onAddNewVariantSubmit(): void;
  onEditVariantSubmit(): void;
  onFindPropositionsSubmit(): void;
}

const FinancingCalculationFormButtons: FC<IProps> = ({
  isCalculatedPropositions,
  isLoadingUpdating,
  isLoading,
  isCertificate,
  onAddNewVariantSubmit,
  onEditVariantSubmit,
  onFindPropositionsSubmit,
}) => {
  const { t } = useTranslation();
  const { isDirty } = useDirtyForm();

  return (
    <>
      {isCalculatedPropositions && !isDirty ? (
        <>
          <EditVariantButton
            id="edit-variant-button"
            customLoaderImage={loadingIcon}
            loader={isLoadingUpdating}
            isDisabled={isLoadingUpdating}
            label={t(
              'financing-portal-page.tabs.calculator.edit.button-edit-variant'
            )}
            onClick={onEditVariantSubmit}
            fluid
            centered
          />
          <AddNewVariantButton
            id="add-variant-button"
            isDisabled={isLoadingUpdating}
            label={t(
              'financing-portal-page.tabs.calculator.edit.button-add-new-variant'
            )}
            fluid
            centered
            onClick={onAddNewVariantSubmit}
          />
        </>
      ) : (
        <MainButton
          label={
            isCertificate
              ? 'Finanzierungszertifikat berechnen'
              : t('financing-portal-page.tabs.calculator.edit.button')
          }
          id="find-propositions-button"
          fluid
          loader
          isLoading={isLoading}
          onClick={onFindPropositionsSubmit}
          isDisabled={!isLoading && !isDirty}
          centered
        />
      )}
    </>
  );
};

export { FinancingCalculationFormButtons };
