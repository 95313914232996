import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const FloorIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={
          'M4.861 2a1 1 0 0 0-.989.852L1 22h22L20.61 2.877A1 1 0 0 0 19.617 2zm14.043 13.007-.512-6.004M5.83 8.996l-.614 6.007m-3.166-.002h20.075M21.375 9H2.95m5.4 6.003L8 22M9 2l-.35 6.995m7 6.008L16 22M15 2l.35 6.995M12 9v6'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { FloorIcon };
