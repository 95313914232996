import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FinancingReasonIcon from 'assets/images/icons/financing-form/info-container/finanzierungsgrund.png';
import PropertyTypeIcon from 'assets/images/icons/financing-form/info-container/immobilientyp.png';
import FinancingRequirementIcon from 'assets/images/icons/financing-form/info-container/finanzierungsbedarf.png';
import KeyDataIcon from 'assets/images/icons/financing-form/info-container/ekdaten.png';

import {
  CircleContainer,
  Container,
  ContentContainer,
} from './form-details-styles';
import { ItemContainer } from './item-container/item-container';
import { CircleProgress } from '../circle-progress/circle-progress';
import { TableFormData } from './table-form-data/table-form-data';
import { InfoWrapper } from '../financing-info-container-styles';
import { useGetProgressPercent } from '../../../../../hooks/useGetProgressPercent';
import { useGetInfoValues } from '../../../../../hooks/useGetInfoValues';
import { useAppSelector } from '../../../../../../../common/hooks';
import { TablePersonalFinancingExample } from './table-personal-financing-example/table-personal-financing-example';

const FormDetails: FC = () => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [mounted, setMount] = useState(false);
  const percent = useGetProgressPercent();
  const { activeStep, isAuthFlow, isLoadingCreating } = useAppSelector(
    (state) => state.financing
  );

  const { reasonName, propertyTable, propertyTypeName, fundingTable } =
    useGetInfoValues();

  useEffect(() => {
    if (wrapperRef.current && isAuthFlow) {
      setMount(true);
      setTimeout(() => {
        wrapperRef.current?.scrollTo({ top: 580 });
      }, 100);
    } else if (wrapperRef.current && !isAuthFlow) {
      setTimeout(() => setMount(false), 700);
      wrapperRef.current.scrollTo({ top: 0 });
    }
  }, [isAuthFlow, wrapperRef]);

  return (
    <InfoWrapper ref={wrapperRef}>
      <Container>
        <CircleContainer>
          <CircleProgress percent={percent} />
        </CircleContainer>
        <ItemContainer
          title={t(reasonName || '')}
          imageSrc={FinancingReasonIcon}
          imageAlt="Financing Reason Icon"
          headline={t(
            'financing-portal.form.info-side.funding-reason.headline'
          )}
          isMark={activeStep > 0}
        >
          <ContentContainer paddingBottom={12} />
        </ItemContainer>
        <ItemContainer
          title={t(propertyTypeName || '')}
          imageSrc={PropertyTypeIcon}
          imageAlt="Financing Reason Icon"
          headline={t('financing-portal.form.info-side.property-type.headline')}
          isMark={activeStep > 1}
        >
          <ContentContainer paddingBottom={27} minHeight={131}>
            <TableFormData formData={propertyTable} />
          </ContentContainer>
        </ItemContainer>
        <ItemContainer
          title={t('financing-portal.form.info-side.funding-needs.title')}
          imageSrc={FinancingRequirementIcon}
          imageAlt="Financing Reason Icon"
          headline={t('financing-portal.form.info-side.funding-needs.headline')}
          isMark={isAuthFlow || isLoadingCreating}
        >
          <ContentContainer paddingBottom={27} minHeight={171}>
            <TableFormData formData={fundingTable} />
          </ContentContainer>
        </ItemContainer>
        <ItemContainer
          title={
            isAuthFlow
              ? t('financing-portal.form.info-side.key-data.title-2')
              : t('financing-portal.form.info-side.key-data.title-1')
          }
          imageSrc={KeyDataIcon}
          imageAlt="Key Data Icon"
          headline={t('financing-portal.form.info-side.key-data.headline')}
          isMark={isAuthFlow || isLoadingCreating}
        >
          {mounted && (
            <ContentContainer paddingBottom={27} minHeight={600}>
              <TablePersonalFinancingExample />
            </ContentContainer>
          )}
        </ItemContainer>
      </Container>
    </InfoWrapper>
  );
};

export { FormDetails };
