import styled, { css } from 'styled-components';
import { device } from '../../../../../style/theme';
import { MainButton } from '../../../../common/components/ui/buttons';
import { ParagraphTextWithHTML } from '../../../../common/components/ui/typography';

export const Form = styled.form`
  /* display: flex; */
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100%;
  @media ${device.tablet} {
    position: relative;
  }
`;

export const SubmitButton = styled(MainButton)<{
  isiframe?: boolean;
  buttonPosition?: string;
  isFullWidth?: boolean;
  isButtonBottom?: boolean;
  isRedesign?: boolean;
}>`
  position: ${({ isiframe, buttonPosition }) =>
    buttonPosition || (isiframe ? 'static' : 'absolute')};
  margin-top: ${({ isiframe, isButtonBottom }) =>
    isButtonBottom ? 'auto' : '24px'};
  right: 0;
  bottom: 0;
  align-self: flex-end;
  z-index: 2;
  justify-content: center;
  text-transform: uppercase;
  ${({ isFullWidth }) => isFullWidth && 'width: 100%;'}
  @media
    ${({ isiframe }) => (isiframe ? '0' : device.tablet)} {
    margin-bottom: 12px;

    ${({ isRedesign }) =>
      isRedesign
        ? 'margin-top: 12px;'
        : 'transform: translateX(-50%); left: 50%; width: calc(100% - 24px);'};
  }
`;

export const HiddenSubmitButton = styled.button`
  display: none;
`;
export const FieldRequirement = styled(ParagraphTextWithHTML)<{
  isRedesign?: boolean;
}>`
  font-weight: bold;

  font-size: 10px;
  line-height: 1.6;
  opacity: 0.8;
  letter-spacing: 0.5px;
  @media ${device.tablet} {
    padding-bottom: ${({ isRedesign }) => (isRedesign ? '0' : '56px')};
  }
`;

export const FieldRequirementFinancing = styled.div<{
  isRedesign?: boolean;
}>`
  margin: 0;
  font-family: 'Roboto' !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${(props) => props.theme.blue};

  ${({ isRedesign }) =>
    isRedesign &&
    css`
      padding-bottom: 72px;
    `}
  a {
    color: ${(props) => props.theme.blue};
  }
  font-weight: bold;

  font-size: 10px;
  opacity: 0.8;
  letter-spacing: 0.5px;

  @media ${device.tablet} {
    padding-bottom: ${({ isRedesign }) => (isRedesign ? '0' : '56px')};
  }
`;

export const InputWrapper = styled.div`
  & > div > div {
    display: block;
  }
  input {
    min-width: 50px;
    width: 100%;
  }
  div {
    min-width: 50px;
  }
`;
