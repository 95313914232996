import { FC } from 'react';
import { useParams } from 'react-router';

import { Poi, PropertyPoi } from '../../../../../generated';

import { Infrastructure } from '../../../../common/components/infrastructure/infrastructure';
import { usePropertyQuery } from '../../../../../services/graphql/enhanced';
import { useMapInfrastructure } from './useMapInfrastructure';
import { addMarkers, removeMarkers } from '../../../../map/redux/mapSlice';
import { Mapbox } from '../../../../map/components/mapbox';
import styled from 'styled-components';
import { useIsMobileSize } from 'modules/common/hooks/useIsMobileSize';

type PropertyPoiWithIndex = PropertyPoi & {
  [key: string]: Record<string, Poi[]>;
};

const RootContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const MapboxContainer = styled.div`
  width: calc(64% - 8px);
  position: relative;
`;

const ContentContainer = styled.div`
  width: calc(36% - 8px);
`;

const InfrastructurePropertyWrapper: FC = () => {
  const isMobileSize = useIsMobileSize();
  const { propertyId } = useParams<{ propertyId: string }>();
  const { propertyPois, propertyLocation, locationScores } = usePropertyQuery(
    {
      id: propertyId,
    },

    {
      selectFromResult: ({ data }) => ({
        propertyPois: data?.property?.poi as PropertyPoiWithIndex,
        propertyLocation: data?.property?.propertyData.location,
        locationScores: data?.property?.locationScores,
      }),
    }
  );
  const { currentMapboxMarkers } = useMapInfrastructure({
    location: propertyLocation,
    propertyPois,
  });
  return (
    <>
      {!isMobileSize ? (
        <RootContainer>
          <MapboxContainer>
            <Mapbox isVisible isSticky />
          </MapboxContainer>
          <ContentContainer>
            <Infrastructure
              propertyPois={propertyPois}
              locationScores={locationScores}
              currentMapboxMarkers={currentMapboxMarkers}
              addMarkers={addMarkers}
              removeMarkers={removeMarkers}
            />
          </ContentContainer>
        </RootContainer>
      ) : (
        <Infrastructure
          propertyPois={propertyPois}
          locationScores={locationScores}
          currentMapboxMarkers={currentMapboxMarkers}
          addMarkers={addMarkers}
          removeMarkers={removeMarkers}
        />
      )}
    </>
  );
};

export { InfrastructurePropertyWrapper };
