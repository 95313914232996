import styled, { css } from 'styled-components';
import { device } from '../../../../../style/theme';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';

export const Container = styled.div<{
  hasError?: boolean;
  isWrapper?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
  position: relative;
  ${(props) =>
    props.hasError &&
    `

  `}
  ${({ isWrapper, theme }) =>
    isWrapper &&
    `
    border: 1px solid ${theme.borderColor};
    padding: 12px;
  `}
`;

export const Wrapper = styled.div<{ isColumn?: boolean }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  ${({ isColumn }) =>
    !isColumn &&
    css`
      gap: 8px;
    `}
  align-items: ${({ isColumn }) => (isColumn ? 'flex-start' : 'center')};
  order: 2;
  flex: 2.125;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};

  &:hover {
    & + label {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }

  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const TooltipContainer = styled.div`
  max-width: 316px;
  width: 100%;
  padding: 0;
  text-align: center;
`;

export const Info = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  cursor: pointer;
  .icon {
    > svg,
    > svg > path {
      stroke: ${(props) => props.theme.blue};
    }
  }

  @media ${device.tablet} {
    margin-right: 0;
  }
`;

export const BoxesWrapper = styled.div<{ isColumn?: boolean }>`
  position: relative;
  display: flex;
  gap: 8px;
  ${({ isColumn }) =>
    isColumn &&
    css`
      margin-top: 8px;
    `}
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const TooltipParagraph = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  padding-right: 16px;
`;

export const Label = styled.label<{
  styleGuideErr?: boolean;
  isOldDesign?: boolean;
  isLabelSaturated?: boolean;
}>`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ styleGuideErr, isOldDesign, theme, isLabelSaturated }) =>
    styleGuideErr
      ? hexToRGB(theme.red, 0.5)
      : hexToRGB(theme.blue, isLabelSaturated ? 1 : isOldDesign ? 0.5 : 0.7)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubLabel = styled(Label)`
  font-size: 10px;
`;

export const ErrorContainer = styled.div`
  order: 3;
  margin-top: 4px;
  display: flex;
  width: 100%;
`;

export const Spacer = styled.div`
  flex: 1;
  padding-right: 16px;

  @media ${device.tablet} {
    display: none;
  }
`;

export const ErrorMessage = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.red};
`;
