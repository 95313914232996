import { CSSProperties, FC } from 'react';
import { useDispatch } from 'react-redux';

import { variantsModalMobileStyles } from '../../../constants';
import { Modal } from '../../../../common/components/modal';
import { toggleModalCompareVariants } from '../../../../forms/form-financing/redux/financingSlice';
import { useAppSelector } from '../../../../common/hooks';
import { ModalHeader } from './modal-header/modal-header';
import { ModalContent } from './modal-content/modal-content';

const CompareVariantsMobileModal: FC = () => {
  const dispatch = useDispatch();
  const isOpen = useAppSelector(
    (state) => state.financing.isCompareVariantsModalOpen
  );

  const onCloseClick = () => {
    dispatch(toggleModalCompareVariants(false));
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseClick}
      wrapperStyles={variantsModalMobileStyles as CSSProperties}
    >
      <ModalHeader
        title="financing-portal-page.variants-compare.modal.title"
        onClose={onCloseClick}
      />
      <ModalContent />
    </Modal>
  );
};

export { CompareVariantsMobileModal };
