import { forwardRef, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { CarouselItem, PictureCarousel } from './full-screen-gallery-styles';
import { Slider } from '../slider';

interface IProps {
  activeSlide: number;
  images: string[];
  setActiveSlide: (index: number) => unknown;
  isFloorPlanGallery?: boolean;
}

const FullScreenGallery = forwardRef<unknown, IProps>(
  (
    { activeSlide, images, setActiveSlide, isFloorPlanGallery = false },
    ref
  ) => {
    const dispatch = useDispatch();
    const pictureCarouselRef = useRef<HTMLImageElement | null>(null);

    const toSlide = (index: number) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref.current?.swiper.slideTo(index);
      dispatch(setActiveSlide(index));
    };
    useEffect(() => {
      pictureCarouselRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [activeSlide]);

    return (
      <>
        <Slider
          ref={ref}
          images={images}
          activeSlide={activeSlide}
          setActiveSlide={setActiveSlide}
          isGallery
          isFloorPlanGallery={isFloorPlanGallery}
          objectFit="contain"
        />
        {images?.length > 0 && (
          <PictureCarousel>
            {images.map((item, i) => (
              <CarouselItem
                onClick={() => toSlide(i)}
                key={String(i)}
                src={item}
                alt={`property-photo-${i}`}
                isActive={i === activeSlide}
                ref={i === activeSlide ? pictureCarouselRef : null}
              />
            ))}
          </PictureCarousel>
        )}
      </>
    );
  }
);

export { FullScreenGallery };
