import { ReactComponent as ArrowButtonLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { FC, Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { Benefits } from '../../components/benefit/benefits';
import { Faqs } from '../../components/faqs/faqs';
import { BenefitsSection } from '../../components/overview-page/benefits-section/benefits-section';
import { FunctionsSection } from '../../components/overview-page/functions-section/functions-section';
import { BuyerFinderTeaser } from '../../components/owner-lp/buyer-finder-teaser/buyer-finder-teaser';
import { Video } from '../../components/owner-lp/video/video';
import { getAttributesFromMeta } from '../../utils/get-attributes-from-meta';
import { sortByOrderIndex } from '../../utils/sort-by-order-index';

import {
  ContentWithPadding,
  IconWrapper,
  IFrameContainer,
  IFrameSectionContainer,
} from './owner-landing-page-styles';

import {
  OwnerLandingPage as OwnerLP,
  useReadOwnerLandingPageQuery,
  ValuationType,
  OwnerLpSectionType,
} from '../../../../generated';
import { usePropertiesQuery } from '../../../../services/graphql/enhanced';
import { Card } from '../../../common/components/ui/card';
import { Hero } from '../../../common/components/ui/hero';
import { HeroSlider } from '../../../common/components/ui/hero-slider/hero-slider';
import Icon from '../../../common/components/ui/icon';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { Mapbox } from '../../../map/components/mapbox';
import { Europe } from '../../../map/constants/points-of-interest';
import { changeViewport } from '../../../map/redux/mapSlice';
import {
  toggleIsConfirmForgotPasswordModalOpen,
  toggleIsLoginModalOpen,
} from '../../../auth/redux/authSlice';
import {
  openValuationWizardAction,
  resetValuationWizard,
  setValuationTypeAction,
} from '../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import {
  openVWAction as openVWV2,
  setValuationTypeAction as setValuationTypeActionV2,
} from '../../../forms/form-property-valuation-wizard-v2/redux/valuationWizardV2Slice';
import { Appointments } from '../../components/appointment/appointment';
import { toggleTimifyModal } from '../../../timify/redux/timifySlice';
import { LngRedirect } from '../../../localization/lng-redirect';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import { siteMap } from '../../../../routes/site-map';
import { useOpenEvaluationWizard } from '../../../forms/form-property-valuation-wizard/hooks/useOpenEvaluationWizard';
import { PropertyValuationWidgetContainer } from '../../../forms/form-property-valuation-wizard/components/containers/property-valuation-widget-container/property-valuation-widget-container';
import useGetLocalization from '../../../localization/get-localization';
import { useGetEvaluationWidgetVersion } from '../../../forms/form-property-valuation-wizard-v2/hooks/useGetWidgetVersion';
import { useOpenEvaluationWizardV2 } from '../../../forms/form-property-valuation-wizard-v2/hooks/callback-hooks/useOpenEvaluationWizardV2';
import { ValuationFirstStepScreen } from '../../../forms/form-property-valuation-wizard-v2/components/containers/property-valuation-containers/valuation-first-step-screen/valuation-first-step-screen';
import { IIframeType } from '../../../auth/interfaces';
import { HelmetContainer } from '../../components/helmet-container/helmet-container';
import { ValuationLoader } from '../../../forms/components/common/loader';

interface OwnerLandingPageResponse {
  data: { readOwnerLandingPage: OwnerLP };
  isLoading: boolean;
}

interface IProps {
  openWidget?: string;
  openLoginModal?: boolean;
  openAppointmentModal?: boolean;
  openConfirmForgotPasswordModal?: boolean;
}

const OwnerLandingPage: FC<IProps> = ({
  openWidget,
  openLoginModal,
  openAppointmentModal,
  openConfirmForgotPasswordModal,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const lngHPush = useLngHistoryPush();
  const lang = useGetLocalization();
  const urlParams = new URLSearchParams(window.location.search);
  const isOpenOnlineModal = urlParams.get('online-modal') === 'true';
  const propertyNotification = urlParams.get('property-notification') || '';
  const propertyId = urlParams.get('property-id') || '';
  const { isRedesignVersion } = useGetEvaluationWidgetVersion();

  const isValuationWizardOpen = useAppSelector(
    (state) => state.valuationWizard.isValuationWizardOpen
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const iframeType = useAppSelector((state) => state.auth.iframeType);
  const isNoModal = iframeType === IIframeType.WIDGET_OWNER_NO_MODAL;
  const selectedAddress = useAppSelector(
    (state) => state.valuationWizard.selectedAddress?.place_name
  );
  const activeStep = useAppSelector(
    (state) => state.valuationWizard.activeStep
  );
  const userId = useAppSelector((state) => state.auth.user?._id);
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const valuationType = useAppSelector(
    (state) => state.valuationWizard.valuationType
  );
  const isRedirect = useAppSelector(
    (state) => state.valuationWizard.isRedirect
  );
  const isRedirectV2 = useAppSelector(
    (state) => state.valuationWizardV2.isRedirect
  );
  const propertyDossier = useAppSelector(
    (state) => state.valuationWizard.propertyDossier
  );

  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  // const propertiesQueryParams = useMemo(
  //   () => ({
  //     userId,
  //     excludeExternalProperties: true,
  //   }),
  //   [userId]
  // );

  const {
    data: userProperties,
    isLoading: areUserPropertiesLoading,
    isFetching: areUserPropertiesFetching,
  } = usePropertiesQuery(
    {
      ownerId: userId,
      excludeExternalProperties: true,
    },
    {
      skip: !userId || isAnonymousUser,
    }
  );

  const { data: landingPage, isLoading: isLandingPageLoading } =
    useReadOwnerLandingPageQuery<OwnerLandingPageResponse>({
      locale: lang,
    });

  const closeEvaluationWizard = () => {
    dispatch(
      changeViewport({
        ...Europe,
        transitionDuration: 1000,
      })
    );
    if (propertyDossier?._id) {
      lngHPush(
        `${siteMap.PropertyPage.pathWithoutParams}/${propertyDossier._id}`
      );
    }
    setTimeout(() => {
      batch(() => {
        dispatch(resetValuationWizard());
      });
    }, 500);
  };

  useEffect(() => {
    if (openLoginModal) {
      dispatch(toggleIsLoginModalOpen(true));
    }

    if (openConfirmForgotPasswordModal) {
      dispatch(toggleIsConfirmForgotPasswordModalOpen(true));
    }

    if (openAppointmentModal) {
      dispatch(toggleTimifyModal(true));
    }
  }, []);

  const { openEvaluationWizard: handleEvaluationWizardOpen } =
    useOpenEvaluationWizard({
      isLandingPage: true,
    });
  const { openEvaluationWizardV2 } = useOpenEvaluationWizardV2({
    isLandingPage: true,
  });

  // isRedirect logic
  useEffect(() => {
    if ((isRedirect || isRedirectV2) && !isValuationWizardOpen) {
      if (isRedesignVersion) {
        batch(() => {
          dispatch(openVWV2());
          dispatch(setValuationTypeActionV2(ValuationType.Online));
        });
      } else {
        batch(() => {
          dispatch(openValuationWizardAction());
          dispatch(setValuationTypeAction(ValuationType.Online));
        });
      }
    }
  }, [
    dispatch,
    isRedesignVersion,
    isRedirect,
    isRedirectV2,
    isValuationWizardOpen,
  ]);

  const openEW = isRedesignVersion
    ? openEvaluationWizardV2
    : handleEvaluationWizardOpen;

  // open widget logic
  useEffect(() => {
    if (openWidget) {
      switch (openWidget) {
        case 'ONLINE':
          if (isRedesignVersion) {
            dispatch(setValuationTypeActionV2(ValuationType.Online));
          } else {
            openEW(ValuationType.Online);
          }
          break;
        case 'PERSONAL':
          if (isRedesignVersion) {
            dispatch(setValuationTypeActionV2(ValuationType.Personal));
          } else {
            openEW(ValuationType.Personal);
          }
          break;
        default:
      }
    } else if (isOpenOnlineModal) {
      openEW(ValuationType.Online);
    } else if (isAnonymousUser && propertyNotification === '-1') {
      dispatch(toggleIsLoginModalOpen(true));
    }
  }, [
    dispatch,
    isIframe,
    isRedesignVersion,
    openEW,
    openWidget,
    propertyNotification,
    isAnonymousUser,
  ]);

  // Redirect logic not in use right now
  // ------------------------------------
  // useEffect(() => {
  //   const lastLandingPageSlug = localStorage.getItem(
  //     LAST_LANDING_PAGE_SLUG_KEY
  //   );
  //   if (lastLandingPageSlug) {
  //     lngHPush(lastLandingPageSlug);
  //   }
  // }, []);

  const handleOpenEvaluationWizard = useCallback(
    (type: ValuationType) => {
      if (isIframe && document.body.requestFullscreen) {
        document.body.requestFullscreen();
      }
      openEW(type);
    },
    [isIframe, openEW]
  );

  if (
    isLandingPageLoading ||
    areUserPropertiesLoading ||
    areUserPropertiesFetching
  ) {
    return <ValuationLoader maxWidth="500px" mb={0} />;
  }

  // If the user has properties do not let him hit the landing page
  if (
    userProperties?.properties.length &&
    !isValuationWizardOpen &&
    !isIframe
  ) {
    const propertiesLength = userProperties?.properties.length;
    const openWidgetParam = openWidget ? `openWidget=${openWidget}&` : '';
    const openOnlineModal = isOpenOnlineModal ? 'online-modal=true&' : '';
    const openProfileEditModal =
      propertyNotification === '-1' ? 'property-notification=-1&' : '';
    const id =
      propertyId || userProperties?.properties[propertiesLength - 1]._id;

    let path = `${siteMap.PropertyPage.pathWithoutParams}/${id}?${openWidgetParam}${openOnlineModal}${openProfileEditModal}`;

    // Redirect to property portfolio if the user  has more than 1 property
    if (!propertyId && propertiesLength > 1) {
      path = `${siteMap.PropertyPortfolioPage.pathWithoutParams}`;
    }

    return <LngRedirect to={path} />;
  }

  const path =
    openWidget === 'ONLINE'
      ? siteMap.OwnerLandingPageOnlineWidget.path
      : siteMap.OwnerLandingPage.path;

  const isIframeBenefit = iframeType === IIframeType.BENEFIT;

  const {
    readOwnerLandingPage: {
      imageAlt,
      imageUrl,
      metaDescription,
      metaKeywords,
      sections,
      metaTitle,
      subtitle,
      text,
      title,
    },
  } = landingPage;

  const sortedSections = sortByOrderIndex(sections ?? []) || [];

  return (
    <>
      <title>{metaTitle || t('buyer-portal.title')}</title>
      <meta name="title" content={metaTitle ?? ''} />
      <meta name="description" content={metaDescription ?? ''} />
      <meta property="og:title" content={metaTitle ?? ''} />
      <meta property="og:description" content={metaDescription ?? ''} />
      {imageUrl && <meta property="og:image" content={String(imageUrl)} />}
      <meta property="twitter:title" content={metaTitle ?? ''} />
      <meta property="twitter:description" content={metaDescription ?? ''} />
      {imageUrl && <meta property="twitter:image" content={String(imageUrl)} />}
      {metaKeywords?.map((item) => {
        const metaObject = getAttributesFromMeta(item);
        return (
          <meta
            key={metaObject.name}
            name={metaObject.name}
            content={metaObject.content}
          />
        );
      })}
      <HelmetContainer path={path} lang={lang} />
      {isIframe && !isIframeBenefit && (
        <IFrameContainer isOverflowVisible={isNoModal}>
          {isRedesignVersion && openWidget ? (
            <ValuationFirstStepScreen />
          ) : (
            <PropertyValuationWidgetContainer isOnLandingPage />
          )}
        </IFrameContainer>
      )}
      {isIframeBenefit && (
        <IFrameSectionContainer>
          {sortedSections?.map((section) => (
            <Fragment key={section.orderIndex}>
              {section.type === OwnerLpSectionType.Benefit && (
                <Benefits
                  section={section}
                  handleEvaluationWizardOpen={handleOpenEvaluationWizard}
                  isSlider
                />
              )}
            </Fragment>
          ))}
        </IFrameSectionContainer>
      )}
      {!isIframe && !isIframeBenefit && (
        <>
          <Hero
            {...(!isValuationWizardOpen && {
              imgSrc: imageUrl ?? '',
              imgAlt: imageAlt ?? '',
            })}
            isWithoutMargin
            mobileReverse={!!openWidget}
          >
            <>{!isMobileSize && <Mapbox isVisible={isValuationWizardOpen} />}</>
            <HeroSlider
              showAddressBar={activeStep > 0}
              address={selectedAddress}
              isDarkTheme
              isAuto
            >
              <Card
                header={subtitle ?? ''}
                titleFirstLine={title ?? ''}
                showAddressBar={activeStep > 0}
                content={text ?? ''}
              />
              <Card
                titleType="h2"
                header={t('landing-page.hero-slider.second-card.back-button')}
                titleFirstLine={
                  valuationType === ValuationType.Online
                    ? t(
                        'landing-page.hero-slider.second-card.online.title.first-line'
                      )
                    : t(
                        'landing-page.hero-slider.second-card.personal.title.first-line'
                      )
                }
                titleSecondLine={t(
                  'landing-page.hero-slider.second-card.title.second-line'
                )}
                content={
                  valuationType === ValuationType.Online
                    ? t('landing-page.hero-slider.second-card.online.content')
                    : t('landing-page.hero-slider.second-card.personal.content')
                }
                showAddressBar={activeStep > 0}
                cta={(c) => (
                  <IconWrapper onClick={closeEvaluationWizard}>
                    <Icon icon={ArrowButtonLeft} width={12} height={12} /> {c}
                  </IconWrapper>
                )}
              />
              <Card
                titleType="h3"
                header={t('landing-page.hero-slider.third-card.back-button')}
                titleFirstLine={
                  valuationType === ValuationType.Online
                    ? t(
                        'landing-page.hero-slider.third-card.online.title.first-line'
                      )
                    : t(
                        'landing-page.hero-slider.third-card.personal.title.first-line'
                      )
                }
                titleSecondLine={t(
                  'landing-page.hero-slider.third-card.title.second-line'
                )}
                content={
                  valuationType === ValuationType.Online
                    ? t('landing-page.hero-slider.third-card.online.content')
                    : t('landing-page.hero-slider.third-card.personal.content')
                }
                showAddressBar={activeStep > 0}
                cta={(c) => (
                  <IconWrapper onClick={closeEvaluationWizard}>
                    <Icon icon={ArrowButtonLeft} width={12} height={12} /> {c}
                  </IconWrapper>
                )}
              />
              <Card
                titleType="h4"
                header={t('landing-page.hero-slider.fourth-card.back-button')}
                titleFirstLine={
                  valuationType === ValuationType.Online
                    ? t(
                        'landing-page.hero-slider.fourth-card.online.title.first-line'
                      )
                    : t(
                        'landing-page.hero-slider.fourth-card.personal.title.first-line'
                      )
                }
                titleSecondLine={t(
                  'landing-page.hero-slider.fourth-card.title.second-line'
                )}
                content={
                  valuationType === ValuationType.Online
                    ? t('landing-page.hero-slider.fourth-card.online.content')
                    : t('landing-page.hero-slider.fourth-card.personal.content')
                }
                showAddressBar={activeStep > 0}
                cta={(c) => (
                  <IconWrapper onClick={closeEvaluationWizard}>
                    <Icon icon={ArrowButtonLeft} width={12} height={12} /> {c}
                  </IconWrapper>
                )}
              />
              <Card
                titleType="h5"
                header={t('landing-page.hero-slider.fifth-card.back-button')}
                titleFirstLine={
                  valuationType === ValuationType.Online
                    ? t(
                        'landing-page.hero-slider.fifth-card.online.title.first-line'
                      )
                    : t(
                        'landing-page.hero-slider.fifth-card.personal.title.first-line'
                      )
                }
                titleSecondLine={t(
                  'landing-page.hero-slider.fifth-card.title.second-line'
                )}
                content={
                  valuationType === ValuationType.Online
                    ? t('landing-page.hero-slider.fifth-card.online.content')
                    : t('landing-page.hero-slider.fifth-card.personal.content')
                }
                showAddressBar={activeStep > 0}
                cta={(c) => (
                  <IconWrapper onClick={closeEvaluationWizard}>
                    <Icon icon={ArrowButtonLeft} width={12} height={12} /> {c}
                  </IconWrapper>
                )}
              />
            </HeroSlider>
            {isRedesignVersion ? (
              <ValuationFirstStepScreen mobileReverse={!!openWidget} />
            ) : (
              <PropertyValuationWidgetContainer isOnLandingPage />
            )}
          </Hero>
          <ContentWithPadding>
            {sortedSections?.map((section) => (
              <Fragment key={section.orderIndex}>
                {section.type === OwnerLpSectionType.Benefit && (
                  <Benefits
                    section={section}
                    handleEvaluationWizardOpen={handleOpenEvaluationWizard}
                    isSlider
                    reduceToken
                  />
                )}

                {section.type === OwnerLpSectionType.Appointment && (
                  <Appointments rpSection={section} />
                )}

                {section.type === OwnerLpSectionType.Faq && (
                  <Faqs section={section} />
                )}

                {section.type === OwnerLpSectionType.Video && (
                  <Video
                    section={section}
                    handleEvaluationWizardOpen={handleOpenEvaluationWizard}
                  />
                )}

                {section.type === OwnerLpSectionType.OwnerBenefit && (
                  <BenefitsSection section={section} />
                )}

                {section.type === OwnerLpSectionType.OwnerFunction && (
                  <FunctionsSection section={section} />
                )}

                {section.type === OwnerLpSectionType.BuyerFinderTeaser && (
                  <BuyerFinderTeaser
                    section={section}
                    handleEvaluationWizardOpen={handleOpenEvaluationWizard}
                  />
                )}
              </Fragment>
            ))}
          </ContentWithPadding>
        </>
      )}
    </>
  );
};

export { OwnerLandingPage };
