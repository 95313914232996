import styled from 'styled-components';
import { MainButton } from '../../common/components/ui/buttons';
import { device } from '../../../style/theme';

export const Container = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const UploadFileBtn = styled(MainButton)`
  width: 300px;
  justify-content: center;
`;

export const LoaderContainer = styled.div<{ isLoadingMore?: boolean }>`
  width: 100%;
  height: ${({ isLoadingMore }) => (isLoadingMore ? 'auto' : '200px')};
  position: relative;
  padding: ${({ isLoadingMore }) => (isLoadingMore ? '36px' : '56px')};
  ${({ isLoadingMore }) => isLoadingMore && 'margin-top: 24px;'};
  ${({ isLoadingMore, theme }) =>
    !isLoadingMore &&
    `border-radius: 3px;border: solid 1px ${theme.ctaDisabledBackground};`};

  @media ${device.tablet} {
    padding: 16px;
  }
`;
