import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature } from '../../../../services/mapbox/interfaces';
import { FormError } from '../../interface';
import { IValuationForm, WidgetPropertyType } from '../interface';
import { Europe } from '../../../map/constants/points-of-interest';
import { IMapSettings, IMapPin } from '../../../map/interfaces';
import { Property, ValuationType } from '../../../../generated';
import { ITimifyParams } from '../../../timify/interfaces';
import { defaultMapSettings } from '../../../map/constants';

interface IState {
  isVWOpen: boolean;
  isAuthFlow: boolean;
  isRegisterFormOpen: boolean;
  isForgotPassword: boolean;
  isLoginFormOpen: boolean;
  isLoading: boolean;
  isDataFromTemporary: boolean;
  isConfirmEmailSent: boolean;
  isRegionalPage: boolean;
  isHighlight: boolean;
  activeStep: number;
  iframePropertyType?: WidgetPropertyType | null;
  errors?: FormError;
  timifyParams: ITimifyParams | null;
  valuationType?: ValuationType;
  userInput?: Partial<IValuationForm>;
  propertyDossier?: Partial<Property>;
  isRedirect?: boolean;
  selectedAddress?: Feature;
  selectedCountryCode?: string;
  relatedCountry: string;
  regionalBrokerId?: string;
  isTimifyWidgetOpen: boolean;
  map: {
    viewport: Record<string, unknown>;
    settings: IMapSettings;
    viewportCheckpoint?: Record<string, unknown>;
    markersCheckpoint?: IMapPin[];
    settingsCheckpoint?: IMapSettings;
  };
}

const initialState: IState = {
  isVWOpen: false,
  isAuthFlow: false,
  isRegisterFormOpen: false,
  isForgotPassword: false,
  isLoginFormOpen: false,
  isDataFromTemporary: false,
  isConfirmEmailSent: false,
  isRegionalPage: false,
  isLoading: false,
  isHighlight: false,
  relatedCountry: 'germany',
  iframePropertyType: null,
  valuationType: undefined,
  activeStep: 0,
  isRedirect: false,
  timifyParams: null,
  isTimifyWidgetOpen: false,
  map: {
    viewport: { ...Europe },
    settings: defaultMapSettings,
  },
};

export const valuationWizardV2Slice = createSlice({
  name: 'valuationWizardV2',
  initialState,
  reducers: {
    openVWAction(state) {
      state.isVWOpen = true;
    },
    closeVWModal(state) {
      state.isVWOpen = false;
    },
    resetVW() {
      return { ...initialState };
    },
    resetRegionalVW() {
      return {
        ...initialState,
        isRegionalPage: true,
        valuationType: ValuationType.Online,
      };
    },
    nextStepAction(state, action: PayloadAction<number | undefined>) {
      if (typeof action.payload === 'number') {
        state.activeStep = action.payload;
      } else {
        state.activeStep += 1;
      }
    },
    prevStepAction(state, action: PayloadAction<number | undefined>) {
      if (typeof action.payload === 'number') {
        state.activeStep = action.payload;
      } else {
        state.activeStep -= 1;
      }
    },
    setValuationTypeAction(state, action: PayloadAction<ValuationType>) {
      state.valuationType = action.payload;
    },
    updateFormStateAction(
      state,
      action: PayloadAction<Partial<IValuationForm> | undefined>
    ) {
      state.userInput = {
        ...state.userInput,
        ...action.payload,
      };
    },
    setIframePropertyType(
      state,
      action: PayloadAction<WidgetPropertyType | null>
    ) {
      state.iframePropertyType = action.payload;
    },
    setSelectedAddressAction(state, action: PayloadAction<Feature>) {
      state.selectedAddress = action.payload;
    },
    setRedirect(state, action: PayloadAction<boolean>) {
      state.isRedirect = action.payload;
    },
    setIsDataFromTemporary(state, action: PayloadAction<boolean>) {
      state.isDataFromTemporary = action.payload;
    },
    setIsConfirmEmailSent(state, action: PayloadAction<boolean>) {
      state.isConfirmEmailSent = action.payload;
    },
    setIsRegionalPage(state, action: PayloadAction<boolean>) {
      state.isRegionalPage = action.payload;
    },
    setSelectedCountryCode(state, action: PayloadAction<string>) {
      state.selectedCountryCode = action.payload;
    },
    setTimifyParams(state, action: PayloadAction<ITimifyParams | null>) {
      state.timifyParams = action.payload;
    },
    setPropertyDossierAction(state, action: PayloadAction<Partial<Property>>) {
      state.propertyDossier = {
        ...state.propertyDossier,
        ...action.payload,
      };
    },
    toggleAuthFlow(state, action: PayloadAction<boolean>) {
      state.isAuthFlow = action.payload;
    },
    toggleForgotPasswordForm(state, action: PayloadAction<boolean>) {
      state.isForgotPassword = action.payload;
    },
    toggleRegisterForm(state, action: PayloadAction<boolean>) {
      state.isRegisterFormOpen = action.payload;
    },
    toggleLoginForm(state, action: PayloadAction<boolean>) {
      state.isLoginFormOpen = action.payload;
    },
    toggleLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    toggleIsHighlight(state, action: PayloadAction<boolean>) {
      state.isHighlight = action.payload;
    },
    setErrors(state, action: PayloadAction<FormError>) {
      state.errors = action.payload;
    },
    clearErrors(state) {
      delete state.errors;
    },
    changeMapViewport(state, action) {
      state.map.viewportCheckpoint = state.map.viewport;
      state.map.viewport = { ...state.map.viewport, ...action.payload };
    },
    setMapSettings(state, action: PayloadAction<Partial<IMapSettings>>) {
      state.map.settingsCheckpoint = state.map.settings;
      state.map.settings = {
        ...state.map.settings,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openVWAction,
  closeVWModal,
  nextStepAction,
  prevStepAction,
  updateFormStateAction,
  setValuationTypeAction,
  setRedirect,
  setTimifyParams,
  setSelectedAddressAction,
  setIsDataFromTemporary,
  setSelectedCountryCode,
  setPropertyDossierAction,
  setIsConfirmEmailSent,
  setIsRegionalPage,
  setIframePropertyType,
  toggleLoading,
  toggleAuthFlow,
  toggleLoginForm,
  toggleForgotPasswordForm,
  toggleRegisterForm,
  toggleIsHighlight,
  setErrors,
  clearErrors,
  resetVW,
  setMapSettings,
  changeMapViewport,
  resetRegionalVW,
} = valuationWizardV2Slice.actions;

export const { name, reducer } = valuationWizardV2Slice;

export default valuationWizardV2Slice;
