import { FC, useState } from 'react';

import { UserState } from '../../../../../../../generated';
import { RegisterContainer } from '../../../../../components/fullscreen-modal/auth-container/register-container/register-container';
import { useSubmitFinancingRegister } from '../../../../hooks/submit-hooks/useSubmitFinancingRegister';
import { FinanceVerifyPhoneContainer } from './verify-phone-container/finance-verify-phone-container';
import { useIsMobileSize } from '../../../../../../common/hooks/useIsMobileSize';

interface IProps {
  isFinancingCertificate?: boolean;
}

const FinanceRegisterContainer: FC<IProps> = ({ isFinancingCertificate }) => {
  const [errors, setErrors] = useState<null | unknown>(null);
  const isMobileSize = useIsMobileSize();

  const { isConfirmEmailSent, userState, onRegisterSubmit, onOpenLogin } =
    useSubmitFinancingRegister({
      setErrors,
    });

  return (
    <RegisterContainer
      onRegisterSubmit={onRegisterSubmit}
      errors={errors}
      isConfirmEmailSent={isConfirmEmailSent}
      onOpenLogin={onOpenLogin}
      isFinancing
      isFinancingCertificate={isFinancingCertificate}
      isBoldCheckbox
      buttonPosition={isMobileSize ? 'static' : 'absolute'}
    >
      {userState === UserState.VerifyPhone && (
        <FinanceVerifyPhoneContainer setErrors={setErrors} />
      )}
    </RegisterContainer>
  );
};

export { FinanceRegisterContainer };
