import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as PencilIcon } from 'assets/streamline-light/interface-essential/edit/pencil.svg';
import { useUpdateFinancingVariantNameMutation } from '../../../../../generated';
import { Modal } from '../../../../common/components/modal';
import {
  MainButton,
  SecondaryButton,
} from '../../../../common/components/ui/buttons';
import Icon from '../../../../common/components/ui/icon';

const ModalTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const ModalTitle = styled.h2`
  color: #00305e;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1;
`;

const InputBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
`;

const Label = styled.label`
  color: rgba(0, 48, 94, 0.7);
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
`;

const Input = styled.input`
  border-radius: 3px;
  background: #f2f5f7;
  outline: none;
  border: 1px solid transparent;
  padding: 12px;
  outline: none;
  color: #00305e;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  width: 274px;
  height: 40px;

  &:focus {
    border-color: #00305e;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface EditVariantModalProps {
  active: { index: number; name: string } | null;
  onClose: () => void;
  refetch: () => void;
}

const EditVariantModal = ({
  active,
  onClose,
  refetch,
}: EditVariantModalProps) => {
  const { t } = useTranslation();
  const [updateFinancingVariantName, { isLoading }] =
    useUpdateFinancingVariantNameMutation({});

  const { financingId } = useParams<{ financingId: string }>();

  const [name, setName] = useState('');

  const onSave = async () => {
    const input = {
      name: name.trim(),
      index: active?.index || 0,
      financingId,
    };

    await updateFinancingVariantName({ input });
    refetch();
    onClose();
  };

  useEffect(() => {
    if (active) {
      setName(active.name);
    }
  }, [active]);

  return (
    <Modal isOpen={!!active}>
      <ModalTitleBox>
        <Icon icon={PencilIcon} color={'#00305E'} />
        <ModalTitle>{t('financing-portal-page.variant.edit.title')}</ModalTitle>
      </ModalTitleBox>

      <InputBox>
        <Label>{t('financing-portal-page.variant.edit.label')}:</Label>
        <Input
          disabled={isLoading}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </InputBox>

      <ButtonBox>
        <SecondaryButton
          isDisabled={isLoading}
          label={t('financing-portal-page.variant.edit.close')}
          onClick={onClose}
        />
        <MainButton
          isDisabled={name === active?.name || name.trim() === '' || isLoading}
          label={t('financing-portal-page.variant.edit.save')}
          onClick={onSave}
        />
      </ButtonBox>
    </Modal>
  );
};

export default EditVariantModal;
