import { FC, memo, useCallback, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import styled from 'styled-components';

import {
  CreatePropertyInput,
  LoginResponse,
  RegistrationBy,
  useConfirmPhoneMutation,
  useRequestVerificationCodeMutation,
  useResendConfirmationCodeMutation,
  UserState,
  UserType,
  useSignInMutation,
  useSignUpMutation,
  useUserSelfDeleteMutation,
  ValuationType,
} from '../../../../../../generated';
import { Feature } from '../../../../../../services/mapbox/interfaces';
import { ConfirmEmail } from '../../../../../auth/components/confirm-email';
import { PhoneVerification } from '../../../../../auth/components/forms/phone-verification';
import { RegisterForm } from '../../../../../auth/components/forms/register-form/register-form';
import { IRegisterForm } from '../../../../../auth/interfaces';
import {
  removeUserInput,
  setUserId,
  setUserInput,
  setUsername,
  setUserState,
  toggleLoading,
} from '../../../../../auth/redux/authSlice';
import { selectFormattedPhone } from '../../../../../auth/redux/selectors/selectFormattedPhone';
import { parseRegisterInput } from '../../../../../auth/utils/parse-register-input';
import { FormGridWrapperFixHeight } from '../../../../../common/components/form/form-grid';
import { Blurry } from '../../../../../common/components/ui/blurry';
import { SecondaryButton } from '../../../../../common/components/ui/buttons';
import { GlobalLoader } from '../../../../../common/components/ui/loaders/global-loader';
import { ParagraphTextWithHTML } from '../../../../../common/components/ui/typography';
import { StatusType } from '../../../../../common/components/verification-input/types';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { FORM_STEPS } from '../../../constants';
import { IValuationForm } from '../../../interface';
import { toggleRegisterForm } from '../../../redux/valuationWizardSlice';
import { parseValuationInput } from '../../../../utils/parse-valuation-input';
import { FormTitle } from '../../../../components/common/form-title';
import { VisibilityWrapper } from '../../../../components/common/visibility-wrapper';
import { MobileBack } from '../common/mobile-back';
import { Progress } from '../../../../components/common/progress';
import LoginOpen from '../../../../../auth/components/login-open';
import useGetLocalization from '../../../../../localization/get-localization';
import { useCreateTemporaryProperty } from '../../../hooks/useCreateTemporaryProperty';
import { useCreateProperty } from '../../../hooks/useCreateProperty';
import { device } from '../../../../../../style/theme';
import { useActualExperiment } from '../../../../../experiments/hooks/useActualExperiment';
import { useGetPortalType } from '../../../../../landing/hooks/useGetPortalType';

const LoaderContainer = styled.div<{ isiframe?: boolean }>`
  height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
  &&&& {
    > div {
      position: absolute;
    }
  }
`;

const Container = styled.div<{ isiframe?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: ${({ isiframe }) => (isiframe ? 'auto' : 'visible')};
  height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
`;

const Header = styled.div`
  display: block;
`;

const BackButton = styled(SecondaryButton)`
  align-self: flex-start;
`;

const PhoneVerificiationWrapper = styled.div`
  flex: 1;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;

const FieldRequirement = styled(ParagraphTextWithHTML)`
  font-weight: bold;
  font-size: 10px;
  line-height: 1.6;
  opacity: 0.8;
  letter-spacing: 0.5px;
  @media ${device.tablet} {
    padding-bottom: 56px;
  }
`;

interface IProps {
  openLogin?(): void;
}

const OnlineRegisterContainerBase: FC<IProps> = ({ openLogin }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const lang = useGetLocalization();

  const [verificationStatus, setVerificationStatus] = useState(
    StatusType.process
  );
  const [confirmEmailSent, setConfirmEmailSent] = useState(false);

  const userState = useAppSelector((state) => state.auth.userState);
  const formattedPhone = useAppSelector(selectFormattedPhone);
  const userId = useAppSelector((state) => state.auth.userInput?._id);
  const email = useAppSelector((state) => state.auth.userInput?.email);
  const username = useAppSelector((state) => state.auth.userInput?.username);
  const password = useAppSelector((state) => state.auth.userInput?.password);
  const firstName = useAppSelector((state) => state.auth.userInput?.name);
  const lastName = useAppSelector((state) => state.auth.userInput?.surname);
  const isRegisterFormLoading = useAppSelector((state) => state.auth.isLoading);
  const userInput = useAppSelector((state) => state.valuationWizard.userInput);
  const selectedAddress = useAppSelector(
    (state) => state.valuationWizard.selectedAddress
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const { isRegistrationByEmail, registrationBy } = useActualExperiment();

  const formFieldsWrapperRef = useRef<HTMLDivElement>(null);
  const [errors, setErrors] = useState<null | unknown>(null);

  // Mutations
  const [signUp] = useSignUpMutation();
  const [signIn] = useSignInMutation();
  const [confirmPhone] = useConfirmPhoneMutation();
  const [requestVerificationCode] = useRequestVerificationCodeMutation();
  const [resendConfirmationCode] = useResendConfirmationCodeMutation();
  const [changePhone] = useUserSelfDeleteMutation();
  const { createProperty } = useCreateProperty();
  const { createTemporaryProperty } = useCreateTemporaryProperty();
  const { isSeller } = useGetPortalType();

  const backToValuationForm = useCallback(
    () => dispatch(toggleRegisterForm(false)),
    [dispatch]
  );

  const onRegisterSubmit: SubmitHandler<IRegisterForm> = useCallback(
    async (formData) => {
      const input = parseRegisterInput({
        formData,
        lang,
        userType: isSeller ? UserType.Seller : UserType.Owner,
      });

      dispatch(setUserInput({ ...formData, password: input.password }));
      dispatch(toggleLoading(true));

      // Create the user account before phone verification
      try {
        const signUpResponse = await signUp({
          input: {
            ...input,
            hasPendingValuations: true,
            registrationBy: registrationBy || RegistrationBy.Mobile,
          },
        }).unwrap();

        if (signUpResponse?.signUp?._id) {
          const {
            _id: id,
            username: newUsername,
            userState: newUserState,
          } = signUpResponse.signUp;
          batch(() => {
            dispatch(setUserId(id));
            dispatch(setUsername(newUsername));
            dispatch(setUserState(newUserState));
          });

          // registration case
          if (isRegistrationByEmail) {
            const valuationInput = parseValuationInput(
              userInput as IValuationForm,
              selectedAddress as Feature
            ) as CreatePropertyInput;

            await createProperty({ valuationInput, userId: id });

            setConfirmEmailSent(true);
          }
        }
      } catch (error: unknown) {
        setErrors(error);
      } finally {
        dispatch(toggleLoading(false));
      }
    },
    [
      createProperty,
      dispatch,
      isRegistrationByEmail,
      isSeller,
      lang,
      registrationBy,
      selectedAddress,
      signUp,
      userInput,
    ]
  );

  const onVerifyPhone = useCallback(
    async (confirmationCode: string) => {
      setVerificationStatus(StatusType.wait);
      dispatch(toggleLoading(true));
      if (confirmationCode && username && password && firstName && lastName) {
        try {
          const confirmPhoneResult = await confirmPhone({
            input: {
              username,
              confirmationCode,
            },
          }).unwrap();

          // set user state VerifyEmail
          if (confirmPhoneResult?.confirmPhone) {
            dispatch(setUserState(confirmPhoneResult.confirmPhone));
          }

          const valuationInput = parseValuationInput(
            userInput as IValuationForm,
            selectedAddress as Feature
          ) as CreatePropertyInput;

          const ownerId = userId as string;

          await createTemporaryProperty({
            valuationInput,
            userId: ownerId,
          });

          try {
            // Initiate login to get the jwt token
            const signInResponse = await signIn({
              input: {
                userAuthenticationKey: username,
                password,
                requestAccessToken: true,
              },
            }).unwrap();

            if (signInResponse.signIn) {
              const signInParams = signInResponse.signIn as LoginResponse;
              dispatch(removeUserInput());

              await requestVerificationCode({
                input: {
                  username,
                  accessToken: signInParams?.jwt?.token ?? '',
                  refreshToken: signInParams?.jwt?.refreshToken ?? '',
                  attributeName: 'email',
                  firstName,
                  lastName,
                },
              });
              setConfirmEmailSent(true);
            }
          } catch (error: unknown) {
            setErrors(error);
          }
        } catch (error: unknown) {
          setErrors(error);
          setVerificationStatus(StatusType.error);
        } finally {
          dispatch(toggleLoading(false));
        }
      }
    },
    [
      username,
      password,
      firstName,
      lastName,
      dispatch,
      confirmPhone,
      userInput,
      selectedAddress,
      createTemporaryProperty,
      userId,
      signIn,
      requestVerificationCode,
    ]
  );

  const onResendConfirmationCode = useCallback(() => {
    if (email) {
      resendConfirmationCode({
        email,
      });
    }
  }, [resendConfirmationCode, email]);

  const onChangePhone = useCallback(async () => {
    if (username) {
      try {
        await changePhone({ username }).unwrap();

        batch(() => {
          dispatch(setUserId(''));
          dispatch(setUsername(''));
          dispatch(setUserState(UserState.Unauthenticated));
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [dispatch, changePhone, username]);

  return (
    <Container isiframe={isIframe}>
      {!isIframe && (
        <MobileBack
          title={t('landing-page.primary-button.online-valuation.label')}
        />
      )}
      {isRegisterFormLoading && (
        <LoaderContainer isiframe={isIframe}>
          <GlobalLoader />
        </LoaderContainer>
      )}
      <VisibilityWrapper isiframe={isIframe} isVisible={!isRegisterFormLoading}>
        {userState === UserState.Unauthenticated && (
          <>
            <Header>
              <Progress activeBullet={FORM_STEPS} bullets={FORM_STEPS} />
              <FormTitle text={t('register.form.title')} />
            </Header>

            <FormGridWrapperFixHeight wrapperRef={formFieldsWrapperRef}>
              <LoginOpen handleClick={openLogin} />
              <RegisterForm
                skipPassword
                onSubmit={onRegisterSubmit}
                valuationType={ValuationType.Online}
                errors={errors}
              />
              <FieldRequirement
                content={t('register.form.required.fields.message')}
              />
            </FormGridWrapperFixHeight>
            <Blurry
              scrolledElementRef={formFieldsWrapperRef}
              position={{
                left: '0',
                bottom: !isMobileSize || isIframe ? '48px' : '0',
              }}
            />
            {(!isMobileSize || isIframe) && (
              <BackButton
                label={t('button.back')}
                onClick={backToValuationForm}
              />
            )}
          </>
        )}
        {userState === UserState.VerifyPhone && (
          <>
            <Header>
              <FormTitle text={t('phone-verification.title')} />
            </Header>
            <PhoneVerificiationWrapper>
              <PhoneVerification
                phoneNumber={formattedPhone ?? ''}
                onVerifyPhone={onVerifyPhone}
                status={verificationStatus}
                resendCode={onResendConfirmationCode}
                changePhone={onChangePhone}
              />
            </PhoneVerificiationWrapper>
          </>
        )}
        {userState === UserState.VerifyEmail && confirmEmailSent && (
          <ConfirmEmail
            title={t('modal.register.confirm-email.title')}
            textContent={t('modal.register.confirm-email.content')}
          />
        )}
      </VisibilityWrapper>
    </Container>
  );
};

const OnlineRegisterContainer = memo(OnlineRegisterContainerBase);

export { OnlineRegisterContainer };
