import { SubmitHandler } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';

import { ILoginForm } from '../../../../../auth/interfaces';
import {
  LoginResponse,
  useRequestVerificationCodeMutation,
  UserState,
  useSignInMutation,
} from '../../../../../../generated';
import {
  login,
  setAnonymousUser,
  setUserId,
  setUserInput,
  setUsername,
  setUserState,
  toggleIsRegisterModalOpen,
  toggleLoading,
  toggleModalOpacity,
} from '../../../../../auth/redux/authSlice';
import { client } from '../../../../../../services/graphql/graphql';
import { IValuationForm } from '../../../../form-property-valuation-wizard/interface';
import { useAppSelector } from '../../../../../common/hooks';
import {
  toggleLoginForm,
  toggleRegisterForm,
  setTimifyParams,
  toggleForgotPasswordForm,
} from '../../../redux/valuationWizardV2Slice';
import { ITimifyParams } from '../../../../../timify/interfaces';
import { MODAL_ANIMATION_DURATION } from '../../../../../common/components/modal/modal';

type TProps = {
  onSuccess: (formData: IValuationForm, ownerId: string) => void;
  setErrors: (errors: unknown) => void;
  setConfirmEmailSent: (isSent: boolean) => void;
  reservationSuccess: boolean;
};

type TReturn = {
  isLoginFormLoading: boolean;
  isIframe?: boolean;
  userState: UserState;
  onLoginSubmit: (formData: ILoginForm) => void;
  onOpenForgotPassword: () => void;
  timifyParams: ITimifyParams | null;
};

const usePersonalLogin = ({
  onSuccess,
  setErrors,
  reservationSuccess,
  setConfirmEmailSent,
}: TProps): TReturn => {
  const dispatch = useDispatch();

  const [inputPassword, setInputPassword] = useState('');
  const [temporaryUserCredentials, setTemporaryUserCredentials] = useState({
    username: '',
    accessToken: '',
    refreshToken: '',
    attributeName: 'email',
    firstName: '',
    lastName: '',
  });

  const isLoginFormLoading = useAppSelector((state) => state.auth.isLoading);
  const userState = useAppSelector((state) => state.auth.userState);
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const userInput = useAppSelector(
    (state) => state.valuationWizardV2.userInput
  );
  const timifyParams = useAppSelector(
    (state) => state.valuationWizardV2.timifyParams
  );
  const isConfirmedGuest = useAppSelector(
    (state) => state.auth.isConfirmedGuest
  );
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);

  // Mutations
  const [signIn, { data: signInData }] = useSignInMutation();
  const [requestConfirmationCode] = useRequestVerificationCodeMutation();

  const onOpenForgotPassword = useCallback(() => {
    dispatch(toggleForgotPasswordForm(true));
    dispatch(toggleLoginForm(false));
  }, [dispatch]);

  const onLoginSubmit: SubmitHandler<ILoginForm> = useCallback(
    async (formData) => {
      const { userAuthenticationKey, password } = formData;
      setInputPassword(password);

      if (userAuthenticationKey && password) {
        try {
          dispatch(toggleLoading(true));
          dispatch(toggleModalOpacity(true));
          // Initiaite login to get the jwt token
          const signInResponse = await signIn({
            input: {
              userAuthenticationKey,
              password,
              requestAccessToken: true,
            },
          }).unwrap();

          if (signInResponse.signIn) {
            const { user, jwt } = signInResponse.signIn as LoginResponse;

            setTemporaryUserCredentials({
              username: user?.username,
              accessToken: jwt?.token ?? '',
              refreshToken: jwt?.refreshToken ?? '',
              attributeName: 'email',
              firstName: user?.name,
              lastName: user?.surname,
            });

            batch(() => {
              dispatch(setUserId(user?._id));
              dispatch(setUsername(user?.username));
              dispatch(setUserState(user?.userState));
            });
          }
        } catch (error: unknown) {
          setErrors(error);
        } finally {
          dispatch(toggleLoading(false));
          setTimeout(() => {
            dispatch(toggleModalOpacity(false));
          }, MODAL_ANIMATION_DURATION);
        }
      }
    },
    [dispatch, setErrors, signIn]
  );

  useEffect(() => {
    if (signInData) {
      const loginResponse = signInData.signIn as LoginResponse;
      if (loginResponse?.jwt?.token) {
        client.setHeader(
          'Authorization',
          `Bearer ${loginResponse?.jwt?.token ?? ''}`
        );

        batch(() => {
          dispatch(setAnonymousUser(false));
          dispatch(login(loginResponse));
          dispatch(setUserState(loginResponse.user.userState));
          onSuccess(userInput as IValuationForm, loginResponse.user._id);
        });
      } else if (loginResponse.user && !loginResponse.jwt) {
        batch(() => {
          const {
            user: { name, surname, phone, email, termsAndConditions },
          } = loginResponse;
          dispatch(toggleRegisterForm(true));
          dispatch(toggleIsRegisterModalOpen(true));
          dispatch(setUserState(userState));
          dispatch(
            setUserInput({
              name,
              surname,
              phonePrefix: '',
              phone,
              email,
              password: inputPassword,
              confirmPassword: inputPassword,
              termsAndConditions,
            })
          );
        });
      }
    }
  }, [dispatch, inputPassword, onSuccess, signInData, userInput, userState]);

  useEffect(() => {
    const sendConfirmEmail = async () => {
      if (reservationSuccess && timifyParams) {
        dispatch(setTimifyParams(null));
        if (temporaryUserCredentials?.username) {
          const confirmationCodeResponse = await requestConfirmationCode({
            input: { ...temporaryUserCredentials },
          }).unwrap();

          if (confirmationCodeResponse?.requestVerificationCode) {
            setConfirmEmailSent(true);
          }
        } else if (isConfirmedGuest) {
          setConfirmEmailSent(true);
        } else if (!isAnonymousUser) {
          setConfirmEmailSent(true);
        }
      }
    };

    sendConfirmEmail();
  }, [
    reservationSuccess,
    timifyParams,
    temporaryUserCredentials,
    requestConfirmationCode,
    isConfirmedGuest,
    isAnonymousUser,
    onSuccess,
    userInput,
    dispatch,
    setConfirmEmailSent,
  ]);

  return {
    onLoginSubmit,
    timifyParams,
    onOpenForgotPassword,
    isLoginFormLoading,
    isIframe,
    userState,
  };
};

export { usePersonalLogin };
