import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import financingIcon1 from 'assets/images/icons/financing-landing-page/financing-icon-1.png';
import financingIcon2 from 'assets/images/icons/financing-landing-page/financing-icon-2.png';
import styled from 'styled-components';
import { device } from '../../../../style/theme';
import { PrimaryCTAButton } from '../../../common/components/ui/buttons';
import { useOpenFinancing } from '../../../forms/form-financing/hooks/callback-hooks/useOpenFinancing';
import { FinancingType } from '../../../../generated';

const Container = styled.div`
  position: absolute;
  bottom: -24px;
  right: 48px;
  display: flex;
  flex-direction: column;
  width: 466px;
  z-index: 2;
  @media ${device.tablet} {
    position: static;
    padding: 0 16px;
  }
`;

interface IProps {
  isEmptyState: boolean;
}

const FinancingWidgets: FC<IProps> = ({ isEmptyState }) => {
  const { t } = useTranslation();
  const { openFinancing } = useOpenFinancing({});

  return (
    <Container>
      <PrimaryCTAButton
        isFirst
        image={financingIcon1}
        title={t(
          isEmptyState
            ? 'financing-portal-page.widgets.financing-request.title'
            : 'financing-portal-page.request-financing.label'
        ).toUpperCase()}
        description={t(
          'financing-portal-page.widgets.financing-request.description'
        )}
        onClick={() => openFinancing(FinancingType.Financing)}
      />
      <PrimaryCTAButton
        titleNoWrap
        image={financingIcon2}
        title={t(
          'financing-portal-page.widgets.financing-certificate-creation.title'
        ).toUpperCase()}
        description={t(
          'financing-portal-page.widgets.financing-certificate-creation.description'
        )}
        onClick={() => openFinancing(FinancingType.FinancingCertificate)}
      />
    </Container>
  );
};

export default FinancingWidgets;
