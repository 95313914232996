import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ParagraphText } from '../../../../common/components/ui/typography';
import BigCardContainer from '../elements/big-card-container';
import { CardContent } from '../elements/card-content';
import { CardContentHolder } from '../elements/card-content-holder';
import { CardTitle } from '../elements/card-title';

const MarketPriceCardElementsHolder = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0 12px;
`;

const MarketDataUpdateMessageContainer = styled.div`
  font-size: 12px;
  line-height: 1.33;
  border-radius: 3px;
  padding: 12px 12px;
  border: solid 1px #dacebc;
  background-color: rgba(162, 133, 87, 0.2);
`;

const MarketDataUpdateDate = styled(ParagraphText)`
  font-weight: bold;
  font-size: 12px;
  padding: 0 12px 0 0;
  display: inline-block;
`;
const MarketDataUpdateMessage = styled(ParagraphText)`
  font-size: 12px;
  display: inline-block;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  #market-price.tooltip {
    max-width: 95%;
  }
  > div:last-child {
    margin-left: 12px;
    margin-bottom: 8px;
  }
`;

interface IProps {
  title: string;
  content: string;
  marketDataMessage: string;
  children?: JSX.Element | JSX.Element[];
}

const OnOfficePriceCardBase = ({
  title,
  content,
  marketDataMessage,
  children,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <BigCardContainer>
      <CardContentHolder>
        <Row>
          <CardTitle content={title} />
        </Row>
        <CardContent content={content} />
      </CardContentHolder>
      <CardContentHolder margin="8px 0 0 0">
        <MarketPriceCardElementsHolder>
          {children}
        </MarketPriceCardElementsHolder>
      </CardContentHolder>
      <CardContentHolder>
        <MarketDataUpdateMessageContainer>
          <MarketDataUpdateDate
            content={t('valuation.overview.on-office-price.message.more-info')}
          />
          <MarketDataUpdateMessage content={marketDataMessage} />
        </MarketDataUpdateMessageContainer>
      </CardContentHolder>
    </BigCardContainer>
  );
};

const OnOfficePriceCard = memo(OnOfficePriceCardBase);

export { OnOfficePriceCard };
