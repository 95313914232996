import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { MainButton } from '../../../../common/components/ui/buttons';

export const Container = styled.div`
  width: 1186px;
  margin-bottom: 184px;
  position: relative;
  left: -41px;

  @media ${device.tablet} {
    width: 100%;
    margin: 0 !important;
    position: static;
    font-size: 0;
  }
`;

export const Img = styled.img`
  width: 545px; // 545 / 612
  height: 640px; // 640 / 719
  object-fit: contain;
  position: relative;
  z-index: 2;

  @media ${device.tablet} {
    object-fit: cover;
    height: 400px;
    width: 100%;
  }
`;

export const ContentBox = styled.div`
  position: absolute;
  left: 496px;
  top: 0;
  height: 764px;
  width: 690px;
  z-index: 1;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    position: static;
    height: auto;
    width: 100%;
  }
`;

export const Subtitle = styled.h3`
  text-transform: uppercase;
  margin: 0 0 8px 98px;
  height: 20px;
  opacity: 0.8;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.67;
  letter-spacing: 1px;
  color: #00305e;

  @media ${device.tablet} {
    display: none;
  }
`;

export const Title = styled.h2`
  margin: 0 0 32px 98px;
  height: 48px;
  font-family: 'Source Serif Pro';
  font-size: 36px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #002849;

  @media ${device.tablet} {
    display: none;
  }
`;

export const SubtitleMobile = styled.h3`
  margin: 16px auto 4px auto;
  opacity: 0.8;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.6;
  letter-spacing: 1px;
  color: #00305e;
  display: none;
  text-transform: uppercase;
  text-align: center;

  @media ${device.tablet} {
    display: block;
  }
`;

export const TitleMobile = styled.h2`
  margin: 0 auto 16px auto;
  font-family: 'Source Serif Pro';
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.5px;
  color: #00305e;
  display: none;
  text-align: center;

  @media ${device.tablet} {
    display: block;
  }
`;

export const BlueBox = styled.div`
  padding: 48px 49px 48px 98px;
  color: #fff;
  background: #002849;
  border-radius: 3px;
  flex: 1;

  @media ${device.tablet} {
    padding: 16px;
    border-radius: 0;
  }
`;

export const InfoBox = styled.div`
  display: flex;
  gap: 23px;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media ${device.tablet} {
    display: none;
  }
`;

export const IconBox = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 100%;
`;

export const IconBoxMobile = styled.div`
  margin: 0 auto 6px auto;
  height: 48px;
  width: 48px;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 100%;
  display: none;

  @media ${device.tablet} {
    display: flex;
  }
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;

  @media ${device.tablet} {
    height: 24px;
    width: 24px;
  }
`;

export const Poll = styled.div`
  border: dashed 1px #979797;
  height: 88px;
`;

export const TextContainer = styled.div`
  flex: 1;
`;

export const TextTitle = styled.h3`
  height: 32px;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.5px;
  margin: 0 0 16px 0;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
    margin: 0 4px 8px 4px;
    text-align: center;
  }
`;

export const ExpandedText = styled.p`
  height: 96px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  margin: 0 0 32px 0;

  &:last-child {
    margin-bottom: 16px;
  }

  @media ${device.tablet} {
    font-family: Roboto;
    line-height: 1.71;
    margin: 0 0 16px 0;
    height: auto;

    &:last-child {
      margin-bottom: 24px;
    }
  }
`;

export const CTAButton = styled(MainButton)`
  padding: 14px 24px;
  border-radius: 3px;
  text-transform: uppercase;

  @media ${device.tablet} {
    margin: 0 auto;
  }
`;
