import { siteMap } from '../site-map';

const useDownloadExposePageLogic = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const exposeRequestId = urlParams.get('exposeRequestId') || '';
  const sellerPropertyId = urlParams.get('sellerPropertyId') || '';
  const pdfToken = urlParams.get('pdfToken') || '';
  if (
    window.location.href.includes(siteMap.DownloadExpose.pathWithoutParams) &&
    exposeRequestId &&
    pdfToken
  ) {
    return `${siteMap.LoginBuyerPage.path}/?exposeRequestId=${exposeRequestId}&pdfToken=${pdfToken}`;
  }
  if (
    window.location.href.includes(
      siteMap.DownloadSellerExpose.pathWithoutParams
    ) &&
    sellerPropertyId &&
    pdfToken
  ) {
    return `${siteMap.LoginSellerPage.path}/?sellerPropertyId=${sellerPropertyId}&pdfToken=${pdfToken}`;
  }
  return null;
};

export { useDownloadExposePageLogic };
