import 'swiper/swiper-bundle.css';

import { easeQuadInOut } from 'd3-ease';
import { interpolate } from 'd3-interpolate';
import {
  cloneElement,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Animate } from 'react-move';
import styled, { ThemeContext } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useTranslation } from 'react-i18next';
import { device } from '../../../../../style/theme';
import { useAppSelector } from '../../../hooks';
import { hexToRGB } from '../../../utils/hex-to-rgb';
import { AddressBar } from './address-bar';
import Icon from '../icon';
import { ReactComponent as ArrowButtonLeft } from '../../../../../assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';

type SwiperInstance = {
  slideTo: (index: number, speed: number) => void;
};

interface IProps {
  children: JSX.Element | JSX.Element[];
  showAddressBar?: boolean;
  isDarkTheme?: boolean;
  addressBarTitle?: string;
  addressBarRadiusInfo?: string;
  address?: string;
  topOffset?: number;
  newPropertiesCount?: number;
  showShadow?: boolean;
  isSPCreatedViaAnotherPortal?: boolean | null;
  showEditValuation?: boolean | null;
  showEditFinancing?: boolean | null;
  code?: string;
  onGoBack?: () => void;
  isAuto?: boolean;
  isPropertyMarketed?: boolean;
}

interface ContainerProps {
  topOffset?: number;
  showShadow?: boolean;
  isAuto?: boolean;
}

const CardContainer = styled.div<ContainerProps>`
  position: absolute;
  align-items: center;
  bottom: ${(props) => props.topOffset ?? -48}px;
  left: 48px;
  width: 688px;
  box-shadow: ${({ showShadow = true }) =>
    showShadow && '0 8px 30px 0 rgba(0, 0, 0, 0.09)'};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 1;
  .swiper-container {
    * {
      color: inherit;
    }
  }

  ${(props) =>
    props.isAuto &&
    `
    .swiper-wrapper {
      height: auto !important;
    }
  `}
  @media ${device.tablet} {
    position: static;
    width: 100%;
    max-width: 688px;
    margin-bottom: 12px;
    margin: 0 auto;
  }
`;

const IconWrapper = styled.div`
  margin-right: 8px;
  cursor: pointer;
  width: 12px;
  height: 12px;
`;

const GoBackButton = styled.div`
  background-color: ${(props) => props.theme.logoBlue};
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: ${(props) => props.theme.white};
  height: 32px;
  padding: 6px 24px;
  cursor: pointer;
`;

const HeroSliderBase = ({
  children,
  showAddressBar,
  addressBarTitle,
  addressBarRadiusInfo,
  address,
  topOffset,
  showShadow,
  newPropertiesCount,
  isSPCreatedViaAnotherPortal,
  showEditValuation,
  showEditFinancing,
  code,
  onGoBack,
  isDarkTheme,
  isAuto,
  isPropertyMarketed,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const sliderRef = useRef<SwiperInstance>();
  const [currentColorScheme, setCurrentColorScheme] = useState({
    color: themeContext.blue,
    backgroundColor: hexToRGB('#ffffff', 0.95),
  });

  const activeStepVW = useAppSelector(
    (state) => state.valuationWizard.activeStep
  );
  const activeStepSP = useAppSelector(
    (state) => state.searchProfile.activeStep
  );

  const isEvaluationWizardOpen = useAppSelector(
    (state) => state.valuationWizard.isValuationWizardOpen
  );
  const isSPCOpen = useAppSelector((state) => state.searchProfile.isSPCOpen);

  const isRegisterFormOpenVW = useAppSelector(
    (state) => state.valuationWizard.isRegisterFormOpen
  );
  const isLoginFormOpenVW = useAppSelector(
    (state) => state.valuationWizard.isLoginFormOpen
  );
  const isRegisterFormOpenSP = useAppSelector(
    (state) => state.searchProfile.isRegisterFormOpen
  );
  const isLoginFormOpenSP = useAppSelector(
    (state) => state.searchProfile.isLoginFormOpen
  );

  useEffect(() => {
    if (isEvaluationWizardOpen) {
      sliderRef?.current?.slideTo(
        isRegisterFormOpenVW
          ? 3
          : isLoginFormOpenVW
          ? 5
          : activeStepVW < 4
          ? 1
          : 2,
        665
      );
      setCurrentColorScheme({
        color: '#fff',
        backgroundColor: hexToRGB(themeContext.logoBlue, 0.95),
      });
    } else if (isSPCOpen) {
      let slideTo = 0;
      if (!isRegisterFormOpenVW && !isLoginFormOpenSP) {
        slideTo = activeStepSP + 1;
      } else if (isRegisterFormOpenVW) {
        slideTo = 3;
      } else if (isLoginFormOpenSP) {
        slideTo = 4;
      }
      sliderRef?.current?.slideTo(slideTo, 665);
      setCurrentColorScheme({
        color: '#fff',
        backgroundColor: hexToRGB(themeContext.logoBlue, 0.95),
      });
    } else {
      sliderRef?.current?.slideTo(0, 665);
      setCurrentColorScheme({
        color: isDarkTheme ? themeContext.white : themeContext.blue,
        backgroundColor: hexToRGB(
          isDarkTheme ? themeContext.logoBlue : themeContext.white,
          0.95
        ),
      });
    }
    return () => {
      setCurrentColorScheme({
        color: themeContext.blue,
        backgroundColor: hexToRGB('#ffffff', 0.95),
      });
    };
  }, [
    activeStepVW,
    activeStepSP,
    isEvaluationWizardOpen,
    isSPCOpen,
    isRegisterFormOpenVW,
    isLoginFormOpenVW,
    isRegisterFormOpenSP,
    themeContext.blue,
    themeContext.logoBlue,
    isLoginFormOpenSP,
    isDarkTheme,
    themeContext.white,
  ]);

  return (
    <Animate
      start={{
        color: currentColorScheme.color,
        backgroundColor: currentColorScheme.backgroundColor,
      }}
      update={{
        color: [currentColorScheme.color],
        backgroundColor: [currentColorScheme.backgroundColor],
        timing: { duration: 665, ease: easeQuadInOut, delay: 200 },
      }}
      interpolation={(begValue, endValue) => interpolate(begValue, endValue)}
    >
      {({
        color: interpolatedColor,
        backgroundColor: interpolatedBackgroundColor,
      }) => (
        <CardContainer
          topOffset={topOffset}
          isAuto={isAuto}
          showShadow={showShadow}
        >
          {onGoBack && !isEvaluationWizardOpen && (
            <GoBackButton onClick={onGoBack}>
              <IconWrapper>
                <Icon
                  icon={ArrowButtonLeft}
                  width={12}
                  height={12}
                  color={themeContext.white}
                />
              </IconWrapper>
              {t('button.go-back')}
            </GoBackButton>
          )}
          <AddressBar
            code={code}
            show={showAddressBar}
            address={address}
            title={addressBarTitle}
            radiusInfo={addressBarRadiusInfo}
            newPropertiesCount={newPropertiesCount}
            showEditValuation={showEditValuation}
            showEditFinancing={showEditFinancing}
            showEditSPButton={isSPCreatedViaAnotherPortal}
            isPropertyMarketed={isPropertyMarketed}
          />
          <Swiper
            allowTouchMove={false}
            autoHeight
            onSwiper={(instance) => {
              sliderRef.current = instance;
            }}
          >
            {Array.isArray(children) ? (
              children?.map((child, index) => (
                <SwiperSlide
                  key={`${child.key} - ${Object.values(
                    child.props
                  )} - ${index}`}
                >
                  {child ? (
                    cloneElement(child, {
                      interpolatedBackgroundColor,
                      interpolatedColor,
                    })
                  ) : (
                    <></>
                  )}
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                {cloneElement(children, {
                  interpolatedBackgroundColor,
                  interpolatedColor,
                })}
              </SwiperSlide>
            )}
          </Swiper>
        </CardContainer>
      )}
    </Animate>
  );
};

const HeroSlider = memo(HeroSliderBase);

export { HeroSlider };
