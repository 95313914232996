import { useGetPortalType } from '../../landing/hooks/useGetPortalType';

const propertyIdName = 'propertyId';

const useGetPropertyId = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  const propertyIdFromUrl = urlParams.get(propertyIdName) || '';
  const propertyIdFromStorage = localStorage.getItem(propertyIdName);
  const { isSeller } = useGetPortalType();
  let propertyId = '';

  if (isSeller) {
    return propertyId;
  }
  if (propertyIdFromUrl) {
    propertyId = propertyIdFromUrl;
    localStorage.setItem(propertyIdName, propertyIdFromUrl);
  } else if (propertyIdFromStorage) {
    propertyId = propertyIdFromStorage;
  }

  return propertyId;
};

export { useGetPropertyId };
