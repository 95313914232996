import styled, { css } from 'styled-components';
import { hexToRGB } from '../../../../../../../common/utils/hex-to-rgb';

export const RadioContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const RadioButton = styled.input<{ isChecked: boolean }>`
  margin-right: 12px;
  z-index: 1;
  border-radius: 50%;
  width: 16px !important;
  min-width: 16px !important;
  max-width: 16px !important;
  height: 16px !important;
  cursor: pointer;
  flex: 0 0 auto;
  outline: none;
  background: #fff;
  border: solid 1px
    ${({ theme, isChecked }) => hexToRGB(theme.blue, isChecked ? 1 : 0.5)};
  ${({ isChecked, theme }) =>
    isChecked &&
    css`
      &::after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin-top: 4px;
        margin-left: 4px;
        background: ${theme.blue};
      }
    `}
`;

export const Text = styled.div`
  opacity: 0.5;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;
