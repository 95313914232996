import { useMemo } from 'react';
import {
  propertyTypeBoxes,
  reasonBoxes,
} from '../../forms/form-financing/constants';
import { FinancingVariantData } from '../../../generated';

interface IProps {
  financingVariantData?: FinancingVariantData;
}

const useGetReasonAndType = ({ financingVariantData }: IProps) => {
  const propertyType = useMemo(() => {
    return financingVariantData
      ? propertyTypeBoxes.find(
          (item) => item.value === financingVariantData.propertyType
        )?.title
      : '';
  }, [financingVariantData]);

  const reason = useMemo(() => {
    return financingVariantData
      ? reasonBoxes.find((item) => item.value === financingVariantData?.reason)
          ?.title
      : '';
  }, [financingVariantData]);
  return {
    propertyType,
    reason,
  };
};

export { useGetReasonAndType };
