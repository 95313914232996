import 'mapbox-gl/dist/mapbox-gl.css';

import mapPin from 'assets/svg/map-pin.svg';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import ReactMapGL, { Marker, Popup } from 'react-map-gl';
import styled from 'styled-components';

import { Coordinates } from '../../../../generated';
import { useAppSelector, useWindowSize } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { Legend } from '../../../map/components/legend';
import { ReachabilityLegend } from '../../../map/components/reachability-legend';

interface IProps {
  isVisible: boolean;
  coordinates: Coordinates;
}

const MarkerImg = styled.img``;

const Container = styled.div<{
  width?: string;
  left?: string;
  top?: string;
  isVisible?: boolean;
  position?: string;
  hasTopRadius?: boolean;
}>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  position: ${({ position }) => position};
  pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
  transform: ${({ top }) => `translateY(${top})`};
  left: ${({ left }) => left};
  z-index: 0;
  transition: transform 665ms ease-in-out, opacity 665ms ease;
  will-change: transform, opacity;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: hidden;
  ${({ hasTopRadius }) => hasTopRadius && `border-radius: 3px`};
  width: 100%;

  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib-inner {
    display: none !important;
  }
`;

const StyledPopup = styled(Popup)`
  .mapboxgl-popup-content {
    padding: 8px;
    font-family: 'Source Serif Pro', serif;
    color: ${(props) => props.theme.blue};
    font-size: 14px;
    background-color: ${(props) => props.theme.white};
  }
  .mapboxgl-popup-tip {
    border-top-color: ${(props) => props.theme.white};
  }
`;

const MapboxBase = ({ isVisible, coordinates }: IProps): JSX.Element => {
  const isValuationWizardOpen = useAppSelector(
    (state) => state.valuationWizard.isValuationWizardOpen
  );
  const isMapAtBottom = useAppSelector(
    (state) => state.dynamicMapUtils.showMap
  );
  const viewport = useAppSelector((state) => state.map.viewport);
  const settings = useAppSelector((state) => state.map.settings);

  const { popup } = useAppSelector((state) => state.map);
  const showLegend = useAppSelector(
    (state) => state.dynamicMapUtils.showLegend
  );
  const showReachabilityLegend = useAppSelector(
    (state) => state.dynamicMapUtils.showReachabilityLegend
  );

  const isMobileSize = useIsMobileSize();
  const windowSize = useWindowSize();

  const { longitude, latitude } = coordinates;

  const [innerViewport, setInnerViewport] = useState<Record<string, unknown>>({
    ...{ ...viewport, ...coordinates, zoom: 13, width: '100%' },
  });

  const onViewportChange = (newViewport: Record<string, unknown>) => {
    setInnerViewport(newViewport);
  };

  const left = useMemo(
    () => `${((windowSize?.width ?? 0) - 690 - 400 - 14) / 2}px`,
    [windowSize?.width]
  );

  useEffect(() => {
    let isCanceled = false;

    if (!isCanceled) {
      setInnerViewport({
        ...viewport,
        ...coordinates,
        zoom: 13,
        width: '100%',
      });
    }

    return () => {
      isCanceled = true;
    };
  }, [viewport, coordinates]);

  const mapboxContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapboxContainerRef.current) {
      return () => {};
    }

    const mapContainer = mapboxContainerRef.current;

    const wheelHandler = (e: Event) => {
      const wheelEvent = e as WheelEvent;

      if (wheelEvent.ctrlKey || wheelEvent.metaKey || wheelEvent.altKey) {
        return;
      }

      e.stopPropagation();
    };

    mapContainer.addEventListener('wheel', wheelHandler, true);

    return () => {
      mapContainer.removeEventListener('wheel', wheelHandler);
    };
  });

  return (
    <Container
      ref={mapboxContainerRef}
      isVisible={isVisible}
      left={isMapAtBottom ? left : '0'}
      top={isMapAtBottom ? '50px' : '0'}
      position={isMapAtBottom ? 'fixed' : 'absolute'}
      hasTopRadius={isMapAtBottom}
      style={{ height: isMobileSize ? '160px' : '100%' }}
    >
      {!isMobileSize && !isValuationWizardOpen && showLegend && <Legend />}
      {!isMobileSize && !isValuationWizardOpen && showReachabilityLegend && (
        <ReachabilityLegend />
      )}
      <ReactMapGL
        width={'100%'}
        height={isMobileSize ? '160px' : '100%'}
        style={{ minHeight: '100%' }}
        {...innerViewport}
        {...settings}
        getCursor={() => 'default'}
        onViewportChange={onViewportChange}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY ?? ''}
        mapStyle="mapbox://styles/mapbox/light-v9?optimize=true"
        asyncRender
      >
        <Marker longitude={longitude || 0} latitude={latitude}>
          <MarkerImg src={mapPin} />
        </Marker>
        {popup?.info && (
          <StyledPopup
            latitude={popup.latitude}
            longitude={popup.longitude}
            anchor="bottom"
            closeButton={false}
            offsetTop={-32}
          >
            {popup.info}
          </StyledPopup>
        )}
      </ReactMapGL>
    </Container>
  );
};

const Mapbox = memo(MapboxBase);

export { Mapbox };
