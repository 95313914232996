import { ReactComponent as PhoneCircle } from 'assets/streamline-light/phones-mobile-devices/phone/phone-circle.svg';
import { ReactComponent as EmailIcon } from 'assets/streamline-light/emails/email-actions/email-action-read.svg';
import logo from 'assets/logo3.webp';
import { FC, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { device } from '../../../../style/theme';
import { MainButton } from '../../../common/components/ui/buttons';
import Icon from '../../../common/components/ui/icon';
import {
  BoxTitle,
  Headline2,
  Headline3,
  ParagraphTitle,
  PhoneLink,
} from '../../../common/components/ui/typography';
import { Avatar } from '../../../landing/components/appointment/avatar';
import { EmailLink } from '../../../common/components/ui/typography/email-link';
import { useGetShopContactsQuery, Shop } from '../../../../generated';

import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { GeneralAppointmentCard } from '../../../property/components/card/general-appointment-card';
import { toggleContactFormOpen } from '../../redux/sellerPropertiesSlice';

const LoaderContainer = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  @media ${device.tablet} {
    /* display: none; */
    padding: 16px;
  }

  &&&& {
    > div {
      position: absolute;
    }
  }
`;

const Container = styled.div`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  @media ${device.tablet} {
    /* display: none; */
    padding: 16px;
  }
`;

const Title = styled(Headline2)`
  margin-top: 16px;
  margin-bottom: 6px;
`;

const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const Text = styled(ParagraphTitle)`
  opacity: 0.6;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  margin: 0 4px 0 8px;
`;

const PhoneNumber = styled(ParagraphTitle)`
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
`;

const Description = styled.p`
  margin-bottom: 16px;
  font-size: 16px;
  font-family: 'Source Serif Pro', serif;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  text-align: center;
`;

const Photo = styled(Avatar)`
  overflow: hidden;
  margin-bottom: 16px;
  object-fit: cover;
  border-radius: 4px;

  @media ${device.mobile} {
    max-width: 100%;
    height: auto;
  }
`;

const City = styled(Headline3)``;

const Address = styled.p`
  margin-bottom: 12px;
  font-family: Roboto, serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  opacity: 0.6;
`;

export const EmailLinkWrapper = styled(EmailLink)`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
`;

export const ContactIconWrapper = styled(Icon)`
  margin-right: 8px;
`;

export const Contact = styled(BoxTitle)`
  letter-spacing: 1px;
  @media ${device.mobile} {
    font-size: 10px;
    line-height: 1.6;
  }
`;

const TeamContactCardBase: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { propertyId } = useParams<{ propertyId: string }>();

  const { shopContacts, isDataLoading, shopContactsError } =
    useGetShopContactsQuery(
      {
        propertyId,
      },
      {
        selectFromResult: ({ data, isLoading, error }) => ({
          shopContacts: data?.getShopContacts as Shop,
          isDataLoading: isLoading,
          shopContactsError: error,
        }),
        skip: !propertyId,
      }
    );

  // const handleShopPageClick = useCallback(() => {
  //   window.open(
  //     shopContacts?.homepage
  //       ? `https://${shopContacts.homepage}`
  //       : 'https://www.von-poll.com',
  //     '_blank'
  //   );
  // }, [shopContacts?.homepage]);

  const handleOpenContactForm = useCallback(() => {
    dispatch(toggleContactFormOpen(true));
  }, [dispatch]);

  if (isDataLoading) {
    return (
      <LoaderContainer>
        <GlobalLoader />
      </LoaderContainer>
    );
  }

  if (!shopContacts) {
    return <GeneralAppointmentCard />;
  }

  return (
    <Container>
      {/* {shopContactsError?.message && ( */}
      {/*  <GlobalError */}
      {/*    title={t(shopContactsError?.message?.split(':')[0].toLowerCase())} */}
      {/*  /> */}
      {/* )} */}
      <Title content={t('appointment.title.text')} />
      <Description>{t('appointment.description.text')}</Description>
      <Photo imageSrc={logo} width={336} height={124} altText={'logo'} />
      <City content={shopContacts?.city ?? 'Frankfurt am Main'} />
      <Address>
        {shopContacts
          ? `${shopContacts?.street} ${shopContacts?.housenumber}, ${shopContacts?.postcode} ${shopContacts?.city}`
          : 'Feldbergstraße 35, 60323 Frankfurt am Main'}
      </Address>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ContactWrapper>
          <Icon
            width={20}
            height={20}
            icon={PhoneCircle}
            color={themeContext.blue}
          />
          <Text content={`${t('appointment.phone.text')}:`} />
          <PhoneLink phoneNumber={shopContacts?.phone ?? '+49 69 26 91 57 -0'}>
            <PhoneNumber
              content={shopContacts?.phone ?? '+49 69 26 91 57 -0'}
            />
          </PhoneLink>
        </ContactWrapper>
        <EmailLinkWrapper email={shopContacts?.email ?? 'info@von-poll.com'}>
          <Icon
            icon={EmailIcon}
            width={20}
            height={20}
            color={themeContext.blue}
          />
          <Text content={`${t('appointment.email.text')}:`} />
          <Contact content={shopContacts?.email ?? 'info@von-poll.com'} />
        </EmailLinkWrapper>
      </div>

      <MainButton
        label={t('appointment.button.shop-page')}
        onClick={handleOpenContactForm}
        fluid
        centered
      />
    </Container>
  );
};

const TeamContactCard = memo(TeamContactCardBase);

export { TeamContactCard };
