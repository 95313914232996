import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, Slide } from 'react-toastify';
import styled, { ThemeProvider } from 'styled-components';
import { CSSProperties, FC } from 'react';

import CSSReset from '../../../../style/reset';
import { defaultTheme, device } from '../../../../style/theme';
import { Footer } from './footer';
import { CollapseHeader } from './header/collapse-header';
import { Header } from './header/header';
import { TimifyModal } from '../../../timify/components/timify-modal';
import { LocalizationContainer } from '../../../localization/localization-container';
import { useIsMobileSize } from '../../hooks/useIsMobileSize';
import PropertyInfo from '../../../search-profile/pages/request-expose-page/property-info';
import { useGetPropertyId } from '../../hooks/useGetPropertyId';

interface IProps {
  children: JSX.Element;
  containerStyles?: CSSProperties;
  isSubheader?: boolean;
  SideImageSrc?: string;
  sideImageStyle?: CSSProperties;
  childrenContainerStyle?: CSSProperties;
  isBuyerLogin?: boolean;
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  //position: fixed;
  //top: 0;
  //bottom: 0;
`;

const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex: 1;

  @media ${device.tablet} {
    max-width: 100%;
    padding-bottom: 0;
  }
`;

const Wrapper = styled.div<{ isMobileSize?: boolean; noSideDesktop?: boolean }>`
  padding: ${(props) => (props.isMobileSize ? '16px' : '36px 36px 36px 64px')};
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.isMobileSize ? '100%' : '50%')};
  overflow: ${(props) => (props.isMobileSize ? 'unset' : 'auto')};
  display: flex;
  flex: 1;
  ${(props) => !props.isMobileSize && 'max-width: 50vw;'}

  ${(props) =>
    props.noSideDesktop &&
    `
    padding: 64px;
    width: 100%;
    max-width: unset;
  `}
`;

const ChildrenBox = styled.div`
  flex: 1;
  max-width: 480px;
`;

const PropertyInfoWrapper = styled.div`
  flex: 1;
  max-width: 50vw;
`;

const LayoutWithImage: FC<IProps> = ({
  children,
  containerStyles,
  isSubheader = false,
  SideImageSrc,
  sideImageStyle,
  childrenContainerStyle,
  isBuyerLogin,
}) => {
  const isMobileSize = useIsMobileSize();
  const propertyId = useGetPropertyId();
  const noSide =
    !new URLSearchParams(window.location.search).get('propertyId') &&
    isBuyerLogin;

  return (
    <ThemeProvider theme={defaultTheme}>
      <LocalizationContainer>
        <CSSReset />
        <Container style={containerStyles}>
          <Header hasAuthHeader={isSubheader} />
          {/* {isSubheader && <SubHeader />} */}
          <CollapseHeader />
          <Content>
            <Wrapper
              isMobileSize={isMobileSize}
              noSideDesktop={noSide && !isMobileSize}
            >
              <ChildrenBox style={childrenContainerStyle}>
                {children}
              </ChildrenBox>
            </Wrapper>
            {!isMobileSize && !noSide && (
              <PropertyInfoWrapper>
                <PropertyInfo
                  propertyId={propertyId}
                  SideImageSrc={SideImageSrc}
                  sideImageStyle={sideImageStyle}
                />
              </PropertyInfoWrapper>
            )}
          </Content>
          <Footer />
        </Container>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          transition={Slide}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <TimifyModal />
      </LocalizationContainer>
    </ThemeProvider>
  );
};

export { LayoutWithImage };
