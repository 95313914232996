import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Icon from '../../../common/components/ui/icon';
import {
  Column,
  Container,
  Value,
  Label,
} from './property-valuation-icon-styles';

interface IProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  type: string;
  value: string | number;
}

const PropertyValuationIcon = ({ icon, type, value }: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  return (
    <Container>
      <Column>
        <Icon icon={icon} width={24} height={24} color={themeContext.blue} />
      </Column>
      <Column>
        <Value content={`${value}`} />
        <Label content={type} />
      </Column>
    </Container>
  );
};

export default PropertyValuationIcon;
