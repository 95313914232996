import styled from 'styled-components';
import { device } from '../../../../../../../style/theme';
import {
  Headline1,
  TitleSlogan,
} from '../../../../../../common/components/ui/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  margin-left: auto;
  @media ${device.tabletSlider} {
    max-width: 100%;
  }
`;

export const SliderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;

  @media ${device.tablet} {
    position: initial;
  }
  &&& {
    .swiper-container {
      width: 100%;
      height: 100%;

      &#main {
        background-color: ${(props) => props.theme.logoBlue};

        @media ${device.tabletSlider} {
          order: 3 !important;
        }
      }

      &#controller {
        position: absolute;
        height: 600px;
        width: 520px;
        left: -472px;
        top: -184px;

        @media ${device.tabletSlider} {
          position: initial !important;
          margin-bottom: 0 !important;
        }
      }
    }

    .swiper-pagination {
      bottom: 0;
      padding-bottom: 24px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .swiper-wrapper {
      padding-inline-start: 0;
    }

    .swiper-pagination-bullet {
      position: relative;
      width: 16px;
      height: 16px;
      padding: 3px;
      border: solid 1px rgba(0, 48, 94, 0.5);
      background: transparent;
      background-clip: content-box;
      margin: 0 20px;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background-color: ${(props) => props.theme.blue};
      }
      &:not(:last-of-type):after {
        content: '';
        border-bottom: 1px dashed rgba(0, 48, 94, 0.5);
        position: absolute;
        width: calc(300% - 8px);
        top: 50%;
        left: calc(100% + 4px);
        transform: translateY(-50%);
      }
    }
    .parallax-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 130%;
      height: 100%;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
    }
  }
  @media ${device.tabletSlider} {
    height: auto;
    &&& {
      .swiper-container {
        &#main {
          order: 2;
        }

        &#controller {
          order: 1;
          position: relative;
          width: 100%;
          max-width: 520px;
          height: 100%;
          left: unset;
          bottom: unset;
          //margin-bottom: 16px;
          margin-bottom: 0;
        }
      }

      .swiper-pagination {
        padding-bottom: 16px;
        background-color: #002849;
      }

      .swiper-wrapper {
      }

      .swiper-pagination-bullet {
        margin: 0 16px;
        border: 1px solid #fff;
        &.swiper-pagination-bullet-active {
          background-color: #fff;
        }
        &:not(:last-of-type):after {
          width: calc(200% - 8px);
          border-bottom: 1px dashed #fff;
          left: calc(100% + 7px);
        }
      }
      .parallax-bg {
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  width: 632px;
  padding: 32px 0 32px 80px;
  @media ${device.tabletSlider} {
    padding: 16px;
    width: auto;
    display: none;
  }
`;

export const MobileHeaderContainer = styled.div`
  display: none;
  padding: 16px;
  order: 2;
  align-items: center;
  gap: 8px;

  @media ${device.tabletSlider} {
    display: flex;
  }
`;

export const HeaderBox = styled.div`
  flex: 1;
`;

export const SliderHeader = styled(TitleSlogan)`
  opacity: 0.8;
  margin-bottom: 4px;
  text-transform: uppercase;

  @media ${device.tabletSlider} {
    font-size: 12px;
    line-height: 1.6;
  }
`;

export const Headline = styled(Headline1)`
  letter-spacing: normal;
  @media ${device.tabletSlider} {
    font-size: 20px;
    line-height: 1.4;
  }
`;
