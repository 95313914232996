const useCheckFinancingURLParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const variantIndexParam = urlParams.get('variant-index') || '';
  const financingIdParam = urlParams.get('financing-id') || '';
  const isCertificateParam = urlParams.get('is-certificate') || '';
  const propertyId = urlParams.get('property_id') || '';
  const source = urlParams.get('source') || '';
  const financingNotification = urlParams.get('financing-notification') || '';

  const setVariantIndexParam = (index: number) => {
    urlParams.set('variant-index', `${index}`);
    const path = window.location.href.split('?')[0];
    const newURL = `${path}?${urlParams}`;

    window.history.pushState({}, '', newURL);
  };

  return {
    variantIndexParam,
    isCertificateParam,
    setVariantIndexParam,
    propertyId,
    source,
    financingIdParam,
    urlParams,
    financingNotification,
  };
};

export { useCheckFinancingURLParams };
