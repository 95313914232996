import styled from 'styled-components';
import { device } from '../../../../../style/theme';

const CardContentHolder = styled.div<{ margin?: string }>`
  margin: ${({ margin }) => margin ?? 0};
  @media ${device.tablet} {
    padding: 0;
  }
`;

export { CardContentHolder };
