import { FC } from 'react';
import styled from 'styled-components';

import { InputV2 } from '../../../../../components/common/input-v2/input-v2';
import { InputType } from '../../../../../../auth/interfaces';
import { FIELDS } from '../../../../constants';
import { SelectV2 } from '../../../../../components/common/select-v2/select-v2';
import { useDirtyForm } from '../../../../hooks/callback-hooks/calculation-form/useDirtyForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > div > div:first-child > div {
    width: 100%;

    & input,
    div {
      max-width: 100%;
      width: 100%;
      max-height: 100px;
    }
  }

  & > div > div:last-child > div {
    flex: 0;
    padding: 0;
  }
`;

const FinancingPersonalEditForm: FC = () => {
  // our dirty function for form
  const { setDirtyTrue } = useDirtyForm();

  return (
    <Container>
      <InputV2
        type={InputType.number}
        name={FIELDS.HOUSEHOLD_NET_INCOME.name}
        label={FIELDS.HOUSEHOLD_NET_INCOME.label}
        placeholder={FIELDS.HOUSEHOLD_NET_INCOME.placeholder}
        inputAdornment={FIELDS.HOUSEHOLD_NET_INCOME.inputAdornment}
        isValidateOnBlur
        isSeparate
        rules={FIELDS.HOUSEHOLD_NET_INCOME.rules}
        handleChangeProp={setDirtyTrue}
        isColumn
        isOldDesign
      />
      <SelectV2
        isColumn
        name={FIELDS.MAIN_EARNER.name}
        label={FIELDS.MAIN_EARNER.label}
        placeholder={FIELDS.MAIN_EARNER.placeholder}
        selectOptions={FIELDS.MAIN_EARNER.options}
        rules={FIELDS.MAIN_EARNER.rules}
        handleChangeProp={setDirtyTrue}
        isOldDesign
      />
      <InputV2
        type={InputType.number}
        name={FIELDS.AGE.name}
        label={FIELDS.AGE.label}
        placeholder={FIELDS.AGE.placeholder}
        isValidateOnBlur
        rules={FIELDS.AGE.rules}
        handleChangeProp={setDirtyTrue}
        isColumn
        isOldDesign
      />
    </Container>
  );
};

export { FinancingPersonalEditForm };
