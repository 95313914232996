import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';

import { ReactComponent as InformationCircle } from '../../../../../assets/streamline-light/interface-essential/alerts/information-circle.svg';

import {
  Container,
  Wrapper,
  ErrorContainer,
  ErrorMessage,
  Info,
  InfoWrapper,
  Label,
  LabelContainer,
  Spacer,
  SubLabel,
  TooltipContainer,
  TooltipParagraph,
  BoxesWrapper,
} from './box-selector-line-styles';
import { Tooltip } from '../../../../common/components/tooltip';
import { IProps } from './interface';
import { BoxItem } from './box-item/box-item';

const BoxSelectorLine: FC<IProps> = ({
  name,
  boxes,
  label,
  sublabel,
  defaultValue,
  onClick,
  styleGuideErr,
  isOldDesign,
  isWrapper,
  skipIcon,
  isColumn,
  tooltipKey,
  isLabelSaturated,
  isDisabled,
  isRequired,
  handleChangeProp,
}) => {
  const { t } = useTranslation();
  const {
    field: { ref, value, onChange },
    formState: { errors },
  } = useController({
    name,
    defaultValue,
  });

  const onSelectBox =
    <T,>(v: T, isDisabledCustomOnClick?: boolean) =>
    () => {
      onChange(v);
      if (onClick && !isDisabledCustomOnClick) {
        onClick();
      }
      if (handleChangeProp) {
        handleChangeProp();
      }
    };

  return (
    <Container hasError={errors[name]} isWrapper={isWrapper} ref={ref}>
      <Wrapper isColumn={isColumn}>
        <InfoWrapper>
          {label && (
            <LabelContainer>
              <Label
                isOldDesign={isOldDesign}
                styleGuideErr={styleGuideErr}
                isLabelSaturated={isLabelSaturated}
              >
                {t(label)}
                {isRequired && '*'}
              </Label>
              {sublabel && <SubLabel>{t(sublabel)}</SubLabel>}
            </LabelContainer>
          )}
          {!skipIcon && tooltipKey && (
            <Info>
              <Tooltip
                icon={InformationCircle}
                id={tooltipKey}
                position="bottom"
                width={16}
                height={16}
              >
                <TooltipContainer>
                  <TooltipParagraph>{t(tooltipKey)}</TooltipParagraph>
                </TooltipContainer>
              </Tooltip>
            </Info>
          )}
        </InfoWrapper>
        <BoxesWrapper isColumn={isColumn}>
          {boxes.map((box) => (
            <Fragment key={box.value}>
              <BoxItem
                text={box.text}
                isSelected={box.value === value}
                isDisabled={isDisabled}
                onClick={onSelectBox(box.value, box.isDisabledCustomOnClick)}
              />
            </Fragment>
          ))}
        </BoxesWrapper>
      </Wrapper>

      {errors[name] && (
        <ErrorContainer>
          <Spacer />
          <ErrorMessage>{t(errors[name].message)}</ErrorMessage>
        </ErrorContainer>
      )}
    </Container>
  );
};

export { BoxSelectorLine };
