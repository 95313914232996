import { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { ReactComponent as PencilIcon } from 'assets/streamline-light/interface-essential/edit/pencil.svg';

import { Container, Title } from './modal-header-styles';
import { CloseButton } from '../../../../../common/components/modal/close-button';
import { IconButton } from '../../../../../common/components/ui/buttons';
import Icon from '../../../../../common/components/ui/icon';

interface IProps {
  title: string;
  onClose: () => void;
}

const ModalHeaderBase: FC<IProps> = ({ title, onClose }) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <CloseButton
        handleClose={onClose}
        color={themeContext.white}
        position="right"
      />
      <Icon icon={PencilIcon} width={16} height={16} />
      <Title>{t(title).toUpperCase()}</Title>
    </Container>
  );
};

const ModalHeader = memo(ModalHeaderBase);

export { ModalHeader };
