import { FinancingLpSection, FinancingLpSectionType } from '../../../generated';

export const sortByOrderIndex = <T extends { orderIndex: number }>(
  sections: T[]
): T[] => {
  return sections?.slice().sort((sectionA, sectionB) => {
    if (sectionA.orderIndex > sectionB.orderIndex) {
      return 1;
    }
    if (sectionA.orderIndex < sectionB.orderIndex) {
      return -1;
    }
    return 0;
  });
};

export const orderMobile = (
  sections: FinancingLpSection[]
): FinancingLpSection[] => {
  return sections.map((section) => {
    if (section.type === FinancingLpSectionType.FinancingPartner) {
      return {
        ...section,
        orderIndex: 3,
      };
    }

    if (section.type === FinancingLpSectionType.FinancingCalculator) {
      return {
        ...section,
        orderIndex: 1,
      };
    }

    if (section.type === FinancingLpSectionType.InterestRatesTable) {
      return {
        ...section,
        orderIndex: 2,
      };
    }

    return section;
  });
};
