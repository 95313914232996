import { Chart } from 'chart.js';
import { memo, useContext, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { DefaultTheme, ThemeContext } from 'styled-components';

import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { abbrNum } from '../../../common/utils/number-scientific-notation';
import { plugin } from '../../../property/components/chart/background-plugin';

// Chart.register([annotationPlugin]);

interface IProps {
  labels: string[];
  data: number[];
  height?: number;
  labelsWithQuarters?: boolean;
  yValueSuffix?: string;
  hideStripes?: boolean;
  labelsWithShortYear?: boolean;
  labelFormatText?: string;
  footer?: string;
  id?: string;
  isSimulation?: boolean;
}

interface ITooltipData {
  chart: typeof Chart;
  label: string;
  parsed: unknown;
  raw: unknown;
  formattedValue: string;
  dataset: {
    labels: string[];
    data: number[];
  };
  datasetIndex: number;
  dataIndex: number;
  element: Element;
}

const buildDatasets = (
  themeContext: DefaultTheme,
  labels: string[],
  data: unknown[]
) => ({
  labels,
  datasets: [
    // {
    //   label: 'First',
    //   data: data[0],
    //   fill: false,
    //   borderColor: themeContext.red,
    //   backgroundColor: themeContext.blue,
    //   pointerStyle: 'circle',
    //   pointRadius: 3,
    //   lineTension: 0,
    //   borderCapStyle: 'butt',
    //   borderWidth: 1,
    //   borderJoinStyle: 'miter',
    //   pointBorderWidth: 1,
    //   pointHoverRadius: 3,
    //   pointHoverBackgroundColor: themeContext.red,
    //   pointHoverBorderColor: themeContext.red,
    //   pointHoverBorderWidth: 6,
    //   pointHitRadius: 3,
    //   pointBorderColor: themeContext.red,
    //   pointBackgroundColor: themeContext.red,
    // },
    {
      label: 'Second',
      data: data[1],
      fill: false,
      borderColor: themeContext.blue,
      backgroundColor: themeContext.blue,
      pointerStyle: 'circle',
      pointRadius: 3,
      lineTension: 0,
      borderCapStyle: 'butt',
      borderWidth: 1,
      borderJoinStyle: 'miter',
      pointBorderWidth: 1,
      pointHoverRadius: 3,
      pointHoverBackgroundColor: themeContext.blue,
      pointHoverBorderColor: themeContext.blue,
      pointHoverBorderWidth: 6,
      pointHitRadius: 3,
      pointBorderColor: themeContext.blue,
      pointBackgroundColor: themeContext.blue,
    },
  ],
});

const ExposeStatisticsLineChartBase = ({
  labels,
  labelFormatText,
  data,
  height,
  labelsWithQuarters,
  yValueSuffix,
  hideStripes,
  labelsWithShortYear,
  footer,
  id,
  isSimulation = false,
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  const datasets = useMemo(() => {
    const LowExposesNumberDataset: unknown[] = [];

    data?.forEach((item) => {
      if (!isSimulation && item < 100) {
        LowExposesNumberDataset.push(item);
      } else {
        LowExposesNumberDataset.push(null);
      }
    });
    return [LowExposesNumberDataset, data];
  }, [isSimulation, data]);

  const chartData = useMemo(
    () => buildDatasets(themeContext, labels, datasets),
    [datasets, labels, themeContext]
  );

  const options = useMemo(
    () => ({
      maintainAspectRatio: true,
      interaction: {
        intersect: false,
        mode: 'point',
      },
      plugins: {
        legend: {
          display: false,
        },
        // annotation: {
        //   annotations: {
        //     box1: {
        //       drawTime: 'afterDatasetsDraw',
        //       type: 'box',
        //       xMin: 0,
        //       xMax: 1,
        //       backgroundColor: `${hexToRGB('#4e73f5', 0.2)}`,
        //     },
        //     line1: {
        //       type: 'line',
        //       xMin: 0,
        //       xMax: 0,
        //       borderColor: '#00305e',
        //       borderWidth: 2,
        //     },
        //   },
        // },
        tooltip: {
          enabled: true,
          displayColors: false,
          backgroundColor: '#fff',
          titleColor: hexToRGB('#00305e', 0.8),
          titleFont: {
            family: 'Roboto',
            size: 12,
            weight: '900',
            lineHeight: 1.67,
          },
          titleAlign: 'center',
          titleMarginBottom: 4,
          bodyColor: '#00305e',
          bodyFont: {
            family: 'Source Serif Pro',
            size: 16,
            weight: '600',
            lineHeight: 1.25,
          },
          bodyAlign: 'center',
          footerColor: hexToRGB('#00305e', 0.5),
          footerFont: {
            family: 'Roboto',
            size: 10,
            weight: '900',
            lineHeight: 1.6,
          },
          footerAlign: 'center',
          footerMarginTop: 2,
          padding: {
            left: 20,
            right: 20,
            top: 8,
            bottom: 8,
          },
          cornerRadius: 3,
          borderColor: 'rgb(217, 217, 217)',
          borderWidth: 1,
          caretSize: 10,
          caretPadding: 8,
          usePointStyle: true,
          callbacks: {
            labelPointStyle: () => {
              return {
                pointStyle: 'triangle',
                rotation: 1,
              };
            },
            title: (context: [ITooltipData]) => {
              const { label } = context[0];
              if (!label) {
                return '';
              }

              const tokens = label.split('-');
              return labelsWithQuarters
                ? `${tokens[1]}-${tokens[0].slice(2)}`
                : label;
            },
            label: (context: ITooltipData) => {
              const { datasetIndex, formattedValue } = context;
              if (datasetIndex === 0) {
                return formattedValue;
              }

              return '';
            },
            footer: () => {
              if (footer) {
                return t(footer);
              }
              return '';
            },
          },
        },
      },
      chartArea: {
        backgroundColor: hexToRGB('#4e73f5', 0.05),
        step: 1,
      },
      scales: {
        x: {
          beginAtZero: false,
          offset: true,
          grid: {
            display: true,
            drawTicks: false,
            drawOnChartArea: false,
          },
          ticks: {
            major: {
              enabled: true,
            },
            maxRotation: 0,
            align: 'center',
            crossAlign: 'near',
            includeBounds: false,
            labelOffset: 0,
            padding: 8,
            color: hexToRGB('#00305e', 0.3),
            font: {
              family: 'Roboto',
              size: 10,
              weight: '900',
              lineHeight: 1.6,
            },
            callback(value: number) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line react/no-this-in-sfc
              let label: string = this.getLabelForValue(value);
              if (!label) {
                return '';
              }
              const tokens = label.split('-');

              if (labelsWithShortYear) {
                label = `'${label.slice(2)}`;
              }

              return labelsWithQuarters
                ? `${tokens[1]}-${tokens[0].slice(2)}`
                : label;
            },
          },
        },
        y: {
          beginAtZero: Boolean(data?.some((item) => item === 0)),
          // bounds: 'ticks',
          offset: true,
          // grace: 0,
          grid: {
            drawBorder: true,
            borderDash: [2, 1],
            zeroLineColor: 'red',
            drawTicks: false,
          },
          ticks: {
            stepSize: isSimulation ? 2 : 1,
            padding: 10,
            maxTicksLimit: isSimulation ? 3 : 5,
            color: '#00305e',
            font: {
              family: 'Roboto',
              size: 12,
              weight: 'bold',
              lineHeight: 1.33,
            },
            callback(value: number) {
              if (value > 999 || value < -999) {
                return `${abbrNum(value, 2)} ${yValueSuffix || ''}`;
              }
              return `${value.toFixed(2).replace(/[,.]00$/, '')}`;
            },
          },
        },
      },
      layout: {
        padding: {
          left: 0,
        },
      },
    }),
    [
      footer,
      labelFormatText,
      labelsWithQuarters,
      labelsWithShortYear,
      t,
      yValueSuffix,
    ]
  );

  const plugins = hideStripes ? [] : [plugin];

  if (!labels || !data || !chartData) {
    return <></>;
  }

  return (
    <Line
      {...(height && { height })}
      data={chartData}
      options={options}
      plugins={plugins}
      id={id}
      style={{ maxHeight: '200px' }}
    />
  );
};

const ExposeStatisticsLineChart = memo(ExposeStatisticsLineChartBase);

export { ExposeStatisticsLineChart };
