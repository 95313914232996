import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MainButton } from '../ui/buttons';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background-color: #4e73f5;
    &:hover {
      background-color: #0f5dc4;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin: 0 auto;
  }
`;

const Title = styled.h2`
  font-size: 46px;
  color: var(--white);
  font-family: 'Heebo';
  margin: 0;
`;

const Output = styled.pre`
  background: var(--shades-700-85);
  color: var(--white);
  border: 1px solid var(--primary);
  padding: 12px;
  border-radius: 8px;
`;

const ErrorView = ({
  error,
  resetErrorBoundary,
}: {
  error: { message?: string; stack?: string };
  resetErrorBoundary: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <Title>Something went wrong</Title>
        <Output
          style={{
            textAlign:
              process.env.NODE_ENV === 'production' ? 'center' : 'left',
          }}
        >{`${error.message}
      ${process.env.NODE_ENV !== 'production' ? error?.stack : ''}`}</Output>
        <MainButton onClick={resetErrorBoundary} label={t('Reload Page')} />
      </Wrapper>
    </Container>
  );
};

export default ErrorView;
