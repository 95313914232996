import styled from 'styled-components';
import { Headline3 } from '../../../../../../common/components/ui/typography';

export const Container = styled.div`
  margin-top: 32px;
`;

export const Title = styled(Headline3)`
  font-size: 16px;
  line-height: 1.25;
`;

export const DocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 56px;

  &&&& {
    > div {
      position: absolute;
      top: 250px;
    }
  }
`;
