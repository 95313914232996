import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

import Icon from '../../../common/components/ui/icon';
import { ReactComponent as EmptyStateIcon } from '../../../../assets/svg/empty-state.svg';
import {
  Headline2,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { device } from '../../../../style/theme';

const Container = styled.div`
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 24px;

  @media ${device.tablet} {
    padding: 12px;
  }
`;

const Title = styled(Headline2)`
  font-size: 20px;
  line-height: 1.6;
  margin-top: 12px;
  text-align: center;
`;

const Description = styled(ParagraphText)`
  text-align: center;
  margin: 8px 0 12px 0;
`;

const EmptyStatePlaceholder = () => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <Container>
      <Icon
        icon={EmptyStateIcon}
        color={themeContext.blue}
        style={{ width: '40px', height: '40px' }}
      />
      <Title
        content={t('search-profile.purchase-offers-tab.empty-list.header')}
      />
      <Description
        content={t('search-profile.purchase-offers-tab.empty-list.text')}
      />
    </Container>
  );
};

export default EmptyStatePlaceholder;
