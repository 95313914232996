import { FC, memo } from 'react';
import { ValuationType } from '../../../../../../generated';
import { OnlineValuationContainer } from '../online-valuation/online-valuation-container';
import { PersonalValuationContainer } from '../personal-valuation/personal-valuation-container';
import { CTAContainer } from '../../../../../property/pages/property-page/property-page-styles';
import { CTAButtons } from './cta-buttons/cta-buttons';
import { useAppSelector } from '../../../../../common/hooks';
import { PageType } from '../../../interface';

interface IProps {
  isOnLandingPage?: boolean;
  pageType?: PageType;
}

const PropertyValuationWidgetContainerBase: FC<IProps> = ({
  isOnLandingPage,
  pageType = PageType.property,
}) => {
  const isValuationWizardOpen = useAppSelector(
    (state) => state.valuationWizard.isValuationWizardOpen
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const valuationType = useAppSelector(
    (state) => state.valuationWizard.valuationType
  );

  return (
    <>
      {isValuationWizardOpen && valuationType === ValuationType.Online && (
        <OnlineValuationContainer isOnLandingPage={isOnLandingPage} />
      )}
      {isValuationWizardOpen && valuationType === ValuationType.Personal && (
        <PersonalValuationContainer />
      )}
      {!isValuationWizardOpen && (
        <CTAContainer iniframe={!!isIframe}>
          <CTAButtons pageType={pageType} />
        </CTAContainer>
      )}
    </>
  );
};

const PropertyValuationWidgetContainer = memo(
  PropertyValuationWidgetContainerBase
);

export { PropertyValuationWidgetContainer };
