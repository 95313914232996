import { FC } from 'react';

import { useFifThStepLogic } from '../../../../../hooks/useStepsLogic';
import { NextButton } from '../../../../../../components/common/next-button';
import { InputsContainer } from '../../../../../../components/fullscreen-modal/inputs-container/inputs-container';
import { BackButtonContainer } from '../../../../common/back-button-container';

const FifthStep: FC = () => {
  const { inputs, watchedKeys } = useFifThStepLogic();
  return (
    <>
      <InputsContainer inputs={inputs} />
      <BackButtonContainer marginTop={0} />
      <NextButton
        watchedKeys={watchedKeys}
        isEvaluationV2
        label={'property-valuation.form.redesign.next-button'}
      />
    </>
  );
};

export { FifthStep };
