import styled, { css } from 'styled-components';
import { device } from '../../../../../style/theme';

export const SliderContainer = styled.div<{
  isGallery?: boolean;
}>`
  height: ${({ isGallery }) => (isGallery ? 660 : 640)}px;
  width: 100%;
  position: relative;
  ${({ isGallery }) =>
    !isGallery &&
    css`
      overflow: hidden;
    `}

  ${({ isGallery }) =>
    isGallery &&
    css`
      margin: 16px 100px 18px;
      max-width: 1000px;
      overflow-x: visible;
    `}

  &&& {
    .swiper-container {
      width: 100%;
      height: 100%;
      ${({ isGallery }) => isGallery && `max-height:540px;`}

      &#main {
        background-color: ${(props) => props.theme.logoBlue};
      }

      .swiper-slide {
        ${({ isGallery }) => isGallery && `max-height:540px;`}
      }
    }

    .swiper-pagination {
      bottom: 0;
      padding-bottom: 24px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .swiper-wrapper {
      padding-inline-start: 0;
    }

    .swiper-pagination-bullet {
      position: relative;
      width: 16px;
      height: 16px;
      padding: 3px;
      border: solid 1px rgba(0, 48, 94, 0.5);
      background: transparent;
      background-clip: content-box;
      margin: 0 20px;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background-color: ${(props) => props.theme.blue};
      }
      &:not(:last-of-type):after {
        content: '';
        border-bottom: 1px dashed rgba(0, 48, 94, 0.5);
        position: absolute;
        width: calc(300% - 8px);
        top: 50%;
        left: calc(100% + 4px);
        transform: translateY(-50%);
      }
    }
    .parallax-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 130%;
      height: 100%;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
    }
  }
  @media ${device.tablet} {
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;
    &&& {
      .swiper-container {
        &#main {
          order: 2;
        }
      }

      .swiper-pagination {
        padding-bottom: 12px;
      }

      .swiper-wrapper {
      }

      .swiper-pagination-bullet {
        margin: 0 12px;
        &.swiper-pagination-bullet-active {
        }
        &:not(:last-of-type):after {
          width: calc(200% - 8px);
          left: calc(100% + 3px);
        }
      }
      .parallax-bg {
      }
    }
  }
  @media ${device.mobile} {
    height: 288px;
  }
`;

export const SliderImage = styled.img<{
  isPortrait?: boolean;
  zoom?: number;
}>`
  object-fit: ${(props) => (props.isPortrait ? 'contain' : 'cover')};
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
  border: 0 none transparent;
  width: 100%;
  height: 100%;
  transition: width 100ms ease-in-out, height 100ms ease-in-out,
    transform 100ms ease-in-out;

  ${({ isPortrait }) =>
    isPortrait &&
    `
    position: relative;
  `}
`;

export const ButtonsWrapper = styled.div`
  width: 34px;
  box-shadow: rgb(0 0 0 / 30%) 0 1px 4px 0;
  position: absolute;
  bottom: 0;
  z-index: 2000000;
`;
