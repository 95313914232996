import { FC } from 'react';

import { NextButton } from '../../../../../../components/common/next-button';
import { InputsContainer } from '../../../../../../components/fullscreen-modal/inputs-container/inputs-container';
import { BackButtonContainer } from '../../../../common/back-button-container';
import { useSecondStepLogic } from '../../../../../hooks/useStepsLogic';

const SecondStep: FC = () => {
  const { inputs, watchedKeys, isBackButton } = useSecondStepLogic();

  return (
    <>
      <InputsContainer inputs={inputs} />
      {isBackButton && <BackButtonContainer marginTop={0} />}
      <NextButton
        watchedKeys={watchedKeys}
        isFinancing
        label={'property-valuation.form.redesign.next-button'}
      />
    </>
  );
};

export { SecondStep };
