import { FC } from 'react';
import styled from 'styled-components';
import { device } from '../../../../../style/theme';

const Container = styled.div`
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #ffffff;
  margin-bottom: 16px;
  padding: 32px;
  overflow: hidden;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    padding: 16px;
    border-radius: 0;
    border-top: 0;
  }
`;

const BigCardContainer: FC = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

export default BigCardContainer;
