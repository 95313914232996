import { identity, omit, pickBy } from 'lodash';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import styled from 'styled-components';

import {
  useConfirmPhoneMutation,
  useResendPhoneCodeAfterChangeMutation,
  UserState,
  useUpdateUserProfileMutation,
  useSetUserStateMutation,
} from '../../../../../generated';
import { device } from '../../../../../style/theme';
import {
  InputOutsideTopLabel,
  InputOutsideTopLabelWithSelect,
} from '../../../../common/components/form';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import {
  FormColumn,
  FormRow,
} from '../../../../common/components/form/form-grid';
import { ModalFooter } from '../../../../common/components/modal';
import {
  MainButton,
  SecondaryButton,
} from '../../../../common/components/ui/buttons';
import { StatusType } from '../../../../common/components/verification-input/types';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { EDIT_PROFILE_FORM_KEYS, phonePrefixes } from '../../../constants';
import { IEditProfile, InputType } from '../../../interfaces';
import { setUser, setUserState } from '../../../redux/authSlice';
import { PhoneVerification } from '../../../components/forms/phone-verification';
import { CreatePasswordForm } from './create-password-form';
import { Header } from '../seller-sign-up-page-styles';
import { ChangePhoneModal } from '../change-phone-modal';

interface IPhoneVerificationForm {
  onSuccessSubmit?: () => void;
}

const Form = styled.form`
  width: 100%;
`;

const Section = styled.section`
  padding: 0 0 4px;

  &:first-of-type {
    margin: 28px 0 0;
  }

  &:last-of-type {
    margin: 0 0 20px;
  }

  @media ${device.tablet} {
    padding: 0 16px;
  }

  #delete-link {
    opacity: 1;
  }

  .extraClass {
    pointer-events: auto !important;

    &:hover {
      visibility: visible !important;
    }
  }
`;

const SectionTitle = styled.h2`
  font-family: 'Source Serif Pro';
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  margin: 0 0 16px;
`;

const StyledModalFooter = styled(ModalFooter)`
  @media ${device.tablet} {
    padding: 0 16px;
  }
`;

const SubmitButton = styled(MainButton)`
  @media ${device.tablet} {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  //max-width: 600px;
  align-items: center;
`;

const PhoneVerificationForm = ({
  onSuccessSubmit,
}: IPhoneVerificationForm): JSX.Element => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();
  const methods = useForm<IEditProfile>();
  const [isChangePhoneOpen, setIsChangePhoneOpen] = useState(false);

  const user = useAppSelector((state) => state.auth.user);
  const userState = useAppSelector((state) => state.auth.userState);

  const [verificationStatus] = useState(StatusType.process);

  const [
    updateUserProfile,
    { isLoading: isUserProfileUpdateLoading, error: updateUserProfileError },
  ] = useUpdateUserProfileMutation();
  const [confirmPhone] = useConfirmPhoneMutation();
  const [resendPhoneCodeAfterChange] = useResendPhoneCodeAfterChangeMutation();
  const [setUserStateRequest] = useSetUserStateMutation();

  const onClose = useCallback(() => {
    setIsChangePhoneOpen(false);
  }, []);

  const onSubmit = async (formData: IEditProfile) => {
    const input = {
      ...pickBy(omit(formData, ['phonePrefix', 'phone']), identity),
      prefix: `${formData.phonePrefix}`,
      phone: `+${formData.phonePrefix}${formData.phone}`,
    };
    try {
      const updateUserProfileResponse = await updateUserProfile({
        input,
      }).unwrap();
      if (
        updateUserProfileResponse?.updateUserProfile.userState ===
        UserState.Verified
      ) {
        dispatch(setUser(updateUserProfileResponse?.updateUserProfile));
        onClose();
      }
      if (
        updateUserProfileResponse?.updateUserProfile.userState ===
        UserState.VerifyPhone
      ) {
        batch(() => {
          dispatch(setUser(updateUserProfileResponse?.updateUserProfile));
          dispatch(
            setUserState(updateUserProfileResponse?.updateUserProfile.userState)
          );
        });
      }
      setIsChangePhoneOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onSuccess = useCallback(() => {
    setIsChangePhoneOpen(false);
    // dispatch(setUserState(UserState.ChangePassword));
  }, []);

  const onVerifyPhone = useCallback(
    async (confirmationCode: string) => {
      if (user?._id) {
        const confirmPhoneResult = await confirmPhone({
          input: {
            confirmationCode,
            username: user?.username,
          },
        }).unwrap();

        await setUserStateRequest({
          _id: user._id,
          state: UserState.ChangePassword,
        });

        if (confirmPhoneResult?.confirmPhone) {
          dispatch(setUserState(UserState.ChangePassword));
        }
        if (onSuccessSubmit) {
          onSuccessSubmit();
        }
      }
    },
    [confirmPhone, dispatch, onSuccessSubmit, user?._id, user?.username]
  );

  const onResendConfirmationCode = useCallback(async () => {
    if (user?.username) {
      await resendPhoneCodeAfterChange({ phone: user?.phone });
    }
  }, [resendPhoneCodeAfterChange, user?.phone, user?.username]);

  const onChangePhone = () => {
    setIsChangePhoneOpen(true);
  };

  // eslint-disable-next-line unicorn/prefer-string-slice, @typescript-eslint/restrict-plus-operands
  const phone = user?.phone?.substr((user?.prefix.length ?? 0) + 1);

  return (
    <Container>
      <ChangePhoneModal
        isOpen={isChangePhoneOpen}
        onSuccessSubmit={onSuccess}
      />
      {userState === UserState.VerifyPhone && !isChangePhoneOpen && (
        <>
          <Header content={t('phone-verification.title')} />
          <PhoneVerification
            phoneNumber={
              user?.phone
                ?.toString()
                ?.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ') ?? ''
            }
            onVerifyPhone={onVerifyPhone}
            status={verificationStatus}
            resendCode={onResendConfirmationCode}
            changePhone={onChangePhone}
            isOnBuyerSignUpPage
          />
        </>
      )}
      {userState === UserState.ChangePassword && <CreatePasswordForm />}
    </Container>
  );
};

export { PhoneVerificationForm };
