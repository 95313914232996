import { CSSProperties, FC } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from '../../../../style/theme';
import { Headline3 } from '../../../common/components/ui/typography';
import { IBoxRating } from '../../interface';
import { IconRatingBox } from '../../../common/components/ui/icon-rating-box';
import { useAppSelector } from '../../../common/hooks';

interface IProps {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  boxes?: IBoxRating[];
  column?: boolean;
  defaultValue?: number | string;
  containerTitle?: string;
  containerStyles?: CSSProperties;
  reverseCounting?: boolean;
}

const Container = styled.div<{ column?: boolean; isiframe?: boolean }>`
  display: flex;
  flex: 1;
  ${(props) =>
    props.column &&
    `
  flex-direction: column;`}
  width: 100%;
  @media ${({ isiframe }) => (isiframe ? '' : device.tablet)} {
    flex-grow: 0;
    flex-direction: column;
  }
`;

const Title = styled(Headline3)`
  margin: 18px auto 12px auto;
`;

const BoxRatingSelector: FC<IProps> = ({
  name,
  boxes,
  column,
  defaultValue,
  containerTitle,
  containerStyles,
  reverseCounting,
}) => {
  const {
    field: { ref, value, onChange },
  } = useController({
    name,
    defaultValue,
  });

  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const onSelectBox =
    <T,>(v: T) =>
    () =>
      onChange(v);

  const { t } = useTranslation();
  return (
    <>
      {containerTitle && <Title content={containerTitle} />}
      <Container
        ref={ref}
        column={column}
        style={containerStyles}
        isiframe={isIframe}
      >
        {boxes?.map((box, idx) => (
          <IconRatingBox
            icon={box.icon}
            title={t(box?.title ?? '')}
            text={t(box?.text ?? '')}
            onClick={onSelectBox(box.value)}
            isSelected={value === box.value}
            key={`${String(box.value)} - ${box.text ?? ''} - ${idx}`}
            iconsCount={boxes.length}
            iconsFill={reverseCounting ? boxes.length - idx : idx + 1}
          />
        ))}
      </Container>
    </>
  );
};

export { BoxRatingSelector };
