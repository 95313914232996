import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  IconContainer,
  IconDigit,
  IconLabel,
  Row,
} from './online-expose-card-styles';
import Icon from '../../../common/components/ui/icon';

interface IProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  label: string;
  value: string | number;
}

export const OnlineExposeIcon: FC<IProps> = ({ icon, label, value }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <IconContainer>
      <Row margin="0 0 4px 0" alignItems="center">
        <Icon icon={icon} color={themeContext.blue} />
        <IconDigit>{value}</IconDigit>
      </Row>
      <Row>
        <IconLabel>{label}</IconLabel>
      </Row>
    </IconContainer>
  );
};
