import { SerializedError } from '@reduxjs/toolkit';
import { ClientError } from 'graphql-request';

import {
  UpdateFinancingDataInput,
  UpdateFinancingMutation,
  UpdateFinancingVariantInput,
} from '../../../../../generated';
import { useUpdateFinancingMutation } from '../../../../../services/graphql/enhanced';

type IUpdateFinancing = {
  financingInput: {
    _id: string;
    countryCode?: string;
    financingData?: UpdateFinancingDataInput;
    financingVariants?: UpdateFinancingVariantInput[];
    title?: string;
    searchProfileId?: string;
  };
};

type IReturn = {
  data: UpdateFinancingMutation | undefined;
  error:
    | Pick<ClientError, 'name' | 'message' | 'stack'>
    | SerializedError
    | undefined;
  isLoading: boolean;
  updateFinancing: (
    props: IUpdateFinancing
  ) => Promise<UpdateFinancingMutation>;
};

export const useUpdateFinancing = (): IReturn => {
  const [updateFinancingMutation, { data, error, isLoading }] =
    useUpdateFinancingMutation();

  const updateFinancing = async ({ financingInput }: IUpdateFinancing) => {
    const financing = await updateFinancingMutation({
      input: {
        ...financingInput,
      },
    }).unwrap();
    return financing;
  };

  return {
    data,
    error,
    isLoading,
    updateFinancing,
  };
};
