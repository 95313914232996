import { FC } from 'react';
import useScript from '../../common/hooks/useScript';

interface IProps {
  scriptId?: string;
  scriptSrc?: string;
  containerSelector?: string;
}

const ContentScript: FC<IProps> = ({
  scriptId,
  scriptSrc,
  containerSelector,
}) => {
  useScript(scriptSrc, scriptId, containerSelector);

  return null;
};

export { ContentScript };
