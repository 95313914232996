import { useActualExperiment } from '../../../experiments/hooks/useActualExperiment';

type TReturn = {
  evaluationWidgetVersion: string;
  isRedesignVersion: boolean;
};

const useGetEvaluationWidgetVersion = (): TReturn => {
  const urlParams = new URLSearchParams(window.location.search);
  const evaluationWidgetVersion = urlParams.get('widgetTemplate') || '';
  const { isRedesignedWidget } = useActualExperiment();
  const isRedesignVersion =
    evaluationWidgetVersion === 'v2' || isRedesignedWidget;

  return {
    evaluationWidgetVersion,
    isRedesignVersion,
  };
};

export { useGetEvaluationWidgetVersion };
