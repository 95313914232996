import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Check } from 'assets/streamline-light/interface-essential/form-validation/check-1.svg';
import { ReactComponent as CashPaymentBag } from 'assets/streamline-light/money-payments-finance/cash-payments/cash-payment-bag.svg';
import { ReactComponent as CheckCircle } from 'assets/streamline-light/interface-essential/form-validation/check-circle-1.svg';

import { ThemeContext } from 'styled-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { Modal, ModalTitle } from '../../../../common/components/modal';
import { toggleAcceptPurchaseOfferModalOpen } from '../../../redux/sellerPropertiesSlice';
import {
  ActionsWrapper,
  CityName,
  CityWrapper,
  FormText,
  FormTitle,
  FormWrapper,
  FromCity,
  Id,
  Info,
  OfferIcon,
  SelectedOfferContainer,
  Title,
  TitleWrapper,
  SuccessMessageContainer,
  SuccessMessageTitle,
  SuccessMessageText,
} from './accept-offer-modal-styles';
import { IAcceptOfferForm } from '../../../interfaces';
import { Form } from '../../expose/expose-state-card/expose-state-card-styles';
import {
  Checkbox,
  InputOutsideTopLabel,
} from '../../../../common/components/form';
import {
  FormColumn,
  FormRow,
} from '../../../../common/components/form/form-grid';
import {
  MainButton,
  SecondaryButton,
} from '../../../../common/components/ui/buttons';
import { useAcceptPurchaseOfferMutation } from '../../../../../generated';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import Icon from '../../../../common/components/ui/icon';

const AcceptOfferModalBase = () => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const { propertyId } = useParams<{ propertyId: string }>();

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const closeModal = useCallback(() => {
    dispatch(toggleAcceptPurchaseOfferModalOpen(false));
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 300);
  }, [dispatch]);

  const wrapperStyles = useMemo(
    () =>
      isMobileSize
        ? {
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            height: '100%',
            overflow: 'overlay',
            padding: '24px',
            ...(showSuccessMessage
              ? {
                  display: 'flex',
                  flexDirection: 'column' as const,
                  justifyContent: 'space-between',
                }
              : {}),
          }
        : {
            maxWidth: '512px',
            // height: '660px',
            ...(showSuccessMessage
              ? {
                  display: 'flex',
                  flexDirection: 'column' as const,
                  justifyContent: 'space-between',
                }
              : {}),
          },
    [isMobileSize, showSuccessMessage]
  );

  const isAcceptPurchaseOfferModalOpen = useAppSelector(
    (state) => state.sellerProperties.isAcceptPurchaseOfferModalOpen
  );

  const selectedPurchaseOffer = useAppSelector(
    (state) => state.sellerProperties.selectedPurchaseOffer
  );

  const [acceptPurchaseOffer, { isLoading, error: acceptOfferError }] =
    useAcceptPurchaseOfferMutation();

  const methods = useForm<IAcceptOfferForm>({
    mode: 'onChange',
  });

  const watchedFromDate = useMemo(
    () => methods.watch('notaryAppointmentFromDate'),
    [methods]
  );

  const dateFromMin = useMemo(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    return yesterday.toISOString();
  }, []);

  const dateToMin = useMemo(() => {
    return watchedFromDate
      ? new Date(watchedFromDate).toISOString()
      : new Date().toISOString();
  }, [watchedFromDate]);

  const onSubmit: SubmitHandler<IAcceptOfferForm> = useCallback(
    async (formData) => {
      try {
        const input = {
          ...formData,
          customerOnOfficeId: selectedPurchaseOffer.customerOnOfficeId ?? '',
          price: selectedPurchaseOffer?.newPrice ?? selectedPurchaseOffer.price,
          propertyId,
          bidOnOfficeId: selectedPurchaseOffer.bidOnOfficeId ?? '',
        };
        await acceptPurchaseOffer({ input }).unwrap();
        setShowSuccessMessage(true);
      } catch (error) {
        console.error(error);
      }
    },
    [
      acceptPurchaseOffer,
      propertyId,
      selectedPurchaseOffer.bidOnOfficeId,
      selectedPurchaseOffer.customerOnOfficeId,
      selectedPurchaseOffer?.newPrice,
      selectedPurchaseOffer.price,
    ]
  );

  if (showSuccessMessage) {
    return (
      <Modal
        isOpen={isAcceptPurchaseOfferModalOpen}
        wrapperStyles={wrapperStyles}
        onClose={closeModal}
      >
        <ModalTitle
          icon={Check}
          text={t('accept-purchase-offer.modal.title')}
          iconPosition="left"
          textAlign="left"
        />
        <SuccessMessageContainer>
          <Icon
            icon={CheckCircle}
            color={themeContext.green}
            width={40}
            height={40}
            style={{ marginBottom: '16px' }}
          />
          <SuccessMessageTitle
            content={t('accept-purchase-offer.modal.success-message.title')}
          />
          <SuccessMessageText
            content={t('accept-purchase-offer.modal.success-message.text')}
          />
        </SuccessMessageContainer>
        <ActionsWrapper>
          <SecondaryButton
            label={t('accept-purchase-offer.modal.button.close')}
            onClick={closeModal}
          />
        </ActionsWrapper>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isAcceptPurchaseOfferModalOpen}
      wrapperStyles={wrapperStyles}
      onClose={closeModal}
    >
      <ModalTitle
        icon={Check}
        text={t('accept-purchase-offer.modal.title')}
        iconPosition="left"
        textAlign="left"
      />
      {acceptOfferError?.message && (
        <GlobalError
          title={t(acceptOfferError?.message?.split(':')[0].toLowerCase())}
        />
      )}
      <SelectedOfferContainer>
        {!isMobileSize && <Id>{`I${selectedPurchaseOffer.customerId}`}</Id>}
        <Info>
          <TitleWrapper>
            <Title
              content={`${t(
                'selling-simulation.offers-statistics-card.customer'
              )} ${selectedPurchaseOffer.customerId}`}
            />
          </TitleWrapper>

          <CityWrapper>
            <FromCity
              content={t('selling-simulation.offers-statistics-card.from-city')}
            />{' '}
            <CityName content={selectedPurchaseOffer.fromCity} />
          </CityWrapper>
        </Info>

        <OfferIcon
          iconStyle={{
            width: '20px',
            height: '20px',
          }}
          digitStyle={{ fontSize: isMobileSize ? '12px' : '14px' }}
          labelStyle={{ fontSize: isMobileSize ? '9px' : '10px' }}
          containerStyle={{
            padding: '8px',
            borderRadius: '3px',
            maxWidth: 'max-content',
            border: `1px solid ${themeContext.borderColor}`,
            minWidth: '160px',
          }}
          icon={CashPaymentBag}
          value={selectedPurchaseOffer.price}
          label={t('selling-simulation.offers-statistics-card.price-offer')}
        />
      </SelectedOfferContainer>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormWrapper>
            <FormTitle
              content={t('accept-purchase-offer.modal.notary-form.title')}
            />
            <FormText
              content={t(
                'accept-purchase-offer.modal.notary-form.appointment-period'
              )}
            />
            <FormRow>
              <FormColumn flex="0 0 50%">
                <InputOutsideTopLabel
                  name={'notaryAppointmentFromDate'}
                  type="date"
                  label="accept-purchase-offer.modal.notary-form.appointment-period.from"
                  placeholder="accept-purchase-offer.modal.notary-form.appointment-period.from"
                  rules={{
                    required: false,
                    min: {
                      value: dateFromMin,
                      message:
                        'accept-purchase-offer.modal.notary-form.appointment-period.from.error',
                    },
                  }}
                  isRedesign
                />
              </FormColumn>
              <FormColumn flex="0 0 50%">
                <InputOutsideTopLabel
                  name={'notaryAppointmentToDate'}
                  type="date"
                  label="accept-purchase-offer.modal.notary-form.appointment-period.to"
                  placeholder="accept-purchase-offer.modal.notary-form.appointment-period.to"
                  rules={{
                    required: false,
                    min: {
                      value: dateToMin,
                      message:
                        'accept-purchase-offer.modal.notary-form.appointment-period.from.error',
                    },
                  }}
                  isRedesign
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn flex="0 0 100%">
                <InputOutsideTopLabel
                  name={'comment'}
                  type="text"
                  label="accept-purchase-offer.modal.notary-form.comment.label"
                  placeholder={t(
                    'accept-purchase-offer.modal.notary-form.comment.label'
                  )}
                  rules={{ required: false }}
                  isRedesign
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn flex="0 0 100%">
                <Checkbox
                  name={'isOfferNotary'}
                  label={
                    'accept-purchase-offer.modal.notary-form.is-offer-notary.checkbox.label'
                  }
                  rules={{
                    // required: 'register.input.error.required',
                    required: false,
                  }}
                  isRedesign
                />
              </FormColumn>
            </FormRow>
          </FormWrapper>
        </Form>
      </FormProvider>
      <ActionsWrapper>
        <SecondaryButton
          label={t('accept-purchase-offer.modal.button.close')}
          onClick={closeModal}
        />
        <MainButton
          label={t('accept-purchase-offer.modal.button.accept-offer')}
          onClick={methods.handleSubmit(onSubmit)}
          loader
          isLoading={isLoading}
          isDisabled={isBrokerView}
        />
      </ActionsWrapper>
    </Modal>
  );
};

const AcceptOfferModal = memo(AcceptOfferModalBase);

export { AcceptOfferModal };
