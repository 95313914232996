import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { ReactComponent as KeyholeCircle } from 'assets/streamline-light/interface-essential/lock-unlock/keyhole-circle.svg';
import { ReactComponent as SingleNeutral } from 'assets/streamline-light/users/natural-close-up-single-user-neutral/single-neutral.svg';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import { device } from '../../../../style/theme';

import { Modal, ModalTitle } from '../../../common/components/modal';
import Icon from '../../../common/components/ui/icon';
import { useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { toggleIsLoginModalOpen } from '../../redux/authSlice';
import { ForgotPasswordForm } from '../forms/forgot-password-form';
import { LoginForm } from '../forms/login-form';

interface IRegisterModal {
  isOpen: boolean;
}

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  @media ${device.tablet} {
    background-color: ${(props) => props.theme.blue};
    width: 100%;
    justify-content: center;
    padding: 18px 0 18px 0;
  }
`;
const FormWrapper = styled.div`
  @media ${device.tablet} {
    margin: 0 16px 0 16px;
    height: 100%;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  @media ${device.tablet} {
    position: absolute;
    right: 16px;
  }
`;

const descktopWrapperStyle = {
  maxWidth: 376,
  padding: '32px 32px 20px 32px',
};
const mobileWrapperStyle = {
  width: '100%',
  height: '100%',
  padding: 0,
  overflow: 'overlay',
};

const LoginModal = ({ isOpen }: IRegisterModal): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const isMobileSize = useIsMobileSize();

  const isForgottenPasswordOpen = useAppSelector(
    (state) => state.auth.isForgottenPasswordOpen
  );

  const onClose = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    dispatch(toggleIsLoginModalOpen(false));
  };

  const icon = isForgottenPasswordOpen ? KeyholeCircle : SingleNeutral;
  const title = isForgottenPasswordOpen
    ? t('modal.forgotten-password.title')
    : t('modal.login.title');

  return (
    <Modal
      isOpen={isOpen}
      wrapperStyles={isMobileSize ? mobileWrapperStyle : descktopWrapperStyle}
    >
      <Container>
        <Row>
          <ModalTitle
            icon={isMobileSize ? undefined : icon}
            text={title}
            textColor={isMobileSize ? 'white' : undefined}
            iconPosition={isMobileSize ? undefined : 'left'}
            textAlign={isMobileSize ? 'center' : 'left'}
            margin="0"
          />
          <IconContainer onClick={onClose}>
            <Icon
              icon={Close}
              width={16}
              height={16}
              color={isMobileSize ? themeContext.white : themeContext.blue}
            />
          </IconContainer>
        </Row>
        <FormWrapper>
          {isForgottenPasswordOpen ? <ForgotPasswordForm /> : <LoginForm />}
        </FormWrapper>
      </Container>
    </Modal>
  );
};

export { LoginModal };
