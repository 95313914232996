import styled, { css } from 'styled-components';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../style/theme';
import { ReactComponent as ArrowDown1 } from '../../../../../assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';

export const Container = styled.div<{
  hasError?: boolean;
  isWrapper?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
  position: relative;
  ${(props) =>
    props.hasError &&
    `

  `}
  ${({ isWrapper, theme }) =>
    isWrapper &&
    `
    border: 1px solid ${theme.borderColor};
    padding: 12px;
  `}
`;

export const InfoWrapper = styled.div<{ isTooltipKeyLeftSide?: boolean }>`
  display: flex;
  flex-grow: 1;
  justify-content: ${({ isTooltipKeyLeftSide }) =>
    isTooltipKeyLeftSide ? 'flex-start' : 'space-between'};
  align-items: center;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const TooltipContainer = styled.div`
  max-width: 316px;
  width: 100%;
  padding: 0;
  text-align: center;
`;

export const TooltipParagraph = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

export const LabelContainer = styled.div<{ isTooltipKeyLeftSide?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ isTooltipKeyLeftSide }) =>
    !isTooltipKeyLeftSide &&
    css`
      flex: 1 1 0%;
    `}
  margin-right: ${({ isTooltipKeyLeftSide }) =>
    isTooltipKeyLeftSide ? 8 : 16}px;
  max-width: 280px;

  @media ${device.tablet} {
    margin-right: ${({ isTooltipKeyLeftSide }) =>
      isTooltipKeyLeftSide ? 4 : 16}px;
  }
`;

export const Label = styled.label<{ isOldDesign?: boolean }>`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme, isOldDesign }) =>
    hexToRGB(theme.blue, isOldDesign ? 0.5 : 0.7)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubLabel = styled(Label)`
  font-size: 10px;
`;

export const InputAdornment = styled.span`
  font-family: Roboto;
  opacity: 0.6;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: right;
  position: absolute;
  right: 12px;
  top: 12px;
  color: ${({ theme }) => theme.blue};
`;

export const Info = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  cursor: pointer;
  .icon {
    > svg,
    > svg > path {
      stroke: ${(props) => props.theme.blue};
    }
  }

  @media ${device.tablet} {
    margin-right: 0;
  }
`;

export const Wrapper = styled.div<{ isColumn?: boolean }>`
  position: relative;
  display: flex;
  align-items: ${({ isColumn }) => (isColumn ? 'flex-start' : 'center')};
  order: 2;
  flex: 2.125;
  flex-wrap: wrap;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  ${({ isColumn }) =>
    !isColumn &&
    css`
      gap: 8px;
    `}

  &:hover {
    & + label {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }

  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
  }
`;

export const InputWrapper = styled.div<{ isColumn?: boolean }>`
  position: relative;
  ${({ isColumn }) =>
    isColumn &&
    css`
      margin-top: 8px;
    `}
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const SelectField = styled.div<{
  isFocus: boolean;
  hasError?: boolean;
  isDisabled?: boolean;
  readOnly?: boolean;
  backgroundColor?: string;
  isValue?: boolean;
  isOldDesign?: boolean;
  isNoBorder?: boolean;
  isSaturatedBorder?: boolean;
  isPlaceholderCenter?: boolean;
  isCenteredText?: boolean;
}>`
  display: flex;
  user-select: none;
  position: relative;
  max-width: 280px;
  min-width: 280px;
  height: 40px;
  padding: 12px 40px 12px 12px;
  border-radius: 3px;
  touch-action: none;

  text-align: ${({ isPlaceholderCenter }) =>
    isPlaceholderCenter ? 'center' : 'left'};

  background-color: ${(props) =>
    props.hasError
      ? props.theme.redBackground
      : props.isOldDesign
      ? props.theme.greyBackground
      : props.backgroundColor ?? props.theme.white};

  outline: none;
  border: ${({ isNoBorder }) => (isNoBorder ? 0 : 1)}px solid
    ${({ theme, isValue, isOldDesign, isSaturatedBorder }) =>
      isOldDesign
        ? 'transparent'
        : isValue || isSaturatedBorder
        ? theme.grey
        : theme.borderColor};

  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  ${(props) =>
    props.hasError &&
    `
      & + label{
        color: ${hexToRGB(props.theme.red, 0.5)};
      }
  `}
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:not([type='radio']):not([type='checkbox']) {
    -webkit-appearance: none;
    border-radius: 3px;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield !important;
  }

  /* clears the ‘X’ from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &::placeholder {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${(props) => hexToRGB(props.theme.blue, 0.6)};
  }
  &:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.borderFocus};
    & + label {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }
  ${({ isFocus, backgroundColor, theme }) =>
    isFocus &&
    `
    cursor: pointer;
    background-color: ${backgroundColor || '#fff'};
    border-color: ${theme.borderFocus};
    color: ${theme.blue};
    & + label {
      color: ${theme.blue};
    }
    &::placeholder {
      color: ${hexToRGB(theme.blue, 0.5)};
    }
  `}
  ${({ isDisabled }) =>
    isDisabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}

  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
  }
`;

export const Placeholder = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => hexToRGB(theme.blue, 0.6)};
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ErrorContainer = styled.div`
  order: 3;
  margin-top: 4px;
  display: flex;
  width: 100%;
`;

export const Spacer = styled.div`
  flex: 1;
  padding-right: 16px;

  @media ${device.tablet} {
    display: none;
  }
`;

export const ErrorMessage = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.red};
`;

export const SelectIcon = styled(ArrowDown1)<{ isopen: string }>`
  position: absolute;
  top: 40%;
  transform: rotate(${({ isopen }) => (isopen === 'true' ? 180 : 0)}deg);
  fill: ${({ theme }) => theme.blue};
  transition: 0.2s ease;
  right: 12px;
  width: 9px;
  height: 9px;
`;

export const SelectDropdown = styled.div<{ isOptionsAtTop?: boolean }>`
  max-height: 230px;
  overflow-y: auto;
  position: absolute;
  z-index: 5;
  width: 100%;
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.borderColor};
  background-color: #fff;
  ${({ isOptionsAtTop }) => (isOptionsAtTop ? 'bottom: 42px;' : `top: 42px;`)}
`;

export const SelectOption = styled.div<{ isSelected: boolean }>`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  padding: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme, isSelected }) =>
    hexToRGB(theme.blue, isSelected ? 0.8 : 0.5)};
  border-top: 1px solid ${({ theme }) => theme.borderColor};

  &:hover {
    background-color: ${({ theme }) => hexToRGB(theme.borderColor, 0.3)};
    cursor: pointer;
  }

  &:first-child {
    border-top: none;
  }
`;
