import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PDFIcon } from 'assets/streamline-light/work-office-companies/office-files/office-file-pdf.svg';

import { MainButton } from '../../../common/components/ui/buttons';
import { Container, Text } from './seller-pdf-document-styles';
import { PDFPropertyMarketingStatisticsContainer } from '../../../pdf-creator/property-marketing-statistics/components/container';
import { formatSyncDate } from '../general-statistics-card/general-statistics-card';

interface IProps {
  isSimulation: boolean;
  lastSync?: string;
  isHiddenButton?: boolean;
}

const SellerPdfDocument: FC<IProps> = ({
  isSimulation,
  lastSync,
  isHiddenButton,
}) => {
  const { t } = useTranslation();
  const lastSyncDate = lastSync ? new Date(lastSync) : new Date();

  const [isPDFLoading, setPDFLoading] = useState(false);

  const onClick = () => {
    setPDFLoading(true);
  };

  return (
    <>
      <Container>
        <Text>
          {t('seller-portal.selling-process.pdf.text')}{' '}
          <b>{t('marketing-dashboard.statistics-last-sync')}</b>{' '}
          {formatSyncDate(lastSyncDate)}
        </Text>
        {!isHiddenButton && (
          <MainButton
            isDisabled={isPDFLoading}
            icon={PDFIcon}
            iconHeight={18}
            iconWidth={14}
            label={t('seller-portal.selling-process.pdf.button')}
            onClick={onClick}
            fluid
            centered
          />
        )}
      </Container>

      {isPDFLoading && (
        <PDFPropertyMarketingStatisticsContainer
          isSimulation={isSimulation}
          setLoading={setPDFLoading}
        />
      )}
    </>
  );
};

export { SellerPdfDocument };
