import { FC } from 'react';
import styled from 'styled-components';

import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { device } from '../../../../../style/theme';
import {
  BuyerFunctions as BuyerFunctionsType,
  OwnerFunctions as OwnerFunctionsType,
  SellerFunctions as SellerFunctionsType,
  useReadBuyerFunctionsListQuery,
  useReadOwnerFunctionsListQuery,
  useReadSellerFunctionsListQuery,
} from '../../../../../generated';

const Container = styled.div`
  // background-color: ${({ theme }) => theme.white};
  display: flex;
  width: 100%;
  overflow: hidden;

  @media ${device.mobile} {
    flex-direction: column;
    max-width: 100%;
    height: 100%;
  }
`;

const ImageWrapper = styled.div`
  max-width: 36px;
  max-height: 36px;
  position: relative;

  @media ${device.mobile} {
    margin: 0 auto 16px auto;
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const Content = styled.div`
  flex: 1;
  padding: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  min-width: 0;

  @media ${device.mobile} {
    padding: 0;
    text-align: center;
  }
`;

const Title = styled.h4`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${(props) => props.theme.blue};
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;

  @media ${device.mobile} {
    text-align: center;
  }
`;

const Text = styled.div`
  color: ${(props) => hexToRGB(props.theme.blue, 0.6)};
  font-family: Roboto, sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.5px;
`;

interface FunctionItemProps {
  recordIds: string[];
}

interface FunctionCardProps {
  section: BuyerFunctionsType | OwnerFunctionsType | SellerFunctionsType;
}

interface FunctionsListResponse {
  data: {
    data: [BuyerFunctionsType | OwnerFunctionsType | SellerFunctionsType];
  };
  isLoading: boolean;
}

const FunctionCardItem: FC<FunctionCardProps> = ({ section }) => {
  if (!section) return null;

  return (
    <Container>
      {section.iconUrl && (
        <ImageWrapper>
          <Image
            src={section.iconUrl}
            key={section.iconKey}
            alt={section.iconAlt || ''}
          />
        </ImageWrapper>
      )}
      <Content>
        <Title>{section.title}</Title>
        <Text
          dangerouslySetInnerHTML={{ __html: section.expandedText ?? '' }}
        />
      </Content>
    </Container>
  );
};

export const FunctionsCardsBuyer: FC<FunctionItemProps> = ({ recordIds }) => {
  const { data, isLoading } =
    useReadBuyerFunctionsListQuery<FunctionsListResponse>({
      ids: recordIds,
    });

  if (isLoading) return null;

  const sections = data?.data || [];

  return (
    <>
      {sections.map((section) => (
        <FunctionCardItem key={section._id} section={section} />
      ))}
    </>
  );
};

export const FunctionsCardsOwner: FC<FunctionItemProps> = ({ recordIds }) => {
  const { data, isLoading } =
    useReadOwnerFunctionsListQuery<FunctionsListResponse>({
      ids: recordIds,
    });

  if (isLoading) return null;

  const sections = data?.data || [];

  return (
    <>
      {sections.map((section) => (
        <FunctionCardItem key={section._id} section={section} />
      ))}
    </>
  );
};

export const FunctionsCardsSeller: FC<FunctionItemProps> = ({ recordIds }) => {
  const { data, isLoading } =
    useReadSellerFunctionsListQuery<FunctionsListResponse>({
      ids: recordIds,
    });

  if (isLoading) return null;

  const sections = data?.data || [];

  return (
    <>
      {sections.map((section) => (
        <FunctionCardItem key={section._id} section={section} />
      ))}
    </>
  );
};
