import styled from 'styled-components';
import { isNumber } from 'lodash';

export const Container = styled.div<{
  isMarginTop: boolean;
  isBold?: boolean;
  mt?: number;
  mb?: number;
}>`
  display: flex;
  flex-direction: column;
  ${({ mt }) => isNumber(mt) && `margin-top: ${mt}px;`}
  ${({ mb }) => isNumber(mb) && `margin-bottom: ${mb}px;`}

  & > div:first-child {
    display: flex;
    justify-content: space-between;

    & > div {
      gap: 8px;
      align-items: center;
      display: flex;
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.5px;
      color: ${({ theme }) => theme.blue};
    }
  }

  & > div:last-child {
    margin: ${({ isMarginTop }) => (isMarginTop ? 4 : 0)}px 0 0;
    width: 100%;
    border-top: ${({ isBold }) =>
      isBold ? `2px solid rgba(0,48,94, 1)` : `solid 1px #d9d9d9`};
  }
`;

export const Div = styled.div``;
