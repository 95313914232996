import { Path, Svg } from '@react-pdf/renderer';

import { defaultTheme } from '../../../../style/theme';

const MultiFamilyHouseIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={'M1.5 10.5V23a.5.5 0 0 0 .5.5h20a.5.5 0 0 0 .5-.5V10.5'}
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={
          'M7.527 10.5a.5.5 0 0 0 .5-.444 4 4 0 0 1 7.952 0 .5.5 0 0 0 .5.444h6.288a.5.5 0 0 0 .465-.686l-3.6-9A.5.5 0 0 0 19.161.5H4.839a.5.5 0 0 0-.465.314l-3.6 9a.5.5 0 0 0 .465.686Z'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={
          'M13.5 11a1.5 1.5 0 0 0-3 0v3a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5Zm-3 12.5V20a1.5 1.5 0 0 1 3 0v3.5m3-6h4v3h-4Zm0-5h4v3h-4Zm-13 5h4v3h-4Zm0-5h4v3h-4Z'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { MultiFamilyHouseIcon };
