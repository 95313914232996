import { AddressPlaceTypes } from '../interface';
import { Feature } from '../../../services/mapbox/interfaces';

type ParsedLocation = {
  mapboxLocality: string;
  mapboxNeighborhood: string;
  mapboxPostCode: string;
  mapboxCity: string;
  mapboxRegion: string;
  mapboxCountry: string;
  mapboxPlaceName: string;
  longitude: number;
  latitude: number;
};

const parseLocation = (geoCodingResult: Feature): ParsedLocation => {
  if (!geoCodingResult) {
    return {
      mapboxLocality: '',
      mapboxNeighborhood: '',
      mapboxPostCode: '',
      mapboxCity: '',
      mapboxRegion: '',
      mapboxCountry: '',
      mapboxPlaceName: '',
      longitude: 0,
      latitude: 0,
    };
  }
  const {
    center,
    context,
    text,
    place_type: placeType,
    place_name_de: placeNameDE,
    place_name: placeName,
  } = geoCodingResult;
  let mapboxLocality = '';
  let mapboxNeighborhood = '';
  let mapboxPostCode = '';
  let mapboxCity = '';
  let mapboxRegion = '';
  let mapboxCountry = '';
  const mapboxPlaceName = placeNameDE || placeName;

  context?.forEach((c) => {
    if (c?.id.includes(AddressPlaceTypes.locality)) {
      mapboxLocality = c.text;
    }
    if (c?.id.includes(AddressPlaceTypes.neighborhood)) {
      mapboxNeighborhood = c.text;
    }
    if (c?.id.includes(AddressPlaceTypes.postcode)) {
      mapboxPostCode = c.text;
    }
    if (c?.id.includes('region')) {
      mapboxRegion = c.text;
    }
    if (c?.id.includes('place')) {
      mapboxCity = c.text;
    }
    if (c?.id.includes('country')) {
      mapboxCountry = c.text;
    }
  });

  if (placeType?.[0] === AddressPlaceTypes.locality) {
    mapboxLocality = text;
  }
  if (placeType?.[0] === AddressPlaceTypes.neighborhood) {
    mapboxNeighborhood = text;
  }
  if (placeType?.[0] === AddressPlaceTypes.postcode) {
    mapboxPostCode = text;
  }
  if (placeType?.[0] === AddressPlaceTypes.region) {
    mapboxRegion = text;
  }

  if (placeType?.includes(AddressPlaceTypes.place)) {
    mapboxCity = text;
  }

  return {
    mapboxLocality,
    mapboxNeighborhood,
    mapboxPostCode,
    mapboxCity,
    mapboxRegion,
    mapboxCountry,
    mapboxPlaceName,
    longitude: center[0],
    latitude: center[1],
  };
};

export { parseLocation };
