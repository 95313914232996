import { useCallback, useEffect } from 'react';
import { batch } from 'react-redux';

import { TimifyPersonalisationIds } from '../../../../../timify/interfaces';
import { showServices } from '../../../../form-property-valuation-wizard/constants';
import { IValuationForm } from '../../../../form-property-valuation-wizard/interface';
import {
  clearErrors,
  setPropertyDossierAction,
  toggleLoading,
  setTimifyParams,
} from '../../../redux/valuationWizardV2Slice';
import { parseValuationInput } from '../../../../utils/parse-valuation-input';
import { CreatePropertyInput } from '../../../../../../generated';
import { setErrors } from '../../../../../auth/redux/authSlice';
import { FormError } from '../../../../interface';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useCreateProperty } from '../../../../form-property-valuation-wizard/hooks/useCreateProperty';
import { useCreatePropertyDossierMutation } from '../../../../../../services/graphql/enhanced';
import { useGetCountry } from '../../../../../localization/get-country';

type TReturn = {
  onSubmit: (formData: IValuationForm, owner?: string) => Promise<void>;
};

const useSubmitPersonalEVForm = (): TReturn => {
  const dispatch = useAppDispatch();
  const { countryCode } = useGetCountry();

  const selectedAddress = useAppSelector(
    (state) => state.valuationWizardV2.selectedAddress
  );
  const propertyDossier = useAppSelector(
    (state) => state.valuationWizardV2.propertyDossier
  );
  const firstName = useAppSelector((state) => state.auth.user?.name);
  const lastName = useAppSelector((state) => state.auth.user?.surname);
  const email = useAppSelector((state) => state.auth.user?.email);
  const phone = useAppSelector((state) => state.auth.user?.phone);
  const userAuthInput = useAppSelector((state) => state.auth.userInput);

  const { createProperty } = useCreateProperty();
  const [createPropertyDossier] = useCreatePropertyDossierMutation();

  const onSubmit = useCallback(
    async (formData: IValuationForm, owner?: string) => {
      if (selectedAddress && owner) {
        batch(() => {
          dispatch(toggleLoading(true));
          dispatch(clearErrors());
        });

        const valuationInput = parseValuationInput(
          formData,
          selectedAddress
        ) as CreatePropertyInput;

        try {
          const createPropertyResponse = await createProperty({
            valuationInput,
            userId: owner,
          });
          if (createPropertyResponse.createProperty._id) {
            const createPropertyDossierResponse = await createPropertyDossier({
              input: {
                propertyId: createPropertyResponse.createProperty._id,
              },
            }).unwrap();
            if (
              createPropertyDossierResponse.createPropertyDossier?.dossierUrl
            ) {
              dispatch(
                setPropertyDossierAction(
                  createPropertyDossierResponse.createPropertyDossier
                )
              );
            }
          }
        } catch (error: unknown) {
          dispatch(setErrors(error as FormError));
        } finally {
          dispatch(toggleLoading(false));
        }
      }
    },
    [selectedAddress, dispatch, createProperty, createPropertyDossier]
  );

  useEffect(() => {
    if (propertyDossier) {
      dispatch(
        setTimifyParams({
          serviceExternalIds: TimifyPersonalisationIds.PersonalValuation,
          firstName: firstName ?? userAuthInput?.name,
          lastName: lastName ?? userAuthInput?.surname,
          email: email ?? userAuthInput?.email,
          phone:
            phone ?? `+${userAuthInput?.phonePrefix}${userAuthInput?.phone}`,
          country: countryCode,
          dossierUrl: propertyDossier?.dossierUrl ?? '',
          showServices,
          showFooterBackButton: '1',
        })
      );
    }
  }, [
    countryCode,
    dispatch,
    email,
    firstName,
    lastName,
    phone,
    propertyDossier,
    userAuthInput?.email,
    userAuthInput?.name,
    userAuthInput?.phone,
    userAuthInput?.phonePrefix,
    userAuthInput?.surname,
  ]);

  return { onSubmit };
};

export { useSubmitPersonalEVForm };
