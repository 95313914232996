import styled from 'styled-components';
import {
  Headline2,
  Headline3,
  ParagraphText,
} from '../../../../common/components/ui/typography';
import { device } from '../../../../../style/theme';

export const Container = styled.div`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  background-color: ${(props) => props.theme.white};

  @media ${device.tablet} {
    padding: 16px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const Title = styled(Headline2)`
  margin-right: 12px;
  font-size: 20px;
`;

export const Subtitle = styled(Headline3)`
  margin: 16px 0 12px 0;
`;

export const Description = styled(ParagraphText)`
  margin-bottom: 16px;
`;

export const ServicesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Form = styled.form`
  width: 100%;
`;
