import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Text } from './financing-calculation-content-styles';
import { CalculationTable } from './calculation-table/calculation-table';
import {
  FinancingProposition,
  FinancingReason,
  FinancingVariantData,
} from '../../../../../../../../generated';
import { getTotalTermOfLoanUntilFullRepayment } from '../../../../../../utils/getTotalTermOfLoanUntilFullRepayment';
import { numberFormat } from '../../../../../../utils/numberFormat';
import { useAppSelector } from '../../../../../../../common/hooks';
import { formatPeriodDate } from '../../../../../../../property/utils/date';

interface IProps {
  isLoading?: boolean;
  financingData?: FinancingVariantData;
  financingProposition?: FinancingProposition | null;
  isDescribed?: boolean;
}

const FinancingCalculationContent: FC<IProps> = ({
  isLoading,
  financingData,
  financingProposition,
  isDescribed,
}) => {
  const { t } = useTranslation();
  const userInput = useAppSelector(
    (state) => state.financing.editWidget.userInputFinancingCalculation
  );
  const purchasePriceForm = userInput?.purchasePrice;
  const reasonFormValue = userInput?.reason;
  const equityCapitalFormValue = userInput?.equityCapital;
  const buildingCostsFormValue = userInput?.buildingCosts;
  const payoutDateFormValue = userInput?.payoutDate;
  const estimatedRemainingDebtFormValue = userInput?.estimatedRemainingDebt;
  const desiredLoanAmountFormValue = userInput?.desiredLoanAmount;
  const landPriceFormValue = userInput?.landPrice;
  const propertyValueFormValue = userInput?.propertyValue;
  const debitRateCommitmentFormValue = userInput?.debitRateCommitment;
  const { calculateFinancingPropositions } = useAppSelector(
    (state) => state.financing.editWidget
  );

  const structure = useMemo(() => {
    const reason = financingData?.reason;
    const purchasePrice = financingData?.purchasePrice;
    const equityCapitalData = financingData?.equityCapital;
    const buildingCostsData = financingData?.buildingCosts;
    const payoutDateData = financingData?.payoutDate;
    const estimatedRemainingDebtData = financingData?.estimatedRemainingDebt;
    const desiredLoanAmountData = financingData?.desiredLoanAmount;
    const debitRateCommitmentData = financingData?.debitRateCommitment;

    let priceLabel = '';
    let priceValue = '';
    let priceTooltip = '';
    let equityCapital = '';
    let equityCapitalTooltip =
      'financing-portal.form.inputs.equity-capital.tooltip';
    let buildingCosts = '';
    let payoutDate = '';
    let estimatedRemainingDebt = '';
    let desiredLoanAmount = '';
    switch (reasonFormValue || reason) {
      case FinancingReason.PurchaseOfExistingProperty:
      case FinancingReason.PurchaseOfNewBuilding:
        priceLabel = 'purchase-intent.form.price';
        priceValue = numberFormat(
          calculateFinancingPropositions ? purchasePriceForm : purchasePrice
        );
        priceTooltip =
          'financing-portal-page.tabs.calculator.data.table.purchase-price.tooltip';
        equityCapital = numberFormat(
          calculateFinancingPropositions
            ? equityCapitalFormValue
            : equityCapitalData
        );
        equityCapitalTooltip =
          'financing-portal.form.inputs.equity-capital.purchase-of-existing-property.tooltip';
        break;
      case FinancingReason.FollowUpFinancing:
        priceLabel = 'financing-portal.form.inputs.property-value.label';
        priceValue = numberFormat(
          calculateFinancingPropositions
            ? propertyValueFormValue
            : financingData?.propertyValue
        );
        priceTooltip =
          'financing-portal.form.inputs.property-value.tooltip.calculation';
        estimatedRemainingDebt = numberFormat(
          calculateFinancingPropositions
            ? estimatedRemainingDebtFormValue
            : estimatedRemainingDebtData
        );
        payoutDate = formatPeriodDate(
          new Date(
            calculateFinancingPropositions
              ? payoutDateFormValue
              : payoutDateData
          ),
          true,
          true
        );
        break;
      case FinancingReason.RaisingCapital:
      case FinancingReason.Modernization:
        priceLabel = 'financing-portal.form.inputs.property-value.label';
        priceValue = numberFormat(
          calculateFinancingPropositions
            ? propertyValueFormValue
            : financingData?.propertyValue
        );
        priceTooltip =
          'financing-portal.form.inputs.property-value.tooltip.calculation';
        desiredLoanAmount = numberFormat(
          calculateFinancingPropositions
            ? desiredLoanAmountFormValue
            : desiredLoanAmountData
        );
        break;
      case FinancingReason.OwnConstructionProject:
        priceLabel = 'financing-portal.form.inputs.land-price.label';
        priceValue = numberFormat(
          landPriceFormValue || financingData?.landPrice
        );
        priceTooltip =
          'financing-portal.form.inputs.land-price.tooltip.calculation';
        equityCapital = numberFormat(
          calculateFinancingPropositions
            ? equityCapitalFormValue
            : equityCapitalData
        );
        equityCapitalTooltip =
          'financing-portal.form.inputs.equity-capital.own-construction-project.tooltip';
        buildingCosts = numberFormat(
          calculateFinancingPropositions
            ? buildingCostsFormValue
            : buildingCostsData
        );
        break;
      default:
        priceLabel = 'purchase-intent.form.price';
        priceValue = numberFormat(
          calculateFinancingPropositions ? purchasePriceForm : purchasePrice
        );
        priceTooltip =
          'financing-portal-page.tabs.calculator.data.table.purchase-price.tooltip';
        break;
    }
    const debitRateCommitment = calculateFinancingPropositions
      ? debitRateCommitmentFormValue
      : debitRateCommitmentData;

    const proposition = financingProposition?.propositions[0];
    const buildingBlock =
      financingProposition?.propositions[0]?.financingBuildingBlocks?.[0];
    const totalTermOfLoanUntilFullRepayment =
      getTotalTermOfLoanUntilFullRepayment(
        buildingBlock?.dateOfLastInstallment ?? ''
      );

    let repaidAmount = 0;
    if (
      buildingBlock?.loanAmount &&
      buildingBlock?.residualDebtAfterTheEndOfTheFixedInterestPeriod
    ) {
      repaidAmount = Math.round(
        buildingBlock?.loanAmount -
          buildingBlock?.residualDebtAfterTheEndOfTheFixedInterestPeriod
      );
    }

    let totalInterestPayments = 0;
    if (
      buildingBlock?.annuityDetails?.fixedInterestInYears &&
      buildingBlock?.rateMonthly &&
      buildingBlock?.loanAmount &&
      repaidAmount
    ) {
      totalInterestPayments =
        buildingBlock?.annuityDetails?.fixedInterestInYears *
          12 *
          buildingBlock?.rateMonthly -
        repaidAmount;
    }

    let totalInterestPaymentsEnd = 0;

    if (buildingBlock?.costOverTotalTerm && buildingBlock?.loanAmount) {
      totalInterestPaymentsEnd = Math.round(
        buildingBlock?.costOverTotalTerm - buildingBlock?.loanAmount
      );
    }

    const isBold = true;
    const isSpan = true;

    return [
      // ...(financingPropositions.maximalPurchasePrice
      //   ? [
      //       [
      //         {
      //           name: 'financing-portal-page.tabs.calculator.data.table.maximum-purchase-budget',
      //           value: `${numberFormat(
      //             financingPropositions.maximalPurchasePrice
      //           )} €`,
      //           skeletonWidth: '63px',
      //           isBold: true,
      //         },
      //       ],
      //     ]
      //   : []),
      [
        ...(buildingCosts
          ? [
              {
                name: 'financing-portal.form.inputs.building-costs.label',
                value: `${buildingCosts} €`,
                tooltipKey:
                  'financing-portal.form.inputs.building-costs.tooltip.calculation',
                skeletonWidth: '63px',
                isBold,
              },
            ]
          : []),
        {
          name: priceLabel,
          value: `${priceValue} €`,
          tooltipKey: priceTooltip,
          skeletonWidth: '63px',
          isBold,
        },
        ...(equityCapital
          ? [
              {
                name: 'financing-portal.form.inputs.equity-capital.label',
                value: `${equityCapital} €`,
                tooltipKey: equityCapitalTooltip,
                skeletonWidth: '63px',
                isBold,
              },
            ]
          : []),
        ...(payoutDate
          ? [
              {
                name: 'financing-portal.form.inputs.payout-date.label',
                value: payoutDate,
                tooltipKey:
                  'financing-portal.form.inputs.payout-date.tooltip.calculation',
                skeletonWidth: '63px',
                isBold,
              },
            ]
          : []),
        ...(estimatedRemainingDebt
          ? [
              {
                name: 'financing-portal.form.inputs.estimated-remaining-debt.label',
                value: `${estimatedRemainingDebt} €`,
                tooltipKey:
                  'financing-portal.form.inputs.estimated-remaining-debt.tooltip.calculation',
                skeletonWidth: '63px',
                isBold,
                isSpan,
              },
            ]
          : []),
        ...(desiredLoanAmount
          ? [
              {
                name: 'financing-portal.form.inputs.desired-loan-amount.label',
                value: `${desiredLoanAmount} €`,
                tooltipKey:
                  'financing-portal.form.inputs.desired-loan-amount.tooltip.calculation',
                skeletonWidth: '63px',
                isBold,
                isSpan,
              },
            ]
          : []),
      ],
      [
        {
          name: 'financing-portal-page.tabs.calculator.data.table.debit-rate-commitment', //
          tooltipKey:
            'financing-portal-page.tabs.calculator.data.table.debit-rate-commitment.tooltip', //
          value: `${financingProposition?.debitRateCommitment} Jahre`,
          skeletonWidth: '63px',
        },
        {
          name: 'financing-portal-page.tabs.calculator.data.table.loan-amount', //
          tooltipKey:
            'financing-portal-page.tabs.calculator.data.table.loan-amount.tooltip', //
          value: `${numberFormat(proposition?.loanAmount)} €`,
          skeletonWidth: '78px',
          isBold,
        },
      ],
      [
        {
          name: 'financing-portal-page.tabs.calculator.data.table.monthly-loan-installment', //
          tooltipKey:
            'financing-portal-page.tabs.calculator.data.table.monthly-loan-installment.tooltip', //
          value: `${numberFormat(proposition?.totalRatePerMonth)} €`,
          skeletonWidth: '63px',
          isBold,
          // subfields: [
          //   {
          //     name: 'financing-portal-page.tabs.calculator.data.table.interest-portion-of-total-rate',
          //     subName: '62,26%',
          //     value: '1.529,00 €',
          //     skeletonWidth: '63px',
          //   },
          //   {
          //     name: 'financing-portal-page.tabs.calculator.data.table.repayment-portion-of-total-installment',
          //     subName: '37,74%',
          //     value: '926,67 €',
          //     skeletonWidth: '63px',
          //   },
          // ],
        },
        {
          name: 'financing-portal-page.tabs.calculator.data.table.debit-interest', //
          tooltipKey:
            'financing-portal-page.tabs.calculator.data.table.debit-interest.tooltip', //
          value: `${numberFormat(proposition?.shouldInterest, true)} %`,
          skeletonWidth: '63px',
        },
        {
          name: 'financing-portal-page.tabs.calculator.data.table.repayment-in-the-first-year', //
          tooltipKey:
            'financing-portal-page.tabs.calculator.data.table.repayment-in-the-first-year.tooltip', //
          value: `${numberFormat(
            proposition?.financingBuildingBlocks[0].repaymentRateInPercent,
            true
          )} %`,
          skeletonWidth: '63px',
        },
        {
          name: 'financing-portal-page.tabs.calculator.data.table.annuity', //
          tooltipKey:
            'financing-portal-page.tabs.calculator.data.table.annuity.tooltip', //
          value: `${numberFormat(
            Number(proposition?.shouldInterest) +
              Number(
                proposition?.financingBuildingBlocks[0].repaymentRateInPercent
              ),
            true
          )} %`,
          skeletonWidth: '63px',
        },
        {
          name: 'financing-portal-page.tabs.calculator.data.table.total-term-of-the-loan-until-full-repayment', //
          tooltipKey:
            'financing-portal-page.tabs.calculator.data.table.total-term-of-the-loan-until-full-repayment.tooltip', //
          value: totalTermOfLoanUntilFullRepayment,
          skeletonWidth: '114px',
          isBold,
          isSpan,
        },
      ],
      [
        {
          name: 'financing-portal-page.tabs.calculator.data.table.at-the-end-of-the-fixed-interest-period-of',
          subName: ` ${debitRateCommitment} Jahren`,
          skeletonWidth: '',
          isBold,
          // subfields: [
          //   {
          //     name: 'financing-portal-page.tabs.calculator.data.table.interest-portion-of-total-rate',
          //     subName: '62,26%',
          //     value: '936,50 €',
          //     skeletonWidth: '63px',
          //   },
          //   {
          //     name: 'financing-portal-page.tabs.calculator.data.table.repayment-portion-of-total-installment',
          //     subName: '37,74%',
          //     value: '1.519,16 €',
          //     skeletonWidth: '63px',
          //   },
          // ],
        },
        {
          name: 'financing-portal-page.tabs.calculator.data.table.residual-debt', //
          tooltipKey:
            'financing-portal-page.tabs.calculator.data.table.residual-debt.tooltip', //
          value: `${numberFormat(
            buildingBlock?.residualDebtAfterTheEndOfTheFixedInterestPeriod
          )} €`,
          skeletonWidth: '78px',
          isBold,
        },
        {
          name: 'financing-portal-page.tabs.calculator.data.table.repaid-amount', //
          tooltipKey:
            'financing-portal-page.tabs.calculator.data.table.repaid-amount-end-fixed-interest-period.tooltip', //
          value: `${numberFormat(repaidAmount)} €`,
          skeletonWidth: '78px',
        },
        {
          name: 'financing-portal-page.tabs.calculator.data.table.total-interest-payments', //
          tooltipKey:
            'financing-portal-page.tabs.calculator.data.table.total-interest-payments-end-fixed-interest-period.tooltip', //
          value: `${numberFormat(totalInterestPayments)} €`,
          skeletonWidth: '78px',
        },
        // {
        //   name: 'financing-portal-page.tabs.calculator.data.table.repayment-rate',
        //   value: '5,35 %',
        //   skeletonWidth: '46px',
        // },
      ],
      [
        {
          name: 'financing-portal-page.tabs.calculator.data.table.at-the-end-of-the-loan',
          value: '',
          skeletonWidth: '',
          isBold,
          subfields: [
            {
              name: 'financing-portal-page.tabs.calculator.data.table.repaid-amount', //
              tooltipKey:
                'financing-portal-page.tabs.calculator.data.table.repaid-amount.tooltip', //
              value: `${numberFormat(proposition?.loanAmount)} €`,
              skeletonWidth: '78px',
            },
          ],
        },
        {
          name: 'financing-portal-page.tabs.calculator.data.table.total-interest-payments', //
          tooltipKey:
            'financing-portal-page.tabs.calculator.data.table.total-interest-payments.tooltip', //
          value: `${numberFormat(totalInterestPaymentsEnd)} €`,
          skeletonWidth: '78px',
          isBold,
        },
        {
          name: 'financing-portal-page.tabs.calculator.data.table.last-loan-installment', //
          tooltipKey:
            'financing-portal-page.tabs.calculator.data.table.last-loan-installment.tooltip', //
          value: `${numberFormat(buildingBlock?.closingRate)} €`,
          skeletonWidth: '78px',
        },
      ],
    ];
  }, [
    financingData?.reason,
    financingData?.purchasePrice,
    financingData?.equityCapital,
    financingData?.buildingCosts,
    financingData?.payoutDate,
    financingData?.estimatedRemainingDebt,
    financingData?.desiredLoanAmount,
    financingData?.propertyValue,
    financingData?.landPrice,
    reasonFormValue,
    financingProposition?.propositions,
    financingProposition?.debitRateCommitment,
    calculateFinancingPropositions,
    equityCapitalFormValue,
    purchasePriceForm,
    estimatedRemainingDebtFormValue,
    payoutDateFormValue,
    propertyValueFormValue,
    desiredLoanAmountFormValue,
    buildingCostsFormValue,
    landPriceFormValue,
  ]);

  return (
    <Container>
      {structure.map((item) => (
        <CalculationTable
          key={item[0].name}
          content={item}
          isLoading={isLoading}
        />
      ))}
      {isDescribed && (
        <Text>
          {t('financing-portal-page.tabs.calculator.data.table.description')}{' '}
          <a
            href="https://www.vp-finance.de/datenschutz"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t(
              'register.form.input.label.terms-and-conditions.financing-2-link'
            )}
          </a>
          .
        </Text>
      )}
    </Container>
  );
};

export { FinancingCalculationContent };
