export enum PortalType {
  Overview = 'Overview',
  Owner = 'Owner',
  Buyer = 'Buyer',
  Seller = 'Seller',
  Financing = 'Financing',
  Broker = 'Broker',
}

type Page = {
  title: string;
  path: string;
  pathWithLang: string;
  pathEmptyState?: string;
  pathWithoutParams: string;
  description: string;
  qp?: { [key: string]: string };
  portalType: PortalType;
};

enum Pages {
  OverviewLandingPage = 'OverviewLandingPage',
  OwnerLandingPage = 'OwnerLandingPage',
  OwnerLandingPageOnlineWidget = 'OwnerLandingPageOnlineWidget',
  OwnerLandingPagePersonalWidget = 'OwnerLandingPagePersonalWidget',
  OwnerLandingPageAppointmentModal = 'OwnerLandingPageAppointmentModal',
  LoginPage = 'LoginPage',
  LoginBuyerPage = 'LoginBuyerPage',
  LoginSellerPage = 'LoginSellerPage',
  FinancingLandingPage = 'FinancingLandingPage',
  FinancingPortalPage = 'FinancingPortalPage',
  FinancingLandingPageWidget = 'FinancingLandingPageWidget',
  FinancingLandingPageCertificateWidget = 'FinancingLandingPageCertificateWidget',
  BuyerLandingPage = 'BuyerLandingPage',
  BuyerLandingPageWidget = 'BuyerLandingPageWidget',
  BuyerSecondLandingPage = 'BuyerSecondLandingPage',
  BuyerSignUpPage = 'BuyerSignUpPage',
  SellerSignUpPage = 'SellerSignUpPage',
  SellerSecondLandingPage = 'SellerSecondLandingPage',
  RequestExposePage = 'RequestExposePage',
  SearchProfilePage = 'SearchProfilePage',
  PropertyPage = 'PropertyPage',
  PropertyLockedPage = 'PropertyLockedPage',
  PropertyPortfolioPage = 'PropertyPortfolioPage',
  PropertyInvitationPage = 'PropertyInvitationPage',
  SharePropertySignInPage = 'SharePropertySignInPage',
  ExpiredConfirmEmailPage = 'ExpiredConfirmEmailPage',
  ConfirmEmailPage = 'ConfirmEmailPage',
  ConfirmBuyerEmailPage = 'ConfirmBuyerEmailPage',
  ConfirmSellerEmailPage = 'ConfirmSellerEmailPage',
  ContentPage = 'ContentPage',
  RegionalLandingPage = 'RegionalLandingPage',
  NotFoundPage = 'NotFoundPage',
  RedirectPage = 'RedirectPage',
  DownloadExpose = 'DownloadExpose',
  DownloadSellerExpose = 'DownloadSellerExpose',
  ResetPasswordPage = 'ResetPasswordPage',
  SellerLandingPage = 'SellerLandingPage',
  SellerPropertiesPage = 'SellerPropertiesPage',
  SellingProcessPage = 'SellingProcessPage',
  VonPollIFrameAuthPage = 'VonPollIFrameAuthPage',
  BuyerExposeFiles = 'BuyerExposeFiles',
  UnsubscribePage = 'UnsubscribePage',
}

export const siteMap: Record<Pages, Page> = {
  OverviewLandingPage: {
    title: 'Overview Landing page',
    path: '/',
    pathWithoutParams: '/',
    pathWithLang: '/:lang/',
    description: '',
    portalType: PortalType.Overview,
  },
  OwnerLandingPage: {
    title: 'Owner Landing page',
    path: '/immobilie-bewerten',
    pathWithoutParams: '/immobilie-bewerten',
    pathWithLang: '/:lang/immobilie-bewerten',
    description: '',
    portalType: PortalType.Owner,
  },
  OwnerLandingPageOnlineWidget: {
    title: 'Owner Landing Page Online Widget',
    path: '/online-immobilienbewertung',
    pathWithoutParams: '/online-immobilienbewertung',
    pathWithLang: '/:lang/online-immobilienbewertung',
    description: '',
    portalType: PortalType.Owner,
  },
  OwnerLandingPagePersonalWidget: {
    title: 'Owner Landing Page Personal Widget',
    path: '/persoenliche-immobilienbewertung',
    pathWithoutParams: '/persoenliche-immobilienbewertung',
    pathWithLang: '/:lang/persoenliche-immobilienbewertung',
    description: '',
    portalType: PortalType.Owner,
  },
  OwnerLandingPageAppointmentModal: {
    title: 'Owner Landing Page Appointment Modal',
    path: '/appointment',
    pathWithoutParams: '/appointment',
    pathWithLang: '/:lang/appointment',
    description: '',
    portalType: PortalType.Owner,
  },
  LoginPage: {
    title: 'Login Page',
    path: '/login',
    pathWithoutParams: '/login',
    pathWithLang: '/:lang/login',
    description: '',
    portalType: PortalType.Owner,
  },
  BuyerLandingPage: {
    title: 'Buyer Landing Page',
    path: '/immobilie-kaufen',
    pathWithoutParams: '/immobilie-kaufen',
    pathWithLang: '/:lang/immobilie-kaufen',
    description: '',
    qp: {
      openWidget: 'openWidget=true',
    },
    portalType: PortalType.Buyer,
  },
  FinancingLandingPage: {
    title: 'Financing Landing Page',
    path: '/baufinanzierung',
    pathWithoutParams: '/baufinanzierung',
    pathWithLang: '/:lang/baufinanzierung',
    description: '',
    portalType: PortalType.Financing,
  },
  FinancingPortalPage: {
    title: 'Financing Portal Page',
    path: '/finanzieren/:financingId',
    pathWithoutParams: '/finanzieren',
    pathEmptyState: '/:lang/finanzieren',
    pathWithLang: '/:lang/finanzieren/:financingId',
    description: '',
    portalType: PortalType.Financing,
  },
  FinancingLandingPageWidget: {
    title: 'Financing Landing Page Widget',
    path: '/finanzierung-berechnen',
    pathWithoutParams: '/finanzierung-berechnen',
    pathEmptyState: '/:lang/finanzierung-berechnen',
    pathWithLang: '/:lang/finanzierung-berechnen',
    description: '',
    portalType: PortalType.Financing,
  },
  FinancingLandingPageCertificateWidget: {
    title: 'Financing Landing Page Certificate Widget',
    path: '/hypothekenzertifikat-anfragen',
    pathWithoutParams: '/hypothekenzertifikat-anfragen',
    pathEmptyState: '/:lang/hypothekenzertifikat-anfragen',
    pathWithLang: '/:lang/hypothekenzertifikat-anfragen',
    description: '',
    portalType: PortalType.Financing,
  },
  BuyerLandingPageWidget: {
    title: 'Buyer Landing Page Widget',
    path: '/immobilie-suchen',
    pathWithoutParams: '/immobilie-suchen',
    pathWithLang: '/:lang/immobilie-suchen',
    description: '',
    portalType: PortalType.Buyer,
  },
  BuyerSecondLandingPage: {
    title: 'Buyer Second Landing Page',
    path: '/buyer-second-landing-page',
    pathWithoutParams: '/buyer-second-landing-page',
    pathWithLang: '/:lang/buyer-second-landing-page',
    description: '',
    portalType: PortalType.Buyer,
  },
  BuyerSignUpPage: {
    title: 'Buyer Sign Up Page',
    path: '/buyer-sign-up-page',
    pathWithoutParams: '/buyer-sign-up-page',
    pathWithLang: '/:lang/buyer-sign-up-page',
    description: '',
    portalType: PortalType.Buyer,
  },
  SellerSignUpPage: {
    title: 'Seller Sign Up Page',
    path: '/seller-sign-up-page',
    pathWithoutParams: '/seller-sign-up-page',
    pathWithLang: '/:lang/seller-sign-up-page',
    description: '',
    portalType: PortalType.Seller,
  },
  SellerSecondLandingPage: {
    title: 'Seller Second Landing Page',
    path: '/seller-second-landing-page',
    pathWithoutParams: '/seller-second-landing-page',
    pathWithLang: '/:lang/seller-second-landing-page',
    description: '',
    portalType: PortalType.Seller,
  },
  LoginBuyerPage: {
    title: 'Login Buyer Page',
    path: '/buyer-login-page',
    pathWithoutParams: '/buyer-login-page',
    pathWithLang: '/:lang/buyer-login-page',
    description: '',
    portalType: PortalType.Buyer,
  },
  LoginSellerPage: {
    title: 'Login Seller Page',
    path: '/seller-login-page',
    pathWithoutParams: '/seller-login-page',
    pathWithLang: '/:lang/seller-login-page',
    description: '',
    portalType: PortalType.Seller,
  },
  RequestExposePage: {
    title: 'Request Expose Page',
    path: '/request-expose/:propertyId',
    pathWithoutParams: '/request-expose',
    pathWithLang: '/:lang/request-expose/:propertyId',
    description: '',
    portalType: PortalType.Buyer,
  },
  SearchProfilePage: {
    title: 'Search Profile Page',
    path: '/search-profile/:searchProfileId',
    pathWithoutParams: '/search-profile',
    pathWithLang: '/:lang/search-profile/:searchProfileId',
    description: '',
    portalType: PortalType.Buyer,
  },
  PropertyPage: {
    title: 'Property Page',
    path: '/property/:propertyId',
    pathWithoutParams: '/property',
    pathWithLang: '/:lang/property/:propertyId',
    description: '',
    portalType: PortalType.Owner,
  },
  PropertyLockedPage: {
    title: 'Property Locked Page',
    path: '/property-locked',
    pathWithoutParams: '/property-locked',
    pathWithLang: '/:lang/property-locked',
    description: '',
    portalType: PortalType.Owner,
  },
  PropertyPortfolioPage: {
    title: 'Property Portfolio',
    path: '/property-portfolio',
    pathWithoutParams: '/property-portfolio',
    pathWithLang: '/:lang/property-portfolio',
    description: '',
    portalType: PortalType.Owner,
  },
  PropertyInvitationPage: {
    title: 'Property Invitation Page',
    path: '/property-invitation/create-account/:invitationId',
    pathWithoutParams: '/property-invitation/create-account',
    pathWithLang: '/:lang/property-invitation/create-account/:invitationId',
    description: '',
    portalType: PortalType.Owner,
  },
  SharePropertySignInPage: {
    title: 'Share Property Sign In Page',
    path: '/property-invitation/:invitationId',
    pathWithoutParams: '/property-invitation',
    pathWithLang: '/:lang/property-invitation/:invitationId',
    description: '',
    portalType: PortalType.Owner,
  },
  ConfirmEmailPage: {
    title: 'Confirm Email Page',
    path: '/confirm_email',
    pathWithoutParams: '/confirm_email',
    pathWithLang: '/:lang/confirm_email',
    description: '',
    portalType: PortalType.Owner,
  },
  ExpiredConfirmEmailPage: {
    title: 'Expired Confirm Email Page',
    path: '/expired_confirm_email',
    pathWithoutParams: '/expired_confirm_email',
    pathWithLang: '/:lang/expired_confirm_email',
    description: '',
    portalType: PortalType.Owner,
  },
  ConfirmBuyerEmailPage: {
    title: 'Confirm Buyer Email Page',
    path: '/confirm_buyer_email',
    pathWithoutParams: '/confirm_buyer_email',
    pathWithLang: '/:lang/confirm_buyer_email',
    description: '',
    portalType: PortalType.Buyer,
  },
  ConfirmSellerEmailPage: {
    title: 'Confirm Seller Email Page',
    path: '/confirm_seller_email',
    pathWithoutParams: '/confirm_seller_email',
    pathWithLang: '/:lang/confirm_seller_email',
    description: '',
    portalType: PortalType.Seller,
  },
  DownloadExpose: {
    title: 'Download Expose',
    path: '/download-expose/:id/:token',
    pathWithoutParams: '/download-expose',
    pathWithLang: '/:lang/download-expose/:id/:token',
    description: '',
    portalType: PortalType.Buyer,
  },
  DownloadSellerExpose: {
    title: 'Download Seller Expose',
    path: '/download-seller-expose/:id/:token',
    pathWithoutParams: '/download-seller-expose',
    pathWithLang: '/:lang/download-seller-expose/:id/:token',
    description: '',
    portalType: PortalType.Seller,
  },
  ContentPage: {
    title: 'Content Page',
    path: '/datenschutzinformationenundnutzungsweise',
    pathWithoutParams: '/datenschutzinformationenundnutzungsweise',
    pathWithLang: '/:lang/datenschutzinformationenundnutzungsweise',
    description: '',
    portalType: PortalType.Owner,
  },
  RegionalLandingPage: {
    title: 'Regional Landing Page',
    path: '/:slug',
    pathWithoutParams: '/:slug',
    pathWithLang: '/:lang/:slug',
    description: '',
    portalType: PortalType.Owner,
  },
  ResetPasswordPage: {
    title: 'Reset Password Page',
    path: '/reset_password',
    pathWithoutParams: '/reset_password',
    pathWithLang: '/:lang/reset_password',
    description: '',
    portalType: PortalType.Owner,
  },
  SellerLandingPage: {
    title: 'Seller Landing Page',
    path: '/immobilie-verkaufen',
    pathWithoutParams: '/immobilie-verkaufen',
    pathWithLang: '/:lang/immobilie-verkaufen',
    description: '',
    portalType: PortalType.Seller,
  },
  SellerPropertiesPage: {
    title: 'Seller Properties Page',
    path: '/seller-properties',
    pathWithoutParams: '/seller-properties',
    pathWithLang: '/:lang/seller-properties',
    description: '',
    portalType: PortalType.Seller,
  },
  SellingProcessPage: {
    title: 'Selling ProcessPage Page',
    path: '/selling-process',
    pathWithoutParams: '/selling-process',
    pathWithLang: '/:lang/selling-process/:propertyId',
    description: '',
    portalType: PortalType.Seller,
  },
  VonPollIFrameAuthPage: {
    title: 'Von Poll IFrame Auth Page',
    path: '/von-poll-auth',
    pathWithoutParams: '/von-poll-auth',
    pathWithLang: '/:lang/von-poll-auth',
    description: '',
    portalType: PortalType.Owner,
  },
  UnsubscribePage: {
    title: 'Unsubscribe Page',
    path: '/unsubscribe',
    pathWithoutParams: '/unsubscribe',
    pathWithLang: '/:lang/unsubscribe/:userId',
    description: '',
    portalType: PortalType.Owner,
  },
  NotFoundPage: {
    title: 'Not Found Page',
    path: '/*',
    pathWithoutParams: '/*',
    pathWithLang: '/:lang/*',
    description: '',
    portalType: PortalType.Owner,
  },
  RedirectPage: {
    title: 'Redirect Page',
    path: '/*',
    pathWithoutParams: '/*',
    pathWithLang: '/*',
    description: '',
    portalType: PortalType.Owner,
  },
  BuyerExposeFiles: {
    title: 'Buyer Expose Files',
    path: '/buyer-expose-files',
    pathWithoutParams: '/buyer-expose-files',
    pathWithLang: '/:lang/buyer-expose-files',
    description: '',
    portalType: PortalType.Broker,
  },
};
