import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Arrow } from 'assets/navigation/mobile-menu-nested.svg';
import { device } from '../../../../style/theme';
import useGetLocalization from '../../../localization/get-localization';
import Icon from '../ui/icon';

const Box = styled.div``;

const TitleBtn = styled.h5`
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 24px;
  line-height: 1;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const ToggleIcon = styled(Icon)`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

const LinksBox = styled.div<{ isOpen: boolean }>`
  display: block;
  @media ${device.tablet} {
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
  }
`;

const Link = styled.a`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 13px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.95);
  text-decoration: none;
  display: block;
  line-height: 1;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
    transition: opacity 0.3s;
  }

  &:last-child {
    margin-bottom: 18px;
  }
`;

interface CollapseFooterProps {
  title: string;
  children: { label: string; path: string }[];
}

const CollapseFooter = ({ title, children }: CollapseFooterProps) => {
  const { t } = useTranslation();
  const lang = useGetLocalization();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Box>
      <TitleBtn onClick={() => setIsOpen(!isOpen)}>
        {t(title)}
        <ToggleIcon
          icon={Arrow}
          color={'rgba(255, 255, 255, .75)'}
          height={8}
          width={15}
        />
      </TitleBtn>
      <LinksBox isOpen={isOpen}>
        {children.map(({ label, path }) => {
          const linkPath = path.replace(
            '#appUrl',
            `${window.location.origin}/${lang}`
          );
          return (
            <Link key={`${label}--${path}`} href={linkPath} target={'_blank'}>
              {t(label)}
            </Link>
          );
        })}
      </LinksBox>
    </Box>
  );
};

export default CollapseFooter;
