import { ChangeEvent, FC, memo, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as File } from 'assets/streamline-light/files-folders/common-files/common-file-text.svg';
import { ReactComponent as Picture } from 'assets/streamline-light/images-photography/pictures/picture-polaroid-landscape.svg';
import { ReactComponent as Plan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { values } from 'lodash';
import { Accordion, AccordionSection } from '../../accordion/accordion';
import {
  InputFile,
  SectionType,
  UploadedFile,
} from '../../../../common/components/form/input/input-file/input-file';
import { buildAcceptTypes } from '../../../../common/components/form/input/input-file/utils/buildAcceptTypes';
import {
  documentTypes,
  imageTypes,
  propertyPlanTypes,
} from '../../../../common/components/form/input/input-file/constants';
import { MotivationBanner } from '../motivation-banner/motivation-banner';
import {
  setPropertyDocuments,
  setPropertyPhotos,
  setPropertyPlans,
} from '../../../redux/sellerPropertiesSlice';
import { useAppSelector } from '../../../../common/hooks';
import { BoxTitle } from '../../../../common/components/ui/typography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 16px 0;
`;

interface IProps {
  accordionsState?: { id: string; isOpen: boolean }[];
  onCollapseAccordion?: (id: string) => void;
  searchInput?: string;
  isDisabled?: boolean;
}

const TotalCount = styled(BoxTitle)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 1.6;
  padding: 2px 8px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.lightBlue};
  background-color: ${(props) => props.theme.greyBackground};
  margin-left: 12px;
`;

const FileUploaderBase: FC<IProps> = ({
  searchInput,
  accordionsState,
  onCollapseAccordion,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const propertyDocuments = useAppSelector(
    (state) => state.sellerProperties.propertyDocuments
  );

  const propertyPhotos = useAppSelector(
    (state) => state.sellerProperties.propertyPhotos
  );

  const propertyPlans = useAppSelector(
    (state) => state.sellerProperties.propertyPlans
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, section?: SectionType) => {
      switch (section) {
        case 'Documents':
          dispatch(
            setPropertyDocuments([
              ...propertyDocuments,
              ...values(e.target.files),
            ])
          );
          break;
        case 'Photos':
          dispatch(
            setPropertyPhotos([...propertyPhotos, ...values(e.target.files)])
          );
          break;
        case 'Plans':
          dispatch(
            setPropertyPlans([...propertyPlans, ...values(e.target.files)])
          );
          break;
        default:
          break;
      }
    },
    [dispatch, propertyDocuments, propertyPhotos, propertyPlans]
  );

  const sections: AccordionSection[] = useMemo(
    () => [
      {
        _id: 'upload_documents',
        title: t('upload-document.header'),
        expandedContent: (
          <InputFile
            title={t('upload-document.title')}
            description={t('upload-document.description')}
            accept={buildAcceptTypes(documentTypes)}
            multiple
            section={'Documents'}
            onEditFileTitle={(files: UploadedFile[]) => {
              dispatch(setPropertyDocuments(values(files)));
            }}
            onChange={handleChange}
            onDelete={(files: UploadedFile[]) => {
              dispatch(setPropertyDocuments(values(files)));
            }}
            searchInput={searchInput}
            uploadedFiles={propertyDocuments}
            isDisabled={isDisabled}
          />
        ),
        icon: File,
        Badge: (
          <TotalCount content={propertyDocuments?.length?.toString() ?? '0'} />
        ),
      },
      {
        _id: 'upload_images',
        title: t('upload-image.header'),
        expandedContent: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
            }}
          >
            <MotivationBanner
              title={t('motivation-banner.image.title')}
              description={t('motivation-banner.image.description')}
            />
            <InputFile
              title={t('upload-image.title')}
              description={t('upload-image.description')}
              accept={buildAcceptTypes(imageTypes)}
              multiple
              section={'Photos'}
              onEditFileTitle={(files: UploadedFile[]) => {
                dispatch(setPropertyPhotos(values(files)));
              }}
              onChange={handleChange}
              onDelete={(files: UploadedFile[]) => {
                dispatch(setPropertyPhotos(values(files)));
              }}
              searchInput={searchInput}
              uploadedFiles={propertyPhotos}
              isDisabled={isDisabled}
            />
          </div>
        ),
        icon: Picture,
        Badge: (
          <TotalCount content={propertyPhotos?.length?.toString() ?? '0'} />
        ),
      },
      {
        _id: 'upload_property_plans',
        title: t('upload-property-plan.header'),
        expandedContent: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
            }}
          >
            <MotivationBanner
              title={t('motivation-banner.property-plan.title')}
              description={t('motivation-banner.property-plan.description')}
            />
            <InputFile
              title={t('upload-property-plan.title')}
              description={t('upload-property-plan.description')}
              accept={buildAcceptTypes(propertyPlanTypes)}
              multiple
              section={'Plans'}
              onEditFileTitle={(files: UploadedFile[]) => {
                dispatch(setPropertyPlans(values(files)));
              }}
              onChange={handleChange}
              onDelete={(files: UploadedFile[]) => {
                dispatch(setPropertyPlans(values(files)));
              }}
              searchInput={searchInput}
              uploadedFiles={propertyPlans}
              isDisabled={isDisabled}
            />
          </div>
        ),
        icon: Plan,
        Badge: (
          <TotalCount content={propertyPlans?.length?.toString() ?? '0'} />
        ),
      },
    ],
    [
      isDisabled,
      t,
      handleChange,
      searchInput,
      propertyDocuments?.length,
      propertyPhotos?.length,
      propertyPlans?.length,
      dispatch,
    ]
  );

  return (
    <Container>
      {sections.map((section) => {
        const isAccordionOpen = accordionsState?.find(
          (accordion) => accordion.id === section._id
        )?.isOpen;
        return (
          <Accordion
            key={section._id}
            id={section._id}
            accordionSection={section}
            isOpen={isAccordionOpen}
            onChange={onCollapseAccordion}
          />
        );
      })}
    </Container>
  );
};

const FileUploader = memo(FileUploaderBase);

export { FileUploader };
