import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAttributesFromMeta } from '../../utils/get-attributes-from-meta';
import { locales } from '../../../localization/constants';
import { siteMap } from '../../../../routes/site-map';
import { useReadOverviewLandingPageQuery } from '../../../../generated';
import { OverviewLandingPageResponse } from '../../interface';
import useGetLocalization from '../../../localization/get-localization';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';

const HelmetContainerBase: FC = () => {
  const { t } = useTranslation();
  const lang = useGetLocalization();

  const { data: overviewLPData, isLoading: isLandingPageLoading } =
    useReadOverviewLandingPageQuery<OverviewLandingPageResponse>({
      locale: lang,
    });

  if (isLandingPageLoading) {
    return <GlobalLoader />;
  }

  if (!overviewLPData) {
    return <Redirect to={siteMap.OwnerLandingPage.path} />;
  }

  const {
    readOverviewLandingPage: {
      metaDescription,
      metaKeywords,
      metaTitle,
      imageUrl,
    },
  } = overviewLPData;

  return (
    <Helmet>
      <title>{metaTitle || t('buyer-portal.title')}</title>
      <meta name="title" content={metaTitle ?? ''} />
      <meta name="description" content={metaDescription ?? ''} />
      <meta property="og:title" content={metaTitle ?? ''} />
      <meta property="og:description" content={metaDescription ?? ''} />
      {imageUrl && <meta property="og:image" content={String(imageUrl)} />}
      <meta property="twitter:title" content={metaTitle ?? ''} />
      <meta property="twitter:description" content={metaDescription ?? ''} />
      {imageUrl && <meta property="twitter:image" content={String(imageUrl)} />}
      {metaKeywords?.map((item) => {
        const metaObject = getAttributesFromMeta(item);
        return (
          <meta
            key={metaObject.name}
            name={metaObject.name}
            content={metaObject.content}
          />
        );
      })}
      {locales.map(({ hrefLang, locale }) => (
        <link
          key={siteMap.OverviewLandingPage.path}
          rel="alternate"
          href={`https://home.von-poll.com/${locale}${siteMap.OverviewLandingPage.path}`}
          hrefLang={hrefLang}
        />
      ))}
      <link
        rel="canonical"
        href={`https://home.von-poll.com/${lang}${siteMap.OverviewLandingPage.path}`}
      />
    </Helmet>
  );
};

const HelmetContainer = memo(HelmetContainerBase);

export { HelmetContainer };
