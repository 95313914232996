import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { Maybe } from 'graphql/jsutils/Maybe';

import { isNumber } from 'lodash';
import { Table, TdYear } from './table-personal-financing-example-styles';
import Icon from '../../../../../../../../common/components/ui/icon';
import { TableTd } from '../table-form-data/table-form-data-styles';
import MockData from '../../../../../../../../../assets/images/icons/financing-form/info-container/mock-personal-financing.png';
import MockDataSollzins from '../../../../../../../../../assets/images/icons/financing-form/info-container/mock-personal-financing-sollzins.png';
import MockDataEffektiver from '../../../../../../../../../assets/images/icons/financing-form/info-container/mock-personal-financing-effektiver.png';
import { useIsMobileSize } from '../../../../../../../../common/hooks/useIsMobileSize';

interface IProps {
  years: number;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  fixedBorrowingRate?: Maybe<number> | undefined | null;
  effectiveInterestRate?: Maybe<number> | undefined | null;
  monthlyRate?: string;
}

const TablePfExampleItem: FC<IProps> = ({
  years,
  icon,
  fixedBorrowingRate,
  effectiveInterestRate,
  monthlyRate,
}) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const isMobileSize = useIsMobileSize();

  return (
    <Table>
      <tbody>
        {isMobileSize && (
          <tr>
            <TdYear>
              <div>
                <Icon
                  icon={icon}
                  width={20}
                  height={20}
                  color={themeContext.blue}
                />
                <h5>
                  {years}{' '}
                  {t('financing-portal.form.info-side.financing-example.years')}
                </h5>
                <p>
                  {t(
                    'financing-portal.form.info-side.financing-example.debit-rate-commitment'
                  )}
                </p>
              </div>
            </TdYear>
          </tr>
        )}
        <tr>
          {!isMobileSize && (
            <TdYear>
              <div>
                <Icon
                  icon={icon}
                  width={20}
                  height={20}
                  color={themeContext.blue}
                />
                <h5>
                  {years}{' '}
                  {t('financing-portal.form.info-side.financing-example.years')}
                </h5>
                <p>
                  {t(
                    'financing-portal.form.info-side.financing-example.debit-rate-commitment'
                  )}
                </p>
              </div>
            </TdYear>
          )}
          <td>
            <table>
              <tbody>
                <tr>
                  <TableTd isBold>
                    {t(
                      'financing-portal.form.info-side.financing-example.fixed-borrowing-rate'
                    )}
                    :
                  </TableTd>
                  <TableTd>
                    {isNumber(fixedBorrowingRate) ? (
                      `${fixedBorrowingRate}% p.a.`
                    ) : (
                      <img src={MockDataSollzins} alt="mock data" />
                    )}
                  </TableTd>
                </tr>
                <tr>
                  <TableTd isBold>
                    {t(
                      'financing-portal.form.info-side.financing-example.effective-interest-rate'
                    )}
                    :
                  </TableTd>
                  <TableTd>
                    {isNumber(effectiveInterestRate) ? (
                      `${effectiveInterestRate}% p.a.`
                    ) : (
                      <img src={MockDataEffektiver} alt="mock data" />
                    )}
                  </TableTd>
                </tr>
                <tr>
                  <TableTd isBold>
                    {t(
                      'financing-portal.form.info-side.financing-example.monthly-rate'
                    )}
                    :
                  </TableTd>
                  <TableTd>
                    {monthlyRate ? (
                      `${monthlyRate} €`
                    ) : (
                      <img src={MockData} alt="mock data" />
                    )}
                  </TableTd>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export { TablePfExampleItem };
