import { useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';

import {
  changeMapViewport,
  removeAllMapMarkersExceptHome,
  setMapSettings,
  addMapMarkers,
} from '../../../redux/exposeSlice';
import {
  Poi,
  PoiCategory,
  PoiSubCategory,
  PropertyLocation,
} from '../../../../../../../generated';
import { IMapPin } from '../../../../../../map/interfaces';
import { PropertyPoiWithIndex } from '../../../../../../common/components/infrastructure/infrastructure';
import { useAppSelector } from '../../../../../../common/hooks';

interface IProps {
  location?: PropertyLocation;
  propertyPois?: PropertyPoiWithIndex;
  isSeller?: boolean;
}

interface IReturn {
  currentMapboxMarkers: IMapPin[];
}

const useMapInfrastructure = ({
  location,
  propertyPois,
  isSeller,
}: IProps): IReturn => {
  const dispatch = useDispatch();
  const currentMapboxMarkers = useAppSelector(
    (state) =>
      state[isSeller ? 'sellerExposeDetails' : 'exposeDetails'].map.markers
  );

  const [areMarkersSetInStore, setMarkersSetInStore] = useState(false);
  const { transport, ...pois } = propertyPois || {};

  useEffect(() => {
    if (location) {
      batch(() => {
        dispatch(
          changeMapViewport({
            ...location?.coordinates,
            bearing: -80,
            pitch: 65,
            zoom: 14,
            transitionDuration: 0,
          })
        );
        dispatch(
          setMapSettings({
            dragPan: true,
            dragRotate: true,
            scrollZoom: true,
            touchZoom: true,
            touchRotate: true,
            keyboard: true,
            doubleClickZoom: true,
          })
        );
      });
    }
    return () => {
      batch(() => {
        dispatch(removeAllMapMarkersExceptHome());
      });
    };
  }, [dispatch, location]);

  useEffect(() => {
    if (pois) {
      Object.keys(pois).forEach((categoryKey) => {
        const content = pois[categoryKey] as unknown as Record<string, unknown>;
        if (!areMarkersSetInStore) {
          const markers: IMapPin[] = [];

          Object.keys(content || {}).forEach((key: string) => {
            if (content[key]) {
              (content[key] as Array<Poi>).slice(0, 5).forEach((poi) => {
                if (poi?.location?.coordinates) {
                  const newMarker = {
                    ...poi.location.coordinates,
                  } as IMapPin & {
                    marker: PoiCategory;
                    category: PoiSubCategory;
                  };
                  newMarker.marker = poi.category as PoiCategory;
                  newMarker.category = poi.subcategory as PoiSubCategory;
                  if (newMarker.latitude && newMarker.longitude) {
                    markers.push(newMarker);
                  }
                }
              });
            }
          });

          dispatch(
            addMapMarkers([
              ...currentMapboxMarkers.filter(
                (current) =>
                  current.category !== 'property' &&
                  current.marker !== 'transport'
              ),
              ...markers,
            ])
          );
          // Did mount flag
          setMarkersSetInStore(true);
        }
      });
    }
  }, [areMarkersSetInStore, currentMapboxMarkers, dispatch, pois]);

  return { currentMapboxMarkers };
};

export { useMapInfrastructure };
