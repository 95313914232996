import { FC, useEffect, useMemo } from 'react';
import { ModalForm } from '../../../../components/fullscreen-modal/modal-form/modal-form';
import { useUsefulSearchProfileContainerData } from '../../../hooks/useGetSearchProfileFormInfo';
import { useSubmitFormSP } from '../../../hooks/useSubmitFormSP';
import { AuthFormStep } from '../../forms/search-profile-form/steps/auth-form-step/auth-form-step';
import { FirstStep } from '../../forms/search-profile-form/steps/first-step/first-step';
import { SecondStep } from '../../forms/search-profile-form/steps/second-step/second-step';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import {
  nextStepAction,
  setRedirect,
  togglePreventCleanupOnInit,
} from '../../../redux/searchProfileSlice';
import { useFormContext } from 'react-hook-form';
import { fields as firstStepFields } from 'modules/forms/form-search-profile/components/forms/search-profile-form/steps/first-step/constants';
import { fields as secondStepFields } from 'modules/forms/form-search-profile/components/forms/search-profile-form/steps/second-step/constants';

const SearchProfileForm: FC<{
  onSubmitSPForm: ReturnType<typeof useSubmitFormSP>['onSubmitSPForm'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialSuggestedPropertiesData: any;
}> = ({ onSubmitSPForm, initialSuggestedPropertiesData }) => {
  const { searchProfileErrors, activeStep, isLoading, isSPCOpen } =
    useUsefulSearchProfileContainerData();

  const dispatch = useAppDispatch();
  const { isLoggedIn, preventCleanupOnInit } = useAppSelector((state) => ({
    isLoggedIn: !!state.auth.user?._id,
    preventCleanupOnInit: state.searchProfile.preventCleanupOnInit,
  }));
  const { reset: resetForm } = useFormContext();
  useEffect(() => {
    if (!preventCleanupOnInit) {
      const nextState = Object.fromEntries(
        [...firstStepFields, ...secondStepFields].map((key) => [key, null])
      );

      resetForm({ ...nextState, radius: 1 });

      dispatch(togglePreventCleanupOnInit(false));
    }
  }, []);

  const isMobileSize = useIsMobileSize();

  const title = useMemo(() => {
    switch (activeStep) {
      case 0:
        return 'search-profile.form.first-step.full-screen-title';
      case 1:
        return 'search-profile.form.second-step.title';
      default:
        return '';
    }
  }, [activeStep]);

  const formStep = useMemo(() => {
    switch (activeStep) {
      case 0:
        return <FirstStep />;
      case 1:
        return (
          <SecondStep
            onSubmit={async (formData, ownerId) => {
              let redirected = false;

              const searchProfileData = await onSubmitSPForm(
                formData,
                ownerId,
                Boolean(ownerId),
                () => {
                  resetForm();
                  redirected = true;
                }
              );

              if (!redirected) dispatch(nextStepAction());
            }}
            submitForm
            isRedesign
          />
        );
      case 2:
        return (
          <AuthFormStep
            onAuthSuccess={() => {
              dispatch(setRedirect(true));
            }}
            initialSuggestedPropertiesData={initialSuggestedPropertiesData}
          />
        );
      default:
        return <FirstStep />;
    }
  }, [activeStep, isLoggedIn, onSubmitSPForm]);

  return (
    <ModalForm
      isHeadline={
        (activeStep === 0 || activeStep === 1) && !isLoading && !isMobileSize
      }
      activeStep={activeStep}
      isModalOpen={isSPCOpen}
      formStep={formStep}
      title={isLoading && activeStep < 2 ? '' : title}
      header={'search-profile.form.header'}
      errors={searchProfileErrors}
    />
  );
};

export { SearchProfileForm };
