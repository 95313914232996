import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

import { Comparable } from '../../../../generated';
import { defaultTheme } from '../../../../style/theme';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { formatNumber } from '../../../property/utils/format-number';
import { BathroomIcon, LivingSpaceIcon } from '../icons';

const styles = StyleSheet.create({
  wrapper: {
    width: '48%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px',
    borderColor: '#d9d9d9',
    borderRadius: '1mm',
    marginBottom: '3mm',
    maxHeight: '100mm',
  },
  imageWrapper: {
    width: '100%',
    height: 'auto',
    flex: '0 0 40%',
  },
  image: {
    objectFit: 'cover',
  },
  infoWrapper: {
    flex: '0 0 60%',
    padding: '3mm',
    paddingBottom: 0,
  },
  headerTextContainer: {
    maxWidth: '90%',
    marginBottom: '2mm',
  },
  headerText: {
    fontSize: '12px',
    fontWeight: 'semibold',
    lineHeight: 1.33,
    letterSpacing: '0.5px',
    color: defaultTheme.blue,
  },
  pillsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2mm',
  },
  pill: {
    border: '1px solid #d9d9d9',
    padding: '1mm 3mm',
    marginRight: '3mm',
    alignItems: 'center',
    borderRadius: '1mm',
  },
  pillsFont: {
    fontFamily: 'Roboto',
    fontSize: '8px',
    fontWeight: 'bold',
    lineHeight: 1.2,
    letterSpacing: '0.5px',
    textAlign: 'right',
    color: defaultTheme.blue,
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 'bold',
    lineHeight: 1.4,
    color: defaultTheme.blue,
    marginLeft: '2mm',
  },
  text: {
    marginTop: '2mm',
    opacity: 0.6,
    fontFamily: 'Roboto',
    fontSize: '8px',
    fontWeight: 900,
    lineHeight: 1.5,
    letterSpacing: '0.5px',
    color: defaultTheme.blue,
  },
});

const SimilarPropertyCard = ({
  similarProperty,
}: {
  similarProperty: Comparable;
}): JSX.Element => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.imageWrapper}>
        <Image
          style={styles.image}
          src={{
            uri: `https://cors.bridged.cc/${
              similarProperty?.images[0]?.url ?? ''
            }`,
            method: 'GET',
            headers: {},
            body: '',
          }}
        />
      </View>
      <View style={styles.infoWrapper}>
        <View style={styles.headerTextContainer}>
          <Text style={styles.headerText}>
            {(similarProperty?.title?.length ?? 0) > 35
              ? `${similarProperty?.title?.slice(0, 35)}...`
              : similarProperty.title}
          </Text>
        </View>
        <View style={styles.pillsWrapper}>
          <View
            style={{
              ...styles.pill,
              borderColor: similarProperty.isActive ? '#d5e3cc' : '#c9d5fc',
              backgroundColor: similarProperty.isActive ? '#f1f5ee' : '#f2f5f7',
            }}
          >
            <Text
              style={{
                ...styles.pillsFont,
                color: similarProperty.isActive
                  ? defaultTheme.green
                  : defaultTheme.blue,
              }}
            >
              {similarProperty.isActive ? 'AKTIV' : 'HISTORISCH'}
            </Text>
          </View>
          <View
            style={{
              ...styles.pill,
              borderColor: '#d9d9d9',
              backgroundColor: '#fff',
            }}
          >
            <Text
              style={{
                ...styles.pillsFont,
                color: defaultTheme.blue,
                opacity: 0.5,
              }}
            >
              {similarProperty.daysOnMarket ?? 0}
              {(similarProperty.daysOnMarket ?? -1) > 1 ? ' TAGE' : ' TAG'} AUF
              DEM MARKT
            </Text>
          </View>
          {/* <View></View> */}
        </View>
        <View
          style={{
            marginTop: '6px',
            backgroundColor: hexToRGB(defaultTheme.blue, 0.1),
            padding: '4px 6px',
            borderRadius: '1mm',
            maxWidth: '85%',
          }}
        >
          <Text
            style={{
              fontFamily: 'Source Serif Pro',
              fontSize: '12px',
              fontWeight: 'bold',
              lineHeight: 1.33,
              letterSpacing: '0.5px',
              color: defaultTheme.blue,
            }}
          >
            {formatNumber(similarProperty.salePrice)} €{' '}
            <Text
              style={{
                fontFamily: 'Source Serif Pro',
                fontSize: '12px',
                fontWeight: 'normal',
                lineHeight: 1.33,
                letterSpacing: '0.5px',
                color: defaultTheme.blue,
              }}
            >
              ({' '}
              {formatNumber(
                Math.trunc(
                  (similarProperty?.salePrice ?? 1) /
                    (similarProperty?.livingArea ?? 1)
                )
              )}{' '}
              €/m²)
            </Text>
          </Text>
        </View>
        <View
          style={{
            marginTop: '4mm',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <BathroomIcon />
              <Text style={styles.label}>
                {similarProperty?.numberOfRooms ?? 0}
              </Text>
            </View>
            <View>
              <Text style={styles.text}>Zimmer</Text>
            </View>
          </View>
          <View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <LivingSpaceIcon />
              <Text style={styles.label}>
                {similarProperty?.livingArea ?? 0} m²
              </Text>
            </View>
            <View>
              <Text style={styles.text}>Nettowohnfläche</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export { SimilarPropertyCard };
