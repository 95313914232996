import { useAppSelector } from '../../../common/hooks';

const useFinancingCalculation = () => {
  const isLoading = useAppSelector(
    (state) => state.financing.editWidget.isLoadingFinancingCalculation
  );
  const userInput = useAppSelector(
    (state) => state.financing.editWidget.userInputFinancingCalculation
  );
  return { isLoading, userInput };
};

export { useFinancingCalculation };
