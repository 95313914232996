import { FC } from 'react';
import { Container } from './box-item-styles';

interface IProps {
  text: string;
  isSelected: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  height?: number;
  width?: number;
}

const BoxItem: FC<IProps> = ({
  text,
  isSelected,
  isDisabled,
  onClick,
  height,
  width,
}) => {
  return (
    <Container
      isSelected={isSelected}
      isDisabled={isDisabled}
      onClick={!isDisabled ? onClick : undefined}
      height={height}
      width={width}
    >
      {text}
    </Container>
  );
};

export { BoxItem };
