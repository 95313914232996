import { useCallback } from 'react';

import { useAppSelector } from '../../../common/hooks';
import {
  CreateSearchProfileInput,
  CreateTemporarySearchProfileInput,
  CreateTemporarySearchProfileMutation,
  useCreateTemporarySearchProfileMutation,
} from '../../../../generated';

interface ICreateTemporarySP {
  spInput: CreateTemporarySearchProfileInput;
  ownerId?: string;
}

interface IReturn {
  onCreateTemporarySP: (
    input: ICreateTemporarySP
  ) => Promise<CreateTemporarySearchProfileMutation>;
}

const useCreateTemporarySP = (): IReturn => {
  const marketingParametersBase = useAppSelector(
    (state) => state.auth.marketingParameters
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const marketingParameters = isIframe
    ? `${marketingParametersBase}de/merkliste`
    : marketingParametersBase;

  const [createTemporarySearchProfile] =
    useCreateTemporarySearchProfileMutation();

  const onCreateTemporarySP = useCallback(
    async ({ spInput, ownerId }: ICreateTemporarySP) => {
      return createTemporarySearchProfile({
        input: {
          ...spInput,
          owner: ownerId,
          marketingParameters:
            marketingParameters ??
            `${window?.location?.pathname || ''}${
              window?.location?.search || ''
            }`,
        },
      }).unwrap();
    },
    [createTemporarySearchProfile, marketingParameters]
  );
  return { onCreateTemporarySP };
};

export { useCreateTemporarySP };
