import styled, { css, ThemeContext } from 'styled-components';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { memo, useContext, useMemo } from 'react';

const Container = styled.div<{
  $currentMin: number;
  $currentMax: number;
  $min: number;
  $max: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 16px;
  & {
    .rc-slider {
      height: 8px;
      border-radius: 6px;
      border: solid 1px #d9d9d9;
      background-color: #fff;
      padding: 0;
    }
    .rc-slider-step {
      height: 100%;
    }
    .rc-slider-rail {
      height: 100%;
      background: transparent;
    }
    .rc-slider-handle {
      width: 24px;
      height: 12px;
      border-radius: 3px;
      border: none;
      margin-top: -3px;
      background: ${({ theme }) => theme.blue};
      box-shadow: none !important;
      &::before {
        position: absolute;
        bottom: 100%;
        width: 100%;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.5px;
        color: ${({ theme }) => theme.blue};
      }
    }
    .rc-slider-handle-1 {
      ${({ $currentMin, $min }) =>
        $currentMin === $min &&
        css`
          transform: translateX(0) !important;
        `}
      &::before {
        content: ${({ $currentMin }) => `"${$currentMin}"`};
      }
    }
    .rc-slider-handle-2 {
      ${({ $currentMax, $max }) =>
        $currentMax === $max &&
        css`
          transform: translateX(-100%) !important;
        `}
      &::before {
        content: ${({ $currentMax }) => `"${$currentMax}"`};
      }
    }
    .rc-slider-track {
      background: transparent !important;
      height: 100%;
    }
  }
`;

interface IMultiRangeSliderInput {
  min: number;
  max: number;
  values: number[];
  onChange: (values: number[]) => void;
}

const MultiRangeSliderInputBase = ({
  min,
  max,
  values,
  onChange,
}: IMultiRangeSliderInput): JSX.Element => {
  const themeContext = useContext(ThemeContext);

  const handleStyles = useMemo(() => {
    return {
      width: '24px',
      height: '12px',
      borderRadius: '3px',
      border: 'none',
      marginTop: '-3px',
      background: themeContext.blue,
      boxShadow: 'none !important',
    };
  }, [themeContext]);

  return (
    <Container
      $currentMin={values[0]}
      $currentMax={values[1]}
      $min={min}
      $max={max}
    >
      <Range
        value={values}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        handleStyle={handleStyles}
        min={min}
        max={max}
        onChange={onChange}
      />
    </Container>
  );
};

const MultiRangeSliderInput = memo(MultiRangeSliderInputBase);

export { MultiRangeSliderInput };
