import { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { ReactComponent as UserIdea } from 'assets/streamline-light/work-office-companies/ideas-creativity/user-idea.svg';

import { Container, IdeaContainer } from './feasibility-rating-styles';
import { FinancingSkeleton as Skeleton } from '../../../../../financing-skeleton/financing-skeleton';
import { CircleProgress } from '../../../../../../../forms/form-financing/components/containers/financing-containers/financing-info-container/circle-progress/circle-progress';
import Icon from '../../../../../../../common/components/ui/icon';

const ratingStates = [
  {
    description: 'financing-portal.rating.1',
    color: '#DC3545',
  },
  {
    description: 'financing-portal.rating.2',
    color: '#DC3545',
  },
  {
    description: 'financing-portal.rating.3',
    color: '#EDB304',
  },
  {
    description: 'financing-portal.rating.4',
    color: '#28A745',
  },
];

interface IProps {
  isLoading?: boolean;
  value: number;
}

const FeasibilityRatingBase: FC<IProps> = ({ isLoading, value }) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  let rating = ratingStates[3];
  if (value >= 30 && value <= 50) {
    // eslint-disable-next-line prefer-destructuring
    rating = ratingStates[2];
  } else if (value < 30 && value > 0) {
    // eslint-disable-next-line prefer-destructuring
    rating = ratingStates[1];
  } else if (value === 0) {
    // eslint-disable-next-line prefer-destructuring
    rating = ratingStates[0];
  }

  return (
    <Container>
      <div style={{ position: 'relative' }}>
        {value === 0 && !isLoading && (
          <IdeaContainer>
            <Icon icon={UserIdea} color={theme.blue} width={32} height={32} />
          </IdeaContainer>
        )}
        <CircleProgress
          circleValue={value}
          percent={isLoading ? 0 : value}
          size={64}
          color={rating.color}
          isLoading={isLoading}
          isPortalPage
          isHideZero
        />
      </div>
      <div>
        <h3>{t('financing.feasibility-rating.label')}:</h3>
        <Skeleton isLoading={isLoading} mt={15} width="100%">
          <p>{t(rating.description)}</p>
        </Skeleton>
      </div>
    </Container>
  );
};

const FeasibilityRating = memo(FeasibilityRatingBase);

export { FeasibilityRating };
