export const percentageDifference = (
  a: number,
  b: number,
  withSign?: boolean
): number => {
  const decreaseValue = a - b;
  const difference = Number(Math.abs((decreaseValue / a) * 100).toFixed(2));
  if (withSign) {
    return a > b ? difference * -1 : difference;
  }

  return difference;
};
