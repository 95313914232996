import { ChangeEvent, FC, useCallback } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { ReactComponent as InformationCircle } from 'assets/streamline-light/interface-essential/alerts/information-circle.svg';
import { device } from '../../../../style/theme';
import { Tooltip } from '../../../common/components/tooltip';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { addCommas } from "../../utils/number-format";

type Constrain = {
  value: number;
  message?: string;
};

interface IProps {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  label: string;
  hasMeasurement?: boolean;
  hasInfo?: boolean;
  defaultValue?: number | string;
  rules: RegisterOptions;
  tooltipKey?: string;
  hasMarginTop?: boolean;
  rentSymbol?: string;
  inBounds?: boolean;
  rounded?: boolean;
  withCommas?: boolean;
}

const Container = styled.div<{ hasMarginTop?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  ${(props) => props.hasMarginTop && 'margin-top: 12px'};
  &:not(:first-of-type) {
    margin-top: 12px;
    @media ${device.tablet} {
      margin-top: 15px;
    }
  }
  @media ${device.tablet} {
    ${(props) => props.hasMarginTop && 'margin-top: 15px'};
  }
`;

const TooltipContainer = styled.div`
  max-width: 316px;
  width: 100%;
  padding: 0;
  text-align: center;
`;

const TooltipParagraph = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

const Row = styled.div<{ hasErrors?: boolean, rounded?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  flex: 1;
  max-height: ${({ hasErrors }) => (hasErrors ? '64px' : `40px`)};
  &:not(:first-of-type) {
    margin-top: 8px;
    ${props => props.rounded && `
      margin-top: 10px;
    `}
    @media ${device.tablet} {
      margin-top: 15px;
    }
  }
`;

const Label = styled.label`
  order: 1;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
`;

const Measurement = styled.div<{ rounded?: boolean }>`
  position: absolute;
  right: 12px;
  top: 12px;
  ${props => props.rounded && `
    top: 14px;
  `}
  width: 16px;
  height: 16px;

  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: right;
  color: ${(props) => hexToRGB(props.theme.blue, 0.5)};

  line-height: 1;
`;

const InputField = styled.input<{ hasError?: boolean }>`
  order: 2;
  flex: 0;
  height: 40px;
  min-width: 128px;
  max-width: 128px;
  padding: 12px 40px 12px 12px;
  border-radius: 3px;
  background-color: ${(props) =>
    props.hasError ? props.theme.redBackground : props.theme.greyBackground};

  outline: none;
  border: 1px solid transparent;

  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) =>
    props.hasError &&
    `
      & + label{
        color: ${hexToRGB(props.theme.red, 0.5)};
      }
  `}
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
  &::placeholder {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  }
  &:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.borderFocus};
    background-color: ${(props) => props.theme.greyBackground};
    & + label {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    }
  }
  &:active,
  &:focus {
    cursor: text;
    background-color: #fff;
    border-color: ${(props) => props.theme.borderFocus};
    color: ${(props) => props.theme.blue};
    & + label {
      color: ${(props) => props.theme.blue};
    }
    &::placeholder {
      color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
    }
  }
`;

const SliderInputBase = styled.input<{ rounded?: boolean }>`
  margin: 0;
  padding: 0;
  height: 12px;
  width: 100%;
  background: transparent;
  font: 12px arial, sans-serif;

  &::-webkit-slider-runnable-track {
    box-sizing: border-box;
    height: 8px;
    border-radius: 6px;
    background: transparent;
    border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
    @media ${device.tablet} {
      height: 12px;
    }

    ${props => props.rounded && `
      height: 12px;
    `}
  }

  &::-moz-range-track {
    box-sizing: border-box;
    height: 8px;
    border-radius: 6px;
    background: transparent;
    border: solid 1px ${(props) => props.theme.ctaDisabledBackground};

    ${props => props.rounded && `
      height: 12px;
    `}

    @media ${device.tablet} {
      height: 12px;
    }
  }

  &::-webkit-slider-thumb {
    margin-top: -3px;
    box-sizing: border-box;
    border: none;
    width: 24px;
    height: 12px;
    background: white;
    border-radius: 3px;
    background-color: ${(props) => props.theme.blue};
    cursor: pointer;

    ${props => props.rounded && `
      height: 16px;
      width: 16px;
      border-radius: 100%;
    `}

    @media ${device.tablet} {
      width: 32px;
      height: 24px;
      transform: translateY(-4px);

      ${props => props.rounded && `
        height: 16px;
        width: 16px;
        border-radius: 100%;
        transform: translateY(0);
      `}
    }
  }

  &::-moz-range-thumb {
    margin-top: -3px;
    box-sizing: border-box;
    border: none;
    width: 24px;
    height: 12px;
    background: white;
    border-radius: 3px;
    background-color: ${(props) => props.theme.blue};
    cursor: pointer;

    ${props => props.rounded && `
      height: 16px;
      width: 16px;
      border-radius: 100%;
    `}

    @media ${device.tablet} {
      width: 32px;
      height: 24px;
      transform: translateY(-4px);

      ${props => props.rounded && `
        height: 16px;
        width: 16px;
        border-radius: 100%;
        transform: translateY(0);
      `}
    }
  }

  &,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
  }
`;

const Info = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  .icon {
    > svg,
    > svg > path {
      stroke: ${(props) => props.theme.blue};
    }
  }
`;

const ErrorContainer = styled.div`
  order: 3;
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`;

const ErrorMessage = styled.p`
  flex: 2.125;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.red};
  text-align: right;
`;

const SliderInput: FC<IProps> = ({
  name,
  label,
  hasMeasurement,
  hasInfo,
  defaultValue,
  rules,
  tooltipKey,
  hasMarginTop,
  rentSymbol,
  inBounds,
  rounded,
  withCommas,
}) => {
  const { t } = useTranslation();

  const {
    field: { ref, onChange, ...inputProps },
    formState: { errors },
  } = useController({
    name,
    rules,
    defaultValue: rules.value ? rules.value : (withCommas ? addCommas(defaultValue) : defaultValue),
  });

  const { min, max } = rules;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if(withCommas){
        const val = e.target.value.replace(/\./g, '');
        if(/^\d*$/.test(val)) {
          onChange(addCommas(val))
        }
      } else {
        onChange(
          Number.isNaN(e.target.value) || Number.isNaN(e.target.valueAsNumber)
            ? e.target.value
            : e.target.valueAsNumber
        );
      }
    },
    [onChange, withCommas]
  );

  const handleOnBlur = () => {
    if(withCommas){
      const val = inputProps.value.replace(/\./g, '');
      if(inBounds && (val < (min as Constrain).value || val > (max as Constrain).value)) {
        onChange(addCommas(defaultValue))
      }
    } else if(inBounds && (inputProps.value < (min as Constrain).value || inputProps.value > (max as Constrain).value)) {
        onChange(defaultValue)
    }
  }

  const getCommaStrippedValue = (): number => {
    if(inputProps && inputProps.value) {
      return Number.parseInt(inputProps.value.replace(/\./g, ''), 10)
    }
    return Number(defaultValue);
  }

  return (
    <Container hasMarginTop={hasMarginTop}>
      <Row hasErrors={!!errors[name]}>
        <InputField
          type={withCommas ? 'text' : 'number'}
          ref={ref}
          hasError={errors[name]}
          onChange={handleChange}
          {...inputProps}
          onBlur={inBounds ? handleOnBlur : inputProps.onBlur}
        />
        <Label>{label}</Label>
        {hasMeasurement && (
          <Measurement>
            m<sup>2</sup>
          </Measurement>
        )}
        {rentSymbol && <Measurement rounded={rounded}>{rentSymbol.toUpperCase()}</Measurement>}
        {hasInfo && tooltipKey && (
          <Info>
            <Tooltip
              icon={InformationCircle}
              id={tooltipKey}
              position="bottom"
              width={16}
              height={16}
            >
              <TooltipContainer>
                <TooltipParagraph>{t(tooltipKey)}</TooltipParagraph>
              </TooltipContainer>
            </Tooltip>
          </Info>
        )}
        {errors[name] && (
          <ErrorContainer>
            <ErrorMessage>{t(errors[name].message)}</ErrorMessage>
          </ErrorContainer>
        )}
      </Row>
      <Row rounded={rounded}>
        <SliderInputBase
          min={(min as Constrain).value}
          max={(max as Constrain).value}
          type="range"
          ref={withCommas ? undefined : ref}
          onChange={handleChange}
          rounded={rounded}
          {...inputProps}
          value={withCommas ? getCommaStrippedValue() : inputProps.value}
        />
      </Row>
    </Container>
  );
};

export { SliderInput };
