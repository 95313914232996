import styled from 'styled-components';
import { FC, memo, useCallback, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from '../../common/components/ui/icon';
import { Headline3 } from '../../common/components/ui/typography';
import { SecondaryButton } from '../../common/components/ui/buttons';
import { ReactComponent as LoginKey } from '../../../assets/streamline-light/interface-essential/login-logout/login-key.svg';
import {
  toggleIsLoginModalOpen,
  toggleIsRegisterModalOpen,
} from '../redux/authSlice';

const LoginContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 12px;
  border-radius: 3px;
  border: solid 1px #d9d9d9;
`;

const LoginKeyWrapper = styled(Icon)`
  margin-right: 12px;

  path {
    stroke: ${(props) => props.theme.blue} !important;
  }
`;

const LoginText = styled(Headline3)`
  font-size: 16px;
`;

const LoginButton = styled(SecondaryButton)`
  margin-left: auto;
  padding: 4px 8px;
  color: ${(props) => props.theme.blue};
  border-color: ${(props) => props.theme.blue};
  text-transform: uppercase;
  font-size: 10px;
`;

interface IProps {
  handleClick?: (ev: MouseEvent<HTMLButtonElement>, ...args: any[]) => void;
}

const LoginOpen: FC<IProps> = ({ handleClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLoginForm = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(toggleIsRegisterModalOpen(false));
      dispatch(toggleIsLoginModalOpen(true));
    },
    [dispatch]
  );

  return (
    <LoginContainer>
      <LoginKeyWrapper icon={LoginKey} width={16} height={16} />
      <LoginText content={t('register.form.login-block.headline')} />
      <LoginButton
        type="button"
        onClick={handleClick || handleLoginForm}
        label={t('modal.login.submit')}
      />
    </LoginContainer>
  );
};

export default LoginOpen;
