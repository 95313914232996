import { ReactComponent as ConstructionBrick } from 'assets/streamline-light/building-construction/construction/construction-brick.svg';
import { ReactComponent as BathroomTubTowel } from 'assets/streamline-light/hotels-spas/bathroom/bathroom-tub-towel.svg';
import { ReactComponent as ArchitectureWindow1 } from 'assets/streamline-light/building-construction/architectural-features/architecture-window-1.svg';
import { ReactComponent as MaterialStone } from 'assets/streamline-light/tools-constructions/materials/material-stone.svg';
import { ReactComponent as KitchenStorage } from 'assets/streamline-light/furnitures-decoration/kitchen/kitchen-storage.svg';
import { Condition, Quality } from '../../../generated';

export const CONDITION_ELEMENTS = [
  {
    label: 'Kuche',
    value: 'kitchen',
    icon: KitchenStorage,
  },
  {
    label: 'Badezimmer',
    value: 'bathrooms',
    icon: BathroomTubTowel,
  },
  {
    label: 'Fussboden',
    value: 'flooring',
    icon: MaterialStone,
  },
  {
    label: 'Fenster',
    value: 'windows',
    icon: ArchitectureWindow1,
  },
  {
    label: 'Mauerwerk',
    value: 'masonry',
    icon: ConstructionBrick,
  },
];

export const QUALITY_VALUES = [
  {
    label: Quality.Simple,
    value: 1,
  },
  {
    label: Quality.Normal,
    value: 2,
  },
  {
    label: Quality.HighQuality,
    value: 3,
  },
  {
    label: Quality.Luxury,
    value: 4,
  },
];

export const CONDITION_VALUES = [
  {
    label: Condition.RenovationNeeded,
    value: 1,
  },
  {
    label: Condition.WellMaintained,
    value: 2,
  },
  {
    label: Condition.NewOrRecentlyRenovated,
    value: 3,
  },
];
