import { useEffect } from 'react';

import { useUpdateFinancingPropositionsMutation } from '../../../../../services/graphql/enhanced';
import { UpdateFinancingPropositionsInput } from '../../../../../generated';
import { useAppDispatch } from '../../../../common/hooks';
import { toggleUpdatingOverdueFinancingPropositions } from '../../redux/financingSlice';

const useUpdateFinancingPropositions = () => {
  const dispatch = useAppDispatch();
  const [updateFinancingPropositionsMutation, { data, error, isLoading }] =
    useUpdateFinancingPropositionsMutation();

  const updateFinancingPropositions = async (
    financingInput: UpdateFinancingPropositionsInput
  ): Promise<boolean> => {
    const isUpdated = await updateFinancingPropositionsMutation({
      input: financingInput,
    }).unwrap();

    return !!isUpdated;
  };

  useEffect(() => {
    dispatch(toggleUpdatingOverdueFinancingPropositions(isLoading));
  }, [isLoading]);

  return {
    data,
    error,
    isLoading,
    updateFinancingPropositions,
  };
};

export { useUpdateFinancingPropositions };
