import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IFinancingForm } from '../../../../../../interface';
import { PurchasePriceAndAdditionalCosts } from '../kauf-fields/purchase-price-and-additional-costs/purchase-price-and-additional-costs';
import { Separator } from '../../separator/separator';
import { InputWrapper } from '../../financing-data-edit-form-styles';
import { CalculateValue } from '../../calculate-value/calculate-value';
import { InputV2 } from '../../../../../../../components/common/input-v2/input-v2';
import { FIELDS, FINANCING_FORM_KEYS } from '../../../../../../constants';
import { numberFormat } from '../../../../../../../../financing/utils/numberFormat';

interface IProps {
  finData: IFinancingForm;
  landTax: number;
  isWithBroker: boolean;
  setWithBroker: (state: boolean) => void;
  totalTax: number;
  onChangeTax: (name: string, cb?: () => void) => (value: number) => void;
  setDirtyTrue(): void;
}

const OwnConstructionFields: FC<IProps> = ({
  finData,
  totalTax,
  onChangeTax,
  landTax,
  isWithBroker,
  setWithBroker,
  setDirtyTrue,
}) => {
  const { t } = useTranslation();
  const [landPrice, equityCapital, buildingCosts] = useWatch({
    name: [
      FINANCING_FORM_KEYS.LAND_PRICE,
      FINANCING_FORM_KEYS.EQUITY_CAPITAL,
      FINANCING_FORM_KEYS.BUILDING_COSTS,
    ],
  });
  const totalCostOfProperty = numberFormat(
    totalTax + Number(landPrice ?? 0) + Number(buildingCosts ?? 0)
  );

  const loanAmount =
    totalTax +
    Number(landPrice ?? 0) +
    Number(buildingCosts ?? 0) -
    Number(equityCapital ?? 0);
  const loanAmountString = numberFormat(loanAmount);

  return (
    <>
      <PurchasePriceAndAdditionalCosts
        isWithBroker={isWithBroker}
        setWithBroker={setWithBroker}
        finData={finData}
        totalTax={totalTax}
        onChangeTax={onChangeTax}
        setDirtyTrue={setDirtyTrue}
        landTax={landTax}
        isOwnConstruction
      />

      <Separator />
      <InputWrapper content="=" mb={12}>
        <CalculateValue
          tooltipWidth={310}
          label="financing-portal-page.tabs.calculator.edit.inputs.total-cost-of-property.label"
          value={`${totalCostOfProperty} €`}
          tooltipKey="financing-portal-page.tabs.calculator.edit.inputs.total-cost-of-property.tooltip"
        />
      </InputWrapper>
      <InputWrapper content="-">
        <InputV2
          name="equityCapital"
          type="number"
          label="financing-portal-page.tabs.calculator.edit.inputs.equity-capital.label"
          placeholder="financing-portal-page.tabs.calculator.edit.inputs.equity-capital.placeholder"
          inputAdornment="€"
          defaultValue={finData?.equityCapital}
          isValidateOnBlur
          handleChangeProp={() => {
            setDirtyTrue();
          }}
          isSeparate
          isLabelSaturated
          isOldDesign
          tooltipKey="financing-portal-page.tabs.calculator.edit.inputs.equity-capital.tooltip"
          tooltipWidth={220}
          isTooltipKeyLeftSide
          isBoldTooltipKeyIcon
          validateNames={
            landPrice && buildingCosts
              ? [
                  FINANCING_FORM_KEYS.BUILDING_COSTS,
                  FINANCING_FORM_KEYS.LAND_PRICE,
                  FINANCING_FORM_KEYS.EQUITY_CAPITAL,
                ]
              : undefined
          }
          rules={{
            ...FIELDS.EQUITY_CAPITAL.rules,
            min: {
              value: landPrice * 0.1,
              message: t(
                'financing-portal.form.inputs.equity-capital.errors.min-value',
                { value: numberFormat(landPrice * 0.1) }
              ),
            },
            validate: (value: number) => {
              return Number(value) > Number(landPrice) + Number(buildingCosts)
                ? t(
                    'financing-portal.form.inputs.equity-capital.errors.building-land-price-value'
                  ).toString()
                : true;
            },
          }}
        />
      </InputWrapper>
      <InputWrapper content="=" minHeight="40px">
        <CalculateValue
          tooltipWidth={280}
          label="financing-portal-page.tabs.calculator.edit.inputs.loan-amount.label"
          value={`${loanAmountString} €`}
          tooltipKey="financing-portal-page.tabs.calculator.edit.inputs.loan-amount.tooltip"
          isError={loanAmount < 0}
        />
      </InputWrapper>
    </>
  );
};

export { OwnConstructionFields };
