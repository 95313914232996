import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useController, useWatch } from 'react-hook-form';
import { isNumber, isString } from 'lodash';

import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { BoxType } from '../../interface';
import {
  PropertyCode,
  RatingReason,
  ValuationType,
} from '../../../../generated';
import { ReactComponent as Buildings2 } from '../../../../assets/streamline-light/building-construction/buildings/buildings-2.svg';
import { ReactComponent as House } from '../../../../assets/streamline-light/building-construction/houses/house-3.svg';
import { ReactComponent as HouseApartment } from '../../../../assets/streamline-light/building-construction/houses/house-apartment.svg';
import {
  apartmentBoxes,
  FIELDS,
  houseBoxes,
  VALUATION_FORM_KEYS,
} from '../constants';
import { useNextHandler } from './callback-hooks/useNextHandler';
import { IValuationForm, WidgetPropertyType } from '../interface';
import { useOpenEvaluationWizardV2 } from './callback-hooks/useOpenEvaluationWizardV2';
import { useGetPortalType } from '../../../landing/hooks/useGetPortalType';
import { IIframeType } from '../../../auth/interfaces';
import {
  nextStepAction as nextStepActionV2,
  setIframePropertyType,
  updateFormStateAction as updateFormStateActionV2,
} from '../redux/valuationWizardV2Slice';

type TGPCReturn = {
  isApartment: boolean;
  isHouse: boolean;
  isMultiFamilyHouse: boolean;
};

export const useIframeWidgetPropertyType = () => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const isIframeApartment =
    urlParams.get('wohnung') === 'true' ? WidgetPropertyType.APARTMENT : null;
  const isIframeHouse =
    urlParams.get('haus') === 'true' ? WidgetPropertyType.HOUSE : null;
  const isIframeMultiFamilyHouse =
    urlParams.get('mehrfamilienhaus') === 'true'
      ? WidgetPropertyType.MULTI_FAMILY_HOUSE
      : null;

  const iframeType =
    isIframeApartment || isIframeHouse || isIframeMultiFamilyHouse;

  useEffect(() => {
    dispatch(setIframePropertyType(iframeType));
  }, []);
};

export const useRegionalCode = () => {
  const userInput = useAppSelector(
    (state) => state.valuationWizardV2.userInput
  );
  const isRegionalPage = useAppSelector(
    (state) => state.valuationWizardV2.isRegionalPage
  );
  const {
    field: { onChange },
  } = useController({
    name: VALUATION_FORM_KEYS.CODE,
  });

  useEffect(() => {
    if (isRegionalPage) {
      onChange(userInput?.code);
    }
    // onChange provokes unbounded rendering
  }, [isRegionalPage, userInput?.code]);
};

export const useGetPropertyCode = (): TGPCReturn => {
  const code = useWatch({
    name: VALUATION_FORM_KEYS.CODE,
  });

  const isApartment = useMemo(() => code === PropertyCode.Apartment, [code]);
  const isHouse = useMemo(() => code === PropertyCode.House, [code]);
  const isMultiFamilyHouse = useMemo(
    () => code === PropertyCode.MultiFamilyHouse,
    [code]
  );

  return { isApartment, isHouse, isMultiFamilyHouse };
};

export const useFirstStepLogic = () => {
  const dispatch = useDispatch();
  const {
    selectedCountryCode,
    isRegionalPage,
    valuationType,
    iframePropertyType,
  } = useAppSelector((state) => state.valuationWizardV2);
  const { isIframe, iframeType } = useAppSelector((state) => state.auth);
  const isNoModal = iframeType === IIframeType.WIDGET_OWNER_NO_MODAL;
  const isCentered = iframeType === IIframeType.WIDGET_OWNER_CENTERED;

  const { openEvaluationWizardV2 } = useOpenEvaluationWizardV2({});
  const { onClickNext } = useNextHandler({
    watchedKeys: [VALUATION_FORM_KEYS.CODE],
  });

  const name = VALUATION_FORM_KEYS.CODE;
  const {
    field: { onChange },
  } = useController({
    name,
  });
  useIframeWidgetPropertyType();

  const onClick = () => {
    if (isRegionalPage && !isNoModal) {
      openEvaluationWizardV2(valuationType || ValuationType.Online);
    }
    if (
      isIframe &&
      document.body.requestFullscreen &&
      !isNoModal &&
      !isCentered
    ) {
      document.body.requestFullscreen();
    }
    onClickNext();
  };

  useEffect(() => {
    if (iframePropertyType) {
      switch (iframePropertyType) {
        case WidgetPropertyType.APARTMENT: {
          dispatch(updateFormStateActionV2({ code: PropertyCode.Apartment }));
          onChange(PropertyCode.Apartment);
          dispatch(nextStepActionV2(1));
          break;
        }
        case WidgetPropertyType.HOUSE: {
          dispatch(updateFormStateActionV2({ code: PropertyCode.House }));
          onChange(PropertyCode.House);
          dispatch(nextStepActionV2(1));
          break;
        }
        case WidgetPropertyType.MULTI_FAMILY_HOUSE: {
          dispatch(
            updateFormStateActionV2({ code: PropertyCode.MultiFamilyHouse })
          );
          onChange(PropertyCode.MultiFamilyHouse);
          dispatch(nextStepActionV2(2));
          break;
        }
        default:
          break;
      }
    }
  }, [dispatch, iframePropertyType]);

  const codeBoxes = useMemo(
    () => [
      {
        id: 'etw',
        boxType: BoxType.iconBox,
        title: 'property-valuation.form.redesign.building.apartment',
        value: PropertyCode.Apartment,
        icon: Buildings2,
      },
      {
        id: 'efh',
        boxType: BoxType.iconBox,
        title: 'property-valuation.form.redesign.building.house',
        value: PropertyCode.House,
        icon: House,
      },
      {
        id: 'mfh',
        boxType: BoxType.iconBox,
        title: 'property-valuation.form.redesign.building.family-house',
        value: PropertyCode.MultiFamilyHouse,
        icon: HouseApartment,
        isDisabled: selectedCountryCode === 'AT',
      },
    ],
    [selectedCountryCode]
  );

  return { name, codeBoxes, onClick, isIframe };
};

export const useSecondStepLogic = () => {
  const { isRegionalPage, valuationType, iframePropertyType } = useAppSelector(
    (state) => state.valuationWizardV2
  );
  const { isIframe, iframeType } = useAppSelector((state) => state.auth);

  const isNoModal = iframeType === IIframeType.WIDGET_OWNER_NO_MODAL;
  const isCentered = iframeType === IIframeType.WIDGET_OWNER_CENTERED;

  const { onClickNext } = useNextHandler({
    watchedKeys: [VALUATION_FORM_KEYS.SUBCODE],
  });
  const isBackButton = !iframePropertyType;

  const code = useWatch({
    name: VALUATION_FORM_KEYS.CODE,
  });
  const codeInput = useAppSelector(
    (state) => state.valuationWizardV2.userInput?.code
  );
  const { openEvaluationWizardV2 } = useOpenEvaluationWizardV2({});

  const onClick = () => {
    if (isRegionalPage && !isNoModal) {
      openEvaluationWizardV2(valuationType || ValuationType.Online);
    }
    if (
      isIframe &&
      document.body.requestFullscreen &&
      !isNoModal &&
      !isCentered
    ) {
      document.body.requestFullscreen();
    }
    onClickNext();
  };

  const isApartment = useMemo(
    () =>
      code === PropertyCode.Apartment || codeInput === PropertyCode.Apartment,
    [code, codeInput]
  );

  useRegionalCode();

  return {
    isBackButton,
    iframePropertyType,
    boxes: isApartment ? apartmentBoxes : houseBoxes,
    onClickNext: onClick,
  };
};

export const useThirdStepLogic = () => {
  const dispatch = useAppDispatch();
  const { isRegionalPage, valuationType, iframePropertyType } = useAppSelector(
    (state) => state.valuationWizardV2
  );
  const { isIframe, iframeType } = useAppSelector((state) => state.auth);
  const isNoModal = iframeType === IIframeType.WIDGET_OWNER_NO_MODAL;
  const isCentered = iframeType === IIframeType.WIDGET_OWNER_CENTERED;

  const isIframePropertyMultiFamilyHouse =
    iframePropertyType === WidgetPropertyType.MULTI_FAMILY_HOUSE;
  const { openEvaluationWizardV2 } = useOpenEvaluationWizardV2({});

  useRegionalCode();
  const [code, buildingYear, renovationYear] = useWatch({
    name: [
      VALUATION_FORM_KEYS.CODE,
      VALUATION_FORM_KEYS.BUILDING_YEAR,
      VALUATION_FORM_KEYS.RENOVATION_YEAR,
    ],
  });

  const isMultiFamilyHouse = useMemo(
    () => code === PropertyCode.MultiFamilyHouse,
    [code]
  );
  const isShowRenovationYear = useMemo(
    () => !isMultiFamilyHouse,
    [isMultiFamilyHouse]
  );

  const isRenovationYearLower = useMemo(
    () =>
      isShowRenovationYear &&
      !!buildingYear &&
      !!renovationYear &&
      renovationYear <= buildingYear,
    [isShowRenovationYear, buildingYear, renovationYear]
  );

  const watchedKeys = useMemo(
    () => [
      VALUATION_FORM_KEYS.STREET,
      VALUATION_FORM_KEYS.NUMBER,
      VALUATION_FORM_KEYS.CITY,
      VALUATION_FORM_KEYS.BUILDING_YEAR,
      ...(!isMultiFamilyHouse &&
      typeof renovationYear !== 'undefined' &&
      !Number.isNaN(renovationYear) &&
      renovationYear !== ''
        ? [VALUATION_FORM_KEYS.RENOVATION_YEAR]
        : []),
    ],
    [isMultiFamilyHouse, renovationYear]
  );

  const inputs = useMemo(
    () => [
      FIELDS.CITY,
      FIELDS.BUILDING_YEAR,
      ...(isMultiFamilyHouse ? [] : [FIELDS.RENOVATION_YEAR]),
    ],
    [isMultiFamilyHouse]
  );

  const outerDisable = isRenovationYearLower;

  const onSubmit = () => {
    if (isIframePropertyMultiFamilyHouse) {
      if (isRegionalPage && !isNoModal) {
        openEvaluationWizardV2(valuationType || ValuationType.Online);
      }
      if (
        isIframe &&
        document.body.requestFullscreen &&
        !isNoModal &&
        !isCentered
      ) {
        document.body.requestFullscreen();
      }
    }
    dispatch(nextStepActionV2());
  };

  return {
    onSubmit,
    isIframePropertyMultiFamilyHouse,
    watchedKeys,
    iframePropertyType,
    inputs,
    outerDisable,
    isRenovationYearLower,
  };
};

export const useFourthStepLogic = () => {
  const dispatch = useAppDispatch();
  const selectedCountryCode = useAppSelector(
    (state) => state.valuationWizardV2.selectedCountryCode
  );
  const [
    code,
    floorNumber,
    numberOfFloorsInBuilding,
    landArea,
    annualRentIncome,
    energyEfficiency,
  ] = useWatch({
    name: [
      VALUATION_FORM_KEYS.CODE,
      VALUATION_FORM_KEYS.FLOOR_NUMBER,
      VALUATION_FORM_KEYS.NUMBER_OF_FLOORS_IN_BUILDING,
      VALUATION_FORM_KEYS.LAND_AREA,
      VALUATION_FORM_KEYS.ANNUAL_RENT_INCOME,
      VALUATION_FORM_KEYS.ENERGY_LABEL,
    ],
  });

  const { isApartment, isHouse, isMultiFamilyHouse } = useGetPropertyCode();
  const isSwitzerland = useMemo(
    () => selectedCountryCode === 'CH',
    [selectedCountryCode]
  );
  const isAustria = useMemo(
    () => selectedCountryCode === 'AT',
    [selectedCountryCode]
  );

  const isNumberOfFloorsLower = useMemo(
    () =>
      isApartment &&
      !Number.isNaN(floorNumber) &&
      !Number.isNaN(numberOfFloorsInBuilding) &&
      numberOfFloorsInBuilding < floorNumber,
    [isApartment, floorNumber, numberOfFloorsInBuilding]
  );

  const inputs = useMemo(() => {
    if (isSwitzerland) {
      switch (code) {
        case PropertyCode.Apartment:
          return [
            FIELDS.LIVING_AREA_SWITZERLAND,
            FIELDS.NUMBER_OF_FLOORS_IN_BUILDING,
            FIELDS.FLOOR_NUMBER,
            FIELDS.ENERGY_LABEL_SWITZERLAND,
          ];
        case PropertyCode.House:
          return [
            FIELDS.LIVING_AREA_SWITZERLAND,
            FIELDS.LAND_AREA,
            FIELDS.NUMBER_OF_FLOORS_IN_BUILDING,
            FIELDS.ENERGY_LABEL_SWITZERLAND,
          ];
        case PropertyCode.MultiFamilyHouse:
          return [
            FIELDS.NUMBER_OF_UNITS,
            FIELDS.LIVING_AREA_MULTI_FAMILY_HOUSE_SWITZERLAND,
            FIELDS.LAND_AREA_MULTI_FAMILY_HOUSE,
            FIELDS.ANNUAL_RENT_INCOME,
            FIELDS.ENERGY_LABEL_SWITZERLAND,
          ];
        default:
          return [];
      }
    } else {
      switch (code) {
        case PropertyCode.Apartment:
          return [
            FIELDS.LIVING_AREA,
            FIELDS.NUMBER_OF_FLOORS_IN_BUILDING,
            FIELDS.FLOOR_NUMBER,
            ...(isAustria ? [] : [FIELDS.ENERGY_LABEL]),
          ];
        case PropertyCode.House:
          return [
            FIELDS.LIVING_AREA,
            FIELDS.LAND_AREA,
            FIELDS.NUMBER_OF_FLOORS_IN_BUILDING,

            ...(isAustria ? [] : [FIELDS.ENERGY_LABEL]),
          ];
        case PropertyCode.MultiFamilyHouse:
          return [
            FIELDS.NUMBER_OF_UNITS,
            FIELDS.LIVING_AREA_MULTI_FAMILY_HOUSE,
            FIELDS.LAND_AREA_MULTI_FAMILY_HOUSE,
            FIELDS.ANNUAL_RENT_INCOME,

            ...(isAustria ? [] : [FIELDS.ENERGY_LABEL]),
          ];
        default:
          return [];
      }
    }
  }, [isSwitzerland, isAustria, code]);

  const watchedKeys = useMemo(
    () => [
      ...(isApartment
        ? [
            VALUATION_FORM_KEYS.LIVING_AREA,
            ...(typeof floorNumber !== 'undefined' && !Number.isNaN(floorNumber)
              ? [VALUATION_FORM_KEYS.FLOOR_NUMBER]
              : []),
            ...(typeof numberOfFloorsInBuilding !== 'undefined' &&
            !Number.isNaN(numberOfFloorsInBuilding)
              ? [VALUATION_FORM_KEYS.NUMBER_OF_FLOORS_IN_BUILDING]
              : []),
            ...(isString(energyEfficiency)
              ? [VALUATION_FORM_KEYS.ENERGY_LABEL]
              : []),
          ]
        : []),
      ...(isHouse
        ? [
            VALUATION_FORM_KEYS.LIVING_AREA,
            VALUATION_FORM_KEYS.LAND_AREA,
            ...(typeof numberOfFloorsInBuilding !== 'undefined' &&
            !Number.isNaN(numberOfFloorsInBuilding)
              ? [VALUATION_FORM_KEYS.NUMBER_OF_FLOORS_IN_BUILDING]
              : []),
            ...(isString(energyEfficiency)
              ? [VALUATION_FORM_KEYS.ENERGY_LABEL]
              : []),
          ]
        : []),
      ...(isMultiFamilyHouse
        ? [
            VALUATION_FORM_KEYS.NUMBER_OF_UNITS,
            VALUATION_FORM_KEYS.LIVING_AREA,
            ...(typeof landArea !== 'undefined' && !Number.isNaN(landArea)
              ? [VALUATION_FORM_KEYS.LAND_AREA]
              : []),
            ...(typeof annualRentIncome !== 'undefined' &&
            !Number.isNaN(annualRentIncome)
              ? [VALUATION_FORM_KEYS.ANNUAL_RENT_INCOME]
              : []),
            ...(isString(energyEfficiency)
              ? [VALUATION_FORM_KEYS.ENERGY_LABEL]
              : []),
          ]
        : []),
    ],
    [
      annualRentIncome,
      energyEfficiency,
      floorNumber,
      isApartment,
      isHouse,
      isMultiFamilyHouse,
      landArea,
      numberOfFloorsInBuilding,
    ]
  );

  const onSubmit = () => {
    if (!isMultiFamilyHouse) {
      dispatch(nextStepActionV2());
    } else {
      dispatch(nextStepActionV2(5));
    }
  };

  return {
    onSubmit,
    watchedKeys,
    inputs,
    dynamicAdornment: numberOfFloorsInBuilding,
    outerDisable: isNumberOfFloorsLower,
  };
};

export const useFifThStepLogic = () => {
  const { isHouse } = useGetPropertyCode();
  const [
    numberOfBathrooms,
    balconyArea,
    numberOfIndoorParkingSpaces,
    numberOfOutdoorParkingSpaces,
  ] = useWatch({
    name: [
      VALUATION_FORM_KEYS.NUMBER_OF_BATHROOMS,
      VALUATION_FORM_KEYS.BALCONY_AREA,
      VALUATION_FORM_KEYS.NUMBER_OF_INDOOR_PARKING_SPACES,
      VALUATION_FORM_KEYS.NUMBER_OF_OUTDOOR_PARKING_SPACES,
    ],
  });

  const inputs = [
    FIELDS.NUMBER_OF_ROOMS,
    FIELDS.NUMBER_OF_BATHROOMS,
    FIELDS.BALCONY_AREA,
    FIELDS.NUMBER_OF_INDOOR_PARKING_SPACES,
    FIELDS.NUMBER_OF_OUTDOOR_PARKING_SPACES,
    ...(isHouse ? [FIELDS.IS_NEW, FIELDS.HAS_POOL, FIELDS.HAS_SAUNA] : []),
  ];

  const watchedKeys = [
    VALUATION_FORM_KEYS.NUMBER_OF_ROOMS,
    ...(isNumber(numberOfBathrooms)
      ? [VALUATION_FORM_KEYS.NUMBER_OF_BATHROOMS]
      : []),
    ...(isNumber(balconyArea) ? [VALUATION_FORM_KEYS.BALCONY_AREA] : []),
    ...(isNumber(numberOfIndoorParkingSpaces)
      ? [VALUATION_FORM_KEYS.NUMBER_OF_INDOOR_PARKING_SPACES]
      : []),
    ...(isNumber(numberOfOutdoorParkingSpaces)
      ? [VALUATION_FORM_KEYS.NUMBER_OF_OUTDOOR_PARKING_SPACES]
      : []),
    ...(isHouse
      ? [
          VALUATION_FORM_KEYS.IS_NEW,
          VALUATION_FORM_KEYS.HAS_POOL,
          VALUATION_FORM_KEYS.HAS_SAUNA,
        ]
      : []),
  ];

  return {
    inputs,
    watchedKeys,
  };
};

export const useSixthStepLogic = () => {
  const { onClickNext } = useNextHandler({
    watchedKeys: [VALUATION_FORM_KEYS.QUALITY],
  });
  const inputs = [{ ...FIELDS.QUALITY, onClick: onClickNext }];

  return { inputs };
};

export const useSeventhStepLogic = () => {
  const { onClickNext } = useNextHandler({
    watchedKeys: [VALUATION_FORM_KEYS.CONDITION],
  });
  const inputs = [{ ...FIELDS.CONDITION, onClick: onClickNext }];

  return { inputs };
};

type TEighthStepProps = {
  onSubmit?: (formData: IValuationForm, ownerId?: string) => Promise<void>;
  lastStepCallback?(): void;
};

export const useEighthStepLogic = ({
  onSubmit,
  lastStepCallback,
}: TEighthStepProps) => {
  const userInput = useAppSelector(
    (state) => state.valuationWizardV2.userInput
  );
  const ownerId = useAppSelector((state) => state.auth.user?._id);

  const formData = userInput as IValuationForm;
  const { reset } = useFormContext();

  const { onClickNext } = useNextHandler({
    watchedKeys: [
      VALUATION_FORM_KEYS.RATING_REASON,
      VALUATION_FORM_KEYS.OTHER_RATING_REASON,
      VALUATION_FORM_KEYS.TIME_HORIZONT,
    ],
    buttonCallback: async (input: Record<string, unknown>) => {
      if (onSubmit) {
        await onSubmit({ ...formData, ...input }, ownerId);
        setTimeout(() => {
          reset();
        }, 300);
      }
      if (lastStepCallback) {
        lastStepCallback();
      }
    },
  });
  const { isSeller } = useGetPortalType();
  const ratingReason = useWatch({ name: 'ratingReason' });

  const isDisplayTimeHorizont =
    ratingReason && ratingReason !== RatingReason.EstimatedResult;
  const isShowInput = ratingReason && ratingReason === RatingReason.Other;

  const handleSubmit = useCallback(() => {
    onClickNext();
  }, [onClickNext]);

  const boxes = isSeller
    ? FIELDS.RATING_REASON.boxes?.filter(
        (ratingReasonBox) => ratingReasonBox.value !== RatingReason.Buy
      )
    : FIELDS.RATING_REASON.boxes;

  const inputs = [
    {
      ...FIELDS.RATING_REASON,
      boxes,
      onClick: handleSubmit,
    },
    ...(isShowInput ? [FIELDS.OTHER_RATING_REASON] : []),
    ...(isDisplayTimeHorizont
      ? [
          {
            ...FIELDS.TIME_HORIZONT,
            onClick: handleSubmit,
          },
        ]
      : []),
  ];
  // const watchedKeys = [
  //   VALUATION_FORM_KEYS.RATING_REASON,
  // ...(isShowInput ? [VALUATION_FORM_KEYS.OTHER_RATING_REASON] : []),
  //   ...(isDisplayTimeHorizont ? [VALUATION_FORM_KEYS.TIME_HORIZONT] : []),
  // ];

  return { inputs };
};
