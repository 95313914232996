import { FC, Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { ReactComponent as Calendar } from 'assets/streamline-light/interface-essential/date-calendar/calendar.svg';

import {
  Container,
  PeriodsContainer,
  TitleContainer,
} from './modal-content-styles';
import Icon from '../../../../../common/components/ui/icon';
import { BoxItem } from '../../../../../forms/components/common/box-selector-line/box-item/box-item';
import { VariantsContainer } from './variants-container/variants-container';
import { DataContainer } from './data-container/data-container';

const boxes = [
  { value: '5', text: '5' },
  { value: '10', text: '10' },
  { value: '15', text: '15' },
  { value: '20', text: '20' },
  { value: '25', text: '25' },
  { value: '30', text: '30' },
];

const ModalContent: FC = () => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const [period, setPeriod] = useState('10');

  return (
    <Container>
      <TitleContainer>
        <Icon
          icon={Calendar}
          width={20}
          height={18}
          color={themeContext.blue}
        />
        <h3>
          {t('financing-portal-page.variants-compare.modal.period.title')}
        </h3>
      </TitleContainer>
      <PeriodsContainer>
        {boxes.map((box) => (
          <Fragment key={box.value}>
            <BoxItem
              text={box.text}
              isSelected={box.value === period}
              isDisabled={box.value === '30'}
              onClick={() => setPeriod(box.value)}
              width={37}
              height={37}
            />
          </Fragment>
        ))}
      </PeriodsContainer>
      <VariantsContainer />
      <DataContainer period={period} />
    </Container>
  );
};

export { ModalContent };
