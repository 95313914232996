import { interpolateRgb } from 'd3-interpolate';

const green = '#45a34d';
const yellow = '#fffa3e';
const orange = '#eb6f23';
const red = '#cf381a';

export const getColorEnergyEfficiency = (score: number): { color: string } => {
  let value = 1;
  let rightColor = green;
  let leftColor = green;

  if (score < 100 && score >= 65) {
    rightColor = red;
    leftColor = orange;
    value = (100 / (100 - 65)) * (score - 65);
  }

  if (score < 65 && score >= 31) {
    rightColor = orange;
    leftColor = yellow;
    value = (100 / (65 - 31)) * (score - 31);
  }

  if (score < 31 && score >= 0) {
    rightColor = yellow;
    leftColor = green;
    value = (100 / 31) * score;
  }
  value /= 100;

  return {
    color: interpolateRgb(leftColor, rightColor)(value),
  };
};
