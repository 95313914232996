import { FC, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import { device } from '../../../../../style/theme';
import { AuthHeader } from '../../../../auth/components/auth-header';
import { Logo } from './logo';
import { Navigation } from './navigation';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { LinkOption } from './navigation-types';
import { toggleHeaderAuthOpen } from '../../../../auth/redux/authSlice';

const Container = styled.div<{ isScrolling?: boolean }>`
  @media ${device.tablet} {
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2009;
  position: sticky;
  background: white;
  ${(props) =>
    props.isScrolling &&
    'background: linear-gradient(90.57deg, #002447 4.48%, #00366B 48.29%);'}
  height: 81px;
  padding: 20px 0;
  top: -1px;
  margin-top: -1px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const BoxHolder = styled.div`
  width: 396px;
`;

interface IProps {
  hasAuthHeader?: boolean;
}

type AuthOpen = 'user' | 'star' | '';

const Header: FC<IProps> = ({ hasAuthHeader = true }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { headerAuthOpen: authOpen, isBrokerView } = useAppSelector(
    (state) => state.auth
  );
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  const [activeMenu, setActiveMenu] = useState<LinkOption>('');
  const setAuthOpen = (state: AuthOpen) => {
    dispatch(toggleHeaderAuthOpen(state));
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    const scrollHandler = (): void => {
      setIsScrolling(window.scrollY !== 0);
    };

    document.addEventListener('scroll', scrollHandler);

    return () => {
      return document.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <Container isScrolling={isScrolling}>
      <Content>
        <Logo />
        <Navigation
          activeMenu={activeMenu}
          deactivateAuthMenu={() => {
            setAuthOpen('');
            setIsDropdownOpen(false);
          }}
          setActiveMenu={setActiveMenu}
          isScrolling={isScrolling}
        />
        {hasAuthHeader && !isBrokerView ? (
          <AuthHeader
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            authOpen={authOpen}
            setAuthOpen={setAuthOpen}
            deactivateMenu={() => setActiveMenu('')}
            isScrolling={isScrolling}
          />
        ) : (
          <BoxHolder />
        )}
      </Content>
    </Container>
  );
};

export { Header };
