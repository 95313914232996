import { FC, useEffect } from 'react';
import {
  BuyerLpSection,
  useLazyReadVirtualToursQuery,
  VirtualTourSection,
} from '../../../../../generated';
import {
  VirtualToursGrid,
  Header,
  Background,
  VirtualToursContainer,
} from './virtual-tour-styles';
import { VirtualTourCard } from './virtual-tour-card';
import { VirtualTourSlider } from './slider/virtual-tour-slider';

interface IProps {
  section: BuyerLpSection;
  isSlider?: boolean;
}

const VirtualTours: FC<IProps> = ({ section, isSlider }) => {
  const [getAppointmentInfo, { data, isLoading }] =
    useLazyReadVirtualToursQuery();

  useEffect(() => {
    if (!isLoading && !data) {
      getAppointmentInfo({
        filter: {
          _ids: section.recordIds.length > 0 ? section.recordIds : [''],
        },
      });
    }
  }, [data, isLoading]);

  if (isLoading) return null;

  const sections = data?.data as VirtualTourSection[];

  return (
    <VirtualToursContainer>
      <Background />
      {isSlider ? (
        <VirtualTourSlider
          sections={sections}
          sliderHeader={''}
          headline={section.title}
        />
      ) : (
        <>
          <Header content={section.title} />
          <VirtualToursGrid>
            {sections?.map((item) => (
              <VirtualTourCard key={item._id} section={item} />
            ))}
          </VirtualToursGrid>
        </>
      )}
    </VirtualToursContainer>
  );
};

export { VirtualTours };
