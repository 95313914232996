import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { ThemeContext } from 'styled-components';
import { isEmpty } from 'lodash';
import { ReactComponent as RemoveUser } from 'assets/streamline-light/users/natural-close-up-single-user-actions-neutral/single-neutral-actions-remove.svg';

import { useDispatch } from 'react-redux';
import { UserStatus } from '../../../../generated';
import {
  Container,
  Content,
  LoadMoreButton,
  NewOfferBadge,
  Statistics,
  ScrollWrapper,
} from './offers-statistics-card-styles';
import { Accordion } from '../accordion/accordion';
import { Offer } from './new-offer/new-offer';
import {
  Item,
  ItemLabel,
  ItemValue,
} from '../general-statistics-card/general-statistics-card-styles';
import { OffersStatisticsItem } from '../../interfaces';
import EmptyStatePlaceholder from '../../../common/components/empty-state-placeholder';
import { useAppSelector } from '../../../common/hooks';
import {
  mergeMarketingStatisticsFilter,
  clearMarketingStatisticsFilter,
} from '../../redux/sellerPropertiesSlice';
import { buildGeneralStatistics } from '../../utils/buildGeneralStatistics';
import { useStatisticsFilter } from '../../hooks/useStatisticsFilter';
import { FilterHeader } from './filter/filter-header';
import { FilterAccordion } from './filter/filter-accordion';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { AcceptOfferModal } from './accept-offer-modal/accept-offer-modal';
import { useCurrency } from '../../../property/hooks/useCurrency';
import { formatPrice } from '../../utils/formatPrice';
import { simulationHighestBidPrice } from '../selling-simulation/dummy-data';

interface IProps {
  statistics?: OffersStatisticsItem[];
  isBlurred?: boolean;
  isSimulation?: boolean;
  currentValuation?: number | null;
  showFilter?: boolean | null;
  showRequestedExposes?: boolean;
  showUnlockedExposes?: boolean;
  showViewingAppointments?: boolean;
  userStatus?: UserStatus | null;
}

const OffersStatisticsCardBase: FC<IProps> = ({
  statistics,
  isBlurred,
  isSimulation,
  currentValuation,
  showFilter,
  showRequestedExposes,
  showUnlockedExposes,
  showViewingAppointments,
  userStatus,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const priceCurrency = useCurrency();

  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [searchInputDebounced, setSearchInputDebounced] = useState<string>('');
  const today = useRef<string>(new Date().toISOString());
  const isMobileSize = useIsMobileSize();

  const filters = useAppSelector(
    (state) => state.sellerProperties.marketingStatisticsFilter
  );

  const accordionsInitialState = useMemo(() => {
    const statisticsAccordions =
      statistics?.map((item, index) => ({
        id: item._id,
        isOpen: index === 0,
        isScrolledToBottom: false,
      })) ?? [];
    return [
      ...statisticsAccordions,
      { id: 'filter', isOpen: true, isScrolledToBottom: false },
    ];
  }, [statistics]);

  const [accordionsState, setAccordionsState] = useState<
    { id: string; isOpen: boolean; isScrolledToBottom: boolean }[] | undefined
  >(accordionsInitialState);
  const [offset, setOffset] = useState<number>(8);

  const isAccordionsOpen = useRef<boolean>(true);

  const onSearchInputChangeDebounced = useDebouncedCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchInputDebounced(event.target.value);
    },
    1000
  );

  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value);
      onSearchInputChangeDebounced(event);
    },
    [onSearchInputChangeDebounced]
  );

  const onClearSearch = useCallback(() => {
    setSearchInput('');
    setSearchInputDebounced('');
  }, []);

  const onCollapseSingleAccordion = useCallback((id: string) => {
    setAccordionsState((prevState) => {
      const sid = prevState?.find((accordion) => accordion.id === id);
      return prevState?.map((accordion) =>
        accordion === sid
          ? { ...accordion, isOpen: !accordion.isOpen }
          : accordion
      );
    });
  }, []);

  const onLastOfferVisible = useCallback(
    (accordionId: string, isVisible: boolean) => {
      setAccordionsState((prevState) => {
        const sid = prevState?.find(
          (accordion) => accordion.id === accordionId
        );
        return prevState?.map((accordion) =>
          accordion === sid
            ? { ...accordion, isScrolledToBottom: isVisible }
            : accordion
        );
      });
    },
    []
  );

  const onCollapseAllAccordions = useCallback(() => {
    isAccordionsOpen.current = !isAccordionsOpen.current;
    setAccordionsState((prevState) =>
      prevState?.map((accordion) => ({
        ...accordion,
        isOpen: isAccordionsOpen.current,
      }))
    );
  }, []);

  const loadMore = useCallback(() => {
    setOffset((prevState) => prevState + 8);
  }, []);

  const onApplyFilters = useCallback(() => {
    setAccordionsState((prevState) => {
      return prevState?.map((accordion) =>
        accordion.id === 'filter' && !accordion.isOpen
          ? { ...accordion, isOpen: true }
          : accordion
      );
    });
    setIsFilterActive(true);
    setMessage('');
  }, []);

  const onResetFilters = useCallback(() => {
    dispatch(clearMarketingStatisticsFilter());
    setIsFilterActive(false);
    setMessage('');
  }, [dispatch]);

  useEffect(() => {
    return () => {
      onResetFilters();
    };
  }, []);

  const {
    filteredOffers,
    filteredGeneralStatistics,
    isFilteredStatisticsEmpty,
    isStatisticsLoading,
    isStatisticsFetching,
    statisticsError,
    hasHighestBid,
  } = useStatisticsFilter(statistics, searchInputDebounced);

  useEffect(() => {
    if (isFilterActive && isFilteredStatisticsEmpty) {
      const dateFrom = filters.dateFrom
        ? new Date(filters.dateFrom)?.toLocaleDateString('en-GB')
        : '';
      const dateTo = filters.dateTo
        ? new Date(filters.dateTo)?.toLocaleDateString('en-GB')
        : '';
      setMessage(
        t('marketing-dashboard.filter.message.no-results', {
          dateFrom,
          dateTo,
        })
      );
      dispatch(
        mergeMarketingStatisticsFilter({
          dateFrom: statistics?.[statistics?.length - 1]?.dateFrom,
          dateTo: today.current,
        })
      );
    }
  }, [
    isFilteredStatisticsEmpty,
    dispatch,
    filteredOffers.length,
    filters.dateFrom,
    filters.dateTo,
    statistics,
    t,
    isFilterActive,
  ]);

  const simulationHighestBid = useMemo(
    () =>
      currentValuation
        ? formatPrice(
            simulationHighestBidPrice(currentValuation),
            priceCurrency === '€' ? 'EUR' : 'CHF'
          )
        : '€650.000',
    [currentValuation, priceCurrency]
  );

  if (isEmpty(statistics)) {
    return (
      <Container>
        <FilterHeader
          searchInput={searchInput}
          onSearchInputChange={onSearchInputChange}
          onClearSearch={onClearSearch}
          onApplyFilters={onApplyFilters}
          onResetFilters={onResetFilters}
          isFilterActive={isFilterActive}
          isAccordionsOpen={isAccordionsOpen.current}
          onCollapseAllAccordions={onCollapseAllAccordions}
          totalCount={String(statistics?.length ?? 0)}
          isSimulation={isSimulation}
        />
        <Content isBlurred={isBlurred}>
          <EmptyStatePlaceholder
            icon={RemoveUser}
            title={t(
              'marketing-dashboard.no-purchase-offers-statistics.placeholder.title'
            )}
            description={t(
              'marketing-dashboard.no-purchase-offers-statistics.placeholder.description'
            )}
          />
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <FilterHeader
        searchInput={searchInput}
        onSearchInputChange={onSearchInputChange}
        onClearSearch={onClearSearch}
        onApplyFilters={onApplyFilters}
        onResetFilters={onResetFilters}
        isFilterActive={isFilterActive}
        isAccordionsOpen={isAccordionsOpen.current}
        onCollapseAllAccordions={onCollapseAllAccordions}
        totalCount={String(filteredOffers?.length ?? 0)}
        startDate={statistics?.[statistics?.length - 1]?.dateFrom}
        endDate={new Date().toISOString()}
        isSimulation={isSimulation}
      />
      <Content isBlurred={isBlurred}>
        {showFilter && (
          <FilterAccordion
            userStatus={userStatus}
            statistics={statistics}
            accordionsState={accordionsState}
            onCollapseSingleAccordion={onCollapseSingleAccordion}
            isShowLoader={
              isStatisticsLoading || isStatisticsFetching
              // isFilteredStatisticsEmpty
            }
            isSimulation={!!isSimulation}
            statisticsError={statisticsError}
            filteredOffers={filteredOffers}
            filteredGeneralStatistics={filteredGeneralStatistics}
            warningMessage={message}
            onCloseWarningMessage={() => setMessage('')}
            hasHighestBid={hasHighestBid}
            accordionId={'filter'}
            setLastItemVisible={onLastOfferVisible}
            isFilterActive={isFilterActive}
            isDisabled={isBlurred}
            simulationHighestBid={simulationHighestBid}
          />
        )}
        {statistics
          ?.slice(0, offset)
          ?.map(
            (
              {
                _id,
                weekNumber,
                dateFrom,
                dateTo,
                offers,
                generalStatistics,
                highestBidId,
              },
              index
            ) => {
              const isAccordionOpen = accordionsState?.find(
                (accordion) => accordion.id === _id
              )?.isOpen;
              const period = `(${new Date(
                dateFrom
              ).toLocaleDateString()} - ${new Date(
                dateTo
              ).toLocaleDateString()})`;

              let highestBidRef: HTMLDivElement | null;

              return (
                <Accordion
                  key={weekNumber + index}
                  id={_id}
                  isOpen={isAccordionOpen}
                  onChange={onCollapseSingleAccordion}
                  accordionSection={{
                    _id: index.toString(),
                    title: t(
                      'selling-simulation.offers-statistics-card.week-number',
                      { weekNumber }
                    ),
                    subtitle: period,
                    expandedContent: (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '8px',
                        }}
                        role={'presentation'}
                        onClick={(event) => event.stopPropagation()}
                      >
                        <ScrollWrapper>
                          {offers?.map((offer, i) => (
                            <div
                              key={offer.bidOnOfficeId}
                              ref={(instance) => {
                                if (
                                  (offer.isHighestBid ||
                                    (isSimulation &&
                                      offer.price === simulationHighestBid)) &&
                                  !highestBidRef
                                ) {
                                  highestBidRef = instance;
                                }
                              }}
                            >
                              <Offer
                                userStatus={userStatus}
                                accordionId={_id}
                                setLastItemVisible={onLastOfferVisible}
                                isLast={i === offers.length - 1}
                                bidOnOfficeId={offer.bidOnOfficeId}
                                customerId={offer.customerId}
                                customerOnOfficeId={
                                  offer.customerOnOfficeId ?? ''
                                }
                                title={`${t(
                                  'selling-simulation.offers-statistics-card.customer'
                                )} ${offer.customerId}`}
                                fromCity={offer.fromCity}
                                offeredPrice={offer.price}
                                newOfferedPrice={offer.newPrice}
                                isHighestBid={
                                  isSimulation
                                    ? offer.price === simulationHighestBid
                                    : offer.isHighestBid
                                }
                                // isNew={offer.isNew}
                                isSimulation={isSimulation}
                                isAccepted={offer.isAccepted}
                                date={offer.date}
                                isDisabled={isBlurred}
                              />
                            </div>
                          ))}
                        </ScrollWrapper>
                        <Statistics
                          isGrid={
                            buildGeneralStatistics({
                              statistics: generalStatistics,
                              showUnlockedExposes,
                              showRequestedExposes,
                              showViewingAppointments,
                            })?.length > 4
                          }
                          isShowGradient={
                            !accordionsState?.find(
                              (accordion) => accordion.id === _id
                            )?.isScrolledToBottom
                          }
                        >
                          {buildGeneralStatistics({
                            statistics: generalStatistics,
                            showUnlockedExposes,
                            showRequestedExposes,
                            showViewingAppointments,
                          })?.map((item) => (
                            <Item key={item.label}>
                              <ItemValue content={item.value} />
                              <ItemLabel content={t(item.label)} />
                            </Item>
                          ))}
                        </Statistics>
                      </div>
                    ),
                    Badge: offers?.length ? (
                      <>
                        <NewOfferBadge
                          role={'presentation'}
                          onClick={(event) => event.stopPropagation()}
                        >
                          {offers.length === 1
                            ? t(
                                'selling-simulation.offers-statistics-card.single-new-offer.badge'
                              )
                            : t(
                                'selling-simulation.offers-statistics-card.new-offers-number.badge',
                                { offersNumber: offers?.length }
                              )}
                        </NewOfferBadge>
                        {highestBidId && (
                          <NewOfferBadge
                            isFilled
                            role={'presentation'}
                            onClick={(event) => {
                              event.stopPropagation();

                              if (isMobileSize) {
                                highestBidRef?.scrollIntoView({
                                  behavior: 'smooth',
                                });
                              } else {
                                const headerHeight = 205;
                                const bodyTopOffset =
                                  document.body.getBoundingClientRect().top;

                                const highestBidTopOffset =
                                  highestBidRef?.getBoundingClientRect().top ??
                                  0;
                                const highestBidPosition =
                                  highestBidTopOffset - bodyTopOffset;
                                const highestBidOffset =
                                  highestBidPosition - headerHeight;

                                window.scrollTo({
                                  top: highestBidOffset,
                                  behavior: 'smooth',
                                });
                              }
                            }}
                          >
                            {t(
                              'selling-simulation.offers-statistics-card.highest-bid.badge'
                            )}
                          </NewOfferBadge>
                        )}
                      </>
                    ) : undefined,
                  }}
                  isBadgeAtBottom={isMobileSize}
                />
              );
            }
          )}
        {statistics && statistics?.length > offset && (
          <LoadMoreButton
            fluid
            label={t('button.load-more')}
            onClick={loadMore}
            borderColor={theme.blue}
            color={theme.blue}
          />
        )}
      </Content>
      <AcceptOfferModal />
    </Container>
  );
};

const OffersStatisticsCard = memo(OffersStatisticsCardBase);

export { OffersStatisticsCard };
