import styled from 'styled-components';

import { BenefitSection } from '../../../../generated';
import {
  Headline2,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { device } from '../../../../style/theme';

interface IProps {
  section: BenefitSection;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  border-radius: 3px;
  width: 100%;
  height: 100%;
`;

const TextContainer = styled.div`
  padding-top: 8rem;
  width: 100%;
  text-align: center;
  position: absolute;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 1600px) {
    height: 800px;
  }
  @media (max-width: 1450px) {
    height: 900px;
  }
`;

const Title = styled(Headline2)`
  padding-bottom: 16px;
`;
const Text = styled(ParagraphText)`
  padding-left: 8px;
  padding-right: 8px;
`;

const Slide = ({ section }: IProps): JSX.Element => (
  <Container>
    <TextContainer>
      <Title content={section.title} />
      <Text content={section.expandedText} />
    </TextContainer>

    <StyledImg src={section.imageUrl} />
  </Container>
);

export { Slide };
