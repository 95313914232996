import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import {
  Headline2,
  TitleSlogan,
} from '../../../../common/components/ui/typography';
import Icon from '../../../../common/components/ui/icon';

export const Wrapper = styled.div`
  padding-top: 56px;
  margin-bottom: 88px;
  @media ${device.tablet} {
    padding: 0;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 3px;
  height: 100%;

  &:before {
    content: '';
    width: 100vw;
    background: #f3f4f7;
    position: absolute;
    left: 50%;
    right: 50%;
    margin: 0 -50vw;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  background-color: #f3f4f7;
  @media ${device.tablet} {
    padding: 0;
    align-items: center;
    margin-bottom: 16px;
  }
`;

export const Title = styled(Headline2)`
  color: ${({ theme }) => theme.blue};
  margin-bottom: 4px;
  @media ${device.mobile} {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const SliderHeader = styled(TitleSlogan)`
  opacity: 0.8;
  margin-bottom: 4px;
`;

export const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const ContentContainer2 = styled.div`
  margin: 0 48px;
  position: relative;
  @media ${device.tablet} {
    margin: 0 16px;
  }
`;

export const ImageBox = styled.div`
  max-width: 450px;
  position: absolute;
  left: 0;
  top: -54px;
  @media ${device.tablet} {
    display: none;
  }
`;

export const InfoBox = styled.div`
  width: 100%;
  padding: 48px 0 48px 498px;
  @media ${device.tablet} {
    position: relative;
    margin: 0;
    padding: 16px;
  }
`;

export const Description = styled.div`
  margin: 0 0 16px 0;
  font-family: 'Roboto' !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;

export const BoxButtons = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    justify-content: center;
  }
  @media ${device.mobile} {
    flex-direction: column-reverse;
  }
`;

export const LinkIconWrapper = styled(Icon)`
  margin-right: 8px;
`;

export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  max-height: 420px;
  object-fit: cover;
`;
