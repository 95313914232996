import styled from 'styled-components';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { device } from '../../../../style/theme';
import EmptyStatePlaceholder from '../../../common/components/empty-state-placeholder';
import { ReactComponent as AdvertisingMegaphone } from '../../../../assets/streamline-light/business-products/advertising/advertising-megaphone.svg';
import { Headline2 } from '../../../common/components/ui/typography';

const PlaceholderContainer = styled.div`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  margin-bottom: 16px;
  background-color: ${(props) => props.theme.white};

  @media ${device.tablet} {
    padding: 16px;
  }
`;

const Title = styled(Headline2)`
  margin-bottom: 20px;
`;

const PlaceholderBase = () => {
  const { t } = useTranslation();

  return (
    <PlaceholderContainer>
      <Title
        content={t('marketing-dashboard.selling-not-started.card.title')}
      />
      <EmptyStatePlaceholder
        icon={AdvertisingMegaphone}
        title={t('marketing-dashboard.selling-not-started.placeholder.title')}
        description={t(
          'marketing-dashboard.selling-not-started.placeholder.description'
        )}
      />
    </PlaceholderContainer>
  );
};

const Placeholder = memo(PlaceholderBase);

export { Placeholder };
