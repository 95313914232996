import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { defaultTheme } from '../../../../style/theme';
import { abbrNum } from '../../../common/utils/number-scientific-notation';

const styles = StyleSheet.create({
  wrapper: {
    width: '32%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '3mm',
    paddingBottom: '3mm',
    paddingLeft: '8mm',
    paddingRight: '8mm',
    border: '1px',
    borderColor: '#c9d5fc',
    borderRadius: '1mm',
    backgroundColor: '#f2f5f7',
  },
  priceText: {
    fontSize: '16px',
    fontWeight: 'extrabold',
    lineHeight: 1.33,
    textAlign: 'center',
    color: defaultTheme.blue,
    marginBottom: '3mm',
  },
  percentText: {
    fontSize: '10px',
    fontWeight: 'extrabold',
    lineHeight: 1.33,
    textAlign: 'center',
    marginBottom: '3mm',
  },
  clarificationText: {
    fontSize: '12px',
    fontWeight: 'extrabold',
    lineHeight: 1.33,
    textAlign: 'center',
    color: defaultTheme.blue,
    opacity: 0.8,
  },
});

const PriceCard = ({
  price,
  percent,
  clarification,
  lowerBound,
  upperBound,
}: {
  price?: string;
  percent: number;
  clarification: string;
  lowerBound?: number;
  upperBound?: number;
}): JSX.Element => {
  return (
    <View style={styles.wrapper}>
      {lowerBound && upperBound ? (
        <Text style={styles.priceText}>
          {abbrNum(lowerBound, 2)}€-{abbrNum(upperBound, 2)}€
        </Text>
      ) : (
        <Text style={styles.priceText}>{price}€</Text>
      )}
      <Text
        style={[
          styles.percentText,
          { color: percent > 0 ? defaultTheme.green : defaultTheme.red },
        ]}
      >
        {`${percent > 0 ? '+' : '-'}${percent}`} %
      </Text>
      <Text style={styles.clarificationText}>{clarification}</Text>
    </View>
  );
};

export { PriceCard };
