import styled from 'styled-components';
import { device } from '../../../../../../style/theme';
import { hexToRGB } from '../../../../../common/utils/hex-to-rgb';

export const SliderContainer = styled.div<{
  isGallery?: boolean;
  height?: string;
}>`
  height: ${({ isGallery, height }) =>
    height || (isGallery ? '660px' : '320px')};
  width: 100%;
  position: relative;
  //overflow: ${({ isGallery }) => (isGallery ? 'visible' : 'hidden')};
  ${({ isGallery }) =>
    isGallery
      ? `
    overflow-x: visible;
  `
      : `
  overflow: hidden;
  `}
  margin-top: 16px;
  margin-bottom: 18px;
  ${({ isGallery }) =>
    isGallery &&
    `
    margin-left: 100px;
    margin-right: 100px;
    max-width: 1000px;
  `}

  &&& {
    .swiper-container {
      width: 100%;
      height: 100%;
      ${({ isGallery }) => isGallery && `max-height:540px;`}

      &#main {
        background-color: ${(props) => props.theme.logoBlue};
      }

      .swiper-slide {
        ${({ isGallery }) => isGallery && `max-height:540px;`}
      }
    }

    .swiper-pagination {
      bottom: 0;
      padding-bottom: 24px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .swiper-wrapper {
      padding-inline-start: 0;
    }

    .swiper-pagination-bullet {
      position: relative;
      width: 16px;
      height: 16px;
      padding: 3px;
      border: solid 1px rgba(0, 48, 94, 0.5);
      background: transparent;
      background-clip: content-box;
      margin: 0 20px;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background-color: ${(props) => props.theme.blue};
      }
      &:not(:last-of-type):after {
        content: '';
        border-bottom: 1px dashed rgba(0, 48, 94, 0.5);
        position: absolute;
        width: calc(300% - 8px);
        top: 50%;
        left: calc(100% + 4px);
        transform: translateY(-50%);
      }
    }
    .parallax-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 130%;
      height: 100%;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
    }
  }

  @media ${device.tablet} {
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;
    &&& {
      .swiper-container {
        &#main {
          order: 2;
        }
      }

      .swiper-pagination {
        padding-bottom: 12px;
      }

      .swiper-wrapper {
      }

      .swiper-pagination-bullet {
        margin: 0 12px;
        &.swiper-pagination-bullet-active {
        }
        &:not(:last-of-type):after {
          width: calc(200% - 8px);
          left: calc(100% + 3px);
        }
      }
      .parallax-bg {
      }
    }
  }
  @media ${device.mobile} {
    height: 288px;
  }
`;

export const BuilderCount = styled.div`
  position: absolute;
  cursor: pointer;
  bottom: 12px;
  right: 12px;
  padding: 10px 16px;
  border-radius: 3px;
  background-color: ${({ theme }) => hexToRGB(theme.white, 0.9)};
  z-index: 300;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1.67;
`;

export const SliderImage = styled.img<{
  isPortrait?: boolean;
  zoom?: number;
}>`
  object-fit: ${(props) => (props.isPortrait ? 'contain' : 'cover')};
  position: relative;
  top: 0;
  left: 0;
  border: 0 none transparent;
  width: ${(props) => `calc(100% * ${props.zoom})`};
  height: ${(props) => `calc(100% * ${props.zoom})`};
  transition: width 100ms ease-in-out, height 100ms ease-in-out,
    transform 100ms ease-in-out;

  ${({ isPortrait }) =>
    isPortrait &&
    `
    position: relative;
  `}
`;

export const ButtonsWrapper = styled.div`
  width: 34px;
  box-shadow: rgb(0 0 0 / 30%) 0 1px 4px 0;
  position: absolute;
  bottom: 0;
  z-index: 2000000;
`;

export const ZoomButton = styled.button`
  width: 34px;
  height: 34px;
  display: block;
  padding: 0;
  outline: none;
  border: 0;
  box-sizing: border-box;
  //background-color: transparent;
  cursor: pointer;
  overflow: hidden;
  border: none;
  border-radius: 4px;
`;

export const ZoomIn = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");

  &:hover {
    opacity: 0.8;
  }
`;

export const ZoomOut = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E");

  &:hover {
    opacity: 0.8;
  }
`;
