import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media ${device.tablet} {
    padding: 12px;
    height: 100%;
  }
`;

export const Title = styled.h3`
  opacity: 1;
  color: rgba(0, 48, 94, 1);
  font-family: Source Serif Pro, sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 32px;
`;

export const Text = styled.p`
  opacity: 1;
  color: rgba(0, 48, 94, 1);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 24px;
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media ${device.tablet} {
    button {
      width: 100%;
    }
  }
`;
