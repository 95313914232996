import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { usePersonalPhoneVerification } from '../../../../../hooks/callback-hooks/personal-valuation/usePersonalPhoneVerification';
import { IValuationForm } from '../../../../../../form-property-valuation-wizard/interface';
import {
  ITemporaryUserCredentials,
  ITemporaryUserInput,
} from '../../../../../interface';
import {
  PhoneVerificationWrapper,
  Title,
} from '../../../../../../components/fullscreen-modal/auth-container/styles';
import { PhoneVerification } from '../../../../../../../auth/components/forms/phone-verification';

interface IProps {
  temporaryUserInput: ITemporaryUserInput;
  setErrors: (errors: unknown) => void;
  onSuccess: (formData: IValuationForm, ownerId: string) => Promise<void>;
  setTemporaryUserCredentials: (temporaryUC: ITemporaryUserCredentials) => void;
}

const PersonalVerifyPhoneContainer: FC<IProps> = ({
  temporaryUserInput,
  setErrors,
  onSuccess,
  setTemporaryUserCredentials,
}) => {
  const { t } = useTranslation();
  const {
    formattedPhone,
    onVerifyPhone,
    verificationStatus,
    onResendConfirmationCode,
    onChangePhone,
  } = usePersonalPhoneVerification({
    temporaryUserInput,
    setTemporaryUserCredentials,
    setErrors,
    onSuccess,
  });

  return (
    <>
      <Title>{t('phone-verification.title')}</Title>
      <PhoneVerificationWrapper>
        <PhoneVerification
          phoneNumber={formattedPhone}
          onVerifyPhone={onVerifyPhone}
          status={verificationStatus}
          resendCode={onResendConfirmationCode}
          changePhone={onChangePhone}
        />
      </PhoneVerificationWrapper>
    </>
  );
};

export { PersonalVerifyPhoneContainer };
