import { ReactComponent as ArrowButtonLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as ReadHuman } from 'assets/streamline-light/school-learning/library-reading/read-human.svg';
import BannerImage from 'assets/broker-view-banner.webp';

import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';

import { ValuationType, useInviteSellerMutation } from '../../../../generated';
import { Card } from '../../../common/components/ui/card';
import { Hero } from '../../../common/components/ui/hero';
import { HeroSlider } from '../../../common/components/ui/hero-slider/hero-slider';
import Icon from '../../../common/components/ui/icon';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { Mapbox } from '../../../map/components/mapbox';
import { Europe } from '../../../map/constants/points-of-interest';
import {
  changeViewport,
  restoreCheckpoints,
} from '../../../map/redux/mapSlice';
import { toggleIsPreview } from '../../redux/sellerPropertiesSlice';
import { OnlineValuationContainer } from '../../../forms/form-property-valuation-wizard/components/containers/online-valuation/online-valuation-container';
import { PersonalValuationContainer } from '../../../forms/form-property-valuation-wizard/components/containers/personal-valuation/personal-valuation-container';
import {
  closeValuationWizardAction,
  resetValuationWizard,
} from '../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { Drawer } from '../../../property/components/drawer/drawer';
import { SellerProperties } from '../../components/seller-property-card/container';
import { PropertyGeneralInfoCard } from '../../components/property-general-info-card/property-general-info-card';
import {
  ContentWithPadding,
  IconWrapper,
  IFrameContainer,
  LoaderContainer,
  MobileHeadContainer,
  MobileMapContainer,
} from './seller-properties-page-styles';
import { PropertyValuationWidgetContainer } from '../../../forms/form-property-valuation-wizard/components/containers/property-valuation-widget-container/property-valuation-widget-container';
import { PageType } from '../../../forms/form-property-valuation-wizard/interface';
import { CTAButtons } from '../../../forms/form-property-valuation-wizard/components/containers/property-valuation-widget-container/cta-buttons/cta-buttons';
import Banner from '../../components/banner/banner';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';

const SellerPropertiesPageBase = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isMobileSize = useIsMobileSize();
  const { t } = useTranslation();

  // onOffice marketplace iframe URL params
  const urlParams = new URLSearchParams(window.location.search);
  const propertyOnOfficeId = urlParams.get('EstateId') || '';

  const [inviteSeller, { isLoading, error: invitationError }] =
    useInviteSellerMutation();

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);
  const isPreview = useAppSelector((state) => state.sellerProperties.isPreview);

  const totalSellerPropertiesValue = useAppSelector(
    (state) => state.sellerProperties.totalValue
  );

  const totalSellerPropertiesCount = useAppSelector(
    (state) => state.sellerProperties.totalItems
  );

  const selectedTab = useAppSelector(
    (state) => state.dynamicMapUtils.selectedTab
  );

  const isValuationWizardOpen = useAppSelector(
    (state) => state.valuationWizard.isValuationWizardOpen
  );

  const valuationType = useAppSelector(
    (state) => state.valuationWizard.valuationType
  );

  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const handleInviteSeller = useCallback(async () => {
    try {
      if (!propertyOnOfficeId) {
        return;
      }
      await inviteSeller({ estateId: propertyOnOfficeId ?? '' }).unwrap();
      dispatch(toggleIsPreview(false));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, inviteSeller, propertyOnOfficeId]);

  const closeEvaluationWizard = () => {
    // Heat map
    if (selectedTab === 0) {
      dispatch(
        changeViewport({
          ...Europe,
          transitionDuration: 1000,
        })
      );
      setTimeout(() => {
        batch(() => {
          dispatch(closeValuationWizardAction());
          dispatch(resetValuationWizard());
        });
      }, 500);
    } else {
      batch(() => {
        dispatch(closeValuationWizardAction());
        dispatch(resetValuationWizard());
        dispatch(restoreCheckpoints());
      });
    }
  };

  if (isLoading) {
    return (
      <LoaderContainer>
        <GlobalLoader />
      </LoaderContainer>
    );
  }

  return (
    <>
      {isIframe && (
        <IFrameContainer>
          <PropertyValuationWidgetContainer
            pageType={PageType.sellerProperties}
          />
        </IFrameContainer>
      )}
      {!isIframe && (
        <>
          <>
            {!isMobileSize && (
              <Hero>
                <Mapbox isVisible />
                <HeroSlider showAddressBar={false} topOffset={-56}>
                  <PropertyGeneralInfoCard
                    header="seller-properties-general-info-card.header"
                    title="seller-properties-general-info-card.title"
                    content="seller-properties-general-info-card.content"
                    numberOfObjects={totalSellerPropertiesCount}
                    totalValue={totalSellerPropertiesValue}
                  />
                  <Card
                    header={t('property-page.hero-slider.first-card.header')}
                    titleFirstLine={
                      valuationType === ValuationType.Online
                        ? t(
                            'property-page.hero-slider.first-card.online.title.first-line'
                          )
                        : t(
                            'property-page.hero-slider.first-card.personal.title.first-line'
                          )
                    }
                    titleSecondLine={t(
                      'property-page.hero-slider.first-card.title.second-line'
                    )}
                    content={
                      valuationType === ValuationType.Online
                        ? t(
                            'property-page.hero-slider.first-card.online.content'
                          )
                        : t(
                            'property-page.hero-slider.first-card.personal.content'
                          )
                    }
                    cta={(c) => (
                      <IconWrapper onClick={closeEvaluationWizard}>
                        <Icon icon={ArrowButtonLeft} width={12} height={12} />{' '}
                        {c}
                      </IconWrapper>
                    )}
                  />
                  <Card
                    titleType="h2"
                    header={t('property-page.hero-slider.second-card.header')}
                    titleFirstLine={
                      valuationType === ValuationType.Online
                        ? t(
                            'property-page.hero-slider.second-card.online.title.first-line'
                          )
                        : t(
                            'property-page.hero-slider.second-card.personal.title.first-line'
                          )
                    }
                    titleSecondLine={t(
                      'property-page.hero-slider.second-card.title.second-line'
                    )}
                    content={
                      valuationType === ValuationType.Online
                        ? t(
                            'property-page.hero-slider.second-card.online.content'
                          )
                        : t(
                            'property-page.hero-slider.second-card.personal.content'
                          )
                    }
                    cta={(c) => (
                      <IconWrapper onClick={closeEvaluationWizard}>
                        <Icon icon={ArrowButtonLeft} width={12} height={12} />{' '}
                        {c}
                      </IconWrapper>
                    )}
                  />
                </HeroSlider>
                <PropertyValuationWidgetContainer
                  pageType={PageType.sellerProperties}
                />
              </Hero>
            )}
          </>
          <>
            {isMobileSize && (
              <MobileHeadContainer>
                <PropertyGeneralInfoCard
                  header="seller-properties-general-info-card.header"
                  title="seller-properties-general-info-card.title"
                  content="seller-properties-general-info-card.content"
                  numberOfObjects={totalSellerPropertiesCount}
                  totalValue={totalSellerPropertiesValue}
                />
                <MobileMapContainer>
                  <>
                    <Mapbox isVisible />
                  </>
                </MobileMapContainer>

                <Drawer
                  drawerTopContent={
                    <CTAButtons
                      skipBorderRadius
                      pageType={PageType.sellerProperties}
                    />
                  }
                />

                {isValuationWizardOpen &&
                  valuationType === ValuationType.Online && (
                    <OnlineValuationContainer />
                  )}
                {isValuationWizardOpen &&
                  valuationType === ValuationType.Personal && (
                    <PersonalValuationContainer />
                  )}
              </MobileHeadContainer>
            )}
          </>

          {invitationError?.message && (
            <GlobalError
              title={t(invitationError?.message?.split(':')[0].toLowerCase())}
            />
          )}

          {isBrokerView && (
            <Banner
              icon={ReadHuman}
              image={BannerImage}
              title={t('seller-portal.broker-view.banner.title')}
              description={t('seller-portal.broker-view.banner.description')}
              containerStyle={{ margin: '0 48px', marginBottom: '24px' }}
              onCTAClick={isPreview ? handleInviteSeller : undefined}
              ctaButtonLabel={
                isPreview
                  ? t('seller-portal.broker-view.banner.cta-label')
                  : undefined
              }
              isCTADisabled={isLoading}
            />
          )}

          <ContentWithPadding>
            <SellerProperties />
          </ContentWithPadding>
        </>
      )}
    </>
  );
};

const SellerPropertiesPage = memo(SellerPropertiesPageBase);

export { SellerPropertiesPage };
