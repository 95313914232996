import styled from 'styled-components';
import { device } from '../../../../style/theme';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { MainButton } from '../../../common/components/ui/buttons';

export const Container = styled.div`
  height: 60px;
  padding: 0 12px;
  display: flex;
  background-color: #e5e9ec;
  overflow-x: auto;
  //opacity: 0.9;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  a {
    transition: 150ms;
    text-decoration: none;
    &:hover {
      background-color: ${({ theme }) => theme.faqActiveHeader};
    }
  }

  @media ${device.tablet} {
    height: 56px;
    padding: 0 16px 0;
    &:hover {
      background-color: #e5e9ec;
    }
  }
`;

export const OuterContainer = styled.div`
  position: sticky;
  top: 80px;
  z-index: 100;
  overflow: hidden;

  @media ${device.tablet} {
    top: 0;
    position: relative;
  }
`;

export const VariantsOuterContainer = styled(OuterContainer)`
  position: sticky;
  top: 140px;
  z-index: 100;
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    background-color: ${({ theme }) => theme.greyBackground};
    flex-direction: column;
    gap: 16px;
    top: 0;
    padding: 16px;
    position: relative;
  }
`;

export const VariantsContainer = styled(Container)`
  flex-grow: 1;
  height: 56px;
  padding: 12px 0 12px 12px;
  gap: 0;
  background-color: white;
  align-items: center;
  & .__react_component_tooltip {
    background-color: white;
  }
  & .__react_component_tooltip.show {
    opacity: 1;
  }

  @media ${device.tablet} {
    background-color: transparent;
    margin: 0 -16px;
    padding: 0;
    height: 32px;
  }
`;

export const ItemBox = styled.div<{ active?: boolean }>`
  font-family: Roboto, sans-serif;
  min-width: 320px;
  padding: 12px 6px 0;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  font-stretch: normal;
  font-style: normal;
  cursor: pointer;
  flex: 1 0 auto;
  height: 100%;
  ${(props) => props.active && 'border-bottom: 4px solid #002849;'}

  @media ${device.tablet} {
    width: 336px;
  }
`;

export const MultipleVariantsItemBox = styled.div`
  margin: 12px 0;
  padding: 0 12px 0 4px;
  display: flex;
  border-radius: 3px;
  align-items: center;
  transition: 0.3s background;

  &:hover {
    background: rgba(0, 40, 73, 0.05);
  }

  @media ${device.tablet} {
    margin: 0;
    padding: 0;

    &:hover {
      background: unset;
    }
  }
`;

export const VariantBox = styled.div<{ active?: boolean }>`
  font-family: Roboto, sans-serif;
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 152px;
  padding: 8px 4px 4px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: ${({ theme }) => hexToRGB(theme.blue, 0.5)};
  border-bottom: 4px solid transparent;
  ${(props) => props.active && 'border-bottom: 4px solid #002849;'}
`;

export const ItemTitle = styled.p`
  margin-bottom: 4px;
  text-transform: uppercase;
  opacity: 0.5;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.6;
`;

export const ItemAddress = styled.p`
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.23;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media ${device.tablet} {
    font-size: 12px;
    line-height: 1.33;
  }
`;

const ButtonContainer = styled.div`
  height: 60px;
  width: 20px;
  position: absolute;
  z-index: 1;

  @media ${device.tablet} {
    height: 60px;
  }
`;

export const LeftButtonContainer = styled(ButtonContainer)`
  top: 0;
  left: 0;

  @media ${device.tablet} {
    background-image: linear-gradient(
      to left,
      rgba(231, 235, 237, 0) 9%,
      #e7ebed 62%
    );
  }
`;

export const RightButtonContainer = styled(ButtonContainer)`
  top: 0;
  right: 0;

  @media ${device.tablet} {
    background-image: linear-gradient(
      to right,
      rgba(231, 235, 237, 0) 9%,
      #e7ebed 62%
    );
  }
`;

const ArrowButton = styled.div`
  width: 40px;
  height: 40px;
  border: solid 2px #fff;
  background-color: ${(props) => props.theme.logoBlue};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  transition: 150ms;

  i {
    path {
      stroke-width: 2px;
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.ctaBlueHover};
  }
`;

export const LeftButton = styled(ArrowButton)`
  left: -20px;
  i {
    margin-left: 14px;
  }
`;

export const RightButton = styled(ArrowButton)`
  right: -20px;
  z-index: 3;
  i {
    margin-right: 14px;
  }
`;

export const VariantCheckbox = styled.div<{ isDisabled?: boolean }>`
  width: 20px;
  height: 20px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: solid 1px
    ${({ theme, isDisabled }) =>
      isDisabled ? theme.ctaDisabledBackground : theme.logoBlue};
  background-color: #fff;
  cursor: pointer;
`;

export const VariantsButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px 0 40px;
  background-color: white;

  @media ${device.tablet} {
    background-color: #f2f5f7;
    padding: 0;
  }
`;

export const VariantsMainButton = styled(MainButton)`
  padding: 8px 12px;
  min-width: 169px;
  height: 32px;
  width: auto;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1px;
  color: #fff;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const TooltipText = styled.p`
  opacity: 1;
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 16px;
  text-transform: uppercase;
`;
