const passwordGenerator = (requiredLength = 8): string => {
  const string = 'abcdefghijklmnopqrstuvwxyz';
  const numeric = '0123456789';
  const punctuation = '!@#$%^&*';

  let password = '';
  let character = '';
  while (password.length < requiredLength) {
    const entity1 = Math.ceil(string.length * Math.random() * Math.random());
    const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
    const entity3 = Math.ceil(
      punctuation.length * Math.random() * Math.random()
    );

    let hold = string.charAt(entity1);
    hold = password.length % 2 === 0 ? hold.toUpperCase() : hold;
    character += hold;
    character += numeric.charAt(entity2);
    character += punctuation.charAt(entity3);
    password = character;
  }

  return password
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('')
    .slice(0, Math.max(0, requiredLength));
};

export { passwordGenerator };
