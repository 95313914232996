import { FC, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { useGetSellerExposePdfLinkMutation } from '../../../../generated';
import { siteMap } from '../../../../routes/site-map';
import { setSelectedTab } from '../../../property/redux/dynamicMapUtilsSlice';

const Container = styled.div`
  width: 100%;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DownloadSellerExposePage: FC = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();

  const [getSellerExposePdfLink] = useGetSellerExposePdfLinkMutation();

  const openPdfLink = useCallback(
    async (sellerPropertyId, pdfToken) => {
      try {
        const { getSellerExposePdfLink: pdfExpose } =
          await getSellerExposePdfLink({
            id: sellerPropertyId,
            token: pdfToken,
          }).unwrap();
        if (pdfExpose) {
          window.open(pdfExpose, '_self');
          dispatch(setSelectedTab(2));
          push(
            `${siteMap.SellingProcessPage.pathWithoutParams}/${sellerPropertyId}?openTab=2`
          );
        }
      } catch (error) {
        console.error(error);
        push(siteMap.SellerLandingPage.pathWithoutParams);
      }
    },
    [getSellerExposePdfLink, push]
  );

  useEffect(() => {
    if (id && token) {
      setTimeout(() => {
        openPdfLink(id, token);
      }, 1000);
    } else {
      push(siteMap.SellerLandingPage.pathWithoutParams);
    }
  }, []);

  return (
    <Container>
      <GlobalLoader />
    </Container>
  );
};

export { DownloadSellerExposePage };
