import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 12px;

  h3 {
    opacity: 1;
    color: ${({ theme }) => theme.blue};
    font-family: Source Serif Pro, sans-serif;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 22px;
  }
`;

export const PeriodsContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  overflow-x: auto;
`;
