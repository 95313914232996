import { FC, memo } from 'react';

import { BoxSelector } from '../../../../../../components/common/box-selector';
import { useFirstStepLogic } from '../../../../../hooks/useStepsLogic';

const FirstStepBase: FC = () => {
  const { name, codeBoxes, onClick, isIframe } = useFirstStepLogic();

  return (
    <>
      <BoxSelector
        name={name}
        onClick={onClick}
        boxes={codeBoxes}
        isBoxTabletColumn
        isIframe={isIframe}
      />
    </>
  );
};

const FirstStep = memo(FirstStepBase);

export { FirstStep };
