import styled from 'styled-components';
import { ReactNode } from 'react';
import { BoxText } from '../ui/typography';

const Container = styled.div`
  max-width: 316px;
  width: 100%;
  padding: 10px 0 10px 0;
  text-align: center;
`;
interface IProps {
  content: string | ReactNode;
}
const ToolTipBasicContent = ({ content }: IProps): JSX.Element => {
  return (
    <Container>
      <BoxText content={content} />
    </Container>
  );
};

export { ToolTipBasicContent };
