import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as HouseCircle } from 'assets/svg/house-circle.svg';
import { ReactComponent as LoadingCircle } from 'assets/svg/loading-circle.svg';
import Icon from '../../../../common/components/ui/icon';
import { useAppSelector } from '../../../../common/hooks';

const Container = styled.div`
  margin-bottom: 4px;
  margin-top: 8px;
  width: 100%;
`;

const Title = styled.h2`
  color: #00305e;
  font-family: 'Source Serif Pro';
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
`;

const MessageBoxContainer = styled.div<{
  isLoading?: boolean;
  loaded?: boolean;
}>`
  display: flex;
  padding: 12px;
  border-radius: 3px;
  align-items: center;
  gap: 8px;
  background-color: #e5e9ec;
  ${(props) => props.isLoading && 'background-color: #F0EDE9;'}
  ${(props) => props.loaded && 'background-color: #F1F5EE;'}
`;

const IconBox = styled.div`
  width: 22px;
  height: 22px;
`;

const MessageText = styled.p<{ loaded?: boolean }>`
  font-family: 'Roboto';
  margin: 0;
  color: #00305e;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 16px;

  & b {
    font-weight: 700;
  }

  ${(props) => props.loaded && 'color: #122E01;'}
`;

interface IProps {
  propertiesCount: number;
}

const MessageBox: FC<IProps> = ({ propertiesCount = 0 }: IProps) => {
  const { t } = useTranslation();

  const isLoading = useAppSelector((state) => state.searchProfile.isLoading);

  const loaded = useAppSelector((state) => state.searchProfile.activeStep > 1);

  const messageText = useMemo(() => {
    if (isLoading)
      return t('search-profile.info-container.message.text-loading');
    if (loaded) {
      return propertiesCount > 0
        ? t('search-profile.info-container.message.text-loaded', {
            propertiesCount,
          })
        : t('search-profile.info-container.message.text-no-results');
    }
    return t('search-profile.info-container.message.text');
  }, [isLoading, loaded, t, propertiesCount]);

  return (
    <Container>
      <Title>{t('search-profile.info-container.message.title')}:</Title>
      <MessageBoxContainer
        isLoading={isLoading}
        loaded={propertiesCount > 0 && loaded}
      >
        <IconBox>
          <Icon
            icon={isLoading ? LoadingCircle : HouseCircle}
            height={22}
            width={22}
            color={'#00305E'}
          />
        </IconBox>
        <MessageText
          loaded={loaded}
          dangerouslySetInnerHTML={{
            __html: messageText,
          }}
        />
      </MessageBoxContainer>
    </Container>
  );
};

export default MessageBox;
