import { FC } from 'react';
import styled from 'styled-components';
import { useWatch } from 'react-hook-form';

import { BoxSelector } from '../../../../../components/common/box-selector';
import {
  FIELDS,
  FINANCING_FORM_KEYS,
  reasonBoxes,
} from '../../../../constants';
import { SelectV2 } from '../../../../../components/common/select-v2/select-v2';
import { InputFinancingAddress } from '../../../common/input-address/input-financing-address';
import { useDirtyForm } from '../../../../hooks/callback-hooks/calculation-form/useDirtyForm';
import { InputV2 } from '../../../../../components/common/input-v2/input-v2';
import {
  FinancingPropertyType,
  FinancingReason,
} from '../../../../../../../generated';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > div > div:first-child > div {
    width: 100%;
    flex: 1 !important;

    & input,
    .select {
      max-width: 100%;
      width: 100%;
    }
  }

  & > div > div:last-child > div {
    flex: 0;
    padding: 0;
    //min-width: 100%;
  }
`;

const FinancingPropertyEditForm: FC = () => {
  // our dirty function for form
  const { setDirtyTrue } = useDirtyForm();
  const [reason, propertyType] = useWatch({
    name: [FINANCING_FORM_KEYS.REASON, FINANCING_FORM_KEYS.PROPERTY_TYPE],
  });
  const isPlotOnly = propertyType === FinancingPropertyType.PlotOnly;
  const isMultiFamilyHouse =
    propertyType === FinancingPropertyType.MultiFamilyHouse;
  const reasonBox = reasonBoxes.find((item) => item.value === reason);

  return (
    <Container>
      <BoxSelector
        {...FIELDS.PROPERTY_TYPE}
        boxPadding="9px 12px"
        isFullWidth
        onClick={setDirtyTrue}
      />

      {!isPlotOnly && (
        <InputV2
          {...FIELDS.LIVING_AREA}
          isValidateOnBlur
          isColumn
          handleChangeProp={setDirtyTrue}
          isOldDesign
        />
      )}

      {!isPlotOnly && reason !== FinancingReason.OwnConstructionProject && (
        <InputV2
          {...FIELDS.BUILDING_YEAR}
          isValidateOnBlur
          isColumn
          handleChangeProp={setDirtyTrue}
          isOldDesign
        />
      )}

      {isMultiFamilyHouse && (
        <InputV2
          {...FIELDS.RENTAL_INCOME_PER_MONTH}
          isValidateOnBlur
          isColumn
          handleChangeProp={setDirtyTrue}
          isOldDesign
          isSeparate
          tooltipWidth={350}
          isTooltipKeyLeftSide
          isBoldTooltipKeyIcon
        />
      )}

      <SelectV2
        {...FIELDS.PROPERTY_USE}
        defaultValue=""
        selectOptions={FIELDS.PROPERTY_USE.options}
        handleChangeProp={setDirtyTrue}
        isOldDesign
      />

      {reasonBox?.isSearchProgressField && (
        <SelectV2
          {...FIELDS.SEARCH_PROGRESS}
          selectOptions={FIELDS.SEARCH_PROGRESS.options}
          handleChangeProp={setDirtyTrue}
          isOldDesign
        />
      )}

      <InputFinancingAddress
        {...FIELDS.PLACE_NAME}
        isOldDesign
        defaultValue=""
      />
    </Container>
  );
};

export { FinancingPropertyEditForm };
