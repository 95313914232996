import { AddressSearchProfileInput } from '../../../generated';

export const implodeAddress = (address?: AddressSearchProfileInput): string =>
  address
    ? address.placeName
      ? address.placeName
      : `${address?.postCode ? `${address?.postCode},` : ''} ${
          address?.locality ? `${address?.locality},` : ''
        } ${address?.neighborhood ? `${address?.neighborhood},` : ''} ${
          address?.city
        }`
    : '';
