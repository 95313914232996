import styled from 'styled-components';
import { device } from "../../../../../../style/theme";
import { FlatButton } from '../../../../../common/components/ui/buttons';

export const Container = styled.div``;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  input {
    min-width: 320px;
  }
  > div {
    margin-bottom: 8px;
  }

  > div > div {
    gap: 0;
  }

  @media ${device.tablet} {
    input {
      min-width: auto !important;
      width: 100%;
    }
  }
`;

export const SubmitButton = styled(FlatButton)`
  max-width: 90px;
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.logoBlue};
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.red};
  font-size: 12px;
`;
