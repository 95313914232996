import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  BenefitSection,
  BlockType,
  useCmsPageQuery,
  useCmsPagesQuery,
} from '../../../generated';
import { device } from '../../../style/theme';
import { useWindowSize } from '../../common/hooks';
import { useIsMobileSize } from '../../common/hooks/useIsMobileSize';
import { calculateParallax } from '../../common/utils/calculate-parallax';
import { Parallax as ParallaxComponent } from '../../landing/components/slider/parallax';
import PasswordChangeForm from '../components/password-change/password-change-form';
import { Slide } from '../components/slide';

const Container = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
`;

const Column = styled.div`
  flex: 1 0 50%;
  position: relative;
  max-width: 50%;
  @media ${device.tablet} {
    max-width: 100%;
    flex: 1 0 100%;
    padding: 0 16px;
  }
  &:first-of-type {
    display: flex;
    flex-direction: column;
    overflow: overlay;
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.ctaDisabledBackground};
      border-radius: 4px;
    }
  }
`;

const SliderContainer = styled.div`
  display: flex;
  max-height: calc(100vh - 64px - 68px);
  max-width: 100%;

  &&& {
    .swiper-pagination-bullet {
      position: relative;
      width: 16px;
      height: 16px;
      padding: 3px;
      border: solid 1px rgba(0, 48, 94, 0.5);
      background: transparent;
      background-clip: content-box;
      margin: 0 20px;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background-color: ${(props) => props.theme.blue};
      }
      &:not(:last-of-type):after {
        content: '';
        border-bottom: 1px dashed rgba(0, 48, 94, 0.5);
        position: absolute;
        width: calc(300% - 8px);
        top: 50%;
        left: calc(100% + 4px);
        transform: translateY(-50%);
      }
    }
  }
`;

const PropertyInvitationPage = (): JSX.Element => {
  const isMobileSize = useIsMobileSize();

  const { propertyInvitationPage } = useCmsPagesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      propertyInvitationPage: data?.cmsPages?.find(
        (page) => page.name === 'Property Invitation'
      ),
    }),
  });

  useEffect(() => {}, []);

  const { data: propertyInvitationPageData } = useCmsPageQuery({
    id: propertyInvitationPage?._id ?? '',
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const onSlideChange = (swiper: SwiperCore) =>
    setActiveIndex(swiper.activeIndex);

  const size = useWindowSize();

  const sliderParallax = useMemo(
    () => calculateParallax(size.width),
    [size.width]
  );

  const { benefitSections } = useMemo(() => {
    let sections = null;

    const benefitBlock = propertyInvitationPageData?.cmsPage.blocks.find(
      (block) => block.type === BlockType.Benefit
    );

    if (benefitBlock && benefitBlock.isActive) {
      sections = benefitBlock.sections.map(
        (section) => section
      ) as BenefitSection[];
    }

    return {
      benefitSections: sections,
    };
  }, [propertyInvitationPageData]);

  return (
    <Container>
      <Column>
        <PasswordChangeForm />
      </Column>
      {!isMobileSize && (
        <Column>
          <SliderContainer>
            <Swiper
              // Rebuild on each possible components lose scenario
              resizeObserver
              observer
              observeParents
              onSwiper={(swiper) => {
                swiper.update();
              }}
              id="main"
              tag="section"
              wrapperTag="ul"
              parallax
              speed={600}
              spaceBetween={0}
              slidesPerView={1}
              pagination={{
                clickable: true,
              }}
              allowTouchMove={false}
              onSlideChange={onSlideChange}
            >
              <ParallaxComponent
                slot="container-start"
                data-swiper-parallax={sliderParallax}
                activeIndex={activeIndex}
                zIndex="10"
                padding="32px 0 0 0"
                margin="0 0 0 45%"
                sections={benefitSections || []}
                reduceToken
              />
              {benefitSections?.map((section) => {
                return (
                  <SwiperSlide
                    key={`slide-${section._id}`}
                    tag="li"
                    style={{ listStyle: 'none' }}
                  >
                    <Slide section={section} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </SliderContainer>
        </Column>
      )}
    </Container>
  );
};

export default PropertyInvitationPage;
