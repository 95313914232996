import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { device } from '../../../../style/theme';
import { ConfirmForgotPasswordForm } from '../../../auth/components/forms/confirm-forgot-password-form';
import { ForgotPasswordForm } from '../../../auth/components/forms/forgot-password-form';
import { LoginForm } from '../../../auth/components/forms/login-form';
import {
  Headline2,
  ParagraphText,
} from '../../../common/components/ui/typography';
import { useAppSelector } from '../../../common/hooks';

const Container = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  @media ${device.tablet} {
    max-height: 100%;
  }
`;

const Title = styled(Headline2)``;

const Paragraph = styled(ParagraphText)`
  margin: 16px 0 24px 0;
`;

const SignInForm = (): JSX.Element => {
  const { t } = useTranslation();
  const isForgottenPasswordOpen = useAppSelector(
    (state) => state.auth.isForgottenPasswordOpen
  );
  const isConfirmForgotPasswordModalOpen = useAppSelector(
    (state) => state.auth.isConfirmForgotPasswordModalOpen
  );

  return (
    <Container>
      <Title content={t('property-share.login.title')} />
      <Paragraph content={t('property-share.login.content')} />
      {isConfirmForgotPasswordModalOpen ? (
        <ConfirmForgotPasswordForm />
      ) : isForgottenPasswordOpen ? (
        <ForgotPasswordForm />
      ) : (
        <LoginForm />
      )}
    </Container>
  );
};

export { SignInForm };
