import { useCallback, useMemo, useState } from 'react';

interface IProps {
  isWithBroker: boolean;
}

const useTotalTax = ({ isWithBroker }: IProps) => {
  const [tax, setTax] = useState({
    landTax: 0,
    brokerFeePercent: 0,
    notary: 0,
    landRegisterEntry: 0,
  });
  const onChangeTax = useCallback(
    (name: string, cb?: () => void) => (value: number) => {
      setTax((init) => ({ ...init, [name]: value }));
      if (cb) {
        cb();
      }
    },
    []
  );
  const totalTax = useMemo(() => {
    if (isWithBroker) {
      return (
        Number(tax.landTax) +
        Number(tax.brokerFeePercent) +
        Number(tax.notary) +
        Number(tax.landRegisterEntry)
      );
    }
    return (
      Number(tax.landTax) + Number(tax.notary) + Number(tax.landRegisterEntry)
    );
  }, [
    isWithBroker,
    tax.brokerFeePercent,
    tax.notary,
    tax.landTax,
    tax.landRegisterEntry,
  ]);

  return { onChangeTax, totalTax };
};

export { useTotalTax };
