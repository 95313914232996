import styled from 'styled-components';
import {
  Headline2,
  ParagraphText,
} from '../../../../common/components/ui/typography';
import { device } from '../../../../../style/theme';

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    'icon title'
    'icon description';
  column-gap: 12px;
  row-gap: 8px;
  align-items: center;
  grid-template-columns: auto 1fr;
  padding: 16px 12px;
  border-radius: 3px;
  border: 1px solid #dacebc;
  background-color: rgba(162, 133, 87, 0.2);

  @media ${device.tablet} {
    grid-template-areas:
      'title'
      'description';
    grid-template-columns: 1fr;
    row-gap: 4px;
  }
`;

export const IconWrapper = styled.div`
  grid-area: icon;
`;

export const Title = styled(Headline2)`
  font-size: 20px;
  line-height: 1.6;
  grid-area: title;

  @media ${device.tablet} {
    font-size: 14px;
  }
`;

export const Description = styled(ParagraphText)`
  grid-area: description;

  @media ${device.tablet} {
    font-size: 12px;
  }
`;
