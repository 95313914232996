const getTotalTermOfLoanUntilFullRepayment = (
  dateOfLastInstallment: string
): string => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;

  const lastInstallmentYear = Number(dateOfLastInstallment.split('-')[0]);
  const lastInstallmentMonth = Number(dateOfLastInstallment.split('-')[1]);

  let year = lastInstallmentYear - currentYear;
  let month = lastInstallmentMonth - currentMonth;
  if (lastInstallmentMonth < currentMonth) {
    year += 1;
    month += 12;
  }

  return `${year} Jahre${month ? `, ${month} Monate` : ''}`;
};

export { getTotalTermOfLoanUntilFullRepayment };
