import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  LoginResponse,
  RegistrationBy,
  useConfirmEmailMutation,
  useRequestVerificationCodeMutation,
  UserState,
  UserType,
  useSignInPropertiesMutation,
} from '../../../generated';
import { client } from '../../../services/graphql/graphql';
import { MainButton } from '../../common/components/ui/buttons';
import { GlobalLoader } from '../../common/components/ui/loaders/global-loader';
import { Headline1 } from '../../common/components/ui/typography';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { parseGqlErrors } from '../../common/utils/parse-gql-errors';
import { setRedirect } from '../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { setRedirect as setRedirectV2 } from '../../forms/form-property-valuation-wizard-v2/redux/valuationWizardV2Slice';
import { LoginModal } from '../components/modals/login-modal';
import {
  login,
  setAnonymousUser,
  setChangePasswordTimeout,
  setConfirmedEmail,
  setUserState,
  toggleisEditProfileModalOpen,
  toggleIsLoginModalOpen,
} from '../redux/authSlice';
import { ProfileEditModal } from '../components/modals/profile-edit-modal/profile-edit-modal';
import { useLngHistoryPush } from '../../localization/lng-history-push';
import { useUpdatePropertyMutation } from '../../../services/graphql/enhanced';
import { siteMap } from '../../../routes/site-map';
import { useGetEvaluationWidgetVersion } from '../../forms/form-property-valuation-wizard-v2/hooks/useGetWidgetVersion';

const ErrorContainer = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Headline = styled(Headline1)`
  max-width: 620px;
  margin-bottom: 20px;
  text-align: center;
`;

const ConfirmEmailPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { search } = useLocation();

  const lngHPush = useLngHistoryPush();
  const { isRedesignVersion } = useGetEvaluationWidgetVersion();

  // Get confirmation code from url
  const {
    confirmation_code: confirmationCode,
    sub,
    email,
  } = queryString.parse(search);

  const [didMount, setMount] = useState(false);
  const [shouldLogin, setShouldLogin] = useState(false);
  const [errors, setErrors] = useState<null | string>(null);

  const [confirmEmail] = useConfirmEmailMutation();
  const [signIn] = useSignInPropertiesMutation();
  const [requestVerificationCode] = useRequestVerificationCodeMutation();

  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const userType = useAppSelector((state) => state.auth.user?.userType);
  const isEditProfileModalOpen = useAppSelector(
    (state) => state.auth.isEditProfileModalOpen
  );
  const [updateProperty] = useUpdatePropertyMutation();

  const onLogin = useCallback(() => {
    dispatch(toggleIsLoginModalOpen(true));
  }, [dispatch]);

  const confirmEmailAsync = useCallback(
    async (passedAccessToken?: string) => {
      if (confirmationCode) {
        try {
          // Execute confirmation mutation
          const confirmEmailResponse = await confirmEmail({
            input: {
              _id: sub as string,
              confirmationCode: confirmationCode as string,
              ...(passedAccessToken ? { accessToken: passedAccessToken } : {}),
            },
          }).unwrap();

          const confirmEmailData = confirmEmailResponse?.confirmEmail;
          if (confirmEmailData?.temporaryPassword) {
            const { temporaryPassword, username, hasPendingValuations } =
              confirmEmailData;

            try {
              const loginResponse = await signIn({
                input: {
                  userAuthenticationKey: username,
                  password: temporaryPassword,
                  requestAccessToken: true,
                },
              }).unwrap();

              client.setHeader(
                'Authorization',
                `Bearer ${
                  (loginResponse?.signIn as LoginResponse)?.jwt?.token ?? ''
                }`
              );

              const signInParams = loginResponse.signIn as LoginResponse;
              if (
                signInParams.user.properties &&
                signInParams.user.properties[0]
              ) {
                await updateProperty({
                  input: { _id: signInParams.user.properties[0]._id },
                });
              }

              if (signInParams.user.registrationBy === RegistrationBy.Email) {
                await requestVerificationCode({
                  input: {
                    username: signInParams.user.username,
                    accessToken: signInParams?.jwt?.token ?? '',
                    refreshToken: signInParams?.jwt?.refreshToken ?? '',
                    attributeName: 'phone_number',
                    firstName: signInParams.user.name,
                    lastName: signInParams.user.surname,
                  },
                }).unwrap();

                batch(() => {
                  dispatch(login(loginResponse.signIn as LoginResponse));
                  dispatch(setAnonymousUser(false));
                  dispatch(setUserState(UserState.VerifyPhone));
                  dispatch(toggleisEditProfileModalOpen(true));
                });
              } else {
                // Selected address ?
                batch(() => {
                  dispatch(login(loginResponse.signIn as LoginResponse));
                  dispatch(
                    setUserState(
                      (loginResponse.signIn as LoginResponse).user.userState
                    )
                  );
                  dispatch(setAnonymousUser(false));
                  dispatch(setChangePasswordTimeout(5000));
                });

                if (hasPendingValuations) {
                  batch(() => {
                    if (isRedesignVersion) {
                      dispatch(setRedirectV2(true));
                    } else {
                      dispatch(setRedirect(true));
                    }
                    dispatch(setChangePasswordTimeout(20_000));
                  });
                }

                if (
                  signInParams.user.userType &&
                  signInParams.user.userType[0] === UserType.Buyer
                ) {
                  lngHPush(siteMap.BuyerLandingPage.path);
                } else if (
                  signInParams.user.userType &&
                  signInParams.user.userType[0] === UserType.Seller
                ) {
                  lngHPush(siteMap.SellerLandingPage.path);
                } else if (
                  signInParams.user.userType &&
                  signInParams.user.userType[0] === UserType.Financier
                ) {
                  lngHPush(siteMap.FinancingLandingPage.path);
                } else {
                  lngHPush(siteMap.OwnerLandingPage.path);
                }
                return;
              }
            } catch (error) {
              console.error(error);
            }
          }

          // If user doesn't have valuations or is in case B
          if (!confirmEmailData?.temporaryPassword) {
            dispatch(toggleIsLoginModalOpen(true));
            dispatch(setConfirmedEmail(email?.toString()));
            if (
              confirmEmailData?.userType &&
              confirmEmailData?.userType[0] === UserType.Buyer
            ) {
              lngHPush(siteMap.BuyerLandingPage.path);
            } else if (
              confirmEmailData?.userType &&
              confirmEmailData?.userType[0] === UserType.Seller
            ) {
              lngHPush(siteMap.SellerLandingPage.path);
            } else if (
              confirmEmailData?.userType &&
              confirmEmailData?.userType[0] === UserType.Financier
            ) {
              lngHPush(siteMap.FinancingLandingPage.path);
            } else {
              lngHPush(siteMap.OwnerLandingPage.path);
            }
          }

          // if system has errors with token
          if (confirmEmailData && shouldLogin) {
            setShouldLogin(false);
            lngHPush(siteMap.OwnerLandingPage.path);
          }
        } catch (error) {
          const parsedErrorResponse = parseGqlErrors(error?.message ?? '[]');
          const parsedErrors = parsedErrorResponse
            ?.map((e: Error) => e?.message)
            ?.join(', ');
          if (
            parsedErrors === 'error.user.does.not.exist' ||
            parsedErrors === 'error.user.account.already.confirmed'
          ) {
            lngHPush(siteMap.OwnerLandingPage.path);
            if (!accessToken) {
              // if not logged show login modal
              onLogin();
            }
          } else if (
            parsedErrors === 'error.access.token.expired' ||
            parsedErrors === 'error.invalid.verification.code.provided'
          ) {
            lngHPush(`${siteMap.ExpiredConfirmEmailPage.path}?email=${email}`);
            // setShouldLogin(true);
          } else {
            setErrors(parsedErrors);
          }
        }
      }
    },
    [
      confirmationCode,
      confirmEmail,
      sub,
      shouldLogin,
      signIn,
      updateProperty,
      requestVerificationCode,
      dispatch,
      isRedesignVersion,
      lngHPush,
      accessToken,
      onLogin,
    ]
  );

  const onVerifyPhone = useCallback(() => {
    batch(() => {
      if (isRedesignVersion) {
        dispatch(setRedirectV2(true));
      } else {
        dispatch(setRedirect(true));
      }
      dispatch(setChangePasswordTimeout(10_000));
      dispatch(setUserState(UserState.ChangePassword));
    });

    if (userType && userType[0] === UserType.Buyer) {
      lngHPush(siteMap.BuyerLandingPage.path);
    } else if (userType && userType[0] === UserType.Seller) {
      lngHPush(siteMap.SellerLandingPage.path);
    } else if (userType && userType[0] === UserType.Financier) {
      lngHPush(siteMap.FinancingLandingPage.path);
    } else {
      lngHPush(siteMap.OwnerLandingPage.path);
    }
  }, [dispatch, isRedesignVersion, lngHPush, userType]);

  // Execute email confirmation
  useEffect(() => {
    if (!didMount) {
      confirmEmailAsync();
    }
    setMount(true);
  }, [
    didMount,
    confirmEmail,
    confirmationCode,
    dispatch,
    signIn,
    sub,
    confirmEmailAsync,
  ]);

  useEffect(() => {
    if (shouldLogin && accessToken) {
      confirmEmailAsync(accessToken);
    }
  }, [shouldLogin, accessToken, confirmEmailAsync]);

  if (!confirmationCode || errors) {
    return (
      <ErrorContainer>
        <Headline content={t('error.confirm-page.email.already.verified')} />
        <MainButton onClick={onLogin} label={t('modal.login.submit')} />
      </ErrorContainer>
    );
  }

  return (
    <>
      <LoginModal isOpen={shouldLogin} />
      <ProfileEditModal
        isOpen={isEditProfileModalOpen}
        isRegistrationStep
        onSuccessSubmit={onVerifyPhone}
      />
      <GlobalLoader />
    </>
  );
};

export { ConfirmEmailPage };
