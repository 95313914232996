import { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckCircle } from 'assets/check-circle-bold.svg';
import { ReactComponent as RemoveCircle } from 'assets/streamline-light/interface-essential/form-validation/remove-circle.svg';
import { ThemeContext } from 'styled-components';
import { useParams } from 'react-router-dom';
import {
  Container,
  Task,
  TaskIcon,
  TaskName,
  TasksWrapper,
  Title,
  LoaderContainer,
} from './progress-card-styles';
import { CircularProgressBar } from '../../../common/components/ui/circular-propgress-bar/circular-progress-bar';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { useSellingProgressQuery } from '../../../../generated';
import { buildSellingProgress } from '../../utils/buildSellingProgress';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';

interface IProps {
  pollingInterval?: number;
  isBlurred?: boolean;
}

const ProgressCardBase: FC<IProps> = ({ pollingInterval, isBlurred }) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const { propertyId } = useParams<{ propertyId: string }>();

  const { sellingProgress, isProgressLoading } = useSellingProgressQuery(
    {
      propertyId,
    },
    {
      selectFromResult: ({ data, isLoading, error }) => {
        return {
          sellingProgress: buildSellingProgress(data?.sellingProgress ?? []),
          isProgressLoading: isLoading,
        };
      },
      skip: !propertyId,
      ...(pollingInterval ? { pollingInterval } : {}),
    }
  );

  if (isProgressLoading) {
    return (
      <LoaderContainer>
        <GlobalLoader />
      </LoaderContainer>
    );
  }

  return (
    <Container isBlurred={isBlurred}>
      <Title content={t('seller.progress-card.title')} />
      <CircularProgressBar
        squareSize={120}
        percent={sellingProgress.progress}
      />
      <TasksWrapper>
        {sellingProgress.steps.map((step) => (
          <Task key={step.title}>
            <TaskIcon
              icon={step.isCompleted ? CheckCircle : RemoveCircle}
              color={
                step.isCompleted
                  ? themeContext.white
                  : hexToRGB(themeContext.blue, 0.5)
              }
              width={16}
              height={16}
            />
            <TaskName content={t(step.title)} />
          </Task>
        ))}
      </TasksWrapper>
    </Container>
  );
};

const ProgressCard = memo(ProgressCardBase);

export { ProgressCard };
