import { FC } from 'react';

import { useFirstStepLogic } from '../../../../../hooks/useStepsLogic';
import { InputsContainer } from '../../../../../../components/fullscreen-modal/inputs-container/inputs-container';

const FirstStep: FC = () => {
  const { inputs } = useFirstStepLogic();

  return (
    <>
      <InputsContainer inputs={inputs} />
    </>
  );
};

export { FirstStep };
