import { useTranslation } from 'react-i18next';
import { ReactNode, useMemo } from 'react';
import {
  ApprovedTag,
  ChangesRequestedTag,
  PreparationTag,
  WaitingApprovalTag,
} from '../components/expose/expose-card/expose-card-styles';
import { OnOfficeExposeStatus } from '../../../generated';

export const useGetExposeStateTag = (
  state: OnOfficeExposeStatus,
  isApprovedViaOnOffice?: boolean | null,
  approvedAt?: string
): ReactNode | null => {
  const { t } = useTranslation();

  const approvedTagText = useMemo(() => {
    if (approvedAt) {
      const date = new Date(approvedAt)
        .toLocaleDateString('en-GB')
        ?.replace(/\//g, '.');
      const time = new Date(approvedAt)
        .toLocaleTimeString('en-GB')
        ?.slice(0, -3);
      return t('seller.expose-card.approved-tag.with-timestamp', {
        date,
        time,
      });
    }

    if (isApprovedViaOnOffice) {
      return t('seller.expose-card.approved-tag.approved-via-on-office');
    }

    return t('seller.expose-card.approved-tag');
  }, [approvedAt, isApprovedViaOnOffice, t]);

  switch (state) {
    case OnOfficeExposeStatus.Preparation:
      return (
        <PreparationTag>
          {t('seller.expose-card.preparation-tag')}
        </PreparationTag>
      );
    case OnOfficeExposeStatus.WaitingApproval:
      return (
        <WaitingApprovalTag>
          {t('seller.expose-card.waiting-approval-tag')}
        </WaitingApprovalTag>
      );
    case OnOfficeExposeStatus.ChangesRequested:
      return (
        <ChangesRequestedTag>
          {t('seller.expose-card.changes-requested-tag')}
        </ChangesRequestedTag>
      );
    case OnOfficeExposeStatus.Approved:
      return <ApprovedTag>{approvedTagText}</ApprovedTag>;
    default:
      return null;
  }
};
