import 'mapbox-gl/dist/mapbox-gl.css';

import mapPin from 'assets/svg/map-pin.svg';
import { FC, memo, useEffect, useRef, useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';

import { MapLoadEvent } from 'react-map-gl/src/mapbox/mapbox';
import { GlobalLoader } from '../../../../common/components/ui/loaders/global-loader';
import { IMapSettings } from '../../../../map/interfaces';
import { Container, MarkerAddress, MarkerImg } from './modal-map-styles';

interface IProps {
  addressMarker?: string;
  viewport: Record<string, unknown>;
  settings: IMapSettings;
}

const ModalMap: FC<IProps> = ({ addressMarker, viewport, settings }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { isMapMarker } = settings;

  const { longitude, latitude } = viewport;

  const [innerViewport, setInnerViewport] =
    useState<Record<string, unknown>>(viewport);

  const onViewportChange = (newViewport: Record<string, unknown>) => {
    setInnerViewport(newViewport);
  };

  useEffect(() => {
    setInnerViewport(viewport);
  }, [viewport]);

  const changeMapLanguage = (e: MapLoadEvent) => {
    e?.target.getStyle()?.layers?.forEach((layer) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if ('layout' in layer && layer.layout['text-field']) {
        e.target.setLayoutProperty(layer.id, 'text-field', ['get', 'name_de']);
      }
    });
    setTimeout(() => setIsLoading(false), 300);
  };

  const mapboxContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapboxContainerRef.current) {
      return () => {};
    }

    const mapContainer = mapboxContainerRef.current;

    const wheelHandler = (e: Event) => {
      const wheelEvent = e as WheelEvent;

      if (wheelEvent.ctrlKey || wheelEvent.metaKey || wheelEvent.altKey) {
        return;
      }

      e.stopPropagation();
    };

    mapContainer.addEventListener('wheel', wheelHandler, true);

    return () => {
      mapContainer.removeEventListener('wheel', wheelHandler);
    };
  });

  return (
    <Container ref={mapboxContainerRef}>
      {isLoading && <GlobalLoader size={60} />}
      <ReactMapGL
        width="100%"
        height="184px"
        {...innerViewport}
        {...settings}
        getCursor={() => 'default'}
        onViewportChange={onViewportChange}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY ?? ''}
        mapStyle="mapbox://styles/mapbox/light-v10"
        asyncRender
        style={{ color: 'transparent', display: isLoading ? 'none' : 'block' }}
        mapOptions={{
          locale: {
            'Map.Title': 'Map',
          },
        }}
        onLoad={changeMapLanguage}
      >
        {isMapMarker && (
          <Marker
            longitude={(longitude as number) || 0}
            latitude={(latitude as number) || 0}
          >
            {addressMarker && <MarkerAddress>{addressMarker}</MarkerAddress>}
            <MarkerImg src={mapPin} />
          </Marker>
        )}
      </ReactMapGL>
    </Container>
  );
};

export { ModalMap };
