import styled from 'styled-components';
import { device } from '../../../../../style/theme';

interface IProps {
  content: string;
}

const Container = styled.h3`
  margin: 0;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
  }
`;

const Headline2 = ({ content, ...rest }: IProps): JSX.Element => (
  <Container {...rest}>{content}</Container>
);

export { Headline2 };
