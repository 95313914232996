import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form';
import { MODAL_ANIMATION_DURATION } from '../../../common/components/modal/modal';
import { useAppSelector } from '../../../common/hooks';
import {
  closeSPCAction,
  prevStepAction,
  resetSPC,
  toggleLoginForm,
  toggleRegisterForm,
  updateTemporarySearchProfileToClaim,
} from '../redux/searchProfileSlice';

type TProps = {
  reset?: () => void;
  watch: UseFormWatch<FieldValues>;
};

type TReturn = {
  onBackClick: () => void;
  onCloseClick: () => void;
};

const useModalButtonsHandler = ({ reset, watch }: TProps): TReturn => {
  const dispatch = useDispatch();
  const { activeStep, isLoginFormOpen, isRegisterFormOpen, userInput } =
    useAppSelector((state) => state.searchProfile);
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const onCloseClick = useCallback(() => {
    dispatch(closeSPCAction());

    if (isIframe && document.fullscreenElement && document.exitFullscreen) {
      document.exitFullscreen();
    }
    if (isIframe) {
      return;
    }
    setTimeout(() => {
      dispatch(resetSPC());
      if (reset) {
        reset();
      }
    }, MODAL_ANIMATION_DURATION);
  }, [dispatch, isIframe, reset]);

  const onBackClick = useCallback(() => {
    if (activeStep === 0) {
      setTimeout(() => dispatch(resetSPC()), MODAL_ANIMATION_DURATION);
      dispatch(closeSPCAction());
      return;
    }

    if (activeStep === 2) {
      if (isLoginFormOpen || isRegisterFormOpen) {
        dispatch(toggleRegisterForm(false));
        dispatch(toggleLoginForm(false));

        return;
      }

      dispatch(updateTemporarySearchProfileToClaim(null));
    }

    dispatch(prevStepAction());
  }, [
    activeStep,
    dispatch,
    isIframe,
    isLoginFormOpen,
    isRegisterFormOpen,
    userInput?.code,
    watch,
  ]);

  return {
    onBackClick,
    onCloseClick,
  };
};

export { useModalButtonsHandler };
