import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  SectionContainer,
  Text,
} from './financing-variants-content-table-item-styles';

interface ISection {
  label: string;
  keyValue: string;
  subName?: string;
  values?: string[];
  deleteId?: number;
  isBold?: boolean;
  isFirstSection?: boolean;
  isLastSection?: boolean;
  subfields?: {
    label: string;
    keyValue: string;
    subName?: string;
    values?: string[];
  }[];
}

const Section: FC<ISection> = ({
  label,
  subName,
  isBold,
  subfields,
  values,
  deleteId,
  isFirstSection,
  isLastSection,
}) => {
  const { t } = useTranslation();

  return (
    <SectionContainer>
      <div>
        <Text
          isBold={isBold}
          deleteId={deleteId}
          isFirstSection={isFirstSection}
          isLastSection={isLastSection}
        >
          {t(label)}
          {subName ? <> {subName}</> : ''}:
        </Text>
        {values ? (
          values.map((value, i) => (
            <Text
              key={`${value}${i}`}
              isBold={isBold}
              isWillBeDeleted={i === deleteId}
              deleteId={deleteId}
              isFirstSection={isFirstSection}
              isLastSection={isLastSection}
            >
              {value}
            </Text>
          ))
        ) : (
          <Text> </Text>
        )}
      </div>
      {!!subfields && (
        <div>
          {subfields.map((item) => (
            <div key={item.label}>
              <div>
                <Text deleteId={deleteId} isLastSection={isLastSection}>
                  {t(item.label)}
                  {item.subName ? ` (${item.subName})` : ''}:
                </Text>
                {item.values ? (
                  item.values.map((value, i) => (
                    <Text
                      key={`${value}${i}`}
                      isWillBeDeleted={i === deleteId}
                      deleteId={deleteId}
                    >
                      {value}
                    </Text>
                  ))
                ) : (
                  <Text> </Text>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </SectionContainer>
  );
};

interface IProps {
  section: ISection[];
}

const FinancingVariantsContentTableItem: FC<IProps> = ({ section }) => {
  return (
    <Container>
      {section.map((item, i) => (
        <Section
          key={item.keyValue}
          isFirstSection={i === 0}
          isLastSection={section.length - 1 === i}
          {...item}
        />
      ))}
    </Container>
  );
};

export { FinancingVariantsContentTableItem };
