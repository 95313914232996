import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { PurchaseIntentType } from '../../../../generated';
import { RootState } from '../../../../store';

const useCanContinue = (): boolean => {
  const [
    companyName,
    registrationNumber,
    headOfficeStreet,
    headOfficeBuildingNumber,
    headOfficePostalCode,
    headOfficeCity,
    name,
    surname,
    postalCode,
    street,
    buildingNumber,
    city,
    email,
    phone,
    otherPersons,
    confirmPrice,
    price,
  ] = useWatch({
    name: [
      'companyName',
      'registrationNumber',
      'headOfficeStreet',
      'headOfficeBuildingNumber',
      'headOfficePostalCode',
      'headOfficeCity',
      'name',
      'surname',
      'postalCode',
      'street',
      'buildingNumber',
      'city',
      'email',
      'phone',
      'otherPersons',
      'confirmPrice',
      'price',
    ],
  });

  const { activeStep, structure, type } = useSelector(
    (state: RootState) => state.purchaseIntend
  );

  const stepsCount = structure[type].length;
  const index = activeStep - 1;

  if (type === PurchaseIntentType.Physical) {
    if (activeStep === 0) {
      return !!(name && surname && postalCode && city && email && phone);
    }

    if (activeStep > 0 && activeStep < stepsCount - 1) {
      const additionalPerson = otherPersons[index];
      return !!(
        additionalPerson.name &&
        additionalPerson.surname &&
        additionalPerson.postalCode &&
        additionalPerson.city &&
        additionalPerson.email &&
        additionalPerson.phone
      );
    }

    if (activeStep === stepsCount - 1) {
      return !!(confirmPrice && price);
    }

    return false;
  }

  if (type === PurchaseIntentType.Legal) {
    if (activeStep === 0) {
      return !!(
        companyName &&
        registrationNumber &&
        headOfficeStreet &&
        headOfficeBuildingNumber &&
        headOfficePostalCode &&
        headOfficeCity
      );
    }

    if (activeStep === 1) {
      return !!(
        name &&
        surname &&
        street &&
        buildingNumber &&
        postalCode &&
        city &&
        email &&
        phone
      );
    }

    if (activeStep > 1 && activeStep < stepsCount - 1) {
      const additionalPerson = otherPersons[index - 1];
      return !!(
        additionalPerson.name &&
        additionalPerson.surname &&
        additionalPerson.street &&
        additionalPerson.buildingNumber &&
        additionalPerson.postalCode &&
        additionalPerson.city &&
        additionalPerson.email &&
        additionalPerson.phone
      );
    }

    if (activeStep === stepsCount - 1) {
      return !!(confirmPrice && price);
    }

    return false;
  }

  return true;
};

export { useCanContinue };
