import { FC } from 'react';
import {
  CustomerAppraisals,
  FinancingLpSection,
  useReadCustomerAppraisalQuery,
} from '../../../../../generated';
import {
  BannerBox,
  BannerImage,
  Container,
  ExpandedText,
  TextBox,
  Title,
  TitleMobile,
} from './customer-appraisal-styles';

interface IProps {
  section: FinancingLpSection;
}

interface CustomerAppraisalData {
  data: {
    readCustomerAppraisal: CustomerAppraisals;
  };
  isLoading: boolean;
}

const CustomerAppraisal: FC<IProps> = ({ section: { recordIds } }) => {
  const { data, isLoading } =
    useReadCustomerAppraisalQuery<CustomerAppraisalData>({
      id: recordIds?.[0],
    });

  if (isLoading || !data || !data.readCustomerAppraisal) return null;

  const {
    readCustomerAppraisal: {
      bannerLink,
      expandedText,
      imageAlt,
      imageUrl,
      title,
    },
  } = data;

  return (
    <Container>
      <TitleMobile>{title}</TitleMobile>
      <BannerBox target={'_blank'} href={bannerLink || ''}>
        <BannerImage src={imageUrl || ''} alt={imageAlt || ''} />
      </BannerBox>
      <TextBox>
        <Title>{title}</Title>
        <ExpandedText>{expandedText}</ExpandedText>
      </TextBox>
    </Container>
  );
};

export default CustomerAppraisal;
