import { FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoaderContainer } from 'modules/seller/pages/seller-properties-page/seller-properties-page-styles';
import { ValuationLoader } from 'modules/forms/components/common/loader';

import { device } from '../../../../style/theme';
import { ProgressCard } from '../progress-card/progress-card';
import { ServicesContainer } from '../services/services-container/services-container';
import {
  Broker,
  useGetPropertyBrokerQuery,
  useGetPropertyServicesQuery,
  useDisabledShopServicesQuery,
} from '../../../../generated';
import { TeamContactCard } from '../appointment-card/team-contact-card';
import { GlobalError } from '../../../common/components/form/error/global-error';
import Banner from '../banner/banner';
import { ReactComponent as ReadHuman } from '../../../../assets/streamline-light/school-learning/library-reading/read-human.svg';
import BannerImage from '../../../../assets/broker-view-banner.webp';
import { useAppSelector } from '../../../common/hooks';
import { PropertyLockedMessage } from '../property-locked-message/property-locked-message';

const Container = styled.div`
  position: relative;
  display: flex;
  @media ${device.tablet} {
    flex-direction: column;
    padding: 16px;
  }
`;

const FirstColumn = styled.div<{ isBlurred?: boolean }>`
  flex: 1.72;
  max-width: 688px;
  margin-right: 16px;
  ${(props) => props.isBlurred && 'filter: blur(6px)'};

  @media ${device.tablet} {
    margin-bottom: 16px;
    max-width: 100%;
    width: 100%;
  }
`;

const SecondColumn = styled.div`
  flex: 1;
  max-width: 400px;
  @media ${device.tablet} {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

interface IProps {
  currentValuation?: number | null;
  isLocked?: boolean;
  isSimulation: boolean;
  disabledPropertyServices: string[] | null;
}

const ServicesBase: FC<IProps> = ({
  currentValuation,
  isLocked,
  isSimulation,
  disabledPropertyServices,
}): JSX.Element => {
  const { t } = useTranslation();
  const { propertyId } = useParams<{ propertyId: string }>();

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const {
    data,
    isLoading,
    error: errors,
  } = useGetPropertyServicesQuery(
    {
      propertyId,
    },
    { skip: isSimulation, pollingInterval: 3000 }
  );

  const { propertyBroker, isBrokerLoading, getBrokerError } =
    useGetPropertyBrokerQuery(
      {
        propertyId,
      },
      {
        selectFromResult: ({
          data: result,
          isLoading: brokerLoading,
          error,
        }) => ({
          propertyBroker: result?.getPropertyBroker as Broker,
          isBrokerLoading: brokerLoading,
          getBrokerError: error,
        }),
        skip: isSimulation,
      }
    );

  const {
    disabledShopServices,
    isServicesLoading,
    errors: shopServicesError,
  } = useDisabledShopServicesQuery(
    {
      propertyId,
    },
    {
      selectFromResult: ({ data: result, isLoading: isLoad, error }) => {
        return {
          disabledShopServices: result?.disabledShopServices,
          errors: error,
          isServicesLoading: isLoad,
        };
      },
      skip: !propertyBroker?.id || isSimulation || !propertyId,
    }
  );

  const onEmailBroker = useCallback(() => {
    if (propertyBroker?.email) {
      window.open(`mailto: ${propertyBroker.email}`, '_self');
    }
  }, [propertyBroker?.email]);

  return (
    <>
      {isBrokerView && (
        <Banner
          icon={ReadHuman}
          image={BannerImage}
          title={t('seller-portal.broker-view.banner.title')}
          description={t('seller-portal.broker-view.banner.description')}
        />
      )}
      <Container>
        {isLocked && <PropertyLockedMessage onEmailBroker={onEmailBroker} />}
        <FirstColumn isBlurred={isLocked}>
          {errors?.message && (
            <GlobalError
              title={t(errors?.message?.split(':')[0].toLowerCase())}
            />
          )}
          {getBrokerError?.message && (
            <GlobalError
              title={t(getBrokerError?.message?.split(':')[0].toLowerCase())}
            />
          )}
          {shopServicesError?.message && (
            <GlobalError
              title={t(shopServicesError?.message?.split(':')[0].toLowerCase())}
            />
          )}
          {isLoading || isBrokerLoading || isServicesLoading ? (
            <LoaderContainer isLoadingMore>
              <ValuationLoader maxWidth="500px" />
            </LoaderContainer>
          ) : (
            <ServicesContainer
              isLocked={isLocked}
              currentValuation={currentValuation}
              preloadedServices={data?.getPropertyServices}
              isSimulation={isSimulation}
              disabledShopServices={disabledShopServices}
              disabledPropertyServices={disabledPropertyServices}
            />
          )}
        </FirstColumn>
        <SecondColumn>
          <ProgressCard pollingInterval={5000} isBlurred={isLocked} />
          <TeamContactCard />
        </SecondColumn>
      </Container>
    </>
  );
};

const Services = memo(ServicesBase);

export { Services };
