import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ChangeEvent } from 'react';
import { hexToRGB } from '../../../utils/hex-to-rgb';

export interface IInputBox {
  className?: string;
  id?: string;
  value?: string | number | null;
  name: string;
  type: 'text' | 'number';
  label: string;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const OuterContainer = styled.div<{ $hasError?: boolean }>`
  width: 100%;
  border-radius: 3px;
  background-color: ${({ theme, $hasError }) =>
    $hasError ? theme.paleRed : theme.greyBackground};
  padding: 4px 12px;
`;

const Label = styled.label<{ $hasError?: boolean }>`
  font-family: Roboto;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme, $hasError }) =>
    $hasError ? hexToRGB(theme.red, 0.5) : hexToRGB(theme.blue, 0.5)};
`;

const InputField = styled.input`
  display: block;
  background: transparent;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  width: 100%;
  &:focus {
    outline: none;
    border: none;
  }
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
  &::placeholder {
    color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  }
`;

const InputBox = ({
  id,
  name,
  label,
  type,
  value,
  placeholder,
  onChange,
}: IInputBox): JSX.Element => {
  const { t } = useTranslation();

  return (
    <OuterContainer>
      <Label htmlFor={id}>{t(label)}</Label>

      <InputField
        id={id}
        type={type}
        name={name}
        value={value as string | number}
        placeholder={t(placeholder ?? '')}
        onChange={onChange}
      />
    </OuterContainer>
  );
};

export { InputBox };
