import { FC, useState } from 'react';
import { ReactComponent as Calendar } from 'assets/streamline-light/interface-essential/date-calendar/calendar.svg';
import { format } from 'date-fns';
import {
  FinancingLpSection,
  FinancingType,
  InterestRatesTables,
  useReadEuropaceInterestRatesQuery,
  useReadInterestRatesTableQuery,
} from '../../../../../generated';
import { useOpenFinancing } from '../../../../forms/form-financing/hooks/callback-hooks/useOpenFinancing';
import OurRepresentativeExampleModal from '../our-representative-example-modal/our-representative-example-modal';
import {
  Container,
  Title,
  Subtitle,
  Table,
  TableRow,
  TableCell,
  DateBox,
  RowLabel,
  TableDetails,
  HintText,
  TimeStampBox,
  TimeStampLabel,
  GhostBtn,
  GhostBtnBox,
  CTAButton,
  TableWrapper,
  SkeletonItem,
} from './interest-rates-table-styles';
import { numberFormat } from '../../../../financing/utils/numberFormat';

interface IProps {
  section: FinancingLpSection;
  ourRepresentativeSection?: FinancingLpSection;
}

interface InterestRatesTableData {
  data: {
    readInterestRatesTable: InterestRatesTables;
  };
  isLoading: boolean;
}

const InterestRatesTable: FC<IProps> = ({
  section: { recordIds },
  ourRepresentativeSection,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { data: tableData, isLoading: isLoadingTableData } =
    useReadEuropaceInterestRatesQuery();

  const europaceInterestRates = tableData?.readEuropaceInterestRates;
  const mockRates = [
    { debitRateCommitment: 5 },
    { debitRateCommitment: 10 },
    { debitRateCommitment: 15 },
    { debitRateCommitment: 20 },
    { debitRateCommitment: 25 },
    { debitRateCommitment: 30 },
  ];
  const interestRates = europaceInterestRates ?? mockRates;

  const { data, isLoading } =
    useReadInterestRatesTableQuery<InterestRatesTableData>({
      id: recordIds?.[0],
    });

  const { openFinancing } = useOpenFinancing({});

  if (isLoading || !data) return null;

  const {
    readInterestRatesTable: {
      ghostButtonLabel,
      mainCTALabel,
      row1Label,
      row2Label,
      row3Label,
      subtitle,
      tableHintText,
      timestampLabel,
      title,
    },
  } = data;

  const date = new Date();

  return (
    <>
      <Container>
        <Title>{title ?? ''}</Title>
        <Subtitle>{subtitle ?? ''}</Subtitle>
        <TableWrapper>
          <Table>
            <TableRow>
              <TableCell dark bold>
                <RowLabel>{row1Label ?? ''}</RowLabel>
              </TableCell>
              {interestRates.map((item) => (
                <TableCell
                  dark
                  bold
                  direction={'column'}
                  key={item.debitRateCommitment}
                >
                  <Calendar style={{ width: 22, height: 22 }} />
                  <DateBox>{item.debitRateCommitment} Jahre</DateBox>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell bold>
                <RowLabel>{row3Label ?? ''}</RowLabel>
              </TableCell>
              {europaceInterestRates
                ? europaceInterestRates.map((item) => (
                    <TableCell key={item.debitRateCommitment}>
                      {numberFormat(item?.propositions[0].shouldInterest, true)}{' '}
                      % p.a.
                    </TableCell>
                  ))
                : mockRates.map((item) => (
                    <TableCell shaded key={item.debitRateCommitment}>
                      <SkeletonItem />
                    </TableCell>
                  ))}
            </TableRow>
            <TableRow>
              <TableCell shaded bold>
                <RowLabel>{row2Label ?? ''}</RowLabel>
              </TableCell>
              {europaceInterestRates
                ? europaceInterestRates.map((item) => (
                    <TableCell shaded key={item.debitRateCommitment}>
                      {numberFormat(
                        item?.propositions[0].effectiveInterest,
                        true
                      )}{' '}
                      % p.a.
                    </TableCell>
                  ))
                : mockRates.map((item) => (
                    <TableCell shaded key={item.debitRateCommitment}>
                      <SkeletonItem />
                    </TableCell>
                  ))}
            </TableRow>
          </Table>
        </TableWrapper>

        <TableDetails>
          <HintText>{tableHintText || ''}</HintText>
          <TimeStampBox>
            <TimeStampLabel>{timestampLabel || ''}</TimeStampLabel>
            {format(date, 'DD.MM.YYYY')} - {format(date, 'HH:mm')} Uhr
          </TimeStampBox>
        </TableDetails>

        <GhostBtnBox>
          <GhostBtn onClick={() => setIsModalOpen(true)}>
            {ghostButtonLabel || ''}
          </GhostBtn>
        </GhostBtnBox>

        <CTAButton
          onClick={() => openFinancing(FinancingType.Financing)}
          label={mainCTALabel || ''}
        />
      </Container>

      {ourRepresentativeSection && europaceInterestRates && (
        <OurRepresentativeExampleModal
          purchasePrice={450_000}
          equityCapital={150_000}
          ourRepresentativeSection={ourRepresentativeSection}
          isOpen={isModalOpen}
          europaceInterestRates={europaceInterestRates[1]}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default InterestRatesTable;
