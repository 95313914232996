import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { HeroSlider } from '../../../common/components/ui/hero-slider/hero-slider';
import { Card } from '../../../common/components/ui/card';
import { Hero } from '../../../common/components/ui/hero';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { useReadOverviewLandingPageQuery } from '../../../../generated';
import { useAppSelector } from '../../../common/hooks';
import useGetLocalization from '../../../localization/get-localization';
import { siteMap } from '../../../../routes/site-map';
import { CTAButtons } from '../../components/overview-page/cta-buttons/cta-buttons';
import { OverviewLandingPageResponse } from '../../interface';
import { HelmetContainer } from '../../components/overview-page/helmet-container';
import { Sections } from '../../components/overview-page/sections/sections';

const OverviewLandingPage: FC = () => {
  const { t } = useTranslation();
  const lang = useGetLocalization();

  const user = useAppSelector((state) => state.auth.user);

  const isCHSite = lang === 'de-ch';
  const isATSite = lang === 'de-at';

  // Fetch landing page details
  const { data: overviewLPData, isLoading: isLandingPageLoading } =
    useReadOverviewLandingPageQuery<OverviewLandingPageResponse>({
      locale: lang,
    });

  if (isLandingPageLoading) {
    return <GlobalLoader />;
  }

  if (!overviewLPData || isCHSite || isATSite || user) {
    return (
      <Redirect
        to={siteMap.OwnerLandingPage.pathWithLang.replace(':lang', lang)}
      />
    );
  }

  const {
    readOverviewLandingPage: { imageAlt, imageUrl, subtitle, text, title },
  } = overviewLPData;

  return (
    <>
      <HelmetContainer />
      <Hero imgSrc={imageUrl ?? ''} imgAlt={imageAlt ?? ''} isWithoutMargin>
        <HeroSlider showAddressBar={false}>
          <Card
            header={t(
              title ?? 'overview-landing-page.hero-slider.first-card.header'
            ).toUpperCase()}
            titleFirstLine={t(
              subtitle ?? 'overview-landing-page.hero-slider.first-card.title'
            )}
            content={
              text ?? t('overview-landing-page.hero-slider.first-card.content')
            }
          />
        </HeroSlider>
        <CTAButtons />
      </Hero>
      <Sections />
    </>
  );
};

export { OverviewLandingPage };
