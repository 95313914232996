import { apartmentBoxes, houseBoxes } from '../constants';

type TReturn = {
  label: string;
};

export const propertySubtypeNormalizer = (propertySubCode: string): TReturn => {
  const subtypes = [...houseBoxes, ...apartmentBoxes];

  const subtype = subtypes.find((item) => item.value === propertySubCode);

  return { label: subtype?.title || '' };
};
