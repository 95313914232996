import styled from 'styled-components';
import { ParagraphText } from '../../../common/components/ui/typography';
import { device } from '../../../../style/theme';

export const Box = styled.div<{
  isActive?: boolean;
  iconPosition?: 'top' | 'left';
  isDark?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) =>
    props.iconPosition === 'left' ? 'row' : 'column'};
  flex: 1;
  align-items: center;
  padding: ${(props) => (props.iconPosition === 'left' ? '16px' : '24px 12px')};
  border-radius: 3px;
  border: 2px solid
    ${(props) => (props.isActive ? props.theme.blue : props.theme.borderColor)};
  ${({ isDark }) =>
    isDark &&
    `
     border: 2px solid #DFD6C8 !important;
     background-color: #F0EDE9 !important;

     &:hover {
      background-color: #EEE6DB !important;
      border: 2px solid #D4C4AD !important;
     }
  `}
  cursor: pointer;

  gap: ${(props) => (props.iconPosition === 'left' ? '16px' : 0)};

  &:hover {
    background-color: ${(props) => props.theme.greyBackground};
  }

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div``;

export const Title = styled(ParagraphText)<{
  isActive?: boolean;
  textAlign: string;
}>`
  font-size: 12px;
  font-weight: 900;
  line-height: 1.67;
  //opacity: ${(props) => (props.isActive ? 1 : 0.6)};
  opacity: 1;
  text-align: ${(props) => props.textAlign};

  @media ${device.tablet} {
    text-align: center;
  }
`;

export const Description = styled(ParagraphText)<{ textAlign: string }>`
  font-size: 12px;
  line-height: 1.67;
  opacity: 1;
  margin-top: 4px;
  text-align: ${(props) => props.textAlign};

  @media ${device.tablet} {
    text-align: center;
  }
`;

export const RadioButton = styled.input`
  display: none;
`;
