import { useEffect } from 'react';
import { batch } from 'react-redux';
import { FlyToInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';

import {
  changeViewport,
  setMarkers,
  setSettings,
} from '../../map/redux/mapSlice';
import { defaultMapSettings } from '../../map/constants';
import { Germany } from '../../map/constants/points-of-interest';
import { useAppDispatch } from '../../common/hooks';
import { Coordinates } from '../../../generated';

interface IProps {
  coordinates?: Coordinates;
}

const useFinancingPortalMap = ({ coordinates }: IProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(
        setSettings({
          ...defaultMapSettings,
        })
      );
      dispatch(
        changeViewport({
          ...Germany,
          zoom: 7,
          transitionDuration: 1000,
        })
      );
      dispatch(setMarkers([]));
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (coordinates) {
        dispatch(
          changeViewport({
            longitude: coordinates.longitude - 0.005,
            latitude: coordinates.latitude - 0.01,
            zoom: 12,
            transitionDuration: 'auto',
            transitionInterpolator: new FlyToInterpolator({
              speed: 1.5,
            }),
            transitionEasing: easeCubic,
          })
        );
        dispatch(
          setMarkers([
            {
              longitude: coordinates?.longitude || 0,
              latitude: coordinates?.latitude || 0,
            },
          ])
        );
      }
    }, 0);
  }, [coordinates, dispatch]);
};

export { useFinancingPortalMap };
