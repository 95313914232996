import styled from 'styled-components';
import { ParagraphText, ParagraphTitle } from '../typography';

export const Container = styled.div`
  border-radius: 3px;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.lightBlue};
  background-color: ${(props) => props.theme.greyBackground};
`;

export const Title = styled(ParagraphTitle)`
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 24px;
`;

export const List = styled.ul`
  margin-top: 8px;
  list-style-position: inside;
`;

export const ListItem = styled.li`
  margin: 0;
  font-family: 'Roboto';
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  font-weight: 400;
  line-height: 24px;
  text-align: start;

  &:not(:first-of-type) {
    margin-top: 4px;
  }

  // &::before {
  //   content: '\\2022'; // CSS Code for a bullet
  //   color: ${(props) => props.theme.blue}; /* Change the color */
  //   display: inline-block; // Needed to add space between the bullet and the text
  //   width: 1em; /* Also needed for space (tweak if needed) */
  //   margin-left: 18px; /* Also needed for space (tweak if needed) */
  // }
`;
