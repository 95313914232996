import { Path, Svg } from '@react-pdf/renderer';

import { defaultTheme } from '../../../../style/theme';

const OverallIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'20px'} height={'20px'}>
      <Path
        d={
          'M5.793 13.711V22.1a.7.7 0 0 0 .731.658h10.952a.7.7 0 0 0 .731-.658v-8.389'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={
          'M3.501 15.982 12 7.501l8.499 8.481M9.892 17.286h3.877v5.467H9.892zM12 1.247v2.342M.5 12.087h2.343m.529-7.251 2.085 1.733M23.5 12.087h-2.343m-.529-7.251-2.085 1.733'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { OverallIcon };
