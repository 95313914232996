import { FC } from 'react';
import { useEighthStepLogic } from '../../../../../hooks/useStepsLogic';
import { InputsContainer } from '../../../../../../components/fullscreen-modal/inputs-container/inputs-container';
import { Container } from './eighth-step-styles';
import { IValuationForm } from '../../../../../../form-property-valuation-wizard/interface';
import { BackButtonContainer } from '../../../../common/back-button-container';

interface IProps {
  onSubmit?: (formData: IValuationForm, ownerId?: string) => Promise<void>;
  lastStepCallback?(): void;
}

const EighthStep: FC<IProps> = ({ onSubmit, lastStepCallback }) => {
  const { inputs } = useEighthStepLogic({
    onSubmit,
    lastStepCallback,
  });

  return (
    <Container>
      <InputsContainer inputs={inputs} />
      <BackButtonContainer marginTop={0} />
    </Container>
  );
};

export { EighthStep };
