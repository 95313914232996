import styled from 'styled-components';

export const Container = styled.div``;

export const InfoContainer = styled.div`
  padding-top: 12px;
  padding-bottom: 16px;
`;

export const TourWindow = styled.iframe`
  width: 100%;
  height: 320px;
`;
