import { ReactComponent as AddCircle } from 'assets/streamline-light/interface-essential/remove-add/add-circle.svg';
import { ReactComponent as Cog1 } from 'assets/streamline-light/interface-essential/settings/cog-1.svg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PropertyInvitation } from '../../../constants';
import { device } from '../../../../../style/theme';

const DropdownWrapper = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  z-index: 9999;
  @media ${device.tablet} {
    top: unset;
    bottom: calc(100% + 8px);
  }
`;

const Dropdown = styled.div`
  background: ${({ theme }) => theme.white};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px ${({ theme }) => theme.ctaDisabledBackground};
  padding: 8px 6px;
  border-radius: 3px;
  width: 256px;
`;

const DropdownOption = styled.div`
  display: block;
  color: ${({ theme }) => theme.blue};
  opacity: 0.5;
  cursor: pointer;
  padding: 4px 6px;
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const OptionIconWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  margin-right: 8px;
  line-height: 0;
  & svg {
    width: 100%;
    height: auto;
  }
`;

const OptionText = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
`;

const Triangle = styled.div`
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent ${({ theme }) => theme.white}
    transparent;
  margin: 0 8px -1px auto;
  @media ${device.tablet} {
    top: 86px;
    margin-right: min(32px, 10%);
    transform: rotate(180deg);
  }
`;

interface ISharePropertyDropdownMenu {
  onOptionClick: (option: Partial<PropertyInvitation>) => () => void;
}

const SharePropertyDropdownMenu = ({
  onOptionClick,
}: ISharePropertyDropdownMenu): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DropdownWrapper>
      <Triangle />
      <Dropdown>
        <DropdownOption onClick={onOptionClick(PropertyInvitation.Create)}>
          <OptionIconWrapper>
            <AddCircle />
          </OptionIconWrapper>

          <OptionText>{t('share.property.dropdown.invite')}</OptionText>
        </DropdownOption>

        <DropdownOption onClick={onOptionClick(PropertyInvitation.Edit)}>
          <OptionIconWrapper>
            <Cog1 />
          </OptionIconWrapper>

          <OptionText>{t('share.property.dropdown.edit')}</OptionText>
        </DropdownOption>
      </Dropdown>
    </DropdownWrapper>
  );
};

export { SharePropertyDropdownMenu };
