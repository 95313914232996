import { Route, RouteProps } from 'react-router';
import { logout, setBrokerView } from '../modules/auth/redux/authSlice';
import { useAppDispatch, useAppSelector } from '../modules/common/hooks';
import { isTokenExpired } from '../modules/common/utils/is-token-expired';
import { LngRedirect } from '../modules/localization/lng-redirect';
import { siteMap } from './site-map';
import { useGetPortalType } from '../modules/landing/hooks/useGetPortalType';
import { useDownloadExposePageLogic } from './hooks/useDownloadExposePageLogic';
import { useSearchProfilePageLogic } from './hooks/useSearchProfilePageLogic';
import { useConfig } from '../config/useConfig';
import { OnOfficeMarketplaceReferrer } from '../modules/seller/constants';

export type ProtectedRouteProps = RouteProps;

const ProtectedRoute = ({
  ...routeProps
}: ProtectedRouteProps): JSX.Element => {
  // onOffice marketplace iframe URL params
  const urlParams = new URLSearchParams(window.location.search);
  const brokerId = urlParams.get('userid') || '';
  const propertyOnOfficeId = urlParams.get('EstateId') || '';
  const source = urlParams.get('source') || '';
  const financingId = urlParams.get('financing-id') || '';
  const financingNotification = urlParams.get('financing-notification') || '';
  const variantId = urlParams.get('variant-index') || '';

  const sourceParam = source ? `source=${source}&` : '';
  const financingIdParam = financingId ? `financing-id=${financingId}&` : '';
  const financingNotificationParam = financingNotification
    ? `financing-notification=${financingNotification}&`
    : '';
  const variantIdParam = variantId ? `variant-index=${variantId}` : '';

  const dispatch = useAppDispatch();
  const { isBuyer, isSeller, isFinancing, isBroker } = useGetPortalType();
  const { isDev } = useConfig();

  let logoutPath = isFinancing
    ? `${siteMap.FinancingLandingPage.path}?${sourceParam}${financingIdParam}${financingNotificationParam}${variantIdParam}`
    : isBuyer
    ? siteMap.BuyerLandingPage.path
    : isSeller
    ? siteMap.SellerLandingPage.path
    : siteMap.OwnerLandingPage.path;

  const downloadPath = useDownloadExposePageLogic();
  const searchProfilePath = useSearchProfilePageLogic();
  if (downloadPath) {
    logoutPath = downloadPath;
  } else if (searchProfilePath) {
    logoutPath = searchProfilePath;
  }

  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const exp = useAppSelector((state) => state.auth.exp);

  // Make Seller portal available for brokers without login (in onOffice marketplace iframe)
  if (
    (isDev ||
      window?.document?.referrer?.includes(OnOfficeMarketplaceReferrer)) &&
    (isSeller || isBroker) &&
    brokerId &&
    propertyOnOfficeId
  ) {
    dispatch(setBrokerView(true));
    return <Route {...routeProps} />;
  }

  if (!isAnonymousUser && exp) {
    const isExpired = isTokenExpired(exp);
    if (!isExpired) {
      return <Route {...routeProps} />;
    }
    dispatch(logout());
  }

  return (
    <Route path={routeProps.path}>
      <LngRedirect to={logoutPath} />
    </Route>
  );
};

export { ProtectedRoute };
