import styled from 'styled-components';
import {
  Headline3,
  ParagraphText,
} from '../../../../common/components/ui/typography';
import { MainButton } from '../../../../common/components/ui/buttons';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';

export const Title = styled(Headline3)`
  font-size: 16px;
  line-height: 1.25;
`;

export const Row = styled.div<{
  justifyContent?: string;
  margin?: string;
  alignItems?: string;
  padding?: string;
  isColumn?: boolean;
  gap?: number;
}>`
  ${({ isColumn }) => isColumn && 'flex-direction: column'};
  display: flex;
  align-self: stretch;
  position: relative;
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};

  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};

  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ gap }) => gap && `gap: ${gap}px`};
`;

export const TextArea = styled.textarea`
  padding: 12px;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.borderColor};
  width: 100%;

  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};

  &::placeholder {
    opacity: 0.6;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${(props) => props.theme.blue};
  }

  &:focus {
    outline: none !important;
    border: 1px solid ${(props) => props.theme.blue};
  }
`;

export const TextAreaTitle = styled(ParagraphText)`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  opacity: 0.6;
  margin: 12px 0 8px 0;
`;

export const ConfirmButton = styled(MainButton)`
  text-transform: uppercase;
  justify-content: center;
  height: 40px;
  //margin-top: 10px;

  &:hover {
    background-color: ${(props) => hexToRGB(props.theme.blue, 0.9)};
  }
`;

export const Form = styled.form`
  width: 100%;
`;
