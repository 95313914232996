import { FC, useEffect, useRef } from 'react';
import {
  CarouselItem,
  PictureCarousel,
} from './seller-property-full-screen-gallery-styles';
import { VonPollPropertyImage } from '../../../../../../generated';
import { SellerPropertyGallerySlider } from '../../seller-property-gallery-slider/seller-property-gallery-slider';

interface IProps {
  activeSlide: number;
  images: VonPollPropertyImage[];
  setActiveSlide: (index: number) => unknown;
}

const SellerPropertyFullScreenGallery: FC<IProps> = ({
  activeSlide,
  images,
  setActiveSlide,
}) => {
  const gallerySliderRef = useRef<HTMLImageElement | null>(null);
  const pictureCarouselRef = useRef<HTMLImageElement | null>(null);

  const changeSwiperSlide = (index: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gallerySliderRef.current?.swiper.slideTo(index);
  };

  const toSlide = (index: number) => {
    changeSwiperSlide(index);
    setActiveSlide(index);
  };

  const onClickNavButton = (isPrev: boolean) => () => {
    if (isPrev && activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
    } else if (images.length > activeSlide) {
      setActiveSlide(activeSlide + 1);
    }
  };

  useEffect(() => {
    changeSwiperSlide(activeSlide);
    pictureCarouselRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [activeSlide]);

  return (
    <>
      <SellerPropertyGallerySlider
        isWithMap={false}
        activeSlide={activeSlide}
        ref={gallerySliderRef}
        images={images}
        onClickNavButton={onClickNavButton}
        isGallery
        isPortrait
      />
      {images?.length > 0 && (
        <PictureCarousel>
          {images.map((item, i) => (
            <CarouselItem
              onClick={() => toSlide(i)}
              key={item.id}
              src={item.link}
              alt={item.link}
              isActive={i === activeSlide}
              ref={i === activeSlide ? pictureCarouselRef : null}
            />
          ))}
        </PictureCarousel>
      )}
    </>
  );
};

export { SellerPropertyFullScreenGallery };
