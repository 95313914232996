import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { Headline1 } from '../../../../common/components/ui/typography';

export const Container = styled.div<{ showAddressBar?: boolean }>`
  & {
    width: 608px;
    border-radius: 3px;
    ${({ showAddressBar }) =>
      showAddressBar && `border-top-left-radius: 0; border-top-right-radius: 0`}
    > * {
      color: ${(props) => props.color};
    }
    @media ${device.laptop} {
      width: 432px;
    }
    @media ${device.tablet} {
      max-width: 464px;
      width: 100%;
      box-shadow: none;
      margin: 0 auto;
    }
  }
`;

export const HeaderContainer = styled.div`
  @media ${device.mobile} {
    padding: 0 16px;
  }
`;

export const Label = styled.span`
  opacity: 0.8;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: ${(props) => props.theme.blue} !important;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0 32px;
  > div {
    display: flex;
    align-items: center;
    flex: 1;
    > i {
      margin-right: 8px;
    }
  }

  @media ${device.tablet} {
    padding: 16px 0;
  }
`;
export const Headline = styled.h1`
  font-family: 'Source Serif Pro';
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue} !important;
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const TeamImage = styled.img`
  width: 100%;
  max-height: 250px;
  object-fit: cover;
`;

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: ${(props) => props.theme.blue};
  padding: 32px;
  margin-top: -5px;
  @media ${device.laptop} {
    padding: 24px;
  }
  @media ${device.mobile} {
    padding: 16px;
  }
`;

export const RegionSlogan = styled.h2`
  margin: 0 0 4px;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #fff !important;
`;

export const TeamLeaderName = styled.p`
  margin: 4px 0 4px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #fff !important;
`;

export const TeamLeaderPosition = styled.p`
  margin: 4px 0 4px;
  opacity: 0.8;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #fff !important;
`;

export const TeamLeaderQualification = styled.p`
  margin: 4px 0 0;
  opacity: 0.6;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.5px;
  color: #fff !important;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  > *:first-child {
    margin-right: 12px;
  }
  > button {
    margin-top: 16px;
  }
`;
