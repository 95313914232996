import styled from 'styled-components';
import { ReactComponent as ChevronLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as ChevronRight } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-right-1.svg';
import { device } from '../../../../../style/theme';
import Icon from '../../../../common/components/ui/icon';

interface IProps {
  isPrev?: boolean;
  isGallery?: boolean;
  onClick: () => void;
}

const Container = styled.div<{ isPrev?: boolean; isGallery?: boolean }>`
  position: absolute;
  top: 51%;
  ${({ isPrev, isGallery }) =>
    isPrev
      ? isGallery
        ? 'left: -60px;'
        : `
    left: -20px;
  `
      : isGallery
      ? 'right: -60px;'
      : `right: -20px`};
  z-index: 2;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${(props) => props.theme.logoBlue};
  svg {
    stroke-width: 1.5px;
  }
  &.swiper-button-disabled {
    visibility: hidden;
    opacity: 0;
  }
  @media ${device.tablet} {
    ${({ isPrev }) => (isPrev ? `left: -20px;` : `right: -20px`)};
  }
`;

const IconWrapper = styled.div<{ isPrev?: boolean; isGallery?: boolean }>`
  position: absolute;
  ${(props) =>
    props.isGallery
      ? ''
      : props.isPrev
      ? `
    left: 21px;
  `
      : `right: 21px`};
  @media ${device.tablet} {
    ${(props) => (props.isPrev ? `left: 21px;` : `right: 21px`)};
  }
`;

const SliderNavButton = ({
  isPrev,
  isGallery,
  onClick,
}: IProps): JSX.Element => (
  <Container onClick={onClick} isPrev={isPrev} isGallery={isGallery}>
    <IconWrapper isPrev={isPrev} isGallery={isGallery}>
      <Icon icon={isPrev ? ChevronLeft : ChevronRight} width={9} height={9} />
    </IconWrapper>
  </Container>
);

export { SliderNavButton };
