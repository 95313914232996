import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { ModalFooter } from '../../../../common/components/modal';
import { MainButton } from '../../../../common/components/ui/buttons';

export const Form = styled.form``;

export const Section = styled.section`
  padding: 0 0 4px;

  &:first-of-type {
    margin: 28px 0 0;
  }

  &:last-of-type {
    margin: 0 0 20px;
  }

  @media ${device.tablet} {
    padding: 0 16px;
  }

  #delete-link {
    opacity: 1;
  }

  .extraClass {
    pointer-events: auto !important;

    &:hover {
      visibility: visible !important;
    }
  }
`;

export const SectionTitle = styled.h2`
  font-family: 'Source Serif Pro';
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  margin: 0 0 16px;
`;

export const SectionSubtitle = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  margin-top: -16px;
  margin-bottom: 16px;
`;

export const SectionText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
  margin: -8px 0 8px;
`;

export const SectionLink = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: ${(props) => props.theme.blue};
  cursor: pointer;
  display: inline-flex;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  @media ${device.tablet} {
    position: absolute;
    right: 16px;
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  position: relative;
  @media ${device.tablet} {
    background-color: ${(props) => props.theme.blue};
    width: 100%;
    justify-content: center;
    padding: 18px 16px;
    h3 {
      padding: 0 44px;
    }
  }
`;

export const StyledModalFooter = styled(ModalFooter)`
  @media ${device.tablet} {
    padding: 0 16px;
  }
`;

export const SubmitButton = styled(MainButton)`
  justify-content: center;
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const TooltipHeader = styled.h4`
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${(props) => props.theme.blue};
  margin-bottom: 12px;
`;

export const TooltipBack = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  text-align: center;
  color: ${(props) => props.theme.blue};
  margin-bottom: 12px;
  cursor: pointer;
`;

export const TooltipAccept = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  text-align: center;
  color: ${(props) => props.theme.ctaBlue};
  cursor: pointer;
`;

export const CheckboxesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  //grid-template-rows: 1fr 0.5fr 1fr;
  row-gap: 16px;
  column-gap: 12px;
  margin-bottom: 12px;
`;
