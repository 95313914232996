import styled from 'styled-components';
import { device } from '../../../../../style/theme';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';

export const Container = styled.div<{
  isRegionalPage?: boolean;
  isIframe?: boolean;
  isShort?: boolean;
}>`
  display: flex;
  background-color: ${({ theme }) => hexToRGB(theme.blue, 0.05)};
  margin-top: 2px;
  padding: 24px;
  & > div:last-child {
    flex-basis: 33%;
    padding-righy: 0;
  }
  & > div {
    flex-basis: 33%;
    padding-right: 12px;
    ${({ isRegionalPage }) =>
      isRegionalPage &&
      `& > div {
          font-size: 10px;
        }`}
  }
  & > div:first-child > div {
    max-width: ${({ isShort }) => (isShort ? '80px' : 'auto')};
  }
  & > div > div {
    max-width: ${({ isShort }) => (isShort ? '100px' : 'auto')};
  }

  @media ${({ isIframe }) => (isIframe ? device.mobileIframe : device.tablet)} {
    flex-direction: column;
    padding: 16px;
    & > div {
      flex-basis: 100%;
      margin-top: 8px;
      padding-left: 0;
    }
    & > div > div {
      max-width: 100%;
    }
    & > div:first-child {
      margin-top: 0;
    }
    & > div:first-child > div {
      max-width: 100%;
    }
  }
`;

export const InfoItemContainer = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-left: 12px;
    color: ${({ theme }) => theme.blue};
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.5px;
    word-break: break-word;
    hyphens: auto;
  }
`;
