import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { ExperimentType } from '../../../../generated';
import { RootState } from '../../../../store';

const selectExperiments = (state: RootState) =>
  state.experiments.savedExperiments;

export const selectRegisterExperiment = createDraftSafeSelector(
  selectExperiments,
  (experiments) =>
    experiments.find(
      (experiment) => experiment.type === ExperimentType.Register
    )
);
