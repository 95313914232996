import { FC } from 'react';
import styled from 'styled-components';
import { BaseButton } from './base-button';

const WhiteButtonBase = styled(BaseButton)`
  border: solid 2px #ffffff;
  background-color: #ffffff;
  color: ${({ theme }) => theme.blue};
  &:hover {
    background-color: #e5eaee;
  }
`;
interface IProps {
  label: string;
  onClick: () => void;
}

const WhiteButton: FC<IProps> = ({ label, onClick, ...rest }) => {
  return (
    <WhiteButtonBase onClick={onClick} {...rest}>
      {label}
    </WhiteButtonBase>
  );
};

export { WhiteButton };
