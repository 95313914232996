import { NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../icon';
import LngLink from '../../../../localization/lng-link';

interface IProps extends NavLinkProps {
  isWithoutLng: boolean;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  color?: string;
  isOpenNewPage?: boolean;
  $forceActive?: boolean;
  $hasActiveIndicator?: boolean;
}

const StyledLink = styled(LngLink)<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, sans-serif;
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${(props) => props.color ?? '#fff'};

  text-decoration: none;
  cursor: pointer;
  &.active,
  &:hover {
    opacity: 1;
  }
  .icon {
    margin-right: 8px;
  }

  ${(props) =>
    props.$hasActiveIndicator &&
    `
    &.active {
      &:before {
        position: absolute;
        bottom: -7px;
        content: '';
        height: 1.5px;
        width: 100%;
        background: #fff;
      }
    }
  `}
`;

const LinkButton = ({
  icon,
  children,
  isWithoutLng,
  isOpenNewPage,
  ...rest
}: IProps): JSX.Element => (
  <StyledLink
    isWithoutLng={isWithoutLng}
    absolute
    isOpenNewPage={isOpenNewPage}
    {...rest}
    activeClassName="active"
  >
    {icon && <Icon width={16} height={16} icon={icon} />}
    {children}
  </StyledLink>
);

export { LinkButton };
