import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { ReactComponent as File } from 'assets/streamline-light/files-folders/common-files/common-file-text.svg';

import styled from 'styled-components';
import {
  Accordion,
  AccordionSection,
} from '../../../../seller/components/accordion/accordion';
import {
  SectionType,
  UploadedBy,
  UploadedFilesSection,
} from '../uploaded-files-section/uploaded-files-section';
import {
  FileAccessConfigInput,
  ReleaseStatus,
  useGetPropertyDocumentsQuery,
  usePropertyAddressQuery,
} from '../../../../../generated';
import { getSizeFromBase64 } from '../../../../common/components/form/input/input-file/utils/getSizeFromBase64';
import { getFileExtension } from '../../../../common/components/form/input/input-file/utils/getFileExtension';
import { GlobalLoader } from '../../../../common/components/ui/loaders/global-loader';
import { device } from '../../../../../style/theme';
import { BoxTitle } from '../../../../common/components/ui/typography';
import { isImage } from '../../../../common/components/form/input/input-file/utils/isImage';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import {
  AllDocumentTypes,
  ExternalFileType,
} from '../../../../seller/constants';
import { FilesAccessManagerModal } from '../../files-access-manager/files-access-manager';
import { useAppSelector } from '../../../../common/hooks';
import { MainButton } from '../../../../common/components/ui/buttons';
import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { FilterLoader } from '../../../../seller/components/offers-statistics-card/filter/loader';
import { ValuationLoader } from '../../../../forms/components/common/loader';
import { LoaderContainer } from '../../../pages/buyer-expose-files-styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

// const LoaderContainer = styled.div`
//   width: 100%;
//   height: 200px;
//   position: relative;
//   padding: 32px;
//   padding: 32px;
//   border-radius: 3px;
//   border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
//   background-color: #ffffff;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 16px;
//   @media ${device.tablet} {
//     padding: 16px;
//   }
//
//   &&&& {
//     > div {
//       position: absolute;
//     }
//   }
// `;

const TotalCount = styled(BoxTitle)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 1.6;
  padding: 2px 8px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.lightBlue};
  background-color: ${(props) => props.theme.greyBackground};
  margin-left: 12px;
`;

interface IProps {
  accordionState?: boolean;
  onCollapseAccordion?: (id: string) => void;
  searchInput?: string;
  isLocked?: boolean;
  isFilterActive?: boolean;
  onResetFilters: () => void;
}

const FilesOverviewBase: FC<IProps> = ({
  accordionState,
  onCollapseAccordion,
  searchInput,
  isLocked,
  isFilterActive,
  onResetFilters,
}) => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const propertyOnOfficeId = urlParams.get('EstateId') || '';
  const [updatedReleaseStatuses, setUpdatedReleaseStatuses] = useState<{
    [key: string]: ReleaseStatus;
  } | null>(null);

  const {
    propertyAddress,
    isPropertyAddressLoading,
    isPropertyAddressFetching,
  } = usePropertyAddressQuery(
    { propertyOnOfficeId },
    {
      selectFromResult: ({ data, isLoading, isFetching, error }) => ({
        propertyAddress: data?.propertyAddress,
        isPropertyAddressLoading: isLoading,
        isPropertyAddressFetching: isFetching,
      }),
    }
  );

  const isAccessManagerModalOpen = useAppSelector(
    (state) => state.brokerView.isFileAccessManagerOpen
  );

  const filters = useAppSelector(
    (state) => state.brokerView.releaseStatusFilter
  );

  const { propertyFiles, isLoading, overviewError, isFetching } =
    useGetPropertyDocumentsQuery(
      {
        propertyId: propertyOnOfficeId,
      },
      {
        selectFromResult: ({
          data,
          isLoading: loading,
          error,
          isFetching: fetching,
        }) => ({
          propertyFiles: data?.getPropertyDocuments,
          isLoading: loading,
          overviewError: error,
          isFetching: fetching,
        }),
      }
    );

  const handleOpenFile = useCallback(
    async (fileBase64: string, fileName: string, originalName: string) => {
      //   const fileBlob = await fetch(fileBase64).then((res) => res.blob());
      //   const url = URL.createObjectURL(fileBlob);
      //   window.open(url, '_blank');
      const fileBlob = await fetch(fileBase64).then((res) => res.blob());
      const fileNameExtension = getFileExtension(fileName);
      const originalFileNameExtension = getFileExtension(originalName);
      let fileNameWithExtension = fileName;
      if (fileNameExtension !== originalFileNameExtension) {
        fileNameWithExtension = `${fileName}.${originalFileNameExtension}`;
      }
      const a = document.createElement('a');
      document.body.append(a);
      a.style.setProperty('display', 'none');
      // a.style = 'display: none';
      const url = window.URL.createObjectURL(fileBlob);
      a.href = url;
      a.download = fileNameWithExtension;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    []
  );

  const handleOpenLink = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  const { documents } = useMemo(() => {
    const transformedFiles = propertyFiles?.map((file) => {
      let fileType: string;

      switch (file.type) {
        case ExternalFileType.Expose:
        case ExternalFileType.Notice:
        case ExternalFileType.RentalStatement:
        case ExternalFileType.EnergyCertificate:
        case 'Grundriss':
        case 'Dokument':
          fileType = 'application';
          break;
        case 'Foto':
          fileType = 'image';
          break;
        default:
          fileType = '';
          break;
      }

      const fileExtension = getFileExtension(file.filename ?? '');
      const openFileHandler = async (fileOriginalName: string) => {
        const isDoc = fileExtension === 'docx' || fileExtension === 'doc';

        if (file.url) {
          handleOpenLink(file.url);
        } else {
          await handleOpenFile(
            `data:${fileType}/${isDoc ? 'msword' : fileExtension};base64, ${
              file.content
            }`,
            file?.title ?? file?.filename ?? file.originalname,
            file.originalname
          );
        }
      };

      return {
        id: file.id,
        type: file.type,
        title: file.title,
        fileName: file.filename ?? file.url ?? '',
        size: file.content ? getSizeFromBase64(file.content) : 0,
        onOpenFile: () => openFileHandler(file.originalname),
        uploadedBy: UploadedBy.Broker,
        url: file.url ?? '',
        previewUrl: isImage(file.filename ?? '')
          ? `data:image/${fileExtension};base64, ${file.content}`
          : '',
        releaseStatus:
          updatedReleaseStatuses?.[String(file.id)] ?? file.releaseStatus,
      };
    });

    const propertyDocuments = transformedFiles?.filter((file) =>
      AllDocumentTypes.includes(file.type)
    );

    return {
      documents: transformedFiles,
    };
  }, [handleOpenFile, propertyFiles, updatedReleaseStatuses]);

  const filteredDocuments = useMemo(() => {
    if (!isFilterActive) {
      return documents;
    }

    return documents?.filter((document) =>
      filters.includes(document.releaseStatus as ReleaseStatus)
    );
  }, [documents, filters, isFilterActive]);

  const handleSave = useCallback((input: FileAccessConfigInput) => {
    let releaseStatus: ReleaseStatus = ReleaseStatus.ReleasedForSomeUsers;
    if (isEmpty(input.usersWithForbiddenAccess)) {
      releaseStatus = ReleaseStatus.ReleasedForAll;
    }
    if (isEmpty(input.usersWithGrantedAccess)) {
      releaseStatus = ReleaseStatus.NotReleased;
    }

    setUpdatedReleaseStatuses((prevState) => ({
      ...prevState,
      [input.fileId]: releaseStatus,
    }));
  }, []);

  const sections: AccordionSection[] = useMemo(
    () => [
      {
        _id: 'overview_documents',
        title: t('upload-document.header'),
        expandedContent: (
          <>
            <div
              style={{
                backgroundColor: 'rgba(229, 233, 236, 0.6)',
                height: '64px',
                width: '100%',
                borderRadius: '3px',
                marginBottom: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '8px 12px',
                alignItems: 'center',
              }}
            >
              <div>
                <b>Objekt: </b> {propertyAddress?.address}
              </div>
              <div
                style={{ display: 'flex', alignItems: 'baseline', gap: '8px' }}
              >
                <b>Eigentümer: </b>
                {propertyAddress?.ownerEmail}
              </div>
            </div>
            <UploadedFilesSection
              sectionType={SectionType.Documents}
              uploadedFiles={filteredDocuments}
              searchInput={searchInput}
              isLocked={isLocked}
              isFilterActive={isFilterActive}
              onResetFilters={onResetFilters}
            />{' '}
          </>
        ),
        icon: File,
        Badge: (
          <TotalCount content={filteredDocuments?.length?.toString() ?? '0'} />
        ),
      },
    ],
    [
      t,
      propertyAddress?.address,
      propertyAddress?.ownerEmail,
      filteredDocuments,
      searchInput,
      isLocked,
      isFilterActive,
      onResetFilters,
    ]
  );

  if (
    isLoading ||
    isFetching ||
    isPropertyAddressLoading ||
    isPropertyAddressFetching
  ) {
    return (
      <LoaderContainer isLoadingMore>
        <ValuationLoader maxWidth="500px" />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      {overviewError?.message && (
        <GlobalError
          title={t(overviewError?.message?.split(':')[0].toLowerCase())}
        />
      )}
      {sections.map((section) => {
        return (
          <Accordion
            key={section._id}
            id={section._id}
            accordionSection={section}
            isOpen={accordionState}
            onChange={onCollapseAccordion}
          />
        );
      })}
      <FilesAccessManagerModal
        isOpen={isAccessManagerModalOpen}
        onSave={handleSave}
      />
    </Container>
  );
};

const FilesOverview = memo(FilesOverviewBase);

export { FilesOverview };
