import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxItem, Container } from './inputs-container-styles';
import { InputNumber } from '../../common/input-number/input-number';
import { InputV2 } from '../../common/input-v2/input-v2';
import { BoxSelector } from '../../common/box-selector';
import { SelectV2 } from '../../common/select-v2/select-v2';
import { ICustomFieldType, TField } from '../interface';
import { InputFinancingAddress } from '../../../form-financing/components/common/input-address/input-financing-address';
import { Switch } from '../../common/switch/switch';
import { DatePicker } from '../../common/datepicker/datepicker';
import { Collapsible } from '../../../../common/components/ui/collapsible';

interface IProps {
  inputs: TField[];
  dynamicAdornment?: string;
}

const InputsContainerBase: FC<IProps> = ({ inputs, dynamicAdornment }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {inputs?.map((item) => {
        switch (item.customType) {
          case ICustomFieldType.FinancingAddress:
            return (
              <InputFinancingAddress
                key={item.label}
                label={item.label}
                placeholder={item.placeholder}
                tooltipKey={item.tooltipKey}
              />
            );
          case ICustomFieldType.BooleanBoxes:
            return (
              <BoxSelector
                key={item.label}
                label={item.label}
                name={item.name}
                boxPadding="9px 12px"
                boxes={item.boxes}
                tabletGap={item.boxTabletGap}
                tooltipKey={item.tooltipKey}
                defaultValue={item.defaultValue}
                isFullWidth
              />
            );
          case ICustomFieldType.Switch:
            return (
              <Switch
                key={item.label}
                name={item.name}
                label={item.label || ''}
                tooltipKey={item.tooltipKey}
              />
            );
          case ICustomFieldType.Number:
            return (
              <InputNumber
                key={item.name}
                name={item.name}
                type={item.type}
                tooltipKey={item.tooltipKey}
                label={item.label}
                inputAdornment={item.inputAdornment}
                dynamicAdornment={dynamicAdornment || '1'}
                placeholder={item.placeholder}
                isDisabled={item.isDisabled}
                isRequired={item.isRequired}
                defaultValue={item.defaultValue}
                rules={item.rules}
              />
            );
          case ICustomFieldType.Checkbox:
            return (
              <CheckboxItem
                key={item.name}
                name={item.name}
                label={item.label}
                isWithoutErrors
              />
            );
          case ICustomFieldType.Select:
            return (
              <SelectV2
                key={item.name}
                name={item.name}
                label={item.label}
                placeholder={item.placeholder}
                isPlaceholderCenter
                selectOptions={item.options}
                tooltipKey={item.tooltipKey}
              />
            );
          case ICustomFieldType.BoxRating:
            return (
              <BoxSelector
                key={item.name}
                name={item.name}
                containerTitle={t(item.containerTitle || '')}
                boxes={item.boxes}
                isWrap={item.isWrap}
                minWidthBox={item.minWidthBox || 202}
                boxMaxWidth={item.boxMaxWidth || 276}
                onClick={item.onClick}
                isBoxTabletColumn={item.isBoxTabletColumn}
                isTabletTwoBoxesColumn={item.isTabletTwoBoxesColumn}
                gap={item.boxGap}
                tabletGap={item.boxTabletGap}
              />
            );
          case ICustomFieldType.Date:
            return (
              <DatePicker
                key={item.name}
                name={item.name}
                label={item.label}
                placeholder={item.placeholder}
                rules={item.rules}
                dateFormat={'dd.MM.yyyy'}
                tooltipKey={item.tooltipKey}
                labelWidth="auto"
                labelMR="8px"
                labelMobileMR="4px"
                isValidateOnBlur={item.isValidateOnBlur}
                isTooltipKeyLeftSide={item.isTooltipKeyLeftSide}
                isOnClickTooltip
                isModalDesign
                isPlaceholderCentered
              />
            );
          default:
            return (
              <Collapsible
                key={item.name}
                initHeight={item.initHeight}
                isOpen={!item.isHidden}
                isNotCollapsible={!item.isCollapsible}
                mt={item.isHidden ? -12 : 0}
              >
                <InputV2
                  name={item.name}
                  type={item.type}
                  tooltipKey={item.tooltipKey}
                  label={item.label}
                  inputAdornment={item.inputAdornment}
                  placeholder={item.placeholder}
                  isDisabled={item.isDisabled}
                  isValidateOnBlur={item.isValidateOnBlur}
                  validateNames={item.validateNames}
                  isWrapper={item.isWrapper}
                  isRequired={item.isRequired}
                  defaultValue={item.defaultValue}
                  rules={item.rules}
                  isPlaceholderCenter
                  isSeparate={item.isSeparate}
                  isPercent={item.isPercent}
                  usefulLink={item.usefulLink}
                  isDecimalNumber={item.isDecimalNumber}
                  isTooltipKeyLeftSide={item.isTooltipKeyLeftSide}
                  handleChangeProp={item.handleChangeProp}
                />
              </Collapsible>
            );
        }
      })}
    </Container>
  );
};

const InputsContainer = memo(InputsContainerBase);

export { InputsContainer };
