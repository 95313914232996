import { FC, memo } from 'react';

import {
  CircleBackground,
  CircleProgress,
  Percent,
} from './circular-progress-bar-styles';

interface IProps {
  squareSize: number; // Size of the enclosing square
  backgroundStrokeWidth?: number;
  progressStrokeWidth?: number;
  percent: number;
}

const CircularProgressBarBase: FC<IProps> = ({
  squareSize,
  backgroundStrokeWidth,
  progressStrokeWidth,
  percent,
}) => {
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (squareSize - (backgroundStrokeWidth ?? 10)) / 2;
  // Enclose circle in a circumscribing square
  const viewBox = `0 0 ${squareSize} ${squareSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percent) / 100;

  return (
    <svg width={squareSize} height={squareSize} viewBox={viewBox}>
      <CircleBackground
        cx={squareSize / 2}
        cy={squareSize / 2}
        r={radius}
        strokeWidth={`${backgroundStrokeWidth}px`}
      />

      <CircleProgress
        cx={squareSize / 2}
        cy={squareSize / 2}
        r={radius}
        strokeWidth={`${progressStrokeWidth ?? 8}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${squareSize / 2} ${squareSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
      />

      <Percent x="50%" y="50%" dy=".3em" textAnchor="middle">
        {`${percent}%`}
      </Percent>
    </svg>
  );
};

const CircularProgressBar = memo(CircularProgressBarBase);

export { CircularProgressBar };
