import styled from 'styled-components';

export const ShadowmapMobileOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  background: #00305e;
`;

export const ShadowmapMobileHeader = styled.div`
  height: 52px;
  width: 100%;
  background: #00305e;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ShadowmapMobileHeaderButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  margin-left: 12px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  color: white;
`;

export const ShadowmapMobileIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

export const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
`;

export const AddressBar = styled.div`
  height: 40px;
  background: #e5e9ec;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
`;

export const AddressBarText = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #00305e;
`;

export const MobileInfoOverlay = styled.div<{ $shown?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100%;
  background: rgba(0, 0, 0, 0.1);
  ${(props) => props.$shown && `bottom: 0;`}
  overflow: hidden;
`;

export const MobileInfoBox = styled.div<{ $shown?: boolean }>`
  position: absolute;
  height: 60%;
  bottom: -70%;
  left: 1px;
  right: 1px;
  background: white;
  transition: 0.5s all;
  border: 1px solid #d9d9d9;
  border-bottom: none;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  ${(props) =>
    props.$shown &&
    `
      bottom: 0;
    `}
`;

export const MobileInfoToggler = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileInfoTogglerIcon = styled.div`
  width: 48px;
  height: 8px;
  background: #00305e;
  border-radius: 100px;
`;

export const MobileInfoContentBox = styled.div`
  padding: 12px;
  background: rgba(0, 0, 0, 0.05);
  overflow: auto;
`;
