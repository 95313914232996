import { useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';
import { SubmitHandler } from 'react-hook-form';

import {
  FinancingType,
  RegistrationBy,
  UserState,
  UserType,
  useSignUpMutation,
} from '../../../../../generated';
import { useAppSelector } from '../../../../common/hooks';
import {
  closeFinancingModal,
  setIsConfirmEmailSent,
  toggleLoginForm,
  toggleRegisterForm,
} from '../../redux/financingSlice';
import { IRegisterForm } from '../../../../auth/interfaces';
import { useCreateFinancing } from '../callback-hooks/useCreateFinancing';
import { parseRegisterInput } from '../../../../auth/utils/parse-register-input';
import useGetLocalization from '../../../../localization/get-localization';
import { parseFinancingInput } from '../../utils/parse-financing-input';
import { IFinancingForm } from '../../interface';
import { Feature } from '../../../../../services/mapbox/interfaces';
import {
  setUserId,
  setUserInput,
  setUsername,
  setUserState,
  toggleLoading,
} from '../../../../auth/redux/authSlice';
import { useActualExperiment } from '../../../../experiments/hooks/useActualExperiment';

interface IProps {
  setErrors: (errors: unknown) => void;
}

interface IReturn {
  isConfirmEmailSent: boolean;
  userState: UserState;
  onRegisterSubmit(formData: IRegisterForm): void;
  onOpenLogin(): void;
}

const useSubmitFinancingRegister = ({ setErrors }: IProps): IReturn => {
  const dispatch = useDispatch();
  const lang = useGetLocalization();
  const { isConfirmEmailSent, selectedAddress, userInput, financingType } =
    useAppSelector((state) => state.financing);
  const userState = useAppSelector((state) => state.auth.userState);
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const { isRegistrationByEmail, registrationBy } = useActualExperiment();

  const [signUp] = useSignUpMutation();

  const onOpenLogin = useCallback(() => {
    dispatch(toggleLoginForm(true));
    dispatch(toggleRegisterForm(false));
  }, [dispatch]);
  const { createFinancing } = useCreateFinancing();

  const onRegisterSubmit: SubmitHandler<IRegisterForm> = useCallback(
    async (formData) => {
      const input = parseRegisterInput({
        formData,
        lang,
        userType: UserType.Financier,
      });

      dispatch(setUserInput({ ...formData, password: input.password }));
      dispatch(toggleLoading(true));

      // Create the user account before phone verification
      try {
        const signUpResponse = await signUp({
          input: {
            ...input,
            termsAndConditions: Boolean(input.termsAndConditionsFinancing),
            age: undefined,
            mainEarner: undefined,
            householdNetIncome: undefined,
            hasPendingValuations: true,
            registrationBy: registrationBy || RegistrationBy.Mobile,
          },
        }).unwrap();

        if (signUpResponse?.signUp?._id) {
          const {
            _id: id,
            username: newUsername,
            userState: newUserState,
          } = signUpResponse.signUp;
          batch(() => {
            dispatch(setUserId(id));
            dispatch(setUsername(newUsername));
            dispatch(setUserState(newUserState));
          });

          // registration case
          if (isRegistrationByEmail) {
            const financingInput = parseFinancingInput(
              {
                ...userInput,
                age: formData.age,
                mainEarner: formData.mainEarner,
                householdNetIncome: formData.householdNetIncome,
              } as IFinancingForm,
              financingType || FinancingType.Financing,
              selectedAddress as Feature
            );

            await createFinancing({
              financingInput,
              userId: id,
            });

            dispatch(setIsConfirmEmailSent(true));
            if (isIframe) {
              setTimeout(() => {
                if (document.fullscreenElement && document.exitFullscreen) {
                  document.exitFullscreen();
                }
                dispatch(closeFinancingModal());
              }, 5000);
            }
          }
        }
      } catch (error: unknown) {
        setErrors(error);
      } finally {
        dispatch(toggleLoading(false));
      }
    },
    [
      createFinancing,
      dispatch,
      isIframe,
      isRegistrationByEmail,
      lang,
      registrationBy,
      selectedAddress,
      setErrors,
      signUp,
      userInput,
    ]
  );

  return { isConfirmEmailSent, userState, onOpenLogin, onRegisterSubmit };
};

export { useSubmitFinancingRegister };
