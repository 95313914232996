import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RealEstateBuildingHouse } from 'assets/streamline-light/real-estate/real-estate/real-estate-building-house.svg';
import { ReactComponent as AccountingBills } from 'assets/streamline-light/money-payments-finance/money/accounting-bills-1.svg';
import { useToLocalWithCurrency } from '../../../common/utils/convertCHF';
import PropertyValuationIcon from '../../../property/components/icon/property-valuation-icon';
import {
  Container,
  Header,
  Title,
  Content,
  IconRow,
  IconContainer,
} from './property-general-info-card-styles';

interface IProps {
  numberOfObjects: number;
  totalValue: number;
  header: string;
  title: string;
  content: string;
}

const PropertyGeneralInfoCardBase = ({
  header,
  title,
  content,
  numberOfObjects,
  totalValue,
}: IProps): JSX.Element => {
  const { t } = useTranslation();

  const localTotalValue = useToLocalWithCurrency(totalValue ?? 0) as string;

  return (
    <Container>
      <Header content={t(header)} />
      <Title content={t(title)} />
      <Content content={t(content)} />
      <IconContainer>
        <IconRow>
          <PropertyValuationIcon
            icon={RealEstateBuildingHouse}
            type={t('seller-properties-general-info-card.properties-count')}
            value={numberOfObjects.toString() ?? '0'}
          />
          <PropertyValuationIcon
            icon={AccountingBills}
            type={t('seller-properties-general-info-card.properties-value')}
            value={localTotalValue}
          />
        </IconRow>
      </IconContainer>
    </Container>
  );
};

const PropertyGeneralInfoCard = memo(PropertyGeneralInfoCardBase);

export { PropertyGeneralInfoCard };
