import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePersonalRegister } from '../../../../hooks/callback-hooks/personal-valuation/usePersonalRegister';
import { TimifyWidget } from '../../../../../../timify/components/widget';
import { ConfirmEmail } from '../../../../../../auth/components/confirm-email';
import { UserState, ValuationType } from '../../../../../../../generated';
import {
  LoaderContainer,
  Title,
} from '../../../../../components/fullscreen-modal/auth-container/styles';
import LoginOpen from '../../../../../../auth/components/login-open';
import { RegisterForm } from '../../../../../../auth/components/forms/register-form/register-form';
import { PersonalVerifyPhoneContainer } from './verify-phone-container/personal-verify-phone-container';
import { useSubmitPersonalEVForm } from '../../../../hooks/callback-hooks/personal-valuation/useSubmitPersonalEVForm';
import { GlobalLoader } from '../../../../../../common/components/ui/loaders/global-loader';

const PersonalRegisterContainer: FC = () => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<null | unknown>(null);
  const [reservationSuccess, setReservationSuccess] = useState(false);
  const [confirmGuestAccount, setConfirmGuestAccount] = useState(false);
  const [temporaryUserInput, setTemporaryUserInput] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
  });
  const [temporaryUserCredentials, setTemporaryUserCredentials] = useState({
    username: '',
    accessToken: '',
    refreshToken: '',
    attributeName: 'email',
    firstName: '',
    lastName: '',
  });
  const { onSubmit } = useSubmitPersonalEVForm();

  const {
    isRegisterFormLoading,
    userState,
    isIframe,
    timifyParams,
    onOpenLogin,
    onRegisterSubmit,
    isValuationLoading,
    isConfirmEmailSent,
  } = usePersonalRegister({
    setTemporaryUserInput,
    setErrors,
    setConfirmGuestAccount,
    temporaryUserCredentials,
    reservationSuccess,
  });

  const isLoading = useMemo(
    () => isRegisterFormLoading || isValuationLoading,
    [isRegisterFormLoading, isValuationLoading]
  );

  const isRegisterForm = useMemo(
    () =>
      userState === UserState.Unauthenticated &&
      !confirmGuestAccount &&
      !timifyParams &&
      !isConfirmEmailSent &&
      !isLoading,
    [
      isConfirmEmailSent,
      confirmGuestAccount,
      timifyParams,
      userState,
      isLoading,
    ]
  );

  return (
    <>
      {isLoading && (
        <LoaderContainer isiframe={isIframe}>
          <GlobalLoader />
        </LoaderContainer>
      )}
      {isRegisterForm && (
        <>
          <Title>{t('register.form.title')}</Title>
          <LoginOpen handleClick={onOpenLogin} />
          <RegisterForm
            // skipPassword
            onSubmit={onRegisterSubmit}
            valuationType={ValuationType.Personal}
            errors={errors}
            isRedesign
            isFullWidthButton
            isFieldRequirement
          />
        </>
      )}
      {timifyParams && !isLoading && (
        <TimifyWidget
          {...timifyParams}
          setReservationSuccess={setReservationSuccess}
        />
      )}
      {(userState === UserState.VerifyPhone ||
        (confirmGuestAccount && !timifyParams && !isConfirmEmailSent)) &&
        !isLoading && (
          <PersonalVerifyPhoneContainer
            setErrors={setErrors}
            onSuccess={onSubmit}
            temporaryUserInput={temporaryUserInput}
            setTemporaryUserCredentials={setTemporaryUserCredentials}
          />
        )}
      {isConfirmEmailSent && !isLoading && (
        <ConfirmEmail
          title={t(
            confirmGuestAccount
              ? 'modal.register.confirm-email.personal-valuation.guest.title'
              : 'modal.register.confirm-email.personal-valuation.title'
          )}
          textContent={t(
            confirmGuestAccount
              ? 'modal.register.confirm-email.personal-valuation.guest.content'
              : 'modal.register.confirm-email.personal-valuation.content'
          )}
        />
      )}
    </>
  );
};

export { PersonalRegisterContainer };
