import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { ThemeContext } from 'styled-components';
import {
  CardHeader,
  Container,
  Description,
  Title,
  CollapseButton,
} from './preparation-card-styles';
import { InputSearch } from '../../../../common/components/form/input/input-search';
import { FileUploader } from '../file-uploader/file-uploader';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { ReactComponent as ArrowDown } from '../../../../../assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import { SimpleList } from '../../../../common/components/ui/simple-list/simple-list';
import { preparationStepList } from '../../../constants';
import { NextStepCard } from '../next-step-card/next-step-card';
import { useAppSelector } from '../../../../common/hooks';

interface IProps {
  onSubmit: () => void;
  isLocked?: boolean;
}

const PreparationCardBase: FC<IProps> = ({ onSubmit, isLocked }) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchInputDebounced, setSearchInputDebounced] = useState<string>('');
  const isMobileSize = useIsMobileSize();
  const isAccordionsOpen = useRef<boolean>(true);
  const [accordionsState, setAccordionsState] = useState<
    { id: string; isOpen: boolean }[]
  >([
    {
      id: 'upload_documents',
      isOpen: true,
    },
    {
      id: 'upload_images',
      isOpen: true,
    },
    {
      id: 'upload_property_plans',
      isOpen: true,
    },
  ]);

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  const onSearchInputChangeDebounced = useDebouncedCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchInputDebounced(event.target.value);
    },
    1000
  );

  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value);
      onSearchInputChangeDebounced(event);
    },
    [onSearchInputChangeDebounced]
  );

  const onClearSearch = useCallback(() => {
    setSearchInput('');
    setSearchInputDebounced('');
  }, []);

  const onCollapseSingleAccordion = useCallback((id: string) => {
    setAccordionsState((prevState) => {
      const sid = prevState.find((accordion) => accordion.id === id);
      return prevState.map((accordion) =>
        accordion === sid
          ? { ...accordion, isOpen: !accordion.isOpen }
          : accordion
      );
    });
  }, []);

  const onCollapseAllAccordions = useCallback(() => {
    isAccordionsOpen.current = !isAccordionsOpen.current;
    setAccordionsState((prevState) =>
      prevState.map((accordion) => ({
        ...accordion,
        isOpen: isAccordionsOpen.current,
      }))
    );
  }, []);

  return (
    <Container>
      <CardHeader>
        <Title content={t('seller.preparation-card.title')} />
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            gap: '8px',
            width: isMobileSize ? '100%' : 'unset',
          }}
        >
          <InputSearch
            placeholder={t('seller-properties-list.search-input.placeholder')}
            animateWidth={{
              from: isMobileSize ? '100%' : '180px',
              to: isMobileSize ? '100%' : '300px',
            }}
            onChange={onSearchInputChange}
            value={searchInput}
            onClearSearch={onClearSearch}
          />
          <CollapseButton
            label={''}
            onClick={onCollapseAllAccordions}
            icon={ArrowDown}
            color={theme.grey2}
            iconStyle={{
              width: '16px',
              height: '8px',
              margin: 0,
              transition: `transform 500ms ease`,
              transform: isAccordionsOpen.current ? 'rotate(-180deg)' : 'none',
            }}
            borderColor={theme.borderColor}
          />
        </div>
      </CardHeader>
      <Description content={t('seller.preparation-card.description')} />
      <SimpleList
        title={'seller.preparation-card.info-list.title'}
        items={preparationStepList}
      />
      <FileUploader
        searchInput={searchInputDebounced}
        accordionsState={accordionsState}
        onCollapseAccordion={onCollapseSingleAccordion}
        isDisabled={isLocked}
      />
      {!isMobileSize && (
        <NextStepCard
          onSubmit={onSubmit}
          isNextStepDisabled={isBrokerView}
          tooltipId={'seller-next-step-2'}
        />
      )}
    </Container>
  );
};

const PreparationCard = memo(PreparationCardBase);

export { PreparationCard };
