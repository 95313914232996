import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ReloadIcon } from 'assets/streamline-light/business-products/data-files/data-file-bars-sync.svg';

import { Modal } from '../../../common/components/modal';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { toggleSourceEmailNotification } from '../../../forms/form-financing/redux/financingSlice';
import {
  ButtonContainer,
  Container,
  Text,
  Title,
} from './source-email-notification-styles';
import { SecondaryButton } from '../../../common/components/ui/buttons';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import Icon from '../../../common/components/ui/icon';
import { defaultTheme } from '../../../../style/theme';

const mobileModalStyles = {
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  padding: 0,
};

const SourceEmailNotification: FC = () => {
  const isMobileSize = useIsMobileSize();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isOpenSourceEmailNotification } = useAppSelector(
    (state) => state.financing
  );

  const onClose = () => {
    dispatch(toggleSourceEmailNotification(false));
  };

  return (
    <Modal
      isOpen={isOpenSourceEmailNotification}
      onClose={onClose}
      wrapperStyles={isMobileSize ? mobileModalStyles : {}}
    >
      <Container>
        <Icon
          icon={ReloadIcon}
          color={defaultTheme.blue}
          width={40}
          height={40}
        />
        <Title>{t('financing-portal.source-email-notification.title')}</Title>
        <Text>
          <b>{t('financing-portal.source-email-notification.text.bold')}</b>{' '}
          {t('financing-portal.source-email-notification.text')}
        </Text>
        <ButtonContainer>
          <SecondaryButton
            label={t('financing-portal.source-email-notification.button')}
            onClick={onClose}
          />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export { SourceEmailNotification };
