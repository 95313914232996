import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const HeaderWrapper = styled.div<{
  isTabListNearTop: boolean;
  isMultipleFinancings: boolean;
  isMultipleVariants: boolean;
}>`
  display: flex;
  position: sticky;
  top: ${({ isMultipleFinancings, isMultipleVariants }) => {
    let top = 80;
    top += isMultipleFinancings ? 60 : 0;
    top += isMultipleVariants ? 56 : 0;

    return top;
  }}px;
  margin: 0 0 16px;
  border-radius: ${(props) => (props.isTabListNearTop ? '0 0 3px 3px' : '3px')};
  background-color: ${(props) => props.theme.logoBlue};
  z-index: 10;

  &:hover {
    z-index: 2010;
  }

  ul {
    display: flex;
    align-items: center;
    padding: 0 24px;

    > li {
      outline: none !important;
      list-style: none;
      min-width: 142px;
      margin: 0 8px;
      padding: 16px 32px;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      p {
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        height: 16px;
        width: 16px;
        border: 1px solid white;
        border-radius: 50%;
        box-sizing: border-box;
        background: #fff;
      }

      &:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: ${(props) => props.theme.blue};
        border-radius: 50%;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
      }

      &:first-child {
        padding-left: 0;
        &::before,
        &::after {
          margin-left: -16px;
        }
      }

      &.react-tabs__tab--selected {
        overflow: visible;
        cursor: default;
      }

      &:not(.react-tabs__tab--selected) {
        opacity: 0.5;

        &:before {
          background: ${(props) => props.theme.blue};
        }

        &:after {
          background: #fff;
        }
      }
    }
  }

  @media ${device.tablet} {
    display: flex;
    overflow: auto;
    white-space: nowrap;
    border-radius: unset;
    height: unset;
    max-width: unset;
    position: static;

    ul {
      padding: 0 12px;

      li {
        padding: 0;
        flex: auto;
        &.react-tabs__tab--selected {
          overflow: hidden;
        }

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }
    }
  }
`;
