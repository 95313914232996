import { Svg, Path } from '@react-pdf/renderer';
import { defaultTheme } from '../../../../style/theme';

const WallIcon = () => {
  return (
    <Svg viewBox={'0 0 24 24'} width={'24px'} height={'24px'}>
      <Path
        d={'M10 13.426H.5v5.5'}
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={
          'M.5 13.926v-5.5H13m-12.5 0v-5h11v5m-7-5v5m4 0v5m-4 0v5m12 .5v4.5M17 6.571a1.5 1.5 0 0 0 2.117.133L22.981 3.2A1.5 1.5 0 0 0 21 .949l-3.86 3.505A1.5 1.5 0 0 0 17 6.571z'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={'M17.016 6.582 19.891 9.9a.508.508 0 0 1-.047.709l-3.375 2.977'}
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={
          'M14.031 10.3a1.459 1.459 0 0 1 2.5-.5l3.5 3.5a1.451 1.451 0 0 1-.5 2.5L11 19.115c-.256.1-.384-.028-.284-.285z'
        }
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
      <Path
        d={'M18.516 18.426H23.5v5H.5v-5h8v5'}
        fill={'none'}
        stroke={defaultTheme.blue}
        strokeLineCap={'round'}
      />
    </Svg>
  );
};

export { WallIcon };
