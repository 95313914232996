import styled from 'styled-components';

import { device } from '../../../../../../../../../style/theme';
import {
  ParagraphTitle,
  TitleSlogan,
} from '../../../../../../../../common/components/ui/typography';

export const Container = styled.div`
  width: 50%;
  display: flex;
  overflow: hidden;
  margin-top: 12px;
  min-width: 160px;
  align-items: center;
  gap: 16px;

  @media ${device.tablet} {
    display: block;
  }

  @media (max-width: 430px) {
    width: 100%;
  }
`;

export const Value = styled(ParagraphTitle)`
  && {
    color: ${(props) => props.theme.blue};
    opacity: 0.8;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
  }
`;

export const Label = styled(TitleSlogan)`
  && {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1.6;
    opacity: 0.5;
    color: ${(props) => props.theme.blue};
    min-width: 0;
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
  }
`;
