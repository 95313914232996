import { RegisterOptions } from 'react-hook-form';

// financing reason
import purchaseOfExistingPropertyIcon from '../../../../assets/images/icons/financing-form/financing-reason/kauf-bestandsimmobilie.png';
import followUpFinancingIcon from '../../../../assets/images/icons/financing-form/financing-reason/anschlussfinanzierung.png';
import ownConstructionProjectIcon from '../../../../assets/images/icons/financing-form/financing-reason/eigenes-bauvorhaben.png';
import purchaseOfNewBuildingIcon from '../../../../assets/images/icons/financing-form/financing-reason/kauf-eines-neubaus.png';
import modernizationIcon from '../../../../assets/images/icons/financing-form/financing-reason/modernisierung.png';
import raisingCapitalIcon from '../../../../assets/images/icons/financing-form/financing-reason/kapitalbeschaffung.png';
// import installmentLoanIcon from '../../../../assets/images/icons/financing-form/financing-reason/ratenkredit.png';

// property type
import houseDetachedIcon from '../../../../assets/images/icons/financing-form/property-type/einfamilienhaus.png';
import condominiumIcon from '../../../../assets/images/icons/financing-form/property-type/eigentumswohnung.png';
import houseSemiDetachedIcon from '../../../../assets/images/icons/financing-form/property-type/doppelhaushalfte.png';
import multiFamilyHouseIcon from '../../../../assets/images/icons/financing-form/property-type/mehrfamilienhaus.png';
import houseRowCornerIcon from '../../../../assets/images/icons/financing-form/property-type/reiheneckhaus.png';
import houseRowMiddleIcon from '../../../../assets/images/icons/financing-form/property-type/reihenmittelhaus.png';
import twoFamilyHouseIcon from '../../../../assets/images/icons/financing-form/property-type/zweifamilienhaus.png';
import plotOnlyIcon from '../../../../assets/images/icons/financing-form/property-type/nur-grundstuck.png';

import { BoxType, IBox } from '../../interface';
import { IOption } from '../../components/common/select-v2/interface';
import { ICustomFieldType } from '../../components/fullscreen-modal/interface';
import {
  FinancingPropertyType,
  FinancingReason,
  PropertyUse,
  SearchProgress,
} from '../../../../generated';
import { mainEarnerOptions, REGISTER_FORM_KEYS } from '../../../auth/constants';
import { siteMap } from '../../../../routes/site-map';

export const usefulInfoKeyPhrases = [
  'financing-portal.form.form-side.useful-info.first',
  'financing-portal.form.form-side.useful-info.second',
  'financing-portal.form.form-side.useful-info.third',
];

// export const defaultDebounceState = {
//   postCode: '',
//   street: '',
//   number: '',
//   city: '',
//   placeName: '',
//   locality: '',
//   neighborhood: '',
//   state: '',
//   proximity: '9.993682,53.551086',
//   country: 'de,at,ch',
//   language: 'de',
// };

export enum Proximity {
  GERMANY = '9.993682,53.551086',
  AUSTRIA = '15.4395,47.0707',
  SWITZERLAND = '7.3606,46.2331',
}

export const FORM_STEPS = 3;

export const FINANCING_FORM_KEYS = {
  REASON: 'reason', // boxes

  PROPERTY_TYPE: 'propertyType',
  LIVING_AREA: 'livingArea',
  BUILDING_YEAR: 'buildingYear',
  RENTAL_INCOME_PER_MONTH: 'rentalIncomePerMonth',
  PROPERTY_USE: 'propertyUse',
  // STATUS_OF_PROJECT: 'statusOfProject',
  SEARCH_PROGRESS: 'searchProgress',
  ADDRESS: 'address',
  POST_CODE: 'postCode',
  CITY: 'city',
  PLACE_NAME: 'placeName',
  LOCALITY: 'locality',
  NEIGHBORHOOD: 'neighborhood',
  REGION: 'region',

  PURCHASE_PRICE: 'purchasePrice',
  EQUITY_CAPITAL: 'equityCapital',
  BROKERAGE_FEE: 'brokerageFee',
  BROKER_FEE_PERCENT: 'brokerFeePercent',
  BUILDING_COSTS: 'buildingCosts',
  LAND_PRICE: 'landPrice',
  PROPERTY_VALUE: 'propertyValue',
  DESIRED_LOAN_AMOUNT: 'desiredLoanAmount',
  MONTHLY_RATE: 'monthlyRate',
  PAYOUT_DATE: 'payoutDate',
  ESTIMATED_REMAINING_DEBT: 'estimatedRemainingDebt',

  DESIRED_RATE: 'desiredRate',
  REPAYMENT_RATE: 'repaymentRate',

  // TERMS_AND_CONDITIONS_FINANCING: 'termsAndConditionsFinancing',
};

export const reasonBoxes = [
  {
    boxType: BoxType.iconBox,
    title:
      'financing-portal.form.inputs.reason.boxes.purchase-of-existing-property',
    value: FinancingReason.PurchaseOfExistingProperty,
    iconImage: purchaseOfExistingPropertyIcon,
    isSearchProgressField: true,
    propertyTypes: [
      FinancingPropertyType.HouseDetached,
      FinancingPropertyType.HouseDetached,
      FinancingPropertyType.Condominium,
      FinancingPropertyType.HouseSemiDetached,
      FinancingPropertyType.HouseRowMiddle,
      FinancingPropertyType.TwoFamilyHouse,
      FinancingPropertyType.MultiFamilyHouse,
      FinancingPropertyType.HouseRowCorner,
      FinancingPropertyType.PlotOnly,
    ],
    fields: {
      secondStep: [
        FINANCING_FORM_KEYS.PROPERTY_TYPE,
        FINANCING_FORM_KEYS.LIVING_AREA,
        FINANCING_FORM_KEYS.BUILDING_YEAR,
        FINANCING_FORM_KEYS.SEARCH_PROGRESS,
        FINANCING_FORM_KEYS.PROPERTY_USE,
        FINANCING_FORM_KEYS.POST_CODE,
      ],
      thirdStep: [
        FINANCING_FORM_KEYS.PURCHASE_PRICE,
        FINANCING_FORM_KEYS.EQUITY_CAPITAL,
        FINANCING_FORM_KEYS.BROKERAGE_FEE,
      ],
    },
  },
  {
    boxType: BoxType.iconBox,
    title: 'financing-portal.form.inputs.reason.boxes.follow-up-financing',
    value: FinancingReason.FollowUpFinancing,
    iconImage: followUpFinancingIcon,
    isSearchProgressField: false,
    propertyTypes: [
      FinancingPropertyType.HouseDetached,
      FinancingPropertyType.Condominium,
      FinancingPropertyType.HouseSemiDetached,
      FinancingPropertyType.HouseRowMiddle,
      FinancingPropertyType.TwoFamilyHouse,
      FinancingPropertyType.MultiFamilyHouse,
      FinancingPropertyType.HouseRowCorner,
      FinancingPropertyType.PlotOnly,
    ],
    fields: {
      secondStep: [
        FINANCING_FORM_KEYS.PROPERTY_TYPE,
        FINANCING_FORM_KEYS.LIVING_AREA,
        FINANCING_FORM_KEYS.BUILDING_YEAR,
        FINANCING_FORM_KEYS.PROPERTY_USE,
        FINANCING_FORM_KEYS.POST_CODE,
      ],
      thirdStep: [
        FINANCING_FORM_KEYS.PROPERTY_VALUE,
        FINANCING_FORM_KEYS.PAYOUT_DATE,
        FINANCING_FORM_KEYS.ESTIMATED_REMAINING_DEBT,
      ],
    },
  },
  {
    boxType: BoxType.iconBox,
    title: 'financing-portal.form.inputs.reason.boxes.own-construction-project',
    value: FinancingReason.OwnConstructionProject,
    iconImage: ownConstructionProjectIcon,
    isSearchProgressField: true,
    propertyTypes: [
      FinancingPropertyType.HouseDetached,
      FinancingPropertyType.HouseSemiDetached,
      FinancingPropertyType.HouseRowMiddle,
      FinancingPropertyType.TwoFamilyHouse,
      FinancingPropertyType.MultiFamilyHouse,
      FinancingPropertyType.HouseRowCorner,
    ],
    fields: {
      secondStep: [
        FINANCING_FORM_KEYS.PROPERTY_TYPE,
        FINANCING_FORM_KEYS.LIVING_AREA,
        FINANCING_FORM_KEYS.BUILDING_YEAR,
        FINANCING_FORM_KEYS.SEARCH_PROGRESS,
        FINANCING_FORM_KEYS.PROPERTY_USE,
        FINANCING_FORM_KEYS.POST_CODE,
      ],
      thirdStep: [
        FINANCING_FORM_KEYS.BUILDING_COSTS,
        FINANCING_FORM_KEYS.LAND_PRICE,
        FINANCING_FORM_KEYS.EQUITY_CAPITAL,
        FINANCING_FORM_KEYS.BROKERAGE_FEE,
      ],
    },
  },
  {
    boxType: BoxType.iconBox,
    title: 'financing-portal.form.inputs.reason.boxes.purchase-of-new-building',
    value: FinancingReason.PurchaseOfNewBuilding,
    iconImage: purchaseOfNewBuildingIcon,
    isSearchProgressField: true,
    propertyTypes: [
      FinancingPropertyType.HouseDetached,
      FinancingPropertyType.Condominium,
      FinancingPropertyType.HouseSemiDetached,
      FinancingPropertyType.HouseRowMiddle,
      FinancingPropertyType.TwoFamilyHouse,
      FinancingPropertyType.MultiFamilyHouse,
      FinancingPropertyType.HouseRowCorner,
      FinancingPropertyType.PlotOnly,
    ],
    fields: {
      secondStep: [
        FINANCING_FORM_KEYS.PROPERTY_TYPE,
        FINANCING_FORM_KEYS.LIVING_AREA,
        FINANCING_FORM_KEYS.BUILDING_YEAR,
        FINANCING_FORM_KEYS.SEARCH_PROGRESS,
        FINANCING_FORM_KEYS.PROPERTY_USE,
        FINANCING_FORM_KEYS.POST_CODE,
      ],
      thirdStep: [
        FINANCING_FORM_KEYS.PURCHASE_PRICE,
        FINANCING_FORM_KEYS.EQUITY_CAPITAL,
        FINANCING_FORM_KEYS.BROKERAGE_FEE,
      ],
    },
  },
  {
    boxType: BoxType.iconBox,
    title: 'financing-portal.form.inputs.reason.boxes.modernization',
    value: FinancingReason.Modernization,
    iconImage: modernizationIcon,
    isSearchProgressField: false,
    propertyTypes: [
      FinancingPropertyType.HouseDetached,
      FinancingPropertyType.Condominium,
      FinancingPropertyType.HouseSemiDetached,
      FinancingPropertyType.HouseRowMiddle,
      FinancingPropertyType.TwoFamilyHouse,
      FinancingPropertyType.MultiFamilyHouse,
      FinancingPropertyType.HouseRowCorner,
    ],
    fields: {
      secondStep: [
        FINANCING_FORM_KEYS.PROPERTY_TYPE,
        FINANCING_FORM_KEYS.LIVING_AREA,
        FINANCING_FORM_KEYS.BUILDING_YEAR,
        FINANCING_FORM_KEYS.PROPERTY_USE,
        FINANCING_FORM_KEYS.POST_CODE,
      ],
      thirdStep: [
        FINANCING_FORM_KEYS.PROPERTY_VALUE,
        FINANCING_FORM_KEYS.DESIRED_LOAN_AMOUNT,
      ],
    },
  },
  {
    boxType: BoxType.iconBox,
    title: 'financing-portal.form.inputs.reason.boxes.raising-capital',
    value: FinancingReason.RaisingCapital,
    iconImage: raisingCapitalIcon,
    isSearchProgressField: false,
    propertyTypes: [
      FinancingPropertyType.HouseDetached,
      FinancingPropertyType.Condominium,
      FinancingPropertyType.HouseSemiDetached,
      FinancingPropertyType.HouseRowMiddle,
      FinancingPropertyType.TwoFamilyHouse,
      FinancingPropertyType.MultiFamilyHouse,
      FinancingPropertyType.HouseRowCorner,
      FinancingPropertyType.PlotOnly,
    ],
    fields: {
      secondStep: [
        FINANCING_FORM_KEYS.PROPERTY_TYPE,
        FINANCING_FORM_KEYS.LIVING_AREA,
        FINANCING_FORM_KEYS.BUILDING_YEAR,
        FINANCING_FORM_KEYS.PROPERTY_USE,
        FINANCING_FORM_KEYS.POST_CODE,
      ],
      thirdStep: [
        FINANCING_FORM_KEYS.PROPERTY_VALUE,
        FINANCING_FORM_KEYS.DESIRED_LOAN_AMOUNT,
      ],
    },
  },
  // {
  //   boxType: BoxType.iconBox,
  //   title: 'financing-portal.form.inputs.reason.boxes.installment-loan',
  //   value: FinancingReason.InstallmentLoan,
  //   iconImage: installmentLoanIcon,
  //   isSearchProgressField: false,
  //   propertyTypes: [],
  // },
];

export const propertyTypeBoxes = [
  {
    boxType: BoxType.iconBox,
    title: 'financing-portal.form.inputs.property-type.boxes.house-detached',
    value: FinancingPropertyType.HouseDetached,
    iconImage: houseDetachedIcon,
  },
  {
    boxType: BoxType.iconBox,
    title: 'financing-portal.form.inputs.property-type.boxes.condominium',
    value: FinancingPropertyType.Condominium,
    iconImage: condominiumIcon,
  },
  {
    boxType: BoxType.iconBox,
    title:
      'financing-portal.form.inputs.property-type.boxes.house-semi-detached',
    value: FinancingPropertyType.HouseSemiDetached,
    iconImage: houseSemiDetachedIcon,
  },
  {
    boxType: BoxType.iconBox,
    title: 'financing-portal.form.inputs.property-type.boxes.house-row-middle',
    value: FinancingPropertyType.HouseRowMiddle,
    iconImage: houseRowMiddleIcon,
  },
  {
    boxType: BoxType.iconBox,
    title: 'financing-portal.form.inputs.property-type.boxes.two-family-house',
    value: FinancingPropertyType.TwoFamilyHouse,
    iconImage: twoFamilyHouseIcon,
  },
  {
    boxType: BoxType.iconBox,
    title:
      'financing-portal.form.inputs.property-type.boxes.multi-family-house',
    value: FinancingPropertyType.MultiFamilyHouse,
    iconImage: multiFamilyHouseIcon,
  },
  {
    boxType: BoxType.iconBox,
    title: 'financing-portal.form.inputs.property-type.boxes.house-row-corner',
    value: FinancingPropertyType.HouseRowCorner,
    iconImage: houseRowCornerIcon,
  },
  {
    boxType: BoxType.iconBox,
    title: 'financing-portal.form.inputs.property-type.boxes.plot-only',
    value: FinancingPropertyType.PlotOnly,
    iconImage: plotOnlyIcon,
  },
];

export const propertyUseOptions = [
  {
    value: PropertyUse.SelfUsed,
    label: 'financing-portal.form.inputs.property-use.options.self-used',
  },
  {
    value: PropertyUse.PartlyRented,
    label: 'financing-portal.form.inputs.property-use.options.partly-rented',
  },
  {
    value: PropertyUse.Rented,
    label: 'financing-portal.form.inputs.property-use.options.rented',
  },
];

// export const projectStatusOptions = [
//   { value: ProjectStatus.PlanningStage, label: 'Noch in Planung' },
//   {
//     value: ProjectStatus.AlreadyVisited,
//     label: 'Immobilie bereits besichtigt',
//   },
//   {
//     value: ProjectStatus.OwnerNegotiation,
//     label: 'Mit Eigentümer in Kaufverhandlung',
//   },
//   {
//     value: ProjectStatus.ExaminationOfFinancialFeasibility,
//     label: 'Prüfung finanzielle Machbarkeit',
//   },
// ];

export const propertySearchProgressOptions = [
  {
    value: SearchProgress.PropertyNotVisited,
    label:
      'financing-portal.form.inputs.search-progress.options.property-not-visited',
  },
  {
    value: SearchProgress.PropertyInspected,
    label:
      'financing-portal.form.inputs.search-progress.options.property-inspected',
  },
  {
    value: SearchProgress.InPurchaseNegotiations,
    label:
      'financing-portal.form.inputs.search-progress.options.in-purchase-negotiations',
  },
  // {
  //   value: SearchProgress.BudgetCheckOnly,
  //   label:
  //     'financing-portal.form.inputs.search-progress.options.budget-check-only',
  // },
];

const repaymentRateOptions = [
  { label: '1,00%', value: 1 },
  { label: '1,50%', value: 1.5 },
  { label: '2,00%', value: 2 },
  { label: '2,50%', value: 2.5 },
  { label: '3,00%', value: 3 },
  { label: '3,50%', value: 3.5 },
  { label: '4,00%', value: 4 },
  { label: '4,50%', value: 4.5 },
  { label: '5,00%', value: 5 },
  { label: '5,50%', value: 5.5 },
  { label: '6,00%', value: 6 },
  { label: '6,50%', value: 6.5 },
  { label: '7,00%', value: 7 },
  { label: '7,50%', value: 7.5 },
];

export type TField = {
  name: string;
  containerTitle?: string;
  type?: string;
  customType?: ICustomFieldType;
  tooltipKey?: string;
  isTooltipKeyLeftSide?: boolean;
  label: string;
  placeholder?: string;
  inputAdornment?: string;
  isDisabled?: boolean;
  isSeparate?: boolean;

  isDecimalNumber?: boolean;
  isPercent?: boolean;
  isWrapper?: boolean;
  defaultValue?: number | boolean;
  isWrap?: boolean;
  minWidthBox?: number;
  boxMaxWidth?: number;
  isRequired?: boolean;
  isBoxTabletColumn?: boolean;
  isTabletTwoBoxesColumn?: boolean;
  isCollapsible?: boolean;
  isValidateOnBlur?: boolean;
  validateNames?: string[];
  initHeight?: number;
  isHidden?: boolean;
  boxGap?: number;
  boxTabletGap?: number;
  usefulLink?: { label: string; link: string };
  rules?: RegisterOptions;
  boxes?: IBox[];
  options?: IOption[];
  onClick?: () => void;
  handleChangeProp?: (value: number | string | boolean) => void;
};

export const FIELDS: Record<string, TField> = {
  REASON: {
    // finanzierungszweck
    name: FINANCING_FORM_KEYS.REASON,
    customType: ICustomFieldType.BoxRating,
    minWidthBox: 179,
    isWrap: true,
    boxMaxWidth: 179,
    boxes: reasonBoxes,
    label: '',
    isBoxTabletColumn: false,
    boxGap: 12,
    boxTabletGap: 12,
  },
  PROPERTY_TYPE: {
    // objektArt
    name: FINANCING_FORM_KEYS.PROPERTY_TYPE,
    customType: ICustomFieldType.BoxRating,
    minWidthBox: 179,
    isWrap: true,
    boxMaxWidth: 179,
    boxes: propertyTypeBoxes,
    label: '',
    isBoxTabletColumn: true,
    isTabletTwoBoxesColumn: true,
    boxGap: 12,
    boxTabletGap: 12,
  },
  LIVING_AREA: {
    // Wohnfläche
    name: FINANCING_FORM_KEYS.LIVING_AREA,
    type: 'number',
    inputAdornment: 'm²',
    label: 'financing-portal.form.inputs.living-area.label',
    placeholder: 'financing-portal.form.inputs.living-area.placeholder',
    isValidateOnBlur: true,
    isCollapsible: true,
    // isDecimalNumber: true,
    rules: {
      // required: 'register.input.error.required',
      // if maximum purchase price min value 17.000
      min: {
        value: 20,
        message: 'financing-portal.form.inputs.living-area.errors.min-value', // net income 1600
      },
      max: {
        value: 2000,
        message: 'financing-portal.form.inputs.living-area.errors.max-value',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  BUILDING_YEAR: {
    // Baujahr
    name: FINANCING_FORM_KEYS.BUILDING_YEAR,
    type: 'number',
    label: 'financing-portal.form.inputs.building-year.label',
    placeholder: 'financing-portal.form.inputs.building-year.placeholder',
    isCollapsible: true,
    isValidateOnBlur: true,
    // isDecimalNumber: true,
    rules: {
      // required: 'register.input.error.required',
      // if maximum purchase price min value 17.000
      min: {
        value: 1850,
        message: 'financing-portal.form.inputs.building-year.errors.min-value', // net income 1600
      },
      max: {
        value: new Date().getFullYear(),
        message: 'financing-portal.form.inputs.building-year.errors.max-value',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  RENTAL_INCOME_PER_MONTH: {
    // Baujahr
    name: FINANCING_FORM_KEYS.RENTAL_INCOME_PER_MONTH,
    type: 'number',
    label: 'financing-portal.form.inputs.rental-income-per-month.label',
    placeholder:
      'financing-portal.form.inputs.rental-income-per-month.placeholder',
    isCollapsible: true,
    tooltipKey: 'financing-portal.form.inputs.rental-income-per-month.tooltip',
    isTooltipKeyLeftSide: true,
    isValidateOnBlur: true,
    isSeparate: true,
    inputAdornment: '€',
    // isDecimalNumber: true,
    rules: {
      // required: 'register.input.error.required',
      // if maximum purchase price min value 17.000
      min: {
        value: 0,
        message:
          'financing-portal.form.inputs.rental-income-per-month.errors.min-value', // net income 1600
      },
      max: {
        value: 100000,
        message:
          'financing-portal.form.inputs.rental-income-per-month.errors.max-value',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  SEARCH_PROGRESS: {
    // Doesn't find
    // Fortschritt der Immobiliensuche
    name: FINANCING_FORM_KEYS.SEARCH_PROGRESS,
    label: 'financing-portal.form.inputs.search-progress.label',
    placeholder: 'financing-portal.form.inputs.search-progress.placeholder',
    customType: ICustomFieldType.Select,
    rules: {
      required: 'register.input.error.required',
    },
    options: propertySearchProgressOptions,
  },
  PROPERTY_USE: {
    // vermietet, gewerblicheNutzung
    // Immobiliennutzung
    name: FINANCING_FORM_KEYS.PROPERTY_USE,
    label: 'financing-portal.form.inputs.property-use.label',
    placeholder: 'financing-portal.form.inputs.property-use.placeholder',
    customType: ICustomFieldType.Select,
    options: propertyUseOptions,
    rules: {
      required: 'register.input.error.required',
    },
  },
  PURCHASE_PRICE: {
    // kaufpreis
    // Kaufpreis
    name: FINANCING_FORM_KEYS.PURCHASE_PRICE,
    type: 'number',
    tooltipKey: 'financing-portal.form.inputs.purchase-price.tooltip',
    isTooltipKeyLeftSide: true,
    inputAdornment: '€',
    label: 'financing-portal.form.inputs.purchase-price.label',
    placeholder: 'financing-portal.form.inputs.purchase-price.placeholder',
    isRequired: true,
    isSeparate: true,
    isValidateOnBlur: true,
    // isDecimalNumber: true,
    usefulLink: {
      label: 'financing-portal.form.inputs.purchase-price.link',
      link: `${siteMap.OwnerLandingPage.path}?online-modal=true`,
    },
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 0,
        message: 'financing-portal.form.inputs.purchase-price.errors.min-value',
      },
      max: {
        value: 10_000_000,
        message: 'financing-portal.form.inputs.purchase-price.errors.max-value',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  PLACE_NAME: {
    // anschrift
    // Wo liegt das Objekt (Postleitzahl)?
    name: FINANCING_FORM_KEYS.PLACE_NAME,
    customType: ICustomFieldType.FinancingAddress,
    label: 'financing-portal.form.inputs.city.label',
    placeholder: 'financing-portal.form.inputs.city.placeholder',
    isRequired: true,
  },
  EQUITY_CAPITAL: {
    // eigenKapital
    // Eigenkapital
    name: FINANCING_FORM_KEYS.EQUITY_CAPITAL,
    type: 'number',
    tooltipKey: 'financing-portal.form.inputs.equity-capital.tooltip',
    isTooltipKeyLeftSide: true,
    inputAdornment: '€',
    label: 'financing-portal.form.inputs.equity-capital.label',
    placeholder: 'financing-portal.form.inputs.equity-capital.placeholder',
    isRequired: true,
    isSeparate: true,
    isValidateOnBlur: true,
    // isDecimalNumber: true,
    rules: {
      required: 'register.input.error.required',
      // if maximum purchase price min value 17.000
      min: {
        value: 0,
        message: 'financing-portal.form.inputs.equity-capital.errors.min-value', // net income 1600
      },
      max: {
        value: 10_000_000,
        message: 'financing-portal.form.inputs.equity-capital.errors.max-value',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  BROKERAGE_FEE: {
    // Maklergebühr
    name: FINANCING_FORM_KEYS.BROKERAGE_FEE,
    customType: ICustomFieldType.BooleanBoxes,
    tooltipKey: 'financing-portal.form.inputs.brokerage-fee.tooltip',
    isTooltipKeyLeftSide: true,
    label: 'financing-portal.form.inputs.brokerage-fee.label',
    isRequired: true,
    boxTabletGap: 12,
    defaultValue: true,
    boxes: [
      {
        value: true,
        title: 'financing-portal.form.inputs.brokerage-fee.boxes.true',
        boxType: BoxType.radioBox,
      },
      {
        value: false,
        title: 'financing-portal.form.inputs.brokerage-fee.boxes.false',
        boxType: BoxType.radioBox,
      },
    ],
  },
  BROKER_FEE_PERCENT: {
    // TODO find
    // Maklergebühr Prozent
    name: FINANCING_FORM_KEYS.BROKER_FEE_PERCENT,
    type: 'number',
    // tooltipKey: 'property-valuation.form.redesign.building-year.tooltip',
    inputAdornment: '%',
    label: 'financing-portal.form.inputs.broker-fee-percent.label',
    placeholder: 'financing-portal.form.inputs.broker-fee-percent.placeholder',
    isRequired: true,
    isSeparate: true,
    isValidateOnBlur: true,
    isPercent: true,
    defaultValue: 3.57,
    isDecimalNumber: true,
    isCollapsible: true,
    initHeight: 64,
    // isSeparate: true,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 0,
        message:
          'financing-portal.form.inputs.broker-fee-percent.errors.min-value',
      },
      max: {
        value: 100,
        message:
          'financing-portal.form.inputs.broker-fee-percent.errors.max-value',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  // IS_MAXIMUM_PURCHASE_PRICE_BUDGET: {
  //   // metadaten
  //   // Maximales Kaufpreisbudget berechnen
  //   tooltipKey:
  //     'financing-portal.form.inputs.is-maximum-purchase-price-budget.tooltip',
  //   name: FINANCING_FORM_KEYS.IS_MAXIMUM_PURCHASE_PRICE_BUDGET,
  //   customType: ICustomFieldType.Switch,
  //   label:
  //     'financing-portal.form.inputs.is-maximum-purchase-price-budget.label',
  //   isRequired: true,
  // },
  // MONTHLY_AVAILABLE_AMOUNT: {
  //   // einkommenNetto
  //   // Ihr monatlich verfügbarer Betrag
  //   name: FINANCING_FORM_KEYS.MONTHLY_AVAILABLE_AMOUNT,
  //   type: 'number',
  //   inputAdornment: '€',
  //   label: 'financing-portal.form.inputs.monthly-available-amount.label',
  //   placeholder:
  //     'financing-portal.form.inputs.monthly-available-amount.placeholder',
  //   isRequired: true,
  //   isSeparate: true,
  //   // isDecimalNumber: true,
  //   isCollapsible: true,
  //   initHeight: 0,
  //   rules: {
  //     required: 'register.input.error.required',
  //     min: {
  //       value: 0,
  //       message: 'Min value 0',
  //     },
  //     max: {
  //       value: 100_000,
  //       message: 'Max value 100.000',
  //     },
  //     valueAsNumber: true,
  //     setValueAs: (v: string): number => Number.parseInt(v, 10),
  //   },
  // },
  BUILDING_COSTS: {
    // herstellungskostenInklusiveEigenleistungen
    // Baukosten
    name: FINANCING_FORM_KEYS.BUILDING_COSTS,
    type: 'number',
    tooltipKey: 'financing-portal.form.inputs.building-costs.tooltip',
    isTooltipKeyLeftSide: true,
    inputAdornment: '€',
    label: 'financing-portal.form.inputs.building-costs.label',
    placeholder: 'financing-portal.form.inputs.building-costs.placeholder',
    isRequired: true,
    isSeparate: true,
    isValidateOnBlur: true,
    // isDecimalNumber: true,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 0,
        message: 'financing-portal.form.inputs.building-costs.errors.min-value',
      },
      max: {
        value: 100_000_000,
        message: 'financing-portal.form.inputs.building-costs.errors.max-value',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  LAND_PRICE: {
    // grundstueckKaufpreis
    // grundstueckBereitsBezahlt !!LAND_PRICE
    // Grundstückspreis
    name: FINANCING_FORM_KEYS.LAND_PRICE,
    type: 'number',
    tooltipKey: 'financing-portal.form.inputs.land-price.tooltip',
    isTooltipKeyLeftSide: true,
    inputAdornment: '€',
    label: 'financing-portal.form.inputs.land-price.label',
    placeholder: 'financing-portal.form.inputs.land-price.placeholder',
    isRequired: true,
    isSeparate: true,
    isValidateOnBlur: true,
    // isDecimalNumber: true,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 0,
        message: 'financing-portal.form.inputs.land-price.errors.min-value',
      },
      max: {
        value: 1_000_000,
        message: 'financing-portal.form.inputs.land-price.errors.max-value',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  PROPERTY_VALUE: {
    // marktwert
    // Immobilienwert
    name: FINANCING_FORM_KEYS.PROPERTY_VALUE,
    type: 'number',
    tooltipKey: 'financing-portal.form.inputs.property-value.tooltip',
    isTooltipKeyLeftSide: true,
    inputAdornment: '€',
    label: 'financing-portal.form.inputs.property-value.label',
    placeholder: 'financing-portal.form.inputs.property-value.placeholder',
    isRequired: true,
    isSeparate: true,
    isValidateOnBlur: true,
    usefulLink: {
      label: 'financing-portal.form.inputs.purchase-price.link',
      link: `${siteMap.OwnerLandingPage.path}?online-modal=true`,
    },
    // isDecimalNumber: true,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 0,
        message: 'financing-portal.form.inputs.property-value.errors.min-value',
      },
      max: {
        value: 10_000_000,
        message: 'financing-portal.form.inputs.property-value.errors.max-value',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  DESIRED_LOAN_AMOUNT: {
    // darlehensSumme
    // Gewünschter Darlehensbetrag
    name: FINANCING_FORM_KEYS.DESIRED_LOAN_AMOUNT,
    type: 'number',
    inputAdornment: '€',
    tooltipKey: 'financing-portal.form.inputs.desired-loan-amount.tooltip',
    isTooltipKeyLeftSide: true,
    label: 'financing-portal.form.inputs.desired-loan-amount.label',
    placeholder: 'financing-portal.form.inputs.desired-loan-amount.placeholder',
    isRequired: true,
    isSeparate: true,
    isValidateOnBlur: true,
    // isDecimalNumber: true,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 0,
        message:
          'financing-portal.form.inputs.desired-loan-amount.errors.min-value',
      },
      max: {
        value: 10_000_000,
        message:
          'financing-portal.form.inputs.desired-loan-amount.errors.max-value',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  MONTHLY_RATE: {
    // praeferenzen/rate
    // Monatsrate
    name: FINANCING_FORM_KEYS.MONTHLY_RATE,
    type: 'number',
    inputAdornment: '€',
    tooltipKey: 'financing-portal.form.inputs.monthly-rate.tooltip',
    isTooltipKeyLeftSide: true,
    label: 'financing-portal.form.inputs.monthly-rate.label',
    placeholder: 'financing-portal.form.inputs.monthly-rate.placeholder',
    isRequired: true,
    isSeparate: true,
    isValidateOnBlur: true,
    // isDecimalNumber: true,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 0,
        message: 'financing-portal.form.inputs.monthly-rate.errors.min-value',
      },
      max: {
        value: 10_000,
        message: 'financing-portal.form.inputs.monthly-rate.errors.max-value',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  ESTIMATED_REMAINING_DEBT: {
    // darlehensliste/restschuld/zumAbloeseTermin
    // Voraussichtliche Restschuld
    name: FINANCING_FORM_KEYS.ESTIMATED_REMAINING_DEBT,
    type: 'number',
    inputAdornment: '€',
    tooltipKey: 'financing-portal.form.inputs.estimated-remaining-debt.tooltip',
    isTooltipKeyLeftSide: true,
    label: 'financing-portal.form.inputs.estimated-remaining-debt.label',
    placeholder:
      'financing-portal.form.inputs.estimated-remaining-debt.placeholder',
    isRequired: true,
    isSeparate: true,
    isValidateOnBlur: true,
    // isDecimalNumber: true,
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1000,
        message:
          'financing-portal.form.inputs.estimated-remaining-debt.errors.min-value',
      },
      max: {
        value: 10_000_000,
        message:
          'financing-portal.form.inputs.estimated-remaining-debt.errors.max-value',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  PAYOUT_DATE: {
    // auszahlungszeitpunkt
    // Auszahlungsdatum
    name: FINANCING_FORM_KEYS.PAYOUT_DATE,
    tooltipKey: 'property-valuation.form.redesign.payout-date.tooltip',
    isTooltipKeyLeftSide: true,
    customType: ICustomFieldType.Date,
    label: 'financing-portal.form.inputs.payout-date.label',
    placeholder: 'financing-portal.form.inputs.payout-date.placeholder',
    isRequired: true,
    isValidateOnBlur: true,
  },
  HOUSEHOLD_NET_INCOME: {
    name: REGISTER_FORM_KEYS.HOUSEHOLD_NET_INCOME,
    type: 'number',
    inputAdornment: '€',
    label: 'register.form.input.label.household-net-income',
    placeholder: 'register.form.input.placeholder.household-net-income',
    isRequired: true,
    isSeparate: true,
    isValidateOnBlur: true,
    // isDecimalNumber: true,
    // if maximum purchase price min value 1700
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1750,
        message: 'register.form.input.household-net-income.errors.min-value',
      },
      max: {
        value: 100_000,
        message: 'register.form.input.household-net-income.errors.max-value',
      },
      valueAsNumber: true,
      setValueAs: (v: string): number => Number.parseInt(v, 10),
    },
  },
  MAIN_EARNER: {
    name: REGISTER_FORM_KEYS.MAIN_EARNER,
    label: 'register.form.input.label.main-earner',
    placeholder: 'register.form.input.placeholder.main-earner',
    isRequired: true,
    isSeparate: true,
    isValidateOnBlur: true,
    options: mainEarnerOptions,
    rules: {
      required: 'register.input.error.required',
    },
  },
  AGE: {
    name: REGISTER_FORM_KEYS.AGE,
    type: 'number',
    label: 'register.form.input.label.age',
    placeholder: 'register.form.input.placeholder.age',
    isRequired: true,
    isSeparate: true,
    isValidateOnBlur: true,
    // isDecimalNumber: true,
    // if maximum purchase price min value 1700
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 18,
        message: 'register.form.input.age.errors.min-value',
      },
      max: {
        value: 90,
        message: 'register.form.input.age.errors.max-value',
      },
    },
  },
  REPAYMENT_RATE: {
    name: FINANCING_FORM_KEYS.REPAYMENT_RATE,
    customType: ICustomFieldType.Select,
    label:
      'financing-portal-page.tabs.calculator.edit.inputs.repayment-rate.label',
    placeholder:
      'financing-portal-page.tabs.calculator.edit.inputs.repayment-rate.placeholder',
    tooltipKey:
      'financing-portal-page.tabs.calculator.edit.inputs.repayment-rate.tooltip',
    isRequired: true,
    rules: {
      required: 'register.input.error.required',
    },
    options: repaymentRateOptions,
  },
  DESIRED_RATE: {
    name: FINANCING_FORM_KEYS.DESIRED_RATE,
    type: 'number',
    label:
      'financing-portal-page.tabs.calculator.edit.inputs.desired-rate.label',
    placeholder:
      'financing-portal-page.tabs.calculator.edit.inputs.desired-rate.placeholder',
    tooltipKey:
      'financing-portal-page.tabs.calculator.edit.inputs.desired-rate.tooltip',
    isRequired: true,
    isSeparate: true,
    // isDecimalNumber: true,
    // if maximum purchase price min value 1700
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 0,
        message: 'register.form.input.age.errors.min-value',
      },
      max: {
        value: 30_000,
        message: 'register.form.input.age.errors.max-value',
      },
    },
  },
  // TERMS_AND_CONDITIONS_FINANCING: {
  //   name: FINANCING_FORM_KEYS.TERMS_AND_CONDITIONS_FINANCING,
  //   type: 'boolean',
  //   customType: ICustomFieldType.Checkbox,
  //   label: 'financing-portal-page.tabs.calculator.edit.inputs.checkbox.label',
  //   usefulLink: {
  //     label:
  //       'financing-portal-page.tabs.calculator.edit.inputs.checkbox.label-link',
  //     link: 'https://www.vp-finance.de/datenschutz',
  //   },
  //   isRequired: true,
  //   rules: {
  //     required: 'register.input.error.required',
  //   },
  // },
};
