import { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { device } from '../../../../style/theme';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import Icon from '../../../common/components/ui/icon';

interface IConditionCalculatorWrapper {
  children?: JSX.Element | JSX.Element[];
}

interface IConditionCalculatorRowParts {
  children?: JSX.Element | JSX.Element[];
}

interface IConditionCalculatorStarWrapper {
  children?: JSX.Element | JSX.Element[];
  rowNumber?: number;
  hasTopLeftBorderRadius?: boolean;
  hasTopRightBorderRadius?: boolean;
  hasBottomLeftBorderRadius?: boolean;
  hasBottomRightBorderRadius?: boolean;
  borders?: string;
}

interface IConditionCalculatorClarification {
  text: string;
  isError?: boolean;
}

interface IConditionCalculatorRating {
  ratingStartsNumber: { label: string; value: number }[];
  value: number;
  newValue: number;
  type: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  onChange: (type: string, value: number) => void;
}

const CCWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 12px 0;
`;

const CCRow = styled.div<{ mobileStart?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.mobileStart &&
    `
    @media ${device.tablet} {
      justify-content: flex-start;
    }
  `}
`;

const CCRatingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.tablet} {
    justify-content: flex-end;
  }
`;

const CCRatingRow = styled.div`
  width: 100%;
  display: flex;
  @media ${device.tablet} {
    flex-direction: column;
    border-width: 1px;
    border-color: ${(props) => props.theme.ctaDisabledBackground};
    border-style: solid;
    border-radius: 3px;
    margin: 4px 0;
  }
`;

const CCRowLeft = styled.div<{
  borders?: string;
  borderRadiuses?: string;
  margin?: string;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  margin: ${({ margin }) => margin ?? 0};
  & span {
    font-size: 14px;
    color: ${(props) => props.theme.blue};
    font-family: Roboto;
    line-height: 20px;
    letter-spacing: 1px;
    font-weight: bold;
  }
  border-width: ${(props) => props.borders ?? '0 0 0 0'};
  border-color: ${(props) => props.theme.ctaDisabledBackground};
  border-style: solid;
  border-radius: ${(props) => props.borderRadiuses ?? '0 0 0 0'};
  @media ${device.tablet} {
    border-width: 0 0 1px 0;
    border-radius: 3px 3px 0 0;
  }
`;

const CCRowRight = styled.div<{
  rowNumber?: number;
  hasTopLeftBorderRadius?: boolean;
  hasTopRightBorderRadius?: boolean;
  hasBottomLeftBorderRadius?: boolean;
  hasBottomRightBorderRadius?: boolean;
  borders?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  flex: 1 0 38%;
  max-width: 38%;
  border-width: ${({ borders }) => borders ?? `1px 1px 0 0px`};
  border-color: #d9d9d9;
  border-style: solid;
  border-top-left-radius: ${(props) =>
    props.hasTopLeftBorderRadius ? '3px' : 0};
  border-top-right-radius: ${(props) =>
    props.hasTopRightBorderRadius ? '3px' : 0};
  border-bottom-left-radius: ${(props) =>
    props.hasBottomLeftBorderRadius ? '3px' : 0};
  border-bottom-right-radius: ${(props) =>
    props.hasBottomRightBorderRadius ? '3px' : 0};
  @media ${device.tablet} {
    border-width: 0;
    flex-direction: column;
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
`;

const CCStarWrapper = styled.div<{ skipBorder?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 50%;
  max-width: 50%;
  overflow: hidden;
  border-width: 0 0 0 ${({ skipBorder }) => (skipBorder ? '0' : '1px')};
  border-color: ${(props) => props.theme.ctaDisabledBackground};
  border-style: solid;
  padding: 8px;
  font-size: 10px;
  color: ${(props) => props.theme.blue};
  font-family: Roboto;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: 900;
  & span {
    opacity: 0.5;
  }
  ${({ skipBorder }) =>
    skipBorder &&
    `
    + div {
      margin-left: -1px;
    }
  `}
  @media ${device.tablet} {
    justify-content: space-between;
    margin: 0;
    width: 100%;
    max-width: 100%;
    border-width: 0;
  }
`;

const CCClarificationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0 0 1px 1px;
  border-color: #d9d9d9;
  border-style: solid;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
`;

const CCClarification = styled.div<{ isError?: boolean }>`
  font-size: 10px;
  color: ${(props) => props.theme.blue};
  font-family: Roboto, sans-serif;
  padding: 8px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: bold;
  & span {
    opacity: 0.8;
  }
  background-color: ${(props) => (props.isError ? 'red' : '')};
  transition: background-color 0.5s ease-in-out;
`;

const IconWrapper = styled.div<{ hasValue: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: ${(props) => props.theme.ctaDisabledBackground};
  border-style: solid;
  border-radius: 3px;
  text-align: center;
  padding: 2px;
  margin: 2px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  cursor: pointer;
  @media ${device.tablet} {
    width: 32px;
    height: 32px;
    margin: 2px 6px;
    border-color: ${(props) =>
      props.hasValue ? props.theme.grey : props.theme.ctaDisabledBackground};
  }
`;

export const ConditionCalculatorWrapper = ({
  children,
}: IConditionCalculatorWrapper): JSX.Element => {
  return <CCWrapper>{children}</CCWrapper>;
};

export const ConditionCalculatorRow = ({
  children,
  mobileStart,
}: IConditionCalculatorWrapper & { mobileStart?: boolean }): JSX.Element => {
  return <CCRow mobileStart={mobileStart}>{children}</CCRow>;
};

export const ConditionCalculatorRatingWrapper = ({
  children,
}: IConditionCalculatorWrapper): JSX.Element => {
  return <CCRatingWrapper>{children}</CCRatingWrapper>;
};

export const ConditionCalculatorRatingRow = ({
  children,
}: IConditionCalculatorRowParts): JSX.Element => {
  return <CCRatingRow>{children}</CCRatingRow>;
};

export const ConditionCalculatorRowLeft = ({
  children,
  borders,
  borderRadiuses,
  margin,
}: IConditionCalculatorRowParts & {
  borders?: string;
  borderRadiuses?: string;
  margin?: string;
}): JSX.Element => {
  return (
    <CCRowLeft
      borders={borders}
      borderRadiuses={borderRadiuses}
      margin={margin}
    >
      {children}
    </CCRowLeft>
  );
};

export const ConditionCalculatorRowRight = ({
  children,
  rowNumber,
  hasTopLeftBorderRadius,
  hasTopRightBorderRadius,
  hasBottomLeftBorderRadius,
  hasBottomRightBorderRadius,
  borders,
}: IConditionCalculatorStarWrapper): JSX.Element => {
  return (
    <CCRowRight
      rowNumber={rowNumber}
      hasTopLeftBorderRadius={hasTopLeftBorderRadius}
      hasTopRightBorderRadius={hasTopRightBorderRadius}
      hasBottomLeftBorderRadius={hasBottomLeftBorderRadius}
      hasBottomRightBorderRadius={hasBottomRightBorderRadius}
      borders={borders}
    >
      {children}
    </CCRowRight>
  );
};

export const ConditionCalculatorStarWrapper = ({
  children,
  skipBorder,
}: IConditionCalculatorWrapper & { skipBorder?: boolean }): JSX.Element => {
  return <CCStarWrapper skipBorder={skipBorder}>{children}</CCStarWrapper>;
};

export const ConditionClarificationWrapper = ({
  children,
}: IConditionCalculatorWrapper): JSX.Element => {
  return <CCClarificationWrapper>{children}</CCClarificationWrapper>;
};

export const ConditionClarification = ({
  text,
  isError,
}: IConditionCalculatorClarification): JSX.Element => {
  return <CCClarification isError={isError}>{text}</CCClarification>;
};

export const ConditionRating = ({
  ratingStartsNumber,
  value,
  newValue,
  type,
  icon,
  onChange,
}: IConditionCalculatorRating): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const [addedStars, setAddedStars] = useState(newValue || 0);
  const isMobileSize = useIsMobileSize();
  const ratingValue = newValue || value;
  return (
    <>
      {ratingStartsNumber.map((_, index: number) => (
        <IconWrapper
          key={`rating-icon-${type}-${index}`}
          onMouseEnter={(): void => {
            setAddedStars(index + 1);
          }}
          onMouseLeave={(): void => setAddedStars(newValue || 0)}
          onMouseDown={(): void => onChange(type, index + 1)}
          hasValue={value > index}
        >
          <Icon
            icon={icon}
            height={isMobileSize ? 16 : 14}
            width={isMobileSize ? 16 : 14}
            color={
              value > index
                ? themeContext.blue
                : (addedStars > index && themeContext.ctaBlue) ||
                  themeContext.ctaDisabledBackground
            }
            fillColor={
              value > index
                ? ratingValue > index
                  ? themeContext.blue
                  : (addedStars > index && themeContext.blue) ||
                    themeContext.white
                : (addedStars > index && themeContext.ctaBlue) ||
                  themeContext.ctaDisabledBackground
            }
          />
        </IconWrapper>
      ))}
    </>
  );
};
