import { useFormContext } from 'react-hook-form';
import { MouseEvent } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { IValuationForm } from '../../../form-property-valuation-wizard/interface';
import {
  nextStepAction as nextStepActionV2,
  updateFormStateAction as updateFormStateActionV2,
} from '../../redux/valuationWizardV2Slice';
import { PropertyCode } from '../../../../../generated';

type TProps = {
  watchedKeys: string[];
  isSubmitForm?: boolean;
  buttonCallback?: (input: Record<string, unknown>) => Promise<void>;
};

type TReturn = {
  onClickNext: (e?: MouseEvent) => void;
};

const useNextHandler = ({
  watchedKeys,
  isSubmitForm,
  buttonCallback,
}: TProps): TReturn => {
  const dispatch = useAppDispatch();
  const { trigger, watch } = useFormContext();
  const activeStep = useAppSelector(
    (state) => state.valuationWizardV2.activeStep
  );

  const onClickNext = async (e?: MouseEvent) => {
    e?.preventDefault();

    const isFormValid = await trigger(watchedKeys);
    let watchedStepState = watch(watchedKeys);

    if (watchedKeys && watchedKeys.length > 0) {
      watchedStepState = watchedStepState.slice(0, watchedKeys.length);
    }

    if (isFormValid) {
      if (isSubmitForm) {
        return;
      }

      const formState = watchedKeys.reduce(
        (acc, _, i) => ({
          ...acc,
          [watchedKeys[i]]: watchedStepState[i],
        }),
        {}
      ) as Partial<IValuationForm>;

      dispatch(updateFormStateActionV2(formState));

      if (buttonCallback) {
        await buttonCallback(formState);
        return;
      }

      if (
        activeStep === 0 &&
        formState.code === PropertyCode.MultiFamilyHouse
      ) {
        dispatch(nextStepActionV2(2));
      } else {
        dispatch(nextStepActionV2());
      }
    }
  };

  return { onClickNext };
};

export { useNextHandler };
