import { useAppSelector } from '../../common/hooks';
import { selectRegisterExperiment } from '../redux/selectors/selectRegisterExperiment';
import {
  EvaluationWidgetVersion,
  RegistrationBy,
  SelectedCase,
} from '../../../generated';

type TReturn = {
  isRegistrationByEmail: boolean;
  isRegistrationByEmailWithMobile: boolean;
  isRegistrationByEmailOnly: boolean;
  isRedesignedWidget: boolean;
  isBCase: boolean;
  registrationBy?: RegistrationBy;
};

const useActualExperiment = (): TReturn => {
  const savedExperimentSelectedCase = useAppSelector(selectRegisterExperiment);

  const registrationBy = savedExperimentSelectedCase?.registrationBy;
  const isRegistrationByEmail =
    savedExperimentSelectedCase?.registrationBy === RegistrationBy.Email ||
    savedExperimentSelectedCase?.registrationBy === RegistrationBy.EmailOnly;
  const isRegistrationByEmailWithMobile =
    savedExperimentSelectedCase?.registrationBy === RegistrationBy.Email;
  const isRegistrationByEmailOnly =
    savedExperimentSelectedCase?.registrationBy === RegistrationBy.EmailOnly;
  const isRedesignedWidget =
    savedExperimentSelectedCase?.evaluationWidgetVersion ===
    EvaluationWidgetVersion.NewRedesignedVersion;
  const isBCase = savedExperimentSelectedCase?.selectedCase === SelectedCase.B;

  return {
    isRegistrationByEmail,
    isRegistrationByEmailWithMobile,
    isRegistrationByEmailOnly,
    registrationBy,
    isRedesignedWidget,
    isBCase,
  };
};

export { useActualExperiment };
