import { FC, useState } from 'react';

import { LoginContainer } from '../../../../../components/fullscreen-modal/auth-container/login-container/login-container';
import { useSubmitFinancingLogin } from '../../../../hooks/submit-hooks/useSubmitFinancingLogin';
import { useSubmitFinancingForm } from '../../../../hooks/submit-hooks/useSubmitFinancingForm';

const FinanceLoginContainer: FC = () => {
  const [errors, setErrors] = useState<null | unknown>(null);

  const { onCreateFinancing } = useSubmitFinancingForm({});

  const { onLoginSubmit, onOpenForgotPassword } = useSubmitFinancingLogin({
    setErrors,
    onSuccess: onCreateFinancing,
  });

  return (
    <LoginContainer
      onLoginSubmit={onLoginSubmit}
      onOpenForgotPassword={onOpenForgotPassword}
      errors={errors}
    />
  );
};

export { FinanceLoginContainer };
