import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, InfoContainer, TourWindow } from './tour-360-styles';
import { Header } from '../energy-efficiency/energy-efficiency-styles';
import { useExposeDetailsTour360 } from '../../../../hooks/useExposeDetailsQueries';

interface IProps {
  link: string;
}

const Tour360: FC<IProps> = ({ link }) => {
  const { t } = useTranslation();
  // const { tour360Link } = useExposeDetailsTour360();

  if (!link) {
    return <></>;
  }

  return (
    <Container>
      <Header content={t('expose-page.tabs.overview.tour-360.header')} />
      <InfoContainer>
        <TourWindow title="tour 360" src={link} />
      </InfoContainer>
    </Container>
  );
};

export { Tour360 };
