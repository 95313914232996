import { FC, memo, useMemo } from 'react';
import { FirstStep } from './steps/first-step/first-step';
import { SecondStep } from './steps/second-step/second-step';
import { ThirdStep } from './steps/third-step/third-step';
import { FormError } from '../../../../interface';
import { useAppSelector } from '../../../../../common/hooks';
import { ModalForm } from '../../../../components/fullscreen-modal/modal-form/modal-form';
import { useGetFinancingTitle } from '../../../hooks/useGetFinancingTitle';
// import { IFinancingForm } from '../../../interface';
import { useGetProgressPercent } from '../../../hooks/useGetProgressPercent';

interface IProps {
  activeFormStep: number;
  errors?: FormError;
  // onSubmit?: (formData: IFinancingForm, ownerId?: string) => Promise<void>;
  lastStepCallback?: () => void;

  isHeadline?: boolean;
}

const FinancingFormBase: FC<IProps> = ({
  activeFormStep,
  errors,
  // onSubmit,
  lastStepCallback,
  isHeadline,
}) => {
  const { title, header } = useGetFinancingTitle({
    activeStepProp: activeFormStep,
  });
  const percent = useGetProgressPercent();
  const isModalOpen = useAppSelector(
    (state) => state.financing.isFinancingOpen
  );

  const formStep = useMemo(() => {
    switch (activeFormStep) {
      case 0:
        return <FirstStep />;
      case 1:
        return <SecondStep />;
      case 7:
        //
        return (
          <ThirdStep
            lastStepCallback={lastStepCallback}
            // onSubmit={onSubmit}
          />
        );
      default:
        return (
          <ThirdStep
            lastStepCallback={lastStepCallback}
            // onSubmit={onSubmit}
          />
        );
    }
  }, [
    activeFormStep,
    lastStepCallback,
    // onSubmit
  ]);

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      formStep={formStep}
      percent={percent}
      title={title}
      header={header}
      errors={errors}
      isHeadline={isHeadline}
    />
  );
};

const FinancingForm = memo(FinancingFormBase);

export { FinancingForm };
