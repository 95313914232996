import { FC, memo, useCallback, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { LoginResponse, useSignInMutation } from '../../../../../../generated';
import { client } from '../../../../../../services/graphql/graphql';
import { device } from '../../../../../../style/theme';
import { ForgotPasswordForm } from '../../../../../auth/components/forms/forgot-password-form';
import { LoginFormWizard } from '../../../../../auth/components/forms/login-form-wizard';
import { ILoginForm } from '../../../../../auth/interfaces';
import {
  login,
  setAnonymousUser,
  setUserInput,
  setUserState,
  toggleIsForgottenPasswordOpen,
  toggleIsRegisterModalOpen,
  toggleLoading,
} from '../../../../../auth/redux/authSlice';
import { SecondaryButton } from '../../../../../common/components/ui/buttons';
import { GlobalLoader } from '../../../../../common/components/ui/loaders/global-loader';
import { useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { FORM_STEPS } from '../../../constants';
import { IValuationForm } from '../../../interface';
import {
  toggleLoginForm,
  toggleRegisterForm,
} from '../../../redux/valuationWizardSlice';
import { FormTitle } from '../../../../components/common/form-title';
import { VisibilityWrapper } from '../../../../components/common/visibility-wrapper';
import { MobileBack } from '../common/mobile-back';
import { Progress } from '../../../../components/common/progress';

const LoaderContainer = styled.div<{ isiframe?: boolean }>`
  height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
  &&&& {
    > div {
      position: absolute;
    }
  }
`;

const Container = styled.div<{ isiframe?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${({ isiframe }) => (isiframe ? '100vh' : 'auto')};
`;

const Header = styled.div`
  display: block;
`;

const BackButton = styled(SecondaryButton)`
  margin-top: auto;
  align-self: flex-start;
`;

const FormWrapper = styled.div`
  @media ${device.tablet} {
    margin: 0 16px 0 16px;
    height: 100%;
  }
`;

interface IProps {
  onSuccess: (formData: IValuationForm, ownerId: string) => Promise<void>;
}

const OnlineLoginContainerBase: FC<IProps> = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  const [inputPassword, setInputPassword] = useState('');
  const [errors, setErrors] = useState<null | unknown>(null);

  const isForgottenPasswordOpen = useAppSelector(
    (state) => state.auth.isForgottenPasswordOpen
  );
  const isLoginFormLoading = useAppSelector((state) => state.auth.isLoading);
  const userInput = useAppSelector((state) => state.valuationWizard.userInput);
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const regionalBrokerId = useAppSelector(
    (state) => state.valuationWizard.regionalBrokerId
  );

  const title = isForgottenPasswordOpen
    ? t('modal.forgotten-password.title')
    : t('modal.login.title');

  // Mutations
  const [signIn, { data: signInData }] = useSignInMutation();

  const backToValuationForm = useCallback(() => {
    dispatch(toggleLoginForm(false));
    dispatch(toggleIsForgottenPasswordOpen(false));
    dispatch(toggleRegisterForm(true));
  }, [dispatch]);

  const onLoginSubmit: SubmitHandler<ILoginForm> = useCallback(
    async (formData) => {
      const { userAuthenticationKey, password } = formData;
      setInputPassword(password);

      if (userAuthenticationKey && password) {
        try {
          dispatch(toggleLoading(true));

          await signIn({
            input: {
              userAuthenticationKey,
              password,
              requestAccessToken: true,
              regionalBrokerId,
            },
          }).unwrap();
        } catch (error: unknown) {
          setErrors(error);
        } finally {
          dispatch(toggleLoading(false));
        }
      }
    },
    [dispatch, regionalBrokerId, signIn]
  );

  useEffect(() => {
    if (signInData) {
      const loginResponse = signInData.signIn as LoginResponse;
      if (loginResponse?.jwt?.token) {
        client.setHeader(
          'Authorization',
          `Bearer ${loginResponse?.jwt?.token ?? ''}`
        );

        batch(() => {
          dispatch(setAnonymousUser(false));
          dispatch(login(loginResponse));
          dispatch(setUserState(loginResponse.user.userState));
          onSuccess(userInput as IValuationForm, loginResponse.user._id);
        });
      } else if (loginResponse.user && !loginResponse.jwt) {
        batch(() => {
          const {
            user: {
              userState,
              name,
              surname,
              phone,
              email,
              termsAndConditions,
            },
          } = loginResponse;
          dispatch(toggleRegisterForm(true));
          dispatch(toggleLoginForm(false));
          dispatch(toggleIsRegisterModalOpen(true));
          dispatch(setUserState(userState));
          dispatch(
            setUserInput({
              name,
              surname,
              phonePrefix: '',
              phone,
              email,
              password: inputPassword,
              confirmPassword: inputPassword,
              termsAndConditions,
            })
          );
        });
      }
    }
  }, [dispatch, inputPassword, onSuccess, signInData, userInput]);

  return (
    <Container isiframe={isIframe}>
      {!isIframe && (
        <MobileBack
          title={t('landing-page.primary-button.online-valuation.label')}
        />
      )}
      {isLoginFormLoading && (
        <LoaderContainer isiframe={isIframe}>
          <GlobalLoader />
        </LoaderContainer>
      )}
      <VisibilityWrapper isiframe={isIframe} isVisible={!isLoginFormLoading}>
        <Header>
          <Progress activeBullet={FORM_STEPS} bullets={FORM_STEPS} />
          <FormTitle text={title} />
        </Header>

        <FormWrapper>
          {isForgottenPasswordOpen ? (
            <ForgotPasswordForm isWizardForm />
          ) : (
            <LoginFormWizard onSubmit={onLoginSubmit} errors={errors} />
          )}
        </FormWrapper>
        {(!isMobileSize || isIframe) && (
          <BackButton label={t('button.back')} onClick={backToValuationForm} />
        )}
      </VisibilityWrapper>
    </Container>
  );
};

const OnlineLoginContainer = memo(OnlineLoginContainerBase);

export { OnlineLoginContainer };
