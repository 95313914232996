import styled from 'styled-components';
import { device } from '../../../../../../../style/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 8px;
  }
`;
