import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const CTAContainer = styled.div<{ iniframe?: boolean }>`
  position: ${({ iniframe }) => (iniframe ? 'static' : 'absolute')};
  bottom: -24px;
  right: 48px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media ${device.tablet} {
    position: static;
  }
`;

export const RatingBox = styled.div`
  height: 24px;
  padding: 4px 0 4px 34px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #002849;
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #002849;
    position: absolute;
    top: -8px;
    left: 32px;
  }
`;

export const RatingText = styled.p`
  margin: 0 6px;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.6;
  letter-spacing: 0.5px;
`;

export const Star = styled.div`
  width: 12px;
  height: 12px;
  background: white;
  margin-right: 6px;
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
`;
