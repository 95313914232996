import { FC, memo, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BorderButton } from '../../../../../common/components/ui/buttons';
import { ParagraphText } from '../../../../../common/components/ui/typography';
import { useAppDispatch } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { prevStepAction } from '../../../redux/valuationWizardSlice';
import { NextButton } from '../../../../components/common/next-button';

interface IProps {
  submitForm?: boolean;

  lastStepCallback?(): void;
  lastStepLoginCallback?(): void;
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const LoginButton = styled(NextButton)`
  margin-bottom: 12px;
  text-transform: uppercase;
  border: solid 2px ${({ theme }) => theme.blue};
  background-color: transparent;
  color: ${({ theme }) => theme.blue};
  &:hover {
    background-color: rgba(0, 48, 94, 0.2);
  }
`;

const RegisterButton = styled(NextButton)`
  text-transform: uppercase;
`;

const BackButton = styled(BorderButton)`
  margin-top: auto;
  margin-right: auto;
`;

const Paragraph = styled(ParagraphText)`
  margin: 0 0 16px 0;
  text-align: center;
`;

const SixthStepBase: FC<IProps> = ({
  submitForm,
  lastStepCallback,
  lastStepLoginCallback,
}) => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();

  const switchToPrevStep = useCallback(
    (e?: MouseEvent) => {
      e?.preventDefault();
      dispatch(prevStepAction());
    },
    [dispatch]
  );

  return (
    <Container>
      <Paragraph content={t('valuation.form.fifth-step.description')} />
      <Row>
        <LoginButton
          id="login-wizard-button"
          watchedKeys={[]}
          label={t('button.login-now')}
          submitForm={submitForm}
          buttonCallback={lastStepLoginCallback}
        />
        <RegisterButton
          id="register-wizard-button"
          watchedKeys={[]}
          label={t('button.register-now')}
          submitForm={submitForm}
          buttonCallback={lastStepCallback}
        />
      </Row>
      <Row>
        {!isMobileSize && (
          <BackButton label={t('button.back')} onClick={switchToPrevStep} />
        )}
      </Row>
    </Container>
  );
};

const SixthStep = memo(SixthStepBase);

export { SixthStep };
