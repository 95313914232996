import { memo, useContext, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { DefaultTheme, ThemeContext } from 'styled-components';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';

interface IProps {
  labels: string[];
  data: Array<string | number>;
  height?: number;
  id?: string;
}

const getOptions = (maxValue: number) => ({
  indexAxis: 'y',
  barPercentage: 1,
  scales: {
    x: {
      stacked: true,
      min: 0,
      max: maxValue,
      ticks: {
        display: false,
        color: 'rgba(0, 48, 94, 1)',
        font: {
          family: 'Roboto',
          size: 12,
          weight: '900',
          lineHeight: 1.6,
        },
      },
      grid: {
        display: false,
        drawTicks: false,
        drawOnChartArea: false,
        drawBorder: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        mirror: true,
        labelOffset: -14,
        maxRotation: 0,
        align: 'center',
        crossAlign: 'near',
        includeBounds: false,
        padding: 4,
        color: 'rgba(0, 48, 94, 0.5)',
        font: {
          family: 'Roboto',
          size: 12,
          weight: '900',
          lineHeight: 1.6,
        },
        max: maxValue,
      },
      grid: {
        display: false,
        drawTicks: false,
        drawOnChartArea: false,
        drawBorder: false,
      },
    },
  },
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  plugins: {
    tooltip: { enabled: false },
    datalabels: {
      anchor: 'end',
      align: 'start',
      offset: -45,
      color: 'rgba(0, 48, 94, 1)',
      font: {
        family: 'Roboto',
        size: 12,
        weight: '900',
        lineHeight: 1,
      },
      formatter: (value: string, context: Context) => {
        const numberValue = Number(value);
        return context.datasetIndex > 0
          ? `${Number(maxValue - numberValue)
              .toFixed(1)
              .replace(',', '.')} %`
          : '';
      },
    },
    legend: {
      display: false,
    },
  },
  // responsive: true,
});

const buildDatasets = (
  themeContext: DefaultTheme,
  labels: string[],
  data: Array<string | number>
) => {
  let maxValue = Math.max(...data.map((value) => Number(value)));
  if (maxValue === 0) maxValue = 100;
  const mainDataSet = {
    data,
    label: 'blue',
    borderColor: themeContext.blue,
    backgroundColor: [themeContext.blue],
    barThickness: 12,
    barPercentage: 1,
    categoryPercentage: 1,
    borderRadius: {
      topLeft: 12,
      topRight: 12,
      bottomRight: 12,
      bottomLeft: 12,
    },
    borderSkipped: false,
  };

  const stackedDataSet = {
    data: [...data].map((value: string | number) => {
      return Number(maxValue - Number(value))
        .toFixed(2)
        .replace(',', '.');
    }),
    borderColor: hexToRGB(themeContext.ctaDisabledBackground, 0.5),
    backgroundColor: hexToRGB(themeContext.ctaDisabledBackground, 0.5),
    barThickness: 12,
    barPercentage: 1,
    categoryPercentage: 1,
    borderRadius: {
      topLeft: 0,
      topRight: 12,
      bottomRight: 12,
      bottomLeft: 0,
    },
    borderSkipped: 'false',
  };
  return {
    labels,
    datasets: [mainDataSet, stackedDataSet],
  };
};
const BarChartBase = ({ labels, data, height, id }: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);

  const { t } = useTranslation();
  const chartData = useMemo(
    () => buildDatasets(themeContext, [...labels.map((l) => t(l))], data),
    [data, labels, themeContext, t]
  );

  let maxValue = Math.max(...data.map((value) => Number(value)));
  if (maxValue === 0) maxValue = 100;

  const options = getOptions(maxValue);

  return (
    <Bar
      id={id}
      {...(height && { height })}
      // width={labels.length * 25}
      data={chartData}
      options={{
        ...options,
        layout: {
          padding: {
            right: 50,
          },
        },
      }}
      plugins={[ChartDataLabels]}
    />
  );
};

const BarChart = memo(BarChartBase);

export { BarChart };
