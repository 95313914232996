import styled from 'styled-components';
import { device } from '../../../../../style/theme';

export const ShadowmapMainButton = styled.button`
  position: absolute;
  z-index: 1999;
  top: 76px;
  left: 16px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  background: #4e73f5;
  height: 32px;
  border: none;
  cursor: pointer;

  @media ${device.tablet} {
    display: none;
  }
`;

export const ShadowmapMainButtonIcon = styled.span`
  width: 36px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShadowmapMainButtonText = styled.span`
  text-transform: uppercase;
  font-weight: 900;
  color: #33597e;
  font-family: Roboto;
  font-size: 12px;
  height: 32px;
  background: white;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

export const ShadowmapOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3000;
  background: rgba(0, 40, 73, 0.9);
  padding: 32px;
`;

export const ShadowmapIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: none;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 32px;
  right: 32px;
  width: 48px;
  height: 48px;
  border-bottom-left-radius: 5px;
  background: #001d38;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const AddressBar = styled.div`
  height: 64px;
  background: #e5e9ec;
  bottom: 32px;
  position: absolute;
  left: 32px;
  right: 32px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

export const AddressBarButton = styled.button`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #e5e9ec;
  background: #4e73f5;
  border-radius: 5px;
  padding: 0 24px;
  border: none;
  font-weight: 900;
  font-family: Roboto;
  font-size: 12px;
  cursor: pointer;
`;

export const AddressBarItemsBox = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const AddressBarTitle = styled.h5`
  font-family: Roboto;
  font-weight: 900;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: rgba(0, 48, 94, 0.5);
  margin-bottom: 8px;
`;

export const AddressBarText = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #00305e;
`;
