import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as FileIcon } from 'assets/streamline-light/files-folders/common-files/common-file-text.svg';
import { ReactComponent as CertificateIcon } from 'assets/streamline-light/social-medias-rewards-rating/certified/certified-certificate.svg';
import { ReactComponent as HouseIcon } from 'assets/streamline-light/interface-essential/home/house-chimney-1.svg';

import { HeaderWrapper } from './tabs-financing-portal-styles';
import { HeaderTab } from '../../../property/components/header/valuation-header-tab';
import CalculationTab from './tabs-content/calculator-tab/calculation-tab';
import { useAppSelector, useScrollPosition } from '../../../common/hooks';
import { VariationHeaderActions } from './variation-header-actions/variation-header-actions';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { setTabListNearTop } from '../../../property/redux/dynamicMapUtilsSlice';
import { MortgageCertificate } from './tabs-content/mortgage-certificate/mortgage-certificate';
import { useGetFinancing } from '../../hooks/useGetFinancing';
import { FinancingReason } from '../../../../generated';
import { setCalculateFinancingPropositions } from '../../../forms/form-financing/redux/financingSlice';
import { PropertiesTab } from './tabs-content/properties-tab/properties-tab';
import { useCheckFinancingURLParams } from '../../hooks/useCheckFinancingURLParams';

interface IProps {
  isMultipleFinancings: boolean;
  isMultipleVariants: boolean;
}

const TabsFinancingPortalBase: FC<IProps> = ({
  isMultipleFinancings,
  isMultipleVariants,
}) => {
  // onOffice marketplace iframe URL params
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();
  const tabListRef = useRef<HTMLDivElement>(null);
  const { financingId } = useParams<{ financingId: string }>();
  const isEmptyState = financingId === undefined;
  const { financingVariant, isCertificate } = useGetFinancing({
    financingId,
  });

  // certificate logic
  const isPurchaseOfExistingProperty =
    financingVariant?.financingVariantData?.reason ===
    FinancingReason.PurchaseOfExistingProperty;

  const maxPurchasePrice =
    financingVariant?.financingPropositionMaxPurchasePrice
      ?.maximalPurchasePrice;

  const isTabListNearTop = useAppSelector(
    (state) => state.dynamicMapUtils.isTabListNearTop
  );
  const purchasePrice = useAppSelector(
    (state) => state.financing.suggestedProperties.purchasePrice
  );

  const [selectedTab, setSelectedTab] = useState(isCertificate ? 1 : 0);
  const onSelect = useCallback((index: number) => {
    setSelectedTab(index);
  }, []);

  useEffect(() => {
    dispatch(setCalculateFinancingPropositions());
  }, [selectedTab]);

  useEffect(() => {
    setSelectedTab(isCertificate ? 1 : 0);
  }, [financingId, isCertificate]);

  useEffect(() => {
    if (
      financingVariant?.financingVariantData.reason &&
      financingVariant?.financingVariantData.reason !==
        FinancingReason.PurchaseOfExistingProperty
    ) {
      setSelectedTab(0);
    }
  }, [
    financingVariant?.financingVariantData.name,
    financingVariant?.financingVariantData.reason,
  ]);

  // suggested properties purchase price logic
  // useEffect(() => {
  //   if (purchasePrice) {
  //     setSelectedTab(0);
  //   }
  // }, [purchasePrice]);

  const { isCertificateParam } = useCheckFinancingURLParams();
  useEffect(() => {
    if (isCertificateParam) {
      if (isCertificateParam === 'true') {
        setSelectedTab(1);
      } else if (isCertificateParam === 'false') {
        setSelectedTab(0);
      }
    }
  }, [isCertificateParam]);

  // make border crossed
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (!isMobileSize && selectedTab < 2) {
        const tabListRect =
          tabListRef &&
          (tabListRef.current?.getBoundingClientRect() as DOMRect);
        const isBorderCrossed = tabListRect?.top > 81;

        if (currPos.y > prevPos.y && isBorderCrossed && isTabListNearTop) {
          dispatch(setTabListNearTop(false));
        } else if (
          currPos.y < prevPos.y &&
          !isBorderCrossed &&
          !isTabListNearTop
        ) {
          dispatch(setTabListNearTop(true));
        }
      } else {
        dispatch(setTabListNearTop(false));
      }
    },
    [selectedTab, isTabListNearTop, dispatch]
  );

  return (
    <Tabs selectedIndex={selectedTab} onSelect={onSelect}>
      <HeaderWrapper
        ref={tabListRef}
        isMultipleFinancings={isMultipleFinancings}
        isMultipleVariants={isMultipleVariants}
        isTabListNearTop={isTabListNearTop}
      >
        <TabList style={{ height: '100%', position: 'relative' }}>
          <Tab tabIndex="0">
            <HeaderTab
              title={t('financing-portal-page.navigation.first-tab')}
              icon={FileIcon}
            />
          </Tab>

          {(isEmptyState || isPurchaseOfExistingProperty) && (
            <Tab tabIndex="0">
              <HeaderTab
                title={t('financing-portal-page.navigation.second-tab')}
                icon={CertificateIcon}
              />
            </Tab>
          )}
          {(isEmptyState || isPurchaseOfExistingProperty) && maxPurchasePrice && (
            <Tab tabIndex="0">
              <HeaderTab
                title={t('financing-portal-page.navigation.third-tab')}
                icon={HouseIcon}
              />
            </Tab>
          )}
        </TabList>
        {!isMobileSize && !isEmptyState && <VariationHeaderActions />}
      </HeaderWrapper>
      <TabPanel>
        <CalculationTab onSelectTab={onSelect} />
      </TabPanel>
      {(isEmptyState || isPurchaseOfExistingProperty) && (
        <TabPanel>
          <MortgageCertificate />
        </TabPanel>
      )}
      {(isEmptyState || isPurchaseOfExistingProperty) && maxPurchasePrice && (
        <TabPanel>
          <PropertiesTab />
        </TabPanel>
      )}
    </Tabs>
  );
};

const TabsFinancingPortal = memo(TabsFinancingPortalBase);

export { TabsFinancingPortal };
