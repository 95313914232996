import { FC, memo, useCallback, useContext, useRef, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useFormContext, Controller } from 'react-hook-form';
import {
  ActionButton,
  ActionsWrapper,
  SwitchLabel,
  SwitchWrapper,
  Container,
  Content,
  Description,
  Image,
  TextWrapper,
  Title,
  HiddenCheckbox,
} from './service-card-styles';
import { Switch } from '../../../../common/components/ui/switch';
import { OnOfficeServices } from '../../../../../generated';

interface IProps {
  id: OnOfficeServices;
  title: string;
  description: string;
  imageSrc: string;
  checkboxLabel: string;
  settingsLabel?: string;
  buttonLabel?: string;
  isButtonFilled?: boolean;
  onChange: () => void;
  name: string;
  settingsName?: string;
  isPropertyServiceSelected: boolean;
  isShopServiceSelected?: boolean;
  onShowExample: (id: OnOfficeServices) => void;
  isPropertyServiceDisabled?: boolean;
  isShopServiceDisabled?: boolean;
  isSimulation?: boolean;
  isBrokerSettings?: boolean;
}

const ServiceCardBase: FC<IProps> = ({
  id,
  title,
  description,
  imageSrc,
  checkboxLabel,
  settingsLabel,
  buttonLabel,
  isButtonFilled,
  onChange,
  name,
  settingsName,
  isPropertyServiceSelected,
  isShopServiceSelected,
  onShowExample,
  isPropertyServiceDisabled,
  isShopServiceDisabled,
  isSimulation = false,
  isBrokerSettings = false,
}) => {
  const theme = useContext(ThemeContext);
  const [isPropertyServiceActive, setIsPropertyServiceActive] =
    useState<boolean>(isPropertyServiceSelected);
  const [isShopServiceActive, setIsShopServiceActive] = useState<boolean>(
    !!isShopServiceSelected
  );
  const { control } = useFormContext();
  const checkboxPropertyRef = useRef<HTMLInputElement>(null);
  const checkboxShopRef = useRef<HTMLInputElement>(null);

  const handleSwitchChange = useCallback(
    (value: boolean, isShopService) => {
      if (!isPropertyServiceDisabled && !isShopService) {
        setIsPropertyServiceActive(value);
        checkboxPropertyRef?.current?.click();
      }

      if (!isShopServiceDisabled && isShopService) {
        setIsShopServiceActive(value);
        checkboxShopRef?.current?.click();
      }
    },
    [isPropertyServiceDisabled, isShopServiceDisabled]
  );

  return (
    <Container
      isActive={
        isBrokerSettings
          ? isPropertyServiceActive && isShopServiceActive
          : isPropertyServiceActive
      }
    >
      <Image src={imageSrc} />
      <Content>
        <TextWrapper>
          <Title content={title} />
          <Description content={description} />
        </TextWrapper>
        <ActionsWrapper
          isSimulation={isSimulation}
          isBrokerSettings={isBrokerSettings}
        >
          {!isSimulation && (
            <SwitchWrapper isBlurred={isPropertyServiceDisabled}>
              <Switch
                onChange={(value: boolean) => handleSwitchChange(value, false)}
                isChecked={isPropertyServiceActive}
                id={`${id}-property`}
              />
              <SwitchLabel
                content={checkboxLabel}
                isActive={isPropertyServiceActive}
              />
            </SwitchWrapper>
          )}
          {isBrokerSettings && settingsLabel && (
            <SwitchWrapper isBlurred={isShopServiceDisabled}>
              <Switch
                onChange={(value: boolean) => handleSwitchChange(value, true)}
                isChecked={isShopServiceActive}
                id={`${id}-shop`}
              />
              <SwitchLabel
                content={settingsLabel}
                isActive={isShopServiceActive}
              />
            </SwitchWrapper>
          )}

          {buttonLabel && !isBrokerSettings && (
            <ActionButton
              isFilled={isButtonFilled}
              label={buttonLabel}
              onClick={() => onShowExample(id)}
              borderColor={theme.blue}
              color={isButtonFilled ? theme.white : theme.blue}
              backgroundColor={isButtonFilled ? theme.blue : theme.white}
            />
          )}
        </ActionsWrapper>
      </Content>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <HiddenCheckbox
              {...field}
              ref={checkboxPropertyRef}
              type="checkbox"
              checked={isPropertyServiceActive}
              onChange={(event) => {
                field.onChange(event.target.checked);
                onChange();
              }}
            />
          );
        }}
      />
      {isBrokerSettings && settingsName && (
        <Controller
          control={control}
          name={settingsName}
          render={({ field }) => {
            return (
              <HiddenCheckbox
                {...field}
                ref={checkboxShopRef}
                type="checkbox"
                checked={isShopServiceActive}
                onChange={(event) => {
                  field.onChange(event.target.checked);
                  onChange();
                }}
              />
            );
          }}
        />
      )}
    </Container>
  );
};

const ServiceCard = memo(ServiceCardBase);

export { ServiceCard };
