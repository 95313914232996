import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../store';

const selectPhone = (state: RootState) => state.auth.userInput?.phone;

const selectPhonePrefix = (state: RootState) =>
  state.auth.userInput?.phonePrefix;

export const selectFormattedPhone = createDraftSafeSelector(
  selectPhone,
  selectPhonePrefix,
  (phone, phonePrefix) => {
    if (!phonePrefix && phone) {
      return phone?.toString()?.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ');
    }
    return `+${phonePrefix} ${phone
      ?.toString()
      ?.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')}`;
  }
);
