import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import { capitalize } from 'lodash';

import { ReactComponent as Building } from 'assets/streamline-light/building-construction/buildings/buildings-2.svg';
import { ReactComponent as BuildingYear } from 'assets/streamline-light/building-construction/utilities/building-year.svg';
import { ReactComponent as RollerIcon } from 'assets/streamline-light/design/colors-painting/color-rolling-brush.svg';
import { ReactComponent as RealEstateDimensionsBlock } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as ResizeExpandSides } from 'assets/streamline-light/design/resize/resize-expand-sides.svg';
import { ReactComponent as FloorsIcon } from 'assets/streamline-light/wayfinding/lifts/lift-two-people.svg';
import { ReactComponent as HotelDoubleBed2 } from 'assets/streamline-light/hotels-spas/hotel-bedrooms/hotel-double-bed-2.svg';
import { ReactComponent as Bathroom } from 'assets/streamline-light/hotels-spas/bathroom/bathroom-tub-towel.svg';
import { ReactComponent as HouseGarage } from 'assets/streamline-light/building-construction/houses/house-garage.svg';
import { ReactComponent as ParkingIcon } from 'assets/streamline-light/shopping-ecommerce/discount-coupons/discount-parking-2.svg';
import { ReactComponent as RealEstateDimensionsPlan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-plan.svg';

import PropertyValuationIcon from '../../../../../../../property/components/icon/property-valuation-icon';
import { InfoContainer, IconRow } from './property-details-styles';
import { useExposeDetailsPropertyData } from '../../../../hooks/useExposeDetailsQueries';
import {
  changeMapViewport,
  removeAllMapMarkersExceptHome,
  setMapSettings,
} from '../../../../redux/exposeSlice';
import {
  extractPropertyTypeLabelAndIcon,
  getPropertyCodeFromType,
} from '../../../../../../../property/utils/property-type-normalizer';
import { PropertyCode } from '../../../../../../../../generated';
import { ParkingSpaceLabelByKey } from '../../../../../../../property/constants';

interface IProps {
  propsData?: {
    type?: string | null;
    buildingYear?: string | null;
    modernizationYear?: string | null;
    livingArea?: number | null;
    landArea?: number | null;
    floor?: number | null;
    numberOfRooms?: number | null;
    numberOfBedrooms?: number | null;
    numberOfBathrooms?: number | null;
    numberOfParkingSpace?: number | null;
    numberOfGarages?: number | null;
    numberOfFloors?: number | null;
    numberOfDuplexParkingSpaces?: number | null;
    numberOfUndergroundGarages?: number | null;
    numberOfOtherParkingSpaces?: number | null;
    numberOfParkingSpaces?: number | null;
  };
}

const PropertyDetails: FC<IProps> = ({ propsData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { propertyData } = useExposeDetailsPropertyData();

  const { location } = propertyData;

  const {
    type,
    buildingYear,
    modernizationYear,
    livingArea,
    landArea,
    floor,
    numberOfRooms,
    numberOfBedrooms,
    numberOfBathrooms,
    numberOfParkingSpace,
    numberOfGarages,
    numberOfFloors,
    numberOfDuplexParkingSpaces,
    numberOfUndergroundGarages,
    numberOfOtherParkingSpaces,
    numberOfParkingSpaces,
  } = propsData || propertyData;

  const propertyCode = getPropertyCodeFromType(type ?? '');

  const { icon, label } = extractPropertyTypeLabelAndIcon(propertyCode);

  useEffect(() => {
    if (location?.coordinates) {
      batch(() => {
        dispatch(
          changeMapViewport({
            ...location?.coordinates,
            bearing: -80,
            pitch: 65,
            zoom: 14,
            transitionDuration: 0,
          })
        );
        dispatch(
          setMapSettings({
            dragPan: true,
            dragRotate: true,
            scrollZoom: true,
            touchZoom: true,
            touchRotate: true,
            keyboard: true,
            doubleClickZoom: true,
          })
        );
      });
    }

    return () => {
      batch(() => {
        dispatch(removeAllMapMarkersExceptHome());
      });
    };
  }, [dispatch, location?.coordinates]);

  const parkingSpacesWithLabel = useMemo(() => {
    const parkingSpacesObj = {
      duplex: numberOfDuplexParkingSpaces,
      garage: numberOfGarages,
      multiStoryGarage: numberOfParkingSpace,
      undergroundGarage: numberOfUndergroundGarages,
      otherParkingLot: numberOfOtherParkingSpaces,
    };

    return Object.keys(parkingSpacesObj)
      ?.filter(
        (key) => parkingSpacesObj?.[key as keyof typeof parkingSpacesObj] !== 0
      )
      ?.map((key) => ({
        label: ParkingSpaceLabelByKey.get(key),
        value: parkingSpacesObj?.[key as keyof typeof parkingSpacesObj],
      }));
  }, [
    numberOfDuplexParkingSpaces,
    numberOfGarages,
    numberOfParkingSpace,
    numberOfUndergroundGarages,
    numberOfOtherParkingSpaces,
  ]);

  return (
    <InfoContainer>
      <IconRow>
        <PropertyValuationIcon
          icon={icon}
          value={String(t(label)) || '-'}
          type={t('expose-page.tabs.overview.property-details.type')}
        />
        <PropertyValuationIcon
          icon={BuildingYear}
          value={buildingYear || '-'}
          type={t(
            'expose-page.tabs.overview.property-details.construction-year'
          )}
        />
        <PropertyValuationIcon
          icon={RollerIcon}
          value={modernizationYear || '-'}
          type={t('expose-page.tabs.overview.property-details.modernization')}
        />
        <PropertyValuationIcon
          icon={RealEstateDimensionsBlock}
          value={`${t('approx')} ${livingArea} m²`}
          type={t('expose-page.tabs.overview.property-details.living-space')}
        />
        <PropertyValuationIcon
          icon={ResizeExpandSides}
          value={landArea ? `${t('approx')} ${landArea} m²` : '-'}
          type={t('expose-page.tabs.overview.property-details.land-area')}
        />

        <PropertyValuationIcon
          icon={FloorsIcon}
          value={
            propertyCode === PropertyCode.Apartment
              ? floor
                ? String(floor)
                : '-'
              : numberOfFloors
              ? String(numberOfFloors)
              : '-'
          }
          type={t(
            propertyCode === PropertyCode.Apartment
              ? 'expose-page.tabs.overview.property-details.floor'
              : 'property-valuation.form.redesign.number-of-floors-in-building.label'
          )}
        />
        <PropertyValuationIcon
          icon={RealEstateDimensionsPlan}
          value={numberOfRooms || '-'}
          type={t('expose-page.tabs.overview.property-details.number-of-rooms')}
        />
        <PropertyValuationIcon
          icon={HotelDoubleBed2}
          value={numberOfBedrooms || '-'}
          type={t(
            'expose-page.tabs.overview.property-details.number-of-bedrooms'
          )}
        />
        <PropertyValuationIcon
          icon={Bathroom}
          value={numberOfBathrooms || '-'}
          type={t('expose-page.tabs.overview.property-details.bathroom')}
        />
        {parkingSpacesWithLabel?.map((parkingSpaceWithLabel) => (
          <PropertyValuationIcon
            icon={HouseGarage}
            value={parkingSpaceWithLabel.value ?? 0}
            type={t(parkingSpaceWithLabel.label || '')}
          />
        ))}
        <PropertyValuationIcon
          icon={ParkingIcon}
          value={numberOfParkingSpaces || '-'}
          type={t('expose-page.tabs.overview.property-details.parking-space')}
        />
      </IconRow>
    </InfoContainer>
  );
};

export { PropertyDetails };
