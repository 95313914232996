import { MouseEvent } from 'react';
import styled from 'styled-components';
import { Input } from '../../../forms/components/common/input/input';
import { hexToRGB } from '../../utils/hex-to-rgb';
import { BoxText, BoxTitle } from './typography';

interface IProps {
  title: string;
  onClick: (e: MouseEvent) => void;
  text?: string;
  isChecked?: boolean;
  isTwoItems?: boolean;
  isFullWidth?: boolean;
  boxPadding?: string;
  nestedInput?: string;
  nestedInputPlaceholder?: string;
}

const Container = styled.div<{
  isChecked?: boolean;
  isTwoItems?: boolean;
  isFullWidth?: boolean;
  boxPadding?: string;
}>`
  ${({ isFullWidth }) => isFullWidth && 'flex: 1;'}
  display: flex;
  align-items: center;
  padding: ${({ boxPadding }) => boxPadding || '12px'};
  position: relative;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  box-shadow: inset 0 0 0 1px transparent;
  background-color: #ffffff;
  cursor: pointer;
  ${({ isTwoItems }) =>
    !isTwoItems &&
    `
    &:not(:first-of-type) {
      margin-top: 8px;
    }
  `}
  &:hover {
    background-color: ${(props) => props.theme.greyBackground};
    border-color: ${(props) => props.theme.blue};
    label {
      background: ${(props) => props.theme.greyBackground};
      border-color: ${(props) => props.theme.blue};
    }
    p {
      &:first-of-type {
        color: ${(props) => props.theme.blue};
      }
    }
    input {
      background-color: #ffffff;
    }
  }
  ${(props) =>
    props.isChecked &&
    `
    p:first-of-type {
      color: ${props.theme.blue};
    }
    border-color: ${props.theme.blue};
    box-shadow: inset 0px 0px 0px 1px ${props.theme.blue};
  `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  div {
    margin: 4px 0 0 0;
  }
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  border: 1px solid ${(props) => hexToRGB(props.theme.blue, 0.5)};
`;
const RadioButton = styled.input`
  margin-right: 12px;
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  cursor: pointer;
  flex: 0 0 auto;
  ${(props) =>
    props.checked &&
    `
    &:checked + ${RadioButtonLabel} {
      background: #fff;
      border: 1px solid ${props.theme.blue};
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin: 3px;
        background: ${props.theme.blue};
      }
    }
  `}
`;

const Title = styled(BoxTitle)`
  color: ${(props) => hexToRGB(props.theme.blue, 0.6)};
`;

const Text = styled(BoxText)`
  color: ${(props) => hexToRGB(props.theme.regularText, 0.6)};
`;

const RadioBox = ({
  title,
  text,
  isChecked,
  onClick,
  isTwoItems,
  isFullWidth,
  boxPadding,
  nestedInput,
  nestedInputPlaceholder,
}: IProps): JSX.Element => (
  <Container
    isChecked={isChecked}
    onClick={onClick}
    isTwoItems={isTwoItems}
    boxPadding={boxPadding}
    isFullWidth={isFullWidth}
  >
    <RadioButton type="radio" checked={isChecked} readOnly />
    <RadioButtonLabel />
    <TextWrapper>
      <Title content={title} />
      {text && <Text content={text} />}
      {nestedInput && (
        <Input
          name={nestedInput}
          skipIcon
          placeholder={nestedInputPlaceholder}
        />
      )}
    </TextWrapper>
  </Container>
);

export { RadioBox };
