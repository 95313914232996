import { FC, useRef } from 'react';
import { useGetOptionsCoordinates } from '../../../../../../../../common/hooks/useGetOptionsCoordinates';
import {
  EnergyConsumptionIcon,
  IconContainer,
  Indicator,
  InfoPopup,
} from './combined-shape-styles';

interface IProps {
  color: string;
  left: string;
  letter?: string;
  value?: string;
}

const CombinedShape: FC<IProps> = ({ color, letter, left, value }) => {
  const infoDiv = useRef<HTMLDivElement>(null);
  const coordinatesDiv = infoDiv.current?.getBoundingClientRect();
  const width = Number(coordinatesDiv?.width) / 2 - 14;
  const { xCenterPX } = useGetOptionsCoordinates({
    optionsDiv: infoDiv,
  });

  return (
    <>
      <Indicator>
        <IconContainer>
          {value && (
            <InfoPopup
              ref={infoDiv}
              left={left}
              width={width.toString()}
              leftArrowPX={xCenterPX}
            >
              {`${value} kWh/m²a`}
            </InfoPopup>
          )}
          <EnergyConsumptionIcon color={color || '#fffa3e'} left={left}>
            {letter}
          </EnergyConsumptionIcon>
        </IconContainer>
      </Indicator>
    </>
  );
};

export { CombinedShape };
