import styled from 'styled-components';
import { device } from '../../../../style/theme';

export const ContentWithPadding = styled.div<{ benefitSectionIndex?: number }>`
  padding: ${(props) =>
    props.benefitSectionIndex === 1 ? '75px 48px 0 48px' : '48px 48px 0 48px'};

  > div:first-child {
    margin-bottom: 64px;
  }

  > div:last-of-type {
    margin-top: 64px;
  }

  ${(props) =>
    props.benefitSectionIndex && props.benefitSectionIndex === 1
      ? `
    > div:first-child > div {
    margin-top: 0
    }
  `
      : ``}

  @media ${device.tablet} {
    padding: 0;
    > div:first-child {
      margin-bottom: 16px;
    }

    ${(props) =>
      props.benefitSectionIndex
        ? `
    > div:not(:nth-child(${props.benefitSectionIndex})) {
      margin-left: 16px;
      margin-right: 16px;
    }
  `
        : `> div {
        margin-left: 16px;
  margin-right: 16px;
  }`}

    //> div:not(:nth-child(benefitSectionIndex)) {
    //  margin-left: 16px;
    //  margin-right: 16px;
    //}

    > div:last-of-type {
      margin-top: 16px;
    }
  }
`;

export const IFrameContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  cursor: pointer;
  width: 12px;
  height: 12px;
`;
