import styled from 'styled-components';
import { BoxTitle, Headline2 } from '../../../common/components/ui/typography';
import { SecondaryButton } from '../../../common/components/ui/buttons';
import { device } from '../../../../style/theme';

export const Container = styled.div`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  margin-bottom: 16px;
  background-color: ${(props) => props.theme.white};

  @media ${device.tablet} {
    padding: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: max-content;
`;

export const Title = styled(Headline2)``;

export const Content = styled.div<{ isBlurred?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  ${(props) => props.isBlurred && 'filter: blur(6px)'};
`;

export const TotalCount = styled(BoxTitle)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 1.6;
  padding: 2px 8px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.lightBlue};
  background-color: ${(props) => props.theme.greyBackground};
  margin-left: 12px;
`;

export const NewOfferBadge = styled.div<{ isFilled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.tagGreenBorder};
  background-color: ${(props) =>
    props.isFilled ? props.theme.green : props.theme.paleGreen};
  margin: 0 0 0 12px;
  font-family: Roboto, 'sans-serif';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${(props) => (props.isFilled ? props.theme.white : '#74a257')};

  @media ${device.tablet} {
    font-size: 8px;
    margin-left: unset;
    padding: 2px 6px;
    width: max-content;
  }
`;

export const EditedOfferBadge = styled(NewOfferBadge)`
  color: ${(props) => props.theme.gold};
  background-color: ${(props) => props.theme.tagGoldBackground};
  border: 1px solid ${(props) => props.theme.tagGoldBorder};

  @media ${device.mobile} {
    width: min-content;
    text-align: center;
  }
`;

export const Statistics = styled.div<{
  isShowGradient?: boolean;
  isGrid?: boolean;
}>`
  gap: 8px;
  position: relative;

  ${({ isGrid }) =>
    isGrid
      ? `display: grid;grid-template-columns: repeat(4, 1fr);grid-template-rows: repeat(2, auto);`
      : `display: flex;flex-wrap: wrap;`}

  & div {
    background-color: ${(props) => `${props.theme.white} !important`};
    flex: 1;
    justify-content: start;

    @media ${device.mobile} {
      text-align: center;

      & p {
        width: unset;
        overflow: unset;
        white-space: unset;
        text-overflow: unset;
      }
    }

    &:nth-child(5) {
      grid-column: 1 / 3;
    }

    &:nth-child(6) {
      grid-column: 3 / 5;
    }
  }

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
  }

  ${(props) =>
    props.isShowGradient &&
    `
    &::before {
    content: ' ';
    position: absolute;
    top: -70px;
    height: 64px;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), ${props.theme.white});
  }
  `}
`;

export const LoadMoreButton = styled(SecondaryButton)`
  margin-top: 32px;
  justify-content: center;
`;

export const CollapseButton = styled(SecondaryButton)`
  width: 40px;
  height: 40px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;

  @media ${device.mobile} {
    width: 100%;

    &:nth-child(1) {
      width: 100%;
    }
  }
`;

export const EditFiltersButton = styled(SecondaryButton)`
  justify-content: center;
  margin-top: 4px;
`;

export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 792px;

  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
  }
`;
