import {
  Currency,
  Direction,
  OffersSearchInput,
  OrderBy,
  Property,
} from '../../../generated';
// eslint-disable-next-line import/no-cycle
import { IPropertyType } from '../interfaces';

export enum PropertyInvitation {
  Create = 'PROPERTY_INVITATION_CREATE',
  Edit = 'PROPERTY_INVITATION_EDIT',
}

export enum PropertyInvitationUserRoles {
  Editor = 'Editor',
  Spectator = 'Spectator',
}

export const PERIOD_15_DAYS = '15d';
export const PERIOD_30_DAYS = '30d';
export const PERIOD_1_YEAR = '1year';
export const PERIOD_2_YEARS = '2years';
export const PERIOD_5_YEARS = '5years';

export const PROPERTY_PERIODS = [
  {
    label: 'similar-properties.filter.period.last_15d',
    value: PERIOD_15_DAYS,
  },
  {
    label: 'similar-properties.filter.period.last_30d',
    value: PERIOD_30_DAYS,
  },
  {
    label: 'similar-properties.filter.period.last_1y',
    value: PERIOD_1_YEAR,
  },
  {
    label: 'similar-properties.filter.period.last_2y',
    value: PERIOD_2_YEARS,
  },
  {
    label: 'similar-properties.filter.period.last_5y',
    value: PERIOD_5_YEARS,
  },
];

export const DEFAULT_SIMILAR_PROPERTIES_FILTERS = (
  property: Property,
  periodDate: string
): OffersSearchInput => ({
  offset: 0,
  limit: 6,
  countryCode: property?.countryCode,
  locale: 'en_GB',
  filters: {
    location: [
      {
        divisionLevel100:
          property?.administrativeDivision?.divisionLevel100?.officialId,
      },
    ],
    dealType: property?.dealType,
    propertyType: [{ code: property?.propertyData?.propertyType?.code }],
    startDate: {
      min: periodDate,
      max: '2100-08-19',
    },
    isActive: false,
  },
  referenceLocation: {
    coordinates: {
      latitude: property?.propertyData?.location?.coordinates?.latitude,
      longitude: property?.propertyData?.location?.coordinates?.longitude,
    },
  },
  orderBy: [{ field: OrderBy.Distance, direction: Direction.Asc }],
});

export const PROPERTY_TYPES: IPropertyType[] = [
  {
    label: 'similar-properties.filter.property-type.all',
    value: null,
  },
  {
    label: 'similar-properties.filter.property-type.new',
    value: true,
  },
  {
    label: 'similar-properties.filter.property-type.old',
    value: false,
  },
];

export const BUILDING_YEAR_MAX = Number(new Date().getFullYear()) + 2;
export const BUILDING_YEAR_MIN = 1800;
export const NUMBER_OF_ROOMS_MIN = 1;
export const NUMBER_OF_ROOMS_MAX = 9;
export const SALE_PRICE_MIN = 100_000;
export const SALE_PRICE_MAX = 5_000_000;
export const LIVING_AREA_MIN = 10;
export const LIVING_AREA_MAX = 300;

export const DEFAULT_PROPERTY_FILTERS_STATE = {
  salePrice: {
    currency: Currency.Eur,
    min: SALE_PRICE_MIN,
    max: SALE_PRICE_MAX,
  },
  livingArea: {
    min: LIVING_AREA_MIN,
    max: LIVING_AREA_MAX,
  },
  buildingYear: {
    min: BUILDING_YEAR_MIN,
    max: BUILDING_YEAR_MAX,
  },
  propertyPeriod: PERIOD_2_YEARS,
  propertyType: null,
  numberOfRooms: [NUMBER_OF_ROOMS_MIN, NUMBER_OF_ROOMS_MAX],
};

export const OutdoorParkingSpaceKeys = ['parkingSpace', 'carport'];

export const ParkingSpaceLabelByKey = new Map([
  ['duplex', 'property-overview.indoor-parking-spaces.duplex'],
  ['garage', 'property-overview.indoor-parking-spaces.garage'],
  [
    'multiStoryGarage',
    'property-overview.indoor-parking-spaces.multiStoryGarage',
  ],
  [
    'undergroundGarage',
    'property-overview.indoor-parking-spaces.undergroundGarage',
  ],
  [
    'otherParkingLot',
    'property-overview.indoor-parking-spaces.otherParkingLot',
  ],
]);
