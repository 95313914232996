import styled from 'styled-components';
import {
  Headline1,
  ParagraphText,
} from '../../../../../../../common/components/ui/typography';
import { device } from '../../../../../../../../style/theme';

export const Container = styled.div``;

export const Header = styled(Headline1)`
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  padding-top: 44px;
  margin-bottom: 12px;
`;

export const Text = styled(ParagraphText)<{ preLine?: boolean }>`
  ${(props) => props.preLine && `white-space: pre-line !important;`}
`;

export const PropertyPlanContainer = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 0.25rem;
  height: 100%;
  flex: 0 0 50%;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const PropertyPlan = styled.img`
  padding: 0.25rem;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto;
  cursor: pointer;
`;
