import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GraphQLClient } from 'graphql-request';

const baseUrl = process.env.REACT_APP_GRAPHQL_ENDPOINT ?? '/graphql';
// const baseUrl = 'http://localhost:3000/graphql';

export const client = new GraphQLClient(baseUrl);

export const api = createApi({
  reducerPath: 'graphql',
  baseQuery: graphqlRequestBaseQuery({ client }),
  endpoints: () => ({}),
});
